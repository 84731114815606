import { addStyles } from 'ethcss'
import { grid, indents, IStyles, textSizes } from 'theme'
import { mediaCSS } from 'blocks.app/config'

export const styles: IStyles = {
    DeviceSettingsNTPServer: {
        ...grid.full,
        ...grid.mt_mdPlus,
    },
    DeviceSettingsNTPServer__title: {
        fontSize: textSizes.normal,
        ...grid.mb_md,
    },
    DeviceSettingsNTPServer__fields: {
        ...grid.w25,
        ...grid.row,
        ...grid.mb_md,
        ...grid.justify,
        //@ts-ignore
        [mediaCSS.tab]: {
            ...grid.w100,
        },
    },
    DeviceSettingsNTPServer__fields_disabled: {
        cursor: 'not-allowed',
        opacity: 0.5,
        pointerEvents: 'none',
    },
    DeviceSettingsNTPServer__controls: {
        ...grid.row,
        ...grid.mb_md,
    },
    DeviceSettingsNTPServer__enabled: {
        ...grid.mr_md,
    },
    DeviceSettingsNTPServer__divider: {
        ...grid.flex,
    },
    DeviceSettingsNTPServer__text: {
        ...grid.mt_auto,
        ...grid.mb_micro,
        fontSize: textSizes.big,
    },
    DeviceSettingsNTPServer__ipAddress: {
        ...grid.w70,
    },
    DeviceSettingsNTPServer__port: {
        ...grid.w25,
    },
}

addStyles(styles)
