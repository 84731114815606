import React from 'react'
import TouchEditorMethods, { ITouchEditorProps } from './touchEditor.local.methods'
import TouchEditorActions from './__actions/touchEditor__actions'
import TouchEditorEvents from './__events/touchEditor__events'
import Modal from 'blocks.simple/modal/modal'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './touchEditor.jcss'
import grid from 'blocks.simple/grid/grid.jcss'

const TouchEditor = (p_: ITouchEditorProps) => {
    const { state, actionsRef, eventsRef, onSave } = TouchEditorMethods(p_)
    const s_ = state

    return (
        <Modal open={p_.open} showCloseIcon={false} mod={'clear'}>
            <div className={styles.modalWrapper}>
                <div className={styles.modalInner}>
                    <Rectangle className={cn(grid.col)}>
                        <div className={styles.wrapper}>
                            <div className={styles.header}>
                                {p_.type === 'content'
                                    ? translate('actionSettingsForContent')
                                    : translate('eventSettingsForPage')}
                            </div>
                            <div className={styles.settings}>
                                {p_.type === 'content' && s_.actions.length > 0 && (
                                    <TouchEditorActions
                                        content={p_.content}
                                        pages={p_.pages}
                                        page={p_.page}
                                        action={p_.action}
                                        actions={s_.actions}
                                        actionsRef={actionsRef}
                                    />
                                )}
                                {p_.type === 'content' && !s_.actions.length && (
                                    <div className={styles.emptyMessage}>{translate('noActionAvailable')}</div>
                                )}
                                {p_.type === 'page' && s_.events.length > 0 && (
                                    <TouchEditorEvents
                                        pages={p_.pages}
                                        page={p_.page}
                                        event={p_.event}
                                        events={s_.events}
                                        eventsRef={eventsRef}
                                    />
                                )}
                                {p_.type === 'page' && !s_.events.length && (
                                    <div className={styles.emptyMessage}>{translate('noEventsAvailable')}</div>
                                )}
                            </div>
                        </div>
                        <div className={styles.footer}>
                            <Button onClick={p_.onClose} className={grid.mr_mini}>
                                {translate('close')}
                            </Button>
                            {p_.type === 'content' && s_.actions.length > 0 && (
                                <Button onClick={onSave} mod={'fill'}>
                                    {translate('save')}
                                </Button>
                            )}
                            {p_.type === 'page' && s_.events.length > 0 && (
                                <Button onClick={onSave} mod={'fill'}>
                                    {translate('save')}
                                </Button>
                            )}
                        </div>
                    </Rectangle>
                </div>
            </div>
        </Modal>
    )
}

TouchEditor.defaultProps = {
    open: false,
    onClose: () => {},
}

export default TouchEditor
