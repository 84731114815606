import PropTypes from 'prop-types'
import React from 'react'
import Draggable from 'blocks.simple/draggable/draggable'
import { Draggable as AtomicDraggable } from 'atoms/Draggable'
import Icon from 'blocks.simple/icon/icon'
import { SortableHandle } from 'react-sortable-hoc'
import Animation from 'blocks.simple/animation/animation'
import DropMenu from 'blocks.simple/dropMenu/dropMenu'
import Modal from 'blocks.simple/modal/modal'
import PreviewWebApp from 'blocks.app/preview/__webApp/preview__webApp'
import Button from 'blocks.simple/button/button'
import DotTooltip from 'blocks.simple/dotTooltip/dotTooltip'
import Checkbox from 'blocks.simple/checkbox/checkbox'

import helpers from 'core/helpers'
import filesHelpers from 'core/helpers/_files'
import deepEqual from 'fast-deep-equal'
import { editor } from '../editor.local.methods'
import translate from 'core/translate'
import { history } from 'features/routes'

import { cn } from 'ethcss'
import styles from './editor__listItem.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { colors } from 'theme'

const PreviewItemInner = (p_) => {
    if (p_.fileItem.fileType === 'text' || p_.fileItem.fileType === 'xml') {
        return (
            <div
                className={cn(styles.text)}
                style={filesHelpers.convertStyles(p_.fileItem.style, { withoutStyles: ['fontSize'] })}
            >
                {helpers.strip_html_tags(p_.fileItem.name)}
                {p_.showBackLayer && <div className={styles.backLayer} />}
            </div>
        )
    }
    return (
        <div
            className={cn(
                styles.preview,
                filesHelpers.isIcon(p_.fileItem) ? item.contain : item.cover,
                p_.isMobileSoundtrack ? item.mobPreview : ''
            )}
            style={{ backgroundImage: `url(${filesHelpers.getSrc(p_.fileItem)})` }}
        >
            {p_.showBackLayer && <div className={styles.backLayer} />}
        </div>
    )
}

export const PreviewItem = SortableHandle(PreviewItemInner)

class PreviewIconInner extends React.Component {
    state = {
        hover: false,
    }

    render() {
        const s_ = this.state
        const p_ = this.props

        return (
            <div
                className={cn(styles.iconWrapper, { [styles.selected]: p_.selected })}
                onMouseEnter={() => this.setState({ hover: true })}
                onMouseLeave={() => this.setState({ hover: false })}
            >
                <Icon type={filesHelpers.getFileIconName(p_.fileItem)} color={'white'} size={12} />
                <div className={cn(item.abs, item.fullAbs, item.cursorMove)} />
                <Animation>
                    {!p_.isSorting && s_.hover && (
                        <div className={cn(styles.previewTip)}>
                            <PreviewItemInner {...p_} />
                        </div>
                    )}
                </Animation>
            </div>
        )
    }
}

const PreviewIcon = SortableHandle(PreviewIconInner)

const ListSortableHandleInner = (p_) => (
    <div className={cn(styles.sortableHandle, p_.hideSortHandle ? styles.hideHandle : '')}>
        <Icon type="sortable_handle" size={15} />
    </div>
)

const ListSortableHandle = SortableHandle(ListSortableHandleInner)

// Получить позицию элемента таймлинии по времени в относительных значениях
const calcPosition = (p_) => {
    const duration = getDuration(p_)
    const left = p_.contentItem.startTime / duration
    const width = p_.contentItem.duration / duration
    const maxLeftWidth = (p_.contentItem.duration + p_.contentItem.leftIndent) / duration
    const maxRightWidth = (p_.contentItem.duration + p_.contentItem.rightIndent) / duration

    return { left, width, maxLeftWidth, maxRightWidth }
}

const getDuration = (p_) => {
    if (p_.selectedArea !== -1) {
        return p_.duration
    }

    const content = editor.getContent(p_.selectedArea)
    return content.reduce((acc, item) => {
        acc += item.duration
        return acc
    }, 0)
}

class EditorListItem extends React.Component {
    constructor(p_) {
        super(p_)

        this.calcTime = this.calcTime.bind(this)
        const position = calcPosition(p_)
        this.state = {
            ...position,
            dir: '',
            active: [],
            editWebApp: false,
        }
        this.dragEnd = false
    }

    static getDerivedStateFromProps(props, state) {
        const position = calcPosition(props)
        const prevPosition = {
            left: state.left,
            width: state.width,
            maxLeftWidth: state.maxLeftWidth,
            maxRightWidth: state.maxRightWidth,
        }

        return !deepEqual(prevPosition, position) ? { ...state, ...position } : state
    }

    shouldComponentUpdate(p_, s_) {
        const prevState = this.state
        const prevProps = this.props

        return !deepEqual(p_, prevProps) || !deepEqual(s_, prevState)
    }

    // Обновление продолжительности и времени начала при ресайзе или сдвиге элемента на таймлинии
    calcTime(pos) {
        const p_ = this.props
        let duration = getDuration(p_)
        const start = pos.left * duration
        duration = pos.width * duration

        editor.updateTime(start, duration, p_.selectedArea, p_.contentIndex)
        return true
    }

    // Максимальная ширина при ресайзе, чтобы не было пересечений по времени
    getMaxWidth = () => {
        const s_ = this.state

        if (s_.dir === 'left') {
            return s_.maxLeftWidth
        }
        if (s_.dir === 'right') {
            return s_.maxRightWidth
        }
    }

    // Событие на редактирование (Текст или таблица)
    onEdit = (e) => {
        const p_ = this.props
        if (p_.fileItem.fileType === 'web_app') {
            this.setState({ editWebApp: true })
            return
        }

        p_.onEdit(e)
        editor.showToolbar(true)
    }

    onCloseEditingWidget = () => {
        this.setState({ editWebApp: false })
    }

    handleCheck = (checked) => {
        const p_ = this.props
        editor.onMultiSelect(checked, p_.inPageContentId, p_.fileItem.id)
    }

    renderSourceTags = () => {
        const p_ = this.props
        const tags = p_.fileItem.tags.map((tag) => tag.name).join(', ')

        return (
            <div className={grid.w20}>
                <DotTooltip
                    tooltip={{
                        html: tags,
                    }}
                    containerClass={cn(grid.w100)}
                >
                    <div className={item.ellipsis}>{tags}</div>
                </DotTooltip>
            </div>
        )
    }

    handleSelectContent = () => {
        const p_ = this.props
        editor.selectContent(p_.selectedArea, p_.contentIndex, !p_.minimized)
    }

    onResize = (pos, dir) => {
        const { dir: oldDir } = this.state

        if (dir === oldDir) return

        this.setState({ dir })
    }

    onMouseDown = () => {
        const { selectedArea, contentIndex, minimized, showAllAreas } = this.props

        editor.selectContent(selectedArea, contentIndex, !minimized, {
            notClearAllAreas: showAllAreas,
        })
    }

    render() {
        const p_ = this.props
        const s_ = this.state
        const duration = getDuration(p_)
        const isMobileSoundtrack = p_.isSoundtrack && p_.isMobile
        const isEditWebApp =
            p_.fileItem.fileType === 'web_app' &&
            p_.fileItem.data &&
            p_.fileItem.data.widgetInfo &&
            p_.fileItem.data.widgetInfo.isEditable
        const isShowEdit =
            p_.fileItem.type === 'text' || p_.fileItem.type === 'table' || p_.fileItem.type === 'link' || isEditWebApp
        const isSmall = p_.contentItem.duration / duration < 0.1
        const isListView = p_.areaViewType === 'list'
        const isSoundtrackList = p_.selectedArea === -1
        const isYView = p_.areaViewType === 'y'
        const isDynamicDuration =
            p_.fileItem.fileType === 'web_app' &&
            p_.fileItem.data &&
            p_.fileItem.data.widgetInfo &&
            p_.fileItem.data.widgetInfo.dynamicDurationQ
        if (isYView || isListView) {
            const name = p_.fileItem.fileType === 'text' ? helpers.strip_html_tags(p_.fileItem.name) : p_.fileItem.name
            const virtualScrollStyle = p_.virtualScrollStyle
            const location = { ...history.location }

            return (
                <div
                    className={cn({
                        [styles.yWrapper]: isYView,
                        [styles.listWrapper]: isListView,
                        [styles.listWrapperSelected]: isListView && p_.selected,
                    })}
                    style={{ ...(virtualScrollStyle || {}) }}
                >
                    <div className={styles.editorListWrapper} onMouseDown={this.handleSelectContent}>
                        {isListView && (
                            <div className={cn(styles.listPreview, isMobileSoundtrack ? styles.listPreviewMob : '')}>
                                <ListSortableHandle hideSortHandle={p_.hideSortHandle} />
                                {!isMobileSoundtrack && (
                                    <div className={styles.selectCheckbox}>
                                        <Checkbox
                                            checked={p_.selectedContent.includes(p_.inPageContentId)}
                                            onClick={this.handleCheck}
                                        />
                                    </div>
                                )}
                                <div className={styles.previewWrapper}>
                                    <PreviewItemInner {...{ ...p_, isMobileSoundtrack }} />
                                </div>
                            </div>
                        )}

                        {isYView && (
                            <PreviewIcon isSorting={p_.isSorting} selected={p_.selected} fileItem={p_.fileItem} />
                        )}

                        <div
                            className={cn({
                                [styles.time]: isYView,
                                [styles.timeList]: isListView,
                                [styles.timeListMob]: isListView && isSoundtrackList && isMobileSoundtrack,
                            })}
                        >
                            <div className={styles.timeInner}>
                                <Draggable
                                    left={isListView ? 0 : s_.left}
                                    width={isListView ? 1 : s_.width}
                                    height={1}
                                    cursor={'pointer'}
                                    selected={p_.selected}
                                    disableResize={isListView}
                                    disableDrag={isListView}
                                    borderColor={'cloud'}
                                    selectedBorderColor={'white'}
                                    enableResize={
                                        filesHelpers.isMedia(p_.fileItem.fileType) ? {} : { left: true, right: true }
                                    }
                                    onDragEnd={this.calcTime}
                                    onResizeEnd={this.calcTime}
                                    noBorder={isListView}
                                >
                                    <div
                                        className={cn(styles.duration, {
                                            [styles.selected]: p_.selected && isYView,
                                            [styles.duration_type_viewY]: isYView,
                                        })}
                                    >
                                        {isYView && (
                                            <div className={styles.name}>
                                                &nbsp;&nbsp;&nbsp;
                                                {name}
                                                {isDynamicDuration ? (
                                                    <div className={styles.nameIcon}>
                                                        <Icon type={'duration_dynamic'} color={'white'} size={16} />
                                                    </div>
                                                ) : (
                                                    ` (${editor.msDurationToS(p_.contentItem.duration)} ${translate(
                                                        'sec'
                                                    )}.)`
                                                )}
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </div>
                                        )}

                                        {isListView && (
                                            <div
                                                className={cn(
                                                    styles.audioInfoLine,
                                                    isMobileSoundtrack ? styles.audioInfoLineMob : ''
                                                )}
                                            >
                                                <div
                                                    className={
                                                        isSoundtrackList && !isMobileSoundtrack ? grid.w20 : grid.w40
                                                    }
                                                >
                                                    <DotTooltip
                                                        tooltip={{
                                                            html: translate(name),
                                                            position: 'left',
                                                            followCursor: true,
                                                        }}
                                                        containerClass={cn(grid.w100)}
                                                    >
                                                        <div className={item.ellipsis}>{name}</div>
                                                    </DotTooltip>
                                                </div>
                                                {isSoundtrackList && !isMobileSoundtrack && this.renderSourceTags()}
                                                <div className={grid.w30}>
                                                    {editor.msDurationToS(p_.contentItem.startTime)} {translate('sec')}.
                                                </div>
                                                <div className={grid.w30}>
                                                    {p_.fileItem.type === 'smartFolder'
                                                        ? '∞'
                                                        : editor.msDurationToS(p_.contentItem.duration) +
                                                          translate('sec')}
                                                </div>
                                            </div>
                                        )}

                                        {p_.selected && (
                                            <div
                                                className={cn(styles.delete, {
                                                    [styles.deleteSmall]: isSmall,
                                                    [styles.deleteList]: isListView,
                                                })}
                                            >
                                                {(isSmall || isShowEdit) && (
                                                    <DropMenu
                                                        gray={true}
                                                        indent={'none'}
                                                        active={s_.active}
                                                        onChange={(active) => this.setState({ active })}
                                                        items={[
                                                            {
                                                                id: 'options',
                                                                icon: 'options',
                                                                tooltip: {
                                                                    title: translate('edit'),
                                                                },
                                                                color: isYView ? 'white' : 'darkCloud',
                                                                children: (
                                                                    <div
                                                                        className={cn(styles.buttons, {
                                                                            [styles.buttonsSmall]: !isShowEdit,
                                                                        })}
                                                                        onMouseDown={(e) => e.stopPropagation()}
                                                                    >
                                                                        <div
                                                                            className={cn({
                                                                                [grid.mr_mini]: isShowEdit,
                                                                            })}
                                                                            onClick={p_.onDelete}
                                                                        >
                                                                            <Icon
                                                                                type={'delete'}
                                                                                size={15}
                                                                                tooltip={{
                                                                                    html: translate('delete'),
                                                                                    position: 'left',
                                                                                    disabled: false,
                                                                                    followCursor: true,
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {isShowEdit && (
                                                                            <div onClick={this.onEdit}>
                                                                                <Icon
                                                                                    type={'pencil'}
                                                                                    size={15}
                                                                                    tooltip={{
                                                                                        html: translate('edit'),
                                                                                        position: 'top',
                                                                                        disabled: false,
                                                                                        followCursor: true,
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ),
                                                            },
                                                        ]}
                                                    />
                                                )}

                                                {!isSmall && !isShowEdit && (
                                                    <div className={cn(grid.row)}>
                                                        {(p_.fileItem.fileType === 'web_app' &&
                                                            location.pathname === '/broadcasts/edit') ||
                                                        (p_.fileItem.fileType === 'web_app' &&
                                                            location.pathname === '/addContentToDevice') ||
                                                        (p_.fileItem.fileType === 'web_app' &&
                                                            location.pathname === '/broadcasts/addAdvanced') ? (
                                                            <div
                                                                className={cn(styles.openWidgetIcon)}
                                                                onMouseDown={(e) => e.stopPropagation()}
                                                                onClick={this.onEdit}
                                                            >
                                                                <Icon
                                                                    type={'open_widget_in_modal'}
                                                                    color={isYView ? 'white' : 'grey'}
                                                                    size={15}
                                                                    tooltip={{
                                                                        html: translate('openWidget'),
                                                                        position: 'left',
                                                                        disabled: false,
                                                                        followCursor: true,
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : null}
                                                        <div
                                                            onMouseDown={(e) => e.stopPropagation()}
                                                            onClick={() => {
                                                                p_.onDelete(p_.inPageContentId)
                                                            }}
                                                        >
                                                            <Icon
                                                                type={'delete'}
                                                                color={isYView ? 'white' : 'grey'}
                                                                size={15}
                                                                tooltip={{
                                                                    html: translate('delete'),
                                                                    position: 'left',
                                                                    disabled: false,
                                                                    followCursor: true,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </Draggable>
                            </div>
                        </div>
                    </div>

                    <Modal open={s_.editWebApp} onClose={this.onCloseEditingWidget}>
                        <PreviewWebApp
                            ref={(previewWebApp) => {
                                this.previewWebApp = previewWebApp
                            }}
                            item={p_.fileItem}
                            isEditable={true}
                            indents={'small'}
                            onClose={this.onCloseEditingWidget}
                        />

                        <Button
                            className={cn(styles.button)}
                            onClick={() => {
                                this.previewWebApp.save(() => {
                                    this.onCloseEditingWidget()
                                })
                            }}
                        >
                            {translate('save')}
                        </Button>
                    </Modal>
                </div>
            )
        }

        if (p_.areaViewType === 'x') {
            return (
                <AtomicDraggable
                    left={s_.left}
                    width={s_.width}
                    maxWidth={p_.autoPos ? undefined : this.getMaxWidth()}
                    height={1}
                    cursor={'pointer'}
                    isActive={p_.selected}
                    isRenderResizeElements={false}
                    onMouseDown={this.onMouseDown}
                    isDraggable={false}
                    isResizable={!filesHelpers.isMedia(p_.fileItem.fileType)}
                    resizeDirections={{ left: true, right: true }}
                    onResizeEnd={this.calcTime}
                    onResize={this.onResize}
                    resizableZIndex={1000}
                    activeBorderColor={colors.white}
                    borderColor={colors.darkCloud}
                >
                    <div className={cn(grid.full)}>
                        <PreviewItem {...p_} showBackLayer={p_.selected} />
                    </div>
                    {p_.selected && p_.isMobile && isSoundtrackList && (
                        <div className={styles.editSoundtrack} onClick={() => editor.showToolbar(true)}>
                            <Icon type={'pencil'} color={'white'} size={15} />
                        </div>
                    )}
                    {p_.selected && (
                        <div className={cn(styles.delete, { [styles.deleteSmall]: isSmall })}>
                            {(isSmall || isShowEdit) && (
                                <DropMenu
                                    gray={true}
                                    indent={'none'}
                                    active={s_.active}
                                    onChange={(active) => {
                                        this.setState({ active })
                                    }}
                                    items={[
                                        {
                                            id: 'options',
                                            icon: 'options',
                                            tooltip: {
                                                title: translate('edit'),
                                            },
                                            children: (
                                                <div
                                                    className={cn(styles.buttons, {
                                                        [styles.buttonsSmall]: !isShowEdit,
                                                    })}
                                                    onMouseDown={(e) => e.stopPropagation()}
                                                >
                                                    <div
                                                        className={cn({ [grid.mr_mini]: isShowEdit })}
                                                        onClick={p_.onDelete}
                                                    >
                                                        <Icon
                                                            type={'delete'}
                                                            size={15}
                                                            tooltip={{
                                                                html: translate('delete'),
                                                                position: 'left',
                                                                disabled: false,
                                                                followCursor: true,
                                                            }}
                                                        />
                                                    </div>
                                                    {isShowEdit && (
                                                        <div onClick={this.onEdit}>
                                                            <Icon
                                                                type={'pencil'}
                                                                size={15}
                                                                tooltip={{
                                                                    html: translate('edit'),
                                                                    position: 'top',
                                                                    disabled: false,
                                                                    followCursor: true,
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            ),
                                        },
                                    ]}
                                />
                            )}
                            {!isSmall && !isShowEdit && (
                                <div onMouseDown={(e) => e.stopPropagation()} onClick={p_.onDelete}>
                                    <Icon
                                        type={'delete'}
                                        color={'white'}
                                        size={15}
                                        tooltip={{
                                            html: translate('delete'),
                                            position: 'left',
                                            disabled: false,
                                            followCursor: true,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    <Modal open={s_.editWebApp} onClose={this.onCloseEditingWidget}>
                        <PreviewWebApp
                            ref={(previewWebApp) => {
                                this.previewWebApp = previewWebApp
                            }}
                            item={p_.fileItem}
                            isEditable={true}
                            onClose={this.onCloseEditingWidget}
                            indents={'small'}
                        />

                        <Button
                            className={cn(styles.button)}
                            onClick={() => {
                                this.previewWebApp.save(() => {
                                    this.onCloseEditingWidget()
                                })
                            }}
                        >
                            {translate('save')}
                        </Button>
                    </Modal>
                </AtomicDraggable>
            )
        }
    }
}

EditorListItem.propTypes = {
    selected: PropTypes.bool,
    selectedArea: PropTypes.number,
    fileItem: PropTypes.object,
    contentItem: PropTypes.object,
    inPageContentId: PropTypes.string,
    contentIndex: PropTypes.number,
    duration: PropTypes.number,
    onDelete: PropTypes.func,
    minimized: PropTypes.bool,
}

export default EditorListItem
