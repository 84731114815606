import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import { transitions } from 'blocks.app/config'
import 'blocks.simple/animation/animation.css'

const showMoreStyles = {
    content: {
        ...item.transitionErase
    },
    open: {
        animation: `showContent ${transitions.normal}ms ease-in`
    },
    close: {
        ...item.overNone
    }
}

addStyles(showMoreStyles)
export default showMoreStyles
