import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../theme'
import { textSizes } from 'theme/media'
import 'blocks.simple/animation/animation.css'
import { defaultThemeStyles } from './checkbox.theme'

const circle = {
    ...grid.rowCenter,
    width: 18,
    height: 18,
}

const animation = 'ripple-checkbox 0.6s ease-out'

const checkboxStyles: IStyles = {
    wrapper: {
        ...grid.w100,
        ...grid.row,
        ...grid.normalCenter,
        ...item.pointer,
    },
    area: {
        ...item.rel,
        borderRadius: 2,
        height: 18,
        marginRight: 7,
        verticalAlign: 'middle',
        width: 18,
        '&::before': {
            content: '""',
            ...item.abs,
            ...item.circle,
            ...grid.row,
            ...grid.center,
            ...grid.normalCenter,
            width: 18,
            height: 18,
        },
    },
    area_type_circle: {
        ...circle,
        border: `1px solid var(--var-module-ui-component-checkbox-style-circle-border, ${defaultThemeStyles['circle-border']})`,
    },
    area_type_circleInv: {
        ...circle,
        border: `1px solid var(--var-module-ui-component-checkbox-style-circleInv-border, ${defaultThemeStyles['circleInv-border']})`,
        backgroundColor: 'inherit',
    },
    area_type_circleDis: {
        ...circle,
        borderRadius: '50%',
        border: `1px solid var(--var-module-ui-component-checkbox-style-circleDis-border, ${defaultThemeStyles['circleInv-border']})`,
        backgroundColor: 'inherit',
    },
    area_type_white: {
        ...circle,
        backgroundColor: 'transparent',
        border: `1px solid var(--var-module-ui-component-checkbox-style-white-border, ${defaultThemeStyles['white-border']})`,
    },
    circleChecked: {
        border: `1px solid var(--var-module-ui-component-checkbox-style-circle-background, ${defaultThemeStyles['circle-background']})!important`,
        backgroundColor: `var(--var-module-ui-component-checkbox-style-circle-background, ${defaultThemeStyles['circle-background']})!important`,
    },
    circleAnimate: {
        '&::before': {
            backgroundColor: `var(--var-module-ui-component-checkbox-style-circle-background, ${defaultThemeStyles['circle-background']})`,
            animation,
        },
    },
    circleInvChecked: {
        border: `1px solid var(--var-module-ui-component-checkbox-style-circleInv-background, ${defaultThemeStyles['circleInv-background']})!important`,
        backgroundColor: `var(--var-module-ui-component-checkbox-style-circleInv-background, ${defaultThemeStyles['circleInv-background']})!important`,
    },
    circleInvAnimate: {
        '&::before': {
            backgroundColor: `var(--var-module-ui-component-checkbox-style-circleInv-background, ${defaultThemeStyles['circleInv-background']})`,
            animation,
        },
    },
    circleDisChecked: {
        border: `1px solid var(--var-module-ui-component-checkbox-style-circleDis-background, ${defaultThemeStyles['circleDis-background']})!important`,
        backgroundColor: `var(--var-module-ui-component-checkbox-style-circleDis-background, ${defaultThemeStyles['circleDis-background']})!important`,
    },
    circleDisAnimate: {
        '&::before': {
            backgroundColor: `var(--var-module-ui-component-checkbox-style-circleDis-background, ${defaultThemeStyles['circleDis-background']})`,
            animation,
        },
    },
    whiteChecked: {
        border: `1px solid var(--var-module-ui-component-checkbox-style-white-background, ${defaultThemeStyles['white-background']})!important`,
        backgroundColor: `var(--var-module-ui-component-checkbox-style-white-background, ${defaultThemeStyles['white-background']})!important`,
    },
    whiteAnimate: {
        '&::before': {
            backgroundColor: `var(--var-module-ui-component-checkbox-style-white-background, ${defaultThemeStyles['white-background']})`,
            animation,
        },
    },
    label: {
        ...grid.space,
        fontSize: textSizes.normalPlus,
    },
    label_type_circle: {
        color: `var(--var-module-ui-component-checkbox-style-circle-textMain, ${defaultThemeStyles['circle-textMain']})`,
    },
    label_type_circleInv: {
        color: `var(--var-module-ui-component-checkbox-style-circleInv-textMain, ${defaultThemeStyles['circleInv-textMain']})`,
    },
    label_type_circleDis: {
        color: `var(--var-module-ui-component-checkbox-style-circleDis-textMain, ${defaultThemeStyles['circleDis-textMain']})`,
    },
    label_type_white: {
        color: `var(--var-module-ui-component-checkbox-style-circleInv-textMain, ${defaultThemeStyles['circleInv-textMain']})`,
    },
}

addStyles(checkboxStyles)
export default checkboxStyles
