import React, { FC } from 'react'
import { cn } from 'ethcss'
import { styles } from './Radio-styles'
import { IRadioProps } from './Radio-types'
import { item } from 'theme'

export const Radio: FC<IRadioProps> = ({
    label,
    onClick,
    className = {},
    animate = true,
    selected = false,
    disabled = false,
    mod = 'default',
}) => {
    const isActive = selected && animate

    function handleClick(e: React.MouseEvent) {
        if (disabled) return

        onClick(!selected, e)
    }

    return (
        <div>
            <div className={cn(styles.Radio__wrapper, className, { [item.disabled]: disabled })} onClick={handleClick}>
                <div
                    className={cn(styles.Radio, styles[`Radio_type_${mod}`], {
                        [styles[`Radio_activated`]]: isActive,
                        [styles[`Radio_type_${mod}_activated`]]: isActive,
                    })}
                >
                    <div
                        className={cn(styles.Radio__inner, {
                            [styles[`Radio__inner_type_${mod}_selected`]]: selected,
                        })}
                    />
                </div>
                {label && <div className={cn(styles.Radio__label, styles[`Radio__label_type_${mod}`])}>{label}</div>}
            </div>
        </div>
    )
}
