import { useState } from 'react'
import { routes } from 'features/routes'
import { changeLocation } from 'features/routes'
import { userActions } from 'blocks.app/user/user.state'
import { useDispatch } from 'react-redux'

const BroadcastsAddVideowallMethods = () => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        selectedInfo: null,
        cols: 3,
        rows: 2,
        resolutionWidth: 1920,
        resolutionHeight: 1080,
        isOneContent: true,
    })
    const onSelectDisplay = (selectedInfo: any) => {
        if (!selectedInfo) {
            return
        }

        if (selectedInfo.type === 'group') {
            return
        }

        setState((prevState: any) => {
            return {
                ...prevState,
                selectedInfo,
            }
        })
        const { resolutionWidth, resolutionHeight } = selectedInfo
        if (resolutionWidth && resolutionHeight) {
            setState((prevState: any) => {
                return {
                    ...prevState,
                    resolutionWidth,
                    resolutionHeight,
                }
            })
        }
    }
    const submit = () => {
        const { cols, rows, resolutionWidth, resolutionHeight, isOneContent } = state
        dispatch(
            userActions.setTransition({
                resolutionWidth,
                resolutionHeight,
                isOneContent,
                cols,
                rows,
            })
        )

        changeLocation(`/${routes.addAdvancedBroadcast.path}`)
    }

    const validateCols = (cols: string) => {
        return cols !== '' && +cols >= 1 && +cols <= 30
    }

    const validateRows = (rows: string) => {
        return rows !== '' && +rows >= 1 && +rows <= 30
    }

    return {
        state,
        setState,
        onSelectDisplay,
        submit,
        validateCols,
        validateRows,
    }
}

// class BroadcastsAddVideowallMethods extends React.Component {
//     state = {
//         selectedInfo: null,
//         cols: 3,
//         rows: 2,
//         resolutionWidth: 1920,
//         resolutionHeight: 1080,
//         isOneContent: true,
//     }
//     onSelectDisplay = (selectedInfo) => {
//         if (!selectedInfo) {
//             return
//         }
//
//         if (selectedInfo.type === 'group') {
//             return
//         }
//
//         this.setState({ selectedInfo })
//         const { resolutionWidth, resolutionHeight } = selectedInfo
//         if (resolutionWidth && resolutionHeight) {
//             this.setState({ resolutionWidth, resolutionHeight })
//         }
//     }
//     submit = () => {
//         const { cols, rows, resolutionWidth, resolutionHeight, isOneContent } = this.state
//         const { setTransition } = this.props
//
//         setTransition({
//             resolutionWidth,
//             resolutionHeight,
//             isOneContent,
//             cols,
//             rows,
//         })
//
//         changeLocation(`/${routes.addAdvancedBroadcast.path}`)
//     }
//
//     validateCols(cols) {
//         return cols !== '' && cols >= 1 && cols <= 30
//     }
//
//     validateRows(rows) {
//         return rows !== '' && rows >= 1 && rows <= 30
//     }
// }

export default BroadcastsAddVideowallMethods
