import { addStyles } from 'ethcss'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { item, grid, IStyles } from '../../../theme'

const settingsFontsStyles: IStyles = {
    controls: {
        ...grid.row,
        ...grid.full,
        ...grid.center,
        ...item.rounded_bottom_mini,
        background: `var(--var-module-app-component-app-style-backgroundSub, ${defaultThemeStyles.backgroundSub})`,
    },
    header: {
        ...grid.row,
        ...grid.justify,
        ...grid.pr_mini,
        ...grid.pt_mini,
    },
    footer: {
        ...grid.full,
        ...grid.rowCenter,
        ...grid.p_mini,
    },
    active: {
        ...item.pointer,
    },
    disable: {
        ...item.disabled,
        opacity: 0.7,
    },
    list: {
        ...grid.pr_md,
        ...grid.pl_md,
        ...grid.pt_mini,
        ...grid.pb_mini,
    },
}

addStyles(settingsFontsStyles)
export default settingsFontsStyles
