import { useEffect, useState } from 'react'
import {
    IConfirmationEmailCode,
    IConfirmEmailInfo,
    ISendConfirmationEmailCodeRequest,
    IUseConfirmationEmail,
    IUseConfirmEmailCode,
} from './AuthenticationSettings-types'
import { TConfirmEmailDialogTypes } from 'organisms/ConfirmEmailDialog/ConfirmEmailDialog-types'
import { isExist, isNotEmptyString } from 'core/utils/coreUtil'
import { api } from 'core/api/ConnectionManager'
import { emitError, emitAppError } from 'features/appNotifications/AppNotifications.state'
import { useDispatch } from 'react-redux'

export const useConfirmationEmail = (code: string | null): IUseConfirmationEmail => {
    const dispatch = useDispatch()
    const [prefix, setPrefix] = useState<string | null>(null)
    const [attempts, setAttempts] = useState<number | null>(null)
    const [timeoutS, setTimeoutS] = useState<number | null>(null)
    const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [status, setStatus] = useState<TConfirmEmailDialogTypes>('idle')
    const [isLocalEmailConfirmed, setIsLocalEmailConfirmed] = useState<boolean>(false)
    const [initialCode, setInitialCode] = useState<string | null>(code)

    useEffect(() => {
        api.send<object, IConfirmEmailInfo | null>('getConfirmationEmailInfo', {})
            .then((response: IConfirmEmailInfo | null) => {
                if (response) {
                    setConfirmationEmailParams(response)
                }
            })
            .catch((error: any) => {
                dispatch(emitError('confirmationEmailCodeNotExist'))
            })
    }, [])

    useEffect(() => {
        if (!initialCode) return

        if (attempts && attempts > 0) {
            sendConfirmationEmailCode(initialCode)
        }
    }, [initialCode, attempts])

    useEffect(() => {
        if (!timeoutS) return

        const timer = setTimeout(() => {
            clearConfirmEmailParams()
            dispatch(emitError('confirmEmailTimeoutError'))
        }, timeoutS * 1000)
        return () => {
            clearTimeout(timer)
        }
    }, [timeoutS])

    const sendConfirmationEmailCode = (code: string) => {
        if (!isExist(code) || !isNotEmptyString(code)) return

        clearInitialCode()

        api.send<ISendConfirmationEmailCodeRequest, IConfirmationEmailCode | null>('sendConfirmationEmailCode', {
            code,
        })
            .then((data: IConfirmationEmailCode | null) => {
                onSendConfirmationEmailCode(data)
            })
            .catch((err: any) => {
                dispatch(emitError('confirmationEmailCodeError'))
                setIsModalOpen(true)
                setStatus('error')
            })
    }

    const onSendConfirmationEmailCode = (data: IConfirmationEmailCode | null) => {
        if (data) {
            if (data.attempts > 0) {
                setAttempts(data.attempts)
                dispatch(
                    emitAppError({
                        errorCode: 'confirmationEmailAttemptsRemaining',
                        message: data.attempts,
                    })
                )
                setIsModalOpen(true)
                setStatus('error')
            } else {
                clearConfirmEmailParams()
                dispatch(
                    emitAppError({
                        errorCode: 'confirmationEmailAttemptsRemaining',
                        message: 0,
                    })
                )
                setIsModalOpen(true)
                setStatus('error')
            }
        } else {
            setIsModalOpen(true)
            setStatus('success')
            clearConfirmEmailParams()
            setIsLocalEmailConfirmed(true)
        }
    }

    const clearConfirmEmailParams = () => {
        setAttempts(null)
        setPrefix(null)
        setTimeoutS(null)
    }

    const setConfirmationEmailParams = (data: IConfirmEmailInfo) => {
        let { prefix, timeoutS, attempts } = data

        setPrefix(prefix)
        setTimeoutS(timeoutS)
        setAttempts(attempts)
    }

    const closeModal = () => {
        setIsModalOpen(false)
        setStatus('idle')
    }

    const sendConfirmationEmail = () => {
        setIsSendingEmail(true)
        clearConfirmEmailParams()

        api.send<object, IConfirmEmailInfo>('sendConfirmationEmail', {})
            .then((response: IConfirmEmailInfo) => {
                if (response) {
                    setConfirmationEmailParams(response)
                }

                setIsSendingEmail(false)
            })
            .catch((err: any) => {
                setIsSendingEmail(false)
                dispatch(emitError('confirmationEmailNotSend'))
            })
    }

    const clearInitialCode = () => {
        setInitialCode(null)
    }

    return {
        isSendingEmail,
        prefix,
        status,
        attempts,
        isModalOpen,
        isLocalEmailConfirmed,
        sendConfirmationEmail,
        sendConfirmationEmailCode,
        closeModal,
        clearInitialCode,
    }
}

export const useConfirmEmailCode = (): IUseConfirmEmailCode => {
    const [code, setCode] = useState<string>('')
    const changeCode = (code: string) => {
        setCode(code)
    }

    return {
        code,
        changeCode,
    }
}
