import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { indents } from 'blocks.app/config'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { IStyles } from '../../../../theme'

const catalogTemplateReportTasksStyles: IStyles = {
    statusWrapper: {
        ...grid.full,
        ...grid.rowCenter,
    },
    status: {
        ...item.circle,
        width: indents.md,
        height: indents.md,
    },
    error: {
        backgroundColor: `var(--var-module-app-component-app-style-error, ${defaultThemeStyles.error})`,
    },
    success: {
        backgroundColor: `var(--var-module-app-component-app-style-online, ${defaultThemeStyles.online})`,
    },
    listWrapperClassName: {
        marginTop: '0 !important',
    },
}

addStyles(catalogTemplateReportTasksStyles)
export default catalogTemplateReportTasksStyles
