import React from 'react'
import { Tabs } from 'atoms/Tabs'

import translate from 'core/translate'

import styles from '../ui.jcss'

const UiTabs = () => (
    <div className={styles.blockPanel}>
        <div className={styles.title}>Табы</div>
        <div className={styles.content}>
            <Tabs active={'2'}>
                <div label={translate('createBroadcast')} tabid="1">
                    Таб {translate('createBroadcast')}
                </div>
                <div label={translate('digitalSignage_permissions')} tabid="2">
                    Таб {translate('digitalSignage_permissions')}
                </div>
                <div label={translate('advertisingBlockByDefault')} tabid="3">
                    Таб {translate('advertisingBlockByDefault')}
                </div>
                <div label={translate('mainSettings')} tabid="4">
                    Таб {translate('mainSettings')}
                </div>
            </Tabs>
        </div>
    </div>
)

export default UiTabs
