import React from 'react'
import Icon from 'blocks.simple/icon/icon'
import ItemUsers from '../../__item/_users/catalog__item_users'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import styles from './catalog__template_users.jcss'
import { cn } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import store from 'core/store'
import { autoLogout } from 'blocks.app/user/user.state'
import { ItemData } from '../../../../core/models/Template'

export default {
    getMethod: 'getUsers',
    emptySearchText: () => translate('notFound'),
    settingsPrefixes: 'users',
    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    getBackendModel: (item: ItemData) => api.send('getUserById', { id: item.id }, { hideLoader: true }),
    tile: ItemUsers,
    wrapperClassName: styles.wrapper,
    cellClassName: styles.cellWrapper,
    isEqual: (firstItem: ItemData, secondItem: ItemData) => firstItem.id === secondItem.id,
    fields: () => [
        { id: 'photo', name: translate('avatar') },
        { id: 'name', name: translate('name') },
        { id: 'email', name: translate('email') },
        { id: 'roles', name: translate('role') },
    ],
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    onCreateListeners: [(cb: any, clearList: string[]) => api.addObserver('userCreated', cb, clearList)],
    onUpdateListeners: [(cb: any, clearList: string[]) => api.addObserver('userUpdated', cb, clearList)],
    onDeleteListeners: [(cb: any, clearList: string[]) => api.addObserver('userDeleted', cb, clearList)],
    isFolder: () => {},
    onDelete: ({ id }: { id: number }) => {
        store.dispatch(autoLogout(id))
    },
    viewOptions: () => [
        {
            id: 'tile',
            name: translate('tile'),
            icon: 'tile',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
        {
            id: 'list',
            name: translate('list'),
            icon: 'list',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
    ],
    sortOptions: () => [
        { id: '-createdAt', name: translate('sortCreatedAt') },
        { id: 'name', name: translate('sortName') },
    ],
    list: {
        photo: ({ photo }: { photo: string }, active: boolean) => {
            if (photo) {
                return (
                    <div
                        className={cn(item.cover, item.circle)}
                        style={{
                            width: '30px',
                            height: '30px',
                            backgroundImage: `url(${photo})`,
                        }}
                    />
                )
            } else {
                return <Icon type="users" color={active ? 'white' : 'grey'} size="30" />
            }
        },
        roles: ({ roles }: { roles: { label: string }[] }) => {
            if (roles && roles[0]) {
                return roles[0].label
            }
        },
    },
}
