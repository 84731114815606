import { addStyles } from 'ethcss'
import { IStyles, grid } from 'theme'
import { indents, radiusSizes, textSizes } from 'theme/media'
import { colors } from 'theme/colors'

const BUTTON_WIDTH = 145
const MIN_SIZE_MODAL_WIDTH = 282
const MIN_SIZE_MODAL_HEIGHT = 164

export const styles: IStyles = {
    videoChatModalContentMinimized: {
        ...grid.flex,
        ...grid.auto,
        background: colors.black,
        borderRadius: radiusSizes.mini,
    },
    videoChatModalContentMinimized__content: {
        ...grid.flex,
        ...grid.auto,
    },
    videoChatModalContentMinimized__header: {
        position: 'absolute',
        top: 0,
        left: 0,
        ...grid.flex,
        ...grid.center,
        padding: indents.mini,
        width: '100%',
        zIndex: 200,
    },
    videoChatModalContentMinimized__footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        ...grid.flex,
        ...grid.center,
        padding: indents.mini,
        width: '100%',
    },
    videoChatModalContentMinimized__video: {
        ...grid.flex,
        ...grid.center,
        ...grid.normalCenter,
        height: MIN_SIZE_MODAL_HEIGHT,
        width: MIN_SIZE_MODAL_WIDTH,
        overflow: 'hidden',
    },
    videoChatModalContentMinimized__text: {
        paddingBottom: indents.mini,
        fontSize: textSizes.md,
        colors: colors.grey,
    },
    videoChatModalContentMinimized__button: {
        ...grid.flex,
        ...grid.center,
        width: BUTTON_WIDTH,
    },
}

addStyles(styles)
