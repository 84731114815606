import React, { FC, useEffect, useState } from 'react'
import { styles } from './distributives-styles'
import { DistributivesProps, DistributivesStatus, GetPlatformResponse } from './distributives-types'
import { Platform } from './models/platforms'
import { DistributivesHeader } from './organisms/DistributivesHeader'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import { PlatformCard } from './organisms/PlatformCard'
import { isNotEmptyArray } from 'core/utils/coreUtil'
import { ContentWithoutToolbar } from 'organisms/ContentWithoutToolbar'
import { checkAvailableModule } from '../../core/helpers/routes/routes'

const DistributivesComponent: FC<DistributivesProps> = () => {
    const [platforms, setPlatforms] = useState<Platform[]>([])
    const [status, setStatus] = useState<DistributivesStatus>('idle')

    async function getPlatforms() {
        try {
            let response = await api.send<object, GetPlatformResponse>('getPlatformsDistributives', {})

            setPlatforms(response)
            setStatus('success')
        } catch (err) {
            setPlatforms([])
            setStatus('error')
        }
    }

    useEffect(() => {
        getPlatforms()
    }, [])

    const isError = () => status === 'error'

    const render = () => {
        if (isError()) return

        return renderPlatformCards()
    }

    const sortPlatformByOrder = (prevPlatform: Platform, nextPlatform: Platform) =>
        prevPlatform.order - nextPlatform.order

    const renderPlatformCards = () => {
        return (
            isNotEmptyArray(platforms) &&
            platforms.sort(sortPlatformByOrder).map((platform) => {
                const { title } = platform

                return (
                    <div key={title} className={styles.distributives__platform}>
                        <PlatformCard platform={platform} />
                    </div>
                )
            })
        )
    }

    return (
        <ContentWithoutToolbar
            title={translate('distributives')}
            className={styles.distributives}
            wrapperClassName={styles.distributives__wrapper}
        >
            <DistributivesHeader onUpdate={getPlatforms} />
            <div className={styles.distributives__content}>{render()}</div>
        </ContentWithoutToolbar>
    )
}

export const Distributives = checkAvailableModule('distributive') ? DistributivesComponent : null
