import { addStyles } from 'ethcss'

const artsModulesStyles = {
    modalBackLayer: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.9)',
        zIndex: '10000',
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '101',
        width: '700px',
        height: '500px',
    },
    modalContent: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },

    modalListWrapper: {
        width: '47%',
        height: '100%',
        borderRadius: '5px',
        padding: '14px',
        fontSize: '16px',
        fontWeight: 'bold',
        boxSizing: 'border-box',
        border: '1px solid grey',
        overflow: 'auto',

        '&::-webkit-scrollbar': {
            borderRadius: 10,
            width: 6,
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            width: 6,
            background: '#b3b3b3',
        },
    },
    modalListElement: {
        color: '#000',
        padding: '3px 0',
    },
    elementActive: {
        background: '#cfcfd6',
    },
    modalSortPanel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '6%',
    },
    arrow: {
        width: '100%',
        textAlign: 'center',
        margin: '6px 0',
    },
    nav: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '50px',
        '& button': {
            margin: '0 10px',
        },
    },
}

addStyles(artsModulesStyles)
export default artsModulesStyles
