import { addStyles } from 'ethcss'
import { mediaCSS } from 'blocks.app/config'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { layers, indents } from 'blocks.app/config'
import { defaultThemeStyles as defaultThemeSidebarStyles } from 'blocks.app/sidebar/Sidebar-theme'
import { textSizes } from 'theme/media'
import translate from '../../../../core/translate'

const editorToolbarRightStyles = {
    files: {
        ...grid.fullHeight,
        overflowY: 'auto',
    },
    search: {
        ...grid.p_md,
        ...grid.pt_none,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-background, ${defaultThemeSidebarStyles.background})`,
    },
    header: {
        ...grid.row,
        borderBottom: `1px solid var(--var-module-sidebar-component-sidebar-style-border, ${defaultThemeSidebarStyles.border})`,
        marginBottom: '20px',
    },
    minimize: {
        cursor: 'pointer',
        borderRight: `1px solid var(--var-module-sidebar-component-sidebar-style-border, ${defaultThemeSidebarStyles.border})`,
    },
    catalogWrapper: {
        ...grid.spaceHeight,
        ...grid.col,
        ...grid.pr_micro,
        ...grid.pl_micro,
        overflowY: 'auto!important',
    },
    templatesWrapper: {
        ...grid.spaceHeight,
        ...grid.pb_md,
        overflowY: 'auto',
    },
    colsRight: {
        ...grid.fullHeight,
        boxShadow: '-1px 4px 4px rgba(0, 54, 136, 0.25)',
        ...item.transitionErase,
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-background, ${defaultThemeSidebarStyles.background})`,
        zIndex: layers.sticky + 2,

        [mediaCSS.tab]: {
            paddingTop: 44,
        },
    },
    colsRightDevice: {
        [mediaCSS.tabMini]: {
            position: 'fixed',
            width: '100%!important',
            right: 0,
            top: 0,
        },
    },
    toggle: {
        ...grid.row,
        ...grid.fullHeight,
    },
    toggleItem: {
        ...grid.rowCenter,
        ...grid.col2,
        ...grid.fullHeight,
        cursor: 'pointer',
        ...item.transitionErase,
        fontSize: textSizes.mini,
        borderRight: `1px solid var(--var-module-sidebar-component-sidebar-style-border, ${defaultThemeSidebarStyles.border})`,
        '&:last-child': {
            borderRight: 'none',
        },
        '&:hover': {
            color: `var(--var-module-sidebar-component-sidebar-style-activeToggleText, ${defaultThemeSidebarStyles.activeToggleText})`,
        },
    },
    toggleItemDisable: {
        color: `var(--var-module-sidebar-component-sidebar-style-disableToggleText, ${defaultThemeSidebarStyles.disableToggleText})`,
    },
    toggleItemActive: {
        color: `var(--var-module-sidebar-component-sidebar-style-activeToggleText, ${defaultThemeSidebarStyles.activeToggleText})`,
    },
    volumeWrapper: {
        ...grid.w100,
        ...grid.rowCenter,
        ...grid.pt_md,
    },
    volume: {
        ...grid.w70,
        boxShadow: '-1px 3px 4px 0px rgba(0, 0, 0, 0.25)',
        ...item.rounded_full_normal,
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-background, ${defaultThemeSidebarStyles.background})`,
    },
    label: {
        marginBottom: indents.micro,
    },
    modalContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    modalInner: {},
    inner: {
        border: '1px solid #000000',
        borderRadius: '11px',
        width: '631px',
        height: '587px',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            borderRadius: 10,
            width: 6,
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            width: 6,
            background: '#b3b3b3',
        },
    },
    pagesList: {
        display: 'flex',
        flexWrap: 'wrap',

        padding: '20px 0 0 20px',
    },
    pageItem: {
        position: 'relative',
        width: '90px',
        height: '120px',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    itemSelector: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        right: '10px',
        top: '10px',
        width: '20px',
        height: '20px',
        border: '1px solid #A2A2A2',
        borderRadius: '50%',
    },
    checkedIcon: {
        width: '12px',
    },
    selected: {
        background: '#A2A2A2',
    },
    pageName: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: '5px',
        fontSize: '11px',
        textAlign: 'center',
    },
    footer: {
        marginTop: '30px',
    },
    colorPicker: {
        position: 'relative',
    },
    colorPickerWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '45%',
    },
    colorLabel: {
        marginLeft: 10,
    },
}

addStyles(editorToolbarRightStyles)
export default editorToolbarRightStyles
