import React from 'react'
import {defaultThemeStyles} from '../FlowDiagram-theme'

export const ARROW_HEAD = 'markerHead'
export const ARROW_HEAD_INVERSE = 'markerHeadInversed'

const arrowColor = `var(--var-module-editor-component-flowDiagram-style-path, ${defaultThemeStyles.path})`

const FlowDiagramMarkers = () => (
    <svg>
        <defs>
            <marker id={ARROW_HEAD} markerWidth="6" markerHeight="4" refX="5" refY="2" orient="auto">
                <path d="M0,0 L0,4 L4,2 Z" style={{ fill: arrowColor }} />
            </marker>

            <marker id={ARROW_HEAD_INVERSE} markerWidth="6" markerHeight="4" refX="-1" refY="2" orient="auto">
                <path d="M4,0 L4,4 L0,2 Z" style={{ fill: arrowColor }} />
            </marker>
        </defs>
    </svg>
)

export default FlowDiagramMarkers
