import React from 'react';
import {editor} from '../editor.local.methods';

class EditorEventsMethods extends React.Component {
    state = {
        isOpenModal: false,
        event: null
    };
    onSave = (event, eventIndex) => {
        const p_ = this.props;
        let data = {};

        if (!p_.page.playOnEvent) {
            data = {
                compositionType: 'OR',
                events: [event]
            };
        } else {
            data = p_.page.playOnEvent;
            if (typeof eventIndex === 'number') {
                data.events[eventIndex] = event;
            } else {
                data.events.push(event);
            }
        }
        this.setState({isOpenModal: false});
        editor.setEventsToPage(data);
    };
    onDelete = (index) => {
        editor.deleteEventFromPage(index);
    };
    onChangePage = ({id}) => {
        editor.setNextPage(id);
    }
}

export default EditorEventsMethods