import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import {defaultThemeStyles as defaultThemeSidebarStyles} from 'blocks.app/sidebar/Sidebar-theme'
import {defaultThemeStyles as defaultThemeAppStyles} from 'blocks.app/app/app.theme'

const editorEventsStyles = {
    eventsWrapper: {
        ...grid.fullWidth,
    },
    title: {
        ...grid.row,
        ...grid.justify,
        ...grid.p_md,
        ...grid.mt_mdPlus,
        textAlign: 'center',
        color: `var(--var-module-sidebar-component-sidebar-style-title, ${defaultThemeSidebarStyles.title})`,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        borderTop: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    emptyMessage: {
        textAlign: 'center',
        ...grid.m_md,
    },
    action: {
        ...grid.row,
        ...grid.justify,
        ...grid.noWrap,
        ...grid.p_md,
    },
    eventTitle: {
        ...item.ellipsis,
    },
    eventIcons: {
        ...grid.row,
        ...grid.noWrap,
    },
    nextPage: {
        ...grid.fullWidth,
        ...grid.rowCenter,
        ...grid.pt_md,
        ...grid.pb_md,
    },
}

addStyles(editorEventsStyles)
export default editorEventsStyles
