import { useEffect, useState } from 'react'

import {
    IAcceptWebRTCConnectionResponse,
    ICreateWebRTCConnectionResponse,
    IGetStunServersResponse,
    IRejectWebRTCConnectionResponse,
    IResetWebRTCConnectionRequest,
    ISendWebRTCRequest,
    IStunServer,
    IUseVideoChatCall,
    IVideoChatPayloadResponse,
    TWebRTCCallStatus,
    ICreateWebRTCConnectionRequest,
} from './VideoChatModalConnection-types'
import { TVideoCallState, IVideoChatModalOptions, videochatActions } from 'pages/videoChat/videoChat.state'
import {
    getUserMediaStream,
    checkMediaDevicesSupported,
    isNotEmptyArray,
    initPeerConnection,
} from 'core/utils/coreUtil'
import { TConnectionStatus } from 'core/models/Connection'
import { api } from 'core/api/ConnectionManager'
import { useDispatch } from 'react-redux'
import { emitError } from 'features/appNotifications/AppNotifications.state'

export const useVideoChatCall = (
    videoCallState: TVideoCallState,
    digitalSignage: any,
    connectionStatus: TConnectionStatus
): IUseVideoChatCall => {
    const dispatch = useDispatch()
    const [stunServers, setStunServers] = useState<IStunServer[]>([])
    const [isAvailableMediaDevices, setIsAvailableMediaDevices] = useState<boolean>(false)
    const [isMediaDevicesInit, setInitMediaDevices] = useState<boolean>(false)
    const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)
    const [remoteMediaStream, setRemoteMediaStream] = useState<MediaStream | null>(null)
    const [peerConnection, setPeerConnection] = useState<RTCPeerConnection | null>(null)
    const [offer, setOffer] = useState<RTCSessionDescriptionInit | null>(null)
    const [callStatus, setCallStatus] = useState<TWebRTCCallStatus>('idle')
    const [videoChatId, setVideoChatId] = useState<string | null>(null)
    const [answer, setAnswer] = useState<RTCSessionDescriptionInit | null>(null)
    const [timeoutS, setTimeoutS] = useState<number | null>(null)
    const [iceCandidates, setIceCandidates] = useState<RTCIceCandidate[]>([])
    const [receivedIceCandidates, setReceivedIceCandidates] = useState<RTCIceCandidate[]>([])

    useEffect(() => {
        switch (videoCallState) {
            case 'calling': {
                checkMediaDevicesSupported({ audio: true, video: true }).then((isSupported) => {
                    if (isSupported) {
                        setIsAvailableMediaDevices(isSupported)
                    } else {
                        dispatch(emitError('videoChatMediaDevicesNotAvailable'))
                        onVideoChatError()
                    }
                })
            }

            default: {
                setStunServers([])
            }
        }
    }, [videoCallState])

    useEffect(() => {
        if (!isAvailableMediaDevices) return

        api.send<object, IGetStunServersResponse>('getStunServers', {})
            .then((response: IGetStunServersResponse) => {
                if (response.iceServers) {
                    setStunServers(response.iceServers)
                }
            })
            .catch((err: any) => {
                dispatch(emitError('videoChatCanNotStartCall'))
                onVideoChatError()
            })
    }, [isAvailableMediaDevices])

    useEffect(() => {
        if (!stunServers || !isNotEmptyArray(stunServers) || mediaStream) return

        getUserMediaStream({ audio: true, video: true }).then((stream) => {
            if (stream) {
                setMediaStream(stream)
                setInitMediaDevices(true)
            } else {
                dispatch(emitError('videoChatUserMediaNotAvailable'))
                onVideoChatError()
            }
        })
    }, [stunServers])

    useEffect(() => {
        if (!RTCPeerConnection || !RTCSessionDescription || !mediaStream || peerConnection) return

        initPeerConnection(
            stunServers,
            mediaStream,
            setRemoteMediaStream,
            handleIceCandidate,
            handleConnectionStateChange
        ).then(({ pc, offer }) => {
            if (pc) {
                setPeerConnection(pc)
            }

            if (offer) {
                setOffer(offer)
            }
        })
    }, [mediaStream])

    useEffect(() => {
        if (!offer || !peerConnection) return

        setCallStatus('init')

        api.send<ICreateWebRTCConnectionRequest, ICreateWebRTCConnectionResponse>('createWebRTCConnection', {
            digitalSignageId: digitalSignage.id,
            offer: offer,
        })
            .then((response: ICreateWebRTCConnectionResponse) => {
                setCallStatus('pending')
                setVideoChatId(response.videoChatId)
                setTimeoutS(response.timeoutS)
            })
            .catch((err: any) => {
                dispatch(emitError('videoChatCanNotCreateConnection'))
                onVideoChatError()
            })
    }, [offer, peerConnection])

    useEffect(() => {
        const onAcceptWebRTCConnection = (response: IAcceptWebRTCConnectionResponse) => {
            if (response && response.videoChatId === videoChatId) {
                if (response.answer) {
                    setAnswer(response.answer)
                } else {
                    dispatch(emitError('videoChatCanNotStartCall'))
                    onVideoChatError()
                }
            }
        }

        const listenersId: string[] = []

        if (callStatus !== 'idle') {
            api.addObserver<IAcceptWebRTCConnectionResponse>(
                'onAcceptWebRTCConnection',
                onAcceptWebRTCConnection,
                listenersId
            )
        }

        return () => {
            listenersId.forEach((id) => api.removeObserver(id))
        }
    }, [callStatus, videoChatId])

    useEffect(() => {
        const onVideoChatPayload = (response: IVideoChatPayloadResponse) => {
            if (response && response.videoChatId === videoChatId) {
                if (response.payload && response.payload.iceCandidates) {
                    setReceivedIceCandidates(response.payload.iceCandidates)
                } else {
                    dispatch(emitError('videoChatCanNotStartCall'))
                    onVideoChatError()
                }
            }
        }

        const listenersId: string[] = []

        if (callStatus !== 'idle') {
            api.addObserver<IVideoChatPayloadResponse>('onVideoChatPayload', onVideoChatPayload, listenersId)
        }

        return () => {
            listenersId.forEach((id) => api.removeObserver(id))
        }
    }, [callStatus, videoChatId])

    useEffect(() => {
        if (callStatus !== 'confirm' || !answer || !videoChatId) return

        api.send<ISendWebRTCRequest, null>('sendWebRTCPayload', {
            videoChatId,
            payload: {
                iceCandidates,
            },
        })
            .then(() => {})
            .catch((err: any) => {
                dispatch(emitError('videoChatCanNotStartCall'))
                onVideoChatError()
            })
    }, [callStatus, answer, videoChatId])

    useEffect(() => {
        const onResetWebRTCConnection = (response: IRejectWebRTCConnectionResponse) => {
            if (response && response.videoChatId === videoChatId) {
                dispatch(emitError('videoChatCallRejected'))
                onVideoChatError()
            }
        }

        const listenersId: string[] = []

        if (callStatus !== 'idle' && videoChatId) {
            api.addObserver<IRejectWebRTCConnectionResponse>(
                'onResetWebRTCConnection',
                onResetWebRTCConnection,
                listenersId
            )
        }

        return () => {
            listenersId.forEach((id) => api.removeObserver(id))
        }
    }, [callStatus, videoChatId])

    useEffect(() => {
        if (!answer || !peerConnection || remoteMediaStream) return

        peerConnection
            .setRemoteDescription(new RTCSessionDescription(answer))
            .then(() => {
                setCallStatus('confirm')
            })
            .catch(() => {
                dispatch(emitError('videoChatCanNotStartCall'))
                onVideoChatError()
            })
    }, [answer])

    useEffect(() => {
        if (!timeoutS || answer) return

        const timer = setTimeout(() => {
            dispatch(emitError('videoChatCallTimeout'))
            onVideoChatError()
        }, timeoutS * 1000)

        return () => {
            clearTimeout(timer)
        }
    }, [timeoutS, answer])

    useEffect(() => {
        if (connectionStatus === 'disconnect') {
            dispatch(emitError('videoChatCallDisconnected'))
            onVideoChatError()
        }
    }, [connectionStatus])

    useEffect(() => {
        if (!receivedIceCandidates) return

        receivedIceCandidates.forEach((ice) => {
            if (peerConnection) {
                peerConnection.addIceCandidate(ice)
            }
        })
    }, [receivedIceCandidates])

    const handleConnectionStateChange = (connectionState: RTCPeerConnectionState) => {
        switch (connectionState) {
            case 'disconnected': {
                dispatch(emitError('videoChatCallDisconnected'))
                onVideoChatError()
            }

            case 'failed': {
                dispatch(emitError('videoChatCallDisconnected'))
                onVideoChatError()
            }
        }
    }

    const handleIceCandidate = (iceCandidate: RTCIceCandidate) => {
        setIceCandidates((candidates) => {
            return [...candidates, iceCandidate]
        })
    }

    const closeMediaStream = () => {
        if (!mediaStream) return

        mediaStream.getTracks().forEach((track) => track.stop())
        setMediaStream(null)
    }

    const closePeerConnection = () => {
        if (!peerConnection) return

        peerConnection.close()
        setPeerConnection(null)
        setOffer(null)
        setAnswer(null)
        setRemoteMediaStream(null)
    }

    const clearFlags = () => {
        setStunServers([])
        setIceCandidates([])
        setIsAvailableMediaDevices(false)
        setCallStatus('idle')
        setVideoChatId(null)
    }

    const resetVideoChat = () => {
        closeMediaStream()
        closePeerConnection()
        if (videoChatId) {
            closeVideoChat(videoChatId, 'On close')
        }

        clearFlags()
    }

    const onVideoChatError = () => {
        setCallStatus('reject')
        resetVideoChat()
        dispatch(videochatActions.closeVideoModal())
    }

    const closeVideoChat = (videoChatId: string, errorMessage: string) => {
        api.send<IResetWebRTCConnectionRequest, null>('resetWebRTCConnection', {
            videoChatId,
            errorMessage,
        }).then(() => {})
    }

    return {
        mediaStream,
        remoteMediaStream,
        isMediaDevicesInit,
        resetVideoChat,
        callStatus,
    }
}
