import { addStyles } from 'ethcss'
import { grid, item, IStyles } from 'theme'
import { colors } from 'theme'
import { textSizes } from 'theme/media'

const changelogStyles: IStyles = {
    wrapper: {
        ...grid.col,
        ...grid.full,
        ...grid.p_md,
        ...item.blackLight,
        color: colors.white,
        fontSize: textSizes.normalPlus,
    },
    header: {
        ...grid.row,
        ...grid.fullWidth,
        ...grid.justify,
    },
    content: {
        ...grid.space,
        ...grid.col,
        ...grid.center,
        ...grid.normalCenter,
    },
    columns: {
        ...grid.row,
        ...grid.noWrap,
        ...grid.normalCenter,
        ...grid.justify,
    },
    centerCol: {
        ...grid.col,
        ...grid.w30,
        ...grid.center,
        ...grid.mr_big,
        ...grid.ml_big,
    },
    iconWrapper: {
        ...grid.p_md,
        ...item.circle,
        ...item.darkCloud,
        ...grid.row,
    },
    title: {
        ...grid.mt_big,
        ...grid.mb_big,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: textSizes.md,
    },
    description: {
        textAlign: 'center',
    },
}

addStyles(changelogStyles)
export default changelogStyles
