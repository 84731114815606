import React, { useEffect } from 'react'
import CollapseMethods from './collapse.local.methods'
import Icon from 'blocks.simple/icon/icon'
import Measure from 'react-measure'
import { cn } from 'ethcss'
import styles from './collapse.jcss'
import { textSizes } from 'blocks.app/config'

export interface ICollapseProps {
    onMouseOver?: () => void
    onMouseOut?: () => void
    disabled?: boolean
    showArrow: boolean
    toggleIconOptions?: {}
    contentWrapperClassName?: string
    contentPadding: boolean
    contentClassName?: string
    className?: {}
    activeClassName: string
    titleClassName?: string
    titleActiveClassName: string
    isLoaded: boolean
    title: (value: { open: boolean }) => {}
    children: React.ReactNode
    onToggle: (value: boolean) => void
    onClick?: any
    refObj?: any
    initialOpen?: boolean
    withoutOverflow: boolean
    noWrap?: boolean
}

const Collapse = (p_: ICollapseProps) => {
    const { open, withoutOverflow, dimensions, onClick, setDimensions } = CollapseMethods(p_)
    const getWrapperClassNames = (showContent: boolean) => {
        const { className, activeClassName } = p_

        return showContent ? cn(styles.wrapper, className, activeClassName) : cn(styles.wrapper, className)
    }

    const getTitleClassNames = (showContent: boolean) => {
        const { titleClassName, titleActiveClassName } = p_

        return showContent
            ? cn({ [styles.noWrap]: p_.noWrap }, styles.title, titleClassName, titleActiveClassName)
            : cn({ [styles.noWrap]: p_.noWrap }, styles.title, titleClassName)
    }

    const showContent = open && p_.isLoaded
    const { height } = dimensions

    if (p_.refObj) {
        p_.refObj.onClick = onClick
    }

    return (
        <div className={getWrapperClassNames(showContent)}>
            <div
                className={getTitleClassNames(showContent)}
                onClick={onClick}
                onMouseOver={p_.onMouseOver}
                onMouseOut={p_.onMouseOut}
            >
                {p_.title({ open: open })}
                {p_.showArrow && !p_.disabled && (
                    <Icon size={textSizes.normal} type={open ? 'arrow_up' : 'arrow_down'} {...p_.toggleIconOptions} />
                )}
            </div>
            <div
                className={cn(styles.contentWrapper, p_.contentWrapperClassName, {
                    // @ts-ignore
                    [styles.withoutOverflow]: withoutOverflow && showContent,
                })}
                style={{ maxHeight: showContent ? `${height || 0}px` : `0px` }}
            >
                <Measure
                    bounds
                    onResize={(contentRect) => {
                        setDimensions(contentRect.bounds)
                    }}
                >
                    {({ measureRef }) => (
                        <div
                            className={cn({ [styles.content]: p_.contentPadding }, p_.contentClassName)}
                            ref={measureRef}
                        >
                            {p_.children}
                        </div>
                    )}
                </Measure>
            </div>
        </div>
    )
}

Collapse.defaultProps = {
    isLoaded: true,
    contentPadding: true,
    showArrow: true,
    withoutOverflow: false,
    onToggle: () => {},
    activeClassName: '',
    titleActiveClassName: '',
}

export default Collapse
