import React from 'react'

import helpers from 'core/helpers'
import { api } from 'core/api/ConnectionManager'
class EditorFilesMethods extends React.Component {
    state = {
        isWebAppEdit: false,
        selectedContent: null,
        disposition: null,
        selectedInfo: null,
        selectedItems: [],
        disableDragUploads: false,
        editWebApp: false,
    }
    dragTimer = null

    setDisposition = (value) => {
        if (value === 'list') {
            this.setState({ disposition: 'tile' })
        } else {
            this.setState({ disposition: 'list' })
        }
    }
    onSelectInfo = (selectedInfo) => {
        const { setToolbar, setFolders } = this.props

        this.setState({ selectedInfo })

        if (!selectedInfo) {
            return
        }

        const data = {
            sourceId: selectedInfo.id,
            nestedQ: false,
        }

        const isDirectory = selectedInfo.isDirectory

        if (isDirectory) {
            delete data.sourceId
            data.sourceFolderId = selectedInfo.id
        }

        api.send('getAvailableFoldersFiles', data, { hideLoader: true }).then((availableFolders) => {
            const folders = helpers.addParentKeyIdForTree(availableFolders, 'folderId')
        })
    }

    editContent = (file) => {
        const { activateEditLink, activateEditTable, activateEditText } = this.props
        const nextState = {
            selectedContent: file,
        }

        if (!helpers.isAvailable({ key: 'files', action: 'update', entity: file })) {
            return
        }

        if (file.fileType === 'web_app') {
            this.setState({ editWebApp: true })
            nextState.isWebAppEdit = true
            return
        }

        const methods = {
            table: activateEditTable,
            link: activateEditLink,
            text: activateEditText,
        }

        const method = methods[file.type]

        if (method) {
            method(file.id)
        }

        this.setState({ ...nextState })
    }

    onCloseEditingWidget = () => {
        this.setState({ isWebAppEdit: false, selectedContent: null, editWebApp: false })
    }
}

export default EditorFilesMethods
