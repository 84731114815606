import { cn } from 'ethcss'
import React, { FC } from 'react'
import { ICustomScroll } from './CustomSroll-types'
import { styles, stylesWide } from './CustomScroll-styles'

export const CustomScroll: FC<ICustomScroll> = ({
    children,
    containerRef = null,
    scrollType,
    classNames,
    style = {},
}) => {
    return (
        <div
            ref={containerRef}
            className={cn(classNames, scrollType === 'wide' ? stylesWide.customScroll : styles.customScroll)}
            style={style}
        >
            {children}{' '}
        </div>
    )
}
