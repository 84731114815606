import React, { FunctionComponent } from 'react'
import Modal from 'molecules/Modal'
//import { Button } from 'atoms/Button'
import Button from 'blocks.simple/button/button'

import translate from 'core/translate'

import { IDialogComponent } from './Dialog-types'

import { cn } from 'ethcss'
import { dialogStyles } from './Dialog-styles'

export const Dialog: FunctionComponent<IDialogComponent> = ({
    open,
    padding,
    onClose,
    id,
    title,
    children,
    onAccept,
    onReject,
    modalClassName,
    rejectLabel = translate('close'),
    acceptLabel = translate('save'),
    dialogInnerClassName = cn(dialogStyles.inner, dialogStyles.responsive),
}) => (
    <Modal
        open={open}
        padding={padding}
        color="transparent"
        className={modalClassName}
        onClose={onClose}
        id={id}
        hideCloseIcon
    >
        <div className={cn(dialogInnerClassName)}>
            {title && <div className={dialogStyles.title}>{title}</div>}
            {children}
            {onAccept && onReject && (
                <div className={dialogStyles.buttons}>
                    <div className={cn(dialogStyles.buttonWrapper, dialogStyles.leftButton)}>
                        <Button mod={'withBorder'} className={dialogStyles.button} onClick={onReject}>
                            {rejectLabel}
                        </Button>
                    </div>
                    <div className={cn(dialogStyles.buttonWrapper, dialogStyles.rightButton)}>
                        <Button mod={'fill'} className={dialogStyles.button} onClick={onAccept}>
                            {acceptLabel}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    </Modal>
)
