import React from 'react'
import Dropdown from 'blocks.simple/dropdown/dropdown'

interface IEditableDropdownProps {
    id: string
    options: any[]
    config?: {}
    value?: string | number
    onChange: any
    onBlur: any
    mask?: string
    label?: string
    labelField?: string
    valueField?: string
    placeholder: string
    mod: string
    activeMod: string
    rounded: string
    showIconOnHover: boolean
    indentSize?: string
    onlyPlaceholder?: boolean
    disabled?: boolean
    type?: string
    searchable?: boolean
}

class EditableDropdown extends React.Component<IEditableDropdownProps> {
    static defaultProps: {
        onBlur: () => void
        showIconOnHover: boolean
        mod: string
        onChange: () => void
        rounded: string
        id: string
        activeMod: string
    }
    state: any

    constructor(p_: IEditableDropdownProps) {
        super(p_)

        this.state = {
            value: p_.value,
            focused: false,
        }
    }
    componentDidUpdate(prevProps: IEditableDropdownProps) {
        const p_ = this.props

        if (p_.value !== prevProps.value) {
            this.setState({ value: p_.value })
        }
    }
    render() {
        const s_ = this.state
        const p_ = this.props

        return (
            <Dropdown
                id={p_.id}
                placeholder={p_.placeholder}
                options={p_.options}
                mod={s_.focused ? p_.activeMod : p_.mod}
                rounded={p_.rounded}
                mask={p_.mask}
                value={s_.value}
                label={p_.label}
                labelField={p_.labelField}
                valueField={p_.valueField}
                onChange={(selected) => {
                    if (!p_.onlyPlaceholder) {
                        this.setState({ value: selected.id })
                    }

                    p_.onChange(selected)
                }}
                onBlur={function (value, e) {
                    p_.onBlur(value, e)
                }}
                onHide={() => this.setState({ focused: false })}
                onShow={() => this.setState({ focused: true })}
                showIconOnHover={p_.showIconOnHover}
                searchable={true}
                wrapperWidth={true}
                config={p_.config}
                indentSize={p_.indentSize}
                onlyPlaceholder={p_.onlyPlaceholder}
                disabled={p_.disabled}
            />
        )
    }
}

EditableDropdown.defaultProps = {
    id: 'autosize_input',
    onChange: () => {},
    onBlur: () => {},
    mod: 'none',
    activeMod: 'withShadow',
    rounded: 'none',
    showIconOnHover: true,
}

export default EditableDropdown
