import React from 'react'
import { Radio } from 'atoms/Radio'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './deviceSettings__browserMode.jcss'
import { Typography } from 'atoms/Typography'

const DeviceSettingsBrowserMode = (p_: {
    enabled: boolean
    value: string
    className: string | object
    onChange: (value: any, field: string) => void
}) => {
    if (!p_.enabled) {
        return null
    }

    function builtInBrowser() {
        p_.onChange(
            {
                openBrowser: 'internal',
            },
            'browserSettings'
        )
    }

    function externalBrowser() {
        p_.onChange(
            {
                openBrowser: 'external',
            },
            'browserSettings'
        )
    }

    return (
        <div className={cn(p_.className)}>
            <Typography className={cn(styles.title)} type="title">
                {translate('OpenLinksThrough')}:
            </Typography>
            <div className={styles.radioButtons}>
                <Radio
                    label={translate('builtInBrowser')}
                    selected={p_.value === 'internal'}
                    className={styles.radio}
                    onClick={builtInBrowser}
                />
                <Radio
                    label={translate('externalBrowser')}
                    selected={p_.value === 'external'}
                    onClick={externalBrowser}
                />
            </div>
        </div>
    )
}

DeviceSettingsBrowserMode.defaultProps = {
    value: 'internal',
}

export default React.memo(DeviceSettingsBrowserMode)
