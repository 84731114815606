type TCachedDebounceOptions = {
    wait?: number
    mounted?: boolean
}

type TDebounceCache = {
    data: Array<any>
    debounce: any
}

interface IDebounceCacheData {
    id: number
}

export function debounce(fn: (...args: any[]) => void, wait = 1) {
    let timeout: number
    return function (...args: any[]) {
        clearTimeout(timeout)
        timeout = window.setTimeout(() => fn.call(null, ...args), wait)
    }
}

export function createCachedDebounce(
    data: IDebounceCacheData,
    cache: TDebounceCache,
    cb: (data: IDebounceCacheData[]) => void,
    options: TCachedDebounceOptions = {}
) {
    const { wait = 800, mounted = true } = options
    const existingElementIndex = cache.data.findIndex((existElement) => existElement.id === data.id)

    if (existingElementIndex !== -1) {
        cache.data[existingElementIndex] = data
    } else {
        cache.data.push(data)
    }

    const constructDebounce = debounce(() => {
        if (!mounted) {
            return
        }

        cb([...cache.data])
        cache.data = []
        cache.debounce = null
    }, wait)

    if (!cache.debounce) {
        cache.debounce = constructDebounce
        cache.debounce()
    }
}
