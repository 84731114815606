import { addStyles } from 'ethcss'
import 'blocks.app/loader/loader.css'
import { colors, IStyles, mediaCSS } from '../../../theme'

const dsChangeServerStyles: IStyles = {
    modal: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(0,0,0,0.7)',
    },
    modalInner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: colors.white,
        borderRadius: 20,
        padding: 34,
        width: 530,

        border: '1px solid black',
    },
    fieldGroup: {
        marginBottom: 20,
    },
    label: {
        marginBottom: 8,
    },
    serverUrlInput: {
        lineHeight: '1.3 !important',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        margin: 8,
        width: 100,
    },
    status: {
        textAlign: 'center',
        marginBottom: 30,
    },
    statusTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 700,
        marginBottom: 10,

        span: {
            marginLeft: 8,
        },
    },
    statusSuccess: {
        color: colors.blueAccent,
    },
    statusError: {
        color: colors.notificationRed,
    },
    warning: {
        marginBottom: 30,
        textAlign: 'center',
    },
}

addStyles(dsChangeServerStyles)
export default dsChangeServerStyles
