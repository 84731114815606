import { addStyles } from 'ethcss'
import grid from '../grid/styles.js'
import { radiusSizes, transitions } from '../media'
import { colors } from '../colors'

let radiusSizeStyles = {}

const item = {
    ...(() => {
        let key
        for (key in radiusSizes) {
            if (radiusSizes.hasOwnProperty(key)) {
                if (typeof radiusSizes[key] === 'string') {
                    radiusSizeStyles[`rounded_full_${key}`] = {
                        borderRadius: `${radiusSizes[key]}!important`,
                    }
                } else {
                    radiusSizeStyles[`rounded_full_${key}`] = {
                        borderRadius: `${radiusSizes[key]}px!important`,
                    }
                }

                radiusSizeStyles[`rounded_top_${key}`] = {
                    borderTopLeftRadius: radiusSizes[key],
                    borderTopRightRadius: radiusSizes[key],
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                }
                radiusSizeStyles[`rounded_right_${key}`] = {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: radiusSizes[key],
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: radiusSizes[key],
                }
                radiusSizeStyles[`rounded_left_${key}`] = {
                    borderTopLeftRadius: radiusSizes[key],
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: radiusSizes[key],
                    borderBottomRightRadius: 0,
                }
                radiusSizeStyles[`rounded_bottom_${key}`] = {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: radiusSizes[key],
                    borderBottomRightRadius: radiusSizes[key],
                }
            }
        }

        return radiusSizeStyles
    })(),
    ...(() => {
        const colorsStyles = {
            transparent: {
                backgroundColor: 'transparent',
            },
        }

        for (let key in colors) {
            if (colors.hasOwnProperty(key)) {
                colorsStyles[key] = {
                    backgroundColor: colors[key],
                }
            }
        }

        return colorsStyles
    })(),
    panel() {
        return {
            ...this.rounded_full_mini,
            ...grid.p_mini,
            backgroundColor: colors.white,
        }
    },
    panelLightTheme() {
        return {
            ...this.rounded_full_mini,
            ...grid.p_mini,
            backgroundColor: colors.greyLight,
        }
    },
    modalBackground: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
    },
    pointer: {
        cursor: 'pointer',
    },
    cursorGrab: {
        cursor: 'grab',
        '&:active': {
            cursor: 'grabbing',
        },
    },
    cursorMove: {
        cursor: 'move !important',
    },
    cursorDefault: {
        cursor: 'default',
    },
    hidden: {
        visibility: 'hidden',
    },
    visible: {
        visibility: 'visible',
    },
    rel: {
        position: 'relative',
    },
    abs: {
        position: 'absolute',
    },
    fullAbs: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    fixed: {
        position: 'fixed',
    },
    disableWrapper: {
        opacity: 0.5,
        cursor: 'not-allowed!important',
        pointerEvents: 'none',
    },
    block: {
        display: 'block',
    },
    blockImportant: {
        display: 'block !important',
    },
    none: {
        display: 'none',
    },
    overNone: {
        overflow: 'hidden',
    },
    overNoneImportant: {
        overflow: 'hidden !important',
    },
    overVisible: {
        overflow: 'visible',
    },
    overAuto: {
        overflow: 'auto',
    },
    ellipsisWrapper: {
        ...grid.space,
        width: 0,
        overflow: 'hidden',
    },
    ellipsis: {
        paddingTop: 1,
        paddingBottom: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    ellipsisPure: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    cover: {
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
    },
    coverSpace: {
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
    },
    contain: {
        width: '100%',
        height: '100%',
        backgroundSize: 'contain',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
    },
    containSpace: {
        width: '100%',
        backgroundSize: 'contain',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
    },
    transition: {
        transition: `${transitions.normal}ms linear`,
    },
    transitionErase: {
        transition: `${transitions.normal}ms ease-in-out`,
    },
    transitionEraseHeight: {
        transition: `height ${transitions.normal}ms ease-in-out`,
    },
    circle: {
        borderRadius: '100%',
    },
    relative: {
        position: 'relative',
    },
    disabled: {
        cursor: 'not-allowed !important',
    },
    disabledLayer: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        cursor: 'not-allowed !important',
    },
    indent_mini: {
        ...grid.pt_micro,
        ...grid.pb_micro,
        ...grid.pl_mini,
        ...grid.pr_mini,
    },
    indent_normal: {
        ...grid.pt_mini,
        ...grid.pb_mini,
        ...grid.pl_md,
        ...grid.pr_md,
    },
    indent_big: {
        ...grid.pr_mdPlus,
        ...grid.pl_mdPlus,
        ...grid.pt_md,
        ...grid.pb_md,
    },
    overY: {
        overflowY: 'auto',
    },
    overX: {
        overflowX: 'auto',
    },
    vLine: {
        display: 'inline-block',
        width: 0,
        height: '100%',
    },
    noSelection: {
        userSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',
        MozUserSelect: 'none',
    },
    /* new */
    microShadow: {
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.3)',
    },
    withShadow: {
        boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.3)',
    },
    miniShadow: {
        boxShadow: '-1px 3px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    shadow: {
        boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.3)',
    },
    shadowMd: {
        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)',
    },
    blackOpacity: {
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
    },
    card: {
        backgroundColor: `var(--var-module-app-component-modal-style-background, #FFFFFF)`,
        borderRadius: '5px!important',
        boxShadow: '0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%)',
        padding: '14px',
        width: '35%',
    },
    whereUseCard: {
        backgroundColor: `var(--var-module-app-component-modal-style-background, #FFFFFF)`,
        borderRadius: '10px!important',
        boxShadow: '0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%)',
        padding: '42px 56px 25px 56px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    propertyCard: {
        backgroundColor: `var(--var-module-app-component-modal-style-background, #FFFFFF)`,
        borderRadius: '10px!important',
        boxShadow: '0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%)',
        padding: '20px 20px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    propertyWidthCard: {
        minWidth: '25vw',
    },
}

addStyles(item)
export default item
