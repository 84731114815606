import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Icon from 'blocks.simple/icon/icon'
import Range from '../range'
import styles from './range_volume.jcss'
import { defaultThemeStyles } from '../Range-theme'
import { getThemeStyleValue } from '../../../theme/colors'
import { cn } from 'ethcss'

const VOLUME_RANGE_STEP = 5

const RangeVolume = ({ showVolumeCounter = true, ...p_ }) => {
    const [offset, setOffset] = useState(0)
    const icons = {
        max: 'volume_max',
        min: 'volume_mute',
        stepUp: 'volume_plus',
        stepDown: 'volume_minus',
        ...p_.icons,
    }

    const changeVolume = (type: string) => {
        let value = p_.volume

        if (type === 'plus') {
            value = p_.volume > 95 ? 100 : p_.volume + VOLUME_RANGE_STEP
        }

        if (type === 'minus') {
            value = p_.volume < VOLUME_RANGE_STEP ? 0 : p_.volume - VOLUME_RANGE_STEP
        }

        p_.onChange(value)
        p_.onAfterChange(value)
    }

    const volumeMinStateIcon = p_.volume ? icons.stepDown : icons.min
    const volumeMaxStateIcon = p_.volume === 100 ? icons.max : icons.stepUp
    const iconColor = getThemeStyleValue('ui', 'range', 'icon') || defaultThemeStyles.icon

    return (
        <div className={cn(styles.wrapper, p_.disabled ? styles.disabled : '')}>
            <div className={styles.icon}>
                <Icon
                    type={volumeMinStateIcon}
                    size="18"
                    color={iconColor}
                    onClick={function () {
                        changeVolume('minus')
                    }}
                />
            </div>
            <div className={styles.range}>
                {showVolumeCounter && (
                    <div className={styles.counter} style={{ left: `${offset}%` }}>
                        {parseInt(offset.toString(), 10)}%
                    </div>
                )}
                <Range
                    range
                    min={0}
                    max={100}
                    mod={p_.mod}
                    value={p_.volume}
                    handle={p_.handle}
                    onChange={p_.onChange}
                    onAfterChange={p_.onAfterChange}
                    getHandleOffset={(offset) => {
                        setOffset(offset)
                    }}
                />
            </div>
            <div className={styles.icon}>
                <Icon
                    type={volumeMaxStateIcon}
                    size="18"
                    color={iconColor}
                    onClick={function () {
                        changeVolume('plus')
                    }}
                />
            </div>
        </div>
    )
}

RangeVolume.propTypes = {
    onChange: PropTypes.func.isRequired,
    onAfterChange: PropTypes.func,
    handle: PropTypes.bool,
    mod: PropTypes.string,
    volume: PropTypes.number,
    icons: PropTypes.shape({
        min: PropTypes.string,
        max: PropTypes.string,
        stepDown: PropTypes.string,
        stepUp: PropTypes.string,
    }),
    showVolumeCounter: PropTypes.bool,
}

RangeVolume.defaultProps = {
    mod: 'darkBlue',
    onAfterChange: () => {},
}

export default RangeVolume
