import React, { FC, memo } from 'react'
import { colors, getThemeIconValue, getThemeStyleValue } from 'theme/colors'
import { IAppMenuItemComponent } from './AppMenuItem-types'
import { defaultThemeIcons } from '../AppMenu.theme'
import { styles } from './AppMenuItem-styles'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import Link from 'blocks.simple/link/link'
import Icon from 'blocks.simple/icon/icon'
import DotTooltip from 'blocks.simple/dotTooltip/dotTooltip'
import { mediaJS } from 'theme'
import { cn } from 'ethcss'
import { appActions } from 'blocks.app/app/app.state'
import { useDispatch } from 'react-redux'
import { actionStyles } from '../AppMenuActions-styles'
import { isHorizontalMenu } from 'core/helpers/menu'

export const AppMenuItem: FC<IAppMenuItemComponent> = memo((props) => {
    const horizontalMenu = isHorizontalMenu()
    const dispatch = useDispatch()
    const {
        routePath,
        isActive,
        item,
        label,
        action,
        isAnimateIcon,
        children,
        isClickable,
        minimize,
        activeMenuItem,
        isExternal,
        notificationsCount,
    } = props
    const activeIconColor = getThemeStyleValue('app', 'menu', 'active') || defaultThemeStyles.active
    const mainIconColor = getThemeStyleValue('app', 'menu', 'disable') || defaultThemeStyles.main
    const itemIcon = getThemeIconValue('app', 'menu', item.name) || defaultThemeIcons[item.name]
    const linkTo = isClickable ? routePath : null
    const href = isExternal && linkTo ? linkTo : null
    const target = isExternal && linkTo ? '_blank' : null

    const onClick = (e: React.MouseEvent) => {
        const isHasSubMenu = !!(item.children && item.children.length)

        if (mediaJS.tab.matches) {
            dispatch(appActions.toggleMobMenu(false))
        }

        if (action) {
            e.preventDefault()
            dispatch(appActions.setActiveMenuItem(null))
            action()
            return
        }

        if (minimize && isHasSubMenu) {
            const isAllreadyActiveMenu = activeMenuItem && activeMenuItem.menuId === item.action
            const activeMenu = isAllreadyActiveMenu ? null : { menuId: item.action }
            dispatch(appActions.setActiveMenuItem(activeMenu))
            return
        }

        if (isClickable) {
            dispatch(appActions.setActiveMenuItem(null))
        }
    }

    const iconTooltipOptions = (options: object) => ({
        ...options,
        position: 'right',
        distance: 25,
        popperOptions: {
            modifiers: { preventOverflow: { enabled: false }, hide: { enabled: false }, flip: { enabled: false } },
        },
    })

    type TProps = {
        iconType: string
    }

    const menuIcons = (name: string) => {
        const isHasSubMenu = !!(item.children && item.children.length)

        if (name === 'logout' && horizontalMenu) {
            return (
                <div className={styles.menuItem__text_x} style={{ width: '100%' }}>
                    <IconOfMenu iconType={'exit_icon_menu'} />
                </div>
            )
        } else if (name === 'globalProgress' && horizontalMenu) {
            return (
                <div className={styles.menuItem__text_x} style={{ width: '100%' }}>
                    <IconOfMenu iconType={'downloads_icon_menu'} />
                </div>
            )
        } else if (name === 'settings' && horizontalMenu) {
            return (
                <div className={styles.menuItem__text_x} style={{ width: '100%' }}>
                    <IconOfMenu iconType={'settings_icon_menu'} />
                </div>
            )
        } else if (name === 'usersActionsHistory' && horizontalMenu) {
            return (
                <div className={styles.menuItem__text_x} style={{ width: '100%' }}>
                    <IconOfMenu iconType={'history_icon_menu'} />
                </div>
            )
        } else if (name === 'distributive' && horizontalMenu) {
            return (
                <div className={styles.menuItem__text_x} style={{ width: '100%' }}>
                    <IconOfMenu iconType={'distributes_icon_menu'} />
                </div>
            )
        } else if (name === 'users' && horizontalMenu) {
            return (
                <div className={styles.menuItem__text_x} style={{ width: '100%' }}>
                    <IconOfMenu iconType={'users_icon_menu'} />
                    <div
                        className={cn(
                            {
                                [styles.menuItem__triangle_active]: isActive && horizontalMenu && isHasSubMenu,
                            },
                            horizontalMenu && isHasSubMenu ? styles.menuItem__triangle : ''
                        )}
                    ></div>
                </div>
            )
        } else if (name === 'licenses' && horizontalMenu) {
            return (
                <div className={styles.menuItem__text_x} style={{ width: '100%' }}>
                    <IconOfMenu iconType={'licenses'} />
                    <div
                        className={cn(
                            {
                                [styles.menuItem__triangle_active]: isActive && horizontalMenu && isHasSubMenu,
                            },
                            horizontalMenu && isHasSubMenu ? styles.menuItem__triangle : ''
                        )}
                    ></div>
                </div>
            )
        } else {
            return (
                <div
                    className={cn(styles.menuItem__text, horizontalMenu ? styles.menuItem__text_x : '')}
                    style={{ width: '100%' }}
                >
                    {label}
                    {name === 'licenses' && (
                        <Icon size={26} containerClass={styles.betaLabel} type={'beta'} color={colors.grey} />
                    )}
                    <div
                        className={cn(
                            {
                                [styles.menuItem__triangle_active]: isActive && horizontalMenu && isHasSubMenu,
                            },
                            horizontalMenu && isHasSubMenu ? styles.menuItem__triangle : ''
                        )}
                    ></div>
                </div>
            )
        }
    }

    const IconOfMenu: React.FC<TProps> = ({ iconType }) => {
        return (
            <Icon
                type={iconType}
                size={16}
                color={getThemeStyleValue('app', 'menu', 'textMain') || defaultThemeStyles.disable}
                tooltip={{
                    html: label,
                    zIndex: 99999,
                    unmountHTMLWhenHide: true,
                }}
            />
        )
    }

    const getIconName = (name: string) => {
        if (name === 'advertisingCampaigns') {
            return 'megaphone'
        }

        return name
    }

    const renderMenuItemContent = () => {
        return (
            <div
                className={cn(
                    styles.menuItem,
                    horizontalMenu && styles.menuItem_x,
                    {
                        [styles.menuItem_type_active]: isActive,
                        [styles.menuItem_type_default]: !isClickable,
                    },
                    horizontalMenu && isActive ? styles.menuItem_type_active_x : ''
                )}
            >
                <div className={styles.menuItem__content}>
                    {horizontalMenu ? null : item.name === 'notificationCenter' &&
                      notificationsCount &&
                      notificationsCount > 0 ? (
                        <>
                            <div className={styles.notificationsCount}>{notificationsCount}</div>
                            <Icon
                                color={isActive ? activeIconColor : mainIconColor}
                                className="menuIcon"
                                size={20}
                                type={itemIcon || getIconName(item.iconName)}
                                containerClass={cn({
                                    [styles.menuItem__icon_type_animate]: isAnimateIcon,
                                    [styles.menuItem__icon]: !minimize,
                                    [styles.menuItem__icon_minimize]: minimize,
                                    [styles.menuItem__icon_active]: isActive,
                                })}
                                tooltip={iconTooltipOptions({
                                    html: label,
                                    disabled: !minimize,
                                })}
                            />
                        </>
                    ) : (
                        <Icon
                            color={isActive ? activeIconColor : mainIconColor}
                            className="menuIcon"
                            size={20}
                            type={itemIcon || getIconName(item.iconName)}
                            containerClass={cn({
                                [styles.menuItem__icon_type_animate]: isAnimateIcon,
                                [styles.menuItem__icon]: !minimize,
                                [styles.menuItem__icon_minimize]: minimize,
                                [styles.menuItem__icon_active]: isActive,
                            })}
                            tooltip={iconTooltipOptions({
                                html: label,
                                disabled: !minimize,
                            })}
                        />
                    )}
                    <DotTooltip
                        tooltip={
                            !horizontalMenu
                                ? {
                                      html: label,
                                      position: 'left',
                                      disabled: false,
                                      followCursor: true,
                                  }
                                : undefined
                        }
                        containerClass={cn(
                            styles.menuItem__text,
                            horizontalMenu ? styles.menuItem__text_x : '',
                            styles.menuItem__text_type_with_transition,
                            {
                                [styles.menuItem__text_type_hidden]: minimize,
                            }
                        )}
                    >
                        {menuIcons(item.name)}
                    </DotTooltip>
                </div>
            </div>
        )
    }
    return (
        <div
            className={cn(
                styles.menuItem__wrapper,
                horizontalMenu ? { ...styles.menuItem__wrapper_x, ...actionStyles.menuItem__hover } : ''
            )}
        >
            <Link to={linkTo} href={href} mods={['noUnderline']} onClick={onClick} target={target}>
                {renderMenuItemContent()}
            </Link>
            {children}
        </div>
    )
})
