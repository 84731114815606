import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import { colors } from 'theme/colors'
import { IStyles } from 'theme'

const initSize = 6
const borderZIndex = 500
const cornerZIndex = 1000
export const styles: IStyles = {
    Draggable__noDrag: {
        'user-drag': 'none',
        'user-select': 'none',
        '-moz-user-select': 'none',
        '-webkit-user-drag': 'none',
        '-webkit-user-select': 'none',
        '-ms-user-select': 'none',
    },
    Draggable() {
        return {
            ...this.Draggable__noDrag,
            ...item.abs,
        }
    },
    Draggable__border() {
        return {
            ...this.Draggable__noDrag,
            ...item.abs,
            zIndex: borderZIndex,
        }
    },
    Draggable__borderV() {
        return {
            ...this.Draggable__border,
            width: initSize,
            top: 0,
            bottom: 0,
            cursor: 'ew-resize',
        }
    },
    Draggable__borderH() {
        return {
            ...this.Draggable__border,
            height: initSize,
            left: 0,
            right: 0,
            cursor: 'ns-resize',
        }
    },
    Draggable__border_type_right() {
        return {
            ...this.Draggable__borderV,
            right: -(initSize / 2),
        }
    },
    Draggable__border_type_left() {
        return {
            ...this.Draggable__borderV,
            left: -(initSize / 2),
        }
    },
    Draggable__border_type_bottom() {
        return {
            ...this.Draggable__borderH,
            bottom: -(initSize / 2),
        }
    },
    Draggable__border_type_top() {
        return {
            ...this.Draggable__borderH,
            top: -(initSize / 2),
        }
    },
    Draggable__corner() {
        return {
            ...this.Draggable__noDrag,
            ...item.abs,
            width: initSize,
            height: initSize,
            zIndex: cornerZIndex,
        }
    },
    Draggable__corner_type_topRight() {
        return {
            ...this.Draggable__corner,
            top: -(initSize / 2),
            right: -(initSize / 2),
            cursor: 'nesw-resize',
        }
    },
    Draggable__corner_type_bottomRight() {
        return {
            ...this.Draggable__corner,
            bottom: -(initSize / 2),
            right: -(initSize / 2),
            cursor: 'nwse-resize',
        }
    },
    Draggable__corner_type_bottomLeft() {
        return {
            ...this.Draggable__corner,
            bottom: -(initSize / 2),
            left: -(initSize / 2),
            cursor: 'nesw-resize',
        }
    },
    Draggable__corner_type_topLeft() {
        return {
            ...this.Draggable__corner,
            top: -(initSize / 2),
            left: -(initSize / 2),
            cursor: 'nwse-resize',
        }
    },
    Draggable__square: {
        background: colors.white,

        '&::before': {
            position: 'absolute',
            top: 1,
            left: 1,
            width: initSize - 2,
            height: initSize - 2,
            background: colors.white,
            content: '""',
        },
    },
    Draggable__square_type_corner: {},
    Draggable__square_type_side() {
        return {
            ...this.Draggable__corner,
            pointerEvents: 'none',
        }
    },
    Draggable__square_type_default: {},
    Draggable__square_type_contrast: {
        background: colors.white,

        '&::before': {
            background: colors.black,
        },
    },
    Draggable__square_position_bottom: {
        bottom: -(initSize / 2),
        left: '50%',
        marginLeft: -(initSize / 2),
    },
    Draggable__square_position_top: {
        top: -(initSize / 2),
        left: '50%',
        marginLeft: -(initSize / 2),
    },
    Draggable__square_position_right: {
        right: -(initSize / 2),
        top: '50%',
        marginTop: -(initSize / 2),
    },
    Draggable__square_position_left: {
        left: -(initSize / 2),
        top: '50%',
        marginTop: -(initSize / 2),
    },
}

addStyles(styles)
