import { useEffect, useState } from 'react'
import translate from 'core/translate'
import { api } from 'core/api/ConnectionManager'
import { getURLSearchParamsByLocation, getURLSearchParamsString } from 'features/routes/utils'
import { changeLocation, history } from 'features/routes'
import helpersSchedule from 'core/helpers/_schedule'
import { createMethods } from './schedule.state'
import helpers from 'core/helpers'
import moment from 'moment'

type Location = {
    pathname: string
    search: string
    hash: string
    state: any
}
type ScheduleType = {
    [index: string]: string
}
type FilterItem = {
    id: string
    name: string
    title: string
    type?: string
}
type Selected = {
    id: string
    name: string
}
type Event = {
    id: number
    title: string
    broadcastTitle: string
    start: number
    end: number
    endDate: number
}

const getModalStatus = (location: Location) => {
    const query = getURLSearchParamsByLocation(location)
    return query.scheduleData
}

let selectedItems: any = {
    displays: [],
    broadcasts: [],
}

const ScheduleMethods = (p_: any) => {
    const listenersId: string[] = []
    const [openModal, setOpenModal] = useState<boolean>(!getModalStatus(p_.location))

    const onScheduleDeleted = ({ id }: { id: number }) => {
        p_.onDelete(id)
    }
    const onScheduleUpdated = (item: { id: number }) => {
        p_.onUpdate(item)
    }
    useEffect(() => {
        api.addObserver('scheduleDeleted', onScheduleDeleted, listenersId)
        api.addObserver('scheduleUpdated', onScheduleUpdated, listenersId)

        p_.clearState()
        p_.setSelectedDate(moment(p_.selectedDate).toDate().getTime())

        const query = getURLSearchParamsByLocation(p_.location)
        selectedItems = setFilterList(query.scheduleData)

        p_.setTitle([translate('schedule')])

        return () => {
            selectedItems = {
                displays: [],
                broadcasts: [],
            }
            listenersId.forEach((id) => api.removeObserver(id))
        }
    }, [])

    useEffect(() => {
        const query = getURLSearchParamsByLocation(p_.location)

        selectedItems = setFilterList(query.scheduleData)
    }, [p_.location])

    const setFilterList = (scheduleData: string) => {
        const { setFilterList: setFilterListAction, getSchedules, clearSchedules } = p_
        const types: ScheduleType = helpersSchedule.getTypes()
        const listByType: { [index: string]: string } = helpersSchedule.getListByType()
        let filterList: FilterItem[] = []
        const req: { [index: string]: string[] } = {}
        const selectedItems: { [index: string]: any } = {
            displays: [],
            broadcasts: [],
        }

        if (scheduleData) {
            try {
                const _scheduleData = JSON.parse(scheduleData)

                for (let itemType in _scheduleData) {
                    if (_scheduleData.hasOwnProperty(itemType)) {
                        req[types[itemType]] = []
                        _scheduleData[itemType].forEach((filterItem: FilterItem) => {
                            req[types[itemType]].push(filterItem.id)
                            filterList.push({
                                ...filterItem,
                                type: itemType,
                            })

                            selectedItems[listByType[itemType]].push(
                                createMethods[itemType](filterItem.id, filterItem.name || filterItem.title)
                            )
                        })
                    }
                }
            } catch (e) {
                console.error('Cannot add items to filter in schedules')
            }
        }

        setFilterListAction(filterList)

        if (Object.keys(req).length > 0) {
            getSchedules({ req })
        } else {
            clearSchedules()
        }

        return selectedItems
    }
    const removeFilterItem = (filterItem: { [index: string]: string }) => {
        const query = getURLSearchParamsByLocation(history.location)
        let scheduleData: any = query.scheduleData

        if (!scheduleData) return

        try {
            scheduleData = JSON.parse(scheduleData)
            scheduleData[filterItem.type] = scheduleData[filterItem.type].filter(
                (listItem: any) => listItem.id !== filterItem.id
            )

            if (!scheduleData[filterItem.type].length) {
                delete scheduleData[filterItem.type]
            }

            const search = getURLSearchParamsString({
                ...query,
                scheduleData: !Object.keys(scheduleData).length ? undefined : JSON.stringify(scheduleData),
            })

            changeLocation({
                ...history.location,
                search,
            })
        } catch (e) {
            console.error('Cannot remove item from filter in schedules')
        }
    }
    const setScheduleData = (scheduleData: string) => {
        const query = getURLSearchParamsByLocation(history.location)
        const search = getURLSearchParamsString({
            ...query,
            scheduleData: JSON.stringify(scheduleData),
        })

        changeLocation({
            ...history.location,
            search,
        })
    }

    const onViewChange = (selected: Selected) => {
        const { updateUserSettings } = p_
        updateUserSettings({ data: { view: { schedule: selected.id } }, isSaveSettings: true })
    }

    const getDisposition = () => {
        const { user } = p_
        const viewParams = helpers.getViewParamsByUser('schedule', user)

        return viewParams.disposition
    }

    const getCalendarDate = () => {
        const { selectedDate } = p_

        return moment(selectedDate).toDate()
    }

    const getCalendarEvents = () => {
        const { events } = p_

        return events.map((event: Event) => ({
            ...event,
            start: moment(event.start).toDate(),
            end: moment(event.end).toDate(),
            endDate: moment(event.end).toDate(),
        }))
    }

    const onToday = () => {
        const { setSelectedDate } = p_
        const date = moment().toDate().getTime()

        setSelectedDate(moment().toDate().getTime())
        onNavigate(moment(date).format())
    }

    const onNavigate = (date: any) => {
        const { navigate } = p_

        navigate(moment(date).toDate().getTime())
    }

    return {
        openModal,
        selectedItems,
        setOpenModal,
        getDisposition,
        onViewChange,
        removeFilterItem,
        getCalendarDate,
        getCalendarEvents,
        onToday,
        onNavigate,
        setScheduleData,
    }
}

export default ScheduleMethods
