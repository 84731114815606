import React from 'react'
import PropTypes from 'prop-types'

import { editor } from '../editor.local.methods'
import deepEqual from 'fast-deep-equal'
import helpers from 'core/helpers'

class EditorDiff extends React.Component {
    broadcast = editor.copyBroadcast(this.props.broadcast)
    data = helpers.deepCopy(this.props.data)

    componentDidUpdate() {
        // Отслеживание изменений в моделе данных для возможности отмены изменений через "ctrl+z"
        const p_ = this.props
        const diffBroadcast = !deepEqual(this.broadcast, p_.broadcast)

        if (diffBroadcast) {
            if (editor.stepChanged) {
                editor.stepChanged = false
            } else {
                editor.addStep(this.broadcast, this.data)
            }
            this.broadcast = editor.copyBroadcast(p_.broadcast)
            this.data = helpers.deepCopy(p_.data)
        }
    }
    render() {
        return null
    }
}

EditorDiff.propTypes = {
    broadcast: PropTypes.object,
    data: PropTypes.object
}

export default EditorDiff
