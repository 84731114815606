import React, { FunctionComponent, useState } from 'react'
import { Icon } from 'molecules/Icon'
import { SearchInput } from 'molecules/SearchInput'

import { ISearchbarComponent, TSearchbarCustomContent, TSearcbarCustomContentSides } from './Searchbar-types'

import { cn } from 'ethcss'
import { searchbarStyles } from './Searchbar-styles'
import Dropdown from 'atoms/Dropdown'
import translate from '../../core/translate'

const findCurrentDropdownValue = (value: any, options: any[]) => options.find((option) => option.value === value)

const getCustomContent = (customContent: TSearchbarCustomContent | undefined, side: TSearcbarCustomContentSides) => {
    const renderSideMethod = customContent?.[side]
    if (typeof renderSideMethod === 'function') {
        return renderSideMethod()
    }

    return null
}

export const Searchbar: FunctionComponent<ISearchbarComponent> = ({
    value = '',
    onChange,
    placeholder,
    customContent,
    searchWrapperClassName = searchbarStyles.searchInputWrapper,
    formWrapper,
    dropdownClassName,
    className,
    view,
    sort,
    selection,
    withoutFilters,
}) => {
    const [openFilters, setOpenFilters] = useState<boolean>(false)
    const filterTypes = [
        { name: 'sort', value: sort },
        { name: 'selection', value: selection },
        { name: 'view', value: view },
    ]

    const toggleSearhbarFilters = () => {
        setOpenFilters(!openFilters)
    }

    if (withoutFilters) {
        return (
            <div className={cn(className)}>
                <form className={searchbarStyles.wrapper}>
                    <div className={cn(searchWrapperClassName)}>
                        <div className={searchbarStyles.searchInput}>
                            <SearchInput value={value} onChange={onChange} placeholder={placeholder} />
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    return (
        <div>
            <div className={cn(className)}>
                <form className={cn(formWrapper, searchbarStyles.wrapper)}>
                    <div className={cn(searchWrapperClassName)}>
                        <div className={searchbarStyles.searchInput}>
                            <SearchInput
                                value={value}
                                onChange={onChange}
                                placeholder={placeholder}
                                wrapperClassName={searchbarStyles.searchInput}
                            />
                        </div>
                        <div
                            className={cn(searchbarStyles.dropdownToggleIcon, {
                                [searchbarStyles.dropdownToggleIconActive]: openFilters,
                            })}
                        >
                            <Icon type="expand" size={24} onClick={toggleSearhbarFilters} />
                        </div>
                    </div>
                    <div
                        className={cn(dropdownClassName, searchbarStyles.dropdownWrapper, {
                            [searchbarStyles.dropdownToggle]: !openFilters,
                        })}
                    >
                        {getCustomContent(customContent, 'right')}
                        {filterTypes.map((filter, index) => {
                            if (!filter.value) {
                                return null
                            }

                            const convertedOptions = filter.value.options.map((option: any) => {
                                return {
                                    id: option.value,
                                    name: option.label,
                                }
                            })

                            return (
                                <Dropdown
                                    mod={'withShadow'}
                                    options={convertedOptions}
                                    value={
                                        findCurrentDropdownValue(filter.value.value, filter.value.options)
                                            ? findCurrentDropdownValue(filter.value.value, filter.value.options).value
                                            : null
                                    }
                                    icon={filter.name === 'selection' ? 'choice' : filter.name}
                                    iconSide={'left'}
                                    label={translate('choice')}
                                    valueKey={'id'}
                                    labelKey={'name'}
                                    onChange={(selected) => {
                                        filter.value.onChange({ value: selected.id, label: selected.name }, filter.name)
                                    }}
                                    wrapperClassName={cn(
                                        searchbarStyles.clearBorder,
                                        index + 1 !== filterTypes.length ? searchbarStyles.borderRight : ''
                                    )}
                                />
                            )
                        })}
                    </div>
                </form>
            </div>
            <div className={searchbarStyles.customContent}>{getCustomContent(customContent, 'left')}</div>
        </div>
    )
}
