import React from 'react'
import Sidebar from 'blocks.app/sidebar/sidebar'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import translate from 'core/translate'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { reportActions } from '../report.state'

type Location = {
    pathname: string
    search: string
    hash: string
    state: any
}

interface IReportLocation {
    report: any
    location: Location
}

const isSelected = (p_: IReportLocation) => {
    const query = getURLSearchParamsByLocation(p_.location)
    const reportType = query.reportType

    switch (reportType) {
        case 'files':
            return p_.report.files && p_.report.files.length > 0
        case 'broadcasts':
            return p_.report.broadcasts && p_.report.broadcasts.length > 0
        case 'advertisingCampaigns':
            return p_.report.advertisingCampaigns && p_.report.advertisingCampaigns.length > 0
        default:
            return p_.report.devices && p_.report.devices.length > 0
    }
}

const getTitle = (location: Location) => {
    const query = getURLSearchParamsByLocation(location)
    const reportType = query.reportType

    switch (reportType) {
        case 'files':
            return translate('reportFiles')
        case 'broadcasts':
            return translate('reportBroadcasts')
        case 'advertisingCampaigns':
            return translate('reportAdvertisingCampaigns')
        default:
            return translate('reportDevices')
    }
}

const getOptions = (p_: IReportLocation) => {
    const query = getURLSearchParamsByLocation(p_.location)
    const reportType = query.reportType

    switch (reportType) {
        case 'files':
            return p_.report.files
        case 'broadcasts':
            return p_.report.broadcasts
        case 'advertisingCampaigns':
            return p_.report.advertisingCampaigns
        default:
            return p_.report.devices
    }
}

const getEmptyTitle = (location: Location) => {
    const query = getURLSearchParamsByLocation(location)
    const reportType = query.reportType

    switch (reportType) {
        case 'files':
            return translate('noFilesSelected')
        case 'broadcasts':
            return translate('noBroadcastsSelected')
        case 'advertisingCampaigns':
            return translate('noAdvCampaignsSelected')
        default:
            return translate('noDevicesSelected')
    }
}

const getEmptyDescription = (location: Location) => {
    const query = getURLSearchParamsByLocation(location)
    const reportType = query.reportType

    switch (reportType) {
        case 'files':
            return ''
        case 'broadcasts':
            return ''
        default:
            return ''
    }
}

const getAction = (p_: any, list: any) => {
    const query = getURLSearchParamsByLocation(p_.location)
    const reportType = query.reportType

    switch (reportType) {
        case 'files':
            p_.setFiles(list)
            break
        case 'broadcasts':
            p_.setBroadcasts(list)
            break
        case 'advertisingCampaigns':
            p_.setAdvertisingCampaigns(list)
            break
        default:
            p_.setDevices(list)
    }
}

const ReportToolbar = ({ location }: { location: Location }) => {
    const dispatch = useDispatch()
    const report = useSelector<RootStateOrAny>((state) => state.report)
    const setFiles = (data: any) => dispatch(reportActions.setFiles(data))
    const setBroadcasts = (data: any) => dispatch(reportActions.setBroadcasts(data))
    const setAdvertisingCampaigns = (data: any) => dispatch(reportActions.setAdvertisingCampaigns(data))
    const setDevices = (data: any) => dispatch(reportActions.setDevices(data))

    return (
        <Sidebar
            selectedSide="right"
            onChange={(list: any) => {
                getAction({ setFiles, setBroadcasts, setAdvertisingCampaigns, setDevices, location }, list)
            }}
            content={{
                right: {
                    title: getTitle(location),
                    options: getOptions({ report, location }),
                },
            }}
            isEmpty={{
                right: !isSelected({ report, location }),
            }}
            emptyText={{
                right: {
                    title: getEmptyTitle(location),
                    description: getEmptyDescription(location),
                },
            }}
            onSelectInfo={() => {}}
        />
    )
}

export default ReportToolbar
