import React from 'react'
import Button from 'blocks.simple/button/button'
import DisplaysMap from '../../__map/_displays/catalog__map_displays'
import ItemDisplays from '../../__item/_displays/catalog__item_displays'
import Icon from 'blocks.simple/icon/icon'
import { api } from 'core/api/ConnectionManager'
import { addListener } from 'core/helpers'
import styles from './catalog__template_displays.jcss'
import helpers from 'core/helpers'
import helpersDigitalSignages from 'core/helpers/_digitalSignages'
import translate from 'core/translate'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
// @ts-ignore
import omitEmpty from 'omit-empty'
import { Status } from 'molecules/Status'
import { Marker } from 'atoms/Marker'
import { helpersMarker } from 'core/helpers/marker'
import { getParamsForMultipleMoveDisplays } from 'core/utils/displayUtil'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import store from 'core/store'
import { openApprove } from 'pages/displays/displays.state'
import { NoDevices } from './noDevices/NoDevices'
import { ItemData } from '../../../../core/models/Template'

const noBroadcastCover = helpersDigitalSignages.getNoBroadcastIconSrc()
let timer: any = null

const getGroupId = (instance: { parentId?: number; groupId?: number }) => {
    if (typeof instance.parentId !== 'undefined') {
        return instance.parentId
    }

    return instance.groupId
}

export default {
    folderName: 'parentId',
    getMethod: 'getDisplaysAndGroupsByParentId',
    getByFiltersMethod: 'getDisplaysAndGroupsByFilters',
    mediaCellClassName: styles.mediaCellClassName,
    customParams: {
        limit: 35,
        parentId: null,
    },
    getByFiltersParams: {
        limit: 35,
    },

    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noDevices'),
    emptyIcon: 'not_allow',
    customEmptyBlock: () => <NoDevices />,
    moveItem: (id: number, groupId: number) => api.send('editDisplay', { id, groupId }),
    moveFolder: (id: number, parentId: number) => api.send('editGroup', { id, parentId }),
    getDragData: (p_: { item: ItemData }) => {
        return JSON.stringify({ id: p_.item.id, type: p_.item.type })
    },
    multipleMove: (sourceId: number, sourceFolderId: number, parentId: number) => {
        return new Promise((resolve, reject) => {
            const displays = omitEmpty({
                sourceId,
                sourceFolderId,
                parentId,
            })

            const params = {
                digitalSignageId: displays.sourceId,
                groupId: displays.sourceFolderId,
                nestedQ: false,
            }

            api.send('getAvailableParentGroups', params)
                .then((res: any) => {
                    const targetGroup = res.find((group: { id: number }) => group.id === parentId)
                    return targetGroup.availableDestinationQ
                })
                .then((isAvailableForMove: boolean) => {
                    if (isAvailableForMove) {
                        const params = getParamsForMultipleMoveDisplays(
                            displays.sourceId,
                            displays.sourceFolderId,
                            displays.parentId
                        )
                        return api.send('moveDsAndGroups', params)
                    }
                    reject()
                    store.dispatch(emitError('noAccessTransferToGroup'))
                })
        })
    },
    addItemButton: () => {
        if (!helpers.isAvailable({ key: 'digitalSignage', action: 'create' })) {
            return null
        }

        return (
            <Button rounded="full_md" mod="withShadow" onClick={() => store.dispatch(openApprove(''))}>
                {translate('add')}
            </Button>
        )
    },
    map: (p_: any) => <DisplaysMap {...p_} />,
    settingsPrefixes: 'digitalSignage',
    // Активирует расширенный поиск
    allowAdvancedSearch: true,
    // showFilter: true,
    useNewFilter: true,
    tile: ItemDisplays,
    viewOptions: (pathname: string) => {
        if (pathname.includes('displays')) {
            return [
                {
                    id: 'tile',
                    name: translate('tile'),
                    icon: 'tile',
                    iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
                    iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
                },
                {
                    id: 'list',
                    name: translate('list'),
                    icon: 'list',
                    iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
                    iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
                },
                {
                    id: 'map',
                    name: translate('map'),
                    icon: 'location',
                    iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
                    iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
                },
            ]
        }

        return [
            {
                id: 'tile',
                name: translate('tile'),
                icon: 'tile',
                iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
                iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
            },
            {
                id: 'list',
                name: translate('list'),
                icon: 'list',
                iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
                iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
            },
        ]
    },
    sortOptions: () => [
        { id: '-createdAt', name: translate('sortCreatedAt') },
        { id: 'name', name: translate('byNameAsc') },
        { id: '-name', name: translate('byNameDesc') },
        { id: 'status', name: translate('sortStatusOnline') },
        { id: '-status', name: translate('sortStatusOffline') },
        { id: 'type', name: translate('devices') },
        { id: '-type', name: translate('groups') },
    ],
    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean, deviceTileView: string) => {
        const item = { ...dataItem }

        if (item.freeStorageSize) {
            item.freeStorageSize = (item.freeStorageSize / Math.pow(1024, 3)).toFixed(2) + ' GB'
        }
        if (item.usedStorageSize) {
            item.usedStorageSize = (item.usedStorageSize / Math.pow(1024, 3)).toFixed(2) + ' GB'
        }
        if (item.storageSize) {
            item.storageSize = (item.storageSize / Math.pow(1024, 3)).toFixed(2) + ' GB'
        }

        if (!item.cover) {
            item.cover = []
        }
        if (item.cover === 'ignore') {
            delete item.cover
        }

        if (item.type && deviceTileView !== 'simple') {
            item.cover = [...item.cover, ...[noBroadcastCover, noBroadcastCover, noBroadcastCover, noBroadcastCover]]
            item.cover = item.cover.slice(0, 4)
        }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }
        if (!item.type) {
            item.type = 'digitalSignage'
        }

        return item
    },
    getBackendModel: (item: ItemData) => {
        if (item.type !== 'group') {
            return api.send('getDisplay', { id: item.id }, { hideLoader: true })
        } else {
            return api.send('getGroup', { groupId: item.id }, { hideLoader: true })
        }
    },
    isEqual: helpersDigitalSignages.isEqual,
    onCreateListeners: [
        (cb: any, clearList: string[]) => api.addObserver('groupCreated', cb, clearList),
        (cb: any, clearList: string[]) => api.addObserver('displayCreated', cb, clearList),
    ],
    onUpdateListeners: [
        (cb: any, clearList: string[]) => api.addObserver('groupUpdated', cb, clearList),
        (cb: any, clearList: string[]) => api.addObserver('displayUpdated', cb, clearList),
        addListener.updateDisplayProgress,
    ],
    onDeleteListeners: [
        (cb: any, clearList: string[]) =>
            api.addObserver('groupDeleted', ({ id }: { id: number }) => cb({ id, type: 'group' }), clearList),
        (cb: any, clearList: string[]) =>
            api.addObserver(
                'displayDeleted',
                ({ id }: { id: number }) => cb({ id, type: 'digitalSignage' }),
                clearList
            ),
    ],
    isFolder: (item: ItemData) => item.type === 'group',
    init(callback: any) {
        //@ts-ignore
        const p_ = this.props

        Promise.all([api.send('getCustomListView', { view: 'DigitalSignage' }), api.send('getDsTags', {})]).then(
            (res: any) => {
                const fields: {
                    id: number | string
                    name: string
                }[] = []

                res[0].fields.forEach((item: { selected: boolean; field: string }) => {
                    if (item.selected) {
                        fields.push({
                            id: item.field,
                            name: translate(item.field),
                        })

                        if (item.field === 'name') {
                            fields.push({
                                id: 'identification',
                                name: translate('identification'),
                            })
                        }
                    }
                })

                let tags: { label: string; value: string; name: string; id: string }[] = res[1]
                tags.forEach((tag) => {
                    tag.label = tag.name
                    tag.value = tag.id
                })

                p_.onGetTags(tags)

                //@ts-ignore
                this.setState({ isInit: true, fields, tags }, callback)
            }
        )

        api.addObserver(
            'digitalSignageScreenshotCreated',
            ({
                digitalSignageId,
                id,
                url,
                createdAt,
                groupId,
            }: {
                digitalSignageId: number
                id: number
                url: string
                createdAt: string
                groupId: number
            }) => {
                //@ts-ignore
                const s_ = this.state
                //@ts-ignore
                const { selectedInfo } = this.props

                const dataItem = { id: digitalSignageId, type: 'digitalSignage' }
                const screenshotData = { id, url, createdAt }

                s_.list.forEach((listItem: ItemData, index: number) => {
                    if (this.isEqual(dataItem, listItem)) {
                        if (!s_.list[index].screenshots) {
                            s_.list[index].screenshots = []
                        }
                        s_.list[index].screenshots.push(screenshotData)
                    }
                })

                //@ts-ignore
                this.setState(s_)

                if (selectedInfo && selectedInfo.type === 'group' && selectedInfo.id === groupId) {
                    clearTimeout(timer)
                    timer = setTimeout(() => {
                        api.send('getGroup', { groupId: selectedInfo.id }).then((groupModel) => {
                            //@ts-ignore
                            this.updateList([groupModel])
                        })
                    }, 600)
                }
            },
            //@ts-ignore
            this.clearListenersList
        )
    },
    unmount() {},
    getBreadcrumbs(groupId: number) {
        let breadcrumbs = [{ name: translate('devices'), id: undefined }]

        if (!groupId) {
            //@ts-ignore
            this.setState({ breadcrumbs })
            return
        }

        api.send('getGroupBreadcrumbs', { groupId }).then((data: any) => {
            data.reverse()
            //@ts-ignore
            this.setState({ breadcrumbs: [...breadcrumbs, ...data] })
        })
    },
    getFilterField(
        s_: { tags: { id: string; name: string }[] },
        filter: any,
        query: { [index: string]: string },
        field: { id: string }
    ) {
        filter[field.id] = query[field.id].split('//')

        if (field.id === 'tags') {
            filter[field.id].forEach((fieldItem: string, index: number) => {
                const currentTag: { id: string; name: string } | undefined = s_.tags.find(
                    (tag) => tag.name === fieldItem
                )

                if (currentTag) {
                    filter[field.id][index] = currentTag.id
                }
            })
        }
    },
    moveOnUpdate({
        prev,
        next,
    }: {
        prev: { parentId?: number; groupId?: number }
        next: { parentId?: number; groupId?: number }
    }) {
        //@ts-ignore
        const p_ = this.props

        //@ts-ignore
        if (this.isMap(p_)) {
            return false
        }

        //@ts-ignore
        if (this.filter.hasActualSchedule) {
            return false
        }

        const prevId = getGroupId(prev)
        const nextId = getGroupId(next)

        return prevId !== nextId
    },
    list: {
        status: (p_: { type: string; descendantStatusCount: { status: string; count: number }[]; status: string }) => {
            if (p_.type === 'group' && p_.descendantStatusCount) {
                return (
                    <div className={styles.statusWrapper}>
                        {renderStatus(p_.descendantStatusCount, 'online')}
                        {renderStatus(p_.descendantStatusCount, 'offline')}
                        {renderStatus(p_.descendantStatusCount, 'error')}
                    </div>
                )
            }
            return translate(p_.status)
        },
        orientation: ({ orientation }: { orientation: number }) => {
            const displayOrientation = helpersDigitalSignages.getOrientation(orientation)

            if (!displayOrientation) {
                return null
            }

            return displayOrientation.name
        },
        identification: (item: { identificationTimeoutS: number }) => {
            return item.identificationTimeoutS ? (
                <Icon
                    type="identification"
                    size={18}
                    color={getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active}
                />
            ) : null
        },
        name: (
            { name, status, type }: { name: string; status: string; type: string },
            active: boolean,
            p_: undefined,
            nested: number
        ) => {
            const color: { [index: string]: string } = { offline: 'offline', error: 'error', online: 'online' }

            return (
                <div
                    className={styles.name}
                    style={{
                        marginLeft: 40 * nested,
                    }}
                >
                    <div className={styles.status}>
                        <Icon type={type === 'group' ? 'files' : 'display'} size={20} color={color[status]} />
                    </div>
                    <div>{name}</div>
                </div>
            )
        },
        createdAt: ({ createdAt }: { createdAt: string }) => helpers.getFormattedLocalDate(createdAt, 'DD/MM/YYYY'),
        lastConnection: ({ lastConnection }: { lastConnection: string }) =>
            helpers.getFormattedLocalDate(lastConnection, 'DD/MM/YYYY'),
        updatedAt: ({ updatedAt }: { updatedAt: string }) => helpers.getFormattedLocalDate(updatedAt, 'DD/MM/YYYY'),
        tags: ({ tags }: { tags: { name: string }[] }) => {
            if (tags) {
                return tags.map((tag) => tag.name).join(', ')
            } else {
                return null
            }
        },
        imei: ({ imei }: { imei: string }) => {
            if (Array.isArray(imei)) {
                return imei.join(', ')
            } else {
                return null
            }
        },
    },
}

const renderStatus = (
    descendantStatusCount: { status: string; count: number }[],
    status: 'offline' | 'online' | 'error'
) => {
    const statusInfo = descendantStatusCount.find((item) => item.status === status)

    return (
        <Status
            className={styles.listStatus}
            disable={statusInfo && statusInfo.count <= 0}
            marker={<Marker color={helpersMarker.getColorByStatus(status)} size={'small'} />}
        >
            {statusInfo && statusInfo.count}
        </Status>
    )
}
