import { useReducer } from 'react'
import { MapOptions } from '../models/map'

export type MapOptionsActionTypes = 'update'

export interface MapOptionsState extends MapOptions {}

export interface MapOptionsAction {
    type: MapOptionsActionTypes
    payload: MapOptions
}

const reducer = (state: MapOptionsState, action: MapOptionsAction) => {
    switch (action.type) {
        case 'update': {
            return { ...state, ...action.payload }
        }
        default: {
            return state
        }
    }
}

export const useMapOptions = (initialMapOptions: MapOptions) => {
    const [state, dispatch] = useReducer(reducer, initialMapOptions)

    const updateMapOptions = (mapOptions: MapOptions) =>
        dispatch({
            type: 'update',
            payload: mapOptions,
        })

    return {
        mapOptions: state,
        updateMapOptions,
    }
}
