import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles } from './CatalogBreadcrumbs-theme'
import { textSizes } from 'theme/media'

const catalogBreadcrumbsStyles = {
    breadcrumbsWrapper: {
        backgroundColor: `var(--var-module-catalog-component-breadcrumbs-style-background, ${defaultThemeStyles.background})`,
    },
    contentScroll: {
        ...item.overX,
        ...grid.row,
        ...grid.noWrap,
        maxWidth: '100%',
    },
    itemWrapper: {
        ...grid.row,
        ...grid.noWrap,
    },
    item: {
        ...grid.rowCenter,
        ...grid.noWrap,
        ...grid.pl_microPlus,
        ...grid.pr_microPlus,
        ...item.pointer,
        textDecoration: 'none',
        color: `var(--var-module-catalog-component-breadcrumbs-style-textMain, ${defaultThemeStyles.textMain})`,
        '&:hover': {
            '& .home_icon': {
                color: `var(--var-module-catalog-component-breadcrumbs-style-hover, ${defaultThemeStyles.hover})!important`,
            },
            '& .group_files_icon': {
                color: `var(--var-module-catalog-component-breadcrumbs-style-hover, ${defaultThemeStyles.hover})!important`,
            },
        },
    },
    active: {
        cursor: 'default',
        color: `var(--var-module-catalog-component-breadcrumbs-style-active, ${defaultThemeStyles.active})`,
        '&:hover': {
            color: `var(--var-module-catalog-component-breadcrumbs-style-hover, ${defaultThemeStyles.hover})!important`,
        },
        '& .home_icon': {
            cursor: 'default',
            color: `var(--var-module-catalog-component-breadcrumbs-style-active, ${defaultThemeStyles.active})!important`,
        },
        '& .group_files_icon': {
            cursor: 'default',
            color: `var(--var-module-catalog-component-breadcrumbs-style-active, ${defaultThemeStyles.active})!important`,
        },
    },
    itemDropActive: {
        color: `var(--var-module-catalog-component-breadcrumbs-style-drop, ${defaultThemeStyles.drop})`,
        backgroundColor: `var(--var-module-catalog-component-breadcrumbs-style-dropBackground, ${defaultThemeStyles.dropBackground})`,
        '& .home_icon': {
            color: `var(--var-module-catalog-component-breadcrumbs-style-drop, ${defaultThemeStyles.drop})!important`,
        },
        '& .group_files_icon': {
            color: `var(--var-module-catalog-component-breadcrumbs-style-drop, ${defaultThemeStyles.drop})!important`,
        },
    },
    indents: {
        ...grid.pt_md,
        ...grid.pb_md,
    },
    itemArrow: {
        cursor: 'default',
        '& .breadcrumbs__arrow': {
            cursor: 'default',
        },
    },
    text: {
        ...grid.rowCenter,
        textTransform: 'uppercase',
        fontSize: textSizes.normal,
        textDecoration: 'none',
        whiteSpace: 'nowrap',
    },
}

addStyles(catalogBreadcrumbsStyles)
export default catalogBreadcrumbsStyles
