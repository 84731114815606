import React, { FunctionComponent } from 'react'
import Content from 'blocks.app/content/content'
import Catalog from 'blocks.app/catalog/catalog'
import { cn } from 'ethcss'
import { styles } from './videoChat-styles'
import { IVideoChatComponent } from './videoChat-types'
import translate from 'core/translate'
import { isHorizontalMenu } from '../../core/helpers/menu'
import { useTypedSelector } from 'core/store/hooks'
import { useWindowResize } from 'core/hooks'
import { checkAvailableModule } from '../../core/helpers/routes/routes'

const VideoChatComponent: FunctionComponent<IVideoChatComponent> = ({ tokens, mainUser, user, ...rest }) => {
    const mainMenuType = useTypedSelector<any>((state) => state.user.data?.settings.mainMenuType)
    const mainMenuTypeCheck = () => mainMenuType
    mainMenuTypeCheck()
    const { width } = useWindowResize({ width: window.innerWidth, height: window.innerHeight })
    return (
        <Content title={translate('videoChat')}>
            <Catalog
                type="videoChat"
                breadcrumbsClassName={cn(styles.breadcrumbs, {
                    [styles.breadcrumbs_x]: width > 960 && isHorizontalMenu(),
                })}
                isExistRightToolbar={false}
                fixedHeader
            />
        </Content>
    )
}

const VideoChat = checkAvailableModule('videoChat') ? VideoChatComponent : null

export { VideoChat }
