import React from 'react'
import { ColItem, SettingsFilterListHooks } from './settings__filterList-hooks'
import Icon from 'blocks.simple/icon/icon'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import Button from 'blocks.simple/button/button'
import Collapse from 'blocks.simple/collapse/collapse'
import Link from 'blocks.simple/link/link'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'

import helpers from 'core/helpers'
import translate from 'core/translate'
import { routes } from 'features/routes'
import styles_ from '../settings.jcss'
import { cn } from 'ethcss'
import { grid } from '../../../theme'
import styles from './settings__filterList.jcss'
import { Typography } from 'atoms/Typography'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'

interface ISortableList {
    items: ColItem[]
    onSelect: any
    permissionDisable: boolean
}

interface ISortableItem {
    value: string
    selected: boolean
    id: number
    onSelect: any
    disable: boolean
    permissionDisable: boolean
    className: any
}

const multiSelectIconProps = (items: ColItem[]) => {
    const selected = items.filter((item: ColItem) => item.selected)
    const props = {
        type: 'checked',
        size: 18,
        tooltip: {
            title: translate('selectAll'),
        },
    }

    if (selected.length === items.length) {
        props.type = 'close'
        props.tooltip = {
            title: translate('deselect'),
        }
    }

    return props
}

const DragHandle = SortableHandle(() => (
    <div className={styles.sortableIcon}>
        <Icon
            size={24}
            type="sortable_handle"
            color={getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable}
        />
    </div>
))

const SortableList = SortableContainer(({ items, onSelect, permissionDisable }: ISortableList) => (
    <ul className={styles.list}>
        {items.map((item: ColItem, index: number) => {
            return (
                <SortableItem
                    className={styles.helperClass}
                    key={`item-${index}`}
                    index={index}
                    value={item.field}
                    selected={item.selected}
                    id={item.order}
                    onSelect={onSelect}
                    disable={item.disabled}
                    permissionDisable={permissionDisable}
                />
            )
        })}
    </ul>
))

const SortableItem = SortableElement(({ value, selected, id, onSelect, disable, permissionDisable }: ISortableItem) => {
    const disabled = (disable && selected) || permissionDisable

    return (
        <li
            className={styles.listItem}
            onClick={() => {
                if (!disabled) {
                    onSelect(!selected, id)
                }
            }}
        >
            <div className={cn(styles.label, { [styles.selected]: selected })}>
                <Checkbox
                    labelClassName={styles.text}
                    label={translate(value)}
                    checked={selected}
                    disabled={disabled}
                    onClick={(check: boolean, e: any) => {
                        e.stopPropagation()
                        onSelect(check, id)
                    }}
                />
            </div>
            {!permissionDisable && <DragHandle />}
        </li>
    )
})

const FilterList = () => {
    const {
        items,
        loading,
        defaultFields,
        customFields,
        getViewSettings,
        onSelect,
        onSortEnd,
        onRedirectToDisplays,
        onMultiSelect,
        onReset,
    } = SettingsFilterListHooks()
    const permissionDisable = !helpers.isAvailable({ key: 'settings', action: 'update' })

    return (
        <Collapse
            noWrap
            isLoaded={!loading}
            title={() => (
                <div>
                    <span>{translate('settingsCol')} </span>
                    <Link
                        to={{
                            pathname: `/${routes.displays.path}`,
                        }}
                        onClick={onRedirectToDisplays}
                    >
                        {translate('devicesList')}
                    </Link>
                </div>
            )}
            onClick={!loading ? null : getViewSettings}
            className={cn(styles_.background, grid.mb_md)}
        >
            <Typography className={styles.description} type="text">
                {translate('settingsFilterDescription')}
            </Typography>
            {helpers.isAvailable({ key: 'settings', action: 'update' }) && (
                <div className={styles.controls}>
                    <div>
                        <Icon {...multiSelectIconProps(items)} onClick={onMultiSelect} />
                    </div>
                </div>
            )}
            <SortableList
                helperClass={`${styles.active}`}
                items={items}
                onSortEnd={onSortEnd}
                useDragHandle={true}
                onSelect={onSelect}
                permissionDisable={permissionDisable}
                lockToContainerEdges={true}
                lockAxis={'y'}
            />
            {helpers.isAvailable({ key: 'settings', action: 'update' }) && (
                <div className={styles.footer}>
                    <div />
                    <Button
                        disabled={defaultFields && customFields}
                        mod={'fill'}
                        className={cn(styles.button)}
                        onClick={onReset}
                    >
                        {translate('restoreSettings')}
                    </Button>
                </div>
            )}
        </Collapse>
    )
}

export default FilterList
