import React from 'react'
import { api } from 'core/api/ConnectionManager'
import moment from 'moment'
import translate from 'core/translate'
import styles from './catalog__template_authors.jcss'
import { CatalogActionBar } from 'organisms/Catalog/ActionBar/ActionBar-view'
import { ActionBarRequestHeaderType, ActionBarActionType } from 'core/models/ActionBar'
import EditableText from 'blocks.simple/editableText/editableText'
import CatalogSelectContent from '../../../_select/_content/catalog_select_content'
import Button from 'blocks.simple/button/button'
import grid from 'blocks.simple/grid/grid.jcss'
import { cn } from 'ethcss'
import { DatePickerSingle } from '../../../../../blocks.simple/datepickerSingle/datepickerSingle'
import { TextEditor } from 'molecules/TextEditor'
import { IActionBarComponent } from '../../../../../organisms/Catalog/ActionBar/ActionBar-types'
import { ItemData } from '../../../../../core/models/Template'

export interface IAuthorProps {
    authorId: number
    firstName: string
    secondName: string
    lastName: string
    description: string
    birthday: string
    sourceId: number
    avatar: { id: number }
}

const toolbar = {
    options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
}

const getActionList = (p_: any) => {
    return [
        {
            order: 1,
            type: ActionBarActionType.ADD,
            cb: () => {
                p_.onAddFakeLine({ name: '' })
            },
        },
        {
            order: 2,
            type: ActionBarActionType.DELETE,
            cb: () => {
                const authorIds = p_.selectedItems.map((selectedItem: { authorId: number }) => selectedItem.authorId)

                if (authorIds.length > 0) {
                    api.send('deleteAuthors', authorIds).then(() => window.location.reload())
                }

                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            },
        },
    ]
}

export default {
    getMethod: 'getAuthorsList',
    customParams: {
        limit: 35,
        order: [
            ['lastName', 'asc'],
            ['firstName', 'asc'],
            ['secondName', 'asc'],
        ],
    },
    trNoClick: true,
    disableDrag: true,
    emptySearchText: () => translate('notFound'),
    emptyDescription: () => translate('createFirstPosition'),
    emptyFolderText: () => translate('listIsEmpty'),
    emptyIcon: 'not_allow',
    addItemButton: () => null,
    settingsPrefixes: 'catalogizator',
    sortOptions: () => [],
    headerFilters: (p_: IActionBarComponent) => {
        return (
            <CatalogActionBar
                title={translate('authorsList')}
                requestHeaderType={ActionBarRequestHeaderType.WITH_AUTH}
                type="authorsList"
                actions={getActionList(p_)}
                {...p_}
            />
        )
    },

    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean) => {
        const item = { ...dataItem }

        if (item.avatar && !item.avatar.__view) {
            item.avatar.__view = {
                selected: getSelected(item),
            }
        }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },

    getBackendModel: (item: ItemData) => {
        return api.send('getAuthorsList', { query: item.name }, { hideLoader: true })
    },
    tableWrapper: styles.tableWrapper,
    isEqual: (firstItem: ItemData, secondItem: ItemData) => {
        if (firstItem.authorId && secondItem.authorId) {
            return firstItem.authorId === secondItem.authorId
        }

        return firstItem === secondItem
    },

    onCreateListeners: [
        // (callback, clearList) => {
        //     return api.addObserver(
        //         'authorCreated',
        //         (authorCreated) => {
        //             callback({ ...authorCreated })
        //         },
        //         clearList
        //     )
        // },
    ],
    onUpdateListeners: [
        (callback: any, clearList: string[]) => {
            return api.addObserver(
                'authorUpdated',
                (authorUpdated: ItemData) => {
                    callback({ ...authorUpdated })
                },
                clearList
            )
        },
    ],
    onDeleteListeners: [
        // (callback, clearList) =>
        //     api.addObserver(
        //         'authorsDeleted',
        //         (authorsDeleted) => {
        //             authorsDeleted.forEach((authorId) => {
        //                 callback({ authorId })
        //             })
        //         },
        //         clearList
        //     ),
    ],
    isFolder: () => {},
    fields: () => [
        { id: 'avatar', name: translate('avatar') },
        { id: 'lastName', name: translate('surname') },
        { id: 'firstName', name: translate('authorName') },
        { id: 'secondName', name: translate('middleName') },
        { id: 'birthday', name: translate('birthDate') },
        { id: 'description', name: translate('aboutAuthor') },
    ],
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        lastName: (p_: IAuthorProps, active: boolean, listProps: { onAddFakeLine: any; fakeLine: IAuthorProps }) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.lastName })}>
                <div className={cn({ [grid.fullWidth]: p_.lastName, [grid.space]: !p_.lastName })}>
                    <EditableText
                        placeholder={translate('enterAuthorLastName')}
                        id={p_.authorId}
                        text={p_.lastName}
                        onChange={(lastName: string) => {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, lastName })
                        }}
                        onUpdate={(lastName, cb) => {
                            api.send('updateAuthor', {
                                authorId: p_.authorId,
                                lastName,
                            }).then(cb)
                        }}
                    />
                </div>
            </div>
        ),
        firstName: (p_: IAuthorProps, active: boolean, listProps: { onAddFakeLine: any; fakeLine: IAuthorProps }) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.authorId })}>
                <div className={cn({ [grid.fullWidth]: p_.authorId })}>
                    <EditableText
                        placeholder={translate('enterAuthorName')}
                        id={p_.authorId}
                        text={p_.firstName}
                        onChange={(firstName: string) => {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, firstName })
                        }}
                        onUpdate={(firstName, cb) => {
                            api.send('updateAuthor', {
                                authorId: p_.authorId,
                                firstName,
                            }).then(cb)
                        }}
                    />
                </div>
            </div>
        ),
        secondName: (p_: IAuthorProps, active: boolean, listProps: { onAddFakeLine: any; fakeLine: IAuthorProps }) => (
            <div className={cn(grid.fullWidth)}>
                <EditableText
                    placeholder={translate('enterAuthorMiddleName')}
                    id={p_.authorId}
                    text={p_.secondName}
                    onChange={(secondName: string) => {
                        listProps.onAddFakeLine({ ...listProps.fakeLine, secondName })
                    }}
                    onUpdate={(secondName, cb) => {
                        api.send('updateAuthor', {
                            authorId: p_.authorId,
                            secondName,
                        }).then(cb)
                    }}
                />
            </div>
        ),
        birthday: (p_: IAuthorProps, active: boolean, listProps: { onAddFakeLine: any; fakeLine: IAuthorProps }) => (
            <div className={cn(grid.fullWidth)}>
                <DatePickerSingle
                    placeholder={translate('chooseDate')}
                    value={p_.birthday ? moment(p_.birthday).format() : ''}
                    dateFormat={'dd-MM-yyyy'}
                    onChangeFormat={'YYYY-MM-DD'}
                    id={p_.authorId}
                    onChange={(birthday: string, cb: any) => {
                        if (!p_.authorId) {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, birthday })
                            return
                        }

                        api.send('updateAuthor', {
                            authorId: p_.authorId,
                            birthday,
                        }).then(cb)
                    }}
                />
            </div>
        ),
        description: (p_: IAuthorProps, active: boolean, listProps: { onAddFakeLine: any; fakeLine: IAuthorProps }) => (
            <div className={cn(grid.fullWidth)}>
                <TextEditor
                    id={p_.authorId}
                    placeholder={translate('enterDescription')}
                    text={p_.description ? p_.description : ''}
                    previewStyles={styles.preview}
                    toolbar={toolbar}
                    onChange={(description: string, cb: any) => {
                        if (!p_.authorId) {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, description })
                            return
                        }

                        api.send('updateAuthor', {
                            authorId: p_.authorId,
                            description,
                        }).then(cb)
                    }}
                />
                {!p_.authorId && (
                    <Button
                        mod={'fill'}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            api.send('createAuthor', {
                                firstName: listProps.fakeLine.firstName,
                                lastName: listProps.fakeLine.lastName ? listProps.fakeLine.lastName : null,
                                secondName: listProps.fakeLine.secondName ? listProps.fakeLine.secondName : null,
                                birthday: listProps.fakeLine.birthday ? listProps.fakeLine.birthday : null,
                                description: listProps.fakeLine.description ? listProps.fakeLine.description : null,
                                sourceId: listProps.fakeLine.avatar ? listProps.fakeLine.avatar.id : null,
                                profession: null,
                                country: null,
                            }).then(() => window.location.reload())
                        }}
                        disabled={!listProps.fakeLine.firstName}
                    >
                        {translate('save')}
                    </Button>
                )}
            </div>
        ),
        avatar: (p_: IAuthorProps, active: boolean, listProps: { onAddFakeLine: any; fakeLine: IAuthorProps }) => (
            <div>
                <CatalogSelectContent
                    id={p_.authorId}
                    type={'image'}
                    selectedInfo={p_.avatar}
                    onSelect={(selectedMedia: { id: number }) => {
                        if (p_.authorId) {
                            api.send('updateAuthor', {
                                authorId: p_.authorId,
                                sourceId: selectedMedia.id,
                            })
                        } else {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, avatar: selectedMedia })
                        }
                    }}
                />
            </div>
        ),
    },
    hideActiveRowColor: true,
    disableUpdateUserSettings: true,
    templateCellsWidth: {
        lastName: '13%',
        firstName: '13%',
        secondName: '13%',
        birthday: '6%',
        avatar: '8%',
        description: '40%',
    },
    disableListScroll: true,
    fixedColWidth: false,
    dndDblClickDisable: true,
}
