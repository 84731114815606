import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { mediaCSS } from 'blocks.app/config'
import { IStyles } from '../../../theme'

const searchbarSearchStyles: IStyles = {
    input: {
        ...grid.w100,
        ...grid.pl_mdPlus,
        ...grid.pr_mini,
        [mediaCSS.tab]: {
            padding: 7,
            fontSize: 12,

            [mediaCSS.tabMini]: {
                //...item.rounded_full_mini,
            },
        },
    },
    search: {
        ...grid.space,
        position: 'relative',
        height: '100%',
        [mediaCSS.tabMini]: {
            //...grid.noSpace,
            ...grid.w100,
            display: 'block !important',
        },
    },
    fullRadius: {
        ...item.rounded_full_mini,
    },
    hideText: {
        '& ::placeholder': {
            color: '#FFFFFF !important',
        },
        [mediaCSS.tab]: {
            color: 'transparent !important',
        },
    },
    hideTextNotSmartplayerTheme: {
        '& ::placeholder': {
            color: '#FFFFFF !important',
        },
        [mediaCSS.tab]: {
            color: 'transparent !important',
            fontSize: '13px',
        },
    },
}

addStyles(searchbarSearchStyles)
export default searchbarSearchStyles
