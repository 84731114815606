import React, { Dispatch, SetStateAction } from 'react'
import deepEqual from 'fast-deep-equal'

interface IDropdownProps {
    onValidateSymbolNumber?: (isValidate: boolean) => void
    id: string
    placeholder?: string
    icon?: string
    className?: string
    containerClassName?: any
    value?: string | number | boolean | null
    multiselect: boolean
    searchable: boolean
    notFilterOnSearch: boolean
    inheritIconColor: boolean
    disabled?: boolean
    options: any[]
    optionsBackground?: string
    valueField: string
    labelField: string
    iconActiveColor?: string
    centered: boolean
    disableChangeRounded?: boolean
    iconColor?: string
    iconSize?: string | number
    noFocus: boolean
    textSize?: string
    mod: string
    label?: any
    children?: React.ReactChildren
    customHeader?: {}
    rounded: string
    indentSize: string
    labelClassName?: string
    mask?: string
    onChange: (list: any, option?: { name: string } | null, name?: string) => void
    onChangeTimeFormat?: (format: string) => void
    onShow?: () => void
    onHide?: () => void
    onBlur?: (value?: any, e?: React.ChangeEvent<HTMLInputElement>) => void
    config?: any
    small?: boolean
    showIconOnHover?: boolean
    placeholderAsValue?: boolean
    hideNotFound: boolean
    wrapperWidth?: boolean
    onlyPlaceholder?: boolean
    disableAddNewTags: boolean
    active?: boolean
    multiselectItems?: boolean
    list?: any
    type?: string
    validationBorder?: boolean
    isNotDropdown?: boolean
    hmsState?: {
        h?: boolean
        m?: boolean
        s?: boolean
    }
    buttonClassName?: string

    iconClassName?: string
    searchableInputClassName?: string
    isRelativeList?: boolean
    textMobPadding?: boolean
    hiddenOverflow?: boolean
    scheduleMode?: boolean
}

interface IState {
    active: boolean
    inputValue: string
    selected: {
        id: string | ''
        name: null | string
    }
    selectedList: { label: string; name: string }[] | null
    opt: any
    position: string
    positionChanged: boolean
}

const getFilteredList = (options: { name: string }[], list: { name: string }[] | undefined) => {
    if (list) {
        return options.filter((field) => !list.map((listItem) => listItem.name).includes(field.name))
    } else {
        return options
    }
}

class DropdownMethods extends React.Component<IDropdownProps> {
    state: IState
    roundedMod: string = ''
    filtered: string[]
    searchable: any

    constructor(p_: IDropdownProps) {
        super(p_)

        this.state = {
            active: false,
            inputValue: '',
            selected: {
                id: '',
                name: null,
            },
            selectedList: p_.list ? p_.list : null,
            opt: getFilteredList(p_.options, p_.list),
            position: 'bottom',
            positionChanged: false,
        }

        this._onToggleClick = this._onToggleClick.bind(this)
        this._onChange = this._onChange.bind(this)
        this.searchableChange = this.searchableChange.bind(this)
        this.roundedMod = this.getRoundedMod(p_.rounded)
        this.filtered = []
    }

    isActive() {
        const p_ = this.props
        const s_ = this.state

        return typeof p_.active === 'boolean' ? p_.active : s_.active
    }

    hide() {
        const p_ = this.props
        if (p_.searchable && !p_.multiselect && !p_.multiselectItems) {
            if (!this.state.inputValue || !this.state.inputValue.length || this.filtered.length === 0) {
                this.setState({ inputValue: this.state.selected.name })
            }
        }
        this.setState(
            {
                active: false,
            },
            () => {
                if (p_.onHide) {
                    p_.onHide()
                }
            }
        )
    }

    show() {
        const p_ = this.props

        if (!p_.multiselect && !p_.multiselectItems && p_.searchable && !p_.noFocus) {
            if (this.searchable) {
                this.searchable!.focus()
            }

            this.setState({ inputValue: '' })
        }
        this.setState(
            {
                active: true,
            },
            () => {
                if (p_.onShow) {
                    p_.onShow()
                }
                this.setAutoPosition()
            }
        )
    }
    setAutoPosition = () => {
        const s_ = this.state

        if (!s_.positionChanged) {
            let position = 'bottom'
            const refs = ['dropdownContent', 'customContent']

            refs.forEach((ref) => {
                // @ts-ignore
                if (this[ref]) {
                    // @ts-ignore
                    const offsetTopHeight = this[ref].clientHeight + this[ref].getBoundingClientRect().top
                    const height = document.body.clientHeight

                    if (offsetTopHeight > height) {
                        position = 'top'
                    }
                }
            })

            this.setState({ position, positionChanged: true })
        }
    }
    removeTagItem(name: string) {
        const s_ = this.state
        const p_ = this.props
        const removedItemList = s_.selectedList!.filter((item: { name: string }) => item.name !== name)
        const newItem = Object.assign({}, { name: name, label: name })

        this.setState({ selectedList: removedItemList, opt: [...s_.opt, newItem] })
        if (p_.onChange) {
            p_.onChange(removedItemList, null, name)
        }
    }
    filterOptions = (value: string) => {
        const p_ = this.props
        const { opt } = this.state
        if (p_.notFilterOnSearch) {
            return opt
        }

        const searchableValue = p_.mask ? value.split('_')[0] : value
        let filtered = opt.filter(
            (field: { [index: string]: string }) =>
                field[p_.labelField].toLowerCase().indexOf(searchableValue.toLowerCase()) !== -1
        )

        return filtered
    }
    searchableChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value
        const p_ = this.props
        let filtered = this.filterOptions(value)

        if (p_.multiselect || p_.multiselectItems) {
            if (value.length > 2 && filtered.length > 0) {
                this.show()
            } else {
                this.hide()
            }
        } else {
            this.show()
        }

        if (p_.config && p_.config.pattern) {
            if (p_.config.pattern.test(value)) {
                this.setState({ inputValue: value })
                if (p_.onBlur) {
                    p_.onBlur({ value })
                }
            }
        } else {
            this.setState({ inputValue: value })
        }
    }
    searchableBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        const p_ = this.props
        const value = e.target.value

        if (p_.onBlur) {
            p_.onBlur({ value }, e)
        }

        if (p_.placeholderAsValue) {
            setTimeout(() => {
                this.setState({ inputValue: '' })
            }, 0)
        } else {
            this.setState({ inputValue: value })
        }
    }

    handleClickOutside() {
        if (this.isActive()) {
            this.hide()
        }
    }

    _onToggleClick(event: React.MouseEvent) {
        event.preventDefault()
        if (this.props.type === 'hmsTime') {
            return
        }
        if (this.props.disabled) {
            return
        }
        if (this.isActive()) {
            this.hide()
        } else {
            this.show()
        }
    }

    _onToggleClickhHms(event: React.MouseEvent) {
        event.preventDefault()
        if (this.props.disabled) {
            return
        }
        if (this.isActive()) {
            this.hide()
        } else {
            this.show()
        }
    }
    _onToggleClickhHmsMode(event: React.MouseEvent, p_: IDropdownProps, scheduleMode?: boolean) {
        if (scheduleMode) {
            this.searchable?.focus()
            if (!p_.multiselect && !p_.multiselectItems && !p_.disabled) {
                this.setState({ inputValue: p_.placeholderAsValue ? p_.value : '' })
            }
        } else {
            event.preventDefault()
            if (this.props.disabled) {
                return
            }
            if (this.isActive()) {
                this.hide()
            } else {
                this.show()
            }
        }
    }

    _onChange(e: React.MouseEvent, value: any) {
        const p_ = this.props
        this._onToggleClick(e)
        if (p_.onChange) {
            p_.onChange(value)
        }
        this._selectedOption(p_)
    }

    _selectedOption(p_: IDropdownProps) {
        if (p_.options && !(p_.value === undefined || p_.value === null)) {
            p_.options.forEach((item) => {
                if (item[p_.valueField] === p_.value) {
                    this.setState({
                        selected: {
                            id: item[p_.valueField],
                            name: item[p_.labelField],
                        },
                    })
                    if (!p_.multiselect && !p_.multiselectItems && p_.searchable) {
                        this.setState({ inputValue: item[p_.labelField] })
                    }
                }
            })
        } else {
            if (p_.onlyPlaceholder) {
                return
            }

            this.setState({
                selected: {
                    id: undefined,
                    name: p_.placeholder,
                },
            })
            if (!p_.multiselect && !p_.multiselectItems && p_.searchable) {
                this.setState({ inputValue: '' })
            }
        }
    }

    _selectedMulti(option: { name: string }) {
        const s_ = this.state
        const p_ = this.props
        const deleteDuplicate = s_.opt.filter((item: { name: string }) => item.name !== option.name)
        const newList = s_.selectedList ? [...s_.selectedList, { ...option }] : []

        this.setState({
            selectedList: newList,
            inputValue: '',
            active: false,
            opt: [...deleteDuplicate],
        })
        if (p_.onChange) {
            p_.onChange(newList, option)
        }
    }

    getRoundedMod(rounded: string) {
        const splitRounded = rounded.split('_')
        let roundedMod = splitRounded[splitRounded.length - 1]
        if (roundedMod === 'none') {
            roundedMod = 'mini'
        }
        return roundedMod
    }

    componentDidMount() {
        const p_ = this.props

        if (typeof p_.value !== 'undefined' && p_.options) {
            this._selectedOption(p_)
        }
        if (p_.multiselect || (p_.multiselectItems && p_.list && p_.options)) {
            this.setState({
                opt: getFilteredList(p_.options, p_.list),
                selectedList: p_.list,
            })
        }

        document.addEventListener('keydown', this.onEnterPressed)
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.onEnterPressed)
    }
    componentDidUpdate(prevProps: IDropdownProps, prevState: IState) {
        const p_ = this.props
        if (p_.options && (!deepEqual(p_.value, prevProps.value) || !deepEqual(p_.options, prevProps.options))) {
            this._selectedOption(p_)
        }
        this.roundedMod = this.getRoundedMod(p_.rounded)

        const opt = getFilteredList(p_.options, p_.list)
        const selectedList = p_.list ? p_.list : null

        if (!deepEqual(prevState.selectedList, selectedList) || !deepEqual(prevState.opt, opt)) {
            this.setState({
                selectedList,
                opt,
            })
        }
    }

    onEnterPressed = (event: any) => {
        const s_ = this.state
        const p_ = this.props

        if (p_.disableAddNewTags) {
            return
        }

        event = event || window.event

        if (event.key === 'Enter' && event.target.id! === 'autosize_input') {
            const p_ = this.props
            if (p_.multiselect || (p_.multiselectItems && p_.searchable)) {
                event.preventDefault()
                if (
                    s_.selectedList!.filter((item: { name: string }) => item.name === s_.inputValue).length === 0 &&
                    // && s_.inputValue.length > 1
                    p_.onValidateSymbolNumber
                        ? s_.inputValue.length > 2
                        : s_.inputValue.length > 1
                ) {
                    const newList = s_.selectedList
                        ? [...s_.selectedList, { name: s_.inputValue, label: s_.inputValue }]
                        : [{ name: s_.inputValue, label: s_.inputValue }]
                    const deleteDuplicate = s_.opt.filter((item: { name: string }) => item.name !== s_.inputValue)

                    this.setState({
                        selectedList: newList,
                        inputValue: '',
                        active: false,
                        opt: [...deleteDuplicate],
                    })
                    if (p_.onChange) {
                        p_.onChange(newList)
                    }
                    if (p_.onValidateSymbolNumber) {
                        p_.onValidateSymbolNumber(false)
                    }
                } else if (s_.inputValue.length < 3) {
                    if (p_.onValidateSymbolNumber) {
                        p_.onValidateSymbolNumber(true)
                    }
                }
            }
        }
    }
}

export default DropdownMethods
