import { addStyles } from 'ethcss'
import { cols, mediaCSS, IStyles, grid, item } from 'theme'
import { defaultThemeStyles } from './Content-theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'

export const contentStyles: IStyles = {
    wrapper: {
        ...grid.col,
        ...grid.flex,
        ...item.transitionErase,
        ...grid.fullHeight,
        ...item.rel,
        ...item.overNone,
        background: `var(--var-module-app-component-content-style-background, ${defaultThemeStyles.background})`,
        '& *': {
            fontFamily: `var(--var-module-app-component-app-style-fontFamily, ${defaultThemeAppStyles.fontFamily})!important`,
        },
    },
    contentWrapper: {
        ...grid.col,
        ...grid.space,
        ...grid.fullHeight,
        ...item.rel,
        overflow: 'auto',
    },
    content: {
        ...grid.full,
    },
    messageWrapper: {
        ...grid.full,
        ...grid.colCenter,
        ...grid.center,
    },
    leftIndent: {
        left: `${cols.normal}%`,
        [mediaCSS.tab]: {
            left: 0,
        },
    },
    leftIndent_x: {
        left: '0 !important',
        padding: '0 1%',
    },
    leftIndentSm: {
        left: `${cols.mini}%`,
    },
    col1: {
        width: `${100 - cols.normal}%`,
        [mediaCSS.tab]: {
            ...grid.w100,
        },
    },
    col1_x: {
        width: '100% !important',
    },
    col1Sm: {
        width: `${100 - cols.mini}%`,
    },
    col2: {
        width: `${100 - 2 * cols.normal}%`,
        [mediaCSS.tab]: {
            ...grid.w100,
        },
    },
    contentMarginTop: {
        marginTop: '80px',

        [mediaCSS.tab]: {
            marginTop: '50px',
        },
    },
}

addStyles(contentStyles)
