import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../../theme'
import { cols, mediaCSS } from 'blocks.app/config'
import _item from 'blocks.simple/item/item.jcss'

const displaysScreenShotsStyles: IStyles = {
    wrapper: {
        ...grid.row,
        ...grid.full,
        ..._item.previewOpacity,
    },
    image: {
        width: `calc(100% - ${cols.normalFixed}px)`,
        ...item.rel,
        maxHeight: '100%',
        [mediaCSS.tab]: {
            ...grid.w100,
        },
    },
    empty: {
        ...grid.full,
        ...grid.colCenter,
        ...grid.center,
    },
    emptyTitle: {
        ...grid.mt_md,
        ..._item.whiteColor,
        textTransform: 'uppercase',
    },
    controlsWrapper: {
        ...grid.fullWidth,
        ...grid.row,
        ...grid.center,
        ...item.abs,
        bottom: 0,
    },
    controls: {
        ...grid.row,
        ...grid.mb_md,
        ..._item.blackOpacity,
        ...item.rounded_full_normal,
    },
}

addStyles(displaysScreenShotsStyles)
export default displaysScreenShotsStyles
