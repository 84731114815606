import React from 'react'
import DraftDevicesMethods from './draftDevices.local.methods'
import DraftDevicesToolbar from './__toolbar/draftDevices__toolbar'
import Content from 'blocks.app/content/content'
import Catalog from 'blocks.app/catalog/catalog'
import { cn } from 'ethcss'
import translate from 'core/translate'
import styles from './draftDevices.jcss'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import Modal from 'blocks.simple/modal/modal'
import { DraftDeviceInformation } from './DraftDeviceInformation'
import { draftDevicesActions } from './draftDevice.state'
import { checkAvailableModule } from '../../core/helpers/routes/routes'

const DraftDevices = () => {
    const { selectedInfo, selectedItems, setSelectedInfo, setSelectedItems } = DraftDevicesMethods()
    const dispatch = useDispatch()
    const draftDevices: any = useSelector<RootStateOrAny>((state) => state.draftDevices)

    const closeModal = () => {
        dispatch(draftDevicesActions.closeModal())
    }

    const renderModalContent = (modalState: string) => {
        if (!modalState) return null

        switch (modalState) {
            case 'information':
                return <DraftDeviceInformation device={selectedInfo} />
            default:
                return null
        }
    }

    return (
        <Content
            title={translate('draftDevices')}
            toolbar={
                <DraftDevicesToolbar
                    selectedInfo={selectedInfo}
                    selectedItems={selectedItems}
                    onChange={(selectedItems: { id: number }[]) => setSelectedItems(selectedItems)}
                />
            }
        >
            <Catalog
                type={'draftDevices'}
                key={'draftDevices__catalog'}
                selectedInfo={selectedInfo}
                onSelectInfo={(
                    selectedInfo: {
                        id: number
                        name: string
                        status: string
                    } | null
                ) => setSelectedInfo(selectedInfo)}
                breadcrumbsClassName={cn(styles.breadcrumbs)}
                selectedItems={selectedItems}
                onSelect={(selectedItems: any) => setSelectedItems(selectedItems)}
                treeView={false}
            />
            <Modal open={draftDevices.modalState} onClose={closeModal} {...draftDevices.modalOptions}>
                {renderModalContent(draftDevices.modalState)}
            </Modal>
        </Content>
    )
}

const exportDraftDevices = checkAvailableModule('displays/draftDevices') ? DraftDevices : null

export default exportDraftDevices
