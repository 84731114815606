import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles } from '../index.theme'
import { IStyles } from '../../../../theme'

const ItemAdvertisingCampaignsStyles: IStyles = {
    wrapper() {
        return {
            ...grid.col,
            cursor: 'pointer',
            boxShadow: `var(--var-module-catalog-component-item-style-shadow, ${defaultThemeStyles.shadow})`,
            backgroundColor: `var(--var-module-catalog-component-item-style-idle, ${defaultThemeStyles.idle})`,
            overflow: 'hidden',
            [`&:hover .${this.checkWrapper}`]: {
                opacity: 1,
            },
        }
    },
    checkWrapper: {
        position: 'absolute',
        ...item.transitionErase,
        top: grid.p_microPlus.padding,
        right: grid.p_micro.padding,
        opacity: 0,
    },
    name: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.p_mini,
        ...item.blackOpacity,
        textTransform: 'uppercase',
        color: `var(--var-module-catalog-component-item-style-textMain, ${defaultThemeStyles.textMain})`,
    },
    nameInner: {
        ...grid.space,
        ...grid.pr_mini,
        marginTop: 3,
        ...item.ellipsisPure,
    },
    preview: {
        ...grid.space,
    },
    previewActive: {},
}

addStyles(ItemAdvertisingCampaignsStyles)
export default ItemAdvertisingCampaignsStyles
