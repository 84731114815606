import React from 'react'
import { Radio } from 'atoms/Radio'
import translate from 'core/translate'
import { Typography } from 'atoms/Typography'
import { cn } from 'ethcss'
import styles from './deviceSettings__audioOutput.jcss'

const DeviceSettingsAudioOutput = (p_: {
    enabled: boolean
    className: string | object
    platform: string
    value: string
    onChange: (value_1: string, value_2: string) => void
}) => {
    if (!p_.enabled) {
        return null
    }

    return (
        <div className={cn(p_.className)}>
            <Typography className={cn(styles.title)} type="title">
                {translate('audioOutput')}
            </Typography>
            <div className={styles.radioWrapper}>
                <Radio
                    label={p_.platform === 'raspberry' ? translate('audioHDMI') : translate('speakers')}
                    selected={p_.value === 'speakers'}
                    className={styles.radio}
                    onClick={function () {
                        p_.onChange('speakers', 'audioOutput')
                    }}
                />
                <Radio
                    label={translate('headset')}
                    selected={p_.value === 'headset'}
                    onClick={function () {
                        p_.onChange('headset', 'audioOutput')
                    }}
                />
            </div>
        </div>
    )
}

export default React.memo(DeviceSettingsAudioOutput)
