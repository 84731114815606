import React, { useEffect, useRef, useState } from 'react'
import styles from './display__control.jcss'
//import ReactHlsPlayer from 'react-hls-player'
import { Event } from './display__remoteControl'
import WebRTCComponent from './display__webRTC'

export const Preview = ({
    onEvent,
    device,
    peerConnection,
    setPeerConnection,
}: {
    onEvent: (event: Event) => void
    device: any
    peerConnection: RTCPeerConnection | null
    setPeerConnection: (peerConnection: RTCPeerConnection) => void
}) => {
    const previewRef = useRef<any>(null)
    const [zoomFactor, setZoomFactor] = useState<number>(1)

    const onClick = (e: any) => {
        e.preventDefault()

        if (!device.resolutionWidth || !device.resolutionHeight) return

        const coords = e.target.getBoundingClientRect()
        const initLeft = e.clientX - coords.left
        const initTop = e.clientY - coords.top
        const deltaLeft = device.resolutionWidth / e.target.offsetWidth
        const deltaTop = device.resolutionHeight / e.target.offsetHeight
        const clientX = Math.round(initLeft * deltaLeft)
        const clientY = Math.round(initTop * deltaTop)

        onEvent({
            type: 'click',
            data: {
                clientX,
                clientY,
            },
        })
    }
    const updateZoom = () => {
        if (previewRef.current) {
            const previewWidth = previewRef.current.offsetWidth
            const previewHeight = previewRef.current.offsetHeight
            const deviceWidth = device.resolutionWidth
            const deviceHeight = device.resolutionHeight
            const zoom = deviceWidth > deviceHeight ? previewWidth / deviceWidth : previewHeight / deviceHeight

            setZoomFactor(zoom)
        }
    }

    useEffect(() => {
        updateZoom()
    }, [previewRef.current])
    useEffect(() => {
        window.addEventListener('resize', updateZoom)

        return () => window.removeEventListener('resize', updateZoom)
    }, [previewRef.current])

    return (
        <div ref={previewRef} className={styles.preview}>
            <div
                className={styles.previewInner}
                onClick={onClick}
                style={{
                    minWidth: device.online ? 0 : '100%',
                    minHeight: device.online ? 0 : '100%',
                }}
            >
                {device.online && (
                    <>
                        <WebRTCComponent
                            digitalSignageId={device.id}
                            style={{
                                zoom: zoomFactor,
                                height: device.resolutionHeight,
                                width: device.resolutionWidth,
                                pointerEvents: 'none',
                                display: 'block',
                            }}
                            peerConnection={peerConnection}
                            setPeerConnection={setPeerConnection}
                        />
                        {/*<ReactHlsPlayer*/}
                        {/*    src={url}*/}
                        {/*    autoPlay={true}*/}
                        {/*    controls={false}*/}
                        {/*    className={styles.player}*/}
                        {/*    playerRef={{ current: null }}*/}
                        {/*    style={{*/}
                        {/*        zoom: zoomFactor,*/}
                        {/*        height: device.resolutionHeight,*/}
                        {/*        width: device.resolutionWidth,*/}
                        {/*        pointerEvents: 'none',*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </>
                )}
            </div>
        </div>
    )
}
