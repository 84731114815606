import React from 'react'
import { colors } from 'theme'

import { cn } from 'ethcss'
import styles from 'pages/ui/ui.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { text as textStyles } from 'theme'

class UiPalette extends React.Component {
    render() {
        return (
            <div className={styles.blockPanel}>
                <div className={styles.title}>Палитра цветов</div>
                <div className={grid.row}>
                    {Object.keys(colors).map((color) => (
                        <div key={`ui_palette_${color}`} className={cn(grid.p_mini, textStyles.center)}>
                            <div
                                style={{
                                    width: 50,
                                    height: 50,
                                    margin: '0 auto',
                                    backgroundColor: colors[color],
                                }}
                            />
                            <div className={cn(grid.mt_micro, textStyles.bold)}>{colors[color]}</div>
                            <div className={grid.mt_nano}>{color}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default UiPalette
