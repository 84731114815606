import React, { FC, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IDownloadModalPosition, IDownloadServiceComponent } from './DownloadService-types'
import { IDownloadData, IDownloadUnkown } from 'core/models/Downloads'
import { DownloadModal } from './molecules/DownloadModal/DownloadModal-view'
import Animation from 'blocks.simple/animation/animation'
import {
    useChangeModalPosition,
    useFetchDownloads,
    useListenDownloadsUpdate,
    useThrottleDownloadsStoreUpdate,
} from './DownloadService-hooks'
import {
    downloadsActions,
    cancelDownload as cancelDownloadAction,
    cancelDownloadWithoutMessage as cancelDownloadWithoutMessageAction,
} from './DownloadService.state'

export const DownloadServiceComponent: FC<IDownloadServiceComponent> = ({}) => {
    const dispatch = useDispatch()
    const downloads: IDownloadData[] = useSelector((state: any) => state.downloads.downloads)
    const isOpenModal: boolean = useSelector((state: any) => state.downloads.isOpenModal)

    const { changeModalPosition, modalPositon } = useChangeModalPosition()

    useFetchDownloads()

    useThrottleDownloadsStoreUpdate()

    useListenDownloadsUpdate()

    const closeModal = () => dispatch(downloadsActions.closeModal())

    const toggleModal = () => dispatch(downloadsActions.toggleModal())

    const cancelDownload = (download: IDownloadUnkown) => {
        dispatch(cancelDownloadAction(download))
    }

    const cancelDownloadWithoutMessage = (download: IDownloadUnkown) => {
        dispatch(cancelDownloadWithoutMessageAction(download))
    }

    const setPosition = (currentPosition: IDownloadModalPosition) => {
        changeModalPosition(currentPosition)
        return true
    }

    const clearAllDownloads = () => {
        dispatch(downloadsActions.clearAllDownloads())
    }

    return (
        <Animation>
            {isOpenModal && (
                <DownloadModal
                    cancelDownload={cancelDownload}
                    cancelDownloadWithoutMessage={cancelDownloadWithoutMessage}
                    setPosition={setPosition}
                    position={modalPositon}
                    closeModal={closeModal}
                    toggleModal={toggleModal}
                    downloads={downloads}
                    clearAllDownloads={clearAllDownloads}
                />
            )}
        </Animation>
    )
}

export const DownloadService = memo(DownloadServiceComponent)
