import React from 'react'
import request from 'superagent'
import Icon from 'blocks.simple/icon/icon'
import DropMenu from 'blocks.simple/dropMenu/dropMenu'
import Button from 'blocks.simple/button/button'

import config from 'core/config'
import styles from './catalog__filter_instancesCatalog.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { text as textStyles } from 'theme'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { connect } from 'react-redux'
import { loaderActions } from 'features/loader/Loader.state'

class InstancesCatalogFilter extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            active: [],
        }
    }
    deleteItem() {
        const p_ = this.props

        return {
            id: 'delete',
            icon: 'delete',
            disabled: p_.selectedItems.length === 0 && !p_.fakeLine,
            color: 'darkCloud',
            tooltip: {
                title: translate('delete'),
            },
            children: (
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        this.setState({ active: [] })
                        p_.onDelete()
                    }}
                    className={cn(grid.colCenter, grid.p_mini)}
                >
                    <div className={cn(grid.w100, grid.mb_mini, textStyles.center)}>{translate('deleteApprove')}</div>
                    <div className={cn(grid.row, grid.justify)}>
                        <Button
                            type="submit"
                            mod="fill"
                            className={cn(grid.mr_mini)}
                            indentSize={'mini'}
                            textSize={'mini'}
                            rounded={'full_normal'}
                        >
                            {translate('yes')}
                        </Button>
                        <Button
                            animation={false}
                            onClick={() => this.setState({ active: [] })}
                            indentSize={'mini'}
                            textSize={'mini'}
                            rounded={'full_normal'}
                        >
                            {translate('no')}
                        </Button>
                    </div>
                </form>
            ),
        }
    }
    render() {
        const s_ = this.state
        const p_ = this.props
        const downloadLink = `${config.api.root}v2/download/school/instances_catalog?token=${p_.token}&instance=${p_.type}`

        return (
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <div className={styles.tools}>
                        <div className={cn(grid.rowCenter)}>
                            <div className={grid.mr_md}>
                                <Icon onClick={p_.onAdd} type={'plus_circle'} tooltip={{ title: translate('add') }} />
                            </div>
                            <div className={styles.deleteButton}>
                                <DropMenu
                                    active={s_.active}
                                    onChange={(active) => this.setState({ active })}
                                    items={[this.deleteItem()]}
                                    gray={true}
                                />
                            </div>
                        </div>
                        <div />
                        <div className={grid.row}>
                            <Button
                                iconPos="left"
                                icon="upload_arrow"
                                className={cn(grid.mr_md)}
                                animation={false}
                                onClick={() => {
                                    this.uploadInput.click()
                                }}
                            >
                                {translate('upload')}
                            </Button>
                            <a
                                href={downloadLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={textStyles.noUnderline}
                            >
                                <Button icon="download_arrow" iconPos="left">
                                    {translate('download')}
                                </Button>
                            </a>
                            <input
                                className={item.none}
                                type="file"
                                ref={(input) => (this.uploadInput = input)}
                                accept=".xls,.xlsx"
                                onChange={() => {
                                    const formData = new FormData()
                                    formData.append('token', p_.token)
                                    formData.append('instance', p_.type)
                                    formData.append('file', this.uploadInput.files[0])
                                    p_.startLoading()

                                    request
                                        .post(`${config.api.root}v2/upload/school/instances_catalog`)
                                        .send(formData)
                                        .end(() => {
                                            p_.endLoading()
                                        })

                                    this.uploadInput.value = ''
                                }}
                            />
                        </div>
                    </div>
                    <div className={cn(grid.rowCenter, grid.p_md)}>{p_.title}</div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.user.data.token,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        startLoading: () => dispatch(loaderActions.startLoading()),
        endLoading: () => dispatch(loaderActions.endLoading()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstancesCatalogFilter)
