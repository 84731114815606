import React from 'react'
import BroadcastsAddMethods from './broadcasts_add.local.methods'
import Content from 'blocks.app/content/content'
import DropMenu from 'blocks.simple/dropMenu/dropMenu'
import Button from 'blocks.simple/button/button'
import Editor from 'blocks.app/editor/editor'
import AddSchedule from 'blocks.app/addSchedule/addSchedule'
import AddScheduleToolbar from 'blocks.app/addSchedule/_toolbar/addSchedule_toolbar'
import scheduleHelpers from 'core/helpers/_schedule'
import { routes } from 'features/routes'
import translate from 'core/translate'
import { ym } from 'blocks.app/yandexMetrika/yandexMetrika'
import template from 'es6-template-strings'
import { cn } from 'ethcss'
import { grid, item } from 'theme'
import styles from './broadcasts_add.jcss'
import { text as textStyles } from 'theme'
import { Steps } from 'atoms/Steps/Steps-view'
import { RouteLeavingGuard } from 'molecules/CustomPromt'
import { changeLocation } from 'features/routes'
import { isHorizontalMenu } from 'core/helpers/menu'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

interface IBroadcastsAdd {
    route: string
    location: {
        hash: string
        pathname: string
        search: string
        state: any
    }
}

const getNextStepButtonText = (step: string) => {
    if (step === 'schedule') {
        return translate('save')
    }

    if (step === 'conflicts') {
        return translate('solveConflictsAndSave')
    }

    return translate('next')
}

const BroadcastsAdd = (p_: IBroadcastsAdd) => {
    let {
        state,
        setState,
        nextStepId,
        addScheduleRef,
        editorRef,
        cancel,
        clickOnStep,
        save,
        solveConflicts,
        onBroadcastScheduleConflicts,
        isPreventLeaveRoute,
        isShouldBlockNavigation,
    } = BroadcastsAddMethods()
    const s_ = state

    return (
        <Content
            indentsMod={'none'}
            fullHeight={s_.selectedStep === 'createBroadcast'}
            className={styles.broadcastsAddWrapper}
            toolbar={(() => {
                switch (s_.selectedStep) {
                    case 'devices':
                        return (
                            <AddScheduleToolbar
                                devices={s_.devices}
                                conflicts={s_.conflicts}
                                onChangeDevices={(devices) =>
                                    setState((prevState: any) => {
                                        return {
                                            ...prevState,
                                            devices,
                                        }
                                    })
                                }
                            />
                        )
                    default:
                        return null
                }
            })()}
        >
            <div>
                <Steps active={s_.selectedStep} contentClassName={styles.tab} className={styles.tabs}>
                    {s_.steps.map((step, index) => (
                        <div
                            key={`broadcastsAdd_${index}`}
                            id={step}
                            onClick={() => clickOnStep(s_.selectedStep, step)}
                        >
                            {translate(step)}
                        </div>
                    ))}
                </Steps>
                <div className={styles.stepsGap}></div>
                {s_.selectedStep !== 'createBroadcast' && (
                    <div className={styles.buttons}>
                        <div className={cn(grid.row)}>
                            <DropMenu
                                active={s_.active}
                                onChange={(active: {}[]) =>
                                    setState((prevState) => {
                                        return {
                                            ...prevState,
                                            active,
                                        }
                                    })
                                }
                                mod="centered"
                                contentHeight={true}
                                gray={true}
                                onClick={(clicked: string) => {
                                    switch (clicked) {
                                        case 'next':
                                            if (s_.selectedStep === 'devices') {
                                                clickOnStep(s_.selectedStep, 'schedule')
                                            }
                                            if (s_.selectedStep === 'schedule') {
                                                save(() => {})
                                            }
                                            if (s_.selectedStep === 'conflicts') {
                                                solveConflicts()
                                            }
                                            break
                                        case 'cancel':
                                            cancel()
                                            break
                                        default:
                                            break
                                    }
                                }}
                                items={[
                                    {
                                        id: 'cancel',
                                        button: <Button mod="fill">{translate('cancel')}</Button>,
                                    },
                                    {
                                        id: 'next',
                                        button: <Button>{getNextStepButtonText(s_.selectedStep)}</Button>,
                                    },
                                ]}
                            />
                        </div>
                    </div>
                )}
            </div>

            {(() => {
                switch (s_.selectedStep) {
                    case 'createBroadcast':
                        return (
                            <Editor
                                ref={editorRef}
                                // @ts-ignore
                                broadcast={s_.broadcast}
                                route={p_.route}
                                isSimple={true}
                                wrapperStyles={{
                                    height: isHorizontalMenu() ? 'calc(100vh - 124px)' : 'calc(100% - 57px)',
                                }}
                                location={p_.location}
                                onSave={(broadcast: {
                                    pages: {
                                        duration: number
                                        areas: {
                                            content: {}[]
                                        }[]
                                    }[]
                                }) => {
                                    setState((prevState) => {
                                        return {
                                            ...prevState,
                                            broadcast,
                                            advDuration: broadcast.pages[0].duration,
                                            advContentLength: broadcast.pages[0].areas[0].content.length,
                                            selectedStep: nextStepId || 'devices',
                                        }
                                    })
                                    ym('reachGoal', `addContentToDevice_${nextStepId}`)
                                    nextStepId = null
                                }}
                            />
                        )
                    default:
                        return (
                            <div className={grid.p_mdPlus}>
                                {s_.selectedStep === 'conflicts' && s_.conflicts.length > 0 && (
                                    <div className={item.white}>
                                        <table className={styles.conflictsTable}>
                                            <thead>
                                                <tr>
                                                    <th>{translate('object')}</th>
                                                    <th>{translate('cause')}</th>
                                                    <th>{translate('solution')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {s_.conflicts.map((conflict, index: number) => (
                                                    <tr key={`conflict_tr_${index}`}>
                                                        <td className={textStyles.center}>{conflict.obj.name}</td>
                                                        <td>
                                                            {template(translate(conflict.cause), {
                                                                platform: scheduleHelpers.getPlatformRestrictionsLabel(
                                                                    conflict.obj.platform,
                                                                    conflict.obj.dsVersion
                                                                ),
                                                            })}
                                                        </td>
                                                        <td>{translate(conflict.proposedSolution)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <AddSchedule
                                    addScheduleRef={addScheduleRef}
                                    broadcast={s_.broadcast}
                                    advDuration={s_.advDuration}
                                    advContentLength={s_.advContentLength}
                                    devices={s_.devices}
                                    onChangeDevices={
                                        s_.selectedStep === 'devices'
                                            ? (devices: any) =>
                                                  setState((prevState) => {
                                                      return {
                                                          ...prevState,
                                                          devices,
                                                      }
                                                  })
                                            : null
                                    }
                                    schedule={s_.schedule}
                                    onChangeDeferredDownload={(value: string, field: string) => {
                                        setState((prevState) => {
                                            return {
                                                ...prevState,
                                                [field]: value,
                                            }
                                        })
                                    }}
                                    deferredDownload={{
                                        date: s_.contentDownloadDate,
                                        time: s_.contentDownloadTime,
                                    }}
                                    onChangeSchedule={
                                        s_.selectedStep === 'schedule'
                                            ? (schedule: {}) =>
                                                  setState((prevState) => {
                                                      return {
                                                          ...prevState,
                                                          schedule,
                                                      }
                                                  })
                                            : null
                                    }
                                    onSave={(saved: boolean) =>
                                        setState((prevState) => {
                                            return {
                                                ...prevState,
                                                saved,
                                            }
                                        })
                                    }
                                    onGetConflicts={onBroadcastScheduleConflicts}
                                    hideConflicts={s_.selectedStep !== 'conflicts'}
                                    changeLocationOnSave={() => {
                                        changeLocation(`/${routes.broadcasts.path}`)
                                    }}
                                />
                            </div>
                        )
                }
            })()}

            <RouteLeavingGuard
                acceptLabel={''}
                rejectLabel={''}
                title={''}
                description={''}
                when={isPreventLeaveRoute()}
                shouldBlockNavigation={isShouldBlockNavigation}
                onAccept={(cb) => {
                    save(cb)
                }}
                navigate={(path) => changeLocation(path)}
            />
        </Content>
    )
}

const exportBroadCastsAdd = checkAvailableModule('addContentToDevice') ? BroadcastsAdd : null

export default exportBroadCastsAdd
