import React from 'react'
// @ts-ignore
import TetherDrop from 'react-tether'
import DropContentMethods from './dropContent.local.methods'
import Button from 'blocks.simple/button/button'
import Icon from 'blocks.simple/icon/icon'
import translate from 'core/translate'
import enhanceWithClickOutside from 'react-click-outside'
import styles from './dropContent.jcss'
import { cn } from 'ethcss'
import { grid } from '../../theme'

class InnerWrapper extends React.Component<{ onClickOutside: (e: MouseEvent) => void }> {
    handleClickOutside(e: MouseEvent) {
        const p_ = this.props

        p_.onClickOutside(e)
    }
    render() {
        const p_ = this.props

        return <div>{p_.children}</div>
    }
}

const InnerContent = enhanceWithClickOutside(InnerWrapper)

class DropContent extends DropContentMethods {
    static defaultProps: {
        isOpen: boolean
        indents: string
        arrow: boolean
        position: string
        onClickOutside: () => void
        animate: boolean
        isShow: boolean
        iconcolor: string
        transitionName: string
        className_: any
    }

    render() {
        const p_ = this.props
        const s_ = this.state
        const position = p_.position.split(' ')[0]
        let animation

        if (p_.animate) {
            animation = this.animationState
                ? styles[`${p_.transitionName}AnimationStart`]
                : styles[`${p_.transitionName}AnimationEnd`]
        }

        return (
            <TetherDrop attachment={p_.position}>
                {p_.children}
                {s_.isOpen && p_.isShow && (
                    <InnerContent onClickOutside={p_.onClickOutside}>
                        <div
                            className={cn(
                                styles.dropContent,
                                animation,
                                styles[`${position}DropContent`],
                                grid[`p_${p_.indents}`],
                                p_.className_
                            )}
                            style={p_.style}
                        >
                            {p_.customContent && p_.customContent}
                            {!p_.customContent && (
                                <div>
                                    <div className={grid.mb_mini}>{p_.text}</div>
                                    <div className={cn(grid.row, grid.center)}>
                                        <Button
                                            rounded="full_md"
                                            mod="fill"
                                            className={cn(grid.mr_mini)}
                                            indentSize="mini"
                                            {...p_.confirmButton}
                                        >
                                            {p_.confirmButtonText ? p_.confirmButtonText : translate('yes')}
                                        </Button>
                                        <Button rounded="full_md" indentSize="mini" {...p_.cancelButton}>
                                            {p_.cancelButtonText ? p_.cancelButtonText : translate('no')}
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {p_.arrow && (
                                <div className={cn(styles.iconDrop)} style={{ [position]: -12 }}>
                                    <Icon type={`arrowhead_${this.getArrowPosition(position)}`} color={p_.iconColor} />
                                </div>
                            )}
                        </div>
                    </InnerContent>
                )}
            </TetherDrop>
        )
    }
}

DropContent.defaultProps = {
    isOpen: false,
    isShow: true,
    arrow: true,
    animate: false,
    position: 'top center',
    iconcolor: 'darkCloud',
    indents: 'mini',
    transitionName: 'fade',
    onClickOutside: () => {},
    className_: '',
}

export default DropContent
