import { addStyles } from 'ethcss'
import { grid, IStyles, text } from 'theme'

export const styles: IStyles = {
    ConfirmNotification: {
        ...grid.colCenter,
        ...grid.p_md,
    },
    ConfirmNotification__text: {
        ...grid.w100,
        ...grid.mb_md,
        ...text.center,
    },
    ConfirmNotification__body: {
        ...grid.row,
        ...grid.justify,
        ...grid.mb_mini,
    },
    ConfirmNotification__button_type_confirm: {
        ...grid.mr_mini,
    },
}

addStyles(styles)
