import { useState } from 'react'

export interface ISearchbarFilterProps {
    filter?: any
    filterList?: { [index: string]: string }[]
    onFilterChange?: (filter: { [index: string]: string }) => void
    onRemoveFilterItem?: (filterItem: { [index: string]: string }) => void
    topIndent: string
}

const getSelectedFilterValues = (filter: any) => (filter ? { ...filter.selected } : null)

const SearchbarFilterListMethods = (p_: ISearchbarFilterProps) => {
    const [state, setState] = useState({
        showAll: false,
        selected: getSelectedFilterValues(p_.filter),
        dimensions: {},
        isHidden: true,
    })

    // static getDerivedStateFromProps(p_) {
    //     return {
    //         selected: getSelectedFilterValues(p_.filter)
    //     }
    // }

    const showAll = () => {
        setState((prevState: any) => {
            return { ...prevState, showAll: !state.showAll }
        })
    }
    const contentWidth = (contentRect: { bounds: any }) => {
        setState((prevState: any) => {
            return { ...prevState, dimensions: contentRect.bounds.width }
        })
    }
    const isHideContent = (contentRect: { bounds: any }) => {
        setState((prevState: any) => {
            return {
                ...prevState,
                isHidden: state.dimensions > contentRect.bounds.width && state.dimensions !== contentRect.bounds.width,
            }
        })
    }
    const getFilterList = (selected: { [index: string]: string[] }) => {
        const filterList: { id: string; name: string }[] = []
        for (let key in selected) {
            if (selected.hasOwnProperty(key)) {
                selected[key].forEach((name: any) => {
                    filterList.push({ id: key, name })
                })
            }
        }

        return filterList
    }
    const removeFilterItem = (filterItem: { id: string; name: string }) => {
        const selectedList = [...state.selected[filterItem.id]]
        selectedList.splice(selectedList.indexOf(filterItem.name), 1)

        const filter = {
            [filterItem.id]: selectedList.join('//'),
        }

        if (p_.onFilterChange) {
            p_.onFilterChange(filter)
        }
    }

    return {
        showAll,
        contentWidth,
        isHideContent,
        getFilterList,
        removeFilterItem,
        state,
    }
}

export default SearchbarFilterListMethods
