import React from 'react'
import { ITypographyProps } from './Typography-types'
import { cn } from 'ethcss'
import { text } from 'theme'
import { typographyStyles } from './Typography-styles'

export class Typography extends React.PureComponent<ITypographyProps, any> {
    render() {
        const {
            element = 'div',
            type = 'text',
            textColor,
            textSize = 'normal',
            className,
            onClick,
            children,
            isEllipsis,
        } = this.props

        return React.createElement(
            element,
            {
                className: cn(
                    typographyStyles.typography,
                    typographyStyles[`typography_type_${type}`],
                    {
                        [typographyStyles.typography_type_ellipsis]: isEllipsis,
                    },
                    text[textSize],
                    className
                ),
                style: {
                    ...(textColor ? { color: textColor } : {}),
                },
                onClick,
            },
            children
        )
    }
}
