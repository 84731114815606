import store from 'core/store'

export const isHorizontalMenu = (): boolean => {
    const isMobile = store.getState().app.showMobMenu
    const menuView = store.getState().user.data?.settings.mainMenuType
    return menuView === 'horizontal' && !isMobile
}

export const themeFromStore = (): string | undefined => {
    const theme = store.getState().user.data?.settings.theme
    return theme
}
