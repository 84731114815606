import { addStyles } from 'ethcss'
import { grid, IStyles } from '../../../theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'

const settingsContentDuration: IStyles = {
    line: {
        ...grid.p_mini,
        borderTop: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    active: {
        boxShadow: `var(--var-module-app-component-app-style-shadow, ${defaultThemeStyles.shadow})`,
        color: `var(--var-module-app-component-app-style-active, ${defaultThemeStyles.active})`,
    },
    contentDuration__column: {
        ...grid.w50,
    },
    overflowAuto: {
        overflow: 'auto !important',
    },
    wrapper: {
        marginBottom: 20,
    },
}

addStyles(settingsContentDuration)
export default settingsContentDuration
