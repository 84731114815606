import React, { useState } from 'react'
import Modal from 'blocks.simple/modal/modal'
import Icon from 'blocks.simple/icon/icon'
import Button from 'blocks.simple/button/button'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { grid } from '../../theme'
import styles from './changelog.jcss'

interface IChangelogProps {
    open: boolean
    lang: number
    options: {
        id: number
        images: {
            left: string
            right: string
        }
        title: string
        text: string
        icon: string
    }[]
    onSkip: () => void
    onNext: (id?: number) => void
}

const Changelog = (p_: IChangelogProps) => {
    const [step, setStep] = useState<number>(0)
    const isNotLast = p_.options.length !== step + 1

    return (
        <Modal open={p_.open} mod={'clear'} name={'newFeatures'} showCloseIcon={false}>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <div>{translate('newFeatures')}</div>
                </div>
                <div className={styles.content}>
                    {!!p_.options.length && (
                        <div className={cn(styles.columns, grid.w80)}>
                            {p_.options[step].images.left && (
                                <div className={grid.w25}>
                                    <Rectangle src={p_.options[step].images.left} contain={true} className={grid.col} />
                                </div>
                            )}
                            <div className={styles.centerCol}>
                                <div className={cn(grid.row, grid.rowCenter)}>
                                    <div className={styles.iconWrapper}>
                                        <Icon type={p_.options[step].icon} size={26} color={'white'} />
                                    </div>
                                </div>
                                <div className={styles.title}>{p_.options[step].title[p_.lang]}</div>
                                <div className={styles.description}>{p_.options[step].text[p_.lang]}</div>
                                <div className={cn(grid.row, grid.noWrap, grid.center, grid.mt_big)}>
                                    {isNotLast && (
                                        <Button
                                            className={cn(grid.mr_mini)}
                                            onClick={function () {
                                                p_.onSkip()
                                            }}
                                        >
                                            {translate('skip')}
                                        </Button>
                                    )}
                                    <Button
                                        onClick={() => {
                                            if (isNotLast) {
                                                setStep((prevState) => prevState + 1)
                                                p_.onNext(p_.options[step].id)
                                            } else {
                                                p_.onSkip()
                                            }
                                        }}
                                    >
                                        {isNotLast ? translate('next') : translate('understandably')}
                                    </Button>
                                </div>
                            </div>
                            {p_.options[step].images.right && (
                                <div className={grid.w25}>
                                    <Rectangle
                                        src={p_.options[step].images.right}
                                        contain={true}
                                        className={grid.col}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.columns}>
                    <div />
                    <div>
                        {step + 1}/{p_.options.length}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default Changelog
