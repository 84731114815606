import React from 'react'
import Content from 'blocks.app/content/content'
import TimetableAddMethods from './timetable_add.local.methods'
import Icon from 'blocks.simple/icon/icon'
import Button from 'blocks.simple/button/button'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import DropContent from 'blocks.simple/dropContent/dropContent'
import EditableDropdown from 'blocks.simple/editableDropdown/editableDropdown'
import EditInline from '../editInline/editInline'
import EditText from 'blocks.simple/editText/editText'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import translate from 'core/translate'
import moment from 'moment'
import { cn } from 'ethcss'
import styles from './timetable_add.jcss'
import { text as textStyles } from 'theme'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { Typography } from 'atoms/Typography'
import { connect } from 'react-redux'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

class TimetableAdd extends TimetableAddMethods {
    render() {
        const s_ = this.state
        const state = (byTimeIndex, lessonIndex) => s_.lessonsByTime[byTimeIndex].lessons[lessonIndex]
        const disabledDays = s_.days.filter((day, index) => s_.daysBetween.indexOf(index + 1) !== -1)

        return (
            <Content title={translate('creatingLessons')}>
                <div className={styles.header}>
                    <div className={cn(styles.headerContent, styles.borderBottom)}>
                        <Icon type="arrow_left" size="20" onClick={this.onPrevDate} />
                        <div className={styles.title}>{moment(s_.currentDate).format('dddd, D MMMM')}</div>
                        <Icon type="arrow_right" size="20" onClick={this.onNextDate} />
                    </div>
                    <div className={styles.headerContent}>
                        <div className={cn(grid.row, grid.justify, grid.fullWidth)}>
                            <div className={cn(grid.row)}>
                                <EditText
                                    type="date"
                                    id="startDate"
                                    indentSize={'mini'}
                                    label={translate('startDate')}
                                    containerClass={cn(grid.row, grid.normalCenter, grid.mr_mdPlus)}
                                    className={cn(grid.ml_mini, textStyles.center)}
                                    minDate={moment()}
                                    maxDate={s_.repeatMode === 'once' ? null : moment(s_.endDate)}
                                    dateValue={s_.startDate ? moment(s_.startDate) : s_.startDate}
                                    onChange={(startDate) => {
                                        if (s_.repeatMode === 'once') {
                                            this.setState({ startDate, endDate: startDate })
                                        } else {
                                            this.setState({ startDate })
                                        }
                                        if (s_.repeatMode === 'weekly') {
                                            this.calcDaysBetween({ startDate, endDate: s_.endDate })
                                        }
                                    }}
                                />
                                <EditText
                                    type="date"
                                    id="startDate"
                                    indentSize={'mini'}
                                    label={translate('endDate')}
                                    containerClass={cn(grid.row, grid.normalCenter, grid.mr_mdPlus)}
                                    className={cn(grid.ml_mini, textStyles.center)}
                                    minDate={moment(s_.startDate)}
                                    disabled={s_.repeatMode === 'once'}
                                    dateValue={s_.repeatMode === 'once' ? moment(s_.startDate) : moment(s_.endDate)}
                                    onChange={(endDate) => {
                                        if (s_.repeatMode === 'weekly') {
                                            this.calcDaysBetween({ startDate: s_.startDate, endDate })
                                        }
                                        this.setState({ endDate })
                                    }}
                                />
                                <div className={cn(grid.row, grid.normalCenter)}>
                                    <div className={grid.mr_mini}>{translate('repeat')}</div>
                                    <Dropdown
                                        value={s_.repeatMode}
                                        indentSize={'mini'}
                                        onChange={(selected) => this.onChangeRepeatMode(selected)}
                                        options={[
                                            { name: translate('once'), id: 'once' },
                                            { name: translate('daily'), id: 'daily' },
                                            { name: translate('weekly'), id: 'weekly' },
                                        ]}
                                    />
                                    <div className={cn(styles.days, { [item.hidden]: s_.repeatMode !== 'weekly' })}>
                                        {s_.days.map((day, index) => {
                                            const dayIndex = index + 1
                                            return (
                                                <div key={`day__checkbox_${dayIndex}`}>
                                                    <Checkbox
                                                        checked={s_.repeatDays.indexOf(dayIndex) !== -1}
                                                        onClick={(e) => this.onChangeDays(e, dayIndex)}
                                                        disabled={
                                                            disabledDays ? disabledDays.indexOf(day) === -1 : false
                                                        }
                                                    />
                                                    <div>{day}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <Button mod="fill" onClick={this.addNewTime}>
                                {translate('addTime')}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={styles.content}>
                    {s_.lessonsByTime.length > 0 && (
                        <div className={styles.table}>
                            <table className={item.tableWrapper}>
                                <thead>
                                    <tr>
                                        {this.getTableHeader().map((title, index) => (
                                            <th key={`table_header_title-${index}`}>{title}</th>
                                        ))}
                                        <th />
                                    </tr>
                                </thead>
                                {s_.lessonsByTime.map((lessonItem, indexItem) => (
                                    <tbody key={`lesson_by_time-${indexItem}`}>
                                        {lessonItem.lessons.map((lesson, index) => {
                                            const lessonsState = state(indexItem, index)
                                            return (
                                                <tr key={`lessons_list-${index}`}>
                                                    {index === 0 && (
                                                        <td
                                                            rowSpan={lessonItem.lessons.length + 1}
                                                            className={styles.lessonTime}
                                                            width="90"
                                                        >
                                                            <div
                                                                className={cn(
                                                                    item.tableCell,
                                                                    styles.lessonNumber,
                                                                    styles.borderBottom
                                                                )}
                                                            >
                                                                {indexItem + 1}
                                                            </div>
                                                            <div className={styles.timeline}>
                                                                <EditInline
                                                                    validate={(value) =>
                                                                        this.onTimeChangeValidate(
                                                                            value,
                                                                            indexItem,
                                                                            'startTime'
                                                                        )
                                                                    }
                                                                    activeClassName={cn(styles.editing)}
                                                                    text={s_.lessonsByTime[indexItem].startTime}
                                                                    isIcon={true}
                                                                    minLength={5}
                                                                    maxLength={5}
                                                                    mask="99:99"
                                                                    type="tel"
                                                                    placeholder="--:--"
                                                                    paramName="value"
                                                                    change={(value) =>
                                                                        this.onChangeTime(value, indexItem, 'startTime')
                                                                    }
                                                                />
                                                                <div>
                                                                    <EditInline
                                                                        validate={(value) =>
                                                                            this.onTimeChangeValidate(
                                                                                value,
                                                                                indexItem,
                                                                                'endTime'
                                                                            )
                                                                        }
                                                                        activeClassName={cn(styles.editing)}
                                                                        text={s_.lessonsByTime[indexItem].endTime}
                                                                        isIcon={true}
                                                                        minLength={5}
                                                                        maxLength={5}
                                                                        mask="99:99"
                                                                        type="tel"
                                                                        placeholder="--:--"
                                                                        paramName="value"
                                                                        change={(value) =>
                                                                            this.onChangeTime(
                                                                                value,
                                                                                indexItem,
                                                                                'endTime'
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className={cn(grid.rowCenter, grid.mt_mini)}>
                                                                    <Icon
                                                                        type="plus_circle"
                                                                        size="28"
                                                                        onClick={() => this.addNewLesson(indexItem)}
                                                                        tooltip={{
                                                                            title: translate('addNewLesson'),
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    )}
                                                    <td className={grid.w10}>
                                                        <div className={styles.tableCell}>
                                                            <div className={styles.editableCell}>
                                                                <EditableDropdown
                                                                    value={lessonsState.gradeId}
                                                                    indentSize="mini"
                                                                    options={s_.grade}
                                                                    searchable={true}
                                                                    labelField="title"
                                                                    onChange={(selected) =>
                                                                        this.onSelected(
                                                                            selected,
                                                                            'gradeId',
                                                                            indexItem,
                                                                            index
                                                                        )
                                                                    }
                                                                    containerClassName={cn(styles.editableCell)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className={grid.w15}>
                                                        <div className={styles.tableCell}>
                                                            <div className={styles.editableCell}>
                                                                <EditableDropdown
                                                                    value={lessonsState.courseId}
                                                                    options={s_.course}
                                                                    searchable={true}
                                                                    labelField="title"
                                                                    onChange={(selected) =>
                                                                        this.onSelected(
                                                                            selected,
                                                                            'courseId',
                                                                            indexItem,
                                                                            index
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className={grid.w30}>
                                                        <div className={styles.tableCell}>
                                                            <div className={styles.editableCell}>
                                                                <EditableDropdown
                                                                    value={lessonsState.subjectId}
                                                                    options={this.getSubjects(
                                                                        lessonsState.gradeId,
                                                                        lessonsState.courseId
                                                                    )}
                                                                    labelField="title"
                                                                    indentSize="mini"
                                                                    onChange={(selected) =>
                                                                        this.onSelected(
                                                                            selected,
                                                                            'subjectId',
                                                                            indexItem,
                                                                            index
                                                                        )
                                                                    }
                                                                    containerClassName={cn(styles.editableCell)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className={styles.tableCell}>
                                                            <div className={styles.editableCell}>
                                                                <EditableDropdown
                                                                    value={lessonsState.classroomId}
                                                                    indentSize="mini"
                                                                    options={s_.classrooms}
                                                                    searchable={true}
                                                                    onChange={(selected) =>
                                                                        this.onSelected(
                                                                            selected,
                                                                            'classroomId',
                                                                            indexItem,
                                                                            index
                                                                        )
                                                                    }
                                                                    labelField="title"
                                                                    containerClassName={cn(styles.editableCell)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className={grid.w20}>
                                                        <div className={styles.tableCell}>
                                                            <div className={styles.editableCell}>
                                                                <EditableDropdown
                                                                    value={lessonsState.teacherId}
                                                                    indentSize="mini"
                                                                    searchable={true}
                                                                    options={s_.teachers}
                                                                    onChange={(selected) =>
                                                                        this.onSelected(
                                                                            selected,
                                                                            'teacherId',
                                                                            indexItem,
                                                                            index
                                                                        )
                                                                    }
                                                                    containerClassName={cn(styles.editableCell)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className={styles.tableCell}>
                                                            <Icon
                                                                className={styles.deleteIcon}
                                                                type="delete"
                                                                onClick={() => this.removeLesson(indexItem, index)}
                                                                tooltip={{
                                                                    title: translate('delete'),
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                ))}
                            </table>
                        </div>
                    )}
                    {s_.lessonsByTime.length > 0 && (
                        <div className={styles.footer}>
                            {s_.isUpdate && (
                                <DropContent
                                    text={`${translate('youSureToDeleteSchedule')} ${moment(s_.currentDate).format(
                                        'YYYY-MM-DD'
                                    )}`}
                                    isOpen={s_.isOpen}
                                    confirmButton={{
                                        onClick: () => this.removeTimetable(),
                                        disabled: s_.isLoading,
                                    }}
                                    cancelButton={{
                                        onClick: () => this.setState({ isOpen: !s_.isOpen }),
                                    }}
                                    position="bottom center"
                                >
                                    <Button
                                        className={cn(grid.mr_mini)}
                                        onClick={() => {
                                            this.setState({ isOpen: !s_.isOpen })
                                        }}
                                    >
                                        {translate('delete')}
                                    </Button>
                                </DropContent>
                            )}
                            <Button className={cn(grid.mr_mini)} onClick={this.onCancel}>
                                {translate('cancel')}
                            </Button>
                            <Button mod="fill" onClick={this.submit}>
                                {translate('save')}
                            </Button>
                        </div>
                    )}
                    {s_.lessonsByTime.length === 0 && (
                        <div className={cn(grid.space, grid.col, grid.colCenter, grid.center, grid.full)}>
                            <Icon type="not_allow" size={60} />
                            <Typography type="text" className={cn(grid.pt_md, textStyles.up, textStyles.md)}>
                                {translate('noTimetable')}
                            </Typography>
                            <Typography
                                type="text"
                                className={cn(grid.pt_md, grid.w50, textStyles.normalPlus, textStyles.center)}
                            >
                                {translate('noTimetableDesc')}
                            </Typography>
                        </div>
                    )}
                </div>
            </Content>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        emitError: (err) => dispatch(emitError(err)),
    }
}

const exportTimetableAdd = checkAvailableModule('timetable/add')
    ? connect(null, mapDispatchToProps)(TimetableAdd)
    : null

export default exportTimetableAdd
