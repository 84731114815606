import React, { useState } from 'react'
import Measure from 'react-measure'
import Icon from 'blocks.simple/icon/icon'
import { cn } from 'ethcss'
import styles from './notification.jcss'
import { grid, item } from '../../theme'

interface INotificationProps {
    icon: {
        type: string
        color: string
        size: number
    }
    showIcon: boolean
    show: boolean
    children: React.ReactNode
    position: string
    color: string
}

const Notification = (p_: INotificationProps) => {
    const initialBounds = {
        bottom: 0,
        top: 0,
        height: 300,
    }
    const [bounds, setBounds] = useState<any>(initialBounds)

    return (
        <Measure
            bounds
            onResize={function ({ bounds }) {
                setBounds(bounds)
            }}
        >
            {({ measureRef }) => (
                <div
                    ref={measureRef}
                    className={cn(styles.notification, { [item.hidden]: !p_.show })}
                    style={{
                        ...item[p_.color],
                        zIndex: p_.color === 'errorLight' ? 11100 : 1800,
                        [p_.position]: p_.show ? 0 : -bounds.height,
                    }}
                >
                    {p_.showIcon && <Icon {...p_.icon} containerClass={cn(grid.mr_mini)} />}
                    {p_.children}
                </div>
            )}
        </Measure>
    )
}

Notification.defaultProps = {
    icon: {
        type: 'attention',
        color: 'white',
        size: 20,
    },
    showIcon: true,
    show: false,
    color: 'errorLight',
    position: 'bottom',
}

export default Notification
