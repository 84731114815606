import { addStyles } from 'ethcss'
import { grid, IStyles, item, mediaCSS } from 'theme'

export const styles: IStyles = {
    TileItem: {
        ...grid.pb_md,
        ...grid.pl_md,
        ...item.transitionErase,
        position: 'relative',
    },
    TileItem_type_minimize: {
        ...grid.pb_md,
        ...grid.pl_md,
        ...item.transitionErase,
        position: 'relative',
    },
    TileItem_type_mobile: {
        [mediaCSS.tabMini]: {
            ...grid.col3,
        },
        [mediaCSS.mob]: {
            ...grid.col1,
        },
    },
}

addStyles(styles)
