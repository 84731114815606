import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { IStyles, mediaCSS } from '../../../../theme'

const catalogTemplateBroadcastsStyles: IStyles = {
    image: {
        ...grid.w30,
    },
    wrapper: {
        ...grid.row,
        ...grid.mt_md,
        marginLeft: -grid.ml_md['margin-left'],
        marginBottom: -grid.mb_md['margin-bottom'],
        overflow: 'hidden',
    },
    mediaCellClassName: {
        [mediaCSS.tab]: {
            ...grid.col4,
        },
        [mediaCSS.tabMini]: {
            width: '50% !important',
        },
    },
}

addStyles(catalogTemplateBroadcastsStyles)
export default catalogTemplateBroadcastsStyles
