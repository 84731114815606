import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { addStyles } from 'ethcss'
import { mediaCSS, layers, IStyles, grid, item, text } from 'theme'

export const HEADER_HEIGHT = 80

export const headerStyles: IStyles = {
    wrapper: {
        ...item.transparent,
        ...grid.w100,
        zIndex: layers.sticky,
        height: HEADER_HEIGHT,
    },
    wrapper_x: {
        marginTop: '60px !important',
    },
    contentWrapper: {
        ...item.rel,
    },
    header: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
        ...grid.p_md,
        ...grid.pr_mdPlus,
        ...grid.pl_mdPlus,
        ...item.white,
        boxShadow: `var(--var-module-app-component-app-style-shadow, ${defaultThemeStyles.shadow})`,
        ...item.rounded_bottom_mini,
        ...item.noSelection,
    },
    title: {
        ...item.cursorDefault,
        ...text.up,
        ...text.mdPlus,
        [mediaCSS.tabMini]: {
            ...text.md,
        },
        letterSpacing: '0.1em',
    },
    profile: {
        ...grid.row,
        ...grid.noWrap,
        ...grid.normalCenter,
        ...grid.w20,
        ...item.pointer,
        [mediaCSS.tabMini]: {
            width: 'auto',
        },
    },
    username: {
        ...grid.ml_md,
        ...item.ellipsisPure,
        ...text.mdPlus,
        [mediaCSS.tabMini]: {
            ...item.none,
        },
    },
}

addStyles(headerStyles)
