import React from 'react'
import Icon from 'blocks.simple/icon/icon'
import { text as textStyles } from 'theme'
import { cn } from 'ethcss'
import styles from './selectListItem.jcss'

interface ISelectListItem {
    value: string | number
    mod: string
    onClick?: (cb?: any) => void
    onIconClick?: () => void
    className?: string | {}
}

const SelectListItem = (p_: ISelectListItem) => {
    return (
        <span
            className={cn(styles.item, textStyles[p_.mod], p_.className)}
            onClick={p_.onClick ? p_.onClick : () => {}}
        >
            {p_.value}
            {p_.onIconClick && (
                <span className={styles.icon}>
                    <Icon type="close" iconColor={p_.mod} size="10" onClick={p_.onIconClick} />
                </span>
            )}
        </span>
    )
}

SelectListItem.defaultProps = {
    mod: 'darkCloud',
}

export default SelectListItem
