import { addStyles } from 'ethcss'
import { colors, IStyles, mediaCSS } from '../../theme'
import { defaultThemeStyles } from '../../blocks.simple/editText/editText.theme'

const reportStyles: IStyles = {
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    searchWrapper: {
        position: 'relative',
    },
    searchInput: {
        width: 400,
        height: 40,
        padding: '0 26px',
        borderRadius: 5,
        color: `var(--var-module-ui-component-editText-style-withLightBorder-textMain, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
        background: `var(--var-module-ui-component-editText-style-withLightBorder-background, ${
            defaultThemeStyles[`withLightBorder-background`]
        })`,
        border: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-border`]
        })`,
        '&::placeholder': {
            color: `var(--var-module-ui-component-editText-style-withLightBorder-placeholder, ${
                defaultThemeStyles[`withLightBorder-placeholder`]
            })`,
        },
    },
    searchBtn: {
        position: 'absolute',
        right: 1,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    firmwaresTableWrapper: {
        borderTop: `1px solid ${colors.grey}`,

        [mediaCSS.tabMini]: {
            overflowX: 'auto',
        },
    },
    table: {
        width: '100%',
    },
    row: {
        borderBottom: `1px solid ${colors.grey}`,
        '&:hover': {
            '& td:last-child': {
                opacity: 1,
            },
        },
    },
    cell: {
        padding: '10px 0',
        textAlign: 'left',
        paddingRight: 20,

        [mediaCSS.tabMini]: {
            fontSize: 12,
            padding: '4px 10px',
        },
    },
    cellPlatform: {
        width: '13%',
    },
    cellVersion: {
        position: 'relative',
        width: '10%',
    },
    cellDeviceModels: {
        width: '30%',
        position: 'relative',
    },
    cellAddedBy: {
        width: '20%',
    },
    cellFirmwareLink: {
        width: '12%',
    },
    cellDate: {
        position: 'relative',
        width: '10%',
    },
    cellAction: {
        width: '5%',
        textAlign: 'center',
        opacity: 0,
        transition: 'opacity .3s',
    },
    edit: {
        position: 'absolute',
        right: 20,
        top: 10,
        opacity: 0,
        transition: 'opacity .3s',
    },
    modelsWrapper: {
        '&:hover': {
            '& > div:last-child': {
                opacity: 1,
            },
        },
    },
    modelTip: {
        margin: '10px 0 20px 0',
    },
    buttonGroup: {
        display: 'flex',
    },
    btn: {
        marginRight: 20,
    },
    triangle: {
        position: 'absolute',
        marginRight: '3px',
        marginLeft: '-11px',
    },
    wrapper: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 2000,
        backgroundColor: 'rgba(0,0,0, 0.2)',
    },
    deleteModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: colors.white,
        borderRadius: 20,
        padding: 30,
        width: 380,
        textAlign: 'center',

        [mediaCSS.mob]: {
            padding: '20px 10px',
            width: '96%',
            borderRadius: 10,
        },
    },
    btnGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 30,
    },
    modalBtn: {
        height: 40,
        padding: '0 44px',

        [mediaCSS.mob]: {
            padding: '0 30px',
        },
    },
}

addStyles(reportStyles)
export default reportStyles
