import { addStyles } from 'ethcss'
import { item, text, grid, IStyles } from 'theme'
import { defaultThemeStyles } from './Tabs-theme'

export const tabsStyles: IStyles = {
    wrapper: {
        ...grid.full,
        ...grid.col,
        ...item.rel,
    },
    content: {
        ...grid.p_md,
        ...grid.space,
    },
    tab: {
        ...grid.space,
        ...grid.rowCenter,
        ...grid.pt_md,
        ...grid.pb_md,
        ...item.pointer,
        ...text.center,
        ...text.noUnderline,
        borderRight: `2px solid var(--var-module-ui-component-tabs-style-border, ${defaultThemeStyles.border})`,
        borderBottom: `2px solid var(--var-module-ui-component-tabs-style-border, ${defaultThemeStyles.border})`,
        background: `var(--var-module-ui-component-tabs-style-background, ${defaultThemeStyles.background})`,
        color: `var(--var-module-ui-component-tabs-style-textMain, ${defaultThemeStyles.textMain})`,
        '&:hover': {
            borderRight: `2px solid var(--var-module-ui-component-tabs-style-hoverBorder, ${defaultThemeStyles.hoverBorder})!important`,
            borderBottom: `2px solid var(--var-module-ui-component-tabs-style-hoverBorder, ${defaultThemeStyles.hoverBorder})!important`,
            background: `var(--var-module-ui-component-tabs-style-hoverBackground, ${defaultThemeStyles.hoverBackground})!important`,
            color: `var(--var-module-ui-component-tabs-style-hover, ${defaultThemeStyles.hover})!important`,
        },
    },
    active: {
        borderRight: `2px solid var(--var-module-ui-component-tabs-style-activeBorder, ${defaultThemeStyles.activeBorder})!important`,
        borderBottom: `2px solid var(--var-module-ui-component-tabs-style-activeBorder, ${defaultThemeStyles.activeBorder})!important`,
        background: `var(--var-module-ui-component-tabs-style-activeBackground, ${defaultThemeStyles.activeBackground})!important`,
        color: `var(--var-module-ui-component-tabs-style-active, ${defaultThemeStyles.active})!important`,
    },
    horizontal: {
        ...grid.row,
    },
    vertical: {
        ...grid.col,
    },
}

addStyles(tabsStyles)
