import { addStyles } from 'ethcss'
import { colors } from '../../../theme'

const catalogizatorStyles = {
    tabsWrapper: {
        display: 'flex',
    },
    tabsWrapper_x: {
        marginTop: '0px !important',
        zIndex: 9999,
    },
    tabBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '40px',
        color: '#000000',
        transition: 'all 0.2s',
        borderRadius: '7px',
        marginRight: '10px',

        '&:last-child': {
            marginRight: 0,
        },

        '&:hover': {
            backgroundColor: '#DBDBDB',
            cursor: 'pointer',
            borderColor: '#DBDBDB',
        },
    },
    tabActive: {
        background: '#DBDBDB',
    },
}

addStyles(catalogizatorStyles)
export default catalogizatorStyles
