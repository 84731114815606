import { addStyles } from 'ethcss'
import { indents } from 'blocks.app/config'
import { string } from 'prop-types'

const flex = {
    display: 'flex',
}
const row = {
    ...flex,
    '-ms-flex-direction': 'row',
    '-webkit-flex-direction': 'row',
    '-webkit-box-orient': 'horizontal',
    '-webkit-box-direction': 'normal',
    'flex-direction': 'row',
    '-webkit-flex-wrap': 'wrap',
    'flex-wrap': 'wrap',
    '-moz-flex-wrap': 'wrap',
}
const staticRow = {
    ...flex,
    '-ms-flex-direction': 'row',
    '-webkit-flex-direction': 'row',
    '-webkit-box-orient': 'horizontal',
    '-webkit-box-direction': 'normal',
    'flex-direction': 'row',
}
const col = {
    ...flex,
    '-ms-flex-direction': 'column',
    '-webkit-flex-direction': 'column',
    '-webkit-box-orient': 'vertical',
    '-webkit-box-direction': 'normal',
    'flex-direction': 'column',
}
const center = {
    '-ms-flex-pack': 'center',
    '-webkit-justify-content': 'center',
    '-webkit-box-pack': 'center',
    'justify-content': 'center',
}
const normalCenter = {
    '-ms-flex-align': 'center',
    '-webkit-align-items': 'center',
    '-webkit-box-align': 'center',
    'align-items': 'center',
}

const grid = {
    hspace() {
        return {
            ...this.col,
            ...this.space,
        }
    },
    full: {
        width: '100%',
        height: '100%',
    },
    fullWidth: {
        width: '100%',
    },
    fullHeight: {
        height: '100%',
    },
    flex: flex,
    space: {
        '-ms-flex': '1 1 0.000000001px',
        '-webkit-flex': '1',
        '-webkit-box-flex': '1',
        flex: '1',
    },
    flexAuto: {
        '-ms-flex': '1 1 auto',
        '-webkit-flex': '1 1 auto',
        '-webkit-box-flex': '1 1 auto',
        flex: '1 1 auto',
    },
    noSpace: {
        '-ms-flex': 'none',
        '-webkit-flex': 'none',
        '-webkit-box-flex': 'none',
        flex: 'none',
    },
    noWrap: {
        '-webkit-flex-wrap': 'nowrap!important',
        'flex-wrap': 'nowrap!important',
        '-moz-flex-wrap': 'nowrap!important',
    },
    row: row,
    staticRow: staticRow,
    col: col,
    justify: {
        '-ms-flex-pack': 'justify',
        '-webkit-justify-content': 'space-between',
        '-webkit-box-pack': 'justify',
        'justify-content': 'space-between',
    },
    around: {
        '-webkit-justify-content': 'space-around',
        '-ms-flex-pack': 'distribute',
        'justify-content': 'space-around',
    },
    centerImportant: {
        '-ms-flex-pack': 'center!important',
        '-webkit-justify-content': 'center!important',
        '-webkit-box-pack': 'center!important',
        'justify-content': 'center!important',
    },
    center: center,
    normalCenter: normalCenter,
    rowCenter: {
        ...row,
        ...center,
        ...normalCenter,
    },
    flexStart: {
        'align-self': 'flex-start',
    },
    flexEnd: {
        'align-self': 'flex-end',
    },
    verticalStart: {
        '-webkit-box-align': 'start',
        '-webkit-align-items': 'flex-start',
        '-moz-box-align': 'start',
        '-ms-flex-align': 'start',
        'align-items': 'flex-start',
    },
    colCenter: {
        ...col,
        ...center,
        ...normalCenter,
    },
    startJustify: {
        '-ms-flex-pack': 'start',
        '-webkit-justify-content': 'flex-start',
        'justify-content': 'flex-start',
    },
    endJustify: {
        '-ms-flex-pack': 'end',
        '-webkit-justify-content': 'flex-end',
        'justify-content': 'flex-end',
    },
    spaceWidth() {
        return {
            ...this.space,
            width: 0,
            overflow: 'hidden',
        }
    },
    spaceHeight() {
        return {
            ...this.space,
            height: 0,
            overflow: 'hidden',
        }
    },
    ...(() => {
        let widths: any = {}

        for (let i = 0; i <= 20; i++) {
            widths[`w${i * 5}`] = { width: `${i * 5}%` }
        }

        return widths
    })(),
    ...(() => {
        let cols: any = {}

        for (let i = 1; i <= 12; i++) {
            cols[`col${i}`] = { width: `${100 / i}%` }
        }

        return cols
    })(),
    ...(() => {
        const sides = [
            { shortName: 't', name: 'top' },
            { shortName: 'r', name: 'right' },
            { shortName: 'b', name: 'bottom' },
            { shortName: 'l', name: 'left' },
        ]
        let indentsStyles: any = {}
        const indentsFull: { [index: string]: string } = { auto: 'auto', ...indents }

        for (let name in indentsFull) {
            if (indentsFull.hasOwnProperty(name)) {
                const indent = indentsFull[name]

                indentsStyles[`m_${name}`] = { margin: indent }
                indentsStyles[`p_${name}`] = { padding: indent }

                sides.forEach((side) => {
                    indentsStyles[`m${side.shortName}_${name}`] = {
                        [`margin-${side.name}`]: indent,
                    }

                    indentsStyles[`p${side.shortName}_${name}`] = {
                        [`padding-${side.name}`]: indent,
                    }
                })
            }
        }

        return indentsStyles
    })(),
    z2: {
        zIndex: 20,
    },
}

addStyles(grid)
export default grid
