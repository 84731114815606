import { addStyles } from 'ethcss'
import { grid, IStyles } from '../../../theme'
import { defaultThemeStyles } from 'blocks.app/sidebar/Sidebar-theme'
import { textSizes } from 'theme/media'

const scheduleToolbarStyles: IStyles = {
    scheduleTitle: {
        color: `var(--var-module-sidebar-component-schedule-style-title, ${defaultThemeStyles['schedule-title']})`,
    },
    addNewIcon: {
        ...grid.p_mini,
        ...grid.mr_mini,
        borderRadius: '50%',
        boxShadow: '-1px 3px 4px 0px rgba(0, 0, 0, 0.25)',
        backgroundColor: `var(--var-module-sidebar-component-schedule-style-addIcon, ${defaultThemeStyles['schedule-addIcon']})`,
    },
    date: {
        ...grid.pt_big,
        ...grid.pb_md,
        ...grid.pr_mini,
        ...grid.pl_mini,
        textAlign: 'center',
        fontSize: 112,
    },
    list: {
        ...grid.mt_big,
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-background, ${defaultThemeStyles.background})`,
        fontSize: textSizes.normal,
    },
}

addStyles(scheduleToolbarStyles)
export default scheduleToolbarStyles
