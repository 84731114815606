import React, { useState } from 'react'
import Modal from 'blocks.simple/modal/modal'
import Icon from 'blocks.simple/icon/icon'
import Tabs from 'blocks.simple/tabs/tabs'
import Button from 'blocks.simple/button/button'
import { EditorVolumeSettings } from '../__toolbar/_right/editor__toolbar_right'
import EditorAutoPositionAudio from '../__autoPositionAudio/editor__autoPositionAudio'
//import EditorConditionSettings from '../__conditionSettings/editor__conditionSettings';

import translate from 'core/translate'
import { editor } from '../editor.local.methods'

import { cn } from 'ethcss'
import styles from './editor__soundtrackSettings.jcss'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { isNotEmptyArray } from 'core/utils/coreUtil'
import { colors } from '../../../theme'

const EditorSoundtrackSettingsSidebar = (p_) => {
    function getSoundtrackVolume() {
        const volumes = p_.content.map((content) => content.volume)

        return Math.max.apply(null, volumes)
    }

    const selectedContent = p_.content[p_.selectedContent]
    const getTagsString = (content) => {
        const tags = content.source.tags
        return tags.map((tag) => tag.name).join(', ')
    }

    return (
        <div className={styles.sidebarContent}>
            <Tabs active="content" className="" tabWrapper={styles.sTab}>
                <div tabid="area" label={translate('soundtrack')}>
                    <div className={styles.title}>{translate('soundTrackSettings')}</div>
                    <div className={styles.label}>{translate('volumeChangesAllAudioTrackContent')}</div>
                    <EditorVolumeSettings type="soundtrack" volume={getSoundtrackVolume()} />
                </div>
                <div tabid="content" label={translate('content')}>
                    {selectedContent && (
                        <React.Fragment>
                            <div className={styles.title}>{translate('soundTrackContentSettings')}</div>
                            <div className={styles.label}>{translate('volumeChangesOnSelectedContent')}</div>
                            <EditorVolumeSettings volume={selectedContent.volume} />
                            <div className={cn(grid.rowCenter, grid.p_md)}>
                                <div className={grid.w45}>
                                    <div className={styles.label}>{translate('outputFrequency')}</div>
                                    <Button
                                        fullWidth={true}
                                        indentSize="mini"
                                        mod="fill"
                                        onClick={function () {
                                            editor.cloneContent(selectedContent)
                                        }}
                                        rounded={'full_md'}
                                    >
                                        {translate('add')}
                                    </Button>
                                </div>
                            </div>
                            {p_.isMobile && (
                                <div className={styles.tagsBlock}>
                                    <div className={styles.tagsCaption}>{translate('tags')}</div>
                                    <div>{getTagsString(selectedContent)}</div>
                                </div>
                            )}
                            {/* !isSimple &&
                        <EditorConditionSettings
                            playingCondition={selectedContent.playingCondition}
                            onChange={(playingCondition) => {
                                editor.setPlayingCondition(-1, p_.selectedContent, playingCondition)
                            }}
                        />
                        */}
                        </React.Fragment>
                    )}
                </div>
                <div tabid="autoPositionAudio" label={translate('autoPositioning')}>
                    <div className={styles.title}>{translate('autoPositionAudio')}</div>
                    <div className={item.blackColor}>
                        <EditorAutoPositionAudio
                            content={p_.content}
                            soundOrderMode={p_.soundOrderMode}
                            onSetSoundOrderMode={p_.onSetSoundOrderMode}
                        />
                    </div>
                </div>
            </Tabs>
            <div className={styles.sidebarFooter}>
                <Button mod="fill" onClick={!p_.isMobile ? p_.onClose : p_.onBack}>
                    {translate(!p_.isMobile ? 'close' : 'back')}
                </Button>
            </div>
        </div>
    )
}

const EditorSoundtrackSettings = (p_) => {
    const [toolbarIsOpened, setToolbarIsOpened] = useState(!p_.isMobile)
    const contentLength = p_.tagNameValue
        ? p_.content.filter((content) => content.source.tags.some((tag) => tag.name.includes(p_.tagNameValue))).length
        : p_.content.length
    const isSelectedAllContent = contentLength === p_.selected.length
    const isSelectedContent = isNotEmptyArray(p_.selected)

    const closeToolbar = () => {
        setToolbarIsOpened(false)
    }

    return (
        <Modal
            open={p_.open}
            showCloseIcon={false}
            contentClassName={styles.soundTrackSettingsWrapper}
            rightSidebarClass={cn(styles.sidebar)}
            sidebars={{
                right: toolbarIsOpened && (
                    <EditorSoundtrackSettingsSidebar
                        content={p_.content}
                        selectedContent={p_.selectedContent}
                        onClose={p_.onClose}
                        onBack={closeToolbar}
                        isSimple={p_.isSimple}
                        soundOrderMode={p_.soundOrderMode}
                        onSetSoundOrderMode={p_.onSetSoundOrderMode}
                        isMobile={p_.isMobile}
                    />
                ),
            }}
        >
            <div>
                <div className={styles.contentTitle}>{translate('audioTrackSettingsInBroadcast')}</div>
                <div className={styles.searchContainer}>
                    <div className={styles.searchInput}>
                        <input
                            value={p_.tagNameValue}
                            onChange={(e) => {
                                p_.changeTagName(e.target.value)
                                editor.onDeselectAllContentInArea({ selectedArea: -1 })
                            }}
                            placeholder={translate('searchByTagName')}
                        />
                    </div>
                    <Icon
                        color={'grey'}
                        type="audio_settings"
                        containerClass={styles.showMobToolbarBtn}
                        onClick={function () {
                            setToolbarIsOpened(!toolbarIsOpened)
                        }}
                    />
                </div>
                <div className={styles.header}>
                    <div className={styles.controlsWrapper}>
                        <Icon
                            color={!isSelectedContent ? 'greyLight' : 'grey'}
                            type="delete"
                            containerClass={styles.control}
                            tooltip={{ title: translate('delete') }}
                            disabled={!isSelectedContent}
                            onClick={function () {
                                editor.onRemoveSelectedSources({ selectedArea: -1 })
                            }}
                        />
                        {contentLength ? (
                            <Icon
                                containerClass={styles.control}
                                type={isSelectedAllContent ? 'close_tiny' : 'checked'}
                                tooltip={{
                                    title: isSelectedAllContent ? translate('deselect') : translate('selectAll'),
                                }}
                                onClick={function () {
                                    if (p_.tagNameValue) {
                                        editor.onSelectAllContentByTagNameInArea({
                                            selectedArea: -1,
                                            tagValue: p_.tagNameValue,
                                        })
                                        return
                                    }
                                    editor.onSelectAllContentInArea({ selectedArea: -1 })
                                }}
                            />
                        ) : null}
                    </div>
                    <div className={styles.fields}>
                        <div className={grid.w10}>
                            <div className={styles.fields__title}>{translate('content')}</div>
                        </div>
                        <div className={grid.w20}>
                            <div className={styles.fields__title}>{translate('name')}</div>
                        </div>
                        <div className={grid.w20}>
                            <div className={styles.fields__title}>{translate('tags')}</div>
                        </div>
                        <div className={grid.w25}>
                            <div className={styles.fields__title}>{translate('startTime')}</div>
                        </div>
                        <div className={grid.w25}>
                            <div className={styles.fields__title}>{translate('duration')}</div>
                        </div>
                    </div>
                </div>
                {!p_.children.props.content.length ? (
                    <div className={styles.treeViewCatalogEmptyWrapper}>
                        <div className={styles.treeViewCatalogEmptyContent}>
                            {<Icon type={'not_allow'} size={60} color={colors.grey} />}
                            <div className={styles.treeViewCatalogEmptyTitle}>
                                <div>{translate('notFound')}</div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={styles.content}>{p_.children}</div>
                )}
                {p_.isMobile && (
                    <div className={styles.modalFooter}>
                        <Button mod="fill" onClick={p_.onClose}>
                            {translate('close')}
                        </Button>
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default EditorSoundtrackSettings
