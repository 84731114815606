import React from 'react'
import styles from './searchbar__fade.jcss'

const SearchbarFade = () => {
    return (
        <div className={styles.wrapper}>
            {(() => {
                const gradient = []

                for (let i = 0; i < 10; i++) {
                    gradient.push(
                        <div
                            style={{ backgroundColor: `rgba(255, 255, 255, ${(i + 3) / 10})` }}
                            className={styles.item}
                            key={`fade__item_${i}`}
                        />
                    )
                }

                return gradient
            })()}
        </div>
    )
}

export default SearchbarFade
