import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { mediaCSS, textSizes } from 'theme/media'
import { IStyles } from '../../../theme'

const deviceSettingsMegafonStyles: IStyles = {
    title: {
        fontSize: textSizes.normal,
        ...grid.mb_md,
    },
    secondTitle: {
        ...grid.mb_md,
        ...grid.mt_md,
    },
    content: {
        ...grid.w60,
        [mediaCSS.tab]: {
            ...grid.w80,
        },
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    inputs: {
        ...grid.w60,
    },
    radioWrapper: {
        ...grid.row,
        ...grid.mb_md,
    },
    radio: {
        ...grid.mr_md,
    },
}

addStyles(deviceSettingsMegafonStyles)
export default deviceSettingsMegafonStyles
