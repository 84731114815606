import React, { FC, memo } from 'react'
import { IDropdownOptionsProps } from './DropdownOption-types'
import { styles } from './DropdownOption-styles'
import { cn } from 'ethcss'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from '../Dropdown.theme'
import Icon from 'blocks.simple/icon/icon'
import { isExist } from 'core/utils/coreUtil'
import { themeFromStore } from 'core/helpers/menu'

const DropdownOptionComponent: FC<IDropdownOptionsProps> = ({
    value,
    label,
    icon,
    className,
    activeClassName,
    onChangeOption,
    iconColor,
    activeIconColor,
    isActive,
    mod,
}) => {
    const getOptionClassName = () => {
        return cn(cn(styles.DropdownOption, styles[`DropdownOption_type_${mod}`]), className)
    }

    const getActiveOptionClassName = () => {
        return isActive
            ? cn(styles.DropdownOption_type_active, styles[`DropdownOption_type_${mod}_status_active`], activeClassName)
            : {}
    }

    const handleClickOption = () => {
        onChangeOption(label, value)
    }

    const getIconColor = () => {
        return isActive
            ? activeIconColor ||
                  getThemeStyleValue('ui', 'dropdown', `${mod}-activeTextSub`) ||
                  defaultThemeStyles['withShadow-activeTextSub']
            : iconColor ||
                  getThemeStyleValue('ui', 'dropdown', `${mod}-textSub`) ||
                  defaultThemeStyles['withShadow-textSub']
    }

    return (
        <div className={cn(getOptionClassName(), getActiveOptionClassName())} onClick={handleClickOption}>
            {icon && (
                <span
                    className={cn(styles.DropdownOption__icon, {
                        [styles.DropdownOption__icon_type_withLabel]: isExist(label),
                    })}
                >
                    <Icon type={icon} size={14} color={themeFromStore() === 'custom' ? 'white' : getIconColor()} />
                </span>
            )}
            <span className={styles.DropdownOption__label}>{label}</span>
        </div>
    )
}

export const DropdownOption = memo(DropdownOptionComponent)
