import React, { FC } from 'react'
import styles from './PlayControllButton-styles'
import Icon from '../../blocks.simple/icon/icon'
import { IPlayControllButton } from './PlayControllButton-types'
import translate from '../../core/translate'

export const PlayControllButton: FC<IPlayControllButton> = ({
    buttonClassName,
    title,
    valueKey,
    isPlaying,
    timer,
    onClick,
    editButton,
}) => {
    return (
        <div className={`${styles.button} ${buttonClassName ? buttonClassName : ''}`} onClick={onClick}>
            <div className={styles.buttonInfo}>
                <div className={styles.keyValue}>{valueKey.toUpperCase()}</div>
                <div className={styles.playbackStatus}>
                    <Icon
                        type={isPlaying ? 'controllStop' : 'controllPlay'}
                        color={'white'}
                        size={isPlaying ? 24 : 21}
                    />
                </div>
            </div>
            <div className={styles.details}>
                <div className={styles.title}>{title}</div>
                {isPlaying && <div className={styles.timer}>{timer}</div>}
            </div>
            <div className={styles.change} onClick={editButton}>
                {translate('editLabel')}
            </div>
        </div>
    )
}
