import React, { Fragment, FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { IAccountComponent, IAccountsSettingsComponent } from './AccountSettings-types'
import Collapse from 'blocks.simple/collapse/collapse'
import EditText, { EditTextPassword } from 'blocks.simple/editText/editText'
import Icon from 'blocks.simple/icon/icon'
import Button from 'blocks.simple/button/button'
import User from 'blocks.app/user/user'
import helpers from 'core/helpers'
import { grid } from 'theme'
import translate from 'core/translate'
import { accountSettingsStyles } from './AccountsSettings-styles'
import { cn } from 'ethcss'
import { useAuthorizationLogin } from 'core/hooks'
import { isNotEmptyString, trimSegmentsFromString } from 'core/utils/coreUtil'
import { useDispatch } from 'react-redux'
import { IUser } from 'core/models/Users'
import { userActions, deleteLinkedAccount as deleteLinkedAccountAction } from 'blocks.app/user/user.state'
import styles from '../settings.jcss'

const Account: FunctionComponent<IAccountComponent> = ({ isDeletable, id, deleteLinkedAccount, ...rest }) => (
    <div className={cn(accountSettingsStyles.account, accountSettingsStyles.itemList)}>
        <User
            {...rest}
            id={id}
            isDeletable={isDeletable}
            size={69}
            padding={'p_none'}
            containerClassName={cn(grid.row, grid.normalCenter, grid.space)}
            multipleAccounts
        />

        {isDeletable && helpers.isAvailable({ key: 'settings', action: 'update' }) && (
            <div className={accountSettingsStyles.deleteIcon}>
                <Icon
                    color={'blackLight'}
                    type={'delete'}
                    onClick={function () {
                        deleteLinkedAccount(id)
                    }}
                />
            </div>
        )}
    </div>
)

const AccountsSettingsComponent: FunctionComponent<IAccountsSettingsComponent> = ({
    main,
    current,
    tokens,
    ...rest
}) => {
    const dispatch = useDispatch()
    const addLinkedAccount = (userData: IUser) => dispatch(userActions.addLinkedAccount(userData))
    const deleteLinkedAccount = (id: number) => dispatch(deleteLinkedAccountAction(id))

    const {
        email,
        password,
        code,
        prefix,
        onChangeEmail,
        onChangePassword,
        onChangeCode,
        authStep,
        login,
        confirmCode,
        isLoading,
    } = useAuthorizationLogin({ onLogin: addLinkedAccount, accessToken: tokens?.accessToken })

    const getRawCodeString = (code: string) => {
        return trimSegmentsFromString(code, [`${prefix}-`, '_'])
    }

    const handleChangeCode = (code: string) => {
        let updatedCode = getRawCodeString(code)
        onChangeCode(updatedCode)
    }

    const handleSendEmailClick = (e: React.MouseEvent) => {
        if (isLoading) return

        login()
    }

    const isEnableLoginButton = () => {
        return !isLoading
    }

    const isEnableTwoFactorLoginButton = () => {
        return !isLoading && isNotEmptyString(getRawCodeString(code))
    }

    return (
        <Collapse
            title={() => translate('linkedAccounts')}
            className={cn(styles.background, grid.mb_md)}
            contentPadding={false}
        >
            <div className={accountSettingsStyles.accountsWrapper}>
                <div className={grid.row}>
                    {helpers.isAvailable({ key: 'settings', action: 'update' }) && (
                        <Fragment>
                            {authStep === 'ONE' && (
                                <div className={accountSettingsStyles.account}>
                                    <Button
                                        disabled={!isEnableLoginButton()}
                                        onClick={handleSendEmailClick}
                                        mod={'fill'}
                                        rounded={'full_circle'}
                                        className={cn(accountSettingsStyles.imgWrapperAdd)}
                                    >
                                        <Icon type="plus" color="white" />
                                    </Button>
                                    <div className={accountSettingsStyles.content}>
                                        <div className={grid.row}>
                                            <EditText
                                                value={email}
                                                placeholder={translate('loginProxy')}
                                                onChange={onChangeEmail}
                                                rounded={'full_md'}
                                                indentSize={'tiny'}
                                            />
                                        </div>
                                        <div className={cn(grid.row, grid.mt_mini)}>
                                            <EditTextPassword
                                                value={password}
                                                placeholder={translate('password')}
                                                onChange={onChangePassword}
                                                rounded={'full_md'}
                                                indentSize={'tiny'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {authStep === 'TWO' && (
                                <div className={accountSettingsStyles.account}>
                                    <Button
                                        disabled={!isEnableTwoFactorLoginButton()}
                                        onClick={confirmCode}
                                        mod={'fill'}
                                        rounded={'full_circle'}
                                        className={cn(accountSettingsStyles.imgWrapperAdd)}
                                    >
                                        <Icon type="plus" color="white" />
                                    </Button>
                                    <div className={accountSettingsStyles.content}>
                                        <div className={cn(accountSettingsStyles.accountSettings__text)}>{email}</div>
                                        <div className={cn(grid.row, grid.mt_mini)}>
                                            <EditText
                                                value={code}
                                                placeholder={translate('confirmEmailLoginPlaceholder')}
                                                onChange={handleChangeCode}
                                                rounded={'full_md'}
                                                indentSize={'tiny'}
                                                mask={`${prefix}-****`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    )}
                    {main && <Account {...main} active={main.id === current.id} type={'mainAccount'} />}
                    {main.multipleAccounts.map((account: any, index: number) => {
                        const active = account.id === current.id
                        return (
                            <Account
                                key={`linkedAccount_${index}`}
                                {...account}
                                active={active}
                                type={active ? 'currentAccount' : null}
                                isDeletable={true}
                                deleteLinkedAccount={deleteLinkedAccount}
                            />
                        )
                    })}
                </div>
            </div>
        </Collapse>
    )
}

export const AccountsSettings = connect((state: any) => ({
    main: state.user.mainUser,
    current: state.user.data,
    tokens: state.user.tokens,
}))(AccountsSettingsComponent)
