import React from 'react'
import PropTypes from 'prop-types'
import DisplaysScreenShotsMethods from './displays__screenShots.local.methods'
import DisplaysScreenShotsSidebar from './_sidebar/displays__screenShots_sidebar'
import ImageView from 'blocks.simple/imageView/imageView'
import Icon from 'blocks.simple/icon/icon'
import Modal from 'blocks.simple/modal/modal'
import PreviewMedia from 'blocks.app/preview/__media/preview__media'

import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './displays__screenShots.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import './animation/animation.css'

class DisplaysScreenShots extends DisplaysScreenShotsMethods {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        selectedInfo: PropTypes.object,
        onClose: PropTypes.func.isRequired,
    }

    listenersId = []

    startUpdateAnimation = () => {
        this.setState({ isScreenshotUpdating: true })
    }
    stopUpdateAnimation = () => {
        setTimeout(() => {
            this.setState({ isScreenshotUpdating: false })
        }, 1000)
    }

    componentDidMount() {
        api.addObserver('digitalSignageScreenshotCreated', this.stopUpdateAnimation, this.listenersId)
    }

    componentWillUnmount() {
        this.listenersId.forEach((id) => api.removeObserver(id))
    }

    render() {
        const p_ = this.props
        const s_ = this.state

        const orientationWidth = () => {
            return p_.selectedInfo &&
                p_.selectedInfo.settings &&
                p_.selectedInfo.settings.autoScreenshot &&
                p_.selectedInfo.settings.autoScreenshot.width
                ? p_.selectedInfo.settings.autoScreenshot.width
                : p_.selectedInfo.resolutionWidth && p_.selectedInfo.resolutionWidth
        }

        const orientationHeight = () => {
            return p_.selectedInfo &&
                p_.selectedInfo.settings &&
                p_.selectedInfo.settings.autoScreenshot &&
                p_.selectedInfo.settings.autoScreenshot.height
                ? p_.selectedInfo.settings.autoScreenshot.height
                : p_.selectedInfo.resolutionHeight
        }

        return (
            <Modal open={p_.open} showCloseIcon={false} mod="content">
                {p_.selectedInfo && (
                    <div className={styles.wrapper}>
                        <div className={styles.image}>
                            {s_.type !== 'video' && (
                                <ImageView
                                    ref={(imageView) => (this.imageView = imageView)}
                                    src={s_.image}
                                    title={s_.caption}
                                    rotate={this.state.rotate}
                                    emptyText={translate('mediaFileNotSelected')}
                                    zoomStatusChange={(zoomStatus: {}) => this.setState({ ...zoomStatus })}
                                    controls={
                                        <React.Fragment>
                                            <Icon
                                                type="rotate"
                                                color="white"
                                                size={24}
                                                onClick={this.onRotate}
                                                containerClass={cn(grid.p_md)}
                                                tooltip={{ title: translate('rotate') }}
                                            />
                                            <Icon
                                                type="zoom_in_bold"
                                                color="white"
                                                size={24}
                                                disabled={s_.maxZoom}
                                                onClick={this.zoomIn}
                                                containerClass={cn(grid.p_md)}
                                                tooltip={{ title: translate('zoomIn') }}
                                            />
                                            <Icon
                                                type="zoom_out_bold"
                                                color="white"
                                                size={24}
                                                disabled={s_.minZoom}
                                                onClick={this.zoomOut}
                                                containerClass={cn(grid.p_md)}
                                                tooltip={{ title: translate('zoomOut') }}
                                            />
                                            {p_.selectedInfo.type !== 'group' && (
                                                <Icon
                                                    type="update"
                                                    color="white"
                                                    size={24}
                                                    disabled={p_.selectedInfo && p_.selectedInfo.status === 'offline'}
                                                    onClick={() => {
                                                        this.startUpdateAnimation()
                                                        api.send('updateScreenshotCb', {
                                                            id: p_.selectedInfo.id,
                                                            width: orientationWidth(),
                                                            height: orientationHeight(),
                                                        })
                                                    }}
                                                    containerClass={cn(
                                                        grid.p_md,
                                                        s_.isScreenshotUpdating ? 'rotateAnimation' : ''
                                                    )}
                                                    tooltip={{ title: translate('update') }}
                                                />
                                            )}
                                            <Icon
                                                type="close"
                                                color="white"
                                                size={24}
                                                onClick={p_.onClose}
                                                containerClass={cn(grid.p_md)}
                                                tooltip={{ title: translate('close') }}
                                            />
                                        </React.Fragment>
                                    }
                                />
                            )}
                            {s_.type === 'video' && (
                                <div className={grid.full}>
                                    <div className={styles.empty}>
                                        <PreviewMedia url={s_.url} item={s_.item} onClose={p_.onClose} />
                                    </div>
                                </div>
                            )}
                        </div>
                        <DisplaysScreenShotsSidebar
                            onChangeActive={(item: any) => {
                                let itemData = { ...item, item: { fileType: item.type } }

                                if (item.metadata) {
                                    itemData.item = {
                                        ...itemData.item,
                                        ...item.metadata,
                                        src: item.url,
                                    }
                                }

                                this.setState({ ...itemData })
                            }}
                            onClose={p_.onClose}
                            minimized={s_.minimized}
                            selectedInfo={p_.selectedInfo}
                            toggleMinimized={() => {
                                this.setState({ minimized: !s_.minimized })
                            }}
                            startUpdateAnimation={this.startUpdateAnimation}
                        />
                    </div>
                )}
            </Modal>
        )
    }
}

export default DisplaysScreenShots
