import { addStyles } from 'ethcss'
import { IStyles } from 'theme'
import grid from 'blocks.simple/grid/grid.jcss'

export const statusStyles: IStyles = {
    status: {
        display: 'inline-block',
        fontSize: 12,
    },
    disable: {
        opacity: 0.15,
    },
    main: {
        ...grid.flex,
        ...grid.normalCenter,
        ...grid.center,
        marginBottom: 3,
    },
    statusText: {
        verticalAlign: 'middle',
    },
    marker: {
        marginRight: 5,
        verticalAlign: 'middle',
    },
}

addStyles(statusStyles)
