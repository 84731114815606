import { colors } from 'theme'

export const defaultThemeStyles = {
    background: colors.white,
    buttonUploads: colors.blackLight,
    toggleButton: colors.white,
    description: colors.darkCloud,
    text: colors.black,
    title: colors.black,
    textHover: colors.blue,
    wrapperBackground: colors.blackLight,
    wrapperText: colors.greyLight,
    contentBackground: colors.white,
    border: colors.cloud,
    borderElement: colors.black,
    'screenshot-border': colors.white,
    'screenshot-infoBorder': colors.blackLight,
    'screenshot-infoText': colors.grey,
    'screenshot-text': colors.white,
    menuTextHover: colors.blackLight,
    menuText: colors.grey,
    toggleText: colors.grey,
    activeToggleText: colors.blackLight,
    disableToggleText: colors.grey,
    itemSelected: colors.blueWhite,

    // move to other component - remove file- prefix
    'file-text': colors.darkCloud,
    'file-background': colors.darkCloud,
    'file-timeline': colors.white,
    'file-innerButton': colors.darkBlue,
    'file-circleButton': colors.cloud,

    'broadcast-title': colors.darkCloud,

    'schedule-title': colors.darkBlue,
    'schedule-addIcon': colors.darkBlue,
    'schedule-icon': 'rgba(0, 0, 0, 0.45)',
    'schedule-iconColor': 'rgba(0, 0, 0, 0.45)',

    offline: colors.grey,
}
