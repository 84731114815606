import React from 'react'
import moment from 'moment'
import ScheduleToolbarContentMethods from './schedule__toolbarContent.local.methods'
import Link from 'blocks.simple/link/link'
import Icon from 'blocks.simple/icon/icon'
import { ScheduleItem } from 'organisms/ScheduleItem/ScheduleItem-view'
import ScheduleTimer from '../__timer/schedule__timer'
import { grid } from 'theme'
import { text as textStyles } from 'theme'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './schedule__toolbarContent.jcss'
import helpers from 'core/helpers'
import { routes } from 'features/routes'
import { getURLSearchParamsString } from 'features/routes/utils'
import { changeLocation } from 'features/routes'
import { useDispatch } from 'react-redux'
import { scheduleActions, deleteSchedule } from '../schedule.state'

type Event = {
    id: any
    broadcastTitle: string
    timeTitle: string
}

const ToolbarContent = (p_: any) => {
    const dispatch = useDispatch()
    const {
        events,
        onDeleteEvent,
        getBroadcastIdByScheduleId,
        getScheduleToDigitalSignageListByScheduleId,
    } = ScheduleToolbarContentMethods(p_)

    const formattedSelectedDay = moment(p_.selectedDate).add(1, 'days')
    const nowDate = moment()

    return (
        <div>
            <div className={styles.scheduleTitle}>
                <div className={styles.date}>{moment(p_.selectedDate).format('D')}</div>
                <div className={cn(textStyles.big, textStyles.center, grid.mb_mini)}>
                    {translate(`month_${moment(p_.selectedDate).format('M')}`)}
                </div>
                <ScheduleTimer selectedDate={moment(p_.selectedDate)} />
            </div>
            <div>
                <div className={styles.list}>
                    {helpers.isAvailable({ key: 'schedule', action: 'create', strict: true }) &&
                        formattedSelectedDay.isAfter(nowDate) && (
                            <div className={cn(grid.p_mini)}>
                                <Link
                                    to={`/${routes.addSchedule.path}`}
                                    mods={['micro', 'noUnderline']}
                                    className={cn(grid.rowCenter, grid.normalCenter)}
                                >
                                    <div className={styles.addNewIcon}>
                                        <Icon size={20} type="plus" color="white" />
                                    </div>
                                    <span>{translate('addNew')}</span>
                                </Link>
                            </div>
                        )}
                    {events.map((event: Event, index) => (
                        <ScheduleItem
                            key={`eventItem_${index}`}
                            id={event.id}
                            setEvents={onDeleteEvent}
                            broadcastId={getBroadcastIdByScheduleId(event.id)}
                            scheduleToDigitalSignageList={getScheduleToDigitalSignageListByScheduleId(event.id)}
                            timeTitle={event.timeTitle}
                            selected={event.id === p_.selectedEventId}
                            broadcastTitle={event.broadcastTitle}
                            onDeleteClick={function () {
                                dispatch(deleteSchedule(event.id))
                            }}
                            onEditClick={function () {
                                changeLocation({
                                    pathname: `/${routes.editSchedule.path}`,
                                    search: getURLSearchParamsString({
                                        scheduleId: p_.selectedEventId,
                                    }),
                                })
                            }}
                            onSelectClick={function () {
                                dispatch(scheduleActions.setSelectedEventId(event.id))
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ToolbarContent
