import React from 'react';
import {AbstractNodeFactory, DefaultNodeModel} from 'storm-react-diagrams';
import FlowDiagramNodeWidget from './flowDiagram__node_widget';

class FlowDiagramNodeFactory extends AbstractNodeFactory {
    constructor() {
        super('default');
    }

    generateReactWidget(diagramEngine, node) {
        return <FlowDiagramNodeWidget node={node} />;
    }

    getNewInstance() {
        return new DefaultNodeModel();
    }
}

export default FlowDiagramNodeFactory