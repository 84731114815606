import {useCallback, useEffect, useState} from 'react'
import moment, { Moment } from 'moment'
import { useOnMount, useOnUnmount } from 'core/hooks'

let timer: any = null
let updateKey = 0

const DashboardMethods = () => {
    const [time, setTime] = useState('10:00')

    useOnMount(() => initTimer())
    useOnUnmount(() => clearInterval(timer))

    const initTimer = () => {
        const deadline: any = moment().add(10, 'minutes')
        const now: any = moment()
        const diff = deadline - now

        setTime(moment(diff).format('mm:ss'))
        startTimer(deadline)
    }

    const startTimer = (deadline: any) => {
        clearInterval(timer)
        timer = setInterval(() => {
            const now: any = moment()

            const diff = deadline - now

            if (now >= deadline) {
                update()
                return
            }
            setTime(moment(diff).format('mm:ss'))
        }, 1000)
    }

    const update = () => {
        initTimer()
        updateKey = updateKey + 1
    }

    return {
        time,
        updateKey,
        update,
    }
}

export default DashboardMethods
