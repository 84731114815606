import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import UserMethods from './user.local.methods'
import Icon from 'blocks.simple/icon/icon'
import Badge from 'blocks.simple/notifyBadge/notifyBadge'
import enhanceWithClickOutside from 'react-click-outside'
import { authType, isActiveDirectory } from 'core/helpers/user'
import helpersCore from 'core/helpers'
import { cn } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import styles from './user.jcss'
import { text as textStyles } from 'theme'
import translate from 'core/translate'
import { Typography } from 'atoms/Typography'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { withRouter } from 'react-router'
import { downloadsActions } from 'core/services/DownloadService/DownloadService.state'
import { userActions } from './user.state'
import { isHorizontalMenu } from 'core/helpers/menu'
import DotTooltip from '../../blocks.simple/dotTooltip/dotTooltip'
import { IRole } from '../../core/models/Roles'

export interface IUserProps {
    type: string
    email: string
    role: string
    roles: IRole[]
    editableAvatar: boolean
    photo: string
    padding: string
    switchAccount: boolean
    active: boolean
    multipleAccounts: boolean
    containerClassName: string | object
    authType: string
    mainUser: {
        multipleAccounts: object[]
    }
    minimize: boolean
    toggleUserPanel: () => void
    size: {
        width: number
        height: number
    }
}

const User = (p_: IUserProps) => {
    // static propTypes = {
    //     email: PropTypes.string,
    //     roles: PropTypes.array,
    //     editableAvatar: PropTypes.bool,
    //     photo: PropTypes.string,
    //     padding: PropTypes.string,
    //     switchAccount: PropTypes.bool,
    //     size: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
    //     active: PropTypes.bool,
    //     multipleAccounts: PropTypes.bool,
    //     containerClassName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    //     authType: PropTypes.string,
    // }
    const { getAvatarSizes } = UserMethods(p_)
    const horizontalMenu = isHorizontalMenu()

    // static defaultProps = {
    //     padding: 'p_mini',
    //     size: this.horizontalMenu ? 35 : 80,
    //     active: false,
    //     multipleAccounts: false,
    //     containerClassName: styles.wrapper,
    //     authType: authType.smartplayer,
    // }

    const isMultipleAccountsExist = () => {
        const { mainUser } = p_

        return mainUser && mainUser.multipleAccounts && mainUser.multipleAccounts.length > 0
    }
    //{current: {name: string, email: string}
    const getUserName = (user: any) => {
        return user.current ? user.current.name || user.current.email : null
    }

    const avatarSize: any = getAvatarSizes()
    const avatarSizeX = getAvatarSizes('x')
    const isMainUserMinimize = isMultipleAccountsExist() && p_.minimize
    const roles = helpersCore.translateRoles(p_.roles)

    return horizontalMenu ? (
        <div onClick={p_.toggleUserPanel} className={styles.wrapper_x}>
            <div className={styles.userWrapper_x}>
                <DotTooltip tooltip={{ html: getUserName(p_) }} containerClass={item.ellipsis}>
                    <div className={item.ellipsis}>{getUserName(p_)}</div>
                </DotTooltip>
            </div>
        </div>
    ) : (
        <div className={cn(p_.containerClassName, grid[`${p_.padding}`])} onClick={p_.toggleUserPanel}>
            <div className={styles.imgWrapper} style={{ ...avatarSize }}>
                {!p_.photo && (
                    <div>
                        <Icon
                            color={`var(--var-module-ui-component-icon-style-mainSub, ${defaultThemeStyles.mainSub})`}
                            type={isActiveDirectory(p_.authType) ? 'usersAD' : 'users'}
                            size={getAvatarSizes(true)}
                            default={!p_.editableAvatar || isMainUserMinimize}
                        />
                    </div>
                )}
                {p_.photo && (
                    <div
                        className={styles.img}
                        style={{
                            backgroundImage: `url(${p_.photo})`,
                            cursor: p_.editableAvatar || isMainUserMinimize ? 'pointer' : 'default',
                        }}
                    ></div>
                )}
                {p_.role && p_.mainUser && !!p_.mainUser.multipleAccounts.length && !p_.minimize && (
                    <div className={styles.badge}>
                        <Badge
                            type={'active'}
                            label={<span className={textStyles.up}>{p_.role.charAt(0)}</span>}
                            textSize={'md'}
                        />
                    </div>
                )}
                {p_.multipleAccounts && roles && roles[0] && (
                    <div className={styles.badge}>
                        <Badge
                            type={p_.active ? 'active' : 'disable'}
                            label={<span className={textStyles.up}>{roles[0].label.charAt(0)}</span>}
                            textSize={'md'}
                        />
                    </div>
                )}
            </div>
            {!p_.multipleAccounts && (
                <div className={cn(styles.title, { [styles.hidden]: p_.minimize })}>
                    <div className={item.ellipsis}>{p_.email}</div>
                </div>
            )}
            {!p_.multipleAccounts && roles && roles[0] && <div className={styles.role}>{roles[0].label}</div>}
            {p_.multipleAccounts && (
                <div className={styles.content}>
                    <div className={cn(grid.rowCenter, grid.noWrap)}>
                        <div className={item.ellipsisWrapper}>
                            <div className={item.ellipsis}>{p_.email}</div>
                        </div>
                    </div>
                    {roles && roles[0] && (
                        <div className={cn(grid.rowCenter, grid.noWrap, textStyles.up, grid.mt_nano)}>
                            <div className={item.ellipsisWrapper}>
                                <div className={item.ellipsis}>{roles[0].label}</div>
                            </div>
                        </div>
                    )}
                    {p_.type && (
                        <div className={cn(grid.rowCenter, grid.noWrap, textStyles.mini, grid.mt_nano)}>
                            <div className={item.ellipsisWrapper}>
                                <Typography className={item.ellipsis}>{translate(p_.type)}</Typography>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state: {
    user: {
        mainUser: object
        data: {
            settings: object
        }
    }
}) => ({
    mainUser: state.user.mainUser,
    settings: state.user.data.settings,
    current: state.user.data,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeModalAndClearDownloads: () => dispatch(downloadsActions.closeModalAndClearDownloads()),
        toggleUserPanel: () => dispatch(userActions.toggleUserPanel()),
    }
}

User.defaultProps = {
    padding: 'p_mini',
    size: 80,
    active: false,
    multipleAccounts: false,
    containerClassName: styles.wrapper,
    authType: authType.smartplayer,
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(enhanceWithClickOutside(User)))
