import React, { useState, useEffect, createRef } from 'react'
import PropTypes from 'prop-types'
import { Avatar } from 'molecules/Avatar'
import { Icon } from 'molecules/Icon'

import helpers from 'core/helpers'
import translate from 'core/translate'

import styles from './styles'
import { mediaJS } from 'theme'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'

const AVATAR_IMAGE_TYPES = 'image/jpeg, image/png, image/pjpeg'

const ProfileAvatar = (p_) => {
    const [avatar, setAvatar] = useState(p_.src)
    const inputRef = createRef()

    useEffect(() => {
        setAvatar(p_.src)
    }, [p_.src])

    const handleClick = () => {
        if (!inputRef.current) {
            return
        }

        inputRef.current.click()
    }

    const handleChange = () => {
        const file = inputRef.current.files[0]

        if (file && AVATAR_IMAGE_TYPES.includes(file.type)) {
            const reader = new FileReader()

            reader.onload = (e) => {
                setAvatar(e.target.result)
            }

            reader.readAsDataURL(file)
            p_.onChange(file)
        }
    }

    return (
        <div className={styles.profileAvatarWrapper}>
            <Avatar
                className={styles.profileAvatarImage}
                src={avatar}
                {...helpers.getPropertiesByMediaQueries(
                    { desktop: { size: '80%' }, tabMini: { size: '35%' } },
                    mediaJS
                )}
                icon={{
                    type: 'user',
                    className: styles.profileAvatarIcon,
                    color: getThemeStyleValue('ui', 'icon', 'light') || defaultThemeStyles.light,
                }}
            />
            <div className={styles.profileEditAvatarWrapper}>
                <div className={styles.profileEditAvatar} onClick={handleClick}>
                    <Icon type="pencil" className={styles.profileEditIcon} color="white" />
                    {translate('editPhoto')}
                </div>
            </div>
            <input
                type="file"
                accept={AVATAR_IMAGE_TYPES}
                className={styles.profileEditAvatarInput}
                onChange={handleChange}
                ref={inputRef}
            />
        </div>
    )
}

ProfileAvatar.propTypes = {
    src: PropTypes.string,
    onChange: PropTypes.func,
}

export default ProfileAvatar
