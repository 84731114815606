import { addStyles } from 'ethcss'
import { grid, IStyles, mediaCSS } from '../../theme'

const broadcastsStyles: IStyles = {
    breadcrumbs: {
        ...grid.pl_mdPlus,
        marginLeft: -grid.m_mdPlus['margin'],
        marginTop: '10px',
        width: `calc(100% + ${2 * grid.m_mdPlus['margin']}px)`,
        [mediaCSS.tabMini]: {
            marginTop: -grid.m_mini['margin'],
            //paddingLeft: '36px',
        },
    },
    breadcrumbs_x: {
        paddingLeft: '0px !important',
    },
    broadcastWrapper: {
        [mediaCSS.tab]: {
            marginTop: 46,
        },
    },
}

addStyles(broadcastsStyles)
export default broadcastsStyles
