import React from 'react'
import PropTypes from 'prop-types'
import TagsListMethods from './tagsList.local.methods'
import Button from 'blocks.simple/button/button'
import EditableText from 'blocks.simple/editableText/editableText'
import EditText from 'blocks.simple/editText/editText'
import Icon from 'blocks.simple/icon/icon'
import ModalAccept from 'blocks.simple/modal/_accept/modal_accept'
import { cn } from 'ethcss'
import translate from 'core/translate'
import { grid } from 'theme'
import styles from './tagsList.jcss'
import { Typography } from 'atoms/Typography'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'

const Tag = (p_) => {
    const { s_, methods } = TagsListMethods(p_)
    return (
        <div className={styles.listItem}>
            <div className={styles.editable}>
                <EditableText
                    id={p_.tag.id}
                    text={p_.tag.name}
                    iconColor={getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable}
                    disabled={p_.disabled}
                    onUpdate={function (name) {
                        if (p_.tag.name === name) {
                            return
                        }
                        if (name.length < 3) {
                            methods.onChangeValidateSymbolNumber(p_.tag.id)
                            return
                        } else {
                            methods.onChangeValidateSymbolNumber(null)
                        }
                        p_.onUpdate({ name, id: p_.tag.id })
                    }}
                />
                {p_.tag.id === s_.stateOnChangeValidateSymbolNumber && (
                    <Typography className={cn(styles.centerBlock, grid.mt_mini, grid.mb_mini)} type="error">
                        {translate('minTagNumber')}
                    </Typography>
                )}
            </div>
            {!p_.disabled && (
                <div
                    className={styles.icon}
                    onClick={function () {
                        p_.onDelete({ id: p_.tag.id })
                    }}
                >
                    <Icon
                        color={getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable}
                        type="delete"
                        size={14}
                    />
                </div>
            )}
        </div>
    )
}

const TagsList = (p_) => {
    const { s_, methods } = TagsListMethods(p_)
    let tags = p_.tags

    if (s_.query) {
        tags = tags.filter((tag) => tag.name.toLowerCase().startsWith(s_.query.toLowerCase()))
    }

    return (
        <React.Fragment>
            {p_.title && (
                <Typography type="title" className={styles.title}>
                    {p_.title}
                </Typography>
            )}
            {!p_.onlyRead && (
                <form
                    className={styles.form}
                    onSubmit={function (event) {
                        event.preventDefault()

                        methods.onAddNewTag()
                    }}
                >
                    <EditText
                        containerClass={styles.input}
                        value={s_.newTag}
                        placeholder={translate('addNewTag')}
                        onChange={methods.onChange}
                    />
                    {s_.validateSymbolNumber && (
                        <Typography className={cn(styles.centerBlock, grid.mt_mini, grid.mb_mini)} type="error">
                            {translate('minTagNumber')}
                        </Typography>
                    )}
                    <Button type="submit" mod="withShadow">
                        {translate('add')}
                    </Button>
                </form>
            )}
            {p_.tags.length > 0 && (
                <React.Fragment>
                    <div className={styles.search}>
                        <EditText
                            containerClass={styles.input}
                            icon="search"
                            iconSize={14}
                            value={s_.query}
                            placeholder={translate('searchByTags')}
                            onChange={methods.onChangeQuery}
                        />
                    </div>
                    <div className={styles.content}>
                        {tags.map((tag) => (
                            <Tag
                                key={tag.id}
                                tag={tag}
                                onUpdate={p_.onUpdate}
                                onDelete={methods.onDelete}
                                disabled={p_.onlyRead}
                            />
                        ))}
                        {!tags.length && (
                            <Typography type="text" className={styles.message}>
                                {translate('notFound')}
                            </Typography>
                        )}
                    </div>
                </React.Fragment>
            )}
            {!p_.tags.length && (
                <Typography type="text" className={styles.message}>
                    {translate('tagListIsEmpty')}
                </Typography>
            )}
            <ModalAccept
                open={s_.confirmModal.open}
                onReject={methods.closeAcceptModal}
                onAccept={methods.onAcceptModal}
                onClose={methods.closeAcceptModal}
                description={translate('youAreSureToDeleteTag')}
                rejectLabel={translate('cancel')}
                acceptLabel={translate('delete')}
            />
        </React.Fragment>
    )
}

TagsList.propTypes = {
    tags: PropTypes.array.isRequired,
    title: PropTypes.string,
    onDelete: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onlyRead: PropTypes.bool,
}

export default TagsList
