import React, { useEffect, useState } from 'react'
import IconBase from 'react-icon-base'
import Tippy from 'blocks.simple/tippy/tippy'
import styles from './icon.jcss'
import { cn } from 'ethcss'
import { grid } from 'theme'
import { iconList } from './__list/icon__list'
import { transitions } from 'blocks.app/config'
import { colors } from 'theme'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from './icon.theme'

interface IconProps {
    default: boolean
    color?: string | null
    className?: string | {}
    direction?: string
    containerClass?: string | {}
    size: any
    style?: {}
    type: string | null
    disabled: boolean
    onClick?: (e: any, p_: any) => void
    tooltip?: {}
    animate: boolean
    rotate: string
    iconColor?: string
    id?: string
}

const Icon = (p_: IconProps) => {
    const [clicked, setClicked] = useState<boolean>(false)
    let transitionClickTimer: any = null
    const colorsList: {
        [index: string]: string
    } = colors

    useEffect(() => {
        return () => clearTimeout(transitionClickTimer)
    })

    const onClick = (e: MouseEvent) => {
        if (clicked) {
            setClicked(false)
            setTimeout(() => setClicked(true), 100)
        } else {
            setClicked(true)
        }

        if (p_.onClick) {
            p_.onClick(e, p_)

            transitionClickTimer = setTimeout(() => {
                setClicked(false)
            }, transitions.normal)
        }
    }

    let configColor
    let currentColor = p_.color || getThemeStyleValue('ui', 'icon', 'main') || defaultThemeStyles.main

    if (colorsList[currentColor]) {
        configColor = colorsList[currentColor]
    } else if (currentColor) {
        configColor = currentColor
    }

    const iconClass = p_.disabled ? styles.disable : p_.default ? styles.default : styles.normal
    const handlerOnClick = p_.disabled ? null : onClick
    const className = cn(`${p_.type}_icon`, iconClass, p_.className, {
        [styles[`${p_.rotate}Rotate`]]: p_.rotate,
    })
    const animationWrapper = cn({
        [styles[`${currentColor}Animation`]]: p_.onClick && clicked && !p_.disabled && p_.animate,
    })
    const wrapperClass = cn(styles.wrapper, { [grid.rowCenter]: p_.animate && p_.onClick }, p_.containerClass)

    if (p_.type && iconList[p_.type]) {
        return (
            <div className={wrapperClass}>
                <div
                    className={cn(animationWrapper, styles.iconAnimate)}
                    style={{
                        width: `${p_.size}px`,
                        height: `${p_.size}px`,
                    }}
                />
                {p_.tooltip ? (
                    <Tippy {...p_.tooltip}>
                        <IconBase
                            className={className}
                            viewBox={iconList[p_.type].viewBox}
                            size={p_.size}
                            style={p_.style}
                            color={configColor}
                            onClick={(e: any) => (handlerOnClick ? handlerOnClick(e) : () => {})}
                        >
                            {iconList[p_.type].image}
                        </IconBase>
                    </Tippy>
                ) : (
                    <IconBase
                        className={className}
                        viewBox={iconList[p_.type].viewBox}
                        size={p_.size}
                        style={p_.style}
                        color={configColor}
                        onClick={(e: any) => (handlerOnClick ? handlerOnClick(e) : () => {})}
                    >
                        {iconList[p_.type].image}
                    </IconBase>
                )}
            </div>
        )
    } else if (p_.type) {
        console.error(`Icon with name ${p_.type} not found`)
        return null
    }

    return null
}

Icon.defaultProps = {
    size: 16,
    disabled: false,
    default: false,
    rotate: '',
    animate: true,
}

export default Icon
