import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'

const instancesCatalogAskonaStyles = {
    wrapper: {
        ...item.white,
        ...item.rounded_full_mini,
        ...grid.col,
        ...grid.space,
    },
    tabs: {
        ...grid.row,
        ...grid.mb_md,
        ...item.rounded_full_mini,
    },
}

addStyles(instancesCatalogAskonaStyles)
export default instancesCatalogAskonaStyles
