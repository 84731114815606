import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { layers } from 'blocks.app/config'
import { defaultThemeStyles } from '../editor.theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { mediaCSS, textSizes } from 'theme/media'
import { colors, item, text } from 'theme'

const editorSoundtrackSettingsStyles = {
    soundTrackSettingsWrapper: {
        overflow: 'auto',
        backgroundColor: `var(--var-module-editor-component-soundtrack-style-panel, ${defaultThemeStyles['soundtrack-panel']})`,
    },
    sidebar: {
        zIndex: layers.modal + 2,

        [mediaCSS.tab]: {
            width: '100% !important',
            paddingBottom: 80,
        },
    },
    sidebarContent: {
        ...grid.col,
        ...grid.full,
        ...grid.justify,

        [mediaCSS.tab]: {
            height: 'auto',
        },
    },
    sidebarFooter: {
        ...grid.rowCenter,
        ...grid.p_md,

        [mediaCSS.tab]: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            padding: 14,
            backgroundColor: colors.white,
        },
    },
    header: {
        ...grid.w100,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.pt_micro,
        ...grid.pb_micro,
        fontSize: textSizes.mini,
        color: `var(--var-module-editor-component-soundtrack-style-text, ${defaultThemeStyles['soundtrack-text']})`,
        borderTop: `1px solid var(--var-module-editor-component-soundtrack-style-border, ${defaultThemeStyles['soundtrack-border']})`,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        backgroundColor: `var(--var-module-editor-component-soundtrack-style-panel, ${defaultThemeStyles['soundtrack-panel']})`,

        [mediaCSS.tab]: {
            display: 'none',
        },
    },
    controlsWrapper: {
        ...grid.row,
        width: '9%',
    },
    control: {
        ...grid.rowCenter,
        ...grid.w50,
    },
    fields: {
        ...grid.w90,
        ...grid.row,
        textAlign: 'center',
    },
    fields__title: {
        ...item.ellipsisPure,
    },
    content: {
        backgroundColor: `var(--var-module-editor-component-soundtrack-style-panel, ${defaultThemeStyles['soundtrack-panel']})`,
    },
    title: {
        textAlign: 'center',
        ...grid.p_md,
        fontSize: textSizes.normalPlus,
    },
    label: {
        ...grid.mb_micro,
        textAlign: 'center',
    },
    contentTitle: {
        fontSize: textSizes.md,
        textAlign: 'center',
        color: `var(--var-module-editor-component-soundtrack-style-textSecondary, ${defaultThemeStyles['soundtrack-textSecondary']})`,
        ...grid.p_md,
        ...item.ellipsisPure,
    },
    searchInput: {
        border: '1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, #b3b3b3)',
        borderRadius: '4px',
        padding: '7px 10px',
        width: '100%',

        [mediaCSS.tab]: {
            width: 'calc(100% - 28px)',
        },

        input: {
            border: 'none',
            width: '100%',
        },
    },
    treeViewCatalogEmptyWrapper: {
        ...grid.colCenter,
        ...grid.mt_md,
        paddingTop: '200px',
    },
    treeViewCatalogEmptyContent: {
        ...grid.row,
        ...grid.center,
        ...grid.mb_md,
    },
    treeViewCatalogEmptyTitle: {
        ...grid.fullWidth,
        ...grid.pt_mini,
        ...text.center,
        ...text.md,
        ...text.up,
        ...text.darkBlue,
        color: colors.grey,
    },
    sTab: {
        [mediaCSS.tab]: {
            padding: 7,
            marginBottom: 4,
        },
    },
    modalFooter: {
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        background: colors.white,
        padding: '14px 0',
    },
    showMobToolbarBtn: {
        display: 'none',

        [mediaCSS.tab]: {
            display: 'block',
        },
    },
    searchContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 10px 10px 10px',
    },
    tagsBlock: {
        textAlign: 'center',
        padding: '0 20px',
    },
    tagsCaption: {
        marginBottom: 10,
    },
    tagsList: {
        fontSize: 12,
    },
}

addStyles(editorSoundtrackSettingsStyles)
export default editorSoundtrackSettingsStyles
