import React from 'react'
import DisplaysInfo from './__info/displays__info'
import DeviceSettings from 'blocks.app/deviceSettings/deviceSettings'
import DisplaysNotifications from './__notifications/displays__notifications'
import WakeOnLan from './__wakeOnLan/wake__on__lan'
import DisplaysDateTime from './__datetime/displays__datetime'
import DisplaysPermissions from './__permissions/displays__permissions'
import DisplaysApprove from './__approve/displays__approve'
import DisplaysAdd from './__add/displays__add'
import DisplaysAddGroup from './__addGroup/displays__addGroup'
import { DisplayAudioControll } from './_blocks/displayAudioControll'
import { DisplayPlayAudioControll } from './_blocks/displayPlayAudioControll'
import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'
import helpersDigitalSignages from 'core/helpers/_digitalSignages'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { routes } from 'features/routes'
import displaysHelpers from 'core/helpers/_digitalSignages'
import { InstalledApplicationList } from './organisms/InstalledApplicationList'
import toolbar from './__toolbar/displays__toolbar.methods'
import { DownloadProgressList } from './organisms/DownloadProgressList'
import { ISelectedInfo } from './__info/displays__info.local.methods'
import { ISelectedItems } from './__toolbarContent/displays__toolbarContent'
import { IUser, IUserTokens } from '../../core/models/Users'
import { DisplaysSynchronization } from './__synchronization/displays__synchronization'
import { DisplayRemoteControl } from './__remoteControl/display__remoteControl'
import { DSChangeServer } from './__changeServer/display__changeServer'

interface IDisplaysProps {
    selectedInfo: ISelectedInfo
    closeModal: () => void
    location: any
    modalState: string
    modalOptions: any
    isConnected: boolean
    downloadLogsLink: (data: { fileUrl: string }) => void
    tags: { id: number }[]
    setTags: any
    clearState: () => void
    openApprove: (value: string) => void
    setGroups: any
    clearMap: () => void
    getDisplaysWithoutAddress: () => void
    user: IUser
    windowWidth: number
    className: {}
    tokens: IUserTokens
    mainUser: any
    groups: any
}

const ModalPlaceholder = ({ close }: { close: () => void }) => {
    close()

    return null
}

class DisplaysMethods extends React.Component<IDisplaysProps> {
    state: {
        selectedInfo: ISelectedInfo | null
        selectedItems: ISelectedItems[]
    } = {
        selectedInfo: null,
        selectedItems: [],
    }
    listenersId: string[] = []
    modalOptions: {
        mod: string
        showCloseIcon: boolean
        test?: boolean
        closeIconStyles?: {}
        style?: {}
    } = {
        mod: '',
        showCloseIcon: true,
        test: true,
    }

    closeModalContent() {
        const { closeModal } = this.props

        closeModal()
    }

    renderModalContent() {
        const s_ = this.state
        const p_ = this.props
        const query = getURLSearchParamsByLocation(p_.location)

        if (this.props.modalState === null) {
            this.modalOptions = {
                mod: 'white',
                showCloseIcon: true,
                closeIconStyles: !helpers.isMobileDevice()
                    ? {
                          top: '92px',
                      }
                    : {
                          display: 'none',
                      },
            }
        }
        if (this.props.modalState === 'changeServer') {
            this.modalOptions = {
                mod: 'clear',
                showCloseIcon: false,
            }
        }
        if (this.props.modalState === 'wakeOnLan') {
            this.modalOptions = {
                mod: 'clear',
                showCloseIcon: false,
            }
        }
        if (this.props.modalState === 'remoteDeviceControl') {
            this.modalOptions = {
                mod: 'black',
                showCloseIcon: true,
                closeIconStyles: !helpers.isMobileDevice()
                    ? {
                          top: '92px',
                      }
                    : {
                          display: 'none',
                      },
            }
        }

        switch (p_.modalState) {
            case 'approve':
                return <DisplaysApprove />
            case 'add':
                // @ts-ignore
                return <DisplaysAdd licenseId={p_.modalOptions.licenseId} />
            case 'addGroup':
                return <DisplaysAddGroup />
            case 'infoDisplay':
                // @ts-ignore
                return <DisplaysInfo groups={p_.groups} query={query} selectedInfo={s_.selectedInfo} />
            case 'displaySettings':
                return (
                    <DeviceSettings
                        {...s_.selectedInfo}
                        options={p_.modalOptions}
                        onClose={function () {
                            p_.closeModal()
                        }}
                    />
                )
            case 'displayNotification':
                return <DisplaysNotifications selectedInfo={s_.selectedInfo} />
            case 'wakeOnLan':
                return (
                    <WakeOnLan
                        digitalSignageId={s_.selectedInfo && s_.selectedInfo.id ? s_.selectedInfo.id : null}
                        onClose={p_.closeModal}
                    />
                )
            case 'dateTimeDisplay':
                return (
                    <DisplaysDateTime
                        selectedInfo={s_.selectedInfo || s_.selectedItems}
                        afterAction={() => {
                            this.setState({ selectedItems: [] })
                        }}
                    />
                )
            case 'permissions':
                return <DisplaysPermissions selectedInfo={s_.selectedInfo} />
            case 'displayAudioControll':
                return (
                    <DisplayAudioControll
                        selectedInfo={s_.selectedInfo}
                        closeModalContent={this.closeModalContent.bind(this)}
                    />
                )
            case 'displayPlayAudioControll':
                return (
                    <DisplayPlayAudioControll
                        selectedInfo={s_.selectedInfo}
                        closeModalContent={this.closeModalContent.bind(this)}
                    />
                )
            case 'displayInstalledApplicationList':
                return <InstalledApplicationList device={s_.selectedInfo} />
            case 'downloadContent':
                return s_.selectedInfo ? (
                    <DownloadProgressList digitalSignageId={s_.selectedInfo.id && s_.selectedInfo.id} />
                ) : null
            case 'displaysSynchronization':
                return s_.selectedInfo ? (
                    <DisplaysSynchronization selectedInfo={s_.selectedInfo} />
                ) : (
                    <ModalPlaceholder close={p_.closeModal} />
                )
            case 'remoteDeviceControl':
                return <DisplayRemoteControl device={s_.selectedInfo} />
            case 'changeServer':
                return (
                    <DSChangeServer
                        device={s_.selectedInfo}
                        onClose={function () {
                            p_.closeModal()
                        }}
                    />
                )
            default:
                return null
        }
    }

    onGetClientLogs = (res: any) => {
        const { isConnected, downloadLogsLink } = this.props

        if (isConnected) {
            downloadLogsLink(res)
        }
    }

    onDsTagCreated = (res: any) => {
        const p_ = this.props
        const tags = [...p_.tags, res]

        p_.setTags(tags)
    }

    onDsTagUpdated = (res: any) => {
        const p_ = this.props
        const tags = p_.tags.map((tag) => {
            if (tag.id === res.id) {
                return res
            }

            return tag
        })

        p_.setTags(tags)
    }

    onDsTagDeleted = (res: any) => {
        const p_ = this.props
        const tags = p_.tags.filter((tag) => tag.id !== res.id)

        p_.setTags(tags)
    }

    componentDidMount() {
        this.checkDisplayAddCode()
        this.initTagsListeners()
        this.initLogListeners()
    }

    componentWillUnmount() {
        const { clearState } = this.props
        clearState()

        this.listenersId.forEach((id) => api.removeObserver(id))
    }

    shouldComponentUpdate(nextProps: IDisplaysProps) {
        const p_ = this.props

        if (nextProps.modalState !== p_.modalState) {
            this.setState({
                modalContent: this.renderModalContent(),
            })
        }

        return true
    }

    checkDisplayAddCode = () => {
        const { location } = this.props

        const query = getURLSearchParamsByLocation(location)
        const displayAddCode = query[`${routes.addDisplay.prefix}_code`]

        if (!displayAddCode) return

        this.openApproveDisplayModal(displayAddCode)
    }
    openApproveDisplayModal = (displayAddCode: string) => {
        const { openApprove } = this.props
        const isValidApproveToken = displaysHelpers.isValidApproveToken(displayAddCode)
        const approveToken = isValidApproveToken ? displaysHelpers.getApproveTokenWithMask(displayAddCode) : ''

        openApprove(approveToken)
    }
    initLogListeners = () => {
        api.addObserver('getClientLogs', this.onGetClientLogs, this.listenersId)
    }
    initTagsListeners = () => {
        if (!helpers.isAvailable({ key: 'digitalSignage', action: 'read' })) return

        api.addObserver('dsTagCreated', this.onDsTagCreated, this.listenersId)
        api.addObserver('dsTagUpdated', this.onDsTagUpdated, this.listenersId)
        api.addObserver('dsTagDeleted', this.onDsTagDeleted, this.listenersId)
    }
    onGetFoldersOnMultiple = () => {
        const s_ = this.state
        const { setGroups } = this.props
        const digitalSignageId: number[] = []
        const groupId: number[] = []

        s_.selectedItems.forEach((selectedItem: ISelectedItems) => {
            if (helpersDigitalSignages.isFolder(selectedItem)) {
                groupId.push(selectedItem.id)
                return
            }

            digitalSignageId.push(selectedItem.id)
        })

        api.send('getAvailableParentGroups', { digitalSignageId, groupId, nestedQ: false }, { hideLoader: true }).then(
            (availableGroups: any) => {
                const groups = helpers.addParentKeyIdForTree(availableGroups, 'parentId')
                setGroups(groups)
            }
        )
    }
    onSelectInfo = (selectedInfo: ISelectedInfo) => {
        const { setGroups } = this.props
        this.setState({ selectedInfo })

        if (selectedInfo) {
            const isDirectory = selectedInfo.type === 'group'
            let params: {
                digitalSignageId?: number[]
                groupId?: number[]
            } = {
                digitalSignageId: [selectedInfo.id],
            }
            if (isDirectory) {
                params = {
                    groupId: [selectedInfo.id],
                }
            }
            api.send('getAvailableParentGroups', { ...params, nestedQ: false }, { hideLoader: true }).then(
                (availableFolders: any) => {
                    const groups = helpers.addParentKeyIdForTree(availableFolders, 'parentId')
                    setGroups(groups)
                }
            )
        }
    }

    onMapUnselect = () => {
        const { clearMap } = this.props

        clearMap()
    }

    onDoubleClickObject = (display: ISelectedInfo) => {
        if (
            helpers.isAvailableAction(display, {
                key: 'digitalSignage',
                action: 'update',
                subaction: 'generalEditing',
            })
        ) {
            toolbar.edit(display)
        }
    }

    mapSelect = () => {
        const { getDisplaysWithoutAddress } = this.props

        getDisplaysWithoutAddress()
    }

    onCatalogChange = () => {
        const { getDisplaysWithoutAddress } = this.props
        const disposition = this.getDisposition()

        if (disposition !== 'map') return

        getDisplaysWithoutAddress()
    }

    onGetTags = (tags: { id: number }[]) => {
        const { setTags } = this.props

        setTags(tags)
    }

    onSelect = (selectedItems: ISelectedItems) => {
        this.setState({ selectedItems })
    }

    getDisposition = () => {
        const { user } = this.props
        const viewParams = helpers.getViewParamsByUser('digitalSignage', user)

        return viewParams.disposition
    }

    onSelectItems = (selectedItems: ISelectedItems) => {
        this.setState({ selectedItems })
    }
}

export default DisplaysMethods
