import React, { useEffect, useRef, useState } from 'react'
import { api } from '../../../core/api/ConnectionManager'

const sortArrayOfObjectsByKey = (array: any, key: any) => {
    const devicesWithsyncOrder = array
        .filter((item: any) => typeof item[key] === 'number')
        .sort((a: any, b: any) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0))
    const devicesWithoutsyncOrder = array.filter((item: any) => typeof item[key] !== 'number')
    const lastsyncOrder = devicesWithsyncOrder.length ? devicesWithsyncOrder[devicesWithsyncOrder.length - 1][key] : 0
    const devicesWithAssignsyncOrder = devicesWithoutsyncOrder.map((device: any, index: number) => {
        return {
            ...device,
            [key]: !devicesWithsyncOrder.length ? lastsyncOrder + index : lastsyncOrder + (index + 1),
        }
    })
    if (!array.length) return []
    return [...devicesWithsyncOrder, ...devicesWithAssignsyncOrder]
}

export const DisplaysSynchronizationHooks = ({ selectedInfo }: { selectedInfo: any }) => {
    const [collapse, setCollapse] = useState<boolean>(false)
    const [cellsPerRow, setCellsPerRow] = useState<number>(6)
    const [autoScreenshotInterval, setAutoScreenshotInterval] = useState<number>(10)
    const [grid, setGrid] = useState<any>([])
    const [devices, setDevices] = useState<any>([])
    const [autoScreenshot, setAutoScreenshot] = useState<boolean>(false)
    const [tableView, setTableView] = useState<boolean>(false)
    const [screenshots, setScreenshots] = useState<any>([])
    const [statuses, setStatuses] = useState<any>({
        online: 0,
        offline: 0,
        error: 0,
        updating: 0,
    })
    const [syncRoles, setSyncRoles] = useState<any>([])
    const listenersId: string[] = []
    let interval = useRef<any>(null)

    const createGridByUpdate = (devices: any) => {
        const _grid = []
        const sortedDevices = sortArrayOfObjectsByKey(devices, 'syncListOrder')
        const lastsyncOrderIndex = sortedDevices.length ? sortedDevices[sortedDevices.length - 1].syncListOrder : 0

        let cellsCount = Math.ceil((lastsyncOrderIndex + 1) / cellsPerRow) * cellsPerRow
        if (cellsCount <= grid.length) {
            cellsCount = grid.length
        }
        for (let i = 0; i < cellsCount; i++) {
            const deviceInCell = sortedDevices.find((device: any) => device.syncListOrder === i)
            _grid.push({ id: deviceInCell ? deviceInCell.id : i + 10000 })
        }
        console.log(sortedDevices)
        console.log(_grid)
        setGrid(_grid)
    }
    const createGridBySelect = (rows: number) => {
        const grid = []
        const sortedDevices = sortArrayOfObjectsByKey(devices, 'syncListOrder')
        const cellsCount = cellsPerRow * rows
        for (let i = 0; i < cellsCount; i++) {
            const deviceInCell = sortedDevices.find((device: any) => device.syncListOrder === i)
            grid.push({ id: deviceInCell ? deviceInCell.id : i + 10000 })
        }

        setGrid(grid)
    }
    const getRowOptions = () => {
        const options = []
        const sortedDevices = sortArrayOfObjectsByKey(devices, 'syncListOrder')
        const lastsyncOrderIndex = sortedDevices.length ? sortedDevices[sortedDevices.length - 1].syncListOrder : 0
        const disabledToIndex = Math.ceil((lastsyncOrderIndex + 1) / cellsPerRow)
        for (let i = disabledToIndex; i <= 10; i++) {
            options.push(<option key={`option_${i}`}>{i}</option>)
        }
        return options
    }
    const onOptionsChange = (e: any) => {
        const rows = +e.target.value
        createGridBySelect(rows)
    }
    const onSwitchChange = () => {
        setAutoScreenshot(!autoScreenshot)
    }
    const onInputChange = (e: any) => {
        const currentValue = +e.target.value
        const value = Math.max(Number(e.target.min), Number(currentValue))

        if (isNaN(value)) return

        setAutoScreenshotInterval(value)
    }
    const onChangeView = () => {
        setTableView(!tableView)
        setCollapse(false)
        setAutoScreenshot(false)
    }
    const collapseMargins = (collapse: boolean) => {
        const deviceItem: any = document.querySelectorAll('.device-item')

        if (collapse) {
            for (let i = 0; i < deviceItem.length; i++) {
                //@ts-ignore
                deviceItem[i].style.cssText += 'transition: all 0.5s !important;'
                deviceItem[i].style.cssText +=
                    'margin-right: 20px !important; margin-bottom: 20px !important; border-radius: 5px'
                deviceItem[i].style.cssText +=
                    'margin-right: 0 !important; margin-bottom: 0 !important; border-radius: 0'
            }
            return
        }

        for (let i = 0; i < deviceItem.length; i++) {
            // @ts-ignore
            deviceItem[i].style.cssText += 'transition: all 0.5s !important;'
            deviceItem[i].style.cssText +=
                'margin-right: 20px !important; margin-bottom: 20px !important; border-radius: 5px'

            setTimeout(() => {
                //@ts-ignore
                deviceItem[i].style.transition = null
            }, 600)
        }
    }
    const takeScreenshots = () => {
        api.send('updateScreenshot', { groupId: selectedInfo.id, width: 288, height: 162, sync: true }).then((res) => {
            console.log(res)
        })
    }
    const onScreenshotCreated = (data: any) => {
        if (!data) return

        const data_1 = screenshots.filter((screenshot: any) =>
            data.every((item: any) => screenshot.digitalSignageId !== item.digitalSignageId)
        )
        setScreenshots([...data_1, ...data])
    }
    const onDigitalSignageUpdated = (data: any) => {
        if (!data) return

        if (data.parentId === selectedInfo.id) {
            const newDevicesList = devices.map((device: any) => {
                if (device.id !== data.id) {
                    return device
                }

                return {
                    ...data,
                    isSynchronized: true,
                    syncStatus: device.syncStatus,
                    syncListOrder: device.syncListOrder,
                }
            })
            setDevices(
                newDevicesList.some((device: any) => device.id === data.id) ? newDevicesList : [...newDevicesList, data]
            )
        }
    }
    const changeOrderDevices = (devices: any) => {
        api.send('updateDSSyncGroup', { parentId: selectedInfo.id, dsOrderList: devices })
    }
    const updateStatuses = () => {
        const online = devices.filter((device: any) => device.status === 'online').length
        const offline = devices.filter((device: any) => device.status === 'offline').length
        const error = devices.filter((device: any) => device.status === 'error').length
        const updating = devices.filter((device: any) => device.status === 'updating').length

        setStatuses({
            online,
            offline,
            error,
            updating,
        })
    }
    const onReceiveSyncStatuses = (data: any) => {
        if (!data) return

        const data_1 = syncRoles.filter((role: any) =>
            data.every((item: any) => role.digitalSignageId !== item.digitalSignageId)
        )
        setSyncRoles([...data_1, ...data])
    }
    const getSyncRole = (id: number) => {
        const status = syncRoles.find(
            (role: { digitalSignageId: number; syncRole: string }) => role.digitalSignageId === id
        )
        return status ? status.syncRole : null
    }
    const getDevices = () => {
        api.send('getSynchronizedDigitalSignages', { groupId: selectedInfo.id }).then((res: any) => {
            setDevices(res)
        })
    }
    useEffect(() => {
        if (autoScreenshot) {
            interval.current = setInterval(takeScreenshots, autoScreenshotInterval * 1000)
        }

        return () => clearInterval(interval.current)
    }, [autoScreenshot, autoScreenshotInterval])
    useEffect(() => {
        getDevices()
    }, [])
    useEffect(() => {
        api.addObserver('displayUpdated', onDigitalSignageUpdated, listenersId)
        api.addObserver('receiveSyncScreenshots', onScreenshotCreated, listenersId)
        api.addObserver('sendDsSyncRoles', onReceiveSyncStatuses, listenersId)
        return () => {
            listenersId.forEach((id) => api.removeObserver(id))
        }
    }, [devices, screenshots, syncRoles])
    useEffect(() => {
        createGridByUpdate(devices)
    }, [devices])
    useEffect(() => {
        updateStatuses()
    }, [devices])
    // useEffect(() => {
    //     setTimeout(() => {
    //         onReceiveSyncStatuses([
    //             {
    //                 digitalSignageId: 593,
    //                 syncRole: 'master',
    //             },
    //             {
    //                 digitalSignageId: 377,
    //                 syncRole: 'slave',
    //             },
    //         ])
    //     }, 4000)
    // }, [])

    return {
        collapse,
        setCollapse,
        grid,
        setGrid,
        devices,
        setDevices,
        getRowOptions,
        onOptionsChange,
        collapseMargins,
        autoScreenshot,
        onSwitchChange,
        autoScreenshotInterval,
        onInputChange,
        tableView,
        onChangeView,
        sortArrayOfObjectsByKey,
        screenshots,
        takeScreenshots,
        changeOrderDevices,
        statuses,
        getSyncRole,
        getDevices,
    }
}
