import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { mediaCSS, textSizes } from 'theme/media'
import { IStyles } from '../../../theme'

const tableEditorSpecialOptionsStyles: IStyles = {
    wrapper: {
        ...grid.row,
    },
    title: {
        ...grid.mb_mini,
        fontSize: textSizes.md,
    },
    content: {
        ...grid.p_md,
    },
    tableEditorSpecialOptions__cellOptions: {
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
        ...grid.pr_md,
    },
    tableEditorSpecialOptions__timings: {
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
}

addStyles(tableEditorSpecialOptionsStyles)
export default tableEditorSpecialOptionsStyles
