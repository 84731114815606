import { useState, useEffect } from 'react'
import { api } from 'core/api/ConnectionManager'
import helpersFiles from 'core/helpers/_files'
import { useDispatch } from 'react-redux'
import { filesActions } from '../files.state'

export interface IFilesEditTagsProps {
    sources: any[]
    isTagsEditModalOpen: boolean
    tags: any
}

const FilesEditTagsModalMethods = (p_: IFilesEditTagsProps) => {
    const dispatch = useDispatch()
    const [tags, setTags] = useState([])
    const [sourcesTags, setSourcesTags] = useState<{ id: number }[]>([])
    const [validateSymbolNumber, setValidateSymbolNumber] = useState<boolean>(false)

    useEffect(() => {
        if (!p_.isTagsEditModalOpen) {
            return
        }

        getSourcesTags()
        getTags()
        // eslint-disable-next-line
    }, [p_.sources && p_.sources[0] && p_.sources[0].tags, p_.isTagsEditModalOpen])

    function onCloseModal() {
        dispatch(filesActions.closeTagsEditModalOpen())
    }

    function getTags() {
        const tags = helpersFiles.getTagsList(p_.tags)
        setTags(tags)
    }

    function getSourcesTags() {
        const tags: { id: number }[] = []

        p_.sources.forEach((source) => {
            const sourceTags = helpersFiles.getTagsList(source.tags)

            tags.push(...sourceTags)
        })

        const filteredTags = tags.reduce((acc: { id: number }[], current: { id: number }) => {
            const x = acc.find((item) => item.id === current.id)
            if (!x) {
                return acc.concat([current])
            }

            return acc
        }, [])

        setSourcesTags(filteredTags)
    }

    function onChangeTags(tags: { id: number }[]) {
        setSourcesTags(tags)
    }

    function onSubmit() {
        onCloseModal()
        const sourceId = p_.sources.map((source) => source.id)
        const tags = helpersFiles.getTagsNamesList(sourcesTags)

        api.send('setTagsToSource', { sourceId, tags })
    }

    function onValidateSymbolNumber(isValidate: boolean) {
        setValidateSymbolNumber(isValidate)
    }

    return {
        s_: {
            tags,
            sourcesTags,
            validateSymbolNumber,
        },
        methods: {
            onCloseModal,
            onChangeTags,
            onSubmit,
            onValidateSymbolNumber,
        },
    }
}

export default FilesEditTagsModalMethods
