import React from 'react'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import styles from './catalog__template_lensOfTime.jcss'
import { CatalogActionBar } from 'organisms/Catalog/ActionBar/ActionBar-view'
import { ActionBarRequestHeaderType, ActionBarActionType } from 'core/models/ActionBar'
import { RenderTree, fakeParams } from './catalog_template_lensOfTime.local.methods'
import { ItemData } from '../../../../core/models/Template'

const getActionList = () => {
    return []
}

export default {
    getMethod: 'getLensData',
    customParams: {},
    trNoClick: true,
    disableDrag: true,
    emptySearchText: () => translate('notFound'),
    emptyDescription: () => translate('createFirstPosition'),
    emptyFolderText: () => translate('listIsEmpty'),
    emptyIcon: 'not_allow',
    addItemButton: () => null,
    settingsPrefixes: 'lensOfTime',
    sortOptions: () => [],
    headerFilters: (p_: any) => {
        return (
            <CatalogActionBar
                title={translate('lensOfTime')}
                requestHeaderType={ActionBarRequestHeaderType.WITH_AUTH}
                type="authorsList"
                actions={getActionList()}
                {...p_}
            />
        )
    },

    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },

    getBackendModel: (item: ItemData) => {
        return api.send('getLensData', { query: item.name }, { hideLoader: true })
    },
    isEqual: (firstItem: ItemData, secondItem: ItemData) => {
        if (firstItem.catalogId && secondItem.catalogId) {
            return firstItem.catalogId === secondItem.catalogId
        }

        return firstItem === secondItem
    },
    onCreateListeners: [
        (callback: any, clearList: string[]) => {
            return api.addObserver(
                'lensDataCreated',
                (lensDataCreated) => {
                    callback({ lensDataCreated })
                },
                clearList
            )
        },
    ],
    onUpdateListeners: [
        (callback: any, clearList: string[]) => {
            return api.addObserver(
                'lensDataUpdated',
                (lensDataUpdated: any) => {
                    callback({ ...lensDataUpdated })
                },
                clearList
            )
        },
    ],
    onDeleteListeners: [
        (callback: any, clearList: string[]) =>
            api.addObserver(
                'lensDataDeleted',
                (lensDataDeleted) => {
                    callback({ lensDataDeleted })
                },
                clearList
            ),
    ],

    isFolder: () => {},
    fields: () => [{ id: 'line', name: '' }],
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        line: (p_: any) => {
            return <RenderTree p_={p_ ? p_ : fakeParams()} create={!p_} />
        },
    },
    hideActiveRowColor: true,
    disableUpdateUserSettings: true,
    disableListScroll: true,
    fixedColWidth: false,
    dndDblClickDisable: true,
    tableWrapper: styles.tableWrapper,
}
