import React from 'react'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import TouchEditorActionsMethods, { ITouchEditorActionsProps } from './touchEditor__actions.local.methods'
import translate from 'core/translate'
import styles from './touchEditor__actions.jcss'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { connect } from 'react-redux'

const TouchEditorActions = (p_: ITouchEditorActionsProps) => {
    const { state, setState } = TouchEditorActionsMethods(p_)
    const s_ = state

    return (
        <React.Fragment>
            <div className={styles.actionType}>
                <Dropdown
                    label={translate('action')}
                    options={p_.actions}
                    value={s_.selectedAction && s_.selectedAction.id}
                    onChange={(selectedAction) =>
                        setState((prevState) => {
                            return {
                                ...prevState,
                                selectedAction: selectedAction,
                            }
                        })
                    }
                />
            </div>
            <div className={styles.settingsForm}>
                {s_.selectedAction && s_.selectedAction.id === 'show_broadcast_page' && (
                    <Dropdown
                        disabled={!s_.pages.length}
                        label={translate('goToPage')}
                        options={s_.pages}
                        value={s_.actionOptions && s_.actionOptions.value}
                        onChange={(selectedPage) => {
                            setState((prevState) => {
                                return {
                                    ...prevState,
                                    actionOptions: {
                                        key: 'order',
                                        value: selectedPage.id,
                                    },
                                }
                            })
                        }}
                    />
                )}
            </div>
        </React.Fragment>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        emitError: (err: string) => dispatch(emitError(err)),
    }
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(TouchEditorActions)
