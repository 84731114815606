import React, { useEffect, useState } from 'react'
import styles from './styles.jcss'
import { api } from '../../../../core/api/ConnectionManager'
import translate from '../../../../core/translate'

export const FileDetails = ({ sourceId }) => {
    const [source, setSource] = useState(null)

    useEffect(() => {
        if (!sourceId) return

        api.send('getFile', { id: sourceId }).then((res) => {
            setSource(res)
        })
    }, [sourceId])

    return (
        <div className={styles.fileDetails}>
            {source ? (
                <>
                    <div className={styles.thumbnail}>
                        <img src={source.thumbnail} />
                    </div>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <b>{translate('name')}:</b>
                                </td>
                                <td>{source.name.replace(/\.[^/.]+$/, '')}</td>
                            </tr>
                            <tr>
                                <td>
                                    <b>{translate('type')}:</b>
                                </td>
                                <td>{translate(source.fileType)}</td>
                            </tr>
                            <tr>
                                <td>
                                    <b>{translate('format')}:</b>
                                </td>
                                <td>{source.extension.replace(/\./g, '')}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
            ) : null}
        </div>
    )
}
