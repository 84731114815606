import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { mediaCSS, textSizes } from 'theme/media'
import { IStyles } from '../../../theme'

const searchbarFilterStyles: IStyles = {
    filterFields: {
        ...grid.p_mini,
        ...grid.pt_none,
        fontSize: textSizes.normal,
        overflow: 'auto',
    },
    delimer: {
        ...grid.fullHeight,
        position: 'absolute',
        borderLeft: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
        width: 0,
        zIndex: 101,
    },
    filterWrapper: {
        fontSize: textSizes.mini,
        ...grid.col,
        overflow: 'hidden',
        ...grid.fullHeight,
    },
    filter: {
        position: 'relative',
        [mediaCSS.tabMini]: {
            ...grid.space,
        },
    },
    modalWrapper: {
        ...grid.full,
        ...grid.rowCenter,
    },
    fieldWrapper: {
        ...grid.p_mini,
        ...grid.pt_none,
    },
    modalInner: {
        ...grid.w35,
        [mediaCSS.tabMini]: {
            ...grid.w90,
        },
        ...item.card,
        ...item.shadowModal,
        ...item.rounded_full_mini,
        overflow: 'hidden',
    },
    buttons: {
        ...grid.row,
        ...grid.p_mini,
        ...grid.justify,
    },
    checkItem: {
        ...grid.pb_mini,
        '&:last-child': {
            ...grid.pb_none,
        },
    },
}

addStyles(searchbarFilterStyles)
export default searchbarFilterStyles
