import React from 'react'
import UpdateDevicesMethods from './updateDevices.local.methods'
import UpdateDevicesToolbar from './__toolbar/updateDevices__toolbar'
import Content from 'blocks.app/content/content'
import EditText from 'blocks.simple/editText/editText'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import { SelectTime } from 'blocks.app/scheduleSettings/scheduleSettings'
import Link from 'blocks.simple/link/link'
import Button from 'blocks.simple/button/button'
import Catalog from 'blocks.app/catalog/catalog'
import ScrollUp from 'blocks.simple/scrollUp/scrollUp'
import { Radio } from 'atoms/Radio'
import translate from 'core/translate'
import styles from './updateDevices.jcss'
import { grid } from '../../theme'
import { cn } from 'ethcss'
import moment from 'moment'
import { routes } from 'features/routes'
import { Steps } from 'atoms/Steps/Steps-view'
import { Typography } from 'atoms/Typography'
import { getURLSearchParamsString, getURLSearchParamsByLocation } from 'features/routes/utils'
import { changeLocation } from 'features/routes'
import { checkAvailableModule } from '../../core/helpers/routes/routes'

interface IProps {
    location: {
        pathname: string
        search: string
        hash: string
        state: any
    }
    updateType: string
}

const getDisplayPlatformName = (platform: string) => {
    switch (platform) {
        case 'sssp':
            return 'SSSP 2/3/4/5/6/7/10'
        case 'brightsign':
            return 'BRIGHTSIGN'
        case 'android':
            return 'Android'
        default:
            return 'WebOS 2/3/3.2/4/4.1/6'
    }
}

const UpdateDevices = (p_: IProps) => {
    const { state, setState, changeTab, timeOptions, updateNowDevice, onUpdate } = UpdateDevicesMethods(p_)
    const s_ = state
    const query = getURLSearchParamsByLocation(p_.location)
    const updateType = query.updateType || 'devicesForUpdate'

    return (
        <Content
            title={translate('updateDevices')}
            toolbar={
                <UpdateDevicesToolbar
                    selectedItems={s_.selectedItems}
                    onChange={(selectedItems: object[]) => {
                        const data: any = {
                            selectedItems,
                        }
                        if (s_.task && !selectedItems.length) {
                            data.task = null
                        }
                        setState((prevState) => {
                            return { ...prevState, ...data }
                        })
                    }}
                    updateType={updateType}
                />
            }
        >
            <div className={styles.wrapper}>
                <div>
                    <Steps active={updateType} className={styles.tabs}>
                        {s_.types.map((updateItem: { id: number | string; name: string }, index: number) => (
                            <Link
                                id={updateItem.id}
                                key={`devicesUpdateTab_${index}`}
                                to={{
                                    pathname: p_.location.pathname,
                                    search: getURLSearchParamsString({ updateType: updateItem.id }),
                                }}
                                onClick={() => {
                                    if (typeof updateItem.id === 'string') {
                                        changeTab(updateItem.id)
                                    }
                                }}
                            >
                                {updateItem.name}
                            </Link>
                        ))}
                    </Steps>
                </div>
                {updateType === 'devicesForUpdate' && (
                    <div>
                        <Typography type="text" className={cn(styles.title, grid.mb_mdPlus)}>
                            {translate('updateParams')}
                        </Typography>
                        <div className={styles.settings}>
                            <div className={styles.updateDevicesUpdateDateWrapper}>
                                <EditText
                                    label={translate('updateDate')}
                                    dateValue={s_.updateNow ? null : s_.startDate.toString()}
                                    disabled={s_.updateNow}
                                    id={'startDate'}
                                    type="date"
                                    onChange={(startDate: string) => {
                                        setState((prevState) => {
                                            return { ...prevState, startDate }
                                        })
                                    }}
                                />
                            </div>
                            <SelectTime
                                time={{
                                    label: translate('updateTime'),
                                    id: 'startTime',
                                    type: 'text',
                                    mask: '99:99:99',
                                    value: s_.updateNow ? undefined : s_.startTime,
                                    disabled: s_.updateNow,
                                    mod: 'darkBlue',
                                    containerClassName: styles.updateDevicesUpdateTimeWrapper,
                                }}
                                timeOptions={timeOptions}
                                onChange={(value: string) => {
                                    if (moment(value, 'HH:mm:ss', true).isValid()) {
                                        setState((prevState) => {
                                            return { ...prevState, startTime: value, editingMode: true }
                                        })
                                    }
                                }}
                            />
                            <div className={styles.updateDevicesUpdateNowCheckboxWrapper}>
                                <Checkbox
                                    label={translate('updateNow')}
                                    className={styles.updateDevicesUpdateNowCheckbox}
                                    checked={s_.updateNow}
                                    onClick={updateNowDevice}
                                />
                            </div>
                            {s_.platforms.length > 0 && (
                                <div className={cn(grid.row, grid.justify, grid.fullWidth, grid.mt_big)}>
                                    {s_.platforms.map(
                                        (platform: { name: string; updateType: string }, index: number) => (
                                            <div className={styles.updateDevicesPlatformWrapper} key={index}>
                                                <Typography type="text">
                                                    {translate('updateTypeParamsFor')}{' '}
                                                    {getDisplayPlatformName(platform.name)}
                                                </Typography>
                                                <div>
                                                    <Radio
                                                        onClick={() => {
                                                            s_.platforms[index].updateType = 'client_app'
                                                            setState((prevState) => {
                                                                return { ...prevState, ...s_ }
                                                            })
                                                        }}
                                                        selected={platform.updateType === 'client_app'}
                                                        className={cn(grid.pt_md)}
                                                        label={translate('updateClientApplication')}
                                                    />
                                                    <Radio
                                                        onClick={() => {
                                                            s_.platforms[index].updateType = 'firmware'
                                                            setState((prevState) => {
                                                                return { ...prevState, ...s_ }
                                                            })
                                                        }}
                                                        selected={platform.updateType === 'firmware'}
                                                        className={cn(grid.pt_md)}
                                                        label={translate('firmwareUpdate')}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            )}
                            <div className={styles.updateDevicesUpdateButtonWrapper}>
                                <Button mod={'fill'} onClick={onUpdate}>
                                    {translate('update')}
                                </Button>
                            </div>
                        </div>
                        <Typography type="text" className={styles.title}>
                            {translate('selectDevicesForUpdate')}
                        </Typography>
                        <div className={cn(grid.p_mdPlus, grid.pt_md)}>
                            <Catalog
                                searchbarMod={'withShadow'}
                                key={'catalog_displays'}
                                type={'displays'}
                                filterOptions={{ hasUpdate: true }}
                                selectedItems={s_.selectedItems}
                                onSelect={(selectedItems: object[]) => {
                                    setState((prevState) => {
                                        return { ...prevState, selectedItems }
                                    })
                                }}
                            />
                        </div>
                    </div>
                )}
                {updateType === 'tasksForUpdate' && (
                    <div className={styles.tasksList}>
                        <Catalog
                            hideHeader
                            searchbarMod={'withShadow'}
                            key={'catalog_tasks'}
                            type={'tasks'}
                            selectedItems={s_.selectedItems}
                            onSelect={(selectedItems: object[]) => {
                                setState((prevState) => {
                                    return { ...prevState, selectedItems }
                                })
                            }}
                            onEditListItem={(task: { digitalSignage: object }) => {
                                setState((prevState) => {
                                    return { ...prevState, selectedItems: [task.digitalSignage], task: task }
                                })

                                let search = getURLSearchParamsString({
                                    updateType: 'devicesForUpdate',
                                })

                                changeLocation({
                                    pathname: `/${routes.updateDevices.path}`,
                                    search,
                                })
                            }}
                        />
                    </div>
                )}
            </div>
            <ScrollUp />
        </Content>
    )
}

const exportUpdateDevices = checkAvailableModule('displays/updateDevices') ? UpdateDevices : null

export default exportUpdateDevices
