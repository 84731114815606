import React from 'react'
import CatalogSelectContent from 'blocks.app/catalog/_select/_content/catalog_select_content'

import styles from 'pages/ui/ui.jcss'
import translate from 'core/translate'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { cn } from 'ethcss'

class UiSelectContent extends React.Component {
    state = {
        selectedMedia: null,
        selectedArchive: null,
    }
    render() {
        const s_ = this.state

        return (
            <div className={styles.blockPanel}>
                <div className={styles.title}>{translate('selectContent')}</div>
                <div className={cn(styles.content, grid.normalCenter)}>
                    <div className={cn(grid.w20, item.rel)}>
                        <CatalogSelectContent
                            type={'media'}
                            selectedInfo={s_.selectedMedia}
                            onSelect={(selectedMedia) => {
                                this.setState({ selectedMedia })
                            }}
                        />
                    </div>
                    <div className={grid.pl_mini} />
                    <div className={grid.space}>
                        <CatalogSelectContent
                            type={'archive'}
                            selectedInfo={s_.selectedArchive}
                            onSelect={(selectedArchive) => {
                                this.setState({ selectedArchive })
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default UiSelectContent
