import * as React from 'react'
import {
    IAppMenuComponent,
    IAppMenuComponentConnected,
    IAppMenuComponentDispatchProps,
    IAppMenuComponentStateProps,
} from './AppMenu-types'
import Icon from 'blocks.simple/icon/icon'
import User from 'blocks.app/user/user'
import { cn } from 'ethcss'
import { styles } from './AppMenu-styles'
import { IRoute, IRoutesPermissions } from 'features/routes/routes-types'
import { history, routes } from 'features/routes'
import helpers, { IRecordWithFunctions, IRecordWithStrings } from 'core/helpers'
import translate from 'core/translate'
import { getURLSearchParamsByLocation, getURLSearchParamsString } from 'features/routes/utils'
import { CustomScroll } from 'atoms/CustomScroll'
import Animation from 'blocks.simple/animation/animation'
import enhanceWithClickOutside from 'react-click-outside'
import { IMenuItem } from 'core/models/Menu'
import { AppMenuItem } from './AppMenuItem'
import { AppSubMenu } from './AppSubMenu'
import { connect } from 'react-redux'
import { changeLocation } from 'features/routes'
import { appActions, getMenu, getTimezonesList, getNotificationsCount } from '../app.state'
import { downloadsActions } from 'core/services/DownloadService/DownloadService.state'
import { filesActions } from 'pages/files/files.state'
import { broadcastsActions } from '../../../pages/broadcasts/broadcasts.state'
import { stringToNumber } from 'core/utils/convertUtil'
import { openApprove } from 'pages/displays/displays.state'
import { logout } from 'blocks.app/user/user.state'
import { isNotEmptyArray } from 'core/utils/coreUtil'
import { IRole } from 'core/models/Roles'
import { IRootState } from 'core/store/rootReducer'
import { actionStyles } from './AppMenuActions-styles'
import { isHorizontalMenu } from 'core/helpers/menu'
import Link from 'blocks.simple/link/link'
import { ScrollableMenuView } from '../../../organisms/ScrollableMenu/ScrollableMenu-view'
import store from 'core/store'
import { api } from 'core/api/ConnectionManager'

class AppMenuComponent extends React.Component<IAppMenuComponentConnected, any> {
    private minimizeIconSize = 16
    listenersId = []

    componentDidMount() {
        const { lang, getAppMenu, getAppTimezonesList, getNotificationsCount } = this.props
        getAppTimezonesList(lang)
        getAppMenu()
        getNotificationsCount()

        api.addObserver(
            'notificationsCountUpdated',
            () => {
                getNotificationsCount()
            },
            this.listenersId
        )
    }

    componentWillUnmount() {
        this.listenersId.forEach((id) => api.removeObserver(id))
    }

    getLabel(textKey: string) {
        switch (textKey) {
            case 'addFillersAdBlock':
            case 'addAdvertisingCampaign':
            case 'addAdvertisingAds':
                return translate('create')
            case 'advertisingBlocks':
                return translate('view')
            default:
                return translate(textKey)
        }
    }

    getActions(query: IRecordWithStrings): IRecordWithFunctions {
        const {
            toggleDownloadsModal,
            createNewDirectory,
            openAddFileModal,
            openApprove,
            logout,
            openAddBroadcastFileModal,
        } = this.props

        return {
            addDisplay: openApprove,
            addGroup: () => {
                const query = getURLSearchParamsByLocation(history.location)
                const parentId = query.parentId
                const group = parentId ? { parentId: stringToNumber(parentId) } : {}

                createNewDirectory({
                    title: translate('groupCreation'),
                    methodName: 'createGroup',
                    fieldParamKey: 'name',
                    inputFieldName: translate('groupName'),
                    placeholder: translate('enterGroupName'),
                    options: group,
                })
            },
            addAdvancedBroadcast: () => {
                const queryString = getURLSearchParamsString({
                    broadcastFolderId: query.folderId,
                })

                changeLocation({
                    pathname: `/${routes.addAdvancedBroadcast.path}`,
                    search: queryString,
                })
            },
            uploadBroadcast: () => openAddBroadcastFileModal(true),
            addBroadcastFolder: () => {
                const query = getURLSearchParamsByLocation(history.location)
                const folderId = query.folderId
                const folder = folderId ? { folderId: stringToNumber(folderId) } : {}

                createNewDirectory({
                    title: translate('createFolder'),
                    methodName: 'createBroadcastFolder',
                    fieldParamKey: 'title',
                    inputFieldName: translate('folderName'),
                    placeholder: translate('enterFolderName'),
                    options: folder,
                })
            },
            logout,
            createFolder: () => {
                const query = getURLSearchParamsByLocation(history.location)
                const folderId = query.folderId
                const folder = folderId ? { folderId: stringToNumber(folderId) } : {}

                createNewDirectory({
                    title: translate('createFolder'),
                    methodName: 'createFolder',
                    fieldParamKey: 'name',
                    inputFieldName: translate('folderName'),
                    placeholder: translate('enterFolderName'),
                    options: folder,
                })
            },
            upload: () => openAddFileModal(),
            globalProgress: () => toggleDownloadsModal(),
        }
    }

    handleClickOutside = () => {
        const { minimize, setActiveMenuItem } = this.props

        if (minimize) {
            setActiveMenuItem(null)
        }
    }

    onIconClick = () => {
        const { minimize, setActiveMenuItem, toggleMenuMinimized } = this.props

        setActiveMenuItem(null)
        toggleMenuMinimized(!minimize)
    }

    onCloseSubMenu = () => {
        const { setActiveMenuItem } = this.props

        setActiveMenuItem(null)
    }

    isAvailablePermissions(action: string, permissions?: IRoutesPermissions) {
        switch (action) {
            case 'addContentToDevice':
                return helpers.isAvailable({ key: 'files', action: 'read' })
            case 'logout':
                return true
            default:
                return permissions ? helpers.isAvailable(permissions) : false
        }
    }

    renderSubMenu(item: IMenuItem, isActive: boolean, routeItem: IRoute | null) {
        const { minimize, active, activeMenuItem, pathname, query } = this.props
        const actions = this.getActions(query)
        const isDropdownOpen = activeMenuItem && activeMenuItem.menuId === item.action
        const horizontalMenu = isHorizontalMenu()

        return minimize ? (
            <div
                className={cn(styles.menu__subMenu_type_dropdown, {
                    [styles.menu__subMenu_status_active]: isDropdownOpen,
                })}
            >
                <div className={styles.subMenu__content}>
                    {item.children && item.children.length > 0 && (
                        <AppSubMenu
                            pathname={pathname}
                            query={query}
                            actions={actions}
                            active={active}
                            routeItem={routeItem}
                            minimize={minimize}
                            item={item}
                        />
                    )}
                </div>
            </div>
        ) : (
            <Animation className={cn(horizontalMenu ? '' : styles.menu__subMenu)}>
                {item.children && item.children.length > 0 && (
                    <AppSubMenu
                        pathname={pathname}
                        query={query}
                        actions={actions}
                        active={active}
                        routeItem={routeItem}
                        minimize={minimize}
                        item={item}
                        className={cn(
                            styles.menu__subMenu,
                            horizontalMenu ? actionStyles.submenuHover : '',
                            isActive && item.action === active
                                ? styles.menu__subMenu_type_open
                                : !horizontalMenu && styles.menu__subMenu_type_close
                        )}
                        isHorizontal={horizontalMenu}
                    />
                )}
            </Animation>
        )
    }

    renderMenuItem(item: IMenuItem, itemIndex: number, notificationsCount?: null | number) {
        const { query, minimize, isOpenDownloadsModal, pathname, active, activeMenuItem } = this.props
        const label = this.getLabel(item.name)
        const routeItem = routes[item.action]
        const actions = this.getActions(query)
        const action = actions[item.action]

        switch (item.name) {
            case 'logout':
            case 'globalProgress':
            case 'settings':
            case 'licenses':
            case 'usersActionsHistory':
            case 'distributive':
            case 'users':
                if (isHorizontalMenu()) {
                    return null
                }
                break
            default:
                break
        }

        if (item.name === 'globalProgress') {
            const isActive = isOpenDownloadsModal

            return (
                <AppMenuItem
                    key={`menu__item_${itemIndex}`}
                    pathname={pathname}
                    active={active}
                    action={action}
                    minimize={minimize}
                    activeMenuItem={activeMenuItem}
                    routeItem={null}
                    routePath={null}
                    query={query}
                    item={item}
                    label={label}
                    isActive={isActive}
                    isClickable={true}
                    isAnimateIcon={false}
                >
                    {this.renderSubMenu(item, isActive, null)}
                </AppMenuItem>
            )
        }

        if (item.name === 'documentation') {
            return (
                <AppMenuItem
                    key={`menu__item_${itemIndex}`}
                    pathname={pathname}
                    active={active}
                    action={action}
                    minimize={minimize}
                    activeMenuItem={activeMenuItem}
                    routeItem={null}
                    routePath={'https://wiki.smartplayer.org/index.php/SmartPlayer'}
                    query={query}
                    item={item}
                    label={label}
                    isActive={false}
                    isClickable={true}
                    isAnimateIcon={false}
                    isExternal={true}
                >
                    {this.renderSubMenu(item, false, null)}
                </AppMenuItem>
            )
        }

        if (routeItem && this.isAvailablePermissions(item.action, routeItem.permissions)) {
            const isActive = routeItem.path === active
            const isClickable = !!(routeItem.path !== pathname || Object.keys(query).length)
            const routePath = `/${routeItem.path}`

            return (
                <AppMenuItem
                    key={`menu__item_${itemIndex}`}
                    pathname={pathname}
                    active={active}
                    action={action}
                    minimize={minimize}
                    routeItem={routeItem}
                    routePath={routePath}
                    activeMenuItem={activeMenuItem}
                    query={query}
                    item={item}
                    label={label}
                    isActive={isActive}
                    isClickable={isClickable}
                    isAnimateIcon={false}
                    notificationsCount={notificationsCount}
                >
                    {this.renderSubMenu(item, isActive, routeItem)}
                </AppMenuItem>
            )
        }
        return null
    }

    renderMenuItemIcons(item: IMenuItem, itemIndex: number) {
        const { query, minimize, isOpenDownloadsModal, pathname, active, activeMenuItem } = this.props
        const label = this.getLabel(item.name)
        const routeItem = routes[item.action]
        const actions = this.getActions(query)
        const action = actions[item.action]
        const horizontalMenu = isHorizontalMenu()

        switch (item.name) {
            case 'logout':
            case 'settings':
            case 'licenses':
            case 'usersActionsHistory':
            case 'distributive':
            case 'users':
                if (this.isAvailablePermissions(item.action, routeItem.permissions) && horizontalMenu) {
                    const isActive = routeItem.path === active
                    const isClickable = !!(routeItem.path !== pathname || Object.keys(query).length)
                    const routePath = `/${routeItem.path}`
                    return (
                        <AppMenuItem
                            key={`menu__item_${itemIndex}`}
                            pathname={pathname}
                            active={active}
                            action={action}
                            minimize={minimize}
                            routeItem={routeItem}
                            routePath={routePath}
                            activeMenuItem={activeMenuItem}
                            query={query}
                            item={item}
                            label={label}
                            isActive={isActive}
                            isClickable={isClickable}
                            isAnimateIcon={false}
                        >
                            {this.renderSubMenu(item, isActive, routeItem)}
                        </AppMenuItem>
                    )
                }
                break
            default:
                break
        }

        if (item.name === 'globalProgress' && horizontalMenu) {
            const isActive = isOpenDownloadsModal
            return (
                <AppMenuItem
                    key={`menu__item_${itemIndex}`}
                    pathname={pathname}
                    active={active}
                    action={action}
                    minimize={minimize}
                    activeMenuItem={activeMenuItem}
                    routeItem={null}
                    routePath={null}
                    query={query}
                    item={item}
                    label={label}
                    isActive={isActive}
                    isClickable={true}
                    isAnimateIcon={false}
                >
                    {this.renderSubMenu(item, isActive, routeItem)}
                </AppMenuItem>
            )
        }
        return null
    }

    renderUser = () => {
        const { user, minimize } = this.props
        const { name, email, roles } = user

        const userRole = roles && isNotEmptyArray<IRole>(roles) ? roles[0].label : null
        const userEmail = name || email

        return (
            <User
                email={userEmail}
                editableAvatar={!minimize}
                switchAccount
                onCloseSubMenu={this.onCloseSubMenu}
                role={userRole}
                minimize={minimize}
                photo={user.photo}
            />
        )
    }

    render() {
        const { isShowMobMenu, minimize, menu, notificationsCount } = this.props
        const whiteLabelChecked = store.getState().app.whiteLabelChecked

        return (
            <>
                {!isHorizontalMenu() ? (
                    <div
                        id={'main-menu'}
                        className={cn(styles.menu, {
                            [styles.menu_type_open]: isShowMobMenu,
                            [styles.menu_type_mini]: minimize,
                        })}
                    >
                        <CustomScroll classNames={styles.menu__scroll}>
                            <div className={styles.menu__content}>
                                <div
                                    className={cn(styles.menu__info, {
                                        [styles.menu__info_type_minimize]: minimize,
                                        [styles.menu__info_type_default]: !minimize,
                                    })}
                                >
                                    <div />
                                    <div
                                        className={styles.menu__iconWrapper}
                                        style={{ right: -(this.minimizeIconSize / 2) }}
                                    >
                                        <Icon
                                            size={this.minimizeIconSize}
                                            type={minimize ? 'double_arrow_right' : 'double_arrow_left'}
                                            containerClass={cn(styles.menu__icon)}
                                            onClick={this.onIconClick}
                                        />
                                    </div>
                                </div>
                                {this.renderUser()}
                                <div>
                                    {menu.map((menuCategory, categoryIndex) => (
                                        <div key={`menu__category_${categoryIndex}`} className={styles.menu__category}>
                                            {menuCategory.menu.map((item, itemIndex) => {
                                                return this.renderMenuItem(item, itemIndex, notificationsCount)
                                            })}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </CustomScroll>
                    </div>
                ) : (
                    <>
                        <div
                            id={'main-menu'}
                            className={cn(styles.menu_x, {
                                [styles.menu_type_open]: isShowMobMenu,
                                [styles.menu_type_mini]: minimize,
                            })}
                        >
                            <div className={styles.menu__content_x}>
                                <div className={styles.iconBackground}>
                                    <Link to={'/dashboard'}>
                                        {whiteLabelChecked && <img src={window.spconfig.auth.menuLogo} />}
                                    </Link>
                                </div>
                                <ScrollableMenuView>
                                    {menu.map((menuCategory, categoryIndex) => (
                                        <div
                                            key={`menu__category_${categoryIndex}`}
                                            className={cn(styles.menu__category_x)}
                                        >
                                            {menuCategory.menu.map((item, itemIndex) => {
                                                return this.renderMenuItem(item, itemIndex)
                                            })}
                                        </div>
                                    ))}
                                </ScrollableMenuView>
                            </div>

                            <div className={styles.menu__fixed_icons_x}>
                                {menu.map((menuCategory, categoryIndex) => (
                                    <div
                                        key={`menu__category_${categoryIndex}`}
                                        className={cn(styles.menu__category_x)}
                                    >
                                        {menuCategory.menu.map((item, itemIndex) => {
                                            return this.renderMenuItemIcons(item, itemIndex)
                                        })}
                                    </div>
                                ))}
                                {this.renderUser()}
                            </div>
                        </div>
                    </>
                )}
            </>
        )
    }
}

const mapStateToProps = (state: IRootState): IAppMenuComponentStateProps => ({
    isOpenDownloadsModal: state.downloads.isOpenModal,
    notificationsCount: state.app.notificationsCount,
})

const mapDispatchToProps: IAppMenuComponentDispatchProps = {
    getAppMenu: () => getMenu(),
    getNotificationsCount: () => getNotificationsCount(),
    getAppTimezonesList: (locale: string) => getTimezonesList(locale),
    toggleMenuMinimized: (status: boolean) => appActions.toggleMenuMinimized(status),
    setActiveMenuItem: (menuItem: any) => appActions.setActiveMenuItem(menuItem),
    toggleDownloadsModal: () => downloadsActions.toggleModal(),
    createNewDirectory: (data) => appActions.setCreateDirectoryWindow(data),
    openAddFileModal: () => filesActions.openAddFileModal(),
    openAddBroadcastFileModal: (value: boolean) => broadcastsActions.openAddFileModal(value),
    openApprove: (code: any) => openApprove(code),
    addNewGroup: (data) => appActions.setCreateDirectoryWindow(data),
    logout: () => logout({ isSwitchAccount: false }),
}

export const AppMenu = connect<
    IAppMenuComponentStateProps,
    IAppMenuComponentDispatchProps,
    IAppMenuComponent,
    IRootState
>(
    mapStateToProps,
    mapDispatchToProps
)(enhanceWithClickOutside(AppMenuComponent))
