import { addStyles } from 'ethcss'
import { grid, indents, IStyles, layers, mediaCSS } from 'theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'

export const styles: IStyles = {
    searchableYandexMap: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    searchableYandexMap__actionBar: {
        position: 'absolute',
        ...grid.flex,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.w50,
        [mediaCSS.tabMini]: {
            width: `calc(100% - ${indents.md * 2}px)`,
        },
        left: indents.md,
        top: indents.md,
        right: indents.md,
        zIndex: layers.secondary,
    },
    searchableYandexMap__searchBar: {
        position: 'relative',
        ...grid.space,
    },
    searchableYandexMap__searchBar_type_enable: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    searchableYandexMap__mapLocationList: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: '100%',
        zIndex: layers.secondary,
    },
    searchableYandexMap__button: {
        paddingLeft: indents.md,
    },
}

addStyles(styles)
