import { colors } from 'theme'

export const defaultThemeStyles = {
    text: colors.white,
    col1: colors.blackLight,
    col2: colors.greyEditor2,
    col3: colors.greyEditor,
    setting: colors.grey,
    settingActive: colors.white,
    sampleBackground: colors.grey,
}
