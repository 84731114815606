import { addStyles } from 'ethcss'
import { grid, IStyles, item } from '../../../theme'
import { defaultThemeStyles } from 'blocks.app/sidebar/Sidebar-theme'

const updateDevicesToolbarStyles: IStyles = {
    menuItem: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.center,
        ...grid.p_mini,
        cursor: 'pointer',
        position: 'relative',
        color: `var(--var-module-sidebar-component-sidebar-style-menuText, ${defaultThemeStyles.menuText})`,
        ...item.transitionErase,
        '&:hover': {
            color: `var(--var-module-sidebar-component-sidebar-style-menuTextHover, ${defaultThemeStyles.menuTextHover})`,
        },
    },
}

addStyles(updateDevicesToolbarStyles)
export default updateDevicesToolbarStyles
