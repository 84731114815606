import { addStyles } from 'ethcss'
import { IStyles, mediaCSS, grid, item, colors } from 'theme'

const NotificationEmails: IStyles = {
    notificationList: {},
    deleteConfirm: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0, 0.7)',
        zIndex: 10000,
    },
    deleteConfirmInner: {
        backgroundColor: colors.white,
        borderRadius: 20,
        padding: 20,
    },
    deleteConfirmMessage: {
        marginBottom: 24,
        textAlign: 'center',
    },
    deleteConfirmButtons: {
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        margin: '0 6px',
    },
    header: {
        display: 'flex',
        padding: 10,
        marginBottom: 20,
    },
    deleteAll: {
        marginLeft: 14,
    },
    modal: {
        height: '100%',
    },
    modalStatus: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: colors.greyLight,
        padding: 40,

        img: {
            marginRight: 10,
        },

        [mediaCSS.tabMini]: {
            padding: '30px 10px',
        },
    },
    modalContent: {
        padding: '20px 40px 70px 40px',
        height: 'calc(100% - 94px)',

        [mediaCSS.tabMini]: {
            padding: 10,
        },
    },
    modalField: {
        marginBottom: 6,
    },
    modalBody: {
        marginTop: 20,
        height: ' calc(100% - 100px)',
        overflow: 'auto',
    },
}

addStyles(NotificationEmails)
export default NotificationEmails
