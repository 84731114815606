import React, { useEffect, useRef, useState } from 'react'
import DashboardMemoryMethods from './dashboard__memory.local.methods'
import DashboardUnusedContent from '../__unusedContent/dashboard__unusedContent'
import PreviewAndHistoryModals from '../__previewAndHistoryModals/dashboard__previewAndHistoryModals'
import Card from 'blocks.simple/card/card'
import Animation from 'blocks.simple/animation/animation'
import Dotdotdot from 'react-dotdotdot'
import translate from 'core/translate'
import filesize from 'filesize'
import helpers from 'core/helpers'
import { useModal } from 'core/hooks'
import styles from './dashboard__memory.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { Typography } from 'atoms/Typography'
import { RootStateOrAny, useSelector } from 'react-redux'
//@ts-ignore
import HSBar from 'react-horizontal-stacked-bar-chart'
import { cn } from 'ethcss'

const getColor = (label: string) => {
    switch (label) {
        case 'free':
            return '#FFFFFF'
        case 'sources':
            return '#2582E0'
        case 'digitalSignageAndGroupMedia':
            return '#97d1df'
        case 'other':
            return '#33c691'
        case 'reserved':
            return '#b3b3b3'
        case 'webAppPreviewSize':
            return '#2FC58A'
        case 'screenshotSize':
            return '#2EA96E'
        case 'statisticSize':
            return '#336C56'
        default:
            return '#FFFFFF'
    }
}

const getSecondaryMemoryParams = (memory: any) => {
    const secondaryParams = ['free', 'webAppPreviewSize', 'screenshotSize', 'statisticSize']
    return memory.filter((item: any) => secondaryParams.includes(item.origLabel))

    // return filterMemory.map((item: any) => {
    //     return (
    //         <div className={styles.detailInfoItem}>
    //             {item.label} - {filesize(item.value)}
    //         </div>
    //     )
    // })
}

function DashboardMemory(p_: any) {
    const { s_ } = DashboardMemoryMethods()
    const modal = useModal()
    const delHistoryModal = useModal()
    const delScreenshotHistory = useModal()
    const itemRules = p_.rules ? p_.rules : 0
    const memoryRef = useRef<any>(null)
    const [shortMode, setShortMode] = useState(false)
    const secondaryParams = getSecondaryMemoryParams(s_.data.memory)
    const appType = useSelector<RootStateOrAny>((state) => state.app.appType)
    const isServerLocal = () => appType === 'personal'
    const getTitle = () => translate('storageUsageStatistics')

    const getMainMemoryParams = (memory: any) => {
        const mainParams = isServerLocal()
            ? ['sources', 'digitalSignageAndGroupMedia', 'other', 'reserved']
            : ['sources', 'digitalSignageAndGroupMedia', 'other', 'reserved', 'free']

        return memory.filter((item: any) => mainParams.includes(item.origLabel))
    }
    const getMainMemoryChartParams = (memory: any) => {
        const mainParams = ['sources', 'digitalSignageAndGroupMedia', 'other', 'reserved', 'free']

        return memory.filter((item: any) => mainParams.includes(item.origLabel) && item.value > 0)
    }

    const changeShortMode = () => {
        if (memoryRef.current) {
            const width = memoryRef.current.offsetWidth

            if (width < 520) {
                setShortMode(true)

                return
            }

            setShortMode(false)
        }
    }

    useEffect(() => {
        changeShortMode()
    })

    useEffect(() => {
        window.addEventListener('resize', changeShortMode)

        return () => window.removeEventListener('resize', changeShortMode)
    }, [])

    function renderCard() {
        if (!s_.data.isLoaded) {
            return null
        }

        return (
            <Card
                className={grid.col}
                title={getTitle()}
                titleClassName={styles.title}
                icon={{
                    type: 'web_server',
                    size: 20,
                    default: true,
                }}
            >
                <div ref={memoryRef} className={styles.wrapper}>
                    <div className={cn(styles.memoryInfo, shortMode ? styles.shortInfo : '')}>
                        {isServerLocal() ? (
                            <div className={cn(styles.chartInfoLocal)}>
                                <div className={cn(styles.chartCol)}>
                                    {getMainMemoryParams(s_.data.memory).map((item: any, index: number) => (
                                        <Typography
                                            key={index}
                                            type="title"
                                            className={item.origLabel !== 'other' ? styles.legend : styles.legendOther}
                                        >
                                            <div
                                                className={cn(
                                                    styles.mark,
                                                    item.origLabel === 'free' ? styles.accentBorder : ''
                                                )}
                                                style={{ background: getColor(item.origLabel) }}
                                            />
                                            <Dotdotdot clamp={3} className={styles.spaceInfoText}>
                                                {item.label} - {filesize(item.value)}
                                            </Dotdotdot>
                                        </Typography>
                                    ))}
                                </div>
                                <div className={cn(styles.chartCol)}>
                                    {getSecondaryMemoryParams(s_.data.memory).map((item: any, index: number) => (
                                        <Typography
                                            key={index}
                                            type="title"
                                            className={
                                                item.origLabel === 'screenshotSize'
                                                    ? styles.legendOther
                                                    : item.origLabel === 'free'
                                                    ? styles.legendOther
                                                    : styles.legend
                                            }
                                        >
                                            <div
                                                className={cn(
                                                    styles.mark,
                                                    item.origLabel === 'free' ? styles.accentBorder : ''
                                                )}
                                                style={{ background: getColor(item.origLabel) }}
                                            />
                                            <Dotdotdot clamp={3} className={styles.spaceInfoText}>
                                                {item.label} - {filesize(item.value)}
                                            </Dotdotdot>
                                        </Typography>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className={cn(styles.chartInfo)}>
                                {getMainMemoryParams(s_.data.memory).map((item: any, index: number) => (
                                    <Typography key={index} type="title" className={styles.legend}>
                                        <div
                                            className={cn(
                                                styles.mark,
                                                item.origLabel === 'free' ? styles.accentBorder : ''
                                            )}
                                            style={{ background: getColor(item.origLabel) }}
                                        />
                                        <Dotdotdot clamp={3} className={styles.spaceInfoText}>
                                            {item.label} - {filesize(item.value)}
                                        </Dotdotdot>
                                    </Typography>
                                ))}
                            </div>
                        )}
                        <div className={styles.gap} />
                        <div className={styles.chart}>
                            <div className={styles.memoryTotal}>
                                <div className={cn(styles.memoryBlock, styles.accentColor)}>
                                    <div className={styles.memoryLabel}>{translate('used')}</div>
                                    <div className={styles.memoryValue}>{filesize(s_.data.usageDiskSpace)}</div>
                                </div>
                                <div className={styles.memoryBlock}>
                                    <div className={styles.memoryLabel}>{translate('free')}</div>
                                    <div className={styles.memoryValue}>
                                        {filesize(s_.data.totalDiskSpace - s_.data.usageDiskSpace)}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.chartWrapper}>
                                <HSBar
                                    height={58}
                                    data={getMainMemoryChartParams(s_.data.memory).map((item: any) => {
                                        return {
                                            value: item.value === 0 ? 0.1 : item.value,
                                            color: getColor(item.origLabel),
                                        }
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    {/* {!shortMode && secondaryParams.length ? (
                        <div className={styles.detailInfo}>
                            <Typography type="title" className={styles.mediaInfo}>
                                <span>Подробная информация</span>
                            </Typography>
                            <div className={styles.detailInfoList}>{secondaryParams}</div>
                        </div>
                    ) : null} */}
                    {isServerLocal() && (
                        <div className={styles.removeContentBlock}>
                            <Typography type="title" className={styles.link}>
                                <span onClick={delScreenshotHistory.toggleModal}>
                                    {translate('deleteWidgetPreviewFiles')}
                                </span>
                            </Typography>
                            <Typography type="title" className={styles.link}>
                                <span onClick={delHistoryModal.toggleModal}>
                                    {translate('deleteScreenshotHistory')}
                                </span>
                            </Typography>
                            {helpers.isAvailable({ key: 'files', action: 'delete' }) && (
                                <Typography type="title" className={styles.link}>
                                    <span onClick={modal.toggleModal}>{translate('removeUnusedContent')}</span>
                                </Typography>
                            )}
                        </div>
                    )}
                </div>
            </Card>
        )
    }

    return (
        <React.Fragment>
            <Animation className={cn(p_.className)} style={{ order: itemRules.order }}>
                {renderCard()}
            </Animation>
            <DashboardUnusedContent open={modal.value} onClose={modal.toggleModal} />
            <PreviewAndHistoryModals
                type={'delHistoryModal'}
                open={delHistoryModal.value}
                onClose={delHistoryModal.toggleModal}
            />
            <PreviewAndHistoryModals
                type={'delScreenshotHistory'}
                open={delScreenshotHistory.value}
                onClose={delScreenshotHistory.toggleModal}
            />
        </React.Fragment>
    )
}

export default React.memo(DashboardMemory)
