import React, { useState } from 'react'
import moment from 'moment'
import Dotdotdot from 'react-dotdotdot'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import DropMenu from 'blocks.simple/dropMenu/dropMenu'
import Button from 'blocks.simple/button/button'
import { cn } from 'ethcss'
import styles from './advertisingCampaigns__toolbarContent.jcss'
import { grid, item } from 'theme'
import { text as textStyles } from 'theme'
import translate from 'core/translate'
import helpers from 'core/helpers'
import { routes } from 'features/routes'
import { getURLSearchParamsString } from 'features/routes/utils'
import { changeLocation } from 'features/routes'
import { api } from '../../../core/api/ConnectionManager'
import { checkRoutePathName } from 'core/helpers/routes/routes'

const AdvertisingCampaignsToolbarContent = (p_: {
    selectedInfo: any
    onChange: (selectedItems: any) => void
    onCloseInfo: () => void
    selectedItems: any
}) => {
    let isPlayableDevices: any = false
    const [active, setActive] = useState([])
    const [selected, setSelected] = useState(null)
    const [schedule, setSchedule] = useState([])

    const deleteAdvertisingCampaign = (id: number) => {
        api.send(checkRoutePathName('/advertisingAds') ? 'deleteAdvertisement' : 'deleteAdvertisingCampaign', { id })
    }

    if (p_.selectedInfo) {
        isPlayableDevices =
            moment().isBefore(p_.selectedInfo.endDate) &&
            moment().isAfter(p_.selectedInfo.startDate) &&
            p_.selectedInfo.digitalSignage
    }

    return (
        <div className={styles.wrapper}>
            {p_.selectedInfo && (
                <div className={cn(styles.wrapper, styles.toolbarWrapper)}>
                    {p_.selectedInfo.title && (
                        <div className={grid.row}>
                            <div className={cn(styles.name, item.ellipsisWrapper)}>
                                <div className={item.ellipsis}>{p_.selectedInfo.title}</div>
                            </div>
                        </div>
                    )}
                    {p_.selectedInfo.description && (
                        <div className={cn(styles.itemDescription, textStyles.wordWrap)}>
                            <Dotdotdot clamp={2}>{p_.selectedInfo.description}</Dotdotdot>
                        </div>
                    )}
                    <Rectangle
                        src={p_.selectedInfo.cover.src}
                        contain={p_.selectedInfo.cover.isIcon}
                        className={cn(grid.col, styles.viewInfo)}
                        width={16}
                        height={9}
                    >
                        <div className={grid.space} />
                        <DropMenu
                            active={active}
                            className={cn(item.blackOpacity)}
                            mod="centered"
                            onChange={(active: any) => {
                                setActive(active)
                            }}
                            onClick={(clicked: string) => {
                                switch (clicked) {
                                    case 'edit':
                                        changeLocation({
                                            pathname: `/${routes.editAdvertisingCampaign.path}`,
                                            search: getURLSearchParamsString({
                                                advId: p_.selectedInfo.id,
                                            }),
                                        })
                                        break
                                    default:
                                        break
                                }
                            }}
                            items={[
                                {
                                    id: 'edit',
                                    icon: 'pencil',
                                    tooltip: {
                                        title: translate('edit'),
                                    },
                                    show: helpers.isAvailableAction(
                                        {
                                            name: 'advertisingCampaign',
                                            action: 'update',
                                            entity: p_.selectedInfo,
                                        },
                                        {}
                                    ),
                                },
                                {
                                    id: 'delete',
                                    icon: 'delete',
                                    tooltip: {
                                        title: translate('delete'),
                                    },
                                    show: true,
                                    // helpers.isAvailableAction(
                                    //     {
                                    //         name: 'advertisingCampaign',
                                    //         action: 'delete',
                                    //         entity: p_.selectedInfo,
                                    //     },
                                    //     {}
                                    // )
                                    children: (
                                        <form
                                            onSubmit={(e) => {
                                                e.preventDefault()
                                                setActive([])
                                                deleteAdvertisingCampaign(p_.selectedInfo.id)
                                            }}
                                            className={cn(grid.colCenter, grid.p_md, styles.advDeleteWarning)}
                                        >
                                            <div className={cn(grid.w100, grid.mb_md, textStyles.center)}>
                                                {translate('advDeleteWarning')}?
                                            </div>
                                            <div className={cn(grid.row, grid.justify, grid.mb_mini)}>
                                                <Button
                                                    indentSize="mini"
                                                    type="submit"
                                                    className={cn(grid.mr_mini)}
                                                    mod="fill"
                                                >
                                                    {translate('yes')}
                                                </Button>
                                                <Button indentSize="mini" onClick={() => setActive([])}>
                                                    {translate('no')}
                                                </Button>
                                            </div>
                                        </form>
                                    ),
                                },
                            ]}
                        />
                    </Rectangle>
                    {isPlayableDevices && (
                        <div>
                            <div className={styles.devicesListTitle}>{translate('playableOnDevices')}:</div>
                            {p_.selectedInfo.digitalSignage.map((digitalSignage: { name: string }, index: number) => (
                                <div key={`digitalSignageList_${index}`} className={styles.deviceListItem}>
                                    <div className={item.ellipsisWrapper}>
                                        <div className={item.ellipsis}>{digitalSignage.name}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    <div className={grid.space} />
                    <div className={cn(grid.p_md, grid.rowCenter, styles.closeBtn)}>
                        <Button mod={'withBorder'} onClick={p_.onCloseInfo} indentSize={'mini'} rounded={'full_normal'}>
                            {translate('close')}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AdvertisingCampaignsToolbarContent
