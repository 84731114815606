import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { textSizes } from 'theme/media'
import { IStyles } from '../../../theme'

const deviceSettingsCoverStyles: IStyles = {
    wrapper: {
        ...grid.row,
        ...grid.fullWidth,
        ...grid.mt_mdPlus,
    },
    title: {
        fontSize: textSizes.normal,
        ...grid.mb_md,
    },
    checkbox: {
        ...grid.row,
        ...grid.fullWidth,
    },
}

addStyles(deviceSettingsCoverStyles)
export default deviceSettingsCoverStyles
