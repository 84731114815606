import { addStyles } from 'ethcss'
import { grid, item, IStyles } from 'theme'
import { colors } from 'theme'
import { textSizes } from 'theme/media'

const notificationStyles: IStyles = {
    notification: {
        ...grid.rowCenter,
        ...grid.normalCenter,
        ...grid.p_mini,
        fontSize: textSizes.normal,
        ...item.transition,
        ...item.fixed,
        color: colors.white,
        left: 0,
        right: 0,
    },
}

addStyles(notificationStyles)
export default notificationStyles
