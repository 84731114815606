import React from 'react'
import translate from 'core/translate'
import { api } from 'core/api/ConnectionManager'
import { isExist, isNotEmptyArray } from 'core/utils/coreUtil'
import { MapCreator } from 'features/map/controllers/MapCreator'
import { SetPartialData } from '../__addGroup/displays__addGroup.local.methods'
import { licenseType, rateTypeRename } from 'core/utils/displayUtil'
import store from 'core/store'
import helpersDisplays from 'core/helpers/_digitalSignages'

const mapFactory = MapCreator.getInstance().getMapFactory()
const mapApi = mapFactory.createMapApi()

interface IAddVirtualDeviceProps {
    groups: { [index: string]: string; nestedKey: string }[]
    currentGroup?: { id: number }
    user?: any
    data?: { parentId: number | string; tags: { label: string }[] }
    parentTimezone?: {
        timezone: number
    }
    setPartialData?: (data: SetPartialData) => void
    emitError?: (error: string) => void
    closeModal?: () => void
    lat?: number
    lng?: number
    currentLicense?: {
        activeQ: boolean
        expiredAt: null | string
        id: number
        name: string
        paidQ: boolean
        vacantCount: number
    } | null
    licensesData?: {
        activeQ: boolean
        startedQ: boolean
        deletedQ: boolean
        notExpiredQ: boolean
        devicesCount: number
        licenseId: number
        companyId: number
        paidQ: boolean
        startedAt: string
        expiredAt: null | string
        manager: string
        comment: string
        createdAt: string
        deletedAt: null | string
        vacantCount: number
        licenseType: string
        rateType: null | string
    }[]
    timezones?: string[]
    tagsList?: string[]
    label?: any
    tabid: string
}
type Data = {
    name?: string
    nullTimezoneError?: string | null
    licensesTypeError?: boolean | null
    error?: string | null
    address?: string
    tags?: { label: string }[]
    groupScheduleMode?: string
    groupId?: number
    lat?: number
    lng?: number
    timezone?: string
    description?: string
    orientation?: string
    licenseId?: any
}
interface IState {
    validateSymbolNumber: boolean
    active: boolean
    mapCenter: { lat: number; lng: number } | null
    initialAddress: string
    data: Data
    groupTimezone: boolean
    parentTimezone: null
    licensesData: {
        activeQ: boolean
        startedQ: boolean
        deletedQ: boolean
        notExpiredQ: boolean
        devicesCount: number
        licenseId: number
        companyId: number
        paidQ: boolean
        startedAt: string
        expiredAt: null | string
        manager: string
        comment: string
        createdAt: string
        deletedAt: null | string
        vacantCount: number
        licenseType: string
        rateType: null | string
    }[]
    currentLicense: {
        activeQ: boolean
        expiredAt: null | string
        id: number
        name: string
        paidQ: boolean
        vacantCount: number
    } | null
}

class DisplaysAddVirtualDeviceMethods extends React.Component<IAddVirtualDeviceProps> {
    state: IState = {
        active: false,
        mapCenter: null,
        initialAddress: '',
        data: {
            name: '',
            address: '',
            tags: [],
            groupScheduleMode: 'apply',
            nullTimezoneError: null,
            licensesTypeError: false,
            error: null,
            licenseId: null,
        },
        groupTimezone: false,
        parentTimezone: null,
        licensesData: [],
        currentLicense: null,
        validateSymbolNumber: false,
    }
    parentTimezone: any = this.props.groups.find((group) => String(group.id) === String(this.props!.currentGroup!.id))

    componentDidUpdate(prevProps: IAddVirtualDeviceProps, prevState: IState) {
        if (
            prevState.groupTimezone !== this.state.groupTimezone &&
            this.state.groupTimezone &&
            this.props.currentGroup &&
            this.props.currentGroup.id
        ) {
            if (this.parentTimezone) {
                this.setState({
                    ...prevState,
                    parentTimezone: this.parentTimezone['0'].timezone,
                    groupTimezone: true,
                    data: {
                        ...prevState.data,
                        timezone: this.parentTimezone['0'].timezone,
                        nullTimezoneError: null,
                    },
                })
            }
        }
    }
    save = () => {
        const s_ = this.state
        const { emitError } = this.props

        if (s_.active) {
            return
        }

        // if (!s_.data.name) {
        //     if (emitError) {
        //         emitError(translate('emptyNameError'))
        //     }
        //     return
        // }
        if (!s_.data.name) {
            this.setState((prevState: IState) => ({
                ...prevState,
                data: { ...prevState.data, error: 'fieldIsRequired' },
            }))
        }
        if (!s_.data.timezone) {
            this.setState((prevState: IState) => ({
                ...prevState,
                data: { ...prevState.data, nullTimezoneError: 'fieldIsRequired' },
            }))
        }
        if (s_.currentLicense === null && helpersDisplays.isCheckLicenseQ()) {
            this.setState((prevState: IState) => ({
                ...prevState,
                data: { ...prevState.data, licensesTypeError: true },
            }))
        }
        if (s_.data.timezone) {
            this.setState((prevState: IState) => ({
                ...prevState,
                data: { ...prevState.data, nullTimezoneError: null },
            }))
        }
        if (s_.data.name) {
            this.setState((prevState: IState) => ({
                ...prevState,
                data: { ...prevState.data, error: null },
            }))
        }
        if (s_.currentLicense !== null) {
            this.setState((prevState: IState) => ({
                ...prevState,
                data: { ...prevState.data, licensesTypeError: false },
            }))
        }
        if (!s_.data.name || !s_.data.timezone || (s_.currentLicense === null && helpersDisplays.isCheckLicenseQ())) {
            return
        }

        let tags: string[] = []
        if (s_.data.tags) {
            tags = s_.data.tags.map((tag: { label: string }) => tag.label)
        }

        api.send('createDigitalSignage', { ...s_.data, tags }).then(() => {
            this.closeModal()
        })
    }

    getLicenses = (companyId: number) => {
        api.send('getLicenseByFilters', {
            sort: [['licenseId', 'ASC']],
            limit: 1000,
            companyId: companyId,
        }).then((res: any) => {
            let optionFormat = res.data
                .filter((data: any) => data.activeQ)
                .map((opt: any) => ({
                    id: opt.licenseId,
                    name: licenseType(opt) + ' ' + rateTypeRename(opt.rateType),
                    vacantCount: opt.vacantCount,
                    expiredAt: opt.expiredAt,
                    paidQ: opt.paidQ,
                    activeQ: opt.activeQ,
                }))
            this.setState((prevState: IState) => ({
                ...prevState,
                licensesData: optionFormat,
            }))
        })
    }

    componentDidMount() {
        const p_ = this.props
        const s_ = this.state

        if (p_.currentGroup) {
            s_.data.groupId = p_.currentGroup.id
        }
        this.setState(s_)
        this.getLicenses(p_.user)
    }

    inputNullCheck = (value: string | null, type: string) => {
        if (type === 'name') {
            value
                ? this.setState((prevState: IState) => ({ ...prevState, data: { ...prevState.data, error: null } }))
                : this.setState((prevState: IState) => ({
                      ...prevState,
                      data: { ...prevState.data, error: 'fieldIsRequired' },
                  }))
        }
        if (type === 'timezone') {
            value
                ? this.setState((prevState: IState) => ({
                      ...prevState,
                      data: { ...prevState.data, nullTimezoneError: null },
                  }))
                : this.setState((prevState: IState) => ({
                      ...prevState,
                      data: { ...prevState.data, nullTimezoneError: 'fieldIsRequired' },
                  }))
        }
    }

    setData = (newData: Data) => {
        const s_ = this.state

        this.setState({
            data: {
                ...s_.data,
                ...newData,
            },
        })
    }

    closeModal = () => {
        const { closeModal } = this.props
        this.setState((prevState: IState) => ({
            ...prevState,
            data: { ...prevState.data, nullTimezoneError: null, error: null, licensesTypeError: false },
        }))
        if (closeModal) {
            closeModal()
        }
    }

    onChangeAddress = (address: string) => {
        this.setState((prevState: IState) => ({ ...prevState, data: { ...prevState.data, address } }))
    }

    onChangeLocation = (location: { name: string; lat: number; lng: number }) => {
        const { name, lat, lng } = location

        this.setState((prevState: IState) => ({
            ...prevState,
            data: { ...prevState.data, address: name, lat, lng },
            initialAddress: name,
        }))
    }

    onChangePlacemarkGeometry = (geometry: { lat: number; lng: number }) => {
        const { lat, lng } = geometry

        this.setState((prevState: IState) => ({ ...prevState, data: { ...prevState.data, lat, lng } }))
    }

    updateLocation() {
        const { address } = this.state.data
        const { initialAddress } = this.state

        if (address === initialAddress) {
            this.save()
            return
        }

        mapApi
            .geocodeLocation(address)
            .then((mapLocationList) => {
                if (!isNotEmptyArray(mapLocationList)) {
                    this.save()
                    return
                }

                let { lat, lng } = mapLocationList[0]

                this.setState(
                    (prevState: IState) => ({ ...prevState, data: { ...prevState.data, lat, lng } }),
                    () => {
                        this.save()
                    }
                )
            })
            .catch(() => {
                this.save()
            })
    }

    onSave = () => {
        this.updateLocation()
    }

    getAddress = () => {
        const { address } = this.state.data

        return address || ''
    }

    getCoordinate = () => {
        const { lat, lng } = this.state.data

        return {
            lat: lat || null,
            lng: lng || null,
        }
    }

    getLocation = () => {
        return {
            name: this.getAddress(),
            ...this.getCoordinate(),
        }
    }

    getCenter = () => {
        const { mapCenter } = this.state

        if (isExist(mapCenter) && isExist(mapCenter!.lat) && isExist(mapCenter!.lng)) {
            return mapCenter
        }

        return null
    }

    onMapClose = () => {
        const { lat, lng } = this.props

        this.setState({
            active: false,
            mapCenter: {
                lat,
                lng,
            },
        })
    }

    onValidateSymbolNumber(isValidate: boolean) {
        this.setState((prevState: IState) => ({ ...prevState, validateSymbolNumber: isValidate }))
    }
}

export default DisplaysAddVirtualDeviceMethods
