import { injectStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'

const editInlineStyles = {
    withIcon: {
        ...item.rel,
        '& .icon': {
            display: 'none'
        },
        '&:hover': {
            '& .icon': {
                ...item.abs,
                display: 'block',
                right: -20,
                top: 0
            }
        }
    },
    showIcon: {
        ...item.rel,
        '& .icon': {
            ...item.abs,
            display: 'block',
            right: -20,
            top: 0
        }
    }
}

injectStyles(editInlineStyles)
export default editInlineStyles
