import React from 'react'
import moment from 'moment'
// @ts-ignore
import momentTz from 'moment-timezone'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import helpers from 'core/helpers'

type DSState = {
    currentDate: string
    timezone: string
}
interface IDateTime {
    selectedInfo: any
    emitError: (error: string) => void
    closeModal: () => void
    afterAction: () => void
    timezones: string[]
}

class DisplaysDateTimeMethods extends React.Component<IDateTime> {
    state: {
        date: moment.Moment
        convertedTime: string
        time: string
        timezone: any
        automaticallyDatetimeQ: boolean
        isLoading: boolean
        isUnknownDateTime: boolean
        timeLabel?: string
    } = {
        date: moment(),
        convertedTime: moment().format('HH:mm'),
        time: moment().format('HH:mm'),
        timezone: momentTz.tz.guess(),
        automaticallyDatetimeQ: this.props.selectedInfo.automaticallyDatetimeQ,
        isLoading: false,
        isUnknownDateTime: false,
    }
    getDsStateTimer: any = null
    listenersId: string[] = []

    onFDsGetState = (dsState: any) => {
        clearTimeout(this.getDsStateTimer)
        this.setState({ isLoading: false })
        this.setDsState(dsState)
    }

    createListenerForGetState = () => {
        api.addObserver('fDsGetState', this.onFDsGetState, this.listenersId)
    }
    componentDidMount() {
        const p_ = this.props

        this.createListenerForGetState()

        if (p_.selectedInfo.timezone) {
            const currentDateTime = `${moment().format('YYYY-MM-DD')} ${this.state.time}`

            this.setState({
                timezone: p_.selectedInfo.timezone,
                convertedTime: momentTz(currentDateTime).tz(p_.selectedInfo.timezone).format('HH:mm'),
            })
        }
        if (p_.selectedInfo.type === 'digitalSignage') {
            this.getDsState()
        }
    }
    onChangeTime = (value: string) => {
        const s_ = this.state

        if (moment(value, 'HH:mm', true).isValid()) {
            const currentDateTime = `${moment().format('YYYY-MM-DD')} ${value}`
            let time = momentTz.tz(currentDateTime, s_.timezone).toISOString()
            time = moment(time).format('HH:mm')

            this.setState({ convertedTime: value, time })
        } else {
            this.setState({ convertedTime: moment().format('HH:mm'), time: moment().format('HH:mm') })
        }
    }
    getDsState = () => {
        const { selectedInfo } = this.props

        this.getDsStateTimer = setTimeout(() => {
            this.setState({
                isLoading: false,
                isUnknownDateTime: true,
            })
        }, 10000)

        this.setState({ isLoading: true }, () => {
            api.send('fDsGetState', { id: selectedInfo.id })
        })
    }
    setDsState = (dsState: DSState) => {
        const p_ = this.props

        if (dsState.currentDate) {
            let date = moment(dsState.currentDate)
            let time = helpers.getFormattedLocalDate(dsState.currentDate, 'HH:mm')
            let timeLabel = helpers.getFormattedLocalDate(dsState.currentDate, 'YYYY-MM-DD HH:mm:ss')
            const current = moment.utc(dsState.currentDate)
            let convertedTime = helpers.getFormattedLocalDate(dsState.currentDate, 'HH:mm')

            if (dsState.timezone) {
                date = momentTz(current).tz(dsState.timezone)
                convertedTime = date.format('HH:mm')
                timeLabel = date.format('YYYY-MM-DD HH:mm:ss')
            } else if (p_.selectedInfo.timezone) {
                date = momentTz(current).tz(p_.selectedInfo.timezone)
                convertedTime = date.format('HH:mm')
                timeLabel = date.format('YYYY-MM-DD HH:mm:ss')
            }

            this.setState({ time, convertedTime, date, timeLabel })
        }
    }
    getDateTimeLabel = () => {
        const p_ = this.props
        const s_ = this.state
        const isGroup = p_.selectedInfo.type === 'group'
        let label: string | undefined = s_.timeLabel

        if (isGroup) {
            return translate('dateTimeGroupLabel')
        }

        if (s_.isUnknownDateTime || !label) {
            return translate('currentTimeIsNotAvailable')
        }

        return `${translate('currentDateTimeOnDevice')}: ${label}`
    }
    save = () => {
        const s_ = this.state
        const p_ = this.props

        if (!s_.time || !s_.date) {
            p_.emitError('fieldsAreRequired')
        } else {
            const newDate = moment(`${moment(s_.date).format('YYYY-MM-DD')} ${s_.time}`).toISOString()

            let data: { [index: string]: string | boolean } = {
                newDate,
                automaticallyDatetimeQ: s_.automaticallyDatetimeQ,
            }

            const type = p_.selectedInfo.type ? p_.selectedInfo.type : p_.selectedInfo.map((item: any) => item.type)[0]
            const id = p_.selectedInfo.id ? p_.selectedInfo.id : p_.selectedInfo.map((item: any) => item.id)

            if (type === 'digitalSignage') {
                data.digitalSignageId = id
            } else {
                data.groupId = id
            }

            console.log(data)

            api.send('setDigitalSignageDate', data).then(() => {
                p_.closeModal()

                if (p_.afterAction) {
                    p_.afterAction()
                }
            })
        }
    }
    componentWillUnmount() {
        this.listenersId.forEach((id) => api.removeObserver(id))
        clearTimeout(this.getDsStateTimer)
    }
}

export default DisplaysDateTimeMethods
