import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment/moment'

import helpers from 'core/helpers'
import { api } from 'core/api/ConnectionManager'
import helpersFiles from 'core/helpers/_files'

export interface IState extends IDefaultVideoPlayerSettings {
    showPlayer: boolean
    error: boolean
    processingTasks: { [index: string]: any }
    animations: typeof defaultAnimations
}
export interface IDefaultVideoPlayerSettings {
    active: boolean
    ready: boolean
    played: number
    volume: number
    error: boolean
}
const defaultVideoPlayerSettings: IDefaultVideoPlayerSettings = {
    active: false,
    ready: false,
    played: 0,
    volume: 75,
    error: false,
}
const defaultAnimations: any = {
    in: null,
    out: null,
}
export interface IVideoEditorContentProps {
    onChangeShowSettings: (showSettings: null | string) => void
    onDeleteMedia: () => void
    onUpload: (file: any) => void
    onDropContent: (content: any) => void
    toggleMinimized: () => void
    onSave: () => void
    onChangeCommands: (commands: { [index: string]: any }) => void
    showSettings: string | null
    media: { [index: string]: any }
    openMinimized: () => void
    animationSettings: boolean
    selectedTasks: []
    downloadMediaPercentage: number | null
    commands?: { [index: string]: any }
    minimized?: boolean
}

const VideoEditorContentMethods = (p_: IVideoEditorContentProps) => {
    const [state, setState] = useState<IState>({
        active: false,
        ready: false,
        played: 0,
        volume: 75,
        error: false,
        showPlayer: true,
        processingTasks: {},
        animations: defaultAnimations,
    })

    let listenersId: string[] = []
    const player = useRef<any>(null)
    let prevProps = p_

    const onVideoEditorTaskProgressUpdated = (res: any) => {
        const s_ = { ...state }

        s_.processingTasks[res.videoEditorTaskId] = {
            percent: res.percent,
        }

        setState((prevState: IState) => {
            return {
                ...prevState,
                s_,
            }
        })
    }

    useEffect(() => {
        api.addObserver('videoEditorTaskProgressUpdated', onVideoEditorTaskProgressUpdated, listenersId)
        return () => listenersId.forEach((id) => api.removeObserver(id))
    }, [])

    useEffect(() => {
        const s_ = { ...state }

        const tasks = { ...s_.processingTasks }

        if (!p_.media || p_.media.status === 'in_progress') {
            return
        }

        const taskId = p_.media.id
        const data: any = {}

        if (tasks[taskId]) {
            delete tasks[taskId]

            data.processingTasks = tasks
        }

        if (prevProps.media) {
            // if (!helpersFiles.isEqual({ item: p_.media, prevItem: prevProps.media })) {
            data.animations = defaultAnimations

            setState((prevState: IState) => {
                return {
                    ...prevState,
                    ...data,
                    ...defaultVideoPlayerSettings,
                    showPlayer: false,
                }
            })

            return
            // }
        }

        setState((prevState: IState) => {
            return {
                ...prevState,
                ...data,
            }
        })
        prevProps = p_
    }, [p_.media])

    useEffect(() => {
        if (state.showPlayer === false) {
            setState((prevState: IState) => {
                return {
                    ...prevState,
                    showPlayer: true,
                }
            })
        }
    }, [state.showPlayer])

    const onSeekChange = (value: number) => {
        setState((prevState: IState) => {
            return {
                ...prevState,
                played: value,
            }
        })
        player.current.seekTo(value)
    }
    const getTime = () => {
        const s_ = { ...state }

        if (p_.media && p_.media.type === 'video') {
            const startOfDay = moment().startOf('day')
            const startTime = startOfDay.seconds(p_.media.duration * s_.played).format('HH:mm:ss')
            const endTime = startOfDay.seconds(parseFloat(p_.media.duration)).format('HH:mm:ss')

            return `${startTime} / ${endTime}`
        }

        return null
    }
    const onDrop = (e: any) => {
        if (e.length) {
            p_.onUpload(e[0])
        }
    }
    const onDropContent = (item: any) => {
        const result = JSON.parse(item.dnd_item)

        p_.onDropContent(result)
    }
    const onCloseAnimationSettings = () => {
        p_.onChangeShowSettings('animations')
    }
    const onSaveAnimationSettings = () => {
        const s_ = { ...state }
        const commands: any = helpers.deepCopy(p_.commands)

        for (let key in s_.animations) {
            if (s_.animations.hasOwnProperty(key)) {
                const animation = s_.animations[key]

                if (animation) {
                    commands[animation.name] = {
                        duration: animation.duration,
                        color: '#000000',
                    }
                }
            }
        }

        p_.onChangeCommands(commands)
        onCloseAnimationSettings()
    }
    return {
        state,
        setState,
        onSaveAnimationSettings,
        onCloseAnimationSettings,
        onDropContent,
        onDrop,
        getTime,
        onSeekChange,
        player,
    }
}

export default VideoEditorContentMethods
