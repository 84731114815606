import { RefObject } from 'react'
import { useEffect } from 'react'

export const useAudioPlaygroundItemPlay = (
    audioRef: RefObject<HTMLAudioElement>,
    currentMs: number,
    volume: number
) => {
    const CURRENT_TIME_DELTA_IN_S = 1

    useEffect(() => {
        if (!audioRef || !audioRef.current) return

        let audioPlayer = audioRef.current
        let currentTimeInS = currentMs / 1000
        let audioVolume = volume > 0 ? volume / 100 : 0

        audioPlayer.currentTime = currentTimeInS
        audioPlayer.volume = audioVolume
        audioPlayer.play()

        return () => {
            if (audioRef && audioRef.current) {
                audioRef.current.pause()
                audioRef.current.currentTime = 0
            }
        }
    }, [])

    useEffect(() => {
        if (!audioRef || !audioRef.current) return

        let audioPlayer = audioRef.current

        if (audioPlayer.paused || audioPlayer.currentTime === 0) return

        let playerCurrentTime = audioPlayer.currentTime
        let currentTimeInS = currentMs / 1000

        if (Math.abs(playerCurrentTime - currentTimeInS) >= CURRENT_TIME_DELTA_IN_S) {
            audioPlayer.currentTime = currentTimeInS
        }
    }, [currentMs])
}
