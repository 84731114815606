import React from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import Collapse from 'blocks.simple/collapse/collapse'
import EditText from 'blocks.simple/editText/editText'
import Icon from 'blocks.simple/icon/icon'
import helpers from 'core/helpers'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './settings__contentDuration.jcss'
import { text as textStyles, grid } from 'theme'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { defaultThemeStyles as appDefaultThemeStyles } from 'blocks.app/app/app.theme'
import { Typography } from 'atoms/Typography'
import { SettingsContentDurationHooks, Duration } from './settings__contentDuration.hooks'
import styles_ from '../settings.jcss'

const getTypes = (fileType: string, type: string) => {
    if (fileType) {
        switch (fileType) {
            case 'image':
                return 'images'
            default:
                return fileType
        }
    } else {
        switch (type) {
            case 'link':
                return 'links'
            default:
                return type
        }
    }
}

const SettingsContentDuration = () => {
    const settings: any = useSelector<RootStateOrAny>((state) => state.user.data.settings)
    const { onChange, setUpdate, active, setActive, defaultSourceDurationList } = SettingsContentDurationHooks(
        settings.defaultSourceDuration
    )

    return (
        <Collapse
            noWrap
            title={() => translate('defaultContentDuration')}
            contentPadding={false}
            className={cn(styles_.background, styles.wrapper)}
        >
            {defaultSourceDurationList.map((source: Duration, index: number) => (
                <div
                    key={`defaultContentDuration_${index}`}
                    className={cn(styles.line, { [styles.active]: active === index })}
                >
                    <div className={cn(grid.row, grid.noWrap, grid.normalCenter)}>
                        <div className={styles.contentDuration__column}>
                            <div className={cn(grid.row, grid.noWrap)}>
                                <Icon
                                    type={source.fileType || source.type}
                                    color={
                                        active === index
                                            ? getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active
                                            : getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable
                                    }
                                />
                                <Typography
                                    type="text"
                                    textColor={
                                        active === index
                                            ? getThemeStyleValue('app', 'app', 'active') || appDefaultThemeStyles.active
                                            : getThemeStyleValue('app', 'app', 'disable') ||
                                              appDefaultThemeStyles.disable
                                    }
                                    className={cn(grid.pr_mini, grid.pl_mini)}
                                >
                                    {translate(getTypes(source.fileType, source.type)).toLowerCase()}
                                </Typography>
                            </div>
                        </div>
                        <div className={styles.contentDuration__column}>
                            <EditText
                                tabindex={'0'}
                                className={cn(textStyles.center, styles.overflowAuto)}
                                value={source.defaultDuration}
                                rounded={'full_md'}
                                indentSize={'mini'}
                                type={'number'}
                                mod="withLightBorder"
                                disabled={!helpers.isAvailable({ key: 'settings', action: 'update' })}
                                onFocus={() => setActive(index)}
                                onBlur={() => setActive(null)}
                                onChange={(value: string) => onChange(value, index)}
                                onEndTyping={() => setUpdate(true)}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </Collapse>
    )
}
export default SettingsContentDuration
