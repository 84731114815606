import React, { FC } from 'react'
import { ITableRowProps } from './TableRow-types'
import { styles } from './TableRow-styles'
import { cn } from 'ethcss'

export const TableRow: FC<ITableRowProps> = ({ isSelected = false, children, className, ...rest }) => {
    return (
        <tr
            className={cn(
                styles.TableRow,
                {
                    [styles.TableRow__selected]: isSelected,
                },
                className
            )}
            {...rest}
        >
            {children}
        </tr>
    )
}
