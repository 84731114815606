import React from 'react'
import Draggable from 'blocks.simple/draggable/draggable'
import Icon from 'blocks.simple/icon/icon'
import helpers from 'core/helpers'
import { jsUcfirst } from '../line.utils'
import styles, { iconSize } from './line_v.jcss'
import { cn } from 'ethcss'
import { indents } from 'blocks.app/config'
import { grid } from '../../../theme'
import { defaultThemeStyles } from '../line.theme'

interface ILineV {
    scaleStep: number
    scaleLength: number
    steps: number
    smallStepsPosition: string
    indents: string
    position: string
    showLastStep: boolean
    showMovablePoint: boolean
    size: number
    onMouseDown?: () => void
}

class LineV extends React.Component<ILineV> {
    static defaultProps: {
        indents: string
        size: number
        scaleLength: number
        smallStepsPosition: string
        showMovablePoint: boolean
        position: string
        steps: number
    }
    state: {
        steps: { number: number; width: number }[]
    }

    constructor(p_: ILineV) {
        super(p_)

        this.state = {
            steps: LineV.calcSteps(p_),
        }
    }

    static getDerivedStateFromProps(nextProps: ILineV) {
        return {
            steps: LineV.calcSteps(nextProps),
        }
    }

    static calcSteps(p_: ILineV) {
        const steps = Math.round((p_.size / 100) * p_.steps)

        return helpers.calcScaleSteps(p_.scaleLength, steps, {
            scale: p_.scaleStep,
        })
    }

    render() {
        const s_ = this.state
        const p_ = this.props
        const smallNumber = 5
        const smallHeight = `${100 / smallNumber}%`

        return (
            <div
                className={cn(styles.wrapper)}
                style={{
                    top: indents[p_.indents],
                    bottom: indents[p_.indents],
                    [p_.position]: 0,
                    [`border${jsUcfirst(
                        p_.smallStepsPosition
                    )}`]: `2px solid var(--var-module-ui-component-line-style-outerBorder, ${defaultThemeStyles.outerBorder})`,
                }}
                onMouseDown={p_.onMouseDown}
            >
                <div className={styles.innerContent} style={{ height: `${p_.size}%` }}>
                    {s_.steps.map((step, index) => (
                        <div
                            style={{ height: `${step.width}%` }}
                            key={`lineV_${p_.smallStepsPosition}_${index}`}
                            className={cn(styles.item, {
                                [styles.itemLast]: !p_.showLastStep,
                            })}
                        >
                            <div className={styles.itemNumber}>{step.number}</div>
                            <div style={{ [p_.smallStepsPosition]: 0 }} className={styles.smallSteps}>
                                {(() => {
                                    const smallSteps = []
                                    for (let i = 0; i < smallNumber - 1; i++) {
                                        smallSteps.push(
                                            <div
                                                style={{ height: smallHeight }}
                                                key={`lineV_${p_.smallStepsPosition}_${index}_${i}`}
                                                className={styles.smallStep}
                                            />
                                        )
                                    }

                                    return smallSteps
                                })()}
                            </div>
                        </div>
                    ))}
                    {p_.showLastStep && (
                        <div
                            className={styles.item}
                            style={{
                                position: 'absolute',
                                bottom: `-${indents[p_.indents]}px`,
                                height: `${indents[p_.indents]}px`,
                            }}
                        >
                            <div className={styles.itemNumber}>{p_.scaleLength}</div>
                        </div>
                    )}

                    {p_.showMovablePoint && (
                        <Draggable
                            left={0}
                            top={0}
                            height={0}
                            width={1}
                            disableResize={true}
                            noBorder={true}
                            isDraggable={true}
                        >
                            <div className={styles.arrow}>
                                <div className={grid.space} />
                                <Icon size={iconSize} color={'white'} type={'arrowhead_right'} />
                            </div>
                        </Draggable>
                    )}
                </div>
            </div>
        )
    }
}

LineV.defaultProps = {
    steps: 10,
    scaleLength: 1080,
    smallStepsPosition: 'right',
    position: 'left',
    indents: 'md',
    size: 100,
    showMovablePoint: false,
}

export default LineV
