import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import validator from 'validator'
import { updateUserSettings } from '../../../blocks.app/user/user.state'
import { emitError } from '../../../features/appNotifications/AppNotifications.state'
import translate from '../../../core/translate'

export const useNotificationsEmail = ({ notificationsEmail }: any) => {
    const collapseRef: { onClick?: () => void } = {}
    const dispatch = useDispatch()
    const [state, setState] = useState<any>({
        notificationsEmailError: null,
        notificationsEmails: notificationsEmail,
        notificationsEmailsOriginal: notificationsEmail,
        isNotificationEmailsListUpdatePending: false,
        confirmDeleteIndex: null,
    })

    const validateNotificationsEmails = () => {
        const s_ = state
        let isValid = true
        const hasDuplicate = s_.notificationsEmails.some(
            (val: any, i: number) => s_.notificationsEmails.indexOf(val) !== i
        )

        if (hasDuplicate) {
            isValid = false

            dispatch(emitError(translate('emailsMustBeUnique')))
        }

        s_.notificationsEmails.forEach((notificationsEmail: string, index: number) => {
            if (!validator.isEmail(notificationsEmail)) {
                if (!s_.notificationsEmailError) {
                    s_.notificationsEmailError = {}
                }

                s_.notificationsEmailError[index] = true
                isValid = false
            }
        })

        setState(s_)
        return isValid
    }

    const updateNotificationsEmails = (value: string, index: number) => {
        const s_ = state

        setState({
            ...s_,
            notificationsEmails: s_.notificationsEmails.map((element: string, index_: number) => {
                if (index === index_) {
                    return value
                }

                return element
            }),
            notificationsEmailError: null,
        })
    }

    const confirmDelete = (index: number | null) => {
        const s_ = state

        setState({
            ...s_,
            confirmDeleteIndex: index,
        })
    }

    const deleteMail = () => {
        const s_ = state

        let notificationsEmailsAfterDelete = [...s_.notificationsEmails]
        notificationsEmailsAfterDelete.splice(s_.confirmDeleteIndex, 1)
        setState({
            ...s_,
            notificationsEmails: [...notificationsEmailsAfterDelete],
            confirmDeleteIndex: null,
        })
    }

    const addMail = () => {
        const s_ = state

        setState({
            ...s_,
            notificationsEmails: [...s_.notificationsEmails, ''],
        })
    }

    const saveEmail = () => {
        const s_ = state
        const isValid = validateNotificationsEmails()

        if (!isValid) {
            return
        }

        setState({ ...s_, isNotificationEmailsListUpdatePending: true })

        dispatch(updateUserSettings({ data: { notificationsEmail: s_.notificationsEmails }, isSaveSettings: true }))

        if (collapseRef.onClick) {
            collapseRef.onClick()
        }
    }

    return {
        state,
        updateNotificationsEmails,
        deleteMail,
        addMail,
        saveEmail,
        collapseRef,
        confirmDelete,
    }
}
