import React, { FC, memo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { IDownloadModalPosition, IDownloadServiceComponent } from './DownloadProgressList-types'
import { DownloadModal } from './molecules/DownloadModal/DownloadProgressModal-view'
import Animation from 'blocks.simple/animation/animation'
import { displaysActions } from '../../displays.state'
import { mediaJS } from '../../../../theme'

const useChangeModalPosition = () => {
    const [modalPosition, setModalPosition] = useState<IDownloadModalPosition>({
        left: mediaJS.tabMini.matches ? 0.05 : 0.3,
        width: mediaJS.tabMini.matches ? 0.9 : 0.4,
        top: 0.15,
        height: 0.7,
    })

    const changeModalPosition = (position: IDownloadModalPosition) => {
        setModalPosition(position)
    }

    return {
        modalPosition,
        changeModalPosition,
    }
}

export const DownloadProgressComponent: FC<IDownloadServiceComponent> = ({ digitalSignageId }) => {
    const { changeModalPosition, modalPosition } = useChangeModalPosition()
    const closeModal = () => {
        return false
    }
    const dispatch = useDispatch()

    const setPosition = (currentPosition: IDownloadModalPosition) => {
        changeModalPosition(currentPosition)
        return true
    }

    const handleClose = () => dispatch(displaysActions.closeModal())

    return (
        <Animation>
            <DownloadModal
                setPosition={setPosition}
                position={modalPosition}
                closeModal={closeModal}
                handleClose={handleClose}
                digitalSignageId={digitalSignageId}
            />
        </Animation>
    )
}

export const DownloadProgressList = memo(DownloadProgressComponent)
