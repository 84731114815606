import React from 'react'
import InstancesCatalogFilter from '../../__filter/_instancesCatalog/catalog__filter_instancesCatalog'
import EditableText from 'blocks.simple/editableText/editableText'

import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import styles from '../_instancesCatalog/catalog__template_instancesCatalog.jcss'

export default {
    getMethod: 'getCourses',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noCourses'),
    emptyDescription: () => translate('noCoursesDescription'),
    emptyIcon: 'not_allow',
    addItemButton: () => null,
    settingsPrefixes: 'courses',
    sortOptions: () => [
        { id: '-createdAt', name: translate('sortCreatedAt') },
        { id: 'title', name: translate('byNameAsc') },
    ],
    headerFilters: (p_) => (
        <InstancesCatalogFilter
            onAdd={() => p_.onAddFakeLine({ title: '' })}
            title={translate('courses')}
            type="course"
            onDelete={() => {
                const id = p_.selectedItems.map((selectedItem) => selectedItem.id)
                if (id.length > 0) {
                    api.send('deleteCourse', { id })
                }
                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            }}
            {...p_}
        />
    ),
    getItemModel: (dataItem, getSelected) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    getBackendModel: (item) => {
        return api.send('getCourse', { id: item.id }, { hideLoader: true })
    },
    tableWrapper: styles.tableWrapper,
    isEqual: (firstItem, secondItem) => firstItem.id === secondItem.id,
    onCreateListeners: [(cb, clearList) => api.addObserver('courseCreated', cb, clearList)],
    onUpdateListeners: [(cb, clearList) => api.addObserver('courseUpdated', cb, clearList)],
    onDeleteListeners: [(cb, clearList) => api.addObserver('courseDeleted', cb, clearList)],
    isFolder: () => {},
    fields: () => [{ id: 'title', name: translate('name') }],
    init(callback) {
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        title: (p_, active, listProps) => (
            <EditableText
                id={p_.id}
                text={p_.title}
                onCreate={(title) => api.send('createCourse', { title })}
                onUpdate={(title, cb) => {
                    api.send('updateCourse', { title, id: p_.id }).then(cb)
                }}
                onSaveEmpty={function () {
                    listProps.onAddFakeLine()
                }}
            />
        ),
    },
    hideActiveRowColor: true,
    disableUpdateUserSettings: true,
    onDeleteListItem: (listItem) => {
        api.send('deleteCourse', { id: listItem.id })
    },
    fixedColWidth: true,
}
