import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import { textSizes } from 'theme/media'
import { grid, IStyles } from '../../../theme'

const dashboardSessionsStyles: IStyles = {
    full: {
        ...grid.fullWidth,
    },
    title: {
        textTransform: 'uppercase',
        marginBottom: '30px',
    },
    wrapper: {
        ...grid.col,
        ...grid.justify,
        ...grid.space,
    },
    wrapperSession: {
        ...grid.col,
        ...grid.startJustify,
        ...grid.space,
    },
    content: {
        ...grid.col,
        ...grid.space,
        ...grid.fullHeight,
        ...grid.verticalStart,
        ...grid.pb_mdPlus,
    },
    footer: {
        ...item.abs,
        bottom: '6px',
        right: '6px',
        fontSize: textSizes.mini,
        textAlign: 'right',
    },
}

addStyles(dashboardSessionsStyles)
export default dashboardSessionsStyles
