import React from 'react'
import PropTypes from 'prop-types'
import FlowDiagramMethods from './flowDiagrams.local.methods'
import { DiagramEngine, DiagramModel, DiagramWidget } from 'storm-react-diagrams'
import FlowDiagramLinkFactory from 'blocks.app/flowDiagram/__link/flowDiagram__link_factory'
import FlowDiagramNodeFactory from './__node/flowDiagam__node_factory'
import FlowDiagramMarkers from './__markers/flowDiagram__markers'
import FlowDiagramLegend from 'blocks.app/flowDiagram/__legend/flowDiagram__legend'

import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import styles from './flowDiagram.jcss'

class FlowDiagram extends FlowDiagramMethods {
    static propTypes = {
        pages: PropTypes.array.isRequired
    }
    static defaultProps = {
        pages: []
    }

    makeDiagram = (pages) => {
        const engine = new DiagramEngine()
        const model = new DiagramModel()
        engine.installDefaultFactories()
        engine.registerLinkFactory(new FlowDiagramLinkFactory())
        engine.registerNodeFactory(new FlowDiagramNodeFactory())

        const nodes = this.createNodes(pages)
        const links = this.createLinks(pages, nodes)

        model.addAll(...nodes, ...links)
        model.setLocked(true)
        engine.setDiagramModel(model)

        return <DiagramWidget className={cn(styles.wrapper)} diagramEngine={engine} smartRouting inverseZoom />
    }

    render() {
        const p_ = this.props

        return (
            <div className={grid.full}>
                {this.makeDiagram(p_.pages)}
                <FlowDiagramMarkers />
                <FlowDiagramLegend />
            </div>
        )
    }
}

export default FlowDiagram
