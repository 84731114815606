import { addStyles } from 'ethcss'
import { grid, item, IStyles, mediaCSS } from '../../../theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { textSizes } from 'theme/media'

const broadcastsAddStyles: IStyles = {
    broadcastsAddWrapper: {
        [mediaCSS.tab]: {
            padding: '74px 0 0 0',
        },
    },
    stepsGap: {
        marginBottom: '20px',

        [mediaCSS.tab]: {
            marginBottom: '10px',
        },
    },
    conflicts: {
        ...grid.mt_micro,
        ...grid.p_md,
        backgroundColor: `var(--var-module-app-component-app-style-background, ${defaultThemeAppStyles.background})`,
        color: `var(--var-module-app-component-app-style-textMain, ${defaultThemeAppStyles.textMain})`,
    },
    conflictItem: {
        ...grid.p_mini,
        fontSize: textSizes.normal,
        textAlign: 'center',
        border: `1px solid var(--var-module-app-component-app-style-error, ${defaultThemeAppStyles.error})`,
        color: `var(--var-module-app-component-app-style-error, ${defaultThemeAppStyles.error})`,
        borderRadius: 5,
    },
    buttons: {
        ...grid.rowCenter,
        ...grid.pt_mini,
        ...grid.pb_mini,
        backgroundColor: `var(--var-module-app-component-app-style-background, ${defaultThemeAppStyles.background})`,
    },
    conflictsTable: {
        ...item.tableWrapper,
        '& td': {
            ...grid.pt_mini,
            ...grid.pb_mini,
        },
    },
    tabs: {
        padding: '0 10px',
    },
    tab: {
        [mediaCSS.tab]: {
            fontSize: 12,
            padding: 7,
            textAlign: 'center',
        },
    },
}

addStyles(broadcastsAddStyles)
export default broadcastsAddStyles
