import { addStyles } from 'ethcss'
import { mediaCSS } from 'blocks.app/config'
import grid from 'blocks.simple/grid/grid.jcss'
import { IStyles, text as textStyles } from 'theme'

const deviceSettingsTabsStyles: IStyles = {
    tab: {
        ...textStyles.center,
        ...grid.mb_mdPlus,
        [mediaCSS.tabMini]: {
            ...grid.mb_micro,
            ...grid.mr_micro,
        },
    },
    platformsWrapper: {
        [mediaCSS.tabMini]: {
            ...grid.row,
            ...grid.justify,
        },
    },
}

addStyles(deviceSettingsTabsStyles)
export default deviceSettingsTabsStyles
