import React, { FC, Fragment, useState } from 'react'
import { Typography } from 'atoms/Typography'
import Icon from 'blocks.simple/icon/icon'
import translate from 'core/translate'
import { isExist, isNotEmptyArray } from 'core/utils/coreUtil'
import { compareVersionsDESC } from 'core/utils/sortUtil'
import { cn } from 'ethcss'
import moment from 'moment'
import { Distributive } from 'pages/distributives/models/platforms'
import Toast from 'blocks.simple/toast/toast'
import { styles } from './PlatformCardTable-styles'
import { PlatformCardTableProps } from './PlatformCardTable-types'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

export const PlatformCardTable: FC<PlatformCardTableProps> = ({ distributives, displayedClientAppType }) => {
    const [isOpenedIndex, setIsOpenedIndex] = useState<any>([])

    if (!isNotEmptyArray(distributives)) return null

    const firstDistributive = distributives[0]
    const { fileHash, distributiveType, capacity } = firstDistributive

    const isUploaded = displayedClientAppType === 'uploaded'
    const isLink = displayedClientAppType === 'link'

    const sortDistributivesByVersion = (prevDistributive: Distributive, nextDistributive: Distributive) => {
        return compareVersionsDESC(prevDistributive.version, nextDistributive.version)
    }

    const renderDescription = (description: string | null) => {
        if (!description) return null
        const htmlString = `${description}`
        return (
            <ul className={styles.platformCardTable__list}>
                <Typography type="title" element="div" className={styles.platformCardTable__listItem}>
                    <span
                        className={styles.platformCardTable__list_span}
                        dangerouslySetInnerHTML={{
                            __html: htmlString,
                        }}
                    ></span>
                </Typography>
            </ul>
        )
    }

    const isDistributiveOpen = (key: any) => isOpenedIndex.includes(key)

    const renderDate = (date: string) => moment(date).format('DD.MM.YYYY HH:mm').toString()

    const isExistCapacity = capacity !== 'any'

    const isExistDistributiveType = isExist(distributiveType) && distributiveType !== 'any'

    const onCopyHash = (hash: string) => {
        const clipboardResultToastOptions = {
            autoClose: 3000,
            position: 'bottom-left',
        }

        navigator.clipboard.writeText(hash).then(
            () => {
                Toast.info(translate('copiedToClipboard'), clipboardResultToastOptions)
            },
            () => {
                Toast.error(translate('copyToClipboardError'), clipboardResultToastOptions)
            }
        )
    }

    const openDetails = (key: any) => {
        if (isOpenedIndex.includes(key)) {
            return
        }

        setIsOpenedIndex([...isOpenedIndex, key])
    }

    const closeDetails = (key: any) => {
        setIsOpenedIndex(isOpenedIndex.filter((item: any) => item !== key))
    }

    const distributiveCount = (distributive: any) => {
        let count = 3

        distributive.forEach((item: any) => {
            if (item) {
                count++
            }
        })

        return count
    }

    const renderRows = () => {
        return distributives.map((distributive, index) => {
            const {
                version,
                releaseDate,
                description,
                capacity,
                distributiveURL,
                distributiveType,
                fileHash,
            } = distributive

            const uniqueKey = index
            let count = distributiveCount([isExistCapacity, fileHash, isExistDistributiveType, isUploaded, isLink])

            return (
                <Fragment key={uniqueKey}>
                    <tr className={styles.platformCardTable__row}>
                        <td className={styles.platformCardTable__td}>
                            <Typography className={styles.platformCardTable__text} type="title">
                                {version}
                            </Typography>
                        </td>
                        <td className={styles.platformCardTable__td}>
                            <Typography className={styles.platformCardTable__text} type="title" isEllipsis={true}>
                                {renderDate(releaseDate)}
                            </Typography>
                        </td>
                        <td className={styles.platformCardTable__td}>
                            <Typography
                                element="button"
                                type={isDistributiveOpen(uniqueKey) ? 'promo' : 'title'}
                                className={cn(styles.platformCardTable__button, {
                                    [styles.platformCardTable__button_type_active]: isDistributiveOpen(uniqueKey),
                                })}
                                onClick={() => {
                                    if (description) {
                                        openDetails(uniqueKey)
                                    }
                                }}
                            >
                                {translate('distributiveDescriptionButton')}
                            </Typography>
                        </td>
                        {isExistCapacity && (
                            <td className={styles.platformCardTable__td}>
                                <Typography
                                    className={cn(
                                        styles.platformCardTable__text,
                                        styles.platformCardTable__text_type_capacity
                                    )}
                                    type="title"
                                >
                                    {capacity}
                                </Typography>
                            </td>
                        )}
                        {fileHash && (
                            <td className={styles.platformCardTable__td}>
                                <Typography
                                    className={cn(
                                        styles.platformCardTable__text,
                                        styles.platformCardTable__text_type_hash
                                    )}
                                    onClick={() => onCopyHash(fileHash)}
                                    type="title"
                                    isEllipsis={true}
                                >
                                    {fileHash}
                                </Typography>
                            </td>
                        )}
                        {isExistDistributiveType && (
                            <td className={styles.platformCardTable__td}>
                                <Typography className={styles.platformCardTable__text} type="title" isEllipsis={true}>
                                    {translate(distributiveType)}
                                </Typography>
                            </td>
                        )}
                        {isUploaded && (
                            <td className={styles.platformCardTable__td}>
                                <Typography
                                    className={cn(
                                        styles.platformCardTable__text,
                                        styles.platformCardTable__text_type_downloadLink
                                    )}
                                    type="title"
                                >
                                    <a href={distributiveURL} target="_blank">
                                        <Icon type="downloadLink" size="24" />
                                    </a>
                                </Typography>
                            </td>
                        )}
                        {isLink && (
                            <td className={styles.platformCardTable__td}>
                                <Typography className={styles.platformCardTable__text} type="title" isEllipsis={true}>
                                    <a href={distributiveURL} target="_blank">
                                        {distributiveURL}
                                    </a>
                                </Typography>
                            </td>
                        )}
                    </tr>
                    <Fragment>
                        <tr>
                            <td
                                colSpan={count}
                                className={cn(styles.platformCardTable__td, styles.platformCardTable__td_type_info)}
                            >
                                <SlideDown className={'my-dropdown-slidedown'}>
                                    {isOpenedIndex.includes(uniqueKey) ? (
                                        <Fragment>
                                            {renderDescription(description)}
                                            {description ? (
                                                <div className={styles.platformCardTable___toggler}>
                                                    <Icon
                                                        type="arrow_up"
                                                        size="23"
                                                        onClick={() => closeDetails(uniqueKey)}
                                                    />
                                                </div>
                                            ) : null}
                                        </Fragment>
                                    ) : null}
                                </SlideDown>
                            </td>
                        </tr>
                    </Fragment>
                </Fragment>
            )
        })
    }

    return (
        <table className={styles.platformCardTable}>
            <thead>
                <tr className={cn(styles.platformCardTable__row, styles.platformCardTable__row_type_head)}>
                    <th className={cn(styles.platformCardTable__th, styles.platformCardTable__th_type_version)}>
                        <Typography className={styles.platformCardTable__title} type="title">
                            {translate('distributiveVersion')}
                        </Typography>
                    </th>
                    <th className={cn(styles.platformCardTable__th, styles.platformCardTable__th_type_date)}>
                        <Typography className={styles.platformCardTable__title} type="title">
                            {translate('distributiveDate')}
                        </Typography>
                    </th>
                    <th className={cn(styles.platformCardTable__th, styles.platformCardTable__th_type_description)}>
                        <Typography className={styles.platformCardTable__title} type="title" isEllipsis={true}>
                            {translate('distributiveDescription')}
                        </Typography>
                    </th>
                    {isExistCapacity && (
                        <th className={cn(styles.platformCardTable__th, styles.platformCardTable__th_type_capacity)}>
                            <Typography className={styles.platformCardTable__title} type="title" isEllipsis={true}>
                                {translate('distributiveCapacity')}
                            </Typography>
                        </th>
                    )}
                    {fileHash && (
                        <th className={cn(styles.platformCardTable__th, styles.platformCardTable__th_type_hash)}>
                            <Typography className={styles.platformCardTable__title} type="title" isEllipsis={true}>
                                {translate('distributiveHash')}
                            </Typography>
                        </th>
                    )}
                    {isExistDistributiveType && (
                        <th
                            className={cn(
                                styles.platformCardTable__th,
                                styles.platformCardTable__th_type_distributiveType
                            )}
                        >
                            <Typography className={styles.platformCardTable__title} type="title" isEllipsis={true}>
                                {translate('distributiveType')}
                            </Typography>
                        </th>
                    )}
                    {isUploaded && (
                        <th
                            className={cn(styles.platformCardTable__th, styles.platformCardTable__th_type_downloadLink)}
                        >
                            <Typography
                                className={cn(
                                    styles.platformCardTable__title,
                                    styles.platformCardTable__title_type_downloadLink
                                )}
                                type="title"
                                isEllipsis={true}
                            >
                                {translate('distributiveDownloadLink')}
                            </Typography>
                        </th>
                    )}
                    {isLink && (
                        <th className={cn(styles.platformCardTable__th, styles.platformCardTable__th_type_installLink)}>
                            <Typography className={styles.platformCardTable__title} type="title" isEllipsis={true}>
                                {translate('distributiveInstallLink')}
                            </Typography>
                        </th>
                    )}
                </tr>
            </thead>
            <tbody>{renderRows()}</tbody>
        </table>
    )
}
