import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import helpers from 'core/helpers'

interface IItem {
    source: { __view: { selected: boolean } }
    __view: { selected: boolean }
}

export default {
    getMethod: 'askonaGetCatalog',
    updateCatalogMethod: 'askonaUpdateCatalog',
    onCatalogUpdatedMethod: 'askonaCatalogUpdated',
    updateMethod: 'askonaUpdateCatalogItem',
    updateWebAppMethod: 'askonaUpdateWebApp',
    catalogSelectedSource: 'askonaCatalogSelectedSource',
    emptyDescription: () => translate('noItemsDescription'),
    emptyIcon: 'not_allow',
    settingsPrefixes: 'askona',
    getItemModel: (dataItem: IItem, getSelected: (item: IItem) => any) => {
        const item = { ...dataItem }

        if (item.source && !item.source.__view) {
            item.source.__view = {
                selected: getSelected(item),
            }
        }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    ...helpers.createListenersWidget({
        onCreate: 'askonaItemCreated',
        onUpdate: 'askonaItemUpdated',
        onDelete: 'askonaItemDeleted',
    }),
    isEqual: (firstItem: { id: number }, secondItem: { id: number }) => firstItem.id === secondItem.id,
    draggable: true,
    onDeleteListItem: (listItem: { id: number }) => {
        api.send('askonaDeleteCatalogItem', { id: listItem.id })
    },
    fixedColWidth: true,
}
