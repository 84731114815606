import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { mediaCSS, textSizes } from 'theme/media'
import { IStyles } from '../../../../theme'

const catalogSelectContentStyles: IStyles = {
    content: {
        ...grid.full,
        ...grid.col,
        position: 'relative',
        ...grid.p_mdPlus,
        ...grid.pb_big,
        backgroundColor: `var(--var-module-app-component-app-style-background, ${defaultThemeStyles.background})`,
    },
    toolbar: {
        ...grid.full,
        ...grid.cols,
    },
    tabMiniPt: {
        [mediaCSS.tabMini]: {
            ...grid.pt_mdPlus,
        },
    },
    buttons: {
        position: 'absolute',
        ...grid.p_mini,
        ...grid.rowCenter,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: `var(--var-module-app-component-app-style-background, ${defaultThemeStyles.background})`,
        zIndex: 1000,
    },
    title: {
        ...grid.pb_md,
        fontSize: textSizes.md,
        fontWeight: 'bold',
    },
    breadcrumbs: {
        ...grid.mb_md,
    },
    toolbarTitle: {
        textAlign: 'center',
        ...grid.mb_bigPlus,
    },
    label: {
        marginBottom: 7,
    },
}

addStyles(catalogSelectContentStyles)
export default catalogSelectContentStyles
