import React from 'react'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import Icon from 'blocks.simple/icon/icon'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { cn } from 'ethcss'
import styles from './customList__content.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import translate from 'core/translate'

const DragHandle = SortableHandle(({ onlyDraggable }: any) => (
    <div className={cn({ [grid.ml_mini]: !!onlyDraggable })}>
        <Icon type="sortable_handle" />
    </div>
))

const SortableList = SortableContainer(
    (p_: {
        fakeLine: string
        multipleSelect: boolean
        animate: boolean
        options: { id: number }[]
        getCellValue: any
        template: any
        list: any
    }) => (
        <tbody>
            {p_.fakeLine && (
                <tr key={`customListRow_${'fake'}`} className={cn({ [styles.animation]: p_.animate })}>
                    {p_.multipleSelect && (
                        <td>
                            <div className={item.tableCell}>
                                <Checkbox
                                    checked={false}
                                    onClick={(e: MouseEvent) => e.stopPropagation()}
                                    mod={'circle'}
                                    disabled={true}
                                />
                            </div>
                        </td>
                    )}
                    {p_.options.map((option, index: number) => (
                        <td key={`customListItem_fake_${index}`}>
                            <div className={item.tableCell}>{p_.getCellValue(p_.fakeLine, option.id, true)}</div>
                        </td>
                    ))}
                    {p_.template.onDeleteListItem && <td></td>}
                </tr>
            )}
            {p_.list.map((listItem: { id: number; __view: { selected: boolean } }, index: number) => {
                const active = listItem.__view.selected

                return (
                    <SortableItem
                        key={`customListRow_${listItem.id}_${index}`}
                        {...p_}
                        index={index}
                        active={active}
                        listItem={listItem}
                    />
                )
            })}
        </tbody>
    )
)

const SortableItem = SortableElement(
    (p_: {
        multipleSelect: boolean
        options: { id: number }[]
        getCellValue: any
        template: any
        listItem: { id: number }
        active: boolean
        onSelect?: any
    }) => (
        <tr>
            {p_.multipleSelect && (
                <td>
                    <div className={item.tableCell}>
                        <Checkbox
                            onClick={(selected: any, e: MouseEvent) => {
                                e.stopPropagation()
                                p_.onSelect({ selected, item: p_.listItem })
                            }}
                            checked={p_.active}
                            mod={'circle'}
                        />
                    </div>
                </td>
            )}
            {p_.options.map((option: { id: number }, index: number) => (
                <td key={`customListItem_${p_.listItem.id}_${index}`}>
                    <div className={item.tableCell}>{p_.getCellValue(p_.listItem, option.id, p_.active)}</div>
                </td>
            ))}
            {(p_.template.onDeleteListItem || p_.template.draggable) && (
                <td>
                    <div className={styles.controls}>
                        {p_.template.onDeleteListItem && (
                            <Icon
                                onClick={function () {
                                    p_.template.onDeleteListItem(p_.listItem)
                                }}
                                type={'delete'}
                            />
                        )}
                        {p_.template.draggable && <DragHandle onlyDraggable={p_.template.onDeleteListItem} />}
                    </div>
                </td>
            )}
        </tr>
    )
)

interface ICustomListContentProps {
    template: any
    options: any[]
    loading: boolean
    multipleSelect: boolean
    onSortEnd: (data: { oldIndex: number; newIndex: number }) => void
    list: any
    catalogId: number | null
    type: string
    isEqual: (value_1: {}, value_2: {}) => boolean
    onSelect: ({ selected, item }: { selected: boolean; item: {} }) => void
    fakeLine: any
    source: any
    animate: boolean
    onAddFakeLine: (data: string) => void
    additionalData: any
}

const CustomListContent = (p_: ICustomListContentProps) => {
    const getCellValue = (listItem: { [index: string]: string }, id: number, active: boolean) => {
        const cellRender = p_.template.list

        if (cellRender && cellRender[id]) {
            return cellRender[id](listItem, active, p_)
        } else {
            const value = listItem[id]
            if (typeof value === 'object') {
                return null
            } else {
                return value
            }
        }
    }

    const options = p_.options.filter((option: { hide: boolean }) => !option.hide)
    const thStyle: any | {} = {}

    if (p_.template.fixedColWidth) {
        thStyle.width = `${90 / options.length}%`
    }

    if (p_.loading) {
        return null
    }

    return (
        <table
            className={cn(item.tableWrapper, item.overVisible, styles.wrapper, {
                [p_.template.tableWrapper]: p_.template.tableWrapper,
            })}
        >
            <thead>
                <tr>
                    {p_.multipleSelect && <th>{translate('choice')}</th>}
                    {options.map((option, index: number) => (
                        <th key={`customListHeader_${index}`} style={thStyle}>
                            {option.name}
                        </th>
                    ))}
                    {p_.template.onDeleteListItem && <th style={{ width: '32px' }} />}
                </tr>
            </thead>
            <SortableList
                helperClass={cn(styles.active)}
                {...p_}
                useDragHandle={true}
                options={options}
                onSortEnd={p_.onSortEnd}
                getCellValue={getCellValue}
            />
        </table>
    )
}

CustomListContent.multipleSelect = true

export default CustomListContent
