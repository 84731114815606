import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { defaultThemeStyles } from './EditorPreview-theme'
import { textSizes } from 'theme/media'

const editorPreviewStyles = {
    editorPreviewWrapper: {
        ...grid.full,
        ...grid.col,
        backgroundColor: `var(--var-module-editor-component-preview-style-background, ${defaultThemeStyles.background})`,
    },
    full: {
        ...grid.full,
        ...grid.rowCenter,
    },
    content: {
        ...grid.full,
        position: 'relative',
    },
    timelineWrapper: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: `var(--var-module-editor-component-preview-style-timelineBackground, ${defaultThemeStyles.timelineBackground})`,
    },
    progress: {
        position: 'absolute',
        cursor: 'pointer',
        left: 0,
        right: 0,
        bottom: 0,
    },
    timeline: {
        position: 'relative',
        ...grid.p_md,
        ...grid.rowCenter,
        fontSize: textSizes.md,
        color: `var(--var-module-editor-component-preview-style-timeline, ${defaultThemeStyles.timeline})`,
    },
    areaContent: {
        ...grid.w100,
        position: 'relative',
        height: 45,
    },
    contentItem: {
        position: 'absolute',
        top: 0,
        bottom: 0,
    },
}

addStyles(editorPreviewStyles)
export default editorPreviewStyles
