import styles from "../styles";
import {Avatar} from "../../../molecules/Avatar";
import React from "react";
import {getRolesString} from "../index";

const UsersListItem = (p_) => {
    const onClick = () => {
        p_.onClick(p_.user)
    }

    return (
        <div className={styles.usersListItem} onClick={onClick}>
            <div className={styles.usersListAvatar}>
                <Avatar src={p_.user.photo} />
            </div>
            <div className={styles.usersListInfo}>
                <div className={styles.username}>{p_.user.name || p_.user.email}</div>
                <div className={styles.roles}>{getRolesString(p_.user.roles)}</div>
            </div>
        </div>
    )
}

export {UsersListItem}