import { addStyles } from 'ethcss'
import { grid, IStyles, item } from '../../../theme'
import { indents } from 'blocks.app/config'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { circleButtonStyles } from 'atoms/CircleButton/CircleButton-styles'
import { mediaCSS, textSizes } from 'theme/media'

const devicesWidth = 100 / 3
const SOURCE_PREVIEW_SIZE = 120

const advertisingCampaignsAdvBlockStyles: IStyles = {
    wrapper: {
        ...grid.space,
        ...grid.row,
        ...item.white,
        borderTop: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles['border']})`,
    },
    devices: {
        width: `${devicesWidth}%`,
        borderRight: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles['border']})`,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    content: {
        ...grid.col,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
        width: `${100 - devicesWidth}%`,
    },
    title: {
        ...grid.col,
        ...grid.center,
        fontSize: textSizes.normal,
        borderBottom: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles['border']})`,
        height: 150,
    },
    device: {
        ...grid.row,
        ...grid.noWrap,
        ...grid.normalCenter,
        ...grid.justify,
        ...grid.spaceWidth,
        fontSize: textSizes.normal,
        textTransform: 'uppercase',
    },
    subDevice: {
        ...grid.w100,
        ...grid.row,
        ...grid.p_micro,
        ...grid.pr_mini,
        ...grid.pl_md,
        ...item.pointer,
    },
    deviceTitle: {
        ...grid.pl_micro,
        ...grid.pr_micro,
        ...item.ellipsis,
    },
    displayIconWrapper: {
        ...item.rel,
        marginTop: -indents.micro,
    },
    deviceWrapper: {
        borderBottom: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles['border']})`,
    },
    deviceWrapperOver() {
        return {
            ...item.blueWhite,
            [`& .${this.circleButton}`]: {
                ...circleButtonStyles.circleButtonActive,
            },
        }
    },
    circleButton: {
        ...circleButtonStyles.circleButton,
    },
    collapseContent: {
        ...grid.pt_micro,
        ...grid.pb_micro,
    },
    contentTitle: {
        ...grid.row,
        ...grid.normalCenter,
        fontSize: textSizes.mini,
        paddingTop: '10px',
        paddingBottom: '10px',
        borderTop: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles['border']})`,
    },
    adListItemDuration: {
        ...grid.mt_mini,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    sec: {
        ...grid.pl_micro,
        width: indents.mdPlus,
    },
    contentInner: {
        ...grid.space,
        ...grid.row,
        ...item.overNone,
    },
    list: {
        ...grid.spaceWidth,
        ...item.rel,
    },
    scale: {
        ...item.rel,
        ...item.greyEditor,
        width: indents.mdPlus,
    },
    contentItem() {
        return {
            ...grid.row,
            ...item.noSelection,
            ...item.white,
            left: 0,
            right: 0,
            borderBottom: `1px solid var(--var-module-app-component-app-style-borderActive, ${defaultThemeStyles['borderActive']})`,
            [`&:hover .${this.sortableIcon}`]: {
                visibility: 'visible',
            },
        }
    },
    contentItemWrapper: {
        ...grid.w100,
        ...grid.rowCenter,
        ...grid.noWrap,
        ...grid.ml_md,
    },
    contentItemPreview: {
        ...grid.row,
        width: SOURCE_PREVIEW_SIZE,
        height: SOURCE_PREVIEW_SIZE,
        ...item.rounded_full_micro,
        ...item.overNone,
    },
    date: {
        ...grid.pl_micro,
        ...grid.pr_micro,
        ...grid.pb_micro,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
    },
    active: {
        ...item.shadowButton,
        fontSize: textSizes.normal,
        border: `1px dashed var(--var-module-app-component-app-style-borderActive, ${defaultThemeStyles['borderActive']})`,
    },
    adListItemTitle: {
        ...grid.rowCenter,
        ...grid.space,
        ...grid.p_mini,
        '&>span': {
            ...item.ellipsis,
        },
    },
}

addStyles(advertisingCampaignsAdvBlockStyles)
export default advertisingCampaignsAdvBlockStyles
