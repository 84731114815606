import React from 'react'
import Catalog from '../../../../blocks.app/catalog/catalog'

class Exhibitions extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            selectedInfo: null,
            selectedItems: [],
        }
    }

    render() {
        const { selectedItems } = this.state

        return (
            <Catalog
                type={'exhibitions'}
                onSelect={(selectedItems) => this.setState({ selectedItems })}
                selectedItems={selectedItems}
                treeView={false}
                additionalData={{}}
                fixedHeader={true}
                isExistRightToolbar={false}
                hideHeader={true}
            />
        )
    }
}
export default Exhibitions
