import React from 'react'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import helpers from 'core/helpers'
import { cn } from 'ethcss'
import moment from 'moment'
import styles from './searchbar__item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'

interface IItem {
    isIcon?: boolean
    src?: string
    type: string
    coverSource?: { fileType?: 'video' | 'audio' | 'web_app' | 'web_page' | 'office' | undefined; thumbnail: string }
    onClick: () => void
    createdAt?: string
    title?: string
    name?: string
}

const getCover = (p_: IItem) => {
    let cover: { isIcon: boolean; src?: string } | null = {
        isIcon: true,
    }

    if (p_.type === 'broadcast') {
        cover = p_.coverSource ? helpers.getBroadcastCover(p_.coverSource) : null
    } else if (p_.type === 'digitalSignage') {
        cover.src = 'images/searchbar/display.png'
    } else if (p_.type === 'digitalSignageGroup') {
        cover.src = './images/searchbar/group.png'
    } else if (p_.type === 'places') {
        cover.src = './images/searchbar/location.png'
    }

    return cover
}

export default (p_: IItem) => {
    const cover: { isIcon: boolean; src?: string } | null = getCover(p_)

    return (
        <div className={styles.wrapper} onClick={p_.onClick}>
            <div className={grid.w5}>
                <Rectangle width={2} height={1} className={styles.icon} src={cover!.src} contain={cover!.isIcon} />
            </div>
            <div className={cn(grid.w50, grid.pl_md, grid.pr_md)}>{p_.title || p_.name}</div>
            <div className={cn(grid.w45)}>{moment(p_.createdAt).format('DD/MM/YYYY')}</div>
        </div>
    )
}
