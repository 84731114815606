import { IStunServer } from 'pages/videoChat/_blocks/VideoChatModal/VideoChatModalConnection/VideoChatModalConnection-types'
import { v4 as uuid4 } from 'uuid'
import deepEqual from 'fast-deep-equal'
import { IRecordWithAny } from 'core/helpers'

export const isExist = <T>(val: T): boolean => {
    return val !== undefined && val !== null
}

export const isHaveLength = (val: string | unknown[], length: number) => val.length === length

export const isIndexExist = (val: number): boolean => val >= 0

export const isNumeric = (val: string): boolean => {
    let pattern = /^\d+$/
    return pattern.test(val)
}

export function isArray(checkData: unknown): boolean {
    return isExist(checkData) && Array.isArray(checkData)
}

export function isString(value: unknown): boolean {
    return typeof value === 'string'
}

export const isNotEmptyArray = <T>(checkData: T[]): boolean => {
    return isExist(checkData) && isArray(checkData) && checkData.length > 0
}

export const isEmptyObject = <T extends object>(obj: T): boolean =>
    Object.keys(obj).length === 0 && obj.constructor === Object

export const isObject = (obj: any): boolean => (typeof obj === 'object' || typeof obj === 'function') && obj !== null

export const isNotEmptyString = (checkData: string): boolean => {
    return checkData !== ''
}

export const isEmptyString = (checkData: string): boolean => checkData === ''

export const getWordTranslateWithDeclension = (length: number, translations: string[]) => {
    let coefficient = Math.abs(length) % 100
    let coefficientSecond = coefficient % 10

    if (coefficient > 10 && coefficient < 20) return translations[2]
    if (coefficientSecond > 1 && coefficientSecond < 5) return translations[1]
    if (coefficientSecond === 1) return translations[0]

    return translations[2]
}

export const generateByTimeStamp = (): number => {
    return new Date().valueOf()
}

export const generateIdByUUID = (): string => {
    return uuid4()
}

export const isFloat = (value: number) => {
    return Number(value) === value && value % 1 !== 0
}

export const cutFileName = (value: string, maxLength: number = 15, startLength: number = 4, endLength = 8) => {
    if (value.length <= maxLength) return value

    let startChunk = value.slice(0, startLength)
    let endChunk = value.slice(-endLength)
    return startChunk.concat('...', endChunk)
}

export const trimSegmentsFromString = (string: string, segments: string[]) => {
    if (!isExist(string) || !isExist(segments) || !isNotEmptyArray(segments)) return string

    let updatedString = string

    segments.forEach((segment) => {
        updatedString = updatedString.split(segment).join('')
    })

    return updatedString
}

export const checkMediaDevicesSupported = async ({
    audio = true,
    video = false,
}: {
    audio: boolean
    video?: boolean
}): Promise<boolean> => {
    if (!isExist(navigator) || !isExist(navigator.mediaDevices) || !isExist(navigator.mediaDevices.enumerateDevices))
        return false

    let hasMicrophone = false
    let hasWebcam = false
    let devices = await navigator.mediaDevices.enumerateDevices()

    devices.forEach((device) => {
        if (device.kind === 'audioinput') {
            hasMicrophone = true
        }

        if (device.kind === 'videoinput') {
            hasWebcam = true
        }
    })

    const isAudioAvailable = audio && hasMicrophone
    const isVideoAvailable = !video || (video && hasWebcam)

    return isAudioAvailable && isVideoAvailable
}

export const getUserMediaStream = async ({
    audio = true,
    video = false,
}: {
    audio: boolean
    video?: boolean
}): Promise<MediaStream | null> => {
    if (!isExist(navigator) || !isExist(navigator.mediaDevices) || !isExist(navigator.mediaDevices.getUserMedia))
        return null

    try {
        let stream = await navigator.mediaDevices.getUserMedia({ video, audio })
        return stream
    } catch (err) {
        return null
    }
}

export const initPeerConnection = async (
    stunServers: IStunServer[],
    stream: MediaStream,
    cbOnTrack: (value: any) => void,
    cbOnIceCandidate: (value: RTCIceCandidate) => void,
    cbOnConnectionStateChange: (value: RTCPeerConnectionState) => void,
    oneWayStream?: boolean
): Promise<{
    pc: RTCPeerConnection | null
    offer: RTCSessionDescriptionInit | null
}> => {
    try {
        const pc = new RTCPeerConnection({
            iceServers: stunServers,
            bundlePolicy: 'balanced',
        })

        stream.getTracks().forEach((track) => {
            pc.addTrack(track, stream)
        })

        let offer

        if (oneWayStream) {
            offer = await pc.createOffer({ offerToReceiveAudio: true, offerToReceiveVideo: true })
        } else {
            offer = await pc.createOffer()
        }

        await pc.setLocalDescription(new RTCSessionDescription(offer))

        pc.ontrack = (data) => {
            if (oneWayStream && data && data.track) {
                cbOnTrack(data.track)

                return
            }

            const streams = data.streams

            if (streams[0]) {
                cbOnTrack(streams[0])
            }
        }

        pc.onicecandidate = (event) => {
            if (event.candidate) {
                cbOnIceCandidate(event.candidate)
            }
        }

        pc.onconnectionstatechange = (event) => {
            cbOnConnectionStateChange(pc.connectionState)
        }

        return {
            pc,
            offer,
        }
    } catch (err) {
        return {
            pc: null,
            offer: null,
        }
    }
}

export const isIncludeSubstring = (current: string, substring: string): boolean => {
    if (!isExist(current) || !isExist(substring)) {
        return false
    }

    return current.toLowerCase().indexOf(substring.toLowerCase()) >= 0
}

export const isDevelopment = (): boolean => {
    return process.env.NODE_ENV === 'development'
}

export const getDeepEqualLogMessage = (prevObject: IRecordWithAny, nextObject: IRecordWithAny, name: string) => {
    let logs = `WriteDeepEqualLogs@${name}\n`
    let countNotEqualKeys = 0

    Object.keys(prevObject).forEach((key, index) => {
        let isEqual = deepEqual(prevObject[key], nextObject[key])

        if (!isEqual) {
            countNotEqualKeys++
        }

        if (!isEqual) {
            logs += `${key}: ${isEqual}\n`
        }
    })

    logs += `Not equal: ${countNotEqualKeys}.`
    return logs
}

export const omitObjectKeysByValue = <N>(object: IRecordWithAny, value: N) => {
    const newObject: IRecordWithAny = {}

    Object.keys(object).forEach((key) => {
        if (object[key] !== value) {
            newObject[key] = object[key]
        }
    })

    return newObject
}

export const capitalize = (string: string) => {
    if (!isString(string)) return ''

    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const exist = <T>(value: T) => isExist(value)
