import React from 'react'
import Content from 'blocks.app/content/content'
import SchoolSchedule from './instancesCatalog_schedule/instancesCatalogSchedule'
import DaysAndClasses from './DaysAndClasses/instancesCatalog_daysAndClasses'
import UploadCsv from './instancesCatalog_uploadCsv/instanceCatalogUploadCsv'
import translate from '../../../core/translate'
import styles from './instancesCatalog_scheduleClasses.jcss'
import { isHorizontalMenu } from 'core/helpers/menu'
import { cn } from 'ethcss'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

interface ISchoolClassesSchedulesProps {}

const instances = ['schoolSchedule', 'downloadFromFile']

class InstancesSchoolClassesSchedules extends React.Component<ISchoolClassesSchedulesProps> {
    state: any = {}
    constructor(p_: any) {
        super(p_)

        this.state = {
            isActive: 'schoolSchedule',
            selectedClassNumber: '1',
            selectedClassLetter: 'а',
            day: 1,
        }
    }

    onDataChange = (parameter: string, value: any) => {
        this.setState({ [parameter]: value })
    }

    customFilterList = (list: { classNumber: string; classLetter: string; day: number }[]) => {
        return list.filter(
            (element) =>
                element.classNumber === this.state.selectedClassNumber &&
                element.classLetter.toLowerCase() === this.state.selectedClassLetter.toLowerCase() &&
                element.day === this.state.day
        )
    }

    instanceRender() {
        const isActive = this.state.isActive

        switch (isActive) {
            case 'schoolSchedule':
                return (
                    <>
                        <DaysAndClasses
                            selectedClassNumber={this.state.selectedClassNumber}
                            selectedClassLetter={this.state.selectedClassLetter}
                            day={this.state.day}
                            onDataChange={this.onDataChange}
                        />
                        <SchoolSchedule customFilterList={this.customFilterList} />
                    </>
                )
            case 'downloadFromFile':
                return <UploadCsv />
            default:
                return null
        }
    }

    render() {
        return (
            <Content title={'scheduleClasses'}>
                <div className={cn(styles.tabsWrapper, isHorizontalMenu() && styles.tabsWrapper_x)}>
                    {instances.map((instance, index) => {
                        return (
                            <div
                                key={index}
                                className={`${styles.tabBtn} ${
                                    this.state.isActive === instance ? styles.tabActive : null
                                }`}
                                onClick={() => this.setState({ isActive: instance })}
                            >
                                {translate(instance)}
                            </div>
                        )
                    })}
                </div>
                {this.instanceRender()}
            </Content>
        )
    }
}

const exportInstancesSchoolClassesSchedules = checkAvailableModule('instancesCatalog/schoolSchedule')
    ? InstancesSchoolClassesSchedules
    : null

export default exportInstancesSchoolClassesSchedules
