import { injectStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { radiusSizes } from 'blocks.app/config'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { colors } from 'theme'
import { textSizes } from 'theme/media'

const timetableAddStyles = {
    header: {
        ...grid.row,
        ...item.white,
        borderRadius: radiusSizes.mini,
    },
    headerContent: {
        ...grid.row,
        ...item.rel,
        ...grid.justify,
        ...grid.fullWidth,
        ...grid.p_mini,
        ...grid.pb_big,
    },
    days: {
        ...item.abs,
        ...grid.row,
        bottom: 5,
    },
    borderBottom: {
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles['border']})`,
    },
    title: {
        fontSize: textSizes.mdPlus,
        textTransform: 'capitalize',
        color: colors.darkBlue,
    },
    content: {
        ...grid.mt_md,
        ...grid.space,
        ...grid.col,
        ...grid.justify,
        ...item.rel,
        ...item.white,
        borderRadius: radiusSizes.mini,
        border: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles['border']})`,
    },
    table() {
        return {
            ...grid.space,
            height: 0,
            overflow: 'auto',
            '& table': {
                overflow: 'auto',
            },
            '& td': {
                borderBottom: 0,
                padding: '0px!important',
            },
            '& tbody tr:nth-child(odd)': {
                ...item.greyLight,
            },
            [`& tbody tr:hover .${this.deleteIcon}`]: {
                ...item.visible,
            },
        }
    },
    deleteIcon: {
        ...item.hidden,
    },
    footer: {
        ...grid.row,
        ...grid.center,
        ...grid.p_mini,
        ...item.shadowBlockTop,
        zIndex: 200,
    },
    lessonNumber: {
        ...grid.p_micro,
        ...grid.pt_microPlus,
        ...grid.pb_microPlus,
        fontSize: textSizes.md,
        ...item.greyLight,
    },
    lessonTime: {
        ...item.white,
        borderRight: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles['border']})`,
        maxWidth: 90,
        verticalAlign: 'top!important',
    },
    timeline: {
        ...grid.p_mini,
        textAlign: 'center',
        fontSize: textSizes.md,
        color: colors.darkBlue,
    },
    editing: {
        border: 0,
        outline: 'none',
        display: 'inline',
        textAlign: 'center',
        fontSize: textSizes.md,
        ...grid.fullWidth,
    },
    tableCell: {
        ...item.tableCell,
        color: colors.darkBlue,
    },
    editableCell: {
        ...grid.fullWidth,
    },
}

injectStyles(timetableAddStyles)
export default timetableAddStyles
