import React from 'react'
import Sidebar from 'blocks.app/sidebar/sidebar'
import translate from 'core/translate'
import { Conflicts } from '../../../pages/broadcasts/_add/broadcasts_add.local.methods'
import { Device } from '../../../pages/schedule/_add/schedule_add.local.methods'

interface IAddScheduleToolbar {
    devices: { id: number }[]
    conflicts: Conflicts[]
    onChangeDevices: (selected: Device[]) => void
}

const AddScheduleToolbar = (p_: IAddScheduleToolbar) => {
    return (
        <Sidebar
            selectedSide="right"
            conflicts={p_.conflicts}
            content={{
                right: {
                    title: translate('selectedDevices'),
                    options: p_.devices,
                },
            }}
            onChange={p_.onChangeDevices}
            isEmpty={{
                right: p_.devices && p_.devices.length === 0,
            }}
            emptyText={{
                right: {
                    title: translate('noSelectedDevices'),
                    description: translate('noSelectedDevicesDescription'),
                },
            }}
        />
    )
}

export default AddScheduleToolbar
