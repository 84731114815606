import { addStyles } from 'ethcss'
import { colors } from '../../../theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { mediaCSS, textSizes } from 'theme/media'
import { IStyles } from '../../../theme'

const filterListStyles: IStyles = {
    filtersBlock: {
        position: 'relative',
        zIndex: 1,
    },
    filterListWrapper: {
        position: 'relative',
        width: '80%',
        [mediaCSS.desktopS]: {
            width: '100%',
        },
    },
    filterList: {
        display: 'flex',
        alignItems: 'center',
        height: '60px',
        marginTop: '10px',
        transition: '0.4s',
    },
    filterItem: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px',
        height: '40px',
        padding: '12px 48px 12px 12px',
        borderRadius: '5px',
        backgroundColor: colors.white,
        maxWidth: '280px',
        overflow: 'hidden',
        flexShrink: 0,

        '&:after': {
            content: '""',
            border: '5px solid transparent',
            borderTop: `6px solid var(--var-module-app-component-menu-style-active, ${defaultThemeStyles.active})`,
            borderRadius: '3px',
            marginLeft: '14px',
            transform: 'translateY(3px)',
        },
    },
    deleteItem: {
        position: 'absolute',
        right: '10px',
        top: '10px',
        cursor: 'pointer',
    },
    addFilter: {
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        [mediaCSS.desktopS]: {
            display: 'none',
        },
    },
    tooltip: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingBottom: '1px',
    },
    container: {
        overflow: 'hidden',
    },
}

addStyles(filterListStyles)
export default filterListStyles
