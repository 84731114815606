import { useEffect, useState } from 'react'
import moment from 'moment'

const ScheduleTimerMethods = () => {
    const [timer, setTimer] = useState<string>('')

    useEffect(() => {
        tick()

        const clockTimer = setInterval(tick, 1000)

        return () => clearInterval(clockTimer)
    }, [])

    const tick = () => {
        setTimer(moment().format('HH : mm : ss'))
    }

    return { timer }
}

export default ScheduleTimerMethods
