import React from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Radio } from 'atoms/Radio'
import Collapse from 'blocks.simple/collapse/collapse'
import helpers from 'core/helpers'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { grid } from '../../../theme'
import { updateUserSettings } from 'blocks.app/user/user.state'
import styles from '../settings.jcss'

const viewOptions = ['simple', 'advanced']

const SettingsDeviceTileView = () => {
    const dispatch = useDispatch()
    const settings: any = useSelector<RootStateOrAny>((state) => state.user.data.settings)
    const lastIndex = viewOptions.length - 1

    return (
        <Collapse title={() => translate('viewOfTileDevices')} className={cn(styles.background, grid.mb_md)}>
            <div>
                {viewOptions.map((option, index) => (
                    <Radio
                        key={`device_tile_view_${option}`}
                        onClick={() => {
                            dispatch(updateUserSettings({ data: { deviceBlockStyle: option }, isSaveSettings: true }))
                        }}
                        selected={settings.deviceBlockStyle === option}
                        label={translate(option)}
                        disabled={!helpers.isAvailable({ key: 'settings', action: 'update' })}
                        className={cn({ [grid.mb_mini]: index !== lastIndex })}
                    />
                ))}
            </div>
        </Collapse>
    )
}

export default SettingsDeviceTileView
