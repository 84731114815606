import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { addStyles } from 'ethcss'
import { defaultThemeStyles } from '../item.theme'
import { colors } from 'theme'
import { textSizes } from 'theme/media'

const ItemDisplaysStyles = {
    wrapper() {
        return {
            ...grid.col,
            boxShadow: `var(--var-module-catalog-component-item-style-shadow, ${defaultThemeStyles.shadow})`,
            ...item.pointer,
            ...item.overNone,
            [`&:hover .${this.checkWrapper}`]: {
                opacity: 1,
            },
            [`&:hover .${this.infoLine}`]: {
                height: 64,
            },
        }
    },
    groupWrapper() {
        return {
            ...item.hover,
            ...item.pointer,
            [`&:hover .${this.checkWrapper}`]: {
                opacity: 1,
            },
        }
    },
    group: {
        ...grid.col,
        ...grid.full,
        boxShadow: `var(--var-module-catalog-component-item-style-shadow, ${defaultThemeStyles.shadow})`,
        ...item.cover,
        ...item.rel,
        ...item.overNone,
        zIndex: 10,
        height: '90%',
        top: '10%',
    },
    groupBackWrapper: {
        ...grid.full,
        ...grid.colCenter,
        ...item.abs,
        top: 0,
        left: 0,
        zIndex: 1,
    },
    groupBack: {
        ...grid.fullHeight,
        boxShadow: `var(--var-module-catalog-component-item-style-shadow, ${defaultThemeStyles.shadow})`,
        position: 'relative',
    },
    groupBack_first: {
        width: '94%',
        height: '90%',
        backgroundColor: `var(--var-module-catalog-component-item-style-backgroundSub, ${defaultThemeStyles.backgroundSub})`,
    },
    groupBack_second: {
        width: '88%',
    },
    offline: {
        backgroundColor: `var(--var-module-catalog-component-item-style-offline, ${defaultThemeStyles.offline})`,
    },
    online: { backgroundColor: `var(--var-module-catalog-component-item-style-online, ${defaultThemeStyles.online})` },
    error: { backgroundColor: `var(--var-module-catalog-component-item-style-error, ${defaultThemeStyles.error})` },
    online_display_off: {
        background: '#005939',
        backgroundSize: 'cover',
    },
    offlineText: { color: `var(--var-module-catalog-component-item-style-offline, ${defaultThemeStyles.offline})` },
    onlineText: { color: `var(--var-module-catalog-component-item-style-online, ${defaultThemeStyles.online})` },
    errorText: { color: `var(--var-module-catalog-component-item-style-error, ${defaultThemeStyles.error})` },
    noBroadcastText: {
        color: `var(--var-module-catalog-component-item-style-textMain, ${defaultThemeStyles.textMain})`,
    },
    content: {
        ...grid.space,
        ...grid.colCenter,
        position: 'relative',
    },
    registrationCode: {
        ...grid.full,
        ...item.abs,
        ...grid.colCenter,
        ...grid.center,
        fontWeight: 'bold',
        ...item.whiteColor,
    },
    registrationCodeSimple: {
        ...grid.mt_micro,
        ...item.whiteColor,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: textSizes.normalPlus,
    },
    simpleWrapper: {
        ...grid.rowCenter,
        ...grid.full,
    },
    checkWrapper: {
        ...item.abs,
        ...item.transitionErase,
        top: grid.p_micro.padding,
        right: grid.p_micro.padding,
        zIndex: 2,
        opacity: 0,
    },
    loading: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.noWrap,
        color: colors.white,
        fontSize: textSizes.mini,
    },
    progress: {
        ...grid.mr_micro,
        ...grid.normalCenter,
        ...grid.row,
        width: 20,
        height: 20,
    },
    infoLine: {
        ...item.abs,
        ...grid.fullWidth,
        ...grid.p_microPlus,
        ...grid.justify,
        ...grid.row,
        ...grid.noWrap,
        color: colors.white,
        fontSize: textSizes.normal,
        ...item.transitionEraseHeight,
        bottom: 0,
        left: 0,
        height: 36,
    },
    infoLineProgress: {
        visibility: 'visible',
    },
    orientationIcon: {
        ...grid.mr_mini,
    },
}

addStyles(ItemDisplaysStyles)
export default ItemDisplaysStyles
