import React from 'react'
import AdvertisementViewHooks, { Device, IAdvBlockProps, IGrid } from './AdvertisementViewHooks'
import Icon from 'blocks.simple/icon/icon'
import Collapse from 'blocks.simple/collapse/collapse'
import EditText from 'blocks.simple/editText/editText'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './advertisementView.jcss'
import { colors, grid, item } from 'theme'
import { text as textStyles } from 'theme'
import Content from 'blocks.app/content/content'
import moment from 'moment'
import Button from '../../blocks.simple/button/button'
import LoaderItem from '../../blocks.app/loader/__item/loader__item'

interface IDeviceComponent {
    device: Device
    open?: boolean
}

const DeviceComponent = (p_: IDeviceComponent) => (
    <div className={styles.device}>
        <div className={cn(grid.rowCenter, grid.noWrap)}>
            <div className={cn(styles.displayIconWrapper)}>
                <Icon type={'display'} color="blackLight" size={18} />
            </div>
        </div>
        <div className={cn(grid.rowCenter, grid.spaceWidth)}>
            <span className={cn(styles.deviceTitle)}>{p_.device.name}</span>
        </div>
    </div>
)

const EventBlock = ({ event }: any) => {
    const file = event.file.source
    const date1 = moment(event.file.startTime)
    const date2 = moment(event.file.endTime)
    const diff = date2.diff(date1, 'seconds')
    const minutes = Math.floor(diff / 60)
    const seconds = diff - minutes * 60
    const isBroadcast = event.type === 'broadcast' && !event.file.type
    const insertionType = event.insertionType || event.file.insertionType

    return (
        <div
            className={cn(styles.eventBlock, insertionType === 'onPause' ? styles.withBorder : '')}
            onClick={() => {
                if (!isBroadcast && event.onClick) {
                    event.onClick(event.eventStartTime)
                }
            }}
        >
            <div
                className={styles.eventIcon}
                style={{
                    borderColor: isBroadcast
                        ? colors.grey
                        : insertionType === 'replaceBroadcastContent'
                        ? 'transparent'
                        : event.color,
                }}
            >
                {insertionType === 'replaceBroadcastContent' && (
                    <img
                        className={styles.eventIconImageBorder}
                        src={
                            event.color === '#2582E0'
                                ? 'images/advertisement/replace_border_active.png'
                                : 'images/advertisement/replace_border.png'
                        }
                    />
                )}
                <Icon
                    type={isBroadcast ? event.file.source.fileType : 'advertisement'}
                    color={isBroadcast ? colors.grey : event.color}
                    size={33}
                />
            </div>
            <div className={styles.eventContent}>
                <div className={styles.eventContentName}>{file.name}</div>
                <div className={styles.eventContentType}>{event.title}</div>
                <div className={styles.eventAdvRow}>{translate(file.fileType)}</div>
                <div className={styles.eventAdvRow}>
                    {translate('time')}:{' '}
                    <span>
                        {moment(event.file.startTime).format('HH:mm:ss')} –{' '}
                        {moment(event.file.endTime).format('HH:mm:ss')}
                    </span>
                </div>
            </div>
            <div className={styles.eventContentDuration}>
                {minutes} {translate('min')} {seconds} {translate('sec')}
            </div>
        </div>
    )
}

export const AdvertisementView = (p_: IAdvBlockProps) => {
    const {
        s_,
        setState,
        onChangeDate,
        onChangeInterval,
        onChangeStartTime,
        generateGrid,
        adGrid,
        selectedAdBlock,
        setSelectedAdBlock,
        devices,
        showError,
        getTimeInterval,
    } = AdvertisementViewHooks()
    const { selectedDevice } = s_

    const renderGrid = (grid: IGrid) => {
        const list: any = []
        const contentList: any = []
        const broadcasts = grid.broadcasts
        const advertisements = grid.advertisements
        const insertionType = advertisements.length && advertisements[0].insertionType

        if (insertionType === 'betweenBroadcast' || insertionType === 'replaceBroadcastContent') {
            broadcasts.forEach((broadcast: any) => {
                const startTime: any = moment(broadcast.startTime)

                advertisements.forEach((advertisement: any) => {
                    const advEndTime: any = moment(advertisement.endTime)

                    if (
                        advEndTime <= startTime &&
                        !list.some((event: any) => JSON.stringify(event) === JSON.stringify(advertisement))
                    ) {
                        list.push(advertisement)
                    }
                })
                list.push(broadcast)
            })
            const advertisementsInListLength = list.filter((advertisement: any) => advertisement.insertionType).length
            if (advertisementsInListLength < advertisements.length) {
                list.push(advertisements[advertisements.length - 1])
            }

            list.forEach((event: any) => {
                event.content.forEach((file: any, index: number) => {
                    const isAdvertisement = event.insertionType
                    const eventStartTime = event.startTime
                    const color =
                        isAdvertisement && selectedAdBlock === eventStartTime ? colors.blueAccent : colors.blackLight

                    contentList.push(
                        <EventBlock
                            key={index + Math.random()}
                            event={{
                                file,
                                title: event.title,
                                type: event.insertionType ? 'advertisement' : 'broadcast',
                                insertionType: event.insertionType,
                                startTime: event.startTime,
                                endTime: event.endTime,
                                color,
                                id: event.id,
                                eventStartTime,
                                onClick: setSelectedAdBlock,
                            }}
                        />
                    )
                })
            })
        }
        if (insertionType === 'onPause') {
            broadcasts.forEach((broadcast: any) => {
                const startTime: any = moment(broadcast.startTime)
                const endTime: any = moment(broadcast.endTime)
                const advertisementsInBroadcast: any = []
                const content = broadcast.content.map((content: any) => {
                    return {
                        ...content,
                        eventTitle: broadcast.title,
                    }
                })
                let sortedContent = []

                advertisements.forEach((advertisement: any) => {
                    const advStartTime: any = moment(advertisement.startTime)
                    const advEndTime: any = moment(advertisement.endTime)

                    if (advStartTime >= startTime && advEndTime <= endTime) {
                        advertisementsInBroadcast.push(advertisement)
                    }
                })
                advertisementsInBroadcast.forEach((advertisement: any) => {
                    advertisement.content.forEach((contentElement: any) => {
                        content.push({
                            ...contentElement,
                            type: 'advertisement',
                            eventTitle: advertisement.title,
                            insertionType: advertisement.insertionType,
                            id: advertisement.id,
                            eventStartTime: advertisement.startTime,
                        })
                    })
                })
                sortedContent = content.sort((a: any, b: any) => {
                    if (moment(a.endTime) <= moment(b.startTime)) {
                        return -1
                    }
                    return 1
                })

                list.push({
                    startTime,
                    endTime,
                    content: sortedContent,
                })
            })

            list.forEach((event: any) => {
                contentList.push(
                    <div className={styles.broadcastWrapper}>
                        {event.content.map((content: any) => {
                            const eventStartTime = content.eventStartTime
                            const isAdvertisement = content.insertionType
                            const color =
                                isAdvertisement && selectedAdBlock === eventStartTime
                                    ? colors.blueAccent
                                    : colors.blackLight

                            return (
                                <EventBlock
                                    event={{
                                        file: content,
                                        title: content.eventTitle,
                                        type: event.insertionType ? 'advertisement' : 'broadcast',
                                        startTime: event.startTime,
                                        endTime: event.endTime,
                                        color,
                                        eventStartTime,
                                        offset: grid.offset,
                                        onClick: setSelectedAdBlock,
                                    }}
                                />
                            )
                        })}
                    </div>
                )
            })
        }

        return contentList.length ? contentList : null
    }

    const renderTimeLine = (grid: any) => {
        if (!grid.broadcasts.length) return

        const timeLineLength = getTimeInterval(grid)
        const insertionType = grid.advertisements && grid.advertisements.length && grid.advertisements[0].insertionType
        const backgroundColor =
            insertionType === 'replaceBroadcastContent' ? 'url("images/advertisement/replace_bg.svg")' : '#858585'
        const backgroundColorActive =
            insertionType === 'replaceBroadcastContent'
                ? 'url("images/advertisement/replace_bg_active.svg")'
                : colors.blueAccent

        return (
            <div className={styles.timeLine}>
                <div className={styles.timeLineHeader}>
                    <div className={styles.insertionTypeInfo}>
                        {insertionType ? (
                            <>
                                {translate('insertionType')}: <span>{translate(insertionType).toLowerCase()}</span>
                            </>
                        ) : null}
                    </div>
                    <div className={styles.legend}>
                        <div className={styles.legendBlock}>
                            <div className={cn(styles.legendColor, styles.legendAdvertisementColor)} />
                            <div>{translate('advertisement')}</div>
                        </div>
                        <div className={styles.legendBlock}>
                            <div className={cn(styles.legendColor, styles.legendBroadcastColor)} />
                            <div>{translate('broadcast')}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.timeLineInner}>
                    <div className={styles.broadcastsInner}>
                        {grid.broadcasts.map((broadcast: any, index: number) => {
                            const startTime = moment(broadcast.startTime)
                            const endTime = moment(broadcast.endTime)
                            const diff = endTime.diff(startTime, 'seconds')
                            const prevBroadcast = index !== 0 && grid.broadcasts[index - 1]
                            const gap = moment(startTime).diff(
                                moment(prevBroadcast ? prevBroadcast.endTime : grid.startTime),
                                'seconds'
                            )

                            return (
                                <div
                                    className={styles.timeLineBroadcast}
                                    style={{
                                        minWidth: `${(diff / timeLineLength) * 100}%`,
                                        marginLeft: `${(gap / timeLineLength) * 100}%`,
                                    }}
                                />
                            )
                        })}
                    </div>
                    {grid.advertisements.map((advertisement: any) => {
                        const startTime = moment(advertisement.startTime)
                        const endTime = moment(advertisement.endTime)
                        const diff = endTime.diff(startTime, 'seconds')
                        const gridStartTime = moment(grid.startTime)
                        const diff_ = startTime.diff(gridStartTime, 'seconds')
                        let leftValue = (diff_ / timeLineLength) * 100
                        let minWidth = (diff / timeLineLength) * 100

                        if (leftValue < 0) {
                            minWidth = (diff / timeLineLength) * 100 + leftValue

                            leftValue = 0
                        }

                        return (
                            <div
                                className={styles.timeLineAdvertisement}
                                style={{
                                    minWidth: `${minWidth}%`,
                                    left: `${leftValue}%`,
                                    background:
                                        selectedAdBlock === advertisement.startTime
                                            ? backgroundColorActive
                                            : backgroundColor,
                                }}
                                onClick={() => setSelectedAdBlock(advertisement.startTime)}
                            >
                                <div className={styles.timeLineBlockPupUp}>
                                    <div className={styles.pupUpTriangle} />
                                    <div className={styles.pupUpIcon}>
                                        <Icon type={'advertisement'} color={colors.white} size={16} />
                                    </div>
                                    <div>
                                        <div className={styles.pupUpText}>{advertisement.title}</div>
                                        <div className={styles.pupUpText}>
                                            {startTime.format('HH:mm:ss')}–{endTime.format('HH:mm:ss')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {renderLineScale(grid)}
            </div>
        )
    }

    const renderLineScale = (grid: IGrid) => {
        const bigLineShiftFactor = 100 / 12
        const smallLineShiftFactor = 20
        const bigLineCount = 12
        const smallLinesCount = 6
        const bigLines = []
        const smallLines: any = []
        const startTime = moment(grid.startTime)
        const endTime = moment(grid.endTime)
        const diff = endTime.diff(startTime, 'seconds')
        const stepFactor = diff / bigLineCount

        for (let i = 0; i < smallLinesCount; i++) {
            smallLines.push(
                <div
                    className={cn(styles.smallLine, i === smallLinesCount - 1 || i === 0 ? styles.noBorder : '')}
                    style={{ left: `${smallLineShiftFactor * i}%` }}
                />
            )
        }
        for (let i = 0; i < bigLineCount; i++) {
            bigLines.push(
                <div
                    className={styles.bigLine}
                    style={{
                        left: `${bigLineShiftFactor * i}%`,
                        width: `${bigLineShiftFactor}%`,
                    }}
                >
                    {smallLines}
                    {i % 2 !== 0 && i !== bigLineCount - 1 && (
                        <div className={cn(styles.timeValue, styles.timeCommon)}>
                            {moment(startTime)
                                .add('seconds', stepFactor * (i + 1))
                                .format('HH:mm')}
                        </div>
                    )}
                </div>
            )
        }

        return (
            <div className={styles.lineScale}>
                {bigLines}
                <div className={cn(styles.timeValue, styles.startTimeValue)}>
                    {moment(grid.startTime).format('HH:mm')}
                </div>
                <div className={cn(styles.timeValue, styles.endTimeValue)}>{moment(grid.endTime).format('HH:mm')}</div>
            </div>
        )
    }

    return (
        <Content title={translate('settings')} wrapperClassName={styles.settingsWrapper}>
            <div className={styles.wrapper}>
                <div className={styles.devices}>
                    <div className={cn(styles.title, grid.pl_mini)}>{translate('devices')}</div>
                    {devices.map((device: Device) => {
                        return (
                            <Collapse
                                showArrow={false}
                                onClick={() => {
                                    setState((prevState) => {
                                        return {
                                            ...prevState,
                                            selectedDevice: device,
                                        }
                                    })
                                }}
                                disabled={true}
                                key={device.id}
                                toggleIconOptions={{
                                    containerClass: cn(styles.circleButton),
                                    size: 10,
                                }}
                                title={(p_: { open: boolean }) => <DeviceComponent open={false} device={device} />}
                                titleClassName={cn(styles.deviceWrapper, {
                                    [styles.deviceWrapperOver]: selectedDevice && selectedDevice.id === device.id,
                                })}
                                contentPadding={false}
                                contentClassName={cn(styles.collapseContent)}
                                children={null}
                            ></Collapse>
                        )
                    })}
                </div>
                <div className={styles.content}>
                    <div className={cn(styles.title, s_.gridInProgress ? styles.disabled : '')}>
                        <div className={cn(grid.space, grid.rowCenter, grid.noWrap, styles.titleBlock)}>
                            <div className={cn(grid.pl_mini, grid.spaceWidth, grid.row)}>
                                {selectedDevice && (
                                    <span className={item.ellipsis}>
                                        {translate('broadcastGridUsedForDevice')}
                                        &nbsp;
                                        <span className={textStyles.darkCloud}>
                                            {selectedDevice && selectedDevice.name}
                                        </span>
                                    </span>
                                )}
                                {!selectedDevice && (
                                    <span className={item.ellipsis}>{translate('deviceNotSelected')}</span>
                                )}
                            </div>
                        </div>
                        <div className={styles.inputWrapper}>
                            <div className={cn(grid.w30)}>
                                <EditText
                                    className={textStyles.left}
                                    type="date"
                                    dateValue={s_.date}
                                    onChange={onChangeDate}
                                    textSize="mini"
                                    indentSize="mini"
                                    rounded="full_mini"
                                    label={translate('date')}
                                    minDate={moment().toDate()}
                                />
                            </div>
                            <div className={grid.w30}>
                                <EditText
                                    className={textStyles.left}
                                    type="time"
                                    value={s_.startTime}
                                    onChange={onChangeStartTime}
                                    textSize="mini"
                                    indentSize="mini"
                                    rounded="full_mini"
                                    label={translate('startTime')}
                                    icon={'time'}
                                    iconSize={18}
                                    iconClassName={styles.iconClassName}
                                />
                            </div>
                            <div className={cn(grid.w30, styles.relativeWrapper)}>
                                <EditText
                                    className={textStyles.left}
                                    type="number"
                                    value={s_.interval}
                                    onChange={onChangeInterval}
                                    textSize="mini"
                                    indentSize="mini"
                                    rounded="full_mini"
                                    label={translate('interval')}
                                    icon={'time'}
                                    iconSize={18}
                                    wrapperClassName={styles.input}
                                />
                                <div className={styles.intervalFormat}>
                                    <div
                                        className={cn(
                                            styles.intervalFormatOption,
                                            s_.intervalFormat === 'sec' ? styles.intervalFormatOptionSelected : ''
                                        )}
                                        onClick={() => {
                                            setState((prevState) => {
                                                return {
                                                    ...prevState,
                                                    intervalFormat: 'sec',
                                                }
                                            })
                                        }}
                                    >
                                        {translate('sec')}
                                    </div>
                                    <div
                                        className={cn(
                                            styles.intervalFormatOption,
                                            s_.intervalFormat === 'min' ? styles.intervalFormatOptionSelected : ''
                                        )}
                                        onClick={() => {
                                            setState((prevState) => {
                                                return {
                                                    ...prevState,
                                                    intervalFormat: 'min',
                                                }
                                            })
                                        }}
                                    >
                                        {translate('min')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectedDevice && (
                            <div className={styles.generateGridBtn} onClick={generateGrid}>
                                <Button mod="fill">{translate('generateBroadcastGrid')}</Button>
                            </div>
                        )}
                    </div>
                    {s_.gridInProgress && (
                        <div className={styles.loading}>
                            <div className={styles.loadingText}>{translate('broadcastGridInProgress')}</div>
                            <LoaderItem inv={true} />
                        </div>
                    )}
                    {showError && <div className={styles.noBroadcasts}>{showError}</div>}
                    {adGrid && renderTimeLine(adGrid)}
                    <div>{adGrid && renderGrid(adGrid)}</div>
                </div>
            </div>
        </Content>
    )
}
