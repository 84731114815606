import React, { FunctionComponent, memo } from 'react'
import { IVideoChatModalContentMinimizedComponent } from './VideoChatModalContentMinimized-types'
import { styles } from './VideoChatModalContentMinimized-styles'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { VideoChatModalVideo } from '../VideoChatModalVideo'
import { colors } from 'theme/colors'
import Icon from 'blocks.simple/icon/icon'

const VideoChatModalContentMinimizedComponent: FunctionComponent<IVideoChatModalContentMinimizedComponent> = ({
    onExpand,
    videoCallData,
    onVideoCallEnd,
}) => {
    const handleVideoCallEnd = (e: React.MouseEvent) => {
        onVideoCallEnd()
    }

    return (
        <div className={styles.videoChatModalContentMinimized}>
            <div className={styles.videoChatModalContentMinimized__content}>
                <div className={styles.videoChatModalContentMinimized__header}>
                    <div className={styles.videoChatModalContent__close} onClick={onExpand}>
                        <Icon type="videoWindow" color={colors.white} size={22} />
                    </div>
                </div>
                <div className={styles.videoChatModalContentMinimized__video}>
                    {videoCallData && videoCallData.video ? (
                        <VideoChatModalVideo id={'videoChatModalVideo'} srcObject={videoCallData.video} />
                    ) : (
                        <div className={styles.videoChatModalContentMinimized__text}>{translate('videoChatVideo')}</div>
                    )}
                </div>
                <div className={styles.videoChatModalContentMinimized__footer}>
                    <div className={styles.videoChatModalContentMinimized__actions}>
                        <Button
                            mod="error"
                            className={cn(styles.videoChatModalContentMinimized__button)}
                            onClick={handleVideoCallEnd}
                        >
                            Завершить
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const VideoChatModalContentMinimized = memo(VideoChatModalContentMinimizedComponent)
