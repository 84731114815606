import { addStyles } from 'ethcss'
import { IStyles } from 'theme'

export const styles: IStyles = {
    list: {
        display: 'inline-block',
        listStyle: 'disc!important',
        lineHeight: 1.5,
    },
    listItem: {
        textAlign: 'left',
    },
}

addStyles(styles)
export default styles
