import React, { FunctionComponent, memo } from 'react'
import { audioTrackItemStyles } from './AudioTrackItem-styles'
import { IAudioTrackItemComponent } from './AudioTrackItem-types'
import { cn } from 'ethcss'
import { cutFileName } from 'core/utils/coreUtil'
import { AudioPlaceholder } from 'atoms/AudioPlaceholder'

const AudioTrackItemComponent: FunctionComponent<IAudioTrackItemComponent> = ({
    id,
    title,
    duration,
    isActive = false,
}) => {
    return (
        <div className={cn(audioTrackItemStyles.AudioItem)}>
            {isActive ? (
                <div className={audioTrackItemStyles.AudioItem__equalizer}>
                    <AudioPlaceholder className={audioTrackItemStyles.AudioItem__icon} size={12} />
                </div>
            ) : (
                <div className={audioTrackItemStyles.AudioItem__fakeIcon}>&bull;</div>
            )}
            <div
                className={cn(audioTrackItemStyles.AudioItem__content, {
                    [audioTrackItemStyles.AudioItem__content_active]: isActive,
                    [audioTrackItemStyles.AudioItem__content_disable]: !isActive,
                })}
            >
                <div className={audioTrackItemStyles.AudioItem__title}>{title}</div>
                {/*<div className={audioTrackItemStyles.AudioItem__time}>{duration}</div>*/}
            </div>
        </div>
    )
}

export const AudioTrackItem = memo(AudioTrackItemComponent)
