import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { IStyles } from '../../../theme'

const tableEditorSizeStyles: IStyles = {
    inputWrapper: {
        ...grid.w45,
    },
    selectSizeType: {
        ...grid.w100,
    },
    row: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
        ...grid.p_md,
        ...grid.pb_none,
    },
}

addStyles(tableEditorSizeStyles)
export default tableEditorSizeStyles
