import config from 'core/config'
import translate from 'core/translate'
import { defaultFilesIconsTheme } from 'core/helpers/FilesIcons-theme'
import { getThemeIconValue } from 'theme/colors'
import { isHaveLength, isNumeric } from 'core/utils/coreUtil'
import store from 'core/store'
import helpers from '../index'

export default {
    isEqual(firstItem: any, secondItem: any) {
        return firstItem.id === secondItem.id && firstItem.type === secondItem.type
    },
    getPlatformName(platform: string) {
        if (platform) {
            const platformSplit = platform.split(' ')

            if (platformSplit.length) {
                const platformName = platformSplit[0].toLowerCase()
                return platformName === 'raspberry_v4' ? 'raspberry' : platformName
            }
        }
    },
    getDownloadLink(selectedList: any[], params: string) {
        let downloadLink = null
        const list = selectedList
        if (list.length > 0) {
            downloadLink = `${config.api.root}v2/download/digital-signage?${params}`

            const digitalSignageId = ['']
            const groupId = ['']
            list.forEach((listItem) => {
                if (listItem.type === 'group') {
                    groupId.push(listItem.id)
                } else {
                    digitalSignageId.push(listItem.id)
                }
            })

            if (digitalSignageId.length > 1) {
                downloadLink += digitalSignageId.join('&digitalSignageId[]=')
            }
            if (groupId.length > 1) {
                downloadLink += groupId.join('&groupId[]=')
            }
        }

        return downloadLink
    },
    availablePlatforms(platform: string) {
        const current = this.getPlatformName(platform)
        const platforms = ['sssp', 'tizen', 'android', 'ios', 'windows', 'linux', 'webos', 'raspberry']

        return current ? platforms.includes(current) : false
    },
    availableWakeOnLan(platform: string) {
        const appType = store.getState().app.appType
        if (appType !== 'personal') return false

        const current = this.getPlatformName(platform)
        const platforms = ['sssp', 'android', 'windows', 'linux', 'webos']

        return current ? platforms.includes(current) : false
    },
    getAvailableCommands(item: any) {
        const allCommands = ['shutdown', 'reboot', 'restartApp', 'powerUp']

        if (!item) {
            return []
        }

        if (item.type === 'group') {
            return allCommands
        }

        if (!item.hasOwnProperty('availableCommands')) {
            return ['shutdown', 'reboot']
        }

        const available = item.availableCommands

        if (!available) {
            return allCommands
        }

        let result = []
        for (let key in available) {
            if (available.hasOwnProperty(key)) {
                if (available[key]) {
                    result.push(key)
                }
            }
        }

        return result
    },
    isFolder(item: any) {
        return item.type === 'group'
    },
    createDisplay(id: number, name: string) {
        return {
            id,
            name,
            type: 'digitalSignage',
        }
    },
    createDisplaysFolder(id: number, name: string) {
        return {
            id,
            name,
            type: 'group',
        }
    },
    getOrientation(orientation: number) {
        const orientations = this.getOrientations()

        return orientations.find((item) => item.id === orientation)
    },
    getOrientations() {
        return [
            {
                id: 0,
                name: translate('orientationH'),
                fullName: translate('horizontalOrientation'),
            },
            {
                id: 90,
                name: translate('orientationV'),
                fullName: translate('verticalOrientation'),
            },
            {
                id: 180,
                name: translate('orientationInvertedH'),
                fullName: translate('invertedHorizontalOrientation'),
            },
            {
                id: 270,
                name: translate('orientationInvertedV'),
                fullName: translate('invertedVerticalOrientation'),
            },
        ]
    },
    getNoBroadcastIconSrc() {
        return getThemeIconValue('displays', 'broadcast', 'noBroadcast') || defaultFilesIconsTheme.noBroadcast
    },
    isValidApproveToken(code: string) {
        return isNumeric(code) && isHaveLength(code, 9)
    },
    getApproveTokenWithMask(code: string) {
        return code.split('').reduce((result, char, index) => {
            return index % 3 === 0 ? result + `-${char}` : result + char
        })
    },
    getApproveToken(value: string) {
        let approveTokenChunks = value.match(/\d+/g)

        if (!approveTokenChunks) return null

        const approveToken = approveTokenChunks.join('')

        if (!this.isValidApproveToken(approveToken)) return null

        return approveToken
    },
    isValidApproveTokenWithMask(approveTokenWithMask: string) {
        if (!approveTokenWithMask) return false

        const approveToken = this.getApproveToken(approveTokenWithMask)

        return approveToken && this.isValidApproveToken(approveToken)
    },
    checkActualLicense(licenseId: number | undefined | null, licenses: any) {
        if (!licenses) return false
        const selectedLicence = licenses.find((license: any) => licenseId === license.id)
        if (!selectedLicence) return false
        const expiredDate = selectedLicence.expiredAt
        if (!selectedLicence.activeQ) return false
        if (expiredDate) {
            const daysLeft = helpers.dateDifference(expiredDate)

            if (!daysLeft) {
                return false
            }
        }

        return true
    },
    isCheckLicenseQ() {
        const state = store.getState()
        const checkLicense = state.user.company?.checkLicenseQ

        return checkLicense
    },
}
