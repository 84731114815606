import React from 'react'
import styles from './email.jcss'
import translate from '../../../../core/translate'
import Checkbox from '../../../../blocks.simple/checkbox/checkbox'
import Icon from '../../../../blocks.simple/icon/icon'
import moment from 'moment'

export interface IEmail {
    id: number
    from: string
    to: string
    message: { html: string; subject: string }
    status: string
    error: string
    transport: string
    statusUpdatedAt: string
}

export const Email = ({ email, selected, onSelect, onDelete, onDetails, getStatus }: any) => {
    return (
        <div className={styles.email}>
            <div className={styles.leftBlock}>
                <Checkbox
                    onClick={(selected: boolean) => {
                        onSelect(email.id, selected)
                    }}
                    checked={selected}
                />
                <div className={styles.status}>
                    <img src={`images/notification-center/email/${email.status}.svg`} />
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.statusMessage}>
                    <b>{getStatus(email.status)}</b>
                </div>
                <div className={styles.subject}>{email.message.subject}</div>
                <div
                    className={styles.body}
                    dangerouslySetInnerHTML={{
                        __html: email.message.html,
                    }}
                />
                <div className={styles.details} onClick={() => onDetails(email)}>
                    {translate('moreDetails')}
                </div>
            </div>
            <div className={styles.rightBlock}>
                <Icon
                    type={'delete'}
                    className={styles.delete}
                    size={16}
                    tooltip={{ title: translate('delete') }}
                    onClick={() => onDelete(email.id)}
                />
                <div className={styles.dateTime}>{moment(email.statusUpdatedAt).format('HH:mm DD.MM.YYYY')}</div>
            </div>
        </div>
    )
}
