import { addStyles } from 'ethcss'
import { IStyles } from '../../../theme'

const deviceSettingsPinCodeStyles: IStyles = {
    input: {
        textAlign: 'center',
    },
}

addStyles(deviceSettingsPinCodeStyles)
export default deviceSettingsPinCodeStyles
