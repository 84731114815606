import { Typography } from 'atoms/Typography'
import Icon from 'blocks.simple/icon/icon'
import translate from 'core/translate'
import React, { FC, useState } from 'react'
import { getThemeStyleValue } from 'theme/colors'
import { DistributivesHeaderProps } from './DistributivesHeader-types'
import { styles } from './DistributivesHeader-styles'
import Button from 'blocks.simple/button/button'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { Upload } from 'pages/firmwareList/__upload/Upload'
import { FirmwareListHooks } from 'pages/firmwareList/FirmwareListHooks'

export const DistributivesHeader: FC<DistributivesHeaderProps> = ({ onUpdate }) => {
    const { isOpen, onOpen, onClose, isUploading, uploadClientApp } = FirmwareListHooks(null)

    return (
        <div className={cn(styles.distributivesHeader, grid.flex, grid.justify)}>
            <div
                className={styles.distributivesHeader__button}
                onClick={onUpdate}
                data-testid="distributivesHeader_button"
            >
                <Icon type="refresh" color={getThemeStyleValue('ui', 'icon', 'active')} />
                <Typography type="promo" className={styles.distributivesHeader__text}>
                    {translate('distributives')}
                </Typography>
            </div>
            <Button className={styles.uploadFirmwareBtn} mod={'fill'} onClick={onOpen}>
                {translate('uploadClientApp')}
            </Button>
            {isOpen && (
                <Upload isUploadClientApp onSave={uploadClientApp} onClose={onClose} isUploading={isUploading} />
            )}
        </div>
    )
}
