import { api } from 'core/api/ConnectionManager'
import { displaysAddActions } from '../__add/displays__add.state'
import store from 'core/store'
import { displaysAddGroupActions } from '../__addGroup/displays__addGroup.state'
import { displaysActions } from '../displays.state'
import { ISelectedInfo } from '../__info/displays__info.local.methods'

class displaysToolbarMethods {
    edit(displayItem: ISelectedInfo) {
        if (displayItem.tags) {
            displayItem.tags = displayItem.tags.map((tag) => {
                tag.label = tag.name
                tag.value = tag.id

                return tag
            })
        }
        store.dispatch(
            displaysAddActions.setData({
                data: {
                    overwriteSettings: false,
                    groupScheduleMode: 'ignore',
                    ...displayItem,
                },
                isEdit: true,
            })
        )

        store.dispatch(displaysActions.openModal({ modalState: 'add', modalOptions: {} }))
    }
    editWithoutAddress(editItem: { id: number }) {
        api.send('getDisplay', { id: editItem.id }).then((item: any) => {
            if (item.tags) {
                item.tags = item.tags.map((tag: { id: string; name: string; label: string; value: string }) => {
                    tag.label = tag.name
                    tag.value = tag.id

                    return tag
                })
            }
            store.dispatch(
                displaysAddActions.setData({
                    data: {
                        overwriteSettings: false,
                        groupScheduleMode: 'ignore',
                        ...item,
                    },
                    isEdit: true,
                })
            )

            store.dispatch(displaysActions.openModal({ modalState: 'add', modalOptions: {} }))
        })
    }
    editGroup(groupItem: ISelectedInfo) {
        if (groupItem.groupId) {
            groupItem.parentId = groupItem.groupId
        }
        store.dispatch(
            displaysAddGroupActions.setData({
                data: { ...groupItem, scheduleIdToDelete: [] },
                isEdit: true,
                error: null,
            })
        )

        store.dispatch(displaysActions.openModal({ modalState: 'addGroup', modalOptions: {} }))
    }
}

export default new displaysToolbarMethods()
