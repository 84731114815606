import { addStyles } from 'ethcss'

const displaysMapStyles = {
    map: {
        height: '100vh'
    }
}

addStyles(displaysMapStyles)
export default displaysMapStyles
