import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { cols, layers, mediaCSS } from 'blocks.app/config'
import { defaultThemeStyles } from './content.theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { colors, IStyles } from '../../theme'

const contentStyles: IStyles = {
    wrapper: {
        //...item.transitionErase,
        ...grid.fullHeight,
        ...item.rel,
        minHeight: '100%',
    },
    wrapper_x: {
        overflow: 'auto',
    },
    wrapper_height_for_lazyLoad: {
        ...item.transitionErase,
        ...item.rel,
        minHeight: '100%',
    },
    settings_content_padding_top_x: {
        paddingTop: '85px !important',
    },
    leftIndent: {
        //...item.transitionErase,
        left: `${cols.normal}%`,
        [mediaCSS.tab]: {
            left: 0,
        },
    },
    leftIndent_x: {
        ...item.transitionErase,
        left: 0,
        [mediaCSS.tab]: {
            left: 0,
        },
    },
    leftIndentSm: {
        ...item.transitionErase,
        left: `${cols.mini}%`,
    },
    fullscreen: {
        left: 0,
        width: '100% !important',
    },
    fullWidth: {
        ...grid.w100,
    },
    col_x: {
        ...grid.w100,
    },
    col1: {
        width: `${100 - cols.normal}%`,
        [mediaCSS.tab]: {
            ...grid.w100,
        },
    },
    col2: {
        width: `${100 - 2 * cols.normal}%`,
        [mediaCSS.tab]: {
            ...grid.w100,
        },
    },
    col2_x: {
        ...grid.w85,
        [mediaCSS.tab]: {
            ...grid.w100,
        },
    },
    col1Sm: {
        width: `${100 - cols.mini}%`,
    },
    col2Sm: {
        width: `${100 - cols.normal - cols.mini}%`,
    },
    content: {
        ...grid.col,
        ...grid.space,
        ...item.rel,
        minHeight: '100%',
    },
    back: {
        background: `var(--var-module-app-component-content-style-background, ${defaultThemeStyles.background})`,
        '& *': {
            fontFamily: `var(--var-module-app-component-app-style-fontFamily, ${defaultThemeAppStyles.fontFamily})!important`,
        },
    },
    backCustomTheme: {
        background: colors.greyLight,
        '& *': {
            fontFamily: `var(--var-module-app-component-app-style-fontFamily, ${defaultThemeAppStyles.fontFamily})!important`,
        },
    },
    backAdBlocks: {
        background: colors.white,
    },
    draggable: {
        ...item.abs,
        zIndex: 1100,
        top: grid.p_mdPlus.padding,
        right: grid.p_mdPlus.padding,
    },
    breadcrumbs: {
        marginRight: -grid.m_mdPlus['margin'],
        marginTop: -grid.m_mdPlus['margin'],
        marginLeft: -grid.m_mdPlus['margin'],
        [mediaCSS.tabMini]: {
            marginRight: -grid.m_mini['margin'],
            marginTop: -grid.m_mini['margin'],
            marginLeft: -grid.m_mini['margin'],
        },
    },
    toolbar: {
        ...grid.fullHeight,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        background: colors.white,
        borderLeft: `1px solid var(--var-module-app-component-contentBorder-style-background, ${defaultThemeStyles.toolbarBorder})`,
        ...grid.col,
        width: `${cols.normal}%`,
        overflowY: 'auto',
        zIndex: layers.sticky + 1,
        [mediaCSS.tab]: {
            ...item.transitionErase,
            zIndex: layers.sticky + 1,
            ...grid.w50,
            top: 44,
            maxWidth: 0,
            paddingBottom: 100,
        },
    },
    toolbar_x: {
        top: 64,
        height: 'calc(100vh - 63px)',
    },
    showToolbar: {
        [mediaCSS.tab]: {
            maxWidth: '50%!important',
            ...item.transitionErase,
        },
    },
    content_type_withIndents: {
        ...grid.p_mdPlus,
        [mediaCSS.tab]: {
            ...grid.p_mini,
            paddingTop: 74,
        },
        [mediaCSS.tabMini]: {
            ...grid.p_mini,
        },
    },
    content_type_withIndents_x: {
        padding: '30px',
        [mediaCSS.tab]: {
            ...grid.p_mini,
            paddingTop: 74,
        },
        [mediaCSS.tabMini]: {
            ...grid.p_mini,
        },
    },
    content_paddingTopMobFix: {
        [mediaCSS.tabMini]: {
            paddingTop: '50px',
        },
    },
    content_type_standard: {
        [mediaCSS.tab]: {
            ...grid.p_mini,
            paddingTop: 74,
        },
    },
    content_padding_top_x: {
        paddingTop: '66px',
    },
    noPadding: {
        padding: '0 !important',
    },
}

addStyles(contentStyles)
export default contentStyles
