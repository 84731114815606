import React from 'react'
import { api } from 'core/api/ConnectionManager'
import helpers, { treeViewHelpers } from 'core/helpers'
import { isExist, isNotEmptyArray } from 'core/utils/coreUtil'
import { MapCreator } from 'features/map/controllers/MapCreator'
import helpersDigitalSignages from 'core/helpers/_digitalSignages'
import { licenseType, noEmoji, rateTypeRename } from 'core/utils/displayUtil'

interface IDisplaysAddProps {
    groups: { id: number; timezone: string }[]
    user?: any
    licensesData?: {
        activeQ: boolean
        startedQ: boolean
        deletedQ: boolean
        notExpiredQ: boolean
        devicesCount: number
        licenseId: number
        companyId: number
        paidQ: boolean
        startedAt: string
        expiredAt: null | string
        manager: string
        comment: string
        createdAt: string
        deletedAt: null | string
        vacantCount: number
        licenseType: string
        rateType: null | string
    }[]
    currentLicense?: {
        activeQ: boolean
        expiredAt: null | string
        id: number
        name: string
        paidQ: boolean
        vacantCount: number
    } | null
    activeQ?: boolean
    data: {
        activeQ: boolean | null
        groupScheduleMode: string
        orientation: string | null
        timezone: string
        name: string
        description: string
        overwriteSettings: boolean
        parentId: number
        lat: number
        lng: number
        id: number
        address: string
        tags: { label: string }[]
        groupId: number
        licenseId?: number | null
    }
    setPartialData: any
    address: string
    lng: number
    lat: number
    isEdit: boolean
    setGroups: (value: string[]) => void
    closeModal: () => void
    clearData: () => void
    emitError: (error: string) => void
    timezones: string[]
    tagsList: React.ReactNode[]
    licenseId?: number | null
}
export interface IState {
    validateSymbolNumber: boolean
    active: boolean
    initGroup: number
    licensesData: {
        activeQ: boolean
        startedQ: boolean
        deletedQ: boolean
        notExpiredQ: boolean
        devicesCount: number
        licenseId: number
        companyId: number
        paidQ: boolean
        startedAt: string
        expiredAt: null | string
        manager: string
        comment: string
        createdAt: string
        deletedAt: null | string
        vacantCount: number
        licenseType: string
        rateType: null | string
    }[]
    currentLicense: {
        activeQ: boolean
        expiredAt: null | string
        id: number
        name: string
        paidQ: boolean
        vacantCount: number
    } | null
    mapCenter: { lat: number; lng: number } | null
    initialAddress: string | null
    groupTimezone: boolean
    parentTimezone: string | null
    data?: {
        nullTimezoneError?: string | null
        error?: string | null
    }
}

const mapFactory = MapCreator.getInstance().getMapFactory()
const mapApi = mapFactory.createMapApi()

const updateMapCenter = (state: any, lat: number, lng: number) => {
    if (isExist(lat) && isExist(lng)) {
        return {
            ...state,
            mapCenter: { lat, lng },
        }
    }

    return {
        ...state,
        mapCenter: { lat: null, lng: null },
    }
}

const updateInitialAddress = (state: any, address: string) => {
    return {
        ...state,
        initialAddress: address,
    }
}

class DisplaysAddMethods extends React.Component<IDisplaysAddProps> {
    state: any = {
        active: false,
        initGroup: this.props.data.groupId,
        mapCenter: null,
        initialAddress: null,
        groupTimezone: false,
        parentTimezone: null,
        currentLicense: null,
        licensesData: [],
        data: {
            nullTimezoneError: null,
            error: null,
        },
        validateSymbolNumber: false,
    }
    componentDidUpdate(prevProps: IDisplaysAddProps, prevState: IState) {
        if (
            prevState.groupTimezone !== this.state.groupTimezone &&
            this.state.groupTimezone &&
            this.props.data &&
            this.props.data.parentId
        ) {
            const parentTimezone: any = this.props.groups.find((group) => group.id === this.props.data.parentId)
            if (parentTimezone) {
                this.setState({
                    ...prevState,
                    groupTimezone: true,
                    parentTimezone: parentTimezone['0'].timezone,
                    data: { ...prevState.data, nullTimezoneError: null },
                })

                this.props.setPartialData({
                    timezone: parentTimezone['0'].timezone,
                })
            }
        }
    }
    static getDerivedStateFromProps(nextProps: IDisplaysAddProps, prevState: IState) {
        const { mapCenter, initialAddress } = prevState
        const { lat, lng, address } = nextProps

        let updatedWithMapCenterState = isExist(mapCenter) ? prevState : updateMapCenter(prevState, lat, lng)
        let updatedWithInitialAddressState = isExist(initialAddress)
            ? updatedWithMapCenterState
            : updateInitialAddress(updatedWithMapCenterState, address)

        return updatedWithInitialAddressState
    }

    isGroupsEmpty() {
        return this.props.groups.length === 0
    }

    getMethodName() {
        const { isEdit } = this.props
        return isEdit ? 'getAvailableParentGroups' : 'getAvailableToCreateParentGroups'
    }

    getMethodData() {
        const { isEdit } = this.props
        const { id } = this.props.data

        return isEdit ? { digitalSignageId: id } : {}
    }

    getLicenses = (companyId: number) => {
        api.send('getLicenseByFilters', {
            sort: [['licenseId', 'ASC']],
            limit: 1000,
            companyId: companyId,
        }).then((res: any) => {
            let optionFormat = res.data
                .filter((data: any) => data.activeQ)
                .map((opt: any) => ({
                    id: opt.licenseId,
                    name: licenseType(opt) + ' ' + rateTypeRename(opt.rateType),
                    vacantCount: opt.vacantCount,
                    expiredAt: opt.expiredAt,
                    paidQ: opt.paidQ,
                    activeQ: opt.activeQ,
                }))
            this.setState((prevState: IState) => ({
                ...prevState,
                licensesData: optionFormat,
            }))
        })
    }

    componentDidMount() {
        const methodName = this.getMethodName()
        const methodData = this.getMethodData()
        const { setGroups, licenseId } = this.props
        this.getLicenses(this.props.user)
        api.send(methodName, { nestedQ: false, ...methodData }, { hideLoader: true }).then((availableFolders: any) => {
            const groups = treeViewHelpers.addParentKeyIdForTree(availableFolders, 'parentId')
            setGroups(groups)
        })
        if (licenseId) {
            this.props.setPartialData({ licenseId })
        }
    }

    getGroupsWithReplacedName = (groups: any) => {
        let updatedGroups: { name: string }[] = []

        if (groups) {
            updatedGroups = groups.map((group: any) => {
                return {
                    ...group,
                    name: helpers.getTranslatedIfMain(group.name),
                }
            })
        }

        return updatedGroups
    }

    onChangeAddress = (address: string) => {
        const { setPartialData } = this.props
        address = noEmoji(address)
        setPartialData({ address })
    }

    onChangeLocation = (location: { name: string; lat: number; lng: number }) => {
        const { setPartialData } = this.props
        const { name, lat, lng } = location

        setPartialData({ address: name, lat, lng })

        this.setState((prevState) => {
            return {
                ...prevState,
                initialAddress: name,
            }
        })
    }

    onChangePlacemarkGeometry = (geometry: { lat: number; lng: number }) => {
        const { setPartialData } = this.props
        const { lat, lng } = geometry

        setPartialData({ lat, lng })
    }

    onMapClose = () => {
        const { lat, lng } = this.props.data

        this.setState({
            active: false,
            mapCenter: {
                lat,
                lng,
            },
        })
    }

    onMapOpen = () => {
        this.setState({ active: true })
    }

    updateLocation() {
        const { setPartialData } = this.props
        const { address } = this.props.data
        const { initialAddress } = this.state

        if (address === initialAddress) {
            this.save()
            return
        }

        mapApi
            .geocodeLocation(address)
            .then((mapLocationList) => {
                if (!isNotEmptyArray(mapLocationList)) {
                    this.save()
                    return
                }

                let { lat, lng } = mapLocationList[0]

                setPartialData({ lat, lng })
                this.save()
            })
            .catch(() => {
                this.save()
            })
    }

    onSave = () => {
        this.updateLocation()
    }

    getAddress = () => {
        const { address } = this.props.data

        return address || ''
    }

    getCoordinate = () => {
        const { lat, lng } = this.props.data

        return {
            lat: lat || null,
            lng: lng || null,
        }
    }

    getLocation = () => {
        return {
            name: this.getAddress(),
            ...this.getCoordinate(),
        }
    }

    getCenter = () => {
        const { mapCenter } = this.state

        // @ts-ignore
        if (isExist(mapCenter) && isExist(mapCenter.lat) && isExist(mapCenter.lng)) {
            return mapCenter
        }

        return null
    }

    checkErrors(displayInfo: { platform: string; timezone: string; name: string }) {
        let error = null
        const platformName: string | undefined = helpersDigitalSignages.getPlatformName(displayInfo.platform)
        const availablePlatforms = ['sssp', 'tizen']

        if (platformName && availablePlatforms.includes(platformName) && !displayInfo.timezone) {
            error = 'displaysAddError'
        }

        if (!displayInfo.name) {
            error = 'nameFieldIsRequired'
        }

        return error
    }

    save() {
        const displayInfo: any = { ...this.props.data }
        const error = this.checkErrors(displayInfo)
        const { emitError, closeModal, clearData } = this.props
        const s_ = this.state
        const p_ = this.props

        if (error) {
            emitError(error)
            return
        }

        if (!p_.data.name && !p_.data.timezone) {
            this.setState((prevState: IState) => ({
                ...prevState,
                data: { ...prevState.data, nullTimezoneError: 'fieldIsRequired', error: 'fieldIsRequired' },
            }))
            return
        } else if (!p_.data.name) {
            this.setState((prevState: IState) => ({
                ...prevState,
                data: { ...prevState.data, nullTimezoneError: null, error: 'fieldIsRequired' },
            }))
            return
        } else if (!p_.data.timezone) {
            this.setState((prevState: IState) => ({
                ...prevState,
                data: { ...prevState.data, nullTimezoneError: 'fieldIsRequired', error: null },
            }))
            return
        }

        if (displayInfo.tags) {
            displayInfo.tags = displayInfo.tags.map((tag: { label: string }) => tag.label)
        }

        api.send('editDisplay', displayInfo).then((res) => {
            clearData()
            closeModal()
        })
    }

    inputNullCheck = (value: string | null, type: string) => {
        if (type === 'name') {
            value
                ? this.setState((prevState: IState) => ({ ...prevState, data: { ...prevState.data, error: null } }))
                : this.setState((prevState: IState) => ({
                      ...prevState,
                      data: { ...prevState.data, error: 'fieldIsRequired' },
                  }))
        }
        if (type === 'timezone') {
            value
                ? this.setState((prevState: IState) => ({
                      ...prevState,
                      data: { ...prevState.data, nullTimezoneError: null },
                  }))
                : this.setState((prevState: IState) => ({
                      ...prevState,
                      data: { ...prevState.data, nullTimezoneError: 'fieldIsRequired' },
                  }))
        }
    }

    onClose = () => {
        const { closeModal, clearData } = this.props

        closeModal()
        clearData()
    }

    onValidateSymbolNumber(isValidate: boolean) {
        this.setState((prevState: IState) => ({ ...prevState, validateSymbolNumber: isValidate }))
    }
}

export default DisplaysAddMethods
