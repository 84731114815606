import React from 'react'
import EditText from 'blocks.simple/editText/editText'
import translate from 'core/translate'
import styles from './deviceSettings__webEngine.jcss'
import { Typography } from 'atoms/Typography'
import { cn } from 'ethcss'

const DeviceSettingsWebEngine = (p_: {
    enabled: boolean
    settings: { options: string[] }
    onChange: (data: any, field: string) => void
}) => {
    if (!p_.enabled) {
        return null
    }

    const options = p_.settings.options.join('\n')

    const onChange = (value: string, event: string | null, key = 'options') => {
        const data: any = {
            ...p_.settings,
        }

        if (key !== 'options') {
            data[key] = value
        } else {
            data[key] = value.split('\n')
        }

        p_.onChange(data, 'webEngineSettings')
    }

    return (
        <div className={styles.deviceSettingsWebEngineWrapper}>
            <Typography className={cn(styles.deviceSettingsWebEngineTitle)} type="title">
                {translate('browserSettings')}
            </Typography>
            <div className={styles.deviceSettingsWebEngineContent}>
                <div className={styles.deviceSettingsTextArea}>
                    <EditText
                        mod={'withBorder'}
                        type="area"
                        label={translate('settings')}
                        value={options}
                        onChange={onChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default DeviceSettingsWebEngine
