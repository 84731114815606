import React, { FC, memo, useState } from 'react'
import { IHeaderWrapperProps } from './HeaderWrapper-types'
import { styles } from './HeaderWrapper-styles'
import Measure, { ContentRect } from 'react-measure'
import { indents, cols } from 'theme'
import { cn } from 'ethcss'

export const HeaderWrapperComponent: FC<IHeaderWrapperProps> = ({ children }) => {
    return <div className={cn(styles.HeaderWrapper)}>{children}</div>
}

export const HeaderWrapper = memo(HeaderWrapperComponent)
