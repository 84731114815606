import { FC } from 'react'
import { SearchableMapProps, MapProps } from '../models/map'
import { MapApi } from '../api/MapApi'

export abstract class MapFactory {
    protected apiKey: string

    constructor(apiKey: string) {
        this.apiKey = apiKey
    }

    abstract createSearchableMap(): FC<SearchableMapProps>

    abstract createMap(): FC<MapProps>

    abstract createMapApi(): MapApi
}
