declare var window: any

export interface IMediaJs {
    [key: string]: MediaQueryList
}

export interface IMediaCss {
    [key: string]: string | number
}

export const cols = {
    mini: 5.504167,
    normal: 15.1041666667,
}

export const indents = {
    none: 0,
    nano: 4,
    micro: 5,
    mini: 10,
    miniMiddle: 12,
    miniPlus: 15,
    md: 20,
    mdPlus: 30,
    normal: 35,
    big: 40,
}

export interface ITextSizes {
    [key: string]: number
}

export const textSizes: ITextSizes = {
    nano: 8,
    micro: 10,
    mini: 12,
    normal: 14,
    normalPlus: 16,
    md: 18,
    mdPlus: 24,
    big: 34,
}

export const layers = {
    secondary: 500,
    sticky: 1500,
    modal: 2000,
    drop: 3000,
    menu: 2000,
    progress: 5000,
    videoChat: 10000,
}

export const radiusSizes = {
    micro: 5,
    mini: 10,
    normal: 18,
    md: 21,
    big: 30,
    circle: '50%',
}

export const transitions = {
    normal: 100,
    normalPlus: 300,
    md: 600,
}

/* media */
export const media: IMediaCss = {
    desktopXL: 1800,
    desktopML: 1640,
    desktopL: 1280,
    desktopLM: 1120,
    desktop: 1199,
    desktopM: 1155,
    desktopS: 1080,
    desktopXS: 1000,
    tab: 960,
    tabMini: 700,
    mini: 570,
    mob: 450,
}

export const mediaCSS: IMediaCss = {
    ...media,
}

export const mediaJS: IMediaJs = {}

for (let mediaKey in media) {
    if (media.hasOwnProperty(mediaKey)) {
        const mediaValue = media[mediaKey]
        mediaCSS[mediaKey] = `@media (max-width: ${mediaValue}px)`
        mediaJS[mediaKey] = window.matchMedia(`(max-width: ${mediaValue}px)`)
    }
}

export const font = {
    regular: 'OpenSans, sans-serif',
    main: window.spconfig.fontFamily || 'century-gothic__regular, sans-serif',
}
