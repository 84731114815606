import React, { useEffect, useState } from 'react'
import LoaderBlock from 'blocks.app/loader/_block/loader_block'
import grid from 'blocks.simple/grid/grid.jcss'
import styles from './preview__webPage.jcss'

const PreviewWebPage = (p_: {
    item: {
        id: number
        src: string
        fileType: string
        name: string
        folderId: number
        data: string
        isDirectory: number
        type: string
        displayAs: string
        thumbnail: string
        style: object
        scroll?: string
    }
    webPageRef: any
}) => {
    const [ready, setReady] = useState<boolean>(false)
    let timer: any = null

    const onLoadPage = () => {
        timer = setTimeout(() => {
            setReady(true)
        }, 1000)
    }
    const refreshPage = () => {
        let iframe: HTMLIFrameElement | null = document.querySelector('#link_iframe iframe')
        if (iframe) {
            // eslint-disable-next-line
            iframe.src = iframe.src
        }
    }
    p_.webPageRef.current = { refreshPage }

    useEffect(() => {
        onLoadPage()

        return () => clearTimeout(timer)
    }, [])

    return (
        <div className={styles.wrapper}>
            {!ready && <LoaderBlock className={styles.loader} />}
            {p_.item.fileType === 'web_page' && (
                <div className={grid.full} id="link_iframe">
                    <iframe
                        src={p_.item.name}
                        onLoad={onLoadPage}
                        width="100%"
                        height="100%"
                        title={p_.item.name}
                        scrolling={!p_.item.scroll || p_.item.scroll === 'on' ? 'yes' : 'no'}
                    />
                </div>
            )}
        </div>
    )
}

export default PreviewWebPage
