import { addStyles } from 'ethcss'
import { indents, layers } from 'blocks.app/config'
import grid from 'blocks.simple/grid/grid.jcss'
import { defaultThemeStyles } from '../editor.theme'
import { mediaCSS, textSizes } from 'theme/media'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { colors } from '../../../theme'

export const iconSize = 16

const editorFooterStyles = {
    audioWrapper: {
        ...grid.w100,
        position: 'relative',
        backgroundColor: `var(--var-module-app-component-editor-style-backgroundMain, ${defaultThemeStyles.backgroundMain})`,
        color: `var(--var-module-app-component-editor-style-textSub, ${defaultThemeStyles.textSub})`,

        [mediaCSS.tab]: {
            height: 44,
        },
    },
    footerWrapper: {
        ...grid.w100,
        position: 'relative',
        backgroundColor: `var(--var-module-app-component-editor-style-backgroundMain, ${defaultThemeStyles.backgroundMain})`,
        color: `var(--var-module-app-component-editor-style-textSub, ${defaultThemeStyles.textSub})`,
        borderRadius: '0 0 5px 5px',
        // overflow: 'hidden',
    },
    listWrapper: {
        backgroundColor: `var(--var-module-editor-component-footer-style-background, ${defaultThemeStyles['footer-background']})`,
    },
    itemsWrapper: {
        ...grid.fullHeight,
        ...grid.mb_md,
        position: 'relative',
    },
    itemsWrapper_type_autoSize: {
        ...grid.fullHeight,
        position: 'relative',
        '& div': {
            outline: 'none',
            '&::-webkit-scrollbar': {
                borderRadius: 10,
                width: 6,
                background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                width: 6,
                background: '#b3b3b3',
            },
        },
    },
    scale: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.pt_micro,
        ...grid.pb_micro,
        fontSize: textSizes.mini,
        backgroundColor: `var(--var-module-app-component-editor-style-backgroundMain, ${defaultThemeStyles.backgroundMain})`,
        borderTop: `1px solid var(--var-module-app-component-editor-style-border, ${defaultThemeStyles.border})`,

        [mediaCSS.tab]: {
            flexWrap: 'nowrap',
        },
    },
    scaleTitle: {
        ...grid.w5,
        ...grid.pl_micro,
        ...grid.pr_micro,
        textAlign: 'center',

        [mediaCSS.tab]: {
            minWidth: 30,
        },
    },
    pointsWrapper: {
        ...grid.w95,
        ...grid.pl_mini,
        ...grid.pr_mini,

        [mediaCSS.tab]: {
            //paddingLeft: 0,
            minWidth: 'calc(100% - 30px)',
        },
    },
    areaCountMobile: {
        [mediaCSS.tab]: {
            minWidth: 30,
            width: 30,
        },
    },
    timeWrapper: {
        position: 'absolute',
        top: -(iconSize / 2 - 2),
        bottom: 0,
        right: indents.mini,
        left: `calc(5% + ${indents.mini}px)`,

        [mediaCSS.tab]: {
            left: `calc(30px + ${indents.mini}px)`,
        },
    },
    timePoint: {
        marginLeft: -(iconSize / 2),
        height: 12,
    },
    timeLine: {
        ...grid.space,
        width: 1,
        backgroundColor: `var(--var-module-editor-component-footer-style-background, ${defaultThemeStyles['footer-background']})`,
    },
    points: {
        ...grid.w100,
        ...grid.row,
        fontSize: textSizes.mini,
    },
    xWrapper: {
        ...grid.row,
        ...grid.fullHeight,
    },
    xWrapperMobile: {
        [mediaCSS.tab]: {
            flexWrap: 'nowrap',
            height: 44,
        },
    },
    emptySoundtrack: {
        ...grid.full,
        ...grid.rowCenter,
        ...grid.normalCenter,
        color: `var(--var-module-editor-component-footer-style-emptySoundtrack, ${defaultThemeStyles['footer-emptySoundtrack']})`,

        [mediaCSS.tab]: {
            fontSize: 12,
            flexWrap: 'nowrap',
        },
    },
    time: {
        ...grid.w95,
        ...grid.pl_mini,
        ...grid.pr_mini,
        ...grid.fullHeight,
        position: 'relative',
        cursor: 'pointer',

        [mediaCSS.tab]: {
            //paddingLeft: 0,
            width: 'calc(100% - 30px)',
        },
    },
    timeNumber: {
        ...grid.p_nano,
        fontSize: textSizes.mini,
        textAlign: 'center',
        'white-space': 'nowrap',
        position: 'absolute',
        width: 80,
        left: -40,
        top: -20,
        borderRadius: 4,
        backgroundColor: `var(--var-module-editor-component-footer-style-timeNumber, ${defaultThemeStyles['footer-timeNumber']})`,
        opacity: 0.85,
        color: `var(--var-module-editor-component-footer-style-timeNumberText, ${defaultThemeStyles['footer-timeNumberText']})`,

        [mediaCSS.tab]: {
            display: 'none',
        },
    },
    disabled: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        cursor: 'not-allowed !important',
    },
    dropWrapper: {
        ...grid.full,
        borderTop: `1px solid var(--var-module-editor-component-footer-style-border, ${defaultThemeStyles['footer-border']})`,
        borderBottom: `1px solid var(--var-module-editor-component-footer-style-border, ${defaultThemeStyles['footer-border']})`,
    },
    dropWrapperActive: {
        backgroundColor: `var(--var-module-app-component-app-style-darkBackground, ${defaultThemeAppStyles.darkBackground})`,
    },
    loopLine: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '15%',
        width: 1,
        backgroundColor: `var(--var-module-editor-component-footer-style-loopLine, ${defaultThemeStyles['footer-loopLine']})`,
    },
    contentTitle: {
        ...grid.w100,
        ...grid.mt_micro,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.pt_micro,
        ...grid.pb_micro,
        fontSize: textSizes.mini,
        color: `var(--var-module-editor-component-timeline-style-textViewText, ${defaultThemeStyles['timeline-textViewText']})`,
        borderTop: `1px solid var(--var-module-app-component-editor-style-border, ${defaultThemeStyles.border})`,
        borderBottom: `1px solid var(--var-module-app-component-timeline-style-border, ${defaultThemeStyles['timeline-border']})`,
        backgroundColor: `var(--var-module-editor-component-footer-style-background, ${defaultThemeStyles['footer-background']})`,

        [mediaCSS.tab]: {
            display: 'none',
        },
    },
    active: {
        boxShadow: '0px 4px 4px rgba(0, 54, 136, 0.25)',
        fontSize: textSizes.normal,
        border: `1px dashed var(--var-module-app-component-editor-style-border, ${defaultThemeStyles.border})`,
        zIndex: layers.modal + 1,
    },
    controlsWrapper: {
        ...grid.row,
        width: '9%',
    },
    control: {
        ...grid.rowCenter,
        ...grid.w50,
    },
    soundtrackMobile: {
        [mediaCSS.tab]: {
            minWidth: 100,
        },
    },
    soundtrackSettingsMobile: {
        [mediaCSS.tab]: {
            padding: 4,
        },
    },
    soundtrackContentMobile: {
        [mediaCSS.tab]: {
            height: 44,
        },
    },
    highLight: {
        border: `1px solid ${colors.white}`,
    },
}

addStyles(editorFooterStyles)
export default editorFooterStyles
