import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import { IStyles } from '../../../theme'

const styles: IStyles = {
    wrapper() {
        return {
            [`& tr:hover .${this.deleteIcon}`]: {
                ...item.visible,
            },
        }
    },
    deleteIcon: {
        ...item.hidden,
    },
}

addStyles(styles)
export default styles
