import { addStyles } from 'ethcss'
import { grid, item } from 'theme'
import { mediaCSS } from 'theme/media'

export const styles = {
    history__info: {
        ...grid.w20,
        [mediaCSS.tab]: {
            ...grid.w100,
            ...grid.mb_md,
        },
        ...item.panel,
        ...grid.p_md,
        ...grid.col,
        ...grid.justify,
    },
    history__info_light_theme: {
        ...grid.w20,
        [mediaCSS.tab]: {
            ...grid.w100,
            ...grid.mb_md,
        },
        ...item.panelLightTheme,
        ...grid.p_md,
        ...grid.col,
        ...grid.justify,
    },
    history__content: {
        ...grid.w75,
        [mediaCSS.tab]: {
            ...grid.w100,
        },
        ...item.panel,
        ...grid.p_md,
    },
    history__content_light_theme: {
        ...grid.w75,
        [mediaCSS.tab]: {
            ...grid.w100,
        },
        ...item.panelLightTheme,
        ...grid.p_md,
    },
    historyWrapper: {
        [mediaCSS.tab]: {
            paddingTop: 60,
        },
    },
}

addStyles(styles)
