import React from 'react'
import Content from 'blocks.app/content/content'
import Catalog from '../../../blocks.app/catalog/catalog'
import translate from '../../../core/translate'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

class InstancesCatalogSurvey extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {}
    }

    render() {
        return (
            <Content title={translate('surveyData')}>
                <Catalog
                    type={'survey'}
                    treeView={false}
                    additionalData={{}}
                    fixedHeader={true}
                    isExistRightToolbar={false}
                    hideHeader={true}
                />
            </Content>
        )
    }
}

const exportInstancesCatalogSurvey = checkAvailableModule('instancesCatalog/surveyCatalog')
    ? InstancesCatalogSurvey
    : null

export default exportInstancesCatalogSurvey
