import { useState } from 'react'

export interface IBreadcrumbsProps {
    listItemData?: { index: number; breadcrumb: { id: number; name: string }; pathname: string }
    list: { id: number; name: string }[]
    multipleSelect: boolean
    selectedItems: { id: number }[]
    onResetSelection: () => void
    template: {
        isEqual: (data_1: any, data_2: any) => boolean
        isFolder: (data: any) => boolean
        multipleMove: (sourceId: number[], folderId: number[], newFolderId: number) => any
        moveFolder: (itemId: number, breadcrumbId: any) => void
        moveItem: (itemId: number, breadcrumbId: any) => void
    }
    filterName: string
    type: string
    query?: { [index: string]: string }
}

const CatalogBreadcrumbsMethods = (p_: IBreadcrumbsProps) => {
    const [isHovered, setIsHovered] = useState(false)

    function onDrop(data: { dnd_item: string }, e: any) {
        e.stopPropagation()

        if (!p_.listItemData) return

        const droppedItem = JSON.parse(data.dnd_item)
        const maxBreadcrumbDepth = p_.list.length - 1
        const currentBreadcrumbDepth = p_.listItemData.index

        if (currentBreadcrumbDepth === maxBreadcrumbDepth) {
            setIsHovered(false)
            return
        }

        if (!p_.listItemData.breadcrumb.id) {
            setIsHovered(false)
            return
        }

        if (p_.template.multipleMove && p_.multipleSelect && p_.selectedItems.length) {
            const droppedItemInSelected = p_.selectedItems.filter((selectedItem: any) =>
                p_.template.isEqual(selectedItem, droppedItem)
            )

            if (droppedItemInSelected.length) {
                onMoveMultiple()
                return
            }
        }

        const breadcrumb = p_.listItemData.breadcrumb
        const isDroppedFolder = p_.template.isFolder(droppedItem)

        if (isDroppedFolder && droppedItem.id === breadcrumb.id) {
            setIsHovered(false)
            return
        }

        const moveMethod = isDroppedFolder ? p_.template.moveFolder : p_.template.moveItem
        const breadcrumbId = breadcrumb.id || null

        moveMethod(droppedItem.id, breadcrumbId)
        setIsHovered(false)
    }

    function onMoveMultiple() {
        const sourceId: number[] = []
        const folderId: number[] = []
        const breadcrumb = p_.listItemData!.breadcrumb
        const newFolderId: any = breadcrumb.id || null

        p_.selectedItems.forEach((item) => {
            const isFolder = p_.template.isFolder(item)

            if (isFolder && item.id === newFolderId) {
                return
            }

            p_.template.isFolder(item) ? folderId.push(item.id) : sourceId.push(item.id)
        })

        p_.template.multipleMove(sourceId, folderId, newFolderId).then(() => {
            p_.onResetSelection()
        })
        setIsHovered(false)
    }

    function onDragOver() {
        if (!isHovered) {
            setIsHovered(true)
        }
    }

    function onDragLeave() {
        if (isHovered) {
            setIsHovered(false)
        }
    }

    return {
        s_: {
            isHovered,
        },
        methods: {
            onDrop,
            onDragOver,
            onDragLeave,
        },
    }
}

export default CatalogBreadcrumbsMethods
