import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { radiusSizes, mediaCSS } from 'blocks.app/config'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { defaultThemeStyles } from './DisplayInfo-theme'
import 'blocks.app/loader/loader.css'
import { colors, IStyles } from '../../../theme'

const displaysInfoStyles: IStyles = {
    wrapper: {
        ...grid.row,
        height: '80%',
    },
    screenshot: {
        ...grid.w50,
        ...grid.col,
        ...grid.pr_mdPlus,
        ...grid.fullHeight,
        [mediaCSS.tab]: {
            ...grid.w100,
            height: 'auto',
            ...grid.pr_none,
            ...grid.mb_md,
        },
    },
    params: {
        ...grid.w50,
        ...grid.col,
        ...grid.pl_mdPlus,
        ...grid.fullHeight,
        position: 'relative',
        [mediaCSS.tab]: {
            ...grid.w100,
            ...grid.row,
            height: 'auto',
            ...grid.pl_none,
            ...grid.pb_md,
            ...grid.mt_mdPlus,
        },
    },
    iconUpdate: {
        position: 'absolute',
    },
    iconUpdateAnim: {
        animation: `loaderSpinAnimation 0.8s linear infinite`,
    },

    image: {
        boxShadow: '0px 3px 13.5px 1.5px rgba(0, 0, 0, 0.5)',
    },
    imageContent: {
        backgroundColor: `var(--var-module-device-component-info-style-imageContent, ${defaultThemeStyles.imageContent})`,
    },
    buttons: {
        ...grid.p_md,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
        position: 'relative',
        borderRadius: `${radiusSizes.mini}px`,
    },
    offline: {
        backgroundColor: `var(--var-module-app-component-app-style-offline, ${defaultThemeAppStyles.offline})`,
    },
    error: {
        backgroundColor: `var(--var-module-app-component-app-style-error, ${defaultThemeAppStyles.error})`,
    },
    online: {
        backgroundColor: `var(--var-module-app-component-app-style-online, ${defaultThemeAppStyles.online})`,
    },
    online_display_off: {
        backgroundColor: `var(--var-module-app-component-app-style-online-display-off, ${defaultThemeAppStyles.displayOff})`,
        backgroundSize: 'cover',
    },
    displayOffDiv: {
        color: colors.white,
    },
    icons: {
        ...grid.row,
        [mediaCSS.tab]: {
            ...grid.center,
        },
    },
    iconWrapperPb: {
        [mediaCSS.tabMini]: {
            ...grid.pb_mini,
        },
    },
    paramsList: {
        ...grid.row,
        overflowY: 'auto',
        overflowX: 'hidden',
        '& .displaysInfo__paramsItem:last-child': {
            border: 'none',
        },

        [mediaCSS.tab]: {
            width: '100%',
        },
    },
    paramsItem: {
        ...grid.p_micro,
        ...grid.row,
        ...grid.fullWidth,
        alignItems: 'center',
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    tagList: {
        ...grid.mb_mini,
        ...grid.colCenter,
        overflowY: 'auto',
        [mediaCSS.tab]: {
            ...grid.mb_none,
        },
    },
    topInfo: {
        height: '20%',
        ...grid.row,
        ...grid.space,
        ...grid.pt_big,
        [mediaCSS.tab]: {
            height: 'auto',
        },
    },
    tagWrapper: {
        ...grid.pt_mini,
        ...grid.fullWidth,
        ...grid.pb_mini,
    },
    tagItem: {
        ...grid.mr_mini,
        ...grid.mb_mini,
    },
    minWidthLicenseDropdown: {
        minWidth: '180px',
    },
    dataScreenshot: {
        position: 'absolute',
        bottom: 0,
    },
    getLogsBtn: {
        backgroundColor: `var(--var-module-device-component-info-style-button, ${defaultThemeStyles.button})`,
    },
    dsStateInfo: {
        position: 'absolute',
        ...grid.full,
        ...grid.row,
        ...item.transitionErase,
        backgroundColor: `var(--var-module-device-component-info-style-deviceState, ${defaultThemeStyles.deviceState})`,
        color: `var(--var-module-device-component-info-style-deviceStateText, ${defaultThemeStyles.deviceStateText})`,
        opacity: 0,
        top: 0,
        left: 0,
    },
    dsStateInfoLine: {
        ...grid.row,
        ...grid.fullWidth,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        '&:last-child': {
            border: 'none',
        },
    },
    imageWithDsInfo() {
        return {
            [`&:hover .${this.dsStateInfo}`]: {
                opacity: 1,
            },
        }
    },
    modalAccept: {
        backgroundColor: `var(--var-module-device-component-info-style-modalAccept, ${defaultThemeStyles.modalAccept})`,
    },
    copyToBufferIconWrapper: {
        position: 'absolute',
        top: -grid.mb_mdPlus['margin-bottom'],
        right: 0,

        [mediaCSS.tab]: {
            right: 10,
            top: 6,
            zIndex: 1,
        },
    },
    displayInfoParameterValue: {
        ...grid.w55,
        wordWrap: 'break-word',
        cursor: 'copy',
    },
    groupList: {
        borderRadius: '10px 10px 0 0',
        overflow: 'hidden',
        marginBottom: '30px',
        border: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    groupHeader: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: '34px',
        padding: '0 10px',
        color: '#fff !important',
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
    },
    headerCaption: {
        color: '#fff',
        marginLeft: '12px',
    },
    scroll: {
        maxHeight: '100%',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    checkConnectionBtn: {
        position: 'absolute',
        top: '50%',
        right: '10px',
        transform: 'translateY(-50%)',
        fontWeight: '500',
        textDecoration: 'underline',
        textTransform: 'none',
        cursor: 'pointer',

        '&:hover': {
            textDecoration: 'none',
        },
    },
    logLevelInfo: {
        color: colors.white,
        margin: '10px 0',
        maxWidth: '470px',
    },
    waitForDownload: {
        color: colors.white,
        margin: '10px 0',
        maxWidth: '470px',
    },
    goToSettings: {
        textDecoration: 'underline',

        '&:hover': {
            textDecoration: 'none',
            cursor: 'pointer',
        },
    },
    clearInfoClass: {
        maxWidth: 800,

        [mediaCSS.laptop]: {
            maxWidth: 360,
        },
    },
    webEngineModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 9999,
        backgroundColor: 'rgba(0,0,0,0.7)',
    },
    webEngineModalInner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '30%',
        backgroundColor: colors.white,
        borderRadius: '20px',
        padding: '20px 20px 100px 20px',
        textAlign: 'center',
    },
    webEngineModalClose: {
        position: 'absolute',
        top: 20,
        right: 20,
    },
    webEngineInfoCaption: {
        fontSize: 20,
        fontWeight: 600,
        marginBottom: 70,
    },
    wikiLink: {
        '>div': {
            display: 'flex',
            alignItems: 'center',
        },
        span: {
            fontSize: 11,
            marginLeft: 24,
        },
        a: {
            color: colors.blueAccent,
        },
    },
}

addStyles(displaysInfoStyles)
export default displaysInfoStyles
