import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { IStyles } from '../../../theme'

const touchEditorEventsStyles: IStyles = {
    eventType: {
        ...grid.col,
        ...grid.w50,
        ...grid.pr_mini,
    },
    settingsForm: {
        ...grid.col,
        ...grid.w50,
        ...grid.pl_mini,
    },
    inputs: {
        ...grid.mt_md,
    },
}

addStyles(touchEditorEventsStyles)
export default touchEditorEventsStyles
