import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch'
import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/dist/geosearch.css'
import { styles } from '../../organisms/SelectMapDialog/SelectMapDialog-styles'
import Icon from '../../../../blocks.simple/icon/icon'
import translate from '../../../../core/translate'
import Modal from '../../../../blocks.simple/modal/modal'
import { cn } from 'ethcss'
import { isHorizontalMenu } from '../../../../core/helpers/menu'

export type Location = {
    name: string
    lat: number
    lng: number
}

const centerDefault: number[] = [55.7504461, 37.6174943] // Координаты по умолчанию (Моксва)

const L = require('leaflet')

const myIcon = L.icon({
    iconUrl: 'images/displays/marker-icon.png',
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
})

const LeafletGeoSearch = ({ location, onChangeLocation, onChangePlacemarkGeometry, coords, changeCoords }: any) => {
    const [search, setSearch] = useState<any>(null)

    const map = useMap()
    // @ts-ignore
    useEffect(() => {
        const provider = new OpenStreetMapProvider()
        //@ts-ignore
        const searchControl: any = new GeoSearchControl({
            provider,
            searchLabel: 'Введите адрес',
            marker: {
                icon: myIcon,
                draggable: true,
            },
            classNames: {
                container: styles.searchBlock,
                resetButton: styles.resetBtn,
                form: styles.formBlock,
                input: styles.inputField,
                resultlist: styles.resultBlock,
            },
        })

        setSearch(searchControl)

        map.addControl(searchControl)

        map.on('geosearch/showlocation', (e: any) => {
            searchControl.searchElement.input.value = e.location.label
            onChangeLocation({ name: e.location.label, lat: e.location.y, lng: e.location.x })
            onChangePlacemarkGeometry({ lat: e.location.y, lng: e.location.x })
        })

        map.on('geosearch/marker/dragend', (e: any) => {
            changeCoords({
                lat: e.location.lat,
                lng: e.location.lng,
            })
            //onChangePlacemarkGeometry({ lat: e.location.lat, lng: e.location.lng })
        })

        if (location.name) {
            searchControl.searchElement.handleSubmit({ query: location.name })
        }

        return () => map.removeControl(searchControl)
    }, [])

    useEffect(() => {
        if (!search || !coords) return

        if (location.lat && location.lng) {
            search.searchElement.handleSubmit({ query: `${coords.lat},${coords.lng}` })
        }
    }, [coords])

    return null
}

function DraggableMarker({ center, onChangePlacemarkGeometry, changeCoords, draggable, onClick, item }: any) {
    const [position, setPosition] = useState<any>(center)
    const markerRef = useRef<any>(null)
    // const eventHandlers = useMemo(
    //     () => ({
    //         dragend() {
    //             const marker: any = markerRef.current
    //             if (marker !== null) {
    //                 onChangePlacemarkGeometry(marker.getLatLng())
    //                 changeCoords(marker.getLatLng())
    //                 setPosition(marker.getLatLng())
    //             }
    //         },
    //     }),
    //     []
    // )

    const onMarkerClick = () => {
        if (onClick) {
            onClick(item)
        }
    }

    const changeMarker = (icon: any, selected: boolean) => {
        if (selected) {
            icon.classList.add(styles.activeIcon)

            return
        }

        icon.classList.remove(styles.activeIcon)
    }

    useEffect(() => {
        if (markerRef.current) {
            markerRef.current.addEventListener('click', onMarkerClick)

            return () => markerRef.current.removeEventListener('click', onMarkerClick)
        }
    }, [])

    useEffect(() => {
        if (item) {
            if (item.__view.selected) {
                if (markerRef.current) {
                    if (markerRef.current._icon) {
                        changeMarker(markerRef.current._icon, true)
                    }
                }

                return
            }

            if (markerRef.current) {
                if (markerRef.current._icon) {
                    changeMarker(markerRef.current._icon, false)
                }
            }
        }
    }, [item])

    return (
        <Marker
            icon={myIcon}
            draggable={draggable}
            //eventHandlers={eventHandlers}
            position={position}
            ref={markerRef}
        ></Marker>
    )
}

const getCenter = (location: Location): any => {
    return location.lat && location.lng ? [location.lat, location.lng] : centerDefault
}

const markersIsDiff = (markersPrev: any, maskersNext: any) => {
    if (!markersPrev || !maskersNext) return true

    const prevParams = markersPrev.map((param: any) => {
        return {
            lat: param.lat,
            lng: param.lng,
        }
    })
    const nextParams = maskersNext.map((param: any) => {
        return {
            lat: param.lat,
            lng: param.lng,
        }
    })

    return JSON.stringify(prevParams) == JSON.stringify(nextParams)
}

export const OpenStreetMap = ({
    onClose,
    isOpen,
    location,
    onChangeLocation,
    onChangePlacemarkGeometry,
    markers,
    onMarkerClick,
}: any) => {
    const [coords, setCoords] = useState<object | null>(null)
    const [markersList, setMarkers] = useState(markers ? markers : [])

    useEffect(() => {
        if (!markersIsDiff(markersList, markers)) {
            setMarkers([])
        }

        setTimeout(() => {
            setMarkers(markers)
        }, 15)
    }, [markers])

    const changeCoords = (coords: any) => {
        setCoords({
            lat: coords.lat,
            lng: coords.lng,
        })
    }

    const renderMarkers = (markers: any) => {
        if (!markers.length) return null

        return markers.map((marker: any) => {
            return (
                <DraggableMarker
                    center={getCenter({ lat: marker.lat, lng: marker.lng, name: '' })}
                    onChangePlacemarkGeometry={() => {}}
                    changeCoords={() => {}}
                    onClick={onMarkerClick}
                    item={marker}
                />
            )
        })
    }

    const renderMarker = useCallback(() => {
        if (!location.name) {
            return (
                <DraggableMarker
                    center={getCenter(location)}
                    onChangePlacemarkGeometry={onChangePlacemarkGeometry}
                    changeCoords={changeCoords}
                    draggable={true}
                />
            )
        }

        return null
    }, [location])

    return (
        <>
            {!markers ? (
                <Modal open={isOpen} showCloseIcon={false} mod="content">
                    <div className={styles.selectMapDialog__close} onClick={onClose}>
                        <Icon type="minimize" color="black" size={28} tooltip={{ title: translate('collapse') }} />
                    </div>
                    <MapContainer
                        center={getCenter(location)}
                        zoom={10}
                        style={{ height: '100%' }}
                        className={styles.openMapWrapper}
                    >
                        <TileLayer attribution="" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <LeafletGeoSearch
                            location={location}
                            onChangeLocation={onChangeLocation}
                            onChangePlacemarkGeometry={onChangePlacemarkGeometry}
                            coords={coords}
                            changeCoords={changeCoords}
                        />
                        {renderMarker()}
                    </MapContainer>
                </Modal>
            ) : (
                <>
                    <MapContainer
                        center={getCenter(location)}
                        zoom={10}
                        style={{ height: '100%' }}
                        className={cn(
                            styles.openMapWrapper,
                            styles.zeroIndex,
                            isHorizontalMenu() ? styles.mapCorrection_x : ''
                        )}
                    >
                        <TileLayer attribution="" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        {renderMarkers(markersList)}
                    </MapContainer>
                </>
            )}
        </>
    )
}
