import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { IStyles, mediaCSS } from '../../../../theme'

const catalogTemplateBroadcastsStyles: IStyles = {
    image: {
        ...grid.w10,
    },
    name: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.noWrap,
        ...grid.w100,
        textAlign: 'left',
    },
    icon: {
        ...grid.mr_mini,
    },
    mediaCellClassName: {
        [mediaCSS.tab]: {
            ...grid.col4,
        },
        [mediaCSS.tabMini]: {
            width: '50% !important',
        },
    },
}

addStyles(catalogTemplateBroadcastsStyles)
export default catalogTemplateBroadcastsStyles
