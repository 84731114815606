import { addStyles } from 'ethcss'
import { grid, item, text } from 'theme'

const itemRolesStyles = {
    catalogItemRolesWrapper: {
        ...grid.space,
        ...grid.mb_mdPlus,
        ...item.pointer,
        ...item.noSelection,
        ...item.rounded_full_mini,
        ...item.rel,
    },
    catalogItemRolesContent: {},
    catalogItemRolesContentActive: {},
    catalogItemRolesCell: {
        ...grid.fullWidth,
        ...grid.p_md,
        ...item.rel,
        ...item.blackLight,
        borderRadius: '5px',
        position: 'relative',
    },
    catalogItemRoleCellMultiSelect: {
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0, 0.5)',
            zIndex: 1,
        },
    },
    catalogItemRoleCheckbox: {
        ...item.abs,
        top: 8,
        right: 4,
        zIndex: 2,
    },
    catalogItemRolesCellText: {
        ...item.abs,
        ...grid.w100,
        ...text.white,
        ...text.center,
        ...text.up,
        ...item.ellipsisPure,
        lineHeight: 1.2,
        fontSize: '5vw',
    },
    catalogItemRolesCellContent: {
        ...grid.colCenter,
        ...item.rel,
    },
    catalogItemRolesCharLayer: {
        paddingBottom: '100%',
    },
    catalogItemRolesFooter: {
        ...grid.mt_mini,
        ...grid.w100,
    },
    catalogItemRolesInfo: {
        ...grid.w90,
    },
    catalogItemRolesName: {
        ...text.bold,
        ...text.md,
    },
    catalogItemRolesCheckbox: {
        ...item.abs,
        bottom: 10,
        right: 10,
    },
}

addStyles(itemRolesStyles)
export default itemRolesStyles
