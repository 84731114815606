import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../theme'
import { indents, layers } from 'blocks.app/config'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'

const sidebars = {
    right: '30%',
}

const modalStyles: IStyles = {
    wrapper: {
        ...grid.row,
        ...grid.center,
        ...grid.normalCenter,
        ...item.transition,
        ...item.fixed,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    wrapper_h: {
        paddingTop: 64,
    },
    /* sidebars */
    sidebarsWrapper: {
        ...grid.row,
        ...grid.full,
    },
    sidebarsContent: {
        width: 0,
        ...grid.space,
        ...grid.fullHeight,
        background: `var(--var-module-app-component-app-style-background, ${defaultThemeStyles.background})`,
    },
    sidebarsLeft: {
        ...grid.fullHeight,
        ...item.greyLight,
    },
    sidebarsRight: {
        ...grid.fullHeight,
        ...item.shadow,
        overflowY: 'auto',
        width: sidebars.right,
        ...item.white,
    },
    /* /sidebars */
    content: {
        ...grid.full,
    },
    white: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
    },
    opacity: {
        backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },
    clear: {
        background: 'none',
    },
    black: {
        ...item.blackOpacity,
    },
    button: {
        ...item.abs,
        right: indents.md,
        top: indents.md,
        zIndex: layers.drop,
    },
    button_h: {
        ...item.abs,
        right: indents.md,
        top: 90,
        zIndex: layers.drop,
    },
    modalOpen: {
        ...grid.full,
        ...item.overNone,
    },
}

addStyles(modalStyles)
export default modalStyles
