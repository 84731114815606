import { addStyles } from 'ethcss'
import { grid, IStyles } from '../../../theme'
import { mediaCSS, textSizes } from 'theme/media'

const filesEditStyles: IStyles = {
    wrapper: {
        ...grid.col,
        ...grid.justify,
        ...grid.full,
        ...grid.p_md,
    },
    title: {
        fontWeight: 'bold',
        display: 'block',
        width: '100%',
        textAlign: 'center',
        [mediaCSS.tabMini]: {
            ...grid.mb_md,
        },
    },
    content: {
        ...grid.colCenter,
        ...grid.full,
        [mediaCSS.tabMini]: {
            ...grid.mb_md,
        },
    },
    form: {
        ...grid.w30,
        [mediaCSS.mob]: {
            ...grid.w100,
        },
        ...grid.row,
    },
    inputTitle: {
        textTransform: 'uppercase',
        display: 'block',
        width: '100%',
        textAlign: 'center',
        fontSize: textSizes.normalPlus,
        ...grid.mb_mini,
    },
    inputWrapper: {
        ...grid.w100,
        ...grid.mb_md,
    },
    footer: {
        ...grid.row,
        ...grid.center,
        ...grid.noWrap,
    },
}

addStyles(filesEditStyles)
export default filesEditStyles
