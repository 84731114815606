import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { IStyles, mediaCSS } from '../../../theme'

const appNotificationsStyles: IStyles = {
    downloadsWrapper: {
        lineHeight: 1.3,
        wordSpacing: 2,
        textAlign: 'center',
    },
    downloadsLink: {
        ...grid.mt_micro,
        ...grid.mb_micro,
    },
    userInactivityText: {
        lineHeight: 1.3,
    },
    fullWidth: {
        [mediaCSS.tab]: {
            width: '240px !important',
        },
    },
}

addStyles(appNotificationsStyles)
export default appNotificationsStyles
