import React from 'react'
import { Radio } from 'atoms/Radio'
import EditText from 'blocks.simple/editText/editText'
import RangeVolume from 'blocks.simple/range/_volume/range_volume'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './deviceSettings__megafon.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { Typography } from 'atoms/Typography'

type Settings = {
    shopCode: string
    tabletMode: string
    inactivityTimeout: number
    prefixWebsite: string
    batteryLevelForShutdown: string
}

const DeviceSettingsMegafon = (p_: {
    enabled: boolean
    settings: Settings
    onChange: (value: any, field: string) => void
    className: string | object
    platform: string
}) => {
    if (!p_.enabled) {
        return null
    }

    let inactivityTimeout = p_.settings && p_.settings.inactivityTimeout ? p_.settings.inactivityTimeout : 60000

    if (inactivityTimeout) {
        inactivityTimeout = inactivityTimeout / 1000
    }

    function onChange(value: string, field: string) {
        const settings = {
            ...p_.settings,
            [field]: value,
        }

        p_.onChange(settings, 'megafon')
    }

    return (
        <div className={cn(p_.className)}>
            <div className={styles.content}>
                <EditText
                    mod={'withBorder'}
                    label={translate('shopCode')}
                    value={p_.settings && p_.settings.shopCode ? p_.settings.shopCode : ''}
                    containerClass={styles.inputs}
                    onChange={function (shopCode: string) {
                        onChange(shopCode, 'shopCode')
                    }}
                />
                <EditText
                    mod={'withBorder'}
                    label={translate('prefixWebsite')}
                    value={p_.settings && p_.settings.prefixWebsite ? p_.settings.prefixWebsite : ''}
                    containerClass={cn(styles.inputs, grid.mt_md)}
                    onChange={function (prefixWebsite: string) {
                        onChange(prefixWebsite, 'prefixWebsite')
                    }}
                />
                <Typography className={cn(styles.secondTitle)} type="title">
                    {translate('tabletMode')}
                </Typography>
                <div className={styles.radioWrapper}>
                    <Radio
                        className={styles.radio}
                        onClick={function () {
                            onChange('user', 'tabletMode')
                        }}
                        label={translate('userMode')}
                        selected={p_.settings && p_.settings.tabletMode ? p_.settings.tabletMode === 'user' : true}
                    />
                    <Radio
                        onClick={function () {
                            onChange('consultant', 'tabletMode')
                        }}
                        label={translate('consultantMode')}
                        selected={
                            p_.settings && p_.settings.tabletMode ? p_.settings.tabletMode === 'consultant' : false
                        }
                    />
                </div>
                <EditText
                    mod={'withBorder'}
                    containerClass={styles.inputs}
                    value={inactivityTimeout}
                    label={translate('userInactivityTimeout')}
                    type="number"
                    onChange={function (inactivityTimeout: any) {
                        if (inactivityTimeout.length) {
                            inactivityTimeout = inactivityTimeout * 1000
                        }

                        onChange(inactivityTimeout, 'inactivityTimeout')
                    }}
                />
                {p_.platform === 'android' && (
                    <div className={styles.inputs}>
                        <Typography className={cn(styles.secondTitle)} type="title">
                            {translate('batteryLevelForShutdown')}
                        </Typography>
                        <RangeVolume
                            onChange={function (batteryLevelForShutdown: string) {
                                onChange(batteryLevelForShutdown, 'batteryLevelForShutdown')
                            }}
                            volume={
                                p_.settings && p_.settings.batteryLevelForShutdown
                                    ? p_.settings.batteryLevelForShutdown
                                    : 0
                            }
                            icons={{
                                min: 'volume_minus',
                                max: 'volume_plus',
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default React.memo(DeviceSettingsMegafon)
