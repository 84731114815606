import translate from 'core/translate'

interface IConfig {
    actionFields: Fields[]
    objectFields: Fields[]
    filtersBy: Fields[]

    [index: string]: any
}

type Fields = {
    id: string
    name: string
}

export const getFilterConfing = (): IConfig => {
    return {
        actionFields: [
            { id: 'create', name: translate('toCreate') },
            { id: 'update', name: translate('toUpdate') },
            { id: 'delete', name: translate('toDelete') },
        ],
        objectFields: [
            { id: 'digitalSignage', name: translate('byDigitalSignage') },
            { id: 'group', name: translate('byGroup') },
            { id: 'source', name: translate('bySource') },
            { id: 'sourceFolder', name: translate('byFolder') },
            { id: 'broadcast', name: translate('byBroadcast') },
            { id: 'broadcastFolder', name: translate('byBroadcastFolder') },
            { id: 'schedule', name: translate('bySchedule') },
            { id: 'advertisingCampaign', name: translate('byAdv') },
            { id: 'role', name: translate('byRole') },
            { id: 'fillersAdBlock', name: translate('byAdBlock') },
            { id: 'scoreboard', name: translate('byScoreboard') },
            { id: 'user', name: translate('byUser') },
            { id: 'digitalSignageUpdateDistributive', name: translate('byUpdateDistributive') },
        ],
        filtersBy: [
            { id: 'action', name: translate('byActions') },
            { id: 'object', name: translate('byEntity') },
            { id: 'userId', name: translate('byUsers') },
            { id: 'date', name: translate('byDate') },
        ],
    }
}
