import { CatalogSortTypesEnum } from 'core/models/Catalogs'
import { DEFAULT_DIGITAL_SIGNAGES_ADVANCED_SEARCH_PARAMETERS } from 'core/models/AdvancedSearch'
import { IUser, IUserTokens, IUserDecodeToken } from 'core/models/Users'
import { isExist } from 'core/utils/coreUtil'
import jwt_decode from 'jwt-decode'
import { IHTTPRequestHeaders } from 'core/models/Request'
import { numberToString } from 'core/utils/convertUtil'
import merge from 'merge'

export const getFromUserId = (mainUserId: number, userId: number) => {
    if (mainUserId !== userId) {
        return userId
    }

    return null
}

export const getParamsForLink = (tokens: IUserTokens, mainUserId: number, userId: number): string => {
    const accessToken = isExist(tokens) ? tokens.accessToken : null
    let params = ''

    if (accessToken) {
        params = `token=${accessToken}`
    }

    if (getFromUserId(mainUserId, userId)) {
        params += `&fromUserId=${userId}`
    }

    return params
}

export const authType = {
    smartplayer: 'smartplayer',
    activeDirectory: 'activeDirectory',
}

export const isActiveDirectory = (type: string) => {
    return type === authType.activeDirectory
}

export const getSelectedItemsId = ({ selectedInfo, selectedItems }: { selectedInfo: any; selectedItems: any }) => {
    if (selectedInfo || selectedItems.length) {
        return selectedInfo ? selectedInfo.id : selectedItems.map((item: any) => item.id)
    }
    return null
}

export const getDefaultTheme = () => 'light'
export const getDefaultMainMenuTheme = () => 'dark'

export const getDefaultLocale = () => {
    const locale = getLocaleFromUrl()
    if (locale) {
        if (locale === 'ru' || locale === 'en' || locale === 'es' || locale === 'pt' || locale === 'kz') {
            return locale
        }
    }

    return getUserLocaleFromNavigator() || 'en'
}

const getLocaleFromUrl = () => {
    const hash = window.location.hash
    if (hash.includes('lang')) {
        return hash.split('=')[1]
    }
}

export const getUserLocaleFromNavigator = () => {
    const currentLocale = navigator.language

    switch (currentLocale) {
        case 'ru-RU':
            return 'ru'
        case 'en-EN':
            return 'en'
        case 'es-ES':
            return 'es'
        case 'pt-PT':
            return 'pt'
        case 'kz-KZ':
            return 'kz'
        default:
            return null
    }
}

export const getLocaleDropdownValue = () => {
    const list = [
        { value: 'ru', label: 'Русский' },
        { value: 'en', label: 'English' },
        { value: 'es', label: 'Español' },
        { value: 'pt', label: 'Português' },
        { value: 'kz', label: 'Қазақ' },
    ]
    const defaultLocale = getDefaultLocale()
    const sortedLocale = list.filter((lang: any) => lang.value === defaultLocale)

    if (sortedLocale.length) {
        return sortedLocale[0]
    }

    return { value: 'en', label: 'English' }
}

const getTheme = (): string => {
    const theme = localStorage.getItem('sm-settings')

    if (!theme) return getDefaultTheme()

    try {
        const themeSettings = JSON.parse(theme)
        return themeSettings.theme || getDefaultTheme()
    } catch (err) {
        console.error(err)
        return getDefaultTheme()
    }
}

const getMainMenuTheme = (): string => {
    const mainMenuTheme = localStorage.getItem('sm-settings')

    if (!mainMenuTheme) return getDefaultMainMenuTheme()

    try {
        const themeSettings = JSON.parse(mainMenuTheme)
        return themeSettings.mainMenuTheme || getDefaultMainMenuTheme()
    } catch (err) {
        console.error(err)
        return getDefaultMainMenuTheme()
    }
}

export const getInitUser = () => {
    return {
        location: { lat: 55.75222, lng: 37.61556 },
        permissions: [],
        multipleAccounts: [],
    }
}

export const getDefaultUserSettings = () => {
    const locale = getDefaultLocale()
    const theme = getDefaultTheme()
    const mainMenuTheme = getMainMenuTheme()

    return {
        locale,
        deviceBlockStyle: 'simple',
        theme,
        mainMenuTheme,
        view: {
            broadcast: 'tile',
            digitalSignage: 'tile',
            scoreboards: 'tile',
            videoChat: 'tile',
            schedule: 'month',
            timetable: 'month',
            files: 'tile',
            advertisingCampaign: 'tile',
            courses: 'list',
            teachers: 'list',
            grades: 'list',
            subjects: 'list',
            classrooms: 'list',
            users: 'tile',
            draftDevices: 'tile',
            tasks: 'list',
            videoEditorTasks: 'list',
            reportTasks: 'list',
            fillersAdBlocks: 'tile',
            roles: 'tile',
            wine: 'list',
            products: 'referenceBook',
        },
        sort: {
            broadcast: '-createdAt',
            digitalSignage: '-createdAt',
            videoChat: '-createdAt',
            files: '-createdAt',
            advertisingCampaign: '-createdAt',
            courses: '-createdAt',
            teachers: '-createdAt',
            grades: '-createdAt',
            subjects: '-createdAt',
            classrooms: '-createdAt',
            users: '-createdAt',
            draftDevices: '-createdAt',
            reportTasks: '-createdAt',
            scoreboards: '-createdAt',
            tasks: '-createdAt',
            videoEditorTasks: CatalogSortTypesEnum.STATUS,
            fillersAdBlocks: CatalogSortTypesEnum.CREATED_AT,
            digitalSignagesTreeView: CatalogSortTypesEnum.CREATED_AT,
            broadcastsTreeView: CatalogSortTypesEnum.CREATED_AT,
            filesTreeView: CatalogSortTypesEnum.CREATED_AT,
        },
        dateFilter: {
            advertisingCampaign: 'current',
        },
        advancedSearch: {
            digitalSignages: DEFAULT_DIGITAL_SIGNAGES_ADVANCED_SEARCH_PARAMETERS,
        },
        pitamixCatalogSelectedSource: {},
        askonaCatalogSelectedSource: {},
        samsoniteCatalogSelectedSource: {},
        burgersCatalogSelectedSource: {},
        broadcastAreaViewType: 'y',
    }
}

export const decorateUserWithDefaultSettings = (user: IUser) => {
    const userSettings = getDefaultUserSettings()
    const initUser = getInitUser()
    const theme = getTheme()
    const mainMenuTheme = getMainMenuTheme()
    const settings = { ...userSettings, theme, mainMenuTheme }

    return merge.recursive(true, { ...initUser, settings }, user)
}

export const getUser = (): IUser | null => {
    const userJson = localStorage.getItem('authorization__user')

    if (!userJson) return null

    try {
        const user = JSON.parse(userJson)
        return decorateUserWithDefaultSettings(user)
    } catch (e) {
        console.error(e)
        return null
    }
}

export const getMainUser = (): IUser | null => {
    const mainUser = localStorage.getItem('main__user')

    if (!mainUser) return null

    try {
        return JSON.parse(mainUser) as IUser
    } catch (err) {
        console.error(err)
        return null
    }
}

export const getTokens = (): IUserTokens | null => {
    const tokens = localStorage.getItem('tokens__user')

    if (!tokens) return null

    try {
        return JSON.parse(tokens) as IUserTokens
    } catch (e) {
        console.error(e)
        return null
    }
}

export const clearUser = () => {
    localStorage.removeItem('authorization__user')
}

export const clearTokens = () => {
    localStorage.removeItem('tokens__user')
}

export const clearMainUser = () => {
    localStorage.removeItem('main__user')
}

export const setUser = (user: IUser) => {
    if (!user) return

    localStorage.setItem('authorization__user', JSON.stringify(user))
}

export const setMainUser = (mainUser: IUser) => {
    if (!mainUser) return

    localStorage.setItem('main__user', JSON.stringify(mainUser))
}

export const setTokens = (tokens: IUserTokens) => {
    if (!tokens) return

    localStorage.setItem('tokens__user', JSON.stringify(tokens))
}

export const getTokenDate = (tokens: IUserTokens) => {
    const accessTokenDecode: IUserDecodeToken = jwt_decode(tokens.accessToken)
    const accessTokenExpDate = new Date(accessTokenDecode.exp * 1000)
    const refreshTokenDecode: IUserDecodeToken = jwt_decode(tokens.refreshToken)
    const refreshTokenExpDate = new Date(refreshTokenDecode.exp * 1000)
    const difference =
        (new Date(accessTokenDecode.exp * 1000).valueOf() - new Date(accessTokenDecode.iat * 1000).valueOf()) / 5
    const accessTokenDate = new Date(accessTokenExpDate.valueOf() - difference)
    const now = new Date()

    return {
        now,
        timer: accessTokenDate.valueOf() - now.valueOf(),
        refreshTokenExpDate,
        accessTokenDate,
    }
}

export const createRequestHeaders = (mainUser: IUser, data: IUser, tokens: IUserTokens) => {
    let headers: IHTTPRequestHeaders = {}

    if (!mainUser || !data) return headers

    const fromUserId = getFromUserId(mainUser.id, data.id)

    if (tokens) {
        headers = {
            ...headers,
            Authorization: `Bearer ${tokens.accessToken}`,
        }
    }

    if (fromUserId) {
        headers = {
            ...headers,
            'SmartPlayer-From-User-Id': numberToString(fromUserId),
        }
    }

    return headers
}

export const getDispositionWhiteList = () => ['tile', 'list', 'map']
