import { addStyles } from 'ethcss'
import { colors, grid, IStyles, item } from '../../theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { mediaCSS, textSizes } from 'theme/media'

const authorizationStyles: IStyles = {
    authWrapper: {
        ...grid.row,
        ...item.cover,
    },
    title: {
        ...grid.row,
        ...grid.fullWidth,
        ...grid.center,
        ...grid.mt_md,
        ...grid.mb_big,
        fontSize: textSizes.big,
    },
    contentForm: {
        ...grid.row,
        ...grid.center,
    },
    error: {
        ...grid.pt_micro,
        ...grid.pb_mini,
        ...grid.w100,
        height: textSizes.normal,
        textAlign: 'center',
        color: `var(--var-module-app-component-app-style-error, ${defaultThemeStyles.error})`,
    },
    filler: {
        ...grid.row,
        ...grid.fullHeight,
        position: 'fixed',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    },
    centerFiller: {
        ...grid.w100,
    },
    leftFiller: {
        right: 0,
        ...grid.w70,
    },
    rightFiller: {
        left: 0,
        ...grid.w70,
    },
    content: {
        ...grid.colCenter,
        ...grid.justify,
        position: 'fixed',
        ...grid.fullHeight,
        ...grid.w30,
        [mediaCSS.tab]: {
            ...grid.w100,
        },
        ...grid.p_md,
        textAlign: 'center',
        backgroundColor: `var(--var-module-app-component-app-style-panel, ${defaultThemeStyles.panel})`,
        overflowY: 'auto',
    },
    leftContent: {
        left: 0,
    },
    rightContent: {
        right: 0,
    },
    centerContent: {
        left: '50%',
        transform: 'translateX(-50%)',
    },
    item: {
        marginBottom: 10,
    },
    logo: {
        //...grid.mb_big,
        ...grid.w70,
        height: 'auto',

        [mediaCSS.desktopL]: {
            marginTop: 20,
        },
    },
    restorePassword: {
        ...grid.row,
        ...grid.center,
        ...grid.normalCenter,
        ...grid.mt_mdPlus,
        height: 20,
    },
    formWrapper: {
        ...grid.w70,
        [mediaCSS.desktopML]: {
            ...grid.w90,
        },
    },
    copyright: {
        ...grid.p_md,
        fontSize: textSizes.md,
        '&>a': {
            textDecoration: 'none',
        },
    },
    dropdown: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',

        '& > div': {
            width: '130px',
            textAlign: 'left',
        },
    },
    authLinkBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        color: `var(--var-module-ui-component-button-style-secondary-textMain, #FFFFFF)`,
        background: `var(--var-module-ui-component-button-style-secondary-background, #003688)`,
        border: `1px solid var(--var-module-ui-component-button-style-secondary-border, transparent)`,
        boxShadow: `var(--var-module-ui-component-button-style-secondary-shadow)`,
        borderRadius: 20,
        textDecoration: 'none',
        height: 30,
        width: '100%',
    },
    orSeparator: {
        marginTop: 14,
        marginBottom: 14,
        color: 'hsl(0, 0%, 80%)',
    },
    checkboxLabel: {
        textAlign: 'left',
        maxWidth: 'calc(100% - 30px)',
        fontSize: 14,
    },
    personalData: {
        textDecoration: 'underline',
        marginLeft: '4px',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    registrationProceed: {
        marginTop: 14,
    },
    helpInfo: {
        fontSize: 10,
        textAlign: 'left',
        marginTop: 4,
        color: colors.grey,
    },
    dropDownIcon: {
        paddingRight: '10px !important',

        '& svg': {
            fill: colors.grey,
        },
        input: {
            textAlign: 'center !important',
        },
    },
    searchableInput: {
        textAlign: 'center !important',
    },
    notification: {
        ...grid.rowCenter,
        ...grid.normalCenter,
        ...grid.p_mini,
        fontSize: textSizes.normal,
        ...item.transition,
        ...item.fixed,
        color: colors.white,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: colors.errorLight,
    },
    googleBtnWrapper: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        margin: '0 auto',
    },
    quickLoginButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '70%',
        margin: '20px auto 0 auto',
    },
    quickLoginButtonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',

        [mediaCSS.desktopXL]: {
            width: 184,

            [mediaCSS.tab]: {
                width: '70%',
            },
        },
    },
    quickButton: {
        marginLeft: 8,
    },
}

addStyles(authorizationStyles)
export default authorizationStyles
