import React, { useEffect, useState } from 'react'
import moment from 'moment'
import helpers from 'core/helpers'

export interface IDeviceSettingsTimerProps {
    timers: any
    onChange: (timers: any) => void
    comPort?: boolean
    type?: string
    platform?: string
}

const DeviceSettingsTimer = (p_: IDeviceSettingsTimerProps) => {
    const [state, setState] = useState<{
        timers: any
        days: string[]
    }>({
        timers: p_.timers,
        days: helpers.getDays(),
    })

    useEffect(() => {
        setState((prevState: any) => {
            return {
                ...prevState,
                timers: p_.timers,
            }
        })
    }, [p_.timers])
    const onChangeTime = (value: string, type: string, index: number) => {
        const timers = helpers.deepCopy(state.timers)

        if (moment(value, 'HH:mm:ss', true).isValid()) {
            timers[type][index].time = value
        } else {
            timers[type][index].time = moment().format('HH:mm:ss')
        }

        p_.onChange(timers)
    }

    const onChangeRepeat = (selected: { setup: string }, type: string, index: number) => {
        const timers = helpers.deepCopy(state.timers)

        if (selected.setup !== 'weekly') {
            timers[type][index].repeatDays = []
        }
        timers[type][index].setup = selected.setup

        p_.onChange(timers)
    }

    const onSelectDays = (selected: { setup: string }, dayIndex: number, type: string, index: number) => {
        const timers = helpers.deepCopy(state.timers)

        if (selected) {
            timers[type][index].repeatDays.push(dayIndex)
        } else {
            timers[type][index].repeatDays = timers[type][index].repeatDays.filter((day: number) => day !== dayIndex)
        }

        p_.onChange(timers)
    }

    const onChangeVolume = (volume: number, type: string, index: number) => {
        const timers = helpers.deepCopy(state.timers)

        timers[type][index].volume = volume

        p_.onChange(timers)
    }
    const onChangeComPortNumber = (name: string, index: number) => {
        const timers = helpers.deepCopy(state.timers)

        timers.on[index].comPortNumber = name
        timers.off[index].comPortNumber = name

        p_.onChange(timers)
    }
    const onChangeRebootType = (type: string, field: string, index: number) => {
        const timers = helpers.deepCopy(state.timers)

        timers[field][index].rebootType = type

        p_.onChange(timers)
    }

    return {
        state,
        onChangeTime,
        onChangeRepeat,
        onSelectDays,
        onChangeVolume,
        onChangeComPortNumber,
        onChangeRebootType,
        setState,
    }
}

export default DeviceSettingsTimer
