import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import {defaultThemeStyles} from 'blocks.app/sidebar/Sidebar-theme'

const editorBroadcastSettings = {
    wrapper: {
        ...grid.col,
        ...grid.normalCenter,
        ...grid.full,
    },
    inputWrapper: {
        ...grid.w75,
        ...grid.mt_bigPlus,
        ...grid.mb_bigPlus,
    },
    input: {
        textAlign: 'center',
    },
    buttons: {
        ...grid.row,
        ...grid.center,
        ...grid.p_md,
    },
    volume: {
        ...grid.w70,
        ...item.shadow,
        ...item.rounded_full_normal,
    },
    tip: {
        ...grid.w65,
        textAlign: 'center',
        fontSize: 12,
        color: `var(--var-module-sidebar-component-sidebar-style-description, ${defaultThemeStyles.description})`
    },
}

addStyles(editorBroadcastSettings)
export default editorBroadcastSettings
