import React from 'react'
import { connect, RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Radio } from 'atoms/Radio'
import Collapse from 'blocks.simple/collapse/collapse'
import helpers from 'core/helpers'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { grid } from '../../../theme'
import { updateUserSettings } from 'blocks.app/user/user.state'
import { appActions } from 'blocks.app/app/app.state'
import styles from '../settings.jcss'

const viewOptions: string[] = ['horizontal', 'vertical']

const SettingsMenuView = () => {
    const dispatch = useDispatch()
    const settings: any = useSelector<RootStateOrAny>((state) => state.user.data.settings)
    const minimizeView = useSelector<RootStateOrAny>((state) => state.app.minimizeMenu)
    const lastIndex = viewOptions.length - 1

    return (
        <Collapse title={() => translate('viewOfMenu')} className={cn(styles.background, grid.mb_md)}>
            <div className={cn(grid.row)}>
                {viewOptions.map((option: string, index: number) => (
                    <div style={{ marginRight: '40px' }}>
                        <Radio
                            key={`device_menu_view_${option}`}
                            onClick={() => {
                                if (minimizeView) {
                                    dispatch(appActions.toggleMenuMinimized(false))
                                }

                                dispatch(updateUserSettings({ data: { mainMenuType: option }, isSaveSettings: true }))
                            }}
                            selected={settings.mainMenuType ? settings.mainMenuType === option : option === 'vertical'}
                            label={translate(option + 'Menu')}
                            disabled={!helpers.isAvailable({ key: 'settings', action: 'update' })}
                            className={cn({ [grid.mb_mini]: index !== lastIndex })}
                        />
                    </div>
                ))}
            </div>
        </Collapse>
    )
}

export default SettingsMenuView
