import { addStyles } from 'ethcss'
import { colors, grid, mediaCSS } from '../../theme'

const mediaWidthQuery = '@media (min-width: 960px)'

const dashboardStyles: any = {
    dashboardWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    content: {
        padding: '0 26px 65px 26px !important',
        overflow: 'hidden',

        [mediaCSS.tab]: {
            padding: '50px 0 34px 0 !important',
            overflow: 'auto',
        },
    },
    leftBlock: {
        ...grid.row,
        ...grid.justify,
        width: '100%',
        position: 'relative',

        '&:after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            width: '1px',
            height: '100%',
            backgroundColor: '#FFFFFF',
        },
    },
    top: {
        [mediaWidthQuery]: {
            top: '65px',
        },
    },
    item: {
        ...grid.col,
        flex: '1 1 440px',
        minHeight: '452px',
        borderBottom: '1px solid #E4E4E4',
        position: 'relative',
        borderRight: '1px solid #E4E4E4',

        [mediaCSS.tab]: {
            borderRight: 'none',
            minHeight: 0,
        },

        '&::before': {
            content: '""',
            position: 'absolute',
            bottom: '-1px',
            left: '-200px',
            height: '1px',
            width: '200px',
            backgroundColor: '#E4E4E4',

            [mediaCSS.tab]: {
                content: 'none',
            },
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-1px',
            right: '-200px',
            height: '1px',
            width: '200px',
            backgroundColor: '#E4E4E4',

            [mediaCSS.tab]: {
                content: 'none',
            },
        },
    },
    memory: {
        flex: '1 1 880px',
    },
    footer: {
        ...grid.row,
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        right: 0,
        width: '100%',
        backgroundColor: colors.white,
        height: '66px',
        borderTop: '1px solid #E4E4E4',
        paddingLeft: '10px',
        paddingRight: '10px',
        color: colors.grey,

        [mediaCSS.tabMini]: {
            justifyContent: 'space-between',
        },
    },
    documentationLink: {
        color: '#2582E0',
        fontSize: '24px',
        fontWeight: 'bold',
        marginRight: 'auto',
        paddingLeft: 'calc(15.1041666667% + 26px)',
        [mediaCSS.tab]: {
            paddingLeft: 0,
        },
        [mediaCSS.mini]: {
            width: '100%',
            fontSize: '18px',
        },
    },
    footerText: {
        color: `${colors.grey} !important`,

        [mediaCSS.tabMini]: {
            width: '60%',
        },
    },
    refreshButton: {
        marginLeft: '20px',
    },
}

addStyles(dashboardStyles)
export default dashboardStyles
