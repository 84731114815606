import React, { useCallback, useEffect, useState } from 'react'
import styles from './Filter-styles'
import Icon from 'blocks.simple/icon/icon'
import Button from 'blocks.simple/button/button'
import { colors } from '../../../theme'
import { FilterInner } from '../FilterInner/FilterInner-view'
import translate from '../../../core/translate'
import { FilterAdd } from '../FilterAdd/FilterAdd-view'
import { cn } from 'ethcss'
import {
    getCompatibleFilters,
    appliedFiltersFromQuery,
    onApplyFilterToQuery,
    getSomeValues,
    getFiltersFromQuery,
} from 'core/helpers/filters'
import { IFilter, Option } from './Filter-types'
import DotTooltip from '../../../blocks.simple/dotTooltip/dotTooltip'
import { useLocation } from 'react-router'
import { AdvancedSearch } from 'organisms/AdvancedSearch'
import { ALLOW_ADVANCED_SEARCH } from '../../catalog/catalog'
import { useClickOutside } from 'react-click-outside-hook'
import { Typography } from '../../../atoms/Typography'
import { useSelector } from 'react-redux'
import { IRootState } from '../../../core/store/rootReducer'
import { themeFromStore } from 'core/helpers/menu'

const FilterItem = ({ activeFilter, changeVariant, multiselect, deleteItem, updateFilterList }: any) => {
    const [isOpened, setIsOpened] = useState(false)

    const closeFilterInner = () => {
        updateFilterList()
        setIsOpened(false)
    }

    return (
        <div className={styles.filterItem}>
            <div className={styles.deleteItem}>
                <Icon
                    className={styles.deleteIcon}
                    onClick={() => deleteItem(activeFilter.name)}
                    type={'minus_circle'}
                    color={'#b3b3b3'}
                />
            </div>
            <div className={styles.filterLabel}>
                <Typography type="text">{translate(activeFilter.name)}</Typography>
            </div>
            <div className={cn(styles.selectedValue, isOpened ? styles.isOpened : '')}>
                <DotTooltip tooltip={{ html: getSomeValues(activeFilter) }}>
                    <div
                        className={styles.selectedText}
                        onClick={() => setIsOpened(!isOpened)}
                        dangerouslySetInnerHTML={{ __html: getSomeValues(activeFilter) }}
                    />
                </DotTooltip>
                {isOpened && (
                    <>
                        <FilterInner
                            containerClassName={styles.filterInner}
                            filter={activeFilter}
                            changeVariant={changeVariant}
                            multiselect={multiselect}
                            onClose={closeFilterInner}
                        />
                    </>
                )}
            </div>
        </div>
    )
}

export const Filter = ({
    onChangeFilterStatus,
    availableFilters,
    setAvailableFilters,
    setSelectedFilters,
    selectedFilters,
    type,
    isAllowAdvancedSearch,
    onFilterChange,
    onChangeADSearch,
    getAdvancedSearch,
    advancedSearchState,
}: any) => {
    const [activeFilters, setActiveFilters] = useState<IFilter[]>(selectedFilters)
    const [modalFilterOpened, setModalFilterOpened] = useState(false)
    const [isAddFilterOpened, setIsAddFilterOpened] = useState(false)
    const [searchReset, setSearchReset] = useState(false)
    const [filtersIsInit, setFiltersIsInit] = useState(false)
    const [activeFiltersLength, setActiveFiltersLength] = useState(0)
    const location = useLocation()
    const [filterRef, hasClickedOutside] = useClickOutside()
    const allowedSearch: any = ALLOW_ADVANCED_SEARCH
    const advSearchSettings = useSelector<IRootState | any>(
        (store) => store.user.data.settings.advancedSearch[allowedSearch[type]]
    )

    //удалить фильтр из списка
    const deleteItem = useCallback(
        (name: string) => {
            setActiveFilters(activeFilters.filter((item: IFilter) => item.name !== name))
        },
        [activeFilters]
    )

    //изменение опции фильтра
    const changeVariant = useCallback(
        (filterItem: any) => {
            setActiveFilters(
                activeFilters.map((filter: IFilter) => {
                    if (filter.name === filterItem.name) {
                        const options = filter.options.map((option: Option) => {
                            if (filterItem.option.label === option.label) {
                                return filterItem.option
                            }

                            return option
                        })

                        return {
                            ...filter,
                            options,
                        }
                    }

                    return filter
                })
            )
        },
        [activeFilters]
    )

    //выбрать все опции у фильтра
    const multiselect = useCallback(
        (filterName: string, state: boolean) => {
            setActiveFilters(
                activeFilters.map((filter: IFilter) => {
                    if (filter.name === filterName) {
                        return {
                            ...filter,
                            options: filter.options.map((option: Option) => ({ ...option, selected: state })),
                        }
                    }

                    return filter
                })
            )
        },
        [activeFilters]
    )

    //добавить выбранный фильтр в список
    const onAddFilter = useCallback(
        (filterItem: IFilter) => {
            setActiveFilters([...activeFilters, filterItem])
            onCloseAddFilter()
        },
        [activeFilters]
    )

    //закрыть окно выбора фильтров из общего списка
    const onCloseAddFilter = () => {
        setIsAddFilterOpened(false)
    }

    //актуализировать список фильтров через запрос на сервер
    const updateFilterList = useCallback(() => {
        const filters = !filtersIsInit ? getFiltersFromQuery(availableFilters, location, type) : activeFilters
        // @ts-ignore
        let advancedSearchParams: any = { operator: advSearchSettings.operator }

        if (advSearchSettings) {
            // @ts-ignore
            if (advSearchSettings.enabledQ) {
                // @ts-ignore
                if (advSearchSettings.exactly.activeQ) {
                    // @ts-ignore
                    advancedSearchParams.separator = advSearchSettings.exactly.separator
                }
                // @ts-ignore
                advancedSearchParams.operator = advSearchSettings.operator
            }
        }

        getCompatibleFilters(
            filters,
            location,
            type,
            // @ts-ignore
            advSearchSettings.enabledQ ? advancedSearchParams : {}
        ).then((res: any) => {
            const filteredArray: IFilter[] = []

            activeFilters.forEach((filter) => {
                const activeFilter = res.find((element: IFilter) => filter.name === element.name)

                if (activeFilter) {
                    filteredArray.push(activeFilter)
                }
            })

            setAvailableFilters(res)
            setSelectedFilters(filteredArray)
        })
    }, [activeFilters, location, filtersIsInit, advSearchSettings])

    //поиск с примененными фильтрами
    const searchByFilters = useCallback(() => {
        getCompatibleFilters(activeFilters, location, type, getAdvancedSearch()).then((res: any) => {
            const filteredArray = res.filter((element: any) => activeFilters.some((item) => element.name === item.name))

            setSelectedFilters(filteredArray)
            onFilterChange(onApplyFilterToQuery(filteredArray, advSearchSettings))
            setModalFilterOpened(false)
        })
    }, [activeFilters, location, advancedSearchState, advSearchSettings])

    //очистить список выбранных фильтров
    const resetFilter = () => {
        setActiveFilters([])
        setSearchReset(true)
    }

    //актуализировать список фильтров, если 1 из выбранных фильтров был удален из списка
    useEffect(() => {
        if (activeFiltersLength > activeFilters.length) {
            updateFilterList()
        }

        setActiveFiltersLength(activeFilters.length)
    }, [activeFiltersLength, activeFilters.length])

    //закрыть окно фильтров, если произошел внешний клик
    useEffect(() => {
        if (hasClickedOutside) {
            if (modalFilterOpened) {
                searchByFilters()
            }
        }
    }, [hasClickedOutside])

    //синхронно актуализировать список активных фильтров при изменении списка выбранных фильтров
    useEffect(() => {
        setActiveFilters(selectedFilters)
    }, [selectedFilters])

    //если список фильтров пуст, не показывать ленту с фильтрами вместо хлебных крошек
    useEffect(() => {
        onChangeFilterStatus(!!selectedFilters.length)
    }, [selectedFilters])

    //установить актуалные фильтры при изменении адресной строки браузера
    useEffect(() => {
        if (!availableFilters.length) return

        if (!filtersIsInit) {
            setSelectedFilters(appliedFiltersFromQuery(availableFilters, location, type))
            setFiltersIsInit(true)
        }
    }, [filtersIsInit, availableFilters, location])

    //актуализировать фильтры при изменении параметров насширенного поиска
    useEffect(() => {
        updateFilterList()
    }, [advSearchSettings])

    useEffect(() => {
        if (filtersIsInit) {
            updateFilterList()
        }
    }, [location])

    return (
        <div ref={filterRef} className={styles.filter}>
            <Button
                mod={themeFromStore() === 'custom' ? 'withLightBorder' : 'withBorderVR'}
                className={styles.filterBtn}
                iconPos="left"
                rounded="none"
                indentSize="normal"
                onClick={() => setModalFilterOpened(!modalFilterOpened)}
                fullWidth={true}
                animation={false}
            >
                <Icon type="new_filter_icon" color={colors.grey} size="18" />
            </Button>
            {modalFilterOpened && (
                <>
                    <div className={styles.filterModal}>
                        {isAllowAdvancedSearch && (
                            <AdvancedSearch
                                type={allowedSearch[type]}
                                onChange={(advancedSearchSettings: any) => {
                                    onChangeADSearch({
                                        separator: advancedSearchSettings.exactly.separator
                                            ? advancedSearchSettings.exactly.separator
                                            : ' ',
                                        operator: advancedSearchSettings.operator,
                                    })
                                }}
                                onChangeADSearch={onChangeADSearch}
                                setSearchReset={setSearchReset}
                                searchReset={searchReset}
                            />
                        )}
                        <div className={styles.filterList}>
                            {activeFilters.map((activeFilter: IFilter, index: number) => (
                                <FilterItem
                                    key={index}
                                    activeFilter={activeFilter}
                                    changeVariant={changeVariant}
                                    multiselect={multiselect}
                                    deleteItem={deleteItem}
                                    updateFilterList={updateFilterList}
                                />
                            ))}
                        </div>
                        {availableFilters.filter(
                            (filter: any) => !activeFilters.some((item: IFilter) => item.name === filter.name)
                        ).length ? (
                            <div className={styles.addFilter}>
                                <div className={styles.addFilterLabel}>
                                    <Typography type="text">{translate('addFilter')}</Typography>
                                </div>
                                <div className={styles.addFilterBtnWrapper}>
                                    <Icon
                                        onClick={() => setIsAddFilterOpened(true)}
                                        type={'plus_circle'}
                                        color={'#b3b3b3'}
                                    />
                                    {isAddFilterOpened && (
                                        <>
                                            <FilterAdd
                                                containerClass={styles.filterAddStyles}
                                                availableFilters={availableFilters}
                                                filterList={activeFilters}
                                                onAddFilter={onAddFilter}
                                                onClose={onCloseAddFilter}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        ) : null}
                        <div className={styles.navButtonsWrapper}>
                            <Button mod={'withBorder'} className={styles.navBtn} onClick={resetFilter}>
                                {translate('reset')}
                            </Button>
                            <Button mod={'fill'} className={styles.navBtn} onClick={searchByFilters}>
                                {translate('search')}
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
