import React from 'react'

import EditableText from 'blocks.simple/editableText/editableText'
import Button from 'blocks.simple/button/button'
import RangeVolume from 'blocks.simple/range/_volume/range_volume'
import EditorEvents from '../__events/editor__events'

import { editor } from '../editor.local.methods'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './editor__durationSettings.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import settingsStyles from '../__areaSettings/editor__areaSettings.jcss'
import helpers from 'core/helpers'

class EditorDurationSettings extends React.Component {
    constructor(p_) {
        super(p_)

        const { volume, isEqual } = editor.getPageVolume()

        this.state = {
            volume,
            isEqual,
        }
    }

    checkAvailableVolume = (page) => {
        if (!page) return false

        const types = ['video', 'audio', 'web_app', 'web_page']
        let available = false

        page.areas.forEach((area) => {
            for (let i = 0; i < area.content.length; i++) {
                const source = area.content[i].source

                if (types.includes(source.fileType)) available = true
            }
        })

        return available
    }

    render() {
        const s_ = this.state
        const p_ = this.props

        return (
            <div className={styles.wrapper}>
                <div className={cn(styles.inputWrapper)}>
                    <EditableText
                        id={'duration'}
                        label={translate('durationSec')}
                        placeholder={' '}
                        className={cn(styles.input)}
                        type={'number'}
                        hideIcon={true}
                        mod={'withBorder'}
                        text={p_.duration}
                        onEndTyping={function (duration) {
                            editor.setPageDuration(duration)
                        }}
                    />

                    <div className={cn(grid.w100, grid.rowCenter, grid.mt_md)}>
                        <Button
                            rounded="full_md"
                            indentSize="mini"
                            mod="fill"
                            onClick={function () {
                                editor.setContentPageDuration()
                            }}
                        >
                            {translate('durationByContent')}
                        </Button>
                    </div>
                </div>
                <div className={cn(grid.w100, settingsStyles.title, settingsStyles.borderTop)}>
                    <div className={item.ellipsis}>{translate('volumeSettings')}</div>
                </div>
                <div className={styles.volume}>
                    <RangeVolume
                        volume={s_.volume}
                        onChange={(volume) => this.setState({ volume, isEqual: true })}
                        onAfterChange={(volume) => {
                            editor.setPageVolume(volume)
                        }}
                        disabled={!this.checkAvailableVolume(p_.page)}
                    />
                </div>
                {!s_.isEqual && (
                    <div className={grid.mt_md}>
                        <div className={grid.colCenter}>
                            <div className={cn(styles.tip, grid.mb_md)}>{translate('durationSettingsTip1')}</div>
                            <div className={styles.tip}>{translate('durationSettingsTip2')}</div>
                        </div>
                    </div>
                )}
                {!this.checkAvailableVolume(p_.page) && (
                    <div className={grid.mt_md}>
                        <div className={grid.colCenter}>
                            <div className={cn(styles.tip, grid.mb_md)}>{translate('noSoundSupportContentTip')}</div>
                        </div>
                    </div>
                )}
                {!p_.isSimple && helpers.isAvailable({ key: 'broadcastEventsAndActions', action: 'create' }) && (
                    <EditorEvents pages={p_.pages} page={p_.page} />
                )}
                <div className={grid.space} />

                <div>
                    <div className={styles.buttons}>
                        <Button
                            mod={'withBorder'}
                            rounded="full_md"
                            indentSize="mini"
                            onClick={function () {
                                editor.hideSettings()
                            }}
                            className={cn(grid.mr_md)}
                        >
                            {translate('cancel')}
                        </Button>
                        <Button
                            rounded="full_md"
                            indentSize="mini"
                            mod="fill"
                            onClick={function () {
                                editor.hideSettings()
                            }}
                        >
                            {translate('save')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditorDurationSettings
