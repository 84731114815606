import React from 'react'
import EditText from 'blocks.simple/editText/editText'
import translate from 'core/translate'
import { Typography } from 'atoms/Typography'
import { cn } from 'ethcss'
import styles from './deviceSettings__contentStorage.jcss'

const DeviceSettingsContentStorage = (p_: {
    enabled: boolean
    className: string | object
    value: string
    onChange: (value: string, path: string) => void
}) => {
    if (!p_.enabled) {
        return null
    }

    return (
        <div className={cn(p_.className)}>
            <Typography className={cn(styles.title)} type="title">
                {translate('contentStoragePath')}
            </Typography>
            <div className={styles.inputWrapper}>
                <EditText
                    mod={'withBorder'}
                    value={p_.value}
                    placeholder="D:\example\"
                    onChange={function (value: string) {
                        p_.onChange(value, 'contentStoragePath')
                    }}
                />
            </div>
        </div>
    )
}

export default React.memo(DeviceSettingsContentStorage)
