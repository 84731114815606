import {
    IDownloadBroadcast,
    IDownloadBroadcastRaw,
    IDownloadData,
    IDownloadDevice,
    IDownloadFile,
    IDownloadInfo,
    IDownloadRaw,
    IDownloadUnkown,
    TDownloadType,
} from 'core/models/Downloads'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { generateIdByUUID, isNotEmptyArray } from 'core/utils/coreUtil'
import helpers from 'core/helpers'
import { downloadStartNotification } from 'blocks.app/app/__notifications/app__notifications'
import { listeners } from 'core/helpers'
import { api } from 'core/api/ConnectionManager'
import { IFile, IUploadFile } from 'core/models/Files'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { createRequestHeaders } from 'core/helpers/user'
import { isFunction } from 'lodash'
import { IUserState } from 'blocks.app/user/user.state'

export interface UploadFilesPayload {
    filesList: any
    folderId: string
    broadcast?: boolean
    withConversion?: string
}

type DelayDownloadTask = {
    type: string
    targetId: number
    exportId: string
}

type DelayDownloadsProgress = {
    name: string
    percent: number
    exportId: string
}

export interface IDownloadsState {
    downloads: IDownloadData[]
    cachedDownloads: IDownloadData[]
    cachedUploads: IDownloadData[]
    uploads: IDownloadData[]
    isUploading: boolean
    isOpenModal: boolean
    isShowNotification: boolean
    queue: string[]
    delayDownloadTasks: DelayDownloadTask[]
    delayDownloadsProgressList: DelayDownloadsProgress[]
}

const MAX_QUEUE_LENGTH = 20
const uploadFileMap = new Map<string, IUploadFile>()

let cachedDownloads: IDownloadData[] = []
let cachedUploads: IDownloadData[] = []

export const initialState: IDownloadsState = {
    cachedDownloads: [],
    downloads: [],
    cachedUploads: [],
    uploads: [],
    isUploading: false,
    isOpenModal: false,
    isShowNotification: true,
    queue: [],
    delayDownloadTasks: [],
    delayDownloadsProgressList: [],
}

const setUploadFile = (file: IUploadFile) => {
    uploadFileMap.set(file.id, file)
}

const deleteUploadFile = (id: string) => {
    return uploadFileMap.delete(id)
}

const getUploadFile = (id: string) => {
    return uploadFileMap.get(id)
}

const cancelUploadFile = (id: string) => {
    const uploadFile = getUploadFile(id)

    if (!uploadFile || !isFunction(uploadFile.cancel)) return

    uploadFile.cancel()
}

const updateUploadFileCancel = (id: string, cancelFn: () => void) => {
    const uploadFile = getUploadFile(id)

    if (!uploadFile) return

    uploadFile.cancel = cancelFn

    uploadFileMap.set(id, uploadFile)
}

const createFileFormData = (folderId: string, file: any, withConversion?: string) => {
    let formData = new FormData()
    formData.append('folderId', folderId)

    if (file.length) {
        file.forEach((el: any) => {
            formData.append('file', el)
        })
    } else {
        formData.append('file', file)
    }
    withConversion && formData.append('withConversion', withConversion)
    return formData
}

const showNotification = (addedDownload: IDownloadRaw) => {
    helpers.debounce(
        () => {
            downloadStartNotification(addedDownload)
        },
        { limit: 1000, id: 'download_global_notification' }
    )
}

const isValidDownload = ({ progress }: IDownloadData) => {
    return typeof progress === 'number' && !isNaN(progress)
}

const isEqual = <T extends Partial<IDownloadInfo>, N extends Partial<IDownloadInfo>>(data: T, comparedData: N) => {
    return data.id === comparedData.id && data.type === comparedData.type
}

const isSourceDownloadType = (type: TDownloadType) => type === 'sourceFromUrl'

const updateProgress = (download: IDownloadBroadcastRaw) => {
    let broadcastProgress = 0

    download.digitalSignage.forEach((display: any) => {
        const progress = parseInt(display.progress, 10)
        broadcastProgress += display.progress

        listeners.updateDisplayProgress({
            id: display.id,
            cover: 'ignore',
            downloadPercentage: progress === 100 ? [] : [{ percentage: display.progress, title: display.name }],
        })
    })

    const roundedBroadcastProgress = parseFloat((broadcastProgress / download.digitalSignage.length).toFixed(2))
    updateBroadcastProgress(download, roundedBroadcastProgress)
}

const updateBroadcastProgress = (download: any, progress: number) => {
    listeners.updateBroadcastProgress({
        id: download.id,
        cover: 'ignore',
        downloadPercentage: progress === 100 ? [] : [{ percentage: progress, title: download.name }],
    })
}

const createDownload = (download: IDownloadRaw): IDownloadData => {
    if (isSourceDownloadType(download.type)) return download as IDownloadFile

    let progress = 0

    const downloadChildren = (download as IDownloadBroadcastRaw).digitalSignage.map((device) => {
        const type: TDownloadType = 'digitalSignage'
        const deviceProgress = parseFloat(device.progress.toFixed(2))
        progress += device.progress

        return {
            id: device.id,
            name: device.name,
            children: device.files,
            type,
            parent: {
                id: download.id,
                type: download.type,
            },
            cancel: true,
            progress: deviceProgress,
        }
    })

    const broadcastProgress = parseFloat((progress / downloadChildren.length).toFixed(2))

    return {
        id: download.id,
        name: download.name,
        type: download.type,
        progress: broadcastProgress,
        cancel: true,
        children: downloadChildren,
    }
}

const addDownload = (downloads: IDownloadData[], addedDownload: IDownloadRaw): IDownloadData[] => {
    switch (addedDownload.type) {
        case 'file':
        case 'sourceFromUrl':
            return [addedDownload as IDownloadFile, ...downloads]
        default:
            updateProgress(addedDownload as IDownloadBroadcastRaw)
            const createdDownload = createDownload(addedDownload) as IDownloadBroadcast
            const isExistDownload = downloads.find((download: IDownloadData) => isEqual(download, createdDownload))

            if (!isExistDownload) {
                return [createdDownload, ...downloads]
            }

            return downloads.map((download: IDownloadData) => {
                if (!isEqual(download, createdDownload)) return download

                let downloadBroadcast = download as IDownloadBroadcast
                let isExistDevice = !!downloadBroadcast.children.find((device) =>
                    isEqual(device, createdDownload.children[0])
                )

                return isExistDevice
                    ? download
                    : { ...download, children: [...downloadBroadcast.children, ...createdDownload.children] }
            })
    }
}

const updateDownload = (downloads: IDownloadData[], updatedDownload: IDownloadRaw) => {
    return downloads.map((download) => {
        if (!isEqual(download, updatedDownload)) return download

        switch (updatedDownload.type) {
            case 'file':
            case 'sourceFromUrl':
                return {
                    ...download,
                    progress: updatedDownload.progress,
                } as IDownloadData
            default:
                updateProgress(updatedDownload as IDownloadBroadcastRaw)
                const createdDownload = createDownload(updatedDownload) as IDownloadBroadcast
                const constructedChild = createdDownload.children[0] as IDownloadDevice
                const downloadBroadcast = download as IDownloadBroadcast

                let progress = 0

                const children = downloadBroadcast.children.map((child) => {
                    if (isEqual(child, constructedChild)) {
                        progress += constructedChild.progress
                        return constructedChild
                    }

                    progress += child.progress
                    return child
                })

                const formattedProgress = parseFloat((progress / children.length).toFixed(2))

                return {
                    ...download,
                    progress: formattedProgress,
                    children,
                }
        }
    })
}

const removeDigitalSignage = (downloads: IDownloadData[], { parent, id }: IDownloadDevice) => {
    return downloads.reduce((filtered: IDownloadData[], download) => {
        if (!isEqual(download, parent)) return [...filtered, download]

        const downloadBroadcast = download as IDownloadBroadcast
        const children = downloadBroadcast.children.filter((device) => !(device.id === id))

        if (children.length > 0) return [...filtered, { ...download, children }]

        return filtered
    }, [])
}

const updateLoading = (payload: IDownloadRaw) => {
    const { progress, id } = payload

    if (!progress) return

    cachedUploads = cachedUploads.map((upload) => (upload.id === id ? { ...upload, progress } : upload))
    cachedDownloads = cachedDownloads.map((download) => (download.id === id ? { ...download, progress } : download))
}

export const cancelUpload = createAsyncThunk('downloads/cancelUpload', async (id: string, { dispatch }) => {
    cancelUploadFile(id)
    deleteUploadFile(id)
    dispatch(downloadsActions.removeLoading(id))
})

export const cancelFileDownload = createAsyncThunk(
    'donwloads/cancelFileDownload',
    async (download: IDownloadFile, { dispatch }) => {
        cancelUploadFile(download.id)
        deleteUploadFile(download.id)
        dispatch(downloadsActions.removeLoading(download.id))
    }
)

export const cancelDeviceDownload = createAsyncThunk(
    'donwloads/cancelDeviceDownload',
    async (download: IDownloadDevice, { dispatch }) => {
        const isComplete = Math.floor(download.progress) === 100

        if (isComplete) {
            dispatch(downloadsActions.removeDigitalSignage(download))
            return
        }

        try {
            await api.send('cancelClientDownload', {
                instanceId: download.parent.id,
                instanceType: download.parent.type,
                digitalSignageId: download.id,
            })

            dispatch(downloadsActions.removeDigitalSignage(download))
        } catch (err) {}
    }
)

export const cancelBroadcastDownload = createAsyncThunk(
    'donwloads/cancelBroadcastDownload',
    async (download: IDownloadBroadcast, { dispatch }) => {
        const isComplete = Math.floor(download.progress) === 100

        if (isComplete) {
            dispatch(downloadsActions.removeDownload(download))
            return
        }

        try {
            const uncompletedDeviceDownloads = download.children.reduce((filtered: string[], child) => {
                const isUncomplete = Math.floor(child.progress) !== 100
                return isUncomplete ? [...filtered, child.id] : filtered
            }, [])

            await api.send('cancelClientDownload', {
                instanceId: download.id,
                instanceType: download.type,
                digitalSignageId: uncompletedDeviceDownloads,
            })

            dispatch(downloadsActions.removeDownload(download))
        } catch (err) {}
    }
)

export const cancelDownload = createAsyncThunk(
    'downloads/cancelDownload',
    async (download: IDownloadUnkown, { dispatch }) => {
        switch (download.type) {
            case 'sourceFromUrl':
            case 'file':
                dispatch(cancelFileDownload(download as IDownloadFile))
                break
            case 'digitalSignage':
                dispatch(cancelDeviceDownload(download as IDownloadDevice))
                break
            default:
                dispatch(cancelBroadcastDownload(download as IDownloadBroadcast))
                break
        }
    }
)

export const cancelDownloadWithoutMessage = createAsyncThunk(
    'downloads/cancelDownloadWithoutMessage',
    async (download: IDownloadUnkown, { dispatch }) => {
        switch (download.type) {
            case 'digitalSignage':
                dispatch(downloadsActions.removeDigitalSignage(download as IDownloadDevice))
                break
            case 'sourceFromUrl':
            case 'file':
                dispatch(cancelFileDownload(download as IDownloadFile))
                break
            default:
                dispatch(downloadsActions.removeDownload(download as IDownloadBroadcast))
                break
        }
    }
)

export const emitFileUploadError = createAsyncThunk(
    'downloads/emitFileUploadError',
    async (err: number, { dispatch }) => {
        switch (err) {
            case 27:
                dispatch(emitError('27'))
                break
            case 64:
                dispatch(emitError('64'))
                break
            default:
                dispatch(emitError('uploadError'))
        }
    }
)

const uploadFile = createAsyncThunk('downloads/uploadFile', async (id: string, { dispatch, getState }) => {
    const state = getState() as { user: IUserState }
    const { data: user, mainUser, tokens } = state.user

    if (!user || !mainUser || !tokens) return

    let isCanceled = false
    const upload = getUploadFile(id)

    if (!upload) {
        dispatch(downloadsActions.removeUpload(id))
        dispatch(startUpload())
        return
    }

    const { folderId, file, broadcast, withConversion } = upload
    const formData = createFileFormData(folderId, file, withConversion)
    const uploadMethod = broadcast ? 'uploadBroadcast' : file.length ? 'uploadMultiplyFiles' : 'uploadFile'

    api.post(uploadMethod, formData, createRequestHeaders(mainUser, user, tokens))
        .on('progress', function (e) {
            const progress = e.percent ? Math.round(e.percent) : null

            if (progress) {
                const cancel = () => {
                    isCanceled = true
                    // @ts-ignore
                    this.xhr.abort()
                    dispatch(downloadsActions.removeLoading(id))
                    deleteUploadFile(id)
                }

                updateLoading({
                    type: 'file',
                    id,
                    name: file.name,
                    progress,
                })

                updateUploadFileCancel(id, cancel)
            }
        })
        .end((err, res) => {
            dispatch(downloadsActions.removeUpload(id))
            deleteUploadFile(id)

            if (err && !isCanceled) {
                dispatch(emitFileUploadError(err))
            }

            if (res && res.body.error) {
                dispatch(emitFileUploadError(res.body.error))
            }

            dispatch(startUpload())
        })
})

export const startUpload = createAsyncThunk(
    'downloads/startUpload',
    async (data, { getState, rejectWithValue, dispatch }) => {
        const state = getState() as { downloads: IDownloadsState }
        const { queue } = state.downloads

        if (queue.length >= MAX_QUEUE_LENGTH) return rejectWithValue(null)

        const unstartedUploads = cachedUploads.filter((upload) => {
            const isAllreadyUploading = !!queue.find((id) => id === upload.id)
            return !isAllreadyUploading
        })

        const uploadsToStart = unstartedUploads.slice(0, MAX_QUEUE_LENGTH - queue.length)

        if (!isNotEmptyArray(uploadsToStart)) return rejectWithValue(null)

        uploadsToStart.forEach(({ id }) => dispatch(uploadFile(id)))
        dispatch(downloadsActions.updateQueue(uploadsToStart.map((upload) => upload.id)))
    }
)

export const uploadFiles = createAsyncThunk(
    'downloads/uploadFiles',
    async (payload: UploadFilesPayload, { getState, rejectWithValue, dispatch }) => {
        const { folderId, filesList, broadcast, withConversion } = payload

        if (!folderId) {
            emitError('chooseFolder')
            return rejectWithValue(null)
        }

        const newUploads: IDownloadData[] = []
        const isFolderExist = filesList.some((file: any) => file.path.includes('/'))

        if (isFolderExist) {
            const id = generateIdByUUID()
            const folderName = filesList[0].path.split('/', 1)

            setUploadFile({
                id,
                file: filesList,
                folderId,
                broadcast,
            })

            newUploads.push({
                type: 'file',
                name: folderName,
                id,
                progress: 0,
                cancel: true,
            })
        } else {
            filesList.forEach((file: any) => {
                const id = generateIdByUUID()
                file.type.includes('video')
                    ? setUploadFile({
                          id,
                          file,
                          folderId,
                          broadcast,
                          withConversion,
                      })
                    : setUploadFile({
                          id,
                          file,
                          folderId,
                          broadcast,
                      })

                newUploads.push({
                    type: 'file',
                    name: file.name,
                    id,
                    progress: 0,
                    cancel: true,
                })
            })
        }

        dispatch(downloadsActions.setUploads(newUploads))
        dispatch(startUpload())
    }
)

const downloadsSlice = createSlice({
    name: 'downloads',
    initialState,
    reducers: {
        setDelayDownloadsProgressList(state, action: any) {
            state.delayDownloadsProgressList = action.payload
        },
        addDelayDownloadTask(state, action: any) {
            state.delayDownloadTasks = [...state.delayDownloadTasks, action.payload]
        },
        deleteDelayDownloadTask(state, action: any) {
            state.delayDownloadTasks = state.delayDownloadTasks.filter((task) => task.exportId !== action.payload)
        },
        openModal(state) {
            state.isOpenModal = true
        },
        closeModal(state) {
            state.isOpenModal = false
        },
        toggleModal(state) {
            state.isOpenModal = !state.isOpenModal
        },
        closeModalAndClearDownloads(state) {
            cachedDownloads = []
            state.isShowNotification = true
            state.isOpenModal = false
            state.downloads = []
        },
        clearAllDownloads(state) {
            cachedDownloads = []
            state.isShowNotification = true
            state.downloads = []
        },
        refreshLoadings(state) {
            state.downloads = [...cachedDownloads]
            state.uploads = [...cachedUploads]

            if (!isNotEmptyArray(cachedDownloads) && !state.isShowNotification) {
                state.isShowNotification = true
            }
        },
        clearAllUploads(state) {
            cachedUploads = []
            state.uploads = []
            state.isUploading = false
        },
        addDownload(state, action: PayloadAction<IDownloadRaw>) {
            const downloads = cachedDownloads
            const addedDownload = action.payload

            if (state.isShowNotification) {
                state.isShowNotification = false
                showNotification(addedDownload)
            }

            cachedDownloads = addDownload(downloads, addedDownload)
        },
        addDownloads(state, action: PayloadAction<IDownloadRaw[]>) {
            const newDownloads = action.payload
                .map((download) => createDownload(download))
                .filter((download) => isValidDownload(download))

            cachedDownloads = [...newDownloads, ...cachedDownloads]
        },
        updateDownload(state, action: PayloadAction<IDownloadRaw>) {
            const updatedDownload = action.payload
            const downloads = cachedDownloads

            cachedDownloads = updateDownload(downloads, updatedDownload)
        },
        setUploads(state, action: PayloadAction<IDownloadData[]>) {
            state.isUploading = true
            cachedUploads = [...cachedUploads, ...action.payload]
            cachedDownloads = [...cachedDownloads, ...action.payload]
        },
        removeUpload(state, action: PayloadAction<string>) {
            cachedUploads = cachedUploads.filter((file) => file.id !== action.payload)

            state.uploads = cachedUploads
            state.queue = state.queue.filter((id) => id !== action.payload)

            if (!isNotEmptyArray(cachedUploads)) {
                state.isUploading = false
            }
        },
        removeLoading(state, action: PayloadAction<string>) {
            cachedUploads = cachedUploads.filter((file) => file.id !== action.payload)
            cachedDownloads = cachedDownloads.filter((file) => file.id !== action.payload)

            state.uploads = cachedUploads
            state.downloads = cachedDownloads
            state.queue = state.queue.filter((id) => id !== action.payload)

            if (!isNotEmptyArray(cachedUploads)) {
                state.isUploading = false
            }
        },
        removeDownload(state, action: PayloadAction<Partial<IDownloadBroadcast> | Partial<IDownloadFile>>) {
            cachedDownloads = cachedDownloads.filter((download) => !isEqual(download, action.payload))
            state.downloads = cachedDownloads

            if (!isNotEmptyArray(cachedDownloads) && !state.isShowNotification) {
                state.isShowNotification = true
            }
        },
        removeDigitalSignage(state, action: PayloadAction<IDownloadDevice>) {
            cachedDownloads = removeDigitalSignage(cachedDownloads, action.payload)
            state.downloads = cachedDownloads

            if (!isNotEmptyArray(cachedDownloads) && !state.isShowNotification) {
                state.isShowNotification = true
            }
        },
        updateQueue(state, action: PayloadAction<string[]>) {
            state.queue = [...state.queue, ...action.payload]
        },
    },
})

const { reducer: downloadsReducer, actions: downloadsActions } = downloadsSlice
export { downloadsReducer, downloadsActions }
