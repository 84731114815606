import React, { useEffect } from 'react'
import helpers from 'core/helpers'
import styles from './content.jcss'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import translate from 'core/translate'
import { connect } from 'react-redux'
import { routesKeys } from 'features/routes'
import { withRouter } from 'react-router'
import { headActions } from 'features/head/Head.state'
import { isHorizontalMenu } from 'core/helpers/menu'
import { withWindowResize } from 'core/hocs'
import { themeFromStore } from 'core/helpers/menu'
import { appActions } from 'blocks.app/app/app.state'

const NoAccessOrNotFound = (p_: any) => (
    <div
        className={cn(
            styles.wrapper,
            p_.minimize ? styles.leftIndentSm : styles.leftIndent,
            p_.minimize ? styles.col1Sm : styles.col1,
            styles.back,
            grid.colCenter
        )}
    >
        {p_.children}
    </div>
)

interface IContentProps {
    title: string
    indentsMod: string
    className: string
    children: any
    toolbar: any
    fullHeight: boolean
    style: object
    frame: boolean
    toolbarClassName: string
    isPublic: boolean
    isToolbarExist: boolean
    noPadding: boolean
    wrapperClassName: string
    setTitle: (title: string[]) => void
    mainMenuType: string
    toggleMobShowToolbarBtn: (value: boolean) => void
    minimize: boolean
    windowWidth: number
    location: { pathname: string }
    showMobToolbar: boolean
    onClick: () => void
    fullscreen?: boolean
}

const Content = (p_: IContentProps) => {
    useEffect(() => {
        const storeCheck = () => p_.mainMenuType
        storeCheck()
        if (p_.title) {
            p_.setTitle([translate(p_.title)])
        }
        p_.toggleMobShowToolbarBtn(!!p_.toolbar)
    }, [])

    const customThemeCheck = () => {
        return themeFromStore() === 'custom'
    }

    const paddingTopMob_check = () => {
        const pathname = helpers.getPathname(p_.location.pathname)
        return (
            pathname === 'displays/updateDevices' ||
            pathname === 'displays/firmwareList' ||
            pathname === 'scoreboards/addFolder' ||
            pathname === 'scoreboards/add' ||
            pathname === 'advertisingCampaigns/add' ||
            pathname === 'advertisingAds/add' ||
            pathname === 'adBlocks/edit' ||
            pathname === 'adBlocks/add' ||
            pathname === 'advertisingAds/edit' ||
            pathname === 'advertisingCampaigns/add' ||
            pathname === 'advertisingCampaigns/edit' ||
            pathname === 'broadcasts/addVideowall' ||
            pathname === 'timetable' ||
            pathname === 'timetable/add' ||
            pathname === 'files/videoEditor' ||
            pathname === 'schedule/add' ||
            pathname === 'reports/tasks' ||
            pathname === 'instancesCatalog/samsonite' ||
            pathname === 'instancesCatalog/burgers' ||
            pathname === 'instancesCatalog/drinks' ||
            pathname === 'instancesCatalog/askona' ||
            pathname === 'instancesCatalog/lightOfHistory' ||
            pathname === 'instancesCatalog/menuProduct' ||
            pathname === 'instancesCatalog/lensOfTime' ||
            pathname === 'instancesCatalog/exhibition' ||
            pathname === 'instancesCatalog/quizQuestionsCatalog' ||
            pathname === 'instancesCatalog/productMenu' ||
            pathname === 'instancesCatalog/catalogizator'
        )
    }

    const renderContent = () => {
        const horizontalMenu = isHorizontalMenu()
        return (
            <div
                className={cn(
                    horizontalMenu ? styles.wrapper_height_for_lazyLoad : styles.wrapper,
                    {
                        [p_.toolbar
                            ? p_.minimize
                                ? styles.col2Sm
                                : !horizontalMenu
                                ? styles.col2
                                : styles.col2_x
                            : p_.minimize
                            ? styles.col1Sm
                            : !horizontalMenu
                            ? styles.col1
                            : styles.col_x]: !p_.frame,
                        [styles.leftIndent]: !p_.frame && !p_.minimize && !horizontalMenu,
                        [styles.leftIndent_x]: horizontalMenu,
                        [styles.wrapper_x]: horizontalMenu,
                        [styles.leftIndentSm]: !p_.frame && p_.minimize,
                        [styles.fullWidth]: p_.frame,
                        [styles.fullscreen]: p_.fullscreen,
                    },
                    p_.wrapperClassName
                )}
            >
                <div
                    className={cn(styles.content, p_.className, {
                        [styles.content_type_standard]: p_.indentsMod === 'none' && !p_.frame && !horizontalMenu,
                        [styles.content_type_withIndents]: p_.indentsMod !== 'none' && !p_.frame && !horizontalMenu,
                        [styles.content_type_withIndents_x]: p_.indentsMod !== 'none' && !p_.frame && horizontalMenu,
                        [styles.content_paddingTopMobFix]: paddingTopMob_check(),
                        [styles.content_padding_top_x]:
                            horizontalMenu &&
                            p_.windowWidth > 960 &&
                            helpers.getPathname(p_.location.pathname) !== 'login',
                        [styles.settings_content_padding_top_x]: horizontalMenu && p_.title === 'Настройки',
                        [styles.back]: !p_.frame,
                        [styles.backCustomTheme]:
                            !p_.frame && helpers.getPathname(p_.location.pathname) === 'history' && customThemeCheck(),
                        [styles.backAdBlocks]: helpers.getPathname(p_.location.pathname) === 'adBlocks/add',
                        [grid.fullHeight]: p_.fullHeight,
                        [styles.noPadding]: p_.noPadding,
                    })}
                    style={p_.style}
                    onClick={p_.onClick}
                >
                    {p_.children}
                </div>

                {p_.toolbar && !p_.frame && (
                    <div
                        className={cn(styles.toolbar, p_.toolbarClassName, {
                            [styles.showToolbar]: p_.showMobToolbar,
                            [styles.toolbar_x]: horizontalMenu,
                        })}
                    >
                        {p_.toolbar}
                    </div>
                )}
            </div>
        )
    }

    const pathname = helpers.getPathname(p_.location.pathname)

    if (p_.isPublic) return renderContent()

    if (!routesKeys[pathname]) {
        return <NoAccessOrNotFound minimize={p_.minimize}>{p_.children}</NoAccessOrNotFound>
    }

    if (
        !helpers.isAvailable({
            key: routesKeys[pathname],
            action: 'read',
        })
    ) {
        return <NoAccessOrNotFound minimize={p_.minimize}>{translate('noAccesses')}</NoAccessOrNotFound>
    }

    return renderContent()
}

const mapStateToProps = (state: {
    app: {
        minimizeMenu: boolean
        showMobToolbar: boolean
        isToolbarExist: boolean
    }
    user: {
        data: {
            settings: {
                mainMenuType: string
            }
        }
    }
}) => ({
    minimize: state.app.minimizeMenu,
    showMobToolbar: state.app.showMobToolbar,
    isToolbarExist: state.app.isToolbarExist,
    mainMenuType: state.user.data?.settings.mainMenuType,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        setTitle: (title: string[]) => dispatch(headActions.setTitle(title)),
        toggleMobShowToolbarBtn: (value: boolean) => dispatch(appActions.toggleMobShowToolbarBtn(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withWindowResize(Content)))
