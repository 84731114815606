import { useState, useEffect } from 'react'
import { treeViewHelpers } from 'core/helpers'
import { ITreeViewCatalogHeaderHooks } from './TreeViewCatalogHeader-types'
import { changeFilter } from 'features/routes'
import { useDispatch } from 'react-redux'
import { IAuthenticatedUserState, updateUserSettings } from 'blocks.app/user/user.state'
import { useTypedAuthenticatedSelector } from 'core/store/hooks'

export const useTreeViewCatalogHeader = ({ type, viewParams }: ITreeViewCatalogHeaderHooks) => {
    const dispatch = useDispatch()
    const { data: user } = useTypedAuthenticatedSelector<IAuthenticatedUserState>((state) => state.user)
    const [parameters, setParameters] = useState(() => treeViewHelpers.getViewParamsByUser(type, user))

    useEffect(() => {
        setParameters({
            sort: viewParams.sort as string,
        })
    }, [viewParams.sort])

    const onSearch = (value: string) => {
        changeFilter({ [treeViewHelpers.prefixQuery(type, 'query')]: value })
    }

    const onChangeSort = (sortParams: { value: string; label: string }) => {
        dispatch(
            updateUserSettings({
                data: {
                    sort: {
                        [treeViewHelpers.getUserSettingWithPrefix(type)]: sortParams.value,
                    },
                },
                isSaveSettings: true,
            })
        )
    }

    return {
        parameters,
        onSearch,
        onChangeSort,
    }
}
