import React from 'react'
import ReactDOM from 'react-dom'
import Animation from 'blocks.simple/animation/animation'
import ModalMethods, { IModalProps } from './modal.local.methods'
import Icon from 'blocks.simple/icon/icon'
import { cn } from 'ethcss'
import styles from './modal.jcss'
import { layers } from 'blocks.app/config'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { isHorizontalMenu } from '../../core/helpers/menu'

const getIconColor = (p_: IModalProps) => {
    if (p_.mod === 'black') {
        return 'white'
    }

    return p_.closeIconColor || getThemeStyleValue('ui', 'icon', 'main') || defaultThemeStyles.main
}

class Modal extends ModalMethods {
    static defaultProps = {
        open: false,
        mod: 'white',
        showCloseIcon: true,
        z: 1,
        name: '',
        closeIconSize: 30,
        transitionName: 'fade',
    }

    renderContent = () => {
        const p_ = this.props

        if (p_.sidebars) {
            return (
                <div className={styles.sidebarsWrapper}>
                    {p_.sidebars.left && (
                        <div className={cn(styles.sidebarsLeft, p_.leftSidebarClass)}>{p_.sidebars.left}</div>
                    )}
                    <div className={cn(styles.sidebarsContent, p_.contentClassName)}>{p_.children}</div>
                    {p_.sidebars.right && (
                        <div className={cn(styles.sidebarsRight, p_.rightSidebarClass)}>{p_.sidebars.right}</div>
                    )}
                </div>
            )
        } else {
            return <div className={cn(styles.content, p_.contentClassName)}>{p_.children}</div>
        }
    }
    renderModal = () => {
        const p_ = this.props
        let mod = p_.mod
        if (p_.sidebars) {
            mod = 'clear'
        }

        return (
            <div
                className={cn(styles.wrapper, isHorizontalMenu() ? styles.wrapper_h : '', styles[mod])}
                style={{ zIndex: p_.z * layers.modal }}
                key={'modalContent'}
            >
                {p_.showCloseIcon && (
                    <div
                        className={`${styles.button} ${isHorizontalMenu() ? styles.button_h : ''} ${
                            p_.closeIconStyles ? p_.closeIconStyles : ''
                        }`}
                        style={p_.closeIconStyles ? p_.closeIconStyles : {}}
                    >
                        <Icon
                            type="close_tiny"
                            color={getIconColor(p_)}
                            size={p_.closeIconSize}
                            onClick={function () {
                                if (p_.onClose) {
                                    p_.onClose()
                                }
                            }}
                        />
                    </div>
                )}

                {this.renderContent()}
            </div>
        )
    }

    render() {
        const p_ = this.props
        const Content = <Animation transitionName={p_.transitionName}>{p_.open ? this.renderModal() : null}</Animation>

        return ReactDOM.createPortal(Content, this.element)
    }
}

export default Modal
