import { addStyles } from 'ethcss'
import { grid, text, IStyles } from 'theme'
import { mediaCSS } from 'theme'
import { defaultThemeStyles } from './DisplayAudioControll-theme'

export const displayAudioControllStyles: IStyles = {
    DisplayAudioControll__wrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
        padding: '70px',
        background: `var(--var-module-device-component-audio-style-background, ${defaultThemeStyles.background})`,
        [mediaCSS.tab]: {
            padding: '10px',
        },
    },
    DisplayAudioControll: {
        width: '100%',
        height: '100%',
        ...grid.flex,
        ...grid.justify,
        ...grid.normalCenter,
        [mediaCSS.tab]: {
            display: 'block',
        },
    },
    DisplayAudioControll__main: {
        width: '70%',
        display: 'flex',
        justifyContent: 'center',
        [mediaCSS.tab]: {
            width: '100%',
        },
    },
    DisplayAudioControll__main_status_default: {
        marginTop: '-12.5%',
        [mediaCSS.tab]: {
            marginTop: 'auto',
        },
    },
    DisplayAudioControll__audioTrackList: {
        width: '30%',
        [mediaCSS.tab]: {
            display: 'none',
        },
    },
    DisplayAudioControll__error: {
        textAlign: 'center',
        maxWidth: 330,
    },
    DisplayAudioControll__errorImage: {
        ...grid.pb_mini,
    },
    DisplayAudioControll__errorTitle: {
        ...text.md,
        ...grid.pb_mdPlus,
        lineHeight: 1.3,
        color: `var(--var-module-device-component-audio-style-error, ${defaultThemeStyles.error})`,
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    DisplayAudioControll__errorSubTitle: {
        ...grid.pb_mini,
        fontSize: 14,
        lineHeight: 1.2,
        color: `var(--var-module-device-component-audio-style-error, ${defaultThemeStyles.error})`,
    },
    DisplayAudioControll__errorText: {
        fontSize: 14,
        lineHeight: 1.2,
        color: `var(--var-module-device-component-audio-style-text, ${defaultThemeStyles.text})`,
    },
    DisplayAudioControll__info: {
        maxWidth: 260,
        textAlign: 'center',
    },
    DisplayAudioControll__infoImage: {
        ...grid.pb_md,
    },
    DisplayAudioControll__infoTitle: {
        ...grid.pb_mdPlus,
        ...grid.m_auto,
        maxWidth: 220,
        lineHeight: 1.4,
        fontSize: 18,
        fontWeight: 'bold',
        color: `var(--var-module-device-component-audio-style-text, ${defaultThemeStyles.text})`,
    },
    DisplayAudioControll__infoSubTitle: {
        ...grid.pb_mini,
        fontSize: 14,
        lineHeight: 1.6,
        color: `var(--var-module-device-component-audio-style-text, ${defaultThemeStyles.text})`,
    },
    DisplayAudioControll__playground_center: {
        justifyContent: 'center !important',
    },
}

addStyles(displayAudioControllStyles)
