import React, { useEffect, useState } from 'react'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import styles from './catalog__template_quizQuestionsCatalog.jcss'
import { CatalogActionBar } from 'organisms/Catalog/ActionBar/ActionBar-view'
import { ActionBarRequestHeaderType, ActionBarActionType } from 'core/models/ActionBar'
import { Radio } from 'atoms/Radio'
import EditableText from 'blocks.simple/editableText/editableText'
import Button from 'blocks.simple/button/button'
import { IActionBarComponent } from '../../../../organisms/Catalog/ActionBar/ActionBar-types'
import { ItemData } from '../../../../core/models/Template'

type Answer = {
    quizQuestionId: number
    order: number
    answer: string
    rightQ: boolean
}

interface IQuestionProps {
    question: string
    answers: Answer[]
    requiredQ: boolean
    quizQuestionId: number
}

const emptyQuestion = {
    question: '',
    requiredQ: false,
    answers: [
        {
            order: 0,
            answer: '',
            rightQ: false,
        },
        {
            order: 1,
            answer: '',
            rightQ: false,
        },
        {
            order: 2,
            answer: '',
            rightQ: false,
        },
    ],
}

const Question = ({ p_, listProps }: { p_: IQuestionProps; listProps: { onAddFakeLine: any; fakeLine: any } }) => {
    const [answersList, setAnswersList] = useState<any[]>(p_.answers ? p_.answers : emptyQuestion.answers)
    const [update, setUpdate] = useState<boolean>(false)
    const [isRequired, setIsRequired] = useState(p_.requiredQ)

    const getAnswersList = (answersList: Answer[]) => {
        return answersList.map((answer) => {
            return {
                answer: answer.answer,
                rightQ: answer.rightQ,
            }
        })
    }

    const changeAnswer = (order: number, answersList: { id: number; order: number }[]) => {
        const list = answersList.map((answer) => {
            return {
                ...answer,
                rightQ: answer.order === order,
            }
        })

        setAnswersList(list)

        if (!p_.quizQuestionId) {
            listProps.onAddFakeLine({ ...listProps.fakeLine, answers: list })
        }

        setUpdate(true)
    }

    const validation = (list: { question: string; answers: Answer[]; requiredQ: boolean }) => {
        if (!list.question) {
            return true
        }
        if (!list.answers) {
            return true
        }
        if (list.answers.filter((answer) => answer.answer !== '').length < 3) {
            return true
        }
        if (!list.requiredQ) {
            if (!list.answers.find((answer) => answer.rightQ)) {
                return true
            }
        }

        return false
    }

    const isRequiredAction = (fakeLine: { answers: Answer[] }, answersList: Answer[], isRequired: boolean) => {
        if (!p_.quizQuestionId) {
            listProps.onAddFakeLine({
                ...fakeLine,
                requiredQ: !isRequired,
                answers: !isRequired
                    ? getAnswersList(fakeLine.answers ? fakeLine.answers : answersList).map((answer, index: number) => {
                          return {
                              ...answer,
                              rightQ: true,
                              order: index,
                          }
                      })
                    : getAnswersList(fakeLine.answers ? fakeLine.answers : answersList).map((answer, index: number) => {
                          return {
                              ...answer,
                              rightQ: index === 0,
                              order: index,
                          }
                      }),
            })

            setAnswersList(
                !isRequired
                    ? getAnswersList(fakeLine.answers ? fakeLine.answers : answersList).map((answer, index: number) => {
                          return {
                              ...answer,
                              rightQ: true,
                              order: index,
                          }
                      })
                    : getAnswersList(fakeLine.answers ? fakeLine.answers : answersList).map((answer, index: number) => {
                          return {
                              ...answer,
                              rightQ: index === 0,
                              order: index,
                          }
                      })
            )

            return
        }

        setUpdate(true)

        setAnswersList(
            !isRequired
                ? getAnswersList(answersList).map((answer, index: number) => {
                      return {
                          ...answer,
                          rightQ: true,
                          order: index,
                      }
                  })
                : getAnswersList(answersList).map((answer, index: number) => {
                      return {
                          ...answer,
                          rightQ: index === 0,
                          order: index,
                      }
                  })
        )
    }

    useEffect(() => {
        if (update) {
            if (!p_.quizQuestionId) {
                listProps.onAddFakeLine({ ...listProps.fakeLine, answers: getAnswersList(answersList) })
                setUpdate(false)
                return
            }
            api.send('updateQuizQuestion', {
                quizQuestionId: p_.quizQuestionId,
                answers: getAnswersList(answersList),
                requiredQ: isRequired,
            }).then(() => setUpdate(false))
        }
    }, [answersList])

    return (
        <div className={styles.questionWrapper}>
            <div className={styles.question}>
                <div className={styles.caption}>Вопрос:</div>
                <div className={styles.questionTextWrapper}>
                    <EditableText
                        mod={'withBorder'}
                        placeholder={translate('enterQuestion')}
                        id={p_.quizQuestionId}
                        text={p_.question ? p_.question : ''}
                        onChange={(question: string) => {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, question })
                        }}
                        onUpdate={(question, cb) => {
                            api.send('updateQuizQuestion', {
                                quizQuestionId: p_.quizQuestionId,
                                question,
                                answers: getAnswersList(answersList),
                            }).then(cb)
                        }}
                    />
                </div>
                <Radio
                    label={translate('Основной вопрос')}
                    selected={isRequired}
                    className={styles.radio}
                    onClick={() => {
                        setIsRequired(!isRequired)
                        isRequiredAction(listProps.fakeLine, answersList, isRequired)
                    }}
                />
            </div>
            <div>
                {answersList.map((answer: Answer, index: number) => {
                    return (
                        <div className={styles.answer} key={answer.order}>
                            <div className={styles.caption}>Ответ {index + 1}:</div>
                            <div className={styles.answerTextWrapper}>
                                <EditableText
                                    mod={'withBorder'}
                                    placeholder={translate('enterAnswer')}
                                    id={p_.quizQuestionId}
                                    text={answer.answer ? answer.answer : ''}
                                    onChange={(text: string) => {
                                        setAnswersList(
                                            answersList.map((answer: Answer) => {
                                                if (answer.order === index) {
                                                    return {
                                                        ...answer,
                                                        answer: text,
                                                    }
                                                }
                                                return answer
                                            })
                                        )
                                        listProps.onAddFakeLine({
                                            ...listProps.fakeLine,
                                            answers: answersList.map((answer: Answer) => {
                                                if (answer.order === index) {
                                                    return {
                                                        ...answer,
                                                        answer: text,
                                                    }
                                                }
                                                return answer
                                            }),
                                        })
                                    }}
                                    onUpdate={(text) => {
                                        setUpdate(true)
                                        setAnswersList(
                                            answersList.map((answer: Answer) => {
                                                if (answer.order === index) {
                                                    return {
                                                        ...answer,
                                                        answer: text,
                                                    }
                                                }
                                                return answer
                                            })
                                        )
                                    }}
                                />
                            </div>
                            <div className={styles.answerChoice}>
                                <Radio
                                    label={translate('Правильный ответ')}
                                    selected={answer.rightQ}
                                    className={styles.radio}
                                    onClick={() => {
                                        changeAnswer(answer.order, answersList)
                                    }}
                                    disabled={isRequired}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
            {!p_.quizQuestionId && (
                <Button
                    className={styles.saveButton}
                    mod={'fill'}
                    indentSize={'mini'}
                    textSize={'mini'}
                    rounded={'full_normal'}
                    onClick={function () {
                        api.send('createQuizQuestion', {
                            question: listProps.fakeLine.question,
                            requiredQ:
                                listProps.fakeLine.requiredQ === undefined ? false : listProps.fakeLine.requiredQ,
                            answers: listProps.fakeLine.answers.map((answer: Answer) => {
                                return {
                                    answer: answer.answer,
                                    rightQ: answer.rightQ,
                                }
                            }),
                        })
                    }}
                    disabled={validation(listProps.fakeLine)}
                >
                    {translate('save')}
                </Button>
            )}
        </div>
    )
}

const getActionList = (p_: any) => {
    return [
        {
            order: 1,
            type: ActionBarActionType.ADD,
            cb: () => {
                p_.onAddFakeLine({ name: '' })
            },
        },
        {
            order: 2,
            type: ActionBarActionType.DELETE,
            cb: () => {
                const quizQuestionIds = p_.selectedItems.map((selectedItem: { quizQuestionId: number }) => {
                    return {
                        quizQuestionId: selectedItem.quizQuestionId,
                    }
                })

                if (quizQuestionIds.length > 0) {
                    api.send('deleteQuizQuestions', quizQuestionIds)
                }

                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            },
        },
    ]
}

export default {
    getMethod: 'getQuizQuestions',
    customParams: {},
    trNoClick: true,
    emptySearchText: () => translate('notFound'),
    emptyDescription: () => translate('createFirstPosition'),
    emptyFolderText: () => translate('listIsEmpty'),
    emptyIcon: 'not_allow',
    addItemButton: () => null,
    settingsPrefixes: 'quiz',
    disableDrag: true,
    sortOptions: () => [],
    headerFilters: (p_: IActionBarComponent) => {
        return (
            <CatalogActionBar
                title={translate('quiz')}
                requestHeaderType={ActionBarRequestHeaderType.WITH_AUTH}
                type="authorsList"
                actions={getActionList(p_)}
                {...p_}
            />
        )
    },

    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },

    getBackendModel: (item: ItemData) => {
        return api.send('getQuizQuestions', { query: item.name }, { hideLoader: true })
    },
    isEqual: (firstItem: ItemData, secondItem: ItemData) => {
        if (firstItem.quizQuestionId && secondItem.quizQuestionId) {
            return firstItem.quizQuestionId === secondItem.quizQuestionId
        }

        return firstItem === secondItem
    },

    onCreateListeners: [
        (callback: any, clearList: string[]) => {
            return api.addObserver(
                'quizQuestionCreated',
                (quizQuestionCreated: Answer) => {
                    callback({ ...quizQuestionCreated })
                },
                clearList
            )
        },
    ],
    onUpdateListeners: [
        (callback: any, clearList: string[]) => {
            return api.addObserver(
                'quizQuestionUpdated',
                (quizQuestionUpdated: Answer) => {
                    callback({ ...quizQuestionUpdated })
                },
                clearList
            )
        },
    ],
    onDeleteListeners: [
        (callback: any, clearList: string[]) =>
            api.addObserver(
                'quizQuestionDeleted',
                (quizQuestionDeleted: Answer) => {
                    const quizQuestionId = quizQuestionDeleted.quizQuestionId
                    callback({ quizQuestionId })
                },
                clearList
            ),
    ],

    isFolder: () => {},
    fields: () => [{ id: 'question', name: translate('questions') }],
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        question: (p_: IQuestionProps, active: boolean, listProps: { onAddFakeLine: any; fakeLine: any }) => (
            <Question p_={p_} listProps={listProps} />
        ),
    },
    hideActiveRowColor: true,
    disableListScroll: true,
    fixedColWidth: false,
    dndDblClickDisable: true,
}
