import { useEffect } from 'react'

export const useOnMount = (onMount: () => void) => {
    // eslint-disable-next-line
    useEffect(() => onMount(), [])
}
export const useOnUnmount = (onUnmount: () => void) => {
    // eslint-disable-next-line
    useEffect(() => () => onUnmount(), [])
}
