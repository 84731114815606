import { addStyles } from 'ethcss'
import { grid, item, mediaCSS } from 'theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'

export const styles = {
    FixedHeaderWrapper: {},
    FixedHeaderWrapper__content: {
        position: 'fixed',
        top: 0,
        paddingBottom: 0,
        paddingTop: '36px',
        zIndex: 1000,
        backgroundColor: `var(--var-module-app-component-app-style-background, ${defaultThemeStyles.background})`,
        [mediaCSS.tab]: {
            left: `0 !important`,
            right: `0 !important`,
            marginTop: 44,
        },
        ...item.transitionErase,
    },
    FixedHeaderWrapper__content_x: {
        top: '80px',
        marginTop: '35px',
    },
}

addStyles(styles)
