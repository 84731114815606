import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { addStyles } from 'ethcss'
import { grid, item } from 'theme'
import { mediaCSS } from 'theme/media'
import { colors } from '../../../theme'

const catalogHeaderStyles = {
    catalogHeaderWrapper: {
        ...item.indent_big,
        //background: `var(--var-module-app-component-app-style-lightBackground, ${defaultThemeStyles.lightBackground})`,
        [mediaCSS.mob]: {
            ...grid.p_mini,
        },
    },
    searchbarComponent: {
        border: `1px solid ${colors.greyLight}`,
        borderRadius: '5px',
    },
}

addStyles(catalogHeaderStyles)
export default catalogHeaderStyles
