import { addStyles } from 'ethcss'
import { grid, IStyles, radiusSizes } from 'theme'
import { defaultThemeTableStyles } from '../Table-theme'

export const styles: IStyles = {
    TableHeaderCell: {
        ...grid.p_mini,
        borderRight: `1px solid var(--var-module-ui-component-table-style-cellBorder, ${defaultThemeTableStyles['cellBorder']})`,
        borderBottom: `1px solid var(--var-module-ui-component-table-style-cellBorder, ${defaultThemeTableStyles['cellBorder']})`,
        borderTop: `1px solid var(--var-module-ui-component-table-style-cellBorder, ${defaultThemeTableStyles['cellBorder']})`,
        '&:last-child': {
            borderRight: 'none',
        },
    },
}

addStyles(styles)
