import { useEffect, useRef, useState } from 'react'
import { ym } from 'blocks.app/yandexMetrika/yandexMetrika'
import { api } from 'core/api/ConnectionManager'
import { routes } from 'features/routes'
import { changeLocation } from 'features/routes'
import { isRedirectToLogin, isRoutePathnameChange } from 'features/routes/utils'
import { IDevice } from '../../../core/models/DigitalSignage'
import moment from 'moment'
import helpersSchedule from '../../../core/helpers/_schedule'

type Location = {
    hash: string
    pathname: string
    search: string
    state: any
}

export function addBroadcastFolder(query: { folderId?: string }) {
    const folderId = query.folderId || null

    api.send('createBroadcastFolder', { folderId })
}

const defaultSteps = ['createBroadcast', 'devices', 'schedule']

export interface Conflicts {
    cause: string
    proposedSolution: string
    obj: {
        id: number
        name?: string
        platform: string
        dsVersion: string
    }
}

interface IState {
    saved: boolean
    selectedStep: string
    steps: string[]
    active: {}[]
    devices: IDevice[]
    conflicts: Conflicts[]
    schedule: any
    broadcast: any
    advDuration: {} | null
    contentDownloadDate: string | null
    contentDownloadTime: string | null
    advContentLength: number | null
    applyConflictsSolution: boolean
}

const initialState = {
    saved: false,
    selectedStep: 'createBroadcast',
    steps: defaultSteps,
    active: [],
    devices: [],
    conflicts: [],
    schedule: {
        startDate: moment(),
        endDate: null,
        startTime: helpersSchedule.startOf(),
        endTime: helpersSchedule.endOf(),
        repeatMode: 'forever',
        repeatDays: [],
        contentDownloadDateTime: null,
        priority: 0,
        localStartTime: helpersSchedule.startOf(),
        localEndTime: helpersSchedule.endOf(),
        localStartDate: moment(),
        localEndDate: null,
        localRepeatDays: [],
        timeToUse: 'local',
        removeOverlappingSchedules: true,
    },
    broadcast: null,
    advDuration: null,
    contentDownloadDate: null,
    contentDownloadTime: null,
    advContentLength: null,
    applyConflictsSolution: false,
}

const AddScheduleMethods = () => {
    const [state, setState] = useState<IState>(initialState)
    const addScheduleRef = useRef<any>()
    const editorRef = useRef<any>()
    let nextStepId = null

    useEffect(() => {
        ym('reachGoal', 'addContentToDevice_createBroadcast')
    }, [])

    useEffect(() => {
        if (state.saved) {
            changeLocation(`/${routes.broadcasts.path}`)
        }
    }, [state.saved])

    const clickOnStep = (currentStepId: string, nextStepId: string) => {
        if (nextStepId === currentStepId) {
            return
        }
        if (currentStepId === 'createBroadcast') {
            nextStepId = nextStepId
            editorRef.current?.save()
        }
        if (currentStepId === 'devices') {
            if (nextStepId !== 'createBroadcast') {
                addScheduleRef.current.validateDevices((isValid: boolean) => {
                    if (isValid) {
                        setState((prevState) => {
                            return {
                                ...prevState,
                                selectedStep: nextStepId,
                            }
                        })
                        ym('reachGoal', `addContentToDevice_${nextStepId}`)
                    }
                })
                return
            }

            setState((prevState) => {
                return {
                    ...prevState,
                    selectedStep: nextStepId,
                }
            })
        }
        if (currentStepId === 'schedule') {
            addScheduleRef.current.validateSchedule((isValid: boolean) => {
                if (isValid) {
                    setState((prevState) => {
                        return {
                            ...prevState,
                            selectedStep: nextStepId,
                        }
                    })
                    ym('reachGoal', `addContentToDevice_${nextStepId}`)
                }
            })
        }
        if (currentStepId === 'conflicts') {
            setState((prevState) => {
                return {
                    ...prevState,
                    selectedStep: nextStepId,
                }
            })
        }
    }
    const onBroadcastScheduleConflicts = (conflicts: Conflicts[]) => {
        if (conflicts.length) {
            setState((prevState) => {
                return {
                    ...prevState,
                    conflicts,
                    selectedStep: 'conflicts',
                    steps: [...defaultSteps, 'conflicts'],
                    applyConflictsSolution: true,
                }
            })
        } else {
            setState((prevState) => {
                return {
                    ...prevState,
                    conflicts,
                    selectedStep: 'devices',
                    steps: defaultSteps,
                    applyConflictsSolution: false,
                }
            })
        }
    }
    const solveConflicts = () => {
        const s_ = state

        if (s_.conflicts.length && s_.applyConflictsSolution) {
            const devicesIdToDelete = s_.conflicts.map(
                (conflict: {
                    obj: {
                        id: number
                    }
                }) => conflict.obj.id
            )
            const filteredDevices = s_.devices.filter(
                (device: { id: number }) => !devicesIdToDelete.includes(device.id)
            )

            setState((prevState) => {
                return {
                    ...prevState,
                    devices: filteredDevices,
                }
            })

            save((isValid: boolean, type = 'schedule') => {
                setState((prevState) => {
                    return {
                        ...prevState,
                        conflicts: [],
                        steps: defaultSteps,
                        selectedStep: type,
                    }
                })
            })
        }
    }
    const cancel = () => {
        setState(initialState)
    }
    const save = (cb: any) => {
        addScheduleRef.current.save((isValid: boolean, type: string) => {
            if (isValid) {
                setState((prevState) => {
                    return {
                        ...prevState,
                        saved: true,
                    }
                })
            }
            cb && cb(isValid, type)
        })
    }
    const isPreventLeaveRoute = () => {
        const s_ = state
        return s_.selectedStep !== 'createBroadcast' && !s_.saved
    }
    const isShouldBlockNavigation = (location: Location, prevLocation: any) => {
        return (
            !isRedirectToLogin(location.pathname) &&
            isRoutePathnameChange(location, prevLocation) &&
            isPreventLeaveRoute()
        )
    }

    return {
        state,
        setState,
        nextStepId,
        addScheduleRef,
        editorRef,
        cancel,
        clickOnStep,
        save,
        solveConflicts,
        onBroadcastScheduleConflicts,
        isPreventLeaveRoute,
        isShouldBlockNavigation,
    }
}

export default AddScheduleMethods
