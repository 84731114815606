import React, { useEffect, useState } from 'react'
import TableEditorPreview from 'blocks.app/tableEditor/_preview/tableEditor_preview'
import LoaderBlock from 'blocks.app/loader/_block/loader_block'
import { api } from 'core/api/ConnectionManager'
import styles from './preview__table.jcss'

const PreviewTable = (p_: { item: { id: number } }) => {
    const [state, setState] = useState({
        cells: [],
        tableFormat: {},
        isLoading: true,
    })
    let timer: any = null

    useEffect(() => {
        if (p_.item) {
            api.send('getFile', { id: p_.item.id }).then(({ data }: any) => {
                // show more time loader
                timer = setTimeout(() => {
                    setState({ cells: data.cells, tableFormat: data.tableFormat, isLoading: false })
                }, 300)
            })
        }

        return () => clearTimeout(timer)
    }, [])

    return (
        <React.Fragment>
            {state.isLoading && <LoaderBlock className={styles.loader} />}
            {!state.isLoading && (
                //@ts-ignore
                <TableEditorPreview wrapperClassName={styles.wrapper} inheritBorder={false} {...state} />
            )}
        </React.Fragment>
    )
}

export default PreviewTable
