import { useState } from 'react'
import { api } from 'core/api/ConnectionManager'
import helpersBroadcasts from 'core/helpers/_broadcasts'
import { routes } from 'features/routes'
import helpers from 'core/helpers'
import { getURLSearchParamsString } from 'features/routes/utils'
import { changeLocation } from 'features/routes'

type Folder = { title: string; id: string }

const getFolders = (folders: Folder[]) =>
    folders.map((folder) => ({ ...folder, name: folder.title, nestedKey: 'folderId' }))

export const setEditBroadcastLocation = (broadcast: { id: number }, folderId: number) => {
    if (helpers.isAvailableAction(broadcast, { key: 'broadcast', action: 'update' })) {
        const query: {
            broadcastId: number
            broadcastFolderId?: string
        } = {
            broadcastId: broadcast.id,
        }

        if (folderId) {
            query.broadcastFolderId = folderId.toString()
        }

        const queryString = getURLSearchParamsString(query)

        changeLocation({
            pathname: `/${routes.editBroadcast.path}`,
            search: queryString,
        })
    }
}

const BroadcastsMethods = (props: { folderId: string }) => {
    const [selectedInfo, setSelectedInfo] = useState<{} | null>(null)
    const [selectedItems, setSelectedItems] = useState([])
    const [folders, setFolders] = useState<{}[]>([])
    const [showDownloadInfo, setShowDownloadInfo] = useState(false)

    const onGetFoldersOnMultiple = () => {
        const broadcastId: number[] = []
        const broadcastFolderId: number[] = []

        selectedItems.forEach((selectedItem: { id: number; instanceType: string }) => {
            if (helpersBroadcasts.isFolder(selectedItem)) {
                broadcastFolderId.push(selectedItem.id)
                return
            }

            broadcastId.push(selectedItem.id)
        })
        api.send(
            'getAvailableParentBroadcastFolders',
            { broadcastFolderId, broadcastId, nestedQ: false },
            { hideLoader: true }
        ).then((availableFolders: any) => {
            const newFoldersList = getFolders(availableFolders)

            setFolders(newFoldersList)
        })
    }

    const onSelectInfo = (selected: { id: number; instanceType: string }) => {
        setSelectedInfo(selected)

        if (!selected) {
            return
        }

        const isDirectory = helpersBroadcasts.isDirectory(selected)

        let params: {
            broadcastId?: number
            broadcastFolderId?: number
        } = {
            broadcastId: selected.id,
        }

        if (isDirectory) {
            params = {
                broadcastFolderId: selected.id,
            }
        }

        api.send('getAvailableParentBroadcastFolders', { ...params, nestedQ: false }, { hideLoader: true }).then(
            (availableFolders: any) => {
                const newFoldersList = getFolders(availableFolders)

                setFolders(newFoldersList)
            }
        )
    }

    const onOpenInfo = () => {
        setShowDownloadInfo(true)
    }

    const onCloseInfo = () => {
        setShowDownloadInfo(false)
    }

    const onDoubleClickObject = (broadcast: { id: number }) => {
        setEditBroadcastLocation(broadcast, +props.folderId)
    }

    return {
        s_: {
            selectedInfo,
            selectedItems,
            folders,
            showDownloadInfo,
        },
        methods: {
            onGetFoldersOnMultiple,
            onSelectInfo,
            onDoubleClickObject,
            setSelectedInfo,
            setSelectedItems,
            onCloseInfo,
            onOpenInfo,
        },
    }
}

export default BroadcastsMethods
