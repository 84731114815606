import React from 'react'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import styles from './catalog__template_exhibitions.jcss'
import { CatalogActionBar } from 'organisms/Catalog/ActionBar/ActionBar-view'
import { ActionBarRequestHeaderType, ActionBarActionType } from 'core/models/ActionBar'
import EditableText from 'blocks.simple/editableText/editableText'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import Button from 'blocks.simple/button/button'
import grid from 'blocks.simple/grid/grid.jcss'
import { cn } from 'ethcss'
import helpers from 'core/helpers'
import { pagesListToShort, responseConverter } from './catalog__template_exhibitions.local.methods'
import { MultiSelectFromListModal } from 'molecules/MultiSelectFromListModal'
import { TextEditor } from 'molecules/TextEditor'
import { IActionBarComponent } from '../../../../../organisms/Catalog/ActionBar/ActionBar-types'
import { ItemData } from '../../../../../core/models/Template'

interface IExhibitionProps {
    exhibitionId: number
    description: string
    activeQ: boolean
    title: string
    pages: IExhibitionProps[]
}

const toolbar = {
    options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
}

const getActionList = (p_: any) => {
    return [
        {
            order: 1,
            type: ActionBarActionType.ADD,
            cb: () => {
                p_.onAddFakeLine({ name: '' })
            },
        },
        {
            order: 2,
            type: ActionBarActionType.DELETE,
            cb: () => {
                const exhibitionIds = p_.selectedItems.map(
                    (selectedItem: { exhibitionId: number }) => selectedItem.exhibitionId
                )

                if (exhibitionIds.length > 0) {
                    api.send('deleteExhibition', { exhibitionId: exhibitionIds })
                }

                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            },
        },
    ]
}

export default {
    getMethod: 'getExhibition',
    customParams: {
        limit: 35,
    },
    trNoClick: true,
    disableDrag: true,
    emptySearchText: () => translate('notFound'),
    emptyDescription: () => translate('createFirstPosition'),
    emptyFolderText: () => translate('listIsEmpty'),
    emptyIcon: 'not_allow',
    addItemButton: () => null,
    settingsPrefixes: 'exhibition',
    sortOptions: () => [],
    headerFilters: (p_: IActionBarComponent) => {
        return (
            <CatalogActionBar
                title={translate('exhibitionsList')}
                requestHeaderType={ActionBarRequestHeaderType.WITH_AUTH}
                type="exhibitionsPagesList"
                actions={getActionList(p_)}
                {...p_}
            />
        )
    },

    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean) => {
        const item: any = { ...dataItem }

        if (item.avatar && !item.avatar.__view) {
            item.avatar.__view = {
                selected: getSelected(item),
            }
        }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },

    getBackendModel: () => {},
    tableWrapper: styles.tableWrapper,
    isEqual: (firstItem: ItemData, secondItem: ItemData) => {
        if (firstItem.exhibitionId && secondItem.exhibitionId) {
            return firstItem.exhibitionId === secondItem.exhibitionId
        }

        return firstItem === secondItem
    },
    updateList: (listItem: ItemData[], updatedList: ItemData[]) => {
        const updatedElement = updatedList[0]

        return listItem.map((item) => {
            if (item.exhibitionId === updatedElement.exhibitionId) {
                return updatedElement
            }
            if (updatedElement.activeQ) {
                return { ...item, activeQ: false }
            }

            return item
        })
    },

    onCreateListeners: [
        (callback: any, clearList: string[]) => {
            return api.addObserver(
                'exhibitionCreated',
                (exhibitionCreated: ItemData) => {
                    callback({ ...exhibitionCreated })
                },
                clearList
            )
        },
    ],
    onUpdateListeners: [
        (callback: any, clearList: string[]) => {
            return api.addObserver(
                'exhibitionUpdated',
                (exhibitionUpdated: ItemData) => {
                    callback({ ...exhibitionUpdated })
                },
                clearList
            )
        },
    ],
    onDeleteListeners: [
        (callback: any, clearList: string[]) =>
            api.addObserver(
                'exhibitionDeleted',
                (deletedItems: { exhibitionId: number[] }) => {
                    deletedItems.exhibitionId.forEach((id) => {
                        callback({ exhibitionId: id })
                    })
                },
                clearList
            ),
    ],
    isFolder: () => {},
    fields: () => [
        { id: 'active', name: translate('active') },
        { id: 'title', name: translate('title') },
        { id: 'selectedPages', name: translate('selectedPages') },
        { id: 'description', name: translate('exhibitionDescription') },
    ],
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        active: (p_: IExhibitionProps, active: boolean, listProps: { onAddFakeLine: any; fakeLine: ItemData }) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.title })}>
                <div className={cn({ [grid.center]: p_.title, [grid.space]: !p_.title })}>
                    <Checkbox
                        checked={p_.exhibitionId ? p_.activeQ : listProps.fakeLine.activeQ}
                        onClick={(cb: any) => {
                            if (!p_.exhibitionId) {
                                listProps.onAddFakeLine({ ...listProps.fakeLine, activeQ: !listProps.fakeLine.activeQ })

                                return
                            }

                            api.send('updateExhibition', {
                                exhibitionId: p_.exhibitionId,
                                activeQ: !p_.activeQ,
                            }).then(cb)
                        }}
                        mod={'circle'}
                        disabled={false}
                    />
                </div>
            </div>
        ),
        title: (p_: IExhibitionProps, active: boolean, listProps: { onAddFakeLine: any; fakeLine: ItemData }) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.title })}>
                <div className={cn({ [grid.fullWidth]: p_.title, [grid.space]: !p_.title })}>
                    <EditableText
                        placeholder={translate('enterTitle')}
                        id={p_.exhibitionId}
                        text={helpers.lineBreak(helpers.quotesConverter(p_.title, 'string'), 'string')}
                        onChange={(title: string) => {
                            listProps.onAddFakeLine({
                                ...listProps.fakeLine,
                                title: helpers.lineBreak(helpers.quotesConverter(title, 'html'), 'html'),
                            })
                        }}
                        onUpdate={(title, cb) => {
                            api.send('updateExhibition', {
                                exhibitionId: p_.exhibitionId,
                                title: helpers.lineBreak(helpers.quotesConverter(title, 'html'), 'html'),
                            }).then(cb)
                        }}
                    />
                </div>
            </div>
        ),
        selectedPages: (
            p_: IExhibitionProps,
            active: boolean,
            listProps: { onAddFakeLine: any; fakeLine: ItemData }
        ) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.pages })}>
                <div className={cn({ [grid.fullWidth]: p_.pages, [grid.space]: !p_.pages })}>
                    <MultiSelectFromListModal
                        id={p_.exhibitionId}
                        mode={'useApi'}
                        apiParams={{
                            method: 'getExhibitionPages',
                            params: { limit: 10000 },
                            callback: responseConverter,
                        }}
                        placeholder={
                            helpers.isListExist(p_.pages) ? `Выбрано (${p_.pages.length})` : `Выберите страницы`
                        }
                        callbackList={pagesListToShort}
                        selectedList={p_.pages ? pagesListToShort(p_.pages) : []}
                        onSelect={(pagesIds: number[], cb: any) => {
                            const pages = pagesIds.map((page) => {
                                return { exhibitionPageId: page }
                            })

                            if (!p_.exhibitionId) {
                                listProps.onAddFakeLine({ ...listProps.fakeLine, pages })
                                return
                            }

                            api.send('updateExhibition', {
                                exhibitionId: p_.exhibitionId,
                                pages: pages,
                            }).then(cb)
                        }}
                        initList={[]}
                        openStatus={false}
                        onCancel={() => {}}
                        header={() => null}
                    />
                </div>
            </div>
        ),
        description: (p_: IExhibitionProps, active: boolean, listProps: { onAddFakeLine: any; fakeLine: ItemData }) => (
            <div className={cn(grid.fullWidth)}>
                <TextEditor
                    id={p_.exhibitionId}
                    placeholder={translate('enterDescription')}
                    text={p_.description ? p_.description : ''}
                    previewStyles={styles.preview}
                    toolbar={toolbar}
                    onChange={(description: string, cb: any) => {
                        if (!p_.exhibitionId) {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, description })
                            return
                        }

                        api.send('updateExhibition', {
                            exhibitionId: p_.exhibitionId,
                            description: helpers.lineBreak(helpers.quotesConverter(description, 'html'), 'html'),
                        }).then(cb)
                    }}
                />
                {!p_.exhibitionId && (
                    <Button
                        mod={'fill'}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            api.send('createExhibition', {
                                activeQ: listProps.fakeLine.activeQ ? listProps.fakeLine.activeQ : false,
                                title: listProps.fakeLine.title,
                                description: listProps.fakeLine.description ? listProps.fakeLine.description : '',
                                pages: listProps.fakeLine.pages ? listProps.fakeLine.pages : [],
                            })
                        }}
                        disabled={!listProps.fakeLine.title}
                    >
                        {translate('save')}
                    </Button>
                )}
            </div>
        ),
    },
    hideActiveRowColor: true,
    disableUpdateUserSettings: true,
    templateCellsWidth: {
        active: '6%',
        title: '20%',
        selectedPages: '13%',
        description: '55%',
    },
    disableListScroll: true,
    fixedColWidth: false,
    dndDblClickDisable: true,
}
