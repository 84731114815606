import React, { ChangeEvent, FC, memo, useCallback, useMemo, useState } from 'react'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { styles } from './Searchbar-styles'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from './Searchbar.theme'
import { ISearchBar } from './SearchBar-types'
import Icon from 'blocks.simple/icon/icon'
import { isExist } from 'core/utils/coreUtil'
import { debounce } from 'lodash'
import { isHorizontalMenu } from 'core/helpers/menu'

const SearchBarComponent: FC<ISearchBar> = ({
    id,
    value,
    classNames,
    placeholder,
    onSearch,
    iconColor,
    mod = 'withShadow',
}) => {
    const currentPlaceholder = placeholder || translate('searchLabel')

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value

        if (!isExist(value)) return

        onSearch(value)
    }

    const getIconColor = () =>
        iconColor || getThemeStyleValue('ui', 'searchbar', 'withShadow-icon') || defaultThemeStyles['withShadow-icon']

    return (
        <div className={cn(styles.SearchBar, styles[`SearchBar_type_${mod}`], classNames)}>
            <input
                className={cn(styles.SearchBar__input, { [styles.SearchBar__input_x]: isHorizontalMenu() })}
                id={id}
                type="text"
                value={value}
                onChange={onChange}
                placeholder={currentPlaceholder}
            />
            <div className={cn(styles.SearchBar__icon)}>
                <Icon type="search" color={getIconColor()} />
            </div>
        </div>
    )
}

export const SearchBar = memo(SearchBarComponent)

const DebouncedSearchbarComponent: FC<ISearchBar> = ({
    id,
    value,
    classNames,
    placeholder,
    onSearch,
    iconColor,
    mod = 'withShadow',
}) => {
    const DEBOUNCE_TIME_MS = 500
    const [query, setQuery] = useState<string>(value || '')

    const onSearchChange = (value: string) => {
        if (!isExist(value)) return

        setQuery(value)
        startSearch(value)
    }

    const startSearch = useCallback(
        debounce((searchString: string) => {
            onSearch(searchString)
        }, DEBOUNCE_TIME_MS),
        [onSearch]
    )
    return (
        <SearchBar
            id={id}
            onSearch={onSearchChange}
            value={query}
            mod={mod}
            iconColor={iconColor}
            placeholder={placeholder}
            classNames={classNames}
        />
    )
}

export const DebouncedSearchbar = memo(DebouncedSearchbarComponent)
