import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles } from '../index.theme'
import { colors, IStyles } from 'theme'
import { textSizes } from 'theme/media'

const ItemBroadcastsStyles: IStyles = {
    wrapper() {
        return {
            ...item.pointer,
            [`&:hover .${this.checkWrapper}`]: {
                opacity: 1,
            },
        }
    },
    broadcast: {
        ...item.overNone,
        background: `var(--var-module-catalog-component-item-style-backgroundMain, ${defaultThemeStyles.backgroundMain})`,
        ...grid.col,
        boxShadow: `var(--var-module-catalog-component-item-style-shadow, ${defaultThemeStyles.shadow})`,
        ...grid.full,
        ...item.cover,
    },
    checkWrapper: {
        ...item.abs,
        ...item.transitionErase,
        top: grid.p_microPlus.padding,
        right: grid.p_micro.padding,
        opacity: 0,
        zIndex: 3,
    },
    name: {
        ...grid.row,
        ...grid.normalCenter,
        padding: '10px 8px',
        ...item.blackOpacity,
        textTransform: 'uppercase',
        color: `var(--var-module-catalog-component-item-style-textMain, ${defaultThemeStyles.textMain})`,
        zIndex: 2,
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    infoContainer: {
        ...grid.row,
        alignItems: 'center',
        width: '100%',
    },
    nameInner: {
        ...grid.space,
        ...grid.pr_mini,
        marginTop: 3,
        ...item.ellipsisPure,
    },
    preview: {
        ...grid.space,
        ...item.abs,
    },
    previewActive: {},
    groupWrapper() {
        return {
            ...item.hover,
            ...item.pointer,
            [`&:hover .${this.checkWrapper}`]: {
                opacity: 1,
            },
        }
    },
    group: {
        ...grid.col,
        ...grid.full,
        boxShadow: `var(--var-module-catalog-component-item-style-shadow, ${defaultThemeStyles.shadow})`,
        ...item.cover,
        ...item.rel,
        ...item.overNone,
        zIndex: 10,
        height: '90%',
        top: '10%',
        backgroundColor: `var(--var-module-catalog-component-item-style-backgroundMain, ${defaultThemeStyles.backgroundMain})`,
    },
    groupBackWrapper: {
        ...grid.full,
        ...grid.colCenter,
        ...item.abs,
        top: 0,
        left: 0,
        zIndex: 1,
    },
    groupBack: {
        ...grid.fullHeight,
        boxShadow: `var(--var-module-catalog-component-item-style-shadow, ${defaultThemeStyles.shadow})`,
        position: 'relative',
    },
    groupBack_first: {
        width: '94%',
        height: '90%!important',
        backgroundColor: `var(--var-module-catalog-component-item-style-backgroundSub, ${defaultThemeStyles.backgroundSub})`,
    },
    groupBack_second: {
        width: '88%',
        backgroundColor: `var(--var-module-catalog-component-item-style-backgroundMain, ${defaultThemeStyles.backgroundMain})`,
    },
    errorGroup: {
        backgroundColor: `var(--var-module-catalog-component-item-style-error, ${defaultThemeStyles.error})`,
    },
    onlineGroup: { background: `var(--var-module-catalog-component-item-style-online, ${defaultThemeStyles.online})` },
    offlineGroup: {
        backgroundColor: `var(--var-module-catalog-component-item-style-offlineGroup, ${defaultThemeStyles.offline})`,
    },
    offline: {
        backgroundColor: `var(--var-module-catalog-component-item-style-offline, ${defaultThemeStyles.offline})`,
    },
    online: { background: `var(--var-module-catalog-component-item-style-online, ${defaultThemeStyles.online})` },
    error: { backgroundColor: `var(--var-module-catalog-component-item-style-error, ${defaultThemeStyles.error})` },
    blackLayer: {
        backgroundColor: `var(--var-module-catalog-component-item-style-active, ${defaultThemeStyles.active})`,
    },
    content: {
        ...grid.space,
        ...grid.colCenter,
        position: 'relative',
    },
    contentBroadcast: {
        ...grid.space,
        ...grid.col,
        ...item.rel,
    },
    loading: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.noWrap,
        color: colors.white,
        fontSize: textSizes.mini,
    },
    progress: {
        ...grid.mr_micro,
        width: 20,
        height: 20,
    },
    noVolumeIcon: {
        marginRight: 4,
    },
}

addStyles(ItemBroadcastsStyles)
export default ItemBroadcastsStyles
