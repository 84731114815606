import { addStyles } from 'ethcss'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { IStyles } from '../../../../theme'

const catalogTemplateTasksStyles: IStyles = {
    list: {
        borderTop: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    tableWrapper: {
        '& > thead': {
            borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
            '& th': {
                borderBottom: 'none',
            },
        },
    },
    status: {
        textAlign: 'center',
    },
    error: {
        color: `var(--var-module-app-component-app-style-error, ${defaultThemeStyles.error})`,
    },
    success: {
        color: `var(--var-module-app-component-app-style-online, ${defaultThemeStyles.online})`,
    },
}

addStyles(catalogTemplateTasksStyles)
export default catalogTemplateTasksStyles
