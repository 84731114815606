import React from 'react'
import { Radio } from 'atoms/Radio'
import translate from 'core/translate'
import styles from './deviceSettings__audioPlayer.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { cn } from 'ethcss'
import { Typography } from 'atoms/Typography'

const DeviceSettingsAudioPlayer = (p_: {
    settings: { audioPlayerEnabled: string; showVolumeHandle: string }
    onChange: (data: any) => void
}) => {
    const onChange = (value: string, event: string | null, key = 'options') => {
        const data: any = {
            ...p_.settings,
            [key]: value,
        }

        p_.onChange(data)
    }

    return (
        <React.Fragment>
            <Typography className={cn(grid.mb_md)} type="title">
                {translate('audioPlayerMode')}
            </Typography>
            <div className={styles.radioButtons}>
                <Radio
                    label={translate('on')}
                    selected={p_.settings.audioPlayerEnabled === 'on'}
                    className={grid.mr_md}
                    onClick={() => onChange('on', null, 'audioPlayerEnabled')}
                />
                <Radio
                    label={translate('off')}
                    selected={p_.settings.audioPlayerEnabled === 'off'}
                    onClick={() => onChange('off', null, 'audioPlayerEnabled')}
                />
            </div>
            <Typography className={cn(grid.mb_md)} type="title">
                {translate('showVolumeHandle')}
            </Typography>
            <div className={cn(styles.radioButtons, p_.settings.audioPlayerEnabled === 'off' ? styles.disabled : '')}>
                <Radio
                    label={translate('on')}
                    selected={p_.settings.showVolumeHandle === 'on'}
                    className={grid.mr_md}
                    onClick={() => onChange('on', null, 'showVolumeHandle')}
                    disabled={p_.settings.audioPlayerEnabled === 'off'}
                />
                <Radio
                    label={translate('off')}
                    selected={p_.settings.showVolumeHandle === 'off'}
                    onClick={() => onChange('off', null, 'showVolumeHandle')}
                    disabled={p_.settings.audioPlayerEnabled === 'off'}
                />
            </div>
        </React.Fragment>
    )
}

export default DeviceSettingsAudioPlayer
