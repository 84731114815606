import { addStyles } from 'ethcss'
import { grid, indents, IStyles, textSizes } from 'theme'

export const styles: IStyles = {
    DigitalSignageExhibitionIndex: {
        ...grid.full,
        ...grid.mt_mdPlus,
    },
    DigitalSignageExhibitionIndex__title: {
        fontSize: textSizes.normal,
        ...grid.mb_md,
    },
    DigitalSignageExhibitionIndex__number: {
        ...grid.w25,
    },
}

addStyles(styles)
