import { addStyles } from 'ethcss'
import { IStyles, grid, item, text } from 'theme'
import { mediaCSS } from 'theme/media'

export const dialogStyles: IStyles = {
    inner: {
        ...grid.colCenter,
        ...item.panel,
        ...item.shadow,
        ...item.rel,
    },
    responsive: {
        ...grid.w25,
        [mediaCSS.tab]: {
            ...grid.w50,
        },
        [mediaCSS.tabMini]: {
            ...grid.w70,
        },
        [mediaCSS.mob]: {
            ...grid.w90,
        },
    },
    title: {
        ...grid.mt_mdPlus,
        ...grid.mb_mdPlus,
        ...text.normal,
        ...text.up,
        ...text.center,
        letterSpacing: '0.1em',
    },
    buttons: {
        ...grid.row,
        ...grid.fullWidth,
        ...grid.justify,
    },
    buttonWrapper: {
        textAlign: 'center',
        ...grid.w50,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    button: {
        ...grid.fullWidth,

        '&>div': {
            width: '100%',
        },
    },
    leftButton: {
        ...grid.pr_micro,
        [mediaCSS.tabMini]: {
            ...grid.pr_none,
        },
        ...grid.pb_micro,
    },
    rightButton: {
        ...grid.pl_micro,
        [mediaCSS.tabMini]: {
            ...grid.pl_none,
        },
    },
}

addStyles(dialogStyles)
