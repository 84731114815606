import React from 'react'
import config from '../index'
import translate from 'core/translate'
import CustomListHeader, { ICustomListHeaderProps } from '../../../__header/customList__header'
import { api } from 'core/api/ConnectionManager'

export default {
    ...config,
    category: 'videos',
    type: 'samsonite',
    headerFilters: (p_: ICustomListHeaderProps) => (
        <CustomListHeader
            onAdd={() => {
                if (!p_.fakeLine) {
                    p_.onAddFakeLine({ name: '', source: null })
                } else {
                    p_.onAnimate(true)
                    setTimeout(() => {
                        p_.onAnimate(false)
                    }, 1000)
                }
            }}
            title={translate('videos')}
            type={p_.type}
            onDelete={() => {
                const id = p_.selectedItems.map((selectedItem) => selectedItem.id)
                if (id.length > 0) {
                    api.send('samsoniteDeleteCatalogItem', { id })
                }
                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            }}
            {...p_}
        />
    ),
}
