import { addStyles } from 'ethcss'
import { colors } from 'theme/colors'
import { IStyles, mediaCSS } from '../../../theme'

const widthQuery = '@media (min-width: 960px) and (max-width: 1200px)'

const widgetStyles: IStyles = {
    widget: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '46%',
        borderBottom: `1px solid ${colors.greyLight}`,
        paddingBottom: 30,
        marginBottom: 30,

        [mediaCSS.tab]: {
            width: '100%',
        },
    },
    widgetLeft: {
        display: 'flex',
        width: 'calc(100% - 150px)',
        cursor: 'pointer',
    },
    titleAndDesc: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: 26,

        [mediaCSS.tab]: {
            marginLeft: 10,
        },
        [widthQuery]: {
            marginLeft: 8,
        },
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        color: colors.blackLight,
        marginBottom: 10,

        [mediaCSS.tab]: {
            fontSize: 18,
        },
        [widthQuery]: {
            fontSize: 17,
        },
    },
    shortDesc: {
        color: colors.grey,
    },
    downloadBtn: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: colors.blackLight,
        color: colors.white,
        padding: '7px 10px',
        borderRadius: 5,
        cursor: 'pointer',

        [mediaCSS.tab]: {
            fontSize: 12,
        },
    },
    downloadIcon: {
        marginLeft: 14,
    },
}

addStyles(widgetStyles)
export default widgetStyles
