import React from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Radio } from 'atoms/Radio'
import Collapse from 'blocks.simple/collapse/collapse'
import helpers from 'core/helpers'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { grid } from '../../../theme'
import { updateUserSettings } from 'blocks.app/user/user.state'
import styles from '../settings.jcss'

const viewOptions: string[] = ['browserTabs', 'dropdownList']

const BroadcastSettings = () => {
    const dispatch = useDispatch()
    const settings: any = useSelector<RootStateOrAny>((state) => state.user.data.settings)
    const lastIndex = viewOptions.length - 1

    return (
        <Collapse
            title={() => translate('toolbarDisplayingInBroadcasts')}
            className={cn(styles.background, grid.mb_md)}
        >
            <div className={cn(grid.mb_md, grid.mt_mini)}>{translate('menuRightPane')}:</div>
            <div className={cn(grid.row)}>
                {viewOptions.map((option: string, index: number) => (
                    <div style={{ marginRight: '40px' }}>
                        <Radio
                            key={`broadcast_settings_view_${option}`}
                            onClick={() => {
                                dispatch(
                                    updateUserSettings({
                                        data: { editor: { toolbarView: option } },
                                        isSaveSettings: true,
                                    })
                                )
                            }}
                            selected={
                                settings.editor && settings.editor.toolbarView
                                    ? settings.editor.toolbarView === option
                                    : option === 'dropdownList'
                            }
                            label={translate(option)}
                            disabled={!helpers.isAvailable({ key: 'settings', action: 'update' })}
                            className={cn({ [grid.mb_mini]: index !== lastIndex })}
                        />
                    </div>
                ))}
            </div>
        </Collapse>
    )
}

export default BroadcastSettings
