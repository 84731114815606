import { addStyles } from 'ethcss'
import { colors, IStyles } from 'theme'

const DsLogMonitor: IStyles = {
    wrapper: {
        padding: '24px 24px 50px 24px',
    },
    log: {
        marginBottom: 2,
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'absolute',
        left: 0,
        bottom: '13px',
        width: '100%',
        padding: '10px 24px',
        backgroundColor: colors.white,
        borderTop: `1px solid ${colors.greyLight}`,
    },
}

addStyles(DsLogMonitor)
export default DsLogMonitor
