import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'

import './css/datepicker.css'
import { defaultThemeStyles } from './editText.theme'

const editTextStyles = {
    wrapper: {
        ...grid.w100,
        ...item.rel,
    },
    validationBorder: {
        border: '1px solid red !important',
    },
    paddingTextError: {
        ...grid.row,
        ...grid.pt_mini,
        ...grid.pb_mini,
        ...grid.justify,
        color: `var(--var-module-ui-component-editText-style-withBorder-error, ${
            defaultThemeStyles[`withBorder-error`]
        })`,
    },
    dateWrapper: {
        paddingRight: 34,
    },
    hoverIconWrapper() {
        return {
            [`& .${this.icon}`]: {
                ...item.hidden,
            },
            [`&:hover .${this.icon}`]: {
                ...item.visible,
            },
        }
    },
    input: {
        ...grid.w100,
        ...grid.rowCenter,
        ...grid.fullHeight,
        padding: 10,
        border: 'none',
        '&[type="number"]': {
            '-moz-appearance': 'textfield',
        },
        '&::-webkit-inner-spin-button, ::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
        },
        '&:disabled': {
            ...item.disabled,
        },
        '&::-webkit-calendar-picker-indicator': {
            background: 'none',
        },
    },
    input_type_inverseBorder: {
        color: `var(--var-module-ui-component-editText-style-inverseBorder-textMain, ${defaultThemeStyles['inverseBorder-textMain']})`,
        backgroundColor: `var(--var-module-ui-component-editText-style-inverseBorder-background, ${defaultThemeStyles['inverseBorder-background']})`,
        border: `1px solid var(--var-module-ui-component-editText-style-inverseBorder-border, ${
            defaultThemeStyles[`inverseBorder-border`]
        })`,
    },
    input_type_white: {
        color: `var(--var-module-ui-component-editText-style-white-textMain, ${defaultThemeStyles[`white-textMain`]})`,
        background: `var(--var-module-ui-component-editText-style-white-background, ${
            defaultThemeStyles[`white-background`]
        })`,
        boxShadow: `var(--var-module-ui-component-editText-style-white-shadow, ${defaultThemeStyles[`white-shadow`]})`,
        borderBottom: `1px solid var(--var-module-ui-component-editText-style-white-border, ${
            defaultThemeStyles[`white-border`]
        })`,
        '&::placeholder': {
            color: `var(--var-module-ui-component-editText-style-white-placeholder, ${
                defaultThemeStyles[`white-placeholder`]
            })`,
        },
    },
    input_type_withBorder: {
        color: `var(--var-module-ui-component-editText-style-withBorder-textMain, ${
            defaultThemeStyles[`withBorder-textMain`]
        })`,
        background: `var(--var-module-ui-component-editText-style-withBorder-background, ${
            defaultThemeStyles[`withBorder-background`]
        })`,
        boxShadow: `var(--var-module-ui-component-editText-style-withBorder-shadow, ${
            defaultThemeStyles[`withBorder-shadow`]
        })`,
        border: `1px solid var(--var-module-ui-component-editText-style-withBorder-border, ${
            defaultThemeStyles[`withBorder-border`]
        })`,
        '&::placeholder': {
            color: `var(--var-module-ui-component-editText-style-withBorder-placeholder, ${
                defaultThemeStyles[`withBorder-placeholder`]
            })`,
        },
    },
    input_type_withShadow: {
        color: `var(--var-module-ui-component-editText-style-default-textMain, ${
            defaultThemeStyles[`default-textMain`]
        })`,
        background: `var(--var-module-ui-component-editText-style-default-background, ${
            defaultThemeStyles[`default-background`]
        })`,
        boxShadow: `var(--var-module-ui-component-editText-style-default-shadow, ${
            defaultThemeStyles[`default-shadow`]
        })`,
        '&::placeholder': {
            color: `var(--var-module-ui-component-editText-style-default-placeholder, ${
                defaultThemeStyles[`default-placeholder`]
            })`,
        },
    },
    input_type_withLightBorder: {
        color: `var(--var-module-ui-component-editText-style-withLightBorder-textMain, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
        background: `var(--var-module-ui-component-editText-style-withLightBorder-background, ${
            defaultThemeStyles[`withLightBorder-background`]
        })`,
        border: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-border`]
        })`,
        '&::placeholder': {
            color: `var(--var-module-ui-component-editText-style-withLightBorder-placeholder, ${
                defaultThemeStyles[`withLightBorder-placeholder`]
            })`,
        },
    },
    input_type_withBottomLine: {
        color: `var(--var-module-ui-component-editText-style-withBottomLine-textMain, ${
            defaultThemeStyles[`withBottomLine-textMain`]
        })`,
        background: `var(--var-module-ui-component-editText-style-withBottomLine-background, ${
            defaultThemeStyles[`withBottomLine-background`]
        })`,
        boxShadow: `var(--var-module-ui-component-editText-style-withBottomLine-shadow, ${
            defaultThemeStyles[`withBottomLine-shadow`]
        })`,
        borderBottom: `1px solid var(--var-module-ui-component-editText-style-withBottomLine-border, ${
            defaultThemeStyles[`withBottomLine-border`]
        })`,
        '&::placeholder': {
            color: `var(--var-module-ui-component-editText-style-withBottomLine-placeholder, ${
                defaultThemeStyles[`withBottomLine-placeholder`]
            })`,
        },
    },
    input_type_default: {
        color: `var(--var-module-ui-component-editText-style-default-textMain, ${
            defaultThemeStyles[`default-textMain`]
        })`,
        background: `var(--var-module-ui-component-editText-style-default-background, ${
            defaultThemeStyles[`default-background`]
        })`,
        border: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
        '&::placeholder': {
            color: `var(--var-module-ui-component-editText-style-default-placeholder, ${
                defaultThemeStyles[`default-placeholder`]
            })`,
        },
    },
    input_type_text: {
        color: `var(--var-module-ui-component-editText-style-text-textMain, ${defaultThemeStyles[`text-textMain`]})`,
        background: `var(--var-module-ui-component-editText-style-text-background, ${
            defaultThemeStyles[`text-background`]
        })`,
        boxShadow: `var(--var-module-ui-component-editText-style-text-shadow, ${defaultThemeStyles[`text-shadow`]})`,
        borderBottom: `1px solid var(--var-module-ui-component-editText-style-text-border, ${
            defaultThemeStyles[`text-border`]
        })`,
        '&::placeholder': {
            color: `var(--var-module-ui-component-editText-style-text-placeholder, ${
                defaultThemeStyles[`text-placeholder`]
            })`,
        },
    },
    error: {},
    error_type_white: {
        borderColor: `var(--var-module-ui-component-editText-style-white-error, ${defaultThemeStyles[`white-error`]})`,
        color: `var(--var-module-ui-component-editText-style-white-error, ${defaultThemeStyles[`white-error`]})`,
    },
    error_type_withBorder: {
        borderColor: `var(--var-module-ui-component-editText-style-withBorder-error, ${
            defaultThemeStyles[`withBorder-error`]
        })`,
        color: `var(--var-module-ui-component-editText-style-withBorder-error, ${
            defaultThemeStyles[`withBorder-error`]
        })`,
    },
    error_type_withLightBorder: {
        borderColor: `var(--var-module-ui-component-editText-style-withLightBorder-error, ${
            defaultThemeStyles[`withLightBorder-error`]
        })`,
        color: `var(--var-module-ui-component-editText-style-withLightBorder-error, ${
            defaultThemeStyles[`withLightBorder-error`]
        })`,
    },
    error_type_withBottomLine: {
        borderColor: `var(--var-module-ui-component-editText-style-withBottomLine-error, ${
            defaultThemeStyles[`withBottomLine-error`]
        })`,
        color: `var(--var-module-ui-component-editText-style-withBottomLine-error, ${
            defaultThemeStyles[`withBottomLine-error`]
        })`,
    },
    error_type_default: {
        borderColor: `var(--var-module-ui-component-editText-style-default-error, ${
            defaultThemeStyles[`default-error`]
        })`,
        color: `var(--var-module-ui-component-editText-style-default-error, ${defaultThemeStyles[`default-error`]})`,
    },
    error_type_text: {
        borderColor: `var(--var-module-ui-component-editText-style-text-error, ${defaultThemeStyles[`text-error`]})`,
        color: `var(--var-module-ui-component-editText-style-text-error, ${defaultThemeStyles[`text-error`]})`,
    },
    errorContainer: {
        ...grid.row,
        ...grid.w100,
        ...grid.mt_micro,
        ...item.abs,
        textAlign: 'center',
    },
    errorText: {
        ...grid.w100,
        whiteSpace: 'nowrap',
    },
    errorText_type_white: {
        color: `var(--var-module-ui-component-editText-style-white-error, ${defaultThemeStyles[`white-error`]})`,
    },
    errorText_type_withBorder: {
        color: `var(--var-module-ui-component-editText-style-withBorder-error, ${
            defaultThemeStyles[`withBorder-error`]
        })`,
    },
    errorText_type_withLightBorder: {
        color: `var(--var-module-ui-component-editText-style-withLightBorder-error, ${
            defaultThemeStyles[`withLightBorder-error`]
        })`,
    },
    errorText_type_withBottomLine: {
        color: `var(--var-module-ui-component-editText-style-withBottomLine-error, ${
            defaultThemeStyles[`withBottomLine-error`]
        })`,
    },
    errorText_type_default: {
        color: `var(--var-module-ui-component-editText-style-default-error, ${defaultThemeStyles[`default-error`]})`,
    },
    errorText_type_text: {
        color: `var(--var-module-ui-component-editText-style-text-error, ${defaultThemeStyles[`text-error`]})`,
    },
    inputWrapper: {
        ...item.rel,
    },
    icon: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.fullHeight,
        ...grid.pr_microPlus,
        ...item.abs,
        ...item.pointer,
        right: 0,
        top: 0,
    },
    label: {
        ...grid.row,
        ...grid.mb_micro,
    },
    label_type_white: {
        color: `var(--var-module-ui-component-editText-style-white-textMain, ${defaultThemeStyles[`white-textMain`]})`,
    },
    label_type_withBorder: {
        color: `var(--var-module-ui-component-editText-style-withBorder-textMain, ${
            defaultThemeStyles[`withBorder-textMain`]
        })`,
    },
    label_type_withLightBorder: {
        color: `var(--var-module-ui-component-editText-style-withLightBorder-textMain, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
    },
    label_type_withBottomLine: {
        color: `var(--var-module-ui-component-editText-style-withBottomLine-textMain, ${
            defaultThemeStyles[`withBottomLine-textMain`]
        })`,
    },
    label_type_default: {
        color: `var(--var-module-ui-component-editText-style-default-textMain, ${
            defaultThemeStyles[`default-textMain`]
        })`,
    },
    label_type_text: {
        color: `var(--var-module-ui-component-editText-style-text-textMain, ${defaultThemeStyles[`text-textMain`]})`,
    },
    disabled: {
        ...item.disabled,
    },
    date: {
        zIndex: 1000,
    },
    fullHeight: {
        height: '100%',
    },
}

addStyles(editTextStyles)
export default editTextStyles
