import { addStyles } from 'ethcss'
import { grid, IStyles } from 'theme'
import { defaultThemeStyles } from './ProgressBar-theme'

export const progressBarStyles: IStyles = {
    ProgressBar: {
        width: 20,
        height: 20,
        '& .CircularProgressbar': {
            ...grid.w100,
        },
        '& .CircularProgressbar .CircularProgressbar-path': {
            stroke: `var(--var-module-ui-component-progressBar-style-progressPath, ${defaultThemeStyles.progressPath})`,
            strokeLinecap: 'round',
            transition: 'stroke-dashoffset 0.5s ease 0s',
        },
        '& .CircularProgressbar .CircularProgressbar-trail': {
            stroke: `var(--var-module-ui-component-progressBar-style-progressTrail, ${defaultThemeStyles.progressTrail})`,
        },
    },
}

addStyles(progressBarStyles)
