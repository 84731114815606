import { addStyles } from 'ethcss'
import { relative } from 'path'
import { grid, IStyles, item, textSizes, indents } from 'theme'
import { defaultThemeStyles } from '../Dropdown.theme'

export const styles: IStyles = {
    DropdownOption: {
        ...grid.flex,
        ...grid.normalCenter,
        padding: `${indents.mini}px 0`,
        cursor: 'pointer',
    },
    DropdownOption__label: {},
    DropdownOption_type_withShadow: {
        color: `var(--var-module-ui-component-dropdown-style-withShadow-textSub, ${defaultThemeStyles['withShadow-textSub']})`,
    },
    DropdownOption_type_withShadow_status_active: {
        fontWeight: 'bold',
        color: `var(--var-module-ui-component-dropdown-style-withShadow-activeTextSub, ${defaultThemeStyles['withShadow-activeTextSub']})!important`,
    },
    DropdownOption_type_fill: {
        color: `var(--var-module-ui-component-dropdown-style-fill-textSub, ${defaultThemeStyles['fill-textSub']})`,
    },
    DropdownOption_type_fill_status_active: {
        fontWeight: 'bold',
        color: `var(--var-module-ui-component-dropdown-style-fill-activeTextSub, ${defaultThemeStyles['fill-activeTextSub']})!important`,
    },
    DropdownOption_type_none: {
        color: `var(--var-module-ui-component-dropdown-style-none-textSub, ${defaultThemeStyles['none-textSub']})`,
    },
    DropdownOption_type_none_status_active: {
        fontWeight: 'bold',
        color: `var(--var-module-ui-component-dropdown-style-none-activeTextSub, ${defaultThemeStyles['none-activeTextSub']})!important`,
    },
    DropdownOption_type_default: {
        color: `var(--var-module-ui-component-dropdown-style-default-textSub, ${defaultThemeStyles['default-textSub']})`,
    },
    DropdownOption_type_default_status_active: {
        fontWeight: 'bold',
        color: `var(--var-module-ui-component-dropdown-style-default-activeTextSub, ${defaultThemeStyles['default-activeTextSub']})!important`,
    },
    DropdownOption__icon: {},
    DropdownOption__icon_type_withLabel: {
        paddingRight: `${indents.mini}px`,
    },
}

addStyles(styles)
