import React from 'react';
import {DefaultLinkWidget} from 'storm-react-diagrams';

class FlowDiagramLinkWidget extends DefaultLinkWidget {
    getDirection(source, target) {
        const difX = source.x - target.x,
            difY = source.y - target.y,
            isHorisontal = Math.abs(difX) > Math.abs(difY);

        return isHorisontal ? difX > 0 : difY > 0;
    }
    generateLink(path, extraProps, id) {
        const p_ = this.props;
        let inversed = this.getDirection(p_.link.sourcePort, p_.link.targetPort);

        const elementProps = {
            ...extraProps,
            strokeLinecap: 'round',
            'data-linkid': p_.link.getID()
        };
        const Link = React.cloneElement(p_.diagramEngine.getFactoryForLink(p_.link).generateLinkSegment(p_.link, this, false, path, inversed), elementProps);

        const LinkClone = (
            <path
                strokeWidth={1}
                stroke='rgba(0,0,0,0)'
                d={path}
                ref={ref => ref && this.refPaths.push(ref)}
            />
        );

        return (
            <g key={'link-' + id}>
                {LinkClone}
                {Link}
            </g>
        );
    }
}

export default FlowDiagramLinkWidget