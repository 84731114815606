import { addStyles } from 'ethcss'
import { grid, text } from 'theme'

const catalogTemplateFilesStyles = {
    filesTemplateName: {
        ...grid.row,
        ...grid.noWrap,
        ...grid.normalCenter,
        ...text.left,
        ...text.grey,
    },
    filesTemplateIcon: {
        ...grid.mr_mini,
    },
}

addStyles(catalogTemplateFilesStyles)
export default catalogTemplateFilesStyles
