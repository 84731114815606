import React from 'react'

import { api } from 'core/api/ConnectionManager'
import { mediaJS } from 'theme'
import { getParamsForLink } from 'core/helpers/user'
const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
const removeEventMethod = window.addEventListener ? 'removeEventListener' : 'detachEvent'
// @ts-ignore
const eventer = window[eventMethod]
// @ts-ignore
const removeEventer = window[removeEventMethod]
const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'
const WIDGET_EDITOR__SELECT_MEDIA = 'widgetEditor__selectMedia'
const WIDGET_EDITOR__SELECT_MEDIA_WITH_FOLDERS = 'widgetEditor__selectMediaWithFolders'

type State = {
    text: string | null
    webAppPreviewURL: string | null
    width: number
    height: number
    data: any
    dimensions: {
        width?: number
        height?: number
    }
    loadingData: boolean
    showSettingsOnMobile: boolean
}

type Item = {
    id: number
    src: string
    fileType: string
    name: string
    folderId: number
    data: string
    isDirectory: number
} | null

interface IPreviewWebAppProps {
    item: Item
    emitError: (err: string) => void
    tokens?: {
        accessToken: string
        refreshToken: string
    }
    isEditable: boolean
    mainUser?: { id: number }
    user?: { id: number }
    onClose?: () => void
    locale?: string
    width?: number
    height?: number
    indents?: string
}

class PreviewWebAppMethods extends React.Component<IPreviewWebAppProps> {
    listenersId = []
    selectContent: any = null
    webAppPreview: any = null
    state: State = {
        text: null,
        webAppPreviewURL: null,
        width: 1920,
        height: 1080,
        data: null,
        dimensions: {},
        loadingData: false,
        showSettingsOnMobile: false,
    }

    onWebAppPreviewUrlCreated = ({ sourceId, webAppPreviewURL }: { sourceId: number; webAppPreviewURL: string }) => {
        const p_ = this.props

        if (p_.item && sourceId !== p_.item.id) {
            return false
        }

        this.setState({ webAppPreviewURL: this.getPreviewUrl(webAppPreviewURL) })
    }

    onWebAppBuildingStatusChanged = ({
        sourceId,
        webAppBuilding,
    }: {
        sourceId: number
        webAppBuilding: { status: string }
    }) => {
        const p_ = this.props

        if (p_.item && sourceId !== p_.item.id) {
            return false
        }

        if (webAppBuilding.status === 'in_progress') {
            this.setState({ loadingData: true })
        } else if (webAppBuilding.status === 'error') {
            p_.emitError('webAppBuildingError')
            this.setState({ loadingData: false, webAppPreviewURL: null })
        } else {
            this.setState({ loadingData: false, webAppPreviewURL: null })
            api.send('getWebAppPreviewUrl', { sourceId: p_.item ? p_.item.id : null }).then(
                ({ webAppPreviewURL }: any) => {
                    this.setState({ webAppPreviewURL: this.getPreviewUrl(webAppPreviewURL) })
                }
            )
        }
    }

    componentDidMount() {
        const p_ = this.props
        this.initPreview(p_)

        api.addObserver('webAppPreviewUrlCreated', this.onWebAppPreviewUrlCreated, this.listenersId)
        api.addObserver('webAppBuildingStatusChanged', this.onWebAppBuildingStatusChanged, this.listenersId)

        eventer(messageEvent, this.getData, false)
    }
    componentDidUpdate(prevProps: IPreviewWebAppProps) {
        const p_ = this.props

        if (p_.item && p_.item.id !== prevProps!.item!.id) {
            this.setState(
                {
                    text: null,
                    webAppPreviewURL: null,
                    data: null,
                    dimensions: {},
                    loadingData: null,
                },
                () => {
                    this.initPreview(p_)
                }
            )
        }
    }
    componentWillUnmount() {
        this.listenersId.forEach((id) => api.removeObserver(id))

        removeEventer(messageEvent, this.getData, false)
    }
    getPreviewUrl = (baseUrl: string) => {
        const p_ = this.props
        const params =
            p_.tokens && p_.mainUser && p_.user ? getParamsForLink(p_.tokens, p_.mainUser.id, p_.user.id) : {}
        const url = `${baseUrl}?${params}`

        return p_.isEditable ? `${url}&editWidget` : url
    }
    initPreview = (p_: IPreviewWebAppProps) => {
        api.send('getFile', { id: p_.item!.id }).then(({ data }: any) => {
            const loadingData = data.webAppBuilding && data.webAppBuilding.status === 'in_progress'
            const state = {
                loadingData,
                showSettingsOnMobile: false,
            }

            //TODO: Убрать логику с showSettingsOnMobile когда все виджеты перейдут на эту логику

            if (data.widgetInfo && data.widgetInfo.showSettingsOnMobile && mediaJS.tabMini.matches) {
                state.showSettingsOnMobile = true
            }

            this.setState({
                ...state,
            })

            api.send('getWebAppPreviewUrl', { sourceId: p_.item!.id }).then(({ webAppPreviewURL, status }: any) => {
                if (status === 'success') {
                    this.setState({ webAppPreviewURL: this.getPreviewUrl(webAppPreviewURL) })
                }
            })
        })
    }
    getData = ({ data }: { data: string }) => {
        const isSelectMedia = data === WIDGET_EDITOR__SELECT_MEDIA || data === WIDGET_EDITOR__SELECT_MEDIA_WITH_FOLDERS

        if (isSelectMedia) {
            this.onOpenModalCatalogContent()
        }

        this.setState({ data }, () => {
            if (isSelectMedia) {
                return
            }

            const p_ = this.props
            const s_ = this.state

            if (s_.showSettingsOnMobile && p_.isEditable) {
                this.save(p_.onClose ? p_.onClose : () => {})
            }
        })
    }

    onOpenModalCatalogContent = () => {
        if (this.selectContent) {
            this.selectContent.openModal()
        }
    }

    iframeOnLoad = () => {
        const { tokens } = this.props

        const data = JSON.stringify({
            host: window.spconfig.ip_address,
            token: tokens ? tokens.accessToken : '',
        })

        this.webAppPreview.contentWindow.postMessage(`authorization__${data}`, '*')
        this.setSettingsToWidget()
    }

    setSettingsToWidget = () => {
        const p_ = this.props
        const s_ = this.state

        if (!p_.isEditable) {
            return
        }

        const locale = p_.locale

        const settings = JSON.stringify({
            showSettingsOnMobile: s_.showSettingsOnMobile,
            locale,
        })

        this.webAppPreview.contentWindow.postMessage(`settings__${settings}`, '*')
    }

    onSelectCatalogContent = (item: Item) => {
        if (item) {
            if (item.isDirectory) {
                api.send('getSources', { folderId: item.id })
                    .then((res: any) => {
                        return res.map((source: Item) => ({
                            id: source!.id,
                            src: source!.src,
                            type: source!.fileType,
                            name: source!.name,
                            folderId: source!.folderId,
                            data: source!.data,
                        }))
                    })
                    .then((fileSources) => {
                        if (this.state.data === WIDGET_EDITOR__SELECT_MEDIA_WITH_FOLDERS) {
                            api.send('getFolder', { folderId: item.id }).then((res: any) => {
                                let folders = this._getFoldersOnly(res.content)
                                const filesWithFolders = {
                                    files: fileSources,
                                    folders,
                                }

                                this.webAppPreview.contentWindow.postMessage(
                                    `selectMediaWithFolders__${JSON.stringify(filesWithFolders)}`,
                                    '*'
                                )
                            })
                            return
                        }

                        this.webAppPreview.contentWindow.postMessage(`selectMedia__${JSON.stringify(fileSources)}`, '*')
                    })

                return
            }

            this.webAppPreview.contentWindow.postMessage(
                `selectMedia__${JSON.stringify({
                    id: item.id,
                    src: item.src,
                    type: item.fileType,
                    name: item.name,
                    data: item.data,
                })}`,
                '*'
            )
        }
    }

    _getFoldersOnly = (contents: { isDirectory: number }[]) => {
        let folders: any = []
        if (contents && Array.isArray(contents)) {
            folders = contents.filter((content) => content.isDirectory === 1)
        }
        return folders
    }

    save = (callback: () => void) => {
        const s_ = this.state
        const p_ = this.props

        if (s_.data) {
            console.log('data to save: ' + s_.data)

            try {
                const data = JSON.parse(s_.data)
                const dataToUpdate = {
                    sourceId: p_.item ? p_.item.id : null,
                    data,
                }

                api.send('updateWebApp', dataToUpdate).then(() => {
                    if (s_.showSettingsOnMobile && p_.isEditable) {
                        return
                    }

                    this.setState({ loadingData: true })
                })
            } catch (e) {}
        }

        callback()
    }
}

export default PreviewWebAppMethods
