import React, { FunctionComponent, memo, useRef } from 'react'
import { IVideoChatModalVideoComponent } from './VideoChatModalVideo-types'
import { styles } from './VideoChatModalVideo-styles'
import { usePlayMediaSourceStream } from './VideoChatModalVideo-hooks'

const VideoChatModalVideoComponent: FunctionComponent<IVideoChatModalVideoComponent> = ({
    id,
    srcObject,
    isLocal = false,
}) => {
    const videoRef = useRef<HTMLVideoElement>(null)
    usePlayMediaSourceStream(videoRef, srcObject)

    return (
        <div className={styles.videoChatModalVideo}>
            {isLocal ? (
                <video autoPlay className={styles.videoChatModalVideo__media} muted id={id} ref={videoRef} />
            ) : (
                <video autoPlay className={styles.videoChatModalVideo__media} id={id} ref={videoRef} />
            )}
        </div>
    )
}

export const VideoChatModalVideo = memo(VideoChatModalVideoComponent)
