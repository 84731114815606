import React, { useCallback, useEffect, useRef, useState } from 'react'
import Icon from '../../blocks.simple/icon/icon'
import styles from './ScrollableMenu-styles'
import { cn } from 'ethcss'

let shiftLeft = 30

const getChildrenLength = (children: any) => {
    if (children.length) {
        if (children[0].props.children) {
            return children[0].props.children.filter((child: any) => child !== null).length
        }
    }

    return 0
}

export const ScrollableMenuView = ({ children }: any) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [isAvailableArrows, setIsAvailableArrows] = useState(true)
    const [isRightLimit, setIsRightLimit] = useState(false)
    const menuRef = useRef<any>(null)
    const scrollAreaRef = useRef<any>(null)

    const shiftOverflowItem = (item: any, items: any) => {
        const rightBorder = item.offsetLeft + scrollAreaRef.current.offsetWidth

        for (let i = 0; i < items.length; i++) {
            const item = items[i]
            const itemLeftBorder = item.offsetLeft

            if (rightBorder > itemLeftBorder) {
                const subMenuWrapperNode = item.children[1]

                if (subMenuWrapperNode.children.length) {
                    const subMenu = subMenuWrapperNode.children[0]
                    const subMenuWidth = subMenu.offsetWidth
                    const subMenuRightBorder = itemLeftBorder + subMenuWidth

                    if (subMenuRightBorder > rightBorder) {
                        const subItemOverflowValue = rightBorder - subMenuRightBorder
                        subMenu.style.left = subItemOverflowValue + 'px'
                    } else {
                        subMenu.style.left = 0
                    }
                }
            }
        }
    }

    const slideMenu = useCallback(
        (direction) => {
            if (!children.length) return
            let itemsWidth = 0
            const isNext = direction === 'next'
            const className = children[0].props.className
            const menu: any = document.querySelector('.' + className)
            const items = menu.children
            const defaultStep = 2
            const actionStep = isNext
                ? currentIndex + defaultStep < items.length - 1
                    ? defaultStep
                    : items.length - 1 - currentIndex
                : currentIndex - defaultStep > 0
                ? defaultStep
                : // : 0
                  1
            const item =
                items[
                    isNext
                        ? currentIndex + actionStep
                        : currentIndex - actionStep === currentIndex
                        ? 0
                        : currentIndex - actionStep
                ]

            if (!item) return

            const moveValue = -item.offsetLeft

            Array.prototype.slice.call(menu.children, 0).forEach((child: any) => {
                itemsWidth += child.offsetWidth
            })

            menu.style.transform = `translateX(${moveValue + shiftLeft}px)`

            setCurrentIndex(
                isNext
                    ? currentIndex + actionStep
                    : currentIndex - actionStep === currentIndex
                    ? 0
                    : currentIndex - actionStep
            )
            shiftLeft = 0

            shiftOverflowItem(item, items)

            setIsRightLimit(itemsWidth - (item.offsetLeft + shiftLeft) - menu.offsetWidth < 0)
        },
        [children.length, currentIndex]
    )

    const checkAvailableArrows = useCallback(() => {
        if (children.length) {
            const className = children[0].props.className
            const menu: any = document.querySelector('.' + className)

            if (menu) {
                let itemsSum = 0
                const items = Array.from(menu.children)

                items.forEach((item: any) => {
                    itemsSum += item.offsetWidth
                })

                //@ts-ignore
                setIsAvailableArrows(itemsSum + 60 > menuRef.current.offsetWidth)
                slideMenu('prev')
            }
        }
    }, [children.length])

    useEffect(() => {
        checkAvailableArrows()

        window.addEventListener('resize', checkAvailableArrows)

        return () => window.removeEventListener('resize', checkAvailableArrows)
    }, [children.length])

    return (
        <div ref={menuRef} className={cn(children.length ? styles.borderStyles : '', styles.scrollableMenu)}>
            {isAvailableArrows ? (
                <Icon
                    containerClass={cn(styles.arrow, styles.arrowLeft)}
                    onClick={() => slideMenu('prev')}
                    type={'left_icon_menu'}
                    size={14}
                    disabled={currentIndex === 0}
                />
            ) : null}
            <div ref={scrollAreaRef} className={styles.scrollArea}>
                {children}
            </div>
            {isAvailableArrows ? (
                <Icon
                    containerClass={cn(styles.arrow, styles.arrowRight)}
                    onClick={() => slideMenu('next')}
                    type={'right_icon_menu'}
                    size={14}
                    disabled={currentIndex >= getChildrenLength(children) - 1 || isRightLimit}
                />
            ) : null}
        </div>
    )
}
