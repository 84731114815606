import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import { mediaCSS, textSizes } from 'theme/media'
import { colors, grid, IStyles } from '../../../theme'

const dashboardDevicesStyles: IStyles = {
    halfWidth: {
        width: '49%',
    },
    fullWidth: {
        width: '100%',
    },
    statusBlock: {
        backgroundColor: 'pink',
        borderRadius: '10px',
        padding: '10px',
        marginBottom: '10px',
    },
    wrapper: {
        ...grid.row,
        justifyContent: 'space-between',
    },
    content: {
        ...grid.w50,
        ...grid.mt_mini,
    },
    title: {
        textTransform: 'uppercase',
        color: colors.white,
    },
    info: {
        marginTop: '18px',
        fontSize: '64px',
        fontWeight: 600,
        lineHeight: 1,
        textAlign: 'right',
        color: colors.white,
    },
    link: {
        textDecoration: 'none',
    },
    footer: {
        ...item.abs,
        bottom: '6px',
        right: '6px',
        fontSize: textSizes.mini,
    },
}

addStyles(dashboardDevicesStyles)
export default dashboardDevicesStyles
