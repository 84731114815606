import React, { FC, Fragment, useContext, useState } from 'react'
import Dotdotdot from 'react-dotdotdot'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import DropMenu from 'blocks.simple/dropMenu/dropMenu'
import Button from 'blocks.simple/button/button'

import translate from 'core/translate'
import { routes } from 'features/routes'
import { api } from 'core/api/ConnectionManager'

import { cn } from 'ethcss'
import { styles } from './ScoreboardsToolbarContent-styles'
import { grid, item } from 'theme'
import { text as textStyles } from 'theme'
import { Typography } from 'atoms/Typography'
import { getURLSearchParamsString } from 'features/routes/utils'
import { IScoreboardsToolbarContentProps } from './ScoreboardsToolbarContent-types'
import { CatalogWithPaginationContext } from 'organisms/CatalogWithPagination'
import { ConfirmNotification } from 'molecules/ConfirmNotification'
import { ConfirmNotificationWithChoices } from 'molecules/ConfirmNotificationWithChoices'
import { IChoice } from 'molecules/ConfirmNotificationWithChoices/ConfirmNotificationWithChoices-types'
import { TActionType } from 'core/models/Roles'
import helpers from 'core/helpers'
import { changeLocation } from 'features/routes'
import { useDispatch } from 'react-redux'
import { scoreboardsActions } from 'pages/scoreboards/scoreboards.state'
import content from '../../../../../blocks.app/content/content'

export const ScoreboardsToolbarContent: FC<IScoreboardsToolbarContentProps> = ({ catalogItem }) => {
    const dispatch = useDispatch()
    const [active, setActive] = useState<string[]>([])
    const { methods, catalogMethods } = useContext(CatalogWithPaginationContext)
    const { onResetSelectedInfo } = catalogMethods
    const { isFolder } = methods
    const { id, description, name, status, actions = [], type } = catalogItem

    const onChangeDropMenu = (value: string[]) => {
        setActive(value)
    }

    const onEditFolder = () => {
        changeLocation({
            pathname: `/${routes.editScoreboardFolder.path}`,
            search: getURLSearchParamsString({
                controllerFolderId: catalogItem.id,
                parentId: catalogItem.parentId,
            }),
        })
    }

    const onEditScoreboard = () => {
        changeLocation({
            pathname: `/${routes.editScoreboard.path}`,
            search: getURLSearchParamsString({
                controllerId: catalogItem.id,
                parentId: catalogItem.parentId,
            }),
        })
    }

    const onClickDropMenu = (action: string) => {
        if (action === 'edit') {
            if (isFolder(catalogItem)) {
                onEditFolder()
                return
            }

            onEditScoreboard()
        }
    }

    const onDeleteItem = (e: React.MouseEvent) => {
        e.preventDefault()
        setActive([])

        api.send('deleteScoreboard', { ledControllerId: [id] }).catch(() => {})
    }

    const onDeleteFolder = (choices: IChoice[]) => {
        const DELETE_FOLDER_FLAG = 'keepContentQ'
        const choiceWithFolderFlag = choices.find((choice) => choice.value === DELETE_FOLDER_FLAG)
        const folderFlagValue = choiceWithFolderFlag ? choiceWithFolderFlag.isEnable : true

        const sendData = {
            ledControllerFolderId: [id],
            [DELETE_FOLDER_FLAG]: folderFlagValue,
        }

        api.send('deleteScoreboardFolder', sendData).catch(() => {})

        setActive([])
    }

    const onEditFolderSettings = () => {
        changeLocation({
            pathname: `/${routes.scoreboardsSettings.path}`,
            search: getURLSearchParamsString({
                controllerFolderId: catalogItem.id,
                parentId: catalogItem.parentId,
            }),
        })
    }

    const onEditControllerSettings = () => {
        changeLocation({
            pathname: `/${routes.scoreboardsSettings.path}`,
            search: getURLSearchParamsString({
                ledControllerId: catalogItem.id,
                parentId: catalogItem.parentId,
            }),
        })
    }

    const onSynchronizeAll = (synchronizeQ: boolean) => {
        api.send('updateScoreboardsInfoByFolderId', {
            ledControllerFolderId: catalogItem.id,
            settings: { synchronizeQ: synchronizeQ },
        })
    }

    const onLocationFolder = () => {
        changeLocation({
            pathname: `/${routes.scoreboards.path}`,
            search: getURLSearchParamsString({
                parentId: catalogItem.parentId,
            }),
        })
    }

    const handleClearDropMenu = (e: React.FormEvent) => {
        onChangeDropMenu([])
    }

    const getDeleteFolderModal = () => {
        return (
            <ConfirmNotificationWithChoices
                text={translate('scoreboardFolderDelete')}
                onSubmit={onDeleteFolder}
                choices={[
                    {
                        value: 'keepContentQ',
                        label: translate('saveScoreboards'),
                        isEnable: true,
                    },
                ]}
            />
        )
    }

    const getDeleteItemModal = () => {
        return (
            <ConfirmNotification
                text={`${translate('scoreboardDeleteWarning')} ?`}
                confirmButtonText={translate('yes')}
                rejectButtonText={translate('no')}
                onReject={handleClearDropMenu}
                onConfirm={onDeleteItem}
            />
        )
    }

    const getDeleteMenuItemModal = () => {
        return isFolder(catalogItem) ? getDeleteFolderModal() : getDeleteItemModal()
    }

    const isShowDropMenuItem = (action: TActionType) => {
        return helpers.isAvailable({ key: 'scoreboard', action }) && actions.includes(action)
    }

    const isLedController = () => {
        return type === 'ledController'
    }

    const onOpenNetworkConverter = () => {
        dispatch(
            scoreboardsActions.openModal({ modalState: 'networkConverter', modalOptions: {}, modalData: catalogItem })
        )
    }

    const onOpenControllerCurrentData = () => {
        dispatch(
            scoreboardsActions.openModal({
                modalState: 'currentControllerData',
                modalOptions: {},
                modalData: catalogItem,
            })
        )
    }

    const ledControllerMenu = () => {
        return (
            <ul className={styles.ScoreboardsToolbarContent__actionsMenu}>
                <Typography element="li" type="title" onClick={onOpenNetworkConverter}>
                    <div className={styles.menuItem}>{translate('networkConverter')}</div>
                </Typography>
                <Typography element="li" type="title" onClick={onOpenControllerCurrentData}>
                    <div className={styles.menuItem}>{translate('controllerCurrentData')}</div>
                </Typography>
                <Typography element="li" type="title" onClick={onEditControllerSettings}>
                    <div className={styles.menuItem}>{translate('settings')}</div>
                </Typography>
                <Typography element="li" type="title" onClick={onLocationFolder}>
                    <div className={styles.menuItem}>{translate('location')}</div>
                </Typography>
            </ul>
        )
    }

    const ledControllerGroupMenu = () => {
        return (
            <ul className={styles.ScoreboardsToolbarContent__actionsMenu}>
                <Typography
                    element="li"
                    type="title"
                    onClick={() => {
                        onSynchronizeAll(true)
                    }}
                >
                    <div className={styles.menuItem}>{translate('activateSync')}</div>
                </Typography>
                <Typography
                    element="li"
                    type="title"
                    onClick={() => {
                        onSynchronizeAll(false)
                    }}
                >
                    <div className={styles.menuItem}>{translate('deactivateSync')}</div>
                </Typography>
            </ul>
        )
    }

    const renderScoreboardActionsMenu = () => {
        if (!helpers.isAvailable({ key: 'scoreboard', action: 'update' })) return null

        if (isLedController()) {
            return ledControllerMenu()
        }

        return ledControllerGroupMenu()
    }

    return (
        <div className={styles.ScoreboardsToolbarContent}>
            <div className={grid.row}>
                <Typography type="title" className={cn(styles.name, item.ellipsisWrapper)}>
                    <div className={item.ellipsis}>{name}</div>
                </Typography>
            </div>
            {description && (
                <div className={cn(styles.itemDescription, textStyles.wordWrap)}>
                    <Dotdotdot clamp={2}>{description}</Dotdotdot>
                </div>
            )}
            <Rectangle className={cn(grid.col, styles.viewInfoNoHiddenOverflow, styles[status])} width={16} height={9}>
                <div className={grid.space} />
                <DropMenu
                    active={active}
                    className={item.blackOpacity}
                    mod="centered"
                    onChange={onChangeDropMenu}
                    onClick={onClickDropMenu}
                    items={[
                        {
                            id: 'edit',
                            icon: 'pencil',
                            tooltip: { title: translate('edit') },
                            show: isShowDropMenuItem('update'),
                        },
                        {
                            id: 'delete',
                            icon: 'delete',
                            tooltip: { title: translate('delete') },
                            show: isShowDropMenuItem('delete'),
                            children: getDeleteMenuItemModal(),
                        },
                    ]}
                />
            </Rectangle>
            {renderScoreboardActionsMenu()}
            <div className={grid.space} />
            <div className={cn(grid.p_md, grid.rowCenter, styles.closeBtn)}>
                <Button mod={'withBorder'} onClick={onResetSelectedInfo} indentSize={'mini'} rounded={'full_normal'}>
                    {translate('close')}
                </Button>
            </div>
        </div>
    )
}
