import React, { useState } from 'react'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import EditText from 'blocks.simple/editText/editText'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import Datepicker from 'blocks.simple/datepicker/datepicker'
import { Radio } from 'atoms/Radio'
import ShowMore from 'blocks.simple/showMore/showMore'
import Icon from 'blocks.simple/icon/icon'
import styles from './scheduleSettings.jcss'
import { cn } from 'ethcss'
import { text as textStyles } from 'theme'
import moment from 'moment'
import translate from 'core/translate'
import helpers from 'core/helpers'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { TScheduelRepeatMode } from '../../core/models/Schedule'

type Time = {
    label?: string
    id: string
    type: string
    mask: string
    value?: string
    disabled?: boolean
    noFocus?: boolean
    placeholder?: string
    mod?: string
    containerClassName?: any
}
interface ISelectTime {
    timeOptions: { id: number; name: string }[]
    time: Time
    onChange: (value: string, e?: any) => void
    scheduleMode?: boolean
}
interface IScheduleSettingsProps {
    repeat?: {
        repeatMode: string
        repeatDays: unknown[]
        disabledDays: string[]
        value: string
        label: string
        options: { id: string; name: string }[]
        days: string[]
        indexOf?: (value: number) => boolean
    }
    onChangeSpecificDays?: (days: string[]) => void
    startDate: { label: string; dateValue: any; id: string; maxDate: any; minDate?: any }
    endDate: { label: string; dateValue: any; id: string; minDate?: any; maxDate?: any | null; disabled?: boolean }
    startTime: Time
    endTime: Time
    onRepeatChange?: (selected: { id: TScheduelRepeatMode }) => void
    onChangeStartDate: (startDate: string) => void
    onChangeEndDate: (startDate: string) => void
    onChangeStartTime: (value: string, e: InputEvent) => void
    onChangeEndTime: (value: string, e: InputEvent) => void
    onChangeDays?: (selected: boolean, dayIndex: number) => void
    onPeriodChange?: (e: any) => void
    onChecked: (e: boolean) => void
    onChangePriority?: (e: number) => void
    onChangeTimeToUse?: (value: string) => void
    onChangeDeferredDownloadDate?: (e: string) => void
    onChangeDeferredDownloadTime?: (value: string, e: any) => void
    period?: { value: string; mod: string; label: string; options: { id: string; name: string }[] }
    checkbox: {
        label: string
        checked: boolean
    }
    deferredDownload?: any
    timeToUse?: string | null
    priority?: number
}

export const SelectTime = (p_: ISelectTime) => {
    const getTimeProps = (time: { value?: string; placeholder?: string; type: string; mask: string }) => {
        const timeProps = { ...time }
        timeProps.placeholder = time.value

        delete timeProps.type

        if (!time.value) {
            delete timeProps.mask
        }

        return timeProps
    }

    const timeProps = getTimeProps(p_.time)

    return (
        <Dropdown
            {...timeProps}
            icon={'time'}
            mod="withBorder"
            options={p_.timeOptions}
            indentSize={'mini'}
            onBlur={function ({ value }, e) {
                p_.onChange(value, e)
            }}
            onChange={({ name }) => {
                p_.onChange(name)
            }}
            searchable={true}
            notFilterOnSearch={true}
            placeholderAsValue={true}
            hideNotFound={true}
            scheduleMode={p_.scheduleMode}
        />
    )
}

const ScheduleSettings = (p_: IScheduleSettingsProps) => {
    const [isShowAdvanced, setIsShowAdvanced] = useState<boolean>(false)

    const timeOptions = helpers.getTimeOptions()

    const isSpecificDays = p_.repeat && p_.repeat.repeatMode === 'exact_dates'
    const priorityOptions = [
        { id: 0, name: translate('lowPriority') },
        { id: 1, name: translate('mediumPriority') },
        { id: 2, name: translate('highPriority') },
    ]

    return (
        <div
            className={cn({
                [grid.row]: isSpecificDays,
                [grid.normalCenter]: isSpecificDays,
            })}
        >
            {isSpecificDays && (
                <div className={styles.calendar}>
                    <Datepicker
                        useMoment
                        inline
                        showWeekNumbers
                        list={p_.repeat && p_.repeat.repeatDays}
                        minDate={moment()}
                        onChange={function (days: string[]) {
                            if (p_.onChangeSpecificDays) {
                                p_.onChangeSpecificDays(days)
                            }
                        }}
                    />
                </div>
            )}
            <div className={cn({ [styles.content]: isSpecificDays })}>
                <div className={cn(styles.wrapper, grid.mb_md, { [styles.row]: isSpecificDays })}>
                    {p_.startDate && !isSpecificDays && (
                        <div className={styles.item}>
                            <EditText
                                {...p_.startDate}
                                type="date"
                                onChange={(e: string) => {
                                    p_.onChangeStartDate(e)
                                }}
                            />
                        </div>
                    )}
                    {p_.endDate && !isSpecificDays && (
                        <div className={styles.item}>
                            <div className={cn(grid.mb_mini, styles.zIndex)}>
                                <EditText
                                    {...p_.endDate}
                                    type="date"
                                    onChange={(e: string) => {
                                        p_.onChangeEndDate(e)
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {p_.startTime && isSpecificDays && (
                        <div className={styles.specificItem}>
                            <SelectTime
                                time={p_.startTime}
                                timeOptions={timeOptions}
                                onChange={(value: string, e: any) => p_.onChangeStartTime(value, e)}
                            />
                        </div>
                    )}
                    {p_.repeat && (
                        <div className={cn(isSpecificDays ? styles.specificItem : styles.item, item.rel)}>
                            <div className={grid.mb_mini}>
                                <Dropdown
                                    value={p_.repeat.value}
                                    mod={'scheduleButtonBorder'}
                                    options={p_.repeat.options}
                                    label={p_.repeat.label}
                                    onChange={(e) => {
                                        if (p_.onRepeatChange) {
                                            p_.onRepeatChange(e)
                                        }
                                    }}
                                />
                            </div>
                            <div className={cn(styles.days, { [item.hidden]: p_.repeat.repeatMode !== 'weekly' })}>
                                {p_.repeat.days.map((day: string, index: number) => {
                                    const dayIndex = index + 1

                                    return (
                                        <div key={`day__checkbox_${dayIndex}`}>
                                            <Checkbox
                                                checked={p_.repeat && p_.repeat.repeatDays.indexOf(dayIndex) !== -1}
                                                onClick={(e: boolean) => {
                                                    if (p_.onChangeDays) {
                                                        p_.onChangeDays(e, dayIndex)
                                                    }
                                                }}
                                                disabled={
                                                    p_.repeat && p_.repeat.disabledDays
                                                        ? p_.repeat.disabledDays.indexOf(day) === -1
                                                        : false
                                                }
                                            />
                                            <div className={cn(grid.mt_mini, textStyles.darkCloud, textStyles.normal)}>
                                                {day}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                    {p_.period && (
                        <div className={isSpecificDays ? styles.specificItem : styles.item}>
                            <div className={grid.mb_mini}>
                                <Dropdown
                                    value={p_.period.value}
                                    mod={p_.period.mod}
                                    options={p_.period.options}
                                    label={p_.period.label}
                                    onChange={(e) => {
                                        if (p_.onPeriodChange) {
                                            p_.onPeriodChange(e)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {(p_.startTime || p_.endTime || p_.checkbox) && (
                    <div className={cn(styles.wrapper, { [styles.row]: isSpecificDays })}>
                        {p_.startTime && !isSpecificDays && (
                            <div className={styles.item}>
                                <SelectTime
                                    scheduleMode
                                    time={p_.startTime}
                                    timeOptions={timeOptions}
                                    onChange={(value: string, e: any) => p_.onChangeStartTime(value, e)}
                                />
                            </div>
                        )}
                        {p_.endTime && (
                            <div className={isSpecificDays ? styles.specificItem : styles.item}>
                                <SelectTime
                                    scheduleMode
                                    time={p_.endTime}
                                    timeOptions={timeOptions}
                                    onChange={(value: string, e: any) => p_.onChangeEndTime(value, e)}
                                />
                            </div>
                        )}
                        {p_.checkbox && (
                            <div className={cn(isSpecificDays ? styles.specificItem : styles.item, grid.col)}>
                                <div className={grid.space} />
                                <div className={grid.pb_micro}>
                                    <Checkbox
                                        {...p_.checkbox}
                                        className={cn(styles.checkbox)}
                                        onClick={(e: any) => {
                                            p_.onChecked(e)
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {(p_.timeToUse || p_.deferredDownload) && (
                    <ShowMore
                        trigger={
                            <div className={styles.more}>
                                <Icon
                                    containerClass={cn(grid.mr_micro)}
                                    type={isShowAdvanced ? 'arrow_up' : 'arrow_down'}
                                    size={16}
                                />
                                {isShowAdvanced ? translate('hide') : translate('more')}
                            </div>
                        }
                        isShow={isShowAdvanced}
                        onToggle={(isShowAdvanced: boolean) => setIsShowAdvanced(isShowAdvanced)}
                    >
                        <div>
                            <div className={styles.scheduleSettings__inputWrapper_type_priority}>
                                <Dropdown
                                    options={priorityOptions}
                                    label={translate('priority')}
                                    value={p_.priority}
                                    onChange={(priority) => {
                                        if (p_.onChangePriority) {
                                            p_.onChangePriority(priority.id)
                                        }
                                    }}
                                    mod={'scheduleButtonBorder'}
                                />
                            </div>
                            {p_.timeToUse && (
                                <div className={cn(grid.row, grid.fullWidth, grid.p_md, grid.mb_md)}>
                                    <Radio
                                        selected={p_.timeToUse === 'utc'}
                                        onClick={() => {
                                            if (p_.onChangeTimeToUse) {
                                                p_.onChangeTimeToUse('utc')
                                            }
                                        }}
                                        className={styles.scheduleSettings__input_type_useUtcTime}
                                        label={translate('useUtcTime')}
                                    />
                                    <Radio
                                        selected={p_.timeToUse === 'local'}
                                        onClick={() => {
                                            if (p_.onChangeTimeToUse) {
                                                p_.onChangeTimeToUse('local')
                                            }
                                        }}
                                        label={translate('useLocalTime')}
                                    />
                                </div>
                            )}
                            {p_.deferredDownload && (
                                <div className={grid.fullWidth}>
                                    {p_.deferredDownload.title}
                                    <div className={styles.wrapper}>
                                        <div className={styles.item}>
                                            <EditText
                                                {...p_.deferredDownload.date}
                                                type="date"
                                                onChange={(e: string) => {
                                                    if (p_.onChangeDeferredDownloadDate) {
                                                        p_.onChangeDeferredDownloadDate(e)
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className={styles.item}>
                                            <SelectTime
                                                time={p_.deferredDownload.time}
                                                timeOptions={timeOptions}
                                                onChange={(value: string, e: any) => {
                                                    if (p_.onChangeDeferredDownloadTime) {
                                                        p_.onChangeDeferredDownloadTime(value, e)
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className={styles.item} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </ShowMore>
                )}
            </div>
        </div>
    )
}

ScheduleSettings.defaultProps = {
    onChangeStartDate: () => {},
    onChangeStartTime: () => {},
    onChangeEndDate: () => {},
    onChangeEndTime: () => {},
    onRepeatChange: () => {},
    onPeriodChange: () => {},
    onChangeDays: () => {},
    onChecked: () => {},
    onChangeDeferredDownloadDate: () => {},
    onChangeDeferredDownloadTime: () => {},
    onChangeSpecificDays: () => {},
    onChangePriority: () => {},
}

export default ScheduleSettings
