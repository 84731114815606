import { addStyles } from 'ethcss'
import { grid, item, IStyles } from 'theme'
import { defaultThemeStyles } from './card.theme'
import { mediaCSS, textSizes } from 'theme'

const CARD_HEIGHT = 'auto'

const cardStyles: IStyles = {
    panel: {
        height: '100%',
        ...grid.w100,
        ...item.overNone,
        background: `var(--var-module-ui-component-card-style-background, ${defaultThemeStyles['background']})`,
        minHeight: CARD_HEIGHT,
        maxHeight: CARD_HEIGHT,
        [mediaCSS.tabMini]: {
            maxHeight: 'none',
        },
    },
    title: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.noWrap,
        ...item.rounded_top_mini,
        padding: '20px 14px 0 14px',
    },
    icon: {
        ...grid.mr_mini,
    },
    body: {
        //flex: '1 1 auto',
        boxSizing: 'border-box',
        ...grid.p_mini,
    },
    footer: {
        ...item.abs,
        bottom: '6px',
        right: '6px',
        fontSize: textSizes.mini,
    },
}

addStyles(cardStyles)
export default cardStyles
