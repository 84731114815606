import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { mediaCSS, textSizes } from 'theme/media'
import { IStyles } from '../../../theme'

const deviceSettingsBrightnessStyles: IStyles = {
    wrapper: {
        ...grid.mt_mdPlus,
        ...grid.w100,
    },
    title: {
        fontSize: textSizes.normal,
    },
    radioWrapper: {
        ...grid.row,
        ...grid.mt_md,
    },
    radio: {
        ...grid.mr_md,
    },
    timersWrapper: {
        ...grid.col,
        ...grid.mr_mdPlus,
        ...grid.w20,
        [mediaCSS.tab]: {
            ...grid.w40,
        },
    },
}

addStyles(deviceSettingsBrightnessStyles)
export default deviceSettingsBrightnessStyles
