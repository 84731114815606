import { addStyles } from 'ethcss'
import { item, IStyles, mediaCSS } from '../../theme'
import { defaultThemeStyles } from './notifyBadge.theme'

const notifyBadgeStyles: IStyles = {
    item: {
        ...item.abs,
        textAlign: 'center',
        padding: '5px 9px',
        borderRadius: 100,
        color: `var(--var-module-ui-component-notifyBadge-style-textMain, ${defaultThemeStyles.textMain})`,
        top: -8,
        right: 0,
        [mediaCSS.desktopL]: {
            top: -10,
            right: 0,
        },
    },
    success: {
        background: `var(--var-module-ui-component-notifyBadge-style-success, ${defaultThemeStyles.success})`,
    },
    error: {
        background: `var(--var-module-ui-component-notifyBadge-style-error, ${defaultThemeStyles.error})`,
    },
    normal: {
        background: `var(--var-module-ui-component-notifyBadge-style-normal, ${defaultThemeStyles.normal})`,
    },
    active: {
        background: `var(--var-module-ui-component-notifyBadge-style-active, ${defaultThemeStyles.active})`,
    },
    disable: {
        background: `var(--var-module-ui-component-notifyBadge-style-disable, ${defaultThemeStyles.disable})`,
    },
    online: {
        background: `var(--var-module-ui-component-notifyBadge-style-online, ${defaultThemeStyles.online})`,
    },
}

addStyles(notifyBadgeStyles)
export default notifyBadgeStyles
