import { isExist, isString } from './coreUtil'

export const compareVersionsDESC = (prevVersion: string, nextVersion: string) => {
    if (!isExist(prevVersion) || !isString(prevVersion)) return 0

    if (!isExist(nextVersion) || !isString(nextVersion)) return 0

    const ARRAY_CHUNKS_LENGTH = 3

    const prevVersionChunks = prevVersion.split('.')
    const nextVersionChunks = nextVersion.split('.')

    if (prevVersionChunks.length < ARRAY_CHUNKS_LENGTH || nextVersionChunks.length < ARRAY_CHUNKS_LENGTH) return 0

    for (let i = 0; i < ARRAY_CHUNKS_LENGTH; i++) {
        if (prevVersionChunks[i] > nextVersionChunks[i]) return -1

        if (prevVersionChunks[i] < nextVersionChunks[i]) return 1
    }

    return 0
}
