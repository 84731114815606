import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment, { Moment } from 'moment'
import helpersSchedule from 'core/helpers/_schedule'
import helpersDisplays from 'core/helpers/_digitalSignages'
import helpersBroadcasts from 'core/helpers/_broadcasts'
import { api } from 'core/api/ConnectionManager'
import { ISchedule, IScheduleDate } from 'core/models/Schedule'
import merge from 'merge'
import { emitError } from 'features/appNotifications/AppNotifications.state'

interface SelectDate {
    checked: boolean
    events: ScheduleEvent[]
}

interface FilterListItem {
    id: string
    name: string
    type: string
}

export interface ScheduleEvent {
    id: number
    title: string
    broadcastTitle: string
    start: number
    end: number
    endDate: number
    allDay?: boolean
    resource?: any
}

interface ScheduleCalendarEvent {
    title: string
    start: Date
    end: Date
    allDay?: boolean
    resource?: any
}

export interface IScheduleState {
    schedules: ISchedule[]
    allSchedules: ISchedule[]
    events: ScheduleEvent[]
    eventsByDate: Record<string, ScheduleEvent>
    selectedDates: ScheduleEvent[]
    selectedEventId: null | string
    showPastSchedule: boolean
    selectedDate: number
    filterList: FilterListItem[]
}

const initialState: IScheduleState = {
    schedules: [],
    allSchedules: [],
    events: [],
    eventsByDate: {},
    selectedDates: [],
    selectedEventId: null,
    showPastSchedule: false,
    selectedDate: moment().toDate().getTime(),
    filterList: [],
}

export const createMethods: any = {
    groups: helpersDisplays.createDisplaysFolder,
    digitalSignages: helpersDisplays.createDisplay,
    broadcasts: helpersBroadcasts.createBroadcast,
    broadcastFolders: helpersBroadcasts.createBroadcastFolder,
}

const getScheduleStartEndDate = (schedule: ISchedule) => {
    return schedule.timeToUse === 'local'
        ? helpersSchedule.getLocalStartEndDate(schedule)
        : helpersSchedule.getUTCStartEndDate(schedule)
}

const getEventsByDate = (events: any[]) => {
    const eventsByDate: any = {}

    events.forEach((event) => {
        const date = moment(event.start).format('YYYY-MM-DD')

        if (!eventsByDate[date]) {
            eventsByDate[date] = []
        }

        eventsByDate[date].push({
            ...event,
            timeTitle: `${moment(event.start).format('HH:mm')} - ${moment(event.endDate).format('HH:mm')}`,
        })
    })

    return eventsByDate
}

const isForeverOrAfterCurrentDate = (endDate: Moment, currentTime: Moment) => {
    return !endDate.isValid() || endDate.isAfter(currentTime)
}

const getEventsFromSchedules = (schedules: ISchedule[], selectedDate: number) => {
    const events: any[] = []

    schedules.forEach((scheduleItem) => {
        let { startDate, endDate, endTime } =
            scheduleItem.timeToUse === 'local'
                ? helpersSchedule.getLocalStartEndDate(scheduleItem)
                : helpersSchedule.getUTCStartEndDate(scheduleItem)

        if (scheduleItem.timeToUse === 'utc') {
            startDate = moment(startDate).local()
            endDate = moment(endDate).local()
        }

        if (scheduleItem.repeatMode === 'forever') {
            endDate = moment(`${moment(startDate).format('YYYY-MM-DD')} ${endTime}`).add(100, 'years')
        }

        const title = `${startDate.format('HH:mm')} ${scheduleItem.broadcast.title}`
        const repeatDays = scheduleItem.repeatDays

        if (scheduleItem.repeatMode === 'once') {
            events.push({
                id: scheduleItem.id,
                title,
                broadcastTitle: scheduleItem.broadcast.title,
                start: startDate.toDate().getTime(),
                end: endDate.toDate().getTime(),
                endDate: endDate.toDate().getTime(),
            })
        } else if (scheduleItem.repeatMode === 'exact_dates') {
            scheduleItem.repeatDays.forEach((repeatDaysItem, index) => {
                const localRepeatDaysItem = scheduleItem.localRepeatDays[index]
                const localRepeatDays = helpersSchedule.getLocalDateFromLocalRepeatDays(
                    localRepeatDaysItem as IScheduleDate
                )

                let { startDate, endDate } =
                    scheduleItem.timeToUse === 'local'
                        ? helpersSchedule.getLocalStartEndDate(localRepeatDays)
                        : helpersSchedule.getUTCStartEndDate(repeatDaysItem as IScheduleDate)

                if (scheduleItem.timeToUse === 'utc') {
                    startDate = moment(startDate).local()
                    endDate = moment(endDate).local()
                }

                events.push({
                    id: scheduleItem.id,
                    title,
                    broadcastTitle: scheduleItem.broadcast.title,
                    start: startDate.toDate().getTime(),
                    end: endDate.toDate().getTime(),
                    endDate: endDate.toDate().getTime(),
                })
            })
        } else {
            let start = moment(startDate)

            let max
            const dateMax = moment(endDate)
            const monthMax = moment(selectedDate).startOf('month').add(2, 'month')
            if (monthMax.isBefore(dateMax)) {
                max = monthMax
            } else {
                max = dateMax
            }

            for (start; start.isBefore(max); start.add(1, 'days')) {
                if (scheduleItem.repeatMode === 'daily' || scheduleItem.repeatMode === 'forever') {
                    events.push({
                        id: scheduleItem.id,
                        title,
                        broadcastTitle: scheduleItem.broadcast.title,
                        start: start.toDate().getTime(),
                        end: start.toDate().getTime(),
                        endDate: endDate.toDate().getTime(),
                    })
                } else if (scheduleItem.repeatMode === 'weekly') {
                    let day = start.day()

                    if (day === 0) {
                        day = 7
                    }

                    if (repeatDays.indexOf(day) !== -1) {
                        events.push({
                            id: scheduleItem.id,
                            title,
                            broadcastTitle: scheduleItem.broadcast.title,
                            start: start.toDate().getTime(),
                            end: start.toDate().getTime(),
                            endDate: endDate.toDate().getTime(),
                        })
                    }
                }
            }
        }
    })

    const eventsByDate = getEventsByDate(events)
    return { events, eventsByDate: { ...eventsByDate } }
}

export const getSchedules = createAsyncThunk(
    'schedule/getSchedules',
    async (data: any, { dispatch, getState, rejectWithValue }) => {
        try {
            const schedules = await api.send<any, ISchedule[]>('getSchedules', data.req, {
                hideLoader: data.hideLoader,
            })
            const state = getState() as { schedule: IScheduleState }
            const { showPastSchedule } = state.schedule
            const currentTime = moment()

            const allSchedules = schedules.map((schedule) => {
                const { startDate } = getScheduleStartEndDate(schedule)
                const { repeatMode } = schedule

                return repeatMode === 'weekly'
                    ? {
                          ...schedule,
                          repeatDays: helpersSchedule.revertFormatRepeatDays(
                              schedule.repeatDays as number[],
                              startDate.toString()
                          ),
                      }
                    : { ...schedule }
            })

            const filteredSchedules = allSchedules.filter((schedule) => {
                const { endDate } = getScheduleStartEndDate(schedule)

                return showPastSchedule || !endDate.isBefore(currentTime)
            })

            return {
                allSchedules,
                filteredSchedules,
            }
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const deleteSchedule = createAsyncThunk(
    'schedule/deleteSchedule',
    async (id: number, { dispatch, rejectWithValue }) => {
        try {
            await api.send('deleteSchedule', { id })
            return id
        } catch (err) {
            dispatch(emitError('scheduleDeletionError'))
            return rejectWithValue(err)
        }
    }
)

const scheduleSlice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        onUpdateSchedule(state, action: PayloadAction<any>) {
            const updatedSchedules = state.schedules.map((schedule) => {
                return schedule.id === action.payload.id ? merge.recursive(true, schedule, action.payload) : schedule
            })

            const { events, eventsByDate } = getEventsFromSchedules(updatedSchedules, state.selectedDate)

            state.schedules = updatedSchedules
            state.events = events
            state.eventsByDate = eventsByDate
        },
        onDeleteSchedule(state, action: PayloadAction<number>) {
            const schedules = state.schedules.filter((schedule) => schedule.id !== action.payload)
            const events = state.events.filter((event) => event.id !== action.payload)
            const eventsByDate = getEventsByDate(events)

            state.schedules = schedules
            state.events = events
            state.eventsByDate = eventsByDate
        },
        navigate(state, action: PayloadAction<number>) {
            state.selectedDate = action.payload

            const { events, eventsByDate } = getEventsFromSchedules(state.schedules, action.payload)

            state.events = events
            state.eventsByDate = eventsByDate
        },
        selectDate(state, action: PayloadAction<SelectDate>) {
            const { checked, events } = action.payload

            let newSelectedDates = checked
                ? [...state.selectedDates, ...events]
                : state.selectedDates.filter((item) => !events.map((itemDate) => itemDate.id).includes(item.id))

            state.selectedDates = newSelectedDates.map((item) => ({ ...item, type: 'schedule' }))
        },
        filterSchedules(state) {
            const currentTime = moment()

            const schedules = state.allSchedules.filter((schedule) => {
                const endDate = moment.utc(`${schedule.endDate} ${schedule.endTime}`, 'YYYY-MM-DD HH:mm:ss')
                return state.showPastSchedule || isForeverOrAfterCurrentDate(endDate, currentTime)
            })

            const { events, eventsByDate } = getEventsFromSchedules(schedules, state.selectedDate)

            state.schedules = schedules
            state.events = events
            state.eventsByDate = eventsByDate
        },
        setFilterList(state, action: PayloadAction<any[]>) {
            state.filterList = action.payload
        },
        clearSchedules(state) {
            state.allSchedules = []
            state.schedules = []
            state.events = []
            state.eventsByDate = {}
        },
        setSelectedEventId(state, action: PayloadAction<string>) {
            state.selectedEventId = action.payload
        },
        setSelectedDate(state, action: PayloadAction<number>) {
            state.selectedDate = action.payload
        },
        setSelectedDates(state, action: PayloadAction<ScheduleEvent[]>) {
            state.selectedDates = action.payload
        },
        setShowPastSchedule(state, action: PayloadAction<boolean>) {
            state.showPastSchedule = action.payload
        },
        clearState(state) {
            state.schedules = []
            state.allSchedules = []
            state.events = []
            state.eventsByDate = {}
            state.selectedDates = []
            state.selectedEventId = null
            state.showPastSchedule = false
            state.selectedDate = moment().toDate().getTime()
            state.filterList = []
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(getSchedules.fulfilled, (state, { payload }) => {
                const { events, eventsByDate } = getEventsFromSchedules(payload.filteredSchedules, state.selectedDate)

                state.allSchedules = payload.allSchedules
                state.schedules = payload.filteredSchedules
                state.events = events
                state.eventsByDate = eventsByDate
            })
            .addCase(deleteSchedule.fulfilled, (state, { payload }) => {
                state.allSchedules = state.schedules.filter((schedule) => schedule.id !== payload)
            }),
})

const { reducer: scheduleReducer, actions: scheduleActions } = scheduleSlice
export { scheduleReducer, scheduleActions }
