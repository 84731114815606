// @ts-ignore
import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../theme'
import { defaultThemeStyles } from './collapse.theme'
import { textSizes } from 'theme/media'

const collapseStyles: IStyles = {
    wrapper: {
        ...item.panel,
        ...grid.p_none,
    },
    title: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
        fontSize: textSizes.normal,
        ...item.pointer,
        ...grid.p_mini,
        color: `var(--var-module-ui-component-collapse-style-textMain, ${defaultThemeStyles.textMain})`,
    },
    contentWrapper: {
        ...item.transitionErase,
        ...item.overNone,
    },
    content: {
        ...grid.p_mini,
    },
    withoutOverflow: {
        overflow: 'visible!important',
    },
    noWrap: {
        flexWrap: 'nowrap !important',
    },
}

addStyles(collapseStyles)
export default collapseStyles
