import React, { FunctionComponent, memo } from 'react'
import { IAudioPlaygroundComponent } from './AudioPlayground-types'
import { audioPlaygroundStyles } from './AudioPlayground-styles'
import { AudioPlaygroundItem } from './AudioPlaygroundItem'
import translate from 'core/translate'
import { AudioPlaceholder } from 'atoms/AudioPlaceholder'

const AudioPlaygroundComponent: FunctionComponent<IAudioPlaygroundComponent> = ({ audioSourceList }) => {
    const renderAudioList = () => {
        return audioSourceList
            .filter((audio) => audio.playingIn === 'area')
            .map(({ uniqueName, volume, areaId, fileURL, contentId, currentMs, playingIn }) => {
                return (
                    <AudioPlaygroundItem
                        key={`${contentId}${playingIn}`}
                        currentMs={currentMs}
                        title={`${translate('area')} ${areaId}`}
                        text={uniqueName}
                        volume={volume}
                        fileURL={fileURL}
                    />
                )
            })
    }

    const renderSoundtrack = () => {
        return audioSourceList
            .filter((audio) => audio.playingIn === 'soundtrack')
            .map(({ uniqueName, volume, fileURL, contentId, currentMs, playingIn }) => {
                return (
                    <AudioPlaygroundItem
                        key={`${contentId}${playingIn}`}
                        currentMs={currentMs}
                        title={translate('soundtrack')}
                        text={uniqueName.replace(/\.[^/.]+$/, '')}
                        volume={volume}
                        fileURL={fileURL}
                    />
                )
            })
    }

    return (
        <div className={audioPlaygroundStyles.AudioPlayground}>
            <div className={audioPlaygroundStyles.AudioPlayground__image}>
                <AudioPlaceholder size={140} />
            </div>
            <div className={audioPlaygroundStyles.AudioPlayground__audioList}>{renderAudioList()}</div>
            <div className={audioPlaygroundStyles.AudioPlayground__soundtrack}>{renderSoundtrack()}</div>
        </div>
    )
}

export const AudioPlayground = memo(AudioPlaygroundComponent)
