/**
 * Модель параметров расширенного поиска, + дефолтные значения для установки их в модель пользователя (настройки)
 * + используется для сброса параметров в organisms/AdvancedSearch
 */

export enum AdvancedSearchOperationsEnum {
    OR = 'OR',
    AND = 'AND',
}

export type AdvancedSearchExactlyType = {
    activeQ: boolean
    separator: string
}

export type AdvancedSearchOperatorType = AdvancedSearchOperationsEnum.AND | AdvancedSearchOperationsEnum.OR

export interface AdvancedSearchInterface {
    exactly: AdvancedSearchExactlyType
    operator: AdvancedSearchOperatorType
    enabledQ: boolean
    // convertLayoutQ: boolean
}

// TODO: добавить в верстку по необходимости параметр convertLayoutQ
// https://dev.smartplayer.org/api-page/events/81

export const DEFAULT_DIGITAL_SIGNAGES_ADVANCED_SEARCH_PARAMETERS: AdvancedSearchInterface = {
    exactly: {
        activeQ: false,
        separator: ',',
    },
    operator: AdvancedSearchOperationsEnum.OR,
    enabledQ: false,
    // convertLayoutQ: false,
}
