import React, { FunctionComponent } from 'react'
import { cn } from 'ethcss'
import { stepsStyles } from './Steps-styles'
import { IStepsComponent, IStepsElement } from './Steps-types'
import { isExist } from 'core/utils/coreUtil'

const StepsComponent: FunctionComponent<IStepsComponent> = ({
    className,
    contentClassName,
    active,
    children,
    mod = 'default',
}) => {
    const modifyChildren = (child: IStepsElement) => {
        if (isExist(child)) {
            const className = cn(child.props.className, contentClassName, stepsStyles.Steps__content, {
                [stepsStyles[`Steps__content_${mod}_activated`]]: active === child.props.id,
                [stepsStyles[`Steps__content_${mod}_disabled`]]: active !== child.props.id,
            })

            const props = {
                ...child.props,
                className,
            }

            return React.cloneElement(child, props)
        }
    }

    return (
        <div className={cn(className, stepsStyles.Steps)}>
            {children &&
                React.Children.count(children) > 0 &&
                children.map((child: IStepsElement) => {
                    let childComponent = child as IStepsElement
                    return modifyChildren(childComponent)
                })}
        </div>
    )
}

export const Steps = React.memo(StepsComponent)
