import { useState } from 'react'

export const useActionBar = () => {
    const [activeElements, setActiveElements] = useState<any>([])

    return {
        activeElements,
        setActiveElements,
    }
}

export const useUploadDelimeter = (defaultDelimeter: string = '') => {
    const [delimiter, setDelimiter] = useState<string>(defaultDelimeter)

    return {
        delimiter,
        setDelimiter,
    }
}
