import React from 'react'
import Content from 'blocks.app/content/content'
import Icon from 'blocks.simple/icon/icon'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import { routes } from 'features/routes'
import { cn } from 'ethcss'
import styles from './advertisingCampaigns_add__empty.jcss'
import { grid } from '../../../../theme'
import { Typography } from 'atoms/Typography'
import { changeLocation } from 'features/routes'

const AdvertisingCampaignsAddEmptyStyles = (p_: { onContinue: () => void }) => (
    <Content>
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <Typography className={styles.title} type="title">
                    {translate('noAdvBlock')}
                </Typography>
                <div className={styles.icon}>
                    <Icon type="no_display" size={50} />
                </div>
                <div className={styles.description}>{translate('needCreateAdUnit')}</div>
                <div className={cn(grid.row)}>
                    <Button
                        className={cn(grid.mr_md)}
                        rounded="full_md"
                        onClick={function () {
                            changeLocation(`/${routes.advertisingCampaigns.path}`)
                        }}
                    >
                        {translate('cancel')}
                    </Button>
                    <Button
                        mod="fill"
                        rounded="full_md"
                        onClick={function () {
                            p_.onContinue()
                        }}
                    >
                        {translate('continue')}
                    </Button>
                </div>
            </div>
        </div>
    </Content>
)

export default AdvertisingCampaignsAddEmptyStyles
