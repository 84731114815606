import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import { textSizes } from 'theme/media'
import { grid, IStyles, mediaCSS } from '../../../theme'

const dashboardUserStyles: IStyles = {
    card: {
        ...item.rel,
    },
    wrapper: {
        ...grid.row,
        ...grid.justify,
        marginTop: '40px',
    },
    cartTitle: {
        textTransform: 'uppercase',
    },
    title: {
        fontSize: textSizes.mini,
        textTransform: 'uppercase',
    },
    info: {
        fontSize: textSizes.normalPlus,
        wordBreak: 'break-all',
        marginTop: '2px',
    },
    item: {
        ...grid.mb_mdPlus,
    },
    footer: {
        ...item.abs,
        bottom: '6px',
        right: '6px',
        fontSize: textSizes.mini,
    },
}

addStyles(dashboardUserStyles)
export default dashboardUserStyles
