import React, { FunctionComponent, memo } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import { progressBarStyles } from './ProgressBar-styles'
import { IProgressBarComponent } from './ProgressBar-types'
import { cn } from 'ethcss'

const ProgressBarComponent: FunctionComponent<IProgressBarComponent> = ({ value, strokeWidth, className }) => {
    return (
        <div className={cn(progressBarStyles.ProgressBar, className)}>
            <CircularProgressbar value={value} strokeWidth={strokeWidth} />
        </div>
    )
}

export const ProgressBar = memo(ProgressBarComponent)
