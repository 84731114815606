import { TableCell } from 'atoms/Table'
import { CatalogWithPaginationContext } from 'organisms/CatalogWithPagination'
import { ICatalogWithPaginationReferenceBookItemProps } from 'organisms/CatalogWithPagination/CatalogWithPagination-types'
import React, { FC, Fragment, memo, useContext } from 'react'
import { ICatalogProductItem } from '../../InstancesCatalogProducts-types'
import { styles } from './ProductsReferenceBookItem-styles'
import translate from '../../../../../core/translate'
import { changeLocation, routes } from '../../../../../features/routes'

const getProductCategory = (productCategoryId: number | null) => {
    switch (productCategoryId) {
        case 1:
            return translate('wineProduct')
        case 2:
            return translate('menuProduct')
        default:
            return translate('unknown')
    }
}

const toReferenceList = (productCategoryId: number | null, productCode: string) => {
    switch (productCategoryId) {
        case 1:
            changeLocation({
                pathname: `/${routes.wineCatalog.path}`,
                search: `wine__query=${productCode}`,
            })
            break
        case 2:
            changeLocation({
                pathname: `/${routes.menuProductCatalog.path}`,
                search: `menuProduct__query=${productCode}`,
            })
            break
        default:
            return
    }
}

const getBarcodes = (barcodes: string[]) => {
    return (
        <div>
            {barcodes.map((barcode: string, index) => {
                return (
                    <div>
                        {barcode}
                        {index !== barcodes.length - 1 ? ',' : ''}
                    </div>
                )
            })}
        </div>
    )
}

const ProductsReferenceBookItemComponent: FC<ICatalogWithPaginationReferenceBookItemProps> = ({
    item,
    isActive,
    className,
}) => {
    const { productCode, oldPrice, currentPrice, barcodes, productCategoryId } = item as ICatalogProductItem

    return (
        <Fragment>
            <TableCell>
                <div
                    className={styles.ProductsReferenceBookItem__tableCell}
                    onClick={() => toReferenceList(productCategoryId, productCode)}
                >
                    {productCode}
                </div>
            </TableCell>
            <TableCell>
                <div
                    className={styles.ProductsReferenceBookItem__tableCell}
                    onClick={() => toReferenceList(productCategoryId, productCode)}
                >
                    {oldPrice}
                </div>
            </TableCell>
            <TableCell>
                <div
                    className={styles.ProductsReferenceBookItem__tableCell}
                    onClick={() => toReferenceList(productCategoryId, productCode)}
                >
                    {currentPrice}
                </div>
            </TableCell>
            <TableCell>
                <div
                    className={styles.ProductsReferenceBookItem__tableCell}
                    onClick={() => toReferenceList(productCategoryId, productCode)}
                >
                    {getBarcodes(barcodes)}
                </div>
            </TableCell>
            <TableCell>
                <div
                    className={styles.ProductsReferenceBookItem__tableCell}
                    onClick={() => toReferenceList(productCategoryId, productCode)}
                >
                    {getProductCategory(productCategoryId)}
                </div>
            </TableCell>
        </Fragment>
    )
}

export const ProductsReferenceBookItem = memo(ProductsReferenceBookItemComponent)
