import React, { useCallback, useEffect, useState } from 'react'
import { cn } from 'ethcss'
import styles from '../styles'
import translate from '../../../core/translate'
import EditText from '../../../molecules/EditText'
import { UsersListItem } from '../UsersListItem'
import { useDispatch } from 'react-redux'
import { switchAccount, userActions } from 'blocks.app/user/user.state'

const UsersList = (p_) => {
    const dispatch = useDispatch()
    const [query, setQuery] = useState('')
    const [accounts, setAccounts] = useState([])

    const getAccounts = useCallback(() => {
        const allAccounts = [p_.mainUser, ...p_.mainUser.multipleAccounts]
        return allAccounts.filter((account) => p_.user.id !== account.id)
    }, [p_.user.id, p_.mainUser])

    useEffect(() => {
        setAccounts(getAccounts())
    }, [p_.user.mainUser, p_.user.id, getAccounts])

    if (!accounts.length) {
        return <div className={cn(styles.usersListWrapper, styles.empty)}>{translate('noLinkedAccounts')}</div>
    }

    const filteredAccounts = accounts.filter((account) => {
        if (account.name) {
            return account.name.toLowerCase().startsWith(query.toLowerCase())
        }

        return account.email.toLowerCase().startsWith(query.toLowerCase())
    })

    const onClick = (account) => {
        dispatch(
            switchAccount({
                account,
                cb: p_.onClose,
            })
        )

        dispatch(userActions.closeUserPanel())
    }
    const handleChangeSearchInput = (query) => {
        setQuery(query)
    }

    return (
        <div className={styles.usersListWrapper}>
            <div className={styles.usersListSearch}>
                <EditText
                    backgroundColor="blueWhite"
                    placeholder={translate('search')}
                    value={query}
                    icon={{
                        type: 'search',
                    }}
                    onChange={handleChangeSearchInput}
                />
            </div>
            {!filteredAccounts.length && (
                <div className={cn(styles.usersListWrapper, styles.empty)}>{translate('noFoundLinkedAccounts')}</div>
            )}
            {filteredAccounts.map((account) => (
                <UsersListItem key={account.id} user={account} onClick={onClick} />
            ))}
        </div>
    )
}

export { UsersList }
