import React from 'react'
import { Icon } from 'molecules/Icon'

import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import helpersBroadcasts from 'core/helpers/_broadcasts'

import styles from './styles'
import { getParamsForMultipleMoveBroadcasts } from 'core/utils/broadcastUtil'

export default {
    getMethod: 'getBroadcastsAndFolders',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noBroadcasts'),
    emptyIcon: 'not_allow',
    moveItem: (broadcastId, parentId) => api.send('moveToBroadcastFolder', { broadcastId, parentId }),
    moveFolder: (broadcastFolderId, parentId) => api.send('moveToBroadcastFolder', { broadcastFolderId, parentId }),
    multipleMove: (broadcastId, broadcastFolderId, parentId) => {
        const params = getParamsForMultipleMoveBroadcasts(broadcastId, broadcastFolderId, parentId)
        return api.send('moveToBroadcastFolder', params)
    },
    getDragData: (p_) => {
        return JSON.stringify({ id: p_.item.id, instanceType: p_.item.instanceType })
    },
    settingsPrefixes: 'broadcast',
    isOnlySearch: false,
    getBackendModel: (item) => {
        if (!helpersBroadcasts.isDirectory(item)) {
            return api.send('getBroadcast', { id: item.id }, { hideLoader: true })
        }

        return api.send('getBroadcastFolder', { id: item.id }, { hideLoader: true })
    },
    isEqual: (item, prevItem) => helpersBroadcasts.isEqual(item, prevItem),
    onCreateListeners: [
        (cb, clearList) => api.addObserver('broadcastCreated', cb, clearList),
        (callback, clearList) =>
            api.addObserver(
                'broadcastFolderCreated',
                (dataItem) => callback({ ...dataItem, instanceType: 'broadcastFolder' }),
                clearList
            ),
    ],
    onUpdateListeners: [
        (cb, clearList) => api.addObserver('broadcastUpdated', cb, clearList),
        (callback, clearList) =>
            api.addObserver(
                'broadcastFolderUpdated',
                (dataItem) => callback({ ...dataItem, instanceType: 'broadcastFolder' }),
                clearList
            ),
    ],
    onDeleteListeners: [
        (callback, clearList) => api.addObserver('broadcastDeleted', ({ id }) => callback({ id }), clearList),
        (callback, clearList) =>
            api.addObserver(
                'broadcastFolderDeleted',
                ({ id }) => callback({ id, instanceType: 'broadcastFolder' }),
                clearList
            ),
    ],
    isFolder: (item) => helpersBroadcasts.isDirectory(item),
    fields: () => [{ id: 'title', name: translate('name'), expanded: true }],
    sortOptions: () => [
        { value: '-createdAt', label: translate('sortCreatedAt') },
        { value: 'title', label: translate('byNameAsc') },
    ],
    list: {
        title: (item, active, p_) => {
            const isDirectory = helpersBroadcasts.isDirectory(item)

            return (
                <div className={styles.broadcastsTemplateName}>
                    <Icon
                        className={styles.broadcastsTemplateIcon}
                        type={isDirectory ? 'folder' : 'broadcast'}
                        size={30}
                        color="grey"
                    />
                    <div>{item.title}</div>
                </div>
            )
        },
    },
}
