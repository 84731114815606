import { api } from 'core/api/ConnectionManager'
import { IBreadcrumbsItem } from 'core/models/Breadcrumbs'
import { useEffect, useState } from 'react'
import {
    IGetBreadcrumbsRequest,
    IGetBreadcrumbsResponse,
    IUseFetchBreadcrumbsHook,
    IUseFetchBreadcrumbsProps,
} from './Breadcrumbs-types'

export const useFetchBreadcrumbs = (props: IUseFetchBreadcrumbsProps): IUseFetchBreadcrumbsHook => {
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumbsItem[]>([])
    const { parentKey, parentValue, methodName, title } = props
    const initBreadcrumb = { name: title, id: undefined }

    useEffect(() => {
        if (!parentKey || !parentValue) {
            setBreadcrumbs([initBreadcrumb])
            return
        }

        api.send<IGetBreadcrumbsRequest, IGetBreadcrumbsResponse[]>(methodName, { [parentKey]: parentValue }).then(
            (breadcrumbsList) => {
                const breadcrumbs: IBreadcrumbsItem[] = breadcrumbsList
                    .reverse()
                    .map((breadcrumbItem) => ({ ...breadcrumbItem, name: breadcrumbItem.title || breadcrumbItem.name }))

                setBreadcrumbs([initBreadcrumb, ...breadcrumbs])
            }
        )
    }, [parentKey, parentValue])

    return {
        breadcrumbs,
    }
}
