import React, { FC, useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import styles from './TextEditor-styles'
import { ITextEditor } from './TextEditor-types'
import Modal from 'blocks.simple/modal/modal'
import { cn } from 'ethcss'
import translate from '../../core/translate'
import Button from 'blocks.simple/button/button'
import grid from 'blocks.simple/grid/grid.jcss'
import { EditorState, ContentState, convertToRaw } from 'draft-js'

export const TextEditor: FC<ITextEditor> = ({ placeholder, text, onChange, previewStyles, toolbar }) => {
    const [value, setValue] = useState<string>(text)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const blocksFromHtml = htmlToDraft(value)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState))

    const onOpen = () => {
        setIsOpen(true)
    }
    const onClose = () => {
        setIsOpen(false)
    }
    const onApply = () => {
        const textHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        setValue(textHTML)

        if (onChange) {
            onChange(textHTML)
        }
        onClose()
    }

    return (
        <>
            <div
                className={`${styles.preview} ${previewStyles ? previewStyles : ''}`}
                dangerouslySetInnerHTML={{ __html: value ? value : placeholder ? placeholder : '' }}
                onClick={onOpen}
            />
            <Modal open={isOpen} mod="white" onClose={onClose}>
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        <Editor
                            editorState={editorState}
                            toolbarClassName={`${styles.toolbarClassName}`}
                            wrapperClassName={`${styles.wrapperClassName}`}
                            editorClassName={`${styles.editorClassName}`}
                            onEditorStateChange={(e) => {
                                setEditorState(e)
                            }}
                            toolbar={toolbar}
                        />
                    </div>
                    <div className={styles.footer}>
                        <div className={cn(grid.row, grid.center)}>
                            <Button rounded="full_md" onClick={onClose}>
                                {translate('cancel')}
                            </Button>
                            <Button rounded="full_md" className={cn(grid)} mod="fill" onClick={onApply}>
                                {translate('apply')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
