import { addStyles } from 'ethcss'
import { grid, IStyles } from '../../theme'
import { colors } from 'theme/colors'

const settingsStyles: IStyles = {
    version: {
        ...grid.row,
        ...grid.center,
        ...grid.mt_mini,
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    td: {
        border: `1px solid ${colors.greyLight}`,
        padding: 10,
    },
    caption: {
        backgroundColor: colors.greyLight,
    },
    content: {
        ...grid.ml_md,
        width: '200px',
    },
}

addStyles(settingsStyles)
export default settingsStyles
