declare var window: any

export default {
    api: {
        root: window.spconfig.ip_address,
        room: 'frontend',
    },
    version: process.env.REACT_APP_VERSION,
    buildTime: window.spconfig.buildTime,
}
