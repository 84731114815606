import { addStyles } from 'ethcss'
import { defaultThemeStyles } from './AppMenu.theme'
export const actionStyles = {
    menuItem__hover() {
        return {
            [`&:hover .${this.submenuHover}`]: {
                opacity: 1,
                transition: '0.4s ease',
                pointerEvents: 'auto',
            },
        }
    },
    submenuHover: {
        position: 'absolute !important',
        left: 0,
        zIndex: 10000,
        background: `var(--var-module-app-component-menu-style-background, ${defaultThemeStyles.background})`,
        textTransform: 'uppercase',
        boxShadow: `var(--var-module-app-component-menu-style-shadow, ${defaultThemeStyles.shadow})`,
        boxSizing: 'content-box',
        borderRadius: '0 0 5px 5px',
        minWidth: '100%',
        maxHeight: 'calc(100vh - 63px)',
        overflowY: 'auto',
        pointerEvents: 'none',
        transition: '0.4s',
        opacity: 0,
    },
}

addStyles(actionStyles)
