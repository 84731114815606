import React from 'react'
import Content from 'blocks.app/content/content'
import CustomList from 'blocks.app/customList/customList'
import InstancesCatalogPitamixMethods from './instancesCatalog_pitamix.local.methods'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

class InstancesCatalogPitamix extends InstancesCatalogPitamixMethods {
    render() {
        const s_ = this.state

        return (
            <Content title={'pitamixCatalog'}>
                <CustomList
                    type={`pitamix`}
                    onSelect={(selectedItems) => this.setState({ selectedItems })}
                    selectedItems={s_.selectedItems}
                    isWebAppTitle
                    additionalData={{}}
                />
            </Content>
        )
    }
}

const exportInstancesCatalogPitamix = checkAvailableModule('instancesCatalog/pitamix') ? InstancesCatalogPitamix : null

export default exportInstancesCatalogPitamix
