import React, { useEffect, useState } from 'react'
import styles from './comPortsStyles.jcss'
import EditText from '../../../blocks.simple/editText/editText'
import translate from '../../../core/translate'
import Dropdown from '../../../blocks.simple/dropdown/dropdown'
import Button from '../../../blocks.simple/button/button'
import Icon from '../../../blocks.simple/icon/icon'

export const ComPortCard = ({ port, index, onChange, onDelete }: any) => {
    const dataTransferSpeedOptions = [
        { id: 1200, name: '1200 baud' },
        { id: 2400, name: '2400 baud' },
        { id: 4800, name: '4800 baud' },
        { id: 9600, name: '9600 baud' },
        { id: 19200, name: '19200 baud' },
        { id: 38400, name: '38400 baud' },
        { id: 57600, name: '57600 baud' },
        { id: 115200, name: '115200 baud' },
    ]
    const parityOptions = [
        { id: 0, name: '0', desc: ` - ${translate('noParityBitIsSend')}` },
        { id: 2, name: '2', desc: ` - ${translate('oneBitsInEach')}` },
        {
            id: 3,
            name: '3',
            desc: ` - ${translate('numBitsInEach')}`,
        },
        { id: 4, name: '4', desc: ` - ${translate('spaceParity')}` },
        {
            id: 5,
            name: '5',
            desc: ` - ${translate('markParity')}`,
        },
    ]
    const dataBitOptions = [
        {
            id: 5,
            name: '5',
            desc: ` - ${translate('numberOfData')}`,
        },
        { id: 6, name: '6', desc: ` - ${translate('numberOfDataEach')}` },
        {
            id: 7,
            name: '7',
            desc: ` - ${translate('numberOfDataIs7')}`,
        },
        {
            id: 8,
            name: '8',
            desc: ` - ${translate('numberOfDataIs8')}`,
        },
    ]
    const stopBitOptions = [
        { id: 1, name: '1', desc: ` - ${translate('stopOneBit')}` },
        { id: 2, name: '2', desc: ` - ${translate('stopTwoBits')}` },
        { id: 3, name: '3', desc: ` - ${translate('stopOneAndHalfBit')}` },
    ]
    const streamControlOptions = [
        { id: 0, name: '0', desc: ` - ${translate('noFollow')}` },
        { id: 1, name: '1', desc: ` - ${translate('hardwareFlow')}` },
        { id: 2, name: '2', desc: ` - ${translate('softwareFlow')}` },
    ]
    const actionOptions = [
        { id: 'PhilipsSICP', name: translate('philipsSICP') },
        { id: 'temperatureSensor', name: translate('temperatureSensor') },
    ]

    const getInitValue = (field: string, value: any) => {
        switch (field) {
            case 'BaudRate':
                return dataTransferSpeedOptions.find((option) => option.id === value)
            case 'Parity':
                return parityOptions.find((option) => option.id === value)
            case 'DataBits':
                return dataBitOptions.find((option) => option.id === value)
            case 'StopBits':
                return stopBitOptions.find((option) => option.id === value)
            case 'FlowControl':
                return streamControlOptions.find((option) => option.id === value)
            case 'Action':
                return actionOptions.find((option) => option.id === value)
        }
    }

    const [comPortsName, setComPortsName] = useState(port.PortName)
    const [selectedDataTransferSpeed, setSelectedDataTransferSpeed] = useState<any>(
        getInitValue('BaudRate', port.BaudRate)
    )
    const [selectedParity, setSelectedParity] = useState<any>(getInitValue('Parity', port.Parity))
    const [selectedDataBit, setSelectedDataBit] = useState<any>(getInitValue('DataBits', port.DataBits))
    const [selectedStopBit, setSelectedStopBit] = useState<any>(getInitValue('StopBits', port.StopBits))
    const [selectedStreamControl, setSelectedStreamControl] = useState<any>(
        getInitValue('FlowControl', port.FlowControl)
    )
    const [selectedAction, setSelectedAction] = useState<any>(getInitValue('Action', port.Action))

    useEffect(() => {
        onChange(index, {
            PortName: comPortsName,
            DataBits: selectedDataBit.id,
            BaudRate: selectedDataTransferSpeed.id,
            Parity: selectedParity.id,
            StopBits: selectedStopBit.id,
            FlowControl: selectedStreamControl.id,
            Action: selectedAction.id,
        })
    }, [
        comPortsName,
        selectedDataTransferSpeed,
        selectedParity,
        selectedDataBit,
        selectedStopBit,
        selectedStreamControl,
        selectedAction,
    ])

    return (
        <div className={styles.portCard}>
            <Button className={styles.deleteBtn} onClick={() => onDelete(index)}>
                <Icon type={'delete'} tooltip={{ title: translate('delete') }} />
            </Button>
            <EditText
                placeholder={translate('comPortName')}
                label={translate('comPortName')}
                value={comPortsName}
                onChange={setComPortsName}
            />
            <Dropdown
                mod={'default'}
                options={dataTransferSpeedOptions}
                valueField={'name'}
                label={translate('dataTransferSpeed')}
                value={selectedDataTransferSpeed.name}
                onChange={setSelectedDataTransferSpeed}
            />
            <Dropdown
                mod={'default'}
                options={parityOptions}
                valueField={'name'}
                label={translate('parity')}
                value={selectedParity.name}
                onChange={setSelectedParity}
            />
            <Dropdown
                mod={'default'}
                options={dataBitOptions}
                valueField={'name'}
                label={translate('dataBit')}
                value={selectedDataBit.name}
                onChange={setSelectedDataBit}
            />
            <Dropdown
                mod={'default'}
                options={stopBitOptions}
                valueField={'name'}
                label={translate('stopBit')}
                value={selectedStopBit.name}
                onChange={setSelectedStopBit}
            />
            <Dropdown
                mod={'default'}
                options={streamControlOptions}
                valueField={'name'}
                label={translate('streamControl')}
                value={selectedStreamControl.name}
                onChange={setSelectedStreamControl}
            />
            <Dropdown
                mod={'default'}
                options={actionOptions}
                valueField={'name'}
                label={translate('actionSelect')}
                value={selectedAction.name}
                onChange={setSelectedAction}
            />
        </div>
    )
}
