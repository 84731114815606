import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { IDisplayPlayAudioControllComponent, ControllButton } from './DisplayPlayAudioControll-types'
import { PlayControllButton } from '../../../../molecules/PlayControllButton/PlayControllButton'
import { DisplayPlayAudioEdit } from '../DisplaysPlayAudioEdit'
import helpers from 'core/helpers'
import styles from './DisplayPlayAudioControll-styles'
import { api } from '../../../../core/api/ConnectionManager'

export const DisplayPlayAudioControll: FunctionComponent<IDisplayPlayAudioControllComponent> = ({
    selectedInfo,
    closeModalContent,
}) => {
    const [editState, setEditState] = useState<ControllButton | null>(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const [buttonsList, setButtonsList] = useState<ControllButton[]>([])
    const [activeButton, setActiveButton] = useState('')
    const [audio, setAudio] = useState('')
    const [currentTime, setCurrentTime] = useState('00:00')
    const audioEl = useRef<any>(null)

    const getButtonsList = () => {
        api.send('getTriggerButton', { digitalSignageId: selectedInfo.id }).then((res: any) => setButtonsList(res))
    }

    const updateButtonsList = useCallback(
        (updatedButton: ControllButton) => {
            setButtonsList(() =>
                buttonsList.map((button: ControllButton) => {
                    if (updatedButton.triggerButtonId === button.triggerButtonId) {
                        return updatedButton
                    }
                    return button
                })
            )
        },
        [buttonsList]
    )

    const editButton = (e: React.MouseEvent, button: ControllButton) => {
        e.stopPropagation()
        setEditState(button)
    }

    const closeEdit = () => {
        setEditState(null)
    }

    const clientTriggerPlayback = (volume: number, sourceId: number) => {
        api.send('playContent', {
            digitalSignageId: selectedInfo.id,
            volume,
            sourceId,
        })
    }

    const playTrack = (id: number) => {
        setIsPlaying(false)
        setCurrentTime('00:00')

        setTimeout(() => {
            buttonsList.some((button: ControllButton) => {
                if (button.triggerButtonId === id && button.source && button.sourceId) {
                    clientTriggerPlayback(button.volume, button.sourceId)
                    setAudio(button.source.src)
                    setActiveButton(button.character)
                    setIsPlaying(true)
                }
            })
        })
    }
    const findActiveButton = (value: string) => activeButton === value
    const getTimeTrack = (value: number): string => {
        let minutes = Math.trunc(value / 60)
        let seconds = Math.trunc(value % 60)
        return helpers.leadingZeroToString(minutes) + ':' + helpers.leadingZeroToString(seconds)
    }

    useEffect(() => {
        getButtonsList()
    }, [])

    useEffect(() => {
        if (audioEl.current && isPlaying) {
            audioEl.current.pause()
            audioEl.current.play()
        } else {
            audioEl.current.pause()
            audioEl.current.currentTime = 0
        }
    }, [isPlaying])

    useEffect(() => {
        if (audioEl.current) {
            audioEl.current.addEventListener('ended', () => {
                setIsPlaying(false)
            })
        }
    }, [])

    useEffect(() => {
        if (isPlaying) {
            const interval = setInterval(() => {
                setCurrentTime(getTimeTrack(audioEl.current.currentTime))
            }, 1000)
            return () => {
                clearInterval(interval)
            }
        }
    }, [isPlaying])

    useEffect(() => {
        const captureKey = (e: any) => {
            if (editState) return

            buttonsList.some((button: ControllButton) => {
                if (button.keyboardEventCode === e.code) {
                    playTrack(button.triggerButtonId)
                }
            })
        }

        document.addEventListener('keydown', captureKey)

        return () => document.removeEventListener('keydown', captureKey)
    }, [buttonsList, editState])

    useEffect(() => {
        if (selectedInfo.status === 'offline') {
            closeModalContent()
        }
    }, [selectedInfo.status])

    useEffect(() => {
        const listenersId: any = []

        api.addObserver(
            'triggerButtonUpdated',
            (data: any) => {
                if (!data) return
                if (data.digitalSignageId === selectedInfo.id) {
                    updateButtonsList(data)
                }
            },
            listenersId
        )

        return () => listenersId.forEach((id: string) => api.removeObserver(id))
    }, [buttonsList])

    return (
        <div className={styles.wrapper}>
            <div className={styles.inner}>
                {buttonsList.map((button: ControllButton) => {
                    return (
                        <PlayControllButton
                            buttonClassName={styles.buttonStyles}
                            key={button.triggerButtonId}
                            title={button.name}
                            valueKey={button.character}
                            isPlaying={isPlaying && findActiveButton(button.character)}
                            timer={currentTime}
                            onClick={() => {
                                playTrack(button.triggerButtonId)
                            }}
                            editButton={(e: React.MouseEvent) => {
                                editButton(e, button)
                            }}
                        />
                    )
                })}
            </div>
            {editState && <DisplayPlayAudioEdit editProps={editState} onClose={closeEdit} />}
            <audio ref={audioEl} src={audio} />
        </div>
    )
}
