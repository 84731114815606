import { useState, useEffect } from 'react'
import { useOnUnmount } from 'core/hooks'

import { ISearchInputHooks } from './SearchInput-types'

let searchInputTimeout: number

export const useSearchInput = ({ value, onChange, immediateChange }: ISearchInputHooks) => {
    const [searchValue, setSearchValue] = useState<string>(value)

    useEffect(() => {
        setSearchValue(value)
    }, [value])

    useOnUnmount(() => {
        clearTimeout(searchInputTimeout)
    })

    const handleOnChange = (newSearchValue: string) => {
        setSearchValue(newSearchValue)

        clearTimeout(searchInputTimeout)
        searchInputTimeout = window.setTimeout(
            () => {
                onChange(newSearchValue)
            },
            immediateChange ? 0 : 600
        )
    }

    const handleOnClearInput = () => {
        const newSearchValue = ''

        setSearchValue(newSearchValue)
        onChange(newSearchValue)
    }

    return { searchValue, handleOnChange, handleOnClearInput }
}
