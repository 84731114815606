import React from 'react'

import styles from './preview__office.jcss'

// TODO: Обработку ошибки (onError) и ее вывод

const PreviewOffice = ({ src }: { src: string }) => {
    return (
        <div className={styles.wrapper}>
            <iframe className={styles.previewOfficeViewer} src={`pdfviewer/viewer.html?path=${src}`} />
            {/*<iframe*/}
            {/*    className={styles.previewOfficeViewer}*/}
            {/*    title={`${window.spconfig.brandName} PDF preview`}*/}
            {/*    src={src}*/}
            {/*    // type="application/pdf"*/}
            {/*/>*/}
        </div>
    )
}

export default PreviewOffice
