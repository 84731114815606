import React from 'react'
import ItemDisplays from '../../__item/_displays/catalog__item_displays'
import Icon from 'blocks.simple/icon/icon'

import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { api } from 'core/api/ConnectionManager'
import helpersDigitalSignages from 'core/helpers/_digitalSignages'
import translate from 'core/translate'
import styles from './catalog__template__draftDevices.jcss'
import { getThemeStyleValue } from 'theme/colors'

const noBroadcastCover = helpersDigitalSignages.getNoBroadcastIconSrc()

export default {
    getMethod: 'getDraftDisplays',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noDevices'),
    emptyIcon: 'not_allow',
    settingsPrefixes: 'draftDevices',
    tile: ItemDisplays,
    viewOptions: () => [
        {
            id: 'tile',
            name: translate('tile'),
            icon: 'tile',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
        {
            id: 'list',
            name: translate('list'),
            icon: 'list',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
    ],
    sortOptions: () => [
        { id: '-createdAt', name: translate('sortCreatedAt') },
        { id: 'name', name: translate('byNameAsc') },
        { id: '-name', name: translate('byNameDesc') },
        { id: 'status', name: translate('sortStatusOnline') },
        { id: '-status', name: translate('sortStatusOffline') },
    ],
    getItemModel: (dataItem, getSelected) => {
        const item = { ...dataItem }

        if (!item.cover) {
            item.cover = []
        }
        item.cover = [...item.cover, ...[noBroadcastCover, noBroadcastCover, noBroadcastCover, noBroadcastCover]]
        item.cover = item.cover.slice(0, 4)

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }
        if (!item.type) {
            item.type = 'digitalSignage'
        }

        return item
    },
    getBackendModel: (item) => {
        return api.send('getDisplay', { id: item.id }, { hideLoader: true })
    },
    isEqual: helpersDigitalSignages.isEqual,
    onCreateListeners: [
        (cb, clearList) => api.addObserver('groupCreated', cb, clearList),
        (cb, clearList) => api.addObserver('displayCreated', cb, clearList),
    ],
    onUpdateListeners: [
        (cb, clearList) => api.addObserver('groupUpdated', cb, clearList),
        (cb, clearList) => api.addObserver('displayUpdated', cb, clearList),
    ],
    onDeleteListeners: [
        (callback) => api.addObserver('groupDeleted', ({ id }) => callback({ id, type: 'group' })),
        (callback) => api.addObserver('displayDeleted', ({ id }) => callback({ id, type: 'digitalSignage' })),
    ],
    fields: () => [
        { id: 'name', name: translate('name') },
        { id: 'status', name: translate('status') },
    ],
    init(callback) {
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    moveOnUpdate(item) {
        return item.groupId
    },
    list: {
        status: ({ status, type }) => {
            const color = { offline: 'offline', error: 'error', online: 'online' }

            return (
                <div className={styles.status}>
                    <Icon type={type === 'group' ? 'files' : 'display'} size={20} color={color[status]} />
                </div>
            )
        },
    },
    disableUpdateUserSettings: true,
}
