import React, { useEffect, useState } from 'react'
import Content from 'blocks.app/content/content'
import Catalog from 'blocks.app/catalog/catalog'
import Sidebar from 'blocks.app/sidebar/sidebar'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import Icon from 'blocks.simple/icon/icon'
import Button from 'blocks.simple/button/button'
import { cn } from 'ethcss'
import styles from './report_tasks.jcss'
import translate from 'core/translate'
import { grid, item } from '../../../theme'
import { api } from 'core/api/ConnectionManager'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

type SelectedInfo = {
    url: string
    id: number
}

const ReportTasks = () => {
    const [selectedInfo, setSelectedInfo] = useState<SelectedInfo | null>(null)

    return (
        <Content
            title={translate('reportTasks')}
            toolbar={
                <Sidebar
                    selectedSide={'right'}
                    customContent={{
                        right: selectedInfo ? (
                            <div className={cn(grid.col, grid.justify, grid.space)}>
                                <div>
                                    <Rectangle>
                                        <div className={cn(styles.iconWrapper)}>
                                            <Icon
                                                containerClass={cn(grid.space, grid.full, grid.colCenter)}
                                                type={'table'}
                                                size={'70%'}
                                                color={'white'}
                                            />
                                        </div>
                                    </Rectangle>
                                    <div className={cn(item.shadowButton)}>
                                        <a
                                            className={cn(styles.menuItem, {
                                                [item.disabled]: !selectedInfo.url,
                                            })}
                                            rel="noopener noreferrer"
                                            href={selectedInfo.url}
                                            download={true}
                                        >
                                            {translate('download')}
                                        </a>
                                        <div
                                            className={cn(styles.menuItem)}
                                            onClick={() => api.send('deleteReportTask', { id: selectedInfo.id })}
                                        >
                                            {translate('delete')}
                                        </div>
                                    </div>
                                </div>
                                <div className={cn(grid.p_md, grid.rowCenter)}>
                                    <Button
                                        onClick={() => setSelectedInfo(null)}
                                        indentSize={'mini'}
                                        rounded={'full_normal'}
                                    >
                                        {translate('close')}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div />
                        ),
                    }}
                    isEmpty={{
                        right: !selectedInfo,
                    }}
                    emptyText={{
                        right: {
                            title: translate('noSelectedReports'),
                            description: translate('noSelectedReportsDescription'),
                        },
                    }}
                />
            }
        >
            <Catalog
                type={'reportTasks'}
                selectedInfo={selectedInfo}
                onSelectInfo={(selectedInfo: SelectedInfo) => setSelectedInfo(selectedInfo)}
                hideHeader={true}
            />
        </Content>
    )
}

const exportReportTasks = checkAvailableModule('reports/tasks') ? ReportTasks : null

export default exportReportTasks
