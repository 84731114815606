import React, { Fragment, FunctionComponent, memo, useEffect } from 'react'
import EditText, { EditTextPassword } from 'blocks.simple/editText/editText'
import Button from 'blocks.simple/button/button'
import { cn } from 'ethcss'
import translate from 'core/translate'
import { grid, text as textStyles } from 'theme'
import authorizationStyles from '../authorization.jcss'
import { useRegistration } from 'core/hooks'
import styles from '../authorization.jcss'
import Checkbox from '../../../blocks.simple/checkbox/checkbox'
// @ts-ignore
import ReCAPTCHA from 'react-google-recaptcha'
import Dropdown from '../../../blocks.simple/dropdown/dropdown'
import helpers from 'core/helpers'
import { changeLocation } from '../../../features/routes'

const RegistrationComponent: FunctionComponent<any> = ({ onLoading }) => {
    const {
        email,
        password,
        repeatPassword,
        phone,
        needInstruction,
        companyName,
        capcha,
        country,
        onChangeEmail,
        onChangePassword,
        onChangeRepeatPassword,
        onChangePhone,
        onChangeNeedInstruction,
        onChangePolicy,
        onChangeSoftwareUse,
        onChangeCompanyName,
        onChangeCapcha,
        onChangeCountry,
        registration,
        policy,
        softwarePolicy,
        capchaRef,
    } = useRegistration({ onLoading })

    const buttonIsDisable = () => {
        return !email || !password || !repeatPassword || !policy || !capcha || !softwarePolicy
    }

    useEffect(() => {
        if (!window.spconfig.registration.isEnabled) {
            changeLocation('/login')
        }
    }, [])

    return (
        <Fragment>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    registration()
                }}
                className={authorizationStyles.formWrapper}
            >
                <div className={authorizationStyles.item}>
                    <EditText
                        id={'email_registration'}
                        className={cn(textStyles.center)}
                        placeholder={translate('email')}
                        type="text"
                        mod="withBottomLine"
                        autoComplete="on"
                        rounded="none"
                        value={email}
                        onChange={onChangeEmail}
                    />
                </div>
                <div className={authorizationStyles.item}>
                    <EditTextPassword
                        id={'password_registration'}
                        className={cn(textStyles.center)}
                        placeholder={translate('password')}
                        mod="withBottomLine"
                        rounded="none"
                        value={password}
                        autoComplete="current-password"
                        onChange={onChangePassword}
                    />
                </div>
                <div className={authorizationStyles.item}>
                    <EditTextPassword
                        id={'password_registration_repeat'}
                        className={cn(textStyles.center)}
                        placeholder={translate('repeatPassword')}
                        mod="withBottomLine"
                        rounded="none"
                        value={repeatPassword}
                        autoComplete="current-password"
                        onChange={onChangeRepeatPassword}
                    />
                </div>
                <div className={authorizationStyles.item}>
                    <EditText
                        id={'phone_registration'}
                        className={cn(textStyles.center)}
                        placeholder={translate('phoneNumber')}
                        type="text"
                        mod="withBottomLine"
                        autoComplete="on"
                        rounded="none"
                        value={phone}
                        onChange={onChangePhone}
                    />
                </div>
                <div className={authorizationStyles.item}>
                    <EditText
                        id={'companyName_registration'}
                        className={cn(textStyles.center)}
                        placeholder={translate('companyName')}
                        type="text"
                        mod="withBottomLine"
                        autoComplete="on"
                        rounded="none"
                        value={companyName}
                        onChange={onChangeCompanyName}
                    />
                </div>
                <div className={authorizationStyles.item}>
                    <Dropdown
                        mod="withBottomLine"
                        className={styles.domainsInput}
                        options={helpers.getSelectCountryList()}
                        searchable={true}
                        onChange={onChangeCountry}
                        value={country}
                        iconClassName={styles.dropDownIcon}
                        searchableInputClassName={styles.searchableInput}
                    />
                </div>
                <div className={authorizationStyles.item}>
                    <Checkbox
                        labelClassName={styles.checkboxLabel}
                        label={translate('iNeedHelpByPlatform')}
                        checked={needInstruction}
                        onClick={(check: boolean, e: any) => {
                            e.stopPropagation()
                            onChangeNeedInstruction(check)
                        }}
                    />
                    <div className={styles.helpInfo}>{translate('helpInfo')}</div>
                </div>
                <div className={authorizationStyles.item}>
                    <Checkbox
                        labelClassName={styles.checkboxLabel}
                        label={
                            <div>
                                {translate('iAgreeTo')}
                                <span
                                    className={styles.personalData}
                                    onClick={(e: any) => {
                                        e.stopPropagation()
                                    }}
                                >
                                    {translate('personalData')}
                                </span>
                            </div>
                        }
                        checked={policy}
                        onClick={(check: boolean, e: any) => {
                            e.stopPropagation()
                            onChangePolicy(check)
                        }}
                    />
                </div>
                <div className={authorizationStyles.item}>
                    <Checkbox
                        labelClassName={styles.checkboxLabel}
                        label={
                            <div>
                                {translate('softwareUse')}
                                <a
                                    href={window.spconfig.registration.terms_of_use}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.personalData}
                                    onClick={(e: any) => {
                                        e.stopPropagation()
                                    }}
                                >
                                    {translate('termsOfUse')},
                                </a>
                                <a
                                    href={window.spconfig.registration.privacy_policy}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.personalData}
                                    onClick={(e: any) => {
                                        e.stopPropagation()
                                    }}
                                >
                                    {translate('privacyPolicy')},
                                </a>
                                <a
                                    href={window.spconfig.registration.user_agreement}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.personalData}
                                    onClick={(e: any) => {
                                        e.stopPropagation()
                                    }}
                                >
                                    {translate('userAgreement')}
                                </a>
                            </div>
                        }
                        checked={softwarePolicy}
                        onClick={(check: boolean, e: any) => {
                            e.stopPropagation()
                            onChangeSoftwareUse(check)
                        }}
                    />
                </div>
                {window.spconfig.registration.capchaKey && (
                    <ReCAPTCHA
                        sitekey={window.spconfig.registration.capchaKey}
                        onChange={onChangeCapcha}
                        ref={capchaRef}
                    />
                )}
                <div className={cn(grid.row, grid.center, grid.mt_mdPlus)}>
                    <Button
                        disabled={buttonIsDisable()}
                        type="submit"
                        mod="secondary"
                        className={cn(grid.row, grid.center, grid.w70)}
                    >
                        {translate('registerUser')}
                    </Button>
                </div>
                <>
                    <div className={styles.orSeparator}>{translate('or')}</div>
                    <div className={cn(grid.row, grid.center)}>
                        <Button
                            type="button"
                            mod="secondary"
                            className={cn(grid.row, grid.center, grid.w70)}
                            onClick={() => changeLocation('login')}
                        >
                            {translate('login')}
                        </Button>
                    </div>
                </>
            </form>
        </Fragment>
    )
}

export const Registration = memo(RegistrationComponent)
