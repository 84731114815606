import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { mediaCSS, textSizes } from 'theme/media'
import { IStyles } from '../../../theme'

const deviceSettingsBrightnessStyles: IStyles = {
    wrapper: {
        ...grid.mt_mdPlus,
        ...grid.w100,
    },
    title: {
        fontSize: textSizes.normal,
    },
    timersWrapper: {
        position: 'relative',
        ...grid.p_mini,
        ...grid.col,
        ...grid.m_mini,
        ...grid.w20,
        [mediaCSS.tabMini]: {
            ...grid.w45,
        },
        [mediaCSS.mob]: {
            ...grid.w100,
        },
        ...item.rounded_full_mini,
        border: `1px solid var(--var-module-app-component-app-style-borderContrast, ${defaultThemeStyles.borderContrast})`,
    },
    content: {
        ...grid.row,
        ...grid.mt_md,
    },
    removeIcon: {
        position: 'absolute',
        top: 10,
        right: 10,
    },
}

addStyles(deviceSettingsBrightnessStyles)
export default deviceSettingsBrightnessStyles
