import { isExist, isNotEmptyArray } from 'core/utils/coreUtil'
import { MAP_CENTER, MAP_ZOOM } from 'features/map/constants'
import { SearchableMapProps, TMapPoint } from 'features/map/models/map'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { styles } from './SearchableGoogleMap-styles'
import GoogleMapReact from 'google-map-react'
import geocoder from 'google-geocoder'
import { SearchBar } from 'molecules/SearchBar'

declare var window: any

let mapListener: object | null = null

const MAP_SEARCHBAR_ID = 'map_search_bar'

const mapOptions = {
    minZoom: 3,
    minZoomOverride: true,
    fullscreenControl: false,
    clickableIcons: false,
}

export const SearchableGoogleMap: FC<SearchableMapProps> = ({
    center,
    location,
    mapApi,
    zoom = MAP_ZOOM,
    onChangeLocation,
    onChangeAddress,
}) => {
    const { name } = location
    const [marker, setMarker] = useState<any>(null)

    let markerRef = useRef(marker)
    markerRef.current = marker

    useEffect(() => {
        return () => {
            if (!window.google) return

            const container = document.querySelector('.pac-container')

            if (container) {
                container.remove()
            }

            if (!mapListener) return

            window.google.maps.event.removeListener(mapListener)
            mapListener = null
        }
    }, [])

    const apiKey = mapApi.getApiKey()

    const getMapSettings = () => {
        return {
            key: apiKey,
            libraries: ['places'].join(','),
            language: 'ru',
        }
    }

    const getMapCenter = () => {
        if (!isExist(center))
            return {
                lat: MAP_CENTER.lat,
                lng: MAP_CENTER.lng,
            }

        return center && center.lat && center.lng
            ? { lat: center.lat, lng: center.lng }
            : { lat: MAP_CENTER.lat, lng: MAP_CENTER.lng }
    }

    const isExistMarkerRef = () => markerRef && markerRef.current

    const deleteMarker = () => {
        if (!isExistMarkerRef()) return

        markerRef.current.setMap(null)
        setMarker(null)
    }

    const createMarker = (maps: any, position: TMapPoint) => {
        let marker = new maps.Marker({
            draggable: true,
            animation: maps.Animation.DROP,
            position,
        })

        marker.addListener('dragend', function () {
            let dragMarkerPos = marker.getPosition()

            mapApi
                .reverseGeocodeLocation(dragMarkerPos.lat(), dragMarkerPos.lng())
                .then((res) => {
                    if (!isExist(res) || !isNotEmptyArray(res)) return

                    const closestPosition = res[0]
                    onChangeLocation(closestPosition)
                })
                .catch(() => {})
        })

        return marker
    }

    const onSearchLocation = (place: geocoder.GoogleGeocoderResult) => {
        const { formatted_address, geometry } = place

        onChangeLocation({
            name: formatted_address,
            lat: geometry.location.lat,
            lng: geometry.location.lng,
            description: '',
        })
    }

    const getDistanceToMouse = ({ x, y }: GoogleMapReact.Point, { x: mouseX, y: mouseY }: GoogleMapReact.Point) => {
        const newX = x + 30
        const newY = y + 30

        return Math.sqrt((newX - mouseX) * (newX - mouseX) + (newY - mouseY) * (newY - mouseY))
    }

    const onLoaded = ({ map, maps }: { map: any; maps: any }) => {
        const targetNode = document.querySelector(`#${MAP_SEARCHBAR_ID}`)

        let searchBox = new maps.places.SearchBox(targetNode)

        mapListener = maps.event.addListener(searchBox, 'places_changed', function () {
            searchBox.set('map', null)
            deleteMarker()

            const places = searchBox.getPlaces()
            const bounds = new maps.LatLngBounds()
            const newPlace = places[0]

            onSearchLocation(newPlace)

            let marker = createMarker(maps, newPlace.geometry.location)

            marker.bindTo('map', searchBox, 'map')
            setMarker(marker)

            bounds.extend(newPlace.geometry.location)
            map.fitBounds(bounds)

            searchBox.set('map', map)
            map.setZoom(Math.min(map.getZoom(), zoom))
        })

        let marker = createMarker(maps, getMapCenter())

        marker.setMap(map)
        setMarker(marker)
    }

    const renderActionBar = () => {
        return (
            <div className={styles.searchableGoogleMap__actionBar}>
                <div className={styles.searchableGoogleMap__searchBar}>
                    <SearchBar id={MAP_SEARCHBAR_ID} value={name} onSearch={onChangeAddress} />
                </div>
            </div>
        )
    }

    return (
        <div className={styles.searchableGoogleMap}>
            {renderActionBar()}

            <GoogleMapReact
                onGoogleApiLoaded={onLoaded}
                yesIWantToUseGoogleMapApiInternals={true}
                center={getMapCenter()}
                zoom={zoom}
                options={mapOptions}
                bootstrapURLKeys={getMapSettings()}
                hoverDistance={40}
                distanceToMouse={getDistanceToMouse}
            />
        </div>
    )
}
