import React, { useState, useEffect } from 'react'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import Icon from 'blocks.simple/icon/icon'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import DndDraggable from 'blocks.app/dnd/_draggable/dnd_draggable'
import DotTooltip from 'blocks.simple/dotTooltip/dotTooltip'
import helpersFiles from 'core/helpers/_files'
import helpersLegacy from 'core/helpers'
import helpers from 'core/helpers'
import { routes } from 'features/routes'
import translate from 'core/translate'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import styles from './catalog__item_files.jcss'
import { TEXT_DISPLAY_AS } from 'core/models/Files'
import { colors } from 'theme'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { getURLSearchParamsString } from 'features/routes/utils'
import { changeLocation } from 'features/routes'
import { useDispatch } from 'react-redux'
import { activateEditLink, activateEditTable, activateEditText, rotateVideo } from 'pages/files/files.state'
import { ProgressBar } from 'atoms/ProgressBar'

type Item = {
    name: string
    id: number
    __view: any
    fileType?: string
    dynamicDurationQ?: boolean
    type?: string
    displayAs?: string
    style?: object
    videoValidationErrors?: boolean
    progress?: number
}
interface IItemProps {
    item: Item
    landscape?: string
    multipleSelect?: boolean
    isActive?: boolean
    onSelect?: (data: { selected: boolean; item: Item }) => void
}

const textFileType = ['text', 'xml', 'csv', 'rss']
const getLandscapeStatusIcon = (landscape: string) => {
    switch (landscape) {
        case 'in_progress':
            return {
                color: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
                type: 'progressClock',
            }
        default:
            return {
                color: 'white',
                type: 'play_video',
            }
    }
}

export const TextItem = ({ style, name, displayAs }: { style: object; name: string; displayAs: string }) => {
    const isDefaultDisplay = displayAs === TEXT_DISPLAY_AS.DEFAULT

    if (!isDefaultDisplay) {
        return <div className={styles.backLayer} />
    }

    return (
        <div className={styles.back}>
            <div
                className={styles.text}
                style={
                    style &&
                    helpersFiles.convertStyles(style, {
                        withoutStyles: ['fontSize'],
                    })
                }
            >
                {helpers.strip_html_tags(name)}
            </div>
            <div className={styles.backLayer} />
        </div>
    )
}

export const Item = (p_: IItemProps) => {
    const dispatch = useDispatch()
    const [image, setImage] = useState<string | undefined>(undefined)
    const imageSource = helpersFiles.getSrc(p_.item)
    const isTextFileType = p_.item.fileType && textFileType.includes(p_.item.fileType)
    const isDynamicDuration = p_.item.fileType === 'web_app' && p_.item.dynamicDurationQ

    useEffect(() => {
        if (!imageSource) {
            return
        }

        helpers.preloadImage(
            imageSource,
            (src: string) => {
                setImage(src)
                return {}
            },
            {
                type: 'files',
            }
        )
    }, [imageSource])

    return (
        <>
            {p_.item && (
                <Rectangle
                    src={p_.item.fileType === 'video' && p_.landscape === 'in_progress' ? '' : image}
                    contain={helpersFiles.isIcon(p_.item)}
                    tooltip={{ html: p_.item.name, disabled: p_.item.type !== 'link' }}
                    containerClassName={'rectangle'}
                >
                    <div className={styles.content}>
                        {!isTextFileType && <div className={styles.backLayer}></div>}
                        {isTextFileType && (
                            <TextItem
                                name={p_.item.name}
                                style={p_.item.style ? p_.item.style : {}}
                                displayAs={p_.item.displayAs ? p_.item.displayAs : ''}
                            />
                        )}

                        {isDynamicDuration && (
                            <div className={styles.back}>
                                <div className={styles.itemIconWrapper}>
                                    <Icon
                                        containerClass={styles.itemIcon}
                                        size={'100%'}
                                        type={'duration_dynamic'}
                                        color={colors.darkCloud}
                                    />
                                </div>
                            </div>
                        )}

                        {p_.item.fileType === 'video' && (
                            <div className={styles.back}>
                                <div className={p_.landscape === 'in_progress' ? styles.text : styles.video}>
                                    <Icon {...getLandscapeStatusIcon(p_.landscape ? p_.landscape : '')} size="50" />
                                </div>
                            </div>
                        )}

                        {p_.item.type === 'input' && (
                            <div className={styles.back}>
                                <div className={grid.w70}>
                                    <Icon size={'100%'} type={helpersFiles.getFileIconName(p_.item)} />
                                </div>
                            </div>
                        )}
                        {p_.item.fileType === 'video' && p_.item.videoValidationErrors && (
                            <div className={styles.errorIcon}>
                                <Icon
                                    color="error"
                                    type="attention"
                                    size={18}
                                    tooltip={{
                                        html: translate('fileMayContainErrors'),
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        changeLocation({
                                            pathname: `/${routes.videoEditor.path}`,
                                            search: getURLSearchParamsString({
                                                sourceId: p_.item.id,
                                            }),
                                        })
                                    }}
                                />
                            </div>
                        )}
                        {p_.multipleSelect && p_.item.type !== 'file' && (
                            <div className={styles.inputWrapper} onClick={(e) => e.stopPropagation()}>
                                <Checkbox
                                    mod={'circleInv'}
                                    className={cn(styles.input)}
                                    onClick={(selected: boolean) => {
                                        if (p_.onSelect) {
                                            p_.onSelect({ selected, item: p_.item })
                                        }
                                    }}
                                    checked={p_.item && p_.item.__view && p_.item.__view.selected}
                                />
                            </div>
                        )}
                        {p_.item.type === 'file' && (
                            <div className={styles.downloadItem} onClick={(e) => e.stopPropagation()}>
                                <ProgressBar
                                    value={p_.item.progress ? p_.item.progress : 0}
                                    strokeWidth={12}
                                    className={styles.progressBarStyle}
                                />
                                <div className={styles.downloadPercent}>{p_.item.progress}%</div>
                            </div>
                        )}
                        {p_.item.fileType === 'video' && p_.landscape === 'error' && (
                            <div className={styles.rotateWrapper} onClick={(e) => e.stopPropagation()}>
                                <Icon
                                    type={'rotate'}
                                    onClick={function () {
                                        dispatch(rotateVideo({ sourceId: p_.item.id }))
                                    }}
                                    size={25}
                                    color={p_.isActive ? 'white' : 'darkBlue'}
                                />
                            </div>
                        )}
                        {((p_.item.fileType && textFileType.includes(p_.item.fileType)) ||
                            p_.item.fileType === 'rss') && (
                            <div className={styles.icon}>
                                <Icon
                                    type={'pencil'}
                                    onClick={function () {
                                        dispatch(activateEditText(p_.item.id))
                                    }}
                                    size={13}
                                    color={'white'}
                                />
                            </div>
                        )}
                        {p_.item.type === 'table' && (
                            <div className={styles.icon}>
                                <Icon
                                    type={'pencil'}
                                    onClick={function () {
                                        dispatch(activateEditTable(p_.item.id))
                                    }}
                                    size={13}
                                    color={'white'}
                                />
                            </div>
                        )}
                        {p_.item.type === 'link' && (
                            <div className={styles.icon}>
                                <Icon
                                    type={'pencil'}
                                    onClick={function () {
                                        dispatch(activateEditLink(p_.item.id))
                                    }}
                                    size={13}
                                    color={'white'}
                                />
                            </div>
                        )}
                    </div>
                </Rectangle>
            )}
        </>
    )
}

const FilesTile = (p_: any) => {
    const [dragData, setDragData] = useState(p_.getDragData(p_))
    const isActive = p_.active || (p_.item.__view && p_.item.__view.selected)

    useEffect(() => {
        setDragData(p_.getDragData(p_))
    }, [p_])

    return (
        <div className={cn(styles.wrapper, { [styles.wrapperActive]: isActive })}>
            <div
                className={styles.itemWrapper}
                onClick={function (e) {
                    if (p_.item.type === 'file') return

                    if (helpersLegacy.isDblTouchTap(e)) {
                        if (!p_.item.isDirectory) {
                            p_.onDoubleClickObject(p_.item)

                            e.preventDefault()
                            return
                        }

                        p_.onSelectFolder(p_.item)
                        return
                    }

                    p_.onSelectInfo(e)
                }}
                onContextMenu={(e) => {
                    if (helpersLegacy.isMobileDevice()) {
                        e.preventDefault()
                    }
                }}
            >
                <DndDraggable
                    data={dragData}
                    dragEnabled={p_.drag}
                    isMobileDragEnabled={p_.isMobileDragEnabled}
                    className={cn(styles.item, p_.itemStyles ? p_.itemStyles : '')}
                    onDragStart={p_.onDragStart}
                    onDragEnd={p_.onDragEnd}
                >
                    <Item {...p_} landscape={p_.item.dataLandscapeStatus} isActive={isActive} />
                </DndDraggable>
                {!p_.hideTitle && (
                    <DotTooltip
                        containerClass={styles.tooltip}
                        tooltip={{
                            html: textFileType.includes(p_.item.fileType) ? null : p_.item.name,
                            disabled: false,
                        }}
                    >
                        <div className={cn(item.ellipsis, styles.itemTextColor)}>
                            {textFileType.includes(p_.item.fileType) ? null : p_.item.name}
                        </div>
                    </DotTooltip>
                )}
            </div>
        </div>
    )
}

FilesTile.defaultProps = {
    drag: false,
    hideTitle: false,
    getDragData: () => {},
    onSelectInfo: () => {},
}

export default FilesTile
