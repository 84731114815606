import { addStyles } from 'ethcss'
import { grid, IStyles, item, textSizes } from 'theme'
import { defaultThemeStyles } from 'blocks.app/sidebar/Sidebar-theme'

const buttonsHeight = 63
export const styles: IStyles = {
    menuItem: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.center,
        ...grid.p_mini,
        position: 'relative',
        color: `var(--var-module-sidebar-component-sidebar-style-menuTextHover, ${defaultThemeStyles.menuTextHover})`,
        ...item.transitionErase,
        cursor: 'pointer',
        '&:hover': {
            color: `var(--var-module-sidebar-component-sidebar-style-menuText, ${defaultThemeStyles.menuText})`,
        },
    },
    ScoreboardsToolbarContent: {
        ...grid.w100,
        ...grid.col,
        ...grid.space,
        padding: '0 10px',
    },
    viewInfo: {
        borderRadius: '5px',
        overflow: 'hidden',
    },
    viewInfoNoHiddenOverflow: {
        borderRadius: '5px',
    },
    ScoreboardsToolbarContent__actionsMenu: {
        padding: 12,
        textAlign: 'center',
        marginTop: '12px',

        '& li': {
            fontSize: textSizes.md,
            lineHeight: 1.3,
            cursor: 'pointer',
        },

        '& div': {
            fontSize: '14px !important',
        },
    },
    toggleButton: {
        ...grid.w50,
        ...grid.rowCenter,
        ...item.pointer,
        ...item.white,
        height: buttonsHeight,
    },
    buttonUploads: {
        ...item.grey,
    },
    content: {
        ...grid.w100,
        ...grid.col,
        minHeight: `calc(100% - ${buttonsHeight}px)`,
    },
    empty: {
        ...grid.space,
        ...grid.col,
        ...grid.center,
        ...grid.normalCenter,
        ...grid.p_micro,
        textAlign: 'center',
        '& img': { ...grid.w100 },
    },
    description: {
        ...grid.mt_mdPlus,
        ...grid.mb_md,
        ...grid.w75,
        '&>p': {
            lineHeight: '22px',
        },
    },
    itemDescription: {
        ...grid.p_md,
        ...grid.pt_none,
        textAlign: 'center',
    },
    name: {
        ...grid.p_md,
        textAlign: 'center',
    },
    offline: {
        ...item.offline,
    },
    online: {
        ...item.online,
    },
    progress: {
        ...item.grey,
    },
    closeBtn: {
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
    },
}

addStyles(styles)
