import React from 'react'
import enhanceWithClickOutside from 'react-click-outside'
import { cn } from 'ethcss'
import { styles } from './Dropdown-styles'
import {
    IDropdownInputOption,
    IDropdownOption,
    IDropdownProps,
    IDropdownState,
    TDropdownIconSide,
    TDropdownMod,
} from './Dropdown-types'
import { isExist, isString } from 'core/utils/coreUtil'
import { DropdownOption } from './DropdownOption'
import { DropdownLabel } from './DropdownLabel'
import { isNumber } from 'core/utils/typeUtil'
import Tippy from 'blocks.simple/tippy/tippy'
import translate from 'core/translate'

const DEFAULT_LABEL_KEY = 'label'
const DEFAULT_VALUE_KEY = 'value'

const getListFromOptions = (
    options: IDropdownInputOption[],
    labelKey: string = DEFAULT_LABEL_KEY,
    valueKey: string = DEFAULT_VALUE_KEY
): IDropdownOption[] => {
    return options.map((option) => ({ label: option[labelKey], value: option[valueKey], icon: option.icon }))
}

class Dropdown extends React.PureComponent<IDropdownProps, IDropdownState> {
    private DEFAULT_MODE: TDropdownMod = 'withShadow'
    private DEFAULT_ICON_SIDE: TDropdownIconSide = 'left'

    constructor(props: IDropdownProps) {
        super(props)

        this.state = {
            isActive: false,
            list: [],
        }
    }

    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        const { list } = prevState
        const { options, labelKey, valueKey } = nextProps

        if (options.length !== list.length) {
            return {
                ...prevState,
                list: getListFromOptions(options, labelKey, valueKey),
            }
        }

        return prevState
    }

    getLabel = (): string | null => {
        const { value, labelKey = DEFAULT_LABEL_KEY, valueKey = DEFAULT_VALUE_KEY, options } = this.props

        if (!isExist(value)) return null

        if (isString(value) || isNumber(value)) {
            let option = options.find((item) => item[valueKey] === value)
            return option ? option[labelKey] : null
        }

        return (value as IDropdownInputOption)[labelKey] || null
    }

    getValue = (): string | number | null => {
        const { value, valueKey = DEFAULT_VALUE_KEY, options } = this.props

        if (!isExist(value)) return null

        if (isString(value) || isNumber(value)) {
            let option = options.find((item) => item[valueKey] === value)
            return option ? option[valueKey] : null
        }

        return (value as IDropdownInputOption)[valueKey] || null
    }

    getWrapperClassName = () => {
        const { wrapperClassName, activeWrapperClassName, mod = this.DEFAULT_MODE } = this.props
        const { isActive } = this.state

        return cn(
            styles.Dropdown,
            styles[`Dropdown_type_${mod}`],
            isActive ? styles.Dropdown_type_active : {},
            wrapperClassName,
            isActive ? activeWrapperClassName : {}
        )
    }

    getListClassName = () => {
        const { listClassName } = this.props

        return cn(styles.Dropdown__list, listClassName)
    }

    toggleDropdown = () => {
        this.setState((prevState) => ({ ...prevState, isActive: !prevState.isActive }))
    }

    handleClickOutside() {
        const { isActive } = this.state

        if (isActive) this.toggleDropdown()
    }

    isEqualValues = (value: string | number, currentValue: string | number | null) => value === currentValue

    handleChangeOption = (label: string, value: string | number) => {
        if (this.isEqualValues(value, this.getValue())) return

        const { onChange, options, valueKey = DEFAULT_VALUE_KEY } = this.props
        const option = options.find((item) => item[valueKey] === value)

        this.toggleDropdown()
        onChange(option as IDropdownInputOption)
    }

    renderList = () => {
        const { list } = this.state
        const {
            optionClassName,
            activeOptionClassName,
            optionIconColor,
            optionActiveIconColor,
            mod = this.DEFAULT_MODE,
            options,
            tippyDropdown,
        } = this.props
        const currentLabel = this.getLabel()

        return (
            <div className={cn(this.getListClassName())}>
                {list.map(({ label, value, icon }, index) => {
                    const isActive = currentLabel === label

                    return (
                        <>
                            {tippyDropdown ? (
                                <Tippy title={translate(`list__${options[index].id}`)}>
                                    <DropdownOption
                                        key={value}
                                        label={label}
                                        value={value}
                                        icon={icon}
                                        mod={mod}
                                        className={optionClassName}
                                        activeClassName={activeOptionClassName}
                                        onChangeOption={this.handleChangeOption}
                                        iconColor={optionIconColor}
                                        activeIconColor={optionActiveIconColor}
                                        isActive={isActive}
                                    />
                                </Tippy>
                            ) : (
                                <DropdownOption
                                    key={value}
                                    label={label}
                                    value={value}
                                    icon={icon}
                                    mod={mod}
                                    className={optionClassName}
                                    activeClassName={activeOptionClassName}
                                    onChangeOption={this.handleChangeOption}
                                    iconColor={optionIconColor}
                                    activeIconColor={optionActiveIconColor}
                                    isActive={isActive}
                                />
                            )}
                        </>
                    )
                })}
            </div>
        )
    }

    renderLabel = () => {
        const {
            label,
            icon,
            activeIcon = icon,
            iconSide = this.DEFAULT_ICON_SIDE,
            labelClassName,
            activeLabelClassName,
            labelIconColor,
            labelActiveIconColor,
            mod = this.DEFAULT_MODE,
        } = this.props
        const { isActive } = this.state
        const dropdownLabel = this.getLabel() || label
        const currentIcon = isActive ? activeIcon : icon

        return (
            <DropdownLabel
                label={dropdownLabel}
                icon={currentIcon}
                mod={mod}
                iconSide={iconSide}
                onClick={this.toggleDropdown}
                className={labelClassName}
                activeClassName={activeLabelClassName}
                iconColor={labelIconColor}
                activeIconColor={labelActiveIconColor}
                isActive={isActive}
            />
        )
    }

    render() {
        const { isActive } = this.state

        return (
            <div className={cn(this.getWrapperClassName())}>
                {this.renderLabel()}
                {isActive && this.renderList()}
            </div>
        )
    }
}

export default enhanceWithClickOutside(Dropdown)
