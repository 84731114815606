import React from 'react'
import { cn } from 'ethcss'
import { Typography } from 'atoms/Typography'
import styles from './deviceSettings__filePath.jcss'
import EditText from 'blocks.simple/editText/editText'
import translate from 'core/translate'

const DeviceSettingsFilePath = React.memo(
    (p_: {
        className: string | object
        value: string
        onChange: (value: string, field: string) => void
        editableField: string
        enabled: boolean
    }) => {
        return (
            <div className={cn(p_.className)}>
                <Typography className={cn(styles.title)} type="title">
                    {translate('localFilePathToXML')}
                </Typography>
                <div className={styles.inputWrapper}>
                    <EditText
                        mod={'withBorder'}
                        value={p_.value}
                        placeholder="С:\xml\check.xml"
                        onChange={function (value: string) {
                            p_.onChange(value, p_.editableField)
                        }}
                    />
                </div>
            </div>
        )
    }
)

export { DeviceSettingsFilePath }
