import React from 'react'
import DisplaysAddMethods, { IState } from './displays__add.local.methods'
import EditText from 'blocks.simple/editText/editText'
import Button from 'blocks.simple/button/button'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import { SelectMapDialog } from 'features/map'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import { Radio } from 'atoms/Radio'
import { Scrollbars } from 'react-custom-scrollbars'
import helpersDisplays from 'core/helpers/_digitalSignages'
import helpers from 'core/helpers'
import { connect } from 'react-redux'
import { cn } from 'ethcss'
import styles from './displays__add.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import translate from 'core/translate'
import { colors, text as textStyles } from 'theme'
import { Typography } from 'atoms/Typography'
import { displaysAddActions } from './displays__add.state'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { displaysActions } from '../displays.state'
import { OpenStreetMap } from '../../../features/map/molecules/OpenStreetMap/OpenStreetMap-view'
import { SetPartialData } from '../__addGroup/displays__addGroup.local.methods'
import Icon from '../../../blocks.simple/icon/icon'
import { noEmoji } from 'core/utils/displayUtil'

const scrollBarStyles = {
    width: '100%',
    height: '100%',
}

class DisplaysAdd extends DisplaysAddMethods {
    getInputLabel = (textKey: string) => (
        <Typography type="title" className={styles.inputLabel}>
            {translate(textKey)}
        </Typography>
    )

    renderMap() {
        const { active } = this.state

        const mapType = window?.spconfig?.map?.type

        return mapType !== 'yandex' && mapType !== 'google' ? (
            <OpenStreetMap
                onClose={this.onMapClose}
                isOpen={active}
                location={this.getLocation()}
                onChangeLocation={this.onChangeLocation}
                onChangePlacemarkGeometry={this.onChangePlacemarkGeometry}
            />
        ) : (
            <SelectMapDialog
                onClose={this.onMapClose}
                isOpen={active}
                location={this.getLocation()}
                center={this.getCenter()}
                onChangeLocation={this.onChangeLocation}
                onChangeAddress={this.onChangeAddress}
                onChangePlacemarkGeometry={this.onChangePlacemarkGeometry}
            />
        )
    }

    checkEmptyLicences = (licences: any) => {
        return licences.some((licence: any) => licence.vacantCount)
    }

    render() {
        const p_ = this.props
        const s_ = this.state
        let groups = this.getGroupsWithReplacedName(p_.groups)
        const disabled = !helpersDisplays.isCheckLicenseQ()
            ? false
            : !helpersDisplays.checkActualLicense(p_.data.licenseId, s_.licensesData)

        return (
            <Scrollbars style={scrollBarStyles}>
                <form
                    className={styles.deviceParametersWrapper}
                    onSubmit={(e) => {
                        e.preventDefault()

                        if (s_.active) {
                            return
                        }
                        this.save()
                    }}
                >
                    <Typography className={cn(styles.title)} type="title">
                        {translate('displayInfo')}
                    </Typography>
                    <div className={styles.content}>
                        <div className={styles.formWrapper}>
                            <div className={styles.form}>
                                <div className={styles.leftColumnContent}>
                                    <div className={cn(grid.mb_md, { [styles.disabled]: disabled })}>
                                        <EditText
                                            disabled={disabled}
                                            validationBorder={!!s_.data.error}
                                            label={this.getInputLabel('name')}
                                            id="name"
                                            mod="withBorder"
                                            type="text"
                                            className={cn(textStyles.center, styles.noBoxShadow)}
                                            value={p_.data.name}
                                            onChange={(name: string) => {
                                                p_.setPartialData({ name })
                                                this.inputNullCheck(name, 'name')
                                            }}
                                        />
                                        <div
                                            className={cn(
                                                grid.mt_micro,
                                                textStyles.centerBlock,
                                                s_.data.error ? '' : item.hidden,
                                                item.errorInputText
                                            )}
                                        >
                                            {translate('fieldIsRequired')}
                                        </div>
                                    </div>
                                    <div
                                        className={cn(grid.mb_md, { [styles.disabled]: disabled })}
                                        onClick={function (e) {
                                            e.stopPropagation()
                                        }}
                                    >
                                        <EditText
                                            label={this.getInputLabel('address')}
                                            id="address"
                                            mod="withBorder"
                                            type="text"
                                            icon="location"
                                            placeholder={translate('selectAddressOnMap')}
                                            className={cn(textStyles.center, styles.noBoxShadow)}
                                            onIconClick={this.onMapOpen}
                                            value={this.getAddress()}
                                            onChange={this.onChangeAddress}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className={styles.descriptionWrapper}>
                                    <div className={cn(grid.mb_md, { [styles.disabled]: disabled })}>
                                        <EditText
                                            disabled={disabled}
                                            label={this.getInputLabel('description')}
                                            id="description"
                                            mod="withBorder"
                                            className={cn(styles.noBoxShadow)}
                                            type="area"
                                            rows="6"
                                            value={p_.data.description}
                                            onChange={function (description: string) {
                                                p_.setPartialData({ description })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.leftColumnContent}>
                                <div className={cn(grid.mb_md, item.rel, { [styles.disabled]: disabled })}>
                                    {p_.groups && (
                                        <Dropdown
                                            id="group"
                                            disabled={disabled}
                                            label={this.getInputLabel('group')}
                                            value={p_.data.groupId}
                                            options={groups}
                                            centered
                                            searchable
                                            type="tree"
                                            onChange={function (selected) {
                                                p_.setPartialData({ groupId: selected.id })
                                            }}
                                        />
                                    )}
                                </div>
                                {s_.initGroup !== p_.data.groupId && (
                                    <div className={cn(grid.mb_md, { [styles.disabled]: disabled })}>
                                        <Checkbox
                                            onClick={function (checked: boolean) {
                                                p_.setPartialData({ overwriteSettings: checked })
                                            }}
                                            className={cn(grid.p_micro)}
                                            checked={p_.data.overwriteSettings}
                                            label={
                                                <Typography
                                                    type="title"
                                                    className={cn(
                                                        textStyles.normal,
                                                        grid.pl_mini,
                                                        grid.pr_mini,
                                                        textStyles.normal
                                                    )}
                                                >
                                                    {translate('applyGroupSettings')}
                                                </Typography>
                                            }
                                            disabled={disabled}
                                        />
                                    </div>
                                )}
                                <div className={cn(grid.mb_md, { [styles.disabled]: disabled })}>
                                    <div
                                        className={cn(
                                            grid.mb_mini,
                                            textStyles.up,
                                            textStyles.normalPlus,
                                            textStyles.centerBlock,
                                            textStyles.normal
                                        )}
                                    >
                                        <Typography type="title">{translate('timezones')}</Typography>
                                    </div>
                                    <Checkbox
                                        disabled={disabled}
                                        className={cn(grid.p_micro)}
                                        label={
                                            <div className={cn(grid.pl_mini, grid.pr_mini, textStyles.normal)}>
                                                <Typography type="title">{translate('useGroupTimezone')}</Typography>
                                            </div>
                                        }
                                        checked={s_.groupTimezone}
                                        onClick={(checked: boolean) => {
                                            this.setState({ groupTimezone: checked })
                                        }}
                                    />
                                </div>
                                <div className={cn(grid.mb_md, { [styles.disabled]: disabled })}>
                                    <Dropdown
                                        id={'timezones'}
                                        value={
                                            s_.groupTimezone
                                                ? s_.parentTimezone
                                                    ? s_.parentTimezone
                                                    : p_.data.timezone
                                                : p_.data.timezone
                                        }
                                        validationBorder={!!s_.data.nullTimezoneError}
                                        options={p_.timezones}
                                        placeholder={translate('notSelected')}
                                        searchable
                                        centered
                                        onChange={(selected) => {
                                            p_.setPartialData({ timezone: selected.id })
                                            this.inputNullCheck(selected, 'timezone')
                                        }}
                                        labelClassName={cn(item.ellipsis)}
                                        disabled={s_.groupTimezone || disabled}
                                    />
                                    <div
                                        className={cn(
                                            grid.mt_micro,
                                            textStyles.centerBlock,
                                            s_.data.nullTimezoneError ? '' : item.hidden,
                                            item.errorInputText
                                        )}
                                    >
                                        {translate('fieldIsRequired')}
                                    </div>
                                </div>
                                <div className={cn(grid.mb_md, { [styles.disabled]: disabled })}>
                                    <Dropdown
                                        disabled={disabled}
                                        searchable
                                        value={p_.data.orientation}
                                        label={this.getInputLabel('orientation')}
                                        options={helpersDisplays.getOrientations()}
                                        onChange={function (value) {
                                            p_.setPartialData({ orientation: value.id })
                                        }}
                                        centered
                                    />
                                </div>
                            </div>
                            <div className={styles.rightColumnContent}>
                                <div
                                    className={cn(styles.displaysAdd__inputWrapper_type_tags, {
                                        [styles.disabled]: disabled,
                                    })}
                                >
                                    {p_.data.tags && p_.tagsList && (
                                        <Dropdown
                                            hiddenOverflow
                                            disabled={disabled}
                                            list={p_.data.tags}
                                            options={p_.tagsList}
                                            label={this.getInputLabel('tag')}
                                            searchable={true}
                                            multiselect={true}
                                            disableAddNewTags={
                                                !helpers.isAvailableAction(null, {
                                                    key: 'digitalSignage',
                                                    action: 'update',
                                                    subaction: 'manageTags',
                                                })
                                            }
                                            onChange={function (tags) {
                                                tags = tags.map((tag: { id: string; name: string; label: string }) => {
                                                    if (!tag.id && tag.name.match(/#/g)) {
                                                        tag.name = tag.label = tag.name.replace(/#/g, '')
                                                    }
                                                    tag = { ...tag, name: noEmoji(tag.name), label: noEmoji(tag.label) }
                                                    return tag
                                                })
                                                p_.setPartialData({ tags })
                                            }}
                                            onValidateSymbolNumber={(checked) => this.onValidateSymbolNumber(checked)}
                                        />
                                    )}
                                </div>
                                {helpers.isAvailableAction(null, {
                                    key: 'digitalSignage',
                                    action: 'update',
                                    subaction: 'manageTags',
                                }) && (
                                    <div className={grid.mt_mini}>
                                        <Typography className={textStyles.centerBlock}>
                                            {translate('forAddTagUseEnter')}
                                        </Typography>
                                        {s_.validateSymbolNumber && (
                                            <Typography className={cn(styles.centerBlock, grid.mt_mini)} type="error">
                                                {translate('minTagNumber')}
                                            </Typography>
                                        )}
                                    </div>
                                )}
                                {helpersDisplays.isCheckLicenseQ() && (
                                    <div className={cn(grid.mb_md, grid.mt_big, item.rel)}>
                                        <Dropdown
                                            id="licensesDataEdit"
                                            label={
                                                <Typography
                                                    type="title"
                                                    className={cn(
                                                        textStyles.up,
                                                        textStyles.centerBlock,
                                                        textStyles['normalPlus'],
                                                        grid.mb_mini
                                                    )}
                                                >
                                                    {translate('licensesType')}
                                                </Typography>
                                            }
                                            value={p_.data.licenseId}
                                            options={s_.licensesData}
                                            centered
                                            onChange={(selected) => {
                                                this.setState((prevState: IState) => ({
                                                    ...prevState,
                                                    currentLicense: selected,
                                                }))
                                                p_.setPartialData({ licenseId: selected.id })
                                            }}
                                        />
                                    </div>
                                )}
                                {s_.currentLicense?.vacantCount ? (
                                    <div className={styles.license__info}>
                                        <Typography type="text">
                                            {translate('licensesBalance')} {s_.currentLicense.vacantCount}{' '}
                                            {translate('licensesLeft')}
                                        </Typography>
                                    </div>
                                ) : s_.currentLicense?.vacantCount === 0 && this.checkEmptyLicences(s_.licensesData) ? (
                                    <div className={styles.noLicensesLeft}>
                                        <Icon type={'info'} color={colors.notificationRed} />
                                        <span>{translate('noLicencesInTariff')}</span>
                                    </div>
                                ) : null}
                                {!this.checkEmptyLicences(s_.licensesData) && (
                                    <div className={styles.noLicensesLeft}>
                                        <Icon type={'info'} color={colors.notificationRed} />
                                        <span>{translate('noLicencesInTariffs')}</span>
                                    </div>
                                )}
                                {s_.initGroup !== p_.data.groupId && (
                                    <div className={cn(grid.mt_mdPlus, { [styles.disabled]: disabled })}>
                                        <Typography
                                            type="title"
                                            className={cn(
                                                textStyles.centerBlock,
                                                textStyles.up,
                                                textStyles['normalPlus']
                                            )}
                                        >
                                            {translate('scheduleForDevice')}
                                        </Typography>
                                        <Radio
                                            className={cn(grid.mt_md)}
                                            label={
                                                <Typography type="title">
                                                    {translate('changeCurrentSchedule')}
                                                </Typography>
                                            }
                                            selected={p_.data.groupScheduleMode === 'apply'}
                                            onClick={function () {
                                                p_.setPartialData({ groupScheduleMode: 'apply' })
                                            }}
                                            disabled={disabled}
                                        />
                                        <Radio
                                            className={cn(grid.mt_mini)}
                                            label={
                                                <Typography type="title">
                                                    {translate('remainCurrentSchedule')}
                                                </Typography>
                                            }
                                            selected={p_.data.groupScheduleMode === 'ignore'}
                                            onClick={function () {
                                                p_.setPartialData({ groupScheduleMode: 'ignore' })
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        {p_.isEdit && (
                            <Button mod="withBorder" className={cn(grid.mr_md)} onClick={this.onClose}>
                                {translate('cancel')}
                            </Button>
                        )}
                        <Button
                            mod="fill"
                            onClick={this.onSave}
                            disabled={
                                !helpersDisplays.isCheckLicenseQ()
                                    ? false
                                    : !!(s_.data.error || s_.data.nullTimezoneError)
                            }
                        >
                            {translate('save')}
                        </Button>
                    </div>
                    {this.renderMap()}
                </form>
            </Scrollbars>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        data: state.displays__add.data,
        isEdit: state.displays__add.isEdit,
        groups: state.displays.groups,
        tagsList: state.displays.tags,
        timezones: state.app.timezones,
        user: state.user.data.companyId,
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    setPartialData: (data: SetPartialData) => dispatch(displaysAddActions.setPartialData(data)),
    emitError: (err: string) => dispatch(emitError(err)),
    closeModal: () => dispatch(displaysActions.closeModal()),
    setGroups: (groups: any) => dispatch(displaysActions.setGroups(groups)),
    clearData: () => dispatch(displaysAddActions.clearData()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DisplaysAdd)
