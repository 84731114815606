import grid from 'blocks.simple/grid/grid.jcss'
import { addStyles } from 'ethcss'
import { defaultThemeStyles } from './app.theme'
import { textSizes } from 'theme/media'
import { IStyles } from '../../theme'

const appStyles: IStyles = {
    rootWrapper: {
        ...grid.w100,
        ...grid.fullHeight,
        minHeight: '100%',
    },
    _global: {
        body: {
            fontSize: textSizes.normal,
            backgroundColor: `var(--var-module-app-component-app-style-background, ${defaultThemeStyles.background})`,
            color: `var(--var-module-app-component-app-style-textSub, ${defaultThemeStyles.textSub})`,
            fontFamily: `var(--var-module-app-component-app-style-fontFamily, ${defaultThemeStyles.fontFamily})`,
            '& *': {
                fontFamily: `var(--var-module-app-component-app-style-fontFamily, ${defaultThemeStyles.fontFamily})`,
            },
            '& *::placeholder': {
                color: `var(--var-module-app-component-app-style-placeholder, ${defaultThemeStyles.placeholder})`,
            },
            '& input': {
                fontFamily: `var(--var-module-app-component-app-style-fontFamily, ${defaultThemeStyles.fontFamily})!important`,
            },
        },
    },
    wrapper: {
        ...grid.w100,
        ...grid.fullHeight,
        fontSize: textSizes.normal,
        minHeight: '100%',
    },
}

addStyles(appStyles)
export default appStyles
