import PropTypes from 'prop-types'
import React from 'react'
import EditorMethods from './editor.local.methods'
import { EditorToolbar } from './__toolbar/editor__toolbar'
import ToolbarRight from './__toolbar/_right/editor__toolbar_right'
import EditorScreen from './__screen/editor__screen'
import Diff from './__diff/editor__diff'
//import AnimationSettings from 'blocks.app/animationSettings/animationSettings'
import { HotKeys } from 'react-hotkeys'
import { cn } from 'ethcss'
import styles from './editor.jcss'
import { connect } from 'react-redux'
import { editor } from './editor.local.methods'
import { defaultOrderMode } from 'core/models/Editor'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { isRedirectToLogin, isRoutePathnameChange } from 'features/routes/utils'
import { RouteLeavingGuard } from 'molecules/CustomPromt'
import { changeLocation } from 'features/routes'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { activateEditTable, saveTable, activateEditLink, activateEditText } from 'pages/files/files.state'
import { withRouterForwardRef } from 'core/hocs/withRouterForwardRef'
import { updateUserSettings, userActions } from 'blocks.app/user/user.state'
import { headActions } from 'features/head/Head.state'
class Editor extends EditorMethods {
    //Возвращает данные, которые используются при рендеринге
    getStateData = () => {
        const s_ = this.state

        return {
            simple: s_.data.simple,
            selectedPage: s_.data.selectedPage,
            selectedContent: s_.data.selectedContent,
            selectedArea: s_.data.selectedArea,
            pages: s_.broadcast.pages,
            resolutionWidth: s_.broadcast.resolutionWidth,
            resolutionHeight: s_.broadcast.resolutionHeight,
            screenSize: s_.data.screenSize,
            sources: s_.data.sources,
            showSettings: s_.data.showSettings,
            minimized: s_.data.minimized,
            selectedTemplate: s_.data.selectedTemplate,
        }
    }
    //Возвращает установленную продолжительность трансляции. Используется при рендеринге
    getDurationSettings = () => {
        const s_ = this.state

        return {
            duration: s_.data.duration,
        }
    }
    //Возвращает данные с настройками трансляции, которые передаются в правый тулбар редактора
    getBroadcastSettings = () => {
        const s_ = this.state

        return {
            title: s_.broadcast.title,
            resolutionWidth: s_.broadcast.resolutionWidth,
            resolutionHeight: s_.broadcast.resolutionHeight,
            folderId: s_.broadcast.folderId,
            titleError: s_.data.titleError,
            simple: s_.data.simple,
        }
    }

    isShouldBlockNavigation = (location, prevLocation) => {
        return (
            !isRedirectToLogin(location.pathname) &&
            isRoutePathnameChange(location, prevLocation) &&
            this.isPreventLeaveRoute()
        )
    }

    render() {
        const s_ = this.state
        const p_ = this.props
        const s_data = this.getStateData()
        let selectedContentItem
        const isSimple = s_data.simple
        const query = getURLSearchParamsByLocation(p_.location)
        const isEdit = typeof query.broadcastId !== 'undefined'
        const isSelectedContent = s_data.selectedContent !== null
        const isSelectedArea = s_data.selectedArea !== null
        const page = s_data.pages[s_data.selectedPage]
        const pages = s_data.pages.map((pagesItem) => pagesItem.order)
        const loop =
            isSelectedArea &&
            page &&
            page.areas &&
            page.areas[s_data.selectedArea] &&
            page.areas[s_data.selectedArea].loop
        const contentOrderMode =
            (isSelectedArea &&
                page &&
                page.areas &&
                page.areas[s_data.selectedArea] &&
                page.areas[s_data.selectedArea].contentOrderMode) ||
            defaultOrderMode
        const soundOrderMode = s_.broadcast.soundOrderMode || defaultOrderMode

        isSelectedArea &&
            isSelectedContent &&
            (selectedContentItem = page.areas[s_data.selectedArea].content[s_data.selectedContent])

        return (
            <HotKeys
                keyMap={this.keyMap}
                handlers={this.handlers}
                className={cn(styles.colsWrapper, p_.wrapperClassName)}
                style={p_.wrapperStyles}
            >
                <div className={cn(styles.colsContent, { [styles.mobile]: p_.isMobile })}>
                    <div className={styles.wrapper}>
                        <div className={styles.header}>
                            <EditorToolbar
                                isMobile={p_.isMobile}
                                getMaxDuration={editor.getMaxDuration}
                                isEdit={isEdit}
                                broadcast={s_.broadcast}
                                broadcastId={query.broadcastId}
                                isSimple={isSimple}
                                onDelete={this.onDelete}
                                onClose={this.onClose}
                                onSave={this.onSave}
                                onAddArea={this.onAddArea}
                                onOpenBroadcastSettings={this.onOpenBroadcastSettings}
                                onFullscreenContent={this.onFullscreenContent}
                                onFullscreenMaximizeContent={this.onFullscreenMaximizeContent}
                                onFullscreenCropContent={this.onFullscreenCropContent}
                                onCopyAreaContent={this.onCopyAreaContent}
                                onPasteAreaContent={this.onPasteAreaContent}
                                onAnimationSettings={this.onAnimationSettings}
                                onGoPrev={this.onGoPrev}
                                onGoNext={this.onGoNext}
                                disabledContentButtons={!isSelectedContent}
                                disabledAreaButtons={!isSelectedArea}
                                onZoomIn={this.zoomIn}
                                onZoomOut={this.zoomOut}
                                onAddTable={this.addTable}
                                disabledPaste={s_.data.clonedArea === null || !isSelectedArea}
                                disabledZoomIn={s_.data.zoom >= 6}
                                disabledZoomOut={s_.data.zoom <= 1}
                                disabledGoPrev={s_.currentTimeStep === 0}
                                disabledGoNext={s_.currentTimeStep >= this.steps.length - 1}
                                activeIcon={{ animationSettings: s_.data.showAnimationSettings }}
                                location={this.props.location}
                                changeLocation={this.changeLocation}
                                displayedTooltips={
                                    this.props.settings &&
                                    this.props.settings.displayedTooltips &&
                                    this.props.settings.displayedTooltips
                                }
                                updateUserSettings={this.props.updateUserSettings}
                            />
                        </div>
                        <div className={styles.screenWrapper}>
                            <HotKeys keyMap={this.screenKeyMap} handlers={this.screenHandlers}>
                                <EditorScreen
                                    isMobile={p_.isMobile}
                                    ref={(screen) => (this.screen = screen)}
                                    zoom={s_.data.zoom}
                                    duration={page.duration}
                                    areas={page.areas}
                                    isSimple={isSimple}
                                    isSelectedContent={isSelectedContent}
                                    isSelectedArea={isSelectedArea}
                                    resolutionWidth={s_data.resolutionWidth}
                                    resolutionHeight={s_data.resolutionHeight}
                                    screenSize={s_data.screenSize}
                                    pagesLength={s_data.pages.length}
                                    pages={s_data.pages}
                                    selectedPage={s_data.selectedPage}
                                    selectedArea={s_data.selectedArea}
                                    sources={s_data.sources}
                                    selectedContent={s_data.selectedContent}
                                    minimized={s_data.minimized}
                                    areaViewType={s_.data.areaViewType}
                                    showAllAreas={s_.data.showAllAreas}
                                    showAnimationSettings={s_.data.showAnimationSettings}
                                    loop={loop}
                                    selected={s_.data.selected}
                                    soundOrderMode={soundOrderMode}
                                    isSoundtrackLoading={s_.isSoundtrackLoading}
                                    fileLoading={s_.fileLoading}
                                />
                            </HotKeys>
                        </div>
                        {/*{selectedContentItem && s_.data.showAnimationSettings && (*/}
                        {/*    <AnimationSettings*/}
                        {/*        animation={selectedContentItem.animation}*/}
                        {/*        onClose={this.closeAnimationSettings}*/}
                        {/*        onSave={this.closeAnimationSettings}*/}
                        {/*        onUpdate={this.updateAnimation}*/}
                        {/*    />*/}
                        {/*)}*/}
                    </div>
                </div>
                <ToolbarRight
                    isMobile={p_.isMobile}
                    isSimple={isSimple}
                    selectedContent={s_data.selectedContent}
                    selectedArea={s_data.selectedArea}
                    selectedContentItem={selectedContentItem}
                    sources={s_data.sources}
                    content={editor.getContent()}
                    page={page}
                    pages={pages}
                    broadcastPages={s_data.pages}
                    onSelectContent={this.onSelectContent}
                    onSelectArea={this.onSelectArea}
                    toolbarIsOpened={s_.toolbarIsOpened}
                    showSettings={s_data.showSettings}
                    areas={page.areas}
                    resolutionWidth={s_data.resolutionWidth}
                    resolutionHeight={s_data.resolutionHeight}
                    minimized={s_data.minimized}
                    selectedTemplateItem={s_data.selectedTemplate[s_data.selectedPage]}
                    autoPos={
                        isSelectedArea &&
                        page &&
                        page.areas &&
                        page.areas[s_data.selectedArea] &&
                        page.areas[s_data.selectedArea].autoPos
                    }
                    loop={loop}
                    contentOrderMode={contentOrderMode}
                    durationSettings={this.getDurationSettings()}
                    broadcastSettings={this.getBroadcastSettings()}
                    areaViewType={s_.data.areaViewType}
                    showAllAreas={s_.data.showAllAreas}
                />
                <RouteLeavingGuard
                    when={this.isPreventLeaveRoute()}
                    shouldBlockNavigation={this.isShouldBlockNavigation}
                    onAccept={this.onAccept}
                    navigate={(path) => changeLocation(path)}
                />

                {this.startBroadcast && <Diff broadcast={s_.broadcast} data={s_.data} />}
            </HotKeys>
        )
    }
}

Editor.propTypes = {
    isSimple: PropTypes.bool,
    broadcast: PropTypes.object,
    location: PropTypes.object,
    route: PropTypes.object,
    showExitWarning: PropTypes.bool,
    onSave: PropTypes.func,
    wrapperClassName: PropTypes.string,
    wrapperStyles: PropTypes.object,
    updateUserSettings: PropTypes.func,
}

Editor.defaultProps = {
    showExitWarning: true,
}

const mapStateToProps = (state) => {
    return {
        transitionData: state.user.transitionData,
        settings: state.user.data.settings,
        isDeleted: state.user.isDeleted,
        isMobile: state.app.isMobile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        emitError: (err) => dispatch(emitError(err)),
        setTitle: (title) => dispatch(headActions.setTitle(title)),
        activateEditTable: (id) => dispatch(activateEditTable(id)),
        activateEditLink: (id) => dispatch(activateEditLink(id)),
        activateEditText: (id) => dispatch(activateEditText(id)),
        saveTable: (savedFileData) => dispatch(saveTable(savedFileData)),
        updateUserSettings: (userSettings) => dispatch(updateUserSettings(userSettings)),
        setTransition: (transitionData) => dispatch(userActions.setTransition(transitionData)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withRouterForwardRef(Editor))
