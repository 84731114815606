import { addStyles } from 'ethcss'
import { indents, textSizes, item, IStyles, grid } from 'theme'
import { mediaCSS } from 'theme/media'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'

const avatarSize = {
    width: 69,
    height: 69,
}

export const accountSettingsStyles: IStyles = {
    accountsWrapper: {
        borderTop: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    account: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.w25,
        ...grid.noWrap,
        [mediaCSS.tab]: {
            ...grid.w50,
        },
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
        ...grid.p_md,
        position: 'relative',
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        borderRight: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    itemList() {
        return {
            ...item.transitionErase,
            '&:hover': {
                ...item.shadowButton,
                [`& .${this.deleteIcon}`]: {
                    ...item.visible,
                },
            },
        }
    },
    deleteIcon: {
        ...grid.col,
        ...grid.normalCenter,
        visibility: 'hidden',
        position: 'absolute',
        right: indents.md,
    },
    content: {
        ...grid.space,
        ...grid.ml_md,
        width: 'calc(100% - 90px)',
    },
    imgWrapperAdd: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.center,
        ...avatarSize,
    },
    accountSettings__text: {
        ...item.ellipsisPure,
        padding: indents.mini,
        fontWeight: 'bold',
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        fontSize: textSizes.mini,
    },
}

addStyles(accountSettingsStyles)
