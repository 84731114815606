import React, { useEffect, useState } from 'react'
import styles from './display__control.jcss'
import { cn } from 'ethcss'
import { Event } from './display__remoteControl'

let timer: any = null
let activeTimer: any = null

export const Remote = ({ onEvent, isMobile }: { onEvent: (event: Event) => void; isMobile: boolean }) => {
    const [pressedBtn, setPressedBtn] = useState<string>('')
    const [active, setActive] = useState(false)

    const buttons = [
        {
            name: 'arrowLeft',
            code: 'KEYCODE_DPAD_LEFT',
            icon: 'arrow-left',
        },
        {
            name: 'arrowRight',
            code: 'KEYCODE_DPAD_RIGHT',
            icon: 'arrow-right',
        },
        {
            name: 'arrowUp',
            code: 'KEYCODE_DPAD_UP',
            icon: 'arrow-up',
        },
        {
            name: 'arrowDown',
            code: 'KEYCODE_DPAD_DOWN',
            icon: 'arrow-down',
        },
        {
            name: 'home',
            code: 'KEYCODE_HOME',
            icon: 'home',
        },
        {
            name: 'back',
            code: 'KEYCODE_BACK',
            icon: 'back',
        },
        {
            name: 'plus',
            code: 'KEYCODE_VOLUME_UP',
            icon: 'plus',
        },
        {
            name: 'minus',
            code: 'KEYCODE_VOLUME_DOWN',
            icon: 'minus',
        },
        {
            name: 'mute',
            code: 'KEYCODE_VOLUME_MUTE',
            icon: 'mute',
        },
        {
            name: 'select',
            code: 'KEYCODE_DPAD_CENTER',
            icon: 'ok',
        },
    ]

    useEffect(() => {
        document.addEventListener('mouseup', buttonReleased)

        return () => document.removeEventListener('mouseup', buttonReleased)
    }, [pressedBtn])
    useEffect(() => {
        if (pressedBtn) {
            timer = setTimeout(() => {
                onButtonPress(true)
            }, 1000)

            return
        }

        return () => clearTimeout(timer)
    }, [pressedBtn])
    useEffect(() => {
        return () => clearTimeout(activeTimer)
    }, [])

    const onButtonPress = (isPressed: boolean) => {
        const button = buttons.find((button) => button.name === pressedBtn)

        if (!button) return

        onEvent({
            type: 'button',
            data: {
                code: button.code,
                isPressed,
            },
        })

        setPressedBtn('')
    }
    const buttonReleased = () => {
        clearTimeout(timer)

        if (pressedBtn) {
            onButtonPress(false)
        }
    }
    const onClick = () => {
        setActive(true)

        if (activeTimer) {
            clearTimeout(activeTimer)
        }

        activeTimer = setTimeout(() => {
            setActive(false)
        }, 3000)
    }

    const renderButtons = () => {
        return buttons.map((button) => {
            return (
                <div
                    className={cn(
                        styles.remoteButton,
                        styles[button.name + 'Btn'],
                        button.name === pressedBtn ? styles.pressedBtn : ''
                    )}
                    onMouseDown={(e) => {
                        e.preventDefault()
                        setPressedBtn(button.name)
                    }}
                >
                    <img src={`images/displays/${button.icon}.svg`} />
                </div>
            )
        })
    }

    return (
        <div className={cn(styles.remote, active ? styles.active : '')} onClick={onClick}>
            <img src={isMobile ? 'images/displays/remote-mobile.svg' : 'images/displays/remote.svg'} />
            {renderButtons()}
        </div>
    )
}
