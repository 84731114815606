import React, { useState, useEffect } from 'react'
import Sidebar from 'blocks.app/sidebar/sidebar'
import translate from 'core/translate'
import { api } from 'core/api/ConnectionManager'
import styles from './updateDevices__toolbar.jcss'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { useLocation } from 'react-router'

type UpdateType = {
    updateType: string
}
interface IItem {
    name: string
    digitalSignage: { name: string }
    id: number
}
interface IUpdateDevicesToolbar {
    selectedItems: IItem[]
    onChange: (items: IItem[]) => void
    updateType: string
}

const getTitle = ({ updateType }: UpdateType) => {
    switch (updateType) {
        case 'devicesForUpdate':
            return translate('selectedDevicesForUpdate')
        default:
            return translate('selectedTasksForUpdate')
    }
}

const getEmptyTitle = ({ updateType }: UpdateType) => {
    switch (updateType) {
        case 'devicesForUpdate':
            return translate('noDevicesSelected')
        default:
            return translate('noTasksSelected')
    }
}

const MenuItem = (p_: { name: string; onClick: () => void }) => (
    <div onClick={p_.onClick} className={styles.menuItem}>
        {translate(p_.name)}
    </div>
)

const UpdateDevicesToolbar = (p_: IUpdateDevicesToolbar) => {
    const [selectedItems, setSelectedItems] = useState<IItem[]>([])
    const tasksIds = selectedItems.map((item: { id: number }) => item.id)
    const location = useLocation()
    const query = getURLSearchParamsByLocation(location)
    const updateType = query.updateType || 'devicesForUpdate'

    useEffect(() => {
        let selectedItems = p_.selectedItems

        if (updateType === 'tasksForUpdate') {
            selectedItems = selectedItems.map((item: IItem) => {
                if (item.hasOwnProperty('digitalSignage')) {
                    item.name = item.digitalSignage.name
                }

                return item
            })
        }

        setSelectedItems(selectedItems)
    }, [p_.selectedItems, updateType])

    function renderBottomContent() {
        if (updateType !== 'tasksForUpdate') {
            return null
        }

        return {
            right: (
                <React.Fragment>
                    <MenuItem
                        name="delete"
                        onClick={function () {
                            api.send('deleteDsUpdateTask', { id: tasksIds })
                        }}
                    />
                    <MenuItem
                        name="cancel"
                        onClick={function () {
                            api.send('cancelDsUpdateTask', { id: tasksIds }).then(() => {
                                p_.onChange([])
                            })
                        }}
                    />
                </React.Fragment>
            ),
        }
    }

    return (
        <Sidebar
            selectedSide="right"
            onChange={function (list: IItem[]) {
                p_.onChange(list)
            }}
            bottomContent={renderBottomContent()}
            content={{
                right: {
                    title: getTitle(p_),
                    options: selectedItems,
                },
            }}
            isEmpty={{
                right: !selectedItems.length,
            }}
            emptyText={{
                right: {
                    title: getEmptyTitle(p_),
                    description: '',
                },
            }}
        />
    )
}

export default UpdateDevicesToolbar
