import { addStyles } from 'ethcss'
import { radiusSizes, grid, item, text } from 'theme'
import { hexToRgba } from 'core/utils'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'

const ACTIVE_ROW_BACKGROUND = hexToRgba(defaultThemeStyles.border, 0.4)

const catalogListStyles = {
    catalogListTableWrapper: {
        ...grid.w100,
    },
    catalogListHeaderItem: {
        ...grid.p_mini,
        ...text.left,
        ...text.greyDark,
        ...text.bold,
    },
    catalogListHeaderCol: {
        ...item.hidden,
    },
    catalogListTableBody: {
        '& tr': {
            ...item.noSelection,
            ...item.pointer,
            '&:nth-child(even)': {
                ...item.water,
            },
            '&:nth-child(odd)': {
                ...item.white,
            },
            '& td': {
                ...text.greyDark,
                verticalAlign: 'middle',
            },
            '&:first-child': {
                '& td:first-child': {
                    borderTopLeftRadius: radiusSizes.mini,
                },
                '& td:last-child': {
                    borderTopRightRadius: radiusSizes.mini,
                },
            },
            '&:last-child': {
                '& td:first-child': {
                    borderBottomLeftRadius: radiusSizes.mini,
                },
                '& td:last-child': {
                    borderBottomRightRadius: radiusSizes.mini,
                },
            },
        },
    },
    catalogListTableCellCheckbox: {
        ...grid.pr_md,
        ...grid.pl_md,
    },
    borderedCell: {
        '&:not(:first-child)': {
            borderLeft: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
        },
    },
    tableCell: {
        ...grid.flex,
        ...grid.normalCenter,
        ...grid.p_mini,
        minHeight: 60,
    },
    catalogListItemRowActive: {
        backgroundColor: `${ACTIVE_ROW_BACKGROUND}!important`,
    },
}

addStyles(catalogListStyles)
export default catalogListStyles
