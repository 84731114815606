import { addStyles } from 'ethcss'

const nodeStyles = {
    inPort: {
        '.srd-port': {
            width: 20,
            height: 20,
            position: 'relative',
            left: -17,
            top: -17,
            borderRadius: '50%'
        },

        '.srd-default-port.srd-default-port--in': {
            marginTop: '0px!important',
            marginBottom: '3px!important'
        }
    },
    outPort: {
        '.srd-port': {
            width: 20,
            height: 20,
            position: 'relative',
            right: -17,
            bottom: -20,
            borderRadius: '50%'
        },

        '.srd-default-port.srd-default-port--out': {
            marginTop: '1px!important',
            marginBottom: '3px!important'
        }
    },
    content: {
        padding: '10px 5px'
    },
    icon: {
        display: 'inline-block',
        marginRight: 5
    }
}

addStyles(nodeStyles)
export default nodeStyles
