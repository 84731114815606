import React from 'react'
import Content from 'blocks.app/content/content'
import Catalog from '../../../blocks.app/catalog/catalog'
import translate from '../../../core/translate'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

class InstancesCatalogLightOfHistory extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            selectedInfo: null,
            selectedItems: [],
        }
    }

    render() {
        const { selectedItems } = this.state

        return (
            <Content title={translate('lightOfHistory')}>
                <Catalog
                    type={'lightOfHistory'}
                    onSelect={(selectedItems) => this.setState({ selectedItems })}
                    selectedItems={selectedItems}
                    treeView={false}
                    additionalData={{}}
                    fixedHeader={true}
                    isExistRightToolbar={false}
                    hideHeader={true}
                    handleSortable={true}
                />
            </Content>
        )
    }
}

const exportInstancesCatalogLightOfHistory = checkAvailableModule('instancesCatalog/lightOfHistory')
    ? InstancesCatalogLightOfHistory
    : null

export default exportInstancesCatalogLightOfHistory
