import { useEffect, useState } from 'react'
import { IWidgetData } from './widget/Widget'
import { RootStateOrAny, useSelector } from 'react-redux'
import { getDefaultLocale } from '../../core/helpers/user'
import { api } from '../../core/api/ConnectionManager'

export const WidgetsGalleryHooks = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [widgetsList, setWidgetsList] = useState<IWidgetData[]>([])
    const [widgetDownload, setWidgetDownload] = useState<IWidgetData | null>(null)
    const [widgetInfo, setWidgetInfo] = useState<IWidgetData | null>(null)
    const user = useSelector((state: RootStateOrAny) => state.user.data)
    const lang = user?.settings?.locale || getDefaultLocale()

    const onCloseDownload = () => {
        setWidgetDownload(null)
    }
    const getWidgetsList = () => {
        api.send('getWidgets', {}).then((res: any) => {
            setWidgetsList(res)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getWidgetsList()
    }, [])

    return {
        widgetsList,
        widgetDownload,
        widgetInfo,
        lang,
        setWidgetDownload,
        setWidgetInfo,
        onCloseDownload,
        isLoading,
    }
}
