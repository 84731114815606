import { addStyles } from 'ethcss'
import { IStyles } from 'theme'

const textEditorStyles: IStyles = {
    preview: {
        border: '1px solid grey !important',
        padding: '8px !important',
        borderRadius: '5px',
        color: '#494848',
    },
    wrapper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    wrapperClassName: {
        marginBottom: '20px',
        border: '1px solid grey',
        padding: '10px',
        borderRadius: '6px',
    },
    editorClassName: {
        minHeight: '350px',
        maxHeight: '350px',
        '&::-webkit-scrollbar': {
            borderRadius: 10,
            width: 6,
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            width: 6,
            background: '#b3b3b3',
        },
        '& .public-DraftStyleDefault-block': {
            margin: 0,
        },
    },
    toolbarClassName: {},
}

addStyles(textEditorStyles)
export default textEditorStyles
