import { addStyles } from 'ethcss'
import { grid, IStyles, item } from '../../../theme'
import { textSizes } from 'theme/media'

const historyFilterListStyles: IStyles = {
    filterOptions: {
        ...grid.mt_md,
        ...grid.mb_md,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.pb_mini,
        ...grid.pl_mini,
        ...item.rounded_full_micro,
        ...item.white,
    },
    filterContent: {
        ...grid.space,
        ...grid.row,
        ...item.fadeRight,
        overflow: 'hidden',
        ...item.rel,
        width: 1,
    },
    showAllButton: {
        ...grid.pt_mini,
        ...grid.pr_mini,
        ...item.pointer,
        fontSize: textSizes.mini,
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    filterItem: {
        ...grid.pt_mini,
        ...grid.pr_mini,
    },
}

addStyles(historyFilterListStyles)
export default historyFilterListStyles
