import React from 'react'
import moment from 'moment'
import Content from 'blocks.app/content/content'
import TimetableMethods from './timetable.local.methods'
import { Calendar } from 'blocks.app/calendar/calendar'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import translate from 'core/translate'
import { routes } from 'features/routes'
import scheduleHelpers from 'core/helpers/_schedule'
import { getURLSearchParamsString } from 'features/routes/utils'
import { changeLocation } from 'features/routes'
import { connect } from 'react-redux'
import { updateUserSettings } from 'blocks.app/user/user.state'
import { checkAvailableModule } from '../../core/helpers/routes/routes'

const DateCellWrapper = (p_) => {
    const startDate = moment(p_.value).format('YYYY-MM-DD HH:mm:ss')
    let endDate = moment(p_.value).format('YYYY-MM-DD')
    endDate = scheduleHelpers.endOf(endDate, 'day', 'YYYY-MM-DD HH:mm:ss')
    const currentDate = moment().format('YYYY-MM-DD')

    return (
        <div
            className={cn('rbc-day-bg', { 'rbc-selectedDate': currentDate === moment(startDate).format('YYYY-MM-DD') })}
            style={{ flexBasis: '14.2857%', maxWidth: '14.2857%' }}
            onClick={() =>
                changeLocation({
                    pathname: `/${routes.addTimetable.path}`,
                    search: getURLSearchParamsString({
                        startDate,
                        endDate,
                    }),
                })
            }
        ></div>
    )
}

const EventWrapper = (p_) => {
    const startDate = moment(p_.event.start).startOf('day').format('YYYY-MM-DD HH:mm:ss')
    const endDate = moment(p_.event.end).endOf('day').format('YYYY-MM-DD HH:mm:ss')

    return (
        <div
            className={cn('rbc-event')}
            style={{ ...p_.children.props.style }}
            onClick={() =>
                changeLocation({
                    pathname: `/${routes.addTimetable.path}`,
                    search: getURLSearchParamsString({
                        startDate,
                        endDate,
                    }),
                })
            }
        >
            <div className="rbc-event-content">
                <div className={cn(grid.row, grid.justify)}>
                    <div>{`${moment(p_.event.start).format('HH:mm')} - ${moment(p_.event.end).format('HH:mm')}`}</div>
                    <div>({p_.event.count})</div>
                </div>
            </div>
        </div>
    )
}

class Timetable extends TimetableMethods {
    render() {
        const s_ = this.state
        const disposition = this.getDisposition()

        return (
            <Content title={translate('timetable')}>
                <Calendar
                    date={s_.selectedDate}
                    onToday={() => {
                        this.setState({ selectedDate: moment().toDate() })
                        this.getLessons(moment().toDate())
                    }}
                    navigate={(selectedDate) => this.navigate(selectedDate)}
                    components={{
                        eventWrapper: EventWrapper,
                        dateCellWrapper: DateCellWrapper,
                    }}
                    events={s_.events}
                    view={disposition}
                    customHeaderItem={
                        <div className={cn(grid.row, grid.justify)}>
                            <div />
                            <Dropdown
                                mod="fill"
                                icon="view"
                                indentSize="mini"
                                iconColor="white"
                                onChange={this.onChangeDisposition}
                                options={[
                                    { id: 'month', name: translate('month') },
                                    {
                                        id: 'week',
                                        name: translate('week'),
                                    },
                                    { id: 'day', name: translate('dayD') },
                                ]}
                                value={disposition}
                            />
                        </div>
                    }
                />
            </Content>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserSettings: (userSettings) => dispatch(updateUserSettings(userSettings)),
    }
}

const exportTimetable = checkAvailableModule('timetable')
    ? connect(mapStateToProps, mapDispatchToProps)(Timetable)
    : null

export default exportTimetable
