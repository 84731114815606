import React from 'react'
import PropTypes from 'prop-types'
import ShowMoreMethods from './showMore.local.methods'
import Measure from 'react-measure'

import { cn } from 'ethcss'
import styles from './showMore.jcss'

class ShowMore extends ShowMoreMethods {
    static propTypes = {
        isShowInit: PropTypes.bool,
        isShow: PropTypes.bool,
        className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        children: PropTypes.node.isRequired,
        trigger: PropTypes.node.isRequired,
        onToggle: PropTypes.func
    }
    static defaultProps = {
        isShowInit: false,
        onToggle: () => {}
    }

    render() {
        const s_ = this.state
        const p_ = this.props

        return [
            this.renderTrigger(),
            <div
                key="children"
                className={cn(styles.content, p_.className, s_.isShow ? styles.open : styles.close)}
                style={{ maxHeight: s_.isShow ? `${s_.dimensions.height || 0}px` : 0 }}
            >
                <Measure
                    bounds
                    onResize={(contentRect) => {
                        this.setState({
                            dimensions: contentRect.bounds
                        })
                    }}
                >
                    {({ measureRef }) => this.renderChildren(measureRef)}
                </Measure>
            </div>
        ]
    }
}

export default ShowMore
