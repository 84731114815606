import React, { FormEvent } from 'react'
import { api } from 'core/api/ConnectionManager'
import moment from 'moment/moment'
import helpers from 'core/helpers'
import translate from 'core/translate'

interface IDisplaysAddGroupProps {
    groups: { id: number | string }[]
    data: Data
    setError: (error: string) => void
    closeModal: () => void
    clearData: () => void
    setPartialData: (data: SetPartialData) => void
    isEdit: boolean
    error: string
    timezones: string[] | any
}
export type Data = {
    id: number
    parentId: number
    name: string
    timezone: string
    inheritTimezone: boolean
    description: string
    scheduleIdToDelete: number[]
}
export type SetPartialData = {
    scheduleIdToDelete?: number[]
    name?: string
    timezone?: string | null
    parentId?: string
    inheritTimezone?: string | boolean
    description?: string
}
export type Item = {
    startDate: string
    endDate: string
    startTime: string
    endTime: string
    repeatMode: string
    id: number
    broadcast: { title: string }
}

interface IState {
    groups: string[]
    schedules: {
        id: number
        broadcast: {
            title: string
        }
    }[]
    groupTimezone: boolean
    parentTimezone: string | null
    data?: {
        error?: string | null
    }
}

class DisplaysAddGroupMethods extends React.Component<IDisplaysAddGroupProps> {
    state = {
        groups: this.props.groups.filter((group) => group.id !== this.props.data.id),
        schedules: [],
        groupTimezone: false,
        parentTimezone: null,
        data: {
            error: null,
        },
    }

    componentDidMount() {
        api.send('getGroupSchedules', { groupId: this.props.data.id }).then((res) => {
            this.setState({ schedules: res })
        })
    }

    componentDidUpdate(prevProps: IDisplaysAddGroupProps, prevState: IState) {
        if (
            prevState.groupTimezone !== this.state.groupTimezone &&
            this.state.groupTimezone &&
            this.props.data &&
            this.props.data.parentId
        ) {
            const parentTimezone: any = this.props.groups.find((group) => group.id === this.props.data.parentId)
            if (parentTimezone) {
                this.setState({
                    parentTimezone: parentTimezone['0'].timezone,
                })

                this.props.setPartialData({
                    timezone: parentTimezone['0'].timezone,
                })
            }

            if (!parentTimezone['0'].timezone) {
                this.props.setPartialData({
                    timezone: null,
                })
                this.props.setPartialData({
                    inheritTimezone: false,
                })
            }
        }
    }

    groupScheduleInfo = (item: Item, type?: string) => {
        const startDate = moment(item.startDate).format('YYYY-MM-DD')
        const endDate = moment(item.endDate).format('YYYY-MM-DD')

        const startCell = helpers.getLocalDate({ date: startDate, time: item.startTime })
        let endCell: null | moment.Moment = helpers.getLocalDate({ date: startDate, time: item.endTime })

        if (item.repeatMode !== 'forever') {
            endCell = helpers.getLocalDate({ date: endDate, time: item.endTime })
        }

        if (type === 'date') {
            let endDateFormat: string | null = translate('forever')

            if (item.repeatMode !== 'forever') {
                endDateFormat = endCell ? moment(endCell).format('DD/MM/YYYY') : null
            }
            return `${startCell ? moment(startCell).format('DD/MM/YYYY') : null} - ${endDateFormat}`
        } else {
            return `${startCell ? moment(startCell).format('HH:mm') : null} - ${
                endCell ? moment(endCell).format('HH:mm') : null
            }`
        }
    }
    submit = (e: FormEvent) => {
        const { setError, data, closeModal, clearData } = this.props
        e.preventDefault()

        const groupInfo = { ...data }

        if (!groupInfo.name) {
            this.setState((prevState: IState) => ({
                ...prevState,
                data: { ...prevState.data, error: 'fieldIsRequired' },
            }))
            return
        }

        api.send('editGroup', groupInfo)
            .then((groupItem) => {
                clearData()
                closeModal()
            })
            .catch((e) => setError('groupExistError'))
    }

    inputNullCheck = (value: string | null, type: string) => {
        if (type === 'name') {
            value
                ? this.setState((prevState: IState) => ({ ...prevState, data: { ...prevState.data, error: null } }))
                : this.setState((prevState: IState) => ({
                      ...prevState,
                      data: { ...prevState.data, error: 'fieldIsRequired' },
                  }))
        }
    }

    onClose = () => {
        const { closeModal, clearData } = this.props

        clearData()
        closeModal()
    }
}

export default DisplaysAddGroupMethods
