import { addStyles } from 'ethcss'
import { grid, indents, IStyles, mediaCSS, radiusSizes, textSizes } from 'theme'
import { defaultThemeStyles } from './ScoreboardsActionBar.theme'

export const styles: IStyles = {
    ScoreboardsActionBar: {
        // padding: `0 ${indents.mdPlus}px 0`,
        padding: '0px 36px 0px',
    },
    ScoreboardsActionBar_x: {
        padding: '0 0 0 !important',
    },
    ScoreboardsActionBar__form: {
        ...grid.row,
    },
    ScoreboardsActionBar__form_custom_theme: {
        ...grid.row,
        border: `1px solid var(--var-module-ui-component-actionBar-style-separator, ${defaultThemeStyles.customThemeBorder})`,
        borderRadius: `${radiusSizes.mini}px!important`,
    },
    ScoreboardsActionBar__sort: {
        ...grid.w20,
        position: 'relative',
        [mediaCSS.tabMini]: {
            ...grid.space,
        },
    },
    ScoreboardsActionBar__delimer_custom_theme_sort: {
        borderLeft: `1px solid var(--var-module-ui-component-actionBar-style-separator, ${defaultThemeStyles.customThemeBorder})`,
        [mediaCSS.tabMini]: {
            borderLeft: 'none',
        },
    },
    ScoreboardsActionBar__delimer_type_sort: {
        [mediaCSS.tabMini]: {
            display: 'none',
        },
    },
    ScoreboardsActionBar__selection: {
        ...grid.w15,
        position: 'relative',
        [mediaCSS.tabMini]: {
            ...grid.space,
        },
    },
    ScoreboardsActionBar__delimer: {
        ...grid.fullHeight,
        position: 'absolute',
        borderLeft: `1px solid var(--var-module-ui-component-actionBar-style-separator, ${defaultThemeStyles.separator})`,
        width: 0,
        zIndex: 101,
    },
    ScoreboardsActionBar__delimer_custom_theme: {
        ...grid.fullHeight,
        position: 'absolute',
        borderLeft: `1px solid var(--var-module-ui-component-actionBar-style-separator, ${defaultThemeStyles.customThemeBorder})`,
        width: 0,
        zIndex: 1002,
    },
    ScoreboardsActionBar__search: {
        ...grid.space,
        position: 'relative',
        [mediaCSS.tabMini]: {
            ...grid.noSpace,
            ...grid.w100,
            ...grid.mb_mini,
            display: 'block !important',
        },
    },
    ScoreboardsActionBar__search_custom_theme: {
        ...grid.space,
        position: 'relative',
        [mediaCSS.tabMini]: {
            ...grid.noSpace,
            ...grid.w100,
            display: 'block !important',
            borderBottom: `1px solid var(--var-module-ui-component-actionBar-style-separator, ${defaultThemeStyles.customThemeBorder})`,
        },
    },
    ScoreboardsActionBar__searchbar: {
        borderBottomLeftRadius: `${radiusSizes.mini}px!important`,
        borderTopLeftRadius: `${radiusSizes.mini}px!important`,
        borderBottomRightRadius: `0px!important`,
        borderTopRightRadius: `0px!important`,
        [mediaCSS.tabMini]: {
            borderBottomRightRadius: `${radiusSizes.mini}px!important`,
            borderTopRightRadius: `${radiusSizes.mini}px!important`,
        },
    },
    ScoreboardsActionBar__dropdown_type_selection: {
        borderBottomRightRadius: `${radiusSizes.mini}px`,
        borderTopRightRadius: `${radiusSizes.mini}px`,
    },
    ScoreboardsActionBar__dropdown_type_selection_status_active: {
        borderBottomRightRadius: `0px`,
    },
    ScoreboardsActionBar__dropdown_type_sort: {
        [mediaCSS.tabMini]: {
            borderBottomLeftRadius: `${radiusSizes.mini}px!important`,
            borderTopLeftRadius: `${radiusSizes.mini}px!important`,
        },
    },
}

addStyles(styles)
