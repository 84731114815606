import React from 'react'
import PropTypes from 'prop-types'

import TreeViewCatalogMethods from './local.methods'
import TreeViewCatalogEmpty from './TreeViewCatalogEmpty'
import { TreeViewCatalogList } from './TreeViewCatalogList'
import { TreeViewCatalogHeader } from './TreeViewCatalogHeader'
import LoaderLazy from 'blocks.app/loader/_lazy/loader__lazy'
import InfiniteScroll from 'react-infinite-scroller'

import treeViewCatalogTemplates from './treeViewCatalogTemplates'
import { treeViewHelpers } from 'core/helpers'

import { cn } from 'ethcss'
import styles from './styles'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

class TreeViewCatalog extends TreeViewCatalogMethods {
    renderList = () => {
        const p_ = this.props
        const s_ = this.state

        if (!this.loading && !s_.list.length) {
            return <TreeViewCatalogEmpty query={p_.params.query} template={p_.template} />
        }

        if (s_.list.length) {
            return (
                <InfiniteScroll
                    loadMore={() => this.get(true)}
                    hasMore={s_.list.length < s_.total}
                    initialLoad={false}
                    loader={<LoaderLazy key="catalog_loader" active={this.loading && s_.list.length > 0} />}
                    threshold={10}
                    useWindow={false}
                    getScrollParent={() => p_.scrollParent}
                >
                    <TreeViewCatalogList
                        list={s_.list}
                        type={p_.type}
                        onFolderOpening={this.onFolderOpening}
                        template={p_.template}
                        drag={p_.drag}
                        onDragStart={p_.onDragStart}
                        onDragEnd={p_.onDragEnd}
                        permissions={p_.permissions}
                        onChangePermissions={p_.onChangePermissions}
                    />
                </InfiniteScroll>
            )
        }

        return null
    }
    render() {
        const { wrapperClassName, classNameContent, params, type, template, viewParams } = this.props

        return (
            <div className={wrapperClassName}>
                <TreeViewCatalogHeader params={params} viewParams={viewParams} type={type} template={template} />
                <div className={cn(styles.treeViewCatalogWrapper, classNameContent)}>{this.renderList()}</div>
            </div>
        )
    }
}

const TreeViewCatalogWrapper = (p_) => {
    const query = getURLSearchParamsByLocation(p_.location)
    const params = treeViewHelpers.getQuery(p_.type, query)
    const template = treeViewCatalogTemplates(p_.type)
    const viewParams = treeViewHelpers.getViewParamsByUser(p_.type, p_.user)

    return <TreeViewCatalog {...p_} template={template} params={params} viewParams={viewParams} />
}

TreeViewCatalogWrapper.propTypes = {
    type: PropTypes.string,
    scrollParent: PropTypes.object,
    classNameContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    wrapperClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    drag: PropTypes.bool,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDragEnd: PropTypes.func,
    onChangePermissions: PropTypes.func,
    permissions: PropTypes.object,
}

TreeViewCatalogWrapper.defaultProps = {
    onDragStart: () => {},
    onDragEnd: () => {},
    onChange: () => {},
    onChangePermissions: () => {},
    drag: false,
    scrollParent: document.getElementById('contentScrollWrapper'),
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
    }
}

export default connect(mapStateToProps)(withRouter(TreeViewCatalogWrapper))
