import React from 'react'
import { Typography } from 'atoms/Typography'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import translate from 'core/translate'
import styles from './deviceSettings__ipTv.jcss'
import { Radio } from 'atoms/Radio'

export const IpTv = (p_: { enableIPTV: boolean; onChange: (value: boolean) => void; enabled: boolean }) => {
    return p_.enabled ? (
        <React.Fragment>
            <Typography className={cn(grid.mb_md, styles.title)} type="title">
                {translate('ipTv')}
            </Typography>
            <div className={styles.radioButtons}>
                <Radio
                    label={translate('on')}
                    selected={p_.enableIPTV}
                    className={grid.mr_md}
                    onClick={() => p_.onChange(true)}
                />
                <Radio label={translate('off')} selected={!p_.enableIPTV} onClick={() => p_.onChange(false)} />
            </div>
        </React.Fragment>
    ) : null
}
