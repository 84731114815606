import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { defaultThemeStyles } from './Searchbar-theme'
import { mediaCSS, textSizes } from 'theme/media'
import { IStyles } from '../../theme'

const searchbarStyles: IStyles = {
    wrapper: {
        ...grid.row,
        height: 39,
        [mediaCSS.tab]: {
            height: 32,
            position: 'relative',

            [mediaCSS.tabMini]: {
                height: 'auto',
                marginBottom: '11px',
            },
        },
    },
    input: {
        ...grid.w100,
        ...grid.pl_mdPlus,
        ...grid.pr_mini,
        fontSize: textSizes.normal,
    },
    search: {
        ...grid.space,
        position: 'relative',
    },
    viewFilter: {
        ...grid.w15,
        position: 'relative',
        [mediaCSS.tabMini]: {
            ...grid.space,
        },
    },
    sortFilter: {
        ...grid.w20,
        position: 'relative',
        [mediaCSS.tabMini]: {
            ...grid.space,
        },
    },
    selectionFilter: {
        ...grid.w15,
        position: 'relative',
        [mediaCSS.tabMini]: {
            ...grid.space,
        },
    },
    delimer: {
        ...grid.fullHeight,
        position: 'absolute',
        // borderLeft: `1px solid var(--var-module-ui-component-actionBar-style-separator, ${defaultThemeStyles.separator})`,
        width: 0,
        zIndex: 101,
    },
    marginLeft: {
        marginLeft: '2px',
    },
    searchbarContainer: {
        position: 'relative',
        display: 'flex',
        flex: 1,
        [mediaCSS.tabMini]: {
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 101,
            maxWidth: 36,
        },
        //transition: 'width 0.3s',
    },
    extraWidth: {
        [mediaCSS.tabMini]: {
            maxWidth: 76,
        },
    },
    button_type_withBorderV: {
        borderTop: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
        borderBottom: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
        color: `var(--var-module-ui-component-button-style-list-textMain, ${defaultThemeStyles['list-textMain']})`,
        background: `var(--var-module-ui-component-button-style-list-background, ${defaultThemeStyles['list-background']})`,
    },
    paddingTop: {
        paddingTop: '35px',
    },
    dropdownBtn: {
        [mediaCSS.tab]: {
            padding: 7,
            fontSize: 12,
        },
    },
    showSearch: {
        maxWidth: '100% !important',
        width: '100% !important',

        input: {
            padding: 8,
        },
    },
    gap: {
        display: 'none',

        [mediaCSS.tabMini]: {
            display: 'flex',
            minWidth: 36,
        },
    },
    extraGap: {
        display: 'none',

        [mediaCSS.tabMini]: {
            display: 'flex',
            minWidth: 76,
        },
    },
}

addStyles(searchbarStyles)
export default searchbarStyles
