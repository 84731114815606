import React, { Fragment, FunctionComponent, memo } from 'react'
import Collapse from 'blocks.simple/collapse/collapse'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { IInactivitySettingsComponent } from './InactivitySettings-types'
import { styles } from './InactivitySettings-styles'
import EditText from 'blocks.simple/editText/editText'
import helpers from 'core/helpers'
import { useInactivityTimeout } from './inactivitySettings-hooks'
import { constants } from 'core/constants'
import Button from 'blocks.simple/button/button'
import { SwitchButton } from 'atoms/SwitchButton'
import { isExist } from 'core/utils/coreUtil'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { useDispatch } from 'react-redux'
import { updateUserSettings } from 'blocks.app/user/user.state'
import styles_ from '../settings.jcss'

const InactivitySettingsComponent: FunctionComponent<IInactivitySettingsComponent> = ({ inactivityTimeoutS }) => {
    const dispatch = useDispatch()
    const { value, setInactivityTimeout } = useInactivityTimeout({ initialValue: inactivityTimeoutS })
    const title = translate('inactivitySettings')

    const isValidInactivityTimeout = (val: number | null): boolean => {
        if (val === null) return true

        let isValidationError = val > constants.MAX_INACTIVITY_TIMEOUT_S || val < constants.MIN_INACTIVITY_TIMEOUT_S

        if (isValidationError) {
            dispatch(emitError(translate('inactivitySettingsTextValidationError')))
            return false
        }

        return true
    }

    const saveInactivitySetting = () => {
        if (isValidInactivityTimeout(value)) {
            dispatch(updateUserSettings({ data: { inactivityTimeoutS: value }, isSaveSettings: true }))
        }
    }

    const onChange = (val: number) => {
        setInactivityTimeout(val)
    }

    const handleChangeInactivitySettingsState = () => {
        if (value) {
            dispatch(updateUserSettings({ data: { inactivityTimeoutS: null }, isSaveSettings: true }))
        } else {
            dispatch(
                updateUserSettings({
                    data: { inactivityTimeoutS: constants.MIN_INACTIVITY_TIMEOUT_S },
                    isSaveSettings: true,
                })
            )
        }
    }

    const isEnableInactivityTimeout = () => isExist(value)

    const renderInactivityControls = () => {
        return (
            <Fragment>
                <div className={styles.inactivitySettings__actions}>
                    <div className={styles.inactivitySettings__editText}>
                        <EditText
                            className={styles.inactivitySettings__input}
                            value={value}
                            rounded={'full_md'}
                            indentSize={'mini'}
                            type={'number'}
                            mod="withLightBorder"
                            disabled={!helpers.isAvailable({ key: 'settings', action: 'update' })}
                            onChange={onChange}
                        />
                    </div>
                    <div className={styles.inactivitySettings__button}>
                        <Button mod="fill" onClick={saveInactivitySetting}>
                            {translate('save')}
                        </Button>
                    </div>
                </div>
                <div className={styles.inactivitySettings__info}>
                    *{' '}
                    {translate('inactivitySettingsTextInfo', [
                        `${constants.MAX_INACTIVITY_TIMEOUT_S}`,
                        `${constants.MIN_INACTIVITY_TIMEOUT_S}`,
                    ])}
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Collapse title={() => title} className={cn(styles_.background, styles.inactivitySettings)}>
                <div className={styles.inactivitySettings__content}>
                    <div className={styles.inactivitySettings__text}>{translate('inactivitySettingsText')}</div>
                    <div className={styles.inactivitySettings__switch}>
                        <SwitchButton
                            onChange={handleChangeInactivitySettingsState}
                            checked={isEnableInactivityTimeout()}
                        />
                    </div>
                    {isEnableInactivityTimeout() && renderInactivityControls()}
                </div>
            </Collapse>
        </Fragment>
    )
}

export const InactivitySettings = memo(InactivitySettingsComponent)
