import { colors, font } from 'theme'

export const defaultThemeStyles = {
    background: colors.white,
    backgroundSub: colors.grey,
    darkBackground: colors.blackLight,
    lightBackground: colors.clouds,
    placeholder: colors.grey,
    border: colors.greyLight,
    borderContrast: colors.blackLight,
    borderActive: colors.darkCloud,
    textMain: colors.blackLight,
    textSub: colors.black,
    fontFamily: font.main,
    active: colors.black,
    disable: colors.grey,
    shadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
    panel: colors.white,
    error: colors.error,
    warning: colors.warning,
    online: colors.online,
    offline: colors.offline,
    displayOff: colors.displayOff,
    notSynchronized: colors.notSynchronized,
}
