import React, { FC } from 'react'
import Modal from 'blocks.simple/modal/modal'
import Icon from 'blocks.simple/icon/icon'
import { styles } from './SelectMapDialog-styles'
import translate from 'core/translate'
import { SelectMapDialogProps } from './SelectMapDialog-types'
import { MapCreator } from 'features/map/controllers/MapCreator'

const mapFactory = MapCreator.getInstance().getMapFactory()
const SearchableMap = mapFactory.createSearchableMap()
const mapApi = mapFactory.createMapApi()

export const SelectMapDialog: FC<SelectMapDialogProps> = ({
    isOpen,
    location,
    center,
    onChangeLocation,
    onChangePlacemarkGeometry,
    onChangeAddress,
    onClose,
}) => {
    const renderMap = () => {
        return (
            <SearchableMap
                center={center}
                location={location}
                onChangeLocation={onChangeLocation}
                onChangeAddress={onChangeAddress}
                onChangePlacemarkGeometry={onChangePlacemarkGeometry}
                mapApi={mapApi}
            />
        )
    }

    return (
        <Modal open={isOpen} showCloseIcon={false} mod="content">
            <div className={styles.selectMapDialog__close} onClick={onClose}>
                <Icon type="minimize" color="black" size={28} tooltip={{ title: translate('collapse') }} />
            </div>
            <div className={styles.selectMapDialog__map}>{renderMap()}</div>
        </Modal>
    )
}
