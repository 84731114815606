import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import DndDraggable from 'blocks.app/dnd/_draggable/dnd_draggable'
import DndDroppable from 'blocks.app/dnd/_droppable/dnd_droppable'

import helpers from 'core/helpers'

import { cn } from 'ethcss'
import styles from './styles'

class ListInner extends React.PureComponent {
    constructor(p_) {
        super(p_)

        this.state = {
            isHovered: false,
            dragData: p_.getDragData({ item: p_.listItemData.listItem }),
        }
    }
    static getDerivedStateFromProps(p_) {
        return {
            dragData: p_.getDragData({ item: p_.listItemData.listItem }),
        }
    }
    onDragOver = () => {
        const s_ = this.state

        if (!s_.isHovered) {
            this.setState({ isHovered: true })
        }
    }
    onDragLeave = () => {
        const s_ = this.state

        if (s_.isHovered) {
            this.setState({ isHovered: false })
        }
    }
    onSelectInfo = (index) => {
        const p_ = this.props
        if (p_.onSelectInfo) {
            p_.onSelectInfo(index)
        }
    }
    onDrop = (data, e) => {
        e.stopPropagation()

        const p_ = this.props
        const droppedItem = JSON.parse(data.dnd_item)

        if (p_.template.multipleMove && p_.multipleSelect && p_.selectedItems.length) {
            const droppedItemInSelected = p_.selectedItems.filter((selectedItem) =>
                p_.template.isEqual(selectedItem, droppedItem)
            )

            if (droppedItemInSelected.length) {
                this.onMoveMultiple()
                return
            }
        }

        const { listItem } = p_.listItemData
        if (p_.template.isEqual(droppedItem, listItem)) {
            this.setState({ isHovered: false })
            return
        }
        const moveMethod = p_.isFolder(droppedItem) ? p_.template.moveFolder : p_.template.moveItem

        moveMethod(droppedItem.id, listItem.id)
        this.setState({ isHovered: false })
    }
    onMoveMultiple = () => {
        const p_ = this.props
        const listItem = p_.listItemData.listItem
        const sourceId = []
        const folderId = []

        p_.selectedItems.forEach((item) => {
            if (!p_.template.isEqual(item, listItem)) {
                p_.isFolder(item) ? folderId.push(item.id) : sourceId.push(item.id)
            }
        })

        p_.template.multipleMove(sourceId, folderId, listItem.id)
        this.setState({ isHovered: false })
    }
    render() {
        const p_ = this.props
        const s_ = this.state
        const { listItem, index, folderKey, options } = p_.listItemData
        const active =
            s_.isHovered || (p_.selectedInfo && p_.isEqual(p_.selectedInfo, listItem)) || listItem.__view.selected
        const isFolder = p_.isFolder(listItem)

        return (
            <DndDroppable
                tag="tr"
                data={s_.dragData}
                dragEnabled={p_.drag}
                dropEnabled={isFolder}
                wrapperComponent={{
                    type: DndDraggable,
                }}
                onDragStart={p_.onDragStart}
                onDragEnd={p_.onDragEnd}
                onDrop={this.onDrop}
                onClick={(e) => {
                    e.stopPropagation()

                    if (helpers.isDblTouchTap(e)) {
                        if (typeof p_.onSelectFolder === 'function' && p_.isFolder(listItem)) {
                            p_.onSelectFolder(listItem)
                        } else {
                            p_.onDoubleClickObject(listItem)
                        }
                        return
                    }

                    if (p_.multiSelect) {
                        p_.onSelect({ selected: !listItem.__view.selected, item: listItem })
                        return
                    }

                    this.onSelectInfo(index)
                }}
                className={cn({ [styles.catalogListItemRowActive]: active })}
            >
                {p_.multiSelect && (
                    <td className={styles.catalogListTableCellCheckbox}>
                        <Checkbox
                            checked={listItem.__view.selected}
                            onChange={(selected, e) => {
                                e.stopPropagation()
                                p_.onSelect({ selected, item: listItem })
                            }}
                            size={24}
                        />
                    </td>
                )}
                {options.map((option, index) => (
                    <td
                        key={`catalogListItem_${folderKey}_${listItem.id}_${index}`}
                        className={cn({ [styles.borderedCell]: !option.hiddenTitle })}
                    >
                        <div className={styles.tableCell}>
                            {p_.getCellValue(listItem, option.id, active, p_.nestedLevel)}
                        </div>
                    </td>
                ))}
            </DndDroppable>
        )
    }
}

const CatalogList = (p_) => {
    const getCellValue = (listItem, id, active, nestedLevel) => {
        const cellRender = p_.template.list

        if (cellRender && cellRender[id]) {
            return cellRender[id](listItem, active, p_, nestedLevel)
        }

        const value = listItem[id]

        if (typeof value === 'object') {
            return null
        }
        return value
    }

    const thStyle = {
        width: `${100 / p_.options.length}%`,
    }

    return (
        <table className={cn(styles.catalogListTableWrapper, p_.template.tableWrapper)}>
            <thead>
                <tr>
                    {p_.multiSelect && <th />}
                    {p_.options.map((option, index) => (
                        <th
                            key={`catalog_list_header_option_${index}`}
                            style={thStyle}
                            className={cn(styles.catalogListHeaderItem, {
                                [styles.catalogListHeaderCol]: option.hiddenTitle,
                                [styles.borderedCell]: !option.hiddenTitle,
                            })}
                        >
                            {option.name}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody className={styles.catalogListTableBody}>
                {p_.list.map((elem, index) => {
                    const isFolder = p_.isFolder(elem) ? 'folder' : 'item'

                    return (
                        <ListInner
                            {...p_}
                            getCellValue={getCellValue}
                            key={`catalog_list_row_${elem.id}_${index}`}
                            listItemData={{ listItem: elem, index, isFolder, options: p_.options }}
                        />
                    )
                })}
            </tbody>
        </table>
    )
}

CatalogList.propTypes = {
    options: PropTypes.array,
    multiSelect: PropTypes.bool,
    list: PropTypes.array,
    type: PropTypes.string,
    onSelectInfo: PropTypes.func,
    isEqual: PropTypes.func,
    isFolder: PropTypes.func,
    selectedInfo: PropTypes.object,
    onDoubleClickObject: PropTypes.func,
    onSelect: PropTypes.func,
    template: PropTypes.object,
}

export default CatalogList
