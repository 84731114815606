import { addStyles } from 'ethcss'
import { colors, grid, IStyles } from 'theme'
import { mediaCSS, textSizes } from 'theme/media'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
const mediaHeightQuery = '@media (max-height: 960px)'
const mediaWidth600pxQuery = '@media (max-width: 600px)'

const styles: IStyles = {
    wrapper: {
        ...grid.col,
        ...grid.justify,
        ...grid.full,
        ...grid.p_md,
    },
    errorInputText: {
        color: 'red',
        fontSize: '12px',
    },
    deviceParametersWrapper: {
        ...grid.col,
        ...grid.justify,
        ...grid.full,
        ...grid.p_md,
        [mediaCSS.tabMini]: {
            height: 'auto',
        },
        backgroundColor: `var(--var-module-app-component-app-style-panel, ${defaultThemeAppStyles.panel})`,
    },
    noBoxShadow: {
        boxShadow: 'none !important',
    },
    w100: {
        width: 'auto !important',
    },
    formWrapper: {
        ...grid.w50,
        ...grid.row,
        [mediaWidth600pxQuery]: {
            height: '100%',
        },
        [mediaCSS.tab]: {
            ...grid.w100,
        },
    },
    form: {
        ...grid.row,
        ...grid.w100,
    },
    title: {
        fontWeight: 'bold',
        display: 'block',
        width: '100%',
        textAlign: 'center',
        fontSize: textSizes.mdPlus,
        [mediaCSS.tabMini]: {
            ...grid.mb_md,
        },
    },
    inputLabel: {
        ...grid.mb_micro,
        textTransform: 'uppercase',
        display: 'block',
        width: '100%',
        textAlign: 'center',
        fontSize: textSizes.normalPlus,
    },
    content: {
        ...grid.colCenter,
        ...grid.full,
        [mediaCSS.tabMini]: {
            ...grid.mb_md,
        },
        [mediaHeightQuery]: {
            overflowY: 'auto',
        },
    },
    descriptionWrapper: {
        ...grid.w50,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    leftColumnContent: {
        ...grid.col,
        ...grid.w45,
        ...grid.mr_md,
        [mediaCSS.tabMini]: {
            ...grid.w100,
            ...grid.mr_none,
        },
    },
    rightColumnContent: {
        ...grid.w50,
        [mediaCSS.tabMini]: {
            ...grid.w100,
            ...grid.row,
        },
    },
    footer: {
        ...grid.row,
        ...grid.center,
        [mediaCSS.tabMini]: {
            ...grid.mb_md,
        },
    },
    displaysAddVirtualDevice__inputWrapper_type_tags: {
        ...grid.mb_micro,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    disabled: {
        opacity: 0.5,
    },
    noLicensesLeft: {
        display: 'flex',
        marginTop: 14,
        color: colors.notificationRed,

        span: {
            marginLeft: 10,
            fontSize: 11,
        },
    },
}

addStyles(styles)
export default styles
