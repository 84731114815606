import React from 'react'
import CatalogBreadcrumbsMethods, { IBreadcrumbsProps } from './catalog__breadcrumbs.local.methods'
import Link from 'blocks.simple/link/link'
import Icon from 'blocks.simple/icon/icon'
import DndDroppable from 'blocks.app/dnd/_droppable/dnd_droppable'
import { cn } from 'ethcss'
import { indents } from 'blocks.app/config'
import styles from './catalog__breadcrumbs.jcss'
import helpers from 'core/helpers'
import helpersCore from 'core/helpers'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from './catalog__breadcrumbs.theme'
import { getURLSearchParamsByLocation, getURLSearchParamsString } from 'features/routes/utils'
import { useLocation } from 'react-router'
import { IBreadcrumbsItem } from '../../../core/models/Breadcrumbs'

const Item = (p_: IBreadcrumbsProps) => {
    const { s_, methods } = CatalogBreadcrumbsMethods(p_)
    // @ts-ignore
    const { pathname, index, breadcrumb } = p_.listItemData
    const maxBreadcrumbDepth = p_.list.length - 1
    const active = index === maxBreadcrumbDepth

    let breadcrumbName = helpersCore.getBreadcrumbsItemName(breadcrumb.name, index)

    return (
        <DndDroppable onDrop={methods.onDrop} onDragOver={methods.onDragOver} onDragLeave={methods.onDragLeave}>
            <Link
                className={cn(styles.item, styles.indents, {
                    [styles.itemDropActive]: s_.isHovered && !active && breadcrumb.id,
                    [styles.active]: active,
                })}
                to={{
                    pathname: `/${pathname}`,
                    search: getURLSearchParamsString({
                        ...p_.query,
                        [`${p_.type}__query`]: undefined,
                        [p_.filterName]: breadcrumb.id,
                    }),
                }}
            >
                <Icon
                    type={index === 0 ? 'home' : 'group_files_v1'}
                    size={18}
                    color={
                        active
                            ? getThemeStyleValue('breadcrumbs', 'icon', 'active') || defaultThemeStyles.active
                            : getThemeStyleValue('breadcrumbs', 'icon', 'disable') || defaultThemeStyles.disable
                    }
                    style={{
                        marginRight: indents.mini,
                    }}
                />
                <div className={styles.text}>{breadcrumbName}</div>
            </Link>
        </DndDroppable>
    )
}

const Breadcrumbs = (p_: IBreadcrumbsProps) => {
    const location = useLocation()
    const pathname = helpers.getPathname(location.pathname)
    const query = getURLSearchParamsByLocation(location)

    const renderBreadcrumbList = () =>
        p_.list.map((breadcrumb, index) => {
            const isFirstItem = index === 0
            const maxBreadcrumbDepth = p_.list.length - 1
            const active = index === maxBreadcrumbDepth

            return (
                <div key={`breadcrumbs__item_${index}`} className={styles.itemWrapper}>
                    {!isFirstItem && (
                        <div className={cn(styles.item, styles.itemArrow)}>
                            <Icon
                                type="arrow_right"
                                color={
                                    active
                                        ? // ? getThemeStyleValue('app', 'menu', 'active') || defaultThemeStyles.active
                                          defaultThemeStyles.active
                                        : getThemeStyleValue('app', 'menu', 'disable') || defaultThemeStyles.disable
                                }
                                default={true}
                            />
                        </div>
                    )}
                    <Item
                        {...p_}
                        query={query}
                        listItemData={{
                            pathname,
                            index,
                            breadcrumb,
                        }}
                    />
                </div>
            )
        })

    return (
        <div className={styles.breadcrumbsWrapper}>
            <div className={styles.contentScroll}>{renderBreadcrumbList()}</div>
        </div>
    )
}

export default Breadcrumbs
