import { addStyles } from 'ethcss'
import { grid, IStyles } from '../../../theme'
import { defaultThemeStyles } from '../Range-theme'

const rangeVolumeStyles: IStyles = {
    wrapper: {
        ...grid.rowCenter,
        ...grid.w100,
        ...grid.pt_microPlus,
        ...grid.pb_microPlus,
        paddingTop: '20px',
    },
    icon: {
        ...grid.p_mini,
    },
    range: {
        ...grid.space,
        position: 'relative',
    },
    counter: {
        position: 'absolute',
        color: `var(--var-module-ui-component-range-style-counter, ${defaultThemeStyles.counter})`,
        transform: 'translate(-50%)',
        top: -20,
    },
    disabled: {
        opacity: 0.5,
        pointerEvents: 'none',
    },
}

addStyles(rangeVolumeStyles)
export default rangeVolumeStyles
