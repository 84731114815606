import { colors } from 'theme'

export const defaultThemeTableStyles = {
    background: colors.white,
    evenLine: colors.greyLight,
    thead: colors.greyLight,
    text: colors.grey,
    border: colors.white,
    borderTop: colors.cloud,
    selected: colors.grey,
    'selected-text': colors.white,
    cellBorder: colors.cloud,
}
