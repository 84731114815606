import React from 'react'
import enhanceWithClickOutside from 'react-click-outside'
import Button from 'blocks.simple/button/button'
import Icon from 'blocks.simple/icon/icon'
import Tag from 'blocks.simple/tag/tag'
import SelectListItem from '../selectListItem/selectListItem'
import DropdownSearchable from './__searchable/dropdown__searchable'
import DropdownMethods from './dropdown.local.methods'
import { Scrollbars } from 'react-custom-scrollbars'
import helpers from 'core/helpers'
import { cn } from 'ethcss'
import { grid, item } from 'theme'
import translate from 'core/translate'
import { text as textStyles } from 'theme'
import styles from './dropdown.jcss'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from './dropdown.theme'
import { themeFromStore } from 'core/helpers/menu'
import { history } from 'features/routes'

class Dropdown extends DropdownMethods {
    dropdownContent: HTMLDivElement | null = null
    wrapper: HTMLDivElement | null = null
    customContent: HTMLDivElement | null = null

    renderTree = (items: any) => {
        const folders = helpers.deepCopy(items)
        // eslint-disable-next-line
        const nested = folders.filter((elt: any, idx: number, arr: any) => {
            const parent = arr.find((e: { id: number }) => e.id === elt[elt.nestedKey])
            if (!parent) return true
            ;(parent.children = parent.children || []).push(elt)
        })

        const s_ = this.state
        const p_ = this.props
        const elements: React.ReactNode[] = []
        let index = 0

        const loopOptions = (data: any, nestedLevel: number) => {
            data.forEach((elem: { [index: string]: string }) => {
                const disabled = !elem.availableDestinationQ
                const selected = elem[p_.valueField] === s_.selected.id

                elements.push(
                    <li
                        key={index}
                        style={{
                            paddingLeft: 20 * nestedLevel,
                        }}
                        onClick={(e) => {
                            if (disabled) {
                                return
                            }

                            this._onChange(e, elem)
                        }}
                        className={cn(styles.list, styles.fontColor, styles[`list_type_${p_.mod}`], textStyles.left, {
                            [styles[`list_type_${p_.mod}_activated`]]: selected,
                            [styles[`list_type_${p_.mod}_disabled`]]: !selected,
                            [styles.disabled]: disabled,
                        })}
                    >
                        {elem.name}
                    </li>
                )

                index++

                if (elem.children) {
                    const counter = nestedLevel + 1
                    loopOptions(elem.children, counter)
                }
            })
        }

        loopOptions(nested, 0)
        return elements
    }
    static defaultProps: {
        mod: string
        textSize: string
        centered: boolean
        placeholderAsValue: boolean
        notFilterOnSearch: boolean
        disableAddNewTags: boolean
        hideNotFound: boolean
        valueField: string
        searchable: boolean
        labelField: string
        multiselect: boolean
        noFocus?: boolean
        onBlur: () => void
        inheritIconColor: boolean
        rounded: string
        id: string
        hmsState?: {
            h?: boolean
            m?: boolean
            s?: boolean
        }
        indentSize: string
        buttonClassName: string
        iconClassName: string
        hiddenOverflow: boolean
    }

    isBorderMode(mode: string) {
        return mode === 'withBorderV' || mode === 'withBorderVR'
    }

    render() {
        let list, selected: boolean
        const p_ = this.props
        const s_ = this.state
        const active = this.isActive()

        const {
            mod,
            indentSize,
            children,
            textSize,
            label,
            customHeader,
            labelField,
            valueField,
            options,
            searchable,
        } = p_

        const dropdownWrapper = cn(
            p_.textSize ? textStyles[p_.textSize] : '',
            styles.wrapper,
            {
                [styles.active]: active,
                [item.rel]: !p_.multiselect && !p_.multiselectItems && searchable,
            },
            p_.className
        )

        const labelClassName = cn(styles.label, p_.textSize ? textStyles[p_.textSize] : '')

        if (s_.opt) {
            this.filtered = [...s_.opt]

            if (p_.searchable) {
                if (s_.inputValue && s_.inputValue.length > 0) {
                    this.filtered = this.filterOptions(s_.inputValue)
                }
            }
            list =
                p_.type === 'tree' && !s_.inputValue
                    ? this.renderTree(this.filtered)
                    : this.filtered.map((option: any, index: number) => {
                          if (s_.selected) {
                              selected = option[valueField] === s_.selected.id
                          }

                          let iconColor = selected ? p_.iconActiveColor : p_.iconColor

                          if (p_.multiselectItems) {
                              return (
                                  <SelectListItem
                                      className={cn(
                                          styles.tag,
                                          p_.textSize ? textStyles[p_.textSize] : '',
                                          item.pointer
                                      )}
                                      onClick={() => this._selectedMulti(option)}
                                      key={`optionList_${index}`}
                                      value={option.label}
                                  />
                              )
                          }
                          if (p_.multiselect) {
                              return (
                                  <Tag
                                      className={cn(
                                          styles.tag,
                                          p_.textSize ? textStyles[p_.textSize] : '',
                                          item.pointer
                                      )}
                                      onClick={() => this._selectedMulti(option)}
                                      key={`optionList_${index}`}
                                      value={option.label}
                                  />
                              )
                          } else {
                              const isNotHtml = typeof option[labelField] !== 'object'
                              const location = { ...history.location }
                              return (
                                  <li
                                      key={`option-list${index}`}
                                      label={isNotHtml ? option[labelField] : ''}
                                      onClick={(e) => this._onChange(e, option)}
                                      className={cn(
                                          styles.list,
                                          themeFromStore() === 'custom' && location.pathname === '/files/videoEditor'
                                              ? styles['list_type_custom_theme_in_video_editor']
                                              : location.pathname === '/timetable'
                                              ? styles['list_type_custom_theme_in_video_editor']
                                              : styles[`list_type_${mod}`],
                                          {
                                              [styles[`list_type_${mod}_activated`]]: selected,
                                              [styles[`list_type_${mod}_disabled`]]: !selected,
                                              [grid.center]: p_.centered || p_.small,
                                          }
                                      )}
                                  >
                                      {option.icon && (
                                          <div className={cn(styles.icon, { [grid.pr_micro]: option[labelField] })}>
                                              <Icon
                                                  type={option.icon}
                                                  color={iconColor}
                                                  size={option.iconSize ? option.iconSize : 14}
                                              />
                                          </div>
                                      )}
                                      {!p_.small && isNotHtml && (
                                          <span className={cn(p_.labelClassName)}>
                                              {option[labelField]}
                                              {option.desc && option.desc}
                                          </span>
                                      )}
                                      {!p_.small && !isNotHtml && option[labelField]}
                                  </li>
                              )
                          }
                      })
        }

        let content
        const location = { ...history.location }

        if (!children && options) {
            content = (
                <div
                    ref={(dropdownContent) => (this.dropdownContent = dropdownContent)}
                    className={cn(
                        styles.content,
                        styles[`content_type_withLightBorder`],
                        `dropdown_${s_.position}Content`,
                        item[`rounded_${s_.position}_${this.roundedMod}`],
                        { [styles.relative]: p_.isRelativeList }
                    )}
                    style={s_.active && s_.position === 'top' ? { bottom: this.wrapper?.clientHeight } : {}}
                >
                    <Scrollbars autoHeight autoHeightMax={250} className={styles.overflow}>
                        <div
                            className={cn(styles.textMob, item[`indent_${indentSize}`], {
                                [styles.textMobForSearchbar]: p_.textMobPadding,
                            })}
                        >
                            {list}
                            {!p_.multiselect &&
                                !p_.multiselectItems &&
                                searchable &&
                                this.filtered.length === 0 &&
                                !p_.hideNotFound && <div>{translate('notFound')}</div>}
                        </div>
                    </Scrollbars>
                </div>
            )
        }

        if (children) {
            content = (
                <div
                    ref={(customContent) => (this.customContent = customContent)}
                    className={cn(styles.customContent)}
                    style={s_.active && s_.position === 'top' ? { bottom: this.wrapper?.clientHeight } : {}}
                >
                    {children}
                </div>
            )
        }

        return (
            <div
                className={cn(styles.wrapperFull, p_.containerClassName, styles[`wrapperFull_type_${mod}`], {
                    [styles.wrapperFullOpen]: s_.active,
                    [styles.hoverIconWrapper]: !s_.active && p_.showIconOnHover,
                })}
            >
                {label && <label className={labelClassName}>{label}</label>}
                <div className={dropdownWrapper} ref={(wrapper) => (this.wrapper = wrapper)}>
                    {s_.position === 'top' && content}
                    {!customHeader && !searchable && (
                        <Button
                            mod={
                                location.pathname === '/timetable'
                                    ? 'secondary'
                                    : themeFromStore() === 'custom' &&
                                      (location.pathname === '/files/videoEditor' ||
                                          location.pathname === '/addContentToDevice' ||
                                          location.pathname === '/scoreboards/add' ||
                                          location.pathname === '/displays' ||
                                          location.pathname === '/broadcasts/edit' ||
                                          location.pathname === '/reports' ||
                                          location.pathname === '/broadcasts/addAdvanced' ||
                                          location.pathname === '/history') &&
                                      !p_.icon
                                    ? 'custom_theme_in_video_editor'
                                    : p_.mod === 'withShadow'
                                    ? 'list'
                                    : this.isBorderMode(p_.mod) && themeFromStore() !== 'custom'
                                    ? p_.mod
                                    : themeFromStore() === 'custom'
                                    ? p_.mod === 'scheduleButtonBorder'
                                        ? p_.mod
                                        : 'withLightBorder'
                                    : 'withBorder'
                            }
                            // isActive={s_.active}
                            onClick={this._onToggleClick}
                            icon={p_.icon ? p_.icon : active ? 'arrow_up' : 'arrow_down'}
                            iconColor={active ? p_.iconActiveColor : p_.iconColor}
                            iconSize={p_.iconSize}
                            rounded={
                                active && !p_.disableChangeRounded
                                    ? `${s_.position === 'bottom' ? 'top' : 'bottom'}_${this.roundedMod}`
                                    : p_.rounded
                            }
                            iconPos={p_.icon ? 'left' : 'right'}
                            fullWidth={true}
                            textSize={p_.textSize}
                            indentSize={indentSize}
                            disabled={p_.disabled}
                            centered={p_.centered}
                            animation={false}
                            small={p_.small}
                            className={cn(
                                { [styles.content_type_fill]: p_.mod === 'fill' },
                                { [styles[`${s_.position}ActiveButton`]]: active },
                                p_.buttonClassName
                            )}
                            showIconOnHover={!s_.active && p_.showIconOnHover}
                        >
                            {(s_.selected.name || p_.placeholder) && <span>{s_.selected.name || p_.placeholder}</span>}
                        </Button>
                    )}
                    {!customHeader && searchable && p_.type !== 'hmsTime' && (
                        <div
                            className={cn(
                                styles.searchable,
                                styles[`searchable_type_${mod}`],
                                active
                                    ? item[`rounded_${s_.position === 'bottom' ? 'top' : 'bottom'}_${this.roundedMod}`]
                                    : item[`rounded_${p_.rounded}`],
                                item[`indent_${indentSize}`],
                                {
                                    [item.ellipsis]: !p_.multiselect && !p_.multiselectItems,
                                    [styles.validationBorder]: p_.validationBorder,
                                }
                            )}
                            onClick={() => {
                                this.searchable?.focus()
                                if (!p_.multiselect && !p_.multiselectItems && !p_.disabled) {
                                    this.setState({ inputValue: p_.placeholderAsValue ? p_.value : '' })
                                }
                            }}
                        >
                            {p_.multiselectItems &&
                                s_.selectedList &&
                                s_.selectedList.map((item: { label: string; name: string }, index: number) => (
                                    <SelectListItem
                                        className={cn(styles.tag, p_.textSize ? textStyles[p_.textSize] : '')}
                                        key={`selectedItem_${index}`}
                                        value={item.label}
                                        onIconClick={() => this.removeTagItem(item.name)}
                                    />
                                ))}
                            {p_.multiselect &&
                                s_.selectedList &&
                                s_.selectedList.map((item: { label: string; name: string }, index: number) => (
                                    <Tag
                                        className={cn(styles.tag, p_.textSize ? textStyles[p_.textSize] : '')}
                                        key={`selectedItem_${index}`}
                                        value={item.label}
                                        onIconClick={() => this.removeTagItem(item.name)}
                                    />
                                ))}
                            <DropdownSearchable
                                disabled={p_.disabled}
                                placeholderText={active ? null : p_.placeholder}
                                className={cn({ [grid.row]: !p_.multiselect && !p_.multiselectItems })}
                                inputClassName={cn(
                                    styles.fontColor,
                                    styles.inputTransparent,
                                    item[`indent_tb_${indentSize}`],
                                    {
                                        [item.ellipsisWrapper]: !p_.multiselect,
                                        [item.disabled]: p_.disabled,
                                        [textStyles.center]: p_.centered,
                                    },
                                    p_.textSize ? textStyles[p_.textSize] : '',
                                    p_.searchableInputClassName
                                )}
                                id={p_.id}
                                value={s_.inputValue ? s_.inputValue : ''}
                                ref={(input) => (this.searchable = input)}
                                onChange={this.searchableChange}
                                onFocus={this.searchableChange}
                                onBlur={this.searchableBlur}
                                style={
                                    !p_.multiselect && !p_.multiselectItems && searchable
                                        ? { paddingRight: p_.iconSize ? p_.iconSize : 14 }
                                        : p_.hiddenOverflow
                                        ? { overflow: 'hidden' }
                                        : null
                                }
                                wrapperWidth={p_.wrapperWidth}
                                mask={p_.mask}
                                type={p_.type}
                            />
                        </div>
                    )}
                    {p_.type === 'hmsTime' && (
                        <div
                            className={cn(
                                styles.searchable,
                                styles[`searchable_type_${mod}`],
                                active
                                    ? item[`rounded_${s_.position === 'bottom' ? 'top' : 'bottom'}_${this.roundedMod}`]
                                    : item[`rounded_${p_.rounded}`],
                                item[`indent_${indentSize}`],
                                {
                                    [item.ellipsis]: !p_.multiselect && !p_.multiselectItems,
                                    [styles.validationBorder]: p_.validationBorder,
                                }
                            )}
                        >
                            <DropdownSearchable
                                disabled={p_.disabled}
                                placeholderText={active ? null : p_.placeholder}
                                className={cn({ [grid.row]: !p_.multiselect && !p_.multiselectItems })}
                                inputClassName={cn(
                                    styles.fontColor,
                                    styles.inputTransparent,
                                    styles.hmsStyle,
                                    item[`indent_tb_${indentSize}`],
                                    {
                                        [item.ellipsisWrapper]: !p_.multiselect,
                                        [item.disabled]: p_.disabled,
                                        [textStyles.center]: p_.centered,
                                    },
                                    p_.textSize ? textStyles[p_.textSize] : ''
                                )}
                                id={p_.id}
                                value={p_.value ? p_.value : s_.inputValue}
                                ref={(input) => (this.searchable = input)}
                                onChange={(e) => {
                                    this.setState({ inputValue: p_.placeholderAsValue ? e.target.value : '' })
                                    p_.onChange(e.target.value)
                                }}
                                style={
                                    !p_.multiselect && !p_.multiselectItems && searchable
                                        ? { paddingRight: p_.iconSize ? p_.iconSize : 14 }
                                        : null
                                }
                                wrapperWidth={p_.wrapperWidth}
                                type={'number'}
                            />
                        </div>
                    )}
                    {!p_.multiselect && !p_.multiselectItems && searchable && (
                        <div
                            className={cn(
                                styles.iconSearchable,
                                grid.pl_micro,
                                item[`indent_${indentSize}`],
                                item.abs,
                                { [item.disabled]: p_.disabled },
                                p_.iconClassName
                            )}
                            onClick={p_.scheduleMode ? () => {} : this._onToggleClick}
                        >
                            {p_.type === 'hmsTime' && (
                                <div className={cn(styles.hmsTime)}>
                                    <span
                                        onClick={() => {
                                            p_.onChangeTimeFormat && p_.onChangeTimeFormat('h')
                                        }}
                                        className={cn({ [styles.hmsActive]: p_.hmsState && p_.hmsState.h })}
                                    >
                                        {translate('hour')}
                                    </span>
                                    &nbsp;
                                    <span
                                        onClick={() => {
                                            p_.onChangeTimeFormat && p_.onChangeTimeFormat('m')
                                        }}
                                        className={cn({ [styles.hmsActive]: p_.hmsState && p_.hmsState.m })}
                                    >
                                        {translate('min')}
                                    </span>
                                    &nbsp;
                                    <span
                                        onClick={() => {
                                            p_.onChangeTimeFormat && p_.onChangeTimeFormat('s')
                                        }}
                                        className={cn({ [styles.hmsActive]: p_.hmsState && p_.hmsState.s })}
                                    >
                                        {translate('sec')}
                                    </span>
                                </div>
                            )}
                            {!p_.isNotDropdown && (
                                <Icon
                                    // onClick={(e) => this._onToggleClickhHms(e)}
                                    onClick={(e) => this._onToggleClickhHmsMode(e, p_, p_.scheduleMode)}
                                    type={p_.icon ? p_.icon : active ? 'arrow_up' : 'arrow_down'}
                                    color={
                                        p_.iconColor
                                            ? p_.iconColor
                                            : getThemeStyleValue('ui', 'dropdown', `${p_.mod}_icon`) ||
                                              defaultThemeStyles.icon
                                    }
                                    size={p_.iconSize ? p_.iconSize : 14}
                                    disabled={p_.disabled}
                                />
                            )}
                        </div>
                    )}
                    {customHeader && <div onClick={(e) => this._onToggleClick(e)}>{customHeader}</div>}
                    {s_.position === 'bottom' && content}
                </div>
            </div>
        )
    }
}

Dropdown.defaultProps = {
    id: 'autosize_input',
    mod: 'default',
    rounded: 'full_micro',
    valueField: 'id',
    labelField: 'name',
    indentSize: 'normal',
    textSize: 'normal',
    noFocus: false,
    multiselect: false,
    hideNotFound: false,
    searchable: false,
    notFilterOnSearch: false,
    inheritIconColor: false,
    placeholderAsValue: false,
    centered: false,
    disableAddNewTags: false,
    onBlur: () => {},
    hmsState: {
        h: false,
        m: false,
        s: true,
    },
    buttonClassName: '',
    iconClassName: '',
    hiddenOverflow: false,
}

export default enhanceWithClickOutside(Dropdown)
