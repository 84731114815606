export const constants = {
    FILE_UPLOAD_CSV_DELIMITER: ',',
    static: {
        displays: {
            AUDIO_PLACEHOLDER_GIF: 'images/displays/audio_placeholder.gif',
        },
    },
    MIN_INACTIVITY_TIMEOUT_S: 7 * 60,
    MAX_INACTIVITY_TIMEOUT_S: 10 * 60,
    INACTIVITY_TIMEOUT_NOTIFICATION_GAP_S: 15,
}
