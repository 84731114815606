import { addStyles } from 'ethcss'
import { grid, IStyles, item } from 'theme'
import { defaultThemeStyles } from '../Breadcrumbs.theme'
import { indents, textSizes } from 'theme/media'

export const styles: IStyles = {
    BreadcrumbsItem: {
        ...grid.rowCenter,
        ...grid.noWrap,
        ...grid.pl_microPlus,
        ...grid.pr_microPlus,
        ...item.pointer,
        textDecoration: 'none',
        color: `var(--var-module-catalog-component-breadcrumbs-style-textMain, ${defaultThemeStyles.textMain})`,
        '&:hover': {
            color: `var(--var-module-catalog-component-breadcrumbs-style-hover, ${defaultThemeStyles.hover})`,
            '& .home_icon': {
                color: `var(--var-module-catalog-component-breadcrumbs-style-hover, ${defaultThemeStyles.hover})!important`,
            },
            '& .group_files_icon': {
                color: `var(--var-module-catalog-component-breadcrumbs-style-hover, ${defaultThemeStyles.hover})!important`,
            },
        },
        ...grid.pt_md,
        ...grid.pb_md,
    },
    BreadcrumbsItem__icon: {
        marginRight: indents.mini,
        marginLeft: indents.mini,
    },
    BreadcrumbsItem__active: {
        color: `var(--var-module-catalog-component-breadcrumbs-style-active, ${defaultThemeStyles.active})`,
        cursor: 'default',
        '&:hover': {
            color: `var(--var-module-catalog-component-breadcrumbs-style-hover, ${defaultThemeStyles.hover})!important`,
        },
        '& .home_icon': {
            color: `var(--var-module-catalog-component-breadcrumbs-style-active, ${defaultThemeStyles.active})!important`,
            cursor: 'default',
        },
        '& .group_files_icon': {
            color: `var(--var-module-catalog-component-breadcrumbs-style-active, ${defaultThemeStyles.active})!important`,
            cursor: 'default',
        },
    },
    BreadcrumbsItem__hovered: {
        color: `var(--var-module-catalog-component-breadcrumbs-style-drop, ${defaultThemeStyles.drop})`,
        backgroundColor: `var(--var-module-catalog-component-breadcrumbs-style-dropBackground, ${defaultThemeStyles.dropBackground})`,
        '& .home_icon': {
            color: `var(--var-module-catalog-component-breadcrumbs-style-drop, ${defaultThemeStyles.drop})!important`,
        },
        '& .group_files_icon': {
            color: `var(--var-module-catalog-component-breadcrumbs-style-drop, ${defaultThemeStyles.drop})!important`,
        },
    },
    BreadcrumbsItem__text: {
        ...grid.rowCenter,
        textTransform: 'uppercase',
        fontSize: textSizes.normal,
        textDecoration: 'none',
        whiteSpace: 'nowrap',
    },
}

addStyles(styles)
