import { Radio } from 'atoms/Radio'
import { Typography } from 'atoms/Typography'
import EditText from 'blocks.simple/editText/editText'
import translate from 'core/translate'
import { isOnlyDigits, isOnlyDigitsAndPoints } from 'core/utils/validateUtil'
import { cn } from 'ethcss'
import React, { FC } from 'react'
import { styles } from './DeviceSettingsNTPServer-styles'
import { DeviceSettingsNTPServerProps, NTPSettings } from './DeviceSettingsNTPServer-types'

const initialNTPSettings = {
    enabled: false,
    ip: '',
    port: '',
}

export const DeviceSettingsNTPServer: FC<DeviceSettingsNTPServerProps> = ({
    platform,
    ntpSettings,
    title,
    onChange,
    enabled,
}) => {
    if (!enabled) {
        return null
    }

    const isEnableNTPSettings = ntpSettings ? ntpSettings.enabled : false
    const ipAddress = ntpSettings ? ntpSettings.ip : ''
    const port = ntpSettings ? ntpSettings.port : ''

    const onEnableNTPSettings = () => {
        const settings = ntpSettings || initialNTPSettings
        onChange({ ...settings, enabled: true }, 'ntpSettings')
    }

    const onDisableNTPSettings = () => {
        const settings = ntpSettings || initialNTPSettings
        onChange({ ...settings, enabled: false }, 'ntpSettings')
    }

    const onChangeIpAddress = (ip: string) => {
        if (isOnlyDigitsAndPoints(ip) || !ip) {
            onChange({ ...(ntpSettings as NTPSettings), ip }, 'ntpSettings')
        }
    }

    const onChangePort = (port: string) => {
        if (isOnlyDigits(port) || !port) {
            onChange({ ...(ntpSettings as NTPSettings), port }, 'ntpSettings')
        }
    }

    return (
        <div className={styles.DeviceSettingsNTPServer}>
            <Typography className={styles.DeviceSettingsNTPServer__title} type="title">
                {title}
            </Typography>
            <div className={styles.DeviceSettingsNTPServer__content}>
                <div className={styles.DeviceSettingsNTPServer__controls}>
                    <Radio
                        className={styles.DeviceSettingsNTPServer__enabled}
                        label={translate('on')}
                        onClick={onEnableNTPSettings}
                        selected={isEnableNTPSettings}
                    />
                    <Radio label={translate('off')} onClick={onDisableNTPSettings} selected={!isEnableNTPSettings} />
                </div>
                <div
                    className={cn(styles.DeviceSettingsNTPServer__fields, {
                        [styles.DeviceSettingsNTPServer__fields_disabled]: !isEnableNTPSettings,
                    })}
                >
                    <EditText
                        mod={'withBorder'}
                        label={'IP'}
                        value={ipAddress}
                        containerClass={styles.DeviceSettingsNTPServer__ipAddress}
                        placeholder={'127.0.0.1'}
                        onChange={onChangeIpAddress}
                    />
                    <div className={styles.DeviceSettingsNTPServer__divider}>
                        <div className={styles.DeviceSettingsNTPServer__text}>:</div>
                    </div>
                    <EditText
                        mod={'withBorder'}
                        label={'PORT'}
                        placeholder={'80'}
                        value={port}
                        containerClass={styles.DeviceSettingsNTPServer__port}
                        onChange={onChangePort}
                    />
                </div>
            </div>
        </div>
    )
}
