import { addStyles } from 'ethcss'
import { grid, IStyles } from 'theme'

export const styles: IStyles = {
    controlPanel: {
        ...grid.flex,
        ...grid.col,
        position: 'absolute',
        left: 14,
        top: '50%',
        transform: 'translateY(-50%)',
        padding: '9px 8px',
        borderRadius: 10,
        background: 'rgba(0, 0, 0, 0.8)',
        zIndex: 1005,
        transition: 'opacity 0.4s',
    },
    controlPanel__button: {
        marginBottom: 16,
        border: 'none',
        background: 'transparent',
        '&:last-child': {
            marginBottom: 0,
        },
        opacity: 0.2,
    },
    controlPanel__button_type_active: {
        opacity: '1 !important',
    },
    hidePanel: {
        opacity: 0,
        pointerEvents: 'none',
    },
}

addStyles(styles)
