import { addStyles } from 'ethcss'
import { grid, IStyles } from '../../theme'
import { defaultThemeStyles } from './datepicker.theme'

const itemWidth = {
    width: '2.5rem',
    lineHeight: '2.5rem',
    fontSize: 16,
}

const datePickerStyles: IStyles = {
    datepickerWrapper: {
        '& .react-datepicker__navigation': {
            //backgroundColor: `var(--var-module-ui-component-range-style-navigation, ${defaultThemeStyles.navigation})`,
            backgroundColor: 'none',
            border: 'none',
            width: '32px',
            top: '2px !important',
        },
        '& .react-datepicker__week-number': {
            cursor: 'pointer',
        },
        '& .react-datepicker__day--highlighted': {
            backgroundColor: `var(--var-module-ui-component-range-style-selected, ${defaultThemeStyles.selected})`,
            '&:hover': {
                backgroundColor: `var(--var-module-ui-component-range-style-selected, ${defaultThemeStyles.selected})`,
            },
        },
    },
    inline: {
        border: 'none!important',
        boxShadow: '0px 3px 13.5px 1.5px rgba(0, 0, 0, 0.2)',
        '& .react-datepicker__navigation': {
            top: 25,
            backgroundColor: `var(--var-module-ui-component-range-style-background, ${defaultThemeStyles.background})!important`,
        },
        '& .react-datepicker__header': {
            backgroundColor: `var(--var-module-ui-component-range-style-background, ${defaultThemeStyles.background})`,
            borderBottom: `2px solid var(--var-module-ui-component-range-style-border, ${defaultThemeStyles.border})`,

            '& .react-datepicker__current-month': {
                ...grid.pt_mini,
                ...grid.pb_mini,
                color: `var(--var-module-ui-component-range-style-currentMonthText, ${defaultThemeStyles.currentMonthText})`,
                textTransform: 'capitalize',
                fontSize: 18,
            },
            '& .react-datepicker__day-name': {
                fontWeight: 'bold',
                ...itemWidth,
            },
        },
        '& .react-datepicker__day': {
            ...itemWidth,
        },
        '& .react-datepicker__week-number': {
            ...itemWidth,
        },
    },
}

addStyles(datePickerStyles)
export default datePickerStyles
