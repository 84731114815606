import { useEffect, useState } from 'react'
import { api } from 'core/api/ConnectionManager'
import deepEqual from 'fast-deep-equal'
import { getURLSearchParamsByLocation } from 'features/routes/utils'

interface IState {
    isFilterOpen: boolean
    selectedFilter: { id: string; name: string } | null
    filterFields: string[]
    filterSearch: string
    selected: { id: string; name: string } | null
}
export interface ISearchbarFilterProps {
    filter: {
        selected: { id: string; name: string }
        groupId: number
        iconSize: number
        iconColor: string
        placeholder: string
        iconActiveColor: string
        options: { id: string; name: string }[]
        icon: string
    }
    location: any
    onFilterChange: (filter: { id: string; name: string; selected: boolean }) => void
    mod: string
}

const SearchbarFilterMethods = (p_: ISearchbarFilterProps) => {
    const [state, setState] = useState<IState>({
        isFilterOpen: false,
        selectedFilter: null,
        filterFields: [],
        filterSearch: '',
        selected: p_.filter ? { ...p_.filter.selected } : null,
    })
    let prevProps = p_

    useEffect(() => {
        const nextProps = p_

        if (!deepEqual(prevProps.filter, nextProps.filter)) {
            let selected: any = null

            if (nextProps.filter) {
                selected = { ...nextProps.filter }
            }

            setState((prevState) => {
                return { ...prevState, selected }
            })
        }

        prevProps = p_
    }, [p_])

    const filterSearch = (filterSearch: string) => {
        setState((prevState) => {
            return { ...prevState, filterSearch }
        })
    }
    const openFilter = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isFilterOpen: true,
                selectedFilter: null,
                selected: p_.filter ? { ...p_.filter.selected } : null,
            }
        })
    }
    const closeFilter = () => {
        setState((prevState) => {
            return { ...prevState, isFilterOpen: false }
        })
    }
    const selectFilter = (selectedFilter: { id: string; name: string }) => {
        const s_ = state
        const locationQuery = getURLSearchParamsByLocation(p_.location)
        const query = locationQuery.query

        setState((prevState) => {
            return { ...prevState, selectedFilter, filterFields: [], filterSearch: '' }
        })

        api.send('getFilterValues', { query, groupId: p_.filter.groupId, [selectedFilter.id]: [] }).then(
            (data: any) => {
                const filterFields: { id: string; name: string }[] = []
                const selectedFilters: any = s_.selected

                data[selectedFilter.id].forEach(
                    (item: { id: string; name: string; checked: boolean }, index: number) => {
                        const field: any = {
                            id: item.name || item,
                            name: item.name || item,
                            index,
                        }

                        if (selectedFilters && selectedFilters[selectedFilter.id]) {
                            if (selectedFilters[selectedFilter.id].indexOf(field.id) !== -1) {
                                field.checked = true
                            }
                        }
                        filterFields.push(field)
                    }
                )

                setState((prevState: any) => {
                    return { ...prevState, filterFields }
                })
            }
        )
    }
    const checkAll = (checked: boolean) => {
        const s_ = { ...state }

        s_.filterFields.forEach((field: any) => (field.checked = checked))
        setSelected(s_)
        setState(s_)
    }
    const toggleField = (index: number) => {
        const s_: any = { ...state }

        s_.filterFields[index].checked = !s_.filterFields[index].checked

        setSelected(s_)
        setState(s_)
    }
    const setSelected = (s_: any) => {
        s_.selected[s_.selectedFilter.id] = s_.filterFields
            .filter((field: { checked: boolean; id: string }) => field.checked)
            .map((field: { checked: boolean; id: string }) => field.id)
    }
    const onFilterChange = () => {
        const s_ = { ...state }
        const filter: any = { ...s_.selected }

        for (let key in filter) {
            if (filter.hasOwnProperty(key)) {
                filter[key] = filter[key].join('//')
            }
        }

        closeFilter()
        if (p_.onFilterChange) {
            p_.onFilterChange(filter)
        }
    }

    return {
        state,
        onFilterChange,
        toggleField,
        checkAll,
        closeFilter,
        openFilter,
        selectFilter,
        filterSearch,
    }
}

export default SearchbarFilterMethods
