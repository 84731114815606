import React, { useRef } from 'react'
import FilesMethods from './files.local.methods'
import Content from 'blocks.app/content/content'
import FilesToolbar from './__toolbar/files__toolbar'
import Catalog from 'blocks.app/catalog/catalog'
import Upload from './__upload/files__upload'
import FilesAdd from './__add/files__add'
import Modal from 'blocks.simple/modal/modal'
import PreviewWebApp from 'blocks.app/preview/__webApp/preview__webApp'
import Button from 'blocks.simple/button/button'
import { connect, RootStateOrAny, useSelector } from 'react-redux'
import styles from './files.jcss'
import translate from 'core/translate'
import { cn } from 'ethcss'
import helpersFiles from 'core/helpers/_files'
import helpers from 'core/helpers'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { activateEditLink, activateEditTable, activateEditText, filesActions, getFileSourceTags } from './files.state'
import { getParamsForLink } from 'core/helpers/user'
import { isHorizontalMenu } from 'core/helpers/menu'
import { withWindowResize } from 'core/hocs'
import { checkAvailableModule } from '../../core/helpers/routes/routes'
import { IFile } from '../../core/models/Files'
import { TagShortModel } from '../../core/models/Tags'

const Files = (p_: any) => {
    const { state, setState, onSelectInfo, onGetFoldersOnMultiple, editContent, onCloseEditingWidget } = FilesMethods(
        p_
    )
    const s_ = state
    const previewWebAppRef = useRef<any>(null)
    const params = getParamsForLink(p_.tokens, p_.mainUser.id, p_.user.id)
    const downloadLink = helpersFiles.getDownloadLink(s_.selectedInfo ? [s_.selectedInfo] : s_.selectedItems, params)
    const locationQuery = getURLSearchParamsByLocation(p_.location)
    const folderId = locationQuery.folderId
    const query = helpers.getQuery(locationQuery, 'files')
    const menu = useSelector((state: RootStateOrAny) => state.app.menu)

    const getPageTitle = () => {
        if (!menu) return translate('files')

        const menuPart = menu[0]
        if (menuPart && menuPart.menu) {
            return translate(menuPart.menu.some((item: any) => item.name === 'advertisers') ? 'advertisers' : 'files')
        }

        return translate('files')
    }

    return (
        <>
            <Upload tokens={p_.tokens} folderId={folderId} disabled={s_.disableDragUploads}>
                <Content
                    title={getPageTitle()}
                    toolbar={
                        <FilesToolbar
                            selectedInfo={s_.selectedInfo}
                            selectedItems={s_.selectedItems}
                            onSelectInfo={onSelectInfo}
                            onGetFoldersOnMultiple={onGetFoldersOnMultiple}
                            onCloseInfo={() =>
                                setState((prevState) => {
                                    return { ...prevState, selectedInfo: null }
                                })
                            }
                            onChange={(selectedItems: { id: number; isDirectory: boolean }[]) =>
                                setState((prevState) => {
                                    return { ...prevState, selectedItems }
                                })
                            }
                            downloadLink={downloadLink}
                            onEdit={() =>
                                setState((prevState) => {
                                    return { ...prevState, editWebApp: true }
                                })
                            }
                            tags={p_.tags}
                        />
                    }
                >
                    <Catalog
                        type="files"
                        selectedInfo={s_.selectedInfo}
                        onSelectInfo={onSelectInfo}
                        onDoubleClickObject={editContent}
                        isHideList={helpersFiles.isShowEditor(p_, query)}
                        toggleControls={helpersFiles.isShowEditor(p_, query)}
                        selectedItems={s_.selectedItems}
                        onSelect={(selectedItems: { id: number; isDirectory: boolean }[]) =>
                            setState((prevState) => {
                                return { ...prevState, selectedItems }
                            })
                        }
                        breadcrumbsClassName={cn(styles.breadcrumbs, {
                            [styles.breadcrumbs_x]: isHorizontalMenu() && p_.windowWidth > 960,
                        })}
                        onDragStart={() =>
                            setState((prevState) => {
                                return { ...prevState, disableDragUploads: true }
                            })
                        }
                        onDragEnd={() =>
                            setState((prevState) => {
                                return { ...prevState, disableDragUploads: false }
                            })
                        }
                        onGetList={p_.handleGetList}
                        fixedHeader
                    />
                    <Modal open={s_.editWebApp} onClose={onCloseEditingWidget}>
                        <PreviewWebApp
                            ref={previewWebAppRef}
                            item={s_.selectedInfo}
                            isEditable={true}
                            indents={'small'}
                            onClose={onCloseEditingWidget}
                        />

                        <Button
                            mod={'withBorder'}
                            className={styles.button}
                            onClick={() => {
                                previewWebAppRef.current.save(() => {
                                    onCloseEditingWidget()
                                })
                            }}
                        >
                            {translate('save')}
                        </Button>
                    </Modal>
                </Content>
            </Upload>
            <Modal open={p_.isOpenAddFile} showCloseIcon={false} mod={'clear'} transitionName={'fade'}>
                <div className={styles.modalWrapper}>
                    <div className={styles.modalInner}>
                        <FilesAdd folderId={+folderId} onClose={() => p_.closeAddFileModal()} />
                    </div>
                </div>
            </Modal>
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
        isOpenAddFile: state.files.isOpenAddFile,
        tags: state.files.tags,
        tokens: state.user.tokens,
        user: state.user.data,
        mainUser: state.user.mainUser,
        downloadLink: '',
        isAddLink: state.files.isAddLink,
        isAddTable: state.files.isAddTable,
        isAddText: state.files.isAddText,
    }
}

const mapDispatchToProps = (dispatch: (method: any) => void) => {
    return {
        handleGetList: (files: IFile[]) => dispatch(filesActions.setFiles(files)),
        openAddFileModal: () => dispatch(filesActions.openAddFileModal()),
        closeAddFileModal: () => dispatch(filesActions.closeAddFileModal()),
        activateEditTable: (id: number) => dispatch(activateEditTable(id)),
        activateEditLink: (id: number) => dispatch(activateEditLink(id)),
        activateEditText: (id: number) => dispatch(activateEditText(id)),
        activateAddText: () => dispatch(filesActions.activateAddText()),
        activateAddTable: () => dispatch(filesActions.activateAddTable()),
        activateAddLink: () => dispatch(filesActions.activateAddLink()),
        getFileSourceTags: () => dispatch(getFileSourceTags()),
        setFolders: (folders: IFile[]) => dispatch(filesActions.setFolders(folders)),
        setTags: (tags: TagShortModel[]) => dispatch(filesActions.setTags(tags)),
        setToolbar: (toolbar: string) => dispatch(filesActions.setToolbar(toolbar)),
        clearFilesState: (options: any) => dispatch(filesActions.clearState(options)),
    }
}

const exportFiles = checkAvailableModule('files')
    ? connect(mapStateToProps, mapDispatchToProps)(withWindowResize(Files))
    : null

export default exportFiles
