import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { IStyles } from 'theme'

const videoEditorToolbarStyles: IStyles = {
    wrapper: {
        ...grid.fullWidth,
        ...item.blackOpacity,
        ...grid.p_mini,
    },
    controls: {
        ...grid.row,
        ...grid.justify,
    },
    selected: {
        ...item.circle,
        ...item.grey,
    },
}

addStyles(videoEditorToolbarStyles)
export default videoEditorToolbarStyles
