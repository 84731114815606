import { addStyles } from 'ethcss'
import { IStyles } from 'theme'

export const styles: IStyles = {
    wrapper: {
        marginBottom: 20,
    },
    dropdown: {
        marginTop: 20,
    },
    buttonWrapper: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
    },
}

addStyles(styles)
