import EditText from '../../blocks.simple/editText/editText'
import translate from '../../core/translate'
import React, { useState } from 'react'

export const DateInterval = ({ onChange, dateBegin, dateEnd }: any) => {
    const [begin, setBegin] = useState(dateBegin)
    const [end, setEnd] = useState(dateEnd)

    return (
        <>
            <div style={{ marginRight: '20px' }}>
                <EditText
                    label={translate('startDate')}
                    dateValue={begin}
                    maxDate={end}
                    type="date"
                    onChange={function (e: any) {
                        onChange({ type: 'begin', value: e.format('YYYY-MM-DD') })
                        setBegin(e)
                    }}
                />
            </div>
            <div>
                <EditText
                    label={translate('endDate')}
                    dateValue={end}
                    minDate={begin}
                    maxDate={dateEnd}
                    type="date"
                    onChange={function (e: any) {
                        onChange({ type: 'end', value: e.format('YYYY-MM-DD') })
                        setEnd(e)
                    }}
                />
            </div>
        </>
    )
}
