import React, { useEffect, useRef, useState } from 'react'
import Icon from 'blocks.simple/icon/icon'
import Modal from 'blocks.simple/modal/modal'
import { Slider } from 'molecules/Slider'
import Animation from 'blocks.simple/animation/animation'
import PreviewImage from './__image/preview__image'
import PreviewText from './__text/preview__text'
import PreviewWebPage from './__webPage/preview__webPage'
import PreviewWebApp from 'blocks.app/preview/__webApp/preview__webApp'
import PreviewMedia from './__media/preview__media'
import PreviewOffice from './__office/preview__office'
import PreviewTable from './__table/preview__table'
import PreviewNotSupport from './__notSupport/preview__notSupport'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './preview.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { connect } from 'react-redux'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { SliderArrowProps } from '../../molecules/Slider/Slider-types'

type Item = {
    id: number
    src: string
    fileType: string
    name: string
    folderId: number
    data: string
    isDirectory: number
    type: string
    displayAs: string
    thumbnail: string
    style: object
}
interface IState {
    items: {
        id: number
        src: string
        fileType: string
        name: string
        folderId: number
        data: string
        isDirectory: number
        type: string
        displayAs: string
        thumbnail: string
        style: object
    }[]
    currentIndex: number | null
    currentItem: any
    maxZoom: boolean
    minZoom: boolean
    rotate: number
}
interface IPreviewProps {
    isOpen?: boolean
    onClose: () => void
    currentIndex: number
    emitError: (err: string) => void
    items: Item[]
    downloadLink?: string
}

const hideControlsForTypes = ['video', 'audio']

const PreviewLeftArrow = ({ isDisabled, onClick }: SliderArrowProps) => {
    return (
        <div className={cn(styles.navIconWrapper, styles.prev)}>
            <div className={styles.navIcon} onClick={onClick}>
                <Icon onClick={() => {}} type="arrow_left" color="white" disabled={isDisabled} size={30} />
            </div>
        </div>
    )
}

const PreviewRightArrow = ({ isDisabled, onClick }: SliderArrowProps) => {
    return (
        <div className={cn(styles.navIconWrapper, styles.next)}>
            <div className={styles.navIcon} onClick={onClick}>
                <Icon onClick={() => {}} type="arrow_right" color="white" disabled={isDisabled} size={30} />
            </div>
        </div>
    )
}

let prevProps: any = null

const Preview = (p_: IPreviewProps) => {
    const [state, setState] = useState<IState>({
        items: [],
        currentIndex: null,
        currentItem: {},
        maxZoom: false,
        minZoom: false,
        rotate: 0,
    })

    const previewWebPageRef = useRef<any>(null)
    const imageView = useRef<any>(null)
    const slider = useRef<any>(null)
    const zoomOut = () => {
        imageView.current.zoomOut()
    }
    const zoomIn = () => {
        imageView.current.zoomIn()
    }

    useEffect(() => {
        const nextProps = p_

        if (!nextProps.items) {
            return
        }
        if (nextProps.items.length && !prevProps.isOpen) {
            const items = nextProps.items
            const currentIndex = nextProps.currentIndex

            setState((prevState) => ({
                ...prevState,
                items,
                currentIndex,
                currentItem: items[currentIndex],
                currentPagePdf: 1,
                pdfPages: null,
            }))
        }

        prevProps = nextProps
    }, [p_])

    const refreshWebPage = () => {
        const { emitError } = p_

        if (previewWebPageRef) {
            previewWebPageRef.current.refreshPage()
        } else {
            emitError('failedToRefreshThePage')
        }
    }

    const onChangeSlide = (index: number) => {
        setState((prevState) => ({ ...prevState, currentIndex: index, currentItem: prevState.items[index] }))
    }

    return (
        <Modal open={p_.isOpen} closeIconSize={24} mod="content" closeIconColor="white" onClose={p_.onClose}>
            <div className={styles.wrapper}>
                {state.currentItem.name && (
                    <div className={styles.caption}>
                        <span>{state.currentItem.name}</span>
                    </div>
                )}
                <Slider
                    ref={slider}
                    className={styles.contentWrapper}
                    initialIndex={state.currentIndex}
                    count={1}
                    leftArrow={PreviewLeftArrow}
                    rightArrow={PreviewRightArrow}
                    onChangeSlide={onChangeSlide}
                    isControlled={true}
                >
                    {state.items.map((item, index) => {
                        if (item.type === 'table') {
                            return <PreviewTable key={index} item={item} />
                        }

                        switch (item.fileType) {
                            case 'image':
                                return (
                                    <PreviewImage
                                        key={index}
                                        src={item.src}
                                        imageViewRef={imageView}
                                        zoomStatusChange={(zoomStatus: any) =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                maxZoom: zoomStatus.maxZoom,
                                                minZoom: zoomStatus.minZoom,
                                            }))
                                        }
                                    />
                                )
                            case 'text':
                            case 'xml':
                            case 'rss':
                                return <PreviewText key={index} item={item} />
                            case 'web_page':
                                return <PreviewWebPage webPageRef={previewWebPageRef} key={index} item={item} />
                            case 'web_app':
                                return <PreviewWebApp key={index} item={item} isEditable={false} indents={'big'} />
                            case 'video':
                            case 'audio':
                                return <PreviewMedia key={index} item={item} downloadLink={p_.downloadLink} />
                            case 'office':
                                return <PreviewOffice key={index} src={item.src} />
                            default:
                                return <PreviewNotSupport key={index} />
                        }
                    })}
                </Slider>
                <Animation>
                    {!hideControlsForTypes.includes(state.currentItem.fileType) ? (
                        <div key={state.currentItem.fileType} className={styles.controlsWrapper}>
                            <div className={styles.controls}>
                                {state.currentItem.fileType === 'web_page' && (
                                    <Icon
                                        type="update"
                                        color="white"
                                        size={24}
                                        onClick={refreshWebPage}
                                        containerClass={cn(grid.p_md)}
                                        tooltip={{ title: translate('update') }}
                                    />
                                )}
                                {state.currentItem.fileType === 'image' && (
                                    <React.Fragment>
                                        <Icon
                                            type="zoom_in_bold"
                                            color="white"
                                            size={24}
                                            disabled={state.maxZoom}
                                            onClick={zoomIn}
                                            containerClass={cn(grid.p_md)}
                                            tooltip={{ title: translate('zoomIn') }}
                                        />
                                        <Icon
                                            type="zoom_out_bold"
                                            color="white"
                                            size={24}
                                            disabled={state.minZoom}
                                            onClick={zoomOut}
                                            containerClass={cn(grid.p_md)}
                                            tooltip={{ title: translate('zoomOut') }}
                                        />
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    ) : null}
                </Animation>
            </div>
        </Modal>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        emitError: (err: string) => dispatch(emitError(err)),
    }
}

export default connect(null, mapDispatchToProps)(Preview)
