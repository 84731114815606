import React from 'react'
import Animation from 'blocks.simple/animation/animation'
import Content from 'blocks.app/content/content'
import AuthorizationMethods from './authorization.local.methods'
import { AuthorizationLogin } from './__login'
import { Registration } from './__registration/Registration-view'
import AuthorizationForget from './__forget/authorization__forget'
import Link from 'blocks.simple/link/link'
import LoaderItem from 'blocks.app/loader/__item/loader__item'
import { routes } from 'features/routes'
import helpers from 'core/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { cn } from 'ethcss'
import styles from './authorization.jcss'
import { grid, item } from 'theme'
import translate from 'core/translate'
import { text as textStyles } from 'theme'
import { ConfirmEmailDialog } from 'organisms/ConfirmEmailDialog'
import { isExist } from 'core/utils/coreUtil'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { routesActions } from 'features/routes/routes.state'
import Dropdown from '../../molecules/Dropdown'
import { changeLocation } from '../../features/routes'
import { Typography } from '../../atoms/Typography'
import store from 'core/store'

declare var window: any

export interface Props {
    location: {
        pathname: string
        search: string
        hash: string
        state: any
    }
    redirectLocation: {
        pathname: string
        search: string
        hash: string
        state: any
    }
    owner: boolean
    clearRedirectLocation: () => void
}

const Authorization = (p_: Props) => {
    const { loading, setLoading, activeIndex, selectedLocale, getHeaderLink, changeLocale } = AuthorizationMethods(p_)
    const state: any = useSelector((state) => state)
    const redirectLocation = state.routes.redirectLocation
    const dispatch = useDispatch()
    const headerLink = getHeaderLink()
    const locationPathname = p_.location.pathname
    const pathname = helpers.getPathname(locationPathname)
    const query = getURLSearchParamsByLocation(redirectLocation)
    const whiteLabelChecked = store.getState().app.whiteLabelChecked

    const isShowConfirmEmailDialog = () => {
        return (
            isExist(query[`${routes.confirmEmail.prefix}_code`]) &&
            isExist(query[`${routes.confirmEmail.prefix}_prefix`])
        )
    }

    const handleConfirmEmailDialogClose = () => {
        dispatch(routesActions.clearRedirectLocation())
    }

    return (
        <Content className={cn(styles.wrapper)} frame isPublic={true}>
            <ConfirmEmailDialog open={isShowConfirmEmailDialog()} onClose={handleConfirmEmailDialogClose} />
            <Animation transitionName={window.spconfig.auth.animation}>
                <div
                    key={window.spconfig.auth.backgroundImages[activeIndex]}
                    className={cn(styles.filler, styles[`${window.spconfig.auth.position}Filler`])}
                    style={{
                        backgroundImage: `url(${
                            whiteLabelChecked
                                ? window.spconfig.auth.backgroundImages[activeIndex]
                                : 'images/authorization/background-placeholder.jpg'
                        })`,
                    }}
                />
            </Animation>
            <div
                className={cn(styles.content, styles[`${window.spconfig.auth.position}Content`])}
                style={{
                    boxShadow: window.spconfig.auth.boxShadow,
                }}
            >
                <Dropdown
                    noOverflow={true}
                    value={selectedLocale}
                    options={[
                        { value: 'ru', label: 'Русский' },
                        { value: 'en', label: 'English' },
                        { value: 'es', label: 'Español' },
                        { value: 'pt', label: 'Português' },
                        { value: 'kz', label: 'Қазақ' },
                    ]}
                    disabled={p_.owner}
                    onChange={(selected) => {
                        changeLocale(selected)
                        changeLocation(locationPathname + '?lang=' + selected.value)
                    }}
                    wrapperClassName={styles.dropdown}
                />
                <div className={cn(grid.fullWidth, textStyles.right)}>
                    <Link
                        textKey={`${helpers.getPathname(headerLink.pathname)}`}
                        mods={['hoverUnderline', 'normalPlus']}
                        to={headerLink}
                    />
                </div>
                <div className={grid.fullWidth}>
                    {whiteLabelChecked && (
                        <img
                            src={window.spconfig.auth.logo}
                            alt={`${window.spconfig.brandName} Digital Signage logo`}
                            className={styles.logo}
                        />
                    )}
                    <div className={styles.contentForm}>
                        <div className={cn(styles.title)}>{translate(`${pathname}Title`)}</div>
                        <div className={cn(grid.fullWidth, item.rel)}>
                            <div className={cn({ [item.hidden]: !loading }, item.abs, grid.colCenter, grid.full)}>
                                <LoaderItem inv={true} />
                                {locationPathname === '/registration' && (
                                    <Typography className={styles.registrationProceed}>
                                        {translate('registrationProceed')}
                                    </Typography>
                                )}
                            </div>
                            <div className={cn({ [item.hidden]: loading }, grid.row, grid.center)}>
                                {(() => {
                                    switch (pathname) {
                                        case routes.login.path:
                                            return (
                                                <AuthorizationLogin
                                                    location={p_.location}
                                                    onLoading={(loading: boolean) => setLoading(loading)}
                                                    showPasswordButton={window.spconfig.auth.showPasswordButton}
                                                />
                                            )
                                        case routes.forgetPassword.path:
                                            return (
                                                <AuthorizationForget
                                                    location={p_.location}
                                                    onLoading={(loading: boolean) => setLoading(loading)}
                                                />
                                            )
                                        case routes.registration.path:
                                            return (
                                                <Registration
                                                    location={p_.location}
                                                    onLoading={(loading: boolean) => setLoading(loading)}
                                                />
                                            )
                                        default:
                                            return ''
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {!window.spconfig.auth.copyright.hidden && (
                        <div className={styles.copyright} style={window.spconfig.auth.copyright.styles}>
                            {translate('madeOnThePlatform')}&nbsp;
                            <a href={`mailto:${window.spconfig.emailSupport}`}>{window.spconfig.brandName}</a>
                        </div>
                    )}
                </div>
            </div>
            {window.spconfig.showServiceProblemWarning && (
                <div className={cn(styles.notification)}>{translate('serverProblemWarning')}</div>
            )}
        </Content>
    )
}

export default Authorization
