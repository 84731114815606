import React from 'react'
import Searchbar from 'blocks.app/searchbar/searchbar'
import translate from 'core/translate'
import { item } from 'theme'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'

const ScheduleSearch = (p_: any) => {
    return (
        <div className={item.rel}>
            <Searchbar
                id={p_.id}
                mod={'withLightBorder'}
                placeholder={p_.placeholder}
                value={p_.query.name}
                onChange={p_.onChange}
                onFocus={p_.onFocus}
                view={{
                    icon: 'view',
                    iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
                    iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
                    value: p_.viewValue,
                    options: [
                        { id: 'month', name: translate('month') },
                        { id: 'week', name: translate('week') },
                        { id: 'day', name: translate('dayD') },
                    ],
                }}
                onViewChange={p_.onViewChange}
                onClickView={p_.onClickView}
                search={{
                    onSelect: p_.onSelect,
                    onChange: p_.onChange,
                    props: { ...p_ },
                }}
            />
        </div>
    )
}

export default ScheduleSearch
