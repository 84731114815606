export default {
    isDirectory: function ({ instanceType }: any): boolean {
        return instanceType === 'broadcastFolder'
    },
    isFolder: function ({ instanceType }: { instanceType: string }): boolean {
        return instanceType === 'broadcastFolder'
    },
    isEqual: function (item: any, prevItem: any): boolean {
        return item.id === prevItem.id && this.isDirectory(item) === this.isDirectory(prevItem)
    },
    getFolder: function (folder: any) {
        return {
            ...folder,
            label: folder.title,
            name: folder.title,
            value: folder.id,
            nestedKey: 'folderId',
        }
    },
    createBroadcast(id: number, title: string) {
        return {
            id,
            title,
            instanceType: 'broadcast',
        }
    },
    createBroadcastFolder(id: number, title: string) {
        return {
            id,
            title,
            instanceType: 'broadcastFolder',
        }
    },
}
