import { addStyles } from 'ethcss'
import 'blocks.app/loader/loader.css'
import { colors, IStyles, mediaCSS } from '../../../theme'

const displayRemoteControlStyles: IStyles = {
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        padding: '24px 50px 40px 50px',
        color: colors.white,
        background: '#272727',

        [mediaCSS.tab]: {
            display: 'block',
            padding: '70px 15px 40px 15px',
        },
    },
    leftBlock: {
        position: 'relative',
        height: '100%',
        width: 'calc(100% - 366px)',

        [mediaCSS.tab]: {
            width: '100%',
            height: 'auto',
            marginBottom: 44,
        },
    },
    caption: {
        fontSize: 24,
        marginBottom: 30,

        [mediaCSS.tab]: {
            display: 'none',
        },
    },
    statusWrapper: {
        position: 'absolute',
        bottom: 40,
        left: 0,
        pointerEvents: 'none',

        [mediaCSS.tab]: {
            display: 'none',
        },
    },
    status: {
        fontSize: 20,
        fontWeight: 900,
        marginBottom: 10,
    },
    statusDescription: {
        fontSize: 16,
    },
    preview: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'calc(100vh - 130px)',
        overflow: 'hidden',

        [mediaCSS.tab]: {
            height: 'auto',
            maxHeight: 'calc(100vh - 130px)',
        },
    },
    previewInner: {
        maxWidth: '100% !important',
        maxHeight: '100% !important',
        background: colors.black,
        borderRadius: 10,
        overflow: 'hidden',
    },
    iframe: {
        display: 'block',
        pointerEvents: 'none',
    },
    player: {
        display: 'block',
        borderRadius: 10,
        backgroundColor: colors.black,
    },
    rightBlock: {
        display: 'flex',
        alignItems: 'center',
        width: 312,
        height: '100%',
        paddingTop: 54,

        [mediaCSS.tab]: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 'auto',
            paddingTop: 0,
            transform: 'translateY(100%)',
            transition: 'transform 0.3s',
        },
    },
    showMobRemote: {
        transform: 'translateY(0)',
    },
    remote: {
        position: 'relative',
        margin: '0 auto',

        [mediaCSS.tab]: {
            opacity: 0.5,
            transition: 'opacity 0.3s',
        },
    },
    remoteButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: 60,
        height: 60,
        borderRadius: '50%',
        //border: '1px solid white',
        cursor: 'pointer',
        transition: 'background-color 0.2s',

        '& img': {
            display: 'block',
            pointerEvents: 'none',
        },
    },
    pressedBtn: {
        backgroundColor: colors.blueAccent,
    },
    arrowLeftBtn: {
        left: 46,
        top: 136,

        [mediaCSS.tab]: {
            top: 118,
            left: 51,
        },
    },
    arrowRightBtn: {
        left: 206,
        top: 136,

        [mediaCSS.tab]: {
            top: 118,
            left: 210,
        },
    },
    arrowUpBtn: {
        left: 126,
        top: 56,

        [mediaCSS.tab]: {
            top: 38,
            left: 132,
        },
    },
    arrowDownBtn: {
        left: 126,
        top: 216,

        [mediaCSS.tab]: {
            top: 197,
            left: 132,
        },
    },
    selectBtn: {
        left: 108,
        top: 118,
        width: '96px !important',
        height: '96px !important',

        [mediaCSS.tab]: {
            top: 100,
            left: 113,
        },
    },
    backBtn: {
        left: 46,
        top: 288,

        [mediaCSS.tab]: {
            top: 269,
            left: 50,
        },
    },
    muteBtn: {
        left: 46,
        top: 376,

        [mediaCSS.tab]: {
            top: 358,
            left: 50,
        },
    },
    homeBtn: {
        left: 206,
        top: 288,

        [mediaCSS.tab]: {
            top: 269,
            left: 210,
        },
    },
    minusBtn: {
        left: 126,
        top: 376,

        [mediaCSS.tab]: {
            top: 358,
            left: 130,
        },
    },
    plusBtn: {
        left: 206,
        top: 376,

        [mediaCSS.tab]: {
            top: 358,
            left: 210,
        },
    },
    showRemoteBtn: {
        position: 'fixed',
        left: '50%',
        bottom: 20,
        transform: 'translateX(-50%)',
    },
    hideRemoteBtn: {
        position: 'fixed',
        left: '50%',
        bottom: 10,
        transform: 'translateX(-50%)',
    },
    active: {
        [mediaCSS.tab]: {
            opacity: 1,
        },
    },
    connectionStatus: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    connectionStatusInner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 18,
    },
    connectionStatusText: {
        marginBottom: 10,
        fontWeight: 700,
    },
    connectionError: {
        color: colors.notificationRed,
    },
}

addStyles(displayRemoteControlStyles)
export default displayRemoteControlStyles
