import React, { useState, useRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'molecules/Icon'

import { cn } from 'ethcss'
import styles from './styles'

const iconDefaultProps = {
    ...Icon.defaultProps,
    size: 18,
    type: 'pencil',
    className: styles.icon,
}

const ContentEditable = (p_) => {
    const Element = p_.tagName
    const elementRef = useRef(null)
    const [isFocused, setIsFocused] = useState(false)
    const iconMergedProps = {
        ...iconDefaultProps,
        ...p_.icon,
    }

    const onBlur = (e) => {
        const value = e.target.textContent

        setIsFocused(false)
        p_.onChange(value, e)
    }

    const onKeyDown = (e) => {
        const value = e.target.textContent

        if (e.keyCode === 13 && !p_.multiline) {
            e.preventDefault()
        }

        if (value.length > p_.maxLength && e.keyCode !== 8) {
            e.preventDefault()
        }

        if (p_.onKeydown) {
            p_.onKeydown(e)
        }
    }

    const onFocus = (e) => {
        setIsFocused(true)

        if (p_.onFocus) {
            p_.onFocus(e)
        }
    }

    const onIconClick = (e, props) => {
        if (elementRef) {
            const range = document.createRange()
            const sel = window.getSelection()
            const firstChild = elementRef.current.childNodes[0]

            if (firstChild) {
                range.setStart(firstChild, p_.value.length)
                range.collapse(true)
                sel.removeAllRanges()
                sel.addRange(range)
            }

            elementRef.current.focus()
        }

        if (iconMergedProps.onClick) {
            iconMergedProps.onClick(e, props)
        }
    }

    useImperativeHandle(p_.editableRef, () => ({
        onIconClick,
        onBlur,
    }))

    return (
        <div className={cn(styles.wrapper, p_.className, { [styles.focused]: isFocused })}>
            <Element
                ref={elementRef}
                className={cn(styles.editable, p_.elementClassName)}
                contentEditable={p_.editable}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                onFocus={onFocus}
                dangerouslySetInnerHTML={{ __html: p_.value }}
            />
            {p_.showIcon && (
                <div className={cn(iconMergedProps.className)}>
                    <div className={styles.iconWrapper} onClick={onIconClick}>
                        <Icon
                            {...iconMergedProps}
                            className={cn(styles.iconRounded, styles[`${iconMergedProps.color}Hover`])}
                            onClick={null}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

ContentEditable.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    elementClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    tagName: PropTypes.string,
    editable: PropTypes.bool,
    multiline: PropTypes.bool,
    showIcon: PropTypes.bool,
    maxLength: PropTypes.number,
    icon: PropTypes.shape({ ...Icon.propTypes }),
    onChange: PropTypes.func,
    onKeydown: PropTypes.func,
    editableRef: PropTypes.any,
}

ContentEditable.defaultProps = {
    tagName: 'div',
    editable: true,
    showIcon: true,
    maxLength: Infinity,
    color: 'darkBlue',
    fontSize: 'md',
    elementClassName: styles.element,
    icon: iconDefaultProps,
    onChange: () => {},
}

export default ContentEditable
