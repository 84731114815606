import { addStyles } from 'ethcss'
import { colors, IStyles } from '../../theme'

const styles: IStyles = {
    panel: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 40,
        backgroundColor: colors.blueAccent,
        zIndex: 10000,
        color: colors.white,
        padding: '0 20px',
        opacity: 0.5,
        transition: 'opacity 0.3s',

        '&:hover': {
            opacity: 1,
        },
    },
    disableButton: {
        padding: 7,
        border: `1px solid ${colors.white}`,
        borderRadius: 20,
        cursor: 'pointer',
    },
}

addStyles(styles)
export default styles
