import { useEffect, useState } from 'react'
import moment from 'moment/moment'

type Format = { format: (value: {}) => string }

const formatDateList = (list: { format: (value: {}) => string }[]) => list.map((item) => item.format('YYYY-MM-DD'))
const isExistDate = (prevList: any, list: any) =>
    prevList.find((element: any) => {
        const formatDate = element.format('YYYY-MM-DD')
        return list.includes(formatDate)
    })

const DatepickerMethods = (p_: any) => {
    const [list, setList] = useState<any[]>([])

    useEffect(() => {
        if (p_.list && p_.list.length) {
            setList(p_.list)
        }
    }, [])
    const handleChange = (date: Format) => {
        if (p_.list) {
            const list = compareDates(date)

            p_.onChange(list)
            setList(list)
        } else {
            p_.onChange(date)
        }
    }
    const compareDates = (date: Format) => {
        let _list: any = [...list]
        const dateFilter = formatDateList(_list)
        const currentDate = date.format('YYYY-MM-DD')

        if (!dateFilter.includes(currentDate)) {
            _list.push(date)
        } else {
            _list = _list.filter((prevDate: any) => prevDate.format('YYYY-MM-DD') !== currentDate)
        }

        return _list
    }
    const handleWeekSelect = (date: string) => {
        let days = []
        let start = moment(date).startOf('week')
        const end = moment(date).endOf('week')

        while (start <= end) {
            days.push(start)
            start = start.clone().add(1, 'd')
        }

        if (p_.minDate) {
            days = days.filter((day) => day.isAfter(p_.minDate) || day.isSame(p_.minDate, 'day'))
        }

        // @ts-ignore
        const formattedDays = formatDateList(days)

        if (!isExistDate(list, formattedDays)) {
            days = [...list, ...days]
        } else {
            days = list.filter(
                (day: { format: (value: {}) => string }) => !formattedDays.includes(day.format('YYYY-MM-DD'))
            )
        }

        p_.onChange(days)
        setList(days)
    }

    return {
        s_list: list,
        handleWeekSelect,
        handleChange,
    }
}

export default DatepickerMethods
