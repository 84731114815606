import React from 'react'
import PropTypes from 'prop-types'
import VideoEditorContentMethods, { IVideoEditorContentProps } from './videoEditor__content.local.methods'
import VideoEditorContentErrors from './_errors/videoEditor__content_errors'
import VideoEditorToolbar from '../__toolbar/videoEditor__toolbar'
import ReactPlayer from 'react-player'
import RangeVolume from 'blocks.simple/range/_volume/range_volume'
import Icon from 'blocks.simple/icon/icon'
import Range from 'blocks.simple/range/range'
import Dropzone from 'react-dropzone'
import DndDroppable from 'blocks.app/dnd/_droppable/dnd_droppable'
import Loader from 'blocks.app/loader/__item/loader__item'
import AnimationSettings from 'blocks.app/animationSettings/animationSettings'
import { PreviewMediaMessage } from 'blocks.app/preview/__media/preview__media'
import translate from 'core/translate'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import styles from './videoEditor__content.jcss'
import { text as textStyles } from 'theme'
import { ProgressBar } from 'atoms/ProgressBar'
import { getThemeStyleValue } from '../../../theme/colors'
import { defaultThemeStyles } from '../VideoEditor-theme'
import helpers from 'core/helpers'
import { IState } from './videoEditor__content.local.methods'
import { RootStateOrAny, useSelector } from 'react-redux'

const accept = () => {
    const accept: any = {}
    if (helpers.isAvailableFileType({ key: 'files', name: 'image', action: 'create' })) {
        return (accept['image/jpg'] = ['.jpg', '.jpeg', '.png'])
    }
}

const VideoEditorContent = (props: IVideoEditorContentProps) => {
    const {
        state,
        setState,
        onSaveAnimationSettings,
        onCloseAnimationSettings,
        onDropContent,
        onDrop,
        getTime,
        onSeekChange,
        player,
    } = VideoEditorContentMethods(props)

    const s_ = { ...state }
    const p_ = props
    const dropzoneIconColor = getThemeStyleValue('app', 'videoEditor', 'dropzone') || defaultThemeStyles.dropzone
    const isMobile = useSelector<RootStateOrAny>((state) => state.app.isMobile)

    return (
        <div className={grid.full}>
            <div className={styles.wrapper}>
                <VideoEditorToolbar
                    onChangeShowSettings={p_.onChangeShowSettings}
                    onSave={p_.onSave}
                    selectedTasks={p_.selectedTasks}
                    toggleMinimized={p_.toggleMinimized}
                    animationSettings={p_.animationSettings}
                    media={p_.media}
                />
                <DndDroppable
                    id={'videoEditorDroppableArea'}
                    onDrop={onDropContent}
                    className={styles.content}
                    onMouseDown={function (e: any) {
                        if (e.target.id !== 'videoEditorDroppableArea') {
                            e.stopPropagation()
                        }
                    }}
                >
                    <div className={cn(grid.colCenter, grid.full, item.abs)}>
                        {p_.media && (
                            <div className={grid.full}>
                                {p_.media.multiple && (
                                    <div
                                        className={styles.multipleWrapper}
                                        onClick={() => {
                                            p_.openMinimized()

                                            if (p_.showSettings === 'edit') {
                                                p_.onChangeShowSettings(null)
                                            } else {
                                                p_.onChangeShowSettings('edit')
                                            }
                                        }}
                                    >
                                        {p_.showSettings === 'edit' && (
                                            <div className={styles.removeIcon}>
                                                <Icon
                                                    type="close"
                                                    color="white"
                                                    size={16}
                                                    containerClass={cn(grid.p_md)}
                                                    tooltip={{ html: translate('close') }}
                                                    onClick={function (e) {
                                                        e.stopPropagation()
                                                        p_.onDeleteMedia()
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <Icon type="gear" size={60} color="white" />
                                        <div className={styles.multipleDescription}>
                                            {translate('videoEditorMultipleEditingDescription')}
                                        </div>
                                    </div>
                                )}
                                <PreviewMediaMessage src={p_.media.src} error={s_.error} />
                                <VideoEditorContentErrors errors={p_.media.errors} />
                                {p_.media.status === 'in_progress' && (
                                    <div className={styles.loader}>
                                        <Loader
                                        // className={styles.progressBackground}
                                        />
                                        {s_.processingTasks[p_.media.id] && (
                                            <div className={styles.processingStatus}>
                                                {translate('processed')}:{' '}
                                                {Math.round(s_.processingTasks[p_.media.id].percent)}%
                                            </div>
                                        )}
                                    </div>
                                )}
                                {p_.media.type === 'image' && p_.media.status !== 'in_progress' && (
                                    <div
                                        className={grid.full}
                                        onClick={() => {
                                            p_.openMinimized()

                                            if (p_.showSettings === 'edit') {
                                                p_.onChangeShowSettings(null)
                                            } else {
                                                p_.onChangeShowSettings('edit')
                                            }
                                        }}
                                    >
                                        {p_.showSettings === 'edit' && (
                                            <div className={styles.removeIcon}>
                                                <Icon
                                                    type="close"
                                                    color="white"
                                                    size={16}
                                                    containerClass={cn(grid.p_md)}
                                                    tooltip={{ html: translate('close') }}
                                                    onClick={function (e) {
                                                        e.stopPropagation()
                                                        p_.onDeleteMedia()
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <div
                                            className={styles.imageView}
                                            style={{ backgroundImage: `url(${p_.media.src})` }}
                                        />
                                    </div>
                                )}
                                {s_.showPlayer && p_.media.type === 'video' && p_.media.status !== 'in_progress' && (
                                    <div
                                        className={grid.full}
                                        onClick={() => {
                                            p_.openMinimized()

                                            if (p_.showSettings === 'edit') {
                                                p_.onChangeShowSettings(null)
                                            } else {
                                                p_.onChangeShowSettings('edit')
                                            }
                                        }}
                                    >
                                        {p_.showSettings === 'edit' && (
                                            <div className={styles.removeIcon}>
                                                <Icon
                                                    type="close"
                                                    color="white"
                                                    size={16}
                                                    containerClass={cn(grid.p_md)}
                                                    tooltip={{ html: translate('close') }}
                                                    onClick={function (e) {
                                                        e.stopPropagation()
                                                        p_.onDeleteMedia()
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <ReactPlayer
                                            ref={player}
                                            width="100%"
                                            height="100%"
                                            hidden={false}
                                            url={p_.media.src}
                                            playing={s_.active}
                                            onEnded={() => {
                                                setState((prevState: IState) => {
                                                    return {
                                                        ...prevState,
                                                        active: false,
                                                        played: 0,
                                                    }
                                                })
                                            }}
                                            onProgress={(e) => {
                                                if (e.played < 1)
                                                    setState((prevState: IState) => {
                                                        return {
                                                            ...prevState,
                                                            played: e.played,
                                                        }
                                                    })
                                            }}
                                            onReady={() =>
                                                setState((prevState: IState) => {
                                                    return {
                                                        ...prevState,
                                                        ready: true,
                                                    }
                                                })
                                            }
                                            onError={() =>
                                                setState((prevState: IState) => {
                                                    return {
                                                        ...prevState,
                                                        error: true,
                                                    }
                                                })
                                            }
                                            progressInterval={250}
                                            volume={s_.volume / 100}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        {!p_.media && !p_.downloadMediaPercentage && (
                            <div>
                                <Dropzone
                                    className={cn(styles.dropzone)}
                                    onDrop={onDrop}
                                    //@ts-ignore
                                    accept={accept()}
                                    disablePreview
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()} className={styles.dropzoneContent}>
                                            <input {...getInputProps()} />
                                            <Icon
                                                type="download"
                                                size={isMobile ? 30 : 150}
                                                color={dropzoneIconColor}
                                            />
                                            <div
                                                className={cn(
                                                    textStyles.md,
                                                    textStyles.center,
                                                    grid.w60,
                                                    grid.mt_mdPlus,
                                                    styles.tabMiniPt
                                                )}
                                            >
                                                {translate('uploadMediaDescription')}
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </div>
                        )}
                        {!p_.media && p_.downloadMediaPercentage && (
                            <div className={grid.colCenter}>
                                <div className={styles.progress}>
                                    <ProgressBar value={p_.downloadMediaPercentage} strokeWidth={17} />
                                </div>
                                <div className={styles.mediaUpload}>
                                    {translate('loading')}: {Math.round(p_.downloadMediaPercentage)}%
                                </div>
                            </div>
                        )}
                    </div>
                    <div style={{ paddingBottom: '56.25%' }} />
                </DndDroppable>
                {p_.animationSettings && (
                    <div className={grid.fullWidth}>
                        <AnimationSettings
                            animation={s_.animations}
                            onUpdate={(obj, key) => {
                                const animations = {
                                    ...s_.animations,
                                    [key]: obj,
                                }
                                setState((prevState: IState) => {
                                    return {
                                        ...prevState,
                                        animations,
                                    }
                                })
                            }}
                            onClose={onCloseAnimationSettings}
                            onSave={onSaveAnimationSettings}
                        />
                    </div>
                )}
                {!p_.animationSettings && (
                    <div className={styles.controls}>
                        <div>
                            <Range
                                min={0}
                                max={1}
                                step={0.001}
                                className={styles.progress}
                                value={s_.played}
                                mod="white"
                                handle={false}
                                disabled={!s_.ready}
                                onChange={onSeekChange}
                            />
                        </div>
                        <div className={cn(grid.row, grid.justify)}>
                            <div className={cn(grid.row, grid.normalCenter, grid.pr_mini, grid.pl_mini)}>
                                <Icon
                                    disabled={!s_.ready}
                                    type={s_.active ? 'pause' : 'play'}
                                    color="white"
                                    size={18}
                                    onClick={() =>
                                        setState((prevState: IState) => {
                                            return {
                                                ...prevState,
                                                active: !s_.active,
                                            }
                                        })
                                    }
                                />
                                <div className={styles.rangeVolume}>
                                    <RangeVolume
                                        volume={s_.volume}
                                        mod="white"
                                        handle={false}
                                        onChange={(volume: number) =>
                                            setState((prevState: IState) => {
                                                return {
                                                    ...prevState,
                                                    volume,
                                                }
                                            })
                                        }
                                        showVolumeCounter={false}
                                    />
                                </div>
                                <div className={styles.timeLine}>{getTime()}</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default VideoEditorContent
