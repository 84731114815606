import React, { FC, memo, useEffect, useRef, useState } from 'react'
import throttle from 'lodash/throttle'
import { constants } from 'core/constants'
import { useAnimationFavIcon } from 'core/hooks'
import { IUserInactivityService } from './UserInactivityService-types'

export const UserInactivityServiceComponent: FC<IUserInactivityService> = ({
    inactivityTimeoutS,
    onShowInactivityNotification,
    onHideInactivityNotification,
    onInactivityTimeout,
    isEnable,
}) => {
    const [isInit, setIsInit] = useState(isEnable)
    const [inactivityMs, setInactivityMs] = useState<number>(0)
    const [isAnimateFavIcon, setIsAnimateFavIcon] = useState<boolean>(false)
    const currentInactivityMs = useRef(inactivityMs)
    currentInactivityMs.current = inactivityMs
    const { isInitFavIcon } = useAnimationFavIcon({ isActive: isAnimateFavIcon })

    useEffect(() => {
        setIsInit(isEnable)
    }, [isEnable])

    useEffect(() => {
        if (isEnable) return

        setInactivityMs(0)
    }, [isEnable])

    useEffect(() => {
        if (!isInit) return

        const createdInterval = setInterval(() => {
            if (inactivityMs < inactivityTimeoutS) {
                setInactivityMs((prev) => prev + 1)
            }

            if (inactivityMs === inactivityTimeoutS - constants.INACTIVITY_TIMEOUT_NOTIFICATION_GAP_S) {
                onShowInactivityNotification(inactivityTimeoutS)
            }

            if (inactivityMs === inactivityTimeoutS) {
                setIsInit(false)
                onInactivityTimeout()
            }
        }, 1000)

        return () => {
            clearInterval(createdInterval)
        }
    }, [isInit, inactivityTimeoutS, inactivityMs])

    useEffect(() => {
        if (
            inactivityMs > inactivityTimeoutS - constants.INACTIVITY_TIMEOUT_NOTIFICATION_GAP_S &&
            inactivityMs < inactivityTimeoutS
        ) {
            if (!isInitFavIcon) {
                setIsAnimateFavIcon(true)
            }
        } else {
            if (isInitFavIcon) {
                setIsAnimateFavIcon(false)
            }
        }
    }, [isInit, inactivityMs, inactivityTimeoutS])

    useEffect(() => {
        if (!isInit) return

        createUserActionsListeners()

        return () => {
            removeUserActionsListeners()
        }
    }, [isInit, inactivityTimeoutS])

    const resetInactivity = () => {
        if (currentInactivityMs.current > inactivityTimeoutS - constants.INACTIVITY_TIMEOUT_NOTIFICATION_GAP_S) {
            onHideInactivityNotification()
        }

        setInactivityMs(0)
    }

    const throttleResetInactivity = throttle(resetInactivity, 1000)

    const createUserActionsListeners = () => {
        window.addEventListener('click', throttleResetInactivity)
        window.addEventListener('mousemove', throttleResetInactivity)
        window.addEventListener('keypress', throttleResetInactivity)
    }

    const removeUserActionsListeners = () => {
        window.removeEventListener('click', throttleResetInactivity)
        window.removeEventListener('mousemove', throttleResetInactivity)
        window.removeEventListener('keypress', throttleResetInactivity)
    }

    return <div></div>
}

export const UserInactivityService = memo(UserInactivityServiceComponent)
