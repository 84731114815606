import React from 'react'
import { api } from 'core/api/ConnectionManager'
import moment from 'moment'
import translate from 'core/translate'
import { routes } from 'features/routes'
import { defaultThemeStyles } from './dashboard__network.theme'
import { changeLocation } from 'features/routes'

const incoming = `var(--var-module-dashboard-component-network-style-incoming, ${defaultThemeStyles.incoming})`
const outgoing = `var(--var-module-dashboard-component-network-style-outgoing, ${defaultThemeStyles.outgoing})`

class DashboardNetworkMethods extends React.PureComponent {
    state = {
        data: [],
        isLoaded: false,
        isOpenReportModal: false,
        startDate: moment(),
        endDate: moment(),
    }

    colors = [incoming, outgoing]

    componentDidMount() {
        api.send('getTodayNetStat', {}, { showEmitError: false }).then((res: any) => {
            if (!res) {
                return
            }

            const dataFields = ['received', 'transmitted']
            const data = []

            for (let key in res) {
                if (res.hasOwnProperty(key) && dataFields.includes(key)) {
                    data.push({ label: translate(key), value: res[key] })
                }
            }

            this.setState({ data, isLoaded: true })
        })
    }

    onCreateReport = () => {
        const s_ = this.state
        const startDate = moment(s_.startDate).format('YYYY-MM-DD')
        const endDate = moment(s_.endDate).format('YYYY-MM-DD')

        api.send('createReportTask', { startDate, endDate, reportType: 'serverNetStatReport' }).then(() => {
            this.setState({ isOpenReportModal: false }, () => {
                changeLocation(`/${routes.reportTasks.path}`)
            })
        })
    }
}

export default DashboardNetworkMethods
