import React, { FC } from 'react'
import { ITableHeaderCellProps } from './TableHeaderCell-types'
import { styles } from './TableHeaderCell-styles'
import { cn } from 'ethcss'

export const TableHeaderCell: FC<ITableHeaderCellProps> = ({ children, className = {}, style = {} }) => {
    return (
        <th className={cn(styles.TableHeaderCell, className)} style={style}>
            {children}
        </th>
    )
}
