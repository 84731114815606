import React, { useEffect, useMemo, useRef, useState } from 'react'

import moment from 'moment'
// @ts-ignore
import omit from 'omit-empty'
import translate from 'core/translate'
import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'
import helpersFiles, { commandsModel } from 'core/helpers/_files'
import { transitions } from 'blocks.app/config'
import { changeFilter } from 'features/routes'
import { RouteComponentProps } from 'react-router-dom'

const getDuration = (seconds: number) => moment().startOf('day').seconds(seconds).format('HH:mm:ss.SSS')
const animationKeys = ['fadeIn', 'fadeOut']

const getTaskSourceSettings = ({ id, data, status }: { id: number | null; data: any; status: string }) => {
    const commands: { [index: string]: any } = helpers.deepCopy(commandsModel)

    const media = {
        src: data.fileURL,
        name: data.fileName,
        duration: data.duration,
        status,
        id,
        width: data.width,
        height: data.height,
        mimetype: data.mimetype,
        type: data.type,
        fps: data.fps,
        errors: data.videoValidationErrors,
    }

    commands.crop.height = media.height
    commands.crop.width = media.width

    if (media.type === 'video') {
        commands.cut.startTime = moment().startOf('day').format('HH:mm:ss')
        commands.cut.duration = helpers.msToHMS(data.duration * 1000, '')
    }

    commands.setVideoBitrate.bitrate = ''

    if (data.hasOwnProperty('bitrate')) {
        commands.setVideoBitrate.bitrate = Math.round(data.bitrate / 1000)
    }

    if (data.hasOwnProperty('fps') && data.fps) {
        commands.setFps.fps = data.fps
    }

    return {
        media,
        commands,
    }
}
interface IState {
    media: { [index: string]: any } | null
    downloadMediaPercentage: number | null
    showSettings: string | null
    minimized: boolean
    openAnimationSettings: boolean
    commands?: { [index: string]: any }
    sourceData: { [index: string]: any }
    settings: { [index: string]: any }
    selectedTasks: []
    selectedTasksSourceType: null | { [index: string]: any } | null
}
export interface IVideoEditorMethodsProps extends RouteComponentProps {
    minimized?: boolean
    media?: {
        duration: any
        multiple: boolean
        errors: []
        fps: number
        height: number
        id: number
        mimetype: string
        name: string
        src: string
        status: string
        type: string
        width: number
    } | null
    commands?: { [index: string]: any }
    defaultCommands?: { [index: string]: any }
    onChangeCommands?: (commands: any) => void
    selectedTasksSourceType?: { [index: string]: any } | null
    toggleMinimized?: () => void
    reset?: () => void
    onDrop?: (content: any) => void
    onSelectTasks?: (tasks: any) => void
    onUpload?: (file: any) => void
    onChangeTask?: (item: any, type: string) => void
    onSelectTask?: () => void
    onProcessMedia?: () => void
    onChangeSettings?: (name: string, fileName: string) => void
    save?: () => void
    sourceId: string
    uploadVideoEditor: (file: any) => void
    emitError: (err: string) => void
}
const VideoEditorMethods = (props: IVideoEditorMethodsProps) => {
    const [state, setState] = useState<IState>({
        // @ts-ignore
        media: null,
        downloadMediaPercentage: null,
        showSettings: null,
        minimized: helpers.isMobileDevice(),
        openAnimationSettings: false,
        commands: commandsModel,
        sourceData: commandsModel,
        settings: {
            fileName: '',
            fileNamePrefix: translate('processed'),
            folderId: null,
        },
        selectedTasks: [],
        selectedTasksSourceType: null,
    })
    let minimizedTimeout: any = null
    const videoEditorSidebarRef = useRef<any>(null)

    useEffect(() => {
        const { sourceId } = props
        sourceId && createVideoEditorTask({ sourceId })

        return () => clearTimeout(minimizedTimeout)
    }, [])

    let setMediaModelCb: any = null
    const sourceData = useMemo(() => state.sourceData, [state.sourceData])
    useEffect(() => {
        if (JSON.stringify(sourceData) !== JSON.stringify(state.sourceData)) {
            setMediaModelCb && setMediaModelCb()
            setMediaModelCb = null
        }
    }, [state.sourceData])

    /**
     * [Toggle sidebar minimized state, close settings if sidebar was minimized]
     * @method toggleMinimized
     */
    const toggleMinimized = () => {
        const { minimized, showSettings } = state

        if (!minimized && showSettings) {
            clearTimeout(minimizedTimeout)

            minimizedTimeout = setTimeout(() => {
                setState((prevState: IState) => {
                    return {
                        ...prevState,
                        showSettings: null,
                    }
                })
            }, transitions.normal)
        }

        setState((prevState: IState) => {
            return {
                ...prevState,
                minimized: !minimized,
            }
        })
    }

    /**
     * [Create video editor task for folder with images/videos and for single video/image file]
     * @method createVideoEditorTask
     * @param  {[object]}              data [only one key with id, sourceId or sourceFolderId]
     */
    const createVideoEditorTask = (data: { [index: string]: any }) => {
        api.send('createVideoEditorTaskFromSource', data).then((videoEditorTaskData) => {
            clearFilter()

            if (Array.isArray(videoEditorTaskData)) {
                onSelectTasks(videoEditorTaskData)
                videoEditorSidebarRef.current.setActiveSidebarTab('tasks')

                return
            }

            setMediaModel(videoEditorTaskData)
        })
    }
    /**
     * [Clear app router filter on edit media source (sourceId)]
     * @method clearFilter
     */
    const clearFilter = () => {
        if (props.sourceId) {
            changeFilter({ sourceId: undefined })
        }
    }
    /**
     * [Reset data to default with clearFilter]
     * @method reset
     */
    const reset = () => {
        clearFilter()
        // @ts-ignore
        setState(() => {
            return {
                media: null,
                showSettings: null,
                selectedTasksSourceType: null,
                selectedTasks: [],
            }
        })
    }
    /**
     * [Get dropped file from react-dropzone, execute createVideoEditorTask method]
     * @method onDrop
     * @param  {[object]} dropped [instance of file or folder, desctructed id and isDirectory fields]
     */
    const onDrop = ({ id, isDirectory }: { id: number; isDirectory: string }) => {
        const dataForCreateVideoEditorTask = {
            [isDirectory ? 'sourceFolderId' : 'sourceId']: id,
        }

        if (state.selectedTasksSourceType) {
            reset()
        }

        createVideoEditorTask(dataForCreateVideoEditorTask)
    }
    /**
     * [Multiple select tasks with specific source type. If first task with source type video was selected, for tasks with image checkbox is disabled]
     * @method onSelectTasks
     * @param  {[array]}      tasks [Array of task instance]
     */
    const onSelectTasks = (tasks: { [index: string]: any }) => {
        const { selectedTasks } = state
        const idx = 0
        const data: { [index: string]: any } = {
            selectedTasks: tasks,
        }

        if (tasks[idx]) {
            data.selectedTasksSourceType = tasks[idx].data.type

            data.selectedTasks = data.selectedTasks.filter(
                (task: { [index: string]: any }) => task.data.type === data.selectedTasksSourceType
            )
        }

        if (!selectedTasks.length && tasks.length) {
            data.selectedTasksSourceType = tasks[idx].data.type

            const { media, commands } = getTaskSourceSettings({
                data: {
                    fileURL: null,
                    fileName: null,
                    duration: 0,
                    width: 0,
                    height: 0,
                    type: data.selectedTasksSourceType,
                    fps: null,
                    error: null,
                    mimetype: 'video/mp4',
                },
                status: 'success',
                id: null,
            })

            data.media = media
            data.media.multiple = true
            commands.convertToMp4.active = true
            data.commands = commands
        }

        if (!tasks.length) {
            data.selectedTasksSourceType = null
            data.media = null
        }

        setState((prevState: IState) => {
            return {
                ...prevState,
                ...data,
            }
        })
    }
    const setMediaModel = (task: any, cb?: any) => {
        const settings = {
            fileName: `${translate('processed')}_${task.data.fileName}`,
            fileNamePrefix: translate('processed'),
            folderId: null,
        }
        const { media, commands }: any = getTaskSourceSettings(task)
        if (cb) {
            setMediaModelCb = cb
        }

        setState((prevState: IState) => {
            return {
                ...prevState,
                media,
                settings,
                commands,
                sourceData: commands,
            }
        })
    }
    const onUploadProgress = (percent: number | null) => {
        if (!percent) return
        setState((prevState: IState) => {
            return {
                ...prevState,
                downloadMediaPercentage: percent === 100 ? null : percent,
            }
        })
    }

    const onUploadComplete = (result: any) => {
        const { media } = state

        if (!result || media) {
            setState((prevState: IState) => {
                return {
                    ...prevState,
                    downloadMediaPercentage: null,
                }
            })
            return
        }

        setMediaModel(result, () => {
            setState((prevState: IState) => {
                return {
                    ...prevState,
                    downloadMediaPercentage: null,
                    showSettings: 'edit',
                }
            })
        })
    }

    const onUpload = (file: any) => {
        const { uploadVideoEditor } = props

        uploadVideoEditor({
            file,
            onProgress: onUploadProgress,
            onComplete: onUploadComplete,
        })
    }
    const onChangeTask = (item: any, type: string) => {
        const s_ = { ...state }

        if (!s_.media) {
            return
        }

        if (item.id === s_.media.id && type === 'delete') {
            // @ts-ignore
            setState((prevState: IState) => {
                return {
                    ...prevState,
                    media: null,
                }
            })
        }
    }

    const onSelectTask = (e: any) => {
        const { selectedTasksSourceType } = state

        if (!e || selectedTasksSourceType) {
            return
        }

        setMediaModel(e, () => {
            if (helpers.isMobileDevice()) {
                toggleMinimized()
            }
        })
    }
    const onProcessMedia = () => {
        validate((data: any) => {
            const { media, selectedTasks }: { media: any; selectedTasks: [] } = state
            const commands = formattingData(data)
            const processedData = {
                commands,
                id: media.id,
            }

            if (media.multiple) {
                processedData.id = selectedTasks.map((task: { [index: string]: any }) => task.id)
            }

            api.send('executeVideoEditorTask', processedData).then(() => {
                let updateStateData = {
                    showSettings: null,
                }

                if (media.multiple) {
                    updateStateData = {
                        ...updateStateData,
                        //@ts-ignore
                        selectedTasks: [],
                        selectedTasksSourceType: null,
                        media: null,
                    }
                }
                setState((prevState: IState) => {
                    return {
                        ...prevState,
                        ...updateStateData,
                    }
                })
            })
        })
    }
    const onChangeSettings = (value: string, field: string) => {
        const s_ = { ...state }
        s_.settings[field] = value

        setState(s_)
    }
    const formattingData = (data: any) => {
        const commands = []

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                if (key === 'cut') {
                    const durationSeconds = helpers.hmsToS(data[key].duration)
                    const startTimeSeconds = helpers.hmsToS(data[key].startTime)

                    data[key].duration = getDuration(durationSeconds - startTimeSeconds)
                    data[key].startTime = getDuration(startTimeSeconds)
                }

                if (data.cut && data.fadeOut) {
                    const sourceDuration = moment.duration(data.cut.duration).seconds()
                    data.fadeOut.startTime = sourceDuration - data.fadeOut.duration
                }

                const model = {
                    action: key,
                    options: data[key],
                }

                if (data[key].hasOwnProperty('active')) {
                    model.options = {}
                }

                commands.push(model)
            }
        }

        return commands
    }
    const validate = (cb: any) => {
        const s_ = { ...state }
        const { emitError } = props

        let parameters: any = {}
        const commands: any = s_.commands
        const source = s_.sourceData

        if (s_.media && s_.media.type === 'image') {
            if (!commands.imageToVideo.duration) {
                emitError('enterDuration')
                return
            }
        }

        if (!commands.convertToMp4.active) {
            if (s_.media && !helpersFiles.availableVideoMimeTypes(s_.media.mimetype)) {
                commands.convertToMp4.active = true
            }
        }
        //@ts-ignore
        Object.entries(source).forEach(([sourceKey, sourceValue]) => {
            //@ts-ignore
            Object.entries(sourceValue).forEach(([key, value]) => {
                const currentCommand = commands[sourceKey][key]
                if (currentCommand !== value) {
                    parameters[sourceKey] = {
                        ...parameters[sourceKey],
                        [key]: currentCommand,
                    }
                }

                if (sourceKey === 'crop') {
                    parameters[sourceKey] = {
                        ...parameters[sourceKey],
                        [key]: currentCommand,
                    }
                }
            })
        })

        parameters = omit(parameters)

        if (!Object.keys(parameters).length) {
            return false
        }

        if (validateTimeParameters(parameters.cut)) {
            if (validateCropParameters(parameters.crop)) {
                formattingAnimationParameters(parameters)
                const data = formattingBitrate(parameters)

                cb(data)
            }
        }
    }
    const formattingAnimationParameters = (parameters: { [index: string]: any }) => {
        const s_ = { ...state }
        const duration =
            s_.media && s_.media.type === 'image' ? s_.commands?.imageToVideo.duration : s_.media && s_.media.duration

        for (let i = 0; animationKeys.length > i; i++) {
            const animation = animationKeys[i]

            if (parameters[animation]) {
                parameters[animation].duration = parameters[animation].duration / 1000

                switch (animation) {
                    case 'fadeIn':
                        parameters[animation].startTime = 0
                        break
                    default:
                        parameters[animation].startTime = duration - parameters[animation].duration
                }
            }
        }
    }
    const validateTimeParameters = (parameters: { [index: string]: any }) => {
        const s_ = { ...state }
        const { emitError } = props

        if (!parameters) {
            return true
        }

        if (!parameters.startTime && parameters.duration) {
            parameters.startTime = moment().startOf('day').format('HH:mm:ss')
        }

        const duration = moment()
            .startOf('day')
            .seconds(s_.media && s_.media.duration)
            .format('HH:mm:ss')

        if (!parameters.duration && parameters.startTime) {
            parameters.duration = duration
        }

        if (parameters.duration < parameters.startTime) {
            emitError('endTimeCantBeLessStartTime')
            return false
        }

        if (s_.media && s_.media.multiple) {
            return true
        }

        if (parameters.startTime > duration) {
            emitError('startTimeCantBeMoreDuration')
            return false
        }

        if (parameters.duration > '00:10:00') {
            emitError('moreThan10Minutes')
            return false
        }

        return true
    }
    const validateCropParameters = (parameters: any) => {
        const { emitError } = props
        let isValidParams = true

        if (parameters) {
            const validateParamsKeys = ['width', 'height']

            for (let key of validateParamsKeys) {
                if (!parameters[key]) {
                    emitError(`${key}Error`)

                    isValidParams = false
                }
            }

            if (isValidParams && state.media && state.media.type === 'image') {
                isValidParams = validateMaxCropImageSizes(parameters)
            }
        }

        return isValidParams
    }
    /**
     * [Validate max crop values for image, check if sizes is full hd format]
     * @method validateMaxImageSizes
     * @param  {[number]}              width  [crop width]
     * @param  {[number]}              height [crop height]
     * @return {[boolean]}                    [returns valid/not valid]
     */
    const validateMaxCropImageSizes = ({ width, height }: { width: string; height: string }) => {
        const { emitError } = props
        const IMAGE_MAX_SIZE = 1920

        if (parseInt(width, 10) > IMAGE_MAX_SIZE) {
            emitError('imageWidthNoMoreThanFullHd')

            return false
        }

        if (parseInt(height, 10) > IMAGE_MAX_SIZE) {
            emitError('imageHeightNoMoreThanFullHd')

            return false
        }

        return true
    }
    const validateSelectedFolder = (folderId: number) => {
        const { emitError } = props
        if (folderId) {
            return true
        }

        emitError('chooseFolder')
        setState((prevState: IState) => {
            return {
                ...prevState,
                showSettings: 'settings',
            }
        })

        return false
    }
    const formattingBitrate = (parameters: { [index: string]: any }) => {
        if (parameters.hasOwnProperty('setVideoBitrate') && !parameters.setVideoBitrate.bitrate) {
            delete parameters.setVideoBitrate
        }

        return parameters
    }
    const save = () => {
        const { media, selectedTasks, settings } = state
        const data: { [index: string]: any } = {
            videoEditorTaskId: media?.id,
            sourceFolderId: settings.folderId,
        }

        if (!validateSelectedFolder(data.sourceFolderId)) {
            return
        }

        if (selectedTasks.length) {
            data.fileNamePrefix = settings.fileNamePrefix || translate('processed')
            data.videoEditorTaskId = selectedTasks.map((task: { [index: string]: any }) => task.id)
        } else {
            data.fileName = settings.fileName || `${translate('processed')}_${media?.name}`
        }

        api.send('saveSourceFromVideoEditorTask', data).then(() => {
            reset()
        })
    }

    return {
        state,
        setState,
        toggleMinimized,
        createVideoEditorTask,
        clearFilter,
        reset,
        onDrop,
        onSelectTasks,
        setMediaModel,
        onUploadProgress,
        onUploadComplete,
        onUpload,
        onChangeTask,
        onSelectTask,
        onProcessMedia,
        onChangeSettings,
        formattingData,
        validate,
        formattingAnimationParameters,
        validateTimeParameters,
        validateCropParameters,
        validateMaxCropImageSizes,
        validateSelectedFolder,
        formattingBitrate,
        save,
        videoEditorSidebarRef,
    }
}

export default VideoEditorMethods
