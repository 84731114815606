import { MapApi } from './MapApi'
import { MapLocation } from '../models/map'
import { isEmptyString, isExist } from 'core/utils/coreUtil'
import geocoder from 'google-geocoder'

export class GoogleMapApi extends MapApi {
    private geo: geocoder.GoogleGeocoder

    constructor(apiKey: string) {
        super(apiKey)

        this.geo = geocoder({ key: apiKey })
    }

    private parseGeocoderResults = (geocodeResults: geocoder.GoogleGeocoderResult[]): MapLocation[] => {
        const mapLocations = geocodeResults
            .map((googleGeocoderResult) => {
                const { location, formatted_address } = googleGeocoderResult
                const { lat, lng } = location

                if (!lat || !lng || !formatted_address) return null

                return {
                    lat: location.lat,
                    lng: location.lng,
                    name: formatted_address,
                    description: '',
                }
            })
            .filter((mapLocation) => isExist(mapLocation))

        return mapLocations as MapLocation[]
    }

    geocodeLocation(address: string): Promise<MapLocation[]> {
        return new Promise((resolve, reject) => {
            if (!isExist(address) || isEmptyString(address)) {
                resolve([])
                return
            }

            this.geo.find(address, (err, res) => {
                if (err || !res) {
                    reject()
                    return
                }

                resolve(this.parseGeocoderResults(res))
            })
        })
    }

    reverseGeocodeLocation(lat: number, lng: number): Promise<MapLocation[]> {
        return new Promise((resolve, reject) => {
            if (!isExist(lat) || !isExist(lng)) {
                resolve([])
                return
            }

            this.geo.reverseFind(lat, lng, (err, res) => {
                if (err || !res) {
                    reject()
                    return
                }

                resolve(this.parseGeocoderResults(res))
            })
        })
    }
}
