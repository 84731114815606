import { addStyles } from 'ethcss'
import { grid, IStyles, item } from '../../../theme'

const adBlocksToolbarContentStyles: IStyles = {
    viewInfo: {
        borderRadius: '5px',
        overflow: 'hidden',
    },
    viewInfoNoHiddenOverflow: {
        borderRadius: '5px',
    },
    wrapper: {
        ...grid.w100,
        ...grid.col,
        ...grid.space,
    },
    titleWrapper: {
        ...grid.row,
    },
    title: {
        ...grid.p_md,
        textAlign: 'center',
        ...item.ellipsisWrapper,
        '&>div': {
            ...item.ellipsis,
        },
    },
    iconsWrapper: {
        ...item.blackOpacity,
    },
    formWrapper: {
        ...grid.colCenter,
        ...grid.p_md,
    },
    formTitle: {
        ...grid.w100,
        ...grid.mb_md,
        textAlign: 'center',
    },
    formButtons: {
        ...grid.row,
        ...grid.justify,
        ...grid.mb_mini,
    },
    footer: {
        ...grid.p_md,
        ...grid.rowCenter,
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
    },
}

addStyles(adBlocksToolbarContentStyles)
export default adBlocksToolbarContentStyles
