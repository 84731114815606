import { addStyles } from 'ethcss'
import { IStyles, colors } from '../../theme'

const createDirectoryModal: IStyles = {
    wrapper: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'rgba(0,0,0, 0.2)',
        zIndex: 2001,
    },
    inner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: '450px',
        padding: '50px 50px 30px 50px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: 20,
        backgroundColor: colors.white,
    },
    title: {
        fontWeight: 700,
        fontSize: 18,
        textAlign: 'center',
        color: colors.black,
        marginBottom: 40,
    },
    inputWrapper: {
        marginBottom: 60,
    },
    controls: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 148,
        height: 34,
    },
}

addStyles(createDirectoryModal)
export default createDirectoryModal
