import React, { RefObject, WheelEvent } from 'react'
import ReactPanZoom from '@ajainarayanan/react-pan-zoom'
import ImageViewMethods from './imageView.local.methods'
import Icon from 'blocks.simple/icon/icon'
import translate from 'core/translate'
import styles from './imageView.jcss'

class ImageView extends ImageViewMethods {
    static defaultProps = {
        emptyText: translate('imageNotAvailable'),
        zoomStatusChange: () => {},
        rotate: 0,
    }

    render() {
        const s_ = this.state
        const p_ = this.props
        const imageRef: RefObject<HTMLImageElement> = React.createRef()

        const onWheelAction = (e: WheelEvent<HTMLDivElement>) => {
            imageRef.current
                ? this.onWheel(
                      e,
                      imageRef.current.clientWidth * s_.zoom,
                      window.innerWidth,
                      imageRef.current.clientHeight * s_.zoom,
                      window.outerHeight
                  )
                : this.onWheel(e)
        }

        return (
            <div className={styles.wrapper} onWheel={onWheelAction}>
                {p_.title && (
                    <div className={styles.title}>
                        <span>{p_.title}</span>
                    </div>
                )}
                {p_.src && (
                    <ReactPanZoom
                        className={styles.image}
                        zoom={s_.zoom}
                        enablePan={s_.startZoom ? true : false}
                        pandx={s_.dx}
                        pandy={s_.dy}
                        onPan={this.onPan}
                    >
                        <img
                            onDragStart={this.preventDragHandler}
                            ref={imageRef}
                            src={p_.src}
                            style={{ transform: `rotate(${this.props.rotate}deg)`, maxHeight: '100vh' }}
                            alt=""
                        />
                    </ReactPanZoom>
                )}
                {p_.controls && (
                    <div className={styles.controlsWrapper}>
                        <div className={styles.controls}>{p_.controls}</div>
                    </div>
                )}
                {!p_.src && (
                    <div className={styles.empty}>
                        <Icon type="not_allow" size={50} color="white" />
                        <div className={styles.emptyTitle}>{p_.emptyText}</div>
                    </div>
                )}
            </div>
        )
    }
}

export default ImageView
