import React from 'react'
import { BaseWidget, DefaultPortLabel } from 'storm-react-diagrams'

import { cn } from 'ethcss'
import styles from './flowDiagram__node.jcss'
import Icon from 'blocks.simple/icon/icon'

class FlowDiagramNodeWidget extends BaseWidget {
    constructor(props) {
        super('srd-default-node', props)
    }

    generatePort = (port) => {
        return <DefaultPortLabel model={port} key={port.id} />
    }

    renderIcon = () => {
        const page = this.props.node.page
        let icon = ''

        if (page.order === 0) {
            icon = 'start_page'
        }

        if (page.order === page.nextPageOrder) {
            icon = 'looped'
        }

        if (icon === '') {
            return null
        }

        return (
            <div className={styles.icon}>
                <Icon type={icon} color="white" size={20} />
            </div>
        )
    }

    render() {
        return (
            <div {...this.getProps()} style={{ background: this.props.node.color }}>
                <div>
                    <div className={cn(this.bem('__in'), styles.inPort)}>
                        {this.props.node.getInPorts().map((port) => this.generatePort(port))}
                    </div>
                </div>
                <div className={cn(this.bem('__title'), styles.content)}>
                    {this.renderIcon()}
                    <div className={this.bem('__name')}>{this.props.node.name}</div>
                </div>
                <div>
                    <div className={cn(this.bem('__out'), styles.outPort)}>
                        {this.props.node.getOutPorts().map((port) => this.generatePort(port))}
                    </div>
                </div>
            </div>
        )
    }
}

export default FlowDiagramNodeWidget
