import React from 'react'
import PropTypes from 'prop-types'

import ScrollUp from 'blocks.simple/scrollUp/scrollUp'
import CatalogMethods from './catalog.local.methods'
import CatalogList from './__list'
import CatalogTiles from './__tiles/catalog__tiles'
import CatalogHeader from './CatalogHeader'
import LoaderLazy from 'blocks.app/loader/_lazy/loader__lazy'
import InfiniteScroll from 'react-infinite-scroller'
import Icon from 'blocks.simple/icon/icon'
import Breadcrumbs from './__breadcrumbs/catalog__breadcrumbs'
import Measure from 'react-measure'
import { HotKeys } from 'react-hotkeys'

import { connect } from 'react-redux'
import { cn } from 'ethcss'
import catalogTemplate from './__template'
import helpers from 'core/helpers'
import styles from './styles'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { text as textStyles } from 'theme'
import { cols, indents } from 'blocks.app/config'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { withRouter } from 'react-router'
import { changeFilter } from 'features/routes'
import { downloadsActions } from 'core/services/DownloadService/DownloadService.state'
import { isHorizontalMenu } from 'core/helpers/menu'

class CatalogInner extends CatalogMethods {
    constructor(p_) {
        super(p_)

        this.getDragData = this.template.getDragData || function () {}
    }
    renderList = (showOnlySelected) => {
        const p_ = this.props
        const s_ = this.state
        const addItemButton = this.template.addItemButton
        const Map = this.template.map
        const disposition = this.getDisposition()
        const query = getURLSearchParamsByLocation(p_.location)
        const deviceBlockStyle = this.getDeviceBlockStyle()

        if (showOnlySelected) {
            let selectedInfo = s_.list.find((listItem) => this.isEqual(listItem, p_.selectedInfo))

            if (!selectedInfo) {
                if (s_.showOnlySelectedItem) {
                    selectedInfo = s_.showOnlySelectedItem
                } else {
                    return null
                }
            }

            const Tile = this.template.tile

            return (
                <div className={grid.col4}>
                    <Tile
                        active={true}
                        onSelectInfo={function () {}}
                        onSelect={function () {}}
                        onSelectFolder={function () {}}
                        multipleSelect={false}
                        item={selectedInfo}
                        onSelectProgress={function () {}}
                        drag={false}
                        getDragData={this.getDragData}
                        deviceBlockStyle={deviceBlockStyle}
                    />
                </div>
            )
        }

        if (!this.loading && s_.list.length === 0 && !s_.fakeLine && !this.isMap(p_) && !p_.toggleControls) {
            return (
                <div className={cn(grid.space, grid.colCenter)}>
                    <div className={cn(grid.colCenter)}>
                        <div className={cn(textStyles.normal, grid.row, grid.center, grid.mb_md, textStyles.darkCloud)}>
                            {this.template.emptyIcon && <Icon type={this.template.emptyIcon} size={60} />}
                            <div
                                className={cn(grid.fullWidth, grid.pt_mini, textStyles.center, {
                                    [grid.mb_mini]: !this.query.query && addItemButton,
                                })}
                            >
                                <div className={textStyles.up}>
                                    {this.query.query
                                        ? this.template.emptySearchText()
                                        : this.template.emptyFolderText()}
                                </div>
                                {!this.query.query && this.template.emptyDescription && (
                                    <div className={cn(grid.pt_mini, grid.rowCenter)}>
                                        <div className={grid.w50}>{this.template.emptyDescription()}</div>
                                    </div>
                                )}
                            </div>
                            {(() => {
                                if (!this.query.query && addItemButton) {
                                    return addItemButton(this)
                                }
                            })()}
                        </div>
                    </div>
                </div>
            )
        } else if (this.isMap(p_) && Map) {
            return (
                <Map
                    query={query}
                    list={s_.list}
                    tags={s_.tags}
                    options={s_.fields}
                    center={s_.center}
                    filter={this.filter}
                    onSelectInfo={this.onSelectInfo}
                    onSelectCluster={this.onSelectCluster}
                    centerMap={this.onCenterMap}
                    activeMarkerIndex={this.getActiveIndex()}
                    onGetList={(dataList) => {
                        const s_ = this.state
                        s_.list = this.getModelsList(dataList)
                        this.setState(s_)
                    }}
                />
            )
        } else if (!this.fullLoading && !p_.isHideList) {
            return (
                <HotKeys
                    keyMap={this.keyMap}
                    handlers={this.handlers}
                    className={cn(
                        styles.hotKeysWrapper,
                        { [styles.wrapperScroll]: !p_.useWindow },
                        { [item.noSelection]: this.isShiftPressed },
                        p_.classNameContent
                    )}
                >
                    <InfiniteScroll
                        loadMore={() => this.get(true)}
                        hasMore={s_.list.length < s_.total}
                        initialLoad={false}
                        loader={<LoaderLazy key="catalog_loader" active />}
                        threshold={10}
                        useWindow={p_.useWindow}
                    >
                        {disposition === 'tile' && (
                            <CatalogTiles
                                list={s_.list}
                                selectedInfo={p_.selectedInfo}
                                onSelectInfo={this.onSelectInfo}
                                isEqual={this.isEqual}
                                isFolder={this.isFolder}
                                type={p_.type}
                                multiSelect={s_.multiSelect}
                                selectedItems={p_.selectedItems}
                                onSelectFolder={(data) => {
                                    p_.onSelectInfo(null)
                                    changeFilter({
                                        [helpers.prefixQuery({ name: p_.type, field: 'query' })]: undefined,
                                        [this.template.folderName]: data.id,
                                    })
                                }}
                                onDoubleClick={(isClicked) => this.setState({ isClicked })}
                                onDoubleClickObject={p_.onDoubleClickObject}
                                onSelect={this.onSelect}
                                onSelectProgress={this.onSelectProgress}
                                drag={this.isDraggable(p_, this.template)}
                                minimize={p_.minimize}
                                cols={p_.cols}
                                template={this.template}
                                disableFolderSelection={p_.disableFolderSelection}
                                disableMultiSelect={p_.disableMultiSelect}
                                onDragStart={this.onDragStart}
                                onDragEnd={this.onDragEnd}
                                getDragData={this.getDragData}
                                isMobileDragEnabled={p_.isMobileDragEnabled}
                                deviceBlockStyle={deviceBlockStyle}
                            />
                        )}
                        {disposition === 'list' && (
                            <div
                                className={cn(styles.listWrapper, this.template.listWrapperClassName, {
                                    [styles.indentsListWrapper]: !this.template.headerFilters,
                                })}
                                style={!this.template.disableListScroll ? { overflowX: 'auto' } : null}
                            >
                                <CatalogList
                                    options={s_.fields}
                                    list={s_.list}
                                    type={p_.type}
                                    multiSelect={s_.multiSelect}
                                    selectedInfo={p_.selectedInfo}
                                    treeView={p_.treeView}
                                    onSelectInfo={this.onSelectInfo}
                                    selectedItems={p_.selectedItems}
                                    isEqual={this.isEqual}
                                    isFolder={this.isFolder}
                                    onSelectFolder={(data) => {
                                        p_.onSelectInfo(null)
                                        changeFilter({
                                            [helpers.prefixQuery({ name: p_.type, field: 'query' })]: undefined,
                                            [this.template.folderName]: data.id,
                                        })
                                    }}
                                    onDoubleClickObject={p_.onDoubleClickObject}
                                    onFolderOpening={this.onFolderOpening}
                                    onSelect={this.onSelect}
                                    template={this.template}
                                    disableFolderSelection={p_.disableFolderSelection}
                                    fakeLine={s_.fakeLine}
                                    onAddFakeLine={(fakeLine) => {
                                        this.setState({ fakeLine })
                                    }}
                                    drag={this.isDraggable(p_, this.template)}
                                    additionalData={p_.additionalData}
                                    disableMultiSelect={p_.disableMultiSelect}
                                    onDragStart={this.onDragStart}
                                    onDragEnd={this.onDragEnd}
                                    getDragData={this.getDragData}
                                    onEditListItem={p_.onEditListItem}
                                />
                            </div>
                        )}
                    </InfiniteScroll>
                </HotKeys>
            )
        }

        return null
    }
    render() {
        const s_ = this.state
        const p_ = this.props
        let filterOptions = s_.fields
        const showOnlySelected = p_.showOnlySelected && p_.selectedInfo
        const isFixedHeader = p_.fixedHeader && !this.isMap(p_)
        const viewParams = this.getViewParams()

        if (!this.template.showFilter) {
            filterOptions = null
        }
        const HeaderFilter = this.template.headerFilters

        if (s_.isInit) {
            return (
                <div className={cn(styles.wrapper, p_.wrapperClassName)}>
                    <Measure
                        bounds
                        onResize={(contentRect) => {
                            this.setState({
                                dimensions: contentRect.bounds,
                            })
                        }}
                    >
                        {({ measureRef }) => (
                            <div
                                ref={measureRef}
                                className={cn(
                                    { [styles.fixedHeader]: isFixedHeader },
                                    isHorizontalMenu() ? styles.fixedHeader_x : ''
                                )}
                                style={
                                    isFixedHeader
                                        ? {
                                              left: `${p_.minimize ? cols.mini : cols.normal}%`,
                                              right: p_.isExistRightToolbar ? `${cols.normal}%` : 0,
                                          }
                                        : null
                                }
                            >
                                {!p_.hideHeader && !showOnlySelected && s_.breadcrumbs && (
                                    <div className={p_.breadcrumbsClassName}>
                                        <Breadcrumbs
                                            filterName={this.template.folderName}
                                            list={s_.breadcrumbs}
                                            type={p_.type}
                                            template={this.template}
                                            selectedInfo={p_.selectedInfo}
                                            selectedItems={p_.selectedItems}
                                            multipleSelect={!!p_.onSelect}
                                        />
                                    </div>
                                )}
                                {!p_.hideHeader && !showOnlySelected && (
                                    <CatalogHeader
                                        query={this.query}
                                        placeholder={p_.searchPlaceholder}
                                        viewParams={viewParams}
                                        type={p_.type}
                                        isMap={this.isMap(p_)}
                                        filterOptions={filterOptions}
                                        searchbarMod={p_.searchbarMod}
                                        searchable={p_.searchable}
                                        showHeaderButton={p_.showHeaderButton}
                                        centerMap={(center) => this.setState({ center })}
                                        list={s_.list}
                                        small={p_.small}
                                        template={this.template}
                                        onSelect={p_.onSelect}
                                        onSelectAll={this.onSelectAll}
                                        onSelectInfo={this.onSelectInfo}
                                        isEqual={this.isEqual}
                                        onDeselectAll={this.onDeselectAll}
                                        multiSelect={s_.multiSelect}
                                        onActivateMultiSelect={this.onActivateMultiSelect}
                                    />
                                )}
                            </div>
                        )}
                    </Measure>
                    {isFixedHeader && s_.dimensions.height && (
                        <div
                            style={{
                                height: `${s_.dimensions.height}px`,
                                marginTop: -indents.mdPlus,
                                visibility: 'hidden',
                            }}
                        />
                    )}
                    {!showOnlySelected && HeaderFilter && (
                        <HeaderFilter
                            query={this.query}
                            small={p_.small}
                            type={p_.type}
                            selectedItems={p_.selectedItems}
                            fakeLine={s_.fakeLine}
                            toggleControls={p_.toggleControls}
                            onAddFakeLine={(fakeLine) => {
                                this.setState({ fakeLine })
                            }}
                            filterItems={p_.filterItems}
                        />
                    )}
                    {!p_.hideList && this.renderList(showOnlySelected)}
                    {isFixedHeader && <ScrollUp isExistRightToolbar={p_.isExistRightToolbar} />}
                </div>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => {
    return {
        minimize: state.app.minimizeMenu,
        identificatedItems: state.displays.identificatedItems,
        user: state.user.data,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleDownloadsModal: () => dispatch(downloadsActions.toggleModal()),
    }
}

const Connected = connect(mapStateToProps, mapDispatchToProps)(withRouter(CatalogInner))

class Catalog extends React.Component {
    render() {
        const p_ = this.props

        return <Connected {...p_} />
    }
}

Catalog.propTypes = {
    type: PropTypes.string,
    disposition: PropTypes.string,
    searchbarMod: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    classNameContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    wrapperClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    useWindow: PropTypes.bool,
    drag: PropTypes.bool,
    small: PropTypes.bool,
    disableFolderSelection: PropTypes.bool,
    showOnlySelected: PropTypes.bool,
    showHeaderButton: PropTypes.bool,
    fixedHeader: PropTypes.bool,
    treeView: PropTypes.bool,
    isExistRightToolbar: PropTypes.bool,
    selectedInfo: PropTypes.object,
    additionalData: PropTypes.object,
    filterOptions: PropTypes.object,
    selectedItems: PropTypes.array,
    cols: PropTypes.number,
    onSelectInfo: PropTypes.func,
    onSelect: PropTypes.func,
    onGetTags: PropTypes.func,
    onGetRootFolder: PropTypes.func,
    onSelectProgress: PropTypes.func,
    breadcrumbsClassName: PropTypes.string,
    mapSelect: PropTypes.func,
    mapUnselect: PropTypes.func,
    onChange: PropTypes.func,
    disableMultiSelect: PropTypes.func,
    onDragStart: PropTypes.func,
    onDragEnd: PropTypes.func,
    onEditListItem: PropTypes.func,
    onDoubleClickObject: PropTypes.func,
    filterItems: PropTypes.array,
    hideHeader: PropTypes.bool,
    hideList: PropTypes.bool,
    toggleControls: PropTypes.bool,
    isHideList: PropTypes.bool,
    searchable: PropTypes.bool,
    isMobileDragEnabled: PropTypes.bool,
    getParams: PropTypes.object,
}
Catalog.defaultProps = {
    useWindow: true,
    searchable: true,
    onSelectInfo: () => {},
    onGetTags: () => {},
    onGetRootFolder: () => {},
    onSelectProgress: () => {},
    onDragStart: () => {},
    onDragEnd: () => {},
    mapSelect: () => {},
    mapUnselect: () => {},
    onChange: () => {},
    onDoubleClickObject: () => {},
    disableMultiSelect: () => false,
    onGetList: () => {},
    isExistRightToolbar: true,
    toggleControls: false,
    isHideList: false,
    drag: false,
    treeView: true,
    filterOptions: {},
}
CatalogInner.propTypes = Catalog.propTypes

export default Catalog
