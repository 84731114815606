import React, { DragEvent } from 'react'

interface IImageViewProps {
    src: string
    title?: string
    emptyText?: string
    controls?: React.ReactNode
    zoomStatusChange: any
    rotate?: number
}

const MIN_IMAGE_ZOOM = 0.1
const MAX_IMAGE_ZOOM = 4.0
const ZOOM_RATIO = 0.1
const defaultState: {
    dx: number
    dy: number
    zoom: number
    maxZoom: boolean
    minZoom: boolean
    startZoom: boolean
} = {
    dx: 0,
    dy: 0,
    zoom: 1,
    startZoom: false,
    maxZoom: false,
    minZoom: false,
}

class ImageViewMethods extends React.Component<IImageViewProps> {
    state = {
        ...defaultState,
    }
    onPan = (dx: number, dy: number) => {
        this.setState({
            dx,
            dy,
        })
    }

    preventDragHandler = (e: DragEvent<HTMLImageElement>) => {
        e.preventDefault()
    }

    onZoomCenterPosition = (widthRef: number, innerWidth: number, heightRef: number, outerHeight: number): void => {
        if (widthRef > innerWidth || heightRef > outerHeight) {
            this.setState({
                dx: 0,
                dy: 0,
            })
        }
    }

    shouldComponentUpdate(nextProps: IImageViewProps) {
        const p_ = this.props

        if (p_.src !== nextProps.src) {
            this.setState({ ...defaultState })
        }

        return true
    }

    onWheel = (e: any, widthRef?: number, innerWidth?: number, heightRef?: number, outerHeight?: number) => {
        e.stopPropagation()
        e.preventDefault()

        const delta = e.deltaY || e.detail || e.wheelDelta
        if (delta > 0) {
            this.zoomOut(widthRef, innerWidth, heightRef, outerHeight)
        } else {
            this.zoomIn(widthRef, innerWidth, heightRef, outerHeight)
        }

        return true
    }

    zoomIn = (widthRef?: number, innerWidth?: number, heightRef?: number, outerHeight?: number) => {
        const s_ = this.state
        let zoom = s_.zoom

        zoom += ZOOM_RATIO

        this.setState({ startZoom: true })
        this.zoomStatusChange(zoom, 'maxZoom')

        if (zoom <= MAX_IMAGE_ZOOM) {
            this.setState({ zoom })
        }

        if (widthRef && innerWidth && heightRef && outerHeight) {
            this.onZoomCenterPosition(widthRef, innerWidth, heightRef, outerHeight)
        }
    }

    zoomOut = (widthRef?: number, innerWidth?: number, heightRef?: number, outerHeight?: number) => {
        const s_ = this.state
        let zoom = s_.zoom

        zoom -= ZOOM_RATIO

        this.setState({ startZoom: true })
        this.zoomStatusChange(zoom, 'minZoom')

        if (zoom >= MIN_IMAGE_ZOOM) {
            this.setState({ zoom })
        }

        if (widthRef && innerWidth && heightRef && outerHeight) {
            this.onZoomCenterPosition(widthRef, innerWidth, heightRef, outerHeight)
        }
    }

    zoomStatusChange = (zoom: number, field: string) => {
        const p_ = this.props
        const s_ = this.state

        const data = {
            minZoom: false,
            maxZoom: false,
        }

        zoom = parseFloat(zoom.toFixed(1))

        switch (field) {
            case 'minZoom':
                if (zoom <= MIN_IMAGE_ZOOM) {
                    data.minZoom = true
                    data.maxZoom = false
                }
                break
            default:
                if (zoom >= MAX_IMAGE_ZOOM) {
                    data.minZoom = false
                    data.maxZoom = true
                }
        }

        this.setState({ ...data })

        if (s_.maxZoom !== data.maxZoom || s_.minZoom !== data.minZoom) {
            p_.zoomStatusChange(data)
        }
    }
}

export default ImageViewMethods
