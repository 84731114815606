import styles from './downloadWidget.jcss'
import React, { useEffect, useState } from 'react'
import { IWidgetData } from '../widget/Widget'
import Button from '../../../blocks.simple/button/button'
import translate from '../../../core/translate'
import { api } from '../../../core/api/ConnectionManager'
import helpers from '../../../core/helpers'
import Dropdown from '../../../blocks.simple/dropdown/dropdown'
import { changeLocation } from '../../../features/routes'
import { getURLSearchParamsString } from '../../../features/routes/utils'

interface IDownloadWidgetProps {
    data: IWidgetData
    lang: string
    folderId?: string
    onClose: () => void
    onCloseModal?: () => void
}

export const DownloadWidget = ({ data, onClose, onCloseModal, lang, folderId }: IDownloadWidgetProps) => {
    const [folders, setFolders] = useState<any>([])
    const [selectedFolderId, setSelectedFolderId] = useState<any>(folderId ? Number(folderId) : null)
    const [isUploaded, setIsUploaded] = useState<boolean>(false)

    const onChange = (selected: any) => {
        setSelectedFolderId(selected.id)
    }
    const upload = () => {
        api.send('installWidget', { widgetId: data.id, folderId: selectedFolderId })

        setIsUploaded(true)

        if (onCloseModal) {
            onCloseModal()
        }
    }
    const goToFolder = () => {
        changeLocation({
            pathname: `/files`,
            search: getURLSearchParamsString({
                folderId: selectedFolderId,
            }),
        })
    }

    useEffect(() => {
        api.send('getAvailableFoldersFiles', { getAllUserFoldersQ: true, nestedQ: false }, { hideLoader: true }).then(
            (availableFolders: any) => {
                const folders = helpers.addParentKeyIdForTree(availableFolders, 'folderId')
                if (!selectedFolderId) {
                    const mainFolder: any = folders.find((folder: any) => !folder.parentId)
                    if (mainFolder) {
                        setSelectedFolderId(mainFolder.id)
                    }
                }

                setFolders(folders)
            }
        )
    }, [])

    return (
        <div className={styles.wrapper}>
            <div className={styles.downloadModal}>
                {isUploaded ? (
                    <div>
                        <div className={styles.success}>{translate('widgetIsAdded')}</div>
                        <div className={styles.btnGroup}>
                            <Button mod={'withBorder'} className={styles.modalBtn} onClick={onClose}>
                                {translate('close')}
                            </Button>
                            <Button mod={'fill'} className={styles.modalBtn} onClick={goToFolder} disabled={false}>
                                {translate('goToFolder')}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className={styles.widgetLeft}>
                            <div>
                                <img src={data.thumbnailURL} />
                            </div>
                            <div className={styles.titleAndDesc}>
                                <div className={styles.title}>{data.title[lang]}</div>
                                <div className={styles.shortDesc}>{data.shortDescription[lang]}</div>
                            </div>
                        </div>
                        <div className={styles.saveToWrapper}>
                            <div className={styles.saveTo}>{translate('saveTo')}:</div>
                            <Dropdown
                                value={selectedFolderId}
                                options={folders}
                                searchable
                                centered
                                type="tree"
                                placeholder={translate('main')}
                                onChange={onChange}
                            />
                        </div>
                        <div className={styles.btnGroup}>
                            <Button mod={'withBorder'} className={styles.modalBtn} onClick={onClose}>
                                {translate('cancel')}
                            </Button>
                            <Button mod={'fill'} className={styles.modalBtn} onClick={upload} disabled={false}>
                                {translate('upload')}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
