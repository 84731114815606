import React, { useState } from 'react'
import Modal from 'blocks.simple/modal/modal'
import Icon from 'blocks.simple/icon/icon'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import Button from 'blocks.simple/button/button'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import { cn } from 'ethcss'
import styles from './filter.jcss'
import { text as textStyles, item, grid } from 'theme'
import translate from 'core/translate'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { Typography } from 'atoms/Typography'
import EditText from 'blocks.simple/editText/editText'
import { IState } from '../NotificationСenterHooks'

interface Props {
    filterFields: string
    setFilterFields?: any
    setState: (state: any) => void
    state: IState
}

const NotificationFilter: React.FC<Props> = ({ filterFields, setFilterFields, setState, state }) => {
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)
    const [selectedFilter, setSelectedFilter] = useState<any>(null)
    const [selected, setSelected] = useState<any>({})
    const openFilter = () => {
        setIsFilterOpen(true)
        setSelectedFilter(null)
    }
    const onFilterChange = () => {
        const filter = { ...selected }

        delete filter.startDate
        delete filter.endDate
        selectedFilter.id === 'date' && setFilterFields('byStartDateChecked')
        selectedFilter.id === 'date' && setState((prevState: IState) => {
            return {
                ...prevState,
                apply: !prevState.apply,
            }
        })

        for (let key in filter) {
            if (filter.hasOwnProperty(key)) {
                if (key === 'userId') {
                    const usersId = filter[key].map((user: any) => user.id)
                    filter['userId'] = usersId.join('//')
                } else {
                    filter[key] = filter[key].join('//')
                }
            }
        }

        closeFilter()
    }

    const cancelFilter = () => {
        setFilterFields('')
        setIsFilterOpen(false)
    }

    const closeFilter = () => {
        setIsFilterOpen(false)
    }
    const notificationOptions = [
        { id: 'status', name: translate('status') },
        { id: 'type', name: translate('type') },
        { id: 'category', name: translate('category') },
        { id: 'date', name: translate('date') },
    ]

    return (
        <div className={styles.filter}>
            <Button
                mod="withShadow"
                icon="filter"
                iconPos="left"
                iconColor={
                    isFilterOpen
                        ? getThemeStyleValue('app', 'app', 'active') || defaultThemeStyles.active
                        : getThemeStyleValue('app', 'app', 'disable') || defaultThemeStyles.disable
                }
                indentSize="normal"
                onClick={openFilter}
                fullWidth={true}
            >
                <span
                    style={{
                        color:
                            isFilterOpen
                                ? getThemeStyleValue('app', 'app', 'active') || defaultThemeStyles.active
                                : getThemeStyleValue('app', 'app', 'disable') || defaultThemeStyles.disable,
                    }}
                >
                    {translate('filter')}
                </span>
            </Button>
            <div className={item.abs}>
                <Modal open={isFilterOpen} showCloseIcon={false} mod={'clear'} transitionName={'fade'}>
                    <div className={styles.modalWrapper}>
                        <div className={styles.modalInner}>
                            <Rectangle className={cn(grid.col)}>
                                <div className={cn(textStyles.mini, grid.col, grid.space, styles.mobileScroll)}>
                                    <div className={cn(grid.rowCenter)}>
                                        <div className={cn(grid.rowCenter, textStyles.normal)}>
                                            <Icon type="filter" className={grid.mr_micro} />
                                            <Typography type="title">{translate('filter')}</Typography>
                                        </div>
                                    </div>
                                    <div className={grid.p_mini}>
                                        <Dropdown
                                            placeholder={translate('filter')}
                                            options={notificationOptions}
                                            onChange={(e) => setSelectedFilter(e)}
                                            value={selectedFilter ? selectedFilter.id : null}
                                        />
                                        {!selectedFilter && (
                                            <Typography type="text" className={cn(grid.pt_micro, textStyles.center)}>
                                                {translate('notificationsFilter')}
                                            </Typography>
                                        )}
                                    </div>

                                    {selectedFilter && selectedFilter.id === 'status' && (
                                        <div className={cn(grid.col, grid.space, styles.mobileScroll)}>
                                            <div className={cn(styles.filterFields)}>
                                                <Checkbox
                                                    className={grid.pb_mini}
                                                    onClick={() => setFilterFields('read')}
                                                    checked={filterFields === 'read'}
                                                    label={translate('read')}
                                                />
                                                <Checkbox
                                                    onClick={() => setFilterFields('unread')}
                                                    checked={filterFields === 'unread'}
                                                    label={translate('notRead')}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {selectedFilter && selectedFilter.id === 'type' && (
                                        <div className={cn(grid.col, grid.space, styles.mobileScroll)}>
                                            <div className={cn(styles.filterFields)}>
                                                <Checkbox
                                                    onClick={() => setFilterFields('information')}
                                                    checked={filterFields === 'information'}
                                                    label={translate('information')}
                                                    className={grid.pb_mini}
                                                />
                                                <Checkbox
                                                    onClick={() => setFilterFields('warning')}
                                                    checked={filterFields === 'warning'}
                                                    label={translate('warning')}
                                                    className={grid.pb_mini}
                                                />
                                                <Checkbox
                                                    onClick={() => setFilterFields('error')}
                                                    checked={filterFields === 'error'}
                                                    label={translate('error')}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {selectedFilter && selectedFilter.id === 'category' && (
                                        <div className={cn(grid.col, grid.space, styles.mobileScroll)}>
                                            <div className={cn(styles.filterFields)}>
                                                <Checkbox
                                                    onClick={() => setFilterFields('default')}
                                                    checked={filterFields === 'default'}
                                                    label={translate('information')}
                                                    className={grid.pb_mini}
                                                />
                                                <Checkbox
                                                    onClick={() => setFilterFields('license')}
                                                    checked={filterFields === 'license'}
                                                    label={translate('licensing')}
                                                    className={grid.pb_mini}
                                                />
                                                <Checkbox
                                                    onClick={() => setFilterFields('digitalSignage')}
                                                    checked={filterFields === 'digitalSignage'}
                                                    label={translate('digitalSignage_permissions')}
                                                    className={grid.pb_mini}
                                                />   <Checkbox
                                                    onClick={() => setFilterFields('engineeringWorks')}
                                                    checked={filterFields === 'engineeringWorks'}
                                                    label={translate('engineeringWorks')}
                                                    className={grid.pb_mini}
                                                />
                                                <Checkbox
                                                    onClick={() => setFilterFields('broadcast')}
                                                    checked={filterFields === 'broadcast'}
                                                    label={translate('broadcasts')}
                                                    className={grid.pb_mini}
                                                />
                                                <Checkbox
                                                    onClick={() => setFilterFields('content')}
                                                    checked={filterFields === 'content'}
                                                    label={translate('content')}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {selectedFilter && selectedFilter.id === 'date' && (
                                        <div className={cn(grid.col, grid.space, grid.p_mini)}>
                                            <div >
                                                <EditText
                                                    containerClass={cn(grid.mt_md, grid.mb_mini,)}
                                                    type={'date'}
                                                    label={translate('startDate')}
                                                    dateValue={state.startDate}
                                                    onChange={(startDate: string) => setState((prevState: IState) => {
                                                        return {
                                                            ...prevState,
                                                            startDate: startDate,
                                                        }
                                                    })}
                                                />
                                                <div>
                                                    <EditText
                                                        containerClass={cn(styles.zIndex)}
                                                        type={'date'}
                                                        label={translate('endDate')}
                                                        dateValue={state.endDate}
                                                        onChange={(endDate: string) => setState((prevState: IState) => {
                                                            return {
                                                                ...prevState,
                                                                endDate: endDate,
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>


                                <div className={styles.buttons}>
                                    <div />
                                    <div className={grid.row}>
                                        <Button
                                            className={cn(grid.mr_mini)}
                                            onClick={cancelFilter}
                                            textSize={'mini'}
                                            indentSize={'mini'}
                                            rounded={'full_normal'}
                                        >
                                            {translate('cancel')}
                                        </Button>
                                        <Button
                                            mod={'fill'}
                                            textSize={'mini'}
                                            indentSize={'mini'}
                                            rounded={'full_normal'}
                                            onClick={onFilterChange}
                                            disabled={!selectedFilter}
                                        >
                                            {translate('apply')}
                                        </Button>
                                    </div>
                                </div>
                            </Rectangle>
                        </div>
                    </div>
                </Modal >
            </div >
        </div >
    )
}

export default NotificationFilter
