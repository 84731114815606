import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { textSizes } from 'theme/media'
import { IStyles } from '../../../theme'

const deviceSettingsKioskModeStyles: IStyles = {
    title: {
        fontSize: textSizes.normal,
        ...grid.mb_md,
    },
    radioWrapper: {
        ...grid.row,
        ...grid.fullWidth,
    },
    radio: {
        ...grid.mr_md,
    },
}

addStyles(deviceSettingsKioskModeStyles)
export default deviceSettingsKioskModeStyles
