import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { MapMarker, TMapPoint, MapCluster, MapOptions, GoogleMapChange } from '../../models/map'
import { MAP_CENTER, MAP_ZOOM } from '../../constants'
import { isExist, isNotEmptyArray } from 'core/utils/coreUtil'
import { MapProps } from 'features/map/models/map'
import { MapMarker as Placemark } from 'features/map/atoms/MapMarker'
import { compareMapProps, getDistance, getInitialMapOptions } from 'features/map/utils'
import { useClusters } from 'features/map/hooks/useClusters'
import { useMapOptions } from 'features/map/hooks/useMapOptions'

const getMapCenter = (center: TMapPoint) => {
    if (!isExist(center))
        return {
            lat: MAP_CENTER.lat,
            lng: MAP_CENTER.lng,
        }

    return center.lat && center.lng
        ? { lat: center.lat, lng: center.lng }
        : { lat: MAP_CENTER.lat, lng: MAP_CENTER.lng }
}

const isMapPropsEqual = compareMapProps

const defaultMapOptions = {
    minZoom: 3,
    minZoomOverride: true,
    fullscreenControl: false,
    clickableIcons: false,
}

const GoogleMap: FC<MapProps> = memo(
    ({
        onChange,
        onClusterClick,
        onMarkerClick,
        markers,
        activeMarkerList,
        activeMarker,
        activeMarkerColor,
        markerColor,
        center,
        zoom = MAP_ZOOM,
        mapApi,
    }) => {
        const apiKey = mapApi.getApiKey()
        const { clusters, updateClusters } = useClusters()
        const { mapOptions, updateMapOptions } = useMapOptions(getInitialMapOptions(center, zoom))

        useEffect(() => {
            updateClusters(mapOptions, markers)
        }, [mapOptions])

        const getMapSettings = () => {
            return {
                key: apiKey,
                libraries: ['places'].join(','),
                language: 'ru',
            }
        }

        const fullActiveMarkerList = useMemo(() => {
            if (!activeMarker) return activeMarkerList

            return activeMarkerList.filter((marker) => marker.id !== activeMarker.id).concat(activeMarker)
        }, [activeMarker, activeMarkerList])

        const findIntersections = (mapMarkerIds: number[]) => {
            const activeMapMarkerIds = fullActiveMarkerList.map((mapMarker) => mapMarker.id)

            return mapMarkerIds.filter((id) => activeMapMarkerIds.includes(id))
        }

        const isMarkerActive = (mapMarker: MapMarker) => {
            if (!isExist(mapMarker) || !isExist(fullActiveMarkerList) || !isNotEmptyArray(fullActiveMarkerList))
                return false

            return isNotEmptyArray(findIntersections([mapMarker.id]))
        }

        const isClusterActive = (cluster: MapCluster) => {
            if (!isExist(fullActiveMarkerList) || !isExist(cluster)) return false

            const clusterIds = cluster.points.map((point) => point.id)
            return isNotEmptyArray(findIntersections(clusterIds))
        }

        const onMapChange = ({ center, zoom, bounds }: GoogleMapChange) => {
            const distance = getDistance(center, bounds)

            if (onChange) {
                onChange(center, distance)
            }

            updateMapOptions({
                center,
                zoom,
                bounds,
            })
        }

        const getDistanceToMouse = ({ x, y }: GoogleMapReact.Point, { x: mouseX, y: mouseY }: GoogleMapReact.Point) => {
            const newX = x + 30
            const newY = y + 30

            return Math.sqrt((newX - mouseX) * (newX - mouseX) + (newY - mouseY) * (newY - mouseY))
        }

        const renderMarkers = useMemo(() => {
            if (!markers || !isNotEmptyArray(markers)) return null

            return markers.map((item, index) => {
                const isActive = isMarkerActive(item)
                const customColor = isActive ? markerColor : activeMarkerColor
                const color = customColor || item.status

                return (
                    <Placemark
                        index={index}
                        key={`marker_key_${index}`}
                        lat={item.lat}
                        lng={item.lng}
                        color={color}
                        onClick={function () {
                            onMarkerClick(item)
                        }}
                        isActive={isActive}
                    />
                )
            })
        }, [markers, fullActiveMarkerList])

        const renderOnePointCluster = (cluster: MapCluster, index: number) => {
            const clusterPoint = cluster.points[0]
            const isActive = isMarkerActive(clusterPoint)
            const customColor = isActive ? markerColor : activeMarkerColor
            const color = customColor || clusterPoint.status

            return (
                <Placemark
                    index={index}
                    key={`marker_key_${index}`}
                    lat={cluster.lat}
                    lng={cluster.lng}
                    color={color}
                    onClick={function () {
                        onMarkerClick(clusterPoint)
                    }}
                    isActive={isActive}
                />
            )
        }

        const renderMultiplePointCluster = (cluster: MapCluster, index: number) => {
            const isActive = isClusterActive(cluster)

            return (
                <Placemark
                    index={index}
                    key={`marker_key_${index}`}
                    lat={cluster.lat}
                    lng={cluster.lng}
                    points={cluster.points}
                    onClick={function () {
                        onClusterClick(cluster.points)
                    }}
                    isActive={isActive}
                />
            )
        }

        const renderClusters = useMemo(() => {
            if (!clusters || !isNotEmptyArray(markers)) return null

            return clusters.map((cluster: any, index: number) => {
                return cluster.numPoints === 1
                    ? renderOnePointCluster(cluster, index)
                    : renderMultiplePointCluster(cluster, index)
            })
        }, [clusters, fullActiveMarkerList])

        const isRenderMarkers = () => !isNotEmptyArray(clusters) && isExist(markers) && isNotEmptyArray(markers)

        const isRenderClusters = () => isNotEmptyArray(clusters)

        const renderMapContent = () => {
            if (isRenderMarkers()) return renderMarkers
            if (isRenderClusters()) return renderClusters

            return null
        }

        return (
            <GoogleMapReact
                yesIWantToUseGoogleMapApiInternals={true}
                center={getMapCenter(center)}
                zoom={zoom}
                options={defaultMapOptions}
                bootstrapURLKeys={getMapSettings()}
                onChange={onMapChange}
                hoverDistance={40}
                distanceToMouse={getDistanceToMouse}
            >
                {renderMapContent()}
            </GoogleMapReact>
        )
    },
    isMapPropsEqual
)

export { GoogleMap }
