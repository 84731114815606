import { addStyles } from 'ethcss'
import { grid, IStyles, item, text } from 'theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'

export const styles: IStyles = {
    ConfirmNotificationWithChoices: {
        boxShadow: `var(--var-module-app-component-app-style-shadow, ${defaultThemeStyles.shadow})`,
        ...item.abs,
        ...item.white,
        top: 0,
        left: 0,
        right: 0,
    },
    ConfirmNotificationWithChoices__button: {
        ...grid.p_md,
        ...grid.rowCenter,
        ...item.pointer,
    },
    ConfirmNotificationWithChoices__info: {
        ...grid.p_md,
        ...grid.rowCenter,
        background: `var(--var-module-app-component-app-style-background, ${defaultThemeStyles.background})`,
        ...item.pointer,
        textAlign: 'center',
    },
}

addStyles(styles)
