import { addStyles } from 'ethcss'
import { colors, item, text, IStyles } from 'theme'
import { TThemeColorTypes } from 'theme/colors'
import { hexToRgba } from 'core/utils'
import { defaultThemeStyles } from './Button-theme'

export const buttonStyles: IStyles = {
    wrapper: {
        ...item.overNone,
        ...item.transition,
    },
    content: {
        ...text.normal,
        ...text.up,
        letterSpacing: '0.1em',
        opacity: 0.8,
    },
    button_type_default: {
        color: `var(--var-module-ui-component-button-style-default-textMain, ${defaultThemeStyles['default-textMain']})`,
        background: `var(--var-module-ui-component-button-style-default-background, ${defaultThemeStyles['default-background']})`,
        border: `1px solid var(--var-module-ui-component-button-style-default-border, ${defaultThemeStyles['default-border']})`,
        boxShadow: `var(--var-module-ui-component-button-style-default-shadow, ${defaultThemeStyles['default-shadow']})`,
    },
    button_type_list: {
        color: `var(--var-module-ui-component-button-style-list-textMain, ${defaultThemeStyles['list-textMain']})`,
        background: `var(--var-module-ui-component-button-style-list-background, ${defaultThemeStyles['list-background']})`,
        boxShadow: `var(--var-module-ui-component-button-style-list-shadow, ${defaultThemeStyles['list-shadow']})`,
    },
    button_type_withBorder: {
        color: `var(--var-module-ui-component-button-style-withBorder-textMain, ${defaultThemeStyles['withBorder-textMain']})`,
        background: `var(--var-module-ui-component-button-style-withBorder-background, ${defaultThemeStyles['withBorder-background']})`,
        border: `1px solid var(--var-module-ui-component-button-style-withBorder-border, ${defaultThemeStyles['withBorder-border']})`,
        boxShadow: `var(--var-module-ui-component-button-style-withBorder-shadow, ${defaultThemeStyles['withBorder-shadow']})`,
    },
    button_type_withShadow: {
        color: `var(--var-module-ui-component-button-style-withShadow-textMain, ${defaultThemeStyles['withShadow-textMain']})`,
        background: `var(--var-module-ui-component-button-style-withShadow-background, ${defaultThemeStyles['withShadow-background']})`,
        border: `1px solid var(--var-module-ui-component-button-style-withShadow-border, ${defaultThemeStyles['withShadow-border']})`,
        boxShadow: `var(--var-module-ui-component-button-style-withShadow-shadow, ${defaultThemeStyles['withShadow-shadow']})`,
    },
    button_type_fill: {
        color: `var(--var-module-ui-component-button-style-fill-textMain, ${defaultThemeStyles['fill-textMain']})`,
        background: `var(--var-module-ui-component-button-style-fill-background, ${defaultThemeStyles['fill-background']})`,
        border: `1px solid var(--var-module-ui-component-button-style-fill-border, ${defaultThemeStyles['fill-border']})`,
        boxShadow: `var(--var-module-ui-component-button-style-fill-shadow, ${defaultThemeStyles['fill-shadow']})`,
    },
    button_type_error: {
        color: `var(--var-module-ui-component-button-style-error-textMain, ${defaultThemeStyles['error-textMain']})`,
        background: `var(--var-module-ui-component-button-style-error-background, ${defaultThemeStyles['error-background']})`,
        border: `1px solid var(--var-module-ui-component-button-style-error-border, ${defaultThemeStyles['error-border']})`,
        boxShadow: `var(--var-module-ui-component-button-style-error-shadow, ${defaultThemeStyles['error-shadow']})`,
    },
    button_type_default_activated: {
        color: `var(--var-module-ui-component-button-style-default-active, ${defaultThemeStyles['default-active']})`,
    },
    button_type_withBorder_activated: {
        color: `var(--var-module-ui-component-button-style-withBorder-active, ${defaultThemeStyles['withBorder-active']})`,
        border: `1px solid var(--var-module-ui-component-button-style-withBorder-activeBorder, ${defaultThemeStyles['withBorder-activeBorder']})`,
    },
    button_type_withShadow_activated: {
        color: `var(--var-module-ui-component-button-style-withShadow-active, ${defaultThemeStyles['withShadow-active']})`,
    },
    button_type_fill_activated: {
        color: `var(--var-module-ui-component-button-style-fill-active, ${defaultThemeStyles['fill-active']})`,
    },
    button_type_error_activated: {
        color: `var(--var-module-ui-component-button-style-error-active, ${defaultThemeStyles['error-active']})`,
    },
    button_type_list_activated: {
        color: `var(--var-module-ui-component-button-style-list-active, ${defaultThemeStyles['list-active']})`,
    },
    ...(() => {
        const hoverBoxShadows: IStyles = []

        for (let color in colors) {
            if (colors.hasOwnProperty(color)) {
                const rgba = hexToRgba(colors[color as TThemeColorTypes], 0.05)

                hoverBoxShadows[`${color}Hover`] = {
                    '&:hover': {
                        boxShadow: `inset 0 0 0 10em ${rgba}`,
                    },
                }
            }
        }

        return hoverBoxShadows
    })(),
}

addStyles(buttonStyles)
