import React, { FunctionComponent, memo } from 'react'
import { cn } from 'ethcss'
import { switchButtonStyles } from './SwitchButton-styles'
import { ISwitchButtonComponent } from './SwitchButton-types'
import { themeFromStore } from 'core/helpers/menu'

const SwitchButtonComponent: FunctionComponent<ISwitchButtonComponent> = ({
    disabled = false,
    checked,
    onChange,
    mod = 'fill',
}) => {
    const handleClickEvent = (e: React.MouseEvent) => {
        if (disabled) return

        onChange()
    }

    return (
        <div
            className={cn(
                switchButtonStyles.switchButton__wrapper,
                switchButtonStyles[`switchButton__wrapper_mod_${mod}`],
                {
                    [switchButtonStyles[`switchButton__wrapper_mod_fill_custom_theme`]]:
                        !checked && themeFromStore() === 'custom',
                    [switchButtonStyles[`switchButton__wrapper_mod_fill_custom_theme_active`]]:
                        checked && themeFromStore() === 'custom',
                    [switchButtonStyles[`switchButton__wrapper_mod_${mod}_type_active`]]:
                        checked && themeFromStore() !== 'custom',
                    [switchButtonStyles.switchButton__wrapper_disabled]: disabled,
                }
            )}
        >
            <button className={cn(switchButtonStyles.switchButton)} onClick={handleClickEvent}></button>
            <div
                className={cn(
                    switchButtonStyles.switchButton__handler,
                    switchButtonStyles[`switchButton__handler_mod_${mod}`],
                    {
                        [switchButtonStyles[`switchButton__handler_mod_${mod}_type_active`]]: checked,
                        [switchButtonStyles.switchButton__handler_checked]: checked,
                        [switchButtonStyles[`switchButton__handler_mod_fill_type_custom_theme`]]:
                            themeFromStore() === 'custom',
                    }
                )}
            ></div>
        </div>
    )
}

export const SwitchButton = memo(SwitchButtonComponent)
