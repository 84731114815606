import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Tooltip } from 'atoms/Tooltip'

import { debounce } from 'core/utils'

export const truncateString = ({ text, ellipsisString, measurements, leftPercentage = 50 }) => {
    if (measurements.text > measurements.component) {
        const size = (percentage) => measurements.component * (percentage / 100)

        const portion = (size) => Math.ceil((text.length * size) / measurements.text)

        const left = text.slice(0, Math.max(0, portion(size(leftPercentage)) - ellipsisString.length))

        const right = text.slice(text.length - portion(size(100 - leftPercentage - measurements.ellipsis)), text.length)

        return `${left}${ellipsisString}${right}`
    }

    return text
}

class TruncateString extends PureComponent {
    static propTypes = {
        ellipsisString: PropTypes.string,
        truncateAt: PropTypes.number,
        text: PropTypes.string,
        withTooltip: PropTypes.bool,
    }

    static defaultProps = {
        ellipsisString: '...',
        text: '',
        truncateAt: 50,
        withTooltip: true,
    }

    state = {
        truncating: true,
        truncatedString: null,
    }

    getTruncateString(text) {
        const measurements = {
            component: this.componentRef.offsetWidth,
            ellipsis: this.ellipsisRef.offsetWidth,
            text: this.textRef.offsetWidth,
        }

        const { ellipsisString } = this.props

        return truncateString({
            measurements,
            text,
            ellipsisString,
            leftPercentage: this.props.truncateAt,
        })
    }

    resetTruncate = debounce(() => {
        // this renders the original string so we can measure it
        this.setState({ truncating: true }, () => {
            // now we render again with the truncated string
            const truncatedString = this.getTruncateString(this.props.text)
            this.setState({ truncatedString, truncating: false })
        })
    }, 50)

    componentDidMount() {
        // calculate  truncatedString and set state to render again with the truncated string
        const truncatedString = this.getTruncateString(this.props.text)
        this.setState({ truncatedString, truncating: false })

        window.addEventListener('resize', this.resetTruncate)
    }

    componentDidUpdate = (_, prevState) => {
        /*
    Yes, we are using an anti-pattern here.
    We want to render two times:
      one to display and measure the input string
      one to display the truncated
    */
        this.state.truncating === prevState.truncating && this.resetTruncate()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resetTruncate)
    }

    setComponentRef = (element) => {
        this.componentRef = element
    }

    setTextRef = (element) => {
        this.textRef = element
    }

    setEllipsisRef = (element) => {
        this.ellipsisRef = element
    }

    render() {
        const { text, ellipsisString, truncateAt, style, withTooltip, ...otherProps } = this.props
        const { truncatedString, truncating } = this.state
        const isTooltipDisabled = truncatedString === text

        const componentStyle = {
            ...style,
            display: 'block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        }

        const Content = () => (
            <div ref={this.setComponentRef} style={componentStyle} {...otherProps}>
                {truncating && <span ref={this.setTextRef}>{text}</span>}
                {truncating && <span ref={this.setEllipsisRef}>{ellipsisString}</span>}
                {!truncating && truncatedString}
            </div>
        )

        if (withTooltip) {
            return (
                <Tooltip html={text} disabled={isTooltipDisabled} distance={5}>
                    <Content />
                </Tooltip>
            )
        }

        return <Content />
    }
}

export default TruncateString
