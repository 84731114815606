import React from 'react'
import Button from 'blocks.simple/button/button'
import Toast from 'blocks.simple/toast/toast'

import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import styles from 'pages/ui/ui.jcss'

const UiToast = function() {
    return (
        <div className={styles.blockPanel}>
            <div className={styles.title}>Нотификации</div>
            <div className={cn(grid.row, grid.p_mini)}>
                <Button
                    className={grid.mr_md}
                    onClick={function() {
                        Toast.success('success')
                    }}
                >
                    success
                </Button>
                <Button
                    className={grid.mr_md}
                    onClick={function() {
                        Toast.warn('warn')
                    }}
                >
                    warn
                </Button>
                <Button
                    className={grid.mr_md}
                    onClick={function() {
                        Toast.error('error')
                    }}
                >
                    error
                </Button>
                <Button
                    className={grid.mr_md}
                    onClick={function() {
                        Toast.info('info')
                    }}
                >
                    info
                </Button>
                <Button
                    onClick={function() {
                        Toast.default('default')
                    }}
                >
                    default
                </Button>
            </div>
        </div>
    )
}

export default UiToast
