import { addStyles } from 'ethcss'
import { colors, IStyles, mediaCSS } from 'theme'

const categoryAdd: IStyles = {
    categoryAdd: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.7)',
        zIndex: 2000,
    },
    inner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 20,
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        backgroundColor: colors.white,
        width: 380,
        padding: 34,
    },
    field: {
        marginBottom: 20,
    },
    controls: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 60,
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 148,
        height: 34,
    },
    selectLogo: {
        width: '100%',
    },
    itemStyles: {
        width: '100% !important',
    },
}

addStyles(categoryAdd)
export default categoryAdd
