import React from 'react'

import Toast from 'blocks.simple/toast/toast'
import { toast } from 'react-toastify'

import catalogTemplate from './__template'
import { api } from 'core/api/ConnectionManager'
import deepEqual from 'fast-deep-equal'
import merge from 'merge'
import helpers from 'core/helpers'
import { createCachedDebounce } from 'core/utils'
import translate from 'core/translate'
import template from 'es6-template-strings'
import moment from 'moment'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { changeFilter } from 'features/routes'

class CatalogMethods extends React.Component {
    constructor(p_) {
        super(p_)

        this.loading = true
        this.fullLoading = true
        this.state = {
            isInit: false,
            list: [],
            total: null,
            lastPage: null,
            fields: null,
            folders: [],
            tags: [],
            breadcrumbs: null,
            center: null,
            isClicked: false,
            autoMovedToTheFolder: false,
            dimensions: {
                height: 73,
            },
            multiSelect: false,
            showOnlySelectedItem: null,
        }
        this.isShiftPressed = false
        this.keyMap = {
            shiftDown: { sequence: 'shift', action: 'keydown' },
            shiftUp: { sequence: 'shift', action: 'keyup' },
        }
        this.handlers = {
            shiftDown: () => {
                this.isShiftPressed = true
            },
            shiftUp: () => {
                this.isShiftPressed = false
            },
        }

        this.template = catalogTemplate(p_.type)

        this.init = this.template.init ? this.template.init.bind(this) : this.baseInit.bind(this)
        this.unmount = this.template.unmount ? this.template.unmount.bind(this) : this.baseUnmount.bind(this)
        this.getBreadcrumbs = this.template.getBreadcrumbs
            ? this.template.getBreadcrumbs.bind(this)
            : this.baseGetBreadcrumbs.bind(this)
        this.get = this.get.bind(this)
        this.getFilter = this.getFilter.bind(this)
        this.onSelectInfo = this.onSelectInfo.bind(this)
        this.moveOnUpdate = this.template.moveOnUpdate
            ? this.template.moveOnUpdate.bind(this)
            : this.baseMoveOnUpdate.bind(this)
        this.onCreate = this.template.onCreate ? this.template.onCreate.bind(this) : this.baseOnCreate.bind(this)
        this.onUpdate = this.template.onUpdate ? this.template.onUpdate.bind(this) : this.baseOnUpdate.bind(this)
        this.onDelete = this.template.onDelete ? this.template.onDelete.bind(this) : this.baseOnDelete.bind(this)
        this.onSelect = this.onSelect.bind(this)
        this.onSelectCluster = this.onSelectCluster.bind(this)
        this.getSelected = this.getSelected.bind(this)
        this.onSelectProgress = this.onSelectProgress.bind(this)
        this.getModelsList = this.getModelsList.bind(this)
        this.getActiveIndex = this.getActiveIndex.bind(this)
        this.isEqual = this.template.isEqual
        this.isFolder = this.template.isFolder ? this.template.isFolder : () => false
        const query = getURLSearchParamsByLocation(p_.location)
        this.query = helpers.getQuery(query, p_.type)
        this.notification = null
        this.getItemModel = this.template.getItemModel
        this.clearListenersList = []
    }
    _mounted = true
    _autoExpandedRootFolder = false
    prevGetParams = null
    cache = {
        updated: {
            data: [],
            debounce: null,
        },
    }

    isDraggable(p_, template) {
        if (template.disableDrag) {
            return false
        }
        const foldersExists = typeof template.isFolder !== 'undefined'

        return p_.drag || foldersExists
    }
    componentDidMount() {
        this.init(() => {
            const p_ = this.props

            this.getSelectedItem()

            if (this.isMap(p_)) {
                p_.mapSelect()
            } else {
                this.get(false, false, { props: p_ })
            }

            this.template.onCreateListeners.forEach((listener) => {
                listener((dataItem) => {
                    this.get(false, true)
                    this.onCreate(dataItem)
                    p_.onChange(dataItem, 'create')
                }, this.clearListenersList)
            })
            this.template.onUpdateListeners.forEach((listener) => {
                listener((dataItem) => {
                    createCachedDebounce(
                        dataItem,
                        this.cache.updated,
                        (data) => {
                            this.updateList(data)
                            this.onUpdate(data)
                            p_.onChange(data, 'update')
                        },
                        {
                            mounted: this._mounted,
                        }
                    )
                }, this.clearListenersList)
            })
            this.template.onDeleteListeners.forEach((listener) => {
                listener((dataItem) => {
                    this.deleteItem(dataItem)
                    this.onDelete(dataItem)
                    p_.onChange(dataItem, 'delete')
                }, this.clearListenersList)
            })
        })
    }
    componentWillUnmount() {
        this.clearListenersList.forEach((id) => api.removeObserver(id))
        this._mounted = false
        this.unmount()
    }
    UNSAFE_componentWillReceiveProps(p_) {
        const s_ = this.state
        const query = getURLSearchParamsByLocation(p_.location)

        this.query = helpers.getQuery(query, p_.type)

        if (!this.isMap(p_)) {
            const prevFilter = this.getFilter(this.props)
            const filter = this.getFilter(p_)
            const filterDiff = !deepEqual(prevFilter, filter)

            if (filterDiff) {
                this.get(false, false, { filter: true, props: p_ })
            }
        }

        if (this.isMap(p_) && !this.isMap(this.props)) {
            p_.mapSelect()
            this.setState({
                breadcrumbs: null,
            })
        }

        if (!this.isMap(p_)) {
            p_.mapUnselect()
        }

        const prevDisposition = this.getViewParamsByProps(this.props).disposition
        const nextDisposition = this.getViewParamsByProps(p_).disposition

        if (!this.isMap(p_) && prevDisposition !== nextDisposition) {
            this.get(false, false, { props: p_ })

            p_.onSelect([])
            p_.onSelectInfo(null)
        }

        if (!deepEqual(this.props.selectedItems, p_.selectedItems)) {
            let updatedList = s_.list.map((listItem, index) => {
                return {
                    ...listItem,
                    __view: {
                        ...listItem.__view,
                        selected: this.getSelected(listItem, p_),
                    },
                }
            })

            this.setState({ list: updatedList })
        }
    }
    getSelectedItem = () => {
        const p_ = this.props
        const deviceBlockStyle = this.getDeviceBlockStyle()

        if (p_.showOnlySelected && p_.selectedInfo) {
            this.template.getBackendModel(p_.selectedInfo).then((response) => {
                const showOnlySelectedItem = this.getItemModel(response, this.getSelected, deviceBlockStyle)

                this.setState({ showOnlySelectedItem })
            })
        }
    }
    baseInit(callback) {
        this.setState({ isInit: true }, callback)
    }
    baseUnmount(callback) {}

    getSelected(dataItem, props) {
        const p_ = props || this.props
        let selected = false

        if (p_.selectedItems) {
            p_.selectedItems.forEach((selectedItem) => {
                if (this.isEqual(dataItem, selectedItem)) {
                    selected = true
                }
            })
        }

        return selected
    }

    onSelectByShift = (items) => {
        const p_ = this.props
        const { list } = this.state

        if (p_.onSelect) {
            let updatedList = list.map((listItem, index) => {
                const findedSelectedItem = items.find((selectedItem) => this.isEqual(selectedItem, listItem))

                return findedSelectedItem
                    ? {
                          ...listItem,
                          __view: {
                              ...listItem.__view,
                              selected: true,
                          },
                      }
                    : listItem
            })

            this.setState({ list: updatedList })

            p_.onSelect(items)
            p_.onSelectInfo(null)
        }
    }

    onSelect({ selected, item }) {
        const p_ = this.props
        const s_ = this.state

        if (p_.onSelect) {
            let selectedItems = []

            if (selected) {
                selectedItems = [...p_.selectedItems, item]
            } else {
                selectedItems = p_.selectedItems.filter((listItem) => !this.isEqual(item, listItem))
            }

            let updatedList = s_.list.map((listItem, index) => {
                if (!this.isEqual(item, listItem)) return listItem

                return {
                    ...listItem,
                    __view: {
                        ...listItem.__view,
                        selected,
                    },
                }
            })

            this.setState({ list: updatedList })

            p_.onSelect(selectedItems)
            p_.onSelectInfo(null)
        }
    }
    onFolderOpening = (listItem) => {
        const s_ = this.state
        const p_ = this.props
        const folderKey = helpers.getNestedFolderKey(p_.type)

        if (this.isTreeLoaded(listItem)) {
            return
        }

        api.send(this.template.getMethod, { [folderKey]: listItem.id }, { hideLoader: false }).then((res) => {
            let list = res.data

            s_.list.forEach((item) => {
                if (this.isEqual(listItem, item)) {
                    item.__view.isLoaded = true
                }

                const existItemIndex = res.data.findIndex((newItem) => this.isEqual(newItem, item))

                if (existItemIndex > -1) {
                    list.splice(existItemIndex, 1)
                }
            })

            list = this.getModelsList(list)
            list = list.map((item) => {
                item.__view = {
                    selected: false,
                    nestedKey: folderKey,
                }

                return item
            })

            s_.list = [...s_.list, ...list]

            this.setState(s_)
        })
    }
    isTreeLoaded = (listItem) => {
        const s_ = this.state

        for (let i = 0; s_.list.length > i; i++) {
            if (this.isEqual(listItem, s_.list[i])) {
                s_.list[i].__view.isOpen = !s_.list[i].__view.isOpen

                this.setState(s_)
                if (s_.list[i].__view.isLoaded) {
                    return true
                }
            }
        }

        return false
    }
    onSelectCluster(selectedItems) {
        const p_ = this.props

        if (p_.onSelect) {
            p_.onSelect(selectedItems)
            this.onSelectInfo(-1)
        }
    }
    onSelectProgress(index) {
        const item = this.state.list[index] || null
        const p_ = this.props

        p_.toggleDownloadsModal()
        p_.onSelectProgress(item)
    }
    onDragStart = (event) => {
        const p_ = this.props

        p_.onDragStart(event)

        if (!p_.onSelect) {
            return
        }

        const count = p_.selectedItems.length ? p_.selectedItems.length : 1
        const message = template(translate('transfer'), { count })
        const toastOptions = {
            closeOnClick: false,
            closeButton: false,
            autoClose: false,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            position: 'bottom-center',
        }

        this.notification = Toast.default(message, toastOptions)
    }
    onDragEnd = (event) => {
        const p_ = this.props

        if (!!p_.onSelect) {
            toast.dismiss(this.notification)
        }

        p_.onDragEnd(event)
    }
    baseOnCreate() {}
    baseOnUpdate() {}
    baseOnDelete() {}
    baseGetBreadcrumbs() {}
    getFilter(p_) {
        const s_ = this.state
        const locationQuery = getURLSearchParamsByLocation(p_.location)
        const query = helpers.getQuery(locationQuery, p_.type)
        const viewParams = this.getViewParamsByProps(p_)
        const filter = {
            dateFilter: viewParams.dateFilter,
            sort: viewParams.sort,
            query: query.query,
            hasActualSchedule: query.hasActualSchedule,
            ...p_.filterOptions,
        }

        if (this.template.folderName) {
            filter[this.template.folderName] = query[this.template.folderName]
        }

        if (this.state.fields) {
            this.state.fields.forEach((field) => {
                if (query[field.id]) {
                    if (this.template.getFilterField) {
                        this.template.getFilterField(s_, filter, query, field)
                    } else {
                        filter[field.id] = query[field.id]
                    }
                }
            })
        }

        return filter
    }
    onGetDataCallback = (list) => {
        if (this.template.onGetDataCallback) {
            return this.template.onGetDataCallback(list)
        }
        return list
    }
    get(isLazyLoad, quiteLoading, { props = this.props, ...options } = {}) {
        const s_ = this.state
        const p_ = this.props
        const filter = this.getFilter(props)
        this.filter = filter

        let perPage = this.template.perPage || 35

        let params = {
            ...filter,
            ...options.filter,
            offset: isLazyLoad ? s_.list.length : 0,
            perPage,
        }

        if (this.template.additionParams) {
            params = {
                ...params,
                ...this.template.additionParams,
            }
        }

        if (p_.type === 'advertisingCampaigns') {
            params.userDate = moment().format('YYYY-MM-DD HH:mm:ss')
        }

        if (isLazyLoad && deepEqual(params, this.prevGetParams)) {
            return
        }

        this.prevGetParams = params
        this.loading = true
        this.fullLoading = !isLazyLoad
        if (quiteLoading) {
            this.loading = false
            this.fullLoading = false
        }

        this.getBreadcrumbs(params[this.template.folderName])

        api.send(this.template.getMethod, params, { hideLoader: false }).then((res) => {
            const p_ = this.props
            const s_ = this.state
            let list = this.getModelsList(res.data)
            list = this.onGetDataCallback(list)

            if (!isLazyLoad && list.length === 1) {
                if (!filter[this.template.folderName] && !this._autoExpandedRootFolder) {
                    const listItem = list[0]

                    if (typeof this.template.isFolder === 'function' && this.template.isFolder(listItem)) {
                        p_.onGetRootFolder(listItem.id)
                        changeFilter({ [this.template.folderName]: listItem.id })

                        this._autoExpandedRootFolder = true
                        this.get(false, false, { filter: { groupId: listItem.id } })
                        return
                    }
                }
            }

            if (!this._autoExpandedRootFolder) {
                this._autoExpandedRootFolder = true
            }

            if (isLazyLoad) {
                list = [...s_.list, ...list]
            }

            this.fullLoading = false
            this.loading = false

            if (this.isList(p_)) {
                list = list.map((item) => {
                    if (!item.__view.hasOwnProperty('nestedKey')) {
                        item.__view.nestedKey = helpers.getNestedFolderKey(p_.type)
                    }

                    if (!isLazyLoad && !options.filter) {
                        const existItem = s_.list.find((listItem) => this.isEqual(listItem, item))

                        if (existItem) {
                            item = merge.recursive(true, item, existItem)
                        }
                    }

                    return item
                })

                if (!isLazyLoad && !options.filter) {
                    const nestedItems = s_.list.filter((listItem) => !list.find((item) => this.isEqual(listItem, item)))

                    list = [...list, ...nestedItems]
                }
            }

            p_.onGetList(list)
            this.setState({
                list,
                total: res.total,
                lastPage: res.lastPage,
                fakeLine: null,
            })
        })
    }
    getModelsList(list) {
        const deviceBlockStyle = this.getDeviceBlockStyle()

        return list.map((item) => {
            return this.getItemModel(item, this.getSelected, deviceBlockStyle)
        })
    }
    baseMoveOnUpdate() {
        return false
    }
    updateList = (updatedList) => {
        const s_ = this.state
        const p_ = this.props
        updatedList = this.getModelsList(updatedList)
        const updateItems = []
        const nextPrevItems = []

        const list = s_.list.map((listItem, index) => {
            const updatedItem = updatedList.find((updatedItem) => this.isEqual(updatedItem, listItem))
            const clonedUpdatedItem = helpers.deepCopy(listItem)

            if (updatedItem) {
                const item = merge.recursive(true, listItem, updatedItem)

                updateItems.push(item)
                nextPrevItems.push({
                    prev: clonedUpdatedItem,
                    next: item,
                })

                return item
            }

            return listItem
        })

        this.setState({ list, isClicked: false })

        if (p_.selectedInfo) {
            const updatedSelectedInfoItem = updateItems.find((updatedItem) =>
                this.isEqual(p_.selectedInfo, updatedItem)
            )
            if (updatedSelectedInfoItem) {
                p_.onSelectInfo(updatedSelectedInfoItem)

                if (s_.isClicked) {
                    p_.onDoubleClickObject(updatedSelectedInfoItem)
                }
            }
        }

        if (p_.selectedItems && p_.selectedItems.length) {
            const selectedItems = p_.selectedItems.map((selectedItem, index) => {
                const updatedItem = updateItems.find((updateItem) => this.isEqual(selectedItem, updateItem))

                if (updatedItem) {
                    return updatedItem
                }

                return selectedItem
            })

            p_.onSelect(selectedItems)
        }

        if (this.isList(p_)) {
            return
        }

        if (!this.loading && !this.query.query) {
            nextPrevItems.forEach((updateItem) => {
                if (this.moveOnUpdate(updateItem)) {
                    this.deleteItem(updateItem.next, false)
                }
            })
        }
    }
    deleteItem(dataItem, saveSelected) {
        const s_ = this.state
        const p_ = this.props
        const list = s_.list.filter((listItem) => !this.isEqual(listItem, dataItem))
        let total = s_.total

        if (s_.list.length >= s_.total) {
            total = list.length
        }

        p_.onGetList(list)
        this.setState({ list, total })

        if (!saveSelected) {
            if (p_.selectedInfo && this.isEqual(p_.selectedInfo, dataItem)) {
                this.onSelectInfo(-1)
            }
            if (p_.selectedItems) {
                let selectedItems = [...p_.selectedItems]

                selectedItems = selectedItems.filter((listItem) => !this.isEqual(listItem, dataItem))
                p_.onSelect(selectedItems)
            }
        }
    }
    onSelectInfo(index) {
        const item = this.state.list[index] || null
        const p_ = this.props

        if (item && this.isShiftPressed) {
            this.onSelectSelectInfoByShift(index)
            return
        }

        p_.onSelectInfo && p_.onSelectInfo(item)
        if (item && this.template.getBackendModel) {
            this.template.getBackendModel(item).then((itemModel) => this.updateList([itemModel]))
        }
    }

    onSelectSelectInfoByShift(index) {
        const p_ = this.props
        const { list } = this.state

        let selectedItemIndex

        if (p_.selectedInfo) {
            selectedItemIndex = list.findIndex((item) => item.id === p_.selectedInfo.id)
        }

        if (p_.selectedItems.length) {
            selectedItemIndex = list.findIndex((item) => item.id === p_.selectedItems[0].id)
        }

        let selectedItems = list.slice(selectedItemIndex, index + 1)

        if (selectedItemIndex > index) {
            selectedItems = list.slice(index, selectedItemIndex + 1).reverse()
        }

        this.onSelectByShift(selectedItems)
    }
    onSelectAll = () => {
        const s_ = this.state
        const p_ = this.props

        this.setState({ multiSelect: true }, () => {
            const newSelectedItems = s_.list.filter((listItem) => {
                if ((p_.disableFolderSelection && this.isFolder(listItem)) || p_.disableMultiSelect(listItem)) {
                    return false
                }

                const findItem = p_.selectedItems.find((selectedItem) => this.isEqual(listItem, selectedItem))

                return !findItem
            })

            if (p_.onSelect) {
                p_.onSelect([...p_.selectedItems, ...newSelectedItems])
            }
            p_.onSelectInfo && p_.onSelectInfo(null)
        })
    }
    onDeselectAll = () => {
        const { onSelect, onSelectInfo } = this.props

        this.setState({ multiSelect: false }, () => {
            onSelect([])
            onSelectInfo(null)
        })
    }
    onActivateMultiSelect = () => {
        const { onSelectInfo } = this.props

        this.setState({ multiSelect: true }, () => onSelectInfo(null))
    }
    getActiveIndex() {
        const s_ = this.state
        const p_ = this.props
        let activeIndex = null

        if (p_.selectedInfo) {
            s_.list.forEach((listItem, index) => {
                if (this.isEqual(listItem, p_.selectedInfo)) {
                    activeIndex = index
                }
            })
        }

        return activeIndex
    }
    onCenterMap = (center) => {
        this.setState({ center })
    }

    getViewParams = () => {
        const { type, user } = this.props

        const prefix = catalogTemplate(type).settingsPrefixes
        return helpers.getViewParamsByUser(prefix, user)
    }

    getViewParamsByProps = (props) => {
        const { type, user } = props

        const prefix = catalogTemplate(type).settingsPrefixes
        return helpers.getViewParamsByUser(prefix, user)
    }

    getDisposition = () => {
        const { disposition } = this.props
        const viewParams = this.getViewParams()

        return disposition || viewParams.disposition
    }

    getDispositionByProps = (props) => {
        const { disposition } = props
        const viewParams = this.getViewParams()

        return disposition || viewParams.disposition
    }

    isList = (props) => {
        return this.getDispositionByProps(props) === 'list'
    }

    isMap(props) {
        return this.getDispositionByProps(props) === 'map'
    }

    getDeviceBlockStyle = () => {
        const { user } = this.props

        return user?.settings?.deviceBlockStyle
    }
}

export default CatalogMethods
