import { colors } from 'theme'

export const defaultThemeStyles = {
    online: colors.online,
    offline: colors.offline,
    idle: colors.darkCloud,
    progress: colors.darkCloud,
    backgroundMain: colors.grey,
    backgroundSub: colors.greyLight,
    textMain: colors.white,
    titleBackground: 'rgba(0, 0, 0, 0.45)',
    error: colors.errorLight,
    active: 'rgba(0, 0, 0, 0.45)',
    shadow: '-1px 3px 4px 0px rgba(0, 0, 0, 0.25)',
}
