import React from 'react'
import styles from './header.jcss'
import Icon from '../../../blocks.simple/icon/icon'
import { colors } from '../../../theme'
import EditText from '../../../blocks.simple/editText/editText'
import translate from '../../../core/translate'
import Button from '../../../blocks.simple/button/button'

interface IHeader {
    selectedItemsLength: number
    deleteAllSelected: (value: boolean) => void
    onAdd: () => void
}

export const Header = ({ selectedItemsLength, deleteAllSelected, onAdd }: IHeader) => {
    return (
        <div className={styles.header}>
            <div className={styles.controlPositionBlock}>
                <div className={styles.controlBlock}>
                    <Icon
                        type={'plus'}
                        color={colors.blueAccent}
                        size={18}
                        className={styles.addIcon}
                        onClick={onAdd}
                    />
                    <Icon
                        type={'delete'}
                        color={colors.blueAccent}
                        disabled={!selectedItemsLength}
                        size={18}
                        onClick={() => deleteAllSelected(true)}
                    />
                </div>
            </div>
            {/*<div className={styles.uploadBlock}>*/}
            {/*    <span>Разделитель</span>*/}
            {/*    <EditText*/}
            {/*        value={','}*/}
            {/*        onChange={(value: string) => {}}*/}
            {/*        containerClass={styles.separator}*/}
            {/*        className={styles.input}*/}
            {/*    />*/}
            {/*    <Button className={styles.refreshButton} onClick={() => {}} mod={'withAccent'}>*/}
            {/*        {translate('upload')}*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </div>
    )
}
