import React from 'react'
import Button from 'blocks.simple/button/button'
import DisplaysMap from '../../__map/_displays/catalog__map_displays'
import ItemDisplays from '../../__item/_displays/catalog__item_displays'
import Icon from 'blocks.simple/icon/icon'

import { api } from 'core/api/ConnectionManager'
import { addListener } from 'core/helpers'
import translate from 'core/translate'
import styles from './catalog__template_displays.jcss'

import helpers from 'core/helpers'
import helpersDigitalSignages from 'core/helpers/_digitalSignages'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import store from 'core/store'
import { openApprove } from 'pages/displays/displays.state'

const noBroadcastCover = helpersDigitalSignages.getNoBroadcastIconSrc()
let timer = null

export default {
    folderName: 'groupId',
    getMethod: 'getDisplaysAndGroups',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noDevices'),
    emptyIcon: 'not_allow',
    moveItem: (id, groupId) => api.send('editDisplay', { id, groupId }),
    moveFolder: (id, parentId) => api.send('editGroup', { id, parentId }),
    getDragData: (p_) => {
        return JSON.stringify({ id: p_.item.id, type: p_.item.type })
    },
    addItemButton: () => {
        if (!helpers.isAvailable({ key: 'digitalSignage', action: 'create' })) {
            return null
        }

        return (
            <Button rounded="full_md" onClick={() => store.dispatch(openApprove(''))}>
                {translate('add')}
            </Button>
        )
    },
    map: (p_) => <DisplaysMap {...p_} />,
    settingsPrefixes: 'digitalSignage',
    showFilter: true,
    tile: ItemDisplays,
    viewOptions: () => [
        {
            id: 'tile',
            name: translate('tile'),
            icon: 'tile',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
        {
            id: 'list',
            name: translate('list'),
            icon: 'list',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
        {
            id: 'map',
            name: translate('map'),
            icon: 'location',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
    ],
    sortOptions: () => [
        { id: '-createdAt', name: translate('sortCreatedAt') },
        { id: 'name', name: translate('byNameAsc') },
        { id: '-name', name: translate('byNameDesc') },
        { id: 'status', name: translate('sortStatusOnline') },
        { id: '-status', name: translate('sortStatusOffline') },
        { id: 'type', name: translate('devices') },
        { id: '-type', name: translate('groups') },
    ],
    getItemModel: (dataItem, getSelected, deviceTileView) => {
        const item = { ...dataItem }

        if (!item.cover) {
            item.cover = []
        }
        if (item.cover === 'ignore') {
            delete item.cover
        }

        if (item.type && deviceTileView === 'simple') {
            item.cover = [...item.cover, ...[noBroadcastCover, noBroadcastCover, noBroadcastCover, noBroadcastCover]]
            item.cover = item.cover.slice(0, 4)
        }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }
        if (!item.type) {
            item.type = 'digitalSignage'
        }

        return item
    },
    getBackendModel: (item) => {
        if (item.type !== 'group') {
            return api.send('getDisplay', { id: item.id }, { hideLoader: true })
        } else {
            return api.send('getGroup', { groupId: item.id }, { hideLoader: true })
        }
    },
    isEqual: helpersDigitalSignages.isEqual,
    onCreateListeners: [
        (cb, clearList) => api.addObserver('groupCreated', cb, clearList),
        (cb, clearList) => api.addObserver('displayCreated', cb, clearList),
    ],
    onUpdateListeners: [
        (cb, clearList) => api.addObserver('groupUpdated', cb, clearList),
        (cb, clearList) => api.addObserver('displayUpdated', cb, clearList),
        addListener.updateDisplayProgress,
    ],
    onDeleteListeners: [
        (callback, clearList) =>
            api.addObserver('groupDeleted', ({ id }) => callback({ id, type: 'group' }), clearList),
        (callback, clearList) =>
            api.addObserver('displayDeleted', ({ id }) => callback({ id, type: 'digitalSignage' }), clearList),
    ],
    isFolder: (item) => item.type === 'group',
    init(callback) {
        const p_ = this.props

        Promise.all([api.send('getCustomListView', { view: 'DigitalSignage' }), api.send('getDsTags', {})]).then(
            (res) => {
                const fields = []

                res[0].fields.forEach((item) => {
                    if (item.selected) {
                        fields.push({
                            id: item.field,
                            name: translate(item.field),
                        })
                    }
                })

                const tags = res[1]
                tags.forEach((tag) => {
                    tag.label = tag.name
                    tag.value = tag.id
                })

                p_.onGetTags(tags)

                this.setState({ isInit: true, fields, tags }, callback)
            }
        )

        api.addObserver(
            'digitalSignageScreenshotCreated',
            ({ digitalSignageId, id, url, createdAt, groupId }) => {
                const s_ = this.state
                const { selectedInfo } = this.props

                const dataItem = { id: digitalSignageId, type: 'digitalSignage' }
                const screenshotData = { id, url, createdAt }

                s_.list.forEach((listItem, index) => {
                    if (this.isEqual(dataItem, listItem)) {
                        if (!s_.list[index].screenshots) {
                            s_.list[index].screenshots = []
                        }
                        s_.list[index].screenshots.push(screenshotData)
                    }
                })

                this.setState(s_)

                if (selectedInfo && selectedInfo.type === 'group' && selectedInfo.id === groupId) {
                    clearTimeout(timer)
                    timer = setTimeout(() => {
                        api.send('getGroup', { groupId: selectedInfo.id }).then((groupModel) => {
                            this.updateList([groupModel])
                        })
                    }, 600)
                }
            },
            this.clearListenersList
        )
    },
    unmount() {},
    getBreadcrumbs(groupId) {
        let breadcrumbs = [{ name: translate('devices'), id: undefined }]

        if (!groupId) {
            this.setState({ breadcrumbs })
            return
        }

        api.send('getGroupBreadcrumbs', { groupId }).then((data) => {
            data.reverse()
            this.setState({ breadcrumbs: [...breadcrumbs, ...data] })
        })
    },
    getFilterField(s_, filter, query, field) {
        filter[field.id] = query[field.id].split('//')

        if (field.id === 'tags') {
            filter[field.id].forEach((fieldItem, index) => {
                const currentTag = s_.tags.find((tag) => tag.name === fieldItem)

                if (currentTag) {
                    filter[field.id][index] = currentTag.id
                }
            })
        }
    },
    moveOnUpdate(item) {
        const p_ = this.props

        if (this.isMap(p_)) {
            return false
        }

        if (this.filter.hasActualSchedule) {
            return false
        }

        let groupId
        let currentGroupId = this.query.groupId

        if (currentGroupId) {
            currentGroupId = parseInt(currentGroupId, 10)
        } else {
            return false
        }

        if (typeof item.parentId !== 'undefined') {
            groupId = item.parentId
        } else {
            groupId = item.groupId
        }
        return groupId !== currentGroupId
    },
    list: {
        status: ({ status }) => translate(status),
        orientation: ({ orientation }) => {
            const displayOrientation = helpersDigitalSignages.getOrientation(orientation)

            if (!displayOrientation) {
                return null
            }

            return displayOrientation.name
        },
        name: ({ name, status, type }, active, p_, nested) => {
            const color = { offline: 'offline', error: 'error', online: 'online' }

            return (
                <div
                    className={styles.name}
                    style={{
                        marginLeft: 40 * nested,
                    }}
                >
                    <div className={styles.status}>
                        <Icon type={type === 'group' ? 'files' : 'display'} size={20} color={color[status]} />
                    </div>
                    <div>{name}</div>
                </div>
            )
        },
        createdAt: ({ createdAt }) => helpers.getFormattedLocalDate(createdAt, 'DD/MM/YYYY'),
        lastConnection: ({ lastConnection }) => helpers.getFormattedLocalDate(lastConnection, 'DD/MM/YYYY'),
        updatedAt: ({ updatedAt }) => helpers.getFormattedLocalDate(updatedAt, 'DD/MM/YYYY'),
        tags: ({ tags }) => {
            if (tags) {
                return tags.map((tag, index) => tag.name).join(', ')
            } else {
                return null
            }
        },
        imei: ({ imei }) => {
            if (Array.isArray(imei)) {
                return imei.join(', ')
            } else {
                return null
            }
        },
    },
}
