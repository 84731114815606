import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../theme'
import { textSizes } from 'theme/media'
import _item from 'blocks.simple/item/item.jcss'

const imageViewStyles: IStyles = {
    wrapper: {
        ...grid.full,
    },
    image: {
        ...item.overNone,
        ...grid.row,
        ...grid.center,
        ...grid.normalCenter,
        ...grid.full,
        '& img': {
            height: 'auto',
            maxWidth: '100%',
        },
    },
    title: {
        ...item.abs,
        ...grid.fullWidth,
        top: 0,
        zIndex: 2,
        '& span': {
            ..._item.whiteColor,
            fontSize: textSizes.mdPlus,
            ...grid.row,
            ...grid.p_md,
            ..._item.blackOpacity,
        },
    },
    controlsWrapper: {
        ...grid.fullWidth,
        ...grid.row,
        ...grid.center,
        ...item.abs,
        bottom: 0,
    },
    controls: {
        ...grid.row,
        ...grid.mb_md,
        ..._item.blackOpacity,
        ...item.rounded_full_normal,
    },
    empty: {
        ...grid.full,
        ...grid.colCenter,
        ...grid.center,
    },
    emptyTitle: {
        ...grid.mt_md,
        ..._item.whiteColor,
        textTransform: 'uppercase',
    },
}

addStyles(imageViewStyles)
export default imageViewStyles
