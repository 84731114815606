import { addStyles } from 'ethcss'
import { IStyles, colors } from 'theme'

const Email: IStyles = {
    email() {
        const self = this

        return {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: colors.greyLight,
            borderRadius: 5,
            padding: 10,
            color: colors.blackLight,
            marginBottom: 20,
            height: 114,

            '&:hover': {
                [`& .${self.delete}`]: {
                    opacity: 1,
                },
            },
        }
    },
    statusMessage: {
        marginBottom: 8,
    },
    subject: {
        marginBottom: 8,
    },
    body: {
        marginBottom: 12,
        height: 16,
        overflow: 'hidden',
    },
    details: {
        textDecoration: 'underline',

        '&:hover': {
            textDecoration: 'none',
            cursor: 'pointer',
        },
    },
    leftBlock: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    status: {
        marginLeft: 4,
        marginRight: 8,
        height: '100%',
    },
    rightBlock: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        position: 'absolute',
        top: 0,
        right: 0,
        height: '100%',
        padding: 10,
        width: 130,
    },
    dateTime: {
        lineHeight: 1,
        width: '100%',
    },
    delete: {
        opacity: 0,
        transition: 'opacity 0.3s',
    },
}

addStyles(Email)
export default Email
