import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { IStyles } from '../../../theme'

const deviceSettingsAudioPlayer: IStyles = {
    radioButtons: {
        ...grid.row,
        ...grid.mb_md,
        ...grid.fullWidth,
    },
    disabled: {
        opacity: 0.5,
    },
}

addStyles(deviceSettingsAudioPlayer)
export default deviceSettingsAudioPlayer
