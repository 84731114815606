import React from 'react'
import Content from 'blocks.app/content/content'
import Editor from 'blocks.app/editor/editor'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'
import { RootStateOrAny, useSelector } from 'react-redux'
import styles from './broadcasts_addAdvanced.jcss'

const BroadcastsAddAdvanced = (p_: { route: any; location: any }) => {
    const isMobile = useSelector<RootStateOrAny>((state) => state.app.isMobile)

    return (
        <Content indentsMod={'none'} fullHeight={true} noPadding={isMobile} wrapperClassName={styles.wrapper}>
            <Editor
                // @ts-ignore
                route={p_.route}
                location={p_.location}
            />
        </Content>
    )
}

const exportBroadcastsAddAdvanced = checkAvailableModule('broadcasts/addAdvanced') ? BroadcastsAddAdvanced : null

export default exportBroadcastsAddAdvanced
