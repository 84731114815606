import { api } from 'core/api/ConnectionManager'
import { IRecordWithAny, IRecordWithStrings, IViewParams } from 'core/helpers'
import { stringToNumber } from 'core/utils/convertUtil'
import { isExist } from 'core/utils/coreUtil'
import { changeFilter } from 'features/routes'
import { getURLSearchParamsBySearchString } from 'features/routes/utils'
import { useEffect, useRef, useState } from 'react'
import {
    IUseFetchCatalogItemsHook,
    IUseFetchCatalogItemsProps,
    IFetchCatalogItemsData,
    IUseSelectedCatalogItemsHookArgs,
    IUseSelectedCatalogItemsHook,
    IFetchCatalogItemsDataResponse,
    ICatalogItem,
} from './CatalogWithPagination-types'

export const useFetchCatalogItems = (props: IUseFetchCatalogItemsProps): IUseFetchCatalogItemsHook => {
    const PER_PAGE_LIMIT = 35

    const getInitialAutoExpand = () => {
        const { parentKey } = options
        let query = getURLSearchParamsBySearchString(search)
        return isExist(query[parentKey]) ? false : true
    }

    const {
        onCreateListeners,
        onUpdateListeners,
        onDeleteListeners,
        onMoveListeners,
        options,
        search,
        viewParams,
        onResetSelectedInfo,
        onResetSelectedItems,
        removeSelectedItemById,
    } = props
    const [prevSearch, setPrevSearch] = useState<string>('')
    const [prevViewParams, setPrevViewParams] = useState<any>(null)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [isInit, setIsInit] = useState<boolean>(false)
    const [isCreateItemInit, setIsCreateItemInit] = useState<boolean>(false)
    const [isReachLimit, setReachLimit] = useState<boolean>(false)
    const [nextPageToken, setNextPageToken] = useState<string | null>(null)
    const [catalogItemList, setCatalogItemList] = useState<ICatalogItem[]>([])
    const [nestedCatalogItemList, setNestedCatalogItemList] = useState<ICatalogItem[]>([])
    const [isEnableAutoExpandFolder, setIsEnableAutoExpandFolder] = useState(getInitialAutoExpand())

    let isLoadingRef = useRef(isLoading)
    isLoadingRef.current = isLoading

    let isInitRef = useRef(isInit)
    isInitRef.current = isInit

    let isReachLimitRef = useRef(isReachLimit)
    isReachLimitRef.current = isReachLimit

    let isEnableAutoExpandFolderRef = useRef(isEnableAutoExpandFolder)
    isEnableAutoExpandFolderRef.current = isEnableAutoExpandFolder

    let catalogItemListRef = useRef(catalogItemList)
    catalogItemListRef.current = catalogItemList

    let nestedCatalogItemListRef = useRef(nestedCatalogItemList)
    nestedCatalogItemListRef.current = nestedCatalogItemList

    const { isEqual, parentKey } = options

    useEffect(() => {
        setPrevSearch(search)
    }, [search])

    useEffect(() => {
        setPrevViewParams(viewParams)
    }, [viewParams])

    useEffect(() => {
        const clearListenersList: any[] = []

        onCreateListeners.forEach((listener) => {
            listener((dataItem) => {
                onCreateItem(dataItem as ICatalogItem)
            }, clearListenersList)
        })

        onMoveListeners.forEach((listener) => {
            listener((dataItem) => {
                onMoveItem(dataItem as ICatalogItem)
            }, clearListenersList)
        })

        onUpdateListeners.forEach((listener) => {
            listener((dataItem) => {
                onUpdateItem(dataItem as ICatalogItem)
                onUpdateNestedItem(dataItem as ICatalogItem)
            }, clearListenersList)
        })

        onDeleteListeners.forEach((listener) => {
            listener((dataItem) => {
                onDeleteItem(dataItem as ICatalogItem)
                onDeleteNestedItem(dataItem as ICatalogItem)
            }, clearListenersList)
        })

        return () => {
            clearListenersList.forEach((id) => api.removeObserver(id))
        }
    }, [search])

    useEffect(() => {
        const isShouldRefresh = isSearchChange(search, prevSearch) || isViewParamsChange(viewParams, prevViewParams)

        fetchCatalogItems({
            search,
            viewParams,
            isShouldRefresh,
        })
    }, [search, viewParams])

    const fetchCatalogItems = ({
        search: inputSearch,
        viewParams: inputViewParams,
        isShouldRefresh = false,
        isLazyLoading = false,
    }: IFetchCatalogItemsData) => {
        if (isLoadingData()) return

        let currentNextPageToken = nextPageToken
        let currentCatalogItemList = catalogItemList

        const query = getURLSearchParamsBySearchString(inputSearch)

        if (isShouldRefresh) {
            currentNextPageToken = null
            currentCatalogItemList = []
            setReachLimit(false)
            setNestedCatalogItemList([])
            onResetSelectedInfo()
            onResetSelectedItems()
        }

        if (!isShouldRefresh && !isLazyLoading) return

        const { getMethodName } = options
        const params: IRecordWithAny = getFetchDataParams(query, inputViewParams)

        if (currentNextPageToken) {
            params.nextPageToken = currentNextPageToken
        }

        setLoading(true)

        api.send<any, IFetchCatalogItemsDataResponse>(getMethodName, params, { hideLoader: false })
            .then((res) => {
                let responseItemList = getCatalogItemModelsList(res.data)

                if (!isLazyLoading && isNeedAutoExpandFolder(responseItemList)) {
                    setLoading(false)
                    expandFolder(responseItemList[0])
                    return
                }

                let newNextPageToken = res.nextPageToken
                let newCatalogItemList = isLazyLoading
                    ? mergeLists(currentCatalogItemList, responseItemList)
                    : responseItemList

                setLoading(false)
                if (!isInitData()) setIsInit(true)

                setCatalogItemList(newCatalogItemList)
                setNextPageToken(newNextPageToken)

                if (!newNextPageToken) {
                    setReachLimit(true)
                }
            })
            .catch(() => {
                setLoading(false)
                if (!isInitData()) setIsInit(true)
            })
    }

    const fetchCatalogItemsByParentId = (parentId: number, cb: () => void) => {
        const { getByParentIdMethodName } = options

        if (!getByParentIdMethodName) {
            cb()
            return
        }

        api.send<any, IFetchCatalogItemsDataResponse>(
            getByParentIdMethodName,
            { [parentKey]: parentId },
            { hideLoader: false }
        )
            .then((res) => {
                if (!isNestedCatalogItemsExists()) return

                let responseItemList = getCatalogItemModelsList(res.data)
                let newCatalogItemList = mergeLists(nestedCatalogItemList, responseItemList)
                setNestedCatalogItemList(newCatalogItemList)
                cb()
            })
            .catch(() => {})
    }

    const isLoadingData = () => {
        return isLoadingRef && isLoadingRef.current
    }

    const isReachLimitExist = () => {
        return isReachLimitRef && isReachLimitRef.current
    }

    const isInitData = () => {
        return isInitRef && isInitRef.current
    }

    const isEnableAutoExpand = () => {
        return isEnableAutoExpandFolderRef && isEnableAutoExpandFolderRef.current
    }

    const isCatalogItemsExists = () => {
        return catalogItemListRef && catalogItemListRef.current
    }

    const isNestedCatalogItemsExists = () => {
        return nestedCatalogItemListRef && nestedCatalogItemListRef.current
    }

    const isSearchChange = (search: string, prevSearch: string): boolean => {
        return search !== prevSearch
    }

    const isViewParamsChange = (viewParams: IViewParams, prevViewParams: IViewParams): boolean => {
        if (!isExist(viewParams) || !isExist(prevViewParams)) return true

        return (
            viewParams.sort !== prevViewParams.sort ||
            viewParams.disposition !== prevViewParams.disposition ||
            viewParams.dateFilter !== prevViewParams.dateFilter
        )
    }

    const getCatalogItemModelsList = (list: any[]) => {
        const { decorateCatalogItemModel } = options

        return list.map((item) => {
            return decorateCatalogItemModel ? decorateCatalogItemModel(item) : item
        })
    }

    const getFetchDataParams = (query: IRecordWithStrings, viewParams: IViewParams): IRecordWithAny => {
        const { perPage, getFilterQuery } = options
        let filter = getFilterQuery(query, viewParams)
        let limit = perPage || PER_PAGE_LIMIT

        return {
            ...filter,
            limit,
        }
    }

    const mergeLists = (oldList: ICatalogItem[], newList: ICatalogItem[]) => {
        const oldCatalogItemList = oldList.filter(
            (item) => !isExist(newList.find((nextItem) => isEqual(item, nextItem)))
        )
        return [...oldCatalogItemList, ...newList]
    }

    const onCreateItem = (dataItem: ICatalogItem) => {
        if (!isCatalogItemsExists()) return
        if (!isCurrentFolderItem(dataItem)) return
        if (isCatalogItemExist(dataItem, catalogItemListRef.current)) return

        setCatalogItemList([...catalogItemListRef.current, dataItem])
    }

    const onMoveItem = (dataItem: ICatalogItem) => {
        if (!isCatalogItemsExists()) return

        if (isCurrentFolderItem(dataItem)) {
            setCatalogItemList([...catalogItemListRef.current, dataItem])
            return
        }

        if (!isCatalogItemExist(dataItem, catalogItemListRef.current)) return

        deleteItem(dataItem, catalogItemListRef.current)
    }

    const onUpdateItem = (dataItem: ICatalogItem) => {
        if (!isCatalogItemsExists()) return
        if (!isCatalogItemExist(dataItem, catalogItemListRef.current)) return

        if (!isCurrentFolderItem(dataItem)) {
            deleteItem(dataItem, catalogItemListRef.current)
            return
        }

        let updatedCatalogItemList = catalogItemListRef.current.map((item) =>
            !isEqual(item, dataItem) ? item : dataItem
        )
        setCatalogItemList(updatedCatalogItemList)
    }

    const onDeleteItem = (dataItem: ICatalogItem) => {
        if (!isCatalogItemsExists()) return

        deleteItem(dataItem, catalogItemListRef.current)
    }

    const onUpdateNestedItem = (dataItem: ICatalogItem) => {
        if (!isNestedCatalogItemsExists()) return
        if (!isCatalogItemExist(dataItem, nestedCatalogItemListRef.current)) return

        let updatedNestedCatalogItemList = nestedCatalogItemListRef.current.map((item) =>
            !isEqual(item, dataItem) ? item : dataItem
        )
        setNestedCatalogItemList(updatedNestedCatalogItemList)
    }

    const onDeleteNestedItem = (dataItem: ICatalogItem) => {
        if (!isNestedCatalogItemsExists()) return

        deleteNestedItem(dataItem, nestedCatalogItemListRef.current)
    }

    const deleteItem = (dataItem: ICatalogItem, catalogItemList: ICatalogItem[]) => {
        let updatedCatalogItemList = catalogItemList.filter((item) => !isEqual(item, dataItem))
        setCatalogItemList(updatedCatalogItemList)
        removeSelectedItemById(dataItem.id)
    }

    const deleteNestedItem = (dataItem: ICatalogItem, nestedCatalogItemList: ICatalogItem[]) => {
        let updatedNestedCatalogItemList = nestedCatalogItemList.filter((item) => !isEqual(item, dataItem))
        setNestedCatalogItemList(updatedNestedCatalogItemList)
        removeSelectedItemById(dataItem.id)
    }

    const isCurrentFolderItem = (dataItem: ICatalogItem): boolean => {
        const query = getURLSearchParamsBySearchString(search)
        const parentValue = query[parentKey]

        if (isRootFolder(dataItem) && !isExist(parentValue)) return true

        return dataItem[parentKey] === stringToNumber(parentValue)
    }

    const isCatalogItemExist = (dataItem: ICatalogItem, catalogItemList: ICatalogItem[]) => {
        return isExist(catalogItemList.find(({ id }) => id === dataItem.id))
    }

    const isNeedAutoExpandFolder = (dataItemList: ICatalogItem[]) => {
        if (dataItemList.length !== 1) return false

        const { isFolder } = options
        const dataItem = dataItemList[0]

        return isFolder(dataItem) && isRootFolder(dataItem) && isEnableAutoExpand()
    }

    const expandFolder = (dataItem: ICatalogItem) => {
        const { parentKey } = options

        setIsEnableAutoExpandFolder(false)
        changeFilter({ [parentKey]: dataItem.id })
    }

    const isRootFolder = (dataItem: ICatalogItem) => {
        const { parentKey } = options
        return dataItem[parentKey] === null || dataItem[parentKey] === undefined
    }

    const loadMore = () => {
        if (isReachLimitExist()) return

        fetchCatalogItems({
            search,
            viewParams,
            isLazyLoading: true,
        })
    }

    const openCreateItem = () => setIsCreateItemInit(true)
    const closeCreateItem = () => setIsCreateItemInit(false)

    return {
        catalogItemList,
        isInit,
        isCreateItemInit,
        isLoading,
        isReachLimit,
        loadMore,
        fetchCatalogItemsByParentId,
        nestedCatalogItemList,
        openCreateItem,
        closeCreateItem,
    }
}

export const useCatalogKeysMap = () => {
    const [isShiftPressed, setIsShiftPressed] = useState<boolean>(false)

    const handlers = {
        shiftDown: () => {
            setIsShiftPressed(true)
        },
        shiftUp: () => {
            setIsShiftPressed(false)
        },
    }

    return {
        isShiftPressed,
        handlers,
    }
}

export const useSelectedCatalogItems = (props: IUseSelectedCatalogItemsHookArgs = {}): IUseSelectedCatalogItemsHook => {
    const [selectedInfo, setSelectedInfo] = useState<number | null>(null)
    const [selectedItems, setSelectedItems] = useState<number[]>([])
    const isSelectedInstanceOrCollection = selectedInfo !== null || selectedItems.length > 0

    const onSetSelectedInfo = (selectedInfo: number) => {
        setSelectedInfo(selectedInfo)
    }

    const onSetSelectedItems = (selectedItems: number[]) => {
        setSelectedItems(selectedItems)
    }

    const onResetSelectedInfo = () => {
        setSelectedInfo(null)
    }
    const onResetSelectedItems = () => setSelectedItems([])
    const onResetAllSelection = () => {
        setSelectedInfo(null)
        setSelectedItems([])
    }

    const addSelectedItem = (id: number) => {
        let updatedSelectedItems = selectedItems.filter((itemId) => itemId !== id)
        setSelectedItems([...updatedSelectedItems, id])
    }

    const removeSelectedItemById = (id: number) => {
        let updatedSelectedItems = selectedItems.filter((itemId) => itemId !== id)
        setSelectedItems(updatedSelectedItems)
    }

    return {
        selectedInfo: selectedInfo,
        selectedItems: selectedItems,
        onResetSelectedInfo,
        onResetSelectedItems,
        onSetSelectedInfo,
        onSetSelectedItems,
        isSelectedInstanceOrCollection,
        onResetAllSelection,
        addSelectedItem,
        removeSelectedItemById,
    }
}
