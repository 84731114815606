import { addStyles } from 'ethcss'
import { grid, IStyles, mediaCSS } from '../../theme'

const advertisingCampaings: IStyles = {
    aDWrapper: {
        [mediaCSS.tab]: {
            marginTop: 46,
        },
    },
}

addStyles(advertisingCampaings)
export default advertisingCampaings
