import { addStyles } from 'ethcss'
import { IStyles } from 'theme'
import { defaultThemeStyles } from './Marker-theme'

export const markerStyles: IStyles = {
    markerCircle: {
        display: 'inline-block',
        width: 20,
        height: 20,
        borderRadius: '50%',
    },
    markerCircle_withBorder: {
        boxShadow: `0 0 0 1px var(--var-module-ui-component-marker-style-border, ${defaultThemeStyles.border})`,
    },
    markerCircle_small: {
        width: '12px!important',
        height: '12px!important',
    },
}

addStyles(markerStyles)
