import { useEffect, useState } from 'react'
import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'
import request from 'superagent'
import { changeFilter } from 'features/routes'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { IUser } from '../../../core/models/Users'

interface IData {
    action: string[]
    object: string[]
    userId: UserId[]
}

type UserId = {
    id: number
    email: string
}

type DataFilter = {
    action: string
    [index: string]: string
}

const HistoryHeaderMethods = () => {
    const [data, setData] = useState<IData | null>(null)
    const [historyLoading, setHistoryLoading] = useState<boolean>(false)

    useEffect(() => {
        api.send('getUsersActionsFilterValues', {}).then((res: any) => {
            const data = {
                ...res,
                userId: res.users.map((user: IUser) => ({ id: user.id, email: user.email })),
            }

            delete data.users

            setData(data)
        })
    }, [])

    const onFilterChange = (dataFilter: DataFilter) => {
        const filter: any = {}
        for (let key in dataFilter) {
            if (dataFilter.hasOwnProperty(key)) {
                const prefixKey = helpers.prefixQuery({ name: 'history', field: key })
                filter[prefixKey] = dataFilter[key]
            }
        }

        changeFilter(filter)
    }

    const getHistory = (downloadLink: string) => {
        setHistoryLoading(true)
        request
            .get(downloadLink)
            .then(() => {
                // TODO: переделать. сейчас приходит ответ на GET запрос. В поле text находится закодированный файл.
                // Передалать: POST вместо GET. В ответе получаем ссылку на файл, и скачиваем файл через link.click();
                // Сейчас я качаю файл 2 раза - request.get и link.click() - что плохо

                const link = document.createElement('a')
                link.setAttribute('href', downloadLink)
                link.style.display = 'none'
                document.body.appendChild(link)

                link.click()
                document.body.removeChild(link)
                setHistoryLoading(false)
            })
            .catch((e) => {
                emitError('historyError')
                setHistoryLoading(false)
                console.error('getHistory Error', e)
            })
    }

    return {
        data,
        onFilterChange,
        getHistory,
        historyLoading,
    }
}

export default HistoryHeaderMethods
