import grid from 'blocks.simple/grid/grid.jcss'
import { addStyles } from 'ethcss'
import { IStyles } from 'theme'
import { colors, indents, textSizes } from 'theme'

export const authenticationSettingsStyles: IStyles = {
    authenticationSettings__inner: {
        paddingTop: indents.md,
    },
    authenticationSettings__subText: {
        paddingBottom: indents.md,
        fontWeight: 'bold',
    },
    authenticationSettings__row: {
        ...grid.flex,
        ...grid.normalCenter,
        ...grid.row,
        ...grid.noWrap,
        paddingBottom: indents.mdPlus,
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    authenticationSettings__content: {
        paddingLeft: indents.mini,
        fontSize: textSizes.normal,
        color: colors.grey,
    },
    authenticationSettings__icon: {
        display: 'inline-block',
    },
    authenticationSettings__text: {
        paddingRight: indents.mini,
    },
    authenticationSettings__input: {
        maxWidth: 300,
        paddingRight: indents.mini,
    },
}

addStyles(authenticationSettingsStyles)
