import { emitError } from 'features/appNotifications/AppNotifications.state'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

const defaultStateConfirmModal = {
    open: false,
    tagId: null,
}

const TagsListMethods = (p_) => {
    const dispatch = useDispatch()
    const [newTag, setNewTag] = useState('')
    const [query, setQuery] = useState('')
    const [confirmModal, setConfirmModal] = useState(defaultStateConfirmModal)
    const [validateSymbolNumber, setValidateSymbolNumber] = useState(false)
    const [stateOnChangeValidateSymbolNumber, setOnChangeValidateSymbolNumber] = useState(null)

    function onAddNewTag() {
        if (newTag.length > 2) {
            onValidateSymbolNumber(false)
        } else {
            onValidateSymbolNumber(true)
            return
        }
        if (!newTag) {
            dispatch(emitError('tagCannotBeEmpty'))
            return
        }

        p_.onCreate({ name: newTag })
        setNewTag('')
    }

    function onChange(value) {
        setNewTag(value)
    }

    function onDelete(tag) {
        setConfirmModal({ open: true, tagId: tag.id })
    }

    function closeAcceptModal() {
        setConfirmModal({ ...defaultStateConfirmModal })
    }

    function onAcceptModal() {
        p_.onDelete({ id: confirmModal.tagId })
        closeAcceptModal()
    }

    function onChangeQuery(value) {
        setQuery(value)
    }

    function onValidateSymbolNumber(isValidate) {
        setValidateSymbolNumber(isValidate)
    }

    function onChangeValidateSymbolNumber(isValidate) {
        setOnChangeValidateSymbolNumber(isValidate)
    }

    return {
        s_: {
            newTag,
            query,
            confirmModal,
            validateSymbolNumber,
            stateOnChangeValidateSymbolNumber,
        },
        methods: {
            onChange,
            onChangeQuery,
            onAddNewTag,
            onDelete,
            onAcceptModal,
            closeAcceptModal,
            onValidateSymbolNumber,
            onChangeValidateSymbolNumber,
        },
    }
}

export default TagsListMethods
