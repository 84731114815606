import React, { useCallback, useEffect, useState } from 'react'
import { ScrollableFilterListView } from '../../../organisms/ScrollableFilterList/ScrollableFilterList-view'
import styles from './filterList.jcss'
import { cn } from 'ethcss'
import { Icon } from '../../../molecules/Icon'
import translate from '../../../core/translate'
import { FilterInner } from '../FilterInner/FilterInner-view'
import { FilterAdd } from '../FilterAdd/FilterAdd-view'
import { getCompatibleFilters, onApplyFilterToQuery, getSomeValues } from 'core/helpers/filters'
import { IFilter, Option } from '../Filter/Filter-types'
import DotTooltip from 'blocks.simple/dotTooltip/dotTooltip'
import { useLocation } from 'react-router'
import { RootStateOrAny, useSelector } from 'react-redux'
import { ALLOW_ADVANCED_SEARCH } from '../../catalog/catalog'

const FilterItem = ({ item, onSelectFilter, onDelete }: any) => {
    return (
        <div className={styles.filterItem} onClick={() => onSelectFilter(item)}>
            <DotTooltip containerClass={styles.container} tooltip={{ html: getSomeValues(item) }}>
                <div className={styles.tooltip}>{getSomeValues(item)}</div>
            </DotTooltip>
            <div className={styles.deleteItem}>
                <Icon
                    type="close"
                    color="#323232"
                    size={13}
                    onClick={(e: any) => {
                        e.stopPropagation()
                        onDelete(item.name)
                    }}
                    tooltip={{ title: translate('delete') }}
                />
            </div>
        </div>
    )
}

export const FilterList = ({
    selectedFilters,
    setSelectedFilters,
    availableFilters,
    setAvailableFilters,
    type,
    onFilterChange,
    getAdvancedSearch,
}: any) => {
    const [selectedFilter, setSelectedFilter] = useState<IFilter | null>(null)
    const [addModalOpened, setAddModalOpened] = useState<boolean>(false)
    const location = useLocation()
    const allowedSearch: any = ALLOW_ADVANCED_SEARCH
    const advancedSearchSettings = useSelector(
        (store: RootStateOrAny) => store.user.data.settings.advancedSearch[allowedSearch[type]]
    )

    const onAddFilter = useCallback(
        (filterItem: IFilter) => {
            setSelectedFilters([...selectedFilters, filterItem])
            closeAddModal()
        },
        [selectedFilters]
    )

    const closeAddModal = () => {
        setAddModalOpened(false)
    }

    const onSelectFilter = (filterItem: IFilter) => {
        setSelectedFilter(filterItem)
    }

    const onCloseFilterInner = () => {
        if (selectedFilter) {
            onApply()
        }
        setSelectedFilter(null)
    }

    const changeVariant = useCallback(
        (filterItem: any) => {
            setSelectedFilters(
                selectedFilters.map((filter: IFilter) => {
                    if (filter.name === filterItem.name) {
                        const options = filter.options.map((option: Option) => {
                            if (filterItem.option.label === option.label) {
                                return filterItem.option
                            }

                            return option
                        })

                        return {
                            ...filter,
                            options,
                        }
                    }

                    return filter
                })
            )
        },
        [selectedFilters]
    )

    const multiselect = useCallback(
        (filterName: string, state: boolean) => {
            setSelectedFilters(
                selectedFilters.map((filter: IFilter) => {
                    if (filter.name === filterName) {
                        return {
                            ...filter,
                            options: filter.options.map((option: Option) => ({ ...option, selected: state })),
                        }
                    }

                    return filter
                })
            )
        },
        [selectedFilters]
    )

    const onDelete = useCallback(
        (filterName) => {
            const filteredItems = selectedFilters.filter((filter: IFilter) => filter.name !== filterName)

            setSelectedFilters(filteredItems)
            onFilterChange(onApplyFilterToQuery(filteredItems, advancedSearchSettings))
        },
        [selectedFilters, advancedSearchSettings]
    )

    const onApply = useCallback(() => {
        getCompatibleFilters(selectedFilters, location, type, getAdvancedSearch()).then((res: any) => {
            const filteredArray = res.filter((element: any) =>
                selectedFilters.some((item: any) => element.name === item.name)
            )

            setAvailableFilters(res)
            setSelectedFilters(filteredArray)
            onFilterChange(onApplyFilterToQuery(filteredArray, advancedSearchSettings))
        })
        setSelectedFilter(null)
    }, [selectedFilters, location, advancedSearchSettings])

    useEffect(() => {
        if (selectedFilter) {
            // @ts-ignore
            setSelectedFilter(selectedFilters.find((filter: IFilter) => filter.name === selectedFilter.name))
        }
    }, [selectedFilters])

    return (
        <div className={styles.filtersBlock}>
            <div className={styles.filterListWrapper}>
                <ScrollableFilterListView refreshArrowsTrigger={selectedFilters}>
                    <div className={cn(styles.filterList)}>
                        {selectedFilters.map((item: IFilter, index: number) => {
                            return (
                                <FilterItem
                                    key={index}
                                    item={item}
                                    onSelectFilter={onSelectFilter}
                                    onDelete={onDelete}
                                />
                            )
                        })}
                    </div>
                </ScrollableFilterListView>
                {selectedFilter && (
                    <>
                        <FilterInner
                            filter={selectedFilter}
                            changeVariant={changeVariant}
                            multiselect={multiselect}
                            onApply={onApply}
                            onClose={onCloseFilterInner}
                        />
                    </>
                )}
            </div>
            {selectedFilters < availableFilters && (
                <div className={styles.addFilter} onClick={() => setAddModalOpened(true)}>
                    + {translate('addFilter')}
                </div>
            )}
            {addModalOpened && (
                <FilterAdd
                    availableFilters={availableFilters}
                    filterList={selectedFilters}
                    onAddFilter={onAddFilter}
                    onClose={closeAddModal}
                />
            )}
        </div>
    )
}
