import { useState } from 'react'
import translate from 'core/translate'
import helpersSchedule from 'core/helpers/_schedule'
import helpersDisplays from 'core/helpers/_digitalSignages'
import helpersBroadcasts from 'core/helpers/_broadcasts'

type Step = {
    id: string
}
type StepNames = {
    displays: string
    broadcasts: string
    [index: string]: string
}
export type ScheduleDataItem = {
    id: number
    name: string
}
export type BroadcastItem = {
    id: number
    title: string
    instanceType: string
}
interface IScheduleData {
    [index: string]: ScheduleDataItem[]
}
export interface IScheduleSelectProps {
    selectedItems: {
        displays: ScheduleDataItem[]
        broadcasts: BroadcastItem[]
    }
    onShowSchedule: any
}

const ScheduleSelectMethods = (p_: IScheduleSelectProps) => {
    const [selected_displays, set_selected_displays] = useState<ScheduleDataItem[]>(p_.selectedItems.displays)
    const [selected_broadcasts, set_selected_broadcasts] = useState<BroadcastItem[]>(p_.selectedItems.broadcasts)
    const [step, set_step] = useState<string>('displays')
    const steps: Step[] = [{ id: 'displays' }, { id: 'broadcasts' }]

    const emptyText: StepNames = {
        displays: 'noSelectedDevices',
        broadcasts: 'noSelectTranslations',
    }
    const emptyDescription: StepNames = {
        displays: 'chooseOrFindDisplayOrBroadcast',
        broadcasts: 'chooseOrFindDisplayOrBroadcast',
    }
    const titles: StepNames = {
        displays: 'selectedDevices',
        broadcasts: 'selectedTranslations',
    }
    const onSelect = (selectedItems: any) => {
        if (step === 'displays') {
            set_selected_displays(selectedItems)

            return
        }

        set_selected_broadcasts(selectedItems)
    }
    const getContent = () => {
        return {
            title: translate(titles[step]),
            options: step === 'displays' ? selected_displays : selected_broadcasts,
        }
    }
    const isEmpty = () => {
        const selectedStep = step === 'displays' ? selected_displays : selected_broadcasts

        return selectedStep.length === 0
    }
    const showSchedule = () => {
        const scheduleData: IScheduleData = helpersSchedule.getInitScheduleData()

        selected_displays.forEach((selectedItem: ScheduleDataItem) => {
            if (helpersDisplays.isFolder(selectedItem)) {
                scheduleData.groups.push({ id: selectedItem.id, name: selectedItem.name })
            } else {
                scheduleData.digitalSignages.push({ id: selectedItem.id, name: selectedItem.name })
            }
        })
        selected_broadcasts.forEach((selectedItem: BroadcastItem) => {
            if (helpersBroadcasts.isFolder(selectedItem)) {
                scheduleData.broadcastFolders.push({ id: selectedItem.id, name: selectedItem.title })
            } else {
                scheduleData.broadcasts.push({ id: selectedItem.id, name: selectedItem.title })
            }
        })

        for (let type in scheduleData) {
            if (scheduleData.hasOwnProperty(type)) {
                if (scheduleData[type].length === 0) {
                    delete scheduleData[type]
                }
            }
        }

        p_.onShowSchedule(scheduleData)
    }

    return {
        step,
        set_step,
        steps,
        emptyText,
        emptyDescription,
        onSelect,
        getContent,
        isEmpty,
        showSchedule,
        selected_displays,
        selected_broadcasts,
    }
}

export default ScheduleSelectMethods
