import { colors } from 'theme'

export const defaultThemeStyles = {
    textMain: colors.grey,
    active: colors.white,
    hover: colors.white,
    background: colors.white,
    activeBackground: colors.grey,
    hoverBackground: colors.grey,
    border: colors.greyLight,
    hoverBorder: colors.grey,
    activeBorder: colors.grey,
    textDefault: '#191919',

    inverseTabActive: colors.grey,
    inverseTabDisable: 'rgba(0, 0, 0, 0.9)',
    inverseBorder: colors.greyLight,
}
