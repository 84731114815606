import React, { useRef, useState } from 'react'
import Link from 'blocks.simple/link/link'
import Icon from 'blocks.simple/icon/icon'
import Button from 'blocks.simple/button/button'
import TextEditor from 'blocks.app/textEditor/textEditor'
import TableEditor from 'blocks.app/tableEditor/tableEditor'
import LinkEditor from 'blocks.app/linkEditor/linkEditor'
import grid from 'blocks.simple/grid/grid.jcss'
import translate from 'core/translate'
import styles from './catalog__filter_files.jcss'
import helpersLegacy from 'core/helpers'
import helpers from 'core/helpers'
import { cn } from 'ethcss'
import { colors, text as textStyles } from 'theme'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { getThemeStyleValue } from 'theme/colors'
import { getURLSearchParamsByLocation, getURLSearchParamsString } from 'features/routes/utils'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { appActions } from 'blocks.app/app/app.state'
import {
    filesActions,
    saveText,
    saveLink,
    saveTable,
    closeAddTable,
    closeAddLink,
    closeAddText,
} from 'pages/files/files.state'
import { WidgetsGallery } from '../../../../pages/widgetsGallery/widgetsGallery'
import Modal from '../../../../blocks.simple/modal/modal'

type CreateFolderData = {
    title: string
    methodName: string
    fieldParamKey: string
    inputFieldName: string
    placeholder: string
    options: { folderId: string }
}

interface ICatalogFilterProps {
    menu: { menu: { name: string; children: any }[] }[]
    filterItems: { type: string; fileType: string; name: string }[]
    small: boolean
    type: string
    location: any
    query: { type: string; fileType: string; query: string }
    toggleControls: boolean
    activateAddText: () => void
    activateAddTable: () => void
    activateAddLink: () => void
    createFilesFolder: (data: CreateFolderData) => void
    closeAddText: () => void
    closeAddTable: () => void
    closeAddLink: () => void
    saveText: (data: any) => any
    saveTable: (data: any) => any
    saveLink: (data: any) => any
    createFolder: boolean
    widgetsGallery?: boolean
    autoSelectContentType?: boolean
}

const isActive = (item: { type: string; fileType: string }, type: string, fileType: string) => {
    if (type && fileType && item.type === type && item.fileType === fileType) {
        return true
    }

    if (type && !fileType && item.type === type) {
        return true
    }

    if (!type && !fileType && !item.type && !item.fileType) {
        return true
    }
}

const isEditContent = (query: { textId?: number; tableId?: number; linkId?: number }) => {
    return query.textId || query.tableId || query.linkId
}

const FilesFilter = (p_: ICatalogFilterProps) => {
    const [widgetsGalleryIsOpened, setWidgetsGalleryIsOpened] = useState<boolean>(false)
    const linkEditorRef = useRef<any>(null)
    const tableEditorRef = useRef<any>(null)
    const textEditorRef = useRef<any>(null)

    const openWidgetsModal = () => {
        setWidgetsGalleryIsOpened(true)
    }
    const closeWidgetsModal = () => {
        setWidgetsGalleryIsOpened(false)
    }

    const getCreateFolderItem = () => {
        const menu = p_.menu[0]
        if (!menu) return null
        const menuList = menu.menu
        const menuFiles = menuList.find((el: { name: string }) => el.name === 'files')
        if (!menuFiles) return null
        return menuFiles.children.find((el: { name: string }) => el.name === 'createFolder')
    }

    const getFilterItems = () => {
        return [
            { name: translate('all') },
            { name: translate('images'), fileType: 'image', type: 'fs' },
            { name: translate('video'), fileType: 'video', type: 'fs' },
            { name: translate('music'), fileType: 'audio', type: 'fs' },
            { name: translate('office'), fileType: 'office', type: 'fs' },
            { name: translate('links'), type: 'link' },
            { name: translate('text'), type: 'text' },
            { name: translate('tables'), type: 'table' },
            { name: translate('web_apps'), fileType: 'web_app', type: 'fs' },
        ]
    }

    const capFirst = (str: string) => {
        return str[0].toUpperCase() + str.slice(1)
    }

    const items = p_.filterItems || getFilterItems()
    const query = { ...p_.query, fileType: undefined, type: undefined }
    const locationQuery = getURLSearchParamsByLocation(p_.location)
    const pathname = p_.location.pathname
    const folderId = locationQuery.folderId
    const isCreatableContent = folderId && helpers.isAvailable({ key: 'files', action: 'create' })
    const isEditorMode =
        pathname === '/addContentToDevice' || pathname === '/broadcasts/addAdvanced' || pathname === '/broadcasts/edit'

    return (
        <React.Fragment>
            <div className={cn(styles.wrapper, isEditorMode ? styles.wrapperMob : '')}>
                <div className={styles.content}>
                    {items.length > 1 &&
                        items.map((item, index: number) => {
                            if (
                                item.type &&
                                !helpersLegacy.isAvailableFileType({
                                    key: 'files',
                                    name: item.fileType ? item.fileType : item.type,
                                    action: 'read',
                                })
                            ) {
                                return null
                            }
                            return (
                                <Link
                                    key={`files__filter_${index}`}
                                    to={{
                                        pathname: pathname,
                                        search: getURLSearchParamsString({
                                            ...query,
                                            [helpersLegacy.prefixQuery({ name: p_.type, field: 'query' })]: query.query,
                                            [helpersLegacy.prefixQuery({
                                                name: p_.type,
                                                field: 'fileType',
                                            })]: item.fileType,
                                            [helpersLegacy.prefixQuery({ name: p_.type, field: 'type' })]: item.type,
                                            query: undefined,
                                        }),
                                    }}
                                    className={cn(styles.item, textStyles.low, {
                                        [styles.itemActive]: isActive(item, p_.query.type, p_.query.fileType),
                                    })}
                                >
                                    <Icon
                                        tooltip={p_.small && { title: capFirst(item.name) }}
                                        type={`${item.fileType || item.type || 'fs'}`}
                                        color={
                                            isActive(item, p_.query.type, p_.query.fileType)
                                                ? getThemeStyleValue('ui', 'icon', 'active') ||
                                                  defaultThemeStyles.active
                                                : getThemeStyleValue('ui', 'icon', 'mainSub') ||
                                                  defaultThemeStyles.mainSub
                                        }
                                        containerClass={styles.icon}
                                    />
                                    {!p_.small && <span className={styles.spanName}>{item.name}</span>}
                                </Link>
                            )
                        })}
                </div>
                {isCreatableContent && (
                    <React.Fragment>
                        {p_.query.type === 'text' && !p_.toggleControls && (
                            <div className={styles.activateEditorButton}>
                                <Button
                                    mod="fill"
                                    onClick={() => {
                                        p_.activateAddText()
                                    }}
                                >
                                    {translate('addNewText')}
                                </Button>
                            </div>
                        )}
                        {p_.query.type === 'table' && !p_.toggleControls && (
                            <div className={styles.activateEditorButton}>
                                <Button
                                    mod="fill"
                                    onClick={() => {
                                        p_.activateAddTable()
                                    }}
                                >
                                    {translate('addTable')}
                                </Button>
                            </div>
                        )}
                        {p_.query.type === 'link' && !p_.toggleControls && (
                            <div className={styles.activateEditorButton}>
                                <Button
                                    mod="fill"
                                    onClick={() => {
                                        p_.activateAddLink()
                                    }}
                                >
                                    {translate('addLink')}
                                </Button>
                            </div>
                        )}
                    </React.Fragment>
                )}
                <div className={styles.addNew}>
                    {(pathname === '/files/videoEditor' && getCreateFolderItem() && folderId) ||
                    (p_.createFolder && getCreateFolderItem() && folderId) ? (
                        <div className={cn(styles.addFolder)}>
                            <div
                                onClick={() => {
                                    p_.createFilesFolder({
                                        title: translate('createFolder'),
                                        methodName: 'createFolder',
                                        fieldParamKey: 'name',
                                        inputFieldName: translate('folderName'),
                                        placeholder: translate('enterFolderName'),
                                        options: { folderId },
                                    })
                                }}
                            >
                                <Icon
                                    type="addNewFolder"
                                    size={32}
                                    color={colors.grey}
                                    tooltip={{ title: translate('createFolder') }}
                                />
                            </div>
                        </div>
                    ) : null}
                    {p_.widgetsGallery && folderId ? (
                        <div className={cn(styles.addWidget)}>
                            <div onClick={openWidgetsModal}>
                                <Icon
                                    type="addNewWidget"
                                    size={32}
                                    color={colors.grey}
                                    tooltip={{ title: translate('addWidget') }}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
                {(p_.query.type === 'text' || p_.query.type === 'table' || p_.query.type === 'link') &&
                    p_.toggleControls && (
                        <div className={styles.activateEditorButton}>
                            <Button
                                mod={'withBorder'}
                                onClick={() => {
                                    switch (p_.query.type) {
                                        case 'text':
                                            p_.closeAddText()
                                            break
                                        case 'table':
                                            p_.closeAddTable()
                                            break
                                        case 'link':
                                            p_.closeAddLink()
                                            break
                                        default:
                                            break
                                    }
                                }}
                                className={cn(grid.mr_mini)}
                            >
                                {translate('cancel')}
                            </Button>
                            <Button
                                mod="fill"
                                onClick={() => {
                                    switch (p_.query.type) {
                                        case 'text':
                                            const data = textEditorRef.current.saveMethod()

                                            p_.saveText({
                                                data,
                                                options: locationQuery,
                                            })
                                            break
                                        case 'table':
                                            const tableData = tableEditorRef.current.saveMethod()

                                            p_.saveTable({
                                                data: { ...tableData, data: { ...tableData.data, type: 'table' } },
                                                options: locationQuery,
                                            })
                                            break
                                        case 'link':
                                            const linkData = linkEditorRef.current.getData()

                                            if (linkData.error) {
                                                return
                                            }

                                            p_.saveLink({
                                                data: linkData,
                                                options: locationQuery,
                                            })
                                            break
                                        default:
                                            break
                                    }
                                }}
                            >
                                {isEditContent(locationQuery) ? translate('update') : translate('save')}
                            </Button>
                        </div>
                    )}
            </div>
            {p_.query.type === 'text' && p_.toggleControls && (
                <div className={cn('picker-container', styles.editors)}>
                    <TextEditor textEditorRef={textEditorRef} query={locationQuery} small={p_.small} />
                </div>
            )}
            {p_.query.type === 'table' && p_.toggleControls && (
                <div className={cn('picker-container', styles.editors)}>
                    <TableEditor tableEditorRef={tableEditorRef} location={p_.location} small={p_.small} />
                </div>
            )}
            {p_.query.type === 'link' && p_.toggleControls && (
                <div className={cn('picker-container', styles.editors)}>
                    <LinkEditor linkEditorRef={linkEditorRef} location={p_.location} small={p_.small} />
                </div>
            )}
            {widgetsGalleryIsOpened && (
                <div className={styles.modalContainer}>
                    <Modal open={true} showCloseIcon={false} contentClassName={styles.modalClassName}>
                        <div className={styles.modalInner}>
                            <WidgetsGallery modalMode={true} onClose={closeWidgetsModal} folderId={folderId} />
                        </div>
                    </Modal>
                </div>
            )}
        </React.Fragment>
    )
}

const mapStateToProps = (state: { app: { menu: { name: string; children: any }[] } }) => {
    return {
        menu: state.app.menu,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        activateAddText: () => dispatch(filesActions.activateAddText()),
        activateAddTable: () => dispatch(filesActions.activateAddTable()),
        activateAddLink: () => dispatch(filesActions.activateAddLink()),
        closeAddText: () => dispatch(closeAddText()),
        closeAddTable: () => dispatch(closeAddTable()),
        closeAddLink: () => dispatch(closeAddLink()),
        saveText: (saveFileData: any) => dispatch(saveText(saveFileData)),
        saveLink: (saveFileData: any) => dispatch(saveLink(saveFileData)),
        saveTable: (saveFileData: any) => dispatch(saveTable(saveFileData)),
        createFilesFolder: (data: CreateFolderData) => dispatch(appActions.setCreateDirectoryWindow(data)),
    }
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FilesFilter))
