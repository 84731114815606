import { addStyles } from 'ethcss'
import { colors, IStyles, mediaCSS } from 'theme'

const header: IStyles = {
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 14px',
    },
    controlBlock: {
        display: 'flex',
        position: 'absolute',
    },
    controlPositionBlock: {
        padding: '8px 0px',
        alignItems: 'center',
        display: 'flex',
    },
    addIcon: {
        marginRight: 16,
    },
    uploadBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        span: {
            fontSize: 16,
        },
        button: {
            height: 30,
        },
    },
    separator: {
        width: 40,
        height: 30,
        margin: '0 20px',
    },
    input: {
        height: 30,
        border: `1px solid ${colors.blueAccent}`,
        padding: '5px 9px',
        textAlign: 'center',
        fontSize: 18,
    },
}

addStyles(header)
export default header
