import React, { useEffect, useRef } from 'react'
import Dropzone from 'react-dropzone'
import { getURLSearchParamsByLocation } from '../../../features/routes/utils'
import { useDispatch } from 'react-redux'
import { uploadFiles } from '../../../core/services/DownloadService/DownloadService.state'
import { broadcastsActions } from '../broadcasts.state'
import { downloadsActions } from '../../../core/services/DownloadService/DownloadService.state'

type Location = {
    pathname: string
    search: string
    hash: string
    state: any
}
interface IUploadBroadcasts {
    location: Location
}

export const UploadBroadcasts = (p_: IUploadBroadcasts) => {
    const dropzoneRef = useRef<any>(null)
    const dispatch = useDispatch()

    useEffect(() => {
        dropzoneRef.current.open()
    }, [])

    const close = () => {
        dispatch(broadcastsActions.openAddFileModal(false))
    }

    const onDrop = (filesList: any) => {
        const { location } = p_
        const query = getURLSearchParamsByLocation(location)
        const folderId = query.folderId

        if (folderId) {
            dispatch(
                uploadFiles({
                    filesList,
                    folderId: folderId,
                    broadcast: true,
                })
            )
        }

        dispatch(downloadsActions.toggleModal())

        close()
    }

    const onCancel = () => {
        close()
    }

    return (
        <div>
            <Dropzone
                ref={dropzoneRef}
                onDrop={onDrop}
                accept={{ 'application/zip': ['.zip'] }}
                onFileDialogCancel={onCancel}
            >
                {() => <></>}
            </Dropzone>
        </div>
    )
}
