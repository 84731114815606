import React, { useEffect, useState } from 'react'
import styles from './channelAdd.jcss'
import EditText from '../../../blocks.simple/editText/editText'
import translate from '../../../core/translate'
import Button from '../../../blocks.simple/button/button'
import CatalogSelectContent from '../../../blocks.app/catalog/_select/_content/catalog_select_content'
import Dropdown from '../../../blocks.simple/dropdown/dropdown'
import { api } from '../../../core/api/ConnectionManager'

export const ChannelAdd = ({ channel, onChange, onClose, onSave }: any) => {
    const [categories, setCategories] = useState([])

    useEffect(() => {
        api.send('getCategoryList', {
            limit: 1000,
            sort: [['id', 'ASC']],
        }).then((res: any) => {
            if (!res && !res.data) return

            setCategories(res.data)
        })
    }, [])

    return channel ? (
        <div className={styles.channelAdd}>
            <div className={styles.inner}>
                <div className={styles.field}>
                    <EditText
                        value={channel.title}
                        onChange={(value: string) => {
                            onChange('title', value)
                        }}
                        containerClass={styles.separator}
                        className={styles.input}
                        label={translate('name')}
                    />
                </div>
                <div className={styles.field}>
                    <EditText
                        value={channel.channelNumber}
                        onChange={(value: string) => {
                            onChange('channelNumber', value)
                        }}
                        containerClass={styles.separator}
                        className={styles.input}
                        label={translate('number')}
                    />
                </div>
                <div className={styles.field}>
                    <EditText
                        value={channel.channelUrl}
                        onChange={(value: string) => {
                            onChange('channelUrl', value)
                        }}
                        containerClass={styles.separator}
                        className={styles.input}
                        label={translate('ipAddress')}
                    />
                </div>
                <div className={styles.field}>
                    <Dropdown
                        mod={'default'}
                        options={categories}
                        valueField={'title'}
                        labelField={'title'}
                        label={translate('category')}
                        value={channel && channel.category && channel.category.title}
                        onChange={(value: any) => onChange('category', value)}
                    />
                </div>
                <div>
                    <CatalogSelectContent
                        label={translate('logo')}
                        type={'image'}
                        selectedInfo={channel.logo ? channel.logo : null}
                        onSelect={(selectedMedia: { id: number; src: string }) => {
                            onChange('logo', selectedMedia)
                        }}
                        selectedItemStyles={{ width: 70, marginRight: 20 }}
                        itemStyles={styles.itemStyles}
                        className={styles.selectLogo}
                    />
                    <div className={styles.recommendedImage}>Рекомендованный размер изображения 180x100 пикселей</div>
                </div>
                <div className={styles.controls}>
                    <Button mod={'withBorder'} className={styles.button} onClick={onClose}>
                        {translate('cancel')}
                    </Button>
                    <Button
                        mod="fill"
                        className={styles.button}
                        onClick={onSave}
                        disabled={!channel.title || !channel.channelUrl}
                    >
                        {translate(channel.id ? 'save' : 'create')}
                    </Button>
                </div>
            </div>
        </div>
    ) : null
}
