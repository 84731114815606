import React, { FC } from 'react'
import { routes } from 'features/routes'
import { connect } from 'react-redux'
import { ContentWithoutToolbar } from 'organisms/ContentWithoutToolbar'
import { ScoreboardsToolbar } from './organisms/ScoreboardsToolbar'
import { CatalogWithPagination } from 'organisms/CatalogWithPagination'
import {
    IScoreboardsComponentConnected,
    IScoreboardsComponentDispatchProps,
    IScoreboardsComponentProps,
    IScoreboardsComponentStateProps,
} from './scoreboards-types'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import { ScoreboardsItem } from './molecules/ScoreboardsItem'
import { api } from 'core/api/ConnectionManager'
import {
    ICatalogItem,
    ICatalogWithPaginationOptions,
} from 'organisms/CatalogWithPagination/CatalogWithPagination-types'
import helpers from 'core/helpers'
import { getURLSearchParamsString } from 'features/routes/utils'
import { ScoreboardsActionBar } from './organisms/ScoreboardsActionBar'
import { ScoreboardsListItem } from './molecules/ScoreboardsListItem'
import { changeLocation } from 'features/routes'
import Modal from 'molecules/Modal'
import { IModalOptions, scoreboardsActions } from './scoreboards.state'
import { NetworkConverter } from './organisms/NetworkConverter'
import { CurrentControllerData } from './organisms/CurrentControllerData'
import { checkAvailableModule } from '../../core/helpers/routes/routes'

const options: ICatalogWithPaginationOptions = {
    common: {
        type: 'scoreboards',
        parentKey: 'parentId',
        fields: [
            { id: 'id', name: 'id' },
            { id: 'name', name: 'name' },
            { id: 'status', name: 'status' },
        ],
        getMethodName: 'getScoreboardsAndFolders',
        getByParentIdMethodName: 'getScoreboardsAndFoldersWithoutPagination',
        onCreateListeners: [
            (cb, clearList) => api.addObserver('scoreboardCreated', cb, clearList),
            (cb, clearlist) => api.addObserver('onScoreboardFolderCreated', cb, clearlist),
        ],
        onMoveListeners: [
            (cb, clearlist) => api.addObserver('scoreboardMoved', cb, clearlist),
            (cb, clearlist) => api.addObserver('onScoreboardFolderMoved', cb, clearlist),
        ],
        onUpdateListeners: [
            (cb, clearList) => api.addObserver('scoreboardUpdated', cb, clearList),
            (cb, clearlist) => api.addObserver('onScoreboardFolderUpdated', cb, clearlist),
        ],
        onDeleteListeners: [
            (cb, clearList) => api.addObserver('scoreboardDeleted', cb, clearList),
            (cb, clearlist) => api.addObserver('onScoreboardFolderDeleted', cb, clearlist),
        ],
        tile: {
            component: ScoreboardsItem,
        },
        list: {
            component: ScoreboardsListItem,
        },
        dispositionList: ['list', 'tile'],
        defaultDisposition: 'tile',
    },
    methods: {
        isEqual: (item: any, nextItem: any) => item.id === nextItem.id,
        isFolder: (item: any) => item.type === 'ledControllerFolder',
        moveItem: (id, groupId) => api.send('editScoreboard', { ledControllerId: id, parentId: groupId }),
        moveFolder: (id, parentId) => api.send('updateScoreboardFolder', { ledControllerFolderId: id, parentId }),
        onDoubleClickObject: (object: ICatalogItem) => {
            if (helpers.isAvailable({ key: 'scoreboard', action: 'update' })) {
                changeLocation({
                    pathname: `/${routes.editScoreboard.path}`,
                    search: getURLSearchParamsString({
                        controllerId: object.id,
                        parentId: object.parentId,
                    }),
                })
            }
        },
        getDragData: (item: any) => JSON.stringify({ id: item.id, type: item.type }),
    },
    breadcrumbs: {
        title: 'scoreboards',
        methodName: 'getScoreboardsBreadcrumbs',
    },
    emptyList: {
        folderText: 'notFound',
        searchText: translate('noScoreboards'),
        getAddButton: () => (
            <Button rounded="full_md" mod="withShadow" onClick={() => changeLocation(`/${routes.addScoreboard.path}`)}>
                {translate('add')}
            </Button>
        ),
    },
    toolbar: {
        component: ScoreboardsToolbar,
    },
    actionBar: {
        component: ScoreboardsActionBar,
    },
}

const ScoreboardsComponent: FC<IScoreboardsComponentConnected> = ({
    location,
    minimize,
    showMobToolbar,
    modalOptions,
    modalState,
    closeModal,
}) => {
    const isModalOpen = !!modalState

    const renderModalContent = () => {
        if (!modalState) return null

        switch (modalState) {
            case 'networkConverter':
                return <NetworkConverter />
            case 'currentControllerData':
                return <CurrentControllerData />
            default:
                return null
        }
    }

    return (
        <ContentWithoutToolbar title={translate('scoreboards')}>
            <CatalogWithPagination
                options={options}
                location={location}
                minimize={minimize}
                showMobToolbar={showMobToolbar}
            />
            <Modal open={isModalOpen} onClose={closeModal} {...modalOptions}>
                {renderModalContent()}
            </Modal>
        </ContentWithoutToolbar>
    )
}

const mapStateToProps = (state: any): IScoreboardsComponentStateProps => ({
    minimize: state.app.minimizeMenu,
    showMobToolbar: state.app.showMobToolbar,
    modalState: state.scoreboards.modalState,
    modalOptions: state.scoreboards.modalOptions,
})

const mapDispatchToProps = (dispatch: any): IScoreboardsComponentDispatchProps => {
    return {
        closeModal: () => dispatch(scoreboardsActions.closeModal()),
    }
}

export const Scoreboards = checkAvailableModule('scoreboards')
    ? connect<IScoreboardsComponentStateProps, IScoreboardsComponentDispatchProps, IScoreboardsComponentProps>(
          mapStateToProps,
          mapDispatchToProps
      )(ScoreboardsComponent)
    : null
