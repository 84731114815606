import React, { FC, useEffect, useState } from 'react'
import { grid } from 'theme'
import { getThemeStyleValue } from 'theme/colors'
import { styles } from './DownloadItem-styles'
import Icon from 'blocks.simple/icon/icon'
import { cn } from 'ethcss'
import translate from 'core/translate'
import { Typography } from '../../../../../../atoms/Typography'

export const DownloadItemView = ({ broadcastFile }: any) => {
    const { sourceId, fileName, totalSizeBytes, downloadedBytes, downloadedPercent, status } = broadcastFile

    const progressInMegabytes = () => {
        const downloaded = downloadedBytes / 1024 / 1024
        const total = totalSizeBytes / 1024 / 1024

        return `${downloaded.toFixed(2)} MB ${translate('of')} ${total.toFixed(2)} MB`
    }

    useEffect(() => {
        progressInMegabytes()
    })

    const renderStatus = (status: string) => {
        switch (status) {
            case 'downloading':
                return <Typography>{downloadedPercent}%</Typography>
            case 'downloaded':
                return <Icon type="done" color="#1EC686" />
            case 'downloadedMultiply':
                return <Icon type="doneMultiply" color="#1EC686" />
            case 'error':
                return <Icon type="errorWarning" color="#D61750" />
        }
    }

    const renderStatusInfo = (status: string) => {
        switch (status) {
            case 'downloading':
                return <Typography>{progressInMegabytes()}</Typography>
            case 'downloaded':
                return <Typography>{translate('downloaded')}</Typography>
            case 'error':
                return <Typography className={styles.errorMessage}>{translate('error')}</Typography>
        }
    }

    return (
        <li className={styles.downloadItem}>
            <div className={styles.downloadItemHeader}>
                <div className={styles.downloadItemLeft}>
                    <Icon type="folder_smartplayer" color="#b3b3b3" className={styles.icon} />
                    <Typography className={styles.downloadName}>{fileName}</Typography>
                </div>
                <div className={styles.downloadItemRight}>
                    <div className={styles.info}>{renderStatusInfo(status)}</div>
                    <div className={styles.status}>{renderStatus(status)}</div>
                </div>
            </div>
        </li>
    )
}
