import { useEffect, useState } from 'react'

export interface IUseAnimationFavIcon {
    isActive: boolean
}

export const useAnimationFavIcon = ({ isActive }: IUseAnimationFavIcon) => {
    const FAVICON_NAME = 'favicon.png'
    const FAVICON_ALARM_NAME = 'favicon_alarm.png'

    let [isInit, setIsInit] = useState<boolean>(isActive)

    useEffect(() => {
        setIsInit(isActive)
    }, [isActive])

    useEffect(() => {
        if (!isActive) {
            let icon = document.getElementById('favicon') as HTMLLinkElement

            if (icon) {
                icon.href = `/${FAVICON_NAME}`
            }
        }
    }, [isActive])

    useEffect(() => {
        if (!isInit) return

        const createdInterval = setInterval(() => {
            let icon = document.getElementById('favicon') as HTMLLinkElement

            if (icon) {
                let href = icon.href

                if (href.includes(FAVICON_ALARM_NAME)) {
                    icon.href = `/${FAVICON_NAME}`
                } else {
                    icon.href = `/${FAVICON_ALARM_NAME}`
                }
            }
        }, 1000)

        return () => {
            clearInterval(createdInterval)
        }
    }, [isInit])

    return {
        isInitFavIcon: isInit,
    }
}
