import { api } from 'core/api/ConnectionManager'

const getSourceIds = (items: { extension: string; id: number }[]) => {
    if (!items) return

    const getSourceIds: { sourceId: number }[] = []

    items.forEach((media) => {
        const ext = media.extension
        if (ext === '.jpg' || ext === '.png' || ext === '.mp4') {
            return getSourceIds.push({ sourceId: media.id })
        }
    })

    return getSourceIds.length ? getSourceIds : null
}

export async function getSourcesIds(selectedMedia: { extension: string; id: number; content: string }) {
    if (!selectedMedia) return

    if (selectedMedia.content) {
        const response: { extension: string; id: number }[] = await api.send('getSources', {
            folderId: selectedMedia.id,
        })
        return getSourceIds(response)
    } else {
        return getSourceIds([selectedMedia])
    }
}
