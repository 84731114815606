import { addStyles } from 'ethcss'
import { grid, item, IStyles, mediaCSS } from '../../../theme'
import { defaultThemeStyles } from 'blocks.app/sidebar/Sidebar-theme'

const filesToolbarFileStyles: IStyles = {
    wrapper: {
        ...grid.col,
        ...grid.space,
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-background, ${defaultThemeStyles['background']})`,
    },
    flexNowWrap: {
        justifyContent: 'center',
    },
    empty: {
        ...grid.space,
        ...grid.col,
        ...grid.center,
        ...grid.normalCenter,
        ...grid.p_micro,
        textAlign: 'center',
        '& img': { ...grid.w100 },
    },
    title: {
        ...grid.p_md,
        ...grid.pt_mdPlus,
        ...grid.pb_none,
        ...grid.fullWidth,
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    full: {
        wordWrap: 'break-word',
    },
    description: {
        ...grid.mt_mdPlus,
        ...grid.mb_md,
        ...grid.w75,
        '&>p': {
            lineHeight: '22px',
        },
    },
    fileWrapper: {
        textAlign: 'center',
        color: `black`,
    },
    viewInfo: {
        borderRadius: '5px',
        overflow: 'hidden',
    },
    viewInfoNoHiddenOverflow: {
        borderRadius: '5px',
    },
    rectangle: {
        position: 'relative',
        backgroundColor: `var(--var-module-sidebar-component-filePreview-style-background, ${defaultThemeStyles['file-background']})`,
        backgroundSize: '45%',
    },
    videoWrapper: {
        ...grid.fullHeight,
        position: 'relative',
        backgroundColor: `var(--var-module-sidebar-component-filePreview-style-background, ${defaultThemeStyles['file-background']})`,
    },
    progressWrapper: {
        ...grid.fullWidth,
        backgroundColor: `var(--var-module-sidebar-component-filePreview-style-timeline, ${defaultThemeStyles['file-timeline']})`,
        height: 3,
    },
    progress: {
        ...grid.fullHeight,
        ...item.selected,
    },
    contentWrapper: {
        ...grid.full,
        ...grid.row,
    },
    contentItem: {
        width: '50%',
        height: '50%',
    },
    previewWrapper: {
        height: 'calc(100% - 42px)',
    },
    transparentGrid: {
        backgroundImage: 'url(images/helpers/_files/transparent.png)',
    },
    tags: {
        ...grid.mt_md,
        ...grid.rowCenter,
    },
    tagWrapper: {
        ...grid.p_micro,
    },
    video: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
    textContentWrapper: {
        ...grid.row,
        ...grid.center,
        ...grid.normalCenter,
        ...grid.full,
        overflow: 'hidden',
        cursor: 'default',
    },
    qrCodeWrapper: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    },
    closeBtn: {
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',

        [mediaCSS.tab]: {
            bottom: 40,
        },
    },
}

addStyles(filesToolbarFileStyles)
export default filesToolbarFileStyles
