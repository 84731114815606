import React from 'react'
import moment from 'moment'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import scheduleHelpers from 'core/helpers/_schedule'
import helpers from 'core/helpers'
import { changeFilter, routes } from 'features/routes'
import omit from 'omit-empty'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { changeLocation } from 'features/routes'

class TimetableAddMethods extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            currentDate: moment(),
            isUpdate: false,
            isOpen: false,
            isLoading: false,
            days: helpers.getDays(),
            daysBetween: [],
            classrooms: [],
            teachers: [],
            course: [],
            grade: [],
            subjects: [],
            startDate: moment(),
            endDate: moment(),
            repeatMode: 'once',
            repeatDays: [],
            lessonsByTime: [],
        }

        this.onPrevDate = this.onPrevDate.bind(this)
        this.onNextDate = this.onNextDate.bind(this)
        this.onTimeChangeValidate = this.onTimeChangeValidate.bind(this)
        this.addNewTime = this.addNewTime.bind(this)
        this.submit = this.submit.bind(this)
        this.calcDaysBetween = this.calcDaysBetween.bind(this)
        this.convertDateTime = this.convertDateTime.bind(this)
        this.validate = this.validate.bind(this)
        this.removeTimetable = this.removeTimetable.bind(this)
    }
    componentDidMount() {
        const p_ = this.props
        const query = getURLSearchParamsByLocation(p_.location)

        const catalogs = {
            grade: [],
            course: [],
            classrooms: [],
            teachers: [],
            subjects: [],
        }

        api.send('getGrades', { fields: 'id, title' })
            .then(({ data }) => {
                catalogs.grade = data
                return api.send('getCourses', { fields: 'id, title' })
            })
            .then(({ data }) => {
                catalogs.course = data
                return api.send('getSubjects', { fields: 'id, title, courseId, gradeId' })
            })
            .then(({ data }) => {
                catalogs.subjects = data
                return api.send('getClassrooms', { fields: 'id, title' })
            })
            .then(({ data }) => {
                catalogs.classrooms = data
                return api.send('getTeachers', { fields: 'id, name' })
            })
            .then(({ data }) => {
                catalogs.teachers = data
                if (query.startDate && query.endDate) {
                    const startDate = helpers.getFormattedUtcDate(query.startDate, 'YYYY-MM-DD HH:mm:ss')
                    const endDate = helpers.getFormattedUtcDate(query.endDate, 'YYYY-MM-DD HH:mm:ss')

                    this.getLessons(startDate, endDate)
                    this.setState({ ...catalogs, currentDate: moment(query.startDate) })
                }
            })
    }
    onPrevDate() {
        const s_ = this.state
        const currentDate = moment(s_.currentDate).add(-1, 'days')

        this.onNavigate(currentDate)
        this.setState({ currentDate })
    }
    onNextDate() {
        const s_ = this.state
        const currentDate = moment(s_.currentDate).add(1, 'days')

        this.onNavigate(currentDate)
        this.setState({ currentDate })
    }
    onNavigate(date) {
        const startDate = moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = moment(date).endOf('day').format('YYYY-MM-DD HH:mm:ss')

        this.clearState(startDate, endDate)
        changeFilter({ startDate, endDate })
        this.getLessons(startDate, endDate)
    }
    clearState(startDate, endDate) {
        this.setState({
            startDate: moment(startDate),
            endDate: moment(endDate),
            repeatMode: 'once',
            lessonsByTime: [],
        })
    }
    getSubjects(gradeId, courseId) {
        const { subjects } = this.state

        return subjects.filter((subject) => subject.gradeId === gradeId && subject.courseId === courseId)
    }
    getLessons(startDate, endDate) {
        api.send('getTimetable', { startDate, endDate }).then((res) => {
            if (res) {
                const state = { ...res }
                state.endDate = helpers.getFormattedLocalDate(state.endDate, 'YYYY-MM-DD HH:mm:ss')
                state.startDate = helpers.getFormattedLocalDate(state.startDate, 'YYYY-MM-DD HH:mm:ss')

                state.lessonsByTime.forEach((item) => {
                    item.startTime = this.toLocalHoursMinutes(item.startTime)
                    item.endTime = this.toLocalHoursMinutes(item.endTime)
                    item.lessons.forEach((lesson) => {
                        lesson.startTime = this.toLocalHoursMinutes(lesson.startTime)
                        lesson.endTime = this.toLocalHoursMinutes(lesson.endTime)
                    })
                })
                if (state.repeatMode === 'weekly') {
                    this.calcDaysBetween(state)
                }
                this.setState({ ...state, isUpdate: true })
            } else {
                const start = helpers.getFormattedLocalDate(startDate, 'YYYY-MM-DD HH:mm:ss')
                const end = helpers.getFormattedLocalDate(endDate, 'YYYY-MM-DD HH:mm:ss')

                this.setState({ startDate: start, endDate: end, isUpdate: false })
                this.calcDaysBetween({ startDate, endDate })
            }
        })
    }
    toLocalHoursMinutes(time) {
        return helpers.getFormattedLocalDate(time, 'HH:mm')
    }
    addNewLesson(byTimeIndex, type, byTime) {
        const s_ = this.state
        let lessonsByTime = [...s_.lessonsByTime]

        if (type === 'addNewTime') {
            lessonsByTime = [...byTime]
        }

        lessonsByTime[byTimeIndex].lessons.push({
            classroomId: null,
            gradeId: null,
            courseId: null,
            subjectId: null,
            teacherId: null,
            startTime: lessonsByTime[byTimeIndex].startTime,
            endTime: lessonsByTime[byTimeIndex].endTime,
            order: byTimeIndex,
        })

        this.setState({ lessonsByTime })
    }
    addNewTime() {
        const s_ = this.state
        const lessonsByTime = [...s_.lessonsByTime]

        lessonsByTime.push({
            startTime: '',
            endTime: '',
            lessons: [],
        })
        const byTimeIndex = lessonsByTime.length - 1
        this.addNewLesson(byTimeIndex, 'addNewTime', lessonsByTime)
    }
    onCancel() {
        changeLocation(`/${routes.timetable.path}`)
    }
    getTableHeader() {
        return [
            translate('time'),
            translate('gradesCatalog'),
            translate('coursesCatalog'),
            translate('subjectsCatalog'),
            translate('classroomsCatalog'),
            translate('teachersCatalog'),
        ]
    }
    onTimeChangeValidate(value, byTimeIndex, type) {
        const s_ = this.state

        let result = false
        const re = /^\s*([01]?\d|2[0-3]):([0-5]\d)\s*$/
        const m = value.match(re)

        if (m) {
            result = (m[1].length === 2 ? '' : '0') + m[1] + ':' + m[2]
        }

        if (result && type === 'startTime' && s_.lessonsByTime[byTimeIndex].endTime.match(re)) {
            if (result > s_.lessonsByTime[byTimeIndex].endTime) {
                result = false
            }
        }
        if (m && result && type === 'endTime' && s_.lessonsByTime[byTimeIndex].startTime.match(re)) {
            if (result < s_.lessonsByTime[byTimeIndex].startTime) {
                result = false
            }
        }

        return result
    }
    onChangeTime({ value }, byTimeIndex, type) {
        const s_ = this.state
        const lessonsByTime = [...s_.lessonsByTime]

        lessonsByTime[byTimeIndex][type] = scheduleHelpers.fullHoursMinutesFormat(value)
        this.setState({ lessonsByTime })
    }
    removeLesson(byTimeIndex, lessonIndex) {
        const s_ = this.state
        const lessonsByTime = [...s_.lessonsByTime]

        const lessons = lessonsByTime[byTimeIndex].lessons
        lessons.splice(lessonIndex, 1)

        if (lessons.length) {
            this.setState({ lessons })
        } else {
            lessonsByTime.splice(byTimeIndex, 1)
            this.setState({ lessonsByTime })
        }
    }
    onSelected(selected, type, byTimeIndex, lessonIndex) {
        const s_ = this.state
        const lessonsByTime = [...s_.lessonsByTime]

        lessonsByTime[byTimeIndex].lessons[lessonIndex][type] = selected.id
        if (type === 'gradeId' || type === 'courseId') {
            lessonsByTime[byTimeIndex].lessons[lessonIndex].subjectId = null
        }
        this.setState({ lessonsByTime })
    }
    onChangeDays(selected, dayIndex) {
        const s_ = this.state

        if (selected) {
            this.setState({
                repeatDays: [...s_.repeatDays, dayIndex],
            })
        } else {
            this.setState({
                repeatDays: s_.repeatDays.filter((day) => day !== dayIndex),
            })
        }
    }
    onChangeRepeatMode(selected) {
        const s_ = this.state

        if (selected.id === 'once') {
            this.setState({ endDate: s_.startDate })
        }
        if (selected.id === 'weekly') {
            this.calcDaysBetween({ startDate: s_.startDate, endDate: s_.endDate })
        }
        if (selected.id !== 'weekly') {
            this.setState({ repeatDays: [] })
        }
        this.setState({
            repeatMode: selected.id,
        })
    }
    calcDaysBetween({ startDate, endDate }) {
        const min = moment(startDate)
        const max = moment(endDate)
        const daysBetween = []

        for (min; min.isBefore(max); min.add(1, 'days')) {
            let day = min.day()

            if (day === 0) {
                day = 7
            }
            daysBetween.push(day)
        }
        this.setState({ daysBetween })
    }
    validate() {
        const s_ = this.state
        const { emitError } = this.props

        let error = false

        s_.lessonsByTime.forEach((byTime) => {
            if (!error) {
                if (!(byTime.startTime.length && byTime.endTime.length)) {
                    emitError('timeAreRequired')
                    error = true
                }
                if (!error) {
                    byTime.lessons.forEach((lesson) => {
                        const sortKeys = ['classroomId', 'courseId', 'endTime', 'gradeId', 'order', 'startTime']
                        const sortedParameters = sortKeys.reduce((acc, key) => {
                            if (lesson.hasOwnProperty(key)) {
                                acc[key] = lesson[key]
                            }
                            return acc
                        }, {})
                        const exist = Object.values(sortedParameters).indexOf(null) === -1
                        if (!exist) {
                            emitError('fieldsAreRequired')
                            error = true
                        }
                    })
                }
            }
        })

        return !error
    }
    convertToUtc(date, time) {
        return helpers.getFormattedUtcDate(`${moment(date).format('YYYY-MM-DD')} ${time}`, 'YYYY-MM-DD HH:mm:ss')
    }
    convertDateTime() {
        const s_ = this.state
        const p_ = this.props
        const query = getURLSearchParamsByLocation(p_.location)
        const endDate = moment(s_.endDate).endOf('day')

        return {
            startDate: helpers.getFormattedUtcDate(s_.startDate, 'YYYY-MM-DD HH:mm:ss'),
            endDate: helpers.getFormattedUtcDate(endDate, 'YYYY-MM-DD HH:mm:ss'),
            repeatDays: [...s_.repeatDays],
            repeatMode: s_.repeatMode,
            lessonsByTime: s_.lessonsByTime.map((byTime) => ({
                ...byTime,
                startTime: this.convertToUtc(query.startDate, byTime.startTime),
                endTime: this.convertToUtc(query.startDate, byTime.endTime),
                lessons: byTime.lessons.map((lesson) => ({
                    ...omit(lesson),
                    startTime: this.convertToUtc(query.startDate, byTime.startTime),
                    endTime: this.convertToUtc(query.startDate, byTime.endTime),
                })),
            })),
        }
    }
    submit() {
        const s_ = this.state
        const p_ = this.props
        const query = getURLSearchParamsByLocation(p_.location)

        if (this.validate()) {
            const data = this.convertDateTime()
            if (s_.isUpdate) {
                const updateOptions = {
                    startDate: helpers.getFormattedUtcDate(query.startDate, 'YYYY-MM-DD HH:mm:ss'),
                    endDate: helpers.getFormattedUtcDate(query.endDate, 'YYYY-MM-DD HH:mm:ss'),
                }

                api.send('updateTimetable', { id: s_.id, ...data, updateOptions }).then(() => {
                    changeLocation(`/${routes.timetable.path}`)
                })
            } else {
                api.send('createTimetable', { ...data }).then(() => {
                    changeLocation(`/${routes.timetable.path}`)
                })
            }
        }
    }
    removeTimetable() {
        const p_ = this.props
        const query = getURLSearchParamsByLocation(p_.location)

        if (query.startDate && query.endDate) {
            this.setState({ isLoading: true })
            api.send('deleteTimetable', {
                startDate: helpers.getFormattedUtcDate(query.startDate, 'YYYY-MM-DD HH:mm:ss'),
                endDate: helpers.getFormattedUtcDate(query.endDate, 'YYYY-MM-DD HH:mm:ss'),
            }).then(() => {
                this.setState({ isLoading: false })
                changeLocation(`/${routes.timetable.path}`)
            })
        }
    }
}

export default TimetableAddMethods
