import { addStyles } from 'ethcss'
import {defaultThemeStyles} from 'blocks.app/app/app.theme'

const catalogTemplateVideoEditorTasksStyles = {
    list: {
        borderTop: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    tableWrapper: {
        '& > thead': {
            borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
            '& th': {
                borderBottom: 'none',
            },
        },
    },
}

addStyles(catalogTemplateVideoEditorTasksStyles)
export default catalogTemplateVideoEditorTasksStyles
