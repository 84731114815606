import React from 'react'
import Content from '../__toolbarContent/schedule__toolbarContent'
import Sidebar from 'blocks.app/sidebar/sidebar'
import Icon from 'blocks.simple/icon/icon'
import { RootStateOrAny, useSelector } from 'react-redux'
import translate from 'core/translate'
import helpersSchedule from 'core/helpers/_schedule'
import styles from './schedule__toolbar.jcss'
import { getParamsForLink } from 'core/helpers/user'

const BottomContent = (p_: any) => (
    <div>
        <a className={styles.icon} href={p_.downloadLink} target="_blank" rel="noopener noreferrer">
            <Icon type="download_arrow" color="white" />
        </a>
        <div className={styles.menuItem} onClick={p_.cancelAll}>
            {translate('cancelAll')}
        </div>
    </div>
)

const ScheduleToolbar = (p_: any) => {
    const userState: any = useSelector<RootStateOrAny>((state) => state.user)
    const schedule: any = useSelector<RootStateOrAny>((state) => state.schedule)
    const allSchedules = schedule.allSchedules
    const eventsByDate = schedule.eventsByDate
    const selectedEventId = schedule.selectedEventId
    const selectedDates = schedule.selectedDates
    const selectedDate = schedule.selectedDate
    const user = userState.data
    const tokens = userState.tokens
    const mainUser = userState.mainUser
    const params = getParamsForLink(tokens, mainUser.id, user.id)
    const downloadLink = helpersSchedule.getDownloadLink(selectedDates, params)
    const isSelectedDates = selectedDates.length === 0

    return (
        <Sidebar
            selectedSide="right"
            side="right"
            content={
                isSelectedDates
                    ? null
                    : {
                          right: {
                              title: translate('selectedSchedules'),
                              options: selectedDates,
                              downloadLink,
                          },
                      }
            }
            customContent={{
                right: isSelectedDates ? (
                    <Content
                        eventsByDate={eventsByDate}
                        allSchedules={allSchedules}
                        selectedDate={selectedDate}
                        selectedEventId={selectedEventId}
                    />
                ) : null,
            }}
            onChange={(list: any, side: any) => {
                if (side === 'right') {
                    p_.onChange(list)
                }
            }}
            bottomContent={{
                right: isSelectedDates ? null : <BottomContent cancelAll={p_.cancelAll} downloadLink={downloadLink} />,
            }}
            isEmpty={{
                right: false,
            }}
            onSelectInfo={() => {}}
        />
    )
}

export default ScheduleToolbar
