import React from 'react'
import { default as ReactSelect } from 'react-select'
import styles from './displays__notifications.jcss'
import translate from 'core/translate'

interface IMySelectProps {
    allowSelectAll: boolean
    value: any
    onChange: (selected: any) => void
    options: any[]
    allOption: {
        label: string
        value: any
    }
    isDisabled?: boolean
    isMulti?: boolean
    separator?: boolean
    closeMenuOnSelect?: boolean
    hideSelectedOptions?: boolean
    components?: any
    placeholder?: string
}

export const MySelect = (props: IMySelectProps) => {
    if (props.allowSelectAll) {
        return (
            <ReactSelect
                classNamePrefix={styles.customSelect}
                {...props}
                options={[props.allOption, ...props.options]}
                onChange={(selected: { value: string }[]) => {
                    if (
                        selected !== null &&
                        selected.length > 0 &&
                        selected[selected.length - 1].value === props.allOption.value
                    ) {
                        return props.onChange(props.options)
                    }
                    return props.onChange(selected)
                }}
            />
        )
    }

    return <ReactSelect {...props} />
}

MySelect.defaultProps = {
    allOption: {
        label: translate('selectAll'),
        value: '*',
    },
}
