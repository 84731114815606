import React from 'react'
import styles from './View.local.jcss'
import Icon from 'blocks.simple/icon/icon'

export const ViewLocal = ({ change, value }: { change: (value: string) => void; value: string }) => {
    return (
        <div className={styles.viewWrapper} onClick={() => change(value)}>
            <Icon type={value} />
        </div>
    )
}
