export default {
    marquee: {
        MIN_SPEED: 1,
        MAX_SPEED: 30,
        defaultSettings: {
            direction: 'disabled',
            speed: 1,
        },
    },
}
