import { addStyles } from 'ethcss'
import { grid, item, IStyles } from 'theme'
import { colors } from 'theme'
import { mediaCSS, textSizes } from 'theme/media'

const displaysDatetimeStyles: IStyles = {
    modalWrapper: {
        ...grid.full,
        ...grid.rowCenter,
    },
    modalInner: {
        [mediaCSS.tabMini]: {
            ...grid.w90,
        },
        ...item.card,
        ...grid.p_md,
        ...item.shadowModal,
        ...item.rounded_full_mini,
        ...item.rel,
        width: '540px !important',
    },
    buttons: {
        ...grid.row,
        ...grid.mt_big,
        ...grid.pr_mini,
        ...grid.pl_mini,
        ...grid.center,
    },
    title: {
        fontSize: textSizes.normalPlus,
    },
    description: {
        ...grid.mt_micro,
        color: colors.darkCloud,
    },
    content: {
        ...grid.justify,
        ...grid.mt_md,
        ...grid.mb_md,
        ...grid.row,
    },
    loader: {
        ...grid.colCenter,
        ...grid.justify,
        ...item.rel,
        ...item.abs,
        ...grid.full,
        ...grid.p_md,
        top: 0,
        left: 0,
    },
    displaysDatetime__inputWrapper_type_date: {
        width: 120,
        [mediaCSS.tabMini]: {
            ...grid.w100,
            ...grid.mb_md,
        },
    },
    displaysDatetime__inputWrapper_type_time: {
        width: 120,
        [mediaCSS.tabMini]: {
            ...grid.w100,
            ...grid.mb_md,
        },
    },
    displaysDatetime__inputWrapper_type_timezone: {
        ...grid.w35,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    displaysDatetime__inputWrapper_type_automaticTime: {
        position: 'relative',
        width: 230,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    switchLabel: {
        marginBottom: 6,
    },
}

addStyles(displaysDatetimeStyles)
export default displaysDatetimeStyles
