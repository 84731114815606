import React, { SyntheticEvent, useState } from 'react'
import PropTypes from 'prop-types'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import DropMenu from 'blocks.simple/dropMenu/dropMenu'
import Button from 'blocks.simple/button/button'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import { routes } from 'features/routes'
import styles from './adBlocks__toolbarContent.jcss'
import { grid } from '../../../theme'
import { Typography } from 'atoms/Typography'
import { getURLSearchParamsString } from 'features/routes/utils'
import { changeLocation } from 'features/routes'
import { cn } from 'ethcss'
import { IAdBlocksToolbar } from '../__toolbar/adBlocks__toolbar'

const AdBlocksToolbarContent = (p_: IAdBlocksToolbar) => {
    const [active, setActive] = useState([])

    function resetActiveState() {
        setActive([])
    }

    function onDelete(e: SyntheticEvent) {
        e.preventDefault()

        resetActiveState()

        if (p_.selectedInfo) {
            api.send('deleteFillersAdBlock', { id: p_.selectedInfo.id })
        }
    }

    function renderTitle() {
        return (
            <div className={styles.titleWrapper}>
                <Typography className={styles.title} type="title">
                    <div>{p_.selectedInfo && p_.selectedInfo.title}</div>
                </Typography>
            </div>
        )
    }

    function renderDeleteForm() {
        return (
            <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => onDelete(e)} className={styles.formWrapper}>
                <div className={styles.formTitle}>{translate('adBlockDeleteWarning')}?</div>
                <div className={styles.formButtons}>
                    <Button indentSize="mini" type="submit" className={grid.mr_mini} mod="fill">
                        {translate('yes')}
                    </Button>
                    <Button indentSize="mini" onClick={resetActiveState}>
                        {translate('no')}
                    </Button>
                </div>
            </form>
        )
    }

    function renderFooter() {
        return (
            <div className={styles.footer}>
                <Button
                    mod={'withBorder'}
                    onClick={function () {
                        p_.onCloseInfo(null)
                    }}
                    indentSize="mini"
                    rounded="full_normal"
                >
                    {translate('close')}
                </Button>
            </div>
        )
    }

    if (!p_.selectedInfo) {
        return null
    }

    return (
        <div className={styles.wrapper}>
            {renderTitle()}
            <Rectangle
                src={p_.selectedInfo.cover.src}
                contain={p_.selectedInfo.cover.isIcon}
                className={cn(grid.col, styles.viewInfoNoHiddenOverflow)}
                width={16}
                height={9}
            >
                <div className={grid.space} />
                <DropMenu
                    active={active}
                    className={styles.iconsWrapper}
                    mod="centered"
                    onChange={setActive}
                    onClick={(clicked: string) => {
                        if (clicked === 'edit') {
                            changeLocation({
                                pathname: `/${routes.editFillersAdBlock.path}`,
                                search: getURLSearchParamsString({
                                    advBlockId: p_.selectedInfo && p_.selectedInfo.id,
                                }),
                            })
                        }
                    }}
                    items={[
                        {
                            id: 'edit',
                            icon: 'pencil',
                            tooltip: {
                                title: translate('edit'),
                            },
                        },
                        {
                            id: 'delete',
                            icon: 'delete',
                            tooltip: {
                                title: translate('delete'),
                            },
                            children: renderDeleteForm(),
                        },
                    ]}
                />
            </Rectangle>
            <div className={grid.space} />
            {renderFooter()}
        </div>
    )
}

AdBlocksToolbarContent.propTypes = {
    selectedInfo: PropTypes.object,
    onCloseInfo: PropTypes.func,
}

export default AdBlocksToolbarContent
