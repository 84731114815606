import React, { FC, memo, useContext, useState } from 'react'
import { ITileItem } from './TileItem-types'
import { styles } from './TileItem-styles'
import { cn } from 'ethcss'
import { CatalogWithPaginationContext } from 'organisms/CatalogWithPagination/CatalogWithPagination-context'
import { grid } from 'theme'
import DndDroppable from 'blocks.app/dnd/_droppable/dnd_droppable'
import {
    ICatalogItem,
    ICatalogItemData,
    ICatalogWithPaginationOptionsTile,
} from 'organisms/CatalogWithPagination/CatalogWithPagination-types'

const TileItemComponent: FC<ITileItem> = ({ listItem, minimize }) => {
    const { common, methods, catalogMethods } = useContext(CatalogWithPaginationContext)
    const { onMoveItem } = catalogMethods
    const { tile } = common
    const { tileClassNames, columns, component } = tile as ICatalogWithPaginationOptionsTile
    const { isFolder } = methods
    const [isActiveDnD, setIsActiveDnD] = useState(false)
    const [isHovered, setIsHovered] = useState(false)

    const handleDrop = (data: ICatalogItemData, e: React.MouseEvent) => {
        const dndItem = getDroppedItem(data)
        e.stopPropagation()

        if (!dndItem) {
            setIsActiveDnD(false)
            return
        }

        if (dndItem.id === listItem.id) {
            setIsActiveDnD(false)
            return
        }

        onMoveItem(
            dndItem,
            {
                type: 'folder',
                target: listItem,
            },
            setIsActiveDnD
        )
    }

    const getDroppedItem = (data: ICatalogItemData): ICatalogItem | null => {
        try {
            return JSON.parse(data.dnd_item)
        } catch (err) {
            return null
        }
    }

    const onDragOver = () => {
        setIsActiveDnD(true)
    }
    const onDragLeave = () => {
        setIsActiveDnD(false)
    }

    const getTileClassName = () => {
        if (!tileClassNames) {
            return minimize ? styles.TileItem_type_minimize : styles.TileItem
        }

        return minimize ? tileClassNames.minimize : tileClassNames.desktop
    }

    const getColumns = () => {
        if (columns) {
            return minimize ? columns.minimize : columns.desktop
        }

        return minimize ? 5 : 4
    }

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
    }

    const Tile = component

    return (
        <div className={cn(getTileClassName(), styles.TileItem_type_mobile, grid[`col${getColumns()}`])}>
            <DndDroppable
                dropEnabled={isFolder(listItem)}
                onDrop={handleDrop}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Tile isHovered={isHovered} isActive={isActiveDnD} item={listItem} />
            </DndDroppable>
        </div>
    )
}

export const TileItem = memo(TileItemComponent)
