import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'molecules/Icon'
import { noEmoji } from 'core/utils/displayUtil'
import translate from 'core/translate'

import { cn } from 'ethcss'
import styles from './styles'
import { item, text } from 'theme'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { defaultThemeStyles as defaultThemeNotifyStyles } from 'blocks.simple/notifyBadge/notifyBadge.theme'

export const EditTextPassword = (p_) => {
    const [isShow, setShow] = useState(false)

    return (
        <EditText
            {...p_}
            type={isShow ? 'text' : 'password'}
            icon={{
                type: 'eye',
                tooltip: { title: isShow ? translate('hidePassword') : translate('showPassword') },
                onClick: () => setShow(!isShow),
                color: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            }}
            autoComplete="new-password"
        />
    )
}

const EditText = (p_) => {
    const onChange = (e) => {
        const value = e.target.value

        p_.onChange && p_.onChange(!p_.enableEmoji ? noEmoji(value) : value, e)
    }

    const onFocus = (e) => {
        const value = e.target.value

        p_.onFocus && p_.onFocus(value, e)
    }

    const onBlur = (e) => {
        const value = e.target.value

        p_.onBlur && p_.onBlur(value, e)
    }

    const inputWrapperStyle = cn(p_.inputWrapperClassName, styles.wrapperInput)

    const getStatusLineStyle = () => ({ background: getColorByStatus(p_.statusOptions) })

    const getColorByStatus = (statusOptions) => {
        if (statusOptions && statusOptions.type) {
            switch (statusOptions.type) {
                case 'error':
                    return `var(--var-module-ui-component-notifyBadge-style-error, ${defaultThemeNotifyStyles.error})`
                case 'warning':
                    return `var(--var-module-ui-component-notifyBadge-style-warning, ${defaultThemeNotifyStyles.warning})`
                case 'success':
                    return `var(--var-module-ui-component-notifyBadge-style-success, ${defaultThemeNotifyStyles.success})`
                default:
                    return 'transparent'
            }
        }
        return 'transparent'
    }

    const getIconPropsByStatus = (statusOptions) => {
        let options = { ...p_.icon }
        options.color = getColorByStatus(statusOptions)

        if (statusOptions && statusOptions.type) {
            switch (statusOptions.type) {
                case 'error':
                    options.type = 'exclamation'
                    break
                case 'warning':
                    options.type = 'exclamation'
                    break
                case 'success':
                    options.type = 'success'
                    break
                default:
                    options.type = ''
            }
        }
        return options
    }

    return (
        <div className={cn(p_.wrapperClassName, { [styles.hoverIconWrapper]: p_.showIconOnHover }, item.rel)}>
            {p_.label && (
                <label htmlFor={p_.id} className={cn(p_.labelClassName, text[p_.fontColor], text[p_.fontSize])}>
                    {p_.label}
                </label>
            )}
            <div className={inputWrapperStyle}>
                <input
                    type={p_.type}
                    value={p_.value}
                    placeholder={p_.placeholder}
                    className={cn(
                        p_.inputClassName,
                        item[`rounded_${p_.rounded}`],
                        item[p_.backgroundColor],
                        item[`indent_${p_.indent}`],
                        text['greyDark'],
                        text[p_.fontSize]
                    )}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    autoComplete={p_.autoComplete}
                />
                {p_.icon /* && !p_.statusOptions.isUseIcon чтобы не было двух иконок */ && (
                    <div className={p_.iconWrapperClassName} onClick={p_.icon.onClick}>
                        <Icon {...p_.icon} />
                    </div>
                )}
                {p_.statusOptions && p_.statusOptions.isUseIcon && p_.statusOptions.type && (
                    <div className={p_.iconWrapperClassName}>
                        <Icon {...getIconPropsByStatus(p_.statusOptions)} />
                    </div>
                )}
                {p_.statusOptions && <div className={cn(styles.statusLine)} style={getStatusLineStyle()} />}
            </div>
            {p_.statusOptions && p_.statusOptions.message && (
                <div className={cn(styles.statusMessage)} style={{ color: getColorByStatus(p_.statusOptions) }}>
                    {p_.statusOptions.message}
                </div>
            )}
        </div>
    )
}

EditText.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    type: PropTypes.string,
    icon: PropTypes.shape(Icon.propTypes),
    showIconOnHover: PropTypes.bool,
    rounded: PropTypes.string,
    backgroundColor: PropTypes.string,
    fontColor: PropTypes.string,
    fontSize: PropTypes.string,
    indent: PropTypes.string,
    placeholder: PropTypes.string,
    wrapperClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    inputClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    iconWrapperClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    inputWrapperClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    autoComplete: PropTypes.string,
    statusOptions: PropTypes.object,

    // error: PropTypes.bool,
    // errorMessage: PropTypes.string,
    // success: PropTypes.bool,
    // successMessage: PropTypes.string
}

EditText.defaultProps = {
    type: 'text',
    rounded: 'full_mini',
    backgroundColor: 'water',
    fontColor: 'grey',
    indent: 'normal',
    fontSize: 'normal',
    autoComplete: 'off',
    placeholder: '',
    wrapperClassName: styles.wrapper,
    labelClassName: styles.label,
    inputWrapperClassName: styles.inputClassName,
    inputClassName: styles.input,
    iconWrapperClassName: styles.icon,
    statusOptions: null,
}

export default EditText
