import React from 'react'
import filesHelpers from 'core/helpers/_files'
import { TEXT_DISPLAY_AS } from 'core/models/Files'
import styles from './preview__text.jcss'

type Item = {
    name: string
    displayAs: string
    thumbnail: string
    style: object
    id: number
    src: string
    fileType: string
    folderId: number
    data: string
    isDirectory: number
    type: string
}

const PreviewText = (p_: { item: Item }) => {
    const { item } = p_

    if (item.displayAs === TEXT_DISPLAY_AS.QR_CODE) {
        return (
            <div
                className={styles.previewQrCode}
                style={{
                    backgroundImage: `url(${item.thumbnail})`,
                }}
            />
        )
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.content} style={filesHelpers.convertStyles(item.style)}>
                {item.name}
            </div>
        </div>
    )
}

export default PreviewText
