import { addStyles } from 'ethcss'
import { colors, indents, IStyles } from 'theme'
import grid from 'theme/grid/styles'

export const styles: IStyles = {
    distributivesHeader: {
        padding: `${indents.md}px ${indents.mdPlus}px`,
        background: colors.white,
    },
    distributivesHeader__button: {
        ...grid.flex,
        ...grid.normalCenter,
        cursor: 'pointer',
    },
    distributivesHeader__text: {
        paddingLeft: indents.md,
        textTransform: 'uppercase',
    },
}

addStyles(styles)
