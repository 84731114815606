import './item.css'
import { addStyles } from 'ethcss'
import { grid, IStyles } from 'theme'
import { radiusSizes, transitions } from 'blocks.app/config'
import { colors } from 'theme'
import { textSizes } from 'theme/media'
import { defaultThemeTableStyles } from 'atoms/Table/Table-theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { defaultThemeStyles as defaultThemeModalStyles } from 'molecules/Modal/Modal-theme'

const createAnimation = (str: string) => ({
    '-webkit-animation': str,
    '-moz-animation': str,
    '-o-animation': str,
    animation: str,
    position: 'relative',
})

export const shadowButton = {
    boxShadow: '0px 4px 4px rgba(0, 54, 136, 0.25)',
}

export const blured = {
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.25)',
}

export const shadowSearchbar = {
    boxShadow: '2px 3px 4px 0px rgba(0, 0, 0, 0.25)',
}

export const shadowBlockTop = {
    boxShadow: '0px -4px 8px -4px rgba(0,0,0,0.25)',
}

export const shadowFull = {
    boxShadow: '0px 2px 18px 0px rgba(0,0,0,0.35)',
}

const backOpacity = {
    backgroundColor: 'rgba(255, 255, 255, 0.55)',
}

let radiusSizeStyles: { [index: string]: {} } = {}
let colorsStyles: { [index: string]: {} } = {}
const colorsList: { [index: string]: string } = colors

const item: IStyles = {
    ...(() => {
        let key

        for (key in radiusSizes) {
            if (radiusSizes.hasOwnProperty(key)) {
                if (typeof radiusSizes[key] === 'string') {
                    radiusSizeStyles[`rounded_full_${key}`] = {
                        borderRadius: `${radiusSizes[key]}!important`,
                    }
                } else {
                    radiusSizeStyles[`rounded_full_${key}`] = {
                        borderRadius: `${radiusSizes[key]}px!important`,
                    }
                }

                radiusSizeStyles[`rounded_top_${key}`] = {
                    borderTopLeftRadius: radiusSizes[key],
                    borderTopRightRadius: radiusSizes[key],
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                }
                radiusSizeStyles[`rounded_right_${key}`] = {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: radiusSizes[key],
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: radiusSizes[key],
                }
                radiusSizeStyles[`rounded_left_${key}`] = {
                    borderTopLeftRadius: radiusSizes[key],
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: radiusSizes[key],
                    borderBottomRightRadius: 0,
                }
                radiusSizeStyles[`rounded_bottom_${key}`] = {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: radiusSizes[key],
                    borderBottomRightRadius: radiusSizes[key],
                }
                radiusSizeStyles[`rounded_top_left_${key}`] = {
                    borderTopLeftRadius: radiusSizes[key],
                }
            }
        }

        return radiusSizeStyles
    })(),
    ...(() => {
        let key

        for (key in colors) {
            if (colors.hasOwnProperty(key)) {
                colorsStyles[key] = {
                    backgroundColor: colorsList[key],
                }

                colorsStyles[`${key}Color`] = {
                    color: colorsList[key],
                }
            }
        }
        return colorsStyles
    })(),
    panel() {
        return {
            ...this.rounded_full_mini,
            ...grid.p_mini,
            backgroundColor: `var(--var-module-app-component-app-style-panel, ${defaultThemeStyles.panel})`,
        }
    },
    tableWrapper: {
        ...grid.w100,
        ...radiusSizeStyles.rounded_full_micro,
        color: `var(--var-module-ui-component-table-style-text, ${defaultThemeTableStyles['text']})`,
        backgroundColor: `var(--var-module-ui-component-table-style-background, ${defaultThemeTableStyles['background']})`,
        '& thead': {
            '&:first-child': {
                border: `1px solid var(--var-module-ui-component-table-style-cellBorder, ${defaultThemeTableStyles['cellBorder']})`,
            },
        },
        '& th': {
            ...grid.p_mini,
            borderRight: `1px solid var(--var-module-ui-component-table-style-cellBorder, ${defaultThemeTableStyles['cellBorder']})`,
            borderBottom: `1px solid var(--var-module-ui-component-table-style-cellBorder, ${defaultThemeTableStyles['cellBorder']})`,
            '&:last-child': {
                borderRight: 'none',
            },
        },
        '& td': {
            ...grid.p_micro,
            cursor: 'pointer',
            verticalAlign: 'middle',
            borderBottom: `1px solid var(--var-module-ui-component-table-style-cellBorder, ${defaultThemeTableStyles['cellBorder']})`,
            '&:last-child': {
                borderRight: 'none',
            },
        },
        // overflow: 'hidden',
    },
    tableSelected: {
        '& td': {
            backgroundColor: `var(--var-module-ui-component-table-style-selected, ${defaultThemeTableStyles['selected']})`,
            color: `var(--var-module-ui-component-table-style-selected-text, ${defaultThemeTableStyles['selected-text']})`,
        },
    },
    tableCell: {
        ...grid.w100,
        ...grid.rowCenter,
    },
    shadow: {
        boxShadow: '-1px 3px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    withShadow: {
        boxShadow: '-1px 3px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    shadowSearchbar,
    shadowButton,
    shadowBlockTop,
    blured,
    shadowFull,
    shadowMenu: {
        boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, 0.25)',
    },
    shadowFile: {
        boxShadow: '0px 3px 13.5px 1.5px rgba(3, 205, 242, 0.5)',
    },
    shadowImage: {
        boxShadow: '0px 3px 13.5px 1.5px rgba(0, 0, 0, 0.5)',
    },
    shadowDatepicker: {
        boxShadow: '0px 3px 13.5px 1.5px rgba(0, 0, 0, 0.2)',
    },
    shadowModal: {
        boxShadow: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
    },
    pointer: {
        cursor: 'pointer',
    },
    cursorGrab: {
        cursor: 'grab',
        '&:active': {
            cursor: 'grabbing',
        },
    },
    cursorMove: {
        cursor: 'move !important',
    },
    cursorDefault: {
        cursor: 'default',
    },
    hidden: {
        visibility: 'hidden',
    },
    visible: {
        visibility: 'visible',
    },
    rel: {
        position: 'relative',
    },
    viewInfo: {
        borderRadius: '5px',
        overflow: 'hidden',
    },
    viewInfoNoHiddenOverflow: {
        borderRadius: '5px',
    },
    abs: {
        position: 'absolute',
    },
    fullAbs: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    fixed: {
        position: 'fixed',
    },
    disableWrapper: {
        opacity: 0.5,
        pointerEvents: 'none',
        cursor: 'not-allowed!important',
    },
    block: {
        display: 'block',
    },
    blockImportant: {
        display: 'block !important',
    },
    none: {
        display: 'none',
    },
    overNone: {
        overflow: 'hidden',
    },
    overNoneImportant: {
        overflow: 'hidden !important',
    },
    overVisible: {
        overflow: 'visible',
    },
    overAuto: {
        overflow: 'auto',
    },
    errorText: {
        color: colors.errorLight,
        height: textSizes.normal,
    },
    errorInputText: {
        color: 'red',
        fontSize: '12px',
    },
    ellipsisWrapper: {
        ...grid.space,
        width: 0,
        overflow: 'hidden',
    },
    ellipsis: {
        paddingTop: 1,
        paddingBottom: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    whiteColor: {
        color: '#fff',
    },
    ellipsisPure: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    cover: {
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
    },
    coverSpace: {
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
    },
    contain: {
        width: '100%',
        height: '100%',
        backgroundSize: 'contain',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
    },
    containSpace: {
        width: '100%',
        backgroundSize: 'contain',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
    },
    backOpacity,
    whiteOpacity: {
        backgroundColor: 'rgba(255, 255, 255, 0.97)',
    },
    blackOpacity: {
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
    },
    previewOpacity: {
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
    },
    dsStateOpacity: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    transition: {
        transition: `${transitions.normal}ms linear`,
    },
    transitionErase: {
        transition: `${transitions.normal}ms ease-in-out`,
    },
    transitionEraseHeight: {
        transition: `height ${transitions.normal}ms ease-in-out`,
    },
    circle: {
        borderRadius: '50%',
    },
    fadeIn: {
        ...createAnimation(`item__fadeIn ${transitions.normal}ms ease-in forwards`),
    },
    fadeOut: {
        ...createAnimation(`item__fadeOut ${transitions.normal}ms ease-in forwards`),
    },
    relative: {
        position: 'relative',
    },
    disabled: {
        cursor: 'not-allowed !important',
    },
    disabledLayer: {
        ...backOpacity,
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        cursor: 'not-allowed !important',
    },
    card: {
        ...grid.p_mini,
        backgroundColor: `var(--var-module-app-component-modal-style-background, ${defaultThemeModalStyles.background})`,
    },
    indent_mini: {
        ...grid.pt_micro,
        ...grid.pb_micro,
        ...grid.pl_microPlus,
        ...grid.pr_microPlus,
    },
    indent_normal: {
        ...grid.pt_microPlus,
        ...grid.pb_microPlus,
        ...grid.pl_mini,
        ...grid.pr_mini,
    },
    indent_tb_mini: {
        ...grid.pt_micro,
        ...grid.pb_micro,
    },
    indent_tb_normal: {
        ...grid.pt_microPlus,
        ...grid.pb_microPlus,
    },
    indent_tiny: {
        ...grid.pt_micro,
        ...grid.pb_micro,
        ...grid.pl_mini,
        ...grid.pr_mini,
    },
    indent_nano: {
        ...grid.pl_nano,
        ...grid.pr_nano,
    },
    title: {
        ...grid.pb_mini,
        fontSize: textSizes.md,
        textAlign: 'center',
    },
    description: {
        color: colors.darkCloud,
        fontSize: textSizes.mini,
        textAlign: 'center',
    },
    overY: {
        overflowY: 'auto',
    },
    overX: {
        overflowX: 'auto',
    },
    iBlock: {
        display: 'inline-block',
    },
    vLine: {
        display: 'inline-block',
        width: 0,
        height: '100%',
    },
    noSelection: {
        userSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',
        MozUserSelect: 'none',
    },
    targetFolder: {
        display: 'flex',
        justifyContent: 'center',
    },
    targetFolderLink: {
        textDecoration: 'underline',
        cursor: 'pointer',
        marginBottom: 20,

        '&:hover': {
            textDecoration: 'none',
        },
    },
    mobPreview: {
        width: 40,
        height: 40,
    },
}

addStyles(item)
export default item
