export enum ActionBarActionType {
    ADD = 'add',
    UPLOAD = 'upload',
    DELETE = 'delete',
    DATE_INTERVAL = 'dateInterval',
}

export enum ActionBarRequestHeaderType {
    DEFAULT = 'default',
    WITH_AUTH = 'with_auth',
}

export interface ActionBarAction {
    order: number
    type: ActionBarActionType
    options: {
        [key: string]: string
    }
    cb: () => {}
}
