import React from 'react'
import PropTypes from 'prop-types'
import PreviewWebAppMethods from './preview__webApp.local.methods'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import LoaderItem from 'blocks.app/loader/__item/loader__item'
import Measure from 'react-measure'
import CatalogSelectContent from 'blocks.app/catalog/_select/_content/catalog_select_content'
import ReactDOM from 'react-dom'
import styles from './preview__webApp.jcss'
import translate from 'core/translate'
import grid from 'blocks.simple/grid/grid.jcss'
import { cn } from 'ethcss'
import { connect } from 'react-redux'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { getDefaultLocale } from 'core/helpers/user'
import { IUser } from '../../../core/models/Users'

class SelectContent extends React.Component<any> {
    static propTypes = {
        selectContentRef: PropTypes.func,
    }
    static defaultProps = {
        selectCatalogContentRef: () => {},
    }
    state = {
        domNode: document.getElementById('root'),
    }
    renderContent = () => {
        const { selectCatalogContentRef, onSelect }: any = this.props

        return (
            <CatalogSelectContent
                type="mediaAndFolder"
                onSelect={onSelect}
                selectCatalogContentRef={selectCatalogContentRef}
                isHideButtonAndPreview
                isHideSidebar
            />
        )
    }
    render() {
        const s_ = this.state

        return s_.domNode ? ReactDOM.createPortal(this.renderContent(), s_.domNode) : null
    }
}

class PreviewWebApp extends PreviewWebAppMethods {
    render() {
        const s_ = this.state
        const p_ = this.props
        let width = s_.width
        let height = s_.height

        if (p_.width && p_.height) {
            width = p_.width
            height = p_.height
        }

        if (s_.loadingData) {
            return (
                <div className={styles.isLoadingWrapper}>
                    <div className={styles.loadingData}>
                        <div className={grid.mb_md}>
                            <LoaderItem small={true} />
                        </div>
                        <div>{translate('loadingWidgetData')}</div>
                    </div>
                </div>
            )
        }

        if (s_.text) {
            return <div className={styles.wrapper}>{translate(s_.text)}</div>
        }

        if (s_.webAppPreviewURL) {
            if (s_.showSettingsOnMobile && p_.isEditable) {
                return (
                    <div className={styles.mobileSettingsWrapper}>
                        <iframe
                            ref={(webAppPreview) => (this.webAppPreview = webAppPreview)}
                            title={s_.webAppPreviewURL}
                            src={s_.webAppPreviewURL}
                            frameBorder={0}
                            onLoad={this.iframeOnLoad}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            allow={'fullscreen'}
                        />
                        <SelectContent
                            selectCatalogContentRef={(selectContent: any) => (this.selectContent = selectContent)}
                            onSelect={this.onSelectCatalogContent}
                        />
                    </div>
                )
            }

            return (
                <div className={cn(styles[`previewWebAppWrapper_${p_.indents}`])}>
                    <div className={cn(styles.previewWebAppWrapper)}>
                        <Measure
                            bounds
                            onResize={(contentRect) => {
                                this.setState({
                                    dimensions: contentRect.bounds,
                                })
                            }}
                        >
                            {({ measureRef }) => (
                                <div
                                    style={{ position: 'absolute', visibility: 'hidden' }}
                                    className={cn(styles.screen)}
                                    ref={measureRef}
                                >
                                    {(() => {
                                        if (!s_.dimensions.width) {
                                            return null
                                        }

                                        return <Rectangle width={width} height={height} />
                                    })()}
                                </div>
                            )}
                        </Measure>
                        {(() => {
                            if (!s_.dimensions.width) {
                                return null
                            }
                            let scale = width > s_.dimensions.width ? s_.dimensions.width / width : 1
                            const screenRelSize = s_.dimensions.height
                                ? s_.dimensions.height / s_.dimensions.width
                                : 1080
                            const widgetRelSize = height / width
                            let relSize = screenRelSize / widgetRelSize

                            if (relSize > 1) {
                                relSize = 1
                            }
                            scale = scale * relSize

                            return (
                                <div style={{ width: `${100 * relSize}%` }}>
                                    <Rectangle
                                        width={width}
                                        height={height}
                                        className={cn({ [grid.colCenter]: width <= s_.dimensions.width })}
                                    >
                                        <iframe
                                            ref={(webAppPreview) => (this.webAppPreview = webAppPreview)}
                                            title={s_.webAppPreviewURL}
                                            src={s_.webAppPreviewURL}
                                            frameBorder={0}
                                            onLoad={this.iframeOnLoad}
                                            style={{
                                                width,
                                                height,
                                                // @ts-ignore
                                                MozTransform: `scale(${scale})`,
                                                MozTransformOrigin: `0 0`,
                                                OTransform: `scale(${scale})`,
                                                OTransformOrigin: `0 0`,
                                                WebkitTransform: `scale(${scale})`,
                                                WebkitTransformOrigin: `0 0`,
                                            }}
                                            allow={'fullscreen'}
                                        />
                                    </Rectangle>
                                </div>
                            )
                        })()}
                        <SelectContent
                            selectCatalogContentRef={(selectContent: any) => (this.selectContent = selectContent)}
                            onSelect={this.onSelectCatalogContent}
                        />
                    </div>
                </div>
            )
        }

        return (
            <div className={styles.isLoadingWrapper}>
                <LoaderItem small={true} />
            </div>
        )
    }
}

const mapStateToProps = (state: {
    user: { mainUser: IUser; data: any; tokens: { accessToken: string; refreshToken: string; locale: string } }
}) => {
    return {
        mainUser: state.user.mainUser,
        user: state.user.data,
        tokens: state.user.tokens,
        locale: state.user.data?.settings?.locale || getDefaultLocale(),
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        emitError: (err: string) => dispatch(emitError(err)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PreviewWebApp)
