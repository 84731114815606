import { CustomScroll } from 'atoms/CustomScroll'
import { Typography } from 'atoms/Typography'
import LoaderLazy from 'blocks.app/loader/_lazy/loader__lazy'
import Button from 'blocks.simple/button/button'
import Icon from 'blocks.simple/icon/icon'
import translate from 'core/translate'
import { cn } from 'ethcss'
import React, { FC } from 'react'
import { colors } from 'theme'
import { styles } from './InformationWindow-styles'
import { InformationWindowProps } from './InformationWindow-types'

const WIDTH = 782
const MAX_SCROLL_AREA_HEIGHT = 375

export const InformationWindow: FC<InformationWindowProps> = ({
    onReject,
    onAccept,
    title,
    rejectText,
    acceptText,
    children,
    isDisableReject = false,
    isDisableAccept = false,
    isError = false,
    isLoading = false,
    errorText = '',
    scrollAreaHeight = MAX_SCROLL_AREA_HEIGHT,
    width = WIDTH,
}) => {
    const getWindowStyle = () => ({ width })

    const isRenderAcceptButton = () => onAccept && acceptText

    const isRenderRejectButton = () => onReject && rejectText

    const renderContent = () => children

    const renderError = () => {
        return (
            <div className={styles.InformationWindow__error}>
                <div className={styles.InformationWindow__icon}>
                    <Icon size="24" type="error" color={colors.error} />
                </div>
                <Typography type="error" className={styles.InformationWindow__title}>
                    {translate('error')}
                </Typography>
                <Typography type="text" className={styles.InformationWindow__text}>
                    {errorText}
                </Typography>
            </div>
        )
    }

    const renderLoading = () => <LoaderLazy key="currentControllerData" active />

    const render = () => {
        if (isError) return renderError()
        if (isLoading) return renderLoading()

        return renderContent()
    }

    const getScrollAreaStyle = () => ({ height: scrollAreaHeight })

    return (
        <div className={styles.InformationWindow}>
            <div className={styles.InformationWindow__window} style={getWindowStyle()}>
                <Typography type="title" className={styles.InformationWindow__header}>
                    {title}
                </Typography>
                <div className={styles.InformationWindow__main}>
                    <div className={styles.InformationWindow__content}>
                        <CustomScroll
                            classNames={cn(styles.InformationWindow__area, {
                                [styles.InformationWindow__area_centered]: isLoading || isError,
                            })}
                            style={getScrollAreaStyle()}
                        >
                            {render()}
                        </CustomScroll>
                    </div>
                </div>
                <div className={styles.InformationWindow__footer}>
                    {isRenderAcceptButton() && (
                        <Button
                            className={styles.InformationWindow__button}
                            mod="withBorder"
                            disabled={isDisableAccept}
                            onClick={onAccept}
                        >
                            {acceptText}
                        </Button>
                    )}
                    {isRenderRejectButton() && onReject && rejectText && (
                        <Button
                            className={styles.InformationWindow__button}
                            mod="fill"
                            disabled={isDisableReject}
                            onClick={onReject}
                        >
                            {rejectText}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}
