import React, { FunctionComponent, useEffect, useRef } from 'react'
import request from 'superagent'
import DropMenu from 'blocks.simple/dropMenu/dropMenu'
import Button from 'blocks.simple/button/button'
import config from 'core/config'
import styles from './ActionBar-styles'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { text as textStyles } from 'theme'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { isExist, isNotEmptyString } from 'core/utils/coreUtil'
import Icon from 'blocks.simple/icon/icon'
import { IActionBarComponent } from './ActionBar-types'
import { useActionBar, useUploadDelimeter } from './ActionBar-hooks'
import { ActionBarRequestHeaderType, ActionBarAction, ActionBarActionType } from 'core/models/ActionBar'
import { Label } from 'atoms/Label/Label-view'
import { constants } from 'core/constants'
import EditableText from 'blocks.simple/editableText/editableText'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeTableStyles } from 'atoms/Table/Table-theme'
import { useDispatch } from 'react-redux'
import { IUserState } from 'blocks.app/user/user.state'
import { loaderActions } from 'features/loader/Loader.state'
import { useTypedSelector } from 'core/store/hooks'
import { DateInterval } from 'molecules/DateInterval/DateInterval'
import { isHorizontalMenu } from 'core/helpers/menu'

export const CatalogActionBar: FunctionComponent<IActionBarComponent> = ({
    children,
    actions,
    className,
    selectedItems,
    fakeLine,
    requestHeaderType,
    type,
    title,
    get,
    state,
    setState,
}) => {
    const dispatch = useDispatch()
    const DEFAULT_UPLOAD_URL: string = `${config.api.root}v2/upload/school/instances_catalog`
    const { activeElements, setActiveElements } = useActionBar()
    const { delimiter, setDelimiter } = useUploadDelimeter(constants.FILE_UPLOAD_CSV_DELIMITER)
    const inputRef = useRef<HTMLInputElement>(null)
    const { tokens } = useTypedSelector<IUserState>((state) => state.user)

    const getHeadersByType = () => {
        switch (requestHeaderType) {
            case ActionBarRequestHeaderType.WITH_AUTH:
                return tokens ? { Authorization: `Bearer ${tokens.accessToken}` } : {}
            default:
                return {}
        }
    }

    const changeDelimiter = (delimiterValue: string) => {
        setDelimiter(delimiterValue)
    }

    const getAcceptFiles = (acceptFiles: string) => (acceptFiles ? acceptFiles : `.xls,.xlsx`)
    const sortActionByOrder = (actions: ActionBarAction[]) =>
        actions.sort((first, second) => first.order - second.order)

    const deleteItem = (action: ActionBarAction) => {
        return {
            id: 'delete',
            icon: 'delete',
            disabled: selectedItems.length === 0 && !fakeLine,
            color: 'grey',
            tooltip: {
                title: translate('delete'),
            },
            children: (
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        setActiveElements([])
                        action.cb()
                    }}
                    className={cn(grid.colCenter, styles.allertModalForm)}
                >
                    <div className={cn(styles.allertModalTitle, textStyles.center)}>{translate('deleteApprove')}</div>

                    <div className={cn(grid.row, grid.justify)}>
                        <Button
                            type="submit"
                            mod="fill"
                            indentSize={'mini'}
                            className={cn(styles.allertModalButton)}
                            textSize={'mini'}
                            rounded={'full_normal'}
                        >
                            {translate('yes')}
                        </Button>
                        <Button
                            mod={'withBorder'}
                            animation={false}
                            onClick={() => setActiveElements([])}
                            indentSize={'mini'}
                            textSize={'mini'}
                            rounded={'full_normal'}
                        >
                            {translate('no')}
                        </Button>
                    </div>
                </form>
            ),
        }
    }

    const handleAddButtonClick = (action: ActionBarAction) => {
        action.cb()
    }

    const dateInterval = (action: any) => {
        return (
            // @ts-ignore
            <div className={styles.dateInterval}>
                <DateInterval onChange={action.cb} dateBegin={action.dateBegin} dateEnd={action.dateEnd} />
            </div>
        )
    }

    const getAddActionComponent = (action: ActionBarAction) => {
        return (
            <div className={cn(styles.addButton)} key={action.type}>
                <Icon
                    onClick={() => handleAddButtonClick(action)}
                    type={'plus_circle'}
                    tooltip={{ title: translate('add') }}
                />
            </div>
        )
    }

    const getDeleteActionComponent = (action: ActionBarAction) => {
        return (
            <div className={cn(styles.deleteButton)} key={action.type}>
                <DropMenu
                    active={activeElements}
                    onChange={(active: any) => setActiveElements(active)}
                    items={[deleteItem(action)]}
                    gray={true}
                />
            </div>
        )
    }

    const handleUploadButtonClick = () => {
        if (!inputRef.current) return

        inputRef.current.click()
    }

    const handleUploadButtonChange = (action: ActionBarAction) => {
        if (!inputRef.current) return

        const files = inputRef.current.files

        if (!files) return

        const options = action.options
        const uploadUrl = options.upload ? `${config.api.root}${options.upload}` : DEFAULT_UPLOAD_URL
        const headers = getHeadersByType()

        const formData = new FormData()
        formData.append('file', files[0])

        if (isExist(delimiter) && isNotEmptyString(delimiter)) {
            formData.append('delimiter', delimiter)
        }

        dispatch(loaderActions.startLoading())

        request
            .post(uploadUrl)
            .set({ ...headers })
            .send(formData)
            .end(() => {
                dispatch(loaderActions.endLoading())
            })

        inputRef.current.value = ''
        action.cb()
    }

    const getUploadActionComponent = (action: ActionBarAction) => {
        const options = action.options
        const acceptedFiles = getAcceptFiles(options.acceptFiles)

        return (
            <div className={cn(styles.uploadButton)} key={action.type}>
                <Label
                    htmlFor={'menuProductDelimiter'}
                    textColor={getThemeStyleValue('ui', 'table', 'text') || defaultThemeTableStyles.text}
                >
                    {translate('separator')}:
                </Label>
                <EditableText
                    id={'menuProductDelimiter'}
                    text={delimiter}
                    placeholder={translate('separator')}
                    onChange={changeDelimiter}
                    onUpdate={changeDelimiter}
                    className={styles.delimiter}
                    containerClassName={styles.delimiterWrapper}
                    mod={'withBorder'}
                    rounded={'full_normal'}
                    hideIcon={true}
                />
                <Button
                    mod={'withBorder'}
                    iconPos="left"
                    icon="upload_arrow"
                    animation={false}
                    onClick={() => handleUploadButtonClick()}
                >
                    {translate('upload')}
                </Button>
                <input
                    className={cn(item.none)}
                    type="file"
                    ref={inputRef}
                    accept={acceptedFiles}
                    onChange={() => handleUploadButtonChange(action)}
                />
            </div>
        )
    }

    const renderActionComponents = () => {
        if (!isExist(actions) || actions.length === 0) return null

        return sortActionByOrder(actions).map((action) => {
            switch (action.type) {
                case ActionBarActionType.ADD:
                    return getAddActionComponent(action)
                case ActionBarActionType.DELETE:
                    return getDeleteActionComponent(action)
                case ActionBarActionType.UPLOAD:
                    return getUploadActionComponent(action)
                case ActionBarActionType.DATE_INTERVAL:
                    return dateInterval(action)
                default:
                    return getAddActionComponent(action)
            }
        })
    }
    const horizontalMenu = isHorizontalMenu()
    return (
        <div
            className={cn(
                styles.actionBarWrapper,
                horizontalMenu && type === 'survey' && styles.actionBarWrapper_x,
                horizontalMenu && type === 'quiz' && styles.actionBarWrapper_x,
                horizontalMenu && type === 'lightOfHistory' && styles.actionBarWrapper_x,
                horizontalMenu && type === 'lensOfTime' && styles.actionBarWrapper_x
            )}
        >
            <div className={styles.content}>
                <div
                    className={cn(
                        grid.startJustify,
                        styles.tools,
                        horizontalMenu && styles.margin_x,
                        horizontalMenu && type === 'exhibitions' && styles.margin_no_x,
                        horizontalMenu && type === 'authors' && styles.margin_no_x
                    )}
                >
                    {renderActionComponents()}
                </div>
                <div className={styles.title}>{title}</div>
            </div>
        </div>
    )
}
