import React from 'react'
import styles from './broadcasts__downloadInfo.jcss'
import Button from '../../../blocks.simple/button/button'
import { getThemeStyleValue } from '../../../theme/colors'
import { defaultThemeStyles } from '../../../blocks.simple/icon/icon.theme'
import Icon from '../../../blocks.simple/icon/icon'
import translate from '../../../core/translate'

export const DownloadInfoModal = ({ onClose, progressList }: { onClose: () => void; progressList: any[] }) => {
    return (
        <div className={styles.modalInfoWrapper} onClick={onClose}>
            <div className={styles.modalInfo} onClick={(e) => e.stopPropagation()}>
                <Icon
                    type={'broadcastDownloadProgress'}
                    size={61}
                    color={getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable}
                />
                <div className={styles.modalInfoMessage}>
                    {translate('prepareBroadcastDownload_1') + ' '}
                    {' ' + translate('prepareBroadcastDownload_2')}
                </div>
                {progressList.length ? (
                    <div className={styles.progress}>
                        <div className={styles.progressTitle}>Выполненяется:</div>
                        {progressList.map((progress) => {
                            return <div className={styles.progressItem}>{`${progress.name} ${progress.percent}%`}</div>
                        })}
                    </div>
                ) : null}
                <Button className={styles.closeBtn} mod={'withBorder'} onClick={onClose}>
                    {translate('close')}
                </Button>
            </div>
        </div>
    )
}
