import { addStyles } from 'ethcss'
import { radiusSizes } from 'blocks.app/config'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { IStyles } from '../../theme'

const triggerSize = 25

const colorpickerStyles: IStyles = {
    wrapper: {
        position: 'relative',
    },
    trigger: {
        width: triggerSize,
        height: triggerSize,
        border: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        borderRadius: radiusSizes.micro,
    },
    picker: {
        position: 'absolute',
        zIndex: 3000,
    },
    transparentWrapper: {
        backgroundSize: 50,
        backgroundImage: 'url(images/helpers/_files/transparent.png)',
    },
}

addStyles(colorpickerStyles)
export default colorpickerStyles
