import { addStyles } from 'ethcss'
import { IStyles, grid, item } from 'theme'
import { colors } from 'theme'
import { hexToRgba } from 'core/utils'

const hoveredTableRowBackground = hexToRgba(colors.cloud, 0.3)

export const treeViewCatalogListStyles: IStyles = {
    treeViewCatalogListTableWrapper: {
        ...grid.w100,
    },
    treeViewCatalogListTableCell: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.p_micro,
    },
    treeViewCatalogListRow: {
        ...item.transition,
        '& td': {
            verticalAlign: 'middle',
        },
        '&:hover': {
            background: hoveredTableRowBackground,
        },
    },
    treeViewCatalogListTableCellIcon: {
        ...grid.mr_mini,
    },
    permissionsIconsWrapper: {
        ...grid.row,
        ...grid.noWrap,
        ...grid.justifyEnd,
    },
    permissionsIcon: {
        ...grid.mr_mini,
        ...grid.ml_mini,
    },
}

addStyles(treeViewCatalogListStyles)
