import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'

const catalogTemplateDisplaysStyles = {
    status: {
        ...grid.p_micro,
        ...item.rounded_full_mini,
        ...item.white,
        ...grid.mr_mini,
    },
    name: {
        ...grid.w100,
        ...grid.row,
        ...grid.noWrap,
        ...grid.normalCenter,
        textAlign: 'left',
    },
}

addStyles(catalogTemplateDisplaysStyles)
export default catalogTemplateDisplaysStyles
