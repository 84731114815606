import React from 'react'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './deviceSettings__modBusSettings.jcss'
import EditText from '../../../blocks.simple/editText/editText'
import { SwitchButton } from '../../../atoms/SwitchButton'
import { Typography } from '../../../atoms/Typography'
import grid from '../../../blocks.simple/grid/grid.jcss'
import { Radio } from '../../../atoms/Radio'

export const ModBusSettings = (p_: {
    modBus: any
    className?: string | object
    onChange: (data: any) => void
    enabled: boolean
}) => {
    if (!p_.enabled || !p_.modBus) {
        return null
    }

    const onDataChange = (value: any, type: string) => {
        p_.onChange({ ...p_.modBus, [type]: value })
    }

    return (
        <div className={cn(p_.className)}>
            <div className={styles.switchWrapper}>
                <div className={cn(styles.switchCaption)}>{translate('useModBus')}</div>
                <SwitchButton
                    checked={p_.modBus && p_.modBus.enabledQ}
                    onChange={() => {
                        onDataChange(!p_.modBus.enabledQ, 'enabledQ')
                    }}
                />
            </div>
            <div className={cn(styles.wrapper, !p_.modBus.enabledQ ? styles.disabled : '')}>
                <EditText
                    mod={'withBorder'}
                    className={styles.input}
                    label={translate('ipAddress')}
                    value={p_.modBus && p_.modBus.deviceIP}
                    onChange={(e: string) => {
                        onDataChange(e, 'deviceIP')
                    }}
                    disabled={!p_.modBus.enabledQ}
                />
            </div>
            <div className={cn(styles.wrapper, !p_.modBus.enabledQ ? styles.disabled : '')}>
                <EditText
                    mod={'withBorder'}
                    className={styles.input}
                    label={translate('TCPPort')}
                    value={p_.modBus && p_.modBus.TCPPort}
                    onChange={(e: string) => {
                        onDataChange(e, 'TCPPort')
                    }}
                    disabled={!p_.modBus.enabledQ}
                />
            </div>
            <div className={cn(styles.wrapper, !p_.modBus.enabledQ ? styles.disabled : '')}>
                <EditText
                    mod={'withBorder'}
                    className={styles.input}
                    label={translate('registersQuantity')}
                    value={p_.modBus && p_.modBus.registers}
                    onChange={(e: string) => {
                        onDataChange(e, 'registers')
                    }}
                    disabled={!p_.modBus.enabledQ}
                />
            </div>
            <div className={cn(styles.wrapper, !p_.modBus.enabledQ ? styles.disabled : '')}>
                <EditText
                    mod={'withBorder'}
                    className={styles.input}
                    label={translate('scanRate')}
                    value={p_.modBus && p_.modBus.scanRate}
                    onChange={(e: string) => {
                        onDataChange(e, 'scanRate')
                    }}
                    disabled={!p_.modBus.enabledQ}
                />
            </div>
            <div className={cn(styles.wrapper, !p_.modBus.enabledQ ? styles.disabled : '')}>
                <EditText
                    mod={'withBorder'}
                    className={styles.input}
                    label={translate('startAddr')}
                    value={p_.modBus && p_.modBus.startAddr}
                    onChange={(e: string) => {
                        onDataChange(e, 'startAddr')
                    }}
                    disabled={!p_.modBus.enabledQ}
                />
            </div>
            <div className={cn(styles.wrapper, !p_.modBus.enabledQ ? styles.disabled : '')}>
                <EditText
                    mod={'withBorder'}
                    className={styles.input}
                    label={translate('registersLength')}
                    value={p_.modBus && p_.modBus.registersLength}
                    onChange={(e: string) => {
                        onDataChange(e, 'registersLength')
                    }}
                    disabled={!p_.modBus.enabledQ}
                />
            </div>
            <div className={cn(styles.wrapper, !p_.modBus.enabledQ ? styles.disabled : '')}>
                <EditText
                    mod={'withBorder'}
                    className={styles.input}
                    label={translate('floorDelta')}
                    value={p_.modBus && p_.modBus.floorDelta}
                    onChange={(e: string) => {
                        onDataChange(e, 'floorDelta')
                    }}
                    disabled={!p_.modBus.enabledQ}
                />
            </div>
            <div className={cn(styles.wrapper, !p_.modBus.enabledQ ? styles.disabled : '')}>
                <Typography className={cn(grid.mb_md)} type="title">
                    {translate('presenceZeroFloor')}
                </Typography>
                <div className={styles.radioButtons}>
                    <Radio
                        label={translate('on')}
                        selected={p_.modBus.presenceZeroFloor}
                        className={grid.mr_md}
                        onClick={() => onDataChange(true, 'presenceZeroFloor')}
                    />
                    <Radio
                        label={translate('off')}
                        selected={!p_.modBus.presenceZeroFloor}
                        onClick={() => onDataChange(false, 'presenceZeroFloor')}
                    />
                </div>
            </div>
        </div>
    )
}
