import React, { useState } from 'react'
import styles from './checkbox.jcss'
import { item } from '../../theme'
import { ICheckboxProps } from './checkbox'

const CheckboxMethods = (p_: ICheckboxProps) => {
    const checked = p_.checked
    const [clicked, setClicked] = useState(false)

    const getDerivedStateFromProps = (p_: any) => {
        return {
            checked: p_.checked,
        }
    }
    const onClick = (e: React.MouseEvent) => {
        const clicked = !checked

        if (p_.disabled) {
            e.stopPropagation()
            return
        }

        p_.onClick(clicked, e)
        setClicked(clicked)
    }
    const onDoubleClick = () => {
        if (!p_.disabled) {
            if (p_.onDoubleClick) {
                p_.onDoubleClick()
            }
        }
    }
    const getClassNames = () => {
        const areaClassNames = [
            styles.area,
            {
                [styles[`${p_.mod}Checked`]]: checked,
                [styles[`${p_.mod}Animate`]]: checked && p_.animate && clicked,
            },
            styles[`area_type_${p_.mod}`],
        ]

        const wrapperClassNames = [
            styles.wrapper,
            p_.className,
            {
                [item.disabled]: p_.disabled,
            },
        ]
        return { areaClassNames, wrapperClassNames }
    }

    return {
        checked,
        onClick,
        onDoubleClick,
        getClassNames,
    }
}

export default CheckboxMethods
