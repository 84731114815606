import translate from 'core/translate'
import React from 'react'

import { colorsDiagram } from '../config'
import styles from './flowDiagram__legend.jcss'

class FlowDiagramLegend extends React.Component {
    render() {
        return (
            <div className={styles.legend}>
                <div className={styles.title}>{translate('designations')}</div>
                <div className={styles.item}>
                    <div className={styles.colorBlock} style={{ backgroundColor: colorsDiagram.start }}></div>
                    <div> – {translate('startPage')}</div>
                </div>
                <div className={styles.item}>
                    <div className={styles.colorBlock} style={{ backgroundColor: colorsDiagram.looped }}></div>
                    <div> – {translate('loopedPage')}</div>
                </div>
                <div className={styles.item}>
                    <div className={styles.colorBlock} style={{ backgroundColor: colorsDiagram.onEvent }}></div>
                    <div> – {translate('onEventPage')}</div>
                </div>
            </div>
        )
    }
}

export default FlowDiagramLegend
