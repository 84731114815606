import { useState } from 'react'
import Toast from 'blocks.simple/toast/toast'
import validator from 'validator'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import { useDispatch } from 'react-redux'
import { emitError } from '../../../features/appNotifications/AppNotifications.state'

interface IState {
    [index: string]: string
    selectedType: string
    yandexDisk: string
    url: string
}

export interface IFilesAddProps {
    folderId: number
    onClose: () => void
}

const FilesAddMethod = (p_: IFilesAddProps) => {
    const dispatch = useDispatch()
    const [state, setState] = useState<IState>({
        selectedType: 'local',
        yandexDisk: '',
        url: '',
    })

    const validate = () => {
        const s_ = state

        const currentTypeValue = s_[s_.selectedType]

        if (!currentTypeValue) {
            dispatch(emitError('emptyLinkError'))
            return false
        }

        if (!validator.isURL(currentTypeValue)) {
            dispatch(emitError('linkFormatError'))
            return false
        }

        return true
    }
    const onUploadFromUrl = () => {
        const s_ = state
        const url = s_[s_.selectedType]

        if (validate()) {
            const data: {
                folderId?: number
                url: string
                type: string
            } = {
                url,
                type: s_.selectedType,
            }

            if (p_.folderId) {
                data.folderId = p_.folderId
            }

            api.send('createSourceFromUrl', data).then(() => {
                if (s_.selectedType === 'yandexDisk') {
                    Toast.info(translate('syncWithYaDiskStarted'))
                }

                p_.onClose()
            })
        }
    }

    return {
        state,
        setState,
        onUploadFromUrl,
    }
}

export default FilesAddMethod
