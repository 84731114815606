import React from 'react'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import Button from 'blocks.simple/button/button'
import ItemAdvertisingCampaigns from '../../__item/_advertisingCampaigns/catalog__item_advertisingCampaigns'
import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'
import translate from 'core/translate'
import styles from './catalog__template_advertisingCampaigns.jcss'
import { routes } from 'features/routes'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { changeLocation } from 'features/routes'

export default {
    getMethod: 'getAdvertisingCampaigns',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noAdv'),
    emptyIcon: 'not_allow',
    addItemButton: () => (
        <Button rounded="full_md" onClick={() => changeLocation(`/${routes.addAdvertisingCampaign.path}`)}>
            {translate('add')}
        </Button>
    ),
    settingsPrefixes: 'advertisingCampaign',
    tile: ItemAdvertisingCampaigns,
    dateFilterOptions: () => [
        { id: 'completed', name: translate('completed') },
        { id: 'current', name: translate('current') },
        { id: 'upcoming', name: translate('upcoming') },
    ],
    viewOptions: () => [
        {
            id: 'tile',
            name: translate('tile'),
            icon: 'tile',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
        {
            id: 'list',
            name: translate('list'),
            icon: 'list',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
    ],
    sortOptions: () => [
        { id: '-createdAt', name: translate('sortCreatedAt') },
        { id: 'title', name: translate('byNameAsc') },
    ],
    getItemModel: (dataItem, getSelected) => {
        const item = { ...dataItem }
        item.cover = helpers.getBroadcastCover(item.coverSource)

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    getBackendModel: (item) => {
        return api.send('getAdvertisingCampaign', { id: item.id }, { hideLoader: true })
    },
    isEqual: (firstItem, secondItem) => firstItem.id === secondItem.id,
    onCreateListeners: [(cb, clearList) => api.addObserver('advertisingCampaignCreated', cb, clearList)],
    onUpdateListeners: [(cb, clearList) => api.addObserver('advertisingCampaignUpdated', cb, clearList)],
    onDeleteListeners: [(cb, clearList) => api.addObserver('advertisingCampaignDeleted', cb, clearList)],
    fields: () => [
        { id: 'cover', name: translate('advertisingCampaign') },
        { id: 'title', name: translate('name') },
        { id: 'createdAt', name: translate('createdAt') },
    ],
    init(callback) {
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        cover: ({ cover }) => {
            return (
                <div className={styles.image}>
                    <Rectangle width="2" height="1" src={cover.src} contain={cover.isIcon} />
                </div>
            )
        },
        createdAt: ({ createdAt }) => helpers.getFormattedLocalDate(createdAt, 'DD/MM/YYYY'),
    },
}
