import { addStyles } from 'ethcss'
import { colors, grid, IStyles, item } from 'theme'
import { indents } from 'blocks.app/config'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { circleButtonStyles } from 'atoms/CircleButton/CircleButton-styles'
import { mediaCSS, textSizes } from 'theme/media'

const devicesWidth = 100 / 3

const advertisingCampaignsAdvBlockStyles: IStyles = {
    wrapper: {
        ...grid.space,
        ...grid.row,
        ...item.white,
    },
    devices: {
        width: `${devicesWidth}%`,
        borderRight: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles['border']})`,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    content: {
        ...grid.col,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
        width: `${100 - devicesWidth}%`,
    },
    title: {
        ...grid.col,
        ...grid.center,
        fontSize: textSizes.normal,
        marginBottom: 24,
    },
    device: {
        ...grid.row,
        ...grid.noWrap,
        ...grid.normalCenter,
        ...grid.justify,
        ...grid.spaceWidth,
        textTransform: 'uppercase',
        padding: '4px 0',
    },
    deviceTitle: {
        ...grid.pl_micro,
        ...grid.pr_micro,
        ...item.ellipsis,
    },
    displayIconWrapper: {
        ...item.rel,
        marginTop: -indents.micro,
    },
    deviceWrapper: {
        borderBottom: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles['border']})`,
    },
    deviceWrapperOver() {
        return {
            ...item.blueWhite,
            [`& .${this.circleButton}`]: {
                ...circleButtonStyles.circleButtonActive,
            },
        }
    },
    circleButton: {
        ...circleButtonStyles.circleButton,
    },
    collapseContent: {
        ...grid.pt_micro,
        ...grid.pb_micro,
    },
    contentTitle: {
        ...grid.row,
        ...grid.normalCenter,
        fontSize: textSizes.mini,
        paddingTop: '10px',
        paddingBottom: '10px',
        borderTop: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles['border']})`,
    },
    contentInner: {
        ...grid.space,
        ...grid.row,
        ...item.overNone,
    },
    titleBlock: {
        marginBottom: 24,
    },
    inputWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 8,
    },
    relativeWrapper: {
        position: 'relative',
    },
    intervalFormat: {
        display: 'flex',
        position: 'absolute',
        right: 36,
        bottom: 12,
    },
    intervalFormatOption: {
        marginLeft: 10,

        '&:hover': {
            cursor: 'pointer',
        },
    },
    intervalFormatOptionSelected: {
        fontWeight: 'bold',
    },
    inputBlock: {},
    iconClassName: {
        backgroundColor: colors.white,
    },
    eventBlock: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: 90,
        fontSize: 12,
        color: colors.grey,
        borderTop: `1px solid ${colors.grey}`,
    },
    eventIcon: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: 100,
        height: '100%',
        borderLeft: '20px solid',
        paddingLeft: 10,
    },
    eventIconImageBorder: {
        position: 'absolute',
        top: 0,
        left: -20,
        width: 20,
        height: '100%',
    },
    withBorder: {
        borderLeft: `20px solid ${colors.grey}`,
        padding: 4,
    },
    eventContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '10px 0',
        height: '100%',
    },
    eventContentName: {
        fontSize: 16,
        fontWeight: 600,
        color: colors.blackLight,
    },
    eventAdvRow: {
        span: {
            color: colors.blackLight,
        },
    },
    eventContentDuration: {
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translateY(-50%)',
        fontSize: 16,
        fontWeight: 600,
        color: colors.blackLight,
    },
    generateGridBtn: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 16,
    },
    disabled: {
        opacity: 0.5,
        pointerEvents: 'none',
    },
    broadcastWrapper: {
        marginBottom: 20,
        borderBottom: `1px solid ${colors.grey}`,
    },
    timeLine: {
        marginBottom: 22,
        padding: '0 10px',
    },
    timeLineInner: {
        position: 'relative',
        display: 'flex',
        height: 40,
        width: '100%',
    },
    broadcastsInner: {
        display: 'flex',
        overflow: 'hidden',
        width: '100%',
    },
    timeLineBroadcast: {
        backgroundColor: '#D9D9D9',
        borderRadius: 5,
        marginRight: 1,
    },
    timeLineAdvertisement: {
        borderRadius: 4,
        position: 'absolute',
        height: '100%',
        backgroundColor: '#858585',

        '&:hover': {
            cursor: 'pointer',

            '>div': {
                opacity: '1 !important',
            },
        },
    },
    timeLineBlockPupUp: {
        display: 'flex',
        alignItems: 'center',
        color: colors.white,
        fontSize: 10,
        position: 'absolute',
        left: '50%',
        bottom: -46,
        transform: 'translateX(-50%)',
        padding: 5,
        borderRadius: 2,
        backgroundColor: '#282828',
        zIndex: 1,
        opacity: 0,
        transition: 'opacity 0.3s',
        pointerEvents: 'none',
    },
    pupUpIcon: {
        paddingRight: 5,
    },
    pupUpText: {
        whiteSpace: 'nowrap',
        lineHeight: 1.3,
    },
    pupUpTriangle: {
        position: 'absolute',
        top: -20,
        left: '50%',
        transform: 'translateX(-50%)',
        border: '10px solid transparent; border-bottom: 10px solid #282828',
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    loadingText: {
        marginTop: 60,
        marginBottom: 24,
    },
    input: {
        height: '30px !important',
    },
    timeLineHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 12,
        lineHeight: 1,
        marginBottom: 8,
    },
    insertionTypeInfo: {
        span: {
            fontWeight: 600,
        },
    },
    legend: {
        display: 'flex',
        fontWeight: 600,
    },
    legendBlock: {
        display: 'flex',
        marginLeft: 14,
    },
    legendColor: {
        width: 12,
        height: 12,
        borderRadius: '50%',
        marginRight: 4,
    },
    legendBroadcastColor: {
        backgroundColor: colors.grey,
    },
    legendAdvertisementColor: {
        backgroundColor: colors.blackLight,
    },
    noBroadcasts: {
        marginTop: 60,
        textAlign: 'center',
    },
    lineScale: {
        position: 'relative',
        borderBottom: `1px solid ${colors.grey}`,
        borderRight: `1px solid ${colors.grey}`,
        height: 10,
        marginTop: 7,
    },
    bigLine: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        position: 'absolute',
        borderLeft: `1px solid ${colors.grey}`,
        height: 9,
        bottom: 0,
    },
    smallLine: {
        borderLeft: `1px solid ${colors.grey}`,
        height: 6,
    },
    noBorder: {
        border: 'none !important',
    },
    timeValue: {
        position: 'absolute',
        fontSize: 10,
        color: colors.grey,
        bottom: -12,
    },
    startTimeValue: {
        left: 0,
    },
    endTimeValue: {
        right: 0,
    },
    timeCommon: {
        right: -12,
    },
}

addStyles(advertisingCampaignsAdvBlockStyles)
export default advertisingCampaignsAdvBlockStyles
