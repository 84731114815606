import React from 'react'
import styles from './styles.module.scss'
import Icon from '../../../../../blocks.simple/icon/icon'
import { defaultNoDevicesTheme } from './defaultTheme'
import { getThemeIconValue, getThemeStyleValue } from '../../../../../theme/colors'
import translate from 'core/translate'

export const NoDevices = () => {
    return (
        <div className={styles.noDevices}>
            <div
                className={styles.caption}
                style={{ color: getThemeStyleValue('step', 'noDevices', 'dark') || defaultNoDevicesTheme.dark }}
            >
                {translate('youDontHaveDevicesYet')}
            </div>
            <div
                className={styles.subcaption}
                style={{ color: getThemeStyleValue('step', 'noDevices', 'light') || defaultNoDevicesTheme.light }}
            >
                {translate('forAddDevice')}
            </div>
            <div className={styles.steps}>
                <div className={styles.step}>
                    <div className={styles.stepImgWrapper}>
                        <img src={getThemeIconValue('step', 'noDevices', 'step-1') || defaultNoDevicesTheme.step1} />
                    </div>
                    <div
                        className={styles.description}
                        style={{
                            color: getThemeStyleValue('step', 'noDevices', 'light') || defaultNoDevicesTheme.light,
                        }}
                    >
                        <span className={styles.number}>1</span>
                        {translate('goToDistributions')}
                    </div>
                </div>
                <div className={styles.step}>
                    <div className={styles.stepImgWrapper}>
                        <span className={styles.instruction}>{translate('instructions')}</span>
                        <Icon
                            className={styles.arrow}
                            type="noDeviceArrow"
                            size={29}
                            color={getThemeStyleValue('step', 'noDevices', 'dark') || defaultNoDevicesTheme.dark}
                        />
                        <img src={getThemeIconValue('step', 'noDevices', 'step-2') || defaultNoDevicesTheme.step2} />
                    </div>
                    <div
                        className={styles.description}
                        style={{
                            color: getThemeStyleValue('step', 'noDevices', 'light') || defaultNoDevicesTheme.light,
                        }}
                    >
                        <span className={styles.number}>2</span>
                        {translate('chooseAndDownload')}
                    </div>
                </div>
                <div className={styles.step}>
                    <div className={styles.stepImgWrapper}>
                        <img src={getThemeIconValue('step', 'noDevices', 'step-3') || defaultNoDevicesTheme.step3} />
                    </div>
                    <div
                        className={styles.description}
                        style={{
                            color: getThemeStyleValue('step', 'noDevices', 'light') || defaultNoDevicesTheme.light,
                        }}
                    >
                        <span className={styles.number}>3</span>
                        {translate('enterScreenCode')}{' '}
                        <span
                            style={{
                                color: getThemeStyleValue('step', 'noDevices', 'dark') || defaultNoDevicesTheme.dark,
                            }}
                        >
                            “{translate('addDevice')}”
                        </span>{' '}
                        {translate('orUseQR')}
                    </div>
                </div>
            </div>
        </div>
    )
}
