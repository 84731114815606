import React, { useEffect, useState } from 'react'
//import styles from './lensOfTime_viewModules.jcss'
import Dropdown from 'blocks.simple/dropdown/dropdown'

export const DropdownSelect = ({ id, value, options, onChange, callbackValue }) => {
    const [selectedValue, setSelectedValue] = useState(value)

    useEffect(() => {
        if (!value) return

        if (callbackValue) {
            callbackValue(value)
        }
    }, [])

    return (
        <Dropdown
            value={selectedValue}
            options={options}
            onChange={(selected) => {
                setSelectedValue(selected.id)
                onChange(selected)
            }}
            indentSize={'mini'}
        />
    )
}
