import { addStyles } from 'ethcss'
import { IStyles, mediaCSS } from '../../../theme'

const broadcastsAddAdvancedStyles: IStyles = {
    wrapper: {
        [mediaCSS.tab]: {
            paddingTop: 44,
        },
    },
}

addStyles(broadcastsAddAdvancedStyles)
export default broadcastsAddAdvancedStyles
