import React from 'react'
import PropTypes from 'prop-types'
import DndDroppable from 'blocks.app/dnd/_droppable/dnd_droppable'

import { cn } from 'ethcss'
import styles from './catalog__tiles.jcss'
import grid from 'blocks.simple/grid/grid.jcss'

class TileInner extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            isHovered: false,
        }
    }
    getCols({ template, minimize }) {
        if (template.cols) {
            return minimize ? template.cols[1] : template.cols[0]
        } else {
            return minimize ? 7 : 6
        }
    }
    onDrop = (data, e) => {
        e.stopPropagation()

        const p_ = this.props
        const droppedItem = JSON.parse(data.dnd_item)

        if (p_.template.multipleMove && p_.multipleSelect && p_.selectedItems.length) {
            const droppedItemInSelected = p_.selectedItems.filter((selectedItem) =>
                p_.template.isEqual(selectedItem, droppedItem)
            )

            if (droppedItemInSelected.length) {
                this.onMoveMultiple()
                return
            }
        }

        if (p_.template.isEqual(droppedItem, p_.listItem)) {
            this.setState({ isHovered: false })
            return
        }

        const moveMethod = p_.checkIsFolder(droppedItem) ? p_.template.moveFolder : p_.template.moveItem

        moveMethod(droppedItem.id, p_.listItem.id)
        this.setState({ isHovered: false })
    }
    onMoveMultiple = () => {
        const p_ = this.props
        const sourceId = []
        const folderId = []

        p_.selectedItems.forEach((item) => {
            if (!p_.template.isEqual(item, p_.listItem)) {
                p_.checkIsFolder(item) ? folderId.push(item.id) : sourceId.push(item.id)
            }
        })

        p_.template.multipleMove(sourceId, folderId, p_.listItem.id)
        this.setState({ isHovered: false })
    }

    onDragOver = () => {
        const s_ = this.state

        if (!s_.isHovered) {
            this.setState({ isHovered: true })
        }
    }
    onDragLeave = () => {
        const s_ = this.state

        if (s_.isHovered) {
            this.setState({ isHovered: false })
        }
    }
    getCellClassName({ template }) {
        if (template.cellClassName) {
            return template.cellClassName
        } else {
            return styles.catalogTilesCellWrapper
        }
    }
    render() {
        const p_ = this.props
        const s_ = this.state
        const Tile = p_.template.tile
        const deviceBlockStyle = p_.deviceBlockStyle

        return (
            <div
                className={cn(
                    this.getCellClassName(p_),
                    grid[`col${p_.cols || this.getCols(p_)}`],
                    p_.template.mediaCellClassName || styles.mediaCellClassName
                )}
            >
                <DndDroppable
                    dropEnabled={p_.isFolder}
                    onDrop={this.onDrop}
                    onDragOver={this.onDragOver}
                    onDragLeave={this.onDragLeave}
                >
                    <Tile
                        active={(p_.selectedInfo ? p_.isEqual(p_.selectedInfo, p_.listItem) : false) || s_.isHovered}
                        onSelectInfo={function () {
                            p_.onSelectInfo(p_.index)
                        }}
                        onSelect={p_.onSelect}
                        onSelectFolder={p_.onSelectFolder}
                        onDoubleClick={p_.onDoubleClick}
                        onDoubleClickObject={p_.onDoubleClickObject}
                        multiSelect={p_.multiSelect}
                        item={p_.listItem}
                        onSelectProgress={function (e) {
                            e.stopPropagation()
                            p_.onSelectProgress(p_.index)
                        }}
                        drag={p_.drag}
                        disableMultiSelect={p_.disableMultiSelect(p_.listItem)}
                        onDragStart={p_.onDragStart}
                        onDragEnd={p_.onDragEnd}
                        getDragData={p_.getDragData}
                        isMobileDragEnabled={p_.isMobileDragEnabled}
                        deviceBlockStyle={deviceBlockStyle}
                    />
                </DndDroppable>
            </div>
        )
    }
}

const CatalogTiles = (p_) => {
    const getWrapperClassName = () => {
        if (p_.template.wrapperClassName) {
            return p_.template.wrapperClassName
        }

        return styles.catalogTilesWrapper
    }

    const getPanelClassName = () => {
        if (p_.template.panelClassName) {
            return p_.template.panelClassName
        }

        return null
    }

    return (
        <div className={getPanelClassName()}>
            <div className={getWrapperClassName()}>
                {p_.list.map((listItem, index) => {
                    const isFolder = p_.isFolder(listItem)
                    const folderKey = isFolder ? 'folder' : 'item'
                    const props = {
                        ...p_,
                        isFolder,
                        checkIsFolder: p_.isFolder,
                        folderKey,
                        listItem,
                        index,
                    }

                    return <TileInner key={`catalog__item_${folderKey}_${listItem.id}_${index}`} {...props} />
                })}
            </div>
        </div>
    )
}

CatalogTiles.propTypes = {
    list: PropTypes.array,
    selectedInfo: PropTypes.object,
    onSelectInfo: PropTypes.func,
    onSelectFolder: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onDoubleClickObject: PropTypes.func,
    onSelect: PropTypes.func,
    isEqual: PropTypes.func,
    isFolder: PropTypes.func,
    onSelectProgress: PropTypes.func,
    disableMultiSelect: PropTypes.func,
    disableFolderSelection: PropTypes.bool,
    type: PropTypes.string,
    multiSelect: PropTypes.bool,
    drag: PropTypes.bool,
    minimize: PropTypes.bool,
    cols: PropTypes.number,
    template: PropTypes.object,
    deviceBlockStyle: PropTypes.string,
}

export default CatalogTiles
