import { addStyles } from 'ethcss'
import { mediaCSS } from 'blocks.app/config'
import grid from 'blocks.simple/grid/grid.jcss'
import { IStyles } from '../../../theme'

const deviceSettingsProxyStyles: IStyles = {
    blockGap: {
        height: '80px',
        [mediaCSS.tabMini]: {
            display: 'none',
        },
    },
    loginWrapper: {
        ...grid.col,
        ...grid.w25,
        [mediaCSS.tab]: {
            ...grid.w45,
        },
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    connectWrapper: {
        ...grid.col,
        ...grid.w25,
        ...grid.ml_mdPlus,
        [mediaCSS.tab]: {
            ...grid.w45,
        },
        [mediaCSS.tabMini]: {
            ...grid.w100,
            ...grid.ml_none,
            ...grid.mt_md,
        },
    },
    radioMargin: {
        marginRight: '30px',
    },
    radioGap: {
        marginBottom: '14px',
    },
}

addStyles(deviceSettingsProxyStyles)
export default deviceSettingsProxyStyles
