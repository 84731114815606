import React from 'react';
import Icon from 'blocks.simple/icon/icon';
import styles from 'pages/ui/ui.jcss';

class UiTooltipIcon extends React.Component {
    render() {
        return (
            <div className={styles.blockPanel}>
                <div className={styles.title}>
                    Иконка с подсказкой (tooltip)
                </div>
                <div className={styles.content}>
                    <Icon
                        type={'pc'}
                        size={30}
                        tooltip={{
                            title: 'personal computer'
                        }}
                    />
                </div>
            </div>
        )
    }
}

export default UiTooltipIcon;