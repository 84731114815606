import { createHashHistory, History } from 'history'
import { IFilter } from '../routes-types'
import { getURLSearchParamsByLocation, getURLSearchParamsString } from '../utils'

export const history: History = createHashHistory()

history.listen((location) => {
    // TODO: use for debug
    // console.log('changeLocation', location)
})

export const changeLocation = (location: any) => {
    history.push(location)
}

export const changeNewFilter = (filter: IFilter) => {
    const location = { ...history.location }
    const query: any = getURLSearchParamsByLocation(location)

    for (let key in query) {
        if (query.hasOwnProperty(key) && key.indexOf('displays__') !== -1 && key !== 'displays__query') {
            delete query[key]
        }
    }
    for (let key in filter) {
        if (filter.hasOwnProperty(key) && filter[key] !== '') {
            query[key] = filter[key]
        }
    }

    const search = getURLSearchParamsString({
        ...query,
    })

    changeLocation({
        ...location,
        search,
    })
}

export const changeFilter = (filter: IFilter) => {
    const location = { ...history.location }
    const query = getURLSearchParamsByLocation(location)

    const updatedFilter: IFilter = Array.from(Object.keys(filter)).reduce((result, key) => {
        if (filter.hasOwnProperty(key) && !filter[key]) {
            return {
                ...result,
                [key]: null,
            }
        }

        return { ...result, [key]: filter[key] }
    }, {})

    const search = getURLSearchParamsString({
        ...query,
        ...updatedFilter,
    })

    changeLocation({
        ...location,
        search,
    })
}
