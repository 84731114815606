import { IRecordWithStrings } from 'core/helpers'
import { colors } from 'theme'

export const defaultThemeStyles = {
    textMain: colors.grey,
    textSub: colors.black,
    hover: colors.black,
    disable: colors.grey,
    active: colors.blackLight,
    background: colors.greyLight,
    border: colors.greyLight,
    shadow: '0px 3px 5px 2px rgba(0, 0, 0, 0.25)',
    arrow: colors.grey,
}

export const defaultThemeIcons: IRecordWithStrings = {
    videoChat: 'videoChat',
    dashboard: 'dashboard',
    devices: 'display',
    scoreboards: 'scoreboard',
    broadcasts: 'megaphone',
    advertisingCampaigns: 'megaphone',
    adBlocks: 'adBlocks',
    schedule: 'date',
    advertisers: 'group',
    files: 'folder',
    reports: 'statistics',
    settings: 'gear',
    logout: 'logout',
    users: 'users',
    timetable: 'timetable',
    instancesCatalog: 'instancesCatalog',
    addContentToDevice: 'multimedia',
    usersActionsHistory: 'history_icon',
    notificationCenter: 'notifications_empty',
    documentation: 'external_link',
    globalProgress: 'download',
    distributive: 'refresh',
    tags: 'hash',
}
