import React, { useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import RangeVolume from 'blocks.simple/range/_volume/range_volume'
import Icon from 'blocks.simple/icon/icon'
import Range from 'blocks.simple/range/range'
import moment from 'moment/moment'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './preview__media.jcss'
import grid from 'blocks.simple/grid/grid.jcss'

interface IPreviewMediaProps {
    item?: {
        duration?: string
        src: string
        fileType: string
        metadata?: any
        id: number
        name: string
        folderId: number
        data: string
        isDirectory: number
        type: string
        displayAs: string
        thumbnail: string
        style: object
    }
    downloadLink?: string
    url?: string
    onClose?: () => void
}

export const PreviewMediaMessage = (p_: {
    className?: object
    src: string
    message?: string
    error: boolean
    style?: object
    icon?: {
        color?: string
        size: number
    }
}) => {
    if (!p_.error) {
        return null
    }

    const message = {
        text: 'errorLoadingMediaFile',
        icon: 'attention',
        color: 'error',
        size: 80,
        ...p_.icon,
    }

    if (!ReactPlayer.canPlay(p_.src)) {
        message.text = 'thisFileFormatNotBrowserSupported'
    }

    return (
        <div className={cn(styles.icons, p_.className)} style={p_.style}>
            <Icon type={message.icon} color={message.color} size={message.size} />
            <div className={styles.error}>{translate(message.text)}</div>
        </div>
    )
}

const PreviewMedia = (p_: IPreviewMediaProps) => {
    const [state, setState] = useState({
        active: false,
        played: 0,
        volume: 75,
        showPlayer: true,
        error: false,
        ready: false,
    })
    const player = useRef<any>(null)

    const onSeekChange = (value: number) => {
        setState((prevState: any) => {
            return { ...prevState, played: value }
        })
        player.current.seekTo(value)
    }

    return (
        <div className={cn(grid.colCenter, grid.center, grid.full)}>
            {p_.item && p_.item.fileType === 'audio' && (
                <Icon containerClass={cn(styles.icons)} type="audio" color="blackLight" size={200} />
            )}
            <PreviewMediaMessage error={state.error} src={p_.item!.src} />
            {state.showPlayer && (
                <ReactPlayer
                    ref={player}
                    width="100%"
                    height="100%"
                    hidden={false}
                    url={p_.item!.src}
                    playing={state.active}
                    className={styles.player}
                    onEnded={() =>
                        setState((prevState: any) => {
                            return { ...prevState, active: false, played: 0 }
                        })
                    }
                    onProgress={(e) => {
                        if (e.played < 1)
                            setState((prevState: any) => {
                                return { ...prevState, played: e.played }
                            })
                    }}
                    onReady={() =>
                        setState((prevState: any) => {
                            return { ...prevState, ready: true }
                        })
                    }
                    onError={() =>
                        setState((prevState: any) => {
                            return { ...prevState, error: true }
                        })
                    }
                    progressInterval={250}
                    volume={state.volume / 100}
                />
            )}
            <div className={styles.controls}>
                <div>
                    <Range
                        min={0}
                        max={1}
                        step={0.001}
                        className={styles.progress}
                        value={state.played}
                        mod="white"
                        handle={false}
                        disabled={!state.ready}
                        onChange={onSeekChange}
                    />
                </div>
                <div className={cn(grid.row, grid.justify)}>
                    <div className={cn(grid.row, grid.normalCenter, grid.pr_mini, grid.pl_mini)}>
                        <Icon
                            disabled={!state.ready}
                            type={state.active ? 'pause' : 'play'}
                            color="white"
                            size={18}
                            onClick={() =>
                                setState((prevState: any) => {
                                    return { ...prevState, active: !state.active }
                                })
                            }
                        />
                        <div className={styles.rangeVolume}>
                            <RangeVolume
                                volume={state.volume}
                                mod="white"
                                // handle={false}
                                handle={true}
                                onChange={(volume: number) =>
                                    setState((prevState: any) => {
                                        return { ...prevState, volume }
                                    })
                                }
                            />
                        </div>
                        {p_.item && p_.item.duration && (
                            <div className={styles.timeLine}>
                                {moment()
                                    .startOf('day')
                                    .seconds(+p_.item!.duration * state.played)
                                    .format('HH:mm:ss')}{' '}
                                / {moment().startOf('day').seconds(parseFloat(p_.item!.duration)).format('HH:mm:ss')}
                            </div>
                        )}
                    </div>
                    <div />
                    <div className={cn(grid.row, grid.normalCenter, grid.pr_mini)}>
                        {p_.downloadLink && (
                            <a href={p_.downloadLink}>
                                <Icon type="download_arrow" color="white" size={18} />
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreviewMedia
