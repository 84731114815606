import { addStyles } from 'ethcss'
import { IStyles } from 'theme'
import { defaultThemeStyles } from './Typography-theme'

export const typographyStyles: IStyles = {
    typography: {},
    typography_type_title: {
        color: `var(--var-module-ui-component-typography-style-textMain, ${defaultThemeStyles.textMain})`,
    },
    typography_type_promo: {
        color: `var(--var-module-ui-component-typography-style-textPromo, ${defaultThemeStyles.textPromo})`,
    },
    typography_type_text: {
        color: `var(--var-module-ui-component-typography-style-textSub, ${defaultThemeStyles.textSub})`,
    },
    typography_type_error: {
        color: `var(--var-module-ui-component-typography-style-textError, ${defaultThemeStyles.textError})`,
    },
    typography_type_ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
}

addStyles(typographyStyles)
