import React from 'react'
import Sidebar from 'blocks.app/sidebar/sidebar'
import Icon from 'blocks.simple/icon/icon'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import { text as textStyles } from 'theme'
import { grid } from 'theme'
import { api } from 'core/api/ConnectionManager'
import { cn } from 'ethcss'
import { draftDevicesActions } from '../draftDevice.state'
import { useDispatch } from 'react-redux'

interface IDraftDevicesToolbarProps {
    selectedInfo: {
        id: number
        name: string
        status: string
    } | null
    selectedItems: { id: number }[]
    onChange: (selectedItems: { id: number }[]) => void
}

const DraftDevicesToolbar = (p_: IDraftDevicesToolbarProps) => {
    const dispatch = useDispatch()
    const openInformationModal = () => {
        const options: any = {
            modalState: 'information',
        }
        dispatch(draftDevicesActions.openModal(options))
    }

    const registerDevices = () => {
        const { selectedItems, onChange } = p_

        api.send('registerDraftDevice', {
            id: selectedItems.map((selectedItem) => selectedItem.id),
        }).then(() => {
            onChange([])
        })
    }

    const registerDevice = () => {
        const { selectedInfo } = p_

        if (selectedInfo) {
            api.send('registerDraftDevice', { id: selectedInfo.id })
        }
    }

    const color: { [index: string]: string } = { offline: 'offline', error: 'error', online: 'online' }

    return (
        <Sidebar
            selectedSide={'right'}
            onChange={(list: any) => p_.onChange(list)}
            customContent={{
                right:
                    p_.selectedItems.length === 0 && p_.selectedInfo ? (
                        <div className={cn(grid.full, grid.colCenter)}>
                            <div className={cn(textStyles.up, grid.pb_md)}>{p_.selectedInfo.name}</div>
                            <Icon
                                type={'close'}
                                size={'75%'}
                                color={color[p_.selectedInfo.status]}
                                containerClass={cn(grid.colCenter)}
                            />
                            <div className={grid.mt_md}>
                                <Button onClick={openInformationModal}>{translate('information')}</Button>
                            </div>
                            <div>
                                <Button onClick={registerDevice}>{translate('register')}</Button>
                            </div>
                        </div>
                    ) : (
                        <div />
                    ),
            }}
            content={{
                right:
                    !p_.selectedInfo && p_.selectedItems.length
                        ? {
                              title: translate('selectedDevices'),
                              options: p_.selectedItems,
                          }
                        : null,
            }}
            bottomContent={{
                right: (
                    <div className={cn(grid.full, grid.rowCenter)}>
                        <Button onClick={registerDevices}>{translate('register')}</Button>
                    </div>
                ),
            }}
            isEmpty={{
                right: !p_.selectedInfo && p_.selectedItems.length === 0,
            }}
            emptyText={{
                right: {
                    title: (
                        <div className={cn(textStyles.normalPlus, textStyles.up)}>{translate('noDisplayInfo')} </div>
                    ),
                    description: translate('selectDraftDeviceDescription'),
                },
            }}
        />
    )
}

export default DraftDevicesToolbar
