import React from 'react'
import EditText from 'molecules/EditText'
import translate from 'core/translate'
import Dropdown from 'molecules/Dropdown'
import { Icon } from 'molecules/Icon'

import PropTypes from 'prop-types'
import { cn } from 'ethcss'
import styles from '../styles'
import { grid, item } from 'theme'
import { Button } from 'atoms/Button'

const AdUser = (p_) => {
    const isAdUser = p_.data.adUser && !p_.data.adUser.error
    const isButtonDisabled = !p_.data.email

    const getStatusOptionsByAdUser = () => {
        if (p_.data.adUser && p_.data.adUser.error) {
            return {
                type: 'error',
                isUseIcon: true,
                message: p_.data.adUser.message,
            }
        }

        if (p_.data.adUser && !p_.data.adUser.error) {
            return {
                type: 'success',
                isUseIcon: true,
            }
        }

        return {}
    }

    return (
        <React.Fragment>
            {!p_.isEdit && (
                <div className={cn({ [item.disableWrapper]: isAdUser })}>
                    <EditText
                        value={p_.data.email}
                        onChange={(email) => p_.onChange(email, 'email')}
                        label={translate('adLogin')}
                        wrapperClassName={cn(styles.profileFormInput, grid.mt_md)}
                        placeholder={''}
                        backgroundColor={'water'}
                        statusOptions={getStatusOptionsByAdUser()}
                    />
                    <div className={cn(styles.profileFormInput, grid.mt_md)}>
                        <Button onClick={p_.findAdUser} className={grid.w50} disabled={isButtonDisabled}>
                            {translate('find')}
                        </Button>
                    </div>
                </div>
            )}
            <div className={cn({ [item.disableWrapper]: !isAdUser })}>
                <EditText
                    value={p_.data.name}
                    label={translate('userName')}
                    wrapperClassName={cn(styles.profileFormInput, grid.mt_md)}
                    onChange={(name) => p_.onChange(name, 'name')}
                />
                {p_.data.roles.map((role, idx) => {
                    const roleValue = role || null

                    return (
                        <div key={`profile_form_role_dropdown_${idx}`} className={styles.profileFormDropdownWrapper}>
                            <Dropdown
                                value={roleValue}
                                label={`${translate('role')} ${idx + 1}`}
                                options={p_.roles}
                                disabled={p_.owner}
                                onChange={(selected) => p_.onChangeDropdown(selected, idx)}
                                wrapperClassName={styles.profileFormInput}
                                menuPortalTarget={document.getElementById('profile_dialog')}
                            />
                            {idx > 0 && (
                                <Icon
                                    type="trash"
                                    onClick={() => p_.onDeleteRole(idx)}
                                    className={styles.profileFormDeleteIcon}
                                />
                            )}
                        </div>
                    )
                })}
                {!p_.owner && (
                    <div className={styles.profileFormLink} onClick={p_.onAddNewRole}>
                        <Icon type="plus" className={styles.profileFormLinkIcon} size={24} /> {translate('addRole')}
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

AdUser.propTypes = {
    data: PropTypes.object,
    owner: PropTypes.bool,
    isEdit: PropTypes.bool,
    onChange: PropTypes.func,
    findAdUser: PropTypes.func.isRequired,
}

export default AdUser
