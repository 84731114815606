import React from 'react'
import EditText from 'blocks.simple/editText/editText'
import item from 'blocks.simple/item/item.jcss'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import translate from 'core/translate'
import template from 'es6-template-strings'

const TextEditorDynamic = (p_: {
    small: boolean
    localPath: string
    type: string
    node: string
    column: number
    row: number
    onChange: (data: any) => void
}) => {
    return (
        <div className={cn(grid.row, grid.p_md)}>
            <div className={cn(p_.small ? grid.w50 : grid.w40)}>
                <div className={cn(item.ellipsisPure, grid.mb_micro)}>
                    {template(translate('localPath'), { type: p_.type })}
                </div>
                <EditText
                    placeholder={`D:\\example\\file.${p_.type}`}
                    indentSize={'mini'}
                    value={p_.localPath}
                    onChange={function (localPath: string) {
                        p_.onChange({ localPath })
                    }}
                />
            </div>
            {p_.type === 'xml' && (
                <div className={cn(p_.small ? grid.w40 : grid.w25, grid.ml_md)}>
                    <div className={cn(item.ellipsisPure, grid.mb_micro)}>{translate('node')}</div>
                    <EditText
                        indentSize={'mini'}
                        value={p_.node}
                        onChange={function (node: string) {
                            const data = {
                                node,
                                content: node,
                            }

                            p_.onChange(data)
                        }}
                    />
                </div>
            )}
            {p_.type === 'csv' && (
                <div className={cn(p_.small ? grid.w40 : grid.w50, grid.ml_md, grid.row)}>
                    <div className={p_.small ? grid.mb_md : grid.mr_md}>
                        <div className={cn(item.ellipsisPure, grid.mb_micro)}>{translate('column')}</div>
                        <EditText
                            indentSize={'mini'}
                            type={'number'}
                            value={p_.column}
                            onChange={function (column: number) {
                                const data = {
                                    column,
                                }
                                p_.onChange(data)
                            }}
                        />
                    </div>
                    <div>
                        <div className={cn(item.ellipsisPure, grid.mb_micro)}>{translate('row')}</div>
                        <EditText
                            indentSize={'mini'}
                            type={'number'}
                            value={p_.row}
                            onChange={function (row: number) {
                                const data = {
                                    row,
                                }
                                p_.onChange(data)
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default TextEditorDynamic
