import React from 'react'
import Content from 'blocks.app/content/content'
import Catalog from 'blocks.app/catalog/catalog'
import { api } from 'core/api/ConnectionManager'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

class InstancesCatalogMenuProduct extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            selectedInfo: null,
            selectedItems: [],
            productMenus: [],
        }
    }

    componentDidMount() {
        api.send('getProductMenus', {}).then((res) => this.setState({ productMenus: res.data }))
    }

    render() {
        const { productMenus, selectedItems } = this.state

        return (
            <Content title={'menuProduct'}>
                <Catalog
                    type={'menuProduct'}
                    onSelect={(selectedItems) => this.setState({ selectedItems })}
                    selectedItems={selectedItems}
                    additionalData={{
                        productMenus,
                    }}
                    fixedHeader={true}
                    isExistRightToolbar={false}
                />
            </Content>
        )
    }
}

const exportInstancesCatalogMenuProduct = checkAvailableModule('instancesCatalog/menuProduct')
    ? InstancesCatalogMenuProduct
    : null

export default exportInstancesCatalogMenuProduct
