import React, { useEffect, useState } from 'react'
import { MultiSelectFromListModal } from '../../../../molecules/MultiSelectFromListModal'
import helpers from 'core/helpers'
import styles from './styles.jcss'
import translate from 'core/translate'
import Button from 'blocks.simple/button/button'
import { api } from '../../../../core/api/ConnectionManager'
import { authorsListToShort } from '../../../../blocks.app/catalog/__template/_catalogizator/arts/catalog__template_arts.local.methods'
import { FileDetails } from '../FileDetails/FileDetails'

export const SourcesToAuthorsFromListModal = ({
    onSelect,
    placeholder,
    callbackList,
    sourceList,
    authorsInProject,
    authorsToSource,
    fakeLine,
    authorsList,
    id,
    selectedList,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [choiceAuthors, setChoiceAuthors] = useState(false)
    const [selectedSourceId, setSelectedSourceId] = useState(null)
    const [authors, setAuthors] = useState(authorsInProject ? authorsInProject : [])
    const [itemsList, setItemsList] = useState(sourceList ? sourceList : [])
    const [authorsToSourceList, setAuthorsToSourceList] = useState(authorsToSource)

    useEffect(() => {
        if (!authorsList) return

        setAuthors(authorsListToShort(authorsList))
    }, [authorsList])

    const renderHeader = () => {
        return <FileDetails sourceId={selectedSourceId} />
    }

    const renderSelectMenu = () => {
        return (
            <MultiSelectFromListModal
                initList={getAuthorsList('init')}
                selectedList={getAuthorsList('selected')}
                header={renderHeader}
                openStatus={choiceAuthors}
                onSelect={(selectedItems) => {
                    setSourceLinks(selectedItems)

                    onClose()
                }}
                onCancel={onClose}
            />
        )
    }

    const setSourceLinks = (selectedItems) => {
        const sortedSources = authorsToSourceList.filter((source) => source.sourceId !== selectedSourceId)

        setAuthorsToSourceList([
            ...sortedSources,
            ...selectedItems.map((id) => {
                return {
                    authorId: id,
                    sourceId: selectedSourceId,
                }
            }),
        ])
    }

    useEffect(() => {
        if (fakeLine) {
            if (fakeLine.media) {
                const item = fakeLine.media.id

                api.send('getSources', {
                    folderId: item.isDirectory ? fakeLine.media.id.id : fakeLine.media.id.folderId,
                }).then((res) => {
                    setItemsList(!item.isDirectory ? res.filter((media) => media.id === item.id) : res)
                })
            }

            if (fakeLine.authors) {
                api.send('getAuthorsNames', { limit: 10000 }).then((res) => {
                    const authorsShortList = callbackList(res)
                    const selectedAuthorsIds = fakeLine.authors
                    const sortedAuthors = authorsShortList.filter((author) => selectedAuthorsIds.includes(author.id))

                    setAuthors(sortedAuthors)
                })
            }
        }
    }, [fakeLine])

    const onClose = () => {
        setSelectedSourceId(null)
        setChoiceAuthors(false)
    }

    const linkIsExist = (author, authorsToSourceList, selectedSourceId) => {
        for (let j = 0; j < authorsToSourceList.length; j++) {
            const authorToSource = authorsToSourceList[j]

            if (authorToSource.sourceId === selectedSourceId) {
                if (authorToSource.authorId === author.id) {
                    return true
                }
            }
        }

        return false
    }

    const getAuthorsList = (type) => {
        let list = []

        for (let i = 0; i < authors.length; i++) {
            const author = authors[i]

            if (type === 'selected') {
                if (linkIsExist(author, authorsToSourceList, selectedSourceId)) {
                    list.push(author)
                }
            }
            if (type === 'init') {
                list.push(author)
            }
        }

        return list
    }

    const authorToSourceCheck = (list, id) => {
        let linkedSourceList = []

        list.forEach((listItem) => {
            if (listItem.sourceId === id) {
                linkedSourceList.push(listItem.authorId)
            }
        })

        return linkedSourceList.length ? (
            <span className={styles.linkedAuthors}>привязано авторов: {linkedSourceList.length}</span>
        ) : null
    }

    useEffect(() => {
        if (isOpen) {
            helpers.disableWindowScroll(true, 'SourcesToAuthorsFromListModal')
        } else {
            helpers.disableWindowScroll(false, 'SourcesToAuthorsFromListModal')
        }
    }, [isOpen])

    useEffect(() => {
        if (selectedSourceId) {
            setChoiceAuthors(true)
        }
    }, [selectedSourceId])

    return (
        <>
            {isOpen ? (
                <div className={styles.modalBackLayer}>
                    <div className={styles.modal}>
                        <div className={styles.modalContent}>
                            <ul className={styles.modalListWrapper}>
                                {itemsList
                                    ? itemsList.map((item) => {
                                          return (
                                              <li
                                                  key={item.id}
                                                  className={`
                                                    ${styles.modalListElement} 
                                                    ${item.selected ? styles.elementActive : null}
                                                `}
                                                  onClick={() => {
                                                      setSelectedSourceId(item.id)
                                                  }}
                                              >
                                                  {item.name.replace(/\.[^/.]+$/, '')}
                                                  {authorToSourceCheck(authorsToSourceList, item.id)}
                                              </li>
                                          )
                                      })
                                    : null}
                            </ul>
                        </div>
                        <div className={styles.nav}>
                            <Button
                                mod={'fill'}
                                indentSize={'mini'}
                                textSize={'mini'}
                                rounded={'full_normal'}
                                onClick={function () {
                                    setIsOpen(false)
                                }}
                            >
                                {translate('cancel')}
                            </Button>
                            <Button
                                mod={'fill'}
                                indentSize={'mini'}
                                textSize={'mini'}
                                rounded={'full_normal'}
                                onClick={function () {
                                    onSelect(authorsToSourceList.filter((item) => item.authorId))
                                    setIsOpen(false)
                                }}
                            >
                                {translate('save')}
                            </Button>
                        </div>
                    </div>
                </div>
            ) : (
                <div onClick={() => setIsOpen(true)}>
                    {authorsToSourceList.length
                        ? `${translate('linked')} (${authorsToSourceList.filter((item) => item.authorId).length})`
                        : placeholder}
                </div>
            )}
            {choiceAuthors ? renderSelectMenu() : null}
        </>
    )
}
