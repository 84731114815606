import { api } from 'core/api/ConnectionManager'
import { useStatus } from 'core/hooks'
import { IRootState } from 'core/store/rootReducer'
import translate from 'core/translate'
import { InformationWindow } from 'molecules/InformationWindow'
import { ScoreboardInfo } from 'pages/scoreboards/models'
import { IScoreboardsState, scoreboardsActions } from 'pages/scoreboards/scoreboards.state'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ControllerDataTable } from './ControllerDataTable'
import { styles } from './CurrentControllerData-styles'
import { CurrentControllerDataProps, FetchScoreboardInfoRequest } from './CurrentControllerData-types'

export const CurrentControllerData: FC<CurrentControllerDataProps> = ({}) => {
    const dispatch = useDispatch()
    const { modalData } = useSelector<IRootState, IScoreboardsState>((state) => state.scoreboards)
    const { status, changeStatus } = useStatus()
    const [data, setData] = useState<ScoreboardInfo | null>(null)

    const isLoading = status === 'loading'
    const isError = status === 'error'

    const fetchScoreboardInfo = async (id: number) => {
        try {
            changeStatus('loading')
            const scoreboardInfo = await api.send<FetchScoreboardInfoRequest, ScoreboardInfo>('getScoreboardInfo', {
                ledControllerId: id,
            })

            if (!scoreboardInfo) {
                changeStatus('error')
                return
            }

            setData(scoreboardInfo)
            changeStatus('success')
        } catch (err) {
            setData(null)
            changeStatus('error')
        }
    }

    useEffect(() => {
        if (!modalData || !modalData.id) return

        fetchScoreboardInfo(modalData.id)
    }, [])

    const onReject = () => {
        dispatch(scoreboardsActions.closeModal())
    }

    const onAccept = () => {
        if (!modalData || !modalData.id) return

        fetchScoreboardInfo(modalData.id)
    }

    const getAcceptText = () => {
        return translate('update')
    }

    const getRejectText = () => {
        return translate('close')
    }

    const getTitle = () => {
        return translate('controllerCurrentData')
    }

    const renderContent = () => {
        if (!data) return null

        const { brightness, devices, type, ipAddress, port } = data

        return (
            <ControllerDataTable
                brightness={brightness}
                devices={devices}
                type={type}
                ipAddress={ipAddress}
                port={port}
            />
        )
    }

    const getErrorText = () => translate('controllerCurrentDataError')

    return (
        <div className={styles.CurrentControllerData}>
            <InformationWindow
                onReject={onReject}
                onAccept={onAccept}
                acceptText={getAcceptText()}
                rejectText={getRejectText()}
                title={getTitle()}
                isError={isError}
                isLoading={isLoading}
                errorText={getErrorText()}
            >
                <div className={styles.CurrentControllerData__content}>{renderContent()}</div>
            </InformationWindow>
        </div>
    )
}
