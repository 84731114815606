import { MapLocation } from '../models/map'

export abstract class MapApi {
    protected apiKey: string

    constructor(apiKey: string) {
        this.apiKey = apiKey
    }

    getApiKey() {
        return this.apiKey
    }

    abstract geocodeLocation(address: any): Promise<MapLocation[]>

    abstract reverseGeocodeLocation(lat: number, lng: number): Promise<MapLocation[]>
}
