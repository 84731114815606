import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'blocks.simple/modal/modal'
import FlowDiagram from 'blocks.app/flowDiagram/flowDiagram'

import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'

class EditorDiagrams extends React.Component {
    static propTypes = {
        pages: PropTypes.array,
        open: PropTypes.bool,
        onClose: PropTypes.func
    }
    static defaultProps = {
        pages: [],
        open: false,
        onClose: () => {}
    }

    render() {
        const p_ = this.props

        return (
            <Modal open={p_.open} closeIconSize={24} closeIconColor={'white'} mod={'clear'} onClose={p_.onClose}>
                <div className={cn(grid.full, item.previewOpacity)}>
                    <FlowDiagram pages={p_.pages} />
                </div>
            </Modal>
        )
    }
}

export default EditorDiagrams
