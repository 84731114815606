interface IRgbaUtil {
    [key: string]: number
}

export const hexToRgba = (hexColor: string, opacity: number): string => {
    const removeHash = (hex: string): string => (hex.charAt(0) === '#' ? hex.slice(1) : hex)
    const hexToDecimal = (hexDigit: string): number => parseInt(hexDigit, 16)

    const parseHex = (hexWithoutHash: string): IRgbaUtil => {
        const isShortedLengths: number[] = [3, 4]
        const isShort: boolean = isShortedLengths.includes(hexWithoutHash.length)

        const getHexDecimal = (from: number, to: number): number => {
            let hexDigitString: string = hexWithoutHash.slice(from * 2, to * 2)

            if (isShort) {
                hexDigitString = hexWithoutHash.slice(from, to).repeat(2)
            }

            return hexToDecimal(hexDigitString)
        }

        return {
            r: getHexDecimal(0, 1),
            g: getHexDecimal(1, 2),
            b: getHexDecimal(2, 3)
        }
    }

    const formatRgb = (decimalObject: IRgbaUtil, opacity: number): string => {
        const { r, g, b }: IRgbaUtil = decimalObject

        return `rgba(${r}, ${g}, ${b}, ${opacity})`
    }

    const convertHex = (hex: string, a: number): string => {
        const hashlessHex: string = removeHash(hex)
        const hexObject: IRgbaUtil = parseHex(hashlessHex)

        return formatRgb(hexObject, a)
    }

    return convertHex(hexColor, opacity)
}
