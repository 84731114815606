import { addStyles } from 'ethcss'
import { relative } from 'path'
import { grid, IStyles, item, textSizes, indents, radiusSizes } from 'theme'
import { defaultThemeStyles } from './Dropdown.theme'
import { colors } from 'theme'

export const styles: IStyles = {
    Dropdown: {
        position: 'relative',
        zIndex: 100,
    },
    Dropdown_type_active: {
        zIndex: 1001,
    },
    Dropdown_type_withShadow: {
        background: `var(--var-module-ui-component-dropdown-style-withShadow-background, ${defaultThemeStyles['withShadow-background']})`,
        //boxShadow: `var(--var-module-ui-component-dropdown-style-withShadow-shadow, ${defaultThemeStyles['withShadow-shadow']})`,
        border: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${defaultThemeStyles['withShadow-border']})`,
    },
    Dropdown_type_default: {
        background: `var(--var-module-ui-component-dropdown-style-default-background, ${defaultThemeStyles['default-background']})`,
        boxShadow: `var(--var-module-ui-component-dropdown-style-default-shadow, ${defaultThemeStyles['default-shadow']})`,
        border: `1px solid var(--var-module-ui-component-dropdown-style-default-border, ${defaultThemeStyles['default-border']})`,
    },
    Dropdown_type_none: {
        background: `var(--var-module-ui-component-dropdown-style-none-background, ${defaultThemeStyles['none-background']})`,
        boxShadow: `var(--var-module-ui-component-dropdown-style-none-shadow, ${defaultThemeStyles['none-shadow']})`,
        border: `1px solid var(--var-module-ui-component-dropdown-style-none-border, ${defaultThemeStyles['none-border']})`,
    },
    Dropdown_type_fill: {
        background: `var(--var-module-ui-component-dropdown-style-fill-background, ${defaultThemeStyles['fill-background']})`,
        boxShadow: `var(--var-module-ui-component-dropdown-style-fill-shadow, ${defaultThemeStyles['fill-shadow']})`,
        border: `1px solid var(--var-module-ui-component-dropdown-style-fill-border, ${defaultThemeStyles['fill-border']})`,
    },
    Dropdown__list: {
        position: 'absolute',
        // top: 'calc(100% - 1px)',
        top: 'calc(100% - 0.5px)',
        left: 0,
        width: 'calc(100% + 1px)',
        padding: `${indents.mini}px ${indents.miniPlus}px`,
        borderBottomRightRadius: `${radiusSizes.mini}px`,
        borderBottomLeftRadius: `${radiusSizes.mini}px`,
        background: 'inherit',
        boxShadow: 'inherit',
        border: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${colors.grey})`,
    },
}

addStyles(styles)
