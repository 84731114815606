import { addStyles } from 'ethcss'
import { IStyles, mediaCSS } from '../../theme'

const widgetGalleryStyles: IStyles = {
    widgetsGallery: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexWrap: 'wrap',

        [mediaCSS.tabMini]: {
            paddingTop: 60,
        },
    },
    closeBlock: {
        position: 'fixed',
        top: 30,
        right: 30,
        display: 'flex',
        justifyContent: 'flex-end',

        [mediaCSS.tab]: {
            top: 30,
            right: 10,
        },
    },
    closeBlockModal: {
        position: 'fixed',
        top: 30,
        right: 30,
        display: 'flex',
        justifyContent: 'flex-end',

        [mediaCSS.tab]: {
            top: 30,
            right: 10,

            [mediaCSS.tabMini]: {
                right: 10,
            },
        },
    },
    noWidgets: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '500',
    },
}

addStyles(widgetGalleryStyles)
export default widgetGalleryStyles
