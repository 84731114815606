import React from 'react'
import { api } from 'core/api/ConnectionManager'
import deepEqual from 'fast-deep-equal'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import helpers from 'core/helpers'

class CatalogDisplaysMapMethods extends React.Component {
    state = {
        distance: 100,
    }

    componentDidMount() {
        const p_ = this.props
        const locationQuery = getURLSearchParamsByLocation(p_.location)
        const query = helpers.getQuery(locationQuery, 'displays')

        if (p_.userLocation && !p_.center && !query.query) {
            this.getDisplaysByDistance(p_.userLocation, this.state.distance)
        }
        p_.onGetList([])
    }

    componentDidUpdate(prevProps) {
        const s_ = this.state
        const p_ = this.props
        let filter = this.getFilter(p_)
        let prevFilter = this.getFilter(prevProps)
        const filterDiff = !deepEqual(prevFilter, filter)

        if (filterDiff) {
            this.getDisplaysByDistance(p_.center, s_.distance, 'update', filter)
        }
    }

    getFilter = (p_) => {
        const locationQuery = getURLSearchParamsByLocation(p_.location)
        const query = helpers.getQuery(locationQuery, 'displays')

        const filter = { ...query }
        let filtered = null

        if (p_.options) {
            p_.options.forEach((field) => {
                if (query[field.id]) {
                    filtered = this.getFilterField(filter, query, field)
                }
            })
        }

        let filterState = {}
        if (filtered) {
            p_.options.forEach((filterItem) => {
                if (filtered[filterItem.id]) {
                    filterState[filterItem.id] = filtered[filterItem.id]
                }
            })
        }
        return filterState
    }

    getFilterField = (filter, query, field) => {
        const tags = this.props.tags
        filter[field.id] = query[field.id].split('//')

        if (field.id === 'tags') {
            filter[field.id].forEach((fieldItem, index) => {
                const currentTag = tags.find((tag) => tag.name === fieldItem)

                if (currentTag) {
                    filter[field.id][index] = currentTag.id
                }
            })
        }

        return filter
    }

    getDisplaysByDistance = (location, distance, type, filter) => {
        const p_ = this.props
        let list = []
        let query = filter

        api.send('getDisplays', { ...query, ...location, distance }).then((newList) => {
            if (type === 'update') {
                const filtered = newList.filter((item) => !p_.list.includes(item.id))
                list = [...filtered]
            } else {
                list = newList
            }

            p_.onGetList(list)
        })
    }

    onChange = (center, distance) => {
        const p_ = this.props

        if (p_.userLocation) {
            this.setState({ distance })
            this.getDisplaysByDistance(center, distance, 'update', this.getFilter(p_))
        }
    }
}

export default CatalogDisplaysMapMethods
