import { useState, MouseEvent } from 'react'
import { useSpring } from 'react-spring'

import { IButtonHook } from './Button-types'
import { transitions } from 'theme'

export const useButton = ({ animated, onClick }: IButtonHook) => {
    const [animationState, toggleAnimationState] = useState<boolean>(true)
    const spring = useSpring({
        from: {
            x: 1,
        },
        x: animationState ? 1 : 0,
        config: {
            duration: transitions.normal,
        },
    })

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        if (animated) {
            toggleAnimationState(!animationState)
        }

        onClick(event)
    }

    return {
        spring,
        animationState,
        toggleAnimationState,
        handleClick,
    }
}
