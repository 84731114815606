import { addStyles } from 'ethcss'
import { indents, IStyles } from 'theme'
import { defaultThemeTableStyles } from '../Table-theme'

export const styles: IStyles = {
    TableCell: {
        padding: indents.miniMiddle,
        borderBottom: `1px solid var(--var-module-ui-component-table-style-cellBorder, ${defaultThemeTableStyles['cellBorder']})`,
        '&:last-child': {
            borderRight: 'none',
        },
        verticalAlign: 'middle',
        cursor: 'pointer',
    },
}

addStyles(styles)
