import { addStyles } from 'ethcss'
import { IStyles, mediaCSS, item } from 'theme'
import { defaultThemeStyles as defaultThemeSidebarStyles } from 'blocks.app/sidebar/Sidebar-theme'

const scrollableFilterListStyles: IStyles = {
    scrollableFilterList: {
        position: 'relative',
        //padding: '0 30px',
        //width: '80%',
        // [mediaCSS.desktopL]: {
        //     width: '66%',
        // },
        // [mediaCSS.desktop]: {
        //     width: '62%',
        // },
        // [mediaCSS.desktopS]: {
        //     width: '59.5%',
        // },
        // [mediaCSS.desktopXS]: {
        //     width: '58%',
        // },
    },
    arrow: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 10000,
    },
    arrowLeft: {
        left: 0,
    },
    arrowRight: {
        right: 0,
    },
    scrollArea: {
        overflowX: 'clip',
        overflowY: 'unset',
    },
}

addStyles(scrollableFilterListStyles)

export default scrollableFilterListStyles
