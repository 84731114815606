import { addStyles } from 'ethcss'
import { grid, item, text } from 'theme'

const DEVICE_CIRCLE_SIZE = 20

const treeViewCatalogTemplateDisplaysStyles = {
    displaysTemplateNameContent: {
        ...grid.p_micro,
        ...grid.mr_mini,
    },
    displaysTemplateNameWrapper: {
        ...grid.row,
        ...grid.noWrap,
        ...grid.normalCenter,
        ...text.left,
        ...text.grey,
    },
    displaysTemplateStatus: {
        ...grid.rowCenter,
        ...grid.normalCenter,
    },
    displaysTemplateDevice: {
        ...item.circle,
        width: DEVICE_CIRCLE_SIZE,
        height: DEVICE_CIRCLE_SIZE,
    },
}

addStyles(treeViewCatalogTemplateDisplaysStyles)
export default treeViewCatalogTemplateDisplaysStyles
