import PropTypes from 'prop-types'
import React from 'react'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import Modal from 'blocks.simple/modal/modal'
import Item from '../__item/editor__item'
import Icon from 'blocks.simple/icon/icon'
import Range from 'blocks.simple/range/range'
import ReactPlayer from 'react-player'
import { PreviewItem } from '../__listItem/editor__listItem'
import helpers from 'core/helpers'
import styles from './editor__preview.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { cn } from 'ethcss'
import translate from 'core/translate'

const step = 100
class EditorPreview extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            playing: false,
            time: 0,
        }
        this.seekTo = false
    }

    // Проигрывание видео или аудио
    play = () => {
        this.seekTo = false
        const s_ = this.state

        if (s_.playing) {
            this.stop()
            return
        }
        this.interval = setInterval(() => {
            const time = this.state.time + step

            if (time > this.props.duration) {
                this.stop(this.props.duration)
                return
            }
            this.setState({ time })
        }, step)
        this.setState({
            time: this.state.time === this.props.duration ? 0 : this.state.time,
            playing: true,
        })
    }

    // Остановить проигрывание видео или аудио
    stop = (initTime) => {
        clearInterval(this.interval)
        let time = initTime

        if (time === undefined) {
            time = this.state.time
        }
        this.setState({ playing: false, time })
    }
    render() {
        const s_ = this.state
        const p_ = this.props

        return (
            <Modal open={p_.open} showCloseIcon={false}>
                {p_.open && (
                    <div className={cn(styles.editorPreviewWrapper)} onMouseDown={(e) => e.stopPropagation()}>
                        <Rectangle width={p_.screenSize.width} height={p_.screenSize.height}>
                            <div className={styles.full}>
                                <div
                                    style={{
                                        width: `${p_.screenWidth}%`,
                                        height: `${p_.screenHeight}%`,
                                    }}
                                >
                                    <div className={styles.content}>
                                        {p_.areas.map(({ area, content }, areaIndex) => {
                                            return (
                                                <Item
                                                    key={`areaItem_${areaIndex}`}
                                                    name={'areaName'}
                                                    width={area.width}
                                                    height={area.height}
                                                    left={area.left}
                                                    top={area.top}
                                                    type={'area'}
                                                    zIndex={areaIndex}
                                                    disableDrag={true}
                                                    disableResize={true}
                                                    showDeleteIcon={false}
                                                    noBorder={true}
                                                >
                                                    {content.files.map((fileItem, contentIndex) => {
                                                        const visible =
                                                            s_.time >= fileItem.startTime &&
                                                            s_.time < fileItem.startTime + fileItem.duration

                                                        if (!visible) {
                                                            return null
                                                        }
                                                        if (
                                                            fileItem.fileType === 'video' ||
                                                            fileItem.fileType === 'audio'
                                                        ) {
                                                            const currentTime = Math.round(
                                                                (s_.time - fileItem.startTime) / 1000
                                                            )

                                                            return (
                                                                <ReactPlayer
                                                                    key={`previewFileItem__${areaIndex}_${contentIndex}`}
                                                                    ref={(reactPlayer) => {
                                                                        if (reactPlayer) {
                                                                            if (!s_.playing && this.seekTo) {
                                                                                reactPlayer.seekTo(currentTime)
                                                                            }
                                                                        }
                                                                    }}
                                                                    width="100%"
                                                                    height="100%"
                                                                    url={fileItem.src}
                                                                    progressInterval={250}
                                                                    playing={s_.playing}
                                                                />
                                                            )
                                                        } else {
                                                            return (
                                                                <Item
                                                                    key={`previewFileItem__${areaIndex}_${contentIndex}`}
                                                                    id={fileItem.id}
                                                                    name={fileItem.name}
                                                                    width={fileItem.width}
                                                                    height={fileItem.height}
                                                                    left={fileItem.x}
                                                                    top={fileItem.y}
                                                                    type={fileItem.type}
                                                                    style={fileItem.style}
                                                                    fileType={fileItem.fileType}
                                                                    src={fileItem.src}
                                                                    thumbnail={fileItem.thumbnail}
                                                                    zIndex={20 * (contentIndex + 1)}
                                                                    showDeleteIcon={false}
                                                                    fillMode={fileItem.fillMode}
                                                                    showLayersIcon={false}
                                                                    layersCount={content.files.length}
                                                                    noBorder={true}
                                                                    disableDrag={true}
                                                                    disableResize={true}
                                                                    data={fileItem.data}
                                                                />
                                                            )
                                                        }
                                                    })}
                                                </Item>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </Rectangle>
                        <div className={styles.timelineWrapper}>
                            <div className={styles.timeline}>
                                <div className={grid.mr_md}>
                                    <Icon
                                        onClick={this.play}
                                        type={s_.playing ? 'pause' : 'play'}
                                        color={'white'}
                                        size={24}
                                    />
                                </div>
                                <div>
                                    {helpers.msToHMS(s_.time)} / {helpers.msToHMS(p_.duration)}
                                </div>
                                <div className={grid.space} />
                                <div
                                    onClick={() => {
                                        this.stop(0)
                                        this.seekTo = false
                                        p_.onClose()
                                    }}
                                >
                                    <Icon
                                        type="minimize"
                                        color="white"
                                        size={24}
                                        tooltip={{ title: translate('close') }}
                                    />
                                </div>
                                <div className={styles.progress}>
                                    <Range
                                        min={0}
                                        max={p_.duration}
                                        step={1}
                                        value={s_.time}
                                        mod="white"
                                        handle={false}
                                        onChange={(time) => {
                                            this.seekTo = true
                                            this.stop(time)
                                        }}
                                    />
                                </div>
                            </div>
                            {p_.areas.map(({ area, content }, index) => {
                                return (
                                    <div key={`areaContent_${index}`} className={styles.areaContent}>
                                        {content.files.map((fileItem, index) => {
                                            return (
                                                <div
                                                    key={`contentItem_${index}`}
                                                    className={styles.contentItem}
                                                    style={{
                                                        left: `${(fileItem.startTime / p_.duration) * 100}%`,
                                                        width: `${(fileItem.duration / p_.duration) * 100}%`,
                                                    }}
                                                >
                                                    <PreviewItem fileItem={fileItem} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </Modal>
        )
    }
}
EditorPreview.propTypes = {
    open: PropTypes.bool,
    areas: PropTypes.array,
    screenWidth: PropTypes.number,
    screenHeight: PropTypes.number,
    duration: PropTypes.number,
    screenSize: PropTypes.object,
    sources: PropTypes.object,
    onClose: PropTypes.func,
}

EditorPreview.defaultProps = {}

export default EditorPreview
