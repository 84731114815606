import React from 'react'
import Content from 'blocks.app/content/content'
import Catalog from 'blocks.app/catalog/catalog'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

class InstancesCatalogTeachers extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            selectedInfo: null,
            selectedItems: [],
        }
    }
    render() {
        const s_ = this.state

        return (
            <Content title={'teachers'}>
                <Catalog
                    type={'teachers'}
                    onSelect={(selectedItems) => this.setState({ selectedItems })}
                    selectedItems={s_.selectedItems}
                    fixedHeader={true}
                    isExistRightToolbar={false}
                    treeView={false}
                />
            </Content>
        )
    }
}

const exportInstancesCatalogTeachers = checkAvailableModule('instancesCatalog/teachers')
    ? InstancesCatalogTeachers
    : null

export default exportInstancesCatalogTeachers
