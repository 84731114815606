import { addStyles } from 'ethcss'
import { indents, IStyles } from 'theme'

export const styles: IStyles = {
    distributives: {
        padding: `0!important`,
    },
    distributives__wrapper: {
        overflow: 'auto',
    },
    distributives__content: {
        padding: '40px 30px',
    },
    distributives__platform: {
        paddingBottom: indents.mini,
        borderRadius: '10px',
    },
}

addStyles(styles)
