import { useEffect, useState } from 'react'
import translate from 'core/translate'

export interface ITouchEditorEventsProps {
    eventsRef: any
    emitError: (err: string) => void
    page: any
    pages: {
        playOnEvent: {
            events: {
                eventType: string
                eventCondition: { key: string; value: string }
            }[]
        }
    }[]
    event: {
        id: string
        eventType: string
        eventCondition: { key: string; value: string }
    }
    events: any[]
}

const getKeyOptions = (event: any, keys: any) =>
    keys.map((key: string) => ({ id: key, name: translate(`${event}_${key}`) }))

const eventConditionDefaultState = {
    key: null,
    value: '',
}

const TouchEditorEventsMethods = (p_: ITouchEditorEventsProps) => {
    const [state, setState] = useState<{
        eventCondition: { key: string | null; value: string }
        selectedEvent: string | null
        selectedKey: string | null
        keyOptions: { id: string; name: string }[]
    }>({
        eventCondition: eventConditionDefaultState,
        selectedEvent: null,
        selectedKey: null,
        keyOptions: [],
    })

    useEffect(() => {
        if (!p_.event) {
            return
        }

        const findedEvent: any = p_.events.find((event) => event.id === p_.event.id)

        setState((prevState) => {
            return {
                ...prevState,
                selectedEvent: p_.event.id,
                eventCondition: p_.event.eventCondition,
                keyOptions: getKeyOptions(p_.event.id, findedEvent.key),
                selectedKey: p_.event.eventCondition.key,
            }
        })
    }, [])

    const onChangeEvent = (event: { id: string; key: string }) => {
        const keyOptions = getKeyOptions(event.id, event.key)

        setState((prevState) => {
            return {
                ...prevState,
                keyOptions,
                selectedEvent: event.id,
                selectedKey: null,
                eventCondition: eventConditionDefaultState,
            }
        })
    }
    const onChangeKey = (key: { id: string }) => {
        setState((prevState) => {
            return {
                ...prevState,
                selectedKey: key.id,
                eventCondition: eventConditionDefaultState,
            }
        })
    }
    const validateValues = () => {
        const s_ = state

        if (!s_.eventCondition.value) {
            p_.emitError('valueEmptyError')
            return false
        }

        if (s_.selectedEvent === 'rfid' && s_.selectedKey === 'tagId') {
            return !p_.pages.some((page) => {
                return (
                    page.playOnEvent &&
                    page.playOnEvent.events.some((event) => {
                        if (
                            event.eventType === s_.selectedEvent &&
                            event.eventCondition.key === s_.selectedKey &&
                            event.eventCondition.value === s_.eventCondition.value
                        ) {
                            p_.emitError('rfid_tagId_mustBeUnique')
                            return true
                        }
                        return false
                    })
                )
            })
        }

        return true
    }
    const validate = () => {
        const s_ = state
        const { emitError } = p_

        if (!s_.selectedEvent) {
            emitError('selectEvent')
            return false
        }

        if (!s_.selectedKey) {
            emitError('selectKey')
            return false
        }

        return validateValues()
    }
    const onSave = () => {
        const s_ = state

        if (validate()) {
            return {
                eventType: s_.selectedEvent,
                eventCondition: s_.eventCondition,
            }
        }
    }

    p_.eventsRef.current = { onSave }

    return {
        state,
        setState,
        onChangeEvent,
        onChangeKey,
    }
}

export default TouchEditorEventsMethods
