import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import { YMaps, Map as YMap, Placemark } from 'react-yandex-maps'
import { styles } from './YandexMap-styles'
import { MapMarker, TMapPoint, Bounds, MapCluster, MapOptions } from '../../models/map'
import { MAP_CENTER, MAP_ZOOM } from '../../constants'
import { isExist, isNotEmptyArray } from 'core/utils/coreUtil'
import MarkerUrl from 'features/map/images/marker.svg'
import MarkerActiveUrl from 'features/map/images/markerActive.svg'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { getThemeStyleValue } from 'theme/colors'
import { MapProps } from 'features/map/models/map'
import { compareMapProps, getDistance, getInitialMapOptions } from 'features/map/utils'
import { useClusters } from 'features/map/hooks/useClusters'
import { useMapOptions } from 'features/map/hooks/useMapOptions'

const mapModules = ['control.ZoomControl', 'templateLayoutFactory', 'layout.ImageWithContent']

const formatBounds = (yandexBounds: any[]): Bounds => {
    const [leftBottom, rightTop] = yandexBounds

    return {
        nw: {
            lat: rightTop[0],
            lng: leftBottom[1],
        },
        ne: {
            lat: rightTop[0],
            lng: rightTop[1],
        },
        se: {
            lat: leftBottom[0],
            lng: rightTop[1],
        },
        sw: {
            lat: leftBottom[0],
            lng: leftBottom[1],
        },
    }
}

const formatCenter = (yandexCenter: any) => {
    return {
        lat: yandexCenter[0],
        lng: yandexCenter[1],
    }
}

const getMapCenter = (center: TMapPoint) => {
    if (!isExist(center)) return [MAP_CENTER.lat, MAP_CENTER.lng]

    return center.lat && center.lng ? [center.lat, center.lng] : [MAP_CENTER.lat, MAP_CENTER.lng]
}

const isMapPropsEqual = compareMapProps

const YandexMap: FC<MapProps> = memo(
    ({
        onChange,
        onClusterClick,
        onMarkerClick,
        markers,
        activeMarkerList,
        activeMarker,
        activeMarkerColor,
        markerColor,
        center,
        zoom = MAP_ZOOM,
    }) => {
        let mapRef: any

        const { clusters, updateClusters } = useClusters()
        const { mapOptions, updateMapOptions } = useMapOptions(getInitialMapOptions(center, zoom))

        useEffect(() => {
            if (!isExist(center) || !isExist(mapRef)) return

            const mapCenter = mapOptions.center

            if (mapCenter.lat !== center.lat || mapCenter.lng !== center.lng) {
                mapRef.setCenter(getMapCenter(center))
            }
        }, [center])

        useEffect(() => {
            updateClusters(mapOptions, markers)
        }, [mapOptions])

        const getDefaultMapState = () => {
            return {
                center: getMapCenter(center),
                zoom: zoom,
            }
        }

        const fullActiveMarkerList = useMemo(() => {
            if (!activeMarker) return activeMarkerList

            return activeMarkerList.filter((marker) => marker.id !== activeMarker.id).concat(activeMarker)
        }, [activeMarker, activeMarkerList])

        const findIntersections = (mapMarkerIds: number[]) => {
            const activeMapMarkerIds = fullActiveMarkerList.map((mapMarker) => mapMarker.id)

            return mapMarkerIds.filter((id) => activeMapMarkerIds.includes(id))
        }

        const isMarkerActive = (mapMarker: MapMarker) => {
            if (!isExist(mapMarker) || !isExist(fullActiveMarkerList) || !isNotEmptyArray(fullActiveMarkerList))
                return false

            return isNotEmptyArray(findIntersections([mapMarker.id]))
        }

        const isClusterActive = (cluster: MapCluster) => {
            if (!isExist(fullActiveMarkerList) || !isExist(cluster)) return false

            const clusterIds = cluster.points.map((point) => point.id)
            return isNotEmptyArray(findIntersections(clusterIds))
        }

        const onMapChange = (event: any) => {
            processMap(event.originalEvent.newCenter, event.originalEvent.newZoom, event.originalEvent.newBounds)
        }

        const processMap = (yandexCenter: any, zoom: number, yandexBounds: any) => {
            const formattedCenter: TMapPoint = formatCenter(yandexCenter)
            const bounds = formatBounds(yandexBounds)

            const distance = getDistance(formattedCenter, bounds)

            if (onChange) {
                onChange(center, distance)
            }

            updateMapOptions({
                center,
                zoom,
                bounds,
            })
        }

        const getIconUrl = (isActive: boolean): string => {
            return isActive ? MarkerActiveUrl : MarkerUrl
        }

        const renderMarkers = useMemo(() => {
            if (!markers || !isNotEmptyArray(markers)) return null

            return markers.map((item, index) => {
                const isActive = isMarkerActive(item)

                return (
                    <Placemark
                        key={`marker_key_${index}`}
                        geometry={[item.lat, item.lng]}
                        options={{
                            iconLayout: 'default#image',
                            iconImageHref: getIconUrl(isActive),
                            iconImageSize: isActive ? [60, 60] : [40, 40],
                            iconImageOffset: isActive ? [-30, -45] : [-20, -30],
                        }}
                        onClick={function () {
                            onMarkerClick(item)
                        }}
                    />
                )
            })
        }, [markers, fullActiveMarkerList])

        const getClusterBadge = (points: any) => {
            const background = getThemeStyleValue('app', 'app', 'backgroundSub') || defaultThemeStyles.backgroundSub
            const border = getThemeStyleValue('app', 'app', 'border') || defaultThemeStyles.border
            const text = getThemeStyleValue('app', 'app', 'border') || defaultThemeStyles.border
            const style = `color: ${text}; background-color: ${background}; position: absolute; right: 0; top: 0; padding: 1px 5px; border-radius: 50%; border: 1px solid ${border}; light-height: 1;`

            return `<div style="${style}">${points.length}</div>`
        }

        const renderOnePointCluster = (cluster: MapCluster, index: number) => {
            const clusterPoint = cluster.points[0]
            const isActive = isMarkerActive(clusterPoint)

            return (
                <Placemark
                    key={`marker_key_${index}`}
                    geometry={[cluster.lat, cluster.lng]}
                    options={{
                        iconLayout: 'default#image',
                        iconImageHref: getIconUrl(isActive),
                        iconImageSize: isActive ? [60, 60] : [40, 40],
                        iconImageOffset: isActive ? [-30, -45] : [-20, -30],
                    }}
                    onClick={function () {
                        onMarkerClick(clusterPoint)
                    }}
                />
            )
        }

        const renderMultiplePointCluster = (cluster: MapCluster, index: number) => {
            const isActive = isClusterActive(cluster)

            return (
                <Placemark
                    key={`clusterMarker_key_${index}`}
                    geometry={[cluster.lat, cluster.lng]}
                    properties={{
                        iconContent: getClusterBadge(cluster.points),
                    }}
                    options={{
                        iconLayout: 'default#imageWithContent',
                        iconImageHref: getIconUrl(isActive),
                        iconImageSize: isActive ? [60, 60] : [40, 40],
                        iconImageOffset: isActive ? [-30, -45] : [-20, -30],
                    }}
                    onClick={function () {
                        if (onClusterClick) {
                            onClusterClick(cluster.points)
                        }
                    }}
                />
            )
        }

        const renderClusters = useMemo(() => {
            if (!clusters || !isNotEmptyArray(markers)) return null

            return clusters.map((cluster: any, index: number) => {
                return cluster.numPoints === 1
                    ? renderOnePointCluster(cluster, index)
                    : renderMultiplePointCluster(cluster, index)
            })
        }, [clusters, fullActiveMarkerList])

        const createMapRef = (yandexMapApi: any) => {
            mapRef = yandexMapApi
        }

        const handleOnLoad = () => {
            if (!mapRef) return

            mapRef.events.add('boundschange', onMapChange)

            processMap(getMapCenter(mapOptions.center), mapRef._zoom, mapRef._bounds)
        }

        const isRenderMarkers = () => !isNotEmptyArray(clusters) && isExist(markers) && isNotEmptyArray(markers)

        const isRenderClusters = () => isNotEmptyArray(clusters)

        const renderMapContent = () => {
            if (isRenderMarkers()) return renderMarkers
            if (isRenderClusters()) return renderClusters

            return null
        }

        return (
            <YMaps>
                <YMap
                    className={styles.map}
                    defaultState={getDefaultMapState()}
                    modules={mapModules}
                    onLoad={handleOnLoad}
                    instanceRef={createMapRef}
                >
                    {renderMapContent()}
                </YMap>
            </YMaps>
        )
    },
    isMapPropsEqual
)

export { YandexMap }
