import React from 'react'
import CatalogDisplaysMapMethods from './catalog__map_displays.local.methods'
import { RootStateOrAny, useSelector } from 'react-redux'
import styles from './catalog__map_displays.jcss'
import { withRouter } from 'react-router'
import { MapCreator } from 'features/map/controllers/MapCreator'
import { OpenStreetMap } from '../../../../features/map/molecules/OpenStreetMap/OpenStreetMap-view'
import { MapMarker } from '../../../../features/map/models/map'
const mapFactory = MapCreator.getInstance().getMapFactory()
const CreatedMap = mapFactory.createMap()
const mapApi = mapFactory.createMapApi()

interface ICatalogDisplaysMapProps {
    onSelectCluster: (item: object) => void
    onSelectInfoByItem: (item: object) => void
    centerMap: (center: { lat: number; lng: number }) => void
    center: { lat: number; lng: number }
    list: MapMarker[]
    zoom: number
    selectedItems: MapMarker[]
    selectedInfo: MapMarker
    markerColor: string
    activeMarkerColor: string
}

const CatalogDisplaysMap = (p_: ICatalogDisplaysMapProps) => {
    const userLocation = useSelector<RootStateOrAny>((state) => state.user.data.location)
    const { onChange } = CatalogDisplaysMapMethods(p_)

    const markerClick = (item: object) => {
        p_.onSelectCluster([item])
        p_.onSelectInfoByItem(item)
    }

    const clusterClick = (list: object[]) => {
        p_.onSelectCluster(list)
    }

    const onMapChange = (center: { lat: number; lng: number }, distance: number) => {
        onChange(center, distance)
        p_.centerMap(center)
    }

    const center = p_.center || userLocation

    return (
        <div className={styles.map}>
            {window.spconfig.map.type === 'open-street-map' ? (
                <OpenStreetMap
                    onClose={() => {}}
                    isOpen={true}
                    location={{ lat: 55.7504461, lng: 37.6174943 }}
                    onChangeLocation={() => {}}
                    onChangePlacemarkGeometry={() => {}}
                    markers={p_.list}
                    onMarkerClick={markerClick}
                />
            ) : (
                <CreatedMap
                    onMarkerClick={markerClick}
                    onClusterClick={clusterClick}
                    onChange={onChange}
                    markers={p_.list}
                    activeMarkerList={p_.selectedItems}
                    activeMarker={p_.selectedInfo}
                    zoom={p_.zoom}
                    markerColor={p_.markerColor}
                    activeMarkerColor={p_.activeMarkerColor}
                    center={center}
                    mapApi={mapApi}
                />
            )}
        </div>
    )
}
// CatalogDisplaysMap.propTypes = {
//     onSelect: PropTypes.func,
//     onSelectCluster: PropTypes.func,
//     center: PropTypes.object,
//     list: PropTypes.array,
//     userLocation: PropTypes.object,
//     onClick: PropTypes.func,
//     onSelectInfo: PropTypes.func,
//     onChange: PropTypes.func,
//     onGetList: PropTypes.func,
//     mapConfig: PropTypes.object,
// }
CatalogDisplaysMap.defaultProps = {
    onGetList: () => {},
    onSelectInfo: () => {},
    onSelectCluster: () => {},
}

// @ts-ignore
export default withRouter(CatalogDisplaysMap)
