import { addStyles } from 'ethcss'
import { grid, indents, IStyles, textSizes } from 'theme'

export const styles: IStyles = {
    DeviceSettingsDownloadMode: {
        ...grid.row,
        ...grid.full,
        ...grid.mt_mdPlus,
    },
    DeviceSettingsDownloadMode__title: {
        fontSize: textSizes.normal,
        ...grid.mb_md,
    },
    DeviceSettingsDownloadMode__buttons: {
        ...grid.row,
        ...grid.fullWidth,
    },
    DeviceSettingsDownloadMode__button: {
        paddingRight: indents.md,
    },
}

addStyles(styles)
