import React from 'react';
import AppTestDownloads from 'blocks.app/app/__test/_downloads/app__test_downloads';

import styles from '../ui.jcss';

class UiDownloads extends React.Component {
    render() {
        return (
            <div className={styles.blockPanel}>
                <div className={styles.title}>
                    Имитация старта загрузки
                </div>
                <div className={styles.content}>
                    <AppTestDownloads />
                </div>
            </div>
        )
    }
}

export default UiDownloads