//http://jenkins.smartplayer.org/
//http://192.168.0.101/
//https://dev.smartplayer.org/
//My key AIzaSyCTCtwNAMey2-qd1qGkd_b2NvDMe8t5BRw

declare global {
    interface Window {
        spconfig: any
    }
}

interface IProps {
    [index: string]: number
}
interface IPropsWithStr {
    [index: string]: number | string
}

export default {
    api: {
        root: window.spconfig.ip_address,
        room: 'frontend',
    },
    version: process.env.REACT_APP_VERSION,
    googleMapKey: 'AIzaSyCWi9bwb7bmPErCUMnEjOESARF8zLhlCA0',
    googleMapKeyDevelopment: 'AIzaSyAJTZXqSB0WxSHLPddPlxNkVNM7oU-vbps',
}

export const cols: IProps = {
    mini: 5.504167,
    normal: 15.1041666667,
    normalFixed: 290,
}

export const indents: IProps = {
    none: 0,
    nano: 4,
    micro: 7,
    microPlus: 10,
    mini: 14,
    md: 21,
    mdPlus: 36,
    big: 53,
    bigPlus: 75,
}

export const textSizes: IProps = {
    nano: 8,
    micro: 10,
    mini: 12,
    normal: 14,
    normalPlus: 16,
    md: 18,
    mdPlus: 21,
    big: 34,
}

export const layers: IProps = {
    sticky: 1500,
    modal: 2000,
    drop: 3000,
    menu: 3000,
    progress: 5000,
}

export const radiusSizes: IPropsWithStr = {
    micro: 5,
    mini: 5,
    normal: 18,
    md: 21,
    big: 30,
    circle: '50%',
}

export const transitions: IProps = {
    normal: 300,
    md: 1000,
}

/* media */
export const media: IProps = {
    laptop: 1600,
    desktopL: 1200,
    tab: 960,
    tabMini: 700,
    mob: 450,
}
export const mediaCSS: any = {}
export const mediaJS: any = {}

for (let mediaKey in media) {
    if (media.hasOwnProperty(mediaKey)) {
        const mediaValue = media[mediaKey]
        mediaCSS[mediaKey] = `@media (max-width: ${mediaValue}px)`
        mediaJS[mediaKey] = window.matchMedia(`(max-width: ${mediaValue}px)`)
    }
}
/* /media */

export const font = {
    regular: window.spconfig.fontFamily || 'century-gothic__regular, sans-serif',
}
