import React, { FC } from 'react'
import {
    ICatalogItem,
    ICatalogWithPaginationOptions,
} from 'organisms/CatalogWithPagination/CatalogWithPagination-types'
import translate from 'core/translate'
import { CatalogWithPagination } from 'organisms/CatalogWithPagination'
import { ContentWithoutToolbar } from 'organisms/ContentWithoutToolbar'
import { ICatalogProductItem, IInstancesCatalogProductsProps } from './InstancesCatalogProducts-types'
import { ProductsReferenceBookItem } from './molecules/ProductsReferenceBookItem'
import { ProductsActionBar } from './organisms/ProductsActionBar'
import { ProductsReferenceBookHeader } from './molecules/ProductsReferenceBookHeader'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

const options: ICatalogWithPaginationOptions = {
    common: {
        type: 'products',
        parentKey: 'parentId',
        searchKey: 'productCode',
        fields: [
            { id: 'productCode', name: translate('productCode') },
            { id: 'oldPrice', name: translate('oldPrice') },
            { id: 'currentPrice', name: translate('currentPrice') },
            { id: 'barcodes', name: translate('barcodes') },
            { id: 'productCategoryId', name: translate('category') },
        ],
        getMethodName: 'getProducts',
        dispositionList: ['referenceBook'],
        defaultDisposition: 'referenceBook',
        isEnableDnD: false,
        isEnableSelectInfo: false,
        isEnableMultipleSelect: false,
        isEnableUpdateUserSettings: true,
        referenceBook: {
            headerComponent: ProductsReferenceBookHeader,
            itemComponent: ProductsReferenceBookItem,
        },
    },
    actionBar: {
        component: ProductsActionBar,
    },
    methods: {
        isEqual: (item: ICatalogItem, nextItem: ICatalogItem) => {
            const productItem = item as ICatalogProductItem
            const nextProductItem = nextItem as ICatalogProductItem

            if (productItem.productCode && nextProductItem.productCode) {
                return productItem.productCode === nextProductItem.productCode
            }

            if (productItem.id && nextProductItem.id) {
                return productItem.productCode === nextProductItem.productCode
            }

            return productItem === nextProductItem
        },
        isFolder: (item: ICatalogItem) => false,
    },
    emptyList: {
        folderText: translate('notFound'),
        searchText: translate('noProducts'),
        descriptionText: translate('noDescription'),
    },
}

const InstancesCatalogProductsComponent: FC<IInstancesCatalogProductsProps> = ({
    location,
    minimize,
    showMobToolbar,
}) => {
    return (
        <ContentWithoutToolbar title={translate('products')}>
            <CatalogWithPagination
                options={options}
                location={location}
                minimize={minimize}
                showMobToolbar={showMobToolbar}
            />
        </ContentWithoutToolbar>
    )
}

export const InstancesCatalogProducts = checkAvailableModule('instancesCatalog/products')
    ? InstancesCatalogProductsComponent
    : null
