import { addStyles } from 'ethcss'
import { grid, item, colors, IStyles } from '../../theme'
import { transitions } from 'blocks.app/config'
import { hexToRgba } from 'core/utils'
import './button.css'
import { defaultThemeStyles } from './button.theme'
import { getThemeStyleValue } from 'theme/colors'

const animation = (color: string) => ({
    '0%': {
        'box-shadow': `0 0 0 0 ${hexToRgba(color, 0.6)}`,
    },
    '70%': {
        'box-shadow': '0 0 0 10px transparent',
    },
    '100%': {
        'box-shadow': '0 0 0 0 transparent',
    },
})

const buttonStyles: IStyles = {
    wrapper: {
        ...grid.row,
        ...grid.normalCenter,
        ...item.rel,
        ...item.pointer,
        ...item.overNone,
        ...grid.noWrap,
    },
    hoverIconWrapper() {
        return {
            [`& .${this.icon}`]: {
                ...item.hidden,
            },
            [`&:hover .${this.icon}`]: {
                ...item.visible,
            },
        }
    },
    icon: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.fullHeight,
        ...item.pointer,
    },
    _animation: {
        pulseFill: animation(
            getThemeStyleValue('ui', 'button', 'fill-background') || defaultThemeStyles['fill-background']
        ),
        pulseWithShadow: animation(
            getThemeStyleValue('ui', 'button', 'withShadow-background') || defaultThemeStyles['withShadow-background']
        ),
        pulseWithBorder: animation(
            getThemeStyleValue('ui', 'button', 'withBorder-border') || defaultThemeStyles['withBorder-border']
        ),
        pulseDefault: animation(
            getThemeStyleValue('ui', 'button', 'default-background') || defaultThemeStyles['default-background']
        ),
        pulseList: animation(
            getThemeStyleValue('ui', 'button', 'list-background') || defaultThemeStyles['list-background']
        ),
    },
    fillAnimation() {
        return {
            '&:focus': {
                '&:not(:active)': {
                    animation: `${this._animation.pulseFill} ${transitions.md}ms`,
                },
            },
        }
    },
    withShadow() {
        return {
            '&:focus': {
                '&:not(:active)': {
                    animation: `${this._animation.pulseWithShadow} ${transitions.md}ms`,
                },
            },
        }
    },
    withBorderAnimation() {
        return {
            '&:focus': {
                '&:not(:active)': {
                    animation: `${this._animation.pulseWithBorder} ${transitions.md}ms`,
                },
            },
        }
    },
    defaultAnimation() {
        return {
            '&:focus': {
                '&:not(:active)': {
                    animation: `${this._animation.pulseDefault} ${transitions.md}ms`,
                },
            },
        }
    },
    listAnimation() {
        return {
            '&:focus': {
                '&:not(:active)': {
                    animation: `${this._animation.pulseList} ${transitions.md}ms`,
                },
            },
        }
    },
    button_type_default: {
        color: `var(--var-module-ui-component-button-style-default-textMain, ${defaultThemeStyles['default-textMain']})`,
        background: `var(--var-module-ui-component-button-style-default-background, ${defaultThemeStyles['default-background']})`,
        border: `1px solid var(--var-module-ui-component-button-style-default-border, ${defaultThemeStyles['default-border']})`,
        boxShadow: `var(--var-module-ui-component-button-style-default-shadow, ${defaultThemeStyles['default-shadow']})`,
    },
    button_type_withLightBorder: {
        color: `var(--var-module-ui-component-button-style-list-textMain, ${defaultThemeStyles['list-textMain']})`,
        background: `var(--var-module-ui-component-button-style-list-background, ${defaultThemeStyles['list-background']})`,
        borderRight: `1px solid var(--var-module-ui-component-button-style-withBorder-activeBorder, ${defaultThemeStyles['withBorder-textMain']})`,
        borderTop: `1px solid var(--var-module-ui-component-button-style-withBorder-activeBorder, ${defaultThemeStyles['withBorder-textMain']})`,
        borderBottom: `1px solid var(--var-module-ui-component-button-style-withBorder-activeBorder, ${defaultThemeStyles['withBorder-textMain']})`,
        borderLeft: `1px solid var(--var-module-ui-component-button-style-withBorder-activeBorder, ${defaultThemeStyles['withBorder-textMain']})`,
    },
    button_type_scheduleButtonBorder: {
        color: `var(--var-module-ui-component-button-style-list-textMain, ${defaultThemeStyles['button-color']})`,
        background: `var(--var-module-ui-component-button-style-list-background, ${defaultThemeStyles['list-background']})`,
        border: `1px solid var(--var-module-ui-component-button-style-withBorder-activeBorder, ${defaultThemeStyles['button-color']})`,
    },
    button_type_withBorderV: {
        borderTop: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
        borderBottom: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
        color: `var(--var-module-ui-component-button-style-list-textMain, ${defaultThemeStyles['list-textMain']})`,
        background: `var(--var-module-ui-component-button-style-list-background, ${defaultThemeStyles['list-background']})`,
    },
    button_type_withBorderVR: {
        borderTop: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
        borderBottom: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
        borderRight: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
        color: `var(--var-module-ui-component-button-style-list-textMain, ${defaultThemeStyles['list-textMain']})`,
        background: `var(--var-module-ui-component-button-style-list-background, ${defaultThemeStyles['list-background']})`,
    },
    button_type_withBorderVL: {
        borderTop: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
        borderBottom: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
        borderRight: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
        color: `var(--var-module-ui-component-button-style-list-textMain, ${defaultThemeStyles['list-textMain']})`,
        background: `var(--var-module-ui-component-button-style-list-background, ${defaultThemeStyles['list-background']})`,
    },
    button_type_list: {
        border: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
        color: `var(--var-module-ui-component-button-style-list-textMain, ${defaultThemeStyles['list-textMain']})`,
        background: `var(--var-module-ui-component-button-style-list-background, ${defaultThemeStyles['list-background']})`,
    },
    button_type_withBorder: {
        color: `var(--var-module-ui-component-button-style-withBorder-textMain, ${defaultThemeStyles['withBorder-textMain']})`,
        background: `var(--var-module-ui-component-button-style-withBorder-background, ${defaultThemeStyles['withBorder-background']})`,
        border: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,

        '&:active': {
            boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.25)',
        },
    },
    button_type_withShadow: {
        color: `var(--var-module-ui-component-button-style-withShadow-textMain, ${defaultThemeStyles['withShadow-textMain']})`,
        background: `var(--var-module-ui-component-button-style-withShadow-background, ${defaultThemeStyles['withShadow-background']})`,
        border: `1px solid var(--var-module-ui-component-button-style-withShadow-border, ${defaultThemeStyles['withShadow-border']})`,
        boxShadow: `var(--var-module-ui-component-button-style-withShadow-shadow, ${defaultThemeStyles['withShadow-shadow']})`,

        '&:active': {
            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
        },
    },
    button_type_fill: {
        color: `var(--var-module-ui-component-button-style-fill-textMain, ${defaultThemeStyles['fill-textMain']})`,
        background: `var(--var-module-ui-component-button-style-fill-background, ${defaultThemeStyles['fill-background']})`,
        border: `1px solid var(--var-module-ui-component-button-style-fill-border, ${defaultThemeStyles['fill-border']})`,
        boxShadow: `var(--var-module-ui-component-button-style-fill-shadow, ${defaultThemeStyles['fill-shadow']})`,

        '&:active': {
            boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.25)',
        },
    },
    button_type_error: {
        color: `var(--var-module-ui-component-button-style-error-textMain, ${defaultThemeStyles['error-textMain']})`,
        background: `var(--var-module-ui-component-button-style-error-background, ${defaultThemeStyles['error-background']})`,
        border: `1px solid var(--var-module-ui-component-button-style-error-border, ${defaultThemeStyles['error-border']})`,
        boxShadow: `var(--var-module-ui-component-button-style-error-shadow, ${defaultThemeStyles['error-shadow']})`,
    },
    button_type_secondary: {
        color: `var(--var-module-ui-component-button-style-secondary-textMain, ${defaultThemeStyles['secondary-textMain']})`,
        background: `var(--var-module-ui-component-button-style-secondary-background, ${defaultThemeStyles['secondary-background']})`,
        border: `1px solid var(--var-module-ui-component-button-style-secondary-border, ${defaultThemeStyles['secondary-border']})`,
        boxShadow: `var(--var-module-ui-component-button-style-secondary-shadow, ${defaultThemeStyles['secondary-shadow']})`,
    },
    button_type_default_activated: {
        color: `var(--var-module-ui-component-button-style-default-active, ${defaultThemeStyles['default-active']})`,
    },
    button_type_list_activated: {
        color: `var(--var-module-ui-component-button-style-list-active, ${defaultThemeStyles['list-active']})`,
    },
    button_type_withBorder_activated: {
        color: `var(--var-module-ui-component-button-style-withBorder-active, ${defaultThemeStyles['withBorder-active']})`,
        border: `1px solid var(--var-module-ui-component-button-style-withBorder-activeBorder, ${defaultThemeStyles['withBorder-activeBorder']})`,
    },
    button_type_custom_theme_in_video_editor: {
        border: '1px solid #494848',
    },
    button_type_withShadow_activated: {
        color: `var(--var-module-ui-component-button-style-withShadow-active, ${defaultThemeStyles['withShadow-active']})`,
    },
    button_type_fill_activated: {
        color: `var(--var-module-ui-component-button-style-fill-active, ${defaultThemeStyles['fill-active']})`,
    },
    button_type_error_activated: {
        color: `var(--var-module-ui-component-button-style-error-active, ${defaultThemeStyles['error-active']})`,
    },
    button_type_secondary_activated: {
        color: `var(--var-module-ui-component-button-style-secondary-active, ${defaultThemeStyles['secondary-active']})`,
    },
    button_type_withAccent: {
        border: `1px solid ${colors.blueAccent}`,
        color: colors.blueAccent,
    },
    disabled: {
        opacity: 0.4,
        pointerEvents: 'none',
    },
}

addStyles(buttonStyles)
export default buttonStyles
