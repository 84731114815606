import { colors } from 'theme'

export const defaultThemeStyles = {
    backgroundMain: colors.greyEditor,
    backgroundSub: colors.grey,
    border: colors.white,
    textMain: colors.black,
    textSub: colors.white,
    backgroundContent: colors.blackLight,
    selectLine: colors.selected,
    backgroundLight: colors.greyLight,

    'area-title': colors.darkBlue,

    template: colors.grey,
    'template-title': colors.grey,
    'template-border': colors.white,

    'timeline-text': colors.white,
    'timeline-lineBorder': colors.white,
    'timeline-border': colors.grey,
    'timeline-list-background': colors.white,
    'timeline-list-border': colors.darkCloud,
    'timeline-preview-background': colors.greyLight,
    'timeline-timeBlock': colors.cloud,
    'timeline-selected': colors.blackLight,
    'timeline-textViewText': colors.grey,
    'timeline-backLayer': 'rgba(0, 0, 0, 0.45)',

    'footer-background': colors.white,
    'footer-emptySoundtrack': colors.white,
    'footer-timeNumber': colors.blackLight,
    'footer-timeNumberText': colors.white,
    'footer-border': colors.white,
    'footer-loopLine': colors.selected,

    'soundtrack-panel': colors.white,
    'soundtrack-border': colors.white,
    'soundtrack-text': colors.grey,
    'soundtrack-textSecondary': colors.black,

    'header-icon': colors.white,
    'header-iconActive': colors.grey,
}
