import React from 'react'
import { editor } from '../editor.local.methods'
import deepEqual from 'fast-deep-equal'

class EditorActionsMethods extends React.PureComponent {
    state = {
        isOpenModal: false,
        content: {},
        action: null
    }
    static getDerivedStateFromProps(nextProps, nextState) {
        if (!deepEqual(nextProps.content, nextState.content)) {
            return {
                content: nextProps.content
            }
        }

        return null
    }
    onSave = (actionsOnContent, actionIndex) => {
        const s_ = this.state
        let data = [actionsOnContent]

        if (s_.content.actionsOnContent) {
            data = s_.content.actionsOnContent.map((type) => {
                if (typeof actionIndex === 'number') {
                    type.actions[actionIndex] = actionsOnContent.actions[0]
                } else if (type.eventType === actionsOnContent.eventType) {
                    type.actions.push(...actionsOnContent.actions)
                }

                return type
            })
        }

        this.setState({ isOpenModal: false })
        editor.setActionsOnContent(data)
    }
    onDelete = (event, action) => {
        editor.deleteActionOnContent(event, action)
    }
}

export default EditorActionsMethods
