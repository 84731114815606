import ModalAccept from 'blocks.simple/modal/_accept/modal_accept'
import translate from 'core/translate'
import { Location } from 'history'
import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Prompt } from 'react-router-dom'
import { IRouteLeavingGuardComponent } from './RouteLeavingGuard-types'

export const RouteLeavingGuard: FC<IRouteLeavingGuardComponent> = ({
    shouldBlockNavigation,
    navigate,
    when,
    onAccept,
    title,
    description,
    acceptLabel,
    rejectLabel,
}) => {
    const location = useLocation()
    const [isVisible, setIsVisible] = useState<boolean>(false)
    const [lastLocation, setLastLocation] = useState<Location | null>(null)
    const [confirmedNavigation, setConfirmedNavigation] = useState<boolean>(false)

    const closeModal = () => {
        setIsVisible(false)
    }

    const handleBlockedNavigation = (nextLocation: Location) => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation, location)) {
            setIsVisible(true)
            setLastLocation(nextLocation)
            return false
        }

        return true
    }

    const handleConfirmNavigationClick = () => {
        setIsVisible(false)
        setConfirmedNavigation(true)
    }

    const handleAccept = () => {
        if (onAccept) {
            onAccept((isRedirect: boolean) => {
                if (isRedirect) {
                    handleConfirmNavigationClick()
                }
            })
        } else {
            closeModal()
        }
    }

    const handleReject = () => {
        handleConfirmNavigationClick()
    }

    const handleClose = () => {
        closeModal()
    }

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.pathname)
        }
    }, [confirmedNavigation, lastLocation])

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <ModalAccept
                open={isVisible}
                onReject={handleReject}
                onAccept={handleAccept}
                onClose={handleClose}
                title={title || translate('notSaved')}
                description={description || translate('notSavedDescription')}
                rejectLabel={
                    rejectLabel || (
                        <React.Fragment>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {translate('exit')}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                        </React.Fragment>
                    )
                }
                acceptLabel={acceptLabel || translate('save')}
            />
        </>
    )
}
