import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { indents, layers } from 'blocks.app/config'
import { mediaCSS } from 'theme/media'
import { colors, IStyles } from '../../../theme'

export const iconSize = 16
const buttonIndents = 'mini'
const buttonSize = indents[buttonIndents] * 2 + iconSize

const appHeaderStyles: IStyles = {
    mobileMenu: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: 'blue',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: layers.sticky + 3,
        backgroundColor: colors.blueAccent,
    },
    name: {
        color: colors.white,
    },
    toggle: {
        display: 'none',
        [mediaCSS.tab]: {
            ...item.blockImportant,
        },
        ...grid[`p_${buttonIndents}`],
        top: 0,
        zIndex: layers.sticky + 3,
    },
    menuToggle: {
        top: 0,
        left: `calc(50% - ${buttonSize + indents[buttonIndents] / 2}px)`,
    },
    toolbarToggle: {
        top: 0,
        left: `calc(50%)`,
    },
    disabled: {
        opacity: 0,
        pointerEvents: 'none',
    },
}

addStyles(appHeaderStyles)
export default appHeaderStyles
