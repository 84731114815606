import React, { Fragment, FunctionComponent } from 'react'
import { VideoChatModalContentMinimized } from '../VideoChatModalContentMinimized'
import { VideoChatModalContent } from '../VideoChatModalContent'
import { IVideoChatModalConnectionComponent } from './VideoChatModalConnection-types'
import { useVideoChatCall } from './VideoChatModalConnection-hooks'
import { videochatActions } from 'pages/videoChat/videoChat.state'
import { useDispatch } from 'react-redux'

const VideoChatModalConnectionComponent: FunctionComponent<IVideoChatModalConnectionComponent> = ({
    isMinimized,
    videoCallState,
    digitalSignage,
    handleMinimize,
    handleExpand,
    connectionStatus,
}) => {
    const dispatch = useDispatch()
    const { mediaStream, remoteMediaStream, resetVideoChat, isMediaDevicesInit, callStatus } = useVideoChatCall(
        videoCallState,
        digitalSignage,
        connectionStatus
    )

    const handleVideoCallStart = () => {
        dispatch(videochatActions.startVideoCall())
    }

    const handleVideoCallEnd = () => {
        if (!isMediaDevicesInit) return

        resetVideoChat()
        dispatch(videochatActions.endVideoCall())
    }

    const handleClose = () => {
        resetVideoChat()
        dispatch(videochatActions.closeVideoModal())
    }

    return (
        <Fragment>
            {isMinimized ? (
                <VideoChatModalContentMinimized
                    videoCallData={{
                        localVideo: mediaStream,
                        video: remoteMediaStream,
                    }}
                    callStatus={callStatus}
                    onExpand={handleExpand}
                    onVideoCallEnd={handleVideoCallEnd}
                    videoCallState={videoCallState}
                    digitalSignage={digitalSignage}
                />
            ) : (
                <VideoChatModalContent
                    videoCallData={{
                        localVideo: mediaStream,
                        video: remoteMediaStream,
                    }}
                    onMinimize={handleMinimize}
                    onVideoCallStart={handleVideoCallStart}
                    onVideoCallEnd={handleVideoCallEnd}
                    isMediaDevicesInit={isMediaDevicesInit}
                    callStatus={callStatus}
                    onClose={handleClose}
                    videoCallState={videoCallState}
                    digitalSignage={digitalSignage}
                />
            )}
        </Fragment>
    )
}

export const VideoChatModalConnection = VideoChatModalConnectionComponent
