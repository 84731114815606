import { isExist, isNotEmptyArray } from 'core/utils/coreUtil'
import { routes } from 'features/routes'
import { getURLSearchParamsString } from 'features/routes/utils'
import { changeLocation } from 'features/routes'

export const findDevicesById = (deviceIdList: number[]): void => {
    if (!isExist(deviceIdList) || !isNotEmptyArray(deviceIdList)) return

    let deviceIdListToQueryString = deviceIdList.reduce((result, id, index) => {
        let isFirstItem = index === 0
        return isFirstItem ? result + id : result + ` ${id}`
    }, '')

    changeLocation({
        pathname: `/${routes.displays.path}`,
        search: getURLSearchParamsString({
            displays__query: deviceIdListToQueryString,
        }),
    })
}
