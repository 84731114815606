import { Typography } from 'atoms/Typography'
import translate from 'core/translate'
import React, { FC } from 'react'
import { styles } from './ControllerDataTable-styles'
import { ControllerDataTableProps } from './ControllerDataTable-types'

export const ControllerDataTable: FC<ControllerDataTableProps> = ({ brightness, devices, type, ipAddress, port }) => {
    return (
        <div className={styles.ControllerDataTable}>
            <div className={styles.ControllerDataTable__devices}>
                <table>
                    <thead>
                        <tr>
                            <Typography type="text" element="th">
                                {translate('order')}
                            </Typography>
                            <Typography type="text" element="th">
                                {translate('value')}
                            </Typography>
                            <Typography type="text" element="th">
                                {translate('label')}
                            </Typography>
                            <Typography type="text" element="th">
                                {translate('snowflake')}
                            </Typography>
                        </tr>
                    </thead>
                    <tbody>
                        {devices.map(({ value, label, order, snowflakeEnabledQ }) => {
                            const labelName = label && label.name ? label.name : null

                            return (
                                <tr
                                    className={styles.ControllerDataTable__device}
                                    key={`${order}_${value}_${labelName}`}
                                >
                                    <Typography type="title" element="td">
                                        {order}
                                    </Typography>
                                    <Typography type="title" element="td">
                                        {value}
                                    </Typography>
                                    <Typography type="title" element="td">
                                        {labelName}
                                    </Typography>
                                    <Typography type="title" element="td">
                                        {translate(snowflakeEnabledQ ? 'yes' : 'no')}
                                    </Typography>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className={styles.ControllerDataTable__brightness}>
                <Typography type="title" className={styles.ControllerDataTable__label}>
                    {translate('brightness')}
                </Typography>
                <Typography type="title" className={styles.ControllerDataTable__text}>
                    {brightness}
                </Typography>
            </div>
            <div className={styles.ControllerDataTable__brightness}>
                <Typography type="title" className={styles.ControllerDataTable__label}>
                    {translate('controllerType')}
                </Typography>
                <Typography type="title" className={styles.ControllerDataTable__text}>
                    {type ? type : translate('unknown')}
                </Typography>
            </div>
            <div className={styles.ControllerDataTable__brightness}>
                <Typography type="title" className={styles.ControllerDataTable__label}>
                    {translate('ipAddress')}
                </Typography>
                <Typography type="title" className={styles.ControllerDataTable__text}>
                    {ipAddress}
                </Typography>
            </div>
            <div className={styles.ControllerDataTable__brightness}>
                <Typography type="title" className={styles.ControllerDataTable__label}>
                    {translate('port')}
                </Typography>
                <Typography type="title" className={styles.ControllerDataTable__text}>
                    {port}
                </Typography>
            </div>
        </div>
    )
}
