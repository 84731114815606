import { useState } from 'react'

export type StatusTypes = 'idle' | 'loading' | 'success' | 'error'

export interface UseStatus {
    changeStatus: (status: StatusTypes) => void
    status: StatusTypes
}

export const useStatus = (): UseStatus => {
    const [status, setStatus] = useState<StatusTypes>('idle')

    const changeStatus = (status: StatusTypes) => setStatus(status)

    return {
        changeStatus,
        status,
    }
}
