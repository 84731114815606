import React, { useEffect, useState } from 'react'
import Button from '../../../blocks.simple/button/button'
import { ComPortCard } from './ComPortCard'
import { Slider } from 'molecules/Slider'
import translate from '../../../core/translate'
import styles from './comPortsStyles.jcss'

export const ComPortsSettings = ({ onChange, ports }: any) => {
    const getComPortDefaultModel = () => {
        return {
            PortName: '/dev/ttyS0',
            DataBits: 5,
            BaudRate: 9600,
            Parity: 0,
            StopBits: 1,
            FlowControl: 0,
            Action: 'PhilipsSICP',
        }
    }
    const [comPorts, setComPorts] = useState<any>([])

    const onPortChange = (index: number, changedPort: any) => {
        onChange(
            comPorts.map((port: any, _index: number) => {
                if (_index === index) {
                    return changedPort
                }

                return port
            }),
            'comPorts'
        )
    }
    const onPortDelete = (index: number) => {
        onChange(
            ports.filter((port: any, _index: number) => _index !== index),
            'comPorts'
        )
    }
    const addComPort = () => {
        onChange(ports ? [getComPortDefaultModel(), ...ports] : [getComPortDefaultModel()], 'comPorts')
    }

    useEffect(() => {
        if (ports) {
            setComPorts(ports)
        }
    }, [ports])

    return (
        <div>
            <div className={styles.buttonsWrapper}>
                <Button mod={'withBorder'} onClick={addComPort}>
                    {translate('add')}
                </Button>
            </div>
            {comPorts.length ? (
                <Slider isShowArrows={true} count={2}>
                    {comPorts.map((port: any, index: number) => (
                        <ComPortCard
                            port={port}
                            onChange={onPortChange}
                            onDelete={onPortDelete}
                            key={index}
                            index={index}
                        />
                    ))}
                </Slider>
            ) : null}
        </div>
    )
}
