import React from 'react'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import translate from 'core/translate'
import merge from 'merge'
import { cn } from 'ethcss'
import styles from './styles'
import { item } from 'theme'
import { SwitchButton } from '../../../../../atoms/SwitchButton'
import { Action, MakeAction, Permissions } from '../index'

interface ICreatePermission {
    [index: string]: any
}

interface ICreateRole {
    option: any
    optionKey: string
    actions: any
    actionType: string
    type: string
    section: string
    onChange: any
}

const FILETYPES_OPTIONS = [
    { name: 'images', fileType: 'image', type: 'fs', key: 'data.type', subkey: 'type' },
    { name: 'video', fileType: 'video', type: 'fs', key: 'data.type', subkey: 'type' },
    { name: 'music', fileType: 'audio', type: 'fs', key: 'data.type', subkey: 'type' },
    { name: 'office', fileType: 'office', type: 'fs', key: 'data.type', subkey: 'type' },
    { name: 'web_app', fileType: 'web_app', type: 'fs', key: 'data.type', subkey: 'type' },
    { name: 'links', fileType: 'link', key: 'type' },
    { name: 'text', fileType: 'text', key: 'type' },
]

const DIGITAL_SIGNAGE_ACTIONS_OPTIONS = (() => {
    const subactions = [
        /*'reboot',
        'restart',
        'shutdown',
        'updateScreenshot',
        'viewScreenshotHistory',
        'changeDateTime',
        'changeSettings',*/
        'changeVolume',
        /*'downloadDeviceInfo',
        'downloadLogs',
        'clearDeviceStorage',
        'changeNotificationSettings',
        'generalEditing',
        'manageTasksForUpdate',
        'manageTags' */
    ]

    return subactions.map((subaction: string) => ({ name: subaction, action: subaction }))
})()

const SECTIONS_RULES_AVAILABLE = ['digitalSignage', 'broadcast', 'files']

interface Actions {
    [index: string]: {
        permissionName: string
        actionId: number
        active: boolean
        additionalRules: string
    }
}

const checkAdditionalRuleProperty = (actions: Actions, action: string, property: string) => {
    return (
        actions.hasOwnProperty(action) &&
        actions[action].hasOwnProperty('additionalRules') &&
        actions[action].additionalRules.hasOwnProperty(property)
    )
}

const CreateRoleSettingsAdditionalRulesCheckbox = (p_: ICreateRole) => {
    const getCheckboxStatus = () => {
        if (!checkAdditionalRuleProperty(p_.actions, p_.actionType, p_.type)) {
            return false
        }

        const options = {
            ...p_.actions[p_.actionType].additionalRules[p_.type],
        }
        let optionField = p_.option.key

        if (p_.section === 'digitalSignage') {
            optionField = 'subactions'
        }

        return options[optionField].includes(p_.option[p_.optionKey])
    }

    const handleOnChange = () => {
        const options = {
            ...p_.actions[p_.actionType].additionalRules[p_.type],
        }
        let optionField = p_.option.key

        if (p_.section === 'digitalSignage') {
            optionField = 'subactions'
        }

        const isOptionExist = options[optionField].includes(p_.option[p_.optionKey])
        const subkey = options[p_.option.subkey]
        const isSubkeyExist = subkey && subkey.includes(p_.option[p_.option.subkey])

        if (isOptionExist) {
            const option = [...options[optionField]]

            option.splice(option.indexOf(p_.option[p_.optionKey]), 1)

            if (isSubkeyExist && !option.length) {
                subkey.splice(subkey.indexOf(p_.option[p_.option.type]))
            }

            options[optionField] = option
        } else {
            if (subkey && !isSubkeyExist) {
                subkey.push(p_.option.type)
            }
            options[optionField] = [...options[optionField], p_.option[p_.optionKey]]
        }

        p_.onChange({
            [p_.actionType]: {
                additionalRules: {
                    [p_.type]: options,
                },
            },
        })
    }

    return (
        <div className={styles.createRoleSettingsAdditionalRulesCheckboxWrapper}>
            <Checkbox checked={getCheckboxStatus()} label={translate(p_.option.name)} onClick={handleOnChange} />
        </div>
    )
}

const CreateRoleSettings = (p_: { permission: Permissions; onChange: any; actions: MakeAction }) => {
    const permission: ICreatePermission = {
        resourceId: p_.permission.id,
    }
    const currentPermission: any = p_.actions[p_.permission.id]

    p_.permission.actions.forEach((action: Action) => {
        permission[action.name] = {
            actionId: action.id,
        }

        if (SECTIONS_RULES_AVAILABLE.includes(p_.permission.name)) {
            action.rules.forEach((rule: any) => {
                permission[action.name].rules = {
                    [rule.name]: {
                        options: rule.options,
                    },
                }
            })
        }
    })

    const handleChangePermission = (action: string) => {
        p_.onChange({
            [p_.permission.id]: {
                ...currentPermission,
                [action]: {
                    ...currentPermission[action],
                    active: !currentPermission[action].active,
                },
            },
        })
    }

    const handleChangeAdditionalRule = (options: any, action: string, property = 'filterByProperty') => {
        const updateData = {
            additionalRules: {
                [property]: options,
            },
        }

        const updatedData = merge.recursive(true, currentPermission[action], updateData)
        let active = true

        if (checkAdditionalRuleProperty(currentPermission, action, property)) {
            delete updatedData.additionalRules[property]

            active = currentPermission[action].hasOwnProperty('filterByProperty')
        }

        p_.onChange({
            [p_.permission.id]: {
                ...currentPermission,
                [action]: {
                    ...updatedData,
                    active,
                },
            },
        })
    }

    const handleOnChangeAdditionalRuleOption = (updateData: any) => {
        const actions = merge.recursive(true, currentPermission, updateData)
        p_.onChange({
            [p_.permission.id]: actions,
        })
    }

    const content = []

    const permissionsSetting = [
        { action: 'read', title: translate('reading') },
        { action: 'create', title: translate('creation'), general: true },
        { action: 'update', title: translate('editing') },
        { action: 'delete', title: translate('deleting') },
    ]

    permissionsSetting.forEach((setting) => {
        if (permission[setting.action]) {
            const settingNodes = (
                <div key={`create_role_settings_${setting.action}`} className={styles.createRoleSettingsHeaderWrapper}>
                    <label htmlFor={setting.action} className={styles.createRoleSettingsHeader}>
                        <div>{setting.title}</div>
                        <div>
                            <SwitchButton
                                onChange={() => handleChangePermission(setting.action)}
                                checked={currentPermission[setting.action].active}
                            />
                        </div>
                    </label>
                </div>
            )

            if (!SECTIONS_RULES_AVAILABLE.includes(p_.permission.name)) {
                content.push(settingNodes)
            } else if (setting.general) {
                content.push(settingNodes)
            }
        }
    })

    if (permission.read && p_.permission.name === 'files') {
        const isActive = checkAdditionalRuleProperty(currentPermission, 'read', 'filterByProperty')
        let filteredFileTypesOptions = FILETYPES_OPTIONS

        const rules = permission.read.rules
        if (rules && rules.filterByProperty && rules.filterByProperty.options) {
            const options = rules.filterByProperty.options
            const filesOptions: any = []

            Object.entries(options).forEach(([key, value]) => {
                if (key === 'type' || key === 'data.type') {
                    // @ts-ignore
                    filesOptions.push(...value)
                }
            })

            filteredFileTypesOptions = FILETYPES_OPTIONS.filter((type) => filesOptions.includes(type.fileType))
        }

        content.push(
            <div key="additional_rules_files" className={styles.createRoleSettingsHeaderWrapper}>
                <label htmlFor="additional_rules_files" className={styles.createRoleSettingsHeader}>
                    <div>{translate('availableFileTypesForRole')}</div>
                    <SwitchButton
                        onChange={() => handleChangeAdditionalRule({ type: [], 'data.type': [] }, 'read')}
                        checked={isActive}
                    />
                </label>
                <div
                    className={cn(styles.createRoleSettingsAdditionalRulesWrapper, {
                        [item.disableWrapper]: !isActive,
                    })}
                >
                    {filteredFileTypesOptions.map((file) => (
                        <CreateRoleSettingsAdditionalRulesCheckbox
                            key={file.name}
                            option={file}
                            optionKey="fileType"
                            actions={currentPermission}
                            actionType="read"
                            type="filterByProperty"
                            section={p_.permission.name}
                            onChange={handleOnChangeAdditionalRuleOption}
                        />
                    ))}
                </div>
            </div>
        )
    }

    if (permission.update && p_.permission.name === 'digitalSignage') {
        const isActive = checkAdditionalRuleProperty(currentPermission, 'update', 'filterByProperty')

        content.push(
            <div key="additional_rules_digitalSignage" className={styles.createRoleSettingsHeaderWrapper}>
                <label htmlFor="additional_rules_digitalSignage" className={styles.createRoleSettingsHeader}>
                    <div>{translate('selectiveEditing')}</div>
                    <SwitchButton
                        onChange={() => handleChangeAdditionalRule({ subactions: [] }, 'update')}
                        checked={isActive}
                    />
                </label>
                <div
                    className={cn(styles.createRoleSettingsAdditionalRulesWrapper, {
                        [item.disableWrapper]: !isActive,
                    })}
                >
                    {DIGITAL_SIGNAGE_ACTIONS_OPTIONS.map((action) => (
                        <CreateRoleSettingsAdditionalRulesCheckbox
                            key={action.action}
                            option={action}
                            optionKey="action"
                            actions={currentPermission}
                            actionType="update"
                            type="filterByProperty"
                            section={p_.permission.name}
                            onChange={handleOnChangeAdditionalRuleOption}
                        />
                    ))}
                </div>
            </div>
        )
    }

    return <>{content}</>
}

export default CreateRoleSettings
