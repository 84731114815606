import { MapApi } from './MapApi'
import { isEmptyString, isExist, isNotEmptyArray, isNotEmptyString } from 'core/utils/coreUtil'
import { api } from 'core/api/ConnectionManager'
import { YandexGeocoderHttpResponse } from '../models/geocoder'
import { TMapPoint, MapLocation } from '../models/map'
import { stringToFloatNumber } from 'core/utils/convertUtil'

export class YandexMapApi extends MapApi {
    private url: string = 'https://geocode-maps.yandex.ru/1.x'

    private createGeocodeQueryString(address: string) {
        return `${this.url}/?format=json&apikey=${this.apiKey}&geocode=${address}`
    }

    private extractCoordinates(geoPosition: string): TMapPoint | null {
        if (!isNotEmptyString(geoPosition)) return null

        const [lng, lat] = geoPosition.split(' ')

        return { lat: stringToFloatNumber(lat), lng: stringToFloatNumber(lng) }
    }

    geocodeLocation(address: string): Promise<MapLocation[]> {
        return new Promise((resolve, reject) => {
            if (!isExist(address) || isEmptyString(address)) {
                resolve([])
                return
            }

            const queryString = this.createGeocodeQueryString(address)

            api.get(queryString)
                .then((res) => {
                    if (!res.text) {
                        reject()
                        return
                    }

                    try {
                        const data = JSON.parse(res.text) as YandexGeocoderHttpResponse
                        const geoObjectDataList = data?.response?.GeoObjectCollection?.featureMember

                        if (!isExist(geoObjectDataList) || !isNotEmptyArray(geoObjectDataList)) {
                            reject()
                            return
                        }

                        const mapLocationList = geoObjectDataList
                            .map((geoObject) => {
                                const geoPosition = geoObject.GeoObject?.Point?.pos
                                const name = geoObject.GeoObject?.name
                                const description = geoObject.GeoObject?.description || ' '

                                if (!isExist(geoPosition) || !isExist(name)) return null

                                let coordinates = this.extractCoordinates(geoPosition)

                                if (!isExist(coordinates)) return null

                                return { ...coordinates, name, description }
                            })
                            .filter((mapLocation) => isExist(mapLocation))

                        resolve(mapLocationList as MapLocation[])
                    } catch (err) {
                        reject()
                    }
                })
                .catch(() => reject())
        })
    }

    reverseGeocodeLocation(lat: number, lng: number): Promise<MapLocation[]> {
        return this.geocodeLocation(`${lng},${lat}`)
    }
}
