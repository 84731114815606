import React from 'react'
import Icon from 'blocks.simple/icon/icon'
import ScrollToTop from 'react-scroll-up'
import styles from './scrollUp.jcss'
import { layers, mediaJS } from 'blocks.app/config'

interface IScrollUpProps {
    isExistRightToolbar: boolean
}

const ScrollUp = (p_: IScrollUpProps) => {
    const getStyle = (p_: IScrollUpProps) => {
        if (mediaJS.tabMini.matches) {
            return styles.contentTabMini
        }
        if (mediaJS.tab.matches) {
            return styles.contentTab
        }
        if (p_.isExistRightToolbar) {
            return styles.content
        }

        return styles.contentWithoutRightToolbar
    }

    return (
        <ScrollToTop
            style={{
                zIndex: layers.sticky,
                ...getStyle(p_),
            }}
            showUnder={160}
        >
            <div className={styles.wrapper}>
                <Icon type="arrow_up_tiny" size={30} color="white" />
            </div>
        </ScrollToTop>
    )
}

ScrollUp.defaultProps = {
    isExistRightToolbar: true,
}

export default ScrollUp
