import React from 'react'
import DatepickerMethods from './datepicker.local.methods'
// @ts-ignore
import DatePicker, { registerLocale } from 'react-datepicker'
import { pt, es, ru } from 'date-fns/locale'
import { getUserLocale } from '../../core/translate'
import { cn } from 'ethcss'
import styles from './datepicker.jcss'
import moment from 'moment'
import MaskedTextInput from 'react-text-mask'

registerLocale('pt', pt)
registerLocale('es', es)
registerLocale('ru', ru)

const Datepicker = (p_: any) => {
    const { s_list, handleWeekSelect, handleChange } = DatepickerMethods(p_)
    const locale = getUserLocale()
    const list = s_list.map((date: any) => date._d)

    return (
        <DatePicker
            locale={locale}
            {...p_}
            calendarClassName={cn(styles.datepickerWrapper, { [styles.inline]: p_.inline })}
            dateFormat="dd.MM.yyyy"
            onChange={(date: any) => {
                handleChange(p_.useMoment ? moment(date) : date)
            }}
            highlightDates={list}
            ref={p_.datepickerRef ? p_.datepickerRef : () => {}}
            onWeekSelect={handleWeekSelect}
            customInput={
                <MaskedTextInput type="text" mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]} />
            }
        />
    )
}

export default Datepicker
