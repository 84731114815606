import React, { useState, useEffect } from 'react'
import { Content } from 'organisms/Content'
import CreateRoleHeader from './CreateRoleHeader'
import CreateRoleSettings from './CreateRoleSettings'
import { Tabs } from 'atoms/Tabs'
import TreeViewCatalog from 'organisms/TreeViewCatalog'
import { connect } from 'react-redux'
import translate from 'core/translate'
import moment from 'moment'
// @ts-ignore
import omitEmpty from 'omit-empty'
import deepEqual from 'fast-deep-equal'
import { routes } from 'features/routes'

import styles from './styles'
import helpers from 'core/helpers'
import { api } from 'core/api/ConnectionManager'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { isNotEmptyArray } from 'core/utils/coreUtil'
import { changeLocation } from 'features/routes'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { checkAvailableModule } from '../../../../core/helpers/routes/routes'
import { IRoleActionRule } from '../../../../core/models/Roles'

export type Action = {
    id: number
    actionId: number
    name: string
    rules: any
}

export interface Permissions {
    id: number
    resourceId: number
    name: string
    actions: Action[]
}

export interface MakeAction {
    [index: string]: string[]
}

interface SpecificRules {
    [index: string]: string[]
}

interface CatalogTypes {
    [index: string]: string
}

interface Payload {
    [index: number]: PayloadAction
}

type PayloadAction = {
    permissionName: string
    actionId: number
    active: boolean
}

const AVAILABLE_PERMISSION_SECTIONS = [
    'dashboard',
    'digitalSignage',
    'scoreboard',
    'advertisingCampaign',
    'broadcast',
    'instancesCatalog',
    'schedule',
    'timetable',
    'files',
    'role',
    'user',
    'statistics',
    'settings',
]

const CATALOG_TYPES: CatalogTypes = {
    digitalSignage: 'digitalSignages',
    files: 'files',
    broadcast: 'broadcasts',
}

const AVAILABLE_CATALOGS = ['digitalSignage', 'broadcast', 'files']
const SPECIFIC_ACTIONS_RULES: SpecificRules = {
    digitalSignage: ['update'],
    files: ['read'],
}

const SPECIFIC_ACTIONS_RULES_OPTIONS_KEYS = ['type', 'data.type', 'subactions']

const makeActionObject = (data: Permissions[]) => {
    const permissions = data.filter((permission: Permissions) =>
        AVAILABLE_PERMISSION_SECTIONS.includes(permission.name)
    )
    const actions: MakeAction = {}

    permissions.forEach((permission: Permissions) =>
        permission.actions.forEach((action: Action) => {
            actions[permission.id] = {
                ...actions[permission.id],
                [action.name]: {
                    permissionName: permission.name,
                    actionId: action.id,
                    active: false,
                },
            }
        })
    )

    return actions
}

const makeActionsWithUserPermissions = (permissions: Permissions[], allPermissions: Permissions[]) => {
    const allActions: any = makeActionObject(allPermissions)
    const copiedPermissions = helpers.deepCopy(permissions)
    copiedPermissions.forEach((permission: Permissions) => {
        permission.actions.forEach((action: Action) => {
            if (allActions[permission.id] && allActions[permission.id][action.name]) {
                const actionData: any = {
                    [action.name]: {
                        permissionName: permission.name,
                        actionId: action.id,
                        active: true,
                    },
                }

                if (action.rules && action.rules.length) {
                    action.rules.forEach((rule: IRoleActionRule) => {
                        if (rule.name === 'filterByProperty') {
                            Object.entries(rule.options).forEach(([key, value]) => {
                                if (SPECIFIC_ACTIONS_RULES_OPTIONS_KEYS.includes(key)) {
                                    if (!actionData[action.name].additionalRules) {
                                        actionData[action.name].additionalRules = {
                                            filterByProperty: {},
                                        }
                                    }

                                    actionData[action.name].additionalRules.filterByProperty[key] = value
                                } else {
                                    if (!actionData[action.name].filterByProperty) {
                                        actionData[action.name].filterByProperty = {}
                                    }
                                    actionData[action.name].filterByProperty[key] = value
                                }
                            })
                        }
                    })
                }

                allActions[permission.id] = {
                    ...allActions[permission.id],
                    ...actionData,
                }
            }
        })
    })

    return allActions
}

const generateRoleLabel = () => `${translate('newRole')} ${moment().format('HH:mm:ss DD/MM/YYYY')}`

const actionsWithoutRules = ['create']

const CreateRole = (p_: any) => {
    const [actions, setActions] = useState(() => makeActionObject(p_.permissions))
    const [initialActions, setInitialActions] = useState({})
    const [label, setLabel] = useState(generateRoleLabel())
    const [editedRole, setEditedRole] = useState<any>(null)
    const [prevPermissions, setPrevPrermissions] = useState(null)
    const query = getURLSearchParamsByLocation(p_.location)
    let roleId = query.roleId

    useEffect(() => {
        if (deepEqual(prevPermissions, p_.permissions)) {
            return
        }

        if (roleId) {
            api.send('getRoleById', { roleId }).then((role: any) => {
                setEditedRole(role)
                setLabel(role.label)
                setActions(makeActionsWithUserPermissions(role.permissions, p_.permissions))
                setInitialActions(makeActionsWithUserPermissions(role.permissions, p_.permissions))
            })
        } else {
            setLabel(generateRoleLabel())
            setActions(makeActionObject(p_.permissions))
            setInitialActions(makeActionObject(p_.permissions))
            setEditedRole(null)
        }

        setPrevPrermissions(p_.permissions)
    }, [roleId, p_.permissions, prevPermissions])

    const isAllowActionWithoutRules = (actionKey: string) => {
        return actionsWithoutRules.includes(actionKey)
    }

    const isActionMustContainRules = (permissionName: string, actionKey: string) => {
        const requiredRules = SPECIFIC_ACTIONS_RULES[permissionName]

        return requiredRules && requiredRules.includes(actionKey)
    }

    const isCatalogTreeView = (permissionName: string) => {
        return CATALOG_TYPES[permissionName]
    }

    const getPermissionsArray = () => {
        const omited = omitEmpty(actions)
        const permissions: Permissions[] = []

        Object.entries(omited).forEach(([resourceId, actions]) => {
            const model: any = {
                resourceId: parseInt(resourceId, 10),
                actions: [],
            }

            // @ts-ignore
            Object.entries(actions).forEach(([actionKey, action]) => {
                if (action.active) {
                    const actionModel: any = {
                        actionId: action.actionId,
                        rules: [],
                    }

                    if (action.hasOwnProperty('filterByProperty')) {
                        actionModel.rules.push({
                            name: 'filterByProperty',
                            options: action.filterByProperty,
                        })
                    }

                    if (action.hasOwnProperty('additionalRules')) {
                        Object.entries(action.additionalRules).forEach(([ruleKey, ruleOptions]) => {
                            actionModel.rules.push({
                                name: ruleKey,
                                options: ruleOptions,
                            })
                        })
                    }

                    if (
                        isActionMustContainRules(action.permissionName, actionKey) &&
                        !isNotEmptyArray(actionModel.rules)
                    )
                        return

                    if (!isAllowActionWithoutRules(actionKey) && !isNotEmptyArray(actionModel.rules)) {
                        delete actionModel.rules
                    }

                    if (isCatalogTreeView(action.permissionName) && !actionModel.rules) return

                    model.actions.push(actionModel)
                }
            })

            if (model.actions.length) {
                permissions.push(model)
            }
        })

        return permissions
    }

    const handleChangeActions = (payload: Payload) => {
        setActions({ ...actions, ...payload })
    }

    const handleChangeLabel = (label: string) => setLabel(label)
    const renderTabs = () => {
        const permissions = p_.permissions.filter((permission: Permissions) =>
            AVAILABLE_PERMISSION_SECTIONS.includes(permission.name)
        )

        permissions.sort(
            (a: any, b: any) =>
                AVAILABLE_PERMISSION_SECTIONS.indexOf(a.name) - AVAILABLE_PERMISSION_SECTIONS.indexOf(b.name)
        )

        return permissions.map((permission: Permissions) => (
            <div key={permission.id} tabid={`${permission.id}`} label={translate(`${permission.name}_permissions`)}>
                <CreateRoleSettings actions={actions} permission={permission} onChange={handleChangeActions} />
                {renderList(permission)}
            </div>
        ))
    }

    const handleChangePermissions = (payload: Payload, id: number) => {
        setActions((prevActions: any) => {
            let nextActions = { ...prevActions, [id]: { ...prevActions[id], ...payload } }
            return nextActions
        })
    }

    const onCheckIsDataChanged = () => {
        const omited = omitEmpty(actions)
        return !deepEqual(omited, initialActions) || (editedRole && editedRole.label !== label)
    }

    const handleSave = () => {
        if (!onCheckIsDataChanged()) {
            p_.emitError(editedRole ? 'updateRoleNothingToUpdate' : 'createRoleNothingToSave')
            return
        }

        let updatedPermissonsArray = getPermissionsArray()

        const data: any = {
            label,
            permissions: updatedPermissonsArray,
        }

        if (editedRole) {
            data.id = Number(editedRole.id)
            api.send('updateRole', data).then(() => {
                changeLocation(`/${routes.roles.path}`)
            })
        } else {
            api.send('createRole', data).then(() => {
                changeLocation(`/${routes.roles.path}`)
            })
        }
    }

    const renderList = (permission: Permissions) => {
        if (!AVAILABLE_CATALOGS.includes(permission.name)) {
            return null
        }

        return (
            <TreeViewCatalog
                type={CATALOG_TYPES[permission.name]}
                scrollParent={document.getElementById('createRoleTabContentWrapper')}
                permissions={actions[permission.id]}
                onChangePermissions={(payload: Payload) => handleChangePermissions(payload, permission.id)}
            />
        )
    }

    return (
        <Content
            title={editedRole ? translate('roleEditing') : translate('roleCreation')}
            contentClassName={styles.createRoleContentWrapper}
        >
            <div className={styles.createRoleWrapper}>
                <CreateRoleHeader
                    label={label}
                    onChange={handleChangeLabel}
                    onSave={handleSave}
                    editedRole={editedRole}
                />
                <Tabs
                    type="vertical"
                    className={styles.createRoleTabsWrapper}
                    tabClassName={styles.createRoleTab}
                    activeTabClassName={styles.createRoleTabActive}
                    tabsWrapperClassName={styles.createRoleTabWrapper}
                    contentClassName={styles.createRoleContent}
                    contentId="createRoleTabContentWrapper"
                    onChange={() => {}}
                >
                    {renderTabs()}
                </Tabs>
            </div>
        </Content>
    )
}

const CreateRoleWrapper = (p_: any) => <CreateRole {...p_} />
const mapStateToProps = (state: any) => ({
    permissions: state.user.data.permissions,
})

const mapDispatchToProps = (dispatch: any) => ({
    emitError: (error: any) => dispatch(emitError(error)),
})

const exportCreateRole = checkAvailableModule('users/roles/create')
    ? connect(mapStateToProps, mapDispatchToProps)(CreateRoleWrapper)
    : null

export default exportCreateRole
