import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { colors, IStyles, mediaCSS, text as textStyles } from 'theme'

const displaysToolbarStyles: IStyles = {
    warningTitleWrapper: {
        ...grid.w100,
        ...grid.rowCenter,
    },
    warningTitle: {
        ...grid.w100,
        ...grid.mt_micro,
        ...textStyles.center,
        ...textStyles.normalPlus,
        ...textStyles.darkBlue,
    },
    warningDescriptionWrapper: {
        ...grid.p_md,
        ...grid.w100,
        ...textStyles.center,
        ...textStyles.normal,
        ...item.greyEditorColor,
    },
    warningDescription: {
        ...item.errorColor,
        ...textStyles.low,
        ...textStyles.bold,
    },
    sidebarHeaderCaption: {
        fontWeight: 600,
        textAlign: 'center',
        padding: 10,
    },
    captionText: {
        fontSize: 16,

        [mediaCSS.desktopL]: {
            fontSize: 12,

            [mediaCSS.desktopL]: {
                fontSize: 14,
            },
        },
    },
    sidebarHeaderDropdownContent: {
        position: 'absolute',
        top: 36,
        left: 0,
        width: '100%',
        background: colors.white,
        zIndex: 3,
        opacity: 0,
        transition: 'opacity 0.5s',
        pointerEvents: 'none',
        padding: '0 44px 36px 44px',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: '0 0 5px 5px',

        [mediaCSS.desktopML]: {
            padding: '0 30px 36px 30px',

            [mediaCSS.desktopL]: {
                padding: '0 10px 36px 10px',
            },
        },
    },
    dropdownArrow: {
        position: 'absolute',
        top: 10,
        right: 20,

        [mediaCSS.desktopML]: {
            right: 6,

            [mediaCSS.desktopL]: {
                display: 'none',

                [mediaCSS.tab]: {
                    display: 'block',
                },
            },
        },
    },
    sidebarHeader: {
        position: 'relative',
        background: colors.white,
        marginBottom: 20,
        color: colors.blackLight,

        '&:hover': {
            div: {
                opacity: 1,
                pointerEvents: 'auto',
            },
        },
    },
    sidebarHeaderStatus: {
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: 26,
    },
    sidebarHeaderStatusInfo: {
        marginLeft: 28,

        [mediaCSS.desktopL]: {
            marginLeft: 8,
        },
    },
    sidebarHeaderStatusInfoCaption: {
        paddingTop: 12,
    },
    sidebarHeaderStatusInfoDescription: {
        fontStyle: 'italic',
        fontSize: 10,
        fontWeight: 300,
        lineHeight: 1.3,
        marginTop: 6,
    },
}

addStyles(displaysToolbarStyles)
export default displaysToolbarStyles
