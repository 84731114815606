import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../../theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'

const filesUploadStyles: IStyles = {
    dropzone: {
        ...grid.row,
        ...grid.full,
        border: 'none',
    },
    dropWrapper: {
        ...grid.row,
        position: 'fixed',
        ...grid.p_big,
        backgroundColor: 'rgba(0, 0, 0, .7)',
        zIndex: 2000,
        top: 0,
        left: 0,
        right: '30%',
        bottom: 0,
    },
    normalWhiteSpace: {
        whiteSpace: 'normal',
    },
    dropWrapperRight: {
        ...grid.row,
        position: 'fixed',
        ...grid.p_big,
        backgroundColor: 'rgba(0, 0, 0, .7)',
        zIndex: 2000,
        top: 0,
        left: '70%',
        right: 0,
        bottom: 0,
    },
    dropzoneContent: {
        ...grid.col,
        ...grid.colCenter,
        ...grid.full,
        ...item.rounded_full_normal,
        border: `5px dashed var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    dropzoneHide: {
        visibility: 'hidden',
        width: 0,
        height: 0,
    },
    selectFolder: {
        marginTop: '10px',
    },
}

addStyles(filesUploadStyles)
export default filesUploadStyles
