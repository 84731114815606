import { addStyles } from 'ethcss'
import './loader.css'
import grid from 'blocks.simple/grid/grid.jcss'
import { layers } from 'blocks.app/config'
import { defaultThemeStyles } from './Loader-theme'
import { IStyles } from '../../theme'

const loaderStyles: IStyles = {
    wrapper: {
        ...grid.w100,
        top: 0,
        position: 'fixed',
        zIndex: layers.progress,
    },
    item: {
        border: `4px solid var(--var-module-ui-component-loader-style-border, ${defaultThemeStyles.borderMain})`,
        borderRadius: '50%',
        borderTop: `4px solid var(--var-module-ui-component-loader-style-selected, ${defaultThemeStyles.borders})`,
        borderRight: `4px solid var(--var-module-ui-component-loader-style-selected, ${defaultThemeStyles.borders})`,
        borderBottom: `4px solid var(--var-module-ui-component-loader-style-selected, ${defaultThemeStyles.borders})`,
        width: 56,
        height: 56,
        '-webkit-animation': `loaderSpinAnimation 2s linear infinite`,
        animation: `loaderSpinAnimation 2s linear infinite`,
    },
    itemInv: {
        border: `4px solid var(--var-module-ui-component-loader-style-inv-borderMain, ${defaultThemeStyles['inv-borderMain']})`,
        borderRadius: '50%',
        borderTop: `4px solid var(--var-module-ui-component-loader-style-inv-borders, ${defaultThemeStyles['inv-borders']})`,
        borderRight: `4px solid var(--var-module-ui-component-loader-style-inv-borders, ${defaultThemeStyles['inv-borders']})`,
        borderBottom: `4px solid var(--var-module-ui-component-loader-style-inv-borders, ${defaultThemeStyles['inv-borders']})`,
        width: 56,
        height: 56,
        '-webkit-animation': `loaderSpinAnimation 2s linear infinite`,
        animation: `loaderSpinAnimation 2s linear infinite`,
    },
    itemSm: {
        width: 28,
        height: 28,
    },
}

addStyles(loaderStyles)
export default loaderStyles
