import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IDisplaysAddData {
    data: object
    isEdit: boolean
}

export interface IDisplaysAddState {
    data: object
    isEdit: boolean | null
}

const initialState: IDisplaysAddState = {
    data: {},
    isEdit: null,
}

const displaysAddSlice = createSlice({
    name: 'displays__add',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<IDisplaysAddData>) => {
            const { data, isEdit } = action.payload

            state.data = data
            state.isEdit = isEdit
        },
        clearData: (state) => {
            state.data = {}
            state.isEdit = false
        },
        setPartialData: (state, action: PayloadAction<object>) => {
            state.data = { ...state.data, ...action.payload }
        },
    },
})

const { reducer: displaysAddReducer, actions: displaysAddActions } = displaysAddSlice
export { displaysAddReducer, displaysAddActions }
