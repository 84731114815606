import React, { FC, Fragment, memo, useContext, useState } from 'react'
import { IListItemProps } from './ListItem-types'
import { styles } from './ListItem-styles'
import DndDroppable from 'blocks.app/dnd/_droppable/dnd_droppable'
import DndDraggable from 'blocks.app/dnd/_draggable/dnd_draggable'
import { CatalogWithPaginationContext } from 'organisms/CatalogWithPagination'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import { isExist } from 'core/utils/coreUtil'
import {
    ICatalogItem,
    ICatalogItemData,
    ICatalogWithPaginationOptionsList,
} from 'organisms/CatalogWithPagination/CatalogWithPagination-types'
import { TableCell } from 'atoms/Table'
import Icon from 'blocks.simple/icon/icon'
import helpers from 'core/helpers'
import { changeFilter } from 'features/routes'

const DEFAULT_NESTING_LEVEL = 0

const ListItemComponent: FC<IListItemProps> = ({
    listItem,
    minimize,
    nestingLevel = DEFAULT_NESTING_LEVEL,
    nestedList,
}) => {
    const { methods, common, catalogMethods, catalogState } = useContext(CatalogWithPaginationContext)
    const { isFolder, getDragData } = methods
    const {
        list,
        isEnableDnD = true,
        isEnableMultipleSelect = true,
        isEnableSelectInfo = true,
        isEnableTreeView = true,
        type,
        parentKey,
    } = common
    const {
        onDragEnd,
        onDragStart,
        onMoveItem,
        onResetSelectedInfo,
        removeSelectedItemById,
        addSelectedItem,
        onSelectInfo,
        onDoubleClickObject,
        onListFolderOpen,
    } = catalogMethods
    const { selectedInfo, selectedItems } = catalogState
    const { component } = list as ICatalogWithPaginationOptionsList
    const { id } = listItem

    const [isOpen, setIsOpen] = useState(false)
    const [isActiveDnd, setIsActiveDnD] = useState(false)

    const isDraggable = () => {
        if (!isEnableDnD) return false

        return typeof isFolder !== 'undefined'
    }

    const getDroppedItem = (data: ICatalogItemData): ICatalogItem | null => {
        try {
            return JSON.parse(data.dnd_item)
        } catch (err) {
            return null
        }
    }

    const changeFolder = () => {
        changeFilter({
            [helpers.prefixQuery({ name: type, field: 'query' })]: undefined,
            [parentKey]: id,
        })
    }

    const onDrop = (data: ICatalogItemData, e: React.MouseEvent) => {
        const dndItem = getDroppedItem(data)
        e.stopPropagation()

        if (!dndItem) {
            setIsActiveDnD(false)
            return
        }

        if (dndItem.id === id) {
            setIsActiveDnD(false)
            return
        }

        onMoveItem(
            dndItem,
            {
                type: 'folder',
                target: listItem,
            },
            setIsActiveDnD
        )
    }

    const isSelected = () => isExist(selectedItems.find((itemId) => itemId === id))

    const isActive = () => selectedInfo === id || isActiveDnd

    const onSelectItem = (isSelect: boolean, e: React.MouseEvent) => {
        e.stopPropagation()
        onResetSelectedInfo()

        if (isSelected()) {
            removeSelectedItemById(id)
            return
        }

        addSelectedItem(id)
    }

    const renderSelectCell = () => {
        return (
            <TableCell>
                <div className={styles.ListItem__checkbox}>
                    <Checkbox
                        onClick={onSelectItem}
                        checked={isSelected()}
                        mod={isActive() || isSelected() ? 'circleInv' : 'circle'}
                        disabled={!isEnableMultipleSelect}
                    />
                </div>
            </TableCell>
        )
    }

    const openFolder = () => {
        setIsOpen(true)
    }

    const onOpenFolder = (e: React.MouseEvent) => {
        e.stopPropagation()

        if (isOpen) {
            setIsOpen(false)
            return
        }

        onListFolderOpen(id, openFolder)
    }

    const onClickItem = (e: React.MouseEvent) => {
        if (!isEnableSelectInfo) {
            e.preventDefault()
            return
        }

        if (!helpers.isDblTouchTap(e)) {
            onSelectInfo(id, listItem[parentKey] as number | null)
            return
        }

        onResetSelectedInfo()

        if (isFolder(listItem)) {
            changeFolder()
            return
        }

        if (onDoubleClickObject) {
            onDoubleClickObject(listItem)
        }

        e.preventDefault()
        return
    }

    const renderTreeExpandButton = () => {
        return (
            <TableCell>
                {isFolder(listItem) ? (
                    <Icon onClick={onOpenFolder} type={isOpen ? 'arrow_down' : 'arrow_right'} />
                ) : null}
            </TableCell>
        )
    }

    const render = () => {
        const ListItemComponent = component

        return <ListItemComponent item={listItem} isActive={false} nestingLevel={nestingLevel} />
    }

    const getChildren = () =>
        nestedList.filter((item) => {
            return item[parentKey] === id
        })

    const renderChildren = () => {
        if (!isOpen || !isFolder(listItem)) return null

        const children = getChildren()

        return children.map((item) => {
            return (
                <ListItem
                    key={item.id}
                    nestingLevel={nestingLevel + 1}
                    listItem={item}
                    nestedList={nestedList}
                    minimize={minimize}
                />
            )
        })
    }

    const createDragData = (item: ICatalogItem) => {
        return getDragData ? getDragData(item) : JSON.stringify(item)
    }

    return (
        <Fragment>
            <DndDroppable
                tag={'tr'}
                data={createDragData(listItem)}
                dragEnabled={isDraggable()}
                dropEnabled={isFolder(listItem)}
                wrapperComponent={{ type: DndDraggable }}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDrop={onDrop}
                onClick={onClickItem}
                className={isActive() || isSelected() ? styles.ListItem__selected : ''}
            >
                {isEnableMultipleSelect && renderSelectCell()}
                {isEnableTreeView && renderTreeExpandButton()}
                {render()}
            </DndDroppable>
            {renderChildren()}
        </Fragment>
    )
}

export const ListItem = memo(ListItemComponent)
