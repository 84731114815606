import React from 'react'
import Icon from 'blocks.simple/icon/icon'
import DropMenu from 'blocks.simple/dropMenu/dropMenu'
import Button from 'blocks.simple/button/button'
import EditText from 'blocks.simple/editText/editText'
import styles from './customList__header.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { text as textStyles } from 'theme'
import translate from 'core/translate'
import { cn } from 'ethcss'

export interface ICustomListHeaderProps {
    type: string
    onAddFakeLine: any
    selectedItems: { id: number; catalogId: number }[]
    fakeLine: string
    onDelete: () => void
    onAdd: () => void
    onAnimate: (value: boolean) => void
    source?: {}
    isDelayTime: boolean
    delayTime: number
    webAppTitle: string
    title: string
    onChangeCustomField: (value: string, type: string) => void
    onBlurCustomField: (value: string, type: string) => void
    isWebAppTitle: boolean
    onChangeDelayTime: (time: number) => void
    onBlurDelayTime: (time: number) => void
}

class CustomListHeader extends React.Component<ICustomListHeaderProps> {
    state = {
        active: [],
    }

    deleteItem = () => {
        const p_ = this.props

        return {
            id: 'delete',
            icon: 'delete',
            disabled: p_.selectedItems.length === 0 && !p_.fakeLine,
            color: 'grey',
            tooltip: {
                title: translate('delete'),
            },
            children: (
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        this.setState({ active: [] })
                        p_.onDelete()
                    }}
                    className={cn(grid.colCenter, grid.p_mini)}
                >
                    <div className={cn(grid.w100, grid.mb_mini, textStyles.center)}>{translate('deleteApprove')}</div>
                    <div className={cn(grid.row, grid.justify)}>
                        <Button
                            type="submit"
                            mod="fill"
                            className={cn(grid.mr_mini)}
                            indentSize={'mini'}
                            textSize={'mini'}
                            rounded={'full_normal'}
                        >
                            {translate('yes')}
                        </Button>
                        <Button
                            animation={false}
                            onClick={() => this.setState({ active: [] })}
                            indentSize={'mini'}
                            textSize={'mini'}
                            rounded={'full_normal'}
                        >
                            {translate('no')}
                        </Button>
                    </div>
                </form>
            ),
        }
    }

    render() {
        const s_ = this.state
        const p_ = this.props

        return (
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <div className={styles.tools}>
                        <div className={cn(grid.rowCenter)}>
                            <div className={grid.mr_md}>
                                <Icon
                                    onClick={p_.onAdd}
                                    type={'plus_circle'}
                                    disabled={!p_.source}
                                    tooltip={{ title: translate('add') }}
                                    color={'grey'}
                                />
                            </div>
                            <div className={styles.deleteButton}>
                                <DropMenu
                                    active={s_.active}
                                    onChange={(active) => this.setState({ active })}
                                    items={[this.deleteItem()]}
                                    gray={true}
                                />
                            </div>
                        </div>
                        <div />
                    </div>
                    {p_.isDelayTime && (
                        <div className={cn(grid.row, grid.justify, grid.mt_md, styles.delayTime)}>
                            <div />
                            <div className={cn(grid.row, grid.normalCenter, grid.mr_md)}>
                                <div className={cn(grid.mr_md, textStyles.black)}>{translate('delayTime')}</div>
                                <div>
                                    <EditText
                                        indentSize={'mini'}
                                        rounded={'full_md'}
                                        type={'number'}
                                        className={cn(textStyles.center)}
                                        value={p_.delayTime / 1000}
                                        onChange={(delayTime: number) => p_.onChangeDelayTime(delayTime)}
                                        onBlur={(delayTime: number) => p_.onBlurDelayTime(delayTime)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {p_.isWebAppTitle && (
                        <div className={cn(grid.row, grid.justify, grid.mt_md, styles.delayTime)}>
                            <div className={cn(grid.row, grid.normalCenter, grid.ml_md)}>
                                <div className={cn(grid.mr_md, textStyles.black)}>{translate('title')}</div>
                                <div>
                                    <EditText
                                        indentSize={'mini'}
                                        rounded={'full_md'}
                                        className={cn(textStyles.center)}
                                        value={p_.webAppTitle}
                                        onChange={(title: string) => p_.onChangeCustomField(title, 'title')}
                                        onBlur={(title: string) => p_.onBlurCustomField(title, 'title')}
                                    />
                                </div>
                            </div>
                            <div />
                        </div>
                    )}
                    {p_.title && <div className={cn(grid.rowCenter, grid.p_md)}>{p_.title}</div>}
                </div>
            </div>
        )
    }
}

export default CustomListHeader
