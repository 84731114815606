import React from 'react'
import { YMInitializer } from 'react-yandex-metrika'
import helpers from 'core/helpers'
import { default as ym_ } from 'react-yandex-metrika'

//const accounts = [47509558]
const accounts = [90585293]
const disableMetrika = window.spconfig.disableMetrika || window.location.host === 'localhost:8080'
export function ym(value_1: string, value_2: string) {
    if (helpers.isProd() && !disableMetrika) {
        // @ts-ignore
        ym_.apply(undefined, arguments)
    }
}

const YandexMetrika = () => {
    return helpers.isProd() && !disableMetrika ? (
        <YMInitializer
            accounts={accounts}
            options={{
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
                trackHash: true,
            }}
        />
    ) : null
}

export default YandexMetrika
