import { colors } from 'theme'

export const defaultThemeStyles = {
    background: colors.blackLight,
    colsRight: colors.white,
    toggleActive: colors.blackSemi,
    toggleDisable: colors.blackLight,
    dropzone: colors.grey,
    timeLine: colors.white,
    controlsBackground: 'rgba(0, 0, 0, 0.45)',
    mediaUpload: colors.white,
}
