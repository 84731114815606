import React, { useState } from 'react'
import translate from '../../core/translate'
import Button from '../../blocks.simple/button/button'
import styles from './cookieUsageWarning.jcss'
import Icon from '../../blocks.simple/icon/icon'

export const CookieUsageWarning = () => {
    const [isConfirmed, setIsConfirmed] = useState<any>(localStorage.getItem('cookieConfirmed'))
    const [showDetails, setShowDetails] = useState<boolean>(false)

    const confirmCookie = () => {
        localStorage.setItem('cookieConfirmed', 'true')
        setIsConfirmed(true)
    }

    return !isConfirmed ? (
        <>
            {showDetails && (
                <div className={styles.warningBlockDetails}>
                    <div className={styles.warningBlockDetailsInner}>
                        <div className={styles.warningBlockDetailsClose}>
                            <Icon type={'close'} color={'black'} size={16} onClick={() => setShowDetails(false)} />
                        </div>
                        <div>{translate('cookiePolicyDetails')}</div>
                        <div className={styles.confirmButton}>
                            <Button mod="fill" onClick={confirmCookie}>
                                {translate('gotIt')}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            <div className={styles.warningBlock}>
                <div>{translate('weUseCookie')}</div>
                <div className={styles.moreDetails} onClick={() => setShowDetails(true)}>
                    {translate('moreDetails')}
                </div>
                <div className={styles.confirmButton}>
                    <Button mod="fill" onClick={confirmCookie}>
                        {translate('gotIt')}
                    </Button>
                </div>
            </div>
        </>
    ) : null
}
