import translate from '../../core/translate'
import Content from '../../blocks.app/content/content'
import React from 'react'
import styles from './notificationСenter.jcss'
import Icon from '../../blocks.simple/icon/icon'
import { cn } from 'ethcss'
import { Steps } from 'atoms/Steps/Steps-view'
import grid from 'blocks.simple/grid/grid.jcss'
import { INotification, NotificationСenterHooks } from './NotificationСenterHooks'
import moment from 'moment'
import ModalAccept from 'blocks.simple/modal/_accept/modal_accept'
import ExportHistory from 'pages/history/history'
import NotificationFilter from './__filter/notificationFilter__filter'
import { colors } from 'theme/colors'
import { NotificationEmails } from './notificationEmails/NotificationEmails'
import { useDispatch } from 'react-redux'
import { userActions } from 'blocks.app/user/user.state'
import filesStyles from 'pages/files/files.jcss'

export const NotificationСenter = (p_: any) => {
    const {
        state,
        setState,
        getCardBg,
        isInformationType,
        getCardIcon,
        deleteNotification,
        filterFields,
        setFilterFields,
        modelText,
        isDeletePermission,
    } = NotificationСenterHooks()

    const dispatch = useDispatch()

    const getIconType = (tab: string) => {
        switch (tab) {
            case 'notificationCenter':
                return 'notifications_empty'
            case 'usersActionsHistory':
                return 'history'
            case 'notificationEmails':
                return 'mail'
            default:
                return ''
        }
    }

    const renderContent = (tab: string) => {
        switch (tab) {
            case 'notificationCenter':
                return (
                    <Content title={translate('notificationCenter')} wrapperClassName={styles.wrapperClassName}>
                        <div className={cn(grid.pb_md)}>
                            <NotificationFilter
                                filterFields={filterFields}
                                setFilterFields={setFilterFields}
                                setState={setState}
                                state={state}
                            />
                        </div>
                        {state.notifications &&
                            state.notifications.map((notification: INotification, index: number) => (
                                <div key={notification.id}>
                                    <div
                                        className={cn(styles.notificationRow)}
                                        style={{
                                            backgroundColor: getCardBg(notification.type),
                                            color: isInformationType(notification.type),
                                        }}
                                    >
                                        <Icon
                                            type={getCardIcon(notification.category, notification.type)}
                                            size={20}
                                            color={isInformationType(notification.type)}
                                        />
                                        <div className={styles.cardText}>
                                            <div className={cn(grid.row, styles.bold, grid.pb_micro)}>
                                                {state.notifications && state.notifications[index].status === 'unread' && (
                                                    <div className={styles.unreadCircle}>
                                                        <Icon type={'unreadCircle'} size={10} color={'white'} />
                                                    </div>
                                                )}
                                                {modelText(notification, 'title')}
                                            </div>
                                            <div className={cn(styles.opacity, styles.nameText)}>
                                                {modelText(notification, 'name') !== '-' &&
                                                    translate('name') + ': ' + modelText(notification, 'name')}
                                            </div>
                                            <div
                                                className={cn(styles.about)}
                                                onClick={() =>
                                                    setState((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            activeModalId: notification.id,
                                                            isModalActive: true,
                                                        }
                                                    })
                                                }
                                            >
                                                <span className={cn(styles.opacity, styles.pointer)}>
                                                    {translate('moreDetails')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className={styles.rightDelete}>
                                            <Icon
                                                disabled={!isDeletePermission}
                                                type={'deleteBasket'}
                                                size={16}
                                                color={isInformationType(notification.type)}
                                                onClick={() => deleteNotification(notification.id)}
                                            />
                                        </div>
                                        <div className={styles.rightDate}>
                                            {moment(notification.createdAt).format('HH:mm DD.MM.YYYY')}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </Content>
                )
            case 'usersActionsHistory':
                return <ExportHistory location={p_.location} />
            case 'notificationEmails':
                return <NotificationEmails />
        }
    }

    return (
        <div>
            <Content wrapperClassName={styles.wrapperClassName}>
                <Steps className={styles.tabs} active={state.selectedTab} mod={'accent'}>
                    {state.tabs.map((tab: string, index: number) => (
                        <div
                            key={`notificationCenter__tab_${index}`}
                            id={tab}
                            onClick={() =>
                                setState((prevState) => {
                                    return {
                                        ...prevState,
                                        selectedTab: tab,
                                    }
                                })
                            }
                        >
                            <Icon
                                type={getIconType(tab)}
                                color={state.selectedTab === tab ? colors.white : colors.blackLight}
                                size={18}
                                className={styles.tabIcon}
                            />
                            {translate(tab)}
                        </div>
                    ))}
                </Steps>
            </Content>
            <div>{renderContent(state.selectedTab)}</div>
            {state.isModalActive && (
                <div>
                    <ModalAccept
                        open={state.isModalActive}
                        onClose={() =>
                            setState((prevState) => {
                                return {
                                    ...prevState,
                                    isModalActive: false,
                                }
                            })
                        }
                        modalInnerClassName={styles.modal}
                    >
                        {state.notificationInModal && (
                            <>
                                <div
                                    className={styles.wrapper}
                                    style={{ backgroundColor: getCardBg(state.notificationInModal.type) }}
                                >
                                    <div className={styles.headerModal}>
                                        <Icon
                                            type={getCardIcon(
                                                state.notificationInModal.category,
                                                state.notificationInModal.type
                                            )}
                                            color={
                                                state.notificationInModal.type === 'information'
                                                    ? colors.blackLight
                                                    : colors.white
                                            }
                                            size={18}
                                        />
                                        <div
                                            className={cn(grid.pl_md)}
                                            style={{
                                                color:
                                                    state.notificationInModal.type === 'information'
                                                        ? colors.blackLight
                                                        : colors.white,
                                            }}
                                        >
                                            {modelText(state.notificationInModal, 'title')}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.textModal}>
                                    <div className={cn(grid.pb_md)}>
                                        <div className={cn(grid.pb_md, grid.row, styles.opacity9)}>
                                            <div className={cn(grid.pr_md, styles.nameText)}>
                                                {modelText(state.notificationInModal, 'name') !== '-' &&
                                                    translate('name') +
                                                        ': ' +
                                                        modelText(state.notificationInModal, 'name')}
                                            </div>
                                            <div className={grid.pr_md}>
                                                {moment(state.notificationInModal.createdAt).format('HH:mm DD.MM.YYYY')}
                                            </div>
                                            <div className={grid.pr_md}>
                                                <b>{translate(state.notificationInModal.type)}</b>
                                            </div>
                                        </div>
                                        <div className={cn(grid.pb_md, styles.opacity)}>
                                            {modelText(state.notificationInModal, 'text') ===
                                            translate('passwordUpdate') ? (
                                                <div
                                                    className={filesStyles.link}
                                                    onClick={() => dispatch(userActions.openUserEditPanel())}
                                                >
                                                    {translate('changeUserPassword')}
                                                </div>
                                            ) : (
                                                modelText(state.notificationInModal, 'text')
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </ModalAccept>
                </div>
            )}
        </div>
    )
}
