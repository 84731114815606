import { addStyles } from 'ethcss'
import { IStyles } from '../../theme'

const highlightStyles: IStyles = {
    wrapper: {
        '& .php, .hljs-tag': {
            color: '#8840ac',
        },
        '& .hljs-attr': {
            color: '#b38754',
        },
        '& .hljs-string': {
            color: '#585bdf',
        },
    },
}

addStyles(highlightStyles)
export default highlightStyles
