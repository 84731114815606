import React, { Fragment, FunctionComponent, memo } from 'react'
import Collapse from 'blocks.simple/collapse/collapse'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { grid, item } from 'theme'
import { IAuthenticationSettingsComponent } from './AuthenticationSettings-types'
import Button from 'blocks.simple/button/button'
import { ConfirmEmailDialog } from 'organisms/ConfirmEmailDialog'
import { useConfirmEmailCode, useConfirmationEmail } from './AuthenticationSettings-hooks'
import { authenticationSettingsStyles } from './AuthenticationSettings-styles'
import EditText from 'blocks.simple/editText/editText'
import { isExist, isNotEmptyString, trimSegmentsFromString } from 'core/utils/coreUtil'
import { SwitchButton } from 'atoms/SwitchButton'
import Icon from 'blocks.simple/icon/icon'
import { colors } from 'theme'
import { useLocation } from 'react-router'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { routes } from 'features/routes'
import { useDispatch } from 'react-redux'
import { updateUserSettings } from 'blocks.app/user/user.state'
import styles from '../settings.jcss'

const AuthenticationSettingsComponent: FunctionComponent<IAuthenticationSettingsComponent> = ({
    twoFactorAuth = 'off',
    isEmailConfirmed = false,
}) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const query = getURLSearchParamsByLocation(location)
    const confirmEmailCode = query[`${routes.confirmEmail.prefix}_code`] || null

    const { changeCode, code } = useConfirmEmailCode()

    const {
        prefix,
        isSendingEmail,
        isModalOpen,
        isLocalEmailConfirmed,
        closeModal,
        status,
        sendConfirmationEmail,
        sendConfirmationEmailCode,
    } = useConfirmationEmail(confirmEmailCode)

    const changeTwoFactorAuth = () => {
        if (!isEmailConfirmedSuccessfully()) return

        dispatch(
            updateUserSettings({ data: { twoFactorAuth: twoFactorAuth === 'on' ? 'off' : 'on' }, isSaveSettings: true })
        )
    }

    const renderTwoFactorAuthText = () => {
        return twoFactorAuth === 'on' ? translate('confirmEmailOn') : translate('confirmEmailOff')
    }

    const handleConfirmEmailDialogClose = () => {
        closeModal()
    }

    const handleSendConfirmationEmail = () => {
        if (isSendingEmail) return

        sendConfirmationEmail()
    }

    const handleRepeatConfirmationEmail = () => {
        closeModal()
        sendConfirmationEmail()
    }

    const getRawCodeString = (code: string) => {
        return trimSegmentsFromString(code, [`${prefix}-`, '_'])
    }

    const handleSendConfirmationEmailCode = () => {
        if (!isExist(code) || !isNotEmptyString(getRawCodeString(code))) return

        sendConfirmationEmailCode(code)
    }

    const renderConfirmEmailText = () => {
        return isEmailConfirmedSuccessfully() ? (
            <Fragment>
                <span className={authenticationSettingsStyles.authenticationSettings__text}>
                    {translate('confirmEmailSuccessTitle')}
                </span>
                <Icon
                    type="checked"
                    color={colors.success}
                    size="15"
                    containerClass={authenticationSettingsStyles.authenticationSettings__icon}
                />
            </Fragment>
        ) : (
            <Fragment>{translate('confirmEmailText')}</Fragment>
        )
    }

    const handleChangeCode = (code: string) => {
        let updatedCode = getRawCodeString(code)
        changeCode(updatedCode)
    }

    const isEmailConfirmedSuccessfully = () => {
        return isEmailConfirmed || isLocalEmailConfirmed
    }

    const isEnableSendCodeButton = () => {
        return !!(code && isNotEmptyString(getRawCodeString(code)))
    }

    const isEnableSendEmailButton = () => {
        return !isSendingEmail && !isEmailConfirmedSuccessfully()
    }

    const renderConfirmEmailDialogContent = () => {
        switch (status) {
            case 'success':
                return (
                    <div>
                        <div className={authenticationSettingsStyles.authenticationSettings__subText}>
                            {renderTwoFactorAuthText()}
                        </div>
                        <SwitchButton
                            checked={twoFactorAuth === 'on'}
                            onChange={changeTwoFactorAuth}
                            disabled={!isEmailConfirmedSuccessfully()}
                        />
                    </div>
                )
            case 'error':
                return (
                    <div className={authenticationSettingsStyles.authenticationSettings__inner}>
                        <Button onClick={handleRepeatConfirmationEmail}>{translate('send')}</Button>
                    </div>
                )
        }
    }

    return (
        <Fragment>
            <Collapse
                title={() => translate('twoFactorAuth')}
                className={cn(grid.mb_md, styles.background, authenticationSettingsStyles.authenticationSettings)}
            >
                <div className={cn(authenticationSettingsStyles.authenticationSettings__row)}>
                    <Button onClick={handleSendConfirmationEmail} disabled={!isEnableSendEmailButton()} mod="fill">
                        {translate('confirmEmail')}
                    </Button>
                    <div className={cn(authenticationSettingsStyles.authenticationSettings__content, item.ellipsis)}>
                        {renderConfirmEmailText()}
                    </div>
                </div>
                {!isEmailConfirmedSuccessfully() && prefix && (
                    <div className={cn(authenticationSettingsStyles.authenticationSettings__row)}>
                        <EditText
                            containerClass={cn(authenticationSettingsStyles.authenticationSettings__input)}
                            onChange={handleChangeCode}
                            placeholder={translate('confirmEmailPlaceholder')}
                            mask={`${prefix}-****`}
                        />
                        <Button
                            mod="fill"
                            onClick={handleSendConfirmationEmailCode}
                            disabled={!isEnableSendCodeButton()}
                        >
                            {translate('confirmEmailCode')}
                        </Button>
                    </div>
                )}
                <div className={cn(authenticationSettingsStyles.authenticationSettings__row)}>
                    <SwitchButton
                        disabled={!isEmailConfirmedSuccessfully()}
                        checked={twoFactorAuth === 'on'}
                        onChange={changeTwoFactorAuth}
                    />
                </div>
            </Collapse>
            <ConfirmEmailDialog open={isModalOpen} status={status} onClose={handleConfirmEmailDialogClose}>
                {renderConfirmEmailDialogContent()}
            </ConfirmEmailDialog>
        </Fragment>
    )
}

export const AuthenticationSettings = memo(AuthenticationSettingsComponent)
