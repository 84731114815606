import React, { useState, useEffect } from 'react'
import DisplaysToolbarContentMethods from './displays__toolbarContent.local.methods'
import Dotdotdot from 'react-dotdotdot'
import Icon from 'blocks.simple/icon/icon'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import DisplaysScreenShots from '../__screenShots/displays__screenShots'
import NotifyBadge from 'blocks.simple/notifyBadge/notifyBadge'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import Tag from 'blocks.simple/tag/tag'
import RangeVolume from 'blocks.simple/range/_volume/range_volume'
import DropMenu from 'blocks.simple/dropMenu/dropMenu'
import Button from 'blocks.simple/button/button'
import ModalAccept from 'blocks.simple/modal/_accept/modal_accept'
import enhanceWithClickOutside from 'react-click-outside'
import toolbar from '../__toolbar/displays__toolbar.methods'
import helpers from 'core/helpers'
import displaysHelpers from 'core/helpers/_digitalSignages'
import { changeFilter, routes } from 'features/routes'
import { api } from 'core/api/ConnectionManager'
import { cn } from 'ethcss'
import styles from './displays__toolbarContent.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { text as textStyles } from 'theme'
import translate from 'core/translate'
import item from 'blocks.simple/item/item.jcss'
import { Tooltip } from 'atoms/Tooltip'
import { Typography } from 'atoms/Typography'
import { getURLSearchParamsString } from 'features/routes/utils'
import { changeLocation } from 'features/routes'
import { isExist } from 'core/utils/coreUtil'
import { connect } from 'react-redux'
import { disableIdentification, displaysActions, enableIdentification } from '../displays.state'
import { ISelectedInfo } from '../__info/displays__info.local.methods'
import { IDevice } from '../../../core/models/DigitalSignage'

const getDigitalSignageAndGroupIds = (list: { id: number; type: string }[]) => {
    const id: number[] = []
    const groupId: number[] = []

    list.forEach((item) => {
        item.type === 'group' ? groupId.push(item.id) : id.push(item.id)
    })

    return { id, groupId }
}

const configureScheduleLocations = (selectedInfo: { id: number; name: string }, type = 'digitalSignages') =>
    JSON.stringify({
        [type]: [
            {
                id: selectedInfo.id,
                name: selectedInfo.name,
            },
        ],
    })

const getClientVersion = ({ newerClientVersion }: any = {}, menuItem: string) => {
    if (newerClientVersion && menuItem === 'information') {
        return (
            <div className={cn(grid.ml_micro, grid.row, grid.normalCenter, grid.fullHeight)}>
                <NotifyBadge label={newerClientVersion} />
            </div>
        )
    }
}

const getMenuDisabledPermission = (entrie: ISelectedInfo, type: string) => {
    const helperModel: {
        [index: string]: string
    } = {
        key: 'digitalSignage',
        action: 'update',
    }

    switch (type) {
        case 'ds_date':
            helperModel.subaction = 'changeDateTime'
            return !helpers.isAvailableAction(entrie, helperModel)

        case 'notifications_settings':
            helperModel.subaction = 'changeNotificationSettings'
            return !helpers.isAvailableAction(entrie, helperModel)

        case 'edit':
            helperModel.subaction = 'generalEditing'
            return !helpers.isAvailableAction(entrie, helperModel)

        case 'settings':
            helperModel.subaction = 'changeSettings'
            return !helpers.isAvailableAction(entrie, helperModel)

        default:
            return false
    }
}

let volumeTimeout: any = null

export const DeviceVolume = ({ device }: { device: { volume: number; type: string; id: number } }) => {
    const [volumeValue, setVolumeValue] = useState(() => device.volume || 0)

    useEffect(() => {
        if (!volumeTimeout) {
            setVolumeValue(device.volume)
        }
    }, [device.volume])

    const changeVolume = (value: number) => {
        clearTimeout(volumeTimeout)

        setVolumeValue(value)
        volumeTimeout = setTimeout(() => {
            clearTimeout(volumeTimeout)
            api.send('changeVolume', { [`${device.type}Id`]: device.id, volume: value }, { hideLoader: true })
        }, 600)
    }

    return (
        <>
            <RangeVolume volume={volumeValue} onChange={changeVolume} />
            <div className={styles.volumeDependencyInfo}>{translate('volumeDependencyInfo')}</div>
        </>
    )
}

interface IMenuItemComponentProps {
    selectedInfo?: ISelectedInfo
    afterAction?: () => void
    data: { name: string }
    openModal: (data: { modalState: string; modalOptions: { [index: string]: any } }) => void
    enableIdentificationAction?: any
    disableIdentificationAction?: any
    selectedItems?: { status: string }[]
    onClick?: any
    dropdown?: { id: string; text: string }
    beforeAction?: () => void
    rebootDeviceSelectedItems?: { status: string }[]
}

export interface ISelectedItems {
    id: number
    type: string
    status: string
}

class MenuItemComponent extends React.Component<IMenuItemComponentProps> {
    static defaultProps = {
        beforeAction: () => {},
    }

    state = {
        active: [],
    }
    afterAction = () => {
        const p_ = this.props

        if (p_.afterAction) {
            p_.afterAction()
        }
    }

    isOnline = () => {
        const p_ = this.props
        return p_.selectedInfo && this.getSelectInfoStatus(p_.selectedInfo) === 'online'
    }

    isDisableInOfflineWithTooltip = () => {
        const p_ = this.props

        switch (p_.data.name) {
            case 'playback_state':
                return true
            case 'play_content':
                return true
            default:
                return false
        }
    }

    isEnableOfflineTooltip = () => {
        return !this.isOnline() && this.isDisableInOfflineWithTooltip()
    }

    isDisableMenuItem = () => {
        return !this.isOnline() && this.isDisableInOfflineWithTooltip()
    }

    getSelectInfoStatus = (selectedInfo: ISelectedInfo) => {
        return selectedInfo ? selectedInfo.status : null
    }

    getDisplayActions = () => {
        const { openModal, enableIdentificationAction, disableIdentificationAction } = this.props

        return {
            remoteDeviceControl: () => openModal({ modalState: 'remoteDeviceControl', modalOptions: {} }),
            edit: (selectedInfo: ISelectedInfo) => toolbar.edit(selectedInfo),
            information: () => openModal({ modalState: 'infoDisplay', modalOptions: {} }),
            settings: () => openModal({ modalState: 'displaySettings', modalOptions: {} }),
            schedule: (selectedInfo: ISelectedInfo) =>
                changeLocation({
                    pathname: `/${routes.schedule.path}`,
                    search: getURLSearchParamsString({
                        scheduleData: configureScheduleLocations(selectedInfo),
                    }),
                }),
            reboot_device: (selectedInfo: ISelectedInfo, selectedItems: { id: number }[]) => {
                const id = selectedItems.map((selectedItem) => selectedItem.id)

                api.send('rebootDisplay', { id })
            },
            restart_application: (selectedInfo: ISelectedInfo, selectedItems: ISelectedItems[]) => {
                const { id, groupId } = getDigitalSignageAndGroupIds(selectedItems)
                const data: any = { id }
                if (groupId && groupId.length) {
                    data.groupId = groupId
                }
                api.send('digitalSignageSendRestartApp', data)
            },
            switchOff: (selectedInfo: ISelectedInfo, selectedItems: ISelectedItems[]) => {
                const id = selectedItems.map((selectedItem) => selectedItem.id)

                api.send('shutdownDisplay', { id })
            },
            screenshot: (selectedInfo: ISelectedInfo, selectedItems: ISelectedItems[]) => {
                const { id, groupId } = getDigitalSignageAndGroupIds(selectedItems)

                id.length && api.send('updateScreenshotCb', { id })
                groupId.length && api.send('updateScreenshotCb', { groupId })
            },
            notifications_settings: () => openModal({ modalState: 'displayNotification', modalOptions: {} }),
            ds_date: () =>
                openModal({ modalState: 'dateTimeDisplay', modalOptions: { mod: 'clear', showCloseIcon: false } }),
            identification_on: (selectedInfo: ISelectedInfo, selectedItems: ISelectedItems[]) => {
                return enableIdentificationAction(
                    [selectedInfo, ...(selectedItems || [])].filter((item) => isExist(item))
                )
            },
            identification_off: (selectedInfo: ISelectedInfo, selectedItems: ISelectedItems[]) =>
                disableIdentificationAction([selectedInfo, ...(selectedItems || [])].filter((item) => isExist(item))),
            android_permissions: () =>
                openModal({ modalState: 'permissions', modalOptions: { mod: 'clear', showCloseIcon: false } }),
            installedApplicationList: () => {
                openModal({
                    modalState: 'displayInstalledApplicationList',
                    modalOptions: { mod: 'clear', showCloseIcon: false },
                })
            },
            downloadContent: () => {
                openModal({
                    modalState: 'downloadContent',
                    modalOptions: { mod: 'clear', showCloseIcon: false },
                })
            },
            playback_state: () => {
                return openModal({
                    modalState: 'displayAudioControll',
                    modalOptions: {
                        mod: 'clear',
                        showCloseIcon: true,
                        closeIconSize: 30,
                        closeIconColor: 'white',
                    },
                })
            },
            play_content: () => {
                return openModal({
                    modalState: 'displayPlayAudioControll',
                    modalOptions: {
                        mod: 'clear',
                        showCloseIcon: true,
                        closeIconSize: 30,
                        closeIconColor: 'white',
                    },
                })
            },
            changeServer: () => openModal({ modalState: 'changeServer', modalOptions: {} }),
        }
    }

    isEnableMenuItemErrorStatus(items: { status: string }[]) {
        let result = items && items.map((item) => item.status === 'error')
        return result && result.every((item) => item)
    }

    isEnableMenuItemOnlineDisplayOff(items: { status: string }[]) {
        let result = items && items.map((item) => item.status === 'online_display_off')
        return result && result.every((item) => item)
    }

    isEnableMenuItemErrorAndOnlineDisplayOffStatus(items: { status: string }[]) {
        let resultError = items && items.some((item) => item.status === 'error')
        let resultOnlineDisplayOff = items && items.some((item) => item.status === 'online_display_off')
        let resultOffline = items && items.some((item) => item.status === 'offline')
        return resultError && resultOnlineDisplayOff && !resultOffline
    }

    isEnableMenuItemOnlineAndOnlineDisplayOffStatus(items: { status: string }[]) {
        let resultOnline = items && items.some((item) => item.status === 'online')
        let resultOnlineDisplayOff = items && items.some((item) => item.status === 'online_display_off')
        let resultOffline = items && items.some((item) => item.status === 'offline')
        return resultOnline && resultOnlineDisplayOff && !resultOffline
    }

    isEnableMenuItemErrorAndOnlineStatus(items: { status: string }[]) {
        let resultError = items && items.some((item) => item.status === 'error')
        let resultOnline = items && items.some((item) => item.status === 'online')
        let resultOffline = items && items.some((item) => item.status === 'offline')
        return resultError && resultOnline && !resultOffline
    }

    isEnableMenuItemErrorAndOnlineStatusAndOnlineDisplayOffStatus(items: { status: string }[]) {
        let resultError = items && items.some((item) => item.status === 'error')
        let resultOnline = items && items.some((item) => item.status === 'online')
        let resultOnlineDisplayOff = items && items.some((item) => item.status === 'online_display_off')
        let resultOffline = items && items.some((item) => item.status === 'offline')
        return resultError && resultOnlineDisplayOff && resultOnline && !resultOffline
    }

    someStatusOffline(items: { status: string }[]) {
        return items && items.some((item) => item.status === 'offline')
    }

    render() {
        const p_ = this.props
        const disabled = (p_.selectedItems && p_.selectedItems.length === 0) || this.isDisableMenuItem()
        // @ts-ignore
        const onClickAction = this.getDisplayActions()[p_.data.name]
            ? () => {
                  // @ts-ignore
                  return this.getDisplayActions()[p_.data.name](p_.selectedInfo, p_.selectedItems)
              }
            : p_.onClick
            ? p_.onClick
            : () => {}
        // @ts-ignore
        const onClickActionRebootForErrorStatus = this.getDisplayActions()[p_.data.name]
            ? () => {
                  // @ts-ignore
                  return this.getDisplayActions()[p_.data.name](p_.selectedInfo, p_.rebootDeviceSelectedItems)
              }
            : p_.onClick
            ? p_.onClick
            : () => {}
        const s_ = this.state

        if (!p_.dropdown && p_.data.name !== 'reboot_device') {
            return (
                <Tooltip
                    isEnabled={this.isEnableOfflineTooltip()}
                    title={translate('availableInOnline')}
                    position="left"
                >
                    <div
                        onClick={
                            !disabled
                                ? (e) => {
                                      if (p_.beforeAction) {
                                          p_.beforeAction()
                                      }
                                      onClickAction(e)
                                      this.afterAction()
                                  }
                                : () => {}
                        }
                        className={cn(styles.menuItem, {
                            [item.disabled]: disabled,
                            [styles.menuItemNormal]: p_.data.name === 'ds_date',
                        })}
                    >
                        {translate(p_.data.name)}
                        {getClientVersion(p_.selectedInfo, p_.data.name)}
                    </div>
                </Tooltip>
            )
        } else if (!p_.dropdown && p_.data.name === 'reboot_device') {
            return (
                <Tooltip
                    isEnabled={this.isEnableOfflineTooltip()}
                    title={translate('availableInOnline')}
                    position="left"
                >
                    <div
                        onClick={
                            (p_.rebootDeviceSelectedItems &&
                                this.isEnableMenuItemErrorStatus(p_.rebootDeviceSelectedItems)) ||
                            (p_.rebootDeviceSelectedItems &&
                                this.isEnableMenuItemErrorAndOnlineStatusAndOnlineDisplayOffStatus(
                                    p_.rebootDeviceSelectedItems
                                )) ||
                            (p_.rebootDeviceSelectedItems &&
                                this.isEnableMenuItemOnlineDisplayOff(p_.rebootDeviceSelectedItems)) ||
                            (p_.rebootDeviceSelectedItems &&
                                this.isEnableMenuItemErrorAndOnlineStatus(p_.rebootDeviceSelectedItems)) ||
                            (p_.rebootDeviceSelectedItems &&
                                this.isEnableMenuItemErrorAndOnlineDisplayOffStatus(p_.rebootDeviceSelectedItems)) ||
                            (p_.rebootDeviceSelectedItems &&
                                this.isEnableMenuItemOnlineAndOnlineDisplayOffStatus(p_.rebootDeviceSelectedItems))
                                ? (e) => {
                                      if (p_.beforeAction) {
                                          p_.beforeAction()
                                      }
                                      onClickActionRebootForErrorStatus(e)
                                      this.afterAction()
                                  }
                                : !disabled
                                ? (e) => {
                                      if (p_.beforeAction) {
                                          p_.beforeAction()
                                      }
                                      onClickAction(e)
                                      this.afterAction()
                                  }
                                : () => {}
                        }
                        className={cn(styles.menuItem, {
                            [item.disabled]:
                                p_.rebootDeviceSelectedItems && this.someStatusOffline(p_.rebootDeviceSelectedItems)
                                    ? true
                                    : p_.rebootDeviceSelectedItems &&
                                      this.isEnableMenuItemErrorStatus(p_.rebootDeviceSelectedItems)
                                    ? false
                                    : p_.rebootDeviceSelectedItems &&
                                      this.isEnableMenuItemOnlineDisplayOff(p_.rebootDeviceSelectedItems)
                                    ? false
                                    : p_.rebootDeviceSelectedItems &&
                                      this.isEnableMenuItemErrorAndOnlineDisplayOffStatus(p_.rebootDeviceSelectedItems)
                                    ? false
                                    : disabled,
                            // @ts-ignore
                            [styles.menuItemNormal]: p_.data.name === 'ds_date',
                        })}
                    >
                        {translate(p_.data.name)}
                        {getClientVersion(p_.selectedInfo, p_.data.name)}
                    </div>
                </Tooltip>
            )
        }

        return (
            <DropMenu
                active={s_.active}
                onChange={(active) => this.setState({ active })}
                mod="centered"
                buttonWrapperClassName={cn(grid.fullWidth)}
                items={[
                    {
                        id: p_.dropdown && p_.dropdown.id,
                        disabled,
                        button: (
                            <div
                                className={cn(styles.menuItem, grid.fullWidth, {
                                    [item.disabled]: disabled,
                                })}
                            >
                                {translate(p_.data.name)}
                            </div>
                        ),
                        children: (
                            <div className={styles.deleteGroup}>
                                <div className={cn(grid.p_mini, textStyles.center)}>
                                    {p_.dropdown && p_.dropdown.text}
                                </div>
                                <div className={cn(grid.mb_mini, grid.row, grid.center)}>
                                    <Button
                                        mod="fill"
                                        indentSize="mini"
                                        className={cn(grid.mr_mini)}
                                        onClick={() => {
                                            if (!disabled) {
                                                onClickAction()
                                                this.afterAction()
                                                this.setState({ active: [] })
                                            }
                                        }}
                                    >
                                        {translate('yes')}
                                    </Button>
                                    <Button indentSize="mini" onClick={() => this.setState({ active: [] })}>
                                        {translate('no')}
                                    </Button>
                                </div>
                            </div>
                        ),
                    },
                ]}
            />
        )
    }
}

const menuItemMapDispatchToProps = (dispatch: any) => {
    return {
        openModal: (modalOptions: any) => dispatch(displaysActions.openModal(modalOptions)),
        enableIdentificationAction: (devices: IDevice[]) => dispatch(enableIdentification(devices)),
        disableIdentificationAction: (devices: IDevice[]) => dispatch(disableIdentification(devices)),
    }
}

export const MenuItem = connect(null, menuItemMapDispatchToProps)(MenuItemComponent)

class DisplaysToolbarContent extends DisplaysToolbarContentMethods {
    renderMenuItemList = () => {
        const p_ = this.props
        const isOnline = p_.selectedInfo.online
        const isIdentificationImplemented = p_.selectedInfo.identificationImplementedQ
        const platform = p_.selectedInfo.platform

        return p_.selectedInfo.menu.map((menuItem, index) => {
            if (!isOnline && ['ds_date', 'android_permissions'].includes(menuItem.name)) {
                return null
            }

            if (!isIdentificationImplemented && ['identification_on', 'identification_off'].includes(menuItem.name)) {
                return null
            }

            if (menuItem.name === 'schedule' && !helpers.isAvailable({ key: 'schedule', action: 'read' })) {
                return null
            }

            if (menuItem.name === 'settings' && !platform) {
                return null
            }

            if (getMenuDisabledPermission(p_.selectedInfo, menuItem.name)) {
                return null
            }

            return <MenuItem key={`menuItem_${index}`} data={menuItem} selectedInfo={p_.selectedInfo} />
        })
    }

    getSelectInfoStatus = (selectedInfo: ISelectedInfo) => {
        return selectedInfo ? selectedInfo.status : null
    }

    isOfflineStatus = (selectedInfo: ISelectedInfo) => {
        return this.getSelectInfoStatus(selectedInfo) === 'offline'
    }

    isOnlineStatus = (selectedInfo: ISelectedInfo) => {
        return this.getSelectInfoStatus(selectedInfo) === 'offline'
    }

    isErrorStatus = (selectedInfo: ISelectedInfo) => {
        return this.getSelectInfoStatus(selectedInfo) === 'error'
    }

    getGroupActions = () => {
        const { openModal, disableIdentificationAction, enableIdentificationAction } = this.props
        const data: any = {
            edit: (selectedInfo: ISelectedInfo) => toolbar.editGroup(selectedInfo),
            information: () => openModal({ modalState: 'infoDisplay', modalOptions: {} }),
            settings: () => openModal({ modalState: 'displaySettings', modalOptions: {} }),
            schedule: (selectedInfo: ISelectedInfo) =>
                changeLocation({
                    pathname: `/${routes.schedule.path}`,
                    search: getURLSearchParamsString({
                        scheduleData: configureScheduleLocations(selectedInfo, 'groups'),
                    }),
                }),
            notifications_settings: () => openModal({ modalState: 'displayNotification', modalOptions: {} }),
            ds_date: () =>
                openModal({ modalState: 'dateTimeDisplay', modalOptions: { mod: 'clear', showCloseIcon: false } }),
            identification_on: (selectedInfo: ISelectedInfo, selectedItems: ISelectedItems[]) =>
                enableIdentificationAction([selectedInfo, ...selectedItems].filter((item) => isExist(item))),
            identification_off: (selectedInfo: ISelectedInfo, selectedItems: ISelectedItems[]) =>
                disableIdentificationAction([selectedInfo, ...selectedItems].filter((item) => isExist(item))),
            synchronizationScheme: () => openModal({ modalState: 'displaysSynchronization', modalOptions: {} }),
        }

        return data
    }

    isAvailableVolumeAction = (selectedInfo: ISelectedInfo) => {
        if (!selectedInfo || !selectedInfo.manufacturer) return true

        return selectedInfo.manufacturer !== 'BRIGHTSIGN'
    }

    licenseIsActive = (selectedInfo: ISelectedInfo) => {
        if (!selectedInfo) return false

        return !!selectedInfo.activeQ
    }

    displayOnOffSupport = (selectedInfo: ISelectedInfo) => {
        if (selectedInfo && selectedInfo.settings.comPorts) {
            return (
                selectedInfo.online &&
                selectedInfo.settings.comPorts.some((port: { Action: string }) => port.Action === 'PhilipsSICP')
            )
        }
        return false
    }

    render() {
        const s_ = this.state
        const p_ = this.props
        const cx_ = this.context
        const isDisplayOff = p_.selectedInfo && p_.selectedInfo.status === 'online_display_off'
        const availableCommands = displaysHelpers.getAvailableCommands(p_.selectedInfo)
        const queryParameters = helpers.getQuery(p_.query, 'displays')
        const orientation =
            p_.selectedInfo && p_.selectedInfo.orientation
                ? displaysHelpers.getOrientation(p_.selectedInfo.orientation)
                : 0
        function isRotate() {
            switch (orientation) {
                case 90:
                case 270:
                    return false
                default:
                    return true
            }
        }

        return (
            <div className={styles.displayInfo}>
                {p_.selectedInfo && p_.selectedInfo.type !== 'group' && (
                    <div>
                        {p_.selectedInfo.errorCode && this.isErrorStatus(p_.selectedInfo) && (
                            <Typography
                                type="title"
                                className={cn(textStyles.wordWrap, item.pointer, styles.errorText)}
                                onClick={() => {
                                    this.setState({
                                        descriptionModal: {
                                            open: true,
                                            text: p_.selectedInfo.errorCode,
                                        },
                                    })
                                }}
                            >
                                <Dotdotdot clamp={2}>{p_.selectedInfo.errorCode}</Dotdotdot>
                            </Typography>
                        )}
                        {p_.selectedInfo.name && (
                            <div className={grid.row}>
                                <Typography type="title" className={cn(styles.name, item.ellipsisWrapper)}>
                                    <div className={item.ellipsis}>{p_.selectedInfo.name}</div>
                                </Typography>
                            </div>
                        )}
                        {p_.selectedInfo.description && (
                            <Typography
                                type="title"
                                className={cn(styles.name, textStyles.wordWrap, item.pointer)}
                                onClick={() => {
                                    this.setState({
                                        descriptionModal: {
                                            open: true,
                                            text: p_.selectedInfo.description,
                                        },
                                    })
                                }}
                            >
                                <Dotdotdot clamp={2}>{p_.selectedInfo.description}</Dotdotdot>
                            </Typography>
                        )}

                        <div className={cn(item.rel, item.viewInfoNoHiddenOverflow)}>
                            <div
                                className={`${styles.info} ${styles[this.getSelectInfoStatus(p_.selectedInfo) || '']}`}
                            >
                                {this.licenseIsActive(p_.selectedInfo) &&
                                helpers.isAvailableAction(null, {
                                    key: 'digitalSignage',
                                    action: 'update',
                                    subaction: 'viewScreenshotHistory',
                                }) ? (
                                    <Icon
                                        type="fullscreen"
                                        tooltip={{
                                            title: translate('showScreen'),
                                        }}
                                        size={18}
                                        color="white"
                                        onClick={() => this.setState({ modalState: true })}
                                    />
                                ) : (
                                    <div />
                                )}
                                <div className={styles.lastUpdateDate}>
                                    {helpers.getFormattedLocalDate(
                                        p_.selectedInfo.lastScreenshotDate,
                                        'DD/MM/YYYY HH:mm'
                                    )}
                                </div>

                                {this.licenseIsActive(p_.selectedInfo) &&
                                helpers.isAvailableAction(p_.selectedInfo, {
                                    key: 'digitalSignage',
                                    action: 'update',
                                    subaction: 'updateScreenshot',
                                }) ? (
                                    <Icon
                                        type="update"
                                        style={{
                                            animation: s_.isUpdatingScreenShot
                                                ? 'loaderSpinAnimation 0.8s linear infinite'
                                                : '',
                                        }}
                                        size={18}
                                        color="white"
                                        tooltip={{
                                            title: translate('updateScreen'),
                                        }}
                                        disabled={this.isOfflineStatus(p_.selectedInfo)}
                                        onClick={() => {
                                            this.enableRefreshAnimation()
                                            api.send('updateScreenshotCb', {
                                                id: p_.selectedInfo.id,
                                                width: isRotate() ? 292 : 208,
                                                height: isRotate() ? 208 : 292,
                                            })
                                        }}
                                    />
                                ) : (
                                    <div />
                                )}
                            </div>
                            <Rectangle contain width={16} height={9} src={p_.selectedInfo.cover[0]} />
                            <DropMenu
                                active={s_.active}
                                onChange={(active) => this.setState({ active })}
                                mod="centered"
                                className={cn(styles[this.getSelectInfoStatus(p_.selectedInfo) || ''])}
                                items={[
                                    {
                                        id: 'on_off_display',
                                        show:
                                            helpers.isAvailableAction(p_.selectedInfo, {
                                                key: 'digitalSignage',
                                                action: 'update',
                                            }) && this.displayOnOffSupport(p_.selectedInfo),
                                        icon: isDisplayOff ? 'display_on' : 'display_off',
                                        size: 24,
                                        tooltip: {
                                            title: translate(isDisplayOff ? 'display_on' : 'display_off'),
                                        },
                                        children: (
                                            <div className={cn(grid.colCenter, grid.p_md)}>
                                                <div className={cn(grid.w100, grid.mb_md, textStyles.center)}>
                                                    <p className={cn(grid.w100, grid.mb_micro)}>
                                                        {translate(
                                                            isDisplayOff ? 'display_on_warning' : 'display_off_warning'
                                                        )}
                                                    </p>
                                                </div>
                                                <div className={cn(grid.row, grid.justify, grid.mb_mini)}>
                                                    <Button
                                                        mod="fill"
                                                        indentSize="mini"
                                                        className={cn(grid.mr_mini)}
                                                        onClick={() => {
                                                            api.send(isDisplayOff ? 'displayOn' : 'displayOff', {
                                                                digitalSignageId: [p_.selectedInfo.id],
                                                            })
                                                            this.setState({ active: [] })
                                                        }}
                                                    >
                                                        {translate('yes')}
                                                    </Button>
                                                    <Button
                                                        mod={'withBorder'}
                                                        indentSize="mini"
                                                        onClick={() => this.setState({ active: [] })}
                                                    >
                                                        {translate('no')}
                                                    </Button>
                                                </div>
                                            </div>
                                        ),
                                    },
                                    {
                                        id: 'power',
                                        show:
                                            this.licenseIsActive(p_.selectedInfo) &&
                                            helpers.isAvailableAction(p_.selectedInfo, {
                                                key: 'digitalSignage',
                                                action: 'update',
                                                subaction: 'shutdown',
                                            }),
                                        icon: 'power',
                                        tooltip: {
                                            title: translate('power'),
                                        },
                                        disabled:
                                            (this.isOnlineStatus(p_.selectedInfo) && availableCommands.length === 0) ||
                                            (this.isOfflineStatus(p_.selectedInfo) &&
                                                !displaysHelpers.availableWakeOnLan(p_.selectedInfo.platform)),
                                        children: (
                                            <div className={styles.deleteGroup}>
                                                {!this.isOfflineStatus(p_.selectedInfo) ? (
                                                    <>
                                                        {availableCommands.includes('reboot') && (
                                                            <div
                                                                className={styles.menuItem}
                                                                onClick={() => {
                                                                    api.send('rebootDisplay', {
                                                                        id: p_.selectedInfo.id,
                                                                    })
                                                                    this.setState({ active: [] })
                                                                }}
                                                            >
                                                                {translate('reboot_device')}
                                                            </div>
                                                        )}
                                                        {availableCommands.includes('restartApp') && (
                                                            <div
                                                                className={styles.menuItem}
                                                                onClick={() => {
                                                                    api.send('digitalSignageSendRestartApp', {
                                                                        id: p_.selectedInfo.id,
                                                                    })
                                                                    this.setState({ active: [] })
                                                                }}
                                                            >
                                                                {translate('restart_application')}
                                                            </div>
                                                        )}
                                                        {availableCommands.includes('shutdown') && (
                                                            <div
                                                                className={styles.menuItem}
                                                                onClick={() => {
                                                                    this.setState({ openPowerModal: 'id', active: [] })
                                                                }}
                                                            >
                                                                {translate('switchOff')}
                                                            </div>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {displaysHelpers.availableWakeOnLan(
                                                            p_.selectedInfo.platform
                                                        ) && (
                                                            <div
                                                                className={styles.menuItem}
                                                                onClick={() => {
                                                                    this.props.openModal({
                                                                        modalState: 'wakeOnLan',
                                                                        modalOptions: {},
                                                                    })
                                                                }}
                                                            >
                                                                {translate('enableDevice')}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        ),
                                    },
                                    {
                                        id: 'volume',
                                        show:
                                            this.licenseIsActive(p_.selectedInfo) &&
                                            helpers.isAvailableAction(p_.selectedInfo, {
                                                key: 'digitalSignage',
                                                action: 'update',
                                                subaction: 'changeVolume',
                                            }) &&
                                            this.isAvailableVolumeAction(p_.selectedInfo),
                                        icon: 'volume_min',
                                        disabled: this.isOfflineStatus(p_.selectedInfo),
                                        children: <DeviceVolume device={p_.selectedInfo} />,
                                        tooltip: {
                                            title: translate('volume'),
                                        },
                                    },
                                    {
                                        id: 'delete',
                                        show: helpers.isAvailableAction(p_.selectedInfo, {
                                            key: 'digitalSignage',
                                            action: 'delete',
                                        }),
                                        icon: 'delete',
                                        tooltip: {
                                            title: translate('delete'),
                                        },
                                        children: (
                                            <div className={cn(grid.colCenter, grid.p_md)}>
                                                <div className={cn(grid.w100, grid.mb_md, textStyles.center)}>
                                                    <p className={cn(grid.w100, grid.mb_micro)}>
                                                        {translate('displayDeleteWarning')}?
                                                    </p>
                                                    <p className={grid.w100}>{translate('displayDeleteError')}.</p>
                                                </div>
                                                <div className={cn(grid.row, grid.justify, grid.mb_mini)}>
                                                    <Button
                                                        mod="fill"
                                                        indentSize="mini"
                                                        className={cn(grid.mr_mini)}
                                                        onClick={() => {
                                                            api.send('deleteDisplay', { id: p_.selectedInfo.id })

                                                            if (cx_.disposition === 'map' && p_.query.mapquery) {
                                                                changeFilter({
                                                                    disposition: cx_.disposition,
                                                                    mapquery: undefined,
                                                                    type: undefined,
                                                                })
                                                            }
                                                            this.setState({ active: [] })
                                                        }}
                                                    >
                                                        {translate('yes')}
                                                    </Button>
                                                    <Button
                                                        mod={'withBorder'}
                                                        indentSize="mini"
                                                        onClick={() => this.setState({ active: [] })}
                                                    >
                                                        {translate('no')}
                                                    </Button>
                                                </div>
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                        <div className={styles.menu}>
                            {s_.active && s_.active.length > 0 && (
                                <div className={cn(grid.full, item.abs, item.backOpacity, item.disabled)} />
                            )}
                            {queryParameters.query && p_.selectedInfo.groupId !== p_.groupId && (
                                <MenuItem
                                    data={{ name: 'location' }}
                                    onClick={function () {
                                        changeLocation({
                                            pathname: `/${routes.displays.path}`,
                                            search: getURLSearchParamsString({
                                                parentId: p_.selectedInfo.groupId,
                                            }),
                                        })
                                    }}
                                />
                            )}
                            {p_.selectedInfo.menu && this.renderMenuItemList()}
                        </div>
                        {p_.selectedInfo.tags && (
                            <div className={styles.tags}>
                                {p_.selectedInfo.tags.map((tag, index) => (
                                    <div key={`display__tag_${index}`} className={styles.tagWrapper}>
                                        <Tag value={tag.name} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}

                {p_.selectedInfo && p_.selectedInfo.type === 'group' && (
                    <div>
                        {p_.selectedInfo.name && (
                            <div className={grid.row}>
                                <Typography type="title" className={cn(styles.name, item.ellipsisWrapper)}>
                                    <div className={item.ellipsis}>{p_.selectedInfo.name}</div>
                                </Typography>
                            </div>
                        )}
                        {p_.selectedInfo.description && (
                            <Typography
                                type="title"
                                className={cn(styles.name, textStyles.wordWrap, item.pointer)}
                                onClick={() => {
                                    this.setState({
                                        descriptionModal: {
                                            open: true,
                                            text: p_.selectedInfo.description,
                                        },
                                    })
                                }}
                            >
                                <Dotdotdot clamp={10}>{p_.selectedInfo.description}</Dotdotdot>
                            </Typography>
                        )}
                        <div>
                            <div
                                className={`${styles.info} ${styles[this.getSelectInfoStatus(p_.selectedInfo) || '']}`}
                            >
                                <Icon
                                    type="fullscreen"
                                    tooltip={{
                                        title: translate('installation'),
                                    }}
                                    size={18}
                                    color="white"
                                    onClick={() => this.setState({ modalState: true })}
                                />
                                {helpers.isAvailableAction(p_.selectedInfo, {
                                    key: 'digitalSignage',
                                    action: 'update',
                                    subaction: 'updateScreenshot',
                                }) && (
                                    <Icon
                                        type="update"
                                        style={{
                                            animation: s_.isUpdatingScreenShot
                                                ? 'loaderSpinAnimation 0.8s linear infinite'
                                                : '',
                                        }}
                                        size={18}
                                        tooltip={{
                                            title: translate('updateScreen'),
                                        }}
                                        color="white"
                                        onClick={() => {
                                            this.enableRefreshAnimation()
                                            api.send('updateScreenshotCb', {
                                                groupId: p_.selectedInfo.id,
                                                width: isRotate() ? 292 : 208,
                                                height: isRotate() ? 208 : 292,
                                            })
                                        }}
                                    />
                                )}
                            </div>
                            <Rectangle width={16} height={9} className={grid.row}>
                                {p_.selectedInfo.cover.map((cover, index) => (
                                    <div key={`group__cover_${index}`} className={grid.w50}>
                                        <Rectangle width={16} height={9} src={cover} />
                                    </div>
                                ))}
                            </Rectangle>
                            <DropMenu
                                active={s_.active}
                                onChange={(active) => this.setState({ active })}
                                mod="centered"
                                className={cn(styles[this.getSelectInfoStatus(p_.selectedInfo) || ''])}
                                items={[
                                    // {
                                    //     id: 'on_off_display',
                                    //     show: helpers.isAvailableAction(p_.selectedInfo, {
                                    //         key: 'digitalSignage',
                                    //         action: 'update',
                                    //     }),
                                    //     icon: 'display_on_off',
                                    //     size: 24,
                                    //     tooltip: {
                                    //         title: translate('display_on_off'),
                                    //     },
                                    //     children: (
                                    //         <div className={styles.deleteGroup}>
                                    //             <div
                                    //                 className={styles.menuItem}
                                    //                 onClick={() => {
                                    //                     api.send('displayOn', { groupId: [p_.selectedInfo.id] })
                                    //                     this.setState({ active: [] })
                                    //                 }}
                                    //             >
                                    //                 {translate('Включить дисплей')}
                                    //             </div>
                                    //             <div
                                    //                 className={styles.menuItem}
                                    //                 onClick={() => {
                                    //                     api.send('displayOff', { groupId: [p_.selectedInfo.id] })
                                    //                     this.setState({ active: [] })
                                    //                 }}
                                    //             >
                                    //                 {translate('Выключить дисплей')}
                                    //             </div>
                                    //         </div>
                                    //     ),
                                    // },
                                    {
                                        id: 'power',
                                        icon: 'power',
                                        show: helpers.isAvailableAction(p_.selectedInfo, {
                                            key: 'digitalSignage',
                                            action: 'update',
                                            subaction: 'shutdown',
                                        }),
                                        tooltip: {
                                            title: translate('power'),
                                        },
                                        children: (
                                            <div className={styles.deleteGroup}>
                                                <div
                                                    className={styles.menuItem}
                                                    onClick={() => {
                                                        api.send('rebootDisplay', { groupId: p_.selectedInfo.id })
                                                        this.setState({ active: [] })
                                                    }}
                                                >
                                                    {translate('reboot_device')}
                                                </div>
                                                <div
                                                    className={styles.menuItem}
                                                    onClick={() => {
                                                        api.send('digitalSignageSendRestartApp', {
                                                            groupId: p_.selectedInfo.id,
                                                        })
                                                        this.setState({ active: [] })
                                                    }}
                                                >
                                                    {translate('restart_application')}
                                                </div>
                                                <div
                                                    className={styles.menuItem}
                                                    onClick={() => {
                                                        this.setState({ openPowerModal: 'groupId', active: [] })
                                                    }}
                                                >
                                                    {translate('switchOff')}
                                                </div>
                                            </div>
                                        ),
                                    },
                                    {
                                        id: 'volume',
                                        show: helpers.isAvailableAction(p_.selectedInfo, {
                                            key: 'digitalSignage',
                                            action: 'update',
                                            subaction: 'changeVolume',
                                        }),
                                        icon: 'volume_min',
                                        children: <DeviceVolume device={p_.selectedInfo} />,
                                    },
                                    {
                                        id: 'delete',
                                        show: helpers.isAvailableAction(p_.selectedInfo, {
                                            key: 'digitalSignage',
                                            action: 'delete',
                                        }),
                                        icon: 'delete',
                                        tooltip: {
                                            title: translate('delete'),
                                        },
                                        children: (
                                            <div className={styles.deleteGroup}>
                                                <div
                                                    onClick={() => this.removeGroup(p_.selectedInfo)}
                                                    className={styles.removeGroupButton}
                                                >
                                                    {translate('deleteGroup')}
                                                </div>
                                                <div className={styles.removeAll}>
                                                    <Checkbox
                                                        label={translate('saveDisplays')}
                                                        onClick={() => this.toggleRemoveAll()}
                                                        checked={!s_.removeAll}
                                                    />
                                                </div>
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                        <div className={styles.menu}>
                            {s_.active && s_.active.length > 0 && (
                                <div className={cn(grid.full, item.abs, item.backOpacity, item.disabled)} />
                            )}
                            {queryParameters.query && p_.selectedInfo.parentId !== p_.parentId && (
                                <MenuItem
                                    data={{ name: 'location' }}
                                    onClick={function () {
                                        changeLocation({
                                            pathname: `/${routes.displays.path}`,
                                            search: getURLSearchParamsString({
                                                parentId: p_.selectedInfo.parentId,
                                            }),
                                        })
                                    }}
                                />
                            )}
                            {p_.selectedInfo.menu &&
                                p_.selectedInfo.menu.map((menuItem, index) => {
                                    if (
                                        this.getSelectInfoStatus(p_.selectedInfo) !== 'online' &&
                                        menuItem.name === 'ds_date'
                                    ) {
                                        return null
                                    }

                                    if (
                                        menuItem.name === 'schedule' &&
                                        !helpers.isAvailable({ key: 'schedule', action: 'read' })
                                    ) {
                                        return null
                                    }

                                    if (getMenuDisabledPermission(p_.selectedInfo, menuItem.name)) {
                                        return null
                                    }

                                    return (
                                        <div
                                            key={`group__menuItem_${index}`}
                                            className={styles.menuItem}
                                            onClick={
                                                this.getGroupActions()[menuItem.name]
                                                    ? () => this.getGroupActions()[menuItem.name](p_.selectedInfo)
                                                    : () => {}
                                            }
                                        >
                                            {translate(menuItem.name)}
                                        </div>
                                    )
                                })}
                            {helpers.isAvailableAction(null, {
                                key: 'digitalSignage',
                                action: 'update',
                                subaction: 'downloadDeviceInfo',
                            }) && (
                                <a
                                    href={p_.downloadLink}
                                    target="_blank"
                                    className={cn(grid.fullWidth, item.block, textStyles.noUnderline)}
                                    rel="noopener noreferrer"
                                >
                                    <MenuItem data={{ name: 'downloadDevices' }} selectedItems={[p_.selectedInfo]} />
                                </a>
                            )}
                        </div>
                    </div>
                )}
                <DisplaysScreenShots
                    open={s_.modalState}
                    selectedInfo={p_.selectedInfo}
                    onClose={() => this.setState({ modalState: false })}
                />
                {!p_.selectedInfo && p_.selectedItems.length === 0 && p_.withoutAddress && (
                    <div>
                        <div
                            className={cn(
                                textStyles.normalPlus,
                                textStyles['blackLight'],
                                textStyles.center,
                                grid.p_mini
                            )}
                        >
                            {translate('devicesWithoutAddress')}:
                        </div>
                        <Typography type="title" className={styles.name}>
                            {translate('devicesWithoutAddressDescription')}.
                        </Typography>
                        <div
                            className={cn(
                                grid.center,
                                grid.row,
                                grid.p_md,
                                textStyles['blackLight'],
                                textStyles.normalPlus
                            )}
                        >
                            {`${p_.withoutAddress.length} ${translate('devicesCounter')}:`}
                        </div>
                        <ul>
                            {(() =>
                                p_.withoutAddress.map((displayItem, index) => (
                                    <li
                                        key={`withoutAddress_${index}`}
                                        className={cn(grid.p_micro, grid.row, grid.noWrap, item.pointer)}
                                        onClick={
                                            helpers.isAvailableAction(p_.selectedInfo, {
                                                key: 'digitalSignage',
                                                action: 'update',
                                            })
                                                ? () => toolbar.editWithoutAddress(displayItem)
                                                : () => {}
                                        }
                                    >
                                        <Icon type="files" className={cn(grid.mr_md, grid.ml_micro)} />
                                        <span className={cn(item.ellipsisWrapper, textStyles['blackLight'])}>
                                            <div className={item.ellipsis}>{displayItem.name}</div>
                                        </span>
                                    </li>
                                )))()}
                        </ul>
                    </div>
                )}

                {p_.selectedInfo && <div className={grid.space} />}
                {p_.selectedInfo && (
                    <div className={cn(grid.p_md, grid.rowCenter, styles.closeBtn)}>
                        <Button mod={'withBorder'} onClick={p_.onCloseInfo} indentSize={'mini'} rounded={'full_normal'}>
                            {translate('close')}
                        </Button>
                    </div>
                )}

                <ModalAccept
                    open={!!s_.openPowerModal}
                    onReject={() => this.setState({ openPowerModal: false })}
                    onAccept={() => {
                        const data = { [s_.openPowerModal]: p_.selectedInfo.id }
                        api.send('shutdownDisplay', data)
                        this.setState({ openPowerModal: false })
                    }}
                    onClose={() => this.setState({ openPowerModal: false })}
                    description={translate(s_.openPowerModal === 'groupId' ? 'powerOffWarning_' : 'powerOffWarning')}
                    rejectLabel={translate('cancel')}
                    acceptLabel={translate('off')}
                />
                <ModalAccept
                    open={s_.descriptionModal.open}
                    onClose={() => {
                        this.setState({
                            descriptionModal: {
                                open: false,
                                text: '',
                            },
                        })
                    }}
                    description={s_.descriptionModal.text}
                />
            </div>
        )
    }
}

// DisplaysToolbarContent.contextTypes = {
//     disposition: PropTypes.string,
//     sort: PropTypes.string,
// }

const mapDispatchToProps = (dispatch: any) => {
    return {
        openModal: (modalOptions: any) => dispatch(displaysActions.openModal(modalOptions)),
        enableIdentificationAction: (devices: IDevice[]) => dispatch(enableIdentification(devices)),
        disableIdentificationAction: (devices: IDevice[]) => dispatch(disableIdentification(devices)),
    }
}

export default connect(null, mapDispatchToProps)(enhanceWithClickOutside(DisplaysToolbarContent))
