import React from 'react'
import VideoEditor from 'blocks.app/videoEditor/videoEditor'
import Content from 'blocks.app/content/content'
import translate from 'core/translate'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

type Location = {
    pathname: string
    search: string
    hash: string
    state: any
}

const FilesVideoEditor = ({ location }: { location: Location }) => {
    const query = getURLSearchParamsByLocation(location)
    const sourceId = query.sourceId

    return (
        <Content title={translate('videoEditor')} indentsMod="none" fullHeight>
            <VideoEditor sourceId={sourceId} />
        </Content>
    )
}

const exportFilesVideoEditor = checkAvailableModule('files/videoEditor') ? FilesVideoEditor : null

export default exportFilesVideoEditor
