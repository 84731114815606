import React from 'react'
import { CatalogItemVideoChat } from '../../__item/_videoChat'
import { api } from 'core/api/ConnectionManager'
import { addListener } from 'core/helpers'
import helpers from 'core/helpers'
import helpersDigitalSignages from 'core/helpers/_digitalSignages'
import translate from 'core/translate'
import styles from '../catalog.template-styles'
import { ItemData } from '../../../../core/models/Template'

const getGroupId = (instance: { parentId: number | undefined; groupId?: number }) => {
    if (typeof instance.parentId !== 'undefined') {
        return instance.parentId
    }

    return instance.groupId
}

export default {
    folderName: 'groupId',
    getMethod: 'getDisplaysAndGroups',
    getMethodParams: {
        videoChatImplementedQ: true,
    },
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => (
        <div>
            <div>{translate('noVideoChat')}</div>
            <div>{translate('videoChatSupportPlatform')}</div>
            <div style={{ textAlign: 'center' }}>
                <ul className={styles.list}>
                    <li className={styles.listItem}>WebOS 3.2 {translate('andHigher')}</li>
                    <li className={styles.listItem}>PRO:CENTRIC 4.5 {translate('andHigher')}</li>
                </ul>
            </div>
        </div>
    ),
    emptyIcon: 'not_allow',
    settingsPrefixes: 'videoChat',
    isAllowMultipleSelection: false,
    tile: CatalogItemVideoChat,
    sortOptions: () => [
        { id: '-createdAt', name: translate('sortCreatedAt') },
        { id: 'name', name: translate('byNameAsc') },
        { id: '-name', name: translate('byNameDesc') },
        { id: 'status', name: translate('sortStatusOnline') },
        { id: '-status', name: translate('sortStatusOffline') },
        { id: 'type', name: translate('devices') },
        { id: '-type', name: translate('groups') },
    ],
    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }
        if (!item.type) {
            item.type = 'digitalSignage'
        }

        return item
    },
    getBackendModel: (item: ItemData) => {
        if (item.type !== 'group') {
            return api.send('getDisplay', { id: item.id }, { hideLoader: true })
        } else {
            return api.send('getGroup', { groupId: item.id }, { hideLoader: true })
        }
    },
    isEqual: helpersDigitalSignages.isEqual,
    onCreateListeners: [
        (cb: any, clearList: string[]) => api.addObserver('groupCreated', cb, clearList),
        (cb: any, clearList: string[]) => api.addObserver('displayCreated', cb, clearList),
    ],
    onUpdateListeners: [
        (cb: any, clearList: string[]) => api.addObserver('groupUpdated', cb, clearList),
        (cb: any, clearList: string[]) => api.addObserver('displayUpdated', cb, clearList),
        addListener.updateDisplayProgress,
    ],
    onDeleteListeners: [
        (callback: any, clearList: string[]) =>
            api.addObserver('groupDeleted', ({ id }: { id: number }) => callback({ id, type: 'group' }), clearList),
        (callback: any, clearList: string[]) =>
            api.addObserver(
                'displayDeleted',
                ({ id }: { id: number }) => callback({ id, type: 'digitalSignage' }),
                clearList
            ),
    ],
    isFolder: (item: ItemData) => item.type === 'group',
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true }, callback)
    },
    unmount() {},
    getBreadcrumbs(groupId: number) {
        let breadcrumbs = [{ name: translate('devices'), id: undefined }]

        if (!groupId) {
            //@ts-ignore
            this.setState({ breadcrumbs })
            return
        }

        api.send('getGroupBreadcrumbs', { groupId }).then((data: any) => {
            data.reverse()
            //@ts-ignore
            this.setState({ breadcrumbs: [...breadcrumbs, ...data] })
        })
    },
    moveOnUpdate({
        prev,
        next,
    }: {
        prev: { parentId: number | undefined; groupId?: number }
        next: { parentId: number | undefined; groupId?: number }
    }) {
        //@ts-ignore
        const p_ = this.props

        //@ts-ignore
        if (this.isMap(p_)) {
            return false
        }

        //@ts-ignore
        if (this.filter.hasActualSchedule) {
            return false
        }

        const prevId = getGroupId(prev)
        const nextId = getGroupId(next)

        return prevId !== nextId
    },
    list: {
        status: ({ status }: { status: string }) => translate(status),
        createdAt: ({ createdAt }: { createdAt: string }) => helpers.getFormattedLocalDate(createdAt, 'DD/MM/YYYY'),
        lastConnection: ({ lastConnection }: { lastConnection: string }) =>
            helpers.getFormattedLocalDate(lastConnection, 'DD/MM/YYYY'),
        updatedAt: ({ updatedAt }: { updatedAt: string }) => helpers.getFormattedLocalDate(updatedAt, 'DD/MM/YYYY'),
    },
}
