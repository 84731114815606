import React from 'react';
import {ARROW_HEAD, ARROW_HEAD_INVERSE} from '../__markers/flowDiagram__markers';

import styles from './flowDiagram__link.jcss';

const setPathMarker = (inversed) => {
    if (inversed) {
        return {markerStart: `url(#${ARROW_HEAD_INVERSE})`}
    }
    return {markerEnd: `url(#${ARROW_HEAD})`}
};

class FlowDiagramLinkSegment extends React.PureComponent {
    path;
    circle;
    style = setPathMarker(this.props.inversed);

    componentDidUpdate(prevProps) {
        const p_ = this.props;

        if (prevProps.inversed !== p_.inversed) {
            this.style = setPathMarker(p_.inversed);
        }
    }

    render() {
        const p_ = this.props;

        return (
            <g>
                <path
                    className={styles.path}
                    ref={ref => this.path = ref}
                    strokeWidth={p_.model.width}
                    strokeLinecap="round"
                    d={p_.path}
                    style={this.style}
                />
                <path
                    className={styles.path}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    strokeWidth={3}
                    d={p_.path}
                />
            </g>
        );
    }
}

export default FlowDiagramLinkSegment