import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { indents } from 'blocks.app/config'
import { defaultThemeStyles } from '../item.theme'

const iconWrapperStyles = (side) => ({
    ...grid.rowCenter,
    ...item.transitionErase,
    ...item.abs,
    zIndex: 10,
    width: 30,
    height: 30,
    top: indents.nano,
    [side]: indents.nano,
})

const ItemFilesStyles = {
    wrapper() {
        return {
            ...grid.fullWidth,
            ...item.transitionErase,
            color: `var(--var-module-catalog-component-item-style-idle, ${defaultThemeStyles.idle})`,
            [`&:hover .${this.video}`]: {
                ...item.visible,
            },
            [`&:hover .${this.inputWrapper}`]: {
                opacity: 1,
            },
            [`&:hover .${this.backLayer}`]: {
                ...item.blackOpacity,
            },
            [`&:hover .${this.icon}`]: {
                ...item.visible,
            },
        }
    },
    itemWrapper: {
        ...item.pointer,
        ...grid.colCenter,
        ...grid.w100,
    },
    content: {
        ...item.rel,
        ...grid.full,
        ...grid.rowCenter,
    },
    wrapperActive() {
        return {
            [`& .${this.video}`]: {
                ...item.visible,
            },
            [`& .${this.inputWrapper}`]: {
                opacity: 1,
            },
            [`& .${this.backLayer}`]: {
                ...item.blackOpacity,
            },
        }
    },
    inputWrapper: {
        ...iconWrapperStyles('right'),
        opacity: 0,
    },
    rotateWrapper: {
        ...iconWrapperStyles('left'),
    },
    back: {
        ...item.abs,
        ...grid.full,
        ...grid.rowCenter,
        top: 0,
    },
    backLayer: {
        ...item.abs,
        ...grid.full,
    },
    item: {
        ...grid.w80,
        ...grid.mb_micro,
        ...item.overNone,
        borderRadius: 5,
    },
    dragItem: {
        cursor: 'move',
    },
    video: {
        ...item.hidden,
    },
    text: {
        ...grid.full,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.center,
        ...item.greyLight,
        textAlign: 'center',
    },
    icon: {
        ...item.abs,
        ...item.hidden,
        bottom: indents.micro,
        right: indents.micro,
    },
    errorIcon: {
        ...item.abs,
        bottom: indents.micro,
        right: indents.micro,
    },
    tooltip: {
        textAlign: 'center',
        ...grid.w100,
    },
}

addStyles(ItemFilesStyles)
export default ItemFilesStyles
