import React, { FC, memo, useContext } from 'react'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import helpers from 'core/helpers'
import { cn } from 'ethcss'
import { styles } from './ScoreboardsItem-styles'
import {
    ICatalogItem,
    ICatalogWithPaginationItemProps,
} from 'organisms/CatalogWithPagination/CatalogWithPagination-types'
import { CatalogWithPaginationContext } from 'organisms/CatalogWithPagination'
import DndDraggable from 'blocks.app/dnd/_draggable/dnd_draggable'
import { isExist } from 'core/utils/coreUtil'
import { changeFilter } from 'features/routes'

const ScoreboardsItemComponent: FC<ICatalogWithPaginationItemProps> = ({ item, isActive, isHovered }) => {
    const { catalogMethods, catalogState, methods, common } = useContext(CatalogWithPaginationContext)
    const { type, parentKey, isEnableDnD = true } = common
    const { isFolder, getDragData } = methods
    const { selectedInfo, selectedItems } = catalogState
    const { status = 'offline', id, name } = item
    const isActiveItem = selectedInfo === id || isActive
    const {
        onDoubleClickObject,
        onSelectInfo,
        addSelectedItem,
        removeSelectedItemById,
        onDragEnd,
        onDragStart,
        onResetSelectedInfo,
    } = catalogMethods

    const changeFolder = () => {
        changeFilter({
            [helpers.prefixQuery({ name: type, field: 'query' })]: undefined,
            [parentKey]: id,
        })
    }

    const onClickItem = (e: React.MouseEvent) => {
        if (!helpers.isDblTouchTap(e)) {
            onSelectInfo(id, item[parentKey] as number | null)
            return
        }

        onResetSelectedInfo()

        if (isFolder(item)) {
            changeFolder()
            return
        }

        if (onDoubleClickObject) {
            onDoubleClickObject(item)
        }

        e.preventDefault()
        return
    }

    const isDraggable = () => {
        if (!isEnableDnD) return false

        return typeof isFolder !== 'undefined'
    }

    const isSelected = () => isExist(selectedItems.find((itemId) => itemId === id))

    const handleSelectItem = () => {
        onResetSelectedInfo()

        if (isSelected()) {
            removeSelectedItemById(id)
            return
        }

        addSelectedItem(id)
    }

    const renderItemContent = () => {
        return (
            <div className={cn(styles.ScoreboardsItem__content)}>
                <div
                    className={cn(styles.ScoreboardsItem__checkbox, {
                        [styles.ScoreboardsItem__checkbox_type_selected]: isActiveItem || isHovered || isSelected(),
                    })}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Checkbox mod={'circleInv'} onClick={handleSelectItem} checked={isSelected()} />
                </div>
            </div>
        )
    }

    const createDragData = (item: ICatalogItem) => {
        return getDragData ? getDragData(item) : JSON.stringify(item)
    }

    const renderItem = () => {
        switch (item.type) {
            case 'ledControllerFolder': {
                return (
                    <Rectangle width={16} height={9} className={cn(styles.ScoreboardItem)}>
                        <DndDraggable
                            className={cn(
                                styles.ScoreboardsItem__dnd_type_group,
                                styles[`ScoreboardsItem__dnd_type_${status}`]
                            )}
                            data={createDragData(item)}
                            dragEnabled={isDraggable()}
                            onDragStart={onDragStart}
                            onDragEnd={onDragEnd}
                            onClick={onClickItem}
                        >
                            {renderItemContent()}
                            <div
                                className={cn(styles.ScoreboardsItem__info_type_group, {
                                    [styles.ScoreboardsItem__info_type_shadow]: isActiveItem || isSelected(),
                                })}
                            >
                                <div className={cn(styles.ScoreboardsItem__title_type_group)}>
                                    <div className={cn(styles.ScoreboardsItem__text_type_group)}>{name}</div>
                                </div>
                            </div>
                        </DndDraggable>
                        <div className={styles.ScoreboardsItem__backWrapper}>
                            <div
                                className={cn(
                                    styles.ScoreboardsItem__back,
                                    styles.ScoreboardsItem__back_type_second,
                                    styles[`ScoreboardsItem__back_type_${status}`]
                                )}
                            />
                        </div>
                        <div className={styles.ScoreboardsItem__backWrapper}>
                            <div
                                className={cn(styles.ScoreboardsItem__back, styles.ScoreboardsItem__back_type_first)}
                            />
                        </div>
                    </Rectangle>
                )
            }
            default: {
                return (
                    <Rectangle width={16} height={9} className={cn(styles.ScoreboardItem)}>
                        <DndDraggable
                            className={cn(
                                styles.ScoreboardsItem__dnd_type_item,
                                styles[`ScoreboardsItem__dnd_type_${status}`]
                            )}
                            data={createDragData(item)}
                            dragEnabled={isDraggable()}
                            onDragStart={onDragStart}
                            onDragEnd={onDragEnd}
                            onClick={onClickItem}
                        >
                            {renderItemContent()}
                            <div
                                className={cn(styles.ScoreboardsItem__info_type_item, {
                                    [styles.ScoreboardsItem__info_type_shadow]: isActiveItem || isSelected(),
                                })}
                            >
                                <div className={cn(styles.ScoreboardsItem__title_type_item)}>
                                    <div className={cn(styles.ScoreboardsItem__text_type_item)}>{name}</div>
                                </div>
                            </div>
                        </DndDraggable>
                    </Rectangle>
                )
            }
        }
    }

    return renderItem()
}

export const ScoreboardsItem = memo(ScoreboardsItemComponent)
