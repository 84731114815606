import { useEffect, useState } from 'react'
import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'
import filesHelpers, { CellModel, cellModel } from 'core/helpers/_files'
import tableEditorConfig from 'blocks.app/tableEditor/tableEditor.config'
import merge from 'merge'
import { getURLSearchParamsByLocation } from 'features/routes/utils'

export interface ITableEditorProps {
    location: { pathname: string; search: string; state: any; hash: string }
    parseCsv: (data: { formData: FormData; cb: (result: any) => void }) => void
    small: boolean
    tableEditorRef: any
}

export interface IState {
    tab: string
    tableName: string
    encoding: string
    tableOptions: any
    prevTableOptions: any
    selectedCellRow: number
    selectedCellCol: number
    isLoadingPreview: boolean
    multiSelect: { [index: string]: boolean }
    isEdit: boolean
}

export const TableEditorMethods = (p_: ITableEditorProps) => {
    const [state, setState] = useState<IState>({
        tab: 'tableSize',
        tableName: '',
        encoding: 'utf8',
        tableOptions: filesHelpers.getDefaultTableOptions(),
        prevTableOptions: null,
        selectedCellRow: 0,
        selectedCellCol: 0,
        isLoadingPreview: false,
        multiSelect: {},
        isEdit: false,
    })
    const tabs = [
        { id: 'tableSize', name: 'tableSize' },
        { id: 'text', name: 'text' },
        { id: 'position', name: 'position' },
        { id: 'cellOptions', name: 'specialOptions' },
        { id: 'connectedData', name: 'data' },
    ]
    const encodings = [
        { id: 'utf8', name: 'utf8' },
        { id: 'windows1251', name: 'windows1251' },
    ]

    useEffect(() => {
        if (!isEditTableExist()) return

        getTableModel(getEditTableId())
    }, [])
    useEffect(() => {
        if (isEditTableExist() && !isEditEnable()) {
            getTableModel(getEditTableId())
        }
    }, [p_])

    p_.tableEditorRef.current = {
        saveMethod: () => {
            const s_ = { ...state }
            const colNumber = s_.tableOptions.tableFormat.colNumber
            const rowNumber = s_.tableOptions.tableFormat.rowNumber

            let name = s_.tableName

            if (!name) {
                name = filesHelpers.autoGenerateTableName()
            }

            if (s_.tableOptions.tableFormat.widthByCol) {
                for (let col = 0; col < colNumber; col++) {
                    const widthByColValue = s_.tableOptions.tableFormat.widthByCol[col]

                    if (typeof widthByColValue === 'undefined' || widthByColValue === null) {
                        s_.tableOptions.tableFormat.widthByCol[col] = tableEditorConfig.initCellWidth
                    }
                }
            }

            if (s_.tableOptions.tableFormat.heightByRow) {
                for (let row = 0; row < rowNumber; row++) {
                    const heightByRowValue = s_.tableOptions.tableFormat.heightByRow[row]

                    if (typeof heightByRowValue === 'undefined' || heightByRowValue === null) {
                        s_.tableOptions.tableFormat.heightByRow[row] = tableEditorConfig.initCellHeight
                    }
                }
            }

            return {
                type: 'table',
                data: {
                    ...s_.tableOptions,
                    name,
                },
            }
        },
    }

    const getTableModel = (tableId: string) => {
        api.send('getFile', { id: tableId }).then(({ name, data }: any) => {
            if (data) {
                const s_ = { ...state }
                let encoding = s_.encoding

                if (data.connectedData) {
                    if (data.connectedData.fileEncoding) {
                        encoding = data.connectedData.fileEncoding
                    }
                }

                setState((prevState: IState) => {
                    return {
                        ...prevState,
                        tableName: name,
                        tableOptions: data,
                        encoding,
                        isEdit: true,
                    }
                })
            }
        })
    }

    const isEditEnable = () => {
        const { isEdit } = state

        return isEdit
    }

    const isEditTableExist = () => {
        const { location } = p_

        const query = getURLSearchParamsByLocation(location)
        return !!query.tableId
    }

    const getEditTableId = () => {
        const { location } = p_

        const query = getURLSearchParamsByLocation(location)
        return query.tableId
    }

    const changeTab = (tab: string) => {
        setState((prevState: IState) => {
            return {
                ...prevState,
                tab,
            }
        })
    }
    const onUpdateTableSize = ({
        colNumber,
        rowNumber,
        widthByCol,
        heightByRow,
    }: {
        colNumber: number
        rowNumber: number
        widthByCol: string
        heightByRow: string
    }) => {
        const s_ = { ...state }
        const prevCells = s_.tableOptions.cells
        s_.tableOptions.tableFormat.colNumber = colNumber
        s_.tableOptions.tableFormat.rowNumber = rowNumber
        s_.tableOptions.tableFormat.widthByCol = widthByCol
        s_.tableOptions.tableFormat.heightByRow = heightByRow
        const cells: any = []

        for (let i = 0; i < rowNumber; i++) {
            cells[i] = []
            for (let j = 0; j < colNumber; j++) {
                cells[i][j] =
                    prevCells[i] && prevCells[i][j] ? helpers.deepCopy(prevCells[i][j]) : helpers.deepCopy(cellModel)
            }
        }
        s_.tableOptions.cells = cells

        setState(s_)
    }
    const onUpdateColWidth = (colWidth: number) => {
        const s_ = { ...state }
        s_.tableOptions.tableFormat.colWidth = colWidth

        setState(s_)
    }
    const onUpdateRowHeight = (rowHeight: number) => {
        const s_ = { ...state }
        s_.tableOptions.tableFormat.rowHeight = rowHeight

        setState(s_)
    }
    const applyCellSettings = () => {
        const s_ = { ...state }
        const selectedCell = helpers.deepCopy(s_.tableOptions.cells[s_.selectedCellRow][s_.selectedCellCol])
        const { cells } = s_.tableOptions
        const colNumber = s_.tableOptions.tableFormat.colNumber
        const rowNumber = s_.tableOptions.tableFormat.rowNumber
        s_.prevTableOptions = merge.recursive(true, {}, s_.tableOptions)

        delete selectedCell.text

        for (let i = 0; i < rowNumber; i++) {
            for (let j = 0; j < colNumber; j++) {
                if (cells[i] && cells[i][j]) {
                    cells[i][j] = merge.recursive(true, cells[i][j], selectedCell)
                }
            }
        }

        setState(s_)
    }
    const applyCellMultiSettings = () => {
        const s_ = { ...state }
        const selectedCell = helpers.deepCopy(s_.tableOptions.cells[s_.selectedCellRow][s_.selectedCellCol])
        const { cells } = s_.tableOptions
        s_.prevTableOptions = merge.recursive(true, {}, s_.tableOptions)

        delete selectedCell.text

        for (let key in s_.multiSelect) {
            if (s_.multiSelect.hasOwnProperty(key)) {
                const parsedKey = key.split('__')
                const i = parsedKey[0]
                const j = parsedKey[1]

                if (cells[i] && cells[i][j]) {
                    cells[i][j] = merge.recursive(true, cells[i][j], selectedCell)
                }
            }
        }

        setState(s_)
    }

    const onParseCsv = (result: any) => {
        setState((prevState: IState) => {
            return { ...prevState, isLoadingPreview: false }
        })

        if (!result) return

        fillTheTable(result)
    }

    const onDropPreview = (e: any) => {
        const { parseCsv } = p_
        const formData = formattingDataForParseCsv(e)

        setState((prevState: IState) => {
            return { ...prevState, isLoadingPreview: true }
        })

        setTimeout(() => {
            parseCsv({
                formData,
                cb: onParseCsv,
            })
        }, 200)
    }
    const formattingDataForParseCsv = (e: any) => {
        const { tableOptions, encoding } = state
        const { connectedData } = tableOptions
        const { columnProcessing, rowProcessing, removeEmptyRows } = connectedData
        const file = e[0]
        const skipEmptyLines = removeEmptyRows.enabled
        const delimiter = columnProcessing.fieldDelimiter

        const formDataObject: {
            [index: string]: string
        } = {
            encoding,
            file,
            delimiter,
            skipEmptyLines,
        }

        if (rowProcessing.fromRow.enabled) {
            formDataObject['fromLine'] = rowProcessing.fromRow.value
            formDataObject['toLine'] = rowProcessing.toRow.value
        }

        if (columnProcessing.fromColumn.enabled) {
            formDataObject['fromColumn'] = columnProcessing.fromColumn.value
            formDataObject['toColumn'] = columnProcessing.toColumn.value
        }

        const formData = new FormData()

        for (let key in formDataObject) {
            if (formDataObject.hasOwnProperty(key)) {
                formData.append(key, formDataObject[key])
            }
        }

        return formData
    }
    const onChangeEncoding = ({ id }: { id: string }) => {
        setState((prevState: IState) => {
            return { ...prevState, encoding: id }
        })
    }
    const fillTheTable = (data: any) => {
        const s_ = { ...state }
        const cells: any = []
        let colNumber: any
        const rowNumber = data.length

        for (let i = 0; i < rowNumber; i++) {
            cells[i] = []
            colNumber = data[i].length
            for (let j = 0; j < colNumber; j++) {
                let model = helpers.deepCopy(cellModel)
                if (s_.tableOptions.cells[i] && s_.tableOptions.cells[i][j]) {
                    model = helpers.deepCopy(s_.tableOptions.cells[i][j])
                }
                cells[i][j] = {
                    ...model,
                    text: data[i][j],
                }
            }
        }

        setState((prevState: IState) => {
            return {
                ...prevState,
                tableOptions: {
                    ...s_.tableOptions,
                    cells,
                    tableFormat: {
                        ...s_.tableOptions.tableFormat,
                        colNumber,
                        rowNumber,
                    },
                },
                selectedCellRow: 0,
                selectedCellCol: 0,
            }
        })
    }
    const cancelCellSettings = () => {
        const s_ = { ...state }
        s_.tableOptions = s_.prevTableOptions
        s_.prevTableOptions = null
        setState(s_)
    }
    const onChangeCellProps = (value: string, property: string, field?: string) => {
        const s_ = { ...state }
        const selectedCell = s_.tableOptions.cells[s_.selectedCellRow][s_.selectedCellCol]

        if (!field) {
            selectedCell[property] = value
        } else {
            selectedCell[property][field] = value
        }
        s_.prevTableOptions = null

        setState(s_)
    }
    const onChangeConnectedData = (value: string, field: string) => {
        const s_ = { ...state }
        const connectedData = s_.tableOptions.connectedData

        connectedData[field] = value

        // setState(s_)
        setState((prevState: any) => {
            return {
                ...prevState,
                s_,
            }
        })
    }

    const onSelectCell = ({
        selectedCellRow,
        selectedCellCol,
    }: {
        selectedCellRow: number
        selectedCellCol: number
    }) => {
        setState((prevState: any) => {
            return {
                ...prevState,
                selectedCellRow,
                selectedCellCol,
                multiSelect: {},
            }
        })
    }
    const onMultiSelectCell = ({
        selectedCellRow,
        selectedCellCol,
    }: {
        selectedCellRow: number
        selectedCellCol: number
    }) => {
        const s_ = { ...state }
        const key = `${selectedCellRow}__${selectedCellCol}`

        s_.multiSelect[key] = !s_.multiSelect[key]
        setState(s_)
    }
    const onMultiSelectCells = ({ row, col }: { row: number; col: number }) => {
        const { selectedCellCol, selectedCellRow } = state
        const minRow = Math.min(row, selectedCellRow)
        const maxRow = Math.max(row, selectedCellRow)
        const minCol = Math.min(col, selectedCellCol)
        const maxCol = Math.max(col, selectedCellCol)
        const multiSelect: { [index: string]: boolean } = {}

        for (let i = minRow; i <= maxRow; i++) {
            for (let j = minCol; j <= maxCol; j++) {
                const key = `${i}__${j}`
                multiSelect[key] = true
            }
        }

        setState((prevState: IState) => {
            return {
                ...prevState,
                multiSelect,
            }
        })
    }
    const getSelectedCell = () => {
        const s_ = { ...state }
        const selectedRow = s_.tableOptions.cells[s_.selectedCellRow]

        if (selectedRow) {
            return selectedRow[s_.selectedCellCol]
        }
    }

    return {
        getSelectedCell,
        onSelectCell,
        onMultiSelectCell,
        onMultiSelectCells,
        cancelCellSettings,
        applyCellMultiSettings,
        applyCellSettings,
        changeTab,
        onUpdateTableSize,
        onUpdateRowHeight,
        onUpdateColWidth,
        onChangeCellProps,
        onChangeConnectedData,
        onDropPreview,
        onChangeEncoding,
        setState,
        state,
        tabs,
        encodings,
    }
}
