import React from 'react'
import { api } from 'core/api/ConnectionManager'
import { MapCreator } from 'features/map/controllers/MapCreator'
import { isNotEmptyArray } from 'core/utils/coreUtil'
import { changeFilter, changeLocation } from '../../../features/routes'
import helpers from '../../../core/helpers'
import { IRecordWithStrings, IViewParams } from '../../../core/helpers'
import { ItemData } from '../../../core/models/Template'
import { getURLSearchParamsByLocation, getURLSearchParamsString } from '../../../features/routes/utils'
import { IFilter } from '../../../features/routes/routes-types'

const mapFactory = MapCreator.getInstance().getMapFactory()
const mapApi = mapFactory.createMapApi()

type Coords = { lat: number; lng: number }

interface ICatalogHeaderProps {
    onSelectAll: () => void
    query: IRecordWithStrings
    filterOptions: { id: any; name: string; hide: boolean }[] | null
    centerMap: (data: Coords) => void
    list: object[]
    isEqual: (data_1: ItemData, data_2: ItemData) => boolean
    isMap: boolean
    onSelectInfo: (index: number | null) => void
    onCancelAllSelect?: () => void
    onGetList: (data: any[]) => void
    onSelect: (data: ItemData[]) => void
    template: any
    type: string
    viewParams: IViewParams
    showHeaderButton: boolean
    pathName: string
    small: boolean
    placeholder: string
    className?: string
    searchable: boolean
    value?: string
    updateUserSettings: (data: {
        data: {
            sort?: { [x: number]: number }
            dateFilter?: { [x: number]: number }
            view?: { [x: number]: number }
        }
        isSaveSettings: boolean
    }) => void
    viewLocal: boolean
    changeDisposition: () => void
    groupId?: number | null
    onChangeFilterStatus: (value: string) => void
    onChangeADSearch: (params: { [index: string]: any }) => void
    getAdvancedSearch: () => void
    advancedSearchState: object
    filterIsActive: boolean
    getDisposition: () => string
    isMobile?: boolean
    //searchbarMod?: string
    contentAndFolders?: boolean
    autoSelectContentType?: boolean
    filterItems?: { name: string; fileType: string }[]
    location: any
}

class CatalogHeaderMethods extends React.Component<ICatalogHeaderProps> {
    static getSelected(p_: ICatalogHeaderProps) {
        const selected: any = {}
        const {
            query,
            filterOptions,
        }: { query: IRecordWithStrings; filterOptions: { id: any; name: string; hide: boolean }[] | null } = p_

        if (filterOptions) {
            filterOptions.forEach((filterOption: { id: string }) => {
                if (query[filterOption.id]) {
                    selected[filterOption.id] = query[filterOption.id].split('//')
                }
            })
        }

        return selected
    }

    static getDerivedStateFromProps(p_: ICatalogHeaderProps) {
        return {
            selected: CatalogHeaderMethods.getSelected(p_),
        }
    }

    state = {
        selected: CatalogHeaderMethods.getSelected(this.props),
        geocoderResult: [],
        mapqueryResult: [],
        availableFilters: [],
        selectedFilters: [],
    }

    setAvailableFilters = (filters: { name: string }[]) => {
        this.setState({ availableFilters: filters })
    }

    setSelectedFilters = (filters: { name: string }[]) => {
        this.setState({ selectedFilters: filters })
    }

    onSelectAutocomplete = (res: ItemData) => {
        const p_ = this.props

        if (res) {
            p_.centerMap(res.location)

            if (res.id) {
                const resItem = { ...res }
                let selectedIndex = null
                if (resItem.type === 'displays') {
                    resItem.type = 'digitalSignage'
                }

                p_.list.forEach((listItem: any, index: number) => {
                    if (p_.isEqual(resItem, listItem)) {
                        selectedIndex = index
                    }
                })

                if (selectedIndex !== null) {
                    p_.onSelectInfo(selectedIndex)
                }
            }
        }
    }
    onSelectionChange = (selected: { id: string }) => {
        const p_ = this.props

        if (selected.id === 'selectAll') {
            p_.onSelectAll()
        }
        if (selected.id === 'cancelAll') {
            if (p_.onCancelAllSelect) {
                p_.onCancelAllSelect()
            }
            if (p_.onSelect) {
                p_.onSelect([])
            }
            p_.onSelectInfo && p_.onSelectInfo(null)
        }
    }

    initDisplaysAndGeocodeResults() {
        const { query, isMap, centerMap } = this.props

        if (!query.query || !isMap) return

        if (window.spconfig.map.type === 'open-street-map') {
            api.send<object, Coords[]>('getDisplays', { mapquery: query.query }).then((mapqueryResult) => {
                if (mapqueryResult) {
                    const filteredResult = mapqueryResult.filter((element) => element.lat && element.lng)
                    this.props.onGetList(filteredResult)

                    this.setState({ mapqueryResult: filteredResult })
                }
            })

            return
        }

        mapApi
            .geocodeLocation(query.query)
            .then((mapLocationList) => {
                if (!isNotEmptyArray(mapLocationList)) return

                let geocoderResult = mapLocationList.map((item) => {
                    return {
                        address: `${item.name} ${item.description}`,
                        location: {
                            lat: item.lat,
                            lng: item.lng,
                        },
                    }
                })

                this.setState({ geocoderResult })
            })
            .catch(() => {})

        api.send<object, Coords[]>('getDisplays', { mapquery: query.query, distance: 100 }).then((mapqueryResult) => {
            if (mapqueryResult) {
                this.setState({ mapqueryResult })

                if (mapqueryResult[0] && mapqueryResult[0].lat && mapqueryResult[0].lng) {
                    centerMap({
                        lat: mapqueryResult[0].lat,
                        lng: mapqueryResult[0].lng,
                    })
                }
            }
        })
    }

    updateDisplaysAndGeocodeResults(value: string) {
        if (!value) {
            this.setState({ geocoderResult: [], mapqueryResult: [] })
        }

        if (window.spconfig.map.type === 'open-street-map') {
            api.send<object, Coords[]>('getDisplays', { mapquery: value }).then((mapqueryResult) => {
                if (mapqueryResult) {
                    const filteredResult = mapqueryResult.filter((element) => element.lat && element.lng)
                    this.props.onGetList(filteredResult)

                    this.setState({ mapqueryResult: filteredResult })
                }
            })

            return
        }

        mapApi
            .geocodeLocation(value)
            .then((mapLocationList) => {
                if (!isNotEmptyArray(mapLocationList)) return

                let geocoderResult = mapLocationList.map((item) => {
                    return {
                        address: `${item.name} ${item.description}`,
                        location: {
                            lat: item.lat,
                            lng: item.lng,
                        },
                    }
                })

                this.setState({ geocoderResult })

                api.send('getDisplays', { mapquery: value }).then((mapqueryResult) => {
                    if (mapqueryResult) {
                        this.setState({ mapqueryResult })
                    }
                })
            })
            .catch(() => {})
    }

    componentDidMount() {
        const p_ = this.props
        this.initDisplaysAndGeocodeResults()

        if (p_.type === 'files' && p_.autoSelectContentType) {
            const location = p_.location
            const query = getURLSearchParamsByLocation(location)
            const filter: any = {}

            if (p_.filterItems && p_.filterItems.length && p_.autoSelectContentType) {
                filter[helpers.prefixQuery({ name: this.props.type, field: 'fileType' })] = p_.filterItems[0].fileType
                filter[helpers.prefixQuery({ name: this.props.type, field: 'type' })] = 'fs'
            }
            if (p_.contentAndFolders) {
                filter[helpers.prefixQuery({ name: this.props.type, field: 'withFolders' })] = 'true'
            }
            const search = getURLSearchParamsString({
                ...query,
                ...filter,
            })
            changeLocation({
                ...location,
                search,
            })
        }
    }

    componentDidUpdate(prevProps: ICatalogHeaderProps) {
        const p_ = this.props

        if (p_.isMap && p_.query.query !== prevProps.query.query) {
            this.onChange(p_.query.query)
        }
    }

    onChange = (value: string) => {
        this.updateDisplaysAndGeocodeResults(value)
    }
}

export default CatalogHeaderMethods
