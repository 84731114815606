import React, { FC, useEffect } from 'react'
import YandexMetrika from 'blocks.app/yandexMetrika/yandexMetrika'
import helpers from 'core/helpers'
import { reportSuccessNotification } from './__notifications/app__notifications'
import { AppNotifications } from 'features/appNotifications'
import { api } from 'core/api/ConnectionManager'
import moment from 'moment'
import { getDefaultUserSettings } from 'core/helpers/user'
import { routes } from 'features/routes'
import { useAppSyncService } from 'core/services/useAppSyncService'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { useRoutesGuard } from 'features/routes/hooks'
import { appActions, getServerInfo, IAppState } from './app.state'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { setThemeColorScheme, TThemeColorSchemeType } from 'theme/colors'
import { useDispatch } from 'react-redux'
import { LANGUAGES } from 'core/translate'
import { customErrorsList } from '../../features/appNotifications/AppCustomErrorsList'
import {
    autoLogout,
    checkTokens,
    IUserState,
    join,
    logout,
    updateTokensTimer,
    updateUserSettings,
    updateCompany,
} from 'blocks.app/user/user.state'

// legacy imports
import 'moment/locale/ru'
import styles from './app.jcss'
import { useLocation } from 'react-router'
import { InitPage } from './organisms/InitPage'
import { Page } from './organisms/Page'
import { findRoute } from 'features/routes'
import { AppProps } from './app-types'
import { IUser, IUserTokens } from 'core/models/Users'
import { mediaJS } from 'theme'
import { loaderActions } from 'features/loader/Loader.state'
import { Head } from 'features/head'
import { useTypedSelector } from 'core/store/hooks'
import { MaintineService } from '../../pages/maintineService/MaintineService'
import { CookieUsageWarning } from '../cookieUsageWarning/CookieUsageWarning'

const listenersId: string[] = []

const App: FC<AppProps> = ({ children }) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const { showMobMenu, showMobToolbar, isJoined } = useTypedSelector<IAppState>((state) => state.app)
    const { mainUser, data: user, tokens } = useTypedSelector<IUserState>((state) => state.user)
    const query = getURLSearchParamsByLocation(location)
    const pathnameWithoutSlash = helpers.getPathname(location.pathname)
    const route = findRoute(pathnameWithoutSlash)
    const isAuthRoute = route && route.isAuth
    const isAuthenticated = isJoined && user && mainUser
    const defaultSettings = getDefaultUserSettings()
    const lang = user?.settings?.locale || defaultSettings.locale
    const theme = user?.settings?.theme || defaultSettings.theme
    const mainMenuTheme = user?.settings?.mainMenuTheme || defaultSettings.mainMenuTheme
    const locale = query.lang
    const config = window.spconfig

    useRoutesGuard()

    useAppSyncService({
        onAuthorization: handleAuthorizationEvent,
        onLogout: handleLogoutEvent,
    })

    useEffect(() => {
        getWhiteLabel()

        dispatch(getServerInfo())
        sendJoinOnAutoLogin()
        createListeners(listenersId)

        return () => {
            clearListeners(listenersId)
        }
    }, [])

    useEffect(() => {
        helpers.disableWindowScroll((showMobMenu || showMobToolbar) && mediaJS.tab.matches, 'media_disableScroll')
    }, [showMobMenu, showMobToolbar])

    useEffect(() => {
        moment.locale(lang)
    }, [lang])

    useEffect(() => {
        setThemeColorScheme(theme as TThemeColorSchemeType, mainMenuTheme)
    }, [theme, mainMenuTheme])

    useEffect(() => {
        if (isJoined || !locale) return

        const localesWhiteList = LANGUAGES.map((lang) => lang.id)

        if (!localesWhiteList.includes(locale) || locale === defaultSettings.locale) return

        dispatch(
            updateUserSettings({
                data: { locale },
                isSaveSettings: false,
            })
        )
    }, [locale, isJoined])

    const getWhiteLabel = async () => {
        const url = window.spconfig.ip_address + 'resources/all'

        try {
            const res = await fetch(url)
            const data = await res.json()
            whiteLabelCheck(data.data)
        } catch (e) {
            console.log(e)
        }
    }
    const getWhiteLabelConfig = async (url: string) => {
        try {
            const res = await fetch(url)
            const data = await res.json()

            if (!data) return
            if (data.brandName) {
                window.spconfig.brandName = data.brandName
            } else {
                window.spconfig.brandName = 'SmartPlayer'
            }
            if (data.emailSupport) {
                window.spconfig.emailSupport = data.emailSupport
            }
        } catch (e) {
            console.log(e)
        }
    }
    const whiteLabelCheck = (data: { name: string; fileUrl: string }[] | null) => {
        if (data) {
            if (data.length) {
                const backgroundImages = data.filter((image) => image.name.includes('background'))
                const logo = data.find((image) => image.name.replace(/\.[^/.]+$/, '') === 'text-logo')
                const menuLogo = data.find((image) => image.name.replace(/\.[^/.]+$/, '') === 'menu-logo')
                const config = data.find((element) => element.name.replace(/\.[^/.]+$/, '') === 'config')

                if (backgroundImages.length) {
                    window.spconfig.auth.backgroundImages = backgroundImages.map((image) => image.fileUrl)
                }
                if (logo) {
                    window.spconfig.auth.logo = logo.fileUrl
                }
                if (menuLogo) {
                    window.spconfig.auth.menuLogo = menuLogo.fileUrl
                }
                if (config) {
                    getWhiteLabelConfig(config.fileUrl)
                } else {
                    window.spconfig.brandName = 'SmartPlayer'
                }
            }
        }

        dispatch(appActions.whiteLabelChecked(true))
    }

    const sendJoinOnAutoLogin = () => {
        if (!user || !tokens || !mainUser) return

        const checkTokensCb = (tokens: IUserTokens) => {
            dispatch(join({ isSwitching: false }))
            onJoin()
            dispatch(updateTokensTimer({ tokens }))
        }

        dispatch(checkTokens({ tokens: tokens, cb: checkTokensCb }))
    }

    const onJoin = () => {
        api.addObserver(
            'connect',
            () => {
                dispatch(join({ isSwitching: false }))
            },
            listenersId
        )
    }

    const onEmitError = (errCode: any) => {
        const errorCodesLogout = [17, 18, 19, 20, 23, 24]
        const pathname = helpers.getPathname(location.pathname)

        if (user && pathname !== routes.login.path && errorCodesLogout.includes(errCode.error)) {
            dispatch(logout({ isSwitchAccount: false }))
        }
        if (customErrorsList.includes(errCode.error.toString())) {
            return dispatch(emitError(errCode))
        }

        dispatch(emitError(errCode.error))
    }

    const onChangeStatus = (status: string) => {
        dispatch(appActions.changeConnectionStatus(status))
    }

    const onUserDeleted = (user: IUser) => {
        const { id } = user

        dispatch(autoLogout(id))
    }

    const onReportTaskUpdated = (task: any) => {
        if (task.status === 'success') {
            reportSuccessNotification(task)
        }
    }

    const onForceLogout = () => {
        dispatch(logout({ isSwitchAccount: false }))
    }

    const onStartLoading = () => {
        dispatch(loaderActions.startLoading())
    }

    const onEndLoading = () => {
        dispatch(loaderActions.endLoading())
    }

    function createListeners(listenersId: string[]) {
        api.addObserver('startLoading', onStartLoading, listenersId)
        api.addObserver('endLoading', onEndLoading, listenersId)
        api.addObserver('emitError', onEmitError, listenersId)
        api.addObserver('onChangeStatus', onChangeStatus, listenersId)
        api.addObserver('userDeleted', onUserDeleted, listenersId)
        api.addObserver('reportTaskUpdated', onReportTaskUpdated, listenersId)
        api.addObserver('companySettingsUpdated', updateCompanySettings, listenersId)
        api.addObserver('forceLogout', onForceLogout, listenersId)
    }

    function clearListeners(listenersId: string[]) {
        listenersId.forEach((id) => api.removeObserver(id))
    }

    function supportOnlineStatus(data: any) {
        if (data) {
            if (user && !user.isSupportQ) {
                dispatch(appActions.toggleRemoteSupport(data.online))
            }
        }
    }

    function handleAuthorizationEvent() {
        window.location.reload()
    }

    function handleLogoutEvent() {
        window.location.reload()
    }

    const renderInitPage = () => {
        return <InitPage>{children}</InitPage>
    }

    const renderPage = () => {
        return isAuthenticated ? <Page>{children}</Page> : null
    }

    const renderYandexMetrica = () => {
        return <YandexMetrika />
    }

    const renderCookieUsageWarning = () => {
        return <CookieUsageWarning />
    }

    const updateCompanySettings = (data: any) => {
        dispatch(
            updateCompany({
                data,
                isSaveCompany: false,
            })
        )
    }

    useEffect(() => {
        const listenersId: string[] = []
        api.addObserver('supportOnlineStatus', supportOnlineStatus, listenersId)

        return () => clearListeners(listenersId)
    }, [user])

    return (
        <div className={styles.rootWrapper}>
            {config.showServicePage ? (
                <MaintineService />
            ) : (
                <>
                    <Head />
                    {isAuthRoute ? renderInitPage() : renderPage()}
                    <AppNotifications />
                    {renderYandexMetrica()}
                    {renderCookieUsageWarning()}
                </>
            )}
        </div>
    )
}

export default App
