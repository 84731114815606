import { IBroadcast, IBroadcastMove } from 'core/models/Broadcast'
import { isExist, isNotEmptyArray } from './coreUtil'
import { IContent } from 'core/models/Content'
import { IDefaultSourceDuration } from 'core/models/Settings'
import { IFileSource } from 'core/models/Files'

export const findBroadcastPageContentById = (
    broadcast: IBroadcast,
    contentId: number
): { areaId: number; content: IContent } | null => {
    if (!isExist(broadcast) || !isExist(contentId)) return null

    let pages = broadcast.pages

    if (!isExist(pages)) return null

    let pagesLength = pages.length

    for (let i = 0; i < pagesLength; i++) {
        let areas = pages[i].areas

        if (areas) {
            let areasLength = areas.length

            for (let j = 0; j < areasLength; j++) {
                let area = areas[j]
                let areaContent = area.content

                if (areaContent) {
                    let areaContentLength = areaContent.length

                    for (let n = 0; n < areaContentLength; n++) {
                        let content = areaContent[n]

                        if (content.id === contentId)
                            return {
                                content,
                                areaId: j + 1,
                            }
                    }
                }
            }
        }
    }

    return null
}

export const findBroadcastSountrackContentById = (
    broadcast: IBroadcast,
    contentId: number
): { areaId: number; content: IContent } | null => {
    if (!isExist(broadcast) || !isExist(contentId)) return null

    const DEFAULT_SOUNDTRACK_AREA_ID = -1

    let soundtrack = broadcast.soundtrack

    if (!isExist(soundtrack)) return null

    let soundtrackLength = soundtrack.length

    for (let j = 0; j < soundtrackLength; j++) {
        let soundtrackContent = soundtrack[j]

        if (soundtrackContent.id === contentId)
            return {
                content: soundtrackContent,
                areaId: DEFAULT_SOUNDTRACK_AREA_ID,
            }
    }

    return null
}

export const getParamsForMultipleMoveBroadcasts = (
    broadcastId: number[],
    broadcastFolderId: number[],
    parentId: number
): IBroadcastMove => {
    const params: IBroadcastMove = {
        parentId,
    }

    if (isNotEmptyArray(broadcastFolderId)) {
        params.broadcastFolderId = broadcastFolderId
    }

    if (isNotEmptyArray(broadcastId)) {
        params.broadcastId = broadcastId
    }

    return params
}

export const getDefaultSourceDuration = (
    defaultSourceDuration: IDefaultSourceDuration[],
    source: IFileSource
): number | null => {
    const defaultSource = defaultSourceDuration.find(
        (sourceSettings) =>
            source.type === sourceSettings.type &&
            (!sourceSettings.hasOwnProperty('fileType') || sourceSettings.fileType === source.fileType)
    )

    return defaultSource ? defaultSource.defaultDuration : null
}
