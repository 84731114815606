import { addStyles } from 'ethcss'
import { indents, layers, grid, item } from 'theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'

const modalStyles = {
    wrapper: {
        ...grid.row,
        ...grid.center,
        ...grid.normalCenter,
        position: 'fixed',
        ...item.modalBackground,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        '& *': {
            fontFamily: `var(--var-module-app-component-app-style-fontFamily, ${defaultThemeStyles.fontFamily})!important`,
        },
    },
    icon: {
        position: 'absolute',
        right: indents.md,
        top: indents.md,
        zIndex: layers.drop,
    },
    modalOpen: {
        ...grid.full,
        overflow: 'hidden',
    },
}

addStyles(modalStyles)
export default modalStyles
