import React from 'react'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import Range from 'blocks.simple/range/range'
import styles from './textEditor__settings.jcss'
import translate from 'core/translate'
import config from '../config'
import { cn } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import helpers from '../../../core/helpers'

const TextEditorSettings = (p_: {
    settings: any
    disabled: boolean
    onChange: (settings: string | number) => void
}) => {
    const isMarqueeDisabled = p_.settings.marquee.direction === 'disabled'
    const directions = [
        { id: 'disabled', name: translate('static') },
        { id: 'left', name: translate('rightToLeft') },
        { id: 'right', name: translate('leftToRight') },
        { id: 'up', name: translate('toTop') },
        { id: 'down', name: translate('toBottom') },
    ]
    const textOrientation = [
        { id: 'horizontal', name: translate('horizontalMenu') },
        { id: 'vertical', name: translate('verticalMenu') },
    ]
    const onChangeSetting = (type: string, key: string, value: any) => {
        const settings = helpers.deepCopy(p_.settings)

        if (type === 'textOrientation') {
            settings[type] = value
        } else {
            settings[type][key] = value
        }

        if (key === 'direction' && value === 'disabled') {
            settings[type]['speed'] = config.marquee.MIN_SPEED
        }
        p_.onChange(settings)
    }

    return (
        <div className={styles.settings}>
            <div className={styles.options}>
                <Dropdown
                    containerClassName={styles.option}
                    label={translate('textMovingDirection')}
                    options={directions}
                    onChange={(selected) => onChangeSetting('marquee', 'direction', selected.id)}
                    value={p_.settings.marquee.direction}
                    disabled={p_.disabled}
                />
                <div className={cn(styles.option, { [item.disableWrapper]: isMarqueeDisabled })}>
                    <div className={grid.mb_micro}>
                        {translate('speed')}: {p_.settings.marquee.speed}
                    </div>
                    <Range
                        min={config.marquee.MIN_SPEED}
                        max={config.marquee.MAX_SPEED}
                        step={1}
                        value={p_.settings.marquee.speed}
                        onChange={(value: number) => onChangeSetting('marquee', 'speed', value)}
                    />
                </div>
            </div>
            <div className={styles.options}>
                <Dropdown
                    containerClassName={styles.option}
                    label={translate('textOrientation')}
                    options={textOrientation}
                    onChange={(selected) => onChangeSetting('textOrientation', '', selected.id)}
                    value={p_.settings.textOrientation}
                />
            </div>
        </div>
    )
}

TextEditorSettings.defaultProps = {
    onChange: () => {},
}

export default TextEditorSettings
