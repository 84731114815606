import { addStyles } from 'ethcss'
import { mediaCSS, textSizes, layers, grid, item, text, colors } from 'theme'

import { HEADER_HEIGHT } from 'organisms/Header/Header-styles'
const INDENT = 10
const OWNER_PANEL_WRAPPER_HEIGHT = 132

const userWindowStyles = {
    wrapper: {
        position: 'fixed',
        top: 0,
        ...grid.col,
        ...item.overNone,
        ...item.shadowMd,
        ...item.rounded_full_mini,
        marginTop: HEADER_HEIGHT + INDENT,
        maxWidth: 375,
        minWidth: 375,
        maxHeight: `calc(100% - ${HEADER_HEIGHT + INDENT * 2}px)`,
        zIndex: layers.modal + 1,
        [mediaCSS.mob]: {
            maxWidth: `calc(100% - ${INDENT * 2}px)`,
            minWidth: `calc(100% - ${INDENT * 2}px)`,
        },
    },
    closeIcon: {
        ...item.abs,
        top: INDENT + 5,
        right: INDENT + 5,
    },
    ownerPanelWrapper: {
        ...grid.row,
        ...grid.noWrap,
        ...item.white,
        maxHeight: OWNER_PANEL_WRAPPER_HEIGHT,
        minHeight: OWNER_PANEL_WRAPPER_HEIGHT,
    },
    ownerPanelAvatar: {
        ...grid.p_md,
        ...grid.fullHeight,
    },
    ownerPanelInfo: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.space,
        ...grid.p_md,
        ...grid.pl_none,
        ...grid.pb_micro,
        ...item.overNone,
    },
    userInfo: {
        ...grid.w100,
        ...item.overNone,
    },
    username: {
        ...grid.w100,
        ...item.ellipsisPure,
        ...text.md,
        ...text.bold,
        ...text.darkBlue,
        lineHeight: `${textSizes.mdPlus}px`,
    },
    roles: {
        ...item.ellipsisPure,
        ...text.normal,
        ...text.darkBlue,
        ...grid.mt_micro,
    },
    email: {
        ...grid.pb_micro,
        ...text.mini,
        ...text.offline,
    },
    ownerPanelActions: {
        ...grid.w100,
        ...grid.row,
        ...grid.justify,
        ...text.offline,
        ...text.mini,
    },
    ownerPanelAction: {
        ...item.pointer,
        ...text.underline,
    },
    usersListWrapper: {
        ...item.overY,
    },
    usersListSearch: {
        ...grid.p_md,
        ...grid.pt_mini,
        ...grid.pb_mini,
        ...item.water,
    },
    usersListItem: {
        ...grid.row,
        ...item.pointer,
        ...item.transitionErase,
        ...item.water,
        ...grid.p_md,
        ...grid.pt_mini,
        ...grid.pb_mini,
        '&:hover': {
            ...item.cloud,
        },
    },
    usersListAvatar: {
        ...grid.col,
        ...grid.normalCenter,
        ...grid.fullHeight,
        ...grid.p_micro,
    },
    usersListInfo: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.space,
        ...grid.pl_md,
        ...item.overNone,
    },
    empty: {
        ...grid.colCenter,
        ...grid.p_mdPlus,
        ...item.water,
        ...text.darkBlue,
        ...text.bold,
        ...text.up,
    },
    userBlock: {
        display: 'flex',
        fontSize: '10px',
        marginTop: '6px',
        lineHeight: 1.2,
    },
    userBlockCaption: {
        width: '112px',
        color: colors.grey,
    },
    userBlockText: {
        width: '114px',
    },
    textTooltip: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}

addStyles(userWindowStyles)
export default userWindowStyles
