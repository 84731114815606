import { addStyles } from 'ethcss'
import { grid, IStyles, item } from '../../../theme'
import { radiusSizes, indents } from 'blocks.app/config'
import { defaultThemeScheduleStyles } from 'organisms/Schedule/Schedule-theme'
import { defaultThemeStyles } from 'blocks.app/sidebar/Sidebar-theme'
import { mediaCSS } from '../../../theme'

import { textSizes } from 'theme/media'

const scheduleAddStyles: IStyles = {
    buttons: {
        ...grid.p_microPlus,
        ...grid.rowCenter,
        ...item.white,
    },
    settings: {
        marginTop: -indents.micro,
    },
    selected: {
        ...grid.pt_md,
    },
    tabs: {
        ...grid.row,
        ...item.white,
        borderRadius: `${radiusSizes.mini}px ${radiusSizes.mini}px 0 0`,
    },
    steps: {
        ...grid.space,
        ...grid.rowCenter,
        ...grid.noWrap,
        ...item.pointer,
        ...grid.pr_md,
        ...grid.pl_md,
        ...grid.pt_mini,
        ...grid.pb_mini,
        fontSize: textSizes.normal,
        textDecoration: 'none',
        ...item.center,
        color: `var(--var-module-schedule-component-add-style-step, ${defaultThemeScheduleStyles['step']})`,
        borderRight: `1px solid var(--var-module-schedule-component-add-style-stepBorder, ${defaultThemeScheduleStyles['stepBorder']})`,
        borderBottom: `1px solid var(--var-module-schedule-component-add-style-stepBorder, ${defaultThemeScheduleStyles['stepBorder']})`,
        '&:last-child': {
            borderRight: 'none',
        },
    },
    stepsActive: {
        color: `var(--var-module-schedule-component-add-style-active-step, ${defaultThemeScheduleStyles['active-step']})!important`,
        borderBottom: 'none!important',
    },
    content: {
        ...grid.p_mini,
        ...grid.pt_md,
        ...item.white,
        borderRadius: `0 0 ${radiusSizes.mini}px ${radiusSizes.mini}px`,
    },
    dNoneModalSave: {
        display: 'none !important',
    },
    modalSaveApprove: {
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-contentBackground, ${defaultThemeStyles.contentBackground})`,
        boxShadow: `var(--var-module-ui-component-dropMenu-style-blockShadow, 0px 4px 4px rgba(0, 0, 0, 0.25))`,
        position: 'fixed',
        zIndex: 9999,
        borderRadius: '8px',
        width: '314px',
        marginTop: '30px',
        left: '50%',
        [mediaCSS.tab]: {
            transform: 'translate(-50%)',
        },
    },
}

addStyles(scheduleAddStyles)
export default scheduleAddStyles
