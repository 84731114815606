import React, { useState } from 'react'
import Measure from 'react-measure'
import Fade from 'blocks.app/searchbar/__fade/searchbar__fade'
import Button from 'blocks.simple/button/button'
import { cn } from 'ethcss'
import styles from './history__filterList.jcss'
import translate from 'core/translate'
import { grid, item } from '../../../theme'
import helpers from '../../../core/helpers'
import { getFilterConfing } from '../__filter/history__filter.config'

interface IRect {
    bounds: {
        top: number
        right: number
        bottom: number
        left: number
        width: number
        height: number
    }
    entry: {
        x: number
        y: number
        width: number
        height: number
        top: number
        right: number
        bottom: number
        left: number
    }
}

export interface IItem {
    action: string[]
    object: string[]
    startDate: string
    endDate: string
    userId: string[]
    [index: string]: any
}

type ItemInfo = {
    id: string | null
    name: string | null
}

const HistoryFilterList = (p_: any) => {
    const [showAll, setShowAll] = useState<boolean>(false)
    const [dimensions, setDimensions] = useState<any>({})
    const [isHidden, setIsHidden] = useState<boolean>(false)

    const _showAll = () => {
        setShowAll(!showAll)
    }
    const contentWidth = (contentRect: IRect) => {
        setDimensions(contentRect.bounds.width)
    }
    const isHideContent = (contentRect: IRect) => {
        setIsHidden(dimensions > contentRect.bounds.width && dimensions !== contentRect.bounds.width)
    }

    const getFilterList = (selected: IItem) => {
        const filterList = []

        for (let key in selected) {
            if (selected.hasOwnProperty(key)) {
                if (key === 'startDate' || key === 'endDate') {
                    filterList.push({
                        id: key,
                        name: helpers.getFormattedLocalDate(selected[key], 'YYYY-MM-DD HH:mm:ss'),
                    })
                } else {
                    selected[key].forEach((name: string) => {
                        filterList.push({ id: key, name })
                    })
                }
            }
        }

        return filterList
    }

    const removeFilterItem = (filterItem: ItemInfo) => {
        if (!filterItem || !filterItem.name || !filterItem.id) return

        const selectedList = [...p_.selected[filterItem.id]]
        selectedList.splice(selectedList.indexOf(filterItem.name), 1)

        const filter = {
            [filterItem.id]: selectedList.join('//'),
        }
        if (filterItem.id === 'startDate') {
            p_.setByStartDateChecked(false)
        }
        if (filterItem.id === 'endDate') {
            p_.setByEndDateChecked(false)
        }
        if (filterItem.id === 'startDate' || filterItem.id === 'endDate') {
            filter[filterItem.id] = ''
        }
        if (p_.onFilterChange) {
            p_.onFilterChange(filter)
        }
    }

    const getFilterName = (filterItem: ItemInfo) => {
        if (filterItem.id === 'startDate' || filterItem.id === 'endDate') {
            return `${translate(filterItem.id)}: ${filterItem.name}`
        }

        if (filterItem.id === 'userId') {
            let user

            if (Object.keys(p_.data).length) {
                user = p_.data.userId.find((user: any) => user.id === filterItem.name)
            }

            return `${translate('byUser')}: ${user ? user.email : filterItem.name}`
        }

        const nameFromConfig: any = getFilterConfing()[`${filterItem.id}Fields`]
        const currentFieldName = nameFromConfig
            ? nameFromConfig.find((field: any) => field.id === filterItem.name)
            : filterItem.id

        if (typeof currentFieldName === 'object') {
            return translate(currentFieldName.name)
        }

        return currentFieldName
    }

    const filterList = getFilterList(p_.selected)

    return (
        <div className={styles.filterOptions}>
            <div
                className={cn(styles.filterContent, {
                    [grid.noWrap]: !showAll,
                })}
            >
                <Measure bounds onResize={contentWidth}>
                    {({ measureRef }) => <div className={cn(grid.fullWidth, item.abs, item.hidden)} ref={measureRef} />}
                </Measure>
                <Measure bounds onResize={isHideContent}>
                    {({ measureRef }) => (
                        <div className={cn(grid.row, { [grid.noWrap]: !showAll })} ref={measureRef}>
                            {filterList.map((filterItem, index) => (
                                <div className={styles.filterItem} key={`filterItem_${index}`}>
                                    <Button
                                        icon={'close'}
                                        textSize={'mini'}
                                        indentSize={'mini'}
                                        animation={false}
                                        iconSize={10}
                                        onIconClick={() => {
                                            removeFilterItem(filterItem)
                                        }}
                                    >
                                        {getFilterName(filterItem)}
                                    </Button>
                                </div>
                            ))}
                        </div>
                    )}
                </Measure>
                {!showAll && <Fade />}
            </div>
            <div className={cn(styles.showAllButton, { [item.hidden]: isHidden })} onClick={_showAll}>
                {showAll ? translate('hide') : translate('showAll')}
            </div>
        </div>
    )
}

export default HistoryFilterList
