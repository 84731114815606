import React from 'react'
import Catalog from '../../../../blocks.app/catalog/catalog'

class Arts extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            selectedInfo: null,
            selectedItems: [],
        }
    }

    componentDidMount() {}

    render() {
        const { selectedItems } = this.state

        return (
            <>
                <Catalog
                    type={'arts'}
                    onSelect={(selectedItems) => this.setState({ selectedItems })}
                    selectedItems={selectedItems}
                    treeView={false}
                    additionalData={{}}
                    fixedHeader={true}
                    isExistRightToolbar={false}
                    hideHeader={true}
                />
            </>
        )
    }
}

export default Arts
