import { addStyles } from 'ethcss'
import { IStyles, grid, item } from 'theme'
import { mediaCSS } from 'theme/media'
import { isHorizontalMenu } from '../../core/helpers/menu'

export const styles: IStyles = {
    breadcrumbs: {
        paddingLeft: '24px',
        marginLeft: -grid.m_mdPlus['margin'],
        marginTop: '10px',
        width: `calc(100% + ${2 * grid.m_mdPlus['margin']}px)`,
        [mediaCSS.tabMini]: {
            marginTop: -grid.m_mini['margin'],
            paddingLeft: '36px',
        },
    },
    breadcrumbs_x: {
        marginLeft: '-36px',
        paddingLeft: '0px !important',
    },
}

addStyles(styles)
