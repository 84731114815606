import { FC } from 'react'
import { MapProps, SearchableMapProps } from '../models/map'
import { SearchableYandexMap } from '../molecules/SearchableYandexMap'
import { YandexMap } from '../molecules/YandexMap'
import { MapApi } from '../api/MapApi'
import { YandexMapApi } from '../api/YandexMapApi'
import { MapFactory } from './MapFactory'

export class YandexMapFactory extends MapFactory {
    createSearchableMap(): FC<SearchableMapProps> {
        return SearchableYandexMap
    }

    createMap(): FC<MapProps> {
        return YandexMap
    }

    createMapApi(): MapApi {
        return new YandexMapApi(this.apiKey)
    }
}
