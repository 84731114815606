import { addStyles } from 'ethcss'
import { grid, IStyles } from 'theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { mediaCSS, text } from 'theme'

const displaysNotificationsStyles: IStyles = {
    wrapper: {
        ...grid.col,
        ...grid.full,
        ...grid.justify,
        overflowY: 'auto',
    },
    header: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.p_md,
        ...grid.pl_big,
        [mediaCSS.tabMini]: {
            ...grid.p_normal,
        },
        ...grid.pl_mini,
        borderBottom: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    selects: {
        ...grid.p_mini,
        ...grid.pt_md,
        [mediaCSS.tabMini]: {
            ...grid.pr_none,
            ...grid.pl_none,
        },
    },
    checkbox: {
        ...grid.pt_md,
        ...grid.w75,
    },
    inputs: {
        ...grid.row,
        ...grid.mt_md,
        ...grid.w50,
    },
    timeInputs: {
        ...grid.row,
        ...grid.pt_mini,
        ...grid.w75,
    },
    timeLeftInputs: {
        ...grid.pt_mini,
        ...grid.w80,
    },
    footer: {
        ...grid.p_md,
        borderTop: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    displaysNotifications__icon: {
        ...grid.mr_md,
        [mediaCSS.tabMini]: {
            ...grid.mr_mini,
        },
    },
    displaysNotifications__title: {
        ...text.mdPlus,
        [mediaCSS.tabMini]: {
            ...text.normal,
        },
    },
    displaysNotifications__content: {
        ...grid.pl_big,
        [mediaCSS.tabMini]: {
            ...grid.pl_mini,
            ...grid.pt_md,
        },
    },
    displaysNotifications__subTitle: {
        ...text.md,
        [mediaCSS.tabMini]: {
            ...text.normalPlus,
        },
    },
    displaysNotifications__inputWrapper_type_startTime: {
        ...grid.w30,
        ...grid.mr_big,
        [mediaCSS.tabMini]: {
            ...grid.w100,
            ...grid.mr_none,
            ...grid.mb_md,
        },
    },
    displaysNotifications__inputWrapper_type_endTime: {
        ...grid.w30,
        [mediaCSS.tabMini]: {
            ...grid.w100,
            ...grid.mb_md,
        },
    },
    displaysNotifications__inputWrapper_type_sendInterval: {
        ...grid.w70,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    inputContainer: {
        ...grid.row,
    },
    leftColumn: {
        ...grid.col,
        ...grid.w30,
        [mediaCSS.desktopS]: {
            ...grid.w100,
        },
    },
    rightColumn: {
        ...grid.col,
        ...grid.w30,
        [mediaCSS.desktopS]: {
            ...grid.w100,
        },
    },
    checkboxPt: {
        ...grid.pt_md,
    },
    dNone: {
        visibility: 'hidden',
        [mediaCSS.desktopS]: {
            display: 'none',
        },
    },
    customSelect: {
        '&__control': {
            border: '1px solid #F0F0F0F0 !important',
            boxShadow: 'none !important',
            '&:hover': {
                cursor: 'pointer !important',
            },
        },
        '&__option': {
            cursor: 'pointer !important',
            '&--is-focused': {
                background: 'hsl(0,0%,90%) !important',
            },
            '&--is-selected': {
                color: 'hsl(0,0%,20%) !important',
                background: 'hsl(0,0%,90%) !important',
            },
        },
        '&__indicator-separator': {
            display: 'none !important',
        },
        '&__multi-value__remove': {
            '&:hover': {
                background: 'transparent !important',
            },
        },
    },
}

addStyles(displaysNotificationsStyles)
export default displaysNotificationsStyles
