import { TableCell } from 'atoms/Table'
import Icon from 'blocks.simple/icon/icon'
import { IRecordWithStrings } from 'core/helpers'
import translate from 'core/translate'
import { CatalogWithPaginationContext } from 'organisms/CatalogWithPagination'
import { ICatalogWithPaginationListItemProps } from 'organisms/CatalogWithPagination/CatalogWithPagination-types'
import React, { FC, Fragment, memo, useContext } from 'react'
import { indents } from 'theme'
import { styles } from './ScoreboardsListItem-styles'

const ScoreboardsListItemComponent: FC<ICatalogWithPaginationListItemProps> = ({
    item,
    isActive,
    className,
    nestingLevel,
}) => {
    const color: IRecordWithStrings = { offline: 'offline', error: 'error', online: 'online' }
    const { methods } = useContext(CatalogWithPaginationContext)
    const { isFolder } = methods
    const { id, name, status = 'offline' } = item

    const getItemStyle = () => {
        return {
            paddingLeft: nestingLevel * indents.big,
        }
    }

    return (
        <Fragment>
            <TableCell>
                <div className={styles.ScoreboardsListItem__tableCell}>{id}</div>
            </TableCell>
            <TableCell>
                <div className={styles.ScoreboardsListItem__name} style={getItemStyle()}>
                    <div className={styles.ScoreboardsListItem__icon}>
                        <Icon type={isFolder(item) ? 'files' : 'display'} size={20} color={color[status]} />
                    </div>
                    <div className={styles.ScoreboardsListItem__tableCell}>{name}</div>
                </div>
            </TableCell>
            <TableCell>
                <div className={styles.ScoreboardsListItem__tableCell}>{translate(status)}</div>
            </TableCell>
        </Fragment>
    )
}

export const ScoreboardsListItem = memo(ScoreboardsListItemComponent)
