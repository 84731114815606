import React from 'react'
import LoaderItem from '../__item/loader__item'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'

export default (p_: { active: boolean }) => (
    <div className={`${grid.w100} ${grid.rowCenter} ${grid.p_md} ${p_.active ? '' : item.hidden}`}>
        <LoaderItem small={true} />
    </div>
)
