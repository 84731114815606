import { addStyles } from 'ethcss'
import { radiusSizes } from 'blocks.app/config'
import { grid, IStyles, item, mediaCSS } from '../../theme'

const updateDevicesStyles: IStyles = {
    wrapper: {
        ...grid.hspace,
        ...item.white,
        borderRadius: radiusSizes.mini,
    },
    tabs: {
        ...grid.row,
        borderRadius: radiusSizes.mini,
    },
    title: {
        ...grid.p_md,
        ...grid.pb_none,
        ...grid.mt_micro,
    },
    tasksList: {
        ...grid.pb_mdPlus,
        ...grid.mt_md,
        ...grid.space,
    },
    settings: {
        ...grid.row,
        ...grid.p_md,
        ...grid.pr_mdPlus,
        ...grid.pl_mdPlus,
    },
    updateDevicesUpdateDateWrapper: {
        ...grid.w20,
        ...grid.mr_big,
        [mediaCSS.tabMini]: {
            ...grid.w100,
            ...grid.mr_none,
            ...grid.mb_md,
        },
    },
    updateDevicesUpdateTimeWrapper: {
        ...grid.w20,
        [mediaCSS.tabMini]: {
            ...grid.w100,
            ...grid.mb_md,
        },
    },
    updateDevicesUpdateNowCheckboxWrapper: {
        ...grid.flexEnd,
        [mediaCSS.tabMini]: {
            ...grid.flexStart,
        },
        ...grid.pb_micro,
    },
    updateDevicesUpdateNowCheckbox: {
        ...grid.ml_big,
        [mediaCSS.tabMini]: {
            ...grid.ml_none,
        },
    },
    updateDevicesPlatformWrapper: {
        ...grid.col,
        ...grid.w45,
        ...grid.pr_md,
        ...grid.pb_md,
        [mediaCSS.mob]: {
            ...grid.w100,
            ...grid.pr_none,
        },
    },
    updateDevicesUpdateButtonWrapper: {
        ...grid.row,
        ...grid.fullWidth,
        ...grid.center,
        ...grid.mt_mdPlus,
        [mediaCSS.tabMini]: {
            ...grid.mt_mini,
        },
    },
}

addStyles(updateDevicesStyles)
export default updateDevicesStyles
