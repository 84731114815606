import { addStyles } from 'ethcss'
import { grid, IStyles, item } from '../../../theme'
import { defaultThemeStyles as defaultThemeSidebarStyles } from 'blocks.app/sidebar/Sidebar-theme'

const scheduleToolbarStyles: IStyles = {
    icon: {
        display: 'block',
        ...grid.p_mini,
        color: `var(--var-module-sidebar-component-schedule-style-iconColor, ${defaultThemeSidebarStyles['schedule-iconColor']})`,
        backgroundColor: `var(--var-module-sidebar-component-schedule-style-icon, ${defaultThemeSidebarStyles['schedule-icon']})`,
        textAlign: 'center',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    menuItem: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.center,
        ...grid.p_mini,
        cursor: 'pointer',
        position: 'relative',
        color: `var(--var-module-sidebar-component-sidebar-style-menuText, ${defaultThemeSidebarStyles.menuText})`,
        ...item.transitionErase,
        '&:hover': {
            color: `var(--var-module-sidebar-component-sidebar-style-menuTextHover, ${defaultThemeSidebarStyles.menuTextHover})`,
        },
    },
}

addStyles(scheduleToolbarStyles)
export default scheduleToolbarStyles
