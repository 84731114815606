import { colors } from 'theme'

export const defaultThemeStyles = {
    'default-background': colors.white,
    'default-shadow': 'none',
    'default-border': 'transparent',
    'default-textMain': colors.grey,
    'default-icon': colors.grey,
    'default-active': colors.blackLight,
    'fill-background': colors.grey,
    'fill-shadow': 'none',
    'fill-border': 'transparent',
    'fill-textMain': colors.white,
    'fill-icon': colors.white,
    'fill-active': colors.blackLight,
    'withBorder-textMain': colors.grey,
    'withBorder-shadow': 'none',
    'withBorder-background': colors.white,
    'withBorder-border': colors.grey,
    'withBorder-activeBorder': colors.black,
    'withBorder-icon': colors.black,
    'withBorder-active': colors.blackLight,
    'withShadow-textMain': colors.black,
    'withShadow-background': colors.white,
    'withShadow-shadow': '2px 3px 4px 0px rgba(0, 0, 0, 0.25)',
    'withShadow-border': 'transparent',
    'withShadow-icon': colors.black,
    'withShadow-active': colors.blackLight,
    'list-textMain': colors.grey,
    'list-background': colors.white,
    'list-shadow': '2px 3px 4px 0px rgba(0, 0, 0, 0.25)',
    'list-border': 'transparent',
    'list-icon': colors.black,
    'list-active': colors.blackLight,
    'error-background': colors.error,
    'error-shadow': 'none',
    'error-border': 'transparent',
    'error-textMain': colors.white,
    'error-icon': colors.white,
    'error-active': colors.error,
}
