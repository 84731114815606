import React, { FC, useRef } from 'react'
import Collapse from 'blocks.simple/collapse/collapse'
import EditText from 'blocks.simple/editText/editText'
import Icon from 'blocks.simple/icon/icon'
import Button from 'blocks.simple/button/button'
import Tippy from 'blocks.simple/tippy/tippy'
import helpers from 'core/helpers'
import translate from 'core/translate'
import styles_ from '../settings.jcss'
import { cn } from 'ethcss'
import styles from './settings__emails.jcss'
import { grid } from 'theme'
import { isEqual } from 'lodash'
import { useNotificationsEmail } from './settings__emails__hooks'
import Modal from '../../../blocks.simple/modal/modal'

const maxNotificationsEmails = 5

interface ISettingsEmailProps {
    notificationsEmail: string[]
}

export const SettingsEmails: FC<ISettingsEmailProps> = ({ notificationsEmail }) => {
    const {
        state,
        updateNotificationsEmails,
        deleteMail,
        addMail,
        saveEmail,
        confirmDelete,
        collapseRef,
    } = useNotificationsEmail({
        notificationsEmail,
    })

    return (
        <Collapse
            refObj={collapseRef}
            title={() => translate('mailForNotifications')}
            className={cn(styles_.background, grid.mb_md)}
        >
            {!!state.notificationsEmails.length && (
                <div className={styles.emails}>
                    {state.notificationsEmails.map((notificationsEmail: string, index: number) => (
                        // @ts-ignore
                        <div className={styles.email} key={`notificationsEmail_${index}`}>
                            <div className={grid.space}>
                                <EditText
                                    value={notificationsEmail}
                                    placeholder={translate('enterYourEmail')}
                                    mod="withBorder"
                                    onChange={(value: string) => updateNotificationsEmails(value, index)}
                                    error={
                                        state.notificationsEmailError && state.notificationsEmailError[index]
                                            ? translate('emailEmpty')
                                            : null
                                    }
                                    disabled={!helpers.isAvailable({ key: 'settings', action: 'update' })}
                                />
                            </div>
                            {helpers.isAvailable({ key: 'settings', action: 'update' }) && (
                                <div className={styles.delete}>
                                    <Icon type={'delete'} onClick={() => confirmDelete(index)} />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
            {helpers.isAvailable({ key: 'settings', action: 'update' }) && (
                <div className={cn(grid.row, grid.center)}>
                    <Tippy
                        title={`${translate('maxNotificationsEmails')} ${maxNotificationsEmails}`}
                        open={!(state.notificationsEmails.length < maxNotificationsEmails) && state.isShowTooltip}
                    >
                        <Button
                            disabled={!(state.notificationsEmails.length < maxNotificationsEmails)}
                            onClick={addMail}
                            mod="withBorder"
                        >
                            {translate('addMail')}
                        </Button>
                    </Tippy>
                    <Button
                        className={cn(grid.ml_md)}
                        mod="fillAccent"
                        onClick={saveEmail}
                        disabled={
                            isEqual(state.notificationsEmails, state.notificationsEmailsOriginal) &&
                            !state.isNotificationEmailsListUpdatePending
                        }
                    >
                        {translate('save')}
                    </Button>
                </div>
            )}
            <Modal open={state.confirmDeleteIndex !== null} showCloseIcon={false} mod={'clear'} transitionName={'fade'}>
                <div className={styles.modalWrapper}>
                    <div className={cn(styles.modalInner)}>
                        <div className={styles.closeIcon}>
                            <Icon
                                type={'close_tiny'}
                                onClick={() => {
                                    confirmDelete(null)
                                }}
                            />
                        </div>
                        <div className={styles.title}>{translate('deleteEmailTitle')}</div>
                        <div className={styles.description}>
                            <div>{translate('deleteEmailQuestion')}</div>
                            <div>{translate('deleteEmailTip')}</div>
                        </div>
                        <div className={styles.buttons}>
                            <Button
                                mod={'withBorder'}
                                className={cn(styles.leftButton)}
                                textSize={'mini'}
                                indentSize={'mini'}
                                rounded={'full_normal'}
                                onClick={() => confirmDelete(null)}
                            >
                                {translate('cancel')}
                            </Button>
                            <Button
                                mod={'fill'}
                                textSize={'mini'}
                                indentSize={'mini'}
                                rounded={'full_normal'}
                                onClick={deleteMail}
                            >
                                {translate('delete')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Collapse>
    )
}
