import React from 'react'
import Icon from 'blocks.simple/icon/icon'

import translate from 'core/translate'

import styles from './videoEditor__content_errors.jcss'
import grid from 'blocks.simple/grid/grid.jcss'

const VideoEditorContentErrors = (props: { errors: any }) => {
    const { errors } = props

    const renderInfo = () => {
        return (
            <div className={styles.tooltip}>
                {errors.map((error: any, index: number) => (
                    <div className={styles.line} key={index}>
                        <div className={styles.title}>{translate(error.errorCode)}</div>
                        {Object.keys(error.options).map((option, index) => (
                            <div className={grid.pt_micro} key={`option_${index}`}>
                                {translate('currentValue')}: {error.options[option]}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        )
    }

    if (!errors) {
        return null
    }

    return (
        <div className={styles.wrapper}>
            <div className={grid.mr_mini}>{translate('videoHasErrorDescription')}</div>
            <Icon
                size={24}
                color={'white'}
                type={'question'}
                tooltip={{
                    // trigger: 'click',
                    // interactive: true,
                    html: renderInfo(),
                }}
            />
        </div>
    )
}

export default VideoEditorContentErrors
