import { addStyles } from 'ethcss'
import { colors, indents, IStyles, mediaCSS, radiusSizes, textSizes } from 'theme'
import grid from 'theme/grid/styles'

const MAX_LOGO_WIDTH = 110
const MAX_LOGO_HEIGHT = 82

export const styles: IStyles = {
    platform: {
        background: `var(--var-module-displays-component-platform-background)`,
        borderRadius: radiusSizes.mini,
    },
    platform_custom_theme: {
        background: '#F0F0F0',
        borderRadius: radiusSizes.mini,
    },
    platform__header: {
        ...grid.flex,
        ...grid.normalCenter,
        ...grid.justify,
        padding: '30px 60px 30px 30px',
        [mediaCSS.tab]: {
            display: 'block',
            padding: '20px',
        },
    },
    platform__info: {
        ...grid.flex,
        ...grid.normalCenter,
        [mediaCSS.tab]: {
            ...grid.row,
            ...grid.justify,
        },
        [mediaCSS.mob]: {
            ...grid.col,
        },
    },
    platform__logo: {
        ...grid.flex,
        ...grid.normalCenter,
        ...grid.center,
        width: MAX_LOGO_WIDTH,
        height: MAX_LOGO_HEIGHT,
        [mediaCSS.tab]: {
            order: 2,
        },
        [mediaCSS.mob]: {
            order: 1,
            paddingBottom: indents.md,
        },
    },
    platform__title: {
        paddingBottom: indents.mini,
        fontSize: textSizes.mdPlus,
        fontWeight: 'bold',
        [mediaCSS.mob]: {
            fontSize: textSizes.md,
        },
    },
    platform__description: {
        fontSize: textSizes.md,
        [mediaCSS.mob]: {
            fontSize: textSizes.normal,
        },
    },
    platform__content: {
        paddingLeft: 45,
        [mediaCSS.tab]: {
            order: 1,
            paddingLeft: 0,
        },
        [mediaCSS.mob]: {
            order: 2,
        },
    },
    platform__table: {
        overflowX: 'auto',
        maxWidth: '100%',
    },
    platform__link: {
        [mediaCSS.tab]: {
            paddingTop: indents.mini,
        },
        [mediaCSS.mob]: {
            paddingTop: indents.md,
        },
    },
}

addStyles(styles)
