import React from 'react'
import AppFeaturesMethods from './app__features.local.methods'
import Notification from 'blocks.simple/notification/notification'
import Changelog from 'blocks.simple/changelog/changelog'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import { api } from 'core/api/ConnectionManager'

import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { userActions } from 'blocks.app/user/user.state'
import { connect } from 'react-redux'

class AppFeatures extends AppFeaturesMethods {
    render = () => {
        const p_ = this.props
        const s_ = this.state

        return (
            <div>
                <Notification show={!!p_.newFeatures.length} position={'top'} color={'blackLight'}>
                    <div className={cn(grid.row, grid.normalCenter)}>
                        <div className={grid.mr_big}>{translate('newFeatures')}</div>
                        <div className={grid.row}>
                            <Button className={cn(grid.mr_md)} onClick={this.onViewed}>
                                {translate('dontShowAgain')}
                            </Button>
                            <Button onClick={() => this.setState({ open: true })}>{translate('view')}</Button>
                        </div>
                    </div>
                </Notification>
                <Changelog
                    lang={p_.lang}
                    open={s_.open}
                    options={p_.newFeatures}
                    onSkip={this.onViewed}
                    onNext={function (newFeatureId) {
                        api.send('newFeatureViewed', { newFeatureId })
                    }}
                />
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearFeatures: () => dispatch(userActions.clearFeatures()),
    }
}

export default connect(null, mapDispatchToProps)(AppFeatures)
