import React from 'react'
import EditText from 'blocks.simple/editText/editText'
import { cn } from 'ethcss'
import { grid, item } from '../../theme'
import translate from 'core/translate'

interface IEditableTextProps {
    id?: string | number
    type?: string
    placeholder?: string
    label?: string | React.ReactNode
    mod: string
    text: string | number | undefined
    onCreate: (value: string | number) => void
    onUpdate: (value: any, cb?: any) => void
    onSaveEmpty: () => void
    onChange?: any
    validate: (value: any) => boolean
    disableDefaultFocus?: boolean
    disabled?: boolean
    hideIcon: boolean
    iconColor?: string
    revertEmptyValue?: boolean
    className?: string
    containerClassName?: string | {}
    onEndTyping: (value: string) => void
    indentSize?: string
    rounded?: string
    rows?: number
}

class EditableText extends React.Component<IEditableTextProps> {
    static defaultProps: {
        onCreate: () => void
        onSaveEmpty: () => void
        mod: string
        hideIcon: boolean
        onEndTyping: () => void
        onUpdate: () => void
        validate: (text: string) => boolean
    }
    inputFocus: any
    updating: boolean = false
    state: { text: string | number; focused: boolean }

    constructor(p_: IEditableTextProps) {
        super(p_)

        this.updating = false
        this.state = {
            text: p_.text ? p_.text : '',
            focused: !p_.id,
        }
        this.onBlur = this.onBlur.bind(this)
        this.save = this.save.bind(this)
    }
    componentDidUpdate(prevProps: IEditableTextProps) {
        if (!this.updating && this.props.text !== prevProps.text) {
            this.setState({ text: this.props.text })
        }
    }
    onBlur() {
        this.setState({ focused: false })
        this.save()
    }
    save() {
        const s_ = this.state
        const p_ = this.props

        if (!p_.validate(s_.text)) {
            if (!p_.id) {
                p_.onSaveEmpty()
                if (p_.revertEmptyValue) {
                    this.setState({ text: p_.text })
                }
            } else {
                this.setState({ text: p_.text })
            }
        } else {
            if (!p_.id) {
                p_.onCreate(s_.text)
            } else {
                this.updating = true
                p_.onUpdate(s_.text, () => {
                    this.updating = false
                    if (this.inputFocus) {
                        this.inputFocus.blur()
                    }
                })
            }
        }
    }
    render() {
        const s_ = this.state
        const p_ = this.props

        // @ts-ignore
        return (
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    this.save()
                }}
                className={cn(grid.w100, p_.containerClassName)}
            >
                <EditText
                    type={p_.type}
                    label={p_.label}
                    inputRef={(input: React.ReactHTMLElement<HTMLInputElement>) => (this.inputFocus = input)}
                    className={cn({ [item.pointer]: !s_.focused }, p_.className)}
                    icon={p_.id && !p_.disabled && !p_.hideIcon ? 'pencil' : undefined}
                    iconColor={p_.iconColor || 'grey'}
                    iconSize={'14'}
                    value={s_.text}
                    mod={p_.mod}
                    onChange={(text: string) => {
                        this.setState({ text })
                        if (p_.onChange && !p_.id) {
                            p_.onChange(text)
                        }
                    }}
                    defaultFocus={!p_.id && !p_.disableDefaultFocus}
                    onIconClick={(e: MouseEvent, input: HTMLInputElement) => input.focus()}
                    onBlur={this.onBlur}
                    onFocus={() => this.setState({ focused: true })}
                    placeholder={p_.placeholder || translate('emptyLabelNameError')}
                    showIconOnHover={true}
                    disabled={p_.disabled}
                    onEndTyping={p_.onEndTyping}
                    indentSize={p_.indentSize}
                    rounded={p_.rounded}
                />
            </form>
        )
    }
}

EditableText.defaultProps = {
    onCreate: () => {},
    onUpdate: () => {},
    onEndTyping: () => {},
    onSaveEmpty: () => {},
    mod: 'text',
    hideIcon: false,
    validate: (text) => !!text,
}

export default EditableText
