import React from 'react'
import Content from 'blocks.app/content/content'
import CustomList from 'blocks.app/customList/customList'
import InstancesCatalogSamsoniteMethods from './instacesCatalog_samsonite.local.methods'
import Link from 'blocks.simple/link/link'

import translate from 'core/translate'
import styles from './instancesCatalog_samsonite.jcss'
import { Steps } from 'atoms/Steps/Steps-view'
import { getURLSearchParamsString } from 'features/routes/utils'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

const categories = ['sites', 'videos', 'advertising']

class InstancesCatalogSamsonite extends InstancesCatalogSamsoniteMethods {
    render() {
        const s_ = this.state
        const p_ = this.props

        return (
            <Content title={'samsoniteCatalog'}>
                <div className={styles.wrapper}>
                    <Steps className={styles.tabs} active={s_.category}>
                        {categories.map((item, index) => (
                            <Link
                                id={item}
                                key={`samsoniteCategory_${index}`}
                                to={{
                                    pathname: p_.location.pathname,
                                    search: getURLSearchParamsString({ category: item }),
                                }}
                            >
                                {translate(item)}
                            </Link>
                        ))}
                    </Steps>
                    <CustomList
                        key={s_.category}
                        type={`samsonite${s_.category.charAt(0).toUpperCase() + s_.category.slice(1)}`}
                        onSelect={(selectedItems) => this.setState({ selectedItems })}
                        selectedItems={s_.selectedItems}
                        isDelayTime
                        additionalData={{}}
                    />
                </div>
            </Content>
        )
    }
}

const exportInstancesCatalogSamsonite = checkAvailableModule('instancesCatalog/samsonite')
    ? InstancesCatalogSamsonite
    : null

export default exportInstancesCatalogSamsonite
