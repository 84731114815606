import React from 'react'
import EditableText from 'blocks.simple/editableText/editableText'
import Button from 'blocks.simple/button/button'
import CustomListHeader, { ICustomListHeaderProps } from '../../__header/customList__header'
import CatalogSelectContent from 'blocks.app/catalog/_select/_content/catalog_select_content'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import helpers from 'core/helpers'
import grid from 'blocks.simple/grid/grid.jcss'
import { cn } from 'ethcss'

export default {
    getMethod: 'getBurgersCatalogByType',
    onCatalogUpdatedMethod: 'burgersCatalogUpdated',
    emptyDescription: () => translate('noItemsDescription'),
    emptyIcon: 'not_allow',
    settingsPrefixes: 'smallBurgers',
    type: 'small_burgers',
    getItemModel: (
        dataItem: { __view: { selected: boolean }; source: { __view: { selected: boolean } } },
        getSelected: (item: { __view: { selected: boolean }; source: { __view: { selected: boolean } } }) => any
    ) => {
        const item = { ...dataItem }

        if (item.source && !item.source.__view) {
            item.source.__view = {
                selected: getSelected(item),
            }
        }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    isEqual: (firstItem: { id: number }, secondItem: { id: number }) => firstItem.id === secondItem.id,
    ...helpers.createListeners({
        category: null,
        type: 'small_burgers',
        onCreate: 'burgersProductCreated',
        onUpdate: 'burgersProductUpdated',
        onDelete: 'burgersProductDeleted',
    }),
    fields: () => [
        { id: 'source', name: translate('image') },
        { id: 'title', name: translate('name') },
        { id: 'price', name: translate('price') },
    ],
    init(callback: any) {
        // @ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    headerFilters: (p_: ICustomListHeaderProps) => (
        <CustomListHeader
            onAdd={() => p_.onAddFakeLine({ title: '', price: '', source: null })}
            title={translate('smallBurgersCatalog')}
            type={p_.type}
            onDelete={() => {
                const id = p_.selectedItems.map((selectedItem) => selectedItem.id)
                if (id.length > 0) {
                    api.send('deleteBurgersProduct', { id })
                }
                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            }}
            {...p_}
        />
    ),
    list: {
        title: (
            p_: { id: number; title: string },
            active: boolean,
            listProps: {
                fakeLine: {}
                onAddFakeLine: (value?: {}) => void
                source: { id: number }
            }
        ) => (
            <EditableText
                id={p_.id}
                text={p_.title}
                onChange={(title: string) => {
                    listProps.onAddFakeLine({ ...listProps.fakeLine, title })
                }}
                onUpdate={(title, cb) => {
                    api.send('updateBurgersProduct', {
                        catalogType: 'small_burgers',
                        id: p_.id,
                        title,
                        webAppSourceId: listProps.source.id,
                    }).then(cb)
                }}
            />
        ),
        price: (
            p_: { id: number; price: string },
            active: boolean,
            listProps: {
                fakeLine: { source: { id: number }; title: string; price: string }
                onAddFakeLine: (value?: {}) => void
                source: { id: number }
            }
        ) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.id })}>
                <div className={cn({ [grid.fullWidth]: p_.id, [grid.space]: !p_.id })}>
                    <EditableText
                        id={p_.id}
                        text={p_.price || ''}
                        type={'number'}
                        placeholder={translate('emptyLabelPriceError')}
                        disableDefaultFocus={true}
                        onChange={(price: string) => {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, price })
                        }}
                        onUpdate={(price, cb) => {
                            api.send('updateBurgersProduct', {
                                catalogType: 'small_burgers',
                                id: p_.id,
                                price,
                                webAppSourceId: listProps.source.id,
                            }).then(cb)
                        }}
                    />
                </div>
                {!p_.id && (
                    <Button
                        className={cn(grid.ml_micro, grid.mr_mini)}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            listProps.onAddFakeLine()
                        }}
                    >
                        {translate('cancel')}
                    </Button>
                )}
                {!p_.id && (
                    <Button
                        mod={'fill'}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            api.send('createBurgersProduct', {
                                catalogType: 'small_burgers',
                                sourceId: listProps.fakeLine.source.id,
                                ...listProps.fakeLine,
                                webAppSourceId: listProps.source.id,
                            })
                        }}
                        disabled={!listProps.fakeLine.title || !listProps.fakeLine.price || !listProps.fakeLine.source}
                    >
                        {translate('save')}
                    </Button>
                )}
            </div>
        ),
        source: (
            p_: { id: number; source: { id: number } },
            active: boolean,
            listProps: {
                fakeLine: { source: { id: number }; title: string; price: string }
                onAddFakeLine: (value?: {}) => void
                source: { id: number }
            }
        ) => (
            <div className={cn(grid.fullWidth, grid.colCenter)}>
                <div className={p_.source ? grid.w15 : null}>
                    <CatalogSelectContent
                        type={'media'}
                        selectedInfo={p_.source}
                        onSelect={(selectedMedia: { id: number }) => {
                            if (p_.id) {
                                api.send('updateBurgersProduct', {
                                    catalogType: 'small_burgers',
                                    id: p_.id,
                                    sourceId: selectedMedia.id,
                                    webAppSourceId: listProps.source.id,
                                })
                            } else {
                                listProps.onAddFakeLine({ ...listProps.fakeLine, source: selectedMedia })
                            }
                        }}
                    />
                </div>
            </div>
        ),
    },
    draggable: true,
    onDeleteListItem: (listItem: { id: number }) => {
        api.send('deleteBurgersProduct', { id: listItem.id })
    },
    fixedColWidth: true,
}
