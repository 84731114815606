import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { textSizes } from 'theme/media'
import { IStyles } from '../../../theme'

const previewMediaStyles: IStyles = {
    controls: {
        ...item.abs,
        ...grid.fullWidth,
        ...item.blackOpacity,
        zIndex: 3,
        bottom: 0,
    },
    rangeVolume: {
        width: 300,
    },
    timeLine: {
        ...item.whiteColor,
    },
    icons: {
        ...grid.colCenter,
        ...grid.full,
        ...grid.center,
        position: 'absolute!important',
    },
    error: {
        ...item.whiteColor,
        ...grid.mt_md,
        fontSize: textSizes.md,
        textAlign: 'center',
    },
    player: {
        ...item.abs,
    },
}

addStyles(previewMediaStyles)
export default previewMediaStyles
