import React from 'react'
import PropTypes from 'prop-types'
import Range from 'blocks.simple/range/range'
import { Radio } from 'atoms/Radio'
import Tippy from 'blocks.simple/tippy/tippy'
import { SelectTime } from 'blocks.app/scheduleSettings/scheduleSettings'
import translate from 'core/translate'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import moment from 'moment/moment'
import helpers from 'core/helpers'
import { Typography } from 'atoms/Typography'

import styles from './deviceSettings__brightness.jcss'

const DeviceSettingsBrightness = (p_: {
    settings: {
        enabled: boolean
        timers: { time: string; enabled: boolean; brightness: number }[]
        // level: number,
        // startTime: string,
        // endTime: string
    }
    enabled: boolean
    onChange: (settings: string, value: string) => void
}) => {
    if (!p_.enabled) {
        return null
    }

    const timeOptions = helpers.getTimeOptions()

    function onEnable() {
        const settings: any = helpers.deepCopy(p_.settings)

        settings.enabled = true

        p_.onChange(settings, 'brightnessTimers')
    }

    function onDisable() {
        const settings: any = helpers.deepCopy(p_.settings)

        settings.enabled = false

        p_.onChange(settings, 'brightnessTimers')
    }

    function onChangeBrightnessLevel(value: number, index: number) {
        const settings: any = helpers.deepCopy(p_.settings)

        settings.timers[index].brightness = value

        p_.onChange(settings, 'brightnessTimers')
    }

    function onChangeTime(value: string, index: number) {
        if (moment(value, 'HH:mm:ss', true).isValid() || moment(value, 'HH:mm', true).isValid()) {
            const settings: any = helpers.deepCopy(p_.settings)
            const time = moment(`${moment().format('YYYY-MM-DD')} ${value}`).format('HH:mm')

            settings.timers[index].time = time

            p_.onChange(settings, 'brightnessTimers')
        }
    }

    return (
        <div className={styles.wrapper}>
            <Typography className={cn(styles.title)} type="title">
                {translate('brightness')}
            </Typography>
            <div className={styles.radioWrapper}>
                <Radio
                    className={styles.radio}
                    selected={p_.settings.enabled}
                    label={translate('on')}
                    onClick={onEnable}
                />
                <Radio
                    wrapperClassName={styles.radio}
                    selected={!p_.settings.enabled}
                    label={translate('off')}
                    onClick={onDisable}
                />
            </div>
            <div className={cn(grid.row, grid.mt_md, { [item.disableWrapper]: !p_.settings.enabled })}>
                {p_.settings.timers.map((timer, index) => (
                    <div key={index} className={styles.timersWrapper}>
                        <div className={grid.mb_mini}>{translate('brightnessLevel')}</div>
                        <Tippy title={`${timer.brightness}`} className={cn(grid.mb_md)}>
                            <Range
                                value={timer.brightness}
                                min={1}
                                max={100}
                                onChange={function (value: number) {
                                    onChangeBrightnessLevel(value, index)
                                }}
                                tooltip
                            />
                        </Tippy>
                        <SelectTime
                            time={{
                                label: translate('time'),
                                type: 'tel',
                                mask: '99:99',
                                value: timer.time,
                                mod: 'darkBlue',
                                id: `brightnessTimer_${index}`,
                            }}
                            timeOptions={timeOptions}
                            onChange={function (value) {
                                onChangeTime(value, index)
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

// DeviceSettingsBrightness.propTypes = {
//     settings: PropTypes.shape({
//         enabled: PropTypes.bool,
//         timers: PropTypes.array,
//     }),
//     onChange: PropTypes.func.isRequired,
//     enabled: PropTypes.bool,
// }

export default DeviceSettingsBrightness
