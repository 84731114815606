import React, { FC } from 'react'
import Icon from 'blocks.simple/icon/icon'
import Badge from 'blocks.simple/notifyBadge/notifyBadge'
import { styles } from './MapMarker-styles'
import { MapMarkerProps } from './MapMarker-types'
import { isNotEmptyArray } from 'core/utils/coreUtil'

export const MapMarker: FC<MapMarkerProps> = ({ color, onClick, points, isActive }) => {
    const getColorMarker = () => {
        if (!points || !isNotEmptyArray(points)) {
            return color === 'offline' ? 'grey' : color
        }

        const colors: string[] = points.map((point) => point.status)

        const preferredColors = ['error', 'online']
        let res = null

        preferredColors.some((color) => {
            const found = colors.find((c) => c === color)

            if (!found) {
                res = 'grey'
                return false
            }

            res = found
            return true
        })

        return res
    }

    const markerColor = getColorMarker()

    return (
        <div className={styles.container}>
            <Icon
                containerClass={isActive ? styles.markerActive : styles.marker}
                onClick={onClick}
                color={markerColor}
                type={isActive ? 'locationActive' : 'location'}
                size={isActive ? 60 : 40}
            />

            {points && (
                <div className={isActive ? styles.badgeActive : styles.badge} onClick={onClick}>
                    <Badge className={styles.clusterBudge} type={markerColor} label={points.length} />
                </div>
            )}
        </div>
    )
}
