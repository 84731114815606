import React from 'react'
import config from '../index'
import translate from 'core/translate'
import CustomListHeader, { ICustomListHeaderProps } from '../../../__header/customList__header'
import { api } from 'core/api/ConnectionManager'

export default {
    ...config,
    category: 'advertising',
    type: 'samsonite',
    headerFilters: (p_: ICustomListHeaderProps) => (
        <CustomListHeader
            onAdd={() => p_.onAddFakeLine({ name: '', source: null })}
            title={translate('advertising')}
            type={p_.type}
            onDelete={() => {
                const id = p_.selectedItems.map((selectedItem) => selectedItem.id)
                if (id.length > 0) {
                    api.send('samsoniteDeleteCatalogItem', { id })
                }
                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            }}
            {...p_}
        />
    ),
}
