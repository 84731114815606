import { addStyles } from 'ethcss'
import { grid, item, IStyles, mediaCSS } from '../../theme'
import { radiusSizes, indents } from 'blocks.app/config'
import { defaultThemeStyles } from './dropdown.theme'
import { colors } from 'theme'

const mediaHeightQuery = '@media (max-width: 570px)'

const dropdownStyles: IStyles = {
    wrapperFull: {
        ...item.rel,
        zIndex: 100,
    },
    relative: {
        position: 'relative !important',
    },
    hoverIconWrapper() {
        return {
            [`& .${this.iconSearchable}`]: {
                ...item.hidden,
            },
            [`&:hover .${this.iconSearchable}`]: {
                ...item.visible,
            },
        }
    },
    wrapperFullOpen: {
        zIndex: 10001,
    },
    wrapper: {
        ...grid.w100,
    },
    content: {
        ...grid.w100,
        display: 'none',
        ...item.abs,
        backgroundColor: '#FFFFFF',
        border: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${colors.grey})`,
    },
    validationBorder: {
        border: '1px solid red !important',
    },
    button_type_withBorderV: {
        borderTop: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
        borderBottom: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
        color: `var(--var-module-ui-component-button-style-list-textMain, ${defaultThemeStyles['list-textMain']})`,
        background: `var(--var-module-ui-component-button-style-list-background, ${defaultThemeStyles['list-background']})`,
    },
    content_type_withShadow: {
        background: `var(--var-module-ui-component-dropdown-style-withShadow-background, ${defaultThemeStyles['withShadow-background']})`,
        boxShadow: `var(--var-module-ui-component-dropdown-style-withShadow-shadow, ${defaultThemeStyles['withShadow-shadow']})`,
        border: `1px solid var(--var-module-ui-component-dropdown-style-withShadow-border, ${defaultThemeStyles['withShadow-border']})`,
    },
    content_type_withLightBorder: {
        border: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
    },
    content_type_default: {
        background: `var(--var-module-ui-component-dropdown-style-default-background, ${defaultThemeStyles['default-background']})`,
        boxShadow: `var(--var-module-ui-component-dropdown-style-default-shadow, ${defaultThemeStyles['default-shadow']})`,
        border: `1px solid var(--var-module-ui-component-dropdown-style-default-border, ${defaultThemeStyles['default-border']})`,
    },
    content_type_none: {
        background: `var(--var-module-ui-component-dropdown-style-none-background, ${defaultThemeStyles['none-background']})`,
        boxShadow: `var(--var-module-ui-component-dropdown-style-none-shadow, ${defaultThemeStyles['none-shadow']})`,
        border: `1px solid var(--var-module-ui-component-dropdown-style-none-border, ${defaultThemeStyles['none-border']})`,
    },
    content_type_fill: {
        background: `var(--var-module-ui-component-dropdown-style-fill-background, ${defaultThemeStyles['fill-background']})`,
        boxShadow: `var(--var-module-ui-component-dropdown-style-fill-shadow, ${defaultThemeStyles['fill-shadow']})`,
        border: `1px solid var(--var-module-ui-component-dropdown-style-fill-border, ${defaultThemeStyles['fill-border']})`,
    },
    list_type_withShadow_disabled: {
        color: `var(--var-module-ui-component-dropdown-style-withShadow-textSub, ${defaultThemeStyles['withShadow-textSub']})`,
    },
    list_type_default_disabled: {
        color: `var(--var-module-ui-component-dropdown-style-default-textSub, ${defaultThemeStyles['default-textSub']})`,
    },
    wrapperFull_type_withBottomLine: {
        color: `var(--var-module-ui-component-editText-style-withBottomLine-textMain, ${
            defaultThemeStyles[`withBottomLine-textMain`]
        })`,
        background: `var(--var-module-ui-component-editText-style-withBottomLine-background, ${
            defaultThemeStyles[`withBottomLine-background`]
        })`,
        boxShadow: `var(--var-module-ui-component-editText-style-withBottomLine-shadow, ${
            defaultThemeStyles[`withBottomLine-shadow`]
        })`,
        borderBottom: `1px solid var(--var-module-ui-component-editText-style-withBottomLine-border, ${
            defaultThemeStyles[`withBottomLine-border`]
        })`,
        '&::placeholder': {
            color: `var(--var-module-ui-component-editText-style-withBottomLine-placeholder, ${
                defaultThemeStyles[`withBottomLine-placeholder`]
            })`,
        },
    },
    fontColor: {
        // color: `var(--var-module-ui-component-dropdown-style-default-activeTextSub, ${defaultThemeStyles['default-activeTextSub']})`,
        color: `var(--var-module-ui-component-dropdown-style-default-textMain, ${defaultThemeStyles['default-activeTextSub']})`,
        '&::placeholder': {
            // color: `var(--var-module-ui-component-dropdown-style-default-activeTextSub, ${defaultThemeStyles['default-activeTextSub']})`,
            color: `var(--var-module-ui-component-dropdown-style-default-textMain, ${defaultThemeStyles['default-activeTextSub']})`,
        },
    },
    hmsActive: {
        color: `var(--var-module-ui-component-dropdown-style-default-textMain, ${defaultThemeStyles['default-activeTextSub']})`,
        fontWeight: 600,
        textDecoration: 'underline',
    },
    hmsStyle: {
        '-moz-appearance': 'textfield',
        '&[type="number"]': {
            '-moz-appearance': 'textfield',
        },
        '&::-webkit-inner-spin-button, ::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
        },
    },
    list_type_none_disabled: {
        color: `var(--var-module-ui-component-dropdown-style-none-textSub, ${defaultThemeStyles['none-textSub']})`,
    },
    list_type_fill_disabled: {
        color: `var(--var-module-ui-component-dropdown-style-fill-textSub, ${defaultThemeStyles['fill-textSub']})`,
    },
    list_type_withShadow_activated: {
        fontWeight: 'bold',
        color: `var(--var-module-ui-component-dropdown-style-withShadow-activeTextSub, ${defaultThemeStyles['withShadow-activeTextSub']})`,
    },
    list_type_default_activated: {
        fontWeight: 'bold',
        // color: `var(--var-module-ui-component-dropdown-style-default-activeTextSub, ${defaultThemeStyles['default-activeTextSub']})`,
        color: `var(--var-module-ui-component-dropdown-style-default-textMain, ${defaultThemeStyles['default-activeTextSub']})`,
    },
    list_type_none_activated: {
        fontWeight: 'bold',
        color: `var(--var-module-ui-component-dropdown-style-none-activeTextSub, ${defaultThemeStyles['none-activeTextSub']})`,
    },
    list_type_fill_activated: {
        fontWeight: 'bold',
        color: `var(--var-module-ui-component-dropdown-style-fill-activeTextSub, ${defaultThemeStyles['fill-activeTextSub']})`,
    },
    list_type_custom_theme_in_video_editor: {
        color: '#494848 !important',
    },
    searchable_type_withShadow: {
        background: `var(--var-module-ui-component-dropdown-style-withShadow-background, ${defaultThemeStyles['withShadow-background']})`,
        boxShadow: `var(--var-module-ui-component-dropdown-style-withShadow-shadow, ${defaultThemeStyles['withShadow-shadow']})`,
        border: `1px solid var(--var-module-ui-component-dropdown-style-withShadow-border, ${defaultThemeStyles['withShadow-border']})`,
    },
    searchable_type_withBorder: {
        background: `var(--var-module-ui-component-dropdown-style-withShadow-background, ${defaultThemeStyles['withShadow-background']})`,
        border: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${
            defaultThemeStyles[`withLightBorder-textMain`]
        })`,
    },
    searchable_type_default: {
        background: `var(--var-module-ui-component-dropdown-style-default-background, ${defaultThemeStyles['default-background']})`,
        boxShadow: `var(--var-module-ui-component-dropdown-style-default-shadow, ${defaultThemeStyles['default-shadow']})`,
        border: `1px solid var(--var-module-ui-component-dropdown-style-default-border, ${defaultThemeStyles['default-border']})`,
    },
    searchable_type_none: {
        background: `var(--var-module-ui-component-dropdown-style-none-background, ${defaultThemeStyles['none-background']})`,
        boxShadow: `var(--var-module-ui-component-dropdown-style-none-shadow, ${defaultThemeStyles['none-shadow']})`,
        border: `1px solid var(--var-module-ui-component-dropdown-style-none-border, ${defaultThemeStyles['none-border']})`,
    },
    searchable_type_fill: {
        background: `var(--var-module-ui-component-dropdown-style-fill-background, ${defaultThemeStyles['fill-background']})`,
        boxShadow: `var(--var-module-ui-component-dropdown-style-fill-shadow, ${defaultThemeStyles['fill-shadow']})`,
        border: `1px solid var(--var-module-ui-component-dropdown-style-fill-border, ${defaultThemeStyles['fill-border']})`,
    },
    bottomContent: {
        left: 0,
    },
    topContent: {
        left: 0,
    },
    hmsTime: {
        color: `var(--var-module-catalog-component-breadcrumbs-style-textMain, #b3b3b3)`,
        paddingRight: '15px',
        zIndex: 99999,
    },
    customContent: {
        ...grid.w100,
        display: 'none',
        ...item.abs,
        borderRadius: `0px 0px ${radiusSizes.mini}px ${radiusSizes.mini}px`,
        left: 0,
    },
    active: {
        '& .dropdown_bottomContent': {
            display: 'block',
            borderTop: 'none',
        },
        '& .dropdown_topContent': {
            display: 'block',
            borderBottom: 'none',
        },
        '& .dropdown_customContent': {
            display: 'block',
            borderTop: 'none',
        },
    },
    bottomActiveButton: {
        borderBottomLeftRadius: '0 !important',
        borderBottomRightRadius: '0 !important',
    },
    topActiveButton: {
        borderTopLeftRadius: '0 !important',
        borderTopRightRadius: '0 !important',
    },
    icon: {
        ...grid.fullHeight,
    },
    iconSearchable: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.fullHeight,
        ...item.pointer,
        right: 0,
        top: 0,
        // pointerEvents: 'none',
    },
    list: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.pt_micro,
        ...grid.pb_micro,
        ...item.pointer,
    },
    selected: {
        fontWeight: 'bold',
    },
    label: {
        ...grid.row,
        ...grid.mb_micro,
    },
    searchable: {
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    inputTransparent: {
        backgroundColor: 'transparent',
        border: '0 none',
        outline: 'none',
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: indents.micro,
    },
    tag: {
        marginRight: 5,
        marginTop: 0,
    },
    disabled: {
        ...item.disabled,
    },
    textMob: {
        [mediaCSS.tab]: {
            fontSize: 10,
            padding: 7 + 'px !important',
        },
    },
    textMobForSearchbar: {
        [mediaCSS.desktopM]: {
            padding: '10px 3px',
        },
    },
    overflow: {
        [mediaHeightQuery]: {
            overflow: 'auto',
        },
    },
}

addStyles(dropdownStyles)
export default dropdownStyles
