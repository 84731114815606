import React, { useEffect } from 'react'
import styles from './FilterAdd-styles'
import { cn } from 'ethcss'
import { IFilter } from '../Filter/Filter-types'
import DotTooltip from 'blocks.simple/dotTooltip/dotTooltip'
import translate from '../../../core/translate'
import { useClickOutside } from 'react-click-outside-hook'

export const FilterAdd = ({ availableFilters, filterList, onAddFilter, containerClass, onClose }: any) => {
    const [filterAddRef, hasClickedOutside] = useClickOutside()

    useEffect(() => {
        if (hasClickedOutside) {
            onClose()
        }
    }, [hasClickedOutside])

    return (
        <div ref={filterAddRef} className={cn(containerClass, styles.filterAdd)}>
            {availableFilters
                .filter((filter: any) => !filterList.some((item: IFilter) => item.name === filter.name))
                .map((filter: IFilter, index: number) => {
                    return (
                        <DotTooltip key={index} tooltip={{ html: translate(filter.name) }}>
                            <div className={styles.filterItem} onClick={() => onAddFilter(filter)}>
                                + {translate(filter.name)}
                            </div>
                        </DotTooltip>
                    )
                })}
        </div>
    )
}
