import React, { FC, SyntheticEvent } from 'react'
import { DashboardUnusedContentHooks } from './dashboard__unusedContent.local.methods'
import ModalAccept from 'blocks.simple/modal/_accept/modal_accept'
import Icon from 'blocks.simple/icon/icon'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import helpersFiles from 'core/helpers/_files'
import helpers from 'core/helpers'
import translate from 'core/translate'
import filesize from 'filesize'
import { cn } from 'ethcss'
import styles from './dashboard__unusedContent.jcss'
import { item } from '../../../theme'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { filesActions } from 'pages/files/files.state'
import { useState } from 'react'
import Preview from 'blocks.app/preview/preview'

interface IDashboardUnusedContent {
    open: boolean
    onClose: any
}

const getFileTypeIcon = (item: any) => {
    let iconType = 'files'
    if (item.fileType) {
        iconType = helpersFiles.getFileIconName(item)

        return <Icon containerClass={styles.colIconCenter} type={iconType} />
    }
}

const getFileSize = (size: number) => {
    if (size >= 0 && size !== null) {
        return <span>{filesize(size, { round: 0 })}</span>
    }

    return null
}

const DashboardUnusedContent: FC<IDashboardUnusedContent> = ({ onClose, open }) => {
    const {
        selectedContents,
        data,
        onSelectContent,
        getRemoveIconProps,
        onMultiSelect,
        onDeleteContent,
    } = DashboardUnusedContentHooks(open)
    const headerOptions = [translate('choice'), translate('preview'), translate('name'), translate('size')]

    const [currentIndexSlider, setCurrentIndexSlider] = useState<number>(0)
    const [sliderItems, setSliderItems] = useState<any>([])
    const dispatch = useDispatch()
    const isPreviewOpen = useSelector((store: RootStateOrAny) => store.files.isPreviewOpen)
    const openPreview = () => {
        dispatch(filesActions.openPreview())
    }
    const closePreview = () => {
        dispatch(filesActions.closePreview())
    }
    const getFilesInFolder = async (id: number) => {
        let sliderItems = data

        const currentIndexSlider = sliderItems.findIndex((item: { id: number }) => item.id === id)

        setCurrentIndexSlider(currentIndexSlider)
        setSliderItems(sliderItems)
        setTimeout(() => {
            openPreview()
        }, 100)
    }

    return (
        <ModalAccept
            open={open}
            title={translate('removeUnusedContent')}
            onClose={onClose}
            modalInnerClassName={styles.modal}
        >
            <div className={styles.wrapper}>
                {!data.length && <div className={styles.emptyText}>{translate('unusedContentListIsEmpty')}</div>}
                {data.length > 0 && (
                    <React.Fragment>
                        <div className={styles.controls}>
                            <Icon
                                className={styles.icon}
                                type="delete"
                                onClick={onDeleteContent}
                                size={18}
                                tooltip={{
                                    title: translate('delete'),
                                }}
                                {...getRemoveIconProps()}
                            />
                            <Icon
                                type={!selectedContents.length ? 'checked' : 'close'}
                                onClick={onMultiSelect}
                                tooltip={{
                                    title: !selectedContents.length ? translate('selectAll') : translate('deselect'),
                                }}
                                size={18}
                            />
                        </div>
                        <div className={styles.tableWrapper}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        {headerOptions.map((option) => (
                                            <th style={{ padding: '14px', textAlign: 'center' }} key={option}>
                                                {option}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((content: any) => {
                                        const isSelected = selectedContents.includes(content.id)

                                        return (
                                            <tr
                                                onClick={() => {
                                                    onSelectContent(content.id)
                                                }}
                                                className={cn({ [item.tableSelected]: isSelected })}
                                                key={content.id}
                                            >
                                                <td style={{ padding: '14px', textAlign: 'center' }}>
                                                    <div className={styles.tdWrapper}>
                                                        <Checkbox
                                                            onClick={(selected: boolean, e: SyntheticEvent) => {
                                                                e.stopPropagation()
                                                                onSelectContent(content.id)
                                                            }}
                                                            checked={isSelected}
                                                            mod={isSelected ? 'circleInv' : 'circle'}
                                                            className={styles.colCheckboxCenter}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        className={item.tableCell}
                                                        onClick={() => getFilesInFolder(content.id)}
                                                    >
                                                        {getFileTypeIcon(content)}
                                                    </div>
                                                </td>
                                                <td className={styles.name}>
                                                    <div
                                                        className={item.ellipsisPure}
                                                        style={helpersFiles.convertStyles(content.data.style, {
                                                            withoutStyles: ['fontSize'],
                                                        })}
                                                    >
                                                        {helpers.strip_html_tags(content.name)}
                                                    </div>
                                                </td>
                                                <td>{getFileSize(content.size)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <Preview
                                isOpen={isPreviewOpen}
                                onClose={() => closePreview()}
                                items={sliderItems}
                                currentIndex={currentIndexSlider}
                            />
                        </div>
                    </React.Fragment>
                )}
            </div>
        </ModalAccept>
    )
}

export default DashboardUnusedContent
