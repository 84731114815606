import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { textSizes } from 'theme/media'
import { IStyles } from '../../../theme'

const previewWebAppStyles: IStyles = {
    previewWebAppWrapper: {
        ...grid.full,
        ...grid.colCenter,
        ...grid.center,
        ...item.rel,
        ...item.whiteColor,
        fontSize: textSizes.md,
    },
    previewWebAppWrapper_none: {
        ...grid.full,
        paddingLeft: '0%',
        paddingRight: '0%',
    },
    previewWebAppWrapper_small: {
        ...grid.full,
        paddingLeft: '2%',
        paddingRight: '2%',
    },
    previewWebAppWrapper_big: {
        ...grid.full,
        paddingLeft: '10%',
        paddingRight: '10%',
    },
    loadingData: {
        ...grid.colCenter,
        textAlign: 'center',
    },
    screen: {
        ...grid.w100,
        maxHeight: '100%',
        ...item.overNone,
    },
    mobileSettingsWrapper: {
        ...grid.full,
        ...item.abs,
        zIndex: 1,
    },
    isLoadingWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
}

addStyles(previewWebAppStyles)
export default previewWebAppStyles
