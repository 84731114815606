import { isDevelopment } from 'core/utils/coreUtil'
import { GoogleMapFactory } from './GoogleMapFactory'
import { MapFactory } from './MapFactory'
import { YandexMapFactory } from './YandexMapFactory'

declare var window: any

export class MapCreator {
    public static instance: MapCreator
    private mapFactory: MapFactory

    private constructor() {
        this.mapFactory = this.createMapFactory()
    }

    public static getInstance() {
        if (typeof MapCreator.instance === 'object') {
            return MapCreator.instance
        }

        MapCreator.instance = new MapCreator()

        return MapCreator.instance
    }

    public getMapFactory() {
        return this.mapFactory
    }

    private createMapFactory = (): MapFactory => {
        const mapType = window?.spconfig?.map?.type
        const isDev = isDevelopment()
        const apiKey = isDev ? window?.spconfig?.map?.devApiKey : window?.spconfig?.map?.apiKey

        switch (mapType) {
            case 'yandex': {
                return new YandexMapFactory(apiKey)
            }
            default: {
                return new GoogleMapFactory(apiKey)
            }
        }
    }
}
