import { addStyles } from 'ethcss'
import { colors, grid, IStyles, item, mediaCSS } from '../../../theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'

const buttonsHeight = 63
const advertisingCampaignsToolbarContentStyles: IStyles = {
    viewInfo: {
        borderRadius: '5px',
        overflow: 'hidden',
    },
    wrapper: {
        ...grid.w100,
        ...grid.col,
        ...grid.space,
    },
    toolbarWrapper: {
        //padding: '0 10px',
    },
    advDeleteWarning: {
        position: 'fixed',
        boxShadow: 'var(--var-module-ui-component-dropMenu-style-blockShadow, 0px 4px 4px rgba(0, 0, 0, 0.25))',
        marginRight: '10px',
        background: colors.white,
    },
    toggleButton: {
        ...grid.w50,
        ...grid.rowCenter,
        ...item.pointer,
        ...item.white,
        height: buttonsHeight,
    },
    buttonUploads: {
        ...item.grey,
    },
    content: {
        ...grid.w100,
        ...grid.col,
        ...item.white,
        minHeight: `calc(100% - ${buttonsHeight}px)`,
    },
    empty: {
        ...grid.space,
        ...grid.col,
        ...grid.center,
        ...grid.normalCenter,
        ...grid.p_micro,
        textAlign: 'center',
        '& img': { ...grid.w100 },
    },
    description: {
        ...grid.mt_mdPlus,
        ...grid.mb_md,
        ...grid.w75,
        '&>p': {
            lineHeight: '22px',
        },
    },
    itemDescription: {
        ...grid.p_md,
        ...grid.pt_none,
        textAlign: 'center',
    },
    name: {
        ...grid.p_md,
        textAlign: 'center',
    },
    devicesListTitle: {
        ...grid.row,
        textAlign: 'center',
        ...grid.p_md,
        borderBottom: `3px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles['border']})`,
    },
    deviceListItem: {
        ...grid.row,
        ...grid.fullWidth,
        ...grid.p_md,
        ...item.cursorDefault,
    },
    closeBtn: {
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',

        [mediaCSS.tab]: {
            bottom: 40,
        },
    },
}

addStyles(advertisingCampaignsToolbarContentStyles)
export default advertisingCampaignsToolbarContentStyles
