import { addStyles } from 'ethcss'
import { grid, IStyles, item } from 'theme'
import { defaultThemeStyles } from '../../molecules/DownloadModal/DownloadProgressModal.theme'

export const styles: IStyles = {
    broadcastItem: {
        padding: '10px 30px',
        listStyle: 'none',
        borderBottom: `1px solid var(--var-module-app-component-download-progress-style-border, ${defaultThemeStyles.border})`,
    },
    broadcastItemHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    broadcastItemLeft: {
        display: 'flex',
    },
    broadcastItemRight: {
        display: 'flex',
    },
    icon: {
        marginRight: '16px',
    },
    broadcastName: {
        lineHeight: '1.3',
    },
    status: {
        width: '70px',
        textAlign: 'center',
    },
    opened: {
        transform: 'rotate(180deg)',
    },
}

addStyles(styles)
