import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { IStyles } from '../../../theme'

const deviceSettingsPictureModeStyles: IStyles = {
    DeviceSettingsPictureMode: {
        ...grid.row,
        ...grid.full,
        ...grid.mt_mdPlus,
    },
    DeviceSettingsPictureMode__list: {},
}

addStyles(deviceSettingsPictureModeStyles)
export default deviceSettingsPictureModeStyles
