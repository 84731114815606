import React from 'react'
import EditText from 'blocks.simple/editText/editText'
import { Radio } from 'atoms/Radio'
import translate from 'core/translate'
import styles from './deviceSettings__videoPlayer.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { cn } from 'ethcss'
import { Typography } from 'atoms/Typography'

const DeviceSettingsVideoPlayer = (p_: {
    settings: { hardwareAcceleration: string; options: string[] }
    onChange: (data: any) => void
}) => {
    const options = p_.settings.options.join('\n')

    const onChange = (value: string, event: string | null, key = 'options') => {
        const data: any = {
            ...p_.settings,
        }

        if (key !== 'options') {
            data[key] = value
        } else {
            data[key] = value.split('\n')
        }

        p_.onChange(data)
    }

    return (
        <React.Fragment>
            <Typography className={cn(grid.mb_md)} type="title">
                {translate('videoHardwareAcceleration')}
            </Typography>
            <div className={styles.radioButtons}>
                <Radio
                    label={translate('on')}
                    selected={p_.settings.hardwareAcceleration === 'on'}
                    className={grid.mr_md}
                    onClick={() => onChange('on', null, 'hardwareAcceleration')}
                />
                <Radio
                    label={translate('off')}
                    selected={p_.settings.hardwareAcceleration === 'off'}
                    onClick={() => onChange('off', null, 'hardwareAcceleration')}
                />
            </div>
            <div className={styles.optionsWrapper}>
                <div className={styles.textArea}>
                    <EditText
                        mod={'withBorder'}
                        type="area"
                        label={translate('settings')}
                        value={options}
                        onChange={onChange}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default DeviceSettingsVideoPlayer
