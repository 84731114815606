import React from 'react'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import Icon from 'blocks.simple/icon/icon'
import Animation from 'blocks.simple/animation/animation'
import DndDraggable from 'blocks.app/dnd/_draggable/dnd_draggable'
import Tippy from 'blocks.simple/tippy/tippy'

import { cn } from 'ethcss'
import styles from './catalog__item_displays.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { text as textStyles } from 'theme'
import translate from 'core/translate'
import displaysHelpers from 'core/helpers/_digitalSignages'
import helpers from 'core/helpers'
import { indents } from 'blocks.app/config'
import { ProgressBar } from 'atoms/ProgressBar'

const Progress = ({ list, onClick, advanced }) => {
    if (list && list.length > 0) {
        return (
            <div className={cn(styles.loading, advanced ? grid.w80 : grid.fullWidth)} onClick={onClick}>
                <div className={styles.progress}>
                    <ProgressBar value={list[0].percentage} strokeWidth={17} />
                </div>
                <div className={cn(grid.row, grid.noWrap, grid.fullWidth, item.ellipsisPure)}>
                    <div className={item.ellipsisPure}>
                        {list[0].title}
                        <span>&nbsp;</span>
                    </div>
                    <div>{Math.round(list[0].percentage)}%</div>
                </div>
            </div>
        )
    } else {
        return null
    }
}

const OrientationIcon = (p_) => {
    const orientation = displaysHelpers.getOrientation(p_.orientation)

    if (!orientation) {
        return null
    }

    function getRotate() {
        switch (orientation.id) {
            case 0:
            case 180:
                return 'right'
            case 90:
            case 270:
                return 'left'
            default:
                return ''
        }
    }

    return (
        <Icon
            containerClass={styles.orientationIcon}
            color="white"
            size={20}
            type="horizontal_pos"
            rotate={getRotate()}
            tooltip={{
                title: orientation.fullName,
            }}
        />
    )
}

class ItemDisplays extends React.PureComponent {
    constructor(p_) {
        super(p_)

        this.state = {
            isHovered: false,
            dragData: p_.getDragData(p_),
        }
    }
    static getDerivedStateFromProps(nextProps) {
        return {
            dragData: nextProps.getDragData(nextProps),
        }
    }
    getDeviceCover = (isSimple, cover) => {
        const p_ = this.props

        if (isSimple && cover) {
            return {
                backgroundImage: `url(${cover})`,
            }
        }

        if (!isSimple && p_.item.cover[0]) {
            const advancedCover = p_.item.cover[0]

            return {
                backgroundImage: `url(${advancedCover})`,
            }
        }
    }
    render() {
        const p_ = this.props
        const s_ = this.state
        let cover = null
        const active = p_.active || p_.item.__view.selected
        const currentTileView = p_.deviceBlockStyle
        const isSimple = currentTileView === 'simple'
        const isAdvanced = currentTileView === 'advanced'
        const downloads = p_.item.downloadPercentage

        if (active) {
            cover = p_.item.cover[0]
        }

        return (
            <div
                onMouseEnter={() => this.setState({ isHovered: true })}
                onMouseLeave={() => this.setState({ isHovered: false })}
                onClick={function (e) {
                    p_.onSelectInfo(e)

                    if (helpers.isDblTouchTap(e)) {
                        if (p_.item.type !== 'group') {
                            p_.onDoubleClickObject(p_.item)
                            p_.onDoubleClick(true)

                            e.preventDefault()
                            return
                        }

                        p_.onSelectFolder(p_.item)
                    }
                }}
                onContextMenu={(e) => {
                    if (helpers.isMobileDevice()) {
                        e.preventDefault()
                    }
                }}
            >
                {p_.item.type !== 'group' && (
                    <Rectangle width="16" height="9" className={`${styles.wrapper} ${styles[p_.item.status]}`}>
                        <DndDraggable
                            data={s_.dragData}
                            dragEnabled={p_.drag}
                            className={cn(styles.content, styles[p_.item.status], item.cover)}
                            onDragStart={p_.onDragStart}
                            onDragEnd={p_.onDragEnd}
                            style={this.getDeviceCover(isSimple, cover)}
                        >
                            {p_.multipleSelect && (
                                <div
                                    className={styles.checkWrapper}
                                    onClick={(e) => e.stopPropagation()}
                                    style={p_.item.__view.selected ? { opacity: 1 } : null}
                                >
                                    <Checkbox
                                        mod={'circleInv'}
                                        onClick={(selected) => {
                                            p_.onSelect({ selected, item: p_.item })
                                        }}
                                        checked={p_.item.__view.selected}
                                    />
                                </div>
                            )}
                            {isSimple && (
                                <div className={cn(styles.simpleWrapper, { [item.blackOpacity]: active })}>
                                    <div className={cn(textStyles.center, textStyles.white, grid.w90)}>
                                        {helpers.isAvailable({ key: 'broadcasts', action: 'read' }) &&
                                            !p_.item.hasBroadcast && <div>{translate('noBroadcast')}</div>}
                                        <div className={cn(grid.p_micro, textStyles.normalPlus, item.ellipsis)}>
                                            {p_.item.name}
                                        </div>
                                        {p_.item.registrationCode && (
                                            <div className={styles.registrationCodeSimple}>
                                                {p_.item.registrationCode}
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        style={{ bottom: indents.nano, left: indents.nano }}
                                        className={cn(item.abs, grid.w90)}
                                    >
                                        <Progress list={downloads} onClick={p_.onSelectProgress} />
                                    </div>
                                </div>
                            )}
                            {isAdvanced && (
                                <React.Fragment>
                                    {p_.item.registrationCode && (
                                        <div className={styles.registrationCode}>{p_.item.registrationCode}</div>
                                    )}
                                    <div className={cn(grid.rowCenter, grid.full, { [item.blackOpacity]: active })} />
                                </React.Fragment>
                            )}
                        </DndDraggable>
                        {isAdvanced && (
                            <div className={cn(styles.infoLine, styles[p_.item.status])}>
                                {downloads && downloads.length > 0 ? (
                                    <div className={cn(grid.row, grid.normalCenter, grid.fullWidth, grid.noWrap)}>
                                        <OrientationIcon orientation={p_.item.orientation} />
                                        <Progress
                                            list={downloads}
                                            advanced={isAdvanced}
                                            onClick={p_.onSelectProgress}
                                        />
                                    </div>
                                ) : (
                                    <div className={styles.infoLine}>
                                        <div className={cn(grid.col, item.ellipsisWrapper)}>
                                            <div
                                                style={{ minHeight: 24 }}
                                                className={cn(item.ellipsis, grid.fullWidth)}
                                            >
                                                {p_.item.name}
                                            </div>
                                            <div className={cn(item.ellipsis, textStyles.up)}>
                                                {p_.item.modelName !== 'undef' ? p_.item.modelName : ''}
                                            </div>
                                        </div>
                                        <div className={cn(grid.col, grid.colCenter)}>
                                            <Animation>
                                                {s_.isHovered ? (
                                                    <div
                                                        className={cn(
                                                            grid.row,
                                                            grid.normalCenter,
                                                            grid.noWrap,
                                                            grid.justify
                                                        )}
                                                    >
                                                        <div className={cn(grid.row, grid.justify, grid.mr_mini)}>
                                                            <div />
                                                            <div
                                                                className={cn(grid.row, grid.normalCenter, grid.noWrap)}
                                                            >
                                                                {helpers.isAvailable({
                                                                    key: 'broadcasts',
                                                                    action: 'read',
                                                                }) &&
                                                                    !p_.item.hasBroadcast && (
                                                                        <Icon
                                                                            color={'white'}
                                                                            size={20}
                                                                            type={'no_display'}
                                                                            containerClass={cn(grid.mr_mini)}
                                                                            tooltip={{
                                                                                title: translate('noBroadcast'),
                                                                            }}
                                                                        />
                                                                    )}
                                                                <OrientationIcon orientation={p_.item.orientation} />
                                                                {displaysHelpers.availablePlatforms(
                                                                    p_.item.platform
                                                                ) && (
                                                                    <Tippy
                                                                        title={p_.item.platform}
                                                                        className={cn(grid.ml_mini)}
                                                                    >
                                                                        <img
                                                                            alt={`${p_.item.platform}_logo`}
                                                                            width="100%"
                                                                            height="auto"
                                                                            src={`images/displays/platforms/small/${displaysHelpers.getPlatformName(
                                                                                p_.item.platform
                                                                            )}.png`}
                                                                        />
                                                                    </Tippy>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </Animation>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </Rectangle>
                )}
                {p_.item.type === 'group' && (
                    <Rectangle width="16" height="9" className={styles.groupWrapper}>
                        <DndDraggable
                            className={`${styles.group} ${styles[p_.item.status]}`}
                            data={s_.dragData}
                            dragEnabled={p_.drag}
                            style={cover ? { backgroundImage: `url(${cover})` } : {}}
                            onDragStart={p_.onDragStart}
                            onDragEnd={p_.onDragEnd}
                        >
                            <div className={cn(styles.content, styles[p_.item.status])}>
                                {p_.multipleSelect && (
                                    <div
                                        className={styles.checkWrapper}
                                        onClick={(e) => e.stopPropagation()}
                                        style={p_.item.__view.selected ? { opacity: 1 } : null}
                                    >
                                        <Checkbox
                                            mod={'circleInv'}
                                            onClick={(selected) => {
                                                p_.onSelect({ selected, item: p_.item })
                                            }}
                                            checked={p_.item.__view.selected}
                                        />
                                    </div>
                                )}
                                <div className={cn(grid.colCenter, grid.full, { [item.blackOpacity]: active })}>
                                    <div className={cn(textStyles.center, textStyles.white, grid.w90)}>
                                        {helpers.isAvailable({ key: 'broadcasts', action: 'read' }) &&
                                            !p_.item.hasBroadcast && <div>{translate('noBroadcast')}</div>}
                                        <div className={cn(grid.p_micro, textStyles.normalPlus, item.ellipsis)}>
                                            {p_.item.name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DndDraggable>
                        <div className={styles.groupBackWrapper}>
                            <div
                                className={`${styles.groupBack} ${styles.groupBack_second} ${styles[p_.item.status]}`}
                            />
                        </div>
                        <div className={styles.groupBackWrapper}>
                            <div className={`${styles.groupBack} ${styles.groupBack_first}`} />
                        </div>
                    </Rectangle>
                )}
            </div>
        )
    }
}

export default ItemDisplays
