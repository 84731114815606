import { addStyles } from 'ethcss'
import { colors, IStyles, mediaCSS } from '../../theme'

const cookieUsageWarningStyles: IStyles = {
    warningBlock: {
        position: 'fixed',
        right: 10,
        bottom: 10,
        background: colors.white,
        borderRadius: 20,
        padding: 20,
        boxShadow: '0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%)',
        textAlign: 'center',
        zIndex: 9000,
    },
    confirmButton: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,

        button: {
            width: 180,
            display: 'flex',
            justifyContent: 'center',
        },
    },
    moreDetails: {
        textDecoration: 'underline',
        marginTop: 10,
        cursor: 'pointer',

        '&:hover': {
            textDecoration: 'none',
        },
    },
    warningBlockDetails: {
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: `rgba(0,0,0,0.7)`,
        zIndex: 10000,
    },
    warningBlockDetailsInner: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: colors.white,
        borderRadius: 20,
        padding: '40px 20px 20px 20px',
        boxShadow: '0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%)',
        width: 500,
        lineHeight: 1.2,
        textAlign: 'center',

        [mediaCSS.tab]: {
            width: '90%',
        },
    },
    warningBlockDetailsClose: {
        position: 'absolute',
        top: 20,
        right: 20,
    },
}

addStyles(cookieUsageWarningStyles)
export default cookieUsageWarningStyles
