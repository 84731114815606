import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles } from './CustomListHeader-theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { IStyles } from '../../../theme'

const customListHeaderStyles: IStyles = {
    wrapper: {
        ...grid.w100,
        ...grid.mt_md,
    },
    content: {
        ...item.rounded_top_mini,
        backgroundColor: `var(--var-module-catalog-component-header-style-background, ${defaultThemeStyles.background})`,
        borderRadius: '5px 5px 0 0',
    },
    tools: {
        ...grid.row,
        ...grid.justify,
        ...grid.normalCenter,
        ...grid.pr_md,
        ...grid.pl_md,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    deleteButton: {
        width: 250,
    },
    delayTime: {
        ...grid.pb_md,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
}

addStyles(customListHeaderStyles)
export default customListHeaderStyles
