import { addStyles } from 'ethcss'
import { IStyles, item } from 'theme'

export const iconStyles: IStyles = {
    wrapper: {
        ...item.rel,
    },
    disabled: {
        ...item.disabled,
    },
    cursor: {
        ...item.pointer,
    },
    leftRotate: {
        transform: 'rotate(90deg)',
    },
}

addStyles(iconStyles)
