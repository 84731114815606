import { Typography } from 'atoms/Typography'
import { isExist } from 'core/utils/coreUtil'
import React, { FC } from 'react'
import DeviceSettingsTimer from '../__timer/deviceSettings__timer'
import { styles } from './DeviceSettingsOnOffTimers-styles'
import { IDeviceSettingsOnOffTimersProps } from './DeviceSettingsOnOffTimers-types'

export const DeviceSettingsOnOffTimers: FC<IDeviceSettingsOnOffTimersProps> = ({
    platform,
    timers,
    title,
    onChange,
    enabled,
    settingName,
    type,
}) => {
    const isTimersExist = () => isExist(timers)

    let onChangeTimers = (onOffTimers: any) => {
        onChange(onOffTimers, settingName)
    }

    if (!enabled || !isTimersExist()) {
        return null
    }

    return (
        <div className={styles.DeviceSettingsOnOffTimers}>
            <Typography className={styles.DeviceSettingsOnOffTimers__title} type="title">
                {title}
            </Typography>
            <div className={styles.DeviceSettingsOnOffTimers__timers}>
                <DeviceSettingsTimer
                    platform={platform}
                    timers={timers}
                    onChange={onChangeTimers}
                    comPort={settingName === 'onOffDisplayTimers'}
                    type={type}
                />
            </div>
        </div>
    )
}
