import { addStyles } from 'ethcss'
import { grid, IStyles, radiusSizes } from 'theme'
import { defaultThemeTableStyles } from './Table-theme'

export const styles: IStyles = {
    Table: {
        ...grid.w100,
        borderRadius: `${radiusSizes.micro}px`,
        color: `var(--var-module-ui-component-table-style-text, ${defaultThemeTableStyles['text']})`,
        backgroundColor: `var(--var-module-ui-component-table-style-background, ${defaultThemeTableStyles['background']})`,
    },
}

addStyles(styles)
