import React from 'react'
import InstancesCatalogFilter from '../../__filter/_instancesCatalog/catalog__filter_instancesCatalog'
import EditableText from 'blocks.simple/editableText/editableText'
import CatalogSelectDevices from 'blocks.app/catalog/_select/_devices/catalog_select_devices'
import Button from 'blocks.simple/button/button'

import { cn } from 'ethcss'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import grid from 'blocks.simple/grid/grid.jcss'
import styles from '../_instancesCatalog/catalog__template_instancesCatalog.jcss'

export default {
    getMethod: 'getClassrooms',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noClassrooms'),
    emptyDescription: () => translate('noClassroomsDescription'),
    emptyIcon: 'not_allow',
    addItemButton: () => null,
    settingsPrefixes: 'classrooms',
    sortOptions: () => [
        { id: '-createdAt', name: translate('sortCreatedAt') },
        { id: 'title', name: translate('byNameAsc') },
        { id: 'number', name: translate('number') },
    ],
    headerFilters: (p_) => (
        <InstancesCatalogFilter
            onAdd={() => p_.onAddFakeLine({ title: '', digitalSignageId: [] })}
            title={translate('classrooms')}
            type="classroom"
            onDelete={() => {
                const id = p_.selectedItems.map((selectedItem) => selectedItem.id)
                if (id.length > 0) {
                    api.send('deleteClassroom', { id })
                }
                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            }}
            {...p_}
        />
    ),
    getItemModel: (dataItem, getSelected) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    getBackendModel: (item) => {
        return api.send('getClassroom', { id: item.id }, { hideLoader: true })
    },
    tableWrapper: styles.tableWrapper,
    isEqual: (firstItem, secondItem) => firstItem.id === secondItem.id,
    onCreateListeners: [(cb, clearList) => api.addObserver('classroomCreated', cb, clearList)],
    onUpdateListeners: [(cb, clearList) => api.addObserver('classroomUpdated', cb, clearList)],
    onDeleteListeners: [(cb, clearList) => api.addObserver('classroomDeleted', cb, clearList)],
    isFolder: () => {},
    fields: () => [
        { id: 'title', name: translate('name') },
        { id: 'number', name: translate('number') },
        { id: 'digitalSignage', name: translate('devices') },
    ],
    init(callback) {
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        title: (p_, active, listProps) => (
            <EditableText
                id={p_.id}
                text={p_.title}
                onChange={(title) => {
                    listProps.onAddFakeLine({ ...listProps.fakeLine, title })
                }}
                onUpdate={(title, cb) => {
                    api.send('updateClassroom', {
                        title,
                        id: p_.id,
                        number: p_.number,
                        digitalSignageId: p_.digitalSignage.map((dsItem) => dsItem.id),
                    }).then(cb)
                }}
            />
        ),
        number: (p_, active, listProps) => (
            <EditableText
                id={p_.id}
                type={'number'}
                disableDefaultFocus={true}
                text={p_.number}
                onChange={(number) => {
                    listProps.onAddFakeLine({ ...listProps.fakeLine, number })
                }}
                onUpdate={(number, cb) => {
                    api.send('updateClassroom', {
                        number,
                        id: p_.id,
                        title: p_.title,
                        digitalSignageId: p_.digitalSignage.map((dsItem) => dsItem.id),
                    }).then(cb)
                }}
            />
        ),
        digitalSignage: (p_, active, listProps) => (
            <div className={cn(grid.fullWidth, grid.pl_mini, grid.rowCenter, styles.selectDevices)}>
                <CatalogSelectDevices
                    selectedItems={p_.digitalSignage}
                    onSelect={(digitalSignageId) => {
                        if (p_.id) {
                            api.send('updateClassroom', {
                                id: p_.id,
                                title: p_.title,
                                number: p_.number,
                                digitalSignageId,
                            })
                        } else {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, digitalSignageId })
                        }
                    }}
                />
                {!p_.id && (
                    <Button
                        className={cn(grid.ml_micro, grid.mr_mini)}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            listProps.onAddFakeLine()
                        }}
                    >
                        {translate('cancel')}
                    </Button>
                )}
                {!p_.id && (
                    <Button
                        mod={'fill'}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            api.send('createClassroom', listProps.fakeLine)
                        }}
                        disabled={
                            !listProps.fakeLine.title ||
                            !listProps.fakeLine.number ||
                            listProps.fakeLine.digitalSignageId.length === 0
                        }
                    >
                        {translate('save')}
                    </Button>
                )}
            </div>
        ),
    },
    hideActiveRowColor: true,
    disableUpdateUserSettings: true,
    onDeleteListItem: (listItem) => {
        api.send('deleteClassroom', { id: listItem.id })
    },
    disableListScroll: true,
    fixedColWidth: true,
}
