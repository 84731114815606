import PropTypes from 'prop-types'
import React from 'react'
import DisplaysNotificationsMethods from './displays__notifications.local.methods'
import Icon from 'blocks.simple/icon/icon'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import EditText from 'blocks.simple/editText/editText'
import { Radio } from 'atoms/Radio'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { text as textStyles } from 'theme'
import styles from './displays__notifications.jcss'
import { Typography } from 'atoms/Typography'
import { displaysActions } from '../displays.state'
import { connect } from 'react-redux'
import { components } from 'react-select'
import { MySelect } from './displays__notifications_multy_select'

const Option = (props: any) => {
    return (
        <div>
            <components.Option {...props}>
                <input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
            </components.Option>
        </div>
    )
}

const MultiValue = (props: any) => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
)

const events = [
    { code: 'core_ds_status_become_offline', key: 'deviceWentOffline' },
    { code: 'core_ds_status_become_error', key: 'deviceGaveError' },
    { code: 'core_ds_status_become_online', key: 'deviceWentOnline' },
    { code: 'core_ds_status_become_screen_off', key: 'screenOffDevice' },
]

const rightEvents = [
    { code: 'deviceOfflineTime', key: 'deviceOfflineNTimes' },
    { code: 'nullValue', key: 'deviceGaveError' },
    { code: 'nullValue1', key: 'deviceWentOnline' },
    { code: 'screenOffTimesDevice', key: 'screenOffNTimesDevice' },
]

class DisplaysNotifications extends DisplaysNotificationsMethods {
    render() {
        const p_ = this.props
        const s_ = this.state
        const notificationsEmails = () => {
            return [...p_.notificationsEmail.map((option, index) => ({ id: index, label: option, value: option }))]
        }
        const valueEmails = (code: string) => {
            let currentEvent: { emails: string[]; event: string } | undefined =
                s_.events && s_.events.find((element) => element.event === code)
            if (currentEvent && currentEvent.emails) {
                return currentEvent.emails.map((event: string) => {
                    return { label: event, value: event }
                })
            } else {
                return null
            }
        }
        const onChangeHandler = (index: number, selected: { value: string }[], code: string) => {
            let mails = selected && selected.map((mail) => mail.value)

            const { events } = this.state
            let newArray = [...events]
            let currentEvent: { emails: string[]; event: string } | undefined = newArray.find(
                (element) => element.event === code
            )
            if (currentEvent) {
                currentEvent.emails = mails
            }
            this.setState({ events: newArray })
        }

        return (
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <Icon type="notifications" containerClass={styles.displaysNotifications__icon} size={30} />
                    <Typography type={'title'} className={styles.displaysNotifications__title}>
                        {translate('notificationsByMail')}
                    </Typography>
                </div>
                <div className={styles.displaysNotifications__content}>
                    <div>
                        <Typography type={'title'} className={styles.displaysNotifications__subTitle}>
                            {translate('typeOfNotification')}
                        </Typography>
                        <div className={styles.selects}>
                            <div className={styles.inputContainer}>
                                <div className={styles.leftColumn}>
                                    {events.map((item, index) => {
                                        return (
                                            <span key={`notification_events_${index}`}>
                                                <Checkbox
                                                    className={cn(styles.checkbox)}
                                                    onClick={(checked: boolean) => this.checkedType(checked, item.code)}
                                                    checked={this.getCheckedValue(item.code)}
                                                    label={
                                                        <div className={cn(grid.ml_mini)}>{translate(item.key)}</div>
                                                    }
                                                />
                                                <div className={cn(styles.timeLeftInputs)}>
                                                    <MySelect
                                                        isDisabled={!this.getCheckedValue(item.code)}
                                                        options={notificationsEmails()}
                                                        isMulti
                                                        separator={false}
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{ Option, MultiValue }}
                                                        placeholder={translate('selectMail')}
                                                        onChange={(selected) => {
                                                            onChangeHandler(index, selected, item.code)
                                                        }}
                                                        allowSelectAll={true}
                                                        value={valueEmails(item.code)}
                                                    />
                                                </div>
                                            </span>
                                        )
                                    })}
                                </div>
                                <div className={styles.rightColumn}>
                                    {rightEvents.map((item, index) =>
                                        index === 1 || index === 2 ? (
                                            <div
                                                className={cn(styles.dNone)}
                                                key={`notification_events_rightEventsDnone_${index}`}
                                            >
                                                <Checkbox
                                                    className={cn(styles.checkbox)}
                                                    label={
                                                        <div className={cn(grid.ml_mini)}>{translate(item.key)}</div>
                                                    }
                                                />
                                                <div className={styles.timeInputs}>
                                                    <EditText
                                                        className={cn(grid.mr_big, textStyles.start)}
                                                        mask="99:99:99"
                                                        type="tel"
                                                        placeholder="__:__:__"
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <span key={`notification_events_rightEvents_${index}`}>
                                                <Checkbox
                                                    className={cn(styles.checkbox)}
                                                    onClick={(checked: boolean) => this.checkedType(checked, item.code)}
                                                    checked={this.getCheckedRightEventsValue(item.code)}
                                                    label={
                                                        <div className={cn(grid.ml_mini)}>{translate(item.key)}</div>
                                                    }
                                                />
                                                <div className={styles.timeInputs}>
                                                    <EditText
                                                        className={cn(grid.mr_big, textStyles.start)}
                                                        onChange={(value: string) =>
                                                            this.setState(
                                                                index === 0
                                                                    ? {
                                                                          ...this.state,
                                                                          options: {
                                                                              ...this.state.options,
                                                                              offlineTime: value,
                                                                          },
                                                                      }
                                                                    : {
                                                                          ...this.state,
                                                                          options: {
                                                                              ...this.state.options,
                                                                              screenOffTime: value,
                                                                          },
                                                                      }
                                                            )
                                                        }
                                                        onBlur={(value: string) =>
                                                            this.setTime(
                                                                value,
                                                                index === 0 ? 'offlineTime' : 'screenOffTime'
                                                            )
                                                        }
                                                        mask="99:99:99"
                                                        type="tel"
                                                        placeholder="__:__:__"
                                                        value={
                                                            index === 0
                                                                ? s_.options.offlineTime
                                                                : s_.options.screenOffTime
                                                        }
                                                        disabled={!this.getCheckedRightEventsValue(item.code)}
                                                    />
                                                </div>
                                            </span>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={grid.mt_big}>
                        <Typography type={'title'} className={styles.displaysNotifications__subTitle}>
                            {translate('notificationTime')}
                        </Typography>
                        <div className={styles.inputs}>
                            <div className={styles.displaysNotifications__inputWrapper_type_startTime}>
                                <EditText
                                    className={cn(grid.mr_big, textStyles.center)}
                                    label={translate('start')}
                                    onChange={(value: string) => this.setState({ startTime: value })}
                                    onBlur={(value: string) => this.setTime(value, 'startTime')}
                                    mask="99:99:99"
                                    type="tel"
                                    placeholder="__:__:__"
                                    value={s_.startTime}
                                />
                            </div>
                            <div className={styles.displaysNotifications__inputWrapper_type_endTime}>
                                <EditText
                                    className={cn(textStyles.center)}
                                    label={translate('ending')}
                                    onChange={(value: string) => this.setState({ endTime: value })}
                                    onBlur={(value: string) => this.setTime(value, 'endTime')}
                                    mask="99:99:99"
                                    type="tel"
                                    placeholder="__:__:__"
                                    value={s_.endTime}
                                />
                            </div>
                        </div>
                        <div className={styles.inputs}>
                            <div className={styles.displaysNotifications__inputWrapper_type_sendInterval}>
                                <EditText
                                    className={cn(textStyles.center)}
                                    label={translate('sendNoMoreOften')}
                                    onChange={(value: string) => this.setState({ timeoutS: value })}
                                    type="number"
                                    value={this.state.timeoutS}
                                />
                            </div>
                        </div>

                        {p_.selectedInfo && p_.selectedInfo.type === 'group' && (
                            <div className={cn(styles.selects, grid.mt_big)}>
                                <Radio
                                    selected={s_.applyRecursively}
                                    onClick={(applyRecursively) => this.setState({ applyRecursively })}
                                    label={translate('applyNotificationsSettingsForAllDisplays')}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={cn(grid.row, grid.center)}>
                        <Button rounded="full_md" mod={'withBorder'} onClick={p_.closeModal}>
                            {translate('cancel')}
                        </Button>
                        <Button rounded="full_md" className={cn(grid.ml_md)} mod="fill" onClick={this.applySettings}>
                            {translate('apply')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: {
    user: { data: { notificationsEmail: { id: number; label: string; value: string }[] } }
}) => {
    return {
        notificationsEmail: state.user.data.notificationsEmail,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeModal: () => dispatch(displaysActions.closeModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplaysNotifications)
