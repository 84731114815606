import React, { FC, Fragment, memo, useMemo, useState } from 'react'
import { ICatalogDnDTargetData, ICatalogItem, ICatalogWithPaginationProps } from './CatalogWithPagination-types'
import { styles } from './CatalogWithPagination-styles'
import { cn } from 'ethcss'
import { FixedHeaderWrapper } from './organisms/FixedHeaderWrapper'
import { Breadcrumbs } from './organisms/Breadcrumbs'
import ScrollUp from 'blocks.simple/scrollUp/scrollUp'
import { isExist, isIndexExist, isNotEmptyArray } from 'core/utils/coreUtil'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import helpers from 'core/helpers'
import { getFilterQuery } from 'core/utils/catalogUtil'
import { stringToNumber } from 'core/utils/convertUtil'
import { useCatalogKeysMap, useFetchCatalogItems, useSelectedCatalogItems } from './CatalogWithPagination-hooks'
import { EmptyList } from './organisms/EmptyList'
import { HotKeys } from 'react-hotkeys'
import InfiniteScroll from 'react-infinite-scroller'
import LoaderLazy from 'blocks.app/loader/_lazy/loader__lazy'
import { CatalogWithPaginationContext } from './CatalogWithPagination-context'
import { TileList } from './organisms/TileList/'
import Toast from 'blocks.simple/toast/toast'
import { toast } from 'react-toastify'
// @ts-ignore
import template from 'es6-template-strings'
import translate from 'core/translate'
import { useWindowResize } from 'core/hooks'
import { cols } from 'theme'
import { List } from './organisms/List'
import { TDispositionTypes } from 'core/models/Catalogs'
import { HeaderWrapper } from './organisms/HeaderWrapper'
import { ReferenceBookList } from './organisms/ReferenceBookList'
import { IAuthenticatedUserState } from 'blocks.app/user/user.state'
import { useTypedAuthenticatedSelector } from 'core/store/hooks'
import { isHorizontalMenu } from 'core/helpers/menu'

const keyMap: any = {
    shiftDown: { sequence: 'shift', action: 'keydown' },
    shiftUp: { sequence: 'shift', action: 'keyup' },
}

const CatalogWithPaginationComponent: FC<ICatalogWithPaginationProps> = ({
    options,
    minimize,
    location,
    disposition,
    useWindow = true,
    showMobToolbar,
    wrapperClassName = {},
}) => {
    const { data: user } = useTypedAuthenticatedSelector<IAuthenticatedUserState>((state) => state.user)
    const { common, breadcrumbs, methods, toolbar, emptyList, actionBar } = options
    const { isEqual, isFolder, onDoubleClickObject, onDragStart, onDragEnd, decorateCatalogItemModel } = methods

    const {
        fields,
        parentKey,
        type,
        onCreateListeners = [],
        onUpdateListeners = [],
        onDeleteListeners = [],
        onMoveListeners = [],
        getMethodName,
        getByParentIdMethodName,
        filterOptions = {},
        defaultDisposition,
        dispositionList,
        searchKey = 'query',
        isEnableFixedHeader = true,
        perPage,
    } = common

    const viewParams = useMemo(() => {
        return helpers.getViewParamsByUser(type, user)
    }, [user])

    const locationQuery = getURLSearchParamsByLocation(location)
    const query = helpers.getQuery(locationQuery, type)

    const getFilterQueryPartial = getFilterQuery({
        parentKey,
        type,
        viewFields: fields,
        viewFilter: filterOptions,
        searchKey,
    })

    const { handlers, isShiftPressed } = useCatalogKeysMap()

    const {
        onSetSelectedInfo,
        onSetSelectedItems,
        onResetSelectedInfo,
        onResetSelectedItems,
        selectedInfo,
        selectedItems,
        onResetAllSelection,
        addSelectedItem,
        removeSelectedItemById,
    } = useSelectedCatalogItems()

    const useFetchCatalogItemsOptions = {
        parentKey,
        isEqual,
        isFolder,
        getMethodName,
        getByParentIdMethodName,
        getFilterQuery: getFilterQueryPartial,
        decorateCatalogItemModel,
        perPage,
    }

    const {
        catalogItemList,
        isReachLimit,
        isLoading,
        isInit,
        loadMore,
        fetchCatalogItemsByParentId,
        nestedCatalogItemList,
        isCreateItemInit,
        openCreateItem,
        closeCreateItem,
    } = useFetchCatalogItems({
        onResetSelectedInfo,
        removeSelectedItemById,
        onResetSelectedItems,
        search: location.search,
        options: useFetchCatalogItemsOptions,
        viewParams,
        onCreateListeners,
        onUpdateListeners,
        onDeleteListeners,
        onMoveListeners,
    })

    const [notification, setNotification] = useState<string | null>(null)
    const { width, height } = useWindowResize({ width: window.innerWidth, height: window.innerHeight })

    const isExistBreadcrumbs = () => isExist(breadcrumbs)

    const isExistRightToolbar = () => isExist(toolbar)

    const isExistActionBar = () => isExist(actionBar)

    const isExistHeader = () => isExistBreadcrumbs() || isExistActionBar()

    const renderActionBar = () => {
        if (!actionBar) return null

        const ActionBar = actionBar.component

        return <ActionBar />
    }

    const renderToolbar = () => {
        if (!toolbar) return null

        const CatalogToolbar = toolbar.component

        return (
            <div
                className={cn(styles.CatalogWithPagination__toolbar, {
                    [styles.CatalogWithPagination__toolbar_type_enable]: showMobToolbar,
                    //[styles.CatalogWithPagination__toolbar_padding_top_x]: isHorizontalMenu(),
                })}
                style={{ width: getToolbarWidth() }}
            >
                <CatalogToolbar />
            </div>
        )
    }

    const getToolbarWidth = () => {
        const catalogPercetageWidth = minimize ? 100 - cols.mini : 100 - cols.normal
        const catalogWidth = (catalogPercetageWidth / 100) * width
        return (catalogWidth * cols.normal) / 100
    }

    const getHeaderPosition = () => {
        const left = minimize ? cols.mini : cols.normal
        const right = isExistRightToolbar() ? getToolbarWidth() : 0

        return {
            left: `${left}%`,
            right: `${right}px`,
        }
    }

    const renderBreadcrumbs = () => {
        if (!breadcrumbs) return null

        const { title, methodName } = breadcrumbs

        return <Breadcrumbs title={title} methodName={methodName} parentValue={stringToNumber(query[parentKey])} />
    }

    const isCatalogItemsEmpty = () => catalogItemList.length === 0

    const renderEmptyList = () => {
        const { searchText, folderText, getAddButton } = emptyList

        return (
            <EmptyList isSearchEnable={query.query} folderText={folderText} searchText={searchText}>
                {getAddButton && getAddButton()}
            </EmptyList>
        )
    }

    const onSelectAllItems = () => {
        onSetSelectedItems(catalogItemList.map(({ id }) => id))
    }

    const isStartSelectionByShift = () => {
        return isShiftPressed && (selectedInfo || selectedItems.length > 0)
    }

    const onSelectInfo = (id: number, parentId: number | null) => {
        if (isStartSelectionByShift()) {
            onSelectItemsByShift(id, parentId)
            return
        }

        onSetSelectedInfo(id)
    }

    const onSelectItemsByShift = (id: number, parentId: number | null) => {
        const isMixedSelectedItems = isMixedSelectedItemsExist(parentId)

        if (isMixedSelectedItems) {
            onSetSelectedInfo(id)
            onResetSelectedItems()
            return
        }

        const selectedItemsList = isCurrentFolderItem(parentId)
            ? getSelectedItemsByShift(id, parentId, catalogItemList)
            : getSelectedItemsByShift(id, parentId, nestedCatalogItemList)

        onResetSelectedInfo()
        onSetSelectedItems(selectedItemsList)
    }

    const getSelectedItemsByShift = (id: number, parentId: number | null, listItems: ICatalogItem[]) => {
        const startSelectionIndex = getStartSelectionIndex(parentId, listItems)

        if (!isIndexExist(startSelectionIndex)) return []

        const endSelectionIndex = getEndSelectionIndex(id, parentId, listItems)
        if (!isIndexExist(endSelectionIndex)) return []

        return getSelectedItemsFromCatalogItems(startSelectionIndex, endSelectionIndex, listItems)
    }

    const getStartSelectionIndex = (parentId: number | null, listItems: ICatalogItem[]) => {
        let startSelectionIndex = listItems.findIndex(
            (item) => item.id === selectedInfo && item[parentKey] === parentId
        )

        if (isNotEmptyArray(selectedItems)) {
            startSelectionIndex = listItems.findIndex(
                (item) => item.id === selectedItems[0] && item[parentKey] === parentId
            )
        }

        return startSelectionIndex
    }

    const getEndSelectionIndex = (id: number, parentId: number | null, listItems: ICatalogItem[]) => {
        let endSelectionIndex = listItems.findIndex((item) => item.id === id && item[parentKey] === parentId)

        return endSelectionIndex
    }

    const getSelectedItemsFromCatalogItems = (
        startSelectionIndex: number,
        endSelectionIndex: number,
        listItems: ICatalogItem[]
    ) => {
        let selectedItemsList =
            startSelectionIndex > endSelectionIndex
                ? listItems.slice(endSelectionIndex, startSelectionIndex + 1).reverse()
                : listItems.slice(startSelectionIndex, endSelectionIndex + 1)

        return selectedItemsList.map((item) => item.id)
    }

    const isMixedSelectedItemsExist = (parentId: number | null) => {
        const listItems = [...nestedCatalogItemList, ...catalogItemList]
        const allSelectedItems = selectedInfo ? [...selectedItems, selectedInfo] : [...selectedItems]

        for (let i = 0; i < allSelectedItems.length; i++) {
            let findedSelectedItem = listItems.find(
                (item) => item.id === allSelectedItems[i] && item[parentKey] === parentId
            )

            if (!isExist(findedSelectedItem)) {
                return true
            }
        }

        return false
    }

    const isCurrentFolderItem = (parentId: number | null) => {
        let currentFolderId = isExist(query[parentKey]) ? stringToNumber(query[parentKey]) : null

        return currentFolderId === parentId
    }

    const handleDragStart = (event: React.MouseEvent) => {
        if (onDragStart) {
            onDragStart(event)
        }

        const count = selectedItems.length ? selectedItems.length : 1
        const message = template(translate('transfer'), { count })
        const toastOptions = {
            closeOnClick: false,
            closeButton: false,
            autoClose: false,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            position: 'bottom-center',
        }

        setNotification(Toast.default(message, toastOptions) as string)
    }

    const handleDragEnd = (event: React.MouseEvent) => {
        if (!notification) return

        toast.dismiss(notification)

        setNotification(null)

        if (onDragEnd) {
            onDragEnd(event)
        }
    }

    const onMoveMultiple = (targetData: ICatalogDnDTargetData, cb: (isActiveDnD: boolean) => void) => {
        const { moveMultiple } = methods
        const sourceId: number[] = []
        const folderId: number[] = []
        const parentId = targetData.target.id

        if (!parentId || !moveMultiple) return

        selectedItems.forEach((id) => {
            if (id === parentId) return

            const item = catalogItemList.find(({ id: itemId }) => itemId === id)

            if (!item) return

            isFolder(item) ? folderId.push(item.id) : sourceId.push(item.id)
        })

        moveMultiple(sourceId, folderId, parentId).then((res) => {
            onResetAllSelection()
        })

        cb(false)
    }

    const onMoveItem = (
        droppedItem: ICatalogItem,
        targetData: ICatalogDnDTargetData,
        cb: (isActiveDnD: boolean) => void
    ) => {
        const { isEnableMultipleSelect = true } = common
        const { moveFolder, moveItem, moveMultiple } = methods

        if (moveMultiple && isEnableMultipleSelect && selectedItems.length > 0) {
            const isSelectedDnDItem = isExist(selectedItems.find((id) => id === droppedItem.id))

            if (isSelectedDnDItem) {
                onMoveMultiple(targetData, cb)
                return
            }
        }

        const moveMethod = isFolder(droppedItem) ? moveFolder : moveItem

        if (!moveMethod) {
            cb(false)
            return
        }

        const folderId = targetData.target.id

        if (folderId) {
            moveMethod(droppedItem.id, folderId)
        }

        cb(false)
    }

    const getCurrentDisposition = () => {
        let currentDisposition = disposition || viewParams.disposition || defaultDisposition

        return !dispositionList.includes(currentDisposition as TDispositionTypes)
            ? defaultDisposition
            : currentDisposition
    }

    const renderMap = () => {
        if (!isLoading && isCatalogItemsEmpty()) return renderEmptyList()

        if (isCatalogItemsEmpty()) return null

        return null
    }

    const renderList = () => {
        if (!isLoading && isCatalogItemsEmpty()) return renderEmptyList()

        if (isCatalogItemsEmpty()) return null

        const { list } = common

        if (!isExist(list)) return null

        return (
            <HotKeys
                keyMap={keyMap}
                handlers={handlers}
                className={cn(styles.CatalogWithPagination__hotKeys, {
                    [styles.CatalogWithPagination__hotKeys_type_window]: !useWindow,
                    [styles.CatalogWithPagination__hotKeys_type_shiftPressed]: isShiftPressed,
                })}
            >
                <InfiniteScroll
                    loadMore={handleLoadMore}
                    hasMore={!isReachLimit}
                    initialLoad={false}
                    loader={<LoaderLazy key="catalog_loader" active />}
                    threshold={10}
                    useWindow={useWindow}
                >
                    <List nestedList={nestedCatalogItemList} list={catalogItemList} minimize={minimize} />
                </InfiniteScroll>
            </HotKeys>
        )
    }

    const handleLoadMore = () => loadMore()

    const onListFolderOpen = (parentId: number, cb: () => void) => fetchCatalogItemsByParentId(parentId, cb)

    const renderTiles = () => {
        if (!isLoading && isCatalogItemsEmpty()) return renderEmptyList()

        if (isCatalogItemsEmpty()) return null

        const { tile } = common

        if (!isExist(tile)) return null

        return (
            <HotKeys
                keyMap={keyMap}
                handlers={handlers}
                className={cn(styles.CatalogWithPagination__hotKeys, {
                    [styles.CatalogWithPagination__hotKeys_type_window]: !useWindow,
                    [styles.CatalogWithPagination__hotKeys_type_shiftPressed]: isShiftPressed,
                })}
            >
                <InfiniteScroll
                    loadMore={handleLoadMore}
                    hasMore={!isReachLimit}
                    initialLoad={false}
                    loader={<LoaderLazy key="catalog_loader" active />}
                    threshold={10}
                    useWindow={useWindow}
                >
                    <TileList list={catalogItemList} minimize={minimize} />
                </InfiniteScroll>
            </HotKeys>
        )
    }

    const renderReferenceBook = () => {
        const { referenceBook } = common

        if (!isExist(referenceBook)) return null

        return (
            <Fragment>
                <HotKeys
                    keyMap={keyMap}
                    handlers={handlers}
                    className={cn(styles.CatalogWithPagination__hotKeys, {
                        [styles.CatalogWithPagination__hotKeys_type_window]: !useWindow,
                        [styles.CatalogWithPagination__hotKeys_type_shiftPressed]: isShiftPressed,
                    })}
                >
                    <InfiniteScroll
                        loadMore={handleLoadMore}
                        hasMore={!isReachLimit}
                        initialLoad={false}
                        loader={<LoaderLazy key="catalog_loader" active />}
                        threshold={10}
                        useWindow={useWindow}
                    >
                        <Fragment>
                            {isExistHeader() && <div className={styles.CatalogWithPagination__divider}></div>}
                            <ReferenceBookList list={catalogItemList} minimize={minimize} />
                        </Fragment>
                    </InfiniteScroll>
                </HotKeys>
                {!isLoading && isCatalogItemsEmpty() && renderEmptyList()}
            </Fragment>
        )
    }

    const renderCatalogContent = () => {
        const currentDisposition = getCurrentDisposition()

        switch (currentDisposition) {
            case 'map': {
                return renderMap()
            }
            case 'list': {
                return renderList()
            }
            case 'tile': {
                return renderTiles()
            }
            case 'referenceBook': {
                return renderReferenceBook()
            }
            default: {
                return null
            }
        }
    }

    const getHeaderWrapperComponent = () => {
        return isEnableFixedHeader ? FixedHeaderWrapper : HeaderWrapper
    }

    const renderHeader = () => {
        const HeaderComponent = getHeaderWrapperComponent()

        return (
            <HeaderComponent positionStyles={getHeaderPosition()}>
                <Fragment>
                    {isExistActionBar() && renderActionBar()}
                    <div className={styles.CatalogWithPagination__divider}></div>
                    {isExistBreadcrumbs() && renderBreadcrumbs()}
                </Fragment>
            </HeaderComponent>
        )
    }

    const render = () => {
        if (!isInit) return null

        return renderCatalogContent()
    }

    return (
        <div className={cn(styles.CatalogWithPagination, wrapperClassName)}>
            <CatalogWithPaginationContext.Provider
                value={{
                    methods,
                    common,
                    catalogMethods: {
                        onListFolderOpen,
                        onMoveItem,
                        onSelectInfo,
                        onSetSelectedItems,
                        onDoubleClickObject,
                        addSelectedItem,
                        removeSelectedItemById,
                        onDragStart: handleDragStart,
                        onDragEnd: handleDragEnd,
                        onResetSelectedInfo,
                        onResetSelectedItems,
                        onSelectAllItems,
                        openCreateItem,
                        closeCreateItem,
                    },
                    catalogState: {
                        selectedInfo,
                        selectedItems,
                        catalogItemList,
                        nestedCatalogItemList,
                        isCreateItemInit,
                        fullCatalogItemList: [...catalogItemList, ...nestedCatalogItemList],
                    },
                }}
            >
                <div
                    className={cn(styles.CatalogWithPagination__main, {
                        [styles.CatalogWithPagination__main_type_withToolbar]: isExistRightToolbar(),
                        [styles.CatalogWithPagination__toolbar_padding_top_x]: width > 960 && isHorizontalMenu(),
                        [styles.CatalogWithPagination__main_x]: width > 960 && isHorizontalMenu(),
                    })}
                >
                    {isExistHeader() && renderHeader()}
                    {render()}
                    {<ScrollUp isExistRightToolbar={isExistRightToolbar()} />}
                </div>

                {isExistRightToolbar() && renderToolbar()}
            </CatalogWithPaginationContext.Provider>
        </div>
    )
}

export const CatalogWithPagination = memo(CatalogWithPaginationComponent)
