import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'molecules/Icon'
import ReactDOM from 'react-dom'
import { useTransition, animated } from 'react-spring'
import styles from './styles'
import { OwnerPanel } from './OwnerPanel'
import { UsersList } from './UserList'
import helpers from 'core/helpers'
import { cn } from 'ethcss'
import { isArray } from 'lodash'
import { isHorizontalMenu } from 'core/helpers/menu'

export const getRolesString = (roles) => {
    if (!isArray(roles)) return null

    return roles.map((role) => helpers.translateRoleLabel(role).label).join(', ')
}

const USER_WINDOW_ID = 'USER_WINDOW_ID'

const UserWindow = ({ open, user, mainUser, onClose, onEditAccount, className, transitionKeys, transitionKeys_x }) => {
    const [container, setContainer] = useState(null)
    const userWindowTransition = useTransition(open, null, isHorizontalMenu() ? transitionKeys_x : transitionKeys)
    const ref = useRef()

    useEffect(() => {
        const container = createContaner()
        renderContainer(container)
        setContainer(container)
    }, [])

    const createContaner = () => {
        const div = document.createElement('div')
        div.id = USER_WINDOW_ID
        return div
    }

    const renderContainer = (element) => {
        document.body.append(element)
    }

    const renderContent = () => {
        return (
            <>
                {userWindowTransition.map(({ item, key, props }) => {
                    return item ? (
                        <animated.div
                            className={cn(styles.wrapper, className)}
                            ref={ref}
                            key={key}
                            style={{ ...props }}
                        >
                            <div className={styles.closeIcon}>
                                <Icon type="close" onClick={onClose} />
                            </div>
                            <OwnerPanel user={user} onClose={onClose} onEditAccount={onEditAccount} />
                            <UsersList user={user} mainUser={mainUser} />
                        </animated.div>
                    ) : null
                })}
            </>
        )
    }

    const renderPortal = () => {
        if (!container) return null

        return ReactDOM.createPortal(renderContent(), container)
    }

    return <>{renderPortal()}</>
}

UserWindow.propTypes = {
    open: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    mainUser: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onEditAccount: PropTypes.func,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    transitionKeys: PropTypes.object,
    transitionKeys_x: PropTypes.object,
}

UserWindow.defaultProps = {
    onEditAccount: () => {},
    className: '',
    transitionKeys: {
        from: { left: -500, opacity: 0 },
        enter: { left: 10, opacity: 1 },
        leave: { left: -500, opacity: 0 },
    },
    transitionKeys_x: {
        from: { right: -500, opacity: 0 },
        enter: { right: 10, opacity: 1 },
        leave: { right: -500, opacity: 0 },
    },
}

export default UserWindow
