import React from 'react'
import CatalogBreadcrumbsMethods from './catalog__breadcrumbs.local.methods'
import Link from 'blocks.simple/link/link'
import Icon from 'blocks.simple/icon/icon'
import DndDroppable from 'blocks.app/dnd/_droppable/dnd_droppable'

import { cn } from 'ethcss'
import { indents } from 'theme'
import styles from './catalog__breadcrumbs.jcss'
import helpers from 'core/helpers'
import { getURLSearchParamsByLocation, getURLSearchParamsString } from 'features/routes/utils'
import { useLocation } from 'react-router'

const Item = (p_) => {
    const { s_, methods } = CatalogBreadcrumbsMethods(p_)
    const { pathname, index, breadcrumb } = p_.listItemData
    const maxBreadcrumbDepth = p_.list.length - 1
    const active = index === maxBreadcrumbDepth

    return (
        <DndDroppable onDrop={methods.onDrop} onDragOver={methods.onDragOver} onDragLeave={methods.onDragLeave}>
            <Link
                className={cn(styles.item, styles.indents, {
                    [styles.itemDropActive]: s_.isHovered && !active,
                    [styles.active]: active,
                })}
                to={{
                    pathname: `/${pathname}`,
                    search: getURLSearchParamsString({
                        ...p_.query,
                        [`${p_.type}__query`]: undefined,
                        [p_.filterName]: breadcrumb.id,
                    }),
                }}
            >
                <Icon
                    type={index === 0 ? 'home' : 'group_files'}
                    size={18}
                    style={{
                        marginRight: indents.mini,
                    }}
                />
                <div className={styles.text}>{breadcrumb.name}</div>
            </Link>
        </DndDroppable>
    )
}

const Breadcrumbs = (p_) => {
    const location = useLocation()
    const query = getURLSearchParamsByLocation(location)
    const locationPathname = location.pathname
    const pathname = helpers.getPathname(locationPathname)

    const renderBreadcrumbList = () =>
        p_.list.map((breadcrumb, index) => (
            <div key={`breadcrumbs__item_${index}`} className={styles.itemWrapper}>
                <Item
                    {...p_}
                    query={query}
                    listItemData={{
                        pathname,
                        index,
                        breadcrumb,
                    }}
                />

                {index < p_.list.length - 1 && (
                    <div className={cn(styles.item, styles.itemArrow)}>
                        <Icon type="arrow_right" default={true} />
                    </div>
                )}
            </div>
        ))

    return (
        <div className={styles.breadcrumbsWrapper}>
            <div className={styles.contentScroll}>{renderBreadcrumbList()}</div>
        </div>
    )
}

export default Breadcrumbs
