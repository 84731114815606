import React from 'react'
import AdvertisingCampaignsToolbarContent from '../__toolbarContent/advertisingCampaigns__toolbarContent'
import Sidebar from 'blocks.app/sidebar/sidebar'
import translate from 'core/translate'
import { api } from 'core/api/ConnectionManager'
import { checkRoutePathName } from 'core/helpers/routes/routes'

interface IACProps {
    selectedInfo: object | null
    selectedItems: { id: number }[]
    onChange: (selectedItems: object[]) => void
    onCloseInfo: () => void
}

const AdvertisingCampaignsToolbar = (p_: IACProps) => {
    return (
        <Sidebar
            selectedSide="right"
            onChange={(list: object[]) => p_.onChange(list)}
            customContent={{
                right: <AdvertisingCampaignsToolbarContent {...p_} />,
            }}
            content={{
                right: !p_.selectedInfo
                    ? {
                          title: translate('promotions'),
                          options: p_.selectedItems,
                      }
                    : null,
            }}
            isEmpty={{
                right: !p_.selectedInfo && p_.selectedItems.length === 0,
            }}
            emptyText={{
                right: {
                    title: checkRoutePathName('/advertisingAds')
                        ? translate('noSelectedAdvertisement')
                        : translate('noSelectedBroadcast'),
                    description: checkRoutePathName('/advertisingAds')
                        ? translate('noSelectedAdvertisementDescription')
                        : translate('noSelectedBroadcastDescription'),
                },
            }}
            isShowActions={true}
            isDeletable={true}
            onSubmit={(side: string, type: string) => {
                if (side === 'right' && type === 'delete') {
                    const id = p_.selectedItems.map((selectedItem: { id: number }) => selectedItem.id)
                    api.send(
                        checkRoutePathName('/advertisingAds') ? 'deleteAdvertisement' : 'deleteAdvertisingCampaign',
                        { id }
                    ).then(() => {
                        p_.onChange([])
                    })
                }
            }}
        />
    )
}

export default AdvertisingCampaignsToolbar
