import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { addStyles } from 'ethcss'
import { grid, IStyles, textSizes } from 'theme'

const MAX_TABLE_WIDTH = 400

export const styles: IStyles = {
    ControllerDataTable: {
        ...grid.flex,
        ...grid.col,
        ...grid.center,
        ...grid.normalCenter,
    },
    ControllerDataTable__devices: {
        ...grid.flex,
        ...grid.col,
        ...grid.normalCenter,
        width: '100%',
        paddingBottom: 18,
        '& table': {
            width: '100%',
            maxWidth: MAX_TABLE_WIDTH,
        },
        '& th': {
            width: '33.3%',
            textAlign: 'center',
            fontSize: textSizes.normal,
            fontWeight: 400,
        },
        '& td': {
            padding: 12,
            textAlign: 'center',
            fontSize: textSizes.md,
            fontWeight: 700,
        },
    },
    ControllerDataTable__brightness: {
        width: '100%',
        ...grid.flex,
        ...grid.col,
        ...grid.center,
        ...grid.normalCenter,
        paddingTop: 30,
        paddingBottom: 30,
        borderTop: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    ControllerDataTable__label: {
        paddingBottom: 10,
        textTransform: 'uppercase',
        fontSize: textSizes.normal,
        fontWeight: 'bold',
    },
    ControllerDataTable__text: {
        fontSize: textSizes.md,
        fontWeight: 'bold',
    },
}

addStyles(styles)
