import template from 'es6-template-strings'
import translate from '../../core/translate'
declare var window: any

export const customNotification = (error: any) => {
    const err = error.error.toString()

    switch (err) {
        case '5':
            return template(translate(error.error), { title: error.data.broadcast.title })
        case '17':
        case '18':
        case '19':
        case '20':
        case '105':
        case '47':
            return `${translate(err)} ${error.message}`
        case 'serverIsNotAvailable':
            return `${translate(err)} ${window.spconfig.emailSupport}`
        default:
            return translate('error')
    }
}
