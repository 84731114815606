import { colors } from 'theme'

export const defaultThemeStyles = {
    background: colors.white,
    header: colors.grey,
    headerText: colors.white,
    dayNumber: colors.grey,
    selectedDate: colors.greyLight,
    cellLine: colors.grey,
    lineShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
    'active-cellLine': colors.selected,
}
