import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IHeadState {
    title: string[]
}

const initialState: IHeadState = {
    title: [],
}

const headSlice = createSlice({
    name: 'head',
    initialState,
    reducers: {
        setTitle(state, action: PayloadAction<string[]>) {
            state.title = action.payload
        },
    },
})

const { reducer: headReducer, actions: headActions } = headSlice
export { headReducer, headActions }
