import { useEffect, useState } from 'react'

export interface IUseInactivityTimeout {
    initialValue: number
}

export const useInactivityTimeout = ({ initialValue }: IUseInactivityTimeout) => {
    let [value, setValue] = useState<null | number>(initialValue)

    useEffect(() => {
        if (value !== initialValue) {
            setValue(initialValue)
        }
    }, [initialValue])

    const setInactivityTimeout = (val: number | null) => {
        setValue(val)
    }

    return {
        setInactivityTimeout,
        value,
    }
}
