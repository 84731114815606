import { addStyles } from 'ethcss'
import { grid, indents, IStyles, textSizes } from 'theme'

export const styles: IStyles = {
    DeviceSettingsOnOffTimers: {
        ...grid.row,
        ...grid.fullWidth,
        ...grid.mt_mdPlus,
    },
    DeviceSettingsOnOffTimers__title: {
        ...grid.mb_md,
        fontSize: textSizes.normal,
    },
    DeviceSettingsOnOffTimers__timers: {
        ...grid.row,
        ...grid.fullWidth,
    },
}

addStyles(styles)
