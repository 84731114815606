import React from 'react'
import { api } from 'core/api/ConnectionManager'
import moment from 'moment'
import translate from 'core/translate'
import styles from './catalog__template_schedule.jcss'
import { CatalogActionBar } from 'organisms/Catalog/ActionBar/ActionBar-view'
import { ActionBarRequestHeaderType, ActionBarActionType } from 'core/models/ActionBar'
import EditableText from 'blocks.simple/editableText/editableText'
import CatalogSelectContent from '../../../_select/_content/catalog_select_content'
import Button from 'blocks.simple/button/button'
import grid from 'blocks.simple/grid/grid.jcss'
import { cn } from 'ethcss'
import { DatePickerSingle } from '../../../../../blocks.simple/datepickerSingle/datepickerSingle'
import { TextEditor } from 'molecules/TextEditor'
import { IActionBarComponent } from '../../../../../organisms/Catalog/ActionBar/ActionBar-types'
import { ItemData } from '../../../../../core/models/Template'

export interface IAuthorProps {
    authorId: number
    firstName: string
    secondName: string
    lastName: string
    description: string
    birthday: string
    sourceId: number
    avatar: { id: number }
}

const toolbar = {
    options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
}

const getActionList = (p_: any) => {
    return []
}

export default {
    getMethod: 'getSchoolSchedules',
    customParams: {},
    trNoClick: true,
    disableDrag: true,
    emptySearchText: () => translate('notFound'),
    emptyDescription: () => translate('createFirstPosition'),
    emptyFolderText: () => translate('listIsEmpty'),
    emptyIcon: 'not_allow',
    addItemButton: () => null,
    settingsPrefixes: 'schoolSchedule',
    sortOptions: () => [],
    headerFilters: (p_: IActionBarComponent) => {
        return (
            <CatalogActionBar
                title={translate('schedule')}
                requestHeaderType={ActionBarRequestHeaderType.WITH_AUTH}
                type="authorsList"
                actions={getActionList(p_)}
                {...p_}
            />
        )
    },

    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },

    getBackendModel: (item: ItemData) => {
        return null
    },
    tableWrapper: styles.tableWrapper,
    isEqual: (firstItem: ItemData, secondItem: ItemData) => {
        if (firstItem.authorId && secondItem.authorId) {
            return firstItem.authorId === secondItem.authorId
        }

        return firstItem === secondItem
    },

    onCreateListeners: [],
    onUpdateListeners: [],
    onDeleteListeners: [],
    isFolder: () => {},
    fields: () => [
        { id: 'startTime', name: translate('startTime') },
        { id: 'endTime', name: translate('endTime') },
        { id: 'subject', name: translate('subject') },
        { id: 'teacher', name: translate('teacher') },
        { id: 'classNumberAndLetter', name: translate('classNumberAndLetter') },
        { id: 'classRoom', name: translate('classRoom') },
    ],
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        startTime: (p_: any, active: boolean, listProps: { onAddFakeLine: any; fakeLine: IAuthorProps }) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.lastName })}>
                <div className={cn({ [grid.fullWidth]: p_.lastName, [grid.space]: !p_.lastName })}>{p_.startTime}</div>
            </div>
        ),
        endTime: (p_: any, active: boolean, listProps: { onAddFakeLine: any; fakeLine: IAuthorProps }) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.lastName })}>
                <div className={cn({ [grid.fullWidth]: p_.lastName, [grid.space]: !p_.lastName })}>{p_.endTime}</div>
            </div>
        ),
        subject: (p_: any, active: boolean, listProps: { onAddFakeLine: any; fakeLine: IAuthorProps }) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.lastName })}>
                <div className={cn({ [grid.fullWidth]: p_.lastName, [grid.space]: !p_.lastName })}>{p_.subject}</div>
            </div>
        ),
        teacher: (p_: any, active: boolean, listProps: { onAddFakeLine: any; fakeLine: IAuthorProps }) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.lastName })}>
                <div className={cn({ [grid.fullWidth]: p_.lastName, [grid.space]: !p_.lastName })}>{p_.teacher}</div>
            </div>
        ),
        classNumberAndLetter: (p_: any, active: boolean, listProps: { onAddFakeLine: any; fakeLine: IAuthorProps }) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.lastName })}>
                <div className={cn({ [grid.fullWidth]: p_.lastName, [grid.space]: !p_.lastName })}>
                    {`${p_.classNumber}${p_.classLetter}`}
                </div>
            </div>
        ),
        classRoom: (p_: any, active: boolean, listProps: { onAddFakeLine: any; fakeLine: IAuthorProps }) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.lastName })}>
                <div className={cn({ [grid.fullWidth]: p_.lastName, [grid.space]: !p_.lastName })}>{p_.classroom}</div>
            </div>
        ),
    },
    hideActiveRowColor: true,
    disableUpdateUserSettings: true,
    disableListScroll: true,
    fixedColWidth: false,
    dndDblClickDisable: true,
}
