import React from 'react'
import translate from '../../../core/translate'
import Icon from '../../../blocks.simple/icon/icon'
import styles from './widgetInfo.jcss'
import { IWidgetData } from '../widget/Widget'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import { colors } from '../../../theme'
import filesize from 'filesize'

interface IWidgetInfoProps {
    modalMode?: boolean
    data: IWidgetData
    lang: string
    setWidgetDownload: (data: IWidgetData) => void
    setWidgetInfo: (data: IWidgetData | null) => void
}

const handleDragStart = (e: any) => e.preventDefault()

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 2 },
}

export const WidgetInfo = ({ data, lang, setWidgetDownload, setWidgetInfo, modalMode }: IWidgetInfoProps) => {
    const getPreviewImages = () => {
        return data.previewImages.map((src: string) => {
            return (
                <img
                    style={{ width: '100%', padding: '0 4px', borderRadius: '5px' }}
                    src={src}
                    onDragStart={handleDragStart}
                    role="presentation"
                />
            )
        })
    }
    const getWikiLang = () => {
        return lang === 'ru' || lang === 'kz' ? '/ru' : '/en'
    }
    const getDescription = (description: any) => {
        return description.replace(/\n/g, '<br />')
    }

    return (
        <div>
            <div className={modalMode ? styles.closeBlock : styles.closeBlockModal}>
                <Icon type={'close'} size={20} color={colors.blackLight} onClick={() => setWidgetInfo(null)} />
            </div>
            <div className={styles.topLine}>
                <div className={styles.widgetInfoTopLineLeft}>
                    <div>
                        <img src={data.thumbnailURL} />
                    </div>
                    <div className={styles.titleAndDownload}>
                        <div className={styles.title}>{data.title[lang]}</div>
                        <div
                            className={styles.actionBtn}
                            onClick={() => {
                                setWidgetDownload(data)
                            }}
                        >
                            {translate('upload')}
                            <span className={styles.actionIcon}>
                                <Icon type={'download'} size={14} color={'white'} />
                            </span>
                        </div>
                    </div>
                </div>
                <div className={styles.widgetInfoTopLineRight}>
                    <div className={styles.metaInfo}>
                        {translate('version')} {data.version}
                    </div>
                    <div className={styles.metaInfo}>
                        {translate('size')} {filesize(data.fileSize)}
                    </div>
                </div>
            </div>
            {data.previewImages.length ? (
                <div className={styles.sliderBlock}>
                    <AliceCarousel
                        autoPlay={true}
                        autoPlayInterval={4000}
                        infinite={true}
                        items={getPreviewImages()}
                        responsive={responsive}
                        disableButtonsControls={true}
                        disableDotsControls={true}
                    />
                </div>
            ) : null}
            <div className={styles.descriptionBlock}>
                <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: getDescription(data.description[lang]) }}
                />
                <a className={styles.actionBtn} target={'_blank'} href={data.wikiUrl + getWikiLang()}>
                    {translate('instruction')}
                    <span className={styles.actionIcon}>
                        <Icon type={'instruction'} size={14} color={'white'} />
                    </span>
                </a>
            </div>
        </div>
    )
}
