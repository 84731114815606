import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { layers, mediaCSS } from 'blocks.app/config'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/sidebar/Sidebar-theme'
import { defaultThemeStyles } from '../VideoEditor-theme'
import { textSizes } from 'theme/media'
import { IStyles } from 'theme'

const videoEditorSidebarStyles: IStyles = {
    colsRight: {
        ...item.shadow,
        ...item.transitionErase,
        backgroundColor: `var(--var-module-videoEditor-component-videoEditor-style-colsRight, ${defaultThemeStyles.colsRight})`,
        ...grid.fullHeight,
        overflow: 'none',
        zIndex: layers.sticky + 2,
    },
    colsRightOpen: {
        [mediaCSS.tab]: {
            ...item.fixed,
            ...grid.fullHeight,
            width: '100%!important',
            right: 0,
        },
    },
    wrapper: {
        ...grid.col,
        ...grid.fullHeight,
    },
    contentWrapper: {
        ...grid.col,
        ...grid.normalCenter,
        ...grid.space,
        ...item.overY,
    },
    content: {
        ...grid.col,
        ...grid.justify,
        ...grid.full,
    },
    title: {
        textAlign: 'center',
        fontSize: textSizes.normalPlus,
        ...grid.p_md,
        borderBottom: `1px solid var(--var-module-sidebar-component-sidebar-style-border, ${defaultThemeAppStyles.border})`,
    },
    subTitle: {
        ...grid.pl_md,
        ...grid.pr_md,
        ...grid.pt_md,
    },
    contentTitle: {
        textAlign: 'center',
        fontSize: textSizes.normalPlus,
        ...grid.p_md,
        borderTop: `1px solid var(--var-module-sidebar-component-sidebar-style-border, ${defaultThemeAppStyles.border})`,
        borderBottom: `1px solid var(--var-module-sidebar-component-sidebar-style-border, ${defaultThemeAppStyles.border})`,
    },
    description: {
        ...grid.pr_md,
        ...grid.pl_md,
        ...grid.pb_md,
        fontSize: textSizes.mini,
        color: `var(--var-module-sidebar-component-sidebar-style-description, ${defaultThemeAppStyles.description})`,
    },
    buttonWrapper: {
        ...grid.row,
        ...grid.rowCenter,
        ...grid.noWrap,
        ...grid.mb_mdPlus,
        ...grid.mt_md,
    },
    header: {
        ...grid.row,
        borderBottom: `1px solid var(--var-module-sidebar-component-sidebar-style-border, ${defaultThemeAppStyles.border})`,
    },
    catalogWrapper: {
        ...grid.spaceHeight,
        ...grid.col,
        ...grid.pr_micro,
        ...grid.pl_micro,
        overflowY: 'auto!important',
    },
    toggle: {
        ...grid.row,
        ...grid.fullHeight,
    },
    toggleItem: {
        ...grid.rowCenter,
        ...grid.fullHeight,
        cursor: 'pointer',
        ...item.transitionErase,
        fontSize: textSizes.mini,
        ...grid.p_md,
        borderRight: `1px solid var(--var-module-sidebar-component-sidebar-style-border, ${defaultThemeAppStyles.border})`,
        '&:last-child': {
            borderRight: 'none',
        },
        '&:hover': {
            color: `var(--var-module-videoEditor-component-videoEditor-style-toggleActive, ${defaultThemeStyles.toggleActive})`,
        },
    },
    toggleItemDisable: {
        color: `var(--var-module-videoEditor-component-videoEditor-style-toggleDisable, ${defaultThemeStyles.toggleDisable})`,
    },
    toggleItemActive: {
        color: `var(--var-module-videoEditor-component-videoEditor-style-toggleActive, ${defaultThemeStyles.toggleActive})`,
    },
    settingsContent: {
        ...grid.row,
        ...grid.noWrap,
        ...grid.p_md,
        ...grid.justify,
    },
    minimize: {
        cursor: 'pointer',
        borderRight: `1px solid var(--var-module-sidebar-component-sidebar-style-border, ${defaultThemeAppStyles.border})`,
        ...grid.p_md,
    },
}

addStyles(videoEditorSidebarStyles)
export default videoEditorSidebarStyles
