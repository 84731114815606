import React from 'react'
import PropTypes from 'prop-types'
import ContentEditable from 'molecules/ContentEditable'
import Button from 'blocks.simple/button/button'
import { routes, changeLocation } from 'features/routes'
import translate from 'core/translate'
import styles from './styles'
import { mediaJS } from 'theme'

interface ICreateRoleHeader {
    label: string
    editedRole: any
    onChange: any
    onSave: () => void
}

const CreateRoleHeader = (p_: ICreateRoleHeader) => {
    const handleOnReject = () => {
        changeLocation(`/${routes.roles.path}`)
    }
    return (
        <div className={styles.createRoleHeaderWrapper}>
            <ContentEditable
                className={styles.createRoleHeaderLabelWrapper}
                elementClassName={styles.createRoleHeaderLabel}
                value={p_.label}
                onChange={p_.onChange}
                showIcon={!mediaJS.tabMini.matches}
            />
            <div className={styles.createRoleHeaderButtonsWrapper}>
                <Button className={styles.createRoleHeaderButton} mod={'withBorder'} onClick={handleOnReject}>
                    {translate('cancel')}
                </Button>
                <Button className={styles.createRoleHeaderButton} mod={'fill'} onClick={p_.onSave}>
                    {translate(p_.editedRole ? 'update' : 'create')}
                </Button>
            </div>
        </div>
    )
}

CreateRoleHeader.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    editedRole: PropTypes.object,
}

export default CreateRoleHeader
