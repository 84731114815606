import React from 'react'
import BroadcastsToolbarContent from '../__toolbarContent/broadcasts__toolbarContent'
import Sidebar from 'blocks.app/sidebar/sidebar'
import translate from 'core/translate'
import { api } from 'core/api/ConnectionManager'
import helpersBroadcasts from 'core/helpers/_broadcasts'
import helpers from 'core/helpers'
import styles from './broadcasts__toolbar.jcss'
import { getParamsForMultipleMoveBroadcasts } from 'core/utils/broadcastUtil'
import { withRouter } from 'react-router'

type SelectedItem = {
    id: number
    instanceType: string
}
type Data = {
    sourceId: number[]
    folderId: number[]
    newFolderId: number
}

const BroadcastsToolbar = (p_: any) => {
    const deleteBroadcastFolders = () => {
        let idBroadcasts: number[] = []
        let idBroadcastsFolders: number[] = []

        p_.selectedItems.forEach((item: SelectedItem) => {
            if (helpersBroadcasts.isDirectory(item)) {
                idBroadcastsFolders.push(item.id)
            } else {
                idBroadcasts.push(item.id)
            }
        })

        const deletePromises = []
        if (idBroadcasts.length > 0) {
            deletePromises.push(api.send('deleteBroadcast', { id: idBroadcasts }))
        }

        if (idBroadcastsFolders.length > 0) {
            deletePromises.push(api.send('deleteBroadcastFolder', { id: idBroadcastsFolders }))
        }

        Promise.all(deletePromises).then(() => {
            p_.onChange([])
        })
    }

    const editBroadcastFolders = (data: Data) => {
        const params = getParamsForMultipleMoveBroadcasts(data.sourceId, data.folderId, data.newFolderId)
        api.send('moveToBroadcastFolder', params).then(() => {
            p_.onChange([])
        })
    }

    return (
        <Sidebar
            selectedSide="right"
            onChange={(list: object[]) => p_.onChange(list)}
            customContent={{
                right: p_.selectedInfo ? <BroadcastsToolbarContent {...p_} /> : null,
            }}
            onGetFoldersOnMultiple={p_.onGetFoldersOnMultiple}
            bottomContent={{
                right: (
                    <div
                        onClick={function () {
                            p_.onChange([])
                        }}
                        className={styles.menuItem}
                    >
                        {translate('deselect')}
                    </div>
                ),
            }}
            content={{
                right: !p_.selectedInfo
                    ? {
                          title: translate('broadcasts'),
                          options: p_.selectedItems,
                          folders: p_.folders,
                          folderId: p_.folderId,
                          editTitle: translate('parentFolder'),
                          isDirectory: (option: { instanceType: string }) => helpersBroadcasts.isDirectory(option),
                      }
                    : null,
            }}
            isEmpty={{
                right: !p_.selectedInfo && p_.selectedItems.length === 0,
            }}
            emptyText={{
                right: {
                    title: translate('noSelectTranslation'),
                    description: translate('noSelectedTranslationDescription'),
                },
            }}
            isShowActions={true}
            isDeletable={!helpers.isSomeNotHaveAction(p_.selectedItems, 'delete')}
            isEditable={!helpers.isSomeNotHaveAction(p_.selectedItems, 'update')}
            onSubmit={(side: string, type: string, data: Data) => {
                if (side === 'right' && type === 'delete') {
                    deleteBroadcastFolders()
                } else if (side === 'right' && type === 'edit') {
                    editBroadcastFolders(data)
                }
            }}
        />
    )
}

export default withRouter(BroadcastsToolbar)
