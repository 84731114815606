import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import Icon from 'blocks.simple/icon/icon'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import DndDraggable from 'blocks.app/dnd/_draggable/dnd_draggable'
import DotTooltip from 'blocks.simple/dotTooltip/dotTooltip'
import filesHelpers from 'core/helpers/_files'
import helpers from 'core/helpers'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import styles from './catalog__item_files.jcss'
import { routes } from 'features/routes'
import translate from 'core/translate'
import { getURLSearchParamsString } from 'features/routes/utils'
import { changeLocation } from 'features/routes'
import { useDispatch } from 'react-redux'
import { activateEditLink, activateEditTable, activateEditText, rotateVideo } from 'pages/files/files.state'

const textFileType = ['text', 'xml', 'csv']
const getLandscapeStatusIcon = (landscape) => {
    switch (landscape) {
        case 'in_progress':
            return {
                color: 'darkCloud',
                type: 'progressClock',
            }
        default:
            return {
                color: 'white',
                type: 'play_video',
            }
    }
}

const Item = (p_) => {
    const dispatch = useDispatch()
    const isTextFileType = textFileType.includes(p_.item.fileType)

    return (
        <>
            {p_.item && (
                <Rectangle
                    src={
                        p_.item.fileType === 'video' && p_.landscape === 'in_progress'
                            ? ''
                            : filesHelpers.getSrc(p_.item)
                    }
                    contain={filesHelpers.isIcon(p_.item)}
                    tooltip={{ html: p_.item.name, disabled: p_.item.type !== 'link' }}
                >
                    <div className={styles.content}>
                        {!isTextFileType && <div className={styles.backLayer}></div>}
                        {isTextFileType && (
                            <div className={styles.back}>
                                <div
                                    className={styles.text}
                                    style={
                                        p_.item.style &&
                                        filesHelpers.convertStyles(p_.item.style, {
                                            withoutStyles: ['fontSize'],
                                        })
                                    }
                                >
                                    {helpers.strip_html_tags(p_.item.name)}
                                </div>
                                <div className={styles.backLayer} />
                            </div>
                        )}

                        {p_.item.fileType === 'video' && (
                            <div className={styles.back}>
                                <div className={p_.landscape === 'in_progress' ? styles.text : styles.video}>
                                    <Icon {...getLandscapeStatusIcon(p_.landscape)} size="50" />
                                </div>
                            </div>
                        )}

                        {p_.item.type === 'input' && (
                            <div className={styles.back}>
                                <div className={grid.w70}>
                                    <Icon size={'100%'} type={filesHelpers.getFileIconName(p_.item)} />
                                </div>
                            </div>
                        )}
                        {p_.item.fileType === 'video' && p_.item.videoValidationErrors && (
                            <div className={styles.errorIcon}>
                                <Icon
                                    color="error"
                                    type="attention"
                                    size={18}
                                    tooltip={{
                                        html: translate('fileMayContainErrors'),
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        changeLocation({
                                            pathname: `/${routes.videoEditor.path}`,
                                            search: getURLSearchParamsString({
                                                sourceId: p_.item.id,
                                            }),
                                        })
                                    }}
                                />
                            </div>
                        )}
                        {p_.multipleSelect && (
                            <div className={styles.inputWrapper} onClick={(e) => e.stopPropagation()}>
                                <Checkbox
                                    mod={'circleInv'}
                                    className={cn(styles.input)}
                                    onClick={(selected) => {
                                        p_.onSelect({ selected, item: p_.item })
                                    }}
                                    checked={p_.item && p_.item.__view && p_.item.__view.selected}
                                />
                            </div>
                        )}
                        {p_.item.fileType === 'video' && p_.landscape === 'error' && (
                            <div className={styles.rotateWrapper} onClick={(e) => e.stopPropagation()}>
                                <Icon
                                    type={'rotate'}
                                    onClick={function () {
                                        dispatch(rotateVideo({ sourceId: p_.item.id }))
                                    }}
                                    size={25}
                                    color={p_.isActive ? 'white' : 'darkBlue'}
                                />
                            </div>
                        )}
                        {(textFileType.includes(p_.item.fileType) || p_.item.fileType === 'rss') && (
                            <div className={styles.icon}>
                                <Icon
                                    type={'pencil'}
                                    onClick={function () {
                                        dispatch(activateEditText(p_.item.id))
                                    }}
                                    size={13}
                                    color={'white'}
                                />
                            </div>
                        )}
                        {p_.item.type === 'table' && (
                            <div className={styles.icon}>
                                <Icon
                                    type={'pencil'}
                                    onClick={function () {
                                        dispatch(activateEditTable(p_.item.id))
                                    }}
                                    size={13}
                                    color={'white'}
                                />
                            </div>
                        )}
                        {p_.item.type === 'link' && (
                            <div className={styles.icon}>
                                <Icon
                                    type={'pencil'}
                                    onClick={function () {
                                        dispatch(activateEditLink(p_.item.id))
                                    }}
                                    size={13}
                                    color={'white'}
                                />
                            </div>
                        )}
                    </div>
                </Rectangle>
            )}
        </>
    )
}

const FilesTile = (p_) => {
    const [dragData, setDragData] = useState(p_.getDragData(p_))
    const isActive = p_.active || (p_.item.__view && p_.item.__view.selected)

    useEffect(() => {
        setDragData(p_.getDragData(p_))
    }, [p_])

    return (
        <div className={cn(styles.wrapper, { [styles.wrapperActive]: isActive })}>
            <div
                className={styles.itemWrapper}
                onClick={function (e) {
                    if (helpers.isDblTouchTap(e)) {
                        if (!p_.item.isDirectory) {
                            p_.onDoubleClickObject(p_.item)

                            e.preventDefault()
                            return
                        }

                        p_.onSelectFolder(p_.item)
                        return
                    }

                    p_.onSelectInfo(e)
                }}
                onContextMenu={(e) => {
                    if (helpers.isMobileDevice()) {
                        e.preventDefault()
                    }
                }}
            >
                <DndDraggable
                    data={dragData}
                    dragEnabled={p_.drag}
                    isMobileDragEnabled={p_.isMobileDragEnabled}
                    className={styles.item}
                    onDragStart={p_.onDragStart}
                    onDragEnd={p_.onDragEnd}
                >
                    <Item {...p_} landscape={p_.item.dataLandscapeStatus} isActive={isActive} />
                </DndDraggable>
                {!p_.hideTitle && (
                    <DotTooltip
                        containerClass={styles.tooltip}
                        tooltip={{
                            html: textFileType.includes(p_.item.fileType) ? null : p_.item.name,
                            disabled: false,
                        }}
                    >
                        <div className={item.ellipsis}>
                            {textFileType.includes(p_.item.fileType) ? null : p_.item.name}
                        </div>
                    </DotTooltip>
                )}
            </div>
        </div>
    )
}

FilesTile.propTypes = {
    drag: PropTypes.bool,
    hideTitle: PropTypes.bool,
    getDragData: PropTypes.func,
    onSelectInfo: PropTypes.func,
}

FilesTile.defaultProps = {
    drag: false,
    hideTitle: false,
    getDragData: () => {},
    onSelectInfo: () => {},
}

export default FilesTile
