import { combineReducers } from '@reduxjs/toolkit'
import { videochatReducer } from 'pages/videoChat/videoChat.state'
import { routesReducer } from 'features/routes/routes.state'
import { displaysAddReducer } from 'pages/displays/__add/displays__add.state'
import { reportReducer } from 'pages/report/report.state'
import { appReducer } from 'blocks.app/app/app.state'
import { downloadsReducer } from 'core/services/DownloadService/DownloadService.state'
import { displaysAddGroupReducer } from 'pages/displays/__addGroup/displays__addGroup.state'
import { filesReducer } from 'pages/files/files.state'
import { broadcastsReducer } from '../../pages/broadcasts/broadcasts.state'
import { draftDevicesReducer } from 'pages/draftDevices/draftDevice.state'
import { displaysReducer } from 'pages/displays/displays.state'
import { IAuthenticatedUserState, userReducer } from 'blocks.app/user/user.state'
import { scheduleReducer } from 'pages/schedule/schedule.state'
import { loaderReducer } from 'features/loader/Loader.state'
import { appNotificationsReducer } from 'features/appNotifications/AppNotifications.state'
import { headReducer } from 'features/head/Head.state'
import { scoreboardsReducer } from 'pages/scoreboards/scoreboards.state'

export const rootReducer = combineReducers({
    broadcasts: broadcastsReducer,
    videoChat: videochatReducer,
    routes: routesReducer,
    displays__add: displaysAddReducer,
    displays__addGroup: displaysAddGroupReducer,
    report: reportReducer,
    draftDevices: draftDevicesReducer,
    user: userReducer,
    files: filesReducer, // TODO NEED OPTIMIZE UPLOAD
    downloads: downloadsReducer, // TODO NEED OPTIMIZE UPLOAD
    loader: loaderReducer,
    appNotifications: appNotificationsReducer,
    app: appReducer,
    schedule: scheduleReducer,
    displays: displaysReducer,
    head: headReducer,
    scoreboards: scoreboardsReducer,
})

export type IRootState = ReturnType<typeof rootReducer>
export type IAuthenticatedRootState = IRootState & { user: IAuthenticatedUserState }
