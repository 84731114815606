import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import { indents } from 'blocks.app/config'
import { colors } from '../../../theme'

const editorFilesStyles = {
    button: {
        ...item.abs,
        bottom: indents.md,
        left: 'calc(50% - 55px)',
    },
    tilesWrapper: {
        position: 'relative',
    },
    multiSelectionPanel: {
        display: 'flex',
        justifyContent: 'center',
        padding: 21,
        backgroundColor: colors.white,
    },
    contentBtn: {
        margin: '0 7px',
    },
}

addStyles(editorFilesStyles)
export default editorFilesStyles
