import React, { Fragment } from 'react'
import DraggableMethods from './draggable.local.methods'
import styles from './draggable.jcss'
import { colors } from 'theme'
import { cn } from 'ethcss'

class Draggable extends DraggableMethods {
    wrapper: any = null

    render() {
        const s_ = this.state
        const p_ = this.props
        const selected = p_.selected || s_.isDraggable || s_.isResizable
        const cornerSquareClassName =
            p_.isShowDraggableSquares && selected
                ? cn(styles.square, styles.square_type_corner, styles[`square_color_${p_.selectedSquareColorType}`])
                : {}
        const borderSquareClassName = cn(
            styles.square,
            styles[`square_color_${p_.selectedSquareColorType}`],
            styles.square_type_side
        )

        return (
            <div
                ref={(wrapper) => (this.wrapper = wrapper)}
                className={cn(styles.wrapper, p_.className)}
                onMouseDown={this.mouseDownHandler}
                style={{
                    position: p_.isFixed ? 'fixed' : 'absolute',
                    left: `${s_.left * 100}%`,
                    top: `${s_.top * 100}%`,
                    width: s_.width !== 'auto' ? `${+s_.width * 100}%` : 'auto',
                    height: s_.height !== 'auto' ? `${+s_.height * 100}%` : 'auto',
                    //@ts-ignore
                    border:
                        !p_.noBorder &&
                        `1px solid ${
                            //@ts-ignore
                            selected && !p_.disableResize ? colors[p_.selectedBorderColor] : colors[p_.borderColor]
                        }`,
                    zIndex: s_.isResizable ? p_.resizableZIndex : p_.zIndex,
                    cursor: p_.disableDrag ? p_.cursor : p_.moveCursor,
                }}
            >
                {p_.children}
                {!p_.disableResize && (
                    <Fragment>
                        {p_.enableResize && p_.enableResize.right && (
                            <Fragment>
                                <div
                                    key={'draggableResize_right'}
                                    onMouseDown={(e) => this.mouseDownResizeHandler(e, 'right')}
                                    className={cn(styles.borderRight)}
                                />
                                {p_.isShowDraggableSquares && selected && (
                                    <div className={cn(borderSquareClassName, styles.square_position_right)}></div>
                                )}
                            </Fragment>
                        )}
                        {p_.enableResize && p_.enableResize.left && (
                            <Fragment>
                                <div
                                    key={'draggableResize_left'}
                                    onMouseDown={(e) => this.mouseDownResizeHandler(e, 'left')}
                                    className={cn(styles.borderLeft)}
                                />
                                {p_.isShowDraggableSquares && selected && (
                                    <div className={cn(borderSquareClassName, styles.square_position_left)}></div>
                                )}
                            </Fragment>
                        )}
                        {p_.enableResize && p_.enableResize.bottom && (
                            <Fragment>
                                <div
                                    key={'draggableResize_bottom'}
                                    onMouseDown={(e) => this.mouseDownResizeHandler(e, 'bottom')}
                                    className={cn(styles.borderBottom)}
                                />
                                {p_.isShowDraggableSquares && selected && (
                                    <div className={cn(borderSquareClassName, styles.square_position_bottom)}></div>
                                )}
                            </Fragment>
                        )}
                        {p_.enableResize && p_.enableResize.top && (
                            <Fragment>
                                <div
                                    key={'draggableResize_top'}
                                    onMouseDown={(e) => this.mouseDownResizeHandler(e, 'top')}
                                    className={cn(styles.borderTop)}
                                />
                                {p_.isShowDraggableSquares && selected && (
                                    <div className={cn(borderSquareClassName, styles.square_position_top)}></div>
                                )}
                            </Fragment>
                        )}
                        {p_.enableResize && p_.enableResize.topRight && (
                            <div
                                key={'draggableResize_topRight'}
                                onMouseDown={(e) => this.mouseDownResizeHandler(e, 'topRight')}
                                className={cn(styles.cornerTR, cornerSquareClassName)}
                            />
                        )}
                        {p_.enableResize && p_.enableResize.bottomRight && (
                            <div
                                key={'draggableResize_bottomRight'}
                                onMouseDown={(e) => this.mouseDownResizeHandler(e, 'bottomRight')}
                                className={cn(styles.cornerBR, cornerSquareClassName)}
                            />
                        )}
                        {p_.enableResize && p_.enableResize.bottomLeft && (
                            <div
                                key={'draggableResize_bottomLeft'}
                                onMouseDown={(e) => this.mouseDownResizeHandler(e, 'bottomLeft')}
                                className={cn(styles.cornerBL, cornerSquareClassName)}
                            />
                        )}
                        {p_.enableResize && p_.enableResize.topLeft && (
                            <div
                                key={'draggableResize_topLeft'}
                                onMouseDown={(e) => this.mouseDownResizeHandler(e, 'topLeft')}
                                className={cn(styles.cornerTL, cornerSquareClassName)}
                            />
                        )}
                    </Fragment>
                )}
            </div>
        )
    }
}

export default Draggable
