import React, { useEffect, useState } from 'react'
import Icon from 'blocks.simple/icon/icon'
import { cn } from 'ethcss'
import { text as textStyles } from 'theme'
import translate from 'core/translate'
import { grid } from 'theme'
import styles from './files__toolbarUploads.jcss'
import { api } from 'core/api/ConnectionManager'

interface IToolbarUploads {
    uploads: { id: number; name: string; progress: number }[]
    folderId: string
    cancelUpload: (id: number) => void
}
interface IDataTasks {
    [key: string]: string | number | Array<object> | object
}
interface IState {
    processingTasks: { [name: string]: {} }
    hasChanged: number
}

const ToolbarUploads = (p_: IToolbarUploads) => {
    const [dataTasks, setDataTasks] = useState<IDataTasks[]>()
    const [state, setState] = useState<IState>({
        processingTasks: {},
        hasChanged: 0,
    })
    let listenersId: string[] = []
    let processingTasks: any = Object.values(state.processingTasks)[0]
    let [percents, setPercents] = useState<number>(processingTasks ? processingTasks.percent : 0)

    let onVideoEditorTaskProgressUpdated = (res: any) => {
        const s_ = state

        s_.processingTasks[res.videoEditorTaskId] = {
            percent: res.percent,
        }
        setState((prevState: IState) => {
            return {
                ...prevState,
                hasChanged: s_.hasChanged + 1,
            }
        })
        setPercents(Math.round(res.percent))
        setState(s_)
    }

    useEffect(() => {
        api.addObserver('videoEditorTaskProgressUpdated', onVideoEditorTaskProgressUpdated, listenersId)
        return () => {
            listenersId.forEach((id) => api.removeObserver(id))
        }
    }, [])

    useEffect(() => {
        api.send('getVideoEditorTasks', {}).then((res: any) => {
            let tasks = res.data.filter((task: IDataTasks) => task.status !== 'success')
            setDataTasks(tasks)
        })
    }, [state.hasChanged])

    return (
        <div>
            <div className={cn(styles.title, grid.p_md, textStyles.center, textStyles.normalPlus)}>
                {translate('uploadFiles')}
            </div>
            {p_.uploads && p_.uploads.length === 0 && (
                <div className={cn(grid.mt_md, textStyles.center)}>{translate('uploadFilesDescription')}</div>
            )}
            {p_.uploads && p_.uploads.length > 0 && (
                <div>
                    {p_.uploads.map((file, index) => (
                        <div className={styles.file} key={`uploads__item_${index}`}>
                            <div className={styles.fileNameWrapper}>
                                <div className={styles.fileName}>{file.name}</div>
                            </div>
                            <div>
                                <div className={styles.cancel}>
                                    <Icon
                                        onClick={() => p_.cancelUpload(file.id)}
                                        type={'close'}
                                        size={12}
                                        color={'white'}
                                    />
                                </div>
                                <div className={styles.progress}>{file.progress}%</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {dataTasks &&
                dataTasks.map(
                    (dataTask: any, index: number) =>
                        dataTask.status !== 'error' &&
                        dataTask.status !== 'idle' && (
                            <div className={styles.file} key={`dataTasks__item_${index}`}>
                                <div className={styles.fileNameWrapper}>
                                    <div className={styles.fileName}>{dataTask.data.fileName}</div>
                                </div>
                                <div>
                                    <div className={styles.progress}>{percents + '%'}</div>
                                </div>
                            </div>
                        )
                )}
        </div>
    )
}

export default ToolbarUploads
