import { useEffect, useState } from 'react'
import translate from 'core/translate'
import helpers from 'core/helpers'
import { changeFilter, routes } from 'features/routes'
import moment from 'moment'
import { api } from 'core/api/ConnectionManager'
import helpersSchedule from 'core/helpers/_schedule'
import helpersBroadcasts from 'core/helpers/_broadcasts'
import { createMethods } from 'pages/schedule/schedule.state'
import { getURLSearchParamsByLocation, getURLSearchParamsString } from 'features/routes/utils'
import { changeLocation } from 'features/routes'
import { Device } from '../../scoreboards/__add/Device'

export type Device = {
    orientation?: number
    id: number
    name: string
    type?: string
}

export type Broadcast = { id: number; title: string; orientation?: number }

const ScheduleAddMethods = (p_: any) => {
    const [orientationWarning, setOrientationWarning] = useState<boolean>(false)
    const [saved, setSaved] = useState(false)
    const [devices, setDevices] = useState<Device[]>([])
    const [conflicts, setConflicts] = useState([])
    const [contentDownloadDate, setContentDownloadDate] = useState(null)
    const [contentDownloadTime, setContentDownloadTime] = useState(null)
    const [broadcast, setBroadcast] = useState<Broadcast | null>(null)
    const [schedule, setSchedule] = useState({
        startDate: moment(p_.selectedDate),
        endDate: null,
        startTime: helpersSchedule.startOf(),
        endTime: helpersSchedule.endOf(),
        repeatMode: 'forever',
        repeatDays: [],
        contentDownloadDateTime: null,
        priority: 0,
        localStartTime: helpersSchedule.startOf(),
        localEndTime: helpersSchedule.endOf(),
        localStartDate: moment(p_.selectedDate),
        localEndDate: null,
        localRepeatDays: [],
        timeToUse: 'local',
        removeOverlappingSchedules: true,
    })

    useEffect(() => {
        const pathname = helpers.getPathname(p_.location.pathname)
        const query = getURLSearchParamsByLocation(p_.location)

        if (pathname === routes.addSchedule.path) {
            p_.setTitle([translate('scheduleCreate')])

            if (p_.filterList) {
                const selectedItems = getSelectedItems(p_.filterList)
                const devices = [...selectedItems.displays]
                let broadcast = null

                if (selectedItems.broadcasts.length === 1 && !helpersBroadcasts.isFolder(selectedItems.broadcasts[0])) {
                    broadcast = selectedItems.broadcasts[0]
                }

                setDevices(devices)
                setBroadcast(broadcast)
            }
        } else if (query.scheduleId) {
            p_.setTitle([translate('scheduleEdit')])
            getSchedule(query.scheduleId)
        }
    }, [])

    useEffect(() => {
        if (saved) {
            showCalendarAfterSave()
        }
    }, [saved])

    const getSelectedItems = (filterList = []) => {
        const listByType: { [index: string]: string } = helpersSchedule.getListByType()

        const selectedItems: {
            [index: string]: any[]
        } = {
            displays: [],
            broadcasts: [],
        }

        filterList.forEach((filterItem: any) => {
            const itemType = filterItem.type

            selectedItems[listByType[itemType]].push(
                createMethods[itemType](filterItem.id, filterItem.name || filterItem.title)
            )
        })

        return selectedItems
    }

    const orientationCheckWarning = (devices: Device[], broadcast: Broadcast | null): any => {
        return devices.some((device) => {
            return (
                broadcast &&
                ((device.orientation === 0 && broadcast.orientation === 90) ||
                    (device.orientation === 90 && broadcast.orientation === 0) ||
                    (device.orientation === 180 && broadcast.orientation === 90) ||
                    (device.orientation === 270 && broadcast.orientation === 0))
            )
        })
    }

    const save = (cb: any, warningConfirm?: boolean) => {
        if (orientationCheckWarning(devices, broadcast) && !warningConfirm) {
            return setOrientationWarning(true)
        } else {
            setOrientationWarning(false)
            p_.el.current.save((isValid: boolean) => {
                if (isValid) {
                    setSaved(true)
                }
                if (cb) {
                    cb()
                }
            })
        }
    }
    const showCalendarAfterSave = () => {
        const data = {
            digitalSignages: devices
                .filter((device: Device) => device.type === 'digitalSignage')
                .map((device: Device) => ({
                    id: device.id,
                    name: device.name,
                })),
            groups: devices
                .filter((device: Device) => device.type === 'group')
                .map((device: Device) => ({
                    id: device.id,
                    name: device.name,
                })),
        }

        changeLocation({
            pathname: `/${routes.schedule.path}`,
            search: getURLSearchParamsString({
                scheduleData: JSON.stringify(data),
            }),
        })
    }
    const getScheduleDataFromFilterList = (filterList: any) => {
        const scheduleData: { [index: string]: { id: number; name: string }[] } = helpersSchedule.getInitScheduleData()

        filterList.forEach((filterItem: any) => {
            scheduleData[filterItem.type].push({
                id: filterItem.id,
                name: filterItem.name,
            })
        })

        for (let type in scheduleData) {
            if (scheduleData.hasOwnProperty(type)) {
                if (scheduleData[type].length === 0) {
                    delete scheduleData[type]
                }
            }
        }

        return scheduleData
    }
    const cancel = () => {
        changeLocation({
            pathname: `/${routes.schedule.path}`,
            search: getURLSearchParamsString({
                scheduleData: JSON.stringify(getScheduleDataFromFilterList(p_.filterList)),
            }),
        })
    }
    const getSchedule = (id: string) => {
        api.send('getSchedule', { id, includeAll: true }).then((data: any) => {
            let { startDate, endDate }: { startDate: any; endDate: any } =
                data.timeToUse === 'local'
                    ? helpersSchedule.getLocalStartEndDate(data)
                    : helpersSchedule.getUTCStartEndDate(data)

            if (data.timeToUse === 'utc') {
                startDate = startDate.isValid() ? moment(startDate).local() : null
                endDate = endDate.isValid() ? moment(endDate).local() : null
            }

            if (!endDate || !endDate.isValid()) {
                endDate = null
            }

            let contentDownloadDateTime: any = schedule.contentDownloadDateTime
            let contentDownloadDate: any = null
            let contentDownloadTime: any = null

            if (data.repeatMode === 'weekly') {
                if (data.timeToUse !== 'local') {
                    const start: any = moment.utc(`${data.startDate} ${data.startTime}`, 'YYYY-MM-DD HH:mm:ss')
                    data.repeatDays = helpersSchedule.revertFormatRepeatDays(data.repeatDays, start)
                } else {
                    data.repeatDays = data.localRepeatDays
                }
            }
            if (data.repeatMode === 'exact_dates') {
                data.repeatDays = data.repeatDays.map((day: any) => moment(day.endDate))
            }
            if (data.contentDownloadDateTime) {
                const date = moment.utc(data.contentDownloadDateTime).local()
                contentDownloadDateTime = date.format('YYYY-MM-DD HH:mm:ss')
                contentDownloadDate = date
                contentDownloadTime = date.format('HH:mm:ss')
            }

            const formattedData = {
                contentDownloadTime,
                contentDownloadDate,
                schedule: {
                    id: data.id,
                    startDate,
                    endDate,
                    startTime: startDate.format('HH:mm:ss'),
                    endTime: data.localEndTime ? data.localEndTime : helpersSchedule.endOf(),
                    localStartDate: startDate,
                    localEndDate: data.localEndDate,
                    localStartTime: startDate.format('HH:mm:ss'),
                    localEndTime: data.localEndTime,
                    repeatMode: data.repeatMode,
                    repeatDays: data.repeatDays,
                    contentDownloadDateTime,
                    localRepeatDays: data.repeatDays,
                    localContentDownloadDateTime: contentDownloadDateTime,
                    timeToUse: data.timeToUse || 'local',
                    priority: data.priority,
                    removeOverlappingSchedules: true,
                },
            }

            setContentDownloadTime(formattedData.contentDownloadTime)
            setContentDownloadDate(formattedData.contentDownloadDate)
            setSchedule(formattedData.schedule)

            const broadcastTitle = data.broadcast ? data.broadcast.title : ''

            setBroadcast({ id: data.broadcastId, title: broadcastTitle })
            setTimeout(() => {
                if (data.broadcast && data.broadcast.folderId) {
                    changeFilter({ folderId: data.broadcast.folderId })
                }
            }, 200)
            api.send('getDisplaysFromGroups', {
                digitalSignageId: data.digitalSignageId.map((listItem: any) => {
                    return listItem.digitalSignageId
                }),
            }).then((devices: any) => {
                setDevices(
                    devices.map((device: Device) => {
                        device.type = 'digitalSignage'

                        return device
                    })
                )
            })
        })
    }

    return {
        devices,
        setDevices,
        conflicts,
        save,
        cancel,
        broadcast,
        setBroadcast,
        advDuration: 0,
        advContentLength: 0,
        schedule,
        setSchedule,
        showCalendarAfterSave,
        contentDownloadDate,
        contentDownloadTime,
        setContentDownloadDate,
        setContentDownloadTime,
        orientationWarning,
        setOrientationWarning,
    }
}

export default ScheduleAddMethods
