import React, { FC, Fragment, memo, useContext } from 'react'
import { cn } from 'ethcss'
import translate from 'core/translate'
import { IReferenceBookListProps } from './ReferenceBookList-types'
import { styles } from './ReferenceBookList-styles'
import { CatalogWithPaginationContext } from 'organisms/CatalogWithPagination/CatalogWithPagination-context'
import { Table, TableHeaderCell } from 'atoms/Table'
import { ICatalogWithPaginationOptionsReferenceBook } from 'organisms/CatalogWithPagination/CatalogWithPagination-types'
import { ReferenceBookListItem } from './ReferenceBookListItem'
import { TableRow } from 'atoms/Table/TableRow'

const ReferenceBookListComponent: FC<IReferenceBookListProps> = ({ list, minimize }) => {
    const DEFAULT_TABLE_CONTENT_WIDTH_PERCENTAGE = 90
    const { common, catalogState } = useContext(CatalogWithPaginationContext)
    const { referenceBook: options, isEnableMultipleSelect = true, fields } = common
    const { isCreateItemInit } = catalogState
    const {
        referenceBookListClassName,
        referenceBookListTableClassName,
        isEnableFixedColumnWidth = true,
        headerComponent,
        createItemComponent,
    } = options as ICatalogWithPaginationOptionsReferenceBook

    const getListClassName = () => {
        if (!referenceBookListClassName) return styles.referenceBookList

        return minimize ? referenceBookListClassName.minimize : referenceBookListClassName.desktop
    }

    const getListTableClassName = () => {
        if (!referenceBookListTableClassName) return styles.referenceBookList__table

        return minimize ? referenceBookListTableClassName.minimize : referenceBookListTableClassName.desktop
    }

    const getListTableColumnWidth = () => {
        return isEnableFixedColumnWidth ? { width: `${DEFAULT_TABLE_CONTENT_WIDTH_PERCENTAGE / fields.length}%` } : {}
    }

    const renderSelectCell = () => <TableHeaderCell>{translate('choice')}</TableHeaderCell>

    const renderHeaderCells = () => {
        return fields.map((option, index) => (
            <TableHeaderCell key={`catalogListHeader_${index}`} style={getListTableColumnWidth()}>
                {option.name}
            </TableHeaderCell>
        ))
    }

    const renderList = () => {
        return list.map((listItem, index) => {
            return <ReferenceBookListItem key={listItem.id} listItem={listItem} minimize={minimize} />
        })
    }

    const renderHeader = () => {
        const Header = headerComponent

        return <Header />
    }

    const renderCreateItemComponent = () => {
        if (!isCreateItemInit || !createItemComponent) return null

        const CreateItemComponent = createItemComponent
        return (
            <TableRow>
                <CreateItemComponent />
            </TableRow>
        )
    }

    return (
        <div className={cn(getListClassName())}>
            <div className={styles.referenceBookList__content}>
                <div className={styles.referenceBookList__header}>{renderHeader()}</div>
                <div className={styles.referenceBookList__wrapper}>
                    <Table className={cn(getListTableClassName())}>
                        <thead>
                            <TableRow>
                                <Fragment>
                                    {isEnableMultipleSelect && renderSelectCell()}
                                    {renderHeaderCells()}
                                </Fragment>
                            </TableRow>
                        </thead>
                        <tbody>
                            {renderCreateItemComponent()}
                            {renderList()}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}

export const ReferenceBookList = memo(ReferenceBookListComponent)
