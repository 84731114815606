import grid from 'blocks.simple/grid/grid.jcss'
import { addStyles } from 'ethcss'
import { IStyles } from 'theme'
import { indents, radiusSizes, textSizes } from 'theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'

export const confirmEmailDialogStyles: IStyles = {
    confirmEmailDialog__wrapper: {
        ...grid.row,
        ...grid.center,
        ...grid.normalCenter,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        '& *': {
            fontFamily: `var(--var-module-app-component-app-style-fontFamily, ${defaultThemeStyles.fontFamily})!important`,
        },
    },
    confirmEmailDialog: {
        position: 'relative',
        ...grid.flex,
        ...grid.col,
        ...grid.center,
        width: 347,
        padding: `64px ${indents.miniPlus}px 90px`,
        borderRadius: radiusSizes.mini,
        backgroundColor: `var(--var-module-app-component-app-style-panel, ${defaultThemeStyles.panel})`,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    confirmEmailDialog__close: {
        position: 'absolute',
        top: 7,
        right: 10,
    },
    confirmEmailDialog__icons: {
        textAlign: 'center',
        paddingBottom: indents.md,
    },
    confirmEmailDialog__body: {
        textAlign: 'center',
    },
    confirmEmailDialog__content: {
        ...grid.flex,
        ...grid.row,
        ...grid.center,
        textAlign: 'center',
    },
    confirmEmailDialog__title: {
        paddingBottom: indents.md,
        fontSize: textSizes.md,
        fontWeight: 'bold',
    },
    confirmEmailDialog__text: {
        fontSize: textSizes.normal,
        fontWeight: 'bold',
        lineHeight: 1.4,
    },
}

addStyles(confirmEmailDialogStyles)
