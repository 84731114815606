import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { defaultThemeStyles } from '../Searchbar-theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { IStyles } from '../../../theme'

const searchbarItemStyles: IStyles = {
    wrapper: {
        ...grid.rowCenter,
        ...grid.pt_mini,
        ...grid.pb_mini,
        cursor: 'pointer',
        borderBottom: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        paddingLeft: grid.pl_mdPlus['padding-left'] * 2,
    },
    icon: {
        backgroundColor: `var(--var-module-ui-component-searchbar-style-iconBackground, ${defaultThemeStyles.iconBackground})`,
    },
}

addStyles(searchbarItemStyles)
export default searchbarItemStyles
