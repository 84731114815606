import { addStyles } from 'ethcss'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { IStyles, mediaCSS, grid, item } from '../../../theme'

const dashboardUnusedContentStyles: IStyles = {
    modal: {
        ...grid.w50,
        [mediaCSS.tab]: {
            ...grid.w60,
        },
        [mediaCSS.tabMini]: {
            ...grid.w70,
        },
        [mediaCSS.mob]: {
            ...grid.w90,
        },
        ...grid.p_none,
    },
    wrapper: {
        ...grid.w100,
        ...grid.mt_md,
        ...grid.mb_md,
    },
    controls: {
        ...grid.row,
        ...grid.p_mini,
    },
    icon: {
        ...grid.mr_mini,
    },
    name: {
        maxWidth: 350,
        overflow: 'hidden',
    },
    tableWrapper: {
        border: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
        ...item.overAuto,
        maxHeight: 600,
    },
    table: {
        ...item.tableWrapper,
    },
    emptyText: {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        ...grid.mt_md,
        ...grid.mb_md,
    },
    colIconCenter: {
        textAlign: 'center',
    },
    colCheckboxCenter: {
        justifyContent: 'center',
    },
}

addStyles(dashboardUnusedContentStyles)
export default dashboardUnusedContentStyles
