import React, { FunctionComponent } from 'react'
import { Icon } from 'molecules/Icon'

import { IAvatarComponent } from './Avatar-types'

import { cn } from 'ethcss'
import { avatarStyles } from './Avatar-styles'

export const Avatar: FunctionComponent<IAvatarComponent> = ({ src, size = 40, className, icon }) => {
    if (!src) {
        return <Icon type="user_rounded" size={size} {...icon} />
    }

    return (
        <div
            className={cn(avatarStyles.wrapper, className)}
            style={{
                width: size,
                paddingTop: size,
            }}
        >
            <div
                className={avatarStyles.image}
                style={{
                    backgroundImage: `url(${src})`,
                }}
            />
        </div>
    )
}
