import { addStyles } from 'ethcss'
import { grid, IStyles, item } from 'theme'
import { defaultThemeStyles } from '../../molecules/DownloadModal/DownloadProgressModal.theme'
import catalogTemplateInstancesCatalogStyles from '../../../../../../blocks.app/catalog/__template/_catalogizator/arts/catalog__template_arts.jcss'

export const styles: IStyles = {
    downloadItem: {
        padding: '10px 0 10px 32px',
        listStyle: 'none',
    },
    downloadItemHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    downloadItemLeft: {
        display: 'flex',
        maxWidth: '44%',
    },
    downloadItemRight: {
        display: 'flex',
    },
    icon: {
        marginRight: '20px',
    },
    downloadName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    status: {
        width: '70px',
        textAlign: 'center',
    },
    errorMessage: {
        color: 'red',
    },

    // downloadItem__title: {
    //     ...grid.w100,
    //     ...grid.p_mini,
    //     ...grid.pl_mdPlus,
    //     ...grid.pr_mdPlus,
    //     ...grid.row,
    //     ...grid.normalCenter,
    //     ...grid.noWrap,
    //     position: 'relative',
    //     color: `var(--var-module-app-component-downloads-style-title, ${defaultThemeStyles.title})`,
    //     borderBottom: `1px solid var(--var-module-app-component-downloads-style-border, ${defaultThemeStyles.border})`,
    // },
    // downloadItem__title_type_collapse: {
    //     padding: `0 !important`,
    // },
    // downloadItem__title_completed: {
    //     color: `var(--var-module-app-component-downloads-style-titleComplete, ${defaultThemeStyles.titleComplete})`,
    // },
    // downloadItem__icon_type_remove: {
    //     padding: 5,
    //     position: 'absolute',
    //     right: 3,
    //     top: `50%`,
    //     marginTop: -13,
    //     width: 26,
    //     height: 26,
    //     transition: '0.15s opacity ease-in-out',
    // },
    // downloadItem__nameWrapper: {
    //     ...grid.spaceWidth,
    //     ...grid.pr_mini,
    //     ...grid.row,
    //     ...grid.normalCenter,
    //     ...grid.noWrap,
    // },
    // downloadItem__name: {
    //     ...item.ellipsis,
    // },
    // downloadItem__progressWrapper: {
    //     ...grid.row,
    //     ...grid.noWrap,
    // },
    // downloadItem__progressWrapper_type_complete: {
    //     borderRadius: '50%',
    //     backgroundColor: `var(--var-module-app-component-downloads-style-progressComplete, ${defaultThemeStyles.progressComplete})`,
    // },
    // downloadItem__progressWrapper_type_over: {
    //     '&:hover': {
    //         borderRadius: '50%',
    //         backgroundColor: `var(--var-module-app-component-downloads-style-progressOver, ${defaultThemeStyles.progressOver})`,
    //     },
    // },
    // downloadItem__progress: {
    //     width: 20,
    //     height: 20,
    //     position: 'relative',
    //     ...grid.rowCenter,
    // },
    // downloadItem__icon_type_complete: {
    //     ...item.block,
    // },
    // downloadItem__icon_type_cancel: {
    //     position: 'absolute',
    // },
}

addStyles(styles)
