import { addStyles } from 'ethcss'
import { grid, item, IStyles } from 'theme'
import { layers, transitions } from 'blocks.app/config'
import 'blocks.simple/animation/animation.css'
import { textSizes } from 'theme/media'
import { colors } from 'theme'

const dropContentStyles: IStyles = {
    _global: {
        '.tether-element': {
            zIndex: layers.drop,
        },
    },
    dropContent: {
        ...item.shadowButton,
        fontSize: textSizes.normal,
        color: colors.darkCloud,
        textAlign: 'center',
        ...item.rel,
        ...item.white,
        maxWidth: 400,
    },
    bottomDropContent: {
        ...grid.mb_mini,
    },
    topDropContent: {
        ...grid.mt_mini,
    },
    iconDrop: {
        ...item.abs,
        ...grid.row,
        ...grid.center,
        ...grid.fullWidth,
        left: 0,
    },
    fadeAnimationStart: {
        animation: `fadeIn ${transitions.normal}ms ease-in`,
    },
    fadeAnimationEnd: {
        animation: `fadeOut ${transitions.normal}ms ease-in`,
    },
    moveLeftAnimationStart: {
        animation: `moveFromLeftDropContent ${transitions.normal}ms ease-in`,
    },
    moveLeftAnimationEnd: {
        animation: `moveToLeftDropContent ${transitions.normal}ms ease-in`,
    },
}

addStyles(dropContentStyles)
export default dropContentStyles
