import { addStyles } from 'ethcss'
import { mediaCSS } from 'theme/media'
import { IStyles, grid } from '../../../theme'

const previewAndHistoryModals: IStyles = {
    modal: {
        ...grid.w50,
        [mediaCSS.tab]: {
            ...grid.w60,
        },
        [mediaCSS.tabMini]: {
            ...grid.w70,
        },
        [mediaCSS.mob]: {
            ...grid.w90,
        },
        ...grid.p_none,
    },
    wrapper: {
        ...grid.w100,
        ...grid.mt_md,
        ...grid.mb_md,
    },
    controls: {
        ...grid.row,
        ...grid.p_mini,
        ...grid.center,
        ...grid.normalCenter,
    },
}

addStyles(previewAndHistoryModals)
export default previewAndHistoryModals
