import { Radio } from 'atoms/Radio'
import translate from 'core/translate'
import { isExist } from 'core/utils/coreUtil'
import React, { FC } from 'react'
import { styles } from './DeviceSettingsDownloadMode-styles'
import { IDeviceSettingsDownloadModeProps } from './DeviceSettingsDownloadMode-types'

export const DeviceSettingsDownloadMode: FC<IDeviceSettingsDownloadModeProps> = ({
    active,
    title,
    onChange,
    enabled,
}) => {
    const isDownloadModeExist = () => isExist(active)

    let changeDownloadMode = (downloadMode: string) => {
        onChange(downloadMode, 'contentDownloadType')
    }

    if (!enabled || !isDownloadModeExist()) {
        return null
    }

    return (
        <div className={styles.DeviceSettingsDownloadMode}>
            <div className={styles.DeviceSettingsDownloadMode__title}>{title}</div>
            <div className={styles.DeviceSettingsDownloadMode__buttons}>
                <Radio
                    className={styles.DeviceSettingsDownloadMode__button}
                    onClick={() => changeDownloadMode('server')}
                    selected={active === 'server'}
                    label={translate('serverDownloadMode')}
                />
                <Radio
                    onClick={() => changeDownloadMode('deviceServer')}
                    selected={active === 'deviceServer'}
                    label={translate('deviceServerDownloadMode')}
                />
            </div>
        </div>
    )
}
