import { addStyles } from 'ethcss'
import { grid, textSizes } from 'theme'
import item from 'blocks.simple/item/item.jcss'
import { mediaCSS } from 'theme/media'
import { indents } from '../../../blocks.app/config'

const settingsEmailsStyles = {
    emails: {
        ...grid.p_mini,
        ...grid.pt_none,
        ...grid.pl_none,
        ...grid.row,
        ...grid.normalCenter,
    },
    email(): {} {
        return {
            ...grid.row,
            ...grid.normalCenter,
            ...grid.col4,
            [mediaCSS.tabMini]: {
                ...grid.w100,
            },
            ...grid.pr_md,
            ...grid.mb_md,
            [`&:hover .${this.delete}`]: {
                ...item.visible,
            },
        }
    },
    delete: {
        ...grid.pl_mini,
        ...item.hidden,
    },
    modalWrapper: {
        ...grid.full,
        ...grid.rowCenter,
        ...item.blackOpacity,
    },
    modalInner: {
        ...grid.colCenter,
        ...item.card,
        ...item.shadowModal,
        ...item.rounded_full_mini,
        ...item.rel,
    },
    closeIcon: {
        ...item.abs,
        right: indents.mini,
        top: indents.mini,
    },
    title: {
        ...grid.mt_md,
        fontSize: textSizes.normal,
        textTransform: 'uppercase',
    },
    description: {
        ...grid.mt_mdPlus,
        ...grid.mb_md,
        textAlign: 'center',
        lineHeight: 1.3,
        wordBreak: 'break-word',
    },
    buttons: {
        ...grid.row,
        ...grid.mt_md,
    },
    leftButton: {
        ...grid.mr_mini,
    },
}

addStyles(settingsEmailsStyles)
export default settingsEmailsStyles
