import React, { useEffect } from 'react'
import { connect } from 'react-redux'

// TODO: Использовать вместо Checkbox -> Switch,
// Заменить старые компоненты на новые (инпут, кнопки)
import Checkbox from 'blocks.simple/checkbox/checkbox'
import { Radio } from 'atoms/Radio'
import EditText from 'blocks.simple/editText/editText'

import translate from 'core/translate'
import { useAdvancedSearch } from './AdvancedSearch-hooks'

import { AdvancedSearchOperationsEnum } from 'core/models/AdvancedSearch'
import { AdvancedSearchComponentInterface } from './AdvancedSearch-types'

import { advancedSearchStyles } from './AdvancedSearch-styles'
import { Typography } from 'atoms/Typography'
import { cn } from 'ethcss'
import { SwitchButton } from '../../atoms/SwitchButton'

const AdvancedSearchComponent: React.FC<AdvancedSearchComponentInterface> = ({
    type,
    advancedSearchSettings,
    onApply,
    onReset,
    onChange,
    setSearchReset,
    searchReset,
    onChangeADSearch,
}) => {
    const {
        parameters,
        changeSearchExactlyParameter,
        changeSearchTermParameter,
        resetSearchParameters,
        applySearchParameters,
        useADSearch,
        setUseADSearch,
        changeEnableQParameter,
    } = useAdvancedSearch({
        type,
        advancedSearchSettings,
        onApply,
        onReset,
        onChange,
    })

    useEffect(() => {
        if (searchReset) {
            resetSearchParameters()
            setSearchReset(false)
        }
    }, [searchReset])

    return (
        <div className={advancedSearchStyles.container}>
            <div className={advancedSearchStyles.parameterWrapper}>
                <Typography type="text" className={advancedSearchStyles.title}>
                    {translate('advancedSearch')}
                </Typography>
                <div className={advancedSearchStyles.actionsWrapper}>
                    <div className={advancedSearchStyles.action}>
                        <SwitchButton
                            onChange={changeEnableQParameter(!parameters.enabledQ)}
                            checked={parameters.enabledQ}
                        />
                    </div>
                    <div className={advancedSearchStyles.actionDesc}>
                        <Typography
                            type="text"
                            className={cn(advancedSearchStyles.description, advancedSearchStyles.text)}
                        >
                            {translate('advancedSearchTitle')}
                        </Typography>
                    </div>
                </div>
            </div>
            {parameters.enabledQ && (
                <>
                    <div className={advancedSearchStyles.parameterWrapper}>
                        <Typography type="text" className={advancedSearchStyles.title}>
                            {translate('separator')}
                        </Typography>
                        <div className={advancedSearchStyles.actionsWrapper}>
                            <div className={advancedSearchStyles.action}>
                                <Checkbox
                                    checked={parameters.exactly.activeQ}
                                    onClick={changeSearchExactlyParameter('activeQ')}
                                />
                                <div className={advancedSearchStyles.parameterInput}>
                                    <EditText
                                        value={parameters.exactly.separator}
                                        indentSize="mini"
                                        rounded="full_micro"
                                        onChange={changeSearchExactlyParameter('separator')}
                                        maxLength={1}
                                        disabled={!parameters.exactly.activeQ}
                                    />
                                </div>
                            </div>
                            <div className={advancedSearchStyles.actionDesc}>
                                <Typography type="text" className={advancedSearchStyles.text}>
                                    {translate('separatorForQuotes')}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className={advancedSearchStyles.parameterWrapper}>
                        <Typography type="text" className={advancedSearchStyles.title}>
                            {translate('searchTerms')}
                        </Typography>
                        <div className={advancedSearchStyles.actionsWrapper}>
                            <div className={cn(advancedSearchStyles.action, advancedSearchStyles.block)}>
                                <div className={advancedSearchStyles.radioWrapper}>
                                    <Radio
                                        selected={parameters.operator === AdvancedSearchOperationsEnum.OR}
                                        onClick={changeSearchTermParameter(AdvancedSearchOperationsEnum.OR)}
                                    />
                                    <div className={cn(advancedSearchStyles.radioTitle, advancedSearchStyles.textMob)}>
                                        {translate('or')}
                                    </div>
                                </div>
                                <div className={advancedSearchStyles.radioWrapper}>
                                    <Radio
                                        selected={parameters.operator === AdvancedSearchOperationsEnum.AND}
                                        onClick={changeSearchTermParameter(AdvancedSearchOperationsEnum.AND)}
                                    />
                                    <div className={cn(advancedSearchStyles.radioTitle, advancedSearchStyles.textMob)}>
                                        {translate('and')}
                                    </div>
                                </div>
                            </div>
                            <div className={advancedSearchStyles.actionDesc}>
                                <Typography type="text" className={advancedSearchStyles.text}>
                                    {translate('searchTermsDescription')}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export const AdvancedSearch = connect((state: any) => ({
    advancedSearchSettings: state.user.data.settings.advancedSearch,
}))(AdvancedSearchComponent)
