import React, { useEffect, useState } from 'react'
import { api } from 'core/api/ConnectionManager'
import deepEqual from 'fast-deep-equal'
import debounce from 'lodash/debounce'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import helpers from 'core/helpers'

const API_CALL_DELAY: number = 1000

const CatalogDisplaysMapMethods = (p_: any) => {
    const [distance, setDistance] = useState<number>(20000)

    useEffect(() => {
        const query = helpers.getQuery(getURLSearchParamsByLocation(p_.location), 'displays')

        if (p_.userLocation && !p_.center && !query.query) {
            getDisplaysByDistance(p_.userLocation, distance)
        }
        p_.onGetList([])
    }, [])
    useEffect(() => {
        let filter = getFilter(p_)
        let prevFilter = getFilter(p_)
        const filterDiff = !deepEqual(prevFilter, filter)

        if (filterDiff) {
            getDisplaysByDistance(p_.center, distance, 'update', filter)
        }
    }, [p_])

    const getFilter = (p_: any) => {
        const query = helpers.getQuery(getURLSearchParamsByLocation(p_.location), 'displays')
        const filter = { ...query }
        let filtered: any = null

        if (p_.options) {
            p_.options.forEach((field: any) => {
                if (query[field.id]) {
                    filtered = getFilterField(filter, query, field)
                }
            })
        }

        let filterState = {}
        if (filtered) {
            p_.options.forEach((filterItem: any) => {
                if (filtered[filterItem.id]) {
                    // @ts-ignore
                    filterState[filterItem.id] = filtered[filterItem.id]
                }
            })
        }
        return filterState
    }

    const getFilterField = (filter: any, query: { [index: string]: string }, field: { id: string }) => {
        const tags = p_.tags
        filter[field.id] = query[field.id].split('//')

        if (field.id === 'tags') {
            filter[field.id].forEach((fieldItem: string, index: number) => {
                const currentTag = tags.find((tag: { name: string; id: string }) => tag.name === fieldItem)

                if (currentTag) {
                    filter[field.id][index] = currentTag.id
                }
            })
        }

        return filter
    }

    const getDisplaysByDistance = (
        location: any,
        distance: number,
        type?: string,
        filter?: { [index: string]: string }
    ) => {
        getDisplaysByDistanceDebounce(location, distance, type, filter)
    }

    const getDisplaysByDistanceDebounce = debounce((location, distance, type, filter) => {
        let list = []
        let query = filter

        api.send<object, { id: number }[]>('getDisplays', { ...query, ...location, distance }).then((newList) => {
            console.log(newList)
            if (type === 'update') {
                const filtered = newList.filter((item) => !p_.list.includes(item.id))
                list = [...filtered]
            } else {
                list = newList
            }

            p_.onGetList(list)
        })
    }, API_CALL_DELAY)

    const onChange = (center: { lat: number; lng: number }, distance: number) => {
        if (p_.userLocation) {
            setDistance(distance)
            setTimeout(() => {
                getDisplaysByDistance(center, distance, 'update', getFilter(p_))
            }, 200)
        }
    }

    return {
        onChange,
    }
}

export default CatalogDisplaysMapMethods
