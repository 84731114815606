import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { indents } from 'blocks.app/config'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { IStyles, item, mediaCSS } from '../../../../theme'

const filesFilterStyles: IStyles = {
    wrapper: {
        ...grid.w100,
        ...grid.mt_md,

        [mediaCSS.tabMini]: {
            marginTop: 66,
        },
    },
    wrapperMob: {
        [mediaCSS.tabMini]: {
            marginTop: 0,
        },
    },
    content: {
        ...grid.row,
        backgroundColor: `var(--var-module-app-component-app-style-panel, ${defaultThemeStyles.panel})`,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
        borderLeft: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
        overflow: 'hidden',
        borderRadius: '5px 5px 0 0',
    },
    item: {
        ...grid.space,
        ...grid.rowCenter,
        ...grid.noWrap,
        ...grid.p_mini,
        cursor: 'pointer',
        backgroundColor: `var(--var-module-app-component-app-style-panel, ${defaultThemeStyles.panel})`,
        textDecoration: 'none',
        color: `var(--var-module-app-component-app-style-placeholder, ${defaultThemeStyles.placeholder})`,
        border: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
        borderBottom: 'none',
        borderLeft: 'none',
    },
    itemActive: {
        color: `var(--var-module-app-component-app-style-active, ${defaultThemeStyles.active})!important`,
        backgroundColor: `var(--var-module-app-component-app-style-panel, ${defaultThemeStyles.panel})!important`,
        fontWeight: 'bold',
    },
    spanName: {
        [mediaCSS.mob]: {
            maxWidth: '64px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
    },
    activateEditorButton: {
        ...grid.pt_mdPlus,
        ...grid.rowCenter,
        ...grid.pb_mdPlus,
        backgroundColor: `var(--var-module-app-component-app-style-panel, ${defaultThemeStyles.panel})`,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    icon: {
        marginRight: indents.micro,
    },
    editors: {
        ...grid.space,
        overflow: 'auto',
        position: 'relative',
    },
    addFolder: {
        ...grid.row,
        display: 'block',
        textAlign: 'right',
        lineHeight: 1.2,
        marginTop: '12px',
        fontSize: '12px',
    },
    addWidget: {
        ...grid.row,
        display: 'block',
        textAlign: 'right',
        lineHeight: 1.2,
        marginTop: '12px',
        fontSize: '12px',
        marginLeft: 14,
    },
    modalContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'black',
        zIndex: 10000,
    },
    modalWrapper: {
        ...grid.full,
        ...grid.rowCenter,
    },
    modalInner: {
        padding: 34,

        [mediaCSS.tab]: {
            padding: '34px 10px',
        },
    },
    modalClassName: {
        overflow: 'auto',
    },
    addNew: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 14,
    },
}

addStyles(filesFilterStyles)
export default filesFilterStyles
