import React, { useState } from 'react'
// @ts-ignore
import Datepicker from 'react-datepicker'
import moment from 'moment/moment'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './datepickerSingle.jcss'

export const DatePickerSingle = ({
    placeholder,
    value,
    dateFormat,
    onChangeFormat,
    onChange,
    id,
}: {
    id?: number
    placeholder: string
    value: string
    dateFormat: string
    onChangeFormat: string
    onChange: (date: string, cb?: any) => void
}) => {
    const [dateValue, setDateValue] = useState(value)

    return (
        <div className={styles.datepickerWrapper}>
            <Datepicker
                placeholderText={placeholder}
                selected={
                    dateValue !== null
                        ? moment(dateValue).isValid()
                            ? moment(dateValue).toDate()
                            : undefined
                        : undefined
                }
                dateFormat={dateFormat}
                showYearDropdown
                scrollableMonthYearDropdown
                onChange={(date: string) => {
                    setDateValue(date)
                    onChange(moment(date).format(onChangeFormat ? onChangeFormat : 'DD-MM-YYYY'))
                }}
            />
        </div>
    )
}
