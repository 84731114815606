import { addStyles } from 'ethcss'
import { grid, IStyles, mediaCSS } from 'theme'

export const audioPlaygroundStyles: IStyles = {
    AudioPlayground: {
        ...grid.p_mdPlus,
        [mediaCSS.tab]: {
            ...grid.p_mini,
            maxHeight: '100vh',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '96vh',
        },
    },
    AudioPlayground__image: {
        ...grid.flex,
        ...grid.center,
    },
    AudioPlayground__soundtrack: {
        paddingTop: 70,
        [mediaCSS.tab]: {
            paddingTop: 30,
        },
    },
}

addStyles(audioPlaygroundStyles)
