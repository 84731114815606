import React, { FC, useState } from 'react'
import { ProgressBar } from 'atoms/ProgressBar'
import { grid } from 'theme'
import Collapse from 'blocks.simple/collapse/collapse'
import { Slider } from 'molecules/Slider'
import { IDownloadBroadcast, IDownloadDevice, IDownloadFile, TDownloadType } from 'core/models/Downloads'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from '../../molecules/DownloadModal/DownloadModal.theme'
import { styles } from './DownloadItem-styles'
import Icon from 'blocks.simple/icon/icon'
import { cn } from 'ethcss'
import translate from 'core/translate'
import { IDownloadProps } from './DownloadItem-types'

const icons = {
    advertisingCampagin: 'megaphone',
    advertisingBlock: 'megaphone',
    broadcast: 'megaphone',
    file: 'file',
    undefined: 'file',
    digitalSignage: 'display',
    sourceFromUrl: 'file',
}

export const DownloadItem: FC<IDownloadProps> = ({ cancelDownload, cancelDownloadWithoutMessage, download }) => {
    const SLIDER_CHILD_MIN_LENGTH = 5
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isShowRemoveButton, setIsShowRemoveButton] = useState<boolean>(false)
    const [isShowCancelButton, setIsShowCancelButton] = useState<boolean>(false)
    const isComplete = Math.floor(download.progress) === 100

    const getChild = () => {
        if (!isDownloadChildrenExist()) return null

        const downloadChildren = download.children as unknown[]

        return downloadChildren.map((child, index) => {
            const downloadChild = child as IDownloadDevice | IDownloadFile

            return (
                <DownloadItem
                    download={downloadChild}
                    cancelDownload={cancelDownload}
                    cancelDownloadWithoutMessage={cancelDownloadWithoutMessage}
                    key={`download_${downloadChild.type}_${downloadChild.id}_${index}`}
                />
            )
        })
    }

    const getChildList = () => {
        const downloadWithChildren = download as IDownloadBroadcast | IDownloadDevice

        return isShowSlider(downloadWithChildren.children) ? getSlider() : getChild()
    }

    const isShowSlider = (downloadChildren: IDownloadFile[] | IDownloadDevice[]) => {
        return downloadChildren && downloadChildren.length > SLIDER_CHILD_MIN_LENGTH
    }

    const getSlider = () => {
        return (
            <Slider count={SLIDER_CHILD_MIN_LENGTH} template={'col'}>
                {getChild()}
            </Slider>
        )
    }

    const removeFromDownload = (e: React.MouseEvent) => {
        e.stopPropagation()
        const currentDownload = download as IDownloadBroadcast | IDownloadDevice | IDownloadFile
        cancelDownloadWithoutMessage(currentDownload)
    }

    const handleToggle = (isOpenCollapse: boolean) => {
        setIsOpen(isOpenCollapse)
    }

    const isShowChildren = () => {
        return isOpen && isDownloadChildrenExist()
    }

    const isDownloadChildrenExist = () => {
        return download.children
    }

    const isDownloadСanBeCanceled = () => {
        return download.cancel
    }

    const handleProgressWrapperClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        if (isSourceFromUrlDownload(download.type)) return

        cancelDownload(download as IDownloadFile)
    }

    const isSourceFromUrlDownload = (type: TDownloadType) => {
        return type === 'sourceFromUrl'
    }

    const handleTitleHover = () => {
        setIsShowRemoveButton(true)
    }

    const handleTitleRemoveHover = () => {
        setIsShowRemoveButton(false)
    }

    const handleProgressHover = () => {
        setIsShowCancelButton(true)
    }

    const handleProgressRemoveHover = () => {
        setIsShowCancelButton(false)
    }

    const renderProgressContent = () => {
        return isShowCancelButton && isDownloadСanBeCanceled() ? (
            <div className={styles.downloadItem__icon_type_cancel}>
                <Icon type={'close'} color={'white'} size={8} tooltip={{ title: translate('cancelDownload') }} />
            </div>
        ) : (
            <ProgressBar value={download.progress} strokeWidth={17} />
        )
    }

    const renderRemoveButton = () => {
        return isShowRemoveButton && isComplete ? (
            <div className={styles.downloadItem__icon_type_remove} onClick={removeFromDownload}>
                <Icon
                    tooltip={{ title: translate('removeLine') }}
                    type={'close'}
                    color={getThemeStyleValue('app', 'downloads', 'title') || defaultThemeStyles.title}
                />
            </div>
        ) : (
            <div className={styles.downloadItem__icon_type_remove}></div>
        )
    }

    return (
        <Collapse
            onToggle={handleToggle}
            contentClassName={cn(grid.pl_md)}
            contentPadding={false}
            disabled={!download.children}
            showArrow={false}
            titleClassName={cn(styles.downloadItem__title_type_collapse)}
            title={({ open }: { open: boolean }) => (
                <div
                    className={cn(styles.downloadItem__title, {
                        [styles.downloadItem__title_completed]: isComplete,
                    })}
                    onMouseEnter={handleTitleHover}
                    onMouseLeave={handleTitleRemoveHover}
                >
                    <div className={styles.downloadItem__nameWrapper}>
                        <Icon
                            containerClass={grid.mr_mini}
                            type={icons[download.type]}
                            color={
                                isComplete
                                    ? getThemeStyleValue('app', 'downloads', 'active') || defaultThemeStyles.active
                                    : getThemeStyleValue('app', 'downloads', 'disable') || defaultThemeStyles.disable
                            }
                            size={18}
                        />
                        <div className={styles.downloadItem__name}>{download.name}</div>
                        {download.children && (
                            <Icon
                                containerClass={grid.ml_micro}
                                type={open ? 'arrow_up' : 'arrow_down'}
                                color={
                                    isComplete
                                        ? getThemeStyleValue('app', 'downloads', 'active') || defaultThemeStyles.active
                                        : getThemeStyleValue('app', 'downloads', 'disable') ||
                                          defaultThemeStyles.disable
                                }
                                size={10}
                            />
                        )}
                    </div>
                    <div className={styles.downloadItem__progressWrapper}>
                        <div className={cn(styles.name, grid.pr_mini)}>{download.progress} %</div>
                        <div
                            className={cn({
                                [styles.downloadItem__progressWrapper_type_over]:
                                    isDownloadСanBeCanceled() && !isComplete,
                                [styles.downloadItem__progressWrapper_type_complete]: isComplete,
                            })}
                            onClick={handleProgressWrapperClick}
                            onMouseEnter={handleProgressHover}
                            onMouseLeave={handleProgressRemoveHover}
                        >
                            <div className={styles.downloadItem__progress}>
                                {!isComplete ? (
                                    renderProgressContent()
                                ) : (
                                    <div className={styles.downloadItem__icon_type_complete}>
                                        <Icon type={'checked'} color={'white'} size={9} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {renderRemoveButton()}
                </div>
            )}
        >
            {isShowChildren() && getChildList()}
        </Collapse>
    )
}
