import { addStyles } from 'ethcss'
import { IStyles, grid, item } from 'theme'
import { defaultThemeStyles as defaultThemeSidebarStyles } from 'blocks.app/sidebar/Sidebar-theme'

export const scheduleItemStyles: IStyles = {
    timeTitleContainer: {
        textAlign: 'center',
        fontSize: 16,
        ...grid.mb_md,
        ...grid.w100,
        color: `var(--var-module-sidebar-component-sidebar-style-text, ${defaultThemeSidebarStyles.text})`,
    },
    timeTitleContainerMarginBottomNormal: {
        ...grid.mb_md,
    },
    confirmTitle: {
        ...grid.mb_big,
        marginTop: 2,
        textAlign: 'center',
        lineHeight: 1.6,
    },
    controlsContainer: {
        ...grid.w100,
        ...grid.rowCenter,
        ...grid.noWrap,
    },
    controlsLeftContainer: {
        ...grid.pl_mini,
        ...grid.pr_mini,
    },
    controlsButtonsContainer: {
        ...grid.rowCenter,
        ...grid.noWrap,
    },
    scheduleItem: {
        ...grid.p_md,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
        cursor: 'pointer',
        borderBottom: `1px solid var(--var-module-sidebar-component-sidebar-style-border, ${defaultThemeSidebarStyles.border})`,
    },
    broadcastTitle: {
        textAlign: 'center',
        lineHeight: 1.5,
    },
    buttonContainer: {
        ...grid.col2,
        ...grid.pl_mini,
        ...grid.pr_micro,
    },
    buttonContainerCenterItem: {
        ...grid.col2,
        ...grid.pl_mini,
        ...grid.pr_mini,
    },
    button: {},
    buttonContent: {},
    buttonMarginRightMicro: {
        ...grid.mr_micro,
    },
    buttonTypeLink: {},
    timeTitle: {
        ...grid.fullWidth,
        textAlign: 'center',
        ...grid.pb_micro,
        'white-space': 'nowrap',
        ...grid.mb_mini,
    },
    selected: {
        ...grid.p_miniPlus,
        ...grid.colCenter,
        ...item.shadowButton,
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-itemSelected, ${defaultThemeSidebarStyles.itemSelected})`,
    },
    dotdotdotContainer: {
        ...grid.fullWidth,
    },
    link: {
        ...grid.pb_mdPlus,
        ...grid.pt_miniPlus,
        cursor: 'pointer',
        textDecoration: 'underline',

        '&:hover': {
            textDecoration: 'none',
        },
    },
    linkNoPb: {
        ...grid.pt_miniPlus,
        cursor: 'pointer',
        textDecoration: 'underline',

        '&:hover': {
            textDecoration: 'none',
        },
    },
}

addStyles(scheduleItemStyles)
