import React from 'react'
import { Radio } from 'atoms/Radio'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './deviceSettings__systemNotifications.jcss'
import { Typography } from 'atoms/Typography'

const DeviceSettingsSystemNotifications = (p_: {
    enabled: boolean
    value: boolean
    onChange: (value: any, field: string) => void
    className: string | object
}) => {
    if (!p_.enabled) {
        return null
    }

    return (
        <div className={cn(p_.className)}>
            <Typography className={cn(styles.title)} type="title">
                {translate('hideSystemNotifications')}
            </Typography>
            <div className={styles.radioWrapper}>
                <Radio
                    className={styles.radio}
                    onClick={function () {
                        p_.onChange(true, 'hideSystemNotifications')
                    }}
                    selected={p_.value}
                    label={translate('yes')}
                />
                <Radio
                    onClick={function () {
                        p_.onChange(false, 'hideSystemNotifications')
                    }}
                    selected={!p_.value}
                    label={translate('no')}
                />
            </div>
        </div>
    )
}

export default React.memo(DeviceSettingsSystemNotifications)
