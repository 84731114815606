import { cn } from 'ethcss'
import React, { FC } from 'react'
import { styles } from './Table-styles'
import { ITableProps } from './Table-types'

export const Table: FC<ITableProps> = ({ children, className = {} }) => {
    const renderChildren = () => children.map((child) => child)

    return <table className={cn(styles.Table, className)}>{renderChildren()}</table>
}
