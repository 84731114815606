import React from 'react'
import InstancesCatalogDrinksMethods, { categories } from './instancesCatalog_drinks.local.methods'
import Content from 'blocks.app/content/content'
import CustomList from 'blocks.app/customList/customList'
import Link from 'blocks.simple/link/link'
import translate from 'core/translate'

import styles from './instancesCatalog_drinks.jcss'
import { Steps } from 'atoms/Steps/Steps-view'
import { getURLSearchParamsString } from 'features/routes/utils'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

const types = {
    sliderProducts: 'drinksSlider',
    menuProducts: 'drinksMenu',
}

class InstancesCatalogDrinks extends InstancesCatalogDrinksMethods {
    render() {
        const s_ = this.state
        const p_ = this.props

        return (
            <Content title={'drinksCatalog'}>
                <div className={styles.wrapper}>
                    <Steps className={styles.tabs} active={s_.category}>
                        {categories.map((item, index) => (
                            <Link
                                id={item}
                                key={`drinksCategory_${index}`}
                                to={{
                                    pathname: p_.location.pathname,
                                    search: getURLSearchParamsString({ category: item }),
                                }}
                            >
                                {translate(item)}
                            </Link>
                        ))}
                    </Steps>
                    <CustomList
                        key={s_.category}
                        type={types[s_.category]}
                        onSelect={(selectedItems) => this.setState({ selectedItems })}
                        selectedItems={s_.selectedItems}
                        additionalData={{
                            sliderProducts: s_.sliderProducts,
                        }}
                        onGetData={(drinksData) => {
                            this.setState({ sliderProducts: drinksData.categories.sliderProducts })
                        }}
                    />
                </div>
            </Content>
        )
    }
}

const exportInstancesCatalogDrinks = checkAvailableModule('instancesCatalog/drinks') ? InstancesCatalogDrinks : null

export default exportInstancesCatalogDrinks
