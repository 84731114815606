import React from 'react'
import { Radio } from 'atoms/Radio'

import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { text as textStyles } from 'theme'
import { colors } from 'theme'
import styles from '../ui.jcss'

const getState = () => {
    const state = {}
    Object.keys(colors).forEach((color) => {
        state[color] = false
        state[`${color}_animate`] = false
    })

    return state
}

class UiRadio extends React.Component {
    state = getState()

    render() {
        const s_ = this.state
        const mods = Object.keys(colors)

        return (
            <div className={styles.blockPanel}>
                <div className={styles.title}>Радио кнопки</div>
                <div className={styles.content}>
                    <div>
                        <div className={cn(textStyles.bold, grid.mb_mini)}>Стандартные</div>
                        <div className={cn(grid.row, grid.fullWidth, grid.mb_big)}>
                            {mods.map((mod) => (
                                <div className={grid.mr_md} key={`default_radio_${mod}`}>
                                    <Radio
                                        onClick={(status) => this.setState({ [mod]: status })}
                                        mod={mod}
                                        selected={s_[mod]}
                                        className={cn(grid.rowCenter)}
                                    />
                                    <div className={cn(textStyles.bold, grid.mt_mini)}>{mod}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={cn(grid.fullWidth, grid.mb_big)}>
                        <div className={cn(textStyles.bold, grid.mb_mini)}>Без анимации</div>
                        <div className={grid.row}>
                            {mods.map((mod) => (
                                <div className={grid.mr_md} key={`withoutAnimate_radio_${mod}`}>
                                    <Radio
                                        onClick={(status) => this.setState({ [`${mod}_animate`]: status })}
                                        mod={mod}
                                        selected={s_[`${mod}_animate`]}
                                        animate={false}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <div className={cn(textStyles.bold, grid.mb_mini)}>disabled</div>
                        <Radio onClick={() => {}} selected={false} animate={false} disabled={true} />
                    </div>
                </div>
            </div>
        )
    }
}

export default UiRadio
