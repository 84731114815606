import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'

const catalogTemplateDisplaysStyles = {
    status: {
        ...grid.p_micro,
        ...item.rounded_full_mini,
        ...item.white
    }
}

addStyles(catalogTemplateDisplaysStyles)
export default catalogTemplateDisplaysStyles
