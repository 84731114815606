import React from 'react'
import { Radio } from 'atoms/Radio'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './deviceSettings__remoteControl.jcss'
import { Typography } from 'atoms/Typography'

const DeviceSettingsRemoteControl = (p_: {
    enabled: boolean
    className: string | object
    value: boolean
    onChange: (value: any, field: string) => void
}) => {
    if (!p_.enabled) {
        return null
    }

    return (
        <div className={cn(p_.className)}>
            <Typography className={cn(styles.title)} type="title">
                {translate('remoteControlEnabled')}
            </Typography>
            <div className={styles.radioWrapper}>
                <Radio
                    className={styles.radio}
                    onClick={function () {
                        p_.onChange(true, 'remoteControlEnabled')
                    }}
                    selected={p_.value}
                    label={translate('yes')}
                />
                <Radio
                    onClick={function () {
                        p_.onChange(false, 'remoteControlEnabled')
                    }}
                    selected={!p_.value}
                    label={translate('no')}
                />
            </div>
        </div>
    )
}

export default React.memo(DeviceSettingsRemoteControl)
