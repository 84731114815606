import { useState, useEffect } from 'react'
import { api } from 'core/api/ConnectionManager'
import { IFile } from '../../../core/models/Files'

type IconProps = {
    color: string
    disabled?: boolean
}

export const DashboardUnusedContentHooks = (open: boolean) => {
    const [selectedContents, setSelectedContents] = useState<number[]>([])
    const [data, setData] = useState<IFile[]>([])

    useEffect(() => {
        if (open) {
            getUnusedSources()
        }
    }, [open])

    const getUnusedSources = () => {
        api.send('getUnusedSources', {}).then((data: any) => {
            setData(data)
        })
    }

    const onSelectContent = (id: number) => {
        let _selectedContents = [...selectedContents]
        const isExistSelectedContent = _selectedContents.includes(id)

        if (isExistSelectedContent) {
            _selectedContents = _selectedContents.filter((content) => content !== id)
        } else {
            _selectedContents.push(id)
        }

        setSelectedContents(_selectedContents)
    }
    const getRemoveIconProps = () => {
        const props: IconProps = {
            color: 'grey',
        }

        if (!selectedContents.length) {
            props.color = 'greyLight'
            props.disabled = true
        }

        return props
    }
    const onMultiSelect = () => {
        let selectedContentsCurrent: number[] = []

        if (!selectedContents.length && data.length) {
            selectedContentsCurrent = data.map((content: IFile) => content.id)
        }

        setSelectedContents(selectedContentsCurrent)
    }
    const onDeleteContent = () => {
        api.send('deleteFile', { id: selectedContents }).then((res: any) => {
            const _selectedContents = selectedContents.filter((selected: any) => !res.id.includes(selected))
            const _data = data.filter((content: any) => !res.id.includes(content.id))

            setSelectedContents(_selectedContents)
            setData(_data)
        })
    }

    return {
        selectedContents,
        data,
        onSelectContent,
        getRemoveIconProps,
        onMultiSelect,
        onDeleteContent,
    }
}
