import React, { FC, memo, useContext } from 'react'
import { styles } from './TileList-styles'
import { ITileList } from './TileList-types'
import { TileItem } from './TileItem'
import { CatalogWithPaginationContext } from 'organisms/CatalogWithPagination/CatalogWithPagination-context'
import { cn } from 'ethcss'
import { ICatalogWithPaginationOptionsTile } from 'organisms/CatalogWithPagination/CatalogWithPagination-types'
import { isHorizontalMenu } from 'core/helpers/menu'

const TileListComponent: FC<ITileList> = ({ list, minimize }) => {
    const { common } = useContext(CatalogWithPaginationContext)
    const { tile } = common
    const { tileListClassNames } = tile as ICatalogWithPaginationOptionsTile

    const getTileListClassName = () => {
        if (!tileListClassNames) {
            return minimize ? styles.TileList_type_minimize : styles.TileList
        }

        return minimize ? tileListClassNames.minimize : tileListClassNames.desktop
    }

    return (
        <div className={cn(getTileListClassName(), isHorizontalMenu() && styles.TileList_x)}>
            {list.map((listItem, index) => {
                return (
                    <TileItem key={`catalog__item_${listItem.id}_${index}`} listItem={listItem} minimize={minimize} />
                )
            })}
        </div>
    )
}

export const TileList = memo(TileListComponent)
