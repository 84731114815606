import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { mediaCSS } from 'blocks.app/config'
import { textSizes } from 'theme/media'

const scoreboardsAddStyles = {
    title: {
        fontSize: textSizes.md,
    },
    inputLabel: {
        ...grid.mb_micro,
        textTransform: 'uppercase',
        display: 'block',
        width: '100%',
        textAlign: 'center',
        fontSize: textSizes.normalPlus,
    },
    contentWrapper: {
        ...grid.row,
        ...item.white,
        ...grid.mt_mini,
        ...grid.p_md,
        ...item.rounded_full_mini,
    },
    content: {
        ...grid.row,
        ...grid.w100,
        ...grid.justify,
        ...grid.mt_mdPlus,
    },
    col: {
        ...grid.w45,
        ...grid.p_mini,
        [mediaCSS.mob]: {
            ...grid.w100,
        },
    },
    inputWrapper: {
        ...grid.mb_md,
    },
    buttons: {
        ...grid.w100,
        ...grid.p_microPlus,
        ...grid.rowCenter,
        ...item.white,
    },
    actions: {
        ...grid.flex,
        ...grid.row,
        ...grid.mb_md,
    },
    action_type_update: {
        marginLeft: 10,
    },
    info: {
        padding: `5px 0`,
        textAlign: 'center',
        lineHeight: 1.3,
        fontSize: textSizes.mini,
    },
}

addStyles(scoreboardsAddStyles)
export default scoreboardsAddStyles
