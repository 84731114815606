import React from 'react'
import LinkEditorMethods, { ILinkEditorProps } from './linkEditor.local.methods'
import EditText from 'blocks.simple/editText/editText'
import { Radio } from 'atoms/Radio'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './linkEditor.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import Dropdown from '../../blocks.simple/dropdown/dropdown'
import { Typography } from '../../atoms/Typography'

const getColLeftClassName = (small: boolean) => {
    if (small) {
        return cn(grid.w100, grid.mb_md)
    }

    return styles.colLeft
}

const getColRightClassName = (small: boolean) => {
    if (small) {
        return grid.w100
    }

    return styles.colRight
}

const convertOptions = (options: any[], toData?: boolean) => {
    if (!options || !options.length) return []

    if (toData) {
        return options.map((option) => option.name)
    }

    return options.map((option) => {
        return {
            name: option,
            label: option,
        }
    })
}

const GetValidUrls = (options: string[]) => options.filter((option: any) => isGoodUrl(option.name))

const isGoodUrl = (urlString: string) => {
    const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' +
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'
    )

    return urlPattern.test(urlString)
}

const LinkEditor = (p_: ILinkEditorProps) => {
    const { onChangeData, onChangeLink, state } = LinkEditorMethods(p_)
    const s_ = state
    const colLeft = getColLeftClassName(p_.small)
    const colRight = getColRightClassName(p_.small)

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.title}>{translate('link')}</div>
                <div className={grid.mb_mdPlus}>
                    <EditText
                        type="text"
                        placeholder="http://example.com"
                        defaultValue={s_.data.url}
                        error={s_.linkError}
                        onChange={onChangeLink}
                    />
                </div>
                <div className={styles.row}>
                    <div className={colLeft}>
                        <div className={styles.title}>{translate('showBackButton')}</div>
                        <div className={styles.radios}>
                            <Radio
                                label={translate('yes')}
                                selected={s_.data.showBackButton}
                                onClick={() => {
                                    onChangeData(true, 'showBackButton')
                                }}
                                className={styles.radio}
                            />
                            <Radio
                                label={translate('no')}
                                selected={!s_.data.showBackButton}
                                onClick={() => {
                                    onChangeData(false, 'showBackButton')
                                }}
                            />
                        </div>
                    </div>
                    <div className={colRight}>
                        <div className={styles.title}>{translate('refreshWhenNetStatusIsChanged')}</div>
                        <div className={styles.radios}>
                            <Radio
                                label={translate('yes')}
                                selected={s_.data.refreshWhenNetStatusIsChanged}
                                onClick={() => {
                                    onChangeData(true, 'refreshWhenNetStatusIsChanged')
                                }}
                                className={styles.radio}
                            />
                            <Radio
                                label={translate('no')}
                                selected={!s_.data.refreshWhenNetStatusIsChanged}
                                onClick={() => {
                                    onChangeData(false, 'refreshWhenNetStatusIsChanged')
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={colLeft}>
                        <div className={styles.title}>{translate('showLoadingProgress')}</div>
                        <div className={styles.radios}>
                            <Radio
                                label={translate('yes')}
                                selected={s_.data.showLoadingProgress}
                                onClick={() => {
                                    onChangeData(true, 'showLoadingProgress')
                                }}
                                className={styles.radio}
                            />
                            <Radio
                                label={translate('no')}
                                selected={!s_.data.showLoadingProgress}
                                onClick={() => {
                                    onChangeData(false, 'showLoadingProgress')
                                }}
                            />
                        </div>
                    </div>
                    <div className={colRight}>
                        <div className={styles.title}>{translate('enableTouchScaling')}</div>
                        <div className={styles.radios}>
                            <Radio
                                label={translate('yes')}
                                selected={s_.data.enableTouchScaling}
                                onClick={() => {
                                    onChangeData(true, 'enableTouchScaling')
                                }}
                                className={styles.radio}
                            />
                            <Radio
                                label={translate('no')}
                                selected={!s_.data.enableTouchScaling}
                                onClick={() => {
                                    onChangeData(false, 'enableTouchScaling')
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={colLeft}>
                        <div className={styles.title}>{translate('disableScrollFrame')}</div>
                        <div className={styles.radios}>
                            <Radio
                                label={translate('yes')}
                                selected={s_.data.scroll === 'off'}
                                onClick={() => {
                                    onChangeData('off', 'scroll')
                                }}
                                className={styles.radio}
                            />
                            <Radio
                                label={translate('no')}
                                selected={s_.data.scroll === 'on'}
                                onClick={() => {
                                    onChangeData('on', 'scroll')
                                }}
                            />
                        </div>
                    </div>
                    <div className={colRight}>
                        <div className={styles.title}>{translate('allowModalOpen')}</div>
                        <div className={styles.radios}>
                            <Radio
                                label={translate('yes')}
                                selected={s_.data.allowModal}
                                onClick={() => {
                                    onChangeData(true, 'allowModal')
                                }}
                                className={styles.radio}
                            />
                            <Radio
                                label={translate('no')}
                                selected={!s_.data.allowModal}
                                onClick={() => {
                                    onChangeData(false, 'allowModal')
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={colLeft}>
                        <div className={styles.title}>
                            {translate('loadTimeout')} ({translate('sec')})
                        </div>
                        <div>
                            <EditText
                                type="number"
                                value={s_.data.loadTimeout ? s_.data.loadTimeout / 1000 : ''}
                                onChange={(loadTimeout: string) => {
                                    const value = parseInt(loadTimeout, 10) * 1000
                                    onChangeData(value, 'loadTimeout')
                                }}
                            />
                        </div>
                    </div>
                    <div className={colRight}>
                        <div className={styles.title}>
                            {translate('refreshInterval')} ({translate('sec')})
                        </div>
                        <div>
                            <EditText
                                type="number"
                                value={s_.data.refreshInterval ? s_.data.refreshInterval / 1000 : ''}
                                onChange={(refreshInterval: string) => {
                                    const value = parseInt(refreshInterval, 10) * 1000
                                    onChangeData(value, 'refreshInterval')
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={colLeft}>
                        <div className={styles.title}>
                            {translate('inactivityTimeout')} ({translate('sec')})
                        </div>
                        <div>
                            <EditText
                                type="number"
                                value={s_.data.inactivityTimeout ? s_.data.inactivityTimeout / 1000 : ''}
                                onChange={(inactivityTimeout: string) => {
                                    const value = parseInt(inactivityTimeout, 10) * 1000
                                    onChangeData(value, 'inactivityTimeout')
                                }}
                            />
                        </div>
                    </div>
                    <div className={colRight}>
                        <div className={styles.title}>{translate('listAllowedDomains')}</div>
                        <div>
                            <Dropdown
                                mod="withBorder"
                                className={styles.domainsInput}
                                list={convertOptions(s_.data.allowedDomains)}
                                options={[]}
                                searchable={true}
                                multiselectItems={true}
                                onChange={(elements) => {
                                    onChangeData(convertOptions(GetValidUrls(elements), true), 'allowedDomains')
                                }}
                            />
                        </div>
                        <div className={grid.mt_mini}>
                            <Typography className={styles.centerBlock} type="text">
                                {translate('addAllowedDomain')}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LinkEditor
