import React from 'react'
import LoaderItem from 'blocks.app/loader/__item/loader__item'

import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import styles from './catalog__template_reportTasks.jcss'
import { cn } from 'ethcss'
import helpers from 'core/helpers'

export default {
    getMethod: 'getReportTasks',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noReportTasks'),
    emptyIcon: 'not_allow',
    settingsPrefixes: 'reportTasks',
    listWrapperClassName: styles.listWrapperClassName,
    getItemModel: (dataItem, getSelected) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    isEqual: (item, prevItem) => item.id === prevItem.id,
    onCreateListeners: [(cb, clearList) => api.addObserver('reportTaskCreated', cb, clearList)],
    onUpdateListeners: [(cb, clearList) => api.addObserver('reportTaskUpdated', cb, clearList)],
    onDeleteListeners: [(cb, clearList) => api.addObserver('reportTaskDeleted', cb, clearList)],
    fields: () => [
        { id: 'status', name: translate('status') },
        { id: 'startTime', name: translate('startTime') },
        { id: 'endTime', name: translate('endTime') },
        { id: 'reportType', name: translate('reportType') },
        { id: 'user', name: translate('user') },
        { id: 'createdAt', name: translate('createdAt') },
    ],
    init(callback) {
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    perPage: 60,
    list: {
        status: ({ status }) => {
            const isShowLoader = status === 'in_progress' || status === 'in_queue'

            return (
                <div className={cn(styles.statusWrapper)}>
                    {isShowLoader && <LoaderItem small={true} />}
                    {!isShowLoader && <div className={cn(styles.status, styles[status])} />}
                </div>
            )
        },
        startTime: ({ data }) => {
            return helpers.getFormattedLocalDate(data.startDate, 'HH:mm DD/MM/YYYY')
        },
        endTime: ({ data }) => {
            return helpers.getFormattedLocalDate(data.endDate, 'HH:mm DD/MM/YYYY')
        },
        reportType: ({ reportType }) => {
            return translate(reportType)
        },
        user: ({ user }) => {
            return user.email
        },
        createdAt: ({ createdAt }) => {
            return helpers.getFormattedLocalDate(createdAt, 'DD/MM/YYYY')
        },
    },
}
