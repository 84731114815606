import { addStyles } from 'ethcss'
import { grid, IStyles } from 'theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { mediaCSS, text } from 'theme'

const wakeOnLanStyles: IStyles = {
    wrapper: {
        ...grid.col,
        ...grid.justify,
        overflowY: 'auto',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        background: '#FFFFFF',
        boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.3)',
        borderRadius: '5px',
        width: '100%',
        maxWidth: '720px',
        lineHeight: '1.3',
    },
    header: {
        ...grid.row,
        ...grid.p_md,
        ...grid.pl_big,
        [mediaCSS.tabMini]: {
            ...grid.p_normal,
        },
        ...grid.pl_mini,
        justifyContent: 'center',
        borderBottom: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    footer: {
        ...grid.p_md,
        borderTop: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    wakeOnLan__content: {
        padding: '21px',
    },
    wakeOnLan__title: {
        ...text.mdPlus,
        [mediaCSS.tabMini]: {
            ...text.normal,
        },
        fontSize: '18px',
    },
    wakeOnLan__text: {
        [mediaCSS.tabMini]: {
            ...text.normal,
        },
    },
    wakeOnLan__list: {
        listStyle: 'inside',
        maxWidth: '542px',
        margin: '20px 0',
    },
    wakeOnLan__element: {
        marginBottom: '20px',
    },
    wakeOnLan__status: {
        marginTop: '20px',
        color: '#196d15',
    },
    wakeOnLan__success: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        fontWeight: 'bold',
        fontSize: '18px',
        color: '#003688',
        padding: '60px 0',

        '& img': {
            marginBottom: '20px',
        },
    },
}

addStyles(wakeOnLanStyles)
export default wakeOnLanStyles
