import PropTypes from 'prop-types'
import React from 'react'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import ModalAccept from 'blocks.simple/modal/_accept/modal_accept'
import Icon from 'blocks.simple/icon/icon'

import styles from './editor__templates.jcss'
import translate from 'core/translate'
import { api } from 'core/api/ConnectionManager'
import { cn } from 'ethcss'
import { editor } from '../editor.local.methods'
import deepEqual from 'fast-deep-equal'

class EditorTemplates extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            templates: [],
            selectedTemplate: null,
        }
        this.deleteTemplate = this.deleteTemplate.bind(this)
    }
    // Удаление шаблона с сервера
    deleteTemplate(e, index) {
        e.stopPropagation()
        const s_ = this.state
        const template = s_.templates[index]

        api.send('deleteTemplate', { id: template.id }).then(() => {
            s_.templates.splice(index, 1)
            this.setState(s_)
        })
    }
    componentDidMount() {
        api.send('getTemplates', {}).then((templates) => this.setState({ templates }))
    }
    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(nextProps, this.props) || !deepEqual(nextState, this.state)
    }
    render() {
        const s_ = this.state
        const p_ = this.props

        return (
            <div className={styles.wrapper}>
                {s_.templates.map((template, index) => (
                    <div key={`editor__template_${index}`} className={styles.cell}>
                        <div className={styles.cellInner}>
                            <div
                                className={styles.cellRect}
                                onClick={() => {
                                    editor.selectArea(null)
                                    if (editor.pageContentLength()) {
                                        this.setState({ selectedTemplate: template })
                                    } else {
                                        editor.setArea(template)
                                    }
                                }}
                            >
                                <Rectangle width="16" height="9">
                                    {template.areas.map((area, areaIndex) => (
                                        <div
                                            key={`editor__template_area_${index}_${areaIndex}`}
                                            className={styles.area}
                                            style={{
                                                top: `${area.top * 100}%`,
                                                left: `${area.left * 100}%`,
                                                width: `${area.width * 100}%`,
                                                height: `${area.height * 100}%`,
                                            }}
                                        />
                                    ))}
                                </Rectangle>
                            </div>
                            <div className={styles.title}>
                                {translate('template')} {index + 1}
                            </div>

                            <div className={cn(styles.delete)}>
                                {(() => {
                                    const disabled =
                                        p_.acceptedSelectedTemplate && p_.acceptedSelectedTemplate.id === template.id

                                    return (
                                        <Icon
                                            disabled={disabled}
                                            type={'delete'}
                                            color={'greyLight'}
                                            size={14}
                                            tooltip={{ title: translate('deleteTemplate') }}
                                            onClick={(e) => this.deleteTemplate(e, index)}
                                        />
                                    )
                                })()}
                            </div>
                        </div>
                    </div>
                ))}
                <ModalAccept
                    open={!!s_.selectedTemplate}
                    onReject={() => {
                        this.setState({ selectedTemplate: null })
                    }}
                    onAccept={() => {
                        this.setState({ selectedTemplate: null }, () => {
                            editor.setArea(s_.selectedTemplate)
                        })
                    }}
                    title={translate('templateWarningTitle')}
                    description={translate('templateWarningDescription')}
                    rejectLabel={translate('cancel')}
                    acceptLabel={translate('change')}
                />
            </div>
        )
    }
}

EditorTemplates.propTypes = {
    acceptedSelectedTemplate: PropTypes.object,
}

export default EditorTemplates
