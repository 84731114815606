import React from 'react'
import { Avatar } from 'molecules/Avatar'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import TruncateString from 'molecules/TruncateString'

import helpers from 'core/helpers'

import { cn } from 'ethcss'
import styles from './styles'
import { Typography } from 'atoms/Typography'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'

const ItemUsers = (p_) => {
    const isActive = p_.item.__view.selected || p_.active

    const printLabels = () => {
        return p_.item.roles.map((role) => role.label).join(', ')
    }

    return (
        <div className={styles.catalogItemUsersWrapper}>
            <div
                className={styles.catalogItemUsersContent}
                onClick={(e) => {
                    if (helpers.isDblTouchTap(e)) {
                        p_.onDoubleClickObject(p_.item)
                        return
                    }

                    if (p_.multiSelect) {
                        p_.onSelect({ selected: !p_.item.__view.selected, item: p_.item })
                        return
                    }

                    p_.onSelectInfo(e)
                }}
            >
                <div
                    className={cn(styles.catalogItemUsersCell, {
                        [styles.catalogItemUsersCellMultiSelect]:
                            (p_.multiSelect && p_.item.__view.selected) || isActive,
                    })}
                >
                    <Avatar
                        className={styles.avatarImageWrapper}
                        src={p_.item.photo}
                        size="100%"
                        icon={{
                            type: 'user',
                            color: getThemeStyleValue('ui', 'icon', 'light') || defaultThemeStyles.light,
                            className: styles.centered,
                        }}
                    />
                    {p_.item.authType === 'activeDirectory' && (
                        <div className={styles.catalogIitemUsersAuthType}>AD</div>
                    )}
                </div>
                <div className={styles.catalogItemUsersFooter}>
                    <div className={styles.catalogItemUsersInfo}>
                        <Typography type="title" className={styles.catalogItemUsersName}>
                            {p_.item.email}
                        </Typography>
                        <div className={styles.catalogItemUsersRoles}>{printLabels()}</div>
                    </div>
                </div>
                {p_.multiSelect && (
                    <div className={styles.catalogItemUsersCheckbox}>
                        <Checkbox
                            checked={p_.item.__view.selected}
                            onChange={(selected, e) => {
                                e.stopPropagation()
                                p_.onSelect({ selected, item: p_.item })
                            }}
                            size={24}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}
export default ItemUsers
