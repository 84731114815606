import EditText from 'blocks.simple/editText/editText'
import translate from 'core/translate'
import React, { FC } from 'react'
import { styles } from './Device-styles'
import { DeviceProps } from './Device-types'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import Button from 'blocks.simple/button/button'
import { SwitchButton } from '../../../../atoms/SwitchButton'

export const Device: FC<DeviceProps> = ({
    order,
    value,
    label,
    snowflakeEnabledQ,
    onChange,
    onDelete,
    onEndTyping,
    labels,
}) => {
    const getInputLabel = (textKey: string) => <div className={styles.device__label}>{translate(textKey)}</div>
    const onChangeOrder = (order: number) => onChange({ order })
    const onChangeValue = (value: string) => onChange({ value })
    const onChangeLabel = ({ labelId }: { labelId: number }) => onChange({ label: labelId })
    const onChangeSwitch = () => onChange({ snowflakeEnabledQ: snowflakeEnabledQ === null ? true : !snowflakeEnabledQ })
    const handleDelete = () => onDelete()

    return (
        <div className={styles.device}>
            <div className={styles.device__input}>
                <EditText label={getInputLabel('order')} value={order} type="number" onChange={onChangeOrder} />
            </div>
            <div className={styles.device__input}>
                <EditText
                    label={getInputLabel('value')}
                    value={value}
                    onChange={onChangeValue}
                    onEndTyping={onEndTyping}
                />
            </div>
            <div className={styles.device__input}>
                <Dropdown
                    label={getInputLabel('label')}
                    options={labels}
                    value={label}
                    valueField="labelId"
                    onChange={onChangeLabel}
                    wrapperWidth
                />
            </div>
            <div className={styles.device__input}>
                <div className={styles.device__inputCaption}>{translate('snowflake')}</div>
                <SwitchButton onChange={onChangeSwitch} checked={snowflakeEnabledQ} />
                {snowflakeEnabledQ === null && <p>{translate('elementNotSelected')}</p>}
            </div>
            <div className={styles.device__button}>
                <Button mod={'fill'} onClick={handleDelete} indentSize="normal">
                    {translate('delete')}
                </Button>
            </div>
        </div>
    )
}
