import React from 'react'
import { api } from 'core/api/ConnectionManager'
import { ISelectedInfo } from '../__info/displays__info.local.methods'
import { IRecordWithStrings } from '../../../core/helpers'
import { ISelectedItems } from './displays__toolbarContent'

interface IDisplaysToolbarContentProps {
    selectedInfo: ISelectedInfo
    online: boolean
    platform: string
    enableIdentificationAction: any
    disableIdentificationAction: any
    openModal: (data: { modalState: string; modalOptions: { [index: string]: any } }) => void
    query: IRecordWithStrings
    parentId: number
    groupId: number
    downloadLink: string
    withoutAddress: { id: number; name: string }[]
    onCloseInfo: () => void
    selectedItems: ISelectedItems[]
}
interface IState {
    removeAll: boolean
    active: any[]
    modalState: boolean
    openPowerModal: string
    externalStorages: {
        externalStorages: {
            size: {
                totalBytes: number
                usedBytes: number
                availableBytes: number
            }
            label: string
        }
        externalStoragesUpdatedAt: string
    } | null
    descriptionModal: {
        open: boolean
        text: string
    }
    isUpdatingScreenShot: boolean
    volume?: number
}

class DisplaysToolbarContentMethods extends React.PureComponent<IDisplaysToolbarContentProps> {
    state: IState = {
        removeAll: false,
        active: [],
        modalState: false,
        openPowerModal: '',
        externalStorages: null,
        descriptionModal: {
            open: false,
            text: '',
        },
        isUpdatingScreenShot: false,
    }
    isGetVolume = true
    listenersId = []

    componentDidMount() {
        api.addObserver(
            'digitalSignageScreenshotCreated',
            () => {
                setTimeout(() => {
                    this.setState({ isUpdatingScreenShot: false })
                }, 1000)
            },
            this.listenersId
        )
    }

    componentDidUpdate(prevProps: IDisplaysToolbarContentProps, prevState: IState) {
        const p_ = this.props

        if (!p_.selectedInfo) {
            return
        }

        this.setVolumeForDeviceOrGroup(prevProps, prevState)
    }

    componentWillUnmount() {
        this.listenersId.forEach((id) => api.removeObserver(id))
    }

    setVolumeForDeviceOrGroup = (prevProps: IDisplaysToolbarContentProps, prevState: IState) => {
        const p_ = this.props

        if (prevProps.selectedInfo && p_.selectedInfo.id !== prevProps.selectedInfo.id) {
            this.isGetVolume = true
        }

        if (p_.selectedInfo.type === 'group' && this.isGetVolume) {
            this.isGetVolume = false

            this.getVolumeForGroup(p_).then((volume) => {
                if (prevState.volume !== volume) {
                    this.setState({ volume })
                }
            })
        }
    }

    enableRefreshAnimation = () => {
        return this.setState({ isUpdatingScreenShot: true })
    }
    getVolumeForGroup = (p_: IDisplaysToolbarContentProps) => {
        return api
            .send('getGroupVolume', { groupId: p_.selectedInfo.id }, { hideLoader: true })
            .then((res: any) => res.volume)
    }

    handleClickOutside() {
        const s_ = this.state

        if (!s_.removeAll) {
            return
        }

        this.setState({ removeAll: !s_.removeAll })
    }

    toggleRemoveAll() {
        this.setState({ removeAll: !this.state.removeAll })
    }

    removeGroup(groupItem: ISelectedInfo) {
        const s_ = this.state

        api.send('deleteGroup', { id: groupItem.id, removeAll: s_.removeAll })
        this.setState({ showDeleteMenu: false })
    }
}

export default DisplaysToolbarContentMethods
