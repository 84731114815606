import React, { useEffect, useState } from 'react'
import EditText from 'blocks.simple/editText/editText'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import { cn } from 'ethcss'
import styles from './tableEditor__size.jcss'
import translate from 'core/translate'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import tableEditorConfig from '../tableEditor.config'

interface IState {
    colOptions: { id: number; name: string }[]
    rowOptions: { id: number; name: string }[]
    data: any
    selectedRow: number
    selectedCol: number
}

interface TableEditorSizeProps {
    name: string
    onChangeName: (name: string) => void
    onUpdateTableSize: ({
        colNumber,
        rowNumber,
        widthByCol,
        heightByRow,
    }: {
        colNumber: number
        rowNumber: number
        widthByCol: string
        heightByRow: string
    }) => void
    onUpdateColWidth: (ColWidth: number) => void
    onUpdateRowHeight: (rowHeight: number) => void
    options: {
        rowHeight: string
        colWidth: string
    }
}

const TableEditorSize = (p_: TableEditorSizeProps) => {
    const [state, setState] = useState<IState>({
        data: constructData(p_),
        selectedRow: 0,
        selectedCol: 0,
        rowOptions: getRowOptions(p_),
        colOptions: getColOptions(p_),
    })
    const sizeTypes = [
        { id: 'fixed', name: translate('fixed') },
        { id: 'auto', name: translate('auto') },
    ]
    function constructData(p_: any) {
        return {
            colNumber: p_.options.colNumber,
            rowNumber: p_.options.rowNumber,
            widthByCol: p_.options.widthByCol || [],
            heightByRow: p_.options.heightByRow || [],
        }
    }
    useEffect(() => {
        setState((prevState) => {
            return {
                ...prevState,
                data: constructData(p_),
                rowOptions: getRowOptions(p_),
                colOptions: getColOptions(p_),
            }
        })
    }, [p_])

    function changeData(value: any, field: string) {
        const s_ = { ...state }

        s_.data[field] = value ? parseInt(value, 10) : value

        setState(s_)
    }
    function getRowOptions(p_: any) {
        const rowNumber = p_.options.rowNumber
        const options = []

        for (let i = 0; i < rowNumber; i++) {
            options.push({ id: i, name: `${i + 1}` })
        }

        return options
    }
    function getColOptions(p_: any) {
        const colNumber = p_.options.colNumber
        const options = []

        for (let i = 0; i < colNumber; i++) {
            options.push({ id: i, name: `${i + 1}` })
        }

        return options
    }
    function onChangeTableSize(value: string, key: string) {
        const s_ = state
        let selectedLine = s_.selectedCol
        if (key === 'heightByRow') {
            selectedLine = s_.selectedRow
        }

        if (!value.length) {
            s_.data[key][selectedLine] = value
        }

        if (value.match(/[0-9]/gm)) {
            s_.data[key][selectedLine] = parseInt(value, 10)
        }

        setState(s_)
    }

    let widthByColValue = state.data.widthByCol[state.selectedCol]
    let heightByRowValue = state.data.heightByRow[state.selectedRow]

    if (typeof widthByColValue === 'undefined' || widthByColValue === null) {
        widthByColValue = tableEditorConfig.initCellWidth
    }
    if (typeof heightByRowValue === 'undefined' || heightByRowValue === null) {
        heightByRowValue = tableEditorConfig.initCellHeight
    }

    return (
        <div>
            <div className={styles.row}>
                <EditText
                    type={'text'}
                    value={p_.name}
                    label={translate('name')}
                    onChange={(name: string) => p_.onChangeName(name)}
                    indentSize={'mini'}
                    rounded={'full_md'}
                />
            </div>
            <div className={styles.row}>
                <EditText
                    type={'number'}
                    containerClass={cn(styles.inputWrapper)}
                    value={state.data.colNumber}
                    label={translate('colNumber')}
                    onChange={(colNumber: string) => {
                        changeData(colNumber, 'colNumber')
                    }}
                    indentSize={'mini'}
                    rounded={'full_md'}
                    onEndTyping={function () {
                        p_.onUpdateTableSize(state.data)
                    }}
                />
                <EditText
                    type={'number'}
                    containerClass={cn(styles.inputWrapper)}
                    value={state.data.rowNumber}
                    label={translate('rowNumber')}
                    onChange={(rowNumber: string) => changeData(rowNumber, 'rowNumber')}
                    indentSize={'mini'}
                    rounded={'full_md'}
                    onEndTyping={function () {
                        p_.onUpdateTableSize(state.data)
                    }}
                />
            </div>
            <div className={styles.row}>
                <div className={cn(styles.inputWrapper)}>
                    <div className={cn(styles.selectSizeType)}>
                        <Dropdown
                            label={translate('rowHeight')}
                            indentSize={'mini'}
                            onChange={(selected) => p_.onUpdateRowHeight(selected.id)}
                            options={sizeTypes}
                            value={p_.options.rowHeight}
                        />
                    </div>

                    <div
                        className={cn(grid.rowCenter, grid.mt_md, {
                            [item.hidden]: p_.options.rowHeight !== 'fixed',
                        })}
                    >
                        <div className={cn(grid.w50, grid.pr_md)}>
                            <Dropdown
                                label={translate('row')}
                                indentSize={'mini'}
                                onChange={(selected) =>
                                    setState((prevState) => {
                                        return { ...prevState, selectedRow: selected.id }
                                    })
                                }
                                options={state.rowOptions}
                                value={state.selectedRow}
                            />
                        </div>
                        <div className={cn(grid.w50)}>
                            <EditText
                                key={`rowHeight_${state.selectedRow}`}
                                value={heightByRowValue}
                                label={translate('height')}
                                onChange={(rowHeight: string) => {
                                    onChangeTableSize(rowHeight, 'heightByRow')
                                }}
                                indentSize={'mini'}
                                rounded={'full_md'}
                                onEndTyping={function () {
                                    p_.onUpdateTableSize(state.data)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={cn(styles.inputWrapper)}>
                    <div className={cn(styles.selectSizeType)}>
                        <Dropdown
                            label={translate('colWidth')}
                            indentSize={'mini'}
                            onChange={(selected) => p_.onUpdateColWidth(selected.id)}
                            options={sizeTypes}
                            value={p_.options.colWidth}
                        />
                    </div>

                    <div
                        className={cn(grid.rowCenter, grid.mt_md, {
                            [item.hidden]: p_.options.colWidth !== 'fixed',
                        })}
                    >
                        <div className={cn(grid.w50, grid.pr_md)}>
                            <Dropdown
                                label={translate('column')}
                                indentSize={'mini'}
                                onChange={(selected) =>
                                    setState((prevState) => {
                                        return { ...prevState, selectedCol: selected.id }
                                    })
                                }
                                options={state.colOptions}
                                value={state.selectedCol}
                            />
                        </div>
                        <div className={cn(grid.w50)}>
                            <EditText
                                key={`colWidth_${state.selectedCol}`}
                                value={widthByColValue}
                                label={translate('width')}
                                onChange={(colWidth: string) => {
                                    onChangeTableSize(colWidth, 'widthByCol')
                                }}
                                indentSize={'mini'}
                                rounded={'full_md'}
                                onEndTyping={function () {
                                    p_.onUpdateTableSize(state.data)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableEditorSize
