import React from 'react'
import TextEditor from 'blocks.app/textEditor/textEditor'

const TableEditorText = (p_: {
    onChange: (value: string, property: string, field?: string) => void
    small: boolean
    options: {
        text: string
        style: object
    }
}) => {
    const style = {
        marginTop: 0,
    }

    return (
        <TextEditor
            style={style}
            onlyText
            type="cell"
            onChange={(object: any) => {
                let field = 'text'

                if (object.hasOwnProperty('style')) {
                    field = 'style'
                }

                p_.onChange(object[field], field)
            }}
            small={p_.small}
            model={{
                content: p_.options.text,
                style: p_.options.style,
            }}
        />
    )
}

export default TableEditorText
