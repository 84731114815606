import React from 'react'
import TextEditorMethods, { ITextEditorProps } from './textEditor.local.methods'
import TextEditorToolbar from './__toolbar/textEditor__toolbar'
import TextEditorSettings from 'blocks.app/textEditor/__settings/textEditor__settings'
import TextEditorDynamic from './__dynamic/textEditor__dynamic'
import EditText from 'blocks.simple/editText/editText'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import Highlight from 'blocks.simple/highlight/highlight'
import filesHelpers from 'core/helpers/_files'
import helpers from 'core/helpers'
import translate from 'core/translate'
import { TEXT_DISPLAY_AS } from 'core/models/Files'
import { cn } from 'ethcss'
import styles from './textEditor.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { connect } from 'react-redux'
import { emitError } from 'features/appNotifications/AppNotifications.state'

const isDynamicText = (type: string) => {
    const types = ['xml', 'csv']

    return types.includes(type)
}

const TextEditor = (p_: ITextEditorProps) => {
    const { state, setState, onChangeStyles, onChange, onDisplayAsQrCode, getRssContent, addTag } = TextEditorMethods(
        p_
    )

    const s_ = state
    const style = filesHelpers.convertStyles(s_.style)
    const isDynamic = isDynamicText(s_.type)

    return (
        <div className={cn(styles.textEditor, p_.containerClassName)} style={p_.style}>
            <TextEditorToolbar
                {...s_}
                onChangeStyles={onChangeStyles}
                onChange={onChange}
                small={p_.small}
                onlyText={p_.onlyText}
                entryType={p_.type}
            />
            <div className={grid.p_md}>
                <EditText
                    readOnly={s_.type === 'xml'}
                    value={s_.content ? helpers.strip_html_tags(s_.content) : ''}
                    style={style}
                    onChange={(content: any) => {
                        p_.onChange({ text: content })
                        setState((prevState) => {
                            return {
                                ...prevState,
                                content,
                            }
                        })
                    }}
                />
                {s_.type !== 'rss' && (
                    <Checkbox
                        className={styles.textEditorQrCodeCheckbox}
                        checked={state.displayAs === TEXT_DISPLAY_AS.QR_CODE}
                        label={translate('displayAsQrCode')}
                        onClick={onDisplayAsQrCode}
                    />
                )}
            </div>
            <TextEditorSettings
                disabled={s_.displayAs === TEXT_DISPLAY_AS.QR_CODE}
                settings={s_.settings}
                onChange={(settings) => {
                    setState((prevState: any) => {
                        return {
                            ...prevState,
                            settings,
                        }
                    })
                }}
            />
            {s_.type === 'rss' && (
                <div className={styles.preview}>
                    <div className={item.title}>{translate('rssResource')}</div>
                    <div className={styles.rss}>
                        <EditText
                            value={s_.url}
                            onChange={(url: string) => {
                                setState((prevState) => {
                                    return {
                                        ...prevState,
                                        url,
                                    }
                                })
                            }}
                            icon={'view'}
                            onIconClick={getRssContent}
                        />
                        {s_.rssContent && (
                            <div className={styles.highlight} onClick={addTag}>
                                <Highlight content={s_.rssContent} />
                            </div>
                        )}
                    </div>
                </div>
            )}
            {isDynamic && (
                <TextEditorDynamic
                    small={p_.small}
                    node={s_.node}
                    type={s_.type}
                    localPath={s_.localPath}
                    row={s_.row}
                    column={s_.column}
                    onChange={(data) =>
                        setState((prevState) => {
                            return {
                                ...prevState,
                                ...data,
                            }
                        })
                    }
                />
            )}
        </div>
    )
}

TextEditor.defaultProps = {
    query: {},
    onlyText: false,
    onChange: () => {},
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        emitError: (err: string) => dispatch(emitError(err)),
    }
}

// @ts-ignore
export default connect(null, mapDispatchToProps, null, { forwardRef: true })(TextEditor)
