import React from 'react'
import helpers from 'core/helpers/_files'
import { cn } from 'ethcss'
import styles from './rectangle.jcss'

interface IRectangleProps {
    className?: string | {}
    containerClassName?: string | {}
    containerStyles?: {}
    src?: string
    contain?: boolean
    center?: boolean
    children?: React.ReactChildren | React.ReactNode
    height: number
    width: number
    onScroll?: () => void
    onClick: () => void
    tooltip?: object
}

const Rectangle = (p_: IRectangleProps) => {
    const paddingBottom = (p_.height / p_.width) * 100
    let contentProps: {
        style?: any
        className: any
    } = {
        className: cn(p_.className, styles.content),
    }

    if (p_.src) {
        const isSvgThumbnail = helpers.isSvgThumbnail(p_.src)

        contentProps = {
            style: {
                backgroundImage: `url(${p_.src})`,
            },
            className: cn(p_.className, {
                [styles.center]: p_.center,
                [styles.contain]: p_.contain,
                [styles.cover]: !p_.center && !p_.contain && !isSvgThumbnail,
                [styles.fullWidth]: isSvgThumbnail,
            }),
        }
    }

    return (
        <div onClick={p_.onClick} className={cn(styles.wrapper, p_.containerClassName)} style={p_.containerStyles}>
            <div {...contentProps} onScroll={p_.onScroll}>
                {p_.children}
            </div>
            <div style={{ paddingBottom: `${paddingBottom}%` }} />
        </div>
    )
}

Rectangle.defaultProps = {
    width: 1,
    height: 1,
    onClick: () => {},
}

export default Rectangle
