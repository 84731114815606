import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { IStyles } from '../../../theme'

const previewTextStyles: IStyles = {
    wrapper: {
        ...grid.colCenter,
        ...grid.full,
        ...grid.center,
    },
    content: {
        ...grid.p_md,
    },
    previewQrCode: {
        ...grid.full,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
    },
}

addStyles(previewTextStyles)
export default previewTextStyles
