import { addStyles } from 'ethcss'
import { colors } from '../../../theme'

const editorAnimationSettings = {
    animationWrapper: {
        paddingBottom: 50,
    },
    useAnimation: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    optionWrapper: {
        marginTop: 20,
        marginBottom: 30,
    },
    option: {
        marginBottom: 10,
    },
    previewWrapper: {
        marginTop: 40,
    },
    previewLabel: {
        marginBottom: 7,
    },
    preview: {
        position: 'relative',
        width: 300,
        height: 169,
        borderRadius: 12,
        overflow: 'hidden',
        backgroundColor: colors.black,
    },
    contentArea: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    },
    content: {
        position: 'absolute',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    checkbox: {},
    rangeWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    range: {
        width: 'calc(100% - 70px)',
        direction: 'rtl',
        background: '#2582e0',
        height: 6,
    },
    rangeLabel: {
        marginBottom: 7,
    },
}

addStyles(editorAnimationSettings)
export default editorAnimationSettings
