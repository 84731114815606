import React, { FunctionComponent, memo, useRef } from 'react'
import { IAudioPlaygroundItemComponent } from './AudioPlaygroundItem-types'
import { audioPlaygroundItemStyles } from './AudioPlaygroundItem-styles'
import { VolumeLevel } from '../VolumeLevel/VolumeLevel-view'
import { useAudioPlaygroundItemPlay } from './AudioPlaygroundItem-hooks'
import translate from '../../../core/translate'

const AudioPlaygroundItemComponent: FunctionComponent<IAudioPlaygroundItemComponent> = ({
    title,
    text,
    volume,
    fileURL,
    currentMs,
}) => {
    let audioRef = useRef<HTMLAudioElement>(null)
    useAudioPlaygroundItemPlay(audioRef, currentMs, volume)

    return (
        <div className={audioPlaygroundItemStyles.AudioPlaygroundItem}>
            <div className={audioPlaygroundItemStyles.AudioPlaygroundItem__volume}>
                <VolumeLevel volume={volume} />
            </div>
            <div className={audioPlaygroundItemStyles.nowPlaying}>{translate('nowPlaying')}:</div>
            <div className={audioPlaygroundItemStyles.AudioPlaygroundItem__content}>
                {/*<div className={audioPlaygroundItemStyles.AudioPlaygroundItem__title}>{title}</div>*/}
                <div className={audioPlaygroundItemStyles.AudioPlaygroundItem__text}>{text}</div>
            </div>
            <div className={audioPlaygroundItemStyles.AudioPlaygroundItem__audio}>
                <audio src={fileURL} ref={audioRef} />
            </div>
        </div>
    )
}

export const AudioPlaygroundItem = memo(AudioPlaygroundItemComponent)
