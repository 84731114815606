import React from 'react'
import config from '../index'
import translate from 'core/translate'
import CustomListHeader, { ICustomListHeaderProps } from '../../../__header/customList__header'
import Button from 'blocks.simple/button/button'
import EditableText from 'blocks.simple/editableText/editableText'
import { api } from 'core/api/ConnectionManager'
import validator from 'validator'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import store from 'core/store'

export default {
    ...config,
    category: 'buttons',
    type: 'askona',
    headerFilters: (p_: ICustomListHeaderProps) => (
        <CustomListHeader
            onAdd={() => p_.onAddFakeLine({ title: '', href: '' })}
            title={translate('buttons')}
            type={p_.type}
            onDelete={() => {
                const id = p_.selectedItems.map((selectedItem: { id: number }) => selectedItem.id)
                if (id.length > 0) {
                    api.send('askonaDeleteCatalogItem', { id })
                }
                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            }}
            {...p_}
        />
    ),
    fields: () => [
        { id: 'title', name: translate('name') },
        { id: 'href', name: translate('url') },
    ],
    init(callback: any) {
        // @ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        href: (
            p_: { id: number; href: string },
            active: boolean,
            listProps: {
                fakeLine: {
                    href: string
                    title: string
                }
                onAddFakeLine: (value?: {}) => void
                template: any
                catalogId: string
            }
        ) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.id })}>
                <div className={cn({ [grid.fullWidth]: p_.id, [grid.space]: !p_.id })}>
                    <EditableText
                        id={p_.id}
                        text={p_.href}
                        onChange={(href: string) => {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, href })
                        }}
                        onUpdate={(href, cb) => {
                            if (validator.isURL(href, { protocols: ['http', 'https'], require_protocol: true })) {
                                api.send('askonaUpdateCatalogItem', {
                                    href,
                                    id: p_.id,
                                    catalogId: listProps.catalogId,
                                    category: listProps.template.category,
                                }).then(cb)
                            } else {
                                store.dispatch(emitError('linkFormatError'))
                            }
                        }}
                    />
                </div>
                {!p_.id && (
                    <Button
                        className={cn(grid.ml_micro, grid.mr_mini)}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            listProps.onAddFakeLine()
                        }}
                    >
                        {translate('cancel')}
                    </Button>
                )}
                {!p_.id && (
                    <Button
                        mod={'fill'}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            if (
                                validator.isURL(listProps.fakeLine.href, {
                                    protocols: ['http', 'https'],
                                    require_protocol: true,
                                })
                            ) {
                                api.send('askonaCreateCatalogItem', {
                                    catalogId: listProps.catalogId,
                                    category: listProps.template.category,
                                    href: listProps.fakeLine.href,
                                    title: listProps.fakeLine.title,
                                })
                            } else {
                                store.dispatch(emitError('linkFormatError'))
                            }
                        }}
                        disabled={!listProps.fakeLine.title || !listProps.fakeLine.href}
                    >
                        {translate('save')}
                    </Button>
                )}
            </div>
        ),
        title: (
            p_: { id: number; title: string },
            active: boolean,
            listProps: {
                fakeLine: {
                    href: string
                    title: string
                }
                onAddFakeLine: (value?: {}) => void
                template: any
                catalogId: string
            }
        ) => (
            <EditableText
                id={p_.id}
                text={p_.title}
                onChange={(title: string) => {
                    listProps.onAddFakeLine({ ...listProps.fakeLine, title })
                }}
                onUpdate={(title, cb) => {
                    api.send('askonaUpdateCatalogItem', {
                        title,
                        id: p_.id,
                        catalogId: listProps.catalogId,
                        category: listProps.template.category,
                    }).then(cb)
                }}
            />
        ),
    },
}
