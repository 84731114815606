import styles from '../ipTv.jcss'
import EditText from '../../../blocks.simple/editText/editText'
import translate from '../../../core/translate'
import Dropdown from '../../../blocks.simple/dropdown/dropdown'
import { colors } from '../../../theme'
import React from 'react'

export const Search = ({
    search,
    setSearch,
    dropdownSelect,
}: {
    search: string
    setSearch: (search: string) => void
    dropdownSelect: any
}) => {
    const selectOptions = [
        { id: 'selectAll', name: translate('selectAll') },
        { id: 'selectNone', name: translate('cancelAll') },
    ]

    return (
        <div className={styles.searchBar}>
            <div className={styles.search}>
                <EditText
                    value={search}
                    onChange={(value: string) => {
                        setSearch(value)
                    }}
                    className={styles.searchInput}
                    placeholder={translate('search')}
                />
            </div>
            <div className={styles.dropdown}>
                <Dropdown
                    mod={'default'}
                    options={selectOptions}
                    valueField={'name'}
                    onChange={dropdownSelect}
                    icon={'choice'}
                    iconColor={colors.grey}
                    className={styles.dropdownBtn}
                    placeholder={translate('choice')}
                />
            </div>
        </div>
    )
}
