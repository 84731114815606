import React, { useRef } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import Content from 'blocks.app/content/content'
import translate from 'core/translate'
import config from 'core/config'
import styles from './about.jcss'
import { cn } from 'ethcss'
import moment from 'moment'
import Toast from 'blocks.simple/toast/toast'
import Button from 'blocks.simple/button/button'
import grid from 'blocks.simple/grid/grid.jcss'
import { EditTextPassword } from 'blocks.simple/editText/editText'

declare var window: any

export const About = () => {
    const backEndVersion: any = useSelector<RootStateOrAny>((state) => state.app.backEndVersion)
    const serverBuildDate: any = useSelector<RootStateOrAny>((state) => state.app.buildDate)
    const location = window.location
    const origin = location.origin
    const pathname = location.pathname !== '/' ? location.pathname : ''

    const copyTableEl = useRef<HTMLTableElement | null>(null)
    const ref = copyTableEl as React.MutableRefObject<HTMLTableElement>

    const copyTable = () => {
        let range, sel
        const clipboardResultToastOptions = {
            autoClose: 3000,
            position: 'bottom-left',
        }

        if (document.createRange && window.getSelection) {
            range = document.createRange()
            sel = window.getSelection()
            sel.removeAllRanges()

            try {
                range.selectNodeContents(ref.current)
                sel.addRange(range)
                Toast.info(translate('tableWasCopied'), clipboardResultToastOptions)
            } catch (e) {
                range.selectNode(ref.current)
                sel.addRange(range)
                Toast.error(translate('copyToClipboardError'), clipboardResultToastOptions)
            }

            document.execCommand('copy')
        }
    }

    return (
        <Content title={translate('aboutSoftware')}>
            <div>
                <Button
                    mod={'fill'}
                    rounded="full_md"
                    indentSize="mini"
                    className={cn(grid.mb_md)}
                    onClick={() => {
                        copyTable()
                    }}
                >
                    {translate('copyTable')}
                </Button>
                <table className={cn(styles.table, grid.mb_md)} ref={ref}>
                    <tbody>
                        <tr>
                            <td className={cn(styles.td, styles.caption)}>
                                <b>{translate('productName')}</b>
                            </td>
                            <td className={cn(styles.td, styles.caption)}>
                                <b>{translate('domainName')}</b>
                            </td>
                            <td className={cn(styles.td, styles.caption)}>
                                <b>{translate('productVersion')}</b>
                            </td>
                            <td className={cn(styles.td, styles.caption)}>
                                <b>{translate('buildDate')}</b>
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.td}>{translate('personalCabinet')}</td>
                            <td className={styles.td}>{origin + pathname}</td>
                            <td className={styles.td}>{config.version ? config.version : null}</td>
                            <td className={styles.td}>{config.buildTime ? config.buildTime : null}</td>
                        </tr>
                        <tr>
                            <td className={styles.td}>{translate('server')}</td>
                            <td className={styles.td}>{window.spconfig.ip_address.slice(0, -1)}</td>
                            <td className={styles.td}>{backEndVersion}</td>
                            <td className={styles.td}>
                                {serverBuildDate ? moment(serverBuildDate).format('DD-MM-YYYY HH:mm:ss') : null}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className={cn(grid.pt_md)}>
                    <b>
                        <a
                            href={'https://wiki.smartplayer.org/index.php/Widget_API'}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {translate('selfDevelopmentWidgets')}
                        </a>
                    </b>
                </div>
                <div className={cn(grid.pt_md)}>
                    <b>
                        <a href={'https://restapi.smartplayer.org/'} target="_blank" rel="noopener noreferrer">
                            {translate('restapiLink')}
                        </a>
                    </b>
                </div>
                <div className={cn(grid.mt_md)}>
                    <div className={cn(grid.row, grid.normalCenter)}>
                        <div className={grid.mr_mini}>{translate('adLogin')}:</div>
                        <div className={grid.ml_md}>{window.spconfig.restApiLogin}</div>
                    </div>
                    <div className={cn(grid.row, grid.mt_md, grid.normalCenter)}>
                        <div>{translate('password')}:</div>
                        <div className={styles.content}>
                            <EditTextPassword
                                value={window.spconfig.restApiPassword}
                                placeholder={translate('password')}
                                rounded={'full_md'}
                                indentSize={'tiny'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}
