import { isNotEmptyArray } from './coreUtil'
import { IDeviceMove, ILicensesData } from 'core/models/DigitalSignage'
import translate from 'core/translate'
import store from 'core/store'

export const getParamsForMultipleMoveDisplays = (
    digitalSignageId: number[],
    groupId: number[],
    parentId: number
): IDeviceMove => {
    const params: IDeviceMove = {
        parentId,
    }

    if (isNotEmptyArray(groupId)) {
        params.groupId = groupId
    }

    if (isNotEmptyArray(digitalSignageId)) {
        params.digitalSignageId = digitalSignageId
    }

    return params
}

export const isVacantCountNull = (licensesData: ILicensesData) => {
    const state = store.getState()
    const checkLicense = state.user.company?.checkLicenseQ

    if (!checkLicense) {
        return true
    } else {
        if (!licensesData) return false
        return licensesData.some((license: any) => license.vacantCount > 0)
    }
}

export const licenseType = (license: any) => {
    if (license.expiredAt === null) {
        return translate('ransom')
    } else if (license.paidQ === false) {
        return translate('trial')
    } else {
        return translate('subscription')
    }
}

export const noEmoji = (value: string) => {
    return value.replace(
        /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
        ''
    )
}

export const rateTypeRename = (type: string): string | null => {
    switch (type) {
        case 'licenseTypeSmartSound':
            return `(${translate('tariff')} Smart Sound)`
        case 'licenseTypeSmartScreen':
            return `(${translate('tariff')} Smart Screen)`
        case 'licenseTypeSmartTouch':
            return `(${translate('tariff')} Smart Touch)`
        case 'licenseTypeVideoWall':
            return `(${translate('tariff')} Smart Video Wall до 4к)`
        case 'licenseTypeVideoWall4k':
            return `(${translate('tariff')} Smart Video Wall свыше 4к)`
        case 'licenseTypeVideoAnalytics':
            return `(${translate('tariff')} Smart Video Analytics)`
        default:
            return ''
    }
}
