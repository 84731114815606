import React, { FC, memo, useContext } from 'react'
import Icon from 'blocks.simple/icon/icon'
import { cn } from 'ethcss'
import { styles } from './Breadcrumbs-styles'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from './Breadcrumbs.theme'
import { IBreadcrumbsProps } from './Breadcrumbs-types'
import { BreadcrumbsItem } from './BreadcrumbsItem'
import { useFetchBreadcrumbs } from './Breadcrumbs-hooks'
import { CatalogWithPaginationContext } from 'organisms/CatalogWithPagination'
import { isHorizontalMenu } from 'core/helpers/menu'

const BreadcrumbsComponent: FC<IBreadcrumbsProps> = ({ parentValue, title, methodName }) => {
    const { common } = useContext(CatalogWithPaginationContext)
    const { parentKey } = common
    const { breadcrumbs: list } = useFetchBreadcrumbs({
        parentKey,
        title,
        parentValue,
        methodName,
    })
    const renderBreadcrumbIcon = ({ isActive }: { isActive: boolean }) => {
        const iconColor = isActive
            ? // ? getThemeStyleValue('app', 'menu', 'active') || defaultThemeStyles.active
              defaultThemeStyles.active
            : getThemeStyleValue('app', 'menu', 'disable') || defaultThemeStyles.disable

        return (
            <div className={cn(styles.Breadcrumbs__arrow)}>
                <Icon type="arrow_right" color={iconColor} default={true} />
            </div>
        )
    }

    const renderBreadcrumbList = () => {
        return list.map((breadcrumb, index) => {
            const { name } = breadcrumb
            const isFirstItem = index === 0
            const maxBreadcrumbDepth = list.length - 1
            const isActive = index === maxBreadcrumbDepth
            const isLastItem = index === maxBreadcrumbDepth

            return (
                <div key={`breadcrumbs__item_${name}`} className={styles.Breadcrumbs__item}>
                    {!isFirstItem && renderBreadcrumbIcon({ isActive })}

                    <BreadcrumbsItem
                        isActive={isActive}
                        isLastItem={isLastItem}
                        isFirstItem={isFirstItem}
                        index={index}
                        breadcrumb={breadcrumb}
                    />
                </div>
            )
        })
    }

    return (
        <div className={styles.Breadcrumbs}>
            <div className={isHorizontalMenu() ? '' : styles.Breadcrumbs__content}>
                <div className={styles.Breadcrumbs__scroll}>{renderBreadcrumbList()}</div>
            </div>
        </div>
    )
}

export const Breadcrumbs = memo(BreadcrumbsComponent)
