import React from 'react'
import { cn } from 'ethcss'
import styles from './tableEditor_preview.jcss'
import helpersFiles from '../../../core/helpers/_files'
import item from '../../../blocks.simple/item/item.jcss'
import grid from '../../../blocks.simple/grid/grid.jcss'
import tableEditorConfig from '../tableEditor.config'

interface ITableEditorPreviewProps {
    tableFormat: {
        widthByCol: string
        heightByRow: string
        colWidth: string
        rowHeight: string
        colNumber: number
        rowNumber: number
    }
    scale?: number
    wrapperClassName?: string
    onMultiSelectCells: (data: { row: number; col: number }) => void
    onMultiSelectCell: (data: { selectedCellRow: number; selectedCellCol: number; row?: number; col?: number }) => void
    onSelectCell: (data: { selectedCellRow: number; selectedCellCol: number }) => void
    id?: number
    cells: any
    inheritBorder?: boolean
    selectedCellRow: number
    selectedCellCol: number
    multiSelect: { [index: string]: boolean }
}

const TableEditorPreview = (p_: ITableEditorPreviewProps) => {
    const renderContent = (p_: ITableEditorPreviewProps) => {
        const { colNumber, rowNumber } = p_.tableFormat
        const rows = []

        for (let row = 0; row < rowNumber; row++) {
            const cols = []

            for (let col = 0; col < colNumber; col++) {
                const cell = getCell(p_, row, col)

                if (!cell) {
                    return
                }

                const styles = helpersFiles.convertStyles(cell.style)
                const cellStyles = {
                    ...getViewStyles(p_, row, col),
                    fontFamily: styles.fontFamily,
                    overflow: 'hidden',
                    verticalAlign: undefined,
                }

                cols.push(
                    <div
                        key={`tablePreviewCol_${col}_${p_.id}`}
                        style={cellStyles}
                        className={item.noSelection}
                        onContextMenu={function (e) {
                            if (/Mac OS/.test(navigator.userAgent)) {
                                e.preventDefault()

                                p_.onMultiSelectCell({
                                    selectedCellRow: row,
                                    selectedCellCol: col,
                                })
                            }
                        }}
                        onClick={function (e) {
                            if (e.ctrlKey) {
                                p_.onMultiSelectCell({
                                    selectedCellRow: row,
                                    selectedCellCol: col,
                                })

                                return
                            }

                            if (e.shiftKey) {
                                e.preventDefault()
                                p_.onMultiSelectCells({ row, col })

                                return
                            }

                            p_.onSelectCell &&
                                p_.onSelectCell({
                                    selectedCellRow: row,
                                    selectedCellCol: col,
                                })
                        }}
                    >
                        <div
                            className={item.iBlock}
                            style={{
                                fontFamily: styles.fontFamily,
                                verticalAlign: styles.verticalAlign,
                                maxWidth: '100%',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {cell ? cell.text : ''}
                        </div>
                        <div
                            className={item.vLine}
                            style={{
                                verticalAlign: styles.verticalAlign,
                            }}
                        />
                    </div>
                )
            }

            rows.push(
                <div
                    key={`tablePreviewRow_${row}_${p_.id}`}
                    className={cn(grid.row, grid.noWrap)}
                    style={{
                        height: p_.tableFormat.rowHeight === 'fixed' ? 'auto' : `${100 / rowNumber}%`,
                    }}
                >
                    {cols}
                </div>
            )
        }

        return rows
    }
    const getFixedWidth = (p_: ITableEditorPreviewProps, col: number) => {
        if (p_.tableFormat.widthByCol) {
            const widthByColValue = p_.tableFormat.widthByCol[col]

            if (typeof widthByColValue === 'undefined' || widthByColValue === null) {
                return tableEditorConfig.initCellWidth
            } else {
                return widthByColValue
            }
        } else {
            return tableEditorConfig.initCellWidth
        }
    }
    const getFixedHeight = (p_: ITableEditorPreviewProps, row: number) => {
        if (p_.tableFormat.heightByRow) {
            const heightByRowValue = p_.tableFormat.heightByRow[row]

            if (typeof heightByRowValue === 'undefined' || heightByRowValue === null) {
                return tableEditorConfig.initCellHeight
            } else {
                return heightByRowValue
            }
        } else {
            return tableEditorConfig.initCellHeight
        }
    }
    const getCell = (p_: ITableEditorPreviewProps, row: number, col: number) => {
        if (p_.cells && p_.cells[row] && p_.cells[row][col]) {
            return p_.cells[row][col]
        }

        return null
    }
    const getViewStyles = (p_: ITableEditorPreviewProps, row: number, col: number) => {
        let style: {
            [index: string]: string
        } = {
            cursor: 'pointer',
            position: 'relative',
        }
        let cellStyles: any = {}
        const { colNumber } = p_.tableFormat

        const cell = getCell(p_, row, col)
        if (cell) {
            cellStyles = {
                ...helpersFiles.convertStyles(cell.style, { withoutStyles: ['backgroundColor'] }),
                ...helpersFiles.convertStyles(cell.options, { cutWithoutPoints: true }),
                width: p_.tableFormat.colWidth === 'fixed' ? getFixedWidth(p_, col) : `${100 / colNumber}%`,
                height: p_.tableFormat.rowHeight === 'fixed' ? getFixedHeight(p_, row) : `${100}%`,
            }

            for (let key in cell.borders) {
                if (cell.borders.hasOwnProperty(key)) {
                    const borderName = key[0].toUpperCase() + key.slice(1)
                    if (cell.borders[key].size) {
                        cellStyles[
                            `border${borderName}`
                        ] = `${cell.borders[key].size}px ${cell.borders[key].type} ${cell.borders[key].color}`
                    } else if (p_.inheritBorder) {
                        cellStyles[`border${borderName}`] = '1px solid rgba(219, 219, 219, 0.3)'
                    }
                }
            }

            for (let key in cell.margins) {
                if (cell.margins.hasOwnProperty(key)) {
                    const sideName = key[0].toUpperCase() + key.slice(1)
                    cellStyles[`padding${sideName}`] = `${cell.margins[key]}px`
                }
            }
            cellStyles.background = helpersFiles.getRgbaBackgroundColor(cell.background)
        }

        if (
            (p_.selectedCellRow !== undefined &&
                p_.selectedCellRow === row &&
                p_.selectedCellCol !== undefined &&
                p_.selectedCellCol === col) ||
            (p_.multiSelect && p_.multiSelect[`${row}__${col}`])
        ) {
            style.boxShadow = '0px 1px 10px rgba(0, 0, 0, .5)'
            style.zIndex = '1'
        }

        return {
            ...cellStyles,
            ...style,
        }
    }
    const getPreviewSizes = (type?: string) => {
        const sum = (array: any) => {
            if (!array) {
                return 'auto'
            }

            return array.reduce((a: any, b: any) => a + b, 0)
        }

        if (type === 'width') {
            return sum(p_.tableFormat.widthByCol)
        }

        return sum(p_.tableFormat.heightByRow)
    }

    const scale = p_.scale ? p_.scale : 1

    return (
        <div className={cn(styles.tableWrapper, p_.wrapperClassName)}>
            <div
                style={{
                    width: p_.tableFormat.colWidth === 'auto' ? `${100 / scale}%` : getPreviewSizes('width'),
                    height: p_.tableFormat.rowHeight === 'auto' ? `${100 / scale}%` : getPreviewSizes(),
                    // @ts-ignore
                    MozTransform: `scale(${scale})`,
                    MozTransformOrigin: `0 0`,
                    OTransform: `scale(${scale})`,
                    OTransformOrigin: `0 0`,
                    WebkitTransform: `scale(${scale})`,
                    WebkitTransformOrigin: `0 0`,
                    willChange: 'transform',
                }}
            >
                {renderContent(p_)}
            </div>
        </div>
    )
}

export default TableEditorPreview
