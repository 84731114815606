import { colors } from 'theme'

export const defaultThemeStyles = {
    'fill-border': colors.greyLight,
    'fill-background': colors.greyLight,
    'fill-handler': colors.grey,
    'fill-activeBorder': colors.blackLight,
    'fill-activeBackground': colors.blackLight,
    'fill-activeHandler': colors.black,
}
