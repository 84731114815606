import { useRef, useEffect } from 'react'

/*
   usage
      const handler = useCallback(
        ({ clientX, clientY }) => {
          // Update coordinates
          setCoords({ x: clientX, y: clientY });
        },
        [setCoords]
      );

      // Add event listener using our hook
      useEventListener('mousemove', handler);
 */

export const useEventListener = (eventName: string, handler: () => void, element = window) => {
    const savedHandler = useRef<any>()

    useEffect(() => {
        savedHandler.current = handler
    }, [handler])

    useEffect(() => {
        const isSupported = element && element.addEventListener
        if (!isSupported) return

        const eventListener = (event: any) => savedHandler.current(event)
        element.addEventListener(eventName, eventListener)
        return () => {
            element.removeEventListener(eventName, eventListener)
        }
    }, [eventName, element])
}
