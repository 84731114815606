import { useState } from 'react'

const DraftDevicesMethods = () => {
    const [selectedInfo, setSelectedInfo] = useState<any>(null)
    const [selectedItems, setSelectedItems] = useState<{ id: number }[]>([])

    return {
        selectedInfo,
        selectedItems,
        setSelectedInfo,
        setSelectedItems,
    }
}

export default DraftDevicesMethods
