import { useRef, useState } from 'react'
import translate from 'core/translate'

const getDefaultActions = () => [{ id: 'show_broadcast_page', name: translate('content_click'), eventType: 'click' }]
const getDefaultEvents = () => [
    { id: 'face_recognition', name: translate('face_recognition'), key: ['gender'] },
    { id: 'rfid', name: translate('rfidTag'), key: ['tagId', 'label'] },
]

export interface ITouchEditorProps {
    type: string
    page: {
        id: number
        name: string
        order: number
        playOnEvent: {
            events: {
                id: string
                eventType: string
                key: string
                eventCondition: { key: string; value: string }
            }[]
        }
    }
    event: {
        id: string
        eventType: string
        eventCondition: { key: string; value: string }
        eventIndex: number
        key: string
    }
    events: {
        id: string
        eventType: string
        eventCondition: { key: string; value: string }
    }[]
    content: {
        actionsOnContent: any
    }
    action: {
        id: string
        name: string
        actionIndex: number
        eventType: string
        actionOptions: {}
    }
    pages: any
    onSave: (data: any, index?: number) => void
    onClose: () => void
    open: boolean
}

const TouchEditorLocalMethods = (p_: ITouchEditorProps) => {
    const [state, setState] = useState<{
        actions: {
            id: string
            eventType: string
            name: string
        }[]
        events: {
            id: string
            key: string[]
            name: string
        }[]
    }>({
        actions: getDefaultActions(),
        events: getDefaultEvents(),
    })
    const actionsRef = useRef<any>(null)
    const eventsRef = useRef<any>(null)

    // shouldComponentUpdate(nextProps) {
    //     const p_ = this.props
    //
    //     if (nextProps.open && p_.open !== nextProps.open) {
    //         if (nextProps.type === 'content') {
    //             this.getContentActions(nextProps)
    //         }
    //         if (nextProps.type === 'page') {
    //             this.getPageEvents(nextProps)
    //         }
    //     }
    //
    //     return true
    // }

    const getContentActions = (p_: ITouchEditorProps) => {
        let actions = getDefaultActions()

        if (p_.content.actionsOnContent) {
            if (!p_.action) {
                actions = actions.filter((action) => getContentAction(p_.content, action))
            } else {
                actions = actions.filter((action) => action.id === p_.action.id)
            }
        }
        setState((prevState) => {
            return {
                ...prevState,
                actions,
            }
        })
    }
    const getPageEvents = (p_: ITouchEditorProps) => {
        let events: any = getDefaultEvents()

        if (p_.page.playOnEvent) {
            if (p_.event) {
                events = events.filter((event: any) => event.id === p_.event.id && event.key.includes(p_.event.key))
            } else {
                events = events.filter((event: any) => getPageEvent(event))
            }
        }

        setState((prevState) => {
            return {
                ...prevState,
                events,
            }
        })
    }
    const getPageEvent = (event: {
        id: string
        eventType: string
        key: string
        eventCondition: { key: string; value: string }
    }) => {
        if (!p_.page.playOnEvent) {
            return true
        }

        const findExistEvent:
            | {
                  id: string
                  eventType: string
                  key: string
                  eventCondition: { key: string; value: string }
              }
            | undefined = p_.page.playOnEvent.events.find(
            (existEvent: any) => existEvent.eventType === event.id && event.key.includes(existEvent.eventCondition.key)
        )
        return findExistEvent === undefined
    }
    const getContentEvent = (content: { actionsOnContent: { eventType: string }[] }, eventType: string) => {
        return content.actionsOnContent.find((event) => event.eventType === eventType)
    }
    const getContentAction = (
        content: { actionsOnContent: { eventType: string }[] },
        action: { id: string; eventType: string }
    ) => {
        const event: any = getContentEvent(content, action.eventType)

        if (!event) {
            return true
        }

        return (
            event.actions.find((eventAction: { actionType: string }) => eventAction.actionType !== action.id) !==
            undefined
        )
    }
    const onClose = () => {
        p_.onClose()
    }
    const onSave = () => {
        if (p_.type === 'content') {
            onSaveAction()
        }

        if (p_.type === 'page') {
            onSaveEvent()
        }
    }
    const onSaveEvent = () => {
        if (!eventsRef.current && !eventsRef.current.onsave) return
        const data = eventsRef.current.onSave()

        if (!data) {
            return
        }

        if (p_.event) {
            p_.onSave(data, p_.event.eventIndex)
            return
        }

        p_.onSave(data)
    }
    const onSaveAction = () => {
        if (!actionsRef.current && !actionsRef.current.onsave) return

        const data = actionsRef.current.onSave()

        if (!data) {
            return
        }

        if (p_.action) {
            p_.onSave(data, p_.action.actionIndex)
            return
        }

        p_.onSave(data)
    }

    return {
        state,
        setState,
        actionsRef,
        eventsRef,
        onSave,
    }
}

export default TouchEditorLocalMethods
