import { addStyles } from 'ethcss'
import { IStyles } from '../../../../theme'

const displaysMapStyles: IStyles = {
    map: {
        height: '100vh',
    },
}

addStyles(displaysMapStyles)
export default displaysMapStyles
