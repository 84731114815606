import { addStyles } from 'ethcss'
import { grid, IStyles } from '../../../../theme'
import { radiusSizes } from 'blocks.app/config'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'

const dashboardSessionsItemStyles: IStyles = {
    item: {
        ...grid.mb_md,
        backgroundColor: `var(--var-module-app-component-app-style-background, ${defaultThemeStyles.background})`,
        borderRadius: radiusSizes.mini,

        '&:last-child': {
            marginBottom: 0,
        },
    },
    itemForThemes: {
        ...grid.mb_md,
        backgroundColor: 'white',
        borderRadius: radiusSizes.mini,

        '&:last-child': {
            marginBottom: 0,
        },
    },
    line: {
        ...grid.mb_micro,
        lineHeight: 1.3,

        '&:last-child': {
            marginBottom: 0,
        },
    },
    title: {
        fontWeight: 'bold',
    },
}

addStyles(dashboardSessionsItemStyles)
export default dashboardSessionsItemStyles
