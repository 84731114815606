import moment, { Moment } from 'moment'
import config from 'core/config'
import { ISchedule, IScheduleDate } from 'core/models/Schedule'
import { Device } from '../../../pages/schedule/_add/schedule_add.local.methods'

export default {
    getSchedule(schedule: ISchedule, devices: Device[]) {
        let endDate

        if (schedule.repeatMode === 'forever') {
            endDate = moment(schedule.startDate).add(100, 'years').format('YYYY-MM-DD')
        } else {
            endDate = moment(schedule.endDate).format('YYYY-MM-DD')
        }

        const startDate = moment(schedule.startDate).format('YYYY-MM-DD')
        const startDateLocal = moment(`${startDate} ${schedule.startTime}`, 'YYYY-MM-DD HH:mm:ss')
        const endDateLocal = moment(`${endDate} ${schedule.endTime}`, 'YYYY-MM-DD HH:mm:ss')
        const startDateUTC = moment(`${startDate} ${schedule.startTime}`, 'YYYY-MM-DD HH:mm:ss').utc()
        const endDateUTC = moment(`${endDate} ${schedule.endTime}`, 'YYYY-MM-DD HH:mm:ss').utc()

        let scheduleRepeatDays: IScheduleDate[] | number[]
        let localScheduleRepeatDays: IScheduleDate[] | number[]

        switch (schedule.repeatMode) {
            case 'exact_dates': {
                const repeatDays = [...(schedule.repeatDays as Moment[])].sort(function (a, b) {
                    return a.valueOf() - b.valueOf()
                })

                scheduleRepeatDays = repeatDays.map((day) => ({
                    startDate: moment(`${day.format('YYYY-MM-DD')} ${schedule.startTime}`)
                        .utc()
                        .format('YYYY-MM-DD'),
                    endDate: moment(`${day.format('YYYY-MM-DD')} ${schedule.endTime}`)
                        .utc()
                        .format('YYYY-MM-DD'),
                    startTime: startDateUTC.format('HH:mm:ss'),
                    endTime: endDateUTC.format('HH:mm:ss'),
                }))

                localScheduleRepeatDays = repeatDays.map((day) => ({
                    startDate: moment(`${day.format('YYYY-MM-DD')} ${schedule.startTime}`).format('YYYY-MM-DD'),
                    endDate: moment(`${day.format('YYYY-MM-DD')} ${schedule.endTime}`).format('YYYY-MM-DD'),
                    startTime: startDateLocal.format('HH:mm:ss'),
                    endTime: endDateLocal.format('HH:mm:ss'),
                }))

                break
            }
            default: {
                scheduleRepeatDays = [...schedule.repeatDays] as number[]
                localScheduleRepeatDays = [...schedule.repeatDays] as number[]
            }
        }

        return {
            localStartTime: startDateLocal.format('HH:mm:ss'),
            localEndTime: endDateLocal.format('HH:mm:ss'),
            localStartDate: startDateLocal.format('YYYY-MM-DD'),
            localEndDate: schedule.repeatMode === 'forever' ? null : endDateLocal.format('YYYY-MM-DD'),
            localRepeatDays: localScheduleRepeatDays,
            timeToUse: schedule.timeToUse,
            startTime: startDateUTC.format('HH:mm:ss'),
            endTime: endDateUTC.format('HH:mm:ss'),
            startDate: startDateUTC.format('YYYY-MM-DD'),
            endDate: schedule.repeatMode === 'forever' ? null : endDateUTC.format('YYYY-MM-DD'),
            repeatMode: schedule.repeatMode,
            repeatDays: scheduleRepeatDays,
            digitalSignageId: devices.map((device) => device.id),
            removeOverlappingSchedules: schedule.removeOverlappingSchedules,
            priority: schedule.priority,
        }
    },
    formatRepeatDays(repeatDays: number[], startDate: string) {
        const localDate = moment(startDate).local().isoWeekday()
        const UTCDate = moment.utc(startDate).isoWeekday()
        const result = localDate - UTCDate

        return repeatDays.map((day) => {
            let newDay = day

            if (result === 1 || result === -6) {
                if (day === 1) {
                    newDay = 7
                } else {
                    newDay = day - 1
                }
            } else if (result === -1 || result === 6) {
                if (day === 7) {
                    newDay = 1
                } else {
                    newDay = day + 1
                }
            }
            return newDay
        })
    },
    revertFormatRepeatDays(repeatDays: number[], startDate: string) {
        const localDate = moment(startDate).local().isoWeekday()
        const UTCDate = moment.utc(startDate).isoWeekday()
        const result = localDate - UTCDate

        return repeatDays.map((day) => {
            let newDay = day

            if (result === -1 || result === 6) {
                if (day === 1) {
                    newDay = 7
                } else {
                    newDay = day - 1
                }
            } else if (result === 1 || result === -6) {
                if (day === 7) {
                    newDay = 1
                } else {
                    newDay = day + 1
                }
            }
            return newDay
        })
    },
    getDownloadLink(selectedList: any, params: string) {
        let downloadLink = null
        const list = selectedList
        if (list.length > 0) {
            downloadLink = `${config.api.root}v2/download/schedule?${params}`

            const schedules = ['']
            list.forEach((date: any) => {
                schedules.push(date.id)
            })

            const uniqArray = Array.from(new Set(schedules))
            downloadLink += uniqArray.join('&scheduleId[]=')
        }

        return downloadLink
    },
    fullHoursMinutesFormat(time: string) {
        const valueConvert = time.split(':')
        return (valueConvert[0].length === 2 ? '' : '0') + valueConvert[0] + ':' + valueConvert[1]
    },
    startOf(value?: string, type = 'day', format = 'HH:mm:ss') {
        let date = moment()
        if (value) {
            date = moment(value)
        }
        return date.startOf(type as moment.unitOfTime.StartOf).format(format)
    },
    endOf(value?: string, type = 'day', format = 'HH:mm:ss') {
        let date = moment()
        if (value) {
            date = moment(value)
        }

        return date.endOf(type as moment.unitOfTime.StartOf).format(format)
    },
    getPlatformRestrictionsLabel(platform: string, version: string) {
        if (!platform) {
            return ''
        }

        let platformString = platform

        if (version) {
            platformString += ` v.${version}`
        }

        return platformString
    },
    getTypes() {
        return {
            groups: 'groupId',
            digitalSignages: 'digitalSignageId',
            broadcasts: 'broadcastId',
            broadcastFolders: 'broadcastFolderId',
        }
    },
    getListByType() {
        return {
            groups: 'displays',
            digitalSignages: 'displays',
            broadcasts: 'broadcasts',
            broadcastFolders: 'broadcasts',
        }
    },
    getInitScheduleData() {
        const types = this.getTypes()
        const scheduleData: { [key: string]: [] } = {}

        for (let type in types) {
            if (types.hasOwnProperty(type)) {
                scheduleData[type] = []
            }
        }

        return scheduleData
    },
    getLocalStartEndDate({
        localStartDate,
        localEndDate,
        localStartTime,
        localEndTime,
    }: {
        localStartDate: string
        localEndDate: string | null
        localStartTime: string
        localEndTime: string
    }) {
        return {
            startDate: moment(`${localStartDate} ${localStartTime}`, 'YYYY-MM-DD HH:mm:ss'),
            endDate: moment(`${localEndDate} ${localEndTime}`, 'YYYY-MM-DD HH:mm:ss'),
            startTime: localStartTime,
            endTime: localEndTime,
        }
    },
    getUTCStartEndDate({ startDate, endDate, startTime, endTime }: IScheduleDate) {
        return {
            startDate: moment.utc(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm:ss'),
            endDate: moment.utc(`${endDate} ${endTime}`, 'YYYY-MM-DD HH:mm:ss'),
            startTime,
            endTime,
        }
    },
    /**
     * [getLocalDateFromLocalRepeatDays method for get local date format fields from localRepeatDays]
     * @method getLocalDateFromLocalRepeatDays
     * @param  object with strings of startDate, endDate, startTime, endTime
     * @return object with { localStartDate, localEndDate, localStartTime, localEndTime}
     */
    getLocalDateFromLocalRepeatDays({ startDate, endDate, startTime, endTime }: IScheduleDate) {
        return {
            localStartDate: startDate,
            localEndDate: endDate,
            localStartTime: startTime,
            localEndTime: endTime,
        }
    },
}

export const getDispositionWhiteList = () => {
    return ['month', 'day', 'week']
}
