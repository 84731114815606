import { addStyles } from 'ethcss'
import './AudioPlaceholder.css'
import { IStyles, grid } from 'theme'
import {defaultThemeStyles} from './AudioPlaceholder-theme'

export const audioPlaceholderStyles: IStyles = {
    AudioPlaceholder: {
        ...grid.flex,
        ...grid.justify,
        ...grid.normalEnd,
        '& span': {
            backgroundColor: `var(--var-module-audio-component-playground-style-background, ${defaultThemeStyles.background})`,
            height: '60%',
            width: '18%',
            transition: '.5s',
            animation: `equalizeAnimation 4s 0s infinite`,
            animationTimingFunction: 'linear',
        },
        '& span:nth-child(1)': {
            animationDelay: '-1.9s',
        },
        '& span:nth-child(2)': {
            animationDelay: '-2.9s',
        },
        '& span:nth-child(3)': {
            animationDelay: '-3.9s',
        },
        '& span:nth-child(4)': {
            animationDelay: '-4.9s',
        },
    },
}

addStyles(audioPlaceholderStyles)
