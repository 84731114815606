import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import settingsStyles from '../__areaSettings/editor__areaSettings.jcss'
import {defaultThemeStyles as defaultThemeSidebarStyles} from 'blocks.app/sidebar/Sidebar-theme'

const editorBroadcastSettings = {
    wrapper: {
        ...grid.col,
        ...grid.justify,
        ...grid.full,
    },
    inputWrapper: {
        ...grid.w75,
        ...grid.mt_mdPlus,
    },
    input: {
        textAlign: 'center',
    },
    buttons: {
        ...grid.row,
        ...grid.center,
        ...grid.p_md,
    },
    switchArrows: {
        ...grid.colCenter,
        ...grid.mt_mdPlus,
        width: 65,
        height: 65,
        border: `1px solid var(--var-module-sidebar-component-sidebar-style-borderElement, ${defaultThemeSidebarStyles.borderElement})`,
        borderRadius: '50% !important'
    },
    orientation: {
        ...settingsStyles.title,
        borderTop: `1px solid var(--var-module-sidebar-component-sidebar-style-border, ${defaultThemeSidebarStyles.border})`,
        ...grid.fullWidth,
        ...grid.mt_big,
        ...grid.mb_none,
        color: `var(--var-module-sidebar-component-sidebar-style-text, ${defaultThemeSidebarStyles.text})`,
    },
    orientationTitle: {
        color: `var(--var-module-sidebar-component-sidebar-style-title, ${defaultThemeSidebarStyles.title})`,
    },
}

addStyles(editorBroadcastSettings)
export default editorBroadcastSettings
