import React from 'react'

import LineH from 'blocks.simple/line/_h/line_h'
import LineV from 'blocks.simple/line/_v/line_v'

import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { layers } from 'blocks.app/config'
import { cn } from 'ethcss'

class EditorLines extends React.Component {
    render() {
        const p_ = this.props

        return (
            <div>
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: -p_.scrollLeft,
                        width: '100%',
                        zIndex: layers.sticky + 1
                    }}
                    className={cn(grid.pl_mdPlus, grid.pr_mdPlus)}
                >
                    <div
                        className={item.rel}
                        style={{
                            width: `${100 * p_.zoom}%`
                        }}
                    >
                        <LineH
                            indents={'none'}
                            scaleLength={p_.resolutionWidth}
                            showLastStep={true}
                            size={p_.screenWidth}
                            showMovablePoint={p_.mouseIn}
                            onMouseDown={p_.onAddHLine}
                        />
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: -p_.scrollLeft,
                        width: '100%',
                        zIndex: layers.sticky + 1
                    }}
                    className={cn(grid.pl_mdPlus, grid.pr_mdPlus)}
                >
                    <div
                        className={item.rel}
                        style={{
                            width: `${100 * p_.zoom}%`
                        }}
                    >
                        <LineH
                            indents={'none'}
                            scaleLength={p_.resolutionWidth}
                            position={'bottom'}
                            smallStepsPosition={'top'}
                            size={p_.screenWidth}
                            onMouseDown={p_.onAddHLine}
                        />
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: -p_.scrollTop,
                        height: '100%',
                        zIndex: layers.sticky + 1
                    }}
                    className={cn(grid.pt_md, grid.pb_md)}
                >
                    <div
                        className={item.rel}
                        style={{
                            height: `${100 * p_.zoom}%`
                        }}
                    >
                        <LineV
                            indents={'none'}
                            scaleLength={p_.resolutionHeight}
                            showLastStep={true}
                            size={p_.screenHeight}
                            showMovablePoint={p_.mouseIn}
                            onMouseDown={p_.onAddVLine}
                        />
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        right: 0,
                        top: -p_.scrollTop,
                        height: '100%',
                        zIndex: layers.sticky + 1
                    }}
                    className={cn(grid.pt_md, grid.pb_md)}
                >
                    <div
                        className={item.rel}
                        style={{
                            height: `${100 * p_.zoom}%`
                        }}
                    >
                        <LineV
                            indents={'none'}
                            scaleLength={p_.resolutionHeight}
                            showLastStep={true}
                            position={'right'}
                            smallStepsPosition={'left'}
                            size={p_.screenHeight}
                            onMouseDown={p_.onAddVLine}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default EditorLines
