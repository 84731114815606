import { addStyles } from 'ethcss'
import { grid, text } from 'theme'
import { defaultThemeStyles } from '../../Roles-theme'

const createRoleSettingsStyles = {
    createRoleSettingsHeaderWrapper: {
        ...grid.mt_mdPlus,
        ...grid.p_mdPlus,
        color: `var(--var-module-roles-component-roles-style-headerText, ${defaultThemeStyles.headerText})`,
        letterSpacing: '0.1em',
        borderBottom: `1px solid var(--var-module-roles-component-roles-style-tableBorder, ${defaultThemeStyles.tableBorder})`,
    },
    createRoleSettingsHeader: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
        ...text.up,
    },
    // Aditional rules styles
    createRoleSettingsAdditionalRulesWrapper: {
        ...grid.row,
        ...grid.w80,
        ...grid.mt_md,
    },
    // Aditional rules checkbox
    createRoleSettingsAdditionalRulesCheckboxWrapper: {
        ...grid.p_mini,
    },
    createRoleSettingsAdditionalRulesCheckbox: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.noWrap,
        cursor: 'pointer',
        '& div::first-letter': {
            ...text.up,
        },
    },
}

addStyles(createRoleSettingsStyles)
export default createRoleSettingsStyles
