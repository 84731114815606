import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { textSizes } from 'theme/media'
import { IStyles } from '../../../theme'

const deviceSettingsSyncContent: IStyles = {
    title: {
        fontSize: textSizes.normal,
    },
    description: {
        fontSize: textSizes.mini,
        ...grid.mt_mini,
    },
    radioButtons: {
        ...grid.row,
        ...grid.mt_md,
        ...grid.mb_md,
    },
    radio: {
        ...grid.mr_md,
    },
}

addStyles(deviceSettingsSyncContent)
export default deviceSettingsSyncContent
