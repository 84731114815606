import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { radiusSizes } from 'blocks.app/config'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { textSizes } from 'theme/media'
import { IStyles } from '../../theme'

const addScheduleStyles: IStyles = {
    stepsMargin: {
        marginBottom: '24px',
        borderRadius: '5px !important',
    },
    wrapper: {
        ...grid.col,
        ...grid.space,
    },
    content: {
        ...grid.col,
        ...grid.space,
        minHeight: 600,
    },
    title: {
        ...grid.p_mini,
        fontSize: textSizes.md,
        ...item.white,
    },
    listWrapper: {
        ...grid.p_md,
        ...grid.mb_micro,
        ...grid.col,
        ...item.white,
    },
    whiteBlock: {
        ...grid.mt_micro,
        ...item.white,
        '&:last-child': {
            ...grid.mb_micro,
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10,
        },
    },
    breadcrumbs: {
        ...grid.mb_md,
    },
    stat: {
        textAlign: 'center',
    },
    statTitle: {
        ...grid.mb_mini,
        fontSize: 85,
        lineHeight: '85px',
        fontWeight: 'bold',
    },
    statSmTitle: {
        fontSize: 56,
    },
    tabs: {
        ...grid.row,
        ...item.white,
        ...item.overNone,
        borderRadius: `${radiusSizes.mini}px ${radiusSizes.mini}px 0 0`,
    },
    modalWrapper: {
        ...grid.full,
        ...grid.rowCenter,
    },
    modalInner: {
        ...item.card,
        ...item.rounded_full_micro,
        ...grid.p_none,
    },
    modalTitle: {
        ...grid.p_md,
        ...grid.rowCenter,
        textTransform: 'uppercase',
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    buttons: {
        ...grid.row,
        ...grid.center,
        ...grid.pt_big,
        ...grid.pb_mdPlus,
    },
    borderTable: {
        '& th': {
            borderTop: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
        },
    },
    deviceCountWarning: {
        marginTop: '24px',
    },
}

addStyles(addScheduleStyles)
export default addScheduleStyles
