import digitalSignagesTemplate from './_digitalSignages'
import broadcastsTemplate from './_broadcasts'
import filesTemplate from './_files'

const treeViewCatalogTemplates = {
    digitalSignages: digitalSignagesTemplate,
    broadcasts: broadcastsTemplate,
    files: filesTemplate,
}

export default (type) => treeViewCatalogTemplates[type]
