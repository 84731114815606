import { IUser } from 'core/models/Users'
import store from 'core/store'

/**
 * 🔥 Все типы каталогов, которые используются для вывода в древовидной структуре
 */
export type TTreeViewTypeInstances =
    | 'digitalSignages'
    | 'files'
    | 'broadcasts'
    | 'displays'
    | 'scoreboards'
    | 'parentId'

/**
 * 🔥 Стандартная модель query объекта от роутера
 */
interface Query {
    [key: string]: string | number | undefined
}

/**
 * ❗ Возвращает строку для параметра в url
 * @param type - тип каталога в древовидном списке
 * @param query - параметр (query, sort, filter и т.д)
 */

const TREE_VIEW_USER_SETTINGS_PREFIX = 'TreeView'

export const prefixQuery = (type: TTreeViewTypeInstances, query: string): string => `${type}_treeview_${query}`

/**
 * ❗ Парсит параметры с url с учетом параметров для древовидного списка
 * @param type - тип каталога в древовидном списке
 * @param query - объект query от react-router (location)
 */
export const getQuery = (type: TTreeViewTypeInstances, query: Query): Query => {
    const parsedQuery: Query = {}

    for (let key in query) {
        if (query.hasOwnProperty(key) && key.includes(type)) {
            const parsedKey = key.replace(`${type}_treeview_`, '')

            parsedQuery[parsedKey] = query[key]
        }
    }

    return parsedQuery
}

/**
 * ❗ Возвращает ключ для id группы/папки в зависимости от типа каталога
 * @param type - тип каталога в древовидном списке
 */
export const getNestedFolderKey = (type: TTreeViewTypeInstances): string => {
    switch (type) {
        case 'digitalSignages':
            return 'groupId'
        case 'scoreboards':
            return 'ledControllerFolderId'
        default:
            return 'folderId'
    }
}

/**
 * ❗ Возвращает массив объектов с вспомогательным полем для рендера древовидной структуры
 * @param array - массив списка с сущностями (файлы, экраны, трансляции)
 * @param type - тип каталога в древовидном списке
 */

export const addParentKeyIdForTree = (array: any[], type: TTreeViewTypeInstances): any[] => {
    const key = getNestedFolderKey(type)

    return array.map((item) => {
        item.nestedKey = key

        return item
    })
}

/**
 * Возвращает ключ настроек в модели пользователя для древовидного списка
 * @param type - тип каталога в древовидном списке
 */

export const getUserSettingWithPrefix = (type: TTreeViewTypeInstances) => type + TREE_VIEW_USER_SETTINGS_PREFIX

/**
 * ❗ Возвращает объект с параметрами из настроек пользователя
 * @param type - тип каталога в древовидном списке
 */
export const getViewParamsByUser = (type: TTreeViewTypeInstances, user: IUser) => {
    const settings = user.settings
    const keyWithPrefix = getUserSettingWithPrefix(type)

    const parameters = {
        sort: settings.sort[keyWithPrefix],
    }

    return parameters
}
