import React from 'react'
import InputMask from 'react-input-mask'

const sizerStyle: { [index: string]: string | number } = {
    position: 'absolute',
    top: 0,
    left: 0,
    visibility: 'hidden',
    height: 0,
    overflow: 'scroll',
    whiteSpace: 'pre',
}

interface IDropdownSearchable {
    id?: string
    className: string // className for the outer element
    defaultValue?: any // default field value
    inputClassName: string // className for the input element
    inputStyle?: {} // css styles for the input element
    minWidth: number | string
    onAutosize?: any // onAutosize handler: function(newWidth) {}
    onChange: (value: any) => void // onChange handler: function(newValue) {}
    placeholder?: string // placeholder text
    placeholderIsMinWidth?: boolean // don't collapse size to less than the placeholder
    style: any // css styles for the outer element
    value: {} // field value
    wrapperWidth?: boolean
    mask?: string
    placeholderText?: string | null
    disabled?: boolean
    onFocus?: (value: any) => void
    onBlur?: (value: any) => void
    type?: string
}

interface IState {
    inputWidth: string | number
}

class DropdownSearchable extends React.Component<IDropdownSearchable> {
    static defaultProps: { minWidth: number }
    mounted: boolean = false
    state: IState
    sizer: any
    placeHolderSizer: any
    input: any

    constructor(p_: IDropdownSearchable) {
        super(p_)
        this.state = {
            inputWidth: p_.minWidth,
        }

        this.inputRef = this.inputRef.bind(this)
        this.sizerRef = this.sizerRef.bind(this)
    }
    componentDidMount() {
        this.mounted = true
        this.copyInputStyles()
    }
    componentDidUpdate(prevProps: IDropdownSearchable, prevState: IState) {
        if (prevState.inputWidth !== this.state.inputWidth) {
            if (typeof this.props.onAutosize === 'function') {
                this.props.onAutosize(this.state.inputWidth)
            }
        }
        this.updateInputWidth()
    }
    componentWillUnmount() {
        this.mounted = false
    }
    inputRef(el: React.ReactHTMLElement<HTMLInputElement>) {
        this.input = el
    }
    placeHolderSizerRef(el: React.ReactHTMLElement<HTMLInputElement>) {
        this.placeHolderSizer = el
    }
    sizerRef(el: any) {
        this.sizer = el
    }
    copyInputStyles() {
        if (this.mounted || !window.getComputedStyle) {
            return
        }
        const inputStyle = this.input && window.getComputedStyle(this.input)
        if (!inputStyle) {
            return
        }
        const widthNode = this.sizer
        widthNode.style.fontSize = inputStyle.fontSize
        widthNode.style.fontFamily = inputStyle.fontFamily
        widthNode.style.fontWeight = inputStyle.fontWeight
        widthNode.style.fontStyle = inputStyle.fontStyle
        widthNode.style.letterSpacing = inputStyle.letterSpacing
        widthNode.style.textTransform = inputStyle.textTransform
        if (this.props.placeholder) {
            const placeholderNode = this.placeHolderSizer
            placeholderNode.style.fontSize = inputStyle.fontSize
            placeholderNode.style.fontFamily = inputStyle.fontFamily
            placeholderNode.style.fontWeight = inputStyle.fontWeight
            placeholderNode.style.fontStyle = inputStyle.fontStyle
            placeholderNode.style.letterSpacing = inputStyle.letterSpacing
            placeholderNode.style.textTransform = inputStyle.textTransform
        }
    }
    updateInputWidth() {
        if (!this.mounted || !this.sizer || typeof this.sizer.scrollWidth === 'undefined') {
            return
        }
        let newInputWidth
        if (this.props.placeholder && (!this.props.value || (this.props.value && this.props.placeholderIsMinWidth))) {
            newInputWidth = Math.max(this.sizer.scrollWidth, this.placeHolderSizer.scrollWidth) + 2
        } else {
            newInputWidth = this.sizer.scrollWidth + 2
        }
        if (newInputWidth < this.props.minWidth) {
            newInputWidth = this.props.minWidth
        }
        if (newInputWidth !== this.state.inputWidth) {
            this.setState({
                inputWidth: newInputWidth,
            })
        }
    }
    getInput() {
        return this.input
    }
    focus() {
        this.input.focus()
    }
    blur() {
        this.input.blur()
    }
    select() {
        this.input.select()
    }
    render() {
        const p_ = this.props
        const sizerValue = [this.props.defaultValue, this.props.value, ''].reduce(function (
            previousValue,
            currentValue
        ) {
            if (previousValue !== null && previousValue !== undefined) {
                return previousValue
            }
            return currentValue
        })

        const wrapperStyle: { [index: string]: string } = this.props.style || {}
        if (!Object.keys(wrapperStyle).length) wrapperStyle.display = 'inline-block'
        const inputStyle: any = Object.assign({}, this.props.inputStyle)
        if (!this.props.wrapperWidth) {
            inputStyle.width = this.state.inputWidth + 'px'
        } else {
            inputStyle.width = '100%'
        }
        inputStyle.boxSizing = 'content-box'
        const inputProps: any = Object.assign({}, this.props)
        inputProps.className = this.props.inputClassName
        inputProps.style = inputStyle
        inputProps.placeholder = this.props.placeholderText
        // ensure props meant for `AutosizeInput` don't end up on the `input`
        delete inputProps.inputClassName
        delete inputProps.inputStyle
        delete inputProps.minWidth
        delete inputProps.onAutosize
        delete inputProps.placeholderIsMinWidth
        delete inputProps.wrapperWidth
        delete inputProps.placeholderText
        return (
            <div className={this.props.className} style={wrapperStyle}>
                {!p_.mask && <input {...inputProps} ref={this.inputRef} autoComplete={'off'} />}
                {p_.mask && <InputMask mask={p_.mask} {...inputProps} inputRef={this.inputRef} autoComplete={'off'} />}
                <div ref={this.sizerRef} style={sizerStyle}>
                    {sizerValue}
                </div>
            </div>
        )
    }
}

DropdownSearchable.defaultProps = {
    minWidth: 1,
}

export default DropdownSearchable
