/**
 * 🔥 Типы отображения для текстового контента
 */
export enum TEXT_DISPLAY_AS {
    DEFAULT = 'default',
    QR_CODE = 'qr_code',
}

/**
 *  TODO Добавить типы файлов
 */
export type TFileType = 'video' | 'audio' | 'web_app' | 'web_page' | 'office'

export interface IFileSourceData {
    duration: number
    extension: string
    fileHash: string
    fileName: string
    fileSize: number
    fileURL: string
    torrentFileURL: string
    type: TFileType
    uniqueName: string
}

export interface IFileSource {
    data: IFileSourceData
    fileType: TFileType
    type: string
}

export interface IFile {
    id: number
    folderId: number
    isDirectory: boolean
}

export interface IUploadFile {
    id: string
    file: any
    folderId: string
    cancel?: () => void
    broadcast?: boolean
    withConversion?: string
}

export interface IFont {}
