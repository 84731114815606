import { addStyles } from 'ethcss'
import { mediaCSS, indents, grid, item, text } from 'theme'

const profileStyles = {
    profileWrapper: {
        ...grid.colCenter,
        ...grid.w50,
        [mediaCSS.tab]: {
            ...grid.w80,
        },
        ...item.rounded_full_micro,
        ...item.rel,
        ...item.white,
        ...item.shadowMd,
        ...item.overNone,
        maxHeight: '100%',
        [mediaCSS.tabMini]: {
            ...grid.full,
            borderRadius: `0px!important`,
        },
    },
    profileContent: {
        ...grid.row,
        ...grid.full,
        ...item.overNone,
        ...item.overY,
    },
    profileCloseIcon: {
        ...item.abs,
        top: indents.md,
        right: indents.md,
    },
    profileAcceptDialogText: {
        ...grid.m_mdPlus,
        ...text.up,
        ...text.center,
        letterSpacing: '0.1em',
    },
}

addStyles(profileStyles)
export default profileStyles
