import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { mediaCSS } from 'theme/media'

const catalogTemplateFilesStyles = {
    wrapper: {
        ...grid.row,
        marginLeft: -grid.ml_mini['margin-left'],
        marginBottom: -grid.mb_mini['margin-bottom'],
        overflow: 'hidden',
    },
    cellWrapper: {
        ...grid.pb_mini,
        ...grid.pl_mini,
        ...item.transitionErase,
        position: 'relative',
    },
    mediaCellClassName: {
        [mediaCSS.tabMini]: {
            ...grid.col4,
        },
        [mediaCSS.mob]: {
            ...grid.col2,
        },
    },
    panelWrapper: {
        ...item.panel,
        borderTopLeftRadius: `0 !important`,
        borderTopRightRadius: `0 !important`,
    },
    tableWrapper: {
        borderTopLeftRadius: `0 !important`,
        borderTopRightRadius: `0 !important`,
        borderTop: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    rotateIconWrapper: {
        ...grid.mr_micro,
    },
    name: {
        ...grid.w100,
        ...grid.row,
        ...grid.noWrap,
        textAlign: 'left',
    },
    icon: {
        ...grid.mr_mini,
    },
}

addStyles(catalogTemplateFilesStyles)
export default catalogTemplateFilesStyles
