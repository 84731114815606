import React, { FunctionComponent } from 'react'
import { Tooltip } from 'atoms/Tooltip'
import { animated } from 'react-spring'

import { useIcon } from './Icon-hooks'

import { IIconComponent } from './Icon-types'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from './Icon-theme'

export const Icon: FunctionComponent<IIconComponent> = ({
    type,
    size = 16,
    color,
    disabled = false,
    style,
    rotate,
    tooltip,
    className,
    iconClassName,
    onClick,
}) => {
    let currentColor = color || getThemeStyleValue('ui', 'icon', 'main') || defaultThemeStyles.main
    const iconHook = useIcon({ type, color: currentColor, disabled, rotate, className, iconClassName, onClick })

    if (type && !iconHook.icon) {
        console.error(`Icon with name ${type} not found`)
        return null
    }

    if (!iconHook.icon) {
        return null
    }

    return (
        <animated.div
            style={{
                transform: iconHook.spring.x
                    .interpolate({
                        range: [0, 0.5, 1],
                        output: [1, 0.9, 1],
                    })
                    .interpolate((x: number) => `scale(${x})`),
            }}
            className={iconHook.classNames.wrapperClassName}
        >
            <Tooltip {...tooltip}>
                <svg
                    children={iconHook.icon.image}
                    fill={currentColor}
                    preserveAspectRatio="xMidYMid meet"
                    height={size}
                    width={size}
                    className={iconHook.classNames.iconClassName}
                    viewBox={iconHook.icon.viewBox}
                    style={{
                        verticalAlign: 'middle',
                        color: iconHook.iconColor,
                        ...style,
                    }}
                    onClick={iconHook.handleIconClick}
                />
            </Tooltip>
        </animated.div>
    )
}
