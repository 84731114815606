import React, { FunctionComponent } from 'react'
import { Dialog } from '../../molecules/Dialog'
import { Profile } from '../Profile'
import helpers from '../../core/helpers'
import { ProfileDialogProps } from './ProfileDialog-types'

import { mediaJS } from 'theme'
import styles from '../Profile/styles'

const ProfileDialog: FunctionComponent<ProfileDialogProps> = (p_) => {
    return (
        <Dialog
            open={p_.open}
            {...helpers.getPropertiesByMediaQueries(
                {
                    desktop: { padding: 'p_md' },
                    tabMini: { padding: 'p_none' },
                },
                mediaJS
            )}
            dialogInnerClassName={styles.profileWrapper}
            id="profile_dialog"
        >
            <Profile {...p_} />
        </Dialog>
    )
}

export { ProfileDialog }
