import React, { useEffect, useState } from 'react'
import Modal from 'blocks.simple/modal/modal'
import Catalog from 'blocks.app/catalog/catalog'
import Sidebar from 'blocks.app/sidebar/sidebar'
import Button from 'blocks.simple/button/button'
import { cn } from 'ethcss'
import deepEqual from 'fast-deep-equal'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import translate from 'core/translate'
import styles from './catalog_select_devices.jcss'

type SelectedItem = {
    id: number
    name: string
}
interface ICatalogSelectDevicesProps {
    selectedItems: SelectedItem[]
    onSelect: (data: number[]) => void
}

const CatalogSelectDevices = (p_: ICatalogSelectDevicesProps) => {
    const [open, setOpen] = useState<boolean>(false)
    const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([...p_.selectedItems])

    useEffect(() => {
        if (!deepEqual(p_.selectedItems, selectedItems)) {
            setSelectedItems(p_.selectedItems)
        }
    }, [p_])

    return (
        <div className={item.ellipsisWrapper}>
            <div className={item.ellipsis} onClick={() => setOpen(true)}>
                {selectedItems.map((dsItem: SelectedItem, index: number) => (
                    <span key={`dsItem_${dsItem.id}`}>
                        {dsItem.name}
                        {index < selectedItems.length - 1 ? ', ' : ''}
                    </span>
                ))}
                {selectedItems.length === 0 && <span>{translate('devicesEmpty')}</span>}
            </div>
            <Modal
                open={open}
                showCloseIcon={false}
                sidebars={{
                    right: (
                        <div className={styles.toolbar}>
                            <Sidebar
                                selectedSide={'right'}
                                onChange={(selectedItems: SelectedItem[]) => setSelectedItems(selectedItems)}
                                content={{
                                    right: {
                                        title: translate('devices'),
                                        options: selectedItems,
                                    },
                                }}
                                isEmpty={{
                                    right: selectedItems.length === 0,
                                }}
                                emptyText={{
                                    right: {
                                        title: translate('noDevicesSelected'),
                                    },
                                }}
                            />
                        </div>
                    ),
                }}
            >
                <div className={styles.content}>
                    <div className={grid.pb_mini}>{translate('devicesEmpty')}</div>
                    <Catalog
                        selectedItems={selectedItems}
                        onSelect={(selectedItems: SelectedItem[]) => setSelectedItems(selectedItems)}
                        type={'displays'}
                        searchbarMod={'grey'}
                        useWindow={false}
                        cols={4}
                        disableFolderSelection={true}
                    />
                    <div className={styles.buttons}>
                        <Button
                            className={cn(grid.ml_micro, grid.mr_mini)}
                            indentSize={'mini'}
                            textSize={'mini'}
                            rounded={'full_normal'}
                            onClick={() => {
                                setSelectedItems(selectedItems)
                                setOpen(false)
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                        <Button
                            mod={'fill'}
                            indentSize={'mini'}
                            textSize={'mini'}
                            rounded={'full_normal'}
                            onClick={() => {
                                setOpen(false)
                                p_.onSelect(selectedItems.map((selectedItem: { id: number }) => selectedItem.id))
                            }}
                            disabled={selectedItems.length === 0}
                        >
                            {translate('save')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

CatalogSelectDevices.defaultProps = {
    selectedItems: [],
}

export default CatalogSelectDevices
