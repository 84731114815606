import React, { Fragment, FunctionComponent, memo, useState } from 'react'
import Collapse from 'blocks.simple/collapse/collapse'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { styles } from './DisplayOfWarningsSettings-styles'
import { SwitchButton } from 'atoms/SwitchButton'
import { RootStateOrAny, useDispatch } from 'react-redux'
import { updateUserSettings } from 'blocks.app/user/user.state'
import styles_ from '../settings.jcss'
import { useSelector } from 'react-redux'
import { grid } from 'theme'
import EditText from 'blocks.simple/editText/editText'
import { text as textStyles } from 'theme'

const DisplayOfWarningsComponent: FunctionComponent = () => {
    const dispatch = useDispatch()
    const title: string = translate('displayOfWarnings')
    const displayedTooltips = useSelector((store: RootStateOrAny) => store.user.data.settings.displayedTooltips)
    const passwordSecurity = useSelector((store: RootStateOrAny) => store.user.data.settings.passwordSecurity)
    const [passwordAlertInterval, setPasswordAlertInterval] = useState<number>(
        passwordSecurity &&
            passwordSecurity.outOfDateNotificationIntervalD &&
            passwordSecurity.outOfDateNotificationIntervalD
    )
    const deletePromptCheck = (): boolean => displayedTooltips && displayedTooltips.deleteFilePrompt === true
    const savePromptCheck = (): boolean => displayedTooltips && displayedTooltips.saveBroadcastPrompt === true
    const changePasswordAlertCheck = (): boolean =>
        passwordSecurity && passwordSecurity.outOfDateNotificationEnabledQ === true
    const isCheckDeleteWarning = (): boolean => {
        if (deletePromptCheck()) {
            return false
        } else {
            return true
        }
    }

    const isCheckSaveWarning = (): boolean => {
        if (savePromptCheck()) {
            return false
        } else {
            return true
        }
    }

    const isCheckChangePasswordAlert = (): boolean => {
        if (changePasswordAlertCheck()) {
            return true
        } else {
            return false
        }
    }

    const onChangeDeleteWarning = (): void => {
        deletePromptCheck()
            ? dispatch(
                  updateUserSettings({ data: { displayedTooltips: { deleteFilePrompt: false } }, isSaveSettings: true })
              )
            : dispatch(
                  updateUserSettings({ data: { displayedTooltips: { deleteFilePrompt: true } }, isSaveSettings: true })
              )
    }

    const onChangeSaveWarning = (): void => {
        savePromptCheck()
            ? dispatch(
                  updateUserSettings({
                      data: { displayedTooltips: { saveBroadcastPrompt: false } },
                      isSaveSettings: true,
                  })
              )
            : dispatch(
                  updateUserSettings({
                      data: { displayedTooltips: { saveBroadcastPrompt: true } },
                      isSaveSettings: true,
                  })
              )
    }

    const onChangePasswordAlert = (): void => {
        changePasswordAlertCheck()
            ? dispatch(
                  updateUserSettings({
                      data: { passwordSecurity: { outOfDateNotificationEnabledQ: false } },
                      isSaveSettings: true,
                  })
              )
            : dispatch(
                  updateUserSettings({
                      data: { passwordSecurity: { outOfDateNotificationEnabledQ: true } },
                      isSaveSettings: true,
                  })
              )
    }
    const onChangeIntervalNotificationPasswordAlert = (value: number): void => {
        dispatch(
            updateUserSettings({
                data: { passwordSecurity: { outOfDateNotificationIntervalD: value } },
                isSaveSettings: true,
            })
        )
    }
    return (
        <Fragment>
            <Collapse title={() => title} className={cn(styles_.background, styles.DisplayOfWarningsSettings)}>
                <div className={styles.DisplayOfWarningsSettings__item}>
                    <div className={styles.DisplayOfWarningsSettings__text}>
                        {translate('showContentDeleteWarning')}
                    </div>
                    <div className={styles.DisplayOfWarningsSettings__button}>
                        <SwitchButton onChange={onChangeDeleteWarning} checked={isCheckDeleteWarning()} />
                    </div>
                </div>

                <div className={styles.DisplayOfWarningsSettings__item}>
                    <div className={styles.DisplayOfWarningsSettings__text}>
                        {translate('showBroadcastsSaveWarning')}
                    </div>
                    <div className={styles.DisplayOfWarningsSettings__button}>
                        <SwitchButton onChange={onChangeSaveWarning} checked={isCheckSaveWarning()} />
                    </div>
                </div>

                <div className={styles.DisplayOfWarningsSettings__item}>
                    <div className={styles.DisplayOfWarningsSettings__text}>{translate('enablePasswordAlert')}</div>
                    <div className={styles.DisplayOfWarningsSettings__button}>
                        <SwitchButton onChange={onChangePasswordAlert} checked={isCheckChangePasswordAlert()} />
                    </div>
                </div>

                {passwordSecurity && passwordSecurity.outOfDateNotificationEnabledQ && (
                    <div className={cn(styles.DisplayOfWarningsSettings__item)}>
                        <div className={cn(grid.pb_mini)}>{translate('PasswordNotificationFrequency')}:</div>
                        <EditText
                            tabindex={'0'}
                            className={cn(textStyles.center, styles.inactivitySettings__input_maxWidth)}
                            value={passwordAlertInterval}
                            indentSize={'mini'}
                            type={'number'}
                            mod="withLightBorder"
                            onChange={(value: number) => setPasswordAlertInterval(value)}
                            onBlur={() =>
                                passwordAlertInterval &&
                                onChangeIntervalNotificationPasswordAlert(passwordAlertInterval)
                            }
                        />
                    </div>
                )}
            </Collapse>
        </Fragment>
    )
}

export const DisplayOfWarnings = memo(DisplayOfWarningsComponent)
