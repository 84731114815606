import React from 'react'
import { Icon } from 'molecules/Icon'

import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'
import helpersFiles from 'core/helpers/_files'
import translate from 'core/translate'
import omitEmpty from 'omit-empty'

import styles from './styles'

export default {
    folderName: 'folderId',
    getMethod: 'getFilesAndFolders',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noFiles'),
    emptyIcon: 'not_allow',
    moveItem: (id, folderId) => api.send('updateFile', { id, folderId }),
    moveFolder: (id, folderId) => api.send('updateFolder', { id, folderId }),
    multipleMove: (sourceId, sourceFolderId, parentId) => {
        const data = omitEmpty({
            sourceId,
            sourceFolderId,
            parentId,
        })

        api.send('moveFilesAndFolders', data)
    },
    getDragData: (p_) => JSON.stringify(helpersFiles.getFileItem(p_.item)),
    settingsPrefixes: 'files',
    isOnlySearch: false,
    getBackendModel: (item) => {
        if (this.isFolder(item)) {
            return api.send('getFile', { id: item.id }, { hideLoader: true })
        }

        return api.send('getFolder', { folderId: item.id }, { hideLoader: true })
    },
    isEqual: (item, prevItem) => helpersFiles.isEqual({ item, prevItem }),
    onCreateListeners: [
        (cb, clearList) => api.addObserver('folderCreated', cb, clearList),
        (cb, clearList) => api.addObserver('fileCreated', cb, clearList),
    ],
    onUpdateListeners: [
        (cb, clearList) => api.addObserver('folderUpdated', cb, clearList),
        (cb, clearList) => api.addObserver('fileUpdated', cb, clearList),
    ],
    onDeleteListeners: [
        (callback, clearList) =>
            api.addObserver('folderDeleted', ({ id }) => callback({ id, isDirectory: 1 }), clearList),
        (callback, clearList) =>
            api.addObserver('fileDeleted', ({ id }) => callback({ id, isDirectory: 0 }), clearList),
    ],
    isFolder: (item) => item.isDirectory > 0,
    fields: () => [{ id: 'name', name: translate('name'), expanded: true }],
    sortOptions: () => [
        { value: '-createdAt', label: translate('sortCreatedAt') },
        { value: 'name', label: translate('sortName') },
        { value: '-size', label: translate('sortSize') },
    ],
    list: {
        name: (item, p_) => {
            let iconType = 'folder'

            if (item.fileType) {
                iconType = helpersFiles.getFileIconName(item)
            }

            return (
                <div className={styles.filesTemplateName}>
                    <Icon className={styles.filesTemplateIcon} type={iconType} size={30} color="grey" />
                    <div style={helpersFiles.convertStyles(item.style, { withoutStyles: ['fontSize'] })}>
                        {helpers.stripHtmlTags(item.name)}
                    </div>
                </div>
            )
        },
    },
}
