import { createSlice } from '@reduxjs/toolkit'

export interface ILoaderState {
    loadCounter: number
}

const initialState: ILoaderState = {
    loadCounter: 0,
}

const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        startLoading(state) {
            state.loadCounter = state.loadCounter + 1
        },
        endLoading(state) {
            const nextValue = state.loadCounter > 0 ? state.loadCounter - 1 : state.loadCounter
            state.loadCounter = nextValue
        },
        clearLoading(state) {
            state.loadCounter = 0
        },
    },
})

const { reducer: loaderReducer, actions: loaderActions } = loaderSlice
export { loaderReducer, loaderActions }
