import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { mediaCSS } from 'theme/media'
import { IStyles } from '../../../theme'

const deviceSettingsVideoPlayer: IStyles = {
    optionsWrapper: {
        ...grid.fullWidth,
    },
    textArea: {
        ...grid.row,
        ...grid.w25,
        [mediaCSS.tab]: {
            ...grid.w45,
        },
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    radioButtons: {
        ...grid.row,
        ...grid.mb_md,
        ...grid.fullWidth,
    },
}

addStyles(deviceSettingsVideoPlayer)
export default deviceSettingsVideoPlayer
