import { addStyles } from 'ethcss'
import { defaultThemeTableStyles } from 'atoms/Table/Table-theme'
import { IStyles } from '../../../../theme'

const catalogTemplateInstancesCatalogStyles: IStyles = {
    preview: {
        maxHeight: '100px',
        overflow: 'auto',
        width: '100%',
    },
    line: {
        width: '100%',
        border: '1px solid #d0c2c2',
        padding: '5px',
        borderRadius: '5px',
        marginBottom: '10px',
    },
    lineMainContent: {
        position: 'relative',
        '&:hover': {
            '& .delete-btn': {
                opacity: 1,
            },
        },
        '& .confirm-form': {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            background: '#fff',
            zIndex: 100,
            height: '60px',
        },
    },
    row: {
        display: 'flex !important',
        alignItems: 'center',
        marginBottom: '10px',
    },
    title: {
        color: '#000',
        marginRight: '10px',
        minWidth: '84px',
    },
    children: {
        position: 'relative',
        paddingLeft: '20px',
    },
    chLine: {
        position: 'absolute',
        top: '-20px',
        left: '20px',
        width: '1px',
        height: '20px',
        background: 'grey',
    },
    navPanel: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '10px',
    },
    addBtn: {
        marginLeft: '16px',
    },
    deleteBtn: {
        position: 'absolute',
        bottom: '20px',
        right: 0,
        opacity: 0,
        transition: 'opacity 0.3s',
    },
    confirmButton: {
        margin: '0 6px',
    },
    approveMessage: {
        marginBottom: '10px',
    },
    toggleBtn: {
        marginLeft: '16px',
    },
    createPanel: {
        display: 'flex',
        justifyContent: 'center',

        '& button': {
            margin: '0 6px',
        },
    },

    tableWrapper: {
        marginBottom: '64px',

        '& td > div': {
            justifyContent: 'flex-start',
        },
        '& td:first-child': {
            display: 'none',
        },
        '& th': {
            display: 'none',
        },
        '& input, textarea': {
            padding: '5px !important',
            //border: '1px solid grey !important',
        },

        '& textarea': {
            '&::-webkit-scrollbar': {
                borderRadius: 10,
                width: 6,
                background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                width: 6,
                background: '#b3b3b3',
            },
        },
        '& .rectangle': {
            width: '50px',
            height: '50px',
            borderRadius: '4px',
            overflow: 'hidden',
        },
        '& div[class^="tableCell_"]': {
            paddingBottom: '40px',
        },
        '& div[class^="item_"]': {
            width: 'auto',
        },
        '& div[class^="wrapperFull"]': {
            minWidth: '112px',
        },
    },
    selectDevices: {
        color: `var(--var-module-ui-component-table-style-selected, ${defaultThemeTableStyles['selected']})`,
    },
}

addStyles(catalogTemplateInstancesCatalogStyles)
export default catalogTemplateInstancesCatalogStyles
