import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { defaultThemeStyles } from './AnimationSettings-theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { IStyles, mediaCSS } from '../../theme'

const animationSettingsStyles: IStyles = {
    animationSettingsWrapper: {
        ...grid.row,
        color: `var(--var-module-editor-component-animation-style-text, ${defaultThemeStyles.text})`,
        borderTop: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,

        [mediaCSS.tab]: {
            display: 'block',
        },
    },
    col: {
        ...grid.p_md,
        color: `var(--var-module-editor-component-animation-style-text, ${defaultThemeStyles.text})`,
    },
    col1: {
        ...grid.w20,
        backgroundColor: `var(--var-module-editor-component-animation-style-col1, ${defaultThemeStyles.col1})`,

        [mediaCSS.tab]: {
            width: '100%',
        },
    },
    col2: {
        ...grid.w40,
        backgroundColor: `var(--var-module-editor-component-animation-style-col2, ${defaultThemeStyles.col2})`,

        [mediaCSS.tab]: {
            width: '100%',
        },
    },
    col3: {
        ...grid.w40,
        backgroundColor: `var(--var-module-editor-component-animation-style-col3, ${defaultThemeStyles.col3})`,

        [mediaCSS.tab]: {
            width: '100%',
        },
    },
    animationTypeItem() {
        return {
            ...grid.mb_mini,
            cursor: 'pointer',
            color: `var(--var-module-editor-component-animation-style-setting, ${defaultThemeStyles.setting})`,
            '&:hover': {
                color: `var(--var-module-editor-component-animation-style-settingActive, ${defaultThemeStyles.settingActive})`,
            },
        }
    },
    animationItemActive: {
        color: `var(--var-module-editor-component-animation-style-settingActive, ${defaultThemeStyles.settingActive})`,
    },
    animationViewItem() {
        return {
            ...grid.row,
            ...grid.normalCenter,
            ...grid.mb_mini,
            cursor: 'pointer',
            color: `var(--var-module-editor-component-animation-style-setting, ${defaultThemeStyles.setting})`,
            '&:hover': {
                color: `var(--var-module-editor-component-animation-style-settingActive, ${defaultThemeStyles.settingActive})`,
            },
        }
    },
    rect: {
        ...grid.full,
        ...grid.rowCenter,
        borderRadius: 4,
        backgroundColor: `var(--var-module-editor-component-animation-style-sampleBackground, ${defaultThemeStyles.sampleBackground})`,
    },
    fadeIn: {
        opacity: 0,
    },
}

addStyles(animationSettingsStyles)
export default animationSettingsStyles
