import React from 'react'
import translate from 'core/translate'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import { Typography } from 'atoms/Typography'
import { cn } from 'ethcss'
import styles from './deviceSettings__cover.jcss'

const DeviceSettingsCover = (p_: {
    value: boolean
    onChange: (value: any, field: string) => void
    enabled: boolean
}) => {
    function onChange(value: boolean) {
        p_.onChange(value, 'broadcastAutoScreenshot')
    }

    if (!p_.enabled) {
        return null
    }

    return (
        <div className={styles.wrapper}>
            <Typography className={cn(styles.title)} type="title">
                {translate('deviceCover')}
            </Typography>
            <div className={styles.checkbox}>
                <Checkbox onClick={onChange} checked={p_.value} label={translate('showScreenshotFromBroadcast')} />
            </div>
        </div>
    )
}

export default React.memo(DeviceSettingsCover)
