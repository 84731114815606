import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../../theme'
import { indents } from 'blocks.app/config'
import { mediaCSS, textSizes } from 'theme/media'

const filesEditTagsModalStyles: IStyles = {
    modalWrapper: {
        ...grid.full,
        ...grid.rowCenter,
        ...item.blackOpacity,
    },
    modalInner: {
        ...grid.colCenter,
        ...item.card,
        ...item.shadowModal,
        ...item.rounded_full_mini,
        ...item.rel,
        ...grid.w45,
        [mediaCSS.tab]: {
            ...grid.w65,
        },
        [mediaCSS.tabMini]: {
            ...grid.w75,
        },
        [mediaCSS.mob]: {
            ...grid.w90,
        },
    },
    closeIcon: {
        ...item.abs,
        right: indents.mini,
        top: indents.mini,
    },
    emptyText: {
        ...grid.mt_mdPlus,
        ...grid.mb_mdPlus,
        fontSize: textSizes.normalPlus,
        textAlign: 'center',
        textTransform: 'uppercase',
        lineHeight: 1.5,
    },
    inputTitle: {
        textTransform: 'uppercase',
        display: 'block',
        width: '100%',
        textAlign: 'center',
        fontSize: textSizes.normalPlus,
        ...grid.mb_mini,
    },
    inputWrapper: {
        ...grid.w100,
        ...grid.mb_md,
    },
    footer: {
        ...grid.mt_md,
        ...grid.rowCenter,
        ...grid.w100,
    },
    title: {
        ...grid.w80,
        ...grid.rowCenter,
        ...grid.mb_md,
        fontSize: textSizes.normalPlus,
        textTransform: 'uppercase',
        textAlign: 'center',
        lineHeight: 1.5,
    },
}

addStyles(filesEditTagsModalStyles)
export default filesEditTagsModalStyles
