import React from 'react'
import DeviceSettingsTimerMethods, { IDeviceSettingsTimerProps } from './deviceSettings__timer.local.methods'
import EditText from 'blocks.simple/editText/editText'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import RangeVolume from 'blocks.simple/range/_volume/range_volume'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import { Slider } from 'molecules/Slider'
import { Radio } from 'atoms/Radio'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './styles'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { text as textStyles } from 'theme'

const DeviceSettingsTimer = (p_: IDeviceSettingsTimerProps) => {
    const {
        state,
        setState,
        onChangeTime,
        onChangeRepeat,
        onSelectDays,
        onChangeVolume,
        onChangeComPortNumber,
        onChangeRebootType,
    } = DeviceSettingsTimerMethods(p_)
    const renderItems = () => {
        const s_ = state
        const isSingle = s_.timers.on && s_.timers.on.length === 1
        const repeatOptions = [
            { name: translate('never'), setup: 'off' },
            { name: translate('once'), setup: 'once' },
            { name: translate('daily'), setup: 'daily' },
            { name: translate('weekly'), setup: 'weekly' },
        ]

        return s_.timers.on.map((timer: any, index: number) => {
            const offTimer = s_.timers.off[index]
            const showLabelNumber = index + 1

            return (
                <div
                    key={`device_settings_timer_${index}`}
                    className={cn(grid.fullWidth, grid.row, { [grid.rowCenter]: !isSingle })}
                >
                    <div className={cn(isSingle ? styles.singleTimer : styles.timer)}>
                        {p_.type !== 'reboot_timers' && (
                            <div className={grid.mb_mini}>
                                {translate(p_.comPort ? 'onDisplayTimer' : 'onTimer')} {showLabelNumber}
                            </div>
                        )}
                        <div className={cn(styles.timers)}>
                            {timer.setup !== 'off' && (
                                <div className={cn(grid.mt_md, grid.fullWidth)}>
                                    <EditText
                                        mod={'withBorder'}
                                        className={cn(textStyles.center)}
                                        label={translate('time')}
                                        type="text"
                                        mask="99:99:99"
                                        value={timer.time}
                                        onChange={(value: string) => {
                                            const s_ = { ...state }
                                            s_.timers['on'][index].time = value

                                            setState(s_)
                                        }}
                                        onBlur={(value: string) => onChangeTime(value, 'on', index)}
                                    />
                                </div>
                            )}
                            <div className={cn(grid.mt_md, grid.fullWidth)}>
                                <Dropdown
                                    mod={'default'}
                                    options={repeatOptions}
                                    valueField={'setup'}
                                    label={translate('repeat')}
                                    value={timer.setup}
                                    onChange={(selected) => onChangeRepeat(selected, 'on', index)}
                                />
                            </div>
                            {timer.setup === 'weekly' && (
                                <div
                                    className={cn(
                                        { [item.hidden]: timer.setup !== 'weekly' },
                                        grid.row,
                                        grid.mt_mini,
                                        grid.center,
                                        grid.fullWidth
                                    )}
                                >
                                    {s_.days.map((day, i) => {
                                        const dayIndex = i + 1

                                        return (
                                            <div key={`day__checkbox_${dayIndex}`}>
                                                <Checkbox
                                                    checked={
                                                        timer.repeatDays
                                                            ? timer.repeatDays.indexOf(dayIndex) !== -1
                                                            : false
                                                    }
                                                    onClick={(selected: any) =>
                                                        onSelectDays(selected, dayIndex, 'on', index)
                                                    }
                                                />
                                                <div className={cn(grid.mt_mini)}>{day}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                            {p_.platform !== 'linux' && p_.type === 'reboot_timers' && (
                                <div className={cn(grid.row, grid.mt_md)}>
                                    <Radio
                                        onClick={() => onChangeRebootType('reboot', 'on', index)}
                                        selected={timer.rebootType === 'reboot'}
                                        label={translate('reboot_device')}
                                        className={cn(grid.mb_md, grid.mr_md)}
                                    />
                                    <Radio
                                        onClick={() => onChangeRebootType('restartApp', 'on', index)}
                                        selected={timer.rebootType === 'restartApp'}
                                        label={translate('restart_application')}
                                    />
                                </div>
                            )}
                        </div>
                        {!p_.comPort && p_.platform !== 'webos' && p_.type !== 'reboot_timers' && (
                            <div className={grid.mt_md}>
                                {translate('volumeOnStartDevice')}
                                <RangeVolume
                                    volume={timer.volume}
                                    onChange={(value: number) => onChangeVolume(value, 'on', index)}
                                />
                            </div>
                        )}
                        {p_.platform === 'android' && p_.type !== 'display' ? (
                            <div>*{translate('onTimerAndroidWarning')}</div>
                        ) : null}
                    </div>
                    {offTimer && (
                        <div className={cn(isSingle ? styles.singleTimer : styles.timer)}>
                            <div className={grid.mb_mini}>
                                {translate(p_.comPort ? 'offDisplayTimer' : 'offTimer')} {showLabelNumber}
                            </div>
                            <div className={cn(styles.timers)}>
                                {offTimer.setup !== 'off' && (
                                    <div className={cn(grid.mt_md, grid.fullWidth)}>
                                        <EditText
                                            mod={'withBorder'}
                                            className={cn(textStyles.center)}
                                            label={translate('time')}
                                            type="text"
                                            mask="99:99:99"
                                            value={offTimer.time}
                                            onChange={(value: string) => {
                                                const s_ = { ...state }
                                                s_.timers['off'][index].time = value

                                                setState(s_)
                                            }}
                                            onBlur={(value: string) => onChangeTime(value, 'off', index)}
                                        />
                                    </div>
                                )}
                                <div className={cn(grid.mt_md, grid.fullWidth)}>
                                    <Dropdown
                                        mod={'default'}
                                        options={repeatOptions}
                                        valueField={'setup'}
                                        label={translate('repeat')}
                                        value={offTimer.setup}
                                        onChange={(selected) => onChangeRepeat(selected, 'off', index)}
                                    />
                                </div>
                                {offTimer.setup === 'weekly' && (
                                    <div
                                        className={cn(
                                            { [item.hidden]: offTimer.setup !== 'weekly' },
                                            grid.row,
                                            grid.mt_mini,
                                            grid.center,
                                            grid.fullWidth
                                        )}
                                    >
                                        {s_.days.map((day, i) => {
                                            const dayIndex = i + 1

                                            return (
                                                <div key={`day__checkbox_${dayIndex}`}>
                                                    <Checkbox
                                                        checked={
                                                            offTimer.repeatDays
                                                                ? offTimer.repeatDays.indexOf(dayIndex) !== -1
                                                                : false
                                                        }
                                                        onClick={(selected: any) =>
                                                            onSelectDays(selected, dayIndex, 'off', index)
                                                        }
                                                    />
                                                    <div className={cn(grid.mt_mini)}>{day}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )
        })
    }

    const s_ = state
    const isSingle = s_.timers.on && s_.timers.on.length === 1

    return (
        <div className={styles.timersWrapper}>
            <Slider isShowArrows={!isSingle} count={1} key={p_.platform}>
                {renderItems()}
            </Slider>
        </div>
    )
}

export default DeviceSettingsTimer
