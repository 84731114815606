import { addStyles } from 'ethcss'
import { grid, IStyles } from '../../../theme'

const historyHeaderStyles: IStyles = {
    header: {
        ...grid.row,
        ...grid.justify,
        ...grid.normalCenter,
        ...grid.mb_md,
    },
    downloadLink: {
        ...grid.ml_mini,
    },
}

addStyles(historyHeaderStyles)
export default historyHeaderStyles
