import PropTypes from 'prop-types'
import React from 'react'
//@ts-ignore
import BigCalendar from 'react-big-calendar'
import Icon from 'blocks.simple/icon/icon'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import { RootStateOrAny, useSelector } from 'react-redux'
import moment from 'moment'
import { getDefaultLocale } from 'core/helpers/user'
import { mediaJS } from 'blocks.app/config'
import translate from 'core/translate'
import styles from './calendar.jcss'
import { Typography } from 'atoms/Typography'
import { defaultThemeStyles } from './Calendar-theme'
import momentWithLocales from 'moment-with-locales-es6'

interface ICalendarProps {
    view: any
    date: string
    navigate: (selectedDate: Date) => void
    onToday: () => void
    customHeaderItem: React.ReactNode
    components: React.ReactNode
    events: any
}

BigCalendar.momentLocalizer(moment)

const getHeaderTitle = ({ view, date }: { view: string; date: string }, lang: string) => {
    switch (view) {
        case 'week':
            const weekDayStart = momentWithLocales(date).locale(lang).startOf('week').format('DD MMM')
            const weekDayEnd = momentWithLocales(date).locale(lang).startOf('week').day(7).format('DD MMM YYYY')
            return weekDayStart + ' - ' + weekDayEnd
        case 'day':
            return momentWithLocales(date).locale(lang).format('DD MMM YYYY')
        default:
            return momentWithLocales(date).locale(lang).format('MMMM YYYY')
    }
}

const getRectangleHeight = () => {
    if (mediaJS.mob.matches) {
        return 7
    }
    if (mediaJS.tabMini.matches) {
        return 5
    }

    return 2
}

export const Calendar = (p_: ICalendarProps) => {
    const user = useSelector((state: RootStateOrAny) => state.user.data)
    const lang = user?.settings?.locale || getDefaultLocale()

    const navigate = (e: any, { direction }: { direction: string }) => {
        const { view } = p_
        const iterator = direction === 'back' ? -1 : 1
        const selectedDate = moment(p_.date).add(iterator, view).startOf(view).toDate()

        p_.navigate(selectedDate)
    }

    return (
        <div className={styles.calendarWrapper}>
            <div className={styles.header}>
                <div className={styles.todayWrapper}>
                    <div className={styles.todayContent} onClick={p_.onToday}>
                        <Icon type="date" color="white" />
                        <div className={styles.todayTitle}>{translate('today')}</div>
                    </div>
                </div>
                <div className={styles.controls}>
                    <div>
                        <Icon type="arrow_left" size={25} color="white" direction="back" onClick={navigate} />
                    </div>
                    <Typography
                        className={styles.title}
                        type="title"
                        textColor={`var(--var-module-app-component-calendar-style-headerText, ${defaultThemeStyles.headerText})`}
                    >
                        {getHeaderTitle(p_, lang)}
                    </Typography>
                    <div>
                        <Icon type="arrow_right" size={25} direction="next" color="white" onClick={navigate} />
                    </div>
                </div>
                <div className={styles.customHeaderItem}>{p_.customHeaderItem}</div>
            </div>
            <div>
                <Rectangle width={3} height={getRectangleHeight()}>
                    <BigCalendar
                        culture={lang}
                        toolbar={false}
                        date={p_.date}
                        view={p_.view}
                        onView={() => {}}
                        onNavigate={() => {}}
                        popup
                        // messages={{
                        //     showMore: function () {
                        //         return translate('showAll')
                        //     },
                        //     allDay: translate('onAllDay'),
                        // }}
                        messages={{
                            showMore: () => (
                                <div
                                    style={{ cursor: 'pointer' }}
                                    onMouseOver={(e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                    }}
                                >
                                    {translate('showAll')}
                                </div>
                            ),
                            allDay: translate('onAllDay'),
                        }}
                        components={p_.components}
                        events={p_.events}
                    />
                </Rectangle>
            </div>
        </div>
    )
}

Calendar.propTypes = {
    events: PropTypes.array,
    onToday: PropTypes.func,
    components: PropTypes.object,
    view: PropTypes.string,
    navigate: PropTypes.func,
    customHeaderItem: PropTypes.node,
}

Calendar.defaultProps = {
    events: [],
    date: null,
    components: {},
    view: 'month',
    navigate: () => {},
}
