import { addStyles } from 'ethcss'
import { grid, indents, item } from 'theme'
import { IStyles } from 'theme'

export const styles: IStyles = {
    List: {
        ...grid.fullWidth,
        paddingTop: indents.md,
        overflowX: 'auto',
    },
}

addStyles(styles)
