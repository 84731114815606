import React from 'react'
import EditText from 'blocks.simple/editText/editText'
import Datepicker from 'blocks.simple/datepicker/datepicker'
import moment from 'moment'

import styles from '../ui.jcss'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'

class UiDatepicker extends React.Component {
    state = {
        oneDate: moment(),
        dateList: []
    }
    render() {
        const s_ = this.state

        return (
            <div className={styles.blockPanel}>
                <div className={styles.title}>Datepicker</div>
                <div className={cn(styles.content, grid.row)}>
                    <div className={grid.mr_md}>
                        <Datepicker
                            inline
                            showWeekNumbers
                            list={s_.dateList}
                            onChange={(dateList) => this.setState({ dateList })}
                        />
                    </div>
                    <div className={cn(grid.w20)}>
                        <EditText
                            value={s_.oneDate.format('YYYY-MM-DD HH:mm:ss')}
                            dateValue={s_.oneDate}
                            type={'date'}
                            onChange={(oneDate) => this.setState({ oneDate })}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default UiDatepicker
