import React, { FunctionComponent } from 'react'

import translate from 'core/translate'
import { useSearchInput } from './SearchInput-hooks'

import { ISearchInputComponent } from './SearchInput-types'
import EditText from 'blocks.simple/editText/editText'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { getThemeStyleValue } from 'theme/colors'

export const SearchInput: FunctionComponent<ISearchInputComponent> = ({
    value = '',
    onChange,
    placeholder = translate('sectionSearch'),
    wrapperClassName,
    backgroundColor = 'white',
    label,
    immediateChange,
}): JSX.Element => {
    const searchInput = useSearchInput({ value, onChange, immediateChange })

    return (
        <EditText
            value={searchInput.searchValue}
            placeholder={placeholder}
            mod="none"
            onChange={searchInput.handleOnChange}
            //icon={searchInput.searchValue.length ? 'close' : 'search'}
            iconColor={getThemeStyleValue('ui', 'icon', 'main') || defaultThemeStyles.main}
            onIconClick={searchInput.handleOnClearInput}
            label={label}
            wrapperClassName={wrapperClassName}
            backgroundColor={backgroundColor}
        />
    )
}
