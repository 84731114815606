import React from 'react'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import Button from 'blocks.simple/button/button'
import Icon from 'blocks.simple/icon/icon'
import ItemBroadcasts from '../../__item/_broadcasts/catalog__item_broadcasts'
import { api } from 'core/api/ConnectionManager'
import helpers, { addListener } from 'core/helpers'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './catalog__template_broadcasts.jcss'
import { routes } from 'features/routes'
import broadcastsHelpers from 'core/helpers/_broadcasts'
import helpersDisplays from 'core/helpers/_digitalSignages'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { getURLSearchParamsByLocation, getURLSearchParamsString } from 'features/routes/utils'
import { getParamsForMultipleMoveBroadcasts } from 'core/utils/broadcastUtil'
import { changeLocation } from 'features/routes'
import { Location } from 'history'
import { ItemData } from '../../../../core/models/Template'

const onUpdateSchedule = (cb: (item: unknown) => void) => ({ broadcastId }: { broadcastId: number }) => {
    api.send('getBroadcast', { id: broadcastId }).then((dataItem) => cb(dataItem))
}
export default {
    folderName: 'folderId',
    getMethod: 'getBroadcastsAndFolders',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noBroadcasts'),
    mediaCellClassName: styles.mediaCellClassName,
    emptyIcon: 'not_allow',
    moveItem: (broadcastId: number, parentId: number) => api.send('moveToBroadcastFolder', { broadcastId, parentId }),
    moveFolder: (broadcastFolderId: number, parentId: number) =>
        api.send('moveToBroadcastFolder', { broadcastFolderId, parentId }),
    multipleMove: (broadcastId: number[], broadcastFolderId: number[], parentId: number) => {
        const params = getParamsForMultipleMoveBroadcasts(broadcastId, broadcastFolderId, parentId)
        return api.send('moveToBroadcastFolder', params)
    },
    getDragData: (p_: { item: ItemData }) => {
        return JSON.stringify({ id: p_.item.id, instanceType: p_.item.instanceType })
    },
    addItemButton: (self: { props: { location: Location } }) => {
        let path: null | string | undefined = null
        const keys = ['addAdvancedBroadcast', 'addVideowall', 'addContentToDevice']
        const query = getURLSearchParamsByLocation(self.props.location)

        keys.forEach((name) => {
            if (
                !path &&
                (helpers.isAvailableMenu({ key: 'broadcasts', name }) || helpers.isAvailableMenu({ key: name }))
            ) {
                path = routes[name].path
            }
        })

        if (!path) {
            return null
        }

        return (
            <Button
                rounded="full_md"
                mod="withShadow"
                onClick={() => {
                    const queryString = getURLSearchParamsString({
                        broadcastFolderId: query.folderId,
                    })

                    changeLocation({
                        pathname: `/${path}`,
                        search: queryString,
                    })
                }}
            >
                {translate('add')}
            </Button>
        )
    },
    settingsPrefixes: 'broadcast',
    tile: ItemBroadcasts,
    viewOptions: () => [
        {
            id: 'tile',
            name: translate('tile'),
            icon: 'tile',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
        {
            id: 'list',
            name: translate('list'),
            icon: 'list',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
    ],
    sortOptions: () => [
        { id: '-createdAt', name: translate('sortCreatedAt') },
        { id: 'title', name: translate('byNameAsc') },
    ],
    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean) => {
        const item = { ...dataItem }
        if (item.cover === 'ignore') {
            delete item.cover
        } else {
            item.cover = helpers.getBroadcastCover(item.coverSource)
        }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    getBackendModel: (item: ItemData) => {
        if (!broadcastsHelpers.isDirectory(item)) {
            return api.send('getBroadcast', { id: item.id }, { hideLoader: true })
        } else {
            return api.send('getBroadcastFolder', { id: item.id }, { hideLoader: true })
        }
    },
    isEqual: (item: ItemData, prevItem: ItemData) => broadcastsHelpers.isEqual(item, prevItem),
    onCreateListeners: [
        (cb: any, clearList: string[]) => api.addObserver('broadcastCreated', cb, clearList),
        (callback: any, clearList: string[]) =>
            api.addObserver(
                'broadcastFolderCreated',
                (dataItem: ItemData) => callback({ ...dataItem, instanceType: 'broadcastFolder' }),
                clearList
            ),
    ],
    onUpdateListeners: [
        (cb: any, clearList: string[]) => api.addObserver('broadcastUpdated', cb, clearList),
        addListener.updateBroadcastProgress,
        (cb: any, clearList: string[]) =>
            api.addObserver(
                'broadcastFolderUpdated',
                (dataItem: ItemData) => cb({ ...dataItem, instanceType: 'broadcastFolder' }),
                clearList
            ),
        (cb: any, clearList: string[]) => api.addObserver('scheduleCreated', onUpdateSchedule(cb), clearList),
        (cb: any, clearList: string[]) => api.addObserver('scheduleUpdated', onUpdateSchedule(cb), clearList),
        (cb: any, clearList: string[]) => api.addObserver('scheduleDeleted', onUpdateSchedule(cb), clearList),
    ],
    onDeleteListeners: [
        (cb: any, clearList: string[]) =>
            api.addObserver('broadcastDeleted', ({ id }: { id: number }) => cb({ id }), clearList),
        (cb: any, clearList: string[]) =>
            api.addObserver(
                'broadcastFolderDeleted',
                ({ id }: { id: number }) => cb({ id, instanceType: 'broadcastFolder' }),
                clearList
            ),
    ],
    isFolder: (item: ItemData) => broadcastsHelpers.isDirectory(item),
    fields: () => [
        { id: 'cover', name: translate('Campaign'), hide: true },
        { id: 'title', name: translate('name') },
        { id: 'orientation', name: translate('orientation') },
        { id: 'broadcastType', name: translate('typeBroadcast') },
        { id: 'createdAt', name: translate('createdAt') },
    ],
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    getBreadcrumbs(folderId: number) {
        const initBreadcrumb = { name: 'broadcasts', id: undefined }

        if (folderId) {
            api.send('getBroadcastFolderBreadcrumbs', { folderId }).then((breadcrumbsList: any) => {
                const breadcrumbs = breadcrumbsList
                    .reverse()
                    .map((breadcrumb: { title: string }) => ({ ...breadcrumb, name: breadcrumb.title }))
                breadcrumbs.unshift(initBreadcrumb)
                //@ts-ignore
                this.setState({ breadcrumbs })
            })
        } else {
            //@ts-ignore
            this.setState({ breadcrumbs: [initBreadcrumb] })
        }
    },
    moveOnUpdate({ prev, next }: { prev: { folderId: number }; next: { folderId: number } }) {
        return prev.folderId !== next.folderId
    },
    list: {
        title: (item: ItemData, active: boolean, p_: unknown, nested: number) => {
            const { cover } = item
            const isDirectory = broadcastsHelpers.isDirectory(item)

            return (
                <div
                    className={styles.name}
                    style={{
                        marginLeft: 40 * nested,
                    }}
                >
                    {isDirectory && <Icon className={styles.icon} type="files" />}
                    {!isDirectory && (
                        <div className={cn(styles.image, styles.icon)}>
                            <Rectangle width={2} height={1} src={cover.src} contain={cover.isIcon} />
                        </div>
                    )}
                    <div>{item.title}</div>
                </div>
            )
        },
        orientation: ({ orientation }: { orientation: number }) => {
            const broadcastOrientation = helpersDisplays.getOrientation(orientation)

            if (!broadcastOrientation) {
                return null
            }

            return broadcastOrientation.name
        },
        broadcastType: ({ broadcastType }: { broadcastType: string }) => {
            if (broadcastType === 'simple') {
                return translate('simpleBroadcast')
            } else if (broadcastType === 'advanced') {
                return translate('advancedBroadcast')
            }
        },
        createdAt: ({ createdAt }: { createdAt: string }) => helpers.getFormattedLocalDate(createdAt, 'DD/MM/YYYY'),
    },
}
