import React from 'react'
import Range from 'blocks.simple/range/range'
import Tippy from 'blocks.simple/tippy/tippy'
import { SelectTime } from 'blocks.app/scheduleSettings/scheduleSettings'
import Icon from 'blocks.simple/icon/icon'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import moment from 'moment/moment'
import helpers from 'core/helpers'
import { Typography } from 'atoms/Typography'
import styles from './deviceSettings__brightness.jcss'

const defaultBrightnessLevel = {
    startTime: moment().format('HH:mm:ss'),
    endTime: moment().format('HH:mm:ss'),
    level: 100,
}

const DeviceSettingsBrightness = (p_: {
    settings: { level: number; startTime: string; endTime: string }[]
    enabled: boolean
    onChange: (settings: any, value: string) => void
}) => {
    if (!p_.enabled) {
        return null
    }

    const timeOptions = helpers.getTimeOptions()

    function addNewTimer() {
        const settings = helpers.deepCopy(p_.settings)

        settings.push(defaultBrightnessLevel)
        p_.onChange(settings, 'brightness')
    }

    function deleteBrightnessTimer(idx: number) {
        const settings = helpers.deepCopy(p_.settings)
        settings.splice(idx, 1)

        p_.onChange(settings, 'brightness')
    }

    function onChangeBrightnessLevel(value: number, index: number) {
        const settings = helpers.deepCopy(p_.settings)

        settings[index].level = value

        p_.onChange(settings, 'brightness')
    }

    function onChangeTime(value: string, index: number, field: string) {
        if (moment(value, 'HH:mm:ss', true).isValid() || moment(value, 'HH:mm:ss', true).isValid()) {
            const settings: any = helpers.deepCopy(p_.settings)
            const time = moment(`${moment().format('YYYY-MM-DD')} ${value}`).format('HH:mm:ss')

            settings[index][field] = time

            p_.onChange(settings, 'brightness')
        }
    }

    return (
        <div className={styles.wrapper}>
            <Typography className={cn(styles.title)} type="title">
                {translate('brightness')}
            </Typography>
            <div className={grid.mt_md}>
                <Button mod={'withBorder'} onClick={addNewTimer}>
                    {translate('add')}
                </Button>
            </div>
            <div className={styles.content}>
                {p_.settings.map((timer, index) => (
                    <div key={index} className={styles.timersWrapper}>
                        <div className={styles.removeIcon}>
                            <Icon
                                type="delete"
                                tooltip={{
                                    html: translate('delete'),
                                }}
                                onClick={function () {
                                    deleteBrightnessTimer(index)
                                }}
                            />
                        </div>
                        <div className={grid.mb_mini}>
                            {translate('brightnessLevel')} {index + 1}
                        </div>
                        <Tippy title={`${timer.level}`} className={cn(grid.mb_md)}>
                            <Range
                                value={timer.level}
                                min={1}
                                max={100}
                                onChange={function (value: number) {
                                    onChangeBrightnessLevel(value, index)
                                }}
                                tooltip
                            />
                        </Tippy>
                        <SelectTime
                            time={{
                                label: translate('timeFrom'),
                                type: 'tel',
                                mask: '99:99:99',
                                value: timer.startTime,
                                mod: 'darkBlue',
                                id: `brightness_startTime_${index}`,
                                containerClassName: grid.mb_md,
                                noFocus: true,
                            }}
                            timeOptions={timeOptions}
                            onChange={function (value) {
                                if (value.length <= 5) return
                                onChangeTime(value, index, 'startTime')
                            }}
                        />
                        <SelectTime
                            time={{
                                label: translate('timeTo'),
                                type: 'tel',
                                mask: '99:99:99',
                                value: timer.endTime,
                                mod: 'darkBlue',
                                id: `brightness_endTime_${index}`,
                                noFocus: true,
                            }}
                            timeOptions={timeOptions}
                            onChange={function (value) {
                                if (value.length <= 5) return
                                onChangeTime(value, index, 'endTime')
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DeviceSettingsBrightness
