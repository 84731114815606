import { addStyles } from 'ethcss'
import { indents, IStyles } from 'theme'

export const styles: IStyles = {
    ProductsReferenceBookHeader: {
        padding: indents.md,
        textAlign: 'center',
    },
}

addStyles(styles)
