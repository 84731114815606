import { useState, useRef } from 'react'
import { api } from 'core/api/ConnectionManager'
import { useEffect } from 'react'
import { IPlaybackState } from 'core/models/PlaybackState'
import { IAudioPlaygroundSource } from 'organisms/AudioPlayground/AudioPlayground-types'
import { IBroadcast } from 'core/models/Broadcast'
import { isExist } from 'core/utils/coreUtil'
import { IAudioTrackItem } from 'organisms/AudioTrackList/AudioTrackItem/AudioTrackItem-types'
import {
    convertBroadcastSoundtrackToAudioTrackList,
    convertPlaybackStateMediaToAudioPlaygroundSourceList,
} from 'core/utils/convertUtil'
import { TDigitalSignageStatus } from 'core/models/DigitalSignage'
import { IGetBroadcastRequest, IGetPlaybackStateRequest } from './DisplayAudioControll-types'

export const usePlaybackState = (deviceInfo: any, closeModalContent: any) => {
    const INTERVAL_TIME_MS = 500
    const [playbackState, setPlaybackState] = useState<IPlaybackState | null>(null)
    const [broadcast, setBroadcast] = useState<IBroadcast | null>(null)
    const [soundtrack, setSoundtrack] = useState<IAudioTrackItem[]>([])
    const [audioSourceList, setAudioSourceList] = useState<IAudioPlaygroundSource[]>([])
    const [isOnline, setIsOnline] = useState<boolean>(
        deviceInfo && deviceInfo.status ? deviceInfo.status === 'online' : true
    )
    const [isGetPlayback, setIsGetPlayback] = useState<boolean>(false)

    let isOnlineRef = useRef(isOnline)
    isOnlineRef.current = isOnline

    let isGetPlaybackRef = useRef(isGetPlayback)
    isGetPlaybackRef.current = isGetPlayback

    const resetState = () => {
        setPlaybackState(null)
        setBroadcast(null)
        setSoundtrack([])
        setAudioSourceList([])
    }

    const changeDisplayStatus = (status: TDigitalSignageStatus): void => {
        let isOnlineStatus = status === 'online'

        if (!isOnlineStatus) {
            resetState()
            setIsGetPlayback(false)
        }

        setIsOnline(() => {
            return isOnlineStatus
        })
    }

    const getActiveSoundtrackId = (): number | undefined => {
        if (!playbackState || !isExist(playbackState.media)) return undefined

        let media = playbackState.media
        let sountrackMedia = media.find((audio) => audio.playingIn === 'soundtrack')

        if (!sountrackMedia) return undefined

        return sountrackMedia.contentId
    }

    const isActualDevice = (display: any) => {
        return display && deviceInfo && display.id && display.id === deviceInfo.id
    }

    const isActualDigitalSignage = (responsePlaybackState: IPlaybackState) => {
        return (
            responsePlaybackState &&
            deviceInfo &&
            responsePlaybackState.digitalSignageId &&
            responsePlaybackState.digitalSignageId === deviceInfo.id
        )
    }

    const isActualBroadcast = (responseBroadcast: IBroadcast) => {
        return responseBroadcast && broadcast && responseBroadcast.id === broadcast.id
    }

    const isCanSetGetPlayback = () => {
        return !isGetPlaybackRef || !isGetPlaybackRef.current
    }

    const isEmptyPlayBackMedia = (responsePlaybackState: IPlaybackState) => {
        return responsePlaybackState.media.length === 0
    }

    useEffect(() => {
        if (!isExist(deviceInfo) || !isExist(deviceInfo.id)) return

        const intervalId = setInterval(() => {
            if (!isOnlineRef || !isOnlineRef.current) return
            console.log('ping playback')
            api.send<IGetPlaybackStateRequest, null>('getPlaybackState', { digitalSignageId: deviceInfo.id })
                .then((response: any) => {})
                .catch((error: any) => {
                    closeModalContent()
                })
        }, INTERVAL_TIME_MS)

        return () => {
            clearInterval(intervalId)
        }
    }, [isOnline])

    useEffect(() => {
        const onPlaybackStateChange = (responsePlaybackState: IPlaybackState) => {
            if (!isActualDigitalSignage(responsePlaybackState)) return

            if (isEmptyPlayBackMedia(responsePlaybackState)) {
                if (isCanSetGetPlayback()) {
                    setIsGetPlayback(true)
                }

                resetState()
            } else {
                setPlaybackState(responsePlaybackState)
            }
        }

        const listenersId: string[] = []
        api.addObserver('onPlaybackStateChange', onPlaybackStateChange, listenersId)

        return () => {
            listenersId.forEach((id) => api.removeObserver(id))
        }
    }, [])

    useEffect(() => {
        if (!playbackState || !playbackState.broadcastId || broadcast) return

        api.send<IGetBroadcastRequest, IBroadcast>('getBroadcast', {
            id: playbackState.broadcastId,
            includeAll: true,
        }).then((responseBroadcast: IBroadcast) => {
            setBroadcast(responseBroadcast)
        })
    }, [playbackState])

    useEffect(() => {
        const onBroadcastUpdated = (responseBroadcast: IBroadcast) => {
            if (!isActualBroadcast(responseBroadcast)) return
            setBroadcast(responseBroadcast)
        }

        const listenersId: string[] = []
        api.addObserver('broadcastUpdated', onBroadcastUpdated, listenersId)

        return () => {
            listenersId.forEach((id) => api.removeObserver(id))
        }
    }, [playbackState])

    useEffect(() => {
        if (!broadcast || !playbackState) return
        setSoundtrack(convertBroadcastSoundtrackToAudioTrackList(broadcast.soundtrack))
        setAudioSourceList(convertPlaybackStateMediaToAudioPlaygroundSourceList(broadcast, playbackState))
    }, [broadcast, playbackState])

    useEffect(() => {
        const onDisplayUpdated = (display: any) => {
            if (!isActualDevice(display) || !isExist(display.status)) return
            changeDisplayStatus(display.status)
        }

        const listenersId: string[] = []
        api.addObserver('displayUpdated', onDisplayUpdated, listenersId)

        return () => {
            listenersId.forEach((id) => api.removeObserver(id))
        }
    }, [])

    return {
        playbackState,
        audioSourceList,
        getActiveSoundtrackId,
        soundtrack,
        isOnline,
        isGetPlayback,
    }
}
