import React, { createRef } from 'react'
import { Draggable } from 'atoms/Draggable'
import Icon from 'blocks.simple/icon/icon'
import { cn } from 'ethcss'
import { styles } from './DownloadModal-styles'
import { grid, item, layers } from 'theme'
import translate from 'core/translate'
import { Typography } from 'atoms/Typography'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from './DownloadModal.theme'
import Searchbar from 'blocks.app/searchbar/searchbar'
import { isArray, isNotEmptyArray, isExist, isIncludeSubstring } from 'core/utils/coreUtil'
import { IDownloadModalProps, IDownloadModalState } from './DownloadModal-types'
import { IDownloadBroadcast, IDownloadData, IDownloadUnkown } from 'core/models/Downloads'
import { DownloadItem } from '../../atoms/DownloadItem'
import { themeFromStore } from 'core/helpers/menu'

export class DownloadModal extends React.PureComponent<IDownloadModalProps, IDownloadModalState> {
    constructor(p_: IDownloadModalProps) {
        super(p_)

        this.state = {
            searchKeyWord: '',
            isRollUp: false,
            refHeight: 0,
        }
    }

    ref: React.RefObject<any> = createRef()
    componentDidMount() {
        this.setState((state) => ({
            ...state,
            refHeight: this.ref.current.clientHeight / 1000,
        }))
    }

    searchDownloads = (searchKeyWord: string) => {
        this.setState((state) => ({
            ...state,
            searchKeyWord,
        }))
    }

    rollUp = () => {
        this.setState((prevState) => ({
            ...prevState,
            isRollUp: !prevState.isRollUp,
        }))
    }
    private filterDownloadsBySearch = () => {
        const { searchKeyWord } = this.state
        const { downloads } = this.props

        if (this.isSearchEnabled()) return downloads

        const targetDownloads = downloads.filter((download) => isIncludeSubstring(download.name, searchKeyWord))
        const otherDownloads = downloads.filter((download) => !isIncludeSubstring(download.name, searchKeyWord))
        const broadcastsWithSearchDevices = this.getDevicesForNotRequestedBroadcasts(otherDownloads)

        return [...targetDownloads, ...broadcastsWithSearchDevices]
    }

    private isSearchEnabled = () => {
        const { searchKeyWord } = this.state

        return searchKeyWord === '' || !isExist(searchKeyWord)
    }

    private getDevicesForNotRequestedBroadcasts = (otherDownloads: IDownloadData[]) => {
        const { searchKeyWord } = this.state

        return otherDownloads
            .filter((download) => download.children && isArray(download.children))
            .map((download) => {
                const downloadBroadcast = download as IDownloadBroadcast
                const childrensForParent = downloadBroadcast.children.filter((device) =>
                    isIncludeSubstring(device.name, searchKeyWord)
                )
                return { ...download, children: childrensForParent }
            })
            .filter((download) => isNotEmptyArray(download.children))
    }

    render() {
        const {
            position,
            setPosition,
            closeModal,
            cancelDownload,
            cancelDownloadWithoutMessage,
            clearAllDownloads,
        } = this.props

        const filteredDownloads = this.filterDownloadsBySearch()
        const s_ = this.state

        return (
            <Draggable
                key={'downloadsModal'}
                parentRect={{
                    width: window.innerWidth,
                    height: window.innerHeight,
                    top: 0,
                    left: 0,
                }}
                isFixed
                classNames={cn(styles.downloadModal__wrapper)}
                left={position.left}
                top={position.top}
                width={position.width}
                isResizable={s_.isRollUp ? false : true}
                // height = {position.height}
                height={s_.isRollUp ? s_.refHeight : 0.7}
                zIndex={layers.progress}
                resizableZIndex={layers.progress}
                isRenderResizeElements={false}
                borderColor={
                    themeFromStore() === 'smartplayer'
                        ? getThemeStyleValue('ui', 'editText', 'withBorder-border')
                        : getThemeStyleValue('app', 'downloads', 'wrapperBorder') || defaultThemeStyles.wrapperBorder
                }
                activeBorderColor={
                    themeFromStore() === 'smartplayer'
                        ? getThemeStyleValue('ui', 'editText', 'withBorder-border')
                        : getThemeStyleValue('app', 'downloads', 'wrapperBorder') || defaultThemeStyles.wrapperBorder
                }
                onDragEnd={setPosition}
                onResizeEnd={setPosition}
                minWidth={0.2}
                minHeight={0.2}
            >
                <div className={styles.downloadModal__contentWrapper}>
                    <div className={styles.downloadModal__header} ref={this.ref}>
                        <div className={item.ellipsis}>{translate('downloadsList')}</div>
                        <div className={cn(grid.row, grid.noWrap)}>
                            <div className={grid.mr_md} onMouseDown={(e) => e.stopPropagation()} onClick={this.rollUp}>
                                <Icon
                                    type={s_.isRollUp ? 'arrow_up' : 'arrow_down'}
                                    color={'white'}
                                    size={20}
                                    tooltip={
                                        s_.isRollUp ? { title: translate('showAll') } : { title: translate('hide') }
                                    }
                                />
                            </div>
                            <div onClick={closeModal} onMouseDown={(e) => e.stopPropagation()}>
                                <Icon type={'close_tiny'} color={'white'} size={18} />
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            s_.isRollUp ? styles.downloadModal__dNone : styles.downloadModal__contentSearchWrapper
                        }
                    >
                        <Searchbar
                            className={styles.downloadModal__search}
                            mod={'withBorder'}
                            onChange={this.searchDownloads}
                        />
                        <div className={styles.downloadModal__content}>
                            {isArray(filteredDownloads) && isNotEmptyArray(filteredDownloads) && (
                                <div className={styles.downloadModal__clearAllDownloads} onClick={clearAllDownloads}>
                                    {translate('clearAllDownloads')}
                                </div>
                            )}
                            <div className={styles.downloadModal__title}>
                                <div className={styles.downloadModal__nameWrapper}>
                                    <Typography className={styles.downloadModal__name}>{translate('name')}</Typography>
                                </div>
                                <div className={styles.downloadModal__progressWrapper}>
                                    <Typography className={styles.downloadModal__name}>
                                        {translate('progress')}
                                    </Typography>
                                </div>
                            </div>
                            {!filteredDownloads.length && (
                                <div className={styles.downloadModal__emptyMessage}>
                                    <Typography type="text">{translate('uploadsEmptyDescriptions')}</Typography>
                                </div>
                            )}
                            {filteredDownloads.map((download, index) => (
                                <DownloadItem
                                    download={download as IDownloadUnkown}
                                    cancelDownload={cancelDownload}
                                    cancelDownloadWithoutMessage={cancelDownloadWithoutMessage}
                                    key={`download_${download.type}_${download.id}_${index}`}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </Draggable>
        )
    }
}
