import { useEffect, useState } from 'react'
import moment from 'moment/moment'
import { isExist, isNotEmptyArray } from 'core/utils/coreUtil'

const sortEventsByStartDate = (p_: any) => {
    let events = p_.eventsByDate[moment(p_.selectedDate).format('YYYY-MM-DD')]

    if (isNotEmptyArray(events)) {
        return [...events].sort((event, nextEvent) => event.start - nextEvent.start)
    }

    return isExist(events) ? events : []
}

const ScheduleToolbarContentMethods = (p_: any) => {
    const [events, setEvents] = useState([])

    const getDerivedStateFromProps = (p_: any) => {
        const events = sortEventsByStartDate(p_)

        return {
            events,
        }
    }

    useEffect(() => {
        if (p_.selectedEventId) {
            setEvents(getDerivedStateFromProps(p_).events)
            return
        }

        setEvents([])
    }, [p_.selectedEventId])

    const onDeleteEvent = (id: number) => {
        setEvents(events.filter((event: any) => event.id !== id))
    }

    const getBroadcastIdByScheduleId = (scheduleId: number) => {
        let schedulesLength = p_.allSchedules.length

        for (let i = 0; i < schedulesLength; i++) {
            let schedule = p_.allSchedules[i]

            if (schedule.id === scheduleId) {
                return schedule.broadcastId
            }
        }

        return null
    }

    const getScheduleToDigitalSignageListByScheduleId = (scheduleId: number) => {
        let schedulesLength = p_.allSchedules.length

        for (let i = 0; i < schedulesLength; i++) {
            let schedule = p_.allSchedules[i]

            if (schedule.id === scheduleId) {
                return schedule.scheduleToDigitalSignage
            }
        }

        return null
    }

    return {
        events,
        onDeleteEvent,
        getBroadcastIdByScheduleId,
        getScheduleToDigitalSignageListByScheduleId,
    }
}

export default ScheduleToolbarContentMethods
