import { addStyles } from 'ethcss'
import { grid } from 'theme'

const catalogStyles = {
    treeViewCatalogWrapper: {
        ...grid.p_md,
        '&:focus': {
            outline: 'none'
        }
    }
}

addStyles(catalogStyles)
export default catalogStyles
