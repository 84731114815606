import { addStyles } from 'ethcss'
import { IStyles, colors, mediaCSS, indents } from '../../../theme'

const displaysSynchronizationStyles: IStyles = {
    toggleView: {
        position: 'absolute',
        top: 134,
        right: 40,

        [mediaCSS.tab]: {
            top: 126,
            right: 10,
        },
    },
    header: {
        padding: 30,
        border: '1px solid #F0F0F0',

        [mediaCSS.tab]: {
            padding: 10,
        },
    },
    caption: {
        fontSize: 20,
        marginBottom: 20,

        [mediaCSS.tab]: {
            fontSize: 14,
            marginBottom: 10,
        },
    },
    statuses: {
        display: 'flex',

        [mediaCSS.tab]: {
            display: 'block',
        },
    },
    status: {
        marginRight: 20,

        [mediaCSS.tab]: {
            marginRight: 0,
            marginBottom: 4,
        },
    },
    tileView: {
        marginTop: 10,
    },
    actionsPanel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: 70,

        [mediaCSS.tab]: {
            display: 'block',
            padding: '0 10px',
        },
    },
    actionButton: {
        letterSpacing: '0.1em',
        textDecoration: 'underline',
        cursor: 'pointer',
        marginRight: 30,

        [mediaCSS.tab]: {
            fontSize: '12px',
            marginBottom: 6,
        },

        '&:hover': {
            textDecoration: 'none',
        },
    },
    autoScreenshot: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 30,

        [mediaCSS.tab]: {
            marginBottom: 6,
        },
    },
    autoScreenshotLabel: {
        color: '#B3B3B3',
        marginRight: 10,

        [mediaCSS.tab]: {
            fontSize: '12px',
            width: 140,
        },
    },
    autoScreenshotInterval: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 30,

        [mediaCSS.tab]: {
            marginBottom: 6,
        },
    },
    autoScreenshotIntervalLabel: {
        color: '#B3B3B3',
        marginRight: 10,

        [mediaCSS.tab]: {
            fontSize: '12px',
            width: 140,
        },
    },
    autoScreenshotIntervalInput: {
        border: '1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, #b3b3b3)',
        textAlign: 'center',
        fontSize: '16px',
        borderRadius: '5px',
        maxWidth: '70px',
        height: '40px',

        [mediaCSS.tab]: {
            maxWidth: '40px',
            height: '20px',
            fontSize: '12px',
        },
    },
    selectRowsCount: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 30,

        [mediaCSS.tab]: {
            marginBottom: 6,
        },
    },
    selectRowsCountLabel: {
        color: '#B3B3B3',
        marginRight: 10,

        [mediaCSS.tab]: {
            fontSize: '12px',
            width: 140,
        },
    },
    selectRowsCountSelect: {
        border: '1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, #b3b3b3)',
        textAlign: 'center',
        fontSize: '16px',
        borderRadius: '5px',
        width: '70px',
        height: '40px',

        [mediaCSS.tab]: {
            maxWidth: '40px',
            height: '20px',
            fontSize: '12px',
        },

        '&:focus': {
            outline: 'none',
        },
    },
    syncList: {
        padding: '30px',
        overflowX: 'auto',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 210px)',

        [mediaCSS.tab]: {
            padding: '10px',
        },
    },
    syncListInner: {
        display: 'flex',
        flexWrap: 'wrap',
        width: 1860,
    },
    cell: {
        backgroundColor: colors.greyLight,
        borderRadius: '5px',
        overflow: 'hidden',
        zIndex: '3000',
    },
    transition: {
        transition: 'margin-right 1s, margin-bottom 1s, transform 0.3s linear !important',
    },
    hideDeviceInfo: {
        opacity: 0,
    },
    display: {
        width: '100%',
        height: '100%',
    },
    displayInfo: {
        position: 'relative',
        padding: '13px 15px',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        transition: 'opacity 0.3s',
    },
    syncStatus: {
        position: 'absolute',
        right: 10,
        bottom: 8,
    },
    name: {
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: 1,
        color: '#FFFFFF',
        marginBottom: '34px',
    },
    option: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: 1,
        color: '#FFFFFF',
        marginBottom: '12px',
    },
    syncIcon: {
        position: 'absolute',
        top: '10px',
        right: '10px',
    },
    tableView: {
        padding: '12px 30px 0 30px',

        [mediaCSS.tab]: {
            overflowY: 'auto',
            padding: '12px 10px 0 10px',
        },
    },
    table: {
        width: 'calc(100% - 153px)',
        lineHeight: 1,

        [mediaCSS.tab]: {
            width: '100%',
        },
    },
    row: {
        color: colors.grey,
    },
    rowMaster: {
        fontWeight: 'bold',
        color: colors.black,
    },
    col: {
        padding: '12px 0',
        verticalAlign: 'middle',

        [mediaCSS.tab]: {
            padding: '12px 10px',
        },
    },
    statusCircle: {
        width: 20,
        height: 20,
        borderRadius: '50%',
    },
    howToUse: {
        position: 'absolute',
        bottom: 24,
        left: '50%',
        transform: 'translateX(-50%)',
        color: colors.grey,
    },
}

addStyles(displaysSynchronizationStyles)
export default displaysSynchronizationStyles
