import displaysTemplate from '../__template/_displays'
import tasksTemplate from '../__template/_tasks'
import broadcastsTemplate from '../__template/_broadcasts'
import filesTemplate from '../__template/_files'
import reportTasksTemplate from '../__template/_reportTasks'
import usersTemplate from '../__template/_users'
import advertisingCampaignsTemplate from '../__template/_advertisingCampaigns'
import coursesTemplate from '../__template/_courses'
import teachersTemplate from '../__template/_teachers'
import gradesTemplate from '../__template/_grades'
import subjectsTemplate from '../__template/_subjects'
import classroomsTemplate from '../__template/_classrooms'
import draftDevicesTemplate from '../__template/_draftDevices'
import videoEditorTasksTemplate from '../__template/_videoEditorTasks'
import fillersAdBlocksTemplate from '../__template/_fillersAdBlocks'
import rolesTemplate from '../__template/_roles'

const template = {
    displays: displaysTemplate,
    tasks: tasksTemplate,
    broadcasts: broadcastsTemplate,
    files: filesTemplate,
    reportTasks: reportTasksTemplate,
    users: usersTemplate,
    advertisingCampaigns: advertisingCampaignsTemplate,
    courses: coursesTemplate,
    teachers: teachersTemplate,
    grades: gradesTemplate,
    subjects: subjectsTemplate,
    classrooms: classroomsTemplate,
    draftDevices: draftDevicesTemplate,
    videoEditorTasks: videoEditorTasksTemplate,
    fillersAdBlocks: fillersAdBlocksTemplate,
    roles: rolesTemplate,
}

export default (type) => template[type]
