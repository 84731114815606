import React from 'react'
import helpers from 'core/helpers'

class DndDraggable extends React.Component {
    state = {
        isDragActions: false,
    }
    render() {
        let Tag = this.props.tag
        let props = Object.assign({}, this.props)

        if (this.props.wrapperComponent) {
            Tag = this.props.wrapperComponent.type
            props = Object.assign(props, this.props.wrapperComponent.props)
            delete props.wrapperComponent
        }

        if (this.props.dragEnabled) {
            props.draggable = 'true'
            props.onDragEnd = this.onDragEnd.bind(this)
            props.onDragStart = this.onDragStart.bind(this)
        }

        if (this.props.dragEnabled && this.props.isMobileDragEnabled && helpers.isMobileDevice()) {
            props.onClick = () => {
                this.setState({ isDragActions: true })
            }

            if (!this.state.isDragActions) {
                delete props.draggable
            }
        }
        delete props.dragEnabled
        delete props.isMobileDragEnabled

        return <Tag {...props}>{props.children}</Tag>
    }
    onDragStart(e) {
        if (typeof this.props.onDragStart === 'function') {
            this.props.onDragStart(e)
        }

        let props = Object.assign({}, this.props)
        if (this.props.wrapperComponent) {
            props = Object.assign(props, this.props.wrapperComponent.props)
        }
        e.dataTransfer.setData(props.type, props.data)
    }
    onDragEnd(e) {
        if (typeof this.props.onDragEnd === 'function') {
            this.props.onDragEnd(e)

            if (this.state.isDragActions) {
                this.setState({ isDragActions: false })
            }
        }
    }
}

DndDraggable.defaultProps = {
    type: 'dnd_item',
    dragEnabled: true,
    tag: 'div',
}

export default DndDraggable
