import React, { useRef, useState } from 'react'
import request from 'superagent'
import DropMenu from 'blocks.simple/dropMenu/dropMenu'
import Button from 'blocks.simple/button/button'
import config from 'core/config'
import styles from './catalog__filter_instancesCatalog.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { text as textStyles } from 'theme'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { constants } from 'core/constants'
import EditableText from 'blocks.simple/editableText/editableText'
import { Label } from 'atoms/Label/Label-view'
import { isNotEmptyString, isExist } from 'core/utils/coreUtil'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeTableStyles } from 'atoms/Table/Table-theme'
import { connect } from 'react-redux'
import { loaderActions } from 'features/loader/Loader.state'
import { getUser } from '../../../../core/helpers/user'
import { isHorizontalMenu } from 'core/helpers/menu'
import Icon from '../../../../blocks.simple/icon/icon'

export interface IInstancesCatalogProps {
    tokens: { accessToken: string; refreshToken: string }
    selectedItems: { id: number; productCode?: string }[]
    fakeLine?: string
    onDelete: () => void
    type: string
    upload?: string
    acceptFiles?: string
    startLoading: () => void
    endLoading: () => void
    title: string
    onAdd?: any
    onAddFakeLine?: any
}

const InstancesCatalogFilter = (p_: IInstancesCatalogProps) => {
    const [active, setActive] = useState([])
    const [delimiter, setDelimiter] = useState<string>(constants.FILE_UPLOAD_CSV_DELIMITER)
    const uploadInputRef = useRef<any>(null)

    const getHeadersByType = (type: string) => {
        const { tokens } = p_

        switch (type) {
            case 'wine':
                return {
                    Authorization: `Bearer ${tokens.accessToken}`,
                }
            default:
                return {}
        }
    }
    const getAcceptFiles = (acceptFiles: string | undefined) => {
        if (!acceptFiles) {
            return `.xls,.xlsx`
        }
        return acceptFiles
    }
    const changeDelimiter = (delimiter: string) => {
        setDelimiter(delimiter)
    }
    const deleteItem = () => {
        return {
            id: 'delete',
            icon: 'delete',
            disabled: p_.selectedItems.length === 0 && !p_.fakeLine,
            color: 'darkCloud',
            tooltip: {
                title: translate('delete'),
            },
            children: (
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        setActive([])
                        p_.onDelete()
                    }}
                    className={cn(grid.colCenter, grid.p_mini)}
                >
                    <div className={cn(grid.w100, grid.mb_mini, textStyles.center)}>{translate('deleteApprove')}</div>
                    <div className={cn(grid.row, grid.justify)}>
                        <Button
                            type="submit"
                            mod="fill"
                            className={cn(grid.mr_mini)}
                            indentSize={'mini'}
                            textSize={'mini'}
                            rounded={'full_normal'}
                        >
                            {translate('yes')}
                        </Button>
                        <Button
                            mod={'withBorder'}
                            animation={false}
                            onClick={() => setActive([])}
                            indentSize={'mini'}
                            textSize={'mini'}
                            rounded={'full_normal'}
                        >
                            {translate('no')}
                        </Button>
                    </div>
                </form>
            ),
        }
    }

    if (!getUser()) return null

    const userId = getUser()!.id
    const uploadUrl = p_.upload
        ? `${config.api.root}${p_.upload}`
        : `${config.api.root}v2/upload/school/instances_catalog`
    const headers = getHeadersByType(p_.type)
    const acceptedFiles = getAcceptFiles(p_.acceptFiles)

    return (
        <div className={cn(styles.wrapper, isHorizontalMenu() && styles.wrapper_x)}>
            <div className={styles.content}>
                <div className={styles.tools}>
                    <div className={cn(grid.rowCenter)}>
                        <div className={grid.mr_md}>
                            <Icon
                                onClick={p_.onAdd}
                                type={'plus_circle'}
                                color={'grey'}
                                tooltip={{ id: 'addItemTooltip', title: translate('add') }}
                            />
                        </div>
                        <div className={styles.deleteButton}>
                            <DropMenu
                                active={active}
                                onChange={(active) => setActive(active)}
                                items={[deleteItem()]}
                                gray={true}
                            />
                        </div>
                    </div>
                    <div />
                    <div className={grid.row}>
                        <Label
                            htmlFor={'wineDelimiter'}
                            textColor={getThemeStyleValue('ui', 'table', 'text') || defaultThemeTableStyles.text}
                        >
                            {translate('separator')}:
                        </Label>
                        <EditableText
                            id={'wineDelimiter'}
                            text={delimiter}
                            placeholder={translate('separator')}
                            onChange={changeDelimiter}
                            onUpdate={changeDelimiter}
                            className={styles.delimiter}
                            containerClassName={styles.delimiterWrapper}
                            mod={'withBorder'}
                            rounded={'full_normal'}
                            hideIcon={true}
                        />
                        <Button
                            mod={'withBorder'}
                            iconPos="left"
                            icon="upload_arrow"
                            className={cn(grid.mr_md)}
                            animation={false}
                            onClick={() => {
                                uploadInputRef.current.click()
                            }}
                        >
                            {translate('upload')}
                        </Button>
                        <input
                            className={item.none}
                            type="file"
                            ref={uploadInputRef}
                            accept={acceptedFiles}
                            onChange={() => {
                                const formData = new FormData()
                                formData.append('file', uploadInputRef.current.files[0])

                                if (isExist(delimiter) && isNotEmptyString(delimiter)) {
                                    formData.append('delimiter', delimiter)
                                }

                                p_.startLoading()

                                request
                                    .post(uploadUrl)
                                    .set({ ...headers, 'SmartPlayer-From-User-Id': userId })
                                    .send(formData)
                                    .end(() => {
                                        p_.endLoading()
                                    })

                                uploadInputRef.current.value = ''
                            }}
                        />
                    </div>
                </div>
                <div className={cn(grid.rowCenter, grid.p_md)}>{p_.title}</div>
            </div>
        </div>
    )
}

const mapStatToProps = (state: { user: { tokens: { accessToken: string; refreshToken: string } } }) => {
    return {
        tokens: state.user.tokens,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        startLoading: () => dispatch(loaderActions.startLoading()),
        endLoading: () => dispatch(loaderActions.endLoading()),
    }
}

export default connect(mapStatToProps, mapDispatchToProps)(InstancesCatalogFilter)
