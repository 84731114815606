import { addStyles } from 'ethcss'
import { grid, text } from 'theme'

const CATALOG_LIST_USER_AVATAR_SIZE = 40

const catalogTemplateUsersStyles = {
    catalogTemplateUsersNameWrapper: {
        ...grid.row,
        ...grid.noWrap,
        ...grid.normalCenter,
    },
    catalogTemplateUsersName: {
        ...text.noWrap,
        ...grid.ml_mdPlus,
    },
    catalogTemplateUserAvatar: {
        ...grid.rowCenter,
        width: CATALOG_LIST_USER_AVATAR_SIZE,
        height: CATALOG_LIST_USER_AVATAR_SIZE,
    },
    catalogTemplateUserIcon: {
        ...grid.ml_mdPlus,
        marginLeft: 0,
    },
    catalogTemplateADUserIcon: {
        marginLeft: '20px',
    },
}

addStyles(catalogTemplateUsersStyles)
export default catalogTemplateUsersStyles
