import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { defaultThemeStyles as defaultThemeToolbarStyles } from 'organisms/Toolbar/Toolbar-theme'
import { defaultThemeStyles as defaultThemeSidebarStyles } from 'blocks.app/sidebar/Sidebar-theme'
import { colors, IStyles, mediaCSS } from 'theme'
import { textSizes } from 'theme/media'

const buttonsHeight = 63
const displaysToolbarContentStyles: IStyles = {
    wrapper: {
        ...grid.fullHeight,
        minHeight: '100%',
    },
    toggleButton: {
        ...grid.w100,
        ...grid.rowCenter,
        ...item.white,
        height: buttonsHeight,
    },
    buttonTranslation: {
        background: `var(--var-module-app-component-app-style-backgroundSub, ${defaultThemeStyles.backgroundSub})`,
    },
    broadcasts: {
        ...grid.w100,
        ...item.darkCloud,
        minHeight: `calc(100% - ${buttonsHeight}px)`,
        color: `var(--var-module-app-component-app-style-textSub, ${defaultThemeStyles.textSub})`,
    },
    empty: {
        ...grid.rowCenter,
        ...grid.p_micro,
        textAlign: 'center',
        '& img': { ...grid.w100 },
    },
    displayInfo: {
        ...grid.w100,
        ...grid.col,
        ...grid.space,
        ...item.white,
        color: `var(--var-module-app-component-app-style-textSub, ${defaultThemeStyles.textSub})`,
    },
    centered: {
        ...grid.col,
        ...grid.center,
        ...grid.normalCenter,
    },
    broadcastPreview: {
        ...grid.w100,
        cursor: 'move',
    },
    broadcastItem: {
        ...grid.w50,
        ...grid.pr_mini,
        ...grid.pb_micro,
    },
    broadcastsList: {
        ...grid.row,
        textAlign: 'center',
        marginRight: -grid.mr_mini['margin-right'],
        marginBottom: -grid.mb_mini['margin-bottom'],
    },
    name: {
        ...grid.p_md,
        textAlign: 'center',
    },
    errorText: {
        color: colors.error,
        ...grid.p_md,
        ...grid.pb_none,
        textAlign: 'center',
    },
    menu: {
        ...item.rel,
        ...item.white,
        marginTop: '24px',
    },
    menuItem: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.center,
        ...grid.p_mini,
        ...item.pointer,
        ...item.relative,
        textAlign: 'center',
        ...item.transitionErase,
        ...item.pointer,
        color: `var(--var-module-sidebar-component-sidebar-style-menuTextHover, ${defaultThemeSidebarStyles.menuTextHover})`,
        '&:hover': {
            color: `var(--var-module-sidebar-component-sidebar-style-menuText, ${defaultThemeSidebarStyles.menuText})`,
        },
    },
    menuItemNormal: {
        textTransform: 'none',
    },
    description: {
        ...grid.mt_mdPlus,
        ...grid.mb_md,
        ...grid.w75,
        '&>p': {
            lineHeight: '22px',
        },
    },
    button: {
        ...grid.p_mini,
        textTransform: 'uppercase',
        background: `var(--var-module-app-component-app-style-background, ${defaultThemeStyles.background})`,
        border: 'none',
        color: `var(--var-module-app-component-app-style-textSub, ${defaultThemeStyles.textSub})`,
    },
    info: {
        ...grid.pr_md,
        ...grid.pl_md,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
        fontSize: textSizes.mini,
        ...item.rel,
        height: 42,
        zIndex: 2,
        color: `var(--var-module-toolbar-component-content-style-info, ${defaultThemeToolbarStyles['info']})`,
        flexWrap: 'nowrap !important',

        [mediaCSS.desktopL]: {
            padding: '0 10px',
        },
    },
    offline: {
        backgroundColor: `var(--var-module-sidebar-component-display-style-offline, ${defaultThemeSidebarStyles.offline})`,
    },
    online: {
        backgroundColor: `var(--var-module-app-component-app-style-online, ${defaultThemeStyles.online})`,
    },
    online_display_off: {
        background: '#005939',
        backgroundSize: 'cover',
    },
    error: {
        backgroundColor: `var(--var-module-toolbar-component-content-style-errorBackground, ${defaultThemeToolbarStyles['errorBackground']})`,
    },
    tags: {
        ...grid.mt_md,
        ...grid.rowCenter,
    },
    tagWrapper: {
        ...grid.p_micro,
    },
    tag: {
        ...grid.p_micro,
        ...item.selected,
        color: `var(--var-module-toolbar-component-toolbar-style-tag, ${defaultThemeToolbarStyles['tag']})`,
    },
    deleteGroup: {
        boxShadow: `var(--var-module-app-component-app-style-shadow, ${defaultThemeStyles.shadow})`,
        ...item.abs,
        ...item.white,
        top: 0,
        left: 0,
        right: 0,
    },
    removeGroupButton: {
        ...grid.p_mini,
        ...grid.rowCenter,
        ...item.pointer,
        '&:hover': {
            color: `var(--var-module-app-component-app-style-textSub, ${defaultThemeStyles.textSub})`,
        },
    },
    removeAll: {
        ...grid.p_mini,
        ...grid.rowCenter,
        background: `var(--var-module-app-component-app-style-background, ${defaultThemeStyles.background})`,
        ...item.pointer,
        textAlign: 'center',
        '& .input_checkbox__label': {
            color: `var(--var-module-app-component-app-style-textSub, ${defaultThemeStyles.textSub})`,
        },
        '&:hover': {
            color: `var(--var-module-app-component-app-style-textSub, ${defaultThemeStyles.textSub})`,
        },
    },
    arrowDelete: {
        position: 'absolute',
        bottom: -4,
    },
    closeBtn: {
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',

        [mediaCSS.tab]: {
            bottom: 40,
        },
    },
    lastUpdateDate: {
        padding: '0 8px',
        textAlign: 'center',
    },
    volumeDependencyInfo: {
        padding: 10,
        textAlign: 'center',
    },
}

addStyles(displaysToolbarContentStyles)
export default displaysToolbarContentStyles
