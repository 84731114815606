import { addStyles } from 'ethcss'
import { IStyles, text, grid, item } from 'theme'
import { indents } from 'theme/media'
import { colors } from 'theme/colors'
import { defaultThemeStyles } from '../index.theme'

export const styles: IStyles = {
    CatalogItemVideoChat__wrapper() {
        return {
            ...grid.col,
            ...item.miniShadow,
            ...item.pointer,
            ...item.overNone,
        }
    },
    CatalogItemVideoChat__groupWrapper() {
        return {
            ...item.hover,
            ...item.pointer,
        }
    },
    CatalogItemVideoChat__group: {
        ...grid.col,
        ...grid.full,
        ...item.miniShadow,
        ...item.cover,
        ...item.rel,
        ...item.overNone,
        zIndex: 10,
        height: '90%',
        top: '10%',
    },
    CatalogItemVideoChat__groupBackWrapper: {
        ...grid.full,
        ...grid.colCenter,
        ...item.abs,
        top: 0,
        left: 0,
        zIndex: 1,
    },
    CatalogItemVideoChat__groupBack: {
        ...grid.fullHeight,
        ...item.miniShadow,
        position: 'relative',
    },
    CatalogItemVideoChat__groupBack_first: {
        width: '94%',
        height: '90%!important',
        backgroundColor: `var(--var-module-catalog-component-item-style-backgroundSub, ${defaultThemeStyles.backgroundSub})`,
    },
    CatalogItemVideoChat__groupBack_second: {
        width: '88%',
    },
    CatalogItemVideoChat__background_type_offline: {
        backgroundColor: `var(--var-module-catalog-component-item-style-offline, ${defaultThemeStyles.offline})`,
    },
    CatalogItemVideoChat__background_type_online: {
        background: `var(--var-module-catalog-component-item-style-online, ${defaultThemeStyles.online})`,
    },
    CatalogItemVideoChat__background_type_error: {
        backgroundColor: `var(--var-module-catalog-component-item-style-error, ${defaultThemeStyles.error})`,
    },
    CatalogItemVideoChat__content: {
        ...grid.space,
        ...grid.colCenter,
        ...grid.auto,
        position: 'relative',
    },
    CatalogItemVideoChat__title: {
        ...grid.p_micro,
        ...text.normalPlus,
        ...item.ellipsis,
        color: colors.white,
    },
    CatalogItemVideoChat__header: {
        padding: indents.micro,
        ...grid.flex,
        ...grid.center,
    },
    CatalogItemVideoChat__footer: {
        padding: indents.micro,
        ...grid.flex,
        ...grid.center,
    },
}

addStyles(styles)
