import React, { useState } from 'react'
import Content from 'blocks.app/content/content'
import Catalog from 'blocks.app/catalog/catalog'
import AdBlocksToolbar from './__toolbar/adBlocks__toolbar'
import { routes } from 'features/routes'
import translate from 'core/translate'
import { getURLSearchParamsString } from 'features/routes/utils'
import { changeLocation } from 'features/routes'
import { checkAvailableModule } from '../../core/helpers/routes/routes'
import styles from './adBlocks.jcss'

const AdBlocks = () => {
    const [selectedInfo, setSelectedInfo] = useState(null)
    const [selectedItems, setSelectedItems] = useState([])

    return (
        <Content
            wrapperClassName={styles.adBlockWrapper}
            title={translate('adBlocks')}
            toolbar={
                <AdBlocksToolbar
                    selectedInfo={selectedInfo}
                    selectedItems={selectedItems}
                    onCloseInfo={setSelectedInfo}
                    onChange={setSelectedItems}
                />
            }
        >
            <Catalog
                type="fillersAdBlocks"
                onSelect={setSelectedItems}
                selectedItems={selectedItems}
                selectedInfo={selectedInfo}
                onSelectInfo={setSelectedInfo}
                onDoubleClickObject={(fillersAdBlock: { id: number }) => {
                    changeLocation({
                        pathname: `/${routes.editFillersAdBlock.path}`,
                        search: getURLSearchParamsString({
                            advBlockId: fillersAdBlock.id,
                        }),
                    })
                }}
            />
        </Content>
    )
}

const exportAdBlocks = checkAvailableModule('adBlocks') ? AdBlocks : null

export default exportAdBlocks
