import { addStyles } from 'ethcss'
import { grid, item } from 'theme'

const catalogStyles = {
    wrapper: {
        ...grid.col,
        ...grid.full,
        ...item.overNone,
    },
    wrapperScroll: {
        ...item.overNone,
        ...item.overY,
    },
    indentsListWrapper: {
        ...grid.mt_mdPlus,
    },
    listWrapper: {
        ...grid.fullWidth,
    },
    hotKeysWrapper: {
        ...grid.p_micro,
        ...grid.pt_none,
        ...item.overY,
        '&:focus': {
            outline: 'none',
        },
    },
    emptyMessage: {
        ...grid.full,
        ...grid.colCenter,
    },
}

addStyles(catalogStyles)
export default catalogStyles
