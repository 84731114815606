import React from 'react'

class DropMenuMethods extends React.PureComponent<{
    items: any
    onChange: (value: any) => void
    onClick?: (value: string) => void
    contentHeight?: boolean
    gray?: boolean
    active: any
    className?: string
    contentClassName?: string
    buttonWrapperClassName?: string
    mod?: string
}> {
    toggleSubMenu(e: any, id: string) {
        const p_ = this.props
        let activeToggleItems: any = p_.items.filter((item: any) => item.toggle && this.isActive(item.id))
        activeToggleItems = activeToggleItems.map((item: { id: number }) => item.id)

        e.stopPropagation()

        if (this.isActive(id)) {
            this.closeItems(id)
        } else {
            p_.onChange([...activeToggleItems, id])
        }
    }
    handleClickOutside() {
        this.closeItems()
    }
    isActive = (id: string) => {
        const p_ = this.props

        return p_.active.indexOf(id) !== -1
    }
    closeItems = (id?: any) => {
        const p_ = this.props

        let activeToggleItems = p_.items.filter((item: any) => item.id !== id && item.toggle && this.isActive(item.id))
        const active = activeToggleItems.map((item: any) => item.id)
        p_.onChange(active)
    }
}

export default DropMenuMethods
