import React, { useEffect, useState } from 'react'
import { api } from '../../../../core/api/ConnectionManager'
import styles from './instancesCatalog_daysAndClasses.jcss'
import translate from '../../../../core/translate'
import { cn } from 'ethcss'

const days = [
    translate('monday'),
    translate('tuesday'),
    translate('wednesday'),
    translate('thursday'),
    translate('friday'),
    translate('saturday'),
]

const DaysAndClasses = ({ selectedClassNumber, selectedClassLetter, day, onDataChange }: any) => {
    const [classList, setClassList] = useState<{ classNumber: string; classLetters: string[] }[]>([])

    useEffect(() => {
        api.send('getSchoolClasses', {}).then((res: any) => setData(res.data))
    }, [])

    const setData = (data: any) => {
        setClassList(data)
    }

    const getClassLetters = (selectedClassNumber: string) => {
        const findedClass: any = classList.find((classElement: any) => classElement.classNumber === selectedClassNumber)

        return findedClass ? findedClass.classLetters : []
    }

    return (
        <div>
            <div className={styles.days}>
                {days.map((day_: any, index: number) => (
                    <div
                        className={cn(styles.day, index + 1 === day ? styles.active : '')}
                        onClick={() => onDataChange('day', index + 1)}
                    >
                        {day_}
                    </div>
                ))}
            </div>
            <div className={styles.classNumbers}>
                {classList.map((classroom) => {
                    return (
                        <div
                            className={cn(
                                styles.classNumber,
                                classroom.classNumber === selectedClassNumber ? styles.active : ''
                            )}
                            onClick={() => {
                                onDataChange('selectedClassNumber', classroom.classNumber)
                            }}
                        >
                            {classroom.classNumber}
                        </div>
                    )
                })}
            </div>
            <div className={styles.classLetters}>
                {getClassLetters(selectedClassNumber).map((letter: any) => {
                    return (
                        <div
                            className={cn(styles.classLetter, letter === selectedClassLetter ? styles.active : '')}
                            onClick={() => {
                                onDataChange('selectedClassLetter', letter)
                            }}
                        >
                            {letter.toUpperCase()}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default DaysAndClasses
