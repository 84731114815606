import React, { memo } from 'react'
import DndDraggable from 'blocks.app/dnd/_draggable/dnd_draggable'
import DndDroppable from 'blocks.app/dnd/_droppable/dnd_droppable'
import { Icon } from 'molecules/Icon'
import helpers from 'core/helpers'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { treeViewCatalogListStyles as styles } from './TreeViewCatalogList-styles'
import { mediaJS, indents } from 'theme'
import { TTreeViewTypeInstances } from 'core/helpers/treeView'
import { TActionType } from 'core/models/Roles'
import {
    IActionIcon,
    IRequiredActionsForActionMap,
    IActionToPermission,
    IActionToPermissionMap,
    ITreeViewCatalogListComponent,
    ITreeViewCatalogListContentComponent,
    IActionIconTooltip,
} from './TreeViewCatalogList-types'

const EXPAND_ICON_SIZE = 16

const PERMISSIONS_ICONS: IActionIcon[] = [
    {
        type: 'book',
        action: 'read',
        tooltip: 'reading',
    },
    {
        type: 'pencil',
        action: 'update',
        tooltip: 'updating',
    },
    {
        type: 'drag_document',
        action: 'replace',
        tooltip: 'replacing',
    },
    {
        type: 'trash_v2',
        action: 'delete',
        tooltip: 'deleting',
    },
]

const REQUIRED_ACTIONS_FOR_ACTION: IRequiredActionsForActionMap = {
    update: ['read'],
    delete: ['read', 'update'],
    replace: ['read', 'delete', 'update'],
}

const clonePermission = (permission: IActionToPermission): IActionToPermission => {
    //@ts-ignore
    return helpers.deepCopy(permission)
}

const getMarginLeftForCell = (isExpandElement: boolean, nestedLevel: number, isFolder: boolean) => {
    const initIndent = mediaJS.tabMini.matches ? 20 : 40
    let marginLeft = initIndent * nestedLevel

    if (isExpandElement && !isFolder) {
        marginLeft += indents.mini + EXPAND_ICON_SIZE
    }

    return { marginLeft }
}

const getFilterByPropertyObject = (type: TTreeViewTypeInstances) => {
    switch (type) {
        case 'displays':
        case 'digitalSignages':
            return {
                groupId: [],
                digitalSignageId: [],
            }
        case 'files':
            return {
                sourceId: [],
                sourceFolderId: [],
            }
        case 'broadcasts':
            return {
                broadcastId: [],
                broadcastFolderId: [],
            }
        default:
            return { id: [], folderId: [] }
    }
}

const getDefaultActionModel = (model: IActionToPermission, catalog: TTreeViewTypeInstances) => {
    return {
        ...model,
        active: true,
        filterByProperty: {
            ...getFilterByPropertyObject(catalog),
        },
    }
}

const getFilterByPropertyKey = (type: TTreeViewTypeInstances, isDirectory: boolean) => {
    switch (type) {
        case 'digitalSignages':
        case 'displays':
            if (isDirectory) {
                return 'groupId'
            }

            return 'digitalSignageId'
        case 'files':
            if (isDirectory) {
                return 'sourceFolderId'
            }

            return 'sourceId'
        case 'broadcasts':
            if (isDirectory) {
                return 'broadcastFolderId'
            }

            return 'broadcastId'
        default:
            if (isDirectory) {
                return 'folderId'
            }

            return 'id'
    }
}

const TreeViewCatalogListPermissionsIcon = ({
    onClick,
    type,
    action,
    active,
    disabled,
    tooltip,
}: {
    onClick: (action: TActionType) => void
    type: string
    action: TActionType
    active: boolean
    disabled?: boolean
    tooltip: IActionIconTooltip
}) => {
    const handleOnClick = () => {
        onClick(action)
    }

    const getIconColor = () => {
        const icon = {
            color: active ? 'blue' : 'cloud',
            type,
        }

        if (disabled) {
            icon.color = 'blueLight'
            icon.type = `inherit_${type}`
        }

        return icon
    }

    const iconState = getIconColor()

    return (
        <Icon
            className={styles.permissionsIcon}
            type={iconState.type}
            onClick={handleOnClick}
            color={iconState.color}
            disabled={disabled}
            tooltip={tooltip}
        />
    )
}

const TreeViewCatalogListPermissionsIcons = ({
    onChangePermissions,
    permissions,
    listItem,
    catalogType,
    template,
}: {
    onChangePermissions: any
    permissions: IActionToPermissionMap
    listItem: any
    catalogType: TTreeViewTypeInstances
    template: any
}) => {
    const getStatus = (action: TActionType) => {
        const currentPermission = permissions[action]
        let isFolder = false

        if (!currentPermission || !currentPermission.hasOwnProperty('filterByProperty')) {
            return false
        }

        if (template.isFolder(listItem)) {
            isFolder = true
        }

        const array = currentPermission.filterByProperty[getFilterByPropertyKey(catalogType, isFolder)]

        return array && array.includes(listItem.id)
    }

    const handleOnClick = (action: TActionType) => {
        let currentPermission = permissions[action]
        let isFolder = false
        let isSetRequired = false
        let isDeleteRequired = false

        if (!currentPermission.hasOwnProperty('filterByProperty')) {
            currentPermission = getDefaultActionModel(permissions[action], catalogType)
        }

        if (template.isFolder(listItem)) {
            isFolder = true
        }

        const array = currentPermission.filterByProperty[getFilterByPropertyKey(catalogType, isFolder)]

        if (array.includes(listItem.id)) {
            array.splice(array.indexOf(listItem.id), 1)
            isDeleteRequired = true
        } else {
            array.push(listItem.id)
            isSetRequired = true
        }

        const required = REQUIRED_ACTIONS_FOR_ACTION[action]
        let newPermissions: IActionToPermissionMap = {}

        if (required && isSetRequired) {
            required.forEach((requiredAction: TActionType) => {
                let requiredPermission = permissions[requiredAction]

                if (requiredPermission) {
                    if (!requiredPermission.hasOwnProperty('filterByProperty')) {
                        requiredPermission = getDefaultActionModel(requiredPermission, catalogType)
                    }

                    let updatedPermission = clonePermission(requiredPermission)
                    const array = updatedPermission.filterByProperty[getFilterByPropertyKey(catalogType, isFolder)]

                    if (!array.includes(listItem.id)) {
                        array.push(listItem.id)
                    }

                    newPermissions[requiredAction] = updatedPermission
                }
            })
        }

        let deletedPermissions: IActionToPermissionMap = {}

        if (isDeleteRequired) {
            Object.entries(permissions).forEach(([permAction, permission]) => {
                let requiredActions = REQUIRED_ACTIONS_FOR_ACTION[permAction]

                if (requiredActions && requiredActions.includes(action)) {
                    if (!permission.hasOwnProperty('filterByProperty')) {
                        permission = getDefaultActionModel(permission, catalogType)
                    }

                    let newPermission = clonePermission(permission)

                    const permissionEntityList =
                        newPermission.filterByProperty[getFilterByPropertyKey(catalogType, isFolder)]

                    if (permissionEntityList.includes(listItem.id)) {
                        permissionEntityList.splice(permissionEntityList.indexOf(listItem.id), 1)
                    }

                    deletedPermissions[permAction] = newPermission
                }
            })
        }

        onChangePermissions({ ...newPermissions, [action]: currentPermission, ...deletedPermissions })
    }

    const getIconTooltipText = (disabled: boolean, icon: IActionIcon): IActionIconTooltip => {
        let tooltipText = icon.tooltip

        if (disabled) {
            if (catalogType === 'displays') {
                tooltipText = 'inherit_group_permissions'
            } else {
                tooltipText = 'inherit_folder_permissions'
            }
        }

        return {
            title: translate(tooltipText),
        }
    }

    return (
        <td>
            <div className={styles.permissionsIconsWrapper}>
                {PERMISSIONS_ICONS.map((icon) => {
                    if (catalogType !== 'displays' && icon.action === 'replace') {
                        return null
                    }

                    if (!Object.keys(permissions).includes(icon.action)) {
                        return null
                    }

                    const disabled = listItem.__view[`disabled_${icon.action}`]

                    return (
                        <TreeViewCatalogListPermissionsIcon
                            key={icon.type}
                            type={icon.type}
                            action={icon.action}
                            active={getStatus(icon.action)}
                            disabled={disabled}
                            onClick={handleOnClick}
                            tooltip={getIconTooltipText(disabled, icon)}
                        />
                    )
                })}
            </div>
        </td>
    )
}

const TreeViewCatalogListContent = (p_: ITreeViewCatalogListContentComponent) => {
    const dragData = p_.template.getDragData({ item: p_.listItemData.listItem })
    const onDrop = (data: any, e: any) => {
        e.stopPropagation()

        const droppedItem = JSON.parse(data.dnd_item)

        const { listItem } = p_.listItemData

        if (p_.template.isEqual(droppedItem, listItem)) {
            return
        }
        const moveMethod = p_.template.isFolder(droppedItem) ? p_.template.moveFolder : p_.template.moveItem
        moveMethod(droppedItem.id, listItem.id)
    }
    const { listItem, options } = p_.listItemData
    const isFolder = p_.template.isFolder(listItem)

    return (
        <DndDroppable
            tag="tr"
            data={dragData}
            dragEnabled={p_.drag}
            dropEnabled={isFolder}
            wrapperComponent={{
                type: DndDraggable,
            }}
            onDragStart={p_.onDragStart}
            onDragEnd={p_.onDragEnd}
            onDrop={onDrop}
            className={styles.treeViewCatalogListRow}
        >
            {options.map((option: any, index: number) => (
                <td
                    key={`treeViewCatalogListItem_${listItem.id}_${index}`}
                    className={cn(styles.treeViewCatalogListTableCell)}
                    style={getMarginLeftForCell(option.expanded, p_.nestedLevel, isFolder)}
                >
                    {option.expanded && isFolder && (
                        <div className={styles.treeViewCatalogListTableCellIcon}>
                            <Icon
                                onClick={(e) => {
                                    e.stopPropagation()
                                    p_.onFolderOpening(listItem, p_.nested)
                                }}
                                size={EXPAND_ICON_SIZE}
                                type={listItem.__view.isOpen ? 'arrow_down' : 'arrow_right'}
                            />
                        </div>
                    )}
                    {p_.getCellValue(listItem, option.id)}
                </td>
            ))}
            {p_.permissions && (
                <TreeViewCatalogListPermissionsIcons
                    permissions={p_.permissions}
                    onChangePermissions={p_.onChangePermissions}
                    listItem={listItem}
                    catalogType={p_.type}
                    template={p_.template}
                />
            )}
        </DndDroppable>
    )
}

const TreeViewCatalogListComponent = (p_: ITreeViewCatalogListComponent) => {
    const getCellValue = (listItem: any, id: string) => {
        const cellRender = p_.template.list

        if (cellRender && cellRender[id]) {
            return cellRender[id](listItem, p_)
        }

        const value = listItem[id]

        if (typeof value === 'object') {
            return null
        }

        return value
    }

    const renderTree = (items: any, options: any) => {
        let copiedItems: any = helpers.deepCopy(items)
        const folders = copiedItems.map((item: any, index: number) => {
            item.__view.index = index
            return item
        })
        const nested = folders.filter((folder: any, index: number, array: any) => {
            const parent = array.find(
                (parentItem: any) =>
                    p_.template.isFolder(parentItem) && parentItem.id === folder[folder.__view.nestedKey]
            )

            if (!parent) {
                return true
            }

            if (p_.permissions) {
                Object.keys(p_.permissions).forEach((actionKey) => {
                    const action = p_.permissions[actionKey]

                    if (!action.hasOwnProperty('filterByProperty')) {
                        return
                    }

                    const array = action.filterByProperty[getFilterByPropertyKey(p_.type, p_.template.isFolder(parent))]

                    if (array && (array.includes(parent.id) || parent.__view[`disabled_${actionKey}`])) {
                        folder.__view[`disabled_${actionKey}`] = true
                    }
                })
            }

            ;(parent.children = parent.children || []).push(folder)

            return false
        })

        const elements: any = []

        const loopOptions = (data: any, nestedLevel: number) => {
            data.forEach((elem: any) => {
                const isFolder = p_.template.isFolder(elem) ? 'folder' : 'item'

                elements.push(
                    <TreeViewCatalogListContent
                        {...p_}
                        getCellValue={getCellValue}
                        key={`treeViewCatalogListRow_${elem.__view.index}`}
                        listItemData={{ listItem: elem, index: elem.__view.index, isFolder, options }}
                        nestedLevel={nestedLevel}
                    />
                )

                if (elem.children && elem.__view.isOpen) {
                    const counter = nestedLevel + 1
                    loopOptions(elem.children, counter)
                }
            })
        }

        loopOptions(nested, 0)
        return elements
    }

    const getHeader = () => {
        let iconWidth = 0
        if (p_.permissions) {
            iconWidth = 20
        }

        const thWidths = (100 - iconWidth) / p_.template.fields().length
        const trStyles = { width: `${thWidths}%` }

        return (
            <thead>
                {p_.template.fields().map((field: any) => (
                    <tr style={trStyles} key={field.name}></tr>
                ))}
                {p_.permissions && <tr style={trStyles}></tr>}
            </thead>
        )
    }

    return (
        <table className={cn(styles.treeViewCatalogListTableWrapper, p_.template.tableWrapper)}>
            {getHeader()}
            <tbody className={styles.catalogListTableBody}>{renderTree(p_.list, p_.template.fields())}</tbody>
        </table>
    )
}

export const TreeViewCatalogList = memo(TreeViewCatalogListComponent)
