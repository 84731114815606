import { IUserState } from 'blocks.app/user/user.state'
import helpers from 'core/helpers'
import { IUser, IUserTokens } from 'core/models/Users'
import { changeLocation } from '../history'
import { isExist } from 'core/utils/coreUtil'
import { getURLSearchParamsByLocation, getURLSearchParamsString } from 'features/routes/utils'
import { Location } from 'history'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { findRoute, routes } from '../utils'
import { IRoutesState, routesActions } from '../routes.state'
import { IRoute } from '../routes-types'
import { useTypedSelector } from 'core/store/hooks'

export const useRoutesGuard = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const [prevLocation, setPrevLocation] = useState<Location | null>(null)
    const { redirectLocation } = useTypedSelector<IRoutesState>((state) => state.routes)
    const { data, tokens } = useTypedSelector<IUserState>((state) => state.user)

    useEffect(() => {
        setPrevLocation(location)
    }, [location])

    useEffect(() => {
        checkAuth(tokens, data, location)
    }, [tokens, location])

    const redirectToLogin = (user: IUser | null) => {
        const locale = user?.settings?.locale

        changeLocation({
            pathname: `/${routes.login.path}`,
            search: locale ? getURLSearchParamsString({ lang: locale }) : '',
        })
    }

    const isEqualLocations = (next: Location, prev: Location) => {
        return next.pathname === prev.pathname && next.search === prev.search && next.hash === prev.hash
    }

    const redirectToDashboard = () => changeLocation(`/${routes.dashboard.path}`)

    const updateLocationPathname = (location: Location, route: IRoute) => {
        return {
            ...location,
            pathname: `/${route.redirectPath}`,
        }
    }

    const updateLocationQuery = (location: Location, route: IRoute) => {
        const prefix = route.prefix
        const query = getURLSearchParamsByLocation(location)

        const updatedQuery = Object.keys(query).reduce((result, key) => {
            const updatedKey = prefix ? `${prefix}_${key}` : `${key}`

            return { ...result, [updatedKey]: query[key] }
        }, {})

        return {
            ...location,
            search: getURLSearchParamsString(updatedQuery),
        }
    }

    const updateLocation = (location: Location, route: IRoute) => {
        if (!route.redirectPath) return location

        const updatedLocation = updateLocationPathname(location, route)
        return updateLocationQuery(updatedLocation, route)
    }

    const saveRedirectLocation = (location: Location) => {
        dispatch(routesActions.setRedirectLocation(location))
    }

    const redirectToLocation = (location: Location) => {
        const savedRedirectLocation = { ...location }
        dispatch(routesActions.clearRedirectLocation())

        changeLocation(savedRedirectLocation)
    }

    const checkAuth = (tokens: IUserTokens | null, user: IUser | null, location: Location) => {
        const pathname = helpers.getPathname(location.pathname)
        const pathnameWithoutSlash = helpers.getPathname(pathname)
        const isAuth = isExist(tokens) && isExist(user)
        const route = findRoute(pathnameWithoutSlash)

        if (!route) return redirectToLogin(user)

        const isAuthRoute = route.isAuth
        const updatedLocation = updateLocation(location, route)

        if (!isAuth && !isAuthRoute) {
            const trimSearchLocation = { ...updatedLocation, search: '' }

            saveRedirectLocation(trimSearchLocation)
            return redirectToLogin(user)
        }

        if (!isAuth && isAuthRoute) return

        if (isAuth && isAuthRoute) return redirectToDashboard()

        if (location && prevLocation && isEqualLocations(location, prevLocation)) return

        if (redirectLocation) return redirectToLocation(redirectLocation)

        changeLocation(updatedLocation)
    }
}
