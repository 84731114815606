import 'blocks.app/dnd/_touchPolyfill/dnd_touchPolyfill'
import './index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import store from 'core/store'
import serviceWorker, { unregister } from './registerServiceWorker'
import { mediaCSS as mediaCSSLegacy } from 'blocks.app/config'
import { mediaCSS } from 'theme'
import { init } from 'ethcss'
import Root from 'core/root'

init({ media: mediaCSSLegacy })
init({ media: mediaCSS })

const __root_app__ = document.getElementById('root')

ReactDOM.render(<Root store={store} />, __root_app__)

if (module.hot) {
    module.hot.accept('core/root', () => {
        const NextRoot = require('core/root').default
        ReactDOM.render(<NextRoot store={store} />, __root_app__)
    })
}

window.addEventListener('unhandledrejection', (event) => {
    event.preventDefault()
    console.error(event.reason)
})

unregister()
