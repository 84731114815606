import React from 'react'
import EditableText from 'blocks.simple/editableText/editableText'
import Button from 'blocks.simple/button/button'
import CatalogSelectContent from 'blocks.app/catalog/_select/_content/catalog_select_content'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import grid from 'blocks.simple/grid/grid.jcss'
import { cn } from 'ethcss'
import helpers from 'core/helpers'

export default {
    getMethod: 'samsoniteGetCatalog',
    updateCatalogMethod: 'samsoniteUpdateCatalog',
    onCatalogUpdatedMethod: 'samsoniteCatalogUpdated',
    updateMethod: 'samsoniteUpdateCatalogItem',
    updateWebAppMethod: 'samsoniteUpdateWebApp',
    catalogSelectedSource: 'samsoniteCatalogSelectedSource',
    emptyDescription: () => translate('noItemsDescription'),
    emptyIcon: 'not_allow',
    settingsPrefixes: 'samsonite',
    ...helpers.createListenersWidget({
        onCreate: 'samsoniteItemCreated',
        onUpdate: 'samsoniteItemUpdated',
        onDelete: 'samsoniteItemDeleted',
    }),
    getItemModel: (
        dataItem: { __view: { selected: boolean }; source: { __view: { selected: boolean } } },
        getSelected: (item: { __view: { selected: boolean }; source: { __view: { selected: boolean } } }) => any
    ) => {
        const item = { ...dataItem }

        if (item.source && !item.source.__view) {
            item.source.__view = {
                selected: getSelected(item),
            }
        }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    isEqual: (firstItem: { id: number }, secondItem: { id: number }) => firstItem.id === secondItem.id,
    fields: () => [
        { id: 'source', name: translate('videos') },
        { id: 'name', name: translate('name') },
    ],
    init(callback: any) {
        // @ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        name: (
            p_: { id: number; name: string },
            active: boolean,
            listProps: {
                fakeLine: { name: string; source: { id: number } }
                onAddFakeLine: (value?: {}) => void
                source: { id: number }
                catalogId: number
                template: any
            }
        ) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.id })}>
                <div className={cn({ [grid.fullWidth]: p_.id, [grid.space]: !p_.id })}>
                    <EditableText
                        id={p_.id}
                        text={p_.name}
                        onChange={(name: string) => {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, name })
                        }}
                        onUpdate={(name, cb) => {
                            api.send('samsoniteUpdateCatalogItem', {
                                name,
                                id: p_.id,
                                catalogId: listProps.catalogId,
                                category: listProps.template.category,
                            }).then(cb)
                        }}
                    />
                </div>
                {!p_.id && (
                    <Button
                        className={cn(grid.ml_micro, grid.mr_mini)}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            listProps.onAddFakeLine()
                        }}
                    >
                        {translate('cancel')}
                    </Button>
                )}
                {!p_.id && (
                    <Button
                        mod={'fill'}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            api.send('samsoniteCreateCatalogItem', {
                                catalogId: listProps.catalogId,
                                category: listProps.template.category,
                                sourceId: listProps.fakeLine.source.id,
                                name: listProps.fakeLine.name,
                            })
                        }}
                        disabled={!listProps.fakeLine.name || !listProps.fakeLine.source}
                    >
                        {translate('save')}
                    </Button>
                )}
            </div>
        ),
        source: (
            p_: { id: number; source: { id: number } },
            active: boolean,
            listProps: {
                fakeLine: {}
                onAddFakeLine: (value?: {}) => void
                catalogId: number
                template: any
            }
        ) => (
            <div className={cn(grid.fullWidth, grid.colCenter)}>
                <div className={p_.source ? grid.w15 : null}>
                    <CatalogSelectContent
                        type={'video'}
                        selectedInfo={p_.source}
                        extensions={['mp4']}
                        onSelect={(selectedMedia: { name: string; id: number }) => {
                            if (p_.id) {
                                api.send('samsoniteUpdateCatalogItem', {
                                    id: p_.id,
                                    catalogId: listProps.catalogId,
                                    category: listProps.template.category,
                                    sourceId: selectedMedia.id,
                                })
                            } else {
                                listProps.onAddFakeLine({ name: selectedMedia.name, source: selectedMedia })
                            }
                        }}
                    />
                </div>
            </div>
        ),
    },
    draggable: true,
    onDeleteListItem: (listItem: { id: number }) => {
        api.send('samsoniteDeleteCatalogItem', { id: listItem.id })
    },
    fixedColWidth: true,
}
