import { addStyles } from 'ethcss'
import { IStyles, mediaCSS, colors } from '../../theme'

const ipTvStyles: IStyles = {
    ipTv: {
        [mediaCSS.tabMini]: {
            paddingTop: 60,
        },
    },
    tabsWrapper: {
        display: 'flex',
        marginBottom: 14,
    },
    tabBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '40px',
        color: '#000000',
        transition: 'all 0.2s',
        borderRadius: '7px',
        marginRight: '10px',

        '&:last-child': {
            marginRight: 0,
        },

        '&:hover': {
            backgroundColor: '#DBDBDB',
            cursor: 'pointer',
            borderColor: '#DBDBDB',
        },
    },
    tabActive: {
        background: '#DBDBDB',
    },
    wrapper: {
        border: `1px solid ${colors.grey}`,
        borderRadius: 5,
        overflow: 'hidden',
    },
    channelsHeader: {
        fontWeight: 700,
        borderTop: `1px solid ${colors.grey}`,
    },
    checkboxHeader: {
        marginLeft: 34,
    },
    listWrapper: {
        position: 'relative',
    },
    checkbox: {
        display: 'flex',
        minWidth: 92,
    },
    handle: {
        marginRight: 20,
    },
    channelName: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginLeft: 40,
        width: '25%',
        minWidth: '60px',
        [mediaCSS.desktopML]: {
            width: '20%',

            [mediaCSS.desktopLM]: {
                marginLeft: 40,
                width: '15%',
            },
        },
    },
    channelNumber: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '20%',

        marginLeft: 40,
        minWidth: 50,

        [mediaCSS.desktopLM]: {
            marginLeft: 20,
            width: '15%',
            [mediaCSS.desktopLM]: {
                width: '10%',
            },
        },
    },
    channelUrl: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginLeft: 60,
        width: '20%',
        minWidth: '60px',
        [mediaCSS.desktopML]: {
            width: '15%',

            [mediaCSS.desktopLM]: {
                width: '10%',
            },
        },
    },
    channelLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 40,
        width: 60,

        [mediaCSS.desktopLM]: {
            marginLeft: 20,
        },

        img: {
            maxWidth: '100%',
            maxHeight: '100%',
        },
    },
    tableDisplay: {
        flexWrap: 'wrap',
        display: 'table',
        width: '100%',
    },
    widthFull: {
        width: '100%',
    },
    scrollWrapper: {
        overflowY: 'hidden',
        overflowX: 'auto',
        width: '100%',
    },
    channelCategory: {
        width: '20%',
        marginLeft: 60,
        minWidth: '60px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        [mediaCSS.desktopML]: {
            width: '15%',
            marginLeft: 40,
        },
    },
    changeButtons: {
        display: 'flex',
        position: 'absolute',
        right: 14,
        top: '50%',
        transform: 'translateY(-50%)',
        opacity: 0,
        transition: 'opacity 0.3s',

        '& >div': {
            marginLeft: 10,
        },
    },
    channel() {
        const self = this

        return {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            borderTop: `1px solid ${colors.grey}`,
            padding: 14,
            height: 70,
            overflow: 'hidden',
            lineHeight: 1.2,
            '&:hover': {
                [`& .${self.changeButtons}`]: {
                    opacity: 1,
                },
            },

            [mediaCSS.tab]: {
                paddingRight: 66,
            },
        }
    },

    categoriesHeader: {
        fontWeight: 700,
        borderTop: `1px solid ${colors.grey}`,
    },
    categoryName: {
        marginLeft: 40,
        width: 'calc(100% - 308px)',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        [mediaCSS.tabMini]: {
            width: '100%',
        },
    },
    category() {
        const self = this

        return {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            borderTop: `1px solid ${colors.grey}`,
            padding: 14,
            height: 70,
            lineHeight: 1.2,
            overflow: 'hidden',

            '&:hover': {
                [`& .${self.changeButtons}`]: {
                    opacity: 1,
                },
            },

            [mediaCSS.tab]: {
                paddingRight: 66,
            },
        }
    },

    searchBar: {
        display: 'flex',
        height: 40,
        border: `1px solid ${colors.grey}`,
        borderRadius: 5,
        marginBottom: 14,
    },
    search: {
        position: 'relative',
        width: '85%',

        [mediaCSS.tab]: {
            width: '70%',
        },
    },
    dropdown: {
        width: '15%',

        [mediaCSS.tab]: {
            width: '30%',
        },
    },
    searchInput: {
        border: 'none',
    },
    dropdownBtn: {
        button: {
            border: 'none',
            borderRadius: '0 !important',
            borderLeft: `1px solid ${colors.grey}`,
            background: 'none',
        },
    },
    checkboxItem: {
        backgroundColor: 'red',
    },
    deleteConfirmWrapper: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10000,
        backgroundColor: 'rgba(0,0,0,0.7)',
    },
    deleteConfirm: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        width: '340px',
        transform: 'translate(-50%, -50%)',
        background: colors.white,
        textAlign: 'center',
        padding: 34,
        borderRadius: 20,
    },
    controls: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '24px auto 0 auto',
        maxWidth: 210,
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        width: 100,
    },
}

addStyles(ipTvStyles)
export default ipTvStyles
