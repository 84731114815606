import React from 'react'
import styles from './notifyBadge.jcss'
import { cn } from 'ethcss'
import { text as textStyles } from 'theme'

interface INotifyBadge {
    label: string | number | React.ReactNode
    type?: string | null
    className?: string | {}
    textSize?: string
}

const NotifyBadge = ({ label, type, textSize, className }: INotifyBadge) => {
    const containerStyles = cn(styles.item, type ? styles[type] : '', textSize ? textStyles[textSize] : '', className)

    return <span className={containerStyles}>{label}</span>
}

NotifyBadge.defaultProps = {
    textSize: 'micro',
    type: 'normal',
}

export default NotifyBadge
