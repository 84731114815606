import { useLayoutEffect, useState } from 'react'

export interface IUseWindowResize {
    width: number
    height?: number
}

export const useWindowResize = (initSize: IUseWindowResize): IUseWindowResize => {
    const [size, setSize] = useState(initSize)

    useLayoutEffect(() => {
        const handleResize = () => {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    })

    return size
}
