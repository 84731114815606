import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { mediaCSS, textSizes } from 'theme/media'

const instancesCatalogStyles = {
    wrapper: {
        ...grid.row,
        ...grid.mt_md,
        marginLeft: -grid.ml_md['margin-left'],
        marginBottom: -grid.mb_md['margin-bottom'],
        overflow: 'hidden',
    },
    cellWrapper: {
        ...grid.pb_mdPlus,
        ...grid.pl_mdPlus,
        ...item.transitionErase,
        position: 'relative',
        [mediaCSS.tabMini]: {
            ...grid.w50,
        },
        [mediaCSS.mob]: {
            ...grid.w100,
        },
    },
    imageWrapper: {
        ...grid.col,
        ...grid.center,
        ...grid.colCenter,
        ...item.rounded_top_mini,
        ...grid.p_mdPlus,
        backgroundColor: `var(--var-module-instancesCatalog-component-blockItem-style-background, ${defaultThemeAppStyles.panel})`,
        cursor: 'pointer',
        '& img': {
            ...grid.fullHeight,
            width: 'auto',
        },
    },
    title: {
        textAlign: 'right',
        fontSize: textSizes.normalPlus,
        ...item.rounded_bottom_mini,
        ...grid.p_mini,
        cursor: 'pointer',
        backgroundColor: `var(--var-module-instancesCatalog-component-blockItem-style-background, ${defaultThemeAppStyles.panel})`,
        color: `var(--var-module-app-component-app-style-textMain, ${defaultThemeAppStyles.textMain})`,
        borderTop: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    instancesWrapper: {
        [mediaCSS.tab]: {
            paddingTop: 30,
        },
    },
}

addStyles(instancesCatalogStyles)
export default instancesCatalogStyles
