import React from 'react'
import Content from 'blocks.app/content/content'
import Exhibitions from './instancesCatalog_exhibitions/instancesCatalogExhibitions'
import ExhibitionsPages from './instancesCatalog_exhibitionsPages/instancesCatalogExhibitionsPages'
import translate from '../../../core/translate'
import styles from './instancesCatalog_exhibition.jcss'
import { isHorizontalMenu } from 'core/helpers/menu'
import { cn } from 'ethcss'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

const instances = ['exhibitionsList', 'exhibitionsPagesList']

class InstancesCatalogExhibition extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            isActive: 'exhibitionsList',
        }
    }

    instanceRender() {
        const isActive = this.state.isActive

        switch (isActive) {
            case 'exhibitionsList':
                return <Exhibitions />
            case 'exhibitionsPagesList':
                return <ExhibitionsPages />
            default:
                return null
        }
    }

    render() {
        return (
            <Content title={'exhibitions'}>
                <div className={cn(styles.tabsWrapper, isHorizontalMenu() && styles.tabsWrapper_x)}>
                    {instances.map((instance, index) => {
                        return (
                            <div
                                key={index}
                                className={`${styles.tabBtn} ${
                                    this.state.isActive === instance ? styles.tabActive : null
                                }`}
                                onClick={() => this.setState({ isActive: instance })}
                            >
                                {translate(instance)}
                            </div>
                        )
                    })}
                </div>
                {this.instanceRender()}
            </Content>
        )
    }
}

const exportInstancesCatalogExhibition = checkAvailableModule('instancesCatalog/exhibition')
    ? InstancesCatalogExhibition
    : null

export default exportInstancesCatalogExhibition
