import React from 'react'
import FilesAddMethod, { IFilesAddProps } from './files__add.local.methods'
import Button from 'blocks.simple/button/button'
import EditText from 'blocks.simple/editText/editText'
import { UploadButton } from '../__upload/files__upload'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './files__add.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import { Typography } from 'atoms/Typography'
const downloadTypes = ['local', 'yandexDisk', 'url']

const FilesAdd = (p_: IFilesAddProps) => {
    const { state, setState, onUploadFromUrl } = FilesAddMethod(p_)
    const s_ = state

    return (
        <div className={styles.filesAddWrapper}>
            <div className={styles.toolbar}>
                {downloadTypes.map((type) => (
                    <div
                        key={type}
                        onClick={() => {
                            setState((prevState) => {
                                return { ...prevState, selectedType: type }
                            })
                        }}
                        className={cn(styles.listItem, {
                            [styles.mainListItem]: type === 'local',
                            [styles.activeItem]: type === s_.selectedType,
                        })}
                    >
                        {translate(`${type}UploadFile`)}
                    </div>
                ))}
            </div>
            <div className={styles.contentWrapper}>
                <div className={styles.content}>
                    <div className={styles.controls}>
                        <div className={styles.title}>{translate(`${s_.selectedType}UploadFileTitle`)}</div>
                        {(() => {
                            switch (s_.selectedType) {
                                case 'yandexDisk':
                                case 'url':
                                    return (
                                        <React.Fragment>
                                            <div className={styles.input}>
                                                <EditText
                                                    label={translate('link')}
                                                    value={s_[s_.selectedType]}
                                                    onChange={(value: string) =>
                                                        setState((prevState) => {
                                                            return { ...prevState, [s_.selectedType]: value }
                                                        })
                                                    }
                                                    placeholder="http://example.com"
                                                />
                                            </div>
                                            <Button mod={'withShadow'} onClick={onUploadFromUrl}>
                                                {translate('upload')}
                                            </Button>
                                        </React.Fragment>
                                    )
                                default:
                                    return (
                                        <>
                                            <UploadButton title={translate('selectFile')} onSuccessDrop={p_.onClose} />
                                            <UploadButton
                                                title={translate('conversionDownload')}
                                                convertMode={true}
                                                mod={'withShadow'}
                                                onSuccessDrop={p_.onClose}
                                            />
                                        </>
                                    )
                            }
                        })()}
                    </div>
                    <div className={styles.footer}>
                        <div />
                        <Button mod="fill" onClick={p_.onClose}>
                            {translate('close')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilesAdd
