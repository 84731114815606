import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { IStyles } from '../../theme'

const textEditorStyles: IStyles = {
    textEditor: {
        ...grid.mt_md,
        backgroundColor: `var(--var-module-app-component-app-style-panel, ${defaultThemeAppStyles.panel})`,
        ...item.rounded_full_mini,
    },
    preview: {
        ...grid.p_md,
    },
    rss: {
        ...grid.p_mini,
        border: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        ...item.rounded_full_mini,
    },
    highlight: {
        ...grid.mt_md,
        overflow: 'hidden',
        overflowX: 'auto',
    },
    settings: {
        ...grid.p_md,
    },
    options: {
        ...grid.row,
        marginLeft: -21,
    },
    option: {
        flex: '1 1 350px',
        ...grid.mb_md,
        ...grid.ml_md,
    },
    textEditorQrCodeCheckbox: {
        ...grid.mt_mini,
    },
}

addStyles(textEditorStyles)
export default textEditorStyles
