import { Typography } from 'atoms/Typography'
import { themeFromStore } from 'core/helpers/menu'
import translate from 'core/translate'
import { isNotEmptyArray } from 'core/utils/coreUtil'
import React, { FC } from 'react'
import { styles } from './PlatformCard-styles'
import { PlatformCardProps } from './PlatformCard-types'
import { PlatformCardTable } from './PlatformCardTable'
import helpers from 'core/helpers'
import { useLocation } from 'react-router'

export const PlatformCard: FC<PlatformCardProps> = ({ platform }) => {
    const { title, description, installationLink, displayedClientAppType, lastClientApplications, type } = platform

    const prepareType = (type: string) => type.toLowerCase().split(' ').join('')

    const getIconSrc = () => `images/distributives/platforms/${prepareType(type)}.png`

    const renderTable = () => {
        return (
            <div className={styles.platform__body}>
                <div className={styles.platform__table}>
                    <PlatformCardTable
                        distributives={lastClientApplications}
                        displayedClientAppType={displayedClientAppType}
                        type={type}
                    />
                </div>
            </div>
        )
    }

    const location = useLocation()
    const pathname = helpers.getPathname(location.pathname)

    return (
        <div
            className={
                themeFromStore() === 'custom' && pathname === 'distributive'
                    ? styles.platform_custom_theme
                    : styles.platform
            }
        >
            <div className={styles.platform__header}>
                <div className={styles.platform__info}>
                    <div className={styles.platform__logo}>
                        <img src={getIconSrc()} />
                    </div>
                    <div className={styles.platform__content}>
                        <Typography className={styles.platform__title} type="title">
                            {title}
                        </Typography>
                        <Typography className={styles.platform__description} type="title">
                            {description}
                        </Typography>
                    </div>
                </div>
                <div className={styles.platform__link}>
                    <Typography type="title">
                        <a href={installationLink} target="_blank">
                            {translate('distributiveInstallationGuide')}
                        </a>
                    </Typography>
                </div>
            </div>
            {isNotEmptyArray(lastClientApplications) && renderTable()}
        </div>
    )
}
