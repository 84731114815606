import { cn } from 'ethcss'
import React, { FunctionComponent, memo } from 'react'
import { audioPlaceholderStyles } from './AudioPlaceholder-styles'
import { IAudioPlaceholderComponent } from './AudioPlaceholder-types'

const AudioPlaceholderComponent: FunctionComponent<IAudioPlaceholderComponent> = ({ size, className }) => {
    return (
        <div
            className={cn(audioPlaceholderStyles.AudioPlaceholder, className)}
            style={{
                width: `${size}px`,
                height: `${size}px`,
            }}
        >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

export const AudioPlaceholder = memo(AudioPlaceholderComponent)
