import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FilesEditTagsModalMethods, { IFilesEditTagsProps } from './files__editTagsModal.local.methods'
import Modal from 'blocks.simple/modal/modal'
import Icon from 'blocks.simple/icon/icon'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import styles from './files__editTagsModal.jcss'
import { grid } from '../../../theme'
import { Typography } from 'atoms/Typography'
import { cn } from 'ethcss'

const FilesEditTagsModal = (p_: IFilesEditTagsProps) => {
    const { s_, methods } = FilesEditTagsModalMethods(p_)

    return (
        <Modal open={p_.isTagsEditModalOpen} onClose={methods.onCloseModal} showCloseIcon={false} mod="clear">
            <div className={styles.modalWrapper}>
                <div className={styles.modalInner}>
                    <div className={styles.closeIcon}>
                        <Icon type="close_tiny" onClick={methods.onCloseModal} />
                    </div>
                    {!p_.sources.length && (
                        <div className={styles.emptyText}>{translate('filesMissingFromSelectedItems')}</div>
                    )}
                    {p_.sources.length > 0 && (
                        <React.Fragment>
                            <Typography className={styles.title} type="title">
                                {translate('editTagsOnSelectedItems')}
                            </Typography>
                            <div className={styles.inputWrapper}>
                                <Typography className={styles.inputTitle} type="title">
                                    #{translate('tags')}
                                </Typography>
                                <Dropdown
                                    list={s_.sourcesTags}
                                    options={s_.tags}
                                    searchable={true}
                                    multiselect={true}
                                    onChange={methods.onChangeTags}
                                    onValidateSymbolNumber={methods.onValidateSymbolNumber}
                                />
                                <div className={grid.mt_mini}>
                                    <Typography className={styles.centerBlock} type="text">
                                        {translate('forAddTagUseEnter')}
                                    </Typography>
                                    {s_.validateSymbolNumber && (
                                        <Typography className={cn(styles.centerBlock, grid.mt_mini)} type="error">
                                            {translate('minTagNumber')}
                                        </Typography>
                                    )}
                                </div>
                            </div>
                            <div className={styles.footer}>
                                <Button onClick={methods.onSubmit} mod="fill">
                                    {translate('save')}
                                </Button>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    isTagsEditModalOpen: state.files.isTagsEditModalOpen,
})

export default connect(mapStateToProps)(FilesEditTagsModal)
