import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { IStyles } from '../../../theme'

const touchEditorActionsStyles: IStyles = {
    actionType: {
        ...grid.col,
        ...grid.w50,
        ...grid.pr_mini,
    },
    settingsForm: {
        ...grid.col,
        ...grid.w50,
        ...grid.pl_mini,
    },
}

addStyles(touchEditorActionsStyles)
export default touchEditorActionsStyles
