import { colors } from 'theme'

export const defaultThemeStyles = {
    textMain: colors.white,
    active: colors.blackLight,
    disable: colors.grey,
    success: colors.success,
    warning: colors.warning,
    error: colors.error,
    normal: colors.selected,
    online: colors.online,
}
