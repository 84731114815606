import React from 'react'
import CustomListHeader, { ICustomListHeaderProps } from '../../__header/customList__header'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'

export default {
    getMethod: 'getBurgersCatalogByType',
    onCatalogUpdatedMethod: 'burgersCatalogUpdated',
    emptyDescription: () => translate('noItemsDescription'),
    emptyIcon: 'not_allow',
    getItemModel: (
        dataItem: {
            __view: { selected: boolean }
            source: { __view: { selected: boolean } }
        },
        getSelected: (item: object) => any
    ) => {
        const item = { ...dataItem }

        if (item.source && !item.source.__view) {
            item.source.__view = {
                selected: getSelected(item),
            }
        }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    getBackendModel: (item: { id: number }) => {
        return api.send('getCourse', { id: item.id }, { hideLoader: true })
    },
    isEqual: (firstItem: { id: number }, secondItem: { id: number }) => firstItem.id === secondItem.id,
    init(callback: any) {
        // @ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    headerFilters: (p_: ICustomListHeaderProps) => (
        <CustomListHeader
            onAdd={() => p_.onAddFakeLine({ title: '', price: '', source: null })}
            title={translate('sliderProducts')}
            type={p_.type}
            onDelete={() => {
                const id = p_.selectedItems.map((selectedItem: { id: number }) => selectedItem.id)
                if (id.length > 0) {
                    api.send('deleteBurgersProduct', { id })
                }
                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            }}
            {...p_}
        />
    ),
    draggable: true,
    onDeleteListItem: (listItem: { id: number }) => {
        api.send('deleteBurgersProduct', { id: listItem.id })
    },
}
