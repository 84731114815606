import { addStyles } from 'ethcss'
import { grid, indents, item, radiusSizes } from 'theme'
import { IStyles } from 'theme'
import { defaultThemeReferenceBookStyles } from './ReferenceBookList.theme'

export const styles: IStyles = {
    referenceBookList: {
        ...grid.fullWidth,
    },
    referenceBookList__content: {
        background: `var(--var-module-catalog-component-referenceBook-style-backgroundMain, ${defaultThemeReferenceBookStyles.backgroundMain})`,
        borderRadius: radiusSizes.micro,
    },
    referenceBookList__table: {
        borderRadius: 0,
    },
    referenceBookList__wrapper: {
        ...grid.fullWidth,
        overflowX: 'auto',
    },
}

addStyles(styles)
