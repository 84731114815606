import React, { useEffect, useState } from 'react'
import { SwitchButton } from '../../../atoms/SwitchButton'
import { cn } from 'ethcss'
import { styles } from './remoteSupport.jcss'
import Collapse from 'blocks.simple/collapse/collapse'
import translate from '../../../core/translate'
import styles_ from '../settings.jcss'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import Button from '../../../blocks.simple/button/button'
import { updateCompany } from '../../../blocks.app/user/user.state'
import { useDispatch } from 'react-redux'

interface IRemoteSupport {
    enabled: boolean
    hoursLimit: number
}

export const RemoteSupport = ({ remoteSupport }: { remoteSupport: IRemoteSupport }) => {
    const endPeriodOptions = [
        { id: 3, name: translate('threeHours') },
        { id: 12, name: translate('twentyHours') },
        { id: 24, name: translate('oneDay') },
        { id: 48, name: translate('twoDays') },
        { id: 72, name: translate('threeDays') },
    ]
    const [isEnabled, setIsEnabled] = useState(remoteSupport && remoteSupport.enabled)
    const [selectedPeriod, setSelectedPeriod] = useState(getInitialPeriod())
    const dispatch = useDispatch()
    const collapseRef: { onClick?: () => void } = {}

    function getInitialPeriod() {
        if (!remoteSupport) {
            return { id: 3, name: translate('threeHours') }
        }

        const limit = endPeriodOptions.find((period) => period.id === remoteSupport.hoursLimit)

        return limit ? limit : { id: 3, name: translate('threeHours') }
    }
    const handleSwitchChange = () => {
        setIsEnabled(!isEnabled)
    }
    const onSave = () => {
        dispatch(
            updateCompany({
                data: { remoteSupport: { enabled: isEnabled, hoursLimit: selectedPeriod.id } },
                isSaveCompany: true,
            })
        )
        if (collapseRef.onClick) {
            collapseRef.onClick()
        }
    }

    useEffect(() => {
        if (remoteSupport) {
            setIsEnabled(remoteSupport.enabled)
            setSelectedPeriod(getInitialPeriod())
        }
    }, [remoteSupport])

    return (
        <Collapse
            refObj={collapseRef}
            title={() => translate('remoteSupport')}
            className={cn(styles.wrapper, styles_.background)}
        >
            <SwitchButton onChange={handleSwitchChange} checked={isEnabled} />
            <Dropdown
                options={endPeriodOptions}
                label={translate('supportPeriodLimit')}
                value={selectedPeriod && selectedPeriod.id}
                onChange={(option) => {
                    setSelectedPeriod(option)
                }}
                mod={'scheduleButtonBorder'}
                containerClassName={styles.dropdown}
                isRelativeList={true}
                disabled={!isEnabled}
            />
            <div className={styles.buttonWrapper}>
                <Button mod="fill" onClick={onSave}>
                    {translate('save')}
                </Button>
            </div>
        </Collapse>
    )
}
