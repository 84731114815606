import { addStyles } from 'ethcss'
import { colors, IStyles } from '../../../theme'

const csvUploadStyles: IStyles = {
    uploadCsvWrapper: {
        width: '100%',
        minHeight: '800px',
        margin: '20px auto 0 auto',
        background: '#fff',
        borderRadius: '5px 5px 0 0',
    },
    panel: {
        textAlign: 'center',
    },
    caption: {
        padding: '20px',
        textAlign: 'center',
        borderBottom: '1px solid #F0F0F0',
    },
    uploadUi: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
        transition: 'opacity 0.3s',
        maxWidth: '400px',
        margin: '50px auto 0 auto',
    },
    input: {
        display: 'none',
    },
    label: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        color: '#fff',
        borderRadius: '20px',
        background: '#b3b3b3',
        padding: '10px 7px',
    },
    fakeLabel: {
        marginBottom: '7px',
    },
    dropDown: {
        width: '140px',
    },
    uploadBtn: {
        justifyContent: 'center',
        marginTop: '20px',
        width: '100%',
    },
    statusWrapper: {
        display: 'inline-block',
        margin: '20px auto 0 auto',
        maxWidth: '500px',
        borderRadius: '5px',
        padding: '8px',
        textAlign: 'left',
        color: colors.notificationRed,
    },
    blockUi: {
        opacity: 0.5,
        pointerEvents: 'none',
    },
    details: {
        '& div': {
            marginTop: '6px',
        },
    },
    divider: {
        width: '100px',
    },
    dividerTitle: {
        textAlign: 'center',
        marginBottom: '7px',
    },
    dividerInput: {
        height: '33px',
    },
    downloadCsv: {
        maxWidth: '400px',
        margin: '0 auto',
    },
}

addStyles(csvUploadStyles)
export default csvUploadStyles
