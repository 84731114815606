import React from 'react';
import {DefaultLinkFactory} from 'storm-react-diagrams';
import FlowDiagramLinkModel from './flowDiagram__link_model';
import FlowDiagramLinkWidget from './flowDiagram__link_widget';
import FlowDiagramLinkSegment from './flowDiagram__link_segment';

class FlowDiagramLinkFactory extends DefaultLinkFactory {
    type = 'advanced';

    generateReactWidget(diagramEngine, link) {
        return React.createElement(FlowDiagramLinkWidget, {
            link: link,
            diagramEngine: diagramEngine
        });
    }
    getNewInstance(initialConfig) {
        return new FlowDiagramLinkModel();
    }

    generateLinkSegment(model, widget, selected, path) {
        return <FlowDiagramLinkSegment model={model} widget={widget} path={path} selected={selected}/>;
    }
}

export default FlowDiagramLinkFactory;