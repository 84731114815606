import React, { useEffect, useRef, useState } from 'react'
import { api } from '../../../core/api/ConnectionManager'
import { initPeerConnection } from '../../../core/utils/coreUtil'
import LoaderItem from '../../../blocks.app/loader/__item/loader__item'
import styles from './display__control.jcss'
import { cn } from 'ethcss'
import translate from '../../../core/translate'

function WebRTCComponent({
    digitalSignageId,
    style,
    peerConnection,
    setPeerConnection,
}: {
    digitalSignageId: number
    style: object
    peerConnection: RTCPeerConnection | null
    setPeerConnection: (peerConnection: RTCPeerConnection) => void
}) {
    const [answer, setAnswer] = useState<RTCSessionDescriptionInit | null>(null)
    const [tracks, setTracks] = useState<any>([])
    const [connectionStatus, setConnectionStatus] = useState<string>('')
    const remoteVideoRef = useRef<HTMLVideoElement | null>(null)
    const remoteStream = useRef<any>(null)
    const stunServers = [
        {
            urls: 'stun:stun.l.google.com:19302',
        },
        {
            urls: 'stun:stun1.l.google.com:19302',
        },
        {
            urls: 'stun:stun2.l.google.com:19302',
        },
        {
            urls: 'stun:stun3.l.google.com:19302',
        },
        {
            urls: 'stun:stun4.l.google.com:19302',
        },
    ]

    const onIceCandidate = (event: RTCIceCandidate) => {
        api.send('webrtcIceCandidate', { digitalSignageId, candidate: event })
    }

    const getConnectionStatus = () => {
        switch (connectionStatus) {
            case 'connecting':
                return (
                    <div className={styles.connectionStatusInner}>
                        <div className={styles.connectionStatusText}>{translate('webRTCStatusConnecting')}</div>
                        <LoaderItem small={true} />
                    </div>
                )
            case 'connected':
                return (
                    <div className={styles.connectionStatusInner}>
                        <div className={styles.connectionStatusText}>{translate('webRTCStatusConnected')}</div>
                        <LoaderItem small={true} />
                    </div>
                )
            case 'failed':
                return (
                    <div className={styles.connectionStatusInner}>
                        <div className={cn(styles.connectionStatusText, styles.connectionError)}>
                            {translate('webRTCStatusFailed')}
                        </div>
                    </div>
                )
            default:
                return null
        }
    }

    const clearConnectionStatus = () => {
        setConnectionStatus('')
    }

    useEffect(() => {
        initPeerConnection(
            stunServers,
            new MediaStream(),
            (track: RTCTrackEvent) => {
                setTracks((prevState: any) => {
                    return [...prevState, track]
                })
            },
            onIceCandidate,
            (state: string) => {
                setConnectionStatus(state === 'disconnected' ? '' : state)

                console.log(state)
            },
            true
        ).then((data) => {
            if (data.pc) {
                setPeerConnection(data.pc)
            }

            console.log('start device stream')

            api.send('webrtcHandshake', {
                digitalSignageId,
                description: data.offer?.sdp,
                type: 'offer',
            })
        })

        remoteStream.current = new MediaStream()
    }, [])

    useEffect(() => {
        const listenersId: string[] = []

        api.addObserver(
            'webrtcHandshake',
            (data: { digitalSignageId: number; type: string; description: string }) => {
                if (data && data.type === 'answer') {
                    setAnswer({
                        type: data.type,
                        sdp: data.description,
                    })
                }
            },
            listenersId
        )
        api.addObserver(
            'webrtcIceCandidate',
            (data: { digitalSignageId: number; type: string; candidate: RTCIceCandidate }) => {
                let candidate: any = data.candidate

                if (peerConnection) {
                    peerConnection.addIceCandidate(new RTCIceCandidate(candidate.candidate))
                }
            },
            listenersId
        )

        return () => {
            listenersId.forEach((id) => api.removeObserver(id))
        }
    }, [peerConnection])

    useEffect(() => {
        if (!answer || !peerConnection) return

        peerConnection.setRemoteDescription(new RTCSessionDescription(answer))
    }, [answer, peerConnection])

    useEffect(() => {
        if (tracks.length && remoteVideoRef.current) {
            remoteVideoRef.current.srcObject = remoteStream.current

            tracks.forEach((track: any) => {
                if (remoteVideoRef.current) {
                    // @ts-ignore
                    remoteVideoRef.current.srcObject.addTrack(track)
                }
            })
        }
    }, [tracks, remoteVideoRef.current])

    useEffect(() => {
        if (!remoteVideoRef.current) return

        remoteVideoRef.current?.addEventListener('play', clearConnectionStatus)

        return () => remoteVideoRef.current?.removeEventListener('play', clearConnectionStatus)
    }, [remoteVideoRef.current])

    return (
        <>
            <video ref={remoteVideoRef} autoPlay={true} playsInline={true} style={style} />
            {connectionStatus && <div className={styles.connectionStatus}>{getConnectionStatus()}</div>}
        </>
    )
}

export default WebRTCComponent
