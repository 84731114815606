import React from 'react'
import { api } from 'core/api/ConnectionManager'

class AppFeaturesMethods extends React.Component {
    static defaultProps = {
        newFeatures: [],
        lang: 'ru',
    }

    state = {
        open: false,
    }

    onViewed = () => {
        const p_ = this.props

        const newFeatureId = p_.newFeatures.map((feature) => feature.id)

        api.send('newFeatureViewed', { newFeatureId }).then(() => {
            this.setState({ open: false })
            p_.clearFeatures()
        })
    }
}

export default AppFeaturesMethods
