import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'

import 'storm-react-diagrams/dist/style.min.css'

const flowDiagramStyles = {
    wrapper: {
        ...grid.full,
        '& .srd-default-node__in': {
            marginRight: 10,
            '& .srd-default-port--in': {
                marginTop: 10,
                marginBottom: 10,
                '& .srd-port': {
                    ...item.online,
                    borderBottomRightRadius: 10,
                    borderTopRightRadius: 10,
                    marginRight: 5
                }
            }
        },
        '& .srd-default-node__out': {
            marginLeft: 10,
            '& .srd-default-port--out': {
                marginTop: 10,
                marginBottom: 10,
                '& .srd-port': {
                    ...item.error,
                    borderBottomLeftRadius: 10,
                    borderTopLeftRadius: 10,
                    marginLeft: 5
                }
            }
        }
    }
}

addStyles(flowDiagramStyles)
export default flowDiagramStyles
