import { addStyles } from 'ethcss'
import { grid, item, mediaCSS } from '../../../theme'

const scheduleSelectStyles = {
    wrapper: {
        ...grid.full,
        ...grid.row,
        ...item.white,
    },
    contentWrapper: {
        width: 0,
        ...grid.space,
        ...grid.fullHeight,
        //...item.greyModal,
    },
    fullContent: {
        ...grid.full,
        ...grid.col,
        ...item.rel,
    },
    content: {
        ...grid.p_md,
    },
    content_x: {
        padding: '21px 30px 21px 30px',
    },
    toolbar: {
        ...grid.w25,
        ...grid.fullHeight,
        [mediaCSS.tabMini]: {
            ...grid.w40,
        },
    },
    breadcrumbs: {
        ...grid.mb_md,
    },
    tilesWrapper: {
        [mediaCSS.desktopL]: {
            paddingRight: 8,
        },
    },
}

addStyles(scheduleSelectStyles)
export default scheduleSelectStyles
