import { addStyles } from 'ethcss'
import { grid, IStyles, mediaCSS, textSizes } from 'theme'

export const styles: IStyles = {
    device: {
        ...grid.w100,
        ...grid.row,
        ...grid.mb_md,
    },
    device__input: {
        ...grid.w20,
        ...grid.mr_md,
        textAlign: 'center',
        [mediaCSS.mob]: {
            ...grid.w100,
        },
        [mediaCSS.tabMini]: {
            ...grid.w45,
            ...grid.mb_md,
        },
    },
    device__button: {
        ...grid.flexEnd,
        [mediaCSS.tabMini]: {
            ...grid.mb_md,
        },
    },
    device__label: {
        ...grid.mb_micro,
        textTransform: 'uppercase',
        display: 'block',
        width: '100%',
        textAlign: 'center',
        fontSize: textSizes.normalPlus,
    },
    device__inputCaption: {
        textTransform: 'uppercase',
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 20,
    },
}

addStyles(styles)
