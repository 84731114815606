import { addStyles } from 'ethcss'
import { IStyles } from 'theme'
import { textSizes } from 'theme/media'

export const styles: IStyles = {
    initPage: {
        position: 'absolte',
        width: `100%`,
        height: `100%`,
        fontSize: textSizes.normal,
        minHeight: '100%',
    },
}

addStyles(styles)
