import { getDefaultLocale } from 'core/helpers/user'
import store from 'core/store'
import l10n from './l10n.json'

export const LANGUAGES = [
    { id: 'ru', text: 'Русский' },
    { id: 'en', text: 'English' },
    { id: 'es', text: 'Spanish' },
    { id: 'pt', text: 'Portuguese' },
    { id: 'kz', text: 'Қазақ' },
]

export interface ITranslationTextModel {
    [key: string]: string
}

export interface ITranslation {
    [key: string]: ITranslationTextModel
}

export const getUserLocale = (): string => {
    const state = store.getState()
    const user = state.user.data

    if (!user || window.location.hash.indexOf('login') !== -1) return getDefaultLocale()

    return user.settings.locale || getDefaultLocale()
}

const isCorrectUserLocale = (): boolean => {
    const checkLocale = getUserLocale()
    return (
        checkLocale === 'ru' ||
        checkLocale === 'en' ||
        checkLocale === 'es' ||
        checkLocale === 'pt' ||
        checkLocale === 'kz'
    )
}

const getStringWithBrandName = (string: string) => {
    if (
        string &&
        (string.includes('SmartPlayer') || string.includes('smartplayer') || string.includes('Smartplayer'))
    ) {
        const brandName = window.spconfig.brandName

        return string
            .replace('SmartPlayer', brandName)
            .replace('smartplayer', brandName)
            .replace('Smartplayer', brandName)
    }

    return string
}

const translate = (key: string, params?: string[]): string => {
    const locale = isCorrectUserLocale() ? getUserLocale() : 'en'
    const translateData: ITranslation = l10n
    const translateString = translateData[key]
    const brandNameIsExist = window.spconfig.brandName

    if (translateString) {
        let string = translateString[locale]

        if (params) {
            params.forEach((param, index) => {
                string = string.replace(`$${index + 1}`, param)
            })
        }

        return brandNameIsExist ? getStringWithBrandName(string) : string
    }

    return key
}

export default translate
