import React from 'react'
import { api } from 'core/api/ConnectionManager'
import helpersDigitalSignages from 'core/helpers/_digitalSignages'
import moment from 'moment'
import merge from 'merge'

interface IState {
    startTime: string
    endTime: string
    options: { [index: string]: string }
    events: { event: string; emails: string[] }[]
    applyRecursively: boolean
    timeoutS: string
}

interface IDisplaysNotificationsProps {
    selectedInfo: {
        id: number
        type: string
    } | null
    closeModal: () => void
    notificationsEmail: { id: number; label: string; value: string }[]
}

class DisplaysNotificationsMethods extends React.Component<IDisplaysNotificationsProps> {
    state: IState = {
        startTime: moment().startOf('day').format('HH:mm:ss'),
        endTime: moment().endOf('day').format('HH:mm:ss'),
        options: {},
        events: [],
        applyRecursively: false,
        timeoutS: '10800',
    }

    componentDidMount() {
        const s_ = this.state
        const params = {
            [this.getIdKeyByType()]: this.props.selectedInfo && this.props.selectedInfo.id,
        }

        api.send('getNotificationsSettingsForDsOrGroup', params).then((res: any) => {
            if (!res || !res.length) {
                return
            }

            const result = res[0]

            if (result.startTime && result.endTime) {
                const currentDate = moment().format('YYYY-MM-DD')
                const startTime = moment.utc(`${currentDate} ${result.startTime}`).local().format('HH:mm:ss')
                const endTime = moment.utc(`${currentDate} ${result.endTime}`).local().format('HH:mm:ss')
                result.startTime = startTime
                result.endTime = endTime
            }

            const data = merge.recursive(true, s_, result)
            this.setState({ ...data })
        })
    }

    getIdKeyByType = () => {
        return helpersDigitalSignages.isFolder(this.props.selectedInfo) ? 'groupId' : 'digitalSignageId'
    }
    checkedType = (checked: boolean, type: string) => {
        const { events } = this.state
        let newArray: { event: string }[] = [...events]
        const filterArray = () => newArray.filter((element) => element.event !== type)

        if (checked) {
            filterArray()
            if (type === 'screenOffTimesDevice') {
                this.setState({
                    ...this.state,
                    options: { ...this.state.options, screenOffTime: '00:00:00' },
                })
            } else if (type === 'deviceOfflineTime') {
                this.setState({
                    ...this.state,
                    options: { ...this.state.options, offlineTime: '00:00:00' },
                })
            } else {
                newArray.push({ event: type })
            }
        } else {
            newArray = newArray.filter((item) => item.event !== type)
            const { options } = this.state
            let newOptions = { ...options }

            if (type === 'deviceOfflineTime') {
                delete newOptions.offlineTime
                this.setState({ ...this.state, options: newOptions })
            } else if (type === 'screenOffTimesDevice') {
                delete newOptions.screenOffTime
                this.setState({
                    ...this.state,
                    options: newOptions,
                })
            } else {
                let currentEvent: { emails?: string[] | null; event: string } | undefined = newArray.find(
                    (element) => element.event === type
                )
                currentEvent && (currentEvent.emails = null)
                this.setState({ events: newArray })
            }
        }

        this.setState({ events: newArray })
    }
    getCheckedValue = (type: string) => {
        const s_ = this.state
        return s_.events.filter((item) => item.event === type).length > 0
    }
    getCheckedRightEventsValue = (type: string) => {
        const s_ = this.state
        let eventValue: string = ''
        if (type === 'deviceOfflineTime') {
            eventValue = 'offlineTime'
        }
        if (type === 'screenOffTimesDevice') {
            eventValue = 'screenOffTime'
        }
        return s_.options.hasOwnProperty(eventValue)
    }
    setTime = (value: string, type: string) => {
        if (moment(value, 'HH:mm:ss', true).isValid()) {
            this.setState({ [type]: value })
        } else {
            this.setState({ [type]: moment().format('HH:mm:ss') })
        }
    }
    applySettings = () => {
        const { closeModal } = this.props
        const currentDate = moment().format('YYYY-MM-DD')
        const startTime = moment(`${currentDate} ${this.state.startTime}`).utc().format('HH:mm:ss')
        const endTime = moment(`${currentDate} ${this.state.endTime}`).utc().format('HH:mm:ss')
        const options = this.state.options

        const data: any = {
            options,
            startTime,
            endTime,
            applyRecursively: this.state.applyRecursively,
            events: this.state.events.map((event) => event),
        }

        data[this.getIdKeyByType()] = this.props.selectedInfo && this.props.selectedInfo.id

        let timeoutS: string = this.state.timeoutS.toString()

        if (!timeoutS.length) {
            timeoutS = '0'
        }

        data.timeoutS = parseInt(timeoutS, 10)

        api.send('updateNotificationsSettingsForDsOrGroup', data).then(() => {
            closeModal()
        })
    }
}

export default DisplaysNotificationsMethods
