import React from 'react'
import EditText, { EditTextPassword } from 'molecules/EditText'
import translate from 'core/translate'
import Dropdown from 'molecules/Dropdown'
import { Icon } from 'molecules/Icon'

import PropTypes from 'prop-types'
import { cn } from 'ethcss'
import styles from '../styles'
import { grid } from 'theme'

const RegularUser = (p_) => {
    return (
        <React.Fragment>
            <EditText
                value={p_.data.name}
                label={translate('userName')}
                wrapperClassName={cn(styles.profileFormInput, grid.mt_md)}
                onChange={(name) => p_.onChange(name, 'name')}
            />
            <EditText
                value={p_.data.email}
                label={translate('email')}
                wrapperClassName={styles.profileFormInput}
                onChange={(email) => p_.onChange(email, 'email')}
            />
            {p_.data.roles.map((role, idx) => {
                const roleValue = role || null

                return (
                    <div key={`profile_form_role_dropdown_${idx}`} className={styles.profileFormDropdownWrapper}>
                        <Dropdown
                            value={roleValue}
                            label={`${translate('role')} ${idx + 1}`}
                            options={p_.roles}
                            disabled={p_.owner}
                            onChange={(selected) => p_.onChangeDropdown(selected, idx)}
                            wrapperClassName={styles.profileFormInput}
                            menuPortalTarget={document.getElementById('profile_dialog')}
                        />
                        {idx > 0 && (
                            <Icon
                                type="trash"
                                onClick={() => p_.onDeleteRole(idx)}
                                className={styles.profileFormDeleteIcon}
                            />
                        )}
                    </div>
                )
            })}
            {!p_.owner && (
                <div className={styles.profileFormLink} onClick={p_.onAddNewRole}>
                    <Icon type="plus" className={styles.profileFormLinkIcon} size={24} /> {translate('addRole')}
                </div>
            )}
            {p_.owner && p_.isEdit && (
                <EditText
                    value={p_.data.oldPassword}
                    label={translate('oldPassword')}
                    wrapperClassName={styles.profileFormInput}
                    onChange={(oldPassword) => p_.onChange(oldPassword, 'oldPassword')}
                />
            )}
            <EditTextPassword
                value={p_.data.password}
                label={
                    <div className={styles.profileFormPasswordLabel}>
                        <div>{translate(p_.isEdit ? 'newPassword' : 'password')}</div>
                        <div className={styles.profileFormPasswordGenerateLabel} onClick={p_.handleGeneratePassword}>
                            {translate('generate')}
                        </div>
                    </div>
                }
                wrapperClassName={styles.profileFormInput}
                onChange={(password) => p_.onChange(password, 'password')}
            />
            <EditTextPassword
                value={p_.data.passwordRepeat}
                label={translate('passwordRepeat')}
                wrapperClassName={styles.profileFormInput}
                onChange={(passwordRepeat) => p_.onChange(passwordRepeat, 'passwordRepeat')}
            />
        </React.Fragment>
    )
}

RegularUser.propTypes = {
    data: PropTypes.object,
    owner: PropTypes.bool,
    isEdit: PropTypes.bool,
    onChange: PropTypes.func,
}

export default RegularUser
