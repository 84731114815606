import { useState } from 'react'

export interface IUseModalHook {
    value: boolean
    setValue: (value: boolean) => void
    toggleModal: () => void
    closeModal: () => void
    openModal: () => void
}

/**
 * [useModal hook]
 * @param object with initialState<boolean> - Initial state of modal status
 * @returns value (boolean) - current status of modal,
 * @returns setValue (function) - set new status of modal,
 * @returns toggleModal (function) - toggle modal method,
 * @returns closeModal (function) - close modal
 * @returns openModal (function) - open modal
 */

export const useModal = ({ initialState = false } = {}): IUseModalHook => {
    const [value, setValue] = useState<boolean>(initialState)

    return {
        value,
        setValue,
        toggleModal: () => setValue(!value),
        closeModal: () => setValue(false),
        openModal: () => setValue(true)
    }
}
