import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { textSizes } from 'theme/media'
import { IStyles } from '../../../theme'

const customListContentStyles: IStyles = {
    wrapper() {
        return {
            '& th': {
                borderTop: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
            },
            [`& tr:hover>td>.${this.controls}`]: {
                visibility: 'visible',
            },
        }
    },
    pitamixTableWrapper: {
        '& td': {
            borderRight: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
            borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
        },
    },
    controls: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.noWrap,
        visibility: 'hidden',
    },
    active: {
        ...item.shadowButton,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.p_micro,
        ...item.white,
        fontSize: textSizes.normal,
        border: `1px dashed var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    animation: {
        animation: 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
        transform: 'translate3d(0, 0, 0)',
        backfaceVisibility: 'hidden',
        perspective: 1000,
    },
}

addStyles(customListContentStyles)
export default customListContentStyles
