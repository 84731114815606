import { addStyles } from 'ethcss'
import { transitions } from 'blocks.app/config'
import { grid, item, IStyles } from '../../theme'

const animationStyles: IStyles = {
    wrapper: {
        ...item.rel,

        /* fade */
        '& .fade_normal-enter': {
            opacity: 0.01,
        },
        '& .fade_normal-enter.fade_normal-enter-active': {
            opacity: 1,
            transition: `opacity ${transitions.normal}ms ease-in-out`,
        },
        '& .fade_normal-leave': {
            opacity: 1,
        },
        '& .fade_normal-leave.fade_normal-leave-active': {
            opacity: 0.01,
            transition: `opacity ${transitions.normal}ms ease-in-out`,
        },
        /* /fade */

        /* sliderFade */
        '& .sliderFade_normal-enter': {
            opacity: 0.01,
        },
        '& .sliderFade_normal-enter.sliderFade_normal-enter-active': {
            opacity: 1,
            transition: `opacity ${transitions.normal}ms ease-in-out`,
        },
        '& .sliderFade_normal-leave': {
            opacity: 1,
            ...item.abs,
            ...item.fullAbs,
            ...grid.fullWidth,
        },
        '& .sliderFade_normal-leave.sliderFade_normal-leave-active': {
            opacity: 0.01,
            transition: `opacity ${transitions.normal}ms ease-in-out`,
        },
        /* /sliderFade */

        /* sliderMove-right */
        '& .sliderMove-right_normal-enter': {
            transform: 'translate(100%, 0)',
        },
        '& .sliderMove-right_normal-enter.sliderMove-right_normal-enter-active': {
            transform: 'translate(0, 0)',
            transition: `transform ${transitions.normal}ms ease-in-out`,
        },
        '& .sliderMove-right_normal-leave': {
            ...item.abs,
            ...item.fullAbs,
            ...grid.fullWidth,
        },
        '& .sliderMove-right_normal-leave.sliderMove-right_normal-leave-active': {
            transform: 'translate(-100%, 0)',
            transition: `transform ${transitions.normal}ms ease-in-out`,
        },
        /* /sliderMove-right */

        /* sliderMove-left */
        '& .sliderMove-left_normal-enter': {
            transform: 'translate(-100%, 0)',
        },
        '& .sliderMove-left_normal-enter.sliderMove-left_normal-enter-active': {
            transform: 'translate(0, 0)',
            transition: `transform ${transitions.normal}ms ease-in-out`,
        },
        '& .sliderMove-left_normal-leave': {
            ...item.abs,
            ...item.fullAbs,
            ...grid.fullWidth,
        },
        '& .sliderMove-left_normal-leave.sliderMove-left_normal-leave-active': {
            transform: 'translate(100%, 0)',
            transition: `transform ${transitions.normal}ms ease-in-out`,
        },
        /* /sliderMove-left */

        /* move-left */
        '& .move-left_normal-enter': {
            transform: 'translate(-100%, 0)',
        },
        '& .move-left_normal-enter.move-left_normal-enter-active': {
            transform: 'translate(0%, 0)',
            transition: `transform ${transitions.normal}ms ease-in-out`,
        },
        '& .move-left_normal-leave': {
            transform: 'translate(0%, 0)',
        },
        '& .move-left_normal-leave.move-left_normal-leave-active': {
            transform: 'translate(100%, 0)',
            transition: `transform ${transitions.normal}ms ease-in-out`,
        },
        /* /sliderMove-left */
    },
}

addStyles(animationStyles)
export default animationStyles
