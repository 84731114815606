import React, { useEffect, useState } from 'react'
import Button from 'blocks.simple/button/button'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import styles from './instanceCatalogUploadCsv.jcss'
import translate from 'core/translate'
import { Typography } from 'atoms/Typography'
import { api } from '../../../../core/api/ConnectionManager'
import { useSelector } from 'react-redux'
import EditText from 'blocks.simple/editText/editText'

const UploadCsv = () => {
    const tokens = useSelector((state) => state.user.tokens)
    const [encoding, setEncoding] = useState('utf8')
    const [divider, setDivider] = useState(',')
    const [status, setStatus] = useState('')
    const [files, setFiles] = useState(null)
    const [fileIsLoading, setFileIsLoading] = useState(true)
    const [errorDetails, setErrorDetails] = useState(null)
    const encodings = [
        {
            id: 'utf8',
            name: 'utf8',
        },
        {
            id: 'windows1251',
            name: 'windows1251',
        },
    ]
    const listenersId = []

    const checkExtension = (name, extension) => {
        return name.split('.').pop() === extension
    }

    const errorCodeIsExist = (code) => {
        if (!code) return

        switch (code) {
            case 'UNEXPECTED_ERROR':
            case 'CSV_FILE_PARSING':
            case 'CSV_FILE_CHARSET_CONVERTING':
            case 'CSV_FILE_EMPTY':
            case 'CSV_FILE_MUST_BE_PROVIDED':
            case 'CSV_FILE_NOT_VALIDE_MIMETYPE':
            case 'CSV_FILE_NOT_SUPPORTED_CHARSET':
            case 'REQUIRED_FIELD':
            case 'NOT_UNIQUE_FIELD':
            case 'NOT_ALLOWED_FIELD_VALUE':
            case 'NOT_VALID_DATE_FORMAT':
            case 'UNSUPPORTED_MIME_TIPE':
            case 'FTP_FILE_NOT_EXIST':
                return true
            default:
                return false
        }
    }

    const renderErrorDetails = (detail) => {
        if (!detail) return

        const code = errorCodeIsExist(detail.code) ? detail.code : ''
        const message = detail.message ? detail.message : ''
        const rowNumber = detail.rowNumber ? detail.rowNumber : null

        return (
            <div className={styles.details}>
                {code ? (
                    <div>
                        <b>Описание:</b> {translate(code)}
                    </div>
                ) : null}
                {message ? (
                    <div>
                        <b>Сообщение:</b> {message}
                    </div>
                ) : null}
                {rowNumber ? (
                    <div>
                        <b>Номер строки:</b> {rowNumber}
                    </div>
                ) : null}
            </div>
        )
    }

    const sendData = (files) => {
        if (!files) return

        const file = files[0]

        if (!checkExtension(file.name, 'csv')) {
            setStatus(translate('wrongFileType'))
            setFileIsLoading(false)
            return
        }

        setFileIsLoading(true)
        const formData = new FormData()
        formData.append('charset', encoding)
        formData.append('delimiter', divider ? divider : ',')
        formData.append('file', file)

        if (tokens.accessToken) {
            api.post('uploadSchoolSchedule', formData, {
                Authorization: `Bearer ${tokens.accessToken}`,
            }).then()
        }
    }

    const downloadChangeStatus = (data) => {
        let status = ''

        switch (data) {
            case 'start':
                status = translate('downloadFileHasStarted')
                break
            case 'stop':
                status = translate('downloadComplete')
                break
            case 'error':
                status = translate('downloadError')
                break
            default:
                status = ''
        }

        setStatus(status)
    }

    useEffect(() => {
        api.send('schoolScheduleGetCSVParsingStatus', {}).then((res) => {
            if (res === null) {
                setFileIsLoading(false)
                return
            }

            if (!res.type) return

            if (res.type === 'error' || res.type === 'stop') {
                setFileIsLoading(false)
                return
            }

            downloadChangeStatus(res.type)
        })

        api.addObserver(
            'getSchoolClassesUploadStatusChanged',
            (data) => {
                console.log(data)

                if (!data.type) return

                downloadChangeStatus(data.type)

                if (data.type === 'error' || data.type === 'stop') {
                    if (data.detail) {
                        setErrorDetails(data.detail)
                    }

                    setFileIsLoading(false)
                }
            },
            listenersId
        )

        return () => {
            listenersId.forEach((id) => api.removeObserver(id))
        }
    }, [])

    return (
        <>
            <div className={styles.uploadCsvWrapper}>
                <div className={styles.caption}>
                    <Typography type="title">{translate('downloadFromFile')}</Typography>
                </div>
                <div className={styles.panel}>
                    <div className={`${styles.uploadUi} ${fileIsLoading ? styles.blockUi : ''}`}>
                        <div>
                            <div className={styles.fakeLabel}>{translate('fileCsv')}</div>
                            <label className={styles.label} htmlFor="fileUpload">
                                {files ? translate('fileSelected') : translate('chooseFile')}
                            </label>
                            <input
                                id="fileUpload"
                                accept=".csv"
                                type="file"
                                className={styles.input}
                                onChange={(e) => {
                                    if (e.target.files.length) {
                                        setFiles(e.target.files)
                                        return
                                    }

                                    setFiles(null)
                                }}
                            />
                        </div>
                        <div className={styles.divider}>
                            <div className={styles.dividerTitle}>{translate('separator')}</div>
                            <EditText
                                mod={'withBorder'}
                                value={divider}
                                placeholder=""
                                className={styles.dividerInput}
                                onChange={(value) => {
                                    setDivider(value)
                                }}
                            />
                        </div>
                        <div className={styles.dropDown}>
                            <Dropdown
                                label={translate('encoding')}
                                indentSize={'mini'}
                                onChange={(selected) => {
                                    setEncoding(selected.id)
                                }}
                                options={encodings}
                                value={encoding}
                            />
                        </div>
                        {files && !fileIsLoading && (
                            <Button
                                className={styles.uploadBtn}
                                mod={'fill'}
                                onClick={function () {
                                    setErrorDetails(null)
                                    sendData(files)
                                }}
                            >
                                {translate('startDownloadFile')}
                            </Button>
                        )}
                    </div>
                    {status && (
                        <div className={styles.statusWrapper}>
                            <div>
                                <b>{translate('status')}:</b> {status}
                            </div>
                            {errorDetails && renderErrorDetails(errorDetails)}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default UploadCsv
