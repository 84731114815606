import { addStyles } from 'ethcss'
import { item, grid } from 'theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { defaultThemeStyles } from './ActionBar-theme'

const actionBarStyles = {
    actionBarWrapper: {
        ...grid.w100,
        ...grid.mt_md,
    },
    actionBarWrapper_x: {
        marginTop: '5px !important',
    },
    content: {
        ...item.rounded_top_mini,
        borderRadius: '5px 5px 0 0',
        backgroundColor: `var(--var-module-instancesCatalog-component-blockItem-style-background, ${defaultThemeAppStyles.panel})`,
    },
    tools: {
        ...grid.staticRow,
        ...grid.normalCenter,
        ...grid.startJustify,
        ...grid.pr_md,
        ...grid.pl_md,
        borderBottom: `1px solid var(--var-module-instancesCatalog-component-blockItem-style-background, ${defaultThemeAppStyles.border})`,
    },
    margin_x: {
        marginTop: '30px',
    },
    margin_no_x: {
        marginTop: '0px !important',
    },
    deleteButton: {
        ...grid.auto,
        width: 250,
    },
    allertModal: {},
    allertModalForm: {
        ...grid.p_mini,
        color: `var(--var-module-catalog-component-actionBar-style-modalFormText, ${defaultThemeStyles.modalFormText})`,
    },
    allertModalTitle: {
        ...grid.w100,
        ...grid.mb_mini,
    },
    allertModalButton: {
        ...grid.mr_mini,
    },
    addButton: {
        paddingRight: 21,
    },
    uploadButton: {
        ...grid.stretch,
        ...grid.flex,
        ...grid.endJustify,
        ...grid.mr_md,
    },
    title: {
        ...grid.rowCenter,
        ...grid.p_md,
    },
    delimiterWrapper: {
        width: 'auto',
    },
    delimiter: {
        width: 110,
        ...grid.mr_md,
    },
    dateInterval: {
        display: 'flex',
        padding: '20px 0',
    },
}

addStyles(actionBarStyles)
export default actionBarStyles
