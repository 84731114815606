import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { mediaCSS } from 'theme/media'
import { IStyles } from '../../theme'

const colSizes: IStyles = {
    ...grid.w50,
    [mediaCSS.tabMini]: {
        ...grid.w100,
    },
}

const linkEditorStyles: IStyles = {
    wrapper: {
        ...grid.mt_md,
        backgroundColor: `var(--var-module-app-component-app-style-panel, ${defaultThemeAppStyles.panel})`,
        ...item.rounded_full_mini,
    },
    content: {
        ...grid.p_md,
    },
    title: {
        ...grid.mb_mini,
        textAlign: 'center',
        color: `var(--var-module-app-component-app-style-textMain, ${defaultThemeAppStyles.textMain})`,
    },
    row: {
        ...grid.w100,
        ...grid.row,
        ...grid.mt_md,
    },
    colLeft: {
        ...colSizes,
        ...grid.pr_md,
        [mediaCSS.tabMini]: {
            ...grid.pr_none,
            width: '100%',
            marginBottom: 16,
        },
    },
    colRight: {
        ...colSizes,
        [mediaCSS.tabMini]: {
            width: '100%',
            marginBottom: 16,
        },
    },
    radios: {
        ...grid.rowCenter,
    },
    radio: {
        ...grid.mr_md,
    },
    disabled: {
        opacity: 0.3,
        pointerEvents: 'none',
    },
    domainsInput: {
        cursor: 'text',
        span: {
            color: `var(--var-module-app-component-app-style-textMain, ${defaultThemeAppStyles.textMain})`,
        },
    },
}

addStyles(linkEditorStyles)
export default linkEditorStyles
