import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { defaultThemeStyles } from '../editor.theme'
import { mediaCSS, textSizes } from 'theme/media'
import { colors } from '../../../theme'

const lineSize = 20
const editorScreenStyles = {
    pagesLinesWrapper: {
        position: 'relative',
        paddingLeft: '106px',

        [mediaCSS.tab]: {
            paddingLeft: 0,
        },
    },
    pagesLinesWrapperIsSimple: {
        paddingLeft: 0,
    },
    pagesWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        backgroundColor: '#b3b3b3',
        paddingLeft: '6px',
        paddingRight: '14px',
    },
    pagesList: {
        position: 'relative',
        maxHeight: 'calc(100% - 80px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            width: '6px',
            background: '#ffffff',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'gray',
            borderRadius: '10px',
        },
    },
    deleteBtn: {
        position: 'absolute',
        right: '4px',
        bottom: '4px',
        zIndex: 2,
        opacity: 0,
        transition: 'opacity 0.3s',
    },
    page() {
        const self = this

        return {
            position: 'relative',
            width: '86px',
            height: '54px',
            borderRadius: '5px',
            backgroundColor: '#727575',
            marginBottom: '13px',
            lineHeight: '1',
            cursor: 'pointer',
            overflow: 'hidden',

            '&::before': {
                content: '""',
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0,0,0,0.3)',
                zIndex: 1,
                pointerEvents: 'none',
            },
            listStyle: 'none',
            '&:hover': {
                [`& .${self.sortableIcon}`]: {
                    display: 'block',
                },
                '.delete': {
                    opacity: 1,
                },
            },
        }
    },
    pageActive: {
        '&::before': {
            content: '""',
            background: 'rgba(0,0,0,0) !important',
            border: '1px solid #ffffff',
            boxSizing: 'border-box',
            borderRadius: '5px',
        },
    },
    pageNumber: {
        position: 'absolute',
        top: '2px',
        left: '8px',
        color: '#FFFFFF',
        textShadow: '1px 1px 0 #000000',
        zIndex: 2,
    },
    sortableIcon: {
        position: 'absolute',
        display: 'none',
        left: '6px',
        bottom: '2px',
        zIndex: 1,
    },
    pageNew: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: '16px',
        left: '6px',
        width: '86px',
        height: '54px',
        borderRadius: '5px',
        border: '1px solid #FFFFFF',
    },
    sourcePreview: {
        position: 'absolute',
        top: '50%',
        left: 0,
        width: '100%',
        transform: ' translateY(-50%)',
        zIndex: 0,
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    scroll: {
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            width: 6,
            background: '#ffffff',
        },
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    lines: {
        ...item.rel,
        ...item.overNone,
        ...grid.pt_md,
        ...grid.pb_md,
        ...grid.pl_mdPlus,
        ...grid.pr_mdPlus,
        backgroundColor: `var(--var-module-app-component-editor-style-backgroundMain, ${defaultThemeStyles.backgroundMain})`,

        [mediaCSS.tab]: {
            padding: 0,
        },
    },
    lineH: {
        ...grid.fullWidth,
        height: 1,
        backgroundColor: `var(--var-module-app-component-editor-style-selectLine, ${defaultThemeStyles.selectLine})`,
    },
    lineV: {
        ...grid.fullHeight,
        width: 1,
        backgroundColor: `var(--var-module-app-component-editor-style-selectLine, ${defaultThemeStyles.selectLine})`,
    },
    content: {
        ...grid.colCenter,
        ...grid.full,
        position: 'relative',
        backgroundColor: `var(--var-module-app-component-editor-style-backgroundContent, ${defaultThemeStyles.backgroundContent})`,
    },
    text: {
        fontSize: textSizes.normalPlus,
        textAlign: 'center',
        color: `var(--var-module-app-component-editor-style-textSub, ${defaultThemeStyles.textSub})`,

        [mediaCSS.tab]: {
            fontSize: 12,
        },
    },
    lastText: {
        ...grid.rowCenter,
        fontSize: textSizes.md,

        [mediaCSS.tab]: {
            fontSize: 12,
        },
    },
    full: {
        ...grid.full,
        ...grid.rowCenter,
    },
    bottomToolbar: {
        ...grid.p_mdPlus,
        ...grid.pt_mini,
        ...grid.pb_mini,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
        borderTop: '1px solid #ffffff',
        backgroundColor: `var(--var-module-app-component-editor-style-backgroundSub, ${defaultThemeStyles.backgroundSub})`,

        [mediaCSS.tab]: {
            position: 'relative',
            padding: '0 10px 0 0',
        },
    },
    lineHWrapper: {
        ...grid.pl_mdPlus,
        ...grid.pr_mdPlus,
        ...grid.colCenter,
        ...grid.fullWidth,
        height: lineSize,
        marginTop: -(lineSize / 2),
    },
    lineVWrapper: {
        ...grid.pt_md,
        ...grid.pb_md,
        ...grid.colCenter,
        ...grid.fullHeight,
        width: lineSize,
        marginLeft: -(lineSize / 2),
    },
    pageListBlockMobile: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: '50%',
        top: '-54px',
        backgroundColor: colors.white,
        borderRadius: 5,
        padding: '14px 30px',
        transform: 'translateX(-50%)',
        zIndex: 1000,
    },
    mobilePage: {
        color: colors.grey,
        margin: '0 7px',
    },
    mobilePageActive: {
        fontWeight: 'bold',
    },
    animationSettingsWrapper: {
        position: 'relative',
    },
    addNewPage: {
        margin: '0 24px',
    },
    pagesBlock: {
        display: 'flex',
        alignItems: 'center',
    },
    pagesListMobile: {
        margin: '0 7px',
    },
}

addStyles(editorScreenStyles)
export default editorScreenStyles
