import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { IStyles } from '../../../../theme'

const ItemUsersStyles: IStyles = {
    wrapper: {
        ...grid.row,
        ...grid.mt_md,
        ...item.panel,
    },
    cellWrapper: {
        ...grid.p_none,
        ...item.rel,
        ...item.transition,
    },
}

addStyles(ItemUsersStyles)
export default ItemUsersStyles
