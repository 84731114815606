import React from 'react'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import { IState, ITableEditorProps, TableEditorMethods } from './tableEditor.local.methods'
import TableEditorSize from './__size/tableEditor__size'
import TableEditorPosition from './__position/tableEditor__position'
import TableEditorText from './__text/tableEditor__text'
import TableEditorData from './__data/tableEditor__data'
import TableEditorSpecialOptions from './__specialOptions/tableEditor__specialOptions'
import TableEditorPreview from 'blocks.app/tableEditor/_preview/tableEditor_preview'
import styles from './tableEditor.jcss'
import { cn } from 'ethcss'
import { text as textStyles } from 'theme'
import translate from 'core/translate'
import grid from 'blocks.simple/grid/grid.jcss'
import Button from 'blocks.simple/button/button'
import { Typography } from 'atoms/Typography'
import { connect } from 'react-redux'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { parseCsv } from 'pages/files/files.state'

const TableEditor = (p_: ITableEditorProps) => {
    const {
        getSelectedCell,
        onSelectCell,
        onMultiSelectCell,
        onMultiSelectCells,
        cancelCellSettings,
        applyCellMultiSettings,
        applyCellSettings,
        changeTab,
        onUpdateTableSize,
        onUpdateRowHeight,
        onUpdateColWidth,
        onChangeCellProps,
        onChangeConnectedData,
        onDropPreview,
        onChangeEncoding,
        setState,
        state,
        tabs,
        encodings,
    } = TableEditorMethods(p_)
    const s_ = state

    const selectedCell = getSelectedCell()

    return (
        <div className={styles.wrapper}>
            <div className={p_.small ? styles.smallPreviewWrapper : styles.previewWrapper}>
                <div className={p_.small ? styles.smallPreview : styles.preview}>
                    <Rectangle width={5} height={2}>
                        <TableEditorPreview
                            onSelectCell={onSelectCell}
                            onMultiSelectCell={onMultiSelectCell}
                            onMultiSelectCells={onMultiSelectCells}
                            selectedCellRow={s_.selectedCellRow}
                            selectedCellCol={s_.selectedCellCol}
                            multiSelect={s_.multiSelect}
                            tableFormat={s_.tableOptions.tableFormat}
                            cells={s_.tableOptions.cells}
                        />
                    </Rectangle>
                </div>
                <Typography className={cn(textStyles.center, grid.pt_mdPlus)} type="title">
                    {translate('applyToAll')}:
                </Typography>
                <div
                    className={cn(
                        styles.buttonWrapperSm,
                        p_.small ? grid.colCenter : grid.rowCenter,
                        grid.noWrap,
                        grid.mt_md
                    )}
                >
                    <Button
                        indentSize="mini"
                        className={cn(grid[`m${p_.small ? 'b' : 'r'}_mini`], styles.tabBtn)}
                        disabled={!s_.prevTableOptions}
                        onClick={cancelCellSettings}
                    >
                        {translate('cancelAction')}
                    </Button>
                    <Button
                        indentSize="mini"
                        mod={'fill'}
                        className={cn(grid[`m${p_.small ? 'b' : 'r'}_mini`], styles.tabBtn)}
                        onClick={applyCellMultiSettings}
                    >
                        {translate('applySelected')}
                    </Button>
                    <Button indentSize="mini" mod={'fill'} onClick={applyCellSettings} className={styles.tabBtn}>
                        {translate('applyAll')}
                    </Button>
                </div>
            </div>
            <div className={styles.tabs}>
                {tabs.map((tab: { id: string; name: string }, index: number) => {
                    const disabledTab = s_.tab !== tab.id && selectedCell === undefined

                    return (
                        <div
                            key={`tableEditor_tab_${index}`}
                            className={cn(styles.item, textStyles.low, {
                                [styles.itemActive]: tab.id === s_.tab,
                                [styles.disabled]: disabledTab,
                            })}
                            onClick={() => {
                                if (!disabledTab) {
                                    changeTab(tab.id)
                                }
                            }}
                        >
                            <span>{translate(tab.name)}</span>
                        </div>
                    )
                })}
            </div>
            <div
                className={cn(styles.content, {
                    [styles.textContent]: s_.tab === 'text',
                    [p_.small ? styles.contentPanelSmall : styles.contentPanel]:
                        s_.tab === 'tableSize' || s_.tab === 'connectedData',
                })}
            >
                {(() => {
                    switch (s_.tab) {
                        case 'tableSize':
                            return (
                                <TableEditorSize
                                    name={s_.tableName}
                                    options={s_.tableOptions.tableFormat}
                                    onChangeName={(tableName) =>
                                        setState((prevState: IState) => {
                                            return { ...prevState, tableName }
                                        })
                                    }
                                    onUpdateTableSize={onUpdateTableSize}
                                    onUpdateRowHeight={onUpdateRowHeight}
                                    onUpdateColWidth={onUpdateColWidth}
                                />
                            )
                        case 'position':
                            return (
                                <TableEditorPosition
                                    onChangePos={onChangeCellProps}
                                    options={selectedCell}
                                    small={p_.small}
                                    selectedCellRow={s_.selectedCellRow}
                                    selectedCellCol={s_.selectedCellCol}
                                />
                            )
                        case 'text':
                            return (
                                <TableEditorText onChange={onChangeCellProps} options={selectedCell} small={p_.small} />
                            )
                        case 'cellOptions':
                            return (
                                <TableEditorSpecialOptions
                                    onChange={onChangeCellProps}
                                    selectedCell={selectedCell}
                                    small={p_.small}
                                />
                            )
                        case 'connectedData':
                            return (
                                <TableEditorData
                                    small={p_.small}
                                    encodings={encodings}
                                    connectedData={s_.tableOptions.connectedData}
                                    encoding={s_.encoding}
                                    onChange={onChangeConnectedData}
                                    onChangeBindData={onDropPreview}
                                    onChangeEncoding={onChangeEncoding}
                                    isLoadingPreview={s_.isLoadingPreview}
                                />
                            )
                        default:
                            return null
                    }
                })()}
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        emitError: (err: string) => dispatch(emitError(err)),
        parseCsv: (parseCsvData: any) => dispatch(parseCsv(parseCsvData)),
    }
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(TableEditor)
