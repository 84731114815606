import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { defaultThemeStyles } from 'blocks.app/sidebar/Sidebar-theme'
import { defaultThemeStyles as defaultThemeTabsStyles } from 'blocks.simple/tabs/tabs.theme'

const editorConditionSettings = {
    row: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
        ...grid.p_md,
        ...grid.pb_none,
    },
    rowNoAlignCenter: {
        ...grid.row,
        ...grid.justify,
        ...grid.p_md,
        ...grid.pb_none,
    },
    buttons: {
        ...grid.row,
        ...grid.center,
    },
    title: {
        ...grid.p_md,
        ...grid.mt_md,
        textAlign: 'center',
        color: `var(--var-module-sidebar-component-sidebar-style-title, ${defaultThemeStyles.title})`,
        borderTop: `1px solid var(--var-module-sidebar-component-sidebar-style-border, ${defaultThemeStyles.border})`,
        borderBottom: `1px solid var(--var-module-sidebar-component-sidebar-style-border, ${defaultThemeStyles.border})`,
    },
    tabs: {
        ...grid.row,
    },
    tab: {
        ...grid.p_mini,
        cursor: 'pointer',
        textAlign: 'center',
        textTransform: 'uppercase',
        borderRight: `1px solid var(--var-module-ui-component-tabs-style-border, ${defaultThemeTabsStyles.border})`,
        '&:last-child': {
            borderRight: 'none',
        },
        color: `var(--var-module-ui-component-tabs-style-textMain, ${defaultThemeTabsStyles.textMain})`,
        borderBottom: `1px solid var(--var-module-ui-component-tabs-style-border, ${defaultThemeTabsStyles.border})`,
    },
    tabActive: {
        color: `var(--var-module-ui-component-tabs-style-active, ${defaultThemeTabsStyles.active})`,
        backgroundColor: `var(--var-module-ui-component-tabs-style-activeBackground, ${defaultThemeTabsStyles.activeBackground})`,
        borderBottom: `none`,
    },
    borderTop: {
        borderTop: `1px solid var(--var-module-ui-component-tabs-style-border, ${defaultThemeTabsStyles.border})`,
    },
}

addStyles(editorConditionSettings)
export default editorConditionSettings
