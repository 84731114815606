import { addStyles } from 'ethcss'
import { grid, text } from 'theme'

const catalogTemplateBroadcastsStyles = {
    broadcastsTemplateName: {
        ...grid.row,
        ...grid.noWrap,
        ...grid.normalCenter,
        ...text.left,
        ...text.grey,
    },
    broadcastsTemplateIcon: {
        ...grid.mr_mini,
    },
}

addStyles(catalogTemplateBroadcastsStyles)
export default catalogTemplateBroadcastsStyles
