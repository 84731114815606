import { addStyles } from 'ethcss'
import { grid, item, text } from 'theme'

const itemUsersStyles = {
    catalogItemUsersWrapper: {
        ...grid.space,
        ...grid.mb_mdPlus,
        ...item.pointer,
        ...item.noSelection,
        ...item.rounded_full_mini,
        ...item.rel,
    },
    catalogItemUsersContent: {
        //...grid.p_mini
    },
    catalogItemUsersContentActive: {
        ...item.cloud,
    },
    catalogItemUsersCell: {
        ...grid.fullWidth,
        ...grid.p_md,
        ...item.rel,
        ...item.blackLight,
        borderRadius: '5px',
        position: 'relative',
    },
    catalogItemUsersCellMultiSelect: {
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0, 0.5)',
            zIndex: 1,
        },
    },
    centered: {
        ...grid.rowCenter,
    },
    avatarImageWrapper: {
        margin: '0 auto',
    },
    catalogItemUsersFooter: {
        ...grid.mt_mini,
        ...grid.w100,
    },
    catalogItemUsersInfo: {
        ...grid.w90,
        '& div': {
            lineHeight: 1.5,
        },
    },
    catalogItemUsersRoles: {
        ...item.ellipsisPure,
    },
    catalogItemUsersName: {
        fontSize: '18px',
        fontWeight: 600,
        ...item.ellipsisPure,
    },
    catalogItemUsersCheckbox: {
        ...item.abs,
        top: 8,
        right: 4,
        zIndex: 2,
    },
    catalogIitemUsersAuthType: {
        ...item.abs,
        ...grid.rowCenter,
        ...grid.w100,
        ...text.blue,
        left: 0,
    },
}

addStyles(itemUsersStyles)
export default itemUsersStyles
