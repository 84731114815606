import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'blocks.simple/icon/icon'
import { cn } from 'ethcss'
import styles from './videoEditor__toolbar.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import translate from 'core/translate'
import { routes } from 'features/routes'
import { changeLocation } from 'features/routes'

interface IVideoEditorToolbarProps {
    onChangeShowSettings: (show: null | string) => void
    toggleMinimized: () => void
    onSave: () => void
    media: { [index: string]: any }
    selectedTasks: []
    minimized?: boolean
    animationSettings: boolean
}
export const VideoEditorToolbar = (props: IVideoEditorToolbarProps) => {
    const p_ = props
    const disabled = !p_.media && !p_.selectedTasks.length

    const isAllTaskSuccess = () => {
        if (disabled) {
            return true
        }

        if (p_.selectedTasks.length) {
            return !p_.selectedTasks.every((task: { [index: string]: any }) => task.status === 'success')
        }

        if (p_.media) {
            return p_.media.status !== 'success'
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.controls}>
                <div />
                <div className={grid.row}>
                    <div className={cn(grid.mr_micro, { [styles.selected]: p_.animationSettings })}>
                        <Icon
                            type="animation"
                            color="white"
                            containerClass={grid.p_micro}
                            size={20}
                            tooltip={{ html: translate('animations') }}
                            onClick={function () {
                                p_.onChangeShowSettings('animations')
                            }}
                            disabled={disabled}
                        />
                    </div>
                    <div className={grid.mr_micro}>
                        <Icon
                            type="gear"
                            color="white"
                            containerClass={grid.p_micro}
                            size={20}
                            onClick={function () {
                                p_.onChangeShowSettings('settings')

                                if (p_.minimized) {
                                    p_.toggleMinimized()
                                }
                            }}
                            tooltip={{ html: translate('settings') }}
                            disabled={disabled}
                        />
                    </div>
                    <div className={grid.mr_micro}>
                        <Icon
                            type="diskette"
                            color="white"
                            containerClass={grid.p_micro}
                            size={20}
                            onClick={p_.onSave}
                            disabled={disabled || isAllTaskSuccess()}
                            tooltip={{ html: translate('save') }}
                        />
                    </div>
                    <Icon
                        type="exit"
                        color="white"
                        size={20}
                        onClick={function () {
                            changeLocation(`/${routes.files.path}`)
                        }}
                        containerClass={grid.p_micro}
                        tooltip={{ html: translate('exit') }}
                    />
                </div>
            </div>
        </div>
    )
}

export default VideoEditorToolbar
