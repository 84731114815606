import React from 'react';

const isTypeOfProp = (prop, type) => {
    return typeof prop === type;
};

class ShowMoreMethods extends React.Component {
    state = {
        isShow: this.props.isShowInit,
        dimensions: {}
    };
    static getDerivedStateFromProps(nextProps) {
        if (isTypeOfProp(nextProps.isShow, 'boolean')) {
            return {isShow: nextProps.isShow};
        }
    }
    onToggle = () => {
        const p_ = this.props;
        const s_ = this.state;

        if (isTypeOfProp(p_.isShow, 'boolean')) {
            p_.onToggle(!s_.isShow);
            return;
        }

        p_.onToggle(!s_.isShow);
        this.setState({isShow: !s_.isShow});
    };
    renderTrigger = () => {
        const p_ = this.props;

        return React.Children.map(p_.trigger, child => {
            return React.cloneElement(child, {
                onClick: this.onToggle
            });
        });
    };
    renderChildren = (ref) => {
        const p_ = this.props;

        return React.Children.map(p_.children, child => {
            return React.cloneElement(child, {
                ref
            });
        });
    };
}

export default ShowMoreMethods