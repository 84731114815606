import React from 'react'
import DisplaysAddGroupMethods, { Data, Item, SetPartialData } from './displays__addGroup.local.methods'
import EditText from 'blocks.simple/editText/editText'
import Button from 'blocks.simple/button/button'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import { Scrollbars } from 'react-custom-scrollbars'
import { connect } from 'react-redux'
import { cn } from 'ethcss'
import styles from './displays__addGroup.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { text as textStyles } from 'theme'
import translate from 'core/translate'
import { displaysAddGroupActions } from './displays__addGroup.state'
import { displaysActions } from '../displays.state'
import { Typography } from 'atoms/Typography'

class DisplaysAddGroup extends DisplaysAddGroupMethods {
    render() {
        const s_ = this.state
        const p_ = this.props

        const isEditGroupTitle = p_.isEdit ? 'save' : 'add'
        const validation = () => {
            return !(p_.data.timezone || s_.parentTimezone != null)
        }

        return (
            <form className={styles.wrapper} onSubmit={this.submit}>
                <div className={styles.title}>
                    {p_.isEdit ? `${translate('informationAboutTheGroup')} ${p_.data.name}` : translate('groupInfo')}
                </div>
                <div className={styles.content}>
                    <div className={styles.formWrapper}>
                        <div className={grid.mb_micro}>
                            <EditText
                                mod={'withBorder'}
                                validationBorder={s_.data.error ? true : false}
                                label={
                                    <div
                                        className={cn(
                                            grid.mb_micro,
                                            textStyles.up,
                                            textStyles.centerBlock,
                                            textStyles.normalPlus
                                        )}
                                    >
                                        {translate('name')}
                                    </div>
                                }
                                id="name"
                                type="text"
                                value={p_.data.name}
                                className={cn(textStyles.center, styles.noBoxShadow)}
                                onChange={(name: string) => {
                                    p_.setPartialData({ name })
                                    this.inputNullCheck(name, 'name')
                                }}
                            />
                            <div
                                className={cn(
                                    grid.mt_micro,
                                    textStyles.centerBlock,
                                    s_.data.error ? '' : item.hidden,
                                    item.errorInputText
                                )}
                            >
                                {translate('fieldIsRequired')}
                            </div>
                        </div>

                        <div className={grid.mb_md}>
                            <Dropdown
                                placeholder={translate('chooseGroup')}
                                label={
                                    <div
                                        className={cn(
                                            grid.mb_micro,
                                            textStyles.up,
                                            textStyles.normalPlus,
                                            textStyles.centerBlock
                                        )}
                                    >
                                        <Typography type="title">{translate('group')}</Typography>
                                    </div>
                                }
                                id="group"
                                value={p_.data.parentId}
                                options={s_.groups}
                                centered
                                searchable
                                type="tree"
                                onChange={function (selected) {
                                    p_.setPartialData({ parentId: selected.id })
                                }}
                            />
                        </div>

                        <div className={cn(grid.mb_md)}>
                            <div
                                className={cn(
                                    grid.mb_mini,
                                    textStyles.up,
                                    textStyles.normalPlus,
                                    textStyles.centerBlock
                                )}
                            >
                                <Typography type="title">{translate('timezones')}</Typography>
                            </div>
                            <Checkbox
                                className={cn(grid.p_micro)}
                                label={
                                    <div className={cn(grid.pl_mini, grid.pr_mini, textStyles.normal)}>
                                        <Typography type="title">{translate('useGroupTimezone')}</Typography>
                                    </div>
                                }
                                checked={s_.groupTimezone}
                                onClick={(checked: boolean) => {
                                    this.setState({ groupTimezone: checked })
                                }}
                            />
                        </div>

                        <div className={cn(grid.mb_md, { [styles.disable]: s_.groupTimezone })}>
                            <Dropdown
                                id={'timezonesGroup'}
                                value={
                                    s_.groupTimezone
                                        ? s_.parentTimezone
                                            ? s_.parentTimezone
                                            : p_.data.timezone
                                        : p_.data.timezone
                                }
                                options={[{ id: null, name: translate('reset') }].concat(p_.timezones)}
                                placeholder={translate('notSelected')}
                                disabled={s_.groupTimezone}
                                searchable
                                centered
                                onChange={(selected) => {
                                    p_.setPartialData({
                                        timezone: selected.id,
                                        inheritTimezone:
                                            selected.id === null
                                                ? false
                                                : p_.data.inheritTimezone
                                                ? p_.data.inheritTimezone
                                                : false,
                                    })
                                }}
                                labelClassName={cn(item.ellipsis)}
                            />
                        </div>
                        <div className={cn(grid.mb_md)}>
                            <Checkbox
                                disabled={validation()}
                                className={cn(grid.p_micro, { [styles.disable]: validation() })}
                                label={
                                    <div className={cn(grid.pl_mini, grid.pr_mini, textStyles.normal)}>
                                        <Typography type="title">{translate('acceptTimezoneFromRoot')}</Typography>
                                    </div>
                                }
                                checked={p_.data.inheritTimezone ? p_.data.inheritTimezone : false}
                                onClick={(checked: boolean) => {
                                    p_.setPartialData({ inheritTimezone: checked })
                                }}
                            />
                        </div>

                        <div className={grid.mt_mdPlus}>
                            <EditText
                                label={
                                    <div
                                        className={cn(
                                            grid.mb_micro,
                                            textStyles.up,
                                            textStyles.centerBlock,
                                            textStyles.normalPlus
                                        )}
                                    >
                                        {translate('description')}
                                    </div>
                                }
                                id="description"
                                type="area"
                                mod="withBorder"
                                className={cn(styles.noBoxShadow)}
                                rows={'6'}
                                value={p_.data.description}
                                placeholder={translate('groupDescriptionHelper')}
                                onChange={function (description: string) {
                                    p_.setPartialData({ description })
                                }}
                            />
                        </div>
                        {!!s_.schedules.length && (
                            <div className={grid.mt_mdPlus}>
                                <div className={cn(textStyles.up, textStyles.normalPlus, textStyles.centerBlock)}>
                                    <Typography type="title">{translate('deleteSchedule')}</Typography>
                                </div>
                                <div className={styles.deleteSchedule}>
                                    <div className={styles.deleteScheduleHeader}>
                                        <Checkbox
                                            className={cn(grid.p_micro)}
                                            label={
                                                <div className={cn(grid.pl_mini, grid.pr_mini, textStyles.normal)}>
                                                    {translate('deleteAllGroupSchedules')}
                                                </div>
                                            }
                                            checked={
                                                p_.data.scheduleIdToDelete &&
                                                p_.data.scheduleIdToDelete.length === s_.schedules.length
                                            }
                                            onClick={() => {
                                                let scheduleIdToDelete: number[] = []

                                                if (
                                                    p_.data.scheduleIdToDelete &&
                                                    p_.data.scheduleIdToDelete.length !== s_.schedules.length
                                                ) {
                                                    scheduleIdToDelete = s_.schedules.map(
                                                        (schedule: { id: number }) => schedule.id
                                                    )
                                                }

                                                p_.setPartialData({ scheduleIdToDelete })
                                            }}
                                        />
                                    </div>
                                    <Scrollbars autoHeight autoHeightMax={195}>
                                        {s_.schedules.map((schedule: Item) => (
                                            <Checkbox
                                                key={`groupSchedule_${schedule.id}`}
                                                wrapperClassName={'groupSchedule'}
                                                className={cn(grid.p_micro)}
                                                label={
                                                    <div className={styles.listItem}>
                                                        <div>{this.groupScheduleInfo(schedule)}</div>
                                                        <div>{this.groupScheduleInfo(schedule, 'date')}</div>
                                                        <div>{schedule.broadcast.title}</div>
                                                    </div>
                                                }
                                                checked={
                                                    p_.data.scheduleIdToDelete &&
                                                    p_.data.scheduleIdToDelete.includes(schedule.id)
                                                }
                                                onClick={() => {
                                                    let scheduleIdToDelete

                                                    if (p_.data.scheduleIdToDelete.includes(schedule.id)) {
                                                        scheduleIdToDelete = p_.data.scheduleIdToDelete.filter(
                                                            (item: any) => item.id === schedule.id
                                                        )
                                                    } else {
                                                        scheduleIdToDelete = [
                                                            ...p_.data.scheduleIdToDelete,
                                                            schedule.id,
                                                        ]
                                                    }

                                                    p_.setPartialData({ scheduleIdToDelete })
                                                }}
                                            />
                                        ))}
                                    </Scrollbars>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.footer}>
                    {p_.isEdit && (
                        <Button mod={'withBorder'} onClick={this.onClose} className={cn(grid.mr_md)}>
                            {translate('cancel')}
                        </Button>
                    )}
                    <Button type={'submit'} mod={'fill'} disabled={s_.data.error ? true : false}>
                        {translate(isEditGroupTitle)}
                    </Button>
                </div>
            </form>
        )
    }
}

const mapStateToProps = (state: {
    app: { timezones: string[] }
    displays: any
    displays__addGroup: {
        isEdit: boolean
        error: string
        data: Data
    }
}) => ({
    data: state.displays__addGroup.data,
    error: state.displays__addGroup.error,
    isEdit: state.displays__addGroup.isEdit,
    groups: state.displays.groups,
    timezones: state.app.timezones,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        setError: (err: string) => dispatch(displaysAddGroupActions.setError(err)),
        setPartialData: (data: SetPartialData) => dispatch(displaysAddGroupActions.setPartialData(data)),
        closeModal: () => dispatch(displaysActions.closeModal()),
        clearData: () => dispatch(displaysAddGroupActions.clearData()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplaysAddGroup)
