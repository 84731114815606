import snacksTemplate from './_snacks'
import burgersBeefTemplate from './_burgers/_beef'
import burgersPremiumTemplate from './_burgers/_premium'
import burgersChickenTemplate from './_burgers/_chicken'
import smallBurgersTemplate from './_smallBurgers'
import comboTemplate from './_combo'
import drinksSliderTemplate from './_drinks/_slider'
import drinksMenuTemplate from './_drinks/_menu'
import samsoniteSitesTemplate from './_samsonite/_sites'
import samsoniteVideosTemplate from './_samsonite/_videos'
import samsoniteAdvertisingTemplate from './_samsonite/_advertising'
import askonaSlidesTemplate from './_askona/_slides'
import askonaButtonsTemplate from './_askona/_buttons'
import askonaRecommendedTemplate from './_askona/_recommended'
import pitamixTemplate from './_pitamix'
import { ReactNode } from 'react'

const template: { [index: string]: ReactNode } = {
    snacks: snacksTemplate,
    burgersChicken: burgersChickenTemplate,
    burgersPremium: burgersPremiumTemplate,
    burgersBeef: burgersBeefTemplate,
    small_burgers: smallBurgersTemplate,
    combo: comboTemplate,
    drinksSlider: drinksSliderTemplate,
    drinksMenu: drinksMenuTemplate,
    samsoniteSites: samsoniteSitesTemplate,
    samsoniteVideos: samsoniteVideosTemplate,
    samsoniteAdvertising: samsoniteAdvertisingTemplate,
    askonaSlides: askonaSlidesTemplate,
    askonaButtons: askonaButtonsTemplate,
    askonaRecommended: askonaRecommendedTemplate,
    pitamix: pitamixTemplate,
}

export default (type: string) => template[type]
