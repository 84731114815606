import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { IStyles } from '../../../theme'

const previewImageStyles: IStyles = {
    wrapper: {
        ...grid.full,
    },
    loader: {
        zIndex: 0,
        ...item.blackOpacity,
    },
    image: {
        outline: 'none',
        ...grid.full,
        ...grid.colCenter,
        ...grid.center,
        '&>img': {
            maxWidth: '100%',
            maxHeight: '100%',
        },
    },
}

addStyles(previewImageStyles)
export default previewImageStyles
