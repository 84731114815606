import { colors } from 'theme'

export const defaultThemeStyles = {
    'default-textMain': colors.blackLight,
    'default-error': colors.errorLight,
    'default-shadow': '2px 3px 4px 0px rgba(0, 0, 0, 0.25)',
    'default-border': colors.greyLight,
    'default-icon': colors.grey,
    'default-placeholder': colors.grey,
    'default-background': colors.white,
    'text-textMain': colors.blackLight,
    'text-error': colors.errorLight,
    'text-shadow': 'none',
    'text-border': 'transparent',
    'text-icon': colors.grey,
    'text-placeholder': colors.grey,
    'text-background': 'transparent',
    'white-textMain': colors.white,
    'white-error': colors.errorLight,
    'white-shadow': 'none',
    'white-border': 'transparent',
    'white-icon': colors.white,
    'white-placeholder': colors.white,
    'white-background': colors.white,
    'withBottomLine-textMain': colors.blackLight,
    'withBottomLine-error': colors.errorLight,
    'withBottomLine-shadow': 'transparent',
    'withBottomLine-border': colors.blackLight,
    'withBottomLine-icon': colors.grey,
    'withBottomLine-placeholder': colors.grey,
    'withBottomLine-background': colors.white,
    'withBorder-textMain': colors.blackLight,
    'withBorder-error': colors.errorLight,
    'withBorder-shadow': '2px 3px 4px 0px rgba(0, 0, 0, 0.25)',
    'withBorder-border': colors.blackLight,
    'withBorder-icon': colors.blackLight,
    'withBorder-placeholder': colors.grey,
    'withBorder-background': colors.white,
    'withLightBorder-textMain': colors.blackLight,
    'withLightBorder-error': colors.errorLight,
    'withLightBorder-shadow': '2px 3px 4px 0px rgba(0, 0, 0, 0.25)',
    'withLightBorder-border': colors.grey,
    'withLightBorder-icon': colors.blackLight,
    'withLightBorder-placeholder': colors.grey,
    'withLightBorder-background': colors.white,

    'inverseBorder-textMain': colors.white,
    'inverseBorder-border': colors.white,
    'inverseBorder-background': 'transparent',
}
