import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../../theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { textSizes } from 'theme/media'

const settingsFilterListStyles: IStyles = {
    sortableIcon: {
        opacity: 0.5,
    },
    list: {
        ...grid.mb_mdPlus,
    },
    controls: {
        ...grid.row,
        ...grid.mt_md,
        ...grid.p_microPlus,
    },
    listItem() {
        const self = this

        return {
            ...grid.row,
            ...grid.justify,
            ...grid.p_md,
            ...grid.pt_mini,
            ...grid.pb_mini,
            ...grid.pl_micro,
            ...item.white,
            ...item.rel,
            ...item.pointer,
            listStyle: 'none',
            borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
            '&:hover': {
                [`& .${self.sortableIcon}`]: {
                    ...grid.row,
                    ...grid.normalCenter,
                    ...grid.fullHeight,
                    opacity: 1,
                },
            },
        }
    },
    description: {
        fontSize: textSizes.microPlus,
    },
    label: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
    },
    active() {
        return {
            boxShadow: `var(--var-module-app-component-app-style-shadow, ${defaultThemeStyles.shadow})`,
            fontSize: textSizes.normal,
            border: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
            borderBottomColor: 'transparent',
            [`& .${this.sortableIcon}`]: {
                ...grid.row,
                ...grid.normalCenter,
                ...grid.fullHeight,
                opacity: 1,
            },
        }
    },
    footer: {
        ...grid.row,
        ...grid.justify,
        ...grid.p_md,
    },
    text: {
        ...grid.ml_mini,
    },
    button: {
        width: 'auto',
    },
}

addStyles(settingsFilterListStyles)
export default settingsFilterListStyles
