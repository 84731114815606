import { cn } from 'ethcss'
import React, { FC } from 'react'
import DotTooltip from 'blocks.simple/dotTooltip/dotTooltip'
import Link from 'blocks.simple/link/link'
import { IAppSubMenuItemComponent } from './AppSubMenuItem-types'
import { styles } from './AppSubMenuItem-styles'
import { item, mediaJS } from 'theme'
import { appActions } from 'blocks.app/app/app.state'
import { useDispatch } from 'react-redux'
import { isHorizontalMenu } from 'core/helpers/menu'
import translate from 'core/translate'

export const AppSubMenuItem: FC<IAppSubMenuItemComponent> = ({
    routePath,
    isClickable,
    query,
    label,
    mods = [],
    childRouteItem,
    pathname,
    action,
    minimize,
    contentClassName,
    disabled = false,
    isHorizontal,
}) => {
    const dispatch = useDispatch()
    const activeClassName = childRouteItem
        ? cn(
              childRouteItem.path === pathname && minimize
                  ? styles.subMenuItem_type_minimizeActive
                  : childRouteItem.path === pathname ||
                    (pathname === 'reports' && childRouteItem.path?.includes(query.reportType))
                  ? styles.subMenuItem_type_active
                  : '',
              isHorizontalMenu() ? styles.subMenuItem_type_active_x : ''
          )
        : ''

    const checkPath = (routePath: string | null) => {
        if (routePath === '/scoreboards/add' || routePath === '/scoreboards/addFolder') {
            if (query.parentId) {
                return { pathname: routePath, state: { parentId: query.parentId } }
            }
        }
        return routePath
    }

    const subItemStyles = minimize ? styles.subMenuItem_type_minimize : styles.subMenuItem

    const onClick = (e: React.MouseEvent) => {
        if (disabled) return

        if (videoEditorCheck()) {
            e.preventDefault()
            return
        }

        if (mediaJS.tab.matches) {
            dispatch(appActions.toggleMobMenu(false))
        }

        if (action) {
            e.preventDefault()
            action()
        }

        if (isClickable) {
            dispatch(appActions.setActiveMenuItem(null))
        }
    }

    const videoEditorCheck = () => {
        return label !== translate('videoEditor') && pathname === 'files/videoEditor'
    }

    return (
        <Link
            className={cn(subItemStyles, activeClassName, {
                [styles.subMenuItem_type_disable]: videoEditorCheck() ? true : disabled,
            })}
            to={isClickable && !disabled ? checkPath(routePath) : null}
            mods={mods}
            onClick={onClick}
        >
            <div className={cn(contentClassName || styles.subMenuItem__content)}>
                {!minimize && <div className={styles.subMenuItem__divider}></div>}
                {isHorizontal ? (
                    <div className={item.ellipsis}>{label}</div>
                ) : (
                    <DotTooltip
                        tooltip={{
                            html: label,
                            position: 'left',
                            disabled: minimize,
                            followCursor: true,
                        }}
                        containerClass={cn(styles.subMenuItem__text, styles.subMenuItem__text_type_with_transition, {
                            [styles.subMenuItem__text_type_minimize]: minimize,
                            [styles.subMenuItem__text_type_default]: !minimize,
                        })}
                    >
                        <div className={item.ellipsis}>{label}</div>
                    </DotTooltip>
                )}
            </div>
        </Link>
    )
}
