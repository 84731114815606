import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { textSizes } from 'theme/media'
import { IStyles } from '../../../theme'

const autocompleteStyles: IStyles = {
    search: {
        ...item.rel,
    },
    select: {
        ...item.shadowButton,
        ...item.abs,
        top: '100%',
        right: 0,
        left: 0,
        zIndex: 99,
        ...item.white,
    },
    title: {
        ...grid.pt_micro,
        ...grid.pb_micro,
        paddingLeft: grid.pl_mdPlus['padding-left'] * 2,
        fontSize: textSizes.normal,
        textTransform: 'uppercase',
        ...item.greyLight,
    },
    list: {
        maxHeight: 248,
        overflowY: 'auto',
    },
    empty: {
        ...grid.pt_md,
        ...grid.pb_md,
        paddingLeft: grid.pl_mdPlus['padding-left'] * 2,
    },
}

addStyles(autocompleteStyles)
export default autocompleteStyles
