import { addStyles } from 'ethcss'
import { IStyles, mediaCSS, item, colors } from 'theme'
import { defaultThemeStyles } from '../../blocks.app/app/__menu/AppMenu.theme'

const scrollableMenuStyles: IStyles = {
    scrollableMenu: {
        position: 'relative',
        padding: '0 30px',
        width: '69.5%',
        [mediaCSS.desktopXL]: {
            width: '65.5%',

            [mediaCSS.desktopML]: {
                width: '48.5%',

                [mediaCSS.desktopLM]: {
                    width: '43%',

                    [mediaCSS.desktopXS]: {
                        width: '40%',
                    },
                },
            },
        },
    },
    borderStyles: {
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: '-11px',
            height: '65px',
            width: '1px',
            zIndex: 10000,
            background: `var(--var-module-app-component-menu-style-dividerBackground, ${colors})`,
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            right: 0,
            top: '-11px',
            height: '65px',
            width: '1px',
            background: `var(--var-module-app-component-menu-style-dividerBackground, ${colors})`,
            zIndex: 10000,
        },
    },
    arrow: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 10000,

        svg: {
            fill: `var(--var-module-app-component-menu-style-icon-color, ${defaultThemeStyles.arrow})`,
        },
    },
    arrowLeft: {
        left: '10px',
    },
    arrowRight: {
        right: '10px',
    },
    scrollArea: {
        overflowX: 'clip',
        overflowY: 'unset',
    },
}

addStyles(scrollableMenuStyles)

export default scrollableMenuStyles
