import React from 'react'
import { Radio } from 'atoms/Radio'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './deviceSettings__exhibitionMode.jcss'
import { Typography } from 'atoms/Typography'

const DeviceSettingsExhibitionMode = (p_: {
    enabled: boolean
    value: boolean
    className: string | object
    onChange: (value: any, field: string) => void
    type: string
}) => {
    if (!p_.enabled) {
        return null
    }

    function disableSyncContent() {
        p_.onChange(false, 'exhibitionModeEnabledQ')
    }

    function enableSyncContent() {
        p_.onChange(true, 'exhibitionModeEnabledQ')
    }

    return (
        <div className={cn(p_.className)}>
            <Typography className={cn(styles.title)} type="title">
                {translate('exhibitionMode')}
            </Typography>
            <div className={styles.radioButtons}>
                <Radio
                    label={translate('yes')}
                    selected={p_.value}
                    className={styles.radio}
                    onClick={enableSyncContent}
                />
                <Radio label={translate('no')} selected={!p_.value} onClick={disableSyncContent} />
            </div>
        </div>
    )
}

DeviceSettingsExhibitionMode.defaultProps = {
    value: false,
}

export default React.memo(DeviceSettingsExhibitionMode)
