import { FC } from 'react'
import { MapProps, SearchableMapProps } from '../models/map'
import { GoogleMap } from '../molecules/GoogleMap'
import { SearchableGoogleMap } from '../molecules/SearchableGoogleMap'
import { GoogleMapApi } from '../api/GoogleMapApi'
import { MapApi } from '../api/MapApi'
import { MapFactory } from './MapFactory'

export class GoogleMapFactory extends MapFactory {
    createSearchableMap(): FC<SearchableMapProps> {
        return SearchableGoogleMap
    }

    createMap(): FC<MapProps> {
        return GoogleMap
    }

    createMapApi(): MapApi {
        return new GoogleMapApi(this.apiKey)
    }
}
