import React, { FunctionComponent, useEffect } from 'react'
// TODO: в конце редизайна и рефактора хэдер перенести на уровень App, чтобы хэдер не перерендеривался на переходах по страницам
//import { ChannelAdd } from 'organisms/ChannelAdd'

import helpers from 'core/helpers'
import translate from 'core/translate'
import { connect, useDispatch } from 'react-redux'
import { routesKeys } from 'features/routes'
import { IContentComponent } from './Content-types'
import { cn } from 'ethcss'
import { contentStyles } from './Content-styles'
import { grid } from 'theme'
import { useLocation } from 'react-router'
import { headActions } from 'features/head/Head.state'
import { isHorizontalMenu } from 'core/helpers/menu'

const NoAccessOrNotFound: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => (
    <div className={contentStyles.messageWrapper}>{children}</div>
)

const ContentInner: FunctionComponent<IContentComponent> = ({
    children,
    title,
    minimize,
    contentClassName = contentStyles.content,
    contentWrapperClassName = contentStyles.contentWrapper,
    withToolbar,
    indent = 'p_none',
    user,
}) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const pathname = helpers.getPathname(location.pathname)
    const route = routesKeys[pathname]
    let contentElements = children
    const horizontalMenu = isHorizontalMenu()

    useEffect(() => {
        dispatch(headActions.setTitle([translate(title)]))
    }, [title])

    if (!route) {
        contentElements = <NoAccessOrNotFound>{children}</NoAccessOrNotFound>
    }

    if (!helpers.isAvailable({ key: route })) {
        contentElements = <NoAccessOrNotFound>{translate('noAccesses')}</NoAccessOrNotFound>
    }

    return (
        <div
            className={cn(
                contentStyles.wrapper,
                withToolbar && !horizontalMenu ? contentStyles.col2 : '',
                minimize
                    ? cn(contentStyles.leftIndentSm, contentStyles.col1Sm)
                    : cn(contentStyles.leftIndent, contentStyles.col1, {
                          [contentStyles.leftIndent_x]: horizontalMenu,
                          [contentStyles.col1_x]: horizontalMenu && !withToolbar,
                      })
            )}
        >
            <div
                id="contentScrollWrapper"
                className={cn(
                    contentWrapperClassName,
                    grid[indent],
                    horizontalMenu ? contentStyles.contentMarginTop : ''
                )}
            >
                <div className={contentClassName}>{contentElements}</div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    minimize: state.app.minimizeMenu,
    user: state.user.data,
})

export const Content = connect(mapStateToProps)(ContentInner)
