import { useState, useEffect } from 'react'
import { api } from 'core/api/ConnectionManager'
import { isNumber } from 'core/utils/typeUtil'
import { IBroadcast } from 'core/models/Broadcast'
import { IGetBroadcastByIdRequest } from './ScheduleItem-types'

export const useConfirmDelete = () => {
    let [isConfirmDelete, setConfirmDelete] = useState<boolean>(false)

    return {
        isConfirmDelete,
        setConfirmDelete,
    }
}

export const useGetBroadcastOnSelect = (id: number, selected: boolean) => {
    const [broadcast, setBroadcast] = useState<IBroadcast | null>(null)

    const getBroadcastById = (broadcastId: number) => {
        api.send<IGetBroadcastByIdRequest, IBroadcast>('getBroadcast', { id: broadcastId }, { hideLoader: true })
            .then((respBroadcast: IBroadcast) => {
                if (respBroadcast) {
                    setBroadcast(respBroadcast)
                }
            })
            .catch(() => {
                setBroadcast(null)
            })
    }

    useEffect(() => {
        if (isNumber(id) && selected) {
            getBroadcastById(id)
        }
    }, [id, selected])

    return {
        broadcast,
    }
}
