import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { defaultThemeStyles } from './editor.theme'
import { mediaCSS } from '../../theme'

const editorStyles = {
    wrapper: {
        ...grid.full,
        overflowX: 'hidden',
        //overflowY: 'scroll',
    },
    screenWrapper: {
        '&>div': {
            outline: 'none !important',
        },
    },
    header: {
        borderRadius: '5px 5px 0 0',
        backgroundColor: `var(--var-module-app-component-editor-style-backgroundSub, ${defaultThemeStyles.backgroundSub})`,

        [mediaCSS.tab]: {
            borderRadius: 0,
        },
    },
    /* cols */
    colsWrapper: {
        ...grid.row,
        ...grid.full,
        '&:focus': {
            outline: 'none',
        },
    },
    colsContent: {
        width: 0,
        ...grid.space,
        ...grid.fullHeight,
        padding: '0 5px 5px 5px',
        [mediaCSS.tab]: {
            padding: 0,
        },
    },
    mobile: {
        padding: '0 0 0 0 !important',
    },
    /* /cols */
}

addStyles(editorStyles)
export default editorStyles
