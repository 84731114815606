import { useEffect, useState } from 'react'
import { api } from 'core/api/ConnectionManager'
// @ts-ignore
import { addFont } from 'addstyles'
import { isNotEmptyArray } from 'core/utils/coreUtil'
import { uploadFonts } from 'pages/files/files.state'
import { useDispatch } from 'react-redux'

export type FontItem = {
    id: number
    uniqueName: string
    companyId: number
    name: string
    fileHash: string
    fileSize: number
    fileUrl: string
    updatedAt: string
    createdAt: string
}

export const SettingsFontsMethodsHook = () => {
    const [items, setItems] = useState<FontItem[]>([])
    const [selectedItems, setSelectedItems] = useState<FontItem[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [isRequest, setIsRequest] = useState<boolean>(false)
    const supportedFontTypes: string[] = ['.ttf', '.otf']
    const dispatch = useDispatch()

    const getCustomFonts = () => {
        api.send('getFonts', {}).then((res: any) => {
            setItems(res)
            setLoading(true)
        })
    }
    const onUploadFonts = (fontList: any): void => {
        if (!fontList || !isNotEmptyArray(fontList)) {
            setIsRequest(false)
            return
        }

        setItems([...items, ...fontList])
        setIsRequest(false)

        fontList.forEach((font: FontItem) => {
            addFont(font.fileUrl, `font-family: ${font.name}; font-weight: normal`)
        })
    }
    const onDrop = (files: any) => {
        setIsRequest(true)

        dispatch(
            uploadFonts({
                files,
                supportedFontTypes: supportedFontTypes,
                cb: onUploadFonts,
            })
        )
    }
    const deleteFonts = () => {
        const id = selectedItems.map((item: FontItem) => item.id)

        if (id.length) {
            api.send('deleteFont', { id }).then((res: any) => {
                setItems(items.filter((item: any) => !res.id.includes(item.id)))
                setSelectedItems(selectedItems.filter((item: any) => !res.id.includes(item.id)))
            })
        }
    }

    return {
        items,
        selectedItems,
        setSelectedItems,
        loading,
        isRequest,
        getCustomFonts,
        onDrop,
        deleteFonts,
        supportedFontTypes,
    }
}
