import { isNumber } from './typeUtil'
import { isFloat, isNotEmptyArray, isExist, isNotEmptyString } from './coreUtil'
import { IContent } from 'core/models/Content'
import { IAudioTrackItem } from 'organisms/AudioTrackList/AudioTrackItem/AudioTrackItem-types'
import { IPlaybackState } from 'core/models/PlaybackState'
import { IAudioPlaygroundSource } from 'organisms/AudioPlayground/AudioPlayground-types'
import { IBroadcast } from 'core/models/Broadcast'
import { findBroadcastPageContentById, findBroadcastSountrackContentById } from './broadcastUtil'

export const numberToString = (value: number): string => (isNumber(value) ? '' + value : '')

export const stringToNumber = (value: string): number => parseInt(value, 10)

export const stringToFloatNumber = (value: string): number => parseFloat(value)

export const roundFloatToInteger = (value: number): number => {
    return isFloat(value) ? Math.round(value) : value
}

export const convertBroadcastSoundtrackToAudioTrackList = (inputSountrack: IContent[]): IAudioTrackItem[] => {
    if (!isExist(inputSountrack) || !isNotEmptyArray(inputSountrack)) return []

    return inputSountrack.map((track: IContent) => {
        let id = track.id
        let duration = track?.source?.data?.duration
        let fileName = track?.source?.data?.fileName

        return {
            id,
            duration,
            title: fileName,
        }
    })
}

export const convertPlaybackStateMediaToAudioPlaygroundSourceList = (
    inputBroadcast: IBroadcast,
    inputPlaybackState: IPlaybackState
): IAudioPlaygroundSource[] => {
    if (!isExist(inputBroadcast) || !isExist(inputPlaybackState) || !isExist(!inputPlaybackState.media)) return []

    const DEFAULT_AREA_ID = -10000
    const DEFAULT_FILE_URL = ''
    const DEFAULT_FILE_NAME = ''

    return inputPlaybackState.media
        .map((audio) => {
            let findedContent =
                audio.playingIn === 'area'
                    ? findBroadcastPageContentById(inputBroadcast, audio.contentId)
                    : findBroadcastSountrackContentById(inputBroadcast, audio.contentId)

            let fileURL = DEFAULT_FILE_URL
            let uniqueName = DEFAULT_FILE_NAME
            let areaId = DEFAULT_AREA_ID

            if (findedContent) {
                fileURL = findedContent.content?.source?.data?.fileURL || DEFAULT_FILE_URL
                uniqueName = findedContent.content?.source?.data?.fileName || DEFAULT_FILE_NAME
                areaId = findedContent.areaId
            }

            return {
                ...audio,
                fileURL,
                uniqueName,
                areaId,
            }
        })
        .filter(({ fileURL, uniqueName, areaId }) => {
            return isNotEmptyString(fileURL) && isNotEmptyString(uniqueName) && areaId !== DEFAULT_AREA_ID
        })
}

export const updateURLProtocol = (link: string) => {
    const protocol = window.location.protocol
    const oppositeProtocol = protocol === 'https:' ? 'http:' : 'https:'

    if (!link.includes(protocol)) {
        return link.replace(oppositeProtocol, protocol)
    }

    return link
}
