import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'

const catalogTemplateBroadcastsStyles = {
    image: {
        ...grid.w30
    }
}

addStyles(catalogTemplateBroadcastsStyles)
export default catalogTemplateBroadcastsStyles
