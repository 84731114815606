export const pagesListToShort = (list: any[]) => {
    return list.map((element: { exhibitionPageId: number; title: string; selected: boolean }) => {
        return {
            id: element.exhibitionPageId,
            name: element.title,
            selected: false,
        }
    })
}

export const responseConverter = (response: { data: object }) => {
    if (!response) return

    return response.data
}
