import React, { useEffect, useRef, useState } from 'react'

import deepEqual from 'fast-deep-equal'
import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'
import helpersFiles from 'core/helpers/_files/index'

export interface IState {
    activeToggle: string
    folders: []
    keepProportions: boolean
    autoConvertMediaId: null | number
    activeProcess: number
}
export interface IVideoEditorSidebarMethodsProps {
    minimized: boolean
    media: { [index: string]: any }
    commands: { [index: string]: any }
    defaultCommands?: { [index: string]: any }
    onChangeCommands: (commands: any) => void
    selectedTasksSourceType: { [index: string]: any } | null
    toggleMinimized: () => void
    showSettings: string | null
    settings: { [index: string]: any }
    selectedTasks: []
    onSelectTask: (e: any) => void
    onChangeTask: (item: any, type: string) => void
    onChangeShowSettings: (show: null) => void
    onProcessMedia: () => void
    onChangeSettings: (name: string, fileName: string) => void
    onSelectTasks: (tasks: any) => void
    openMinimized: () => void
    sourceId: string
    videoEditorSidebarRef?: any
}
const VideoEditorSidebarMethods = (props: IVideoEditorSidebarMethodsProps) => {
    const [state, setState] = useState<IState>({
        activeToggle: props.minimized ? 'files' : 'tasks',
        folders: [],
        keepProportions: true,
        autoConvertMediaId: null,
        activeProcess: 0,
    })
    const s_ = { ...state }
    let prevProps = props
    let isLoaded: boolean = false
    const setActiveToggle = () => {
        setState((prevState) => {
            return {
                ...prevState,
                activeToggle: 'tasks',
                activeProcess: state.activeProcess + 1,
            }
        })
    }

    const usePrevious = (value: any) => {
        const ref = useRef()
        useEffect(() => {
            ref.current = value
        })
        return ref.current
    }

    const prevPropsMinimized = usePrevious(props.minimized)

    useEffect(() => {
        api.send('getAvailableToCreateParentSourceFolders', { nestedQ: false }, { hideLoader: true }).then(
            (availableFolders: any) => {
                const folders: any = helpers.addParentKeyIdForTree(availableFolders, 'folderId')
                setState((prevState: IState) => {
                    return {
                        ...prevState,
                        folders,
                    }
                })
            }
        )
    }, [])

    useEffect(() => {
        if (state.activeProcess > 0) {
            state.activeToggle === 'tasks' && props.onProcessMedia()
        }
        isLoaded = true
    }, [state.activeProcess, state.activeToggle])

    useEffect(() => {
        const p_ = props

        if (p_.media && !deepEqual(prevProps.media, p_.media)) {
            const availableMimeTypes = helpersFiles.availableVideoMimeTypes(p_.media.mimetype)

            if (s_.autoConvertMediaId !== p_.media.id) {
                if (availableMimeTypes) {
                    setState((prevState) => {
                        return {
                            ...prevState,
                            autoConvertMediaId: null,
                        }
                    })
                }

                const data: any = helpers.deepCopy(p_.commands)

                setState((prevState) => {
                    return {
                        ...prevState,
                        autoConvertMediaId: p_.media.id,
                    }
                })
                data.convertToMp4.active = true
                p_.onChangeCommands(data)
            }
        }

        if (!prevPropsMinimized && p_.minimized) {
            setState((prevState) => {
                return {
                    ...prevState,
                    activeToggle: 'files',
                }
            })
        }
        prevProps = p_
    }, [props])

    const setActiveSidebarTab = (value: string) => {
        setState((prevState: IState) => {
            return {
                ...prevState,
                activeToggle: value,
            }
        })
    }

    const calculateProportions = () => {
        const p_ = props
        const data: any = helpers.deepCopy(p_.commands)
        const proportionsData = {
            sourceWidth: p_.media.width,
            sourceHeight: p_.media.height,
            width: data.crop.width,
            height: data.crop.height,
        }
        const proportions = helpersFiles.calculateAspectRatioFit(proportionsData)

        data.crop.height = Math.round(proportions.height)
        data.crop.width = Math.round(proportions.width)

        p_.onChangeCommands(data)
    }
    const isButtonCheckedAndDisabled = (mimetype: string) => {
        return (
            mimetype === 'video/mp4' ||
            mimetype === 'video/ogg' ||
            mimetype === 'video/ogv' ||
            mimetype === 'application/x-troff-msvideo' ||
            mimetype === 'video/avi' ||
            mimetype === 'video/msvideo' ||
            mimetype === 'video/x-msvideo' ||
            mimetype === 'video/x-matroska' ||
            mimetype === 'video/quicktime'
        )
    }

    const onChangeCrop = (key: string, value: any) => {
        const p_ = props
        const s_ = { ...state }

        if (!value.match(/\d/g)) {
            value = 0
        }
        value = parseInt(value, 10)

        const data: any = helpers.deepCopy(p_.commands)

        if (!p_.media.multiple && s_.keepProportions) {
            const proportionsData = {
                sourceWidth: p_.media.width,
                sourceHeight: p_.media.height,
                width: p_.media.width,
                height: p_.media.height,
                [key]: value,
            }
            const proportions = helpersFiles.calculateAspectRatioFit(proportionsData)

            if (key === 'width') {
                data.crop.height = Math.round(proportions.height)
            } else {
                data.crop.width = Math.round(proportions.width)
            }
        }

        data.crop[key] = value
        p_.onChangeCommands(data)
    }
    const onChangeNumberField = (value: any, key: string, field: string) => {
        const p_ = props
        const data: any = helpers.deepCopy(p_.commands)

        if (!value.length) {
            data[key][field] = value
            p_.onChangeCommands(data)
            return
        }
        if (!value.match(/^\d+$/)) {
            return
        }
        value = parseInt(value, 10)

        data[key][field] = value
        p_.onChangeCommands(data)
    }
    /**
     * [If source type image/video was selected, disable multiple selected checkbox for other type. Accept only type, for example: image, video]
     * @method setDisabledMultiSelect
     * @param  {[object]}               source [instance of video editor task]
     */
    const setDisabledMultiSelect = (source: { [index: string]: any }) => {
        const { selectedTasksSourceType } = props

        if (selectedTasksSourceType) {
            return source.data.type !== selectedTasksSourceType
        }

        return false
    }
    return {
        state,
        setActiveSidebarTab,
        calculateProportions,
        isButtonCheckedAndDisabled,
        onChangeCrop,
        onChangeNumberField,
        setDisabledMultiSelect,
        setState,
        setActiveToggle,
    }
}

export default VideoEditorSidebarMethods
