import { addStyles } from 'ethcss'
import { IStyles } from '../../../../theme'

const catalogTemplateInstancesCatalogStyles: IStyles = {
    questionWrapper: {
        padding: '20px 0',
        width: '100%',
    },
    question: {
        display: 'flex',
        alignItems: 'center',
    },
    answer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '14px',
    },
    answerChoice: {
        display: 'flex',
        alignItems: 'center',
    },
    caption: {
        width: '64px',
        textAlign: 'right',
        paddingRight: '8px',
    },
    questionTextWrapper: {
        width: '506px',
    },
    answerTextWrapper: {
        width: '506px',
    },
    radio: {
        marginLeft: '30px',
    },
    saveButton: {
        margin: '20px 0 0 66px',
    },
}

addStyles(catalogTemplateInstancesCatalogStyles)
export default catalogTemplateInstancesCatalogStyles
