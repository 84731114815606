import React, { FC, useContext, useState } from 'react'
import Link from 'blocks.simple/link/link'
import Icon from 'blocks.simple/icon/icon'
import DndDroppable from 'blocks.app/dnd/_droppable/dnd_droppable'

import { cn } from 'ethcss'
import { styles } from './BreadcrumbsItem-styles'
import helpersCore from 'core/helpers'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from '../Breadcrumbs.theme'
import { getURLSearchParamsByLocation, getURLSearchParamsString } from 'features/routes/utils'
import { IBreadcrumbsItemProps } from './BreadcrumbsItem-types'
import { useLocation } from 'react-router'
import helpers from 'core/helpers'
import { getQueryWithoutSearchQuery } from 'core/utils/catalogUtil'
import { numberToString } from 'core/utils/convertUtil'
import { Location } from 'history'
import { CatalogWithPaginationContext } from 'organisms/CatalogWithPagination/CatalogWithPagination-context'
import { ICatalogItemData, ICatalogItem } from 'organisms/CatalogWithPagination/CatalogWithPagination-types'

export const BreadcrumbsItem: FC<IBreadcrumbsItemProps> = ({
    breadcrumb,
    isActive,
    isLastItem,
    isFirstItem,
    index,
}) => {
    const { common, catalogMethods } = useContext(CatalogWithPaginationContext)
    const { onMoveItem } = catalogMethods
    const { parentKey } = common
    const [isActiveDnD, setIsActiveDnD] = useState(false)

    function handleDrop(data: ICatalogItemData, e: React.MouseEvent) {
        e.stopPropagation()

        const droppedItem = getDroppedItem(data)

        if (!droppedItem) {
            setIsActiveDnD(false)
            return
        }

        if (isLastItem) {
            setIsActiveDnD(false)
            return
        }

        if (!breadcrumb.id) {
            setIsActiveDnD(false)
            return
        }

        onMoveItem(
            droppedItem,
            {
                type: 'breadcrumb',
                target: breadcrumb,
            },
            setBreadcrumbsItemHover
        )
    }

    function handleDragOver() {
        if (!isActiveDnD) {
            setIsActiveDnD(true)
        }
    }

    function handleDragLeave() {
        if (isActiveDnD) {
            setIsActiveDnD(false)
        }
    }

    const setBreadcrumbsItemHover = (isHover: boolean) => {
        setIsActiveDnD(isHover)
    }

    const getDroppedItem = (data: ICatalogItemData): ICatalogItem | null => {
        try {
            return JSON.parse(data.dnd_item)
        } catch (err) {
            return null
        }
    }

    const getLinkPath = (location: Location) => {
        const pathname = helpers.getPathname(location.pathname)
        const query = getURLSearchParamsByLocation(location)
        const updatedQuery = getQueryWithoutSearchQuery(query)
        const parentId = parentKey && breadcrumb.id ? numberToString(breadcrumb.id) : null

        return {
            pathname: `/${pathname}`,
            search: getURLSearchParamsString({
                ...updatedQuery,
                [parentKey as string]: parentId,
            }),
        }
    }

    const location = useLocation()
    const linkPath = getLinkPath(location)

    let breadcrumbName = helpersCore.getBreadcrumbsItemName(breadcrumb.name, index)

    return (
        <DndDroppable onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={handleDragLeave}>
            <Link
                className={cn(styles.BreadcrumbsItem, {
                    [styles.BreadcrumbsItem__hovered]: isActiveDnD && !isActive && breadcrumb.id,
                    [styles.BreadcrumbsItem__active]: isActive,
                })}
                to={linkPath}
            >
                <Icon
                    className={styles.BreadcrumbsItem__icon}
                    type={isFirstItem ? 'home' : 'group_files_v1'}
                    size={18}
                    color={
                        isActive
                            ? getThemeStyleValue('breadcrumbs', 'icon', 'active') || defaultThemeStyles.active
                            : getThemeStyleValue('breadcrumbs', 'icon', 'disable') || defaultThemeStyles.disable
                    }
                />
                <div className={styles.BreadcrumbsItem__text}>{breadcrumbName}</div>
            </Link>
        </DndDroppable>
    )
}
