import React, { FC } from 'react'
import { NetworkConverterEditProps } from './NetworkConverterEdit-types'
import { styles } from './NetworkConverterEdit-styles'
import { Typography } from 'atoms/Typography'
import translate from 'core/translate'
import Button from 'blocks.simple/button/button'
import EditText from 'blocks.simple/editText/editText'

export const NetworkConverterEdit: FC<NetworkConverterEditProps> = ({
    networkConverterInfo,
    onRestart,
    onChangePassword,
    onChangeSearchPin,
    searchPin,
    password,
}) => {
    const {
        status,
        macAddress,
        firmwareVersion,
        model,
        password: oldPassword,
        searchPin: oldSearchPin,
    } = networkConverterInfo

    const getInputLabel = (textKey: string) => {
        return (
            <Typography type="text" className={styles.NetworkConverterEdit__label}>
                {translate(textKey)}
            </Typography>
        )
    }

    return (
        <div className={styles.NetworkConverterEdit}>
            <div className={styles.NetworkConverterEdit__info}>
                <Typography type="title" className={styles.NetworkConverterEdit__title}>
                    {translate('information')}
                </Typography>
                <ul className={styles.NetworkConverterEdit__list}>
                    <li>
                        <Typography element="span" type="title">
                            {translate('status')}:
                        </Typography>
                        <Typography element="strong" type="title">
                            {status}
                        </Typography>
                    </li>
                    <li>
                        <Typography element="span" type="title">
                            {translate('macAddress')}:
                        </Typography>
                        <Typography element="strong" type="title">
                            {macAddress}
                        </Typography>
                    </li>
                    <li>
                        <Typography element="span" type="title">
                            {translate('firmwareVersion')}:
                        </Typography>
                        <Typography element="strong" type="title">
                            {firmwareVersion}
                        </Typography>
                    </li>
                    <li>
                        <Typography element="span" type="title">
                            {translate('model')}:
                        </Typography>
                        <Typography element="strong" type="title">
                            {model}
                        </Typography>
                    </li>
                </ul>
            </div>
            <div className={styles.NetworkConverterEdit__border}></div>
            <div className={styles.NetworkConverterEdit__form}>
                <Typography type="title" className={styles.NetworkConverterEdit__title}>
                    {translate('setPassword')}
                </Typography>
                <div className={styles.NetworkConverterEdit__field}>
                    <EditText
                        id={'TCPServerCode'}
                        autoComplete="new-password"
                        className={styles.NetworkConverterEdit__input}
                        label={getInputLabel('setTCPServerPassword')}
                        value={password}
                        onChange={onChangePassword}
                    />
                    <Typography className={styles.NetworkConverterEdit__info} type="text">
                        {translate('scoreboardsPasswordInfo')}
                    </Typography>
                </div>
                <div className={styles.NetworkConverterEdit__divider}></div>
                <div className={styles.NetworkConverterEdit__field}>
                    <EditText
                        id={'searchPin'}
                        autoComplete="new-password"
                        className={styles.NetworkConverterEdit__input}
                        label={getInputLabel('setSearchPin')}
                        value={searchPin}
                        onChange={onChangeSearchPin}
                    />
                    <Typography className={styles.NetworkConverterEdit__info} type="text">
                        {translate('scoreboardsPasswordInfo')}
                    </Typography>
                </div>
            </div>
            <div className={styles.NetworkConverterEdit__border}></div>
            <div className={styles.NetworkConverterEdit__actions}>
                <Typography type="title" className={styles.NetworkConverterEdit__title}>
                    {translate('actions')}
                </Typography>
                <Button onClick={onRestart} mod="withBorder">
                    {translate('restart')}
                </Button>
            </div>
        </div>
    )
}
