import { addStyles } from 'ethcss'
import { IStyles, colors } from '../../theme'

const monitorWindowStyles: IStyles = {
    monitorWindow: {
        position: 'fixed',
        width: 600,
        height: 400,
        background: colors.white,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        border: `1px solid ${colors.greyLight}`,
        borderRadius: '10px',
        overflow: 'hidden',
        zIndex: 2001,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${colors.greyLight}`,
        padding: 24,
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
    },
    action: {
        marginLeft: 20,
    },
    downloadLog: {
        padding: 7,
        backgroundColor: colors.greyLight,
        borderRadius: 3,
    },
    content: {
        position: 'relative',
        height: 'calc(100% - 64px)',
        overflow: 'auto',
        opacity: 1,
        transition: 'opacity 0.2s',
    },
    disabled: {
        opacity: 0.5,
        pointerEvents: 'none',
    },
    noDraggable: {
        opacity: 0.5,
        pointerEvents: 'none',
    },
    hidden: {
        opacity: 0,
    },
    resizer: {
        position: 'absolute',
    },
    resizer_r: {
        cursor: 'col-resize',
        height: '100%',
        right: 0,
        top: 0,
        width: '5px',
    },
    resizer_t: {
        cursor: 'row-resize',
        height: '5px',
        left: 0,
        top: 0,
        width: '100%',
    },
    resizer_b: {
        cursor: 'row-resize',
        height: '5px',
        left: 0,
        bottom: 0,
        width: '100%',
    },
    resizer_l: {
        cursor: 'col-resize',
        height: '100%',
        left: 0,
        top: 0,
        width: '5px',
    },
    title: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    menuItem__text: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    scroll: {
        maxHeight: '100%',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
}

addStyles(monitorWindowStyles)
export default monitorWindowStyles
