import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { indents } from 'blocks.app/config'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'

const filesFilterStyles = {
    wrapper: {
        ...grid.w100,
        ...grid.mt_md,
    },
    content: {
        ...grid.row,
        ...item.rounded_top_mini,
        ...item.white,
        borderRadius: '5px 5px 0 0',
        overflow: 'hidden',
    },
    item: {
        ...grid.space,
        ...grid.rowCenter,
        ...grid.noWrap,
        ...grid.p_mini,
        ...item.pointer,
        ...item.white,
        textDecoration: 'none',
        color: `var(--var-module-app-component-app-style-textSub, ${defaultThemeStyles.textSub})`,
        border: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
        borderLeft: 0,
        '&:last-child': {
            borderRight: 'none',
        },
    },
    itemActive: {
        color: `var(--var-module-app-component-app-style-active, ${defaultThemeStyles.active})`,
        ...item.white,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    activateEditorButton: {
        ...grid.pt_mdPlus,
        ...grid.rowCenter,
        ...grid.pb_mdPlus,
        ...item.white,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    icon: {
        marginRight: indents.micro,
    },
    editors: {
        ...grid.space,
        ...item.overAuto,
        ...item.rel,
    },
}

addStyles(filesFilterStyles)
export default filesFilterStyles
