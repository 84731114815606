import { colors } from 'theme'

export const defaultThemeStyles = {
    'default-background': colors.white,
    'default-activeBackground': colors.white,
    'default-border': colors.blackLight,
    'default-activeBorder': colors.blackLight,
    'default-textMain': colors.blackLight,
    'default-activeTextMain': colors.blackLight,
    'default-textSub': colors.grey,
    'default-activeTextSub': colors.blackLight,
    'default-icon': colors.blackLight,
    'default-activeIcon': colors.blackLight,
    'default-shadow': 'none',
    'withShadow-background': colors.white,
    'withShadow-activeBackground': colors.white,
    'withShadow-border': 'transparent',
    'withShadow-activeBorder': 'transparent',
    'withShadow-textMain': colors.blackLight,
    'withShadow-activeTextMain': colors.blackLight,
    'withShadow-textSub': colors.grey,
    'withShadow-activeTextSub': colors.blackLight,
    'withShadow-icon': colors.grey,
    'withShadow-activeIcon': colors.blackLight,
    'withShadow-shadow': `2px 3px 4px 0px rgba(0, 0, 0, 0.25)`,
    'none-background': colors.white,
    'none-activeBackground': colors.white,
    'none-border': 'transparent',
    'none-activeBorder': 'transparent',
    'none-textMain': colors.blackLight,
    'none-activeTextMain': colors.blackLight,
    'none-textSub': colors.grey,
    'none-activeTextSub': colors.blackLight,
    'none-icon': colors.blackLight,
    'none-activeIcon': colors.blackLight,
    'none-shadow': 'none',
    'fill-background': colors.grey,
    'fill-activeBackground': colors.grey,
    'fill-border': colors.grey,
    'fill-activeBorder': colors.grey,
    'fill-textMain': colors.white,
    'fill-activeTextMain': colors.white,
    'fill-textSub': colors.white,
    'fill-activeTextSub': colors.white,
    'fill-icon': colors.white,
    'fill-activeIcon': colors.white,
    'fill-shadow': 'none',
}
