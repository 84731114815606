import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'
import { useOnMount } from 'core/hooks'
import translate from 'core/translate'
import { generate as generator } from 'generate-password'

import styles from './styles'
import RegularUser from './RegularUser'
import AdUser from './AdUser'
import Button from 'blocks.simple/button/button'
import { cn } from 'ethcss'

const ProfileForm = (p_) => {
    const [roles, setRoles] = useState([])

    useOnMount(() => {
        api.send('getRoles', {}).then(({ data }) => {
            const rolesOptions = data.map((role) => ({ value: role.id, label: role.label, name: role.name }))
            const translatedRoles = rolesOptions.map((role) => helpers.translateRoleLabel(role))
            setRoles(translatedRoles)
        })
    })

    const onChange = (value, field) => {
        const newData = {
            ...p_.data,
            [field]: value,
        }

        p_.onChange(newData)
    }

    const onChangeDropdown = (selected, idx) => {
        const roles = p_.data.roles.map((role, index) => {
            if (idx === index) {
                return selected
            }

            return role
        })

        p_.onChange({
            ...p_.data,
            roles,
        })
    }

    const onAddNewRole = () => {
        p_.onChange({
            ...p_.data,
            roles: [...p_.data.roles, null],
        })
    }

    const onDeleteRole = (idx) => {
        p_.onChange({
            ...p_.data,
            roles: p_.data.roles.filter((_, index) => index !== idx),
        })
    }

    const handleGeneratePassword = () => {
        const generatedPassword = generator({
            length: 12,
            numbers: true,
            symbols: false,
            exclude: '|/\\`\',."!',
        })

        onChange(generatedPassword, 'password')
    }

    return (
        <div className={styles.profileForm}>
            <div className={styles.profileFormContent}>
                <div className={styles.profileFormTitleWrapper}>
                    <div className={styles.profileFormTitle}>
                        {p_.isEdit ? translate('userChange') : translate('userCreation')}
                    </div>
                    {p_.isActiveDirectory && <div className={styles.profileFormTitleType}>Active directory</div>}
                </div>
                {p_.isActiveDirectory ? (
                    <AdUser
                        data={p_.data}
                        isEdit={p_.isEdit}
                        owner={p_.owner}
                        onChange={onChange}
                        roles={roles}
                        onChangeDropdown={onChangeDropdown}
                        onAddNewRole={onAddNewRole}
                        onDeleteRole={onDeleteRole}
                        findAdUser={p_.findAdUser}
                    />
                ) : (
                    <RegularUser
                        data={p_.data}
                        isEdit={p_.isEdit}
                        owner={p_.owner}
                        onChange={onChange}
                        roles={roles}
                        onChangeDropdown={onChangeDropdown}
                        onAddNewRole={onAddNewRole}
                        onDeleteRole={onDeleteRole}
                        handleGeneratePassword={handleGeneratePassword}
                    />
                )}
                <div className={styles.profileFormButtons}>
                    <div className={cn(styles.profileFormButtonWrapper, styles.profileFormRejectButton)}>
                        <Button mod={'withBorder'} onClick={p_.onReject} className={styles.profileFormButtonButton}>
                            {translate('close')}
                        </Button>
                    </div>
                    <div className={cn(styles.profileFormButtonWrapper, styles.profileFormRejectButton)}>
                        <Button mod={'fill'} className={styles.profileFormButtonButton} onClick={p_.onAccept}>
                            {p_.isEdit ? translate('update') : translate('save')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

ProfileForm.propTypes = {
    data: PropTypes.object,
    owner: PropTypes.bool,
    isEdit: PropTypes.bool,
    onChange: PropTypes.func,
    isActiveDirectory: PropTypes.bool,
    findAdUser: PropTypes.func,
    onReject: PropTypes.func,
    onAccept: PropTypes.func,
}

export default ProfileForm
