import { createSlice } from '@reduxjs/toolkit'

const broadcastsSlice = createSlice({
    name: 'broadcasts',
    initialState: { isOpenAddBroadcastFile: false },
    reducers: {
        openAddFileModal(state, action) {
            state.isOpenAddBroadcastFile = action.payload
        },
    },
})

const { reducer: broadcastsReducer, actions: broadcastsActions } = broadcastsSlice
export { broadcastsReducer, broadcastsActions }
