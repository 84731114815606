import React from 'react'
import { Radio } from 'atoms/Radio'
import Collapse from 'blocks.simple/collapse/collapse'
import helpers from 'core/helpers'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { grid } from '../../../theme'
import { useDispatch } from 'react-redux'
import { updateUserSettings } from 'blocks.app/user/user.state'
import styles from '../settings.jcss'
const themes = ['light', 'dark']

export type LSSettings = { theme: string; mainMenuTheme: string }
interface IMainMenuTheme {
    mainMenuTheme: string
}

const SettingsMainMenuTheme = ({ mainMenuTheme }: IMainMenuTheme) => {
    const dispatch = useDispatch()

    function changeTheme(mainMenuTheme: string) {
        dispatch(updateUserSettings({ data: { mainMenuTheme }, isSaveSettings: true }))

        let settings: LSSettings | {} = {}

        try {
            const items: string | null = localStorage.getItem('sm-settings')

            if (items) {
                settings = JSON.parse(items)
            }
        } catch (err) {}

        const data = {
            ...settings,
            mainMenuTheme,
        }

        localStorage.setItem('sm-settings', JSON.stringify(data))
    }

    return (
        <Collapse title={() => translate('mainMenuTheme')} className={cn(styles.background, grid.mb_md)}>
            <div className={cn(grid.row)}>
                {themes.map((item: string) => {
                    let label = translate(`${item}Theme`)

                    return (
                        <div key={item} className={cn(grid.colCenter, grid.mr_mini)}>
                            <Radio
                                className={cn(grid.mb_micro)}
                                selected={mainMenuTheme === item}
                                label={label}
                                onClick={() => changeTheme(item)}
                                disabled={!helpers.isAvailable({ key: 'settings', action: 'update' })}
                            />
                        </div>
                    )
                })}
            </div>
        </Collapse>
    )
}

export default SettingsMainMenuTheme
