import { addStyles } from 'ethcss'
import { grid, item, text } from 'theme'
import { colors } from 'theme'

import { hexToRgba } from 'core/utils'

const contentEditableStyles = {
    wrapper: {
        ...grid.row,
        ...grid.normalCenter,
    },
    focused() {
        return {
            [`& .${this.iconWrapper}`]: {
                ...item.cursorDefault,
                opacity: 0,
                touchEvents: 'none',
            },
        }
    },
    editable: {
        outline: 'none',
        minWidth: 50,
    },
    iconWrapper: {
        ...item.pointer,
        ...item.transitionErase,
        opacity: 1,
    },
    icon: {
        ...grid.ml_md,
    },
    element: {
        ...text.darkBlue,
        ...text.mdPlus,
        ...text.bold,
    },
    iconRounded: {
        ...item.transitionErase,
        ...item.circle,
        ...grid.p_mini,
    },
    ...(() => {
        let hoverBoxShadows = {}

        for (let color in colors) {
            if (colors.hasOwnProperty(color)) {
                const rgba = hexToRgba(colors[color], 0.05)

                hoverBoxShadows[`${color}Hover`] = {
                    '&:hover': {
                        boxShadow: `inset 0 0 0 10em ${rgba}`,
                    },
                }
            }
        }

        return hoverBoxShadows
    })(),
}

addStyles(contentEditableStyles)
export default contentEditableStyles
