import { addStyles } from 'ethcss'
import { grid, IStyles } from '../../../../theme'
import { textSizes } from 'theme/media'

const advertisingCampaignsAddEmptyStyles: IStyles = {
    wrapper: {
        ...grid.space,
        ...grid.col,
        ...grid.colCenter,
        ...grid.full,
    },
    content: {
        ...grid.space,
        ...grid.colCenter,
        ...grid.w40,
    },
    title: {
        textTransform: 'uppercase',
        fontSize: textSizes.normalPlus,
    },
    description: {
        textAlign: 'center',
        ...grid.mb_mdPlus,
    },
    icon: {
        ...grid.mt_mdPlus,
        ...grid.mb_mdPlus,
    },
}

addStyles(advertisingCampaignsAddEmptyStyles)
export default advertisingCampaignsAddEmptyStyles
