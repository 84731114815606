import { getURLSearchParamsByLocation } from 'features/routes/utils'
import React from 'react'

class InstancesCatalogAskonaMethods extends React.Component {
    state = {
        selectedItems: [],
        category: 'slides',
    }

    componentDidMount() {
        const p_ = this.props
        const s_ = this.state
        const query = getURLSearchParamsByLocation(p_.location)
        const category = query.category
        this.changeCategory(s_.category, category)
    }

    shouldComponentUpdate(p_) {
        const s_ = this.state
        const query = getURLSearchParamsByLocation(p_.location)
        const category = query.category
        this.changeCategory(s_.category, category)

        return true
    }

    changeCategory = (prevCategory, nextCategory) => {
        if (nextCategory && nextCategory !== prevCategory) {
            this.setState({
                category: nextCategory,
                selectedItems: [],
            })
        }
    }
}

export default InstancesCatalogAskonaMethods
