import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../../../theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { textSizes } from 'theme/media'

const advertisingCampaignsAddSummaryStyles: IStyles = {
    row: {
        ...grid.row,
        ...grid.noWrap,
        ...grid.mt_md,
        ...grid.mb_md,
    },
    title: {
        fontWeight: 'bold',
    },
    description: {
        ...grid.w70,
    },
    bigTitle: {
        ...grid.pb_mini,
        ...grid.pt_mini,
        textAlign: 'center',
        fontSize: textSizes.normalPlus,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles['border']})`,
    },
    empty: {
        ...grid.space,
        ...grid.colCenter,
        ...grid.p_md,
    },
    emptyText: {
        ...grid.mt_md,
        fontSize: textSizes.normal,
        textTransform: 'uppercase',
    },
    conflictsTable: {
        ...item.tableWrapper,
        marginTop: '14px',
        '& td': {
            ...grid.pt_mini,
            ...grid.pb_mini,
        },
    },
}

addStyles(advertisingCampaignsAddSummaryStyles)
export default advertisingCampaignsAddSummaryStyles
