import React from 'react'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import { cn } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { text as textStyles } from 'theme'
import styles from '../ui.jcss'

const mods = ['circle', 'circleInv', 'white']

const setDefaultState = () => {
    const state = {}

    mods.forEach((mod) => {
        state[mod] = false
        state[`${mod}_animate`] = false
    })

    return state
}

class UiCheckbox extends React.Component {
    state = setDefaultState()
    render() {
        const s_ = this.state
        return (
            <div className={styles.blockPanel}>
                <div className={styles.title}>Чекбоксы</div>
                <div className={styles.content}>
                    <div className={cn(grid.row, grid.p_md, item.greyLight, grid.mr_md)}>
                        {mods.map((mod) => (
                            <div className={grid.mr_md} key={`default_checkbox_${mod}`}>
                                <Checkbox
                                    onClick={(status) => this.setState({ [mod]: status })}
                                    mod={mod}
                                    style={{ margin: '0 auto' }}
                                    checked={s_[mod]}
                                />
                                <div className={cn(textStyles.bold, grid.mt_mini)}>{mod}</div>
                            </div>
                        ))}
                    </div>
                    <div className={cn(grid.p_md, item.greyLight, grid.mr_md)}>
                        <div className={cn(textStyles.bold, grid.mb_mini)}>Без анимации</div>
                        <div className={grid.row}>
                            {mods.map((mod) => (
                                <div className={grid.mr_md} key={`withoutAnimate_checkbox_${mod}`}>
                                    <Checkbox
                                        onClick={(status) => this.setState({ [`${mod}_animate`]: status })}
                                        mod={mod}
                                        style={{ margin: '0 auto' }}
                                        checked={s_[`${mod}_animate`]}
                                        animate={false}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={cn(grid.p_md, item.greyLight, grid.mr_md)}>
                        <div className={cn(textStyles.bold, grid.mb_mini)}>disabled</div>
                        <div className={grid.row}>
                            {mods.map((mod) => (
                                <div className={grid.mr_md} key={`disabled_checkbox_${mod}`}>
                                    <Checkbox
                                        onClick={() => {}}
                                        mod={mod}
                                        style={{ margin: '0 auto' }}
                                        checked={false}
                                        animate={false}
                                        disabled={true}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UiCheckbox
