import React from 'react'
import { api } from 'core/api/ConnectionManager'
import moment from 'moment'
import translate from 'core/translate'
import styles from './catalog__template_arts.jcss'
import helpers from 'core/helpers'
import {
    authorsListToShort,
    typesListToShort,
    directionsListToShort,
    getSourcesIds,
    getMediaIds,
    getAuthorsIds,
} from './catalog__template_arts.local.methods'
import { CatalogActionBar } from 'organisms/Catalog/ActionBar/ActionBar-view'
import { ActionBarRequestHeaderType, ActionBarActionType } from 'core/models/ActionBar'
import EditableText from 'blocks.simple/editableText/editableText'
import CatalogSelectContent from '../../../_select/_content/catalog_select_content'
import Button from 'blocks.simple/button/button'
import grid from 'blocks.simple/grid/grid.jcss'
import { cn } from 'ethcss'
import { DatePickerSingle } from 'blocks.simple/datepickerSingle/datepickerSingle'
import { MultiSelectFromListModal } from 'molecules/MultiSelectFromListModal'
import { SourcesToAuthorsFromListModal } from '../../../../../pages/instancesCatalog/_catalogizator/SourcesToAuthorsFromListModal'
import { TextEditor } from 'molecules/TextEditor'
import { IActionBarComponent } from '../../../../../organisms/Catalog/ActionBar/ActionBar-types'
import { ItemData } from '../../../../../core/models/Template'
import { IAuthorProps } from '../authors'

const toolbar = {
    options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
}

const getActionList = (p_: any) => {
    return [
        {
            order: 1,
            type: ActionBarActionType.ADD,
            cb: () => {
                p_.onAddFakeLine({ name: '' })
            },
        },
        {
            order: 2,
            type: ActionBarActionType.DELETE,
            cb: () => {
                const artIds = p_.selectedItems.map((selectedItem: { artId: number }) => selectedItem.artId)

                if (artIds.length > 0) {
                    api.send('deleteArts', artIds).then(() => window.location.reload())
                }

                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            },
        },
    ]
}

export default {
    getMethod: 'getArtsList',
    customParams: {
        limit: 35,
    },
    trNoClick: true,
    disableDrag: true,
    emptySearchText: () => translate('notFound'),
    emptyDescription: () => translate('createFirstPosition'),
    emptyFolderText: () => translate('listIsEmpty'),
    emptyIcon: 'not_allow',
    addItemButton: () => null,
    settingsPrefixes: 'arts',
    sortOptions: () => [],
    headerFilters: (p_: IActionBarComponent) => {
        return (
            <CatalogActionBar
                title={translate('artsList')}
                requestHeaderType={ActionBarRequestHeaderType.WITH_AUTH}
                type="artsList"
                actions={getActionList(p_)}
                {...p_}
            />
        )
    },
    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean) => {
        const item: any = { ...dataItem }

        if (item.media && !item.media.__view) {
            item.media.__view = {
                selected: getSelected(item),
            }
        }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    getBackendModel: (item: ItemData) => {
        return api.send('getArtsList', { query: item.name }, { hideLoader: true })
    },

    tableWrapper: styles.tableWrapper,

    isEqual: (firstItem: ItemData, secondItem: ItemData) => {
        if (firstItem.artId && secondItem.artId) {
            return firstItem.artId === secondItem.artId
        }

        return firstItem === secondItem
    },

    onCreateListeners: [
        // (callback, clearList) => {
        //     return api.addObserver(
        //         'artCreated',
        //         (artCreated) => {
        //             callback({ ...artCreated })
        //         },
        //         clearList
        //     )
        // },
    ],
    onUpdateListeners: [
        (callback: any, clearList: string[]) => {
            return api.addObserver(
                'artUpdated',
                (artUpdated: ItemData) => {
                    callback({ ...artUpdated })
                },
                clearList
            )
        },
    ],
    onDeleteListeners: [
        // (callback, clearList) =>
        //     api.addObserver(
        //         'artsDeleted',
        //         (deletedArts) => {
        //             deletedArts.forEach((artId) => {
        //                 callback({ artId })
        //             })
        //         },
        //         clearList
        //     ),
    ],
    isFolder: () => {},
    fields: () => [
        { id: 'artName', name: translate('artName') },
        { id: 'authors', name: translate('authors') },
        { id: 'creationDate', name: translate('creationDate') },
        { id: 'type', name: translate('type') },
        { id: 'direction', name: translate('direction') },
        { id: 'cover', name: translate('cover') },
        { id: 'media', name: translate('media') },
        { id: 'sourcesToAuthors', name: translate('sourcesToAuthors') },
        { id: 'description', name: translate('instanceDescription') },
    ],
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        artName: (p_: ItemData, active: boolean, listProps: { onAddFakeLine: any; fakeLine: ItemData }) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.name })}>
                <div className={cn({ [grid.fullWidth]: p_.name, [grid.space]: !p_.name })}>
                    <EditableText
                        id={p_.artId}
                        placeholder={translate('enterTitle')}
                        text={p_.name}
                        onChange={(name: string) => {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, name })
                        }}
                        onUpdate={(name, cb) => {
                            api.send('updateArt', {
                                artId: p_.artId,
                                name,
                            }).then(cb)
                        }}
                    />
                </div>
            </div>
        ),
        authors: (p_: ItemData, active: boolean, listProps: { onAddFakeLine: any; fakeLine: ItemData }) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.authors })}>
                <div className={cn({ [grid.fullWidth]: p_.authors, [grid.space]: !p_.authors })}>
                    <MultiSelectFromListModal
                        id={p_.artId}
                        mode={'useApi'}
                        apiParams={{ method: 'getAuthorsNames', params: { limit: 10000 } }}
                        placeholder={
                            helpers.isListExist(p_.authors)
                                ? `${translate('selected')} (${p_.authors.length})`
                                : translate('chooseAuthors')
                        }
                        callbackList={authorsListToShort}
                        selectedList={p_.authors ? authorsListToShort(p_.authors) : []}
                        initList={[]}
                        onSelect={(authors: IAuthorProps[], cb: any) => {
                            if (!authors.length) {
                                return
                            }

                            if (!p_.artId) {
                                listProps.onAddFakeLine({ ...listProps.fakeLine, authors })
                                return
                            }

                            api.send('updateArt', {
                                artId: p_.artId,
                                authorIds: authors,
                            }).then(cb)
                        }}
                        openStatus={false}
                        header={() => null}
                        onCancel={() => {}}
                    />
                </div>
            </div>
        ),
        creationDate: (p_: ItemData, active: boolean, listProps: { onAddFakeLine: any; fakeLine: ItemData }) => (
            <div className={cn(grid.fullWidth)}>
                <DatePickerSingle
                    id={p_.artId}
                    placeholder={translate('chooseDate')}
                    value={p_.creationDate ? moment(p_.creationDate).format() : ''}
                    dateFormat={'dd-MM-yyyy'}
                    onChangeFormat={'YYYY-MM-DD'}
                    onChange={(creationDate, cb) => {
                        if (!p_.artId) {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, creationDate })
                            return
                        }

                        api.send('updateArt', {
                            artId: p_.artId,
                            creationDate,
                        }).then(cb)
                    }}
                />
            </div>
        ),
        type: (p_: ItemData, active: boolean, listProps: { onAddFakeLine: any; fakeLine: ItemData }) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.typeIds })}>
                <div className={cn({ [grid.fullWidth]: p_.typeIds, [grid.space]: !p_.typeIds })}>
                    <MultiSelectFromListModal
                        id={p_.artId}
                        placeholder={
                            helpers.isListExist(p_.types)
                                ? `${translate('selected')} (${p_.types.length})`
                                : translate('chooseType')
                        }
                        selectedList={p_.types ? typesListToShort(p_.types) : []}
                        initList={[
                            {
                                id: 1,
                                name: translate('text'),
                                selected: false,
                            },
                            {
                                id: 2,
                                name: translate('audio'),
                                selected: false,
                            },
                            {
                                id: 3,
                                name: translate('video'),
                                selected: false,
                            },
                            {
                                id: 4,
                                name: translate('picture'),
                                selected: false,
                            },
                        ]}
                        onSelect={(types: { id: number; name: string; selected: boolean }[], cb: any) => {
                            if (!types.length) {
                                return
                            }

                            if (!p_.artId) {
                                listProps.onAddFakeLine({ ...listProps.fakeLine, types })
                                return
                            }

                            api.send('updateArt', {
                                artId: p_.artId,
                                typeIds: types,
                            }).then(cb)
                        }}
                        openStatus={false}
                        apiParams={null}
                        header={() => null}
                        onCancel={() => {}}
                        mode={null}
                        callbackList={() => {}}
                    />
                </div>
            </div>
        ),
        direction: (p_: ItemData, active: boolean, listProps: { onAddFakeLine: any; fakeLine: ItemData }) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.directions })}>
                <div className={cn({ [grid.fullWidth]: p_.directions, [grid.space]: !p_.directions })}>
                    <MultiSelectFromListModal
                        id={p_.artId}
                        placeholder={
                            helpers.isListExist(p_.directions)
                                ? `${translate('selected')} (${p_.directions.length})`
                                : translate('chooseDirection')
                        }
                        selectedList={p_.directions ? directionsListToShort(p_.directions) : []}
                        initList={[
                            {
                                id: 1,
                                name: translate('literature'),
                                selected: false,
                            },
                            {
                                id: 2,
                                name: translate('design'),
                                selected: false,
                            },
                            {
                                id: 3,
                                name: translate('architecture'),
                                selected: false,
                            },
                            {
                                id: 4,
                                name: translate('performing'),
                                selected: false,
                            },
                        ]}
                        onSelect={(directions: string[], cb: any) => {
                            if (!directions.length) {
                                return
                            }

                            if (!p_.artId) {
                                listProps.onAddFakeLine({ ...listProps.fakeLine, directions })
                                return
                            }

                            api.send('updateArt', {
                                artId: p_.artId,
                                directionIds: directions,
                            }).then(cb)
                        }}
                        openStatus={false}
                        apiParams={null}
                        header={() => null}
                        onCancel={() => {}}
                        mode={null}
                        callbackList={() => {}}
                    />
                </div>
            </div>
        ),
        cover: (p_: ItemData, active: boolean, listProps: { onAddFakeLine: any; fakeLine: ItemData }) => (
            <div>
                <CatalogSelectContent
                    id={p_.artId}
                    type={'image'}
                    selectedInfo={
                        p_.mainImage
                            ? {
                                  ...p_.mainImage,
                                  __view: {
                                      selected: false,
                                  },
                              }
                            : null
                    }
                    onSelect={(selectedMedia: { id: number; content: any; extension: string }) => {
                        if (p_.artId) {
                            api.send('updateArt', {
                                artId: p_.artId,
                                mainImageSourceId: selectedMedia.id,
                            })
                        } else {
                            getSourcesIds(selectedMedia).then(() => {
                                listProps.onAddFakeLine({
                                    ...listProps.fakeLine,
                                    mainImageSourceId: selectedMedia.id,
                                })
                            })
                        }
                    }}
                />
            </div>
        ),
        media: (p_: ItemData, active: boolean, listProps: { onAddFakeLine: any; fakeLine: ItemData }) => (
            <div>
                <CatalogSelectContent
                    id={p_.artId}
                    type={'mediaAndFolder'}
                    selectedInfo={
                        helpers.isListExist(p_.sources)
                            ? {
                                  ...p_.sources[0],
                                  __view: {
                                      selected: false,
                                  },
                              }
                            : null
                    }
                    onSelect={(selectedMedia: { id: number; content: any; extension: string }) => {
                        if (p_.artId) {
                            getSourcesIds(selectedMedia).then((res) => {
                                api.send('updateArt', {
                                    artId: p_.artId,
                                    sourceIds: res,
                                })
                            })
                        } else {
                            getSourcesIds(selectedMedia).then((res) => {
                                listProps.onAddFakeLine({
                                    ...listProps.fakeLine,
                                    media: {
                                        sourceIds: res,
                                        id: selectedMedia,
                                    },
                                })
                            })
                        }
                    }}
                />
            </div>
        ),
        sourcesToAuthors: (p_: ItemData, active: boolean, listProps: { onAddFakeLine: any; fakeLine: ItemData }) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.authorToSource })}>
                <div className={cn({ [grid.fullWidth]: p_.authorToSource, [grid.space]: !p_.authorToSource })}>
                    <SourcesToAuthorsFromListModal
                        id={p_.artId}
                        placeholder={
                            helpers.isListExist(p_.authorToSource)
                                ? `${translate('linked')} (${p_.authorToSource.length})`
                                : translate('linkArts')
                        }
                        authorsInProject={p_.authors ? authorsListToShort(p_.authors) : []}
                        authorsToSource={p_.authorToSource ? p_.authorToSource : []}
                        selectedList={p_.sources ? p_.sources : []}
                        sourceList={p_.sources ? p_.sources : []}
                        fakeLine={!p_.artId ? listProps.fakeLine : null}
                        authorsList={p_.artId ? p_.authors : null}
                        callbackList={authorsListToShort}
                        onSelect={(authorToSource: any[], cb: any) => {
                            if (!authorToSource.length) {
                                return
                            }

                            if (!p_.artId) {
                                listProps.onAddFakeLine({ ...listProps.fakeLine, authorToSource })
                                return
                            }

                            api.send('updateArt', {
                                artId: p_.artId,
                                authorToSource: authorToSource,
                                sourceIds: getMediaIds(p_.sources),
                                authorIds: getAuthorsIds(p_.authors),
                            }).then(cb)
                        }}
                    />
                </div>
            </div>
        ),
        description: (p_: ItemData, active: boolean, listProps: { onAddFakeLine: any; fakeLine: ItemData }) => (
            <div className={cn(grid.fullWidth)}>
                <TextEditor
                    id={p_.artId}
                    placeholder={translate('enterDescription')}
                    text={p_.description ? p_.description : ''}
                    previewStyles={styles.preview}
                    toolbar={toolbar}
                    onChange={(description: string, cb: any) => {
                        if (!p_.artId) {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, description })
                            return
                        }

                        api.send('updateArt', {
                            artId: p_.artId,
                            description,
                        }).then(cb)
                    }}
                />
                {!p_.artId && (
                    <Button
                        mod={'fill'}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            api.send('createArt', {
                                authorIds: listProps.fakeLine.authors ? listProps.fakeLine.authors : null,
                                name: listProps.fakeLine.name,
                                creationDate: listProps.fakeLine.creationDate ? listProps.fakeLine.creationDate : null,
                                typeIds: listProps.fakeLine.types ? listProps.fakeLine.types : null,
                                directionIds: listProps.fakeLine.directions ? listProps.fakeLine.directions : null,
                                description: listProps.fakeLine.description ? listProps.fakeLine.description : null,
                                sourceIds: listProps.fakeLine.media ? listProps.fakeLine.media.sourceIds : null,
                                mainImageSourceId: listProps.fakeLine.mainImageSourceId
                                    ? listProps.fakeLine.mainImageSourceId
                                    : null,
                                endDate: listProps.fakeLine.creationDate ? listProps.fakeLine.creationDate : null,
                                authorToSource: listProps.fakeLine.authorToSource
                                    ? listProps.fakeLine.authorToSource
                                    : [],
                            }).then(() => window.location.reload())
                        }}
                        disabled={
                            !listProps.fakeLine.name ||
                            !listProps.fakeLine.authors ||
                            !listProps.fakeLine.creationDate ||
                            !listProps.fakeLine.types ||
                            !listProps.fakeLine.directions ||
                            !listProps.fakeLine.media ||
                            !listProps.fakeLine.authorToSource
                        }
                    >
                        {translate('save')}
                    </Button>
                )}
            </div>
        ),
    },
    hideActiveRowColor: true,
    disableUpdateUserSettings: true,
    disableListScroll: true,
    fixedColWidth: false,
    draggable: false,
    templateCellsWidth: {
        description: '30%',
    },
    dndDblClickDisable: true,
}
