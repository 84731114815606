import translate from '../../core/translate'
import Content from '../../blocks.app/content/content'
import React from 'react'
import { Widget } from './widget/Widget'
import styles from './widgetsGallery.jcss'
import { DownloadWidget } from './downloadWidget/DownloadWidget'
import { WidgetsGalleryHooks } from './WidgetsGalleryHooks'
import { WidgetInfo } from './WidgetInfo/WidgetInfo'
import Icon from '../../blocks.simple/icon/icon'
import { colors } from '../../theme'

interface IWidgetsGallery {
    modalMode?: boolean
    onClose?: () => void
    folderId?: string
}

export const WidgetsGallery = ({ modalMode, onClose, folderId }: IWidgetsGallery) => {
    const {
        widgetsList,
        widgetDownload,
        widgetInfo,
        lang,
        setWidgetDownload,
        setWidgetInfo,
        onCloseDownload,
        isLoading,
    } = WidgetsGalleryHooks()

    const renderWidgetsComponent = () => {
        return (
            <>
                {modalMode && onClose && !widgetInfo && (
                    <div className={modalMode ? styles.closeBlockModal : styles.closeBlock}>
                        <Icon type={'close'} size={20} color={colors.blackLight} onClick={onClose} />
                    </div>
                )}
                {widgetInfo ? (
                    <WidgetInfo
                        data={widgetInfo}
                        lang={lang}
                        setWidgetDownload={setWidgetDownload}
                        setWidgetInfo={setWidgetInfo}
                    />
                ) : (
                    <div className={styles.widgetsGallery}>
                        {widgetsList.length ? (
                            widgetsList.map((data) => (
                                <Widget
                                    key={data.id}
                                    data={data}
                                    lang={lang}
                                    setWidgetDownload={setWidgetDownload}
                                    setWidgetInfo={setWidgetInfo}
                                />
                            ))
                        ) : (
                            <>{!isLoading && <span className={styles.noWidgets}>{translate('noWidgets')}</span>}</>
                        )}
                    </div>
                )}
                {widgetDownload && (
                    <DownloadWidget
                        data={widgetDownload}
                        lang={lang}
                        onClose={onCloseDownload}
                        onCloseModal={onClose}
                        folderId={folderId}
                    />
                )}
            </>
        )
    }

    return modalMode ? (
        renderWidgetsComponent()
    ) : (
        <Content title={translate('widgetsGallery')} fullscreen={modalMode}>
            {renderWidgetsComponent()}
        </Content>
    )
}
