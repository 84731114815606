let lastTime: number

const now = (): number => {
    const time = Date.now()
    const last = lastTime || time
    return (lastTime = time > last ? time : last + 1)
}

export const uniqid = (prefix?: string, suffix?: string): string =>
    (prefix ? prefix : '') + now().toString(36) + (suffix ? suffix : '')
