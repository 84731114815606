import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IDevice } from 'core/models/DigitalSignage'

export type TVideoChatModalState = 'idle' | 'opened' | 'minimized'
export type TVideoCallState = 'idle' | 'calling'

export interface IVideoChatModalOptions {
    digitalSignage: any
}

export interface IVideoCallData {
    video: MediaStream | null
    localVideo: MediaStream | null
}

export interface IVideoChatState {
    videoModalState: TVideoChatModalState
    digitalSignage: any
    videoCallState: TVideoCallState
}

export const initialState: IVideoChatState = {
    videoModalState: 'idle',
    digitalSignage: null,
    videoCallState: 'idle',
}

const videochatSlice = createSlice({
    name: 'videoChat',
    initialState,
    reducers: {
        closeVideoModal: (state) => {
            state.videoModalState = 'idle'
            state.digitalSignage = null
            state.videoCallState = 'idle'
        },
        expandVideoModal: (state) => {
            state.videoModalState = 'opened'
        },
        minimizeVideoModal: (state) => {
            state.videoModalState = 'minimized'
        },
        openVideoModal: (state, action: PayloadAction<IDevice>) => {
            if (state.videoModalState !== 'idle') return

            const digitalSignage = action.payload

            state.videoModalState = 'opened'
            state.digitalSignage = digitalSignage
            state.videoCallState = 'idle'
        },
        openVideoModalWithCall: (state, action: PayloadAction<IDevice>) => {
            if (state.videoModalState !== 'idle') return

            const digitalSignage = action.payload

            state.videoModalState = 'opened'
            state.digitalSignage = digitalSignage
            state.videoCallState = 'calling'
        },
        startVideoCall: (state) => {
            state.videoCallState = 'calling'
        },
        endVideoCall: (state) => {
            state.videoModalState = 'idle'
            state.digitalSignage = null
            state.videoCallState = 'idle'
        },
    },
})

const { reducer: videochatReducer, actions: videochatActions } = videochatSlice
export { videochatReducer, videochatActions }
