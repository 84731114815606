import React from 'react'
import DashboardDevicesMethods from './dashboard__devices.local.methods'
import Card from 'blocks.simple/card/card'
import Animation from 'blocks.simple/animation/animation'
import Link from 'blocks.simple/link/link'

import translate from 'core/translate'
import { routes } from 'features/routes'
import helpers from 'core/helpers'

import styles from './dashboard__devices.jcss'
import { Typography } from 'atoms/Typography'
import { getURLSearchParamsString } from 'features/routes/utils'
import { cn } from 'ethcss'

const displaysQueryParam = helpers.prefixQuery({ name: 'displays', field: 'query' })

const linkRoutes = {
    online: {
        pathname: routes.displays.path,
        search: getURLSearchParamsString({ [displaysQueryParam]: 'online' }),
    },
    offline: {
        pathname: routes.displays.path,
        search: getURLSearchParamsString({ [displaysQueryParam]: 'offline' }),
    },
    error: {
        pathname: routes.displays.path,
        search: getURLSearchParamsString({ [displaysQueryParam]: 'error' }),
    },
    draft: {
        pathname: routes.draftDevices.path,
    },
}

function DashboardDevices(p_: any) {
    const { s_ } = DashboardDevicesMethods()
    const itemRules = p_.rules ? p_.rules : 0

    const getStatus = (status: string, widthValue: number) => {
        if (!s_.data.stats) {
            return null
        }

        const item = s_.data.stats.find((stat: any) => stat.label === status)

        return (
            <div
                className={cn(styles.statusBlock, widthValue === 0.5 ? styles.halfWidth : styles.fullWidth)}
                style={{
                    backgroundColor: item.color,
                }}
            >
                <Typography type="text" className={styles.title}>
                    {translate(item.label)}
                </Typography>
                <div className={styles.info}>
                    <Link
                        className={styles.link}
                        // @ts-ignore
                        to={linkRoutes[item.key]}
                    >
                        {item.value}
                    </Link>
                </div>
            </div>
        )
    }

    function renderCard() {
        if (!s_.data.stats) {
            return null
        }

        return (
            <Card
                title={translate('deviceSummary').toUpperCase()}
                icon={{
                    type: 'display',
                    size: 20,
                    default: true,
                }}
            >
                <div className={styles.wrapper}>
                    {getStatus('online', 1)}
                    {getStatus('error', 0.5)}
                    {getStatus('offline', 0.5)}
                    {getStatus('virtual', 0.5)}
                    {getStatus('draft', 0.5)}
                </div>
                <Typography type="text" className={styles.footer}>
                    {translate('devicesInCompany')}: {s_.data.devicesCount}
                </Typography>
            </Card>
        )
    }

    return (
        <Animation className={cn(p_.className)} style={{ order: itemRules.order }}>
            {renderCard()}
        </Animation>
    )
}

export default React.memo(DashboardDevices)
