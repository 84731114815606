import React, { FunctionComponent, memo } from 'react'
import { cn } from 'ethcss'
import { displayAudioControllStyles } from './DisplayAudioControll-styles'
import { IDisplayAudioControllComponent } from './DisplayAudioControll-types'
import { usePlaybackState } from './DisplayAudioControll-hooks'
import { isNotEmptyArray } from 'core/utils/coreUtil'
import { AudioTrackList } from 'organisms/AudioTrackList'
import { AudioPlayground } from 'organisms/AudioPlayground'
import translate from 'core/translate'
import Icon from 'blocks.simple/icon/icon'

export const DisplayAudioControllComponent: FunctionComponent<IDisplayAudioControllComponent> = ({
    selectedInfo,
    closeModalContent,
}) => {
    const { isGetPlayback, isOnline, soundtrack, getActiveSoundtrackId, audioSourceList } = usePlaybackState(
        selectedInfo,
        closeModalContent
    )

    const isExistSoundtrack = () => {
        return soundtrack && isNotEmptyArray(soundtrack)
    }

    const isExistAudioSourceList = () => {
        return audioSourceList && isNotEmptyArray(audioSourceList)
    }

    const isShowInfo = () => {
        return isOnline && isGetPlayback && !isExistAudioSourceList() && !isExistSoundtrack()
    }

    return (
        <div className={displayAudioControllStyles.DisplayAudioControll__wrapper}>
            <div
                className={cn(
                    displayAudioControllStyles.DisplayAudioControll,
                    !soundtrack.length ? displayAudioControllStyles.DisplayAudioControll__playground_center : ''
                )}
            >
                {isOnline && soundtrack && soundtrack.length ? (
                    <div className={displayAudioControllStyles.DisplayAudioControll__audioTrackList}>
                        <AudioTrackList
                            audioTrackList={soundtrack}
                            activeAudioTrackId={getActiveSoundtrackId()}
                            title={translate('audioTrackList')}
                        />
                    </div>
                ) : null}

                <div
                    className={cn(displayAudioControllStyles.DisplayAudioControll__main, {
                        [displayAudioControllStyles.DisplayAudioControll__main_status_info]: isShowInfo(),
                        [displayAudioControllStyles.DisplayAudioControll__main_status_error]: !isOnline,
                        [displayAudioControllStyles.DisplayAudioControll__main_status_default]:
                            !isShowInfo() && isOnline,
                        [displayAudioControllStyles.DisplayAudioControll__playground_center]: !soundtrack.length,
                    })}
                >
                    {isOnline && isExistAudioSourceList() ? (
                        <div className={displayAudioControllStyles.DisplayAudioControll__audioPlayground}>
                            <AudioPlayground audioSourceList={audioSourceList} />
                        </div>
                    ) : null}
                    {!isOnline ? (
                        <div className={displayAudioControllStyles.DisplayAudioControll__error}>
                            <div className={displayAudioControllStyles.DisplayAudioControll__errorImage}>
                                <Icon type={'error'} color={'error'} size={77} />
                            </div>
                            <div className={displayAudioControllStyles.DisplayAudioControll__errorTitle}>
                                {translate('playbackStateErrorTitle')}
                            </div>
                            <div className={displayAudioControllStyles.DisplayAudioControll__errorSubTitle}>
                                {translate('playbackStateErrorSubTitle')}
                            </div>
                            <div className={displayAudioControllStyles.DisplayAudioControll__errorText}>
                                {translate('playbackStateErrorText')}
                            </div>
                        </div>
                    ) : null}
                    {isShowInfo() ? (
                        <div className={displayAudioControllStyles.DisplayAudioControll__info}>
                            <div className={displayAudioControllStyles.DisplayAudioControll__infoImage}>
                                <Icon type={'playback'} color={'white'} size={37} />
                            </div>
                            <div className={displayAudioControllStyles.DisplayAudioControll__infoTitle}>
                                {translate('playbackStateInfoTitle')}
                            </div>
                            <div className={displayAudioControllStyles.DisplayAudioControll__infoSubTitle}>
                                {translate('playbackStateInfoSubTitle')}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export const DisplayAudioControll = memo(DisplayAudioControllComponent)
