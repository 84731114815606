import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import {defaultThemeStyles as defaultThemeSidebarStyles} from 'blocks.app/sidebar/Sidebar-theme'

const editorActionsStyles = {
    title: {
        ...grid.row,
        ...grid.justify,
        ...grid.p_md,
        ...grid.mt_mdPlus,
        textAlign: 'center',
        color: `var(--var-module-sidebar-component-sidebar-style-title, ${defaultThemeSidebarStyles.title})`,
        borderBottom: `1px solid var(--var-module-sidebar-component-sidebar-style-border, ${defaultThemeSidebarStyles.border})`,
        borderTop: `1px solid var(--var-module-sidebar-component-sidebar-style-border, ${defaultThemeSidebarStyles.border})`,
    },
    emptyMessage: {
        textAlign: 'center',
        ...grid.m_md,
    },
    action: {
        ...grid.row,
        ...grid.justify,
        ...grid.noWrap,
        ...grid.p_md,
    },
    actionTitle: {
        ...item.ellipsis,
    },
    actionIcons: {
        ...grid.row,
        ...grid.noWrap,
    },
}

addStyles(editorActionsStyles)
export default editorActionsStyles
