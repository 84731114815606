import { addStyles } from 'ethcss'
import { IStyles, mediaCSS } from '../../theme'
import { colors } from 'theme/colors'
import { defaultThemeStyles } from '../../blocks.app/app/app.theme'

const settingsStyles: IStyles = {
    companyWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        color: colors.grey,
        fontSize: 18,
        borderBottom: `1px solid ${colors.grey}`,
        paddingBottom: 20,
        marginBottom: 24,

        [mediaCSS.desktopL]: {
            display: 'block',
            fontSize: 14,

            [mediaCSS.tabMini]: {
                paddingTop: 54,
                marginBottom: 24,
            },
        },
    },
    companyName: {
        fontSize: 24,
        fontWeight: 700,
        marginBottom: 20,

        [mediaCSS.tabMini]: {
            fontSize: 18,
            marginBottom: 14,
        },
    },
    companyLeft: {},
    companyRow: {
        padding: '4px 0',
    },
    companyRight: {
        display: 'flex',
        textAlign: 'center',
        paddingTop: 46,
        maxWidth: 740,

        [mediaCSS.desktopL]: {
            maxWidth: '100%',

            [mediaCSS.tabMini]: {
                display: 'block',
                paddingTop: 6,
            },
        },
    },
    companyBlock: {
        position: 'relative',
        borderRadius: 10,
        padding: 20,

        [mediaCSS.tabMini]: {
            display: 'flex',
            padding: 0,
            marginBottom: 6,
        },
    },
    deviceIcon: {
        position: 'absolute',
        top: -16,
        left: '50%',
        transform: 'translateX(-50%)',
        padding: 4,
        backgroundColor: `var(--var-module-app-component-app-style-background, ${colors.white})`,

        [mediaCSS.tabMini]: {
            display: 'none',
        },
    },
    companyBlockWithBorder: {
        border: `1px solid ${colors.grey}`,

        [mediaCSS.tabMini]: {
            border: 'none',
        },
    },
    count: {
        fontSize: 32,
        fontWeight: 700,
        marginTop: 24,

        [mediaCSS.tabMini]: {
            fontSize: 14,
            marginTop: 0,
            marginLeft: 6,
        },
    },
    licensesBlock: {
        fontSize: 18,
        color: colors.white,

        [mediaCSS.desktopL]: {
            fontSize: 14,
        },
    },
    licensesRow: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 36,
        borderRadius: 5,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        marginBottom: 20,
        overflow: 'hidden',

        [mediaCSS.tab]: {
            padding: 20,

            [mediaCSS.tabMini]: {
                display: 'block',
            },
        },
    },
    timeLeftAndCount: {
        justifyContent: 'space-between',
        width: 340,

        [mediaCSS.desktopL]: {
            display: 'flex',
            width: 240,

            [mediaCSS.tabMini]: {
                display: 'block',

                span: {
                    display: 'block',
                },
            },
        },
    },
    daysLeft: {
        display: 'inline-block',
        width: 200,

        [mediaCSS.desktopL]: {
            width: 'auto',
            display: 'inline',
        },
    },
    licenseStatus: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        width: 270,
        zIndex: 1,

        [mediaCSS.desktopL]: {
            width: 190,

            [mediaCSS.tabMini]: {
                display: 'block',
            },
        },
    },
    licenseType: {
        width: 150,

        [mediaCSS.desktopL]: {
            width: 90,
        },
    },
    accentLabel: {
        position: 'absolute',
        right: 0,
        top: 0,
        height: '100%',
        width: 354,
        borderRadius: '0 0 0 40px',
        background: colors.blueAccent,

        [mediaCSS.desktopL]: {
            width: 236,

            [mediaCSS.tabMini]: {
                display: 'none',
            },
        },
    },
    accentFrame: {
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        left: 0,
        border: `4px solid ${colors.blueAccent}`,
        pointerEvents: 'none',
    },
    activateBtn: {
        borderRadius: 5,
        backgroundColor: 'rgba(0,0,0,0.4)',
        padding: 10,
        cursor: 'pointer',
    },
    modal: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.7)',
        zIndex: 9999,
    },
    modalInner: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        width: 450,
        height: 520,
        borderRadius: 20,
        backgroundColor: colors.white,
        backgroundImage: 'url("images/licenses/gift_background.jpg")',
        backgroundPosition: 'left -30px bottom 0',
        backgroundSize: '480px',
        backgroundRepeat: 'no-repeat',
        padding: 44,
        textAlign: 'center',
    },
    giftCaption: {
        fontSize: 36,
        fontWeight: 700,
    },
    giftText: {
        fontSize: 24,
        color: colors.white,
        marginTop: 340,
        lineHeight: 1.3,
    },
    modalClose: {
        position: 'absolute',
        top: 20,
        right: 20,
    },
    gift: {
        position: 'absolute',
        left: 14,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    giftPadding: {
        paddingLeft: '53px !important',
    },
    licenceActionsButtons: {
        display: 'flex',
        marginBottom: 24,
    },
    licenceActionButton: {
        position: 'relative',
        marginRight: 20,
    },
    redError: {
        color: colors.error,
        paddingTop: '5px',
    },
    requestLicensesModal: {
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(0,0,0,0.7)',
        zIndex: 9999,
    },
    requestLicensesModalInner: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: colors.white,
        width: 450,
        padding: 40,
        borderRadius: 20,
    },
    requestLicensesFieldWrapper: {
        marginBottom: 20,
    },
    requestLicensesFields: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    halfField: {
        width: '48%',
    },
    halfFieldForDate: {
        width: '48%',
        zIndex: 9,
    },
    createFileButtons: {
        display: 'flex',
        justifyContent: 'center',
    },
    createFileButton: {
        margin: '0 14px',
    },
    uploadLicenseStatus: {
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(0,0,0,0.7)',
        zIndex: 9999,
    },
    uploadLicenseStatusInner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: colors.white,
        width: 450,
        padding: 40,
        borderRadius: 20,
    },
    uploadText: {
        marginBottom: 46,
        fontSize: 18,
        color: colors.grey,
        textAlign: 'center',
    },
    uploadLicenseStatusClose: {
        position: 'absolute',
        top: 16,
        right: 16,
    },
    licenceInputFile: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: 0,
    },
}

addStyles(settingsStyles)
export default settingsStyles
