import { colors } from 'theme'

export const defaultThemeStyles: {
    [index: string]: string
} = {
    'circle-textMain': colors.grey,
    'circle-border': colors.grey,
    'circle-background': colors.grey,
    'circle-icon': colors.white,
    'circleInv-textMain': colors.grey,
    'circleInv-border': colors.white,
    'circleInv-background': colors.white,
    'circleInv-icon': colors.grey,
    'white-textMain': colors.white,
    'white-border': colors.white,
    'white-background': colors.white,
    'white-icon': colors.black,
}
