import React, { useEffect, useRef, useState } from 'react'
import Dropzone from 'react-dropzone'
import Icon from 'blocks.simple/icon/icon'
import { connect } from 'react-redux'
import Animation from 'blocks.simple/animation/animation'
import Button from 'blocks.simple/button/button'
import { filesActions } from '../files.state'
import { uploadFiles } from 'core/services/DownloadService/DownloadService.state'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { grid } from 'theme'
import { text as textStyles } from 'theme'
import styles from './files__upload.jcss'
import helpers from 'core/helpers'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { withRouter } from 'react-router'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { IFile } from '../../../core/models/Files'

const accept = (onHover?: boolean) => {
    const accept: { [index: string]: string[] } = {}
    if (!onHover) {
        if (helpers.isAvailableFileType({ key: 'files', name: 'image', action: 'create' })) {
            accept['image/jpg'] = ['.jpg', '.jpeg', '.png', '.svg', '.gif']
        }
        if (helpers.isAvailableFileType({ key: 'files', name: 'office', action: 'create' })) {
            accept['application/pdf'] = ['.pdf']
        }
        if (helpers.isAvailableFileType({ key: 'files', name: 'video', action: 'create' })) {
            accept['video/mp4'] = ['.mp4', '.ogv', '.avi', '.mkv', '.mov', '.ogg']
        }
        if (helpers.isAvailableFileType({ key: 'files', name: 'audio', action: 'create' })) {
            accept['audio/mp3'] = ['.wav', '.mp3', '.m4a', '.aac']
        }
        if (helpers.isAvailableFileType({ key: 'files', name: 'web_app', action: 'create' })) {
            accept['application/zip'] = ['.zip']
        }

        return accept
    } else return
}

const onSelectCheck = () => {
    let accept = []
    if (helpers.isAvailableFileType({ key: 'files', name: 'image', action: 'create' })) {
        accept.push('jpg')
        accept.push('jpeg')
        accept.push('png')
        accept.push('gif')
        accept.push('svg')
    }
    if (helpers.isAvailableFileType({ key: 'files', name: 'office', action: 'create' })) {
        accept.push('pdf')
    }
    if (helpers.isAvailableFileType({ key: 'files', name: 'video', action: 'create' })) {
        accept.push('mp4')
        accept.push('ogv')
        accept.push('avi')
        accept.push('mkv')
        accept.push('mov')
    }
    if (helpers.isAvailableFileType({ key: 'files', name: 'audio', action: 'create' })) {
        accept.push('wav')
        accept.push('mp3')
        accept.push('m4a')
        accept.push('aac')
    }
    if (helpers.isAvailableFileType({ key: 'files', name: 'web_app', action: 'create' })) {
        accept.push('zip')
    }

    return accept
}

const ButtonUpload = (p_: any) => {
    const dropzoneRef = useRef<any>(null)
    const inputRef = useRef<any>(null)
    const inputFileRef = useRef<any>(null)
    const mod = p_.mod ? p_.mod : 'withShadow'

    const getAllowedFiles = (filesList: any) => {
        const accept = onSelectCheck()
        return filesList.filter((file: any) => accept.includes(file.name.split('.').pop()))
    }

    const onDrop = (filesList: IFile[]) => {
        const allowedFiles = getAllowedFiles(filesList)
        const { location, onSuccessDrop, setToolbar, uploadFiles } = p_
        const query = getURLSearchParamsByLocation(location)
        const folderId = query.folderId

        if (onSuccessDrop) {
            onSuccessDrop()
        }

        setToolbar('left')
        !p_.convertMode
            ? uploadFiles({
                  filesList: allowedFiles,
                  folderId,
              })
            : uploadFiles({
                  filesList: allowedFiles,
                  folderId,
                  withConversion: 'withConversion',
              })
    }
    const onOpenFilesClick = () => {
        const s_ = { loading: false }

        if (!p_.disabled || !s_.loading) {
            if (inputFileRef.current) {
                inputFileRef.current.click()
            }
        }
    }
    const onOpenFolderClick = () => {
        const s_ = { loading: false }

        if (!p_.disabled || !s_.loading) {
            inputRef.current.click()
        }
    }

    const getTitle = () => {
        const title = <span className={styles.normalWhiteSpace}>{p_.title}</span>

        return p_.convertMode ? title : translate('uploadFile') || title
    }

    useEffect(() => {
        const input = inputRef.current
        if (input) {
            input.setAttribute('webkitdirectory', true)
        }
    }, [])

    return (
        <div>
            <Button
                className={grid.mt_mini}
                onClick={onOpenFilesClick}
                mod={mod}
                rounded="full_md"
                animation={false}
                disabled={p_.disabled || p_.isUploading}
            >
                {p_.isUploading ? translate('uploadProgress') : getTitle()}
            </Button>
            {!p_.withoutFolder && !p_.convertMode && (
                <Button
                    onClick={onOpenFolderClick}
                    mod={mod}
                    rounded="full_md"
                    animation={false}
                    disabled={p_.disabled || p_.isUploading}
                    className={styles.selectFolder}
                >
                    {translate('uploadFolder')}
                </Button>
            )}
            <Dropzone ref={dropzoneRef} onDrop={(acceptedFiles: any) => onDrop(acceptedFiles)} accept={accept()}>
                {({ getRootProps, getInputProps }) => {
                    const folderInputProps = getInputProps()
                    delete folderInputProps.accept
                    delete folderInputProps.multiple

                    return (
                        <div
                            {...getRootProps({
                                onClick: (event) => event.stopPropagation(),
                                className: cn(styles.dropzone),
                            })}
                        >
                            <input {...folderInputProps} type={'file'} ref={inputRef} />
                            <input {...getInputProps()} type={'file'} ref={inputFileRef} />
                        </div>
                    )
                }}
            </Dropzone>
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setToolbar: (toolbar: string) => dispatch(filesActions.setToolbar(toolbar)),
        uploadFiles: (uploadData: { filesList: any[]; folderId: string }) => dispatch(uploadFiles(uploadData)),
        showError: (err: string) => dispatch(emitError(err)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        isUploading: state.downloads.isUploading,
    }
}

export const UploadButton = connect(null, mapDispatchToProps)(withRouter(ButtonUpload))

let dragStart = false

const Upload = (p_: any) => {
    const [onHover, toggleOnHover] = useState<boolean>(false)
    const disabled = p_.disabled || p_.frame || !helpers.isAvailable({ key: 'files', action: 'create' }) || !p_.folderId

    const onDrop = (filesList: { path: string }[]) => {
        const filesListFiltered = filesList.filter((file) => file.path[0] !== '/')
        const { setToolbar, folderId, uploadFiles, showError } = p_

        if (!filesList.length) {
            showError(translate('fileIsNotSupported'))
        }

        dragStart = false
        setToolbar('left')
        onHover
            ? uploadFiles({
                  filesList: filesListFiltered,
                  folderId,
                  withConversion: 'withConversion',
              })
            : uploadFiles({
                  filesList: filesListFiltered,
                  folderId,
              })
    }

    useEffect(() => {
        accept(onHover)
    }, [onHover])

    return (
        <Dropzone onDrop={(acceptedFiles: any) => onDrop(acceptedFiles)} accept={accept(onHover)}>
            {({ getRootProps, getInputProps }) => (
                <div
                    {...getRootProps({
                        onClick: (event) => event.stopPropagation(),
                        onDragEnter: (e: any) => {
                            if (disabled) {
                                return
                            }
                            dragStart = true
                            if (
                                e.target.id === 'filesDropRightWrapper' ||
                                e.target.id === 'filesDropzoneContent' ||
                                e.target.id === 'filesDropzoneIcon' ||
                                e.target.id === 'filesDropzoneText'
                            ) {
                                toggleOnHover(true)
                            } else {
                                toggleOnHover(false)
                            }
                        },
                        onDragLeave: () => {
                            if (disabled) {
                                return
                            }
                            dragStart = false
                        },
                        className: cn(styles.dropzone),
                    })}
                >
                    <input {...getInputProps()} />
                    <div className={cn(grid.row, grid.full)}>{p_.children}</div>
                    <Animation>
                        {dragStart && !disabled && (
                            <>
                                <div key={'filesDropWrapper'} className={styles.dropWrapper}>
                                    <div className={styles.dropzoneContent}>
                                        <Icon type="download" size={150} />
                                        <div
                                            className={cn(
                                                textStyles.md,
                                                textStyles.darkCloud,
                                                textStyles.center,
                                                grid.w60,
                                                grid.mt_mdPlus
                                            )}
                                        >
                                            {translate('uploadDescription')}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    key={'filesDropRightWrapper'}
                                    className={styles.dropWrapperRight}
                                    id={'filesDropRightWrapper'}
                                >
                                    <div className={styles.dropzoneContent} id={'filesDropzoneContent'}>
                                        <Icon type="download" size={150} id={'filesDropzoneIcon'} />
                                        <div
                                            id={'filesDropzoneText'}
                                            className={cn(
                                                textStyles.md,
                                                textStyles.darkCloud,
                                                textStyles.center,
                                                grid.w60,
                                                grid.mt_mdPlus
                                            )}
                                        >
                                            {translate('uploadConvertVideo')}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </Animation>
                </div>
            )}
        </Dropzone>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Upload)
