import PropTypes from 'prop-types'
import React from 'react'
import { TransitionGroup } from 'react-transition-group'
import { cn } from 'ethcss'
import styles from './animation.jcss'
import { transitions } from 'blocks.app/config'
const getTransition = (p_: { transition: string }) => transitions[p_.transition]
const getTransitionName = (p_: { transition: string; transitionName: string }) =>
    `${p_.transitionName}_${p_.transition}`
const getClassName = (p_: { transition: string; transitionName: string }, prefix: string) =>
    `${getTransitionName(p_)}-${prefix}`

interface AnimationWrapperProps {
    activeClassName: any
    children: any
    className: string
    isActive: (value: number) => boolean
    transitionName: string
    transition: string
    style: {
        [index: string]: any
    }
}

class AnimationInner extends React.Component<{
    transition: string
    transitionName: string
}> {
    state = {
        className: '',
    }
    startAnimateTimer: any = null
    endAnimateTimer: any = null

    componentWillEnter(cb: { key: React.Component }) {
        this.animate(cb, 'enter')
    }
    componentWillLeave(cb: { key: React.Component }) {
        this.animate(cb, 'leave')
    }
    componentWillUnmount() {
        clearTimeout(this.startAnimateTimer)
        clearTimeout(this.endAnimateTimer)
    }
    animate = (cb: any, prefix: string) => {
        const p_ = this.props

        const transition = getTransition(p_)

        this.setState({ className: getClassName(p_, prefix) }, () => {
            this.startAnimateTimer = setTimeout(() => {
                this.setState({ className: cn(this.state.className, getClassName(p_, `${prefix}-active`)) }, () => {
                    this.endAnimateTimer = setTimeout(() => this.endAnimate(cb), transition)
                })
            }, 0)
        })
    }
    endAnimate = (cb: any) => {
        this.setState({ className: '' }, cb)
    }

    extendChildren(p_: any, className: any) {
        return React.Children.map(p_.children, (child) => {
            return React.cloneElement(child, {
                className: cn(child.props.className, className),
            })
        })
    }
    render() {
        const p_ = this.props
        const s_ = this.state

        return this.extendChildren(p_, s_.className)
    }
}

class AnimationWrapper extends React.Component<AnimationWrapperProps> {
    render() {
        const p_ = this.props
        const divProps = { ...p_ }
        const isActive = p_.isActive(p_.children ? p_.children.length : 0)

        delete divProps.activeClassName
        delete divProps.isActive
        return <div {...divProps} className={cn(p_.className, { [p_.activeClassName]: isActive })} />
    }
}

class Animation extends React.Component {
    static propTypes = {
        transition: PropTypes.string,
        className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        activeClassName: PropTypes.string,
        isActive: PropTypes.func,
        children: PropTypes.node,
        transitionName: PropTypes.string,
        style: PropTypes.object,
    }
    static defaultProps = {
        transitionName: 'fade',
        transition: 'normal',
        isActive: () => false,
    }
    render() {
        const p_: any = this.props

        return (
            //@ts-ignore
            <TransitionGroup
                component={AnimationWrapper}
                className={cn(styles.wrapper, p_.className)}
                activeClassName={p_.activeClassName}
                isActive={p_.isActive}
                style={p_.style}
            >
                {!!p_.children ? <AnimationInner key={p_.children.key} {...p_} /> : null}
            </TransitionGroup>
        )
    }
}

export default Animation
