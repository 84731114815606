import { addStyles } from 'ethcss'
import { item, IStyles } from '../../theme'

const stickStyles: IStyles = {
    wrapper: {
        '& .sticky-outer-wrapper.active': {
            '& .sticky-inner-wrapper': {
                ...item.shadowButton,
                backgroundColor: 'inherit',
            },
        },
    },
}

addStyles(stickStyles)
export default stickStyles
