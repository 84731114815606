import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../../theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { defaultThemeStyles } from './FilesAdd-theme'

const filesAddStyles: IStyles = {
    filesAddWrapper: {
        ...grid.row,
        ...grid.full,
        position: 'absolute',
    },
    toolbar: {
        ...grid.w40,
        ...grid.fullHeight,
        backgroundColor: `var(--var-module-files-component-add-style-toolbar, ${defaultThemeStyles.toolbar})`,
    },
    activeItem: {
        ...item.clouds,
        backgroundColor: `var(--var-module-files-component-add-style-activeItem, ${defaultThemeStyles.activeItem})`,
    },
    listItem: {
        ...grid.p_mini,
        cursor: 'pointer',
    },
    mainListItem: {
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    contentWrapper: {
        ...grid.w60,
        ...grid.fullHeight,
    },
    content: {
        ...grid.col,
        ...grid.full,
        ...grid.justify,
        ...grid.p_mini,
    },
    title: {
        fontWeight: 'bold',
        ...grid.mb_md,
    },
    footer: {
        ...grid.row,
        ...grid.justify,
    },
    input: {
        ...grid.mb_md,
    },
}

addStyles(filesAddStyles)
export default filesAddStyles
