import { addStyles } from 'ethcss'
import { item, grid, IStyles } from '../../../theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { mediaCSS } from 'theme/media'

const displaysPermissionsStyles: IStyles = {
    modalWrapper: {
        ...grid.full,
        ...grid.rowCenter,
    },
    modalInner: {
        ...grid.w40,
        [mediaCSS.tabMini]: {
            ...grid.w90,
        },
        ...item.card,
        ...grid.p_md,
        ...item.shadowModal,
        ...item.rounded_full_mini,
        position: 'relative',
    },
    loader: {
        ...grid.colCenter,
    },
    loadingDescription: {
        ...grid.colCenter,
        position: 'absolute',
        ...grid.full,
        textAlign: 'center',
        top: 0,
        left: 0,
    },
    header: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
        ...grid.pb_md,
        fontSize: 18,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    permission: {
        ...grid.w50,
    },
    listItem: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.pb_mini,
        ...grid.pt_mini,
        ...grid.justify,
        ...grid.noWrap,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    footer: {
        ...grid.pt_md,
        ...grid.rowCenter,
    },
}

addStyles(displaysPermissionsStyles)
export default displaysPermissionsStyles
