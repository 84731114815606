import React from 'react'
import { Radio } from 'atoms/Radio'
import translate from 'core/translate'
import { Typography } from 'atoms/Typography'
import { cn } from 'ethcss'
import styles from './deviceSettings__kioskMode.jcss'

const DeviceSettingsKioskMode = (p_: {
    enabled: boolean
    value: string
    className: string | object
    onChange: (value: string, field: string) => void
}) => {
    if (!p_.enabled) {
        return null
    }

    return (
        <div className={cn(p_.className)}>
            <Typography className={cn(styles.title)} type="title">
                {translate('kioskMode')}
            </Typography>
            <div className={styles.radioWrapper}>
                <Radio
                    className={styles.radio}
                    onClick={function () {
                        p_.onChange('kiosk', 'controlsMode')
                    }}
                    selected={p_.value === 'kiosk'}
                    label={translate('on')}
                />
                <Radio
                    onClick={function () {
                        p_.onChange('system', 'controlsMode')
                    }}
                    selected={p_.value === 'system'}
                    label={translate('off')}
                />
            </div>
        </div>
    )
}

export default React.memo(DeviceSettingsKioskMode)
