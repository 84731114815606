import React, { useEffect, useState } from 'react'
import styles from '../ipTv.jcss'
import translate from '../../../core/translate'
import { Header } from '../__header/Header'
import { cn } from 'ethcss'
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import Icon from '../../../blocks.simple/icon/icon'
import Checkbox from '../../../blocks.simple/checkbox/checkbox'
import Button from '../../../blocks.simple/button/button'
import { Search } from '../__search/Search'
import { CategoryAdd } from '../__categoryAdd/CategoryAdd'
import { api } from '../../../core/api/ConnectionManager'
import InfiniteScroll from 'react-infinite-scroller'
import LoaderLazy from 'blocks.app/loader/_lazy/loader__lazy'
import Tippy from 'blocks.simple/tippy/tippy'

export interface ICategory {
    id?: number
    title: string
    order?: number
}

const mockList = [
    {
        id: 1,
        title: 'ТВ-3',
    },
    {
        id: 2,
        title: 'Россия 1',
    },
]

export const Categories = () => {
    const [categoryList, setCategoryList] = useState<ICategory[]>([])
    const [selectedItems, setSelectedItems] = useState<number[]>([])
    const [categoryData, setCategoryData] = useState<ICategory | null>(null)
    const [search, setSearch] = useState<string>('')
    const [deleteConfirm, setDeleteConfirm] = useState<number | null>(null)
    const [multipleDeleteConfirm, setMultipleDeleteConfirm] = useState<boolean>(false)
    const [nextPageTokenRes, setNextPageTokenRes] = useState<string>('')
    useEffect(() => {
        getCategoryList(search, null)
    }, [search])

    const getCategoryList = (query: string, nextPageToken: any) => {
        const data: any =
            nextPageToken === null
                ? {
                      limit: 15,
                      sort: [['order', 'ASC']],
                  }
                : {
                      nextPageToken: nextPageToken,
                      limit: 10,
                  }

        if (query) {
            data.query = query
        }

        api.send('getCategoryList', data).then((res: any) => {
            if (!res && !res.data) return

            setNextPageTokenRes(res.nextPageToken)

            if (nextPageToken) {
                setCategoryList([...categoryList, ...res.data])
            } else {
                setCategoryList(res.data)
            }
        })
    }

    const onSelectItem = (id: number) => {
        if (selectedItems.includes(id)) {
            setSelectedItems(selectedItems.filter((item) => item !== id))
            return
        }

        setSelectedItems([id, ...selectedItems])
    }
    const onDeleteItem = (id: number) => {
        api.send('deleteCategory', { ids: [id] }).then(() => setDeleteConfirm(null))
    }
    const deleteAllSelected = () => {
        api.send('deleteCategory', { ids: selectedItems }).then(() => setMultipleDeleteConfirm(false))
    }
    const onCancelDelete = () => {
        setDeleteConfirm(null)
        setMultipleDeleteConfirm(false)
    }
    const onDeleteConfirm = (id: number) => {
        setDeleteConfirm(id)
    }
    const onMultipleDeleteConfirm = () => {
        setMultipleDeleteConfirm(true)
    }
    const selectAll = () => {
        setSelectedItems(categoryList.map((category: any) => category.id))
    }
    const clearSelection = () => {
        setSelectedItems([])
    }
    const dropdownSelect = (select: any) => {
        if (select.id === 'selectAll') {
            selectAll()
            return
        }

        clearSelection()
    }
    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        const sortedList = arrayMove(categoryList, oldIndex, newIndex).slice()
        setCategoryList(sortedList)
        api.send('updateCategory', { id: sortedList[newIndex].id, order: newIndex })
    }
    const onAddCategory = () => {
        setCategoryData({
            title: '',
        })
    }
    const onEditCategory = (category: ICategory) => {
        setCategoryData(category)
    }
    const onCategoryChange = (field: string, value: any) => {
        if (categoryData) {
            setCategoryData({
                ...categoryData,
                [field]: value,
            })
        }
    }
    const onCloseModal = () => {
        setCategoryData(null)
    }
    const onCategoryCreated = (category: ICategory) => {
        setCategoryList([category, ...categoryList])
    }
    const onCategoryUpdated = (category: ICategory) => {
        setCategoryList(
            categoryList.map((el: ICategory) => {
                if (el.id === category.id) {
                    return category
                }

                return el
            })
        )
    }
    const onCategoryDeleted = (res: any) => {
        setCategoryList(categoryList.filter((category) => !res.find((el: any) => el.id === category.id)))
    }
    const onSaveCategory = () => {
        if (!categoryData) return

        if (categoryData.id) {
            api.send('updateCategory', {
                id: categoryData.id,
                title: categoryData.title,
            }).then(onCloseModal)

            return
        }

        api.send('createCategory', {
            title: categoryData.title,
            order: 2,
        }).then(onCloseModal)
    }
    const SortableList = SortableContainer(({ items }: any) => (
        <ul className={styles.listWrapper}>
            {items.map((category: any, index: number) => (
                <SortableItem
                    category={category}
                    key={category.id}
                    index={index}
                    onDelete={onDeleteItem}
                    onSelect={onSelectItem}
                />
            ))}
        </ul>
    ))
    const DragHandle = SortableHandle(() => (
        <div className={styles.handle}>
            <Icon type={'sortable_handle'} />
        </div>
    ))
    const SortableItem = SortableElement(({ category }: any) => {
        return (
            <li className={styles.category}>
                <div className={styles.checkbox}>
                    <DragHandle />
                    <Checkbox checked={selectedItems.includes(category.id)} onClick={() => onSelectItem(category.id)} />
                </div>
                <div className={styles.categoryName}>
                    <Tippy title={category.title} disabled={category.title.length < 16}>
                        {' '}
                        {category.title}
                    </Tippy>
                </div>
                <div className={styles.changeButtons}>
                    <div>
                        <Icon type={'pencil'} onClick={() => onEditCategory(category)} />
                    </div>
                    <div>
                        <Icon type={'delete'} onClick={() => onDeleteConfirm(category.id)} />
                    </div>
                </div>
            </li>
        )
    })

    useEffect(() => {
        const listenersId: string[] = []
        api.addObserver('categoryCreated', onCategoryCreated, listenersId)
        api.addObserver('categoryUpdated', onCategoryUpdated, listenersId)
        api.addObserver('categoryDeleted', onCategoryDeleted, listenersId)

        return () => listenersId.forEach((id) => api.removeObserver(id))
    }, [categoryList])

    return (
        <>
            <Search search={search} setSearch={setSearch} dropdownSelect={dropdownSelect} />
            <div className={styles.wrapper}>
                <Header
                    selectedItemsLength={selectedItems.length}
                    deleteAllSelected={onMultipleDeleteConfirm}
                    onAdd={onAddCategory}
                />
                <div className={cn(styles.category, styles.categoriesHeader)}>
                    <div className={styles.checkbox}>
                        <span className={styles.checkboxHeader}>{translate('choice')}</span>
                    </div>
                    <div className={styles.categoryName}>{translate('name')}</div>
                </div>
                <InfiniteScroll
                    loadMore={() => getCategoryList(search, nextPageTokenRes)}
                    hasMore={nextPageTokenRes !== null}
                    initialLoad={false}
                    loader={<LoaderLazy key="iptvCategories_loader" active />}
                    threshold={10}
                >
                    <SortableList items={categoryList} useDragHandle={true} onSortEnd={onSortEnd} />
                </InfiniteScroll>
            </div>
            <CategoryAdd
                category={categoryData}
                onChange={onCategoryChange}
                onClose={onCloseModal}
                onSave={onSaveCategory}
            />
            {(deleteConfirm || multipleDeleteConfirm) && (
                <div className={styles.deleteConfirmWrapper}>
                    <div className={styles.deleteConfirm}>
                        <div>{translate(deleteConfirm ? 'deleteCategoryConfirm' : 'deleteCategoriesConfirm')}</div>
                        <div className={styles.controls}>
                            <Button mod={'withBorder'} className={styles.button} onClick={onCancelDelete}>
                                {translate('cancel')}
                            </Button>
                            <Button
                                mod={'fill'}
                                className={styles.button}
                                onClick={() => (deleteConfirm ? onDeleteItem(deleteConfirm) : deleteAllSelected())}
                            >
                                {translate('delete')}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
