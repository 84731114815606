import React from 'react'
import CustomListHeader, { ICustomListHeaderProps } from '../../__header/customList__header'
import EditableText from 'blocks.simple/editableText/editableText'
import Button from 'blocks.simple/button/button'
import Icon from 'blocks.simple/icon/icon'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import translate from 'core/translate'
import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'
import grid from 'blocks.simple/grid/grid.jcss'
import { cn } from 'ethcss'
import { text as textStyles } from 'theme'
import styles from '../../__content/customList__content.jcss'

const DragHandle = SortableHandle(() => (
    <div className={grid.ml_mini}>
        <Icon type="sortable_handle" />
    </div>
))

const SortableItem = SortableElement(
    (p_: {
        product: {
            title: string
            subTitle: string
            price: string
            fontColor: string
            volume: number
            type: string
        }
        indexData: number
        onEdit: (
            index: number,
            product: {
                type?: string
                title?: string
                subTitle?: string
                price?: string
                fontColor?: string
                volume?: number
            }
        ) => void
        onDelete: (index: number) => void
    }) => {
        const { product } = p_
        const index = p_.indexData
        const isSelectSeparator = product.type === 'separator'

        return (
            <tr>
                <td>
                    <div className={grid.rowCenter}>
                        <Checkbox
                            checked={isSelectSeparator}
                            onClick={(isSeparator: boolean) => {
                                const productType = isSeparator ? 'separator' : ''

                                p_.onEdit(index, { type: productType })
                            }}
                        />
                    </div>
                </td>
                <td>
                    {!isSelectSeparator && (
                        <EditableText
                            id={`productItem_${index}_title`}
                            type={'area'}
                            rows={5}
                            text={product.title}
                            onUpdate={(title, cb) => {
                                p_.onEdit(index, { title })
                                cb()
                            }}
                            placeholder={' '}
                            indentSize={'none'}
                        />
                    )}
                </td>
                <td>
                    {!isSelectSeparator && (
                        <EditableText
                            id={`productItem_${index}_subTitle`}
                            type={'area'}
                            rows={5}
                            text={product.subTitle}
                            onUpdate={(subTitle, cb) => {
                                p_.onEdit(index, { subTitle })
                                cb()
                            }}
                            placeholder={' '}
                            indentSize={'none'}
                        />
                    )}
                </td>
                <td>
                    {!isSelectSeparator && (
                        <EditableText
                            id={`productItem_${index}_price`}
                            type={'text'}
                            text={product.price}
                            onUpdate={(price, cb) => {
                                p_.onEdit(index, { price })
                                cb()
                            }}
                            placeholder={' '}
                            indentSize={'none'}
                        />
                    )}
                </td>
                <td>
                    {!isSelectSeparator && (
                        <Dropdown
                            value={product.fontColor}
                            options={[
                                { id: 'orange', name: translate('orange') },
                                { id: 'white', name: translate('white') },
                            ]}
                            placeholder={translate('white')}
                            onChange={(selected) => {
                                p_.onEdit(index, { fontColor: selected.id })
                            }}
                            indentSize={'mini'}
                        />
                    )}
                </td>

                <td>
                    {!isSelectSeparator && (
                        <EditableText
                            id={`productItem_${index}_volume`}
                            type={'text'}
                            text={product.volume}
                            onUpdate={(volume, cb) => {
                                p_.onEdit(index, { volume })
                                cb()
                            }}
                            placeholder={' '}
                            indentSize={'none'}
                        />
                    )}
                </td>
                <td>
                    <div className={styles.controls}>
                        <Icon
                            onClick={function () {
                                p_.onDelete(index)
                            }}
                            type={'delete'}
                        />

                        <DragHandle />
                    </div>
                </td>
            </tr>
        )
    }
)

const SortableList = SortableContainer((p_: any) => (
    <tbody>
        {p_.list.map((product: any, index: number) => (
            <SortableItem key={`productItem_${index}`} product={product} index={index} indexData={index} {...p_} />
        ))}
    </tbody>
))

const Products = (p_: {
    list: object[]
    onSort: (oldIndex: number, newIndex: number) => void
    onAdd: () => void
    onEdit: (index: number, data: object) => void
    onDelete: (index: number) => void
}) => {
    return (
        <div className={cn(grid.w100, grid.mt_mini)}>
            <div className={cn(grid.w100, grid.mb_mini, textStyles.center)}>{translate('products')}</div>
            <table className={cn(grid.w100)}>
                <thead>
                    <tr>
                        <th>{translate('separator')}</th>
                        <th>{translate('name')}</th>
                        <th>{translate('additionDescription')}</th>
                        <th>{translate('price')}</th>
                        <th>{translate('fontColor')}</th>
                        <th>{translate('volumeLabel')}</th>
                        <th />
                    </tr>
                </thead>
                <SortableList
                    {...p_}
                    useDragHandle={true}
                    onSortEnd={({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
                        p_.onSort(oldIndex, newIndex)
                    }}
                />
            </table>
            <div className={cn(grid.rowCenter, grid.w100, grid.mt_mini)}>
                <Button
                    mod={'fill'}
                    indentSize={'mini'}
                    textSize={'mini'}
                    rounded={'full_normal'}
                    onClick={function () {
                        p_.onAdd()
                    }}
                >
                    {translate('addProduct')}
                </Button>
            </div>
        </div>
    )
}

export default {
    getMethod: 'pitamixGetCatalog',
    updateCatalogMethod: 'pitamixUpdateCatalog',
    onCatalogUpdatedMethod: 'pitamixCatalogUpdated',
    updateMethod: 'pitamixUpdateCatalogCategory',
    updateWebAppMethod: 'pitamixUpdateWebApp',
    catalogSelectedSource: 'pitamixCatalogSelectedSource',
    emptyDescription: () => translate('noItemsDescription'),
    emptyIcon: 'not_allow',
    settingsPrefixes: 'pitamix',
    ...helpers.createListenersWidget({
        onCreate: 'pitamixCatalogCategoryCreated',
        onUpdate: 'pitamixCatalogCategoryUpdated',
        onDelete: 'pitamixCatalogCategoryDeleted',
    }),
    isEqual: (firstItem: { id: number }, secondItem: { id: number }) => firstItem.id === secondItem.id,
    draggable: true,
    category: 'categories',
    tableWrapper: styles.pitamixTableWrapper,
    onDeleteListItem: (listItem: { id: number; catalogId: number }) => {
        api.send('pitamixDeleteCatalogCategory', {
            id: listItem.id,
            catalogId: listItem.catalogId,
        })
    },
    fields: () => [
        {
            id: 'displayStyle',
            name: <Icon size={18} type="fs" tooltip={{ title: translate('displayStyle') }} />,
        },
        { id: 'title', name: translate('categoryName') },
        { id: 'subTitle', name: translate('additionDescription') },
        { id: 'products', name: '' },
    ],
    init(callback: any) {
        // @ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    type: 'pitamix',
    getItemModel: (
        dataItem: { __view: { selected: boolean } },
        getSelected: (item: { __view: { selected: boolean } }) => any
    ) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    headerFilters: (p_: ICustomListHeaderProps) => (
        <CustomListHeader
            onAdd={() => {
                if (!p_.fakeLine) {
                    p_.onAddFakeLine({
                        title: '',
                        subTitle: '',
                        displayStyle: 'default',
                        products: [],
                    })
                } else {
                    p_.onAnimate(true)
                    setTimeout(() => {
                        p_.onAnimate(false)
                    }, 1000)
                }
            }}
            type={p_.type}
            onDelete={() => {
                const id = p_.selectedItems.map((selectedItem) => selectedItem.id)
                if (id.length > 0) {
                    api.send('pitamixDeleteCatalogCategory', {
                        id,
                        catalogId: p_.selectedItems[0].catalogId,
                    })
                }
                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            }}
            {...p_}
        />
    ),
    list: {
        title: (
            p_: { id: number; title: string },
            active: boolean,
            listProps: {
                fakeLine: {}
                onAddFakeLine: (value?: {}) => void
                catalogId: string
            }
        ) => (
            <EditableText
                id={p_.id}
                text={p_.title}
                type={'area'}
                rows={5}
                onChange={(title: string) => {
                    listProps.onAddFakeLine({ ...listProps.fakeLine, title })
                }}
                onUpdate={(title, cb) => {
                    api.send('pitamixUpdateCatalogCategory', {
                        id: p_.id,
                        catalogId: listProps.catalogId,
                        title,
                    }).then(cb)
                }}
                indentSize={'none'}
                placeholder={' '}
            />
        ),
        subTitle: (
            p_: { id: number; subTitle: string },
            active: boolean,
            listProps: {
                fakeLine: {}
                onAddFakeLine: (value?: {}) => void
                catalogId: string
            }
        ) => (
            <div className={cn(grid.w100)}>
                <EditableText
                    id={p_.id}
                    text={p_.subTitle}
                    type={'area'}
                    rows={5}
                    onChange={(subTitle: string) => {
                        listProps.onAddFakeLine({ ...listProps.fakeLine, subTitle })
                    }}
                    onUpdate={(subTitle, cb) => {
                        api.send('pitamixUpdateCatalogCategory', {
                            id: p_.id,
                            catalogId: listProps.catalogId,
                            subTitle,
                        }).then(cb)
                    }}
                    placeholder={' '}
                    indentSize={'none'}
                />
            </div>
        ),
        products: (
            p_: {
                products: object[]
                id: number
                title: string
                subTitle: string
                displayStyle: object
                list: { products: object[] }
            },
            active: boolean,
            listProps: {
                fakeLine: {}
                onAddFakeLine: (value?: {}) => void
                catalogId: string
            }
        ) => (
            <div className={grid.w100}>
                {!p_.id && (
                    <div className={cn(grid.rowCenter, grid.w100, grid.mt_mini)}>
                        <Button
                            className={cn(grid.ml_micro, grid.mr_mini)}
                            indentSize={'mini'}
                            textSize={'mini'}
                            rounded={'full_normal'}
                            onClick={function () {
                                listProps.onAddFakeLine()
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                        <Button
                            mod={'fill'}
                            indentSize={'mini'}
                            textSize={'mini'}
                            rounded={'full_normal'}
                            onClick={function () {
                                api.send('pitamixCreateCatalogCategory', {
                                    catalogId: listProps.catalogId,
                                    title: p_.title,
                                    subTitle: p_.subTitle,
                                    displayStyle: p_.displayStyle,
                                })
                            }}
                            disabled={!p_.title}
                        >
                            {translate('save')}
                        </Button>
                    </div>
                )}
                {p_.id && (
                    <Products
                        list={p_.products}
                        onAdd={() => {
                            const products = [
                                ...p_.products,
                                {
                                    title: ``,
                                    subTitle: ``,
                                    price: '',
                                    type: '',
                                    fontColor: 'white',
                                    volume: '',
                                },
                            ]

                            api.send('pitamixUpdateCatalogCategory', {
                                id: p_.id,
                                catalogId: listProps.catalogId,
                                products,
                            })
                        }}
                        onEdit={(index: number, data: object) => {
                            const products = [...p_.products]
                            products[index] = { ...products[index], ...data }

                            api.send('pitamixUpdateCatalogCategory', {
                                id: p_.id,
                                catalogId: listProps.catalogId,
                                products,
                            })
                        }}
                        onSort={(oldIndex: number, newIndex: number) => {
                            const products = arrayMove(p_.products, oldIndex, newIndex)
                            api.send('pitamixUpdateCatalogCategory', {
                                id: p_.id,
                                catalogId: listProps.catalogId,
                                products,
                            })
                        }}
                        onDelete={(index: number) => {
                            const products = [...p_.products]
                            products.splice(index, 1)

                            api.send('pitamixUpdateCatalogCategory', {
                                id: p_.id,
                                catalogId: listProps.catalogId,
                                products,
                            })
                        }}
                    />
                )}
            </div>
        ),
        displayStyle: (
            p_: { id: number; displayStyle: string },
            active: boolean,
            listProps: {
                fakeLine: {}
                onAddFakeLine: (value?: {}) => void
                catalogId: string
            }
        ) => (
            <Checkbox
                checked={p_.displayStyle === 'compact'}
                onClick={(isCompact: boolean) => {
                    const displayStyle = isCompact ? 'compact' : 'default'
                    if (!p_.id) {
                        listProps.onAddFakeLine({ ...listProps.fakeLine, displayStyle })
                        return
                    }

                    api.send('pitamixUpdateCatalogCategory', {
                        id: p_.id,
                        catalogId: listProps.catalogId,
                        displayStyle,
                    })
                }}
            />
        ),
    },
}
