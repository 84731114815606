import React from 'react'
import ScoreboardsAddMethods from './scoreboards__add.local.methods'
import Content from 'blocks.app/content/content'
import { SelectMapDialog } from 'features/map'
import EditText from 'blocks.simple/editText/editText'
import Stick from 'blocks.simple/stick/stick'
import Button from 'blocks.simple/button/button'
import Range from 'blocks.simple/range/range'
import Tippy from 'blocks.simple/tippy/tippy'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import { useModal } from 'core/hooks'
import translate from 'core/translate'
import styles from './scoreboards__add.jcss'
import { text as textStyles } from 'theme'
import grid from 'blocks.simple/grid/grid.jcss'
import { Typography } from 'atoms/Typography'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { useDispatch } from 'react-redux'
import { Device } from './Device'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

const getInputLabel = (textKey) => <div className={styles.inputLabel}>{translate(textKey)}</div>

const ScoreboardsAdd = (p_) => {
    const scoreboardAdd = ScoreboardsAddMethods(p_)
    const dispatch = useDispatch()
    const mapModal = useModal()

    const getParentId = () => {
        if (p_.location.state) {
            if (p_.location.state.parentId) {
                const parentId = +p_.location.state.parentId
                scoreboardAdd.updateState(parentId, 'parentId')

                return parentId
            }
        }

        return scoreboardAdd.data.parentId
    }

    const onMapClose = () => {
        mapModal.toggleModal()
        scoreboardAdd.onMapClose()
    }

    const renderMap = () => {
        return (
            <SelectMapDialog
                onClose={onMapClose}
                isOpen={mapModal.value}
                location={scoreboardAdd.getLocation()}
                center={scoreboardAdd.getCenter()}
                onChangeLocation={scoreboardAdd.onChangeLocation}
                onChangeAddress={scoreboardAdd.onChangeAddress}
                onChangePlacemarkGeometry={scoreboardAdd.onChangePlacemarkGeometry}
            />
        )
    }

    return (
        <Content>
            <Stick enabled>
                <div className={styles.buttons}>
                    <Button mod="fill" className={grid.mr_mini} onClick={scoreboardAdd.onCancel}>
                        {translate('cancel')}
                    </Button>
                    <Button mod={'withBorder'} onClick={scoreboardAdd.onSave}>
                        {translate('save')}
                    </Button>
                </div>
            </Stick>
            <div className={styles.contentWrapper}>
                <Typography className={styles.title} type="title">
                    {translate('settings')}
                </Typography>
                <div className={styles.content}>
                    <div className={styles.col}>
                        <div className={styles.inputWrapper}>
                            <EditText
                                label={getInputLabel('ipAddress')}
                                id="ipAddress"
                                placeholder="127.0.0.1"
                                className={textStyles.center}
                                value={scoreboardAdd.data.ipAddress}
                                onChange={scoreboardAdd.updateIpAddress}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <EditText
                                label={getInputLabel('port')}
                                id="port"
                                type="number"
                                className={textStyles.center}
                                value={scoreboardAdd.data.port}
                                onChange={scoreboardAdd.updatePort}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <EditText
                                id={'TCPServerCode'}
                                autoComplete="new-password"
                                className={textStyles.center}
                                label={getInputLabel('TCPServerPassword')}
                                value={scoreboardAdd.data.password}
                                onChange={scoreboardAdd.updatePassword}
                            />
                            <Typography className={styles.info} type="text">
                                {translate('scoreboardsPasswordInfo')}
                            </Typography>
                        </div>
                        <div className={styles.inputWrapper}>
                            <EditText
                                id={'searchPin'}
                                className={textStyles.center}
                                label={getInputLabel('searchPin')}
                                value={scoreboardAdd.data.searchPin}
                                onChange={scoreboardAdd.updateSearchPin}
                            />
                            <Typography className={styles.info} type="text">
                                {translate('scoreboardsPasswordInfo')}
                            </Typography>
                        </div>
                        <div className={styles.inputWrapper}>
                            <EditText
                                label={getInputLabel('name')}
                                id="name"
                                className={textStyles.center}
                                value={scoreboardAdd.data.name}
                                onChange={scoreboardAdd.updateName}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <EditText
                                label={getInputLabel('address')}
                                id="address"
                                icon="location"
                                className={textStyles.center}
                                onIconClick={mapModal.toggleModal}
                                value={scoreboardAdd.getAddress()}
                                onChange={scoreboardAdd.updateAddress}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <Dropdown
                                label={getInputLabel('folder')}
                                id="group"
                                value={scoreboardAdd.data.parentId ? scoreboardAdd.data.parentId : getParentId()}
                                valueField={'ledControllerFolderId'}
                                options={scoreboardAdd.folders}
                                centered
                                searchable
                                type="tree"
                                onChange={scoreboardAdd.onChangeParentFolder}
                            />
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.inputWrapper}>
                            <EditText
                                label={getInputLabel('description')}
                                id="description"
                                type="area"
                                rows="6"
                                value={scoreboardAdd.data.description}
                                onChange={scoreboardAdd.updateDescription}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            {getInputLabel('brightness')}
                            <Tippy html={scoreboardAdd.data.brightness}>
                                <Range
                                    max={100}
                                    min={1}
                                    step={1}
                                    value={scoreboardAdd.data.brightness}
                                    onChange={scoreboardAdd.updateBrightness}
                                />
                            </Tippy>
                        </div>
                    </div>
                </div>
                <Typography className={styles.title} type="title">
                    {translate('devices')}
                </Typography>
                <div className={styles.content}>
                    <div className={styles.actions}>
                        <Button
                            mod="fill"
                            onClick={scoreboardAdd.onAddNewDevice}
                            disabled={scoreboardAdd.data.devices.length === 10}
                        >
                            {translate('addNew')}
                        </Button>
                        <Button
                            className={styles.action_type_update}
                            mod="fill"
                            onClick={scoreboardAdd.updateScoreboardDevices}
                            disabled={scoreboardAdd.isDisableUpdateScoreboardDevices()}
                        >
                            {translate('updateScoreboardDevices')}
                        </Button>
                    </div>
                    {scoreboardAdd.data.devices.map((device, index) => {
                        const labelName = device && device.label ? device.label : null

                        const onChangeDevice = (deviceInfo) => {
                            const devices = scoreboardAdd.data.devices.map((dev, inx) =>
                                index === inx ? { ...dev, ...deviceInfo } : { ...dev }
                            )
                            scoreboardAdd.onChangeDevice(devices)
                        }

                        const onDelete = () => {
                            const devices = scoreboardAdd.data.devices.filter((dev, inx) => index !== inx)
                            scoreboardAdd.onChangeDevice(devices)
                        }

                        const onEndTyping = (value) => {
                            const regex = /^\d{1,2}(\.\d{1,2})?$/g

                            if (value && !value.match(regex)) {
                                dispatch(emitError('scoreboardLabelFormatError'))
                            }
                        }

                        return (
                            <Device
                                key={`device_${labelName}_${index}`}
                                labels={scoreboardAdd.labels}
                                onChange={onChangeDevice}
                                onDelete={onDelete}
                                onEndTyping={onEndTyping}
                                {...device}
                            />
                        )
                    })}
                </div>
            </div>
            {renderMap()}
        </Content>
    )
}

const ScoreboardAddWrapper = (p_) => <ScoreboardsAdd {...p_} />

const exportScoreboardsAdd = checkAvailableModule('scoreboards/add') ? ScoreboardAddWrapper : null

export default exportScoreboardsAdd
