import React from 'react'
import config from '../index'
import translate from 'core/translate'
import CustomListHeader, { ICustomListHeaderProps } from '../../../__header/customList__header'
import helpers from 'core/helpers'
import { api } from 'core/api/ConnectionManager'

export default {
    ...config,
    category: 'chicken',
    type: 'burgers',
    ...helpers.createListeners({
        category: 'chicken',
        type: 'burgers',
        onCreate: 'burgersProductCreated',
        onUpdate: 'burgersProductUpdated',
        onDelete: 'burgersProductDeleted',
    }),
    headerFilters: (p_: ICustomListHeaderProps) => (
        <CustomListHeader
            onAdd={() => p_.onAddFakeLine({ title: '' })}
            title={translate('chicken')}
            type={p_.type}
            onDelete={() => {
                const id = p_.selectedItems.map((selectedItem) => selectedItem.id)
                if (id.length > 0) {
                    api.send('deleteBurgersProduct', { id })
                }
                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            }}
            {...p_}
        />
    ),
}
