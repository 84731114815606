import { addStyles } from 'ethcss'
import { item, IStyles } from '../../theme'
import { colors } from '../../theme'

const initSize = 6
const borderZIndex = 500
const cornerZIndex = 1000
const draggableStyles: IStyles = {
    noDrag: {
        'user-drag': 'none',
        'user-select': 'none',
        '-moz-user-select': 'none',
        '-webkit-user-drag': 'none',
        '-webkit-user-select': 'none',
        '-ms-user-select': 'none',
    },
    wrapper() {
        return {
            ...this.noDrag,
            ...item.abs,
        }
    },
    border() {
        return {
            ...this.noDrag,
            ...item.abs,
            zIndex: borderZIndex,
        }
    },
    borderV() {
        return {
            ...this.border,
            width: initSize,
            top: 0,
            bottom: 0,
            cursor: 'ew-resize',
        }
    },
    borderH() {
        return {
            ...this.border,
            height: initSize,
            left: 0,
            right: 0,
            cursor: 'ns-resize',
        }
    },
    borderRight() {
        return {
            ...this.borderV,
            right: -(initSize / 2),
        }
    },
    borderLeft() {
        return {
            ...this.borderV,
            left: -(initSize / 2),
        }
    },
    borderBottom() {
        return {
            ...this.borderH,
            bottom: -(initSize / 2),
        }
    },
    borderTop() {
        return {
            ...this.borderH,
            top: -(initSize / 2),
        }
    },
    corner() {
        return {
            ...this.noDrag,
            ...item.abs,
            width: initSize,
            height: initSize,
            zIndex: cornerZIndex,
        }
    },
    cornerTR() {
        return {
            ...this.corner,
            top: -(initSize / 2),
            right: -(initSize / 2),
            cursor: 'nesw-resize',
        }
    },
    cornerBR() {
        return {
            ...this.corner,
            bottom: -(initSize / 2),
            right: -(initSize / 2),
            cursor: 'nwse-resize',
        }
    },
    cornerBL() {
        return {
            ...this.corner,
            bottom: -(initSize / 2),
            left: -(initSize / 2),
            cursor: 'nesw-resize',
        }
    },
    cornerTL() {
        return {
            ...this.corner,
            top: -(initSize / 2),
            left: -(initSize / 2),
            cursor: 'nwse-resize',
        }
    },
    square: {
        background: colors.white,

        '&::before': {
            position: 'absolute',
            top: 1,
            left: 1,
            width: initSize - 2,
            height: initSize - 2,
            background: colors.white,
            content: '""',
        },
    },
    square_type_corner: {},
    square_type_side() {
        return {
            ...this.corner,
            pointerEvents: 'none',
        }
    },
    square_color_default: {},
    square_color_contrast: {
        background: colors.white,

        '&::before': {
            background: colors.black,
        },
    },
    square_position_bottom: {
        bottom: -(initSize / 2),
        left: '50%',
        marginLeft: -(initSize / 2),
    },
    square_position_top: {
        top: -(initSize / 2),
        left: '50%',
        marginLeft: -(initSize / 2),
    },
    square_position_right: {
        right: -(initSize / 2),
        top: '50%',
        marginTop: -(initSize / 2),
    },
    square_position_left: {
        left: -(initSize / 2),
        top: '50%',
        marginTop: -(initSize / 2),
    },
}

addStyles(draggableStyles)
export default draggableStyles
