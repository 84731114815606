import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAdvertisingCampaign } from 'core/models/AdvertisingCampaigns'
import { IBroadcast } from 'core/models/Broadcast'
import { IDevice } from 'core/models/DigitalSignage'
import { IFile } from 'core/models/Files'
export interface IReportState {
    devices: IDevice[]
    files: IFile[]
    broadcasts: IBroadcast[]
    advertisingCampaigns: IAdvertisingCampaign[]
    advertisingAds: IAdvertisingCampaign[]
    mailReport: boolean
    devicesReportType: string
    startDate: number | null
    endDate: number | null
    startTime: string | null
    endTime: string | null
}

interface IReportDate {
    startDate: number
    endDate: number
    startTime: string
    endTime: string
}

const initialState: IReportState = {
    devices: [],
    files: [],
    advertisingAds: [],
    broadcasts: [],
    advertisingCampaigns: [],
    mailReport: false,
    devicesReportType: '',
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
}

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        clearData: (state) => {
            state = { ...initialState }
        },
        setDate: (state, action: PayloadAction<IReportDate>) => {
            const { startTime, endTime, startDate, endDate } = action.payload

            state.startDate = startDate
            state.endDate = endDate
            state.startTime = startTime
            state.endTime = endTime
        },
        setFiles: (state, action: PayloadAction<IFile[]>) => {
            state.files = action.payload
        },
        setDevices: (state, action: PayloadAction<IDevice[]>) => {
            state.devices = action.payload
        },
        setAdvertisingAds: (state, action: PayloadAction<IDevice[]>) => {
            state.advertisingAds = action.payload
        },
        setBroadcasts: (state, action: PayloadAction<IBroadcast[]>) => {
            state.broadcasts = action.payload
        },
        setAdvertisingCampaigns: (state, action: PayloadAction<IAdvertisingCampaign[]>) => {
            state.advertisingCampaigns = action.payload
        },
        setStartDate: (state, action: PayloadAction<number>) => {
            state.startDate = action.payload
        },
        setEndDate: (state, action: PayloadAction<number>) => {
            state.endDate = action.payload
        },
        setStartTime: (state, action: PayloadAction<string>) => {
            state.startTime = action.payload
        },
        setEndTime: (state, action: PayloadAction<string>) => {
            state.endTime = action.payload
        },
        setMailReport: (state, action: PayloadAction<boolean>) => {
            state.mailReport = action.payload
        },
        setDevicesReportType: (state, action: PayloadAction<string>) => {
            state.devicesReportType = action.payload
        },
    },
})

const { reducer: reportReducer, actions: reportActions } = reportSlice
export { reportReducer, reportActions }
