import { addStyles } from 'ethcss'
import { grid, indents, IStyles, radiusSizes } from 'theme'
import { defaultThemeStyles } from './Searchbar.theme'

export const styles: IStyles = {
    SearchBar: {
        borderRadius: `${indents.mini}px`,
    },
    SearchBar_type_withShadow: {
        background: `var(--var-module-ui-component-searchbar-style-withShadow-background, ${defaultThemeStyles['withShadow-background']})`,
        //boxShadow: `var(--var-module-ui-component-searchbar-style-withShadow-shadow, ${defaultThemeStyles['withShadow-shadow']})`,
        border: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${defaultThemeStyles['withShadow-border']})`,
    },
    SearchBar_type_none: {
        background: `var(--var-module-ui-component-searchbar-style-none-background, ${defaultThemeStyles['none-background']})`,
        boxShadow: `var(--var-module-ui-component-searchbar-style-none-shadow, ${defaultThemeStyles['none-shadow']})`,
        border: `1px solid var(--var-module-ui-component-searchbar-style-none-border, ${defaultThemeStyles['none-border']})`,
    },
    SearchBar__input: {
        position: 'relative',
        ...grid.w100,
        // padding: `${radiusSizes.mini}px ${indents.big}px ${radiusSizes.mini}px ${indents.miniPlus}px`,
        padding: `${radiusSizes.mini}px ${indents.big}px ${radiusSizes.mini}px 36px`,
        borderRadius: `${radiusSizes.mini}px`,
        border: 'none',
        lineHeight: '16px',
    },
    SearchBar__input_x: {
        paddingLeft: '36px !important',
    },
    SearchBar__icon: {
        position: 'absolute',
        top: `50%`,
        right: `${indents.mini}px`,
        marginTop: -8,
    },
}

addStyles(styles)
