import { addStyles } from 'ethcss'
import { defaultThemeTableStyles } from 'atoms/Table/Table-theme'
import { IStyles } from '../../../../../theme'

const catalogTemplateInstancesCatalogStyles: IStyles = {
    preview: {
        maxHeight: '100px',
        overflow: 'auto',
    },
    tableWrapper: {
        borderTop: `1px solid var(--var-module-ui-component-table-style-borderTop, ${defaultThemeTableStyles['borderTop']})`,

        '& thead th': {
            textAlign: 'left',
            padding: '14px 7px',
        },
        '& thead th:first-child': {
            textAlign: 'center',
            width: '6%',
            padding: '14px',
        },
        '& td:nth-child(2)': {
            textAlign: 'center',
            width: '6%',
            paddingLeft: '14px',
        },
        '& thead tr': {
            backgroundColor: `var(--var-module-ui-component-table-style-thead, ${defaultThemeTableStyles['thead']})`,
        },
        '& tr td': {
            padding: '14px 7px',
        },
        '& tr:nth-child(even)': {
            backgroundColor: `var(--var-module-ui-component-table-style-evenLine, ${defaultThemeTableStyles['evenLine']})`,
        },
        '& input, & textarea': {
            padding: '7px 0',
        },
        '& input + div': {
            pointerEvents: 'none',
        },
        '& textarea': {
            '&::-webkit-scrollbar': {
                borderRadius: 10,
                width: 6,
                background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: 10,
                width: 6,
                background: '#b3b3b3',
            },
        },
        '& .rectangle': {
            width: '50px',
            height: '50px',
            borderRadius: '4px',
            overflow: 'hidden',
        },
        '& td:first-child > div': {
            display: 'flex',
        },
        '& div[class^="tableCell_"], & div[class^="row_"]': {
            display: 'block',
        },
        '& div[class^="item_"]': {
            width: 'auto',
        },
        '& div[class^="itemWrapper_"]': {
            width: '50px',
        },
        overflow: 'visible !important',
    },
    selectDevices: {
        color: `var(--var-module-ui-component-table-style-selected, ${defaultThemeTableStyles['selected']})`,
    },
}

addStyles(catalogTemplateInstancesCatalogStyles)
export default catalogTemplateInstancesCatalogStyles
