import { addStyles } from 'ethcss'
import { grid, item, mediaCSS, IStyles } from 'theme'
import {} from 'blocks.app/config'
import { textSizes } from 'theme/media'

export const styles: IStyles = {
    title: {
        fontSize: textSizes.md,
    },
    inputLabel: {
        ...grid.mb_micro,
        textTransform: 'uppercase',
        display: 'block',
        width: '100%',
        textAlign: 'center',
        fontSize: textSizes.normalPlus,
    },
    contentWrapper: {
        ...grid.row,
        ...item.white,
        ...grid.mt_mini,
        ...grid.p_md,
        ...item.rounded_full_mini,
    },
    content: {
        ...grid.row,
        ...grid.w100,
        ...grid.justify,
        ...grid.mt_mdPlus,
    },
    col: {
        ...grid.w45,
        ...grid.p_mini,
        [mediaCSS.mob]: {
            ...grid.w100,
        },
    },
    inputWrapper: {
        ...grid.mb_md,
    },
    buttons: {
        ...grid.w100,
        ...grid.p_mini,
        ...grid.rowCenter,
        ...item.white,
    },
}

addStyles(styles)
