import { addStyles } from 'ethcss'
import { transitions } from 'blocks.app/config'
import { colors, item, IStyles } from 'theme'
import { hexToRgba } from 'core/utils'

const animation = (color: string) => {
    const rgba = hexToRgba(color, 0.6)

    return {
        '0%': {
            'box-shadow': `0 0 0 0 ${rgba}`,
            backgroundColor: rgba,
            ...item.circle,
        },
        '70%': {
            'box-shadow': '0 0 0 10px transparent',
            backgroundColor: 'transparent',
            ...item.circle,
        },
        '100%': {
            'box-shadow': '0 0 0 0 transparent',
            backgroundColor: 'transparent',
            ...item.circle,
        },
    }
}

const animations = () => {
    let key,
        animations: { [index: string]: any } = {}
    for (key in colors) {
        if (colors.hasOwnProperty(key)) {
            // @ts-ignore
            animations[`${key}Animation`] = animation(colors[key])
        }
    }
    return animations
}

const iconStyles: IStyles = {
    wrapper: {
        ...item.rel,
    },
    normal: {
        ...item.pointer,
    },
    disable: {
        ...item.disabled,
        opacity: 0.5,
    },
    default: {
        ...item.cursorDefault,
    },
    leftRotate: {
        transform: 'rotate(90deg)',
    },
    _animation() {
        return animations()
    },
    ...(() => {
        let animationsClasses: { [index: string]: any } = {}

        for (let key in colors) {
            if (colors.hasOwnProperty(key)) {
                let fullKey = `${key}Animation`

                animationsClasses[fullKey] = function () {
                    return {
                        animation: `${this._animation[fullKey]} ${transitions.normal}ms`,
                    }
                }
            }
        }

        return animationsClasses
    })(),
    iconAnimate: {
        ...item.abs,
        pointerEvents: 'none',
    },
}

addStyles(iconStyles)
export default iconStyles
