import { addStyles } from 'ethcss'
import { grid, IStyles, mediaCSS } from 'theme'

const displayPlayAudioControllStyles: IStyles = {
    wrapper: {
        ...grid.flex,
        ...grid.normalCenter,
        position: 'relative',
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.9)',
    },
    inner: {
        width: '100%',
        padding: '30px',
        flexWrap: 'no-wrap',
        ...grid.flex,
        ...grid.justify,
        ...grid.normalCenter,
        ...grid.wrap,

        [mediaCSS.tabMini]: {
            height: '100%',
            overflow: 'auto',
        },
    },
    buttonStyles: {
        marginBottom: '24px',
    },
}

addStyles(displayPlayAudioControllStyles)

export default displayPlayAudioControllStyles
