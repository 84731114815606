import React from 'react'
import Content from 'blocks.app/content/content'
import Authors from './instancesCatalog_authors/instancesCatalogAuthors'
import Arts from './instancesCatalog_arts/instancesCatalogArts'
import UploadCsv from './instanceCatalog_uploadCsv/instanceCatalogUploadCsv'
import translate from '../../../core/translate'
import styles from './instancesCatalog_catalogizator.jcss'
import { isHorizontalMenu } from 'core/helpers/menu'
import { cn } from 'ethcss'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

const instances = ['authorsList', 'artsList', 'downloadFromFile']

class InstancesCatalogCatalogizator extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            isActive: 'authorsList',
        }
    }

    instanceRender() {
        const isActive = this.state.isActive

        switch (isActive) {
            case 'authorsList':
                return <Authors />
            case 'artsList':
                return <Arts />
            case 'downloadFromFile':
                return <UploadCsv />
            default:
                return null
        }
    }

    render() {
        return (
            <Content title={'catalogizator'}>
                <div className={cn(styles.tabsWrapper, isHorizontalMenu() && styles.tabsWrapper_x)}>
                    {instances.map((instance, index) => {
                        return (
                            <div
                                key={index}
                                className={`${styles.tabBtn} ${
                                    this.state.isActive === instance ? styles.tabActive : null
                                }`}
                                onClick={() => this.setState({ isActive: instance })}
                            >
                                {translate(instance)}
                            </div>
                        )
                    })}
                </div>
                {this.instanceRender()}
            </Content>
        )
    }
}

const exportInstancesCatalogCatalogizator = checkAvailableModule('instancesCatalog/catalogizator')
    ? InstancesCatalogCatalogizator
    : null

export default exportInstancesCatalogCatalogizator
