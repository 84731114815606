import React from 'react'
import Icon from 'blocks.simple/icon/icon'
import { cn } from 'ethcss'
import { indents } from 'blocks.app/config'
import styles from './button.jcss'
import { text as textStyles } from 'theme'
import { grid, item } from 'theme'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from './button.theme'

interface IButtonProps {
    small?: boolean
    type?: any
    disabled?: boolean
    className?: string
    rounded?: string
    mod?: string
    isActive?: boolean
    iconPos?: string
    fullWidth?: boolean
    animation?: boolean
    showIconOnHover?: boolean
    textSize?: string
    indentSize?: string
    icon?: string
    iconColor?: string
    iconSize?: string | number
    centered?: boolean
    children?: React.ReactNode
    preloader?: boolean
    isDisableLayer?: boolean
    onClick?: (value: any) => any
    onIconClick?: () => void
}

const getDefaultProp = (propName: string) => {
    const defaultProps: { [index: string]: any } = {
        mod: 'default',
        fullWidth: false,
        textSize: 'normal',
        indentSize: 'mini',
        iconSize: 14,
        iconPos: 'right',
        type: 'button',
        rounded: 'full_md',
        animation: true,
        preloader: false,
        isDisableLayer: true,
        isActive: false,
    }

    return defaultProps[propName]
}

const Button = (p_: IButtonProps) => {
    const mod = p_.mod ? p_.mod : getDefaultProp('mod')
    const fullWidth = p_.fullWidth ? p_.fullWidth : getDefaultProp('fullWidth')
    const textSize = p_.textSize ? p_.textSize : getDefaultProp('textSize')
    const indentSize = p_.indentSize ? p_.indentSize : getDefaultProp('indentSize')
    const iconSize = p_.iconSize ? p_.iconSize : getDefaultProp('iconSize')
    const iconPos = p_.iconPos ? p_.iconPos : getDefaultProp('iconPos')
    const type = p_.type ? p_.type : getDefaultProp('type')
    const rounded = p_.rounded ? p_.rounded : getDefaultProp('rounded')
    const animation = p_.animation ? p_.animation : getDefaultProp('animation')
    const preloader = p_.preloader ? p_.preloader : getDefaultProp('preloader')
    const isDisableLayer = p_.isDisableLayer ? p_.isDisableLayer : getDefaultProp('isDisableLayer')
    const isActive = p_.isActive ? p_.isActive : getDefaultProp('isActive')

    return (
        <button
            type={type}
            disabled={p_.disabled}
            className={cn(
                styles.wrapper,
                item[`rounded_${rounded}`],
                p_.className,
                styles[`button_type_${mod}`],
                {
                    [styles[`button_type_${mod}_activated`]]: isActive,
                    [item.disabled]: p_.disabled,
                    [styles.disabled]: p_.disabled,
                    [grid.justify]: iconPos === 'right',
                    [grid.rowCenter]: p_.small,
                    [grid.w100]: fullWidth,
                    [styles[`${mod}Animation`]]: animation,
                    [styles.hoverIconWrapper]: p_.showIconOnHover,
                },
                textStyles[textSize],
                item[`indent_${indentSize}`]
            )}
            onClick={p_.onClick}
        >
            {p_.icon && iconPos === 'left' && (
                <div className={cn(styles.icon, { [grid.pr_micro]: p_.children })}>
                    <Icon
                        type={p_.icon}
                        color={
                            p_.iconColor
                                ? p_.iconColor
                                : getThemeStyleValue('ui', 'button', `${mod}-icon`) || defaultThemeStyles[`${mod}-icon`]
                        }
                        size={iconSize}
                        onClick={p_.onIconClick}
                    />
                </div>
            )}
            {!p_.small && (
                <div
                    className={cn(item.ellipsisPure, {
                        [textStyles.centerBlock]: p_.centered,
                        [grid.w100]: fullWidth,
                    })}
                    style={p_.centered && p_.icon && iconSize ? { paddingLeft: +iconSize + indents.micro } : {}}
                >
                    {preloader && (
                        <div className={cn('btn-preloader', grid.row)}>
                            {p_.children}
                            <div className={grid.pl_micro}>
                                <span>.</span>
                                <span>.</span>
                                <span>.</span>
                            </div>
                        </div>
                    )}
                    {!preloader && p_.children}
                </div>
            )}
            {p_.icon && iconPos === 'right' && (
                <div className={cn(styles.icon, { [grid.pl_micro]: p_.children })}>
                    <Icon
                        type={p_.icon}
                        color={
                            p_.iconColor
                                ? p_.iconColor
                                : getThemeStyleValue('ui', 'button', `${mod}-icon`) || defaultThemeStyles[`${mod}-icon`]
                        }
                        size={iconSize}
                        onClick={p_.onIconClick}
                    />
                </div>
            )}
            {p_.disabled && <div className={cn({ [item.disabledLayer]: isDisableLayer })} />}
        </button>
    )
}

export default Button
