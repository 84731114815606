import React from 'react'
import Icon from 'blocks.simple/icon/icon'
import translate from 'core/translate'
import styles from './deviceSettings__noSettings.jcss'
import { Typography } from 'atoms/Typography'

const DeviceSettingsNoSettings = () => (
    <div className={styles.wrapper}>
        <div>
            <Icon type="not_allow" size={40} containerClass={styles.icon} />
            <Typography type="text" className={styles.description}>
                {translate('noPlatformSettings')}
            </Typography>
        </div>
    </div>
)

export default DeviceSettingsNoSettings
