import { addStyles } from 'ethcss'
import { colors, IStyles } from '../../../theme'

const comPortsStyles: IStyles = {
    portCard: {
        position: 'relative',
        border: `1px solid ${colors.black}`,
        borderRadius: 5,
        padding: 20,
        width: '49%',
        margin: '0 0.5%',

        '& > div': {
            marginBottom: 14,
        },
        '&:hover': {
            '& button': {
                opacity: 1,
            },
        },
    },
    buttonsWrapper: {
        marginBottom: 15,
    },
    deleteBtn: {
        position: 'absolute',
        top: 6,
        right: 6,
        opacity: 0,
        transition: 'opacity 0.3s',
        zIndex: 1,
    },
}

addStyles(comPortsStyles)
export default comPortsStyles
