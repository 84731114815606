import React from 'react'
import Modal from '../modal'
import Button from 'blocks.simple/button/button'
import Icon from 'blocks.simple/icon/icon'
import styles from './modal_accept.jcss'
import { cn } from 'ethcss'

interface IModalAccept {
    open: boolean
    onReject?: () => void
    onAccept?: () => void
    onClose: () => void
    rejectLabel?: string | React.ReactNode
    acceptLabel?: string | React.ReactNode
    title?: string | React.ReactNode
    description?: any
    modalInnerClassName?: string | {}
    children?: React.ReactNode
}

const ModalAccept = (p_: IModalAccept) => (
    <Modal open={p_.open} showCloseIcon={false} mod={'clear'} onClose={p_.onClose} transitionName={'fade'}>
        <div className={styles.modalWrapper}>
            <div
                className={cn(styles.modalInner, p_.modalInnerClassName, {
                    [styles.modalSizes]: !p_.modalInnerClassName,
                })}
            >
                {p_.onClose && (
                    <div className={styles.closeIcon}>
                        <Icon type={'close_tiny'} onClick={p_.onClose} />
                    </div>
                )}
                {p_.title && <div className={styles.title}>{p_.title}</div>}
                {p_.description && (
                    <div className={styles.description}>
                        <span dangerouslySetInnerHTML={{ __html: p_.description }} />
                    </div>
                )}
                {p_.children}
                {(p_.rejectLabel || p_.acceptLabel) && (
                    <div className={styles.buttons}>
                        {p_.rejectLabel && (
                            <Button
                                mod={'withBorder'}
                                className={cn(styles.leftButton)}
                                textSize={'mini'}
                                indentSize={'mini'}
                                rounded={'full_normal'}
                                onClick={p_.onReject}
                            >
                                {p_.rejectLabel}
                            </Button>
                        )}
                        {p_.acceptLabel && (
                            <Button
                                mod={'fill'}
                                textSize={'mini'}
                                indentSize={'mini'}
                                rounded={'full_normal'}
                                onClick={p_.onAccept}
                            >
                                {p_.acceptLabel}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    </Modal>
)

export default ModalAccept
