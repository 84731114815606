import React from 'react'
import BroadcastsAddVideowallMethods from './broadcasts_addVideowall.local.methods'
import Content from 'blocks.app/content/content'
import ScrollUp from 'blocks.simple/scrollUp/scrollUp'
import Stick from 'blocks.simple/stick/stick'
import Button from 'blocks.simple/button/button'
import EditableText from 'blocks.simple/editableText/editableText'
import Catalog from 'blocks.app/catalog/catalog'
import { Radio } from 'atoms/Radio'
import Tippy from 'blocks.simple/tippy/tippy'
import { cn } from 'ethcss'
import styles from './broadcasts_addVideowall.jcss'
import { grid } from '../../../theme'
import translate from 'core/translate'
import { routes } from 'features/routes'
import { Typography } from 'atoms/Typography'
import { changeLocation } from 'features/routes'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

const previewHeight = 220

const Preview = () => {
    return (
        <div className={styles.previewImageWrapper}>
            <div className={styles.previewImage} />
        </div>
    )
}

const BroadcastsAddVideowall = () => {
    const { state, setState, onSelectDisplay, submit, validateCols, validateRows } = BroadcastsAddVideowallMethods()

    const s_ = state

    return (
        <Content title={translate('addVideowall')}>
            <div className={styles.panel}>
                <Stick enabled={true}>
                    <div className={styles.buttons}>
                        <Button
                            className={cn(grid.mr_mini)}
                            onClick={() => changeLocation(`/${routes.broadcasts.path}`)}
                        >
                            {translate('cancel')}
                        </Button>
                        <Tippy
                            html={
                                s_.selectedInfo ? (
                                    <div className={cn(styles.tooltipWrapper)}>
                                        <div className={cn(styles.tooltipTitle)}>
                                            {translate('videowallSubmitTooltip__1')}
                                        </div>
                                        <div className={cn(styles.tooltipDescription)}>
                                            <span className={cn(styles.tooltipResolutionSize)}>
                                                {translate('videowallSubmitTooltip__2')} {s_.resolutionWidth} x{' '}
                                                {s_.resolutionHeight}.
                                            </span>
                                            <br />
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: translate('videowallSubmitTooltip__3'),
                                                }}
                                            />
                                            .
                                        </div>
                                    </div>
                                ) : null
                            }
                            title={translate('selectDevice')}
                        >
                            <Button onClick={() => submit()} mod="fill" disabled={!s_.selectedInfo}>
                                {translate('apply')}
                            </Button>
                        </Tippy>
                    </div>
                </Stick>
                <div className={styles.preview}>
                    <Typography className={grid.mb_mdPlus} type="title">
                        {translate('preview')}
                    </Typography>
                    <div className={styles.previewRectangle}>
                        <div
                            style={{
                                width:
                                    ((s_.resolutionWidth * s_.cols) / (s_.resolutionHeight * s_.rows)) * previewHeight,
                                height: `${previewHeight}px`,
                            }}
                        >
                            <div className={styles.previewContent}>
                                {(() => {
                                    const lines = []
                                    const indent = 100 / s_.cols

                                    for (let i = 1; i < s_.cols; i++) {
                                        lines.push(
                                            <div
                                                key={`vLine_${i}`}
                                                style={{ left: `${i * indent}%` }}
                                                className={styles.vLine}
                                            />
                                        )
                                    }

                                    return lines
                                })()}

                                {(() => {
                                    const lines = []
                                    const indent = 100 / s_.rows

                                    for (let i = 1; i < s_.rows; i++) {
                                        lines.push(
                                            <div
                                                key={`hLine_${i}`}
                                                style={{ top: `${i * indent}%` }}
                                                className={styles.hLine}
                                            />
                                        )
                                    }

                                    return lines
                                })()}

                                {!s_.isOneContent &&
                                    (() => {
                                        const rects = []
                                        const width = 100 / s_.cols
                                        const height = 100 / s_.rows

                                        for (let i = 0; i < s_.cols; i++) {
                                            for (let j = 0; j < s_.rows; j++) {
                                                rects.push(
                                                    <div
                                                        key={`rect_${i}_${j}`}
                                                        style={{
                                                            width: `${width}%`,
                                                            height: `${height}%`,
                                                            left: `${i * width}%`,
                                                            top: `${j * height}%`,
                                                            position: 'absolute',
                                                        }}
                                                    >
                                                        <Preview />
                                                    </div>
                                                )
                                            }
                                        }

                                        return rects
                                    })()}

                                {s_.isOneContent && <Preview />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.settings}>
                <div className={grid.rowCenter}>
                    <div className={cn(styles.settingsItem, grid.mr_big)}>
                        <Typography type="title" className={grid.mr_md}>
                            {translate('cols')}
                        </Typography>
                        <div className={grid.space}>
                            <EditableText
                                type={'number'}
                                hideIcon={true}
                                mod={'grey'}
                                text={s_.cols}
                                onChange={(cols: string) => {
                                    if (validateCols(cols)) {
                                        setState((prevState: any) => {
                                            return {
                                                ...prevState,
                                                cols,
                                            }
                                        })
                                    }
                                }}
                                revertEmptyValue={true}
                                validate={validateCols}
                                placeholder={translate('emptyFieldError')}
                            />
                        </div>
                    </div>
                    <div className={styles.settingsItem}>
                        <Typography type="title" className={grid.mr_md}>
                            {translate('rows')}
                        </Typography>
                        <div className={grid.space}>
                            <EditableText
                                type={'number'}
                                hideIcon={true}
                                mod={'grey'}
                                text={s_.rows}
                                onChange={(rows: string) => {
                                    if (validateRows(rows)) {
                                        setState((prevState: any) => {
                                            return {
                                                ...prevState,
                                                rows,
                                            }
                                        })
                                    }
                                }}
                                revertEmptyValue={true}
                                validate={validateRows}
                                placeholder={translate('emptyFieldError')}
                            />
                        </div>
                    </div>
                </div>
                <div className={cn(grid.rowCenter, grid.pt_md)}>
                    <div className={cn(grid.mr_mdPlus)}>
                        <Radio
                            label={translate('isOneContent')}
                            name="isOneContent"
                            selected={s_.isOneContent}
                            onClick={() =>
                                setState((prevState: any) => {
                                    return {
                                        ...prevState,
                                        isOneContent: true,
                                    }
                                })
                            }
                        />
                    </div>
                    <Radio
                        label={translate('isPartContent')}
                        name="isPartContent"
                        selected={!s_.isOneContent}
                        onClick={() =>
                            setState((prevState: any) => {
                                return {
                                    ...prevState,
                                    isOneContent: false,
                                }
                            })
                        }
                    />
                </div>
            </div>
            <div className={styles.devices}>
                <div>
                    <Typography type="title" className={cn(grid.rowCenter, grid.mb_mdPlus)}>
                        {translate('videowallDescription')}
                    </Typography>
                </div>
                <Catalog
                    type="displays"
                    searchbarMod="grey"
                    selectedInfo={s_.selectedInfo}
                    onSelectInfo={onSelectDisplay}
                    disableFolderSelection
                />
            </div>
            <ScrollUp isExistRightToolbar={false} />
        </Content>
    )
}

const exportBroadcastsVideoWall = checkAvailableModule('broadcasts/addVideowall') ? BroadcastsAddVideowall : null

export default exportBroadcastsVideoWall
