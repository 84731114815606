import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../../theme'
import { indents } from 'blocks.app/config'
import { defaultThemeStyles } from '../line.theme'
import { textSizes } from 'theme/media'

export const iconSize = 12

const lineVStyles: IStyles = {
    wrapper: {
        ...grid.colCenter,
        ...item.abs,
        width: indents.mdPlus,
        backgroundColor: `var(--var-module-ui-component-line-style-background, ${defaultThemeStyles.background})`,
        '& *': {
            userSelect: 'none',
        },
    },
    innerContent: {
        ...grid.fullWidth,
        ...item.rel,
    },
    item: {
        ...grid.col,
        ...grid.normalCenter,
        ...grid.fullWidth,
        ...item.rel,
        color: `var(--var-module-ui-component-line-style-textMain, ${defaultThemeStyles.textMain})`,
        borderTop: `1px solid var(--var-module-ui-component-line-style-border, ${defaultThemeStyles.border})`,
    },
    itemLast: {
        '&:last-child': {
            borderBottom: `1px solid var(--var-module-ui-component-line-style-border, ${defaultThemeStyles.border})`,
        },
    },
    itemNumber: {
        ...grid.pt_nano,
        fontSize: textSizes.nano,
    },
    smallSteps: {
        ...grid.col,
        ...item.abs,
        top: 0,
        bottom: 0,
    },
    smallStep: {
        width: 3,
        borderBottom: `1px solid var(--var-module-ui-component-line-style-border, ${defaultThemeStyles.border})`,
    },
    arrow: {
        ...grid.full,
        ...grid.row,
        ...item.rel,
        top: -((iconSize + 2) / 2),
    },
}

addStyles(lineVStyles)
export default lineVStyles
