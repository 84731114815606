import { addStyles } from 'ethcss'
import { IStyles, grid } from 'theme'

export const styles: IStyles = {
    videoChatModalVideo: {
        position: 'relative',
        overflow: 'hidden',
        ...grid.flex,
        ...grid.normalCenter,
        ...grid.center,
        width: `100%!important`,
        height: `auto!important`,
    },
    videoChatModalVideo__media: {
        width: `100%!important`,
        height: `auto!important`,
    },
}

addStyles(styles)
