import React from 'react'
import Authorization from 'pages/authorization/authorization'
import BroadcastsAdd from 'pages/broadcasts/_add/broadcasts_add'
import Dashboard from 'pages/dashboard/dashboard'
import Displays from 'pages/displays/displays'
import DraftDevices from 'pages/draftDevices/draftDevices'
import UpdateDevices from 'pages/updateDevices/updateDevices'
import Files from 'pages/files/files'
import FilesVideoEditor from 'pages/files/__videoEditor/files__videoEditor'
import Broadcasts from 'pages/broadcasts/broadcasts'
import AdvertisingCampaigns from 'pages/advertisingCampaigns/advertisingCampaigns'
import BroadcastsAddVideowall from 'pages/broadcasts/_addVideowall/broadcasts_addVideowall'
import BroadcastsAddAdvanced from 'pages/broadcasts/_addAdvanced/broadcasts_addAdvanced'
import AdvertisingCampaignsAdd from 'pages/advertisingCampaigns/_add/advertisingCampaigns_add'
import AdvertisingCampaignsAdvBlocks from 'pages/advertisingCampaigns/_advBlocks/advertisingCampaigns_advBlocks'
import AdBlocks from 'pages/adBlocks/adBlocks'
import Report from 'pages/report/report'
import ReportTasks from 'pages/report/_tasks/report_tasks'
import Schedule from 'pages/schedule/schedule'
import ScheduleAdd from 'pages/schedule/_add/schedule_add'
import Settings from 'pages/settings/settings'
import Users from 'pages/users'
import Roles from 'pages/users/roles'
import CreateRole from 'pages/users/roles/create'
import History from 'pages/history/history'
import { Scoreboards } from 'pages/scoreboards'
import AddScoreboard from 'pages/scoreboards/__add/scoreboards__add'
import { ScoreboardsAddFolder } from 'pages/scoreboards/organisms/ScoreboardsAddFolder'
import { ScoreboardsSettings } from '../../../pages/scoreboards/organisms/ScoreboardsSettings'
import { VideoChat } from 'pages/videoChat'
import InstancesCatalog from 'pages/instancesCatalog/instancesCatalog'
import InstancesCatalogClassrooms from 'pages/instancesCatalog/_classrooms/instancesCatalog_classrooms'
import InstancesCatalogCourses from 'pages/instancesCatalog/_courses/instancesCatalog_courses'
import InstancesCatalogGrades from 'pages/instancesCatalog/_grades/instancesCatalog_grades'
import InstancesCatalogSubjects from 'pages/instancesCatalog/_subjects/instancesCatalog_subjects'
import InstancesCatalogTeachers from 'pages/instancesCatalog/_teachers/instancesCatalog_teachers'
import InstancesCatalogSnacks from 'pages/instancesCatalog/_snacks/instancesCatalog_snacks'
import InstancesCatalogBurgers from 'pages/instancesCatalog/_burgers/instancesCatalog_burgers'
import InstancesCatalogSmallBurgers from 'pages/instancesCatalog/_smallBurgers/instancesCatalog_smallBurgers'
import InstancesCatalogDrinks from 'pages/instancesCatalog/_drinks/instancesCatalog_drinks'
import InstancesCatalogCombo from 'pages/instancesCatalog/_combo/instancesCatalog_combo'
import InstancesCatalogSamsonite from 'pages/instancesCatalog/_samsonite/instancesCatalog_samsonite'
import InstancesCatalogAskona from 'pages/instancesCatalog/_askona/instancesCatalog_askona'
import InstancesCatalogPitamix from 'pages/instancesCatalog/_pitamix/instancesCatalog_pitamix'
import InstancesCatalogWine from 'pages/instancesCatalog/_wine/instancesCatalog_wine'
import InstancesCatalogProductMenu from 'pages/instancesCatalog/_productMenu/instancesCatalog_productMenu'
import InstancesCatalogMenuProduct from 'pages/instancesCatalog/_menuProduct/instancesCatalog_menuProduct'
import InstancesCatalogCatalogizator from 'pages/instancesCatalog/_catalogizator/instancesCatalog_catalogizator'
import InstancesCatalogLightOfHistory from 'pages/instancesCatalog/_lightOfHistory/instancesCatalog_lightOfHistory'
import InstancesCatalogSurvey from 'pages/instancesCatalog/_survey/instancesCatalog_survey'
import InstancesCatalogLensOfTime from 'pages/instancesCatalog/_lensOfTime/instancesCatalog_lensOfTime'
import InstancesCatalogExhibition from 'pages/instancesCatalog/_exhibition/instancesCatalog_exhibition'
import InstancesCatalogQuizQuestions from 'pages/instancesCatalog/_quizQuestionsCatalog/instancesCatalog_quizQuestionsCatalog'
import { InstancesCatalogProducts } from 'pages/instancesCatalog/products'
import InstancesCatalogSchoolSchedule from 'pages/instancesCatalog/_scheduleClasses/instancesCatalog_scheduleClasses'
import Ui from 'pages/ui/ui'
import { NotFound } from 'pages/notFound'
import { routes } from '../utils'
import { IUseRoutes } from '../routes-types'
import { Distributives } from 'pages/distributives'
import Timetable from 'pages/timetable/timetable'
import TimetableAdd from 'pages/timetable/_add/timetable_add'
import { About } from '../../../pages/about/about'
import { Licenses } from '../../../pages/licenses/Licenses'
import { FirmwareList } from '../../../pages/firmwareList/FirmwareList'
import { WidgetsGallery } from '../../../pages/widgetsGallery/widgetsGallery'
import { NotificationСenter } from '../../../pages/notificationСenter/notificationСenter'
import { IpTv } from '../../../pages/iptv/IpTv'
import { AdvertisementView } from '../../../pages/advertisementView/AdvertisementView'

export const useRoutes = ({ isDev }: IUseRoutes) => {
    const devRoutes = isDev
        ? [
              {
                  path: routes.ui.path,
                  component: Ui,
              },
          ]
        : []

    const authRoutes = [
        {
            path: routes.login.path,
            component: Authorization,
        },
        {
            path: routes.forgetPassword.path,
            component: Authorization,
        },
        {
            path: routes.registration.path,
            component: Authorization,
        },
    ]

    const mainRoutes: any = [
        {
            path: routes.dashboard.path,
            component: Dashboard,
        },
        {
            path: routes.videoChat.path,
            component: VideoChat,
        },
        {
            path: routes.displays.path,
            component: Displays,
        },
        {
            path: routes.draftDevices.path,
            component: DraftDevices,
        },
        {
            path: routes.updateDevices.path,
            component: UpdateDevices,
        },
        {
            path: routes.scoreboards.path,
            component: Scoreboards,
        },
        {
            path: routes.addScoreboardFolder.path,
            component: ScoreboardsAddFolder,
        },
        {
            path: routes.scoreboardsSettings.path,
            component: ScoreboardsSettings,
        },
        {
            path: routes.addScoreboard.path,
            component: AddScoreboard,
        },
        {
            path: routes.editScoreboard.path,
            component: AddScoreboard,
        },
        {
            path: routes.editScoreboardFolder.path,
            component: ScoreboardsAddFolder,
        },
        {
            path: routes.files.path,
            component: Files,
        },
        {
            path: routes.videoEditor.path,
            component: FilesVideoEditor,
        },
        {
            path: routes.broadcasts.path,
            component: Broadcasts,
        },
        {
            path: routes.addVideowall.path,
            component: BroadcastsAddVideowall,
        },
        {
            path: routes.addContentToDevice.path,
            component: BroadcastsAdd,
        },
        {
            path: routes.addAdvancedBroadcast.path,
            component: BroadcastsAddAdvanced,
        },
        {
            path: routes.editBroadcast.path,
            component: BroadcastsAddAdvanced,
        },
        {
            path: routes.advertisingCampaigns.path,
            component: AdvertisingCampaigns,
        },
        {
            path: routes.addAdvertisingCampaign.path,
            component: AdvertisingCampaignsAdd,
        },
        {
            path: routes.editAdvertisingCampaign.path,
            component: AdvertisingCampaignsAdd,
        },
        {
            path: routes.advertisingBlocks.path,
            component: AdvertisingCampaignsAdvBlocks,
        },
        {
            path: routes.addFillersAdBlock.path,
            component: AdvertisingCampaignsAdd,
        },
        {
            path: routes.editFillersAdBlock.path,
            component: AdvertisingCampaignsAdd,
        },
        {
            path: routes.adBlocks.path,
            component: AdBlocks,
        },
        {
            path: routes.reports.path,
            component: Report,
        },
        {
            path: routes.reportTasks.path,
            component: ReportTasks,
        },
        {
            path: routes.schedule.path,
            component: Schedule,
        },
        {
            path: routes.addSchedule.path,
            component: ScheduleAdd,
        },
        {
            path: routes.editSchedule.path,
            component: ScheduleAdd,
        },
        {
            path: routes.settings.path,
            component: Settings,
        },
        {
            path: routes.users.path,
            component: Users,
        },
        {
            path: routes.createRole.path,
            component: CreateRole,
        },
        {
            path: routes.roles.path,
            component: Roles,
        },
        {
            path: routes.editRole.path,
            component: CreateRole,
        },
        {
            path: routes.distributive.path,
            component: Distributives,
        },
        {
            path: routes.timetable.path,
            component: Timetable,
        },
        {
            path: routes.addTimetable.path,
            component: TimetableAdd,
        },
        {
            path: routes.surveyReport.path,
            component: Report,
        },
        {
            path: routes.instancesCatalog.path,
            component: InstancesCatalog,
        },
        {
            path: routes.classroomsCatalog.path,
            component: InstancesCatalogClassrooms,
        },
        {
            path: routes.coursesCatalog.path,
            component: InstancesCatalogCourses,
        },
        {
            path: routes.gradesCatalog.path,
            component: InstancesCatalogGrades,
        },
        {
            path: routes.teachersCatalog.path,
            component: InstancesCatalogTeachers,
        },
        {
            path: routes.subjectsCatalog.path,
            component: InstancesCatalogSubjects,
        },
        {
            path: routes.snacksCatalog.path,
            component: InstancesCatalogSnacks,
        },
        {
            path: routes.burgersCatalog.path,
            component: InstancesCatalogBurgers,
        },
        {
            path: routes.smallBurgersCatalog.path,
            component: InstancesCatalogSmallBurgers,
        },
        {
            path: routes.drinksCatalog.path,
            component: InstancesCatalogDrinks,
        },
        {
            path: routes.comboCatalog.path,
            component: InstancesCatalogCombo,
        },
        {
            path: routes.samsoniteCatalog.path,
            component: InstancesCatalogSamsonite,
        },
        {
            path: routes.askonaCatalog.path,
            component: InstancesCatalogAskona,
        },
        {
            path: routes.pitamixCatalog.path,
            component: InstancesCatalogPitamix,
        },
        {
            path: routes.wineCatalog.path,
            component: InstancesCatalogWine,
        },
        {
            path: routes.productMenuCatalog.path,
            component: InstancesCatalogProductMenu,
        },
        {
            path: routes.artsCatalog.path,
            component: InstancesCatalogCatalogizator,
        },
        {
            path: routes.historyPagesCatalog.path,
            component: InstancesCatalogLightOfHistory,
        },
        {
            path: routes.treeCatalog.path,
            component: InstancesCatalogLensOfTime,
        },
        {
            path: routes.exhibitionsCatalog.path,
            component: InstancesCatalogExhibition,
        },
        {
            path: routes.surveyCatalog.path,
            component: InstancesCatalogSurvey,
        },
        {
            path: routes.menuProductCatalog.path,
            component: InstancesCatalogMenuProduct,
        },
        {
            path: routes.productsCatalog.path,
            component: InstancesCatalogProducts,
        },
        {
            path: routes.quizQuestionsCatalog.path,
            component: InstancesCatalogQuizQuestions,
        },
        {
            path: routes.about.path,
            component: About,
        },
        {
            path: routes.licenses.path,
            component: Licenses,
        },
        {
            path: routes.firmwareList.path,
            component: FirmwareList,
        },
        {
            path: routes.widgetsGallery.path,
            component: WidgetsGallery,
        },
        {
            path: routes.advertisingAds.path,
            component: AdvertisingCampaigns,
        },
        {
            path: routes.addAdvertisingAds.path,
            component: AdvertisingCampaignsAdd,
        },
        {
            path: routes.editAdvertisingAds.path,
            component: AdvertisingCampaignsAdd,
        },
        {
            path: routes.view.path,
            component: AdvertisementView,
        },
        {
            path: routes.schoolSchedule.path,
            component: InstancesCatalogSchoolSchedule,
        },
        {
            path: routes.notificationCenter.path,
            component: NotificationСenter,
        },
        {
            path: routes.ipTv.path,
            component: IpTv,
        },
    ]

    return [
        ...authRoutes,
        ...devRoutes,
        ...mainRoutes,
        {
            component: NotFound,
            path: '*',
        },
    ]
}
