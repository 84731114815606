import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { textSizes } from 'theme/media'
import { IStyles } from 'theme'

const videoEditorContentErrorsStyles: IStyles = {
    wrapper: {
        ...item.abs,
        ...item.whiteColor,
        ...grid.p_md,
        ...grid.row,
        ...grid.normalCenter,
        fontSize: textSizes.md,
        zIndex: 1,
        top: 0,
        left: 0,
    },
    tooltip: {
        textAlign: 'left',
    },
    title: {
        fontSize: textSizes.normalPlus,
    },
    line: {
        ...grid.pb_mini,
        ...grid.pt_mini,
    },
}

addStyles(videoEditorContentErrorsStyles)
export default videoEditorContentErrorsStyles
