import { addStyles } from 'ethcss'
import { mediaCSS } from 'theme/media'
import { grid, IStyles, item } from '../../theme'

const scheduleStyles: IStyles = {
    checkbox: {
        [mediaCSS.tabMini]: {
            ...item.none,
        },
    },
    checkboxWrapper: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
        marginTop: 3,
    },
    fullBlockCheckboxWrapper: {
        ...grid.row,
        ...grid.full,
        ...grid.normalCenter,
        ...grid.center,
    },
    scheduleWrapper: {
        [mediaCSS.tab]: {
            paddingTop: 60,
        },
    },
    wrapperClassName: {
        zIndex: 99,
        background: `var(--var-module-app-component-content-style-background, #FFFFFF)`,
    },
    wrap: {
        whiteSpace: 'normal !important',
    },
}

addStyles(scheduleStyles)
export default scheduleStyles
