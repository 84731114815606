import React from 'react'
import FilterFiles from '../../__filter/_files/catalog__filter_files'
import ItemFiles from '../../__item/_files/catalog__item_files'
import Icon from 'blocks.simple/icon/icon'
// @ts-ignore
import { addFont } from 'addstyles'
import { api } from 'core/api/ConnectionManager'
import styles from './catalog__template_files.jcss'
import helpers from 'core/helpers'
import helpersFiles from 'core/helpers/_files'
import translate from 'core/translate'
import { UploadButton } from 'pages/files/__upload/files__upload'
import { userActions } from 'blocks.app/user/user.state'
import filesize from 'filesize'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { ProgressBar } from 'atoms/ProgressBar'

// @ts-ignore
import omitEmpty from 'omit-empty'
import store from 'core/store'
import { ItemData } from '../../../../core/models/Template'

export default {
    folderName: 'folderId',
    getMethod: 'getFilesAndFolders',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noFiles'),
    emptyIcon: 'not_allow',
    moveItem: (id: number, folderId: number) => api.send('updateFile', { id, folderId }),
    moveFolder: (id: number, folderId: number) => api.send('updateFolder', { id, folderId }),
    multipleMove: (sourceId: number, sourceFolderId: number, parentId: number) => {
        const data = omitEmpty({
            sourceId,
            sourceFolderId,
            parentId,
        })

        return api.send('moveFilesAndFolders', data)
    },
    getDragData: (p_: { item: ItemData }) => {
        return JSON.stringify(helpersFiles.getFileItem(p_.item))
    },
    addItemButton: (self: { query: { type: string }; filter: { folderId?: number | null } }) => {
        if (self.query.type !== 'text' && self.query.type !== 'link' && self.filter && self.filter.folderId) {
            return <UploadButton />
        }
    },
    headerButton: () => <UploadButton mod="fill" title={translate('upload')} withoutFolder={true} />,
    headerFilters: FilterFiles,
    settingsPrefixes: 'files',
    tile: ItemFiles,
    cols: [8, 10],
    wrapperClassName: styles.wrapper,
    cellClassName: styles.cellWrapper,
    mediaCellClassName: styles.mediaCellClassName,
    panelClassName: styles.panelWrapper,
    viewOptions: () => [
        {
            id: 'tile',
            name: translate('tile'),
            icon: 'tile',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
        {
            id: 'list',
            name: translate('list'),
            icon: 'list',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
    ],
    sortOptions: () => [
        { id: '-createdAt', name: translate('sortCreatedAt') },
        { id: 'name', name: translate('sortName') },
        { id: '-size', name: translate('sortSize') },
    ],
    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean) => {
        const item = { ...dataItem }
        const empty = { fileType: 'empty' }

        if (dataItem.isDirectory) {
            dataItem.content = [empty, empty, empty, empty]
        }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    getBackendModel: (item: ItemData) => {
        if (!item.isDirectory) {
            return api.send('getFile', { id: item.id }, { hideLoader: true })
        } else {
            return api.send('getFolder', { folderId: item.id }, { hideLoader: true })
        }
    },
    isEqual: (item: ItemData, prevItem: ItemData) => helpersFiles.isEqual({ item, prevItem }),
    onCreateListeners: [
        (cb: any, clearList: string[]) => api.addObserver('folderCreated', cb, clearList),
        (cb: any, clearList: string[]) => api.addObserver('fileCreated', cb, clearList),
    ],
    onUpdateListeners: [
        (cb: any, clearList: string[]) => api.addObserver('folderUpdated', cb, clearList),
        (cb: any, clearList: string[]) => api.addObserver('fileUpdated', cb, clearList),
    ],
    onDeleteListeners: [
        (callback: any, clearList: string[]) =>
            api.addObserver('folderDeleted', ({ id }: { id: number }) => callback({ id, isDirectory: 1 }), clearList),
        (callback: any, clearList: string[]) =>
            api.addObserver('fileDeleted', ({ id }: { id: number }) => callback({ id, isDirectory: 0 }), clearList),
    ],
    isFolder: (item: ItemData) => item.isDirectory,
    tableWrapper: styles.tableWrapper,
    fields: () => [
        { id: 'type', name: translate('type'), hide: true },
        { id: 'fileType', name: translate('type'), hide: true },
        { id: 'name', name: translate('name') },
        { id: 'size', name: translate('size') },
        { id: 'duration', name: translate('durationFull') },
        { id: 'createdAt', name: translate('createdAt') },
        { id: 'updatedAt', name: translate('updatedAt') },
    ],
    init(callback: any) {
        // @ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)

        const state = store.getState()
        const userState = state.user

        if (!userState.isFontsLoaded) {
            api.send('getFonts', { withCommon: true }).then((res: any) => {
                store.dispatch(userActions.setFont())

                res.forEach(function (font: { fileUrl: string; name: string }) {
                    if (font.fileUrl) {
                        addFont(font.fileUrl, `font-family: ${font.name}; font-weight: normal`)
                    }
                })
            })
        }
    },
    getBreadcrumbs(folderId: number) {
        const initBreadcrumb = { name: 'content', id: undefined }

        if (!folderId) {
            // @ts-ignore
            this.setState({ breadcrumbs: [initBreadcrumb] })
            return
        }

        api.send('getFolderBreadcrumbs', { folderId }).then((breadcrumbs: any) => {
            breadcrumbs.reverse()
            breadcrumbs.unshift(initBreadcrumb)
            // @ts-ignore
            this.setState({ breadcrumbs })
        })
    },
    moveOnUpdate({ prev, next }: { prev: { folderId: number }; next: { folderId: number } }) {
        // @ts-ignore
        return !this.query.fileType && prev.folderId !== next.folderId
    },
    perPage: 60,
    customElementInSelect(item: { id: number; fileType: string; dataLandscapeStatus: string }, active: boolean) {
        if (item.fileType === 'video' && item.dataLandscapeStatus === 'error') {
            return (
                <Icon
                    className={styles.rotateIconWrapper}
                    type={'rotate'}
                    onClick={function (e) {
                        e.stopPropagation()
                        api.send('retryRotateVideo', { sourceId: item.id })
                    }}
                    size={25}
                    color={active ? 'blue' : 'darkCloud'}
                />
            )
        }
    },
    list: {
        size: (p_: { type: string; progress: number; size: number }) => {
            if (p_.type === 'file') {
                return <div>{p_.progress}%</div>
            }
            if (p_.size >= 0 && p_.size !== null) {
                return <span>{filesize(p_.size, { round: 0 })}</span>
            }
        },
        name: (item: ItemData, active: boolean, p_: any, nested: number) => {
            let iconType = 'files'

            if (item.hasSmartFolderRulesQ) {
                iconType = 'smartFiles'
            }

            if (item.fileType) {
                iconType = helpersFiles.getFileIconName(item)
            }

            if (!item.fileType && item.type) {
                iconType = item.type
            }

            return (
                <div
                    className={styles.name}
                    style={{
                        marginLeft: 40 * nested,
                    }}
                >
                    {item.type === 'file' ? (
                        <div>
                            <ProgressBar value={item.progress} strokeWidth={12} className={styles.progressBarStyle} />
                        </div>
                    ) : (
                        <Icon className={styles.icon} type={iconType} />
                    )}

                    <div style={helpersFiles.convertStyles(item.style, { withoutStyles: ['fontSize'] })}>
                        {helpers.strip_html_tags(item.name)}
                    </div>
                </div>
            )
        },
        createdAt: ({ createdAt }: { createdAt: string }) => helpers.getFormattedLocalDate(createdAt, 'DD/MM/YYYY'),
        duration: (listItem: { duration: string }) => {
            if (!listItem.duration) {
                return '-'
            } else {
                const value = (listItem.duration = String(parseInt(listItem.duration, 10)))
                return helpersFiles.convertSeconds(+value)
            }
        },
        updatedAt: ({ updatedAt }: { updatedAt: string }) => helpers.getFormattedLocalDate(updatedAt, 'DD/MM/YYYY'),
    },
}
