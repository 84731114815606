import { addStyles } from 'ethcss'
import { colors, IStyles } from 'theme'
import grid from 'theme/grid/styles'
import item from 'theme/item/styles'

export const styles: IStyles = {
    Slider: {
        ...grid.row,
        ...grid.fullWidth,
    },
    Slider__animation: {
        ...grid.spaceWidth,
        ...grid.row,
        overflow: 'visible',
    },
    Slider__animation_active: {
        overflow: 'hidden !important',
    },
    Slider__content: {
        ...grid.spaceWidth,
        ...grid.noWrap,
        overflow: 'visible',
    },
    Slider__content_type_row: {
        ...grid.row,
    },
    Slider__content_type_col: {
        ...grid.col,
    },
    Slider__slide: {
        ...grid.rowCenter,
        ...grid.noWrap,
        ...grid.fullWidth,
    },
    Slider__arrow: {
        ...grid.rowCenter,
        ...item.pointer,
        width: 42,
        background: colors.greyModal,
    },
    Slider__arrow_disabled: {
        cursor: 'not-allowed !important',
    },
}

addStyles(styles)
