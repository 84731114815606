import { addStyles } from 'ethcss'
import { grid, IStyles, item } from 'theme'
import { defaultThemeStyles } from './Breadcrumbs.theme'
import { indents, textSizes } from 'theme/media'

export const styles: IStyles = {
    Breadcrumbs: {},
    Breadcrumbs__content: {
        // padding: `0 ${indents.mdPlus}px 0`,
        padding: '0 36px 0',
    },
    Breadcrumbs__content_x: {
        //padding: '0 46px 0',
    },
    Breadcrumbs__scroll: {
        ...item.overX,
        ...grid.row,
        ...grid.noWrap,
        maxWidth: '100%',
    },
    Breadcrumbs__item: {
        ...grid.row,
        ...grid.noWrap,
    },
    Breadcrumbs__arrow: {
        ...grid.rowCenter,
        ...grid.noWrap,
        ...grid.pl_md,
        ...grid.pr_md,
        ...item.pointer,
        textDecoration: 'none',
        color: `var(--var-module-catalog-component-breadcrumbs-style-textMain, ${defaultThemeStyles.textMain})`,
        '&:hover': {
            color: `var(--var-module-catalog-component-breadcrumbs-style-hover, ${defaultThemeStyles.hover})`,
            '& .home_icon': {
                color: `var(--var-module-catalog-component-breadcrumbs-style-hover, ${defaultThemeStyles.hover})!important`,
            },
            '& .group_files_icon': {
                color: `var(--var-module-catalog-component-breadcrumbs-style-hover, ${defaultThemeStyles.hover})!important`,
            },
        },
        cursor: 'default',
        '& .breadcrumbs__arrow': {
            cursor: 'default',
        },
    },
}

addStyles(styles)
