import React from 'react'
import moment from 'moment'
import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'

class TimetableMethods extends React.Component {
    state = {
        selectedDate: moment().toDate(),
        events: [],
    }
    navigate(selectedDate) {
        this.getLessons(selectedDate)
        this.setState({ selectedDate })
    }
    componentDidMount() {
        const s_ = this.state

        this.getLessons(s_.selectedDate)
    }
    convertDateTime = (date) => {
        const disposition = this.getDisposition()

        const startDate = moment(date).startOf(disposition).utc().format('YYYY-MM-DD HH:mm:ss')
        let endDate = moment(date).endOf(disposition).format('YYYY-MM-DD')
        endDate = moment(`${endDate} 23:59:59`).utc().format('YYYY-MM-DD HH:mm:ss')

        return {
            startDate,
            endDate,
        }
    }
    getLessons(date) {
        const data = this.convertDateTime(date)
        api.send('getLessonsInDateRange', { ...data }).then((events) => {
            events.forEach((element) => {
                element.start = moment(element.startTime).local().toDate()
                element.end = moment(element.endTime).local().toDate()
            })
            this.setState({ events })
        })
    }
    onChangeDisposition = (selected) => {
        const { updateUserSettings } = this.props
        const { selectedDate } = this.state

        updateUserSettings({ data: { view: { timetable: selected.id }, isSaveSettings: true } })

        this.getLessons(selectedDate)
    }

    getDisposition = () => {
        const { user } = this.props
        const viewParams = helpers.getViewParamsByUser('timetable', user)

        return viewParams.disposition
    }
}

export default TimetableMethods
