import { addStyles } from 'ethcss'
import { grid, IStyles, radiusSizes } from 'theme'
import { defaultThemeTableStyles } from '../Table-theme'

export const styles: IStyles = {
    TableRow: {},
    TableRow_type_selected: {
        backgroundColor: `var(--var-module-ui-component-table-style-selected, ${defaultThemeTableStyles['selected']})`,
        color: `var(--var-module-ui-component-table-style-selected-text, ${defaultThemeTableStyles['selected-text']})`,
    },
}

addStyles(styles)
