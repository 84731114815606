import { useEffect, useState } from 'react'
import helpers from 'core/helpers'
import { api } from 'core/api/ConnectionManager'
import { getURLSearchParamsByLocation } from 'features/routes/utils'

interface IFilesState {
    selectedInfo: {
        id: number
        src: string
        fileType: string
        name: string
        folderId: number
        data: string
        isDirectory: number
    } | null
    selectedItems: { id: number; isDirectory: boolean }[]
    disableDragUploads: boolean
    editWebApp: boolean
}

const FilesMethods = (p_: any) => {
    const [state, setState] = useState<IFilesState>({
        selectedInfo: null,
        selectedItems: [],
        disableDragUploads: false,
        editWebApp: false,
    })

    let _mounted = true
    let cache: any = {
        created: [],
        updated: [],
        deleted: [],
    }
    const listenersId: string[] = []

    const createCachedDebounce = (data: any, listenerType: string, cb: any) => {
        cache[listenerType].push(data)

        helpers.debounce(
            () => {
                if (!_mounted) {
                    return
                }
                const cachedData = [...cache[listenerType]]
                cache[listenerType] = []

                cb(cachedData)
            },
            { id: `source_tag_${listenerType}`, limit: 600 }
        )
    }

    useEffect(() => {
        const { getFileSourceTags, location } = p_
        const query = helpers.getQuery(getURLSearchParamsByLocation(location), 'files')

        getFileSourceTags()
        initTagsListeners()

        const method = getAddContentsMethods()[query.type]

        if (method && query[`${query.type}Id`]) {
            method()
        }

        return () => {
            const { clearFilesState } = p_
            clearFilesState()

            _mounted = false
            listenersId.forEach((id) => api.removeObserver(id))
        }
    }, [])

    const onSourceTagCreated = (res: any) => onCreatedHandler(res)

    const onSourceTagUpdated = (res: any) => onUpdatedHandler(res)

    const onSourceTagDeleted = (res: any) => onDeletedHandler(res)

    const getAddContentsMethods = (type?: string) => {
        const { activateAddLink, activateAddTable, activateAddText } = p_

        switch (type) {
            case 'table':
                return activateAddTable
            case 'link':
                return activateAddLink
            default:
                return activateAddText
        }
    }

    const getEditContentsMethods = (type?: string) => {
        const { activateEditLink, activateEditTable, activateEditText } = p_

        switch (type) {
            case 'table':
                return activateEditTable
            case 'link':
                return activateEditLink
            default:
                return activateEditText
        }
    }

    const initTagsListeners = () => {
        api.addObserver('sourceTagCreated', onSourceTagCreated, listenersId)
        api.addObserver('sourceTagUpdated', onSourceTagUpdated, listenersId)
        api.addObserver('sourceTagDeleted', onSourceTagDeleted, listenersId)
    }

    const onCreatedHandler = (response: any) => {
        const { setTags, tags } = p_

        createCachedDebounce(response, 'created', (data: any) => {
            const updatedTags = [...tags, ...data]

            setTags(updatedTags)
        })
    }
    const onUpdatedHandler = (response: any) => {
        const { setTags, tags } = p_

        createCachedDebounce(response, 'updated', (data: any) => {
            const updatedTags = tags.map((tag: { id: string }) => {
                const updatedTag = data.find((cacheUpdatedTag: { id: string }) => tag.id === cacheUpdatedTag.id)

                if (updatedTag) {
                    return updatedTag
                }

                return tag
            })

            setTags(updatedTags)
        })
    }
    const onDeletedHandler = (response: any) => {
        const { setTags, tags } = p_

        createCachedDebounce(response, 'deleted', (data: any) => {
            const cachedDeletedTags = data.map((tag: { id: string }) => tag.id)
            const updatedTags = tags.filter((tag: { id: string }) => !cachedDeletedTags.includes(tag.id))

            setTags(updatedTags)
        })
    }

    const onGetFoldersOnMultiple = () => {
        const { setFolders } = p_
        const s_ = state
        const sourceId: any = []
        const sourceFolderId: any = []

        s_.selectedItems.forEach((source: { id: number; isDirectory: boolean }) => {
            const isDirectory = source.isDirectory

            isDirectory ? sourceFolderId.push(source.id) : sourceId.push(source.id)
        })

        const data = {
            sourceId,
            sourceFolderId,
            nestedQ: false,
        }

        api.send('getAvailableFoldersFiles', data, { hideLoader: true }).then((availableFolders: any) => {
            const folders = helpers.addParentKeyIdForTree(availableFolders, 'folderId')
            setFolders(folders)
        })
    }

    const onSelectInfo = (selectedInfo: any) => {
        const { setToolbar, setFolders } = p_

        setState((prevState: any) => {
            return { ...prevState, selectedInfo }
        })
        setToolbar('right')

        if (!selectedInfo) {
            return
        }

        const data: {
            sourceId: number
            nestedQ: boolean
            sourceFolderId?: number
        } = {
            sourceId: selectedInfo.id,
            nestedQ: false,
        }

        const isDirectory = selectedInfo.isDirectory

        if (isDirectory) {
            delete data.sourceId
            data.sourceFolderId = selectedInfo.id
        }

        api.send('getAvailableFoldersFiles', data, { hideLoader: true }).then((availableFolders: any) => {
            const folders = helpers.addParentKeyIdForTree(availableFolders, 'folderId')
            setFolders(folders)
        })
    }

    const editContent = (file: { id: number; fileType: string; type: string }) => {
        if (!helpers.isAvailable({ key: 'files', action: 'update' })) {
            return
        }

        if (file.fileType === 'web_app') {
            setState((prevState: any) => {
                return { ...prevState, editWebApp: true }
            })
            return
        }

        const method = getEditContentsMethods()[file.type]

        if (method) {
            method(file.id)
        }
    }
    const onCloseEditingWidget = () => {
        setState((prevState: any) => {
            return { ...prevState, editWebApp: false }
        })
    }

    return {
        state,
        setState,
        onSelectInfo,
        onGetFoldersOnMultiple,
        editContent,
        onCloseEditingWidget,
    }
}

export default FilesMethods
