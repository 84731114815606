import React from 'react'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import styles from './catalog__template_menuProduct.jcss'
import { CatalogActionBar } from 'organisms/Catalog/ActionBar/ActionBar-view'
import { ActionBarRequestHeaderType, ActionBarActionType } from 'core/models/ActionBar'
import grid from 'blocks.simple/grid/grid.jcss'
import { cn } from 'ethcss'
import EditableDropdown from 'blocks.simple/editableDropdown/editableDropdown'
import { ItemData } from '../../../../core/models/Template'
import { IActionBarComponent } from '../../../../organisms/Catalog/ActionBar/ActionBar-types'

const getActionList = (p_: any) => {
    return [
        {
            order: 1,
            type: ActionBarActionType.DELETE,
            cb: () => {
                const productCodes = p_.selectedItems.map(
                    (selectedItem: { productCode: string }) => selectedItem.productCode
                )

                if (productCodes.length > 0) {
                    api.send('deleteMenuProducts', { productCode: productCodes })
                }

                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            },
        },
        {
            order: 2,
            type: ActionBarActionType.UPLOAD,
            cb: () => {},
            options: {
                upload: `v2/price-list/upload/menu-info`,
                acceptFiles: `.csv`,
            },
        },
    ]
}

export default {
    getMethod: 'getMenuProducts',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('emptyMenuProduct'),
    emptyDescription: () => translate('noDescription'),
    emptyIcon: 'not_allow',
    disableDrag: true,
    addItemButton: () => null,
    settingsPrefixes: 'menuProduct',
    sortOptions: () => [
        { id: 'name', name: translate('name') },
        { id: 'productCode', name: translate('productCode') },
    ],
    headerFilters: (p_: IActionBarComponent) => {
        return (
            <CatalogActionBar
                title={translate('menuProductCatalog')}
                requestHeaderType={ActionBarRequestHeaderType.WITH_AUTH}
                type="menuProduct"
                actions={getActionList(p_)}
                {...p_}
            />
        )
    },
    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    getBackendModel: (item: ItemData) => {
        return api.send('getMenuProducts', { query: item.name }, { hideLoader: true })
    },
    tableWrapper: styles.tableWrapper,
    isEqual: (firstItem: ItemData, secondItem: ItemData) => {
        if (firstItem.productCode && secondItem.productCode) {
            return firstItem.productCode === secondItem.productCode
        }

        return firstItem === secondItem
    },
    onCreateListeners: [
        (callback: any, clearList: string[]) => {
            return api.addObserver(
                'menuProductCreated',
                (createdMenuProduct: object) => {
                    callback({ ...createdMenuProduct })
                },
                clearList
            )
        },
    ],
    onUpdateListeners: [
        (callback: any, clearList: string[]) => {
            return api.addObserver(
                'menuProductUpdated',
                (updatedMenuProduct: object[]) => {
                    callback({ ...updatedMenuProduct[0] })
                },
                clearList
            )
        },
    ],
    onDeleteListeners: [
        (callback: any, clearList: string[]) =>
            api.addObserver(
                'menuProductsDeleted',
                (deletedMenuProducts: { productCode: object[] }) => {
                    deletedMenuProducts.productCode.forEach((productCode) => {
                        callback({ productCode })
                    })
                },
                clearList
            ),
    ],
    isFolder: () => {},
    fields: () => [
        { id: 'name', name: translate('name') },
        { id: 'productCode', name: translate('productCode') },
        { id: 'productMenuId', name: translate('productMenuName') },
    ],
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        productMenuId: (p_: { productMenuId: number; productCode: string }, active: boolean, listProps: any) => (
            <div className={cn(grid.fullWidth)}>
                <EditableDropdown
                    placeholder={translate('elementNotSelected')}
                    options={listProps.additionalData.productMenus}
                    value={p_.productMenuId}
                    valueField={'productMenuId'}
                    labelField={'name'}
                    onChange={(selected: { productMenuId: number }) => {
                        if (!p_.productCode) {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, productMenuId: selected.productMenuId })
                            return
                        }

                        api.send('updateMenuProduct', [
                            {
                                productCode: p_.productCode,
                                productMenuId: selected.productMenuId,
                            },
                        ])
                    }}
                />
            </div>
        ),
    },
    hideActiveRowColor: true,
    disableUpdateUserSettings: true,
    disableListScroll: true,
    fixedColWidth: true,
}
