import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../../theme'
import { indents } from 'blocks.app/config'
import { defaultThemeStyles } from '../line.theme'
import { textSizes } from 'theme/media'

export const iconSize = 12

const lineHStyles: IStyles = {
    wrapper: {
        ...grid.rowCenter,
        ...item.abs,
        height: indents.md,
        backgroundColor: `var(--var-module-ui-component-line-style-background, ${defaultThemeStyles.background})`,
        '& *': {
            userSelect: 'none',
        },
    },
    item: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.fullHeight,
        ...item.rel,
        color: `var(--var-module-ui-component-line-style-textMain, ${defaultThemeStyles.textMain})`,
        borderLeft: `1px solid var(--var-module-ui-component-line-style-border, ${defaultThemeStyles.border})`,
    },
    itemLast: {
        '&:last-child': {
            borderRight: `1px solid var(--var-module-ui-component-line-style-border, ${defaultThemeStyles.border})`,
        },
    },
    innerContent: {
        ...grid.row,
        ...grid.fullHeight,
        ...item.rel,
    },
    itemNumber: {
        fontSize: textSizes.nano,
        ...grid.pl_nano,
    },
    smallSteps: {
        ...grid.row,
        ...item.abs,
        left: 0,
        right: 0,
    },
    smallStep: {
        height: 3,
        borderRight: `1px solid var(--var-module-ui-component-line-style-border, ${defaultThemeStyles.border})`,
    },
    arrow: {
        ...grid.full,
        ...grid.col,
        ...item.rel,
        left: -(iconSize / 2),
    },
}

addStyles(lineHStyles)
export default lineHStyles
