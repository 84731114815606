import React from 'react'
import AdvertisingCampaignsAdvBlockMethods, {
    Device,
    Block,
    ContentItem,
    IAdvBlockProps,
    IAdvBlockState,
    SelectedBlock,
} from './advertisingCampaigns__advBlock.local.methods'
import Icon from 'blocks.simple/icon/icon'
import Collapse from 'blocks.simple/collapse/collapse'
import LineV from 'blocks.simple/line/_v/line_v'
import { Item as FileThumbnail } from 'blocks.app/catalog/__item/_files/catalog__item_files'
import EditText from 'blocks.simple/editText/editText'
import ModalAccept from 'blocks.simple/modal/_accept/modal_accept'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './advertisingCampaigns__advBlock.jcss'
import { grid, item } from 'theme'
import { text as textStyles } from 'theme'
import { CircleButton } from 'atoms/CircleButton'

interface IDeviceComponent {
    device: Device
    length?: number
    isGroup?: boolean
    open?: boolean
    onRemoveBlock: () => void
}
const DeviceComponent = (p_: IDeviceComponent) => (
    <div className={styles.device}>
        <div className={cn(grid.rowCenter, grid.noWrap)}>
            <div className={cn({ [styles.displayIconWrapper]: !p_.isGroup })}>
                <Icon type={p_.isGroup ? 'files' : 'display'} color="blackLight" size={p_.isGroup ? 20 : 18} />
            </div>
        </div>
        <div className={cn(grid.rowCenter, grid.spaceWidth)}>
            <span className={cn(styles.deviceTitle)}>
                {p_.isGroup ? `${translate('group')} (${p_.length})` : p_.device.name}
            </span>
            {p_.isGroup && <Icon size={12} type={p_.open ? 'arrow_up' : 'arrow_down'} />}
        </div>
        <div>
            <CircleButton
                onClick={function (e) {
                    e.stopPropagation()
                    p_.onRemoveBlock()
                }}
                type={'delete'}
            />
        </div>
    </div>
)

const AdvertisingCampaignsAdvBlockListItem = (p_: {
    contentItem: ContentItem
    contentIndex: number
    index: number
}) => {
    const itemWrapperStyles = {
        height: p_.contentItem.durationPx,
    }

    return (
        <div style={itemWrapperStyles} className={styles.contentItem}>
            <div className={styles.contentItemWrapper}>
                <div className={styles.contentItemPreview}>
                    <FileThumbnail item={p_.contentItem.source} />
                </div>
                <div className={styles.adListItemTitle}>
                    <div>
                        <div>{p_.contentItem.source.name}</div>
                        <div className={styles.adListItemDuration}>
                            {p_.contentItem.duration / 1000} {translate('sec')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const AdvertisingCampaignsAdvBlockList = (p_: { selectedBlock: SelectedBlock }) => (
    <div className={styles.list}>
        {p_.selectedBlock.content.map((contentItem: ContentItem, index: number) => (
            <AdvertisingCampaignsAdvBlockListItem
                index={contentItem.order}
                key={`advBlock_${index}_${contentItem.source.id}`}
                contentIndex={index}
                contentItem={contentItem}
            />
        ))}
    </div>
)

const AdvertisingCampaignsAdvBlock = (p_: IAdvBlockProps) => {
    const { s_, setState, selectBlock, onChangeDate, onRemoveBlock } = AdvertisingCampaignsAdvBlockMethods(p_)
    const { selectedBlock, isGroupSelected, selectedDevice, duration } = s_

    return (
        <div className={styles.wrapper}>
            <div className={styles.devices}>
                <div className={cn(styles.title, grid.pl_mini)}>{translate('devices')}</div>
                {p_.blocks.map((block: Block, blockIndex: number) => {
                    const isGroup = block.devices.length > 1
                    const overIndex = `${blockIndex}_title`
                    const isActive =
                        s_.over[overIndex] ||
                        (s_.selectedBlockIndex === blockIndex && s_.selectedDeviceIndex === (isGroup ? null : 0))

                    return (
                        <Collapse
                            showArrow={false}
                            onClick={() => selectBlock(blockIndex, isGroup ? null : 0)}
                            disabled={!isGroup}
                            key={`block_${blockIndex}`}
                            toggleIconOptions={{
                                containerClass: cn(styles.circleButton),
                                size: 10,
                            }}
                            title={(p_: { open: boolean }) => (
                                <DeviceComponent
                                    open={p_.open}
                                    isGroup={isGroup}
                                    device={block.devices[0]}
                                    length={block.devices.length}
                                    onRemoveBlock={() =>
                                        setState((prevState: IAdvBlockState) => {
                                            return {
                                                ...prevState,
                                                deleteData: { blockIndex },
                                            }
                                        })
                                    }
                                />
                            )}
                            titleClassName={cn(styles.deviceWrapper, {
                                [styles.deviceWrapperOver]: isActive,
                            })}
                            contentPadding={false}
                            contentClassName={cn(styles.collapseContent)}
                            onMouseOver={() =>
                                setState((prevState: IAdvBlockState) => {
                                    return {
                                        ...prevState,
                                        over: { [overIndex]: true },
                                    }
                                })
                            }
                            onMouseOut={() =>
                                setState((prevState: IAdvBlockState) => {
                                    return {
                                        ...prevState,
                                        over: { [overIndex]: false },
                                    }
                                })
                            }
                        >
                            {isGroup &&
                                block.devices.map((device: Device, deviceIndex: number) => {
                                    const deviceOverIndex = `${blockIndex}_${deviceIndex}`
                                    const isDeviceActive =
                                        s_.over[deviceOverIndex] ||
                                        (s_.selectedBlockIndex === blockIndex && s_.selectedDeviceIndex === deviceIndex)

                                    return (
                                        <div
                                            key={`block_${blockIndex}__device_${deviceIndex}`}
                                            onMouseOver={() =>
                                                setState((prevState: IAdvBlockState) => {
                                                    return {
                                                        ...prevState,
                                                        over: {
                                                            [deviceOverIndex]: true,
                                                        },
                                                    }
                                                })
                                            }
                                            onMouseOut={() =>
                                                setState((prevState: IAdvBlockState) => {
                                                    return {
                                                        ...prevState,
                                                        over: {
                                                            [deviceOverIndex]: false,
                                                        },
                                                    }
                                                })
                                            }
                                            className={cn(styles.subDevice, {
                                                [styles.deviceWrapperOver]: isDeviceActive,
                                            })}
                                            onClick={() => selectBlock(blockIndex, deviceIndex)}
                                        >
                                            <DeviceComponent
                                                device={device}
                                                onRemoveBlock={() =>
                                                    setState((prevState: IAdvBlockState) => {
                                                        return {
                                                            ...prevState,
                                                            deleteData: {
                                                                blockIndex,
                                                                deviceIndex,
                                                            },
                                                        }
                                                    })
                                                }
                                            />
                                        </div>
                                    )
                                })}
                        </Collapse>
                    )
                })}
            </div>
            <div className={styles.content}>
                <div className={styles.title}>
                    <div className={cn(grid.space, grid.rowCenter, grid.noWrap)}>
                        <div className={cn(grid.pl_mini, grid.spaceWidth, grid.row)}>
                            {selectedBlock && (
                                <span className={item.ellipsis}>
                                    {translate('advBlock')}
                                    &nbsp;
                                    <span className={textStyles.darkCloud}>
                                        ({translate('advBlockUsed')}{' '}
                                        {isGroupSelected
                                            ? `${selectedBlock.devices.length} ${translate('devices__')}`
                                            : `${translate('devices_')} ${selectedDevice && selectedDevice.name}`}
                                        )
                                    </span>
                                </span>
                            )}
                            {!selectedBlock && (
                                <span className={item.ellipsis}>{translate('advBlockNotSelected')}</span>
                            )}
                        </div>
                    </div>
                    <div className={styles.date}>
                        <div className={grid.w45}>
                            <EditText
                                className={textStyles.center}
                                type="date"
                                dateValue={p_.date}
                                onChange={onChangeDate}
                                textSize="mini"
                                indentSize="mini"
                                rounded="full_mini"
                                label={translate('date')}
                            />
                        </div>
                    </div>
                    <div className={styles.contentTitle}>
                        <div className={styles.sec}>{translate('sec')}</div>
                        <div className={grid.ml_mdPlus}>{translate('content')}</div>
                    </div>
                </div>
                {selectedBlock && (
                    <div className={styles.contentInner}>
                        <div style={{ height: s_.scaleHeight }} className={styles.scale}>
                            <LineV scaleLength={duration / 1000} indents="none" scaleStep={5} showLastStep />
                        </div>
                        <AdvertisingCampaignsAdvBlockList selectedBlock={selectedBlock} />
                    </div>
                )}
            </div>
            <ModalAccept
                open={!!s_.deleteData}
                onReject={() =>
                    setState((prevState: IAdvBlockState) => {
                        return {
                            ...prevState,
                            deleteData: null,
                        }
                    })
                }
                onAccept={onRemoveBlock}
                onClose={() =>
                    setState((prevState: IAdvBlockState) => {
                        return {
                            ...prevState,
                            deleteData: null,
                        }
                    })
                }
                title={translate('deleteAdvBlock')}
                rejectLabel={translate('no')}
                acceptLabel={translate('yes')}
            />
        </div>
    )
}

export default AdvertisingCampaignsAdvBlock
