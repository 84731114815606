import { useTypedSelector } from 'core/store/hooks'
import React, { FC } from 'react'
import { HeadProps } from './Head-types'

export const Head: FC<HeadProps> = () => {
    const title = useTypedSelector<string[]>((state) => state.head.title)
    const appTitle = [...title, 'CMS - ' + `${window.spconfig.brandName}`]

    document.title = appTitle.join(' | ')

    return null
}
