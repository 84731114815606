import { addStyles } from 'ethcss'
import { grid, text, IStyles } from 'theme'
import { defaultThemeStyles } from './AudioTrack-theme'

export const audioTrackListStyles: IStyles = {
    AudioTrackList__title: {
        color: `var(--var-module-audio-component-trackList-style-text, ${defaultThemeStyles.text})`,
        ...text.md,
        ...grid.pb_mini,
        span: {
            ...grid.pb_micro,
            display: 'block',
            borderBottom: `1px solid var(--var-module-audio-component-trackList-style-border, ${defaultThemeStyles.border})`,
        },
    },

    AudioTrackList: {
        padding: '24px',
        width: '100%',
        maxHeight: '96vh',
        background: '#303030',
        borderRadius: '15px',
        overflowY: 'auto',
        overflowX: 'hidden',
    },

    AudioTrackList__hide: {
        display: 'none',
    },
}

addStyles(audioTrackListStyles)
