import React from 'react'
import Item from '../__item/searchbar__item'
import { cn } from 'ethcss'
import styles from './autocomplete.jcss'
import translate from 'core/translate'

type Element = { id?: number; __type?: string; address: string; lat?: number; lng?: number }

const historyLength = 15
const history: any = {
    digitalSignageGroup: [],
    digitalSignage: [],
    broadcast: [],
}

const allHistory: { id?: number; __type?: string; address?: string }[] = []
const Autocomplete = (p_: {
    displays: Element[]
    onSelect: (element: Element, __type: string) => void
    query: {
        query: string
        name: string
    }
    isOpen: boolean
    places: { address: string }[]
    searchMap: boolean
    digitalSignageGroup: any
}) => {
    const getSearchStatus = (p_: { query: { name: string } }) => {
        const dataSource = p_.query.name ? p_ : history

        return {
            digitalSignageGroup: dataSource.digitalSignageGroup && dataSource.digitalSignageGroup.length > 0,
            digitalSignage: dataSource.digitalSignage && dataSource.digitalSignage.length > 0,
            broadcast: dataSource.broadcast && dataSource.broadcast.length > 0,
        }
    }

    const onSelect = (element: Element, type: string) => {
        saveHistory(element, type)
        if (p_.onSelect) {
            p_.onSelect(element, type)
        }
    }

    const saveHistory = (element: Element, __type: string) => {
        const findElement = allHistory.find(
            (historyItem) => historyItem.id === element.id && historyItem.__type === __type
        )

        if (!findElement) {
            allHistory.unshift({ ...element, __type })
            allHistory.slice(0, historyLength - 1)
        }

        history.digitalSignage = []
        history.digitalSignageGroup = []
        history.broadcast = []
        allHistory.forEach((historyItem) => {
            if (historyItem.__type && history[historyItem.__type]) {
                history[historyItem.__type].push(historyItem)
            }
        })
    }

    const searchStatus = getSearchStatus(p_)
    const isOpenSearch = p_.isOpen && (p_.query.name || allHistory.length > 0)
    const dataSource: any = p_.query.name ? p_ : history

    if (p_.searchMap) {
        const filteredDisplays = p_.displays.filter((item) => item.lat && item.lng).length > 0
        return (
            <div className={cn(styles.search, p_.isOpen && p_.query.query ? styles.opened : '')}>
                {p_.isOpen && p_.query.query && (
                    <div className={styles.select}>
                        {window.spconfig.map.type !== 'open-street-map' ? (
                            <div>
                                <div className={styles.title}>{translate('addresses')}</div>
                                <div className={styles.list}>
                                    {p_.places.length > 0 &&
                                        p_.places.map((item, index) => (
                                            <Item
                                                name={item.address}
                                                onClick={() => onSelect(item, 'places')}
                                                key={`places__item_${index}`}
                                                type="places"
                                            />
                                        ))}
                                    {p_.places.length === 0 && (
                                        <div className={styles.empty}>{translate('noAddresses')}</div>
                                    )}
                                </div>
                            </div>
                        ) : null}
                        <div>
                            <div className={styles.title}>{translate('devices')}</div>
                            <div className={styles.list}>
                                {filteredDisplays &&
                                    p_.displays.map((item, index) => {
                                        if (item.lng && item.lat) {
                                            return (
                                                <Item
                                                    {...item}
                                                    onClick={() => onSelect(item, 'displays')}
                                                    key={`digitalSignage__item_${index}`}
                                                    type="digitalSignage"
                                                />
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                {!filteredDisplays && (
                                    <div className={styles.empty}>{translate('devicesNotFound')}</div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    } else {
        return (
            <div className={cn(styles.search, isOpenSearch ? styles.opened : '')}>
                {isOpenSearch && (
                    <div className={styles.select}>
                        <div>
                            <div className={styles.title}>{translate('groups')}</div>
                            <div className={styles.list}>
                                {searchStatus.digitalSignageGroup &&
                                    dataSource.digitalSignageGroup.map((searchItem: any, index: number) => (
                                        <Item
                                            {...searchItem}
                                            onClick={() => onSelect(searchItem, 'digitalSignageGroup')}
                                            type="digitalSignageGroup"
                                            key={`digitalSignageGroup__item_${index}`}
                                        />
                                    ))}
                                {!searchStatus.digitalSignageGroup && (
                                    <div className={styles.empty}>{translate('groupsNotFound')}</div>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className={styles.title}>{translate('devices')}</div>
                            <div className={styles.list}>
                                {searchStatus.digitalSignage &&
                                    dataSource.digitalSignage.map((searchItem: any, index: number) => (
                                        <Item
                                            {...searchItem}
                                            onClick={() => onSelect(searchItem, 'digitalSignage')}
                                            key={`digitalSignage__item_${index}`}
                                            type="digitalSignage"
                                        />
                                    ))}
                                {!searchStatus.digitalSignage && (
                                    <div className={styles.empty}>{translate('devicesNotFound')}</div>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className={styles.title}>{translate('broadcasts')}</div>
                            <div className={styles.list}>
                                {searchStatus.broadcast &&
                                    dataSource.broadcast.map((searchItem: any, index: number) => (
                                        <Item
                                            {...searchItem}
                                            onClick={() => onSelect(searchItem, 'broadcast')}
                                            key={`broadcast__item_${index}`}
                                            type="broadcast"
                                        />
                                    ))}
                                {!searchStatus.broadcast && (
                                    <div className={styles.empty}>{translate('broadcastsNotFound')}</div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

Autocomplete.defaultProps = {
    searchMap: false,
}

export default Autocomplete
