import React, { useEffect, useState } from 'react'
import Content from '../../../blocks.app/content/content'
import translate from '../../../core/translate'
import { Email, IEmail } from './__email/Email'
import Button from '../../../blocks.simple/button/button'
import styles from './notificationEmails.jcss'
import Checkbox from '../../../blocks.simple/checkbox/checkbox'
import Icon from '../../../blocks.simple/icon/icon'
import Modal from '../../../blocks.simple/modal/modal'
import moment from 'moment'
import { api } from '../../../core/api/ConnectionManager'

export const NotificationEmails = () => {
    const [emails, setEmails] = useState<IEmail[]>([])
    const [selectedEmails, setSelectedEmails] = useState<number[]>([])
    const [confirmDeleteEmail, setConfirmDeleteEmail] = useState<number[] | null>(null)
    const [modalInfo, setModalInfo] = useState<IEmail | null>(null)

    const getEmailStatus = (status: string) => {
        switch (status) {
            case 'sent':
                return translate('mailSent')
            case 'in_delivery':
                return translate('mailInDelivery')
            case 'delivered':
                return translate('mailDelivered')
            case 'error':
                return translate('mailError')
            default:
                return ''
        }
    }
    const onSelect = (id: number, selected: boolean) => {
        setSelectedEmails(selected ? [...selectedEmails, id] : selectedEmails.filter((email) => email !== id))
    }
    const onSelectAll = (selected: boolean) => {
        if (selected) {
            setSelectedEmails(emails.map((email) => email.id))
        } else {
            setSelectedEmails([])
        }
    }
    const onCloseConfirm = () => {
        setConfirmDeleteEmail(null)
    }
    const onDelete = (id: number) => {
        setConfirmDeleteEmail([id])
    }
    const onDeleteAll = () => {
        setConfirmDeleteEmail(emails.map((email) => email.id))
    }
    const onConfirmDelete = () => {
        if (!confirmDeleteEmail) return

        api.send('deleteEmailNotifications', { id: confirmDeleteEmail })

        setSelectedEmails([])

        onCloseConfirm()
    }
    const onClose = () => {
        setModalInfo(null)
    }
    const onDetails = (email: IEmail) => {
        setModalInfo(email)
    }

    useEffect(() => {
        api.send('getEmailNotifications', {}).then((emails: any) => {
            if (emails) {
                setEmails(emails)
            }
        })
    }, [])
    useEffect(() => {
        const listenersId: string[] = []

        api.addObserver(
            'emailNotificationCreated',
            (email: any) => {
                if (!email) return

                setEmails([...email, ...emails])
            },
            listenersId
        )
        api.addObserver(
            'emailNotificationUpdated',
            (email: any) => {
                if (!email) return

                setEmails(
                    emails.map((item) => {
                        if (item.id === email.id) {
                            return email
                        }

                        return item
                    })
                )
            },
            listenersId
        )
        api.addObserver(
            'emailNotificationDeleted',
            (ids: any) => {
                if (!ids) return

                setEmails(emails.filter((email) => !ids.includes(email.id)))
            },
            listenersId
        )

        return () => {
            listenersId.forEach((id: any) => api.removeObserver(id))
        }
    }, [emails])

    return (
        <Content title={translate('notificationEmails')}>
            <div className={styles.header}>
                <Checkbox
                    onClick={(selected: boolean) => {
                        onSelectAll(selected)
                    }}
                    checked={!!selectedEmails.length && selectedEmails.length === emails.length}
                />
                <Icon
                    type={'delete'}
                    className={styles.deleteAll}
                    size={17}
                    tooltip={{ title: translate('delete') }}
                    disabled={!selectedEmails.length}
                    onClick={onDeleteAll}
                />
            </div>
            <div>
                {emails.map((email) => (
                    <Email
                        key={email.id}
                        email={email}
                        selected={selectedEmails.includes(email.id)}
                        onSelect={onSelect}
                        onDelete={onDelete}
                        onDetails={onDetails}
                        getStatus={getEmailStatus}
                    />
                ))}
            </div>
            {confirmDeleteEmail && (
                <div className={styles.deleteConfirm}>
                    <div className={styles.deleteConfirmInner}>
                        <div className={styles.deleteConfirmMessage}>
                            {confirmDeleteEmail.length > 1
                                ? translate('mailsNotificationDeleteConfirm')
                                : translate('mailNotificationDeleteConfirm')}
                        </div>
                        <div className={styles.deleteConfirmButtons}>
                            <Button mod="withBorder" className={styles.button} onClick={onCloseConfirm}>
                                {translate('cancel')}
                            </Button>
                            <Button mod={'fill'} className={styles.button} onClick={onConfirmDelete}>
                                {translate('delete')}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {modalInfo && (
                <Modal open={true} mod={'white'} transitionName={'fade'} onClose={onClose}>
                    <div className={styles.modal}>
                        <div className={styles.modalStatus}>
                            <img src={`images/notification-center/email/${modalInfo.status}.svg`} />
                            <b>{getEmailStatus(modalInfo.status)}</b>
                        </div>
                        <div className={styles.modalContent}>
                            <div className={styles.modalField}>
                                <b>{translate('lastChangeDate')}: </b>
                                {moment(modalInfo.statusUpdatedAt).format('HH:mm DD.MM.YYYY')}
                            </div>
                            <div className={styles.modalField}>
                                <b>{translate('mailFrom')}: </b>
                                {modalInfo.from}
                            </div>
                            <div className={styles.modalField}>
                                <b>{translate('mailTo')}: </b>
                                {modalInfo.to}
                            </div>
                            <div className={styles.modalField}>
                                <b>{translate('mailSubject')}: </b>
                                {modalInfo.message.subject}
                            </div>
                            <div
                                className={styles.modalBody}
                                dangerouslySetInnerHTML={{
                                    __html: modalInfo.message.html,
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </Content>
    )
}
