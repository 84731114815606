import { addStyles } from 'ethcss'
import {defaultThemeStyles} from '../FlowDiagram-theme'

const advancedLinkSegment = {
    path: {
        stroke: `var(--var-module-editor-component-flowDiagram-style-path, ${defaultThemeStyles.path})`
    },
}

addStyles(advancedLinkSegment)
export default advancedLinkSegment
