import { addStyles } from 'ethcss'
import { indents, IStyles } from 'theme'
import { defaultThemeMapLocationStyles } from './MapLocationList-theme'

const MAP_LOCATION_LIST_HEIGHT = 250

export const styles: IStyles = {
    mapLocationList: {
        width: '100%',
        backgroundColor: `var(--var-module-map-component-mapLocationList-style-background, ${defaultThemeMapLocationStyles.background})`,
        boxShadow: `var(--var-module-map-component-mapLocationList-style-shadow, ${defaultThemeMapLocationStyles.shadow})`,
    },
    mapLocationList__content: {
        maxHeight: MAP_LOCATION_LIST_HEIGHT,
        overflowY: 'auto',
    },
    mapLocationList__item: {
        padding: `${indents.mini}px ${indents.md}px`,
        lineHeight: 1.4,
        color: `var(--var-module-map-component-mapLocationList-style-text, ${defaultThemeMapLocationStyles.text})`,
        '&:hover': {
            backgroundColor: `var(--var-module-map-component-mapLocationList-style-hoverBackground, ${defaultThemeMapLocationStyles.hoverBackground})`,
            color: `var(--var-module-map-component-mapLocationList-style-hoverText, ${defaultThemeMapLocationStyles.hoverText})`,
        },
        cursor: 'pointer',
    },
}

addStyles(styles)
