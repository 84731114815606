import PropTypes from 'prop-types'
import React from 'react'
import CatalogDisplaysMapMethods from './catalog__map_displays.local.methods'

import { connect } from 'react-redux'

import styles from './catalog__map_displays.jcss'
import { withRouter } from 'react-router'
import { MapCreator } from 'features/map/controllers/MapCreator'

const mapFactory = MapCreator.getInstance().getMapFactory()
const CreatedMap = mapFactory.createMap()
const mapApi = mapFactory.createMapApi()

class CatalogDisplaysMap extends CatalogDisplaysMapMethods {
    markerClick = (item) => {
        this.props.onSelectCluster([item])
        this.props.onSelectInfoByItem(item)
    }

    clusterClick = (list) => {
        this.props.onSelectCluster(list)
    }

    onMapChange = (center, distance) => {
        this.onChange(center, distance)
        this.props.centerMap(center)
    }

    render() {
        const p_ = this.props
        const center = p_.center || p_.userLocation

        return (
            <div className={styles.map}>
                <CreatedMap
                    onMarkerClick={this.markerClick}
                    onClusterClick={this.clusterClick}
                    onChange={this.onChange}
                    markers={p_.list}
                    activeMarkerList={p_.selectedItems}
                    activeMarker={p_.selectedInfo}
                    zoom={p_.zoom}
                    markerColor={p_.markerColor}
                    activeMarkerColor={p_.activeMarkerColor}
                    center={center}
                    mapApi={mapApi}
                />
            </div>
        )
    }
}
CatalogDisplaysMap.propTypes = {
    onSelect: PropTypes.func,
    onSelectCluster: PropTypes.func,
    center: PropTypes.object,
    list: PropTypes.array,
    userLocation: PropTypes.object,
    onClick: PropTypes.func,
    onSelectInfo: PropTypes.func,
    onChange: PropTypes.func,
    onGetList: PropTypes.func,
}
CatalogDisplaysMap.defaultProps = {
    onGetList: () => {},
    onSelectInfo: () => {},
    onSelectCluster: () => {},
}

export default connect((state, route) => {
    return {
        userLocation: state.user.data.location,
    }
})(withRouter(CatalogDisplaysMap))
