import { addStyles } from 'ethcss'
import { IStyles, colors } from '../../../theme'

const filterInnerStyles: IStyles = {
    filterInner: {
        position: 'absolute',
        backgroundColor: colors.white,
        filter: 'drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25))',
        borderRadius: '10px',
        width: '100%',
        height: '400px',
        overflow: 'hidden',
    },
    searchBlock: {
        borderBottom: `1px solid ${colors.greyLight}`,
    },
    searchInput: {
        width: '100%',
        border: 'none',
        height: '40px',
        padding: '0 20px',
    },
    filterValues: {
        padding: '20px',
        maxHeight: '274px',
        overflow: 'auto',
    },
    checkbox: {
        position: 'relative',
        marginBottom: '10px',
    },
    label: {
        overflow: 'hidden',
        padding: '2px 0 0 34px',
        height: '24px',
    },
    tooltip: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingBottom: '1px',
    },
    multiSelect: {
        display: 'flex',
        justifyContent: 'flex-end',
        fontSize: '12px',
        padding: '20px 20px 0 20px',
    },
    selectBtn: {
        textDecoration: 'underline',
        marginLeft: '20px',
        cursor: 'pointer',

        '&:hover': {
            textDecoration: 'none',
        },
    },
    applyBtn: {
        position: 'absolute',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
    },
}

addStyles(filterInnerStyles)

export default filterInnerStyles
