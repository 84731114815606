import { addStyles } from 'ethcss'
import { IStyles, grid } from 'theme'
import { indents, radiusSizes, textSizes } from 'theme/media'
import { colors } from 'theme/colors'
import { defaultThemeStyles } from '../../../videoChat.theme'

const CONTENT_PADDING_TOP = 130
const CONTENT_PADDING_SIDE = 127
const LOCAL_VIDEO_WIDTH = 257
const LOCAL_VIDEO_HEIGHT = 155
const BUTTON_WIDTH = 250

export const styles: IStyles = {
    videoChatModalContent: {
        ...grid.auto,
        padding: `${CONTENT_PADDING_TOP}px ${CONTENT_PADDING_SIDE}px 0`,
    },
    videoChatModalContent__content: {
        ...grid.flex,
        ...grid.col,
        height: `calc(100vh - ${CONTENT_PADDING_TOP}px)`,
    },
    videoChatModalContent__close: {
        position: 'absolute',
        top: 44,
        right: 44,
    },
    videoChatModalContent__header: {
        padding: indents.mini,
        fontSize: 18,
        textAlign: 'center',
        color: colors.white,
    },
    videoChatModalContent__video: {
        ...grid.flex,
        ...grid.center,
        ...grid.normalCenter,
        borderRadius: radiusSizes.mini,
        background: `var(--var-module-videoChat-component-videoChat-style-video, ${defaultThemeStyles.video})`,
        height: `calc(100% - ${LOCAL_VIDEO_HEIGHT}px - 60px)`,
        overflow: 'hidden',
    },
    videoChatModalContent__footer: {
        ...grid.flex,
        ...grid.row,
        padding: `${indents.mdPlus}px 0`,
    },
    videoChatModalContent__localVideo: {
        ...grid.flex,
        ...grid.center,
        ...grid.normalCenter,
        width: LOCAL_VIDEO_WIDTH,
        height: LOCAL_VIDEO_HEIGHT,
        borderRadius: radiusSizes.mini,
        background: `var(--var-module-videoChat-component-videoChat-style-localVideo, ${defaultThemeStyles.localVideo})`,
        overflow: 'hidden',
    },
    videoChatModalContent__text: {
        fontSize: textSizes.mdPlus,
        color: colors.white,
        textAlign: 'center',
    },
    videoChatModalContent__actions: {
        ...grid.flex,
        ...grid.center,
        ...grid.normalCenter,
        ...grid.auto,
        paddingRight: `${LOCAL_VIDEO_WIDTH}px`,
    },
    videoChatModalContent__button: {
        ...grid.flex,
        ...grid.center,
        padding: indents.miniPlus,
        width: BUTTON_WIDTH,
        fontSize: textSizes.normal,
    },
    videoChatModalContent__button_type_default: {},
    videoChatModalContent__button_type_calling: {},
    videoChatModalContent__button_disabled: {
        opacity: 0.5,
        cursor: 'not-allowed',
    },
    videoChatModalContent__image: {
        position: 'absolute',
        top: -4,
        left: -4,
        width: 90,
        height: 90,
    },
    videoChatModalContent__circle: {
        position: 'relative',
        width: 82,
        height: 82,
        border: `1px solid #454242`,
        borderRadius: `50%`,
    },
    videoChatModalContent__loader: {
        ...grid.flex,
        ...grid.col,
        ...grid.center,
        ...grid.normalCenter,
    },
    videoChatModalContent__status: {
        paddingTop: indents.md,
        fontSize: 24,
        color: colors.white,
    },
}

addStyles(styles)
