import React, { useEffect, useRef, useState } from 'react'
import styles from './editor__animationSettings.jcss'
import 'animate.css'
import ReactPlayer from 'react-player'
import moment from 'moment'
import { cn } from 'ethcss'
import translate from '../../../core/translate'
import item from '../../../blocks.simple/item/item.jcss'
import grid from '../../../blocks.simple/grid/grid.jcss'
import PreviewWebApp from '../../preview/__webApp/preview__webApp'

const defaultDuration = 300
let styleObj = {
    animationFillMode: 'forwards',
}
let startTime = 0
export const Preview = (p_) => {
    const reactPlayer = useRef(null)
    const contentAreaRef = useRef(null)
    const contentItem = p_.contentItem
    const animation = p_.animation
    const contentType = contentItem.source.data.type
    const [animationClassName, setAnimationClassName] = useState('')
    const [duration, setDuration] = useState(defaultDuration)

    useEffect(() => {
        if (!p_.useAnimation) {
            setAnimationClassName('')

            return
        }

        const interval = setInterval(() => {
            let relTime = moment() - startTime

            if (relTime > contentItem.duration + 1000000) {
                relTime = 0
                setAnimationClassName('')
            }

            const animationInIsExist = animation.in && animation.in.name && animation.in.name !== 'noAnimate'
            const animationOutIsExist = animation.out && animation.out.name && animation.out.name !== 'noAnimate'

            if (relTime > contentItem.duration || !startTime) {
                startTime = moment()
            }

            if (relTime > 0 && animationInIsExist && relTime < animation.in.duration) {
                if (animationClassName !== animation.in.name) {
                    setAnimationClassName(animation.in.name)
                    setDuration(animation.in.duration ? animation.in.duration : defaultDuration)
                    styleObj.animationFillMode = 'forwards'
                }

                return
            }

            if (animationOutIsExist && relTime > contentItem.duration - animation.out.duration) {
                if (styleObj.animationIterationCount) {
                    delete styleObj.animationIterationCount
                }
                styleObj.animationFillMode = 'forwards'
                if (animationClassName !== animation.out.name) {
                    setAnimationClassName(animation.out.name)
                    setDuration(animation.out.duration ? animation.out.duration : defaultDuration)
                }

                return
            }
            if (animation.state) {
                if (styleObj.animationFillMode) {
                    delete styleObj.animationFillMode
                }

                styleObj.animationIterationCount = 'infinite'

                if (!animationInIsExist && !animationOutIsExist) {
                    setDuration(animation.state.duration ? animation.state.duration : defaultDuration)
                    setAnimationClassName(animation.state.name)

                    return
                }

                if (animationInIsExist && relTime >= animation.in.duration) {
                    if (animationOutIsExist && relTime < contentItem.duration - animation.out.duration) {
                        setDuration(animation.state.duration ? animation.state.duration : defaultDuration)
                        setAnimationClassName(animation.state.name)

                        return
                    }

                    setDuration(animation.state.duration ? animation.state.duration : defaultDuration)
                    setAnimationClassName(animation.state.name)

                    return
                }

                setDuration(animation.state.duration ? animation.state.duration : defaultDuration)
                setAnimationClassName(animation.state.name)
            }

            setAnimationClassName('')
        }, 20)

        return () => {
            startTime = 0
            clearInterval(interval)
        }
    }, [animation, p_.useAnimation])
    useEffect(() => {
        if (reactPlayer.current) {
            reactPlayer.current.seekTo(0)
        }
    }, [animation, p_.useAnimation])

    const renderContent = () => {
        switch (contentType) {
            case 'video':
                return (
                    <ReactPlayer
                        ref={reactPlayer}
                        className={styles.content}
                        width="100%"
                        height="100%"
                        url={contentItem.source.data.fileURL}
                        playing={true}
                        onEnded={() => {}}
                        onProgress={() => {}}
                        onReady={() => {}}
                        onError={() => {}}
                        progressInterval={250}
                        volume={0}
                        loop={true}
                    />
                )
            case 'image':
                return (
                    <img
                        className={styles.content}
                        src={contentItem.source.data.fileUrl}
                        style={{
                            height: '100%',
                        }}
                    />
                )
            case 'web_app':
                return (
                    <div className={cn(item.rel, grid.full)} style={p_.style}>
                        <PreviewWebApp
                            item={{ id: contentItem.source.id }}
                            indents={'none'}
                            width={1920}
                            height={1080}
                        />
                    </div>
                )
            case 'link':
                return <iframe src={contentItem.source.name} width="100%" height="100%" title={p_.name} />
            default:
                return null
        }
    }

    return (
        <div className={styles.previewWrapper}>
            <div className={styles.previewLabel}>{translate('preview')}</div>
            <div ref={contentAreaRef} className={styles.preview}>
                <div
                    className={cn(styles.contentArea, `animate__${animationClassName}`)}
                    style={
                        animationClassName
                            ? {
                                  animationDuration: duration + 'ms',
                                  ...styleObj,
                              }
                            : null
                    }
                >
                    {renderContent()}
                </div>
            </div>
        </div>
    )
}
