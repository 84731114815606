import { addStyles } from 'ethcss'
import { defaultThemeStyles } from '../AppMenu.theme'
import { IStyles, grid, item, text, mediaCSS, colors } from 'theme'
import 'blocks.simple/animation/animation.css'

export const styles: IStyles = {
    menuItem__wrapper: {
        position: 'relative',
        ...grid.col,
    },
    menuItem__wrapper_x: {
        position: 'relative',
    },
    menuItem__icon: {
        ...item.transitionErase,
        ...grid.w20,
        svg: {
            fill: `var(--var-module-app-component-menu-style-textMain, ${defaultThemeStyles.textMain})`,
        },
    },
    menuItem__icon_active: {
        svg: {
            fill: `var(--var-module-app-component-menu-style-active, ${defaultThemeStyles.textMain})`,
        },
    },
    notificationsCount: {
        position: 'absolute',
        borderRadius: '50%',
        width: 15,
        height: 15,
        padding: 3,
        zIndex: 99,
        fontWeight: 600,
        left: 11,
        bottom: 8,
        fontSize: 8,
        background: colors.notificationRed,
        color: colors.white,
        textAlign: 'center',
    },
    menuItem: {
        ...grid.p_md,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
        textTransform: 'uppercase',
        cursor: 'pointer',
        '& .menuIcon': {
            ...item.transitionErase,
        },
        color: `var(--var-module-app-component-menu-style-textMain, ${defaultThemeStyles.textMain})`,
        '&:hover': {
            color: `var(--var-module-app-component-menu-style-hover, ${defaultThemeStyles.hover})`,
            svg: {
                fill: `var(--var-module-app-component-menu-style-active, ${defaultThemeStyles.textMain})`,
            },
        },
    },
    betaLabel: {
        position: 'absolute',
        left: '54%',
        top: -17,
        fontSize: 10,
        textTransform: 'none',

        [mediaCSS.desktopXL]: {
            left: 'auto',
            right: -10,
        },
    },
    menuItem_x: {
        padding: '12px !important',
    },
    menuItem_type_active: {
        color: `var(--var-module-app-component-menu-style-active, ${defaultThemeStyles.active})`,
    },
    menuItem_type_active_x: {
        fontWeight: 700,
        border: '1px solid',
        borderColor: `var(--var-module-app-component-menu-style-active, ${defaultThemeStyles.active})`,
        borderRadius: '5px',
        width: '100%',
        '&:before': {
            content: '""',
        },
    },
    menuItem_type_default: {
        cursor: 'default!important',
    },
    menuItem__content: {
        ...grid.rowCenter,
        ...grid.w100,
        position: 'relative',
    },
    menuItem__icon_minimize: {
        ...item.transitionErase,
    },
    menuItem_type_animate: {
        animation: 'shake 2s infinite',
    },
    menuItem__text_type_with_transition: {
        ...item.transitionErase,
    },
    menuItem__text: {
        ...grid.w80,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        opacity: 1,
    },
    menuItem__text_x: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',
        overflow: 'visible !important',
        lineHeight: 'normal',
        ...text.center,
    },
    menuItem__triangle: {
        border: '5px solid transparent',
        borderTop: `6px solid var(--var-module-app-component-menu-style-textMain, ${defaultThemeStyles.textMain})`,
        borderRadius: '3px',
        marginLeft: '4px',
    },
    menuItem__triangle_active: {
        borderTop: `6px solid var(--var-module-app-component-menu-style-active, ${defaultThemeStyles.active})`,
    },
    menuItem__text_type_hidden: {
        opacity: 0,
        width: 0,
    },
}

addStyles(styles)
