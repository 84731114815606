import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import styles from './catalog__template_lensOfTime.jcss'
import { DropdownSelect } from '../../../../pages/instancesCatalog/_lensOfTime/lensOfTime_viewModules'
import EditableText from 'blocks.simple/editableText/editableText'
import React from 'react'
import CatalogSelectContent from '../../_select/_content/catalog_select_content'
import Icon from 'blocks.simple/icon/icon'
import Button from 'blocks.simple/button/button'
import { cn } from 'ethcss'
import grid from '../../../../blocks.simple/grid/grid.jcss'
import { text as textStyles } from '../../../../theme'
import { TextEditor } from 'molecules/TextEditor'
import helpers from 'core/helpers'

interface IRenderTreeProps {
    create?: any
    p_: any
    removeChild?: (id: number) => void
    setChildren?: (child: any) => void
    toggleCreate?: () => void
}

interface IState {
    confirm?: boolean
    isOpened?: boolean
    create?: boolean
    children?: any
    fakeLine?: any
    lastNode?: boolean
}

const toolbar = {
    options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
}

export class RenderTree extends React.Component<IRenderTreeProps> {
    create = null
    p_: any = null
    state: IState = {}
    constructor(props: any) {
        super(props)

        this.p_ = this.props.p_
        this.create = this.props.create
        this.state = {
            confirm: false,
            isOpened: false,
            create: false,
            children: this?.p_?.children ? this.p_.children : [],
            fakeLine: {},
            lastNode: false,
        }
    }

    toggleChildren() {
        this.setState({ isOpened: !this.state.isOpened })
    }

    toggleCreate() {
        this.setState({ create: !this.state.create })
    }

    setChildren(child: any) {
        const children = [...this.state.children, child]

        this.setState({
            ...this.state,
            create: false,
            children,
        })
    }

    setBackgroundColorHex(color: string) {
        this.setState({
            fakeLine: { ...this.state.fakeLine, backgroundColorHex: color },
        })
    }

    removeChild(id: number) {
        this.setState({
            ...this.state,
            children: this.state.children.filter((child: { catalogId: number }) => child.catalogId !== id),
        })
    }

    render() {
        const p_ = this.p_
        const s_ = this.state

        return (
            <div className={styles.line}>
                <div className={styles.lineMainContent}>
                    <div className={styles.row}>
                        <div className={styles.title}>{translate('title')}:</div>
                        <EditableText
                            placeholder={translate('enterTitle')}
                            id={p_.catalogId ? p_.catalogId : null}
                            text={p_.title ? p_.title : ''}
                            onChange={(title: string) => {
                                this.setState({ ...s_, fakeLine: { ...s_.fakeLine, title } })
                            }}
                            onUpdate={(title, cb) => {
                                if (p_.catalogId) {
                                    api.send('updateLensData', {
                                        catalogId: p_.catalogId,
                                        title,
                                    }).then(cb)
                                }
                            }}
                        />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.title}>{translate('instanceDescription')}:</div>
                        <TextEditor
                            id={p_.catalogId ? p_.catalogId : null}
                            placeholder={translate('enterDescription')}
                            text={p_.description ? p_.description : ''}
                            previewStyles={styles.preview}
                            toolbar={toolbar}
                            onChange={(description: string) => {
                                this.setState({
                                    fakeLine: { ...s_.fakeLine, description },
                                })

                                if (p_.catalogId) {
                                    api.send('updateLensData', {
                                        catalogId: p_.catalogId,
                                        description: helpers.lineBreak(
                                            helpers.quotesConverter(description, 'html'),
                                            'html'
                                        ),
                                    })
                                }
                            }}
                        />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.title}>{translate('backgroundColor')}:</div>
                        <DropdownSelect
                            id={p_.catalogId ? p_.catalogId : null}
                            value={p_.backgroundColorHex ? p_.backgroundColorHex : getColorHex('green')}
                            callbackValue={this.setBackgroundColorHex.bind(this)}
                            options={[
                                { name: translate('green'), id: getColorHex('green') },
                                { name: translate('black'), id: getColorHex('black') },
                                { name: translate('white'), id: getColorHex('white') },
                                { name: translate('violet'), id: getColorHex('violet') },
                                { name: translate('grey'), id: getColorHex('grey') },
                            ]}
                            onChange={(color: { id: string }, cb: any) => {
                                if (p_.catalogId) {
                                    api.send('updateLensData', {
                                        catalogId: p_.catalogId,
                                        backgroundColorHex: color.id,
                                    }).then(cb)
                                } else {
                                    this.setBackgroundColorHex(color.id)
                                }
                            }}
                        />
                    </div>
                    {!s_.children.length ? (
                        <div className={styles.row} style={{ position: 'relative' }}>
                            <div className={styles.title}>{translate('media')}:</div>
                            <CatalogSelectContent
                                id={p_.catalogId ? p_.catalogId : null}
                                type={'mediaAndFolder'}
                                selectedInfo={
                                    p_.medias
                                        ? p_.medias.length
                                            ? {
                                                  ...p_.medias[0],
                                                  __view: {
                                                      selected: false,
                                                  },
                                              }
                                            : null
                                        : null
                                }
                                onSelect={(selectedMedia: { content?: any; id: number; extension: string }) => {
                                    this.setState({ lastNode: true })

                                    if (p_.catalogId) {
                                        getSourcesIds(selectedMedia).then((res) => {
                                            api.send('updateLensData', {
                                                catalogId: p_.catalogId,
                                                medias: res,
                                            })
                                        })
                                    } else {
                                        getSourcesIds(selectedMedia).then((res) => {
                                            this.setState({
                                                fakeLine: { ...s_.fakeLine, medias: res },
                                            })
                                        })
                                    }
                                }}
                            />
                        </div>
                    ) : null}
                    <div className={styles.row} style={{ position: 'relative' }}>
                        <div className={styles.title}>Картинка:</div>
                        <CatalogSelectContent
                            id={p_.catalogId ? p_.catalogId : null}
                            type={'image'}
                            selectedInfo={
                                p_.background
                                    ? {
                                          ...p_.background,
                                          __view: {
                                              selected: false,
                                          },
                                      }
                                    : null
                            }
                            onSelect={(selectedMedia: { id: number }) => {
                                if (p_.catalogId) {
                                    api.send('updateLensData', {
                                        catalogId: p_.catalogId,
                                        sourceId: selectedMedia.id,
                                    })
                                } else {
                                    this.setState({
                                        fakeLine: { ...s_.fakeLine, background: selectedMedia },
                                    })
                                }
                            }}
                        />
                        {p_.catalogId ? (
                            <div
                                className={`delete-btn ${styles.deleteBtn}`}
                                onClick={() => {
                                    this.setState({ confirm: true })
                                }}
                            >
                                <Icon type={'delete'} />
                            </div>
                        ) : null}
                    </div>
                    {s_.confirm ? (
                        <form
                            onSubmit={(e) => {
                                e.preventDefault()
                                api.send('deleteLensData', {
                                    catalogId: p_.catalogId,
                                }).then(() => {
                                    if (p_.parentId) {
                                        if (this.props.removeChild) {
                                            this.props.removeChild(p_.catalogId)
                                        }
                                    } else {
                                        window.location.reload()
                                    }
                                })
                                this.setState({ confirm: false })
                            }}
                            className="confirm-form"
                        >
                            <div className={cn(styles.approveMessage, textStyles.center)}>
                                {translate('deleteTreeApprove')}
                            </div>

                            <div className={cn(grid.row, grid.center)}>
                                <Button
                                    type="submit"
                                    mod="fill"
                                    indentSize={'mini'}
                                    className={styles.confirmButton}
                                    textSize={'mini'}
                                    rounded={'full_normal'}
                                >
                                    {translate('yes')}
                                </Button>
                                <Button
                                    mod={'withBorder'}
                                    animation={false}
                                    onClick={() => this.setState({ confirm: false })}
                                    indentSize={'mini'}
                                    textSize={'mini'}
                                    rounded={'full_normal'}
                                >
                                    {translate('no')}
                                </Button>
                            </div>
                        </form>
                    ) : null}
                </div>
                <div className={styles.navPanel}>
                    {p_.catalogId && !p_.medias.length && !s_.lastNode ? (
                        <div
                            className={styles.addBtn}
                            onClick={() => {
                                this.setState({ ...s_, create: true, isOpened: true })
                            }}
                        >
                            <Icon type={'plus_circle'} />
                        </div>
                    ) : null}
                    {s_.children.length || s_.create ? (
                        <div
                            className={styles.toggleBtn}
                            onClick={() => {
                                this.toggleChildren()
                            }}
                        >
                            <Icon type={s_.isOpened ? 'arrow_up' : 'arrow_down'} />
                        </div>
                    ) : null}
                </div>
                <div style={{ display: s_.isOpened ? 'block' : 'none' }} className={styles.children}>
                    {s_.create ? (
                        <RenderTree
                            p_={fakeParams(p_.catalogId)}
                            create={true}
                            setChildren={this.setChildren.bind(this)}
                            toggleCreate={this.toggleCreate.bind(this)}
                        />
                    ) : null}
                    {s_.children.length ? (
                        <>
                            {s_.children.map((child: { catalogId: number }) => (
                                <RenderTree
                                    key={child.catalogId}
                                    p_={child}
                                    removeChild={this.removeChild.bind(this)}
                                />
                            ))}
                        </>
                    ) : null}
                </div>
                {this.create ? (
                    <CreatePanel
                        createData={() => {
                            api.send('createLensData', {
                                parentId: this.p_.parentId ? this.p_.parentId : null,
                                title: s_.fakeLine.title,
                                description: s_.fakeLine.description,
                                sourceId: s_.fakeLine.background.id,
                                backgroundColorHex: s_.fakeLine.backgroundColorHex,
                                medias: s_.fakeLine.medias ? s_.fakeLine.medias : null,
                            }).then((res: any) => {
                                if (this.p_.parentId) {
                                    if (this.props.setChildren) {
                                        this.props.setChildren({
                                            parentId: res.parentId,
                                            catalogId: res.catalogId,
                                            title: res.title,
                                            description: res.description,
                                            backgroundColorHex: res.backgroundColorHex,
                                            background: res.background,
                                            children: null,
                                            medias: res.medias,
                                        })
                                    }
                                }
                            })
                        }}
                        disabled={
                            !s_.fakeLine.title ||
                            !s_.fakeLine.description ||
                            !s_.fakeLine.background ||
                            !s_.fakeLine.backgroundColorHex
                        }
                        cancel={this.props.toggleCreate ? this.props.toggleCreate : () => {}}
                    />
                ) : null}
            </div>
        )
    }
}

const CreatePanel = ({
    createData,
    disabled,
    cancel,
}: {
    createData: () => void
    disabled: boolean
    cancel: () => void
}) => {
    return (
        <div className={styles.createPanel}>
            <Button mod={'fill'} rounded="full_md" indentSize="mini" onClick={() => cancel()}>
                {translate('cancel')}
            </Button>
            <Button
                mod={'fill'}
                indentSize={'mini'}
                textSize={'mini'}
                rounded={'full_normal'}
                onClick={() => createData()}
                disabled={disabled}
            >
                {translate('save')}
            </Button>
        </div>
    )
}

export const fakeParams = (parentId?: number) => {
    return {
        parentId: parentId,
        catalogId: null,
        title: '',
        description: '',
        backgroundColorHex: '',
        background: null,
        children: null,
    }
}

async function getSourcesIds(selectedMedia: { content?: any; id: number; extension: string }) {
    if (!selectedMedia) return

    if (selectedMedia.content) {
        const response: { id: number; extension: string }[] = await api.send('getSources', {
            folderId: selectedMedia.id,
        })
        return getMediaIds(response)
    } else {
        return getMediaIds([selectedMedia])
    }
}

const getMediaIds = (items: { id: number; extension: string }[]) => {
    if (!items) return

    const mediaIds: number[] = []

    items.forEach((media) => {
        const ext = media.extension
        if (ext === '.jpg' || ext === '.png' || ext === '.mp4') {
            return mediaIds.push(media.id)
        }
    })

    return mediaIds.length ? mediaIds : null
}

const getColorHex = (color: string) => {
    if (!color) return

    switch (color) {
        case 'green':
            return '#8cbf60'
        case 'black':
            return '#000000'
        case 'white':
            return '#ffffff'
        case 'violet':
            return '#c90bdb'
        case 'grey':
            return '#828282'
    }
}
