import { addStyles } from 'ethcss'
import { IStyles, grid, item } from 'theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { mediaCSS } from 'theme/media'

export const treeViewCatalogHeaderStyles: IStyles = {
    searchWrapper: {
        ...grid.w70,
        // borderTop: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
        // borderLeft: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
        // borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    formWrapper: {
        borderLeft: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border}) !important`,
        borderTop: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border}) !important`,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border}) !important`,
        borderRadius: '5px',
        //...grid.w100,
    },
    dropdownClassName: {
        width: '30%',

        '&>div': {
            width: '100%',
        },
    },
    wrapper: {
        ...item.indent_big,
    },
}

addStyles(treeViewCatalogHeaderStyles)
