import React from 'react'
import Icon from 'blocks.simple/icon/icon'
import translate from 'core/translate'
import moment from 'moment'
import styles from './advertisingCampaigns_add__summary.jcss'
import { Typography } from 'atoms/Typography'

// Не приходит ориентация в собитии core_v1_frontend_get_ds_by_ds_id_and_group_id
/*const getAmountDevicesByOrientation = (displays) => {
    let orientations = helpersDisplays.getOrientations();
    orientations = orientations.map(orientation => {
        orientation.amount = 0;

        return orientation;
    });

    displays.forEach(display => {
        const orientationIdx = orientations.findIndex(item => item.id === display.orientation);

        if (orientationIdx !== -1) {
            orientations[orientationIdx].amount++;
        }
    });

    const infoArray = orientations.map((orientation) => {
        return `${orientation.name}: ${orientation.amount}`
    });

    return infoArray.join(' / ');
};*/

const getArrayContentInfo = (
    summary: {
        displays: { id: number; name: string }[]
        content: any
        name: string
        description: string
        startDate: string
        endDate: string
        applyToNewDigitalSignage: boolean
    },

    isAdvBlock: boolean
) => {
    let duration = 0
    const amount = summary.displays.length

    if (summary.content.length) {
        duration = summary.content.reduce((acc: number, item: { duration: string }) => {
            acc += parseInt(item.duration, 10)

            return acc
        }, 0)
    }
    const options = [
        {
            label: translate('name'),
            value: summary.name,
        },
        {
            label: translate('simpleDescription'),
            value: summary.description || '-',
            hidden: isAdvBlock,
        },
        {
            label: translate('date'),
            value:
                moment(summary.startDate).format('YYYY.MM.DD') + ' - ' + moment(summary.endDate).format('YYYY.MM.DD'),
            hidden: isAdvBlock,
        },
        {
            label: translate('amountOfContent'),
            value: summary.content.length,
        },
        {
            label: translate('contentDuration'),
            value: `${duration} ${translate('sec')}`,
        },
        {
            label: translate('amountOfDevices'),
            value: amount,
        },
    ]

    if (isAdvBlock) {
        options.push({
            label: translate('applyToNewDisplays'),
            value: summary.applyToNewDigitalSignage ? translate('yes') : translate('no'),
        })
    }
    return options
}

const AdvertisingCampaignsAddSummary = (p_: any) => {
    return (
        <React.Fragment>
            <Typography className={styles.bigTitle} type="title">
                {translate('summary')}
            </Typography>
            {getArrayContentInfo(p_.summary, p_.isAdvBlock).map(
                (item: { label: string; value: string; hidden?: boolean }, index: number) => {
                    if (item.hidden) {
                        return null
                    }

                    return (
                        <div key={`summary_info_${index}`} className={styles.row}>
                            <span className={styles.title}>{item.label}:&nbsp;</span> {item.value}
                        </div>
                    )
                }
            )}
            <Typography className={styles.bigTitle} type="title">
                {translate('conflicts')}
            </Typography>
            {p_.conflicts && !p_.conflicts.length && (
                <div className={styles.empty}>
                    <Icon type="not_allow" size={60} />
                    <Typography type="title" className={styles.emptyText}>
                        {translate('noConflicts')}
                    </Typography>
                </div>
            )}
            {p_.conflicts && p_.conflicts.length > 0 && (
                <table className={styles.conflictsTable}>
                    <thead>
                        <tr>
                            <th>{translate('object')}</th>
                            <th>{translate('cause')}</th>
                            <th>{translate('solution')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {p_.conflicts.map(
                            (
                                conflict: { obj: { name: string }; cause: string; proposedSolution: string },
                                index: number
                            ) => (
                                <tr key={`conflict_tr_${index}`}>
                                    <td>{conflict.obj.name}</td>
                                    <td>{translate(conflict.cause)}</td>
                                    <td>{translate(conflict.proposedSolution)}</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            )}
        </React.Fragment>
    )
}

export default React.memo(AdvertisingCampaignsAddSummary)
