import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { IStyles } from 'theme'

const videoEditorStyles: IStyles = {
    colsWrapper: {
        ...grid.row,
        ...grid.full,
    },
    colsContent: {
        width: 0,
        ...grid.space,
        ...grid.fullHeight,
        ...item.overY,
    },
}

addStyles(videoEditorStyles)
export default videoEditorStyles
