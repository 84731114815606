import { useEffect, useState } from 'react'
import translate from 'core/translate'
import moment from 'moment'
import helpers from 'core/helpers'
import helpersDisplays from 'core/helpers/_digitalSignages'
import { api } from 'core/api/ConnectionManager'
import { routes, changeLocation } from 'features/routes'
import { getURLSearchParamsString } from 'features/routes/utils'

interface IState {
    startDate: string
    startTime: string
    updateNow: boolean
    selectedItems: any
    task: any
    editingMode: boolean
    types: {
        id: string
        name: string
    }[]
    platforms: any
    defaultPlatforms: string[]
}

const defaultState = {
    startDate: moment().format(),
    startTime: moment().format('HH:mm:ss'),
    updateNow: false,
    selectedItems: [],
    task: null,
    editingMode: false,
}

const platformsForUpdate = [
    'sssp 2',
    'sssp 3',
    'sssp 4',
    'sssp 5',
    'sssp 6',
    'sssp 7',
    'sssp 10',
    'webos 2',
    'webos 3',
    'webos 3.2',
    'webos 4',
    'webos 4.1',
    'webos 6',
    'brightsign',
    'android',
]

const getUpdatePlatformOnEdit = (state: any) =>
    state.platforms.map((platform: any) => {
        if (state.task && state.task.digitalSignage.platform) {
            const devicePlatform = helpersDisplays.getPlatformName(state.task.digitalSignage.platform)

            if (platform.name === devicePlatform) {
                return {
                    ...platform,
                    updateType: state.task.digitalSignageUpdateDistributive.updateType,
                }
            }
        }

        return platform
    })

const UpdateDevicesMethods = (p_: { updateType: string }) => {
    const [state, setState] = useState<IState>({
        types: [
            {
                id: 'devicesForUpdate',
                name: translate('devicesForUpdate'),
            },
            {
                id: 'tasksForUpdate',
                name: translate('tasksForUpdate'),
            },
        ],
        platforms: [],
        defaultPlatforms: [],
        ...defaultState,
    })
    const timeOptions = helpers.getTimeOptions()
    const getDerivedStateFromProps = (p_: any, s_: any) => {
        if (!s_.task) {
            return null
        }

        const startDate = moment(s_.task.date)
        const startTime = s_.editingMode ? s_.startTime : moment(startDate).format('HH:mm:ss')
        const platforms = getUpdatePlatformOnEdit(s_)

        return {
            startDate,
            startTime,
            platforms,
            editingMode: false,
        }
    }

    useEffect(() => {
        getCompanyPlatforms()
    }, [])

    const getCompanyPlatforms = () => {
        api.send('getCompanyPlatforms', {}).then((res: any) => {
            let platforms: any = []

            res.forEach((platform: { name: string; updateType: string }) => {
                if (platformsForUpdate.includes(platform.name.toLowerCase())) {
                    platforms.push({
                        name: helpersDisplays.getPlatformName(platform.name),
                        updateType: 'firmware',
                    })
                }
            })

            platforms = helpers.removeDuplicates(platforms, 'name')
            const defaultPlatforms = helpers.deepCopy(platforms)

            setState((prevState) => {
                return { ...prevState, platforms, defaultPlatforms }
            })
        })
    }
    const updateNowDevice = (updateNow: boolean) => {
        let startTime = moment().add(3, 'minutes').format('HH:mm:ss')

        if (!updateNow) {
            startTime = moment().format('HH:mm:ss')
        }

        setState((prevState) => {
            return { ...prevState, updateNow, startTime }
        })
    }

    const changeTab = (id: string) => {
        if (id !== p_.updateType) {
            setState((prevState) => {
                return { ...prevState, selectedItems: [] }
            })
        }
    }

    const convertData = (cb: any) => {
        const s_ = state
        const groupId: number[] = []
        const digitalSignageId: number[] = []

        s_.selectedItems.forEach((device: { id: number; type: string }) => {
            if (device.type === 'group') {
                groupId.push(device.id)
            } else {
                digitalSignageId.push(device.id)
            }
        })

        api.send('getDisplaysFromGroups', { digitalSignageId, groupId, fields: 'id, name, platform' }).then(
            (devices: any) => {
                let data = devices.map((device: { id: number; platform: string }) => device.id)

                if (s_.platforms.length) {
                    data = devices.map((device: { id: number; platform: string }) => ({
                        id: device.id,
                        platform: device.platform,
                    }))
                }

                cb(data)
            }
        )
    }

    const onUpdate = () => {
        const s_ = state
        const startDate = moment(s_.startDate).format('YYYY-MM-DD')

        let data: {
            id?: string
            date: string
            digitalSignageId?: { id: number; platform: string }[]
        } = {
            date: moment(`${startDate} ${s_.startTime}`).utc().toISOString(),
        }

        convertData((devices: { id: number; platform: string }[]) => {
            data.digitalSignageId = devices

            if (s_.platforms.length) {
                data.digitalSignageId = devices.map((device) => {
                    if (platformsForUpdate.includes(device.platform.toLowerCase())) {
                        const data: any = {
                            id: device.id,
                        }
                        const platformName = helpersDisplays.getPlatformName(device.platform)
                        const settings = s_.platforms.find(
                            (platform: { name: string }) => platform.name === platformName
                        )

                        if (settings) {
                            data.updateType = settings.updateType
                        }

                        return data
                    } else {
                        return { id: device.id }
                    }
                })
            }

            if (s_.task) {
                data.id = s_.task.id
            }

            const method = s_.task ? 'updateDsUpdateTask' : 'createDsUpdateTask'

            api.send(method, data).then(() => {
                setState((prevState) => {
                    return { ...prevState, ...defaultState, platforms: s_.defaultPlatforms }
                })
                changeLocation({
                    pathname: `/${routes.updateDevices.path}`,
                    search: getURLSearchParamsString({ updateType: 'tasksForUpdate' }),
                })
            })
        })
    }

    return {
        state,
        setState,
        changeTab,
        timeOptions,
        updateNowDevice,
        onUpdate,
    }
}

export default UpdateDevicesMethods
