import React from 'react'
import PropTypes from 'prop-types'
import EditorActionsMethods from './editor__actions.local.methods'
import Icon from 'blocks.simple/icon/icon'
import TouchEditor from 'blocks.app/touchEditor/touchEditor'

import translate from 'core/translate'

import styles from './editor__actions.jcss'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'

const getTitle = (p_) => {
    let title = `${translate(`${p_.event}_${p_.type}`)}`

    if (p_.options.key === 'order') {
        title += ` (${translate('page')} ${p_.options.value + 1})`
    }

    return title
}

const ListItem = (p_) => (
    <div className={styles.action}>
        <div className={styles.actionTitle}>{getTitle(p_)}</div>
        <div className={styles.actionIcons}>
            <Icon
                containerClass={grid.mr_mini}
                type={'pencil'}
                tooltip={{ title: translate('edit') }}
                onClick={p_.onEdit}
            />
            <Icon type={'delete'} tooltip={{ title: translate('delete') }} onClick={p_.onDelete} />
        </div>
    </div>
)

class EditorActions extends EditorActionsMethods {
    static propTypes = {
        currentPage: PropTypes.object,
        pages: PropTypes.array,
        content: PropTypes.object,
    }

    render() {
        const s_ = this.state
        const p_ = this.props

        return (
            <div>
                <div className={styles.title}>
                    <div />
                    <div className={item.ellipsis}>{translate('actions')}</div>
                    <div className={styles.icon} onClick={() => this.setState({ isOpenModal: true, action: null })}>
                        <Icon type="plus" tooltip={{ title: translate('add') }} />
                    </div>
                </div>
                {!s_.content.actionsOnContent && (
                    <div className={styles.emptyMessage}>{translate('listOfActionsIsEmpty')}</div>
                )}
                {s_.content.actionsOnContent &&
                    s_.content.actionsOnContent.map((type) =>
                        type.actions.map((action, index) => (
                            <ListItem
                                key={index}
                                type={action.actionType}
                                event={type.eventType}
                                options={action.actionOptions}
                                onDelete={() => this.onDelete(type.eventType, action.actionType)}
                                onEdit={() => {
                                    this.setState({
                                        action: {
                                            eventType: type.eventType,
                                            id: action.actionType,
                                            actionOptions: action.actionOptions,
                                            actionIndex: index,
                                        },
                                        isOpenModal: true,
                                    })
                                }}
                            />
                        ))
                    )}
                <TouchEditor
                    open={s_.isOpenModal}
                    page={p_.currentPage}
                    pages={p_.pages}
                    content={s_.content}
                    action={s_.action}
                    onSave={this.onSave}
                    onClose={() => this.setState({ isOpenModal: false })}
                    type="content"
                />
            </div>
        )
    }
}

export default EditorActions
