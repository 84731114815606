import React from 'react'
import { Avatar } from 'molecules/Avatar'
import { Icon } from 'molecules/Icon'
import UsersTile from './Tile'

import store from 'core/store'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import helpers from 'core/helpers'

import styles from './styles'
import { isArray } from 'core/utils/coreUtil'
import { autoLogout, userActions } from 'blocks.app/user/user.state'
import { cn } from 'ethcss'
import moment from 'moment'

export default {
    getMethod: 'getUsers',
    onGetDataCallback: (userDataList) => {
        if (isArray(userDataList)) {
            return userDataList.map((user) => {
                let translatedRoles = []
                if (isArray(user.roles)) {
                    translatedRoles = user.roles.map((role) => helpers.translateRoleLabel(role))
                }

                return {
                    ...user,
                    roles: translatedRoles,
                }
            })
        }

        return []
    },
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noUsers'),
    settingsPrefixes: 'users',
    multiSelect: true,
    getItemModel: (dataItem, getSelected) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    getBackendModel: (item) => {
        return api.send('getUserById', { id: item.id }, { hideLoader: true }).then((user) => {
            return {
                ...user,
                roles: user.roles.map((role) => helpers.translateRoleLabel(role)),
            }
        })
    },
    tile: UsersTile,
    headerCustomContent: {
        left: () => {
            const appStore = store.getState()
            const appType = appStore.app.appType

            return (
                <React.Fragment>
                    {helpers.isAvailable({ key: 'user', action: 'create' }) && (
                        <React.Fragment>
                            <div className={styles.catalogTemplateUserIcon}>
                                <Icon
                                    type="new_user"
                                    size={28}
                                    onClick={() =>
                                        store.dispatch(userActions.openUserModal({ isActiveDirectory: false }))
                                    }
                                    tooltip={{ title: translate('newUser') }}
                                />
                            </div>
                            {appType === 'personal' && (
                                <div className={cn(styles.catalogTemplateUserIcon, styles.catalogTemplateADUserIcon)}>
                                    <Icon
                                        type="new_user_ad"
                                        size={28}
                                        onClick={() =>
                                            store.dispatch(userActions.openUserModal({ isActiveDirectory: true }))
                                        }
                                        tooltip={{ title: translate('newADUser') }}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )
        },
    },
    isEqual: (firstItem, secondItem) => firstItem.id === secondItem.id,
    fields: () => {
        const state = store.getState()
        const app = state.app

        const fields = [
            { id: 'name', name: translate('name'), hiddenTitle: true },
            { id: 'roles', name: translate('role') },
            { id: 'email', name: translate('email') },
            { id: 'lastLoginAt', name: translate('lastLoginAt') },
        ]

        if (app.appType === 'personal') {
            fields.push({ id: 'authType', name: 'AD' })
        }

        return fields
    },
    init(callback) {
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    onCreateListeners: [(cb, clearList) => api.addObserver('userCreated', cb, clearList)],
    onUpdateListeners: [(cb, clearList) => api.addObserver('userUpdated', cb, clearList)],
    onDeleteListeners: [(cb, clearList) => api.addObserver('userDeleted', cb, clearList)],
    onDelete: ({ id }) => {
        store.dispatch(autoLogout(id))
    },
    viewOptions: () => [
        { value: 'tile', label: translate('tile') },
        { value: 'list', label: translate('list') },
    ],
    sortOptions: () => [
        { value: '-createdAt', label: translate('sortCreatedAt') },
        { value: 'name', label: translate('sortName') },
    ],
    list: {
        name: ({ photo, name }, active) => (
            <div className={styles.catalogTemplateUsersNameWrapper}>
                <div className={styles.catalogTemplateUserAvatar}>
                    <Avatar src={photo} icon={{ type: 'user', size: 16 }} />
                </div>
                <div className={styles.catalogTemplateUsersName}>{name}</div>
            </div>
        ),
        roles: ({ roles, ...p_ }) => {
            if (!roles.length) {
                return null
            }

            return roles.map((role) => role.label).join(', ')
        },
        authType: ({ authType }) => {
            if (authType === 'activeDirectory') {
                return 'AD'
            }

            return '-'
        },
        lastLoginAt: ({ lastLoginAt }) => (
            <div>{lastLoginAt === null ? '-' : moment(lastLoginAt).format('DD.MM.YYYY')}</div>
        ),
    },
}
