import { useState, useEffect } from 'react'
import { api } from 'core/api/ConnectionManager'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { defaultThemeStyles as defaultThemeDashboardStyles } from 'organisms/Dashboard/Dashboard-theme'

const getColorForSchedules = (key: string) => {
    switch (key) {
        case 'today':
            return `var(--var-module-dashboard-component-dashboard-style-online, ${defaultThemeDashboardStyles.online})`
        case 'digitalSignageWithoutSchedule':
            return `var(--var-module-dashboard-component-dashboard-style-error, ${defaultThemeDashboardStyles.error})`
        default:
            return getThemeStyleValue('app', 'app', 'textMain') || defaultThemeStyles.textMain
    }
}

function DashboardScheduleMethods() {
    const [data, setScheduleStats] = useState(null)
    const [schedulesCount, setSchedulesCount] = useState(0)

    useEffect(() => {
        getScheduleStats()
    }, [])

    function getScheduleStats() {
        api.send('dashboardGetScheduleStats', {}, { showEmitError: false }).then((res: any) => {
            const data: any = []
            let count = 0

            for (let key in res) {
                if (res.hasOwnProperty(key)) {
                    const value = res[key]

                    data.push({
                        color: getColorForSchedules(key),
                        label: `dashboard_${key}`,
                        key,
                        value,
                    })

                    count += value
                }
            }

            setScheduleStats(data)
            setSchedulesCount(count)
        })
    }

    return {
        s_: {
            data,
            schedulesCount,
        },
    }
}

export default DashboardScheduleMethods
