import { isExist, isEmptyObject } from 'core/utils/coreUtil'
import { HistoryFilterData, HistoryFilterParams, IRoute, IRoutePath, IRoutes } from '../routes-types'
import { IRecordWithAny, IRecordWithStrings } from 'core/helpers'
import { Location } from 'history'
import config from 'core/config'

export const routes: IRoutes = {
    login: {
        path: 'login',
        isAuth: true,
    },
    registration: {
        path: 'registration',
        isAuth: true,
    },
    logout: {
        path: 'logout',
    },
    signup: {
        path: 'signup',
    },
    forgetPassword: {
        path: 'forgetPassword',
        isAuth: true,
    },
    confirmEmail: {
        path: 'confirm_email',
        prefix: 'confirm_email',
        redirectPath: 'settings',
    },
    dashboard: {
        path: 'dashboard',
        permissions: { key: 'dashboard', action: 'read' },
    },
    videoChat: {
        path: 'videoChat',
        permissions: { key: 'digitalSignage', action: 'read' },
    },
    editVideoChat: {
        path: 'videoChat/edit',
        permissions: { key: 'digitalSignage', action: 'update' },
    },
    displays: {
        path: 'displays',
        permissions: { key: 'digitalSignage', action: 'read' },
    },
    addDisplay: {
        path: 'displays/add',
        prefix: 'add_display',
        redirectPath: 'displays',
        permissions: { key: 'digitalSignage', action: 'create' },
    },
    addGroup: {
        permissions: { key: 'digitalSignage', action: 'create' },
    },
    draftDevices: {
        path: 'displays/draftDevices',
        permissions: { key: 'digitalSignage', action: 'create' },
    },
    updateDevices: {
        path: 'displays/updateDevices',
        permissions: { key: 'digitalSignage', action: 'update' },
    },
    firmwareList: {
        path: 'displays/firmwareList',
        permissions: { key: 'digitalSignage', action: 'read' },
    },
    scoreboards: {
        path: 'scoreboards',
        permissions: { key: 'scoreboard', action: 'read' },
    },
    addScoreboard: {
        path: 'scoreboards/add',
        permissions: { key: 'scoreboard', action: 'create' },
    },
    addScoreboardFolder: {
        path: 'scoreboards/addFolder',
        permissions: { key: 'scoreboard', action: 'create' },
    },
    editScoreboard: {
        path: 'scoreboards/edit',
        permissions: { key: 'scoreboard', action: 'update' },
    },
    editScoreboardFolder: {
        path: 'scoreboards/editFolder',
        permissions: { key: 'scoreboard', action: 'update' },
    },
    scoreboardsSettings: {
        path: 'scoreboards/scoreboardsSettings',
        permissions: { key: 'scoreboard', action: 'update' },
    },
    files: {
        path: 'files',
        permissions: { key: 'files', action: 'read' },
    },
    videoEditor: {
        path: 'files/videoEditor',
        permissions: { key: 'files', action: 'update' },
    },
    broadcasts: {
        path: 'broadcasts',
        permissions: { key: 'broadcast', action: 'read' },
    },
    addContentToDevice: {
        path: 'addContentToDevice',
        permissions: { key: 'broadcast', action: 'create' },
    },
    addAdvancedBroadcast: {
        path: 'broadcasts/addAdvanced',
        permissions: { key: 'broadcast', action: 'create' },
    },
    editBroadcast: {
        path: 'broadcasts/edit',
        permissions: { key: 'broadcast', action: 'update' },
    },
    addVideowall: {
        path: 'broadcasts/addVideowall',
        permissions: { key: 'broadcast', action: 'create' },
    },
    addBroadcastFolder: {
        permissions: { key: 'broadcast', action: 'create' },
    },
    uploadBroadcast: {
        permissions: { key: 'broadcast', action: 'create' },
    },
    advertisingCampaigns: {
        path: 'advertisingCampaigns',
        permissions: { key: 'advertisingCampaign', action: 'read' },
    },
    addAdvertisingCampaign: {
        path: 'advertisingCampaigns/add',
        permissions: { key: 'advertisingCampaign', action: 'create' },
    },
    editAdvertisingCampaign: {
        path: 'advertisingCampaigns/edit',
        permissions: { key: 'advertisingCampaign', action: 'update' },
    },
    adBlocks: {
        path: 'adBlocks',
        permissions: { key: 'advertisingCampaign', action: 'read' },
    },
    addFillersAdBlock: {
        path: 'adBlocks/add',
        permissions: { key: 'advertisingCampaign', action: 'create' },
    },
    editFillersAdBlock: {
        path: 'adBlocks/edit',
        permissions: { key: 'advertisingCampaign', action: 'update' },
    },
    advertisingBlocks: {
        path: 'adBlocks/advertisingBlocks',
        permissions: { key: 'advertisingCampaign', action: 'read' },
    },
    reports: {
        path: 'reports',
        permissions: { key: 'statistics', action: 'read' },
    },
    reportTasks: {
        path: 'reports/tasks',
        permissions: { key: 'statistics', action: 'read' },
    },
    licenseReport: {
        path: 'reports?reportType=licenseReport',
        permissions: { key: 'statistics', action: 'read' },
    },
    currencyReport: {
        path: 'reports?reportType=currencyReport',
        permissions: { key: 'statistics', action: 'read' },
    },
    settings: {
        path: 'settings',
        permissions: { key: 'settings', action: 'read' },
    },
    schedule: {
        path: 'schedule',
        permissions: { key: 'schedule', action: 'read' },
    },
    addSchedule: {
        path: 'schedule/add',
        permissions: { key: 'schedule', action: 'create' },
    },
    editSchedule: {
        path: 'schedule/edit',
        permissions: { key: 'schedule', action: 'update' },
    },
    users: {
        path: 'users',
        permissions: { key: 'user', action: 'read' },
    },
    timetable: {
        path: 'timetable',
        permissions: { key: 'timetable', action: 'read' },
    },
    addTimetable: {
        path: 'timetable/add',
        permissions: { key: 'timetable', action: 'create' },
    },
    instancesCatalog: {
        path: 'instancesCatalog',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    coursesCatalog: {
        path: 'instancesCatalog/courses',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    classroomsCatalog: {
        path: 'instancesCatalog/classrooms',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    snacksCatalog: {
        path: 'instancesCatalog/snacks',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    burgersCatalog: {
        path: 'instancesCatalog/burgers',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    smallBurgersCatalog: {
        path: 'instancesCatalog/smallBurgers',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    drinksCatalog: {
        path: 'instancesCatalog/drinks',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    comboCatalog: {
        path: 'instancesCatalog/combo',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    samsoniteCatalog: {
        path: 'instancesCatalog/samsonite',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    askonaCatalog: {
        path: 'instancesCatalog/askona',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    pitamixCatalog: {
        path: 'instancesCatalog/pitamix',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    gradesCatalog: {
        path: 'instancesCatalog/grades',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    teachersCatalog: {
        path: 'instancesCatalog/teachers',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    subjectsCatalog: {
        path: 'instancesCatalog/subjects',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    wineCatalog: {
        path: 'instancesCatalog/wine',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    productMenuCatalog: {
        path: 'instancesCatalog/productMenu',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    menuProductCatalog: {
        path: 'instancesCatalog/menuProduct',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    artsCatalog: {
        path: 'instancesCatalog/catalogizator',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    historyPagesCatalog: {
        path: 'instancesCatalog/lightOfHistory',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    surveyCatalog: {
        path: 'instancesCatalog/surveyCatalog',
        permissions: { key: 'instancesCatalog', action: 'read' },
    },
    treeCatalog: {
        path: 'instancesCatalog/lensOfTime',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    exhibitionsCatalog: {
        path: 'instancesCatalog/exhibition',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    productsCatalog: {
        path: 'instancesCatalog/products',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    quizQuestionsCatalog: {
        path: 'instancesCatalog/quizQuestionsCatalog',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    createFolder: {
        permissions: { key: 'files', action: 'create' },
    },
    upload: {
        permissions: { key: 'files', action: 'create' },
    },
    createRole: {
        path: 'users/roles/create',
        permissions: { key: 'role', action: 'create' },
    },
    roles: {
        path: 'users/roles',
        permissions: { key: 'role', action: 'read' },
    },
    editRole: {
        path: 'users/roles/edit',
        permissions: { key: 'role', action: 'update' },
    },
    ui: {
        path: 'ui',
        permissions: { key: 'digitalSignage', action: 'read' },
    },
    distributive: {
        path: 'distributive',
        permissions: { key: 'dashboard', action: 'read' },
    },
    surveyReport: {
        path: 'reports?reportType=photoBoothSurveyReport',
        permissions: { key: 'statistics', action: 'read' },
    },
    about: {
        path: 'about',
        permissions: { key: 'settings', action: 'read' },
    },
    licenses: {
        path: 'licenses',
        permissions: { key: 'licenses', action: 'read' },
    },
    widgetsGallery: {
        path: 'widgetsGallery',
        permissions: { key: 'settings', action: 'read' },
    },
    advertisingAds: {
        path: 'advertisingAds',
        permissions: { key: 'advertisingAds', action: 'read' },
    },
    addAdvertisingAds: {
        path: 'advertisingAds/add',
        permissions: { key: 'advertisingAds', action: 'create' },
    },
    editAdvertisingAds: {
        path: 'advertisingAds/edit',
        permissions: { key: 'advertisingAds', action: 'update' },
    },
    view: {
        path: 'advertisingAds/view',
        permissions: { key: 'advertisingAds', action: 'read' },
    },
    schoolSchedule: {
        path: 'instancesCatalog/schoolSchedule',
        permissions: { key: 'instancesCatalog', action: 'create' },
    },
    notificationCenter: {
        path: 'notificationCenter',
        permissions: { key: 'notificationCenter', action: 'read' },
    },
    ipTv: {
        path: 'ipTv',
        permissions: { key: 'ipTv', action: 'create' },
    },
}

export const routesKeys: IRoutePath = Array.from(Object.keys(routes)).reduce((result, key) => {
    const path = routes[key].path

    if (!path) return result

    return { ...result, [path]: key }
}, {})

export const findRoute = (routePath: string): IRoute | null => {
    if (!isExist(routePath)) return null

    const findedRoute = Array.from(Object.values(routes)).find((route) => route.path === routePath)

    return findedRoute || null
}

export const getURLSearchParamsByLocation = (location: Location, who?: string): IRecordWithStrings => {
    if (!isExist(location) || !isExist(location.search)) {
        return {}
    }

    return convertURLparamsToPureObject(new URLSearchParams(location.search))
}

export const getURLSearchParamsBySearchString = (search: string): IRecordWithStrings => {
    return isExist(search) ? convertURLparamsToPureObject(new URLSearchParams(search)) : {}
}

export const getURLSearchParamsString = (object?: IRecordWithAny): string => {
    const urlParams = new URLSearchParams()

    if (object) {
        for (let key in object) {
            if (object[key]) {
                urlParams.set(key, object[key])
            }
        }
    }

    return `?${urlParams.toString()}`
}

export const convertURLparamsToPureObject = (params: URLSearchParams): IRecordWithStrings => {
    const result: IRecordWithStrings = {}

    params.forEach((value, key) => {
        result[key] = value
    })

    return result
}

export const getDownloadLink = (selectedList: HistoryFilterParams, params: string) => {
    let downloadLink = `${config.api.root}v2/download/users-actions-history?${params}`
    const data = selectedList
    const date = ['startDate', 'endDate']

    if (isExist(data) || !isEmptyObject(data)) {
        const dataObj: HistoryFilterData = {
            action: [''],
            object: [''],
            userId: [''],
        }

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                if (key !== 'startDate' && key !== 'endDate') {
                    ;(data[key] as string[]).forEach((item) => {
                        dataObj[key].push(item)
                    })
                }
            }
        }

        if (dataObj.action.length) {
            downloadLink += dataObj.action.join('&action[]=')
        }
        if (dataObj.object.length) {
            downloadLink += dataObj.object.join('&object[]=')
        }
        if (dataObj.userId.length) {
            downloadLink += dataObj.userId.join('&userId[]=')
        }

        date.forEach((date) => {
            if (data[date]) {
                downloadLink += `&${date}=${data[date]}`
            }
        })
    }

    return downloadLink
}

export const isRedirectToLogin = (pathname: string) => {
    let loginPath = routes.login.path
    return loginPath && pathname.includes(loginPath)
}

export const isRoutePathnameChange = (nextLocation: Location, prevLocation: Location) => {
    return nextLocation.pathname !== prevLocation.pathname
}
