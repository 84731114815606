import { addStyles } from 'ethcss'
import { grid, text, IStyles, colors, mediaCSS } from 'theme'
import { defaultThemeStyles } from '../AudioPlayground-theme'

export const audioPlaygroundItemStyles: IStyles = {
    nowPlaying: {
        fontWeight: 500,
        fontSize: '20px',
        color: '#BABABA',
        marginTop: '20px',
        textTransform: 'uppercase',
        [mediaCSS.tab]: {
            fontSize: '16px',
        },
    },
    AudioPlaygroundItem: {},
    AudioPlaygroundItem__content: {
        ...grid.pb_mini,
        ...grid.pt_mini,
    },
    AudioPlaygroundItem__title: {
        ...text.md,
        lineHeight: 1.3,
        color: `var(--var-module-audio-component-playground-style-text, ${defaultThemeStyles.text})`,
    },
    AudioPlaygroundItem__text: {
        ...text.mini,
        lineHeight: 1.2,
        color: colors.white,
        fontSize: '20px',
        [mediaCSS.tab]: {
            fontSize: '16px',
        },
    },
    AudioPlaygroundItem__audio: {
        display: 'none',
    },
}

addStyles(audioPlaygroundItemStyles)
