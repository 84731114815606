import { addStyles } from 'ethcss'
import { grid, item, IStyles, colors } from '../../theme'
import { defaultThemeStyles } from './Range-theme'

const rangeStyles: IStyles = {
    rangeWrapper: {
        ...grid.w100,
        ...grid.pMini,
        '& .rc-slider-disabled': {
            background: 'none!important',
            ...item.disableWrapper,
        },
    },
    rangeSlider: {
        '& .rc-slider-track': {
            borderRadius: 2,
            height: 6,
        },
        '& .rc-slider-rail': {
            borderRadius: 2,
            height: 6,
        },
        '& .rc-slider-step': {
            borderRadius: 2,
            height: 6,
        },
    },
    blueAccent: {
        height: 16,
        '& .rc-slider-track': {
            backgroundColor: colors.blueAccent,
        },
        '& .rc-slider-rail': {
            backgroundColor: colors.blueAccent,
        },
        '& .rc-slider-step': {
            border: colors.blueAccent,
        },
    },
    blackLight: {
        height: 16,
        '& .rc-slider-track': {
            backgroundColor: `var(--var-module-ui-component-range-style-contrast-track, ${defaultThemeStyles['contrast-track']})`,
        },
        '& .rc-slider-rail': {
            backgroundColor: `var(--var-module-ui-component-range-style-contrast-rail, ${defaultThemeStyles['contrast-rail']})`,
        },
        '& .rc-slider-step': {
            border: `1px solid var(--var-module-ui-component-range-style-contrast-track, ${defaultThemeStyles['contrast-track']})`,
        },
    },
    darkBlue: {
        height: 16,
        '& .rc-slider-track': {
            backgroundColor: `var(--var-module-ui-component-range-style-colored-track, ${defaultThemeStyles['colored-track']})`,
        },
        '& .rc-slider-rail': {
            backgroundColor: `var(--var-module-ui-component-range-style-colored-rail, ${defaultThemeStyles['colored-rail']})`,
        },
        '& .rc-slider-step': {
            border: `1px solid var(--var-module-ui-component-range-style-colored-track, ${defaultThemeStyles['colored-track']})`,
        },
    },
    white: {
        height: 6,
        '& .rc-slider-track': {
            backgroundColor: `var(--var-module-ui-component-range-style-monochrome-track, ${defaultThemeStyles['monochrome-track']})`,
        },
        '& .rc-slider-rail': {
            backgroundColor: `var(--var-module-ui-component-range-style-monochrome-rail, ${defaultThemeStyles['monochrome-rail']})`,
        },
    },
    handle: {
        position: 'absolute',
        cursor: 'pointer',
        width: 16,
        height: 16,
        top: 0,
        borderRadius: `50%`,
        transform: 'translate(-50%)',
        backgroundColor: `var(--var-module-ui-component-range-style-handler, ${defaultThemeStyles.handler})`,
    },
    blueHandle: {
        backgroundColor: `${colors.blueAccent} !important`,
    },
    handleInactive: {
        backgroundColor: `var(--var-module-ui-component-range-style-handlerInactive, ${defaultThemeStyles.handlerInactive})`,
    },
}

addStyles(rangeStyles)
export default rangeStyles
