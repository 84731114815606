import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { IStyles } from '../../../theme'

const previewTableStyles: IStyles = {
    wrapper: {
        ...grid.full,
        ...grid.colCenter,
        ...grid.center,
        ...item.overNone,
    },
    loader: {
        zIndex: 0,
        ...item.blackOpacity,
    },
}

addStyles(previewTableStyles)
export default previewTableStyles
