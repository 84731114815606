import React, { FC, memo, useState } from 'react'
import { IChoice, IConfirmNotificationWithChoicesProps } from './ConfirmNotificationWithChoices-types'
import { styles } from './ConfirmNotificationWithChoices-styles'
import Checkbox from 'blocks.simple/checkbox/checkbox'

const ConfirmNotificationWithChoicesComponent: FC<IConfirmNotificationWithChoicesProps> = ({
    onSubmit,
    choices,
    text,
}) => {
    const [choicesData, setChoicesData] = useState<IChoice[]>(choices)

    const handleSubmit = () => {
        onSubmit(choicesData)
    }

    const handleChangeChoiceStatus = (value: string, isChecked: boolean) => {
        let updatedChoicesData = choicesData.map((choice) => {
            return choice.value === value ? { ...choice, isEnable: isChecked } : choice
        })

        setChoicesData(updatedChoicesData)
    }

    const renderChoices = () => {
        return choicesData.map(({ label, value, isEnable }) => {
            return (
                <Checkbox
                    key={value}
                    label={label}
                    onClick={(isChecked: boolean) => handleChangeChoiceStatus(value, isChecked)}
                    checked={isEnable}
                />
            )
        })
    }

    return (
        <div className={styles.ConfirmNotificationWithChoices}>
            <div onClick={handleSubmit} className={styles.ConfirmNotificationWithChoices__button}>
                {text}
            </div>
            <div className={styles.ConfirmNotificationWithChoices__info}>{renderChoices()}</div>
        </div>
    )
}

export const ConfirmNotificationWithChoices = memo(ConfirmNotificationWithChoicesComponent)
