import { defaultThemeStyles } from 'atoms/Marker/Marker-theme'
import { TMarkerStatusType } from 'atoms/Marker/Marker-types'

export const helpersMarker = {
    getColorByStatus: (status: TMarkerStatusType): string => {
        if (status === 'offline') {
            return `var(--var-module-ui-component-marker-style-offline, ${defaultThemeStyles.offline})`
        }

        if (status === 'online') {
            return `var(--var-module-ui-component-marker-style-online, ${defaultThemeStyles.online})`
        }

        if (status === 'error') {
            return `var(--var-module-ui-component-marker-style-error, ${defaultThemeStyles.error})`
        }

        return ''
    },
}
