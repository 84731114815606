import React, { useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import Icon from 'blocks.simple/icon/icon'
import HistoryFilter from '../__filter/history__filter'
import HistoryFilterList from '../__filterList/history__filterList'
import HistoryHeaderMethods from './history__header.local.methods'
import translate from 'core/translate'
import { getDownloadLink } from 'features/routes/utils'
import styles from './history__header.jcss'
import { isEmpty } from 'lodash'
import { getParamsForLink } from 'core/helpers/user'

const HistoryHeader = (p_: any) => {
    const user: any = useSelector<RootStateOrAny>((state) => state.user)
    const tokens = user.tokens
    const userData = user.data
    const mainUser = user.mainUser
    const { data, onFilterChange, getHistory, historyLoading } = HistoryHeaderMethods()
    const isFilterList = !isEmpty(p_.query)
    const params = getParamsForLink(tokens, mainUser.id, userData.id)
    const downloadLink = getDownloadLink(p_.query, params)
    const [byStartDateChecked, setByStartDateChecked] = useState(false)
    const [byEndDateChecked, setByEndDateChecked] = useState(false)

    return (
        <div>
            <div className={styles.header}>
                <HistoryFilter
                    byStartDateChecked={byStartDateChecked}
                    byEndDateChecked={byEndDateChecked}
                    setByStartDateChecked={setByStartDateChecked}
                    setByEndDateChecked={setByEndDateChecked}
                    location={p_.location}
                    data={data}
                    selected={p_.query}
                    onFilterChange={onFilterChange}
                />
                <div className={styles.downloadLink}>
                    <Icon
                        type="download_arrow"
                        size={24}
                        tooltip={{ html: translate('downloadReport') }}
                        onClick={() => getHistory(downloadLink)}
                        disabled={historyLoading}
                    />
                </div>
            </div>
            {isFilterList && (
                <HistoryFilterList
                    location={p_.location}
                    data={data}
                    selected={p_.query}
                    onFilterChange={onFilterChange}
                    setByStartDateChecked={setByStartDateChecked}
                    setByEndDateChecked={setByEndDateChecked}
                />
            )}
        </div>
    )
}

export default HistoryHeader
