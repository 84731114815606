import React from 'react'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import Button from 'blocks.simple/button/button'
import ItemAdvertisingCampaigns from '../../__item/_advertisingCampaigns/catalog__item_advertisingCampaigns'
import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'
import translate from 'core/translate'
import styles from './catalog__template_advertisingCampaigns.jcss'
import { routes } from 'features/routes'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { changeLocation } from 'features/routes'
import { ItemData } from '../../../../core/models/Template'

export default {
    wrapperClassName: styles.wrapper,
    similarTemplate: (path?: string, query?: any) => {
        return {
            getMethod:
                path === '/advertisingAds' || (query && query.reportType === 'advertisingAds')
                    ? 'getAdvertisements'
                    : 'getAdvertisingCampaigns',
        }
    },
    emptySearchText: () => translate('notFound'),
    emptyFolderText: (path?: string, search?: string) => {
        if (path === '/reports' && search && search.includes('advertisingAds')) return translate('noAdvertisement')

        return translate(path === '/advertisingAds' ? 'noAdvertisement' : 'noAdv')
    },
    mediaCellClassName: styles.mediaCellClassName,
    emptyIcon: 'not_allow',
    addItemButton: (self?: any, path?: any) => {
        return (
            <Button
                rounded="full_md"
                mod="withShadow"
                onClick={() =>
                    changeLocation(
                        path === '/advertisingAds'
                            ? `/${routes.addAdvertisingAds.path}`
                            : `/${routes.addAdvertisingCampaign.path}`
                    )
                }
            >
                {translate('add')}
            </Button>
        )
    },
    settingsPrefixes: 'advertisingCampaign',
    tile: ItemAdvertisingCampaigns,
    dateFilterOptions: () => [
        { id: 'completed', name: translate('completed') },
        { id: 'current', name: translate('current') },
        { id: 'upcoming', name: translate('upcoming') },
    ],
    viewOptions: () => [
        {
            id: 'tile',
            name: translate('tile'),
            icon: 'tile',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
        {
            id: 'list',
            name: translate('list'),
            icon: 'list',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
    ],
    sortOptions: () => [
        { id: '-createdAt', name: translate('sortCreatedAt') },
        { id: 'title', name: translate('byNameAsc') },
    ],
    getItemModel: (dataItem: ItemData, getSelected: (item: { __view?: { selected: boolean } }) => boolean) => {
        const item = { ...dataItem }
        item.cover = helpers.getBroadcastCover(item.coverSource)

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    getBackendModel: (item: { id: number }) => {
        return api.send('getAdvertisingCampaign', { id: item.id }, { hideLoader: true })
    },
    similarTemplateBackendModel: (item: { id: number }, path?: string) => {
        return path === '/advertisingAds'
            ? api.send('getAdvertisement', { id: item.id }, { hideLoader: true })
            : api.send('getAdvertisingCampaign', { id: item.id }, { hideLoader: true })
    },
    isEqual: (firstItem: { id: number }, secondItem: { id: number }) => firstItem.id === secondItem.id,
    onCreateListeners: [(cb: any, clearList: string[]) => api.addObserver('advertisingCampaignCreated', cb, clearList)],
    onUpdateListeners: [(cb: any, clearList: string[]) => api.addObserver('advertisingCampaignUpdated', cb, clearList)],
    onDeleteListeners: [(cb: any, clearList: string[]) => api.addObserver('advertisingCampaignDeleted', cb, clearList)],
    onAdvertisementDeleteListeners: [
        (cb: any, clearList: string[]) => api.addObserver('advertisementDeleted', cb, clearList),
    ],
    isFolder: () => {},
    fields: () => [
        { id: 'cover', name: translate('advertisingCampaign') },
        { id: 'title', name: translate('name') },
        { id: 'createdAt', name: translate('createdAt') },
    ],
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        cover: ({ cover }: { cover: { src: string; isIcon: boolean } }) => {
            return (
                <div className={styles.image}>
                    <Rectangle width={2} height={1} src={cover.src} contain={cover.isIcon} />
                </div>
            )
        },
        createdAt: ({ createdAt }: { createdAt: string }) => helpers.getFormattedLocalDate(createdAt, 'DD/MM/YYYY'),
    },
}
