import { addStyles } from 'ethcss'
import { grid, IStyles, mediaCSS } from '../../theme'
import { colors } from 'theme/colors'

const settingsStyles: IStyles = {
    version: {
        ...grid.row,
        ...grid.center,
        ...grid.mt_mini,
    },
    documentationLink: {
        ...grid.row,
    },
    documentationIcon: {
        ...grid.ml_micro,
    },
    space: {
        ...grid.space,
    },
    background: {
        background: `var(--var-module-settings-component-settingsItem-style-background, ${colors.greyLight})`,
    },
    settingsWrapper: {
        [mediaCSS.tab]: {
            paddingTop: 60,
        },
    },
    softwareLink: {
        textDecoration: 'underline',
        cursor: 'pointer',

        '&:hover': {
            textDecoration: 'none',
        },
    },
    inputWidth: {
        width: '370px',
    },
}

addStyles(settingsStyles)
export default settingsStyles
