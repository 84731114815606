import React, { useState } from 'react'
import translate from '../../core/translate'
import Content from '../../blocks.app/content/content'
import { FirmwareListHooks, IFirmware } from './FirmwareListHooks'
import styles from './firmwareList.jcss'
import Icon from '../../blocks.simple/icon/icon'
import Button from '../../blocks.simple/button/button'
import { cn } from 'ethcss'
import { colors } from '../../theme'
import moment from 'moment'
import Dropdown from '../../blocks.simple/dropdown/dropdown'
import { Typography } from '../../atoms/Typography'
import { Upload } from './__upload/Upload'

interface IFirmwareItem {
    item: IFirmware
    editFirmware: (id: number, modelNameForUpdate: string[]) => void
    onDelete: (id: number) => void
}

const FirmwareItem = (p_: IFirmwareItem) => {
    const item = p_.item
    const [editMode, setEditMode] = useState<boolean>(false)
    const [list, setList] = useState<{ name: string; label: string }>(convertOptions(item.modelNameForUpdate))

    function convertOptions(options: any, toData?: boolean) {
        if (!options || !options.length) return []

        if (toData) {
            return options.map((option: { name: string; label: string }) => option.name)
        }

        return options.map((option: string[]) => {
            return {
                name: option,
                label: option,
            }
        })
    }

    const onChange = (list: any) => {
        setList(list)
    }
    const onCancel = () => {
        setEditMode(false)
        setList(convertOptions(item.modelNameForUpdate))
    }
    const onSave = () => {
        setEditMode(false)
        p_.editFirmware(item.id, convertOptions(list, true))
    }

    return (
        <>
            <tr className={styles.row}>
                <td className={cn(styles.cell, styles.cellPlatform)}>{item.platform}</td>
                <td className={cn(styles.cell, styles.cellVersion)}>{item.version}</td>
                <td className={cn(styles.cell, styles.cellDeviceModels)}>
                    <div className={styles.modelsWrapper}>
                        {editMode ? (
                            <>
                                <Dropdown
                                    className={styles.domainsInput}
                                    list={list}
                                    options={[]}
                                    searchable={true}
                                    multiselectItems={true}
                                    onChange={(elements) => {
                                        onChange(elements)
                                    }}
                                />
                                <Typography className={styles.modelTip} type="text">
                                    {translate('addFirmwareModels')}
                                </Typography>
                                <div className={styles.buttonGroup}>
                                    <Button className={styles.btn} mod={'withBorder'} onClick={onCancel}>
                                        {translate('cancel')}
                                    </Button>
                                    <Button className={styles.btn} mod={'fill'} onClick={onSave}>
                                        {translate('save')}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                {item.modelNameForUpdate.join(', ')}
                                {item.addedBy && (
                                    <div className={styles.edit} onClick={() => setEditMode(true)}>
                                        <Icon
                                            type={'pencil'}
                                            color={colors.grey}
                                            tooltip={{ title: translate('edit') }}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </td>
                <td className={cn(styles.cell, styles.cellAddedBy)}>
                    {item.addedBy ? item.addedBy : translate('admin')}
                </td>
                <td className={cn(styles.cell, styles.cellFirmwareLink)}>
                    <a href={item.distributiveURL} download={true}>
                        {translate('link')}
                    </a>
                </td>
                <td className={cn(styles.cell, styles.cellDate)}>
                    {moment(item.createdAt).format('DD.MM.YYYY HH:SS')}
                </td>
                <td className={cn(styles.cell, styles.cellAction)}>
                    {item.addedBy && (
                        <Icon
                            type={'delete'}
                            color={colors.grey}
                            tooltip={{ title: translate('deleteFirmware') }}
                            onClick={() => p_.onDelete(item.id)}
                        />
                    )}
                </td>
            </tr>
        </>
    )
}
export const FirmwareList = () => {
    const {
        firmwareList,
        searchValue,
        onDelete,
        sortType,
        onSortChange,
        onSearchChange,
        uploadFirmware,
        isOpen,
        onOpen,
        onClose,
        editFirmware,
        isUploading,
        onClearSearch,
        deleteId,
        openDeleteConfirm,
    } = FirmwareListHooks(null)

    return (
        <Content title={translate('firmwareList')}>
            <div className={styles.header}>
                <div className={styles.searchWrapper}>
                    <input
                        className={styles.searchInput}
                        value={searchValue}
                        placeholder={translate('searchByModel')}
                        onChange={onSearchChange}
                    />
                    {searchValue ? (
                        <Button className={styles.searchBtn} onClick={onClearSearch}>
                            <Icon type={'close'} />
                        </Button>
                    ) : (
                        <Button className={styles.searchBtn}>
                            <Icon type={'search'} />
                        </Button>
                    )}
                </div>
                <Button className={styles.uploadFirmwareBtn} mod={'fill'} onClick={onOpen}>
                    {translate('uploadFirmware')}
                </Button>
            </div>
            <div className={styles.firmwaresTableWrapper}>
                <table className={styles.table}>
                    <tbody>
                        <tr>
                            <td className={cn(styles.cell, styles.cellPlatform)}>{translate('platform')}</td>
                            <td className={cn(styles.cell, styles.cellVersion)}>
                                <div
                                    className={styles.triangle}
                                    onClick={() => onSortChange(sortType === 'byVersion' ? '-byVersion' : 'byVersion')}
                                >
                                    <Icon type={sortType === 'byVersion' ? 'triangleUp' : 'triangleDown'} size={8} />
                                </div>
                                {translate('version')}
                            </td>
                            <td className={cn(styles.cell, styles.cellDeviceModels)}>{translate('deviceModels')}</td>
                            <td className={cn(styles.cell, styles.cellAddedBy)}>{translate('addedBy')}</td>
                            <td className={cn(styles.cell, styles.cellFirmwareLink)}>{translate('firmwareLink')}</td>
                            <td className={cn(styles.cell, styles.cellDate)}>
                                <div
                                    className={styles.triangle}
                                    onClick={() => onSortChange(sortType === 'byDate' ? '-byDate' : 'byDate')}
                                >
                                    <Icon type={sortType === 'byDate' ? 'triangleUp' : 'triangleDown'} size={8} />
                                </div>
                                {translate('date')}
                            </td>
                            <td className={cn(styles.cell, styles.cellAction)}></td>
                        </tr>
                        {firmwareList.map((item) => {
                            return (
                                <FirmwareItem
                                    key={item.id}
                                    item={item}
                                    editFirmware={editFirmware}
                                    onDelete={openDeleteConfirm}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {isOpen && <Upload onSave={uploadFirmware} onClose={onClose} isUploading={isUploading} />}
            {deleteId && (
                <div className={styles.wrapper}>
                    <div className={styles.deleteModal}>
                        <div>{translate('deleteFirmwareConfirm')}</div>
                        <div className={styles.btnGroup}>
                            <Button
                                mod={'withBorder'}
                                className={styles.modalBtn}
                                onClick={() => openDeleteConfirm(null)}
                            >
                                {translate('cancel')}
                            </Button>
                            <Button mod={'fill'} className={styles.modalBtn} onClick={() => onDelete(deleteId)}>
                                {translate('delete')}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Content>
    )
}
