import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { defaultThemeStyles } from '../catalog_select.theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { IStyles } from '../../../../theme'

const catalogSelectDevicesStyles: IStyles = {
    content: {
        ...grid.full,
        ...grid.col,
        position: 'relative',
        ...grid.p_mdPlus,
        ...grid.pb_big,
        backgroundColor: `var(--var-module-catalog-component-select-style-background, ${defaultThemeStyles.background})`,
    },
    toolbar: {
        ...grid.full,
        ...grid.col,
        borderLeft: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    buttons: {
        position: 'absolute',
        ...grid.p_mini,
        ...grid.rowCenter,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: `var(--var-module-catalog-component-select-style-button, ${defaultThemeStyles.button})`,
        borderTop: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        zIndex: 1000,
    },
}

addStyles(catalogSelectDevicesStyles)
export default catalogSelectDevicesStyles
