import { useState, useEffect } from 'react'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'

const initialMemoryStatsState = {
    memory: [
        {
            label: 'space',
            value: 0,
        },
    ],
    totalDiskSpace: 0,
    usageDiskSpace: 0,
    isLoaded: false,
}

type Memory = {
    label: string
    value: number
}

interface IData {
    memory: Memory[]
    totalDiskSpace: number
    usageDiskSpace: number
    isLoaded: boolean
}

let componentIsMounted: boolean = false
function DashboardMemoryMethods() {
    const [data, setData] = useState<IData>(initialMemoryStatsState)

    useEffect(() => {
        componentIsMounted = true
        getInfo()

        return () => {
            componentIsMounted = false
        }
    }, [])

    function getInfo() {
        api.send('getDiskUsageStats', {}).then((res: any) => {
            if (!componentIsMounted) {
                return
            }

            const result = []

            let usageDiskSpace = 0

            for (let key in res.usage) {
                if (res.usage.hasOwnProperty(key)) {
                    result.push({ label: translate(key), origLabel: key, value: res.usage[key] })
                    usageDiskSpace += res.usage[key]
                }
            }

            result.push({
                origLabel: 'free',
                label: translate('free'),
                value: res.availableDiskSpace || 0,
            })

            const data: any = {
                memory: result,
                usageDiskSpace,
            }

            if (res.totalDiskSpace) {
                data.totalDiskSpace = res.totalDiskSpace
            }
            setData({ ...data, isLoaded: true })
        })
    }

    return {
        s_: {
            data,
        },
    }
}

export default DashboardMemoryMethods
