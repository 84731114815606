import { useEffect, useState } from 'react'
import { api } from 'core/api/ConnectionManager'
// @ts-ignore
import beautify from 'xml-beautifier'
import validator from 'validator'
import merge from 'merge'
import parsePath from 'parse-filepath'
import normalizePath from 'normalize-path'
import config from './config'
import helpers from 'core/helpers'
import { TEXT_DISPLAY_AS } from 'core/models/Files'
import deepEqual from 'fast-deep-equal'

const getStylesByType = (type: string) => {
    const stylesByType: { [index: string]: string | boolean | null } = {
        backgroundColor: '#00000000',
        size: '14',
        fontId: null,
        fontFamily: '',
        bold: false,
        underline: false,
        italic: false,
        align: 'left',
        color: '#000000',
    }

    if (type === 'cell') {
        stylesByType.verticalAlign = 'middle'
    }

    return stylesByType
}

interface IState {
    content: string
    type: string
    refreshInterval: number
    fontList: { id: number; name: string; companyId: number | null }[]
    url: string
    rssContent: any | null
    localPath: string
    node: string
    row: number
    column: number
    style: any
    displayAs: string
    settings: {
        marquee: any
        textOrientation: string
    }
    isEdit: boolean
}

export interface ITextEditorProps {
    type: string
    model: {
        style: {
            fontFamily: string
        }
        content: string
    }
    query: { textId: string; files__type?: string }
    isEdit: boolean
    emitError: (error: string) => void
    small: boolean
    onlyText: boolean
    containerClassName: {} | string
    style: {}
    onChange: any
    defaultProps: any
    textEditorRef: any
}
let prevProps: any = {}
const TextEditorMethods = (p_: ITextEditorProps) => {
    const [state, setState] = useState<IState>({
        content: '',
        type: 'text',
        refreshInterval: 5000,
        fontList: [],
        url: '',
        rssContent: null,
        localPath: '',
        node: '',
        row: 0,
        column: 0,
        style: getStylesByType(p_.type),
        displayAs: 'default',
        settings: {
            marquee: config.marquee.defaultSettings,
            textOrientation: 'horizontal',
        },
        isEdit: false,
    })

    useEffect(() => {
        getFonts()

        if (isModelExist()) return getModel()

        if (!isEditTextExist()) return

        getTextModel()
    }, [])

    useEffect(() => {
        // if (JSON.stringify(prevProps) === JSON.stringify(p_)) return
        if (JSON.stringify(prevProps) === JSON.stringify(p_)) return
        if (!deepEqual(prevProps, p_) && prevProps !== null && p_.query.files__type !== 'text') return getModel()
        if (isEditTextExist() && !isEditEnable()) {
            getTextModel()
        }
        prevProps = p_
    }, [p_])

    const disableTextAnimation = () => {
        setState((prevState) => {
            return {
                ...prevState,
                settings: {
                    marquee: config.marquee.defaultSettings,
                    textOrientation: 'horizontal',
                },
            }
        })
    }

    const getFonts = () => {
        api.send('getFonts', { withCommon: true }).then((res: any) => {
            const { style } = state
            let fontList: { id: number; name: string; companyId: number | null }[] = []

            if (res.length) {
                res.forEach((font: { id: number; name: string; companyId: number | null }) => {
                    fontList.push({ id: font.id, name: font.name, companyId: font.companyId })
                })
                let defaultFont = fontList[0]

                if (p_.model && p_.model.style && p_.model.style.fontFamily) {
                    const font = fontList.find((font) => font.name === p_.model.style.fontFamily)

                    if (font) {
                        defaultFont = font
                    }
                }

                setState((prevState) => {
                    return {
                        ...prevState,
                        fontList,
                        style: { ...style, fontId: defaultFont.id, fontFamily: defaultFont.name },
                    }
                })
            }
        })
    }

    const getModel = () => {
        const { model } = p_
        const { style } = state
        if (model) {
            const updatedStyle = merge.recursive(true, style, model.style)
            setState((prevState) => {
                return {
                    ...prevState,
                    content: model.content,
                    style: updatedStyle,
                }
            })
        }
    }

    const getTextModel = () => {
        const { query } = p_
        const { style } = state

        api.send('getFile', { id: query.textId }).then(({ data }: any) => {
            if (data) {
                const updatedStyle = merge.recursive(true, style, data.style)

                let settings = {}

                if (data.settings) {
                    settings = helpers.deepCopy(data.settings)
                    settings = {
                        ...settings,
                        textOrientation: data.settings.textOrientation ? data.settings.textOrientation : 'horizontal',
                    }
                } else {
                    settings = {
                        marquee: config.marquee.defaultSettings,
                        textOrientation: 'horizontal',
                    }
                }

                setState((prevState) => {
                    return {
                        ...prevState,
                        ...data,
                        style: updatedStyle,
                        settings,
                        isEdit: true,
                    }
                })
            }
        })
    }

    const isModelExist = () => {
        const { model } = p_

        return !!model
    }

    const isEditEnable = () => {
        const { isEdit } = p_

        return isEdit
    }

    const isEditTextExist = () => {
        const { query } = p_

        return !!query.textId
    }

    const getEditLinkId = () => {
        const { query } = p_

        return query.textId
    }
    const onChange = (fields: { key: string; value: string }) => {
        const s_: any = { ...state }

        if (Array.isArray(fields)) {
            fields.forEach((field) => {
                if (field.key) {
                    s_[field.key] = field.value
                }
            })
        } else {
            s_[fields.key] = fields.value
        }

        setState(s_)
    }
    const onDisplayAsQrCode = (checked: boolean) => {
        const state_: any = { displayAs: checked ? TEXT_DISPLAY_AS.QR_CODE : TEXT_DISPLAY_AS.DEFAULT }

        if (checked) {
            state_.settings = {
                marquee: config.marquee.defaultSettings,
                textOrientation: state.settings.textOrientation,
            }
        }

        setState((prevState) => {
            return {
                ...prevState,
                ...state_,
            }
        })
    }
    const onChangeStyles = (fields: { key: string; value: string }) => {
        const s_ = { ...state }
        const style = { ...s_.style }

        if (Array.isArray(fields)) {
            fields.forEach((field) => {
                if (field.key) {
                    // if (field.key === 'underline' && field.value) {
                    //     style['textDecoration'] = 'underline'
                    // } else if (field.key === 'underline' && !field.value) {
                    //     style['textDecoration'] = 'none'
                    // }
                    style[field.key] = field.value
                }
            })
        } else {
            if (fields.key) {
                // if (fields.key === 'underline' && fields.value) {
                //     style['textDecoration'] = 'underline'
                // } else if (fields.key === 'underline' && !fields.value) {
                //     style['textDecoration'] = 'none'
                // }
                style[fields.key] = fields.value
            }
        }

        setState((prevState) => {
            return {
                ...prevState,
                style,
            }
        })
        p_.onChange({ style })
        // this.setState({style}, () => {
        //     p_.onChange({style})
        // })
    }
    const getRssContent = () => {
        const s_ = { ...state }
        const { emitError } = p_
        const url = s_.url

        if (
            validator.isURL(url, {
                protocols: ['http', 'https'],
                require_protocol: true,
            })
        ) {
            api.send('getRssContent', { url }).then(({ content }: any) => {
                if (content) {
                    setState((prevState) => {
                        return {
                            ...prevState,
                            rssContent: beautify(content),
                        }
                    })
                } else {
                    emitError('urlNotLoad')
                }
            })
        } else {
            emitError('linkFormatError')
        }
    }
    const addTag = (e: any) => {
        let { content } = state
        const target = e.target.parentNode

        if (
            target &&
            target.classList.contains('hljs-tag') &&
            target.querySelector('.hljs-attr') &&
            target.querySelector('.hljs-string')
        ) {
            const isExist: any = Array.from(target.querySelectorAll('.hljs-attr')).filter(
                (node: any) => node.innerText === 'smartplayer-path'
            )

            if (isExist.length) {
                let pathText = isExist[0].nextSibling.nextSibling.innerText.replace(/"/g, '')
                content += `{${pathText}}`

                setState((prevState) => {
                    return {
                        ...prevState,
                        content,
                    }
                })
            }
        }
    }
    const validatePath = (url: string, type: string) => {
        const { emitError } = p_

        if (!url) {
            emitError('localPathEmpty')
            return false
        } else {
            const parsedPath = parsePath(url)

            if (parsedPath.extname !== `.${type}`) {
                emitError('incorrectPathToFile')
                return false
            }

            if (!parsedPath.root) {
                emitError('diskCannotBeCyrillic')
                return false
            }

            return normalizePath(url).replace(/\//g, '\\')
        }
    }
    const saveMethod = () => {
        const { emitError } = p_

        const { type, content, url, refreshInterval, style, localPath, node, row, column, settings, displayAs } = state

        const model: {
            type: string
            data: {
                content: string
                style: object
                type: string
                settings: object
                refreshInterval?: number
                url?: string
                displayAs?: string
                localPath?: string
                row?: number
                column?: number
                node?: any
            }
        } = {
            type: 'text',
            data: {
                content,
                style,
                type,
                settings,
            },
        }

        if (!content.length) {
            emitError('textCantBeEmpty')
            return false
        }

        if (type === 'rss') {
            model.data.refreshInterval = refreshInterval
            if (!url) {
                emitError('urlCantBeEmpty')
                return false
            }

            model.data.url = url
        } else {
            model.data.displayAs = displayAs
        }

        if (type === 'xml' || type === 'csv') {
            model.data.localPath = localPath

            if (type === 'xml') {
                model.data.node = node

                if (!node) {
                    emitError('nodeNameEmpty')
                    return false
                }
            }

            if (type === 'csv') {
                model.data.row = row
                model.data.column = column
            }

            const validatedPath = validatePath(localPath, type)

            if (validatedPath) {
                model.data.localPath = validatedPath
            }
        }
        return model
    }

    if (p_.query.files__type === 'text') {
        p_.textEditorRef.current = { saveMethod }
    }

    return {
        state,
        setState,
        onChangeStyles,
        onChange,
        onDisplayAsQrCode,
        getRssContent,
        addTag,
    }
}

export default TextEditorMethods
