import { addStyles } from 'ethcss'
import { grid, item, mediaCSS, IStyles } from '../../../theme'
import { radiusSizes } from 'blocks.app/config'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { defaultThemeStyles } from './DisplayGroup-theme'

const mediaHeightQuery = '@media (max-height: 960px)'
const mediaWidth600pxQuery = '@media (max-width: 600px)'

const displaysAddGroupStyles: IStyles = {
    wrapper: {
        ...grid.col,
        ...grid.justify,
        ...grid.full,
        ...grid.p_md,
    },
    disable: {
        opacity: '0.5',
    },
    content: {
        ...grid.colCenter,
        ...grid.full,
        [mediaHeightQuery]: {
            overflowY: 'auto',
        },
    },
    noBoxShadow: {
        boxShadow: 'none !important',
    },
    title: {
        display: 'block',
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 24,

        [mediaCSS.tabMini]: {
            fontSize: 20,
            padding: '0 40px',
        },
    },
    footer: {
        ...grid.row,
        ...grid.center,
    },
    deleteSchedule: {
        borderRadius: radiusSizes.mini,
        border: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        ...grid.mt_mini,
        ...item.overNone,
        '& .groupSchedule:nth-child(odd)': {
            backgroundColor: `var(--var-module-device-component-group-style-deleteScheduleOdd, ${defaultThemeStyles.deleteScheduleOdd})`,
        },
    },
    deleteScheduleHeader: {
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    listItem: {
        ...grid.row,
        ...grid.justify,
        fontSize: 14,
        color: `var(--var-module-device-component-group-style-listItem, ${defaultThemeStyles.listItem})`,
        ...grid.pr_mini,
        ...grid.pl_mini,
    },
    formWrapper: {
        ...grid.w30,
        [mediaWidth600pxQuery]: {
            height: '100%',
        },
        [mediaCSS.tab]: {
            ...grid.w60,
        },
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
}

addStyles(displaysAddGroupStyles)
export default displaysAddGroupStyles
