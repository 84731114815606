import React from 'react'
import EditText from 'blocks.simple/editText/editText'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import Button from 'blocks.simple/button/button'
import EditableDropdown from 'blocks.simple/editableDropdown/editableDropdown'
import RangeVolume from 'blocks.simple/range/_volume/range_volume'
import { cn } from 'ethcss'
import filesHelpers from 'core/helpers/_files'
import { grid, item } from 'theme'
import styles from './advertisingCampaigns_add__contentSettings.jcss'
import translate from 'core/translate'
import { text as textStyles } from 'theme'

const repetitionCountOptions = () => {
    const values = ['1/2', '1/3', '1/4', '1/5', '1/6', '1/7', '1/8', '1/9']
    const options: { id: string; name: string }[] = []

    values.forEach((value: string) => {
        options.push({ id: value, name: value })
    })

    return options
}

const getVolumeValue = (item: { volume: number | string }) => {
    if (typeof item.volume === 'number') {
        return item.volume
    }

    return 0
}

const isVolumeSupport = (item: { source: { fileType: string } }) => {
    if (item.source) {
        return item.source.fileType === 'video' || item.source.fileType === 'audio'
    }

    return false
}

const AdvertisingCampaignsAddContentSettings = (p_: any) => {
    return (
        <div className={cn(styles.wrapper)}>
            <div className={styles.contentHeader}>
                <div className={cn(grid.w100)}>
                    <Dropdown
                        value={p_.selectedContentId}
                        label={translate('advContent')}
                        options={p_.files}
                        onChange={function (selected: { id: number }) {
                            p_.onSelectContent(selected.id)
                        }}
                    />
                </div>
            </div>
            <div className={styles.contentOptions}>
                <div className={cn(grid.p_mini, grid.w100)}>
                    <div className={textStyles.center}>
                        <div className={grid.pb_nano}>{translate('contentSettings')}:</div>
                        <div className={cn(item.ellipsis, textStyles.center)}>{p_.selectedFile.name}</div>
                    </div>
                </div>
                {isVolumeSupport(p_.selectedContent) && (
                    <div className={cn(grid.w100)}>
                        <label>{translate('volume')}</label>
                        <RangeVolume
                            onChange={function (volume: number) {
                                p_.onChangeVolume(volume)
                            }}
                            volume={getVolumeValue(p_.selectedContent)}
                            onAfterChange={p_.getVolumeDiff}
                        />
                    </div>
                )}
                <div className={cn(grid.w100)}>
                    <EditText
                        label={translate('duration')}
                        disabled={filesHelpers.isMedia(p_.selectedFile.fileType)}
                        type="number"
                        value={p_.selectedContent.duration}
                        onChange={function (value: number) {
                            p_.onChangeDuration(value)
                        }}
                    />
                </div>
                {!p_.isAdvBlock && (
                    <div className={cn(grid.w100)}>
                        <EditableDropdown
                            key={`repetitionCount_${p_.selectedContent.sourceId}`}
                            placeholder={`${p_.selectedContent.repetitionCount}`}
                            options={repetitionCountOptions()}
                            type="number"
                            label={translate('outputFrequency')}
                            onBlur={function (e: { value: string }) {
                                if (e.value) {
                                    p_.onChangeRepetitionCount(e.value)
                                }
                            }}
                            onChange={function ({ id }: { id: number }) {
                                p_.onChangeRepetitionCount(id)
                            }}
                            searchable
                            rounded={'full_mini'}
                            mod={'darkBlue'}
                        />
                    </div>
                )}
            </div>
            <div className={grid.space} />
            <div className={cn(grid.p_md, grid.rowCenter)}>
                <Button
                    mod={'withBorder'}
                    onClick={function () {
                        p_.onSelectContent(null)
                    }}
                    indentSize={'mini'}
                    rounded={'full_normal'}
                >
                    {translate('close')}
                </Button>
            </div>
        </div>
    )
}

export default AdvertisingCampaignsAddContentSettings
