import { IUserProps } from './user'

const UserMethods = (p_: IUserProps) => {
    const getAvatarSizes = (isIcon?: any) => {
        if (isIcon === 'x') {
            return { width: 40, height: 40 }
        }

        if (p_.minimize) {
            if (isIcon) {
                return 40
            }
            return { width: 40, height: 40 }
        }

        if (typeof p_.size !== 'object') {
            if (isIcon) {
                return p_.size
            }

            return { width: p_.size, height: p_.size }
        }

        if (isIcon) {
            if (p_.size.width) return p_.size.width
            if (p_.size.height) return p_.size.height
            return
        }

        return p_.size
    }

    return {
        getAvatarSizes,
    }
}

export default UserMethods
