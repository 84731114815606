import { addStyles } from 'ethcss'
import { grid, item, text } from 'theme'

const catalogTemplateRolesStyles = {
    catalogTemplateRoleIcon: {
        ...grid.flex,
        ...grid.normalCenter,
        ...item.pointer,
    },
    catalogTemplateRoleLabelWrapper: {
        ...grid.row,
        ...grid.noWrap,
        ...grid.normalCenter,
    },
    catalogTemplateRoleLabelChar: {
        ...grid.mr_mdPlus,
        ...grid.ml_md,
        ...text.darkCloud,
        ...text.up,
        ...text.md,
    },
    roleAddText: {
        textDecoration: 'underline',
    },
}

addStyles(catalogTemplateRolesStyles)
export default catalogTemplateRolesStyles
