import React from 'react'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import Range from 'blocks.simple/range/range'
import Tippy from 'blocks.simple/tippy/tippy'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './tableEditor__specialOptions.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { Typography } from 'atoms/Typography'

interface ITableEditorSpecialOptionsProps {
    onChange: (value: string, property: string, field?: string) => void
    selectedCell: { options: { [index: string]: any } }
    small: boolean
}

const specialOptionsCheckboxes: string[] = [
    /*'blink', 'wordBreak', 'breakOnCharacters', 'dataConnected'*/
]
const specialOptionsTimings: string[] = [/*'blinkSpeed', 'blinkSymmetry', 'pageFlipSpeed',*/ 'scrollSpeed']
const textIsTooLongOptions = [
    { id: 'cut', name: translate('cutRemainingText') },
    { id: 'scroll', name: translate('scrollForText') },
]

const TableEditorSpecialOptions = (props: ITableEditorSpecialOptionsProps) => {
    const { selectedCell, ...p_ } = props

    return (
        <div className={cn(styles.wrapper, grid.mb_mini)}>
            <div className={cn(styles.tableEditorSpecialOptions__cellOptions, p_.small ? grid.w100 : grid.w50)}>
                <Typography className={styles.title} type="title">
                    {translate('specialCellOptions')}
                </Typography>
                <div className={styles.content}>
                    {specialOptionsCheckboxes.map((field, index) => (
                        <Checkbox
                            key={index}
                            className={cn(grid.mb_md)}
                            onClick={(value: string) => p_.onChange(value, 'options', field)}
                            checked={!!selectedCell.options[field]}
                            label={translate(field)}
                        />
                    ))}
                    <Dropdown
                        label={translate('behaviorWhenTheTextIsTooLong')}
                        options={textIsTooLongOptions}
                        value={selectedCell.options.textOverflow}
                        onChange={(value) => p_.onChange(value.id, 'options', 'textOverflow')}
                    />
                </div>
            </div>
            <div className={cn(styles.tableEditorSpecialOptions__timings, p_.small ? grid.w100 : grid.w50)}>
                <Typography className={styles.title} type="title">
                    {translate('timings')}
                </Typography>
                <div className={styles.content}>
                    {specialOptionsTimings.map((field, index) => (
                        <div key={index} className={grid.mb_md}>
                            <Typography className={cn(grid.mb_micro)} type="title">
                                {translate(field)}
                            </Typography>
                            <Tippy title={selectedCell.options[field]}>
                                <Range
                                    min={0}
                                    max={100}
                                    step={1}
                                    disabled={field === 'scrollSpeed' && selectedCell.options.textOverflow !== 'scroll'}
                                    value={selectedCell.options[field]}
                                    onChange={function (value: string) {
                                        p_.onChange(value, 'options', field)
                                    }}
                                />
                            </Tippy>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default TableEditorSpecialOptions
