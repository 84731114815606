import { addStyles } from 'ethcss'
import { item, IStyles } from '../../theme'

const dotTooltipStyles: IStyles = {
    hidden: {
        ...item.hidden,
        ...item.abs,
        pointerEvents: 'none',
        opacity: 0,
        left: -99999,
    },
}

addStyles(dotTooltipStyles)
export default dotTooltipStyles
