import React from 'react'
import DashboardScheduleMethods from './dashboard__schedule.local.methods'
import Card from 'blocks.simple/card/card'
import Animation from 'blocks.simple/animation/animation'
import Link from 'blocks.simple/link/link'
import translate from 'core/translate'
import styles from './dashboard__schedule.jcss'
import { routes } from 'features/routes'
import helpers from 'core/helpers'
import { Typography } from 'atoms/Typography'
import { getURLSearchParamsString } from 'features/routes/utils'
import { cn } from 'ethcss'

interface IData {
    color: string
    label: string
    key: string
    value: number
}

type ScheduleItem = {
    key: string
    value: number
}

const displaysQueryParam = helpers.prefixQuery({ name: 'displays', field: 'filterList' })

const linkRoutes = {
    digitalSignageWithoutSchedule: {
        pathname: `/${routes.displays.path}`,
        search: getURLSearchParamsString({
            [displaysQueryParam]: JSON.stringify([
                {
                    name: 'hasActualSchedule',
                    type: 'select',
                    options: [
                        {
                            value: false,
                            label: 'withoutActualSchedule',
                            selected: true,
                        },
                    ],
                },
                {
                    name: 'type',
                    type: 'select',
                    options: [
                        {
                            value: 'digitalSignage',
                            label: 'digitalSignage',
                            selected: true,
                        },
                    ],
                },
            ]),
        }),
    },
}

const DashboardSchedule = (p_: any) => {
    const { s_ }: any = DashboardScheduleMethods()
    const itemRules = p_.rules ? p_.rules : 0

    const getTitle = () => translate('scheduleSummary')

    function renderScheduleStatsLinks({ key, value }: ScheduleItem) {
        if (key === 'digitalSignageWithoutSchedule') {
            return (
                <Link
                    className={styles.link}
                    //@ts-ignore
                    to={linkRoutes[key]}
                >
                    {value}
                </Link>
            )
        }

        return value
    }

    return (
        <Animation className={cn(p_.className)} style={{ order: itemRules.order }}>
            {!s_.data ? null : (
                <Card
                    title={getTitle()}
                    titleClassName={styles.title}
                    icon={{
                        type: 'date',
                        size: 20,
                        default: true,
                    }}
                >
                    <div className={styles.wrapper}>
                        {s_.data.map((item: IData, index: number) => (
                            <div key={index} className={styles.content}>
                                <Typography type="text" className={styles.text}>
                                    {translate(item.label)}
                                </Typography>
                                <div className={styles.info}>{renderScheduleStatsLinks(item)}</div>
                            </div>
                        ))}
                    </div>
                    <Typography type="text" className={styles.footer}>
                        {translate('schedulesInCompany')}: {s_.schedulesCount}
                    </Typography>
                </Card>
            )}
        </Animation>
    )
}

export default DashboardSchedule
