import { addStyles } from 'ethcss'
import { grid, indents, IStyles, mediaCSS } from 'theme'

const CARD_WIDTH = 882
const APP_LIST_HEIGHT = 350

export const styles: IStyles = {
    installedApplicationList: {
        height: `100%`,
        width: `100%`,
        ...grid.flex,
        ...grid.normalCenter,
        ...grid.center,
        padding: indents.normal,
        background: `rgba(0, 0, 0, 0.2)`,
    },
    installedApplicationList__close: {
        position: 'absolute',
        top: 20,
        right: 20,
    },
    installedApplicationList__card: {
        position: 'relative',
        width: CARD_WIDTH,
        maxHeight: 'none!important',
        [mediaCSS.tabMini]: {
            minHeight: 'auto',
        },
    },
    installedApplicationList__title: {
        paddingRight: `${indents.big}px!important`,
    },
    installedApplicationList__body: {
        padding: `${indents.mini}px ${indents.big}px`,
        [mediaCSS.tabMini]: {
            padding: 0,
        },
    },
    installedApplicationList__content: {
        height: APP_LIST_HEIGHT,
        overflow: 'auto',
    },
    installedApplicationList__item: {
        paddingBottom: indents.big,
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    installedApplicationList__loader: {
        ...grid.flex,
        ...grid.center,
        ...grid.normalCenter,
        height: `100%`,
    },
}

addStyles(styles)
