import helpers, { IRecordWithAny, IRecordWithStrings, IViewParams } from 'core/helpers'
import { omit } from 'lodash'
import { TDispositionTypes } from 'core/models/Catalogs'

export interface ICatalogField {
    id: string
    name: string
}

export interface IGetFilterQuery {
    parentKey: string
    type: string
    viewFields: ICatalogField[]
    viewFilter: IRecordWithAny
    searchKey: string
}

export const getFilterQuery = (props: IGetFilterQuery) => (query: IRecordWithStrings, viewParams: IViewParams) => {
    const { parentKey, viewFields, viewFilter, type, searchKey } = props
    const { sort, dateFilter } = viewParams

    const parsedQuery = helpers.getQuery(query, type)
    const filterQuery: IRecordWithAny = {
        sort,
        [searchKey]: parsedQuery.query,
        hasActualSchedule: query.hasActualSchedule,
        ...viewFilter,
    }

    if (dateFilter) {
        filterQuery.dateFilter = dateFilter
    }

    if (parentKey) {
        filterQuery[parentKey] = query[parentKey]
    }

    if (viewFields) {
        viewFields.forEach((field) => {
            if (query[field.id]) {
                filterQuery[field.id] = query[field.id]
            }
        })
    }

    return filterQuery
}

export const getQueryWithoutSearchQuery = (query: IRecordWithStrings) => {
    const SEARCH_QUERY_SUFFIX = 'query'
    const searchQueryProperty = Object.keys(query).find((key) => key.includes(SEARCH_QUERY_SUFFIX))
    return searchQueryProperty ? omit(query, [searchQueryProperty]) : query
}

export const isMap = (disposition: TDispositionTypes) => disposition === 'map'
