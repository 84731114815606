import { addStyles } from 'ethcss'
import { grid, item } from 'theme'
import { defaultThemeStyles } from 'blocks.app/sidebar/Sidebar-theme'
import { textSizes } from 'theme/media'
import { IStyles } from 'theme'

export const styles: IStyles = {
    menuItem: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.center,
        ...grid.p_mini,
        position: 'relative',
        cursor: 'pointer',
        color: `var(--var-module-sidebar-component-sidebar-style-menuTextHover, ${defaultThemeStyles.menuTextHover})`,
        ...item.transitionErase,

        '&:hover': {
            color: `var(--var-module-sidebar-component-sidebar-style-menuText, ${defaultThemeStyles.menuText})`,
        },
    },
    editingModalWrapper: {
        ...grid.w100,
        ...grid.row,
        ...grid.justify,
        ...grid.p_md,
    },
    editingModalTitle: {
        ...grid.w100,
        ...grid.mb_md,
        textAlign: 'center',
        fontSize: textSizes.md,
    },
    editingModalContentItem: {
        ...grid.w45,
        ...grid.mb_mini,
    },
    editingModalContentItemLabel: {
        ...grid.mb_mini,
        textAlign: 'center',
    },
    checkbox: {
        ...grid.mt_mini,
        ...grid.mb_md,
    },
}

addStyles(styles)
