import { colors } from 'theme'

export const defaultThemeStyles = {
    textMain: colors.grey,
    hover: colors.black,
    active: colors.blackLight,
    disable: colors.grey,
    background: colors.white,
    drop: colors.white,
    dropBackground: 'rgba(0, 0, 0, 0.45)',
}
