import { RefObject, useEffect } from 'react'

export const usePlayMediaSourceStream = (elementRef: RefObject<HTMLVideoElement>, srcObject: MediaStream) => {
    useEffect(() => {
        if (!elementRef || !elementRef.current) return

        elementRef.current.srcObject = srcObject

        return () => {
            if (elementRef && elementRef.current) {
                elementRef.current.srcObject = null
            }
        }
    }, [srcObject])

    return {}
}
