import { addStyles } from 'ethcss'
import { mediaCSS, IStyles, grid, item } from 'theme'
import { inspect } from 'util'
import { colors } from 'theme'

export const MIN_DROPDOWN_WIDTH_TABMINI = 100
export const MIN_DROPDOWN_WIDTH = {
    sort: 160,
    view: 105,
    selection: 140,
}

export const searchbarStyles: IStyles = {
    wrapper: {
        ...grid.row,
        ...grid.normalCenter,
        flexWrap: 'nowrap',
        [mediaCSS.tabMini]: {
            ...item.block,
        },
    },
    searchInputWrapper: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.space,
        ...grid.w100,
        flex: 'auto',
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    searchInput: {
        borderRight: `1px solid ${colors.greyLight}`,
        ...grid.w100,
        [mediaCSS.tabMini]: {
            ...grid.space,
            ...grid.wAuto,
        },
    },
    dropdownWrapper: {
        ...grid.space,
        ...grid.row,
        ...grid.noWrap,
        ...grid.justifyEnd,
        //...grid.w44,
        flex: 'auto',
        maxHeight: 1000,
        [mediaCSS.tabMini]: {
            ...grid.rowCenter,
            ...grid.wrap,
            ...grid.w100,
            ...grid.mt_mini,
        },
    },
    dropdownToggle: {
        [mediaCSS.tab]: {
            maxHeight: '0px!important',
            ...item.overNone,
        },
    },
    dropdownToggleIcon: {
        ...item.none,
        [mediaCSS.tabMini]: {
            ...item.block,
            ...grid.mr_mini,
            ...grid.ml_mini,
            ...grid.p_mini,
            ...item.circle,
        },
    },
    dropdownToggleIconActive: {
        ...item.cloud,
    },
    dropdown_sort: {
        minWidth: MIN_DROPDOWN_WIDTH.sort,
        [mediaCSS.tabMini]: {
            minWidth: MIN_DROPDOWN_WIDTH_TABMINI,
            ...grid.space,
        },
        [mediaCSS.mob]: {
            maxWidth: MIN_DROPDOWN_WIDTH.sort,
        },
    },
    dropdown_view: {
        minWidth: MIN_DROPDOWN_WIDTH.view,
        [mediaCSS.tabMini]: {
            minWidth: MIN_DROPDOWN_WIDTH_TABMINI,
            ...grid.space,
        },
        [mediaCSS.mob]: {
            maxWidth: MIN_DROPDOWN_WIDTH.view,
        },
    },
    dropdown_selection: {
        minWidth: MIN_DROPDOWN_WIDTH.selection,
        [mediaCSS.tabMini]: {
            minWidth: MIN_DROPDOWN_WIDTH_TABMINI,
            ...grid.space,
        },
        [mediaCSS.mob]: {
            maxWidth: MIN_DROPDOWN_WIDTH.selection,
        },
    },
    clearBorder: {
        border: 'none',
    },
    borderRight: {
        borderRight: `1px solid ${colors.greyLight} !important`,
    },
    customContent: {
        display: 'flex',
        marginTop: '20px',
        borderTop: `1px solid ${colors.greyLight}`,
        padding: '20px 0 0 0',
    },
}

addStyles(searchbarStyles)
