import React, { FC } from 'react'
import ModalAccept from 'blocks.simple/modal/_accept/modal_accept'
import { grid } from '../../../theme'
import { cn } from 'ethcss'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import { api } from 'core/api/ConnectionManager'
import styles from './dashboard__previewAndHistoryModals.jcss'

interface IPreviewAndHistoryModals {
    open: boolean
    type: string
    onClose: any
}

const PreviewAndHistoryModals: FC<IPreviewAndHistoryModals> = ({ open, onClose, type }) => {
    return (
        <ModalAccept
            open={open}
            title={
                type === 'delHistoryModal'
                    ? translate('deleteScreenshotHistory') + '?'
                    : translate('deleteWidgetPreviewFiles') + '?'
            }
            onClose={onClose}
            modalInnerClassName={styles.modal}
        >
            <div className={styles.wrapper}>
                <React.Fragment>
                    {type === 'delHistoryModal' ? (
                        <div className={styles.controls}>
                            <div className={cn(grid.row, grid.justify, grid.mb_mini)}>
                                <Button
                                    onClick={() => api.send('deleteScreenshots', {}).then(onClose)}
                                    mod="fill"
                                    className={cn(grid.mr_mini)}
                                >
                                    {translate('yes')}
                                </Button>
                                <Button mod={'withBorder'} onClick={onClose}>
                                    {translate('no')}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.controls}>
                            <div className={cn(grid.row, grid.justify, grid.mb_mini)}>
                                <Button
                                    onClick={() => api.send('deleteWebAppPreviews', {}).then(onClose)}
                                    mod="fill"
                                    className={cn(grid.mr_mini)}
                                >
                                    {translate('yes')}
                                </Button>
                                <Button mod={'withBorder'} onClick={onClose}>
                                    {translate('no')}
                                </Button>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            </div>
        </ModalAccept>
    )
}

export default PreviewAndHistoryModals
