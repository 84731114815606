import React from 'react'
import Icon from '../../../blocks.simple/icon/icon'
import styles from './widget.jcss'
import translate from '../../../core/translate'

type LocalizationField = {
    [index: string]: string
}

export interface IWidgetData {
    id: number
    createdAt: string
    fileSize: number
    thumbnail: string
    version: string
    title: LocalizationField
    shortDescription: LocalizationField
    description: LocalizationField
    wikiUrl: string
    cover: string
    previewImages: string[]
    thumbnailURL: string
}

interface IWidgetProps {
    data: IWidgetData
    lang: string
    setWidgetDownload: (widget: IWidgetData) => void
    setWidgetInfo: (widget: IWidgetData) => void
}

export const Widget = ({ data, lang, setWidgetDownload, setWidgetInfo }: IWidgetProps) => {
    return (
        <div className={styles.widget}>
            <div className={styles.widgetLeft} onClick={() => setWidgetInfo(data)}>
                <div>
                    <img src={data.thumbnailURL} />
                </div>
                <div className={styles.titleAndDesc}>
                    <div className={styles.title}>{data.title[lang]}</div>
                    <div className={styles.shortDesc}>{data.shortDescription[lang]}</div>
                </div>
            </div>
            <div
                className={styles.downloadBtn}
                onClick={() => {
                    setWidgetDownload(data)
                }}
            >
                {translate('upload')}
                <span className={styles.downloadIcon}>
                    <Icon type={'download'} size={14} color={'white'} />
                </span>
            </div>
        </div>
    )
}
