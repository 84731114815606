import { useState, useEffect } from 'react'
import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'
import helpersFiles from 'core/helpers/_files'
import { editor } from '../editor.local.methods'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { useDispatch } from 'react-redux'

const defaultSelectedTagsState = {
    promotional: [],
    backContent: [],
}

const defaultVolumeState = {
    promotional: 0,
    backContent: 0,
}

const EditorAutoPositionAudioMethods = (p_) => {
    const dispatch = useDispatch()
    const [tags, setTags] = useState([])
    const [selectedTags, setSelectedTags] = useState(defaultSelectedTagsState)
    const [algorithm, setAlgorithm] = useState('alternate')
    const [alternateSeconds, setAlternateSeconds] = useState(0)
    const [volume, setVolume] = useState(defaultVolumeState)

    useEffect(() => {
        api.send('getSourceTags', {}).then((res) => {
            const tags = helpersFiles.getTagsList(res)
            setTags(tags)
        })
    }, [])

    function onChangeTags(tag, type, deleted) {
        let list = {
            ...selectedTags,
        }

        if (deleted) {
            list[type] = list[type].filter((item) => item.name !== deleted)
        } else {
            list[type] = [...list[type], tag]
        }

        setSelectedTags(list)
    }

    function validate() {
        if (!selectedTags.promotional.length) {
            dispatch(emitError('emptyPromotionalError'))
            return false
        }

        if (algorithm === 'alternateWithTime' && !alternateSeconds) {
            dispatch(emitError('alternateWithTimeCantBeZero'))
            return false
        }

        return true
    }

    function onChangeVolume(value, type) {
        const volumes = { ...volume }
        volumes[type] = value
        setVolume(volumes)
    }

    function automaticCalculateAudioContentPosition() {
        if (!validate()) {
            return
        }

        const content = helpers.deepCopy(p_.content)
        let calculatedContent

        if (algorithm === 'alternate') {
            // чередование аудио контента (фоновый-рекламный-фоновый-рекламный)
            calculatedContent = automaticCalculateByAlternate(content)
        } else {
            // чередование аудио контента через определенное время
            // например: промежуток 30 секунд
            // получается что фоновые контенты на 30 секунд (если например 3 фоновых контента по 10 секунд и один ролик рекламы)
            // -> фоновый-фоновый-фоновый-рекламный
            calculatedContent = automaticCalculateByAlternateWithTime(content)
        }

        if (calculatedContent) {
            editor.setAutoPositionSoundContent(calculatedContent)
        }
    }

    function getFormattingContent(content) {
        const promotionalTagsIds = selectedTags.promotional.map((tag) => tag.id)
        const backContentTagsIds = selectedTags.backContent.map((tag) => tag.id)

        // выборка всех рекламных вставок по тегам
        const promotionalContent = content.filter((item) => {
            return item.source.tags.find((tag) => promotionalTagsIds.includes(tag.id))
        })
        const promotionalContentIds = promotionalContent.map((promotional) => promotional.sourceId)

        // Выборка фонового контента в зависимости от того, что есть в рекламе
        const backgroundContent = content.filter((item) => {
            // фоновый контент (теги), могут быть пустыми, в таком случае выборка всех файлов, которые не являются рекламной вставкой
            if (!backContentTagsIds.length) {
                return !promotionalContentIds.includes(item.sourceId)
            }

            if (!item.source.tags || !item.source.tags.length) {
                return item
            }

            return item.source.tags.find((tag) => backContentTagsIds.includes(tag.id))
        })

        // Меняем громкость у всех рекламных вставок
        promotionalContent.forEach((promotional) => {
            promotional.volume = volume.promotional
        })

        // Меняем громкость у всех фоновых вставок
        backgroundContent.forEach((background) => {
            background.volume = volume.backContent
        })

        return {
            promotionalContent,
            backgroundContent,
        }
    }

    const separateContentByTags = (contentArray, byTags) => {
        let allContent = helpers.deepCopy(contentArray)
        const tags = [].concat(byTags)
        const arrayOfArraysContent = []
        tags.forEach((tag) => {
            let arr = []
            allContent.forEach((content) => {
                for (let i = 0; i < content.source.tags.length; i++) {
                    if (tag.id === content.source.tags[i].id) {
                        arr.push(content)
                        break
                    }
                }
            })
            arrayOfArraysContent.push(arr)
        })
        return arrayOfArraysContent
    }

    const mergeInOrder = (arrayOfArrays) => {
        const commonArray = []
        let isEmptyArrays = checkEmptyArrays(arrayOfArrays)

        while (!isEmptyArrays) {
            arrayOfArrays.forEach((array) => {
                const element = array.pop()
                if (element) {
                    commonArray.push(element)
                }
            })

            isEmptyArrays = checkEmptyArrays(arrayOfArrays)
        }
        return commonArray
    }

    const checkEmptyArrays = (arrays) => {
        let isEmpty = true
        for (let i = 0; i < arrays.length; i++) {
            if (arrays[i].length > 0) {
                isEmpty = false
                break
            }
        }
        return isEmpty
    }

    const formatContentWithSeparateAndMerge = (content, tags) =>
        helpers.deepCopy(mergeInOrder(separateContentByTags(content, tags)))

    function automaticCalculateByAlternate(content) {
        let { promotionalContent, backgroundContent } = getFormattingContent(content)

        if (!promotionalContent.length) {
            dispatch(emitError('forCorrectAlgorithmWork'))
            return
        }

        promotionalContent = formatContentWithSeparateAndMerge(promotionalContent, selectedTags.promotional)

        if (selectedTags.backContent.length) {
            backgroundContent = formatContentWithSeparateAndMerge(backgroundContent, selectedTags.backContent)
        }

        if (!backgroundContent.length) {
            return null
        }

        let repeatlyPromotionalContent = []

        let counter = 0
        backgroundContent.forEach((backgroundItem) => {
            const promotionalItem = promotionalContent[counter]
            if (promotionalItem) {
                counter++
                repeatlyPromotionalContent.push(backgroundItem, promotionalItem)
            } else {
                counter = 1
                repeatlyPromotionalContent.push(backgroundItem, promotionalContent[0])
            }
        })

        return repeatlyPromotionalContent
    }

    function automaticCalculateByAlternateWithTime(content) {
        let { promotionalContent, backgroundContent } = getFormattingContent(content)

        if (!promotionalContent.length) {
            dispatch(emitError('forCorrectAlgorithmWork'))
            return
        }

        promotionalContent = formatContentWithSeparateAndMerge(promotionalContent, selectedTags.promotional)

        if (selectedTags.backContent.length) {
            backgroundContent = formatContentWithSeparateAndMerge(backgroundContent, selectedTags.backContent)
        }

        if (!backgroundContent.length) {
            return null
        }

        const interval = parseFloat(alternateSeconds)
        const formattingContent = []
        let blockDuration = 0
        let promotionIndex = 0

        backgroundContent.forEach((background, backgroundIndex) => {
            blockDuration += background.duration / 1000

            if (blockDuration > interval) {
                if (!formattingContent.length) {
                    formattingContent.push(background)
                    blockDuration = 0

                    return
                }
                formattingContent.push(...[promotionalContent[promotionIndex], background])

                promotionIndex++
                blockDuration = background.duration / 1000

                if (promotionIndex >= promotionalContent.length) {
                    promotionIndex = 0
                }

                if (backgroundContent.length - 1 === backgroundIndex) {
                    formattingContent.push(promotionalContent[promotionIndex])
                }

                return
            }

            formattingContent.push(background)

            if (backgroundContent.length - 1 === backgroundIndex) {
                if (promotionIndex >= promotionalContent.length) {
                    promotionIndex = 0
                }

                formattingContent.push(promotionalContent[promotionIndex])
            }
        })

        return formattingContent
    }

    return {
        s_: {
            tags,
            selectedTags,
            algorithm,
            alternateSeconds,
            volume,
        },
        methods: {
            onChangeTags,
            automaticCalculateAudioContentPosition,
            setAlgorithm,
            setAlternateSeconds,
            onChangeVolume,
        },
    }
}

export default EditorAutoPositionAudioMethods
