import { addStyles } from 'ethcss'
import { defaultThemeStyles } from '../../AppMenu.theme'
import { IStyles, grid, item, indents, textSizes } from 'theme'

export const styles: IStyles = {
    subMenuItem: {
        textDecoration: 'none',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        color: `var(--var-module-app-component-menu-style-textSub, ${defaultThemeStyles.textSub})`,
        '&:hover': {
            color: `var(--var-module-app-component-menu-style-hover, ${defaultThemeStyles.hover})`,
        },
    },
    subMenuItem_type_active: {
        color: `var(--var-module-app-component-menu-style-active, ${defaultThemeStyles.active})`,
        position: 'relative',
        '&:before': {
            position: 'absolute',
            top: '50%',
            marginLeft: 24,
            marginTop: -7,
            color: `var(--var-module-app-component-menu-style-active, ${defaultThemeStyles.active})`,
            content: '"•"',
        },
    },
    subMenuItem_type_active_x: {
        '&:before': {
            content: '""',
        },
    },
    subMenuItem_type_minimizeActive: {
        backgroundColor: `var(--var-module-app-component-menu-style-background, ${defaultThemeStyles.background})`,
        color: `var(--var-module-app-component-menu-style-active, ${defaultThemeStyles.active})`,
    },
    subMenuItem_type_minimize: {
        ...item.pointer,
        textDecoration: 'none',
        '&:hover': {
            color: `var(--var-module-app-component-menu-style-hover, ${defaultThemeStyles.hover})`,
        },
    },
    subMenuItem_type_disable: {
        cursor: 'not-allowed !important',
    },
    subMenuItem__text_type_with_transition: {
        ...item.transitionErase,
    },
    subMenuItem__text: {
        fontSize: textSizes.normal,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        opacity: 1,
    },
    subMenuItem__text_type_default: {
        ...grid.w80,
    },
    subMenuItem__text_type_minimize: {
        ...grid.w100,
    },
    subMenuItem__divider: {
        ...grid.w20,
    },
    subMenuItem__content: {
        ...grid.w100,
        ...grid.row,
        padding: indents.miniPlus,
    },
}

addStyles(styles)
