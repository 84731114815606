import React from 'react'
import FolderEditMethods, { IFolderEditProps } from './folder__edit.local.methods'
import Modal from 'blocks.simple/modal/modal'
import EditText from 'blocks.simple/editText/editText'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './folder__edit.jcss'
import { grid } from 'theme'
import { text as textStyles } from 'theme'
import { Typography } from 'atoms/Typography'

const FolderEdit = (p_: IFolderEditProps) => {
    const { s_, methods } = FolderEditMethods(p_)

    return (
        <Modal onClose={() => p_.onClose()} open={p_.open}>
            <form
                onClick={(e) => {
                    e.stopPropagation()
                }}
                onSubmit={methods.onSave}
                className={styles.wrapper}
            >
                <div className={styles.title}>
                    <Typography type="title" className={textStyles.mdPlus}>
                        {translate('folderOptions')}
                    </Typography>
                </div>
                <div className={styles.content}>
                    <div className={styles.form}>
                        <div className={styles.inputWrapper}>
                            <Typography className={styles.inputTitle} type="title">
                                {translate('name')}
                            </Typography>
                            <div className={grid.w100}>
                                <EditText
                                    value={s_.name}
                                    onChange={(name: string) => {
                                        methods.onChangeName(name)
                                    }}
                                    className={cn(textStyles.center, textStyles.normal)}
                                />
                            </div>
                        </div>
                        <div className={styles.inputWrapper}>
                            <Typography className={styles.inputTitle} type="title">
                                {translate('parentFolder')}
                            </Typography>
                            <div className={grid.w100}>
                                <Dropdown
                                    value={s_.folderId}
                                    options={p_.folders}
                                    searchable
                                    centered
                                    type="tree"
                                    placeholder={translate('main')}
                                    onChange={methods.onChangeFolder}
                                />
                            </div>
                        </div>
                        <div className={styles.inputWrapper}>
                            <Typography className={styles.inputTitle} type="title">
                                {translate('description')}
                            </Typography>
                            <div className={grid.w100}>
                                <EditText
                                    type={'area'}
                                    value={s_.description}
                                    onChange={(description: string) => {
                                        methods.onChangeDescription(description)
                                    }}
                                    className={cn(textStyles.normal)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button onClick={p_.onClose} className={grid.mr_md}>
                        {translate('cancel')}
                    </Button>
                    <Button type="submit" mod="fill">
                        {translate('save')}
                    </Button>
                </div>
            </form>
        </Modal>
    )
}

export default React.memo(FolderEdit)
