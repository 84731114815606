import React, { FunctionComponent, memo } from 'react'
import { IVolumeLevelComponent } from './VolumeLevel-types'
import { volumeLevelStyles } from './VolumeLevel-styles'
import translate from '../../../core/translate'
import Icon from '../../../blocks.simple/icon/icon'

const VolumeLevelComponent: FunctionComponent<IVolumeLevelComponent> = ({ volume }) => {
    const activeStyles = {
        width: `${volume}%`,
    }

    const pointerStyles = {
        left: `${volume}%`,
    }

    return (
        <div className={volumeLevelStyles.VolumeLevel}>
            <Icon type={'playingVolume'} color={'white'} size={28} />
            <div className={volumeLevelStyles.VolumeLevel__content}>
                <div className={volumeLevelStyles.VolumeLevel__active} style={activeStyles} />
                <div className={volumeLevelStyles.VolumeLevel__pointer} style={pointerStyles} />
            </div>
        </div>
    )
}

export const VolumeLevel = memo(VolumeLevelComponent)
