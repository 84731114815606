import { addStyles } from 'ethcss'
import { IStyles, grid, text } from 'theme'

export const styles: IStyles = {
    EmptyList__wrapper: {
        ...grid.space,
        ...grid.colCenter,
    },
    EmptyList: {
        ...grid.colCenter,
    },
    EmptyList__content: {
        ...text.normal,
        ...grid.row,
        ...grid.center,
        ...grid.mb_md,
    },
    EmptyList__description: {
        ...grid.pt_mini,
        ...grid.rowCenter,
    },
    EmptyList__text: {
        ...grid.w50,
    },
    EmptyList__title: {
        ...text.up,
    },
    EmptyList__info: {
        ...grid.fullWidth,
        ...grid.pt_mini,
        ...text.center,
    },
    EmptyList__info_type_default: {
        ...grid.mb_mini,
    },
}

addStyles(styles)
