import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { indents } from 'blocks.app/config'
import { defaultThemeStyles } from '../index.theme'
import { colors, IStyles, mediaCSS } from '../../../../theme'

const iconWrapperStyles = (side: string) => ({
    ...grid.rowCenter,
    ...item.transitionErase,
    ...item.abs,
    zIndex: 10,
    width: 30,
    height: 30,
    top: indents.nano,
    [side]: indents.nano,
})

const defaultTectColor = '#b3b3b3'

const ItemFilesStyles: IStyles = {
    wrapper() {
        return {
            ...grid.fullWidth,
            ...item.transitionErase,
            color: `var(--var-module-catalog-component-item-style-idle, ${defaultThemeStyles.idle})`,
            [`&:hover .${this.video}`]: {
                ...item.visible,
            },
            [`&:hover .${this.inputWrapper}`]: {
                opacity: 1,
            },
            [`&:hover .${this.backLayer}`]: {
                ...item.blackOpacity,
            },
            [`&:hover .${this.icon}`]: {
                ...item.visible,
            },
        }
    },
    itemWrapper: {
        cursor: 'pointer',
        ...grid.colCenter,
        ...grid.w100,
    },
    content: {
        position: 'relative',
        ...grid.full,
        ...grid.rowCenter,
    },
    wrapperActive() {
        return {
            [`& .${this.video}`]: {
                ...item.visible,
            },
            [`& .${this.inputWrapper}`]: {
                opacity: 1,
            },
            [`& .${this.backLayer}`]: {
                backgroundColor: `var(--var-module-catalog-component-item-style-active, ${defaultThemeStyles.active})`,
            },
        }
    },
    inputWrapper: {
        ...iconWrapperStyles('right'),
        opacity: 0,

        [mediaCSS.tab]: {
            opacity: 1,
        },
    },
    downloadItem: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: colors.greyLight,
        width: '100%',
        height: '100%',
    },
    rotateWrapper: {
        ...iconWrapperStyles('left'),
    },
    back: {
        position: 'absolute',
        ...grid.full,
        ...grid.rowCenter,
        top: 0,
    },
    backLayer: {
        position: 'absolute',
        ...grid.full,
        zIndex: 1,
    },
    item: {
        ...grid.w80,
        ...grid.mb_micro,
        overflow: 'hidden',
        borderRadius: 5,
    },
    dragItem: {
        cursor: 'move',
    },
    video: {
        visibility: 'hidden',
    },
    text: {
        ...grid.full,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.center,
        textAlign: 'center',
        color: `var(--var-module-catalog-component-item-style-textMain, ${defaultThemeStyles.textMain})`,
    },
    icon: {
        position: 'absolute',
        visibility: 'hidden',
        bottom: indents.micro,
        right: indents.micro,
        zIndex: 2,
    },
    errorIcon: {
        position: 'absolute',
        bottom: indents.micro,
        right: indents.micro,
        zIndex: 2,
    },
    tooltip: {
        textAlign: 'center',
        ...grid.w100,
    },
    itemIconWrapper: {
        position: 'absolute',
        ...grid.rowCenter,
        bottom: '5%',
        right: '6%',
        padding: 1,
        width: '32%',
        height: '32%',
        background: `var(--var-module-catalog-component-item-style-textMain, ${defaultThemeStyles.textMain})`,
        borderRadius: '50%',
    },
    itemIcon: {
        ...grid.flex,
    },
    itemTextColor: {
        color: `var(--var-module-ui-component-typography-style-textSub, ${defaultTectColor})`,
    },
    progressBarStyle: {
        width: '34px',
        height: '34px',
    },
    downloadPercent: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '12px',
        color: colors.blackLight,
    },
}

addStyles(ItemFilesStyles)
export default ItemFilesStyles
