import { addStyles } from 'ethcss'
import { indents, IStyles, textSizes } from 'theme'
import grid from 'theme/grid/styles'

export const styles: IStyles = {
    inactivitySettings: {
        marginBottom: indents.md,
    },
    inactivitySettings__content: {},
    inactivitySettings__text: {
        paddingBottom: indents.md,
    },
    inactivitySettings__actions: {
        ...grid.flex,
        ...grid.row,
        ...grid.justify,
        paddingBottom: indents.md,
    },
    inactivitySettings__switch: {
        ...grid.flex,
        ...grid.row,
        ...grid.justify,
        paddingBottom: indents.mini,
    },
    inactivitySettings__editText: {
        width: '20%',
    },
    inactivitySettings__info: {
        fontSize: textSizes.mini,
    },
    inactivitySettings__input: {
        textAlign: 'center',
    },
}

addStyles(styles)
