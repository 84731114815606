import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { IStyles, mediaCSS } from '../../../../theme'

const catalogTemplateDisplaysStyles: IStyles = {
    status: {
        ...grid.p_micro,
        ...item.rounded_full_mini,
        ...item.white,
        ...grid.mr_mini,
    },
    name: {
        ...grid.w100,
        ...grid.row,
        ...grid.noWrap,
        ...grid.normalCenter,
        textAlign: 'left',
    },
    listStatus: {
        marginRight: 10,
    },
    statusWrapper: {
        minWidth: 180,
        textAlign: 'center',
    },
    mediaCellClassName: {
        [mediaCSS.tab]: {
            ...grid.col4,
        },
        [mediaCSS.tabMini]: {
            width: '50% !important',
        },
    },
}

addStyles(catalogTemplateDisplaysStyles)
export default catalogTemplateDisplaysStyles
