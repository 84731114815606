import React from 'react'
// @ts-ignore
import BareHighlight from 'react-fast-highlight/lib/js/BareHighlight'
// @ts-ignore
import hljs from 'highlight.js/lib/highlight'
// @ts-ignore
import xml from 'highlight.js/lib/languages/xml'

import styles from './highlight.jcss'

hljs.registerLanguage('xml', xml)

const Highlight = (p_: { content: string; languages: string[] }) => (
    <div className={styles.wrapper}>
        <BareHighlight languages={p_.languages} highlightjs={hljs}>
            {p_.content}
        </BareHighlight>
    </div>
)

Highlight.defaultProps = {
    languages: ['xml'],
}

export default Highlight
