import React, { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { styles } from './Draggable-styles'
import { cn } from 'ethcss'
import {
    MoveBorder,
    DraggableProps,
    MousePosition,
    Position,
    ResizeDirection,
    ResizeDirections,
    StartPosition,
} from './Draggable-types'
import throttle from 'lodash/throttle'

const mockFunction = () => {}

const mockResizeDirections: ResizeDirections = {
    top: true,
    bottom: true,
    right: true,
    left: true,
    topRight: true,
    topLeft: true,
    bottomRight: true,
    bottomLeft: true,
}

const initialStartPosition = {
    left: 0,
    top: 0,
    deltaRight: 0,
    deltaLeft: 0,
    deltaTop: 0,
    deltaBottom: 0,
}

const initialMoveBorder: MoveBorder = {
    left: 0,
    top: 1,
    right: 1,
    bottom: 0,
}

export const Draggable: FC<DraggableProps> = ({
    isActive = true,
    isDraggable = true,
    isResizable = true,
    width = 0.3,
    height = 0.3,
    top = 0,
    left = 0,
    minWidth = 0,
    maxWidth = 1,
    minHeight = 0.1,
    maxHeight = 1,
    zIndex,
    resizableZIndex,
    resizeSquareType,
    isFixed = false,
    isRenderResizeElements = true,
    children,
    isBorder = true,
    borderColor = 'transparent',
    activeBorderColor = 'transparent',
    moveCursor = 'move',
    cursor = 'default',
    classNames = '',
    onMouseDown = mockFunction,
    target = null,
    parentRect,
    onResizeEnd = mockFunction,
    onResize = mockFunction,
    resizeDirections = mockResizeDirections,
    onDragEnd = mockFunction,
    onDrag = mockFunction,
}) => {
    const initialPosition = useMemo(() => {
        return {
            width,
            height,
            top,
            left,
        }
    }, [width, height, top, left])

    const wrapperRef = useRef<HTMLDivElement | null>(null)
    const [position, setPosition] = useState<Position>(initialPosition)
    const [isResizeOn, toggleResize] = useState<boolean>(false)
    const [resizeDirection, setResizeDirection] = useState<ResizeDirection | null>(null)
    const [moveBorder, setMoveBorder] = useState<MoveBorder>(initialMoveBorder)
    const [startPosition, setStartPosition] = useState<StartPosition>(initialStartPosition)
    const [isDragOn, toggleDrag] = useState<boolean>(false)

    const positionRef = useRef<Position>(position)
    positionRef.current = position

    const startPositionRef = useRef<StartPosition>(startPosition)
    startPositionRef.current = startPosition

    const resizeDirectonRef = useRef<ResizeDirection | null>(resizeDirection)
    resizeDirectonRef.current = resizeDirection

    useEffect(() => {
        if (!initialPosition) return

        setPosition(initialPosition)
    }, [initialPosition])

    const handleResizeStart = (e: React.MouseEvent, direction: ResizeDirection) => {
        e.stopPropagation()

        const rect = getRect()

        if (!rect) return

        const lastPosition = positionRef.current

        setMoveBorder({
            ...initialMoveBorder,
        })

        const relLeft = (e.pageX - rect.left) / rect.width
        const relTop = (e.pageY - rect.top) / rect.height
        const left = relLeft - lastPosition.left
        const top = relTop - lastPosition.top
        const deltaRight = relLeft - (lastPosition.left + lastPosition.width)
        const deltaLeft = relLeft - lastPosition.left
        const deltaBottom = relTop - (lastPosition.top + lastPosition.height)
        const deltaTop = relTop - lastPosition.top

        setStartPosition({
            left,
            top,
            deltaRight,
            deltaLeft,
            deltaBottom,
            deltaTop,
        })

        toggleResize(true)
        setResizeDirection(direction)
    }

    const handleChangePosition = (newPosition: Position) => {
        setPosition(newPosition)
    }

    const handleResizeEnd = () => {
        toggleResize(false)
        setResizeDirection(null)
        setMoveBorder(initialMoveBorder)
        setStartPosition(initialStartPosition)
        onResizeEnd(positionRef.current)
    }

    const getRect = () => {
        if (parentRect) return parentRect

        if (!wrapperRef) return null

        const ref = wrapperRef as React.MutableRefObject<HTMLDivElement>

        const parentNode = ref.current.parentNode

        if (!parentNode) return null
        return (parentNode as HTMLDivElement).getBoundingClientRect()
    }

    const handleResize = useCallback(
        (mousePosition: MousePosition) => {
            if (!mousePosition) return

            const lastResizeDirection = resizeDirectonRef.current
            const lastPosition = positionRef.current
            const lastStartPosition = startPositionRef.current

            if (!lastResizeDirection || !lastPosition) return

            switch (lastResizeDirection) {
                case 'left': {
                    return onResizeLeft(mousePosition, lastPosition, lastStartPosition)
                }
                case 'right': {
                    return onResizeRight(mousePosition, lastPosition, lastStartPosition)
                }
                case 'top': {
                    return onResizeTop(mousePosition, lastPosition, lastStartPosition)
                }
                case 'bottom': {
                    return onResizeBottom(mousePosition, lastPosition, lastStartPosition)
                }
                case 'topLeft': {
                    return onResizeTopLeft(mousePosition, lastPosition, lastStartPosition)
                }
                case 'topRight': {
                    return onResizeTopRight(mousePosition, lastPosition, lastStartPosition)
                }
                case 'bottomLeft': {
                    return onResizeBottomLeft(mousePosition, lastPosition, lastStartPosition)
                }
                case 'bottomRight': {
                    return onResizeBottomRight(mousePosition, lastPosition, lastStartPosition)
                }
            }
        },
        [resizeDirections]
    )

    const onResizeRight = (mousePosition: MousePosition, lastPosition: Position, lastStartPosition: StartPosition) => {
        const { mouseLeft } = mousePosition
        const { left, width } = lastPosition

        const leftDistance = getValidXPos(mouseLeft - lastStartPosition.deltaRight)
        const newWidth = getValidWidth(leftDistance - left)

        const newPosition = {
            ...lastPosition,
            width: newWidth,
        }

        onResize(newPosition, 'right')
        return handleChangePosition(newPosition)
    }

    const onResizeLeft = (mousePosition: MousePosition, lastPosition: Position, lastStartPosition: StartPosition) => {
        const { mouseLeft } = mousePosition
        const { left, width } = lastPosition

        const leftDistance = getValidXPos(mouseLeft - lastStartPosition.deltaLeft)
        const rightBorder = left + width
        const newWidth = getValidWidth(rightBorder - leftDistance)
        const newLeft = rightBorder - newWidth

        const newPosition = {
            ...lastPosition,
            left: newLeft,
            width: newWidth,
        }

        onResize(newPosition, 'left')
        return handleChangePosition(newPosition)
    }

    const onResizeTop = (mousePosition: MousePosition, lastPosition: Position, lastStartPosition: StartPosition) => {
        const { mouseTop } = mousePosition
        const { top, height } = lastPosition

        const topDistance = getValidYPos(mouseTop - lastStartPosition.deltaTop)
        const bottomBorder = height + top
        const newHeight = getValidHeight(bottomBorder - topDistance)
        const newTop = bottomBorder - newHeight

        const newPosition = {
            ...lastPosition,
            height: newHeight,
            top: newTop,
        }

        onResize(newPosition, 'top')
        return handleChangePosition(newPosition)
    }

    const onResizeBottom = (mousePosition: MousePosition, lastPosition: Position, lastStartPosition: StartPosition) => {
        const { mouseTop } = mousePosition
        const { top } = lastPosition

        const topDistance = getValidYPos(mouseTop - lastStartPosition.deltaBottom)
        const newHeight = getValidHeight(topDistance - top)

        const newPosition = {
            ...lastPosition,
            height: newHeight,
        }

        onResize(newPosition, 'bottom')
        return handleChangePosition(newPosition)
    }

    const onResizeTopLeft = (
        mousePosition: MousePosition,
        lastPosition: Position,
        lastStartPosition: StartPosition
    ) => {
        const { mouseTop, mouseLeft } = mousePosition
        const { top, height } = lastPosition

        const leftDistance = getValidXPos(mouseLeft - lastStartPosition.deltaLeft)
        const rightBorder = left + width
        const newWidth = getValidWidth(rightBorder - leftDistance)
        const newLeft = rightBorder - newWidth

        const topDistance = getValidYPos(mouseTop - lastStartPosition.deltaTop)
        const bottomBorder = height + top
        const newHeight = getValidHeight(bottomBorder - topDistance)
        const newTop = bottomBorder - newHeight

        const newPosition = {
            ...lastPosition,
            height: newHeight,
            left: newLeft,
            width: newWidth,
            top: newTop,
        }

        onResize(newPosition, 'topLeft')
        return handleChangePosition(newPosition)
    }

    const onResizeTopRight = (
        mousePosition: MousePosition,
        lastPosition: Position,
        lastStartPosition: StartPosition
    ) => {
        const { mouseTop, mouseLeft } = mousePosition
        const { top, height } = lastPosition

        const leftDistance = getValidXPos(mouseLeft - lastStartPosition.deltaRight)
        const newWidth = getValidWidth(leftDistance - left)

        const topDistance = getValidYPos(mouseTop - lastStartPosition.deltaTop)
        const bottomBorder = height + top
        const newHeight = getValidHeight(bottomBorder - topDistance)
        const newTop = bottomBorder - newHeight

        const newPosition = {
            ...lastPosition,
            height: newHeight,
            width: newWidth,
            top: newTop,
        }

        onResize(newPosition, 'topRight')
        return handleChangePosition(newPosition)
    }

    const onResizeBottomLeft = (
        mousePosition: MousePosition,
        lastPosition: Position,
        lastStartPosition: StartPosition
    ) => {
        const { mouseTop, mouseLeft } = mousePosition
        const { top } = lastPosition

        const leftDistance = getValidXPos(mouseLeft - lastStartPosition.deltaLeft)
        const rightBorder = left + width
        const newWidth = getValidWidth(rightBorder - leftDistance)
        const newLeft = rightBorder - newWidth

        const topDistance = getValidYPos(mouseTop - lastStartPosition.deltaBottom)
        const newHeight = getValidHeight(topDistance - top)

        const newPosition = {
            ...lastPosition,
            height: newHeight,
            left: newLeft,
            width: newWidth,
        }

        onResize(newPosition, 'bottomLeft')
        return handleChangePosition(newPosition)
    }

    const onResizeBottomRight = (
        mousePosition: MousePosition,
        lastPosition: Position,
        lastStartPosition: StartPosition
    ) => {
        const { mouseTop, mouseLeft } = mousePosition
        const { top } = lastPosition

        const leftDistance = getValidXPos(mouseLeft - lastStartPosition.deltaRight)
        const newWidth = getValidWidth(leftDistance - left)

        const topDistance = getValidYPos(mouseTop - lastStartPosition.deltaBottom)
        const newHeight = getValidHeight(topDistance - top)

        const newPosition = {
            ...lastPosition,
            height: newHeight,
            width: newWidth,
        }

        onResize(newPosition, 'bottomRight')
        return handleChangePosition(newPosition)
    }

    const handleDragStart = (e: React.MouseEvent) => {
        e.stopPropagation()

        const rect = getRect()

        if (!rect) return

        const lastPosition = positionRef.current

        setMoveBorder({
            ...initialMoveBorder,
            right: 1 - lastPosition.width,
            top: 1 - lastPosition.height,
        })

        const relLeft = (e.pageX - rect.left) / rect.width
        const relTop = (e.pageY - rect.top) / rect.height
        const left = relLeft - lastPosition.left
        const top = relTop - lastPosition.top
        const deltaRight = relLeft - (lastPosition.left + lastPosition.width)
        const deltaLeft = relLeft - lastPosition.left
        const deltaBottom = relTop - (lastPosition.top + lastPosition.height)
        const deltaTop = relTop - lastPosition.top

        setStartPosition({
            left,
            top,
            deltaRight,
            deltaLeft,
            deltaBottom,
            deltaTop,
        })

        toggleDrag(true)
    }

    const handleDragEnd = () => {
        setMoveBorder(initialMoveBorder)
        setStartPosition(initialStartPosition)
        toggleDrag(false)
        onDragEnd(positionRef.current)
    }

    const handleDrag = throttle((mousePosition: MousePosition) => {
        const lastPosition = positionRef.current
        const lastStartPosition = startPositionRef.current

        const { mouseLeft, mouseTop } = mousePosition

        const newLeft = getValidXPos(mouseLeft - lastStartPosition.deltaLeft)
        const newTop = getValidYPos(mouseTop - lastStartPosition.deltaTop)

        const newPosition = {
            ...lastPosition,
            left: newLeft,
            top: newTop,
        }

        setPosition(newPosition)
        onDrag(newPosition)
    }, 50)

    const getValidXPos = useCallback(
        (pos: number) => {
            if (pos < moveBorder.left) {
                return moveBorder.left
            }

            if (pos > moveBorder.right) {
                return moveBorder.right
            }

            return pos
        },
        [moveBorder]
    )

    const getValidWidth = useCallback(
        (width: number) => {
            if (width < minWidth) {
                return minWidth
            }

            if (width > maxWidth) {
                return maxWidth
            }

            return width
        },
        [minWidth, maxWidth]
    )

    const getValidHeight = useCallback(
        (height: number) => {
            if (height < minHeight) {
                return minHeight
            }

            if (height > maxHeight) {
                return maxHeight
            }

            return height
        },
        [minHeight, maxHeight]
    )

    const getValidYPos = useCallback(
        (pos: number) => {
            if (pos > moveBorder.top) {
                return moveBorder.top
            }

            if (pos < moveBorder.bottom) {
                return moveBorder.bottom
            }

            return pos
        },
        [moveBorder]
    )

    const onMouseMove = useCallback(
        (e: MouseEvent) => {
            if (!isResizeOn && !isDragOn) return

            const rect = getRect()

            if (!rect) return

            const mousePosition: MousePosition = {
                mouseLeft: (e.pageX - rect.left) / rect.width,
                mouseTop: (e.pageY - rect.top) / rect.height,
            }

            if (isResizeOn) {
                return handleResize(mousePosition)
            }

            if (isDragOn) {
                return handleDrag(mousePosition)
            }
        },
        [isResizeOn, isDragOn]
    )

    const onMouseUp = useCallback(
        (e: MouseEvent) => {
            if (isResizeOn) {
                handleResizeEnd()
            }

            if (isDragOn) {
                handleDragEnd()
            }
        },
        [isResizeOn, isDragOn]
    )

    useEffect(() => {
        if (target) {
            const element = document.getElementById(target)

            if (!element) return

            element.addEventListener('mousemove', onMouseMove)
            element.addEventListener('mouseup', onMouseUp)
            return
        }

        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('mouseup', onMouseUp)

        return () => {
            if (target) {
                const element = document.getElementById(target)

                if (!element) {
                    return
                }

                element.removeEventListener('mousemove', onMouseMove)
                element.removeEventListener('mouseup', onMouseUp)
                return
            }

            document.removeEventListener('mousemove', onMouseMove)
            document.removeEventListener('mouseup', onMouseUp)
        }
    }, [target, onMouseMove, onMouseUp])

    const cornerSquareClassName = cn(
        styles.Draggable__square,
        styles.Draggable__square_type_corner,
        styles[`Draggable__square_type_${resizeSquareType}`]
    )

    const borderSquareClassName = cn(
        styles.Draggable__square,
        styles[`Draggable__square_type_${resizeSquareType}`],
        styles.Draggable__square_type_side
    )

    const handleMouseDown = useCallback(
        (e: React.MouseEvent) => {
            onMouseDown(e)

            if (isDraggable) {
                handleDragStart(e)
            }
        },
        [onMouseDown, isDraggable]
    )

    const renderResizeFrame = () => {
        return (
            <>
                {resizeDirections.right && (
                    <div
                        onMouseDown={(e) => handleResizeStart(e, 'right')}
                        className={cn(styles.Draggable__border, styles.Draggable__border_type_right)}
                    />
                )}
                {resizeDirections.left && (
                    <div
                        onMouseDown={(e) => handleResizeStart(e, 'left')}
                        className={cn(styles.Draggable__border, styles.Draggable__border_type_left)}
                    />
                )}
                {resizeDirections.bottom && (
                    <div
                        onMouseDown={(e) => handleResizeStart(e, 'bottom')}
                        className={cn(styles.Draggable__border, styles.Draggable__border_type_bottom)}
                    />
                )}
                {resizeDirections.top && (
                    <div
                        onMouseDown={(e) => handleResizeStart(e, 'top')}
                        className={cn(styles.Draggable__border, styles.Draggable__border_type_top)}
                    />
                )}
            </>
        )
    }

    const renderResizeElements = () => {
        return (
            <>
                {resizeDirections.right && (
                    <div className={cn(borderSquareClassName, styles.Draggable__square_position_right)}></div>
                )}
                {resizeDirections.left && (
                    <div className={cn(borderSquareClassName, styles.Draggable__square_position_left)}></div>
                )}
                {resizeDirections.bottom && (
                    <div className={cn(borderSquareClassName, styles.Draggable__square_position_bottom)}></div>
                )}
                {resizeDirections.top && (
                    <div className={cn(borderSquareClassName, styles.Draggable__square_position_top)}></div>
                )}
                {resizeDirections.topRight && (
                    <div
                        onMouseDown={(e) => handleResizeStart(e, 'topRight')}
                        className={cn(
                            styles.Draggable__corner,
                            styles.Draggable__corner_type_topRight,
                            cornerSquareClassName
                        )}
                    />
                )}
                {resizeDirections.bottomRight && (
                    <div
                        onMouseDown={(e) => handleResizeStart(e, 'bottomRight')}
                        className={cn(
                            styles.Draggable__corner,
                            styles.Draggable__corner_type_bottomRight,
                            cornerSquareClassName
                        )}
                    />
                )}
                {resizeDirections.bottomLeft && (
                    <div
                        onMouseDown={(e) => handleResizeStart(e, 'bottomLeft')}
                        className={cn(
                            styles.Draggable__corner,
                            styles.Draggable__corner_type_bottomLeft,
                            cornerSquareClassName
                        )}
                    />
                )}
                {resizeDirections.topLeft && (
                    <div
                        onMouseDown={(e) => handleResizeStart(e, 'topLeft')}
                        className={cn(
                            styles.Draggable__corner,
                            styles.Draggable__corner_type_topLeft,
                            cornerSquareClassName
                        )}
                    />
                )}
            </>
        )
    }

    return (
        <div
            ref={wrapperRef}
            className={cn(styles.Draggable, classNames)}
            onMouseDown={handleMouseDown}
            style={{
                position: isFixed ? 'fixed' : 'absolute',
                left: `${position.left * 100}%`,
                top: `${position.top * 100}%`,
                width: `${position.width * 100}%`,
                height: `${position.height * 100}%`,
                border: isBorder ? `1px solid transparent` : 'none',
                borderColor: isActive && isResizable ? activeBorderColor : borderColor,
                zIndex: isResizeOn ? resizableZIndex : zIndex,
                cursor: isDraggable ? moveCursor : cursor,
            }}
        >
            {isResizable && isActive && renderResizeFrame()}
            {isResizable && isActive && isRenderResizeElements && renderResizeElements()}
            {children}
        </div>
    )
}
