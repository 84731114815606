import React, { FunctionComponent } from 'react'
import ReactSwitch from 'react-switch'

import { ISwitchComponent } from './Switch-types'
import { defaultThemeStyles } from './Switch-theme'
import { getThemeStyleValue } from '../../theme/colors'

const getPropsByType = (type: string | undefined) => {
    switch (type) {
        default:
            return {
                onColor: getThemeStyleValue('ui', 'switch', 'on') || defaultThemeStyles.on,
                offColor: getThemeStyleValue('ui', 'switch', 'off') || defaultThemeStyles.off,
                onHandleColor: getThemeStyleValue('ui', 'switch', 'onHandler') || defaultThemeStyles.onHandler,
                offHandleColor: getThemeStyleValue('ui', 'switch', 'offHandler') || defaultThemeStyles.offHandler,
                handleDiameter: 18,
                uncheckedIcon: false,
                checkedIcon: false,
                disabled: false,
                boxShadow: 'none',
                activeBoxShadow: 'none',
                height: 16,
                width: 30,
            }
    }
}

export const Switch: FunctionComponent<ISwitchComponent> = ({ type, ...props }) => {
    const switchProps = {
        ...getPropsByType(type),
        ...props,
    }

    return <ReactSwitch {...switchProps} type="checkbox" />
}
