import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import { radiusSizes } from 'blocks.app/config'
import grid from 'blocks.simple/grid/grid.jcss'

const instancesCatalogDrinksStyles = {
    wrapper: {
        ...item.white,
        borderRadius: radiusSizes.mini,
        ...grid.col,
        ...grid.space,
    },
    tabs: {
        ...grid.row,
        ...grid.mb_md,
        borderRadius: radiusSizes.mini,
    },
}

addStyles(instancesCatalogDrinksStyles)
export default instancesCatalogDrinksStyles
