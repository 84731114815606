import { useReducer } from 'react'
import { MapCluster, MapMarker, MapOptions } from '../models/map'
import {
    createSuperClusters,
    isEqualClusters,
    isMapItemsChanged,
    transformSuperClustersToClusters,
} from 'features/map/utils'

export type ClustersActionTypes = 'update'

export interface ClustersState {
    clusters: MapCluster[]
}

export interface ClustersAction {
    type: ClustersActionTypes
    payload: MapCluster[]
}

const initialState: ClustersState = { clusters: [] }

const reducer = (state: ClustersState, action: ClustersAction) => {
    switch (action.type) {
        case 'update': {
            return { ...state, clusters: action.payload }
        }
        default: {
            return state
        }
    }
}

const getInitialState = () => initialState

export const useClusters = () => {
    const [state, dispatch] = useReducer(reducer, initialState, getInitialState)

    const getClusters = () => state.clusters

    const createClusters = (mapOptions: MapOptions, markers: MapMarker[]) => {
        let newClusters = transformSuperClustersToClusters(createSuperClusters(mapOptions, markers))

        if (!isMapItemsChanged(getClusters(), newClusters, isEqualClusters)) return

        setClusters(newClusters)
    }

    const updateClusters = (mapOptions: MapOptions, markers: MapMarker[]) => {
        if (mapOptions.bounds) {
            createClusters(mapOptions, markers)
            return
        }

        setClusters([])
    }

    const setClusters = (clusters: MapCluster[]) => {
        dispatch({
            type: 'update',
            payload: clusters,
        })
    }

    return {
        clusters: getClusters(),
        updateClusters,
    }
}
