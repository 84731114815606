import { addStyles } from 'ethcss'
import { IStyles } from '../../theme'

const datePickerStyles: IStyles = {
    datepickerWrapper: {
        input: {
            border: 'none',
            background: 'none',
        },
    },
}

addStyles(datePickerStyles)
export default datePickerStyles
