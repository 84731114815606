import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { addStyles } from 'ethcss'
import { mediaCSS, grid, item, text } from 'theme'

const profileAvatarStyles = {
    profileAvatarWrapper: {
        ...grid.colCenter,
        ...grid.w50,
        ...grid.p_md,
        background: `var(--var-module-app-component-app-style-darkBackground, ${defaultThemeStyles.darkBackground})`,
        ...item.rounded_full_micro,
        boxShadow: '4px 0px 8px rgba(0, 0, 0, 0.3)',
        [mediaCSS.tabMini]: {
            ...grid.w100,
            ...grid.p_miniPlus,
            borderRadius: `0px!important`,
        },
    },
    profileEditAvatarWrapper: {
        ...grid.mt_md,
        ...grid.mb_mini,
        ...grid.rowCenter,
        ...text.white,
        [mediaCSS.tabMini]: {
            ...grid.mb_none,
        },
    },
    profileEditAvatar: {
        ...grid.row,
        ...item.pointer,
        ...text.hoverUnderline,
    },
    profileEditIcon: {
        ...grid.mr_mini,
    },
    profileAvatarIcon: {
        ...grid.rowCenter,
    },
    profileAvatarImage: {
        ...grid.marginCenter,
    },
    profileEditAvatarInput: {
        ...item.none,
    },
}

addStyles(profileAvatarStyles)
export default profileAvatarStyles
