import React from 'react'
import InstancesCatalogFilter from '../../__filter/_instancesCatalog/catalog__filter_instancesCatalog'
import EditableText from 'blocks.simple/editableText/editableText'
import EditableDropdown from 'blocks.simple/editableDropdown/editableDropdown'
import Button from 'blocks.simple/button/button'

import { cn } from 'ethcss'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import grid from 'blocks.simple/grid/grid.jcss'
import styles from '../_instancesCatalog/catalog__template_instancesCatalog.jcss'

export default {
    getMethod: 'getSubjects',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noSubjects'),
    emptyDescription: () => translate('noSubjectsDescription'),
    emptyIcon: 'not_allow',
    addItemButton: () => null,
    settingsPrefixes: 'subjects',
    sortOptions: () => [
        { id: '-createdAt', name: translate('sortCreatedAt') },
        { id: 'title', name: translate('byNameAsc') },
    ],
    headerFilters: (p_) => (
        <InstancesCatalogFilter
            onAdd={() => p_.onAddFakeLine({ title: '' })}
            title={translate('subjects')}
            type="subject"
            onDelete={() => {
                const id = p_.selectedItems.map((selectedItem) => selectedItem.id)
                if (id.length > 0) {
                    api.send('deleteSubject', { id })
                }
                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            }}
            {...p_}
        />
    ),
    getItemModel: (dataItem, getSelected) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    getBackendModel: (item) => {
        return api.send('getSubject', { id: item.id }, { hideLoader: true })
    },
    tableWrapper: styles.tableWrapper,
    isEqual: (firstItem, secondItem) => firstItem.id === secondItem.id,
    onCreateListeners: [(cb, clearList) => api.addObserver('subjectCreated', cb, clearList)],
    onUpdateListeners: [(cb, clearList) => api.addObserver('subjectUpdated', cb, clearList)],
    onDeleteListeners: [(cb, clearList) => api.addObserver('subjectDeleted', cb, clearList)],
    fields: () => [
        { id: 'title', name: translate('name') },
        { id: 'gradeId', name: translate('gradesCatalog') },
        { id: 'courseId', name: translate('coursesCatalog') },
    ],
    init(callback) {
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        title: (p_, active, listProps) => (
            <EditableText
                id={p_.id}
                text={p_.title}
                onChange={(title) => {
                    listProps.onAddFakeLine({ ...listProps.fakeLine, title })
                }}
                onUpdate={(title, cb) => {
                    api.send('updateSubject', { title, id: p_.id, gradeId: p_.gradeId, courseId: p_.courseId }).then(cb)
                }}
            />
        ),
        gradeId: (p_, active, listProps) => (
            <div className={cn(grid.fullWidth)}>
                <EditableDropdown
                    placeholder={translate('elementNotSelected')}
                    options={listProps.additionalData.grades}
                    value={p_.gradeId}
                    labelField={'title'}
                    onChange={(selected) => {
                        if (p_.id) {
                            api.send('updateSubject', {
                                title: p_.title,
                                id: p_.id,
                                gradeId: selected.id,
                                courseId: p_.courseId,
                            })
                        } else {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, gradeId: selected.id })
                        }
                    }}
                />
            </div>
        ),
        courseId: (p_, active, listProps) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.id })}>
                <div className={cn({ [grid.fullWidth]: p_.id, [grid.space]: !p_.id })}>
                    <EditableDropdown
                        placeholder={translate('elementNotSelected')}
                        options={listProps.additionalData.courses}
                        value={p_.courseId}
                        labelField={'title'}
                        onChange={(selected) => {
                            if (p_.id) {
                                api.send('updateSubject', {
                                    title: p_.title,
                                    id: p_.id,
                                    gradeId: p_.gradeId,
                                    courseId: selected.id,
                                })
                            } else {
                                listProps.onAddFakeLine({ ...listProps.fakeLine, courseId: selected.id })
                            }
                        }}
                    />
                </div>
                {!p_.id && (
                    <Button
                        className={cn(grid.ml_micro, grid.mr_mini)}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            listProps.onAddFakeLine()
                        }}
                    >
                        {translate('cancel')}
                    </Button>
                )}
                {!p_.id && (
                    <Button
                        mod={'fill'}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            api.send('createSubject', listProps.fakeLine)
                        }}
                        disabled={
                            !listProps.fakeLine.title || !listProps.fakeLine.gradeId || !listProps.fakeLine.courseId
                        }
                    >
                        {translate('save')}
                    </Button>
                )}
            </div>
        ),
    },
    hideActiveRowColor: true,
    disableUpdateUserSettings: true,
    onDeleteListItem: (listItem) => {
        api.send('deleteSubject', { id: listItem.id })
    },
    disableListScroll: true,
    fixedColWidth: true,
}
