import React from 'react'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import DotTooltip from 'blocks.simple/dotTooltip/dotTooltip'

import { cn } from 'ethcss'
import styles from './catalog__item_advertisingCampaigns.jcss'
import item from 'blocks.simple/item/item.jcss'
import helpers from 'core/helpers'

class ItemAdvertisingCampaigns extends React.Component {
    render() {
        const p_ = this.props
        const active = p_.active || p_.item.__view.selected

        return (
            <div
                onClick={function (e) {
                    if (helpers.isDblTouchTap(e)) {
                        p_.onDoubleClickObject(p_.item)

                        e.preventDefault()
                        return
                    }

                    p_.onSelectInfo(e)
                }}
            >
                <Rectangle
                    width="16"
                    height="9"
                    className={styles.wrapper}
                    src={p_.item.cover.src}
                    contain={p_.item.cover.isIcon}
                >
                    {p_.multipleSelect && (
                        <div
                            className={styles.checkWrapper}
                            onClick={(e) => e.stopPropagation()}
                            style={p_.item.__view.selected ? { opacity: 1 } : null}
                        >
                            <Checkbox
                                mod={'circleInv'}
                                onClick={(selected) => {
                                    p_.onSelect({ selected, item: p_.item })
                                }}
                                checked={p_.item.__view.selected}
                            />
                        </div>
                    )}
                    <DotTooltip tooltip={{ html: p_.item.title }} containerClass={styles.name}>
                        <div className={styles.nameInner}>{p_.item.title}</div>
                    </DotTooltip>
                    <div className={cn(styles.preview, { [item.blackOpacity]: active })} />
                </Rectangle>
            </div>
        )
    }
}

export default ItemAdvertisingCampaigns
