import { useState, useEffect } from 'react'

export type Folder = {
    id: number
    name: string
    folderId: string | null
    availableDestinationQ: boolean
    nestedKey: string
    description: string | null
}

export interface IFolderEditProps {
    onClose: () => void
    folders: Folder[]
    open: boolean
    selectedInfo: {
        id: number
        name: string
        folderId: string
        description: string | null
    }
    updateFolder: (folder: { id: number; folderId: string; name: string; description: string | null }) => void
}

function FolderEditMethods(p_: IFolderEditProps) {
    const [name, setName] = useState<string>(p_.selectedInfo.name)
    const [folderId, setFolderId] = useState<string>(p_.selectedInfo.folderId)
    const [description, setDescription] = useState<string | null>(p_.selectedInfo.description)
    const [error, setError] = useState(null)

    useEffect(() => {
        updateState()
    }, [p_.selectedInfo.id])

    const updateState = () => {
        setName(p_.selectedInfo.name)
        setFolderId(p_.selectedInfo.folderId)
        setDescription(p_.selectedInfo.description)
    }

    function onSave(e: any) {
        e.preventDefault()

        p_.updateFolder({
            id: p_.selectedInfo.id,
            folderId,
            name,
            description,
        })

        p_.onClose()
    }

    function onChangeFolder(selected: { id: string }) {
        setFolderId(selected.id)
    }

    const onChangeName = (name: string) => {
        setName(name)
    }

    const onChangeDescription = (description: string) => {
        setDescription(description)
    }

    return {
        s_: {
            name,
            folderId,
            error,
            description,
        },
        methods: {
            onSave,
            onChangeFolder,
            onChangeName,
            onChangeDescription,
        },
    }
}

export default FolderEditMethods
