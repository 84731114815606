import { useCallback, useEffect, useState } from 'react'
import helpers from 'core/helpers'
import { updateUserSettings } from '../../../blocks.app/user/user.state'
import { useDispatch } from 'react-redux'

export type Duration = {
    type: string
    fileType: string
    defaultDuration: number
}

const setSourceDuration = (defaultSourceDuration: Duration[]) => {
    if (!defaultSourceDuration) return []

    return defaultSourceDuration.map((item: Duration) => ({
        ...item,
        defaultDuration: item.defaultDuration / 1000,
    }))
}

export const SettingsContentDurationHooks = (durationList: Duration[]) => {
    const [defaultSourceDurationList, setDefaultSourceDurationList] = useState(setSourceDuration(durationList))
    const [active, setActive] = useState<null | number>(null)
    const [update, setUpdate] = useState<boolean>(false)
    const dispatch = useDispatch()

    const onChange = (value: string, index: number) => {
        const defaultSourceDuration = helpers.deepCopy(defaultSourceDurationList)

        defaultSourceDuration[index].defaultDuration = +value

        setDefaultSourceDurationList(defaultSourceDuration)
    }
    const save = useCallback(() => {
        const defaultSourceDuration = defaultSourceDurationList.map((item) => ({
            ...item,
            defaultDuration: item.defaultDuration * 1000,
        }))

        dispatch(updateUserSettings({ data: { defaultSourceDuration }, isSaveSettings: true }))
    }, [defaultSourceDurationList])

    useEffect(() => {
        if (update) {
            save()

            setUpdate(false)
        }
    }, [update])

    return {
        onChange,
        setUpdate,
        active,
        setActive,
        defaultSourceDurationList,
    }
}
