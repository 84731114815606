import { addStyles } from 'ethcss'
import { grid, IStyles, item, text } from 'theme'
import { defaultThemeStyles } from 'organisms/CatalogWithPagination/CatalogWithPagination.theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'

export const styles: IStyles = {
    ScoreboardItem: {
        ...grid.col,
        cursor: 'pointer',
    },
    ScoreboardItem_type_offline: {
        backgroundColor: `var(--var-module-catalog-component-item-style-offline, ${defaultThemeStyles.item.offline})!important`,
    },
    ScoreboardItem_type_online: {
        backgroundColor: `var(--var-module-catalog-component-item-style-online, ${defaultThemeStyles.item.online})!important`,
    },
    ScoreboardItem_type_error: {
        backgroundColor: `var(--var-module-app-component-app-style-error, ${defaultThemeAppStyles.error})!important`,
    },
    ScoreboardItem_type_updating: {
        backgroundColor: `var(--var-module-app-component-app-style-warning, ${defaultThemeAppStyles.warning})!important`,
    },
    ScoreboardItem_type_notSynchronized: {
        backgroundColor: `var(--var-module-app-component-app-style-notSynchronized, ${defaultThemeAppStyles.notSynchronized})!important`,
    },
    ScoreboardsItem__dnd_type_offline: {
        backgroundColor: `var(--var-module-catalog-component-item-style-offline, ${defaultThemeStyles.item.offline})!important`,
    },
    ScoreboardsItem__dnd_type_online: {
        backgroundColor: `var(--var-module-catalog-component-item-style-online, ${defaultThemeStyles.item.online})!important`,
    },
    ScoreboardsItem__dnd_type_error: {
        backgroundColor: `var(--var-module-app-component-app-style-error, ${defaultThemeAppStyles.error})!important`,
    },
    ScoreboardsItem__dnd_type_updating: {
        backgroundColor: `var(--var-module-app-component-app-style-warning, ${defaultThemeAppStyles.warning})!important`,
    },
    ScoreboardsItem__dnd_type_notSynchronized: {
        backgroundColor: `var(--var-module-app-component-app-style-notSynchronized, ${defaultThemeAppStyles.notSynchronized})!important`,
    },
    ScoreboardsItem__dnd: {
        ...item.blackOpacity,
    },
    ScoreboardsItem__rectangle: {
        ...item.hover,
        cursor: 'pointer',
    },
    ScoreboardsItem__dnd_type_group: {
        ...grid.col,
        ...grid.full,
        ...item.miniShadow,
        ...item.cover,
        ...item.rel,
        ...item.overNone,
        zIndex: 10,
        height: '90%',
        top: '10%',
    },
    ScoreboardsItem__dnd_type_item: {
        ...grid.col,
        ...grid.full,
        ...item.miniShadow,
        ...item.cover,
        ...item.rel,
        ...item.overNone,
        zIndex: 10,
        height: '100%',
    },
    ScoreboardsItem__backWrapper: {
        ...grid.full,
        ...grid.colCenter,
        ...item.abs,
        top: 0,
        left: 0,
        zIndex: 1,
    },
    ScoreboardsItem__back: {
        ...grid.fullHeight,
        ...item.miniShadow,
        position: 'relative',
    },
    ScoreboardsItem__back_type_first: {
        width: '94%',
        height: '90%!important',
        backgroundColor: `var(--var-module-catalog-component-item-style-backgroundSub, ${defaultThemeStyles.item.backgroundSub})`,
    },
    ScoreboardsItem__back_type_second: {
        width: '88%',
    },
    ScoreboardsItem__back_type_offline: {
        backgroundColor: `var(--var-module-catalog-component-item-style-offline, ${defaultThemeStyles.item.offline})!important`,
    },
    ScoreboardsItem__back_type_online: {
        backgroundColor: `var(--var-module-catalog-component-item-style-online, ${defaultThemeStyles.item.online})!important`,
    },
    ScoreboardsItem__back_type_error: {
        backgroundColor: `var(--var-module-app-component-app-style-error, ${defaultThemeAppStyles.error})!important`,
    },
    ScoreboardsItem__back_type_updating: {
        backgroundColor: `var(--var-module-app-component-app-style-warning, ${defaultThemeAppStyles.warning})!important`,
    },
    ScoreboardsItem__content: {
        ...grid.space,
        ...grid.colCenter,
        position: 'relative',
    },
    ScoreboardsItem__info_type_shadow: {
        backgroundColor: `var(--var-module-catalog-component-item-style-active, ${defaultThemeStyles.item.active})`,
    },
    ScoreboardsItem__info_type_group: {
        ...grid.rowCenter,
        ...grid.full,
    },
    ScoreboardsItem__checkbox: {
        position: 'absolute',
        ...item.transitionErase,
        top: grid.p_micro.padding,
        right: grid.p_micro.padding,
        zIndex: 2,
        opacity: 0,
    },
    ScoreboardsItem__checkbox_type_selected: {
        opacity: '1!important',
    },
    ScoreboardsItem__title_type_group: {
        ...text.center,
        ...text.white,
        ...grid.w90,
    },
    ScoreboardsItem__text_type_group: {
        ...grid.p_micro,
        ...text.normalPlus,
        ...item.ellipsis,
    },
    ScoreboardsItem__title_type_item: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.p_mini,
        backgroundColor: `var(--var-module-catalog-component-item-style-titleBackground, ${defaultThemeStyles.item.titleBackground})`,
        textTransform: 'uppercase',
        color: `var(--var-module-catalog-component-item-style-textMain, ${defaultThemeStyles.item.textMain})`,
    },
    ScoreboardsItem__text_type_item: {
        ...grid.space,
        ...grid.pr_mini,
        marginTop: 3,
        ...item.ellipsisPure,
    },
    ScoreboardsItem__info_type_item: {
        ...grid.full,
    },
}

addStyles(styles)
