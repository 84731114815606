import React, { FC, memo, useContext } from 'react'
import { cn } from 'ethcss'
import translate from 'core/translate'
import { IListProps } from './List-types'
import { styles } from './List-styles'
import { CatalogWithPaginationContext } from 'organisms/CatalogWithPagination/CatalogWithPagination-context'
import { Table, TableHeaderCell } from 'atoms/Table'
import { ListItem } from './ListItem'
import { ICatalogWithPaginationOptionsList } from 'organisms/CatalogWithPagination/CatalogWithPagination-types'

const ListComponent: FC<IListProps> = ({ list, nestedList, minimize }) => {
    const DEFAULT_TABLE_CONTENT_WIDTH_PERCENTAGE = 90
    const { common } = useContext(CatalogWithPaginationContext)
    const { list: options, isEnableMultipleSelect = true, fields, isEnableTreeView = true } = common
    const {
        listClassName,
        listTableClassName,
        isEnableFixedColumnWidth = true,
    } = options as ICatalogWithPaginationOptionsList

    const getListClassName = () => {
        if (!listClassName) return styles.List

        return minimize ? listClassName.minimize : listClassName.desktop
    }

    const getListTableClassName = () => {
        if (!listTableClassName) return {}

        return minimize ? listTableClassName.minimize : listTableClassName.desktop
    }

    const getListTableColumnWidth = () => {
        return isEnableFixedColumnWidth ? { width: `${DEFAULT_TABLE_CONTENT_WIDTH_PERCENTAGE / fields.length}%` } : {}
    }

    const renderSelectCell = () => <TableHeaderCell>{translate('choice')}</TableHeaderCell>

    const renderHeaderCells = () => {
        return fields.map((option, index) => (
            <TableHeaderCell key={`catalogListHeader_${index}`} style={getListTableColumnWidth()}>
                {translate(option.name)}
            </TableHeaderCell>
        ))
    }

    const renderTree = () => {
        return list.map((listItem) => {
            return (
                <ListItem
                    key={`catalogListRow_${listItem.id}`}
                    minimize={minimize}
                    nestedList={nestedList}
                    listItem={listItem}
                />
            )
        })
    }

    return (
        <div className={cn(getListClassName())}>
            <Table className={cn(getListTableClassName())}>
                <thead>
                    <tr>
                        {isEnableMultipleSelect && renderSelectCell()}
                        {isEnableTreeView && <TableHeaderCell />}
                        {renderHeaderCells()}
                    </tr>
                </thead>
                <tbody>{renderTree()}</tbody>
            </Table>
        </div>
    )
}

export const List = memo(ListComponent)
