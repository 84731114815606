import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { mediaCSS } from 'blocks.app/config'
import { IStyles } from '../../theme'

const displaysStyles: IStyles = {
    breadcrumbs: {
        ...grid.pl_mdPlus,
        marginLeft: -grid.m_mdPlus['margin'],
        marginTop: '10px',
        width: `calc(100% + ${2 * grid.m_mdPlus['margin']}px)`,
        [mediaCSS.tabMini]: {
            marginTop: -grid.m_mini['margin'],
        },
    },
    breadcrumbs_x: {
        paddingLeft: '0px',
    },
    toolbar: {
        ...item.white,
    },
    displaysWrapper: {
        [mediaCSS.tab]: {
            marginTop: 46,
        },
    },
}

addStyles(displaysStyles)

export default displaysStyles
