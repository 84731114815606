import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../../theme'
import { indents } from 'blocks.app/config'
import { mediaCSS, textSizes } from 'theme/media'

const modalAcceptStyles: IStyles = {
    modalWrapper: {
        ...grid.full,
        ...grid.rowCenter,
        ...item.blackOpacity,
    },
    modalInner: {
        ...grid.colCenter,
        ...item.card,
        ...item.shadowModal,
        ...item.rounded_full_mini,
        ...item.rel,
    },
    modalSizes: {
        ...grid.w25,
        [mediaCSS.tab]: {
            ...grid.w50,
        },
        [mediaCSS.tabMini]: {
            ...grid.w60,
        },
        [mediaCSS.mob]: {
            ...grid.w90,
        },
    },
    title: {
        ...grid.mt_md,
        fontSize: textSizes.normal,
        textTransform: 'uppercase',
    },
    description: {
        ...grid.mt_mdPlus,
        ...grid.mb_md,
        textAlign: 'center',
        lineHeight: 1.3,
        wordBreak: 'break-word',
    },
    buttons: {
        ...grid.row,
        ...grid.mt_md,
    },
    leftButton: {
        ...grid.mr_mini,
    },
    closeIcon: {
        ...item.abs,
        right: indents.mini,
        top: indents.mini,
    },
}

addStyles(modalAcceptStyles)
export default modalAcceptStyles
