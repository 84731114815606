import React from 'react'
import Button from 'blocks.simple/button/button'
import { api } from 'core/api/ConnectionManager'

class AppTestDownloads extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {}
    }
    startDownloads() {
        api.post('newTestDownloads', {
            companyId: 12,
            broadcastId: 1639,
            groupId: 752,
            devicesCount: 10,
            dpCount: 10,
            dpDelay: 5,
            testLifetime: 20,
        })
            .then(() => {})
            .catch((err) => console.error(err))
    }
    stopDownloads() {
        api.post('newTestStopDownlaods', {})
            .then(() => {})
            .catch((err) => console.error(err))
    }
    render() {
        return (
            <div>
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 100000,
                    }}
                >
                    <Button onClick={this.startDownloads}>Start downloads</Button>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top: 50,
                        left: 0,
                        zIndex: 100000,
                    }}
                >
                    <Button onClick={this.stopDownloads}>Stop downloads</Button>
                </div>
            </div>
        )
    }
}

export default AppTestDownloads
