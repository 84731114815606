import React from 'react'
import { Content } from 'organisms/Content'
import UiIconsLegacy from './__icons_legacy/ui__icons'
import UiTooltipIcon from './__tooltipIcon/ui__tooltipIcon'
import UiSelectContent from './__select/_content/ui__select_content'
import UiPalette from './__palette/ui__palette'
import UiCheckbox from './__checkbox/ui__checkbox'
import UiRadio from './__radio/ui__radio'
import UiDatepicker from './__datepicker/ui__datepicker'
import UiDownloads from './__downloads/ui__downloads'
import UiToast from './__toast/ui__toast'
import UiTabs from './__tabs/ui__tabs'

import { cn } from 'ethcss'
import styles from './ui.jcss'

class Ui extends React.Component {
    render() {
        return (
            <Content title="Компоненты" className={cn(styles.wrapper)}>
                <UiDownloads />
                <UiPalette />
                <UiIconsLegacy />
                <UiTooltipIcon />
                <UiCheckbox />
                <UiRadio />
                <UiSelectContent />
                <UiDatepicker />
                <UiToast />
                <UiTabs />
            </Content>
        )
    }
}

export default Ui
