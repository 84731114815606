import React from 'react'
import Modal from 'blocks.simple/modal/modal'
import Icon from 'blocks.simple/icon/icon'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import EditText from 'blocks.simple/editText/editText'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import Button from 'blocks.simple/button/button'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import SearchbarFilterMethods, { ISearchbarFilterProps } from './searchbar__filter.local.methods'
import helpersDigitalSignages from 'core/helpers/_digitalSignages'
import translate from 'core/translate'
import filesize from 'filesize'
import { cn } from 'ethcss'
import styles from './searchbar__filter.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { text as textStyles } from 'theme'
import item from 'blocks.simple/item/item.jcss'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { Typography } from 'atoms/Typography'
import { isEmpty } from 'lodash'
import { withRouter } from 'react-router'

const getFilterName = (selected: { id: string; name: string } | null, field: { id: string; name: string }) => {
    switch (selected!.id) {
        case 'orientation':
            const id = parseInt(field.id, 10)
            const orientation = helpersDigitalSignages.getOrientation(id)

            if (!orientation) {
                return field.name
            }

            return orientation.name
        case 'freeStorageSize':
        case 'usedStorageSize':
            return filesize(+field.id)
        default:
            return field.name
    }
}

const SearchbarFilter = (p_: ISearchbarFilterProps) => {
    const {
        state,
        onFilterChange,
        toggleField,
        checkAll,
        closeFilter,
        openFilter,
        selectFilter,
        filterSearch,
    } = SearchbarFilterMethods(p_)

    const s_ = state
    const isFilterList = p_.filter && !isEmpty(p_.filter.selected)

    return (
        <div className={styles.filter}>
            <div className={styles.delimer} />
            <Button
                mod={'list'}
                isActive={s_.isFilterOpen}
                icon={p_.filter.icon}
                iconSize={p_.filter.iconSize}
                iconPos="left"
                iconColor={
                    s_.isFilterOpen
                        ? getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active
                        : p_.filter.iconColor
                }
                rounded="none"
                indentSize="normal"
                onClick={openFilter}
                fullWidth={true}
                animation={false}
            >
                <span>{p_.filter.placeholder}</span>
            </Button>
            {isFilterList && p_.mod === 'withShadow' && (
                <div className={cn(grid.fullWidth, grid.row, grid.center, item.abs)}>
                    <Icon type="arrowhead_up" color="white" size="40" />
                </div>
            )}
            <div className={item.abs}>
                <Modal open={s_.isFilterOpen} showCloseIcon={false} mod={'clear'} transitionName={'fade'}>
                    <div className={styles.modalWrapper}>
                        <div className={styles.modalInner}>
                            <Rectangle className={cn(grid.col)}>
                                <div className={styles.filterWrapper}>
                                    <div className={cn(grid.rowCenter)}>
                                        <div className={cn(grid.rowCenter, textStyles.normal)}>
                                            <Icon
                                                type={p_.filter.icon}
                                                color={p_.filter.iconActiveColor}
                                                className={grid.mr_micro}
                                            />
                                            <Typography type="title">{p_.filter.placeholder}</Typography>
                                        </div>
                                    </div>
                                    <div className={grid.p_mini}>
                                        <Dropdown
                                            placeholder={p_.filter.placeholder}
                                            iconActiveColor={
                                                getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active
                                            }
                                            iconColor={
                                                getThemeStyleValue('ui', 'icon', 'disable') ||
                                                defaultThemeStyles.disable
                                            }
                                            options={p_.filter.options}
                                            onChange={selectFilter}
                                            value={s_.selectedFilter ? s_.selectedFilter.id : null}
                                        />
                                        {!s_.selectedFilter && (
                                            <Typography type="text" className={cn(grid.pt_micro, textStyles.center)}>
                                                {translate('searchFilter')}
                                            </Typography>
                                        )}
                                    </div>
                                    {s_.selectedFilter && (
                                        <React.Fragment>
                                            <div className={styles.fieldWrapper}>
                                                <EditText
                                                    icon={'search'}
                                                    rounded={'full_normal'}
                                                    value={s_.filterSearch}
                                                    onChange={filterSearch}
                                                />
                                            </div>
                                            <div className={cn(styles.fieldWrapper, grid.row, grid.justify)}>
                                                <div />
                                                <div className={grid.row}>
                                                    <div
                                                        className={cn(item.pointer, textStyles.underline, grid.mr_mini)}
                                                        onClick={() => checkAll(true)}
                                                    >
                                                        <Typography type="title">{translate('selectAll')}</Typography>
                                                    </div>
                                                    <div
                                                        className={cn(item.pointer, textStyles.underline)}
                                                        onClick={() => checkAll(false)}
                                                    >
                                                        <Typography type="title">{translate('clear')}</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={cn(styles.filterFields)}>
                                                {s_.filterFields
                                                    .filter(
                                                        (field: any) =>
                                                            field.name
                                                                .toLowerCase()
                                                                .indexOf(s_.filterSearch.toLowerCase()) !== -1
                                                    )
                                                    .map((field: any) => (
                                                        <Checkbox
                                                            key={field.name}
                                                            onClick={() => toggleField(field.index)}
                                                            checked={field.checked}
                                                            label={getFilterName(s_.selectedFilter, field)}
                                                            wrapperClassName={styles.checkItem}
                                                        />
                                                    ))}
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                                <div className={styles.buttons}>
                                    <div />
                                    <div className={grid.row}>
                                        <Button
                                            className={cn(grid.mr_mini)}
                                            onClick={closeFilter}
                                            textSize={'mini'}
                                            indentSize={'mini'}
                                            rounded={'full_normal'}
                                        >
                                            {translate('cancel')}
                                        </Button>
                                        <Button
                                            mod={'fill'}
                                            textSize={'mini'}
                                            indentSize={'mini'}
                                            rounded={'full_normal'}
                                            onClick={onFilterChange}
                                            disabled={!s_.selectedFilter}
                                        >
                                            {translate('apply')}
                                        </Button>
                                    </div>
                                </div>
                            </Rectangle>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

// @ts-ignore
export default withRouter(SearchbarFilter)
