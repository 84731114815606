import { addStyles } from 'ethcss'
import { grid, IStyles } from 'theme'
import { defaultThemeStyles } from '../AudioPlayground-theme'

const VOLUME_LEVEL_HEIGHT = 6

export const volumeLevelStyles: IStyles = {
    VolumeLevel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px',
        //...grid.p_normal,
    },
    VolumeLevel__content: {
        position: 'relative',
        height: VOLUME_LEVEL_HEIGHT,
        width: 111,
        marginLeft: '10px',
        backgroundColor: `var(--var-module-audio-component-playground-style-levelContent, ${defaultThemeStyles.levelContent})`,
    },
    VolumeLevel__active: {
        position: 'absolute',
        left: 0,
        top: 0,
        height: VOLUME_LEVEL_HEIGHT,
        backgroundColor: `var(--var-module-audio-component-playground-style-levelActive, ${defaultThemeStyles.levelActive})`,
    },
    VolumeLevel__pointer: {
        position: 'absolute',
        top: '50%',
        marginTop: '-7.5px',
        marginLeft: '-7.5px',
        width: 14,
        height: 14,
        borderRadius: 3,
        backgroundColor: `var(--var-module-audio-component-playground-style-levelPointer, ${defaultThemeStyles.levelPointer})`,
    },
}

addStyles(volumeLevelStyles)
