import { addStyles } from 'ethcss'
import { colors, IStyles } from '../../../../theme'

const daysAndClassesStyles: IStyles = {
    days: {
        display: 'flex',
        marginTop: 10,
    },
    day: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 120,
        height: 30,
        borderRadius: 5,
        marginRight: 6,
        cursor: 'pointer',

        '&:hover': {
            backgroundColor: colors.middleGrey,
        },
    },
    classNumbers: {
        display: 'flex',
        marginTop: 10,
    },
    classNumber: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 50,
        height: 30,
        borderRadius: 5,
        marginRight: 6,
        cursor: 'pointer',

        '&:hover': {
            backgroundColor: colors.middleGrey,
        },
    },
    classLetters: {
        display: 'flex',
        marginTop: 10,
    },
    classLetter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 50,
        height: 30,
        borderRadius: 5,
        marginRight: 6,
        cursor: 'pointer',

        '&:hover': {
            backgroundColor: colors.middleGrey,
        },
    },
    active: {
        backgroundColor: colors.middleGrey,
    },
}

addStyles(daysAndClassesStyles)
export default daysAndClassesStyles
