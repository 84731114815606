import React, { FunctionComponent } from 'react'
import { Searchbar } from 'organisms/Searchbar'

import { useTreeViewCatalogHeader } from './TreeViewCatalogHeader-hooks'

import { ITreeViewCatalogHeaderComponent } from './TreeViewCatalogHeader-types'

import { treeViewCatalogHeaderStyles } from './TreeViewCatalogHeader-styles'

export const TreeViewCatalogHeader: FunctionComponent<ITreeViewCatalogHeaderComponent> = ({
    type,
    params,
    viewParams,
    template,
}) => {
    const headerHook = useTreeViewCatalogHeader({ type, viewParams })

    return (
        <div className={treeViewCatalogHeaderStyles.wrapper}>
            <Searchbar
                searchWrapperClassName={treeViewCatalogHeaderStyles.searchWrapper}
                formWrapper={treeViewCatalogHeaderStyles.formWrapper}
                dropdownClassName={treeViewCatalogHeaderStyles.dropdownClassName}
                customContent={template.headerCustomContent}
                value={params.query}
                withoutFilters={template.isOnlySearch}
                onChange={headerHook.onSearch}
                sort={
                    template.sortOptions && {
                        value: headerHook.parameters.sort,
                        options: template.sortOptions(),
                        onChange: headerHook.onChangeSort,
                    }
                }
            />
        </div>
    )
}
