import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles } from '../CatalogFilter-theme'
import { IStyles, mediaCSS } from '../../../../theme'

const instancesCatalogFilterStyles: IStyles = {
    wrapper: {
        ...grid.w100,
        ...grid.mt_md,
        [mediaCSS.tabMini]: {
            ...grid.pt_mdPlus,
        },
    },
    wrapper_x: {
        marginTop: '41px !important',
    },
    content: {
        ...item.rounded_top_mini,
        borderRadius: '5px 5px 0 0',
        backgroundColor: `var(--var-module-catalog-component-filter-style-background, ${defaultThemeStyles.background})`,
    },
    tools: {
        ...grid.row,
        ...grid.justify,
        ...grid.normalCenter,
        ...grid.pr_md,
        ...grid.pl_md,
    },
    deleteButton: {
        width: 250,
    },
    delimiterWrapper: {
        width: 'auto !important',
    },
    delimiter: {
        width: 110,
        ...grid.mr_md,
    },
}

addStyles(instancesCatalogFilterStyles)
export default instancesCatalogFilterStyles
