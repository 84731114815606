import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'

const catalogTemplateBroadcastsStyles = {
    image: {
        ...grid.w10,
    },
    name: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.noWrap,
        ...grid.w100,
        textAlign: 'left',
    },
    icon: {
        ...grid.mr_mini,
    },
}

addStyles(catalogTemplateBroadcastsStyles)
export default catalogTemplateBroadcastsStyles
