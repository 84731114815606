export enum CatalogSortTypesEnum {
    CREATED_AT = '-createdAt',
    STATUS = '-status',
}

export enum CatalogTypesEnum {
    DIGITAL_SIGNAGES = 'digitalSignages',
    FILES = 'files',
    BROADCASTS = 'broadcasts',
}

export type TDispositionTypes = 'tile' | 'list' | 'map' | 'referenceBook'
