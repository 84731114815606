import React, { useEffect, useState } from 'react'
import styles from './FilterInner-styles'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import translate from '../../../core/translate'
import Button from 'blocks.simple/button/button'
import { cn } from 'ethcss'
import { Option } from '../Filter/Filter-types'
import DotTooltip from 'blocks.simple/dotTooltip/dotTooltip'
import { getValidNameLabel } from 'core/helpers/filters'
import { useClickOutside } from 'react-click-outside-hook'

export const FilterInner = ({ filter, changeVariant, multiselect, onApply, onClose, containerClassName }: any) => {
    const [searchValue, setSearchValue] = useState('')
    const [filterInnerRef, hasClickedOutside] = useClickOutside()

    const tooltip = (label: string) => {
        return (
            <DotTooltip tooltip={{ html: label }}>
                <div className={styles.tooltip} dangerouslySetInnerHTML={{ __html: label }} />
            </DotTooltip>
        )
    }

    useEffect(() => {
        if (hasClickedOutside) {
            onClose()
        }
    }, [hasClickedOutside])

    return (
        <div ref={filterInnerRef} className={cn(containerClassName, styles.filterInner)}>
            <div className={styles.searchBlock}>
                <input
                    className={styles.searchInput}
                    type={'text'}
                    placeholder={translate('searchByOptions')}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
            </div>
            <div className={styles.multiSelect}>
                <div className={styles.selectBtn} onClick={() => multiselect(filter.name, true)}>
                    {translate('selectAll')}
                </div>
                <div className={styles.selectBtn} onClick={() => multiselect(filter.name, false)}>
                    {translate('reset')}
                </div>
            </div>
            <div className={styles.filterValues}>
                {filter.options
                    .filter((option: any) => translate(option.label.toLowerCase()).includes(searchValue.toLowerCase()))
                    .map((optionItem: Option, index: number) => {
                        const label: any = getValidNameLabel(optionItem, filter.translateOptionsQ)
                        return (
                            <Checkbox
                                key={index}
                                onClick={() =>
                                    changeVariant({
                                        name: filter.name,
                                        option: {
                                            value: optionItem.value,
                                            selected: !optionItem.selected,
                                            label: optionItem.label,
                                        },
                                    })
                                }
                                checked={optionItem.selected}
                                label={tooltip(label)}
                                labelClassName={styles.label}
                                wrapperClassName={styles.checkbox}
                                style={{ position: 'absolute', top: 0, left: 0 }}
                            />
                        )
                    })}
            </div>
            {!containerClassName && (
                <Button onClick={onApply} className={styles.applyBtn} mod="fill">
                    {translate('apply')}
                </Button>
            )}
        </div>
    )
}
