import React from 'react'
import { transitions } from 'blocks.app/config'

interface DropContentProps {
    children: React.ReactComponentElement<any>
    isOpen: boolean
    style?: {}
    isShow: boolean
    arrow: boolean
    animate: boolean
    transitionName: string
    text?: string
    confirmButton?: {}
    confirmButtonText?: string
    cancelButton?: {}
    cancelButtonText?: string
    indents: string
    onClickOutside: () => void
    position: string
    customContent: any
    iconColor: string
    className_: string
}

class DropContentMethods extends React.Component<DropContentProps> {
    state = {
        isOpen: this.props.isOpen,
    }
    animationState = true
    animationTimer: any = null

    componentDidUpdate(prevProps: DropContentProps) {
        const p_ = this.props

        if (p_.animate && prevProps.isOpen && !p_.isOpen) {
            this.animationState = false
            clearTimeout(this.animationTimer)
            this.animationTimer = setTimeout(() => {
                this.setState({ isOpen: p_.isOpen })
                this.animationState = true
            }, transitions.normal)
            return
        }
        if (prevProps.isOpen !== p_.isOpen) {
            this.setState({ isOpen: p_.isOpen })
        }
    }
    getArrowPosition = (position: string) => {
        switch (position) {
            case 'bottom':
                return 'down'
            case 'top':
                return 'up'
            default:
                return position
        }
    }
}

export default DropContentMethods
