import React, { useEffect, useState } from 'react'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import Icon from 'blocks.simple/icon/icon'
import DndDraggable from 'blocks.app/dnd/_draggable/dnd_draggable'
import DndDroppable from 'blocks.app/dnd/_droppable/dnd_droppable'
import { cn } from 'ethcss'
import styles from './catalog__list.jcss'
import item from 'blocks.simple/item/item.jcss'
import translate from 'core/translate'
import helpers from 'core/helpers'
import { activateEditLink, activateEditTable, activateEditText } from 'pages/files/files.state'
import { useDispatch } from 'react-redux'
import { ItemData } from '../../../core/models/Template'

type Item = { id: number; __view: { index: number; isOpen: boolean }; children: React.ReactNode[] }

export interface ICatalogListProps {
    options: { id: any; name: string; hide: boolean }[] | null
    template: {
        list: any
        isFolder: (data: Item) => boolean
        fixedColWidth: boolean
        templateCellsWidth: { [index: string]: string }
        tableWrapper: string
        onDeleteListItem: (listItem: Item) => void
        trNoClick: boolean
        multipleMove: (sourceId: number[], folderId: number[], id: number) => Promise<any>
        isEqual: (data_1: object, data_2: object) => boolean
        moveFolder: (id_1: number, id_2: number) => void
        moveItem: (id_1: number, id_2: number) => void
        customElementInSelect: (data: object, active: boolean) => object
        hideActiveRowColor: boolean
        dndDblClickDisable: boolean
    }
    isFolder: (data: any) => boolean
    multipleSelect: boolean
    treeView?: boolean
    allowEmptyList?: boolean
    fakeLine: string
    list: {
        children: any
        id: number
        type: string
        __view: { selected: boolean; isOpen: boolean; index: number }
    }[]
    getDragData?: any
    onSelectInfo: (index: number) => void
    selectedItems: ItemData[]
    onResetSelection: () => void
    selectedInfo?: ItemData
    isEqual?: (firstItem: ItemData, secondItem: ItemData) => boolean
    drag?: boolean
    onDragStart?: (event: string) => void
    onDragEnd?: (event: string) => void
    onSelectFolder?: (data: { id: number }) => void
    onDoubleClickObject?: (data: ItemData) => void
    disableFolderSelection?: boolean
    disableMultiSelect: any
    onSelect: (data: { selected: boolean; item: any }) => void
    onFolderOpening?: (listItem: ItemData, nested: number | undefined) => void
    nested?: number | undefined
    nestedLevel?: number
    onEditListItem?: (listItem: ItemData) => void
    onSortEnd?: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void
    type: string
    onAddFakeLine: (cb: any) => void
    additionalData: any
    handleSortable: any
    identificatedItems?: any
}

interface IListInnerProps extends ICatalogListProps {
    listItemData: any
    getCellValue: (listItem: Item, id: string, active: boolean, nestedLevel: number) => object
}

const ListInner = (p_: IListInnerProps) => {
    const [isHovered, setIsHovered] = useState<boolean>(false)
    const [dragData, setDragData] = useState<object>(p_.getDragData({ item: p_.listItemData.listItem }))
    const dispatch = useDispatch()

    useEffect(() => {
        setDragData(p_.getDragData({ item: p_.listItemData.listItem }))
    }, [p_])

    const onDragOver = () => {
        if (!isHovered) {
            setIsHovered(true)
        }
    }
    const onDragLeave = () => {
        if (isHovered) {
            setIsHovered(false)
        }
    }
    const onSelectInfo = (index: number) => {
        if (p_.template.trNoClick) {
            return
        }

        if (p_.onSelectInfo) {
            p_.onSelectInfo(index)
        }
    }
    const onDrop = (data: { dnd_item: string }, e: any) => {
        e.stopPropagation()

        const droppedItem = JSON.parse(data.dnd_item)

        if (p_.template.multipleMove && p_.multipleSelect && p_.selectedItems.length) {
            const droppedItemInSelected = p_.selectedItems.filter((selectedItem) =>
                p_.template.isEqual(selectedItem, droppedItem)
            )

            if (droppedItemInSelected.length) {
                onMoveMultiple()
                return
            }
        }

        const { listItem } = p_.listItemData
        if (p_.template.isEqual(droppedItem, listItem)) {
            setIsHovered(false)
            return
        }
        const moveMethod = p_.isFolder(droppedItem) ? p_.template.moveFolder : p_.template.moveItem

        moveMethod(droppedItem.id, listItem.id)
        setIsHovered(false)
    }
    const onMoveMultiple = () => {
        const listItem = p_.listItemData.listItem
        const sourceId: number[] = []
        const folderId: number[] = []

        p_.selectedItems.forEach((item: ItemData) => {
            if (!p_.template.isEqual(item, listItem)) {
                p_.isFolder(item) ? folderId.push(item.id) : sourceId.push(item.id)
            }
        })

        p_.template.multipleMove(sourceId, folderId, listItem.id).then(() => {
            p_.onResetSelection()
        })
        setIsHovered(false)
    }
    const { listItem, index, folderKey, options } = p_.listItemData
    const active =
        isHovered ||
        (p_.selectedInfo && p_.isEqual && p_.isEqual(p_.selectedInfo, listItem)) ||
        listItem.__view.selected
    const isFolder = p_.isFolder(listItem)
    const customElement: React.ReactNode =
        p_.template.customElementInSelect && p_.template.customElementInSelect(listItem, active)
    const onOpenEditSetting = (listItem: any): any => {
        if (listItem.type === 'text') {
            return () => dispatch(activateEditText(listItem.id))
        }
        if (listItem.type === 'link') {
            return () => dispatch(activateEditLink(listItem.id))
        }
        if (listItem.type === 'table') {
            return () => dispatch(activateEditTable(listItem.id))
        }
    }
    return (
        <DndDroppable
            tag={'tr'}
            data={dragData}
            dragEnabled={p_.drag}
            dropEnabled={isFolder}
            wrapperComponent={{
                type: DndDraggable,
            }}
            onDragStart={p_.onDragStart}
            onDragEnd={p_.onDragEnd}
            onDrop={onDrop}
            onClick={(e: React.MouseEvent) => {
                e.stopPropagation()

                if (p_.template) {
                    if (p_.template.dndDblClickDisable) {
                        return
                    }
                }

                if (helpers.isDblTouchTap(e)) {
                    if (typeof p_.onSelectFolder === 'function' && p_.isFolder(listItem)) {
                        p_.onSelectFolder(listItem)
                    } else {
                        if (p_.onDoubleClickObject) {
                            p_.onDoubleClickObject(listItem)
                        }
                    }
                    return
                }

                onSelectInfo(index)
            }}
            className={cn({ [item.tableSelected]: active && !p_.template.hideActiveRowColor })}
        >
            {p_.multipleSelect && !customElement && (
                <td>
                    <div className={item.tableCell}>
                        <Checkbox
                            onClick={(selected: boolean, e: React.MouseEvent) => {
                                e.stopPropagation()
                                p_.onSelect({ selected, item: listItem })
                            }}
                            checked={listItem.__view.selected}
                            mod={active && !p_.template.hideActiveRowColor ? 'circleInv' : 'circle'}
                            disabled={
                                (p_.disableFolderSelection && p_.isFolder(listItem)) ||
                                p_.disableMultiSelect(listItem) ||
                                listItem.type === 'file'
                            }
                        />
                    </div>
                </td>
            )}
            {p_.treeView && (
                <td>
                    {Boolean(isFolder) && (
                        <Icon
                            onClick={(e) => {
                                e.stopPropagation()

                                if (p_.onFolderOpening) {
                                    p_.onFolderOpening(listItem, p_.nested)
                                }
                            }}
                            type={listItem.__view.isOpen ? 'arrow_down' : 'arrow_right'}
                        />
                    )}
                </td>
            )}
            {customElement && (
                <td>
                    <div className={item.tableCell}>{customElement}</div>
                </td>
            )}
            {options.map((option: { id: string; name: string; hide: boolean }, index: number) => (
                <td
                    key={`catalogListItem_${folderKey}_${listItem.id}_${index}`}
                    onDoubleClick={onOpenEditSetting(listItem)}
                >
                    {p_.nestedLevel !== undefined && (
                        <div className={item.tableCell}>
                            {p_.getCellValue(listItem, option.id, active, p_.nestedLevel)}
                        </div>
                    )}
                </td>
            ))}
            {p_.template.onDeleteListItem && (
                <td>
                    <div className={styles.deleteIcon}>
                        <Icon
                            onClick={function () {
                                p_.template.onDeleteListItem(listItem)
                            }}
                            type={'delete'}
                            color={active ? 'blue' : 'darkCloud'}
                        />
                    </div>
                </td>
            )}
            {p_.onEditListItem && (
                <td>
                    <div className={styles.deleteIcon}>
                        <Icon
                            onClick={function () {
                                if (p_.onEditListItem) {
                                    p_.onEditListItem(listItem)
                                }
                            }}
                            type={'pencil'}
                            color={active ? 'blue' : 'darkCloud'}
                        />
                    </div>
                </td>
            )}
        </DndDroppable>
    )
}

const CatalogList = (p_: ICatalogListProps) => {
    const getCellValue = (listItem: any, id: string, active: boolean, nestedLevel?: number) => {
        const cellRender = p_.template.list

        if (cellRender && cellRender[id]) {
            return cellRender[id](listItem, active, p_, nestedLevel)
        } else {
            const value = listItem[id]
            if (typeof value === 'object') {
                return null
            } else {
                return value
            }
        }
    }
    const renderTree = (
        items: {
            id: number
            type: string
            __view: { selected: boolean; isOpen: boolean }
        }[],
        options: { name: string; id: string; hide: boolean }[]
    ) => {
        const folders = helpers.deepCopy(items).map((item: any, index: number) => {
            if (!item.__view) {
                item.__view = {
                    index,
                }
            } else {
                item.__view.index = index
            }

            return item
        })

        const nested = folders.filter((elt: any, idx: number, arr: { id: string; children: React.ReactNode[] }[]) => {
            const parent = arr.find((e: any) => p_.template.isFolder(e) && e.id === elt[elt.__view.nestedKey])
            if (!parent) return true
            ;(parent.children = parent.children || []).push(elt)
            return false
        })

        const elements: React.ReactNode[] = []

        const loopOptions = (
            data: { id: number; __view: { index: number; isOpen: boolean }; children: any }[],
            nestedLevel: number
        ) => {
            data.forEach((elem) => {
                const isFolder = p_.isFolder(elem) ? 'folder' : 'item'

                elements.push(
                    <ListInner
                        {...p_}
                        getCellValue={getCellValue}
                        key={`catalogListRow_${isFolder}_${elem.id}_${elem.__view.index}`}
                        listItemData={{ listItem: elem, index: elem.__view.index, isFolder, options }}
                        nestedLevel={nestedLevel}
                    />
                )

                if (elem.children && elem.__view.isOpen) {
                    const counter = nestedLevel + 1
                    loopOptions(elem.children, counter)
                }
            })
        }

        loopOptions(nested, 0)
        return elements
    }

    const options: { name: string; id: string; hide: boolean }[] = p_.options
        ? p_.options.filter((option) => !option.hide)
        : []
    let thStyle: {
        [index: string]: string
    } = {}

    if (p_.template.fixedColWidth) {
        thStyle.width = `${90 / options.length}%`
    }

    if (p_.template.templateCellsWidth) {
        thStyle = p_.template.templateCellsWidth
    }

    return (
        <table
            className={cn(item.tableWrapper, styles.wrapper, {
                [p_.template.tableWrapper]: p_.template.tableWrapper,
            })}
        >
            <thead>
                <tr>
                    {p_.multipleSelect && <th>{translate('choice')}</th>}
                    {p_.treeView && <th />}
                    {options.map((option, index: number) => (
                        <th
                            key={`catalogListHeader_${index}`}
                            style={p_.template.templateCellsWidth ? { width: thStyle[option.id] } : thStyle}
                        >
                            {option.name}
                        </th>
                    ))}
                    {p_.template.onDeleteListItem && <th style={{ width: '32px' }} />}
                </tr>
            </thead>
            <tbody>
                {p_.fakeLine || p_.allowEmptyList ? (
                    <tr key={`catalogListRow_${'fake'}`}>
                        {p_.multipleSelect && (
                            <td>
                                <div className={item.tableCell}>
                                    <Checkbox
                                        checked={false}
                                        onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                        mod={'circle'}
                                        disabled={true}
                                    />
                                </div>
                            </td>
                        )}
                        {options.map((option, index) => (
                            <td key={`catalogListItem_fake_${index}`}>
                                <div className={item.tableCell}>{getCellValue(p_.fakeLine, option.id, true)}</div>
                            </td>
                        ))}
                        {p_.template.onDeleteListItem && <td></td>}
                    </tr>
                ) : null}
                {renderTree(p_.list, options)}
            </tbody>
        </table>
    )
}

CatalogList.defaultProps = {
    multipleSelect: true,
}

export default CatalogList
