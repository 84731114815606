import { addStyles } from 'ethcss'
import './Loader.css'
import { layers } from 'blocks.app/config'
import { defaultThemeStyles } from './Loader-theme'
import { IStyles } from 'theme'
import grid from 'theme/grid/styles'

export const styles: IStyles = {
    wrapper: {
        top: 0,
        position: 'fixed',
        width: '100%',
        zIndex: layers.progress,
    },
    loadbar: {
        position: 'relative',
        height: 6,
    },
    bar: {
        ...grid.fullHeight,
        position: 'absolute',
    },
    selected: {
        backgroundColor: `var(--var-module-ui-component-loader-style-selected, ${defaultThemeStyles['selected']})`,
        animation: 'loading 2s linear 0.5s infinite',
    },
    darkCloud: {
        backgroundColor: `var(--var-module-ui-component-loader-style-activeBackground, ${defaultThemeStyles.activeBackground})`,
        animation: 'loading 2s linear 1s infinite',
    },
    white: {
        backgroundColor: `var(--var-module-ui-component-loader-style-background, ${defaultThemeStyles.background})`,
        animation: 'loading 2s linear 1.5s infinite',
    },
}

addStyles(styles)
