import { useEffect } from 'react'
import { TStorageEventKeys } from 'core/models/Storage'

export interface IUseAppSyncService {
    onAuthorization: () => void
    onLogout: () => void
}

export const useAppSyncService = ({ onAuthorization, onLogout }: IUseAppSyncService) => {
    useEffect(() => {
        createEventListeners()
        return () => {
            destroyEventListeners()
        }
    }, [])

    const createEventListeners = () => {
        window.addEventListener('storage', onStorageEvent)
    }

    const onStorageEvent = ({ key, oldValue, newValue }: StorageEvent) => {
        switch (key as TStorageEventKeys) {
            case 'authorization__user': {
                onAuthorizationEvent(oldValue, newValue)
            }
        }
    }

    const onAuthorizationEvent = (oldValue: string | null, newValue: string | null) => {
        if (oldValue === null && newValue) {
            onAuthorization()
        }

        if (oldValue && newValue === null) {
            onLogout()
        }
    }

    const destroyEventListeners = () => {
        window.removeEventListener('storage', onStorageEvent)
    }
}
