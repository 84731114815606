import React, { FC, useEffect } from 'react'
import helpers from 'core/helpers'
import { styles } from './ContentWithoutToolbar-styles'
import { cn } from 'ethcss'
import translate from 'core/translate'
import { useDispatch, useSelector } from 'react-redux'
import { routesKeys } from 'features/routes'
import { useLocation } from 'react-router'
import { IContentWithoutToolbar } from './ContentWithoutToolbar-types'
import { isExist } from 'core/utils/coreUtil'
import { headActions } from 'features/head/Head.state'
import { isHorizontalMenu, themeFromStore } from 'core/helpers/menu'

const ContentWithoutToolbarComponent: FC<IContentWithoutToolbar> = ({
    title,
    children,
    className,
    wrapperClassName,
}) => {
    const dispatch = useDispatch()
    const { minimizeMenu: minimize, showMobToolbar } = useSelector((state: any) => state.app)
    const user = useSelector((state: any) => state.user)
    const location = useLocation()
    const pathname = helpers.getPathname(location.pathname)

    useEffect(() => {
        if (title) {
            dispatch(headActions.setTitle([translate(title)]))
        }
    }, [])

    const isAccessAvailable = () => {
        return helpers.isAvailable({
            key: routesKeys[pathname],
            action: 'read',
            permissions: user.data.permissions,
        })
    }

    const isRouteExist = () => {
        return isExist(routesKeys[pathname])
    }

    const renderContent = (childContent: JSX.Element | JSX.Element[] | string) => {
        const additionStyles = !isRouteExist() ? styles.ContentWithoutToolbar_type_centered : {}
        const horizontalMenu = isHorizontalMenu()

        return (
            <div
                className={cn(
                    styles.ContentWithoutToolbar,
                    themeFromStore() === 'custom' &&
                        (pathname === 'scoreboards' || pathname === 'distributive') &&
                        styles.ContentWithoutToolbar_custom_theme_background,
                    wrapperClassName,
                    additionStyles,
                    {
                        [styles.ContentWithoutToolbar_type_minimize]: minimize,
                        [styles.ContentWithoutToolbar_type_default]: !minimize && !horizontalMenu,
                        [styles.ContentWithoutToolbar_type_default_x]: horizontalMenu,
                    }
                )}
            >
                <div className={cn(styles.content, className, styles.ContentWithoutToolbar__content)}>
                    {childContent}
                </div>
            </div>
        )
    }

    return !isAccessAvailable() ? renderContent(translate('noAccesses')) : renderContent(children)
}

export const ContentWithoutToolbar = ContentWithoutToolbarComponent
