import { addStyles } from 'ethcss'
import { item, IStyles } from 'theme'

export const avatarStyles: IStyles = {
    wrapper: {
        ...item.rel
    },
    image: {
        ...item.circle,
        ...item.cover,
        ...item.abs,
        ...item.fullAbs,
        ...item.full
    }
}

addStyles(avatarStyles)
