import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles } from './user.theme'
import { colors, IStyles, mediaCSS } from 'theme'

const userStyles: IStyles = {
    wrapper: {
        ...grid.colCenter,
        ...item.rel,
    },
    wrapper_x: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        ...item.transitionErase,
        ...grid.pt_mini,
        color: `var(--var-module-app-component-user-style-textMain, ${defaultThemeStyles.textMain})`,
        maxWidth: '100%',
        opacity: 1,
    },
    hidden: {
        opacity: 0,
    },
    role: {
        ...grid.pt_nano,
        textTransform: 'uppercase',
        color: `var(--var-module-app-component-user-style-textSub, ${defaultThemeStyles.textSub})`,
        textAlign: 'center',
    },
    dropzoneHide: {
        ...item.hidden,
        width: 0,
        height: 0,
    },
    imgWrapper: {
        ...item.transitionErase,
        ...item.rel,
    },
    userWrapper_x: {
        ...item.transitionErase,
        ...item.rel,
        zIndex: 1000,
        marginLeft: '15px',
        color: colors.grey,
        width: 170,
        cursor: 'pointer',
        [mediaCSS.desktopL]: {
            marginLeft: '0px',
            marginRight: '16px',
            display: 'flex',
            justifyContent: 'center',
        },
    },
    email: {
        display: 'visible',
        [mediaCSS.desktopXL]: {
            display: 'none',
        },
    },
    img: {
        ...item.circle,
        ...item.cover,
    },
    loading: {
        ...grid.rowCenter,
        ...item.circle,
        ...item.backOpacity,
        ...item.abs,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    badge: {
        ...item.abs,
        ...item.pointer,
        bottom: 25,
        right: 25,
    },
    content: {
        ...grid.space,
        ...grid.ml_md,
    },
    activeDirectory: {
        position: 'absolute',
        right: 0,
        bottom: 0,
    },
}

addStyles(userStyles)
export default userStyles
