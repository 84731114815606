import { addStyles } from 'ethcss'
import { colors } from 'theme/colors'
import { IStyles, mediaCSS } from '../../../theme'

const downloadWidgetStyles: IStyles = {
    wrapper: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 2000,
        backgroundColor: 'rgba(0,0,0, 0.2)',
    },
    downloadModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: colors.white,
        borderRadius: 20,
        padding: 30,
        width: 400,

        [mediaCSS.mob]: {
            padding: '20px 10px',
            width: '96%',
            borderRadius: 10,
        },
    },
    widgetLeft: {
        display: 'flex',
    },
    titleAndDesc: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: 26,
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        color: colors.blackLight,
        marginBottom: 10,
    },
    shortDesc: {
        color: colors.grey,
    },
    saveToWrapper: {
        marginTop: 40,
    },
    saveTo: {
        marginBottom: 6,
        color: colors.grey,
    },
    btnGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 30,
    },
    modalBtn: {
        height: 40,
        padding: '0 34px',

        [mediaCSS.mob]: {
            padding: '0 30px',
        },
    },
    success: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 20,
    },
}

addStyles(downloadWidgetStyles)
export default downloadWidgetStyles
