import { addStyles } from 'ethcss'
import { grid, IStyles, item } from 'theme'
import { defaultThemeStyles } from './DownloadProgressModal.theme'

export const styles: IStyles = {
    downloadModal__wrapper: {
        ...item.rounded_full_mini,
        ...item.shadowModal,
        ...item.overNone,
        position: 'fixed',
    },
    downloadModal__contentWrapper: {
        ...grid.full,
        ...grid.col,
    },
    downloadModal__content: {
        ...grid.col,
        ...grid.full,
        overflowY: 'auto',
        backgroundColor: `var(--var-module-app-component-downloads-style-background, ${defaultThemeStyles.background})`,
    },
    downloadModal__header: {
        ...grid.p_mini,
        ...grid.row,
        ...grid.noWrap,
        ...grid.normalCenter,
        ...grid.justify,
        color: `var(--var-module-app-component-downloads-style-textMain, ${defaultThemeStyles.textMain})`,
        backgroundColor: `var(--var-module-app-component-downloads-style-header, ${defaultThemeStyles.header})`,
    },
    downloadModal__contentSearchWrapper: {
        ...grid.col,
        ...grid.full,
        overflowY: 'auto',
        backgroundColor: `var(--var-module-app-component-downloads-style-background, ${defaultThemeStyles.background})`,
    },
    downloadModal__search: {
        width: '100%',
        padding: 10,
    },
    downloadModal__title: {
        ...grid.w100,
        ...grid.p_mini,
        ...grid.pl_mdPlus,
        ...grid.pr_mdPlus,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.noWrap,
        position: 'relative',
        color: `var(--var-module-app-component-downloads-style-title, ${defaultThemeStyles.title})`,
        borderBottom: `1px solid var(--var-module-app-component-download-progress-style-border, ${defaultThemeStyles.border})`,
    },
    downloadModal__nameWrapper: {
        ...grid.spaceWidth,
        ...grid.pr_mini,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.noWrap,
    },
    downloadModal__name: {
        ...item.ellipsis,
    },
    downloadModal__progressWrapper: {
        ...grid.row,
        ...grid.noWrap,
    },
    downloadModal__emptyMessage: {
        ...grid.colCenter,
        ...grid.space,
        ...grid.p_md,
        ...item.white,
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    downloadModal__clearAllDownloads: {
        color: `var(--var-module-app-component-downloads-style-title, ${defaultThemeStyles.title})`,
        textDecoration: 'underline',
        cursor: 'pointer',
        padding: 10,
        textAlign: 'right',
    },
    downloadModal__updateText: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '14px',
    },
    downloadModal__downloadActions: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    downloadModal__leftBlock: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    navWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 30px',
    },
}

addStyles(styles)
