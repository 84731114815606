import React, { FC, memo } from 'react'
import { IEmptyListProps } from './EmptyList-types'
import { styles } from './EmptyList-styles'
import Icon from 'blocks.simple/icon/icon'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { Typography } from 'atoms/Typography'
import { isExist } from 'core/utils/coreUtil'
import { cn } from 'ethcss'
import translate from 'core/translate'

const EmptyListComponent: FC<IEmptyListProps> = ({
    children = null,
    isSearchEnable,
    emptyIcon = 'not_allow',
    descriptionText,
    searchText,
    folderText,
}) => {
    const isExistChildren = () => isExist(children)

    return (
        <div className={styles.EmptyList__wrapper}>
            <div className={styles.EmptyList}>
                <div className={styles.EmptyList__content}>
                    {emptyIcon && (
                        <Icon
                            type={emptyIcon}
                            size={60}
                            color={getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable}
                        />
                    )}
                    <div
                        className={cn(styles.EmptyList__info, {
                            [styles.EmptyList__info_type_default]: !isSearchEnable && isExistChildren(),
                        })}
                    >
                        <Typography type="text" className={styles.EmptyList__title}>
                            {isSearchEnable ? searchText : translate(folderText)}
                        </Typography>

                        {!isSearchEnable && descriptionText && (
                            <div className={styles.EmptyList__description}>
                                <div className={styles.EmptyList__text}>{descriptionText}</div>
                            </div>
                        )}
                    </div>
                    {!isSearchEnable && isExistChildren() && children}
                </div>
            </div>
        </div>
    )
}

export const EmptyList = memo(EmptyListComponent)
