import { useState } from 'react'

type selectedInfo = object | null
type selectedItems = any[]

interface IUseSelectedHookArgs {
    selectedInfo?: selectedInfo
    selectedItems?: selectedItems
}

interface IUseSelectedHook extends IUseSelectedHookArgs {
    onSetSelectedInfo: (newStateOfSelectedInfo: selectedInfo) => void
    onSetSelectedItems: (newStateofSelectedItems: selectedItems) => void
    isSelectedInstanceOrCollection: boolean
}

/**
 * [useSelected hook for instance or collection of instance such as displays, users, files, etc]
 * @param  object with selectedInfo<object | null> and/or selectedItems<any[]>
 * @returns selectedInfo (object | null) - instance object or null
 * @returns selectedItems (any[]) - collection of instance
 * @returns onSetSelectedInfo (function) - function for set instance
 * @returns onSetSelectedItems (function) - function for set collection
 * @returns isSelectedInstanceOrCollection (boolean) - return true if selected instance or collection of instance
 */

export const useSelected = ({
    selectedInfo = null,
    selectedItems = [],
}: IUseSelectedHookArgs = {}): IUseSelectedHook => {
    const [selectedInfoState, setSelectedInfo] = useState<any>(selectedInfo)
    const [selectedItemsState, setSelectedItems] = useState<any>(selectedItems)
    const isSelectedInstanceOrCollection = selectedInfoState !== null || selectedItemsState.length > 0

    const onSetSelectedInfo = (selectedInfo: selectedInfo) => {
        setSelectedInfo(selectedInfo)
    }

    const onSetSelectedItems = (selectedItems: selectedItems) => {
        setSelectedItems(selectedItems)
    }

    return {
        selectedInfo: selectedInfoState,
        selectedItems: selectedItemsState,
        onSetSelectedInfo,
        onSetSelectedItems,
        isSelectedInstanceOrCollection,
    }
}
