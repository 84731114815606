import React, { useEffect, useRef, useState } from 'react'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import Colorpicker from 'blocks.simple/colorpicker/colorpicker'
import EditText from 'blocks.simple/editText/editText'
import Range from 'blocks.simple/range/range'
import Icon from 'blocks.simple/icon/icon'
import { cn } from 'ethcss'
import styles from './tableEditor__position.jcss'
import translate from 'core/translate'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import Tippy from 'blocks.simple/tippy/tippy'
import { text as textStyles } from 'theme'
import { Typography } from 'atoms/Typography'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'

const borderLineOptions = [
    {
        id: 1,
        name: (
            <div className={styles.borderBlock}>
                <div className={styles.borderSmall} />
            </div>
        ),
    },
    {
        id: 2,
        name: (
            <div className={styles.borderBlock}>
                <div className={styles.borderSmallPlus} />
            </div>
        ),
    },
    {
        id: 3,
        name: (
            <div className={styles.borderBlock}>
                <div className={styles.borderMedium} />
            </div>
        ),
    },
    {
        id: 4,
        name: (
            <div className={styles.borderBlock}>
                <div className={styles.borderBig} />
            </div>
        ),
    },
]

const borderTypeOptions = [
    {
        id: 'left',
        icon: 'leftBorder',
        iconSize: 18,
        iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
        iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
    },
    {
        id: 'right',
        icon: 'rightBorder',
        iconSize: 18,
        iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
        iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
    },
    {
        id: 'top',
        icon: 'topBorder',
        iconSize: 18,
        iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
        iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
    },
    {
        id: 'bottom',
        icon: 'bottomBorder',
        iconSize: 18,
        iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
        iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
    },
    {
        id: 'full',
        icon: 'fullBorder',
        iconSize: 18,
        iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
        iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
    },
    {
        id: 'no',
        icon: 'noBorder',
        iconSize: 18,
        iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
        iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
    },
]

const sides = ['left', 'top', 'right', 'bottom']

const TableEditorPosition = (p_: {
    onChangePos: (data: any, type: string, side: string) => void
    selectedCellRow: number
    selectedCellCol: number
    small: boolean
    options: {
        background: {
            color: string
            transparency: number
        }
        margins: {
            [index: string]: string
        }
    }
}) => {
    const [state, setState] = useState(getBorders(p_))
    const prevProps = useRef<any>(null)

    const onChangeBorder = (selectedBorder: any) => {
        const { id: type } = selectedBorder
        const s_ = { ...state }
        const data = {
            size: s_.borderSize,
            color: s_.lineColor,
            type: 'solid',
        }

        if (type === 'full') {
            sides.forEach((side) => {
                p_.onChangePos(data, 'borders', side)
            })
            setState((prevState: any) => {
                return {
                    ...prevState,
                    selectedBorder: type,
                }
            })
            return
        }

        if (type === 'no') {
            data.size = 0

            sides.forEach((side) => {
                p_.onChangePos(data, 'borders', side)
            })

            setState((prevState: any) => {
                return {
                    ...prevState,
                    selectedBorder: type,
                }
            })
            return
        }

        p_.onChangePos(data, 'borders', type)
        setState((prevState: any) => {
            return {
                ...prevState,
                selectedBorder: type,
            }
        })
    }

    useEffect(() => {
        if (state.selectedBorder !== 'no') {
            onChangeBorder({ id: state.selectedBorder })
        }
    }, [state.lineColor, state.borderSize])
    useEffect(() => {
        if (prevProps.current) {
            const nextProps = p_
            const isNewSelectedCellRow = nextProps.selectedCellRow !== prevProps.current.selectedCellRow
            const isNewSelectedCellCol = nextProps.selectedCellCol !== prevProps.current.selectedCellCol

            if (isNewSelectedCellCol || isNewSelectedCellRow) {
                setState(getBorders(nextProps))
            }
        }

        prevProps.current = p_
    }, [p_])

    function constructBorder(data: { size: number; color: string }, side: string) {
        return {
            borderSize: data.size,
            lineColor: data.color,
            selectedBorder: side,
        }
    }

    function getBorders(p_: any) {
        let borders = {
            borderSize: 1,
            lineColor: '#000000',
            selectedBorder: 'no',
        }

        let currentBorder = null
        let isFull = true
        let diffItt = 0
        for (let side in p_.options.borders) {
            if (p_.options.borders.hasOwnProperty(side)) {
                const border = p_.options.borders[side]

                if (border.size > 0) {
                    borders = constructBorder(border, side)
                    if (currentBorder) {
                        if (currentBorder.size === border.size && currentBorder.color === border.color) {
                            diffItt++
                        } else {
                            isFull = false
                        }
                    }

                    currentBorder = border
                }
            }
        }

        if (isFull && diffItt === 3) {
            borders.selectedBorder = 'full'
        }

        return borders
    }

    return (
        <div className={cn(grid.fullWidth, grid.mb_big, grid.row)}>
            <div className={cn(styles.column, { [styles.colSmall]: p_.small })}>
                <div className={styles.title}>{translate('background')}</div>
                <div className={cn(grid.row, grid.pl_md, grid.pr_md, grid.pb_mdPlus)}>
                    <div className={grid.mr_mdPlus}>
                        <Typography className={cn(grid.mb_micro)} type="title">
                            {translate('color')}
                        </Typography>
                        <Colorpicker
                            title={translate('background')}
                            color={p_.options.background.color}
                            onChange={function (value) {
                                p_.onChangePos(value, 'background', 'color')
                            }}
                            //disableAlpha
                            className={''}
                            style={{}}
                        />
                    </div>
                    <div>
                        <Typography className={cn(grid.mb_micro)} type="title">
                            {translate('transparency')}
                        </Typography>
                        <Range
                            min={0}
                            max={1}
                            step={0.1}
                            value={p_.options.background.transparency}
                            onChange={function (value: number) {
                                p_.onChangePos(value, 'background', 'transparency')
                            }}
                        />
                    </div>
                </div>
                <div className={styles.title}>{translate('borders')}</div>
                <div className={grid.row}>
                    <div className={cn(styles.col, styles.col_type_borders)}>
                        <div className={cn(grid.row, grid.noWrap, grid.normalCenter)}>
                            <Tippy
                                className={cn(grid.w35, grid.mr_md)}
                                html={translate('selectBorder')}
                                disabled={!(state.selectedBorder === 'no')}
                            >
                                <Dropdown
                                    value={state.borderSize}
                                    options={borderLineOptions}
                                    onChange={({ id }) => {
                                        setState((prevState: any) => {
                                            return {
                                                ...prevState,
                                                borderSize: id,
                                            }
                                        })
                                    }}
                                    label={translate('line')}
                                    disabled={state.selectedBorder === 'no'}
                                />
                            </Tippy>
                            <div className={cn(grid.colCenter, grid.mr_md)}>
                                <div className={textStyles.darkBlue}>{translate('lineColor')}</div>
                                <Tippy
                                    className={grid.p_mini}
                                    html={translate('selectBorder')}
                                    disabled={!(state.selectedBorder === 'no')}
                                >
                                    <Colorpicker
                                        disabled={state.selectedBorder === 'no'}
                                        title={translate('lineColor')}
                                        color={state.lineColor}
                                        onChange={(lineColor) => {
                                            setState((prevState: any) => {
                                                return {
                                                    ...prevState,
                                                    lineColor,
                                                }
                                            })
                                        }}
                                        className={''}
                                        style={{}}
                                    />
                                </Tippy>
                            </div>
                            <Dropdown
                                value={state.selectedBorder}
                                options={borderTypeOptions}
                                label={translate('border')}
                                onChange={onChangeBorder}
                                mod={'none'}
                                rounded={'none'}
                                customHeader={
                                    state.selectedBorder && (
                                        <div className={cn(item.indent_normal, grid.center)}>
                                            <Icon type={`${state.selectedBorder}Border`} color={'darkBlue'} size={24} />
                                        </div>
                                    )
                                }
                                wrapperWidth
                                centered
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={cn(styles.column, { [styles.colSmall]: p_.small })}>
                <div className={styles.title}>{translate('margins')}</div>
                <div className={cn(grid.row, grid.mb_mdPlus, grid.pl_md, grid.pr_md, { [grid.noWrap]: !p_.small })}>
                    {sides.map((side) => (
                        <EditText
                            key={side}
                            type={'number'}
                            containerClass={cn(grid.w35, grid.mb_md, grid.mr_md)}
                            label={translate(side)}
                            value={p_.options.margins[side]}
                            onChange={(value: string) => {
                                p_.onChangePos(value, 'margins', side)
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default TableEditorPosition
