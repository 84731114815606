import styles from './licenses.jcss'
import translate from '../../core/translate'
import { cn } from 'ethcss'
import helpers from '../../core/helpers'
import React from 'react'
import { colors } from '../../theme'

export const LicenseDemo = ({ activate }: { activate: () => void }) => {
    return (
        <div className={styles.licensesRow} style={{ backgroundColor: colors.success }}>
            <div className={styles.licensesCol}>
                <div className={styles.tdInner}>
                    <div className={styles.activateBtn} onClick={activate}>
                        {translate('activate')}
                    </div>
                </div>
            </div>
            <div className={cn(styles.licensesCol, styles.timeLeftAndCount)}>
                <div></div>
                <span className={styles.licenseCount}>
                    {20 +
                        ' ' +
                        helpers.getLabelByNumber(20, [
                            translate('license1'),
                            translate('license2'),
                            translate('license3'),
                        ])}
                </span>
            </div>
            <div className={cn(styles.licensesCol, styles.licenseStatus)}>
                <div>14 Дней</div>
                <div className={styles.licenseType}>{translate('gift')}</div>
            </div>
            <div className={styles.accentLabel}></div>
            <div className={styles.accentFrame}></div>
        </div>
    )
}
