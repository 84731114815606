import { addStyles } from 'ethcss'
import { defaultThemeStyles } from './SelectMapDialog-theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { colors } from 'theme/colors'
import { IStyles, grid, indents } from 'theme'
import { mediaCSS, textSizes } from 'theme'

const fullscreenSize = 42

export const styles: IStyles = {
    selectMapDialog__map: {
        height: '100vh',
    },
    selectMapDialog__close: {
        position: 'absolute',
        ...grid.rowCenter,
        backgroundColor: `var(--var-module-map-component-selectMapDialog-style-background, ${defaultThemeStyles.background})`,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        width: fullscreenSize,
        height: fullscreenSize,
        bottom: indents.md,
        left: `calc(50% - ${fullscreenSize / 2}px)`,
        zIndex: 2001,
    },
    searchBlock: {
        position: 'absolute !important',
        top: '5px',
        left: '5px',
        width: '400px',
    },
    formBlock: {
        width: '100%',
        maxWidth: '100% !important',
    },
    inputField: {
        border: `1px solid ${colors.grey}`,
        padding: '10px 30px 10px 10px',
        borderRadius: '10px',
        width: '100%',
        marginBottom: '4px',
    },
    resultBlock: {
        backgroundColor: '#fff',
        padding: '0 10px',
        borderRadius: '10px',

        '& >div': {
            padding: '5px 0',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            cursor: 'pointer',
        },
    },
    resetBtn: {
        display: 'block',
        position: 'absolute',
        right: '10px',
        top: '-2px',
        fontSize: '30px',
        textDecoration: 'none',
        color: `${colors.grey} !important`,

        '&:hover': {
            color: `${colors.black} !important`,
        },
    },
    openMapWrapper: {
        '& .leaflet-control-zoom': {
            marginTop: '60px !important',
            marginLeft: '16px !important',
            border: `1px solid ${colors.grey}`,

            '& a': {
                color: colors.grey,
            },
        },
    },
    zeroIndex: {
        zIndex: 0,

        '& .leaflet-control-zoom': {
            position: 'fixed',
            bottom: '20px',
            left: '15%',
            marginTop: '60px !important',
            marginLeft: '16px !important',
            border: `1px solid ${colors.grey}`,

            '& a': {
                color: colors.grey,
            },

            [mediaCSS.tab]: {
                left: '10px',
                right: 'auto',
            },
        },
    },
    mapCorrection_x: {
        marginTop: '-22px',

        '& .leaflet-control-zoom': {
            left: '10px !important',
        },

        [mediaCSS.tab]: {
            marginTop: 'auto',
        },
    },
    activeIcon: {
        width: '36px',
    },
}

addStyles(styles)
