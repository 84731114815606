import React from 'react'
import FilterFiles from '../../__filter/_files/catalog__filter_files'
import ItemFiles from '../../__item/_files/catalog__item_files'
import Icon from 'blocks.simple/icon/icon'
import { addFont } from 'addstyles'

import { api } from 'core/api/ConnectionManager'
import styles from './catalog__template_files.jcss'
import helpers from 'core/helpers'
import helpersFiles from 'core/helpers/_files'
import translate from 'core/translate'
import { UploadButton } from 'pages/files/__upload/files__upload'
import { userActions } from 'blocks.app/user/user.state'
import filesize from 'filesize'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'

import omitEmpty from 'omit-empty'
import store from 'core/store'

export default {
    folderName: 'folderId',
    getMethod: 'getFilesAndFolders',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noFiles'),
    emptyIcon: 'not_allow',
    moveItem: (id, folderId) => api.send('updateFile', { id, folderId }),
    moveFolder: (id, folderId) => api.send('updateFolder', { id, folderId }),
    multipleMove: (sourceId, sourceFolderId, parentId) => {
        const data = omitEmpty({
            sourceId,
            sourceFolderId,
            parentId,
        })

        api.send('moveFilesAndFolders', data)
    },
    getDragData: (p_) => {
        return JSON.stringify(helpersFiles.getFileItem(p_.item))
    },
    addItemButton: (self) => {
        if (self.query.type !== 'text' && self.query.type !== 'link') {
            return <UploadButton />
        }
    },
    headerButton: () => <UploadButton mod="fill" />,
    headerFilters: FilterFiles,
    settingsPrefixes: 'files',
    tile: ItemFiles,
    cols: [8, 10],
    wrapperClassName: styles.wrapper,
    cellClassName: styles.cellWrapper,
    mediaCellClassName: styles.mediaCellClassName,
    panelClassName: styles.panelWrapper,
    viewOptions: () => [
        {
            id: 'tile',
            name: translate('tile'),
            icon: 'tile',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
        {
            id: 'list',
            name: translate('list'),
            icon: 'list',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
    ],
    sortOptions: () => [
        { id: '-createdAt', name: translate('sortCreatedAt') },
        { id: 'name', name: translate('sortName') },
        { id: '-size', name: translate('sortSize') },
    ],
    getItemModel: (dataItem, getSelected) => {
        const item = { ...dataItem }
        const empty = { fileType: 'empty' }

        if (dataItem.isDirectory) {
            dataItem.content = [empty, empty, empty, empty]
        }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    getBackendModel: (item) => {
        if (!item.isDirectory) {
            return api.send('getFile', { id: item.id }, { hideLoader: true })
        } else {
            return api.send('getFolder', { folderId: item.id }, { hideLoader: true })
        }
    },
    isEqual: (item, prevItem) => helpersFiles.isEqual({ item, prevItem }),
    onCreateListeners: [
        (cb, clearList) => api.addObserver('folderCreated', cb, clearList),
        (cb, clearList) => api.addObserver('fileCreated', cb, clearList),
    ],
    onUpdateListeners: [
        (cb, clearList) => api.addObserver('folderUpdated', cb, clearList),
        (cb, clearList) => api.addObserver('fileUpdated', cb, clearList),
    ],
    onDeleteListeners: [
        (callback, clearList) =>
            api.addObserver('folderDeleted', ({ id }) => callback({ id, isDirectory: 1 }), clearList),
        (callback, clearList) =>
            api.addObserver('fileDeleted', ({ id }) => callback({ id, isDirectory: 0 }), clearList),
    ],
    isFolder: (item) => item.isDirectory,
    tableWrapper: styles.tableWrapper,
    fields: () => [
        { id: 'type', name: translate('type'), hide: true },
        { id: 'fileType', name: translate('type'), hide: true },
        { id: 'name', name: translate('name') },
        { id: 'size', name: translate('size') },
        { id: 'duration', name: translate('durationFull') },
        { id: 'createdAt', name: translate('createdAt') },
        { id: 'updatedAt', name: translate('updatedAt') },
    ],
    init(callback) {
        this.setState({ isInit: true, fields: this.template.fields() }, callback)

        const state = store.getState()
        const userState = state.user

        if (!userState.isFontsLoaded) {
            api.send('getFonts', { withCommon: true }).then((res) => {
                store.dispatch(userActions.setFont())

                res.forEach(function (font) {
                    if (font.fileUrl) {
                        addFont(font.fileUrl, `font-family: ${font.name}; font-weight: normal`)
                    }
                })
            })
        }
    },
    getBreadcrumbs() {
        const initBreadcrumb = { name: 'content', id: undefined }
        const breadcrumbId = this.query.folderId

        if (breadcrumbId) {
            api.send('getFolderBreadcrumbs', { folderId: breadcrumbId }).then((breadcrumbs) => {
                breadcrumbs.reverse()
                breadcrumbs.unshift(initBreadcrumb)
                this.setState({ breadcrumbs })
            })
        } else {
            this.setState({ breadcrumbs: [initBreadcrumb] })
        }
    },
    moveOnUpdate(item) {
        let currentFolderId = this.query.folderId || null
        if (currentFolderId) {
            currentFolderId = parseInt(currentFolderId, 10)
        }

        return !this.query.fileType && item.folderId !== currentFolderId
    },
    perPage: 60,
    customElementInSelect(item, active) {
        if (item.fileType === 'video' && item.dataLandscapeStatus === 'error') {
            return (
                <Icon
                    className={styles.rotateIconWrapper}
                    type={'rotate'}
                    onClick={function (e) {
                        e.stopPropagation()
                        api.send('retryRotateVideo', { sourceId: item.id })
                    }}
                    size={25}
                    color={active ? 'white' : 'grey'}
                />
            )
        }
    },
    list: {
        size: ({ size }) => {
            if (size >= 0 && size !== null) {
                return <span>{filesize(size, { round: 0 })}</span>
            }
        },
        name: (item, active, p_, nested) => {
            let iconType = 'files'

            if (item.fileType) {
                iconType = helpersFiles.getFileIconName(item)
            }

            return (
                <div
                    className={styles.name}
                    style={{
                        marginLeft: 40 * nested,
                    }}
                >
                    <Icon className={styles.icon} type={iconType} />

                    <div style={helpersFiles.convertStyles(item.style, { withoutStyles: ['fontSize'] })}>
                        {helpers.strip_html_tags(item.name)}
                    </div>
                </div>
            )
        },
        createdAt: ({ createdAt }) => helpers.getFormattedLocalDate(createdAt, 'DD/MM/YYYY'),
        duration: ({ duration }) => {
            if (!duration) {
                return '-'
            } else {
                duration = parseInt(duration, 10)
                return helpersFiles.convertSeconds(duration)
            }
        },
        updatedAt: ({ updatedAt }) => helpers.getFormattedLocalDate(updatedAt, 'DD/MM/YYYY'),
    },
}
