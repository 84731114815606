import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../../theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { defaultThemeStyles } from './BroadcastAddVideowall-theme'

const broadcastsAddVideowallStyles: IStyles = {
    panel: {
        ...item.panel,
        ...grid.p_none,
        ...grid.mb_mini,
    },
    buttons: {
        ...item.panel,
        ...grid.rowCenter,
    },
    preview: {
        borderTop: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        ...grid.colCenter,
        ...grid.p_mdPlus,
    },
    previewContent: {
        ...grid.full,
        position: 'relative',
        backgroundColor: `var(--var-module-broadcast-component-videowall-style-preview, ${defaultThemeStyles.preview})`,
    },
    previewRectangle: {
        ...grid.fullWidth,
        ...grid.colCenter,
        overflow: 'hidden',
    },
    previewImageWrapper: {
        position: 'absolute',
        ...item.containSpace,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 10,
    },
    previewImage: {
        ...item.contain,
        position: 'relative',
        ...grid.fullWidth,
        top: '18%',
        height: '64%',
        backgroundImage: `url(images/helpers/empty.png)`,
    },
    hLine: {
        position: 'absolute',
        height: 2,
        left: 0,
        right: 0,
        backgroundColor: `var(--var-module-broadcast-component-videowall-style-wallLine, ${defaultThemeStyles.wallLine})`,
        zIndex: 200,
    },
    vLine: {
        position: 'absolute',
        width: 2,
        top: 0,
        bottom: 0,
        backgroundColor: `var(--var-module-broadcast-component-videowall-style-wallLine, ${defaultThemeStyles.wallLine})`,
        zIndex: 200,
    },
    settings: {
        ...item.panel,
        ...grid.mb_mini,
        ...grid.colCenter,
    },
    settingsItem: {
        ...grid.rowCenter,
        color: `var(--var-module-app-component-app-style-textMain, ${defaultThemeAppStyles.textMain})`,
    },
    devices: {
        ...item.panel,
        ...grid.space,
        ...grid.col,
        ...grid.p_mdPlus,
        color: `var(--var-module-app-component-app-style-textMain, ${defaultThemeAppStyles.textMain})`,
    },
    tooltipWrapper: {
        ...grid.p_mini,
        ...grid.colCenter,
        color: `var(--var-module-broadcast-component-videowall-style-tooltip, ${defaultThemeStyles.tooltip})`,
    },
    tooltipTitle: {
        textTransform: 'uppercase',
        ...grid.mb_mini,
    },
    tooltipDescription: {
        textAlign: 'center',
    },
    tooltipResolutionSize: {
        whiteSpace: 'nowrap',
    },
}

addStyles(broadcastsAddVideowallStyles)
export default broadcastsAddVideowallStyles
