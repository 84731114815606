import React from 'react'
import Icon from 'blocks.simple/icon/icon'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import { Scrollbars } from 'react-custom-scrollbars'
import { cn } from 'ethcss'
import translate from 'core/translate'
import { grid } from 'theme'
import { styles } from './DraftDeviceInformation-styles'
import helpers from 'core/helpers'
import helpersDigitalSignages from 'core/helpers/_digitalSignages'
import { Typography } from 'atoms/Typography'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import {
    DraftDeviceInformationProps,
    DraftDeviceInformationState,
    FormattedDeviceParameters,
} from './DraftDeviceInformation-types'
import Toast from 'blocks.simple/toast/toast'
import item from 'theme/item/styles'
import { IDevice, TDeviceValues } from 'core/models/DigitalSignage'
import deepEqual from 'fast-deep-equal'
import filesize from 'filesize'

const isNeedRemoveDiagonal = (platform: string, diagonal: number) => {
    if (!platform || !diagonal) return false

    switch (platform) {
        case 'SSSP 2':
        case 'SSSP 3':
            return true
        default:
            return false
    }
}

const getParametersFromDevice = (device: IDevice) => {
    const deviceParams = { ...device } as Partial<IDevice>
    const parameters: FormattedDeviceParameters = {}
    const sizes = ['storageSize', 'usedStorageSize', 'freeStorageSize']
    const dates = ['lastScreenshotDate', 'createdAt', 'lastConnection', 'updatedAt']

    if (
        deviceParams['platform'] &&
        deviceParams['diagonal'] &&
        isNeedRemoveDiagonal(deviceParams['platform'], deviceParams['diagonal'])
    ) {
        delete deviceParams['diagonal']
    }

    for (let key in deviceParams) {
        if (!deviceParams.hasOwnProperty(key)) continue

        const paramsKey = key as keyof IDevice
        const parameter = deviceParams[paramsKey] as TDeviceValues

        if (Array.isArray(parameter)) {
            parameters[paramsKey] = parameter.join(', ')
            continue
        }

        if (typeof parameter === 'string' || typeof parameter === 'number') {
            if (sizes.includes(paramsKey) && parameter > 0) {
                parameters[paramsKey] = filesize(parameter as number)
                continue
            }

            if (dates.includes(paramsKey)) {
                parameters[paramsKey] = helpers.getFormattedLocalDate(parameter as string, 'DD/MM/YYYY HH:mm') as string
                continue
            }

            if (paramsKey === 'orientation') {
                const orientation = helpersDigitalSignages.getOrientation(parameter as number)

                if (orientation) {
                    parameters[paramsKey] = orientation.name
                    continue
                }
            }

            parameters[key] = deviceParams[paramsKey] as TDeviceValues
        }
    }

    return sortedParams(parameters)
}

function sortedParams(parameters: FormattedDeviceParameters) {
    const keys = [
        'id',
        'deviceId',
        'idCashRegisterGlobal',
        'dsVersion',
        'name',
        'description',
        'manufacturer',
        'modelName',
        'serialNumber',
        'platform',
        'firmwareVersion',
        'applicationURL',
        'orientation',
        'resolutionWidth',
        'resolutionHeight',
        'ipAddress',
        'macAddress',
        'imei',
        'lastConnection',
        'lastScreenshotDate',
        'lastScreenshot',
        'storageSize',
        'usedStorageSize',
        'freeStorageSize',
        'volume',
        'groupId',
        'companyId',
        'updatedAt',
        'createdAt',
    ]

    return keys.reduce<FormattedDeviceParameters>((acc, key) => {
        if (parameters.hasOwnProperty(key)) {
            const value = parameters[key].toString()
            if (parameters[key] !== null && value.length && value !== 'undef') {
                acc[key] = parameters[key]
            }
        }
        return acc
    }, {})
}

const noBroadcast = helpersDigitalSignages.getNoBroadcastIconSrc()
const initialFormattedDeviceParameters = {} as FormattedDeviceParameters

class DraftDeviceInformationComponent extends React.Component<
    DraftDeviceInformationProps,
    DraftDeviceInformationState
> {
    state = {
        formattedParameters: initialFormattedDeviceParameters,
    }

    componentDidMount() {
        const { device } = this.props

        this.setState({
            formattedParameters: getParametersFromDevice(device),
        })
    }

    static getDerivedStateFromProps(nextProps: DraftDeviceInformationProps, prevState: DraftDeviceInformationState) {
        const formattedParameters = getParametersFromDevice(nextProps.device)

        if (!deepEqual(formattedParameters, prevState.formattedParameters)) {
            return {
                formattedParameters,
            }
        }

        return null
    }

    copyToClipboard = (data: any) => {
        const clipboardResultToastOptions = {
            autoClose: 3000,
            position: 'bottom-left',
        }

        navigator.clipboard.writeText(data).then(
            () => {
                Toast.info(translate('copiedToClipboard'), clipboardResultToastOptions)
            },
            () => {
                Toast.error(translate('copyToClipboardError'), clipboardResultToastOptions)
            }
        )
    }

    copyParametersToClipboard = () => {
        const parametersString = Object.entries(this.state.formattedParameters).reduce((acc, [key, value]) => {
            acc += `${translate(key)}: ${value}\n`

            return acc
        }, '')

        this.copyToClipboard(parametersString)
    }

    copyParameterValueToClipboard = (value: any) => () => {
        this.copyToClipboard(value)
    }

    renderParamsList = () => {
        const { formattedParameters } = this.state

        return Array.from(Object.keys(formattedParameters)).map((key) => {
            return (
                <div key={key} className={styles.paramsItem}>
                    <Typography type="title" className={cn(grid.w45)}>
                        {translate(key)}
                    </Typography>
                    <Typography type="title" className={styles.displayInfoParameterValue}>
                        <span onClick={this.copyParameterValueToClipboard(formattedParameters[key])}>
                            {formattedParameters[key]}
                        </span>
                    </Typography>
                </div>
            )
        })
    }

    render() {
        const { device } = this.props

        return (
            <Scrollbars style={{ width: '100%', height: '100%' }}>
                <div className={cn(grid.full, grid.p_md)}>
                    <div className={styles.topInfo}></div>
                    <div className={styles.wrapper}>
                        <div className={styles.screenshot}>
                            <div className={cn(styles.image, grid.row, grid.p_micro, item.rel)}>
                                <Rectangle
                                    className={styles.imageContent}
                                    width={8}
                                    height={5}
                                    contain={!!device.cover}
                                    center={!device.cover}
                                    src={device.cover ? device.cover[0] : noBroadcast}
                                />
                            </div>
                        </div>
                        <div className={styles.params}>
                            <div className={styles.copyToBufferIconWrapper}>
                                <Icon
                                    type="paste"
                                    size={24}
                                    tooltip={{ html: translate('copyToClipboard') }}
                                    onClick={this.copyParametersToClipboard}
                                    color={getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable}
                                />
                            </div>
                            <div className={styles.paramsList}>{this.renderParamsList()}</div>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        )
    }
}

export const DraftDeviceInformation = DraftDeviceInformationComponent
