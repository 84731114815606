import React, { FC } from 'react'
import { ITableCellProps } from './TableCell-types'
import { styles } from './TableCell-styles'
import { cn } from 'ethcss'

export const TableCell: FC<ITableCellProps> = ({ children, className = {}, style = {}, onClick = null }) => {
    return (
        <td className={cn(styles.TableCell, className)} style={style} onClick={onClick}>
            {children}
        </td>
    )
}
