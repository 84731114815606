import React, { useEffect } from 'react'
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'

interface ITippyProps {
    position?: any
    title?: string
    arrow?: boolean
    className?: string
    children?: any
    html?: any
    style?: {}
    disabled?: boolean
    followCursor?: boolean
    open?: boolean
}

const Tippy = (p_: ITippyProps) => {
    const { children, ...props } = p_

    const clearTooltips = () => {
        let tooltips: any = document.querySelectorAll('.tippy-popper')

        if (tooltips && tooltips.length) {
            tooltips = Array.prototype.slice.call(tooltips, 0)

            tooltips.forEach((tooltip: any) => {
                document.body.removeChild(tooltip)
            })
        }
    }

    useEffect(() => {
        clearTooltips()
    }, [p_])

    if (!props.title && props.html === null) {
        return children
    }

    return <Tooltip {...props}>{children}</Tooltip>
}

export default Tippy
