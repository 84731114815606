import Dropdown from 'blocks.simple/dropdown/dropdown'
import { isArray } from 'core/utils/coreUtil'
import React from 'react'
import styles from './deviceSettings__pictureMode.jcss'

const DeviceSettingsPictureMode = ({
    list,
    active,
    title,
    onChange,
    enabled,
}: {
    list: any
    active: boolean
    title: string
    enabled: boolean
    onChange: (pictureMode: any, field: string) => void
}) => {
    const isPictureModeSettingsExist = () => {
        return isArray(list) && active
    }

    if (!enabled || !isPictureModeSettingsExist()) {
        return null
    }

    let getDropdownOptionList = () => {
        if (!list) return []

        return list.map((pictureMode: { id: number; name: string }) => {
            return {
                id: pictureMode.id,
                name: pictureMode.name,
            }
        })
    }

    let changePictureMode = (pictureMode: { id: number; name: string }) => {
        onChange(pictureMode.id, 'pictureMode')
    }

    return (
        <div className={styles.DeviceSettingsPictureMode}>
            <div className={styles.DeviceSettingsPictureMode__list}>
                <Dropdown
                    label={title}
                    value={active}
                    options={getDropdownOptionList()}
                    onChange={changePictureMode}
                    mod={'default'}
                />
            </div>
        </div>
    )
}

export default DeviceSettingsPictureMode
