import React from 'react'
import Collapse from 'blocks.simple/collapse/collapse'
import CatalogSelectContent from 'blocks.app/catalog/_select/_content/catalog_select_content'
import helpers from 'core/helpers'
import translate from 'core/translate'
import { grid } from '../../../theme'
import { useDispatch } from 'react-redux'
import { updateCompany } from 'blocks.app/user/user.state'
import styles from '../settings.jcss'
import { cn } from 'ethcss'
import { IPlaceholder } from '../../../core/models/Placeholder'

interface ISource {
    source: IPlaceholder
}

const SettingsPlaceholder = ({ source }: ISource) => {
    const dispatch = useDispatch()

    function setViewProp() {
        if (!source) return source

        return { ...source, __view: { selected: true } }
    }

    function onSelect(placeholderSource: IPlaceholder) {
        const updateData = {
            data: {
                placeholderSource,
                placeholderSourceId: placeholderSource.id,
            },
            isSaveCompany: true,
        }

        dispatch(updateCompany(updateData))
    }

    function onRemove() {
        const updateData: any = {
            data: {
                placeholderSource: null,
                placeholderSourceId: null,
            },
            isSaveCompany: true,
        }

        dispatch(updateCompany(updateData))
    }

    const isCanUpdatePlaceholder = helpers.isAvailable({ key: 'settings', action: 'update' })

    return (
        <Collapse
            title={() => translate('defaultGraphicsContent')}
            className={cn(styles.background, grid.mb_md)}
            noWrap
        >
            <CatalogSelectContent
                type="image"
                selectedInfo={setViewProp()}
                onSelect={isCanUpdatePlaceholder ? onSelect : null}
                onRemove={isCanUpdatePlaceholder ? onRemove : null}
                selectedItemStyles={{ width: 150 }}
                title={translate('selectPlaceholder')}
                showFileName
                showSelectButton={isCanUpdatePlaceholder}
                contentAndFolders={true}
                autoSelectContentType={true}
            />
        </Collapse>
    )
}

export default SettingsPlaceholder
