import React, { Fragment, useEffect, useState } from 'react'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import Icon from 'blocks.simple/icon/icon'
import Animation from 'blocks.simple/animation/animation'
import DndDraggable from 'blocks.app/dnd/_draggable/dnd_draggable'
import Tippy from 'blocks.simple/tippy/tippy'
import DotTooltip from 'blocks.simple/dotTooltip/dotTooltip'
import { cn } from 'ethcss'
import styles from './catalog__item_displays.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { text as textStyles } from 'theme'
import translate from 'core/translate'
import displaysHelpers from 'core/helpers/_digitalSignages'
import helpers from 'core/helpers'
import helpersCore from 'core/helpers'
import { indents } from 'blocks.app/config'
import { ProgressBar } from 'atoms/ProgressBar'
import { Status } from 'molecules/Status'
import { Marker } from 'atoms/Marker'
import { helpersMarker } from 'core/helpers/marker'
import { history } from 'features/routes'
import { TMarkerStatusType } from '../../../../atoms/Marker/Marker-types'

type Item = {
    cover: string[]
    activeQ?: boolean
    descendantStatusCount: {
        status: string
        count: number
    }[]
    __view: { selected: boolean }
    downloadPercentage: { title: string; percentage: number }[]
    identificatedItem: boolean
    name: string
    type: string
    status: string
    registrationCode: string
    hasBroadcast: boolean
    orientation: number
    modelName: string
    platform: string
}

interface IItemDisplaysProps {
    item: Item
    deviceBlockStyle: string
    active: boolean
    onDoubleClickObject: (data: Item) => void
    onDoubleClick: (value: boolean) => void
    onSelectInfo: (e: React.MouseEvent) => void
    onSelectFolder: (item: Item) => void
    onDragStart: () => void
    onDragEnd: () => void
    drag: boolean
    multipleSelect: boolean
    onSelect: (data: { selected: boolean; item: Item }) => void
    getDragData: (data: IItemDisplaysProps) => object
    onSelectProgress: () => void
    identificatedItem: boolean
}

const Progress = ({
    list,
    onClick,
    advanced,
}: {
    list: { title: string; percentage: number }[]
    onClick: () => void
    advanced?: boolean
}) => {
    if (list && list.length > 0) {
        return (
            <div className={cn(styles.loading, advanced ? grid.w80 : grid.fullWidth)} onClick={onClick}>
                <div className={styles.progress}>
                    <ProgressBar value={list[0].percentage} strokeWidth={17} />
                </div>
                <div className={cn(grid.row, grid.noWrap, grid.fullWidth, item.ellipsisPure)}>
                    <div className={item.ellipsisPure}>
                        {list[0].title}
                        <span>&nbsp;</span>
                    </div>
                    <div>{Math.round(list[0].percentage)}%</div>
                </div>
            </div>
        )
    } else {
        return null
    }
}

const OrientationIcon = (p_: { orientation: any }) => {
    const orientation = displaysHelpers.getOrientation(p_.orientation)

    if (!orientation) {
        return null
    }

    function getRotate() {
        if (!orientation) return ''

        switch (orientation.id) {
            case 0:
            case 180:
                return 'right'
            case 90:
            case 270:
                return 'left'
            default:
                return ''
        }
    }

    return (
        <Icon
            containerClass={styles.orientationIcon}
            color="white"
            size={20}
            type="horizontal_pos"
            rotate={getRotate()}
            tooltip={{
                title: orientation.fullName,
            }}
        />
    )
}

const ItemDisplays = (p_: IItemDisplaysProps) => {
    const [isHovered, setIsHovered] = useState(false)
    const [dragData, setDragData] = useState<object | null>(null)

    useEffect(() => {
        setDragData(p_.getDragData(p_))
    }, [p_])

    const getDeviceCover = (isSimple: boolean, cover: string | null) => {
        if (isSimple && cover) {
            return {
                backgroundImage: `url(${cover})`,
            }
        }

        if (!isSimple && p_.item.cover[0]) {
            const advancedCover = p_.item.cover[0]

            return {
                backgroundImage: `url(${advancedCover})`,
            }
        }
    }
    const renderGroupStatus = () => {
        if (p_.item && p_.item.descendantStatusCount) {
            return (
                <React.Fragment>
                    {renderStatus('online', translate('groupStatusOnline'))}
                    {renderStatus('offline', translate('groupStatusOffline'))}
                    {renderStatus('error', translate('groupStatusError'))}
                </React.Fragment>
            )
        }
        return null
    }

    const renderStatus = (status: TMarkerStatusType, description: string) => {
        const { descendantStatusCount } = p_.item
        if (descendantStatusCount) {
            const statusInfo = descendantStatusCount.find((item: { status: string }) => item.status === status)

            if (!statusInfo) return null

            return (
                <Status
                    description={description}
                    disable={statusInfo.count <= 0}
                    marker={<Marker mod={'withBorder'} color={helpersMarker.getColorByStatus(status)} />}
                >
                    <span style={{ fontSize: 14 }}>{statusInfo.count}</span>
                </Status>
            )
        }

        return null
    }

    let cover = null
    const active = p_.active || p_.item.__view.selected
    const currentTileView = p_.deviceBlockStyle
    const isSimple = currentTileView === 'simple'
    const isAdvanced = currentTileView === 'advanced'
    const downloads = p_.item.downloadPercentage

    const identificatedItem = p_.identificatedItem

    if (active) {
        cover = p_.item.cover[0]
    }

    let name = helpersCore.getTranslatedIfMain(p_.item.name)
    const location = { ...history.location }

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={function (e) {
                p_.onSelectInfo(e)

                if (helpers.isDblTouchTap(e)) {
                    if (p_.item.type !== 'group') {
                        p_.onDoubleClickObject(p_.item)
                        p_.onDoubleClick(true)

                        e.preventDefault()
                        return
                    }

                    p_.onSelectFolder(p_.item)
                }
            }}
            onContextMenu={(e) => {
                if (helpers.isMobileDevice()) {
                    e.preventDefault()
                }
            }}
        >
            {p_.item.type !== 'group' && (
                <Rectangle width={16} height={9} className={`${styles.displaysWrapper} ${styles[p_.item.status]}`}>
                    <DndDraggable
                        data={dragData}
                        dragEnabled={p_.drag}
                        className={cn(styles.content, styles[p_.item.status], item.cover)}
                        onDragStart={p_.onDragStart}
                        onDragEnd={p_.onDragEnd}
                        style={getDeviceCover(isSimple, cover)}
                    >
                        {!p_.item.activeQ && (
                            <div className={styles.blocked}>
                                <img
                                    className={styles.blockedImg}
                                    src={'images/displays/license_is_blocked.svg'}
                                    alt={'license is blocked'}
                                />
                            </div>
                        )}
                        {p_.multipleSelect && (
                            <div
                                className={styles.checkWrapper}
                                onClick={(e) => e.stopPropagation()}
                                style={p_.item.__view.selected ? { opacity: 1 } : {}}
                            >
                                <Checkbox
                                    mod={'circleDis'}
                                    onClick={(selected: boolean) => {
                                        p_.onSelect({ selected, item: p_.item })
                                    }}
                                    checked={p_.item.__view.selected}
                                />
                            </div>
                        )}
                        {isSimple && (
                            <div className={cn(styles.simpleWrapper, { [styles.blackLayer]: active })}>
                                <div className={cn(textStyles.center, textStyles.white, grid.w90, styles.displayText)}>
                                    {helpers.isAvailable({ key: 'broadcasts', action: 'read' }) &&
                                        !p_.item.hasBroadcast && <div>{translate('noBroadcast')}</div>}
                                    <DotTooltip tooltip={{ html: name }}>
                                        <div
                                            className={cn(
                                                grid.p_micro,
                                                textStyles.normalPlus,
                                                item.ellipsis,
                                                styles.displayText
                                            )}
                                        >
                                            {name}
                                        </div>
                                    </DotTooltip>
                                    {p_.item.registrationCode && (
                                        <div className={styles.registrationCodeSimple}>{p_.item.registrationCode}</div>
                                    )}
                                </div>
                                <div
                                    style={{ bottom: indents.nano, left: indents.nano }}
                                    className={cn(item.abs, grid.w90)}
                                >
                                    <Progress list={downloads} onClick={p_.onSelectProgress} />
                                </div>
                            </div>
                        )}
                        {isAdvanced && (
                            <React.Fragment>
                                {p_.item.registrationCode && (
                                    <div className={styles.registrationCode}>{p_.item.registrationCode}</div>
                                )}
                                <div className={cn(grid.rowCenter, grid.full, { [styles.blackLayer]: active })} />
                            </React.Fragment>
                        )}
                    </DndDraggable>
                    {isAdvanced && (
                        <div className={cn(styles.infoLine, styles[p_.item.status])}>
                            {downloads && downloads.length > 0 ? (
                                <div className={cn(grid.row, grid.normalCenter, grid.fullWidth, grid.noWrap)}>
                                    <OrientationIcon orientation={p_.item.orientation} />
                                    <Progress list={downloads} advanced={isAdvanced} onClick={p_.onSelectProgress} />
                                </div>
                            ) : (
                                <div className={styles.infoLine}>
                                    <div className={cn(grid.col, item.ellipsisWrapper)}>
                                        <div
                                            style={{ minHeight: 24 }}
                                            className={cn(item.ellipsis, grid.fullWidth, styles.displayText)}
                                        >
                                            {name}
                                        </div>
                                        <div className={cn(item.ellipsis, textStyles.up)}>
                                            {p_.item.modelName !== 'undef' ? p_.item.modelName : ''}
                                        </div>
                                    </div>
                                    {!isHovered && identificatedItem ? (
                                        <Fragment>
                                            <div className={styles.pseudoIcon}></div>
                                            <div className={styles.infoIcon}>
                                                <Icon type="identification" size={18} color={'white'} />
                                            </div>
                                        </Fragment>
                                    ) : null}
                                    {!isHovered && !identificatedItem && p_.item.status === 'online_display_off' ? (
                                        <div className={cn(grid.pb_micro)}>{translate('displayOff')}</div>
                                    ) : null}
                                    <div className={cn(grid.col, grid.colCenter)}>
                                        <Animation>
                                            {isHovered ? (
                                                <div
                                                    className={cn(
                                                        p_.item.status === 'online_display_off' ? grid.col : grid.row,
                                                        p_.item.status === 'online_display_off' && grid.normalCenter,
                                                        grid.noWrap,
                                                        grid.justify
                                                    )}
                                                >
                                                    {p_.item.status === 'online_display_off' && (
                                                        <div className={cn(grid.pb_micro)}>
                                                            {translate('displayOff')}
                                                        </div>
                                                    )}
                                                    <div className={cn(grid.row, grid.justify, grid.mr_mini)}>
                                                        <div />
                                                        <div className={cn(grid.row, grid.normalCenter, grid.noWrap)}>
                                                            {helpers.isAvailable({
                                                                key: 'broadcasts',
                                                                action: 'read',
                                                            }) &&
                                                                !p_.item.hasBroadcast && (
                                                                    <Icon
                                                                        color={'white'}
                                                                        size={20}
                                                                        type={'no_display'}
                                                                        containerClass={cn(grid.mr_mini)}
                                                                        tooltip={{
                                                                            title: translate('noBroadcast'),
                                                                        }}
                                                                    />
                                                                )}
                                                            <OrientationIcon orientation={p_.item.orientation} />
                                                            {identificatedItem ? (
                                                                <Icon type="identification" size={18} color={'white'} />
                                                            ) : null}
                                                            {displaysHelpers.availablePlatforms(p_.item.platform) && (
                                                                <Tippy
                                                                    title={p_.item.platform}
                                                                    className={cn(grid.ml_mini)}
                                                                >
                                                                    <img
                                                                        alt={`${p_.item.platform}_logo`}
                                                                        width="100%"
                                                                        height="auto"
                                                                        src={`images/displays/platforms/small/${displaysHelpers.getPlatformName(
                                                                            p_.item.platform
                                                                        )}.png`}
                                                                    />
                                                                </Tippy>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </Animation>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </Rectangle>
            )}
            {p_.item.type === 'group' && (
                <Rectangle width={16} height={9} className={styles.groupWrapper}>
                    <DndDraggable
                        className={`${styles.group} ${styles[`${p_.item.status}Group`]}`}
                        data={dragData}
                        dragEnabled={p_.drag}
                        style={cover ? { backgroundImage: `url(${cover})` } : {}}
                        onDragStart={p_.onDragStart}
                        onDragEnd={p_.onDragEnd}
                    >
                        <div className={cn(styles.content, styles[`${p_.item.status}Group`])}>
                            {p_.multipleSelect && location.pathname !== '/displays/updateDevices' && (
                                <div
                                    className={styles.checkWrapper}
                                    onClick={(e) => e.stopPropagation()}
                                    style={p_.item.__view.selected ? { opacity: 1 } : {}}
                                >
                                    <Checkbox
                                        mod={'circleDis'}
                                        onClick={(selected: boolean) => {
                                            p_.onSelect({ selected, item: p_.item })
                                        }}
                                        checked={p_.item.__view.selected}
                                    />
                                </div>
                            )}
                            <div className={cn(grid.colCenter, grid.full, { [styles.blackLayer]: active })}>
                                <div className={cn(textStyles.center, textStyles.white, grid.w90, styles.displayText)}>
                                    {helpers.isAvailable({ key: 'broadcasts', action: 'read' }) &&
                                        !p_.item.hasBroadcast && <div>{translate('noBroadcast')}</div>}
                                    <DotTooltip tooltip={{ html: name }}>
                                        <div
                                            className={cn(
                                                grid.p_micro,
                                                textStyles.normalPlus,
                                                item.ellipsis,
                                                styles.displayText
                                            )}
                                        >
                                            {name}
                                        </div>
                                    </DotTooltip>
                                </div>
                                <div className={styles.statusPanel}>{renderGroupStatus()}</div>
                            </div>
                        </div>
                    </DndDraggable>
                    <div className={styles.groupBackWrapper}>
                        <div
                            className={`${styles.groupBack} ${styles.groupBack_second} ${
                                styles[`${p_.item.status}Group`]
                            }`}
                        />
                    </div>
                    <div className={styles.groupBackWrapper}>
                        <div className={`${styles.groupBack} ${styles.groupBack_first}`} />
                    </div>
                </Rectangle>
            )}
        </div>
    )
}

export default ItemDisplays
