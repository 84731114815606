import React from 'react'

import EditText from 'blocks.simple/editText/editText'
import EditableText from 'blocks.simple/editableText/editableText'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import Button from 'blocks.simple/button/button'
import Icon from 'blocks.simple/icon/icon'

import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import helpersBroadcasts from 'core/helpers/_broadcasts'
import { editor } from '../editor.local.methods'

import { cn } from 'ethcss'
import styles from './editor__broadcastSettings.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { getURLSearchParamsByLocation } from 'features/routes/utils'

class EditorBroadcastSettings extends React.PureComponent {
    state = {
        title: this.props.title,
        titleError: this.props.titleError,
        folderId: this.props.folderId,
        folders: [],
    }
    componentDidUpdate(prevProps) {
        const p_ = this.props

        if (prevProps.title === p_.title) {
            return
        }

        this.setState({
            title: p_.title,
            titleError: p_.titleError,
            folderId: p_.folderId,
        })
    }
    componentDidMount() {
        const p_ = this.props
        const query = getURLSearchParamsByLocation(p_.location)
        const broadcastId = query.broadcastId
        let method = 'getAvailableToCreateParentBroadcastFolders'

        const params = {
            nestedQ: false,
        }

        if (broadcastId) {
            method = 'getAvailableParentBroadcastFolders'
            params.broadcastId = broadcastId
        }

        api.send(method, params).then((foldersData) => {
            const folders = foldersData.map((folder) => helpersBroadcasts.getFolder(folder))
            this.setState({ folders })
        })
    }

    // Изменение заголовка трансляции
    changeTitle = (title) => {
        this.setState({
            title,
            titleError: null,
        })
    }

    render() {
        const p_ = this.props
        const s_ = this.state

        return (
            <div className={styles.wrapper}>
                <div />
                <div>
                    <div className={cn(grid.w100, grid.col, grid.normalCenter)}>
                        <div className={cn(styles.inputWrapper)}>
                            <EditText
                                className={cn(styles.input)}
                                label={translate('name')}
                                value={s_.title}
                                error={s_.titleError}
                                onChange={this.changeTitle}
                                onEndTyping={function (title) {
                                    editor.changeTitle(title)
                                    editor.validateTitle()
                                }}
                            />
                        </div>
                        <div className={cn(styles.inputWrapper)}>
                            <Dropdown
                                searchable
                                centered
                                type="tree"
                                label={translate('parentFolder')}
                                value={s_.folderId}
                                options={s_.folders}
                                placeholder={translate('chooseFolder')}
                                onChange={(selected) => {
                                    const folderId = selected.id
                                    editor.state.broadcast.folderId = folderId
                                    this.setState({ folderId })
                                }}
                            />
                        </div>
                        <div className={cn(styles.orientation)}>
                            <div className={item.ellipsis}>
                                <span className={cn(styles.orientationTitle)}>
                                    {translate('orientation')}

                                    {p_.resolutionWidth !== p_.resolutionHeight && <span>: </span>}
                                </span>
                                {p_.resolutionWidth > p_.resolutionHeight && <b>{translate('orientationH')}</b>}
                                {p_.resolutionWidth < p_.resolutionHeight && <b>{translate('orientationV')}</b>}
                            </div>
                        </div>
                        <div className={cn(styles.inputWrapper)}>
                            <EditableText
                                id={'resolutionWidth'}
                                mod={'withBorder'}
                                className={cn(styles.input)}
                                type={'number'}
                                hideIcon={true}
                                text={p_.resolutionWidth}
                                label={translate('width')}
                                onEndTyping={(width) => {
                                    editor.changeResolutionSize(width, 'width')
                                }}
                            />
                        </div>
                        <div className={grid.colCenter}>
                            <Button className={cn(styles.switchArrows)} onClick={editor.switchResolutionSize}>
                                <Icon type={'switchArrows'} size={24} />
                            </Button>
                        </div>
                        <div className={cn(styles.inputWrapper)}>
                            <EditableText
                                id={'resolutionHeight'}
                                mod={'withBorder'}
                                className={cn(styles.input)}
                                type={'number'}
                                hideIcon={true}
                                text={p_.resolutionHeight}
                                label={translate('height')}
                                onEndTyping={(height) => {
                                    editor.changeResolutionSize(height, 'height')
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.buttons}>
                        <Button
                            mod={'withBorder'}
                            rounded="full_md"
                            indentSize="mini"
                            onClick={function () {
                                editor.hideSettings()
                            }}
                            className={cn(grid.mr_md)}
                        >
                            {translate('cancel')}
                        </Button>
                        <Button
                            rounded="full_md"
                            indentSize="mini"
                            mod="fill"
                            onClick={function () {
                                editor.hideSettings()
                            }}
                        >
                            {translate('save')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditorBroadcastSettings
