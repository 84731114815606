import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import styles from './catalog__template_tasks.jcss'
import { cn } from 'ethcss'
import { ItemData } from '../../../../core/models/Template'

const requestTaskInfo = (digitalSignageUpdateTaskId: number, digitalSignageId: number) => {
    api.send('requestUpdateTaskInfo', {
        digitalSignageUpdateTaskId,
        digitalSignageId,
    }).then((res) => console.log(res))
}

const GetUpdateStatus = (p_: { digitalSignageUpdateTaskId: number; digitalSignageId: number }) => {
    const [dataInfo, setDataInfo] = useState<any>(null)

    useEffect(() => {
        const { digitalSignageUpdateTaskId, digitalSignageId } = p_
        requestTaskInfo(digitalSignageUpdateTaskId, digitalSignageId)
        const listenersId: string[] = []

        const interval = setInterval(() => {
            requestTaskInfo(digitalSignageUpdateTaskId, digitalSignageId)
        }, 5000)

        api.addObserver(
            'updateTaskInfoSent',
            (data: { digitalSignageUpdateTaskId: number; downloadedPercent: number }) => {
                if (!data) return

                if (data.digitalSignageUpdateTaskId === digitalSignageUpdateTaskId) {
                    setDataInfo(data.downloadedPercent)
                }
            },
            listenersId
        )

        return () => {
            clearInterval(interval)
            listenersId.forEach((id) => api.removeObserver(id))
        }
    }, [])

    return (
        <>
            {dataInfo && dataInfo < 100
                ? `${translate('task_download_in_progress')} ${dataInfo} %`
                : translate('task_in_progress')}
        </>
    )
}

export default {
    getMethod: 'getDsUpdateTasks',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noTasks'),
    emptyIcon: 'not_allow',
    settingsPrefixes: 'tasks',
    listWrapperClassName: styles.list,
    fields: () => [
        { id: 'digitalSignage', name: translate('name') },
        { id: 'date', name: translate('date_update') },
        { id: 'status', name: translate('status') },
        { id: 'message', name: translate('message') },
    ],
    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    isEqual: (firstItem: ItemData, secondItem: ItemData) => firstItem.id === secondItem.id,
    onCreateListeners: [(cb: any, clearList: string[]) => api.addObserver('dsUpdateTaskCreated', cb, clearList)],
    onUpdateListeners: [(cb: any, clearList: string[]) => api.addObserver('dsUpdateTaskUpdated', cb, clearList)],
    onDeleteListeners: [(cb: any, clearList: string[]) => api.addObserver('dsUpdateTaskDeleted', cb, clearList)],
    isFolder: () => {},
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    tableWrapper: styles.tableWrapper,
    list: {
        digitalSignage: ({ digitalSignage }: { digitalSignage: { name: string } }) => digitalSignage.name,
        date: ({ date }: { date: string }) => moment(date).format('YYYY-MM-DD HH:mm:ss'),
        status: (p_: { id: number; digitalSignageId: number; status: string }) => {
            if (p_.status === 'in_progress')
                return <GetUpdateStatus digitalSignageUpdateTaskId={p_.id} digitalSignageId={p_.digitalSignageId} />

            return <span className={cn(styles.status, styles[p_.status])}>{translate(`task_${p_.status}`)}</span>
        },
        message: ({ message }: { message: string }) => message,
    },
}
