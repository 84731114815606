import React from 'react'
import Content from 'blocks.app/content/content'
import CustomList from 'blocks.app/customList/customList'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

class InstancesCatalogCombo extends React.Component {
    state = {
        selectedItems: [],
    }

    render = () => {
        const s_ = this.state

        return (
            <Content title={'comboCatalog'}>
                <CustomList
                    type={'combo'}
                    onSelect={(selectedItems) => this.setState({ selectedItems })}
                    selectedItems={s_.selectedItems}
                    additionalData={{}}
                />
            </Content>
        )
    }
}

const exportInstancesCatalogCombo = checkAvailableModule('instancesCatalog/combo') ? InstancesCatalogCombo : null

export default exportInstancesCatalogCombo
