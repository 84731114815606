import { api } from 'core/api/ConnectionManager'
import { IDownloadRaw } from 'core/models/Downloads'
import { useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { mediaJS } from 'theme'
import { IDownloadModalPosition } from './DownloadService-types'
import { downloadsActions } from './DownloadService.state'

export const useFetchDownloads = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        api.send<object, IDownloadRaw[]>('getDownloads', {}).then((downloads: IDownloadRaw[]) =>
            dispatch(downloadsActions.addDownloads(downloads))
        )
    }, [])
}

export const useThrottleDownloadsStoreUpdate = () => {
    const UPDATE_STORE_THROTTLE_MS = 5000
    const dispatch = useDispatch()

    useEffect(() => {
        const updateStoreInterval: NodeJS.Timeout = setInterval(() => {
            dispatch(downloadsActions.refreshLoadings())
        }, UPDATE_STORE_THROTTLE_MS)

        return () => {
            clearInterval(updateStoreInterval)
        }
    }, [])
}

export const useListenDownloadsUpdate = () => {
    const dispatch = useDispatch()
    const downloads: any = useSelector<RootStateOrAny>((state) => state.downloads)

    const setDelayDownloadState = (data: any) => {
        const taskIsExist = downloads.delayDownloadTasks.some((task: any) => task.exportId === data.detail.exportId)

        if (!taskIsExist) return

        const progressList = downloads.delayDownloadsProgressList

        if (data.type === 'in_progress') {
            const filteredList = progressList.filter((el: any) => el.name !== data.detail.exportId)
            dispatch(
                downloadsActions.setDelayDownloadsProgressList([
                    ...filteredList,
                    { name: data.detail.broadcastTitle, percent: data.detail.percent, exportId: data.detail.exportId },
                ])
            )
        }
        if (data.type === 'success') {
            const url = data.detail.url

            const a = document.createElement('a')
            a.href = url
            a.download = 'true'
            a.click()

            dispatch(
                downloadsActions.setDelayDownloadsProgressList(
                    progressList.filter((task: any) => task.exportId !== data.detail.exportId)
                )
            )
            dispatch(downloadsActions.deleteDelayDownloadTask(data.detail.exportId))
        }
    }

    useEffect(() => {
        const listenersId: string[] = []

        const onDownloadStarted = (download: IDownloadRaw) => dispatch(downloadsActions.addDownload(download))
        const onDownloadUpdated = (download: IDownloadRaw) => dispatch(downloadsActions.updateDownload(download))

        api.addObserver('sendBroadcastExportState', (data) => setDelayDownloadState(data), listenersId)
        api.addObserver('downloadStarted', onDownloadStarted, listenersId)
        api.addObserver('downloadUpdated', onDownloadUpdated, listenersId)
        return () => {
            listenersId.forEach((id) => api.removeObserver(id))
        }
    }, [downloads.delayDownloadTasks])
}

export const useChangeModalPosition = () => {
    const [modalPositon, setModalPosition] = useState<IDownloadModalPosition>({
        left: mediaJS.tabMini.matches ? 0.05 : 0.3,
        width: mediaJS.tabMini.matches ? 0.9 : 0.4,
        top: 0.15,
        height: 0.7,
    })

    const changeModalPosition = (position: IDownloadModalPosition) => {
        setModalPosition(position)
    }

    return {
        modalPositon,
        changeModalPosition,
    }
}
