import { addStyles } from 'ethcss'
import { IStyles, grid, text } from 'theme'

// TODO: Удалить когда будет редизайн страницы устройства, и когда компонент будет в новых цветах
// заменить на цвета нового дизайна (text, item модули в theme)

import { mediaCSS, textSizes } from 'blocks.app/config'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'

//

const widthQuery = '@media (min-width: 960px) and (max-width: 1176px)'

export const advancedSearchStyles: IStyles = {
    container: {
        marginTop: '20px',
        ...grid.w100,
        '& > *': {
            userSelect: 'none',
        },
    },
    title: {
        width: '40%',
    },
    parameterWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
    },
    actionsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '56%',
        [mediaCSS.mob]: {
            width: '70%',
        },
    },
    action: {
        display: 'flex',
        width: '30%',
        [widthQuery]: {
            flexWrap: 'wrap',
        },
    },
    block: {
        display: 'block !important',
    },
    actionDesc: {
        width: '68%',
    },
    parameterTitle: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
        ...grid.w100,
        ...grid.mb_md,
    },
    description: {
        ...grid.row,
        ...grid.normalCenter,
    },
    text: {
        fontSize: textSizes.mini,
        lineHeight: 1,
    },
    textMob: {
        [mediaCSS.mob]: {
            fontSize: textSizes.mini,
        },
    },
    parameterInput: {
        marginLeft: '4px',
        width: '24px',

        '& input': {
            padding: '3px 6px !important',
            textAlign: 'center',
            width: '24px',
        },
        [widthQuery]: {
            marginLeft: '0px',
            marginTop: '6px',
        },
    },
    titleWithIcon: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.noWrap,
    },
    icon: {
        ...grid.mr_mini,
    },
    leftColumn: {
        ...grid.w70,
    },
    rightColumn: {
        ...grid.col,
    },
    radioWrapper: {
        ...grid.row,
        alignItems: 'center',
        marginBottom: '10px',
    },
    radioTitle: {
        marginLeft: '8px',
    },
    buttonsWrapper: {
        ...grid.row,
        ...grid.w100,
        ...grid.endJustify,
        ...grid.mt_md,
    },
    button: {
        ...grid.rowCenter,
        ...grid.w40,
        ...grid.ml_mini,
    },
}

addStyles(advancedSearchStyles)
