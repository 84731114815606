import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import { textSizes } from 'theme/media'
import { grid, IStyles, mediaCSS, colors } from '../../../theme'
import { inspect } from 'util'

const dashboardNetworkStyles: IStyles = {
    wrapper: {
        ...grid.col,
        ...grid.space,
        ...grid.justify,
        ...item.rel,
        height: '100%',
    },
    memoryInfo: {
        ...grid.rowCenter,
        ...grid.noWrap,
        ...grid.normalCenter,
        paddingTop: '46px',
        [mediaCSS.tabMini]: {
            ...grid.center,
        },
        '& svg': {
            width: '100%',
        },
    },
    title: {
        textTransform: 'uppercase',
    },
    chart: {
        ...grid.mr_md,
        '& .donutchart-arcs-path': {
            stroke: 'transparent!important',
        },
        '& .donutchart-innertext-label': {
            fontWeight: 'bold',
        },
        '& .selected.donutchart-arcs-path': {
            opacity: '1!important',
        },
        width: '40%',
    },
    rightBlock: {
        width: '60%',
    },
    mark: {
        display: 'inline-block',
        width: 15,
        height: 15,
        ...grid.mr_micro,
    },
    legend: {
        ...grid.mb_md,
        wordBreak: 'break-all',
        lineHeight: 1.5,
    },
    info: {
        fontSize: textSizes.mini,
        color: colors.darkCloud,
    },
    link: {
        ...grid.mt_md,
        color: colors.black,
        textDecoration: 'underline',
        position: 'absolute',
        right: '-6px',
        bottom: '-5px',
        '&>span': {
            ...item.pointer,
            fontSize: '12px',
        },
    },
    reportContent: {
        ...grid.row,
        ...grid.mt_md,
        ...grid.mb_md,
    },
    zIndex: {
        [mediaCSS.tabMini]: {
            zIndex: 99,
        },
    },
    inputs: {
        ...grid.w45,
        ...grid.mb_md,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    inputsWrapper: {
        ...grid.row,
        ...grid.justify,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
}

addStyles(dashboardNetworkStyles)
export default dashboardNetworkStyles
