import React, { useEffect, useRef, useState } from 'react'
import { appActions } from '../../blocks.app/app/app.state'
import { useDispatch } from 'react-redux'

type WindowStyles = {
    width?: string | number
    height?: string | number
    left?: string | number
    right?: string | number
    top?: string | number
    borderRadius?: string | number
}

export const MonitorWindowMethods = () => {
    const [windowStyles, setWindowStyles] = useState<WindowStyles | {}>({})
    const [minimize, setMinimize] = useState<boolean>(false)
    const [maximize, setMaximize] = useState<boolean>(false)
    const [dragStart, setDragStart] = useState<boolean>(false)
    const [styleBuffer, setStyleBuffer] = useState<WindowStyles | null>(null)
    const [downloadLog, setDownloadLog] = useState<boolean>(false)
    const [scrollToBottom, setScrollToBottom] = useState<boolean>(false)
    const [scrollTo, setScrollTo] = useState<boolean>(false)
    const monitorRef = useRef<HTMLDivElement>(null)
    const resizerLeftRef = useRef<HTMLDivElement>(null)
    const resizerRightRef = useRef<HTMLDivElement>(null)
    const resizerBottomRef = useRef<HTMLDivElement>(null)
    const resizerTopRef = useRef<HTMLDivElement>(null)
    const contentRef = useRef<any>(null)
    const dispatch = useDispatch()

    const toggleAutoscroll = () => {
        setScrollToBottom(!scrollToBottom)
    }
    const onClose = () => {
        dispatch(appActions.closeMonitorWindow())
    }
    const onMinimize = () => {
        setMinimize(!minimize)
    }
    const onMaximize = () => {
        setMaximize(!maximize)
    }
    const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
    }
    const getCoords = (elem: HTMLDivElement | null) => {
        const box = elem!.getBoundingClientRect()
        return {
            top: box.top + window.pageYOffset,
            left: box.left + window.pageXOffset,
        }
    }
    const actionScroll = () => {
        setScrollTo(true)
    }
    const moveAt = (element: HTMLDivElement | null, shiftX: number, shiftY: number, e: React.MouseEvent) => {
        const windowWidth = window.innerWidth
        const windowHeight = window.innerHeight
        const topPosition = e.pageY - shiftY
        const leftPosition = e.pageX - shiftX

        setWindowStyles({
            ...windowStyles,
            top:
                topPosition < 0
                    ? 0 + 'px'
                    : topPosition > windowHeight - 64
                    ? windowHeight - 64 + 'px'
                    : topPosition + 'px',
            left:
                leftPosition > windowWidth - 64
                    ? windowWidth - 64 + 'px'
                    : leftPosition + monitorRef!.current!.offsetWidth - 64 < 0
                    ? -monitorRef!.current!.offsetWidth + 64 + 'px'
                    : leftPosition + 'px',
        })
    }
    const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        if (
            e.target === resizerRightRef.current ||
            e.target === resizerLeftRef.current ||
            e.target === resizerTopRef.current ||
            e.target === resizerBottomRef.current
        )
            return

        monitorRef.current!.draggable = true
        setDragStart(true)

        const monitorElement = monitorRef.current
        const coords = getCoords(monitorElement)
        const shiftX = e.pageX - coords.left
        const shiftY = e.pageY - coords.top

        moveAt(monitorElement, shiftX, shiftY, e)

        document.onmousemove = function (e: any) {
            moveAt(monitorElement, shiftX, shiftY, e)
        }
        monitorElement!.onmouseup = function () {
            document.onmousemove = null
            monitorElement!.onmouseup = null
            monitorRef!.current!.draggable = false
            setDragStart(false)
            const windowWidth = window.innerWidth
            const right = windowWidth - (monitorElement!.offsetLeft + monitorElement!.offsetWidth)

            setWindowStyles((prevState) => {
                return {
                    ...prevState,
                    right: right + 'px',
                }
            })
        }
    }

    useEffect(() => {
        if (scrollToBottom && scrollTo) {
            contentRef.current.scrollTo(0, contentRef.current.scrollHeight)
            setScrollTo(false)
        }
    }, [scrollTo, scrollToBottom])
    useEffect(() => {
        if (minimize) {
            setStyleBuffer(windowStyles)
            setWindowStyles({
                ...windowStyles,
                width: '400px',
                height: '64px',
            })

            return
        }

        setWindowStyles(styleBuffer ? styleBuffer : getInitialStyles())
        setStyleBuffer(null)
    }, [minimize])

    useEffect(() => {
        if (maximize) {
            setStyleBuffer(windowStyles)
            setWindowStyles({
                width: '100%',
                height: '100%',
                right: 0,
                top: 0,
                borderRadius: 0,
            })

            return
        }

        setWindowStyles(styleBuffer ? styleBuffer : getInitialStyles())
        setStyleBuffer(null)
    }, [maximize])

    function getInitialStyles() {
        const screenWidth = window.innerWidth
        const screenHeight = window.innerHeight
        const monitorWidth = screenWidth / 2.4
        const monitorHeight = screenHeight / 2.4
        const right = screenWidth / 2 - monitorWidth / 2
        const top = screenHeight / 2 - monitorHeight / 2
        const mediaQuery = window.matchMedia('(max-width: 500px)').matches
        return {
            width: mediaQuery ? '90%' : monitorWidth + 'px',
            height: monitorHeight + 'px',
            left: mediaQuery ? '50%' : right + 'px',
            top: top + 'px',
            borderRadius: 10,
            transform: mediaQuery ? 'translateX(-50%)' : 'none',
        }
    }

    useEffect(() => {
        const resizeableEle: any = monitorRef.current
        const styles = window.getComputedStyle(resizeableEle)
        let width = parseInt(styles.width, 10)
        let height = parseInt(styles.height, 10)
        let x = 0
        let y = 0

        const onMouseMoveRightResize = (event: MouseEvent) => {
            const dx = event.clientX - x
            x = event.clientX
            width = width + dx
            setWindowStyles((prevState) => {
                return {
                    ...prevState,
                    width: width < 300 ? 300 + 'px' : width + 'px',
                }
            })
        }
        const onMouseUpRightResize = () => {
            document.removeEventListener('mousemove', onMouseMoveRightResize)
        }
        const onMouseDownRightResize = (event: MouseEvent) => {
            x = event.clientX
            setWindowStyles((prevState) => {
                return {
                    ...prevState,
                    left: styles.left,
                    right: null,
                }
            })
            document.addEventListener('mousemove', onMouseMoveRightResize)
            document.addEventListener('mouseup', onMouseUpRightResize)
        }
        const onMouseMoveTopResize = (event: MouseEvent) => {
            const dy = event.clientY - y
            height = height - dy
            y = event.clientY
            setWindowStyles((prevState) => {
                return {
                    ...prevState,
                    height: height < 64 ? 64 + 'px' : height + 'px',
                }
            })
        }
        const onMouseUpTopResize = () => {
            document.removeEventListener('mousemove', onMouseMoveTopResize)
        }
        const onMouseDownTopResize = (event: MouseEvent) => {
            y = event.clientY
            const styles = window.getComputedStyle(resizeableEle)
            setWindowStyles((prevState) => {
                return {
                    ...prevState,
                    bottom: styles.bottom,
                    top: null,
                }
            })
            document.addEventListener('mousemove', onMouseMoveTopResize)
            document.addEventListener('mouseup', onMouseUpTopResize)
        }
        const onMouseMoveBottomResize = (event: MouseEvent) => {
            const dy = event.clientY - y
            height = height + dy
            y = event.clientY
            setWindowStyles((prevState) => {
                return {
                    ...prevState,
                    height: height < 64 ? 64 + 'px' : height + 'px',
                }
            })
        }
        const onMouseUpBottomResize = () => {
            document.removeEventListener('mousemove', onMouseMoveBottomResize)
        }
        const onMouseDownBottomResize = (event: MouseEvent) => {
            y = event.clientY
            const styles = window.getComputedStyle(resizeableEle)
            setWindowStyles((prevState) => {
                return {
                    ...prevState,
                    top: styles.top,
                    bottom: null,
                }
            })
            document.addEventListener('mousemove', onMouseMoveBottomResize)
            document.addEventListener('mouseup', onMouseUpBottomResize)
        }
        const onMouseMoveLeftResize = (event: MouseEvent) => {
            const dx = event.clientX - x
            x = event.clientX
            width = width - dx
            setWindowStyles((prevState) => {
                return {
                    ...prevState,
                    width: width < 300 ? 300 + 'px' : width + 'px',
                }
            })
        }
        const onMouseUpLeftResize = () => {
            document.removeEventListener('mousemove', onMouseMoveLeftResize)
        }
        const onMouseDownLeftResize = (event: MouseEvent) => {
            x = event.clientX
            setWindowStyles((prevState) => {
                return {
                    ...prevState,
                    right: styles.right,
                    left: null,
                }
            })
            document.addEventListener('mousemove', onMouseMoveLeftResize)
            document.addEventListener('mouseup', onMouseUpLeftResize)
        }

        const resizerRight = resizerRightRef.current
        resizerRight!.addEventListener('mousedown', onMouseDownRightResize)
        const resizerTop = resizerTopRef.current
        resizerTop!.addEventListener('mousedown', onMouseDownTopResize)
        const resizerBottom = resizerBottomRef.current
        resizerBottom!.addEventListener('mousedown', onMouseDownBottomResize)
        const resizerLeft = resizerLeftRef.current
        resizerLeft!.addEventListener('mousedown', onMouseDownLeftResize)

        return () => {
            resizerRight!.removeEventListener('mousedown', onMouseDownRightResize)
            resizerTop!.removeEventListener('mousedown', onMouseDownTopResize)
            resizerBottom!.removeEventListener('mousedown', onMouseDownBottomResize)
            resizerLeft!.removeEventListener('mousedown', onMouseDownLeftResize)
        }
    }, [windowStyles, dragStart])
    useEffect(() => {
        setTimeout(() => {
            setDownloadLog(false)
        }, 1000)
    }, [downloadLog])

    return {
        onClose,
        onMinimize,
        onMaximize,
        windowStyles,
        minimize,
        onDragStart,
        onMouseDown,
        monitorRef,
        resizerLeftRef,
        resizerRightRef,
        resizerTopRef,
        resizerBottomRef,
        downloadLog,
        setDownloadLog,
        contentRef,
        actionScroll,
        scrollToBottom,
        toggleAutoscroll,
    }
}
