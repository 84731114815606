import { colors } from 'theme'

export const defaultThemeStyles = {
    'contrast-track': colors.blackLight,
    'contrast-rail': colors.white,
    'colored-track': colors.darkBlue,
    'colored-rail': colors.white,
    'monochrome-track': colors.greyLight,
    'monochrome-rail': colors.grey,

    handler: colors.darkBlue,
    handlerInactive: colors.darkCloud,
    counter: colors.darkBlue,
    icon: colors.blackLight,
}
