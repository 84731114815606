import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { Icon } from 'molecules/Icon'

import { uniqid } from 'core/utils'
import helpers from 'core/helpers'
import { useEventListener, useOnMount, useOnUnmount } from 'core/hooks'
import { useTransition, animated } from 'react-spring'

import { cn } from 'ethcss'
import styles from './styles'
import { layers, grid } from 'theme'

const root = document.getElementById('root')

const iconDefaultProps = {
    ...Icon.defaultProps,
    type: 'close',
    color: 'darkBlue',
}

const Modal = (p_) => {
    const [modalId] = useState(uniqid())
    const [modalContainer] = useState(document.createElement('div'))

    const closeByEscHandler = useCallback(
        (event) => {
            if (!p_.closeOnEsc) {
                return
            }

            event = event || window.event

            if (event.keyCode === 27) {
                if (p_.open) {
                    event.preventDefault()
                    p_.onClose()
                }
            }
        },
        [p_]
    )

    useEventListener('keydown', closeByEscHandler)

    useOnMount(() => {
        root.appendChild(modalContainer)
    })

    useOnUnmount(() => {
        root.removeChild(modalContainer)
        helpers.disableWindowScroll(false, modalId)
    })

    useEffect(() => {
        helpers.disableWindowScroll(p_.open, modalId)
    }, [p_.open, modalId])

    const onClose = () => {
        p_.onClose()
    }

    const renderModal = () => {
        const iconMergedProps = {
            ...iconDefaultProps,
            ...p_.icon,
        }

        return (
            <React.Fragment>
                {!p_.hideCloseIcon && (
                    <div className={styles.icon}>
                        <Icon {...iconMergedProps} onClick={onClose} />
                    </div>
                )}

                {p_.children}
            </React.Fragment>
        )
    }

    const modalTransition = useTransition(p_.open, null, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    })
    const zIndex = p_.zIndex + layers.modal

    const Content = (
        <React.Fragment>
            {modalTransition.map(({ item, key, props }) => {
                return item ? (
                    <animated.div
                        key={key}
                        style={{ ...props, zIndex, ...p_.style }}
                        className={cn(p_.className, item[p_.color], grid[p_.padding])}
                        id={p_.id}
                    >
                        {renderModal()}
                    </animated.div>
                ) : null
            })}
        </React.Fragment>
    )

    return ReactDOM.createPortal(Content, modalContainer)
}

Modal.propTypes = {
    id: PropTypes.string,
    open: PropTypes.bool.isRequired,
    hideCloseIcon: PropTypes.bool,
    closeOnEsc: PropTypes.bool,
    onClose: PropTypes.func,
    color: PropTypes.string,
    padding: PropTypes.string,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    style: PropTypes.object,
    zIndex: PropTypes.number,
    icon: PropTypes.shape({ ...Icon.propTypes }),
    children: PropTypes.node,
}

Modal.defaultProps = {
    hideCloseIcon: false,
    color: 'white',
    padding: 'p_md',
    closeOnEsc: true,
    className: styles.wrapper,
    onClose: () => {},
    zIndex: 3000,
    icon: iconDefaultProps,
    isDisableBackground: false,
}

export default Modal
