import React, { FC } from 'react'
import { IAppSubMenuComponent } from './AppSubMenu-types'
import helpers from 'core/helpers'
import { styles } from './AppSubMenu-styles'
import { routes } from 'features/routes'
import { AppSubMenuItem } from './AppSubMenuItem'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { IMenuItem } from 'core/models/Menu'

export const AppSubMenu: FC<IAppSubMenuComponent> = ({
    pathname,
    query,
    actions,
    active,
    routeItem,
    minimize,
    className,
    item,
    isHorizontal,
}) => {
    const disabledActions = (key: string) => {
        switch (key) {
            case 'addGroup':
            case 'addDisplay':
                return pathname !== routes.displays.path || !query.parentId
            case 'addAdvancedBroadcast':
            case 'addBroadcastFolder':
            case 'createFolder':
            case 'upload':
                return !query.folderId
            case 'uploadBroadcast':
                return pathname !== routes.broadcasts.path
            default:
                return false
        }
    }

    const getLabel = (textKey: string) => {
        switch (textKey) {
            case 'addFillersAdBlock':
            case 'addAdvertisingCampaign':
            case 'addAdvertisingAds':
                return translate('create')
            case 'advertisingBlocks':
                return translate('view')
            default:
                return translate(textKey)
        }
    }

    const isAvailablePermissions = (childName: string, childRouteItem: any) => {
        if (!(childRouteItem && helpers.isAvailable(childRouteItem.permissions))) return false

        switch (childName) {
            case 'addAdvancedBroadcast':
            case 'addVideowall':
                return (
                    helpers.isAvailable({ key: 'files', action: 'read' }) &&
                    helpers.isAvailable({ key: 'broadcast', action: 'create' }) &&
                    helpers.isAvailable({ key: 'digitalSignage', action: 'read' })
                )
            case 'addAdvertisingCampaign':
                return (
                    helpers.isAvailable({ key: 'files', action: 'read' }) &&
                    helpers.isAvailable({ key: 'digitalSignage', action: 'read' })
                )
            case 'addAdvertisingAds':
                return (
                    helpers.isAvailable({ key: 'files', action: 'read' }) &&
                    helpers.isAvailable({ key: 'digitalSignage', action: 'read' })
                )
            case 'updateDevices':
                return helpers.isAvailableAction(null, {
                    key: 'digitalSignage',
                    action: 'update',
                    subaction: 'manageTasksForUpdate',
                })
            default:
                return !childName.includes('videoEditor') || helpers.isAvailable({ key: 'files', action: 'create' })
        }
    }

    const renderChildItem = (child: IMenuItem, childIndex: number) => {
        const childRouteItem = routes[child.action]
        const label = getLabel(child.name)

        if (!isAvailablePermissions(child.name, childRouteItem)) return null

        const disabled = actions[child.action] ? disabledActions(child.action) : false
        const action = actions[child.action]
        const isClickable = childRouteItem.path !== pathname
        const routePath = `/${childRouteItem.path}`

        return (
            <AppSubMenuItem
                action={action}
                key={`menu__subItem_${childIndex}`}
                isClickable={isClickable}
                childRouteItem={childRouteItem}
                minimize={minimize}
                pathname={pathname}
                routePath={routePath}
                disabled={disabled}
                label={label}
                query={query}
                isHorizontal={isHorizontal}
                contentClassName={isHorizontal ? styles.subMenuItem__content_x : null}
            />
        )
    }

    const renderTitleItem = () => {
        if (!minimize || !routeItem) return null

        const isClickable = routeItem.path !== pathname || Object.keys(query).length > 0
        const isActive = routeItem.path === active

        return (
            <AppSubMenuItem
                isClickable={isClickable}
                routePath={`/${routeItem.path}`}
                minimize={minimize}
                pathname={pathname}
                label={translate(item.name)}
                query={query}
                mods={['noUnderline']}
                contentClassName={cn(styles.subMenuItem__content_type_title, {
                    [styles.subMenuItem__content_type_active]: isActive,
                    [styles.subMenuItem__content_type_default]: !isClickable,
                })}
            />
        )
    }

    return (
        <div className={cn(className)}>
            {renderTitleItem()}

            {item.children &&
                item.children.map((child, childIndex) => {
                    return renderChildItem(child, childIndex)
                })}
        </div>
    )
}
