import { addStyles } from 'ethcss'
import { grid, IStyles, item } from '../../../theme'
import { defaultThemeStyles } from 'blocks.app/sidebar/Sidebar-theme'

const reportTasksStyles: IStyles = {
    menuItem: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.center,
        ...grid.p_mini,
        ...item.pointer,
        position: 'relative',
        textAlign: 'center',
        ...item.transitionErase,
        cursor: 'pointer',
        textDecoration: 'none',
        color: `var(--var-module-sidebar-component-sidebar-style-menuText, ${defaultThemeStyles.menuText})`,
        '&:hover': {
            color: `var(--var-module-sidebar-component-sidebar-style-menuTextHover, ${defaultThemeStyles.menuTextHover})`,
        },
    },
    iconWrapper: {
        ...grid.full,
        ...grid.col,
        backgroundColor: `var(--var-module-sidebar-component-filePreview-style-background, ${defaultThemeStyles['file-background']})`,
    },
}

addStyles(reportTasksStyles)
export default reportTasksStyles
