import React from 'react'
import config from '../index'
import translate from 'core/translate'
import CatalogSelectContent from 'blocks.app/catalog/_select/_content/catalog_select_content'
import CustomListHeader, { ICustomListHeaderProps } from '../../../__header/customList__header'
import Button from 'blocks.simple/button/button'
import { api } from 'core/api/ConnectionManager'
import EditableText from 'blocks.simple/editableText/editableText'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import validator from 'validator'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import store from 'core/store'

export default {
    ...config,
    fields: () => [
        { id: 'source', name: translate('image') },
        { id: 'name', name: translate('name') },
        { id: 'url', name: translate('url') },
    ],
    init(callback: any) {
        // @ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    category: 'sites',
    type: 'samsonite',
    headerFilters: (p_: ICustomListHeaderProps) => (
        <CustomListHeader
            onAdd={() => {
                if (!p_.fakeLine) {
                    p_.onAddFakeLine({ name: '', url: '', source: null })
                } else {
                    p_.onAnimate(true)
                    setTimeout(() => {
                        p_.onAnimate(false)
                    }, 1000)
                }
            }}
            title={translate('sites')}
            type={p_.type}
            onDelete={() => {
                const id = p_.selectedItems.map((selectedItem) => selectedItem.id)
                if (id.length > 0) {
                    api.send('samsoniteDeleteCatalogItem', { id })
                }
                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            }}
            {...p_}
        />
    ),
    list: {
        source: (
            p_: { id: number; source: { id: number } },
            active: boolean,
            listProps: {
                fakeLine: {}
                onAddFakeLine: (value?: {}) => void
                source: { id: number }
                template: any
                catalogId: number
            }
        ) => (
            <div className={cn(grid.fullWidth, grid.colCenter)}>
                <div className={p_.source ? grid.w15 : null}>
                    <CatalogSelectContent
                        type={'image'}
                        isHideSidebar
                        selectedInfo={p_.source}
                        onSelect={(selectedMedia: { id: number }) => {
                            if (p_.id) {
                                api.send('samsoniteUpdateCatalogItem', {
                                    id: p_.id,
                                    catalogId: listProps.catalogId,
                                    category: listProps.template.category,
                                    sourceId: selectedMedia.id,
                                })
                            } else {
                                listProps.onAddFakeLine({ ...listProps.fakeLine, source: selectedMedia })
                            }
                        }}
                    />
                </div>
            </div>
        ),
        name: (
            p_: { id: number; name: string },
            active: boolean,
            listProps: {
                fakeLine: {}
                onAddFakeLine: (value?: {}) => void
                source: { id: number }
                template: any
                catalogId: number
            }
        ) => (
            <EditableText
                id={p_.id}
                text={p_.name}
                onChange={(name: string) => {
                    listProps.onAddFakeLine({ ...listProps.fakeLine, name })
                }}
                onUpdate={(name, cb) => {
                    api.send('samsoniteUpdateCatalogItem', {
                        name,
                        id: p_.id,
                        catalogId: listProps.catalogId,
                        category: listProps.template.category,
                    }).then(cb)
                }}
            />
        ),
        url: (
            p_: { url: string; id: number },
            active: boolean,
            listProps: {
                fakeLine: { url: string; source: { id: number }; name: string }
                onAddFakeLine: (value?: {}) => void
                template: any
                catalogId: number
            }
        ) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.id })}>
                <div className={cn({ [grid.fullWidth]: p_.id, [grid.space]: !p_.id })}>
                    <EditableText
                        id={p_.id}
                        text={p_.url}
                        onChange={(url: string) => {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, url })
                        }}
                        onUpdate={(url, cb) => {
                            if (validator.isURL(url, { protocols: ['http', 'https'], require_protocol: true })) {
                                api.send('samsoniteUpdateCatalogItem', {
                                    url,
                                    id: p_.id,
                                    catalogId: listProps.catalogId,
                                    category: listProps.template.category,
                                }).then(cb)
                            } else {
                                store.dispatch(emitError('linkFormatError'))
                            }
                        }}
                    />
                </div>
                {!p_.id && (
                    <Button
                        className={cn(grid.ml_micro, grid.mr_mini)}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            listProps.onAddFakeLine()
                        }}
                    >
                        {translate('cancel')}
                    </Button>
                )}
                {!p_.id && (
                    <Button
                        mod={'fill'}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            if (
                                validator.isURL(listProps.fakeLine.url, {
                                    protocols: ['http', 'https'],
                                    require_protocol: true,
                                })
                            ) {
                                api.send('samsoniteCreateCatalogItem', {
                                    catalogId: listProps.catalogId,
                                    category: listProps.template.category,
                                    sourceId: listProps.fakeLine.source.id,
                                    ...listProps.fakeLine,
                                })
                            } else {
                                store.dispatch(emitError('linkFormatError'))
                            }
                        }}
                        disabled={!listProps.fakeLine.name || !listProps.fakeLine.url || !listProps.fakeLine.source}
                    >
                        {translate('save')}
                    </Button>
                )}
            </div>
        ),
    },
}
