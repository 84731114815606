import { addStyles } from 'ethcss'
import { textSizes } from 'theme/media'
import { IStyles, grid } from '../../../theme'

const scheduleTimerStyles: IStyles = {
    wrapper: {
        fontSize: textSizes.miniPlus,
        textAlign: 'center',
    },
    day: {
        ...grid.mb_mini,
    },
    time: {
        fontSize: textSizes.mdPlus,
        fontWeight: 'bold',
    },
}

addStyles(scheduleTimerStyles)
export default scheduleTimerStyles
