import React from 'react'
import { arrayMove } from 'react-sortable-hoc'
import Toast from 'blocks.simple/toast/toast'
import { toast } from 'react-toastify'
import catalogTemplate from './__template'
import { api } from 'core/api/ConnectionManager'
import deepEqual from 'fast-deep-equal'
import merge from 'merge'
import helpers, { IRecordWithStrings } from 'core/helpers'
import { createCachedDebounce } from 'core/utils'
import translate from 'core/translate'
import template from 'es6-template-strings'
import moment from 'moment'
import { isExist, isNotEmptyArray } from '../../core/utils/coreUtil'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { changeFilter } from 'features/routes'
import { isHorizontalMenu } from '../../core/helpers/menu'
import { Location } from 'history'
import store from 'core/store'
import { ItemData } from '../../core/models/Template'
import { IUser } from '../../core/models/Users'

let interval: any = null

interface IFilter {
    searchByFilters: {
        advancedSearch: { operator: string; separator: string }
        query: string
        appliedFilters: string[]
    }
}
export interface ICatalogMethodsProps {
    type: string
    location: Location
    selectedItems: ItemData[]
    mapSelect: () => void
    mapUnselect: () => void
    onSelectInfo: (data: ItemData | null) => void
    onSelect: (item: ItemData[]) => void
    drag?: boolean
    onChange: (dataItem: any, value: string) => void
    toggleDownloadsModal: () => void
    onSelectProgress: (item: ItemData) => void
    onDragStart: (event: string) => void
    onDragEnd: (event: string) => void
    onGetRootFolder: (value: number) => void
    onGetList: (list: any) => void
    selectedInfo: ItemData
    onDoubleClickObject: (item: ItemData) => void
    disableFolderSelection?: boolean
    disableMultiSelect: (list: ItemData[]) => boolean
    user: IUser
    disposition?: string
    showOnlySelected?: boolean
    hideHeader?: boolean
    searchPlaceholder: string
    searchable: boolean
    isMobile: boolean
    showHeaderButton: boolean
    small: boolean
    viewLocal: boolean
    changeDisposition: () => void
    breadcrumbsClassName: string
    createFolder: () => void
    additionalData: any
    toggleControls: () => void
    filterItems: any
    hideList?: boolean
    useWindow?: boolean
    classNameContent: string
    isHideList?: boolean
    windowWidth: number
    identificatedItems: any
    minimize?: boolean
    cols: number
    isMobileDragEnabled?: boolean
    handleSortable?: boolean
    treeView?: boolean
    onEditListItem?: (listItem: ItemData) => void
    allowEmptyList?: boolean
    fixedHeader?: boolean
    wrapperClassName: string
    isExistRightToolbar?: boolean
    tilesWrapperClassName: object
    contentAndFolders?: boolean
    autoSelectContentType?: boolean
    widgetsGallery: any
    customFilterList?: (list: any) => any[]
}

interface IState {
    startFirstQuery: boolean
    nextPageToken: string
    isInit: boolean
    downloadList: { type: string; progress: number }[]
    list: any[]
    total: number
    lastPage: number | null
    fields: { id: any; name: string; hide: boolean }[] | null
    folders: []
    tags: []
    breadcrumbs: any
    center: { lat: number; lng: number } | null
    isClicked: boolean
    autoMovedToTheFolder: boolean
    filterIsActive: boolean
    dimensions: {
        height: number
    }
    showOnlySelectedItem: object | null
    advancedSearch: object
    sortForListRequest: object | null
    fakeLine?: any
}

class CatalogMethods extends React.Component<ICatalogMethodsProps> {
    state: IState
    isShiftPressed: boolean
    loading: boolean
    fullLoading: boolean
    template: any
    init: (data: (data: any) => void) => void
    unmount: () => void
    getBreadcrumbs: (data: object[]) => object[]
    getMethodParams: { videoChatImplementedQ: boolean } | {}
    moveOnUpdate: ({ next, prev }: { prev: ItemData; next: ItemData }) => object
    onCreate: (dataItem: ItemData) => void
    onUpdate: any
    isEqual: (firstItem: ItemData, secondItem: ItemData) => boolean
    onDelete: any
    isFolder: (data: { id: number; __view: { index: number; isOpen: boolean }; children: React.ReactNode[] }) => boolean
    notification: any
    query: IRecordWithStrings
    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean, deviceBlockStyle?: string) => object
    clearListenersList: string[]
    filter: IFilter | {}

    constructor(p_: ICatalogMethodsProps) {
        super(p_)

        this.loading = true
        this.fullLoading = true
        this.state = {
            startFirstQuery: false,
            nextPageToken: '',
            isInit: false,
            downloadList: [],
            list: [],
            total: 0,
            lastPage: null,
            fields: null,
            folders: [],
            tags: [],
            breadcrumbs: null,
            center: null,
            isClicked: false,
            autoMovedToTheFolder: false,
            filterIsActive: false,
            dimensions: {
                height: 73,
            },
            showOnlySelectedItem: null,
            advancedSearch: {},
            sortForListRequest: null,
        }
        this.isShiftPressed = false

        this.template = catalogTemplate(p_.type)

        this.init = this.template.init ? this.template.init.bind(this) : this.baseInit.bind(this)
        this.unmount = this.template.unmount ? this.template.unmount.bind(this) : this.baseUnmount.bind(this)
        this.getBreadcrumbs = this.template.getBreadcrumbs
            ? this.template.getBreadcrumbs.bind(this)
            : this.baseGetBreadcrumbs.bind(this)
        this.get = this.get.bind(this)
        this.getMethodParams = this.template.getMethodParams || {}
        this.getFilter = this.getFilter.bind(this)
        this.onSelectInfo = this.onSelectInfo.bind(this)
        this.onSelectAll = this.onSelectAll.bind(this)
        this.moveOnUpdate = this.template.moveOnUpdate
            ? this.template.moveOnUpdate.bind(this)
            : this.baseMoveOnUpdate.bind(this)
        this.onCreate = this.template.onCreate ? this.template.onCreate.bind(this) : this.baseOnCreate.bind(this)
        this.onUpdate = this.template.onUpdate ? this.template.onUpdate.bind(this) : this.baseOnUpdate.bind(this)
        this.onDelete = this.template.onDelete ? this.template.onDelete.bind(this) : this.baseOnDelete.bind(this)
        this.onSelect = this.onSelect.bind(this)
        this.onSelectCluster = this.onSelectCluster.bind(this)
        this.getSelected = this.getSelected.bind(this)
        this.onSelectProgress = this.onSelectProgress.bind(this)
        this.getModelsList = this.getModelsList.bind(this)
        this.getActiveIndex = this.getActiveIndex.bind(this)
        this.isEqual = this.template.isEqual
        this.isFolder = this.template.isFolder ? this.template.isFolder : () => false
        const query = getURLSearchParamsByLocation(p_.location)
        this.query = helpers.getQuery(query, p_.type)
        this.notification = null
        this.getItemModel = this.template.getItemModel
        this.clearListenersList = []
        this.filter = {}
    }

    _mounted = true
    _autoExpandedRootFolder = false
    prevGetParams = null
    cache = {
        updated: {
            data: [],
            debounce: null,
        },
    }

    setAdvancedSearch = (params: { [index: string]: any }) => {
        this.setState({ advancedSearch: params })
    }

    getAdvancedSearch = () => {
        return this.state.advancedSearch
    }

    isDraggable(p_: any, template: { disableDrag: boolean; isFolder: boolean }) {
        if (template.disableDrag) {
            return false
        }
        const foldersExists = typeof template.isFolder !== 'undefined'

        return p_.drag || foldersExists
    }

    componentDidMount() {
        const p_ = this.props
        interval = setInterval(() => {
            if (
                p_.location.pathname.includes('/files') ||
                p_.location.pathname.includes('/addAdvanced') ||
                p_.location.pathname.includes('/addContentToDevice') ||
                p_.location.pathname.includes('/broadcasts/edit')
            ) {
                const downloads = store.getState().downloads.downloads
                const downloadsInProgress = downloads.filter(
                    (download) => download.progress < 100 && download.type === 'file'
                )
                this.setState({ downloadList: downloadsInProgress })
            }
        }, 1500)

        this.init(() => {
            const p_ = this.props

            this.getSelectedItem()

            if (this.isMap(p_)) {
                p_.mapSelect()
            } else {
                this.get(false, false, { props: p_ })
            }

            this.template.onCreateListeners.forEach((listener: (dataItem: any, clearList: string[]) => void) => {
                listener((dataItem: ItemData) => {
                    this.get(false, true)
                    this.onCreate(dataItem)
                    p_.onChange(dataItem, 'create')
                }, this.clearListenersList)
            })
            this.template.onUpdateListeners.forEach((listener: (dataItem: any, clearList: string[]) => void) => {
                listener((dataItem: ItemData) => {
                    createCachedDebounce(
                        dataItem,
                        this.cache.updated,
                        (data) => {
                            this.updateList(data)
                            this.onUpdate(data)
                            p_.onChange(data, 'update')
                        },
                        {
                            mounted: this._mounted,
                        }
                    )
                }, this.clearListenersList)
            })
            let deleteListeners = (array: []) =>
                array.forEach((listener: (dataItem: any, clearList: string[]) => void) => {
                    listener((dataItem: ItemData) => {
                        this.deleteItem(dataItem)
                        this.onDelete(dataItem)
                        p_.onChange(dataItem, 'delete')
                    }, this.clearListenersList)
                })
            if (this.template.onAdvertisementDeleteListeners) {
                deleteListeners(this.template.onAdvertisementDeleteListeners)
                deleteListeners(this.template.onDeleteListeners)
            } else {
                deleteListeners(this.template.onDeleteListeners)
            }
        })
    }

    componentWillUnmount() {
        this.clearListenersList.forEach((id) => api.removeObserver(id))
        this._mounted = false
        this.unmount()

        this.setState({ downloadList: [] })
        clearInterval(interval)
    }

    UNSAFE_componentWillReceiveProps(p_: ICatalogMethodsProps) {
        const s_ = this.state
        const query = getURLSearchParamsByLocation(p_.location)

        this.query = helpers.getQuery(query, p_.type)
        if (!this.isMap(p_)) {
            const prevFilter = this.getFilter(this.props)
            const filter = this.getFilter(p_)
            const filterDiff = !deepEqual(prevFilter, filter)

            if (filterDiff) {
                this.get(false, false, { filter: true, props: p_ }, true)
            }
        }

        if (this.isMap(p_) && !this.isMap(this.props)) {
            p_.mapSelect()
            this.setState({
                breadcrumbs: null,
            })
        }

        if (!this.isMap(p_)) {
            p_.mapUnselect()
        }

        const prevDisposition = this.getViewParamsByProps(this.props).disposition
        const nextDisposition = this.getViewParamsByProps(p_).disposition

        if (!this.isMap(p_) && prevDisposition !== nextDisposition) {
            this.get(false, false, { props: p_ })

            if (p_.onSelect) {
                p_.onSelect([])
            }

            p_.onSelectInfo(null)
        }

        if (!deepEqual(this.props.selectedItems, p_.selectedItems)) {
            let updatedList = s_.list.map((listItem, index) => {
                return {
                    ...listItem,
                    __view: {
                        ...listItem.__view,
                        selected: this.getSelected(listItem, p_),
                    },
                }
            })

            this.setState({ list: updatedList })
        }
    }

    onChangeFilterStatus = (statusValue: string) => {
        this.setState({ filterIsActive: statusValue })
    }

    onKeyDown = (e: any) => {
        if (e.shiftKey) {
            this.isShiftPressed = true
        }
    }
    onKeyUp = (e: any) => {
        if (!e.shiftKey) {
            this.isShiftPressed = false
        }
    }
    getSelectedItem = () => {
        const p_ = this.props
        const deviceBlockStyle = this.getDeviceBlockStyle()

        if (p_.showOnlySelected && p_.selectedInfo) {
            this.template.getBackendModel(p_.selectedInfo).then((response: any) => {
                const showOnlySelectedItem = this.getItemModel(response, this.getSelected, deviceBlockStyle)

                this.setState({ showOnlySelectedItem })
            })
        }
    }

    baseInit(callback: any) {
        this.setState({ isInit: true }, callback)
    }

    baseUnmount(callback: any) {}

    getSelected(dataItem: ItemData, props?: any) {
        const p_ = props || this.props
        let selected = false

        if (p_.selectedItems) {
            p_.selectedItems.forEach((selectedItem: ItemData) => {
                if (this.isEqual(dataItem, selectedItem)) {
                    selected = true
                }
            })
        }

        return selected
    }

    onSelectByShift = (items: ItemData[] | undefined) => {
        const p_ = this.props
        const { list }: any = this.state

        if (p_.onSelect && items) {
            let updatedList = list.map((listItem: any, index: number) => {
                const findedSelectedItem = items.find((selectedItem) => this.isEqual(selectedItem, listItem))

                return findedSelectedItem
                    ? {
                          ...listItem,
                          __view: {
                              ...list[index].__view,
                              selected: true,
                          },
                      }
                    : listItem
            })

            this.setState({ list: updatedList })

            p_.onSelect(items)
            p_.onSelectInfo(null)
        }
    }

    onResetSelection = () => {
        this.props.onSelect([])
        this.props.onSelectInfo(null)
    }

    onSelect({ selected, item }: { selected: boolean; item: ItemData }) {
        const p_ = this.props
        const s_ = this.state

        if (p_.onSelect) {
            let selectedItems: ItemData[] = []

            if (selected) {
                selectedItems = [...p_.selectedItems, item]
            } else {
                selectedItems = p_.selectedItems.filter((listItem: ItemData) => !this.isEqual(item, listItem))
            }

            let updatedList = s_.list.map((listItem: ItemData) => {
                if (!this.isEqual(item, listItem)) return listItem

                return {
                    ...listItem,
                    __view: {
                        ...listItem.__view,
                        selected: selected,
                    },
                }
            })

            this.setState({ list: updatedList })

            p_.onSelect(selectedItems)
            p_.onSelectInfo(null)
        }
    }

    onFolderOpening = (listItem: ItemData) => {
        const s_ = this.state
        const p_ = this.props
        const folderKey = this.template.folderName ? this.template.folderName : helpers.getNestedFolderKey(p_.type)

        if (this.isTreeLoaded(listItem)) {
            return
        }

        let getMethodParams = this.template.getMethodParams || {}

        if (this.template.customParams) {
            getMethodParams = this.template.customParams
            getMethodParams.sort = s_.sortForListRequest
        }

        api.send(this.template.getMethod, { ...getMethodParams, [folderKey]: listItem.id }, { hideLoader: false }).then(
            (res: any) => {
                let list = res.data

                s_.list.forEach((item: ItemData) => {
                    if (this.isEqual(listItem, item)) {
                        item.__view.isLoaded = true
                    }

                    const existItemIndex = res.data.findIndex((newItem: ItemData) => this.isEqual(newItem, item))

                    if (existItemIndex > -1) {
                        list.splice(existItemIndex, 1)
                    }
                })

                list = this.getModelsList(list)
                list = list.map((item: ItemData) => {
                    item.__view = {
                        selected: false,
                        nestedKey: folderKey,
                    }

                    return item
                })

                s_.list = [...s_.list, ...list]

                this.setState(s_)
            }
        )
    }
    isTreeLoaded = (listItem: ItemData) => {
        const s_ = this.state

        for (let i = 0; s_.list.length > i; i++) {
            if (this.isEqual(listItem, s_.list[i])) {
                s_.list[i].__view.isOpen = !s_.list[i].__view.isOpen

                this.setState(s_)
                if (s_.list[i].__view.isLoaded) {
                    return true
                }
            }
        }

        return false
    }

    onSelectCluster(selectedItems: ItemData[] | undefined) {
        const p_ = this.props

        if (selectedItems && isExist(selectedItems) && isNotEmptyArray(selectedItems) && this.isShiftPressed) {
            const itemsWithoutDoubles: any = this.getSelectedWithoutDoublesForArray(selectedItems)
            if (itemsWithoutDoubles) {
                this.onSelectByShift(itemsWithoutDoubles)
            }
            return
        }

        if (p_.onSelect && selectedItems) {
            p_.onSelect(selectedItems)
            this.onSelectInfo(-1)
        }
    }

    getSelectedWithoutDoublesForArray = (newSelection: ItemData[] | undefined) => {
        const p_ = this.props

        if (newSelection) {
            const allItems = [...newSelection, ...p_.selectedItems]
            const idsWithoutDoubles = this._getSelectedIdsWithoutDoublesForArray(p_.selectedItems, newSelection)

            return idsWithoutDoubles
                .map((id) => {
                    for (let i = 0; i < allItems.length; i++) {
                        if (allItems[i].id === id) {
                            return allItems[i]
                        }
                    }
                })
                .filter((item) => item)
        }
    }

    _getSelectedIdsWithoutDoublesForArray = (selectedItems: ItemData[], newSelection: ItemData[]) => {
        let selectedItemsIds: number[] = selectedItems.map((selected) => selected.id)
        let newSelectionIds: number[] = newSelection.map((selected) => selected.id)
        // @ts-ignore
        return [...new Set([...newSelectionIds, ...selectedItemsIds])]
    }

    onSelectProgress(index: number) {
        const item = this.state.list[index] || null
        const p_ = this.props

        p_.toggleDownloadsModal()
        p_.onSelectProgress(item)
    }

    onDragStart = (event: string) => {
        const p_ = this.props

        p_.onDragStart(event)

        if (!p_.onSelect) {
            return
        }

        const count = p_.selectedItems.length ? p_.selectedItems.length : 1
        const message = template(translate('transfer'), { count })
        const toastOptions = {
            closeOnClick: false,
            closeButton: false,
            autoClose: false,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            position: 'bottom-center',
        }

        this.notification = Toast.default(message, toastOptions)
    }
    onDragEnd = (event: string) => {
        const p_ = this.props

        if (!!p_.onSelect) {
            toast.dismiss(this.notification)
        }

        p_.onDragEnd(event)
    }

    baseOnCreate() {}

    baseOnUpdate() {}

    baseOnDelete() {}

    baseGetBreadcrumbs() {}

    getFilter(p_: any) {
        const s_ = this.state
        const locationQuery = getURLSearchParamsByLocation(p_.location)
        const query = helpers.getQuery(locationQuery, p_.type)
        const viewParams = this.getViewParamsByProps(p_)

        if (this.template.useNewFilter) {
            this.setState({ sortForListRequest: this.convertSortParams(viewParams.sort) })
        }

        const filter: any = {
            dateFilter: viewParams.dateFilter,
            sort: this.template.useNewFilter ? this.convertSortParams(viewParams.sort) : viewParams.sort,
            query: query.query,
            hasActualSchedule: query.hasActualSchedule,
            withFolders: query.withFolders,
            ...p_.filterOptions,
        }

        if (this.template.folderName) {
            filter[this.template.folderName] = query[this.template.folderName]
        }

        if (this.state.fields && !this.template.useNewFilter) {
            this.state.fields.forEach((field) => {
                if (query[field.id]) {
                    if (this.template.getFilterField) {
                        this.template.getFilterField(s_, filter, query, field)
                    } else {
                        filter[field.id] = query[field.id]
                    }
                }
            })
        }

        if (this.template.useNewFilter) {
            filter.searchByFilters = this.convertFilterParams(query)
        }

        return filter
    }

    parseParams(string: string) {
        let result = []

        try {
            result = JSON.parse(string)
        } catch (e) {
            console.log(e)
        }

        return result
    }

    convertFilterParams(queryObj: IRecordWithStrings) {
        const query = queryObj.query ? queryObj.query : ''
        const appliedFilters = queryObj.filterList ? this.parseParams(queryObj.filterList) : []
        let data = {
            query,
            appliedFilters,
            advancedSearch: {},
        }

        if (queryObj.advancedSearch) {
            data.advancedSearch = JSON.parse(queryObj.advancedSearch)
        }

        return data
    }

    convertSortParams(params: string) {
        let convertedParams: any = []

        switch (params) {
            case 'name':
                convertedParams = [['name', 'ASC']]
                break
            case '-name':
                convertedParams = [['name', 'DESC']]
                break
            case 'status':
                convertedParams = [['status', 'DESC']]
                break
            case '-status':
                convertedParams = [['status', 'ASC']]
                break
            case '-createdAt':
                convertedParams = [['createdAt', 'DESC']]
                break
            case '-type':
                convertedParams = [['type', 'DESC']]
                break
            default:
                convertedParams = []
        }

        if (!convertedParams.some((element: string[]) => element.includes('type'))) {
            convertedParams.push(['type', 'ASC'])
        }

        convertedParams.push(['id', 'ASC'])

        return convertedParams
    }

    checkNewFilterExist(filter: IFilter) {
        if (filter.searchByFilters) {
            const advSearchIsExist = filter.searchByFilters.advancedSearch
                ? !!filter.searchByFilters.advancedSearch.operator || !!filter.searchByFilters.advancedSearch.separator
                : false
            const queryAndAdSearchIsExist = filter.searchByFilters.query && advSearchIsExist
            return (
                filter.searchByFilters.appliedFilters.length || filter.searchByFilters.query || queryAndAdSearchIsExist
            )
        }
        return false
    }

    get(
        isLazyLoad?: boolean,
        quiteLoading?: boolean,
        { props = this.props, advancedSearchSettings = {}, ...options }: any = {},
        ignoreToken?: boolean
    ) {
        const s_ = this.state
        const p_ = this.props
        const filter = this.getFilter(props)
        this.filter = filter
        const locationQuery = getURLSearchParamsByLocation(p_.location)
        let getMethod = this.template.similarTemplate
            ? this.template.similarTemplate(p_.location.pathname, locationQuery).getMethod
            : this.template.getMethod
        let perPage = this.template.perPage || 35
        let getMethodParams = this.template.getMethodParams || {}
        let params

        if (!this.template.customParams && !this.template.getByFiltersParams) {
            delete filter['searchByFilters']
            params = {
                ...getMethodParams,
                ...filter,
                ...options.filter,
                ...advancedSearchSettings,
                offset: isLazyLoad ? s_.list.length : 0,
                perPage,
            }
        } else if (this.state.nextPageToken && !ignoreToken) {
            if (this.checkNewFilterExist(filter)) {
                getMethod = this.template.getByFiltersMethod
            }

            params = { nextPageToken: this.state.nextPageToken }
        } else {
            if (this.checkNewFilterExist(filter)) {
                getMethod = this.template.getByFiltersMethod
                params = this.template.getByFiltersParams
                params.appliedFilters = filter.searchByFilters.appliedFilters
                params.advancedSearch = {
                    query: filter.searchByFilters.query,
                    ...filter.searchByFilters.advancedSearch,
                }
            } else {
                params =
                    filter.parentId && this.template.customParams
                        ? {
                              ...this.template.customParams,
                              parentId: filter.parentId,
                          }
                        : { ...this.template.customParams }
            }
            if (filter.sort) {
                params.sort = filter.sort
            }
        }

        if (p_.type === 'advertisingCampaigns') {
            params.userDate = moment().format('YYYY-MM-DD HH:mm:ss')
        }
        if (locationQuery.contentId) {
            params.appliedFilters = [
                {
                    type: 'select',
                    name: 'sources',
                    options: [
                        {
                            value: locationQuery.contentId,
                            selected: true,
                        },
                    ],
                },
            ]
        }
        if (isLazyLoad && deepEqual(params, this.prevGetParams)) {
            return
        }

        if (s_.startFirstQuery && !s_.nextPageToken && !ignoreToken) {
            return
        }

        this.prevGetParams = params
        this.loading = true
        this.fullLoading = !isLazyLoad
        if (quiteLoading) {
            this.loading = false
            this.fullLoading = false
        }

        this.getBreadcrumbs(filter[this.template.folderName])

        api.send(getMethod, params, { hideLoader: false }).then((res: any) => {
            const p_ = this.props
            const s_ = this.state
            let list: any = this.getModelsList(res.data)

            if (!isLazyLoad && list.length === 1) {
                if (!filter[this.template.folderName] && !this._autoExpandedRootFolder) {
                    const listItem = list[0]

                    if (this.template.isFolder(listItem)) {
                        p_.onGetRootFolder(listItem.id)
                        changeFilter({ [this.template.folderName]: listItem.id })

                        this._autoExpandedRootFolder = true
                        //this.get(false, false, { filter: { groupId: listItem.id } })
                        return
                    }
                }
            }

            if (!this._autoExpandedRootFolder) {
                this._autoExpandedRootFolder = true
            }

            if (isLazyLoad) {
                list = [...s_.list, ...list]
            }

            this.fullLoading = false
            this.loading = false

            if (this.isList(p_)) {
                list = list.map((item: ItemData) => {
                    if (!item.__view.hasOwnProperty('nestedKey')) {
                        item.__view.nestedKey = helpers.getNestedFolderKey(p_.type)
                    }

                    if (!isLazyLoad && !options.filter) {
                        const existItem = s_.list.find((listItem: ItemData) => this.isEqual(listItem, item))

                        if (existItem) {
                            item = merge.recursive(true, item, existItem)
                        }
                    }

                    return item
                })

                if (!isLazyLoad && !options.filter) {
                    const nestedItems = s_.list.filter(
                        (listItem) => !list.find((item: ItemData) => this.isEqual(listItem, item))
                    )

                    list = [...list, ...nestedItems]
                }
            }

            p_.onGetList(list)

            if (res.nextPageToken) {
                if (!this.state.startFirstQuery) {
                    this.setState({
                        startFirstQuery: true,
                    })
                }

                this.setState({
                    nextPageToken: res.nextPageToken,
                })
            } else {
                this.setState({
                    nextPageToken: '',
                })
            }

            this.setState({
                list,
                total: res.nextPageToken ? list.length + 1 : res.total ? res.total : 0,
                lastPage: res.lastPage,
                fakeLine: null,
            })
        })
    }

    getNewData() {
        this.setState({ ...this.state, startFirstQuery: false, nextPageToken: '', list: [] }, this.get)
    }

    getModelsList(list: ItemData[]) {
        const deviceBlockStyle = this.getDeviceBlockStyle()

        if (this.props.type === 'files') {
            return [
                // ...this.state.downloadList,
                ...list.map((item) => {
                    return this.getItemModel(item, this.getSelected, deviceBlockStyle)
                }),
            ]
        }

        return list.map((item) => {
            return this.getItemModel(item, this.getSelected, deviceBlockStyle)
        })
    }

    baseMoveOnUpdate() {
        return false
    }

    updateList = (updatedList: any) => {
        const s_ = this.state
        const p_ = this.props
        updatedList = this.getModelsList(updatedList)
        const updateItems: ItemData[] = []
        const nextPrevItems: {
            prev: ItemData
            next: ItemData
        }[] = []

        const list = this.template.updateList
            ? this.template.updateList(s_.list, updatedList)
            : s_.list.map((listItem: ItemData) => {
                  const updatedItem = updatedList.find((updatedItem: ItemData) => this.isEqual(updatedItem, listItem))
                  const clonedUpdatedItem: ItemData = helpers.deepCopy(listItem)

                  if (updatedItem) {
                      const item = merge.recursive(true, listItem, updatedItem)

                      updateItems.push(item)
                      nextPrevItems.push({
                          prev: clonedUpdatedItem,
                          next: item,
                      })

                      return item
                  }

                  return listItem
              })

        this.setState({ list, isClicked: false })

        if (p_.selectedInfo) {
            const updatedSelectedInfoItem = updateItems.find((updatedItem) =>
                this.isEqual(p_.selectedInfo, updatedItem)
            )
            if (updatedSelectedInfoItem) {
                p_.onSelectInfo(updatedSelectedInfoItem)

                if (s_.isClicked) {
                    p_.onDoubleClickObject(updatedSelectedInfoItem)
                }
            }
        }

        if (p_.selectedItems && p_.selectedItems.length) {
            const selectedItems = p_.selectedItems.map((selectedItem: ItemData) => {
                const updatedItem = updateItems.find((updateItem) => this.isEqual(selectedItem, updateItem))

                if (updatedItem) {
                    return updatedItem
                }

                return selectedItem
            })

            p_.onSelect(selectedItems)
        }

        if (!this.loading && !this.query.query) {
            nextPrevItems.forEach((updateItem) => {
                if (this.moveOnUpdate(updateItem)) {
                    this.deleteItem(updateItem.next, false)
                }
            })
        }
    }

    deleteItem(dataItem: ItemData, saveSelected?: boolean) {
        const s_ = this.state
        const p_ = this.props
        const list = s_.list.filter((listItem) => !this.isEqual(listItem, dataItem))
        let total = s_.total

        if (s_.total && s_.list.length >= s_.total) {
            total = list.length
        }

        p_.onGetList(list)
        this.setState({ list, total })

        if (!saveSelected) {
            if (p_.selectedInfo && this.isEqual(p_.selectedInfo, dataItem)) {
                this.onSelectInfo(-1)
            }
            if (p_.selectedItems) {
                let selectedItems = [...p_.selectedItems]

                selectedItems = selectedItems.filter((listItem) => !this.isEqual(listItem, dataItem))
                p_.onSelect(selectedItems)
            }
        }
    }

    onSelectInfo(index: number | null) {
        if (typeof index === 'number') {
            const selectedIndex = index - this.state.downloadList.length
            const item = this.state.list[selectedIndex] || null
            const p_ = this.props

            if (item && this.isShiftPressed) {
                this.onSelectSelectInfoByShift(selectedIndex)
                return
            }

            p_.onSelectInfo && p_.onSelectInfo(item)
            if (item && this.template.getBackendModel) {
                if (this.template.similarTemplateBackendModel) {
                    this.template
                        .similarTemplateBackendModel(item, p_.location.pathname)
                        .then((itemModel: ItemData) => this.updateList([itemModel]))
                } else {
                    this.template.getBackendModel(item).then((itemModel: ItemData) => this.updateList([itemModel]))
                }
            }
        }
    }

    onSelectInfoByItem = (item: ItemData) => {
        const p_ = this.props

        if (item && this.isShiftPressed) {
            let selectedWithoutDoubles = p_.selectedItems.filter((selected: { id: number }) => selected.id !== item.id)
            selectedWithoutDoubles = [item, ...selectedWithoutDoubles]
            this.onSelectByShift(selectedWithoutDoubles)
            return
        }

        p_.onSelectInfo && p_.onSelectInfo(item)
        if (item && this.template.getBackendModel) {
            this.template.getBackendModel(item).then((itemModel: ItemData) => this.updateList([itemModel]))
        }
    }

    onSelectSelectInfoByShift(index: number) {
        const p_ = this.props
        const { list } = this.state

        let selectedItemIndex: number = 0

        if (p_.selectedInfo) {
            selectedItemIndex = list.findIndex((item) => item.id === p_.selectedInfo.id)
        }

        if (p_.selectedItems && p_.selectedItems.length) {
            selectedItemIndex = list.findIndex((item) => item.id === p_.selectedItems[0].id)
        }

        let selectedItems = list.slice(selectedItemIndex, index + 1)

        if (selectedItemIndex > index) {
            selectedItems = list.slice(index, selectedItemIndex + 1).reverse()
        }

        this.onSelectByShift(selectedItems)
    }

    onSelectAll() {
        const s_ = this.state
        const p_ = this.props
        const newSelectedItems = s_.list.filter((listItem) => {
            if ((p_.disableFolderSelection && this.isFolder(listItem)) || p_.disableMultiSelect(listItem)) {
                return false
            }

            const findItem = p_.selectedItems.find((selectedItem: ItemData) => this.isEqual(listItem, selectedItem))

            return !findItem
        })

        if (p_.onSelect) {
            p_.onSelect([...p_.selectedItems, ...newSelectedItems])
        }
        p_.onSelectInfo && p_.onSelectInfo(null)
    }

    getActiveIndex() {
        const s_ = this.state
        const p_ = this.props
        let activeIndex = null

        if (p_.selectedInfo) {
            s_.list.forEach((listItem, index) => {
                if (this.isEqual(listItem, p_.selectedInfo)) {
                    activeIndex = index
                }
            })
        }

        return activeIndex
    }

    onCenterMap = (center: { lat: number; lng: number }) => {
        this.setState({ center })
    }

    getViewParams = () => {
        const { type, user } = this.props

        const prefix = catalogTemplate(type).settingsPrefixes
        return helpers.getViewParamsByUser(prefix, user)
    }

    getViewParamsByProps = (props: any) => {
        const { type, user } = props

        const prefix = catalogTemplate(type).settingsPrefixes
        return helpers.getViewParamsByUser(prefix, user)
    }

    getDisposition = () => {
        const { disposition } = this.props
        const viewParams = this.getViewParams()

        if (this.props.location.pathname === '/schedule' && isHorizontalMenu() && viewParams.disposition === 'map')
            return 'tile'

        return disposition || viewParams.disposition
    }

    getDispositionByProps = (props: { disposition: string }) => {
        const { disposition } = props
        const viewParams = this.getViewParams()

        return disposition || viewParams.disposition
    }

    isList = (props: any) => {
        return this.getDispositionByProps(props) === 'list'
    }

    isMap(props: any) {
        const p_ = this.props

        const isMap = this.getDispositionByProps(props) === 'map' && p_.location.pathname.includes('/displays')

        if (isHorizontalMenu()) {
            if (props.location.pathname === '/schedule' && isMap) return false
        }

        return isMap
    }

    getDeviceBlockStyle = () => {
        const { user } = this.props

        return user?.settings?.deviceBlockStyle
    }

    onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        const { list } = this.state
        const sorted = arrayMove(list, oldIndex, newIndex)

        this.setState({
            list: [],
        })

        this.setState({
            list: sorted,
        })

        if (this.template.onSortEndCallback) {
            this.template.onSortEndCallback({ oldIndex, newIndex, sorted })
        }
    }
}

export default CatalogMethods
