import { addStyles } from 'ethcss'
import { grid } from 'theme'

const dropdownStyles = {
    label: {
        ...grid.row,
        ...grid.mb_micro
    }
}

addStyles(dropdownStyles)
export default dropdownStyles
