import { addStyles } from 'ethcss'
import { mediaCSS, layers, grid, item, text, IStyles } from 'theme'
import { defaultThemeStyles } from 'atoms/Tabs/Tabs-theme'

const createRoleHeaderStyles: IStyles = {
    createRoleHeaderWrapper: {
        ...grid.row,
        ...grid.justify,
        ...grid.normalCenter,
        ...item.indent_big,
        ...item.white,
        borderBottom: `1px solid var(--var-module-roles-component-roles-style-leftColumnBorder, ${defaultThemeStyles.background})`,
    },
    createRoleHeaderButtonsWrapper: {
        ...grid.p_mini,
        ...grid.row,
        ...grid.noWrap,
        ...grid.w30,
        [mediaCSS.tabMini]: {
            ...grid.rowCenter,
            ...grid.w100,
            ...item.water,
            ...item.fixed,
            bottom: 0,
            left: 0,
            zIndex: layers.sticky,
        },
    },
    createRoleHeaderLabel: {
        ...text.mdPlus,
        ...text.greyDark,
        ...text.bold,
        [mediaCSS.tabMini]: {
            ...text.normal,
        },
    },
    createRoleHeaderLabelWrapper: {
        ...grid.row,
        ...grid.noWrap,
        ...item.overNone,
    },
    createRoleHeaderButton: {
        ...grid.w45,
        ...grid.mr_mini,
        ...grid.ml_mini,

        div: {
            width: '100%',
        },
    },
}

addStyles(createRoleHeaderStyles)
export default createRoleHeaderStyles
