import React, { FC, useEffect } from 'react'
import Animation from 'blocks.simple/animation/animation'
import { layers } from 'blocks.app/config'
import { styles } from './Loader-styles'
import { cn } from 'ethcss'
import { useDispatch } from 'react-redux'
import { ILoaderState, loaderActions } from './Loader.state'
import { LoaderProps } from './Loader-types'
import { useTypedSelector } from 'core/store/hooks'

const colorBlocks = (colors: string[]) => {
    return colors.map((color, index) => <div key={`bar_${index}`} className={cn(styles.bar, styles[color])} />)
}

const colors = ['selected', 'darkCloud', 'white']

let timer: NodeJS.Timer | null = null
const TIMEOUT_MS = 30000
const z = 2

export const Loader: FC<LoaderProps> = () => {
    const dispatch = useDispatch()
    const { loadCounter } = useTypedSelector<ILoaderState>((state) => state.loader)
    const isLoading = (value: number) => value > 0

    const resetTimer = () => {
        if (!timer) return null

        clearTimeout(timer)
        timer = null
    }

    useEffect(() => {
        if (isLoading(loadCounter)) return createTimeoutTimer()

        resetTimer()

        return () => {
            resetTimer()
        }
    }, [loadCounter])

    const createTimeoutTimer = () => {
        resetTimer()

        timer = setTimeout(() => {
            dispatch(loaderActions.clearLoading())
        }, TIMEOUT_MS)
    }

    return (
        <div className={styles.wrapper}>
            <Animation>
                {loadCounter > 0 && (
                    <div key={'app_loader'} className={styles.loadbar} style={{ zIndex: z * layers.modal }}>
                        {colorBlocks(colors)}
                    </div>
                )}
            </Animation>
        </div>
    )
}
