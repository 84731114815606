import { useEffect, useState } from 'react'
import { routes } from 'features/routes'
import { getURLSearchParamsByLocation, getURLSearchParamsString } from 'features/routes/utils'
import { getLocaleDropdownValue } from '../../core/helpers/user'
import { Props } from './authorization'
declare var window: any

type Selected = {
    value: string
    label: string
}

const AuthorizationMethods = (p_: Props) => {
    const [loading, setLoading] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0)
    const [selectedLocale, setSelectedLocale] = useState(getLocaleDropdownValue())
    const backgroundImagesLength = window.spconfig.auth.backgroundImages.length
    let animationTimer: any = null

    useEffect(() => {
        startBackgroundSlider()

        return () => clearInterval(animationTimer)
    }, [activeIndex, backgroundImagesLength])

    const changeLocale = (selected: Selected) => {
        setSelectedLocale(selected)
    }

    const startBackgroundSlider = () => {
        const backgroundsCount = window.spconfig.auth.backgroundImages.length

        if (backgroundsCount > 1) {
            animationTimer = setInterval(() => {
                let _activeIndex = activeIndex

                if (backgroundsCount - 1 === _activeIndex) {
                    _activeIndex = 0
                } else {
                    _activeIndex++
                }

                setActiveIndex(_activeIndex)
            }, 10000)
        }
    }
    const getHeaderLink = () => {
        const pathname = p_.location.pathname
        const currentPathname = pathname.substr(1)
        let nextPathname = ''
        const query = getURLSearchParamsByLocation(p_.location)

        if (currentPathname === routes.forgetPassword.path) {
            nextPathname = `/${routes.login.path}`
        }

        return { pathname: nextPathname, search: getURLSearchParamsString({ lang: query.lang }) }
    }

    return {
        loading,
        setLoading,
        activeIndex,
        selectedLocale,
        getHeaderLink,
        changeLocale,
    }
}

export default AuthorizationMethods
