import React, { CSSProperties } from 'react'
import helpers from 'core/helpers'
import { uniqid } from 'core/utils'

export interface IModalProps {
    open: boolean
    contentClassName?: string | {}
    closeIconSize: number
    closeIconColor?: string
    closeIconStyles?: CSSProperties
    children: React.ReactNode
    sidebars?: {
        left?: any
        right?: any
    }
    leftSidebarClass?: string
    rightSidebarClass?: string
    onClose?: () => void
    mod: string
    showCloseIcon: boolean
    z: number
    name: string
    transitionName: string
}

const root: HTMLElement | null = document.getElementById('root')

class ModalMethods extends React.Component<IModalProps> {
    state = {
        open: false,
    }
    id = uniqid()
    element = document.createElement('div')

    componentDidMount() {
        document.addEventListener('keydown', this.closeByKey)
        if (root) {
            root.appendChild(this.element)
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.closeByKey)

        if (root) {
            root.removeChild(this.element)
        }
        helpers.disableWindowScroll(false, this.id)
    }

    shouldComponentUpdate(nextProps: IModalProps) {
        const s_ = this.state

        if (nextProps.open !== s_.open) {
            helpers.disableWindowScroll(nextProps.open, this.id)
            this.setState({ open: nextProps.open })
        }

        return true
    }

    closeByKey = (event: KeyboardEvent) => {
        event = event || window.event

        if (event.keyCode === 27) {
            const p_ = this.props
            if (p_.open && p_.onClose) {
                event.preventDefault()
                p_.onClose()
            }
        }
    }
}

export default ModalMethods
