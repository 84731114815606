import React from 'react'
import { arrayMove } from 'react-sortable-hoc'
import customListTemplate from './__template'
import { api } from 'core/api/ConnectionManager'
import merge from 'merge'

interface ICustomListProps {
    type: string
    settings: {}
    emitError: (error: string) => void
    showUpdateArchive: boolean
    onSelect: (data: any) => void
    onGetData: (data: any) => void
    updateUserSettings: (data: any) => void
    selectedItems: {}[]
    showChooseWebApp: boolean
    toggleControls: () => void
    isDelayTime: boolean
    isWebAppTitle: boolean
    additionalData: any
}

type Item = { id: number; catalogId: number; order: number }

class CustomListMethods extends React.Component<ICustomListProps> {
    loading: boolean = false
    fullLoading: boolean = false
    template: any
    init: (cb: () => void) => void
    unmount: () => void
    moveOnUpdate: () => void
    onCreate: (item: {}) => void
    onUpdate: (item: {}) => void
    onDelete: (item: {}) => void
    isEqual: (item_1: {}, item_2: {}) => boolean
    getItemModel: any
    clearListenersList: string[]
    state: {
        isInit: boolean
        list: any[]
        catalogId: number | null
        sources: { [index: string]: { [index: string]: any } }
        total: number | null
        lastPage: number | null
        fields: string[]
        animate: boolean
        delayTime: number
        title: string
        webAppStatus: string
        fakeLine: string | null
    }

    constructor(p_: ICustomListProps) {
        super(p_)

        this.loading = true
        this.fullLoading = true
        this.state = {
            isInit: false,
            list: [],
            catalogId: null,
            sources: p_.settings,
            total: null,
            lastPage: null,
            fields: [],
            animate: false,
            delayTime: 10000,
            title: '',
            webAppStatus: 'actual',
            fakeLine: null,
        }
        this.template = customListTemplate(p_.type)

        this.init = this.template.init ? this.template.init.bind(this) : this.baseInit.bind(this)
        this.unmount = this.template.unmount ? this.template.unmount.bind(this) : this.baseUnmount.bind(this)
        this.get = this.get.bind(this)
        this.updateItem = this.updateItem.bind(this)
        this.moveOnUpdate = this.template.moveOnUpdate
            ? this.template.moveOnUpdate.bind(this)
            : this.baseMoveOnUpdate.bind(this)
        this.onCreate = this.template.onCreate ? this.template.onCreate.bind(this) : this.baseOnCreate.bind(this)
        this.onUpdate = this.template.onUpdate ? this.template.onUpdate.bind(this) : this.baseOnUpdate.bind(this)
        this.onDelete = this.template.onDelete ? this.template.onDelete.bind(this) : this.baseOnDelete.bind(this)
        this.onSelect = this.onSelect.bind(this)
        this.onSortEnd = this.onSortEnd.bind(this)
        this.getSelected = this.getSelected.bind(this)
        this.getModelsList = this.getModelsList.bind(this)
        this.isEqual = this.template.isEqual
        this.getItemModel = this.template.getItemModel
        this.template.category = this.template.category || 'products'
        this.template.type = this.template.type || p_.type
        this.clearListenersList = []
    }
    componentDidMount() {
        this.init(() => {
            // @ts-ignore
            this.get()

            this.template.onCreateListeners.forEach(
                (listener: (dataItem: (dataItem: string) => void, clearList: string[]) => void) => {
                    listener((dataItem) => {
                        this.get(false, true)
                        this.onCreate(dataItem)
                    }, this.clearListenersList)
                }
            )
            this.template.onUpdateListeners.forEach(
                (listener: (dataItem: (dataItem: string) => void, clearList: string[]) => void) => {
                    listener((dataItem) => {
                        this.updateItem(dataItem)
                        this.onUpdate(dataItem)
                    }, this.clearListenersList)
                }
            )
            this.template.onDeleteListeners.forEach(
                (listener: (dataItem: (dataItem: string) => void, clearList: string[]) => void) => {
                    listener((dataItem) => {
                        this.deleteItem(dataItem)
                        this.onDelete(dataItem)
                    }, this.clearListenersList)
                }
            )

            if (this.props.showUpdateArchive) {
                const method = this.template.onCatalogUpdatedMethod

                api.addObserver(
                    method,
                    (dataItem: { webAppStatus: string }) => {
                        this.webAppUpdated(dataItem)
                    },
                    this.clearListenersList
                )
            }
        })
    }
    componentWillUnmount() {
        this.clearListenersList.forEach((id: string) => api.removeObserver(id))
        this.unmount()
    }
    baseInit(callback: () => void) {
        this.setState({ isInit: true }, callback)
    }
    baseUnmount(callback: () => void) {}
    getSelected(dataItem: {}, props: ICustomListProps) {
        const p_ = props || this.props
        let selected = false

        if (p_.selectedItems) {
            p_.selectedItems.forEach((selectedItem: {}) => {
                if (this.isEqual(dataItem, selectedItem)) {
                    selected = true
                }
            })
        }

        return selected
    }
    onSelect({ selected, item }: { selected: boolean; item: {} }) {
        const p_ = this.props
        const s_ = this.state

        if (p_.onSelect) {
            let selectedItems = []

            if (selected) {
                selectedItems = [...p_.selectedItems, item]
            } else {
                selectedItems = p_.selectedItems.filter((listItem: {}) => !this.isEqual(item, listItem))
            }

            p_.onSelect(selectedItems)

            let updatedList = s_.list.map((listItem: { __view: any }) => {
                if (!this.isEqual(item, listItem)) return listItem

                return {
                    ...listItem,
                    __view: {
                        ...listItem.__view,
                        selected,
                    },
                }
            })

            this.setState({ list: updatedList })
        }
    }

    onSortEnd({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) {
        const s_ = this.state
        let sorted: Item[] = arrayMove(s_.list, oldIndex, newIndex)
        const method = this.template.updateMethod || 'updateBurgersProduct'

        sorted = sorted.map((item: Item, index) => {
            item.order = index
            return item
        })

        const newList = sorted.map((item) => ({ id: item.id, catalogId: item.catalogId, order: item.order }))

        api.send(method, newList)
        this.setState({ list: sorted })
    }
    baseOnCreate() {}
    baseOnUpdate() {}
    baseOnDelete() {}
    prefixQuery(fieldName: string) {
        return `${this.template.type}__${fieldName}`
    }
    get(isLazyLoad?: boolean, quiteLoading?: boolean) {
        const s_ = this.state
        let source = this.getSource()
        let perPage = this.template.perPage || 35

        if (!source) {
            return
        }
        let params: {
            [index: string]: any
        } = {
            sourceId: source.id,
        }

        params = {
            ...params,
            offset: isLazyLoad ? s_.list.length : 0,
            perPage: perPage,
            type: this.template.type,
        }

        this.loading = true
        this.fullLoading = !isLazyLoad
        if (quiteLoading) {
            this.loading = false
            this.fullLoading = false
        }

        api.send(this.template.getMethod, params, { hideLoader: false }).then((res: any) => {
            const category = this.template.category
            const modelList =
                category === 'products' || category === 'categories' ? res[category] : res.categories[category]
            let list = this.getModelsList(modelList)

            if (isLazyLoad) {
                list = [...s_.list, ...list]
            }

            this.fullLoading = false
            this.loading = false
            this.setState({
                list,
                webAppStatus: res.webAppStatus,
                total: res.total,
                lastPage: res.lastPage,
                fakeLine: null,
                catalogId: res.id,
                delayTime: res.delayTime,
                title: res.title,
            })
            this.props.onGetData(res)
        })
    }
    getModelsList(list = []) {
        return list.map((item) => {
            return this.getItemModel(item, this.getSelected)
        })
    }
    baseMoveOnUpdate() {
        return false
    }
    updateItem(dataItem: string) {
        const p_ = this.props
        const item = this.getItemModel(dataItem, this.getSelected)
        const { list } = this.state
        let updatedItem: {} = {}

        list.forEach((listItem: {}, index: number) => {
            if (this.isEqual(item, listItem)) {
                list[index] = { ...list[index], ...item }
                updatedItem = list[index]
            }
        })

        this.setState({ list })

        if (p_.selectedItems && updatedItem) {
            const selectedItems = [...p_.selectedItems]

            selectedItems.forEach((selectedItem, index) => {
                if (this.isEqual(selectedItem, updatedItem)) {
                    selectedItems[index] = updatedItem
                }
            })
            p_.onSelect(selectedItems)
        }
    }
    updateCatalog = (data: { [index: string]: number }) => {
        const s_ = this.state

        if (data.delayTime) {
            if (data.delayTime < 10) {
                data.delayTime = 10000
            } else {
                data.delayTime *= 1000
            }

            this.setState({ delayTime: data.delayTime })
        }

        api.send(this.template.updateCatalogMethod, {
            id: s_.catalogId,
            ...data,
        })
    }
    deleteItem(dataItem: string, saveSelected?: boolean) {
        const s_ = this.state
        const p_ = this.props

        s_.list = s_.list.filter((listItem: string) => !this.isEqual(listItem, dataItem))
        this.setState(s_)

        if (!saveSelected) {
            if (p_.selectedItems) {
                let selectedItems = [...p_.selectedItems]

                selectedItems = selectedItems.filter((listItem) => !this.isEqual(listItem, dataItem))
                p_.onSelect(selectedItems)
            }
        }
    }
    onChooseWebApp = (archive: string, type: string) => {
        const { updateUserSettings } = this.props
        let prop = 'burgersCatalogSelectedSource'
        const object: { [index: string]: any } = { [prop]: {} }
        const burgersCategories = ['burgersBeef', 'burgersPremium', 'burgersChicken']
        const drinksCategories = ['drinkSlider', 'drinksMenu']

        if (burgersCategories.includes(type)) {
            type = 'burgers'
        }

        if (drinksCategories.includes(type)) {
            type = 'drinks'
        }

        if (this.template.type === 'samsonite') {
            type = this.template.type
            prop = 'samsoniteCatalogSelectedSource'

            object[prop] = {}
        }

        if (this.template.type === 'askona') {
            type = this.template.type
            prop = 'askonaCatalogSelectedSource'

            object[prop] = {}
        }

        if (this.template.type === 'pitamix') {
            type = this.template.type
            prop = 'pitamixCatalogSelectedSource'

            object[prop] = {}
        }

        object[prop][type] = archive
        updateUserSettings({ data: { [prop]: object[prop] }, isSaveSettings: true })

        const sources = merge.recursive(true, this.state.sources, { [prop]: { [type]: object[prop][type] } })

        this.setState({ sources }, () => {
            this.get()
        })
    }

    updateWebApp = () => {
        const { emitError } = this.props
        const source = this.getSource()
        const method = this.template.updateWebAppMethod || 'updateBurgersWebApp'
        if (!source) {
            emitError('archiveNotSelected')
            return
        }

        let options: {
            id?: number | null
            catalogType?: string
            sourceId?: number
        } = {
            catalogType: this.template.type,
            sourceId: source.id,
        }

        switch (this.template.type) {
            case 'samsonite':
                options = {
                    id: this.state.catalogId,
                }
                break
            case 'askona':
                options = {
                    id: this.state.catalogId,
                }
                break
            case 'pitamix':
                options = {
                    id: this.state.catalogId,
                }
                break
            default:
                break
        }

        api.send(method, options, { hideLoader: true })
    }
    webAppUpdated = ({ webAppStatus }: { webAppStatus: string }) => {
        this.setState({ webAppStatus })
    }
    getSource = () => {
        const s_ = this.state
        const catalogSelectedSource = this.template.catalogSelectedSource || 'burgersCatalogSelectedSource'
        const sourceByType = s_.sources[catalogSelectedSource][this.template.type]
        if (!sourceByType) {
            return null
        }

        return sourceByType
    }
}

export default CustomListMethods
