import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { textSizes } from 'theme/media'

const legendStyles = {
    legend: {
        ...item.abs,
        ...grid.p_mini,
        ...item.whiteOpacity,
        right: 20,
        bottom: 20,
    },
    title: {
        ...grid.mb_md,
        fontSize: textSizes.mdPlus,
        textAlign: 'center',
    },
    item: {
        ...grid.flex,
        ...grid.normalCenter,
        ...grid.mb_mini,
    },
    colorBlock: {
        ...grid.mr_micro,
        width: 50,
        height: 30,
        borderRadius: 7,
        border: '1px solid #aaaaaa',
    },
}

addStyles(legendStyles)
export default legendStyles
