import { addStyles } from 'ethcss'
import { IStyles } from '../../../theme'
import grid from '../../../blocks.simple/grid/grid.jcss'

const modBusSettings: IStyles = {
    switchWrapper: {
        display: 'flex',
    },
    switchCaption: {
        marginRight: 14,
    },
    wrapper: {
        marginTop: 21,
    },
    disabled: {
        opacity: 0.5,
    },
    radioButtons: {
        ...grid.row,
        ...grid.mb_md,
        ...grid.fullWidth,
    },
}

addStyles(modBusSettings)
export default modBusSettings
