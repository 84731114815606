import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { defaultThemeStyles } from '../VideoEditor-theme'
import { mediaCSS, textSizes } from 'theme/media'
import { IStyles } from 'theme'

const videoEditorContentStyles: IStyles = {
    wrapper: {
        ...grid.fullWidth,
        ...grid.colCenter,
        ...grid.center,
        backgroundColor: `var(--var-module-videoEditor-component-content-style-background, ${defaultThemeStyles.background})`,
    },
    controls: {
        ...grid.fullWidth,
        backgroundColor: `var(--var-module-videoEditor-component-videoEditor-style-controlsBackground, ${defaultThemeStyles.controlsBackground})`,
    },
    tabMiniPt: {
        [mediaCSS.tabMini]: {
            fontSize: 16,
            marginTop: 42,
        },
        [mediaCSS.mob]: {
            fontSize: 14,
            marginTop: 6,
        },
    },
    rangeVolume: {
        width: 300,
    },
    timeLine: {
        color: `var(--var-module-videoEditor-component-videoEditor-style-timeLine, ${defaultThemeStyles.timeLine})`,
    },
    dropzone: {
        ...grid.w60,
        [mediaCSS.tab]: {
            ...grid.w90,
        },
        margin: '0 auto',
    },
    dropzoneContent: {
        ...grid.colCenter,
        ...grid.center,
        cursor: 'pointer',
        ...grid.p_md,
        ...item.rounded_full_normal,
        color: `var(--var-module-videoEditor-component-videoEditor-style-dropzone, ${defaultThemeStyles.dropzone})`,
        border: `5px dashed var(--var-module-videoEditor-component-videoEditor-style-dropzone, ${defaultThemeStyles.dropzone})`,
    },
    content: {
        ...grid.colCenter,
        ...grid.center,
        ...grid.full,
        position: 'relative',
    },
    progress: {
        width: 50,
        height: 50,
    },
    mediaUpload: {
        color: `var(--var-module-videoEditor-component-videoEditor-style-mediaUpload, ${defaultThemeStyles.mediaUpload})`,
        fontSize: textSizes.md,
        ...grid.mt_md,
    },
    removeIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 1,
    },
    progressBackground: {
        background: 'none!important',
    },
    loader: {
        ...grid.full,
        ...grid.colCenter,
    },
    processingStatus: {
        ...grid.mt_md,
        fontSize: textSizes.md,
        color: `var(--var-module-videoEditor-component-videoEditor-style-timeLine, ${defaultThemeStyles.timeLine})`,
    },
    imageView: {
        ...grid.full,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
    },
    multipleWrapper: {
        ...grid.full,
        ...grid.colCenter,
    },
    multipleDescription() {
        return {
            ...this.mediaUpload,
            ...grid.w50,
            textAlign: 'center',
            cursor: 'default',
        }
    },
}

addStyles(videoEditorContentStyles)
export default videoEditorContentStyles
