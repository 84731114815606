import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICatalogItem } from 'organisms/CatalogWithPagination/CatalogWithPagination-types'

export interface IModalOptions {
    mod?: string
    showCloseIcon?: boolean
}

export interface IScoreboardsState {
    modalState: string | null
    modalOptions: IModalOptions
    modalData: ICatalogItem | null
    modalFetchData: null | unknown
    isModalFetchLoading: boolean
}

export interface IScoreboardsModal {
    modalState: string | null
    modalOptions: IModalOptions
    modalData: ICatalogItem | null
}

export const initialState: IScoreboardsState = {
    modalState: null,
    modalOptions: {},
    modalData: null,
    modalFetchData: null,
    isModalFetchLoading: false,
}

const scoreboardsSlice = createSlice({
    name: 'scoreboards',
    initialState,
    reducers: {
        closeModal(state) {
            state.modalState = null
            state.modalOptions = {}
            state.modalData = null
            state.isModalFetchLoading = false
            state.modalFetchData = null
        },
        openModal(state, action: PayloadAction<IScoreboardsModal>) {
            const { modalData, modalState, modalOptions } = action.payload

            state.modalState = modalState
            state.modalOptions = modalOptions
            state.modalData = modalData
        },
    },
})

const { reducer: scoreboardsReducer, actions: scoreboardsActions } = scoreboardsSlice
export { scoreboardsReducer, scoreboardsActions }
