import React, { useState } from 'react'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import Button from 'blocks.simple/button/button'
import EditableText from 'blocks.simple/editableText/editableText'
import { cn } from 'ethcss'
import styles from './animationSettings.jcss'
import translate from 'core/translate'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import helpersDigitalSignages from 'core/helpers/_digitalSignages'

const animationTypes = [{ id: 'in' }, { id: 'out' }]
const animationViews: { [index: string]: any } = {
    in: [{ name: 'withoutAnimation' }, { name: 'fadeIn' }],
    out: [{ name: 'withoutAnimation' }, { name: 'fadeOut' }],
}

const AnimationView = (p_: any) => {
    const [over, setOver] = useState(false)

    const noBroadcastImage = helpersDigitalSignages.getNoBroadcastIconSrc()

    return (
        <div
            className={cn(styles.animationViewItem, { [styles.animationItemActive]: p_.isActive })}
            onMouseEnter={() => setOver(true)}
            onMouseLeave={() => setOver(false)}
            onClick={p_.onClick}
        >
            <div className={cn(grid.w10, grid.mr_md)}>
                <Rectangle>
                    <div className={styles.rect}>
                        <div
                            className={cn(grid.w70, grid.colCenter, styles[p_.name], { [item[p_.name]]: over })}
                            style={{ animationDuration: `${p_.animationDuration}ms` }}
                        >
                            <img className={grid.w100} alt={''} src={noBroadcastImage} />
                        </div>
                    </div>
                </Rectangle>
            </div>
            <div>{translate(p_.name)}</div>
        </div>
    )
}

interface IAnimationSettingsProps {
    animation: any
    onUpdate: (data: { name: string; duration: number } | null, activeAnimationType: string) => void
    onClose: () => void
    onSave: () => void
}

const AnimationSettings = (p_: IAnimationSettingsProps) => {
    const [activeAnimationType, setActiveAnimationType] = useState<string>('in')

    const isActive = (viewName: string) => {
        const animationObj = p_.animation[activeAnimationType]

        if (viewName === 'withoutAnimation') {
            return !animationObj
        } else {
            return animationObj && animationObj.name === viewName
        }
    }
    const select = (viewName: string) => {
        const animationObj = p_.animation[activeAnimationType]

        if (viewName === 'withoutAnimation') {
            p_.onUpdate(null, activeAnimationType)
        } else {
            p_.onUpdate(
                {
                    name: viewName,
                    duration: animationObj ? animationObj.duration : 300,
                },
                activeAnimationType
            )
        }
    }

    const animationObj = p_.animation[activeAnimationType]

    return (
        <div className={styles.animationSettingsWrapper}>
            <div className={cn(styles.col, styles.col1)}>
                <div className={grid.mb_mdPlus}>{translate('animationType')}</div>
                {animationTypes.map((animationType, index) => (
                    <div
                        key={`animationType_${index}`}
                        className={cn(styles.animationTypeItem, {
                            [styles.animationItemActive]: animationType.id === activeAnimationType,
                        })}
                        onClick={() => setActiveAnimationType(animationType.id)}
                    >
                        {translate(animationType.id)}
                    </div>
                ))}
            </div>
            <div className={cn(styles.col, styles.col2)}>
                <div className={grid.mb_mdPlus}>{translate('animationView')}</div>
                {animationViews[activeAnimationType].map((animationView: any, index: number) => (
                    <AnimationView
                        key={`animationView_${index}`}
                        name={animationView.name}
                        onClick={() => {
                            select(animationView.name)
                        }}
                        isActive={isActive(animationView.name)}
                        animationDuration={animationObj ? animationObj.duration : 300}
                    />
                ))}
            </div>
            <div className={cn(styles.col, styles.col3, grid.colCenter)}>
                <div className={grid.mb_mdPlus}>{translate('animationSpeed')}</div>
                <div className={cn(grid.w70, grid.mb_mdPlus)}>
                    <div className={cn({ [item.hidden]: !animationObj })}>
                        <EditableText
                            placeholder={''}
                            mod={'greyFill'}
                            id={'duration'}
                            text={animationObj ? animationObj.duration : 0}
                            type="number"
                            onEndTyping={(duration) => {
                                p_.onUpdate(
                                    {
                                        name: animationObj.name,
                                        duration: Math.round(+duration),
                                    },
                                    activeAnimationType
                                )
                            }}
                            indentSize={'mini'}
                            rounded={'full_md'}
                            onUpdate={(duration, cb) => cb()}
                        />
                    </div>
                </div>
                <div className={cn(grid.rowCenter, grid.noWrap)}>
                    <Button indentSize="mini" className={cn(grid.mr_mini)} onClick={p_.onClose}>
                        {translate('cancel')}
                    </Button>
                    <Button indentSize="mini" mod={'fill'} onClick={p_.onSave}>
                        {translate('save')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default AnimationSettings
