import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { IStyles } from '../../../../theme'

const itemUsersStyles: IStyles = {
    wrapper() {
        return {
            [`&:hover .${this.checkWrapper}`]: {
                opacity: 1,
            },
        }
    },
    cell: {
        ...grid.fullWidth,
        ...item.pointer,
    },
    checkWrapper: {
        ...item.abs,
        ...item.transitionErase,
        top: grid.p_microPlus.padding,
        right: grid.p_micro.padding,
        zIndex: 10,
        opacity: 0,
    },
}

addStyles(itemUsersStyles)
export default itemUsersStyles
