import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Icon from 'blocks.simple/icon/icon'
import InputMask from 'react-input-mask'
import Datepicker from 'blocks.simple/datepicker/datepicker'
import EditTextMethods from './editText.local.methods'

import { cn } from 'ethcss'
import styles from './editText.jcss'
import { text as textStyles } from 'theme'
import item from 'blocks.simple/item/item.jcss'
import translate from 'core/translate'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { getThemeStyleValue } from 'theme/colors'
import moment from 'moment/moment'

export const EditTextPassword = (p_) => {
    const [isShow, setShow] = useState(false)
    return (
        <EditText
            {...p_}
            type={isShow ? 'text' : 'password'}
            icon={isShow ? 'viewHide' : 'view'}
            iconColor={getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable}
            iconTooltip={{
                title: isShow ? translate('hidePassword') : translate('showPassword'),
            }}
            onIconClick={() => setShow(!isShow)}
        />
    )
}

class EditText extends EditTextMethods {
    render() {
        const props = this.props
        const className = props.className || ''
        const s_ = this.state
        const {
            onEndTyping,
            onIconClick,
            defaultFocus,
            inputRef,
            containerClass,
            showIconOnHover,
            rounded,
            textSize,
            indentSize,
            iconColor,
            iconTooltip,
            dateValue,
            error,
            errorBorder,
            iconSize,
            mod,
            rows,
            cols,
            label,
            popperPlacement,
            max,
            valueType,
            labelColor,
            ...p_
        } = props

        error && (p_.icon = 'close')
        p_.type === 'date' && !p_.icon && (p_.icon = 'date')
        const wrapperClassName = cn(
            styles.input,
            className,
            {
                [styles.error]: error,
                [styles.error]: errorBorder,
                [styles.validationBorder]: p_.validationBorder,
            },
            styles[`input_type_${mod}`],
            textStyles[textSize],
            item[`indent_${indentSize}`],
            item[`rounded_${rounded}`]
        )

        const labelClassName = cn(styles.label, styles[`label_type_${mod}`], textStyles[textSize])
        const inputTypeCheck = () => {
            return valueType === 'screenshot'
        }
        return (
            <div
                className={cn(
                    p_.fullHeight ? styles.fullHeight : '',
                    { [styles.wrapper]: !containerClass },
                    containerClass,
                    {
                        [styles.hoverIconWrapper]: showIconOnHover,
                        [styles.date]: p_.type === 'date',
                    }
                )}
            >
                {label && (
                    <label
                        htmlFor={p_.id}
                        className={labelClassName}
                        style={{ color: labelColor ? labelColor : 'black' }}
                    >
                        {label}
                    </label>
                )}
                {!p_.mask && p_.type !== 'date' && (
                    <div style={{ height: '100%' }}>
                        <div className={cn(p_.fullHeight ? styles.fullHeight : '', styles.inputWrapper)}>
                            {p_.type === 'area' && (
                                <textarea
                                    {...p_}
                                    rows={rows}
                                    cols={cols}
                                    className={wrapperClassName}
                                    onChange={this.onChange}
                                    onFocus={this.onFocus}
                                    onBlur={this.onBlur}
                                    ref={(input) => (this.inputFocus = input)}
                                />
                            )}
                            {p_.type !== 'area' && (
                                <>
                                    {inputTypeCheck() && s_.minValueError && s_.maxValueError && (
                                        <div className={styles.paddingTextError}>
                                            {translate('screenshotMin') + ' ' + (max + 1)}
                                        </div>
                                    )}
                                    <input
                                        tabindex={p_.tabindex && p_.tabindex}
                                        {...p_}
                                        className={cn(
                                            item.ellipsisPure,
                                            inputTypeCheck() && s_.minValueError && styles.error_type_withBorder,
                                            inputTypeCheck() && s_.maxValueError && styles.error_type_withBorder,
                                            wrapperClassName
                                        )}
                                        onChange={inputTypeCheck() ? this.onChangeScreen : this.onChange}
                                        onFocus={this.onFocus}
                                        onBlur={this.onBlur}
                                        onWheel={p_.type === 'number' ? this.onWheel : undefined}
                                        ref={(input) => (this.inputFocus = input)}
                                    />
                                </>
                            )}
                            {p_.icon && (
                                <div className={styles.icon} onClick={this.onIconClick}>
                                    <Icon
                                        type={p_.icon}
                                        color={error ? 'error' : this.getIconColor(iconColor, mod)}
                                        size={error ? 11 : iconSize}
                                        tooltip={iconTooltip}
                                        containerClass={p_.iconClassName}
                                    />
                                </div>
                            )}
                        </div>
                        {error && (
                            <div className={styles.errorContainer}>
                                <span className={cn(styles.errorText, styles[`errorText_type_${mod}`])}>{error}</span>
                            </div>
                        )}
                    </div>
                )}
                {p_.mask && p_.type !== 'date' && (
                    <div>
                        <div className={styles.inputWrapper}>
                            <InputMask
                                {...p_}
                                className={wrapperClassName}
                                onChange={this.onChange}
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                inputRef={(input) => (this.inputFocus = input)}
                            />
                            {p_.icon && (
                                <div className={styles.icon} onClick={this.onIconClick}>
                                    <Icon
                                        type={p_.icon}
                                        color={this.getIconColor(iconColor, mod)}
                                        size={error ? 11 : iconSize}
                                    />
                                </div>
                            )}
                        </div>
                        {error && (
                            <div className={styles.errorContainer}>
                                <span className={cn(styles.errorText, styles[`errorText_type_${mod}`])}>{error}</span>
                            </div>
                        )}
                    </div>
                )}
                {p_.type === 'date' && (
                    <div>
                        <div className={styles.inputWrapper}>
                            <Datepicker
                                {...p_}
                                className={cn(wrapperClassName, styles.dateWrapper)}
                                onChange={this.onChange}
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                selected={
                                    dateValue ? (moment(dateValue).isValid() ? moment(dateValue).toDate() : null) : null
                                }
                                popperPlacement={popperPlacement}
                                datepickerRef={(input) => (this.datePicker = input)}
                            />
                            {p_.icon && (
                                <div
                                    className={cn(styles.icon, p_.disabled ? styles.disabled : '')}
                                    onClick={this.onIconClick}
                                >
                                    <Icon
                                        type={p_.icon}
                                        color={this.getIconColor(iconColor, mod)}
                                        size={error ? 11 : iconSize}
                                        disabled={p_.disabled}
                                    />
                                </div>
                            )}
                        </div>
                        {error && (
                            <div className={styles.errorContainer}>
                                <span className={cn(styles.errorText, styles[`errorText_type_${mod}`])}>{error}</span>
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

EditText.propTypes = {
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dateValue: PropTypes.object,
    placeholder: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    type: PropTypes.string,
    error: PropTypes.string,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    containerClass: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    mask: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    iconTooltip: PropTypes.object,
    textSize: PropTypes.string,
    mod: PropTypes.oneOf([
        'default',
        'white',
        'withBorder',
        'withShadow',
        'withLightBorder',
        'withBottomLine',
        'text',
        'inverseBorder',
    ]),
    rows: PropTypes.string,
    cols: PropTypes.string,
    indentSize: PropTypes.string,
    onClick: PropTypes.func,
    onIconClick: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    inputRef: PropTypes.func,
    rounded: PropTypes.string,
    defaultFocus: PropTypes.bool,
    showIconOnHover: PropTypes.bool,
    onEndTyping: PropTypes.func,
    errorBorder: PropTypes.bool,
    autoComplete: PropTypes.string,
}

EditText.defaultProps = {
    type: 'text',
    mod: 'default',
    textSize: 'normal',
    indentSize: 'normal',
    placeholder: '',
    iconSize: '20',
    rows: '3',
    popperPlacement: 'bottom-start',
    rounded: 'full_mini',
    onEndTyping: () => {},
    errorBorder: false,
    autoComplete: 'off',
    max: 1920,
    min: 5,
    valueType: '',
}

export default EditText
