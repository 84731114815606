import { colors } from 'theme'

export const defaultThemeStyles = {
    text: colors.white,
    textSecondary: colors.darkCloud,
    link: colors.cloud,
    linkText: colors.darkCloud,
    line: colors.white,
    lineSelected: colors.selected,
    inputFile: colors.cloud,
}
