import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { mediaCSS, textSizes } from 'theme/media'
import { IStyles } from '../../../theme'

const deviceSettingsContentStorage: IStyles = {
    title: {
        fontSize: textSizes.normal,
        ...grid.mb_md,
    },
    inputWrapper: {
        ...grid.w25,
        [mediaCSS.tab]: {
            ...grid.w50,
        },
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
}

addStyles(deviceSettingsContentStorage)
export default deviceSettingsContentStorage
