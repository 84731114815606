import React, { useState } from 'react'
import Tabs from 'blocks.simple/tabs/tabs'
import { AddDeviceByCode } from '../organisms/AddDeviceByCode/AddDeviceByCode-view'
import DisplaysAddVirtualDevice from '../__addVirtualDevice/displays__addVirtualDevice'
import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'
import translate from 'core/translate'
import { useOnMount } from 'core/hooks'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { useLocation } from 'react-router'

export default () => {
    const location = useLocation()
    const query = getURLSearchParamsByLocation(location)
    const [groups, setGroups] = useState<{ [index: string]: string; nestedKey: string }[]>([])
    const currentGroup = {
        id: parseInt(query.parentId, 10),
    }

    useOnMount(() => {
        api.send('getAvailableToCreateParentGroups', { nestedQ: false }, { hideLoader: true }).then(
            (availableFolders: any) => {
                const groups: { [index: string]: string; nestedKey: string }[] = helpers.addParentKeyIdForTree(
                    availableFolders,
                    'parentId'
                )
                setGroups(groups)
            }
        )
    })

    return (
        <Tabs>
            <AddDeviceByCode label={translate('addDeviceByCode')} tabid="add-device" />
            <DisplaysAddVirtualDevice
                label={translate('createVirtualDevice')}
                tabid="create-virtual-device"
                groups={groups}
                currentGroup={currentGroup}
            />
        </Tabs>
    )
}
