import { useState, useEffect } from 'react'

import { api } from 'core/api/ConnectionManager'

interface IData {
    usersInCompany?: number
    isLoaded: boolean
}

const initialState = {
    usersInCompany: 0,
    isLoaded: false,
}
let componentIsMounted: boolean = false
function DashboardUserMethods() {
    const [data, setData] = useState<IData>(initialState)

    useEffect(() => {
        componentIsMounted = true
        getDashboardUserStats()

        return () => {
            componentIsMounted = false
        }
    }, [])

    function getDashboardUserStats() {
        api.send('dashboardGetUserStats', {}, { showEmitError: false })
            .then((res: any) => {
                if (!componentIsMounted) {
                    return
                }

                setData({
                    usersInCompany: res.usersInCompany,
                    isLoaded: true,
                })
            })
            .catch(() => {
                setData({
                    isLoaded: true,
                })
            })
    }

    return {
        s_: {
            data,
        },
    }
}

export default DashboardUserMethods
