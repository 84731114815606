import React, { FunctionComponent, memo } from 'react'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import helpers from 'core/helpers'
import { cn } from 'ethcss'
import { styles } from './catalog__item_videoChat-styles'
import { ICatalogItemVideoChatComponent } from './catalog__item_videoChat-types'
import { colors } from 'theme/colors'
import Icon from 'blocks.simple/icon/icon'
import { videochatActions } from 'pages/videoChat/videoChat.state'
import { useDispatch } from 'react-redux'

const CatalogItemVideoChatComponent: FunctionComponent<ICatalogItemVideoChatComponent> = ({
    item,
    onDoubleClickObject,
    onSelectInfo,
    onDoubleClick,
    onSelectFolder,
}) => {
    const dispatch = useDispatch()
    const isOnline = item.status === 'online'
    const isGroup = item.type === 'group'
    const backgroundClassName = styles[`CatalogItemVideoChat__background_type_${item.status}`]

    const handleClick = (e: React.MouseEvent) => {
        onSelectInfo(e)

        if (helpers.isDblTouchTap(e)) {
            if (item.type !== 'group') {
                onDoubleClickObject(item)
                onDoubleClick(true)

                e.preventDefault()
                return
            }

            onSelectFolder(item)
        }
    }

    const handleContextMenu = (e: React.MouseEvent) => {
        if (helpers.isMobileDevice()) {
            e.preventDefault()
        }
    }

    const handleStartVideoCall = () => {
        dispatch(videochatActions.openVideoModalWithCall(item))
    }

    const handleOpenVideoModal = () => {
        dispatch(videochatActions.openVideoModal(item))
    }

    return (
        <div onClick={handleClick} onContextMenu={handleContextMenu}>
            {!isGroup && (
                <Rectangle
                    width={16}
                    height={9}
                    className={cn(styles.CatalogItemVideoChat__wrapper, backgroundClassName)}
                >
                    {isOnline ? (
                        <div className={styles.CatalogItemVideoChat__header}>
                            <Icon type={'videoWindow'} size={22} color={colors.white} onClick={handleOpenVideoModal} />
                        </div>
                    ) : null}

                    <div className={cn(styles.CatalogItemVideoChat__content)}>
                        <div className={styles.CatalogItemVideoChat__title}>{item.name}</div>
                    </div>
                    {isOnline ? (
                        <div className={styles.CatalogItemVideoChat__footer}>
                            <Icon type={'phone'} size={18} color={colors.white} onClick={handleStartVideoCall} />
                        </div>
                    ) : null}
                </Rectangle>
            )}
            {isGroup && (
                <Rectangle width={16} height={9} className={styles.CatalogItemVideoChat__groupWrapper}>
                    <div className={cn(styles.CatalogItemVideoChat__group, backgroundClassName)}>
                        <div className={cn(styles.CatalogItemVideoChat__content)}>
                            <div className={styles.CatalogItemVideoChat__title}>{item.name}</div>
                        </div>
                    </div>
                    <div className={styles.CatalogItemVideoChat__groupBackWrapper}>
                        <div
                            className={cn(
                                styles.CatalogItemVideoChat__groupBack,
                                styles.CatalogItemVideoChat__groupBack_second,
                                backgroundClassName
                            )}
                        />
                    </div>
                    <div className={styles.CatalogItemVideoChat__groupBackWrapper}>
                        <div
                            className={cn(
                                styles.CatalogItemVideoChat__groupBack,
                                styles.CatalogItemVideoChat__groupBack_first
                            )}
                        />
                    </div>
                </Rectangle>
            )}
        </div>
    )
}

export const CatalogItemVideoChat = memo(CatalogItemVideoChatComponent)
