import React, { useEffect, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import DashboardMethods from './dashboard.local.methods'
import Content from 'blocks.app/content/content'
import DashboardUser from './__user/dashboard__user'
import DashboardScoreboardDevices from './__scoreboardDevices/dashboard__scoreboardDevices'
import DashboardDevices from './__devices/dashboard__devices'
import DashboardSchedule from './__schedule/dashboard__schedule'
import DashboardNetwork from './__network/dashboard__network'
import DashboardMemory from './__memory/dashboard__memory'
import { DashboardSessions } from './__sessions/dashdoard__sessions'
import Button from 'blocks.simple/button/button'

import translate from 'core/translate'
import helpers from 'core/helpers'
import { isHorizontalMenu } from 'core/helpers/menu'

import styles from './dashboard.jcss'
import { Typography } from 'atoms/Typography'
import { checkAvailableModule } from '../../core/helpers/routes/routes'
import { cn } from 'ethcss'

type ItemRules = {
    order: number
    enableBorder: boolean
}

interface IDashboardComponents {
    appType?: string | unknown
    componentName: string
    rules?: ItemRules
}

const getDashboardComponent = ({ appType, componentName, rules }: IDashboardComponents) => {
    let component = null

    switch (componentName) {
        case 'DashboardDevices':
            component = {
                component: DashboardDevices,
                enabled: helpers.isAvailable({ key: 'digitalSignage', action: 'read' }),
            }
            break
        case 'DashboardScoreboardDevices':
            component = {
                component: DashboardScoreboardDevices,
                enabled: helpers.isAvailable({ key: 'scoreboard', action: 'read' }),
            }
            break
        case 'DashboardSchedule':
            component = {
                component: DashboardSchedule,
                enabled: helpers.isAvailable({ key: 'schedule', action: 'read' }),
            }
            break
        case 'DashboardUser':
            component = { component: DashboardUser, enabled: true }
            break
        case 'DashboardMemory':
            component = { component: DashboardMemory, enabled: true }
            break
        case 'DashboardNetwork':
            component = { component: DashboardNetwork, enabled: appType === 'personal' }
            break
        case 'DashboardSessions':
            component = { component: DashboardSessions, enabled: true }
    }

    if (component && component.enabled) {
        const Component = component.component

        return (
            <Component
                // @ts-ignore
                className={cn(
                    styles.item,
                    helpers.isAvailable({ key: 'scoreboard', action: 'read' }) ? styles.memoryShort : ''
                )}
                rules={rules}
            />
        )
    }

    return null
}

const getItemRules = () => {
    if (helpers.isAvailable({ key: 'scoreboard', action: 'read' })) {
        return {
            DashboardDevices: {
                order: 1,
            },
            DashboardScoreboardDevices: {
                order: 2,
            },
            DashboardSchedule: {
                order: 3,
            },
            DashboardMemory: {
                order: 4,
            },
            DashboardNetwork: {
                order: 5,
            },
            DashboardUser: {
                order: 6,
            },
            DashboardSessions: {
                order: 7,
            },
        }
    }

    return {
        DashboardDevices: {
            order: 1,
        },
        DashboardSchedule: {
            order: 2,
        },
        DashboardUser: {
            order: 3,
        },
        DashboardMemory: {
            order: 4,
        },
        DashboardNetwork: {
            order: 5,
        },
        DashboardSessions: {
            order: 6,
        },
    }
}

const Dashboard = () => {
    const appType = useSelector<RootStateOrAny>((state) => state.app.appType)
    const timer = DashboardMethods()
    const [itemRules, setItemRules] = useState<any>({})

    useEffect(() => {
        setItemRules(getItemRules())
    }, [])
    const isDocumentationShow =
        window.spconfig && window.spconfig.documentation_dashboard_url && !window.spconfig.hideDocumentationRef

    return (
        <Content key={timer.updateKey} title={translate('dashboard')} className={styles.content}>
            <div className={styles.dashboardWrapper}>
                <div className={cn(styles.leftBlock, { [styles.top]: isHorizontalMenu() })}>
                    {getDashboardComponent({
                        componentName: 'DashboardDevices',
                        rules: itemRules.DashboardDevices,
                    })}
                    {getDashboardComponent({
                        componentName: 'DashboardScoreboardDevices',
                        rules: itemRules.DashboardScoreboardDevices,
                    })}
                    {getDashboardComponent({ componentName: 'DashboardSchedule', rules: itemRules.DashboardSchedule })}
                    {getDashboardComponent({ componentName: 'DashboardUser', rules: itemRules.DashboardUser })}
                    {getDashboardComponent({ componentName: 'DashboardMemory', rules: itemRules.DashboardMemory })}
                    {getDashboardComponent({
                        appType,
                        componentName: 'DashboardNetwork',
                        rules: itemRules.DashboardNetwork,
                    })}
                    {getDashboardComponent({ componentName: 'DashboardSessions', rules: itemRules.DashboardSessions })}
                </div>
            </div>
            <div className={styles.footer}>
                {isDocumentationShow && (
                    <a
                        href={window.spconfig.documentation_dashboard_url}
                        className={styles.documentationLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {translate('documentation')}
                    </a>
                )}
                <Typography type="title" className={styles.footerText}>
                    {translate('autoUpdateOfDashboard')}: {timer.time}
                </Typography>
                <Button className={styles.refreshButton} onClick={timer.update} mod={'withAccent'}>
                    {translate('update')}
                </Button>
            </div>
        </Content>
    )
}

export default checkAvailableModule('dashboard') ? Dashboard : null
