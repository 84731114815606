import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { addStyles } from 'ethcss'
import { grid, IStyles, textSizes } from 'theme'

const MAX_FORM_WIDTH = 425

export const styles: IStyles = {
    NetworkConverterEdit__title: {
        paddingBottom: 24,
        fontSize: textSizes.md,
        fontWeight: 'bold',
    },
    NetworkConverterEdit__list: {
        paddingLeft: 12,
        '& li': {
            ...grid.flex,
            ...grid.row,
            ...grid.startJustify,
            paddingBottom: 14,
            '& span': {
                display: 'inline-block',
                width: 180,
            },
        },
    },
    NetworkConverterEdit__form: {
        maxWidth: MAX_FORM_WIDTH,
        width: '100%',
    },
    NetworkConverterEdit__label: {
        paddingBottom: 3,
    },
    NetworkConverterEdit__divider: {
        padding: 12,
    },
    NetworkConverterEdit__border: {
        marginTop: 24,
        marginBottom: 24,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    NetworkConverterEdit__info: {
        padding: `5px 0`,
        lineHeight: 1.3,
        fontSize: textSizes.mini,
    },
}

addStyles(styles)
