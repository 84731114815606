import React, { FC, useEffect, useState } from 'react'
import { styles } from './InstalledApplicationList-styles'
import {
    IInstalledApplicationListProps,
    IAppInfo,
    IGetInstalledApplicationListRequest,
    IGetInstalledApplicationListResponse,
    TGetInstalledApplicationListStatus,
} from './InstalledApplicationList-types'
import Card from 'blocks.simple/card/card'
import translate from 'core/translate'
import Icon from 'blocks.simple/icon/icon'
import { api } from 'core/api/ConnectionManager'
import LoaderLazy from 'blocks.app/loader/_lazy/loader__lazy'
import { Typography } from 'atoms/Typography'
import { CustomScroll } from 'atoms/CustomScroll'
import { useDispatch } from 'react-redux'
import { displaysActions } from 'pages/displays/displays.state'

export const InstalledApplicationList: FC<IInstalledApplicationListProps> = ({ device }) => {
    const dispatch = useDispatch()
    const [appList, setAppList] = useState<IAppInfo[]>([])
    const [status, setStatus] = useState<TGetInstalledApplicationListStatus>('idle')

    useEffect(() => {
        if (status !== 'idle') return

        if (device) {
            api.send<IGetInstalledApplicationListRequest, null>('getInstalledApplicationList', {
                digitalSignageId: device.id,
            })
                .then(() => setStatus('init'))
                .catch(() => setStatus('error'))
        }
    }, [status])

    useEffect(() => {
        if (status !== 'init') return

        const listenersIdList: string[] = []

        api.addObserver<IGetInstalledApplicationListResponse>(
            'onInstalledApplicationListSended',
            onAppListSended,
            listenersIdList
        )

        return () => {
            listenersIdList.forEach((id) => api.removeObserver(id))
        }
    }, [status])

    const isCurrentDevice = (id: number, deviceId: number) => id === deviceId

    const onAppListSended = (response: IGetInstalledApplicationListResponse) => {
        if (device) {
            if (!isCurrentDevice(response.digitalSignageId, device.id)) return

            setAppList(response.installedApplicationList)
            setStatus('success')
        }
    }

    const handleClose = () => dispatch(displaysActions.closeModal())

    const renderAppList = () => {
        return (
            <div className={styles.installedApplicationList__list}>
                {appList.map(({ name }) => {
                    return (
                        <Typography type="title" className={styles.installedApplicationList__item} key={name}>
                            {name}
                        </Typography>
                    )
                })}
            </div>
        )
    }

    const renderLoader = () => {
        return (
            <div className={styles.installedApplicationList__loader}>
                <LoaderLazy active />
            </div>
        )
    }

    const renderError = () => {
        return (
            <Typography type="text" className={styles.installedApplicationList__error}>
                {translate('installedApplicationListError')}
            </Typography>
        )
    }

    const renderContent = () => {
        switch (status) {
            case 'success':
                return renderAppList()
            case 'error':
                return renderError()
            default:
                return renderLoader()
        }
    }

    const getTitle = () => translate('installedApplicationListTitle')

    return (
        <div className={styles.installedApplicationList}>
            <Card
                title={getTitle()}
                titleClassName={styles.installedApplicationList__title}
                className={styles.installedApplicationList__card}
                icon={{
                    type: 'display',
                    size: 20,
                    default: true,
                }}
            >
                <div className={styles.installedApplicationList__body}>
                    <div className={styles.installedApplicationList__close} onClick={handleClose}>
                        <Icon type="close" size="20" />
                    </div>
                    <CustomScroll classNames={styles.installedApplicationList__content}>{renderContent()}</CustomScroll>
                </div>
            </Card>
        </div>
    )
}
