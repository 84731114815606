import React, { useEffect, useState } from 'react'
import { api } from '../../../core/api/ConnectionManager'
import styles from './DsLogMinitor.jcss'
import translate from '../../../core/translate'

const logsLimit = 2000

export const DSLogMonitor = ({
    options,
    download,
    actionScroll,
}: {
    options: any
    download: boolean
    actionScroll: () => void
}) => {
    const [logs, setLogs] = useState<string[]>([])
    const [monitoringStarted, setMonitoringStarted] = useState<boolean>(false)
    const [status, setStatus] = useState<string>(options.status)

    const onReceiveLog = (data: any) => {
        if (data && data.logs) {
            if (data.digitalSignageId === options.digitalSignageId) {
                setLogs((prevState) => {
                    const logData = [...prevState, ...data.logs]
                    return logData.length > logsLimit ? logData.slice(-logsLimit) : logData
                })
            }
        }
    }
    const startLogMonitor = (digitalSignageId: number) => {
        api.send('startOnlineMonitoring', { digitalSignageId })
        setMonitoringStarted(true)
    }
    const stopLogMonitor = (digitalSignageId: number) => {
        api.send('stopOnlineMonitoring', { digitalSignageId })
    }
    const downloadLogToFile = (filename: string, text: string) => {
        const element = document.createElement('a')
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
        element.setAttribute('download', filename)
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
    }
    const onMonitoringStop = () => {
        setMonitoringStarted(false)
    }
    const onDisplayUpdated = (data: any) => {
        if (data.id === options.digitalSignageId) {
            if (!monitoringStarted) {
                if (data.status === 'online') {
                    startLogMonitor(options.digitalSignageId)
                }
            }

            setStatus(data.status ? data.status : 'offline')
        }
    }

    useEffect(() => {
        actionScroll()
    }, [logs])
    useEffect(() => {
        const listenersId: string[] = []
        api.addObserver('receiveOnlineMonitoringLogs', onReceiveLog, listenersId)

        return () => listenersId.forEach((id) => api.removeObserver(id))
    }, [])
    useEffect(() => {
        startLogMonitor(options.digitalSignageId)

        return () => stopLogMonitor(options.digitalSignageId)
    }, [])
    useEffect(() => {
        if (download) {
            if (!logs.length) return

            let log: string = ''
            logs.forEach((element: string) => (log += element + '\n'))

            if (log) {
                downloadLogToFile('logs_' + options.name, log)
            }
        }
    }, [download])

    useEffect(() => {
        const listenersId: string[] = []
        api.addObserver('onlineMonitoringStopped', onMonitoringStop, listenersId)
        api.addObserver('displayUpdated', onDisplayUpdated, listenersId)

        return () => {
            listenersId.forEach((id) => api.removeObserver(id))
        }
    }, [status, monitoringStarted])

    return (
        <>
            <div className={styles.wrapper}>
                {logs.map((log, index) => (
                    <div key={index} className={styles.log}>
                        {log}
                    </div>
                ))}
            </div>
            <div className={styles.footer}>
                <div>
                    {translate('device')}: <b>{translate(status).toLowerCase()}</b>
                </div>
                <div>
                    {translate('monitoring')}: <b>{monitoringStarted ? translate('started') : translate('stopped')}</b>
                </div>
            </div>
        </>
    )
}
