import React, { useState, Fragment, useRef } from 'react'
import ReactDOM from 'react-dom'
import Tippy from 'blocks.simple/tippy/tippy'
import { cn } from 'ethcss'
import styles from './dotTooltip.jcss'

const DotTooltip = (p_: {
    tooltip: {
        title?: string
        arrow?: boolean
        position?: string
        html?: string
        disabled?: boolean
        followCursor?: boolean
    }
    containerClass?: {}
    id?: number | string
    portalId?: string
    children?: any
}) => {
    const [originalWidth, setOriginalWidth] = useState(0)
    const [contentWidth, setContentWidth] = useState(0)
    const originalWrapperRef = useRef<any>(null)
    const hiddenWrapperRef = useRef<any>(null)

    function getChildrenWithRef(children: HTMLCollection, ref: any) {
        return React.Children.map(children, (child: any) => {
            return React.cloneElement(child, {
                ref,
                onMouseEnter,
            })
        })
    }

    function onMouseEnter() {
        const originalRect = originalWrapperRef.current.getClientRects()[0]
        const hiddenRect = hiddenWrapperRef.current.getClientRects()[0]

        if (!hiddenRect) {
            return
        }

        setOriginalWidth(hiddenRect.width)
        setContentWidth(originalRect.width)
    }

    const isShowTooltip = contentWidth < originalWidth && !p_.tooltip.disabled

    let content = [
        <div className={cn(p_.containerClass)} key={`originalContent`}>
            {p_.children ? getChildrenWithRef(p_.children, originalWrapperRef) : null}
        </div>,
    ]

    if (isShowTooltip) {
        content = [
            <Tippy
                key={`originalDotTooltipWrapper_${p_.id}`}
                className={cn(p_.containerClass)}
                style={{ display: 'block' }}
                {...p_.tooltip}
            >
                {p_.children ? getChildrenWithRef(p_.children, originalWrapperRef) : null}
            </Tippy>,
        ]
    }

    const hiddenOriginalTextContent = (
        <div key={`hiddenDotTooltipWrapper_${p_.id}`} ref={hiddenWrapperRef} className={styles.hidden}>
            {p_.children}
        </div>
    )

    const element = p_.portalId ? document.getElementById(p_.portalId) : null

    if (element) {
        content.push(ReactDOM.createPortal(hiddenOriginalTextContent, element))
    } else {
        content.push(hiddenOriginalTextContent)
    }

    return <Fragment>{content}</Fragment>
}

DotTooltip.defaultProps = {
    tooltip: {
        disabled: true,
    },
    portalId: null,
}

export default DotTooltip
