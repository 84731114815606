import React, { FC } from 'react'
import { StatusProps } from './Status-types'

import { statusStyles } from './Status-styles'
import { cn } from 'ethcss'
import { RootStateOrAny, useSelector } from 'react-redux'

const Status: FC<StatusProps> = ({ status, children, description, disable, className, marker }) => {
    const isMobile = useSelector<RootStateOrAny>((state) => state.app.isMobile)

    return (
        <div className={cn(className, statusStyles.status, { [statusStyles.disable]: disable })}>
            <div className={statusStyles.main}>
                {marker && <span className={statusStyles.marker}>{marker}</span>}
                <span className={statusStyles.statusText}>{children}</span>
            </div>
            {description && !isMobile && <div>{description}</div>}
        </div>
    )
}

export { Status }
