import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { mediaCSS } from 'blocks.app/config'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { IStyles } from '../../theme'

const catalogStyles: IStyles = {
    wrapper: {
        ...grid.space,
        ...grid.col,
        ...item.overNone,
    },
    advertisingHeader_x: {
        top: '30px !important',
        paddingLeft: '0px  !important',
    },
    wrapperAdvertisingHeader_x: {
        marginTop: '20px !important',
    },
    videoChatHeader_x: {
        top: '60px !important',
    },
    wrapperScroll: {
        ...item.overNone,
        ...item.overY,
        '-webkit-overflow-scrolling': 'touch',
    },
    wrapperScrollOverflowMinHeight: {
        minHeight: '30vh',
    },
    indentsListWrapper: {
        ...grid.mt_md,
    },
    indentsListWrapper_x: {
        marginTop: '36px',
    },
    listWrapper: {
        borderRadius: '0 0 5px 5px',
        background: `var(--var-module-instancesCatalog-component-listWrapper-style-background, ${defaultThemeStyles.background})`,
        ...grid.fullWidth,
    },

    listWrapperShortSm: {
        height: 'calc(100vh - 272px)',

        [mediaCSS.laptop]: {
            height: 'calc(100vh - 320px)',

            [mediaCSS.tab]: {
                height: 'auto',
            },
        },
    },
    listWrapperShortMd: {
        height: 'calc(100vh - 348px)',

        [mediaCSS.laptop]: {
            height: 'calc(100vh - 390px)',

            [mediaCSS.tab]: {
                height: 'auto',
            },
        },
    },
    instancesCatalogHeader_x: {
        paddingLeft: '0px !important',
        paddingRight: '20px !important',
        top: '60px !important',
    },
    fixedHeader: {
        ...grid.p_mdPlus,
        ...item.transitionErase,
        position: 'fixed',
        top: 0,
        paddingBottom: 0,
        zIndex: 1000,
        backgroundColor: `var(--var-module-app-component-app-style-background, ${defaultThemeStyles.background})`,
        [mediaCSS.tab]: {
            left: `0 !important`,
            right: `0 !important`,
            top: 44,
        },
        [mediaCSS.tabMini]: {
            ...grid.p_mini,
        },
    },
    fixedHeader_x: {
        top: '65px',
        paddingBottom: '21px',
        [mediaCSS.tab]: {
            top: 0,
        },
    },
    fixedHeader_width_x_type_1: {
        width: 'calc(85% - 22px)',
        [mediaCSS.tab]: {
            width: '100%',
        },
    },
    fixedHeader_width_x_type_2: {
        width: 'calc(100% - 24px)',
        [mediaCSS.tab]: {
            width: '100%',
        },
    },
    noBg: {
        background: 'none !important',
    },
    hotKeysWrapper: {
        '&:focus': {
            outline: 'none',
        },
    },
    marginEmptyList: {
        marginTop: '320px',
        marginBottom: '320px',
    },
    marginEmptyListRightToolBar: {
        marginTop: '10% !important',
    },
    content_padding_top_x_draftDevices: {
        paddingTop: '35px !important',
        paddingLeft: '36px  !important',
    },
    headerTopAndLeftPadding_x: {
        paddingTop: '35px !important',
    },
}

addStyles(catalogStyles)
export default catalogStyles
