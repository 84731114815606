import { addStyles } from 'ethcss'
import { indents } from '../media'

const flex = {
    display: 'flex',
}

const row = {
    ...flex,
    msFlexDirection: 'row',
    WebkitFlexDirection: 'row',
    WebkitBoxOrient: 'horizontal',
    WebkitBoxDirection: 'normal',
    flexDirection: 'row',
    WebkitFlexWrap: 'wrap',
    flexWrap: 'wrap',
    MozFlexWrap: 'wrap',
}
const staticRow = {
    ...flex,
    '-ms-flex-direction': 'row',
    '-webkit-flex-direction': 'row',
    '-webkit-box-orient': 'horizontal',
    '-webkit-box-direction': 'normal',
    'flex-direction': 'row',
}
const col = {
    ...flex,
    '-ms-flex-direction': 'column',
    '-webkit-flex-direction': 'column',
    '-webkit-box-orient': 'vertical',
    '-webkit-box-direction': 'normal',
    'flex-direction': 'column',
}
const center = {
    '-ms-flex-pack': 'center',
    '-webkit-justify-content': 'center',
    '-webkit-box-pack': 'center',
    'justify-content': 'center',
}
const normalCenter = {
    '-ms-flex-align': 'center',
    '-webkit-align-items': 'center',
    '-webkit-box-align': 'center',
    'align-items': 'center',
}

const normalEnd = {
    '-ms-flex-align': 'flex-end',
    '-webkit-align-items': 'flex-end',
    '-webkit-box-align': 'flex-end',
    'align-items': 'flex-end',
}

const grid = {
    hspace() {
        return {
            ...this.col,
            ...this.space,
        }
    },
    full: {
        width: '100%',
        height: '100%',
    },
    fullWidth: {
        width: '100%',
    },
    fullHeight: {
        height: '100%',
    },
    flex: flex,
    stretch: {
        '-ms-flex': '0 1 100%',
        '-webkit-flex': '0 1 100%',
        '-webkit-box-flex': '0 1 100%',
        flex: '0 1 100%',
    },
    space: {
        '-ms-flex': '1 1 0.000000001px',
        '-webkit-flex': '1',
        '-webkit-box-flex': '1',
        flex: '1',
    },
    auto: {
        '-ms-flex': '1 0 auto',
        '-webkit-flex': '1 0 auto',
        '-webkit-box-flex': '1 0 auto',
        flex: '1 0 auto',
    },
    spaceAround: {
        justifyContent: 'space-around',
    },
    noSpace: {
        '-ms-flex': 'none',
        '-webkit-flex': 'none',
        '-webkit-box-flex': 'none',
        flex: 'none',
    },
    wrap: {
        flexWrap: 'wrap!important',
    },
    noWrap: {
        flexWrap: 'nowrap!important',
    },
    row: row,
    staticRow: staticRow,
    col: col,
    justify: {
        '-ms-flex-pack': 'justify',
        '-webkit-justify-content': 'space-between',
        '-webkit-box-pack': 'justify',
        'justify-content': 'space-between',
    },
    baseCenter: {
        '-ms-flex-align': 'baseline',
        '-webkit-align-items': 'baseline',
        '-webkit-box-align': 'baseline',
        'align-items': 'baseliner',
    },
    center: center,
    normalCenter: normalCenter,
    normalEnd: normalEnd,
    rowCenter: {
        ...row,
        ...center,
        ...normalCenter,
    },
    flexStart: {
        'align-self': 'flex-start',
    },
    flexEnd: {
        'align-self': 'flex-end',
    },
    colCenter: {
        ...col,
        ...center,
        ...normalCenter,
    },
    startJustify: {
        '-ms-flex-pack': 'start',
        '-webkit-justify-content': 'flex-start',
        'justify-content': 'flex-start',
    },
    endJustify: {
        '-ms-flex-pack': 'end',
        '-webkit-justify-content': 'flex-end',
        'justify-content': 'flex-end',
    },
    marginCenter: {
        margin: '0 auto',
    },
    iBlock: {
        display: 'inline-block',
    },
    justifyEnd: {
        justifyContent: 'flex-end',
    },
    spaceWidth() {
        return {
            ...this.space,
            width: 0,
            overflow: 'hidden',
        }
    },
    spaceHeight() {
        return {
            ...this.space,
            height: 0,
            overflow: 'hidden',
        }
    },
    ...(() => {
        let widths = {
            wAuto: {
                width: 'auto',
            },
        }

        for (let i = 0; i <= 20; i++) {
            widths[`w${i * 5}`] = { width: `${i * 5}%` }
        }

        return widths
    })(),
    ...(() => {
        let cols = {}

        for (let i = 1; i <= 12; i++) {
            cols[`col${i}`] = { width: `${100 / i}%` }
        }

        return cols
    })(),
    ...(() => {
        const sides = [
            { shortName: 't', name: 'top', capitalizeName: 'Top' },
            { shortName: 'r', name: 'right', capitalizeName: 'Right' },
            { shortName: 'b', name: 'bottom', capitalizeName: 'Bottom' },
            { shortName: 'l', name: 'left', capitalizeName: 'Left' },
        ]
        let indentsStyles = {}
        const indentsFull = { auto: 'auto', ...indents }

        for (let name in indentsFull) {
            if (indentsFull.hasOwnProperty(name)) {
                const indent = indentsFull[name]

                indentsStyles[`m_${name}`] = { margin: indent }
                indentsStyles[`p_${name}`] = { padding: indent }

                sides.forEach((side) => {
                    indentsStyles[`m${side.shortName}_${name}`] = {
                        [`margin${side.capitalizeName}`]: indent,
                    }

                    indentsStyles[`p${side.shortName}_${name}`] = {
                        [`padding${side.capitalizeName}`]: indent,
                    }
                })
            }
        }

        return indentsStyles
    })(),
}

addStyles(grid)
export default grid
