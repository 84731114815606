import React from 'react'
import moment from 'moment'
import merge from 'merge'
import { api } from 'core/api/ConnectionManager'
import displaysHelpers from 'core/helpers/_digitalSignages'
import translate from 'core/translate'
import deepEqual from 'fast-deep-equal'
import helpers from 'core/helpers'

interface IState {
    settings: any
    selectedPlatform: { id: string; name: string } | null
    availablePlatforms: []
    applyRecursively: boolean
    modalState: boolean
    error: string | null
    isMultipleDevicesSettings: boolean
    name?: string
    contentHeight?: number
}

export const AVAILABLE_PLATFORMS: any = [
    'windows',
    'linux',
    'android',
    'sssp',
    'sssp_new',
    'sssp_old',
    'webos',
    'webos_new',
    'webos_old',
    'brightsign',
    'raspberry',
]

const makeTimersLoop = () => {
    const on = []
    const off = []
    const obj = {
        setup: 'off',
        time: moment().format('HH:mm:ss'),
        repeatDays: [],
    }

    for (let i = 1; i <= 7; i++) {
        on.push({ ...obj, volume: 75 })
        off.push({ ...obj })
    }

    return { on, off }
}

const makeLedTimersLoop = () => {
    const on = []
    const off = []
    const obj = {
        setup: 'off',
        time: moment().format('HH:mm:ss'),
        repeatDays: [],
    }

    for (let i = 1; i <= 7; i++) {
        on.push({ ...obj, comPortNumber: '1' })
        off.push({ ...obj, comPortNumber: '1' })
    }

    return { on, off }
}

const defaultSettings = (settings?: any) => {
    const data: { [index: string]: any } = {}

    if (helpers.isAvailable({ key: 'megafonSettings', action: 'update' })) {
        data.megafon = {
            shopCode: '',
            tabletMode: 'consultant',
            inactivityTimeout: 10000,
            prefixWebsite: '',
            batteryLevelForShutdown: 0,
        }
    }
    if (helpers.isAvailable({ key: 'modBus', action: 'update' })) {
        data.modBus = {
            enabledQ: false,
            deviceIP: '',
            TCPPort: '502',
            registers: '20',
            scanRate: '1000',
            startAddr: '0',
            registersLength: '2',
            floorDelta: '0',
            presenceZeroFloor: false,
        }
    }
    if (settings?.deviceSettings?.contentDownloadType?.values) {
        data.contentDownloadType = settings.deviceSettings.contentDownloadType.defaultValue
        data.netDiskAddress = ''
        data.netDiskDefaultMaster = false
    }

    return {
        onOffTimers: makeTimersLoop(),
        onOffDisplayTimers: makeLedTimersLoop(),
        pinCode: '',
        displaySettings: {},
        proxySettings: {},
        speedLimit: null,
        rebootTimers: [
            {
                setup: 'off',
                rebootType: 'restartApp',
                time: '14:24:56',
                repeatDays: [],
            },
        ],
        resizeImageQ: false,
        resizeImageParams: null,
        broadcastAutoScreenshot: false,
        remoteControlEnabled: true,
        controlsMode: 'kiosk',
        audioOutput: 'speakers',
        syncContent: false,
        browserSettings: {
            openBrowser: 'internal',
        },
        hideSystemNotifications: false,
        contentStoragePath: '',
        chequeFilePath: '',
        pictureModeList: [],
        pictureMode: null,
        videoPlayerSettings: {
            hardwareAcceleration: 'off',
            options: [''],
        },
        audioPlayerSettings: {
            audioPlayerEnabled: 'off',
            showVolumeHandle: 'on',
        },
        webEngineSettings: {
            options: [''],
        },
        logLevel: 'Info',
        brightnessTimers: {
            enabled: false,
            timers: [
                {
                    time: moment().format('HH:mm:ss'),
                    brightness: 100,
                },
                {
                    time: moment().format('HH:mm:ss'),
                    brightness: 100,
                },
            ],
        },
        brightness: [
            {
                startTime: moment().format('HH:mm:ss'),
                endTime: moment().format('HH:mm:ss'),
                level: 100,
            },
        ],
        ...data,
    }
}

const getPlatformName = (platform: string) => {
    const ssspNew = ['SSSP 4', 'SSSP 5', 'SSSP 6', 'SSSP 7', 'SSSP 10']
    const ssspOld = ['SSSP 2', 'SSSP 3']
    const webosNew = ['WEBOS 3.2', 'WEBOS 4', 'WEBOS 4.1', 'WEBOS 6']
    const webosOld = ['WEBOS 2', 'WEBOS 3']
    const raspberry = ['RASPBERRY_V3 x32', 'RASPBERRY_V3 x64', 'RASPBERRY_V4 x32', 'RASPBERRY_V4 x64']

    if (ssspNew.includes(platform)) return 'sssp_new'

    if (ssspOld.includes(platform)) return 'sssp_old'

    if (webosNew.includes(platform)) return 'webos_new'

    if (webosOld.includes(platform)) return 'webos_old'

    if (raspberry.includes(platform)) return 'raspberry'

    return displaysHelpers.getPlatformName(platform)
}

const getBrightnessSettings = (brightness: { startTime: string; endTime: string }[]) =>
    brightness.map((timer) => {
        const now = moment().format('YYYY-MM-DD')

        timer.startTime = moment(`${now} ${timer.startTime}`).format('HH:mm:ss')
        timer.endTime = moment(`${now} ${timer.endTime}`).format('HH:mm:ss')

        return timer
    })

class DeviceSettingsMethods extends React.Component<any> {
    state: IState = {
        settings: defaultSettings(this.props.deviceSettings),
        selectedPlatform: null,
        availablePlatforms: [],
        applyRecursively: false,
        modalState: false,
        error: null,
        isMultipleDevicesSettings: false,
    }

    componentDidMount() {
        const p_ = this.props
        this.setupSettings(p_)
    }

    componentDidUpdate(p_: {}, prevState: IState) {
        const props: any = this.props
        const s_ = this.state
        if (props.onRequested && !deepEqual(props, p_)) {
            this.setupSettings(props)
        }

        if (prevState.settings.resizeImageQ !== s_.settings.resizeImageQ && !s_.settings.resizeImageQ) {
            this.setState({
                settings: {
                    ...s_.settings,
                    resizeImageParams: null,
                },
            })
        }
    }

    setupSettings = (p_: any) => {
        this.setState({ isMultipleDevicesSettings: Array.isArray(p_.options?.devices) })

        this.getSettings(p_.settings, 1, p_.type)
        if (p_.type === 'group') {
            this.getPlatforms()
        }
    }
    getSettings = (settings: any, platformId: number, type?: string) => {
        const p_ = this.props
        if (!type) {
            type = p_.type
        }

        if (p_.options?.devices) {
            const platforms = p_.options.devices.map((device: { platformId: string; platform: string }) => ({
                id: device.platformId,
                name: device.platform,
            }))

            this.setPlatformList(platforms)
            return
        }

        if (type === 'group') {
            const initSettings = defaultSettings(p_.deviceSettings)

            if (settings.length) {
                const currentPlatform = settings.find((item: { platformId: number }) => item.platformId === platformId)
                if (currentPlatform) {
                    let groupSettings = currentPlatform.defaultSettings.settings

                    groupSettings = merge.recursive(true, initSettings, groupSettings)
                    this.setState({ settings: groupSettings })
                } else {
                    this.setState({ settings: initSettings })
                }
            } else {
                this.setState({ settings: initSettings })
            }
        } else {
            if (settings && Object.keys(settings).length > 0) {
                this.mergeOverwriteTimers(merge.recursive(true, this.state.settings, settings))
            }
        }
    }
    mergeOverwriteTimers = (settings: any) => {
        const s_ = this.state
        const initTimers = s_.settings.onOffTimers
        const newTimers = settings.onOffTimers
        const timersType = ['on', 'off']

        if (newTimers) {
            timersType.forEach((type) => {
                initTimers[type].forEach((onTimer: string, index: number) => {
                    if (!newTimers[type][index]) {
                        newTimers[type][index] = onTimer
                    }
                })
            })
        }

        const initDisplayTimers = s_.settings.onOffDisplayTimers
        const newDisplayTimers = settings.onOffDisplayTimers
        const timersDisplayType = ['on', 'off']

        if (newDisplayTimers) {
            timersDisplayType.forEach((type) => {
                initDisplayTimers[type].forEach((onTimer: string, index: number) => {
                    if (!newDisplayTimers[type][index]) {
                        newDisplayTimers[type][index] = onTimer
                    }
                })
            })
        }

        this.setState({ settings })
    }

    onSelectPlatform = (selectedPlatform: { id: number; name: string }) => {
        const p_ = this.props
        const s_ = this.state
        const data: any = {
            selectedPlatform,
        }

        if (!s_.isMultipleDevicesSettings) {
            this.getSettings(p_.settings, selectedPlatform.id)
        } else {
            data.settings = defaultSettings()
        }

        this.setState(data)
    }

    onChangeSettings = (value: string, field: string) => {
        const s_ = this.state
        const settings = helpers.deepCopy(s_.settings)
        settings[field] = value

        this.setState({ settings })
    }

    onCloseAcceptModal = () => {
        const s_ = this.state

        if (!s_.modalState) {
            return
        }

        this.setState({ modalState: false })
    }

    getDeviceSettingsFromCompany = () => {
        const { deviceSettings: settings } = this.props

        if (settings.deviceSettings?.contentDownloadType?.values) {
            return settings.deviceSettings.contentDownloadType.values
        }

        return null
    }
    getApplyRecursivelyLabel = () => {
        let platform = this.getPlatform()

        if (platform) {
            if (platform === 'sssp_new') {
                platform = 'SSSP 4/5/6/7/10'
            }

            if (platform === 'sssp_old') {
                platform = 'SSSP 2/3'
            }

            if (platform === 'webos_new') {
                platform = 'WEBOS 4/6'
            }

            if (platform === 'webos_old') {
                platform = 'WEBOS 2/3'
            }

            return `${translate('applyGroupSettingsDisplays')} ${platform.toUpperCase()}`
        }
    }
    getPlatform = () => {
        const p_ = this.props
        const { selectedPlatform }: any = this.state

        let platformName = selectedPlatform ? selectedPlatform.name : p_.platform

        return getPlatformName(platformName)
    }
    getBrightsignSettings = () => {
        const s_ = this.state
        const data = helpers.deepCopy(s_.settings)
        const settings: { [index: string]: any } = {
            rebootTimers: data.rebootTimers,
            logLevel: data.logLevel,
            syncContent: data.syncContent,
            resizeImageQ: data.resizeImageQ,
            resizeImageParams:
                s_.settings.resizeImageQ && !s_.settings.resizeImageParams
                    ? {
                          backgroundColor: '#00000000',
                          mode: 'inside',
                          preserveAspectRatioQ: true,
                      }
                    : s_.settings.resizeImageParams,
        }

        if (data.hasOwnProperty('autoScreenshot')) {
            settings.autoScreenshot = data.autoScreenshot
        }

        return settings
    }
    getFormattedProxy = (proxy: { enabled: boolean }) => {
        if (proxy) {
            if (proxy.enabled) {
                return proxy
            }

            return {
                ip: '',
                port: '',
                login: '',
                enabled: false,
                password: '',
                connectionType: '',
            }
        }

        return {}
    }
    getWebOsSettings = () => {
        const s_ = this.state
        const data = helpers.deepCopy(s_.settings)
        const settings: { [index: string]: any } = {
            pinCode: data.pinCode,
            onOffTimers: data.onOffTimers,
            remoteControlEnabled: data.remoteControlEnabled,
            logLevel: data.logLevel,
            syncContent: data.syncContent,
            rebootTimers: data.rebootTimers,
            pictureMode: data.pictureMode || null,
            contentDownloadType: data.contentDownloadType || 'server',
            brightness: getBrightnessSettings(data.brightness),
            audioOutput: data.audioOutput,
            ntpSettings: data.ntpSettings,
            proxySettings: this.getFormattedProxy(data.proxySettings),
            controlsMode: data.controlsMode,
            exhibitionModeEnabledQ: data.exhibitionModeEnabledQ,
            digitalSignageExhibitionIndex: data.digitalSignageExhibitionIndex,
            resizeImageQ: data.resizeImageQ,
            resizeImageParams:
                s_.settings.resizeImageQ && !s_.settings.resizeImageParams
                    ? {
                          backgroundColor: '#00000000',
                          mode: 'inside',
                          preserveAspectRatioQ: true,
                      }
                    : s_.settings.resizeImageParams,
            onOffDisplayTimers: data.onOffDisplayTimers,
            enableIPTV: data.enableIPTV,
        }

        if (data.hasOwnProperty('autoScreenshot')) {
            settings.autoScreenshot = data.autoScreenshot
        }

        settings.onOffTimers.on.forEach((timer: { volume: number }) => {
            delete timer.volume
        })

        return settings
    }
    getAndroidSettings = () => {
        const s_ = this.state
        const data = helpers.deepCopy(s_.settings)
        const settings: { [index: string]: any } = {
            onOffTimers: data.onOffTimers,
            controlsMode: data.controlsMode,
            remoteControlEnabled: data.remoteControlEnabled,
            syncContent: data.syncContent,
            logLevel: data.logLevel,
            rebootTimers: data.rebootTimers,
            brightness: getBrightnessSettings(data.brightness),
            resizeImageQ: data.resizeImageQ,
            resizeImageParams:
                s_.settings.resizeImageQ && !s_.settings.resizeImageParams
                    ? {
                          backgroundColor: '#00000000',
                          mode: 'inside',
                          preserveAspectRatioQ: true,
                      }
                    : s_.settings.resizeImageParams,
            displaySettings: data.displaySettings,
            enableIPTV: data.enableIPTV,
            audioPlayerSettings: {
                audioPlayerEnabled: data.audioPlayerSettings.audioPlayerEnabled,
                showVolumeHandle: data.audioPlayerSettings.showVolumeHandle,
            },
            browserSettings: data.browserSettings,
        }

        if (data.megafon) {
            settings.megafon = data.megafon
            if (!settings.megafon.inactivityTimeout) {
                settings.megafon.inactivityTimeout = 0
            }
        }

        if (data.hasOwnProperty('autoScreenshot')) {
            settings.autoScreenshot = data.autoScreenshot
        }

        return settings
    }
    getSsspSettings = () => {
        const s_ = this.state

        return {
            rebootTimers: s_.settings.rebootTimers,
            onOffTimers: s_.settings.onOffTimers,
            broadcastAutoScreenshot: s_.settings.broadcastAutoScreenshot,
            brightnessTimers: s_.settings.brightnessTimers,
            remoteControlEnabled: s_.settings.remoteControlEnabled,
            audioOutput: s_.settings.audioOutput,
            syncContent: s_.settings.syncContent,
            logLevel: s_.settings.logLevel,
            hideSystemNotifications: s_.settings.hideSystemNotifications,
            proxySettings: this.getFormattedProxy(s_.settings.proxySettings),
            ntpSettings: s_.settings.ntpSettings,
            controlsMode: s_.settings.controlsMode,
            resizeImageQ: s_.settings.resizeImageQ,
            resizeImageParams:
                s_.settings.resizeImageQ && !s_.settings.resizeImageParams
                    ? {
                          backgroundColor: '#00000000',
                          mode: 'inside',
                          preserveAspectRatioQ: true,
                      }
                    : s_.settings.resizeImageParams,
            onOffDisplayTimers: s_.settings.onOffDisplayTimers,
            enableIPTV: s_.settings.enableIPTV,
        }
    }
    getPlatforms = () => {
        api.send('getPlatforms', {}).then((platforms: any) => {
            this.setPlatformList(platforms)
        })
    }

    setPlatformList = (platforms: any[]) => {
        let formattedPlatforms: { id: string; name: any }[] = []
        platforms.forEach((platform) => {
            platform.name = getPlatformName(platform.name)

            if (AVAILABLE_PLATFORMS.includes(platform.name)) {
                const platformExist = formattedPlatforms.filter((formatPlat) => {
                    const item = formatPlat.name.toLowerCase()
                    const repeatPlatforms = ['sssp_new', 'sssp_old', 'windows', 'linux', 'webos_new', 'webos_old']

                    if (repeatPlatforms.includes(item)) {
                        return false
                    }
                    return item === platform.name
                }).length

                if (!platformExist) {
                    formattedPlatforms.push(platform)
                }
            }
        })

        this.setState({
            availablePlatforms: formattedPlatforms,
            selectedPlatform: formattedPlatforms[0], // selected first item by default
        })
    }

    isAvailablePlatform = () => {
        const platform = this.getPlatform()

        return AVAILABLE_PLATFORMS.includes(platform)
    }
    getSaveSettings = (platform: string | undefined, settings: any) => {
        // TODO: Добавить настройки для windows/linux
        switch (platform) {
            case 'sssp':
            case 'sssp_new':
            case 'sssp_old':
                return this.getSsspSettings()
            case 'webos':
            case 'webos_new':
            case 'webos_old':
                return this.getWebOsSettings()
            case 'android':
                return this.getAndroidSettings()
            case 'brightsign':
                return this.getBrightsignSettings()
            default:
                return settings
        }
    }
    saveSettings = () => {
        const s_ = this.state
        const p_ = this.props
        const platform = s_.selectedPlatform ? s_.selectedPlatform.name : displaysHelpers.getPlatformName(p_.platform)
        let settings = helpers.deepCopy(s_.settings)

        if (platform !== 'windows') {
            // video player settings (vlc)
            delete settings.contentStoragePath
            delete settings.chequeFilePath
        }

        if (platform !== 'windows' && platform !== 'linux' && platform !== 'raspberry') {
            // video player settings (vlc)
            delete settings.videoPlayerSettings
        }

        if (Object.keys(settings.proxySettings).length) {
            const proxy = settings.proxySettings

            if (
                platform !== 'windows' &&
                platform !== 'linux' &&
                platform !== 'sssp_new' &&
                platform !== 'webos' &&
                platform !== 'webos_old' &&
                platform !== 'webos_new'
            ) {
                delete settings.proxySettings
            } else {
                if (proxy.enabled) {
                    if (!proxy.ip || !proxy.port || !proxy.connectionType) {
                        p_.emitError('emptyProxyFieldsError')
                        return
                    }
                } else {
                    if (!proxy.ip || !proxy.port || !proxy.connectionType) {
                        delete settings.proxySettings
                    }
                }
            }
        } else {
            delete settings.proxySettings
        }

        if (settings.pinCode.match(/_/g)) {
            p_.emitError('pinCode')
        } else {
            const item = { ...p_ }
            let valid = true

            if (platform !== 'windows' && platform !== 'linux') {
                delete settings.displaySettings
                delete settings.contentDownloadType
                delete settings.netDiskAddress
                delete settings.netDiskDefaultMaster
                delete settings.comPorts

                if (
                    platform !== 'android' &&
                    platform !== 'sssp_new' &&
                    platform !== 'webos' &&
                    platform !== 'webos_old' &&
                    platform !== 'webos_new'
                ) {
                    delete settings.controlsMode
                }
                delete settings.speedLimit
            } else if ((platform === 'windows' || platform === 'linux') && p_.type === 'group') {
                delete settings.netDiskDefaultMaster
            }

            if (platform === 'linux') {
                delete settings.speedLimit
            }

            if (settings.contentDownloadType !== 'netDisk') {
                delete settings.netDiskAddress
            }

            if (platform === 'windows' || platform === 'linux') {
                if (settings.megafon) {
                    if (platform === 'windows' || platform === 'linux') {
                        delete settings.megafon.batteryLevelForShutdown

                        if (!settings.megafon.inactivityTimeout) {
                            settings.megafon.inactivityTimeout = 0
                        }
                    }
                }

                settings.brightness = getBrightnessSettings(settings.brightness)

                const validateFields = ['width', 'height', 'top', 'left']
                delete settings.remoteControlEnabled
                delete settings.hideSystemNotifications

                if (Object.keys(settings.displaySettings).length) {
                    for (let key in settings.displaySettings) {
                        if (settings.displaySettings.hasOwnProperty(key)) {
                            if (settings.displaySettings[key].length) {
                                settings.displaySettings[key] = parseFloat(settings.displaySettings[key])
                            }
                            if (validateFields.includes(key)) {
                                if (typeof settings.displaySettings[key] !== 'number') {
                                    delete settings.displaySettings[key]
                                }
                            }
                        }
                    }

                    if (Object.keys(settings.displaySettings).length) {
                        validateFields.forEach((item) => {
                            if (!settings.displaySettings.hasOwnProperty(item)) {
                                this.setState({ error: item })
                                p_.emitError('coordinatesAreEmpty')
                                valid = false
                            }
                        })
                    } else {
                        delete settings.displaySettings
                    }
                } else {
                    delete settings.displaySettings
                }

                if (settings.speedLimit) {
                    settings.speedLimit = parseInt(settings.speedLimit, 10)
                }
            }

            if (!valid) {
                return false
            }

            const sssp: any = ['sssp', 'sssp_new', 'sssp_old']

            if (platform !== 'android' && platform !== 'raspberry') {
                delete settings.audioOutput
            }

            if (platform !== 'linux') {
                settings.onOffTimers.on = [settings.onOffTimers.on[0]]
                settings.onOffTimers.off = [settings.onOffTimers.off[0]]
            }

            settings = this.getSaveSettings(platform, settings)

            if (platform === 'android' && !settings.displaySettings.width && !settings.displaySettings.height) {
                delete settings.displaySettings
            }

            if (!sssp.includes(platform)) {
                delete settings.brightnessTimers
            }

            if (platform === 'sssp_old') {
                delete settings.controlsMode
            }

            if (settings.ntpSettings) {
                const ntp = settings.ntpSettings

                if (!ntp.ip || !ntp.port) {
                    if (ntp.enabled) {
                        p_.emitError('emptyNtpFieldsError')
                        return
                    }

                    delete settings.ntpSettings
                }
            }

            if (p_.type === 'group') {
                const platforms: any = s_.availablePlatforms.filter(
                    (platform: { name: string }) => platform.name === s_.selectedPlatform!.name
                )
                let platformId: any = s_.selectedPlatform!.id

                if (platforms.length > 1) {
                    platformId = platforms.map((platform: { id: string }) => platform.id)
                }

                api.send('saveGroupSettings', {
                    groupId: item.id,
                    platformId,
                    defaultSettings: {
                        settings,
                    },
                    applyRecursively: s_.applyRecursively,
                }).then(() => {
                    if (p_.onClose) {
                        p_.onClose()
                    }

                    this.onCloseAcceptModal()
                })

                return
            }

            const data: any = {
                settings,
            }

            if (s_.isMultipleDevicesSettings) {
                const devices = p_.options.devices.filter(
                    (device: { id: number; platformId: string }) => device.platformId === s_.selectedPlatform!.id
                )

                data.id = devices.map((device: { id: number }) => device.id)
            }

            if (item.type === 'digitalSignage') {
                data.id = item.id
            }

            api.send('editDisplay', data).then(() => {
                if (p_.onClose) {
                    p_.onClose()
                }
            })
        }
    }
}

export default DeviceSettingsMethods
