import { addStyles } from 'ethcss'
import { grid, item, textSizes } from 'theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'

const uiStyles = {
    wrapper: {
        '& *': {
            fontFamily: `var(--var-module-app-component-app-style-fontFamily, ${defaultThemeStyles.fontFamily})`,
        },
    },
    blockPanel: {
        ...grid.mb_md,
        ...item.panel,
    },
    title: {
        ...grid.p_mini,
        fontSize: textSizes.md,
        fontWeight: 'bold',
    },
    content: {
        ...grid.row,
        ...grid.p_mini,
    },
    list: {
        ...grid.ml_mini,
        '& li': {
            ...grid.p_micro,
        },
        '& span': {
            fontWeight: 'bold',
        },
    },
}

addStyles(uiStyles)
export default uiStyles
