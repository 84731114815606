import { addStyles } from 'ethcss'
import { mediaCSS, textSizes } from 'theme/media'
import { IStyles, item, grid } from '../../../theme'

const historyFilterStyles: IStyles = {
    filterFields: {
        ...grid.p_mini,
        ...grid.pt_none,
        ...grid.space,
        fontSize: textSizes.normal,
        overflowY: 'auto',
    },
    filter: {
        ...item.rel,
        [mediaCSS.tabMini]: {
            ...grid.space,
        },
    },
    modalWrapper: {
        ...grid.full,
        ...grid.rowCenter,
    },
    fieldWrapper: {
        ...grid.p_mini,
        ...grid.pt_none,
    },
    modalInner: {
        ...grid.w35,
        [mediaCSS.tab]: {
            ...grid.w90,
        },
        ...item.card,
        ...item.shadowModal,
        ...item.rounded_full_mini,
    },
    buttons: {
        ...grid.row,
        ...grid.pr_mini,
        ...grid.pl_mini,
        ...grid.justify,
    },
    checkItem: {
        ...grid.pb_mini,
        '&:last-child': {
            ...grid.pb_none,
        },
    },
    mobileScroll: {
        overflow: 'auto',
    },
}

addStyles(historyFilterStyles)
export default historyFilterStyles
