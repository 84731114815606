import { addStyles } from 'ethcss'
import { IStyles } from 'theme'
import grid from 'blocks.simple/grid/grid.jcss'
import { defaultThemeStyles } from './CircleButton-theme'

export const circleButtonStyles: IStyles = {
    circleButton: {
        ...grid.rowCenter,
        width: 25,
        height: 25,
        borderRadius: '50%',
        cursor: 'pointer',
        backgroundColor: `var(--var-module-ui-component-circleButton-style-background, ${defaultThemeStyles.background})`,
    },
    circleButtonActive: {
        backgroundColor: `var(--var-module-ui-component-circleButton-style-activeBackground, ${defaultThemeStyles['background-active']})`,
    },
    deleteButton: {
        width: '20%',
        height: 1,
        backgroundColor: `var(--var-module-ui-component-circleButton-style-text, ${defaultThemeStyles.text})`,
    },
}

addStyles(circleButtonStyles)
