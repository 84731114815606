import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { textSizes } from 'theme/media'
import { IStyles } from '../../../theme'

const previewNotSupportStyles: IStyles = {
    wrapper: {
        ...grid.full,
        ...grid.colCenter,
        ...grid.center,
        ...item.whiteColor,
        fontSize: textSizes.md,
    },
}

addStyles(previewNotSupportStyles)
export default previewNotSupportStyles
