import React from 'react';

class InstancesCatalogPitamixMethods extends React.Component {
    constructor(p_) {
        super(p_);

        this.state = {
            selectedItems: []
        };
    }
}

export default InstancesCatalogPitamixMethods;