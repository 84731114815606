import styles from './licenses.jcss'
import translate from '../../core/translate'
import moment from 'moment'
import { cn } from 'ethcss'
import helpers from '../../core/helpers'
import React from 'react'
import { colors } from '../../theme'
import { themeFromStore } from '../../core/helpers/menu'
import Icon from '../../blocks.simple/icon/icon'

const getBackgroundColor = (license: any) => {
    if (!license.activeQ) return colors.grey
    if (license.licenseType === 'purchase') return colors.blueAccent
    return colors.success
}
const getLicenseType = (type: string) => {
    if (!type) return ''

    return translate(type)
}
const getInactiveStatus = (license: any) => {
    return translate(license.deletedQ ? 'licenseRevoked' : 'licenseCompleted')
}

export const License = ({ license }: any) => {
    return (
        <div
            className={cn(styles.licensesRow, license.giftQ ? styles.giftPadding : '')}
            style={{ backgroundColor: getBackgroundColor(license) }}
        >
            {license.giftQ && <Icon containerClass={styles.gift} type={'gift'} size={24} color={'white'} />}
            <div className={styles.licensesCol}>
                <div className={styles.tdInner}>
                    <span>{translate('startDate')}: </span>
                    <span className={styles.dateMargin}>{moment(license.startrdAt).format('DD.MM.YYYY')}</span>
                </div>
            </div>
            <div className={cn(styles.licensesCol, styles.timeLeftAndCount)}>
                {license.expiredAt ? (
                    <span className={styles.daysLeft}>
                        {translate('leftN')} {helpers.dateDifference(license.expiredAt)}{' '}
                        {helpers.getLabelByNumber(license.vacantCount, [
                            translate('dayN1'),
                            translate('dayN2'),
                            translate('dayN3'),
                        ])}
                    </span>
                ) : (
                    <div></div>
                )}
                <span className={styles.licenseCount}>
                    {license.vacantCount +
                        ' ' +
                        helpers.getLabelByNumber(license.vacantCount, [
                            translate('license1'),
                            translate('license2'),
                            translate('license3'),
                        ])}
                </span>
            </div>
            <div className={cn(styles.licensesCol, styles.licenseStatus)}>
                <div className={styles.licenseType}>{getLicenseType(license.licenseType)}</div>
                {license.expiredAt && license.activeQ ? (
                    <>{translate('till') + ' ' + moment(license.expiredAt).format('DD.MM.YYYY')}</>
                ) : license.licenseType === 'purchase' && license.activeQ ? null : (
                    getInactiveStatus(license)
                )}
            </div>
            {license.licenseType === 'trial' && license.activeQ && (
                <>
                    <div className={styles.accentLabel}></div>
                    <div className={styles.accentFrame}></div>
                </>
            )}
        </div>
    )
}
