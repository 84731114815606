import { useState, useEffect } from 'react'
import {
    IUsePreparePortalElement,
    IUseVideoChatModalDad,
    IUseVideoChatModalSize,
    IVideoChatModalPosition,
    IVideoChatModalSize,
} from './VideoChatModal-types'

export const useVideoChatModalDaD = (top: number, left: number): IUseVideoChatModalDad => {
    const [position, setPosition] = useState<IVideoChatModalPosition>({ top, left })

    useEffect(() => {
        setPosition({ top, left })
    }, [top, left])

    return {
        position,
        setPosition,
    }
}

export const usePreparePortalElement = (isOpened: boolean): IUsePreparePortalElement => {
    const [portalParentElement, setPortalParentElement] = useState<HTMLDivElement | null>(null)

    useEffect(() => {
        let element = document.createElement('div')
        let root = document.getElementById('root')
        root?.appendChild(element)
        setPortalParentElement(element)

        return () => {
            portalParentElement?.remove()
        }
    }, [isOpened])

    return {
        portalParentElement,
    }
}

export const useVideoChatModalSize = (
    isMinimized: boolean,
    minSize: IVideoChatModalSize,
    maxSize: IVideoChatModalSize
): IUseVideoChatModalSize => {
    const [size, setSize] = useState(isMinimized ? minSize : maxSize)

    useEffect(() => {
        if (isMinimized) {
            setSize(minSize)
        } else {
            setSize(maxSize)
        }
    }, [isMinimized])

    return {
        size,
    }
}
