import helpers from 'core/helpers'
import { useEffect } from 'react'

export const useDisableWindowScroll = (isOpened: boolean, id: string) => {
    useEffect(() => {
        if (isOpened) {
            helpers.disableWindowScroll(isOpened, id)
        }

        return () => {
            helpers.disableWindowScroll(false, id)
        }
    }, [isOpened])
}
