import React, { FunctionComponent, useEffect, useState } from 'react'
import EditText from 'blocks.simple/editText/editText'
import RangeVolume from 'blocks.simple/range/_volume/range_volume'
import { IDisplayPlayAudioEditComponent } from './DisplayPlayAudioEdit-types'
import styles from './DisplayPlayAudioEdit-styles'
import Button from 'blocks.simple/button/button'
import { cn } from 'ethcss'
import CatalogSelectContent from 'blocks.app/catalog/_select/_content/catalog_select_content'
import translate from 'core/translate'
import { api } from 'core/api/ConnectionManager'

const allowedKeyCodes = [
    'Backquote',
    'Digit1',
    'Digit2',
    'Digit3',
    'Digit4',
    'Digit5',
    'Digit6',
    'Digit7',
    'Digit8',
    'Digit9',
    'Digit0',
    'Minus',
    'Equal',
    'KeyQ',
    'KeyW',
    'KeyE',
    'KeyR',
    'KeyT',
    'KeyY',
    'KeyU',
    'KeyI',
    'KeyO',
    'KeyP',
    'BracketLeft',
    'BracketRight',
    'Backslash',
    'KeyA',
    'KeyS',
    'KeyD',
    'KeyF',
    'KeyG',
    'KeyH',
    'KeyJ',
    'KeyK',
    'KeyL',
    'Semicolon',
    'Quote',
    'KeyZ',
    'KeyX',
    'KeyC',
    'KeyV',
    'KeyB',
    'KeyN',
    'KeyM',
    'Comma',
    'Period',
    'Slash',
]

export const DisplayPlayAudioEdit: FunctionComponent<IDisplayPlayAudioEditComponent> = ({ editProps, onClose }) => {
    const [volume, setVolume] = useState<number>(editProps.volume)
    const [name, setName] = useState<string>(editProps.name)
    const [keyboardEventCode, setKeyboardEventCode] = useState(editProps.keyboardEventCode)
    const [character, setCharacter] = useState<string>(editProps.character)
    const [sourceId, setSourceId] = useState<number | null>(editProps.sourceId)
    const [audioTitle, setAudioTitle] = useState<string>(editProps.source ? editProps.source.name : '')

    const setKeyCode = (e: any) => {
        const activeElement = document.activeElement

        if (!activeElement) return

        if (activeElement.id === 'character' && allowedKeyCodes.includes(e.code)) {
            e.preventDefault()

            setCharacter(e.key)
            setKeyboardEventCode(e.code)
        }
    }

    const save = (data: any) => {
        api.send('updateTriggerButton', data).then(() => {
            onClose()
        })
    }

    useEffect(() => {
        document.addEventListener('keydown', setKeyCode)

        return () => document.removeEventListener('keydown', setKeyCode)
    }, [])

    return (
        <div className={styles.wrapper}>
            <div className={styles.inner}>
                <div className={styles.volume}>
                    <div className={styles.volumeLabel}>{translate('volume')}</div>
                    <RangeVolume volume={volume} onChange={(value: number) => setVolume(value)} />
                </div>
                <div className={styles.inputGroup}>
                    <EditText
                        label={translate('buttonName')}
                        onChange={(value: string) => setName(value)}
                        type="text"
                        placeholder={translate('nameEnter')}
                        value={name}
                    />
                </div>
                <div className={styles.inputGroup}>
                    <EditText
                        id={'character'}
                        label={translate('hotKey')}
                        onChange={(value: string) => setCharacter(value)}
                        type="text"
                        placeholder={translate('enterKey')}
                        value={character.toUpperCase()}
                        maxLength={1}
                    />
                </div>
                <div className={styles.audioChoice}>
                    <div className={cn(styles.inputGroup, styles.audioName)}>
                        <EditText
                            label={translate('audioFile')}
                            type="text"
                            placeholder={translate('selectTrack')}
                            value={audioTitle}
                            disabled={true}
                        />
                    </div>
                    <div className={styles.audioChoiceBtnWrapper}>
                        <CatalogSelectContent
                            type={'audio'}
                            selectedInfo={null}
                            isHidePreview
                            onSelect={(selectedMedia: any) => {
                                if (selectedMedia) {
                                    setSourceId(selectedMedia.id)
                                    setAudioTitle(selectedMedia.name)
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={styles.actions}>
                    <Button mod={'withBorder'} onClick={onClose} indentSize={'mini'} rounded={'full_normal'}>
                        {translate('cancel')}
                    </Button>
                    <Button
                        mod={'withBorder'}
                        onClick={() => {
                            save({
                                triggerButtonId: editProps.triggerButtonId,
                                keyboardEventCode,
                                character,
                                name,
                                volume,
                                sourceId,
                            })
                        }}
                        indentSize={'mini'}
                    >
                        {translate('editDisplay')}
                    </Button>
                </div>
            </div>
        </div>
    )
}
