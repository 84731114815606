import { addStyles } from 'ethcss'
import { grid, text } from 'theme'

const treeViewCatalogEmptyStyles = {
    treeViewCatalogEmptyWrapper: {
        ...grid.colCenter,
        ...grid.mt_md
    },
    treeViewCatalogEmptyContent: {
        ...grid.row,
        ...grid.center,
        ...grid.mb_md
    },
    treeViewCatalogEmptyTitle: {
        ...grid.fullWidth,
        ...grid.pt_mini,
        ...text.center,
        ...text.md,
        ...text.up,
        ...text.darkBlue
    }
}

addStyles(treeViewCatalogEmptyStyles)
export default treeViewCatalogEmptyStyles
