import { api } from 'core/api/ConnectionManager'
import { IFile } from '../../../../core/models/Files'

export const isImagesExist = (item: { historyPageId: number; images: { source: IFile }[] }) => {
    if (item.images) {
        if (item.images.length) {
            return true
        }
    }
    return false
}

const getMediaIds = (items: { id: number; extension: string }[]) => {
    if (!items) return

    const mediaIds: { sourceId: number }[] = []

    items.forEach((media, index) => {
        const ext = media.extension
        if (ext === '.jpg' || ext === '.png' || ext === '.mp4') {
            if (index < 4) {
                return mediaIds.push({
                    sourceId: media.id,
                })
            }
        }
    })

    return mediaIds.length ? mediaIds : null
}

export async function getSourcesIds(selectedMedia: { id: number; extension: string; content: any }) {
    if (!selectedMedia) return

    if (selectedMedia.content) {
        const response: { id: number; extension: string }[] = await api.send('getSources', {
            folderId: selectedMedia.id,
        })
        return getMediaIds(response)
    } else {
        return getMediaIds([selectedMedia])
    }
}
