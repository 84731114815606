import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { textSizes } from 'theme/media'
import { IStyles } from '../../theme'

export const PREVIEW_CAPTION_HEIGHT = 63

const previewStyles: IStyles = {
    wrapper: {
        ...grid.full,
        ...item.previewOpacity,
    },
    contentWrapper: {
        ...grid.full,
    },
    caption: {
        ...item.abs,
        ...grid.fullWidth,
        height: PREVIEW_CAPTION_HEIGHT,
        top: 0,
        zIndex: 2,
        '& span': {
            ...item.whiteColor,
            ...grid.row,
            ...grid.p_md,
            ...item.blackOpacity,
            fontSize: textSizes.mdPlus,
        },
    },
    navIconWrapper: {
        ...item.abs,
        ...grid.colCenter,
        ...grid.fullHeight,
        ...grid.p_md,
    },
    prev: {
        left: 0,
    },
    next: {
        right: 0,
    },
    navIcon: {
        ...item.pointer,
        ...item.circle,
        ...grid.p_md,
        ...item.blackLight,
        zIndex: 2,
    },
    controlsWrapper: {
        ...grid.fullWidth,
        ...grid.row,
        ...grid.center,
        ...item.abs,
        bottom: 0,
    },
    controls: {
        ...grid.row,
        ...grid.mb_md,
        ...item.blackOpacity,
        ...item.rounded_full_normal,
    },
}

addStyles(previewStyles)
export default previewStyles
