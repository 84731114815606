import { colors } from 'theme'

export const defaultThemeStyles = {
    wrapperBorder: colors.black,
    border: colors.greyLight,
    header: colors.blackLight,
    background: colors.white,
    active: colors.black,
    disable: colors.grey,
    textMain: colors.white,
    title: colors.grey,
    titleComplete: colors.black,
    progressComplete: colors.online,
    progressOver: colors.grey,
}
