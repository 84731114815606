import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../theme'
import { cols, indents } from 'blocks.app/config'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'

const scrollUpStyles: IStyles = {
    content: {
        right: `calc(${cols.normal}% + ${indents.mdPlus}px)`,
    },
    contentWithoutRightToolbar: {
        right: indents.mdPlus,
    },
    contentTab: {
        right: indents.mdPlus,
    },
    contentTabMini: {
        right: indents.mini,
    },
    wrapper: {
        ...grid.p_mini,
        background: `var(--var-module-app-component-app-style-backgroundSub, ${defaultThemeStyles.backgroundSub})`,
        ...item.blured,
        borderRadius: '50%',
    },
}

addStyles(scrollUpStyles)
export default scrollUpStyles
