import React from 'react'
import Content from 'blocks.app/content/content'
import Catalog from 'blocks.app/catalog/catalog'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

class InstancesCatalogProductMenu extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            selectedInfo: null,
            selectedItems: [],
        }
    }
    componentDidMount() {}
    render() {
        const { selectedItems } = this.state

        return (
            <Content title={'productMenu'}>
                <Catalog
                    type={'productMenu'}
                    onSelect={(selectedItems) => this.setState({ selectedItems })}
                    selectedItems={selectedItems}
                    treeView={false}
                    additionalData={{}}
                    fixedHeader={true}
                    isExistRightToolbar={false}
                />
            </Content>
        )
    }
}

const exportInstancesCatalogProductMenu = checkAvailableModule('instancesCatalog/productMenu')
    ? InstancesCatalogProductMenu
    : null

export default exportInstancesCatalogProductMenu
