import React from 'react'
import { api } from 'core/api/ConnectionManager'

class InstancesCatalogMethods extends React.Component {
    state = {
        catalogs: [],
    }
    componentDidMount() {
        api.send('getInstancesCatalogList', {}).then((res) => {
            if (res) {
                this.setState({ catalogs: res })
            }
        })
    }

    getCatalogImage = (catalog) => {
        const catalogName = catalog.replace('Catalog', '')

        switch (catalogName) {
            case 'combo':
            case 'burgers':
            case 'smallBurgers':
            case 'snacks':
            case 'drinks':
                return `images/catalogs/${catalog}.png`
            default:
                return 'images/helpers/empty_grey.png'
        }
    }
}

export default InstancesCatalogMethods
