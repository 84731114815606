import React from 'react'
import Measure from 'react-measure'
import SearchbarFilterListMethods, { ISearchbarFilterProps } from './searchbar_filterList.local.methods'
import Fade from '../__fade/searchbar__fade'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './searchbar_filterList.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { text as textStyles } from 'theme'
import item from 'blocks.simple/item/item.jcss'

const SearchbarFilterList = (p_: ISearchbarFilterProps) => {
    const { showAll, contentWidth, isHideContent, getFilterList, removeFilterItem, state } = SearchbarFilterListMethods(
        p_
    )
    const s_ = state
    const filterList = p_.filterList || getFilterList(p_.filter.selected)

    return (
        <div className={cn(styles.filterOptions, grid[`mt_${p_.topIndent}`])}>
            <div
                className={cn(styles.filterContent, {
                    [grid.noWrap]: !s_.showAll,
                })}
            >
                <Measure bounds onResize={contentWidth}>
                    {({ measureRef }) => <div className={cn(grid.fullWidth, item.abs, item.hidden)} ref={measureRef} />}
                </Measure>
                <Measure bounds onResize={isHideContent}>
                    {({ measureRef }) => (
                        <div className={cn(grid.row, { [grid.noWrap]: !s_.showAll })} ref={measureRef}>
                            {filterList.map((filterItem: any, index: number) => (
                                <div className={cn(grid.pt_mini, grid.pr_mini)} key={`filterItem_${index}`}>
                                    <Button
                                        mod={'withBorder'}
                                        icon={'close'}
                                        textSize={'mini'}
                                        indentSize={'mini'}
                                        animation={false}
                                        iconSize={10}
                                        onIconClick={() => {
                                            if (p_.onRemoveFilterItem) {
                                                p_.onRemoveFilterItem(filterItem)
                                            } else {
                                                removeFilterItem(filterItem)
                                            }
                                        }}
                                    >
                                        {filterItem.name}
                                    </Button>
                                </div>
                            ))}
                        </div>
                    )}
                </Measure>
                {!s_.showAll && <Fade />}
            </div>
            <div
                className={cn(grid.pt_mini, grid.pr_mini, item.pointer, textStyles.mini, textStyles.underline, {
                    [item.hidden]: s_.isHidden,
                })}
                onClick={showAll}
            >
                {s_.showAll ? translate('hide') : translate('showAll')}
            </div>
        </div>
    )
}

SearchbarFilterList.defaultProps = {
    topIndent: 'md',
}

export default SearchbarFilterList
