import React from 'react'
import Sidebar from 'blocks.app/sidebar/sidebar'
import AdvertisingCampaignsAddContentSettings from '../__contentSettings/advertisingCampaigns_add__contentSettings'
import RangeVolume from 'blocks.simple/range/_volume/range_volume'
import { checkRoutePathName } from 'core/helpers/routes/routes'

import translate from 'core/translate'

const emptyText: { [index: string]: string } = {
    files: 'noContent',
    displays: 'noSelectedDevices',
    summary: 'description',
}
const emptyDescription: { [index: string]: string } = {
    files: 'noContentDescription',
    displays: 'noSelectedDevicesDescription',
    summary: checkRoutePathName('/advertisingAds/add')
        ? 'summaryToolbarAdvertisementDescription'
        : 'summaryToolbarDescription',
}

const AdvertisingCampaignsAddSidebar = (p_: any) => {
    const isEmpty = () => {
        if (p_.step !== 'summary') {
            return !p_.selectedContent ? !p_[p_.step].length : false
        }

        return true
    }
    const getCustomContent = () => {
        if (p_.step !== 'summary' && p_.selectedContent) {
            return (
                <AdvertisingCampaignsAddContentSettings
                    files={p_.files}
                    selectedContentId={p_.selectedContentId}
                    selectedContent={p_.selectedContent}
                    selectedFile={p_.selectedFile}
                    onChangeVolume={p_.onChangeVolume}
                    getVolumeDiff={p_.getVolumeDiff}
                    onSelectContent={p_.onEditItem}
                    onChangeDuration={p_.onChangeDuration}
                    onChangeRepetitionCount={p_.onChangeRepetitionCount}
                    isAdvBlock={p_.isAdvBlock}
                />
            )
        }

        return null
    }
    const getExtraComponent = () => {
        return p_.isVolumeSupport() && p_.step === 'files' ? (
            <div style={{ marginBottom: '10px' }}>
                <label>{translate('commonVolume')}</label>
                <RangeVolume
                    onChange={function (volume: number) {
                        p_.onChangeCommonVolume(volume)
                    }}
                    volume={p_.commonVolume}
                    onAfterChange={() => {
                        setTimeout(() => {
                            p_.getVolumeDiff()
                        }, 200)
                    }}
                />
                {p_.isVolumeDiff ? (
                    <div>
                        {translate('durationSettingsTip1')}
                        <br />
                        <br />
                    </div>
                ) : null}
                <div>{translate('durationSettingsTip3')}</div>
            </div>
        ) : null
    }
    const getContent = () => {
        const isFiles = p_.step === 'files'

        if (p_.step !== 'summary' && !p_.selectedContent) {
            return {
                title: translate(isFiles ? 'advContent' : 'selectedDevices'),
                options: p_[p_.step],
                showEditIcon: isFiles,
            }
        }
        if (p_.step === 'summary') {
            return {
                options: [],
            }
        }

        return null
    }

    return (
        <Sidebar
            selectedSide="right"
            extraComponent={getExtraComponent()}
            content={{
                right: getContent(),
            }}
            customContent={{
                right: getCustomContent(),
            }}
            isEmpty={{
                right: isEmpty(),
            }}
            emptyText={{
                right: {
                    title: translate(emptyText[p_.step]),
                    description: translate(emptyDescription[p_.step]),
                },
            }}
            onChange={p_.onChange}
            onEditItem={function (selectedItem: { id: number }) {
                p_.onEditItem(selectedItem.id)
            }}
        />
    )
}

export default AdvertisingCampaignsAddSidebar
