import io from 'socket.io-client'
import config from 'core/config'
import request from 'superagent'
import { EventChannel, EventData } from '../EventChannel'
import {
    ListenEventsType,
    ApiEventsType,
    ApiPostEvents,
    ApiPostEventsType,
    ApiEvents,
    ApiListenEvents,
    ApiListenEventsType,
} from '../events'
import { IHTTPRequestHeaders } from 'core/models/Request'
import { IResponse } from 'core/models/Response'
import { IWebSocketError } from '../models'
declare var window: any

export interface IWebSocketOptions {
    transports?: string[]
}

export interface IWebSocketSendOptions {
    hideLoader?: boolean
    showEmitError?: boolean
}

export type TWebSocketStatus = 'connect' | 'disconnect' | 'connect_error' | 'connect_timeout' | 'error'

class ConnectionManager {
    private static instance: ConnectionManager
    private eventChannel: EventChannel
    private RECONNECT_TIMER_MS = 500
    private reconnectTimeout: NodeJS.Timer | null = null
    private socket: SocketIOClient.Socket

    static get() {
        if (!ConnectionManager.instance) {
            let eventChannel = new EventChannel()
            ConnectionManager.instance = new ConnectionManager(eventChannel)
        }
        return ConnectionManager.instance
    }

    private constructor(eventChannel: EventChannel) {
        this.eventChannel = eventChannel
        this.socket = this.connect(this.getSocketOptions())
        this.init()
        this.initListeners()
    }

    public post<T extends object>(eventType: ApiPostEventsType, data: T, headers: IHTTPRequestHeaders = {}) {
        if (headers) {
            return request.post(`${config.api.root}${ApiPostEvents[eventType]}`).send(data).set(headers)
        }

        return request.post(`${config.api.root}${ApiPostEvents[eventType]}`).send(data)
    }

    public get(url: string) {
        return request.get(url)
    }

    public send<T, K>(eventType: ApiEventsType, data: T, options: IWebSocketSendOptions = {}): Promise<K> {
        if (!options.hideLoader) {
            this.eventChannel.fireEvent({ type: 'startLoading', data: {}, error: null })
        }

        return new Promise((resolve, reject) => {
            this.socket.emit(ApiEvents[eventType], data, (err: IResponse<any>, res: IResponse<K>) => {
                if (err) {
                    const error = this.getError(err, eventType, ApiEvents[eventType], options.showEmitError)
                    reject(error)
                } else {
                    resolve(res.data)
                }

                if (!options.hideLoader) {
                    this.eventChannel.fireEvent({ type: 'endLoading', data: null, error: null })
                }
            })
        })
    }

    private init() {
        this.socket.on('connect', () => {
            this.eventChannel.fireEvent({ type: 'connect', data: null, error: null })
            this.eventChannel.fireEvent<TWebSocketStatus>({ type: 'onChangeStatus', data: 'connect', error: null })
        })

        this.socket.on('disconnect', () => {
            console.log('Socket disconnected...')
            this.eventChannel.fireEvent<TWebSocketStatus>({ type: 'onChangeStatus', data: 'disconnect', error: null })
            this.reconnect()
        })

        this.socket.on('connect_error', () => {
            console.error('Socket connection error.')
            this.eventChannel.fireEvent<TWebSocketStatus>({
                type: 'onChangeStatus',
                data: 'connect_error',
                error: null,
            })
            this.reconnect()
        })

        this.socket.on('connect_timeout', () => {
            console.error('Socket connection timeout error.')
            this.eventChannel.fireEvent<TWebSocketStatus>({
                type: 'onChangeStatus',
                data: 'connect_timeout',
                error: null,
            })
            this.reconnect()
        })

        this.socket.on('error', () => {
            console.error('Socket error.')
            this.eventChannel.fireEvent<TWebSocketStatus>({ type: 'onChangeStatus', data: 'error', error: null })
            this.reconnect()
        })
    }

    private initListeners() {
        for (let apiListenEvent in ApiListenEvents) {
            let eventType = apiListenEvent as ApiListenEventsType

            this.socket.on(ApiListenEvents[eventType], (res: IResponse<any>) => {
                if (res.error) {
                    const error = this.getError(res, eventType, ApiListenEvents[eventType])
                    this.eventChannel.fireEvent({ type: eventType, error, data: null })
                } else {
                    this.eventChannel.fireEvent({ type: eventType, data: res.data, error: null })
                }

                this.eventChannel.fireEvent({ type: 'endLoading', data: null, error: null })
            })
        }
    }

    private reconnect() {
        this.clearReconnectTimeout()

        this.reconnectTimeout = setTimeout(() => {
            console.log('Reconnect socket after disconnect...')
            this.socket.connect()
        }, this.RECONNECT_TIMER_MS)
    }

    private connect(socketOptions: IWebSocketOptions) {
        return io.connect(`${config.api.root}${config.api.room}`, socketOptions)
    }

    private clearReconnectTimeout() {
        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout)
            this.reconnectTimeout = null
        }
    }

    private getError(
        res: IResponse<any>,
        eventType: ApiEventsType | ApiListenEventsType,
        eventCode: string,
        showEmitError: boolean = true
    ): IWebSocketError {
        const error: IWebSocketError = {
            name: res.message,
            code: eventCode,
            apiEventName: eventType,
        }

        if (showEmitError) {
            console.error(`API error: ${error.name} on event "${error.apiEventName}" with code ${error.code}`)
            this.eventChannel.fireEvent<typeof res>({ type: 'emitError', data: res, error: null })
        }

        return error
    }

    private getSocketOptions(): IWebSocketOptions {
        const socketOptions: IWebSocketOptions = {}

        if (!window.spconfig.useOnlyWS) {
            socketOptions.transports = ['polling', 'websocket']
        } else {
            socketOptions.transports = ['websocket']
        }

        return socketOptions
    }

    public addObserver<T>(
        type: ListenEventsType,
        cb: (eventData: T, error: IWebSocketError | null) => void,
        listenersIdList: string[]
    ): string {
        return this.eventChannel.addObserver<T>(type, cb, listenersIdList)
    }

    public removeObserver(id: string): boolean {
        return this.eventChannel.removeObserver(id)
    }

    public fireEvent<T>(eventData: EventData<T>) {
        this.eventChannel.fireEvent<T>(eventData)
    }
}

export const api = ConnectionManager.get()
