import React from 'react'
import User from 'blocks.app/user/user'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import styles from './catalog__item_users.jcss'
import helpers from 'core/helpers'

type Item = {
    __view: {
        selected: boolean
    }
    email: string
    roles: string
    photo: string
}

interface IItemUsersProps {
    item: Item
    onSelect: (data: { selected: boolean; item: Item }) => void
    onDoubleClickObject: (item: Item) => void
    multipleSelect: boolean
    disableMultiSelect: boolean
    onSelectInfo: (e: React.MouseEvent) => void
}

const ItemUsers = (p_: IItemUsersProps) => (
    <div className={styles.wrapper}>
        <div
            className={styles.cell}
            onClick={function (e) {
                if (helpers.isDblTouchTap(e)) {
                    p_.onDoubleClickObject(p_.item)
                    return
                }

                p_.onSelectInfo(e)
            }}
        >
            {p_.multipleSelect && (
                <div
                    className={styles.checkWrapper}
                    onClick={(e) => e.stopPropagation()}
                    style={p_.item.__view.selected ? { opacity: 1 } : {}}
                >
                    <Checkbox
                        onClick={(selected: boolean) => {
                            p_.onSelect({ selected, item: p_.item })
                        }}
                        checked={p_.item.__view.selected}
                        disabled={p_.disableMultiSelect}
                    />
                </div>
            )}
            <User email={p_.item.email} roles={p_.item.roles} photo={p_.item.photo} />
        </div>
    </div>
)

export default ItemUsers
