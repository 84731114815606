import React, { useState } from 'react'
import styles from './ipTv.jcss'
import translate from '../../core/translate'
import Content from 'blocks.app/content/content'
import { Channels } from './__channels/Channels'
import { cn } from 'ethcss'
import { Categories } from './__categories/Categories'
import { Upload } from './__upload/Upload'

export const IpTv = () => {
    const tabs = ['channels', 'categories', 'upload']
    const [activeTab, setActiveTab] = useState('channels')

    const renderTab = (activeTab: string) => {
        switch (activeTab) {
            case 'channels':
                return <Channels />
            case 'categories':
                return <Categories />
            case 'upload':
                return <Upload />
            default:
                return null
        }
    }

    return (
        <Content title={translate('ipTv')} wrapperClassName={styles.ipTv}>
            <div className={styles.tabsWrapper}>
                {tabs.map((tab) => (
                    <div
                        className={cn(styles.tabBtn, tab === activeTab ? styles.tabActive : '')}
                        onClick={() => setActiveTab(tab)}
                    >
                        {translate(tab)}
                    </div>
                ))}
            </div>
            {renderTab(activeTab)}
        </Content>
    )
}
