import { addStyles } from 'ethcss'
import { mediaCSS } from 'blocks.app/config'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'

const deviceSettingsTimerStyles = {
    timersWrapper: {
        ...grid.row,
        ...grid.w90,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    timer: {
        ...grid.col,
        ...grid.w40,
        ...grid.flexStart,
        ...grid.p_mini,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    singleTimer: {
        ...grid.col,
        ...grid.w35,
        ...grid.pr_mini,
        ...grid.pl_mini,
        [mediaCSS.tab]: {
            ...grid.w100,
            ...grid.p_none,
            ...grid.pt_mini,
            ...grid.pb_mini,
        },
    },
    timers: {
        ...grid.row,
        ...grid.fullWidth,
        ...grid.p_md,
        ...grid.pt_none,
        ...item.rounded_full_mini,
        border: `1px solid var(--var-module-app-component-app-style-borderContrast, ${defaultThemeStyles.borderContrast})`,
    },
}

addStyles(deviceSettingsTimerStyles)
export default deviceSettingsTimerStyles
