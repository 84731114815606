import React, { useEffect, useRef, useState } from 'react'
import Icon from 'blocks.simple/icon/icon'
import DropMenu from 'blocks.simple/dropMenu/dropMenu'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import Button from 'blocks.simple/button/button'
import filesHelpers from 'core/helpers/_files'
import broadcastsHelpers from 'core/helpers/_broadcasts'
import translate from 'core/translate'
import deepEqual from 'fast-deep-equal'
import { cn } from 'ethcss'
import styles from './sidebar.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { text as textStyles } from 'theme'
import { Typography } from 'atoms/Typography'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { CircleButton } from 'atoms/CircleButton'
import { isNotEmptyArray } from 'core/utils/coreUtil'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import { Radio } from 'atoms/Radio'
import { colors } from '../../theme'
import { history } from 'features/routes'
import { CustomScroll } from 'atoms/CustomScroll'
import DotTooltip from '../../blocks.simple/dotTooltip/dotTooltip'
import { Tooltip } from 'atoms/Tooltip'
import { Conflicts } from '../../pages/broadcasts/_add/broadcasts_add.local.methods'

interface ISidebarProps {
    showAlwaysCustomContent?: boolean
    isShowActions: boolean
    isEditable: boolean
    isDeletable: boolean
    hideHeader?: boolean
    onChange?: (selected: any[], side?: string) => void
    onSubmit?: (side: string, type: string, data: any) => void
    onEditItem?: (selectedItem: { id: number }) => void
    selectSidebar?: (side: string) => void
    onGetFoldersOnMultiple: () => void
    customContent?: any
    bottomContent?: any
    customHeader?: any
    icon?: any
    content?: any
    isEmpty: object
    emptyText?: any
    listItem?: object
    listItemProps?: object
    selectedSide: string
    contentWrapperClassName?: string | object
    conflicts?: Conflicts[]
    extraComponent?: any
    onSelectInfo?: any
    side?: string
    selectedInfo?: object | null
}

const Sidebar = (p_: ISidebarProps) => {
    const contentSide = function (side: string) {
        return [p_.content, p_.customContent].some(function (o) {
            return (o || {})[side]
        })
    }
    const headerIcon = function (side: string) {
        return [p_.icon].some(function (o) {
            return (o || {})[side]
        })
    }

    return (
        <CustomScroll classNames={styles.customScrollBlock}>
            <div className={styles.sidebarWrapper}>
                {!p_.hideHeader ? (
                    p_.customHeader ? (
                        p_.customHeader
                    ) : (
                        <div
                            className={styles.sidebarHeader}
                            onClick={function (e) {
                                e.stopPropagation()
                            }}
                        >
                            {contentSide('left') && (
                                <div
                                    className={cn(
                                        styles.toggleButton,
                                        p_.selectedSide === 'left' ? styles.selectedSide : '',
                                        contentSide('right') ? '' : styles.full
                                    )}
                                    onClick={function () {
                                        if (p_.selectSidebar) {
                                            p_.selectSidebar('left')
                                        }
                                    }}
                                >
                                    {headerIcon('left') && (
                                        <Icon
                                            size={p_.icon.left.size || '23'}
                                            type={p_.icon.left.name || 'info'}
                                            color={p_.selectedSide === 'left' ? colors.white : colors.grey}
                                        />
                                    )}
                                    {!headerIcon('left') && <Icon size="23" type="info" color="white" />}
                                </div>
                            )}
                            {contentSide('right') && (
                                <div
                                    className={cn(
                                        styles.toggleButton,
                                        contentSide('left') && p_.selectedSide === 'right' ? styles.selectedSide : '',
                                        contentSide('left') ? styles.buttonWithBorder : '',
                                        contentSide('left') ? '' : styles.full
                                    )}
                                    onClick={function () {
                                        if (p_.selectSidebar) {
                                            p_.selectSidebar('right')
                                        }
                                    }}
                                >
                                    {headerIcon('right') && (
                                        <Icon
                                            size={p_.icon.right.size || '23'}
                                            type={p_.icon.right.name || 'info'}
                                            color={
                                                p_.icon.right.color ||
                                                getThemeStyleValue('ui', 'icon', 'main') ||
                                                defaultThemeStyles.main
                                            }
                                        />
                                    )}
                                    {!headerIcon('right') && (
                                        <Icon
                                            size="23"
                                            type="info"
                                            color={p_.selectedSide === 'right' ? colors.white : colors.grey}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    )
                ) : null}
                <Content {...p_} side={p_.selectedSide} />
            </div>
        </CustomScroll>
    )
}

Sidebar.defaultProps = {
    isShowActions: false,
    isEditable: false,
    isDeletable: false,
    onGetFoldersOnMultiple: () => {},
}

const getEditTitle = (p_: { content: any; side: string }) => {
    if (p_.content[p_.side]) {
        return p_.content[p_.side].editTitle
    }
}

const isAdditionContentExist = (p_: any) => {
    if (!p_.content[p_.side]) return false

    let additionContent = p_.content[p_.side].additionContent

    return !(!additionContent || !isNotEmptyArray(additionContent))
}

const isFolderChanged = (s_: {
    data: {
        currentFolderId: number[]
        newFolderId: number[]
    }
}) => {
    let currentFolderId = s_.data.currentFolderId
    let newFolderId = s_.data.newFolderId

    return currentFolderId && newFolderId && currentFolderId !== newFolderId
}

const getAdditionContentData = (p_: any) => {
    let data = {}

    if (!isAdditionContentExist(p_)) return data

    p_.content[p_.side].additionContent.forEach(({ name, initValue }: { name: string; initValue: string }) => {
        data = { ...data, [name]: initValue }
    })

    return data
}

const getFileData = (p_: any) => {
    let sourceIdArray: number[] = []
    let foldersIdArray: number[] = []
    let data = {}

    if (p_.content[p_.side]) {
        p_.content[p_.side].options.forEach((item: { id: number }) => {
            if (p_.content[p_.side].isDirectory(item)) {
                foldersIdArray.push(item.id)
            } else {
                sourceIdArray.push(item.id)
            }
        })
        data = {
            sourceId: sourceIdArray,
            folderId: foldersIdArray,
            currentFolderId: parseInt(p_.content[p_.side].folderId, 10),
            newFolderId: parseInt(p_.content[p_.side].folderId, 10),
        }
    }
    return data
}

const getFoldersList = (p_: any) => {
    let folders: { id: number }[] = []
    if (p_.content[p_.side]) {
        folders = p_.content[p_.side].folders
        p_.content[p_.side].options.forEach((option: { id: number }) => {
            if (p_.content[p_.side].isDirectory(option)) {
                folders = folders.filter((folder) => folder.id !== option.id)
            }
        })
    }
    return folders
}

const getState = (p_: any) => {
    const additionContentData = getAdditionContentData(p_)
    const fileData = getFileData(p_)
    const mergedData = { ...additionContentData, ...fileData }

    return {
        data: mergedData,
        folders: getFoldersList(p_),
        editTitle: getEditTitle(p_),
    }
}

interface IState {
    active?: number[]
    data: any
    folders: number[]
    visible?: boolean
    index?: number
    editTitle?: string
}

const Content = (p_: {
    isEditable: boolean
    onSubmit?: (side: string, type: string, data?: any) => void
    selectedSide: string
    side: string
    content?: any
    isDeletable: boolean
    listItem?: any
    onSelectInfo?: (listItem: any) => void
    contentWrapperClassName?: string | object
    extraComponent?: any
    listItemProps?: any
    conflicts: Conflicts[]
    onEditItem: (listItem: any) => void
    onChange?: (selected: any[], side?: string) => void
    emptyText?: any
    bottomContent?: {
        [index: string]: any
    }
    customContent?: any
    isShowActions: boolean
    showAlwaysCustomContent?: boolean
    isEmpty: any
    onGetFoldersOnMultiple: () => void
}) => {
    let prevProps: any = null
    const [state, setState] = useState<IState>({
        active: [],
        data: {},
        folders: [],
        visible: false,
        index: 0,
    })

    useEffect(() => {
        prevProps = p_
    }, [])

    const usePrevious = (value: any) => {
        const ref = useRef()
        useEffect(() => {
            ref.current = value
        })
        return ref.current
    }

    let prevPropsHook: any = usePrevious(p_)

    useEffect(() => {
        if (!p_.isEditable || !prevPropsHook) {
            return
        }

        const prevData = getState(prevPropsHook)
        const nextData = getState(p_)

        if (!deepEqual(prevData, nextData)) {
            setState((prevState: any) => {
                return { ...prevState, ...nextData }
            })
        }

        prevPropsHook = p_
    }, [p_])

    const cancelRemove = () => {
        setState((prevState) => {
            return { ...prevState, active: [] }
        })
    }

    const getIcon = (item: { fileType: string; type: string; isDirectory: number; id: number }, conflicts: any) => {
        let icon = 'group_files'

        if (item.fileType) {
            icon = filesHelpers.getFileIconName(item)
        }
        if (item.type === 'group' || item.isDirectory || broadcastsHelpers.isDirectory(item)) {
            icon = 'files'
        }
        if (item.type === 'schedule') {
            icon = 'date'
        }
        if (conflicts && conflicts.indexOf(item.id) !== -1) {
            return <Icon type="attention" color="error" />
        } else {
            return <Icon type={icon} />
        }
    }

    const moveItem = () => {
        const s_ = state

        return {
            id: 'edit',
            icon: 'move',
            tooltip: {
                title: translate('move'),
            },
            children: (
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        setState((prevState) => {
                            return { ...prevState, active: [] }
                        })

                        if (p_.onSubmit) {
                            p_.onSubmit(p_.selectedSide, 'edit', s_.data)
                        }
                    }}
                    className={cn(grid.colCenter, grid.p_md, textStyles.center)}
                >
                    <div className={cn(grid.w100, grid.mb_mini)}>
                        <Typography className={cn(grid.mb_mini, textStyles.up)} type="title">
                            {s_.editTitle}
                        </Typography>
                        <div className={grid.w100}>
                            <Dropdown
                                value={s_.data.newFolderId}
                                options={s_.folders}
                                placeholder={translate('chooseFolder')}
                                searchable
                                centered
                                type="tree"
                                onChange={(selected) =>
                                    setState((prevState: IState) => {
                                        return { ...prevState, data: { ...s_.data, newFolderId: selected.id } }
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className={cn(grid.w100, grid.mb_mini, styles.additionContent)}>
                        {isFolderChanged(s_) && isAdditionContentExist(p_)
                            ? p_.content[p_.side].additionContent.map(
                                  ({ title, name, initValue, type, options }: any) => {
                                      switch (type) {
                                          case 'checkbox':
                                              return (
                                                  <div className={styles.additionContent__item} key={title}>
                                                      <Checkbox
                                                          onClick={(checked: boolean) =>
                                                              setState((prevState) => {
                                                                  return {
                                                                      ...prevState,
                                                                      data: { ...s_.data, [name]: checked },
                                                                  }
                                                              })
                                                          }
                                                          className={cn(styles.additionContent__checkbox)}
                                                          labelClassName={cn(styles.additionContent__label)}
                                                          checked={s_.data[name]}
                                                          label={title}
                                                      />
                                                  </div>
                                              )
                                          case 'radio':
                                              return (
                                                  <div className={styles.additionContent__item} key={title}>
                                                      <div className={styles.additionContent__title}>{title}</div>
                                                      {options &&
                                                          isNotEmptyArray(options) &&
                                                          options.map(
                                                              ({ label, value }: { label: string; value: string }) => {
                                                                  return (
                                                                      <Radio
                                                                          key={label}
                                                                          className={cn(styles.additionContent__radio)}
                                                                          label={label}
                                                                          selected={s_.data[name] === value}
                                                                          onClick={() =>
                                                                              setState((prevState) => {
                                                                                  return {
                                                                                      ...prevState,
                                                                                      data: {
                                                                                          ...s_.data,
                                                                                          [name]: value,
                                                                                      },
                                                                                  }
                                                                              })
                                                                          }
                                                                      />
                                                                  )
                                                              }
                                                          )}
                                                  </div>
                                              )
                                          default:
                                              return null
                                      }
                                  }
                              )
                            : null}
                    </div>
                    <Button type="submit" mod="withBorder">
                        {translate('save')}
                    </Button>
                </form>
            ),
        }
    }

    const deleteItem = () => {
        return {
            id: 'delete',
            icon: 'delete',
            show: p_.isDeletable,
            tooltip: {
                title: translate('delete'),
            },
            children: (
                <form
                    onSubmit={(e) => {
                        e.preventDefault()

                        if (p_.onSubmit) {
                            p_.onSubmit(p_.selectedSide, 'delete')
                        }

                        setState((prevState) => {
                            return { ...prevState, active: [] }
                        })
                    }}
                    className={cn(grid.colCenter, grid.p_md)}
                >
                    <div className={cn(grid.w100, grid.mb_md, textStyles.center)}>{translate('deleteApprove')}</div>
                    <div className={cn(grid.row, grid.justify, grid.mb_mini)}>
                        <Button type="submit" mod="fill" className={cn(grid.mr_mini)}>
                            {translate('yes')}
                        </Button>
                        <Button mod={'withBorder'} onClick={cancelRemove}>
                            {translate('no')}
                        </Button>
                    </div>
                </form>
            ),
        }
    }

    const topListItemClick = (listItem: any) => {
        if (p_.onSelectInfo) {
            p_.onSelectInfo(listItem)
        }
    }

    const s_ = state
    const listItemSide = function (side: string) {
        return [p_.listItem].some(function (o) {
            return (o || {})[side]
        })
    }
    const location = { ...history.location }

    return (
        <div className={cn(styles.content)}>
            {p_.content && p_.content[p_.side] && p_.selectedSide === p_.side && !p_.isEmpty[p_.side] && (
                <div
                    className={cn(
                        p_.side === 'right' ? styles.contentWrapper : styles.wrapperBack,
                        p_.contentWrapperClassName
                    )}
                >
                    {p_.content[p_.side].title && (
                        <div className={cn(grid.p_md, textStyles.center)}>
                            <Typography type="title">{p_.content[p_.side].title}</Typography>
                            {p_.content[p_.side].description && (
                                <Typography type="text" className={cn(grid.pt_micro, textStyles.mini)}>
                                    {p_.content[p_.side].description}
                                </Typography>
                            )}
                        </div>
                    )}
                    {p_.extraComponent && p_.extraComponent}
                    <div className={styles.toolbarListWrapper}>
                        {p_.content[p_.side].options.map((listItem: any, index: number) => {
                            if (listItemSide(p_.side) && p_.content[p_.side].options.length === 1) {
                                const List = p_.listItem[p_.side]

                                return (
                                    <List
                                        key={`${p_.side}Content__item_${index}`}
                                        {...listItem}
                                        {...p_.listItemProps}
                                        downloadLink={p_.content[p_.side].downloadLink}
                                    />
                                )
                            } else if (typeof listItem.id === 'number') {
                                return (
                                    <>
                                        <div
                                            key={`${p_.side}Content__item_${index}`}
                                            className={cn(grid.p_md, grid.rowCenter, grid.noWrap)}
                                        >
                                            <div className={grid.pr_mini}>{getIcon(listItem, p_.conflicts)}</div>
                                            <div
                                                className={styles.sidebarTopListItem}
                                                // @ts-ignore
                                                onClick={topListItemClick.bind(this, listItem)}
                                            >
                                                <div
                                                    className={cn(item.ellipsis, {
                                                        [textStyles.redError]: p_.conflicts.indexOf(listItem.id) !== -1,
                                                    })}
                                                >
                                                    <DotTooltip
                                                        tooltip={{
                                                            html: listItem.name ? listItem.name : listItem.title,
                                                        }}
                                                    >
                                                        <div
                                                            className={cn(
                                                                grid.p_micro,
                                                                textStyles.normalPlus,
                                                                item.ellipsis
                                                            )}
                                                        >
                                                            {listItem.name ? listItem.name : listItem.title}
                                                        </div>
                                                    </DotTooltip>
                                                </div>
                                            </div>
                                            <div className={grid.rowCenter}>
                                                {p_.content[p_.side].showEditIcon && (
                                                    <div className={grid.pl_nano}>
                                                        <Tooltip title={translate('edit')} position="left">
                                                            <CircleButton
                                                                onClick={function () {
                                                                    p_.onEditItem(listItem)
                                                                }}
                                                            >
                                                                <Icon type={'pencil'} size={10} />
                                                            </CircleButton>
                                                        </Tooltip>
                                                    </div>
                                                )}
                                                {!p_.content[p_.side].hideDeleteIcon &&
                                                    (location.pathname === '/adBlocks/edit' ? (
                                                        <div className={grid.pl_nano}>
                                                            <Tooltip title={translate('deselect')} position="left">
                                                                <CircleButton
                                                                    onClick={() => {
                                                                        setState((prevState) => {
                                                                            return {
                                                                                ...prevState,
                                                                                visible: true,
                                                                                index: index,
                                                                            }
                                                                        })
                                                                    }}
                                                                    type={'delete'}
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                    ) : (
                                                        <div className={grid.pl_nano}>
                                                            <Tooltip title={translate('deselect')} position="left">
                                                                <CircleButton
                                                                    onClick={() => {
                                                                        let newList = [...p_.content[p_.side].options]
                                                                        newList.splice(index, 1)

                                                                        if (p_.onChange) {
                                                                            p_.onChange(newList, p_.selectedSide)
                                                                        }
                                                                    }}
                                                                    type={'delete'}
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        {location.pathname === '/adBlocks/edit' && s_.index === index && (
                                            <div>
                                                <form
                                                    onSubmit={(e) => {
                                                        e.preventDefault()
                                                        let newList = [...p_.content[p_.side].options]
                                                        newList.splice(index, 1)

                                                        if (p_.onChange) {
                                                            p_.onChange(newList, p_.selectedSide)
                                                        }
                                                    }}
                                                    className={
                                                        s_.visible ? styles.formWrapper : styles.formWrapperDNone
                                                    }
                                                >
                                                    <div className={styles.formTitle}>
                                                        {translate('deleteSelectedContent')}?
                                                    </div>
                                                    <div className={styles.formButtons}>
                                                        <Button
                                                            indentSize="mini"
                                                            type="submit"
                                                            className={grid.mr_mini}
                                                            mod="fill"
                                                            onClick={() =>
                                                                setState((prevState) => {
                                                                    return {
                                                                        ...prevState,
                                                                        visible: false,
                                                                    }
                                                                })
                                                            }
                                                        >
                                                            {translate('yes')}
                                                        </Button>
                                                        <Button
                                                            indentSize="mini"
                                                            onClick={() =>
                                                                setState((prevState) => {
                                                                    return {
                                                                        ...prevState,
                                                                        visible: false,
                                                                    }
                                                                })
                                                            }
                                                        >
                                                            {translate('no')}
                                                        </Button>
                                                    </div>
                                                </form>
                                            </div>
                                        )}
                                    </>
                                )
                            } else {
                                return null
                            }
                        })}
                    </div>
                    {p_.isShowActions && p_.side === 'right' && (
                        <DropMenu
                            active={s_.active}
                            onChange={(active) => {
                                if (active.includes('edit')) {
                                    p_.onGetFoldersOnMultiple()
                                }
                                setState((prevState) => {
                                    return {
                                        ...prevState,
                                        active,
                                    }
                                })
                            }}
                            mod="centered"
                            className={item.blackOpacity}
                            items={p_.isEditable ? [moveItem(), deleteItem()] : [deleteItem()]}
                        />
                    )}
                    {p_.bottomContent && p_.bottomContent[p_.side] && <div>{p_.bottomContent[p_.side]}</div>}
                </div>
            )}
            {p_.customContent && p_.customContent[p_.side] && (!p_.isEmpty[p_.side] || p_.showAlwaysCustomContent) && (
                <div className={cn(p_.side === 'right' ? styles.contentWrapper : styles.wrapperBack)}>
                    {p_.customContent[p_.side]}
                </div>
            )}
            {p_.isEmpty[p_.side] && p_.emptyText[p_.side] && (
                <div className={cn(p_.side === 'right' ? styles.contentWrapper : styles.wrapperBack)}>
                    <div className={cn(styles.empty)}>
                        <div className={`${grid.colCenter} ${grid.w100}`}>
                            {typeof p_.emptyText[p_.side].title === 'object' && (
                                <div>{p_.emptyText[p_.side].title}</div>
                            )}
                            {typeof p_.emptyText[p_.side].title === 'string' && (
                                <Typography type="title" className={cn(textStyles['normalPlus'], textStyles.up)}>
                                    {p_.emptyText[p_.side].title}
                                </Typography>
                            )}
                            <div className={styles.description}>
                                <p>{p_.emptyText[p_.side].description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

Content.defaultProps = {
    conflicts: [],
    onEditItem: () => {},
}

export default Sidebar
