import { addStyles } from 'ethcss'
import { IStyles } from 'theme'

export const styles: IStyles = {
    map: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
}

addStyles(styles)
