import { useState, MouseEvent } from 'react'
import { ITabsHook, TTabId } from './Tabs-types'

export const useTabs = ({ active, children, disabled, onChange }: ITabsHook) => {
    const [activeTab, setActiveTab] = useState(() => active || children[0].props.tabid)

    const getContentFromTabId = () => children.find((child) => child.props.tabid === activeTab)

    const onClickTab = (event: MouseEvent<HTMLDivElement>) => {
        if (disabled) {
            return
        }

        const eventTarget = event.target as HTMLDivElement
        const tabid: TTabId = eventTarget.dataset.tabid

        setActiveTab(tabid)
        onChange(tabid, event)
    }

    return {
        activeTab,
        setActiveTab,
        getContentFromTabId,
        onClickTab,
    }
}
