import { addStyles } from 'ethcss'
import { grid, IStyles, item } from '../../theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { mediaCSS, textSizes } from 'theme/media'

const Styles: IStyles = {
    adBlockWrapper: {
        [mediaCSS.tab]: {
            paddingTop: 60,
        },
    },
}

addStyles(Styles)
export default Styles
