import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { api } from 'core/api/ConnectionManager'
import { IDevice } from 'core/models/DigitalSignage'
import { isNotEmptyArray } from 'core/utils/coreUtil'
import { updateURLProtocol } from 'core/utils/convertUtil'
import helpers from 'core/helpers'
import { changeLocation, history, routes } from 'features/routes'
import { transitions } from 'theme'
import { displaysAddActions } from './__add/displays__add.state'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { displaysAddGroupActions } from './__addGroup/displays__addGroup.state'
import displaysHelpers from 'core/helpers/_digitalSignages'
import { TagShortModel } from 'core/models/Tags'
import { appActions } from '../../blocks.app/app/app.state'

export interface IModalOptions {
    mod?: string
    showCloseIcon?: boolean
    licenseId?: number | null | string
}

export interface IDisplaysModal {
    modalState: string | null
    modalOptions: IModalOptions
}

export interface IdentificationItem {
    id: number
    timeoutS: number
}

export interface SendIdentificationRequest {
    showQ: boolean
    groupId?: number[]
    digitalSignageIdList?: number[]
}

export interface SendIdentificationResponse {
    timeoutS: number
}

export interface ClientLogsData {
    startDate?: string
    endDate?: string
    actual?: boolean
    id: number
}

export interface SendClientLogsResponse {
    fileURL: string
}

export interface ApproveData {
    licenseId?: number | string | null
}

export interface ApproveDisplay {
    groupId: number
    approveToken: string
    licenseId?: number | null | string
}
export interface IDisplaysState {
    modalState: string | null
    modalOptions: IModalOptions
    approveToken: string
    error: string | null
    toolbar: string
    groups: IDevice[]
    tags: any[]
    identificatedItems: IdentificationItem[]
    withoutAddress: IDevice[]
    isConnected: boolean
}

const initialState: IDisplaysState = {
    modalState: null,
    modalOptions: {},
    approveToken: '',
    error: null,
    toolbar: 'displayInfo',
    groups: [],
    tags: [],
    identificatedItems: [],
    withoutAddress: [],
    isConnected: false,
}

const getDisplayAndGroupIdLists = (devices: IDevice[]) => {
    let selectedList = [...devices]
    let digitalSignageIdList: number[] = []
    let groupIdList: number[] = []

    selectedList
        .filter(({ status }) => status === 'online')
        .filter(
            ({ type, identificationImplementedQ }) =>
                type === 'group' || (type === 'digitalSignage' && identificationImplementedQ)
        )
        .forEach(({ type, id }) => {
            switch (type) {
                case 'group': {
                    groupIdList.push(id)
                    break
                }
                default: {
                    digitalSignageIdList.push(id)
                    break
                }
            }
        })

    return {
        digitalSignageIdList,
        groupIdList,
    }
}

export const enableIdentification = createAsyncThunk(
    'displays/enableIdentification',
    async (devices: IDevice[], { getState, dispatch, rejectWithValue }) => {
        let data
        let { groupIdList, digitalSignageIdList } = getDisplayAndGroupIdLists(devices)

        if (isNotEmptyArray(groupIdList)) {
            data = { groupId: groupIdList }
        }

        if (isNotEmptyArray(digitalSignageIdList)) {
            data = { digitalSignageId: digitalSignageIdList }
        }

        if (!data) return []

        try {
            const response = await api.send<SendIdentificationRequest, SendIdentificationResponse>(
                'sendIdentification',
                {
                    showQ: true,
                    ...data,
                }
            )

            return [
                ...groupIdList.map((id) => ({ id, timeoutS: response.timeoutS })),
                ...digitalSignageIdList.map((id) => ({ id, timeoutS: response.timeoutS })),
            ]
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const disableIdentification = createAsyncThunk(
    'displays/disableIdentification',
    async (devices: IDevice[], { getState, dispatch, rejectWithValue }) => {
        let data
        let { groupIdList, digitalSignageIdList } = getDisplayAndGroupIdLists(devices)

        if (isNotEmptyArray(groupIdList)) {
            data = { groupId: groupIdList }
        }

        if (isNotEmptyArray(digitalSignageIdList)) {
            data = { digitalSignageId: digitalSignageIdList }
        }

        if (!data) return []

        try {
            await api.send<SendIdentificationRequest, SendIdentificationResponse>('sendIdentification', {
                showQ: false,
                ...data,
            })

            return [...groupIdList, ...digitalSignageIdList]
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const getClientLogs = createAsyncThunk(
    'displays/getClientLogs',
    async (data: ClientLogsData, { getState, dispatch, rejectWithValue }) => {
        try {
            await api.send('getClientLogs', data)
            return true
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const downloadLogsLink = createAsyncThunk(
    'displays/downloadLogsLink',
    async (data: SendClientLogsResponse, { getState, dispatch, rejectWithValue }) => {
        const { fileURL } = data

        if (!fileURL) {
            dispatch(emitError('logsError'))
            return rejectWithValue(null)
        }

        let link = document.createElement('a')
        const updatedUrl = updateURLProtocol(fileURL)
        link.href = updatedUrl
        link.click()

        return true
    }
)

export const getDisplaysWithoutAddress = createAsyncThunk(
    'displays/getDisplaysWithoutAddress',
    async (data, { getState, dispatch, rejectWithValue }) => {
        const address = { lat: null, lng: null }

        try {
            const withoutAddress = await api.send<typeof address, IDevice[]>('getDisplays', address)
            return withoutAddress
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const openApprove = createAsyncThunk(
    'displays/openApprove',
    async (code: string, { getState, dispatch, rejectWithValue }) => {
        if (helpers.getPathname(history.location.pathname) === routes.displays.path) {
            dispatch(openApproveMethod(code))
            return
        }

        dispatch(openApproveWithReload(code))
    }
)

export const openApproveWithReload = createAsyncThunk(
    'displays/openApproveWithReload',
    async (code: string, { getState, dispatch, rejectWithValue }) => {
        changeLocation(`/${routes.displays.path}`)

        setTimeout(() => {
            dispatch(openApproveMethod(code))
        }, transitions.normal)
    }
)

export const openApproveMethod = createAsyncThunk(
    'displays/openApproveMethod',
    async (code: string, { getState, dispatch, rejectWithValue }) => {
        dispatch(displaysAddActions.clearData())
        return code
    }
)

export const addNewGroup = createAsyncThunk(
    'displays/addNewGroup',
    async (data, { getState, dispatch, rejectWithValue }) => {
        if (helpers.getPathname(history.location.pathname) === routes.displays.path) {
            dispatch(addNewGroupMethod())
        } else {
            changeLocation(`/${routes.displays.path}`)
            setTimeout(() => {
                dispatch(addNewGroupMethod())
            }, transitions.normal)
        }
    }
)

export const addNewGroupMethod = createAsyncThunk(
    'displays/addNewGroupMethod',
    async (data, { getState, dispatch, rejectWithValue }) => {
        const query = getURLSearchParamsByLocation(history.location)
        const currentGroupId = parseInt(query.parentId, 10)

        dispatch(displaysAddGroupActions.clearData())
        dispatch(displaysAddGroupActions.setPartialData({ parentId: currentGroupId }))

        try {
            await api.send('createGroup', { parentId: currentGroupId })
        } catch (err) {
            dispatch(displaysAddGroupActions.setError('groupExistError'))
        }
    }
)

export const approve = createAsyncThunk(
    'displays/approve',
    async (data: ApproveData, { getState, dispatch, rejectWithValue }) => {
        const state = getState() as { displays: IDisplaysState }
        const { approveToken: approveTokenWithMask } = state.displays
        const query = getURLSearchParamsByLocation(history.location)
        const groupId = parseInt(query.parentId, 10)
        const { licenseId } = data
        if (!groupId) return false

        const approveToken = displaysHelpers.getApproveToken(approveTokenWithMask)

        if (!approveToken) {
            return rejectWithValue(null)
        }

        dispatch(
            sendApproveDisplay({
                approveToken,
                groupId,
                licenseId,
            })
        )

        return true
    }
)

export const sendApproveDisplay = createAsyncThunk(
    'displays/sendApproveDisplay',
    async (data: ApproveDisplay, { getState, dispatch, rejectWithValue }) => {
        try {
            const response = await api.send<ApproveDisplay, IDevice>('approveDisplay', { ...data })

            const { groupId } = data
            const device = {
                ...response,
                coder: [],
                groupId,
                groupScheduleMode: 'apply',
            }

            dispatch(displaysAddActions.setData({ data: device, isEdit: true }))

            return { data }
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

const displaysSlice = createSlice({
    name: 'displays',
    initialState,
    reducers: {
        deleteIdentificatedItem(state, action: PayloadAction<number>) {
            state.identificatedItems = state.identificatedItems.filter((item) => item.id !== action.payload)
        },
        addIdentificatedItem(state, action: PayloadAction<IdentificationItem>) {
            state.identificatedItems = [...state.identificatedItems, action.payload]
        },
        openModal(state, action: PayloadAction<IDisplaysModal>) {
            state.modalState = action.payload.modalState
            state.modalOptions = action.payload.modalOptions
        },
        closeModal(state) {
            state.modalOptions = {}
            state.modalState = null
        },
        addApproveToken(state, action: PayloadAction<string>) {
            state.approveToken = action.payload
            state.error = null
        },
        setGroups(state, action: PayloadAction<IDevice[]>) {
            state.groups = action.payload
        },
        setTags(state, action: PayloadAction<TagShortModel[]>) {
            state.tags = action.payload
        },
        clearState(state) {
            state.modalState = null
            state.modalOptions = {}
            state.approveToken = ''
            state.error = null
            state.toolbar = 'displayInfo'
            state.groups = []
            state.tags = []
            state.identificatedItems = []
            state.withoutAddress = []
            state.isConnected = false
        },
        clearMap(state) {
            if (state.withoutAddress.length > 0) {
                state.withoutAddress = []
            }
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(enableIdentification.fulfilled, (state, { payload }) => {
                state.identificatedItems = [...state.identificatedItems, ...payload]
            })
            .addCase(enableIdentification.rejected, (state, { payload }) => {})
            .addCase(disableIdentification.fulfilled, (state, { payload }) => {
                state.identificatedItems = state.identificatedItems.filter((item) => !payload.includes(item.id))
            })
            .addCase(getClientLogs.fulfilled, (state, { payload }) => {
                state.isConnected = true
            })
            .addCase(downloadLogsLink.fulfilled, (state, { payload }) => {
                state.isConnected = false
            })
            .addCase(downloadLogsLink.rejected, (state, { payload }) => {
                state.isConnected = false
            })
            .addCase(getDisplaysWithoutAddress.fulfilled, (state, { payload }) => {
                state.withoutAddress = payload
            })
            .addCase(getDisplaysWithoutAddress.rejected, (state, { payload }) => {})
            .addCase(openApproveMethod.fulfilled, (state, { payload }) => {
                state.modalOptions = {
                    showCloseIcon: false,
                }

                state.modalState = 'approve'
                state.approveToken = payload
                state.error = null
            })
            .addCase(approve.rejected, (state, { payload }) => {
                state.error = 'emptyCodeError'
            })
            .addCase(sendApproveDisplay.fulfilled, (state, { payload }) => {
                state.modalState = 'add'
                state.modalOptions = {
                    showCloseIcon: true,
                    licenseId: payload.data.licenseId,
                }
            }),
})

const { reducer: displaysReducer, actions: displaysActions } = displaysSlice
export { displaysReducer, displaysActions }
