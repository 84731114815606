import React from 'react'
import PropTypes from 'prop-types'
import EditText from 'blocks.simple/editText/editText'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './deviceSettings__pinCode.jcss'

const DeviceSettingsPinCode = (p_: {
    enabled: boolean
    className: string | object
    value: string
    onChange: (formattedCode: string, pin: string) => void
}) => {
    function onChangePinCode(code: string) {
        const formattedCode = code.split(' ').join('')
        p_.onChange(formattedCode, 'pinCode')
    }

    if (!p_.enabled) {
        return null
    }

    return (
        <div className={cn(p_.className)}>
            <EditText
                mod={'withBorder'}
                className={styles.input}
                placeholder="_ _ _ _"
                label={translate('lockCode')}
                mask="9 9 9 9"
                type="text"
                value={p_.value}
                onChange={onChangePinCode}
            />
        </div>
    )
}

DeviceSettingsPinCode.propTypes = {
    value: PropTypes.string,
    enabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default React.memo(DeviceSettingsPinCode)
