import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { defaultThemeStyles } from './TableEditor-theme'
import { defaultThemeStyles as defaultThemeTabsStyles } from 'blocks.simple/tabs/tabs.theme'
import { IStyles, mediaCSS } from '../../theme'

const tableEditorStyles: IStyles = {
    wrapper: {},
    previewWrapper: {
        ...grid.mt_md,
        ...grid.p_mdPlus,
        ...grid.colCenter,
        ...item.rounded_top_mini,
        backgroundColor: `var(--var-module-app-component-tableEditor-style-background, ${defaultThemeStyles.background})`,

        [mediaCSS.tabMini]: {
            padding: 0,
        },
    },
    smallPreviewWrapper: {
        ...grid.mt_md,
        ...grid.p_mini,
        ...grid.colCenter,
        ...item.rounded_top_mini,
        backgroundColor: `var(--var-module-app-component-tableEditor-style-background, ${defaultThemeStyles.background})`,
    },
    preview: {
        ...grid.w60,
        ...grid.p_md,
        ...item.rounded_full_mini,
        backgroundColor: `var(--var-module-app-component-tableEditor-style-previewBorder, ${defaultThemeStyles.previewBorder})`,

        [mediaCSS.tabMini]: {
            width: '100%',
        },
    },
    smallPreview: {
        ...grid.w100,
        ...grid.p_md,
        ...item.rounded_full_mini,
        backgroundColor: `var(--var-module-app-component-tableEditor-style-previewBorder, ${defaultThemeStyles.previewBorder})`,
    },
    tabs: {
        ...grid.row,
        backgroundColor: `var(--var-module-app-component-tableEditor-style-background, ${defaultThemeStyles.background})`,
        overflow: 'hidden',
    },
    content: {
        ...grid.rowCenter,
        ...item.panel,
        borderTopLeftRadius: '0 !important',
        borderTopRightRadius: '0 !important',
        '&>div': {
            ...grid.w100,
        },
    },
    contentPanel: {
        '&>div': {
            width: '50% !important',

            [mediaCSS.tabMini]: {
                width: '100% !important',
            },
        },
    },
    contentPanelSmall: {
        '&>div': {
            width: '95% !important',
        },
    },
    textContent: {
        padding: '0px !important',
    },
    item: {
        ...grid.space,
        ...grid.rowCenter,
        ...grid.noWrap,
        ...grid.p_mini,
        cursor: 'pointer',
        backgroundColor: `var(--var-module-ui-component-tabs-style-background, ${defaultThemeTabsStyles.background})`,
        textDecoration: 'none',
        color: `var(--var-module-ui-component-tabs-style-textMain, ${defaultThemeTabsStyles.textMain})`,
        border: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        borderLeft: 0,
        '&:last-child': {
            borderRight: 'none',
        },
    },
    itemActive: {
        backgroundColor: `var(--var-module-ui-component-tabs-style-activeBackground, ${defaultThemeTabsStyles.activeBackground})`,
        color: `var(--var-module-ui-component-tabs-style-active, ${defaultThemeTabsStyles.active})`,
    },
    disabled: {
        cursor: 'not-allowed !important',
    },
    buttonWrapperSm: {
        display: 'flex',
        [mediaCSS.mini]: {
            display: 'block',
        },
    },
    tabBtn: {
        [mediaCSS.tab]: {
            margin: '0 auto 14px auto !important',
        },
    },
}

addStyles(tableEditorStyles)
export default tableEditorStyles
