import { addStyles } from 'ethcss'
import { item, IStyles } from '../../theme'
import 'react-toastify/dist/ReactToastify.css'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'

const toastStyles: IStyles = {
    wrapper: {
        fontFamily: `var(--var-module-app-component-app-style-fontFamily, ${defaultThemeStyles.fontFamily})!important`,
        ...item.rounded_full_micro,
        '&.Toastify__toast--success': {
            ...item.online,
        },
        '&.Toastify__toast--error': {
            ...item.errorLight,
        },
        '&.Toastify__toast--info': {
            ...item.selected,
        },
        '&.Toastify__toast--default': {
            ...item.whiteColor,
            ...item.greyEditor,
        },
    },
}

addStyles(toastStyles)
export default toastStyles
