import React from 'react'
import config from '../index'
import CustomListHeader, { ICustomListHeaderProps } from '../../../__header/customList__header'
import EditableText from 'blocks.simple/editableText/editableText'
import Button from 'blocks.simple/button/button'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import helpers from 'core/helpers'
import { api } from 'core/api/ConnectionManager'
import { cn } from 'ethcss'
import translate from 'core/translate'
import grid from 'blocks.simple/grid/grid.jcss'

export default {
    ...config,
    category: 'menuProducts',
    type: 'drinks',
    ...helpers.createListeners({
        category: 'menuProducts',
        type: 'drinks',
        onCreate: 'burgersProductCreated',
        onUpdate: 'burgersProductUpdated',
        onDelete: 'burgersProductDeleted',
    }),
    headerFilters: (p_: ICustomListHeaderProps) => (
        <CustomListHeader
            onAdd={() => p_.onAddFakeLine({ title: '' })}
            title={translate('menuProducts')}
            type={p_.type}
            onDelete={() => {
                const id = p_.selectedItems.map((selectedItem) => selectedItem.id)
                if (id.length > 0) {
                    api.send('deleteBurgersProduct', { id })
                }
                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            }}
            {...p_}
        />
    ),
    fields: () => [
        { id: 'activeOn', name: translate('activeOn') },
        { id: 'title', name: translate('name') },
        { id: 'volume', name: translate('volumeLabel') },
        { id: 'type', name: translate('type') },
        { id: 'price', name: translate('price') },
    ],
    list: {
        title: (
            p_: { id: number; title: string },
            active: boolean,
            listProps: {
                template: any
                source: { id: number }
                fakeLine: object
                onAddFakeLine: (value?: {}) => void
            }
        ) => (
            <EditableText
                id={p_.id}
                text={p_.title}
                onChange={(title: string) => {
                    listProps.onAddFakeLine({ ...listProps.fakeLine, title })
                }}
                onUpdate={(title, cb) => {
                    api.send('updateBurgersProduct', {
                        title,
                        id: p_.id,
                        catalogType: listProps.template.type,
                        categoryName: listProps.template.category,
                        webAppSourceId: listProps.source.id,
                    }).then(cb)
                }}
            />
        ),
        volume: (
            p_: { id: number; volume: number },
            active: boolean,
            listProps: {
                template: any
                source: { id: number }
                fakeLine: object
                onAddFakeLine: (value?: {}) => void
            }
        ) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.id })}>
                <div className={cn({ [grid.fullWidth]: p_.id, [grid.space]: !p_.id })}>
                    <EditableText
                        id={p_.id}
                        text={p_.volume || ''}
                        type={'number'}
                        placeholder={translate('emptyLabelVolumeError')}
                        disableDefaultFocus={true}
                        onChange={(volume: number) => {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, volume })
                        }}
                        onUpdate={(volume, cb) => {
                            api.send('updateBurgersProduct', {
                                catalogType: listProps.template.type,
                                categoryName: listProps.template.category,
                                webAppSourceId: listProps.source.id,
                                id: p_.id,
                                volume,
                            }).then(cb)
                        }}
                    />
                </div>
            </div>
        ),
        price: (
            p_: { id: number; price: string },
            active: boolean,
            listProps: {
                template: any
                source: { id: number }
                fakeLine: { activeOn: boolean; title: string; price: string }
                onAddFakeLine: (value?: {}) => void
            }
        ) => (
            <div className={grid.fullWidth}>
                <div className={grid.fullWidth}>
                    <EditableText
                        id={p_.id}
                        text={p_.price || ''}
                        type={'text'}
                        placeholder={translate('emptyLabelPriceError')}
                        disableDefaultFocus={true}
                        onChange={(price: string) => {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, price })
                        }}
                        onUpdate={(price, cb) => {
                            api.send('updateBurgersProduct', {
                                catalogType: listProps.template.type,
                                categoryName: listProps.template.category,
                                webAppSourceId: listProps.source.id,
                                id: p_.id,
                                price,
                            }).then(cb)
                        }}
                    />
                </div>
                {!p_.id && (
                    <div className={cn(grid.fullWidth, grid.pt_mini, grid.rowCenter)}>
                        <Button
                            className={cn(grid.ml_micro, grid.mr_mini)}
                            indentSize={'mini'}
                            textSize={'mini'}
                            rounded={'full_normal'}
                            onClick={function () {
                                listProps.onAddFakeLine()
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                        <Button
                            mod={'fill'}
                            indentSize={'mini'}
                            textSize={'mini'}
                            rounded={'full_normal'}
                            onClick={function () {
                                api.send('createBurgersProduct', {
                                    catalogType: listProps.template.type,
                                    categoryName: listProps.template.category,
                                    webAppSourceId: listProps.source.id,
                                    ...listProps.fakeLine,
                                })
                            }}
                            disabled={
                                !listProps.fakeLine.activeOn || !listProps.fakeLine.title || !listProps.fakeLine.price
                            }
                        >
                            {translate('save')}
                        </Button>
                    </div>
                )}
            </div>
        ),
        type: (
            p_: { id: number; type: string },
            active: boolean,
            listProps: {
                template: any
                source: { id: number }
                onAddFakeLine: (value?: {}) => void
                fakeLine: object
            }
        ) => (
            <div className={grid.fullWidth}>
                <Dropdown
                    value={p_.type}
                    options={[
                        { id: null, name: translate('notSelected') },
                        { id: 'draft', name: translate('drinksDraft') },
                    ]}
                    placeholder={translate('notSelected')}
                    onChange={function (selected) {
                        if (p_.id) {
                            api.send('updateBurgersProduct', {
                                catalogType: listProps.template.type,
                                categoryName: listProps.template.category,
                                webAppSourceId: listProps.source.id,
                                id: p_.id,
                                type: selected.id,
                            })
                        } else {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, type: selected.id })
                        }
                    }}
                />
            </div>
        ),
        activeOn: (
            p_: { id: number; activeOn: boolean[] },
            active: boolean,
            listProps: {
                additionalData: { sliderProducts: any[] }
                template: any
                source: { id: number }
                onAddFakeLine: (value?: {}) => void
                fakeLine: object
            }
        ) => {
            let activeOn = null
            if (p_.activeOn) {
                activeOn = p_.activeOn[0]
            }

            return (
                <div className={grid.fullWidth}>
                    <Dropdown
                        value={activeOn}
                        options={listProps.additionalData.sliderProducts}
                        labelField={'title'}
                        placeholder={translate('activeOn')}
                        onChange={function (selected) {
                            if (p_.id) {
                                api.send('updateBurgersProduct', {
                                    catalogType: listProps.template.type,
                                    categoryName: listProps.template.category,
                                    webAppSourceId: listProps.source.id,
                                    id: p_.id,
                                    activeOn: [selected.id],
                                })
                            } else {
                                listProps.onAddFakeLine({ ...listProps.fakeLine, activeOn: [selected.id] })
                            }
                        }}
                    />
                </div>
            )
        },
    },
    fixedColWidth: true,
}
