import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect, { components } from 'react-select'
import { Icon } from 'molecules/Icon'

import translate from 'core/translate'

import { cn } from 'ethcss'
import { grid, item, text } from 'theme'
import styles from './styles'
import { defaultThemeStyles } from './Dropdown-theme'

const dropdownCustomStyles = (p_) => ({
    container: (provided, state) => ({
        ...provided,
        ...(state.isDisabled ? item.disableWrapper : {}),
    }),
    control: (provided, state) => {
        return {
            ...provided,
            backgroundColor: `var(--var-module-ui-component-newDropdown-style-control, ${defaultThemeStyles.control})`,
            border: `1px solid var(--var-module-ui-component-newDropdown-style-controlBorder ${defaultThemeStyles.control})`,
            cursor: 'pointer',
            boxShadow: 'none',
            '&>div': {
                ...grid.row,
                ...grid.noWrap,
            },
            ...p_.controlStyles,
        }
    },
    singleValue: (provided) =>
        p_.noOverflow
            ? {
                  ...provided,
                  ...p_.inputStyles,
                  lineHeight: 1.5,
                  overflow: 'visible',
              }
            : {
                  ...provided,
                  ...p_.inputStyles,
                  lineHeight: 1.5,
              },
    input: (provided) => ({
        ...provided,
        ...p_.inputStyles,
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: `var(--var-module-ui-component-newDropdown-style-menuBackground, ${defaultThemeStyles.menuBackground})`,
        ...item.shadowMd,
        marginTop: 0,
        ...p_.menuStyles,
    }),
    option: (provided) => ({
        cursor: 'pointer',
        '&:hover': {
            color: `var(--var-module-ui-component-newDropdown-style-controlText, ${defaultThemeStyles.controlText})`,
            opacity: 0.6,
        },
        ...p_.optionStyles,
    }),
    placeholder: (provided) => ({
        color: 'inherit',
        opacity: 0.3,
        ...grid.pl_mini,
    }),
    menuList: (provided) => ({
        ...provided,
        margin: 0,
    }),
})

const CustomSeparator = () => null
const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <Icon type={props.selectProps.menuIsOpen ? 'arrowhead_up' : 'arrowhead_down'} size={10} />
            </components.DropdownIndicator>
        )
    )
}

const Dropdown = (p_) => (
    <div className={cn(p_.wrapperClassName)}>
        {p_.label && <label className={cn(p_.labelClassName, text[p_.fontColor], text[p_.fontSize])}>{p_.label}</label>}
        <ReactSelect
            value={p_.value}
            options={p_.options}
            styles={dropdownCustomStyles(p_)}
            isSearchable={p_.searchable}
            isDisabled={p_.disabled}
            onChange={p_.onChange}
            placeholder={p_.placeholder ? p_.placeholder : translate('select')}
            noOptionsMessage={() => p_.noOptionsMessage}
            components={{ IndicatorSeparator: CustomSeparator, DropdownIndicator }}
            isClearable={p_.searchable}
            menuPortalTarget={p_.menuPortalTarget}
            autoFocus={false}
        />
    </div>
)

Dropdown.propTypes = {
    value: PropTypes.object,
    rounded: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    options: PropTypes.array,
    searchable: PropTypes.bool,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    fontColor: PropTypes.string,
    fontSize: PropTypes.string,
    maxMenuHeight: PropTypes.number,
    labelClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    wrapperClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    centered: PropTypes.bool,
    type: PropTypes.string,
    noOverflow: PropTypes.bool,
}

Dropdown.defaultProps = {
    rounded: 'mini',
    controlStyles: {
        ...text.greyDark,
        color: `var(--var-module-ui-component-newDropdown-style-controlText, ${defaultThemeStyles.controlText})`,
        backgroundColor: `var(--var-module-ui-component-newDropdown-style-control, ${defaultThemeStyles.control})`,
        fontWeight: 'bold',
    },
    menuStyles: {
        borderTop: `4px solid var(--var-module-ui-component-newDropdown-style-menuBorder, ${defaultThemeStyles.menuBorder})`,
        backgroundColor: `var(--var-module-ui-component-newDropdown-style-menuBackground, ${defaultThemeStyles.menuBackground})`,
        color: `var(--var-module-ui-component-newDropdown-style-controlText, ${defaultThemeStyles.controlText})`,
        overflow: 'auto',
    },
    optionStyles: {
        color: `var(--var-module-ui-component-newDropdown-style-controlText, ${defaultThemeStyles.controlText})`,
        ...grid.p_md,
    },
    inputStyles: {
        ...grid.pl_mini,
        color: `var(--var-module-ui-component-newDropdown-style-controlText, ${defaultThemeStyles.controlText})`,
    },
    fontColor: 'grey',
    fontSize: 'normal',
    placeholder: undefined,
    noOptionsMessage: (() => translate('noOptions'))(),
    labelClassName: styles.label,
}

export default Dropdown
