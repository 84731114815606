import { addStyles } from 'ethcss'
import { colors, grid, IStyles } from 'theme'
import { defaultThemeStyles } from '../AudioTrack-theme'

export const audioTrackItemStyles: IStyles = {
    AudioItem: {
        ...grid.flex,
        ...grid.pb_micro,
        ...grid.pt_micro,
        ...grid.ba,
        ...grid.baseCenter,
    },
    AudioItem__equalizer: {
        width: 17,
        ...grid.pr_micro,
    },
    AudioItem__fakeIcon: {
        color: colors.white,
        width: 17,
        ...grid.pr_micro,
    },
    AudioItem__icon: {
        width: '100%',
    },
    AudioItem__content: {
        ...grid.flex,
        ...grid.justify,
        fontSize: 14,
        lineHeight: 1.2,
        color: `var(--var-module-audio-component-trackList-style-text, ${defaultThemeStyles.text})`,
    },
    AudioItem__content_active: {
        opacity: 1,
    },
    AudioItem__content_disable: {
        opacity: 1,
    },
    AudioItem__title: {},
    AudioItem__time: {},
}

addStyles(audioTrackItemStyles)
