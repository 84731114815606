import { addStyles } from 'ethcss'
import { grid, item } from 'theme'

const editorAutoPositionAudioStyles = {
    tagsWrapper: {
        ...grid.colCenter,
    },
    inputTitle: {
        display: 'block',
        width: '100%',
        textAlign: 'center',
        ...grid.mb_mini,
    },
    inputWrapper: {
        ...grid.w90,
        ...grid.mb_md,
    },
    controls: {
        ...grid.pl_mini,
        ...grid.pr_mini,
    },
    footer: {
        ...grid.w100,
        ...grid.rowCenter,
        ...grid.mt_md,
    },
    radio: {
        ...grid.mt_mini,
        ...grid.mb_mini,
    },
    wrapper: {
        ...item.rel,
    },
    verticalOffset: {
        ...grid.pt_md,
        ...grid.pb_md,
    },
    orderModeDropdownWrapper() {
        return {
            ...this.controls,
            ...this.verticalOffset,
            ...grid.w50,
        }
    },
    orderModeDropdown: {
        ...grid.w100,
    },
}

addStyles(editorAutoPositionAudioStyles)
export default editorAutoPositionAudioStyles
