import { IActiveMenuItem, IMenuWrapper } from 'core/models/Menu'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from 'core/api/ConnectionManager'
import { ServerInfo, Timezone } from './app-types'
import helpers from 'core/helpers'

export interface ICreateDirectoryWindow {
    title: string
    methodName: any
    fieldParamKey: string
    inputFieldName: string
    placeholder: string
    options: any
}
export interface IAppState {
    timezones: Timezone[]
    theme: string
    isJoined: boolean
    emitted: any
    menu: IMenuWrapper[]
    notificationsCount: number | null
    activeMenuItem: IActiveMenuItem | null
    isMobile: boolean
    minimizeMenu: boolean
    showMobMenu: boolean
    showMobToolbar: boolean
    isToolbarExist: boolean
    appType: string | null
    backEndVersion: string | null
    buildDate: string | number | null
    connectionStatus: string
    monitorWindow: { type: string; title?: string; options?: any } | null
    createDirectoryWindow: ICreateDirectoryWindow | null
    whiteLabelChecked: boolean
    isActiveRemoteSupport: boolean
}

const initialState: IAppState = {
    timezones: [],
    theme: 'default',
    isJoined: false,
    emitted: {},
    menu: [],
    notificationsCount: null,
    activeMenuItem: null,
    minimizeMenu: false,
    isMobile: false,
    showMobMenu: false,
    showMobToolbar: false,
    isToolbarExist: false,
    appType: null,
    backEndVersion: null,
    buildDate: null,
    connectionStatus: 'idle',
    monitorWindow: null,
    createDirectoryWindow: null,
    whiteLabelChecked: false,
    isActiveRemoteSupport: false,
}

export const getMenu = createAsyncThunk('app/getMenu', async (data, { dispatch, rejectWithValue }) => {
    try {
        const menu = await api.send<object, IMenuWrapper[]>('getMenu', {})

        return menu
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getNotificationsCount = createAsyncThunk(
    'app/getNotificationsCount',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const notificationsCount = await api.send<object, any>('getNotificationsCount', {})

            return notificationsCount
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const getServerInfo = createAsyncThunk('app/getServerInfo', async (data, { dispatch, rejectWithValue }) => {
    try {
        const serverInfo = await api.send<object, ServerInfo>('getServerInfo', {})

        return serverInfo
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getTimezonesList = createAsyncThunk(
    'app/getTimezonesList',
    async (locale: string, { dispatch, rejectWithValue }) => {
        try {
            const timezonesData = await api.send<{ locale: string }, Timezone[]>('getTimezonesList', { locale })

            const timezones = timezonesData.map((timezone) => ({
                ...timezone,
                name: `(UTC ${helpers.sToHM(timezone.gmtOffset, '')}) ${timezone.name}`,
            }))

            return timezones
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        toggleRemoteSupport(state, action: PayloadAction<boolean>) {
            state.isActiveRemoteSupport = action.payload
        },
        whiteLabelChecked(state, action: PayloadAction<boolean>) {
            state.whiteLabelChecked = action.payload
        },
        toggleMobile(state, action: PayloadAction<boolean>) {
            state.isMobile = action.payload
        },
        toggleMobMenu(state, action: PayloadAction<boolean>) {
            state.showMobMenu = action.payload
        },
        toggleMobToolbar(state, action: PayloadAction<boolean>) {
            state.showMobToolbar = action.payload
        },
        toggleMobShowToolbarBtn(state, action: PayloadAction<boolean>) {
            state.isToolbarExist = action.payload
        },
        toggleMenuMinimized(state, action: PayloadAction<boolean>) {
            state.minimizeMenu = action.payload
        },
        setActiveMenuItem(state, action: PayloadAction<IActiveMenuItem | null>) {
            state.activeMenuItem = action.payload
        },
        changeConnectionStatus(state, action: PayloadAction<string>) {
            state.connectionStatus = action.payload
        },
        setJoinedStatus(state, action: PayloadAction<boolean>) {
            state.isJoined = action.payload
        },
        setMonitorWindow(state, action: PayloadAction<{ type: string; title?: string; options?: any }>) {
            state.monitorWindow = action.payload
        },
        closeMonitorWindow(state) {
            state.monitorWindow = null
        },
        setCreateDirectoryWindow(state, action: PayloadAction<ICreateDirectoryWindow>) {
            state.createDirectoryWindow = action.payload
        },
        closeCreateDirectoryWindow(state) {
            state.createDirectoryWindow = null
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(getMenu.fulfilled, (state, { payload }) => {
                state.menu = payload
            })
            .addCase(getMenu.rejected, (state) => {
                state.menu = []
            })
            .addCase(getNotificationsCount.fulfilled, (state, { payload }) => {
                state.notificationsCount = payload.notificationCount
            })
            .addCase(getNotificationsCount.rejected, (state) => {
                state.notificationsCount = null
            })
            .addCase(getServerInfo.fulfilled, (state, { payload }) => {
                const { appType, backEndVersion, buildDate } = payload

                state.appType = appType
                state.backEndVersion = backEndVersion
                state.buildDate = buildDate
            })
            .addCase(getServerInfo.rejected, (state) => {
                state.appType = null
                state.backEndVersion = null
                state.buildDate = null
            })
            .addCase(getTimezonesList.fulfilled, (state, { payload }) => {
                state.timezones = payload
            })
            .addCase(getTimezonesList.rejected, (state) => {
                state.timezones = []
            }),
})

const { reducer: appReducer, actions: appActions } = appSlice
export { appReducer, appActions }
