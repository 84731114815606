import { useEffect, useState } from 'react'
import moment from 'moment'
import { getFilterConfing } from './history__filter.config'
import { IItem } from '../__filterList/history__filterList'
import { IUser } from '../../../core/models/Users'

export interface ISelectedFilter {
    id: string
    name: string
    checked?: boolean
}

type Date = {
    startDate?: string
    endDate?: string
}

type FilterField = {
    id: string
    name: string
    index: number
    checked?: boolean
}

const HistoryFilterMethods = (p_: any) => {
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)
    const [selectedFilter, setSelectedFilter] = useState<any>(null)
    const [filterFields, setFilterFields] = useState<FilterField[]>([])
    const [_filterSearch, setFilterSearch] = useState<string>('')
    const [selected, setSelected] = useState<any>({})
    const [startDate, setStartDate] = useState<string>(moment().format())
    const [endDate, setEndDate] = useState<string>(moment().add(1, 'day').format())

    useEffect(() => {
        setSelected(p_.selected)
    }, [p_])

    const filterSearch = (filterSearch: string) => {
        setFilterSearch(filterSearch)
    }

    const openFilter = () => {
        setIsFilterOpen(true)
        setSelectedFilter(null)
        setSelected(p_.selected)
    }

    const closeFilter = () => {
        setIsFilterOpen(false)
    }

    const dateSelectFilter = (selectedFilter: ISelectedFilter) => {
        const selectedFilters = selected

        if (selectedFilter.id === 'date') {
            const days: Date = {}
            let byStartDateChecked = false
            let byEndDateChecked = false

            if (selectedFilters.startDate) {
                days.startDate = moment().format(selectedFilters.startDate)
                byStartDateChecked = true
            }
            if (selectedFilters.endDate) {
                days.endDate = moment().format(selectedFilters.endDate)
                byEndDateChecked = true
            }

            p_.setByEndDateChecked(byEndDateChecked)
            p_.setByStartDateChecked(byStartDateChecked)

            if (days.startDate) {
                setStartDate(days.startDate)
            }
            if (days.endDate) {
                setStartDate(days.endDate)
            }
        }
    }

    const selectFilter = (selectedFilter: ISelectedFilter) => {
        setSelectedFilter(selectedFilter)
        setFilterFields([])
        setFilterSearch('')

        const filterFields: FilterField[] = []
        const selectedFilters = selected

        dateSelectFilter(selectedFilter)

        if (!p_.data[selectedFilter.id]) {
            return
        }

        p_.data[selectedFilter.id].forEach((item: any, index: number) => {
            const field: FilterField = {
                id: item,
                name: item.email ? item.email : item,
                index,
            }

            if (getFilterConfing()[`${selectedFilter.id}Fields`]) {
                let name = item
                const findedName = getFilterConfing()[`${selectedFilter.id}Fields`].find((field: ISelectedFilter) => {
                    return field.id === item
                })

                if (findedName) {
                    name = findedName.name
                }

                field.name = name
            }

            if (selectedFilters && selectedFilters[selectedFilter.id]) {
                if (selectedFilter.id === 'userId') {
                    const id = field.id

                    if (selectedFilters[selectedFilter.id].includes(id)) {
                        field.checked = true
                    }
                } else {
                    if (selectedFilters[selectedFilter.id].includes(field.id)) {
                        field.checked = true
                    }
                }
            }
            filterFields.push(field)
        })

        setFilterFields(filterFields)
    }
    const checkAll = (checked: boolean) => {
        let _filterFields = [...filterFields]

        _filterFields.forEach((field: FilterField) => (field.checked = checked))

        setSelectedMethod(selected, selectedFilter, filterFields)

        setFilterFields(_filterFields)
    }

    const toggleField = (index: number) => {
        const _filterFields = [...filterFields]

        filterFields[index].checked = !filterFields[index].checked

        setSelectedMethod(selected, selectedFilter, _filterFields)
        setFilterFields(_filterFields)
    }
    const setSelectedMethod = (selected: IItem, selectedFilter: ISelectedFilter, filterFields: ISelectedFilter[]) => {
        selected[selectedFilter.id] = filterFields.filter((field) => field.checked).map((field) => field.id)
    }
    const onFilterChange = () => {
        const filter = { ...selected }

        delete filter.startDate
        delete filter.endDate

        for (let key in filter) {
            if (filter.hasOwnProperty(key)) {
                if (key === 'userId') {
                    const usersId = filter[key].map((user: IUser) => user.id)
                    filter['userId'] = usersId.join('//')
                } else {
                    filter[key] = filter[key].join('//')
                }
            }
        }

        if (p_.byStartDateChecked) {
            filter.startDate = moment(startDate).format('YYYY-MM-DD HH:mm:ss')
        }
        if (p_.byEndDateChecked) {
            filter.endDate = moment(endDate).format('YYYY-MM-DD HH:mm:ss')
        }

        closeFilter()
        if (p_.onFilterChange) {
            p_.onFilterChange(filter)
        }
    }

    return {
        openFilter,
        isFilterOpen,
        selectFilter,
        selectedFilter,
        _filterSearch,
        filterSearch,
        checkAll,
        filterFields,
        toggleField,
        startDate,
        endDate,
        closeFilter,
        onFilterChange,
        setStartDate,
        setEndDate,
    }
}

export default HistoryFilterMethods
