import React, { useState } from 'react'
import Sticky from 'react-stickynode'
import styles from './stick.jcss'
import item from 'blocks.simple/item/item.jcss'
import { layers } from 'blocks.app/config'
import { cn } from 'ethcss'

interface IStickProps {
    children: any
    enabled: boolean
    mod: string
    wrapperClassName?: string
    zIndex: number
    top?: number
}

const Stick = (p_: IStickProps) => {
    const [active, setActive] = useState<boolean>(false)

    const handleStateChange = ({ status }: { status: number }) => {
        const active = status === Sticky.STATUS_FIXED
        setActive(active)
    }
    const topForMobile = window.matchMedia('(max-width: 960px)').matches

    return (
        <div className={cn(styles.wrapper, p_.wrapperClassName)}>
            <Sticky
                enabled={p_.enabled}
                activeClass={cn('active', item[p_.mod])}
                innerZ={active ? layers.sticky + 5 : p_.zIndex}
                onStateChange={handleStateChange}
                top={topForMobile ? p_.top : ''}
            >
                {p_.children}
            </Sticky>
        </div>
    )
}

Stick.defaultProps = {
    enabled: false,
    mod: 'white',
    zIndex: 0,
}

export default Stick
