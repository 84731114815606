import React from 'react'

export interface IWithWindowResizeProps {}

export interface IWithWindowResizeState {
    width: number
    height: number
}

export const withWindowResize = (WrappedComponent: any) => {
    return class extends React.Component<any, IWithWindowResizeState> {
        constructor(props: any) {
            super(props)

            this.state = {
                width: window.innerWidth,
                height: window.innerHeight,
            }
        }

        handleResize = () => {
            this.setState(() => {
                return {
                    width: window.innerWidth,
                    height: window.innerHeight,
                }
            })
        }

        componentDidMount() {
            window.addEventListener('resize', this.handleResize)
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.handleResize)
        }

        render() {
            const { width, height } = this.state
            return <WrappedComponent {...this.props} windowWidth={width} windowHeight={height} />
        }
    }
}
