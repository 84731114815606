import React from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import DashboardUserMethods from './dashboard__user.local.methods'
import Card from 'blocks.simple/card/card'
import Animation from 'blocks.simple/animation/animation'

import moment from 'moment'
import translate from 'core/translate'

import styles from './dashboard__user.jcss'
import { grid } from '../../../theme'
import { Typography } from 'atoms/Typography'
import helpers from 'core/helpers'
import { cn } from 'ethcss'

function DashboardUser(p_: any) {
    const { s_ } = DashboardUserMethods()
    const itemRules = p_.rules ? p_.rules : 0

    const user: any = useSelector<RootStateOrAny>((state) => state.user.data)
    const getTitle = () => translate('userLead')

    function renderCard() {
        if (!s_.data.isLoaded) {
            return null
        }

        const roles = helpers.translateRoles(user.roles)

        return (
            <Card
                className={styles.card}
                titleClassName={styles.cartTitle}
                title={getTitle()}
                icon={{
                    type: 'user',
                    size: 20,
                    default: true,
                }}
            >
                <div className={styles.wrapper}>
                    <div className={grid.w100}>
                        <div className={styles.item}>
                            <Typography type="text" className={styles.title}>
                                {translate('email')}
                            </Typography>
                            <Typography type="title" className={styles.info}>
                                {user.email}
                            </Typography>
                        </div>
                        <div className={styles.item}>
                            <Typography type="text" className={styles.title}>
                                {translate('userName')}
                            </Typography>
                            <Typography type="title" className={styles.info}>
                                {user.name || translate('notSet')}
                            </Typography>
                        </div>
                        <div className={styles.item}>
                            <Typography type="text" className={styles.title}>
                                {translate('lastJoin')}
                            </Typography>
                            <Typography type="title" className={styles.info}>
                                {user.lastLoginAt
                                    ? moment(user.lastLoginAt).format('YYYY-MM-DD HH:mm')
                                    : translate('noData')}
                            </Typography>
                        </div>
                        <div className={styles.item}>
                            <Typography type="text" className={styles.title}>
                                {translate('userRoles')}
                            </Typography>
                            {roles &&
                                roles.map((role, index) => (
                                    <Typography type="title" className={styles.info} key={index}>
                                        {role.label}
                                    </Typography>
                                ))}
                        </div>
                        {user.activeBefore && (
                            <div className={styles.item}>
                                <Typography type="text" className={styles.title}>
                                    {translate('activeBefore')}
                                </Typography>
                                <Typography type="title" className={styles.info}>
                                    {moment(user.activeBefore).format('DD.MM.YYYY')}
                                </Typography>
                            </div>
                        )}
                    </div>
                </div>
                {!!s_.data.usersInCompany && (
                    <Typography type="text" className={styles.footer}>
                        {translate('usersInCompany')}: {s_.data.usersInCompany}
                    </Typography>
                )}
            </Card>
        )
    }

    return (
        <Animation className={cn(p_.className)} style={{ order: itemRules.order }}>
            {renderCard()}
        </Animation>
    )
}

export default React.memo(DashboardUser)
