import React, {FC} from 'react';
import {CircleButtonProps} from "./CircleButton-types";
import {circleButtonStyles as styles} from "./CircleButton-styles";
import {cn} from 'ethcss';

const CircleButton: FC<CircleButtonProps> = ({onClick, className, children, type}) => {

    const getInnerViewByType = () => {
        switch (type) {
            case "delete":
                return <div className={styles.deleteButton}/>
            default:
                return children
        }
    }

    return (
        <div
            onClick={onClick}
            className={cn(styles.circleButton, className)}
        >
            {getInnerViewByType()}
        </div>
    );
};

export {CircleButton}
