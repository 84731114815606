import React, { useEffect, useState } from 'react'
import FilesEdit from '../__edit/files__edit'
import FolderEdit from '../__editFolder/folder__edit'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import DropMenu from 'blocks.simple/dropMenu/dropMenu'
import Preview from 'blocks.app/preview/preview'
import Button from 'blocks.simple/button/button'
import Icon from 'blocks.simple/icon/icon'
import ReactPlayer from 'react-player'
import RangeVolume from 'blocks.simple/range/_volume/range_volume'
import LoaderBlock from 'blocks.app/loader/_block/loader_block'
import Tag from 'blocks.simple/tag/tag'
import { PreviewMediaMessage } from 'blocks.app/preview/__media/preview__media'
import filesize from 'filesize'
import deepEqual from 'fast-deep-equal'
import helpersFiles from 'core/helpers/_files'
import helpers from 'core/helpers'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { grid, item, text } from '../../../theme'
import styles from './files__toolbarFile.jcss'
import { connect, RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { deleteFile, deleteFolder, filesActions, updateFolder } from '../files.state'
import { UpdateUserSettings, updateUserSettings } from 'blocks.app/user/user.state'
import { IFile } from '../../../core/models/Files'
import { IRootState } from 'core/store/rootReducer'
import EditableText from 'blocks.simple/editableText/editableText'
import { api } from 'core/api/ConnectionManager'

const textFileType = ['text', 'xml', 'csv']

function getShowDeletePrompt(p_: { displayedTooltips?: { [index: string]: boolean | undefined } }) {
    return !(p_.displayedTooltips && p_.displayedTooltips.deleteFilePrompt)
}

function getFileData(p_: {
    selectedInfo: {
        id: number
        folderId: number
        name: string
        categoryId: number
        data: {}
    }
}) {
    let data: {
        id?: number
        folderId?: number
        name?: string
        categoryId?: number
        data?: {}
    } = {}

    if (p_) {
        data = {
            id: p_.selectedInfo.id,
            folderId: p_.selectedInfo.folderId,
            name: p_.selectedInfo.name,
            categoryId: p_.selectedInfo.categoryId,
        }
    }

    if (p_.selectedInfo && p_.selectedInfo.data && p_.selectedInfo.hasOwnProperty('data')) {
        data.data = p_.selectedInfo.data
    }

    return data
}

function getFoldersList(p_: {
    selectedInfo: {
        isDirectory: boolean
        id: number
    }
    folders: IFile[]
}) {
    let folders = p_.folders
    if (p_ && p_.selectedInfo.isDirectory) {
        folders = folders.filter((folder) => folder.id !== p_.selectedInfo.id)
    }

    return folders
}

const playerDefaultState = {
    active: [],
    played: 0,
    volume: 75,
    ready: false,
    showPlayer: true,
    error: false,
}

interface IState {
    active: string[]
    played: number
    volume: number
    ready: boolean
    showPlayer: boolean
    error: boolean
    data: { id?: number }
    isEditFileModal: boolean
    isEditFolderModal: boolean
    showDeletePrompt: boolean
    tags: []
    folders: any
    initShowDeletePrompt: boolean
    isOpenFolderModal: boolean
    cloneNumber: number | string
    cloneError?: boolean
    isDeleted?: boolean
}

let prevProps: any = null

const ToolbarFile = (p_: any) => {
    //const [prevProps, setPrevProps] = useState(p_)
    const dispatch = useDispatch()
    const showDeletePrompt = getShowDeletePrompt(p_)
    const [state, setState] = useState<IState>({
        ...playerDefaultState,
        data: getFileData(p_),
        cloneNumber: 1,
        isEditFileModal: false,
        isEditFolderModal: false,
        showDeletePrompt,
        tags: [],
        folders: getFoldersList(p_),
        initShowDeletePrompt: showDeletePrompt,
        isOpenFolderModal: false,
        isDeleted: false,
    })
    const isMobile = useSelector<RootStateOrAny>((state) => state.app.isMobile)
    const clickDropMenu = (id: string) => {
        const s_ = state

        if (id === 'delete' && !(s_.initShowDeletePrompt || p_.selectedInfo.isDirectory)) {
            if (isMobile) {
                !s_.isDeleted && p_.deleteFile(s_.data.id)
                setState((prevState) => {
                    return { ...prevState, isDeleted: true }
                })
            } else {
                p_.deleteFile(s_.data.id)
            }
        }
        if (id === 'fullscreen') {
            p_.onClickPreview()
        }
        if (id === 'editFile') {
            setState((prevState) => {
                return { ...prevState, isEditFileModal: true }
            })

            const tags = helpersFiles.getTagsList(p_.tags)

            setState((prevState) => {
                return { ...prevState, tags }
            })
        }

        if (id === 'editFolder') {
            setState((prevState) => {
                return { ...prevState, isOpenFolderModal: true }
            })
        }
    }

    const handleDropMenuChange = (active: string[]) => {
        if (
            !textFileType.includes(p_.selectedInfo.fileType) ||
            p_.selectedInfo.fileType === 'text' ||
            (p_.selectedInfo.fileType === 'table' && p_.selectedInfo.fileType !== 'rss')
        ) {
            setState((prevState) => {
                return { ...prevState, active }
            })
        }
    }

    useEffect(() => {
        const showDeletePrompt = getShowDeletePrompt(p_)
        const prevData = {
            data: getFileData(prevProps !== null ? prevProps : p_),
            showDeletePrompt: getShowDeletePrompt(prevProps !== null ? prevProps : p_),
            initShowDeletePrompt: getShowDeletePrompt(prevProps !== null ? prevProps : p_),
            folders: getFoldersList(prevProps !== null ? prevProps : p_),
        }
        const nextData = {
            data: getFileData(p_),
            showDeletePrompt,
            initShowDeletePrompt: showDeletePrompt,
            folders: getFoldersList(p_),
        }

        if (!deepEqual(prevData, nextData)) {
            setState((prevState) => {
                return { ...prevState, ...nextData }
            })
        }

        if (
            !helpersFiles.isEqual({
                item: p_.selectedInfo,
                prevItem: prevProps === null ? p_.selectedInfo : prevProps.selectedInfo,
            })
        ) {
            resetPlayer()
        }
        if (isMobile) {
            setState((prevState) => {
                return { ...prevState, isDeleted: false }
            })
        }
        prevProps = p_
    }, [p_])

    useEffect(() => {
        if (state.showPlayer) return

        setState((prevState) => {
            return { ...prevState, showPlayer: true }
        })
    }, [state.showPlayer])

    const resetPlayer = () => {
        setState((prevState) => {
            return { ...prevState, ...playerDefaultState, showPlayer: false }
        })
    }

    const editItem = () => {
        return {
            id: 'editFolder',
            show: helpers.isAvailableAction(p_.selectedInfo, {
                key: 'files',
                action: 'update',
            }),
            icon: 'pencil',
            tooltip: {
                title: translate('edit'),
            },
        }
    }

    const validateClonedWidget = (number: string | number) => {
        return number !== '' && number >= 1 && number <= 30
    }

    const cloneFile = () => {
        return {
            id: 'cloneFile',
            icon: 'copy',
            tooltip: {
                title: translate('clone'),
            },
            children: (
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        if (validateClonedWidget(s_.cloneNumber)) {
                            api.send('cloneFile', { sourceId: p_.selectedInfo.id, cloneNumber: s_.cloneNumber })
                            setState((prevState) => {
                                return { ...prevState, active: [] }
                            })
                        }
                    }}
                    className={cn(grid.colCenter, grid.p_md)}
                >
                    <div className={cn(grid.w80, grid.mb_md)}>{translate('copiesNumber')}:</div>
                    <div className={cn(grid.row, grid.justify, grid.colCenter)}>
                        <EditableText
                            type={'number'}
                            mod={'withBorder'}
                            text={s_.cloneNumber ? s_.cloneNumber : 1}
                            onChange={(cloneNumber: number) => {
                                setState((prevState) => {
                                    return { ...prevState, cloneNumber: cloneNumber }
                                })
                            }}
                            placeholder={translate('emptyFieldError')}
                        />
                        <div className={cn(grid.row, grid.justify, grid.mt_micro, text.redError)}>
                            {!validateClonedWidget(s_.cloneNumber) && <p>{translate('cloneError')}</p>}
                        </div>
                        <div className={cn(grid.row, grid.justify, grid.mt_md)}>
                            <Button type="submit" mod="fill" className={cn(grid.mr_mini)}>
                                {translate('yes')}
                            </Button>
                            <Button
                                mod={'withBorder'}
                                onClick={() =>
                                    setState((prevState) => {
                                        return { ...prevState, active: [] }
                                    })
                                }
                            >
                                {translate('no')}
                            </Button>
                        </div>
                    </div>
                </form>
            ),
        }
    }

    const deleteItem = () => {
        const s_ = state
        if ((s_.initShowDeletePrompt || p_.selectedInfo.isDirectory) && p_.selectedInfo.folderId) {
            return {
                id: 'delete',
                icon: 'delete',
                show: helpers.isAvailableAction(p_.selectedInfo, {
                    key: 'files',
                    action: 'delete',
                }),
                tooltip: {
                    title: translate('delete'),
                },
                children: (
                    <form
                        onSubmit={(e) => {
                            e.preventDefault()
                            setState((prevState) => {
                                return { ...prevState, active: [] }
                            })

                            if (s_.showDeletePrompt) {
                                dispatch(
                                    updateUserSettings({
                                        data: { displayedTooltips: { deleteFilePrompt: true } },
                                        isSaveSettings: true,
                                    })
                                )
                            }

                            if (p_.selectedInfo.isDirectory) {
                                p_.deleteFolder(s_.data.id)
                            } else {
                                p_.deleteFile(s_.data.id)
                            }
                        }}
                        className={cn(grid.colCenter, grid.p_md)}
                    >
                        <div className={cn(grid.w80, grid.mb_md)}>{translate('deleteApprove')}</div>
                        <div className={cn(grid.row, grid.justify, grid.mb_mini)}>
                            <Button type="submit" mod="fill" className={cn(grid.mr_mini)}>
                                {translate('yes')}
                            </Button>
                            <Button
                                animation={false}
                                onClick={() =>
                                    setState((prevState) => {
                                        return { ...prevState, active: [] }
                                    })
                                }
                            >
                                {translate('no')}
                            </Button>
                        </div>

                        {!p_.selectedInfo.isDirectory && (
                            <Checkbox
                                className={styles.flexNowWrap}
                                label={translate('tooltipWarning')}
                                checked={s_.showDeletePrompt}
                                onClick={(showDeletePrompt: boolean) =>
                                    setState((prevState) => {
                                        return { ...prevState, showDeletePrompt }
                                    })
                                }
                            />
                        )}
                    </form>
                ),
            }
        }

        return {
            id: 'delete',
            icon: 'delete',
            show: helpers.isAvailableAction(p_.selectedInfo, {
                key: 'files',
                action: 'delete',
            }),
            tooltip: {
                title: translate('delete'),
            },
        }
    }

    const previewItem = () => {
        const editAction = {
            id: 'editFile',
            icon: 'pencil',
            show: helpers.isAvailableAction(p_.selectedInfo, {
                key: 'files',
                action: 'update',
            }),
            tooltip: {
                title: translate('edit'),
            },
        }

        if (p_.selectedInfo.isDirectory) {
            return [editItem(), deleteItem()]
        }

        if (p_.selectedInfo.fileType === 'web_page' && !p_.selectedInfo.iFrameAvailable) {
            return [editAction, cloneFile(), deleteItem()]
        }
        return [
            {
                id: 'fullscreen',
                icon: 'fullscreen',
                tooltip: {
                    title: translate('preview'),
                },
            },
            editAction,
            cloneFile(),
            deleteItem(),
        ]
    }

    const s_ = state

    const titleClassNames = cn(
        styles.title,
        p_.selectedInfo.fileType === 'rss' ||
            p_.selectedInfo.fileType === 'stream' ||
            p_.selectedInfo.fileType === 'web_page'
            ? styles.full
            : styles.ellipsis
    )

    return (
        <div className={styles.wrapper}>
            <div className={styles.fileWrapper}>
                {!textFileType.includes(p_.selectedInfo.fileType) && (
                    <div className={titleClassNames}>{p_.selectedInfo.name}</div>
                )}
                <div className={`${grid.p_md}`}>
                    <div className={grid.mb_micro}>
                        {p_.selectedInfo.size >= 0 && p_.selectedInfo.size !== null && (
                            <span>{filesize(p_.selectedInfo.size, { round: 0 })}</span>
                        )}
                    </div>
                    <div>
                        {p_.selectedInfo.createdAt && (
                            <span>{helpers.getFormattedLocalDate(p_.selectedInfo.createdAt, 'DD/MM/YYYY')}</span>
                        )}
                    </div>
                </div>
                {(() => {
                    switch (p_.selectedInfo.fileType) {
                        case 'audio':
                            return (
                                <Rectangle>
                                    <div className={cn(grid.full, grid.col, styles.viewInfoNoHiddenOverflow)}>
                                        <div
                                            className={cn(grid.space, item.containSpace, styles.rectangle)}
                                            style={{
                                                backgroundImage: `url(images/files/sound.png)`,
                                            }}
                                        >
                                            {!s_.ready && !s_.error && <LoaderBlock />}
                                            {s_.showPlayer && (
                                                <ReactPlayer
                                                    width="100%"
                                                    height="100%"
                                                    hidden={true}
                                                    url={p_.selectedInfo.src}
                                                    playing={s_.active.indexOf('play') !== -1}
                                                    onEnded={() =>
                                                        setState((prevState) => {
                                                            return { ...prevState, active: [], played: 0 }
                                                        })
                                                    }
                                                    onProgress={(e) => {
                                                        if (e.played < 1)
                                                            setState((prevState) => {
                                                                return { ...prevState, played: e.played }
                                                            })
                                                    }}
                                                    onReady={() =>
                                                        setState((prevState) => {
                                                            return { ...prevState, ready: true }
                                                        })
                                                    }
                                                    onError={() =>
                                                        setState((prevState) => {
                                                            return { ...prevState, error: true }
                                                        })
                                                    }
                                                    progressInterval={250}
                                                    volume={s_.volume / 100}
                                                />
                                            )}
                                            <PreviewMediaMessage
                                                //@ts-ignore
                                                error={s_.error}
                                                src={p_.selectedInfo.src}
                                                icon={{ size: 24 }}
                                                style={{ top: 0 }}
                                            />
                                        </div>
                                        <div className={styles.progressWrapper}>
                                            <div style={{ width: `${s_.played * 100}%` }} className={styles.progress} />
                                        </div>
                                        <DropMenu
                                            active={s_.active}
                                            onChange={(active: string[]) =>
                                                setState((prevState) => {
                                                    return { ...prevState, active }
                                                })
                                            }
                                            onClick={clickDropMenu}
                                            className={item.blackOpacity}
                                            items={[
                                                {
                                                    id: 'fullscreen',
                                                    icon: 'fullscreen',
                                                    tooltip: {
                                                        title: translate('preview'),
                                                    },
                                                },
                                                {
                                                    id: 'play',
                                                    disabled: !s_.ready,
                                                    icon: 'play',
                                                    toggle: true,
                                                    activeIcon: 'pause',
                                                    tooltip: {
                                                        title: translate('playback'),
                                                    },
                                                },
                                                {
                                                    id: 'volume',
                                                    icon: 'volume_min',
                                                    children: (
                                                        <RangeVolume
                                                            volume={s_.volume}
                                                            onChange={(volume: number) =>
                                                                setState((prevState) => {
                                                                    return { ...prevState, volume }
                                                                })
                                                            }
                                                        />
                                                    ),
                                                    tooltip: {
                                                        title: translate('volume'),
                                                    },
                                                },
                                                {
                                                    id: 'editFile',
                                                    icon: 'pencil',
                                                    show:
                                                        !!p_.selectedInfo.folderId &&
                                                        helpers.isAvailableAction(p_.selectedInfo, {
                                                            key: 'files',
                                                            action: 'update',
                                                        }),
                                                    tooltip: {
                                                        title: translate('edit'),
                                                    },
                                                },
                                                cloneFile(),
                                                deleteItem(),
                                            ]}
                                        />
                                    </div>
                                    {!p_.selectedInfo.isDirectory && (
                                        <Preview
                                            isOpen={p_.isPreviewOpen}
                                            onClose={() => p_.closePreview()}
                                            items={p_.previewSliderOptions.sliderItems}
                                            currentIndex={p_.previewSliderOptions.currentIndexSlider}
                                            downloadLink={p_.downloadLink}
                                        />
                                    )}
                                </Rectangle>
                            )
                        case 'video':
                            return (
                                <Rectangle>
                                    <div className={cn(grid.full, grid.col, styles.viewInfoNoHiddenOverflow)}>
                                        <div className={styles.videoWrapper}>
                                            {!s_.ready && !s_.error && <LoaderBlock />}
                                            {s_.showPlayer && (
                                                <ReactPlayer
                                                    width="100%"
                                                    height="100%"
                                                    url={p_.selectedInfo.src}
                                                    playing={s_.active.indexOf('play') !== -1}
                                                    onEnded={() =>
                                                        setState((prevState) => {
                                                            return { ...prevState, active: [], played: 0 }
                                                        })
                                                    }
                                                    onProgress={(e) => {
                                                        if (e.played < 1)
                                                            setState((prevState) => {
                                                                return { ...prevState, played: e.played }
                                                            })
                                                    }}
                                                    onReady={() =>
                                                        setState((prevState) => {
                                                            return { ...prevState, ready: true }
                                                        })
                                                    }
                                                    onError={() =>
                                                        setState((prevState) => {
                                                            return { ...prevState, error: true }
                                                        })
                                                    }
                                                    progressInterval={250}
                                                    volume={s_.volume / 100}
                                                    className={styles.video}
                                                />
                                            )}
                                            <PreviewMediaMessage
                                                //@ts-ignore
                                                error={s_.error}
                                                src={p_.selectedInfo.src}
                                                icon={{ size: 24 }}
                                                style={{ top: 0 }}
                                            />
                                        </div>
                                        <div className={styles.progressWrapper}>
                                            <div style={{ width: `${s_.played * 100}%` }} className={styles.progress} />
                                        </div>
                                        <DropMenu
                                            active={s_.active}
                                            onChange={(active: string[]) =>
                                                setState((prevState) => {
                                                    return { ...prevState, active }
                                                })
                                            }
                                            onClick={clickDropMenu}
                                            className={item.blackOpacity}
                                            items={[
                                                {
                                                    id: 'fullscreen',
                                                    icon: 'fullscreen',
                                                    tooltip: {
                                                        title: translate('preview'),
                                                    },
                                                },
                                                {
                                                    id: 'play',
                                                    icon: 'play',
                                                    toggle: true,
                                                    disabled: !s_.ready,
                                                    activeIcon: 'pause',
                                                    tooltip: {
                                                        title: translate('playback'),
                                                    },
                                                },
                                                {
                                                    id: 'volume',
                                                    icon: 'volume_min',
                                                    children: (
                                                        <RangeVolume
                                                            volume={s_.volume}
                                                            onChange={(volume: number) =>
                                                                setState((prevState) => {
                                                                    return { ...prevState, volume }
                                                                })
                                                            }
                                                        />
                                                    ),
                                                    tooltip: {
                                                        title: translate('volume'),
                                                    },
                                                },
                                                {
                                                    id: 'editFile',
                                                    icon: 'pencil',
                                                    show:
                                                        !!p_.selectedInfo.folderId &&
                                                        helpers.isAvailableAction(p_.selectedInfo, {
                                                            key: 'files',
                                                            action: 'update',
                                                        }),
                                                    tooltip: {
                                                        title: translate('edit'),
                                                    },
                                                },
                                                cloneFile(),
                                                deleteItem(),
                                            ]}
                                        />
                                    </div>
                                    {!p_.selectedInfo.isDirectory && (
                                        <Preview
                                            isOpen={p_.isPreviewOpen}
                                            onClose={() => p_.closePreview()}
                                            items={p_.previewSliderOptions.sliderItems}
                                            currentIndex={p_.previewSliderOptions.currentIndexSlider}
                                            downloadLink={p_.downloadLink}
                                        />
                                    )}
                                </Rectangle>
                            )
                        case 'office':
                            return (
                                <Rectangle>
                                    <div className={cn(grid.full, grid.col, styles.viewInfoNoHiddenOverflow)}>
                                        <div
                                            className={cn(grid.space, item.containSpace, styles.rectangle)}
                                            style={{
                                                backgroundImage: `url(images/files/office.png)`,
                                            }}
                                        />
                                        <DropMenu
                                            active={s_.active}
                                            onChange={(active: string[]) =>
                                                setState((prevState) => {
                                                    return { ...prevState, active }
                                                })
                                            }
                                            onClick={(id: string) => {
                                                if (id === 'fullscreen') {
                                                    p_.onClickPreview()
                                                }
                                                clickDropMenu(id)
                                            }}
                                            mod="centered"
                                            className={item.blackOpacity}
                                            items={previewItem()}
                                        />
                                    </div>
                                    {!p_.selectedInfo.isDirectory && (
                                        <Preview
                                            isOpen={p_.isPreviewOpen}
                                            onClose={() => p_.closePreview()}
                                            items={p_.previewSliderOptions.sliderItems}
                                            currentIndex={p_.previewSliderOptions.currentIndexSlider}
                                        />
                                    )}
                                </Rectangle>
                            )
                        default:
                            return (
                                <Rectangle
                                    src={
                                        p_.selectedInfo.fileType === 'image'
                                            ? helpersFiles.getSrc(p_.selectedInfo)
                                            : undefined
                                    }
                                    containerClassName={styles.viewInfoNoHiddenOverflow}
                                >
                                    <div className={cn(grid.full, grid.col)}>
                                        {p_.selectedInfo.type === 'link' && (
                                            <Icon
                                                containerClass={cn(
                                                    grid.space,
                                                    grid.full,
                                                    styles.rectangle,
                                                    grid.colCenter
                                                )}
                                                type="web_page"
                                                size="70%"
                                                color="white"
                                            />
                                        )}
                                        {p_.selectedInfo.type !== 'link' && (
                                            <div className={styles.previewWrapper}>
                                                {!!p_.selectedInfo.isDirectory && (
                                                    <div className={styles.contentWrapper}>
                                                        {p_.selectedInfo.content &&
                                                            !!p_.selectedInfo.content.length &&
                                                            p_.selectedInfo.content.map(
                                                                (contentItem: { fileType: string }, index: number) => (
                                                                    <div
                                                                        key={`files__contentItem_${index}`}
                                                                        className={cn(styles.contentItem, item.contain)}
                                                                        style={{
                                                                            backgroundImage: `url(${helpersFiles.getSrc(
                                                                                contentItem
                                                                            )})`,
                                                                            backgroundSize:
                                                                                contentItem.fileType === 'empty'
                                                                                    ? 'auto'
                                                                                    : 'auto auto',
                                                                        }}
                                                                    />
                                                                )
                                                            )}
                                                        {p_.selectedInfo.content && !p_.selectedInfo.content.length && (
                                                            <Icon
                                                                containerClass={cn(
                                                                    grid.space,
                                                                    styles.rectangle,
                                                                    grid.full,
                                                                    grid.colCenter
                                                                )}
                                                                type={
                                                                    p_.selectedInfo.hasSmartFolderRulesQ
                                                                        ? 'smartFolder'
                                                                        : 'folder'
                                                                }
                                                                size="70%"
                                                                color="white"
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                                {textFileType.includes(p_.selectedInfo.fileType) &&
                                                    (p_.selectedInfo.displayAs === 'qr_code' ? (
                                                        <div
                                                            className={cn(
                                                                styles.textContentWrapper,
                                                                styles.qrCodeWrapper
                                                            )}
                                                            style={{
                                                                backgroundImage: `url(${p_.selectedInfo.thumbnail})`,
                                                            }}
                                                        />
                                                    ) : (
                                                        <div
                                                            className={cn(styles.textContentWrapper, {
                                                                [styles.transparentGrid]:
                                                                    p_.selectedInfo.style &&
                                                                    (p_.selectedInfo.style.backgroundColor ===
                                                                        'transparent' ||
                                                                        !p_.selectedInfo.style.backgroundColor),
                                                            })}
                                                            style={helpersFiles.convertStyles(p_.selectedInfo.style)}
                                                        >
                                                            {helpers.strip_html_tags(p_.selectedInfo.name)}
                                                        </div>
                                                    ))}
                                                {p_.selectedInfo.fileType === 'rss' && (
                                                    <Icon
                                                        containerClass={cn(
                                                            grid.space,
                                                            styles.rectangle,
                                                            grid.full,
                                                            grid.colCenter
                                                        )}
                                                        type="rss"
                                                        size="70%"
                                                        color="white"
                                                    />
                                                )}
                                                {p_.selectedInfo.fileType === 'web_app' &&
                                                    p_.selectedInfo.type !== 'link' && (
                                                        <Icon
                                                            containerClass={cn(
                                                                grid.space,
                                                                styles.rectangle,
                                                                grid.full,
                                                                grid.colCenter
                                                            )}
                                                            type="web_app"
                                                            size="70%"
                                                            color="white"
                                                        />
                                                    )}
                                                {p_.selectedInfo.type === 'table' && (
                                                    <Icon
                                                        containerClass={cn(
                                                            grid.space,
                                                            styles.rectangle,
                                                            grid.full,
                                                            grid.colCenter
                                                        )}
                                                        type={'table'}
                                                        size="70%"
                                                        color="white"
                                                    />
                                                )}
                                            </div>
                                        )}
                                        <DropMenu
                                            active={s_.active}
                                            onChange={handleDropMenuChange}
                                            onClick={(id: string) => {
                                                if (id === 'fullscreen') {
                                                    p_.onClickPreview()
                                                }
                                                clickDropMenu(id)
                                            }}
                                            mod="centered"
                                            className={item.blackOpacity}
                                            items={previewItem()}
                                        />
                                    </div>
                                    {!p_.selectedInfo.isDirectory && (
                                        <Preview
                                            isOpen={p_.isPreviewOpen}
                                            onClose={() => p_.closePreview()}
                                            items={p_.previewSliderOptions.sliderItems}
                                            currentIndex={p_.previewSliderOptions.currentIndexSlider}
                                        />
                                    )}
                                </Rectangle>
                            )
                    }
                })()}
                {p_.selectedInfo.broadcasts && p_.selectedInfo.broadcasts.length > 0 && (
                    <div>
                        <div className={grid.p_md}>{translate('usedInBroadcasts')}:</div>
                        {p_.selectedInfo.broadcasts.map((broadcast: { title: string }, index: number) => (
                            <div key={`toolbar__file_${index}`} className={grid.p_md}>
                                {broadcast.title}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {p_.bottomContent}
            {p_.selectedInfo.tags && (
                <div className={styles.tags}>
                    {p_.selectedInfo.tags.map((tag: { name: string }, index: number) => (
                        <div key={`content__tag_${index}`} className={styles.tagWrapper}>
                            <Tag value={tag.name} />
                        </div>
                    ))}
                </div>
            )}
            <div className={grid.space} />
            <div className={cn(grid.p_md, grid.rowCenter, styles.closeBtn)}>
                <Button mod={'withBorder'} onClick={p_.onCloseInfo} indentSize={'mini'} rounded={'full_normal'}>
                    {translate('close')}
                </Button>
            </div>
            {p_.selectedInfo && !p_.selectedInfo.isDirectory && (
                <FilesEdit
                    open={s_.isEditFileModal}
                    selectedInfo={p_.selectedInfo}
                    folders={s_.folders}
                    tags={s_.tags}
                    onClose={() =>
                        setState((prevState) => {
                            return { ...prevState, isEditFileModal: false }
                        })
                    }
                />
            )}
            {p_.selectedInfo && p_.selectedInfo.isDirectory ? (
                <FolderEdit
                    selectedInfo={p_.selectedInfo}
                    folders={s_.folders}
                    updateFolder={p_.updateFolder}
                    open={s_.isOpenFolderModal}
                    onClose={() =>
                        setState((prevState) => {
                            return { ...prevState, isOpenFolderModal: false }
                        })
                    }
                />
            ) : null}
        </div>
    )
}

const mapStateToProps = (state: IRootState): any => ({
    displayedTooltips: state.user.data?.settings.displayedTooltips,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        deleteFile: (id: number) => dispatch(deleteFile(id)),
        deleteFolder: (id: number) => dispatch(deleteFolder(id)),
        updateFolder: (folder: IFile) => dispatch(updateFolder(folder)),
        closePreview: () => dispatch(filesActions.closePreview()),
        updateUserSettings: (userSettings: UpdateUserSettings) => dispatch(updateUserSettings(userSettings)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarFile)
