import { addStyles } from 'ethcss'
import { grid, IStyles, item, textSizes, indents } from 'theme'
import { defaultThemeStyles } from '../Dropdown.theme'

export const styles: IStyles = {
    DropdownLabel: {
        position: 'relative',
        ...grid.flex,
        ...grid.center,
        padding: `${indents.mini}px ${indents.miniPlus}px`,
        fontSize: textSizes.normal,
        cursor: 'pointer',
    },
    DropdownLabel_type_withShadow: {
        color: `var(--var-module-ui-component-dropdown-style-withShadow-textSub, ${defaultThemeStyles['withShadow-textSub']})`,
    },
    DropdownLabel_type_withShadow_status_active: {
        color: `var(--var-module-ui-component-dropdown-style-withShadow-activeTextSub, ${defaultThemeStyles['withShadow-activeTextSub']})!important`,
    },
    DropdownLabel_type_fill: {
        color: `var(--var-module-ui-component-dropdown-style-fill-textSub, ${defaultThemeStyles['fill-textSub']})`,
    },
    DropdownLabel_type_fill_status_active: {
        color: `var(--var-module-ui-component-dropdown-style-fill-activeTextSub, ${defaultThemeStyles['fill-activeTextSub']})!important`,
    },
    DropdownLabel_type_none: {
        color: `var(--var-module-ui-component-dropdown-style-none-textSub, ${defaultThemeStyles['none-textSub']})`,
    },
    DropdownLabel_type_none_status_active: {
        color: `var(--var-module-ui-component-dropdown-style-none-activeTextSub, ${defaultThemeStyles['none-activeTextSub']})!important`,
    },
    DropdownLabel_type_default: {
        color: `var(--var-module-ui-component-dropdown-style-default-textSub, ${defaultThemeStyles['default-textSub']})`,
    },
    DropdownLabel_type_default_status_active: {
        color: `var(--var-module-ui-component-dropdown-style-default-activeTextSub, ${defaultThemeStyles['default-activeTextSub']})!important`,
    },
    DropdownLabel_type_leftIcon: {
        paddingLeft: `${indents.miniPlus * 2.5}px !important`,
    },
    DropdownLabel_type_rightIcon: {
        paddingRight: `${indents.miniPlus * 2.5}px !important`,
    },
    DropdownLabel__icon: {},
    DropdownLabel__icon_type_withLabel: {
        position: 'absolute',
        top: `50%`,
        marginTop: -8,
    },
    DropdownLabel__icon_type_left: {
        left: indents.miniPlus,
    },
    DropdownLabel__icon_type_right: {
        right: indents.miniPlus,
    },
    DropdownLabel__text: {
        ...item.ellipsis,
    },
}

addStyles(styles)
