import React from 'react'
import FilesEditMethods from './files__edit.local.methods'
import Modal from 'blocks.simple/modal/modal'
import EditText from 'blocks.simple/editText/editText'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './files__edit.jcss'
import { grid } from 'theme'
import { text as textStyles } from 'theme'
import { Typography } from 'atoms/Typography'

function FilesEdit(p_: any) {
    const { s_, methods } = FilesEditMethods(p_)

    function renderForm() {
        if (!p_.open) {
            return null
        }

        return (
            <form onSubmit={methods.onSave} className={styles.wrapper}>
                <div className={styles.title}>
                    <Typography type="title" className={textStyles.mdPlus}>
                        {translate('additionalContentOptions')}
                    </Typography>
                </div>
                <div className={styles.content}>
                    <div className={styles.form}>
                        {p_.selectedInfo.type !== 'table' && p_.selectedInfo.type !== 'text' && (
                            <div className={styles.inputWrapper}>
                                <Typography className={styles.inputTitle} type="title">
                                    {p_.selectedInfo.type === 'link' ? translate('linkName') : translate('fileName')}
                                </Typography>
                                <div className={grid.w100}>
                                    <EditText
                                        value={s_.model.data.fileName}
                                        onChange={(fileName: string) => {
                                            methods.onChangeData('fileName', fileName)
                                        }}
                                        className={cn(textStyles.center, textStyles.normal)}
                                    />
                                </div>
                            </div>
                        )}
                        {p_.selectedInfo.type === 'table' && (
                            <div className={styles.inputWrapper}>
                                <Typography className={styles.inputTitle} type="title">
                                    {translate('fileName')}
                                </Typography>
                                <div className={grid.w100}>
                                    <EditText
                                        value={s_.model.data.name}
                                        onChange={(name: string) => {
                                            methods.onChangeData('name', name)
                                        }}
                                        className={cn(textStyles.center, textStyles.normal)}
                                    />
                                </div>
                            </div>
                        )}
                        {p_.selectedInfo.type === 'link' && (
                            <div className={styles.inputWrapper}>
                                <Typography className={styles.inputTitle} type="title">
                                    {translate('link')}
                                </Typography>
                                <div className={grid.w100}>
                                    <EditText
                                        value={s_.model.data.url}
                                        onChange={(url: string) => {
                                            methods.onChangeData('url', url)
                                        }}
                                        error={s_.error}
                                        className={cn(textStyles.center, textStyles.normal)}
                                    />
                                </div>
                            </div>
                        )}
                        {p_.selectedInfo.fileType === 'rigla_local_xml' && (
                            <div className={styles.inputWrapper}>
                                <Typography className={styles.inputTitle} type="title">
                                    {translate('path')}
                                </Typography>
                                <div className={grid.w100}>
                                    <EditText
                                        value={s_.model.data.localPath}
                                        onChange={(localPath: string) => {
                                            methods.onChangeData('localPath', localPath)
                                        }}
                                        className={cn(textStyles.center, textStyles.normal)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className={styles.inputWrapper}>
                            <Typography className={styles.inputTitle} type="title">
                                {translate('parentFolder')}
                            </Typography>
                            <div className={grid.w100}>
                                <Dropdown
                                    value={s_.model.folderId}
                                    options={p_.folders}
                                    searchable
                                    centered
                                    type="tree"
                                    placeholder={translate('main')}
                                    onChange={methods.onChangeFolder}
                                />
                            </div>
                        </div>
                        <div className={styles.inputWrapper}>
                            <Typography className={styles.inputTitle} type="title">
                                {translate('tag')}
                            </Typography>
                            <Dropdown
                                list={s_.model.tags}
                                options={p_.tags}
                                searchable={true}
                                multiselect={true}
                                onChange={methods.onChangeTags}
                                hiddenOverflow
                                onValidateSymbolNumber={methods.onValidateSymbolNumber}
                            />
                            <div className={grid.mt_mini}>
                                <Typography className={styles.centerBlock} type="text">
                                    {translate('forAddTagUseEnter')}
                                </Typography>
                                {s_.validateSymbolNumber && (
                                    <Typography className={cn(styles.centerBlock, grid.mt_mini)} type="error">
                                        {translate('minTagNumber')}
                                    </Typography>
                                )}
                            </div>
                        </div>
                        <div className={styles.inputWrapper}>
                            <Typography className={styles.inputTitle} type="title">
                                {translate('description')}
                            </Typography>
                            <div className={grid.w100}>
                                <EditText
                                    type={'area'}
                                    value={s_.model.description}
                                    onChange={(description: string) => {
                                        methods.onChangeDescription(description)
                                    }}
                                    className={cn(textStyles.normal)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button onClick={p_.onClose} className={grid.mr_md}>
                        {translate('cancel')}
                    </Button>
                    <Button type="submit" mod="fill">
                        {translate('save')}
                    </Button>
                </div>
            </form>
        )
    }

    return (
        <Modal onClose={p_.onClose} open={p_.open}>
            {renderForm()}
        </Modal>
    )
}

export default React.memo(FilesEdit)
