import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { addStyles } from 'ethcss'
import { colors, indents, IStyles, mediaCSS, textSizes, transitions } from 'theme'
import grid from 'theme/grid/styles'

export const styles: IStyles = {
    platformCardTable: {
        width: `100%`,
        tableLayout: 'fixed',
        [mediaCSS.desktop]: {
            tableLayout: 'auto',
        },
    },
    platformCardTable__row: {
        borderTop: `1px solid var(--var-module-displays-component-platform-border, ${defaultThemeStyles.border})`,
    },
    platformCardTable__td: {
        padding: `${indents.mdPlus}px ${indents.mdPlus}px`,
        textAlign: 'left',
        verticalAlign: 'middle',
        [mediaCSS.tab]: {
            padding: `${indents.md}px ${indents.md}px`,
        },
        [mediaCSS.mob]: {
            padding: `${indents.mini}px ${indents.mini}px`,
        },
    },
    platformCardTable__td_type_info: {
        paddingTop: `0!important`,
    },
    platformCardTable__text: {
        fontSize: textSizes.md,
        // whiteSpace: 'nowrap',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        lineHeight: 1.4,
        '& a': {
            textDecoration: 'none',
        },
        [mediaCSS.tab]: {
            fontSize: `${textSizes.normal}px!important`,
        },
    },
    platformCardTable__text_type_capacity: {
        fontSize: `${textSizes.mdPlus}px!important`,
        fontWeight: 'bold',
        [mediaCSS.tab]: {
            fontSize: `${textSizes.md}px!important`,
        },
        [mediaCSS.mob]: {
            fontSize: `${textSizes.normal}px!important`,
        },
    },
    platformCardTable__text_type_downloadLink: {
        textAlign: 'center',
    },
    platformCardTable__text_type_hash: {
        cursor: 'copy',
    },
    platformCardTable__button: {
        textDecoration: 'underline',
        fontSize: textSizes.normal,
        '&:hover': {
            textDecoration: 'none!important',
        },
    },
    platformCardTable__button_type_active: {
        fontWeight: 'bold',
    },
    platformCardTable___toggler: {
        ...grid.flex,
        ...grid.center,
    },
    platformCardTable__th: {
        padding: `${indents.micro}px ${indents.mdPlus}px`,
        textAlign: 'left',
        verticalAlign: 'middle',
        [mediaCSS.tab]: {
            padding: `${indents.micro}px ${indents.md}px`,
        },
        [mediaCSS.mob]: {
            padding: `${indents.micro}px ${indents.mini}px`,
        },
    },
    platformCardTable__th_type_version: {
        width: `12%`,
    },
    platformCardTable__th_type_date: {
        width: `16%`,
    },
    platformCardTable__th_type_description: {
        width: `27%`,
    },
    platformCardTable__th_type_capacity: {
        width: `15%`,
    },
    platformCardTable__th_type_downloadLink: {
        width: `8%`,
    },
    platformCardTable__title: {
        lineHeight: 1.4,
        fontSize: textSizes.mini,
        fontWeight: 'bold',
        '& span': {
            display: 'block',
            fontWeight: 'normal',
        },
    },
    platformCardTable__title_type_downloadLink: {
        textAlign: 'center',
    },
    platformCardTable__listItem: {
        fontSize: textSizes.md,
        lineHeight: 2.4,
        [mediaCSS.tab]: {
            fontSize: textSizes.normal,
            '-webkit-text-size-adjust': 'none !important',
        },
    },
    platformCardTable__list_span: {
        [mediaCSS.tab]: {
            '-webkit-text-size-adjust': 'none !important',
            '& ul': {
                '-webkit-text-size-adjust': 'none !important',
            },
        },
    },
}

addStyles(styles)
