import React from 'react'

import { api } from 'core/api/ConnectionManager'
import { treeViewHelpers } from 'core/helpers'
import deepEqual from 'fast-deep-equal'
import merge from 'merge'
import moment from 'moment'

class TreeViewCatalogMethods extends React.Component {
    state = {
        list: [],
        total: null,
        lastPage: null,
    }
    loading = true
    fullLoading = true
    clearListenersList = []

    componentDidUpdate(prevProps) {
        if (
            !deepEqual(prevProps.params, this.props.params) ||
            !deepEqual(prevProps.viewParams, this.props.viewParams)
        ) {
            this.get(false, false)
        }
    }
    componentDidMount() {
        const p_ = this.props

        this.get()
        p_.template.onCreateListeners.forEach((listener) => {
            listener((dataItem) => {
                this.get(false, true)
                p_.onChange(dataItem, 'create')
            }, this.clearListenersList)
        })
        p_.template.onUpdateListeners.forEach((listener) => {
            listener((dataItem) => {
                this.updateItem(dataItem)
                p_.onChange(dataItem, 'update')
            }, this.clearListenersList)
        })
        p_.template.onDeleteListeners.forEach((listener) => {
            listener((dataItem) => {
                this.deleteItem(dataItem)
                p_.onChange(dataItem, 'delete')
            }, this.clearListenersList)
        })
    }
    componentWillUnmount() {
        this.clearListenersList.forEach((id) => api.removeObserver(id))
    }

    getParameters = () => {
        return {
            ...this.props.viewParams,
        }
    }

    onFolderOpening = (listItem) => {
        const s_ = this.state
        const p_ = this.props
        const folderKey = treeViewHelpers.getNestedFolderKey(p_.type)

        if (this.isTreeLoaded(listItem)) {
            return
        }

        api.send(
            p_.template.getMethod,
            { [folderKey]: listItem.id, ...this.getParameters() },
            { hideLoader: false }
        ).then((res) => {
            let list = res.data

            s_.list.forEach((item) => {
                if (p_.template.isEqual(listItem, item)) {
                    item.__view.isLoaded = true
                }

                const existItemIndex = res.data.findIndex((newItem) => p_.template.isEqual(newItem, item))

                if (existItemIndex > -1) {
                    list.splice(existItemIndex, 1)
                }
            })

            list = this.setViewListItemParameters(list)

            this.setState({ list: [...s_.list, ...list] })
        })
    }
    isTreeLoaded = (listItem) => {
        const s_ = this.state
        const p_ = this.props

        for (let i = 0; s_.list.length > i; i++) {
            if (p_.template.isEqual(listItem, s_.list[i])) {
                s_.list[i].__view.isOpen = !s_.list[i].__view.isOpen

                this.setState(s_)
                if (s_.list[i].__view.isLoaded) {
                    return true
                }
            }
        }

        return false
    }
    get = (isLazyLoad, quiteLoading) => {
        const s_ = this.state
        const p_ = this.props

        const options = {
            ...this.getParameters(),
            offset: isLazyLoad ? s_.list.length : 0,
            perPage: 35,
        }

        if (p_.type === 'advertisingCampaigns') {
            options.userDate = moment().format('YYYY-MM-DD HH:mm:ss')
        }

        this.loading = true
        this.fullLoading = !isLazyLoad
        if (quiteLoading) {
            this.loading = false
            this.fullLoading = false
        }

        api.send(p_.template.getMethod, options, { hideLoader: false }).then(({ data: list, total, lastPage }) => {
            if (isLazyLoad) {
                list = [...s_.list, ...list]
            }

            this.fullLoading = false
            this.loading = false

            list = this.setViewListItemParameters(list)

            this.setState({
                list,
                total,
                lastPage,
            })
        })
    }
    setViewListItemParameters = (list) => {
        const p_ = this.props

        return list.map((listItem) => {
            if (!listItem.hasOwnProperty.__view) {
                listItem.__view = {}
            }

            if (!listItem.__view.hasOwnProperty('nestedKey')) {
                listItem.__view.nestedKey = treeViewHelpers.getNestedFolderKey(p_.type)
            }

            return listItem
        })
    }
    updateItem = (dataItem) => {
        const s_ = this.state
        const p_ = this.props
        const list = [...s_.list]

        list.forEach((listItem, index) => {
            if (p_.template.isEqual(dataItem, listItem)) {
                list[index] = merge.recursive(true, list[index], dataItem)
            }
        })

        this.setState({ list })
    }
    deleteItem(dataItem) {
        const s_ = this.state
        const p_ = this.props
        const list = s_.list.filter((listItem) => !p_.template.isEqual(listItem, dataItem))
        let total = s_.total

        if (s_.list.length >= s_.total) {
            total = list.length
        }

        this.setState({ list, total })
    }
}

export default TreeViewCatalogMethods
