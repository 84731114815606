import React, { useEffect, useState } from 'react'
import styles from './display__changeServer.jcss'
import translate from '../../../core/translate'
import { Typography } from '../../../atoms/Typography'
import EditText from '../../../blocks.simple/editText/editText'
import Button from '../../../blocks.simple/button/button'
import { cn } from 'ethcss'
import Icon from '../../../blocks.simple/icon/icon'
import { colors } from '../../../theme'
import { api } from '../../../core/api/ConnectionManager'

export const DSChangeServer = ({ device, onClose }: any) => {
    const [newServerUrl, setNewServerUrl] = useState<string>('')
    const [serverUrlIsExist, setServerUrlIsExist] = useState<boolean>(false)
    const [status, setStatus] = useState<string>('')
    const serverUrl = window.spconfig.ip_address

    const checkServerUrl = () => {
        api.send('checkNewServerAccess', { digitalSignageId: device.id, serverUrl: newServerUrl })
    }
    const changeServerUrl = () => {
        api.send('moveToNewServer', { digitalSignageId: device.id, serverUrl: newServerUrl }).then(() => onClose())
    }
    const validateUrl = () => {
        const pattern = new RegExp(
            '^([a-zA-Z]+:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', // fragment locator
            'i'
        )
        return pattern.test(newServerUrl)
    }
    const renderStatus = () => {
        switch (status) {
            case 'success':
                return (
                    <div className={cn(styles.status, styles.statusSuccess)}>
                        <div className={styles.statusTitle}>
                            <Icon type={'checked'} color={colors.blueAccent} size={16} />
                            <span>{translate('serverValidationSuccess')}</span>
                        </div>
                        <div className={styles.statusDescription}>
                            {translate('forConfirmNewServer')} "{translate('change')}"
                        </div>
                    </div>
                )
            case 'error':
                return (
                    <div className={cn(styles.status, styles.statusError)}>
                        <div className={styles.statusTitle}>
                            <Icon type={'attention'} color={colors.notificationRed} size={16} />
                            <span>{translate('error')}</span>
                        </div>
                        <div className={styles.statusDescription}>{translate('errorCheckNewServer')}</div>
                    </div>
                )
            default:
                return null
        }
    }

    useEffect(() => {
        setServerUrlIsExist(false)
    }, [newServerUrl])
    useEffect(() => {
        const listenersId: string[] = []

        api.addObserver(
            'answerNewServerAccess',
            (data: { digitalSignageId: number; accessAllowed: boolean }) => {
                if (data && data.digitalSignageId === device.id) {
                    setStatus(data.accessAllowed ? 'success' : 'error')
                    setServerUrlIsExist(data.accessAllowed)
                }
            },
            listenersId
        )

        return () => listenersId.forEach((id) => api.removeObserver(id))
    }, [])

    return (
        <div className={styles.modal}>
            <div className={styles.modalInner}>
                <div className={styles.warning}>
                    <Typography>{translate('changeServerWarning')}</Typography>
                </div>
                <div className={styles.fieldGroup}>
                    <Typography className={styles.label}>{translate('currentIp')}:</Typography>
                    <Typography type={'title'}>{serverUrl}</Typography>
                </div>
                <div className={styles.fieldGroup}>
                    <Typography className={styles.label}>{translate('newIp')}:</Typography>
                    <EditText
                        value={newServerUrl}
                        placeholder={translate('enterNewServerIp')}
                        onChange={setNewServerUrl}
                        className={styles.serverUrlInput}
                    />
                </div>
                {renderStatus()}
                <div className={styles.buttons}>
                    <Button mod="withBorder" className={styles.button} onClick={onClose}>
                        {translate('cancel')}
                    </Button>
                    {serverUrlIsExist ? (
                        <Button
                            disabled={!device.online}
                            mod={'fill'}
                            className={styles.button}
                            onClick={changeServerUrl}
                        >
                            {translate('change')}
                        </Button>
                    ) : (
                        <Button
                            disabled={!device.online || !validateUrl()}
                            mod={'fill'}
                            className={styles.button}
                            onClick={checkServerUrl}
                        >
                            {translate('check')}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}
