import { addStyles } from 'ethcss'
import { IStyles } from '../../../theme'
import { colors } from '../../../theme'

const filterAddStyles: IStyles = {
    filterAdd: {
        position: 'absolute',
        right: 0,
        top: '24px',
        filter: 'drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25))',
        backgroundColor: colors.white,
        minWidth: '430px',
        height: '400px',
        borderRadius: '10px',
        zIndex: 10000,
        padding: '20px',
        overflow: 'auto',
    },
    filterItem: {
        marginBottom: '20px',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingBottom: '1px',
    },
}

addStyles(filterAddStyles)
export default filterAddStyles
