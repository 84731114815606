import React, { useState } from 'react'
import translate from '../../core/translate'
import EditText from '../editText/editText'
import styles from './createDirectoryModal.jcss'
import Button from '../button/button'
import { appActions, ICreateDirectoryWindow } from 'blocks.app/app/app.state'
import { useDispatch } from 'react-redux'
import { api } from '../../core/api/ConnectionManager'

export const CreateDirectoryModal = (p_: { data: ICreateDirectoryWindow | null }) => {
    const [inputValue, setInputValue] = useState<string>('')
    const dispatch = useDispatch()

    if (!p_.data) return null

    const { title, methodName, fieldParamKey, inputFieldName, placeholder } = p_.data

    const onClose = () => {
        setInputValue('')
        dispatch(appActions.closeCreateDirectoryWindow())
    }
    const createDirectory = () => {
        let options = { ...p_.data!.options }

        if (inputValue) {
            options[fieldParamKey] = inputValue
        }

        api.send(methodName, options).then(() => {
            onClose()
        })
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.inner}>
                <div className={styles.title}>{title}</div>
                <div className={styles.inputWrapper}>
                    <EditText
                        label={inputFieldName}
                        value={inputValue}
                        onChange={(value: string) => {
                            setInputValue(value)
                        }}
                        placeholder={placeholder}
                    />
                </div>
                <div className={styles.controls}>
                    <Button mod={'withBorder'} className={styles.button} onClick={onClose}>
                        {translate('cancel')}
                    </Button>
                    <Button mod="fill" className={styles.button} onClick={createDirectory}>
                        {translate('create')}
                    </Button>
                </div>
            </div>
        </div>
    )
}
