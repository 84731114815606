import React from 'react'
import { Icon } from 'molecules/Icon'

import { api } from 'core/api/ConnectionManager'
import helpersDigitalSignages from 'core/helpers/_digitalSignages'
import translate from 'core/translate'
import { colors } from 'theme'
import styles from './styles'

const FOLDER_ICON_SIZE = 30

export default {
    getMethod: 'getDisplaysAndGroups',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noDevices'),
    emptyIcon: 'not_allow',
    moveItem: (id, groupId) => api.editDisplay({ id, groupId }),
    moveFolder: (id, parentId) => api.editGroup({ id, parentId }),
    getDragData: (p_) => {
        return JSON.stringify({ id: p_.item.id, type: p_.item.type })
    },
    isOnlySearch: false,
    fields: () => [{ id: 'name', name: translate('name'), expanded: true }],
    sortOptions: () => [
        { value: '-createdAt', label: translate('sortCreatedAt') },
        { value: 'name', label: translate('byNameAsc') },
        { value: '-name', label: translate('byNameDesc') },
        { value: 'status', label: translate('sortStatusOnline') },
        { value: '-status', label: translate('sortStatusOffline') },
        { value: 'type', label: translate('devices') },
        { value: '-type', label: translate('groups') },
    ],
    isEqual: helpersDigitalSignages.isEqual,
    on: [
        {
            name: 'groupDeleted',
        },
    ],
    onCreateListeners: [
        (cb, clearList) => api.addObserver('groupCreated', cb, clearList),
        (cb, clearList) => api.addObserver('displayCreated', cb, clearList),
    ],
    onUpdateListeners: [
        (cb, clearList) => api.addObserver('groupUpdated', cb, clearList),
        (cb, clearList) => api.addObserver('displayUpdated', cb, clearList),
    ],
    onDeleteListeners: [
        (callback, clearList) =>
            api.addObserver('groupDeleted', ({ id }) => callback({ id, type: 'group' }), clearList),
        (callback, clearList) =>
            api.addObserver('displayDeleted', ({ id }) => callback({ id, type: 'digitalSignage' }), clearList),
    ],
    isFolder: helpersDigitalSignages.isFolder,
    list: {
        name: ({ name, status, type }, active, p_) => {
            let element = <Icon type="folder" size={FOLDER_ICON_SIZE} color="grey" />

            if (type !== 'group') {
                element = (
                    <div
                        className={styles.displaysTemplateStatus}
                        style={{ width: FOLDER_ICON_SIZE, height: FOLDER_ICON_SIZE }}
                    >
                        <div className={styles.displaysTemplateDevice} style={{ background: colors[status] }} />
                    </div>
                )
            }

            return (
                <div className={styles.displaysTemplateNameWrapper}>
                    <div className={styles.displaysTemplateNameContent}>{element}</div>
                    <div>{name}</div>
                </div>
            )
        },
    },
}
