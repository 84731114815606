import { addStyles } from 'ethcss'
import { grid, indents, IStyles, layers, mediaCSS } from 'theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'

export const styles: IStyles = {
    searchableGoogleMap: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    searchableGoogleMap__actionBar: {
        position: 'absolute',
        ...grid.flex,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.w50,
        [mediaCSS.tabMini]: {
            width: `calc(100% - ${indents.md * 2}px)`,
        },
        left: indents.md,
        top: indents.md,
        right: indents.md,
        zIndex: layers.secondary,
    },
    searchableGoogleMap__searchBar: {
        position: 'relative',
        ...grid.space,
    },
    searchableGoogleMap__searchBar_type_enable: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    _global: {
        '.pac-container': {
            zIndex: '5000!important',
        },
    },
}

addStyles(styles)
