import React from 'react'
import styles from './remoteSupportPanel.jcss'
import translate from '../../core/translate'
import { api } from '../../core/api/ConnectionManager'

export const RemoteSupportPanel = () => {
    const disconnect = () => {
        api.send('disconnectSupport', {})
    }

    return (
        <div className={styles.panel}>
            {translate('remoteSpecialistOnline')}
            <div className={styles.disableButton} onClick={disconnect}>
                {translate('disableAccess')}
            </div>
        </div>
    )
}
