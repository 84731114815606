import React from 'react'
import { Icon } from 'molecules/Icon'

import styles from './styles'

const TreeViewCatalogEmpty = ({ query, template }) => (
    <div className={styles.treeViewCatalogEmptyWrapper}>
        <div className={styles.treeViewCatalogEmptyContent}>
            {template.emptyIcon && <Icon type={template.emptyIcon} size={60} />}
            <div className={styles.treeViewCatalogEmptyTitle}>
                <div>{query ? template.emptySearchText() : template.emptyFolderText()}</div>
            </div>
        </div>
    </div>
)

export default TreeViewCatalogEmpty
