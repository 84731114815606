import React from 'react'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import helpers from 'core/helpers'
import styles from './catalog__template_lightOfHistory.jcss'
import { CatalogActionBar } from 'organisms/Catalog/ActionBar/ActionBar-view'
import { ActionBarRequestHeaderType, ActionBarActionType } from 'core/models/ActionBar'
import EditableText from 'blocks.simple/editableText/editableText'
import CatalogSelectContent from '../../_select/_content/catalog_select_content'
import Button from 'blocks.simple/button/button'
import grid from 'blocks.simple/grid/grid.jcss'
import { cn } from 'ethcss'
import { isImagesExist, getSourcesIds } from './catalog_template_lightOfHistory.local.methods'
import { TextEditor } from 'molecules/TextEditor'
import { IFile } from '../../../../core/models/Files'

interface IItem {
    images: any
    __view: { selected: boolean }
}

const toolbar = {
    options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
}

const onSortEnd = (data: { newIndex: number; sorted: any }) => {
    const { newIndex, sorted } = data
    const historyPageId = sorted[newIndex].historyPageId
    const aboveHistoryPageId = newIndex === 0 ? null : sorted[newIndex - 1].historyPageId

    api.send('moveHistoryPage', {
        historyPageId,
        aboveHistoryPageId,
    })
}

const getActionList = (p_: { onAddFakeLine: any; selectedItems: { historyPageId: number }[]; fakeLine: any }) => {
    return [
        {
            order: 1,
            type: ActionBarActionType.ADD,
            cb: () => {
                p_.onAddFakeLine({ name: '' })
            },
        },
        {
            order: 2,
            type: ActionBarActionType.DELETE,
            cb: () => {
                const historyPageIds = p_.selectedItems.map((selectedItem) => {
                    return {
                        historyPageId: selectedItem.historyPageId,
                    }
                })

                if (historyPageIds.length > 0) {
                    api.send('deleteHistoryPage', historyPageIds).then(() => window.location.reload())
                }

                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            },
        },
    ]
}

export default {
    getMethod: 'getHistoryPage',
    customParams: {
        limit: 35,
    },
    trNoClick: true,
    emptySearchText: () => translate('notFound'),
    emptyDescription: () => translate('createFirstPosition'),
    emptyFolderText: () => translate('listIsEmpty'),
    emptyIcon: 'not_allow',
    addItemButton: () => null,
    settingsPrefixes: 'lightOfHistory',
    disableDrag: true,
    sortOptions: () => [],
    headerFilters: (p_: any) => {
        return (
            <CatalogActionBar
                title={translate('lightOfHistory')}
                requestHeaderType={ActionBarRequestHeaderType.WITH_AUTH}
                type="authorsList"
                actions={getActionList(p_)}
                {...p_}
            />
        )
    },

    getItemModel: (dataItem: IItem, getSelected: (item: IItem) => boolean) => {
        const item = { ...dataItem }

        if (item.images && !item.images.__view) {
            item.images.forEach((image: { source: { __view: { selected: boolean } } }) => {
                image.source.__view = {
                    selected: getSelected(item),
                }
            })
        }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },

    getBackendModel: (item: { name: string }) => {
        return api.send('getHistoryPage', { query: item.name }, { hideLoader: true })
    },
    tableWrapper: styles.tableWrapper,
    isEqual: (firstItem: { historyPageId: number }, secondItem: { historyPageId: number }) => {
        if (firstItem.historyPageId && secondItem.historyPageId) {
            return firstItem.historyPageId === secondItem.historyPageId
        }

        return firstItem === secondItem
    },

    onCreateListeners: [
        // (callback, clearList) => {
        //     return api.addObserver(
        //         'historyPageCreated',
        //         (historyPageCreated) => {
        //             callback({ ...historyPageCreated })
        //         },
        //         clearList
        //     )
        // },
    ],
    onUpdateListeners: [
        (callback: any, clearList: string[]) => {
            return api.addObserver(
                'historyPageUpdated',
                (historyPageUpdated: object) => {
                    callback({ ...historyPageUpdated })
                },
                clearList
            )
        },
    ],
    onDeleteListeners: [
        // (callback, clearList) =>
        //     api.addObserver(
        //         'historyPageDeleted',
        //         (historyPageDeleted) => {
        //             const historyPageId = historyPageDeleted.historyPageId
        //             callback({historyPageId})
        //         },
        //         clearList
        //     ),
    ],

    isFolder: () => {},
    fields: () => [
        { id: 'media', name: translate('media') },
        { id: 'title', name: translate('title') },
        { id: 'description', name: translate('historyPageDescription') },
    ],
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        media: (p_: { historyPageId: number; images: { source: IFile }[] }, active: boolean, listProps: any) => (
            <div>
                <CatalogSelectContent
                    id={p_.historyPageId}
                    type={'mediaAndFolder'}
                    selectedInfo={
                        isImagesExist(p_)
                            ? {
                                  ...p_.images[0].source,
                              }
                            : null
                    }
                    onSelect={(selectedMedia: { id: number; extension: string; content: any }) => {
                        if (p_.historyPageId) {
                            getSourcesIds(selectedMedia).then((res) => {
                                api.send('updateHistoryPage', {
                                    historyPageId: p_.historyPageId,
                                    images: res,
                                })
                            })
                        } else {
                            getSourcesIds(selectedMedia).then((res) => {
                                listProps.onAddFakeLine({
                                    ...listProps.fakeLine,
                                    images: res,
                                })
                            })
                        }
                    }}
                />
            </div>
        ),
        title: (
            p_: { historyPageId: number; images: { source: IFile }[]; title: string },
            active: boolean,
            listProps: any
        ) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.title })}>
                <div className={cn({ [grid.fullWidth]: p_.title, [grid.space]: !p_.title })}>
                    <EditableText
                        placeholder={translate('enterTitle')}
                        id={p_.historyPageId}
                        text={p_.title}
                        onChange={(title: string) => {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, title })
                        }}
                        onUpdate={(title, cb) => {
                            api.send('updateHistoryPage', {
                                historyPageId: p_.historyPageId,
                                title,
                            }).then(cb)
                        }}
                    />
                </div>
            </div>
        ),
        description: (p_: { historyPageId: number; description: string }, active: boolean, listProps: any) => (
            <div className={cn(grid.fullWidth)}>
                <TextEditor
                    id={p_.historyPageId}
                    placeholder={translate('enterDescription')}
                    text={p_.description ? p_.description : ''}
                    previewStyles={styles.preview}
                    toolbar={toolbar}
                    onChange={(description: string, cb: any) => {
                        if (!p_.historyPageId) {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, description })
                            return
                        }

                        api.send('updateHistoryPage', {
                            historyPageId: p_.historyPageId,
                            description: helpers.lineBreak(helpers.quotesConverter(description, 'html'), 'html'),
                        }).then(cb)
                    }}
                />
                {!p_.historyPageId && (
                    <Button
                        mod={'fill'}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            api.send('createHistoryPage', {
                                title: listProps.fakeLine.title,
                                description: listProps.fakeLine.description,
                                images: listProps.fakeLine.images ? listProps.fakeLine.images : null,
                            }).then(() => window.location.reload())
                        }}
                        disabled={!listProps.fakeLine.title}
                    >
                        {translate('save')}
                    </Button>
                )}
            </div>
        ),
    },
    hideActiveRowColor: true,
    disableUpdateUserSettings: true,
    templateCellsWidth: [
        {
            width: '10%',
            flexBasis: '130px',
        },
        {
            width: '30%',
            flexBasis: 'auto',
        },
        {
            width: '54%',
            flexBasis: 'auto',
        },
    ],
    disableListScroll: true,
    fixedColWidth: false,
    dndDblClickDisable: true,
    onSortEndCallback: onSortEnd,
}
