import { useEffect, useState } from 'react'
import { arrayMove } from 'react-sortable-hoc'
import { api } from 'core/api/ConnectionManager'
import { updateUserSettings } from '../../../blocks.app/user/user.state'

export type ColItem = {
    field: string
    order: number
    default: boolean
    disabled: boolean
    selected: boolean
    filterEnabled: boolean
}

export const SettingsFilterListHooks = () => {
    const [items, setItems] = useState<ColItem[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [defaultFields, setDefaultFields] = useState<boolean>(false)
    const [customFields, setCustomFields] = useState<boolean>(false)

    const getViewSettings = () => {
        api.send('getCustomListView', { view: 'DigitalSignage' }).then((res: any) => {
            setItems(res.fields)
            setLoading(false)
        })
    }
    const onSelect = (check: boolean, index: number) => {
        const selectedItem = items.map((item: ColItem) => {
            if (item.order === index) {
                item.selected = check
            }
            return item
        })

        setItems(selectedItem)

        api.send('updateCustomListView', { view: 'DigitalSignage', fields: selectedItem })
    }
    const onMultiSelect = () => {
        const selected = items.filter((item: ColItem) => item.selected)
        let itemsList = items.map((item: ColItem) => ({
            ...item,
            selected: true,
        }))

        if (selected.length === items.length) {
            itemsList = items.map((item: ColItem) => {
                const newItem = {
                    ...item,
                    selected: false,
                }

                if (newItem.disabled) {
                    newItem.selected = true
                }

                return newItem
            })
        }

        setItems(itemsList)
        api.send('updateCustomListView', { view: 'DigitalSignage', fields: itemsList })
    }
    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        let sorted = arrayMove(items, oldIndex, newIndex)
        sorted = sorted.map((item: ColItem, index) => {
            item.order = index
            return item
        })
        setItems(sorted)

        api.send('updateCustomListView', { view: 'DigitalSignage', fields: sorted })
    }
    const onReset = () => {
        api.send('resetCustomListView', { view: 'DigitalSignage' }).then((res: any) => {
            setItems(res.fields)
        })
    }
    const onRedirectToDisplays = () => {
        updateUserSettings({ data: { view: { digitalSignage: 'list' } }, isSaveSettings: true })
    }

    return {
        items,
        loading,
        defaultFields,
        customFields,
        getViewSettings,
        onSelect,
        onSortEnd,
        onRedirectToDisplays,
        onMultiSelect,
        onReset,
    }
}
