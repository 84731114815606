import { ListenEventsType } from '../events'
import { generateIdByUUID } from 'core/utils/coreUtil'
import { IWebSocketError } from '../models'

export interface EventData<T> {
    type: ListenEventsType
    data?: T
    error: IWebSocketError | null
}

const FUNCTION_ARITY_WITH_ERROR_CATCH = 2

export class EventChannel {
    private observers: Map<ListenEventsType, Map<string, (data: any, error: IWebSocketError | null) => void> | null>

    public constructor() {
        this.observers = new Map<
            ListenEventsType,
            Map<string, (data: any, error: IWebSocketError | null) => void> | null
        >()
    }

    public fireEvent<T>(event: EventData<T>) {
        if (event) {
            let observersMap = this.observers.get(event.type)

            if (observersMap) {
                observersMap.forEach((fn) => {
                    if (event.error && fn.length !== FUNCTION_ARITY_WITH_ERROR_CATCH) return

                    fn(event.data, event.error)
                })
            }
        }
    }

    public addObserver<T>(
        type: ListenEventsType,
        cb: (eventData: T, error: IWebSocketError | null) => void,
        ListenersIdList: string[]
    ): string {
        let observersMap = this.observers.get(type)
        const id = generateIdByUUID()

        if (observersMap) {
            observersMap.set(id, cb)
        } else {
            let map = new Map<string, (data: any, error: IWebSocketError | null) => void>()
            map.set(id, cb)
            this.observers.set(type, map)
        }

        ListenersIdList.push(id)
        return id
    }

    public removeObserver(id: string): boolean {
        if (!id) return false
        let isObserverRemoved = false

        this.observers.forEach((observersMap) => {
            if (observersMap && observersMap.has(id)) {
                observersMap.delete(id)
                isObserverRemoved = true
            }
        })

        return isObserverRemoved
    }
}
