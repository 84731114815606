import React, { useEffect, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { cn } from 'ethcss'
import Card from 'blocks.simple/card/card'
import Animation from 'blocks.simple/animation/animation'
import { DashboardSessionsItem } from './__item/dashboard__sessions__item'
import DashboardSessionsMethods from './dashboard__sessions.local.methods'
import styles from './dashboard__sessions.jcss'
import translate from 'core/translate'

export type Session = {
    ipAddress: string
    userAgent: string
    count: number
}

const renderSessions = (sessions: Session[]) => {
    return sessions.map((session: Session, index: number) => {
        return <DashboardSessionsItem key={index} session={session} />
    })
}

const isSessionsAvailable = (s_: any) => {
    return s_.data.sessions && s_.data.sessions.length !== 0
}

export const DashboardSessions = React.memo((p_: any) => {
    const { s_ } = DashboardSessionsMethods()
    const itemRules = p_.rules ? p_.rules : 0
    const [width, setWidth] = useState(0)

    const getTitle = () => translate('activeSessions')

    const setWindowWidth = () => {
        const width = window.innerWidth

        setWidth(width)
    }

    useEffect(() => {
        setWindowWidth()

        window.addEventListener('resize', setWindowWidth)

        return () => window.removeEventListener('resize', setWindowWidth)
    }, [])

    function renderCard() {
        if (!s_.data.isLoaded) {
            return null
        }

        return (
            <Card
                className={styles.wrapperSession}
                title={getTitle()}
                titleClassName={styles.title}
                text={translate('exitSessionsByPassword')}
                icon={{
                    type: 'inter_connections',
                    size: 20,
                    default: true,
                }}
            >
                <div className={cn(styles.content)}>
                    {!isSessionsAvailable(s_) && <div>{translate('noSessionsAvailable')}</div>}
                    {isSessionsAvailable(s_) && (
                        <Scrollbars
                            autoHeight
                            autoHeightMax={400}
                            // autoHeight={width < 900}
                        >
                            {renderSessions(s_.data.sessions)}
                        </Scrollbars>
                    )}
                </div>
            </Card>
        )
    }

    return (
        <Animation className={cn(p_.className, styles.full)} style={{ order: itemRules.order }}>
            {renderCard()}
        </Animation>
    )
})
