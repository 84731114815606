import translate from 'core/translate'
import { api } from 'core/api/ConnectionManager'

export const getAuthorsIds = (authors: { authorId: number }[]) => {
    if (!authors) return

    return authors.map((author) => {
        return author.authorId
    })
}

export const getMediaIds = (items: { id: number; extension: string; content: any }[]) => {
    if (!items) return

    const mediaIds: number[] = []

    items.forEach((media) => {
        const ext = media.extension
        if (ext === '.jpg' || ext === '.jpeg' || ext === '.png' || ext === '.mp4' || ext === '.mp3' || ext === '.pdf') {
            return mediaIds.push(media.id)
        }
    })

    return mediaIds.length ? mediaIds : null
}

export async function getSourcesIds(selectedMedia: { id: number; content: any; extension: string }) {
    if (!selectedMedia) return

    if (selectedMedia.content) {
        const response: { id: number; extension: string; content: any }[] = await api.send('getSources', {
            folderId: selectedMedia.id,
        })
        return getMediaIds(response)
    } else {
        return getMediaIds([selectedMedia])
    }
}

export async function getSources(selectedMedia: { id: number; content: any; extension: string }) {
    if (!selectedMedia) return

    if (selectedMedia.content) {
        const response = await api.send('getSources', { folderId: selectedMedia.id })
        return response
    } else {
        return [selectedMedia]
    }
}

export const authorsListToShort = (
    list: { authorId: number; firstName: string; lastName: string; secondName: string }[]
) => {
    return list.map((element) => {
        return {
            id: element.authorId,
            name: `
                ${element.lastName ? element.lastName : ''} 
                ${element.firstName ? element.firstName : ''} 
                ${element.secondName ? element.secondName : ''}`,
            selected: false,
        }
    })
}

export const typesListToShort = (
    list: {
        authorId: number
        firstName: string
        lastName: string
        secondName: string
        typeId: string
        title: string
    }[]
) => {
    return list.map((element) => {
        return {
            id: element.typeId,
            name: translate(element.title),
            selected: false,
        }
    })
}

export const directionsListToShort = (list: { directionId: string; title: string }[]) => {
    return list.map((element) => {
        return {
            id: element.directionId,
            name: translate(element.title),
            selected: false,
        }
    })
}
