import React, { FC, useEffect, useState } from 'react'
import { IAuthTimer } from './IAuthTimer-types'

export const AuthTimer: FC<IAuthTimer> = ({ minutesProps = 0, secondsProps = 0, setIsActive, isActive }) => {
    const [minutes, setMinutes] = useState(minutesProps)
    const [seconds, setSeconds] = useState(secondsProps)

    let renderMinutes = minutes.toString().padStart(2, '0')
    let renderSeconds = seconds.toString().padStart(2, '0')

    useEffect(() => {
        let interval: any = null
        if (isActive) {
            interval = setInterval(() => {
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval)
                        setIsActive(false)
                    } else {
                        setMinutes(minutes - 1)
                        setSeconds(59)
                    }
                } else {
                    setSeconds(seconds - 1)
                }
            }, 1000)
        } else {
            clearInterval(interval)
        }

        return () => clearInterval(interval)
    }, [isActive, minutes, seconds, secondsProps, minutesProps])

    return (
        <div>
            {
                <div>
                    <div>
                        <span>{renderMinutes}:</span>
                        <span>{renderSeconds}</span>
                    </div>
                </div>
            }
        </div>
    )
}
