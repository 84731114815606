import React, { FunctionComponent, memo } from 'react'
import { Dialog } from '../../molecules/Dialog'
import helpers from '../../core/helpers'
import Icon from 'blocks.simple/icon/icon'
import { IConfirmEmailDialogComponent } from './ConfirmEmailDialog-types'
import { confirmEmailDialogStyles } from './ConfirmEmailDialog-styles'
import { colors } from 'theme/colors'
import translate from 'core/translate'
import { mediaJS } from 'theme'

const CONFIRM_EMAIL_DIALOG_ID = 'confirm_email_dialog'

const ConfirmEmailDialogComponent: FunctionComponent<IConfirmEmailDialogComponent> = ({
    open = false,
    status = 'unauthorized',
    onClose,
    children,
    ...rest
}) => {
    const renderStatusIcon = () => {
        switch (status) {
            case 'unauthorized':
            case 'error':
                return <Icon type="dialog_attention" size={44} color={colors.error} />
            case 'success':
                return <Icon type="dialog_success" size={33} color={colors.success} />
        }
    }

    const renderTitle = () => {
        switch (status) {
            case 'unauthorized':
            case 'error':
                return translate('confirmEmailErrorTitle')
            case 'success':
                return translate('confirmEmailSuccessTitle')
        }
    }

    const renderText = () => {
        switch (status) {
            case 'unauthorized':
                return translate('confirmEmailUnauthorizedText')
            case 'error':
                return translate('confirmEmailErrorText')
            case 'success':
                return translate('confirmEmailSuccessText')
        }
    }

    const handleCloseEvent = (e: React.MouseEvent) => onClose()

    return (
        <Dialog
            open={open}
            {...helpers.getPropertiesByMediaQueries(
                {
                    desktop: { padding: 'p_md' },
                    tabMini: { padding: 'p_none' },
                },
                mediaJS
            )}
            dialogInnerClassName={confirmEmailDialogStyles.confirmEmailDialog}
            modalClassName={confirmEmailDialogStyles.confirmEmailDialog__wrapper}
            id={CONFIRM_EMAIL_DIALOG_ID}
        >
            <div className={confirmEmailDialogStyles.confirmEmailDialog__close} onClick={handleCloseEvent}>
                <Icon type="close" size={14} color={colors.black} />
            </div>
            <div className={confirmEmailDialogStyles.confirmEmailDialog__icons}>
                {renderStatusIcon()}
                <Icon type="dialog_email" size={61} color={colors.black} />
            </div>
            <div className={confirmEmailDialogStyles.confirmEmailDialog__body}>
                <div className={confirmEmailDialogStyles.confirmEmailDialog__title}>{renderTitle()}</div>
                <div className={confirmEmailDialogStyles.confirmEmailDialog__text}>{renderText()}</div>
                <div className={confirmEmailDialogStyles.confirmEmailDialog__content}>{children}</div>
            </div>
        </Dialog>
    )
}

export const ConfirmEmailDialog = memo(ConfirmEmailDialogComponent)
