import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { mediaCSS } from 'blocks.app/config'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { textSizes } from 'theme/media'
import { IStyles } from '../../theme'

const scheduleSettingsStyle: IStyles = {
    wrapper: {
        ...grid.row,
        ...grid.justify,
        ...item.rel,
        ...grid.p_md,
        '& *': {
            fontFamily: `var(--var-module-app-component-app-style-fontFamily, ${defaultThemeStyles.fontFamily})`,
        },
    },
    days: {
        ...grid.row,
        ...grid.noWrap,
        ...grid.center,
        ...grid.fullWidth,
        ...item.abs,
    },
    item: {
        ...grid.w25,
        [mediaCSS.tabMini]: {
            ...grid.w100,
            ...grid.mb_md,
        },
    },
    zIndex: {
        [mediaCSS.tabMini]: {
            zIndex: 999,
            position: 'relative',
        },
    },
    specificItem: {
        ...grid.w40,
        [mediaCSS.tabMini]: {
            ...grid.w100,
            ...grid.mb_md,
        },
    },
    checkbox: {
        fontSize: textSizes.normalPlus,
    },
    content: {
        ...grid.row,
        ...grid.mt_md,
        ...grid.fullWidth,
    },
    calendar: {
        ...grid.fullWidth,
        ...grid.row,
        ...grid.center,
        ...grid.m_none,
    },
    row: {
        ...grid.fullWidth,
    },
    more: {
        ...grid.row,
        ...item.pointer,
        ...item.blackColor,
        ...grid.normalCenter,
        ...grid.p_md,
        textTransform: 'lowercase',
    },
    scheduleSettings__inputWrapper_type_priority: {
        ...grid.p_md,
        ...grid.w40,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    scheduleSettings__input_type_useUtcTime: {
        ...grid.pr_md,
        [mediaCSS.tabMini]: {
            ...grid.mb_mini,
        },
    },
}

addStyles(scheduleSettingsStyle)
export default scheduleSettingsStyle
