import { addStyles } from 'ethcss'
import { mediaCSS, grid, item, text, IStyles } from 'theme'
import { defaultThemeStyles } from 'atoms/Tabs/Tabs-theme'

const createRoleStyles: IStyles = {
    createRoleWrapper: {
        ...grid.col,
        ...grid.full,
    },
    createRoleContent: {
        ...grid.fullHeight,
        ...grid.w75,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
        ...grid.space,
        ...item.overAuto,
    },
    createRoleTabsWrapper: {
        ...grid.row,
        ...grid.space,
        ...grid.fullHeight,
        [mediaCSS.tabMini]: {
            ...grid.col,
            paddingBottom: 60,
        },
    },
    createRoleTabWrapper: {
        ...grid.w25,
        ...text.grey,
        ...text.up,
        ...text.bold,
        borderRight: `1px solid var(--var-module-roles-component-roles-style-leftColumnBorder, ${defaultThemeStyles.background})`,
        [mediaCSS.tabMini]: {
            ...grid.row,
            ...grid.noWrap,
            ...grid.w100,
            ...item.overY,
            ...item.transparent,
            ...text.noWrap,
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': {
                ...item.none,
            },
        },
    },
    createRoleTab: {
        ...item.indent_big,
        ...item.pointer,
        '&:first-child': {
            ...grid.mt_md,
            [mediaCSS.tabMini]: {
                ...grid.mt_none,
            },
        },
        '&:last-child': {
            ...grid.mb_md,
            [mediaCSS.tabMini]: {
                ...grid.mb_none,
            },
        },
        [mediaCSS.tabMini]: {
            ...grid.row,
            ...grid.noWrap,
            borderBottom: `4px solid var(--var-module-ui-component-tabs-style-vertical-background, ${defaultThemeStyles['vertical-background']})`,
        },
    },
    createRoleTabActive: {
        ...item.blueWhite,
        ...text.greyDark,
        [mediaCSS.tabMini]: {
            ...item.transparent,
            borderColor: `var(--var-module-ui-component-tabs-style-vertical-backgroundActive, ${defaultThemeStyles['vertical-backgroundActive']})`,
        },
    },
}

addStyles(createRoleStyles)
export default createRoleStyles
