import React from 'react'
import PropTypes from 'prop-types'
import EditorEventsMethods from './editor__events.local.methods'
import Icon from 'blocks.simple/icon/icon'
import TouchEditor from 'blocks.app/touchEditor/touchEditor'
import Dropdown from 'blocks.simple/dropdown/dropdown'

import translate from 'core/translate'

import styles from './editor__events.jcss'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'

const ListItem = (p_) => (
    <div className={styles.action}>
        <div className={styles.eventTitle}>{translate(p_.type)}</div>
        <div className={styles.eventIcons}>
            <Icon
                containerClass={grid.mr_mini}
                type={'pencil'}
                tooltip={{ title: translate('edit') }}
                onClick={p_.onEdit}
            />
            <Icon type={'delete'} tooltip={{ title: translate('delete') }} onClick={p_.onDelete} />
        </div>
    </div>
)

const getPageOptions = (pages) => {
    return pages.map((page) => ({
        id: page.order,
        name: `${translate('page')} ${page.order + 1}`,
    }))
}

class EditorEvents extends EditorEventsMethods {
    static propTypes = {
        page: PropTypes.object,
        pages: PropTypes.array,
    }

    render() {
        const s_ = this.state
        const p_ = this.props

        return (
            <div className={styles.eventsWrapper}>
                <div className={styles.title}>
                    <div />
                    <div className={item.ellipsis}>{translate('events')}</div>
                    <div className={styles.icon} onClick={() => this.setState({ isOpenModal: true, action: null })}>
                        <Icon type="plus" tooltip={{ title: translate('add') }} />
                    </div>
                </div>
                {p_.pages.length > 1 && (
                    <div className={styles.nextPage}>
                        <Dropdown
                            wrapperWidth={false}
                            label={translate('nextPageLink')}
                            value={p_.page.nextPageOrder}
                            options={getPageOptions(p_.pages)}
                            onChange={this.onChangePage}
                            centered
                        />
                    </div>
                )}
                {!p_.page.playOnEvent && <div className={styles.emptyMessage}>{translate('listOfEventsIsEmpty')}</div>}
                {p_.page.playOnEvent &&
                    p_.page.playOnEvent.events.map((event, index) => (
                        <ListItem
                            key={index}
                            type={event.eventType}
                            condition={event.eventCondition}
                            onDelete={() => this.onDelete(index)}
                            onEdit={() => {
                                this.setState({
                                    event: {
                                        key: event.eventCondition.key,
                                        id: event.eventType,
                                        eventIndex: index,
                                        eventCondition: event.eventCondition,
                                    },
                                    isOpenModal: true,
                                })
                            }}
                        />
                    ))}
                <TouchEditor
                    open={s_.isOpenModal}
                    pages={p_.pages}
                    page={p_.page}
                    event={s_.event}
                    onSave={this.onSave}
                    onClose={() => this.setState({ isOpenModal: false, event: null })}
                    type="page"
                />
            </div>
        )
    }
}

export default EditorEvents
