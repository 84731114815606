import { addStyles } from 'ethcss'
import { grid, IStyles, item, text } from 'theme'

export const styles: IStyles = {
    ScoreboardsListItem__name: {
        ...grid.w100,
        ...grid.row,
        ...grid.noWrap,
        ...grid.normalCenter,
        textAlign: 'left',
    },
    ScoreboardsListItem__icon: {
        ...grid.p_micro,
        ...item.rounded_full_mini,
        ...item.white,
        ...grid.mr_mini,
    },
    ScoreboardsListItem__tableCell: {
        ...grid.flex,
        ...grid.center,
        ...grid.normalCenter,
    },
}

addStyles(styles)
