import React from 'react'
import { connect } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import Icon from 'blocks.simple/icon/icon'
import { cn } from 'ethcss'
import styles from './toast.jcss'

const defaultOptions = {
    className: cn(styles.wrapper),
}

const CloseButton = ({ closeToast }: { closeToast?: any }) => (
    <div onClick={closeToast}>
        <Icon type="close" color="white" />
    </div>
)

class Toast extends React.PureComponent<any> {
    static info(item: string, options?: {}) {
        toast.info(item, {
            ...defaultOptions,
            ...options,
        })
    }
    static warn(item: string, options?: {}) {
        toast.warn(item, {
            ...defaultOptions,
            ...options,
        })
    }
    static error(item: string, options?: {}) {
        toast.error(item, {
            ...defaultOptions,
            ...options,
        })
    }
    static success(item: string, options?: {}) {
        toast.success(item, {
            ...defaultOptions,
            ...options,
        })
    }
    static default(item: string, options?: {}) {
        return toast(item, {
            ...defaultOptions,
            ...options,
        })
    }

    render() {
        const minimizeWidth = this.props.minimizeMenu ? 'Toastify__toast-container--bottom-left_minimize' : ''

        return <ToastContainer closeButton={<CloseButton />} autoClose={5000} className={minimizeWidth} />
    }
}

const mapStateToProps = (state: any) => {
    return {
        minimizeMenu: state.app.minimizeMenu,
    }
}

export default connect(mapStateToProps)(Toast)
