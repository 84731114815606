import { addStyles } from 'ethcss'
import { indents, mediaCSS, textSizes } from 'theme/media'
import { IStyles, grid, colors } from 'theme'

export const styles: IStyles = {
    addDeviceByCode: {
        ...grid.col,
        ...grid.justify,
        ...grid.full,
        ...grid.p_md,
    },
    addDeviceByCode__content: {
        ...grid.colCenter,
        ...grid.full,
    },
    addDeviceByCode__title: {
        display: 'block',
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: textSizes.mdPlus,
    },
    addDeviceByCode__field: {
        ...grid.w20,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
        ...grid.mb_mini,
    },
    addDeviceByCode__footer: {
        ...grid.row,
        ...grid.center,
    },
    addDeviceByCode__label: {
        width: '100%',
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: textSizes.md,
    },
    addDeviceByCode__input: {
        textAlign: 'center',
        fontSize: textSizes.normalPlus,
    },
    addDeviceByCode__info: {
        width: '60%',
        paddingTop: indents.mini,
        textAlign: 'center',
    },
    license__info: {
        width: '100%',
        paddingTop: indents.mini,
        textAlign: 'center',
    },
    license__label: {
        width: '100%',
        textAlign: 'center',
        fontSize: textSizes.md,
    },
    addDeviceByCode__button: {
        marginRight: indents.mini,
    },
    addDeviceByCode_noVacant: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
    },
    noLicensesLeft: {
        display: 'flex',
        marginTop: 14,
        color: colors.notificationRed,

        span: {
            marginLeft: 10,
            fontSize: 11,
        },
    },
}

addStyles(styles)
