import React, { FC, Fragment, memo, useContext, useState } from 'react'
import { IReferenceBookListItemProps } from './ReferenceBookListItem-types'
import { styles } from './ReferenceBookListItem-styles'
import DndDroppable from 'blocks.app/dnd/_droppable/dnd_droppable'
import DndDraggable from 'blocks.app/dnd/_draggable/dnd_draggable'
import { CatalogWithPaginationContext } from 'organisms/CatalogWithPagination'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import { isExist } from 'core/utils/coreUtil'
import {
    ICatalogItem,
    ICatalogItemData,
    ICatalogWithPaginationOptionsReferenceBook,
} from 'organisms/CatalogWithPagination/CatalogWithPagination-types'
import { TableCell } from 'atoms/Table'
import helpers from 'core/helpers'
import { changeFilter } from 'features/routes'

const ReferenceBookListItemComponent: FC<IReferenceBookListItemProps> = ({ listItem, minimize }) => {
    const { methods, common, catalogMethods, catalogState } = useContext(CatalogWithPaginationContext)
    const { isFolder, getDragData } = methods
    const {
        referenceBook,
        isEnableDnD = true,
        isEnableSelectInfo = true,
        isEnableMultipleSelect = true,
        type,
        parentKey,
    } = common
    const {
        onDragEnd,
        onDragStart,
        onMoveItem,
        onResetSelectedInfo,
        removeSelectedItemById,
        addSelectedItem,
        onSelectInfo,
        onDoubleClickObject,
    } = catalogMethods
    const { selectedInfo, selectedItems } = catalogState
    const { itemComponent } = referenceBook as ICatalogWithPaginationOptionsReferenceBook
    const { id } = listItem

    const [isActiveDnd, setIsActiveDnD] = useState(false)
    const [isHovered, setIsHovered] = useState(false)

    const isDraggable = () => {
        if (!isEnableDnD) return false

        return typeof isFolder !== 'undefined'
    }

    const getDroppedItem = (data: ICatalogItemData): ICatalogItem | null => {
        try {
            return JSON.parse(data.dnd_item)
        } catch (err) {
            return null
        }
    }

    const changeFolder = () => {
        changeFilter({
            [helpers.prefixQuery({ name: type, field: 'query' })]: undefined,
            [parentKey]: id,
        })
    }

    const onDrop = (data: ICatalogItemData, e: React.MouseEvent) => {
        const dndItem = getDroppedItem(data)
        e.stopPropagation()

        if (!dndItem) {
            setIsActiveDnD(false)
            return
        }

        if (dndItem.id === id) {
            setIsActiveDnD(false)
            return
        }

        onMoveItem(
            dndItem,
            {
                type: 'folder',
                target: listItem,
            },
            setIsActiveDnD
        )
    }

    const isSelected = () => isExist(selectedItems.find((itemId) => itemId === id))

    const isActive = () => selectedInfo === id || isActiveDnd

    const onSelectItem = (isSelect: boolean, e: React.MouseEvent) => {
        e.stopPropagation()
        onResetSelectedInfo()

        if (isSelected()) {
            removeSelectedItemById(id)
            return
        }

        addSelectedItem(id)
    }

    const renderSelectCell = () => {
        return (
            <TableCell>
                <div className={styles.ReferenceBookListItem__checkbox}>
                    <Checkbox
                        onClick={onSelectItem}
                        checked={isSelected()}
                        mod={isActive() || isSelected() ? 'circle' : 'circle'}
                        disabled={!isEnableMultipleSelect}
                    />
                </div>
            </TableCell>
        )
    }

    const onClickItem = (e: React.MouseEvent) => {
        if (!isEnableSelectInfo) {
            e.preventDefault()
            return
        }

        if (!helpers.isDblTouchTap(e)) {
            onSelectInfo(id, listItem[parentKey] as number | null)
            return
        }

        onResetSelectedInfo()

        if (isFolder(listItem)) {
            changeFolder()
            return
        }

        if (onDoubleClickObject) {
            onDoubleClickObject(listItem)
        }

        e.preventDefault()
        return
    }

    const render = () => {
        const ReferenceBookListItemComponent = itemComponent

        return <ReferenceBookListItemComponent item={listItem} isActive={false} isHovered={isHovered} />
    }

    const createDragData = (item: ICatalogItem) => {
        return getDragData ? getDragData(item) : JSON.stringify(item)
    }

    const onMouseEnter = () => setIsHovered(true)

    const onMouseLeave = () => setIsHovered(false)

    return (
        <Fragment>
            <DndDroppable
                tag={'tr'}
                data={createDragData(listItem)}
                dragEnabled={isDraggable()}
                dropEnabled={isFolder(listItem)}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                wrapperComponent={{ type: DndDraggable }}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDrop={onDrop}
                onClick={onClickItem}
            >
                {isEnableMultipleSelect && renderSelectCell()}
                {render()}
            </DndDroppable>
        </Fragment>
    )
}

export const ReferenceBookListItem = memo(ReferenceBookListItemComponent)
