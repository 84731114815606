import { useState } from 'react'

const CatalogBreadcrumbsMethods = (p_) => {
    const [isHovered, setIsHovered] = useState(false)

    function onDrop(data, e) {
        e.stopPropagation()

        const droppedItem = JSON.parse(data.dnd_item)
        const maxBreadcrumbDepth = p_.list.length - 1
        const currentBreadcrumbDepth = p_.listItemData.index

        if (currentBreadcrumbDepth === maxBreadcrumbDepth) {
            setIsHovered(false)
            return
        }

        if (p_.template.multipleMove && p_.multipleSelect && p_.selectedItems.length) {
            const droppedItemInSelected = p_.selectedItems.filter((selectedItem) =>
                p_.template.isEqual(selectedItem, droppedItem)
            )

            if (droppedItemInSelected.length) {
                onMoveMultiple()
                return
            }
        }

        const breadcrumb = p_.listItemData.breadcrumb
        const isDroppedFolder = p_.template.isFolder(droppedItem)

        if (isDroppedFolder && droppedItem.id === breadcrumb.id) {
            setIsHovered(false)
            return
        }

        const moveMethod = isDroppedFolder ? p_.template.moveFolder : p_.template.moveItem
        const breadcrumbId = breadcrumb.id || null

        moveMethod(droppedItem.id, breadcrumbId)
        setIsHovered(false)
    }

    function onMoveMultiple() {
        const sourceId = []
        const folderId = []
        const breadcrumb = p_.listItemData.breadcrumb
        const newFolderId = breadcrumb.id || null

        p_.selectedItems.forEach((item) => {
            const isFolder = p_.template.isFolder(item)

            if (isFolder && item.id === newFolderId) {
                return
            }

            p_.template.isFolder(item) ? folderId.push(item.id) : sourceId.push(item.id)
        })

        p_.template.multipleMove(sourceId, folderId, newFolderId)
        setIsHovered(false)
    }

    function onDragOver() {
        if (!isHovered) {
            setIsHovered(true)
        }
    }

    function onDragLeave() {
        if (isHovered) {
            setIsHovered(false)
        }
    }

    return {
        s_: {
            isHovered
        },
        methods: {
            onDrop,
            onDragOver,
            onDragLeave
        }
    }
}

export default CatalogBreadcrumbsMethods
