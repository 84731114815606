import Icon from 'blocks.simple/icon/icon'
import translate from 'core/translate'
import { isExist } from 'core/utils/coreUtil'
import { cn } from 'ethcss'
import React, { FC } from 'react'
import { colors } from 'theme'
import { styles } from './EditorAreaControlPanel-styles'
import { IEditorAreaControlPanel } from './EditorAreaControlPanel-types'

export const EditorAreaControlPanel: FC<IEditorAreaControlPanel> = ({
    isSelectedArea,
    selected,
    selectedContent,
    onControlPanerClick,
    time,
    files,
    mouseOver,
}) => {
    const isVisibleContent = (fileItem: any) => {
        return time >= fileItem.startTime && time < fileItem.startTime + fileItem.duration
    }

    const renderControlPanelAreaButton = () => {
        const isSelectedOnlyArea = isSelectedArea && !isExist(selectedContent)

        return (
            <button
                className={cn(styles.controlPanel__button, styles.controlPanel__button_type_area, {
                    [styles.controlPanel__button_type_active]: isSelectedOnlyArea,
                })}
                onClick={() => onControlPanerClick('area')}
            >
                <Icon type="drag_area" color={colors.white} tooltip={{ title: translate('resizeArea') }} />
            </button>
        )
    }

    const renderControlPanelContentButtons = () => {
        return files
            .filter((fileItem) => isVisibleContent(fileItem))
            .map((fileItem, index) => {
                const isSelected = selected && selectedContent === fileItem.index

                return (
                    <button
                        key={index}
                        className={cn(styles.controlPanel__button, styles.controlPanel__button_type_content, {
                            [styles.controlPanel__button_type_active]: isSelected,
                        })}
                        onClick={() => onControlPanerClick('content', fileItem.index)}
                    >
                        <Icon
                            type="drag_image"
                            color={colors.white}
                            tooltip={{ title: `${translate('resizeContent')} ${fileItem.name}` }}
                        />
                    </button>
                )
            })
    }

    return (
        <div className={cn(styles.controlPanel, !mouseOver ? styles.hidePanel : '')}>
            {renderControlPanelAreaButton()}
            {renderControlPanelContentButtons()}
        </div>
    )
}
