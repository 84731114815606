import React, { FC } from 'react'
import { MarkerProps } from './Marker-types'
import { isExist } from '../../core/utils/coreUtil'
import { cn } from 'ethcss'

import { markerStyles } from './Marker-styles'

const Marker: FC<MarkerProps> = ({ type = 'default', color, className, size, mod }) => {
    if (!isExist(type)) {
        return null
    }

    return (
        <div
            className={cn(
                className,
                markerStyles.markerCircle,
                markerStyles[`markerCircle_${size}`],
                markerStyles[`markerCircle_${mod}`]
            )}
            style={{ backgroundColor: color }}
        />
    )
}

export { Marker }
