import React from 'react'
import deepEqual from 'fast-deep-equal'
import throttle from 'lodash/throttle'

interface IDraggableProps {
    cursor?: string
    selectedBorderColor: string
    disableResize?: boolean
    isShowDraggableSquares?: boolean
    minWidth?: number
    onDrag?: (position: { top: number; left: number; width: number; height: number }) => void
    moveCursor?: string
    onDragEnd?: (position: { top: number; left: number; width: number; height: number }) => boolean
    onSelect?: () => void
    minHeight?: number
    onResize?: (position: { top: number; left: number; width: number; height: number }, right: string) => void
    onResizeEnd?: (position: { top: number; left: number; width: number; height: number }) => boolean
    top: number
    isDraggable: boolean
    left: number
    maxHeight?: number
    width: number
    isFixed?: boolean
    height: number
    maxWidth?: number
    disableDrag?: boolean
    enableResize?: {
        bottomLeft: boolean
        top: boolean
        left: boolean
        bottom: boolean
        bottomRight: boolean
        topLeft: boolean
        topRight: boolean
        right: boolean
    }
    selectedSquareColorType?: string
    zIndex?: number
    resizableZIndex?: number
    selected?: boolean
    noBorder?: boolean
    borderColor?: string
    className?: string
    parentRect?: {
        width: number
        height: number
        top: number
        left: number
    }
    target?: string
    relativeOnBody?: boolean
    isResizable?: boolean
}

interface IState {
    isDraggable: boolean
    isResizable: boolean
    resizableMod: string | null
    width: string
    height: string
    left: number
    top: number
}

class DraggableMethods extends React.Component<IDraggableProps> {
    state: IState
    mousePosition: {
        left: number
        top: number
    }
    startPosition: {
        left: number
        top: number
        deltaRight: number
        deltaLeft: number
        deltaTop: number
        deltaBottom: number
    }
    moveBorder: {
        left: number
        top: number
        right: number
        bottom: number
    }
    isStartDrag: boolean = false
    wrapper: any = null

    static defaultProps: {
        cursor: string
        selectedBorderColor: string
        disableResize?: boolean
        isShowDraggableSquares: boolean
        minWidth: number
        onDrag: (value: { position: { top: number; left: number; width: number; height: number } }) => void
        moveCursor: string
        onDragEnd: (value: { position: { top: number; left: number; width: number; height: number } }) => boolean
        onSelect: () => void
        bordercolor: string
        minHeight: number
        onResize: () => void
        onResizeEnd: () => boolean
        top: number
        isDraggable: boolean
        left: number
        maxHeight: number
        width: number
        isFixed: boolean
        height: number
        maxWidth: number
        disableDrag: boolean
        enableResize: {
            bottomLeft: boolean
            top: boolean
            left: boolean
            bottom: boolean
            bottomRight: boolean
            topLeft: boolean
            topRight: boolean
            right: boolean
        }
        selectedSquareColorType: string
    }

    constructor(p_: any) {
        super(p_)

        this.state = {
            isDraggable: false,
            isResizable: false,
            resizableMod: null,
            width: p_.width,
            height: p_.height,
            left: p_.left,
            top: p_.top,
        }
        this.mousePosition = {
            left: 0,
            top: 0,
        }
        this.startPosition = {
            left: 0,
            top: 0,
            deltaRight: 0,
            deltaLeft: 0,
            deltaTop: 0,
            deltaBottom: 0,
        }
        this.moveBorder = {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
        }
        this.getPosition = this.getPosition.bind(this)
        this.setPosition = this.setPosition.bind(this)
        this.validateWidth = this.validateWidth.bind(this)
        this.validateHeight = this.validateHeight.bind(this)
        this.setStartPosition = this.setStartPosition.bind(this)
        this.mouseMoveHandler = this.mouseMoveHandler.bind(this)
        this.mouseDownHandler = this.mouseDownHandler.bind(this)
        this.mouseDownResizeHandler = this.mouseDownResizeHandler.bind(this)
        this.mouseUpHandler = this.mouseUpHandler.bind(this)
        this.onDrag = this.onDrag.bind(this)
        this.onResizeRight = this.onResizeRight.bind(this)
        this.onResizeLeft = this.onResizeLeft.bind(this)
        this.onResizeBottom = this.onResizeBottom.bind(this)
        this.onResizeTop = this.onResizeTop.bind(this)
    }

    componentDidMount() {
        const p_ = this.props
        this.addEventListeners()
        if (p_.isDraggable) {
            this.mouseDownHandler()
        }
    }

    componentWillUnmount() {
        this.removeEventListeners()
    }

    shouldComponentUpdate(p_: IDraggableProps, s_: IState) {
        const prevProps = this.props

        if (s_.isDraggable && s_.isResizable) {
            return false
        }

        const prevPosition = {
            width: prevProps.width,
            height: prevProps.height,
            top: prevProps.top,
            left: prevProps.left,
        }
        const nextPosition = {
            width: p_.width,
            height: p_.height,
            top: p_.top,
            left: p_.left,
        }

        if (!deepEqual(prevPosition, nextPosition)) {
            this.setPosition(p_)
        }

        if (p_.isDraggable && !prevProps.isDraggable) {
            this.mouseDownHandler()
        }

        return true
    }

    addEventListeners = () => {
        const p_ = this.props

        if (p_.target) {
            const element = document.getElementById(p_.target)
            if (!element) {
                return
            }
            element.addEventListener('mousemove', this.mouseMoveHandler)
            element.addEventListener('mouseup', this.mouseUpHandler)
            return
        }

        document.addEventListener('mousemove', this.mouseMoveHandler)
        document.addEventListener('mouseup', this.mouseUpHandler)
    }

    removeEventListeners = () => {
        const p_ = this.props

        if (p_.target) {
            const element = document.getElementById(p_.target)
            if (!element) {
                return
            }
            element.removeEventListener('mousemove', this.mouseMoveHandler)
            element.removeEventListener('mouseup', this.mouseUpHandler)
            return
        }

        document.removeEventListener('mousemove', this.mouseMoveHandler)
        document.removeEventListener('mouseup', this.mouseUpHandler)
    }

    setPosition(p_: { width: number; height: number; left: number; top: number }) {
        this.setState({
            width: p_.width,
            height: p_.height,
            left: p_.left,
            top: p_.top,
        })
    }

    getPosition(state: any) {
        const s_ = state || this.state

        return {
            width: s_.width,
            height: s_.height,
            left: s_.left,
            top: s_.top,
        }
    }

    mouseMoveHandler(e: MouseEvent) {
        const s_ = this.state

        if (!s_.isDraggable && !s_.isResizable) {
            return
        }
        const rect = this.getRect()

        this.mousePosition = {
            left: (e.pageX - rect.left) / rect.width,
            top: (e.pageY - rect.top) / rect.height,
        }
        if (s_.isDraggable) {
            this.onDrag()
            return
        }

        if (s_.isResizable) {
            if (s_.resizableMod === 'right') {
                this.onResizeRight()
                return
            }
            if (s_.resizableMod === 'left') {
                this.onResizeLeft()
                return
            }
            if (s_.resizableMod === 'bottom') {
                this.onResizeBottom()
                return
            }
            if (s_.resizableMod === 'top') {
                this.onResizeTop()
                return
            }

            if (s_.resizableMod === 'topRight') {
                this.onResizeTop()
                this.onResizeRight()
                return
            }
            if (s_.resizableMod === 'bottomRight') {
                this.onResizeBottom()
                this.onResizeRight()
                return
            }
            if (s_.resizableMod === 'bottomLeft') {
                this.onResizeBottom()
                this.onResizeLeft()
                return
            }
            if (s_.resizableMod === 'topLeft') {
                this.onResizeTop()
                this.onResizeLeft()
            }
        }
    }

    mouseUpHandler() {
        const s_ = this.state
        const p_ = this.props
        let isValid = false

        if (s_.isDraggable) {
            s_.isDraggable = p_.isDraggable
            isValid = p_.onDragEnd ? p_.onDragEnd(this.getPosition(s_)) : false
        }
        if (s_.isResizable) {
            s_.isResizable = false
            isValid = p_.onResizeEnd ? p_.onResizeEnd(this.getPosition(s_)) : false
        }

        this.setState(s_)
        if (!isValid) {
            this.setPosition(p_)
        }
    }

    mouseDownHandler(e?: any) {
        const p_ = this.props
        const s_ = this.state

        if (p_.onSelect) {
            p_.onSelect()
        }

        if (p_.disableDrag) {
            return
        }

        if (e) {
            this.setStartPosition(e)
            this.isStartDrag = true
        }

        this.moveBorder = {
            left: 0,
            top: 0,
            right: 1 - +s_.width,
            bottom: 1 - +s_.height,
        }
        s_.isDraggable = true
        this.setState(s_)
    }

    getRect = () => {
        const p_ = this.props
        if (!this.wrapper) return
        const parentNode = this.wrapper.parentNode
        const rect = p_.parentRect || parentNode.getBoundingClientRect()

        return rect
    }

    setStartPosition(e: React.MouseEvent<HTMLDivElement>) {
        const s_ = this.state
        const rect = this.getRect()

        const relLeft = (e.pageX - rect.left) / rect.width
        const relTop = (e.pageY - rect.top) / rect.height
        const left = relLeft - s_.left
        const top = relTop - s_.top
        const deltaRight = relLeft - (+s_.left + +s_.width)
        const deltaLeft = relLeft - s_.left
        const deltaBottom = relTop - (+s_.top + +s_.height)
        const deltaTop = relTop - s_.top

        this.startPosition = {
            left,
            top,
            deltaRight,
            deltaLeft,
            deltaBottom,
            deltaTop,
        }
    }

    mouseDownResizeHandler(e: React.MouseEvent<HTMLDivElement>, resizableMod: string) {
        e.stopPropagation()
        const s_ = this.state
        const p_ = this.props

        if (p_.onSelect) {
            p_.onSelect()
        }

        this.setStartPosition(e)
        this.moveBorder = {
            left: 0,
            top: 0,
            right: 1,
            bottom: 1,
        }

        s_.isResizable = true
        s_.resizableMod = resizableMod
        this.setState(s_)
    }

    onDrag = throttle(() => {
        const { left, top } = this.mousePosition
        const s_ = this.state
        const p_ = this.props

        s_.left = this.validateHPos(left - this.startPosition.left)
        s_.top = this.validateVPos(top - this.startPosition.top)

        this.setState(s_)
        if (p_.onDrag) {
            p_.onDrag(this.getPosition(s_))
        }
    }, 50)

    onResizeRight() {
        const { left } = this.mousePosition
        const leftPos = this.validateHPos(left - this.startPosition.deltaRight)
        const s_ = this.state
        const p_ = this.props

        s_.width = this.validateWidth(leftPos - s_.left)
        this.setState(s_)
        if (p_.onResize) {
            p_.onResize(this.getPosition(s_), 'right')
        }
    }

    onResizeBottom() {
        const { top } = this.mousePosition
        const topPos = this.validateVPos(top - this.startPosition.deltaBottom)
        const s_ = this.state
        const p_ = this.props

        s_.height = this.validateHeight(topPos - s_.top)
        this.setState(s_)
        if (p_.onResize) {
            p_.onResize(this.getPosition(s_), 'right')
        }
    }

    onResizeLeft() {
        const { left } = this.mousePosition
        const leftPos = this.validateHPos(left - this.startPosition.deltaLeft)
        const s_ = this.state
        const p_ = this.props
        const rightBorder = s_.left + s_.width

        s_.width = this.validateWidth(+rightBorder - leftPos)
        s_.left = +rightBorder - +s_.width

        this.setState(s_)
        if (p_.onResize) {
            p_.onResize(this.getPosition(s_), 'right')
        }
    }

    onResizeTop() {
        const { top } = this.mousePosition
        const topPos = this.validateVPos(top - this.startPosition.deltaTop)
        const s_ = this.state
        const p_ = this.props
        const bottomBorder = s_.top + s_.height

        s_.height = this.validateHeight(+bottomBorder - +topPos)
        s_.top = +bottomBorder - +s_.height

        this.setState(s_)
        if (p_.onResize) {
            p_.onResize(this.getPosition(s_), 'right')
        }
    }

    validateHPos(pos: number) {
        if (pos < this.moveBorder.left) {
            return this.moveBorder.left
        }
        if (pos > this.moveBorder.right) {
            return this.moveBorder.right
        }

        return pos
    }

    validateWidth(width: any) {
        if (this.props.minWidth && +width < this.props.minWidth) {
            return this.props.minWidth
        }
        if (this.props.maxWidth && +width > this.props.maxWidth) {
            return this.props.maxWidth
        }

        return width
    }

    validateHeight(height: any) {
        if (this.props.minHeight && height < this.props.minHeight) {
            return this.props.minHeight
        }
        if (this.props.maxHeight && height > this.props.maxHeight) {
            return this.props.maxHeight
        }

        return height
    }

    validateVPos(pos: number) {
        if (pos < this.moveBorder.top) {
            return this.moveBorder.top
        }
        if (pos > this.moveBorder.bottom) {
            return this.moveBorder.bottom
        }

        return pos
    }
}

DraggableMethods.defaultProps = {
    width: 0.3,
    height: 0.3,
    top: 0,
    left: 0,
    minWidth: 0,
    maxWidth: 1,
    minHeight: 0,
    maxHeight: 1,
    onDrag: () => {},
    onDragEnd: () => true,
    onResize: () => {},
    onResizeEnd: () => true,
    onSelect: () => {},
    disableDrag: false,
    disableResize: false,
    enableResize: {
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: true,
        bottomRight: true,
        bottomLeft: true,
        topLeft: true,
    },
    bordercolor: 'darkCloud',
    selectedBorderColor: 'white',
    cursor: 'default',
    moveCursor: 'move',
    isDraggable: false,
    isFixed: false,
    isShowDraggableSquares: false,
    selectedSquareColorType: 'default',
}

export default DraggableMethods
