import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { IStyles } from '../../../theme'

const loaderBlockStyles: IStyles = {
    wrapper: {
        ...grid.rowCenter,
        ...item.backOpacity,
        ...item.abs,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 99,
    },
}

addStyles(loaderBlockStyles)
export default loaderBlockStyles
