import { useEffect, useState } from 'react'
import validator from 'validator'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import { isExist } from 'core/utils/coreUtil'
import { IUser, IUserTokens } from 'core/models/Users'
import request from 'superagent'
import { useLocation } from 'react-router'
import { emitError, emitAppError } from 'features/appNotifications/AppNotifications.state'
import { useDispatch } from 'react-redux'
import { login as loginAction } from 'blocks.app/user/user.state'
import { headActions } from 'features/head/Head.state'
import { getDefaultLocale } from '../helpers/user'

export type TAuthorizationLoginStepTypes = 'ONE' | 'TWO'

export interface IDefaultLoginData {
    user: IUser
    tokens: IUserTokens
}

export interface ITwoFactorLoginData {
    preToken: string
    prefix: string
    attempts: number
    timeoutS: number
}

export interface IUseAuthorizationLogin {
    authStep: TAuthorizationLoginStepTypes
    email: string
    password: string
    code: string
    prefix: string | null
    isLoading: boolean
    minAuthTimer: number
    secAuthTimer: number
    authTimerChanged: boolean
    onChangeEmail: (email: string) => void
    onChangePassword: (password: string) => void
    onChangeCode: (code: string) => void
    login: (dataTokens?: {
        tokens: { accessToken: string; refreshToken?: string }
        isYandexAuth?: boolean
        isGoogleAuth?: boolean
    }) => void
    confirmCode: () => void
}

export interface IUseAuthorizationLoginProps {
    onLoading?: (loading: boolean) => void
    accessToken?: string
    onLogin?: (user: IUser) => void
}

export interface ILoginFirstStepRequest {
    email: string
    password: string
    addAccountTo?: string
}

export interface ILoginSecondStepRequest {
    code: string
    preToken: string
}

export const useAuthorizationLogin = ({
    onLoading,
    onLogin,
    accessToken,
}: IUseAuthorizationLoginProps): IUseAuthorizationLogin => {
    const dispatch = useDispatch()
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [code, setCode] = useState<string>('')
    const [attempts, setAttempts] = useState<number | null>(null)
    const [timeoutS, setTimeoutS] = useState<number | null>(null)
    const [prefix, setPrefix] = useState<string | null>(null)
    const [preToken, setPreToken] = useState<string | null>(null)
    const [authStep, setAuthStep] = useState<TAuthorizationLoginStepTypes>('ONE')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [secAuthTimer, setSecAuthTimer] = useState<number>(0)
    const [minAuthTimer, setMinAuthTimer] = useState<number>(0)
    const [authTimerChanged, setAuthTimerChanged] = useState<boolean>(false)
    const location = useLocation()

    useEffect(() => {
        if (location.pathname !== '/settings') {
            dispatch(headActions.setTitle([translate('login')]))
        }
    }, [])

    useEffect(() => {
        if (!timeoutS) return

        const timer = setTimeout(() => {
            clearSendCodeParams()
            clearUserData()
            dispatch(emitError('confirmEmailTimeoutError'))
        }, timeoutS * 1000)
        return () => {
            clearTimeout(timer)
        }
    }, [timeoutS])

    const clearSendCodeParams = () => {
        setAttempts(null)
        setPrefix(null)
        setTimeoutS(null)
        setPreToken(null)
        setAuthStep('ONE')
    }

    const clearUserData = () => {
        setPassword('')
        setEmail('')
        setCode('')
    }

    const onChangeEmail = (email: string) => {
        setEmail(email.trim())
    }

    const onChangePassword = (password: string) => {
        setPassword(password)
    }

    const onChangeCode = (code: string) => {
        setCode(code)
    }

    const onLoadingCb = (value: boolean) => {
        if (!onLoading) return

        onLoading(value)
    }

    const validate = () => {
        if (!email) {
            dispatch(emitError('emailEmpty'))
            return false
        }
        if (!password) {
            dispatch(emitError('passwordEmpty'))
            return false
        }
        if (!validator.isEmail(email)) {
            dispatch(emitError('emailFormatError'))
            return false
        }

        return true
    }

    const onTwoFactorLoginSuccess = (data: ITwoFactorLoginData) => {
        let { attempts, preToken, timeoutS, prefix } = data

        if (data) {
            setAttempts(attempts)
            setPreToken(preToken)
            setTimeoutS(timeoutS)
            setPrefix(prefix)
            setAuthStep('TWO')
        }
    }

    const onDefaultLoginSuccess = (data: IDefaultLoginData) => {
        if (onLogin) {
            onLogin(data.user)
        } else {
            dispatch(
                loginAction({
                    tokens: data.tokens,
                    user: data.user,
                })
            )
        }
    }

    const onLoginError = (response: any) => {
        let errorMessage = 'serverIsNotAvailable'

        if (response && response.body && response.body.error === 80) {
            setMinAuthTimer(response.body.data.blockedFor.min)
            setSecAuthTimer(response.body.data.blockedFor.sec)
            setAuthTimerChanged(!authTimerChanged)
        }
        if (response) {
            errorMessage = response.body.error
        }

        dispatch(emitError(errorMessage))
    }

    const onConfirmCodeError = (response: any) => {
        let errorMessage = 'serverIsNotAvailable'
        let additionMessage = ''

        if (response) {
            errorMessage = response.body.error
            let data = response.body.data

            if (data && data.attempts > 0) {
                setAttempts(data.attempts)
                additionMessage = `${data.attempts}`
            }

            if (data && data.attempts === 0) {
                clearSendCodeParams()
                clearUserData()
                additionMessage = '0'
            }
        }

        dispatch(
            emitError({
                error: errorMessage,
                message: additionMessage,
            })
        )
    }

    const confirmCode = () => {
        if (!isExist(code) || !preToken || !isExist(attempts) || attempts === 0) return

        onLoadingCb(true)
        setIsLoading(true)

        api.post<ILoginSecondStepRequest>('login', { code, preToken })
            .then((res: request.Response) => {
                onLoadingCb(false)
                setIsLoading(false)
                clearSendCodeParams()
                clearUserData()

                let data: IDefaultLoginData = res.body.data
                onDefaultLoginSuccess(data)
            })
            .catch(({ response }: { response: any }) => {
                onLoadingCb(false)
                setIsLoading(false)
                onConfirmCodeError(response)
            })
    }

    const login = (dataTokens?: {
        tokens: { accessToken: string; refreshToken?: string }
        isYandexAuth?: boolean
        isGoogleAuth?: boolean
    }) => {
        if (dataTokens && dataTokens.isGoogleAuth && !dataTokens.isYandexAuth) {
            onLoadingCb(true)
            setIsLoading(true)

            const accessToken: string = dataTokens.tokens.accessToken

            fetch(window.spconfig.ip_address + 'user/auth/google', {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify({
                    accessToken,
                    expireD: window.spconfig.registration.expireD,
                    locale: getDefaultLocale(),
                }),
            })
                .then((res) => res.json())
                .then((json) => {
                    onLoadingCb(false)
                    setIsLoading(false)
                    const data = {
                        tokens: json.data.tokens,
                        user: json.data.user,
                    }
                    onDefaultLoginSuccess(data as IDefaultLoginData)
                })
                .catch(({ response }: { response: any }) => {
                    onLoadingCb(false)
                    setIsLoading(false)
                    onLoginError(response)
                })

            clearQueryString()

            return
        }

        if (dataTokens && dataTokens.isYandexAuth && !dataTokens.isGoogleAuth) {
            onLoadingCb(true)
            setIsLoading(true)

            const accessToken: string = dataTokens.tokens.accessToken

            fetch(window.spconfig.ip_address + 'user/auth/yandex', {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify({
                    accessToken,
                    expireD: window.spconfig.registration.expireD,
                    locale: getDefaultLocale(),
                }),
            })
                .then((res) => res.json())
                .then((json) => {
                    onLoadingCb(false)
                    setIsLoading(false)
                    const data = {
                        tokens: json.data.tokens,
                        user: json.data.user,
                    }
                    onDefaultLoginSuccess(data as IDefaultLoginData)
                })
                .catch(({ response }: { response: any }) => {
                    onLoadingCb(false)
                    setIsLoading(false)
                    onLoginError(response)
                })

            clearQueryString()

            return
        }

        if (dataTokens && !dataTokens.isYandexAuth && !dataTokens.isGoogleAuth) {
            const tokens = dataTokens.tokens

            fetch(window.spconfig.ip_address + 'user', {
                method: 'GET',
                headers: new Headers({
                    Authorization: `Bearer ${tokens.accessToken}`,
                }),
            })
                .then((res) => res.json())
                .then((json) => {
                    const data = {
                        tokens: tokens,
                        user: json.data,
                    }

                    onDefaultLoginSuccess(data as IDefaultLoginData)
                })

            clearQueryString()

            return
        }

        if (!validate()) {
            return
        }

        onLoadingCb(true)
        setIsLoading(true)

        let loginParams: ILoginFirstStepRequest = { email, password }

        if (accessToken) {
            loginParams.addAccountTo = accessToken
        }

        api.post<ILoginFirstStepRequest>('login', loginParams)
            .then((res: request.Response) => {
                onLoadingCb(false)
                setIsLoading(false)

                let data: ITwoFactorLoginData | (IDefaultLoginData & { preToken?: null }) = res.body.data

                if (data.preToken) {
                    onTwoFactorLoginSuccess(data as ITwoFactorLoginData)
                } else {
                    onDefaultLoginSuccess(data as IDefaultLoginData)
                    clearUserData()
                }
            })
            .catch(({ response }: { response: any }) => {
                onLoadingCb(false)
                setIsLoading(false)
                onLoginError(response)
            })
    }
    function clearQueryString() {
        const location = window.location
        const params = new URLSearchParams(location.search)
        params.delete('success')
        params.delete('result')
        window.history.replaceState(null, '', '?' + params + '#' + location.hash)
    }

    return {
        password,
        email,
        login,
        code,
        prefix,
        confirmCode,
        onChangeEmail,
        onChangePassword,
        onChangeCode,
        authStep,
        isLoading,
        secAuthTimer,
        minAuthTimer,
        authTimerChanged,
    }
}
