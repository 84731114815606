import { useEffect, useState } from 'react'
import { transitions } from 'blocks.app/config'
import { ICollapseProps } from './collapse'

const CollapseMethods = (p_: ICollapseProps) => {
    const [open, setOpen] = useState<boolean>(p_.initialOpen || false)
    const [withoutOverflow, setWithoutOverflow] = useState<boolean>(false)
    const [dimensions, setDimensions] = useState<any>({})
    let animationTimer: any = null

    useEffect(() => {
        return () => clearTimeout(animationTimer)
    }, [])

    const onClick = () => {
        if (!p_.disabled) {
            const _open = !open

            toggleOverflow(_open)
            setOpen(_open)
            p_.onToggle(_open)
        }
        if (p_.onClick) {
            p_.onClick()
        }
    }
    const toggleOverflow = (status: boolean) => {
        if (!p_.withoutOverflow) {
            return
        }

        if (status) {
            animationTimer = setTimeout(() => {
                setWithoutOverflow(true)
            }, transitions.normal)
        } else {
            clearTimeout(animationTimer)
            setWithoutOverflow(false)
        }
    }

    return {
        open,
        withoutOverflow,
        dimensions,
        onClick,
        setDimensions,
    }
}

export default CollapseMethods
