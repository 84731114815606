import React, { useEffect, useState } from 'react'
import styles from './display__control.jcss'
import { Preview } from './display__preview'
import { Remote } from './display__remote'
import { api } from '../../../core/api/ConnectionManager'
import translate from '../../../core/translate'
import { RootStateOrAny, useSelector } from 'react-redux'
import { cn } from 'ethcss'
import helpers from '../../../core/helpers'

export type Event = {
    type: string
    data: any
}

export const DisplayRemoteControl = ({ device }: any) => {
    const app: any = useSelector<RootStateOrAny>((state) => state.app)
    const [peerConnection, setPeerConnection] = useState<RTCPeerConnection | null>(null)
    const [showMobRemote, setShowMobRemote] = useState<boolean>(false)

    const sendEventToDS = (event: Event) => {
        if (!device) return
        if (
            !device.online ||
            !helpers.isAvailableAction(device, {
                key: 'digitalSignage',
                action: 'update',
            })
        )
            return
        if (event.type === 'click' && (!device.resolutionWidth || !device.resolutionHeight)) return

        console.log('send event to device')
        console.log(event)

        api.send('sendRemoteDeviceEvent', {
            digitalSignageId: device.id,
            ...event,
        })
    }

    const renderStatusMessage = (online: boolean) => {
        if (device && online) {
            if (!device.resolutionWidth || !device.resolutionHeight) {
                return translate('remoteControlNoResolution')
            }

            return ''
        }

        return translate('remoteControlDeviceIsOffline')
    }

    useEffect(() => {
        if (!device) return

        return () => {
            console.log('stop device stream')

            api.send('stopRemoteDeviceStream', { digitalSignageId: device.id })
        }
    }, [])
    useEffect(() => {
        return () => {
            if (peerConnection) {
                peerConnection.close()
            }
        }
    }, [peerConnection])

    return (
        device && (
            <div className={styles.wrapper}>
                <div className={styles.leftBlock}>
                    <div className={styles.caption}>
                        {translate('deviceManagement')}:<b> {device.name}</b>
                    </div>
                    <Preview
                        onEvent={sendEventToDS}
                        device={{
                            ...device,
                            resolutionWidth: device.resolutionWidth || 1920,
                            resolutionHeight: device.resolutionHeight || 1080,
                        }}
                        peerConnection={peerConnection}
                        setPeerConnection={setPeerConnection}
                    />
                    <div className={styles.statusWrapper}>
                        {!device.online && (
                            <div className={styles.status}>{`${translate('device')} ${translate('offline')}`}</div>
                        )}
                        <div className={styles.statusDescription}>{renderStatusMessage(device.online)}</div>
                    </div>
                </div>
                {helpers.isAvailableAction(device, {
                    key: 'digitalSignage',
                    action: 'update',
                }) && (
                    <>
                        <div
                            className={cn(styles.rightBlock, showMobRemote && app.isMobile ? styles.showMobRemote : '')}
                        >
                            <Remote onEvent={sendEventToDS} isMobile={app.isMobile} />
                            {app.isMobile && showMobRemote && (
                                <div className={styles.hideRemoteBtn} onClick={() => setShowMobRemote(false)}>
                                    <img src={'images/displays/hide-remote-icon.svg'} />
                                </div>
                            )}
                        </div>
                        {app.isMobile && !showMobRemote && (
                            <div className={styles.showRemoteBtn} onClick={() => setShowMobRemote(true)}>
                                <img src={'images/displays/remote-icon.svg'} />
                            </div>
                        )}
                    </>
                )}
            </div>
        )
    )
}
