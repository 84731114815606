import { addStyles } from 'ethcss'
import { cols, grid, indents, IStyles, item, layers, mediaCSS } from 'theme'

export const styles: IStyles = {
    CatalogWithPagination: {
        ...grid.space,
        ...grid.col,
        ...item.overNone,
        position: 'relative',
    },
    CatalogWithPagination__hotKeys: {
        '&:focus': {
            outline: 'none',
        },
    },
    CatalogWithPagination__divider: {
        // paddingBottom: indents.md,
        paddingBottom: indents.mini,
    },
    CatalogWithPagination__hotKeys_type_shiftPressed: {
        userSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',
        MozUserSelect: 'none',
    },
    CatalogWithPagination__hotKeys_type_window: {
        ...item.overNone,
        ...item.overY,
    },
    CatalogWithPagination__main: {
        ...grid.flex,
        ...grid.col,
        ...grid.space,
        ...grid.p_mdPlus,
    },
    CatalogWithPagination__main_x: {
        padding: '66px 30px 30px 30px',
    },
    CatalogWithPagination__main_type_withToolbar: {
        width: `${100 - cols.normal}%`,
        [mediaCSS.tab]: {
            width: `100%!important`,
        },
    },
    CatalogWithPagination__toolbar: {
        ...grid.fullHeight,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        ...item.shadow,
        ...grid.col,
        overflowY: 'auto',
        zIndex: layers.sticky + 1,
        [mediaCSS.tab]: {
            ...item.transitionErase,
            zIndex: layers.sticky + 1,
            width: `50%!important`,
            maxWidth: 0,
        },
    },
    CatalogWithPagination__toolbar_top_x: {
        top: 64,
    },
    CatalogWithPagination__toolbar_padding_top_x: {
        paddingTop: 66,
    },
    CatalogWithPagination__toolbar_type_enable: {
        [mediaCSS.tab]: {
            maxWidth: '50%!important',
            ...item.transitionErase,
        },
    },
}

addStyles(styles)
