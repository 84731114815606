import React from 'react'
import ScheduleTimerMethods from './schedule__timer.local.methods'
import momentWithLocales from 'moment-with-locales-es6'
import { RootStateOrAny, useSelector } from 'react-redux'
import helpers from 'core/helpers'
import styles from './schedule__timer.jcss'
import { getDefaultLocale } from '../../../core/helpers/user'

const ScheduleTimer = (p_: { selectedDate: any }) => {
    const user: any = useSelector<RootStateOrAny>((state) => state.user)
    const { timer } = ScheduleTimerMethods()

    const getUserLocale = () => {
        const userData = user.data
        return userData?.settings?.locale || getDefaultLocale()
    }

    const isToday = helpers.isToday(p_.selectedDate.toString())
    const lang = getUserLocale()

    return (
        <div className={styles.wrapper}>
            {isToday && (
                <React.Fragment>
                    <div className={styles.day}>{momentWithLocales().locale(lang).format('dddd')}</div>
                    <div className={styles.time}>{timer}</div>
                </React.Fragment>
            )}
            {!isToday && momentWithLocales(p_.selectedDate).locale(lang).format('dddd')}
        </div>
    )
}

export default ScheduleTimer
