import React, { useState } from 'react'
import Content from 'blocks.app/content/content'
import Catalog from 'blocks.app/catalog/catalog'
import Toolbar from './__toolbar/advertisingCampaigns__toolbar'
import translate from 'core/translate'
import { routes } from 'features/routes'
import helpers from 'core/helpers'
import { getURLSearchParamsString } from 'features/routes/utils'
import { changeLocation } from 'features/routes'
import { checkAvailableModule } from '../../core/helpers/routes/routes'
import { checkRoutePathName } from 'core/helpers/routes/routes'
import styles from './advertisingCapmaigns'

const AdvertisingCampaigns = (p_: any) => {
    const [selectedInfo, setSelectedInfo] = useState(null)
    const [selectedItems, setSelectedItems] = useState<any>([])

    return (
        <Content
            className={p_.className}
            title={translate('promotions')}
            frame={p_.frame}
            toolbar={
                <Toolbar
                    selectedInfo={selectedInfo}
                    selectedItems={selectedItems}
                    onCloseInfo={() => setSelectedInfo(null)}
                    onChange={(selectedItems) => setSelectedItems(selectedItems)}
                />
            }
            wrapperClassName={styles.aDWrapper}
        >
            <Catalog
                type={'advertisingCampaigns'}
                selectedInfo={selectedInfo}
                onSelectInfo={(selectedInfo: any) => setSelectedInfo(selectedInfo)}
                onDoubleClickObject={function (advCampaign: any) {
                    if (
                        helpers.isAvailableAction(advCampaign, {
                            key: 'advertisingCampaign',
                            action: 'update',
                        })
                    ) {
                        changeLocation({
                            pathname: checkRoutePathName('/advertisingAds')
                                ? `/${routes.editAdvertisingAds.path}`
                                : `/${routes.editAdvertisingCampaign.path}`,
                            search: getURLSearchParamsString({
                                advId: advCampaign.id,
                            }),
                        })
                    }
                }}
                selectedItems={selectedItems}
                onSelect={(selectedItems: any) => setSelectedItems(selectedItems)}
                fixedHeader={true}
            />
        </Content>
    )
}

const exportAdvertisingCampaigns = checkAvailableModule('advertisingCampaigns') ? AdvertisingCampaigns : null

export default exportAdvertisingCampaigns
