import { addStyles } from 'ethcss'
import { grid, colors, IStyles } from 'theme'

const displayPlayAudioEditStyles: IStyles = {
    wrapper: {
        ...grid.flex,
        ...grid.center,
        ...grid.normalCenter,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
    },
    inner: {
        width: '620px',
        height: '600px',
        border: `1px solid ${colors.white}`,
        borderRadius: '10px',
        backgroundColor: colors.black,
        padding: '50px',
    },
    volume: {
        ...grid.flex,
        ...grid.normalCenter,
        marginBottom: '24px',

        '.counter_ftakoop': {
            color: colors.white,
        },
        'div[class^="handle"]': {
            backgroundColor: colors.white,
        },
        '.rc-slider-rail': {
            backgroundColor: '#2d2d2d',
        },
        '.rc-slider-step': {
            backgroundColor: 'transparent',
            border: 'none',
        },
        '.rc-slider-track': {
            backgroundColor: '#666666',
        },
        svg: {
            fill: colors.white,
        },
    },
    volumeLabel: {
        color: colors.white,
    },
    inputGroup: {
        marginBottom: '24px',

        label: {
            color: '#FFFFFF !important',
            marginBottom: '16px',
        },
        input: {
            border: `1px solid ${colors.white}`,
            backgroundColor: colors.black,
            color: colors.white,
        },
    },
    audioChoice: {
        ...grid.flex,
        ...grid.justify,

        button: {
            backgroundColor: '#CCCCCC',
            color: colors.black,
        },
    },
    audioName: {
        width: '74%',
    },
    audioChoiceBtnWrapper: {
        paddingTop: '34px',
    },
    actions: {
        ...grid.flex,
        ...grid.center,
        marginTop: '116px',

        button: {
            margin: '0 20px',
        },
    },
}

addStyles(displayPlayAudioEditStyles)

export default displayPlayAudioEditStyles
