import React from 'react'
import TouchEditorEventsMethods, { ITouchEditorEventsProps } from './touchEditor__events.local.methods'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import EditText from 'blocks.simple/editText/editText'
import translate from 'core/translate'
import styles from './touchEditor__events.jcss'
import { connect } from 'react-redux'
import { emitError } from 'features/appNotifications/AppNotifications.state'

const TouchEditorEvents = (p_: ITouchEditorEventsProps) => {
    const { state, setState, onChangeEvent, onChangeKey } = TouchEditorEventsMethods(p_)

    const getFormInputs = () => {
        const s_ = state
        const genderOptions = [
            { id: 'male', name: translate('male') },
            { id: 'female', name: translate('female') },
        ]

        switch (`${s_.selectedEvent}_${s_.selectedKey}`) {
            case 'face_recognition_gender':
                return (
                    <Dropdown
                        options={genderOptions}
                        value={s_.eventCondition.value}
                        label={translate('value')}
                        onChange={(selectedCondition) => {
                            setState((prevState) => {
                                return {
                                    ...prevState,
                                    eventCondition: {
                                        comparisonType: 'EQUAL',
                                        key: s_.selectedKey,
                                        value: selectedCondition.id,
                                    },
                                }
                            })
                        }}
                    />
                )
            case 'rfid_tagId':
            case 'rfid_label':
                return (
                    <EditText
                        value={s_.eventCondition.value}
                        onChange={(value: string) => {
                            setState((prevState) => {
                                return {
                                    ...prevState,
                                    eventCondition: {
                                        comparisonType: 'EQUAL',
                                        key: s_.selectedKey,
                                        value,
                                    },
                                }
                            })
                        }}
                        label={translate('value')}
                    />
                )
            default:
                return null
        }
    }
    return (
        <React.Fragment>
            <div className={styles.eventType}>
                <Dropdown
                    options={p_.events}
                    value={state.selectedEvent}
                    label={translate('selectEvent')}
                    onChange={onChangeEvent}
                />
            </div>
            <div className={styles.settingsForm}>
                {state.selectedEvent && (
                    <Dropdown
                        options={state.keyOptions}
                        value={state.selectedKey}
                        label={translate('key')}
                        onChange={onChangeKey}
                    />
                )}
                <div className={styles.inputs}>{getFormInputs()}</div>
            </div>
        </React.Fragment>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        emitError: (err: string) => dispatch(emitError(err)),
    }
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(TouchEditorEvents)
