import { addStyles } from 'ethcss'
import { colors, IStyles, mediaCSS } from '../../theme'

const settingsStyles: IStyles = {
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        backgroundColor: colors.black,
        textAlign: 'center',
    },
    justify: {
        textAlign: 'justify',
    },
    vCenter: {
        fontSize: 0,
    },
    hLine: {
        width: '100%',
        height: 0,
    },
    vLine: {
        width: 0,
        height: '100%',
    },
    full: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    fullWidth: {
        position: 'relative',
        width: '100%',
    },
    col: {
        position: 'absolute',
        top: 0,
        bottom: 0,
    },
    title: {
        fontSize: '28px',
        marginBottom: '7px',
        color: '#646464',
    },
    points: {
        fontSize: '50px',
        marginTop: '12px',
        marginBottom: '25px',
        color: 'white',
    },
    subTitle: {
        fontSize: '22px',
        color: '#646464',
    },
}

addStyles(settingsStyles)
export default settingsStyles
