import React from 'react'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './deviceSettings__logLevel.jcss'
import { Typography } from 'atoms/Typography'

const DeviceSettingsLogLevel = (p_: {
    enabled: boolean
    value: string
    className: string | object
    onChange: (logLevel: any, field: string) => void
}) => {
    if (!p_.enabled) {
        return null
    }

    const logLevelOptions = [
        { id: 'Trace', name: 'Trace' },
        { id: 'Debug', name: 'Debug' },
        { id: 'Info', name: 'Info' },
        { id: 'Warn', name: 'Warn' },
        { id: 'Error', name: 'Error' },
        { id: 'Fatal', name: 'Fatal' },
        { id: 'Off', name: translate('off') },
    ]

    return (
        <div className={cn(p_.className)}>
            <Typography className={cn(styles.title)} type="title">
                {translate('logLevel')}
            </Typography>
            <div className={styles.dropdownWrapper}>
                <Dropdown
                    value={p_.value}
                    options={logLevelOptions}
                    onChange={function (logLevel) {
                        p_.onChange(logLevel.id, 'logLevel')
                    }}
                />
            </div>
        </div>
    )
}

export default React.memo(DeviceSettingsLogLevel)
