import { addStyles } from 'ethcss'
import { grid, mediaCSS, IStyles, colors } from '../../../theme'
import { textSizes } from 'theme/media'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'

const mediaHeightQuery = '@media (max-height: 960px)'
const mediaWidth600pxQuery = '@media (max-width: 600px)'

const displaysAddStyles: IStyles = {
    wrapper: {
        ...grid.col,
        ...grid.justify,
        ...grid.full,
        ...grid.p_md,
    },
    deviceParametersWrapper: {
        ...grid.col,
        ...grid.justify,
        ...grid.full,
        ...grid.p_md,
        [mediaCSS.tabMini]: {
            height: 'auto',
            paddingRight: 37,
        },
        backgroundColor: `var(--var-module-app-component-app-style-panel, ${defaultThemeAppStyles.panel})`,
    },
    formWrapper: {
        ...grid.w50,
        ...grid.row,
        [mediaWidth600pxQuery]: {
            height: '100%',
        },
        [mediaCSS.tab]: {
            ...grid.w100,
        },
    },
    form: {
        ...grid.row,
        ...grid.w100,
    },
    title: {
        fontWeight: 'bold',
        display: 'block',
        width: '100%',
        textAlign: 'center',
        fontSize: textSizes.mdPlus,
        [mediaCSS.tabMini]: {
            ...grid.mb_md,
            fontSize: 20,
            padding: '0 40px',
        },
    },
    inputLabel: {
        ...grid.mb_micro,
        textTransform: 'uppercase',
        display: 'block',
        width: '100%',
        textAlign: 'center',
        fontSize: textSizes.normalPlus,
    },
    noBoxShadow: {
        boxShadow: 'none !important',
    },
    content: {
        ...grid.colCenter,
        ...grid.full,
        [mediaCSS.tabMini]: {
            ...grid.mb_md,
        },
        [mediaHeightQuery]: {
            overflowY: 'auto',
        },
    },
    descriptionWrapper: {
        ...grid.w50,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    leftColumnContent: {
        ...grid.col,
        ...grid.w45,
        ...grid.mr_md,
        [mediaCSS.tabMini]: {
            ...grid.w100,
            ...grid.mr_none,
        },
    },
    rightColumnContent: {
        ...grid.w50,
        [mediaCSS.tabMini]: {
            ...grid.w100,
            ...grid.row,
        },
    },
    footer: {
        ...grid.row,
        ...grid.center,
        [mediaCSS.tabMini]: {
            ...grid.mb_md,
        },
    },
    displaysAdd__inputWrapper_type_tags: {
        ...grid.mb_micro,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
    disabled: {
        opacity: 0.5,
    },
    noLicensesLeft: {
        display: 'flex',
        color: colors.notificationRed,

        span: {
            marginLeft: 10,
            fontSize: 11,
        },
    },
}

addStyles(displaysAddStyles)
export default displaysAddStyles
