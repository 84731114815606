import React from 'react'
import Icon from 'blocks.simple/icon/icon'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import styles from './catalog__template_videoEditorTasks.jcss'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import store from 'core/store'
import { ItemData } from '../../../../core/models/Template'

const getActionIcon = (action: string | null) => {
    switch (action) {
        case 'removeAudio':
            return 'volume_mute'
        case 'convertToMp4':
            return 'mp4'
        case 'setVideoBitrate':
            return 'bitrate'
        case 'imageToVideo':
            return 'planning'
        case 'fadeIn':
        case 'fadeOut':
            return null
        case 'setFps':
            return 'frames'
        default:
            return action
    }
}

export default {
    getMethod: 'getVideoEditorTasks',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noTasks'),
    emptyIcon: 'not_allow',
    settingsPrefixes: 'videoEditorTasks',
    listWrapperClassName: styles.list,
    fields: () => [
        { id: 'fileName', name: translate('name') },
        { id: 'commands', name: translate('actions') },
        { id: 'status', name: translate('status') },
    ],
    sortOptions: () => [
        { id: '-status', name: translate('sortStatus') },
        { id: '-createdAt', name: translate('sortCreatedAt') },
        { id: '-id', name: translate('id') },
    ],
    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    isEqual: (firstItem: ItemData, secondItem: ItemData) => firstItem.id === secondItem.id,
    onCreateListeners: [(cb: any, clearList: string[]) => api.addObserver('videoEditorTaskCreated', cb, clearList)],
    onUpdateListeners: [(cb: any, clearList: string[]) => api.addObserver('videoEditorTaskUpdated', cb, clearList)],
    onDeleteListeners: [(cb: any, clearList: string[]) => api.addObserver('videoEditorTaskDeleted', cb, clearList)],
    isFolder: () => {},
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    onDeleteListItem(item: ItemData) {
        const disabled = ['in_queue', 'in_progress']

        if (item.status && disabled.includes(item.status)) {
            store.dispatch(emitError('cannotDeleteTaskInProcess'))
            return
        }

        api.send('deleteVideoEditorTask', { id: item.id })
    },
    tableWrapper: styles.tableWrapper,
    list: {
        fileName: ({ data }: { data: { fileName: string } }) => data.fileName,
        commands: ({ commands }: { commands: { action: string }[] }, active: boolean) => (
            <div className={cn(grid.row, grid.justify, grid.noWrap)}>
                {commands &&
                    commands.map((command) => (
                        <Icon
                            containerClass={cn(grid.ml_micro, grid.mr_micro)}
                            key={command.action}
                            type={getActionIcon(command.action)}
                            color={active ? 'white' : 'grey'}
                        />
                    ))}
            </div>
        ),
        status: ({ status }: { status: string }) => translate(`videoEditor_${status}`),
    },
}
