import { addStyles } from 'ethcss'

const fileDetailsStyles = {
    fileDetails: {
        display: 'flex',
        alignItems: 'flex-start',
        position: 'absolute',
        color: '#000',
        fontSize: '18px',
        bottom: '524px',
        left: 0,
        '& td': {
            background: 'none',
            border: 'none',
            padding: '0 10px 0 0',
        },
        '& tr': {
            background: 'none !important',
        },
    },
    thumbnail: {
        marginRight: '20px',

        '& img': {
            width: '94px',
            borderRadius: '8px',
        },
    },
}

addStyles(fileDetailsStyles)
export default fileDetailsStyles
