import React, { FC, memo, useEffect, useState } from 'react'
import Content from 'blocks.app/content/content'
import EditText from 'blocks.simple/editText/editText'
import Stick from 'blocks.simple/stick/stick'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import { styles } from './ScoreboardsAddFolder-styles'
import { text as textStyles, grid } from 'theme'
import { Typography } from 'atoms/Typography'
import { IScoreboardAvailableFolder, IScoreboardFolder, IScoreboardsAddFolder } from './ScoreboardsAddFolder-types'
import { routes } from 'features/routes'
import { api } from 'core/api/ConnectionManager'
import { isExist, isNotEmptyString, omitObjectKeysByValue } from 'core/utils/coreUtil'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import { treeViewHelpers } from 'core/helpers'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { changeLocation } from 'features/routes'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { useDispatch } from 'react-redux'
import { checkAvailableModule } from '../../../../core/helpers/routes/routes'

const initialState: IScoreboardFolder = {
    name: '',
    description: '',
    externalId: '',
    parentId: null,
}

const ScoreboardsAddFolderComponent: FC<IScoreboardsAddFolder> = ({ location }) => {
    const dispatch = useDispatch()
    const [formData, setFormData] = useState<IScoreboardFolder>(initialState)
    const [folders, setFolders] = useState<IScoreboardAvailableFolder[]>([])
    const { controllerFolderId, parentId } = getURLSearchParamsByLocation(location)

    useEffect(() => {
        const methodName = isEdit()
            ? 'getScoreboardsMoveAvailableParentFolders'
            : 'getScoreboardsCreateAvailableParentFolders'

        const requestData = isEdit() ? { ledControllerFolderId: controllerFolderId } : {}

        api.send<object, IScoreboardAvailableFolder[]>(methodName, { nestedQ: false, ...requestData }).then(
            (availableFolders) => {
                const updatedFolders = treeViewHelpers.addParentKeyIdForTree(availableFolders, 'scoreboards')
                setFolders(updatedFolders)
            }
        )
    }, [controllerFolderId])

    useEffect(() => {
        api.send<object, IScoreboardFolder>('getScoreboardFolderById', {
            ledControllerFolderId: controllerFolderId,
        }).then(({ name, parentId, externalId, description }) => {
            setFormData({ name, parentId, externalId, description })
        })
    }, [controllerFolderId])

    const getParentId = () => {
        if (location.state) {
            // @ts-ignore
            if (location.state.parentId) {
                // @ts-ignore
                const parentId = +location.state.parentId
                setFormData({
                    ...formData,
                    parentId,
                })

                return parentId
            }
        }

        return formData.parentId
    }

    const isEdit = () => isExist(controllerFolderId)

    const validateFormData = () => {
        const MAX_DESCRIPTION_LENGTH = 2000
        const MAX_NAME_LENGTH = 255
        const MAX_EXTERNAL_ID_LENGTH = 127
        const MIN_NAME_LENGTH = 3

        const { name, description, externalId, parentId } = formData

        if (!name) {
            dispatch(emitError(`nameEmptyError`))
            return false
        }

        if (!isEdit() && !parentId) {
            dispatch(emitError(`parentIdEmptyError`))
            return false
        }

        if (name.length < MIN_NAME_LENGTH || name.length > MAX_NAME_LENGTH) {
            dispatch(emitError('nameRangeError'))
            return false
        }

        if (description && description.length > MAX_DESCRIPTION_LENGTH) {
            dispatch(emitError('descriptionRangeError'))
            return false
        }

        if (externalId && externalId.length > MAX_EXTERNAL_ID_LENGTH) {
            dispatch(emitError('externalIdRangeError'))
            return false
        }

        return true
    }

    const getFormDataForSave = () => {
        const { name, description, externalId, parentId } = formData

        const updatedExternalId = isNotEmptyString(externalId) ? externalId : null

        return { parentId, name, description, externalId: updatedExternalId }
    }

    const getSendData = () => {
        const { name, description, externalId, parentId } = getFormDataForSave()

        if (!isEdit()) {
            return { parentId, name, description, externalId }
        }

        const sendData = { parentId, name, description, externalId, ledControllerFolderId: controllerFolderId }
        const filteredSendData = omitObjectKeysByValue<null>(sendData, null)

        return filteredSendData
    }

    const onSave = () => {
        const isValid = validateFormData()

        if (!isValid) return

        const methodName = isEdit() ? 'updateScoreboardFolder' : 'createScoreboardFolder'
        const sendData = getSendData()

        api.send(methodName, sendData)
            .then(() => onCancel())
            .catch(() => dispatch(emitError('nameExist')))
    }

    const onCancel = () => {
        changeLocation(`/${routes.scoreboards.path}${parentId ? '?parentId=' + parentId : ''}`)
    }

    const onFormDataChanged = (value: string, e: React.SyntheticEvent) => {
        const htmlElement = e.target as HTMLInputElement
        setFormData({
            ...formData,
            [htmlElement.id]: value,
        })
    }

    const onChangeParentFolder = (folder: IScoreboardAvailableFolder) => {
        setFormData({
            ...formData,
            parentId: folder.ledControllerFolderId,
        })
    }

    const getInputLabel = (textKey: string) => <div className={styles.inputLabel}>{translate(textKey)}</div>

    return (
        <Content>
            <Stick enabled>
                <div className={styles.buttons}>
                    <Button mod="fill" className={grid.mr_mini} onClick={onCancel}>
                        {translate('cancel')}
                    </Button>
                    <Button mod={'withBorder'} onClick={onSave}>
                        {translate('save')}
                    </Button>
                </div>
            </Stick>
            <div className={styles.contentWrapper}>
                <Typography className={styles.title} type="title">
                    {translate('settings')}
                </Typography>
                <div className={styles.content}>
                    <div className={styles.col}>
                        <div className={styles.inputWrapper}>
                            <EditText
                                label={getInputLabel('name')}
                                id="name"
                                className={textStyles.center}
                                value={formData.name}
                                onChange={onFormDataChanged}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <EditText
                                label={getInputLabel('id')}
                                id="externalId"
                                className={textStyles.center}
                                value={formData.externalId}
                                onChange={onFormDataChanged}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <Dropdown
                                placeholder={translate('chooseFolder')}
                                label={getInputLabel('folder')}
                                id="group"
                                value={formData.parentId ? formData.parentId : getParentId()}
                                valueField={'ledControllerFolderId'}
                                options={folders}
                                centered
                                searchable
                                type="tree"
                                mod="withBorder"
                                onChange={onChangeParentFolder}
                            />
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.inputWrapper}>
                            <EditText
                                label={getInputLabel('additionDescription')}
                                id="description"
                                type="area"
                                rows={6}
                                className={textStyles.center}
                                value={formData.description}
                                onChange={onFormDataChanged}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}

export const ScoreboardsAddFolder = checkAvailableModule('scoreboards/addFolder')
    ? memo(ScoreboardsAddFolderComponent)
    : null
