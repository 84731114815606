import { addStyles } from 'ethcss'
// import grid from 'blocks.simple/grid/grid.jcss'
// import {mediaCSS, textSizes} from 'theme/media'

const SmartFolderSettingsStyles: any = {
    rulesContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: '#ffffff',
        width: '450px',
        height: '450px',
        borderRadius: '20px',
        border: '1px solid grey',
        padding: '50px 40px',
    },
    emptyInfo: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#B3B3B3',
        textAlign: 'center',
    },
    ruleSelectInfo: {
        position: 'absolute',
        bottom: '94px',
        left: '50%',
        transform: 'translateX(-50%)',
        color: '#B3B3B3',
        textAlign: 'center',
    },
    editRule: {
        position: 'absolute',
        right: '42px',
        top: '50%',
        transform: 'translateY(-50%)',
        opacity: 0,
        transition: 'opacity 0.3s',
    },
    deleteRule: {
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        opacity: 0,
        transition: 'opacity 0.3s',
    },
    rule() {
        const self = this
        return {
            position: 'relative',
            marginBottom: '14px',
            paddingRight: '64px',

            '&:hover': {
                [`& .${self.editRule}`]: {
                    opacity: 1,
                },
                [`& .${self.deleteRule}`]: {
                    opacity: 1,
                },
            },
        }
    },
    ruleLeftPadding: {
        paddingLeft: '40px',
    },
    ruleTitle: {
        fontSize: '18px',
    },
    addBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '34px',
        height: '34px',
        borderRadius: '50%',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        margin: '0 auto',
        cursor: 'pointer',
    },
    rulesList: {
        maxHeight: '180px',
        overflow: 'auto',
        padding: '10px 0',
    },
    rulesListBtnWrapper: {
        display: 'flex',
        position: 'absolute',
        bottom: '22px',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    ruleContainer: {
        backgroundColor: 'rgba(255,255,255,1)',
        height: '100%',
        padding: '50px 60px',
        overflow: 'auto',
    },
    caption: {
        fontSize: '18px',
        fontWeight: 600,
        marginBottom: '38px',
    },
    subcaption: {
        marginBottom: '15px',
        fontSize: '16px',
    },
    settingsBlock: {
        marginBottom: '50px',
    },
    settingsRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '512px',
        marginBottom: '20px',
    },
    input: {
        width: '340px',

        '& label': {
            fontSize: 16,
        },
    },
    buttons: {
        display: 'flex',
    },
    backBtn: {
        width: '194px',
        marginRight: '30px',
        display: 'flex',
        justifyContent: 'center',
    },
    saveBtn: {
        width: '174px',
        display: 'flex',
        justifyContent: 'center',
    },
    switchWrapper: {
        display: 'flex',
    },
    switchCaption: {
        marginRight: '24px',
    },
    disabled: {
        opacity: 0.5,
    },
    selectedRule: {
        fontWeight: 'bold',
    },
    accept: {
        marginLeft: '24px',
    },
    radio: {
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
}

addStyles(SmartFolderSettingsStyles)
export default SmartFolderSettingsStyles
