import React from 'react'
import helpers from 'core/helpers'
import deepEqual from 'fast-deep-equal'
import { ISelectedInfo } from '../__info/displays__info.local.methods'

interface IDisplaysScreenShotsProps {
    selectedInfo: ISelectedInfo
    open: boolean
    onClose: () => void
}

interface IState {
    minimized: boolean
    maxZoom: boolean
    minZoom: boolean
    isScreenshotUpdating: boolean
    image: string
    caption: string
    type: string
    rotate: number
    url?: string
    item?: {
        matadata: string
        duration?: string
        src: string
        fileType: string
        id: number
        name: string
        folderId: number
        data: string
        isDirectory: number
        type: string
        displayAs: string
        thumbnail: string
        style: object
    }
}

const STEP_ON_ROTATE = 90
const MAX_ROTATE_VALUE = 270
const defaultImageState = {
    image: '',
    caption: '',
    type: '',
    rotate: 0,
}

class DisplaysScreenShotsMethods extends React.Component<IDisplaysScreenShotsProps> {
    state: IState = {
        ...defaultImageState,
        minimized: helpers.isMobileDevice(),
        maxZoom: false,
        minZoom: false,
        isScreenshotUpdating: false,
    }
    imageView: { zoomIn: () => void; zoomOut: () => void } | null = null
    zoomOut = () => {
        if (this.imageView) {
            if (this.imageView.zoomOut) {
                this.imageView.zoomOut()
            }
        }
    }
    zoomIn = () => {
        if (this.imageView) {
            if (this.imageView.zoomIn) {
                this.imageView.zoomIn()
            }
        }
    }
    shouldComponentUpdate(nextProps: IDisplaysScreenShotsProps) {
        const p_ = this.props
        if (!deepEqual(p_.selectedInfo, nextProps.selectedInfo)) {
            this.setState({ ...defaultImageState })
        }

        return true
    }
    onRotate = () => {
        let rotate = this.state.rotate

        if (rotate < MAX_ROTATE_VALUE) {
            rotate += STEP_ON_ROTATE
        } else {
            rotate = 0
        }

        this.setState({ rotate })
    }
}

export default DisplaysScreenShotsMethods
