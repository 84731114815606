import { addStyles } from 'ethcss'
import { grid, item, IStyles, mediaCSS } from '../../../theme'
import { defaultThemeStyles } from 'blocks.app/sidebar/Sidebar-theme'

const buttonsHeight = 63
const broadcastsToolbarContentStyles: IStyles = {
    viewInfo: {
        borderRadius: '5px',
        overflow: 'hidden',
    },
    viewInfoNoHiddenOverflow: {
        borderRadius: '5px',
    },
    toolbarContentWrapper: {
        ...grid.w100,
        ...grid.col,
        ...grid.space,
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-backgrounds, ${defaultThemeStyles.background})`,
    },
    toggleButton: {
        ...grid.w50,
        ...grid.rowCenter,
        cursor: 'pointer',
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-toggleButton, ${defaultThemeStyles.toggleButton})`,
        height: buttonsHeight,
    },
    buttonUploads: {
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-buttonUploads, ${defaultThemeStyles.buttonUploads})`,
    },
    content: {
        ...grid.w100,
        ...grid.col,
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-backgrounds, ${defaultThemeStyles.background})`,
        minHeight: `calc(100% - ${buttonsHeight}px)`,
    },
    empty: {
        ...grid.space,
        ...grid.col,
        ...grid.center,
        ...grid.normalCenter,
        ...grid.p_micro,
        textAlign: 'center',
        '& img': { ...grid.w100 },
    },
    description: {
        ...grid.mt_mdPlus,
        ...grid.mb_md,
        ...grid.w75,
        '&>p': {
            lineHeight: '22px',
        },
    },
    itemDescription: {
        ...grid.p_md,
        ...grid.pt_none,
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-backgrounds, ${defaultThemeStyles.background})`,
    },
    itemWrapper: {
        textAlign: 'center',
        color: `var(--var-module-sidebar-component-broadcast-style-title, ${defaultThemeStyles['broadcast-title']})`,
    },
    name: {
        ...grid.p_md,
        ...grid.pt_mdPlus,
    },
    scheduleListTitle: {
        ...grid.row,
        textAlign: 'center',
        ...grid.p_md,
        borderBottom: `3px solid var(--var-module-sidebar-component-sidebar-style-border, ${defaultThemeStyles.border})`,
    },
    scheduleEmptyTitle: {
        textAlign: 'center',
        ...grid.row,
        ...grid.p_md,
    },
    showMoreTrigger: {
        ...grid.row,
        ...grid.noWrap,
        ...grid.normalCenter,
        cursor: 'pointer',
        ...grid.justify,
        ...grid.p_md,
        textAlign: 'center',
    },
    separator: {
        borderBottom: `3px solid var(--var-module-sidebar-component-sidebar-style-border, ${defaultThemeStyles.border})`,
    },
    showMoreContentWrapper: {
        ...grid.p_md,
        '&>div:last-child': {
            ...grid.mb_none,
        },
    },
    showMoreContent: {
        ...grid.mb_md,
    },
    showMoreTitle: {
        fontWeight: 'bold',
        fontSize: 16,
        textAlign: 'center',
    },
    showMoreDescription: {
        textAlign: 'center',
        ...grid.pt_mini,
        '&>div': {
            ...grid.pb_micro,
        },
        '&>div:last-child': {
            ...grid.pb_none,
        },
    },
    menuItem: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.center,
        ...grid.p_mini,
        cursor: 'pointer',
        position: 'relative',
        color: `var(--var-module-sidebar-component-sidebar-style-menuText, ${defaultThemeStyles.menuText})`,
        textAlign: 'center',
        ...item.transitionErase,
        '&:hover': {
            color: `var(--var-module-sidebar-component-sidebar-style-menuText, ${defaultThemeStyles.menuTextHover})`,
        },
    },
    closeBtn: {
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',

        [mediaCSS.tab]: {
            bottom: 40,
        },
    },
}

addStyles(broadcastsToolbarContentStyles)
export default broadcastsToolbarContentStyles
