import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Location } from 'history'

export interface IRoutesState {
    redirectLocation: Location | null
}

const initialState: IRoutesState = {
    redirectLocation: null,
}

const routesSlice = createSlice({
    name: 'routes',
    initialState,
    reducers: {
        setRedirectLocation: (state, action: PayloadAction<Location>) => {
            state.redirectLocation = action.payload
        },
        clearRedirectLocation: (state) => {
            state.redirectLocation = null
        },
    },
})

const { reducer: routesReducer, actions: routesActions } = routesSlice
export { routesReducer, routesActions }
