import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { textSizes } from 'theme/media'
import { IStyles } from '../../theme'

const touchEditorStyles: IStyles = {
    modalWrapper: {
        ...grid.full,
        ...grid.rowCenter,
        ...item.blackOpacity,
    },
    modalInner: {
        ...grid.w90,
        ...item.white,
        ...item.shadowModal,
        ...item.rounded_full_mini,
        ...item.overNone,
        maxWidth: 500,
    },
    wrapper: {
        ...grid.col,
        ...grid.fullHeight,
        ...item.overNone,
        ...grid.p_md,
    },
    header: {
        fontSize: textSizes.md,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    footer: {
        ...grid.row,
        ...grid.center,
        ...grid.p_md,
    },
    settings: {
        ...grid.row,
        ...grid.full,
        ...grid.mb_md,
        ...grid.mt_mdPlus,
    },
    emptyMessage: {
        ...grid.colCenter,
        ...grid.full,
        fontSize: textSizes.md,
    },
}

addStyles(touchEditorStyles)
export default touchEditorStyles
