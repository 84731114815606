import { addStyles } from 'ethcss'
import { IStyles, grid, layers } from 'theme'

export const styles: IStyles = {
    videoChatModal: {
        ...grid.flex,
        background: 'rgba(0, 0, 0, 0.9)',
        zIndex: layers.videoChat,
    },
    videoChatModal__body: {
        ...grid.flex,
        ...grid.auto,
    },
}

addStyles(styles)
