import React, { FunctionComponent, memo } from 'react'
import { IAudioTrackListComponent } from './AudioTrackList-types'
import { audioTrackListStyles } from './AudioTrackList-styles'
import { isNotEmptyArray, isExist } from 'core/utils/coreUtil'
import { AudioTrackItem } from './AudioTrackItem'
//import {styles} from "../../pages/displays/organisms/InstalledApplicationList/InstalledApplicationList-styles";
import { CustomScroll } from '../../atoms/CustomScroll'

const AudioTrackListComponent: FunctionComponent<IAudioTrackListComponent> = ({
    title,
    audioTrackList,
    activeAudioTrackId,
}) => {
    const renderAudioTrackList = () => {
        if (!isExist(audioTrackList) || !isNotEmptyArray(audioTrackList)) return null

        return audioTrackList.map((audioTrack, index) => {
            let isActiveAudioTrack = isExist(activeAudioTrackId) && activeAudioTrackId === audioTrack.id

            return (
                <div className={audioTrackListStyles.AudioTrackList__item} key={audioTrack.id}>
                    <AudioTrackItem
                        id={audioTrack.id}
                        isActive={isActiveAudioTrack}
                        title={audioTrack.title}
                        duration={audioTrack.duration}
                    />
                </div>
            )
        })
    }

    return (
        <CustomScroll
            classNames={`${audioTrackListStyles.AudioTrackList} 
        ${
            !isExist(audioTrackList) || !isNotEmptyArray(audioTrackList)
                ? audioTrackListStyles.AudioTrackList__hide
                : ''
        }`}
        >
            {/*<div className={audioTrackListStyles.AudioTrackList__title}>*/}
            {/*    <span>{title}</span>*/}
            {/*</div>*/}
            <div className={audioTrackListStyles.AudioTrackList__content}>{renderAudioTrackList()}</div>
        </CustomScroll>
    )
}

export const AudioTrackList = memo(AudioTrackListComponent)
