import React, { FunctionComponent } from 'react'
import { Tooltip as Tippy } from 'react-tippy'

import { ITooltipComponent } from './Tooltip-types'

import 'react-tippy/dist/tippy.css'

/**
 * [Tooltip]
 * @param children (React.ReactElement<any>) - Show tooltip on hover of element
 * @param arrow (boolean) - Show css arrow on tooltip
 * @param position (string) - Show tooltip in specified position (top, bottom, left, right)
 */

export const Tooltip: FunctionComponent<ITooltipComponent> = ({
    children = null,
    arrow = false,
    isEnabled = true,
    position = 'bottom',
    ...tippyProps
}) => {
    const props = {
        arrow,
        position,
        ...tippyProps,
    }

    if (props.disabled || !isEnabled) {
        return children
    }

    if (!props.title && !props.html) {
        return children
    }

    return <Tippy {...props}>{children}</Tippy>
}
