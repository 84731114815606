import React from 'react'
import Icon from 'blocks.simple/icon/icon'
import { cn } from 'ethcss'
import styles, { iconSize } from './app__header.jcss'
import { appActions } from '../app.state'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { colors } from '../../../theme'

const AppHeader = (p_: any) => {
    const dispatch = useDispatch()
    const head: any = useSelector<RootStateOrAny>((state) => state.head)
    const app: any = useSelector<RootStateOrAny>((state) => state.app)
    const isToolbarExist: boolean = app.isToolbarExist

    const getTitle = () => head && head!.title && head!.title.length && head!.title[0]

    return (
        <div className={styles.mobileMenu}>
            <div
                key={'menuToggle'}
                className={cn(styles.toggle, styles.menuToggle)}
                onClick={function () {
                    dispatch(appActions.toggleMobMenu(!p_.showMobMenu))
                }}
            >
                <Icon type="burger" size={iconSize} color={colors.white} />
            </div>
            <div className={styles.name}>{getTitle()}</div>
            <div
                key={'toolbarToggle'}
                className={cn(styles.toggle, styles.toolbarToggle, !isToolbarExist ? styles.disabled : '')}
                onClick={function () {
                    if (isToolbarExist) {
                        dispatch(appActions.toggleMobToolbar(!p_.showMobToolbar))
                    }
                }}
            >
                <Icon type={'gear'} size={iconSize} color={colors.white} />
            </div>
        </div>
    )
}

export default AppHeader
