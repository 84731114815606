import React from 'react'
import styles from './categoryAdd.jcss'
import EditText from '../../../blocks.simple/editText/editText'
import translate from '../../../core/translate'
import Button from '../../../blocks.simple/button/button'

export const CategoryAdd = ({ category, onChange, onClose, onSave }: any) => {
    return category ? (
        <div className={styles.categoryAdd}>
            <div className={styles.inner}>
                <div className={styles.field}>
                    <EditText
                        value={category.title}
                        onChange={(value: string) => {
                            onChange('title', value)
                        }}
                        containerClass={styles.separator}
                        className={styles.input}
                        label={translate('name')}
                    />
                </div>
                <div className={styles.controls}>
                    <Button mod={'withBorder'} className={styles.button} onClick={onClose}>
                        {translate('cancel')}
                    </Button>
                    <Button mod="fill" className={styles.button} onClick={onSave} disabled={!category.title}>
                        {translate(category.id ? 'save' : 'create')}
                    </Button>
                </div>
            </div>
        </div>
    ) : null
}
