import React, { FC, useState } from 'react'
import { api } from 'core/api/ConnectionManager'
import validator from 'validator'
import { routes } from 'features/routes'
import { changeLocation } from 'features/routes'
import { emitError } from '../../../features/appNotifications/AppNotifications.state'
import { useDispatch } from 'react-redux'

interface Props {
    onLoading: (isLoading: boolean) => void
}

export interface IState {
    email: string
    error: string | null
    success: string | null
    recoverySuccess: boolean | null
    errorEmail: string | null
    password: string
    passwordRepeat: string
    errorPassword: string | null
    errorRepeatPassword: string | null
}

const AuthorizationForgetMethods = (p_: Props) => {
    const [timer, setTimer] = useState<{ min: number; sec: number } | null>(null)
    const [state, setState] = useState<IState>({
        email: '',
        error: null,
        success: null,
        recoverySuccess: null,
        errorEmail: null,
        password: '',
        passwordRepeat: '',
        errorPassword: null,
        errorRepeatPassword: null,
    })
    const dispatch = useDispatch()

    const clearTimer = () => {
        setTimer(null)
        setState((prevState) => {
            return {
                ...prevState,
                error: null,
            }
        })
    }

    const recovery = () => {
        const { email } = state
        const { onLoading } = p_

        if (!email) {
            setState((prevState) => {
                return { ...prevState, errorEmail: 'emptyFieldsError' }
            })
        } else if (!validator.isEmail(email)) {
            setState((prevState) => {
                return { ...prevState, errorEmail: 'emailFormatError' }
            })
        } else {
            onLoading(true)

            api.post('recovery', { email }).end((err, res) => {
                onLoading(false)
                if (err) {
                    if (res.body.error === 84 && res.body.data && res.body.data.blockedFor) {
                        setTimer({
                            min: res.body.data.blockedFor.min,
                            sec: res.body.data.blockedFor.sec,
                        })
                        dispatch(emitError(res.body.error))
                    }
                    setState((prevState) => {
                        return { ...prevState, error: 'recoveryError', email: '' }
                    })
                } else {
                    setState((prevState) => {
                        return { ...prevState, success: 'recoverySuccess', email: '' }
                    })
                }
            })
        }
    }

    const setNewPassword = (action: { email: string; hash: string }) => {
        const { password, passwordRepeat } = state
        const { onLoading } = p_

        if (!password && !passwordRepeat) {
            !password &&
                setState((prevState) => {
                    return { ...prevState, errorPassword: 'emptyFieldsError' }
                })
            !passwordRepeat &&
                setState((prevState) => {
                    return { ...prevState, errorRepeatPassword: 'emptyFieldsError' }
                })
        } else if (password !== passwordRepeat) {
            setState((prevState) => {
                return { ...prevState, errorRepeatPassword: 'repeatPasswordError' }
            })
        } else {
            onLoading(true)

            api.post('setNewPassword', {
                email: action.email,
                hash: action.hash,
                password,
            }).then(({ body }) => {
                onLoading(false)
                if (body.error) {
                    setState((prevState) => {
                        return { ...prevState, error: 'recoveryError' }
                    })
                } else {
                    setState((prevState) => {
                        return { ...prevState, recoverySuccess: true }
                    })
                    setTimeout(() => {
                        changeLocation(`/${routes.login.path}`)
                    }, 3000)
                }
            })
        }
    }

    return {
        setNewPassword,
        recovery,
        state,
        setState,
        timer,
        clearTimer,
    }
}

export default AuthorizationForgetMethods
