import React from 'react'
import styles from './loader_block.jcss'
import LoaderItem from '../__item/loader__item'
import { cn } from 'ethcss'

export default (p_: { className?: string }) => (
    <div className={cn(styles.wrapper, p_.className)}>
        <LoaderItem small={true} />
    </div>
)
