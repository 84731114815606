import React from 'react'
import moment from 'moment-with-locales-es6'
import HistoryMethods, { IHistoryItem, IInstance } from './history.local.methods'
import HistoryHeader from './__header/history__header'
import Content from 'blocks.app/content/content'
import LoaderLazy from 'blocks.app/loader/_lazy/loader__lazy'
import User from 'blocks.app/user/user'
import InfiniteScroll from 'react-infinite-scroller'
import Icon from 'blocks.simple/icon/icon'
import translate from 'core/translate'
import helpers from 'core/helpers'
import { cn } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import { text as textStyles, grid } from 'theme'
import { Typography } from 'atoms/Typography'
import { styles } from './history-styles'
import store from '../../core/store'
import { checkAvailableModule } from '../../core/helpers/routes/routes'
import { themeFromStore } from 'core/helpers/menu'

const renderEntries = (entry: IHistoryItem) =>
    entry.context.instances
        .map((instance: IInstance) => {
            if (!instance) {
                return null
            }

            if (instance && instance.broadcast) {
                let endDate = null
                const startDate = helpers.getLocalDate({ date: instance.startDate, time: instance.startTime })
                const formatStartDate = startDate ? startDate.format('YYYY/MM/DD HH:mm:ss') : null
                let formatEndDate = null

                if (instance.endDate) {
                    endDate = helpers.getLocalDate({ date: instance.endDate, time: instance.endTime })
                    formatEndDate = endDate ? endDate.format('YYYY/MM/DD HH:mm:ss') : null
                } else {
                    formatEndDate = translate('forever')
                }
                return `(${formatStartDate} - ${formatEndDate}) ${instance.broadcast.title}`
            }

            if (instance.label) {
                return instance.label
            }

            if (instance.name) {
                return instance.name
            }

            return instance.title
        })
        .join(', ')

const History = (p_: any) => {
    const { historyList, total, isLoading, _parsedQuery, getHistory } = HistoryMethods(p_)

    const getUserLocale = () => {
        const user = store.getState().user
        const settings: any = user.data?.settings

        return settings.locale
    }

    const lightThemeCheck = () => {
        return themeFromStore() === 'light'
    }

    return (
        <Content title={translate('actionsHistory')} wrapperClassName={styles.historyWrapper}>
            <HistoryHeader location={p_.location} query={_parsedQuery} />
            {!!historyList.length && (
                <InfiniteScroll
                    loadMore={() => getHistory(true)}
                    hasMore={historyList.length < total}
                    initialLoad={false}
                    loader={<LoaderLazy key="history__loader" active={isLoading && historyList.length > 0} />}
                    threshold={10}
                    useWindow={true}
                >
                    {historyList.map((entry: IHistoryItem, index: number) => (
                        <div className={cn(grid.row, grid.mb_md, grid.justify)} key={`history_entry_${index}`}>
                            <div
                                className={lightThemeCheck() ? styles.history__info_light_theme : styles.history__info}
                            >
                                <div className={cn(grid.pb_md, grid.row, grid.justify)}>
                                    <div className={grid.w50}>
                                        <Typography type="text">
                                            {moment(entry.createdAt).format('HH:mm:ss')},
                                        </Typography>
                                        <Typography type="title">
                                            {helpers.isToday(entry.createdAt)
                                                ? translate('today')
                                                : moment(entry.createdAt).locale(getUserLocale()).format('DD MMMM')}
                                        </Typography>
                                    </div>
                                    <User photo={entry.user.photo} minimize={true} padding={'p_none'} />
                                </div>
                                <div>
                                    <Typography className={cn(textStyles.up, item.ellipsis)} type="title">
                                        {entry.user.roles && entry.user.roles.length
                                            ? helpers.getTranslatedIfRole(entry.user.roles[0])
                                            : ''}
                                    </Typography>
                                    <Typography className={cn(grid.mt_micro, item.ellipsis)} type="title">
                                        {entry.user.email}
                                    </Typography>
                                </div>
                            </div>
                            <div
                                className={
                                    lightThemeCheck() ? styles.history__content_light_theme : styles.history__content
                                }
                            >
                                <Typography className={cn(textStyles.up)} type="title">
                                    {translate(`${entry.action}_${entry.object}`)}
                                </Typography>
                                <Typography className={cn(grid.mt_mini)} type="title">
                                    {renderEntries(entry)}
                                </Typography>
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
            )}
            {!isLoading && !historyList.length && (
                <div className={cn(grid.col, grid.space, grid.colCenter)}>
                    <Icon type={'vision'} size={40} />
                    <div className={cn(grid.mt_mdPlus, grid.mb_md, textStyles.up)}>{translate('noNewEvents')}</div>
                    <div
                        className={textStyles.center}
                        style={{
                            lineHeight: 1.5,
                        }}
                    >
                        <Typography type="text" className={cn(textStyles.bold, textStyles.mini)}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: translate('noEventsDescription'),
                                }}
                            />
                        </Typography>
                    </div>
                </div>
            )}
        </Content>
    )
}

const exportHistory: any = checkAvailableModule('history') ? History : null

export default exportHistory
