import React, { FunctionComponent } from 'react'
import ReactDOM from 'react-dom'
import { connect, useDispatch } from 'react-redux'
import { IVideoChatModalComponent, IVideoChatModalConnected, IVideoChatModalState } from './VideoChatModal-types'
import { styles } from './VideoChatModal-styles'
import Draggable from 'blocks.simple/draggable/draggable'
import { cn } from 'ethcss'
import { usePreparePortalElement, useVideoChatModalDaD, useVideoChatModalSize } from './VideoChatModal-hooks'
import { cols } from 'theme/media'
import { isExist } from 'core/utils/coreUtil'
import { useDisableWindowScroll } from 'core/hooks'
import { VideoChatModalConnection } from './VideoChatModalConnection'
import { videochatActions } from 'pages/videoChat/videoChat.state'
import { IRootState } from 'core/store/rootReducer'
import { TConnectionStatus } from 'core/models/Connection'

const MIN_SIZE_MODAL_WIDTH = 284
const MIN_SIZE_MODAL_HEIGHT = 166
const MIN_SIZE_MODAL_OFFSET = 22

const VideoChatModalComponent: FunctionComponent<IVideoChatModalConnected> = ({
    videoModalState,
    digitalSignage,
    videoCallState,
    connectionStatus,
}) => {
    const dispatch = useDispatch()

    const MAX_SIZE = {
        width: 1 * ((100 - cols.normal) / 100),
        height: 1,
    }

    const MAX_INIT_POSITION = {
        left: cols.normal / 100,
        top: 0,
    }

    const MIN_SIZE = {
        width: MIN_SIZE_MODAL_WIDTH / window.innerWidth,
        height: MIN_SIZE_MODAL_HEIGHT / window.innerHeight,
    }

    const MIN_INIT_POSITION = {
        left: (window.innerWidth - MIN_SIZE_MODAL_WIDTH - MIN_SIZE_MODAL_OFFSET) / window.innerWidth,
        top: (window.innerHeight - MIN_SIZE_MODAL_HEIGHT - MIN_SIZE_MODAL_OFFSET) / window.innerHeight,
    }

    let isMinimized = videoModalState === 'minimized'
    const isOpened = videoModalState === 'opened'
    const isActive = (isOpened || isMinimized) && isExist(digitalSignage)
    const isDraggable = isMinimized

    const currentTop = isMinimized ? MIN_INIT_POSITION.top : MAX_INIT_POSITION.top
    const currentLeft = isMinimized ? MIN_INIT_POSITION.left : MAX_INIT_POSITION.left
    const { setPosition, position } = useVideoChatModalDaD(currentTop, currentLeft)
    const { size } = useVideoChatModalSize(isMinimized, MIN_SIZE, MAX_SIZE)
    const { portalParentElement } = usePreparePortalElement(isActive)
    useDisableWindowScroll(isOpened, 'videoChatModal')

    const handleSetPosition = ({ left, top }: { left: number; top: number }) => {
        if (!isDraggable) return false

        setPosition({ left, top })
        return true
    }

    const handleMinimize = () => {
        dispatch(videochatActions.minimizeVideoModal())
    }

    const handleExpand = () => {
        dispatch(videochatActions.expandVideoModal())
    }

    return portalParentElement && isActive
        ? ReactDOM.createPortal(
              <Draggable
                  key={'videoChat_modal'}
                  parentRect={{
                      width: window.innerWidth,
                      height: window.innerHeight,
                      top: currentTop,
                      left: currentLeft,
                  }}
                  relativeOnBody={true}
                  className={cn(styles.videoChatModal)}
                  width={size.width}
                  height={size.height}
                  left={position.left}
                  top={position.top}
                  borderColor={'blackLight'}
                  selectedBorderColor={'blackLight'}
                  onDragEnd={handleSetPosition}
                  isResizable={false}
                  isDraggable={false}
                  isFixed={true}
              >
                  <div className={styles.videoChatModal__body}>
                      <VideoChatModalConnection
                          isMinimized={isMinimized}
                          handleExpand={handleExpand}
                          handleMinimize={handleMinimize}
                          videoCallState={videoCallState}
                          digitalSignage={digitalSignage}
                          connectionStatus={connectionStatus as TConnectionStatus}
                      />
                  </div>
              </Draggable>,
              portalParentElement
          )
        : null
}

const mapStateToProps = (state: IRootState): IVideoChatModalState => ({
    videoModalState: state.videoChat.videoModalState,
    digitalSignage: state.videoChat.digitalSignage,
    videoCallState: state.videoChat.videoCallState,
    connectionStatus: state.app.connectionStatus,
})

export const VideoChatModal = connect<IVideoChatModalState, null, IVideoChatModalComponent, IRootState>(
    mapStateToProps
)(VideoChatModalComponent)
