import { addStyles } from 'ethcss'
import { colors, indents, IStyles, mediaCSS } from 'theme'
import { inspect } from 'util'

const styles: IStyles = {
    wrapper: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 2000,
        backgroundColor: 'rgba(0,0,0, 0.2)',
    },
    uploadModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: colors.white,
        borderRadius: 20,
        padding: 30,
        width: 450,

        [mediaCSS.mob]: {
            padding: '20px 10px',
            width: '96%',
            borderRadius: 10,
        },
    },
    caption: {
        display: 'flex',
        fontSize: 18,
        color: colors.grey,
        marginBottom: 30,

        a: {
            marginLeft: 7,
        },
    },
    row: {
        marginBottom: 20,
    },
    label: {
        color: colors.grey,
    },
    select: {
        width: '100%',
        border: `1px solid ${colors.greyLight}`,
        borderRadius: 5,
        padding: '10px 7px',
        outline: 'none',
        marginTop: 7,
    },
    uploadRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    firmwareInput: {
        width: '96% !important',
    },
    firmwareButton: {
        display: 'flex',
        justifyContent: 'center',
        width: '35%',
        height: 40,

        [mediaCSS.mob]: {
            width: '40%',
        },
    },
    modelsInput: {
        marginTop: 7,
    },
    modelTip: {
        marginTop: 7,
    },
    btnGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 30,
    },
    modalBtn: {
        height: 40,
        padding: '0 44px',

        [mediaCSS.mob]: {
            padding: '0 30px',
        },
    },
    loaderWrapper: {
        textAlign: 'center',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 30,
    },
}

addStyles(styles)

export default styles
