import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { mediaCSS } from 'theme/media'
import { IStyles } from '../../../theme'

const catalogTilesStyles: IStyles = {
    wrapper: {
        ...grid.row,
        ...grid.mt_md,
        marginLeft: -grid.ml_md['margin-left'],
        marginBottom: -grid.mb_md['margin-bottom'],
        overflow: 'hidden',
    },
    wrapper_x: {
        ...grid.row,
        marginTop: '35px',
        marginLeft: -grid.ml_md['margin-left'],
        marginBottom: -grid.mb_md['margin-bottom'],
        overflow: 'hidden',
    },
    cellWrapper: {
        ...grid.pb_md,
        ...grid.pl_md,
        ...item.transitionErase,
        position: 'relative',
    },
    mediaCellClassName: {
        [mediaCSS.tabMini]: {
            ...grid.col3,
        },
        [mediaCSS.mob]: {
            ...grid.col1,
        },
    },
}

addStyles(catalogTilesStyles)
export default catalogTilesStyles
