import { addStyles } from 'ethcss'
import { grid, IStyles, item, mediaCSS } from 'theme'

export const styles: IStyles = {
    TileList: {
        ...grid.row,
        ...grid.mt_md,
        marginLeft: -grid.ml_md['margin-left'],
        marginBottom: -grid.mb_md['margin-bottom'],
        overflow: 'hidden',

        [mediaCSS.tab]: {
            marginTop: 50,
        },
    },
    TileList_x: {
        marginLeft: '-21px',

        [mediaCSS.tab]: {
            marginTop: 50,
        },
    },
    TileList_type_minimize: {
        ...grid.row,
        ...grid.mt_md,
        marginLeft: -grid.ml_md['margin-left'],
        marginBottom: -grid.mb_md['margin-bottom'],
        overflow: 'hidden',

        [mediaCSS.tab]: {
            marginTop: 50,
        },
    },
}

addStyles(styles)
