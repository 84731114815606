import moment from 'moment'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import styles from './catalog__template_tasks.jcss'

export default {
    getMethod: 'getDsUpdateTasks',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noTasks'),
    emptyIcon: 'not_allow',
    settingsPrefixes: 'tasks',
    listWrapperClassName: styles.list,
    fields: () => [
        { id: 'digitalSignage', name: translate('name') },
        { id: 'date', name: translate('date_update') },
        { id: 'status', name: translate('status') },
        { id: 'message', name: translate('message') },
    ],
    getItemModel: (dataItem, getSelected) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    isEqual: (firstItem, secondItem) => firstItem.id === secondItem.id,
    onCreateListeners: [(cb, clearList) => api.addObserver('dsUpdateTaskCreated', cb, clearList)],
    onUpdateListeners: [(cb, clearList) => api.addObserver('dsUpdateTaskUpdated', cb, clearList)],
    onDeleteListeners: [(cb, clearList) => api.addObserver('dsUpdateTaskDeleted', cb, clearList)],
    init(callback) {
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    tableWrapper: styles.tableWrapper,
    list: {
        digitalSignage: ({ digitalSignage }) => digitalSignage.name,
        date: ({ date }) => moment(date).format('YYYY-MM-DD HH:mm:ss'),
        status: ({ status }) => translate(`task_${status}`),
        message: ({ message }) => message,
    },
}
