import React from 'react'
import styles from './dashboard__sessions__item.jcss'
import translate from 'core/translate'
import { Typography } from 'atoms/Typography'
import { Session } from '../dashdoard__sessions'
import { themeFromStore } from 'core/helpers/menu'

interface ISession {
    session: Session
}

const lightThemeCheck = () => {
    return themeFromStore() === 'light'
}

export const DashboardSessionsItem = ({ session }: ISession) => {
    return (
        <div className={lightThemeCheck() ? styles.item : styles.itemForThemes}>
            <Typography type="title" className={styles.line}>
                <span className={styles.title}>IP</span>: {session.ipAddress}
            </Typography>
            <Typography type="title" className={styles.line}>
                <span className={styles.title}>{translate('browserTabs')}</span>: {session.count}
            </Typography>
            <Typography type="title" className={styles.line}>
                <span className={styles.title}>UserAgent</span>: ${session.userAgent}
            </Typography>
        </div>
    )
}
