import React from 'react'
import Icon from 'blocks.simple/icon/icon'
import { iconList } from 'blocks.simple/icon/__list/icon__list'
import grid from 'blocks.simple/grid/grid.jcss'
import { text as textStyles } from 'theme'
import { cn } from 'ethcss'
import styles from 'pages/ui/ui.jcss'

class UiIcons extends React.Component {
    render() {
        return (
            <div className={styles.blockPanel}>
                <div className={styles.title}>Иконки</div>
                <div className={grid.row}>
                    {(() => {
                        let icons = []

                        for (let item in iconList) {
                            if (iconList.hasOwnProperty(item)) {
                                icons.push(
                                    <div key={`iconList_${item}`} className={grid.p_mini}>
                                        <div className={cn(grid.rowCenter, grid.mb_mini)}>
                                            <Icon type={item} size={30} />
                                        </div>
                                        <div className={cn(textStyles.center, textStyles.mini)}>{item}</div>
                                    </div>
                                )
                            }
                        }
                        return icons
                    })()}
                </div>
            </div>
        )
    }
}

export default UiIcons
