import React from 'react'
import Icon from 'blocks.simple/icon/icon'
import { cn } from 'ethcss'
import styles from './card.jcss'
import { item } from '../../theme'
import { Typography } from 'atoms/Typography'

const Card = ({
    icon,
    title,
    text,
    children,
    className,
    titleClassName = {},
}: {
    icon: {
        type: string
        size: string | number
        default: boolean
    }
    title: string
    text?: string
    children: React.ReactNode
    className?: {}
    titleClassName?: {}
}) => {
    return (
        <div className={cn(styles.panel, className)}>
            <Typography type="title" className={cn(styles.title, titleClassName)}>
                {icon && <Icon containerClass={styles.icon} {...icon} />}
                <div className={item.ellipsisWrapper}>
                    <div className={item.ellipsis}>{title}</div>
                </div>
            </Typography>
            <div className={styles.body}>{children}</div>
            {text && (
                <Typography type="text" className={styles.footer}>
                    {text}
                </Typography>
            )}
        </div>
    )
}

export default Card
