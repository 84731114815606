import { defaultThemeTableStyles } from 'atoms/Table/Table-theme'
import { addStyles } from 'ethcss'
import { grid } from 'theme'
import { IStyles } from 'theme'

export const styles: IStyles = {
    ReferenceBookListItem__checkbox: {
        ...grid.w100,
        ...grid.rowCenter,
    },
    ReferenceBookListItem__selected: {
        backgroundColor: `var(--var-module-ui-component-table-style-selected, ${defaultThemeTableStyles['selected']})`,
        color: `var(--var-module-ui-component-table-style-selected-text, ${defaultThemeTableStyles['selected-text']})`,
    },
}

addStyles(styles)
