import { addStyles } from 'ethcss'
import { defaultThemeStyles } from './Radio.theme'
import { colors, grid, item, IStyles } from 'theme'

const animation = 'ripple-checkbox 0.6s ease-out'

export const styles: IStyles = {
    Radio__wrapper: {
        ...grid.w100,
        ...grid.row,
        ...grid.normalCenter,
        cursor: 'pointer',
    },
    Radio: {
        position: 'relative',
        borderRadius: '50%',
        ...grid.rowCenter,
        ...grid.p_nano,
        width: 20,
        height: 20,
        backgroundColor: 'transparent',
        '&::before': {
            content: '""',
            position: 'absolute',
            borderRadius: '50%',
            ...grid.row,
            ...grid.center,
            ...grid.normalCenter,
            width: 20,
            height: 20,
            opacity: 0,
        },
    },
    Radio_type_default: {
        border: `1px solid var(--var-module-ui-component-radio-style-border, ${defaultThemeStyles.border})`,
    },
    Radio_type_white: {
        border: `1px solid ${colors.white}`,
    },
    Radio_activated: {
        '&::before': {
            animation,
        },
    },
    Radio_type_white_activated: {
        '&::before': {
            backgroundColor: colors.white,
        },
        content: 'inherit',
    },
    Radio_type_default_activated: {
        borderColor: `var(--var-module-ui-component-radio-style-active-border, ${defaultThemeStyles.background})`,
        '&::before': {
            backgroundColor: `var(--var-module-ui-component-radio-style-background, ${defaultThemeStyles.background})`,
        },
        content: 'inherit',
    },
    Radio__inner: {
        ...item.circle,
        ...grid.full,
    },
    Radio__inner_type_white_selected: {
        backgroundColor: colors.white,
    },
    Radio__inner_type_default_selected: {
        backgroundColor: `var(--var-module-ui-component-radio-style-background, ${defaultThemeStyles.background})`,
    },
    Radio__label: {
        ...grid.space,
        ...grid.pl_md,
    },
    Radio__label_type_white: {
        color: colors.white,
    },
    Radio__label_type_default: {
        color: `var(--var-module-ui-component-radio-style-textMain, ${defaultThemeStyles.textMain})`,
    },
}

addStyles(styles)
