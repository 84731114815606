import React from 'react'
import { connect, RootStateOrAny } from 'react-redux'
import CustomListMethods from './customList.local.methods'
import CustomListContent from './__content/customList__content'
import CatalogSelectContent from 'blocks.app/catalog/_select/_content/catalog_select_content'
import Icon from 'blocks.simple/icon/icon'
import Button from 'blocks.simple/button/button'
import { cn } from 'ethcss'
import { text as textStyles } from 'theme'
import grid from 'blocks.simple/grid/grid.jcss'
import translate from 'core/translate'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { updateUserSettings } from 'blocks.app/user/user.state'

class CustomList extends CustomListMethods {
    static defaultProps = {
        disableMultiSelect: false,
        searchbarMod: 'withShadow',
        showChooseWebApp: true,
        showUpdateArchive: true,
        onGetData: () => {},
        selectedArchive: {},
    }

    render() {
        const s_ = this.state
        const p_ = this.props
        const HeaderFilter = this.template.headerFilters
        const source = this.getSource()
        const updateButtonMod = 'withBorder'
        return (
            <div className={cn(grid.col, grid.space)}>
                <div className={cn(grid.row, grid.justify, grid.ml_md, grid.mr_md)}>
                    <div className={grid.row}>
                        {p_.showChooseWebApp && (
                            <CatalogSelectContent
                                type={'archive'}
                                selectedInfo={source}
                                onSelect={(selectedArchive: any) => {
                                    this.onChooseWebApp(selectedArchive, p_.type)
                                }}
                            />
                        )}
                    </div>
                    <div className={grid.row}>
                        {p_.showUpdateArchive && (
                            <Button
                                mod={updateButtonMod}
                                onClick={this.updateWebApp}
                                disabled={s_.webAppStatus === 'building' || s_.webAppStatus === 'actual' || !source}
                                preloader={s_.webAppStatus === 'building' && !!source}
                                isDisableLayer={s_.webAppStatus !== 'building'}
                            >
                                {s_.webAppStatus === 'building'
                                    ? translate('archiveUpdating')
                                    : translate('updateArchive')}
                            </Button>
                        )}
                    </div>
                </div>
                {HeaderFilter && (
                    <HeaderFilter
                        selectedItems={p_.selectedItems}
                        fakeLine={s_.fakeLine}
                        source={source}
                        toggleControls={p_.toggleControls}
                        onAddFakeLine={(fakeLine: string) => {
                            this.setState({ fakeLine })
                        }}
                        onAnimate={(animate: boolean) => this.setState({ animate })}
                        type={this.template.type}
                        isDelayTime={p_.isDelayTime}
                        isWebAppTitle={p_.isWebAppTitle}
                        delayTime={s_.delayTime}
                        webAppTitle={s_.title}
                        onChangeDelayTime={(delayTime: number) => {
                            this.setState({ delayTime: delayTime * 1000 })
                        }}
                        onBlurDelayTime={(delayTime: number) => {
                            this.updateCatalog({ delayTime })
                        }}
                        onChangeCustomField={(value: string, type: string) => {
                            this.setState({ [type]: value })
                        }}
                        onBlurCustomField={(value: string, type: string) => {
                            this.updateCatalog({ [type]: +value })
                        }}
                    />
                )}
                {!s_.list.length && !s_.fakeLine && !this.fullLoading ? (
                    <div className={cn(grid.space, grid.colCenter)}>
                        <div className={cn(grid.colCenter)}>
                            <div
                                className={cn(
                                    textStyles.normal,
                                    grid.row,
                                    grid.center,
                                    grid.mb_md,
                                    textStyles.darkCloud
                                )}
                            >
                                {this.template.emptyIcon && <Icon type={this.template.emptyIcon} size={60} />}
                                <div className={cn(grid.fullWidth, grid.pt_mini, textStyles.center)}>
                                    {this.template.emptyDescription && (
                                        <div className={cn(grid.pt_mini, grid.rowCenter)}>
                                            <div className={grid.w50}>{this.template.emptyDescription()}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <CustomListContent
                        loading={this.fullLoading}
                        options={s_.fields}
                        list={s_.list}
                        catalogId={s_.catalogId}
                        type={this.template.type}
                        multipleSelect={!!p_.onSelect}
                        isEqual={this.isEqual}
                        onSelect={this.onSelect}
                        onSortEnd={this.onSortEnd}
                        template={this.template}
                        fakeLine={s_.fakeLine}
                        source={source}
                        animate={s_.animate}
                        onAddFakeLine={(fakeLine: string) => {
                            this.setState({ fakeLine })
                        }}
                        additionalData={p_.additionalData}
                    />
                )}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        emitError: (err: string) => dispatch(emitError(err)),
        updateUserSettings: (userSettings: any) => dispatch(updateUserSettings(userSettings)),
    }
}

export default connect(
    (state: RootStateOrAny) => ({
        settings: state.user.data.settings,
    }),
    mapDispatchToProps
)(CustomList)
