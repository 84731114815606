import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../../theme'
import { defaultThemeStyles } from './FileToolbarUploads-theme'

const filesToolbarUploadsStyles: IStyles = {
    file() {
        return {
            ...grid.rowCenter,
            ...grid.p_md,
            '& .file__progress': {
                display: 'block',
            },
            '&:hover': {
                backgroundColor: `var(--var-module-sidebar-component-toolbarUpload-style-background, ${defaultThemeStyles.background})`,
                color: `var(--var-module-sidebar-component-toolbarUpload-style-text, ${defaultThemeStyles.text})`,
                [`& .${this.cancel}`]: { display: 'block' },
                [`& .${this.progress}`]: { ...item.none },
            },
        }
    },
    progress: {},
    cancel: {
        display: 'none',
    },
    fileNameWrapper: {
        ...item.ellipsisWrapper,
    },
    fileName: {
        ...item.ellipsis,
    },
}

addStyles(filesToolbarUploadsStyles)
export default filesToolbarUploadsStyles
