import React, { useState, useEffect, FC, useContext } from 'react'
import PropTypes from 'prop-types'
import Sidebar from 'blocks.app/sidebar/sidebar'
import { ScoreboardsToolbarContent } from './ScoreboardToolbarContent'
import ModalAccept from 'blocks.simple/modal/_accept/modal_accept'
import EditText from 'blocks.simple/editText/editText'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import translate from 'core/translate'
import { api } from 'core/api/ConnectionManager'
import { useModal } from 'core/hooks'
import helpers from 'core/helpers'
import { styles } from './ScoreboardsToolbar-styles'
import {
    ICatalogItem,
    ICatalogWithPaginationToolbarProps,
} from 'organisms/CatalogWithPagination/CatalogWithPagination-types'
import { CatalogWithPaginationContext } from 'organisms/CatalogWithPagination'
import { isExist, isNotEmptyArray } from 'core/utils/coreUtil'
import {
    IEditingModalContent,
    IEditingModalContentItem,
    IScoreboardCatalogItem,
    IScoreboardDevice,
} from './ScoreboardsToolbar-types'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { useDispatch } from 'react-redux'

const EditingModalContentItem: FC<IEditingModalContentItem> = ({ device, onChange }) => {
    const dispatch = useDispatch()
    const { labelId, value, empty, label } = device

    const handleChange = (value: string) => {
        onChange({
            ...device,
            labelId: labelId,
            value,
        })
    }

    const onChecked = (checked: boolean) => {
        onChange({
            ...device,
            labelId: labelId,
            value: '',
            empty: checked,
        })
    }

    const onEndTyping = (value: string) => {
        const regex = /^\d{1,2}(\.\d{1,2})?$/g

        if (value && !value.match(regex)) {
            dispatch(emitError('scoreboardLabelFormatError'))
        }
    }

    return (
        <div className={styles.editingModalContentItem}>
            <div className={styles.editingModalContentItemLabel}>{label.name}</div>
            <EditText value={value} onChange={handleChange} onEndTyping={onEndTyping} type="number" disabled={empty} />
            <Checkbox className={styles.checkbox} label={translate('emptyLabel')} checked={empty} onClick={onChecked} />
        </div>
    )
}

const EditingModalContent: FC<IEditingModalContent> = ({ isOpen, devices, onChange }) => {
    if (!isOpen) {
        return null
    }

    const handleChange = (device: IScoreboardDevice) => {
        const updatedDevices = devices.map((deviceItem) => {
            if (deviceItem.labelId === device.labelId) {
                return {
                    ...deviceItem,
                    value: device.value,
                    empty: device.empty,
                }
            }

            return deviceItem
        })

        onChange(updatedDevices)
    }

    return (
        <div className={styles.editingModalWrapper}>
            <div className={styles.editingModalTitle}>{translate('editingLabels')}</div>
            {devices.map((device) => (
                <EditingModalContentItem key={device.labelId} onChange={handleChange} device={device} />
            ))}
        </div>
    )
}

const ScoreboardsToolbarBottomContent = () => {
    const dispatch = useDispatch()
    const { catalogState, catalogMethods, methods } = useContext(CatalogWithPaginationContext)
    const { selectedItems, fullCatalogItemList } = catalogState
    const { onResetSelectedItems } = catalogMethods
    const { isFolder } = methods

    const modal = useModal()
    const [devicesState, setDevicesState] = useState<IScoreboardDevice[]>([])

    const getSelectedItemList = () => {
        return (fullCatalogItemList as IScoreboardCatalogItem[])
            .filter(({ id }) => isExist(selectedItems.find((selectedId) => selectedId === id)))
            .filter((item) => !isFolder(item))
    }

    useEffect(() => {
        let devices: IScoreboardDevice[] = []
        const selectedItemList = getSelectedItemList()

        selectedItemList.forEach((selectedItem) => {
            const newDevices = selectedItem.devices.map((device) => ({ ...device, value: '' }))
            devices.push(...newDevices)
        })

        devices = helpers.getUniqueArray(devices, { field: 'labelId' })
        setDevicesState(devices)
    }, [selectedItems])

    const updateScoreboards = () => {
        const selectedItemList = getSelectedItemList()
        const data: { ledControllerId: number; devices: Partial<IScoreboardDevice>[] }[] = []

        selectedItemList.forEach((selectedItem) => {
            const devices: Partial<IScoreboardDevice>[] = []

            selectedItem.devices.forEach((selectedDevice) => {
                devicesState.forEach((device) => {
                    if (device.labelId === selectedDevice.labelId) {
                        if (device.value || device.empty) {
                            devices.push({
                                labelId: device.labelId,
                                order: selectedDevice.order,
                                value: device.value,
                            })
                        }
                    }
                })

                const findingDevice = devices.find((device) => device.labelId === selectedDevice.labelId)

                if (!findingDevice) {
                    devices.push({
                        labelId: selectedDevice.labelId,
                        order: selectedDevice.order,
                        value: selectedDevice.value,
                    })
                }
            })

            data.push({
                ledControllerId: selectedItem.id,
                devices,
            })
        })

        for (let i = 0; data.length > i; i++) {
            const notValid = data[i].devices.some((device) => {
                const regex = /^\d{1,2}(\.\d{1,2})?$/g

                return device.value && !device.value.match(regex)
            })

            if (notValid) {
                dispatch(emitError('scoreboardLabelFormatError'))
                return
            }
        }

        api.send('updateScoreboard', data).then(() => {
            modal.closeModal()
        })
    }

    const handleResetSelectedItems = () => {
        onResetSelectedItems()
    }

    const handleChangeDevices = (devices: IScoreboardDevice[]) => {
        setDevicesState(devices)
    }

    return (
        <React.Fragment>
            {helpers.isAvailable({ key: 'scoreboard', action: 'update' }) && (
                <div onClick={modal.openModal} className={styles.menuItem}>
                    {translate('edit')}
                </div>
            )}

            <div onClick={handleResetSelectedItems} className={styles.menuItem}>
                {translate('deselect')}
            </div>

            <ModalAccept
                open={modal.value}
                onReject={modal.closeModal}
                onAccept={updateScoreboards}
                onClose={modal.closeModal}
                rejectLabel={translate('cancel')}
                acceptLabel={translate('save')}
            >
                <EditingModalContent isOpen={modal.value} devices={devicesState} onChange={handleChangeDevices} />
            </ModalAccept>
        </React.Fragment>
    )
}

export const ScoreboardsToolbar: FC<ICatalogWithPaginationToolbarProps> = () => {
    const [catalogItem, setCatalogItem] = useState<ICatalogItem | null>(null)
    const { catalogMethods, catalogState, methods, common } = useContext(CatalogWithPaginationContext)
    const { onSetSelectedItems, onSelectInfo } = catalogMethods
    const { selectedInfo, selectedItems, fullCatalogItemList } = catalogState
    const { isFolder } = methods
    const { parentKey } = common

    useEffect(() => {
        const fetchCatalogItem = () => {
            if (!selectedInfo) {
                setCatalogItem(null)
                return
            }

            const selectedCatalogItem = getSelectedCatalogItem(selectedInfo)

            if (!selectedCatalogItem) {
                setCatalogItem(null)
                return
            }

            const methodName = getMethodName(selectedCatalogItem)
            const methodData = getMethodData(selectedCatalogItem)

            api.send<object, ICatalogItem>(methodName, methodData, { hideLoader: true })
                .then((res) => setCatalogItem(res))
                .catch(() => {})
        }

        fetchCatalogItem()
    }, [selectedInfo])

    const getMethodName = (selectedCatalogItem: ICatalogItem) => {
        return isFolder(selectedCatalogItem) ? 'getScoreboardFolderById' : 'getScoreboardById'
    }

    const getMethodData = (selectedCatalogItem: ICatalogItem) => {
        const { id } = selectedCatalogItem

        return isFolder(selectedCatalogItem) ? { ledControllerFolderId: id } : { ledControllerId: id }
    }

    const getSelectedCatalogItem = (id: number) => fullCatalogItemList.find((item) => item.id === id)

    const handleChange = (items: ICatalogItem[]) => {
        onSetSelectedItems(items.map(({ id }) => id))
    }

    const handleSelectInfo = (item: ICatalogItem) => {
        onSelectInfo(item.id, item[parentKey] as number | null)
    }

    const getContent = () => {
        if (!isSelectedItemsExist() || isSelectedInfoExist()) {
            return null
        }

        const options = selectedItems
            .map((id) => {
                const catalogItem = fullCatalogItemList.find((item) => item.id === id)
                return catalogItem ? { ...catalogItem, id: catalogItem.id, title: catalogItem.name } : null
            })
            .filter((catalogItem) => isExist(catalogItem))

        return {
            title: translate('controllers'),
            options,
        }
    }

    const getCustomContent = () => {
        if (!selectedInfo || !catalogItem) return <div></div>

        return <ScoreboardsToolbarContent catalogItem={catalogItem} />
    }

    const isSelectedItemsExist = () => selectedItems.length > 0

    const isSelectedInfoExist = () => isExist(selectedInfo)

    const isEmpty = () => !isSelectedInfoExist() && !isSelectedItemsExist()

    const onSubmit = (side: string, action: string) => {
        if (action !== 'delete') return

        const choosenCatalogItems = fullCatalogItemList.filter(({ id }) => {
            return selectedItems.find((selectedId) => selectedId === id)
        })

        const ledControllerFolderIds = choosenCatalogItems.filter((item) => isFolder(item)).map(({ id }) => id)

        const ledControllerIds = choosenCatalogItems.filter((item) => !isFolder(item)).map(({ id }) => id)

        if (isNotEmptyArray(ledControllerIds)) {
            api.send('deleteScoreboard', { ledControllerId: ledControllerIds })
        }

        if (isNotEmptyArray(ledControllerFolderIds)) {
            api.send('deleteScoreboardFolder', { ledControllerFolderId: ledControllerFolderIds })
        }
    }

    return (
        <Sidebar
            selectedSide="right"
            isEmpty={{
                right: isEmpty(),
            }}
            onSelectInfo={handleSelectInfo}
            onChange={handleChange}
            emptyText={{
                right: {
                    title: translate('noControllerSelected'),
                    description: translate('noControllerSelectedDescription'),
                },
            }}
            content={{
                right: getContent(),
            }}
            customContent={{
                right: getCustomContent(),
            }}
            bottomContent={{
                right: <ScoreboardsToolbarBottomContent />,
            }}
            isShowActions
            isDeletable={helpers.isAvailable({ key: 'scoreboard', action: 'delete' })}
            onSubmit={onSubmit}
        />
    )
}
