import { addStyles } from 'ethcss'
import { IStyles } from 'theme'

export const styles: IStyles = {
    customScroll: {
        '&::-webkit-scrollbar': {
            borderRadius: 10,
            width: 6,
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            width: 6,
            background: '#b3b3b3',
        },
    },
}

export const stylesWide: IStyles = {
    customScroll: {
        '&::-webkit-scrollbar': {
            borderRadius: 10,
            width: 10,
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            width: 10,
            background: '#b3b3b3',
        },
    },
}

addStyles(stylesWide)
addStyles(styles)
