import { addStyles } from 'ethcss'
import { defaultThemeTableStyles } from 'atoms/Table/Table-theme'
import { IStyles } from '../../../../theme'

const catalogTemplateInstancesCatalogStyles: IStyles = {
    tableWrapper: {
        borderTop: `1px solid var(--var-module-ui-component-table-style-borderTop, ${defaultThemeTableStyles['borderTop']})`,

        '& thead th:first-child': {
            textAlign: 'center',
        },
        '& thead tr': {
            backgroundColor: `var(--var-module-ui-component-table-style-thead, ${defaultThemeTableStyles['thead']})`,
        },
        '& tr:nth-child(even)': {
            backgroundColor: `var(--var-module-ui-component-table-style-evenLine, ${defaultThemeTableStyles['evenLine']})`,
        },
        overflow: 'visible !important',
    },
    selectDevices: {
        color: `var(--var-module-ui-component-table-style-selected, ${defaultThemeTableStyles['selected']})`,
    },
}

addStyles(catalogTemplateInstancesCatalogStyles)
export default catalogTemplateInstancesCatalogStyles
