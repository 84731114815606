import React from 'react'
import InstancesCatalogFilter, {
    IInstancesCatalogProps,
} from '../../__filter/_instancesCatalog/catalog__filter_instancesCatalog'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import styles from './catalog__template_wine.jcss'
import { ItemData } from '../../../../core/models/Template'

export default {
    getMethod: 'getWines',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('emptyWineProducts'),
    emptyDescription: () => translate('noDescription'),
    emptyIcon: 'not_allow',
    disableDrag: true,
    addItemButton: () => null,
    settingsPrefixes: 'wine',
    sortOptions: () => [],
    headerFilters: (p_: IInstancesCatalogProps) => {
        return (
            <InstancesCatalogFilter
                onAdd={() => p_.onAddFakeLine({ title: '', digitalSignageId: [] })}
                title={translate('wineCatalog')}
                type="wine"
                upload={`v2/price-list/upload/wine-info`}
                acceptFiles={`.csv`}
                onDelete={() => {
                    const productCode = p_.selectedItems.map((selectedItem) => selectedItem.productCode)
                    if (productCode.length > 0) {
                        api.send('deleteWines', { productCode })
                    }
                    if (p_.fakeLine) {
                        p_.onAddFakeLine(null)
                    }
                }}
                {...p_}
            />
        )
    },
    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    getBackendModel: (item: ItemData) => {
        return api.send('getWines', { productCode: item.productCode }, { hideLoader: true })
    },
    tableWrapper: styles.tableWrapper,
    isEqual: (firstItem: ItemData, secondItem: ItemData) => {
        if (firstItem.productCode && secondItem.productCode && Array.isArray(secondItem.productCode)) {
            return firstItem.productCode === secondItem.productCode[0]
        }

        if (firstItem.productCode && secondItem.productCode) {
            return firstItem.productCode === secondItem.productCode
        }

        return firstItem === secondItem
    },
    onCreateListeners: [
        (callback: any, clearList: string[]) => {
            return api.addObserver(
                'winesCreated',
                (createdWines: { id: number }[]) => {
                    createdWines.forEach((item) => {
                        callback({ item })
                    })
                },
                clearList
            )
        },
    ],
    onUpdateListeners: [
        (callback: any, clearList: string[]) => {
            return api.addObserver(
                'winesUpdated',
                (updatedWines: { id: number }[]) => {
                    updatedWines.forEach((product) => {
                        callback({ ...product })
                    })
                },
                clearList
            )
        },
    ],
    onDeleteListeners: [
        (callback: any, clearList: string[]) =>
            api.addObserver(
                'winesDeleted',
                (deletedWines: { productCode: { productCode: string }[] }) => {
                    deletedWines.productCode.forEach((productCode) => {
                        callback({ productCode })
                    })
                },
                clearList
            ),
    ],
    isFolder: () => {},
    fields: () => [
        { id: 'productCode', name: translate('productCode') },
        { id: 'name', name: translate('name') },
        { id: 'country', name: translate('country') },
        { id: 'style', name: translate('wineСlassification') },
    ],
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        country: (p_: { country?: string; origin?: string }) => <div>{p_.country || p_.origin}</div>,
    },
    hideActiveRowColor: true,
    disableUpdateUserSettings: true,

    disableListScroll: true,
    fixedColWidth: true,
}
