import React, { useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { text as textStyles } from 'theme'
import Collapse from 'blocks.simple/collapse/collapse'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { grid } from '../../../theme'
import { updateUserSettings } from 'blocks.app/user/user.state'
import styles from '../settings.jcss'
import EditText from 'blocks.simple/editText/editText'
import { SwitchButton } from 'atoms/SwitchButton/SwitchButton-view'

const DownloadVideoOptions = () => {
    const dispatch = useDispatch()
    const settingsVideoConversionFps: any = useSelector<RootStateOrAny>(
        (state) => state.user.data.settings.videoConversion.fps
    )
    const settingsVideoConversionBitrate: any = useSelector<RootStateOrAny>(
        (state) => state.user.data.settings.videoConversion.bitrate
    )
    const settingsVideoConversionRemoveAudio: any = useSelector<RootStateOrAny>(
        (state) => state.user.data.settings.videoConversion.removeAudio
    )
    const settingsVideoConversionDuration: any = useSelector<RootStateOrAny>(
        (state) => state.user.data.settings.videoConversion.duration
    )

    const [stateVideoConversionFps, setVideoConversionFps] = useState<number>(settingsVideoConversionFps)
    const [stateVideoConversionBitrate, setVideoConversionBitrate] = useState<number>(settingsVideoConversionBitrate)
    const [stateVideoConversionDuration, setVideoConversionDuration] = useState<number | null>(
        settingsVideoConversionDuration
    )

    const onChangeFps = (fps: number): void => {
        dispatch(updateUserSettings({ data: { videoConversion: { fps: fps } }, isSaveSettings: true }))
    }

    const onChangeBitrate = (bitrate: number): void => {
        dispatch(updateUserSettings({ data: { videoConversion: { bitrate: bitrate } }, isSaveSettings: true }))
    }

    const onChangeDuration = (duration: number | null): void => {
        setVideoConversionDuration(duration)
        dispatch(updateUserSettings({ data: { videoConversion: { duration: duration } }, isSaveSettings: true }))
    }

    const onChangeRemoveAudio = (): void => {
        settingsVideoConversionRemoveAudio
            ? dispatch(updateUserSettings({ data: { videoConversion: { removeAudio: false } }, isSaveSettings: true }))
            : dispatch(updateUserSettings({ data: { videoConversion: { removeAudio: true } }, isSaveSettings: true }))
    }

    return (
        <Collapse title={() => translate('downloadVideoOptions')} className={cn(styles.background, grid.mb_md)}>
            <div className={cn(grid.row, grid.pt_micro)}>
                <div className={cn(grid.pb_mini)}>{translate('frameFrequency')}:</div>
                <EditText
                    tabindex={'0'}
                    className={cn(textStyles.center, styles.overflowAuto, styles.inputWidth)}
                    value={stateVideoConversionFps}
                    indentSize={'mini'}
                    type={'number'}
                    mod="withLightBorder"
                    onChange={(value: number) => setVideoConversionFps(value)}
                    onBlur={() => stateVideoConversionFps && onChangeFps(stateVideoConversionFps)}
                />

                <div className={cn(grid.pb_mini, grid.pt_md)}>{translate('bitrate')}:</div>
                <EditText
                    tabindex={'0'}
                    className={cn(textStyles.center, styles.overflowAuto, styles.inputWidth)}
                    value={stateVideoConversionBitrate}
                    indentSize={'mini'}
                    type={'number'}
                    mod="withLightBorder"
                    onChange={(value: number) => setVideoConversionBitrate(value)}
                    onBlur={() => stateVideoConversionBitrate && onChangeBitrate(stateVideoConversionBitrate)}
                />

                <div>
                    <div className={cn(grid.pb_mini, grid.pt_md, grid.row, grid.justify)}>
                        <div>{translate('videoDuration')}:</div>
                        <SwitchButton
                            onChange={() =>
                                stateVideoConversionDuration !== null ? onChangeDuration(null) : onChangeDuration(30)
                            }
                            checked={stateVideoConversionDuration ? true : false}
                        />
                    </div>
                    <EditText
                        disabled={stateVideoConversionDuration ? false : true}
                        tabindex={'0'}
                        className={cn(textStyles.center, styles.overflowAuto, styles.inputWidth)}
                        value={stateVideoConversionDuration ? stateVideoConversionDuration : ''}
                        indentSize={'mini'}
                        type={'number'}
                        mod="withLightBorder"
                        onChange={(value: number) => setVideoConversionDuration(value)}
                        onBlur={() =>
                            !stateVideoConversionDuration
                                ? onChangeDuration(null)
                                : onChangeDuration(stateVideoConversionDuration)
                        }
                    />
                    <div className={cn(grid.pb_mini, grid.pt_md, grid.row, grid.justify, styles.inputWidth)}>
                        {translate('removeAudio')}
                        <div>
                            <SwitchButton onChange={onChangeRemoveAudio} checked={settingsVideoConversionRemoveAudio} />
                        </div>
                    </div>
                </div>
            </div>
        </Collapse>
    )
}

export default DownloadVideoOptions
