import { addStyles } from 'ethcss'
import { mediaCSS } from 'blocks.app/config'
import grid from 'blocks.simple/grid/grid.jcss'
import { textSizes } from 'theme/media'
import { IStyles } from '../../../theme'

const deviceSettingsWebEngineStyles: IStyles = {
    deviceSettingsWebEngineWrapper: {
        ...grid.row,
        ...grid.w100,
        ...grid.mt_mdPlus,
    },
    deviceSettingsWebEngineTitle: {
        fontSize: textSizes.normal,
        ...grid.mb_md,
    },
    deviceSettingsWebEngineContent: {
        ...grid.row,
        ...grid.w100,
    },
    deviceSettingsTextArea: {
        ...grid.row,
        ...grid.w25,
        [mediaCSS.tab]: {
            ...grid.w45,
        },
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
}

addStyles(deviceSettingsWebEngineStyles)
export default deviceSettingsWebEngineStyles
