import { useEffect, useState } from 'react'
import moment from 'moment'
import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { IUser } from '../../core/models/Users'

const queryFields = ['action', 'object', 'startDate', 'endDate', 'userId']

export interface IInstance {
    id: number
    title: string
    duration: number
    companyId: number
    createdAt: string
    deletedAt: string | null
    updatedAt: string
    applyToNewDigitalSignage: boolean
    broadcast?: any
    label?: string
    name?: string
    endDate: string
    startDate: string
    startTime: string
    endTime: string
}

interface IParsedQuery {
    [index: string]: any
}

export interface IHistoryItem {
    context: {
        instances: IInstance[]
    }
    id: number
    companyId: number
    userId: number
    action: string
    object: string
    createdAt: string
    user: IUser
}

let history: any = []

const HistoryMethods = (p_: any) => {
    const [historyList, setHistoryList] = useState<IHistoryItem[]>([])
    const [total, setTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [_parsedQuery, setParsedQuery] = useState({})
    const listenersId: string[] = []
    let prevQuery: any = null

    useEffect(() => {
        history = historyList
    }, [historyList])

    useEffect(() => {
        api.addObserver('userActionsHistoryCreated', onUserActionsHistoryCreated, listenersId)

        return () => {
            listenersId.forEach((id: string) => api.removeObserver(id))
        }
    }, [])

    useEffect(() => {
        if (JSON.stringify(prevQuery) !== JSON.stringify(p_.location)) {
            parsedQuery(p_)
            prevQuery = p_.location
        }
    }, [p_.location])
    useEffect(() => {
        getHistory(false)
    }, [_parsedQuery])

    const onUserActionsHistoryCreated = (item: IHistoryItem) => {
        updateHistoryList(item, history)
    }
    const parsedQuery = (p_: any) => {
        const locationQuery = getURLSearchParamsByLocation(p_.location)
        const query = helpers.getQuery(locationQuery, 'history')
        let parsedQuery: IParsedQuery = {}
        queryFields.forEach((field: string) => {
            if (query[field]) {
                if (field === 'startDate' || field === 'endDate') {
                    if (moment(query[field]).isValid()) {
                        parsedQuery[field] = moment(query[field]).utc().format('YYYY-MM-DD HH:mm:ss')
                    }
                } else {
                    parsedQuery[field] = query[field].split('//')
                }
            }
        })

        setHistoryList([])
        setTotal(0)
        setParsedQuery(parsedQuery)
    }
    const updateHistoryList = (item: IHistoryItem, historyList: IHistoryItem[]) => {
        const _historyList: IHistoryItem[] = [item, ...historyList]

        setHistoryList(_historyList)
        setTotal(_historyList.length)
    }
    const getHistory = (isLazyLoad: boolean) => {
        const data = {
            offset: isLazyLoad ? historyList.length : 0,
            perPage: 20,
            ..._parsedQuery,
        }

        setIsLoading(true)

        api.send('getUsersActionsHistory', data).then((res: any) => {
            let _historyList: IHistoryItem[] = [...res.data]

            if (isLazyLoad) {
                _historyList = [...historyList, ...res.data]
            }

            setHistoryList(_historyList)
            setTotal(res.total)
            setIsLoading(false)
        })
    }

    return {
        historyList,
        total,
        isLoading,
        _parsedQuery,
        getHistory,
    }
}

export default HistoryMethods
