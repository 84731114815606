import { addStyles } from 'ethcss'
import { colors, grid, IStyles, item, mediaCSS } from '../../../theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { textSizes } from 'theme/media'

const advertisingCampaignsAddStyles: IStyles = {
    wrapper: {
        ...grid.space,
        ...grid.col,
    },
    settings: {
        ...grid.space,
        ...grid.col,
        ...item.white,
    },
    inner: {
        ...grid.space,
        ...grid.col,
    },
    none: {
        display: 'none !important',
    },
    more: {
        ...grid.row,
        ...item.pointer,
        ...item.blackColor,
        ...grid.normalCenter,
        ...grid.p_md,
        textTransform: 'lowercase',
    },
    input: {
        ...grid.pb_md,
        ...grid.pt_md,
    },
    inputWrapper: {},
    title: {
        ...grid.pb_mini,
        ...grid.pt_mini,
        textAlign: 'center',
        fontSize: textSizes.normalPlus,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles['border']})`,
    },
    controls: {
        ...grid.rowCenter,
        ...grid.pt_md,
        ...grid.pb_md,
        ...grid.pl_mini,
        ...grid.pr_mini,
        ...item.white,
    },
    bordered: {
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles['border']})`,
    },
    tableCell: {
        ...grid.w100,
        ...grid.p_mini,
        textAlign: 'left',
    },
    zIndex: {
        [mediaCSS.tabMini]: {
            zIndex: 999,
        },
    },
    editRow: {
        ...grid.col2,
        ...grid.pr_mdPlus,
        [mediaCSS.tabMini]: {
            ...grid.col1,
            ...grid.pr_none,
            ...grid.mb_md,
        },
    },
    noMargin: {
        paddingRight: '0 !important',
    },
    minTimeWidth: {
        minWidth: '105px',
    },
}

addStyles(advertisingCampaignsAddStyles)
export default advertisingCampaignsAddStyles
