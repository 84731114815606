import React from 'react'
import Toast from 'blocks.simple/toast/toast'
import translate from 'core/translate'
import { toast } from 'react-toastify'
import pageVisibility from 'page-visibility'
import Icon from 'blocks.simple/icon/icon'

import styles from './app__notifications.jcss'
import './app_notifications_toast.css'
import { text as textStyles } from 'theme'
import { constants } from 'core/constants'

export const reportSuccessNotification = (task) => {
    Toast.info(
        <div>
            {translate('reportIsGenerated')}.{' '}
            <a className={textStyles.hoverUnderline} href={task.url}>
                {translate('upload')}
            </a>
        </div>,
        {
            position: 'bottom-left',
            closeOnClick: false,
            pauseOnFocusLoss: false,
            className: styles.fullWidth,
        }
    )
}

export const reportInitializedNotification = () => {
    Toast.info(<div>{translate('reportIsInitialized')}. </div>, {
        position: 'bottom-left',
        closeOnClick: false,
        pauseOnFocusLoss: false,
    })
}

export const downloadStartNotification = (download, cb) => {
    if (toast.isActive(download.id)) {
        return
    }

    pageVisibility((isVisible) => {
        if (isVisible) {
            Toast.info(
                <div className={styles.downloadsWrapper} onClick={cb}>
                    <Icon type="download_arrow" color="white" size={24} />
                </div>,
                {
                    position: 'bottom-left',
                    closeOnClick: false,
                    autoClose: 3000,
                    pauseOnFocusLoss: false,
                    pauseOnHover: false,
                    toastId: download.id,
                }
            )
        } else {
            toast.dismiss()
        }
    })
}

export const showInactivityNotification = (id, timeout) => {
    Toast.info(<div className={styles.userInactivityText}>{translate('notificationInactivityText', [timeout])}</div>, {
        position: 'bottom-left',
        closeOnClick: true,
        pauseOnFocusLoss: false,
        autoClose: constants.INACTIVITY_TIMEOUT_NOTIFICATION_GAP_S * 1000,
        toastId: id,
    })
}

export const hideInactivityNotification = (id) => {
    if (toast.isActive(id)) {
        toast.dismiss()
    }
}

toast.onChange(() => {
    pageVisibility((isVisible) => {
        if (!isVisible) {
            toast.dismiss()
        }
    })
})

const AppNotifications = () => <Toast />

export default AppNotifications
