import React, { FC, useEffect, useState } from 'react'
import { Draggable } from 'atoms/Draggable'
import Icon from 'blocks.simple/icon/icon'
import { cn } from 'ethcss'
import { styles } from './DownloadProgressModal-styles'
import { grid, item, layers } from 'theme'
import translate from 'core/translate'
import { Typography } from 'atoms/Typography'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from './DownloadProgressModal.theme'
import Searchbar from 'blocks.app/searchbar/searchbar'
import { BroadcastItemView } from '../../atoms/BroadcastItem/BroadcastItem-view'
import { api } from '../../../../../../core/api/ConnectionManager'
import { IDownloadModalPosition } from '../../../../../../core/services/DownloadService/DownloadService-types'
import { DropdownSelect } from '../../../../../instancesCatalog/_lensOfTime/lensOfTime_viewModules'

export interface IDownloadModalProps {
    closeModal: () => void
    setPosition: (pos: IDownloadModalPosition) => void
    position: IDownloadModalPosition
    handleClose: any
    digitalSignageId: number | null
}

export const DownloadModal: FC<IDownloadModalProps> = ({
    closeModal,
    setPosition,
    position,
    handleClose,
    digitalSignageId,
}) => {
    const [receivedBroadcasts, setReceivedBroadcasts] = useState([])
    const [searchKeyWord, setSearchKeyWord] = useState('')
    const [broadcastList, setBroadcastList] = useState<any>([])
    const [updateTimeout, setUpdateTimeout] = useState(10 * 1000)
    const [updateKey, setUpdateKey] = useState('')
    let timer: any = null
    const listenersId: string[] = []

    const getBroadcastList = () => {
        if (!digitalSignageId) return

        api.send('requestBroadcastInfo', {
            digitalSignageId,
        })
    }

    function updateBroadcastList(list: any, broadcastList: any) {
        setBroadcastList(
            list.map((broadcast: any) => {
                return {
                    ...broadcast,
                    isOpened: isOpenedCheck(broadcast, broadcastList),
                }
            })
        )
    }

    const isOpenedCheck = (broadcast: any, broadcastList: any) => {
        for (let i = 0; i < broadcastList.length; i++) {
            if (broadcastList[i].broadcastId === broadcast.broadcastId) {
                if (broadcastList[i].isOpened) {
                    return true
                }
            }
        }
        return false
    }

    const broadcastHandleClick = (broadcastId: number) => {
        setBroadcastList(
            broadcastList.map((broadcast: any) => {
                if (broadcast.broadcastId === broadcastId) {
                    return { ...broadcast, isOpened: !broadcast.isOpened }
                }
                return { ...broadcast, isOpened: false }
            })
        )
    }

    const searchInBroadcastList = (inputKeyWord: string) => {
        setSearchKeyWord(inputKeyWord)
    }

    const getFilteredBroadcastList = () => {
        return broadcastList.filter((broadcast: any) => {
            return broadcast.name.toLowerCase().includes(searchKeyWord.toLowerCase())
        })
    }

    useEffect(() => {
        getBroadcastList()

        api.addObserver(
            'broadcastInfoSent',
            (data: any) => {
                if (!data) return

                if (data.digitalSignageId === digitalSignageId) {
                    setReceivedBroadcasts(data.broadcasts)
                }
            },
            listenersId
        )

        return () => {
            listenersId.forEach((id) => api.removeObserver(id))
        }
    }, [])

    useEffect(() => {
        updateBroadcastList(receivedBroadcasts, broadcastList)
    }, [receivedBroadcasts])

    useEffect(() => {
        clearInterval(timer)

        timer = setInterval(() => {
            setUpdateKey(Math.random().toString())
        }, updateTimeout)

        return () => {
            clearInterval(timer)
        }
    }, [updateTimeout])

    useEffect(() => {
        if (!updateKey) return
        getBroadcastList()
    }, [updateKey])

    return (
        <Draggable
            key={'downloadsModal'}
            parentRect={{
                width: window.innerWidth,
                height: window.innerHeight,
                top: 0,
                left: 0,
            }}
            isFixed
            classNames={cn(styles.downloadModal__wrapper)}
            left={position.left}
            top={position.top}
            width={position.width}
            height={position.height}
            zIndex={layers.progress}
            resizableZIndex={layers.progress}
            isRenderResizeElements={false}
            borderColor={getThemeStyleValue('app', 'downloads', 'wrapperBorder') || defaultThemeStyles.wrapperBorder}
            activeBorderColor={
                getThemeStyleValue('app', 'downloads', 'wrapperBorder') || defaultThemeStyles.wrapperBorder
            }
            onDragEnd={setPosition}
            onResizeEnd={setPosition}
            minWidth={0.2}
            minHeight={0.2}
        >
            <div className={styles.downloadModal__contentWrapper}>
                <div className={styles.downloadModal__header}>
                    <div className={item.ellipsis}>{translate('downloadsList')}</div>
                    <div className={cn(grid.row, grid.noWrap)}>
                        <div onClick={handleClose} onMouseDown={(e) => e.stopPropagation()}>
                            <Icon type={'close_tiny'} color={'white'} size={18} />
                        </div>
                    </div>
                </div>
                <div className={styles.downloadModal__contentSearchWrapper}>
                    <Searchbar
                        className={styles.downloadModal__search}
                        mod={'withBorder'}
                        onChange={searchInBroadcastList}
                    />
                    <div className={styles.navWrapper}>
                        <div className={styles.downloadModal__leftBlock}>
                            <Typography className={styles.downloadModal__updateText}>
                                {translate('updateData')}
                            </Typography>
                            <DropdownSelect
                                id={null}
                                value={'10'}
                                callbackValue={null}
                                options={[
                                    { name: '5 ' + translate('seconds'), id: '5' },
                                    { name: '10 ' + translate('seconds'), id: '10' },
                                    { name: '15 ' + translate('seconds'), id: '15' },
                                    { name: '30 ' + translate('seconds'), id: '30' },
                                    { name: '60 ' + translate('seconds'), id: '60' },
                                ]}
                                onChange={(selectedValue: any) => {
                                    setUpdateTimeout(selectedValue.id * 1000)
                                }}
                            />
                        </div>
                        {/*<div className={styles.downloadModal__downloadActions}>*/}
                        {/*    <Typography>Отменить загрузку</Typography>*/}
                        {/*</div>*/}
                    </div>
                    <div className={styles.downloadModal__content}>
                        <div className={styles.downloadModal__title}>
                            <div className={styles.downloadModal__nameWrapper}>
                                <Typography className={styles.downloadModal__name}>{translate('name')}</Typography>
                            </div>
                            <div className={styles.downloadModal__progressWrapper}>
                                <Typography className={styles.downloadModal__name}>{translate('progress')}</Typography>
                            </div>
                        </div>
                        {getFilteredBroadcastList().map((broadcast: any, index: number) => {
                            return (
                                <BroadcastItemView
                                    key={index}
                                    broadcastData={broadcast}
                                    isOpened={broadcast.isOpened}
                                    broadcastHandleClick={broadcastHandleClick}
                                    digitalSignageId={digitalSignageId}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </Draggable>
    )
}
