import { addStyles } from 'ethcss'
import { grid, item } from 'theme'
import { mediaCSS } from 'theme/media'

const catalogTilesStyles = {
    catalogTilesWrapper: {
        ...grid.row,
        ...grid.mt_md,
        ...item.overNone,
        justifyContent: 'left',
    },
    catalogTilesCellWrapper: {
        ...item.rel,
    },
    mediaCellClassName: {
        marginRight: '4.1%',

        '&:nth-child(5n)': {
            marginRight: 0,
        },

        [mediaCSS.tabMini]: {
            ...grid.col3,
        },
        [mediaCSS.mob]: {
            width: '48%',
            marginRight: '4%',
            '&:nth-child(2n)': {
                marginRight: 0,
            },
            '&:nth-child(5n)': {
                marginRight: '4%',
            },
        },
    },
}

addStyles(catalogTilesStyles)
export default catalogTilesStyles
