import React, { useEffect, useState } from 'react'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { grid } from 'theme'
import { text as textStyles } from 'theme'
import styles from './wake__on__lan.jcss'
import { Typography } from 'atoms/Typography'
import { displaysActions } from '../displays.state'
import { connect } from 'react-redux'
import { api } from '../../../core/api/ConnectionManager'

const WakeOnLan = ({ digitalSignageId, onClose }: { digitalSignageId: number | null; onClose: () => void }) => {
    const [status, setStatus] = useState<string>('')
    const [disable, setDisable] = useState<boolean>(false)
    const [deviceIsEnabled, setDeviceIsEnabled] = useState<boolean>(false)

    const enableFiledMock = () => {
        setTimeout(enableFiled, 300000)
    }

    const tryToEnable = () => {
        setStatus(translate('tryingTurnOnDevice'))
        setDisable(true)
    }
    const enableSuccess = () => {
        setDeviceIsEnabled(true)
    }
    const enableFiled = () => {
        setStatus(translate('filedEnableDevice'))
        setDisable(false)
    }

    const wakeOnLan = () => {
        if (!digitalSignageId) return

        api.send('wakeOnLanDigitalSignage', { digitalSignageId })
            .then(() => {
                enableFiledMock()

                tryToEnable()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (!digitalSignageId) return
        const listenersId: string[] = []

        api.addObserver(
            'digitalSignageWokeUp',
            (data: { digitalSignageId: number; success: boolean }) => {
                if (!data) return

                if (data.digitalSignageId === digitalSignageId) {
                    if (data.success) {
                        enableSuccess()
                    } else {
                        enableFiled()
                    }
                }
            },
            listenersId
        )

        return () => listenersId.forEach((id) => api.removeObserver(id))
    }, [])

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <Typography type={'title'} className={styles.wakeOnLan__title}>
                    <span dangerouslySetInnerHTML={{ __html: translate('wakeOnLineTitle') }} />
                </Typography>
            </div>
            {deviceIsEnabled ? (
                <div className={styles.wakeOnLan__success}>
                    <img src="images/displays/enable_success.svg" />
                    {translate('enableDeviceSuccess')}
                </div>
            ) : (
                <div className={styles.wakeOnLan__content}>
                    <Typography type="title" className={cn(textStyles.normal, grid.mb_md)}>
                        {translate('beSure')}
                        <ul className={styles.wakeOnLan__list}>
                            <li className={styles.wakeOnLan__element}>
                                <span dangerouslySetInnerHTML={{ __html: translate('biosFunction') }} />
                            </li>
                            <li className={styles.wakeOnLan__element}>
                                <span dangerouslySetInnerHTML={{ __html: translate('netSupport') }} />
                            </li>
                            <li className={styles.wakeOnLan__element}>
                                <span dangerouslySetInnerHTML={{ __html: translate('cableConnection') }} />
                            </li>
                        </ul>
                        {translate('noReason')}
                    </Typography>
                    <div className={styles.wakeOnLan__status}>{status}</div>
                </div>
            )}
            <div className={styles.footer}>
                <div className={cn(grid.row, grid.center)}>
                    <Button rounded="full_md" onClick={onClose}>
                        {translate('close')}
                    </Button>
                    {!deviceIsEnabled && (
                        <Button
                            rounded="full_md"
                            className={cn(grid.ml_md)}
                            mod="fill"
                            onClick={wakeOnLan}
                            disabled={disable}
                        >
                            {translate('on')}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeModal: () => dispatch(displaysActions.closeModal()),
    }
}

export default connect(null, mapDispatchToProps)(WakeOnLan)
