import React from 'react'
import ScrollUp from 'blocks.simple/scrollUp/scrollUp'
import CatalogMethods, { ICatalogMethodsProps } from './catalog.local.methods'
import CatalogList from './__list/catalog__list'
import CatalogTiles from './__tiles/catalog__tiles'
import CatalogHandleSortableList from './__list/catalog__handleSortableList'
import CatalogHeader from './__header/catalog__header'
import LoaderLazy from 'blocks.app/loader/_lazy/loader__lazy'
import InfiniteScroll from 'react-infinite-scroller'
import Icon from 'blocks.simple/icon/icon'
import Breadcrumbs from './__breadcrumbs/catalog__breadcrumbs'
import Measure from 'react-measure'
import { connect } from 'react-redux'
import { cn } from 'ethcss'
import helpers from 'core/helpers'
import styles from './catalog.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { text as textStyles } from 'theme'
import { cols, indents } from 'blocks.app/config'
import { Typography } from 'atoms/Typography'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { withRouter } from 'react-router'
import { changeFilter } from 'features/routes'
import { downloadsActions } from 'core/services/DownloadService/DownloadService.state'
import { isHorizontalMenu } from 'core/helpers/menu'
import { withWindowResize } from 'core/hocs'
import { activateEditLink, activateEditTable, activateEditText } from 'pages/files/files.state'
import { ItemData } from '../../core/models/Template'
import { checkRoutePathName } from 'core/helpers/routes/routes'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import { routes } from 'features/routes'
import { changeLocation } from 'features/routes'

// Костыль, по которому берется новое название для новых каталогов - для устройств это digitalSignages, расширенный поиск работает только по нему
export const ALLOW_ADVANCED_SEARCH = {
    displays: 'digitalSignages',
}

const headerWidthLimitType_1 = (pathName: string) => {
    switch (pathName) {
        case '/displays':
        case '/advertisingCampaigns':
        case '/broadcasts':
        case '/advertisingAds':
        case '/files':
            return true
        default:
            return false
    }
}

const headerWidthLimitType_2 = (pathName: string) => {
    switch (pathName) {
        case '/instancesCatalog/wine':
        case '/instancesCatalog/courses':
        case '/instancesCatalog/classrooms':
        case '/instancesCatalog/teachers':
        case '/instancesCatalog/subjects':
        case '/instancesCatalog/productMenu':
        case '/instancesCatalog/menuProduct':
        case '/instancesCatalog/grades':
        case '/videoChat':
            return true
        default:
            return false
    }
}

const headerTopAndLeftPaddingType_1 = (pathName: string) => {
    switch (pathName) {
        case '/displays/draftDevices':
        case '/adBlocks':
            return true
        default:
            return false
    }
}

const hotKeysWrapperType_1 = (pathName: string) => {
    switch (pathName) {
        case '/advertisingCampaigns':
            return true
        default:
            return false
    }
}

const removeObjectDuplicates = (arr: any) => {
    const stringifiedArr = arr.map(JSON.stringify)
    const uniqueArr: any = Array.from(new Set(stringifiedArr))
    return uniqueArr.map(JSON.parse)
}

// @ts-ignore
export const CatalogContext = React.createContext()

class CatalogInner extends CatalogMethods {
    getDragData: () => void

    constructor(p_: ICatalogMethodsProps) {
        super(p_)

        this.getDragData = this.template.getDragData || function () {}
    }

    renderList = (showOnlySelected: any) => {
        const p_ = this.props
        const s_ = this.state
        const query = getURLSearchParamsByLocation(p_.location)
        const addItemButton = this.template.addItemButton
        const Map = this.template.map
        const disposition = this.getDisposition()
        const deviceBlockStyle = this.getDeviceBlockStyle()
        const uniqueList = removeObjectDuplicates([...s_.downloadList, ...s_.list])
        const list = uniqueList

        if (showOnlySelected) {
            let selectedInfo = list.find((listItem: ItemData) => this.isEqual(listItem, p_.selectedInfo))

            if (!selectedInfo) {
                if (s_.showOnlySelectedItem) {
                    selectedInfo = s_.showOnlySelectedItem
                } else {
                    return null
                }
            }

            const Tile = this.template.tile

            return (
                <div className={grid.col4}>
                    <Tile
                        active={true}
                        onSelectInfo={function () {}}
                        onSelect={function () {}}
                        onSelectFolder={function () {}}
                        multipleSelect={false}
                        item={selectedInfo}
                        onSelectProgress={function () {}}
                        drag={false}
                        getDragData={this.getDragData}
                        deviceBlockStyle={deviceBlockStyle}
                    />
                </div>
            )
        }

        if (
            !this.loading &&
            list.length === 0 &&
            !s_.fakeLine &&
            !this.isMap(p_) &&
            !p_.toggleControls &&
            !p_.allowEmptyList
        ) {
            return this.template.customEmptyBlock && !this.query.query ? (
                this.template.customEmptyBlock()
            ) : (
                <div
                    className={cn(grid.space, grid.colCenter, styles.marginEmptyList, {
                        [styles.marginEmptyListRightToolBar]:
                            p_.location.pathname === '/addContentToDevice' ||
                            p_.location.pathname === '/broadcasts/edit',
                    })}
                >
                    <div className={cn(grid.colCenter)}>
                        <div className={cn(textStyles.normal, grid.row, grid.center, grid.mb_md)}>
                            {this.template.emptyIcon && (
                                <Icon
                                    type={this.template.emptyIcon}
                                    size={60}
                                    color={getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable}
                                />
                            )}
                            <div
                                className={cn(grid.fullWidth, grid.pt_mini, textStyles.center, {
                                    [grid.mb_mini]: !this.query.query && addItemButton,
                                })}
                            >
                                <Typography type="text" className={textStyles.up}>
                                    {this.query.query
                                        ? this.template.emptySearchText()
                                        : this.template.emptyFolderText(p_.location.pathname, p_.location.search)}
                                </Typography>
                                {!this.query.query && this.template.emptyDescription && (
                                    <div className={cn(grid.pt_mini, grid.rowCenter)}>
                                        <div className={grid.w50}>{this.template.emptyDescription()}</div>
                                    </div>
                                )}
                            </div>
                            {(() => {
                                if (!this.query.query && addItemButton) {
                                    return addItemButton(this, p_.location.pathname)
                                }
                            })()}
                        </div>
                    </div>
                </div>
            )
        } else if (this.isMap(p_) && Map) {
            return (
                <div
                    onKeyDown={this.onKeyDown}
                    onKeyUp={this.onKeyUp}
                    tabIndex={-1}
                    className={cn(
                        styles.hotKeysWrapper,
                        { [styles.wrapperScroll]: !p_.useWindow },
                        { [item.noSelection]: this.isShiftPressed },
                        p_.classNameContent
                    )}
                >
                    <Map
                        mapConfig={window.spconfig.map}
                        query={query}
                        list={list}
                        tags={s_.tags}
                        options={s_.fields}
                        center={s_.center}
                        filter={this.filter}
                        onSelectInfoByItem={this.onSelectInfoByItem}
                        onSelectInfo={this.onSelectInfo}
                        onSelectCluster={this.onSelectCluster}
                        activeMarkerIndex={this.getActiveIndex()}
                        centerMap={this.onCenterMap}
                        selectedInfo={p_.selectedInfo}
                        selectedItems={p_.selectedItems}
                        onGetList={(dataList: ItemData[]) => {
                            const s_ = this.state
                            s_.list = this.getModelsList(dataList)
                            this.setState(s_)
                        }}
                    />
                </div>
            )
        } else if (!this.fullLoading && !p_.isHideList) {
            return (
                <div
                    onKeyDown={this.onKeyDown}
                    onKeyUp={this.onKeyUp}
                    tabIndex={-1}
                    className={cn(
                        styles.hotKeysWrapper,
                        {
                            [styles.headerTopAndLeftPadding_x]:
                                p_.windowWidth > 960 &&
                                isHorizontalMenu() &&
                                hotKeysWrapperType_1(p_.location.pathname),
                        },
                        {
                            [styles.wrapperScrollOverflowMinHeight]:
                                !p_.useWindow && p_.location.pathname === '/schedule',
                        },
                        { [styles.wrapperScroll]: !p_.useWindow },
                        { [item.noSelection]: this.isShiftPressed },
                        p_.classNameContent
                    )}
                >
                    <InfiniteScroll
                        loadMore={() => this.get(true)}
                        hasMore={s_.list.length < s_.total}
                        initialLoad={false}
                        loader={<LoaderLazy key="catalog_loader" active />}
                        threshold={10}
                        useWindow={p_.useWindow}
                    >
                        {disposition === 'tile' && (
                            <CatalogTiles
                                list={list}
                                selectedInfo={p_.selectedInfo}
                                onSelectInfo={this.onSelectInfo}
                                isEqual={this.isEqual}
                                isFolder={this.isFolder}
                                type={p_.type}
                                multipleSelect={!!p_.onSelect}
                                selectedItems={p_.selectedItems}
                                identificatedItems={p_.identificatedItems}
                                onSelectFolder={(data: { id: number }) => {
                                    p_.onSelectInfo(null)
                                    changeFilter({
                                        [helpers.prefixQuery({ name: p_.type, field: 'query' })]: undefined,
                                        [this.template.folderName]: data.id,
                                    })
                                }}
                                onDoubleClick={(isClicked: boolean) => this.setState({ isClicked })}
                                onDoubleClickObject={p_.onDoubleClickObject}
                                onSelect={this.onSelect}
                                onResetSelection={this.onResetSelection}
                                onSelectProgress={this.onSelectProgress}
                                drag={this.isDraggable(p_, this.template)}
                                minimize={p_.minimize}
                                cols={p_.cols}
                                template={this.template}
                                disableFolderSelection={p_.disableFolderSelection}
                                disableMultiSelect={p_.disableMultiSelect}
                                onDragStart={this.onDragStart}
                                onDragEnd={this.onDragEnd}
                                getDragData={this.getDragData}
                                isMobileDragEnabled={p_.isMobileDragEnabled}
                                deviceBlockStyle={deviceBlockStyle}
                                tilesWrapperClassName={p_.tilesWrapperClassName}
                            />
                        )}
                        {disposition === 'list' && (
                            <div
                                className={cn(
                                    p_.location.pathname === '/broadcasts/edit' ? styles.listWrapperShortSm : '',
                                    p_.location.pathname === '/addContentToDevice' ? styles.listWrapperShortMd : '',
                                    styles.listWrapper,
                                    this.template.listWrapperClassName,
                                    {
                                        [styles.indentsListWrapper]: !this.template.headerFilters,
                                        [styles.indentsListWrapper_x]: isHorizontalMenu(),
                                    }
                                )}
                                style={!this.template.disableListScroll ? { overflowX: 'auto' } : {}}
                            >
                                {p_.handleSortable ? null : (
                                    <CatalogList
                                        // activateEditLink={p_.activateEditLink}
                                        // activateEditTable={p_.activateEditTable}
                                        // activateEditText={p_.activateEditText}
                                        options={s_.fields}
                                        list={p_.customFilterList ? p_.customFilterList(list) : list}
                                        type={p_.type}
                                        multipleSelect={!!p_.onSelect}
                                        selectedInfo={p_.selectedInfo}
                                        treeView={p_.treeView}
                                        onSelectInfo={this.onSelectInfo}
                                        selectedItems={p_.selectedItems}
                                        identificatedItems={p_.identificatedItems}
                                        isEqual={this.isEqual}
                                        isFolder={this.isFolder}
                                        onSelectFolder={(data: { id: number }) => {
                                            p_.onSelectInfo(null)
                                            changeFilter({
                                                [helpers.prefixQuery({ name: p_.type, field: 'query' })]: undefined,
                                                [this.template.folderName]: data.id,
                                            })
                                        }}
                                        onDoubleClickObject={p_.onDoubleClickObject}
                                        onFolderOpening={this.onFolderOpening}
                                        onSelect={this.onSelect}
                                        onResetSelection={this.onResetSelection}
                                        template={this.template}
                                        disableFolderSelection={p_.disableFolderSelection}
                                        fakeLine={s_.fakeLine}
                                        onAddFakeLine={(fakeLine) => {
                                            this.setState({ fakeLine })
                                        }}
                                        drag={this.isDraggable(p_, this.template)}
                                        additionalData={p_.additionalData}
                                        disableMultiSelect={p_.disableMultiSelect}
                                        onDragStart={this.onDragStart}
                                        onDragEnd={this.onDragEnd}
                                        getDragData={this.getDragData}
                                        onEditListItem={p_.onEditListItem}
                                        allowEmptyList={p_.allowEmptyList && !list.length}
                                        handleSortable={p_.handleSortable}
                                    />
                                )}
                            </div>
                        )}
                    </InfiniteScroll>
                </div>
            )
        }

        return null
    }

    checkAvailableBreadcrumbs = () => {
        const p_ = this.props
        const s_ = this.state
        const showOnlySelected = p_.showOnlySelected && p_.selectedInfo

        if (!this.props.hideHeader && !showOnlySelected && s_.breadcrumbs) {
            if (this.template.useNewFilter) {
                return !s_.filterIsActive && !this.query.query
            }
            return true
        }
        return false
    }

    render() {
        const s_ = this.state
        const p_ = this.props
        let filterOptions = s_.fields
        const showOnlySelected = p_.showOnlySelected && p_.selectedInfo
        const isFixedHeader = (p_.fixedHeader && !this.isMap(p_)) || (isHorizontalMenu() && this.isMap(p_))
        const viewParams = this.getViewParams()

        if (!this.template.showFilter) {
            filterOptions = null
        }

        const HeaderFilter = this.template.headerFilters

        if (s_.isInit) {
            const horizontalMenu = isHorizontalMenu()
            return (
                <CatalogContext.Provider
                    value={{
                        get: this.get,
                    }}
                >
                    <div
                        className={cn(styles.wrapper, p_.wrapperClassName, {
                            [styles.wrapperAdvertisingHeader_x]: horizontalMenu && p_.type === 'files',
                        })}
                    >
                        <Measure
                            bounds
                            onResize={(contentRect) => {
                                this.setState({
                                    dimensions: contentRect.bounds,
                                })
                            }}
                        >
                            {({ measureRef }) => (
                                <div
                                    ref={measureRef}
                                    className={cn({
                                        [styles.fixedHeader]:
                                            p_.location.pathname !== '/instancesCatalog/catalogizator' &&
                                            p_.location.pathname !== '/instancesCatalog/exhibition' &&
                                            isFixedHeader,
                                        [styles.fixedHeader_width_x_type_1]:
                                            horizontalMenu && headerWidthLimitType_1(p_.location.pathname),
                                        [styles.fixedHeader_width_x_type_2]:
                                            horizontalMenu && headerWidthLimitType_2(p_.location.pathname),
                                        [styles.fixedHeader_x]: p_.windowWidth > 960 && horizontalMenu,
                                        [styles.content_padding_top_x_draftDevices]:
                                            horizontalMenu &&
                                            p_.windowWidth > 960 &&
                                            headerTopAndLeftPaddingType_1(p_.location.pathname),
                                        [styles.noBg]: this.isMap(p_),
                                    })}
                                    style={
                                        isFixedHeader && !horizontalMenu
                                            ? {
                                                  left: `${p_.minimize ? cols.mini : cols.normal}%`,
                                                  right: p_.isExistRightToolbar ? `${cols.normal}%` : 0,
                                              }
                                            : {}
                                    }
                                >
                                    {!p_.hideHeader && !showOnlySelected && (
                                        <CatalogHeader
                                            type={p_.type}
                                            query={this.query}
                                            placeholder={p_.searchPlaceholder}
                                            viewParams={viewParams}
                                            isMap={this.isMap(p_)}
                                            filterOptions={filterOptions}
                                            //searchbarMod={p_.searchbarMod}
                                            searchable={p_.searchable}
                                            showHeaderButton={p_.showHeaderButton}
                                            centerMap={(center) => this.setState({ center })}
                                            list={s_.list}
                                            small={p_.small}
                                            template={this.template}
                                            onSelect={p_.onSelect}
                                            onSelectAll={this.onSelectAll}
                                            onSelectInfo={this.onSelectInfo}
                                            isEqual={this.isEqual}
                                            viewLocal={p_.viewLocal}
                                            getDisposition={this.getDisposition}
                                            changeDisposition={p_.changeDisposition}
                                            onChangeFilterStatus={this.onChangeFilterStatus}
                                            onChangeADSearch={this.setAdvancedSearch}
                                            getAdvancedSearch={this.getAdvancedSearch}
                                            advancedSearchState={s_.advancedSearch}
                                            filterIsActive={s_.filterIsActive}
                                            pathName={p_.location.pathname}
                                            onGetList={(dataList: ItemData[]) => {
                                                const s_ = this.state
                                                s_.list = this.getModelsList(dataList)
                                                this.setState(s_)
                                            }}
                                            isMobile={p_.isMobile}
                                            contentAndFolders={p_.contentAndFolders}
                                            autoSelectContentType={p_.autoSelectContentType}
                                            filterItems={p_.filterItems}
                                            location={p_.location}
                                        />
                                    )}
                                    {this.checkAvailableBreadcrumbs() && (
                                        <div className={p_.breadcrumbsClassName}>
                                            <Breadcrumbs
                                                filterName={this.template.folderName}
                                                list={s_.breadcrumbs}
                                                type={p_.type}
                                                template={this.template}
                                                //selectedInfo={p_.selectedInfo}
                                                selectedItems={p_.selectedItems}
                                                multipleSelect={!!p_.onSelect}
                                                onResetSelection={this.onResetSelection}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </Measure>
                        {isFixedHeader && s_.dimensions.height ? (
                            <div
                                style={{
                                    height: `${s_.dimensions.height}px`,
                                    marginTop: -indents.mdPlus,
                                    visibility: 'hidden',
                                }}
                            />
                        ) : p_.location.pathname === '/instancesCatalog/catalogizator' ||
                          p_.location.pathname === '/instancesCatalog/exhibition' ? (
                            <div />
                        ) : null}
                        {!showOnlySelected && HeaderFilter && (
                            <HeaderFilter
                                query={this.query}
                                small={p_.small}
                                type={p_.type}
                                createFolder={p_.createFolder}
                                widgetsGallery={p_.widgetsGallery}
                                selectedItems={p_.selectedItems}
                                additionalData={p_.additionalData}
                                fakeLine={s_.fakeLine}
                                toggleControls={p_.toggleControls}
                                getNewData={this.template.getNewDataMethod ? this.getNewData.bind(this) : null}
                                onAddFakeLine={(fakeLine: any) => {
                                    this.setState({ fakeLine })
                                }}
                                filterItems={p_.filterItems}
                                autoSelectContentType={p_.autoSelectContentType}
                            />
                        )}
                        {!p_.hideList && this.renderList(showOnlySelected)}
                        {isFixedHeader && <ScrollUp isExistRightToolbar={p_.isExistRightToolbar} />}
                    </div>
                </CatalogContext.Provider>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = (state: any) => {
    return {
        minimize: state.app.minimizeMenu,
        identificatedItems: state.displays.identificatedItems,
        user: state.user.data,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        toggleDownloadsModal: () => dispatch(downloadsActions.toggleModal()),
        activateEditTable: (id: number) => dispatch(activateEditTable(id)),
        activateEditLink: (id: number) => dispatch(activateEditLink(id)),
        activateEditText: (id: number) => dispatch(activateEditText(id)),
    }
}

// @ts-ignore
const Connected = connect(mapStateToProps, mapDispatchToProps)(withRouter(CatalogInner))

class Catalog extends React.Component {
    static defaultProps: {
        onGetRootFolder: () => void
        isExistRightToolbar: boolean
        onDoubleClickObject: () => void
        useWindow: boolean
        treeView: boolean
        mapUnselect: () => void
        onChange: () => void
        disableMultiSelect: () => boolean
        mapSelect: () => void
        onDragEnd: () => void
        onSelectInfo: () => void
        onSelectProgress: () => void
        searchable: boolean
        identificatedItems: any[]
        isHideList: boolean
        onGetTags: () => void
        drag: boolean
        createFolder: boolean
        onDragStart: () => void
        onGetList: () => void
        toggleControls: boolean
        filterOptions: {}
    }
    render() {
        const p_ = this.props
        return <Connected {...p_} />
    }
}

Catalog.defaultProps = {
    useWindow: true,
    searchable: true,
    onSelectInfo: () => {},
    onGetTags: () => {},
    onGetRootFolder: () => {},
    onSelectProgress: () => {},
    onDragStart: () => {},
    onDragEnd: () => {},
    mapSelect: () => {},
    mapUnselect: () => {},
    onChange: () => {},
    onDoubleClickObject: () => {},
    disableMultiSelect: () => false,
    onGetList: () => {},
    identificatedItems: [],
    isExistRightToolbar: true,
    toggleControls: false,
    isHideList: false,
    drag: false,
    treeView: true,
    filterOptions: {},
    createFolder: false,
}

export default withWindowResize(Catalog)
