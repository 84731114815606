import React from 'react'
import InstancesCatalogMethods from './instancesCatalog.local.methods'
import Content from 'blocks.app/content/content'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import styles from './instancesCatalog.jcss'
import helpers from 'core/helpers'
import translate from 'core/translate'
import { routes } from 'features/routes'
import { changeLocation } from 'features/routes'
import { connect } from 'react-redux'
import { checkAvailableModule } from '../../core/helpers/routes/routes'
class InstancesCatalog extends InstancesCatalogMethods {
    render() {
        const s_ = this.state
        const { minimizeMenu } = this.props

        return (
            <Content title={translate('instancesCatalog')} wrapperClassName={styles.instancesWrapper}>
                <div className={styles.wrapper}>
                    {s_.catalogs.map((catalog, index) => {
                        if (!helpers.isAvailable({ key: catalog.action, action: 'create' })) {
                            return null
                        }

                        const image = this.getCatalogImage(catalog.name)

                        return (
                            <div
                                className={cn(styles.cellWrapper, minimizeMenu ? grid.col4 : grid.col3)}
                                key={`catalog_list_${index}`}
                            >
                                <div
                                    onClick={function () {
                                        changeLocation(`/${routes[catalog.action].path}`)
                                    }}
                                >
                                    <Rectangle width="14" height="7" className={styles.imageWrapper}>
                                        <img alt={`${catalog.name}_logo`} src={image} />
                                    </Rectangle>
                                    <div className={styles.title}>{translate(catalog.name)}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Content>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        minimizeMenu: state.app.minimizeMenu,
    }
}

const exportInstancesCatalog = checkAvailableModule('instancesCatalog')
    ? connect(mapStateToProps)(InstancesCatalog)
    : null

export default exportInstancesCatalog
