import { addStyles } from 'ethcss'
import { IStyles } from '../../../theme'
import { colors } from '../../../theme'
import { defaultThemeStyles } from '../../app/app.theme'
import { mediaCSS, textSizes } from 'theme/media'

const filterStyles: IStyles = {
    filter: {},
    filterBtn: {
        height: '100%',
        padding: '0 10px !important',
    },
    filterModal: {
        position: 'absolute',
        // top: '30px',
        right: 0,
        width: '100%',
        backgroundColor: colors.white,
        zIndex: 11,
        borderRadius: '0 0 10px 10px',
        padding: '30px 40px 20px 40px',
        border: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border, ${colors.grey})`,
        borderTop: 'none',
        overflow: 'auto',
        maxHeight: '90vh',
        [mediaCSS.tab]: {
            // position: 'fixed',
            // top: '86px',
            // left: '16px',
            // right: '14px',
            // width: 'auto',
            // zIndex: 9999,
            // padding: '10px',

            left: '0',
            [mediaCSS.tabMini]: {
                width: '95vw',
                [mediaCSS.mini]: {
                    width: '94vw',
                    [mediaCSS.mob]: {
                        width: '93vw',
                    },
                },
            },
        },
    },
    filterItem: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '22px',

        '&:hover': {
            svg: {
                opacity: '1 !important',
            },
        },
    },
    filterLabel: {
        color: colors.grey,
        width: '40%',
        overflow: 'hidden',
    },
    selectedValue: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        border: `1px solid ${colors.greyLight}`,
        borderRadius: '5px',
        width: '56%',
        padding: '0 30px 0 10px',
        cursor: 'pointer',

        '&:after': {
            content: '""',
            position: 'absolute',
            right: '16px',
            border: '5px solid transparent',
            borderTop: `6px solid var(--var-module-app-component-menu-style-active, ${defaultThemeStyles.active})`,
            borderRadius: '3px',
            marginLeft: '14px',
            transform: 'translateY(3px)',
        },

        '& >div': {
            overflow: 'hidden',
            width: '100%',
        },
    },
    isOpened: {
        // '&:after': {
        //     transform: 'rotate(180deg)',
        // },
    },
    filterInner: {
        top: 0,
        right: 0,
        zIndex: 2,
        height: 'auto !important',
    },
    addFilter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    addFilterLabel: {
        color: colors.grey,
        width: '40%',
    },
    addFilterBtnWrapper: {
        display: 'flex',
        position: 'relative',
        width: '56%',
    },
    filterAddStyles: {
        minWidth: '0 !important',
        width: '100%',
        top: '-2px !important',
    },
    navBtn: {
        display: 'flex',
        justifyContent: 'center',
        width: '150px',
        marginLeft: '24px',

        [mediaCSS.tab]: {
            marginLeft: 0,
        },
    },
    navButtonsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '24px',

        [mediaCSS.tab]: {
            justifyContent: 'space-between',
        },
    },
    selectedText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingBottom: '1px',
    },
    deleteItem: {
        position: 'absolute',
        top: '50%',
        left: '-20px',
        transform: 'translateY(-50%)',
        width: '22px',
    },
    deleteIcon: {
        width: '14px',
        height: '14px',
        opacity: 0,
        transition: 'opacity 0.3s',
    },
}

addStyles(filterStyles)
export default filterStyles
