import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import { textSizes } from 'theme/media'
import { colors, grid, IStyles, mediaCSS } from '../../../theme'

const dashboardScheduleStyles: IStyles = {
    wrapper: {
        ...grid.col,
        [mediaCSS.tab]: {
            paddingBottom: '14px',
        },
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E4E4E4',
        paddingBottom: '20px',
        paddingTop: '30px',

        [mediaCSS.tab]: {
            paddingBottom: '10px',
            paddingTop: '16px',
        },
    },
    text: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
    },
    title: {
        textTransform: 'uppercase',
    },
    link: {
        textDecoration: 'none',
    },
    info: {
        fontSize: '48px',
        color: colors.blueAccent,
    },
    footer: {
        ...item.abs,
        bottom: '6px',
        right: '6px',
        fontSize: textSizes.mini,
    },
}

addStyles(dashboardScheduleStyles)
export default dashboardScheduleStyles
