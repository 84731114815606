import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { defaultThemeStyles } from '../TableEditor-theme'
import { textSizes } from 'theme/media'
import { IStyles } from '../../../theme'

const tableEditorDataStyles: IStyles = {
    inputWrapper: {
        ...grid.w45,
    },
    row: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
        ...grid.p_md,
        ...grid.pb_none,
    },
    panel: {
        ...grid.w100,
        ...grid.pb_md,
        ...grid.pt_none,
        ...item.rounded_full_mini,
        border: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    title: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.p_md,
        ...grid.pb_none,
    },
    dropzoneHide: {
        visibility: 'hidden',
        width: 0,
        height: 0,
    },
    description: {
        ...grid.mr_md,
        fontSize: textSizes.md,
        color: `var(--var-module-app-component-tableEditor-style-description, ${defaultThemeStyles.description})`,
    },
}

addStyles(tableEditorDataStyles)
export default tableEditorDataStyles
