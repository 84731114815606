import { addStyles } from 'ethcss'
import { grid, IStyles, item, mediaCSS, textSizes } from '../../theme'
import { radiusSizes } from 'blocks.app/config'

const reportStyles: IStyles = {
    reportType: {
        ...grid.pb_md,
        ...grid.pt_md,
        ...grid.w35,
        margin: '0 auto',
    },
    tabs: {
        ...grid.row,
        borderRadius: radiusSizes.mini,
    },
    reportWrapper: {
        ...grid.hspace,
        ...item.white,
        borderRadius: radiusSizes.mini,
    },
    radioBlockMobile: {
        [mediaCSS.mini]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    paddingMobile: {
        [mediaCSS.mini]: {
            padding: '0 20px',
        },
    },
    title: {
        ...grid.pl_big,
        ...grid.pr_big,
        ...grid.pt_md,
        ...grid.pb_md,
        ...item.white,
    },
    advTitle: {
        ...grid.pb_md,
        ...grid.pl_md,
        fontSize: textSizes.md,
        ...item.white,
    },
    listWrapper: {
        ...grid.p_md,
        ...grid.mb_micro,
        ...grid.col,
        ...item.white,
    },
    whiteBlock: {
        ...grid.p_md,
        ...grid.pb_none,
        ...grid.mt_micro,
        ...item.white,
        ...item.rounded_bottom_mini,
    },
    radio: {
        ...grid.mr_md,
        ...grid.mb_mini,
    },
    report__catalog: {
        ...grid.hspace,
        ...grid.pl_big,
        ...grid.pr_big,
        ...grid.pb_big,
        [mediaCSS.tab]: {
            ...grid.p_mini,
        },
    },
    reportsWrapper: {
        [mediaCSS.tab]: {
            paddingTop: 60,
        },
    },
}

addStyles(reportStyles)
export default reportStyles
