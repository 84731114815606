import React from 'react'
import config from '../index'
import translate from 'core/translate'
import CatalogSelectContent from 'blocks.app/catalog/_select/_content/catalog_select_content'
import CustomListHeader, { ICustomListHeaderProps } from '../../../__header/customList__header'
import Button from 'blocks.simple/button/button'
import { api } from 'core/api/ConnectionManager'
import EditableText from 'blocks.simple/editableText/editableText'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import validator from 'validator'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import store from 'core/store'

interface Item {
    __view: { selected: Item }
    bottomImageSource: {
        __view: {
            selected: Item
        }
    }
    topImageSource: {
        __view: {
            selected: Item
        }
    }
}

export default {
    ...config,
    fields: () => [
        { id: 'topImageSource', name: translate('slide') },
        { id: 'bottomImageSource', name: translate('services') },
        { id: 'title', name: translate('buttonName') },
        { id: 'href', name: translate('urlForButton') },
    ],
    init(callback: any) {
        // @ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    category: 'slides',
    type: 'askona',
    getItemModel: (dataItem: Item, getSelected: (item: Item) => any) => {
        const item = { ...dataItem }

        if (item.topImageSource && !item.topImageSource.__view) {
            item.topImageSource.__view = {
                selected: getSelected(item),
            }
        }

        if (item.bottomImageSource && !item.bottomImageSource.__view) {
            item.bottomImageSource.__view = {
                selected: getSelected(item),
            }
        }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    headerFilters: (p_: ICustomListHeaderProps) => (
        <CustomListHeader
            onAdd={() => {
                if (!p_.fakeLine) {
                    p_.onAddFakeLine({
                        topImageSource: null,
                        bottomImageSource: null,
                        bottomButton: {
                            title: '',
                            href: '',
                        },
                    })
                } else {
                    p_.onAnimate(true)
                    setTimeout(() => {
                        p_.onAnimate(false)
                    }, 1000)
                }
            }}
            title={translate('slides')}
            type={p_.type}
            onDelete={() => {
                const id = p_.selectedItems.map((selectedItem) => selectedItem.id)
                if (id.length > 0) {
                    api.send('askonaDeleteCatalogItem', { id })
                }
                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            }}
            {...p_}
        />
    ),
    list: {
        topImageSource: (
            p_: {
                id: number
                topImageSource: {
                    __view: {
                        selected: Item
                    }
                }
            },
            active: boolean,
            listProps: {
                fakeLine: {}
                onAddFakeLine: (value?: {}) => void
                template: any
                catalogId: string
            }
        ) => (
            <div className={cn(grid.fullWidth, grid.colCenter)}>
                <div className={p_.topImageSource ? grid.w15 : null}>
                    <CatalogSelectContent
                        type={'image'}
                        isHideSidebar
                        selectedInfo={p_.topImageSource}
                        onSelect={(selectedMedia: { id: number }) => {
                            if (p_.id) {
                                api.send('askonaUpdateCatalogItem', {
                                    id: p_.id,
                                    catalogId: listProps.catalogId,
                                    category: listProps.template.category,
                                    topImageSourceId: selectedMedia.id,
                                })
                            } else {
                                listProps.onAddFakeLine({ ...listProps.fakeLine, topImageSource: selectedMedia })
                            }
                        }}
                    />
                </div>
            </div>
        ),
        bottomImageSource: (
            p_: { id: number; bottomImageSource: object },
            active: boolean,
            listProps: {
                fakeLine: {}
                onAddFakeLine: (value?: {}) => void
                template: any
                catalogId: string
            }
        ) => (
            <div className={cn(grid.fullWidth, grid.colCenter)}>
                <div className={p_.bottomImageSource ? grid.w15 : null}>
                    <CatalogSelectContent
                        type={'image'}
                        isHideSidebar
                        selectedInfo={p_.bottomImageSource}
                        onSelect={(selectedMedia: { id: number }) => {
                            if (p_.id) {
                                api.send('askonaUpdateCatalogItem', {
                                    id: p_.id,
                                    catalogId: listProps.catalogId,
                                    category: listProps.template.category,
                                    bottomImageSourceId: selectedMedia.id,
                                })
                            } else {
                                listProps.onAddFakeLine({ ...listProps.fakeLine, bottomImageSource: selectedMedia })
                            }
                        }}
                    />
                </div>
            </div>
        ),
        title: (
            p_: { id: number; bottomButton: { title: string } },
            active: boolean,
            listProps: {
                fakeLine: {
                    bottomButton: { title: string }
                }
                onAddFakeLine: (value?: {}) => void
                template: any
                catalogId: string
            }
        ) => (
            <EditableText
                id={p_.id}
                text={p_.bottomButton.title}
                onChange={(title: string) => {
                    listProps.onAddFakeLine({
                        ...listProps.fakeLine,
                        bottomButton: {
                            ...listProps.fakeLine.bottomButton,
                            title,
                        },
                    })
                }}
                onUpdate={(title, cb) => {
                    api.send('askonaUpdateCatalogItem', {
                        id: p_.id,
                        catalogId: listProps.catalogId,
                        category: listProps.template.category,
                        bottomButton: {
                            title,
                        },
                    }).then(cb)
                }}
            />
        ),
        href: (
            p_: { id: number; bottomButton: { href: string } },
            active: boolean,
            listProps: {
                fakeLine: {
                    bottomButton: { title: string; href: string }
                    topImageSource: { id: number }
                    bottomImageSource: { id: number }
                }
                onAddFakeLine: (value?: {}) => void
                template: any
                catalogId: string
            }
        ) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.id })}>
                <div className={cn({ [grid.fullWidth]: p_.id, [grid.space]: !p_.id })}>
                    <EditableText
                        id={p_.id}
                        text={p_.bottomButton.href}
                        onChange={(href: string) => {
                            listProps.onAddFakeLine({
                                ...listProps.fakeLine,
                                bottomButton: {
                                    ...listProps.fakeLine.bottomButton,
                                    href,
                                },
                            })
                        }}
                        onUpdate={(href, cb) => {
                            if (validator.isURL(href, { protocols: ['http', 'https'], require_protocol: true })) {
                                api.send('askonaUpdateCatalogItem', {
                                    id: p_.id,
                                    catalogId: listProps.catalogId,
                                    category: listProps.template.category,
                                    bottomButton: {
                                        href,
                                    },
                                }).then(cb)
                            } else {
                                store.dispatch(emitError('linkFormatError'))
                            }
                        }}
                    />
                </div>
                {!p_.id && (
                    <Button
                        className={cn(grid.ml_micro, grid.mr_mini)}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            listProps.onAddFakeLine()
                        }}
                    >
                        {translate('cancel')}
                    </Button>
                )}
                {!p_.id && (
                    <Button
                        mod={'fill'}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            if (
                                validator.isURL(listProps.fakeLine.bottomButton.href, {
                                    protocols: ['http', 'https'],
                                    require_protocol: true,
                                })
                            ) {
                                api.send('askonaCreateCatalogItem', {
                                    catalogId: listProps.catalogId,
                                    category: listProps.template.category,
                                    topImageSourceId: listProps.fakeLine.topImageSource.id,
                                    bottomImageSourceId: listProps.fakeLine.bottomImageSource.id,
                                    ...listProps.fakeLine,
                                })
                            } else {
                                store.dispatch(emitError('linkFormatError'))
                            }
                        }}
                        disabled={
                            !listProps.fakeLine.topImageSource ||
                            !listProps.fakeLine.bottomImageSource ||
                            !listProps.fakeLine.bottomButton.title ||
                            !listProps.fakeLine.bottomButton.href
                        }
                    >
                        {translate('save')}
                    </Button>
                )}
            </div>
        ),
    },
}
