import { addStyles } from 'ethcss'
import { IStyles } from '../../theme'

const ViewLocalStyles: IStyles = {
    viewWrapper: {
        position: 'relative',
        background: '#fff',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 12px',
        boxSizing: 'content-box',
        marginLeft: '10px',
        height: '37px',
        border: `1px solid var(--var-module-ui-component-editText-style-withLightBorder-border)`,
    },
}

addStyles(ViewLocalStyles)
export default ViewLocalStyles
