import React from 'react'
import moment from 'moment'
import translate from 'core/translate'
import styles from './catalog__template_survey.jcss'
import { CatalogActionBar } from 'organisms/Catalog/ActionBar/ActionBar-view'
import { ActionBarRequestHeaderType, ActionBarActionType } from 'core/models/ActionBar'
import grid from 'blocks.simple/grid/grid.jcss'
import { cn } from 'ethcss'
import { IActionBarComponent } from '../../../../organisms/Catalog/ActionBar/ActionBar-types'
import { ItemData } from '../../../../core/models/Template'

interface Data {
    type: 'begin' | 'end'
    value: string
}

const getActionList = (p_: any) => {
    return [
        {
            order: 1,
            type: ActionBarActionType.DATE_INTERVAL,
            dateBegin: moment().subtract(1, 'months'),
            dateEnd: moment(),
            cb: (data: { type: 'begin' | 'end'; value: string }) => {
                template.customParams.filter.creationDate[data.type] = data.value
                p_.getNewData()
            },
        },
    ]
}

const template = {
    getMethod: 'getSurveyList',
    customParams: {
        limit: 35,
        filter: {
            creationDate: {
                begin: moment().subtract(1, 'months').format('YYYY-MM-DD'),
                end: moment().format('YYYY-MM-DD'),
            },
        },
    },
    getNewDataMethod: true,
    trNoClick: true,
    disableDrag: true,
    emptySearchText: () => translate('notFound'),
    emptyDescription: () => translate('createFirstPosition'),
    emptyFolderText: () => translate('listIsEmpty'),
    emptyIcon: 'not_allow',
    addItemButton: () => null,
    settingsPrefixes: 'survey',
    sortOptions: () => [],
    headerFilters: (p_: IActionBarComponent) => {
        return (
            <CatalogActionBar
                title={translate('surveyData')}
                requestHeaderType={ActionBarRequestHeaderType.WITH_AUTH}
                type="survey"
                actions={getActionList(p_)}
                {...p_}
            />
        )
    },

    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },

    tableWrapper: styles.tableWrapper,
    isEqual: (firstItem: ItemData, secondItem: ItemData) => {
        if (firstItem.historyPageId && secondItem.historyPageId) {
            return firstItem.historyPageId === secondItem.historyPageId
        }

        return firstItem === secondItem
    },

    onCreateListeners: [],
    onUpdateListeners: [],
    onDeleteListeners: [],

    isFolder: () => {},
    fields: () => [
        { id: 'title', name: translate('title') },
        { id: 'answer', name: translate('answer') },
        { id: 'email', name: translate('email') },
        { id: 'createdAt', name: translate('createdAt') },
    ],
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        title: (p_: { title: string }) => <div className={cn(grid.fullWidth)}>{p_.title}</div>,
        answer: (p_: { answer: string }) => <div className={cn(grid.fullWidth)}>{p_.answer}</div>,
        email: (p_: { email: string }) => <div className={cn(grid.fullWidth)}>{p_.email}</div>,
        createdAt: (p_: { createdAt: string }) => (
            <div className={cn(grid.fullWidth)}>{moment(p_.createdAt).format('DD-MM-YYYY HH:mm')}</div>
        ),
    },
    hideActiveRowColor: true,
    disableUpdateUserSettings: true,
    templateCellsWidth: {
        answer: '25%',
        title: '25%',
        email: '25%',
        createdAt: '25%',
    },
    disableListScroll: true,
    fixedColWidth: false,
    dndDblClickDisable: true,
}

export default template
