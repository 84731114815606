import React from 'react'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import DndDraggable from 'blocks.app/dnd/_draggable/dnd_draggable'
import DotTooltip from 'blocks.simple/dotTooltip/dotTooltip'

import { cn } from 'ethcss'
import styles from './catalog__item_broadcasts.jcss'
import { text as textStyles } from 'theme'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import broadcastsHelpers from 'core/helpers/_broadcasts'
import { indents } from 'blocks.app/config'
import helpers from 'core/helpers'
import { ProgressBar } from 'atoms/ProgressBar'

const Progress = ({ list, onClick }) => {
    if (list && list.length > 0) {
        return (
            <div className={cn(styles.loading)} onClick={onClick}>
                <div className={styles.progress}>
                    <ProgressBar value={list[0].percentage} strokeWidth={17} />
                </div>
                <div className={cn(grid.row, grid.noWrap, grid.w75, item.ellipsisPure)}>
                    <div className={item.ellipsisPure}>
                        {list[0].title}
                        <span>&nbsp;</span>
                    </div>
                    <div>{Math.round(list[0].percentage)}%</div>
                </div>
            </div>
        )
    } else {
        return null
    }
}

class ItemBroadcasts extends React.PureComponent {
    state = {
        isHovered: false,
        dragData: this.props.getDragData(this.props),
        isItemDrag: false,
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            ...prevState,
            dragData: nextProps.getDragData(nextProps),
        }
    }
    render() {
        const s_ = this.state
        const p_ = this.props
        const active = p_.active || p_.item.__view.selected
        const isDirectory = broadcastsHelpers.isDirectory(p_.item)
        const downloads = p_.item.downloadPercentage
        let broadcastCover = {}

        if (!isDirectory) {
            broadcastCover = {
                backgroundSize: p_.item.cover.isIcon ? 'contain' : 'cover',
            }

            if (p_.item.cover.src) {
                broadcastCover.backgroundImage = `url(${p_.item.cover.src})`
            }
        }

        return (
            <div
                onClick={function (e) {
                    if (helpers.isDblTouchTap(e)) {
                        if (!isDirectory) {
                            p_.onDoubleClickObject(p_.item)

                            e.preventDefault()
                            return
                        }

                        p_.onSelectFolder(p_.item)
                        return
                    }

                    p_.onSelectInfo(e)
                }}
                onContextMenu={(e) => {
                    if (helpers.isMobileDevice()) {
                        e.preventDefault()
                    }
                }}
                id={`broadcast_${p_.item.instanceType}_${p_.item.id}`}
            >
                {!isDirectory && (
                    <Rectangle width="16" height="9" className={styles.wrapper}>
                        <DndDraggable
                            data={s_.dragData}
                            dragEnabled={p_.drag}
                            className={styles.broadcast}
                            style={broadcastCover}
                            onDragStart={() => {
                                p_.onDragStart()
                                this.setState({ isItemDrag: true })
                            }}
                            onDragEnd={() => {
                                p_.onDragEnd()
                                this.setState({ isItemDrag: false })
                            }}
                        >
                            <div className={styles.contentBroadcast}>
                                {p_.multipleSelect && (
                                    <div
                                        className={styles.checkWrapper}
                                        onClick={(e) => e.stopPropagation()}
                                        style={p_.item.__view.selected ? { opacity: 1 } : null}
                                    >
                                        <Checkbox
                                            mod={'circleInv'}
                                            onClick={(selected) => {
                                                p_.onSelect({ selected, item: p_.item })
                                            }}
                                            checked={p_.item.__view.selected}
                                        />
                                    </div>
                                )}
                                <DotTooltip
                                    id={p_.item.id}
                                    tooltip={{ html: p_.item.title, disabled: s_.isItemDrag }}
                                    containerClass={styles.name}
                                    portalId={`broadcast_${p_.item.instanceType}_${p_.item.id}`}
                                >
                                    <div className={styles.nameInner}>{p_.item.title}</div>
                                </DotTooltip>
                                <div className={cn(styles.preview, { [item.blackOpacity]: active })} />
                                <div style={{ bottom: indents.nano, left: indents.nano }} className={cn(item.abs)}>
                                    <Progress list={downloads} onClick={p_.onSelectProgress} />
                                </div>
                                <div className={cn(grid.col, grid.full)} />
                            </div>
                        </DndDraggable>
                    </Rectangle>
                )}
                {isDirectory && (
                    <Rectangle width="16" height="9" className={styles.groupWrapper}>
                        <DndDraggable
                            data={s_.dragData}
                            dragEnabled={p_.drag}
                            className={`${styles.group} ${styles[`${p_.item.status}Group`]}`}
                            onDragStart={() => {
                                p_.onDragStart()
                                this.setState({ isItemDrag: true })
                            }}
                            onDragEnd={() => {
                                p_.onDragEnd()
                                this.setState({ isItemDrag: false })
                            }}
                        >
                            <div className={styles.content}>
                                {p_.multipleSelect && (
                                    <div
                                        className={styles.checkWrapper}
                                        onClick={(e) => e.stopPropagation()}
                                        style={p_.item.__view.selected ? { opacity: 1 } : null}
                                    >
                                        <Checkbox
                                            mod={'circleInv'}
                                            onClick={(selected) => {
                                                p_.onSelect({ selected, item: p_.item })
                                            }}
                                            checked={p_.item.__view.selected}
                                        />
                                    </div>
                                )}
                                <div className={cn(grid.colCenter, grid.full, { [item.blackOpacity]: active })}>
                                    <div className={cn(textStyles.center, textStyles.white, grid.w90)}>
                                        <div className={cn(grid.p_micro, textStyles.normalPlus, item.ellipsis)}>
                                            {p_.item.title}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DndDraggable>
                        <div className={`${styles.groupBackWrapper} ${styles.offlineGroup}`}>
                            <div className={cn(styles.groupBack, styles.groupBack_second)} />
                        </div>
                        <div className={styles.groupBackWrapper}>
                            <div className={`${styles.groupBack} ${styles.groupBack_first}`} />
                        </div>
                    </Rectangle>
                )}
            </div>
        )
    }
}

export default ItemBroadcasts
