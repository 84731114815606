import PropTypes from 'prop-types'
import React from 'react'
import Icon from 'blocks.simple/icon/icon'
import styles from './editor__toolbar.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { cn } from 'ethcss'
import { colors, getThemeStyleValue } from '../../../theme/colors'
import { defaultThemeStyles } from '../editor.theme'
import translate from 'core/translate'
import { editor } from '../editor.local.methods'
import EditText from '../../../blocks.simple/editText/editText'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import Button from 'blocks.simple/button/button'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import DropMenu from '../../../blocks.simple/dropMenu/dropMenu'
import { api } from '../../../core/api/ConnectionManager'

class EditorToolbar extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            titleError: null,
            broadcastTitle: p_.broadcast.title,
            showSaveApprove: false,
            actionsBarOpened: false,
            pictograms: [],
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.broadcast.title !== this.state.broadcastTitle) {
            this.setState((state) => ({
                ...state,
                broadcastTitle: nextProps.broadcast.title,
            }))
        }
    }

    componentDidMount() {
        api.send('getPictogram', {}).then((res) => {
            if (res) {
                this.setState({ pictograms: res })
            }
        })
    }

    renderPictograms() {
        return (
            <>
                {this.state.pictograms.map((pictogram) => {
                    return (
                        <div
                            className={styles.pictogramOption}
                            onClick={() => this.onSelectPictogram(pictogram.label.replace('.svg', ''))}
                        >
                            <img src={pictogram.iconUrl} />
                        </div>
                    )
                })}
            </>
        )
    }

    onSelectPictogram = (pictogram) => {
        const isNewSvg = pictogram === 'rectangle' || pictogram === 'circle'
        const style = {
            fill: '#ffffff00',
            stroke: '#ffffffff',
            strokeWidth: isNewSvg ? '5' : '1',
        }
        const pictogramSrc = this.state.pictograms.find((item) => item.label.includes(pictogram)).url

        if (pictogram === 'rectangle') {
            style.rx = '0'
        }

        editor.addContent({
            id: Math.random().toString(),
            src: pictogramSrc,
            type: 'image',
            thumbnail: pictogramSrc || `images/editor/pictograms/pictogram_${pictogram}.svg`,
            name: pictogram,
            fileType: 'image',
            data: {
                fileUrl: pictogramSrc,
                duration: 30,
                pictogramName: pictogram,
                style,
                isNewSvg: isNewSvg ? pictogram : null,
                body: '',
                type: 'image',
            },
        })
    }

    save = () => {
        const { isEdit, onSave, updateUserSettings } = this.props
        if (this.state.showDeletePrompt) {
            updateUserSettings({ data: { displayedTooltips: { saveBroadcastPrompt: true } }, isSaveSettings: true })
        }
        return isEdit ? onSave({ forceUpdate: true }) : onSave()
    }

    changeTitle = (title) => {
        this.setState((state) => ({
            ...state,
            broadcastTitle: title,
            titleError: null,
        }))
    }

    render() {
        const s_ = this.state
        const p_ = this.props
        const { folderId } = getURLSearchParamsByLocation(p_.location)

        const iconColor = getThemeStyleValue('editor', 'header', 'icon') || defaultThemeStyles['header-icon']
        const iconActiveColor =
            getThemeStyleValue('editor', 'header', 'iconActive') || defaultThemeStyles['header-iconActive']
        const actionIconColorMobile = colors.greyEditor
        const iconActiveColorMobile = colors.greyEditor2

        const getShowSavePrompt = () => {
            return !(p_.displayedTooltips && p_.displayedTooltips.saveBroadcastPrompt)
        }

        const saveDiskette = () => {
            const { isEdit, onSave } = this.props

            return getShowSavePrompt()
                ? this.setState((prevState) => {
                      return { ...prevState, showSaveApprove: true }
                  })
                : isEdit
                ? onSave({ forceUpdate: true })
                : onSave()
        }

        return (
            <div className={styles.toolbarBlock}>
                <div className={cn(styles.editorToolbarHeader, p_.isMobile ? styles.editorToolbarHeaderMobile : '')}>
                    <div className={styles.broadcastName}>
                        <EditText
                            className={styles.titleEditText}
                            mod={'text'}
                            value={s_.broadcastTitle}
                            error={s_.titleError}
                            onChange={this.changeTitle}
                            onEndTyping={function (title) {
                                editor.changeTitle(title)
                                editor.validateTitle()
                            }}
                        />
                        {p_.isMobile && (
                            <Icon type="pencil" color={'white'} size="18" className={styles.titleEditIcon} />
                        )}
                    </div>
                    <div className={cn(styles.headerIcons, p_.isMobile ? styles.headerIconsMobile : '')}>
                        <div className={cn(styles.iconWrapper, p_.isMobile ? styles.mobileIconWrapper : '')}>
                            <Icon
                                type="gear"
                                color={iconColor}
                                onClick={p_.onOpenBroadcastSettings}
                                tooltip={{ title: translate('openBroadcastSettings') }}
                                size="20"
                            />
                        </div>
                        <div className={cn(styles.iconWrapper, p_.isMobile ? styles.mobileIconWrapper : '')}>
                            <Icon
                                type="diskette"
                                onClick={saveDiskette}
                                color={iconColor}
                                size="20"
                                tooltip={{ title: translate('save') }}
                            />
                            <div
                                className={cn(grid.colCenter, grid.p_microPlus, {
                                    [styles.dNoneModalSave]: !s_.showSaveApprove,
                                    [styles.modalSaveApprove]: s_.showSaveApprove,
                                })}
                            >
                                <div className={cn(grid.w80, grid.mb_md, grid.pt_microPlus)}>
                                    {translate('broadcastSaveChanges')}
                                </div>
                                <div className={cn(grid.row, grid.justify, grid.mb_mini)}>
                                    <Button mod="fill" className={cn(grid.mr_mini)} onClick={this.save}>
                                        {translate('yes')}
                                    </Button>
                                    <Button
                                        animation={false}
                                        onClick={() =>
                                            this.setState((prevState) => {
                                                return { ...prevState, showSaveApprove: false }
                                            })
                                        }
                                    >
                                        {translate('no')}
                                    </Button>
                                </div>

                                <Checkbox
                                    className={grid.pb_microPlus}
                                    label={translate('tooltipWarning')}
                                    checked={s_.showDeletePrompt}
                                    onClick={(showDeletePrompt) =>
                                        this.setState((prevState) => {
                                            return { ...prevState, showDeletePrompt }
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className={cn(styles.iconWrapper, p_.isMobile ? styles.mobileIconWrapper : '')}>
                            <Icon
                                type="exit"
                                onClick={p_.changeLocation}
                                color={iconColor}
                                size="20"
                                tooltip={{ title: translate('closeEditor') }}
                            />
                        </div>
                    </div>
                </div>
                {p_.isMobile && (
                    <div
                        className={styles.actionBarToggle}
                        onClick={() => {
                            editor.actionBarToggle(!editor.state.actionsBarOpened)
                        }}
                    >
                        <Icon
                            type={s_.actionsBarOpened ? 'editorActionToggleOpened' : 'editorActionToggle'}
                            color={colors.white}
                            size="27"
                        />
                    </div>
                )}
                <div
                    className={cn(
                        styles.editorToolbarWrapper,
                        p_.isMobile
                            ? {
                                  [styles.editorToolbarWrapperMobile]: true,
                                  [styles.actionsBarOpened]: editor.state.actionsBarOpened,
                              }
                            : ''
                    )}
                >
                    <div className={cn(grid.row, p_.isMobile ? styles.editorRowMobile : '')}>
                        {!p_.isSimple && (
                            <div className={cn(styles.iconWrapper, p_.isMobile ? styles.iconWrapperMobile : '')}>
                                <Icon
                                    type={'add_area'}
                                    onClick={p_.onAddArea}
                                    color={p_.isMobile ? actionIconColorMobile : iconColor}
                                    size="20"
                                    tooltip={{ title: translate('addArea') }}
                                />
                            </div>
                        )}
                        <div className={cn(styles.iconWrapper, p_.isMobile ? styles.iconWrapperMobile : '')}>
                            <Icon
                                type={'goPrev'}
                                onClick={p_.onGoPrev}
                                disabled={p_.disabledGoPrev}
                                color={p_.isMobile ? actionIconColorMobile : iconColor}
                                size="20"
                            />
                        </div>
                        <div className={cn(styles.iconWrapper, p_.isMobile ? styles.iconWrapperMobile : '')}>
                            <Icon
                                type={'goNext'}
                                onClick={p_.onGoNext}
                                disabled={p_.disabledGoNext}
                                color={p_.isMobile ? actionIconColorMobile : iconColor}
                                size="20"
                            />
                        </div>
                        <div className={cn(styles.iconWrapper, p_.isMobile ? styles.iconWrapperMobile : '')}>
                            <Icon
                                type="zoom_in_bold"
                                onClick={p_.onZoomIn}
                                disabled={p_.disabledZoomIn}
                                color={p_.isMobile ? actionIconColorMobile : iconColor}
                                size="20"
                                tooltip={{ title: translate('zoomIn') }}
                            />
                        </div>
                        <div className={cn(styles.iconWrapper, p_.isMobile ? styles.iconWrapperMobile : '')}>
                            <Icon
                                type="zoom_out_bold"
                                onClick={p_.onZoomOut}
                                disabled={p_.disabledZoomOut}
                                color={p_.isMobile ? actionIconColorMobile : iconColor}
                                size="20"
                                tooltip={{ title: translate('zoomOut') }}
                            />
                        </div>
                        <div className={cn(styles.iconWrapper, p_.isMobile ? styles.iconWrapperMobile : '')}>
                            <Icon
                                type={'maximize'}
                                onClick={p_.onFullscreenMaximizeContent}
                                disabled={p_.disabledContentButtons}
                                color={p_.isMobile ? actionIconColorMobile : iconColor}
                                size="20"
                                tooltip={{ title: translate('fullscreenCropContent') }}
                            />
                        </div>
                        <div className={cn(styles.iconWrapper, p_.isMobile ? styles.iconWrapperMobile : '')}>
                            <Icon
                                type={'crop'}
                                onClick={p_.onFullscreenCropContent}
                                disabled={p_.disabledContentButtons}
                                color={p_.isMobile ? actionIconColorMobile : iconColor}
                                size="20"
                                tooltip={{ title: translate('fullscreenMaximizeContent') }}
                            />
                        </div>
                        <div className={cn(styles.iconWrapper, p_.isMobile ? styles.iconWrapperMobile : '')}>
                            <Icon
                                type={'fullscreenContent'}
                                onClick={p_.onFullscreenContent}
                                disabled={p_.disabledContentButtons}
                                color={p_.isMobile ? actionIconColorMobile : iconColor}
                                size="20"
                                tooltip={{ title: translate('fullscreenContent') }}
                            />
                        </div>
                        {/*<div*/}
                        {/*    className={cn(*/}
                        {/*        styles.iconWrapper,*/}
                        {/*        {*/}
                        {/*            [styles.selected]: p_.activeIcon['animationSettings'],*/}
                        {/*        },*/}
                        {/*        p_.isMobile ? styles.iconWrapperMobile : ''*/}
                        {/*    )}*/}
                        {/*>*/}
                        {/*    <Icon*/}
                        {/*        type={'animation'}*/}
                        {/*        onClick={p_.onAnimationSettings}*/}
                        {/*        disabled={p_.disabledContentButtons}*/}
                        {/*        color={*/}
                        {/*            p_.activeIcon['animationSettings']*/}
                        {/*                ? p_.isMobile*/}
                        {/*                    ? iconActiveColorMobile*/}
                        {/*                    : iconActiveColor*/}
                        {/*                : p_.isMobile*/}
                        {/*                ? actionIconColorMobile*/}
                        {/*                : iconColor*/}
                        {/*        }*/}
                        {/*        size="20"*/}
                        {/*        tooltip={{ title: translate('animationSettings') }}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className={cn(styles.iconWrapper, p_.isMobile ? styles.iconWrapperMobile : '')}>
                            <Icon
                                type={'table'}
                                onClick={p_.onAddTable}
                                disabled={!folderId || p_.disabledAreaButtons}
                                color={p_.isMobile ? actionIconColorMobile : iconColor}
                                size="20"
                                tooltip={{ title: translate('addTable') }}
                            />
                        </div>
                        {!p_.isSimple && (
                            <div className={cn(styles.iconWrapper, p_.isMobile ? styles.iconWrapperMobile : '')}>
                                <Icon
                                    type={'copy'}
                                    onClick={p_.onCopyAreaContent}
                                    disabled={p_.disabledAreaButtons}
                                    color={p_.isMobile ? actionIconColorMobile : iconColor}
                                    size="20"
                                    tooltip={{ title: translate('copyAreaContent') }}
                                />
                            </div>
                        )}
                        {!p_.isSimple && (
                            <div className={styles.iconWrapper}>
                                <Icon
                                    type={'paste'}
                                    onClick={p_.onPasteAreaContent}
                                    disabled={p_.disabledPaste}
                                    color={p_.isMobile ? actionIconColorMobile : iconColor}
                                    size="20"
                                    tooltip={{ title: translate('pasteAreaContent') }}
                                />
                            </div>
                        )}
                        {!p_.isSimple && (
                            <div className={styles.iconWrapper}>
                                <DropMenu
                                    active={[this.state.active]}
                                    onChange={(active) => this.setState({ active })}
                                    onClick={(active) => this.setState({ active })}
                                    className={styles.iconsList}
                                    contentClassName={styles.contentList}
                                    mod="centered"
                                    items={[
                                        {
                                            id: 'pictogram',
                                            icon: 'rectangleStar',
                                            tooltip: {
                                                title: translate('pictograms'),
                                            },
                                            disabled: p_.disabledAreaButtons,
                                            children: <div>{this.renderPictograms()}</div>,
                                        },
                                    ]}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

EditorToolbar.propTypes = {
    isSimple: PropTypes.bool,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    onAddArea: PropTypes.func,
    onZoomIn: PropTypes.func,
    onZoomOut: PropTypes.func,
    onGoPrev: PropTypes.func,
    onGoNext: PropTypes.func,
    onAddTable: PropTypes.func,
    onCopyAreaContent: PropTypes.func,
    onPasteAreaContent: PropTypes.func,
    onFullscreenContent: PropTypes.func,
    onAnimationSettings: PropTypes.func,
    onFullscreenMaximizeContent: PropTypes.func,
    onFullscreenCropContent: PropTypes.func,
    broadcast: PropTypes.object,
    onOpenBroadcastSettings: PropTypes.func,
    changeLocation: PropTypes.func,
    disabledContentButtons: PropTypes.bool,
    disabledAreaButtons: PropTypes.bool,
    disabledZoomIn: PropTypes.bool,
    disabledZoomOut: PropTypes.bool,
    disabledGoPrev: PropTypes.bool,
    disabledGoNext: PropTypes.bool,
    disabledPaste: PropTypes.bool,
    activeIcon: PropTypes.object,
    displayedTooltips: PropTypes.object,
    updateUserSettings: PropTypes.func,
}
EditorToolbar.defaultProps = {
    activeIcon: {},
}

export { EditorToolbar }
