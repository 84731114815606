import { isExist, isNotEmptyArray } from 'core/utils/coreUtil'
import { Bounds, MapCluster, MapMarker, MapOptions, MapProps, SuperCluster, TMapPoint } from '../models/map'
// @ts-ignore
import supercluster from 'points-cluster'
import { EARTH_RADIUS_KM } from '../constants'

export const isEqualMarkers = (prevMarker: MapMarker, nextMarker: MapMarker) => {
    return prevMarker.lng === nextMarker.lng && prevMarker.lat === nextMarker.lat && prevMarker.id === nextMarker.id
}

export const isEqualClusters = (prevCluster: MapCluster, nextCluster: MapCluster) => {
    return (
        prevCluster.lng === nextCluster.lng &&
        prevCluster.lat === nextCluster.lat &&
        prevCluster.numPoints === nextCluster.numPoints &&
        prevCluster.id === nextCluster.id
    )
}

export const createSuperClusters = (mapOptions: MapOptions, markers: MapMarker[]): SuperCluster[] => {
    const clusters = supercluster(markers, {
        minZoom: 0,
        maxZoom: 25,
        radius: 60,
    })

    return clusters(mapOptions)
}

export const transformSuperClustersToClusters = (clusters: SuperCluster[]) => {
    return clusters.map(({ wx, wy, numPoints, points }) => ({
        lat: wy,
        lng: wx,
        numPoints,
        id: `${numPoints}_${points[0].id}`,
        points,
    }))
}

export function isMapItemsChanged<T>(prevItems: T[], nextItems: T[], isEqual: (prevItem: T, nextItem: T) => boolean) {
    if (!isExist(prevItems) || !isExist(nextItems)) return false
    if (prevItems.length !== nextItems.length) return true

    const differentsMapItems = nextItems.filter(
        (nextItem) => !isExist(prevItems.find((prevItem) => isEqual(prevItem, nextItem)))
    )

    return !isNotEmptyArray(differentsMapItems)
}

export const getDistance = (center: TMapPoint, bounds: Bounds) => {
    return (
        EARTH_RADIUS_KM *
        Math.acos(
            Math.sin(center.lat) * Math.sin(bounds.ne.lat) +
                Math.cos(center.lat) * Math.cos(bounds.ne.lat) * Math.cos(bounds.ne.lng - center.lng)
        )
    )
}

export const compareMapProps = (prevProps: MapProps, nextProps: MapProps) => {
    let isEqualProps = true

    Object.keys(prevProps).forEach((key) => {
        const mapKey = key as keyof MapProps

        switch (mapKey) {
            case 'markers': {
                if (isMapItemsChanged<MapMarker>(prevProps[mapKey], nextProps[mapKey], isEqualMarkers)) {
                    isEqualProps = false
                }

                break
            }
            case 'center':
                if (
                    prevProps[mapKey].lat !== nextProps[mapKey].lat ||
                    prevProps[mapKey].lng !== nextProps[mapKey].lng
                ) {
                    isEqualProps = false
                }

                break
            default: {
                if (prevProps[mapKey] !== nextProps[mapKey]) {
                    isEqualProps = false
                }
            }
        }
    })

    return isEqualProps
}

export const getInitialMapOptions = (center: TMapPoint, zoom: number) => {
    return {
        center,
        zoom,
        bounds: null,
    }
}
