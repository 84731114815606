import { createHashHistory } from 'history'

declare var window: any

export const checkAvailableModule = (route: string) => {
    const modulesList = window.spconfig.modulesList

    if (!modulesList) return true

    return modulesList.includes(route)
}

export const checkRoutePathName = (path: string) => {
    const history = createHashHistory()
    let pathname = history.location.pathname
    return path === pathname
}
