import { addStyles } from 'ethcss'
import { cols, layers, mediaCSS, radiusSizes } from 'blocks.app/config'
import { grid, item, IStyles } from 'theme'
import { defaultThemeStyles } from 'blocks.app/sidebar/Sidebar-theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { colors } from 'theme'
import { textSizes } from 'theme/media'
import _item from 'blocks.simple/item/item.jcss'

const displaysScreenShotsSidebarStyles: IStyles = {
    sidebar: {
        color: `var(--var-module-sidebar-component-screenshot-style-text, ${defaultThemeStyles['screenshot-text']})`,
        ...item.transitionErase,
        ..._item.previewOpacity,
        width: cols.normalFixed,
        zIndex: layers.sticky + 2,
        [mediaCSS.tab]: {
            ...item.fixed,
            ...grid.fullHeight,
            width: 0,
            right: 0,
            opacity: 0,
        },
    },
    sidebarOpen: {
        [mediaCSS.tab]: {
            ...grid.w80,
            opacity: 1,
        },
    },
    toggleIcon: {
        ...item.fixed,
        ...item.transitionErase,
        ...grid.p_md,
        ..._item.previewOpacity,
        ...item.rounded_full_md,
        zIndex: layers.sticky + 2,
        right: '2%',
    },
    toggleIconOpen: {
        right: '80%',
    },
    screenInfo: {
        ...item.ellipsis,
        fontSize: textSizes.mini,
    },
    radio: {
        ...grid.row,
        ...grid.fullWidth,
    },
    inputCol: {
        ...grid.col,
        ...grid.fullWidth,
    },
    titleSidebar: {
        ...grid.mt_md,
        ...grid.mb_md,
        ...grid.p_mini,
        fontSize: textSizes.тd,
    },
    screenshotInfo() {
        return {
            ...grid.p_mini,
            ...grid.normalCenter,
            ...grid.row,
            ...grid.noWrap,
            cursor: 'pointer',
            color: `var(--var-module-sidebar-component-screenshot-style-infoText, ${defaultThemeStyles['screenshot-infoText']})`,
            borderTop: `1px solid var(--var-module-sidebar-component-screenshot-style-border, ${defaultThemeStyles['screenshot-border']})`,
            [`&:hover .${this.checkbox}`]: {
                opacity: 1,
            },
            '&:last-child': {
                borderBottom: `1px solid var(--var-module-sidebar-component-screenshot-style-border, ${defaultThemeStyles['screenshot-border']})`,
            },
        }
    },
    active: {
        color: colors.white,
        background: 'rgba(255, 255, 255, 0.20)',
    },
    filter: {
        ...grid.w20,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.center,
        border: `1px solid var(--var-module-sidebar-component-screenshot-style-border, ${defaultThemeStyles['screenshot-border']})`,
        ...item.rounded_right_mini,
        ...item.pointer,
    },
    checkbox: {
        ...grid.w15,
        ...grid.mr_micro,
        opacity: 0,
        ...item.transitionErase,
    },
    checkboxActive: {
        opacity: '1!important',
    },
    minimize: {
        ...item.pointer,
        borderRight: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles['border']})`,
        ...grid.p_md,
    },
    tabs: {
        ...grid.row,
        ...grid.mb_md,
        borderRadius: radiusSizes.mini,
    },
    tabsColor: {
        color: 'white !important',
    },
    toolbar: {
        ...grid.row,
        ...grid.p_mini,
        ...grid.justify,
    },
    dropzoneHide: {
        ...item.hidden,
        width: 0,
        height: 0,
    },
    actionPanel: {
        position: 'relative',
        minHeight: 49,
    },
    selectAll: {
        display: 'inline-block',
        position: 'absolute',
        left: 0,
        marginLeft: 20,
        zIndex: 1,
    },
}

addStyles(displaysScreenShotsSidebarStyles)
export default displaysScreenShotsSidebarStyles
