import { useEffect, useState } from 'react'
import translate from 'core/translate'

export interface ITouchEditorActionsProps {
    pages: any
    page: { id: number; name: string; order: number }
    action: { id: string; eventType: string; name: string; actionOptions: {} }
    actions: { id: string; eventType: string; name: string; actionOptions?: {} }[]
    emitError: (err: string) => void
    content: any
    actionsRef: any
}

const TouchEditorActionsMethods = (p_: ITouchEditorActionsProps) => {
    const [state, setState] = useState<{
        selectedAction: { id: string; action: string; eventType: string } | null
        actionOptions: { value: string } | null
        pages: []
    }>({
        selectedAction: null,
        actionOptions: null,
        pages: [],
    })

    useEffect(() => {
        setPages()
    }, [])

    const setPages = () => {
        const data: any = {}
        let pages = p_.pages.filter((page: any) => page !== p_.page.order)

        data.pages = pages.map((page: number) => ({
            id: page,
            name: `${translate('page')} ${page + 1}`,
        }))

        if (p_.action) {
            data.selectedAction = {}
            data.selectedAction.id = p_.action.id
            data.selectedAction.eventType = p_.action.eventType
            data.actionOptions = p_.action.actionOptions
        }

        setState((prevState) => {
            return {
                ...prevState,
                ...data,
            }
        })
    }
    const validate = () => {
        const { emitError } = p_

        if (!state.selectedAction && !state.selectedAction!.id) {
            emitError('actionNotSelected')
            return false
        }

        switch (state.selectedAction && state.selectedAction.id) {
            default:
                if (!state.actionOptions!.hasOwnProperty('value')) {
                    emitError('pageNotSelected')
                    return false
                }
        }

        return true
    }
    const onSave = () => {
        if (validate()) {
            return {
                eventType: state.selectedAction!.eventType,
                actions: [
                    {
                        actionType: state.selectedAction!.id,
                        actionOptions: state.actionOptions,
                    },
                ],
            }
        }

        return false
    }

    p_.actionsRef.current = { onSave }

    return {
        state,
        setState,
    }
}

export default TouchEditorActionsMethods
