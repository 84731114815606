import { addStyles } from 'ethcss'
import { radiusSizes } from 'blocks.app/config'
import { grid, IStyles } from '../../theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'

const tagStyles: IStyles = {
    item: {
        ...grid.row,
        padding: 4,
        ...grid.pr_micro,
        ...grid.pl_micro,
        borderRadius: radiusSizes.mini,
        border: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
        cursor: 'default',
        display: 'inline-block',
    },
    icon: {
        ...grid.pl_micro,
        ...grid.fullHeight,
        cursor: 'pointer',
        display: 'inline-block',
    },
    text: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    textWrapper: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%',
    },
}

addStyles(tagStyles)
export default tagStyles
