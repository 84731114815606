import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { mediaCSS } from 'blocks.app/config'
import './assets/calendar.css'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { defaultThemeStyles } from './Calendar-theme'
import { textSizes } from 'theme/media'
import { IStyles } from '../../theme'

const calendarStyles: IStyles = {
    calendarWrapper: {
        '& .rbc-calendar': {
            backgroundColor: `var(--var-module-app-component-calendar-style-background, ${defaultThemeStyles.background})`,
        },
        '& .rbc-month-view': {
            border: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
            borderTop: 'none',
        },
        '& .rbc-day-bg': {
            cursor: 'pointer',
        },
        '& .rbc-day-bg + .rbc-day-bg': {
            borderLeft: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        },
        '& .rbc-month-view .rbc-header': {
            borderBottom: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        },
        '& .rbc-month-view .rbc-header + .rbc-header': {
            borderLeft: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        },
        '& .rbc-month-row + .rbc-month-row': {
            borderTop: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        },
        '& .rbc-header': {
            ...grid.p_mini,
            fontSize: textSizes.md,
            color: `var(--var-module-app-component-calendar-style-headerText, ${defaultThemeStyles.headerText})`,
            textAlign: 'left',
            [mediaCSS.tabMini]: {
                fontSize: textSizes.normal,
                textAlign: 'center',
                ...grid.p_nano,
            },
        },
        '& .rbc-date-cell': {
            ...grid.p_micro,
            ...grid.pb_microPlus,
            fontSize: textSizes.md,
            textAlign: 'left',
            color: `var(--var-module-app-component-calendar-style-dayNumber, ${defaultThemeStyles.dayNumber})`,
        },
        '& .rbc-off-range': {
            color: `var(--var-module-app-component-calendar-style-dayNumber, ${defaultThemeStyles.dayNumber})`,
        },
        '& .rbc-selectedDate': {
            backgroundColor: `var(--var-module-app-component-calendar-style-selectedDate, ${defaultThemeStyles.selectedDate})`,
        },
        '& .rbc-event': {
            ...grid.p_none,
            ...grid.w100,
            // height: 28,
            minHeight: 28,
            fontSize: textSizes.normal,
            ...grid.p_micro,
            boxShadow: `var(--var-module-app-component-calendar-style-lineShadow, ${defaultThemeStyles.lineShadow})`,
            borderRadius: 0,
            backgroundColor: `var(--var-module-app-component-calendar-style-cellLine, ${defaultThemeStyles.cellLine})`,
            pointerEvents: 'visible',
            border: 'none!important',
        },
        '& .rbc-row-content': {
            // pointerEvents: 'none',
        },
        '& .rbc-row-segment': {
            padding: `0 0 4px 0;`,
        },
        '& .rbc-event.rbc-selected': {
            backgroundColor: `var(--var-module-app-component-calendar-style-active-cellLine, ${defaultThemeStyles['active-cellLine']})`,
        },
        '& .rbc-show-more': {
            ...grid.pt_micro,
            textAlign: 'center',
        },
        '& .rbc-event-content-wrapper': {
            position: 'absolute',
            width: '14.2857%',
            height: 28,
            paddingLeft: 2,
            paddingRight: 2,
            zIndex: 100,
        },
        '& .rbc-header-gutter': {
            wordBreak: 'break-word',
            maxWidth: 80,
        },
        '& .rbc-today': {
            background: 'none',
        },
    },
    controls: {
        ...grid.rowCenter,
        ...grid.space,
        fontSize: 30,
        textTransform: 'capitalize',
        [mediaCSS.tabMini]: {
            fontSize: textSizes.mdPlus,
        },
    },
    header: {
        ...grid.row,
        ...grid.rowCenter,
        ...grid.pt_md,
        ...grid.pb_md,
        ...grid.pl_mdPlus,
        ...grid.pr_mdPlus,
        backgroundColor: `var(--var-module-app-component-calendar-style-header, ${defaultThemeStyles.header})`,
        color: `var(--var-module-app-component-calendar-style-headerText, ${defaultThemeStyles.headerText})`,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
    },
    todayWrapper: {
        ...grid.w15,
        [mediaCSS.tabMini]: {
            ...item.none,
        },
    },
    todayContent: {
        ...grid.row,
        ...grid.normalCenter,
        cursor: 'pointer',
    },
    todayTitle: {
        ...grid.pl_microPlus,
        textTransform: 'capitalize',
    },
    title: {
        ...grid.w60,
        textAlign: 'center',
        fontSize: 30,
    },
    customHeaderItem: {
        ...grid.w15,
        [mediaCSS.tabMini]: {
            ...item.none,
        },
    },
}

addStyles(calendarStyles)
export default calendarStyles
