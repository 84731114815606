import styles from '../styles'
import { Avatar } from '../../../molecules/Avatar'
import { cn } from 'ethcss'
import { grid } from '../../../theme'
import translate from '../../../core/translate'
import React from 'react'
import { getRolesString } from '../index'
import { useDispatch } from 'react-redux'
import { logout, userActions } from 'blocks.app/user/user.state'
import DotTooltip from '../../../blocks.simple/dotTooltip/dotTooltip'

const OwnerPanel = (p_) => {
    const dispatch = useDispatch()

    const getUserEmail = () => {
        if (!p_.user.name) {
            return null
        }

        return <div className={styles.email}>{p_.user.email}</div>
    }

    const handleUserLogout = (e) => {
        dispatch(userActions.closeUserPanel())
        dispatch(logout({ isSwitchAccount: false }))
    }

    return (
        <div className={styles.ownerPanelWrapper}>
            <div className={styles.ownerPanelAvatar}>
                <Avatar src={p_.user.photo} size={92} />
            </div>
            <div className={styles.ownerPanelInfo}>
                <div className={styles.userInfo}>
                    <div className={styles.username}>{p_.user.name || p_.user.email}</div>
                    {getUserEmail()}
                    <div className={styles.userBlock}>
                        <div className={styles.userBlockCaption}>{translate('idCompany')}:</div>
                        <div className={styles.userBlockText}>{p_.user.company.id}</div>
                    </div>
                    <div className={styles.userBlock}>
                        <div className={styles.userBlockCaption}>{translate('companyName')}:</div>
                        <div className={styles.userBlockText}>
                            <DotTooltip
                                tooltip={{
                                    html: p_.user.company.name,
                                    position: 'right',
                                    disabled: false,
                                    followCursor: true,
                                }}
                                containerClass={cn(styles.textTooltip)}
                            >
                                <div className={styles.textTooltip}>{p_.user.company.name}</div>
                            </DotTooltip>
                        </div>
                    </div>
                    <div className={styles.roles}>{getRolesString(p_.user.roles)}</div>
                </div>

                <div className={styles.ownerPanelActions}>
                    <div className={cn(styles.ownerPanelAction, grid.mr_md)} onClick={p_.onEditAccount}>
                        {translate('edit')}
                    </div>
                    <div className={styles.ownerPanelAction} onClick={handleUserLogout}>
                        {translate('exit')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export { OwnerPanel }
