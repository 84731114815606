import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { indents } from 'blocks.app/config'

const editorAreaStyles = {
    wrapper: {
        ...grid.full,
        ...item.rel,
    },
    dropWrapper: {
        ...grid.full,
        ...item.rel,
    },
    audioWrapper: {
        ...grid.w25,
        ...item.abs,
        top: indents.mini,
        right: indents.mini,
    },
    audioItem: {
        ...grid.mb_micro,
        '&:last-child': {
            ...grid.mb_none,
        },
    },
    isResize: {
        ...grid.rowCenter,
        ...item.abs,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    loader: {
        height: '100%',
        width: '100%',
        ...grid.flex,
        ...grid.center,
        ...grid.normalCenter,
    },
    areaSettingsPanel: {
        position: 'absolute',
        left: 14,
        bottom: 14,
        backgroundColor: 'rgba(0,0,0,0.45)',
        padding: 9,
        borderRadius: '50%',
        zIndex: 10000,
    },
    areaFullScreenPanel: {
        position: 'absolute',
        left: 14,
        top: 14,
        backgroundColor: 'rgba(0,0,0,0.45)',
        padding: 9,
        borderRadius: '50%',
        zIndex: 10000,
    },
}

addStyles(editorAreaStyles)
export default editorAreaStyles
