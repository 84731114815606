import { colors } from 'theme'

export const defaultThemeStyles = {
    textMain: colors.grey,
    active: colors.white,
    hover: colors.white,
    background: colors.white,
    activeBackground: colors.grey,
    hoverBackground: colors.grey,
    border: colors.greyLight,
    hoverBorder: colors.grey,
    activeBorder: colors.grey,
    'vertical-background': colors.darkCloud,
    'vertical-backgroundActive': colors.blue
}
