import React from 'react'
import { api } from 'core/api/ConnectionManager'

class DisplaysPermissionsMethods extends React.Component<{
    selectedInfo: { id: number } | null
    closeModal: () => void
}> {
    state: {
        permissions: string[] | null
        isUnknownPermissions: boolean
        isLoading: boolean
        requestPermissions: { [index: string]: boolean }
        isRequestAll: boolean
    } = {
        permissions: null,
        isUnknownPermissions: false,
        isLoading: true,
        requestPermissions: {},
        isRequestAll: false,
    }
    getPermissionsTimer: any = null
    listenersId: string[] = []

    componentDidMount() {
        this.get()
    }
    componentWillUnmount() {
        this.listenersId.forEach((id) => api.removeObserver(id))

        clearInterval(this.getPermissionsTimer)
    }
    onGetDisplayAndroidPermissions = (res: { permissions: string[] }) => {
        clearTimeout(this.getPermissionsTimer)
        this.setState({ isLoading: false, permissions: res.permissions, isRequestAll: false })
    }
    get = () => {
        api.addObserver('getDisplayAndroidPermissions', this.onGetDisplayAndroidPermissions, this.listenersId)

        this.getPermissions()
    }
    getPermissions = () => {
        const p_ = this.props

        this.getPermissionsTimer = setTimeout(() => {
            this.setState({
                isUnknownPermissions: true,
                isLoading: false,
            })
        }, 10000)

        this.setState({ isLoading: true }, () => {
            const id = p_.selectedInfo && p_.selectedInfo.id

            if (id) {
                api.send('getDisplayAndroidPermissions', { id }, { hideLoader: true })
            }
        })
    }
    requestPermission = (permission: string) => {
        const s_ = this.state

        s_.requestPermissions[permission] = true

        this.setState(s_, () => {
            this.requestPermissions(permission)
        })
    }
    requestPermissions = (permissions: string) => {
        const p_ = this.props
        const id = p_.selectedInfo && p_.selectedInfo.id

        if (id) {
            api.send('requestDisplayAndroidPermissions', { id, permissions })
        }
    }
}

export default DisplaysPermissionsMethods
