import translate from 'core/translate'
import React, { FC, useEffect, useState } from 'react'
import { styles } from './NetworkConverter-styles'
import {
    FetchNetworkConverterInfoRequest,
    NetworkConverterProps,
    RestartScoreboardRequest,
    SaveNetworkConverterInfoRequest,
} from './NetworkConverter-types'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from 'core/store/rootReducer'
import { IScoreboardsState, scoreboardsActions } from 'pages/scoreboards/scoreboards.state'
import { NetworkConverterInfo } from 'pages/scoreboards/models'
import { api } from 'core/api/ConnectionManager'
import { InformationWindow } from 'molecules/InformationWindow'
import { isEmptyObject } from 'core/utils/coreUtil'
import { NetworkConverterEdit } from './NetworkConverterEdit'
import { useStatus } from 'core/hooks'

const SCROLL_AREA_HEIGHT = 530

export const NetworkConverter: FC<NetworkConverterProps> = ({}) => {
    const dispatch = useDispatch()
    const { modalData } = useSelector<IRootState, IScoreboardsState>((state) => state.scoreboards)
    const { status, changeStatus } = useStatus()
    const [data, setData] = useState<NetworkConverterInfo | null>(null)
    const [password, setPassword] = useState<string>('')
    const [searchPin, setSearchPin] = useState<string>('')

    const isError = status === 'error'
    const isLoading = status === 'loading'

    const fetchNetworkConverterInfo = async (id: number) => {
        try {
            changeStatus('loading')
            const networkConverterInfo = await api.send<FetchNetworkConverterInfoRequest, NetworkConverterInfo>(
                'getNetworkConverterInfo',
                { ledControllerId: id }
            )

            if (!networkConverterInfo) {
                changeStatus('error')
                return
            }

            setData(networkConverterInfo)
            setPassword(networkConverterInfo.password)
            setSearchPin(networkConverterInfo.searchPin)
            changeStatus('success')
        } catch (err) {
            setData(null)
            changeStatus('error')
        }
    }

    const getChangedData = () => {
        if (!data) return null

        let changedData = {}

        const { searchPin: oldPin, password: oldPassword } = data

        if (oldPin !== searchPin) {
            changedData = { ...changedData, searchPin }
        }

        if (oldPassword !== password) {
            changedData = { ...changedData, password }
        }

        return changedData
    }

    const isDataChanged = () => {
        if (!data) return false

        const { searchPin: oldPin, password: oldPassword } = data

        return oldPin !== searchPin || oldPassword !== password
    }

    const saveNetworkConverterInfo = async (id: number) => {
        if (!data) return null

        const changedData = getChangedData()

        if (!changedData || isEmptyObject(changedData)) return

        try {
            changeStatus('loading')
            const networkConverterInfo = await api.send<SaveNetworkConverterInfoRequest, NetworkConverterInfo>(
                'saveNetworkConverterInfo',
                {
                    ledControllerId: id,
                    ...changedData,
                }
            )

            if (!networkConverterInfo) {
                changeStatus('error')
                return
            }

            setData(networkConverterInfo)
            setPassword(networkConverterInfo.password)
            setSearchPin(networkConverterInfo.searchPin)
            changeStatus('success')
        } catch (err) {
            changeStatus('error')
        }
    }

    const onChangeSearchPin = (searchPin: string) => {
        setSearchPin(searchPin)
    }

    const onChangePassword = (password: string) => {
        setPassword(password)
    }

    const onRestart = async () => {
        if (!modalData || !modalData.id) return

        try {
            await api.send<RestartScoreboardRequest, null>('restartScoreboard', { ledControllerId: modalData.id })
            dispatch(scoreboardsActions.closeModal())
        } catch (err) {
            changeStatus('error')
        }
    }

    useEffect(() => {
        if (!modalData || !modalData.id) return

        fetchNetworkConverterInfo(modalData.id)
    }, [])

    const onReject = () => dispatch(scoreboardsActions.closeModal())

    const onAccept = () => {
        if (!modalData || !modalData.id) return

        if (isError) {
            return fetchNetworkConverterInfo(modalData.id)
        }

        saveNetworkConverterInfo(modalData.id)
    }

    const getAcceptText = () => (isError ? translate('repeatVerb') : translate('save'))

    const getRejectText = () => (isError ? translate('close') : translate('cancel'))

    const getTitle = () => translate('networkConverter')

    const getErrorText = () => translate('networkConverterError')

    const renderContent = () => {
        if (!data) return null

        return (
            <NetworkConverterEdit
                networkConverterInfo={data}
                searchPin={searchPin}
                password={password}
                onRestart={onRestart}
                onChangeSearchPin={onChangeSearchPin}
                onChangePassword={onChangePassword}
            />
        )
    }

    return (
        <div className={styles.NetworkConverter}>
            <InformationWindow
                onReject={onReject}
                onAccept={onAccept}
                acceptText={getAcceptText()}
                rejectText={getRejectText()}
                title={getTitle()}
                errorText={getErrorText()}
                isError={isError}
                isLoading={isLoading}
                isDisableAccept={!isDataChanged()}
                scrollAreaHeight={SCROLL_AREA_HEIGHT}
            >
                <div className={styles.NetworkConverter__content}>{renderContent()}</div>
            </InformationWindow>
        </div>
    )
}
