import { addStyles } from 'ethcss'
import { IStyles } from 'theme'
import { radiusSizes, transitions } from 'theme'
import { defaultThemeStyles } from './SwitchButton-theme'

export const switchButtonStyles: IStyles = {
    switchButton__wrapper: {
        display: 'inline-block',
        position: 'relative',
        height: 14,
        width: 30,
        borderRadius: radiusSizes.big,
        cursor: 'pointer',
    },
    switchButton__wrapper_mod_fill: {
        border: `1px solid var(--var-module-ui-component-switchButton-style-fill-border, ${defaultThemeStyles['fill-border']})`,
        background: `var(--var-module-ui-component-switchButton-style-fill-background, ${defaultThemeStyles['fill-background']})`,
    },
    switchButton__wrapper_mod_fill_custom_theme: {
        border: `1px solid var(--var-module-ui-component-switchButton-style-fill-border, ${defaultThemeStyles['fill-handler']})`,
        background: `var(--var-module-ui-component-switchButton-style-fill-background, ${defaultThemeStyles['fill-handler']})`,
    },
    switchButton__wrapper_mod_fill_custom_theme_active: {
        border: `1px solid var(--var-module-ui-component-switchButton-style-fill-border, ${defaultThemeStyles['fill-activeBorder']})`,
        background: `var(--var-module-ui-component-switchButton-style-fill-background, ${defaultThemeStyles['fill-activeBackground']})`,
    },
    switchButton__wrapper_mod_fill_type_active: {
        border: `1px solid var(--var-module-ui-component-switchButton-style-fill-activeBorder, ${defaultThemeStyles['fill-activeBorder']})`,
        background: `var(--var-module-ui-component-switchButton-style-fill-activeBackground, ${defaultThemeStyles['fill-activeBackground']})`,
    },
    switchButton__wrapper_disabled: {
        opacity: '0.2!important',
        cursor: 'not-allowed!important',
    },
    switchButton: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'none',
        border: 'none',
        width: '100%',
        height: '100%',
    },
    switchButton__handler: {
        position: 'absolute',
        top: 1,
        left: 3,
        width: 10,
        height: 10,
        borderRadius: radiusSizes.circle,
        transition: `all ${transitions.normal}ms`,
        pointerEvents: 'none',
        background: '#FFFFFF',
    },
    switchButton__handler_mod_fill: {
        background: `var(--var-module-ui-component-switchButton-style-fill-handler, ${defaultThemeStyles['fill-handler']})`,
    },
    switchButton__handler_mod_fill_type_active: {
        background: `var(--var-module-ui-component-switchButton-style-fill-activeHandler, ${defaultThemeStyles['fill-activeHandler']})`,
    },
    switchButton__handler_mod_fill_type_custom_theme: {
        background: '#FFFFFF !important',
    },
    switchButton__handler_checked: {
        left: `15px!important`,
    },
}

addStyles(switchButtonStyles)
