import React from 'react'
import DashboardNetworkMethods from './dashboard__network.local.methods'
// @ts-ignore
import DonutChart from 'react-donut-chart'
import Card from 'blocks.simple/card/card'
import EditText from 'blocks.simple/editText/editText'
import ModalAccept from 'blocks.simple/modal/_accept/modal_accept'
import Animation from 'blocks.simple/animation/animation'

import filesize from 'filesize'
import translate from 'core/translate'
import helpers from 'core/helpers'

import { cn } from 'ethcss'
import styles from './dashboard__network.jcss'
import { grid } from '../../../theme'

class DashboardNetwork extends DashboardNetworkMethods {
    getTitle = () => translate('currentNetUsageStatistic')

    render() {
        const s_ = this.state
        const p_: any = this.props
        const itemRules = p_.rules ? p_.rules : 0

        return (
            <>
                {s_.data.length ? (
                    <Animation className={p_.className} style={{ order: itemRules.order }}>
                        {!s_.isLoaded ? null : (
                            <Card
                                className={grid.col}
                                titleClassName={styles.title}
                                title={this.getTitle()}
                                icon={{
                                    type: 'cloud_network',
                                    size: 20,
                                    default: true,
                                }}
                            >
                                <div className={styles.wrapper}>
                                    <div className={styles.memoryInfo}>
                                        <div className={styles.chart}>
                                            <DonutChart
                                                width={250}
                                                height={250}
                                                legend={false}
                                                data={s_.data}
                                                colors={this.colors}
                                                formatValues={(value: number) => filesize(value)}
                                                clickToggle={false}
                                            />
                                        </div>
                                        <div className={styles.rightBlock}>
                                            {s_.data.map((item: any, index) => (
                                                <div className={styles.legend} key={index}>
                                                    <div
                                                        className={styles.mark}
                                                        style={{ background: this.colors[index] }}
                                                    />
                                                    {item.label} - {filesize(item.value)}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {helpers.isAvailable({ key: 'statistics', action: 'read' }) && (
                                        <div className={styles.link}>
                                            <span onClick={() => this.setState({ isOpenReportModal: true })}>
                                                {translate('moreStatistic')}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <ModalAccept
                                    open={s_.isOpenReportModal}
                                    title={translate('reportNetUsage')}
                                    rejectLabel={translate('close')}
                                    acceptLabel={translate('createReport')}
                                    onReject={() => this.setState({ isOpenReportModal: false })}
                                    onAccept={this.onCreateReport}
                                    onClose={() => this.setState({ isOpenReportModal: false })}
                                >
                                    <div className={styles.reportContent}>
                                        <div className={cn(grid.w100, grid.mb_md)}>{translate('reportParams')}</div>
                                        <div className={styles.inputsWrapper}>
                                            <EditText
                                                containerClass={cn(styles.inputs)}
                                                label={translate('startDate')}
                                                dateValue={s_.startDate.toString()}
                                                id="startDate"
                                                maxDate={s_.endDate.toString()}
                                                type="date"
                                                onChange={(startDate: string) => this.setState({ startDate })}
                                            />
                                            <EditText
                                                containerClass={cn(styles.inputs, styles.zIndex)}
                                                label={translate('endDate')}
                                                dateValue={s_.endDate.toString()}
                                                id="startDate"
                                                minDate={s_.startDate.toString()}
                                                type="date"
                                                onChange={(endDate: string) => this.setState({ endDate })}
                                            />
                                            <div />
                                        </div>
                                    </div>
                                </ModalAccept>
                            </Card>
                        )}
                    </Animation>
                ) : null}
            </>
        )
    }
}

export default DashboardNetwork
