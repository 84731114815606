import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { IStyles } from 'theme'

export const styles: IStyles = {
    container: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.center,
        position: 'relative',
    },
    marker: {
        top: -40,
    },
    markerActive: {
        top: -60,
    },
    badge: {
        position: 'absolute',
        top: -50,
    },
    badgeActive: {
        position: 'absolute',
        top: -65,
        left: 5,
    },
    bordered: {
        border: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    clusterBudge: {
        backgroundColor: `var(--var-module-app-component-app-style-backgroundSub, ${defaultThemeStyles.backgroundSub})`,
        border: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
}

addStyles(styles)
