import { addStyles } from 'ethcss'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { defaultThemeStyles } from './DraftDeviceInformation-theme'
import { grid, IStyles } from 'theme'
import { mediaCSS } from 'theme/media'

export const styles: IStyles = {
    wrapper: {
        ...grid.row,
        height: '80%',
    },
    screenshot: {
        ...grid.w50,
        ...grid.col,
        ...grid.pr_mdPlus,
        ...grid.fullHeight,
        [mediaCSS.tab]: {
            ...grid.w100,
            height: 'auto',
            ...grid.pr_none,
            ...grid.mb_md,
        },
    },
    params: {
        ...grid.w50,
        ...grid.col,
        ...grid.pl_mdPlus,
        ...grid.fullHeight,
        position: 'relative',
        [mediaCSS.tab]: {
            ...grid.w100,
            ...grid.row,
            height: 'auto',
            ...grid.pl_none,
            ...grid.pb_md,
            ...grid.mt_mdPlus,
        },
    },
    image: {
        boxShadow: '0px 3px 13.5px 1.5px rgba(0, 0, 0, 0.5)',
    },
    imageContent: {
        backgroundColor: `var(--var-module-device-component-info-style-imageContent, ${defaultThemeStyles.imageContent})`,
    },
    icons: {
        ...grid.row,
    },
    paramsList: {
        ...grid.row,
        overflowY: 'auto',
        overflowX: 'hidden',
        border: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        '& .displaysInfo__paramsItem:last-child': {
            border: 'none',
        },
    },
    paramsItem: {
        ...grid.p_micro,
        ...grid.row,
        ...grid.fullWidth,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    topInfo: {
        height: '20%',
        ...grid.row,
        ...grid.space,
        ...grid.pt_big,
        [mediaCSS.tab]: {
            height: 'auto',
        },
    },
    copyToBufferIconWrapper: {
        position: 'absolute',
        top: -36,
        right: 0,
    },
    displayInfoParameterValue: {
        ...grid.w55,
        wordWrap: 'break-word',
        cursor: 'copy',
    },
}

addStyles(styles)
