import React, { useRef } from 'react'
import EditText from 'blocks.simple/editText/editText'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import Dropzone from 'react-dropzone'
import Button from 'blocks.simple/button/button'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import template from 'es6-template-strings'
import { cn } from 'ethcss'
import styles from './tableEditor__data.jcss'
import translate from 'core/translate'
import grid from 'blocks.simple/grid/grid.jcss'
import { Typography } from 'atoms/Typography'

interface ITableEditorDataProps {
    small: boolean
    connectedData: {
        columnProcessing: {
            fieldDelimiter: string
            fromColumn: {
                enabled: boolean
                value: string
            }
            toColumn: {
                enabled: boolean
                value: string
            }
        }
        pathToFile: string
        trimRows: boolean
        removeEmptyRows: {
            enabled: boolean
        }
        rowProcessing: {
            fromRow: {
                enabled: boolean
                value: string
            }
            toRow: {
                enabled: boolean
                value: string
            }
        }
    }
    onChange: (columnProcessing: any, value: string) => void
    isLoadingPreview: boolean
    onChangeEncoding: ({ id }: { id: string }) => void
    encodings: {
        id: string
    }[]
    encoding: string
    onChangeBindData: (e: any) => void
}

const TableEditorData = (p_: ITableEditorDataProps) => {
    const dropzoneRef = useRef<any>(null)
    const onLoadPreviewClick = () => {
        dropzoneRef.current.open()
    }

    return (
        <div>
            <div className={styles.row}>
                <div className={styles.panel}>
                    <Typography className={cn(styles.row)} type="title">
                        {translate('loadPreview')}
                    </Typography>
                    <div className={cn(grid.row, grid.pl_md, grid.pr_md, grid.pt_md)}>
                        <EditText
                            type={'text'}
                            containerClass={cn(grid.w20)}
                            value={p_.connectedData.columnProcessing.fieldDelimiter}
                            label={translate('fieldDelimiter')}
                            onChange={(fieldDelimiter: string) => {
                                const columnProcessing = {
                                    ...p_.connectedData.columnProcessing,
                                    fieldDelimiter,
                                }

                                p_.onChange(columnProcessing, 'columnProcessing')
                            }}
                            indentSize={'mini'}
                            rounded={'full_md'}
                        />
                        <Button
                            onClick={onLoadPreviewClick}
                            mod="fill"
                            className={cn(grid.ml_mini, grid.mt_md)}
                            disabled={p_.isLoadingPreview}
                        >
                            {translate('chooseFile')}
                        </Button>

                        <div className={cn(grid.w100, grid.mt_mini)}>
                            <Dropdown
                                label={translate('encoding')}
                                indentSize={'mini'}
                                onChange={(selected) => {
                                    p_.onChangeEncoding(selected)

                                    setTimeout(() => {
                                        p_.onChange(selected.id, 'fileEncoding')
                                    }, 0)
                                }}
                                options={p_.encodings}
                                value={p_.encoding}
                            />
                        </div>
                    </div>
                    <Dropzone
                        //className={cn(styles.dropzoneHide)}
                        ref={dropzoneRef}
                        onDrop={p_.onChangeBindData}
                        accept={{ 'text/csv': ['.csv'] }}
                    >
                        {() => <></>}
                    </Dropzone>
                </div>
            </div>
            <div className={cn(styles.row)}>
                <EditText
                    type={'text'}
                    value={p_.connectedData.pathToFile}
                    placeholder={'D:\\example\\file.csv'}
                    label={template(translate('localDataPath'), { type: 'csv' })}
                    onChange={(value: { fieldDelimiter: string }) => p_.onChange(value, 'pathToFile')}
                    indentSize={'mini'}
                    rounded={'full_md'}
                />
            </div>
            <div className={styles.row}>
                <Checkbox
                    label={translate('trimRows')}
                    checked={p_.connectedData.trimRows}
                    onClick={(value: boolean) => p_.onChange(value, 'trimRows')}
                />
            </div>
            <div className={styles.row}>
                <Checkbox
                    label={translate('removeEmptyRows')}
                    checked={p_.connectedData.removeEmptyRows.enabled}
                    onClick={(enabled: boolean) =>
                        p_.onChange({ ...p_.connectedData.removeEmptyRows, enabled }, 'removeEmptyRows')
                    }
                />
            </div>
            <div className={styles.row}>
                <div className={cn(styles.panel)}>
                    <div className={styles.title}>
                        <Checkbox
                            checked={p_.connectedData.rowProcessing.fromRow.enabled}
                            onClick={(enabled: boolean) => {
                                const rowProcessing = {
                                    ...p_.connectedData.rowProcessing,
                                    fromRow: {
                                        ...p_.connectedData.rowProcessing.fromRow,
                                        enabled,
                                    },
                                    toRow: {
                                        ...p_.connectedData.rowProcessing.toRow,
                                        enabled,
                                    },
                                }

                                p_.onChange(rowProcessing, 'rowProcessing')
                            }}
                            label={translate('rowProcessing')}
                        />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.description}>{translate('fromRow')}</div>
                        <EditText
                            type={'number'}
                            disabled={!p_.connectedData.rowProcessing.fromRow.enabled}
                            containerClass={cn(grid.space)}
                            value={p_.connectedData.rowProcessing.fromRow.value}
                            onChange={(value: boolean) => {
                                const rowProcessing = {
                                    ...p_.connectedData.rowProcessing,
                                    fromRow: {
                                        ...p_.connectedData.rowProcessing.fromRow,
                                        value,
                                    },
                                }

                                p_.onChange(rowProcessing, 'rowProcessing')
                            }}
                            indentSize={'mini'}
                            rounded={'full_md'}
                        />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.description}>{translate('toRow')}</div>
                        <EditText
                            type={'number'}
                            disabled={!p_.connectedData.rowProcessing.toRow.enabled}
                            containerClass={cn(grid.space)}
                            value={p_.connectedData.rowProcessing.toRow.value}
                            onChange={(value: string) => {
                                const rowProcessing = {
                                    ...p_.connectedData.rowProcessing,
                                    toRow: {
                                        ...p_.connectedData.rowProcessing.toRow,
                                        value,
                                    },
                                }

                                p_.onChange(rowProcessing, 'rowProcessing')
                            }}
                            indentSize={'mini'}
                            rounded={'full_md'}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.row}>
                <div className={cn(styles.panel)}>
                    <div className={styles.title}>
                        <Checkbox
                            checked={p_.connectedData.columnProcessing.fromColumn.enabled}
                            onClick={(enabled: boolean) => {
                                const columnProcessing = {
                                    ...p_.connectedData.columnProcessing,
                                    fromColumn: {
                                        ...p_.connectedData.columnProcessing.fromColumn,
                                        enabled,
                                    },
                                    toColumn: {
                                        ...p_.connectedData.columnProcessing.toColumn,
                                        enabled,
                                    },
                                }

                                p_.onChange(columnProcessing, 'columnProcessing')
                            }}
                            label={translate('columnProcessing')}
                        />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.description}>{translate('fromColumn')}</div>
                        <EditText
                            type={'number'}
                            disabled={!p_.connectedData.columnProcessing.fromColumn.enabled}
                            containerClass={cn(grid.space)}
                            value={p_.connectedData.columnProcessing.fromColumn.value}
                            onChange={(value: boolean) => {
                                const columnProcessing = {
                                    ...p_.connectedData.columnProcessing,
                                    fromColumn: {
                                        ...p_.connectedData.columnProcessing.fromColumn,
                                        value,
                                    },
                                }

                                p_.onChange(columnProcessing, 'columnProcessing')
                            }}
                            indentSize={'mini'}
                            rounded={'full_md'}
                        />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.description}>{translate('toColumn')}</div>
                        <EditText
                            type={'number'}
                            disabled={!p_.connectedData.columnProcessing.toColumn.enabled}
                            containerClass={cn(grid.space)}
                            value={p_.connectedData.columnProcessing.toColumn.value}
                            onChange={(value: boolean) => {
                                const columnProcessing = {
                                    ...p_.connectedData.columnProcessing,
                                    toColumn: {
                                        ...p_.connectedData.columnProcessing.toColumn,
                                        value,
                                    },
                                }

                                p_.onChange(columnProcessing, 'columnProcessing')
                            }}
                            indentSize={'mini'}
                            rounded={'full_md'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableEditorData
