import React from 'react'
import ScheduleSelectMethods, { IScheduleSelectProps } from './schedule__select.local.methods'
import Catalog from 'blocks.app/catalog/catalog'
import Sidebar from 'blocks.app/sidebar/sidebar'
import { cn } from 'ethcss'
import styles from './schedule__select.jcss'
import translate from 'core/translate'
import { grid, item } from '../../../theme'
import Button from 'blocks.simple/button/button'
import { Steps } from 'atoms/Steps/Steps-view'
import { isHorizontalMenu } from 'core/helpers/menu'

const ScheduleSelect = (p_: IScheduleSelectProps) => {
    const {
        step,
        set_step,
        steps,
        emptyText,
        emptyDescription,
        onSelect,
        getContent,
        isEmpty,
        showSchedule,
        selected_displays,
        selected_broadcasts,
    } = ScheduleSelectMethods(p_)

    const horizontalMenu = isHorizontalMenu()

    return (
        <div
            className={styles.wrapper}
            style={{
                position: horizontalMenu ? 'absolute' : 'relative',
                top: horizontalMenu ? '65px' : 'auto',
            }}
        >
            <div className={styles.contentWrapper}>
                <div className={styles.fullContent}>
                    <Steps active={step}>
                        {steps.map((step, index) => (
                            <div key={`scheduleSelectStep_${index}`} id={step.id} onClick={() => set_step(step.id)}>
                                {translate(step.id)}
                            </div>
                        ))}
                    </Steps>
                    <Catalog
                        key={step}
                        type={step}
                        selectedItems={step === 'displays' ? selected_displays : selected_broadcasts}
                        onSelect={onSelect}
                        useWindow={false}
                        wrapperClassName={horizontalMenu ? styles.content_x : styles.content}
                        breadcrumbsClassName={cn(styles.breadcrumbs)}
                        tilesWrapperClassName={styles.tilesWrapper}
                    />
                </div>
            </div>
            <div className={styles.toolbar}>
                <Sidebar
                    contentWrapperClassName={item.overNone}
                    selectedSide={'right'}
                    content={{
                        right: getContent(),
                    }}
                    isEmpty={{
                        right: isEmpty(),
                    }}
                    emptyText={{
                        right: {
                            title: translate(emptyText[step]),
                            description: translate(emptyDescription[step]),
                        },
                    }}
                    onChange={onSelect}
                    bottomContent={{
                        right: (
                            <div className={cn(grid.rowCenter, grid.pt_md, grid.pb_md)}>
                                <Button mod={'fill'} indentSize={'mini'} rounded={'full_normal'} onClick={showSchedule}>
                                    {translate('showSchedule')}
                                </Button>
                            </div>
                        ),
                    }}
                />
            </div>
        </div>
    )
}

export default ScheduleSelect
