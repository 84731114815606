import React, { ChangeEvent, useEffect, useState } from 'react'
import Autocomplete from '../__autocomplete/autocomplete'
import EditText from 'blocks.simple/editText/editText'
import enhanceWithClickOutside from 'react-click-outside'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './searchbar__search.jcss'
import { isExist } from 'core/utils/coreUtil'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { connect } from 'react-redux'
import { themeFromStore } from 'core/helpers/menu'
import validator from 'validator'

interface IState {
    value: string
    isOpen: boolean
    active: boolean
}
interface ISearchProps {
    id: number
    onClickOutside: (element: any, value: boolean) => void
    onChange: (value?: string) => void
    search: any
    onFocus: (e: InputEvent) => void
    onClick: (e: string) => void
    onBlur: (e: string) => void
    enableMobileSearch: boolean
    searchIsOpened: boolean
    placeholder: string
    view: string
    mod: string
    className: string | object
    sort: string
    isMobile: boolean
    icon: object
    activeAdvancedSearchIconColor: string
    iconActiveColor: string
    iconColor: string
    onSearchIconClick: () => void
    searchToggle: () => void
    iconTooltip: object
    value: string
    rounded: string
    filter: any
}

let timeout: any = null

const Search = (p_: ISearchProps) => {
    const getValue = (value: string) => {
        if (!value) {
            return ''
        }

        return value
    }
    const [state, setState] = useState<IState>({
        value: getValue(p_.value),
        isOpen: false,
        active: false,
    })

    let prevProps: ISearchProps = p_
    let typing = false

    useEffect(() => {
        if (!typing && prevProps.value !== p_.value) {
            setState((prevState) => {
                return { ...prevState, value: getValue(p_.value) }
            })
        }

        prevProps = p_
    }, [p_])

    const handleClickOutside = () => {
        if (p_.onClickOutside) {
            p_.onClickOutside(null, false)
        }

        setState((prevState) => {
            return { ...prevState, isOpen: false }
        })
    }
    const changeSearch = (value: string) => {
        typing = true
        value = getValue(value)

        setState((prevState) => {
            return { ...prevState, value }
        })
        clearTimeout(timeout)

        timeout = setTimeout(() => {
            typing = false
            if (p_.onChange) {
                p_.onChange(value)
            }
        }, 600)
    }
    const selectItem = (searchItem: any, type: string) => {
        setState((prevState) => {
            return { ...prevState, isOpen: false }
        })

        if (type === 'places') {
            p_.search.onSelect({
                ...searchItem,
                type,
            })
        } else if (type === 'displays') {
            p_.search.onSelect({
                ...searchItem,
                id: searchItem.id,
                name: searchItem.name || searchItem.title,
                location: {
                    lat: searchItem.lat,
                    lng: searchItem.lng,
                },
                type,
            })
        } else {
            p_.search.onSelect({
                id: searchItem.id,
                name: searchItem.name || searchItem.title,
                type,
            })
        }
    }
    const clearFilter = (target: { focus: () => void }) => {
        if (state.value) {
            if (p_.onChange) {
                p_.onChange(undefined)
            }
            target.focus()
            setState((prevState) => {
                return { ...prevState, value: '' }
            })
        }
    }
    const onFocus = (e: InputEvent) => {
        setState((prevState) => {
            return { ...prevState, active: true }
        })
        if (p_.onFocus) {
            p_.onFocus(e)
        }
        if (p_.search) {
            setState((prevState) => {
                return { ...prevState, isOpen: true }
            })
        }
    }
    const onBlur = (e: any) => {
        setState((prevState) => {
            return { ...prevState, active: false }
        })
        if (p_.onBlur) {
            p_.onBlur(e.target.value)
        }
    }
    const onClick = (e: MouseEvent) => {
        if (p_.onClick) {
            p_.onClick((e.target as HTMLInputElement).value)
        }
    }

    const getSearchIconName = () => {
        if (p_.enableMobileSearch) {
            return p_.searchIsOpened ? 'close' : 'search'
        }

        return state.value ? 'close' : 'search'
    }

    const placeholder = isExist(p_.placeholder) ? p_.placeholder : translate('searchLabel')

    return (
        <div className={cn(styles.search)}>
            <EditText
                id={p_.id}
                className={cn(
                    styles.input,
                    p_.filter || p_.view || p_.sort ? '' : styles.fullRadius,
                    p_.className,
                    p_.enableMobileSearch && !p_.searchIsOpened
                        ? themeFromStore() !== 'smartplayer'
                            ? styles.hideTextNotSmartplayerTheme
                            : styles.hideText
                        : ''
                )}
                mod={p_.mod ? p_.mod : 'default'}
                type="text"
                value={state.value}
                placeholder={p_.isMobile && p_.enableMobileSearch ? '' : placeholder}
                onChange={(e: string) => changeSearch(e)}
                onFocus={onFocus}
                onBlur={onBlur}
                icon={p_.icon || getSearchIconName()}
                iconSize="16"
                iconColor={p_.activeAdvancedSearchIconColor || (state.active ? p_.iconActiveColor : p_.iconColor)}
                onIconClick={
                    p_.onSearchIconClick ||
                    ((e: any, target: HTMLDivElement) => {
                        if (p_.searchToggle) {
                            p_.searchToggle()

                            return
                        }
                        clearFilter(target)
                    })
                }
                rounded={p_.rounded}
                autoComplete="off"
                iconTooltip={p_.iconTooltip}
                // fullHeight={true}
            />
            {p_.search && (
                <Autocomplete
                    {...p_.search.props}
                    onChange={p_.search.onChange}
                    onSelect={(element, type) => selectItem(element, type)}
                    isOpen={state.isOpen}
                />
            )}
            {/*{p_.searchElements}*/}
        </div>
    )
}

Search.defaultProps = {
    id: 'searchbar_search',
    iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
    iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
    rounded: 'left_mini',
}

const mapStateToProps = (state: { app: { isMobile: boolean } }) => {
    return {
        isMobile: state.app.isMobile,
    }
}

// @ts-ignore
export default connect(mapStateToProps, null, null, { forwardRef: true })(enhanceWithClickOutside(Search))
