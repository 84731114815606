import { cn } from 'ethcss'
import React, { FC, memo } from 'react'
import { IDropdownLabelProps } from './DropdownLabel-types'
import { styles } from './DropdownLabel-styles'
import Icon from 'blocks.simple/icon/icon'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from '../Dropdown.theme'
import { isExist } from 'core/utils/coreUtil'
import { themeFromStore } from 'core/helpers/menu'
import { useLocation } from 'react-router'
import helpers from 'core/helpers'

const DropdownLabelComponent: FC<IDropdownLabelProps> = ({
    icon,
    iconSide,
    label,
    onClick,
    className,
    activeClassName,
    iconColor,
    activeIconColor,
    isActive,
    mod,
}) => {
    const getLabelClassName = () => {
        return cn(
            styles.DropdownLabel,
            styles[`DropdownLabel_type_${mod}`],
            {
                [styles.DropdownLabel_type_leftIcon]: iconSide === 'left' && isExist(label),
                [styles.DropdownLabel_type_rightIcon]: iconSide === 'right' && isExist(label),
            },
            className
        )
    }

    const getLabelActiveClassName = () => {
        return isActive
            ? cn(styles.DropdownLabel__active, styles[`DropdownLabel_type_${mod}_status_active`], activeClassName)
            : {}
    }

    const getIconClassName = () => {
        return cn(styles.DropdownLabel__icon, {
            [styles.DropdownLabel__icon_type_withLabel]: isExist(label),
            [styles.DropdownLabel__icon_type_left]: iconSide === 'left',
            [styles.DropdownLabel__icon_type_right]: iconSide === 'right',
        })
    }

    const getIconColor = () => {
        return isActive
            ? activeIconColor ||
                  getThemeStyleValue('ui', 'dropdown', `${mod}-activeTextSub`) ||
                  defaultThemeStyles['withShadow-activeTextSub']
            : iconColor ||
                  getThemeStyleValue('ui', 'dropdown', `${mod}-textSub`) ||
                  defaultThemeStyles['withShadow-textSub']
    }

    const location = useLocation()
    const pathname = helpers.getPathname(location.pathname)

    return (
        <div className={cn(getLabelClassName(), getLabelActiveClassName())} onClick={onClick}>
            {icon && (
                <span className={getIconClassName()}>
                    <Icon
                        type={icon}
                        size={14}
                        color={themeFromStore() === 'custom' && pathname !== 'scoreboards' ? 'white' : getIconColor()}
                    />
                </span>
            )}
            <span className={styles.DropdownLabel__text}>{label}</span>
        </div>
    )
}

export const DropdownLabel = memo(DropdownLabelComponent)
