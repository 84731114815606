import React from 'react'
import App from 'blocks.app/app/app'
import { Provider } from 'react-redux'
import { HashRouter, Switch, Route } from 'react-router-dom'
import { renderCss } from 'ethcss'
import { history } from 'features/routes'
import { useRoutes } from 'features/routes/hooks/useRoutes'

class Root extends React.Component {
    constructor(p_) {
        super(p_)

        renderCss(document.getElementById('rootCss'))
    }

    renderRoutes(routes) {
        return routes.map((route) => {
            return <Route key={route.path} path={`/${route.path}`} exact={true} component={route.component} />
        })
    }

    render() {
        const routes = useRoutes({
            isDev: process.env.NODE_ENV === 'development',
        })

        const { store } = this.props

        return (
            <Provider store={store}>
                <HashRouter history={history}>
                    <App>
                        <Switch>{this.renderRoutes(routes)}</Switch>
                    </App>
                </HashRouter>
            </Provider>
        )
    }
}

export default Root
