import { useState, useEffect } from 'react'
import { api } from 'core/api/ConnectionManager'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { defaultThemeStyles as defaultThemeDashboardStyles } from 'organisms/Dashboard/Dashboard-theme'
import { colors } from '../../../theme'

let componentIsMounted: boolean = false
const getColorForDevicesNumber = (key: string) => {
    switch (key) {
        case 'online':
            return colors.sCBlue
        case 'error':
            return `var(--var-module-dashboard-component-dashboard-style-error, ${defaultThemeDashboardStyles.error})`
        case 'offline':
            return `var(--var-module-dashboard-component-dashboard-style-offline, ${defaultThemeDashboardStyles.offline})`
        case 'updating':
            return '#cecece'
        case 'notSynchronized':
            return '#cecece'
        default:
            return getThemeStyleValue('app', 'app', 'textMain') || defaultThemeStyles.textMain
    }
}

interface IData {
    stats: any
    devicesCount: number
}

const initialState = {
    stats: null,
    devicesCount: 0,
}

function DashboardScoreboardDevicesMethods() {
    const [data, setData] = useState<IData>(initialState)

    useEffect(() => {
        componentIsMounted = true
        getDigitalSignageStats()

        return () => {
            componentIsMounted = false
        }
    }, [])

    function getDigitalSignageStats() {
        api.send('getScoreboardsStats', {}, { showEmitError: false }).then((res: any) => {
            if (!componentIsMounted) {
                return
            }

            const stats = []
            let devicesCount = 0

            for (let key in res) {
                if (res.hasOwnProperty(key)) {
                    const value = res[key]

                    stats.push({
                        label: key,
                        color: getColorForDevicesNumber(key),
                        key,
                        value,
                    })

                    devicesCount += value
                }
            }

            setData({ stats, devicesCount })
        })
    }

    return {
        s_: {
            data,
        },
    }
}

export default DashboardScoreboardDevicesMethods
