import React, { FunctionComponent } from 'react'
import { animated as Animated } from 'react-spring'
import { IButtonComponent } from './Button-types'
import { useButton } from './Button-hooks'
import { cn } from 'ethcss'
import { item, text, grid } from 'theme'
import { buttonStyles } from './Button-styles'

export const Button: FunctionComponent<IButtonComponent> = ({
    className,
    contentClassName = buttonStyles.content,
    disabled,
    mod = 'default',
    animated = true,
    textPosition = 'center',
    textSize = 'normal',
    rounded = 'full_micro',
    indent = 'normal',
    fullWidth = false,
    children,
    onClick,
    isActive = false,
}) => {
    const buttonHooks = useButton({ animated, onClick })

    return (
        <Animated.button
            className={cn(
                buttonStyles.wrapper,
                className,
                item[`rounded_${rounded}`],
                buttonStyles[`button_type_${mod}`],
                item[`indent_${indent}`],
                {
                    [buttonStyles[`button_type_${mod}_activated`]]: isActive,
                    [grid.w100]: fullWidth,
                }
            )}
            disabled={disabled}
            onClick={buttonHooks.handleClick}
            style={{
                transform: buttonHooks.spring.x
                    .interpolate({
                        range: [0, 0.5, 1],
                        output: [1, 0.9, 1],
                    })
                    .interpolate((x: number) => `scale(${x})`),
            }}
        >
            <div className={cn(contentClassName, text[textSize], text[textPosition])}>
                <div className={cn(item.ellipsisPure)}>{children}</div>
            </div>
        </Animated.button>
    )
}
