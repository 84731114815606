import { addStyles } from 'ethcss'
import { grid, item, text } from 'theme'

const editTextStyles = {
    wrapper: {
        ...grid.w100,
        ...item.rel,
    },
    hoverIconWrapper() {
        return {
            [`& .${this.icon}`]: {
                ...item.hidden,
            },
            [`&:hover .${this.icon}`]: {
                ...item.visible,
            },
        }
    },
    input: {
        ...grid.w100,
        ...grid.rowCenter,
        ...item.ellipsisPure,
        ...text.bold,
        outline: 'none',
        boxShadow: 'none',
        border: 'none',
        '&[type="number"]': {
            '-moz-appearance': 'textfield',
        },
        '&::-webkit-inner-spin-button, ::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
        },
        '&:disabled': {
            ...item.disabled,
        },
        '&::placeholder': {
            fontWeight: 'normal',
            color: 'inherit',
            opacity: 0.3,
        },
    },
    icon: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.fullHeight,
        ...grid.pl_microPlus,
        ...item.abs,
        ...item.pointer,
        right: 10,
        top: 0,
    },
    label: {
        ...grid.row,
        ...grid.mb_micro,
    },
    disabled: {
        ...item.disabled,
    },
    inputClassName: {
        ...item.rel,
    },
    statusLine: {
        ...grid.w100,
        ...item.abs,
        height: '2px',
        left: 0,
        bottom: 0,
        backgroundColor: 'darkBlue',
    },
    wrapperInput: {
        ...item.overNone,
    },
    statusMessage: {
        ...item.abs,
        ...text.right,
        ...item.ellipsisPure,
        right: 0,
        bottom: -15,
        fontSize: 12,
    },
}

addStyles(editTextStyles)
export default editTextStyles
