import React, { FC, useEffect, useState } from 'react'
// import { grid } from 'theme'
// import Collapse from 'blocks.simple/collapse/collapse'
// import { getThemeStyleValue } from 'theme/colors'
// import { defaultThemeStyles } from './BroadcastItem.theme'
import { styles } from './BroadcastItem-styles'
import Icon from 'blocks.simple/icon/icon'
//import { cn } from 'ethcss'
//import translate from 'core/translate'
import { Typography } from '../../../../../../atoms/Typography'
import { DownloadItemView } from '../DownloadItem/DownloadItem-view'
import { api } from '../../../../../../core/api/ConnectionManager'

export const BroadcastItemView = ({ broadcastData, isOpened, broadcastHandleClick, digitalSignageId }: any) => {
    const [broadcastFilesList, setBroadcastFilesList] = useState<any>([])
    const { broadcastId, name, totalSizeBytes, downloadedBytes, downloadedPercent, status } = broadcastData
    const listenersId: any = []

    const renderStatus = (status: string) => {
        switch (status) {
            case 'downloading':
                return <Typography>{downloadedPercent}%</Typography>
            case 'downloaded':
                return <Icon type="done" color="#1EC686" />
            case 'downloadedMultiply':
                return <Icon type="doneMultiply" color="#1EC686" />
            case 'error':
                return <Icon type="errorWarning" color="#D61750" />
        }
    }

    const getBroadcastListInfo = () => {
        api.send('requestBroadcastInfoWithFiles', {
            digitalSignageId,
            broadcastId,
        })
    }

    useEffect(() => {
        api.addObserver(
            'requestBroadcastInfoWithFilesSent',
            (data: any) => {
                if (!data) return

                if (data.broadcast.broadcastId === broadcastId && data.digitalSignageId === digitalSignageId) {
                    setBroadcastFilesList(data.broadcast.files)
                }
            },
            listenersId
        )

        return () => {
            listenersId.forEach((id: any) => api.removeObserver(id))
        }
    }, [])

    useEffect(() => {
        if (!isOpened) {
            if (broadcastFilesList.length) {
                setBroadcastFilesList([])
            }

            return
        }

        if (digitalSignageId && broadcastId) {
            getBroadcastListInfo()
        }
    }, [isOpened, broadcastData])

    return (
        <li className={styles.broadcastItem} onClick={() => broadcastHandleClick(broadcastId)}>
            <div className={styles.broadcastItemHeader}>
                <div className={styles.broadcastItemLeft}>
                    <Icon type="camera" color="#b3b3b3" className={styles.icon} />
                    <Icon
                        type="openArrow"
                        color="#b3b3b3"
                        className={`${styles.icon} ${isOpened ? styles.opened : ''}`}
                    />
                    <Typography className={styles.broadcastName}>{name}</Typography>
                </div>
                <div className={styles.broadcastItemRight}>
                    <div className={styles.status}>{renderStatus(status)}</div>
                </div>
            </div>
            <ul>
                {broadcastFilesList.map((broadcastFile: any, index: number) => {
                    return <DownloadItemView key={index} broadcastFile={broadcastFile} />
                })}
            </ul>
        </li>
    )
}
