import React from 'react'
import Button from 'blocks.simple/button/button'
import ItemAdvertisingCampaigns from '../../__item/_advertisingCampaigns/catalog__item_advertisingCampaigns'
import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'
import translate from 'core/translate'
import { routes } from 'features/routes'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { changeLocation } from 'features/routes'
import { ItemData } from '../../../../core/models/Template'

export default {
    getMethod: 'getFillersAdBlock',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noAdBlocks'),
    emptyIcon: 'not_allow',
    addItemButton: () => (
        <Button rounded="full_md" mod="withShadow" onClick={() => changeLocation(`/${routes.addFillersAdBlock.path}`)}>
            {translate('add')}
        </Button>
    ),
    tile: ItemAdvertisingCampaigns,
    settingsPrefixes: 'fillersAdBlocks',
    sortOptions: () => [
        { id: '-createdAt', name: translate('sortCreatedAt') },
        { id: 'title', name: translate('byNameAsc') },
    ],
    viewOptions: () => [
        {
            id: 'tile',
            name: translate('tile'),
            icon: 'tile',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
        {
            id: 'list',
            name: translate('list'),
            icon: 'list',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
        },
    ],
    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean) => {
        const item = { ...dataItem }
        item.cover = helpers.getBroadcastCover(item.coverSource)

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    getBackendModel: (item: { id: number }) => {
        return api.send('getFillersAdBlockById', { id: item.id }, { hideLoader: true })
    },
    isEqual: (firstItem: ItemData, secondItem: ItemData) => firstItem.id === secondItem.id,
    onCreateListeners: [(cb: any, clearList: string[]) => api.addObserver('fillersAdBlockCreated', cb, clearList)],
    onUpdateListeners: [(cb: any, clearList: string[]) => api.addObserver('fillersAdBlockUpdated', cb, clearList)],
    onDeleteListeners: [(cb: any, clearList: string[]) => api.addObserver('fillersAdBlockDeleted', cb, clearList)],
    isFolder: () => {},
    fields: () => [{ id: 'title', name: translate('name') }],
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        name: () => {
            return null
        },
    },
}
