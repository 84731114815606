import { isExist, isNotEmptyArray } from 'core/utils/coreUtil'
import { MAP_CENTER, MAP_ZOOM } from 'features/map/constants'
import { MapLocation, SearchableMapProps, YandexBoundsChangeEvent } from 'features/map/models/map'
import React, { FC, useState } from 'react'
import { YMaps, Map as YMap, Placemark } from 'react-yandex-maps'
import { styles } from './SearchableYandexMap-styles'
import MarkerUrl from '../../images/marker.svg'
import { SearchBar } from 'molecules/SearchBar'
import Button from 'blocks.simple/button/button'
import { MapLocationList } from 'features/map/atoms/MapLocationList'
import { cn } from 'ethcss'
import translate from 'core/translate'

const MAP_MODULES = ['control.ZoomControl', 'templateLayoutFactory', 'layout.ImageWithContent']
const MAP_SEARCHBAR_ID = 'map_search_bar'

export const SearchableYandexMap: FC<SearchableMapProps> = ({
    center,
    location,
    zoom = MAP_ZOOM,
    onChangeLocation,
    onChangeAddress,
    mapApi,
}) => {
    const [mapLocationList, setMapLocationList] = useState<MapLocation[]>([])
    const { name } = location
    let mapRef: any

    const getMapCenter = () => {
        if (!isExist(center)) return [MAP_CENTER.lat, MAP_CENTER.lng]

        return center && center.lat && center.lng ? [center.lat, center.lng] : [MAP_CENTER.lat, MAP_CENTER.lng]
    }

    const getMapLocation = () => {
        if (!isExist(location)) return [MAP_CENTER.lat, MAP_CENTER.lng]

        return location.lat && location.lng ? [location.lat, location.lng] : [MAP_CENTER.lat, MAP_CENTER.lng]
    }

    const onMapLocationSelect = (item: MapLocation) => {
        const { lat, lng } = item

        clearMapLocationList()
        onChangeLocation(item)
        mapRef.setCenter([lat, lng])
    }

    const clearMapLocationList = () => {
        setMapLocationList([])
    }

    const isMapLocationListExist = () => mapLocationList.length > 0

    const renderMapLocationList = () => {
        return (
            <div className={styles.searchableYandexMap__mapLocationList}>
                <MapLocationList list={mapLocationList} onSelect={onMapLocationSelect} />
            </div>
        )
    }

    const geocodeLocation = () => {
        mapApi
            .geocodeLocation(name)
            .then((mapLocationList) => {
                setMapLocationList(mapLocationList)
            })
            .catch(() => {
                clearMapLocationList()
            })
    }

    const createMapRef = (yandexMapApi: any) => {
        mapRef = yandexMapApi
    }

    const getLatLngFromCoordinate = (coordinate: any) => {
        return {
            lat: coordinate[0],
            lng: coordinate[1],
        }
    }

    const getDefaultMapState = () => {
        return {
            center: getMapCenter(),
            zoom,
        }
    }

    const onDragEnd = (e: any) => {
        let newCoordinates = e?.get('target')?.geometry?.getCoordinates()

        if (!isExist(newCoordinates)) return

        const { lat, lng } = getLatLngFromCoordinate(newCoordinates)

        mapApi
            .reverseGeocodeLocation(lat, lng)
            .then((res) => {
                if (!isExist(res) || !isNotEmptyArray(res)) return

                const closestPosition = res[0]
                onChangeLocation(closestPosition)
            })
            .catch(() => {})
    }

    const onDragStart = (e: any) => {
        clearMapLocationList()
    }

    const renderPlacemark = () => {
        return (
            <Placemark
                geometry={getMapLocation()}
                options={{
                    iconLayout: 'default#image',
                    iconImageHref: MarkerUrl,
                    draggable: true,
                    iconImageSize: [40, 40],
                    iconImageOffset: [-20, -30],
                }}
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}
            />
        )
    }

    const renderActionBar = () => {
        return (
            <div className={styles.searchableYandexMap__actionBar}>
                <div className={styles.searchableYandexMap__searchBar}>
                    <SearchBar
                        id={MAP_SEARCHBAR_ID}
                        value={name}
                        onSearch={onChangeAddress}
                        classNames={cn({
                            [styles.searchableYandexMap__searchBar_type_enable]: isMapLocationListExist(),
                        })}
                    />
                    {isMapLocationListExist() && renderMapLocationList()}
                </div>
                <div className={styles.searchableYandexMap__button}>
                    <Button mod="fill" onClick={geocodeLocation}>
                        {translate('search')}
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.searchableYandexMap}>
            {renderActionBar()}

            <YMaps>
                <YMap
                    className={styles.searchableYandexMap}
                    defaultState={getDefaultMapState()}
                    modules={MAP_MODULES}
                    instanceRef={createMapRef}
                >
                    {renderPlacemark()}
                </YMap>
            </YMaps>
        </div>
    )
}
