import { addStyles } from 'ethcss'
import { grid, indents, IStyles, mediaCSS, radiusSizes, textSizes } from 'theme'
import { defaultThemeStyles } from './ProductsActionBar.theme'

export const styles: IStyles = {
    ProductsActionBar__form: {
        ...grid.row,
        padding: `0 ${indents.mdPlus}px 0`,
        border: `1px solid var(--var-module-ui-component-actionBar-style-border, ${defaultThemeStyles.border})`,
    },
    ProductsActionBar__form_x: {
        padding: '0 !important',
    },
    margin_x: {
        marginTop: '35px !important',
    },
    ProductsActionBar__sort: {
        ...grid.w20,
        position: 'relative',
        [mediaCSS.tabMini]: {
            ...grid.space,
        },
    },
    ProductsActionBar__delimer_type_sort: {
        [mediaCSS.tabMini]: {
            display: 'none',
        },
    },
    ProductsActionBar__selection: {
        ...grid.w15,
        position: 'relative',
        [mediaCSS.tabMini]: {
            ...grid.space,
        },
    },
    ProductsActionBar__delimer: {
        ...grid.fullHeight,
        position: 'absolute',
        borderLeft: `1px solid var(--var-module-ui-component-actionBar-style-separator, ${defaultThemeStyles.separator})`,
        width: 0,
        zIndex: 101,
    },
    ProductsActionBar__search: {
        ...grid.space,
        position: 'relative',
        [mediaCSS.tabMini]: {
            ...grid.noSpace,
            ...grid.w100,
            ...grid.mb_mini,
            display: 'block !important',
        },
    },
    ProductsActionBar__searchbar: {
        borderBottomLeftRadius: `${radiusSizes.mini}px!important`,
        borderTopLeftRadius: `${radiusSizes.mini}px!important`,
        borderBottomRightRadius: `0px!important`,
        borderTopRightRadius: `0px!important`,
        [mediaCSS.tabMini]: {
            borderBottomRightRadius: `${radiusSizes.mini}px!important`,
            borderTopRightRadius: `${radiusSizes.mini}px!important`,
        },
    },
    ProductsActionBar__dropdown_type_sort: {
        borderBottomRightRadius: `${radiusSizes.mini}px`,
        borderTopRightRadius: `${radiusSizes.mini}px`,
        [mediaCSS.tabMini]: {
            borderBottomLeftRadius: `${radiusSizes.mini}px!important`,
            borderTopLeftRadius: `${radiusSizes.mini}px!important`,
        },
    },
    ProductsActionBar__dropdown_type_sort_status_active: {
        borderBottomRightRadius: `0px`,
    },
}

addStyles(styles)
