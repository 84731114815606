import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { IStyles } from '../../../theme'

const textEditorSettingsStyles: IStyles = {
    settings: {
        ...grid.p_md,
    },
    options: {
        ...grid.row,
        marginLeft: -grid.m_md['margin'],
    },
    option: {
        flex: '1 1 350px',
        ...grid.mb_md,
        ...grid.ml_md,
    },
}

addStyles(textEditorSettingsStyles)
export default textEditorSettingsStyles
