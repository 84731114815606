import React, { useEffect, useState } from 'react'
import translate from 'core/translate'
import item from 'blocks.simple/item/item.jcss'
import settingsStyles from '../__areaSettings/editor__areaSettings.jcss'
import styles from './editor__animationSettings.jcss'
import Collapse from 'blocks.simple/collapse/collapse'
import { Switch } from '../../../atoms/Switch'
import { cn } from 'ethcss'
import Dropdown from '../../../blocks.simple/dropdown/dropdown'
import EditText from '../../../blocks.simple/editText/editText'
import { Preview } from './editor__animationPreview'
import Icon from '../../../blocks.simple/icon/icon'
import { colors } from '../../../theme'

const startContentOptions = [
    { id: 'fadeIn', name: translate('fadeIn') },
    { id: 'fadeInDown', name: translate('fadeInDown') },
    { id: 'fadeInLeft', name: translate('fadeInLeft') },
    { id: 'fadeInRight', name: translate('fadeInRight') },
    { id: 'fadeInUp', name: translate('fadeInUp') },
    { id: 'fadeInTopLeft', name: translate('fadeInTopLeft') },
    { id: 'fadeInTopRight', name: translate('fadeInTopRight') },
    { id: 'fadeInBottomLeft', name: translate('fadeInBottomLeft') },
    { id: 'fadeInBottomRight', name: translate('fadeInBottomRight') },

    { id: 'backInDown', name: translate('backInDown') },
    { id: 'backInLeft', name: translate('backInLeft') },
    { id: 'backInRight', name: translate('backInRight') },
    { id: 'backInUp', name: translate('backInUp') },

    { id: 'bounceIn', name: translate('bounceIn') },
    { id: 'bounceInDown', name: translate('bounceInDown') },
    { id: 'bounceInRight', name: translate('bounceInRight') },
    { id: 'bounceInUp', name: translate('bounceInUp') },

    { id: 'lightSpeedInRight', name: translate('lightSpeedInRight') },
    { id: 'lightSpeedInLeft', name: translate('lightSpeedInLeft') },

    { id: 'rotateIn', name: translate('rotateIn') },
    { id: 'rotateInDownLeft', name: translate('rotateInDownLeft') },
    { id: 'rotateInDownRight', name: translate('rotateInDownRight') },
    { id: 'rotateInUpLeft', name: translate('rotateInUpLeft') },
    { id: 'rotateInUpRight', name: translate('rotateInUpRight') },

    { id: 'slideInRight', name: translate('slideInRight') },
    { id: 'slideInUp', name: translate('slideInUp') },

    { id: 'noAnimate', name: translate('noAnimate') },
]
const endContentOptions = [
    { id: 'fadeOut', name: translate('fadeOut') },
    { id: 'fadeOutDown', name: translate('fadeOutDown') },
    //{ id: 'fadeOutDownBig', name: translate('fadeOutDownBig') },
    { id: 'fadeOutLeft', name: translate('fadeOutLeft') },
    //{ id: 'fadeOutLeftBig', name: translate('fadeOutLeftBig') },
    { id: 'fadeOutRight', name: translate('fadeOutRight') },
    //{ id: 'fadeOutRightBig', name: translate('fadeOutRightBig') },
    { id: 'fadeOutUp', name: translate('fadeOutUp') },
    //{ id: 'fadeOutUpBig', name: translate('fadeOutUpBig') },
    { id: 'fadeOutTopLeft', name: translate('fadeOutTopLeft') },
    { id: 'fadeOutTopRight', name: translate('fadeOutTopRight') },
    { id: 'fadeOutBottomRight', name: translate('fadeOutBottomRight') },
    { id: 'fadeOutBottomLeft', name: translate('fadeOutBottomLeft') },

    { id: 'backOutDown', name: translate('backOutDown') },
    { id: 'backOutLeft', name: translate('backOutLeft') },
    { id: 'backOutRight', name: translate('backOutRight') },
    { id: 'backOutUp', name: translate('backOutUp') },

    { id: 'bounceOutDown', name: translate('bounceOutDown') },
    { id: 'bounceOutLeft', name: translate('bounceOutLeft') },
    { id: 'bounceOutRight', name: translate('bounceOutRight') },
    { id: 'bounceOutUp', name: translate('bounceOutUp') },

    { id: 'lightSpeedOutRight', name: translate('lightSpeedOutRight') },
    { id: 'lightSpeedOutLeft', name: translate('lightSpeedOutLeft') },

    { id: 'rotateOut', name: translate('rotateOut') },
    { id: 'rotateOutDownLeft', name: translate('rotateOutDownLeft') },
    { id: 'rotateOutUpLeft', name: translate('rotateOutUpLeft') },

    { id: 'zoomOut', name: translate('zoomOut') },
    { id: 'zoomOutDown', name: translate('zoomOutDown') },
    { id: 'zoomOutLeft', name: translate('zoomOutLeft') },
    { id: 'zoomOutRight', name: translate('zoomOutRight') },
    { id: 'zoomOutUp', name: translate('zoomOutUp') },

    { id: 'slideOutDown', name: translate('slideOutDown') },
    { id: 'slideOutLeft', name: translate('slideOutLeft') },
    { id: 'slideOutRight', name: translate('slideOutRight') },
    { id: 'slideOutUp', name: translate('slideOutUp') },

    { id: 'noAnimate', name: translate('noAnimate') },
]
const stateContentOptions = [
    { id: 'pulse', name: translate('pulse') },
    { id: 'bounce', name: translate('bounce') },
    { id: 'flash', name: translate('flash') },
    { id: 'rubberBand', name: translate('rubberBand') },
    { id: 'shakeX', name: translate('shakeX') },
    { id: 'shakeY', name: translate('shakeY') },
    { id: 'swing', name: translate('swing') },
    { id: 'wobble', name: translate('wobble') },
    { id: 'jello', name: translate('jello') },
    { id: 'heartBeat', name: translate('heartBeat') },
    { id: 'noAnimate', name: translate('noAnimate') },
]

const EditorAnimationSettings = (p_) => {
    const [animationState, setAnimationState] = useState(p_.contentItem.animation ? p_.contentItem.animation : null)
    const [stateSpeed, setStateSpeed] = useState(initStateSpeed())
    const stateSpeedStep = () => {
        let duration = p_.contentItem.duration

        if (animationState.in && animationState.in.name) {
            duration -= animationState.in.duration
        }
        if (animationState.out && animationState.out.name) {
            duration -= animationState.out.duration
        }

        const quotient = Math.floor(duration / stateSpeed)

        return Math.round(duration / quotient)
    }

    function initStateSpeed() {
        if (animationState && animationState.state && animationState.state.duration) {
            if (animationState.state.duration > 4000) {
                return 4000
            }
            if (animationState.state.duration < 300) {
                return 300
            }

            return animationState.state.duration
        }

        return 300
    }

    const onChange = (value, key, field) => {
        const _value = value !== 'noAnimate' ? value : null
        const name =
            field !== 'name' && p_.contentItem.animation[key] && p_.contentItem.animation[key].name
                ? p_.contentItem.animation[key].name
                : null
        const duration =
            field !== 'duration' && p_.contentItem.animation[key] && p_.contentItem.animation[key].duration
                ? p_.contentItem.animation[key].duration
                : 300

        setAnimationState((prevState) => {
            return {
                ...prevState,
                [key]: {
                    ...prevState[key],
                    name,
                    duration,
                    [field]: _value,
                },
            }
        })
    }
    const changeUseAnimation = () => {
        setAnimationState((prevState) => {
            return {
                ...prevState,
                useAnimation: !prevState.useAnimation,
            }
        })
    }

    const getSelectedAnimationOption = (type, field) => {
        if (!animationState[type]) {
            if (field === 'name') {
                return 'noAnimate'
            }
            if (field === 'duration') {
                return '300'
            }
            if (field === 'syncQ') {
                return false
            }
        }

        switch (type) {
            case 'in':
                return animationState.in[field] ? animationState.in[field] : 'noAnimate'
            case 'out':
                return animationState.out[field] ? animationState.out[field] : 'noAnimate'
            case 'state':
                return animationState.state[field] ? animationState.state[field] : 'noAnimate'
            default:
                return null
        }
    }
    const getConditionTitle = () => {
        return (
            <div className={settingsStyles.collapseText}>
                <div className={item.ellipsis}>{translate('animation')}</div>
            </div>
        )
    }
    const Content = () => {
        return (
            <div className={styles.animationWrapper}>
                <div className={styles.useAnimation}>
                    Использовать анимацию
                    <Switch onChange={changeUseAnimation} checked={animationState.useAnimation} />
                </div>
                <div className={styles.optionWrapper}>
                    <div className={styles.option}>
                        <Dropdown
                            value={getSelectedAnimationOption('in', 'name')}
                            options={startContentOptions}
                            label={translate('playContentStart')}
                            onChange={(value) => onChange(value.id, 'in', 'name')}
                        />
                    </div>
                    <div className={styles.option}>
                        <EditText
                            type={'number'}
                            containerClass={cn(styles.inputWrapper)}
                            value={getSelectedAnimationOption('in', 'duration')}
                            label={translate('animationTime')}
                            onChange={(value) => onChange(value, 'in', 'duration')}
                            indentSize={'mini'}
                        />
                    </div>
                </div>
                <div className={styles.optionWrapper}>
                    <div className={styles.option}>
                        <Dropdown
                            value={getSelectedAnimationOption('out', 'name')}
                            options={endContentOptions}
                            label={translate('playContentEnd')}
                            onChange={(value) => onChange(value.id, 'out', 'name')}
                        />
                    </div>
                    <div className={styles.option}>
                        <EditText
                            type={'number'}
                            containerClass={cn(styles.inputWrapper)}
                            value={getSelectedAnimationOption('out', 'duration')}
                            label={translate('animationTime')}
                            onChange={(value) => onChange(value, 'out', 'duration')}
                            indentSize={'mini'}
                        />
                    </div>
                </div>
                <div className={styles.optionWrapper}>
                    <div className={styles.option}>
                        <Dropdown
                            value={getSelectedAnimationOption('state', 'name')}
                            options={stateContentOptions}
                            label={translate('playContentState')}
                            onChange={(value) => onChange(value.id, 'state', 'name')}
                        />
                    </div>
                    <div className={styles.option}>
                        <div className={styles.rangeLabel}>Скорость анимации</div>
                        <div className={styles.rangeWrapper}>
                            <Icon type={'turtle'} size={24} color={colors.blueAccent} />
                            <input
                                className={styles.range}
                                type={'range'}
                                min={300}
                                max={4000}
                                step={1}
                                defaultValue={stateSpeed}
                                onInput={(e) => {
                                    setStateSpeed(+e.target.value)
                                }}
                            />
                            <Icon type={'rabbit'} size={24} color={colors.blueAccent} />
                        </div>
                    </div>
                </div>
                <div>
                    <Preview
                        contentItem={p_.contentItem}
                        animation={animationState}
                        useAnimation={animationState.useAnimation}
                    />
                </div>
            </div>
        )
    }

    useEffect(() => {
        let timer = null

        if (JSON.stringify(animationState) !== JSON.stringify(p_.contentItem.animation)) {
            timer = setTimeout(() => {
                p_.onChange(animationState)
            }, 1500)
        }

        return () => clearTimeout(timer)
    }, [animationState])
    useEffect(() => {
        setAnimationState(p_.contentItem.animation)
    }, [p_.contentItem.animation])
    useEffect(() => {
        let timer = null

        timer = setTimeout(() => {
            onChange(stateSpeedStep(p_.contentItem, stateSpeed), 'state', 'duration')
        }, 300)

        return () => clearTimeout(timer)
    }, [stateSpeed])
    useEffect(() => {
        if (animationState.state) {
            onChange(stateSpeedStep(p_.contentItem, stateSpeed), 'state', 'duration')
        }
    }, [animationState.in, animationState.out])

    return !p_.noCollapse ? (
        <Collapse
            title={() => getConditionTitle()}
            titleClassName={settingsStyles.collapseTitle}
            activeClassName={settingsStyles.collapseActive}
            className={settingsStyles.collapse}
            withoutOverflow={true}
            initialOpen={true}
        >
            {Content()}
        </Collapse>
    ) : (
        Content()
    )
}

export default EditorAnimationSettings
