import React, { useState, useEffect } from 'react'
import DndDroppable from 'blocks.app/dnd/_droppable/dnd_droppable'
import { cn } from 'ethcss'
import styles from './catalog__tiles.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { connect } from 'react-redux'
import { displaysActions } from 'pages/displays/displays.state'
import { isHorizontalMenu } from 'core/helpers/menu'

interface ITileInner {
    template: any
    multipleSelect: boolean
    selectedItems: { id: number; isDirectory?: number }[]
    listItem: { id: number; identificationTimeoutS: number }
    checkIsFolder: (item: { id: number; isDirectory?: number }) => boolean
    onResetSelection: () => void
    addIdentificatedItem: (item: { id: number; timeoutS: number }) => void
    deleteIdentificatedItem: (id: number) => void
    deviceBlockStyle: string
    identificatedItem: { id: number; timeoutS: number }
    cols: number
    isFolder: number
    selectedInfo: { id: number }
    isEqual: (item_1: { id: number }, item_2: { id: number }) => boolean
    onSelectInfo: (index: number) => void
    index: number
    onSelect: () => void
    onSelectFolder: () => void
    onDoubleClick: () => void
    onDoubleClickObject: () => void
    disableFolderSelection: boolean
    onSelectProgress: (index: number) => void
    drag: () => void
    disableMultiSelect: (item: { id: number }) => void
    onDragStart: () => void
    onDragEnd: () => void
    getDragData: () => void
    isMobileDragEnabled: () => boolean
    minimize: boolean
}

const TileInner = (p_: ITileInner) => {
    const [isHovered, setIsHovered] = useState<boolean>(false)
    const [identificationTimer, setIdentificationTimer] = useState<ReturnType<typeof setTimeout> | null>(null)

    const getCols = ({ template, minimize }: { template: any; minimize: boolean }) => {
        if (template.cols) {
            return minimize ? template.cols[1] : template.cols[0]
        } else {
            return minimize ? 5 : 4
        }
    }
    const onDrop = (data: { dnd_item: string }, e: MouseEvent) => {
        e.stopPropagation()

        const droppedItem = JSON.parse(data.dnd_item)

        if (p_.template.multipleMove && p_.multipleSelect && p_.selectedItems.length) {
            const droppedItemInSelected = p_.selectedItems.filter((selectedItem) =>
                p_.template.isEqual(selectedItem, droppedItem)
            )

            if (droppedItemInSelected.length) {
                onMoveMultiple()
                return
            }
        }

        if (p_.template.isEqual(droppedItem, p_.listItem)) {
            setIsHovered(false)
            return
        }

        const moveMethod = p_.checkIsFolder(droppedItem) ? p_.template.moveFolder : p_.template.moveItem

        moveMethod(droppedItem.id, p_.listItem.id)
        setIsHovered(false)
    }
    const onMoveMultiple = () => {
        const sourceId: number[] = []
        const folderId: number[] = []

        p_.selectedItems.forEach((item) => {
            if (!p_.template.isEqual(item, p_.listItem)) {
                p_.checkIsFolder(item) ? folderId.push(item.id) : sourceId.push(item.id)
            }
        })

        p_.template.multipleMove(sourceId, folderId, p_.listItem.id).then(() => {
            p_.onResetSelection()
        })
        setIsHovered(false)
    }

    const onDragOver = () => {
        if (!isHovered) {
            setIsHovered(true)
        }
    }
    const onDragLeave = () => {
        if (isHovered) {
            setIsHovered(false)
        }
    }
    const getCellClassName = ({ template }: { template: { cellClassName: string } }) => {
        if (template.cellClassName) {
            return template.cellClassName
        } else {
            return styles.cellWrapper
        }
    }
    const initIdentificationStatus = () => {
        const { listItem, addIdentificatedItem } = p_
        const identificationTimeoutS = listItem?.identificationTimeoutS

        if (identificationTimeoutS) {
            addIdentificatedItem({
                id: listItem.id,
                timeoutS: identificationTimeoutS,
            })
        }
    }
    const updateIdentificationStatus = () => {
        const { identificatedItem, listItem, addIdentificatedItem, deleteIdentificatedItem } = p_
        const identificationTimeoutS = listItem?.identificationTimeoutS

        if (identificationTimeoutS && !identificatedItem) {
            return addIdentificatedItem({
                id: listItem.id,
                timeoutS: identificationTimeoutS,
            })
        }

        if (!identificationTimeoutS && identificatedItem && identificationTimer) {
            deleteIdentificatedItem(identificatedItem.id)
        }

        if (identificatedItem && !identificationTimer) {
            initIdentificationTimer(identificatedItem)
        }

        if (!identificatedItem && identificationTimer) {
            clearIdentificationTimer(identificationTimer)
        }
    }
    const initIdentificationTimer = (identificatedItem: { id: number; timeoutS: number }) => {
        const { deleteIdentificatedItem } = p_

        let timeoutInMs = identificatedItem.timeoutS * 1000

        setIdentificationTimer((prevState: any) => {
            return {
                ...prevState,
                identificationTimer: setTimeout(() => {
                    deleteIdentificatedItem(identificatedItem.id)
                }, timeoutInMs),
            }
        })
    }
    const clearIdentificationTimer = (identificationTimer: ReturnType<typeof setTimeout>) => {
        clearTimeout(identificationTimer)

        setIdentificationTimer((prevState: any) => {
            return {
                ...prevState,
                identificationTimer: null,
            }
        })
    }
    useEffect(() => {
        initIdentificationStatus()
    }, [])
    useEffect(() => {
        updateIdentificationStatus()
    }, [p_])

    const Tile = p_.template.tile
    const deviceBlockStyle = p_.deviceBlockStyle

    return (
        <div
            className={cn(
                getCellClassName(p_),
                grid[`col${p_.cols || getCols(p_)}`],
                p_.template.mediaCellClassName || styles.mediaCellClassName
            )}
        >
            <DndDroppable dropEnabled={p_.isFolder} onDrop={onDrop} onDragOver={onDragOver} onDragLeave={onDragLeave}>
                <Tile
                    active={(p_.selectedInfo ? p_.isEqual(p_.selectedInfo, p_.listItem) : false) || isHovered}
                    onSelectInfo={function () {
                        p_.onSelectInfo(p_.index)
                    }}
                    onSelect={p_.onSelect}
                    onSelectFolder={p_.onSelectFolder}
                    onDoubleClick={p_.onDoubleClick}
                    onDoubleClickObject={p_.onDoubleClickObject}
                    multipleSelect={p_.isFolder ? p_.multipleSelect && !p_.disableFolderSelection : p_.multipleSelect}
                    item={p_.listItem}
                    identificatedItem={p_.identificatedItem}
                    onSelectProgress={function (e: any) {
                        e.stopPropagation()
                        p_.onSelectProgress(p_.index)
                    }}
                    drag={p_.drag}
                    disableMultiSelect={p_.disableMultiSelect(p_.listItem)}
                    onDragStart={p_.onDragStart}
                    onDragEnd={p_.onDragEnd}
                    getDragData={p_.getDragData}
                    isMobileDragEnabled={p_.isMobileDragEnabled}
                    deviceBlockStyle={deviceBlockStyle}
                />
            </DndDroppable>
        </div>
    )
}

const CatalogTiles = (p_: any) => {
    function getWrapperClassName() {
        if (p_.template.wrapperClassName) {
            return p_.template.wrapperClassName
        } else {
            return isHorizontalMenu() ? styles.wrapper_x : styles.wrapper
        }
    }

    function getPanelClassName() {
        if (p_.template.panelClassName) {
            return p_.template.panelClassName
        } else {
            return null
        }
    }

    return (
        <div className={getPanelClassName()}>
            <div className={getWrapperClassName()}>
                {p_.list.map((listItem: { id: number }, index: number) => {
                    const isFolder = p_.isFolder(listItem)
                    const folderKey = isFolder ? 'folder' : 'item'
                    const identificatedItem = p_.identificatedItems.find(
                        (item: { id: number }) => item.id === listItem.id
                    )
                    const props = {
                        ...p_,
                        isFolder,
                        checkIsFolder: p_.isFolder,
                        folderKey,
                        listItem,
                        identificatedItem,
                        index,
                    }

                    return <TileInner key={`catalog__item_${folderKey}_${listItem.id}_${index}`} {...props} />
                })}
            </div>
        </div>
    )
}

CatalogTiles.defaultProps = {
    identificatedItems: [],
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        addIdentificatedItem: (item: { id: number; timeoutS: number }) =>
            dispatch(displaysActions.addIdentificatedItem(item)),
        deleteIdentificatedItem: (id: number) => dispatch(displaysActions.deleteIdentificatedItem(id)),
    }
}

export default connect(null, mapDispatchToProps)(CatalogTiles)
