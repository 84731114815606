import { useEffect, useState } from 'react'
import moment from 'moment'
import helpers from 'core/helpers'
import filesHelper from 'core/helpers/_files'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import { routes } from 'features/routes'
import deepEqual from 'fast-deep-equal'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { getDefaultSourceDuration } from 'core/utils/broadcastUtil'
import { changeLocation } from 'features/routes'
import { isRedirectToLogin, isRoutePathnameChange } from 'features/routes/utils'
import { IFileSourceData, TFileType } from '../../../core/models/Files'
import { checkRoutePathName } from 'core/helpers/routes/routes'

type Location = {
    pathname: string
    state: any
    hash: string
    search: string
}
interface IFile {
    id: number
    duration: string
    volume: string | number
    data: any
    fileType: TFileType
    type: string
}

const getDurationInMs = (duration: any, format?: any) =>
    parseInt(duration, 10) * (format ? (format.m ? 60000 : format.h ? 3600000 : 1000) : 1000)
const getContentInSecDuration = (content: { duration: number }[]) => {
    if (!content) {
        return []
    }

    return content.map((item) => {
        item.duration /= 1000
        return item
    })
}

const getDisplaysModel = (displays: { id: number; name: string }[]) => {
    if (!displays) {
        return []
    }

    return displays.map((display) => ({
        id: display.id,
        type: 'digitalSignage',
        name: display.name,
    }))
}

export interface IState {
    id: number | null
    isAdvBlock: boolean
    isEdit: boolean
    selectedContentId: number | null
    conflicts: any
    duration: string
    durationError: any
    descriptionError: any
    fillersAdBlockCreated: boolean
    applyToNewDigitalSignage: boolean
    startTime: any
    endTime: any
    startDate?: string
    endDate?: string
    localStartDate: string
    localEndDate?: string
    localStartTime?: string
    localEndTime?: string
    timeToUse?: string
    loading: boolean
    dataCompare: any
    isSaved: boolean
    step: string
    steps: { id: string; name: string }[]
    commonVolume: number
    isVolumeDiff: boolean
    files?: any
    content?: any
    displays?: any[]
    name?: string | undefined
    description?: string
    digitalSignageId: number | null
    insertionType?: string
    repetitionCount?: number | null
    repetitionIntervalMS?: number | null
    isShowAdvanced?: boolean
    insertionTypes: [] | {}[]
    hms?: {
        h?: boolean
        m?: boolean
        s?: boolean
    }
}

const AdvertisingCampaignsAddMethods = (p_: any) => {
    const getAutoName = (isAdvBlock: boolean) => {
        const currentDateTime = moment().format('HH:mm:ss DD/MM/YYYY')
        const label = isAdvBlock
            ? 'newAdBlock'
            : checkRoutePathName('/advertisingAds/add')
            ? 'newAdvertisement'
            : 'newAdvertisingCampaign'

        return `${translate(label)} ${currentDateTime}`
    }
    const dataCompare = {
        files: [],
        content: [],
        displays: [],
        name: getAutoName(false),
        description: '',
        startDate: moment().format(),
        endDate: moment().add(1, 'week').format(),
        startTime: '00:00:00',
        endTime: '23:59:59',
        localStartTime: '00:00:00',
        localEndTime: '23:59:59',
        localStartDate: moment().format(),
        localEndDate: moment().add(1, 'week').format(),
        timeToUse: 'local',
    }
    const checkIsAdvBlock = (p_: { location: { pathname: string } }) => {
        const pathname = helpers.getPathname(p_.location.pathname)
        const addFillersAdBlockPaths = [routes.addFillersAdBlock.path, routes.editFillersAdBlock.path]

        return addFillersAdBlockPaths.includes(pathname)
    }
    const getInitState = (p_: { location: { pathname: string } }, loading: boolean = true) => {
        return {
            id: null,
            isAdvBlock: checkIsAdvBlock(p_),
            isEdit: false,
            selectedContentId: null,
            conflicts: [],
            duration: '0',
            durationError: null,
            descriptionError: null,
            fillersAdBlockCreated: true,
            applyToNewDigitalSignage: false,
            loading,
            ...dataCompare,
            dataCompare,
            isSaved: false,
            step: 'files',
            steps: [
                { id: 'files', name: 'mainSettings' },
                { id: 'displays', name: 'devices' },
                { id: 'summary', name: 'summary' },
            ],
            commonVolume: 0,
            isVolumeDiff: false,
            digitalSignageId: null,
            insertionType: helpers.isDevMode() ? 'betweenBroadcast' : 'addEnd',
            repetitionCount: 1,
            repetitionIntervalMS: 60,
            insertionTypes: [
                { id: 'addStart', name: translate('addStart') },
                { id: 'addEnd', name: translate('addEnd') },
                { id: 'addOver', name: translate('addOver') },
                { id: 'replace', name: translate('replaceInsertion') },
            ],
            hms: {
                h: false,
                m: false,
                s: true,
            },
            isShowAdvanced: false,
        }
    }

    const [state, setState] = useState<IState>(getInitState(p_, true))

    useEffect(() => {
        const s_ = state
        const query = getURLSearchParamsByLocation(p_.location)

        if (s_.isAdvBlock) {
            if (!query.advBlockId) {
                const name = getAutoName(s_.isAdvBlock)
                setState((prevState: IState) => {
                    return {
                        ...prevState,
                        loading: false,
                        dataCompare: {
                            ...s_.dataCompare,
                            name,
                        },
                        name,
                    }
                })
                setTitle(s_.isAdvBlock)
                return
            }

            getAdBlock()
        } else {
            getAdvertisingCampaign()
        }

        getVolumeDiff()
    }, [])

    const getAdBlock = () => {
        const s_ = state
        const query = getURLSearchParamsByLocation(p_.location)

        api.send('getFillersAdBlockById', { id: query.advBlockId }).then((fillersAdBlock: any) => {
            const content = getContentInSecDuration(fillersAdBlock.fillers)
            let data: any = {}

            if (fillersAdBlock) {
                data = {
                    files: filesHelper.getFiles(content),
                    duration: fillersAdBlock.duration / 1000,
                    id: fillersAdBlock.id,
                    name: fillersAdBlock.title,
                    content,
                    displays: getDisplaysModel(fillersAdBlock.digitalSignage),
                    applyToNewDigitalSignage: fillersAdBlock.applyToNewDigitalSignage,
                    isEdit: true,
                }
            }

            data.loading = false

            setState((prevState: IState) => {
                return {
                    ...prevState,
                    ...data,
                }
            })

            getVolumeDiff()
        })
    }

    const getAdvertisingCampaign = () => {
        const { isAdvBlock } = state
        const advertiseMethod = () =>
            checkRoutePathName('/advertisingAds/edit') ? 'getAdvertisement' : 'getAdvertisingCampaign'
        const query = getURLSearchParamsByLocation(p_.location)

        if (!checkRoutePathName('/advertisingAds/add') && !checkRoutePathName('/advertisingAds/edit')) {
            api.send('hasCompanyCreatedFillersAdBlock', {}).then(({ fillersAdBlockCreated }: any) => {
                setState((prevState: IState) => {
                    return {
                        ...prevState,
                        fillersAdBlockCreated,
                        loading: false,
                    }
                })
                setTitle(isAdvBlock)

                if (fillersAdBlockCreated && query.advId) {
                    api.send(advertiseMethod(), { id: query.advId }).then((data: any) => {
                        const content: { source?: {}; duration: number }[] = getContentInSecDuration(data.content)
                        const newState = {
                            name: data.title,
                            description: data.description,
                            content,
                            startDate: moment(data.startDate),
                            endDate: moment(data.endDate),
                            files: content.map((contentItem) => contentItem.source),
                            displays: getDisplaysModel(data.digitalSignage),
                            startTime: data.startTime,
                            endTime: data.endTime,
                            localStartDate: moment(data.localStartDate),
                            localEndDate: moment(data.localEndDate),
                            timeToUse: data.timeToUse,
                            localStartTime: data.localStartTime,
                            localEndTime: data.localEndTime,
                            repetitionCount: data.repetitionCount,
                            insertionType: data.insertionType,
                            repetitionIntervalMS: data.repetitionIntervalMS / 1000,
                        }

                        setState((prevState: any) => {
                            return {
                                ...prevState,
                                isEdit: true,
                                ...newState,
                                dataCompare: {
                                    ...newState,
                                },
                            }
                        })
                        getVolumeDiff()

                        setTitle(isAdvBlock)
                    })
                }
            })
        } else {
            setTitle(isAdvBlock)
            setState((prevState: IState) => {
                return {
                    ...prevState,
                    loading: false,
                }
            })
            if (query.advId) {
                api.send(advertiseMethod(), { id: query.advId }).then((data: any) => {
                    const content: { source?: {}; duration: number }[] = getContentInSecDuration(data.content)
                    const newState = {
                        name: data.title,
                        description: data.description,
                        content,
                        startDate: moment(data.startDate),
                        endDate: moment(data.endDate),
                        localStartDate: moment(data.localStartDate),
                        localEndDate: moment(data.localEndDate),
                        timeToUse: data.timeToUse,
                        localStartTime: data.localStartTime,
                        localEndTime: data.localEndTime,
                        files: content.map((contentItem) => contentItem.source),
                        displays: getDisplaysModel(data.digitalSignage),
                        startTime: data.startTime,
                        endTime: data.endTime,
                        repetitionCount: data.repetitionCount,
                        insertionType: data.insertionType,
                        repetitionIntervalMS: data.repetitionIntervalMS / 1000,
                    }

                    setState((prevState: any) => {
                        return {
                            ...prevState,
                            isEdit: true,
                            ...newState,
                            dataCompare: {
                                ...newState,
                            },
                        }
                    })
                    getVolumeDiff()

                    setTitle(isAdvBlock)
                })
            }
        }
    }

    useEffect(() => {
        const isAdvBlock = checkIsAdvBlock(p_)
        const query = getURLSearchParamsByLocation(p_.location)
        //const prevQuery = getURLSearchParamsByLocation(p_.location)
        if (isAdvBlock !== state.isAdvBlock) {
            const name = getAutoName(isAdvBlock)

            setTitle(isAdvBlock)
            setState((prevState: IState) => {
                return {
                    ...prevState,
                    isAdvBlock,
                    dataCompare: {
                        ...prevState.dataCompare,
                        name,
                    },
                    name,
                }
            })

            return
        }

        // if (!query.advId && prevQuery.advId) {
        //     setState(getInitState(p_, false))
        // }
    }, [p_, state])

    const isPreventLeaveRoute = () => {
        const s_ = state
        const { isDeleted } = p_

        return s_.fillersAdBlockCreated && isDataWasChanged() && !s_.isSaved && !isDeleted
    }

    const isDataWasChanged = () => {
        const {
            dataCompare,
            files,
            content,
            displays,
            name,
            description,
            startDate,
            endDate,
            startTime,
            endTime,
            localStartDate,
            localEndDate,
            timeToUse,
            localStartTime,
            localEndTime,
        } = state

        const editableData = {
            files,
            content,
            displays,
            name,
            description,
            startDate,
            endDate,
            startTime,
            endTime,
            localStartDate,
            localEndDate,
            timeToUse,
            localStartTime,
            localEndTime,
        }

        return !deepEqual(dataCompare, editableData)
    }
    const onContinue = () => {
        let { redirect } = p_

        setState((prevState: IState) => {
            return {
                ...prevState,
                fillersAdBlockCreated: true,
            }
        })
        changeLocation(`/${routes.addFillersAdBlock.path}`)
        redirect = routes.addAdvertisingCampaign.path
    }
    const setTitle = (isAdvBlock: boolean) => {
        const { isEdit } = state
        const { setTitle } = p_

        let title = isEdit ? 'editAdv' : 'createAdv'

        if (isAdvBlock) {
            title += 'Block'
        }

        setTitle([translate(title)])
    }

    const onChangeSetting = (value: string, e: { target: { dataset: { field: string } } }) => {
        const field = e.target.dataset.field

        setState((prevState: IState) => {
            return {
                ...prevState,
                [field]: value,
                [`${field}Error`]: null,
            }
        })
    }

    const onSelectContent = (files: { id: number }[]) => {
        const s_ = state
        const prevFilesId = s_.files.map((file: { id: number }) => file.id)
        const filesId = files.map((file) => file.id)
        const deletedId = prevFilesId.filter((fileId: number) => filesId.indexOf(fileId) === -1)
        const content = s_.content.filter(
            (contentItem: { sourceId: number }) => deletedId.indexOf(contentItem.sourceId) === -1
        )
        files.forEach((file: any) => {
            if (prevFilesId.indexOf(file.id) === -1) {
                content.push(formatFile(file))
            }
        })

        let startTime = 0
        content.forEach((contentItem: {}, index: number) => {
            content[index].startTime = startTime
            if (!s_.isAdvBlock) {
                content[index].repetitionCount = content[index].repetitionCount || 1
            }
            startTime += content[index].duration
        })

        setState((prevState: IState) => {
            return {
                ...prevState,
                files,
                content,
                selectedContentId: null,
                conflicts: [],
            }
        })
        getVolumeDiff()
    }
    const onSelectContentInSidebar = (files: IFile[]) => {
        const s_ = state
        const content: { [index: string]: any } = []
        files.forEach((file) => {
            content.push(formatFile(file))
        })

        let startTime = 0
        content.forEach((contentItem: object, index: number) => {
            content[index].startTime = startTime
            if (!s_.isAdvBlock) {
                content[index].repetitionCount = content[index].repetitionCount || 1
            }
            startTime += content[index].duration
        })

        setState((prevState: IState) => {
            return {
                ...prevState,
                files,
                content,
                selectedContentId: null,
                conflicts: [],
            }
        })
    }
    const onSelectDisplays = (displays: { id: number; name: string }) => {
        setState((prevState: any) => {
            return {
                ...prevState,
                displays,
                conflicts: [],
            }
        })
    }

    const formatFile = (item: IFile) => {
        const itemFromState = state.content.find((contentItem: any) => contentItem.sourceId === item.id)
        const defaultDuration = getDefaultDuration(item)
        let duration: string = itemFromState ? itemFromState.duration : '0'
        if (!!item.duration) {
            duration = item.duration
        } else if (item.data && !!item.data.duration) {
            duration = item.data.duration
        }

        const data: { [index: string]: any } = {
            sourceId: item.id,
            duration: parseInt(duration, 10) || defaultDuration,
            animation: { in: null, state: null, out: null },
            source: { ...item },
        }

        if (fileWithVolume(item)) {
            data.volume = typeof item.volume !== 'number' ? 0 : item.volume
        }

        return data
    }

    const getDefaultDuration = (source: { data: IFileSourceData; fileType: TFileType; type: string }) => {
        const { settings } = p_
        const defaultSourceDuration = getDefaultSourceDuration(settings.defaultSourceDuration, source)
        const duration = defaultSourceDuration || 20000

        return duration / 1000
    }

    const onChangeDuration = (duration: string) => {
        const s_ = state
        const content = s_.content.map((item: { sourceId: number; duration: string }) => {
            if (item.sourceId === s_.selectedContentId) {
                item.duration = duration
            }

            return item
        })

        setState((prevState: IState) => {
            return {
                ...prevState,
                content,
            }
        })
    }

    const onChangeRepetitionCount = (repetitionCount: string) => {
        const s_ = state
        const content = s_.content.map((item: { sourceId: number; repetitionCount: string }) => {
            if (item.sourceId === s_.selectedContentId) {
                item.repetitionCount = repetitionCount
            }

            return item
        })

        setState((prevState: IState) => {
            return {
                ...prevState,
                content,
            }
        })
    }

    const onChangeVolume = (volume: number) => {
        const s_ = state
        const content = s_.content.map((item: { sourceId: number; volume: number }) => {
            if (item.sourceId === s_.selectedContentId) {
                item.volume = volume
            }

            return item
        })

        setState((prevState: IState) => {
            return {
                ...prevState,
                content,
                commonVolume: 0,
            }
        })
    }

    const onChangeCommonVolume = (volume: number) => {
        setState((prevState: IState) => {
            const content = prevState.content.map((item: { source: { fileType: string } }) => {
                if (item.source) {
                    if (item.source.fileType === 'video' || item.source.fileType === 'audio') {
                        return {
                            ...item,
                            volume,
                        }
                    }
                }

                return item
            })

            return {
                ...prevState,
                content,
                commonVolume: volume,
            }
        })
    }

    const isVolumeSupport = () => {
        const s_ = state
        const itemsWithVolume = getItemsWithVolume(s_.content)

        return itemsWithVolume.length > 1
    }

    const getVolumeDiff = () => {
        setTimeout(() => {
            const s_ = state
            const itemsWithVolume = getItemsWithVolume(s_.content)

            if (itemsWithVolume.length) {
                const sampleElement: { volume: number } = itemsWithVolume[0]
                const isDiff = itemsWithVolume.some((item) => item.volume !== sampleElement.volume)

                if (!isDiff) {
                    onChangeCommonVolume(sampleElement.volume)
                }

                setState((prevState: IState) => {
                    return {
                        ...prevState,
                        isVolumeDiff: isDiff,
                    }
                })
            }
        }, 200)
    }

    const fileWithVolume = (file: { fileType: string }) => {
        if (file.fileType) {
            return file.fileType === 'video' || file.fileType === 'audio'
        }

        return false
    }

    const getItemsWithVolume = (items: { source: { fileType: string }; volume: number }[]) => {
        return items.filter((item) => {
            if (item.source) {
                return item.source.fileType === 'video' || item.source.fileType === 'audio'
            }
        })
    }

    const setName = () => {
        const s_ = state

        if (s_.name) {
            return
        }

        setState((prevState: IState) => {
            return {
                ...prevState,
                name: getAutoName(s_.isAdvBlock),
            }
        })
    }

    // todo: сделать нормальный вид
    const stepValidate = () => {
        const s_ = state
        const { emitError } = p_
        let valid = true

        switch (s_.step) {
            case 'files':
                if (!contentValidate()) {
                    valid = false
                } else {
                    setName()
                }
                break
            case 'displays':
                if (s_.displays && s_.displays.length === 0) {
                    valid = false
                    emitError('noSelectedDevices')
                }
                break
            default:
                break
        }

        return valid
    }

    const defaultValidate = () => {
        const s_ = state
        const { emitError } = p_
        let valid = true

        if (!contentValidate()) {
            valid = false
        } else {
            setName()
            let contentDuration = 0
            s_.content.forEach(
                (contentItem: { duration: string }) => (contentDuration += parseFloat(contentItem.duration))
            )

            if (
                contentDuration >
                +(s_.hms
                    ? s_.hms.m
                        ? parseInt(s_.duration, 10) * 60000
                        : s_.hms.h
                        ? parseInt(s_.duration, 10) * 3600000
                        : s_.duration
                    : s_.duration)
            ) {
                valid = false
                emitError('conflict_frontend_sources_duration_greater_than_page_default_duration')
            }
        }

        return valid
    }

    const changeStep = (step: string) => {
        if (!stepValidate()) {
            return
        }

        setState((prevState: IState) => {
            return {
                ...prevState,
                step,
            }
        })

        if (step === 'summary') {
            getConflicts()
        }
    }

    const contentValidate = () => {
        const s_ = state
        const { emitError } = p_

        if (!s_.content.length) {
            emitError('noContent')
            return false
        }

        if ((!s_.duration || +s_.duration <= 0) && s_.isAdvBlock) {
            emitError('enterDuration')
            return false
        }

        return true
    }

    const onSubmit = () => {
        const s_ = state
        const submitActions: {
            [index: string]: () => void
        } = {
            files: () => changeStep('displays'),
            displays: () => changeStep('summary'),
            summary: () => {
                s_.isAdvBlock ? advBlockSubmit() : advertisingCampaignSubmit()
            },
        }

        submitActions[s_.step]()
    }
    const advBlockSubmit = () => {
        const s_ = state
        const { redirect } = p_

        if (!defaultValidate()) {
            return
        }

        const data = getAdBlockData(null)
        const method = s_.isEdit ? 'updateFillersAdBlock' : 'createFillersAdBlock'

        api.send(method, data).then(() => {
            setState({
                ...getInitState(p_),
                isAdvBlock: false,
                loading: false,
                isSaved: true,
            })

            changeLocation(redirect ? `/${redirect}` : `/${routes.adBlocks.path}`)
        })
    }

    const cancel = () => {
        const location = state.isAdvBlock
            ? routes.adBlocks.path
            : checkRoutePathName('/advertisingAds/add') || checkRoutePathName('/advertisingAds/edit')
            ? routes.advertisingAds.path
            : routes.advertisingCampaigns.path
        changeLocation(`/${location}`)
    }

    const getSaveText = () => {
        const s_ = state
        let saveButtonLabel = translate('next')

        if (s_.step === 'summary') {
            saveButtonLabel = translate('save')
            if (s_.conflicts && s_.conflicts.length) {
                saveButtonLabel = translate('solveConflictsAndSave')
            }
        }

        return saveButtonLabel
    }
    const getConflicts = () => {
        const s_ = state
        const { emitError } = p_

        if (s_.displays && !s_.displays.length) {
            emitError('noSelectedDevices')
            setState((prevState: IState) => {
                return {
                    ...prevState,
                    step: 'displays',
                }
            })

            return
        }

        const groupId: number[] = []
        const digitalSignageId: number[] = []

        if (s_.displays) {
            s_.displays.forEach((device: { id: number; type: string }) => {
                device.type === 'group' ? groupId.push(device.id) : digitalSignageId.push(device.id)
            })
        }

        api.send('getDisplaysFromGroups', { groupId, digitalSignageId })
            .then((displays: any) => {
                if (!displays.length) {
                    emitError('groupDevicesEmpty')
                    setState((prevState: IState) => {
                        return {
                            ...prevState,
                            step: 'displays',
                        }
                    })
                    return []
                }

                return displays
            })
            .then((displays) => {
                const digitalSignageId: any = displays.map((display: { id: number | null }) => display.id)
                let method: string =
                    checkRoutePathName('/advertisingAds/add') || checkRoutePathName('/advertisingAds/edit')
                        ? 'getAdvertisementConflicts'
                        : 'getAdvertisingCampaignsConflicts'
                let data: any = getAdvertisingCampaignData(digitalSignageId)

                if (s_.isAdvBlock) {
                    data = getAdBlockData(digitalSignageId)
                    method = 'getFillersAdBlockConflicts'
                }

                //@ts-ignore
                api.send(method, data).then((res: any) => {
                    setState((prevState: IState) => {
                        return {
                            ...prevState,
                            conflicts:
                                checkRoutePathName('/advertisingAds/add') || checkRoutePathName('/advertisingAds/edit')
                                    ? res
                                    : res.conflicts,
                            digitalSignageId,
                            displays: getDisplaysModel(displays),
                        }
                    })
                })
            })
    }
    const advertisingCampaignSubmit = () => {
        const s_ = state
        const saveMethod =
            checkRoutePathName('/advertisingAds/add') ||
            checkRoutePathName('/advertisingAds') ||
            checkRoutePathName('/advertisingAds/edit')
                ? s_.isEdit
                    ? 'updateAdvertisement'
                    : 'createAdvertisement'
                : s_.isEdit
                ? 'updateAdvertisingCampaign'
                : 'createAdvertisingCampaign'
        const data = getAdvertisingCampaignData(null)

        api.send(saveMethod, data).then(() => {
            setState((prevState: IState) => {
                return {
                    ...prevState,
                    isSaved: true,
                }
            })

            changeLocation(
                checkRoutePathName('/advertisingAds/add') || checkRoutePathName('/advertisingAds/edit')
                    ? `/${routes.advertisingAds.path}`
                    : `/${routes.advertisingCampaigns.path}`
            )
        })
    }
    const getAdBlockData = (digitalSignageId: number | null) => {
        const s_ = state
        const query = getURLSearchParamsByLocation(p_.location)

        if (!digitalSignageId) {
            digitalSignageId = s_.digitalSignageId
        }

        const fillers = getAdBlockFillers()

        const data: {
            id?: number
            title: string | undefined
            duration: number | string
            digitalSignageId: number | null
            applyToNewDigitalSignage: boolean
            fillers: { sourceId: number; duration: string; volume: number }
        } = {
            title: s_.name,
            duration: getDurationInMs(s_.duration, s_.hms),
            digitalSignageId,
            applyToNewDigitalSignage: s_.applyToNewDigitalSignage,
            fillers,
        }

        if (s_.isEdit) {
            data.id = parseInt(query.advBlockId, 10)
        }

        return data
    }

    const getAdBlockFillers = () => {
        const s_ = state

        return s_.content.map((content: { sourceId: number; duration: string; volume: number }, index: number) => ({
            sourceId: content.sourceId,
            duration: getDurationInMs(content.duration),
            order: index,
            volume: content.volume,
        }))
    }

    const convertToUtcDate = (date: moment.MomentInput, time: moment.MomentInput) => {
        return helpers.getFormattedUtcDate(`${moment(date).format('YYYY-MM-DD')} ${time}`, 'YYYY-MM-DD')
    }

    const convertToUtcTime = (date: moment.MomentInput, time: moment.MomentInput) => {
        return helpers.getFormattedUtcDate(`${moment(date).format('YYYY-MM-DD')} ${time}`, 'HH:mm:ss')
    }

    const getAdvertisingCampaignData = (digitalSignageId: number | null) => {
        const s_ = state
        const query = getURLSearchParamsByLocation(p_.location)

        const content = getAdvertisingCampaignContent()

        if (!digitalSignageId) {
            digitalSignageId = s_.digitalSignageId
        }

        const data: {
            id?: number
            title: string | undefined
            description: string | undefined
            digitalSignageId: number | null
            startDate: any
            endDate: any
            startTime: any
            endTime: any
            content: {}[]
            insertionType?: string
            repetitionCount?: number | null
            repetitionIntervalMS?: number | null
            localStartDate?: string
            localEndDate?: string
            timeToUse?: string
            localStartTime?: string
            localEndTime?: string
        } = {
            title: s_.name,
            description: s_.description,
            startDate: convertToUtcDate(s_.localStartDate, s_.localStartTime),
            endDate: convertToUtcDate(s_.localEndDate, s_.localEndTime),
            digitalSignageId,
            content,
            startTime: convertToUtcTime(s_.localStartDate, s_.localStartTime),
            endTime: convertToUtcTime(s_.localEndDate, s_.localEndTime),
            localStartTime: s_.localStartTime,
            localEndTime: s_.localEndTime,
            localStartDate: moment(s_.localStartDate).startOf('day').format('YYYY-MM-DD'),
            localEndDate: moment(s_.localEndDate).startOf('day').format('YYYY-MM-DD'),
            timeToUse: s_.timeToUse,
            insertionType: s_.insertionType,
            repetitionCount: s_.repetitionCount,
            repetitionIntervalMS: getDurationInMs(s_.repetitionIntervalMS, s_.hms),
        }
        if (checkRoutePathName('/advertisingCampaigns/add') || checkRoutePathName('/advertisingCampaigns/edit')) {
            delete data.insertionType
            delete data.repetitionCount
            delete data.repetitionIntervalMS
        }
        if (
            !checkRoutePathName('/advertisingCampaigns/add') &&
            !checkRoutePathName('/advertisingCampaigns/edit') &&
            s_.insertionType === 'betweenBroadcast'
        ) {
            delete data.repetitionIntervalMS
        }
        if (s_.isEdit) {
            data.id = parseInt(query.advId, 10)
        }

        return data
    }
    const getAdvertisingCampaignContent = () => {
        const s_ = state

        return s_.content.map(
            (
                content: {
                    sourceId: number
                    duration: string
                    volume: number
                    repetitionCount: number
                },
                index: number
            ) => ({
                sourceId: content.sourceId,
                duration: getDurationInMs(content.duration),
                repetitionCount: content.repetitionCount,
                order: index,
                volume: content.volume,
            })
        )
    }
    const isShouldBlockNavigation = (location: Location, prevLocation: any) => {
        return (
            !isRedirectToLogin(location.pathname) &&
            isRoutePathnameChange(location, prevLocation) &&
            isPreventLeaveRoute()
        )
    }

    return {
        state,
        setState,
        onChangeSetting,
        cancel,
        onSubmit,
        getSaveText,
        onContinue,
        onSelectContentInSidebar,
        onSelectDisplays,
        onChangeDuration,
        onChangeVolume,
        onChangeCommonVolume,
        isVolumeSupport,
        getVolumeDiff,
        onChangeRepetitionCount,
        changeStep,
        onSelectContent,
        isPreventLeaveRoute,
        isShouldBlockNavigation,
    }
}

export default AdvertisingCampaignsAddMethods
