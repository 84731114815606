import React from 'react'
import ScheduleSetting from 'blocks.app/scheduleSettings/scheduleSettings'
import AddScheduleSettingsMethods, { ISettingsProps } from './addSchedule__settings.local.methods'
import moment from 'moment'
import translate from 'core/translate'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { text as textStyles } from 'theme'
import styles from '../addSchedule.jcss'

const AddScheduleSettings = (p_: ISettingsProps) => {
    const {
        state,
        setState,
        onChangeStartDate,
        onRepeatChange,
        onChangeDays,
        handleChangeSpecificDays,
        roundTime,
        setStartEndTime,
    } = AddScheduleSettingsMethods(p_)

    return (
        <div>
            <div className={styles.whiteBlock}>
                <div className={cn(grid.mb_mdPlus, textStyles.md, grid.pl_md, grid.pt_md)}>{translate('schedule')}</div>
                <ScheduleSetting
                    startDate={{
                        label: translate('startDate'),
                        dateValue: p_.schedule.startDate,
                        id: 'startDate',
                        maxDate: p_.schedule.repeatMode === 'once' ? null : p_.schedule.endDate,
                    }}
                    onChangeStartDate={onChangeStartDate}
                    endDate={{
                        label: translate('endDate'),
                        id: 'endDate',
                        minDate: p_.schedule.startDate,
                        dateValue: p_.schedule.repeatMode === 'once' ? p_.schedule.startDate : p_.schedule.endDate,
                        disabled: p_.schedule.repeatMode === 'once' || p_.schedule.repeatMode === 'forever',
                    }}
                    onChangeEndDate={(endDate: string) => {
                        p_.onChangeSchedule({
                            ...p_.schedule,
                            endDate,
                        })
                    }}
                    repeat={{
                        label: translate('repeat'),
                        value: p_.schedule.repeatMode,
                        options: [
                            { name: translate('once'), id: 'once' },
                            { name: translate('daily'), id: 'daily' },
                            { name: translate('weekly'), id: 'weekly' },
                            { name: translate('forever'), id: 'forever' },
                            { name: translate('specificDays'), id: 'exact_dates' },
                        ],
                        days: p_.schedule.repeatMode === 'weekly' ? state.days : [],
                        disabledDays: state.days.filter((day, index) => state.daysBetween.indexOf(index + 1) !== -1),
                        repeatMode: p_.schedule.repeatMode,
                        repeatDays: p_.schedule.repeatDays,
                    }}
                    onRepeatChange={onRepeatChange}
                    onChangeDays={onChangeDays}
                    onChangeSpecificDays={handleChangeSpecificDays}
                    startTime={{
                        label: translate('startTime'),
                        id: 'startTime',
                        type: 'tel',
                        mask: '99:99:99',
                        value: p_.schedule.startTime,
                        disabled: state.onAllDay,
                    }}
                    onChangeStartTime={(value: string) => {
                        if (moment(value, 'HH:mm:ss', true).isValid()) {
                            p_.onChangeSchedule({
                                ...p_.schedule,
                                startTime: value,
                            })
                        }
                    }}
                    endTime={{
                        label: translate('endTime'),
                        id: 'endTime',
                        type: 'tel',
                        mask: '99:99:99',
                        value: p_.schedule.endTime,
                        disabled: state.onAllDay,
                    }}
                    onChangeEndTime={(value: string) => {
                        if (moment(value, 'HH:mm:ss', true).isValid()) {
                            p_.onChangeSchedule({
                                ...p_.schedule,
                                endTime: value,
                            })
                        }
                    }}
                    checkbox={{
                        label: translate('onAllDay'),
                        checked: state.onAllDay,
                    }}
                    onChecked={(check: boolean) => {
                        setState((prevState) => {
                            return {
                                ...prevState,
                                onAllDay: check,
                            }
                        })
                        if (!check) {
                            roundTime(p_)
                        } else {
                            setStartEndTime(check, p_)
                        }
                    }}
                    onChangeDeferredDownloadDate={function (date: string) {
                        p_.onChangeDeferredDownload(date, 'contentDownloadDate')
                    }}
                    onChangeDeferredDownloadTime={function (time: string) {
                        p_.onChangeDeferredDownload(time, 'contentDownloadTime')
                    }}
                    deferredDownload={
                        !p_.deferredDownload
                            ? null
                            : {
                                  title: (
                                      <div className={cn(grid.mb_mdPlus, textStyles.md, grid.pl_md)}>
                                          {translate('uploadContentToDevice')}
                                      </div>
                                  ),
                                  time: {
                                      label: translate('time'),
                                      id: 'deferredDownloadScheduleTime',
                                      type: 'tel',
                                      mask: '99:99:99',
                                      value: p_.deferredDownload.time,
                                  },
                                  date: {
                                      label: translate('date'),
                                      id: 'deferredDownloadScheduleDate',
                                      minDate: moment(),
                                      maxDate: p_.schedule.startDate,
                                      dateValue: p_.deferredDownload.date,
                                  },
                              }
                    }
                    timeToUse={!p_.schedule.timeToUse ? null : p_.schedule.timeToUse}
                    onChangeTimeToUse={function (timeToUse: string) {
                        p_.onChangeSchedule({
                            ...p_.schedule,
                            timeToUse,
                        })
                    }}
                    priority={p_.schedule.priority}
                    onChangePriority={function (priority: number) {
                        p_.onChangeSchedule({
                            ...p_.schedule,
                            priority,
                        })
                    }}
                />
            </div>
            {p_.advContentLength && p_.schedule.repeatMode !== 'forever' && p_.schedule.repeatMode !== 'exact_dates' ? (
                <div className={cn(styles.whiteBlock, grid.p_md)}>
                    <div className={cn(grid.mb_mdPlus, textStyles.md)}>{translate('planning')}</div>
                    <div className={cn(grid.pl_big, grid.pr_big, grid.row, grid.justify)}>
                        <div className={styles.stat}>
                            <div className={styles.statTitle}>{state.advCount}</div>
                            <div>
                                <p>{translate('advCounterTitle')}</p>
                            </div>
                        </div>
                        <div className={styles.stat}>
                            <div className={styles.statTitle}>
                                {state.dayDuration}
                                <span className={styles.statSmTitle}>{translate('dayShort')}, </span>
                                {state.hourDuration}
                                <span className={styles.statSmTitle}>{translate('hourShort')}, </span>
                                {state.minuteDuration}
                                <span className={styles.statSmTitle}>{translate('minuteShort')}</span>
                            </div>
                            <div>
                                <p>{translate('broadcastDuration')}</p>
                            </div>
                        </div>
                        <div className={styles.stat}>
                            <div className={styles.statTitle}>{state.allAdvCount}</div>
                            <div>
                                <p>{translate('allAdvCounterTitle')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default AddScheduleSettings
