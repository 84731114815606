import { useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import { api } from '../../core/api/ConnectionManager'
import { emitError } from '../../features/appNotifications/AppNotifications.state'
import { useDispatch } from 'react-redux'
import translate from '../../core/translate'

export type Device = {
    id: number
    name: string
}

export type SelectedBlock = {
    duration: number
    devices: Device[]
    content: ContentItem[]
}

export type ContentItem = {
    id: number
    duration: number
    durationPx: number
    order: number
    source: {
        name: string
        id: number
        __view: object
    }
}

export interface IAdvBlockState {
    over: {
        [index: string]: boolean
    }
    deleteData: { blockIndex: number; deviceIndex?: number } | null
    selectedBlockIndex: number | null
    selectedDeviceIndex: number | null
    selectedBlock: SelectedBlock | null
    selectedDevice: Device | null
    isGroupSelected: boolean
    scaleHeight: number
    duration: number
    date: Moment
    interval: string
    intervalFormat: string
    startTime: string
    gridInProgress: boolean
}

export type Block = {
    content: ContentItem[]
    devices: Device[]
    duration: number
    dynamic: boolean
}

export interface IAdvBlockProps {
    blocks: Block[]
    devices?: Device[]
    date: string
    onChange: (selected: any) => void
    onChangeDate: (newDate: string, cb: any) => void
    onChangeInterval: (interval: string, cb: any) => void
    onUpdateBlocks: (blocks: Block[], digitalSignageId: number[]) => void
    selectedBlockData: {
        selectedBlockIndex: number | null
        selectedDeviceIndex: number | null
        selectedBlock: SelectedBlock | null
        selectedDevice: Device | null
        isGroupSelected: boolean
        scaleHeight: number
        duration: number
    } | null
}

export interface IGrid {
    startTime: string
    endTime: string
    offset: number
    broadcasts: {
        id: number
        startTime: string
        endTime: string
        title: string
        content: {
            startTime: string
            endTime: string
            source: {
                id: number
                fileType: string
                name: string
            }
        }[]
    }[]
    advertisements: {
        id: number
        startTime: string
        endTime: string
        title: string
        insertionType: string
        content: {
            startTime: string
            endTime: string
            source: {
                id: number
                fileType: string
                name: string
            }
        }[]
    }[]
}

// const mockDevices = [
//     {
//         id: 1,
//         name: 'WIN-4KATIA7FTBV',
//     },
//     {
//         id: 2,
//         name: 'Taizen',
//     },
// ]

// const mockGridReplaceBroadcastContent = {
//     startTime: '2023-12-30 00:00:00',
//     endTime: '2023-12-30 01:00:00',
//     offset: 180,
//     broadcasts: [
//         {
//             id: 1,
//             startTime: '2023-12-30 00:00:00',
//             endTime: '2023-12-30 00:30:10',
//             title: 'Новая трансляция - 1',
//             content: [
//                 {
//                     startTime: '2023-12-30 00:00:00',
//                     endTime: '2023-12-30 00:10:00',
//                     source: {
//                         id: 32453,
//                         fileType: 'audio',
//                         name: 'Фоновая музыка.mp3',
//                     },
//                 },
//                 {
//                     startTime: '2023-12-30 00:20:00',
//                     endTime: '2023-12-30 00:30:10',
//                     source: {
//                         id: 32423,
//                         fileType: 'video',
//                         name: 'Демонстрационное видео.mp4',
//                     },
//                 },
//             ],
//         },
//         {
//             id: 2,
//             startTime: '2023-12-30 00:31:10',
//             endTime: '2023-12-30 00:46:00',
//             title: 'Новая трансляция - 2',
//             content: [
//                 {
//                     startTime: '2023-12-30 00:30:10',
//                     endTime: '2023-12-30 00:35:00',
//                     source: {
//                         id: 32453,
//                         fileType: 'video',
//                         name: 'Фоновое видео.mp4',
//                     },
//                 },
//                 {
//                     startTime: '2023-12-30 00:42:00',
//                     endTime: '2023-12-30 00:46:00',
//                     source: {
//                         id: 32423,
//                         fileType: 'web_app',
//                         name: 'Виджет погоды',
//                     },
//                 },
//             ],
//         },
//         {
//             id: 3,
//             startTime: '2023-12-30 00:46:00',
//             endTime: '2023-12-30 01:20:00',
//             title: 'Новая трансляция - 3',
//             content: [
//                 {
//                     startTime: '2023-12-30 00:46:00',
//                     endTime: '2023-12-30 00:48:00',
//                     source: {
//                         id: 32453,
//                         fileType: 'video',
//                         name: 'Фоновое видео.mp4',
//                     },
//                 },
//                 {
//                     startTime: '2023-12-30 00:57:00',
//                     endTime: '2023-12-30 01:20:00',
//                     source: {
//                         id: 32423,
//                         fileType: 'video',
//                         name: 'Тестовый ролик.mp4',
//                     },
//                 },
//             ],
//         },
//     ],
//     advertisements: [
//         {
//             id: 1,
//             insertionType: 'replaceBroadcastContent',
//             startTime: '2023-12-30 00:10:00',
//             endTime: '2023-12-30 00:20:00',
//             title: 'Объявление - 1',
//             content: [
//                 {
//                     startTime: '2023-12-30 00:10:00',
//                     endTime: '2023-12-30 00:15:00',
//                     source: {
//                         id: 32474323,
//                         fileType: 'video',
//                         name: 'Рекламный видео ролик - 1.mp4',
//                     },
//                 },
//                 {
//                     startTime: '2023-12-30 00:15:00',
//                     endTime: '2023-12-30 00:20:00',
//                     source: {
//                         id: 32123323,
//                         fileType: 'video',
//                         name: 'Рекламный видео ролик - 2.mp4',
//                     },
//                 },
//             ],
//         },
//         {
//             id: 2,
//             insertionType: 'replaceBroadcastContent',
//             startTime: '2023-12-30 00:35:00',
//             endTime: '2023-12-30 00:42:00',
//             title: 'Объявление - 2',
//             content: [
//                 {
//                     startTime: '2023-12-30 00:35:00',
//                     endTime: '2023-12-30 00:38:00',
//                     source: {
//                         id: 32474323,
//                         fileType: 'video',
//                         name: 'Рекламный видео ролик.mp4',
//                     },
//                 },
//                 {
//                     startTime: '2023-12-30 00:38:00',
//                     endTime: '2023-12-30 00:42:00',
//                     source: {
//                         id: 32123323,
//                         fileType: 'audio',
//                         name: 'Рекламный аудио трек.mp3',
//                     },
//                 },
//             ],
//         },
//         {
//             id: 3,
//             insertionType: 'replaceBroadcastContent',
//             startTime: '2023-12-30 00:48:00',
//             endTime: '2023-12-30 00:57:00',
//             title: 'Объявление - 3',
//             content: [
//                 {
//                     startTime: '2023-12-30 00:48:00',
//                     endTime: '2023-12-30 00:52:00',
//                     source: {
//                         id: 32474323,
//                         fileType: 'video',
//                         name: 'Рекламный видео ролик.mp4',
//                     },
//                 },
//                 {
//                     startTime: '2023-12-30 00:52:00',
//                     endTime: '2023-12-30 00:57:00',
//                     source: {
//                         id: 32123323,
//                         fileType: 'audio',
//                         name: 'Рекламный аудио трек.mp3',
//                     },
//                 },
//             ],
//         },
//     ],
// }

// const mockGridBetweenBroadcast = {
//     startTime: '2023-12-30 00:00:00',
//     endTime: '2023-12-30 01:00:00',
//     broadcasts: [
//         {
//             id: 1,
//             startTime: '2023-12-30 00:10:00',
//             endTime: '2023-12-30 00:40:13',
//             title: 'Новая трансляция - 1',
//             content: [
//                 {
//                     startTime: '2023-12-30 00:10:00',
//                     endTime: '2023-12-30 00:17:45',
//                     source: {
//                         id: 32453,
//                         fileType: 'audio',
//                         name: 'Фоновая музыка.mp3',
//                     },
//                 },
//                 {
//                     startTime: '2023-12-30 00:17:45',
//                     endTime: '2023-12-30 00:40:13',
//                     source: {
//                         id: 32423,
//                         fileType: 'video',
//                         name: 'Демонстрационное видео.mp4',
//                     },
//                 },
//             ],
//         },
//         {
//             id: 2,
//             startTime: '2023-12-30 00:50:00',
//             endTime: '2023-12-30 01:00:00',
//             title: 'Новая трансляция - 2',
//             content: [
//                 {
//                     startTime: '2023-12-30 00:50:00',
//                     endTime: '2023-12-30 00:53:42',
//                     source: {
//                         id: 3223453,
//                         fileType: 'office',
//                         name: 'Презентация.pdf',
//                     },
//                 },
//                 {
//                     startTime: '2023-12-30 00:53:42',
//                     endTime: '2023-12-30 01:00:00',
//                     source: {
//                         id: 32434323,
//                         fileType: 'web_app',
//                         name: 'Виджет RSS',
//                     },
//                 },
//             ],
//         },
//     ],
//     advertisements: [
//         {
//             id: 1,
//             insertionType: 'betweenBroadcast',
//             startTime: '2023-12-30 00:00:00',
//             endTime: '2023-12-30 00:10:00',
//             title: 'Объявление - 1',
//             content: [
//                 {
//                     startTime: '2023-12-30 00:00:00',
//                     endTime: '2023-12-30 00:05:00',
//                     source: {
//                         id: 32474323,
//                         fileType: 'video',
//                         name: 'Рекламный видео ролик - 1.mp4',
//                     },
//                 },
//                 {
//                     startTime: '2023-12-30 00:05:00',
//                     endTime: '2023-12-30 00:10:00',
//                     source: {
//                         id: 32123323,
//                         fileType: 'video',
//                         name: 'Рекламный видео ролик - 2.mp4',
//                     },
//                 },
//             ],
//         },
//         {
//             id: 2,
//             insertionType: 'betweenBroadcast',
//             startTime: '2023-12-30 00:40:13',
//             endTime: '2023-12-30 00:50:00',
//             title: 'Объявление - 2',
//             content: [
//                 {
//                     startTime: '2023-12-30 00:40:13',
//                     endTime: '2023-12-30 00:45:00',
//                     source: {
//                         id: 32474323,
//                         fileType: 'video',
//                         name: 'Рекламный видео ролик - 1.mp4',
//                     },
//                 },
//                 {
//                     startTime: '2023-12-30 00:45:00',
//                     endTime: '2023-12-30 00:50:00',
//                     source: {
//                         id: 32123323,
//                         fileType: 'audio',
//                         name: 'Рекламная аудио дороджка.mp4',
//                     },
//                 },
//             ],
//         },
//     ],
// }

const AdvertisingCampaignsAdvBlockMethods = () => {
    const dispatch = useDispatch()
    const [state, setState] = useState<IAdvBlockState>({
        over: {},
        deleteData: null,
        selectedBlockIndex: null,
        selectedDeviceIndex: null,
        selectedBlock: null,
        selectedDevice: null,
        isGroupSelected: false,
        scaleHeight: 0,
        duration: 0,
        date: moment(),
        interval: '60',
        intervalFormat: 'min',
        startTime: '00:00',
        gridInProgress: false,
    })
    const [adGrid, setAdGrid] = useState<IGrid | null>(null)
    const [selectedAdBlock, setSelectedAdBlock] = useState(null)
    const [devices, setDevices] = useState<Device[]>([])
    const [showError, setShowError] = useState<string | null>(null)

    useEffect(() => {
        api.send('getDSWithAdvertisements', {}).then((res: any) => {
            if (res && res.length) {
                setDevices(res)
            }
        })
    }, [])
    useEffect(() => {
        const listenersId: string[] = []
        api.addObserver('broadcastGridReceived', broadcastGridReceived, listenersId)
        api.addObserver('broadcastGridError', setBroadcastError, listenersId)

        return () => {
            listenersId.forEach((id) => api.removeObserver(id))
        }
    }, [state.selectedDevice])
    useEffect(() => {
        if (state.intervalFormat === 'min') {
            if (+state.interval > 60) {
                setState((prevState) => {
                    return {
                        ...prevState,
                        interval: '60',
                    }
                })
            }
        }
    }, [state.intervalFormat])
    useEffect(() => {
        setSelectedAdBlock(null)
        setAdGrid(null)
    }, [state.selectedDevice])
    useEffect(() => {
        const interval = setInterval(() => {
            checkActualStartTime()
        }, 1000)

        return () => clearInterval(interval)
    }, [state.startTime, state.date])

    const checkActualStartTime = () => {
        const startTime = state.startTime
        const dateNow = moment()
        const time: string[] = startTime.split(':')
        const selectedDateTime = moment(state.date).set({
            h: +time[0],
            m: +time[1],
            s: 0,
        })

        const isOldDate = dateNow.diff(selectedDateTime, 'seconds')

        if (isOldDate > 0) {
            setState((prevState) => {
                return {
                    ...prevState,
                    startTime: dateNow.format('HH:mm'),
                }
            })
        }
    }
    const setBroadcastError = (err: any) => {
        setState((prevState) => {
            return {
                ...prevState,
                gridInProgress: false,
            }
        })
        const error = err.error

        switch (error) {
            case 78:
                setShowError(translate('noAdvertisementOnSelectedInterval'))
                break
            case 79:
                setShowError(translate('noScheduleOnSelectedInterval'))
                break
            default:
                dispatch(emitError(error))
        }
    }
    const getTimeInterval = (grid: IGrid) => moment(grid.endTime).diff(grid.startTime, 'seconds')
    const broadcastGridReceived = (data: any) => {
        if (!data) return

        console.log('Received broadcast grid from device:')
        console.log(data.response)

        setState((prevState) => {
            return {
                ...prevState,
                gridInProgress: false,
            }
        })

        if (state.selectedDevice && state.selectedDevice.id === data.digitalSignageId) {
            setAdGrid(data.response)
        }
    }
    const generateGrid = () => {
        setAdGrid(null)

        setState((prevState) => {
            return {
                ...prevState,
                gridInProgress: true,
            }
        })
        const time: string[] = state.startTime.split(':')

        api.send('getAdvertisementGridByDSId', {
            // @ts-ignore
            digitalSignageId: state.selectedDevice.id,
            date: moment(state.date)
                .set({
                    h: +time[0],
                    m: +time[1],
                    s: 0,
                })
                .format('YYYY-MM-DD HH:mm:ss'),
            intervalS: state.intervalFormat === 'min' ? +state.interval * 60 : +state.interval,
            utcOffsetMinutes: moment().utcOffset(),
        })
    }
    const onChangeDate = (date: string) => {
        setState((prevState) => {
            return {
                ...prevState,
                date: moment(date),
            }
        })
    }
    const onChangeStartTime = (time: string) => {
        setState((prevState) => {
            return {
                ...prevState,
                startTime: time,
            }
        })
    }
    const onChangeInterval = (interval: string) => {
        let formattedInterval = 0

        if (state.intervalFormat === 'sec') {
            formattedInterval = +interval > 3600 ? 3600 : +interval
        }
        if (state.intervalFormat === 'min') {
            formattedInterval = +interval > 60 ? 60 : +interval
        }

        setState((prevState) => {
            return {
                ...prevState,
                interval: formattedInterval.toString(),
            }
        })
    }

    return {
        s_: state,
        setState,
        onChangeDate,
        onChangeInterval,
        onChangeStartTime,
        generateGrid,
        adGrid,
        selectedAdBlock,
        setSelectedAdBlock,
        devices,
        showError,
        getTimeInterval,
    }
}

export default AdvertisingCampaignsAdvBlockMethods
