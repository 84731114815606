import React from 'react';

class InstancesCatalogSamsoniteMethods extends React.Component {
    state = {
        selectedItems: [],
        category: 'sites'
    };

    componentDidMount() {
        this.changeCategory(this.state.category, this.props.category);
    }

    shouldComponentUpdate(p_) {
        const s_ = this.state;
        this.changeCategory(s_.category, p_.category);

        return true;
    }

    changeCategory = (prevCategory, nextCategory) => {
        if (nextCategory && nextCategory !== prevCategory) {
            this.setState({
                category: nextCategory,
                selectedItems: []
            });
        }
    };
}

export default InstancesCatalogSamsoniteMethods