import { addStyles } from 'ethcss'
import { grid, IStyles, item, mediaCSS } from '../../theme'

const draftDevicesStyles: IStyles = {
    breadcrumbs: {
        ...grid.mb_md,
        ...grid.pl_mdPlus,
        ...item.white,
        marginLeft: -grid.m_mdPlus['margin'],
        marginTop: -grid.m_mdPlus['margin'],
        width: `calc(100% + ${2 * grid.m_mdPlus['margin']}px)`,
        [mediaCSS.tabMini]: {
            marginTop: -grid.m_mini['margin'],
            marginLeft: -grid.m_mini['margin'],
        },
    },
}

addStyles(draftDevicesStyles)
export default draftDevicesStyles
