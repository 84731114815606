import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { IStyles } from '../../../theme'

const searchbarFilterListStyles: IStyles = {
    filterOptions: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.pb_mini,
        ...grid.pl_mini,
        ...item.rounded_full_micro,
        ...item.white,
    },
    filterContent: {
        ...grid.space,
        ...grid.row,
        ...item.fadeRight,
        overflow: 'hidden',
        ...item.rel,
        width: 1,
    },
}

addStyles(searchbarFilterListStyles)
export default searchbarFilterListStyles
