import { addStyles } from 'ethcss'
import { IStyles, item } from 'theme'
import { indents, textSizes } from 'theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import grid from 'blocks.simple/grid/grid.jcss'

export const authorizationLoginStyles: IStyles = {
    authorizationLogin__link: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    authorizationLogin__text: {
        padding: `${indents.mini}px 0`,
        fontSize: textSizes.mini,
        color: `var(--var-module-app-component-app-style-placeholder, ${defaultThemeStyles.placeholder})`,
    },
    errorMessageAuthTimer: {
        color: defaultThemeStyles.error,
        ...grid.mt_micro,
    },
    authorizationLogin__editableText: {
        background: 'none',
        color: `var(--var-module-app-component-app-style-textMain, ${defaultThemeStyles.textMain})`,
    },
    authorizationLogin__email: {
        ...item.ellipsisPure,
        fontWeight: 'bold',
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
        padding: indents.miniPlus,
        fontSize: textSizes.mini,
    },
    disableShowPasswordButton: {
        '& svg': {
            display: 'none',
        },
    },
}

addStyles(authorizationLoginStyles)
