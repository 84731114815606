import React, { FC, useMemo, useState } from 'react'
import Icon from 'blocks.simple/icon/icon'
import { cn } from 'ethcss'
import { styles } from './Slider-styles'
import Animation from 'blocks.simple/animation/animation'
import { SliderProps } from './Slider-types'
import { isExist } from 'core/utils/coreUtil'

const mockFunction = () => {}

const getTransitionName = (directory: string) => `sliderMove-${directory}`

export const Slider: FC<SliderProps> = ({
    initialIndex = 0,
    template = 'row',
    contentClassName = '',
    className = '',
    isShowArrows = true,
    count = 5,
    children = [],
    onChangeSlide = mockFunction,
    leftArrow: LeftArrowComponent = null,
    rightArrow: RightArrowComponent = null,
}) => {
    const [index, setIndex] = useState<any>(initialIndex)
    const [dir, setDir] = useState<string>('right')

    const filteredSlides = useMemo(() => {
        return children ? children.filter((item) => isExist(item)) : []
    }, [children])

    const slide = useMemo(() => {
        return filteredSlides.slice(index, count + index)
    }, [filteredSlides, index, count])

    const isEnableLeft = () => index > 0
    const isEnableRight = () => index + count < filteredSlides.length

    const onMoveLeft = () => {
        if (!isEnableLeft()) return

        const newIndex = index - count

        setDir('left')
        setIndex(newIndex)
        onChangeSlide(newIndex)
    }

    const onMoveRight = () => {
        if (!isEnableRight()) return

        const newIndex = index + count

        setDir('right')
        setIndex(newIndex)
        onChangeSlide(newIndex)
    }

    const isActiveAnimation = (childLength: number) => childLength > 1

    const renderDefaultLeftArrow = () => {
        return (
            <div
                className={cn(styles.Slider__arrow, { [styles.Slider__arrow_disabled]: !isEnableLeft() })}
                onClick={onMoveLeft}
            >
                <Icon disabled={!isEnableLeft()} type={'arrow_left'} />
            </div>
        )
    }

    const renderDefaultRightArrow = () => {
        return (
            <div
                className={cn(styles.Slider__arrow, { [styles.Slider__arrow_disabled]: !isEnableRight() })}
                onClick={onMoveRight}
            >
                <Icon disabled={!isEnableRight()} type={'arrow_right'} />
            </div>
        )
    }

    const renderLeftArrow = () => {
        if (LeftArrowComponent) return <LeftArrowComponent isDisabled={!isEnableLeft()} onClick={onMoveLeft} />

        return renderDefaultLeftArrow()
    }

    const renderRightArrow = () => {
        if (RightArrowComponent) return <RightArrowComponent isDisabled={!isEnableRight()} onClick={onMoveRight} />

        return renderDefaultRightArrow()
    }

    return (
        <div className={cn(styles.Slider, className)}>
            {isShowArrows && renderLeftArrow()}

            <Animation
                className={cn(styles.Slider__animation)}
                activeClassName={cn(styles.Slider__animation_active)}
                transitionName={getTransitionName(dir)}
                isActive={isActiveAnimation}
            >
                <div
                    className={cn(styles.Slider__content, contentClassName, {
                        [styles.Slider__content_type_row]: template === 'row',
                        [styles.Slider__content_type_col]: template === 'col',
                    })}
                    key={index}
                >
                    {slide}
                </div>
            </Animation>

            {isShowArrows && renderRightArrow()}
        </div>
    )
}
