import React, { useCallback, useEffect, useRef, useState } from 'react'
import Icon from '../../blocks.simple/icon/icon'
import styles from './ScrollableFilterList-styles'
import { cn } from 'ethcss'

let shiftLeft = 0

const getChildrenLength = (children: any) => {
    if (children.props.children) {
        return children.props.children.filter((child: any) => child !== null).length
    }

    return 0
}

export const ScrollableFilterListView = ({ children, refreshArrowsTrigger }: any) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [isAvailableArrows, setIsAvailableArrows] = useState(true)
    const [isRightLimit, setIsRightLimit] = useState(false)
    const filterListRef = useRef<any>(null)

    const slideReset = () => {
        const className = children.props.className
        const list: any = document.querySelector('.' + className)
        list.style.transform = `translateX(0)`
        setCurrentIndex(0)
    }

    const slideList = useCallback(
        (direction) => {
            if (!children) return

            const isNext = direction === 'next'
            const className = children.props.className
            const list: any = document.querySelector('.' + className)
            const items = list.children
            const defaultStep = 1
            const actionStep = isNext
                ? currentIndex + defaultStep < items.length - 1
                    ? defaultStep
                    : items.length - 1 - currentIndex
                : currentIndex - defaultStep > 0
                ? defaultStep
                : 0
            const item =
                items[
                    isNext
                        ? currentIndex + actionStep
                        : currentIndex - actionStep === currentIndex
                        ? 0
                        : currentIndex - actionStep
                ]

            if (!item) return

            const moveValue = -item.offsetLeft

            setIsRightLimit(item.offsetLeft > list.offsetWidth)

            list.style.transform = `translateX(${moveValue + shiftLeft}px)`

            setCurrentIndex(
                isNext
                    ? currentIndex + actionStep
                    : currentIndex - actionStep === currentIndex
                    ? 0
                    : currentIndex - actionStep
            )
            shiftLeft = 0
        },
        [children.length, currentIndex]
    )

    const checkAvailableArrows = useCallback(() => {
        if (children) {
            const className = children.props.className
            const list: any = document.querySelector('.' + className)

            if (list) {
                let itemsSum = 0
                const items = Array.from(list.children)

                items.forEach((item: any) => {
                    itemsSum += item.offsetWidth
                })

                //@ts-ignore
                setIsAvailableArrows(itemsSum + 60 > filterListRef.current.offsetWidth)
            }
        }
    }, [children])

    useEffect(() => {
        slideReset()
        checkAvailableArrows()

        window.addEventListener('resize', checkAvailableArrows)

        return () => window.removeEventListener('resize', checkAvailableArrows)
    }, [children.length, refreshArrowsTrigger])

    return (
        <div
            ref={filterListRef}
            className={styles.scrollableFilterList}
            style={{ padding: isAvailableArrows ? '0 30px' : '0' }}
        >
            {isAvailableArrows ? (
                <Icon
                    containerClass={cn(styles.arrow, styles.arrowLeft)}
                    onClick={() => slideList('prev')}
                    type={'left_icon_menu'}
                    size={14}
                    disabled={currentIndex === 0}
                />
            ) : null}
            <div className={styles.scrollArea}>{children}</div>
            {isAvailableArrows ? (
                <Icon
                    containerClass={cn(styles.arrow, styles.arrowRight)}
                    onClick={() => slideList('next')}
                    type={'right_icon_menu'}
                    size={14}
                    disabled={currentIndex >= getChildrenLength(children) - 1 || isRightLimit}
                />
            ) : null}
        </div>
    )
}
