import { addStyles } from 'ethcss'
import { colors, IStyles } from '../../../theme'

const styles: IStyles = {
    modalInfoWrapper: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 10000,
    },
    modalInfo: {
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '20px',
        padding: '40px',
        maxWidth: '560px',
        textAlign: 'center',
        fontSize: '16px',
        lineHeight: 1.2,
        color: colors.grey,
    },
    modalInfoMessage: {
        marginTop: '34px',
    },
    closeBtn: {
        margin: '34px auto 0 auto',
    },
    toDownloads: {
        borderBottom: '1px solid',
        cursor: 'pointer',
        color: colors.black,

        '&:hover': {
            borderBottom: 'none',
        },
    },
    progress: {
        marginTop: '20px',
    },
    progressTitle: {
        marginBottom: '16px',
    },
    progressItem: {
        marginBottom: '10px',
    },
}

addStyles(styles)
export default styles
