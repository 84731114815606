import React from 'react'
import PropTypes from 'prop-types'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import EditText from 'blocks.simple/editText/editText'

import translate from 'core/translate'
import template from 'es6-template-strings'
import deepEqual from 'fast-deep-equal'

import { cn } from 'ethcss'
import styles from './editor__conditionSettings.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import settingsStyles from '../__areaSettings/editor__areaSettings.jcss'
import Collapse from 'blocks.simple/collapse/collapse'

const defaultState = {
    localPath: '',
    node: '',
    comparisonType: 'EMPTY_STRING',
    value: '',
    type: 'xml',
    row: 0,
    column: 0,
}

// Получить объект условий
const getPlayingCondition = ({ type, node, row, column, value, comparisonType }) => {
    if (type === 'xml') {
        return {
            node,
            comparisonType,
            value,
        }
    }
    if (type === 'csv') {
        return {
            row: parseInt(row, 10),
            column: parseInt(column, 10),
            comparisonType,
            value,
        }
    }
}

class EditorConditionSettings extends React.Component {
    state = {
        ...defaultState,
    }

    types = [{ id: 'csv' }, { id: 'xml' }]
    componentDidMount() {
        const p_ = this.props

        this.setPlayingConditions(p_)
    }
    componentDidUpdate(prevProps) {
        const p_ = this.props

        if (!deepEqual(prevProps.playingCondition, p_.playingCondition)) {
            this.setPlayingConditions(p_)
        }
    }
    // Установить условия проигрывания из props
    setPlayingConditions = (p_) => {
        if (!Object.keys(p_.playingCondition).length) {
            this.setState({ ...defaultState })

            return
        }

        this.setState({
            type: p_.playingCondition.type,
            localPath: p_.playingCondition.localPath,
            node: p_.playingCondition.condition.node,
            comparisonType: p_.playingCondition.condition.comparisonType,
            value: p_.playingCondition.condition.value,
            row: p_.playingCondition.condition.row,
            column: p_.playingCondition.condition.column,
        })
    }

    // Изменение свойств state
    onChange = (value, key) => {
        this.setState({ [key]: value })
    }

    // Изменить условия проигрывания
    changePlayingCondition = () => {
        const p_ = this.props
        const s_ = this.state

        p_.onChange({
            type: s_.type,
            localPath: s_.localPath,
            condition: getPlayingCondition(s_),
        })
    }
    // Установить тип
    changeType = (type) => {
        this.setState({
            type,
            comparisonType: 'EMPTY_STRING',
        })
    }

    getConditionTitle = () => {
        return (
            <div className={settingsStyles.collapseText}>
                <div className={item.ellipsis}>{translate('playingCondition')}</div>
            </div>
        )
    }

    onChangeComparisonType = (selected) => {
        const initialValue = selected.id === 'EMPTY_STRING' ? true : ''

        this.setState(
            (prevState) => {
                return {
                    ...prevState,
                    value: initialValue,
                    comparisonType: selected.id,
                }
            },
            () => {
                this.changePlayingCondition()
            }
        )
    }

    onChangeEmptyStringValue = (selected) => {
        this.setState(
            (prevState) => {
                return {
                    ...prevState,
                    value: selected.id,
                }
            },
            () => {
                this.changePlayingCondition()
            }
        )
    }

    render() {
        const s_ = this.state
        const Content = () => {
            return (
                <div>
                    <div className={styles.tabs}>
                        {this.types.map((type, index) => (
                            <div
                                key={`typeTab_${index}`}
                                className={cn(styles.tab, grid[`col${this.types.length}`], {
                                    [styles.tabActive]: type.id === s_.type,
                                })}
                                onClick={() => this.changeType(type.id)}
                            >
                                {type.id}
                            </div>
                        ))}
                    </div>
                    <div>
                        <div className={styles.row}>
                            <EditText
                                placeholder={`D:\\example\\file.${s_.type}`}
                                type={'text'}
                                value={s_.localPath}
                                label={template(translate('localPath'), { type: s_.type })}
                                onChange={(value) => this.onChange(value, 'localPath')}
                                indentSize={'mini'}
                                onEndTyping={this.changePlayingCondition}
                            />
                        </div>
                        {s_.type === 'xml' && (
                            <div className={styles.row}>
                                <EditText
                                    type={'text'}
                                    value={s_.node}
                                    label={translate('node')}
                                    onChange={(value) => this.onChange(value, 'node')}
                                    indentSize={'mini'}
                                    onEndTyping={this.changePlayingCondition}
                                />
                            </div>
                        )}
                        {s_.type === 'csv' && (
                            <div className={styles.row}>
                                <div className={cn(grid.w50, grid.pr_mini)}>
                                    <EditText
                                        type={'number'}
                                        value={s_.column}
                                        label={translate('column')}
                                        onChange={(value) => this.onChange(value, 'column')}
                                        indentSize={'mini'}
                                        onEndTyping={this.changePlayingCondition}
                                    />
                                </div>
                                <div className={cn(grid.w50)}>
                                    <EditText
                                        type={'number'}
                                        value={s_.row}
                                        label={translate('row')}
                                        onChange={(value) => this.onChange(value, 'row')}
                                        indentSize={'mini'}
                                        onEndTyping={this.changePlayingCondition}
                                    />
                                </div>
                            </div>
                        )}
                        <div className={styles.rowNoAlignCenter}>
                            <div className={cn(grid.w50, grid.pr_mini)}>
                                <Dropdown
                                    disabled={s_.type === 'csv'}
                                    containerClassName={cn(grid.w100)}
                                    value={s_.comparisonType}
                                    options={[
                                        {
                                            id: 'EMPTY_STRING',
                                            name: s_.type === 'csv' ? translate('emptyCell') : translate('emptyString'),
                                        },
                                        { id: 'EQUAL', name: translate('equal') },
                                    ]}
                                    label={translate('comparisonType')}
                                    centered={true}
                                    onChange={this.onChangeComparisonType}
                                    indentSize={'mini'}
                                    isRelativeList={true}
                                />
                            </div>
                            <div className={cn(grid.w50)}>
                                {s_.comparisonType !== 'EMPTY_STRING' && (
                                    <EditText
                                        type={'text'}
                                        value={s_.value}
                                        label={translate('comparisonValue')}
                                        onChange={(value) => this.onChange(value, 'value')}
                                        indentSize={'mini'}
                                        onEndTyping={this.changePlayingCondition}
                                    />
                                )}
                                {s_.comparisonType === 'EMPTY_STRING' && (
                                    <Dropdown
                                        containerClassName={cn(grid.w100)}
                                        value={s_.value}
                                        options={[
                                            { id: true, name: translate('yes') },
                                            { id: false, name: translate('no') },
                                        ]}
                                        label={translate('comparisonValue')}
                                        centered={true}
                                        onChange={this.onChangeEmptyStringValue}
                                        indentSize={'mini'}
                                        isRelativeList={true}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <>
                {!this.props.noCollapse ? (
                    <Collapse
                        title={() => this.getConditionTitle()}
                        titleClassName={settingsStyles.collapseTitle}
                        activeClassName={settingsStyles.collapseActive}
                        className={settingsStyles.collapse}
                        withoutOverflow={true}
                        initialOpen={true}
                    >
                        {Content()}
                    </Collapse>
                ) : (
                    Content()
                )}
            </>
        )
    }
}

EditorConditionSettings.propTypes = {
    playingCondition: PropTypes.object,
    onChange: PropTypes.func,
}

EditorConditionSettings.defaultProps = {
    playingCondition: {},
}

export default EditorConditionSettings
