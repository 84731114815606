import React, { useState } from 'react'
import Content from 'blocks.app/content/content'
import AdvBlock from '../__advBlock/advertisingCampaigns__advBlock'
import { api } from 'core/api/ConnectionManager'
import moment from 'moment'
import { useOnMount } from 'core/hooks'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'
import { Block } from '../__advBlock/advertisingCampaigns__advBlock.local.methods'

const AdvertisingCampaignsAdvBlocks = () => {
    const [blocks, setBlocks] = useState<Block[]>([])
    const [date, setDate] = useState<any>(moment().endOf('day').format())
    const [selectedBlockData, setSelectedBlockData] = useState(null)

    useOnMount(() => {
        getBlocks()
    })

    function getBlocks(cb = () => {}) {
        api.send('getAdBlocks', { date }).then((blocks: any) => {
            setBlocks(blocks)
            cb()
        })
    }

    const onChangeDate = (newDate: string, cb: any) => {
        const date = moment(newDate).endOf('day')

        setDate(date)
        getBlocks(cb)
    }

    const onChange = (selected: any) => setSelectedBlockData(selected)
    const onUpdateBlocks = (blocks: Block[], digitalSignageId: number[]) => {
        setBlocks(blocks)
        api.send('deleteAdBlock', { digitalSignageId })
    }

    return (
        <Content title="advertisingBlocks">
            <AdvBlock
                date={date}
                onChangeDate={onChangeDate}
                selectedBlockData={selectedBlockData}
                onChange={onChange}
                blocks={blocks}
                onUpdateBlocks={onUpdateBlocks}
            />
        </Content>
    )
}

const exportAdvertisingCampaignsAdvBlocks = checkAvailableModule('adBlocks/advertisingBlocks')
    ? AdvertisingCampaignsAdvBlocks
    : null

export default exportAdvertisingCampaignsAdvBlocks
