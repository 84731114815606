import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { mediaCSS } from 'theme/media'
import { IStyles } from '../../../theme'

const deviceSettingsAcceptStyles: IStyles = {
    modalWrapper: {
        ...grid.full,
        ...grid.rowCenter,
    },
    modalInner: {
        ...grid.w35,
        [mediaCSS.tabMini]: {
            ...grid.w90,
        },
        ...item.card,
        ...item.shadowModal,
        ...item.rounded_full_mini,
        textAlign: 'center',
    },
    title: {
        ...grid.m_md,
        textTransform: 'uppercase',
    },
    confirmText: {
        ...grid.mt_mdPlus,
        ...grid.mb_mdPlus,
    },
    footer: {
        ...grid.row,
        ...grid.center,
        ...grid.mb_md,
    },
    button: {
        ...grid.mr_mini,
    },
}

addStyles(deviceSettingsAcceptStyles)
export default deviceSettingsAcceptStyles
