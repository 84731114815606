import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { IStyles } from '../../../theme'

const deviceSettingsNoSettingsStyles: IStyles = {
    wrapper: {
        ...grid.row,
        ...grid.full,
        ...grid.center,
        ...grid.normalCenter,
    },
    icon: {
        ...grid.row,
        ...grid.fullWidth,
        ...grid.center,
    },
    description: {
        ...grid.mt_mini,
    },
}

addStyles(deviceSettingsNoSettingsStyles)
export default deviceSettingsNoSettingsStyles
