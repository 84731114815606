import React from 'react'
import { connect } from 'react-redux'
import DisplaysDateTimeMethods from './displays__datetime.local.methods'
import Button from 'blocks.simple/button/button'
import EditText from 'blocks.simple/editText/editText'
import translate from 'core/translate'
import LoaderLazy from 'blocks.app/loader/_lazy/loader__lazy'
import { cn } from 'ethcss'
import styles from './displays__datetime.jcss'
import { grid, item } from 'theme'
import { text as textStyles } from 'theme'
import { Typography } from 'atoms/Typography'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { displaysActions } from '../displays.state'
import { SwitchButton } from '../../../atoms/SwitchButton'

class DisplaysDateTime extends DisplaysDateTimeMethods {
    render() {
        const s_ = this.state
        const p_ = this.props
        const isGroup = p_.selectedInfo.type === 'group'

        return (
            <div className={styles.modalWrapper}>
                <div className={styles.modalInner}>
                    {s_.isLoading && !isGroup && (
                        <div className={styles.loader}>
                            <div />
                            <div>
                                <LoaderLazy active />
                                {translate('getClientData')}
                            </div>
                            <Button
                                textSize={'mini'}
                                indentSize={'mini'}
                                rounded={'full_normal'}
                                onClick={function () {
                                    p_.closeModal()
                                }}
                            >
                                {translate('cancel')}
                            </Button>
                        </div>
                    )}
                    <div className={cn({ [item.hidden]: s_.isLoading && !isGroup })}>
                        <Typography className={styles.title} type="title">
                            {translate('deviceDateTime')}
                        </Typography>
                        <div className={styles.description}>{translate('setDateAndTime')}</div>
                        <div className={styles.content}>
                            <div className={cn(grid.row, grid.mb_md, grid.fullWidth)}>{this.getDateTimeLabel()}</div>
                            <div className={styles.displaysDatetime__inputWrapper_type_date}>
                                <EditText
                                    className={cn(textStyles.left)}
                                    type="date"
                                    dateValue={s_.date}
                                    onChange={(date: string) => this.setState({ date })}
                                    label={translate('date')}
                                    disabled={s_.automaticallyDatetimeQ}
                                />
                            </div>
                            <div className={styles.displaysDatetime__inputWrapper_type_time}>
                                <EditText
                                    className={cn(textStyles.left)}
                                    label={translate('time')}
                                    mask="99:99"
                                    type="tel"
                                    placeholder="__:__"
                                    value={s_.convertedTime}
                                    onChange={(convertedTime: string) => this.setState({ convertedTime })}
                                    onBlur={this.onChangeTime}
                                    disabled={s_.automaticallyDatetimeQ}
                                />
                            </div>
                            <div className={styles.displaysDatetime__inputWrapper_type_automaticTime}>
                                <div className={styles.switchLabel}>{translate('setDateAndTimeAutomatically')}</div>
                                <SwitchButton
                                    onChange={() => {
                                        this.setState({ automaticallyDatetimeQ: !s_.automaticallyDatetimeQ })
                                    }}
                                    checked={s_.automaticallyDatetimeQ}
                                />
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            <div className={grid.row}>
                                <Button
                                    mod={'withBorder'}
                                    className={cn(grid.mr_mini)}
                                    textSize={'mini'}
                                    indentSize={'mini'}
                                    rounded={'full_normal'}
                                    onClick={function () {
                                        p_.closeModal()
                                    }}
                                >
                                    {translate('cancel')}
                                </Button>
                                <Button
                                    mod={'fill'}
                                    textSize={'mini'}
                                    indentSize={'mini'}
                                    rounded={'full_normal'}
                                    onClick={this.save}
                                >
                                    {translate('apply')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        emitError: (err: string) => dispatch(emitError(err)),
        closeModal: () => dispatch(displaysActions.closeModal()),
    }
}

const mapStateToProps = (state: {
    user: {
        data: {
            settings: {
                locale: string
            }
        }
    }
    app: {
        timezones: string[]
    }
}) => {
    return {
        lang: state.user.data.settings.locale,
        timezones: state.app.timezones,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplaysDateTime)
