import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IDisplaysAddGroupData {
    data: object
    isEdit: boolean
    error: null | string
}

export interface IDisplaysAddGroupState {
    data: object
    isEdit: boolean
    error: null | string
}

const initialState: IDisplaysAddGroupState = {
    data: {},
    isEdit: false,
    error: null,
}

const displaysAddGroupSlice = createSlice({
    name: 'displays__addGroup',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<IDisplaysAddGroupData>) => {
            const { data, isEdit, error } = action.payload

            state.data = data
            state.isEdit = isEdit
            state.error = error
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload
        },
        clearData: (state) => {
            state.data = {}
            state.isEdit = false
            state.error = null
        },
        setPartialData: (state, action: PayloadAction<object>) => {
            state.data = { ...state.data, ...action.payload }
        },
    },
})

const { reducer: displaysAddGroupReducer, actions: displaysAddGroupActions } = displaysAddGroupSlice
export { displaysAddGroupReducer, displaysAddGroupActions }
