import React, { FC, memo, useState } from 'react'
import { IFixedHeaderWrapperProps, IFixedHeaderDimensions } from './FixedHeaderWrapper-types'
import { styles } from './FixedHeaderWrapper-styles'
import Measure, { ContentRect } from 'react-measure'
import { indents, cols } from 'theme'
import { cn } from 'ethcss'
import { isHorizontalMenu } from 'core/helpers/menu/index'

export const FixedHeaderWrapperComponent: FC<IFixedHeaderWrapperProps> = ({ children, positionStyles }) => {
    const [dimensions, setDimensions] = useState<IFixedHeaderDimensions>({
        width: 0,
        height: 0,
    })

    const handleHeaderResize = (resizeParams: ContentRect) => {
        if (!resizeParams.bounds) return

        setDimensions(resizeParams.bounds)
    }

    const horizontalMenu = isHorizontalMenu()

    return (
        <div className={cn(styles.FixedHeaderWrapper)}>
            <Measure bounds onResize={handleHeaderResize}>
                {({ measureRef }) => (
                    <div
                        ref={measureRef}
                        className={
                            horizontalMenu
                                ? dimensions.width > 960 && styles.FixedHeaderWrapper__content_x
                                : styles.FixedHeaderWrapper__content
                        }
                        style={positionStyles}
                    >
                        {children}
                    </div>
                )}
            </Measure>
            {dimensions.height && (
                <div
                    style={{
                        height: horizontalMenu ? '30px' : `${dimensions.height}px`,
                        marginTop: -indents.mdPlus,
                        visibility: 'hidden',
                    }}
                />
            )}
        </div>
    )
}

export const FixedHeaderWrapper = memo(FixedHeaderWrapperComponent)
