import React, { FC, memo } from 'react'
import { IConfirmNotificationProps } from './ConfrimNotification-types'
import { styles } from './ConfirmNotiifcation-styles'
import Button from 'blocks.simple/button/button'

const ConfirmNotificationComponent: FC<IConfirmNotificationProps> = ({
    onReject,
    onConfirm,
    text,
    confirmButtonText,
    rejectButtonText,
}) => {
    return (
        <div className={styles.ConfirmNotification}>
            <div className={styles.ConfirmNotification__text}>{text}</div>
            <div className={styles.ConfirmNotification__body}>
                <Button
                    indentSize="mini"
                    type="submit"
                    onClick={onConfirm}
                    className={styles.ConfirmNotification__button_type_confirm}
                    mod="fill"
                >
                    {confirmButtonText}
                </Button>
                <Button indentSize="mini" onClick={onReject}>
                    {rejectButtonText}
                </Button>
            </div>
        </div>
    )
}

export const ConfirmNotification = memo(ConfirmNotificationComponent)
