import { addStyles } from 'ethcss'
import { cols, layers, mediaCSS, item, grid, IStyles } from 'theme'
import { defaultThemeStyles } from './ContentWithoutToolbar.theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'

export const styles: IStyles = {
    ContentWithoutToolbar: {
        ...item.transitionErase,
        ...grid.fullHeight,
        ...item.rel,
        minHeight: '100%',
        background: `var(--var-module-app-component-content-style-background, ${defaultThemeStyles.background})`,
        '& *': {
            fontFamily: `var(--var-module-app-component-app-style-fontFamily, ${defaultThemeAppStyles.fontFamily})!important`,
        },
    },
    ContentWithoutToolbar_custom_theme_background: {
        background: '#FFFFFF',
        '& *': {
            fontFamily: `var(--var-module-app-component-app-style-fontFamily, ${defaultThemeAppStyles.fontFamily})!important`,
        },
    },
    ContentWithoutToolbar_type_centered: {
        ...grid.colCenter,
    },
    ContentWithoutToolbar__content: {
        ...grid.col,
        ...grid.space,
        ...item.rel,
        minHeight: '100%',
        [mediaCSS.tabMini]: {
            ...grid.p_mini,
        },
    },
    ContentWithoutToolbar_type_default: {
        width: `${100 - cols.normal}%`,
        [mediaCSS.tab]: {
            ...grid.w100,
        },
        ...item.transitionErase,
        left: `${cols.normal}%`,
        [mediaCSS.tab]: {
            left: 0,
            width: '100%',
        },
    },
    ContentWithoutToolbar_type_default_x: {
        height: 'auto',
        overflow: 'auto',
        width: '100%',
        [mediaCSS.tab]: {
            ...grid.w100,
        },
        ...item.transitionErase,
        [mediaCSS.tab]: {
            left: 0,
            width: '100%',
        },
    },
    ContentWithoutToolbar_type_minimize: {
        width: `${100 - cols.mini}%`,
        ...item.transitionErase,
        left: `${cols.mini}%`,
        [mediaCSS.tab]: {
            left: 0,
            width: '100%',
        },
    },
    content: {},
}

addStyles(styles)
