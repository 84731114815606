import React from 'react'
import PropTypes from 'prop-types'
import { Searchbar } from 'organisms/Searchbar'
import translate from 'core/translate'
import helpers from 'core/helpers'
import deepEqual from 'fast-deep-equal'

import styles from './styles'
import { changeFilter } from 'features/routes'
import { useDispatch } from 'react-redux'
import { updateUserSettings } from 'blocks.app/user/user.state'

const getPrefixField = (type) => {
    switch (type) {
        case 'view':
            return 'disposition'
        default:
            return type
    }
}

const CatalogHeader = (p_) => {
    const dispatch = useDispatch()
    const onChangeFilterType = (selected, type) => {
        const selectedId = selected.value

        dispatch(
            updateUserSettings({
                data: {
                    [type]: {
                        [p_.template.settingsPrefixes]: selectedId,
                    },
                },
                isSaveSettings: true,
            })
        )

        changeFilter({
            [helpers.prefixQuery({ name: p_.type, field: getPrefixField(type) })]: selectedId,
        })
    }

    const handleSelectionChange = (selected) => {
        switch (selected.value) {
            case 'selectAll':
                p_.onSelectAll()
                return
            case 'multiSelect':
                p_.onActivateMultiSelect()
                return
            default:
                p_.onDeselectAll()
        }
    }

    const { sort, disposition } = p_.viewParams

    return (
        <div className={styles.catalogHeaderWrapper}>
            <Searchbar
                customContent={p_.template.headerCustomContent}
                className={styles.searchbarComponent}
                placeholder={p_.placeholder}
                value={p_.query.query}
                onChange={function (value) {
                    changeFilter({ [helpers.prefixQuery({ name: p_.type, field: 'query' })]: value })
                }}
                sort={
                    p_.template.sortOptions && {
                        value: sort,
                        options: p_.template.sortOptions(),
                        onChange: onChangeFilterType,
                    }
                }
                selection={
                    p_.template.multiSelect && {
                        value: p_.multiSelect ? 'multiSelect' : null,
                        options: [
                            { value: 'multiSelect', label: translate('choice') },
                            { value: 'selectAll', label: translate('selectAll') },
                            { value: 'cancelAll', label: translate('cancelAll') },
                        ],
                        onChange: handleSelectionChange,
                    }
                }
                view={
                    p_.template.viewOptions && {
                        onChange: onChangeFilterType,
                        value: disposition,
                        options: p_.template.viewOptions(),
                    }
                }
            />
        </div>
    )
}

CatalogHeader.propTypes = {
    multiSelect: PropTypes.bool,
    query: PropTypes.object,
    prefixQuery: PropTypes.func,
    viewParams: PropTypes.object,
    onActivateMultiSelect: PropTypes.func,
    onSelect: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSelectInfo: PropTypes.func,
    isEqual: PropTypes.func,
    template: PropTypes.object,
}

const getEqualParameters = (parameters) => ({
    multiSelect: parameters.multiSelect,
    query: parameters.query.query,
    viewParams: parameters.viewParams,
})

export default React.memo(CatalogHeader, (prevProps, nextProps) =>
    deepEqual(getEqualParameters(prevProps), getEqualParameters(nextProps))
)
