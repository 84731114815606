import React from 'react'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import styles from './catalog__template_exhibitionsPages.jcss'
import helpers from 'core/helpers'
import { getSourcesIds } from './catalog__template_exhibitionsPages.local.methods'
import { CatalogActionBar } from 'organisms/Catalog/ActionBar/ActionBar-view'
import { ActionBarRequestHeaderType, ActionBarActionType } from 'core/models/ActionBar'
import EditableText from 'blocks.simple/editableText/editableText'
import CatalogSelectContent from '../../../_select/_content/catalog_select_content'
import Button from 'blocks.simple/button/button'
import grid from 'blocks.simple/grid/grid.jcss'
import { cn } from 'ethcss'
import { TextEditor } from 'molecules/TextEditor'
import { ItemData } from '../../../../../core/models/Template'
import { IActionBarComponent } from '../../../../../organisms/Catalog/ActionBar/ActionBar-types'

export interface IExhibitionProps {
    exhibitionPageId: number
    description: string
    sources: any
    title: string
}

const toolbar = {
    options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
}

const getActionList = (p_: any) => {
    return [
        {
            order: 1,
            type: ActionBarActionType.ADD,
            cb: () => {
                p_.onAddFakeLine({ name: '' })
            },
        },
        {
            order: 2,
            type: ActionBarActionType.DELETE,
            cb: () => {
                const exhibitionPageIds = p_.selectedItems.map(
                    (selectedItem: { exhibitionPageId: number }) => selectedItem.exhibitionPageId
                )

                if (exhibitionPageIds.length > 0) {
                    api.send('deleteExhibitionPage', { exhibitionPageId: exhibitionPageIds })
                }

                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            },
        },
    ]
}

export default {
    getMethod: 'getExhibitionPages',
    customParams: {
        limit: 35,
    },
    trNoClick: true,
    disableDrag: true,
    emptySearchText: () => translate('notFound'),
    emptyDescription: () => translate('createFirstPosition'),
    emptyFolderText: () => translate('listIsEmpty'),
    emptyIcon: 'not_allow',
    addItemButton: () => null,
    settingsPrefixes: 'exhibitionsPages',
    sortOptions: () => [],
    headerFilters: (p_: IActionBarComponent) => {
        return (
            <CatalogActionBar
                title={translate('exhibitionsPages')}
                requestHeaderType={ActionBarRequestHeaderType.WITH_AUTH}
                type="exhibitionsPages"
                actions={getActionList(p_)}
                {...p_}
            />
        )
    },
    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean) => {
        const item: any = { ...dataItem }

        if (item.media && !item.media.__view) {
            item.media.__view = {
                selected: getSelected(item),
            }
        }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    getBackendModel: () => {},

    tableWrapper: styles.tableWrapper,

    isEqual: (firstItem: ItemData, secondItem: ItemData) => {
        if (firstItem.exhibitionPageId && secondItem.exhibitionPageId) {
            return firstItem.exhibitionPageId === secondItem.exhibitionPageId
        }

        return firstItem === secondItem
    },

    onCreateListeners: [
        (callback: any, clearList: string[]) => {
            return api.addObserver(
                'exhibitionPageCreated',
                (page: object) => {
                    callback({ ...page })
                },
                clearList
            )
        },
    ],
    onUpdateListeners: [
        (callback: any, clearList: string[]) => {
            return api.addObserver(
                'exhibitionPageUpdated',
                (page: object) => {
                    callback({ ...page })
                },
                clearList
            )
        },
    ],
    onDeleteListeners: [
        (callback: any, clearList: string[]) =>
            api.addObserver(
                'exhibitionPageDeleted',
                (deletedItems: { exhibitionPageId: number[] }) => {
                    deletedItems.exhibitionPageId.forEach((id) => {
                        callback({ exhibitionPageId: id })
                    })
                },
                clearList
            ),
    ],
    isFolder: () => {},
    fields: () => [
        { id: 'sources', name: translate('media') },
        { id: 'title', name: translate('title') },
        { id: 'description', name: translate('instanceDescription') },
    ],
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        sources: (p_: IExhibitionProps, active: boolean, listProps: { onAddFakeLine: any; fakeLine: object }) => (
            <div>
                <CatalogSelectContent
                    id={p_.exhibitionPageId}
                    type={'mediaAndFolder'}
                    selectedInfo={
                        helpers.isListExist(p_.sources)
                            ? {
                                  ...p_.sources[0],
                                  __view: {
                                      selected: false,
                                  },
                              }
                            : null
                    }
                    onSelect={(selectedMedia: { extension: string; id: number; content: string }) => {
                        if (p_.exhibitionPageId) {
                            getSourcesIds(selectedMedia).then((res) => {
                                api.send('updateExhibitionPage', {
                                    exhibitionPageId: p_.exhibitionPageId,
                                    sources: res,
                                })
                            })
                        } else {
                            getSourcesIds(selectedMedia).then((res) => {
                                listProps.onAddFakeLine({
                                    ...listProps.fakeLine,
                                    sources: res,
                                })
                            })
                        }
                    }}
                />
            </div>
        ),
        title: (p_: IExhibitionProps, active: boolean, listProps: { onAddFakeLine: any; fakeLine: object }) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.title })}>
                <div className={cn({ [grid.fullWidth]: p_.title, [grid.space]: !p_.title })}>
                    <EditableText
                        id={p_.exhibitionPageId}
                        placeholder={translate('enterTitle')}
                        text={helpers.lineBreak(helpers.quotesConverter(p_.title, 'string'), 'string')}
                        onChange={(title: string) => {
                            listProps.onAddFakeLine({
                                ...listProps.fakeLine,
                                title: helpers.lineBreak(helpers.quotesConverter(title, 'html'), 'html'),
                            })
                        }}
                        onUpdate={(title, cb) => {
                            api.send('updateExhibitionPage', {
                                exhibitionPageId: p_.exhibitionPageId,
                                title: helpers.lineBreak(helpers.quotesConverter(title, 'html'), 'html'),
                            }).then(cb)
                        }}
                    />
                </div>
            </div>
        ),
        description: (p_: IExhibitionProps, active: boolean, listProps: { onAddFakeLine: any; fakeLine: any }) => (
            <div className={cn(grid.fullWidth)}>
                <TextEditor
                    id={p_.exhibitionPageId}
                    placeholder={translate('enterDescription')}
                    text={p_.description ? p_.description : ''}
                    previewStyles={styles.preview}
                    toolbar={toolbar}
                    onChange={(description: string, cb: any) => {
                        if (!p_.exhibitionPageId) {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, description })
                            return
                        }

                        api.send('updateExhibitionPage', {
                            exhibitionPageId: p_.exhibitionPageId,
                            description: helpers.lineBreak(helpers.quotesConverter(description, 'html'), 'html'),
                        }).then(cb)
                    }}
                />
                {!p_.exhibitionPageId && (
                    <Button
                        mod={'fill'}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            api.send('createExhibitionPage', {
                                sources: listProps.fakeLine.sources,
                                title: listProps.fakeLine.title,
                                description: listProps.fakeLine.description,
                            })
                        }}
                        disabled={
                            !listProps.fakeLine.title || !listProps.fakeLine.sources || !listProps.fakeLine.description
                        }
                    >
                        {translate('save')}
                    </Button>
                )}
            </div>
        ),
    },
    hideActiveRowColor: true,
    disableUpdateUserSettings: true,
    templateCellsWidth: {
        sources: '6%',
        title: '32%',
        description: '60%',
    },
    disableListScroll: true,
    fixedColWidth: false,
    draggable: false,
    dndDblClickDisable: true,
}
