import React, { FC } from 'react'
import { MapLocationListProps } from './MapLocationList-types'
import { styles } from './MapLocationList-styles'
import { CustomScroll } from 'atoms/CustomScroll'

export const MapLocationList: FC<MapLocationListProps> = ({ list, onSelect }) => {
    const renderList = () => {
        return list.map((item, index) => {
            const { name, description } = item
            const key = description ? `${name}_${description}` : name

            return (
                <div key={key} className={styles.mapLocationList__item} onClick={() => onSelect(item)}>
                    <strong>{name}</strong> {description}
                </div>
            )
        })
    }
    return (
        <div className={styles.mapLocationList}>
            <CustomScroll classNames={styles.mapLocationList__content}>{renderList()}</CustomScroll>
        </div>
    )
}
