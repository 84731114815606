import { addStyles } from 'ethcss'
import { colors } from 'theme/colors'
import { IStyles, mediaCSS } from '../../../theme'

const widgetInfoStyles: IStyles = {
    closeBlock: {
        position: 'fixed',
        top: 30,
        right: 30,
        display: 'flex',
        justifyContent: 'flex-end',

        [mediaCSS.tab]: {
            top: 70,

            [mediaCSS.tabMini]: {
                right: 10,
            },
        },
    },
    closeBlockModal: {
        position: 'fixed',
        top: 30,
        right: 30,
        display: 'flex',
        justifyContent: 'flex-end',

        [mediaCSS.tab]: {
            top: 56,
            right: 10,
        },
    },
    topLine: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        paddingBottom: 36,
        borderBottom: `1px solid ${colors.greyLight}`,

        [mediaCSS.tabMini]: {
            paddingTop: 60,
        },
    },
    widgetInfoTopLineLeft: {
        display: 'flex',
        width: 'calc(100% - 150px)',
        cursor: 'pointer',
    },
    titleAndDownload: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginLeft: 26,
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        color: colors.blackLight,
        marginBottom: 10,
    },
    actionBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.blackLight,
        color: colors.white,
        padding: '7px 10px',
        borderRadius: 5,
        cursor: 'pointer',
        textDecoration: 'none',
        width: 140,
    },
    actionIcon: {
        marginLeft: 14,
    },
    widgetInfoTopLineRight: {
        textAlign: 'right',
        color: colors.grey,
    },
    metaInfo: {
        marginTop: 8,
    },
    descriptionBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        paddingTop: 36,
    },
    description: {
        width: 'calc(100% - 140px)',
        fontSize: 18,
        color: colors.blackLight,

        [mediaCSS.mob]: {
            fontSize: 14,
        },
    },
    sliderBlock: {
        padding: '36px 0',
        borderBottom: `1px solid ${colors.greyLight}`,
    },
}

addStyles(widgetInfoStyles)
export default widgetInfoStyles
