import React, { FC } from 'react'
import Notification from 'blocks.simple/notification/notification'
import { customError, IAppNotificationsState } from './AppNotifications.state'
import translate from 'core/translate'
import { isObject } from 'core/utils/coreUtil'
import { AppErrorProps, AppError, AppCustomError } from './AppNotifications-types'
import { useTypedSelector } from 'core/store/hooks'
import { customNotification } from './AppCustomNotifications'
import { customErrorsList } from './AppCustomErrorsList'

export const AppNotifications: FC<AppErrorProps> = () => {
    const { error } = useTypedSelector<IAppNotificationsState>((state) => state.appNotifications)

    const getErrorMessage = (error: AppError | AppCustomError | string | number | customError) => {
        if (!isObject(error)) {
            if (customErrorsList.includes(error.toString())) {
                return customNotification({ error })
            }
            return translate(error as string)
        }
        if ((error as AppCustomError).error) {
            if (customErrorsList.includes((error as AppCustomError).error.toString())) {
                return customNotification(error)
            }
        }

        return translate((error as AppError).errorCode, [(error as AppError).message as string])
    }

    return (
        <Notification show={!!error}>
            <div>{error && <span>{getErrorMessage(error)}</span>}</div>
        </Notification>
    )
}
