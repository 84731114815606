import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { defaultThemeStyles } from '../TableEditor-theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { mediaCSS, textSizes } from 'theme/media'
import { IStyles } from '../../../theme'

const borderStyles = {
    ...grid.w100,
    backgroundColor: `var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
}

const tableEditorPosition: IStyles = {
    title: {
        ...grid.mb_mdPlus,
        fontSize: textSizes.md,
        color: `var(--var-module-app-component-tableEditor-style-title, ${defaultThemeStyles.title})`,
    },
    col: {
        ...grid.w100,
        ...grid.col,
        ...grid.pr_md,
        ...grid.pl_md,
    },
    col_type_borders: {
        [mediaCSS.tab]: {
            ...grid.mb_mdPlus,
        },
    },
    column: {
        ...grid.w50,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
        ...grid.col,
    },
    colSmall: {
        ...grid.col,
        ...grid.w100,
        ...grid.mb_md,
    },
    borderBlock: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.w100,
        height: 10,
    },
    borderSmall: {
        height: 1,
        ...borderStyles,
    },
    borderSmallPlus: {
        height: 2,
        ...borderStyles,
    },
    borderMedium: {
        height: 3,
        ...borderStyles,
    },
    borderBig: {
        height: 4,
        ...borderStyles,
    },
    preview: {
        width: 150,
        height: 150,
        position: 'relative',
    },
    icon: {
        position: 'absolute',
    },
    topIcon: {
        ...grid.rowCenter,
        ...grid.w100,
        top: -25,
    },
    leftIcon: {
        ...grid.colCenter,
        ...grid.fullHeight,
        left: -25,
    },
    rightIcon: {
        ...grid.colCenter,
        ...grid.fullHeight,
        right: -25,
    },
    bottomIcon: {
        ...grid.rowCenter,
        ...grid.w100,
        bottom: -25,
    },
}

addStyles(tableEditorPosition)
export default tableEditorPosition
