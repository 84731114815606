import React, { FC, memo, useEffect, useState } from 'react'
import Content from 'blocks.app/content/content'
import Stick from 'blocks.simple/stick/stick'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import { styles } from './ScoreboardsSettings-styles'
import { grid } from 'theme'
import { Typography } from 'atoms/Typography'
import { IScoreboardsAddFolder } from './ScoreboardsSettings-types'
import { routes } from 'features/routes'
import { api } from 'core/api/ConnectionManager'
import { Radio } from 'atoms/Radio'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { changeLocation } from 'features/routes'
import { checkAvailableModule } from '../../../../core/helpers/routes/routes'

const ScoreboardsSettingsComponent: FC<IScoreboardsAddFolder> = ({ location }) => {
    const [synchronization, setSynchronization] = useState<any>(null)
    const { ledControllerId, controllerFolderId, parentId } = getURLSearchParamsByLocation(location)

    useEffect(() => {
        if (ledControllerId) {
            api.send<any, any>('getScoreboardById', { ledControllerId }).then((res) => {
                if (res.settings) {
                    setSynchronization(res.settings.synchronizeQ)
                }
            })
        }
    }, [])

    const onSave = () => {
        api.send('updateScoreboard', { ledControllerId, settings: { synchronizeQ: synchronization } }).then((res) => {
            onCancel()
        })
    }

    const onCancel = () => {
        changeLocation(`/${routes.scoreboards.path}${parentId ? '?parentId=' + parentId : ''}`)
    }

    return (
        <Content>
            <Stick enabled>
                <div className={styles.buttons}>
                    <Button mod="fill" className={grid.mr_mini} onClick={onCancel}>
                        {translate('cancel')}
                    </Button>
                    <Button mod={'withBorder'} onClick={onSave}>
                        {translate('save')}
                    </Button>
                </div>
            </Stick>
            <div className={styles.contentWrapper}>
                {synchronization !== null ? (
                    <>
                        <Typography className={styles.title} type="title">
                            {translate('scoreboardsSync')}
                        </Typography>
                        <div className={styles.content}>
                            <div className={styles.col}>
                                <div className={styles.radioWrapper}>
                                    <Radio
                                        className={styles.radio}
                                        onClick={function () {
                                            setSynchronization(true)
                                        }}
                                        selected={synchronization}
                                        label={translate('activate')}
                                    />
                                    <Radio
                                        onClick={function () {
                                            setSynchronization(false)
                                        }}
                                        selected={!synchronization}
                                        label={translate('deactivate')}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        </Content>
    )
}

export const ScoreboardsSettings = checkAvailableModule('scoreboards/scoreboardsSettings')
    ? memo(ScoreboardsSettingsComponent)
    : null
