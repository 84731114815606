import React, { FC, memo, useContext } from 'react'
import { ICatalogWithPaginationActionBarProps } from 'organisms/CatalogWithPagination/CatalogWithPagination-types'
import { styles } from './ScoreboardsActionBar-styles'
import { cn } from 'ethcss'
import translate from 'core/translate'
import { CatalogWithPaginationContext } from 'organisms/CatalogWithPagination'
import helpers, { IRecordWithAny } from 'core/helpers'
import { DebouncedSearchbar } from 'molecules/SearchBar'
import { useLocation } from 'react-router'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import Dropdown from 'atoms/Dropdown'
import { changeFilter } from 'features/routes'
import { useDispatch } from 'react-redux'
import { IAuthenticatedUserState, updateUserSettings } from 'blocks.app/user/user.state'
import { useTypedAuthenticatedSelector } from 'core/store/hooks'
import { isHorizontalMenu } from 'core/helpers/menu'
import { themeFromStore } from 'core/helpers/menu'

export const ScoreboardsActionBarComponent: FC<ICatalogWithPaginationActionBarProps> = ({ classNames }) => {
    const dispatch = useDispatch()
    const { data: user } = useTypedAuthenticatedSelector<IAuthenticatedUserState>((state) => state.user)
    const { common, catalogMethods } = useContext(CatalogWithPaginationContext)
    const { type, isEnableUpdateUserSettings = true, dispositionList } = common
    const viewParams = helpers.getViewParamsByUser(type, user)
    const { sort, disposition: settingsDisposition } = viewParams
    const { onResetSelectedItems, onSelectAllItems } = catalogMethods

    const location = useLocation()
    const pathname = helpers.getPathname(location.pathname)
    const locationQuery = getURLSearchParamsByLocation(location)
    const { query, disposition } = helpers.getQuery(locationQuery, type)

    const selectionOptions = [
        { id: 'selectAll', name: translate('selectAll') },
        { id: 'cancelAll', name: translate('cancelAll') },
    ]

    const sortOptions = [
        { id: '-createdAt', name: translate('sortCreatedAtDESC') },
        { id: 'createdAt', name: translate('sortCreatedAtASC') },
        { id: 'name', name: translate('byNameAsc') },
        { id: '-name', name: translate('byNameDesc') },
    ]

    const viewOptions = dispositionList.map((view) => ({ id: view, name: translate(view), icon: view }))

    const onSelectionChange = (sortValue: IRecordWithAny) => {
        const { id } = sortValue as typeof selectionOptions[0]

        switch (id) {
            case 'selectAll':
                onSelectAllItems()
                break
            case 'cancelAll':
                onResetSelectedItems()
                break
        }
    }

    const onSortChange = (sortValue: IRecordWithAny) => {
        const { id } = sortValue as typeof sortOptions[0]

        dispatch(
            updateUserSettings({ data: { sort: { scoreboards: id } }, isSaveSettings: isEnableUpdateUserSettings })
        )
    }

    const onViewChange = (viewValue: IRecordWithAny) => {
        const { id } = viewValue as typeof viewOptions[0]

        dispatch(
            updateUserSettings({ data: { view: { scoreboards: id } }, isSaveSettings: isEnableUpdateUserSettings })
        )
    }

    const onSearchChange = (searchQuery: any) => {
        changeFilter({
            [helpers.prefixQuery({ name: type, field: 'query' })]: searchQuery,
        })
    }

    const getDisposition = () => {
        return settingsDisposition || disposition
    }

    return (
        <div
            className={cn(
                classNames || styles.ScoreboardsActionBar,
                isHorizontalMenu() && styles.ScoreboardsActionBar_x
            )}
        >
            <div
                className={
                    themeFromStore() === 'custom' && pathname === 'scoreboards'
                        ? styles.ScoreboardsActionBar__form_custom_theme
                        : styles.ScoreboardsActionBar__form
                }
            >
                <div
                    className={
                        themeFromStore() === 'custom' && pathname === 'scoreboards'
                            ? styles.ScoreboardsActionBar__search_custom_theme
                            : styles.ScoreboardsActionBar__search
                    }
                >
                    <DebouncedSearchbar
                        id="searchbar"
                        onSearch={onSearchChange}
                        value={query}
                        mod={'withShadow'}
                        classNames={styles.ScoreboardsActionBar__searchbar}
                    />
                </div>
                <div
                    className={cn(
                        themeFromStore() === 'custom' &&
                            pathname === 'scoreboards' &&
                            styles.ScoreboardsActionBar__delimer_custom_theme_sort,
                        styles.ScoreboardsActionBar__sort
                    )}
                >
                    <div
                        className={cn(
                            themeFromStore() !== 'custom' &&
                                pathname !== 'scoreboards' &&
                                styles.ScoreboardsActionBar__delimer,
                            styles.ScoreboardsActionBar__delimer_type_sort
                        )}
                    />
                    <Dropdown
                        mod={'withShadow'}
                        options={sortOptions}
                        wrapperClassName={styles.ScoreboardsActionBar__dropdown_type_sort}
                        value={sort}
                        icon={'sort'}
                        iconSide={'left'}
                        label={translate('sort')}
                        valueKey={'id'}
                        labelKey={'name'}
                        onChange={onSortChange}
                    />
                </div>
                <div className={styles.ScoreboardsActionBar__selection}>
                    <div
                        className={
                            themeFromStore() === 'custom' && pathname === 'scoreboards'
                                ? styles.ScoreboardsActionBar__delimer_custom_theme
                                : styles.ScoreboardsActionBar__delimer
                        }
                    />
                    <Dropdown
                        mod={'withShadow'}
                        options={selectionOptions}
                        value={null}
                        icon={'choice'}
                        iconSide={'left'}
                        label={translate('choice')}
                        valueKey={'id'}
                        labelKey={'name'}
                        onChange={onSelectionChange}
                    />
                </div>
                <div className={styles.ScoreboardsActionBar__selection}>
                    <div
                        className={
                            themeFromStore() === 'custom' && pathname === 'scoreboards'
                                ? styles.ScoreboardsActionBar__delimer_custom_theme
                                : styles.ScoreboardsActionBar__delimer
                        }
                    />
                    <Dropdown
                        mod={'withShadow'}
                        wrapperClassName={styles.ScoreboardsActionBar__dropdown_type_selection}
                        activeWrapperClassName={styles.ScoreboardsActionBar__dropdown_type_selection_status_active}
                        options={viewOptions}
                        label={translate('tile')}
                        value={getDisposition()}
                        valueKey={'id'}
                        labelKey={'name'}
                        icon={'view'}
                        onChange={onViewChange}
                    />
                </div>
            </div>
        </div>
    )
}

export const ScoreboardsActionBar = memo(ScoreboardsActionBarComponent)
