import React, { useEffect, useState } from 'react'
import LoaderBlock from 'blocks.app/loader/_block/loader_block'
import ImageView from 'blocks.simple/imageView/imageView'
import styles from './preview__image.jcss'

const PreviewImage = (p_: { src: string; imageViewRef: any; zoomStatusChange: (zoomStatus: number) => void }) => {
    const [loaded, setLoaded] = useState(false)
    let preloader: any = null
    const setPreloader = () => {
        preloader = new Image()

        preloader.onload = () => {
            setLoaded(true)
        }

        preloader.src = p_.src
    }

    useEffect(() => {
        setPreloader()

        return () => {
            preloader.onload = null
        }
    }, [])

    return (
        <div className={styles.wrapper}>
            {!loaded && <LoaderBlock className={styles.loader} />}
            {loaded && (
                <ImageView
                    src={p_.src}
                    ref={p_.imageViewRef ? p_.imageViewRef : () => {}}
                    zoomStatusChange={p_.zoomStatusChange ? p_.zoomStatusChange : () => {}}
                />
            )}
        </div>
    )
}

export default PreviewImage
