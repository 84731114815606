import { Typography } from 'atoms/Typography'
import EditText from 'blocks.simple/editText/editText'
import { isOnlyDigits } from 'core/utils/validateUtil'
import React from 'react'
import { styles } from './DigitalSignageExhibitionIndex-styles'

export const DigitalSignageExhibitionIndex = (p_: {
    enabled: boolean
    title: string
    digitalSignageExhibitionIndex?: number
    onChange: (index: string, field: string) => void
    platform: string
}) => {
    if (!p_.enabled) {
        return null
    }

    const onChangeIndex = (index: string) => {
        if (isOnlyDigits(index) || !index) {
            p_.onChange(index, 'digitalSignageExhibitionIndex')
        }
    }

    return (
        <div className={styles.DigitalSignageExhibitionIndex}>
            <Typography className={styles.DigitalSignageExhibitionIndex__title} type="title">
                {p_.title}
            </Typography>
            <EditText
                mod={'withBorder'}
                label={''}
                placeholder={'0'}
                value={p_.digitalSignageExhibitionIndex !== undefined ? p_.digitalSignageExhibitionIndex : 0}
                containerClass={styles.DigitalSignageExhibitionIndex__number}
                onChange={onChangeIndex}
            />
        </div>
    )
}
