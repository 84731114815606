import React from 'react'
import Icon from 'blocks.simple/icon/icon'
import { text as textStyles } from 'theme'
import { cn } from 'ethcss'
import styles from './tag.jcss'

interface ITagProps {
    value: string | number
    mod?: string
    onClick?: () => void
    className?: string | {}
    onIconClick?: any
}

const Tag = (p_: ITagProps) => {
    return (
        <span
            className={cn(styles.item, p_.className, p_.mod ? textStyles[p_.mod] : '')}
            onClick={p_.onClick ? p_.onClick : () => {}}
        >
            #{p_.value}
            {p_.onIconClick && (
                <span className={styles.icon}>
                    <Icon type="close" iconColor={p_.mod} size="10" onClick={p_.onIconClick} />
                </span>
            )}
        </span>
    )
}

Tag.defaultProps = {
    mod: 'darkCloud',
}

export default Tag
