import { useState, useEffect } from 'react'
import { useAdvancedSearchInterface } from './AdvancedSearch-types'

import {
    DEFAULT_DIGITAL_SIGNAGES_ADVANCED_SEARCH_PARAMETERS,
    AdvancedSearchOperatorType,
} from 'core/models/AdvancedSearch'
import { useDispatch } from 'react-redux'
import { updateUserSettings } from 'blocks.app/user/user.state'

export const useAdvancedSearch = ({
    type,
    advancedSearchSettings,
    onApply,
    onReset,
    onChange,
}: useAdvancedSearchInterface) => {
    const dispatch = useDispatch()
    const [parameters, setParameters] = useState({ ...DEFAULT_DIGITAL_SIGNAGES_ADVANCED_SEARCH_PARAMETERS })
    const [useADSearch, setUseADSearch] = useState<boolean>(false)

    useEffect(() => {
        if (!advancedSearchSettings[type]) {
            throw new Error(`Advanced search get settings fail, type is not exist, type: ${type}`)
        } else {
            setParameters(advancedSearchSettings[type])
        }
    }, [advancedSearchSettings, type])

    const changeSearchExactlyParameter = (key: string) => (value: boolean | string) => {
        const newParameters = {
            ...parameters,
            exactly: {
                ...parameters.exactly,
                [key]: value,
            },
        }

        setParameters(newParameters)
        onChange(newParameters)

        if (newParameters.exactly.separator) {
            applySearchParameters(newParameters)
        }
    }

    const changeSearchTermParameter = (value: AdvancedSearchOperatorType) => () => {
        const newParameters = {
            ...parameters,
            operator: value,
        }

        setParameters(newParameters)
        onChange(newParameters)
        applySearchParameters(newParameters)
    }

    const changeEnableQParameter = (value: any) => () => {
        const newParameters = {
            ...parameters,
            enabledQ: value,
        }

        setParameters(newParameters)
        onChange(newParameters)
        applySearchParameters(newParameters)
    }

    const resetSearchParameters = () => {
        if (typeof onReset === 'function') {
            onReset(DEFAULT_DIGITAL_SIGNAGES_ADVANCED_SEARCH_PARAMETERS)
        }

        setParameters(DEFAULT_DIGITAL_SIGNAGES_ADVANCED_SEARCH_PARAMETERS)
    }

    const applySearchParameters = (parameters: any) => {
        if (typeof onApply === 'function') {
            onApply(parameters)
        }

        if (parameters.exactly.separator === '') {
            parameters.exactly.separator = ' '
        }

        dispatch(updateUserSettings({ data: { advancedSearch: { [type]: parameters } }, isSaveSettings: true }))
    }

    return {
        parameters,
        changeSearchExactlyParameter,
        changeSearchTermParameter,
        resetSearchParameters,
        applySearchParameters,
        useADSearch,
        setUseADSearch,
        changeEnableQParameter,
    }
}
