import React from 'react'
import filesize from 'filesize'
import deepEqual from 'fast-deep-equal'
import moment from 'moment'
import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'
import Toast from 'blocks.simple/toast/toast'
import translate from 'core/translate'
import helpersDigitalSignages from 'core/helpers/_digitalSignages'
import { changeFilter } from 'features/routes'
import { licenseType, rateTypeRename } from 'core/utils/displayUtil'

export interface ISelectedInfo {
    groupId: number
    parentId: number
    id: number
    online: boolean
    platform: string
    orientation: number
    errorCode: number
    description: string
    volume: number
    diagonal: string
    resolutionWidth: number
    resolutionHeight: number
    status: string
    type: string
    lastScreenshotDate: string
    licenseId: number | null
    tags: { name: string; id: string; value: string; label: string }[]
    cover: string[]
    newerClientVersion: string
    name: string
    identificationImplementedQ: boolean
    menu: { name: string }[]
    manufacturer: string
    settings: {
        logLevel: string
        autoScreenshot: {
            width: number
            height: number
            enabled: boolean
            interval: number
        }
        comPorts: any
    }
    activeQ: boolean
}

export interface ISetMonitorWindow {
    title: string
    type: string
    options: {
        name: string
        digitalSignageId: number
        status: string
    }
}

export interface IDisplayInfoProps {
    user: any
    selectedInfo: ISelectedInfo
    closeModal: () => void
    query: {
        mapquery: string
    }
    getClientLogs: (data: { id: number; actual: boolean }) => void
    isConnected: boolean
    setMonitorWindow: (data: ISetMonitorWindow) => void
    groups?: any
}

type DSState = {
    id: number
    startTime: string | null
    currentDate: string | null
    timezone: string | null
}

interface IState {
    formattedParameters: { [index: string]: any }
    openPowerModal: boolean
    showCleanMenu: boolean
    removeAll: boolean
    times: {
        diffTimes: string
        diffDays: string
    } | null
    showAll: boolean
    dsState: DSState | null
    isUpdatingScreenShot: boolean
    startTime: string | null
    getClientLogsMenu: boolean
    clean: { files?: boolean; database?: boolean; statistic?: boolean }
    externalStorages: {
        externalStorages: {
            size: {
                totalBytes: number
                usedBytes: number
                availableBytes: number
            }
            label: string
        }
        externalStoragesUpdatedAt: string
    } | null
    showShutdownMenu?: boolean
    showDeleteMenu?: boolean
    licensesData?: {
        activeQ: boolean
        startedQ: boolean
        deletedQ: boolean
        notExpiredQ: boolean
        devicesCount: number
        licenseId: number
        companyId: number
        paidQ: boolean
        startedAt: string
        expiredAt: null | string
        manager: string
        comment: string
        createdAt: string
        deletedAt: null | string
        vacantCount: number
        licenseType: string
        rateType: null | string
    }[]
    currentLicense?: {
        activeQ: boolean
        expiredAt: null | string
        id: number
        name: string
        paidQ: boolean
        vacantCount: number
    } | null
    webEngineInfo: string
}

function _formattingParameters(p_: ISelectedInfo) {
    const selectedInfo = { ...p_ }
    const parameters: { [index: string]: any } = {}
    const sizes = ['storageSize', 'usedStorageSize', 'freeStorageSize']
    const dates = ['lastScreenshotDate', 'createdAt', 'lastConnection', 'updatedAt']

    if (selectedInfo.hasOwnProperty('platform') && selectedInfo.hasOwnProperty('diagonal')) {
        if (selectedInfo.platform === 'SSSP 2' || selectedInfo.platform === 'SSSP 3') {
            delete selectedInfo.diagonal
        }
    }

    if (helpersDigitalSignages.isFolder(selectedInfo)) {
        delete selectedInfo.orientation
    }

    for (let key in selectedInfo) {
        if (selectedInfo.hasOwnProperty(key)) {
            // @ts-ignore
            let selectedInfoKey = selectedInfo[key]
            const parameter: { [index: string]: any } = selectedInfoKey

            if (key === 'rootEnabled') {
                if (selectedInfoKey !== null) {
                    parameters[key] = `<div>${
                        selectedInfoKey
                            ? translate('yes')
                            : `<div>${translate('no')}</div><span>${translate(
                                  'noRootWarning'
                              )} <a target="_blank" href="https://wiki.smartplayer.org/index.php/%D0%9E%D1%82%D1%81%D1%83%D1%82%D1%81%D1%82%D0%B2%D0%B8%D0%B5_root_%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%B0_%D1%83_%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F_SmartPlayer_%D0%BD%D0%B0_AndroidOS">${translate(
                                  'here'
                              )}</a></span>`
                    }</div>`
                }
            }

            if (Array.isArray(parameter)) {
                parameters[key] = parameter.join(', ')
            }

            if (typeof parameter === 'string' || typeof parameter === 'number') {
                if (sizes.includes(key)) {
                    if (parameter > 0) {
                        selectedInfoKey = filesize(parameter)
                    }
                }
                if (dates.includes(key)) {
                    selectedInfoKey = helpers.getFormattedLocalDate(parameter, 'DD/MM/YYYY HH:mm')
                }
                parameters[key] = selectedInfoKey

                if (key === 'orientation') {
                    const orientation = helpersDigitalSignages.getOrientation(parameters[key])
                    if (orientation) {
                        parameters[key] = orientation.name
                    }
                }
                if (key === 'rateType' || key === 'licenseType') {
                    parameters[key] = translate(parameters[key])
                }
            }
        }
    }

    return sortedParams(parameters)
}

function sortedParams(parameters: { [index: string]: string }) {
    const keys: string[] = [
        'licenseId',
        'licenseType',
        'rateType',
        'id',
        'deviceId',
        'idCashRegisterGlobal',
        'dsVersion',
        'name',
        'description',
        'manufacturer',
        'modelName',
        'serialNumber',
        'prettySysName',
        'platform',
        'rootEnabled',
        'firmwareVersion',
        'applicationURL',
        'orientation',
        'resolutionWidth',
        'resolutionHeight',
        'ipAddress',
        'firstDns',
        'secondDns',
        'macAddress',
        'imei',
        'connectionType',
        'lastConnection',
        'lastScreenshotDate',
        'lastScreenshot',
        'storageSize',
        'usedStorageSize',
        'freeStorageSize',
        'volume',
        'groupId',
        'companyId',
        'updatedAt',
        'createdAt',
        'networkIpMode',
        'networkWiFiSecurityMode',
        'networkWiFiEncryptionType',
        'networkWiFiSignalStrengthLevel',
        'networkGateway',
        'networkSubnetMask',
        'networkWiFiSsid',
    ]

    return keys.reduce((acc: { [index: string]: string }, key) => {
        if (parameters.hasOwnProperty(key)) {
            const value = parameters[key].toString()

            if (parameters[key] !== null && value.length && value !== 'undef') {
                acc[key] = parameters[key]
            }
        }
        return acc
    }, {})
}

class DisplaysInfoMethods extends React.Component<IDisplayInfoProps> {
    state: IState = {
        showAll: false,
        removeAll: false,
        dsState: null,
        startTime: null,
        times: null,
        showCleanMenu: false,
        getClientLogsMenu: false,
        openPowerModal: false,
        externalStorages: null,
        clean: {
            files: true,
        },
        isUpdatingScreenShot: false,
        formattedParameters: {},
        licensesData: [],
        currentLicense: null,
        webEngineInfo: '',
    }

    showCount: number = 4
    timerCall: any = null
    timer: any = null
    listenersId: string[] = []

    toggleRemoveAll = () => {
        this.setState({ removeAll: !this.state.removeAll })
    }

    enableRefreshAnimation() {
        this.setState({ isUpdatingScreenShot: true })
    }

    removeGroup(groupItem: { id: number }) {
        const s_ = this.state
        const { closeModal } = this.props

        api.send('deleteGroup', { id: groupItem.id, removeAll: s_.removeAll })
        closeModal()
        this.setState({ showDeleteMenu: false })
    }

    deleteDisplay = () => {
        const p_ = this.props
        const cx_ = this.context

        api.send('deleteDisplay', { id: p_.selectedInfo.id })
        p_.closeModal()
        if (cx_.disposition === 'map' && p_.query.mapquery) {
            changeFilter({
                disposition: cx_.disposition,
                mapquery: undefined,
                type: undefined,
            })
        }
        this.setState({ showDeleteMenu: false })
    }
    showAll = () => {
        this.setState({ showAll: !this.state.showAll })
    }

    toggleShutdownMenu(e: MouseEvent) {
        e.stopPropagation()
        const s_ = this.state
        s_.showShutdownMenu = !s_.showShutdownMenu
        s_.showDeleteMenu = false

        this.setState(s_)
    }

    toggleCleanMenu = (e: MouseEvent) => {
        e.stopPropagation()
        const s_ = this.state
        s_.showCleanMenu = !s_.showCleanMenu

        this.setState(s_)
    }

    toggleLoadLogsMenu = (e: MouseEvent) => {
        e.stopPropagation()
        const s_ = this.state
        s_.getClientLogsMenu = !s_.getClientLogsMenu

        this.setState(s_)
    }

    toggleDeleteMenu(e: MouseEvent) {
        e.stopPropagation()
        const s_ = this.state
        s_.showDeleteMenu = !s_.showDeleteMenu
        s_.showShutdownMenu = false

        this.setState(s_)
    }

    getDsState = () => {
        const p_ = this.props

        api.send('fDsGetState', { id: p_.selectedInfo.id }, { hideLoader: true })
    }
    setDsState = (dsState: DSState) => {
        if (this.props.selectedInfo && this.props.selectedInfo.id !== dsState.id) {
            // backend посылает информация о разных экранах f_ds_get_state
            return
        }

        const data: {
            dsState: DSState
            startTime: string | null
        } = {
            dsState,
            startTime: null,
        }

        delete data.dsState.id

        if (dsState.hasOwnProperty('startTime')) {
            data.startTime = dsState.startTime
                ? moment(parseInt(dsState.startTime, 10)).format('YYYY-MM-DD HH:mm:ss')
                : null
        }

        this.setState({ ...data })
    }
    getDsStateFormat = (dsState: any, key: string) => {
        const { selectedInfo } = this.props
        const { platform } = selectedInfo
        const percentageByPlatforms = ['SSSP 2', 'SSSP 3']
        const parameter: any = dsState[key]

        switch (key) {
            case 'cpuLoad':
                return `${parameter}%`
            case 'cpuTemperature':
                return `${parameter}°`
            case 'freeRAM':
                if (percentageByPlatforms.includes(platform)) {
                    return `${parameter}%`
                } else {
                    return filesize(parseFloat(parameter))
                }
            case 'availableRfid':
                return parameter.join(', ')
            default:
                return parameter
        }
    }
    timerCallback = () => {
        const s_ = this.state

        if (!s_.startTime) {
            return
        }

        const current = moment()
        const uptime = moment(s_.startTime)
        const diff = current.diff(uptime, 'seconds')
        const diffDays = current.diff(uptime, 'days')

        const diffTimes = moment().startOf('day').seconds(diff).format('HH:mm:ss')

        this.setState({
            startTime: uptime.format('YYYY-MM-DD HH:mm:ss'),
            times: {
                diffTimes,
                diffDays,
            },
        })
    }

    onAcceptModal = () => {
        const p_ = this.props
        let data: {
            id?: number
            groupId?: number
        } = {
            id: p_.selectedInfo.id,
        }

        if (helpersDigitalSignages.isFolder(p_.selectedInfo)) {
            data = {
                groupId: p_.selectedInfo.id,
            }
        }

        api.send('shutdownDisplay', data)
        this.closeAcceptModal()
    }

    closeAcceptModal = () => {
        this.setState({ openPowerModal: false })
    }

    onFDsGetState = (dsState: DSState) => {
        this.setDsState(dsState)
    }

    setExternalStorages = (data: {
        externalStorages: {
            size: {
                totalBytes: number
                usedBytes: number
                availableBytes: number
            }
            label: string
        }
        externalStoragesUpdatedAt: string
    }) => {
        if (!data) return

        this.setState({
            externalStorages: {
                externalStorages: data.externalStorages,
                externalStoragesUpdatedAt: data.externalStoragesUpdatedAt,
            },
        })
    }
    getLicenses = (companyId: number) => {
        api.send('getLicenseByFilters', {
            sort: [['licenseId', 'ASC']],
            limit: 1000,
            companyId: companyId,
        }).then((res: any) => {
            let optionFormat = res.data
                .filter((data: any) => data.activeQ)
                .map((opt: any) => ({
                    id: opt.licenseId,
                    name: licenseType(opt) + ' ' + rateTypeRename(opt.rateType),
                    vacantCount: opt.vacantCount,
                    expiredAt: opt.expiredAt,
                    paidQ: opt.paidQ,
                }))
            this.setState((prevState: IState) => ({
                ...prevState,
                licensesData: optionFormat,
            }))
        })
    }

    componentDidMount() {
        const p_ = this.props
        this.getLicenses(p_.user)
        if (p_.selectedInfo && p_.selectedInfo.type !== 'group') {
            api.send('getExternalStoragesInfo', {
                digitalSignageId: p_.selectedInfo.id,
            }).then((res: any) => {
                this.setExternalStorages({
                    externalStorages: res.externalStorages,
                    externalStoragesUpdatedAt: res.externalStoragesUpdatedAt,
                })
            })
        }

        api.addObserver('fDsGetState', this.onFDsGetState, this.listenersId)
        api.addObserver('webEngineInfoReceived', this.setWebEngineInfo, this.listenersId)
        api.addObserver(
            'digitalSignageUpdated',
            (res: any) => {
                if (res.digitalSignageId && res.digitalSignageId === p_.selectedInfo.id) {
                    this.setExternalStorages({
                        externalStorages: res.externalStorages,
                        externalStoragesUpdatedAt: res.externalStoragesUpdatedAt,
                    })
                }
            },
            this.listenersId
        )
        api.addObserver(
            'digitalSignageScreenshotCreated',
            () => {
                this.setState({ isUpdatingScreenShot: false })
            },
            this.listenersId
        )

        if (p_.selectedInfo.online && !helpersDigitalSignages.isFolder(p_.selectedInfo)) {
            this.getDsState()
            this.timerCallback()

            clearInterval(this.timerCall)
            this.timerCall = setInterval(this.getDsState, 10000)

            clearInterval(this.timer)
            this.timer = setInterval(this.timerCallback, 1000)
        }

        this.setState({
            formattedParameters: _formattingParameters(p_.selectedInfo),
        })
    }

    componentWillUnmount() {
        clearInterval(this.timerCall)
        clearInterval(this.timer)
        this.listenersId.forEach((id) => api.removeObserver(id))
    }

    static getDerivedStateFromProps(nextProps: IDisplayInfoProps, prevState: IState) {
        const formattedParameters = _formattingParameters(nextProps.selectedInfo)

        if (!deepEqual(formattedParameters, prevState.formattedParameters)) {
            return {
                formattedParameters,
            }
        }

        return null
    }

    componentDidUpdate(prevProps: IDisplayInfoProps) {
        const p_ = this.props

        if (prevProps.selectedInfo.online && !p_.selectedInfo.online) {
            clearInterval(this.timerCall)
            this.setState({ dsState: null })
        }
    }

    copyToClipboard = (data: string) => {
        const clipboardResultToastOptions = {
            autoClose: 3000,
            position: 'bottom-left',
        }

        navigator.clipboard.writeText(data).then(
            () => {
                Toast.info(translate('copiedToClipboard'), clipboardResultToastOptions)
            },
            () => {
                Toast.error(translate('copyToClipboardError'), clipboardResultToastOptions)
            }
        )
    }

    copyParameterValueToClipboard = (value: string) => () => {
        this.copyToClipboard(value)
    }

    copyParametersToClipboard = () => {
        const parametersString = Object.entries(this.state.formattedParameters).reduce((acc, [key, value]) => {
            acc += `${translate(key)}: ${value}\n`

            return acc
        }, '')

        this.copyToClipboard(parametersString)
    }
    setWebEngineInfo = (webEngineInfo: { content: string }) => {
        if (webEngineInfo && webEngineInfo.content) {
            this.setState({ webEngineInfo: webEngineInfo.content })

            return
        }

        this.setState({ webEngineInfo: translate('cantGetWebEngineInfo') })
    }
    closeWebEngineInfo = () => {
        this.setState({ webEngineInfo: '' })
    }

    isMultipleClearSupport = (platformName: any) => {
        const supportList = [
            'LINUX',
            'LINUX x32',
            'LINUX x64',
            'RASPBERRY',
            'RASPBERRY_V3 x32',
            'RASPBERRY_V3 x64',
            'RASPBERRY_V4 x32',
            'RASPBERRY_V4 x64',
            'WINDOWS',
            'WINDOWS x32',
            'WINDOWS x64',
            'WINDOWS 7 x32',
            'WINDOWS 7 x64',
            'ANDROID',
        ]

        return supportList.includes(platformName)
    }

    isWebEngineInfoSupport = (platformName: any) => {
        const supportList = [
            'LINUX',
            'LINUX x32',
            'LINUX x64',
            'RASPBERRY',
            'RASPBERRY_V3 x32',
            'RASPBERRY_V3 x64',
            'RASPBERRY_V4 x32',
            'RASPBERRY_V4 x64',
            'WINDOWS',
            'WINDOWS x32',
            'WINDOWS x64',
            'WINDOWS 7 x32',
            'WINDOWS 7 x64',
            'ANDROID',
            'BRIGHTSIGN',
            'SSSP 2',
            'SSSP 3',
            'SSSP 4',
            'SSSP 5',
            'SSSP 6',
            'SSSP 7',
            'SSSP 10',
            'WEBOS 2',
            'WEBOS 3',
            'WEBOS 3.2',
            'WEBOS 4',
            'WEBOS 4.1',
            'WEBOS 6',
        ]

        return supportList.includes(platformName)
    }

    isMonitoringSupport = (platformName: string) => {
        const supportList = [
            'BRIGHTSIGN',
            'SSSP 2',
            'SSSP 3',
            'SSSP 4',
            'SSSP 5',
            'SSSP 6',
            'SSSP 7',
            'SSSP 10',
            'WEBOS 2',
            'WEBOS 3',
            'WEBOS 3.2',
            'WEBOS 4',
            'WEBOS 4.1',
            'WEBOS 6',
            'LINUX',
            'LINUX x32',
            'LINUX x64',
            'RASPBERRY',
            'RASPBERRY_V3 x32',
            'RASPBERRY_V3 x64',
            'RASPBERRY_V4 x32',
            'RASPBERRY_V4 x64',
            'WINDOWS',
            'WINDOWS x32',
            'WINDOWS x64',
            'WINDOWS 7 x32',
            'WINDOWS 7 x64',
            'ANDROID',
        ]

        return supportList.includes(platformName)
    }
}

export default DisplaysInfoMethods
