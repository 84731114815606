import React, { FunctionComponent, memo } from 'react'
import { ILabelComponent } from './Label-types'
import { cn } from 'ethcss'
import { item, text, grid } from 'theme'
import { labelStyles } from './Label-styles'

const LabelComponent: FunctionComponent<ILabelComponent> = ({
    className,
    htmlFor,
    contentClassName,
    textColor = 'darkCloud',
    textPosition = 'center',
    textSize = 'normal',
    indent = 'normal',
    fullWidth = false,
    children,
}) => {
    return (
        <label
            htmlFor={htmlFor}
            className={cn(labelStyles.wrapper, className, item[`indent_${indent}`], { [grid.w100]: fullWidth })}
        >
            <div style={{ color: textColor }} className={cn(contentClassName, text[textSize], text[textPosition])}>
                <div className={cn(item.ellipsisPure)}>{children}</div>
            </div>
        </label>
    )
}

export const Label = memo(LabelComponent)
