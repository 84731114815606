import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { IStyles } from '../../../theme'

const styles: IStyles = {
    title: {
        marginTop: 40,
    },
    radioButtons: {
        ...grid.row,
        ...grid.mb_md,
        ...grid.fullWidth,
    },
}

addStyles(styles)
export default styles
