import PropTypes from 'prop-types'
import React from 'react'
import Icon from 'blocks.simple/icon/icon'
import InputMask from 'react-input-mask'

import { cn } from 'ethcss'
import styles from './editInline.jcss'

class EditInline extends React.Component {
    keyUpListener = document.addEventListener('keyup', (e) => {
        if (e.keyCode === 9) {
            if (e.target.dataset.trigger === 'editInline') {
                e.target.click()
            }
        }
        if (e.keyCode === 13) {
            if (this.inputRef) {
                this.finishEditing()
            }
        }
    })

    constructor(p_) {
        super(p_)
        this.isInputValid = p_.validate || this.isInputValid
    }
    static propTypes = {
        text: PropTypes.string.isRequired,
        paramName: PropTypes.string.isRequired,
        change: PropTypes.func.isRequired,
        placeholder: PropTypes.string,
        className: PropTypes.string,
        activeClassName: PropTypes.string,
        minLength: PropTypes.number,
        maxLength: PropTypes.number,
        validate: PropTypes.func,
        style: PropTypes.object,
        staticElement: PropTypes.string,
        tabIndex: PropTypes.number,
        isDisabled: PropTypes.bool,
        editing: PropTypes.bool,
        isIcon: PropTypes.bool,
        showIcon: PropTypes.bool,
        iconType: PropTypes.string,
    }

    static defaultProps = {
        minLength: 1,
        maxLength: 256,
        staticElement: 'span',
        isIcon: false,
        showIcon: false,
        iconType: 'pencil',
        tabIndex: 0,
        isDisabled: false,
        editing: false,
    }

    state = {
        editing: this.props.editing,
        text: this.props.text,
        minLength: this.props.minLength,
        maxLength: this.props.maxLength,
    }

    componentDidUpdate(prevProps, prevState) {
        const p_ = this.props
        let inputElem = this.inputRef

        if (this.state.editing && !prevState.editing) {
            inputElem.focus()
        } else if (this.state.editing && prevProps.text !== this.props.text) {
            this.finishEditing()
        }

        const isTextChanged = p_.text !== prevProps.text
        const isEditingChanged = p_.editing !== prevProps.editing
        let nextState = {}
        if (isTextChanged) {
            nextState.text = p_.text
        }
        if (isEditingChanged) {
            nextState.editing = p_.editing
        }
        if (isTextChanged || isEditingChanged) {
            this.setState(nextState)
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.keyUpListener)
    }

    startEditing = (e) => {
        if (this.props.stopPropagation) {
            e.stopPropagation()
        }
        this.setState({ editing: true, text: this.props.text })
    }

    finishEditing = () => {
        if (this.isInputValid(this.state.text) && this.props.text !== this.state.text) {
            this.commitEditing()
        } else if (this.props.text === this.state.text || !this.isInputValid(this.state.text)) {
            this.cancelEditing()
        }
    }

    cancelEditing = () => {
        this.setState({ editing: false, text: this.props.text })
    }

    commitEditing = () => {
        this.setState({ editing: false, text: this.state.text })
        let newProp = {}
        newProp[this.props.paramName] = this.state.text
        this.props.change(newProp)
    }

    clickWhenEditing = (e) => {
        if (this.props.stopPropagation) {
            e.stopPropagation()
        }
    }

    isInputValid = (text) => {
        return text.length >= this.state.minLength && text.length <= this.state.maxLength
    }

    textChanged = (event) => {
        this.setState({
            text: event.target.value.trim(),
        })
    }

    render() {
        if (this.props.isDisabled) {
            const Element = this.props.element || this.props.staticElement
            return (
                <Element className={this.props.className} style={this.props.style}>
                    {this.state.text || this.props.placeholder}
                </Element>
            )
        } else if (!this.state.editing) {
            const Element = this.props.element || this.props.staticElement
            return (
                <Element
                    className={cn(this.props.className, {
                        [styles.withIcon]: !this.props.showIcon,
                        [styles.showIcon]: this.props.showIcon,
                    })}
                    data-trigger="editInline"
                    onClick={this.startEditing}
                    tabIndex={this.props.tabIndex}
                    style={this.props.style}
                >
                    {this.state.text || this.props.placeholder}
                    {this.props.isIcon && <Icon containerClass="icon" type={this.props.iconType} size="14" />}
                </Element>
            )
        } else {
            return (
                <InputMask
                    onClick={this.clickWhenEditing}
                    onBlur={this.finishEditing}
                    mask={this.props.mask}
                    className={this.props.activeClassName}
                    placeholder={this.props.placeholder}
                    onChange={this.textChanged}
                    style={this.props.style}
                    inputRef={(input) => (this.inputRef = input)}
                />
            )
        }
    }
}

export default EditInline
