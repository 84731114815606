import { addStyles } from 'ethcss'
import { defaultThemeStyles } from './AppMenu.theme'
import { colors, textSizes } from 'theme'
import { mediaCSS, IStyles, grid, item, cols, layers } from 'theme'

export const styles: IStyles = {
    iconBackground: {
        display: 'flex',
        paddingLeft: '20px',
        background: `var(--var-module-app-component-menu-style-background, ${defaultThemeStyles.background})`,
        zIndex: 10001,
        paddingRight: '10px',
        minHeight: '45px',
        alignItems: 'center',
    },
    vertBorderLeft: {
        position: 'absolute',
        top: 0,
        left: '60px',
        zIndex: 10001,
        height: '100%',
        borderLeft: '1px solid',
        color: `var(--var-module-app-component-menu-style-textMain,${defaultThemeStyles.textMain})`,
    },
    vertBorderRight: {
        position: 'absolute',
        top: 0,
        right: '25.1%',
        zIndex: 10001,
        height: '100%',
        borderLeft: '1px solid',
        color: `var(--var-module-app-component-menu-style-textMain,${defaultThemeStyles.textMain})`,
        [mediaCSS.desktopXL]: {
            right: '24.3% !important',
        },
        [mediaCSS.desktopL]: {
            right: '24% !important',
        },
    },
    menu: {
        ...item.transitionErase,
        ...grid.fullHeight,
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        width: `${cols.normal}%`,
        backgroundColor: `var(--var-module-app-component-menu-style-background, ${defaultThemeStyles.background})`,
        zIndex: layers.menu,
        [mediaCSS.tab]: {
            ...grid.w50,
            maxWidth: 0,
            overflowX: 'hidden',
            zIndex: layers.sticky + 2,
            top: 44,
        },
    },
    menu_x: {
        position: 'fixed',
        width: '100%',
        zIndex: '9999',
        padding: '10px 0',
        backgroundColor: `var(--var-module-app-component-menu-style-background, ${defaultThemeStyles.background})`,
        boxShadow: '0px 1px 1px rgb(0 0 0 / 30%)',
        borderRadius: '0px 0px 10px 10px',
        ...item.transitionErase,
        [mediaCSS.tab]: {
            ...grid.w50,
            maxWidth: 0,
            overflowX: 'hidden',
            zIndex: layers.sticky + 2,
        },
    },
    menu__scroll: {
        maxHeight: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        boxShadow: `var(--var-module-app-component-menu-style-shadow, ${defaultThemeStyles.shadow})`,

        [mediaCSS.tab]: {
            paddingBottom: 44,
        },
    },
    menu__content: {
        ...grid.w100,
        minHeight: '100%',
        backgroundColor: `var(--var-module-app-component-menu-style-background, ${defaultThemeStyles.background})`,
    },
    menu__content_x: {
        ...grid.w100,
        backgroundColor: `var(--var-module-app-component-menu-style-background, ${defaultThemeStyles.background})`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    menu__info: {
        ...grid.row,
        ...grid.justify,
        ...item.transitionErase,
    },
    menu__info_type_default: {
        ...grid.w85,
    },
    menu__info_type_minimize: {
        ...grid.w50,
    },
    menu__category: {
        ...grid.mb_md,
        '&:last-child': {
            ...grid.mb_none,
            paddingBottom: 20,
        },
    },
    menu__category_x: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        transition: '0.3s ease-out',
    },
    menu__next_position: {
        transform: 'translate(-40%)',
        transitionDuration: '1s',
    },
    menu__next_position_s_1: {
        transform: 'translate(-30%)',
        transitionDuration: '1s',
    },
    menu__next_position_s_2: {
        transform: 'translate(-60%)',
        transitionDuration: '1s',
    },
    menu__prev_position: {
        transform: 'translate(0%)',
        transitionDuration: '1s',
    },
    menu__prev_position_s_1: {
        transform: 'translate(-30%)',
        transitionDuration: '1s',
    },
    menu__prev_position_s_2: {
        transform: 'translate(0%)',
        transitionDuration: '1s',
    },
    menu__fixed_icons_x: {
        position: 'absolute',
        right: '20px',
        paddingLeft: '15px',
        top: 0,
        height: '96%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        zIndex: 9999,
        backgroundColor: `var(--var-module-app-component-menu-style-background, ${defaultThemeStyles.background})`,
    },
    menu_type_open: {
        [mediaCSS.tab]: {
            ...item.transitionErase,
            maxWidth: '50%',
        },
    },
    menu_type_mini: {
        width: `${cols.mini}%`,
    },

    menu__iconWrapper: {
        ...item.rel,
        [mediaCSS.tab]: {
            ...item.none,
        },
    },
    menu__icon: {
        ...grid.row,
        ...grid.center,
        ...grid.pt_mini,

        svg: {
            fill: `var(--var-module-app-component-menu-style-icon-color, ${defaultThemeStyles.arrow})`,
        },
    },
    menu__subMenu: {
        ...item.transitionErase,
        height: 'auto',
        overflow: 'hidden',
    },
    menu__subMenu_type_open: {
        //maxHeight: 1000,
    },
    menu__subMenu_type_close: {
        maxHeight: 0,
    },
    menu__subMenu_status_active: {
        maxWidth: '300px!important',
    },
    menu__subMenu_type_dropdown: {
        position: 'absolute',
        left: `100%`,
        top: 0,
        bottom: 0,
        width: 300,
        maxWidth: 0,
        overflow: 'hidden',
        zIndex: layers.menu,
        ...item.transitionErase,
    },
    subMenu__content: {
        height: '100%',
        boxShadow: `var(--var-module-app-component-menu-style-shadow, ${defaultThemeStyles.shadow})`,
        fontSize: textSizes.md,
        color: colors.darkCloud,
        textAlign: 'center',
        ...item.rel,
        ...item.white,
    },
    logoIcon: {
        marginRight: '15px',
        stroke: `var(--var-module-app-component-menu-style-logoColor, ${colors.blackLight})`,
    },
}

addStyles(styles)
