import { useEffect, useState } from 'react'
import { api } from 'core/api/ConnectionManager'

interface IData {
    sessions: any[]
    isLoaded: boolean
}

const initialState = {
    sessions: [],
    isLoaded: false,
}

let componentIsMounted: boolean = false

function DashboardSessionsMethods() {
    const [data, setData] = useState<IData>(initialState)

    useEffect(() => {
        componentIsMounted = true
        getDashboardSessionsStats()

        return () => {
            componentIsMounted = false
        }
    }, [])

    function getDashboardSessionsStats() {
        api.send('getUserConnections', {})
            .then((res: any) => {
                if (!componentIsMounted) {
                    return
                }

                setData({
                    sessions: res,
                    isLoaded: true,
                })
            })
            .catch(() => {
                setData({
                    sessions: [],
                    isLoaded: true,
                })
            })
    }

    function exitAllSessions() {
        console.log('exitAllSessions')
    }

    return {
        s_: {
            data,
        },
        exitAllSessions,
    }
}

export default DashboardSessionsMethods
