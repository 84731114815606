import {
    clearMainUser,
    clearTokens,
    clearUser,
    createRequestHeaders,
    decorateUserWithDefaultSettings,
    getFromUserId,
    getMainUser,
    getTokenDate,
    getTokens,
    getUser,
    setMainUser,
    setTokens,
    setUser,
} from 'core/helpers/user'
import { IUser, IUserSettings, IUserTokens } from 'core/models/Users'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICompany } from 'core/models/Company'
import { IRole } from 'core/models/Roles'
import { api } from 'core/api/ConnectionManager'
import { changeLocation, routes, history } from 'features/routes'
import { appActions, getMenu, getNotificationsCount } from 'blocks.app/app/app.state'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { downloadsActions } from 'core/services/DownloadService/DownloadService.state'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import deepEqual from 'fast-deep-equal'
import merge from 'merge'

export interface IUserState {
    data: IUser | null
    tokens: IUserTokens | null
    mainUser: IUser | null
    loading: boolean
    redirect: any
    transitionData: any
    isDeleted: boolean
    isFontsLoaded: boolean
    company: null | ICompany
    isUserPanelOpen: boolean
    isUserEditPanelOpen: boolean
    refreshTokenTimeout: null | NodeJS.Timeout
    selectedInfo: null | IUser
    isOpenProfileModal: boolean
    isActiveDirectory: boolean
}

export interface IAuthenticatedUserState {
    data: IUser
    tokens: IUserTokens
    mainUser: IUser
    loading: boolean
    redirect: any
    transitionData: any
    isDeleted: boolean
    isFontsLoaded: boolean
    company: null | ICompany
    isUserPanelOpen: boolean
    isUserEditPanelOpen: boolean
    refreshTokenTimeout: null | NodeJS.Timeout
    selectedInfo: null | IUser
    isOpenProfileModal: boolean
    isActiveDirectory: boolean
}

export interface LogoutPayload {
    isSwitchAccount: boolean
}

export interface CheckTokensPayload {
    tokens: IUserTokens
    cb: (tokens: IUserTokens) => void
}

export interface JoinPayload {
    isSwitching: boolean
    userId?: number
}

export interface JoinRequest {
    accessToken: string
    fromUserId?: number
}

export interface LoginPayload {
    user: IUser
    tokens: IUserTokens
}

export interface JoinData {
    data: IUser
    company?: ICompany
    mainUser?: IUser
}

export interface RefreshTokenPayload {
    refreshToken: string
    cb?: (tokens: IUserTokens) => void
}

export interface UpdateTokensTimerPayload {
    tokens: IUserTokens
}

export interface UpdateUserSettings {
    data: Partial<IUserSettings>
    isSaveSettings: boolean
}

export interface UpdateCompany {
    data: Partial<ICompany>
    isSaveCompany: boolean
}

export interface SwitchAccountPayload {
    account: any
    cb: () => {}
}

export interface OpenUserModalPayload {
    isActiveDirectory: boolean
    user?: IUser
}

const initialState: IUserState = {
    data: getUser(),
    tokens: getTokens(),
    mainUser: getMainUser(),
    loading: false,
    redirect: null,
    transitionData: null,
    isDeleted: false,
    isFontsLoaded: false,
    company: null,
    isUserPanelOpen: false,
    isUserEditPanelOpen: false,
    refreshTokenTimeout: null,
    selectedInfo: null,
    isOpenProfileModal: false,
    isActiveDirectory: false,
}

const createUserFormData = (user: IUser, mainUser: IUser, currentUser: IUser, tokens: IUserTokens) => {
    const headers = createRequestHeaders(mainUser, currentUser, tokens)
    const formData = new FormData()

    for (let [key, value] of Object.entries(user)) {
        formData.append(key, value)
    }

    return {
        headers,
        formData,
    }
}

export const login = createAsyncThunk('user/login', async (data: LoginPayload, { dispatch }) => {
    const { user, tokens } = data

    const decoratedUser = decorateUserWithDefaultSettings(user)

    setUser(decoratedUser)
    setTokens(tokens)
    setMainUser(decoratedUser)

    dispatch(userActions.setLogin({ user: decoratedUser, tokens }))
    dispatch(join({ isSwitching: false }))
    dispatch(updateTokensTimer({ tokens }))
})

export const join = createAsyncThunk(
    'user/join',
    async (data: JoinPayload, { getState, dispatch, rejectWithValue }) => {
        let { isSwitching, userId } = data
        const state = getState() as { user: IUserState }
        const { data: user, mainUser, tokens } = state.user

        if (!mainUser || !tokens || !user) return rejectWithValue(null)

        const currentUserId = userId || user.id
        const fromUserId = getFromUserId(mainUser.id, currentUserId)
        const requestParams: JoinRequest = fromUserId
            ? { accessToken: tokens.accessToken, fromUserId }
            : { accessToken: tokens.accessToken }

        try {
            const response: IUser = await api.send('join', requestParams)

            const query = getURLSearchParamsByLocation(history.location)
            const locale = query.lang
            const userData = isSwitching ? { ...user, permissions: [], roles: [] } : { ...user }

            let updatedState: JoinData = {
                data: merge.recursive(true, userData, response),
            }

            if (locale && response.settings.locale !== locale) {
                await api.send('updateUserSettings', { locale })
                updatedState.data.settings.locale = locale
            }

            const mainUser = getMainUser()

            if (!mainUser && !isSwitching) {
                setMainUser(updatedState.data)
                updatedState.mainUser = updatedState.data
            }

            if (mainUser) {
                updatedState.mainUser = mainUser

                if (updatedState.data.id === mainUser.id && !deepEqual(user, mainUser)) {
                    setMainUser(updatedState.data)
                }
            }

            if (isSwitching) {
                dispatch(getMenu())
                dispatch(getNotificationsCount())
            }

            let companyResponse = await api.send<object, ICompany>('getCompany', {})
            updatedState.company = companyResponse

            dispatch(appActions.setJoinedStatus(true))

            return updatedState
        } catch (err) {
            console.error(err)
            dispatch(emitError('authError'))
            dispatch(logout({ isSwitchAccount: false }))
            return rejectWithValue(err)
        }
    }
)

export const checkTokens = createAsyncThunk(
    'users/checkTokens',
    async (data: CheckTokensPayload, { getState, dispatch, rejectWithValue }) => {
        const state = getState() as { user: IUserState }
        const { cb, tokens } = data
        const userTokens = tokens || state.user.tokens

        try {
            const { now, refreshTokenExpDate, accessTokenDate } = getTokenDate(userTokens)

            if (now > refreshTokenExpDate) {
                dispatch(logout({ isSwitchAccount: false }))
                dispatch(emitError('tokenIsExpired'))
                return
            }

            if (now >= accessTokenDate) {
                dispatch(
                    refreshToken({
                        refreshToken: userTokens.refreshToken,
                        cb,
                    })
                )
            } else {
                cb(userTokens)
            }
        } catch (err) {
            console.error(err)
            dispatch(emitError('authError'))
            dispatch(logout({ isSwitchAccount: false }))
            return rejectWithValue(err)
        }
    }
)

export const refreshToken = createAsyncThunk(
    'users/refreshToken',
    async (data: RefreshTokenPayload, { dispatch, rejectWithValue }) => {
        const { refreshToken, cb } = data

        try {
            const response: IUserTokens = await api.send<object, IUserTokens>('refreshJwt', { refreshToken })
            setTokens(response)

            if (cb) {
                cb(response)
            }

            return response
        } catch (err) {
            dispatch(logout({ isSwitchAccount: false }))
            return rejectWithValue(err)
        }
    }
)

export const onRefreshTokenTimeout = createAsyncThunk(
    'user/updateTokensTime',
    async (data, { getState, dispatch, rejectWithValue }) => {
        const state = getState() as { user: IUserState }
        const userState = state.user
        const tokens = getTokens()

        if (!tokens) {
            dispatch(logout({ isSwitchAccount: false }))
            return rejectWithValue('Saved tokens does not exist')
        }

        try {
            if (!deepEqual(userState.tokens, tokens)) {
                dispatch(
                    checkTokens({
                        tokens,
                        cb: (newTokens: IUserTokens) => {
                            dispatch(updateTokensTimer({ tokens: newTokens }))
                        },
                    })
                )

                return tokens
            }

            dispatch(
                refreshToken({
                    refreshToken: tokens.refreshToken,
                    cb: (newTokens: IUserTokens) => {
                        dispatch(updateTokensTimer({ tokens: newTokens }))
                    },
                })
            )

            return null
        } catch (err) {
            dispatch(logout({ isSwitchAccount: false }))
            return rejectWithValue(null)
        }
    }
)

export const updateTokensTimer = createAsyncThunk(
    'user/updateTokensTimer',
    async (updateTokensTimerPayload: UpdateTokensTimerPayload, { getState, dispatch }) => {
        const state = getState() as { user: IUserState }
        const { refreshTokenTimeout } = state.user
        const { tokens } = updateTokensTimerPayload

        if (refreshTokenTimeout) {
            clearTimeout(refreshTokenTimeout)
        }

        return {
            refreshTokenTimeout: setTimeout(() => dispatch(onRefreshTokenTimeout()), getTokenDate(tokens).timer),
        }
    }
)

export const switchAccount = createAsyncThunk(
    'user/switchAccount',
    async (switchAccountPayload: SwitchAccountPayload, { dispatch }) => {
        const { account, cb } = switchAccountPayload

        dispatch(downloadsActions.clearAllDownloads())
        dispatch(logout({ isSwitchAccount: true }))

        setUser(account)
        dispatch(join({ isSwitching: true, userId: account.id }))
        changeLocation('/')

        if (cb) {
            cb()
        }
    }
)

export const autoLogout = createAsyncThunk(
    'user/autoLogout',
    async (id: number, { getState, dispatch, rejectWithValue }) => {
        const state = getState() as { user: IUserState }
        const { data: user } = state.user

        if (!user || id !== user.id) return rejectWithValue(null)

        dispatch(emitError('accountHasBeenDeleted'))

        setTimeout(() => {
            dispatch(logout({ isSwitchAccount: false }))
        }, 3000)

        return { isDeleted: true }
    }
)

export const logout = createAsyncThunk(
    'user/logout',
    async (logoutData: LogoutPayload, { getState, dispatch, rejectWithValue }) => {
        const state = getState() as { user: IUserState }
        const { data: user } = state.user
        const { isSwitchAccount } = logoutData

        if (!user) return rejectWithValue(null)

        clearUser()

        if (!isSwitchAccount) {
            clearTokens()
            dispatch(downloadsActions.clearAllDownloads())
            clearMainUser()
            changeLocation(`/${routes.login.path}${user.settings.locale ? `?lang=${user.settings.locale}` : ''}`)
        }

        return logoutData
    }
)

export const updateCompany = createAsyncThunk(
    'user/updateCompany',
    async (updateCompany: UpdateCompany, { getState }) => {
        const { data, isSaveCompany } = updateCompany

        if (!isSaveCompany) return data

        try {
            await api.send<Partial<ICompany>, null>('updateCompany', data)
        } catch (err) {
        } finally {
            return data
        }
    }
)

export const updateUserSettings = createAsyncThunk(
    'user/updateUserSettings',
    async (updateSettingsData: UpdateUserSettings, { getState }) => {
        const { isSaveSettings, data } = updateSettingsData

        if (!isSaveSettings) return data

        const state: any = getState()
        const userData = { userId: state.user.data.id, settings: data }

        try {
            await api.send('updateUserSettings', userData)
        } catch (err) {
        } finally {
            return data
        }
    }
)

export const updateUser = createAsyncThunk(
    'user/updateUser',
    async (user: IUser, { getState, dispatch, rejectWithValue }) => {
        const state = getState() as { user: IUserState }
        const { mainUser, tokens, data: currentUser } = state.user

        if (!mainUser || !currentUser || !tokens) return

        const { formData, headers } = createUserFormData(user, mainUser, currentUser, tokens)

        try {
            const response = await api.post('updateUser', formData, headers)

            return response.body.data
        } catch (err) {
            dispatch(emitError('updateUserError'))
            return rejectWithValue(null)
        }
    }
)

export const createUser = createAsyncThunk(
    'user/createUser',
    async (user: IUser, { getState, dispatch, rejectWithValue }) => {
        const state = getState() as { user: IUserState }
        const { mainUser, tokens, data: currentUser } = state.user

        if (!mainUser || !currentUser || !tokens) return

        const { formData, headers } = createUserFormData(user, mainUser, currentUser, tokens)

        try {
            const response = await api.post('createUser', formData, headers)
            return response.body
        } catch (err) {
            const error = JSON.parse(err.message).error
            if (error) {
                dispatch(emitError(error))
            } else {
                dispatch(emitError('createUserError'))
            }

            return rejectWithValue(null)
        }
    }
)

export const createRole = createAsyncThunk('user/createRole', async (role: IRole, { rejectWithValue }) => {
    try {
        await api.send('createRole', role)
        changeLocation(`/${routes.users.path}`)
    } catch (err) {
        return rejectWithValue(null)
    }
})

export const deleteLinkedAccount = createAsyncThunk(
    'user/deleteLinkedAccount',
    async (id: number, { rejectWithValue }) => {
        try {
            await api.send<{ id: number }, null>('deleteMultipleAccounts', { id })
            return id
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        openUserPanel(state) {
            state.isUserPanelOpen = true
        },
        closeUserPanel(state) {
            state.isUserPanelOpen = false
        },
        toggleUserPanel(state) {
            state.isUserPanelOpen = !state.isUserPanelOpen
        },
        openUserEditPanel(state) {
            state.isUserEditPanelOpen = true
        },
        closeUserEditPanel(state) {
            state.isUserEditPanelOpen = false
        },
        clearFeatures(state) {
            if (!state.data) return

            state.data.newFeatures = []
        },
        setFont(state) {
            state.isFontsLoaded = true
        },
        addLinkedAccount(state, action: PayloadAction<Partial<IUser>>) {
            if (!state.mainUser) return

            state.mainUser.multipleAccounts = [...state.mainUser.multipleAccounts, { ...action.payload }]
        },
        openUserModal(state, action: PayloadAction<OpenUserModalPayload>) {
            state.selectedInfo = action.payload.user || null
            state.isOpenProfileModal = true
            state.isActiveDirectory = action.payload.isActiveDirectory
        },
        closeUserModal(state) {
            state.selectedInfo = null
            state.isOpenProfileModal = false
            state.isActiveDirectory = false
        },
        setLogin(state, action: PayloadAction<LoginPayload>) {
            const { user, tokens } = action.payload

            state.tokens = tokens
            state.data = user
            state.mainUser = user
        },
        setTransition(state, action: PayloadAction<any>) {
            state.transitionData = action.payload
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(join.pending, (state, { payload }) => {
                state.loading = true
            })
            .addCase(join.fulfilled, (state, { payload }) => {
                const { data, mainUser, company } = payload
                state.data = data

                if (company) {
                    state.company = company
                }

                if (mainUser) {
                    state.mainUser = mainUser
                }

                state.loading = false
            })
            .addCase(join.rejected, (state) => {
                state.loading = false
            })
            .addCase(logout.fulfilled, (state, { payload }) => {
                let { isSwitchAccount } = payload

                if (!state.data || isSwitchAccount) return

                state.data.permissions = []
                state.company = null
                state.isFontsLoaded = false
                state.tokens = null

                if (state.refreshTokenTimeout) {
                    clearTimeout(state.refreshTokenTimeout)
                }

                state.refreshTokenTimeout = null
            })
            .addCase(refreshToken.fulfilled, (state, { payload }) => {
                state.tokens = payload
            })
            .addCase(updateTokensTimer.fulfilled, (state, { payload }) => {
                const { refreshTokenTimeout } = payload

                if (refreshTokenTimeout) {
                    state.refreshTokenTimeout = refreshTokenTimeout
                }
            })
            .addCase(onRefreshTokenTimeout.fulfilled, (state, { payload }) => {
                if (!payload) return

                state.tokens = payload
            })
            .addCase(updateUserSettings.fulfilled, (state, { payload }) => {
                if (!state.data) return

                state.data.settings = merge.recursive(true, { ...state.data.settings }, payload)
            })
            .addCase(updateCompany.fulfilled, (state, { payload }) => {
                if (!state.company) return

                state.company = { ...state.company, ...payload }
            })
            .addCase(autoLogout.fulfilled, (state, { payload }) => {
                state.isDeleted = payload.isDeleted
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.isOpenProfileModal = false
            })
            .addCase(updateUser.fulfilled, (state, { payload }) => {
                if (!state.data) return

                state.isOpenProfileModal = false

                if (payload.id !== state.data.id) return

                state.data = {
                    ...state.data,
                    name: payload.name,
                    email: payload.email,
                    photo: payload.photo,
                }
            })
            .addCase(deleteLinkedAccount.fulfilled, (state, { payload }) => {
                if (!state.mainUser) return

                state.mainUser.multipleAccounts = state.mainUser.multipleAccounts.filter(
                    (account: Partial<IUser>) => account.id !== payload
                )
            }),
})

const { reducer: userReducer, actions: userActions } = userSlice
export { userReducer, userActions }
