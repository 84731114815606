import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { addStyles } from 'ethcss'
import { colors, grid, indents, IStyles, mediaCSS, radiusSizes, textSizes } from 'theme'

export const styles: IStyles = {
    InformationWindow: {
        width: '100vw',
        height: '100vh',
        ...grid.flex,
        ...grid.col,
        ...grid.center,
        ...grid.normalCenter,
    },
    InformationWindow__window: {
        background: colors.white,
        borderRadius: 10,
        maxWidth: '80%',
    },
    InformationWindow__header: {
        padding: '28px 50px',
        textAlign: 'center',
        fontSize: textSizes.md,
        textTransform: 'uppercase',
        fontWeight: 700,
        [mediaCSS.tabMini]: {
            padding: '14px 15px',
        },
    },
    InformationWindow__main: {
        padding: '0px 50px',
        [mediaCSS.tabMini]: {
            padding: '0px 15px',
        },
    },
    InformationWindow__content: {
        padding: '24px 0 0 0',
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
        borderTop: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    InformationWindow__area: {
        overflowY: 'auto',
        [mediaCSS.tabMini]: {
            maxHeight: '400px!important',
        },
    },
    InformationWindow__area_centered: {
        ...grid.flex,
        ...grid.col,
        ...grid.center,
        ...grid.normalCenter,
    },
    InformationWindow__footer: {
        ...grid.flex,
        ...grid.row,
        ...grid.center,
        padding: '44px 50px 27px',
        [mediaCSS.tabMini]: {
            padding: '14px 15px 14px',
        },
    },
    InformationWindow__button: {
        margin: '0 6px',
    },
    InformationWindow__error: {
        ...grid.flex,
        ...grid.col,
        ...grid.center,
        ...grid.normalCenter,
    },
    InformationWindow__icon: {
        paddingBottom: 12,
    },
    InformationWindow__title: {
        paddingBottom: 22,
        fontSize: textSizes.md,
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    InformationWindow__text: {
        fontSize: textSizes.md,
        textAlign: 'center',
        lineHeight: 1.3,
    },
}

addStyles(styles)
