import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { indents } from 'blocks.app/config'
import { defaultThemeStyles } from '../editor.theme'
import { textSizes } from 'theme/media'

const editorTemplatesStyles = {
    wrapper: {
        ...grid.row,
        ...grid.pr_md,
    },
    cell: {
        ...grid.col3,
        ...grid.pl_md,
        ...grid.pt_md,
    },
    cellInner() {
        return {
            ...grid.col,
            cursor: 'pointer',
            position: 'relative',
            [`&:hover .${this.cellRect}`]: {
                ...item.shadowButton,
            },
            [`&:hover .${this.delete}`]: {
                visibility: 'visible',
            },
        }
    },
    cellRect: {
        backgroundColor: `var(--var-module-editor-component-templates-style-template, ${defaultThemeStyles.template})`,
    },
    title: {
        ...grid.w100,
        ...grid.mt_micro,
        textAlign: 'center',
        fontSize: textSizes.mini,
        color: `var(--var-module-editor-component-templates-style-template-title, ${defaultThemeStyles['template-title']})`,
    },
    area: {
        position: 'absolute',
        border: `1px solid var(--var-module-editor-component-templates-style-template-border, ${defaultThemeStyles['template-border']})`,
    },
    delete: {
        position: 'absolute',
        visibility: 'hidden',
        top: indents.micro,
        right: indents.micro,
    },
}

addStyles(editorTemplatesStyles)
export default editorTemplatesStyles
