import { useEffect, useState } from 'react'
import { api } from '../../core/api/ConnectionManager'
import { createRequestHeaders } from '../../core/helpers/user'
import store from 'core/store'
import { emitError } from '../../features/appNotifications/AppNotifications.state'
import { useDispatch } from 'react-redux'

export interface IFirmwareUpload {
    platform: string
    version: string
    modelNameForUpdate: string[]
    file: any
    packageType: string
    packageDescription: string
}

export interface IFirmware {
    id: number
    platform: string
    version: string
    distributiveURL: string
    modelNameForUpdate: string[]
    addedBy: string | null
    createdAt: string
}

export const FirmwareListHooks = (p_: any) => {
    const [firmwareList, setFirmwareList] = useState<IFirmware[]>([])
    const [searchValue, setSearchValue] = useState<string>('')
    const [sortType, setSortType] = useState<string>('')
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [deleteId, setDeleteId] = useState<number | null>(null)
    const state = store.getState()
    const { data: user, mainUser, tokens }: any = state.user
    const dispatch = useDispatch()

    const openDeleteConfirm = (id: number | null) => {
        setDeleteId(id)
    }
    const onOpen = () => {
        setIsOpen(true)
    }
    const onClose = () => {
        setIsOpen(false)
    }
    const onDelete = (id: number | null) => {
        if (!id) return

        api.send('deleteFirmware', { id }).then(() => {
            setFirmwareList(firmwareList.filter((firmware: any) => firmware.id !== id))
            setDeleteId(null)
        })
    }
    const onSortChange = (value: string) => {
        setSortType(value)
    }
    const onSearchChange = (e: any) => {
        setSearchValue(e.target.value)
    }
    const onClearSearch = () => {
        setSearchValue('')
    }
    const uploadFirmware = (data: IFirmwareUpload) => {
        setIsUploading(true)

        api.send('getPlatforms', {}).then((res: any) => {
            const platform = res.find((el: { name: string; id: number }) => el.name === data.platform)

            if (platform) {
                api.send('getDsBuildTypes', {}).then((res: any) => {
                    if (res) {
                        const buildType = res.find((type: { id: number; name: string }) => type.name === 'ds')

                        if (buildType) {
                            const formData = new FormData()
                            formData.append('file', data.file)
                            formData.append('version', data.version)
                            formData.append('platformId', platform.id)
                            formData.append('buildTypeId', buildType.id)
                            formData.append('modelNameForUpdate', JSON.stringify(data.modelNameForUpdate))
                            formData.append('capacity', 'any')

                            api.post('uploadFirmware', formData, createRequestHeaders(mainUser, user, tokens))
                                .then(() => {
                                    onClose()
                                    setIsUploading(false)
                                    getFirmwareList()
                                })
                                .catch(() => {
                                    setIsUploading(false)
                                    dispatch(emitError('uploadFirmwareError'))
                                })
                        }
                    }
                })
            }
        })
    }
    const uploadClientApp = (data: IFirmwareUpload) => {
        setIsUploading(true)

        api.send('getPlatforms', {}).then((res: any) => {
            const platform = res.find((el: { name: string; id: number }) => el.name === data.platform)

            if (platform) {
                api.send('getDsBuildTypes', {}).then((res: any) => {
                    if (res) {
                        const buildType = res.find((type: { id: number; name: string }) => type.name === 'ds')

                        if (buildType) {
                            const formData = new FormData()
                            formData.append('file', data.file)
                            formData.append('version', data.version)
                            formData.append('platformId', platform.id)
                            formData.append('buildTypeId', buildType.id)
                            formData.append('packageType', data.packageType)
                            formData.append('description', data.packageDescription)
                            formData.append('capacity', 'any')

                            api.post('uploadClientApp', formData, createRequestHeaders(mainUser, user, tokens))
                                .then(() => {
                                    onClose()
                                    setIsUploading(false)
                                    getFirmwareList()
                                })
                                .catch(() => {
                                    setIsUploading(false)
                                    dispatch(emitError('uploadFirmwareError'))
                                })
                        }
                    }
                })
            }
        })
    }
    const editFirmware = (id: number, modelNameForUpdate: string[]) => {
        api.send('updateFirmware', { id, modelNameForUpdate }).then((res: any) => {
            if (res) {
                const updatedFirmware = {
                    id: res.id,
                    platform: res.platform.name,
                    version: res.version,
                    distributiveURL: res.distributiveURL,
                    modelNameForUpdate: res.modelNameForUpdate,
                    addedBy: res.uploadedUser ? res.uploadedUser.email : null,
                    createdAt: res.createdAt,
                }

                setFirmwareList(
                    firmwareList.map((firmware) => {
                        if (firmware.id === id) {
                            return updatedFirmware
                        }
                        return firmware
                    })
                )
            }
        })
    }
    const getFirmwareList = () => {
        const params: any = {}

        if (searchValue) {
            params.searchString = searchValue
        }
        if (sortType) {
            const sort = []

            switch (sortType) {
                case 'byVersion':
                    sort.push(['version', 'ASC'])
                    break
                case '-byVersion':
                    sort.push(['version', 'DESC'])
                    break
                case 'byDate':
                    sort.push(['createdAt', 'ASC'])
                    break
                case '-byDate':
                    sort.push(['createdAt', 'DESC'])
            }

            params.sort = sort
        }

        api.send('getFirmwares', params).then((res: any) => {
            if (res) {
                setFirmwareList(
                    res.map((firmware: any) => {
                        return {
                            id: firmware.id,
                            platform: firmware.platform.name,
                            version: firmware.version,
                            distributiveURL: firmware.distributiveURL,
                            modelNameForUpdate: firmware.modelNameForUpdate,
                            addedBy: firmware.uploadedUser ? firmware.uploadedUser.email : null,
                            createdAt: firmware.createdAt,
                        }
                    })
                )
            }
        })
    }

    useEffect(() => {
        getFirmwareList()
    }, [searchValue, sortType])

    return {
        firmwareList,
        searchValue,
        onSearchChange,
        onDelete,
        sortType,
        onSortChange,
        uploadFirmware,
        isOpen,
        onOpen,
        onClose,
        editFirmware,
        isUploading,
        onClearSearch,
        deleteId,
        openDeleteConfirm,
        uploadClientApp,
    }
}
