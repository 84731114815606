import { useState } from 'react'
import { ITabsProps } from './tabs'

const Tabs = (p_: ITabsProps) => {
    const [active, setActive] = useState<string>(p_.active || p_.children[0].props.tabid)

    const getTabFromId = (tabid: string) => {
        const children = p_.children.slice()
        return children.find((child: any) => child.props.tabid === tabid)
    }
    const onClick = (tab: string) => {
        setActive(tab)
        p_.onChange(tab)
    }

    return {
        active,
        onClick,
        getTabFromId,
    }
}

export default Tabs
