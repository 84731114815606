import React from 'react'
import { Radio } from '../../../atoms/Radio'
import Collapse from 'blocks.simple/collapse/collapse'
import helpers from 'core/helpers'
import translate, { LANGUAGES } from 'core/translate'
import { cn } from 'ethcss'
import { grid } from 'theme'
import { text as textStyles } from 'theme'
import { Typography } from 'atoms/Typography'
import { getTimezonesList } from 'blocks.app/app/app.state'
import { useDispatch } from 'react-redux'
import { updateUserSettings } from 'blocks.app/user/user.state'
import styles from '../settings.jcss'

type Locale = {
    lang: string
}

const SettingsLang = React.memo(function ({ lang }: Locale) {
    const dispatch = useDispatch()

    function changeLang(locale: any) {
        if (locale !== lang) {
            dispatch(getTimezonesList(locale))
            dispatch(updateUserSettings({ data: { locale }, isSaveSettings: true }))
        }
    }

    return (
        <Collapse title={() => translate('language')} className={cn(styles.background, grid.mb_md)}>
            <div className={cn(grid.row)}>
                {LANGUAGES.map((language) => (
                    <div key={language.id} className={cn(grid.colCenter, grid.mr_mini)}>
                        <Radio
                            className={cn(grid.mb_micro)}
                            selected={lang === language.id}
                            onClick={() => changeLang(language.id)}
                            disabled={!helpers.isAvailable({ key: 'settings', action: 'update' })}
                            name="settingsLocale"
                        />
                        <Typography type="text" className={cn(textStyles.mini)}>
                            {language.text}
                        </Typography>
                    </div>
                ))}
            </div>
        </Collapse>
    )
})

export default SettingsLang
