import React from 'react'
import CustomList from 'blocks.app/customList/customList'
import Content from 'blocks.app/content/content'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

class InstancesCatalogSnacks extends React.Component {
    state = {
        selectedItems: [],
    }

    render() {
        const s_ = this.state
        return (
            <Content title={'snacksCatalog'}>
                <CustomList
                    type={'snacks'}
                    onSelect={(selectedItems) => this.setState({ selectedItems })}
                    selectedItems={s_.selectedItems}
                    additionalData={{}}
                />
            </Content>
        )
    }
}

const exportInstancesCatalogSnacks = checkAvailableModule('instancesCatalog/snacks') ? InstancesCatalogSnacks : null

export default exportInstancesCatalogSnacks
