import React from 'react'
import AdBlocksToolbarContent from '../__toolbarContent/adBlocks__toolbarContent'
import Sidebar from 'blocks.app/sidebar/sidebar'
import translate from 'core/translate'
import { api } from 'core/api/ConnectionManager'

export interface IAdBlocksToolbar {
    selectedInfo: {
        id: number
        title: string
        cover: {
            src: string
            isIcon: boolean
        }
    } | null
    selectedItems: { id: number }[]
    onCloseInfo: any
    onChange: any
}

const AdBlocksToolbar = (p_: IAdBlocksToolbar) => (
    <Sidebar
        selectedSide="right"
        onChange={p_.onChange}
        customContent={{
            right: <AdBlocksToolbarContent {...p_} />,
        }}
        content={{
            right: !p_.selectedInfo
                ? {
                      title: translate('adBlocks'),
                      options: p_.selectedItems,
                  }
                : null,
        }}
        isEmpty={{
            right: !p_.selectedInfo && !p_.selectedItems.length,
        }}
        emptyText={{
            right: {
                title: translate('noSelectedAdBlock'),
                description: translate('noSelectedAdBlockDescription'),
            },
        }}
        onSubmit={(side: string, type: string) => {
            if (side === 'right' && type === 'delete') {
                const id = p_.selectedItems.map((selectedItem: { id: number }) => selectedItem.id)
                api.send('deleteFillersAdBlock', { id }).then(() => {
                    p_.onChange([])
                })
            }
        }}
        isShowActions
        isDeletable
    />
)

export default AdBlocksToolbar
