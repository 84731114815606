import React, { ChangeEvent } from 'react'
import DisplaysScreenShotsSidebarMethods from './displays__screenShots_sidebar.local.methods'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import { Radio } from 'atoms/Radio'
import InfiniteScroll from 'react-infinite-scroller'
import EditText from 'blocks.simple/editText/editText'
import LoaderLazy from 'blocks.app/loader/_lazy/loader__lazy'
import Measure, { ContentRect } from 'react-measure'
import Icon from 'blocks.simple/icon/icon'
import { Scrollbars } from 'react-custom-scrollbars'
import Dropzone from 'react-dropzone'
import moment from 'moment'
import helpersLegacy from 'core/helpers'
import helpers from 'core/helpers'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './displays__screenShots_sidebar.jcss'
import { grid, item } from 'theme'
import { text as textStyles } from 'theme'
import { Steps } from 'atoms/Steps/Steps-view'
import { connect } from 'react-redux'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { uploadDigitalSignageAndGroupMedia } from 'pages/files/files.state'

const accept = {
    'image/jpg': ['.jpg', '.jpeg', '.png', '.gif', '.pjpeg'],
    'video/mp4': ['.mp4', '.ogg', '.avi'],
    'application/x-troff-msvideo': ['.msvideo', '.x-msvideo'],
}

class DisplaysScreenShotsSidebar extends DisplaysScreenShotsSidebarMethods {
    static defaultProps = {
        onChangeActive: () => {},
    }

    getMediaImage(media: { createdAt: string; id: number; url: string; thumbnailUrl?: string }) {
        return (
            <Rectangle
                width={16}
                height={9}
                src={media.thumbnailUrl || 'images/displays/placeholder.png'}
                contain={!media.thumbnailUrl}
            />
        )
    }

    render() {
        const s_ = this.state
        const p_ = this.props
        const selectAllIconProps = this.getSelectAllIconProps()
        let resolutionHeight = p_.selectedInfo.resolutionHeight ? p_.selectedInfo.resolutionHeight : 1080
        let resolutionWidth = p_.selectedInfo.resolutionWidth ? p_.selectedInfo.resolutionWidth : 1920
        return (
            <>
                {helpersLegacy.isMobileDevice() && (
                    <div
                        className={cn(styles.toggleIcon, { [styles.toggleIconOpen]: !p_.minimized })}
                        onClick={p_.toggleMinimized}
                    >
                        <Icon type="burger" color="white" size={24} />
                    </div>
                )}
                <div className={cn(styles.sidebar, { [styles.sidebarOpen]: !p_.minimized })}>
                    <Measure
                        bounds
                        onResize={({ bounds }: ContentRect) => {
                            this.setState({ height: bounds!.height })
                        }}
                    >
                        {({ measureRef }) => (
                            <div ref={measureRef}>
                                {p_.selectedInfo.type === 'group' && (
                                    <div className={styles.titleSidebar}>{translate('installation')}</div>
                                )}
                                {p_.selectedInfo.type !== 'group' && (
                                    <Steps className={styles.tabs} active={s_.activeTab} mod={'inverse'}>
                                        {this.tabs.map((tab) => (
                                            <div
                                                className={styles.tabsColor}
                                                id={tab.id}
                                                key={tab.id}
                                                onClick={() => this.setState({ activeTab: tab.id })}
                                            >
                                                {tab.label}
                                            </div>
                                        ))}
                                    </Steps>
                                )}
                                {s_.activeTab === 'installation' && (
                                    <div className={styles.toolbar}>
                                        <Icon
                                            type="download"
                                            size={24}
                                            color="white"
                                            onClick={this.openDropzone}
                                            disabled={s_.isLoadingMedia}
                                            tooltip={{
                                                html: translate('upload'),
                                            }}
                                        />
                                        <Dropzone
                                            //className={cn(styles.dropzoneHide)}
                                            ref={(dropzone) => (this.dropzone = dropzone)}
                                            onDrop={this.onDrop}
                                            accept={accept}
                                            //disablePreview
                                        >
                                            {() => <></>}
                                        </Dropzone>
                                        {s_.media.length > 0 && (
                                            <div
                                                className={cn(grid.p_mini, {
                                                    [item.hidden]: !s_.selectedMedia.length,
                                                })}
                                            >
                                                <Icon
                                                    type={'delete'}
                                                    color={'white'}
                                                    size={18}
                                                    tooltip={{
                                                        title: translate('delete'),
                                                    }}
                                                    onClick={this.onDeleteMedia}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                                {s_.activeTab === 'screenshotsHistory' && (
                                    <div>
                                        {helpers.isAvailableAction(p_.selectedInfo, {
                                            key: 'digitalSignage',
                                            action: 'update',
                                        }) && (
                                            <React.Fragment>
                                                <div className={styles.toolbar}>
                                                    <div>{translate('autoScreenShot')}</div>
                                                    <div className={styles.radio}>
                                                        <Radio
                                                            className={cn(grid.mt_md, grid.mr_md)}
                                                            label={translate('on')}
                                                            onClick={() =>
                                                                this.setAutoScreenShotSettings(true, 'enabled')
                                                            }
                                                            selected={s_.autoScreenshot.enabled}
                                                            mod="white"
                                                        />
                                                        <Radio
                                                            className={cn(grid.mt_md)}
                                                            label={translate('off')}
                                                            onClick={() =>
                                                                this.setAutoScreenShotSettings(false, 'enabled')
                                                            }
                                                            selected={!s_.autoScreenshot.enabled}
                                                            mod="white"
                                                        />
                                                    </div>
                                                    <div className={grid.mt_md}>{translate('intervalSeconds')}</div>
                                                    <div className={styles.inputCol}>
                                                        <EditText
                                                            disabled={!s_.autoScreenshot.enabled}
                                                            type={'number'}
                                                            containerClass={cn(grid.mt_md, grid.mb_md)}
                                                            mod={'inverseBorder'}
                                                            value={s_.autoScreenshot.interval / 1000}
                                                            onChange={(e: number) =>
                                                                this.setAutoScreenShotSettings(e, 'interval')
                                                            }
                                                        />
                                                        <div>{translate('screenshotWidth')}</div>
                                                        <EditText
                                                            disabled={!s_.autoScreenshot.enabled}
                                                            type={'number'}
                                                            containerClass={cn(grid.mt_md, grid.mb_md)}
                                                            mod={'inverseBorder'}
                                                            value={s_.autoScreenshot.width}
                                                            onChange={(e: number) =>
                                                                this.setAutoScreenShotSettingsWidthHeight(
                                                                    e,
                                                                    'width',
                                                                    resolutionWidth
                                                                )
                                                            }
                                                            valueType={'screenshot'}
                                                            max={resolutionWidth}
                                                        />
                                                        <div>{translate('screenshotHeight')}</div>
                                                        <EditText
                                                            disabled={!s_.autoScreenshot.enabled}
                                                            type={'number'}
                                                            containerClass={cn(grid.mt_md, grid.mb_md)}
                                                            mod={'inverseBorder'}
                                                            value={s_.autoScreenshot.height}
                                                            onChange={(e: number) =>
                                                                this.setAutoScreenShotSettingsWidthHeight(
                                                                    e,
                                                                    'height',
                                                                    resolutionHeight
                                                                )
                                                            }
                                                            valueType={'screenshot'}
                                                            max={resolutionHeight}
                                                        />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )}
                                        <div className={cn(grid.row, grid.p_mini)}>
                                            <div className={grid.w40}>
                                                <EditText
                                                    type="date"
                                                    mod={'inverseBorder'}
                                                    iconColor={'white'}
                                                    // readOnly={true}
                                                    indentSize={'mini'}
                                                    popperPlacement={'left-start'}
                                                    dateValue={s_.startDate}
                                                    rounded={'left_mini'}
                                                    onChange={(startDate: moment.Moment) => {
                                                        if (startDate > s_.endDate) {
                                                            this.setState({ startDate, endDate: startDate })
                                                        } else {
                                                            this.setState({ startDate })
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className={grid.w40}>
                                                <EditText
                                                    type="date"
                                                    // readOnly={true}
                                                    mod={'inverseBorder'}
                                                    iconColor={'white'}
                                                    popperPlacement={'left-start'}
                                                    dateValue={s_.endDate}
                                                    minDate={s_.startDate}
                                                    rounded={'none'}
                                                    indentSize={'mini'}
                                                    onChange={(endDate: moment.Moment) => {
                                                        if (endDate > s_.startDate) {
                                                            this.setState({ endDate })
                                                        } else {
                                                            this.setState({ endDate: s_.endDate })
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.filter} onClick={this.onFilter}>
                                                <Icon type={'filter'} color={'white'} size={16} />
                                            </div>
                                        </div>
                                        <div className={cn(grid.p_mini, styles.actionPanel)}>
                                            <div className={styles.selectAll}>
                                                <Icon {...selectAllIconProps} onClick={this.onSelectAll} />
                                            </div>
                                            {s_.selected.length !== 0 &&
                                                s_.screenshots.length > 0 &&
                                                helpers.isAvailableAction(p_.selectedInfo, {
                                                    key: 'digitalSignage',
                                                    action: 'update',
                                                }) && (
                                                    <Icon
                                                        type={'delete'}
                                                        color={'white'}
                                                        size={18}
                                                        tooltip={{
                                                            title: translate('delete'),
                                                        }}
                                                        onClick={this.onDelete}
                                                    />
                                                )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </Measure>
                    {s_.activeTab === 'screenshotsHistory' && (
                        <div>
                            {s_.screenshots.length > 0 && (
                                <Scrollbars
                                    style={{
                                        height: document.body.offsetHeight - s_.height - 20,
                                    }}
                                >
                                    <InfiniteScroll
                                        loadMore={() => this.getScreenshots(true)}
                                        hasMore={s_.screenshots.length < s_.total}
                                        initialLoad={false}
                                        loader={
                                            <LoaderLazy
                                                key={`fullscreen_device_loader`}
                                                active={s_.isLoading && s_.screenshots.length > 0}
                                            />
                                        }
                                        threshold={10}
                                        useWindow={true}
                                    >
                                        {s_.screenshots.map((screenshot, index) => {
                                            const isSelected = s_.selected.includes(screenshot.id)
                                            const isActive = s_.active && s_.active.id === screenshot.id

                                            return (
                                                <div
                                                    key={`screenshot_sidebar_list_key${index}`}
                                                    className={cn(styles.screenshotInfo, { [styles.active]: isActive })}
                                                >
                                                    <div
                                                        className={cn(styles.checkbox, {
                                                            [styles.checkboxActive]: isSelected,
                                                        })}
                                                    >
                                                        <div className={grid.row}>
                                                            <Checkbox
                                                                checked={isSelected}
                                                                mod={'white'}
                                                                onClick={(checked: boolean) => {
                                                                    const selected = [...s_.selected]

                                                                    if (checked) {
                                                                        selected.push(screenshot.id)
                                                                    } else {
                                                                        selected.splice(
                                                                            s_.selected.indexOf(screenshot.id),
                                                                            1
                                                                        )
                                                                    }

                                                                    this.setState({ selected })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={cn(grid.row, grid.fullWidth, grid.normalCenter)}
                                                        onClick={() => this.setActiveMedia(screenshot)}
                                                    >
                                                        <div className={grid.w40}>
                                                            <Rectangle width={16} height={9} src={screenshot.url} />
                                                        </div>
                                                        <div className={cn(grid.w60, grid.p_mini)}>
                                                            <div className={styles.screenInfo}>
                                                                {translate('screen')} {index + 1}
                                                            </div>
                                                            <div className={grid.row}>
                                                                <div className={item.ellipsisWrapper}>
                                                                    <div
                                                                        className={cn(item.ellipsis, styles.screenInfo)}
                                                                    >
                                                                        {moment(screenshot.createdAt).format(
                                                                            'HH:mm - DD.MM.YYYY'
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </InfiniteScroll>
                                </Scrollbars>
                            )}
                            {!s_.screenshots.length && (
                                <div className={cn(grid.colCenter, grid.normalCenter, grid.p_mini, textStyles.center)}>
                                    {translate('screenshotsNotFound')}
                                </div>
                            )}
                        </div>
                    )}
                    {s_.activeTab === 'installation' && (
                        <div>
                            {s_.media.length > 0 && (
                                <Scrollbars
                                    style={{
                                        height: document.body.offsetHeight - s_.height - 20,
                                    }}
                                >
                                    <InfiniteScroll
                                        loadMore={() => this.getMedia(true)}
                                        hasMore={s_.media.length < s_.totalMedia}
                                        initialLoad={false}
                                        threshold={10}
                                        useWindow={true}
                                    >
                                        {s_.media.map((media, index) => {
                                            const isSelected = s_.selectedMedia.includes(media.id)
                                            const isActive = s_.active && s_.active.id === media.id

                                            return (
                                                <div
                                                    key={media.id}
                                                    className={cn(styles.screenshotInfo, { [styles.active]: isActive })}
                                                >
                                                    <div
                                                        className={cn(styles.checkbox, {
                                                            [styles.checkboxActive]: isSelected,
                                                        })}
                                                    >
                                                        <div className={grid.row}>
                                                            <Checkbox
                                                                checked={isSelected}
                                                                mod={'white'}
                                                                onClick={(checked: boolean) => {
                                                                    const selected = [...s_.selectedMedia]

                                                                    if (checked) {
                                                                        selected.push(media.id)
                                                                    } else {
                                                                        selected.splice(
                                                                            s_.selected.indexOf(media.id),
                                                                            1
                                                                        )
                                                                    }

                                                                    this.setState({ selectedMedia: selected })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={cn(grid.row, grid.fullWidth, grid.normalCenter)}
                                                        onClick={() => this.setActiveMedia(media)}
                                                    >
                                                        <div className={grid.w40}>{this.getMediaImage(media)}</div>
                                                        <div className={cn(grid.w60, grid.p_mini)}>
                                                            <div className={styles.screenInfo}>
                                                                {translate('installation').toLowerCase()} {index + 1}
                                                            </div>
                                                            <div className={grid.row}>
                                                                <div className={item.ellipsisWrapper}>
                                                                    <div
                                                                        className={cn(item.ellipsis, styles.screenInfo)}
                                                                    >
                                                                        {moment(media.createdAt).format(
                                                                            'HH:mm - DD.MM.YYYY'
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </InfiniteScroll>
                                </Scrollbars>
                            )}
                            {!s_.media.length && (
                                <div className={cn(grid.colCenter, grid.normalCenter, grid.p_mini, textStyles.center)}>
                                    {translate('mediaFilesNotFound')}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        emitError: (err: string) => dispatch(emitError(err)),
        uploadDigitalSignageAndGroupMedia: (uploadPayload: { formData: FormData; cb: () => void }) =>
            dispatch(uploadDigitalSignageAndGroupMedia(uploadPayload)),
    }
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(DisplaysScreenShotsSidebar)
