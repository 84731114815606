import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { textSizes } from 'theme/media'

const tagsListStyles = {
    title: {
        ...grid.mb_md,
        fontSize: textSizes.normalPlus,
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    form: {
        ...grid.rowCenter,
        ...grid.mb_md,
        ...grid.mt_md,
        ...grid.pb_mdPlus,
        borderBottom: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles.border})`,
    },
    search: {
        ...grid.rowCenter,
        ...grid.mt_mini,
    },
    content: {
        ...grid.space,
        ...item.overAuto,
        ...item.white,
    },
    listItem() {
        return {
            ...grid.row,
            ...grid.justify,
            [`&:hover .${this.icon}`]: {
                ...item.visible,
            },
        }
    },
    editable: {
        ...grid.w85,
    },
    icon: {
        ...grid.space,
        ...grid.rowCenter,
        ...item.pointer,
        ...item.hidden,
    },
    input: {
        ...grid.w90,
        ...grid.mb_mini,
    },
    message: {
        ...grid.p_md,
        textTransform: 'uppercase',
        textAlign: 'center',
    },
}

addStyles(tagsListStyles)
export default tagsListStyles
