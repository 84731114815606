import itemStyles from './item/styles.js'
import gridStyles from './grid/styles.js'
import textStyles from './text/styles.js'
import { colors } from './colors'

import { cols, mediaCSS, mediaJS, indents, radiusSizes, textSizes, layers, media, font, transitions } from './media'

export interface IStyles {
    [key: string]: any
}

export interface IStyleMediaProperties {
    desktop: IStyles
    tabMini: IStyles
}

export type TClassName = object | string

export const item: IStyles = itemStyles
export const grid: IStyles = gridStyles
export const text: IStyles = textStyles
export { colors, cols, media, mediaCSS, mediaJS, indents, radiusSizes, textSizes, layers, font, transitions }
