import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { mediaCSS } from 'blocks.app/config'
import item from 'blocks.simple/item/item.jcss'
import { textSizes } from 'theme/media'
import { IStyles } from '../../theme'

const deviceSettingsStyles: IStyles = {
    wrapper: {
        ...grid.row,
        ...grid.full,
        ...item.white,
        ...grid.noWrap,
        [mediaCSS.tabMini]: {
            ...grid.col,
        },
    },
    sidebar: {
        ...grid.col,
        ...grid.justify,
        ...grid.p_md,
        ...grid.pt_mdPlus,
        ...item.clouds,
        fontSize: textSizes.normalPlus,
        overflowY: 'auto',
        [mediaCSS.tabMini]: {
            ...grid.col,
            ...grid.pt_mini,
            overflowY: 'initial',
        },
    },
    sidebarTitle: {
        [mediaCSS.tabMini]: {
            ...grid.mb_mdPlus,
        },
        textAlign: 'center',
    },
    content: {
        ...grid.col,
        ...grid.space,
        ...item.rel,
    },
    title: {
        fontSize: textSizes.md,
        ...grid.pb_mdPlus,
    },
    buttons: {
        position: 'fixed',
        right: 0,
        left: '132px',
        bottom: 0,
        paddingLeft: '34px',
        paddingBottom: '34px',
        background: '#FFFFFF',
        zIndex: 10000,
        ...grid.row,
        ...grid.mt_mdPlus,
        [mediaCSS.tab]: {
            ...grid.center,
            left: 0,
            paddingLeft: '14px',
            paddingRight: '14px',
        },
    },
    pinCodeWrapper: {
        ...grid.col,
        ...grid.w20,
        [mediaCSS.tabMini]: {
            ...grid.w60,
        },
        ...grid.mt_mdPlus,
    },
    syncContentWrapper: {
        ...grid.w40,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
        ...grid.mt_mdPlus,
    },
    exhibitionModeContentWrapper: {
        ...grid.w100,
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
        ...grid.mt_mdPlus,
    },
    row: {
        ...grid.fullWidth,
        ...grid.mt_mdPlus,
    },
    positionWrapper: {
        ...grid.row,
        ...grid.fullWidth,
    },
    positionContent: {
        ...grid.col,
        ...grid.w25,
        '&:first-child': {
            ...grid.pr_mini,
        },
        '&:last-child': {
            ...grid.pl_mini,
        },
        [mediaCSS.tab]: {
            ...grid.w45,
        },
        [mediaCSS.tabMini]: {
            ...grid.w100,
            '&:first-child': {
                ...grid.p_none,
            },
            '&:last-child': {
                ...grid.p_none,
            },
        },
    },
    deviceSettings__inner: {
        ...grid.p_mdPlus,
        paddingBottom: '126px',
        [mediaCSS.tabMini]: {
            ...grid.p_mini,
            paddingBottom: '234px',
        },
        '& .dropdown_bottomContent': {
            position: 'relative',
        },
        '& .dropdown_topContent': {
            position: 'static',
        },
    },
    deviceSettings__inputWrapper_type_width: {
        ...grid.row,
        ...grid.fullWidth,
        ...grid.mb_mdPlus,
        [mediaCSS.tabMini]: {
            ...grid.mb_md,
        },
    },
    deviceSettings__inputWrapper_type_speedLimit: {
        ...grid.col,
        ...grid.w25,
        [mediaCSS.tabMini]: {
            ...grid.w60,
        },
        ...grid.mt_md,
    },
    deviceSettings__input_type_contentDownloadType: {
        ...grid.mr_md,
        [mediaCSS.tabMini]: {
            ...grid.mb_md,
        },
    },
    deviceSettings__inputWrapper_type_netDiskAddress: {
        ...grid.mt_mdPlus,
        [mediaCSS.tabMini]: {
            ...grid.mt_none,
        },
    },
    collapseBorder: {
        background: '#FFFFFF',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
    },
    dropdownWrapper: {
        ...grid.w25,
        [mediaCSS.tab]: {
            ...grid.w50,
        },
        [mediaCSS.tabMini]: {
            ...grid.w100,
        },
    },
}

addStyles(deviceSettingsStyles)
export default deviceSettingsStyles
