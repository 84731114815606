import React from 'react'
import VideoEditorMethods, { IVideoEditorMethodsProps } from './videoEditor.local.methods'
import VideoEditorContent from './__content/videoEditor__content'
import VideoEditorSidebar from './__sidebar/videoEditor__sidebar'

import styles from './videoEditor.jcss'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { uploadVideoEditor } from 'pages/files/files.state'

const VideoEditor = (props: IVideoEditorMethodsProps) => {
    const {
        state,
        setState,
        toggleMinimized,
        reset,
        onDrop,
        onSelectTasks,
        onUpload,
        onChangeTask,
        onSelectTask,
        onProcessMedia,
        onChangeSettings,
        save,
        videoEditorSidebarRef,
    } = VideoEditorMethods(props)

    const s_ = { ...state }

    return (
        <div className={styles.colsWrapper}>
            <div className={styles.colsContent}>
                <VideoEditorContent
                    //@ts-ignore
                    media={s_.media}
                    selectedTasks={s_.selectedTasks}
                    commands={s_.commands}
                    downloadMediaPercentage={s_.downloadMediaPercentage}
                    onUpload={onUpload}
                    onDropContent={onDrop}
                    showSettings={s_.showSettings}
                    onDeleteMedia={reset}
                    onSave={save}
                    minimized={s_.minimized}
                    toggleMinimized={() => {
                        setState((prevState) => {
                            return {
                                ...prevState,
                                minimized: !s_.minimized,
                            }
                        })
                    }}
                    openMinimized={() => {
                        if (s_.minimized) {
                            setState((prevState) => {
                                return {
                                    ...prevState,
                                    minimized: false,
                                }
                            })
                        }
                    }}
                    animationSettings={s_.openAnimationSettings}
                    onChangeCommands={(commands) =>
                        setState((prevState) => {
                            return {
                                ...prevState,
                                commands,
                            }
                        })
                    }
                    onChangeShowSettings={(showSettings) => {
                        switch (showSettings) {
                            case 'animations':
                                setState((prevState) => {
                                    return {
                                        ...prevState,
                                        openAnimationSettings: !s_.openAnimationSettings,
                                    }
                                })
                                break
                            default:
                                setState((prevState) => {
                                    return {
                                        ...prevState,
                                        showSettings,
                                    }
                                })
                        }
                    }}
                />
            </div>
            <VideoEditorSidebar
                videoEditorSidebarRef={videoEditorSidebarRef}
                showSettings={s_.showSettings}
                //@ts-ignore
                media={s_.media}
                commands={s_.commands ? s_.commands : {}}
                defaultCommands={s_.sourceData}
                settings={s_.settings}
                onSelectTask={onSelectTask}
                onChangeTask={onChangeTask}
                onProcessMedia={onProcessMedia}
                selectedTasks={state.selectedTasks}
                onSelectTasks={onSelectTasks}
                selectedTasksSourceType={state.selectedTasksSourceType}
                onChangeCommands={(commands) =>
                    setState((prevState) => {
                        return {
                            ...prevState,
                            commands,
                        }
                    })
                }
                onChangeSettings={onChangeSettings}
                onChangeShowSettings={(showSettings) =>
                    setState((prevState) => {
                        return {
                            ...prevState,
                            showSettings,
                        }
                    })
                }
                minimized={s_.minimized}
                toggleMinimized={toggleMinimized}
                openMinimized={() => {
                    if (s_.minimized) {
                        setState((prevState) => {
                            return {
                                ...prevState,
                                minimized: false,
                            }
                        })
                    }
                }}
            />
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        emitError: (err: string) => dispatch(emitError(err)),
        uploadVideoEditor: (uploadPayload: any) => dispatch(uploadVideoEditor(uploadPayload)),
    }
}

export default connect(null, mapDispatchToProps)(withRouter(VideoEditor))
