import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import { cn } from 'ethcss'
import moment from 'moment'
import { ICatalogWithPaginationReferenceBookHeaderProps } from 'organisms/CatalogWithPagination/CatalogWithPagination-types'
import React, { FC, memo, useEffect, useState } from 'react'
import { styles } from './ProductsReferenceBookHeader-styles'
import { IProductsUpdateInfo } from './ProductsReferenceBookHeader-types'

const ProductsReferenceBookHeaderComponent: FC<ICatalogWithPaginationReferenceBookHeaderProps> = ({ className }) => {
    const [title, setTitle] = useState<string | null>(null)

    useEffect(() => {
        const fetchProductsUpdateInfo = () => {
            api.send<object, IProductsUpdateInfo>('getProductsUpdateInfo', {})
                .then((res) => {
                    if (!res) return

                    setTitle(createTitle(res))
                })
                .catch(() => {})
        }

        fetchProductsUpdateInfo()
    }, [])

    const createTitle = (productsUpdateInfo: IProductsUpdateInfo) => {
        if (!productsUpdateInfo) return null

        let { lastUpdatedAt, lastFileName } = productsUpdateInfo

        if (lastFileNameIsObject(lastFileName)) {
            lastFileName = convertLastFileNameToString(lastFileName)
        }

        const momentDate = moment(lastUpdatedAt).format('YYYY-MM-DD HH:mm')

        return `${translate('productsCatalog')} (${translate('updatedAt')}: ${momentDate}, ${translate(
            'fileNames'
        )}: ${lastFileName})`
    }

    const convertLastFileNameToString = (fileName: any) => {
        let fileNameString = ''

        for (let key in fileName) {
            fileNameString += fileName[key] + ','
        }

        return fileNameString.replace(/,\s*$/, '')
    }

    const lastFileNameIsObject = (lastFileName: any) => {
        return typeof lastFileName === 'object'
    }

    return <div className={cn(styles.ProductsReferenceBookHeader, className)}>{title}</div>
}

export const ProductsReferenceBookHeader = memo(ProductsReferenceBookHeaderComponent)
