import { addStyles } from 'ethcss'
import { defaultThemeStyles } from '../AppMenu.theme'
import { IStyles, grid, item, indents } from 'theme'

export const styles: IStyles = {
    subMenuItem__content_type_title: {
        padding: indents.miniPlus,
        paddingBottom: indents.md,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
        borderBottom: `1px solid var(--var-module-app-component-menu-style-border, ${defaultThemeStyles.border})`,
        '& .menuIcon': {
            ...item.transitionErase,
        },
        color: `var(--var-module-app-component-menu-style-textMain, ${defaultThemeStyles.textMain})`,
        '&:hover': {
            color: `var(--var-module-app-component-menu-style-hover, ${defaultThemeStyles.hover})`,
            '& .menuIcon': {
                color: `var(--var-module-app-component-menu-style-hover, ${defaultThemeStyles.hover})!important`,
            },
        },
        textTransform: 'uppercase',
        cursor: 'pointer',
    },
    subMenuItem__content_type_active: {
        color: `var(--var-module-app-component-menu-style-active, ${defaultThemeStyles.active})!important`,
    },
    subMenuItem__content_type_default: {
        cursor: 'default!important',
    },
    subMenuItem__content_x: {
        padding: '10px',
        width: '100%',
        boxSizing: 'content-box',
    },
}

addStyles(styles)
