import { addStyles } from 'ethcss'
import { item, IStyles } from 'theme'

export const labelStyles: IStyles = {
    wrapper: {
        ...item.overNone,
    },
}

addStyles(labelStyles)
