import { useEffect, useState } from 'react'
import moment from 'moment/moment'
import helpers from 'core/helpers'
import scheduleHelpers from 'core/helpers/_schedule'
import { ISchedule, TScheduelRepeatMode } from '../../../core/models/Schedule'

interface IState {
    days: string[]
    daysBetween: number[]
    advCount: number
    allAdvCount: number | null
    onAllDay: boolean
    dayDuration?: number
    hourDuration?: number
    minuteDuration?: number
}
export interface ISettingsProps {
    schedule: ISchedule
    onChangeSchedule: (schedule: ISchedule) => void
    onChangeDeferredDownload: (date: string, type: string) => void
    deferredDownload: { date: string | null; time: string | null }
    advDuration: {} | number
    advContentLength: null | number
}

let prevInterval:
    | {
          startTime: string
          endTime: string
          repeatMode: string
      }
    | {} = {}
let prevSchedule: ISchedule | {} = {}

const setStartEndOf = (obj: any = {}) => {
    obj.startTime = scheduleHelpers.startOf()
    obj.endTime = scheduleHelpers.endOf()

    return obj
}

const AddScheduleSettingsMethods = (p_: ISettingsProps) => {
    const [state, setState] = useState<IState>({
        days: helpers.getDays(),
        daysBetween: [],
        advCount: 0,
        allAdvCount: 0,
        onAllDay: false,
    })

    const setStartEndTime = (onAllDay: boolean | void, p_: ISettingsProps) => {
        if (onAllDay) {
            p_.onChangeSchedule({
                ...p_.schedule,
                ...setStartEndOf(),
            })
        }
    }
    const roundTime = (p_: ISettingsProps) => {
        const translateTime = 30 * 60 * 1000
        const duration = +p_.advDuration
        let roundTime = moment()

        if (roundTime.second() > 30) {
            roundTime =
                roundTime.second() || roundTime.millisecond()
                    ? roundTime.add(1, 'minute').startOf('minute')
                    : roundTime.startOf('minute')
        } else {
            roundTime = roundTime.startOf('minute')
        }

        p_.onChangeSchedule({
            ...p_.schedule,
            startTime: moment(roundTime).add(translateTime).format('HH:mm:ss'),
            endTime: moment(roundTime)
                .add(translateTime + duration)
                .format('HH:mm:ss'),
            dayDuration: 0,
            hourDuration: 0,
            minuteDuration: 0,
        })
    }
    const compareTimes = (start: string, end: string, repeatMode: string) => {
        const { startTime, endTime }: { startTime: string; endTime: string } = setStartEndOf()
        const compareResult = startTime === start && endTime === end

        setState((prevState) => {
            return {
                ...prevState,
                onAllDay: compareResult,
            }
        })
    }
    const calcDaysBetween = (p_: ISettingsProps) => {
        const startDate = p_.schedule.startDate
        const endDate = p_.schedule.endDate
        const min = moment(startDate)
        const max = moment(endDate)
        const daysBetween: number[] = []

        for (min; min.isBefore(max); min.add(1, 'days')) {
            let day = min.day()

            if (day === 0) {
                day = 7
            }
            daysBetween.push(day)
        }

        setState((prevState) => {
            return {
                ...prevState,
                daysBetween,
            }
        })
    }
    useEffect(() => {
        const { schedule } = p_
        const { startTime, endTime, repeatMode } = schedule

        compareTimes(startTime, endTime, repeatMode)

        if (p_.advContentLength) {
            calcStat(p_)
        }
    }, [])
    useEffect(() => {
        const nextInterval = {
            startTime: p_.schedule.startTime,
            endTime: p_.schedule.endTime,
            repeatMode: p_.schedule.repeatMode,
        }
        if (JSON.stringify(prevInterval) !== JSON.stringify(nextInterval)) {
            setStartEndTime(compareTimes(p_.schedule.startTime, p_.schedule.endTime, p_.schedule.repeatMode), p_)
        }

        if (JSON.stringify(prevSchedule) !== JSON.stringify(p_.schedule)) {
            if (p_.schedule.repeatMode === 'weekly') {
                calcDaysBetween(p_)
            }
            if (p_.advContentLength) {
                calcStat(p_)
            }
        }

        prevInterval = {
            startTime: p_.schedule.startTime,
            endTime: p_.schedule.endTime,
            repeatMode: p_.schedule.repeatMode,
        }
        prevSchedule = p_.schedule
    }, [p_])

    const calcStat = (p_: ISettingsProps) => {
        let startDate: moment.Moment | string = moment(p_.schedule.startDate).format('DD.MM.YYYY')
        let endDate: moment.Moment | string = moment(p_.schedule.endDate).format('DD.MM.YYYY')
        startDate = moment(`${startDate} ${p_.schedule.startTime}`, 'DD.MM.YYYY HH:mm:ss')
        endDate = moment(`${endDate} ${p_.schedule.endTime}`, 'DD.MM.YYYY HH:mm:ss')
        const startTime = moment(p_.schedule.startTime, 'HH:mm:ss')
        const endTime = moment(p_.schedule.endTime, 'HH:mm:ss')
        const oneDuration = endTime.unix() - startTime.unix()
        let repeatCount = 0

        if (p_.schedule.repeatMode === 'once') {
            repeatCount = 1
        } else {
            for (let m = startDate; m.isBefore(endDate); m.add(1, 'days')) {
                if (p_.schedule.repeatMode === 'daily') {
                    repeatCount++
                } else if (p_.schedule.repeatMode === 'weekly') {
                    let day = m.day()

                    if (day === 0) {
                        day = 7
                    }

                    if (p_.schedule.repeatDays.indexOf(day) !== -1) {
                        repeatCount++
                    }
                }
            }
        }

        const duration = +p_.advDuration / 1000
        const advCount = Math.round((oneDuration * repeatCount) / duration)
        const startInterval = moment()
        const endInterval = moment().add(oneDuration * repeatCount, 'seconds')
        const dayDuration = endInterval.diff(startInterval, 'days')
        const hourDuration = endInterval.diff(startInterval, 'hours') - 24 * dayDuration
        const minuteDuration = endInterval.diff(startInterval, 'minutes') - 24 * dayDuration * 60 - hourDuration * 60

        setState((prevState) => {
            return {
                ...prevState,
                advCount,
                dayDuration,
                hourDuration,
                minuteDuration,
                allAdvCount: p_.advContentLength ? advCount * p_.advContentLength : null,
            }
        })
    }
    const onChangeStartDate = (startDate: string) => {
        const data = { ...p_.schedule }

        data.startDate = startDate

        if (data.repeatMode === 'once') {
            data.endDate = startDate
        }

        p_.onChangeSchedule(data)
    }
    const onChangeDays = (selected: boolean, dayIndex: number) => {
        const data = { ...p_.schedule }

        if (selected) {
            data.repeatDays = [...data.repeatDays, dayIndex]
        } else {
            data.repeatDays = data.repeatDays.filter((day) => day !== dayIndex)
        }

        p_.onChangeSchedule(data)
    }
    const handleChangeSpecificDays = (days: string[]) => {
        const { schedule, onChangeSchedule } = p_
        const data = { ...schedule, repeatDays: days }

        onChangeSchedule(data)
    }
    const onRepeatChange = (selected: { id: TScheduelRepeatMode }) => {
        const data = { ...p_.schedule }

        if (selected.id === 'forever') {
            data.endDate = ''
            setStartEndOf(data)

            setState((prevState) => {
                return {
                    ...prevState,
                    onAllDay: false,
                }
            })
        } else {
            data.endDate = data.startDate
            data.repeatDays = []
        }
        data.repeatMode = selected.id

        p_.onChangeSchedule(data)
    }

    return {
        state,
        setState,
        onChangeStartDate,
        onRepeatChange,
        onChangeDays,
        handleChangeSpecificDays,
        roundTime,
        setStartEndTime,
    }
}

export default AddScheduleSettingsMethods
