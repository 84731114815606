import React, { useState } from 'react'
import { connect } from 'react-redux'
import DisplaysToolbarContent, { MenuItem } from '../__toolbarContent/displays__toolbarContent'
import Sidebar from 'blocks.app/sidebar/sidebar'
import TagsList from 'blocks.app/tagsList/tagsList'
import ModalAccept from 'blocks.simple/modal/_accept/modal_accept'
import Icon from 'blocks.simple/icon/icon'
import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'
import helpersDigitalSignages from 'core/helpers/_digitalSignages'
import translate from 'core/translate'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { colors, text as textStyles } from 'theme'
import styles from './styles.jcss'
import { isNotEmptyArray } from 'core/utils/coreUtil'
import { displaysActions } from '../displays.state'
import { IDevice } from '../../../core/models/DigitalSignage'
import { Typography } from '../../../atoms/Typography'

interface IData {
    sourceId: number
    groupId: number | null
    folderId: number
    overwriteSettings: any
    groupScheduleMode: string
    newFolderId: number
}
type SelectedItem = { type: string; id: number; status: string }
interface IDisplaysToolbarProps {
    onChange: (data: any) => void
    selectedInfo: any
    selectedItems: SelectedItem[]
    onCloseInfo: () => void
    openModal: (data: {
        modalState: string
        modalOptions: {
            devices: any[]
        }
    }) => void
    tags: any
    withoutAddress: string[]
    onGetFoldersOnMultiple: () => void
    downloadLink: string
    groupId: number | null
    groups: any
    onSelectInfo: any
    query: any
}

const getDevicesFromSelected = (selected: any) =>
    selected.filter((selectedItem: any) => !helpersDigitalSignages.isFolder(selectedItem))

const DisplaysToolbar = (p_: IDisplaysToolbarProps) => {
    const [showWarningOnMultipleChangeSettings, setShowWarningOnMultipleChangeSettings] = useState<boolean>(false)

    const onCloseWarning = () => {
        setShowWarningOnMultipleChangeSettings(false)
    }
    const onAcceptWarning = () => {
        const { openModal } = p_
        const devices = getDevicesFromSelected(p_.selectedItems)

        onCloseWarning()

        openModal({
            modalState: 'displaySettings',
            modalOptions: {
                devices,
            },
        })
    }

    const updateDsTag = (data: { id: number; name: string }) => api.send('updateDsTag', data)
    const deleteDsTag = (data: { id: number; name: string }) => api.send('deleteDsTag', data)
    const createDsTag = (data: { id: number; name: string }) => api.send('createDsTag', data)

    const getCustomContent = () => {
        if (p_.selectedInfo || p_.withoutAddress.length) {
            // @ts-ignore
            return <DisplaysToolbarContent {...p_} />
        }

        if (p_.selectedItems.length) {
            return null
        }

        return (
            <TagsList
                title={translate('tags')}
                tags={p_.tags}
                onUpdate={updateDsTag}
                onDelete={deleteDsTag}
                onCreate={createDsTag}
                onlyRead={
                    !helpers.isAvailableAction(null, {
                        key: 'digitalSignage',
                        action: 'update',
                        subaction: 'manageTags',
                    })
                }
            />
        )
    }

    const isShowAdditionContent = (selectedItems: SelectedItem[]) => {
        let isShowAdditionContent = false

        if (!selectedItems || !isNotEmptyArray(selectedItems)) return isShowAdditionContent

        selectedItems.forEach((item) => {
            if (item.type === 'digitalSignage') {
                isShowAdditionContent = true
            }
        })

        return isShowAdditionContent
    }

    const deleteDisplayGroups = () => {
        let idDisplays: number[] = []
        let idGroups: number[] = []

        p_.selectedItems.forEach((item) => {
            if (item.type === 'group') {
                idGroups.push(item.id)
            } else {
                idDisplays.push(item.id)
            }
        })

        Promise.all([api.send('deleteGroup', { id: idGroups }), api.send('deleteDisplay', { id: idDisplays })]).then(
            () => {
                p_.onChange([])
            }
        )
    }

    const editDisplayGroups = (data: IData) => {
        Promise.all([
            api.send('editDisplay', {
                id: data.sourceId,
                groupId: data.newFolderId,
                overwriteSettings: data.overwriteSettings,
                groupScheduleMode: data.groupScheduleMode,
            }),
            api.send('editGroup', {
                id: data.folderId,
                parentId: data.newFolderId,
            }),
        ]).then(() => {
            p_.onChange([])
        })
    }

    const getSelectInfoStatus = (selectedInfo: SelectedItem) => {
        return selectedInfo ? selectedInfo.status : null
    }

    const isErrorStatus = (selectedInfo: SelectedItem) => {
        return getSelectInfoStatus(selectedInfo) === 'error'
    }

    const onlineSelectedItems = p_.selectedItems.filter(
        (selectedItem) => selectedItem.status === 'online' || selectedItem.status === 'online_display_off'
    )
    const devices: IDevice[] = getDevicesFromSelected(p_.selectedItems)
    const isErrorCode = p_.selectedInfo && p_.selectedInfo.errorCode
    const _isErrorStatus = isErrorStatus(p_.selectedInfo)
    const allLicenseIsActive = () => {
        if (!devices.length) return false

        return devices.every((device) => device.activeQ)
    }

    return (
        <Sidebar
            selectedSide="right"
            onSelectInfo={p_.onSelectInfo}
            onChange={(list: {}[] | []) => p_.onChange(list)}
            onGetFoldersOnMultiple={p_.onGetFoldersOnMultiple}
            icon={{
                right: {
                    name: isErrorCode && _isErrorStatus ? 'attention' : null,
                    color: isErrorCode && _isErrorStatus ? 'red' : null,
                },
            }}
            customHeader={
                <div className={styles.sidebarHeader}>
                    <div className={styles.sidebarHeaderCaption}>
                        <Typography type={'title'} className={styles.captionText}>
                            {translate('devicesStatuses')}
                        </Typography>
                        <Icon
                            containerClass={cn('statusesArrow', styles.dropdownArrow)}
                            type={'infoFill'}
                            size={15}
                            color={colors.blueAccent}
                        />
                    </div>
                    <div className={styles.sidebarHeaderDropdownContent}>
                        <div className={styles.sidebarHeaderStatus}>
                            <Icon type={'displayIcon'} size={36} color={colors.online} />
                            <div className={styles.sidebarHeaderStatusInfo}>
                                <div className={styles.sidebarHeaderStatusInfoCaption}>{translate('online')}</div>
                                <div className={styles.sidebarHeaderStatusInfoDescription}>
                                    {translate('deviceIsOnline')}
                                </div>
                            </div>
                        </div>
                        <div className={styles.sidebarHeaderStatus}>
                            <Icon type={'displayIcon'} size={36} color={colors.displayOff} />
                            <div className={styles.sidebarHeaderStatusInfo}>
                                <div className={styles.sidebarHeaderStatusInfoCaption}>{translate('displayOff')}</div>
                                <div className={styles.sidebarHeaderStatusInfoDescription}>
                                    {translate('deviceIsOnlineDisplayOff')}
                                </div>
                            </div>
                        </div>
                        <div className={styles.sidebarHeaderStatus}>
                            <Icon type={'displayIcon'} size={36} color={colors.offline} />
                            <div className={styles.sidebarHeaderStatusInfo}>
                                <div className={styles.sidebarHeaderStatusInfoCaption}>{translate('offline')}</div>
                                <div className={styles.sidebarHeaderStatusInfoDescription}>
                                    {translate('deviceIsOffline')}
                                </div>
                            </div>
                        </div>
                        <div className={styles.sidebarHeaderStatus}>
                            <Icon type={'displayIcon'} size={36} color={colors.warning} />
                            <div className={styles.sidebarHeaderStatusInfo}>
                                <div className={styles.sidebarHeaderStatusInfoCaption}>{translate('online')}</div>
                                <div className={styles.sidebarHeaderStatusInfoDescription}>
                                    {translate('deviceIsUpdating')}
                                </div>
                            </div>
                        </div>
                        <div className={styles.sidebarHeaderStatus}>
                            <Icon type={'displayIcon'} size={36} color={colors.error} />
                            <div className={styles.sidebarHeaderStatusInfo}>
                                <div className={styles.sidebarHeaderStatusInfoCaption}>{translate('error')}</div>
                                <div className={styles.sidebarHeaderStatusInfoDescription}>
                                    {translate('deviceHaveError')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            bottomContent={{
                right: (
                    <div>
                        {helpers.isAvailableAction(null, {
                            key: 'digitalSignage',
                            action: 'update',
                            subaction: 'reboot',
                        }) &&
                            allLicenseIsActive() && (
                                <MenuItem
                                    data={{ name: 'reboot_device' }}
                                    selectedItems={onlineSelectedItems}
                                    rebootDeviceSelectedItems={p_.selectedItems}
                                    afterAction={function () {
                                        p_.onChange([])
                                    }}
                                />
                            )}
                        {helpers.isAvailableAction(null, {
                            key: 'digitalSignage',
                            action: 'update',
                            subaction: 'restart',
                        }) &&
                            allLicenseIsActive() && (
                                <MenuItem
                                    data={{ name: 'restart_application' }}
                                    selectedItems={onlineSelectedItems}
                                    afterAction={function () {
                                        p_.onChange([])
                                    }}
                                />
                            )}
                        {helpers.isAvailableAction(null, {
                            key: 'digitalSignage',
                            action: 'update',
                            subaction: 'shutdown',
                        }) &&
                            allLicenseIsActive() && (
                                <MenuItem
                                    data={{ name: 'switchOff' }}
                                    dropdown={{
                                        id: 'switchOff',
                                        text: translate('youAreSureSwitchOffDevices'),
                                    }}
                                    afterAction={function () {
                                        p_.onChange([])
                                    }}
                                    selectedItems={onlineSelectedItems}
                                />
                            )}
                        {helpers.isAvailableAction(null, {
                            key: 'digitalSignage',
                            action: 'update',
                            subaction: 'updateScreenshot',
                        }) &&
                            allLicenseIsActive() && (
                                <MenuItem
                                    data={{ name: 'screenshot' }}
                                    selectedItems={onlineSelectedItems}
                                    beforeAction={function () {
                                        p_.onChange([])
                                    }}
                                />
                            )}
                        {helpers.isAvailableAction(null, {
                            key: 'digitalSignage',
                            action: 'update',
                            subaction: 'changeDateTime',
                        }) &&
                            allLicenseIsActive() && (
                                <MenuItem data={{ name: 'ds_date' }} selectedItems={onlineSelectedItems} />
                            )}
                        {helpers.isAvailableAction(null, {
                            key: 'digitalSignage',
                            action: 'update',
                            subaction: 'identification_on',
                        }) &&
                            allLicenseIsActive() && (
                                <MenuItem data={{ name: 'identification_on' }} selectedItems={p_.selectedItems} />
                            )}
                        {helpers.isAvailableAction(null, {
                            key: 'digitalSignage',
                            action: 'update',
                            subaction: 'identification_off',
                        }) &&
                            allLicenseIsActive() && (
                                <MenuItem data={{ name: 'identification_off' }} selectedItems={p_.selectedItems} />
                            )}
                        {helpers.isAvailableAction(null, {
                            key: 'digitalSignage',
                            action: 'update',
                            subaction: 'downloadDeviceInfo',
                        }) &&
                            allLicenseIsActive() && (
                                <a
                                    href={p_.downloadLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={cn(grid.fullWidth, item.block, textStyles.noUnderline)}
                                >
                                    <MenuItem
                                        data={{ name: 'downloadDevices' }}
                                        selectedItems={p_.selectedItems}
                                        afterAction={function () {
                                            p_.onChange([])
                                        }}
                                    />
                                </a>
                            )}
                        {helpers.isAvailableAction(null, {
                            key: 'digitalSignage',
                            action: 'update',
                            subaction: 'changeSettings',
                        }) &&
                            allLicenseIsActive() && (
                                <MenuItem
                                    data={{ name: 'generalSettings' }}
                                    selectedItems={devices}
                                    onClick={() => {
                                        setShowWarningOnMultipleChangeSettings(true)
                                    }}
                                />
                            )}
                        <MenuItem
                            data={{ name: 'deselect' }}
                            onClick={function () {
                                p_.onChange([])
                            }}
                        />

                        <ModalAccept
                            open={showWarningOnMultipleChangeSettings}
                            onReject={onCloseWarning}
                            onClose={onCloseWarning}
                            onAccept={onAcceptWarning}
                            title={
                                <div className={styles.warningTitleWrapper}>
                                    <Icon type="attention" color="darkCloud" size={24} />
                                    <div className={styles.warningTitle}>{translate('attention')}!</div>
                                </div>
                            }
                            rejectLabel={translate('cancel')}
                            acceptLabel={translate('continue')}
                        >
                            <div className={styles.warningDescriptionWrapper}>
                                {translate('multipleChangeSettingsDescription')}{' '}
                                <span className={styles.warningDescription}>{translate('removed')}</span>.
                            </div>
                        </ModalAccept>
                    </div>
                ),
            }}
            customContent={{
                right: getCustomContent(),
            }}
            content={{
                right:
                    !p_.selectedInfo && p_.selectedItems.length
                        ? {
                              title: translate('selectedDevices'),
                              options: p_.selectedItems,
                              folders: p_.groups,
                              editTitle: translate('parentFolder'),
                              folderId: p_.groupId,
                              isDirectory: (option: { type: string }) => option.type === 'group',
                              additionContent: isShowAdditionContent(p_.selectedItems)
                                  ? [
                                        {
                                            title: translate('applyGroupSettings'),
                                            name: 'overwriteSettings',
                                            type: 'checkbox',
                                            initValue: false,
                                        },
                                        {
                                            title: translate('scheduleForDevice'),
                                            name: 'groupScheduleMode',
                                            type: 'radio',
                                            options: [
                                                {
                                                    label: translate('changeCurrentSchedule'),
                                                    value: 'apply',
                                                },
                                                {
                                                    label: translate('remainCurrentSchedule'),
                                                    value: 'ignore',
                                                },
                                            ],
                                            initValue: 'ignore',
                                        },
                                    ]
                                  : null,
                          }
                        : null,
            }}
            isEmpty={{
                right: false,
            }}
            isShowActions
            isEditable={
                !helpers.isSomeNotHaveAction(p_.selectedItems, 'update') &&
                helpers.isAvailableAction(null, {
                    key: 'digitalSignage',
                    action: 'update',
                    subaction: 'generalEditing',
                })
            }
            onSubmit={(side: string, type: string, data: IData) => {
                if (side === 'right' && type === 'delete') {
                    deleteDisplayGroups()
                }

                if (side === 'right' && type === 'edit') {
                    editDisplayGroups(data)
                }
            }}
        />
    )
}

const mapStateToProps = (state: any) => ({
    tags: state.displays.tags,
    withoutAddress: state.displays.withoutAddress || [],
    toolbar: state.displays.toolbar,
    broadcasts: state.displays.broadcasts || [],
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        openModal: (modalOptions: any) => dispatch(displaysActions.openModal(modalOptions)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplaysToolbar)
