import React from 'react'
import Modal from 'blocks.simple/modal/modal'
import Icon from 'blocks.simple/icon/icon'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import EditText from 'blocks.simple/editText/editText'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import Button from 'blocks.simple/button/button'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import HistoryFilterMethods from './history__filter.local.methods'
import { getFilterConfing } from './history__filter.config'
import { cn } from 'ethcss'
import styles from './history__filter.jcss'
import { text as textStyles, item, grid } from 'theme'
import translate from 'core/translate'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'
import { Typography } from 'atoms/Typography'
import { isEmpty } from 'lodash'

const HistoryFilter = (p_: any) => {
    const isFilterList = !isEmpty(p_.selected)

    const {
        openFilter,
        isFilterOpen,
        selectFilter,
        selectedFilter,
        _filterSearch,
        filterSearch,
        checkAll,
        filterFields,
        toggleField,
        startDate,
        endDate,
        closeFilter,
        onFilterChange,
        setStartDate,
        setEndDate,
    } = HistoryFilterMethods(p_)

    return (
        <div className={styles.filter}>
            <Button
                mod="withShadow"
                icon="filter"
                iconPos="left"
                iconColor={
                    isFilterOpen || isFilterList
                        ? getThemeStyleValue('app', 'app', 'active') || defaultThemeStyles.active
                        : getThemeStyleValue('app', 'app', 'disable') || defaultThemeStyles.disable
                }
                indentSize="normal"
                onClick={openFilter}
                fullWidth={true}
            >
                <span
                    style={{
                        color:
                            isFilterOpen || isFilterList
                                ? getThemeStyleValue('app', 'app', 'active') || defaultThemeStyles.active
                                : getThemeStyleValue('app', 'app', 'disable') || defaultThemeStyles.disable,
                    }}
                >
                    {translate('filter')}
                </span>
            </Button>
            {/*{isFilterList && (*/}
            {/*    <div className={cn(grid.fullWidth, grid.row, grid.center, item.abs)}>*/}
            {/*        <Icon type="arrowhead_up" color="white" size="40" />*/}
            {/*    </div>*/}
            {/*)}*/}
            <div className={item.abs}>
                <Modal open={isFilterOpen} showCloseIcon={false} mod={'clear'} transitionName={'fade'}>
                    <div className={styles.modalWrapper}>
                        <div className={styles.modalInner}>
                            <Rectangle className={cn(grid.col)}>
                                <div className={cn(textStyles.mini, grid.col, grid.space, styles.mobileScroll)}>
                                    <div className={cn(grid.rowCenter)}>
                                        <div className={cn(grid.rowCenter, textStyles.normal)}>
                                            <Icon type="filter" className={grid.mr_micro} />
                                            <Typography type="title">{translate('filter')}</Typography>
                                        </div>
                                    </div>
                                    <div className={grid.p_mini}>
                                        <Dropdown
                                            placeholder={translate('filter')}
                                            options={getFilterConfing().filtersBy}
                                            onChange={selectFilter}
                                            value={selectedFilter ? selectedFilter.id : null}
                                        />
                                        {!selectedFilter && (
                                            <Typography type="text" className={cn(grid.pt_micro, textStyles.center)}>
                                                {translate('searchHistoryFilter')}
                                            </Typography>
                                        )}
                                    </div>
                                    {selectedFilter && selectedFilter.id !== 'date' && (
                                        <div className={cn(grid.col, grid.space, styles.mobileScroll)}>
                                            <div className={styles.fieldWrapper}>
                                                <EditText
                                                    icon={'search'}
                                                    value={_filterSearch}
                                                    onChange={filterSearch}
                                                />
                                            </div>
                                            <div className={cn(styles.fieldWrapper, grid.row, grid.justify)}>
                                                <div />
                                                <div className={grid.row}>
                                                    <div
                                                        className={cn(item.pointer, textStyles.underline, grid.mr_mini)}
                                                        onClick={() => checkAll(true)}
                                                    >
                                                        {translate('selectAll')}
                                                    </div>
                                                    <div
                                                        className={cn(item.pointer, textStyles.underline)}
                                                        onClick={() => checkAll(false)}
                                                    >
                                                        {translate('clear')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={cn(styles.filterFields)}>
                                                {filterFields
                                                    .filter((field: any) => {
                                                        return (
                                                            field.name
                                                                .toLowerCase()
                                                                .indexOf(_filterSearch.toLowerCase()) !== -1
                                                        )
                                                    })
                                                    .map((field, index) => (
                                                        <div key={`checkItem_${index}`} className={styles.checkItem}>
                                                            <Checkbox
                                                                onClick={() => toggleField(field.index)}
                                                                checked={field.checked}
                                                                label={field.name}
                                                            />
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    )}
                                    {selectedFilter && selectedFilter.id === 'date' && (
                                        <div className={cn(grid.col, grid.space, grid.p_mini)}>
                                            <Checkbox
                                                checked={p_.byStartDateChecked}
                                                onClick={() => p_.setByStartDateChecked(!p_.byStartDateChecked)}
                                                label={translate('byStartDate')}
                                            />
                                            {p_.byStartDateChecked && (
                                                <div>
                                                    <EditText
                                                        containerClass={cn(grid.mt_md)}
                                                        type={'date'}
                                                        label={translate('startDate')}
                                                        dateValue={startDate}
                                                        onChange={(startDate: any) => setStartDate(startDate)}
                                                    />
                                                </div>
                                            )}
                                            <Checkbox
                                                checked={p_.byEndDateChecked}
                                                onClick={() => p_.setByEndDateChecked(!p_.byEndDateChecked)}
                                                label={translate('byEndDate')}
                                                wrapperClassName={cn(grid.mt_md, grid.mb_md)}
                                            />
                                            {p_.byEndDateChecked && (
                                                <div>
                                                    <EditText
                                                        type={'date'}
                                                        label={translate('endDate')}
                                                        dateValue={endDate}
                                                        onChange={(endDate: any) => setEndDate(endDate)}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className={styles.buttons}>
                                    <div />
                                    <div className={grid.row}>
                                        <Button
                                            className={cn(grid.mr_mini)}
                                            onClick={closeFilter}
                                            textSize={'mini'}
                                            indentSize={'mini'}
                                            rounded={'full_normal'}
                                        >
                                            {translate('cancel')}
                                        </Button>
                                        <Button
                                            mod={'fill'}
                                            textSize={'mini'}
                                            indentSize={'mini'}
                                            rounded={'full_normal'}
                                            onClick={onFilterChange}
                                            disabled={!selectedFilter}
                                        >
                                            {translate('apply')}
                                        </Button>
                                    </div>
                                </div>
                            </Rectangle>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default HistoryFilter
