import React, { useEffect, useState } from 'react'
import Button from 'blocks.simple/button/button'
import EditText from 'blocks.simple/editText/editText'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import helpers from 'core/helpers'
import moment from 'moment'
import { api } from 'core/api/ConnectionManager'
import broadcastsHelpers from 'core/helpers/_broadcasts'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { text as textStyles } from 'theme'
import { grid } from 'theme'

type Item = {
    startDate: string
    endDate: string
    startTime: string
    endTime: string
    repeatMode: string
    repeatDays: any
    localStartTime: string
    localEndTime: string
}

interface IState {
    active: object[]
    selected: number | null
    schedule: {
        timeTitle: string
        id: number
        broadcastTitle: string
        view_: boolean
    }[]
    data: any
    folders: { id: number }[]
    showMoreRestrictSupport: boolean
    unsupportedPlatforms:
        | {
              platformName: string
          }[]
        | null
}

const getLabel = (item: Item) => {
    const startDate = helpers.getLocalDate({ date: item.startDate, time: item.startTime })?.format()
    const endDate = helpers.getLocalDate({ date: item.endDate, time: item.endTime })
    // const timeTitle = `${moment(startDate).format('HH:mm')} - ${endDate ? moment(endDate).format('HH:mm') : '23:59'}`
    const timeTitle = item.localStartTime + ' - ' + item.localEndTime
    const broadcastTitle = `${moment(startDate).format('DD/MM/YYYY')} - ${
        endDate ? moment(endDate).format('DD/MM/YYYY') : translate('forever')
    }`

    return { timeTitle, broadcastTitle }
}

const getUnsupportedPlatforms = (p_: {
    selectedInfo: {
        instanceType: string
        unsupportedPlatforms: { platformName: string }[]
    }
}) => {
    const isDirectory = broadcastsHelpers.isDirectory(p_.selectedInfo)
    const unsupportedPlatforms = p_.selectedInfo.unsupportedPlatforms

    if (!isDirectory && unsupportedPlatforms && unsupportedPlatforms.length) {
        return unsupportedPlatforms
    }

    return null
}

const getItemData = (p_: {
    selectedInfo: {
        id: number
        folderId: string
        title: string
    }
}) => {
    let data = {}

    if (p_) {
        data = {
            id: p_.selectedInfo.id,
            folderId: p_.selectedInfo.folderId,
            title: p_.selectedInfo.title,
        }
    }

    return data
}

function formattingSchedule(item: Item[]) {
    let data: any = []

    if (!item) {
        return data
    }

    item.forEach((item) => {
        if (item.repeatMode === 'exact_dates') {
            item.repeatDays.forEach((day: any, index: number) => {
                data.push({ ...item, ...getLabel(day), view_: index })
            })
        } else {
            data.push({ ...item, ...getLabel(item) })
        }
    })
    return data
}

function getFoldersList(p_: {
    selectedInfo: {
        id: number
        instanceType: string
    }
    folders: { id: number }[]
}) {
    let folders = p_.folders
    if (p_ && broadcastsHelpers.isDirectory(p_.selectedInfo)) {
        folders = folders.filter((folder) => folder.id !== p_.selectedInfo.id)
    }

    return folders
}

const BroadcastsToolbarContentMethods = (p_: any) => {
    const [state, setState] = useState<IState>({
        active: [],
        selected: null,
        schedule: [],
        data: getItemData(p_),
        folders: getFoldersList(p_),
        showMoreRestrictSupport: false,
        unsupportedPlatforms: null,
    })

    useEffect(() => {
        if (p_.selectedInfo) {
            const data = {
                data: getItemData(p_),
                folders: getFoldersList(p_),
                schedule: formattingSchedule(p_.selectedInfo.schedule),
                unsupportedPlatforms: getUnsupportedPlatforms(p_),
            }

            setState((prevState) => {
                return { ...prevState, ...data }
            })
        }
    }, [p_.selectedInfo])

    const downloadBroadcast = (broadcast: { id: number }) => {
        if (!broadcast) return

        const tasks: { id: number; type: string }[] = p_.downloads.delayDownloadTasks
        const task = tasks.find((task) => task.id === broadcast.id && task.type === 'broadcast')

        if (!task) {
            api.send('createBroadcastExportTask', { broadcastId: broadcast.id }).then((res: any) => {
                p_.addDelayDownloadTask({
                    id: broadcast.id,
                    type: 'broadcast',
                    exportId: res.exportId,
                })
            })
        }

        p_.onOpenInfo()
    }
    const deleteBroadcast = (id: number, isDirectory: boolean) => {
        if (!isDirectory) {
            api.send('deleteBroadcast', { id })
        } else {
            api.send('deleteBroadcastFolder', { id })
        }
    }
    const getItems = (p_: { selectedInfo: { id: number; folderId: string; instanceType: string } }) => {
        const s_ = state
        const isDirectory = broadcastsHelpers.isDirectory(p_.selectedInfo)
        const isAvailableActionIcon = !!p_.selectedInfo.folderId
        let items = []
        const broadcastItems = [
            {
                id: 'download',
                icon: 'download',
                tooltip: {
                    title: translate('download'),
                },
                show: isAvailableActionIcon && helpers.isAvailable({ key: 'broadcast', action: 'read' }),
            },
            {
                id: 'clone',
                icon: 'copy',
                tooltip: {
                    title: translate('clone'),
                },
                show: isAvailableActionIcon && helpers.isAvailable({ key: 'broadcast', action: 'create' }),
            },
            {
                id: 'date',
                icon: 'date',
                tooltip: {
                    title: translate('schedule'),
                },
                show: helpers.isAvailable({ key: 'schedule', action: 'read' }),
            },
            {
                id: 'edit',
                icon: 'pencil',
                tooltip: {
                    title: translate('edit'),
                },
                show: helpers.isAvailableAction(p_.selectedInfo, { key: 'broadcast', action: 'update' }),
            },
        ]
        const deleteItem = {
            id: 'delete',
            icon: 'delete',
            tooltip: {
                title: translate('delete'),
            },
            show: helpers.isAvailableAction(p_.selectedInfo, { key: 'broadcast', action: 'delete' }),
            children: (
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        setState((prevState) => {
                            return { ...prevState, active: [] }
                        })
                        deleteBroadcast(p_.selectedInfo.id, isDirectory)
                    }}
                    className={cn(grid.colCenter, grid.p_md)}
                >
                    <div className={cn(grid.w100, grid.mb_md, textStyles.center)}>
                        {translate(isDirectory ? 'advFolderDeleteWarning' : 'broadcastDeleteWarning')}?
                    </div>
                    <div className={cn(grid.row, grid.justify, grid.mb_mini)}>
                        <Button indentSize="mini" type="submit" className={cn(grid.mr_mini)} mod="fill">
                            {translate('yes')}
                        </Button>
                        <Button
                            indentSize="mini"
                            onClick={() =>
                                setState((prevState) => {
                                    return { ...prevState, active: [] }
                                })
                            }
                        >
                            {translate('no')}
                        </Button>
                    </div>
                </form>
            ),
        }
        const folderEditItem = {
            id: 'edit',
            show: helpers.isAvailableAction(p_.selectedInfo, { key: 'broadcast', action: 'update' }),
            icon: 'pencil',
            tooltip: {
                title: translate('edit'),
            },
            children: (
                <form
                    onSubmit={(e) => {
                        e.preventDefault()

                        setState((prevState) => {
                            return { ...prevState, active: [] }
                        })
                        api.send('updateBroadcastFolder', s_.data)
                    }}
                    className={cn(grid.colCenter, grid.p_md)}
                >
                    <div>
                        <div className={cn(grid.w100, grid.mb_md)}>
                            <div className={cn(grid.mb_mini, textStyles.up)}>{translate('folderName')}</div>
                            <div className={grid.w100}>
                                <EditText
                                    mod={'withBorder'}
                                    value={s_.data.title}
                                    onChange={(title: string) =>
                                        setState((prevState) => {
                                            return { ...prevState, data: { ...s_.data, title } }
                                        })
                                    }
                                    className={cn(textStyles.center, textStyles.normal)}
                                />
                            </div>
                        </div>
                        <div className={cn(grid.w100, grid.mb_mini)}>
                            <div className={cn(grid.mb_mini, textStyles.up)}>{translate('parentFolder')}</div>
                            <div className={grid.w100}>
                                <Dropdown
                                    value={s_.data.folderId}
                                    options={s_.folders}
                                    centered
                                    searchable
                                    type="tree"
                                    placeholder={translate('chooseFolder')}
                                    onChange={(selected: { id: number }) =>
                                        setState((prevState) => {
                                            return { ...prevState, data: { ...s_.data, folderId: selected.id } }
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className={grid.colCenter}>
                            <Button type="submit" mod="fill">
                                {translate('save')}
                            </Button>
                        </div>
                    </div>
                </form>
            ),
        }

        if (isDirectory) {
            items = [folderEditItem, deleteItem]
        } else {
            items = [...broadcastItems, deleteItem]
        }

        return items
    }

    return {
        state,
        setState,
        downloadBroadcast,
        getItems,
    }
}

export default BroadcastsToolbarContentMethods
