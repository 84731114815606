import React, { FC, memo, useContext } from 'react'
import { ICatalogWithPaginationActionBarProps } from 'organisms/CatalogWithPagination/CatalogWithPagination-types'
import { styles } from './ProductsActionBar-styles'
import { cn } from 'ethcss'
import translate from 'core/translate'
import { CatalogWithPaginationContext } from 'organisms/CatalogWithPagination'
import helpers, { IRecordWithAny } from 'core/helpers'
import { DebouncedSearchbar } from 'molecules/SearchBar'
import { useLocation } from 'react-router'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import Dropdown from 'atoms/Dropdown'
import { changeFilter } from 'features/routes'
import { useDispatch } from 'react-redux'
import { IAuthenticatedUserState, updateUserSettings } from 'blocks.app/user/user.state'
import { useTypedAuthenticatedSelector } from 'core/store/hooks'
import { isHorizontalMenu } from 'core/helpers/menu'

export const ProductsActionBarComponent: FC<ICatalogWithPaginationActionBarProps> = ({ classNames }) => {
    const dispatch = useDispatch()
    const { data: user } = useTypedAuthenticatedSelector<IAuthenticatedUserState>((state) => state.user)
    const { common } = useContext(CatalogWithPaginationContext)
    const { type, isEnableUpdateUserSettings = true } = common
    const viewParams = helpers.getViewParamsByUser(type, user)
    const { sort } = viewParams

    const location = useLocation()
    const locationQuery = getURLSearchParamsByLocation(location)
    const { query } = helpers.getQuery(locationQuery, type)
    const currentSort = sort

    const sortOptions = [
        { id: '-createdAt', name: translate('sortCreatedAt') },
        { id: 'productCode', name: translate('productCode') },
    ]

    const onSortChange = (sortValue: IRecordWithAny) => {
        const { id } = sortValue as typeof sortOptions[0]

        dispatch(updateUserSettings({ data: { sort: { products: id } }, isSaveSettings: isEnableUpdateUserSettings }))
    }

    const onSearchChange = (searchQuery: any) => {
        changeFilter({
            [helpers.prefixQuery({ name: type, field: 'query' })]: searchQuery,
        })
    }

    return (
        <div className={cn(classNames || styles.ProductsActionBar)}>
            <div className={cn(styles.ProductsActionBar__form, isHorizontalMenu() && styles.ProductsActionBar__form_x)}>
                <div className={styles.ProductsActionBar__search}>
                    <DebouncedSearchbar
                        id="searchbar"
                        onSearch={onSearchChange}
                        value={query}
                        mod={'none'}
                        classNames={styles.ProductsActionBar__searchbar}
                    />
                </div>
                <div className={styles.ProductsActionBar__sort}>
                    <div
                        className={cn(styles.ProductsActionBar__delimer, styles.ProductsActionBar__delimer_type_sort)}
                    />
                    <Dropdown
                        mod={'none'}
                        options={sortOptions}
                        wrapperClassName={styles.ProductsActionBar__dropdown_type_sort}
                        activeWrapperClassName={styles.ProductsActionBar__dropdown_type_sort_status_active}
                        value={currentSort}
                        icon={'sort'}
                        iconSide={'left'}
                        label={translate('sort')}
                        valueKey={'id'}
                        labelKey={'name'}
                        onChange={onSortChange}
                    />
                </div>
            </div>
        </div>
    )
}

export const ProductsActionBar = memo(ProductsActionBarComponent)
