import React from 'react'
import Modal from 'blocks.simple/modal/modal'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import styles from './deviceSettings__accept.jcss'

const DeviceSettingsAccept = (p_: { onCancel: () => void; onAccept: () => void; open: boolean }) => (
    <Modal open={p_.open} showCloseIcon={false} mod="clear">
        <div className={styles.modalWrapper}>
            <div className={styles.modalInner}>
                <div className={styles.title}>{translate('changeAllDevices')}</div>
                <div>{translate('descriptionChangeAllDevices')}</div>
                <div className={styles.confirmText}>{translate('confirmChangeSettings')}</div>
                <div className={styles.footer}>
                    <Button className={styles.button} onClick={p_.onCancel}>
                        {translate('cancel')}
                    </Button>
                    <Button mod="fill" onClick={p_.onAccept}>
                        {translate('apply')}
                    </Button>
                </div>
            </div>
        </div>
    </Modal>
)

export default React.memo(DeviceSettingsAccept)
