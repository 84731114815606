import React, { useCallback } from 'react'
import Content from 'blocks.app/content/content'
import BroadcastsMethods from './broadcasts.local.methods'
import BroadcastsToolbar from './__toolbar/broadcasts__toolbar'
import Catalog from 'blocks.app/catalog/catalog'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './broadcasts.jcss'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { isHorizontalMenu } from '../../core/helpers/menu'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useWindowResize } from 'core/hooks'
import { checkAvailableModule } from '../../core/helpers/routes/routes'
import { UploadBroadcasts } from './__upload/broadcasts__upload'
import { DownloadInfoModal } from './__downloadInfoModal/broadcasts__downloadInfoModal'
import { downloadsActions } from '../../core/services/DownloadService/DownloadService.state'

const Broadcasts = (p_: any) => {
    const { s_, methods } = BroadcastsMethods(p_)
    const query = getURLSearchParamsByLocation(p_.location)
    const folderId = query.folderId
    const mainMenuType = useSelector<RootStateOrAny>((state) => state.user.data?.settings.mainMenuType)
    const mainMenuTypeCheck = () => mainMenuType
    mainMenuTypeCheck()
    const broadcastsState: any = useSelector<RootStateOrAny>((state) => state.broadcasts)
    const dispatch = useDispatch()
    const downloads: any = useSelector<RootStateOrAny>((state) => state.downloads)

    const addDelayDownloadTask = (task: string) => {
        dispatch(downloadsActions.addDelayDownloadTask(task))
    }

    const onResetSelection = useCallback(() => {
        methods.setSelectedInfo(null)
        methods.setSelectedItems([])
    }, [])

    const { width } = useWindowResize({ width: window.innerWidth })

    return (
        <>
            {s_.showDownloadInfo && (
                <DownloadInfoModal progressList={downloads.delayDownloadsProgressList} onClose={methods.onCloseInfo} />
            )}
            {broadcastsState.isOpenAddBroadcastFile && <UploadBroadcasts location={p_.location} />}
            <Content
                title={translate('broadcasts')}
                className={p_.className}
                wrapperClassName={styles.broadcastWrapper}
                frame={p_.frame}
                toolbar={
                    <BroadcastsToolbar
                        query={query}
                        selectedInfo={s_.selectedInfo}
                        selectedItems={s_.selectedItems}
                        folders={s_.folders}
                        folderId={folderId}
                        onCloseInfo={() => methods.setSelectedInfo(null)}
                        onChange={methods.setSelectedItems}
                        onGetFoldersOnMultiple={methods.onGetFoldersOnMultiple}
                        onResetSelection={onResetSelection}
                        onOpenInfo={methods.onOpenInfo}
                        addDelayDownloadTask={addDelayDownloadTask}
                        downloads={downloads}
                    />
                }
            >
                <Catalog
                    type="broadcasts"
                    breadcrumbsClassName={cn(styles.breadcrumbs, {
                        [styles.breadcrumbs_x]: isHorizontalMenu() && width > 960,
                    })}
                    selectedInfo={s_.selectedInfo}
                    onSelectInfo={methods.onSelectInfo}
                    onResetSelection={onResetSelection}
                    onDoubleClickObject={methods.onDoubleClickObject}
                    selectedItems={s_.selectedItems}
                    onSelect={methods.setSelectedItems}
                    fixedHeader
                />
            </Content>
        </>
    )
}

const exportBroadcasts = checkAvailableModule('broadcasts') ? Broadcasts : null

export default exportBroadcasts
