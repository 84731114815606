import { addStyles } from 'ethcss'
import { indents, mediaCSS, grid, item, text } from 'theme'
import { colors } from '../../../theme'

const profileFormStyles = {
    profileForm: {
        ...grid.w50,
        ...grid.colCenter,
        ...grid.fullHeight,
        [mediaCSS.tabMini]: {
            ...grid.w100,
            ...item.block,
            height: 'auto',
        },
    },
    profileFormContent: {
        ...grid.full,
        ...grid.p_md,
        [mediaCSS.tabMini]: {
            ...grid.p_none,
            ...grid.pt_md,
            ...grid.pb_md,
        },
    },
    profileFormInput: {
        ...grid.mb_md,
        ...grid.ml_mdPlus,
        ...grid.mr_mdPlus,
        overflow: 'visible !important',
        borderRadius: '5px !important',

        '& input': {
            backgroundColor: colors.white,
            borderRadius: '5px !important',
            border: `1px solid ${colors.grey}`,
        },
    },
    profileFormLink: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.mb_md,
        ...grid.pl_mdPlus,
        ...grid.pr_mdPlus,
        ...item.pointer,
        ...text.grey,
    },
    profileFormLinkIcon: {
        ...grid.mr_mini,
    },
    profileFormDropdownWrapper() {
        return {
            ...item.rel,
            [`&:hover .${this.profileFormDeleteIcon}`]: {
                ...item.visible,
                opacity: 1,
            },
        }
    },
    profileFormDeleteIcon: {
        ...item.abs,
        ...item.transitionErase,
        ...item.hidden,
        bottom: indents.mini,
        right: 0,
        opacity: 0,
    },
    profileFormPasswordLabel: {
        ...grid.row,
        ...grid.justify,
        ...grid.w100,
    },
    profileFormPasswordGenerateLabel: {
        ...text.mini,
        ...text.hoverUnderline,
        ...item.pointer,
    },
    profileFormTitleWrapper: {
        ...grid.mr_mdPlus,
        ...grid.ml_mdPlus,
        ...grid.mb_mini,
        ...grid.mt_mini,
        ...text.normal,
        lineHeight: '18px',
    },
    profileFormTitle: {
        ...text.up,
        ...text.greyDark,
        ...text.bold,
        letterSpacing: '0.1em',
    },
    profileFormTitleType: {
        ...text.bold,
        ...text.blue,
        letterSpacing: '0.1em',
    },
    profileFormRejectButton: {
        ...grid.pr_micro,
        [mediaCSS.tabMini]: {
            ...grid.pr_none,
            marginBottom: '10px',
        },
    },
    profileFormAcceptButton: {
        ...grid.pl_micro,
        [mediaCSS.tabMini]: {
            ...grid.pl_none,
        },
    },
    profileFormButtons: {
        ...grid.row,
        ...grid.fullWidth,
        ...grid.justify,
    },
    profileFormButtonWrapper: {
        ...grid.w50,
        ...grid.flex,
        ...grid.center,

        [mediaCSS.tabMini]: {
            ...grid.w100,
        },

        '& button': {
            width: '150px',
            ...grid.pb_micro,
            '&>div': {
                width: '100%',
            },
        },
    },
    profileFormButton: {
        ...grid.fullWidth,
    },
}

addStyles(profileFormStyles)
export default profileFormStyles
