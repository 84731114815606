import React, { FunctionComponent, memo } from 'react'
import { Button } from 'atoms/Button'
import Dotdotdot from 'react-dotdotdot'
import { cn } from 'ethcss'
import { scheduleItemStyles } from './ScheduleItem-styles'
import helpers from 'core/helpers'
import { IScheduleItemComponent } from './ScheduleItem-types'
import { useConfirmDelete, useGetBroadcastOnSelect } from './ScheduleItem-hooks'
import { setEditBroadcastLocation } from '../../pages/broadcasts/broadcasts.local.methods'
import translate from 'core/translate'
import { IBroadcast } from 'core/models/Broadcast'
import { isExist, isNotEmptyArray, getWordTranslateWithDeclension } from 'core/utils/coreUtil'
import { findDevicesById } from 'core/helpers/digitalSignages'
import Icon from 'blocks.simple/icon/icon'

const ScheduleItemComponent: FunctionComponent<IScheduleItemComponent> = ({
    id,
    broadcastId,
    isShowButtons = true,
    timeTitle,
    broadcastTitle,
    onDeleteClick,
    onSelectClick,
    onEditClick,
    selected,
    scheduleToDigitalSignageList,
    setEvents,
}) => {
    let { isConfirmDelete, setConfirmDelete } = useConfirmDelete()
    let { broadcast } = useGetBroadcastOnSelect(broadcastId, selected)

    const handleScheduleEdit = () => {
        if (broadcast) {
            setEditBroadcastLocation(broadcast, broadcast.folderId)
        }
    }

    const handleCancelDelete = () => {
        setConfirmDelete(false)
    }

    const handleDelete = (e: unknown) => {
        if (!isConfirmDelete) {
            return setConfirmDelete(true)
        }

        onDeleteClick(e)
        setConfirmDelete(false)
        if (setEvents) {
            setEvents(id)
        }
    }

    const handleDevicesSearch = () => {
        let deviceIdList = scheduleToDigitalSignageList.map(
            (scheduleToDigitalSignage) => scheduleToDigitalSignage.digitalSignageId
        )
        findDevicesById(deviceIdList)
    }

    const isEnableEditBroadcast = (broadcast: IBroadcast | null) => {
        return helpers.isAvailable({ key: 'schedule', action: 'update', strict: true })
    }

    const isScheduleToDigitalSignageListExist = () => {
        return isExist(scheduleToDigitalSignageList) && isNotEmptyArray(scheduleToDigitalSignageList)
    }

    const getDevicesListText = () => {
        let translation = getWordTranslateWithDeclension(scheduleToDigitalSignageList.length, [
            translate('device'),
            translate('devices'),
            translate('devices|'),
        ]).toLowerCase()

        return `${scheduleToDigitalSignageList.length} ${translation}`
    }

    return (
        <div>
            {selected && (
                <div className={scheduleItemStyles.selected}>
                    {!isConfirmDelete && (
                        <div
                            className={cn(scheduleItemStyles.timeTitleContainer, {
                                [scheduleItemStyles.timeTitleContainerMarginBottomNormal]: isShowButtons,
                            })}
                        >
                            <div className={scheduleItemStyles.timeTitle}>{timeTitle}</div>
                            <div>{broadcastTitle}</div>
                            {isScheduleToDigitalSignageListExist() ? (
                                <div className={scheduleItemStyles.linkNoPb} onClick={handleDevicesSearch}>
                                    {getDevicesListText()}
                                </div>
                            ) : null}
                        </div>
                    )}
                    <div className={scheduleItemStyles.controlsButtonsContainer}>
                        {!isConfirmDelete && isEnableEditBroadcast(broadcast) ? (
                            <div className={scheduleItemStyles.controlsLeftContainer}>
                                <Icon
                                    type={'editBroadcast'}
                                    size={24}
                                    color={'custom'}
                                    onClick={handleScheduleEdit}
                                    tooltip={{ title: translate('edit_broadcast') }}
                                />
                            </div>
                        ) : null}
                        {isShowButtons && (
                            <div className={scheduleItemStyles.controlsContainer}>
                                {helpers.isAvailable({ key: 'schedule', action: 'update', strict: true }) &&
                                    !isConfirmDelete && (
                                        <div className={cn(scheduleItemStyles.buttonContainerCenterItem)}>
                                            <Icon
                                                type={'pencil'}
                                                size={18}
                                                color={'custom'}
                                                onClick={onEditClick}
                                                tooltip={{ title: translate('editSchedule') }}
                                            />
                                        </div>
                                    )}
                                {!isConfirmDelete &&
                                    helpers.isAvailable({ key: 'schedule', action: 'delete', strict: true }) && (
                                        <div className={cn(scheduleItemStyles.buttonContainer)}>
                                            <Icon
                                                type={'deleteBasket'}
                                                size={18}
                                                color={'custom'}
                                                onClick={handleDelete}
                                                tooltip={{ title: translate('delete') }}
                                            />
                                        </div>
                                    )}
                                {isConfirmDelete &&
                                    helpers.isAvailable({ key: 'schedule', action: 'delete', strict: true }) && (
                                        <div className={cn(scheduleItemStyles.buttonContainer)}>
                                            <Button
                                                indent={'normal'}
                                                rounded={'full_normal'}
                                                onClick={handleDelete}
                                                className={cn(scheduleItemStyles.button)}
                                                contentClassName={scheduleItemStyles.buttonContent}
                                            >
                                                <span>{translate('yes')}</span>
                                            </Button>
                                        </div>
                                    )}
                                {isConfirmDelete && (
                                    <div className={cn(scheduleItemStyles.buttonContainer)}>
                                        <Button
                                            indent={'normal'}
                                            rounded={'full_normal'}
                                            // fullWidth={true}
                                            onClick={handleCancelDelete}
                                            className={cn(scheduleItemStyles.button)}
                                            contentClassName={scheduleItemStyles.buttonContent}
                                        >
                                            <span>{translate('no')}</span>
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {isConfirmDelete && (
                        <div className={scheduleItemStyles.confirmTitle}>
                            <Dotdotdot clamp={2}>
                                <span dangerouslySetInnerHTML={{ __html: translate('youAreSureToDeleteSchedule') }} />{' '}
                                {timeTitle} {broadcastTitle}?
                            </Dotdotdot>
                        </div>
                    )}
                </div>
            )}
            {!selected && (
                <div className={scheduleItemStyles.scheduleItem} onClick={onSelectClick}>
                    <div className={scheduleItemStyles.timeTitle}>{timeTitle}</div>
                    <div className={scheduleItemStyles.dotdotdotContainer}>
                        <Dotdotdot clamp={2}>
                            <div className={scheduleItemStyles.broadcastTitle}>{broadcastTitle}</div>
                        </Dotdotdot>
                    </div>
                </div>
            )}
        </div>
    )
}

export const ScheduleItem = memo(ScheduleItemComponent)
