import { useRef, useState } from 'react'
import validator from 'validator'
import { api } from 'core/api/ConnectionManager'
import request from 'superagent'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { useDispatch } from 'react-redux'
import { login as loginAction } from 'blocks.app/user/user.state'
import { IDefaultLoginData, ITwoFactorLoginData } from './useAuthorizationLogin'
import { getDefaultLocale } from '../helpers/user'

export interface IUseRegistration {
    email: string
    password: string
    repeatPassword: string
    phone: string
    needInstruction: boolean
    policy: boolean
    softwarePolicy: boolean
    companyName: string | null
    capcha: string | null
    country: string
    onChangeEmail: (email: string) => void
    onChangePassword: (password: string) => void
    onChangeRepeatPassword: (password: string) => void
    onChangePhone: (phone: string) => void
    onChangeCompanyName: (name: string) => void
    onChangeNeedInstruction: (isNeedInstruction: boolean) => void
    onChangePolicy: (policy: boolean) => void
    onChangeSoftwareUse: (policy: boolean) => void
    onChangeCapcha: (token: string | null) => void
    onChangeCountry: (country: { id: string; name: string }) => void
    registration: () => void
    capchaRef: any
}

export interface IUseRegistrationProps {
    onLoading: (loading: boolean) => void
}

export const useRegistration = ({ onLoading }: IUseRegistrationProps): IUseRegistration => {
    const dispatch = useDispatch()
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [repeatPassword, setRepeatPassword] = useState<string>('')
    const [phone, setPhone] = useState<string>('')
    const [needInstruction, setNeedInstruction] = useState<boolean>(false)
    const [policy, setPolicy] = useState<boolean>(false)
    const [softwarePolicy, setSoftwarePolicy] = useState<boolean>(false)
    const [companyName, setCompanyName] = useState<string | null>(null)
    const [capcha, setCapcha] = useState<string | null>(null)
    const [country, setCountry] = useState<string>('Russia')
    const capchaRef = useRef<any>(null)

    const onChangeEmail = (email: string) => {
        setEmail(email.trim())
    }
    const onChangePassword = (password: string) => {
        setPassword(password)
    }
    const onChangeRepeatPassword = (password: string) => {
        setRepeatPassword(password)
    }
    const onChangePhone = (phone: string) => {
        setPhone(phone)
    }
    const onChangeNeedInstruction = (isNeedInstruction: boolean) => {
        setNeedInstruction(isNeedInstruction)
    }
    const onChangePolicy = (policy: boolean) => {
        setPolicy(policy)
    }
    const onChangeSoftwareUse = (softwarePolicy: boolean) => {
        setSoftwarePolicy(softwarePolicy)
    }
    const onChangeCompanyName = (name: string) => {
        setCompanyName(name)
    }
    const onChangeCapcha = (token: string | null) => {
        setCapcha(token)
    }
    const onChangeCountry = (country: { id: string; name: string }) => {
        setCountry(country.id)
    }

    const onRegistrationSuccess = (data: IDefaultLoginData) => {
        dispatch(
            loginAction({
                tokens: data.tokens,
                user: data.user,
            })
        )
    }

    const onRegisterError = (response: any) => {
        let errorMessage: string | number = 'serverIsNotAvailable'

        if (response) {
            errorMessage = response.body.error
            console.log(errorMessage)
        }

        if (errorMessage === 69) {
            capchaRef.current.reset()
        }

        dispatch(emitError(errorMessage))
    }

    const validate = () => {
        if (!email) {
            dispatch(emitError('emailEmpty'))
            return false
        }
        if (!password) {
            dispatch(emitError('passwordEmpty'))
            return false
        }
        if (!repeatPassword) {
            dispatch(emitError('passwordEmpty'))
            return false
        }
        if (password !== repeatPassword) {
            dispatch(emitError('repeatPasswordError'))
            return false
        }
        if (!validator.isEmail(email)) {
            dispatch(emitError('emailFormatError'))
            return false
        }
        if (!policy) {
            dispatch(emitError('policyError'))
            return false
        }
        if (!softwarePolicy) {
            dispatch(emitError('softwarePolicyError'))
            return false
        }
        if (!capcha) {
            dispatch(emitError('capchaError'))
            return false
        }

        return true
    }

    const registration = () => {
        if (!validate()) {
            return
        }

        const params: any = {
            email,
            password,
            locale: getDefaultLocale(),
            needInstructionQ: needInstruction,
            expireD: window.spconfig.registration.expireD,
            capchaToken: capcha,
            country,
        }

        if (companyName) {
            params.companyName = companyName
        }
        if (phone) {
            const phoneNumber = phone.replace(/\D/g, '')

            if (phoneNumber) {
                params.phone = phoneNumber
            }
        }

        onLoading(true)

        api.post('registration', params)
            .then((res: request.Response) => {
                let data: ITwoFactorLoginData | (IDefaultLoginData & { preToken?: null }) = res.body.data

                onLoading(false)
                onRegistrationSuccess(data as IDefaultLoginData)
            })
            .catch(({ response }: { response: any }) => {
                onLoading(false)
                console.log(response)
                onRegisterError(response)
            })
    }

    return {
        password,
        repeatPassword,
        email,
        phone,
        policy,
        softwarePolicy,
        companyName,
        registration,
        needInstruction,
        capcha,
        country,
        capchaRef,
        onChangeEmail,
        onChangePassword,
        onChangeRepeatPassword,
        onChangePhone,
        onChangePolicy,
        onChangeSoftwareUse,
        onChangeNeedInstruction,
        onChangeCompanyName,
        onChangeCapcha,
        onChangeCountry,
    }
}
