import { addStyles } from 'ethcss'
import { grid, IStyles } from '../../../../theme'
import { defaultThemeStyles } from 'blocks.app/app/app.theme'

const advertisingCampaignsAddContentSettingsStyles: IStyles = {
    wrapper: {
        ...grid.col,
        ...grid.space,
    },
    contentHeader: {
        ...grid.pt_mdPlus,
        ...grid.pb_mdPlus,
        ...grid.colCenter,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeStyles['border']})`,
    },
}

addStyles(advertisingCampaignsAddContentSettingsStyles)
export default advertisingCampaignsAddContentSettingsStyles
