import React from 'react'
import moment from 'moment'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import deepEqual from 'fast-deep-equal'
import helpers from 'core/helpers'
import helpersDigitalSignages from 'core/helpers/_digitalSignages'
import { getThemeStyleValue } from '../../../../theme/colors'
import { defaultThemeStyles as defaultThemeSidebarStyles } from 'blocks.app/sidebar/Sidebar-theme'

interface ISelectedInfo {
    id: number
    type: string
    settings: {
        autoScreenshot: {
            width: number
            height: number
            enabled: boolean
            interval: number
        }
    }
    online?: boolean
    screenshots?: { createdAt: string }[]
    __type?: string
    name?: string
    url?: string
    createdAt?: string
    resolutionHeight?: number
    resolutionWidth?: number
    status: string
}
interface IShotsSidebarProps {
    selectedInfo: ISelectedInfo
    startUpdateAnimation: () => void
    emitError: (error: string) => void
    onChangeActive: (data: any) => void
    uploadDigitalSignageAndGroupMedia: (data: { formData: FormData; cb: () => void }) => void
    minimized: boolean
    toggleMinimized: () => void
    onClose: () => void
}
interface IState {
    active: { __type: string; id: number } | null
    selected: number[]
    selectedMedia: number[]
    screenshots: { createdAt: string; id: number; url: string }[]
    total: number
    isLoading: boolean
    startDate: moment.Moment
    endDate: moment.Moment
    height: number
    autoScreenshot: {
        enabled: boolean
        interval: number
        width: number
        height: number
    }
    media: { createdAt: string; id: number; url: string }[]
    totalMedia: number
    activeTab: string
    isLoadingMedia: boolean
    historyList?: {}[]
}

const dates = {
    startDate: moment(),
    endDate: moment(),
}

const defaultAutoScreenShotSettings = {
    enabled: false,
    interval: 5000,
    width: 1920,
    height: 1080,
}

class DisplaysScreenShotsSidebarMethods extends React.Component<IShotsSidebarProps> {
    state: IState = {
        active: null,
        selected: [],
        selectedMedia: [],
        screenshots: [],
        total: 0,
        isLoading: true,
        ...dates,
        height: 0,
        autoScreenshot: defaultAutoScreenShotSettings,
        media: [],
        totalMedia: 0,
        activeTab: 'screenshotsHistory',
        isLoadingMedia: false,
    }
    autoScreenShotTimer: any = null
    dropzone: any = null
    tabs = [
        { id: 'screenshotsHistory', label: translate('screenshotsHistory') },
        { id: 'installation', label: translate('installation') },
    ]
    listenersId = []

    componentDidMount() {
        const p_ = this.props

        this.getMedia()
        this.createListeners()

        if (p_.selectedInfo.type !== 'group') {
            this.getScreenshots()
            this.getAutoScreenShotSettings()
        }

        if (p_.selectedInfo.type === 'group') {
            this.setState({ activeTab: 'installation' })
        }
    }

    getMedia = (isLazyLoad?: boolean) => {
        const p_ = this.props
        const s_ = this.state
        const data = {
            offset: isLazyLoad ? (this.state.historyList ? this.state.historyList.length : 0) : 0,
            perPage: 20,
            [`${p_.selectedInfo.type}Id`]: p_.selectedInfo.id,
        }

        api.send('getDigitalSignageAndGroupMedia', data).then((res: any) => {
            let media = res.data
            media.forEach((item: { __type: string }) => {
                item.__type = 'installation'
            })

            if (isLazyLoad) {
                media = [...s_.media, ...media]
            }

            this.setState({
                media,
                total: res.total,
            })
        })
    }
    getAutoScreenShotSettings = () => {
        const { selectedInfo } = this.props

        if (selectedInfo.settings && selectedInfo.settings.autoScreenshot) {
            this.setState({
                autoScreenshot: {
                    ...selectedInfo.settings.autoScreenshot,
                },
            })

            this.setAutoScreenShotTimer(selectedInfo.settings.autoScreenshot, selectedInfo)
        }
    }
    setAutoScreenShotSettings = (value: number | boolean, field: string) => {
        const s_ = this.state
        const p_ = this.props

        if (field === 'interval') {
            value = Number(value)
            value *= 1000
        }

        // @ts-ignore
        s_.autoScreenshot[field] = value

        this.setAutoScreenShotTimer(s_.autoScreenshot, p_.selectedInfo)
        this.setState(s_)
    }
    setAutoScreenShotSettingsWidthHeight = (value: number, field: string, max: number) => {
        const s_ = this.state
        const p_ = this.props

        if (field === 'width') {
            s_.autoScreenshot.width = value
            if (!max) {
                return
            }
            this.setAutoScreenShotTimer(s_.autoScreenshot, p_.selectedInfo)
        } else {
            s_.autoScreenshot.height = value
            if (!max) {
                return
            }
            this.setAutoScreenShotTimer(s_.autoScreenshot, p_.selectedInfo)
        }

        this.setState(s_)
    }

    saveAutoScreenShotSettings = () => {
        const s_ = this.state
        const { selectedInfo } = this.props

        if (helpersDigitalSignages.isFolder(selectedInfo)) {
            return
        }

        if (!deepEqual(selectedInfo.settings.autoScreenshot, s_.autoScreenshot)) {
            api.send('editDisplay', {
                settings: { ...selectedInfo.settings, autoScreenshot: s_.autoScreenshot },
                id: selectedInfo.id,
            })
        }
    }
    setAutoScreenShotTimer = (
        settings: {
            enabled: boolean
            interval: number
            width: number
            height: number
            autoScreenshot?: boolean
        } | null,
        item: ISelectedInfo
    ) => {
        const p_ = this.props

        if (!helpers.isAvailableAction(p_.selectedInfo, { key: 'digitalSignage', action: 'update' })) {
            return
        }

        if (!settings) {
            settings = this.state.autoScreenshot
        }

        if (item && item.online && settings.enabled) {
            clearInterval(this.autoScreenShotTimer)

            this.autoScreenShotTimer = setInterval(() => {
                if (settings && settings.interval < defaultAutoScreenShotSettings.interval) {
                    clearInterval(this.autoScreenShotTimer)
                    p_.emitError('intervalCantBeLessFiveSeconds')
                    return
                }

                p_.startUpdateAnimation()

                api.send('updateScreenshotCb', {
                    id: item.id,
                    width: settings && settings.width ? settings.width : 1920,
                    height: settings && settings.height ? settings.height : 1080,
                })
            }, settings.interval)
        } else {
            clearInterval(this.autoScreenShotTimer)
        }
    }
    isSameOrBeforeDates = (current: string) => {
        const s_ = this.state
        const currentFormat = moment(current).format('YYYY-MM-DD')
        const startDateFormat = moment(s_.startDate).format('YYYY-MM-DD')

        return moment(startDateFormat).isSameOrBefore(moment(currentFormat))
    }

    componentDidUpdate(prevProps: IShotsSidebarProps) {
        const s_ = this.state
        const p_ = this.props

        if (
            !deepEqual(p_.selectedInfo.screenshots, prevProps.selectedInfo.screenshots) &&
            moment(dates.endDate).isSameOrBefore(s_.endDate)
        ) {
            const screenshots = p_.selectedInfo!.screenshots!.filter((screenshot) =>
                this.isSameOrBeforeDates(screenshot.createdAt)
            )
            this.setState({ screenshots })

            if (screenshots.length && s_.activeTab !== 'installation') {
                this.setActiveMedia(screenshots[0])
            }
        }

        if (p_.selectedInfo.online !== prevProps.selectedInfo.online) {
            this.setAutoScreenShotTimer(null, p_.selectedInfo)
        }
    }

    getScreenshots = (isLazyLoad?: boolean, filter = {}) => {
        const data = {
            offset: isLazyLoad ? this.state.screenshots.length : 0,
            perPage: 20,
            digitalSignageId: this.props.selectedInfo.id,
            ...filter,
        }

        this.setState({ isLoading: true })
        api.send('getDigitalSignageScreenshots', data).then((res: any) => {
            const data = {
                screenshots: res.data,
                total: res.total,
            }

            if (!res.data.length) {
                data.screenshots = []
            }

            this.setState({ ...data, isLoading: false })

            if (!isLazyLoad) {
                if (res.data.length) {
                    this.setActiveMedia(res.data[0])
                }
            }
        })
    }

    onDigitalSignageScreenshotDeleted = (item: ISelectedInfo) => {
        this.onScreenShotDeleted(item)
    }

    onDigitalSignageAndGroupMediaCreated = (item: ISelectedInfo) => {
        this.onMediaCreated(item)
    }

    onDigitalSignageAndGroupMediaDeleted = (item: ISelectedInfo) => {
        this.onMediaDeleted(item)
    }

    createListeners = () => {
        api.addObserver('digitalSignageScreenshotDeleted', this.onDigitalSignageScreenshotDeleted, this.listenersId)
        api.addObserver(
            'digitalSignageAndGroupMediaCreated',
            this.onDigitalSignageAndGroupMediaCreated,
            this.listenersId
        )
        api.addObserver(
            'digitalSignageAndGroupMediaDeleted',
            this.onDigitalSignageAndGroupMediaDeleted,
            this.listenersId
        )
    }
    onScreenShotDeleted = (item: ISelectedInfo) => {
        const s_ = this.state
        const p_ = this.props
        const screenshots = s_.screenshots.filter((screen) => screen.id !== item.id)
        const selected = s_.selected.filter((media) => media !== item.id)
        const data: {
            selected: number[]
            screenshots: { id: number }[]
            active?: null
        } = {
            screenshots,
            selected,
        }

        if (s_.active && s_.active.__type !== 'installation' && item.id === s_.active.id) {
            data.active = null
            p_.onChangeActive({ caption: '', image: '', type: '' })
        }

        this.setState({ ...data })
    }
    onMediaCreated = (item: ISelectedInfo) => {
        const s_ = this.state
        item.__type = 'installation'

        this.setState({ media: [...s_.media, item] })
    }
    onMediaDeleted = (item: ISelectedInfo) => {
        const s_ = this.state
        const p_ = this.props
        const media = s_.media.filter((media) => media.id !== item.id)
        const selectedMedia = s_.selectedMedia.filter((media) => media !== item.id)
        const data: {
            media: { id: number }[]
            selectedMedia: number[]
            active?: null
        } = {
            media,
            selectedMedia,
        }

        if (s_.active && s_.active.__type === 'installation' && item.id === s_.active.id) {
            data.active = null
            p_.onChangeActive({ caption: '', image: '', type: '' })
        }

        this.setState({ ...data })
    }
    componentWillUnmount() {
        const p_ = this.props
        if (helpers.isAvailableAction(p_.selectedInfo, { key: 'digitalSignage', action: 'update' })) {
            this.saveAutoScreenShotSettings()
        }

        this.listenersId.forEach((id) => api.removeObserver(id))
        clearInterval(this.autoScreenShotTimer)
    }

    setActiveMedia = (item: { createdAt: string; url?: string }) => {
        const p_ = this.props
        const s_ = this.state
        const typeLabel =
            s_.activeTab === 'installation' ? translate('installation').toLowerCase() : translate('screen')
        const caption = `${p_.selectedInfo.name}, ${typeLabel} ${moment(item.createdAt).format('DD/MM/YYYY HH:mm')}`
        const image = item.url
        const active = {
            ...item,
            __type: s_.activeTab,
        }

        p_.onChangeActive({ ...active, caption, image })
        this.setState({ active })
    }
    onDelete = () => {
        api.send('deleteDigitalSignageScreenshot', { id: this.state.selected })
    }
    onDeleteMedia = () => {
        api.send('deleteDigitalSignageAndGroupMedia', { id: this.state.selectedMedia })
    }
    onFilter = () => {
        const s_ = this.state
        const startDate = moment(s_.startDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
        const endDate = moment(s_.endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss')

        this.getScreenshots(false, { startDate, endDate })
    }
    openDropzone = () => {
        const s_ = this.state

        if (!s_.isLoadingMedia) {
            this.dropzone.open()
        }
    }
    onDrop = (e: any) => {
        this.setState({ isLoadingMedia: true })

        const p_ = this.props
        const formData = new FormData()
        const type = `${p_.selectedInfo.type}Id`

        formData.append('file', e[0])
        formData.append(type, p_.selectedInfo.id.toString())

        p_.uploadDigitalSignageAndGroupMedia({
            formData,
            cb: () => this.setState({ isLoadingMedia: false }),
        })
    }

    onSelectAll = () => {
        const s_ = this.state
        if (s_.selected.length === s_.screenshots.length) {
            this.setState({ selected: [] })
            return
        }
        this.setState({ selected: this.state.screenshots.map((screenshot) => screenshot.id) })
    }

    getSelectAllIconProps = () => {
        const s_ = this.state

        const props = {
            type: 'checked',
            size: 18,
            color:
                getThemeStyleValue('sidebar', 'screenshot', 'infoText') ||
                defaultThemeSidebarStyles['screenshot-infoText'],
            tooltip: {
                title: translate('selectAll'),
            },
        }

        if (!s_.screenshots.length) {
            return props
        }

        if (s_.selected.length === s_.screenshots.length) {
            props.type = 'close'
            props.tooltip = {
                title: translate('deselect'),
            }
        }

        return props
    }
}

export default DisplaysScreenShotsSidebarMethods
