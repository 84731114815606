import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { IStyles } from '../../../theme'

const searchbarFadeStyles: IStyles = {
    wrapper: {
        ...grid.row,
        ...grid.w10,
        position: 'absolute',
        right: -1,
        top: 0,
        bottom: 0,
    },
    item: {
        ...grid.fullHeight,
        ...grid.w10,
    },
}

addStyles(searchbarFadeStyles)
export default searchbarFadeStyles
