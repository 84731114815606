import React from 'react'
import AddScheduleMethods, { IAddScheduleProps } from './addSchedule.local.methods'
import Settings from './__settings/addSchedule__settings'
import Button from 'blocks.simple/button/button'
import Catalog from 'blocks.app/catalog/catalog'
import Modal from 'blocks.simple/modal/modal'
import { Slider } from 'molecules/Slider'
import { Radio } from 'atoms/Radio'
import helpers from 'core/helpers'
import broadcastsHelpers from 'core/helpers/_broadcasts'
import scheduleHelpers from 'core/helpers/_schedule'
import translate from 'core/translate'
import template from 'es6-template-strings'
import { cn } from 'ethcss'
import styles from './addSchedule.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { text as textStyles } from 'theme'
import { Steps } from 'atoms/Steps/Steps-view'
import { connect } from 'react-redux'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { ISchedule } from '../../core/models/Schedule'

const AddSchedule = (p_: IAddScheduleProps) => {
    const {
        state,
        setState,
        solveConflicts,
        closeWarningWindow,
        saveDataAndClose,
        closeConflictsModal,
        saveWithSolution,
    } = AddScheduleMethods(p_)
    const s_ = state

    return (
        <div className={cn(styles.wrapper)}>
            {p_.onChangeSchedule && (
                <Settings
                    schedule={p_.schedule}
                    onChangeSchedule={p_.onChangeSchedule}
                    onChangeDeferredDownload={p_.onChangeDeferredDownload}
                    advDuration={p_.advDuration || 0}
                    advContentLength={p_.advContentLength}
                    deferredDownload={p_.deferredDownload}
                />
            )}
            {(p_.onChangeDevices || p_.onChangeBroadcast) && (
                <div className={styles.content}>
                    <Steps
                        active={s_.selectedTab}
                        className={cn(
                            styles.tabs,
                            {
                                [grid.mb_md]: s_.selectedTab === 'broadcasts' && p_.broadcast,
                            },
                            styles.stepsMargin
                        )}
                    >
                        {s_.tabs.map((tab: string, index: number) => (
                            <div
                                key={`addSchedule__tab_${index}`}
                                id={tab}
                                onClick={() =>
                                    setState((prevState: any) => {
                                        return {
                                            ...prevState,
                                            selectedTab: tab,
                                        }
                                    })
                                }
                            >
                                {translate(tab)}
                            </div>
                        ))}
                    </Steps>
                    {p_.onChangeDevices && s_.selectedTab === 'devices' && (
                        <Catalog
                            key={'displays'}
                            type={'displays'}
                            selectedItems={p_.devices}
                            breadcrumbsClassName={cn(styles.breadcrumbs)}
                            onSelect={p_.onChangeDevices}
                        />
                    )}
                    {p_.onChangeBroadcast && s_.selectedTab === 'broadcasts' && (
                        <div>
                            <Catalog
                                key={'broadcasts'}
                                type={'broadcasts'}
                                selectedInfo={p_.broadcast}
                                onSelectInfo={function (selectedInfo: { instanceType: string }) {
                                    if (selectedInfo && broadcastsHelpers.isDirectory(selectedInfo)) {
                                        return
                                    }
                                    p_.onChangeBroadcast(selectedInfo)
                                }}
                                breadcrumbsClassName={cn(styles.breadcrumbs)}
                                showOnlySelected
                            />
                            {p_.broadcast && (
                                <Button className={cn(grid.mt_md)} onClick={() => p_.onChangeBroadcast(null)}>
                                    {translate('editLabel')}
                                </Button>
                            )}
                        </div>
                    )}
                    {s_.conflicts.length > 0 && s_.selectedTab === 'restrictions' && !p_.hideConflicts && (
                        <div className={item.white}>
                            <table className={item.tableWrapper}>
                                <thead>
                                    <tr>
                                        <th>{translate('object')}</th>
                                        <th>{translate('cause')}</th>
                                        <th>{translate('solution')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {s_.conflicts.map((conflict: any, index: number) => (
                                        <tr key={`conflict_tr_${index}`}>
                                            <td className={textStyles.center}>{conflict.obj.name}</td>
                                            <td>
                                                {template(translate(conflict.cause), {
                                                    platform: scheduleHelpers.getPlatformRestrictionsLabel(
                                                        conflict.obj.platform,
                                                        conflict.obj.dsVersion
                                                    ),
                                                })}
                                            </td>
                                            <td>{translate(conflict.proposedSolution)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className={cn(grid.row, grid.justify, grid.p_md)}>
                                <div />
                                <Button onClick={() => solveConflicts()}>{translate('solveConflictsAndSave')}</Button>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <Modal open={s_.showWarningWindow && p_.devices.length > 0} showCloseIcon={false} mod={'black'}>
                <div className={styles.modalWrapper}>
                    <div className={styles.modalInner}>
                        <div className={styles.modalTitle}>{translate('warningScheduleMessage')}</div>
                        <div>
                            <div className={cn(grid.row, grid.pl_mdPlus, grid.pr_mdPlus, styles.deviceCountWarning)}>
                                {`${translate('numberOfDevices')}: ${p_.devices.length}`}
                            </div>
                            <div className={styles.buttons}>
                                <Button
                                    rounded="full_md"
                                    indentSize="mini"
                                    onClick={closeWarningWindow}
                                    className={cn(grid.mr_md)}
                                >
                                    {translate('cancel')}
                                </Button>
                                <Button rounded="full_md" indentSize="mini" mod="fill" onClick={saveDataAndClose}>
                                    {translate('save')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal open={s_.showConflictsModal} showCloseIcon={false} mod={'black'}>
                <div className={styles.modalWrapper}>
                    <div className={styles.modalInner}>
                        <div className={styles.modalTitle}>{translate('addScheduleTitle')}</div>
                        <div className={cn(grid.pt_md, grid.mb_md, textStyles.center)}>
                            {translate('intersectionsWith')}{' '}
                            {translate(s_.schedules.length > 1 ? 'timetables' : 'timetable_')}
                        </div>
                        <Slider count={1}>
                            {s_.schedules.map((scheduleItem: ISchedule, index: number) => {
                                const startDate = helpers.getLocalDate({
                                    date: scheduleItem.startDate,
                                    time: scheduleItem.startTime,
                                })
                                const endDate = helpers.getLocalDate({
                                    date: scheduleItem.endDate,
                                    time: scheduleItem.endTime,
                                })

                                return (
                                    <div
                                        key={`scheduleItem_${index}`}
                                        className={cn(grid.w100, grid.colCenter, textStyles.center)}
                                    >
                                        <div className={grid.mb_micro}>
                                            {translate('start')}:{' '}
                                            {helpers.getFormattedLocalDate(startDate, 'DD.MM.YYYY HH:mm:ss')}
                                        </div>
                                        <div className={grid.mb_micro}>
                                            {translate('ending')}:{' '}
                                            {helpers.getFormattedLocalDate(endDate, 'DD.MM.YYYY HH:mm:ss')}
                                        </div>
                                        <div>
                                            {translate('repeat')}: {translate(scheduleItem.repeatMode)}
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                        <div className={cn(grid.p_mdPlus, textStyles.center)}>
                            {translate('scheduleConflictsDescription')}
                        </div>
                        <div className={cn(grid.row, grid.justify, grid.pl_mdPlus, grid.pr_mdPlus)}>
                            {s_.solutions.replace &&
                                (() => {
                                    const scheduleItem = s_.solutions.replace
                                    const startDate = helpers.getLocalDate({
                                        date: scheduleItem.startDate,
                                        time: scheduleItem.startTime,
                                    })
                                    const endDate = helpers.getLocalDate({
                                        date: scheduleItem.endDate,
                                        time: scheduleItem.endTime,
                                    })
                                    const selected = s_.selectedSolution === 'replace'

                                    return (
                                        <div className={cn(grid.row)}>
                                            <Radio
                                                onClick={() =>
                                                    setState((prevState: any) => {
                                                        return {
                                                            ...prevState,
                                                            selectedSolution: 'replace',
                                                        }
                                                    })
                                                }
                                                selected={selected}
                                                className={cn(grid.mr_mini)}
                                            />
                                            <div>
                                                <div
                                                    className={cn(grid.pb_md, grid.pt_micro, {
                                                        [textStyles.darkCloud]: !selected,
                                                    })}
                                                >
                                                    {translate('replaceCurrent')}
                                                </div>
                                                <div className={textStyles.darkCloud}>
                                                    <div className={grid.mb_micro}>
                                                        {translate('start')}:{' '}
                                                        {helpers.getFormattedLocalDate(
                                                            startDate,
                                                            'DD.MM.YYYY HH:mm:ss'
                                                        )}
                                                    </div>
                                                    <div className={grid.mb_micro}>
                                                        {translate('ending')}:{' '}
                                                        {helpers.getFormattedLocalDate(endDate, 'DD.MM.YYYY HH:mm:ss')}
                                                    </div>
                                                    <div>
                                                        {translate('repeat')}: {translate(scheduleItem.repeatMode)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })()}
                            {s_.solutions.placeAfter &&
                                (() => {
                                    const scheduleItem = s_.solutions.placeAfter
                                    const startDate = helpers.getLocalDate({
                                        date: scheduleItem.startDate,
                                        time: scheduleItem.startTime,
                                    })
                                    const endDate = helpers.getLocalDate({
                                        date: scheduleItem.endDate,
                                        time: scheduleItem.endTime,
                                    })
                                    const selected = s_.selectedSolution === 'placeAfter'

                                    return (
                                        <div className={cn(grid.row)}>
                                            <Radio
                                                onClick={() =>
                                                    setState((prevState: any) => {
                                                        return {
                                                            ...prevState,
                                                            selectedSolution: 'placeAfter',
                                                        }
                                                    })
                                                }
                                                selected={selected}
                                                className={cn(grid.mr_mini)}
                                            />
                                            <div>
                                                <div
                                                    className={cn(grid.pb_md, grid.pt_micro, {
                                                        [textStyles.darkCloud]: !selected,
                                                    })}
                                                >
                                                    {translate('postNext')}
                                                </div>
                                                <div className={textStyles.darkCloud}>
                                                    <div className={grid.mb_micro}>
                                                        {translate('start')}:{' '}
                                                        {helpers.getFormattedLocalDate(
                                                            startDate,
                                                            'DD.MM.YYYY HH:mm:ss'
                                                        )}
                                                    </div>
                                                    <div className={grid.mb_micro}>
                                                        {translate('ending')}:{' '}
                                                        {helpers.getFormattedLocalDate(endDate, 'DD.MM.YYYY HH:mm:ss')}
                                                    </div>
                                                    <div>
                                                        {translate('repeat')}: {translate(scheduleItem.repeatMode)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })()}
                        </div>
                        <div>
                            <div className={cn(grid.row, grid.pl_mdPlus, grid.pr_mdPlus, styles.deviceCountWarning)}>
                                {`${translate('numberOfDevices')}: ${p_.devices.length}`}
                            </div>
                            <div className={styles.buttons}>
                                <Button
                                    rounded="full_md"
                                    indentSize="mini"
                                    onClick={closeConflictsModal}
                                    className={cn(grid.mr_md)}
                                >
                                    {translate('cancel')}
                                </Button>
                                <Button rounded="full_md" indentSize="mini" mod="fill" onClick={saveWithSolution}>
                                    {translate('save')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        emitError: (err: string) => dispatch(emitError(err)),
    }
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(AddSchedule)
