import React, { useEffect, useState } from 'react'
import { Radio } from 'atoms/Radio'
import EditText, { EditTextPassword } from 'blocks.simple/editText/editText'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import deepEqual from 'fast-deep-equal'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './styles'
import { text as textStyles } from 'theme'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { Typography } from 'atoms/Typography'

const isDisabledPlatform = (platform: string) => {
    return platform === 'sssp_old' || platform === 'sssp_new' || platform === 'webos_old' || platform === 'webos_new'
}

const connectionTypes = [
    { id: 'socks', name: 'socks' },
    { id: 'http', name: 'http' },
]

const DisplaysSettingsProxy = (p_: { onChange: (data: any) => void; platform: string; settings: any }) => {
    const [state, setState] = useState({
        settings: p_.settings ? p_.settings : {},
    })
    useEffect(() => {
        if (p_.settings && !deepEqual(p_.settings, state.settings)) {
            setState({ settings: p_.settings })
        }
    }, [p_])

    const s_ = state
    const status = s_.settings.enabled
    const resetIfDisabledQ = s_.settings.resetIfDisabledQ

    return (
        <div className={grid.mb_mdPlus}>
            <Typography className={cn(textStyles.normal)} type="title">
                {translate('proxyServer')}
            </Typography>
            <div className={cn(grid.row, grid.mt_md, grid.mb_md)}>
                <Radio
                    className={cn(grid.mr_md, styles.radioGap)}
                    label={translate('on')}
                    onClick={() => {
                        if (Object.keys(s_.settings).length) {
                            p_.onChange({
                                ...s_.settings,
                                enabled: true,
                                resetIfDisabledQ: false,
                            })
                        } else {
                            p_.onChange({
                                enabled: true,
                                ip: '',
                                port: '',
                                login: '',
                                password: '',
                                connectionType: '',
                                resetIfDisabledQ: false,
                            })
                        }
                    }}
                    selected={!!status}
                />
                <Radio
                    label={translate('offAndNoDeleteNetworkSettings')}
                    onClick={() => {
                        if (Object.keys(s_.settings).length) {
                            if (!s_.settings.ip || !s_.settings.port || !s_.settings.connectionType) {
                                p_.onChange({})
                            } else {
                                p_.onChange({
                                    ...s_.settings,
                                    enabled: false,
                                    resetIfDisabledQ: false,
                                })
                            }
                        } else {
                            p_.onChange({})
                        }
                    }}
                    selected={!status && !resetIfDisabledQ}
                    className={cn(styles.radioMargin, styles.radioGap)}
                />
                <Radio
                    label={translate('offAndDeleteNetworkSettings')}
                    onClick={() => {
                        p_.onChange({
                            ...s_.settings,
                            enabled: false,
                            resetIfDisabledQ: true,
                        })
                    }}
                    selected={!status && resetIfDisabledQ}
                    className={styles.radioGap}
                />
            </div>
            <div className={cn(grid.row, grid.mb_md, { [item.disableWrapper]: !status })}>
                <div className={styles.loginWrapper}>
                    <div className={cn(grid.row, grid.mb_md, grid.justify)}>
                        <EditText
                            mod={'withBorder'}
                            label={'IP'}
                            value={s_.settings.ip || ''}
                            containerClass={cn(grid.w70)}
                            placeholder={'127.0.0.1'}
                            onChange={function (ip: string) {
                                if (/\d\.*$/g.test(ip) || !ip) {
                                    p_.onChange({ ...s_.settings, ip })
                                }
                            }}
                        />
                        <div className={cn(grid.flex)}>
                            <div className={cn(textStyles.big, grid.mt_auto, grid.mb_micro)}>:</div>
                        </div>
                        <EditText
                            mod={'withBorder'}
                            label={'PORT'}
                            placeholder={'80'}
                            value={s_.settings.port || ''}
                            containerClass={cn(grid.w25)}
                            onChange={function (port: string) {
                                if (/^\d+$/.test(port) || !port) {
                                    p_.onChange({ ...s_.settings, port })
                                }
                            }}
                        />
                    </div>
                    <EditText
                        mod={'withBorder'}
                        label={translate('loginProxy')}
                        value={s_.settings.login || ''}
                        onChange={function (login: string) {
                            p_.onChange({ ...s_.settings, login })
                        }}
                    />
                </div>
                <div className={styles.connectWrapper}>
                    {!isDisabledPlatform(p_.platform) ? (
                        <Dropdown
                            className={cn(grid.mb_md)}
                            options={connectionTypes}
                            label={translate('typeOfSocketConnection')}
                            value={s_.settings.connectionType}
                            onChange={function (connectionType) {
                                p_.onChange({ ...s_.settings, connectionType: connectionType.id })
                            }}
                        />
                    ) : (
                        <div className={styles.blockGap} />
                    )}

                    <EditTextPassword
                        mod={'withBorder'}
                        label={translate('password')}
                        value={s_.settings.password || ''}
                        onChange={function (password: string) {
                            p_.onChange({ ...s_.settings, password })
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default DisplaysSettingsProxy
