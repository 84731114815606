import { useState, useEffect, useCallback } from 'react'
import { routes } from 'features/routes'
import { api } from 'core/api/ConnectionManager'
import { useOnMount } from 'core/hooks'
import translate from 'core/translate'
import validator from 'validator'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { treeViewHelpers } from 'core/helpers'
import { MapCreator } from 'features/map/controllers/MapCreator'
import { isExist, isNotEmptyArray } from 'core/utils/coreUtil'
import { changeLocation } from 'features/routes'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { useDispatch } from 'react-redux'
import { headActions } from 'features/head/Head.state'

const mapFactory = MapCreator.getInstance().getMapFactory()
const mapApi = mapFactory.createMapApi()

const updateMapCenter = (state, lat, lng) => {
    if (isExist(lat) && isExist(lng)) {
        return {
            ...state,
            mapCenter: { lat, lng },
        }
    }

    return {
        ...state,
        mapCenter: { lat: null, lng: null },
    }
}

const updateInitialAddress = (state, address) => {
    return {
        ...state,
        initialAddress: address,
    }
}

const defaultDeviceState = {
    order: 0,
    label: '',
    value: '',
    snowflakeEnabledQ: null,
}

const initialState = {
    name: '',
    ipAddress: '',
    port: '',
    password: '',
    searchPin: '',
    description: '',
    parentId: null,
    address: '',
    lat: null,
    lng: null,
    brightness: 100,
    devices: [{ ...defaultDeviceState }],
    mapCenter: null,
    initialAddress: null,
}

const ScoreboardsAddMethods = (p_) => {
    const dispatch = useDispatch()
    const query = getURLSearchParamsByLocation(p_.location)
    const { controllerId, cloneControllerId, parentId } = query
    const [data, setState] = useState(initialState)
    const [isEdit, setIsEdit] = useState(false)
    const [labels, setLabels] = useState([])
    const [folders, setFolders] = useState([])

    useEffect(() => {
        const methodName = controllerId
            ? 'getScoreboardsMoveAvailableParentFolders'
            : 'getScoreboardsCreateAvailableParentFolders'

        const requestData = controllerId ? { ledControllerId: controllerId } : {}

        api.send(methodName, { nestedQ: false, ...requestData }).then((availableFolders) => {
            const updatedFolders = treeViewHelpers.addParentKeyIdForTree(availableFolders, 'scoreboards')
            setFolders(updatedFolders)
        })
    }, [controllerId])

    const reset = useCallback(() => {
        if (!controllerId && isEdit) {
            dispatch(headActions.setTitle([translate('controllerAdding')]))
            setIsEdit(false)
            setState(initialState)
        }
    }, [controllerId, isEdit])

    useEffect(() => {
        reset()
    }, [controllerId, isEdit, reset])

    const getData = (model, { edit = false } = {}) => {
        if (!model) {
            model = data
        }

        const devices = model.devices.map((device) => {
            const deviceState = {
                value: device.value,
                order: parseInt(device.order, 10),
                snowflakeEnabledQ: device.snowflakeEnabledQ,
            }

            if (edit) {
                deviceState.label = device.labelId
            } else {
                deviceState.labelId = device.label
            }

            return deviceState
        })

        return {
            ipAddress: model.ipAddress,
            port: parseInt(model.port, 10),
            name: model.name,
            description: model.description,
            lat: model.lat,
            lng: model.lng,
            address: model.address,
            brightness: model.brightness,
            parentId: model.parentId,
            searchPin: model.searchPin,
            password: model.password,
            devices,
        }
    }

    const decorateWithMapData = (newData) => {
        const { lat, lng, address } = newData

        let updatedWithMapCenterState = updateMapCenter(newData, lat, lng)
        let updatedWithInitialAddressState = updateInitialAddress(updatedWithMapCenterState, address)

        return updatedWithInitialAddressState
    }

    const getScoreboard = () => {
        const ledControllerId = parseInt(controllerId, 10)
        api.send('getScoreboardById', { ledControllerId }).then((res) => {
            const newData = getData(res, { edit: true })
            const decoratedData = decorateWithMapData(newData)

            setState(decoratedData)
            setIsEdit(true)
        })
    }

    const getCloneScoreboardData = () => {
        if (!cloneControllerId) {
            return
        }

        const ledControllerId = parseInt(cloneControllerId, 10)
        api.send('getScoreboardById', { ledControllerId }).then((controller) => {
            let newData = getData(controller, { edit: true })
            newData = {
                ...newData,
                name: '',
                ipAddress: '',
                password: '',
                searchPin: '',
                port: '',
            }

            setState(newData)
        })
    }

    useOnMount(() => {
        api.send('getSdLabels', {}).then((res) => {
            setLabels(res)
        })

        if (controllerId) {
            dispatch(headActions.setTitle([translate('controllerEditing')]))
            getScoreboard()
            return
        }

        getCloneScoreboardData()
        dispatch(headActions.setTitle([translate('controllerAdding')]))
    })

    const updateState = (value, field) => {
        const newState = {
            ...data,
            [field]: value,
        }

        setState(newState)
    }

    const updateIpAddress = (ipAddress) => updateState(ipAddress, 'ipAddress')
    const updatePort = (port) => updateState(port, 'port')
    const updateName = (name) => updateState(name, 'name')
    const updateSearchPin = (searchPin) => updateState(searchPin, 'searchPin')
    const updatePassword = (password) => updateState(password, 'password')
    const updateAddress = (address) => updateState(address, 'address')
    const onChangeParentFolder = (folder) => updateState(folder.ledControllerFolderId, 'parentId')
    const onSelectPlace = (address, lat, lng) => {
        const newState = {
            ...data,
            address,
            lat,
            lng,
        }

        setState(newState)
    }
    const updateDescription = (description) => updateState(description, 'description')
    const updateBrightness = (brightness) => updateState(brightness, 'brightness')
    const onAddNewDevice = () => {
        const devicesLength = data.devices.length
        setState({
            ...data,
            devices: [
                ...data.devices,
                {
                    ...defaultDeviceState,
                    order: devicesLength,
                },
            ],
        })
    }
    const onChangeDevice = (devices) => {
        setState({
            ...data,
            devices,
        })
    }
    const onCancel = () => {
        changeLocation(`/${routes.scoreboards.path}${parentId ? '?parentId=' + parentId : ''}`)
    }
    const validate = () => {
        const validateEmptyFields = ['ipAddress', 'port', 'name', 'parentId']

        for (let i = 0; validateEmptyFields.length > i; i++) {
            const field = validateEmptyFields[i]

            if (!data[field]) {
                dispatch(emitError(`${field}EmptyError`))
                return false
            }
        }

        if (!data.devices.length) {
            dispatch(emitError('devicesEmptyError'))
            return false
        }

        const port = parseInt(data.port, 10)

        if (!validator.isIP(data.ipAddress)) {
            dispatch(emitError('ipAddressFormatError'))
            return false
        }

        if (port >= 65535 || port <= 0) {
            dispatch(emitError('portRangeError'))
            return false
        }

        if (data.name.length < 3 || data.name.length > 127) {
            dispatch(emitError('nameRangeError'))
            return false
        }

        if (data.password.length > 31) {
            dispatch(emitError('TCPServerPasswordError'))
            return false
        }

        if (data.searchPin.length > 31) {
            dispatch(emitError('searchPinError'))
            return false
        }

        const emptyOrder = data.devices.find((device) => !device.order.toString())

        if (emptyOrder) {
            dispatch(emitError('orderEmptyError'))
            return false
        }

        const uniqueOrders = [...new Set(data.devices.map((device) => parseInt(device.order, 10)))]

        if (uniqueOrders.length < data.devices.length) {
            dispatch(emitError('ordersMustBeUnique'))
            return false
        }

        const deviceWithoutLabel = data.devices.find((device) => !device.label)

        if (deviceWithoutLabel) {
            dispatch(emitError('selectLabelError'))
            return false
        }

        const isValid = data.devices.some((device) => {
            const regex = /^\d{1,2}(\.\d{1,2})?$/g

            if (device.value && !device.value.match(regex)) {
                dispatch(emitError('scoreboardLabelFormatError'))
                return true
            }

            return false
        })

        return !isValid
    }

    const updateLocation = () => {
        const { address, initialAddress } = data

        if (address === initialAddress) {
            save()
            return
        }

        mapApi
            .geocodeLocation(address)
            .then((mapLocationList) => {
                if (!isNotEmptyArray(mapLocationList)) {
                    save()
                    return
                }

                let { lat, lng } = mapLocationList[0]

                setState(...data, lat, lng)
                save()
            })
            .catch(() => {
                save()
            })
    }

    const onSave = () => {
        updateLocation()
    }

    const save = () => {
        const methodName = isEdit ? 'updateScoreboard' : 'createScoreboard'
        const isValid = validate()

        if (!isValid) {
            return
        }

        const formattedData = getData()

        if (isEdit) {
            formattedData.ledControllerId = parseInt(controllerId, 10)
        }

        api.send(methodName, formattedData).then(() => {
            onCancel()
        })
    }

    const onChangeAddress = (address) => {
        setState({ ...data, address })
    }

    const onChangeLocation = (location) => {
        const { name, lat, lng } = location

        setState({ ...data, address: name, lat, lng, initialAddress: name })
    }

    const onChangePlacemarkGeometry = (geometry) => {
        const { lat, lng } = geometry

        setState({ ...data, lat, lng })
    }

    const getAddress = () => {
        const { address } = data

        return address || ''
    }

    const getCoordinate = () => {
        const { lat, lng } = data

        return {
            lat: lat || null,
            lng: lng || null,
        }
    }

    const getLocation = () => {
        return {
            name: getAddress(),
            ...getCoordinate(),
        }
    }

    const getCenter = () => {
        const { mapCenter } = data

        if (isExist(mapCenter) && isExist(mapCenter.lat) && isExist(mapCenter.lng)) {
            return mapCenter
        }

        return null
    }

    const onMapClose = () => {
        setState({ ...data, mapCenter: { lat: data.lat, lng: data.lng } })
    }

    const updateScoreboardDevices = async () => {
        const { port, ipAddress, password = '' } = data

        try {
            const scoreboardInfo = await api.send('getScoreboardInfo', { port, ipAddress, password })

            if (!scoreboardInfo) {
                dispatch(emitError('updateScoreboardDevicesError'))
                return
            }

            const devices = scoreboardInfo.devices.map((device) => ({ ...device, label: '' }))
            setState({ ...data, devices })
        } catch (err) {
            dispatch(emitError('updateScoreboardDevicesError'))
        }
    }

    const isDisableUpdateScoreboardDevices = () => {
        const { ipAddress, port } = data

        if (!port) return true

        const portNumber = parseInt(port, 10)

        return !validator.isIP(ipAddress) || portNumber >= 65535 || portNumber <= 0
    }

    return {
        data,
        isEdit,
        labels,
        updateIpAddress,
        updatePort,
        updateName,
        updatePassword,
        updateSearchPin,
        folders,
        onChangeParentFolder,
        updateAddress,
        onSelectPlace,
        updateDescription,
        updateBrightness,
        onCancel,
        onSave,
        onAddNewDevice,
        onChangeDevice,
        getCenter,
        getLocation,
        getAddress,
        onChangeLocation,
        onChangePlacemarkGeometry,
        onChangeAddress,
        onMapClose,
        isDisableUpdateScoreboardDevices,
        updateScoreboardDevices,
        updateState,
    }
}

export default ScoreboardsAddMethods
