import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IModalOptions {
    mod?: string
    showCloseIcon?: boolean
}

export interface IDraftDeviceState {
    modalState: string | null
    modalOptions: IModalOptions
}

export interface IDraftDeviceModal {
    modalState: string | null
    modalOptions: IModalOptions
}

export const initialState: IDraftDeviceState = {
    modalState: null,
    modalOptions: {},
}

const draftDevicesSlice = createSlice({
    name: 'draftDevices',
    initialState,
    reducers: {
        closeModal(state) {
            state.modalState = null
            state.modalOptions = {}
        },
        openModal(state, action: PayloadAction<IDraftDeviceModal>) {
            state.modalState = action.payload.modalState
            state.modalOptions = action.payload.modalOptions
        },
    },
})

const { reducer: draftDevicesReducer, actions: draftDevicesActions } = draftDevicesSlice
export { draftDevicesReducer, draftDevicesActions }
