import { addStyles } from 'ethcss'
import { grid, IStyles } from 'theme'

import { PREVIEW_CAPTION_HEIGHT } from '../preview.jcss'

const previewOfficeStyles: IStyles = {
    wrapper: {
        ...grid.space,
        ...grid.col,
        ...grid.center,
        marginTop: PREVIEW_CAPTION_HEIGHT,
    },
    previewOfficeViewer: {
        ...grid.space,
    },
}

addStyles(previewOfficeStyles)
export default previewOfficeStyles
