import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../theme'
import { defaultThemeStyles } from './dropMenu.theme'
import { mediaCSS } from 'blocks.app/config'

const dropMenuStyles: IStyles = {
    wrapper: {
        ...grid.w100,
        height: 42,
        ...item.rel,
    },
    icons: {
        ...grid.full,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.noWrap,
    },
    listTriggerItem: {
        ...grid.fullHeight,
        ...grid.colCenter,
        ...item.relative,
        ...item.pointer,
        padding: '0 8px',

        [mediaCSS.laptop]: {
            padding: '0 2px',

            [mediaCSS.tab]: {
                padding: '0 8px',

                [mediaCSS.tabMini]: {
                    padding: '0 2px',
                },
            },
        },
    },
    arrow: {
        ...item.abs,
        top: 1,
        left: -6,
    },
    arrowAuto: {
        ...item.abs,
        bottom: 'auto !important',
        top: '100% !important',
    },
    content: {
        boxShadow: `var(--var-module-ui-component-dropMenu-style-blockShadow, ${defaultThemeStyles.blockShadow})`,
        ...item.white,
        ...item.abs,
        top: '100%',
        left: 0,
        right: 0,
        zIndex: 99,
    },
    contentAuto: {
        top: 'calc(100% + 9px) !important',
    },
    contentGray: {
        ...item.greyLight,
    },
}

addStyles(dropMenuStyles)
export default dropMenuStyles
