import React, { useEffect, useState } from 'react'
import helpers from 'core/helpers'
import styles from './styles.jcss'
import Icon from 'blocks.simple/icon/icon'
import translate from 'core/translate'
import Button from 'blocks.simple/button/button'
import { api } from 'core/api/ConnectionManager'

export const MultiSelectFromListModal = ({
    onSelect,
    placeholder,
    callbackList,
    mode,
    initList,
    selectedList,
    apiParams,
    openStatus,
    onCancel,
    header,
    id,
}) => {
    const [isOpen, setIsOpen] = useState(openStatus ? openStatus : false)
    const [itemsList, setItemsList] = useState(initList ? initList : [])
    const [selectedItems, setSelectedItems] = useState(selectedList ? selectedList : [])

    useEffect(() => {
        if (openStatus) {
            setIsOpen(openStatus)
        }
    }, [openStatus])

    useEffect(() => {
        if (isOpen) {
            helpers.disableWindowScroll(true, 'MultiSelectFromListModal')
        } else {
            helpers.disableWindowScroll(false, 'MultiSelectFromListModal')
        }
    }, [isOpen])

    useEffect(() => {
        if (!isOpen) return

        if (mode === 'useApi') {
            api.send(apiParams.method, apiParams.params).then((res) => {
                setItemsList(callbackList(apiParams.callback ? apiParams.callback(res) : res))
            })
        }
    }, [isOpen])

    return (
        <>
            {isOpen ? (
                <div className={styles.modalBackLayer}>
                    <div className={styles.modal}>
                        {header ? header() : null}
                        <div className={styles.modalContent}>
                            <ul className={styles.modalListWrapper}>
                                {itemsList
                                    ? itemsList.map((item, index) => {
                                          if (!selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
                                              return (
                                                  <li
                                                      key={index}
                                                      className={`
                                            ${styles.modalListElement} 
                                            ${item.selected ? styles.elementActive : null}
                                        `}
                                                      onClick={() => {
                                                          setItemsList(
                                                              itemsList.map((element) => {
                                                                  if (element.id === item.id) {
                                                                      return { ...element, selected: !element.selected }
                                                                  }

                                                                  return element
                                                              })
                                                          )
                                                      }}
                                                  >
                                                      {helpers.quotesConverter(item.name, 'string')}
                                                  </li>
                                              )
                                          }
                                      })
                                    : null}
                            </ul>
                            <div className={styles.modalSortPanel}>
                                <div
                                    className={styles.arrow}
                                    onClick={() => {
                                        let list = []

                                        itemsList.forEach((item) => {
                                            if (item.selected) {
                                                item.selected = false
                                                list.push(item)
                                            }
                                        })
                                        setSelectedItems([...selectedItems, ...list])
                                    }}
                                >
                                    <Icon size={16} type={'double_arrow_right'} />
                                </div>
                                <div
                                    className={styles.arrow}
                                    onClick={() => {
                                        setSelectedItems(selectedItems.filter((elem) => elem.selected !== true))
                                    }}
                                >
                                    <Icon size={16} type={'double_arrow_left'} />
                                </div>
                            </div>
                            <ul className={styles.modalListWrapper}>
                                {selectedItems.map((item, index) => {
                                    if (item.id) {
                                        return (
                                            <li
                                                key={index}
                                                className={`
                                            ${styles.modalListElement} 
                                            ${item.selected ? styles.elementActive : ''}
                                        `}
                                                onClick={() => {
                                                    setSelectedItems(
                                                        selectedItems.map((element) => {
                                                            if (element.id === item.id) {
                                                                return { ...element, selected: !element.selected }
                                                            }

                                                            return element
                                                        })
                                                    )
                                                }}
                                            >
                                                {helpers.quotesConverter(item.name, 'string')}
                                            </li>
                                        )
                                    }
                                })}
                            </ul>
                        </div>
                        <div className={styles.nav}>
                            <Button
                                mod={'fill'}
                                indentSize={'mini'}
                                textSize={'mini'}
                                rounded={'full_normal'}
                                onClick={function () {
                                    setIsOpen(false)

                                    if (onCancel) {
                                        onCancel()
                                    }
                                }}
                            >
                                {translate('cancel')}
                            </Button>
                            <Button
                                mod={'fill'}
                                indentSize={'mini'}
                                textSize={'mini'}
                                rounded={'full_normal'}
                                onClick={function () {
                                    onSelect(selectedItems.filter((item) => item.id).map((item) => item.id))
                                    setIsOpen(false)
                                }}
                            >
                                {translate('save')}
                            </Button>
                        </div>
                    </div>
                </div>
            ) : (
                <div onClick={() => setIsOpen(true)}>
                    {selectedItems.length
                        ? `${translate('selected')} (${selectedItems.filter((item) => item.id).length})`
                        : placeholder}
                </div>
            )}
        </>
    )
}
