import React from 'react'
import EditorAutoPositionAudioMethods from './editor__autoPositionAudio.local.methods'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import Button from 'blocks.simple/button/button'
import { Radio } from 'atoms/Radio'
import EditText from 'blocks.simple/editText/editText'
import RangeVolume from 'blocks.simple/range/_volume/range_volume'

import translate from 'core/translate'

import styles from './editor__autoPositionAudio.jcss'
import { OrderMode } from 'core/models/Editor'

const getOptions = (tags, ignore) => tags.filter((tag) => !ignore.find((item) => item.id === tag.id))

const soundOrderModeOptionsList = [OrderMode.userProvided, OrderMode.shuffle]

const EditorAutoPositionAudio = (p_) => {
    const { s_, methods } = EditorAutoPositionAudioMethods(p_)
    const promotionalList = s_.selectedTags['promotional']
    const backContentList = s_.selectedTags['backContent']
    const getSoundModeOptions = (options) => {
        return [...options.map((option) => ({ id: option, name: translate(option) }))]
    }

    const soundModeOptions = getSoundModeOptions(soundOrderModeOptionsList)

    return (
        <div className={styles.wrapper}>
            <div className={styles.tagsWrapper}>
                <div className={styles.inputWrapper}>
                    <div className={styles.inputTitle}>{translate('promotionalInsert')}</div>
                    <Dropdown
                        list={promotionalList}
                        options={getOptions(s_.tags, backContentList)}
                        onChange={function (list, tag, deletedTag) {
                            methods.onChangeTags(tag, 'promotional', deletedTag)
                        }}
                        multiselect
                        searchable
                        disableAddNewTags
                    />
                </div>
                <div className={styles.inputTitle}>{translate('setVolumeLevelAdvertisingContent')}</div>
                <RangeVolume
                    volume={s_.volume.promotional}
                    onChange={function (volume) {
                        methods.onChangeVolume(volume, 'promotional')
                    }}
                />
            </div>
            <div className={styles.tagsWrapper}>
                <div className={styles.inputWrapper}>
                    <div className={styles.inputTitle}>{translate('backContent')}</div>
                    <Dropdown
                        list={backContentList}
                        options={getOptions(s_.tags, promotionalList)}
                        onChange={function (list, tag, deletedTag) {
                            methods.onChangeTags(tag, 'backContent', deletedTag)
                        }}
                        multiselect
                        searchable
                        disableAddNewTags
                    />
                </div>
                <div className={styles.inputTitle}>{translate('setVolumeLevelBackgroundContent')}</div>
                <RangeVolume
                    volume={s_.volume.backContent}
                    onChange={function (volume) {
                        methods.onChangeVolume(volume, 'backContent')
                    }}
                />
            </div>
            {p_.onSetSoundOrderMode && (
                <div className={styles.orderModeDropdownWrapper}>
                    <Dropdown
                        className={styles.orderModeDropdown}
                        value={p_.soundOrderMode}
                        options={soundModeOptions}
                        label={translate('playOrder')}
                        onChange={(e) => p_.onSetSoundOrderMode(e.id)}
                    />
                </div>
            )}
            {p_.soundOrderMode === OrderMode.userProvided && (
                <React.Fragment>
                    <div className={styles.controls}>
                        <div className={styles.radio}>
                            <Radio
                                label={translate('alternateBackgroundAndAdvContent')}
                                selected={s_.algorithm === 'alternate'}
                                onClick={function () {
                                    methods.setAlgorithm('alternate')
                                }}
                            />
                        </div>
                        <div className={styles.radio}>
                            <Radio
                                label={translate('alternateBackgroundAndAdvContentAfterTime')}
                                selected={s_.algorithm === 'alternateWithTime'}
                                onClick={function () {
                                    methods.setAlgorithm('alternateWithTime')
                                }}
                            />
                        </div>
                        <EditText
                            disabled={s_.algorithm !== 'alternateWithTime'}
                            value={s_.alternateSeconds}
                            type="number"
                            onChange={function (value) {
                                methods.setAlternateSeconds(value)
                            }}
                        />
                    </div>
                    <div className={styles.footer}>
                        <Button mod="fill" onClick={methods.automaticCalculateAudioContentPosition}>
                            {translate('calculate')}
                        </Button>
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

export default EditorAutoPositionAudio
