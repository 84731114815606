import { useState } from 'react'
import { useSpring } from 'react-spring'

import { colors, transitions } from 'theme'
import { TThemeColorTypes } from 'theme/colors'
import { icons } from './Icon-config'

import { IIconHook } from './Icon-types'

import { cn } from 'ethcss'
import { iconStyles } from './Icon-styles'

const getIconColor = (color?: string) => {
    if (!color) {
        return
    }

    const colorFromConfig = colors[color as TThemeColorTypes]
    return colorFromConfig ? colorFromConfig : color
}

const getClassNames = ({ iconClassName, type, rotate, disabled, className, onClick }: IIconHook) => ({
    iconClassName: cn(`${type}_icon`, iconClassName, {
        [iconStyles[`${rotate}Rotate`]]: rotate,
        [iconStyles.cursor]: onClick && !disabled,
        [iconStyles.disabled]: disabled,
    }),
    wrapperClassName: cn(iconStyles.wrapper, className),
})

export const useIcon = ({ type, color, disabled, rotate, className, iconClassName, onClick }: IIconHook) => {
    const [animationState, toggleAnimationState] = useState(true)
    const spring = useSpring({
        from: {
            x: 1,
        },
        x: animationState ? 1 : 0,
        config: {
            duration: transitions.normal,
        },
    })
    const iconColor = getIconColor(color)
    const icon = icons[type]
    const classNames = getClassNames({ type, disabled, rotate, className, iconClassName, onClick })

    const handleIconClick = (event: React.MouseEvent) => {
        if (disabled || !onClick) {
            return
        }

        toggleAnimationState(!animationState)
        onClick(event)
    }

    return {
        spring,
        iconColor,
        icon,
        classNames,
        handleIconClick,
    }
}
