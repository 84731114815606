import React, { FunctionComponent } from 'react'

import { useTabs } from './Tabs-hooks'
import { ITabsComponent } from './Tabs-types'

import { cn } from 'ethcss'
import { tabsStyles } from './Tabs-styles'

export const Tabs: FunctionComponent<ITabsComponent> = ({
    active,
    type = 'horizontal',
    tabsIsHidden = false,
    className = tabsStyles.wrapper,
    tabsWrapperClassName,
    tabClassName = tabsStyles.tab,
    contentClassName = tabsStyles.content,
    activeTabClassName = tabsStyles.active,
    contentId,
    disabled,
    onChange = () => {},
    children
}) => {
    const tabsHooks = useTabs({ disabled, onChange, children, active })

    return (
        <div className={cn(className)}>
            <div className={cn(tabsStyles[type], tabsWrapperClassName)}>
                {!tabsIsHidden &&
                    children.map((tab: JSX.Element, index: number) => (
                        <div
                            key={index}
                            className={cn(tabClassName, {
                                [activeTabClassName]: tabsHooks.activeTab === tab.props.tabid
                            })}
                            data-tabid={tab.props.tabid}
                            onClick={tabsHooks.onClickTab}
                        >
                            {tab.props.label}
                        </div>
                    ))}
            </div>
            <div className={cn(contentClassName)} id={contentId}>
                {tabsHooks.getContentFromTabId()}
            </div>
        </div>
    )
}
