import { addStyles } from 'ethcss'
import { IStyles, grid, colors, mediaCSS } from 'theme'

const playControllButtonStyles: IStyles = {
    button: {
        ...grid.flex,
        ...grid.justify,
        position: 'relative',
        border: '1px solid #969696',
        borderRadius: '10px',
        width: '32.5%',
        height: '130px',
        padding: '14px',
        lineHeight: '1px',
        color: colors.white,
        cursor: 'pointer',
        transition: 'border-color 0.3s',

        '&:hover': {
            borderColor: '#14D182',

            '&>div': {
                opacity: 1,
            },
        },

        [mediaCSS.tab]: {
            width: '49%',
        },
        [mediaCSS.tabMini]: {
            width: '100%',
        },
    },
    buttonInfo: {
        ...grid.flex,
        ...grid.normalCenter,
        padding: '0 22px',
        width: '30%',
        height: '100px',
        backgroundColor: '#969696',
        borderRadius: '5px',
    },
    keyValue: {
        fontSize: '48px',
        width: '62%',
    },
    details: {
        ...grid.flex,
        position: 'relative',
        alignItems: 'center',
        width: '65%',
        overflow: 'hidden',
    },
    title: {
        fontSize: '24px',
    },
    timer: {
        position: 'absolute',
        left: 0,
        bottom: '14px',
        fontWeight: '600',
        fontSize: '24px',
        color: '#14D182',
    },
    change: {
        position: 'absolute',
        right: '14px',
        top: '14px',
        opacity: 0,
        transition: 'all 0.3s',
        fontWeight: 500,
        lineHeight: 1,

        '&:hover': {
            color: '#14D182',
        },
    },
}

addStyles(playControllButtonStyles)

export default playControllButtonStyles
