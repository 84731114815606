import displaysTemplate from './_displays'
import videoChatTemplate from './_videoChat'
import tasksTemplate from './_tasks'
import broadcastsTemplate from './_broadcasts'
import filesTemplate from './_files'
import reportTasksTemplate from './_reportTasks'
import usersTemplate from './_users'
import advertisingCampaignsTemplate from './_advertisingCampaigns'
import coursesTemplate from './_courses'
import teachersTemplate from './_teachers'
import gradesTemplate from './_grades'
import subjectsTemplate from './_subjects'
import classroomsTemplate from './_classrooms'
import draftDevicesTemplate from './_draftDevices'
import videoEditorTasksTemplate from './_videoEditorTasks'
import fillersAdBlocksTemplate from './_fillersAdBlocks'
import wineTemplate from './_wine'
import menuProductTemplate from './_menuProduct'
import productMenuTemplate from './_productMenu'
import authorsTemplate from './_catalogizator/authors'
import artsTemplate from './_catalogizator/arts'
import lightOfHistory from './_lightOfHistory'
import survey from './_survey'
import lensOfTime from './_lensOfTime'
import exhibitionsTemplate from './_exhibition/exhibitions'
import exhibitionsPagesTemplate from './_exhibition/exhibitionsPages'
import quiz from './_quizQuestionsCatalog'
import schoolClassRoomsSchedule from './_schedulesClasses/schedule'

const template: { [index: string]: any } = {
    displays: displaysTemplate,
    videoChat: videoChatTemplate,
    tasks: tasksTemplate,
    broadcasts: broadcastsTemplate,
    files: filesTemplate,
    reportTasks: reportTasksTemplate,
    users: usersTemplate,
    advertisingCampaigns: advertisingCampaignsTemplate,
    courses: coursesTemplate,
    teachers: teachersTemplate,
    grades: gradesTemplate,
    subjects: subjectsTemplate,
    classrooms: classroomsTemplate,
    wine: wineTemplate,
    draftDevices: draftDevicesTemplate,
    videoEditorTasks: videoEditorTasksTemplate,
    fillersAdBlocks: fillersAdBlocksTemplate,
    menuProduct: menuProductTemplate,
    productMenu: productMenuTemplate,
    authors: authorsTemplate,
    arts: artsTemplate,
    lightOfHistory: lightOfHistory,
    lensOfTime: lensOfTime,
    exhibitions: exhibitionsTemplate,
    exhibitionsPages: exhibitionsPagesTemplate,
    survey: survey,
    quiz: quiz,
    scheduleClasses: schoolClassRoomsSchedule,
}

export default (type: string) => template[type]
