import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../theme'

const rectangleStyles: IStyles = {
    wrapper: {
        ...item.rel,
        ...grid.w100,
    },
    content: {
        ...item.abs,
        ...grid.full,
    },
    cover: {
        ...item.abs,
        ...item.cover,
    },
    contain: {
        ...item.abs,
        ...item.cover,
        backgroundSize: 'contain',
    },
    center: {
        ...item.abs,
        ...grid.full,
        backgroundSize: '30%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    fullWidth: {
        ...item.abs,
        ...grid.full,
        backgroundSize: '96%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
}

addStyles(rectangleStyles)
export default rectangleStyles
