import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { IStyles } from '../../../theme'

const tableEditorPreviewStyles: IStyles = {
    tableWrapper: {
        ...grid.full,
        overflow: 'auto',
    },
}

addStyles(tableEditorPreviewStyles)
export default tableEditorPreviewStyles
