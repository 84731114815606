import React from 'react'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import styles from './catalog__template_productMenu.jcss'
import { CatalogActionBar } from 'organisms/Catalog/ActionBar/ActionBar-view'
import { ActionBarRequestHeaderType, ActionBarActionType } from 'core/models/ActionBar'
import EditableText from 'blocks.simple/editableText/editableText'
import Button from 'blocks.simple/button/button'
import grid from 'blocks.simple/grid/grid.jcss'
import { cn } from 'ethcss'
import { IActionBarComponent } from '../../../../organisms/Catalog/ActionBar/ActionBar-types'
import { ItemData } from '../../../../core/models/Template'

const getActionList = (p_: any) => {
    return [
        {
            order: 1,
            type: ActionBarActionType.ADD,
            cb: () => {
                p_.onAddFakeLine({ name: '' })
            },
        },
        {
            order: 2,
            type: ActionBarActionType.DELETE,
            cb: () => {
                const productMenuIds = p_.selectedItems.map(
                    (selectedItem: { productMenuId: number }) => selectedItem.productMenuId
                )

                if (productMenuIds.length > 0) {
                    api.send('deleteProductMenus', { productMenuId: productMenuIds })
                }

                if (p_.fakeLine) {
                    p_.onAddFakeLine(null)
                }
            },
        },
    ]
}

export default {
    getMethod: 'getProductMenus',
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('emptyProductMenus'),
    emptyDescription: () => translate('noDescription'),
    emptyIcon: 'not_allow',
    disableDrag: true,
    addItemButton: () => null,
    settingsPrefixes: 'productMenu',
    sortOptions: () => [],
    headerFilters: (p_: IActionBarComponent) => {
        return (
            <CatalogActionBar
                title={translate('productMenuCatalog')}
                requestHeaderType={ActionBarRequestHeaderType.WITH_AUTH}
                type="productMenu"
                actions={getActionList(p_)}
                {...p_}
            />
        )
    },
    getItemModel: (dataItem: ItemData, getSelected: (item: ItemData) => boolean) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    getBackendModel: (item: ItemData) => {
        return api.send('getProductMenus', { query: item.name }, { hideLoader: true })
    },
    tableWrapper: styles.tableWrapper,
    isEqual: (firstItem: ItemData, secondItem: ItemData) => {
        if (firstItem.productMenuId && secondItem.productMenuId) {
            return firstItem.productMenuId === secondItem.productMenuId
        }

        return firstItem === secondItem
    },
    onCreateListeners: [
        (callback: any, clearList: string[]) => {
            return api.addObserver(
                'productMenuCreated',
                (createdProductMenu: object) => {
                    callback({ ...createdProductMenu })
                },
                clearList
            )
        },
    ],
    onUpdateListeners: [
        (callback: any, clearList: string[]) => {
            return api.addObserver(
                'productMenuUpdated',
                (updatedProductMenu: object) => {
                    callback({ ...updatedProductMenu })
                },
                clearList
            )
        },
    ],
    onDeleteListeners: [
        (callback: any, clearList: string[]) =>
            api.addObserver(
                'productMenusDeleted',
                (deletedProductMenus: { productMenuId: { productMenuId: number }[] }) => {
                    deletedProductMenus.productMenuId.forEach((productMenuId) => {
                        callback({ productMenuId })
                    })
                },
                clearList
            ),
    ],
    isFolder: () => {},
    fields: () => [
        { id: 'productMenuId', name: translate('productMenuId') },
        { id: 'name', name: translate('name') },
    ],
    init(callback: any) {
        //@ts-ignore
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    list: {
        name: (p_: { name: string; productMenuId: number; productCode: string }, active: boolean, listProps: any) => (
            <div className={cn(grid.fullWidth, { [grid.row]: !p_.productMenuId })}>
                <div className={cn({ [grid.fullWidth]: p_.productMenuId, [grid.space]: !p_.productMenuId })}>
                    <EditableText
                        id={p_.productMenuId}
                        text={p_.name}
                        onChange={(name: string) => {
                            listProps.onAddFakeLine({ ...listProps.fakeLine, name })
                        }}
                        onUpdate={(name, cb) => {
                            api.send('updateProductMenu', {
                                productMenuId: p_.productMenuId,
                                name,
                            }).then(cb)
                        }}
                    />
                </div>

                {!p_.productMenuId && (
                    <Button
                        className={cn(grid.ml_micro, grid.mr_mini)}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            listProps.onAddFakeLine()
                        }}
                    >
                        {translate('cancel')}
                    </Button>
                )}

                {!p_.productMenuId && (
                    <Button
                        mod={'fill'}
                        indentSize={'mini'}
                        textSize={'mini'}
                        rounded={'full_normal'}
                        onClick={function () {
                            api.send('createProductMenu', {
                                name: listProps.fakeLine.name,
                            })
                        }}
                        disabled={!listProps.fakeLine.name}
                    >
                        {translate('save')}
                    </Button>
                )}
            </div>
        ),
    },
    hideActiveRowColor: true,
    disableUpdateUserSettings: true,

    disableListScroll: true,
    fixedColWidth: true,
}
