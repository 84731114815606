import React from 'react'
import { Link as ReactLink } from 'react-router-dom'
import { text as textStyles } from 'theme'
import translate from 'core/translate'

export default (p_: {
    id?: string | number
    mods?: string[]
    className?: string
    textKey?: string
    children?: any
    target?: string | null
    href?: string | null
    to?: any
    onClick?: any
}) => {
    let className = p_.className || ''
    let innerText

    if (p_.mods) {
        p_.mods.forEach((mod) => {
            className += ` ${textStyles[mod]}`
        })
    }

    if (p_.textKey) {
        innerText = translate(p_.textKey)
    } else {
        innerText = p_.children
    }

    if (p_.href) {
        return (
            <a className={className} target={p_.target ? p_.target : ''} href={p_.href}>
                {innerText}
            </a>
        )
    }

    if (p_.to) {
        return (
            <ReactLink to={p_.to} onClick={p_.onClick} className={className} draggable={false}>
                {innerText}
            </ReactLink>
        )
    }

    return (
        <div onClick={p_.onClick} className={className} draggable={false}>
            {innerText}
        </div>
    )
}
