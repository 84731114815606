import { Option, IFilter } from '../../../blocks.app/searchbar/Filter/Filter-types'
import translate from '../../translate'
import { api } from '../../api/ConnectionManager'
import moment from 'moment'
import helpers from 'core/helpers'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import coreHelpers from '../index'

export const getCompatibleFilters = async (filters: any, location?: any, type?: any, advancedSearch?: any) => {
    const query = helpers.getQuery(getURLSearchParamsByLocation(location), type).query

    return await api.send('getDsAndGroupFilters', {
        appliedFilters: filters,
        utcOffsetM: moment().utcOffset(),
        advancedSearch: {
            query: query ? query : '',
            ...advancedSearch,
        },
    })
}

export const getValidNameLabel = (option: Option, translateQ: boolean | undefined) => {
    const label = translateQ ? translate(option.label.toString()) : option.label

    return option.value === null || option.value === '' ? translate('filterOptionUnknown') : label
}

const parseParams = (string: string) => {
    let result = []

    try {
        result = JSON.parse(string)
    } catch (e) {
        console.log(e)
    }

    return result
}

export const appliedFiltersFromQuery = (availableFilters: IFilter[], location: any, type: string) => {
    const locationQuery = getURLSearchParamsByLocation(location)
    const query = coreHelpers.getQuery(locationQuery, type)
    const appliedFilters: IFilter[] = []
    const filterList: any = query.filterList ? parseParams(query.filterList) : []

    if (filterList) {
        filterList.forEach((filter: any) => {
            const availableFilter = availableFilters.find((item: IFilter) => filter.name === item.name)
            const availableOptions = availableFilter ? availableFilter.options : []
            const options = availableOptions.map((option: Option) => {
                return {
                    ...option,
                    selected: filter.options.some((item: any) => option.label === item.label && item.selected),
                }
            })

            if (options.length) {
                appliedFilters.push({
                    name: filter.name,
                    type: filter.type,
                    translateOptionsQ: availableFilter ? availableFilter.translateOptionsQ : false,
                    options,
                })
            }
        })
    }

    return appliedFilters
}

export const getFiltersFromQuery = (availableFilters: IFilter[], location: any, type: string) => {
    const locationQuery = getURLSearchParamsByLocation(location)
    const query = coreHelpers.getQuery(locationQuery, type)
    const filterList: any = query.filterList ? parseParams(query.filterList) : []

    return filterList
}

export const onApplyFilterToQuery = (filteredArray: any, advancedSearch: any) => {
    const filterToQuery: any = []
    let data: any = {}
    let advancedSearchList: any = {}

    filteredArray.forEach((filter: any) => {
        filterToQuery.push({
            name: filter.name,
            type: filter.type,
            options: getSelectedOptions(filter.options),
        })
    })

    data.filterList = JSON.stringify(filterToQuery)

    if (advancedSearch.enabledQ) {
        advancedSearchList.operator = advancedSearch.operator

        if (advancedSearch.exactly.activeQ) {
            advancedSearchList.separator = advancedSearch.exactly.separator
        }

        data.advancedSearch = JSON.stringify(advancedSearchList)
    }

    if (!filterToQuery.length) {
        delete data.filterList
    }

    return data
}

const getSelectedOptions = (options: any) => {
    return options.filter((option: any) => option.selected)
}

export const getSomeValues = (filter: IFilter) => {
    let valuesString = ''
    const keys: any[] = []

    filter.options.forEach((option: Option) => {
        if (option.selected) {
            keys.push(getValidNameLabel(option, filter.translateOptionsQ))
        }
    })

    valuesString = keys.length > 2 ? keys.slice(0, 2).join(', ') + ' и еще' : keys.slice().join(', ')

    return valuesString ? valuesString : translate(filter.name)
}
