import React, { useRef } from 'react'
import Dropzone from 'react-dropzone'
import Collapse from 'blocks.simple/collapse/collapse'
import Tippy from 'blocks.simple/tippy/tippy'
import Button from 'blocks.simple/button/button'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import Icon from 'blocks.simple/icon/icon'
import helpers from 'core/helpers'
import translate from 'core/translate'
import styles_ from '../settings.jcss'
import { cn } from 'ethcss'
import { grid } from '../../../theme'
import styles from './settings__fonts.jcss'
import { SettingsFontsMethodsHook, FontItem } from './settings__fonts-hooks'

interface IFontItem {
    items: FontItem[]
    selected: FontItem[]
    onSelect: any
}

const FontItems = ({ items, selected, onSelect }: IFontItem) => {
    const selectedItemsId = selected.map((selected: any) => selected.id)

    return (
        <div className={styles.list}>
            {!!items.length &&
                items.map((item: any, index: number) => (
                    <div key={`font_item_${index}`} className={grid.row}>
                        <Checkbox
                            onClick={() => {
                                const isExist = selectedItemsId.includes(item.id)
                                let state = [...selected]

                                if (isExist) {
                                    state = state.filter((stateItem) => stateItem.id !== item.id)
                                } else {
                                    state.push(item)
                                }

                                onSelect(state)
                            }}
                            className={cn(grid.mb_mini, grid.mt_mini)}
                            label={item.name}
                            disabled={!helpers.isAvailable({ key: 'settings', action: 'update' })}
                            checked={selectedItemsId.includes(item.id)}
                        />
                    </div>
                ))}
        </div>
    )
}

const SettingsFonts = () => {
    const {
        items,
        selectedItems,
        setSelectedItems,
        loading,
        isRequest,
        getCustomFonts,
        onDrop,
        deleteFonts,
        supportedFontTypes,
    } = SettingsFontsMethodsHook()
    const upload = useRef<any>(null)

    return (
        <Collapse
            isLoaded={loading}
            onClick={loading ? null : getCustomFonts}
            title={() => translate('customFonts')}
            contentPadding={false}
            className={cn(styles_.background, grid.mb_md)}
        >
            {helpers.isAvailable({ key: 'settings', action: 'update' }) && (
                <div className={styles.header}>
                    <div />
                    <Button
                        animation={false}
                        mod={'fill'}
                        disabled={isRequest}
                        onClick={() => {
                            upload.current.open()
                        }}
                    >
                        {translate('uploadNewFont')}
                    </Button>
                </div>
            )}
            <FontItems items={items} selected={selectedItems} onSelect={setSelectedItems} />
            {!!items && helpers.isAvailable({ key: 'settings', action: 'update' }) && (
                <Tippy
                    html={translate('delete')}
                    disabled={!selectedItems.length}
                    style={{ display: 'flex' }}
                    className={cn(styles.controls, selectedItems.length ? styles.active : styles.disable)}
                >
                    <div className={styles.footer} onClick={deleteFonts}>
                        <Icon type="delete" color="white" disabled={!selectedItems.length} />
                    </div>
                </Tippy>
            )}
            <Dropzone
                onDrop={onDrop}
                accept={{ 'application/x-font-ttf': supportedFontTypes }}
                ref={upload}
                multiple={true}
            >
                {() => <></>}
            </Dropzone>
        </Collapse>
    )
}

export default SettingsFonts
