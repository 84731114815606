import React from 'react'
import DisplaysPermissionsMethods from './displays__permissions.local.methods'
import Button from 'blocks.simple/button/button'
import LoaderLazy from 'blocks.app/loader/_lazy/loader__lazy'
import translate from 'core/translate'
import { cn } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import styles from './displays__permissions.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { displaysActions } from '../displays.state'
import { connect } from 'react-redux'

class DisplaysPermissions extends DisplaysPermissionsMethods {
    render() {
        const s_ = this.state
        const { closeModal } = this.props

        return (
            <div className={styles.modalWrapper}>
                <div className={styles.modalInner}>
                    {(s_.isLoading || s_.isUnknownPermissions) && (
                        <div className={styles.loader}>
                            <div className={cn(grid.mb_md, item.rel)}>
                                <div className={cn({ [item.hidden]: s_.isUnknownPermissions })}>
                                    <LoaderLazy active />
                                    {translate('getClientData')}
                                </div>
                                {s_.isUnknownPermissions && (
                                    <div className={styles.loadingDescription}>
                                        {translate('availablePermissionsUnknown')}
                                    </div>
                                )}
                            </div>
                            <Button
                                textSize={'mini'}
                                indentSize={'mini'}
                                rounded={'full_normal'}
                                onClick={function () {
                                    closeModal()
                                }}
                            >
                                {translate('cancel')}
                            </Button>
                        </div>
                    )}
                    {s_.permissions && (
                        <div>
                            <div className={styles.header}>
                                <div>{translate('permissions')}</div>
                            </div>
                            {s_.permissions &&
                                Object.keys(s_.permissions).map((permission: any, index) => (
                                    <div className={styles.listItem} key={index}>
                                        <div className={styles.permission}>
                                            {translate(`permission_${permission}`)}:
                                        </div>
                                        <div className={styles.description}>
                                            {s_.permissions && s_.permissions[permission] ? (
                                                translate('permission_received')
                                            ) : (
                                                <Button
                                                    mod={'fill'}
                                                    textSize={'mini'}
                                                    indentSize={'mini'}
                                                    rounded={'full_normal'}
                                                    onClick={() => {
                                                        this.requestPermission(permission)
                                                    }}
                                                    disabled={s_.requestPermissions[permission]}
                                                >
                                                    {translate('allow')}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            <div className={styles.footer}>
                                <Button
                                    textSize={'mini'}
                                    indentSize={'mini'}
                                    rounded={'full_normal'}
                                    onClick={function () {
                                        closeModal()
                                    }}
                                >
                                    {translate('close')}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeModal: () => dispatch(displaysActions.closeModal()),
    }
}

export default connect(null, mapDispatchToProps)(DisplaysPermissions)
