import React, { Fragment, FunctionComponent, memo } from 'react'
import { IVideoChatModalContentComponent } from './VideoChatModalContent-types'
import { styles } from './VideoChatModalContent-styles'
import Icon from 'blocks.simple/icon/icon'
import { colors } from 'theme/colors'
import Button from 'blocks.simple/button/button'
import { VideoChatModalVideo } from '../VideoChatModalVideo'
import translate from 'core/translate'
import { cn } from 'ethcss'

const VideoChatModalContentComponent: FunctionComponent<IVideoChatModalContentComponent> = ({
    digitalSignage,
    onMinimize,
    videoCallState,
    videoCallData,
    onVideoCallStart,
    onVideoCallEnd,
    onClose,
    isMediaDevicesInit,
    callStatus,
}) => {
    const { name } = digitalSignage
    const isCalling = videoCallState === 'calling'

    const handleVidelCallStart = (e: React.MouseEvent) => {
        onVideoCallStart()
    }

    const handleVideoCallEnd = (e: React.MouseEvent) => {
        onVideoCallEnd()
    }

    const handleClose = (e: React.MouseEvent) => {
        onClose()
    }

    const handleMinimize = (e: React.MouseEvent) => {
        onMinimize()
    }

    const renderVideo = () => {
        switch (callStatus) {
            case 'idle': {
                return <div className={styles.videoChatModalContent__text}>{translate('videoChatVideo')}</div>
            }
            case 'init':
                return (
                    <div className={styles.videoChatModalContent__loader}>
                        <div className={styles.videoChatModalContent__circle}>
                            <img
                                className={styles.videoChatModalContent__image}
                                src={'./images/loaders/loader.gif'}
                                alt=""
                            />
                        </div>
                        <div className={styles.videoChatModalContent__status}>
                            {translate('videoChatVideoStatusInit')}
                        </div>
                    </div>
                )
            case 'pending':
                return (
                    <div className={styles.videoChatModalContent__loader}>
                        <div className={styles.videoChatModalContent__circle}>
                            <img
                                className={styles.videoChatModalContent__image}
                                src={'./images/loaders/loader.gif'}
                                alt=""
                            />
                        </div>
                        <div className={styles.videoChatModalContent__status}>
                            {translate('videoChatVideoStatusPending')}
                        </div>
                    </div>
                )
            case 'confirm':
                if (videoCallData && videoCallData.video) {
                    return <VideoChatModalVideo id={'videoChatModalVideo'} srcObject={videoCallData.video} />
                } else {
                    return null
                }
        }
    }

    const renderButton = () => {
        if (callStatus === 'idle' && !isCalling) {
            return (
                <div className={styles.videoChatModalContent__close} onClick={handleClose}>
                    <Icon type="close" color={colors.white} size={32} />
                </div>
            )
        }

        if (callStatus === 'confirm' && isCalling) {
            return (
                <div className={styles.videoChatModalContent__close} onClick={handleMinimize}>
                    <Icon type="videoClose" color={colors.white} size={43} />
                </div>
            )
        }

        return null
    }

    return (
        <Fragment>
            <div className={styles.videoChatModalContent}>
                <div className={styles.videoChatModalContent__content}>
                    <div className={styles.videoChatModalContent__header}>{name}</div>
                    <div className={styles.videoChatModalContent__video}>{renderVideo()}</div>
                    <div className={styles.videoChatModalContent__footer}>
                        <div className={styles.videoChatModalContent__localVideo}>
                            {videoCallData && videoCallData.localVideo ? (
                                <VideoChatModalVideo
                                    id={'videoChatModalSubVideo'}
                                    srcObject={videoCallData.localVideo}
                                    isLocal={true}
                                />
                            ) : (
                                <div className={styles.videoChatModalContent__text}>
                                    {translate('videoChatLocalVideo')}
                                </div>
                            )}
                        </div>
                        <div className={styles.videoChatModalContent__actions}>
                            {isCalling ? (
                                <Button
                                    mod="error"
                                    className={cn(
                                        styles.videoChatModalContent__button,
                                        styles.videoChatModalContent__button_type_calling,
                                        {
                                            [styles.videoChatModalContent__button_disabled]: !isMediaDevicesInit,
                                        }
                                    )}
                                    onClick={handleVideoCallEnd}
                                >
                                    Завершить
                                </Button>
                            ) : (
                                <Button
                                    mod="fill"
                                    className={cn(
                                        styles.videoChatModalContent__button,
                                        styles.videoChatModalContent__button_type_default
                                    )}
                                    onClick={handleVidelCallStart}
                                >
                                    Позвонить
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {renderButton()}
        </Fragment>
    )
}

export const VideoChatModalContent = memo(VideoChatModalContentComponent)
