import React, { useRef } from 'react'
import Content from 'blocks.app/content/content'
import Stick from 'blocks.simple/stick/stick'
import ScrollUp from 'blocks.simple/scrollUp/scrollUp'
import ScheduleAddMethods, { Broadcast, Device } from './schedule_add.local.methods'
import Button from 'blocks.simple/button/button'
import AddSchedule from 'blocks.app/addSchedule/addSchedule'
import AddScheduleToolbar from 'blocks.app/addSchedule/_toolbar/addSchedule_toolbar'
import helpers from 'core/helpers'
import { connect } from 'react-redux'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { grid, text } from '../../../theme'
import styles from './schedule_add.jcss'
import { routes } from 'features/routes'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { headActions } from 'features/head/Head.state'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'

const ScheduleAdd = (p_: any) => {
    const el = useRef<any>(null)
    const {
        devices,
        setDevices,
        conflicts,
        save,
        cancel,
        broadcast,
        setBroadcast,
        advDuration,
        advContentLength,
        schedule,
        setSchedule,
        showCalendarAfterSave,
        contentDownloadDate,
        contentDownloadTime,
        setContentDownloadDate,
        setContentDownloadTime,
        orientationWarning,
        setOrientationWarning,
    } = ScheduleAddMethods({ ...p_, el })

    const pathname = helpers.getPathname(p_.location.pathname)
    const query = getURLSearchParamsByLocation(p_.location)
    const OrientationWarningModal = () => {
        return (
            <div
                className={cn(grid.colCenter, grid.p_microPlus, grid.pt_mini, {
                    [styles.dNoneModalSave]: !orientationWarning,
                    [styles.modalSaveApprove]: orientationWarning,
                })}
            >
                <div className={cn(grid.w80, grid.mb_md, grid.pt_micro)}>{translate('orientationWarning')}</div>
                <div className={cn(grid.pb_md, text.bold)}>{translate('save')}?</div>
                <div className={cn(grid.row, grid.justify, grid.mb_mini)}>
                    <Button mod="fill" className={cn(grid.mr_mini)} onClick={() => save(() => {}, true)}>
                        {translate('yes')}
                    </Button>
                    <Button animation={false} onClick={() => setOrientationWarning(false)}>
                        {translate('no')}
                    </Button>
                </div>
            </div>
        )
    }
    return (
        <Content
            toolbar={
                <AddScheduleToolbar
                    devices={devices}
                    conflicts={conflicts}
                    onChangeDevices={(devices: Device[]) => setDevices(devices)}
                />
            }
        >
            <ScrollUp />
            <Stick enabled={true} top={46}>
                <div className={styles.buttons}>
                    <Button mod="fill" className={cn(grid.mr_mini)} onClick={cancel}>
                        {translate('cancel')}
                    </Button>
                    <Button mod={'withBorder'} onClick={() => save(() => {})}>
                        {query.scheduleId && pathname === routes.editSchedule.path
                            ? translate('update')
                            : translate('save')}
                    </Button>
                </div>
            </Stick>
            {orientationWarning && <OrientationWarningModal />}
            <AddSchedule
                addScheduleRef={el}
                broadcast={broadcast}
                onChangeBroadcast={(broadcast: Broadcast) => setBroadcast(broadcast)}
                advDuration={advDuration}
                advContentLength={advContentLength}
                devices={devices}
                onChangeDevices={(devices: Device[]) => setDevices(devices)}
                schedule={schedule}
                changeLocationOnSave={showCalendarAfterSave}
                deferredDownload={{
                    date: contentDownloadDate,
                    time: contentDownloadTime,
                }}
                onChangeSchedule={(schedule: any) => setSchedule(schedule)}
                onChangeDeferredDownload={(value: any, field: any) => {
                    if (field === 'contentDownloadDate') setContentDownloadDate(value)
                    if (field === 'contentDownloadTime') setContentDownloadTime(value)
                }}
            />
        </Content>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setTitle: (title: any) => dispatch(headActions.setTitle(title)),
    }
}

const exportScheduleAdd = checkAvailableModule('schedule/add')
    ? connect(
          (state: any, route) => ({
              selectedDate: state.schedule.selectedDate,
              filterList: state.schedule.filterList,
          }),
          mapDispatchToProps
      )(ScheduleAdd)
    : null

export default exportScheduleAdd
